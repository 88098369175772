import { actions } from '..//constants/actions'

const tabInitialState = {
    tabs: [],
    activeTabIndex: 0,
    prevActiveTabIndex: -1,
    rememberTab: {}
}

const tabReducer = (state = tabInitialState, action) => {
    switch (action.type) {
        case actions.APP_SET_TAB:
            return {
                ...state,
                tabs: action.payload.tabs,
                activeTabIndex: action.payload.activeTabIndex
            }
        case actions.APP_SET_SELECTED_TAB:
            return {
                ...state,
                activeTabIndex: action.payload,
                prevActiveTabIndex: state.activeTabIndex
            }
        case actions.APP_SET_BACK_FR_LOGIN_TAB: 
            return {
                ...state,
                tabs: action.payload.tabs,
                rememberTab: action.payload.rememberTab
            }
        default:
            return state;
    }
}

export default tabReducer;