import React from 'react';
import {useDrag} from "react-dnd";
import {ItemTypes} from "./ItemTypes";

const AutomationAction = (props) => {
    const [collected, drag] = useDrag(() => ({
        type: ItemTypes.ACTION,
        item: props,
        end: (props, monitor) => {
            const item = monitor.getItem();
            const delta = monitor.getClientOffset();
            const dropResult = monitor.getDropResult();
            if (dropResult) {
                let posObject = {
                    posX: dropResult.left - 50,
                    posY: dropResult.top - 20,
                }
                props.openDrawer(item.objActions, posObject);
            }
        }
    }))
    const opacity = collected.isDragging ? 0.4 : 1;

    return (
        <div
            ref={drag}
            key={"1_" + props.id}
            style={{color: '#717171', paddingBottom: '4px', opacity}}
            title={props.tooltip}
            id={"T_" + props.id}
            onClick={() => props.openDrawer(props.objActions, null)}
        >
                <span key={"2_" + props.id} style={{cursor: 'pointer'}}>
                  <i key={"3_" + props.id} className={props.icon} style={{paddingRight: '5px'}}/>
                    {props.isOpen ? props.label : ''}
                </span>
        </div>
    );
}

export default AutomationAction;