import React, { useEffect, Fragment, useCallback } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./Login";
import { constants } from "../../../services/constants/constants";
import { setCookie } from "../../../services/helper/sfCookies";
import {
	initAppContextData,
	setSelectedModuleItems,
	updateAppRedirectBackFRLogin,
} from "../../../services/actions/appActions";
import {
	isValidParam,
	getArrayParam,
	getObjectParam,
	getStringParam,
} from "../../../services/helper/parameterVerifier";
import {
	deleteAllTabCookies,
	getLasetSelectedModuleName,
} from "../../../services/helper/sfCookies";
import PreviewTemplate from "../components/previewTemplate";
import PreviewBroadcast from "../components/previewBroadcast";
import PreviewNews from "../components/previewNews";
import Logout from "../components/logout";
import { setLasetSelectedModuleName } from "../../../services/helper/sfCookies";
import RedirectComponent from "../components/redirectComponent";
import AWSReportContainer from "../containers/AWSReportContainer";
import initNotfiAndReminder from "../../../services/actions/notification&ReminderActions";
import PageTemplatePreview from "../components/pageTemplatePreview";
import AuditQuiz from "../components/AuditQuiz";
import ChangePassword from "../components/changePassword";
import ComposeMobileEmail from "../components/ComposeMobileEmail";
import { useDispatch, useSelector } from "react-redux";
import PreviewTemplates from "../components/previewTemplates";
import PreviewAgreementTemplate from "../components/PreviewAgreementTemplate";
import PreviewNewsTemplate from "../components/PreviewNewsTemplate";
import MobileAuditQuiz from "../components/mobileAuditQuize";
import ThumbSurvey from "../components/thumbSurvey";
import { setTabDataBackFRLogin } from "../../../services/actions/tabActions";
import IntakeForm from "../components/IntakeForm";
import AuditAnswersAttachments from "../components/AuditAnswersAttachments";
import MMSAttachments from "../components/mmsAttachments";


//@DragDropContext(HTML5Backend)
const App = () => {
	const dispatch = useDispatch();
	const app = useSelector((state) => state.app);
	const header = useSelector((state) => state.header);
	
	const loginCallbackRedirectAndLocalStorageUpdate = useCallback(() => {
        let previousFRFEKeys = JSON.parse(localStorage.getItem('shouldReturnToInitialPosition_' + app.me.projectId + "_" + app.me.id))
		// written 12-02-2024 - remove after 45 days
		if(previousFRFEKeys) {
			localStorage.removeItem('type_' + app.me.projectId + "_" + app.me.id)
			localStorage.removeItem('returnUrl_' + app.me.projectId + "_" + app.me.id);
			localStorage.removeItem('listselectedTab_' + app.me.projectId + "_" + app.me.id);
			localStorage.removeItem('selectedModuleInfo_' + app.me.projectId + "_" + app.me.id);
			localStorage.removeItem('selectedModuleItems_' + app.me.projectId + "_" + app.me.id);
			localStorage.removeItem('selectedModuleName_' + app.me.projectId + "_" + app.me.id);
			localStorage.removeItem('listrememberedTab_' + app.me.projectId + "_" + app.me.id);
			localStorage.removeItem('shouldReturnToInitialPosition_' + app.me.projectId + "_" + app.me.id);
		}

		const franchiseAppLoginDetails = JSON.parse(localStorage.getItem('franchiseAppLoginDetails'))
        let shouldReturnToInitialPosition = JSON.parse(localStorage.getItem(`shouldReturnToInitialPos_${app.me.projectId}_${app.me.id}`))
		let selectionDetails, tabDetails, returnUrl, callFrom;
		if(franchiseAppLoginDetails && franchiseAppLoginDetails.length >= 2 ) {
			const index = franchiseAppLoginDetails.length - 1;
			const previousUnitLoginDetails = franchiseAppLoginDetails[index]
			selectionDetails = previousUnitLoginDetails.selectionDetails
			tabDetails= previousUnitLoginDetails.tabDetails
			returnUrl = previousUnitLoginDetails.returnUrl
			callFrom = previousUnitLoginDetails.callFrom
		} else if(franchiseAppLoginDetails && franchiseAppLoginDetails.length === 1){
			const previousUnitLoginDetails = franchiseAppLoginDetails[0]
			selectionDetails = previousUnitLoginDetails.selectionDetails
			tabDetails = previousUnitLoginDetails.tabDetails
			returnUrl = previousUnitLoginDetails.returnUrl
			callFrom = previousUnitLoginDetails.callFrom
		}

        if(shouldReturnToInitialPosition){
			if(returnUrl && returnUrl?.length > 0){
				window.location.replace(returnUrl);
				if(callFrom === constants.DETAIL){
					const tempredirectUrl = returnUrl.split("#");
					let tabData = {}
					tabData.tabs = tabDetails.listselectedTab;
					tabData.rememberTab = tabDetails.listrememberedTab;
					let appData = {}
					appData.selectedModuleInfo = selectionDetails.selectedModuleInfo;
					appData.selectedModuleName = selectionDetails.selectedModuleName;
					appData.selectedModuleItems = selectionDetails.selectedModuleItems;
					appData.redirectUrl = tempredirectUrl[1]
					dispatch(setTabDataBackFRLogin(tabData))
					dispatch(updateAppRedirectBackFRLogin(appData))
				} else if(callFrom === constants.LIST) {
					const tempredirectUrl = returnUrl.split("#");
					let tabData = {}
					tabData.tabs = tabDetails.listselectedTab;
					tabData.rememberTab = tabDetails.listrememberedTab;
					let appData = {}
					appData.selectedModuleInfo = selectionDetails.selectedModuleInfo;
					appData.selectedModuleName = selectionDetails.selectedModuleName;
					appData.selectedModuleItems = selectionDetails.selectedModuleItems;
					appData.redirectUrl = tempredirectUrl[1]
					dispatch(setTabDataBackFRLogin(tabData))
					dispatch(updateAppRedirectBackFRLogin(appData))
				}
			}
        }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[app.me.id, app.me.projectId, dispatch, header]);

	useEffect(()=>{
		loginCallbackRedirectAndLocalStorageUpdate()
	},[loginCallbackRedirectAndLocalStorageUpdate])

	const directRecordOpenByLinkClickFromEmail = () => {
		if (window.location.hash.indexOf("link-click") > 0) {
			let postUrl = window.location.hash.replace("#/", "");
			let arr = postUrl.split("/");
			let params = { object: arr[0], suffix: arr[1], id: arr[3] };
			if (params.object === constants.ACCOUNTS_OBJECT && arr.length > 3) {
				params.title = window.atob(arr[4]);
			}
			setCookie("link_click_from_email-" + arr[2], JSON.stringify(params), 1);
		}
	};

	useEffect(() => {
		let token = localStorage.getItem("token");

		if (token !== null) {
			initAppContextData();
		}
		
		let url = new URL(window.location.href);
		let tempToken = url.searchParams.get("token");
		if (isValidParam(tempToken)) {
			localStorage.setItem("token", tempToken);
			window.location.replace("/");
		}

		if (token !== null) {
			deleteAllTabCookies(); // for delete old cookie
			initNotfiAndReminder();
		}

		directRecordOpenByLinkClickFromEmail();

	}, []);

	useEffect(()=>{
		let home = getObjectParam(app.home);
		let me = app.me;
		let modules = getArrayParam(home.modules);
		let temModules = [];
		if (modules.length > 0 && me !== undefined && me !== null) {
			modules = modules.filter((f) => f?.is_show);
			let selectedModuleName = getStringParam(getLasetSelectedModuleName());

			if ((me.is_free_crm || !me.is_franchisor) && selectedModuleName === constants.MODULE_OPERATIONS) {
				selectedModuleName = "";
				setLasetSelectedModuleName();
			}
			temModules = modules.filter((f) => {
				return f.module === selectedModuleName;
			});
			if (temModules.length === 0) {
				temModules = [modules[0]];
			}

			selectedModuleName = getStringParam(app.selectedModuleName);
			if (selectedModuleName === "" && temModules.length > 0) {
				let moduleName = temModules[0].module;
				let moduleItems = temModules[0].module_items;
				dispatch(
					setSelectedModuleItems({
						moduleName: moduleName,
						moduleItems: moduleItems,
					})
				);
			}

			let createdAt = me.created_on;
			let date = new Date(createdAt);
			let dtstring =
				date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

		}
	},[app])

	if (
		(localStorage.getItem("token") === null || app.isInvalidToken === true) &&
		window.location.hash.search("audit-questions") === -1 &&
		window.location.hash.search("audit-answers-attachment") === -1 &&
		window.location.hash.search("mms-attachment") === -1 &&
		window.location.hash.search("compose-mail") === -1 &&
		window.location.hash.search("mobile-audit-questions") === -1 &&
		window.location.hash.search("intake-form") === -1 &&
		window.location.hash.search("changepassword") === -1 &&
		window.location.hash.search("thankyou") === -1
	) {
		return <Login />;
	}

	return (
		<>
			<Routes>
				<Fragment>
					<Route
						path="/previewemailtemplate/:id"
						element={<PreviewTemplate />}
					/>
					<Route
						path="/previewtemplates/:id"
						element={<PreviewTemplates />}
					/>
					<Route
						path="/previewAgreementTemplate/:id"
						element={<PreviewAgreementTemplate />}
					/>
					<Route
						path="/PreviewNewsTemplate/:id"
						element={<PreviewNewsTemplate />}
					/>
					<Route
						path="/previewbroadcasttemplate/:id"
						element={<PreviewBroadcast />}
					/>
					<Route
						path="/previewpagetemplate/:id"
						element={<PageTemplatePreview />}
					/>
					<Route
						path="/previewNews/:franchisor/:id"
						element={<PreviewNews />}
					/>
					<Route path="/logout" element={<Logout />} />
					<Route path="/audit-questions" element={<AuditQuiz />} />
					<Route path="/audit-answers-attachment" element={<AuditAnswersAttachments />} />
					<Route path="/mms-attachment" element={<MMSAttachments />} />
					<Route path="/mobile-audit-questions" element={<MobileAuditQuiz />} />
					<Route path="/compose-mail" element={<ComposeMobileEmail />} />
					<Route path="/intake-form" element={<IntakeForm />} />
					<Route path="/changepassword/:eKey" element={<ChangePassword />} />
					<Route path="/thankyou/:eKey/:tType" element={<ThumbSurvey />} />
					<Route path="/quicksight/expand/:gadget/:projectId/:object/:fieldName/:fieldValue/:dateRange"element={AWSReportContainer}/>
					<Route path="/"  element={<RedirectComponent url={app.redirectUrl} />} />
					<Route  path="/:object/:suffix"  element={<RedirectComponent url={app.redirectUrl} />} />
					<Route path="/:object/:suffix/:id" element={<RedirectComponent url={app.redirectUrl} />} />
						
				</Fragment>
			</Routes>
		</> 
	);
};

export default App;
