import { getLocalizedStrings } from "../../../../services/constants/MultiLingual"

export const customerDetailsSection1 = [
    {
        id: 'si1',
        section: 1,
        dataType: 'input',
        name: 'legal_entity_name',
        value: '',
        type: 'text',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.LEGAL_ENTITY_NAME,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si2',
        section: 1,
        dataType: 'input',
        name: 'website_url',
        value: '',
        type: 'text',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.WEBSITE_URL,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si3',
        section: 1,
        dataType: 'input',
        name: 'first_name',
        value: '',
        type: 'text',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.FIRST_NAME,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si4',
        section: 1,
        dataType: 'input',
        name: 'last_name',
        value: '',
        type: 'text',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.LAST_NAME,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si5',
        section: 1,
        dataType: 'input',
        name: 'email_address',
        value: '',
        type: 'text',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.EMAIL_ADDRESS,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si6',
        section: 1,
        dataType: 'input',
        name: 'phone_number',
        value: '',
        type: 'tel',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.PHONE_NUMBER,
        required: true,
        multiline: false,
        max_lines: 1,
    },
]
    
export const customerDetailsSection2 = [
    {
        id: 'si7',
        section: 21,
        dataType: 'dropdown',
        dropDownName: 'country',
        dropDownValue: '',
        type: 'select',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.COUNTRY,
        options:[],
        required: true,
        
    },
    {
        id: 'si8',
        section: 2,
        dataType: 'input',
        name: 'address',
        value: '',
        type: 'text',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.ADDRESS,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si9',
        section: 21,
        dataType: 'dropdown',
        dropDownName: 'state',
        dropDownValue: '',
        type: 'select',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.STATE,
        options:[],
        required: true,
    },
    {
        id: 'si10',
        section: 21,
        dataType: 'input',
        name: 'city',
        value: '',
        type: 'text',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.CITY,
        required: true,
        multiline: false,
        max_lines: 1,
    },    
    {
        id: 'si11',
        section: 21,
        dataType: 'input',
        name: 'zip',
        value: '',
        type: 'tel',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.ZIP,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si12',
        section: 21,
        dataType: 'dropdown',
        dropDownName: 'business_registration_identifier',
        dropDownValue: '',
        type: 'select',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.BUSINESS_REGISTRATION_IDENTIFIER,
        options:['EIN','CBN'],
        required: true,       
    },
    {
        id: 'si13',
        section: 21,
        dataType: 'input',
        name: 'business_registration_number',
        value: '',
        type: 'tel',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.BUSINESS_REGISTRATION_NUMBER,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si14',
        section: 21,
        dataType: 'dropdown',
        dropDownName: 'business_type',
        dropDownValue: '',
        type: 'select',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.BUSINESS_TYPE,
        options:['Partnership','Corporation','Co-operative','Limited Liability Corporation','Non-profit Corporation'],
        required: true,       
    },
    {
        id: 'si15',
        section: 21,
        dataType: 'dropdown',
        dropDownName: 'business_industry',
        dropDownValue: '',
        type: 'select',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.BUSINESS_INDUSTRY,
        options:['Automotive','Agriculture','Banking','Construction','Consumer','Education','Engineering',
                 'Energy','Oil & Gas','Fast moving consumer goods','Financial','Fintech','Food & Beverage',
                 'Government','Healthcare','Hospitality','Insurance','Legal','Manufacturing','Media','Online',
                 'Professional Services','Raw Materials','Real Estate','Religion','Retail','Jewelry','Technology',
                 'Telecommuication','Transportation','Travel','Electronics','Not for profit'],
        required: true,       
    },
    // {
    //     id: 'si16',
    //     section: 21,
    //     dataType: 'input',
    //     name: 'business_identity',
    //     value: '',
    //     type: 'input',
    //     label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.BUSINESS_IDENTITY,
    //     required: true,
    //     multiline: false,
    //     max_lines: 1,
    // },
    {
        id: 'si17',
        section: 21,
        dataType: 'input',
        name: 'business_title',
        value: '',
        type: 'input',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.BUSINESS_TITLE,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si18',
        section: 21,
        dataType: 'dropdown',
        dropDownName: 'job_position',
        dropDownValue: '',
        type: 'select',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.JOB_POSITION,
        options:['Other','Director', 'GM',' VP', 'CEO', 'CFO', 'General Counsel'],
        required: true,       
    }

]

export const customerDetailsHeaderText = {
    SECTION2: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.BUSINESS_LOCATION
}