import * as React from 'react';
import { connect } from 'react-redux';
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { isValidParam, getArrayParam, getBooleanParam, getStringParam } from '../../../services/helper/parameterVerifier';
import { getListViewData, removeScrollPosition } from '../../../services/actions/listViewAcions';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getActiveTab, getActiveTabInfo, updateActiveTab } from '../../../services/helper/sfTabManager';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import ShowCircularProgress from '../components/circularProgress';
import { sortArrayObjectByProperty, getMultilingualLabelName } from '../../../services/helper/utils';
import _ from 'lodash';

const dataTarget = {
  canDrop(props, monitor) {
    return true;
  },

  hover(props, monitor, component) {
    const draggingItem = monitor.getItem();
    if (isValidParam(props.item))
      if (draggingItem.id !== props.item.key) {
        props.moveCard(props, draggingItem.id);
      }
  },

  drop(props, monitor, component) {
    console.log(props)
  }
}
const dataSource = {
  canDrag(props) {
    let isDragging = true;
    if (props.item.key.toLowerCase() === 'all') {
      isDragging = false;
    }
    return isDragging;
  },

  isDragging(props, monitor) {
    return monitor.getItem().id === props.item.key;
  },

  beginDrag(props, monitor, component) {
    const item = { id: props.item.key }
    return item;
  },

  endDrag(props, monitor, component) {

  }
}
/*function collectDragSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }
}*/
/*function collectDragTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  }
}*/
const getGroupByInfoFromRedux = (state) => {
  let tabInfo = getActiveTabInfo();
  let isAssignRule = getBooleanParam(tabInfo.isAssignRule);
  if (getBooleanParam(isAssignRule)) {
    return state.assignListView.groupByInfo;
  } else {
    return state.listView.groupByInfo;
  }
}

const getIsGroupByMountedFromRedux = (state) => {
  let tabInfo = getActiveTabInfo();
  let isAssignRule = getBooleanParam(tabInfo.isAssignRule);
  if (getBooleanParam(isAssignRule)) {
    return state.assignListView.isGroupByMounted;
  } else {
    return state.listView.isGroupByMounted;
  }
}
const mapStateToProps = (state, props) => {
  return {
    groupByInfo: getGroupByInfoFromRedux(state),
    isGroupByMounted: getIsGroupByMountedFromRedux(state),
    app: state.app,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getListViewData: (object, params) => {
      dispatch(getListViewData(object, params));
    },
    showCustomSnackBar: (message, bodyStyle, style) => {
      dispatch(showCustomSnackBar(message, bodyStyle, style));
    },
    removeScrollPosition: (obj) => {
      dispatch(removeScrollPosition(obj))
  }
  }
};

connect(mapStateToProps, mapDispatchToProps)
class SfListBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataGet: false,
      mounted: false,
      fixtDataItems: [],
      dataItems: [],
      dataItemsFix: [],
      data: null,
      isEdit: false,
      group_by_condition: null,
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let isEqual = _.isEqual(this.props, nextProps);
    if (!isEqual) {
      let groupByValues = getArrayParam(nextProps.groupByInfo.group_by);
      this.setState({ data: nextProps.groupByInfo.group_by })
      this.state.group_by_condition=nextProps.groupByInfo.group_by_condition;
      this.processDatas(groupByValues);
    }
  }
  componentDidUpdate() {
    let groupByValues = getArrayParam(this.props.groupByInfo.group_by);
    if (this.state.dataItems.length === 0 && groupByValues.length > 0 && this.props.groupByInfo.object === this.props.object) {
      this.state.group_by_condition=this.props.groupByInfo.group_by_condition;

      this.setState({ data: this.props.groupByInfo.group_by})
      this.processDatas(groupByValues);
    }
  }

  componentDidMount() {
    let groupByValues = getArrayParam(this.props.groupByInfo.group_by);
    if (this.state.dataItems.length === 0 && groupByValues.length > 0 && this.props.groupByInfo.object === this.props.object) {
      this.setState({ data: this.props.groupByInfo.group_by,dataGet: true})
      this.state.group_by_condition=this.props.groupByInfo.group_by_condition;
      this.processDatas(groupByValues);
    }
  }

  moveCard = (props, dataId) => {
    const { columnIndex } = props
    let dataItems = getArrayParam(this.state.dataItems);
    let tempDataItems = dataItems.filter(m => m.key === dataId);
    if (columnIndex < dataItems.length) {
      dataItems = dataItems.filter(m => m.key !== tempDataItems[0].key)
      dataItems = [
        ...dataItems.slice(0, columnIndex),
        tempDataItems[0],
        ...dataItems.slice(columnIndex)
      ]
    }

    dataItems.forEach((elem, idx) => {
      dataItems[idx].orderId = idx + 1
    })
    this.setState({ dataItemsFix: dataItems }, () => { this.updateDataItemOrder(dataItems) })

  }
  updateDataItemOrder = (data) => {

    try {
      let tempData = [];
      data = getArrayParam(data);
      data.forEach((element) => {
        let obj = {};
        obj.name = element.name;
        obj.orderId = element.orderId;
        tempData.push(obj);
      })
      if (tempData.length > 0) {
        let url = null;
        let params = {};
        if (this.props.object === constants.AUDIT_QUESTIONS_OBJECT) {
          url = endPoints.AUDIT_QUESTIONS.REARRANGE_CATEGORY;
          params.categories = tempData;
        }

        HTTPClient.post(url, params)
          .then(res => {
            if (res.status === 0) {
              this.props.showCustomSnackBar(getLocalizedStrings().message.AUDIT_QUESTIONS.SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
            } else {
              this.props.showCustomSnackBar(getLocalizedStrings().message.AUDIT_QUESTIONS.ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            }
          })
      }
    } catch (error) {
      console.error("Error in 'sfListBox.js -> updateDataItemOrder()':" + error);
    }
  }
  processDatas = (groupByValues) => {
      let arr = getArrayParam(groupByValues);
      let _state = this.state;
      if (isValidParam(arr) && arr.length > 0) {
        let _fixtDataItems = [];
        if (this.props.object === constants.AUDIT_QUESTIONS_OBJECT) {
          _fixtDataItems = arr.filter(ar => ar.label === 'All');
          _fixtDataItems = _fixtDataItems.map((m, i) => { return { key: m.label, name: m.label, recordCount: m.record_count, canDelete: false, orderId: i } });
        }
        let marr = arr.filter(ar => ar.label !== 'All');
        let tempArr = marr.map((m, i) => { return { key: m.label, name: m.label, recordCount: m.record_count, canDelete: false, orderId: i } });
        _state.fixtDataItems = _fixtDataItems;
        _state.dataItems = tempArr;

      }
      _state.mounted = true;
      this.setState(_state);
  }
  listViewCallBack = (value) => {
    if (isValidParam(value)) {
      let tab = getActiveTab();
      let group_by_condition = getStringParam(value.name);
      let params = { "group_by_condition": group_by_condition, "query_name": "All Audit Questions", "query_type": "query" };
      this.props.groupByInfo.group_by_condition = group_by_condition;
      tab.info = { ...tab.info, groupByCondition: group_by_condition };
      delete tab.info.startIndex;
      delete tab.info.pageSize;
      delete tab.info.selectedPageNo;
      updateActiveTab(tab);
      this.setState({ group_by_condition: group_by_condition });
      this.props.removeScrollPosition(this.props.object);
      this.props.listViewCallBack(this.props.object, params, true);
    }
  }
  getDataContainer = (contentHeight) => {
    let themeColor = this.props.app.me.background;
    let element = [];
    let dataItems = getArrayParam(this.state.dataItems);
    let dataContainerLabel = isValidParam(this.props.groupByInfo.group_by_field) ? this.getFieldLabelByFieldName(this.props.groupByInfo.group_by_field) : 'category';

    sortArrayObjectByProperty(dataItems, 'orderId');
    let props = Object.assign({}, this.props);
    try {
      props.moveCard = this.moveCard;
      element =
      <div>
        <div title={dataContainerLabel} style={{ marginLeft: '18px', paddingTop: '0px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', cursor: "default", fontSize: '16px', paddingLeft: '1px', fontWeight: 'bold' , height: '35px' }}>
            <div style={{ maxWidth: '90%', float: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{dataContainerLabel}</div>
          </div>
        <div id="module-config-id" className="" style={{ padding: '0px 10px', height: contentHeight, overflow: 'auto' }}>
          
          <div>
            <div>
              {this.state.fixtDataItems.map(item => (
                <div id={`item-` + item.key} onClick={(e) => this.listViewCallBack(item)} style={{ height: '30px', marginTop: '0px', backgroundColor: '', cursor: 'pointer' }}>
                  <div className="item-container" style={{ opacity: 1, height: '100%' }}>
                    <div className="item-content" style={{ padding: getBooleanParam(this.state.isEdit) ? '0px 10px' : '5px 10px' }}>
                      <div title={item.name} style={{ width: 'auto', float: 'left', color: item.name === this.state.group_by_condition ? themeColor : '#717171', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '14px' }}>{item.name}<span>&nbsp;{'(' + item.recordCount + ')'}</span></div>
                    </div>
                  </div>
                </div>))}
                {this.state.dataItems.map(item => (
                <div id={`item-` + item.key} onClick={(e) => this.listViewCallBack(item)} style={{ height: '30px', marginTop: '0px', backgroundColor: '', cursor: 'pointer' }}>
                  <div className="item-container" style={{ opacity: 1, height: '100%' }}>
                    <div className="item-content" style={{ padding: getBooleanParam(this.state.isEdit) ? '0px 10px' : '5px 10px', display:'flex' }}>
                      <div title={item.name} style={{ width: 'auto', float: 'left', color: item.name === this.state.group_by_condition ? themeColor : '#717171', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '14px' }}>
                        {item.name}
                        </div>
                        <span style={{ color: item.name === this.state.group_by_condition ? themeColor : '#717171',  fontSize: '14px' }}>&nbsp;{'(' + item.recordCount + ')'}</span>
                    </div>
                  </div>
                </div>))}
            </div>

            <DataContainer
              key={'module-config-key'}
              {...props}
              listViewCallBack={(value) => this.listViewCallBack(value)}
              dataItems={this.state.dataItems}
              selecteItem={this.state.group_by_condition}
            />

          </div>
        </div></div>
    } catch (error) {
      console.error("Error in 'sfListBox.js -> getDataContainer()':" + error);
    }
    return element;
  }
  getFieldLabelByFieldName = (fieldName) => {
    let fields = getArrayParam(this.props.groupByInfo.fields);
    fields = fields.filter(f => { return isValidParam(f) && f.name === fieldName });
    let label = fields.length > 0 ? fields[0].label : '';
    let object = this.props.object;
    label = getMultilingualLabelName(object, label);
    return label;
  }
  render() {
    let contentHeight = window.innerHeight - 250;
    let top = (contentHeight - 10) / 2;
    return (
      <div>
        {this.state.mounted ?
          <div>{this.getDataContainer(contentHeight)}</div>
          :
          <div style={{ width: '100%', height: contentHeight }}>
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
              <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
              </div>
            </div>
          </div>}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SfListBox);

connect(mapStateToProps, mapDispatchToProps)
//DropTarget('data', dataTarget, collectDragTarget)
class DataContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      section: null,
      dataItems: [],
    }
  }

  UNSAFE_componentWillMount() {
    this.generateData(this.props);
  }

  componentDidMount() {
    console.log("DataContainer==============>")
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let isEqual = _.isEqual(this.props, nextProps);
    if (!isEqual) {
      this.generateData(nextProps);
    }
  }


  generateData = (props) => {
    try {
      this.setState({ dataItems: props.dataItems });
    } catch (error) {
      console.error("Error in 'sfListBox.js -> DataContainer.js -> generateData()':" + error);
    }
  }



  render() {
    //const { connectDropTarget } = this.props;
    let props = Object.assign({}, this.props);
    let allDataItems = getArrayParam(this.state.dataItems);

    return (
      <div>
        {allDataItems.length > 0 ?
          <div id={"desk-items-id"} >
            {allDataItems.forEach((item, index) => {
              if (isValidParam(item)) {
                return (<ModuleDataItem
                  item={item}
                  dataItems
                  listViewCallBack={(value) => this.props.listViewCallBack(value)}
                  {...props}
                  columnIndex={index}
                  selecteItem={this.props.selecteItem}
                  key={'desk-items-id-' + index} 
                  />);
              }
            })}
          </div> :
         // connectDropTarget(
          <div style={{ height: '100%', textAlign: 'center', fontSize: '14px' }}>Empty</div>
         // )
          }

      </div>
    )
  }
}

connect(mapStateToProps, mapDispatchToProps)
//DropTarget('data', dataTarget, collectDragTarget)
//DragSource('data', dataSource, collectDragSource)
class ModuleDataItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      item: null,
      dataItems: [],
      prevItem: {},
      isEdit: false
    }
  }

  UNSAFE_componentWillMount() {
    let item = this.props.item;
    let _dataItems = this.props.dataItems;
    this.setState({ item: Object.assign({}, item), prevItem: Object.assign({}, item), dataItems: _dataItems });
  }

  componentDidMount() {
    console.log("this.props from ModuleItem=======>",this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    let isEqual = _.isEqual(this.state.prevItem, nextProps.item);
    if (!isEqual) {
      this.setState({ item: Object.assign({}, nextProps.item), prevItem: Object.assign({}, nextProps.item), dataItems: nextProps.dataItems });

    }
  }

  render() {
    const { connectDropTarget, connectDragSource, isDragging } = this.props;
    let themeColor = this.props.app.me.background;
    let item = this.state.item;
    let opacity = isDragging ? 0.5 : 1;
    let isSelected = item.name === this.props.selecteItem ? true : false;
    return (
      connectDragSource(connectDropTarget(
        <div id={`item-` + item.key} onClick={(e) => this.props.listViewCallBack(item)} style={{ height: '30px', marginTop: '0px', backgroundColor: isDragging ? '#efefef' : '', cursor: 'pointer' }}>
          <div className="item-container" style={{ opacity, height: '100%' }}>
            <div className="item-content" style={{ padding: getBooleanParam(this.state.isEdit) ? '0px 10px' : '5px 10px' }}>
              <div title={item.name} style={{ ...styles.row, width: 'auto', color: isSelected ? themeColor : '#717171', padding: 0 }}><span style={{ maxWidth: '100%', float: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '14px' }}>{item.name}</span><span>&nbsp;{'(' + item.recordCount + ')'}</span></div>
            </div>
          </div>
        </div>
      ))
      );
  }
}
