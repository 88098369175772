import React, { useState, useEffect,useMemo } from 'react';
import { styles } from '../../../services/constants/styles';
import { isValidParam, getObjectParam, getArrayParam, getStringParam, getIntParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import GroupByValues from '../components/groupByValues';
import { getActiveTab, updateTabInfo, getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { removeScrollPosition } from '../../../services/actions/listViewAcions';
import { constants } from '../../../services/constants/constants';
import { getMultilingualLabelName } from '../../../services/helper/utils';
import { setCookie } from '../../../services/helper/sfCookies';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import ShowCircularProgress from '../components/circularProgress';
import PopOver from '../components/PopOver';
import { useDispatch, useSelector } from "react-redux";


const getGroupByInfoFromRedux = (state,suffix) => {
    let tabInfo = getActiveTabInfo();
    let isAssignRule = getBooleanParam(tabInfo.isAssignRule);
    if (getBooleanParam(isAssignRule)) {
        return state.assignListView.groupByInfo;
    } else if (state.childListView.data.object === constants.AUDIT_ANSWERS_OBJECT && tabInfo.isAuditAnswerChild) {
        return state.childListView.groupByInfo;
    }  else if (getBooleanParam(suffix === constants.TASK_LIST)) {
        return state.detailViewTaskList.groupByInfo;
    } else {
        return state.listView.groupByInfo;
    }
}

const getIsGroupByMountedFromRedux = (state,suffix) => {
    let tabInfo = getActiveTabInfo();
    let isAssignRule = getBooleanParam(tabInfo.isAssignRule);
    if (getBooleanParam(isAssignRule)) {
        return state.assignListView.isGroupByMounted;
    } else if (state.childListView.data.object === constants.AUDIT_ANSWERS_OBJECT && tabInfo.isAuditAnswerChild) {
        return state.childListView.isGroupByMounted;
    }  else if (getBooleanParam(suffix === constants.TASK_LIST)) {
        return state.detailViewTaskList.groupByInfo;
    } else {
        return state.listView.isGroupByMounted;
    }
}

const GroupByFieldComponent = ({ object, suffix, listViewCallBack }) => {

    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const groupByInfo = useSelector((state) => getGroupByInfoFromRedux(state,suffix));
    const isGroupByMounted = useSelector((state) => getIsGroupByMountedFromRedux(state ,suffix));
    const childListVIew = useSelector((state) => state.childListView);

    const [isGroupByOpen, setIsGroupByOpen] = useState(false);
    const [groupByFieldName, setGroupByFieldName] = useState(null);



  
    const getGroupByFieldItems = () => {
        let themeColor = app.me.background;
        let tabInfo = getActiveTabInfo();
        let groupByFieldName = tabInfo?.groupByFieldName ? getStringParam(tabInfo.groupByFieldName) : getStringParam(groupByInfo.group_by_field)
        setGroupByFieldName(groupByFieldName);
        let groupByFieldItems = [];
        if (isValidParam(groupByInfo.fields)) {
            let fields = groupByInfo.fields;
            fields.sort(function (a, b) {
                return a.label.localeCompare(b.label);
            })
            fields.forEach((field, i) => {

                let labelStyle = { color: '#666666',  fontSize: '12px',};
                labelStyle = field.name === groupByFieldName ? {...labelStyle, color: themeColor} : {...labelStyle}
                let data = {};
                data.label = getMultilingualLabelName(object, field.label);
                data.value = field.name;
                data.labelStyle = labelStyle;
                groupByFieldItems.push(data);

            });
        }
        return groupByFieldItems;
    }

    const changeGroupByField = (value) => {
        let groupByCond = null;
        try {
            let tabinfo = getObjectParam(getActiveTabInfo());
            delete tabinfo.groupByCondition;
            if (value === 't_status' && (object === constants.WORKFLOW_OBJECT || object === constants.SALES_TEMPLATE_OBJECT)) {
                groupByCond = 'Active';
            }
            updateTabInfo(tabinfo);
            setIsGroupByOpen(false);
            dispatch(removeScrollPosition(object));
            getListView(value, groupByCond);
        } catch (error) {
            console.error("error in 'groupByFieldComponent.js' --> 'changeGroupByField()':" + error);
        }
    }

    const callBack = (groupByCond, recordCount) => {
        try {
            let tabinfo = getObjectParam(getActiveTabInfo());
            let groupByFieldName = getStringParam(groupByInfo.group_by_field);
            dispatch(removeScrollPosition(object));
            getListView(groupByFieldName, groupByCond, recordCount);
            if (object === constants.WORKFLOW_OBJECT) {
                let groupByField = { object: object, groupByCondition: groupByCond, groupByFieldName: tabinfo.groupByFieldName };
                setCookie('groupBy_' + object + '_' + tabinfo.groupByFieldName + '_' + app.me.projectId, JSON.stringify(groupByField));
            }
        } catch (error) {
            console.error("error in 'groupByFieldComponent.js' --> 'callBack()':" + error);
        }
    }

    const getListView = (fieldName, groupByCond, recordCount) => {
        try {
            let filterObj = {};

            let activeTab = getActiveTab();
            activeTab = getObjectParam(activeTab);
            let info = getObjectParam(activeTab.info);
            info.groupByFieldName = fieldName;

            if (isValidParam(groupByCond) && groupByCond !== '') {
                filterObj.group_by_condition = groupByCond;
                info.groupByCondition = groupByCond;
                info.groupByConditionTotalCount = getIntParam(recordCount);
            } else {
                delete info.groupByCondition;
                delete info.groupByConditionTotalCount;
            }
            delete info.startIndex;
            delete info.pageSize;
            delete info.selectedPageNo;
            updateTabInfo(info);

            listViewCallBack(object, filterObj, true);
        } catch (error) {
            console.error("Error in 'groupByFieldComponent.js -> getListView()':" + error);
        }
    }


    const getFieldLabelByFieldName = (fieldName) => {
        let fields = getArrayParam(groupByInfo.fields);
        if (object === constants.AUDIT_ANSWERS_OBJECT) {
            fields = getArrayParam(childListVIew.data.fields);
        }
        fields = fields.filter(f => { return isValidParam(f) && f.name === fieldName });
        let label = fields.length > 0 ? fields[0].label : '';
        label = getMultilingualLabelName(object, label);
        return label;
    }


    let contentHeight = document.body.clientHeight - 240;
    let top = (contentHeight - 10) / 2;
    let isshowPopOver = true;
    let groupByFieldItems = useMemo(() => getGroupByFieldItems(), [isGroupByMounted]);
    if (isGroupByMounted) {
        let groupByCondition = getStringParam(groupByInfo.group_by_condition);
        let groupByValues = getArrayParam(groupByInfo.group_by);
        let selectedObject;
        if (isValidParam(app.selectedSearchObject) && isValidParam(app.selectedSearchObject[app.selectedModuleName])) {
            let selectedObjectSet = app.selectedSearchObject[app.selectedModuleName];
            selectedObject = [...selectedObjectSet][0];
        }
        let dataContainerLabel = isValidParam(groupByInfo.group_by_field) ? getFieldLabelByFieldName(groupByInfo.group_by_field) : 'category';
        if (selectedObject === 'Unit’s Accounts') {
            isshowPopOver = false;
        }
        if (object === constants.AUDIT_ANSWERS_OBJECT) {
            isshowPopOver = false;
        }
        let style;
        if (object === constants.WORKFLOW_OBJECT) {
            style= {height:"27px", marginTop:'10px', fontWeight:'bold'}
        }
        
        if (groupByValues.length > 0) {
            groupByValues = groupByValues.map((obj) => {
                let label = obj.label === 'All' ? getLocalizedStrings().label.COMMON.ALL : obj.label;
                return { record_count: obj.record_count, label: label }
            });
            return (
                <div style={{ marginLeft: '5px', marginRight: '5px' }}>
                    {isshowPopOver &&
                        <div style={{ marginLeft: '25px', paddingTop: '0px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', cursor: "pointer", fontSize: '16px', paddingLeft: '1px' }}

                            title={getFieldLabelByFieldName(groupByFieldName)}>
                            <div>
                                <div style={{ maxWidth: '70%', float: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{getFieldLabelByFieldName(groupByFieldName)}</div>
                                <PopOver
                                    id={'modules'}
                                    key={'modules'}
                                    name={'modules'}
                                    buttonEndIcon={"keyboard_arrow_down"}
                                    buttonStyle={{ fontWeight: 'bold', paddingLeft: '2px', paddingTop: '.5px' }}
                                    menuItemStyle={style}
                                    labelposition={"before"}
                                    buttonLabel={""}
                                    options={groupByFieldItems}
                                    onclickAction={(value) => changeGroupByField(value)}
                                    isShowIcon={true}
                                    buttonLineOrigin={[12, 65]}
                                />

                            </div>

                        </div>
                    }
                    {!isshowPopOver &&
                        <div title={dataContainerLabel} style={{ marginLeft: '10px', paddingTop: '0px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', cursor: "default", fontSize: '16px', paddingLeft: '1px', fontWeight: 'bold' }}>
                            <div style={{ maxWidth: '90%', float: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{dataContainerLabel}</div>
                        </div>
                    }
                    <div style={{ marginTop: '20px' }}>
                        <GroupByValues groupByCondition={groupByCondition} groupByValues={groupByValues} object={object} callBack={(groupByCond, recordCount) => callBack(groupByCond, recordCount)} />
                    </div>
                </div>
            );
        } else {
            return <div />
        }
    } else {
        return (
            <div style={{ marginLeft: '10px', height: contentHeight, overflowY: 'scroll' }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '40%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }
};

export default GroupByFieldComponent;

