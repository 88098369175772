import { actions } from '..//constants/actions';
import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';

function get(id) {
    return {
        type: actions.AUTOMATION_CAMPAIGN_GET,
        payload: HTTPClient.get(endPoints.AUTOMATION_DESIGNER.AUTOMATION_CAMPAIGN_GET + "/" + id, null)
    }
}

function setJSPlumbInst(jsPlumbInst) {
    return {
        type: actions.AUTOMATION_CAMPAIGN_SET_JSPLUMBINST,
        payload: jsPlumbInst
    }
}

// function getWebFormList() {
//     let params = null;
//     params = {}
//     return {
//         type: actions.AUTOMATION_CAMPAIGN_WEBFORM_GET,
//         payload: HTTPClient.get()
//     }
// }

function setTemplate(templateObject) {
    return {
        type: actions.AUTOMATION_CAMPAIGN_SET_TEMPLATE,
        payload: templateObject
    }
}

function addNode(arrayNodes) {
    return {
        type: actions.AUTOMATION_CAMPAIGN_ADD_NODE,
        payload: arrayNodes
    }
}

function setMounted(data) {
    return {
        type: actions.AUTOMATION_CAMPAIGN_SET_MOUNTED,
        payload: data
    };
}

export { get, setJSPlumbInst, setTemplate, addNode, setMounted }