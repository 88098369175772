import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';

const getDatabaseSetupInfo = () => {
    let url = null;
    var promise = null;
    try {
        url = endPoints.HOME.DATABASE_SETUP_GET;
        promise = Promise.resolve(HTTPClient.get(url, {}));
    } catch (error) {
        console.error("Error in 'databaseActions.js -> getDatabaseSetupInfo()':" + error);
    }
    return promise;
}

const saveDatabaseSetupInfo = (arrSetupData) => {
    let url = null;
    var promise = null;
    try {
        url = endPoints.HOME.DATABASE_SETUP_SAVE;
        promise = Promise.resolve(HTTPClient.post(url, arrSetupData));
    } catch (error) {
        console.error("Error in 'databaseActions.js -> saveDatabaseSetupInfo()':" + error);
    }
    return promise;
}

export {
    getDatabaseSetupInfo,
    saveDatabaseSetupInfo
}