
import React from 'react';
import { connect } from 'react-redux';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { matrixReportInitialData, MatrixReportTable } from '../components/ReportDataTable';
import { isValidParam, getObjectParam,} from '../../../services/helper/parameterVerifier';
import { constants } from '../../../services/constants/constants';
import { addTab, TYPE_LIST_VIEW, getActiveTab, updateTabInfo } from '../../../services/helper/sfTabManager';
import { getAppDialog, getAppDrawer } from '../../../services/actions/appContainerActions';
import DashBoardLinkClickInfo from '../components/DashBoardLinkClickInfo';
import ListView from '../containers/ListView';
import { getListViewData } from '../../../services/actions/listViewAcions';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { Icon, Button } from '@mui/material';

/**
 * 
 * @Author  : Pravat.Jana 
 * @Date:   : 2017-08-30
 */

const mapStateToProps = (state) => {
    return {
        app: state.app,
        appContainer: state.appContainer,
        attachmentUpload: state.attachmentUpload,
        sendMail: state.sendMail
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAppDialog: (isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom) => {
            dispatch(getAppDialog(isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom));
        },

        getAppDrawer: (isOpen, actionDialogName, actionType, data, callFrom) => {
            dispatch(getAppDrawer(isOpen, actionDialogName, actionType, data, callFrom));
        }
    }
};

connect(mapStateToProps, mapDispatchToProps)
class DashBoardEmailSummery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            emailSummeryData: {
                column: [],
                data: [],
                footer: []
            },
            isShowLinkClickChild: false,
            clickStatsId: 0
        };
    }

    componentDidMount() {
        let event_type = this.props.data.event;
        let timeLine = this.props.data.timeLine;
        // data.timeLine = localStorage.getItem('dashboardTimeline');
        let promise = Promise.resolve(HTTPClient.get(endPoints.DASHBOARD.DASHBOARD_EMAIL_SUMMERY, { "eventtype": event_type, "timeline": timeLine }));
        promise.then((response) => {

            let emailSummeryData = response;
            let tableData = emailSummeryData.data;
            let column = emailSummeryData.column;
            column.forEach((key, value) => {
                if (key.name === 'campaignName') {
                    key.label = getLocalizedStrings().label.CAMPAIGNS.CAMPAIGN_NAME;
                } else if (key.name === 'campaign_type') {
                    key.label = getLocalizedStrings().label.CAMPAIGNS.CAMPAIGN_TYPE;
                } else if (key.name === 't_subject') {
                    key.label = getLocalizedStrings().label.CAMPAIGNS.SUBJECT;
                } else {
                    key.label = getLocalizedStrings().label.CAMPAIGNS.hasOwnProperty(key.label.toUpperCase()) ? getLocalizedStrings().label.CAMPAIGNS[key.name.toUpperCase()] : key.label;
                }
            });
            // let tableData = emailSummeryData.tableBodyData;
            // let column = emailSummeryData.tableHeaderData;
            let configData = JSON.parse(JSON.stringify(matrixReportInitialData));
            configData.column = column;
            configData.data = tableData;
            column[3].isDetailsLink = true;
            matrixReportInitialData.reportName = ''// "Marketing Email Summery";
            this.setState({ emailSummeryData: configData });
        });

    }

    drillDownMatrixReport = (reportName, clickPosition, headerData, rowJsonData, footerData, clickJsonFieldName) => {
        // drilldownMarketingReport
        if (this.props.data.event !== 'Clicked') {
            this.drilldownMarketingReport(rowJsonData.id, this.props.data.event, rowJsonData.campaign_type);
        } else {

            this.setState({ isShowLinkClickChild: true, clickStatsId: rowJsonData.id });

        }
    }

    drilldownMarketingReport = (statsId, eventType, reportType) => {
        try {
            let params = {
                name: 'All records',
                tableId: 9,
            }
            let labelEventType = '';

            if (eventType === "Opened") {
                eventType = 'open';
                labelEventType = 'Opened';
            } else if (eventType === "Bounced") {
                eventType = 'Bounce';
                labelEventType = 'Bounced';
            } else if (eventType === "Unsubscribed") {
                eventType = 'Unsubscribed';
                labelEventType = 'Unsubscribed';
            } else if (eventType === "Sent") {
                eventType = 'Sent';
                labelEventType = 'Sent';
            } else {
                labelEventType = eventType;
            }

            var promise = Promise.resolve(HTTPClient.get(endPoints.REPORTS.QUERY_DETAIL, params));
            promise.then((response) => {
                if (isValidParam(response)) {

                    let label = "";
                    label = labelEventType + ' ' + reportType + " Report";
                    let url = "/" + constants.CONTACTS_OBJECT + "/queries";
                    this.openTab(label, constants.CONTACTS_OBJECT, response.query_id, "", url, statsId, eventType.toLowerCase());
                }
            });
        } catch (error) {
            console.error("Error in 'BroadcastCampaign.js -> drilldownMarketingReport()':" + error);
        }
    }

    openTab = (label, component, queryId, imgName, url, statsId, eventType) => {
        let filter = {
            id: queryId,
            name: '',
            type: constants.TYPE_AUTORESPONDER_REPORT,
        }
        let tab = {
            label: label,
            object: component,
            type: TYPE_LIST_VIEW,
            imgName: imgName,
            info: {
                filter: filter,
                input_param: {
                    stat_id: statsId,
                    event_type: eventType,
                },
                isReport: true,
                reportFieldValue: eventType,
                reportLabel: label
            },
            url: url,
            isActive: true,

        };

        addTab(tab, true);
        this.props.getAppDrawer(false, null, null, null, null);
    }

    callParentFunction = () => {
        this.setState({ isShowLinkClickChild: false });
    }

    render() {
        if (this.state.isShowLinkClickChild) {
            return (
                <div>
                    < DashBoardLinkCick callParentFunction={this.callParentFunction} clickStatsId={this.state.clickStatsId} />
                </div>
            );
        } else {
            return (
                <div className="col-md-12">
                    <MatrixReportTable config={this.state.emailSummeryData} drillDownMatrixReport={this.drillDownMatrixReport} />
                </div>
            );
        }

    }
}


class DashBoardLinkCick extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            isShowChildChild: false,
            clickStatsId: 0,
            clickedUrl: ''
        };
    }


    componentDidMount() {
        let clickStatsId = this.props.clickStatsId;
        let tab = getObjectParam(getActiveTab());
        this.setState({ clickStatsId: clickStatsId });
        let info = getObjectParam(tab.info);
        info.filter = {
            "id": 551,
            "name": "",
            //  "type": query_type
            "type": 77
        }
        info.input_param = {
            event_type: "click",
            stat_id: clickStatsId
        }
        info.event_type = "click";
        info.stat_id = clickStatsId;
        updateTabInfo(info);
    }

    componentDidUpdate() {

    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    showChild = () => {
        this.setState({ isShowChildChild: true });
    }

    callParentFunction = () => {
        this.setState({ isShowChildChild: false });
    }

    setClickUrl = (url) => {
        this.setState({ clickedUrl: url, isShowChildChild: true });
    }

    render() {

        if (this.state.isShowChildChild) {

            return (
                <div>

                    <DashBoardLinkCickListView callParentFunction={this.callParentFunction} clickedUrl={this.state.clickedUrl} />
                </div>
            )

        } else {

            return (
                <div>
                    <div style={{ marginLeft: '41px' }}>


                        {this.props.callFrom !== constants.DASHBOARD_TOP_MARKETING_EMAIL_BY_CTR &&
                            <Button variant="contained"

                                //  buttonStyle={{ backgroundColor: '#808080', color: '#FFF', width: '105px' }}
                                secondary={true}
                                buttonStyle={{ ...styles.listViewPrimaryButton, marginTop: '0px' }}
                                endIcon={<Icon>keyboard_arrow_left</Icon>}
                                onClick={this.props.callParentFunction}
                            >{getLocalizedStrings().label.COMMON.BACK}</Button>
                        }



                    </div>


                    <DashBoardLinkClickInfo setClickUrl={this.setClickUrl} />



                </div>
            );

        }
    }
}



function mapStateToPropsB(state) {
    return {

    };
}

function mapDispatchToPropsB(dispatch) {
    return {
         getListViewData: (object, params) => {
            dispatch(getListViewData(object, params));
        },
    }
}

connect(mapStateToPropsB, mapDispatchToPropsB)
class DashBoardLinkCickListView extends React.Component {
    constructor(props) {

        super(props);
        this.state = { isListviewMounted: false };
    }

    componentDidMount() {

        let params = {};
        let tab = getObjectParam(getActiveTab());
        let filter = getObjectParam(tab.info.filter);

        let clickedUrl = this.props.clickedUrl;
        params.query_id = filter.id;
        params.query_name = '';
        params.query_type = filter.type;
        params.stat_id = tab.info.input_param.stat_id;
        params.event_type = tab.info.input_param.event_type;
        params.url = clickedUrl;

        let info = getObjectParam(tab.info);
        info.input_param.url = clickedUrl;
        info.isReport = true;
        updateTabInfo(info);
        getListViewData(constants.CONTACTS_OBJECT, params);
        this.setState({ isListviewMounted: true });
    }

    componentDidUpdate() {

    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    render() {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        return (
            <div>
                <div style={{ marginLeft: '25px' }}>
                    <Button variant="contained"
                        buttonStyle={{ ...styles.listViewPrimaryButton, marginTop: '0px' }}
                        secondary={true}
                        // style={{  ...styles.primaryButton }}
                        endIcon={<Icon>keyboard_arrow_left</Icon>}
                        onClick={this.props.callParentFunction}
                    >{getLocalizedStrings().label.COMMON.BACK}</Button>
                </div>
                <br />
                {
                    this.state.isListviewMounted ?
                        <ListView object={constants.CONTACTS_OBJECT} isShowHeader={false} />
                        :
                        <div style={{ width: '100%', height: contentHeight }}>
                            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                </div>
                            </div>
                        </div>
                }
            </div>
        );
    }
}

export {
    DashBoardEmailSummery,
    DashBoardLinkCick

}