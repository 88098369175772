import { isValidParam, isInteger, isFloat, isEmail, isUrl, isEmpty, getBooleanParam, isValidEmail, getStringParam } from './parameterVerifier';
import { getLocalizedStrings } from '..//constants/MultiLingual';
import { constants } from '..//constants/constants';

const FORM_FIELD_TYPE = {
    UNIQUE: 'unique_id',
    TEXT: 'text',
    INTEGER: 'integer',
    FLOAT: 'float',
    DATE: 'date',
    DATE_AND_TIME: 'datetime',
    MEMO: 'memo',
    STATIC: 'static',
    CHECKBOX: 'checkbox',
    STATUS: 'status',
    URL: 'url',
    EMAIL: 'email',
    NOTIFY: 'notify',
    HIDDEN: 'hidden',
    LIST: 'list',
    LIST_INTEGER_TYPE: 'list-integer-type'
}

function isValidateEntry(content, formFieldType, isEmailField, maxLength, isRequiredField) {
    let returnObj = {};
    returnObj.isValid = false;
    returnObj.message = '';

    isRequiredField = getBooleanParam(isRequiredField);
    if (formFieldType === FORM_FIELD_TYPE.INTEGER) {
        if (isRequiredField && content === '') {
            returnObj.message = getLocalizedStrings().message.COMMON.VALID_INTEGER;
        } else if (content !== '' && !isInteger(content)) {
            returnObj.message = content + ' ' + getLocalizedStrings().message.COMMON.INTEGER;
        } else {
            returnObj.isValid = true;
        }
    } else if (formFieldType === FORM_FIELD_TYPE.FLOAT) {
        if (isRequiredField && content === '') {
            returnObj.message = getLocalizedStrings().message.COMMON.VALID_FLOAT;
        } else if (content !== '' && !isFloat(content)) {
            returnObj.message = content + ' ' + getLocalizedStrings().message.COMMON.FLOAT;
        } else {
            var floatValueArray = (content.toString()).split(".");
            var intSize = floatValueArray[0].length;
            if (intSize > maxLength - 3) {
                returnObj.message = getLocalizedStrings().message.COMMON.TOO_BIG_SIZE;
            } else {
                returnObj.isValid = true;
            }
        }
    } else if (isEmailField) {
        if (isRequiredField && content === '') {
            returnObj.message = getLocalizedStrings().message.COMMON.VALID_EMAIL;
        } else if (content !== '' && !isEmail(content)) {
            returnObj.message = content + ' ' + getLocalizedStrings().message.COMMON.EMAIL;
        } else {
            returnObj.isValid = true;
        }
    } else if (formFieldType === FORM_FIELD_TYPE.URL) {
        if (isRequiredField && content === '') {
            returnObj.message = getLocalizedStrings().message.COMMON.VALID_URL;
        } else if (content !== '' && !isUrl(content)) {
            returnObj.message = content + ' ' + getLocalizedStrings().message.COMMON.URL;
        } else {
            returnObj.isValid = true;
        }
    } else {
        returnObj.isValid = true;
    }

    return returnObj;
}

function isValidateFormField(object, formField, fieldForSave) {
    let label, value;
    let isValid = true;
    let maxLength = 0;
    try {
        if (isValidParam(formField) && isValidParam(fieldForSave)) {
            label = formField.label;
            value = getStringParam(fieldForSave.value);

            if (!formField.is_hidden_field) {
                if (formField.is_required_field && (!isValidParam(value) || isEmpty(value.trim()) || (formField.field_type === FORM_FIELD_TYPE.DATE_AND_TIME && value === 'Invalid date'))) {
                    if (label === 'Subject' && object === constants.APPOINTMENTS) {
                        label = getLocalizedStrings().label.APPOINTMENTS_TASKS.SUBJECT;
                    }
                    if (label === 'Task Name' && object === constants.TASKS) {
                        label = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK_NAME;
                    }
                    fieldForSave.helperText = label + ' - ' + getLocalizedStrings().message.COMMON.VALIDATE_BLANK;
                    isValid = false;
                } else if (!isEmpty(value)) {
                    if (formField.field_type === FORM_FIELD_TYPE.INTEGER && formField.name === 'lead_score' && (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT)) {
                        //by pass integr value checking for lead_score in lead and contact
                    } else if (formField.field_type === FORM_FIELD_TYPE.INTEGER && (formField.name === 'duration' || formField.name === 'days_after') && object === constants.TASKS) {
                        if ((value.indexOf('.') > -1 || !(/(^[\d+]$)|(^\d+,?\d{1,2}$)/.test(value)))) {
                            fieldForSave.helperText = label + ' - ' + getLocalizedStrings().message.COMMON.VALIDATE_INTEGER;
                            isValid = false;
                        }
                    } else if (formField.field_type === FORM_FIELD_TYPE.FLOAT && formField.name === 'total_sale' && object === constants.REPORTED_SALES_OBJECT) {
                        if (!isFloat(value) || parseFloat(value) <= 0) {
                            fieldForSave.helperText = label + ' - ' + getLocalizedStrings().message.COMMON.VALIDATE_POSITIVE_DECIMAL;
                            isValid = false;
                        }
                    } else {
                        maxLength = formField.field_length;

                        if (formField.field_type === FORM_FIELD_TYPE.INTEGER && ( !isInteger(value))) {

                            fieldForSave.helperText = label + ' - ' + getLocalizedStrings().message.COMMON.VALIDATE_INTEGER;
                            isValid = false;
                        } else if (formField.field_type === FORM_FIELD_TYPE.FLOAT) {
                            if (!isFloat(value)) {
                                fieldForSave.helperText = label + ' - ' + getLocalizedStrings().message.COMMON.VALIDATE_DECIMAL;
                                isValid = false;
                            } else {
                                var floatValueArray = (value.toString()).split(".");
                                var intSize = floatValueArray[0].length;
                                if (intSize > maxLength - 3) {
                                    fieldForSave.helperText = getLocalizedStrings().message.COMMON.TOO_BIG_SIZE;
                                    isValid = false;
                                }
                                if ((object === constants.PAYMENTS_OBJECT && formField.name === 'payment_value') || fieldForSave.callFrom === constants.CONVERT) {
                                    if (Number(value) === Number(0)) {
                                        if(object === constants.PAYMENTS_OBJECT && formField.name === 'payment_value'){
                                            fieldForSave.helperText = getLocalizedStrings().message.PAYMENTS.PAYMENT_VALUE_ALERT_MSG;
                                        // }else if(object === constants.PRODUCTS_OBJECT && formField.name === 'price'){
                                        //     fieldForSave.helperText = getLocalizedStrings().message.PRODUCTS.PRODUCT_PRICE_VALUE_ALERT_MSG;
                                        }else if(fieldForSave.callFrom === constants.CONVERT){
                                            fieldForSave.helperText = label + ' - ' + getLocalizedStrings().message.COMMON.VALIDATE_ZERO;
                                        }
                                        isValid = false;
                                    }
                                }
                            }
                        } else if (formField.field_type === FORM_FIELD_TYPE.URL && !isUrl(value)) {
                            fieldForSave.helperText = label + ' - ' + getLocalizedStrings().message.COMMON.VALIDATE_URL;
                            isValid = false;
                        } else if (formField.field_type === FORM_FIELD_TYPE.TEXT && formField.is_email_field && !isValidEmail(value)) {
                            fieldForSave.helperText = label + ' - ' + getLocalizedStrings().message.COMMON.VALIDATE_EMAIL;
                            isValid = false;
                        } else if(object === constants.PRODUCTS_OBJECT && formField.name === 'product_name' && value.indexOf(":") > -1){
                            fieldForSave.helperText = label + ' - ' + getLocalizedStrings().message.PRODUCTS.PRODUCT_NAME_COULDNOT_CONTAIN_COLON_MSG;
                            isValid = false;
                        }
                    }
                }
            }
        } else {
            isValid = false;
        }
    } catch (error) {
        console.error("Error in 'formValidator.js -> isValidateFormField()':" + error);
    }
    return isValid;
}

function isValidateFormFieldByField(object, formField) {

    let label, value;
    let isValid = true;
    let maxLength = 0;
    try {
        if (isValidParam(formField)) {
            label = formField.label;
            value = formField.value;
            if (!formField.is_hidden_field) {
                if (formField.is_required_field && isEmpty(value)) {
                    formField.helperText = label + ' - ' + getLocalizedStrings().message.COMMON.VALIDATE_BLANK;;
                    isValid = false;
                } else if (!isEmpty(value)) {
                    if (formField.field_type === FORM_FIELD_TYPE.INTEGER && formField.name === 'lead_score' && (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT)) {
                        //by pass integr value checking for lead_score in lead and contact
                    } else {
                        maxLength = formField.field_length;
                        if (formField.field_type === FORM_FIELD_TYPE.INTEGER && (value.indexOf('.') > -1 || !isInteger(value))) {

                            formField.helperText = label + ' - ' + getLocalizedStrings().message.COMMON.VALIDATE_INTEGER;;
                            isValid = false;
                        } else if (formField.field_type === FORM_FIELD_TYPE.FLOAT) {
                            if (!isFloat(value)) {
                                // formField.helperText = label + ' - ' + getLocalizedStrings().message.COMMON.VALUE + ' ' + value + ' ' + getLocalizedStrings().message.COMMON.FLOAT;
                                formField.helperText = label + ' - ' + getLocalizedStrings().message.COMMON.VALIDATE_DECIMAL;
                                isValid = false;
                            } else {
                                var floatValueArray = (value.toString()).split(".");
                                var intSize = floatValueArray[0].length;
                                if (intSize > maxLength - 3) {
                                    formField.helperText = getLocalizedStrings().message.COMMON.TOO_BIG_SIZE;
                                    isValid = false;
                                }
                            }
                        } else if (formField.field_type === FORM_FIELD_TYPE.URL && !isUrl(value)) {
                            formField.helperText = label + ' - ' + getLocalizedStrings().message.COMMON.VALIDATE_URL
                            isValid = false;
                        } else if (formField.field_type === FORM_FIELD_TYPE.TEXT) {

                            if (formField.is_email_field && !isEmail(value)) {
                                formField.helperText = label + ' - ' + getLocalizedStrings().message.COMMON.VALIDATE_EMAIL;
                                isValid = false;
                            } else if (formField.is_phone_field) {
                                let numbers = /^[0-9]+$/;
                                let phone_val = formField.value;
                                let result = phone_val.search(numbers);
                                if (result !== -1 && phone_val.length === 10) {
                                    let enterphno = phone_val.substring(0, 3) + "-" + phone_val.substring(3, 6) + "-" + phone_val.substring(6, 10);
                                    formField.value = enterphno;
                                }
                            }


                        }
                    }
                }
            }
        } else {
            isValid = false;
        }
    } catch (error) {
        console.error("Error in 'formValidator.js -> isValidateFormField()':" + error);
    }


    if (isValid) {
        if (formField.name === "phone") {

        }
    }
    return isValid;
}


export {
    FORM_FIELD_TYPE,
    isValidateEntry,
    isValidateFormField,
    isValidateFormFieldByField
}