import { actions } from '..//constants/actions';

function updatePipelineReportData(pipelineReportData, reportParams) {
    return {
        type: actions.PIPELINE_REPORT_DATA_UPDATE,
        payload: {
                pipelineReportData,
                reportParams
            }
    }
}

function updatePipelineReportSalesRep(pipelineReportSalesRep) {
    return {
        type: actions.PIPELINE_REPORT_SALESREP_UPDATE,
        payload: pipelineReportSalesRep
    }
}

function updatePipelineReportWorkflows(pipelineReportWorkflows) {
    return {
        type: actions.PIPELINE_REPORT_WORKFLOW_LIST_UPDATE,
        payload: pipelineReportWorkflows
    }
}

export {
updatePipelineReportData,
updatePipelineReportSalesRep,
updatePipelineReportWorkflows
}