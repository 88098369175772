import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as HTTPClient from '../../../services/helper/httpClient';
import Tooltip from '@mui/material/Tooltip';
import { addNode } from '../../../services/actions/automationCampaignActions';
import { styles } from '../../../services/constants/styles';
import { constants, EVENT_TYPE_KEYS } from '../../../services/constants/constants';
import { getAppCustomDrawer, getAppDialog, getAppDrawer } from '../../../services/actions/appContainerActions';
import { getIntParam, getStringParam, isValidParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import {
	getMaxId,
	getNodeByChildId,
	getNodeByParentId,
	getParentByActionChildName,
	getParentByActionName,
	showPlusIcon,
} from '../../../services/helper/automationUtils';
import {endPoints} from '../../../services/constants/endPoints';
import {LinkToType} from '../../../services/constants/link';
import {setMarketingResults} from '../../../services/actions/marketingReportAction';
import {addTab, TYPE_LIST_VIEW} from '../../../services/helper/sfTabManager';
import * as sfDialogs from '../components/sfDialogs';


const AutomationNode = ({node, iconName, campStatus}) => {
	const dispatch = useDispatch()
	const automationCampaign = useSelector((state) => state.automationCampaign);
	const [jsPlumbInst] = useState(automationCampaign.jsPlumbInst);

	const ref = useRef();

	const [resultContent, setResultContent] = useState(null);
	const [gblStatIdSubArr] = useState([]);
	const [campaignId, setCampaignId] = useState(0);

	useEffect(() => {
		if (ref.current) {
				jsPlumbInst.draggable(ref.current, {
					containment: true,
					drag: function () {
						jsPlumbInst.repaintEverything();
					},
					stop: function (event, ui) {
						node.posX = event.pos[0];
						node.posY = event.pos[1];
						if (node.id > 0) {
							node.nodeStatusFlg = 2;
						}
						jsPlumbInst.repaintEverything();
					}
				});			
		}
	}, [ref.current]);
	
	function showReport(node) {
		if (isValidParam(node)) {
			let dialogData = null;
			if (node.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL) {
				let dialogName = "";
				if (node.hasOwnProperty('details')) {
					if (node.details[0] !== undefined && node.details[0] !== null && node.details[0].hasOwnProperty('name')) {
						dialogName = node.details[0].name;
					}
					if (node.details[0] !== undefined && node.details[0] !== null && node.details[0].hasOwnProperty('autoresponder_id')) {
						dialogData = {
							campaignId: node.details[0].autoresponder_id,
						}
					}
				}
				dispatch(getAppDrawer(true, dialogName, constants.AUTO_RESPONDER_REPORT, dialogData, constants.AUTOMATION_DESIGNER));
			} else if (node.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL) {
				showBirthdayReport(node);
			} else if (node.actionName === constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN) {
				let dialogName = "";
				dialogName = automationCampaign?.data?.name;
				if (node.hasOwnProperty('details')) {
					if (node.details[0] !== undefined && node.details[0] !== null && node.details[0].hasOwnProperty('name')) {
						dialogName = node.details[0].name;
					}
					if (node.details[0] !== undefined && node.details[0] !== null && node.details[0].hasOwnProperty('id')) {
						dialogData = {
							campaignId: node.details[0].id,
						}
					}
				}
				dispatch(getAppDrawer(true, dialogName, constants.SMS_CAMPAIGN_REPORT, dialogData, constants.AUTOMATION_DESIGNER));
			}
		}
	}

	function showBirthdayReport(node) {
		let campaignId = node.details[0].autoresponder_id;
		let statId = 0;
		if (campaignId > 0) {
			setResultContent(null);
			let params = {
				campaign_id: campaignId,
				report_type: constants.BROADCAST_REPORT,
			}
			const promise = Promise.resolve(HTTPClient.get(endPoints.BROADCAST_CAMPAIGNS.RESULTS, params));

			promise.then((response) => {
				if (Array.isArray(response.marketing_results) && response.marketing_results.length > 0) {
					setResultContent(response);
					setCampaignId(campaignId);
					statId = response['marketing_results'][0].id;
					drilldownBCampaignReport(statId, 'Sent', constants.BIRTHDAY_REPORT_TYPE);
				} else {
					setResultContent('');
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.BIRTHDAY_CAMPAIGN_NO_REPORT, null);
				}
			});
		}
	}

	function drilldownBCampaignReport(statsId, eventType, reportType) {
		try {
			let params = {
				name: 'All records',
				tableId: 9,
			}

			const promise = Promise.resolve(HTTPClient.get(endPoints.REPORTS.QUERY_DETAIL, params));
			promise.then((response) => {
				if (isValidParam(response)) {
					let label = "";
					if (eventType === 'click') {
						let reportName = gblStatIdSubArr.length > 0 ? gblStatIdSubArr[statsId] : null;
						let eventTypeText = getLocalizedStrings().label.CAMPAIGNS.CLICK_URL;
						label = eventTypeText + " " + reportName;
					} else {
						let eventTypeText = eventType.charAt(0).toUpperCase() + eventType.slice(1);
						if (reportType === constants.BROADCAST_REPORT_TYPE) {
							label = eventTypeText + " " + getLocalizedStrings().label.BROADCAST_CAMPAIGNS.EMAIL_CAMPAIGN_REPORT;
						} else if (reportType === constants.AUTO_RESPONDER_REPORT_TYPE) {
							let eventTypeTextUpper = eventTypeText.toUpperCase();
							eventTypeText = getLocalizedStrings().label.CAMPAIGNS.hasOwnProperty(eventTypeTextUpper) ? getLocalizedStrings().label.CAMPAIGNS[eventTypeTextUpper] : eventTypeText;
							label = eventTypeText + " " + getLocalizedStrings().label.AUTOMATION_DESIGNER.TOUCH_CAMPAIGN_REPORT;
						} else if (reportType === constants.AB_REPORT_TYPE) {
							label = eventTypeText + " " + getLocalizedStrings().label.AB_CAMPAIGNS.AB_CAMPAIGN_REPORT;
						} else if (reportType === constants.BIRTHDAY_REPORT_TYPE) {
							label = eventTypeText + " " + getLocalizedStrings().label.AUTOMATION_DESIGNER.BIRTHDAY_CAMPAIGN_REPORT;
						}
					}
					let url = "/" + constants.CONTACTS_OBJECT + "/" + constants.BIRTHDAY_REPORT_TYPE.toLocaleLowerCase();
					openTab(label, constants.CONTACTS_OBJECT, response.query_id, "fa fa-user", url, statsId, eventType);
				}
			});
		} catch (error) {
			console.error("Error in 'AutomationNode.js -> drilldownBCampaignReport()':" + error);
		}
	}

	function openTab(label, component, queryId, imgName, url, statsId, eventType) {
		let filter = {
			id: queryId,
			name: '',
			type: constants.TYPE_AUTORESPONDER_REPORT,
		}
		let tabType = TYPE_LIST_VIEW;
		let reportFieldValue = eventType;
		if (eventType === 'click') {
			reportFieldValue = 'Clicked';
		}
		let campaignName = null;
		let marketingResults = resultContent.marketing_results;
		let reportObj = marketingResults.filter(function (el) {
			return el.id === statsId;
		});
		campaignName = reportObj[0].campaign_name;
		let tab = {
			label: label,
			object: component,
			type: tabType,
			imgName: imgName,
			info: {
				filter: filter,
				input_param: {
					stat_id: statsId,
					event_type: eventType,
				},
				isReport: true,
				reportType: constants.BIRTHDAY_REPORT_TYPE,
				event_type_key: EVENT_TYPE_KEYS[eventType],
				campaignId: campaignId,
				reportFieldValue: reportFieldValue,
				reportLabel: campaignName,
				recordCount: reportObj[0][EVENT_TYPE_KEYS[eventType]],
			},
			url: url,
			isActive: true
		};
		dispatch(setMarketingResults(marketingResults));
		addTab(tab, true);
	}

	function openDrawer(node) {
		let actionName = node.actionName;
		let dialogName = "";
		let isOpenDrawer = false;
		if (automationCampaign.data.status !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) {
			if (node.hasOwnProperty('id')) {
				let nodeId = node.id;
				nodeId = getIntParam(nodeId);
				if (nodeId > 0) {
					node.detailStatusFlg = 2;
				}
			}
		}
		if (actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK) {
			dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.LINK_CLICKED;
			isOpenDrawer = true;
		}
		if (actionName === constants.AUTOMATION_DESIGNER_SCORE_REACHED) {
			dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.SCORE_REACHED;
			isOpenDrawer = true;
		}
		if (actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL) {
			dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.TOUCH_CAMPAIGN;
			isOpenDrawer = true;
		}
		if (actionName === constants.AUTOMATION_DESIGNER_WEB_FORM) {
			dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.WEB_FORM;
			isOpenDrawer = true;
		}
		if (actionName === constants.AUTOMATION_DESIGNER_LANDING_PAGE) {
			dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.LANDING_PAGE;
			isOpenDrawer = true;
		}
		if (actionName === constants.AUTOMATION_DESIGNER_WEBSITE_VISIT) {
			dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.WEBSITE_VISITED;
			isOpenDrawer = true;
		}
		if (actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL) {
			dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.BIRTHDAY_CAMPAIGN;
			isOpenDrawer = true;
		}

		if (actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE || actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL || actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
			dialogName = getLocalizedStrings().label.MAIL.SEND_MAIL;
			isOpenDrawer = false;
			let detailsArray = null;
			let templateObject = null;
			if (actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE || actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
				if (node.hasOwnProperty('details')) {
					detailsArray = node.details[0];
					if (node.hasOwnProperty('is_new')) {
						let isNew = node.is_new;
						if (isNew) {
							node.details = [];
							let callbackData = {
								labelName: getLocalizedStrings().label.AUTOMATION_DESIGNER['Add Touch Message'],
								value: "New",
								data: {},
								nodeobject: node,
							}
							dispatch(getAppDialog(true, constants.EDITOR_TEMPLATE_DIALOG,
								getLocalizedStrings().label.CAMPAIGNS.TEMPLATE_DIALOG_TITLE,
								null, callbackData, constants.LIST_VIEW));
						}
					} else {
						if (isValidParam(detailsArray) && detailsArray.hasOwnProperty('message_id') &&
							detailsArray.hasOwnProperty('template')) {
							if (automationCampaign.data.hasOwnProperty('editorType') &&
								isValidParam(automationCampaign.data.hasOwnProperty('editorType'))) {
								let editorType = automationCampaign.data['editorType'];
								if (editorType === constants.EDITOR_NEW || editorType === constants.EDITOR_BEE) {
									templateObject = detailsArray.template;
									if (templateObject.hasOwnProperty('template_id')
										&& templateObject.hasOwnProperty('metadata')
										&& templateObject.hasOwnProperty('content')
										&& templateObject.metadata === '' && templateObject.content === ''
									) {
										isOpenDrawer = true;
									} else {
										openTemplateDrawer(node, false);
									}

								} else {
									templateObject = detailsArray.template;
									if (templateObject.hasOwnProperty('template_id')) {
										isOpenDrawer = true;
									}
								}
							} else {
								node.details = [];
								openEditorOptionDoalog(automationCampaign.data, node);
							}
						} else {
							node.details = [];
							let callbackData = {
								labelName: getLocalizedStrings().label.AUTOMATION_DESIGNER['Add Touch Message'],
								value: "New",
								data: {},
								nodeobject: node,
							}
							dispatch(getAppDialog(true, constants.EDITOR_TEMPLATE_DIALOG, getLocalizedStrings().label.CAMPAIGNS.TEMPLATE_DIALOG_TITLE, null, callbackData, constants.LIST_VIEW));
						}
					}
				} else {
					node.details = [];
					let callbackData = {
						labelName: getLocalizedStrings().label.AUTOMATION_DESIGNER['Add Touch Message'],
						value: "New",
						data: {},
						nodeobject: node,
					}
					dispatch(getAppDialog(true, constants.EDITOR_TEMPLATE_DIALOG, getLocalizedStrings().label.CAMPAIGNS.TEMPLATE_DIALOG_TITLE, null, callbackData, constants.LIST_VIEW));
				}
			}

			if (actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
				if (node.hasOwnProperty('details')) {
					detailsArray = node.details[0];
					if (node.hasOwnProperty('is_new')) {
						let isNew = node.is_new;
						if (isNew) {
							node.details = [];
							let callbackData = {
								labelName: getLocalizedStrings().label.COMMON.SEND_EMAIL,//getLocalizedStrings().label.AUTOMATION_DESIGNER['Send One-off Email'],
								value: "New",
								data: {},
								nodeobject: node,
							}
							dispatch(getAppDialog(true, constants.EDITOR_TEMPLATE_DIALOG, getLocalizedStrings().label.CAMPAIGNS.TEMPLATE_DIALOG_TITLE, null, callbackData, constants.LIST_VIEW));
						}
					} else {
						if (isValidParam(detailsArray) && detailsArray.hasOwnProperty('id') &&
							detailsArray.hasOwnProperty('template')) {
							if (automationCampaign.data.hasOwnProperty('editorType') &&
								isValidParam(automationCampaign.data['editorType'])) {
								let editorType = automationCampaign.data['editorType'];
								if (editorType === constants.EDITOR_NEW || editorType === constants.EDITOR_BEE) {
									templateObject = detailsArray.template;
									if (templateObject.hasOwnProperty('template_id')
										&& templateObject.hasOwnProperty('metadata')
										&& templateObject.hasOwnProperty('content')
										&& templateObject.metadata === '' && templateObject.content === ''
									) {
										isOpenDrawer = true;
									} else {
										openTemplateDrawer(node, false);
									}
								} else {
									templateObject = detailsArray.template;
									if (templateObject.hasOwnProperty('template_id')) {
										isOpenDrawer = true;
									}
								}
							} else {
								node.details = [];
								openEditorOptionDoalog(automationCampaign.data, node);
							}
						} else {
							node.details = [];
							let callbackData = {
								labelName: getLocalizedStrings().label.COMMON.SEND_EMAIL,//getLocalizedStrings().label.AUTOMATION_DESIGNER['Send One-off Email'],
								value: "New",
								data: {},
								nodeobject: node,
							}
							dispatch(getAppDialog(true, constants.EDITOR_TEMPLATE_DIALOG, getLocalizedStrings().label.CAMPAIGNS.TEMPLATE_DIALOG_TITLE, null, callbackData, constants.LIST_VIEW));
						}
					}
				} else {
					if (automationCampaign.data.hasOwnProperty('editorType') &&
						isValidParam(automationCampaign.data.hasOwnProperty('editorType'))) {
						node.details = [];
						openTemplateDrawer(node, true);
					} else {
						node.details = [];
						let callbackData = {
							labelName: getLocalizedStrings().label.COMMON.SEND_EMAIL,//getLocalizedStrings().label.AUTOMATION_DESIGNER['Send One-off Email'],
							value: "New",
							data: {},
							nodeobject: node,
						}
						dispatch(getAppDialog(true, constants.EDITOR_TEMPLATE_DIALOG, getLocalizedStrings().label.CAMPAIGNS.TEMPLATE_DIALOG_TITLE, null, callbackData, constants.LIST_VIEW));
					}
				}
			}
		}
		if (actionName === constants.AUTOMATION_DESIGNER_CHANGE_GROUP) {
			dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.CHANGE_GROUP;
			isOpenDrawer = true;
		}
		if (actionName === constants.AUTOMATION_DESIGNER_ADD_NOTE) {
			dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.ADD_NOTE;
			isOpenDrawer = true;
		}
		if (actionName === constants.AUTOMATION_DESIGNER_NOTIFY) {
			dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.NOTIFY;
			isOpenDrawer = true;
		}
		if (actionName === constants.AUTOMATION_DESIGNER_ADD_TASK) {
			dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.ADD_TASK;
			isOpenDrawer = true;
		}
		if (actionName === constants.AUTOMATION_DESIGNER_UPDATE_FIELD) {
			dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.FIELD_UPDATED;
			isOpenDrawer = true;
		}
		if (actionName === constants.AUTOMATION_DESIGNER_ASSIGN) {
			dialogName = getLocalizedStrings().label.COMMON.ASSIGN;
			isOpenDrawer = true;
		}
		if (actionName === constants.AUTOMATION_DESIGNER_CONVERT) {
			dialogName = getLocalizedStrings().label.COMMON.CONVERTED;
			isOpenDrawer = true;
		}
		if (actionName === constants.AUTOMATION_DESIGNER_WAIT) {
			dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.WAIT;
			isOpenDrawer = true;
		}
		if (actionName === constants.AUTOMATION_DESIGNER_SEND_SMS || actionName === constants.AUTOMATION_DESIGNER_ADD_SMS) {
			dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.SEND_SMS;
			isOpenDrawer = true;
			if(actionName === constants.AUTOMATION_DESIGNER_ADD_SMS) {
				let dbp = 0;
                let interval_mode = 'days';
                let arrayNodes = automationCampaign.data.nodes;
                let immediateprevId = 0;
                let immediateprevnodeDetails = null;
                if (isValidParam(node.parentId) && node.parentId > 0) {
                    immediateprevId = node.parentId;
                    immediateprevnodeDetails = getNodeByChildId(arrayNodes, immediateprevId);
                    if (isValidParam(immediateprevnodeDetails)) {
                        let prevNodeActionName = immediateprevnodeDetails.hasOwnProperty('actionName') ? immediateprevnodeDetails.actionName : "";
                        if (prevNodeActionName === constants.AUTOMATION_DESIGNER_WAIT) {
                            if (immediateprevnodeDetails.hasOwnProperty('details')) {
                                let preveNodeDetailsArray = immediateprevnodeDetails.details[0];
                                if (isValidParam(preveNodeDetailsArray)) {
                                    if (preveNodeDetailsArray.interval_mode === 'days') {
                                        dbp = preveNodeDetailsArray.interval / 24;
                                        interval_mode = 'days';
                                    } else {
                                        dbp = preveNodeDetailsArray.interval;
                                        interval_mode = 'hours'
                                    }

                                }
                            }
                        }
                    }
                }
                
                if(!node?.details) {
					node.details = [{
						sequence: 1,
                        dbp: dbp,
                        interval_mode: interval_mode
					}]
                } else {
					node.details = [{...node.details[0], 
						sequence: 1,
                        dbp: dbp,
                        interval_mode: interval_mode
					}]
				}
			}
		}
		if (actionName === constants.AUTOMATION_DESIGNER_SMS_REPLY) {
			// dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.SMS_REPLY;
			isOpenDrawer = false;
		}
		if (actionName === constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN) {
			dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.SMS_CAMPAIGN;
			isOpenDrawer = true;
		}

		if (isOpenDrawer) {
			if (actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE || actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
				let style = {};
				style.width = '83%';
				dispatch(getAppCustomDrawer(true, dialogName, actionName, style, node, null, false));
			} else {
				dispatch(getAppDrawer(true, dialogName, actionName, node, constants.AUTOMATION_DESIGNER));
			}
		}
	}

	function openTemplateDrawer(nodeObject, isUpdateJson) {
		try {
			if (automationCampaign.data.hasOwnProperty('editorType') &&
				isValidParam(automationCampaign.data.hasOwnProperty('editorType'))) {
				let editorType = automationCampaign.data['editorType'];
				let labelName = getLocalizedStrings().label.AUTOMATION_DESIGNER['Add Touch Message'];
				if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
					labelName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
				}
				if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
					labelName = getLocalizedStrings().label.AUTOMATION_DESIGNER['Add Birthday Message'];
				}
				if (editorType === constants.EDITOR_LEGACY) {
					if (isUpdateJson) {
						automationCampaign.data['editorType'] = constants.EDITOR_LEGACY;
					}
					dispatch(getAppDialog(true, constants.TEMPLATE_DIALOG, getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_EMAIL_TEMPLATE, null, nodeObject, constants.AUTOMATION_DESIGNER_OBJECT));
				} else if (editorType === constants.EDITOR_NEW || editorType === constants.EDITOR_BEE) {
					if (isUpdateJson) {
						automationCampaign.data['editorType'] = editorType;
					}
					let data = {
						type: "New",
						'node_object': nodeObject,
						object: constants.AUTOMATION_DESIGNER_OBJECT
					}
					let style = {};
					style.width = '100%';
					dispatch(getAppCustomDrawer(true, labelName, LinkToType.TYPE_NEW, style, data, null, false));
				}
			}
		} catch (error) {
			console.error("Error in 'automationNode.js -> openTemplateDrawer()': " + error);
		}
	}

	function callbackEditorSelection(callbackData) {
		try {
			if (callbackData.object === constants.AUTOMATION_DESIGNER_OBJECT) {
				dispatch(getAppDialog(false, constants.EDITOR_SELECTION_DIALOG, null, null, null, null));
				let nodeObject = callbackData['nodeObject'];
				let data = callbackData['data'];
				if (data['type'] === constants.EDITOR_NEW || data['type'] === constants.EDITOR_BEE) {
					automationCampaign.data['editorType'] = data['type'];
					openTemplateDrawer(nodeObject, true);
				} else if (data['type'] === constants.EDITOR_LEGACY) {
					automationCampaign.data['editorType'] = constants.EDITOR_LEGACY;
					dispatch(getAppDialog(true, constants.TEMPLATE_DIALOG, getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_EMAIL_TEMPLATE, null, nodeObject, constants.AUTOMATION_DESIGNER_OBJECT));
				}
			}
		} catch (error) {
			console.error("Error in 'automationNode.js -> callbackEditorSelection()': " + error);
		}
	}

	function openEditorOptionDoalog(automationData, nodeObject) {
		try {
			let callbackData = {
				nodeObject: nodeObject,
				data: null,
				object: constants.AUTOMATION_DESIGNER_OBJECT,
				automationData: automationData,
				value: 'New',
				labelName: getLocalizedStrings().label.AUTOMATION_DESIGNER['Add Touch Message']
			}
			dispatch(getAppDialog(true, constants.EDITOR_SELECTION_DIALOG, 'Choose Editor', callbackEditorSelection, callbackData, constants.DETAIL_VIEW));
		} catch (error) {
			console.error("Error in 'automationNode.js -> openEditorOptionDoalog()': " + error);
		}
	}


	function generateNodeObject(parentNode) {
		let arrayNodes = automationCampaign.data.nodes;
		let maxId = 0;
		maxId = getMaxId(arrayNodes);
		let childId = 0;
		childId = maxId + 1;
		let posY = parentNode.posY + 120;
		let posX = parentNode.posX;
		let processId = 0;
		if (parentNode.actionName === constants.AUTOMATION_DESIGNER_SCORE_REACHED) {
			processId = "";
		} else if (parentNode.actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK) {
			processId = "";
		}
		return {
			id: 0,
			parentId: 0,
			childId: childId,
			actionId: parentNode.actionId,
			actionName: parentNode.actionName,
			actionLabel: parentNode.actionLabel,
			nodeStatusFlg: 1,
			detailStatusFlg: 1,
			status: "",
			processId: processId,
			secProcessId: 0,
			type: parentNode.type,
			decisionFlg: 0,
			posX: posX,
			posY: posY,
			details: [],
			referenceId: 0
		};
	}

	function deleteNode(node) {
		try {
			if (automationCampaign.data.status !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) {
				let arrNodes = automationCampaign.data.nodes;
				let objNodeElem = getNodeByChildId(arrNodes, node.childId);
				let isDelete = true;
				let msg = null;
				if ((objNodeElem.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL || objNodeElem.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL) && objNodeElem.hasOwnProperty('details') &&
					objNodeElem.details[0] !== null && objNodeElem.details[0].hasOwnProperty('autoresponder_id') &&
					objNodeElem.details[0].autoresponder_id > 0) {
					isDelete = false;
					msg = getLocalizedStrings().message.AUTOMATION_DESIGNER.DELETE_NODE_CONFIRMATION;
					sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => onConfirmDelete(objNodeElem, arrNodes), null);
				}
				let parentInfo;
				try {
					if ((objNodeElem.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE || objNodeElem.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) && objNodeElem.hasOwnProperty('details') &&
						objNodeElem.details.length > 0 && objNodeElem.details[0] !== null && objNodeElem.details[0] !== undefined &&
						objNodeElem.details[0] !== "" && objNodeElem.details[0].hasOwnProperty('message_id') &&
						objNodeElem.details[0].message_id > 0) {
						parentInfo = getParentByActionName(arrNodes, objNodeElem.parentId, constants.AUTOMATION_DESIGNER_SEND_MAIL);
						if (parentInfo !== null && parentInfo !== undefined && objNodeElem.parentId !== parentInfo.childId &&
							parentInfo.details[0].hasOwnProperty("t_status") && parentInfo.details[0].t_status === "Stopped") {
							isDelete = false;
							checkDeleteStatus(arrNodes, objNodeElem, "delete");
						} else if (parentInfo !== null && parentInfo !== undefined && objNodeElem.parentId === parentInfo.childId &&
							parentInfo.details[0].hasOwnProperty("t_status") && parentInfo.details[0].t_status === "Stopped") {
							isDelete = false;
							msg = getLocalizedStrings().message.AUTOMATION_DESIGNER.MSG_CANT_BE_DELETED;
							sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
							return false;
						}
					}
				} catch (error) {
					console.error("Error in 'automationNode.js -> deleteNode()': " + error);
				}

				if (objNodeElem.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
					parentInfo = getNodeByChildId(arrNodes, objNodeElem.parentId);
					if (isValidParam(parentInfo) && parentInfo.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL &&
						objNodeElem.parentId === parentInfo.childId) {
						isDelete = false;
						msg = getLocalizedStrings().message.AUTOMATION_DESIGNER.BIRTHDAY_FIRST_MSG_CANT_BE_DELETED;
						sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);

						return false;
					}
				}
				if (isDelete) {
					deleteNodeFromCanvas(arrNodes, objNodeElem, isDelete);
				}
			}
		} catch (error) {
			console.error("Error in 'automationNode.js -> deleteNode()': " + error);
		}
	}

	function onConfirmDelete(objNodeElem, arrNodes) {
		let childArr = [];
		if (objNodeElem.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL) {
			childArr = getParentByActionChildName(arrNodes, objNodeElem.childId, constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE, new Array);
		} else {
			childArr = getParentByActionChildName(arrNodes, objNodeElem.childId, constants.AUTOMATION_DESIGNER_ADD_MESSAGE, new Array);
		}
		if (childArr !== null && childArr !== undefined && childArr.length > 0) {
			for (let ds = 0; ds < childArr.length; ds++) {
				let objNodeElement = getNodeByChildId(arrNodes, childArr[ds].childId);
				deleteFromCanvas(arrNodes, objNodeElement);
			}
		}
		deleteFromCanvas(arrNodes, objNodeElem);
		// setTimeout(() => dispatch(addNode(automationCampaign.data)), 500);
	}

	function checkDeleteStatus(arrNodes, objNodeElem, checkStatus) {
		let url = endPoints.AUTOMATION_DESIGNER.CAN_MSG_BE_DELETED;
		let params = {
			messageId: objNodeElem.details[0].message_id,
		}
		const promise = Promise.resolve(HTTPClient.get(url, params));
		promise.then((response) => {
			let isDelete = response.can_msg_be_deleted;
			if (checkStatus !== null && checkStatus !== undefined && checkStatus === "remove") {
				//remove connection
			} else {
				deleteNodeFromCanvas(arrNodes, objNodeElem, isDelete);
			}
		});
	}

	function deleteNodeFromCanvas(arrNodes, objNodeElem, isDelete) {
		let msg = null;
		try {
			if (isDelete) {
				msg = getLocalizedStrings().message.AUTOMATION_DESIGNER.DELETE_NODE_CONFIRMATION;
				sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => onConfirmDeleteNodeCanvas(arrNodes, objNodeElem), null);
			} else {
				msg = getLocalizedStrings().message.AUTOMATION_DESIGNER.MSG_CANT_BE_DELETED;
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
			}
		} catch (error) {
			console.error("Error in 'automationNode.js -> deleteNodeFromCanvas()': " + error);
		}
	}

	function onConfirmDeleteNodeCanvas(arrNodes, objNodeElem) {
		deleteFromCanvas(arrNodes, objNodeElem);
		if (objNodeElem.hasOwnProperty('prevDetails')) {
			arrNodes.forEach(function (element, index) {
				if (element.childId === objNodeElem.childId) {
					arrNodes[index] = objNodeElem.prevDetails;
				}
			});
		}
		// setTimeout(() => dispatch(addNode(automationCampaign.data)), 500);
	}

	function deleteFromCanvas(arrNodes, objNodeElem) {
		 
		try {
			if (!!objNodeElem) {
				if (objNodeElem.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
					let nodesArray = automationCampaign.data.nodes;
					let currNode = null;
					currNode = getNodeByParentId(nodesArray, objNodeElem.childId);
					if (isValidParam(currNode)) {
						currNode = currNode.filter(n => n.nodeStatusFlg !== 3);
						currNode = currNode[0];
					}
					if (currNode) {
						do {
							if (currNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL || currNode.acionType === 1) {
								break;
							}
							if (currNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
								if (automationCampaign.data.id > 0 && currNode.id > 0) {
									let copyCurrNode = {...currNode};
									copyCurrNode.nodeStatusFlg = 3;
									copyCurrNode.detailStatusFlg = 3;
									currNode.prevDetails = copyCurrNode;
								}
								currNode.actionId = 26;
								currNode.actionName = constants.AUTOMATION_DESIGNER_AUTO_MAIL;
								currNode.actionLabel = getLocalizedStrings().label.AUTOMATION_DESIGNER['Send Email'];
								currNode.processId = null;
								currNode.secProcessId = 0;
								currNode.decisionFlg = 0;
								currNode.referenceId = 0;
								currNode.detailStatusFlg = 1;
								currNode.id = 0;
								if (currNode.hasOwnProperty('details')&& currNode.details.length > 0) {
									let detailsArray = currNode.details[0];
									if (isValidParam(detailsArray) && detailsArray.hasOwnProperty('message_id')) {
										detailsArray.id = 0;
										delete detailsArray.message_id;
									}
									let prevWaitNode = getNodeByChildId(nodesArray, currNode.parentId);
									if (isValidParam(prevWaitNode) && prevWaitNode.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
										if(detailsArray){
											detailsArray.interval_mode = prevWaitNode.details[0].interval_mode;
											if (prevWaitNode.details[0].interval_mode === 'days') {
												detailsArray.interval = prevWaitNode.details[0].interval / 24;
											} else {
												detailsArray.interval = prevWaitNode.details[0].interval;
											}
										}
									}
								}
							}
							if (currNode.actionName === constants.AUTOMATION_DESIGNER_ADD_SMS) {
								if (automationCampaign.data.id > 0 && currNode.id > 0) {
									let copyCurrNode = {...currNode};
									copyCurrNode.nodeStatusFlg = 3;
									copyCurrNode.detailStatusFlg = 3;
									currNode.prevDetails = copyCurrNode;
								}
								currNode.actionId = 30;
								currNode.actionName = constants.AUTOMATION_DESIGNER_SEND_SMS;
								currNode.actionLabel = getLocalizedStrings().label.AUTOMATION_DESIGNER['Send SMS'];
								currNode.processId = null;
								currNode.secProcessId = 0;
								currNode.decisionFlg = 0;
								currNode.referenceId = 0;
								currNode.detailStatusFlg = 1;
								currNode.id = 0;
								if (currNode.hasOwnProperty('details') && currNode.details.length > 0) {
									let detailsArray = currNode.details[0];
									if (isValidParam(detailsArray) && detailsArray.hasOwnProperty('message_id')) {
										detailsArray.id = 0;
										delete detailsArray.message_id;
									}
									let prevWaitNode = getNodeByChildId(nodesArray, currNode.parentId);
									if (isValidParam(prevWaitNode) && prevWaitNode.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
											if(detailsArray){
												detailsArray.interval_mode = prevWaitNode.details[0].interval_mode;
												if (prevWaitNode.details[0].interval_mode === 'days') {
													detailsArray.interval = prevWaitNode.details[0].interval / 24;
												} else {
													detailsArray.interval = prevWaitNode.details[0].interval;
												}
										}
									}
								}
							}
							currNode = getNodeByParentId(nodesArray, currNode.childId);
							if (isValidParam(currNode)) {
								currNode = currNode.filter(n => n.nodeStatusFlg !== 3);
								currNode = currNode[0];
							}
						} while (currNode);
					}
				}
				const automationData = {...automationCampaign.data};
				if (objNodeElem.nodeStatusFlg === 1) {
					automationData.nodes = automationData.nodes.filter(function (el) {
						return el.childId.toString() !== objNodeElem.childId.toString().replace("_B", "").replace("_Y", "").replace("_N", "");
					});
				}
				let jsPlumbInst = automationCampaign.jsPlumbInst;
				if (jsPlumbInst && objNodeElem.childId) {
					// jsPlumbInst.deleteConnection(objNodeElem.childId);
				}

				if (objNodeElem) {
					objNodeElem.nodeStatusFlg = 3;
					objNodeElem.detailStatusFlg = 3;
					showPlusIcon(arrNodes, objNodeElem);
				}
				let arrChildNodes = getNodeByParentId(arrNodes, objNodeElem.childId);
				if (arrChildNodes !== null && arrChildNodes !== undefined && arrChildNodes.length > 0) {
					for (let j = 0; j < arrChildNodes.length; j++) {
						arrChildNodes[j].parentId = 0;
					}
				}
				dispatch(addNode(automationData))
			}
		} catch (error) {
			console.error("Error in 'automationNode.js -> deleteFromCanvas()': " + error);
		}
	}

	function generateTooltipInfo(node) {
		let i;
		let arrNodes = automationCampaign.data.nodes;
		let infoMessage = getLocalizedStrings().message.AUTOMATION_DESIGNER.NO_INFO_EXISTS
		let tooltipInfo = [];
		let actionName = node.actionName;
		let nodeDetailArray = null;
		if (node.hasOwnProperty('details')) {
			nodeDetailArray = node['details'];
		}
		let isNew = isValidParam(node.hasOwnProperty('is_new')) ? node['is_new'] : false;
		try {
			if (actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL || actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL) {
				if (nodeDetailArray !== null && nodeDetailArray !== undefined && nodeDetailArray.length === 1) {
					let name = getLocalizedStrings().label.AUTOMATION_DESIGNER.NAME + ": " + nodeDetailArray[0].name;
					tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{name}</div>)

					if (actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL) {
						let filter_type = nodeDetailArray[0].filter_type;
						let resendAll = nodeDetailArray[0].resendAll;
						let sendOnWeekdays = nodeDetailArray[0].sendOnWeekdays;
						let sendOnWeekdaysVal = getLocalizedStrings().label.AUTOMATION_DESIGNER.SEND_ON_ANY_TIME;

						if (sendOnWeekdays !== null) {
							if (sendOnWeekdays === "Yes") {
								sendOnWeekdaysVal = getLocalizedStrings().label.AUTOMATION_DESIGNER.SEND_ON_BUSINESS_DAY;
							}
						}

						let campaign = null
						if (filter_type === "0") {
							campaign = getLocalizedStrings().label.AUTOMATION_DESIGNER.TARGET_GROUPS + ": " + nodeDetailArray[0].campaign;
						} else {
							campaign = getLocalizedStrings().label.AUTOMATION_DESIGNER.TARGET_FILTER + ": " + nodeDetailArray[0].campaign;
						}
						tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{campaign}</div>)

						let d = "";
						let resendAfter = "";
						if (resendAll === "Yes") {
							resendAfter = getLocalizedStrings().label.AUTOMATION_DESIGNER.RESEND_AFTER + ": " + nodeDetailArray[0].resendAfter + " Day(s)";
						}

						let autoresponder_id = nodeDetailArray[0].autoresponder_id;
						if (nodeDetailArray[0].startDate !== undefined && nodeDetailArray[0].startDate !== "") {
							let startDate = nodeDetailArray[0].startDate;
							if (startDate === null || startDate === undefined) {
								startDate = "";
							}
							d = getLocalizedStrings().label.AUTOMATION_DESIGNER.START_DATE + ": " + startDate;
							tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{d}</div>)
							if (resendAfter !== "" && resendAfter !== null && resendAfter !== 'undefined') {
								tooltipInfo.push(<div key={actionName}
								                      style={styles.automationTooltipStyle}>{resendAfter}</div>)
							}
							tooltipInfo.push(<div key={actionName}
							                      style={styles.automationTooltipStyle}>{sendOnWeekdaysVal}</div>)
						} else {
							let tempNode = getNodeByParentId(arrNodes, node.childId);
							tempNode = tempNode.filter(n => n.nodeStatusFlg !== 3);
							let sendMail = null;
							if (tempNode[0].hasOwnProperty('actionName') && tempNode[0].actionName === constants.AUTOMATION_DESIGNER_WAIT) {
								sendMail = getNodeByParentId(arrNodes, tempNode[0].childId);
								sendMail = sendMail.filter(n => n.nodeStatusFlg !== 3);
							}
							let sendMailDate = sendMail[0].details;
							let date = "";
							if (sendMailDate !== null && sendMailDate !== undefined && sendMailDate.length === 1 && autoresponder_id !== "") {
								date = sendMailDate[0].startDate;
							}
							if (date === null || date === undefined) {
								date = "";
							}
							d = getLocalizedStrings().label.AUTOMATION_DESIGNER.START_DATE + ": " + date;
							tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{d}</div>)
							if (resendAfter !== "" && resendAfter !== null && resendAfter !== 'undefined') {
								tooltipInfo.push(<div key={actionName}
								                      style={styles.automationTooltipStyle}>{resendAfter}</div>)
							}
							tooltipInfo.push(<div key={actionName}
							                      style={styles.automationTooltipStyle}>{sendOnWeekdaysVal}</div>)
						}
					}
					tooltipInfo = (<div style={{width: '300px'}}>{tooltipInfo}</div>);
				}
			} else if (actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE || actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL || actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
				if (nodeDetailArray !== null && nodeDetailArray !== undefined && nodeDetailArray.length === 1 && !isNew) {
					let name = nodeDetailArray[0].template;
					let Subject = getLocalizedStrings().label.AUTOMATION_DESIGNER.SUBJECT + ": " + name["subject"];
					tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{Subject}</div>)

					let intervalCount = '';
					if (actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
						intervalCount = nodeDetailArray[0].dbp;
					} else if (actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
						intervalCount = nodeDetailArray[0].interval;
					}
					if (actionName !== constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
						let interval_mode = nodeDetailArray[0].interval_mode === 'days' ? getLocalizedStrings().label.AUTOMATION_DESIGNER['day(s)'] : getLocalizedStrings().label.AUTOMATION_DESIGNER['hour(s)'];
						let interval = getLocalizedStrings().label.AUTOMATION_DESIGNER.INTERVAL + ": " + intervalCount + " " + interval_mode;
						tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{interval}</div>);
					}
					tooltipInfo = (<div style={{width: '260px'}}>{tooltipInfo}</div>);
				}
			} else if (actionName === constants.AUTOMATION_DESIGNER_ADD_NOTE) {
				if (nodeDetailArray !== null && nodeDetailArray !== undefined && nodeDetailArray.length === 1) {
					let qualifier_1 = getLocalizedStrings().label.AUTOMATION_DESIGNER.NOTE_TYPE + ": " + nodeDetailArray[0].qualifier_1;
					tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{qualifier_1}</div>);

					let note_text = getLocalizedStrings().label.AUTOMATION_DESIGNER.TEXT + ": " + nodeDetailArray[0].note_text;
					tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{note_text}</div>);
					tooltipInfo = (<div style={{width: '240px'}}>{tooltipInfo}</div>);
				}
			} else if (actionName === constants.AUTOMATION_DESIGNER_ADD_TASK) {
				if (nodeDetailArray !== null && nodeDetailArray !== undefined && nodeDetailArray.length > 0) {
					let t_name = "";
					let task = "";
					for (i = 0; i < nodeDetailArray.length; i++) {
						if (i < 5) {
							t_name = nodeDetailArray[i].t_name;
							if (task === "") {
								task = t_name;
							} else {
								task = task + "," + t_name;
							}
						} else {
							task = task + "...";
						}
					}
					tooltipInfo.push(<div key={actionName}
					                      style={styles.automationTooltipStyle}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.TASK_NAME + ": " + task}</div>);
					tooltipInfo = (<div style={{width: '240px'}}>{tooltipInfo}</div>);
				}
			} else if (actionName === constants.AUTOMATION_DESIGNER_CONVERT) {
				if (nodeDetailArray !== null && nodeDetailArray !== undefined && nodeDetailArray.length === 1) {
					let param_value = getLocalizedStrings().label.AUTOMATION_DESIGNER.CONVERT_TO + ": " + nodeDetailArray[0].param_value;
					tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{param_value}</div>)
				}
			} else if (actionName === constants.AUTOMATION_DESIGNER_CHANGE_GROUP) {
				if (nodeDetailArray !== null && nodeDetailArray !== undefined && nodeDetailArray.length === 1) {
					let action_type = nodeDetailArray[0].action_type;
					let object_indentifierText = nodeDetailArray[0].object_indentifierText;
					if (object_indentifierText !== null && object_indentifierText !== "" && object_indentifierText !== undefined) {
						let groupActionType = "";
						if (action_type === "add_to_group") {
							groupActionType = getLocalizedStrings().label.AUTOMATION_DESIGNER.ADD_TO_GROUP;
						} else if (action_type === "move_to_group") {
							groupActionType = getLocalizedStrings().label.AUTOMATION_DESIGNER.MOVE_TO_GROUP;
						} else if (action_type === "remove_from_group") {
							groupActionType = getLocalizedStrings().label.AUTOMATION_DESIGNER.REMOVE_FROM_GROUP;
						}
						tooltipInfo.push(<div key={actionName}
						                      style={styles.automationTooltipStyle}>{groupActionType + ": " + object_indentifierText}</div>)
					} else {
						tooltipInfo.push(<div key={actionName}
						                      style={styles.automationTooltipStyle}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.DRAG_TO_MOVE}</div>)
					}
					tooltipInfo = (<div style={{width: '240px'}}>{tooltipInfo}</div>);
				}
			} else if (actionName === constants.AUTOMATION_DESIGNER_ASSIGN) {
				if (nodeDetailArray !== null && nodeDetailArray !== undefined && nodeDetailArray.length === 1) {
					let param_text = "";
					if (nodeDetailArray[0].hasOwnProperty('param_value') && nodeDetailArray[0].param_value === -999) {
						param_text = 'Use Assignment Rule';
					} else {
						param_text = nodeDetailArray[0].object_indentifierText;
					}
					tooltipInfo.push(<div key={actionName}
					                      style={styles.automationTooltipStyle}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.ASSIGN_TO + ": " + param_text}</div>)
				}
			} else if (actionName === constants.AUTOMATION_DESIGNER_NOTIFY) {
				if (nodeDetailArray !== null && nodeDetailArray !== undefined && nodeDetailArray.length > 0) {
					let textArray = "";
					let qualifier = "";
					let qualifierUser = "";
					let text = "";
					let qualifier_1 = "";

					for (i = 0; i < nodeDetailArray.length; i++) {
						qualifier_1 = nodeDetailArray[i].qualifier_1;
						if (qualifier_1 === "notify_sender") {
							qualifier = getLocalizedStrings().label.AUTOMATION_DESIGNER.NOTIFY_SENDER;
						} else if (qualifier_1 === "notify_salesrep") {
							qualifier = getLocalizedStrings().label.AUTOMATION_DESIGNER.NOTIFY_SALES_REP
						} else if (qualifier_1 === "notify_both") {
							qualifier = getLocalizedStrings().label.AUTOMATION_DESIGNER.NOTIFY_SENDER_SALESREP
						}
					}

					for (i = 0; i < nodeDetailArray.length; i++) {
						let userText = nodeDetailArray[i].object_indentifierText;
						if (qualifier_1 === "notify_users")
							if (qualifierUser === "") {
								qualifierUser = getLocalizedStrings().label.AUTOMATION_DESIGNER.NOTIFY_USERS
							}
						if (userText !== undefined && userText !== null && userText !== "") {
							if (textArray === null || textArray === "") {
								textArray = userText;
							} else {
								textArray = textArray + ", " + userText;
							}
						}
						if (textArray !== null && textArray !== "" && textArray !== undefined) {
							text = getLocalizedStrings().label.AUTOMATION_DESIGNER.NOTIFY + ": " + textArray;
						}
					}

					if (qualifierUser !== "" && qualifier !== "") {
						tooltipInfo.push(<div key={actionName}
						                      style={styles.automationTooltipStyle}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.QUALIFIER + ": " + qualifier + ", " + qualifierUser}</div>)
						tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{text}</div>)
					} else if (qualifierUser !== "" && qualifier === "") {
						tooltipInfo.push(<div key={actionName}
						                      style={styles.automationTooltipStyle}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.QUALIFIER + ": " + qualifierUser}</div>)
						tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{text}</div>)
					} else {
						tooltipInfo.push(<div key={actionName}
						                      style={styles.automationTooltipStyle}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.QUALIFIER + ": " + qualifier}</div>)
						tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{text}</div>)
					}
					tooltipInfo = (<div style={{width: '250px'}}>{tooltipInfo}</div>)
				}
			} else if (actionName === constants.AUTOMATION_DESIGNER_WEB_FORM) {
				if (nodeDetailArray !== null && nodeDetailArray !== undefined && nodeDetailArray.length === 1) {
					let webformText = nodeDetailArray[0].object_indentifierText;
					if (webformText !== null && webformText !== "" && webformText !== undefined) {
						webformText = getLocalizedStrings().label.AUTOMATION_DESIGNER.WEB_FORM + ": " + webformText;
						tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{webformText}</div>);
						tooltipInfo = (<div style={{width: '250px'}}>{tooltipInfo}</div>);
					}

					let objectName = nodeDetailArray[0].objectName;
					if (objectName !== null && objectName !== "" && objectName !== undefined) {
						objectName = getLocalizedStrings().label.AUTOMATION_DESIGNER.OBJECT + ": " + objectName;
						tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{objectName}</div>);
						tooltipInfo = (<div style={{width: '250px'}}>{tooltipInfo}</div>);
					}
				}
			} else if (actionName === constants.AUTOMATION_DESIGNER_LANDING_PAGE) {
				if (nodeDetailArray !== null && nodeDetailArray !== undefined && nodeDetailArray.length === 1) {
					let landingPageText = nodeDetailArray[0].object_indentifierText;
					if (landingPageText !== null && landingPageText !== "" && landingPageText !== undefined) {
						landingPageText = getLocalizedStrings().label.AUTOMATION_DESIGNER.LANDING_PAGE + ": " + landingPageText;
						tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{landingPageText}</div>);
						tooltipInfo = (<div style={{width: '250px'}}>{tooltipInfo}</div>);
					}
				}
			} else if (actionName === constants.AUTOMATION_DESIGNER_UPDATE_FIELD) {
				if (nodeDetailArray !== null && nodeDetailArray !== undefined && nodeDetailArray.length > 0) {
					let table = getLocalizedStrings().label.COMMON.UPDATE + " " + nodeDetailArray[0].tableText;
					tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{table}</div>);
					for (let i = 0; i < nodeDetailArray.length; i++) {
						tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{nodeDetailArray[i].t_fieldLabel + ": " + nodeDetailArray[i].t_fieldValue}</div>)
					}
					tooltipInfo = (<div style={{width: '250px'}}>{tooltipInfo}</div>);
				}
			} else if (actionName === constants.AUTOMATION_DESIGNER_WAIT) {
				if (nodeDetailArray !== null && nodeDetailArray !== undefined && nodeDetailArray.length === 1) {
					let tempInterval = nodeDetailArray[0].interval;
					let tempIntervalMode = nodeDetailArray[0].interval_mode;

					if (tempInterval > 0) {
						if (tempIntervalMode === 'days') {
							tempInterval = tempInterval / 24;
						}
					}
					let strMode = tempIntervalMode === 'days' ? ' ' + getLocalizedStrings().label.AUTOMATION_DESIGNER['day(s)'] : ' ' + getLocalizedStrings().label.AUTOMATION_DESIGNER['hour(s)'];
					let interval = getLocalizedStrings().label.AUTOMATION_DESIGNER.INTERVAL + ": " + tempInterval + strMode;
					tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{interval}</div>)
				}
			} else if (actionName === constants.AUTOMATION_DESIGNER_SCORE_REACHED) {
				let scoreRange = null;
				if (node.hasOwnProperty('secProcessId')) {
					scoreRange = node.secProcessId;
				}
				if (scoreRange !== null && scoreRange !== "" && scoreRange !== undefined) {
					tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.SCORE + ": " + scoreRange}</div>)
				}
			} else if (actionName === constants.AUTOMATION_DESIGNER_WEBSITE_VISIT ||
				actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK) {
				if (!!node && node.hasOwnProperty('details')) {
					if (nodeDetailArray !== null && nodeDetailArray !== undefined && nodeDetailArray.length > 0 &&
						node.details[0].websiteUrl !== "") {
						tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.LINK + ": " + node.details[0].websiteUrl}</div>);
						tooltipInfo = (<div style={{width: '250px'}}>{tooltipInfo}</div>);
					}
				}
			} else if (actionName === constants.AUTOMATION_DESIGNER_SEND_SMS || actionName === constants.AUTOMATION_DESIGNER_ADD_SMS) {
				if (!!node && node.hasOwnProperty('details')) {
					if (!!nodeDetailArray && nodeDetailArray.length > 0 && node.details[0].from_number !== "") {
						tooltipInfo.push(<div key={actionName}
						                      style={styles.automationTooltipStyle}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.FROM_NUMBER + ": " + node.details[0].from_number}</div>)
						tooltipInfo.push(<div key={actionName}
						                      style={styles.automationTooltipStyle}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.MESSAGE + ": " + node.details[0].message}</div>)
					}
				}
			} else if (actionName === constants.AUTOMATION_DESIGNER_SMS_REPLY) {
				if (!!node && node.hasOwnProperty('details')) {
					if (!!nodeDetailArray && nodeDetailArray.length > 0 && node.details[0].replyText !== "") {
						tooltipInfo.push(<div key={actionName}
						                      style={styles.automationTooltipStyle}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.REPLY + ": " + node.details[0].replyText}</div>)
					}
				}
			}else if (actionName === constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN) {
				if (nodeDetailArray !== null && nodeDetailArray !== undefined && nodeDetailArray.length === 1) {
						let filter_type = nodeDetailArray[0].filter_type;

						let campaign = null
						if (filter_type === "0") {
							campaign = getLocalizedStrings().label.AUTOMATION_DESIGNER.TARGET_GROUPS + ": " + nodeDetailArray[0].campaign;
						} else {
							campaign = getLocalizedStrings().label.AUTOMATION_DESIGNER.TARGET_FILTER + ": " + nodeDetailArray[0].campaign;
						}
						tooltipInfo.push(<div key={actionName} style={styles.automationTooltipStyle}>{campaign}</div>)

					tooltipInfo = (<div style={{width: '300px'}}>{tooltipInfo}</div>);
				}
			}
			if (tooltipInfo.length === 0) {
				tooltipInfo.push(<div key={actionName}
				                      style={{...styles.automationTooltipStyle, width: '145px'}}>{infoMessage}</div>);
			}
		} catch (error) {
			console.error("Error in 'automationNode.js -> generateTooltipInfo()': " + error);
		}
		return tooltipInfo;
	}

	function doubleClickEvent(node) {
		if (isValidParam(node)) {
			openDrawer(node);
		}
	}


	let detailsArray = null;
	let websiteVisitUrl = "";

	let posX = "0px";
	let posY = "0px";
	let width = "0px";

	let nodeIcon = (<i className={iconName} style={styles.automationIcon}/>);
	let actionLabel = [];
	actionLabel=(isValidParam(node.actionLabel) &&
		isValidParam(getLocalizedStrings().label.AUTOMATION_DESIGNER[node.actionLabel])) ?
		getLocalizedStrings().label.AUTOMATION_DESIGNER[node.actionLabel] :
		node.actionLabel;
	if (node.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
		let nodeDetailArray = null;
		if (node.hasOwnProperty('details')) {
			nodeDetailArray = node['details'];
		}
		if (nodeDetailArray !== null && nodeDetailArray !== undefined && nodeDetailArray.length === 1) {
			let tempInterval = nodeDetailArray[0].interval;
			let tempIntervalMode = nodeDetailArray[0].interval_mode;

			if (tempInterval > 0) {
				if (tempIntervalMode === 'days') {
					tempInterval = tempInterval / 24;
				}
			}
			let strMode = tempIntervalMode === 'days' ? ' ' + getLocalizedStrings().label.AUTOMATION_DESIGNER['day(s)'] : ' ' + getLocalizedStrings().label.AUTOMATION_DESIGNER['hour(s)'];
			let interval = tempInterval + strMode;
			actionLabel = `${actionLabel} ${interval}`;
		}
	}

	let className = "actionHeader";
	let typeInfo = "";
	let tooltipInfo = [];

	if (node.posX > 0) {
		posX = node.posX + "px";
	}

	if (node.posY > 0) {
		posY = node.posY + "px";
	}

	if (node.actionName === constants.AUTOMATION_DESIGNER_START) {
		width = "78px";
	} else if (node.actionName === constants.AUTOMATION_DESIGNER_LANDING_PAGE ||
		node.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL) {
		width = "188px";
	} else if (node.actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK ||
		node.actionName === constants.AUTOMATION_DESIGNER_ASSIGN ||
		node.actionName === constants.AUTOMATION_DESIGNER_SCORE_REACHED) {
		width = "182px";
	} else if (node.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL ||
		node.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE ||
		node.actionName === constants.AUTOMATION_DESIGNER_CHANGE_GROUP ||
		node.actionName === constants.AUTOMATION_DESIGNER_UPDATE_FIELD ||
		node.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
		width = "185px";
	} else if (node.actionName === constants.AUTOMATION_DESIGNER_NOTIFY) {
		width = "184px";
	} else if (node.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL &&
		campStatus !== constants.AUTOMATION_DESIGNER_STATUS_ACTIVE) {
		width = "196px";
	} else if(node.actionName === constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN){
		width = "190px";
	}else {
		width = "180px";
	}

	let isShowDecision = false;
	isShowDecision = node.type === 1;

	if (node.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL || node.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL) {
		if (node.hasOwnProperty('details')) {
			detailsArray = node.details[0];
			if (isValidParam(detailsArray) && detailsArray.hasOwnProperty('name')) {
				if (detailsArray.name !== null && detailsArray.name !== "" && detailsArray.name !== undefined) {
					actionLabel = detailsArray.name;
				}
			}
		}
	}
	if (node.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE ||
		node.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL ||
		node.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
		let isNew = node.hasOwnProperty('is_new') ? node['is_new'] : false;
		if (node.hasOwnProperty('details') && !isNew) {
			detailsArray = node.details[0];
			if (isValidParam(detailsArray) && detailsArray.hasOwnProperty('template')) {
				let templateObject = detailsArray.template;
				if (isValidParam(templateObject) && templateObject.hasOwnProperty('subject')) {
					let emailSubject = templateObject.subject;
					if (isValidParam(emailSubject) && emailSubject && emailSubject !== "") {
						actionLabel = emailSubject;
					}
				}
			}
		}
	}
	if (node.actionName === constants.AUTOMATION_DESIGNER_LANDING_PAGE) {
		if (node.hasOwnProperty('details')) {
			detailsArray = node.details[0];
			if (isValidParam(detailsArray) && detailsArray.hasOwnProperty('object_indentifierText')) {
				actionLabel = detailsArray.object_indentifierText;
			}
		}
	}

	if (node.actionName === constants.AUTOMATION_DESIGNER_WEB_FORM) {
		if (node.hasOwnProperty('details')) {
			detailsArray = node.details[0];
			if (isValidParam(detailsArray) && detailsArray.hasOwnProperty('object_indentifierText')) {
				actionLabel = detailsArray.object_indentifierText;
			}
		}
	}

	let iconEdit = null;
	let iconEditTitle = null;
	if (campStatus === constants.AUTOMATION_DESIGNER_STATUS_RUNNING) {
		iconEdit = "fa fa-eye";
		iconEditTitle = getLocalizedStrings().label.AUTOMATION_DESIGNER.CLICK_TO_VIEW;
	} else {
		iconEdit = "fa fa-pencil";
		iconEditTitle = getLocalizedStrings().label.AUTOMATION_DESIGNER.CLICK_TO_EDIT;
	}

	let widthActionLabel = '0px';
	if (node.actionName === constants.AUTOMATION_DESIGNER_START) {
		widthActionLabel = '35px';
	} else if (node.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL || node.actionName === constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN) {
		widthActionLabel = '78px';
	} else if (node.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
		widthActionLabel = '90px';
	} else {
		widthActionLabel = '95px';
	}

	if (node.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE || node.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE ) {
		nodeIcon = (<i className="fa fa-envelope-o" style={styles.automationIcon}/>);
	} else if (node.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
		nodeIcon = (<i className="far fa-hourglass" style={styles.automationIcon}/>);
	} else if (node.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL) {
		nodeIcon = (<i className="fas fa-birthday-cake" style={styles.automationIcon}/>);
	}

	if (node.hasOwnProperty('actionName') && node.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
		if (node.hasOwnProperty('details')) {
			if (!isValidParam(node.details[0])) {
				node.details = [];
				node["details"][0] = {
					interval: 120,
					interval_mode: 'days'
				}
				node.interval_mode = 'days';
			}
		}
	}

	let automationDesignerDecisionNo = styles.automationDesignerDecisionNo;
	if (node.actionName === constants.AUTOMATION_DESIGNER_UNSUBSCRIBE || node.actionName === constants.AUTOMATION_DESIGNER_SCORE_REACHED) {
		automationDesignerDecisionNo = {...automationDesignerDecisionNo, cursor: "default", color: "#b5b5b5"}
	} else if (node.actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK) {
		if (node.hasOwnProperty('details')) {
			detailsArray = node.details[0];
			if (isValidParam(detailsArray)) {
				if (detailsArray.hasOwnProperty('websiteUrl')) {
					websiteVisitUrl = detailsArray.websiteUrl;
					if (websiteVisitUrl !== null && websiteVisitUrl !== undefined && websiteVisitUrl !== "") {
						automationDesignerDecisionNo = {
							...automationDesignerDecisionNo,
							cursor: "default",
							color: "#b5b5b5"
						}
					} else {
						automationDesignerDecisionNo = {
							...automationDesignerDecisionNo,
							cursor: "pointer",
							color: "#ee5a00"
						}
					}
				}
			}
		}
	} else if (node.actionName === constants.AUTOMATION_DESIGNER_EMAIL_OPEN) {
		automationDesignerDecisionNo = {...automationDesignerDecisionNo, cursor: "pointer", color: "#ee5a00"}
	}

	let data = automationCampaign.data;
	let autoTempName = getStringParam(data.template_name);
	if (autoTempName === constants.BIRTHDAY_CAMPAIGN_TEMPLATE_NAME || autoTempName === constants.PET_BIRTHDAY_CAMPAIGN_TEMPLATE_NAME) {
		automationDesignerDecisionNo = {...automationDesignerDecisionNo, cursor: "default", color: "#b5b5b5"}
	}

	if (node.hasOwnProperty('is_error')) {
		let isError = node.is_error;
		if (isError) {
			if (node.hasOwnProperty('arr_error')) {
				let arrError = node.arr_error;
				if (!!arrError && arrError.length > 0) {
					className = "actionHeaderError";
					typeInfo = "error";
					arrError.forEach((objError, i) => tooltipInfo.push(<p key={i}>{objError.error_msg}</p>));
				}
			}
		} else {
			className = "actionHeader";
		}
	} else {
		className = "actionHeader";
		if (node.actionName === constants.AUTOMATION_DESIGNER_START ||
			node.actionName === constants.AUTOMATION_DESIGNER_EMAIL_OPEN ||
			node.actionName === constants.AUTOMATION_DESIGNER_UNSUBSCRIBE) {
			tooltipInfo.push(<p key={node.actionName}
			                    style={styles.automationTooltipStyle}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.DRAG_TO_MOVE}</p>);
		} else {
			tooltipInfo = generateTooltipInfo(node);
		}
	}

	let showDeleteIcon = true;
	let editIconAdlCss = {}
	let actionStyles = {padding: '0 3px 0 3px'}
	if (node.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE || node.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL) {
		showDeleteIcon = false;
		editIconAdlCss = {paddingRight: 8}
		widthActionLabel = '110px';
	}

	return (
		<div id={node.childId} ref={ref} className="handle"
		     style={{left: posX, top: posY, position: 'absolute', width: width}}>
			<div style={{width: width}}>
				<div id={node.childId + '_H'}
				     title="" className={className}
				     style={{
					     width: width,
					     display: 'flex',
					     alignItems: 'center',
					     padding: '5px 5px 8px',
					     justifyContent: 'space-between'
				     }}
				>
					<div id={node.childId + '_I'}
					     title={getLocalizedStrings().label.AUTOMATION_DESIGNER.DRAG_TO_CONNECT}
					     style={{textAlign: 'right', verticalAlign: 'middle', paddingLeft: '5px'}}
					>
						{nodeIcon}
					</div>
					<div id={node.childId + '_B'}
					     onDoubleClick={() => doubleClickEvent(node)}
					     style={{
						     whiteSpace: 'nowrap',
						     overflow: 'hidden',
						     textOverflow: 'ellipsis',
						     width: widthActionLabel,
						     minWidth: widthActionLabel,
							 color:'white'
					     }}
					     data-tip="" data-for={node.childId + '_INFO'}
					>
					<Tooltip title={tooltipInfo} id={node.childId + '_INFO'} 
					type={typeInfo} effect='solid' place="bottom">
						<span className='automation_node' style={{color:'white', fontSize:13}}>{actionLabel}</span>
					</Tooltip>

					</div>
						

					<div style={{display: 'flex'}}>
						{
							((node.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL ||
								node.actionName === constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN ||
									node.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL) &&
								campStatus !== constants.AUTOMATION_DESIGNER_STATUS_ACTIVE && node.id > 0) &&

							<div id={node.childId_I} title="" style={{...actionStyles}}>

								<i className="fa fa-bar-chart" style={styles.automationIcon}
								   title={getLocalizedStrings().label.AUTOMATION_DESIGNER.CLICK_TO_SEE_REPORT}
								   onClick={() => showReport(node)}/>
							</div>
							
						}

						{
							(node.actionName !== constants.AUTOMATION_DESIGNER_START &&
								node.actionName !== constants.AUTOMATION_DESIGNER_EMAIL_OPEN &&
								node.actionName !== constants.AUTOMATION_DESIGNER_UNSUBSCRIBE &&
								node.actionName !== constants.AUTOMATION_DESIGNER_SMS_REPLY) &&

							<div id={node.childId + '_L'} title={iconEditTitle}
							     style={{...actionStyles, ...editIconAdlCss}}>
								<i className={iconEdit} style={styles.automationIcon} onClick={() => openDrawer(node)}/>
							</div>
						}
						{showDeleteIcon &&
							<div id={node.childId + '_R'}
							     title={getLocalizedStrings().label.AUTOMATION_DESIGNER.CLICK_TO_REMOVE}
							     style={{
								     ...actionStyles,
								     paddingRight: '5px'
							     }}
							>
								{(node.actionName !== constants.AUTOMATION_DESIGNER_START) &&
									(<i className="fa fa-times" style={styles.automationIcon}
									    onClick={() => deleteNode(node)}
									/>)
								}
							</div>
						}
					</div>

				</div>
			</div>
			<div className="actionFooter">
				{
					(isShowDecision) &&
					<div>
						<div id={node.childId + "_N"}
						     style={automationDesignerDecisionNo}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.NO}</div>
						<div id={node.childId + "_Y"}
						     style={styles.automationDesignerDecisionYes}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.YES}</div>
					</div>
				}
			</div>
		</div>
	);
}
export default AutomationNode;