import store from '../../../src/services/store';
import { isValidParam} from '..//helper/parameterVerifier';
import { constants } from '..//constants/constants';
import { getObjectParam } from '..//helper/parameterVerifier';

const EMPTY_STRING = "";
const UNDEFINED = "undefined";
const NULL = "null";

function getNodeByChildId(arrNodes, childId) {
    arrNodes = arrNodes.filter(function (el) {
        // eslint-disable-next-line
        return el.childId == childId.toString().replace("_B", "").replace("_Y", "").replace("_N", "").replace("_I", "");
    });
    return arrNodes[0];
}

function getParentDecision(arrNodes, id) {
    if (isValidParam(id)) {
        let objChildNode = getNodeByChildId(arrNodes, id);
        if (isValidParam(objChildNode) && objChildNode.hasOwnProperty('type')) {
            if (objChildNode.type === 1) {
                return objChildNode;
            } else if (objChildNode.actionName === constants.AUTOMATION_DESIGNER_WEB_FORM ||
                objChildNode.actionName === constants.AUTOMATION_DESIGNER_WEBSITE_VISIT ||
                objChildNode.actionName === constants.AUTOMATION_DESIGNER_LANDING_PAGE) {
                return objChildNode;
            }
        } else {
            return "false";
        }
        return getParentDecision(arrNodes, objChildNode.parentId);
    }
}

function getChildNodeIds(nodesArray, id, nodeIds, pendingItem) {
    let arrNodes = getNodeByParentId(nodesArray, id);
    if (isValidParam(arrNodes) && arrNodes.length > 0) {
        arrNodes = arrNodes.filter(function (el) {
            return el.nodeStatusFlg !== 3;
        });
    }
    if (isValidParam(arrNodes) && arrNodes.length > 0) {
        let fChild = arrNodes[0].childId;
        for (var i = 1; i < arrNodes.length; i++) {
            if (pendingItem !== null && pendingItem !== undefined && pendingItem !== "") {
                pendingItem += ", " + arrNodes[i].childId;
            } else {
                pendingItem = arrNodes[i].childId;
            }
        }
        if (nodeIds === "") {
            nodeIds = fChild;
        } else {
            nodeIds += ", " + fChild;
        }
        return getChildNodeIds(nodesArray, fChild, nodeIds, pendingItem);
    } else {
        if (pendingItem !== null && pendingItem !== undefined && pendingItem !== "") {
            let arrNode;
            let fChild;

            arrNode = pendingItem.toString().split(", ");
            fChild = arrNode[0];

            if (nodeIds === "") {
                nodeIds = fChild;
            } else {
                nodeIds += ", " + fChild;
            }
            arrNode = arrNode.filter(function (el) {
                return el !== fChild;
            });
            pendingItem = arrNode.join(", ");
            return getChildNodeIds(nodesArray, fChild, nodeIds, pendingItem);
        } else {
            return nodeIds;
        }
    }
}

function getNodeByParentId(arrNodes, parentId) {
    let nodesArr = arrNodes.filter(function (el) {
        // eslint-disable-next-line
        return el.parentId == parentId.toString().replace("_B", "").replace("_Y", "").replace("_N", "").replace("_I", "");
    });
    return nodesArr;
}

function getParentByActionName(arrNodes, id, actionName) {
    let objChildNode = getNodeByChildId(arrNodes, id);
    if (checkNode(objChildNode)) {
        if (objChildNode.hasOwnProperty('actionName') && objChildNode.actionName === actionName) {
            return objChildNode;
        }
        return getParentByActionName(arrNodes, objChildNode.parentId, actionName);
    }
}

function getDaysBetween(date1, date2) {
    let one_day = 1000 * 60 * 60 * 24;
    let date1_ms = date1.getTime();
    let date2_ms = date2.getTime();
    let difference_ms = date2_ms - date1_ms;
    return Math.round(difference_ms / one_day);
}

function checkNode(n) {
    let validNode = false;
    if (n !== null && n !== undefined) {
        if (n !== EMPTY_STRING && n !== UNDEFINED && n !== NULL) {
            validNode = true;
        }
    }
    return validNode;
}

function validateMessagesChildLink(arrNodes, childId) {
    var arrChildNode = null;
    var isValid = true;
    if (isValidParam(childId) && childId > 0) {
        arrChildNode = getNodeByParentId(arrNodes, childId);
        arrChildNode = arrChildNode.filter(function (el) {
            return (el.parentId === childId && el.actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK);
        });
        if (isValidParam(arrChildNode) && arrChildNode.length > 1) {
            arrChildNode.forEach(function (childNode, index) {
                if (isValidParam(childNode) && (!childNode.hasOwnProperty('details') ||
                    (childNode.hasOwnProperty('details') && childNode.details.length > 0 &&
                        childNode.details.hasOwnProperty('websiteUrl') && childNode.details.websiteUrl === ""))) {
                    isValid = false;
                    return;
                }
            });
        }
    }
    return isValid;
}

function traverseNodeList(arrNodes) {
    var appliedWhen = "";
    var seq = 0;
    arrNodes.forEach((objectNode, i) => {
        if (objectNode !== null && objectNode.hasOwnProperty('childId') && objectNode.hasOwnProperty('actionName')) {
            if (objectNode.actionName !== constants.AUTOMATION_DESIGNER_START) {
                let parentnodeDetailsObj = getParentDecision(arrNodes, objectNode.childId);
                let parentnodeDetails = "";
                if (parentnodeDetailsObj !== "false") {
                    parentnodeDetails = parentnodeDetailsObj;
                }
                if (parentnodeDetails && parentnodeDetails.hasOwnProperty('actionName')) {
                    appliedWhen = parentnodeDetails['actionName'];
                    if (parentnodeDetails['actionName'] === constants.AUTOMATION_DESIGNER_WEB_FORM) {
                        appliedWhen = constants.AUTOMATION_APPLIEDWHEN_WEB_FORM_SUBMIT;
                    } else if (parentnodeDetails['actionName'] === constants.AUTOMATION_DESIGNER_WEBSITE_VISIT) {
                        appliedWhen = constants.AUTOMATION_APPLIEDWHEN_VISITED_WEBSITE;
                    } else if (parentnodeDetails['actionName'] === constants.AUTOMATION_DESIGNER_LANDING_PAGE) {
                        appliedWhen = constants.AUTOMATION_APPLIEDWHEN_LANDING_PAGE_SUBMIT;
                    }
                } else {
                    appliedWhen = "";
                }
                objectNode.appliedWhen = appliedWhen;
            }

            if (objectNode.actionName !== constants.AUTOMATION_DESIGNER_WAIT) {
                if(objectNode.hasOwnProperty('details') && isValidParam(objectNode.details[0])) {
                    if (isValidParam(objectNode.details[0].interval_mode)) {
                        objectNode.interval_mode = objectNode.details[0].interval_mode;
                    }
                }
            }

            if (objectNode.actionName !== constants.AUTOMATION_DESIGNER_START) {
                let parentnodeDetailsObj = getParentDecisionOrWait(arrNodes, objectNode.childId);
                let parentnodeDetails = "";
                if (parentnodeDetailsObj !== "false") {
                    parentnodeDetails = parentnodeDetailsObj;
                }
                if (parentnodeDetails && parentnodeDetails.hasOwnProperty('actionName')) {
                    appliedWhen = parentnodeDetails['actionName'];
                    if (parentnodeDetails['actionName'] === constants.AUTOMATION_DESIGNER_WAIT) {
                        // objectNode.decisionFlg = 2;
                        if (parentnodeDetails.details[0] !== null && parentnodeDetails.details[0].hasOwnProperty('interval') &&
                            parentnodeDetails.details[0].interval > 0) {
                            objectNode.intervalWait = parentnodeDetails.details[0].interval;
                        } else {
                            objectNode.intervalWait = 0;
                        }
                    } else {
                        objectNode.decisionFlg = 1;
                        objectNode.intervalWait = 0;
                    }
                    objectNode.decisionFlg = parentnodeDetails.decisionFlg;
                } else {
                    // objectNode.decisionFlg = 0;
                    objectNode.intervalWait = 0;
                }
            }
            if ((objectNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL || objectNode.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL) && isValidParam(objectNode) &&
                objectNode.hasOwnProperty("details") && objectNode.details[0]) {
                let sDate = null;
                let hDate = null;
                let childArr = [];
                if(objectNode.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL){
                    childArr = getParentByActionChildName(arrNodes, objectNode.childId, constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE, []);
                } else {
                    childArr = getParentByActionChildName(arrNodes, objectNode.childId, constants.AUTOMATION_DESIGNER_ADD_MESSAGE, []);
                }
                seq = 0;
                // if (objectNode.details[0].hasOwnProperty("startDate") && isValidParam(objectNode.details[0].startDate) &&
                //     objectNode.details[0].hasOwnProperty("hDate") && isValidParam(objectNode.details[0].hDate)) {
                //     sDate = objectNode.details[0].startDate;
                //     hDate = objectNode.details[0].hDate;
                //     arrNodes = setAddMessageManipulation(arrNodes, childArr, seq, sDate, hDate, objectNode.childId);
                // }
                sDate = "";
                hDate = "";
                if (objectNode.details[0].hasOwnProperty("startDate") && isValidParam(objectNode.details[0].startDate) &&
                    objectNode.details[0].hasOwnProperty("hDate") && isValidParam(objectNode.details[0].hDate)) {
                    sDate = objectNode.details[0].startDate;
                    hDate = objectNode.details[0].hDate;
                }
                arrNodes = setAddMessageManipulation(arrNodes, childArr, seq, sDate, hDate, objectNode.childId);
                
                objectNode.referenceId = 0;
            } else if (objectNode.actionName === constants.AUTOMATION_DESIGNER_WEB_FORM ||
                objectNode.actionName === constants.AUTOMATION_DESIGNER_WEBSITE_VISIT ||
                objectNode.actionName === constants.AUTOMATION_DESIGNER_LANDING_PAGE) {
                objectNode.referenceId = 0;
            } else if (objectNode.actionName !== constants.AUTOMATION_DESIGNER_START) {
                try {
                    if (objectNode.type === constants.AUTOMATION_DESIGNER_ACTIONTYPE_ONE) {
                        objectNode.referenceId = objectNode.parentId;
                    } else if ((objectNode.type === constants.AUTOMATION_DESIGNER_ACTIONTYPE_TWO ||
                        objectNode.type === constants.AUTOMATION_DESIGNER_ACTIONTYPE_THREE) &&
                        objectNode.actionName !== constants.AUTOMATION_DESIGNER_ADD_MESSAGE && objectNode.parentId !== 0) {
                        let objectChild = getParentDecision(arrNodes, objectNode.childId);
                        if (objectChild !== null && objectChild !== undefined && objectChild !== false &&
                            objectChild !== "false" && objectChild.hasOwnProperty("childId")) {
                            if (objectChild.actionName === constants.AUTOMATION_DESIGNER_WEB_FORM ||
                                objectChild.actionName === constants.AUTOMATION_DESIGNER_WEBSITE_VISIT ||
                                objectChild.actionName === constants.AUTOMATION_DESIGNER_LANDING_PAGE) {
                                objectNode.referenceId = objectChild.childId;
                            } else {
                                let objParentAction = getNodeByChildId(arrNodes, objectChild.parentId);
                                if (objParentAction !== null && objParentAction !== undefined) {
                                    objectNode.referenceId = objParentAction.childId;
                                }
                            }
                        }
                    }
                } catch (error) {
                    console.error("Error in 'automationUtils.js -> setAddMessageManipulation()': " + error);
                }
            } 
            if (objectNode.actionName === constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN && objectNode?.details?.length > 0) {
                seq = 0;
                let childArr = getParentByActionChildName(arrNodes, objectNode.childId, constants.AUTOMATION_DESIGNER_ADD_SMS, []);
                arrNodes = setAddSmsManipulation(arrNodes, childArr, seq, objectNode.childId);
                objectNode.referenceId = 0;
            }
        }
    });
    return arrNodes;
}

function setAddMessageManipulation(arrNodes, childArr, seq, sDate, hDate, referenceId) {
    try {
        if (childArr !== null && childArr !== undefined && childArr.length > 0) {
            let temArrNodes = arrNodes;
            for (var ca = 0; ca < childArr.length; ca++) {
                for (var i = 0; i < temArrNodes.length; i++) {
                    if (temArrNodes[i].actionName === childArr[ca].actionName && arrNodes[i].childId === childArr[ca].childId) {
                        let tempObj = {};
                        tempObj = childArr[ca];
                        seq++;
                        if (tempObj.hasOwnProperty('details')) {
                            if (seq === 1) {
                                if (tempObj.details[0] !== undefined && tempObj.details[0] !== null && tempObj.details[0] !== "") {
                                    tempObj.details[0].hDate = hDate;
                                    tempObj.details[0].startDate = sDate;
                                }
                            }
                            if (tempObj.details[0] !== undefined && tempObj.details[0] !== null && tempObj.details[0] !== "") {
                                tempObj.details[0].sequence = seq;
                            }
                        }
                        tempObj.detailStatusFlg = 2;
                        //arrNodes.splice(i, 1, tempObj);
                        arrNodes[i].referenceId = referenceId;
                        break;
                    }
                }
            }
        }
    } catch (error) {
        console.error("Error in 'automationUtils.js -> setAddMessageManipulation()': " + error);
    }
    return arrNodes;
}

function setAddSmsManipulation(arrNodes, childArr, seq, referenceId) {
    try {
        if (childArr !== null && childArr !== undefined && childArr.length > 0) {
            let temArrNodes = arrNodes;
            for (var ca = 0; ca < childArr.length; ca++) {
                for (var i = 0; i < temArrNodes.length; i++) {
                    if (temArrNodes[i].actionName === childArr[ca].actionName && arrNodes[i].childId === childArr[ca].childId) {
                        let tempObj = {};
                        tempObj = childArr[ca];
                        seq++;
                        if (tempObj.hasOwnProperty('details')) {
                            if (tempObj.details[0] !== undefined && tempObj.details[0] !== null && tempObj.details[0] !== "") {
                                tempObj.details[0].sequence = seq;
                            }
                        }
                        tempObj.detailStatusFlg = 2;
                        arrNodes[i].referenceId = referenceId;
                        break;
                    }
                }
            }
        }
    } catch (error) {
        console.error("Error in 'automationUtils.js -> setAddSmsManipulation()': " + error);
    }
    return arrNodes;
}


function getParentByActionChildName(arrNodes, id, actionName, childArray) {
    try {
        let objChildNode = getNodeByParentId(arrNodes, id);
        if (isValidParam(objChildNode) && objChildNode.length > 0) {
            objChildNode.forEach((objectNode, i) => {
                if (isValidParam(objectNode) &&  objectNode.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
                    objChildNode = getNodeByParentId(arrNodes, objectNode.childId);
                }
            });
        }
        
        if (isValidParam(objChildNode)) {
            objChildNode = objChildNode.filter(function (el) {
                return el.detailStatusFlg !== 3 && el.nodeStatusFlg !== 3
            });
            if (isValidParam(objChildNode) && objChildNode.length > 0) {
                objChildNode.forEach((objectNode, i) => {
                    if (objectNode.hasOwnProperty("actionName") && objectNode.actionName === actionName) {
                        childArray.push(objectNode);
                        if (objectNode.hasOwnProperty("childId") && objectNode.childId !== null &&
                            objectNode.childId !== undefined && objectNode.childId !== "" && objectNode.childId > 0) {
                            return getParentByActionChildName(arrNodes, objectNode.childId, actionName, childArray);
                        }
                    }
                });
            }
        }
    } catch (error) {
        console.error("Error in 'automationUtils.js -> getParentByActionChildName()': " + error);
    }
    return childArray;
}

function getParentDecisionOrWait(arrNodes, id) {
    try {
        let objChildNode = getNodeByChildId(arrNodes, id);
        if (isValidParam(objChildNode)) {
            if (objChildNode.hasOwnProperty('type')) {
                if (objChildNode.type === constants.AUTOMATION_DESIGNER_ACTIONTYPE_ONE ||
                    objChildNode.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
                    return objChildNode;
                } else if (objChildNode.actionName === constants.AUTOMATION_DESIGNER_WEB_FORM ||
                    objChildNode.actionName === constants.AUTOMATION_DESIGNER_WEBSITE_VISIT) {
                    return objChildNode;
                }
            } else {
                return "false";
            }
            return getParentDecisionOrWait(arrNodes, objChildNode.parentId);
        }
    } catch (error) {
        console.error("Error in 'automationUtils.js -> getParentDecisionOrWait()': " + error);
    }
}

function getActionByName(arrActions, actionName) {
    try {
        arrActions = arrActions.filter(function (el) {
            return el.actionName.trim() === actionName.trim();
        });
        return arrActions[0];
    } catch (error) {
        console.error("Error in 'automationUtils.js -> getActionByName()': " + error);
    }
}

function getMaxId(arrNodes) {
    let maxid = 0;
    try {
        arrNodes.forEach((obj, i) => {
            if (obj.childId > maxid) {
                maxid = obj.childId;
            }
        });
    } catch (error) {
        console.error("Error in 'automationUtils.js -> getMaxId()': " + error);
    }
    return maxid;
}

function removeError(objNode) {
    try {
        if (objNode.hasOwnProperty('is_error')) {
            delete objNode['is_error'];
        }
        if (objNode.hasOwnProperty('arr_error')) {
            delete objNode['arr_error'];
        }
    } catch (error) {
        console.error("Error in 'automationUtils.js -> removeError()': " + error);
    }
    return objNode;
}

function showPlusIcon(arrNodes, objChild) {
    try {
        let objParent = getNodeByChildId(arrNodes, objChild.parentId);
        if (isValidParam(objParent)) {
            if (objParent.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE &&
                objChild.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
                document.getElementById(objParent.childId + "_P").style.display = 'block';
            }
        }
    } catch (error) {
        console.error("Error in 'automationUtils.js -> showPlusIcon()': " + error);
    }
}

function getNodesByActionName(actionName) {//arrNodes, 
    let arrNodes;
    try {
        let state = store.getState();
        arrNodes = state.automationCampaign.data.nodes;
        arrNodes = arrNodes.filter(function (el) {
            return el.actionName === actionName;
        });
    } catch (error) {
        console.error("Error in 'automationUtils.js -> getNodesByActionName()': " + error);
    }
    return arrNodes;
}

function updateNodesData(nodeObject) {
    let campaignData;
    try {
        let state = store.getState();
        campaignData = state.automationCampaign.data;
        let arrNodes = state.automationCampaign.data.nodes;
        arrNodes.forEach((objectNode, i) => {
            if (isValidParam(objectNode) && objectNode.hasOwnProperty('childId') &&
                isValidParam(nodeObject) && nodeObject.hasOwnProperty('childId') &&
                objectNode.childId === nodeObject.childId) {
                arrNodes.splice(i, 1, nodeObject);
            }
        });
        campaignData.nodes = arrNodes;
    } catch (error) {
        console.error("Error in 'automationUtils.js -> updateNodesData()': " + error);
    }
    return campaignData;
}

function getRequiredParentNode(childId, parentActionName) {
    let retrunObj;
    try {
        let state = store.getState();
        let arrNodes = state.automationCampaign.data.nodes;
        let sourceObject = getObjectParam(getNodeByChildId(arrNodes, childId));
        if (childId >= 2) {
            if (sourceObject.actionName === parentActionName) {
                retrunObj = sourceObject;
            } else {
                retrunObj = getRequiredParentNode(sourceObject['parentId'], parentActionName);
            }
        }
    } catch (error) {
        console.error("Error in 'automationUtils.js -> getRequiredParentNode()': " + error);
    }
    return retrunObj;
}

export {
    getNodeByChildId,
    getParentDecision,
    getNodeByParentId,
    getChildNodeIds,
    getParentByActionName,
    checkNode,
    getDaysBetween,
    validateMessagesChildLink,
    traverseNodeList,
    getParentByActionChildName,
    getActionByName,
    getMaxId,
    removeError,
    showPlusIcon,
    getNodesByActionName,
    updateNodesData,
    getRequiredParentNode,
}