import React, { useState, useEffect, useRef } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Button';
import Box from '@mui/material/Button';
import { styles } from '../../../services/constants/styles';
import Button from '@mui/material/Button';
import * as ParameterVerifier from '../../../services/helper/parameterVerifier';
import { constants } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { connect } from 'react-redux';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { Menu, Select, MenuItem, InputLabel, FormControl, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { getObjectParam } from '../../../services/helper/parameterVerifier';
import { timeZoneMap } from '../../../services/helper/utils';
import { getLocationList } from '../../../services/actions/importAction';
import * as sfDialogs from '../components/sfDialogs';
import moment from 'moment';
import ShowCircularProgress from '../components/circularProgress';
import { useDispatch, useSelector } from "react-redux";

const duration = [
    { key: '-1', label: 'None' },
    { key: '1 AM', label: '1 AM' },
    { key: '2 AM', label: '2 AM' },
    { key: '3 AM', label: '3 AM' },
    { key: '4 AM', label: '4 AM' },
    { key: '5 AM', label: '5 AM' },
    { key: '6 AM', label: '6 AM' },
    { key: '7 AM', label: '7 AM' },
    { key: '8 AM', label: '8 AM' },
    { key: '9 AM', label: '9 AM' },
    { key: '10 AM', label: '10 AM' },
    { key: '11 AM', label: '11 AM' },
    { key: '12 PM', label: '12 PM' },
    { key: '13 PM', label: '1 PM' },
    { key: '14 PM', label: '2 PM' },
    { key: '15 PM', label: '3 PM' },
    { key: '16 PM', label: '4 PM' },
    { key: '17 PM', label: '5 PM' },
    { key: '18 PM', label: '6 PM' },
    { key: '19 PM', label: '7 PM' },
    { key: '20 PM', label: '8 PM' },
    { key: '21 PM', label: '9 PM' },
    { key: '22 PM', label: '10 PM' },
    { key: '23 PM', label: '11 PM' },
    { key: '00 AM', label: '12 AM' },

];
const advance_notice_type = {
    hours: 'Hour(s)',
    days: 'Day(s)'
}


const CompanySchedulerAvailability = ({ }) => {

    const app = useSelector((state) => state.app);
    const userlist = useSelector((state) => state.userlist);
    const dispatch = useDispatch();


    const [buttonVisibility, setButtonVisibility] = useState(true);
    const [showSelectField, setShowSelectField] = useState(false);
    const [allSelectedUser, setAllSelectedUser] = useState([]);
    const [valueUser, setValueUser] = useState('');
    const [zipTextValue, setZipTextValue] = useState('');
    const [showContent, setShowContent] = useState(false);
    const [showZipDetails, setShowZipDetails] = useState(false);
    const [showAddZipButton, setShowAddZipButton] = useState(false);
    const [allZipCodes, setAllZipCodes] = useState([]);
    const [getDtaFields, setGetDtaFields] = useState(false);
    const [showAddZipSection, setShowAddZipSection] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedUserId, setSelectedUserId] = useState('');
    const [days, setDays] = useState([{ key: 1, label: 'Monday' },
    { key: 2, label: 'Tuesday' },
    { key: 3, label: 'Wednesday' },
    { key: 4, label: 'Thursday' },
    { key: 5, label: 'Friday' },
    { key: 6, label: 'Saturday' },
    { key: 7, label: 'Sunday' }]);

    const [availabilityInfo, setAvailabilityInfo] = useState({});
    const [defaultStartTime, setDefaultStartTime] = useState(8);
    const [defaultEndTime, setDefaultEndTime] = useState(17);
    const [defaultStartTimeMeridiem, setDefaultStartTimeMeridiem] = useState('AM');
    const [defaultEndTimeMeridiem, setDefaultEndTimeMeridiem] = useState('PM');
    const [defaultWeekendTime, setDefaultWeekendTime] = useState(-1);
    const [selectedItem, setSelectedItem] = useState({});
    const [scheduleInfo, setScheduleInfo] = useState({
        min_advance_notice: 2,
        min_advance_notice_type: "hours",
    });

    const [showZipField, setShowZipField] = useState(false);
    const [locationList, setLocationList] = useState([]);
    const [mounted, setMounted] = useState(false);
    const [isZipDisable, setIsZipDisable] = useState(false);
    const [finalLocationList, setFinalLocationList] = useState([]);

    useEffect(() => {
        getAvailableData();
        getLocationLists();
    }, [])


    const getLocationLists = () => {
        let promise = Promise.resolve(getLocationList());
        if (ParameterVerifier.isValidParam(promise)) {
            promise.then((response) => {
                if (ParameterVerifier.isValidParam(response)) {
                    setMounted(true);
                    if (response.records !== undefined) {
                        setLocationList([...response.records]);
                    }
                }
            });
        }
    }

    const populateAvalaibleData = (availabilityArray, option) => {
        let _availabilityInfo = null;
        _availabilityInfo = availabilityArray;
        let min_advance_notice;
        let min_advance_notice_type;
        let _scheduleInfo = scheduleInfo;
        if (Object.keys(_availabilityInfo).length > 0) {
            setAvailabilityInfo(_availabilityInfo);
        } else {
            setAvailabilityInfo(availabilityArray);

        }

        let obj = null;
        let dayInfo = null;
        days.map((d, index) => {
            obj = new Object();
            obj.day_id = d.key;

            if (_availabilityInfo.hasOwnProperty(d.key)) {
                dayInfo = getObjectParam(_availabilityInfo[d.key]);

                if (dayInfo.hasOwnProperty('startTime')) {
                    obj.startTime = dayInfo.startTime
                } else {
                    if (dayInfo.start_time == -1) {
                        obj.startTime = "-1";
                    } else {
                        if (dayInfo.start_time == 0) {
                            dayInfo.start_time = "00";
                        }
                        obj.startTime = dayInfo.start_time + " " + dayInfo.start_time_meridiem;
                    }
                }

                if (dayInfo.hasOwnProperty('endTime')) {
                    obj.endTime = dayInfo.endTime
                } else {
                    if (dayInfo.end_time == -1) {
                        obj.endTime = "-1";
                    } else {
                        if (dayInfo.end_time == 0) {
                            dayInfo.end_time = "00";
                        }
                        obj.endTime = dayInfo.end_time + " " + dayInfo.end_time_meridiem;
                    }
                }

                obj.start_time = dayInfo.start_time;
                obj.start_time_meridiem = dayInfo.start_time_meridiem;
                obj.end_time = dayInfo.end_time;
                obj.end_time_meridiem = dayInfo.end_time_meridiem;
                obj.min_advance_notice = dayInfo.min_advance_notice;
                obj.min_advance_notice_type = dayInfo.min_advance_notice_type;
                obj.userId = parseInt(dayInfo.userId);
                obj.zipCode = dayInfo.zipCode;
                 let temp = scheduleInfo;
                 temp.min_advance_notice =dayInfo.min_advance_notice;
                 temp.min_advance_notice_type = dayInfo.min_advance_notice_type;
                 setScheduleInfo({...temp})
                dayInfo = null;

            } else {
                if (d.key == 6 || d.key == 7) {

                    obj.startTime = defaultWeekendTime
                    obj.start_time = -1;
                    obj.start_time_meridiem = defaultWeekendTime;
                    obj.endTime = defaultWeekendTime;
                    obj.end_time = -1;
                    obj.end_time_meridiem = defaultWeekendTime;
                    obj.min_advance_notice = scheduleInfo['min_advance_notice'];
                    obj.min_advance_notice_type = scheduleInfo['min_advance_notice_type'];
                    obj.userId = parseInt(selectedUserId);
                    obj.zipCode = zipTextValue;
                } else {
                    obj.startTime = defaultStartTime + ' ' + defaultStartTimeMeridiem;
                    obj.start_time = defaultStartTime;
                    obj.start_time_meridiem = defaultStartTimeMeridiem;
                    obj.endTime = defaultEndTime + ' ' + defaultEndTimeMeridiem;
                    obj.end_time = defaultEndTime;
                    obj.end_time_meridiem = defaultEndTimeMeridiem;
                    obj.min_advance_notice = scheduleInfo['min_advance_notice'];
                    obj.min_advance_notice_type = scheduleInfo['min_advance_notice_type'];
                    obj.userId = parseInt(selectedUserId);
                    obj.zipCode = zipTextValue;
                }
            }
            _availabilityInfo[d.key] = obj;
            obj = null;
        });

        setAvailabilityInfo(_availabilityInfo)
        //setScheduleInfo({ ..._scheduleInfo });
    }

    const getAvailableData = () => {
        try {
            let zipCodeArray = [];
            let availabilityArray = [];
            let userIds = [];
            let _allSelectedUser = [];
            let user;
            var promise = Promise.resolve(HTTPClient.get(endPoints.COMPANY_SCHEDULER.GET_COMPANY_SCHEDULER_AVAILABILITY, null));
            if (ParameterVerifier.isValidParam(promise)) {
                promise.then((response) => {
                    if (ParameterVerifier.isValidParam(response) && response != '') {
                        userIds = response.selectedUserIds;
                        zipCodeArray = response.zipCodeList;
                        availabilityArray = response.availabilityInfo;
                        let userList = userlist.data;
                          user = userList.find(e => { return e.id == userIds[0] });

                        let singleSelectedValue = [];
                        userIds.map((fld, i) => {
                            singleSelectedValue = userList.find(e => {
                                return e.id == fld;
                            });
                            _allSelectedUser.push(singleSelectedValue);
                        });

                        _allSelectedUser = _allSelectedUser.filter((f)=>{
                            return f != undefined
                        })
                        populateAvalaibleData(availabilityArray);
                        setMounted(true);
                        setAllSelectedUser([..._allSelectedUser]);
                        setSelectedUser(_allSelectedUser[0]?.value);
                        setSelectedUserId(_allSelectedUser[0]?.id);
                        setAvailabilityInfo(availabilityArray);
                        setShowZipDetails(true);
                        setShowContent(true);
                        setButtonVisibility(true);
                        setShowAddZipSection(true);
                        setShowZipField(true);
                        setIsZipDisable(true);
                        getUserdetails(_allSelectedUser[0])
                    }

                });
            }
        } catch (error) {
            console.error("Error in 'UnitCrm.js -> getUnitCrmSetup()':" + error);
        }

    }

    const showFields = () => {

        setShowSelectField(true);
        setButtonVisibility(false);
        setShowAddZipSection(false);
        setShowZipDetails(false);
        setValueUser('');
        // this.setState({ showSelectField: true, buttonVisibility: false, showAddZipSection: false, showZipDetails: false, valueUser: '' })

    }
    const changeUser = (event) => {
        let value = event.target.value;

        setValueUser(value);

    }

    const showUserData = () => {
        if (valueUser == "") {
            dispatch(showCustomSnackBar("Please select user", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else {
            let _availabilityInfo = availabilityInfo;
            let _userList = userlist.data;
            let singleSelectedValue = [];
            singleSelectedValue = _userList.find(e => {
                return e.value == valueUser;
            });
            let _allSelectedUser = allSelectedUser;
            _allSelectedUser =_allSelectedUser.filter((f)=>{
                return f !== undefined ;
            })
            let allUsers = _allSelectedUser.map((row) => {
                return row.value
            })
            _allSelectedUser.push(singleSelectedValue);

            days.map((d, index) => {
                let obj = getObjectParam(_availabilityInfo[d.key]);

                if (d.key == 1 || d.key == 2 || d.key == 3 || d.key == 4 || d.key == 5 || d.key == 6 || d.key == 7) {
                    obj.userId = parseInt(singleSelectedValue.id)
                }
                _availabilityInfo[d.key] = obj;
            })
            getUserdetails(singleSelectedValue)
            setShowSelectField(false);
            setShowContent(true);
            setAllSelectedUser([..._allSelectedUser]);
            setButtonVisibility(true);
            setAvailabilityInfo(_availabilityInfo);

        }


    }
    const generateUserList = () => {
        let _userList = null;
        let arrayUserList = userlist.data;
        let _allSelectedUser = allSelectedUser;
        _allSelectedUser =_allSelectedUser.filter((f)=>{
            return f !== undefined ;
        })
        _allSelectedUser = _allSelectedUser.map(f => {
            return f.value
        });
        arrayUserList = arrayUserList.map(f => {
            return f.value
        });

        let withOutSelectOption = [];
        arrayUserList.map((option) => {
            let containOption = false;
            _allSelectedUser.map((row) => {
                if (option == row) {
                    containOption = true;
                }
            });
            if (!containOption) {
                withOutSelectOption.push(option);
            }
        });
        if(withOutSelectOption.length > 0){
            arrayUserList = withOutSelectOption;
        }else if (arrayUserList.length == _allSelectedUser.length){
            arrayUserList = [];
        }
        _userList = arrayUserList.map((objUser, i) => {
            return (
                <MenuItem className="menuItemAddTaskSalesrep" value={objUser} title={objUser} style={{ ...styles.popoverMenuItem }} >{objUser}</MenuItem>
            );
        });

        return _userList;
    }

    const setAdditionalFields = (item, Index) => {

        if(ParameterVerifier.isValidParam(item)){
            let id = item.id;
            let _userName = item.value;
            let tZ = app.me.timezone;
    
            let newElement;
            if (!showZipDetails) {
                newElement = <div style={{ display: 'flex', marginTop: '22px', marginLeft: '22px' }} >
                    <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                        <div  >
                            <Link
                                to={'#'}
                                onClick={() => getUserdetails(item)}
                                value={item}
                                style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                                {item.value}
                            </Link>
                        </div>
                        <div style={{ marginLeft: '30px' }}>
                            <i
                                key={'cross_icon_'}
                                className="fa fa-times"
                                aria-hidden="true"
                                style={{ cursor: 'pointer', fontSize: '14px', marginTop: '3px' }}
                                onClick={() => removeAvailabilityByUser(id, _userName)}
                                title={getLocalizedStrings().label.COMMON.DELETE}
                            ></i>
                        </div>
                    </div>
    
                </div>
            } else {
                newElement = <div style={{ marginTop: '22px', marginLeft: '50px' }} >
                    <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }} >
                        <div >
                            <Link
                                to={'#'}
                                value={item}
                                style={{ cursor: 'pointer' }}>
                                <span style={{ fontWeight: selectedUser == item.value ? 'bold' : '', textDecorationLine: selectedUser == item.value ? 'underline' : '', flexWrap: 'wrap', flexDirection: 'row' }} onClick={() => getUserdetails(item)}> {item.value} </span>
                            </Link>
                        </div>
                        <div style={{ marginLeft: '30px' }}>
                            <i
                                key={'cross_icon_'}
                                className="fa fa-times"
                                aria-hidden="true"
                                style={{ cursor: 'pointer', fontSize: '14px' }}
                                onClick={() => removeAvailabilityByUser(id, _userName)}
                                title={getLocalizedStrings().label.COMMON.DELETE}
                            ></i>
                        </div>
                    </div>
                </div>
            }
            return newElement;
    
        }
      
    }
    const addNewZipCode = () => {
        setZipTextValue('');
        setAvailabilityInfo({});
        setShowAddZipSection(true);
        setShowZipDetails(true);
        setShowAddZipSection(false);
        setShowZipField(true);
        setShowAddZipButton(true);
        setIsZipDisable(false);
        // this.setState({ zipTextValue: '', availabilityInfo: {}, showAddZipSection: true, showZipDetails: true, showAddZipSection: false, 
        // showZipField: true, showAddZipButton: true, isZipDisable: false })

    }
    const removeAvailabilityByUser = (id, _userName) => {
        try {
            let msg = "Removing " + _userName + " will remove all locations and availabilities for " + _userName + " that you already added. Click OK to continue. Click CANCEL to keep " + _userName + ".";
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => onConfirmremoveAvailabilityByUser(id), null);

        } catch (error) {
            console.error("Error in notificationRemiders.js ---> deleteCustomReminders() " + error)
        }
    }
    const onConfirmremoveAvailabilityByUser = (id) => {
        let _allSelectedUser = allSelectedUser;
        _allSelectedUser =_allSelectedUser.filter((f)=>{
            return f !== undefined ;
        })
        _allSelectedUser = _allSelectedUser.filter(f => {
            return f.id != id;
        });
        let params = {
            technician_id: id
        }
        var promise = Promise.resolve(HTTPClient.post(endPoints.COMPANY_SCHEDULER.DELETE_COMPANY_SCHEDULER_AVAILABILITY_BY_USERID, params));
        promise.then((response) => {
            if (ParameterVerifier.isValidParam(response)) {
                if (response.data.isDelete) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    setAllSelectedUser([..._allSelectedUser]);
                    setShowAddZipSection(false);
                    setShowZipDetails(false);
                    setShowZipField(false);
                }
            }

        })

    }
    const removeAvailabilityByZipCode = (option) => {

        try {
            let msg = "Removing " + option + " will remove all availabilities for " + option + " that you already added. Click OK to continue. Click CANCEL to keep " + option + ".";
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => onConfirmremoveAvailabilityByZipCode(option), null);

        } catch (error) {
            console.error("Error in notificationRemiders.js ---> deleteCustomReminders() " + error)
        }
    }

    const onConfirmremoveAvailabilityByZipCode = (option) => {
        let _allZipCodes = allZipCodes;
        _allZipCodes = _allZipCodes.filter(f => {
            return f != option;
        });

        let params = {
            technician_id: selectedUserId,
            zip_code: option,
        }
        var promise = Promise.resolve(HTTPClient.post(endPoints.COMPANY_SCHEDULER.DELETE_COMPANY_SCHEDULER_AVAILABILITY_BY_ZIPCODE, params));
        promise.then((response) => {
            if (ParameterVerifier.isValidParam(response)) {
                if (response.data.isDelete) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    setAllZipCodes([..._allZipCodes]);
                    setShowAddZipSection(false);
                    setShowZipField(false);
                    setShowAddZipButton(false);
                }
            }

        })

    }
    const getUserdetails = (item) => {
        let zipCodeArray = [];
        let availabilityArray = {};
        let params = {
            technician_id: item.id,
        }
        let _selectedUser = item.value;
        let _selectedUserId = item.id;
        var promise = Promise.resolve(HTTPClient.get(endPoints.COMPANY_SCHEDULER.GET_COMPANY_SCHEDULER_AVAILABILITY_USERID, params));
        promise.then((response) => {
            if (ParameterVerifier.isValidParam(response)) {
                zipCodeArray = response.zipCodeList;
                availabilityArray = response.availabilityInfo;
                populateAvalaibleData(availabilityArray);
                if (zipCodeArray.length > 0) {
                    setAllZipCodes([...zipCodeArray]);
                    setSelectedUser(_selectedUser);
                    setSelectedUserId(_selectedUserId);
                    setZipTextValue(zipCodeArray[0]);
                    setShowAddZipButton(false);
                    setAvailabilityInfo(availabilityArray);
                    setShowZipDetails(true);
                    setShowSelectField(false);
                    setShowAddZipSection(true);
                    setButtonVisibility(true);
                    setShowZipField(true);
                    // this.setState({ allZipCodes: zipCodeArray, selectedUser: selectedUser, selectedUserId: selectedUserId, 
                    //     zipTextValue: zipCodeArray[0], showAddZipButton: false, availabilityInfo: availabilityArray, showZipDetails: true, 
                    //     showSelectField: false, showAddZipSection: true, buttonVisibility: true, showZipField: true });
                } else {
                    setAllZipCodes([...zipCodeArray]);
                    setSelectedUser(_selectedUser);
                    setSelectedUserId(_selectedUserId);
                    setZipTextValue(zipCodeArray[0]);
                    setShowAddZipButton(false);
                    setAvailabilityInfo(availabilityArray);
                    setShowZipDetails(true);
                    setShowSelectField(false);
                    setShowAddZipSection(false);
                    setButtonVisibility(true);
                    setShowZipField(false);
                    // this.setState({ allZipCodes: zipCodeArray, selectedUser: selectedUser, selectedUserId: selectedUserId, zipTextValue: zipCodeArray[0], 
                    //     availabilityInfo: availabilityArray, showZipDetails: true, showAddZipSection: false, showZipField: false, showSelectField: false, buttonVisibility: true });

                }
            }
        });
    }

    const getRowFields = () => {
        let rows = [];

        let obj1 = days[0];
        let obj2 = days[1];
        let advance_notice = [];
        let adv_notice_len = 5;
        if (scheduleInfo.min_advance_notice_type == 'hours') {
            adv_notice_len = 23;
        } else if (scheduleInfo.min_advance_notice_type == 'days') {
            adv_notice_len = 30;
        }
        for (var i = 0; i <= adv_notice_len; i++) {
            advance_notice.push(i);
        }
        let noticeMenuItems = null;
        let noticeTypeMenuItems = null;
        noticeMenuItems = advance_notice.map((d, index) => {
            return <MenuItem key={d} value={d} style={styles.popoverMenuItem} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }} title={d}>{d}</div>}</MenuItem>
        });
        noticeTypeMenuItems = Object.keys(advance_notice_type).map((key) => {
            return <MenuItem key={key} value={key} style={styles.popoverMenuItem} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }} title={advance_notice_type[key]}>{advance_notice_type[key]}</div>}</MenuItem>
        });
        rows.push(<div className='row' style={{ fontSize: '14px' }}>
            {obj1 != null &&
                <div className='col-sm-5' style={{}}><div style={{ float: 'left', width: '75px', paddingTop: '20px' }}>{obj1.label}</div> {getDropDownFields(obj1)}</div>}
            {obj2 != null &&
                <div className='col-sm-7' style={{}}><div style={{ float: 'left', width: '75px', paddingTop: '20px' }}>{obj2.label}</div>{getDropDownFields(obj2)}</div>}
        </div>)


        obj1 = days[2];
        obj2 = days[3];

        rows.push(<div className='row' style={{ fontSize: '14px' }}>
            {obj1 != null &&
                <div className='col-sm-5' style={{}}><div style={{ float: 'left', width: '75px', paddingTop: '20px' }}>{obj1.label}</div> {getDropDownFields(obj1)}</div>}
            {obj2 != null &&
                <div className='col-sm-7' style={{}}><div style={{ float: 'left', width: '75px', paddingTop: '20px' }}>{obj2.label}</div>{getDropDownFields(obj2)}</div>}
        </div>)


        obj1 = days[4];
        obj2 = days[5];

        rows.push(<div className='row' style={{ fontSize: '14px' }}>
            {obj1 != null &&
                <div className='col-sm-5' style={{}}><div style={{ float: 'left', width: '75px', paddingTop: '20px' }}>{obj1.label}</div> {getDropDownFields(obj1)}</div>}
            {obj2 != null &&
                <div className='col-sm-7' style={{}}><div style={{ float: 'left', width: '75px', paddingTop: '20px' }}>{obj2.label}</div>{getDropDownFields(obj2)}</div>}

        </div>)


        obj1 = days[6];
        rows.push(<div className='row' style={{ fontSize: '14px' }}>
            {obj1 != null &&
                <div className='col-sm-5' style={{ width: '30%' }}><div style={{ float: 'left', width: '75px', paddingTop: '20px' }}>{obj1.label}</div> {getDropDownFields(obj1)}</div>}
        </div>)
        rows.push(<div className='row' style={{ fontSize: '14px' }}>
            {obj1 != null &&
                <div className='col-sm-5' style={{ width: '30%' }}><div style={{ float: 'left', fontWeight: 'bold', paddingTop: '10px' }}>Minimum Advance Notice </div> </div>}
        </div>)
        obj1 = 8;
        rows.push(<div className='row' style={{ fontSize: '14px' }}>
            {obj1 != null &&
                <div className='col-sm-5' style={{}}><div style={{ float: 'left', fontWeight: 'bold', paddingTop: '10px' }}>
                    <FormControl variant="outlined">
                        <InputLabel id="sf-schedulerinfo-from-scheduler-simple-select-outlined-label" className="sf-schedulerinfo-from-scheduler">{null}</InputLabel>
                        <Select
                            key="min_advance_notice"
                            id='min_advance_notice'
                            value={scheduleInfo.min_advance_notice}
                            onChange={(event) => onChangeNotice('min_advance_notice', obj1, event)}
                            name='min_advance_notice'
                            className={"sf-fields-bg"}
                            style={{ height: '35px', width: '130px' }} >
                            {noticeMenuItems}
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" style={{ paddingLeft: '13px' }}>
                        <InputLabel id="sf-schedulerinfo-from-schedulerinfo-simple-select-outlined-label" className="sf-schedulerinfo-from-schedulerinfo">{null}</InputLabel>
                        <Select
                            key="min_advance_notice_type"
                            id='min_advance_notice_type'
                            value={scheduleInfo.min_advance_notice_type}
                            onChange={(event) => onChangeNotice('min_advance_notice_type', obj1, event)}
                            name='min_advance_notice_type'
                            className={"sf-fields-bg"}
                            style={{ height: '35px', width: '135px' }} >
                            {noticeTypeMenuItems}
                        </Select>
                    </FormControl>
                </div> </div>}
        </div>)
        return rows;

    }
    const onChangeNotice = (field, id, event) => {
        let _availabilityInfo = availabilityInfo;
        let noticeValue = '';
        let noticePeriod = '';
        let _scheduleInfo = scheduleInfo;
        _scheduleInfo[field] = event.target.value;

        if (field == 'min_advance_notice_type') {
            if (event.target.value == 'hours') {
                _scheduleInfo['min_advance_notice'] = 2;
            } else {

                _scheduleInfo['min_advance_notice'] = 2;
            }
        }
        if (field == 'min_advance_notice') {
            noticeValue = event.target.value;
        }
        else {
            noticePeriod = event.target.value;

        }
        days.map((d, index) => {
            let obj = getObjectParam(_availabilityInfo[d.key]);

            if (d.key == 1 || d.key == 2 || d.key == 3 || d.key == 4 || d.key == 5 || d.key == 6 || d.key == 7) {
                if (field == "min_advance_notice") {
                    obj.min_advance_notice = noticeValue;

                } else if (field == "min_advance_notice_type") {
                    obj.min_advance_notice_type = noticePeriod;

                }
            }
            _availabilityInfo[d.key] = obj;

        })
        setAvailabilityInfo({ ..._availabilityInfo });
        setScheduleInfo({..._scheduleInfo});

    }

    const getDropDownFields = (d) => {
        let durationMenuItems = null;
        let durationTypeMenuItems = null;
        durationMenuItems = duration.map((d, index) => {
            return <MenuItem key={d.key} value={d.key} style={styles.popoverMenuItem} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={d.label}>{d.label}</div>}</MenuItem>
        });

        let _availabilityInfo = getObjectParam(availabilityInfo);
        let start_time = "9 AM";
        let end_time = "17 PM";
        let obj = getObjectParam(_availabilityInfo[d.key]);

        let style = { backgroundColor: '#fff', height: '35px', width: '87px', paddingLeft: '7px', marginBottom: '10px', marginLeft: '5px', float: 'left' }
        let startTimeStyle = {};
        if (obj.hasOwnProperty('error') && obj.error == 'start_time_error') {
            startTimeStyle = { ...style, border: '1px solid #f75723' };
        } else {
            startTimeStyle = { ...style, border: '1px solid rgb(221, 221, 221)' };
        }
        let endTimeStyle = {};
        if (obj.hasOwnProperty('error') && obj.error == 'end_time_error') {
            endTimeStyle = { ...style, border: '1px solid #f75723' };
        } else {
            endTimeStyle = { ...style, border: '1px solid rgb(221, 221, 221)' };
        }

        start_time = obj.startTime;
        end_time = obj.endTime;
        let ele = <div style={{ display: 'flex' }}>
            <div style={{ float: 'left' }}>
                <FormControl variant="outlined" style={{ width: '100%', paddingTop: '10px' }}>
                    <InputLabel id="sf-scheduleravailibilitys-simple-select-outlined-label" className="sf-scheduleravailibilitys">{null}</InputLabel>
                    <Select
                        key={"start_time_" + d.key}
                        value={start_time}
                        onChange={(e) => onChangeValueStart('start_time', d.key, e.target.value)}
                        name={"start_time_" + d.key}
                        className={"sf-fields-bg"}
                        style={{ minWidth: 95, maxWidth: 95, height: '35px', background: start_time == -1 ? "#e3e6e8" : '' }}>
                        {durationMenuItems}
                    </Select>
                </FormControl>
            </div>
            <span style={{ float: 'left', paddingLeft: '5px', paddingRight: '5px', paddingTop: '20px' }}>to</span>
            <div style={{ float: 'left' }}>
                <FormControl variant="outlined" style={{ width: '100%', paddingTop: '10px' }}>
                    <InputLabel id="sf-scheduleravailibity-from-scheduler-simple-select-outlined-label" className="sf-scheduleravailibity-from-scheduler">{null}</InputLabel>
                    <Select
                        key={"end_time_" + d.key}
                        id={"end_time_" + d.key}
                        value={end_time}
                        onChange={(e) => onChangeValueEnd('end_time', d.key, e.target.value)}
                        name={"end_time_" + d.key}
                        className={"sf-fields-bg"}
                        style={{ minWidth: 95, maxWidth: 95, height: '35px', background: end_time == -1 ? "#e3e6e8" : '' }} >
                        {durationMenuItems}
                    </Select>
                </FormControl>
            </div>
        </div>
        return ele;

    }
    const onChangeValueStart = (field, id, value) => {

        let _availabilityInfo = availabilityInfo;

        let obj = getObjectParam(_availabilityInfo[id]);
        obj.day_id = id;
        let val = value;
        if (value == '-1') {
            value = "-1" + " " + "-1";
        }
        if (field == 'start_time' && value == "-1 -1") {
            obj.startTime = val;
            obj.start_time = parseInt(value.split(' ')[0]);
            obj.start_time_meridiem = value.split(' ')[1];
            obj.endTime = val;
            obj.end_time = parseInt(value.split(' ')[0]);
            obj.end_time_meridiem = value.split(' ')[1];
        }

        else {
            if (field == 'start_time') {
                obj.startTime = val;
                obj.start_time = parseInt(value.split(' ')[0]);
                obj.start_time_meridiem = value.split(' ')[1];
            }
        }

        _availabilityInfo[id] = obj;
        setAvailabilityInfo({ ..._availabilityInfo });
    }


    const onChangeValueEnd = (field, id, value) => {

        let _availabilityInfo = availabilityInfo;

        let obj = getObjectParam(_availabilityInfo[id]);
        obj.day_id = id;
        let val = value;
        if (value == '-1') {
            value = "-1" + " " + "-1";
        }
        if (field == 'end_time' && value == "-1 -1") {
            obj.startTime = val;
            obj.start_time = parseInt(value.split(' ')[0]);
            obj.start_time_meridiem = value.split(' ')[1];
            obj.endTime = val;
            obj.end_time = parseInt(value.split(' ')[0]);
            obj.end_time_meridiem = value.split(' ')[1];
        }

        else {
            if (field == 'end_time') {
                obj.endTime = val;
                obj.end_time = parseInt(value.split(' ')[0]);
                obj.end_time_meridiem = value.split(' ')[1];
            }

        }

        _availabilityInfo[id] = obj;
        setAvailabilityInfo({ ..._availabilityInfo });
    }

    const changeZipValue = (event) => {
        let value = event.target.value;
        setZipTextValue(value);
    }

    const availabilityData = () => {
        let withOutSelectOption = [];
        let location = [];
        locationList.map((option) => {
            let containOption = false;
            if (ParameterVerifier.isValidParam(allZipCodes)) {
                allZipCodes.map((row) => {
                    if (option.t_location == row) {
                        containOption = true;
                    }
                });
            }

            if (!containOption) {
                withOutSelectOption.push(option);
            }
        });
        if (zipTextValue != '' || isZipDisable == true) {

            location = locationList;
        }
        else {
            location = withOutSelectOption;
        }

        let zipCodeMenuItems = null;
        zipCodeMenuItems = location.map((d, index) => {
            return <MenuItem key={d.id} value={d.t_location} style={styles.popoverMenuItem} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }} title={d.t_location}>{d.t_location}</div>}</MenuItem>
        });
        let field =
            <div style={{}}>
                <div style={{ width: '30%', marginLeft: '47px', marginTop: '10px' }}>
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <Select
                            labelId="zipCode"
                            variant="outlined"
                            style={{ height: '42px' }}
                            className={"sf-fields-bg"}
                            autoComplete="new-password"
                            margin='dense'
                            disabled={isZipDisable}
                            value={zipTextValue}
                            onChange={(e) => changeZipValue(e)} >
                            {zipCodeMenuItems}
                        </Select>
                    </FormControl>
                </div>

            </div>

        return field;
    }
    useEffect(() => {
        let location = [];
        let withOutSelectOption = [];

        if (zipTextValue != '' || isZipDisable == true) {

            location = locationList;
            setFinalLocationList(location);
        }
        else {
            locationList.map((option) => {
                let containOption = false;
                if (ParameterVerifier.isValidParam(allZipCodes)) {
                    allZipCodes.map((row) => {
                        if (option.t_location == row) {
                            containOption = true;
                        }
                    });
                }

                if (!containOption) {
                    withOutSelectOption.push(option);
                }
            });
            location = withOutSelectOption;
            setFinalLocationList(location);
        }
    }, [zipTextValue, isZipDisable, locationList])


    const validate = (availabilityInfo) => {
        let isValid = true;
        let msg = "";
        let availabilityInfoObj = getObjectParam(availabilityInfo);

        Object.keys(availabilityInfoObj).map(key => {
            let obj = getObjectParam(availabilityInfoObj[key]);
            if (obj.hasOwnProperty("error")) {
                delete obj.error;
            }
        });

        let keys = Object.keys(availabilityInfoObj);
        for (var i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (key != 'isNylasSetup') {
                let obj = getObjectParam(availabilityInfoObj[key]);
                let s_time = ParameterVerifier.getIntParam(obj.start_time);
                let e_time = ParameterVerifier.getIntParam(obj.end_time);

                if (s_time != -1 && e_time != -1) {
                    var startTime = moment(s_time + ":" + "00" + "00", 'h:mm:ss');
                    var endTime = moment(e_time + ":" + "00" + "00", 'h:mm:ss');

                    if (startTime.isAfter(endTime) || startTime.isSame(endTime)) {
                        isValid = false;
                        msg = getLocalizedStrings().message.SCHEDULER.VALIDATE_TIME;
                        obj.error = "start_time_error";
                        break;
                    }
                }

                if (s_time == -1 && e_time != -1) {
                    isValid = false;
                    msg = getLocalizedStrings().message.SCHEDULER.NO_START_TIME;
                    obj.error = "start_time_error";
                    break;
                }

                if (e_time == -1 && s_time != -1) {
                    isValid = false;
                    msg = getLocalizedStrings().message.SCHEDULER.NO_END_TIME;
                    obj.error = "end_time_error";
                    break;
                }

            }
        }



        if (!isValid) {
            dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }

        return isValid;
    }

    const save = (itemRow) => {
        setIsZipDisable(true);
        let _availabilityInfo = availabilityInfo;
        let isValid = validate(_availabilityInfo);
        if (isValid) {
            days.map((d, index) => {
                let obj = getObjectParam(_availabilityInfo[d.key]);
                if (d.key == 1 || d.key == 2 || d.key == 3 || d.key == 4 || d.key == 5 || d.key == 6 || d.key == 7) {
                    obj.zipCode = zipTextValue
                }
                _availabilityInfo[d.key] = obj;
            })
            let keys = Object.keys(_availabilityInfo);
            let availabilityInfoArr = new Array();
            keys.map((key) => {

                availabilityInfoArr.push(availabilityInfo[key]);

            });
            let params = new Object();
            params.availabilityInfo = availabilityInfoArr;
            var promise = Promise.resolve(HTTPClient.post(endPoints.COMPANY_SCHEDULER.SAVE_COMPANY_SCHEDULER_AVAILABILITY, params));
            promise.then((response) => {
                if (ParameterVerifier.isValidParam(response)) {
                    if (response.status == 0) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    }
                    else {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.SCHEDULER.SAVE_FAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));

                    }
                }
            });
        }

    }
    const addZipCode = () => {
        if (zipTextValue === "") {
            dispatch(showCustomSnackBar("Please select location", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else {
            let availabilityArray = {};
            let _allZipCodes = allZipCodes;
            let _scheduleInfo = scheduleInfo;
            let isExist = allZipCodes.filter(f => {
                return f == zipTextValue;
            });
            if (isExist.length > 0) {
                dispatch(showCustomSnackBar("Zipcode is already exists", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
                populateAvalaibleData(availabilityArray);
                _allZipCodes.push(zipTextValue);
            }
            _scheduleInfo.min_advance_notice = 2;
            _scheduleInfo.min_advance_notice_type = "hours";
            if (isExist.length > 0) {
                setAllZipCodes([...allZipCodes]);
                setShowZipField(true);
                setShowAddZipSection(false);
                setShowAddZipButton(true);
                setScheduleInfo({..._scheduleInfo});
                // this.setState({
                //     allZipCodes: this.state.allZipCodes, showZipField: true, showAddZipSection: false, showAddZipButton: true, scheduleInfo: scheduleInfo
                // })
            } else {
                setAllZipCodes([...allZipCodes]);
                setShowZipField(true);
                setShowAddZipSection(true);
                setShowAddZipButton(false);
                setScheduleInfo({..._scheduleInfo});
                // this.setState({
                //     allZipCodes: this.state.allZipCodes, showZipField: true, showAddZipSection: true, showAddZipButton: false, scheduleInfo: scheduleInfo
                // })
            }

        }

    }
    const getAvailabilityByZip = (option) => {
        let availabilityArray = [];
        let params = {
            technician_id: selectedUserId,
            zipcode: option
        }
        var promise = Promise.resolve(HTTPClient.get(endPoints.COMPANY_SCHEDULER.GET_COMPANY_SCHEDULER_AVAILABILITY_ZIPCODE, params));
        promise.then((response) => {
            if (ParameterVerifier.isValidParam(response)) {
                console.log("333",Object.keys(response.availabilityInfo).length)
                availabilityArray = response.availabilityInfo;
                    if((Object.keys(response.availabilityInfo).length > 0)){
                        populateAvalaibleData(availabilityArray);
                        setAvailabilityInfo(availabilityArray);
                }else{
                let _availabilityArray = {}
                        populateAvalaibleData(_availabilityArray);
                    let _scheduleInfo = scheduleInfo
                    _scheduleInfo.min_advance_notice = 2;
                    _scheduleInfo.min_advance_notice_type = "hours";
                  setScheduleInfo({..._scheduleInfo})
                }
                setZipTextValue(option);
                setShowZipDetails(true);
                setShowAddZipSection(true);
                setShowZipField(true);
                setIsZipDisable(true);
                setShowAddZipButton(false);
                // this.setState({ zipTextValue: option, availabilityInfo: availabilityArray, showZipDetails: true, showAddZipSection: true, showZipField: true, isZipDisable: true, showAddZipButton: false });

            }

        });

    }
    const hideLocationField = () => {
        setShowZipDetails(false);
    }
    let additionalFields = allSelectedUser.map((type, index) => { return setAdditionalFields(type, index) })

    let selectedValueRow;
    selectedValueRow = allSelectedUser.map((row, index) => {
        return selectedItem == row
    }
    );
    let itemRow = selectedItem;
    let color = '#717171';
    let zipDetails = availabilityData();
    let tZ = app.me.timezone;
    let availability = getRowFields();
    if (mounted) {
        return (
            <>

                <div style={{ marginLeft: '-25px', width: '90%' }}>
                    <div style={{ ...styles.sf_12 }}>
                        <div id="header" style={{ paddingLeft: '0px', width: '100%', fontSize: '24px', paddingBottom: '5px', marginTop: '18px', marginLeft: showSelectField == true ? '57px' : '68px' }}>Scheduler Availability</div>

                        {/* <div style={{ fontSize: '30px', marginTop: '18px', fontWeight: 'bold', marginLeft:this.state.showSelectField== true ? '55px': '70px' }}> Scheduler Availability </div> */}
                    </div>
                    <div style={{ display: 'flex', marginLeft: showZipDetails == true ? '' : '30px' }}>

                        {showContent &&
                            <span style={{ fontSize: '14px', color: color, display: 'flex', marginBottom: showSelectField == true ? '20px' : '', marginLeft: showSelectField == true ? '10px' : '20px', marginLeft: additionalFields == '' ? '' : '20px', flexWrap: 'wrap', flexDirection: 'row', maxWidth: '75%' }}>{additionalFields}</span>
                        }
                        {buttonVisibility &&
                            <Button variant="contained" style={{ ...styles.primaryButton, width: '', border: '', fontSize: 13, marginTop: "20px", marginLeft: additionalFields == '' ? '40px' : '60px' }} size="small" onClick={() => showFields()}>Add Technician </Button>
                        }

                    </div>
                    {showSelectField &&
                        <div>
                            <span style={{ fontSize: '20px', marginTop: '18px', fontWeight: 'bold', marginLeft: '60px' }} > Add a technician </span>
                            <div className="boxContent">
                                <FormControl variant="outlined" style={{ width: '25%', marginTop: '20px', marginLeft: '55px' }}>
                                    <InputLabel id="sf-schedulerinfo-simple-select-outlined-label" style={{ marginTop: '-5px' }} className="sf-schedulerinfo"> Select a user </InputLabel>

                                    <Select
                                        value={valueUser}
                                        onChange={(e) => changeUser(e)}
                                        label={getLocalizedStrings().label.AUTOMATION_DESIGNER.OWNER_REQUIRED}
                                        labelId="user"
                                        style={{ height: '42px' }}
                                        className={"sf-fields-bg"}
                                    >
                                        {generateUserList()}
                                    </Select>
                                </FormControl>

                            </div>

                            <div style={{ width: '75.5%', marginTop: '15px', float: 'right' }}>

                                <Button
                                    key='search'
                                    onClick={() => showUserData()}
                                    style={{ ...styles.primaryButton, marginRight: '6px', verticalAlign: 'top', border: '' }}
                                >{getLocalizedStrings().label.COMMON.ADD}</Button>

                            </div>
                        </div>
                    }




                    {showZipDetails &&
                        <div style={{ marginLeft: '18px' }}>
                            <div style={{ fontSize: '20px', marginTop: '18px', fontWeight: 'bold', marginLeft: '50px' }}> {selectedUser}'s Availability ({timeZoneMap[tZ]}) </div>
                            <div style={{ display: 'flex', marginTop: '20px', marginLeft: '30px', flexWrap: 'wrap', flexDirection: 'row' }}>
                                {allZipCodes.map(option => (
                                    <div>
                                        <div>
                                            <span>
                                                <Link
                                                    to={'#'}
                                                    onClick={() => getAvailabilityByZip(option)}
                                                    style={{ cursor: 'pointer' }}>
                                                    <span style={{ marginLeft: '20px', fontWeight: zipTextValue == option ? ' bold' : '', textDecorationLine: zipTextValue == option ? 'underline' : '' }}>  {option}</span>
                                                </Link>


                                            </span>
                                            <i
                                                key={'cross_icon_'}
                                                className="fa fa-times"
                                                aria-hidden="true"
                                                style={{ cursor: 'pointer', fontSize: '14px', marginLeft: '20px' }}
                                                onClick={() => removeAvailabilityByZipCode(option)}
                                                title={getLocalizedStrings().label.COMMON.DELETE}
                                            ></i>
                                        </div>
                                    </div>
                                ))}
                                <Link
                                    to={'#'}
                                    onClick={() => addNewZipCode()}
                                    style={{ cursor: 'pointer' }}>
                                    <span style={{ marginLeft: '20px' }}> Add Location</span>
                                </Link>
                            </div>
                            {showZipField &&
                                <div>
                                    {zipDetails}
                                </div>
                            }
                            {showAddZipSection &&
                                <div style={{ width: '99%', marginLeft: '50px' }}>
                                    {getRowFields()}
                                </div>
                            }
                            {showAddZipSection &&
                                <div style={{ float: 'right', width: '35%', paddingBottom: '30px' }}>
                                    <Button variant="contained" style={{ ...styles.primaryButton, border: '', fontSize: 13, marginTop: "20px", marginLeft: '50px' }} size="small" onClick={() => save(itemRow)}> Save</Button>
                                </div>
                            }
                            <div style={{ display: 'flex', width: finalLocationList.length == 0 ? '81%' : '75%', float: 'right' }}>
                                <div>
                                    {showAddZipButton &&
                                        <Button variant="contained" style={{ ...styles.primaryButton, border: '', fontSize: 13, marginTop: "20px", marginLeft: '30px' }} size="small" onClick={() => addZipCode(itemRow)}> Add </Button>

                                    }
                                </div>
                                <div>
                                    {finalLocationList.length == 0 && showAddZipButton &&
                                        <Button variant="contained" style={{ ...styles.primaryButton, border: '', fontSize: 13, marginTop: "20px" }} size="small" onClick={() => hideLocationField()}> Back </Button>

                                    }
                                </div>
                            </div>

                        </div>
                    }


                </div>
                <div>

                </div>


            </>
        );
    }
    else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }

}
export default CompanySchedulerAvailability;