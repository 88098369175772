import { getLocalizedStrings } from "../../../../services/constants/MultiLingual"


export const brandDetailsSection1 = [
    {
        id: 'si19',
        section: 3,
        dataType: 'input',
        name: 'brand_name',
        value: '',
        type: 'input',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.BRAND_NAME,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si20',
        section: 31,
        dataType: 'radiobutton',
        radioButtonName: 'brand_type',
        radioButtonValue: '',
        type: 'select',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.BRAND_TYPE,
        options:['Low-volume standard $4 one-time fee','Standard $44 one-time fee'],
        required: true,       
    },
    {
        id: 'si21',
        section: 31,
        dataType: 'radiobutton',
        radioButtonName: 'company_type',
        radioButtonValue: '',
        type: 'select',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.COMPANY_TYPE,
        options:['Private','Public','US Non Profit','US Government'],
        required: true,       
    },

]

