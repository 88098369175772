import { endPoints } from '..//constants/endPoints';
import { actions } from '..//constants/actions';
import * as HTTPClient from '..//helper/httpClient';
import { getObjectParam } from '..//helper/parameterVerifier';


const getEmails = () => {
    try {
        let url = endPoints.EMAIL.LIST_GET;
        return {
            type: actions.EMAIL_GET,
            payload: HTTPClient.get(url, null)
        }
    } catch (error) {
        console.error("Error in 'emailActions.js -> getEmails()':" + error);
    }
}

const getEmailPromise = (params) => {
    try {
        params = getObjectParam(params);
        let url = endPoints.EMAIL.LIST_GET;
        
        return Promise.resolve(HTTPClient.get(url, params));
    } catch (error) {
        console.error("Error in 'emailActions.js -> getEmailPromise()':" + error);
    }
}

export {
    getEmails,
    getEmailPromise
}