import React, { useState, useRef, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { isValidParam, getArrayParam, getBooleanParam, getStringParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import * as sfDialogs from '../components/sfDialogs';
import { Icon } from '@mui/material';
import _ from 'lodash';
import { ItemTypes } from './ItemTypes.js'

const style = {
    border: '1px dashed gray',
    // padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
};

const ModuleMenuItem = (props) => {
    const ref = useRef(null);

    const { item, mMenuItems, handleRemoveForcefix, updateIsEditProps, updateModuleItem, parentKey, handleSave, moveCard, columnId, columnIndex } = props;

    const [prevItem] = useState(Object.assign({}, item));
    const [isEdit, setIsEdit] = useState(false);
    const [selectedItem, setSelectedItem] = useState(item);

    useEffect(() => {
        setSelectedItem(item);
    }, [item]);

    const handleEditAction = (isEditVal) => {
        try {
            setIsEdit(isEditVal);
            updateIsEditProps(isEditVal)
        } catch (error) {
            console.error("Error in 'MobileMenuConfiguration.js -> ModuleMenuItem.js -> handleEditAction()':" + error);
        }
    }

    const handleRemove = (sItem) => {
        let itemVal = getObjectParam(sItem);
        try {
            let msg = itemVal.name + getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_REMOVE_MSG;
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => handleRemoveOnConfirmed(itemVal), null);
        } catch (error) {
            console.error("Error in 'MobileMenuConfiguration.js -> ModuleMenuItem.js -> handleRemove()':" + error);
        }
    }

    const handleRemoveOnConfirmed = (itemNew) => {
        let params = {};
        try {
            params.key = itemNew.key;
            params.name = itemNew.name;
            params.is_remove = true;
            updateModuleItem(params);
        } catch (error) {
            console.error("Error in 'MobileMenuConfiguration.js -> ModuleMenuItem.js -> handleRemoveOnConfirmed()':" + error);
        }
    }

    const onChangeItem = (event) => {
        try {
            let value = event.target.value;
            let itemVal = getObjectParam(selectedItem);
            itemVal.name = value;
            setSelectedItem(itemVal);
        } catch (error) {
            console.error("Error in 'MobileMenuConfiguration.js -> ModuleMenuItem.js -> onChangeItem()':" + error);
        }
    }

    const isValidateCheck = () => {
        let isValidate = true;
        let itemNew = getObjectParam(selectedItem);
        let itemLabel = getStringParam(itemNew.name).trim();
        let mobileMenuItemsNew = getArrayParam(mMenuItems);
        let tempItem = mobileMenuItemsNew.filter(m => getStringParam(m.name).toLowerCase() === itemLabel.toLowerCase());

        try {
            if (itemLabel === '') {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.THIS_FIELD_CANNOT_BLANK, '', null)
                return false;
            } else if (itemLabel.length > 32) {
                sfDialogs.alert(getLocalizedStrings().message.MENU.MODULE_LABEl_CHAR_LIMIT_MSG, '', null)
                return false;
            } else if (tempItem.length > 0) {
                sfDialogs.alert(itemLabel + ' ' + getLocalizedStrings().message.MENU.DUPLICATE_NAME, '', null)
                return false;
            }
        } catch (error) {
            console.error("Error in 'MobileMenuConfiguration.js -> ModuleMenuItem.js -> isValidate()':" + error);
        }
        return isValidate;
    }

    const saveItem = () => {
       let params = {};
        let itemNew = getObjectParam(selectedItem);
        try {
            let isEqual = _.isEqual(itemNew, prevItem);
            if (!isEqual) {
                let isValidate = isValidateCheck;
                if (isValidate) {
                    handleEditAction(false);
                    params.key = getStringParam(itemNew.key);
                    params.name = getStringParam(itemNew.name).trim();
                    updateModuleItem(params, true);
                }
            } else {
                handleEditAction(false);
            }
        } catch (error) {
            console.error("Error in 'MobileMenuConfiguration.js -> ModuleMenuItem.js -> saveModuleItem()':" + error);
        }
    }

    const [{ isDragging, opacity }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: { selectedItem, columnId, columnIndex },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
            opacity: monitor.isDragging() ? 0.5 : 1,
        }),
    });

    const [{ isOver, canDrop, handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        drop(item, monitor) {
            if(item.selectedItem.key != "more"){
                if (parseInt(columnId) !== 1) {
                    handleSave(item.selectedItem, 1);
                } else if(item.columnId != 1) {
                    handleRemoveForcefix(item.selectedItem);
                }
            }
        },
        hover(item, monitor) {
            if(item.selectedItem.key != "more"){
                const draggingItem = monitor.getItem();

                if (draggingItem.selectedItem.key !== selectedItem.key) {

                    moveCard(columnId, columnIndex, draggingItem.selectedItem.key);
                }
            }
        },

        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            handlerId: monitor.getHandlerId(),
        }),
    });

    const isActive = isOver && canDrop;

    let isGrab = parentKey === '1' ? true : false;
    let showActionButtons = getBooleanParam(isEdit) || selectedItem.key === 'more' ? false : true;
    if(selectedItem.key != 'more'){
        drag(drop(ref));
    }
    return (
        <div
            id={`ModuleMenuItem-` + selectedItem.key}
            ref={ref}
            style={{ ...style, height: '30px', marginTop: '0px', backgroundColor: isActive ? '#efefef' : '', opacity, cursor: isGrab ? 'grab' : 'grab', border: isDragging ? "1px solid #000" : "none" }}
            data-handler-id={handlerId}
        >
            <div className="item-container" style={{ opacity, height: '100%'  }}>
                <div className="item-content" style={{ padding: getBooleanParam(isEdit) ? '0px 10px' : '5px 10px' }}>
                    <div style={{ display: 'flex' }}>
                        {getBooleanParam(isEdit) ?
                             <input
                                    autoFocus={true}
                                    type="text"
                                    inputprops={{ disableUnderline: true }}
                                    id={`item-name-${selectedItem.key}`}
                                    key={`item-field-${selectedItem.key}-key`}
                                    defaultValue={selectedItem.name}
                                    title={selectedItem.name}
                                    onChange={(e) => onChangeItem(e)}
                                    style={{ border: '1px solid rgb(153, 153, 153)', backgroundColor: '#fff', width: '100%', height: '30px', padding: '0px 7px' }}
                                    inputStyle={{ color: '#333', fontSize: '10px', fontWeight: 'normal' }}
                                    onBlur={() => saveItem()}
                                    autoComplete="new-password"
                                    className={"sf-fields-bg"}
                                />
                           :
                            <div title={selectedItem.name} style={{ width: '100%', float: 'left', paddingLeft:'0px', color: '#717171', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '14px' }}>{selectedItem.name}</div>
                        }
                        {showActionButtons && <div>
                            {parentKey !== '1' &&
                                <span style={{ padding: '5px' }}>
                                    <Icon key={`module-item-edit-${selectedItem.key}`} style={{ 'fontSize': '12px', cursor: 'pointer', color: '#717171' }} title={getLocalizedStrings().label.COMMON.EDIT} onClick={() => handleEditAction(true)}>edit</Icon>
                                </span>
                            }
                            {parentKey !== '1' &&
                                <span style={{ padding: '5px' }}>
                                    <Icon key={`module-item-delete-${selectedItem.key}`} style={{ 'fontSize': '12px', fontWeight: 'normal', color: '#717171', cursor: 'pointer' }} title={getLocalizedStrings().label.COMMON.DELETE} onClick={() => handleRemove(selectedItem)}>close</Icon>
                                </span>
                            }
                        </div>
                        }
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModuleMenuItem;