
import {actions} from '../constants/actions'


function getTopUpDialog(isOpenDialog, dialogType, dialogTitle, eventName, data,controlActions, callFrom, style) {
    return function (dispatch) {
       try {
           dispatch({
               type: actions.TOP_UP_DIALOG_OPEN_CLOSE,
               payload: {
                   isOpenDialog: isOpenDialog,
                   dialogType: dialogType,
                   dialogTitle: dialogTitle,
                   eventName: eventName,
                   data: data,
                   actions:controlActions,
                   callFrom: callFrom,
                   style: style
               }
           })
       } catch (e) { console.error("Error in 'appContainerActions.js -> getTopUpDialog()':" + e); }
   }
}

function setTopUpDialogAction(dialogActions) {
        return function (dispatch) {
            try {
            dispatch({
                type: actions.TOP_UP_DIALOG_SET_ACTION,
                payload: {
                    dialogActions: dialogActions
                }
            })
        } catch (e) { console.error("Error in 'appContainerActions.js -> setTopUpDialogAction()':" + e); }
    }
}

export {
    getTopUpDialog,
    setTopUpDialogAction
}