import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { isValidParam, getStringParam, getArrayParam, getObjectParam, getBooleanParam, getIntParam } from '../../../services/helper/parameterVerifier';
import SFDataTable from '../components/sfDataTable';
import { getUnits } from '../../../services/actions/unitsAction';
import { constants } from '../../../services/constants/constants'
import { addTab, TYPE_DETAIL_VIEW, getActiveTab } from '../../../services/helper/sfTabManager';
import { LinkToType } from '../../../services/constants/link';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getAppDrawer, getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import { setHeader } from '../../../services/actions/headerActions';
import { getMultiColumnTitles } from '../../../services/helper/common';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { Menu, MenuItem, Icon, Select, InputLabel, FormControl } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import { sortArrayObjectByPropertyOrderType } from '../../../services/helper/utils'
import { getCookie, setCookie } from '../../../services/helper/sfCookies';
import { getCallDrawer } from '../../../services/actions/callAction';
import Button from '@mui/material/Button';
import map from 'lodash/map';
import PopOver from '../components/PopOver';
import { dateFormatFlatPicker, dateFormat } from '../../../services/helper/utils';
import moment from 'moment';
import Grid from '@mui/material/Grid';
/**
 * 
 * @Author  : Pradyut.s 
 * @Date:   : 2019-01-21
 */

const optionvalue = {
    td: 'Today',
    yd: 'Yesterday',
    tw: 'This Week',
    tm: 'This Month',
    tq: 'This Quarter',
    ty: 'This Year',
    lw: 'Last Week',
    lm: 'Last Month',
    lq: 'Last Quarter',
    ly: 'Last Year',
    cust: 'Custom'
};

function formatDate(date) {
    if (isValidParam(date)) {
        let d = date instanceof Date ? date : new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }
    return null;
}

const AssetsReport = ({ object, fieldProps }) => {

    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);

    const [isMounted, setIsMounted] = useState(false);
    const [data, setData] = useState({});
    const [records, setRecords] = useState([]);
    const [fieldsData, setFieldsData] = useState([]);
    const [rowSort, setRowSort] = useState({
        enableAllColumn: true,
        sortFieldName: 'company',
        isDescending: false,
        onSort: (sortFieldName, isDescending, records) => onSortFn(sortFieldName, isDescending, records)
    });
    const [isTwilioPopOverOpen, setIsTwilioPopOverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [dueBy, setDueBy] = useState('tm');
    const [reportParams, setReportParams] = useState( {
                    due_by: 'tm',
                    from_date: null,
                    to_date: null,
                },);
    const [from_date, setFrom_date] = useState(null);
    const [to_date, setTo_date] = useState(null);
    const [customTimeLineDisplay, setCustomTimeLineDisplay] = useState('none');
    const [fieldName, setFieldName] = useState('');

    useEffect(() => {
        let arr = fields.filter(f => { return f.is_sortable });
        let sortFieldName = arr.length > 0 ? arr[0].name : '';
        let _rowSort = rowSort;
        _rowSort = {
            enableAllColumn: true,
            sortFieldName: sortFieldName,
            isDescending: false,
            onSort: (sortFieldName, isDescending, records) => onSortFn(sortFieldName, isDescending, records)
        };
        setRecords([]);
        setFieldsData([...fields]);
        setRowSort({ ..._rowSort });
        setData({});
        getUnitData();
     }, []);


    useEffect(() => {
        try {
            let tab = getObjectParam(getActiveTab());
            let label = tab.label;
            if (tab.object === 'units' && tab.label === 'Units') {
                label = getLocalizedStrings().label.SIDE_NAV.UNITS;
            } else if (tab.object === constants.OPERATIONS_ASSETS_REPORTS && tab.label === 'Asset Distribution') {
                label = getLocalizedStrings().label.MODULE_ITEMS.ASSETS_DISTRIBUTION;
            }
            dispatch(setHeader(tab.object, label, null, false, true));

        } catch (error) {
            console.error("Error in 'AssetsReport.js -> componentDidUpdate()'" + error);
        }
    }, [app]);

    const setReportParamsData = () => {
        let _dueBy = getStringParam(dueBy);
        let fromDate = from_date;
        let toDate = to_date;
        let params = {
            due_by: _dueBy,
            from_date: formatDate(fromDate),
            to_date: formatDate(toDate),
         };
        setReportParams(params);
        return params;
    }

    const getUnitData = () => {
        let params = setReportParamsData();
        let _rowSort = rowSort;
        try {
            params = getObjectParam(params);
            if (!isValidParam(params.sort_field_name)) {
                params.sort_field_name = 'company';
            }
            if (!isValidParam(params.sort_type)) {
                params.sort_type = 'asc';
            }
            if (!isValidParam(params.due_by)) {
                params.due_by = reportParams.due_by;
            }
            let promise = getUnits(params);
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        let data = { ...response };
                        let rowData = getArrayParam(response.records)
                        let tempFields = getArrayParam(fieldsData);
                        delete response.tempRecords
                        tempFields = tempFields.map(field => {
                            if (field.is_phone_field || field.is_mobile_field) {
                                field = { ...field, ...response };
                                field.is_popover = (field.is_mobile_field && field.is_click_to_call && field.is_twilio_sms && (field.is_twilio || field.is_ring_central)) ? true : false;
                            }
                            return field;
                        });
                        let sortInfo = getOrderInfoData();
                        if (sortInfo != null && Object.keys(sortInfo).length > 0) {
                            let fieldName = sortInfo.fieldName;
                            let orderType = sortInfo.orderType;
                            sortArrayObjectByPropertyOrderType(rowData, fieldName, orderType);
                            _rowSort = {
                                enableAllColumn: true,
                                sortFieldName: fieldName,
                                isDescending: orderType == 'asc' ? false : true,
                                onSort: (sortFieldName, isDescending,rowData) => onSortFn(sortFieldName, isDescending, rowData)
                            }
                            setIsMounted(true);
                            setFieldsData([...tempFields]);
                            setData({ ...data });
                            setRecords([...rowData]);
                            setRowSort({ ..._rowSort });
                        }
                        else {
                            setIsMounted(true);
                            setFieldsData([...tempFields]);
                            setData({ ...data });
                            setRecords([...rowData]);
                            setRowSort({ ..._rowSort });
                        }

                    }
                });
            }
        } catch (error) {
            console.error("Error in 'AssetsReport.js -> getUnits()':" + error);
        }
    }


    const onSortFn = (fieldName, isDescending, recordsData) => {
        try {
            let tempRecords = recordsData;
            let orderType = isDescending ? 'desc' : 'asc';
            sortArrayObjectByPropertyOrderType(tempRecords, fieldName, orderType);
            setOrderInfoData(fieldName, orderType);
            setRecords(tempRecords);
        }
        catch (error) {
            console.error("Error in 'operationsReports.js -> onSort()':" + error);
        }
    }
    const getOrderInfoData = () => {
        try {
            let orderInfo = {};
            let cookieName = 'operations_reports_order' + object + '_' + app.me.projectId + '_' + app.me.id;
            let cookieValue = getObjectParam(JSON.parse(getCookie(cookieName)));
            if (Object.keys(cookieValue).length === 0) {
                return orderInfo;
            } else {
                let fieldName = cookieValue['fieldName'];
                let orderType = cookieValue['orderType'];
                orderInfo.fieldName = fieldName;
                orderInfo.orderType = orderType;
                return orderInfo;
            }
        } catch (error) {
            console.error("Error in 'AssetsReport.js -> getOrderInfoData()':" + error);
        }
    }

    const setOrderInfoData = (fieldName, orderType) => {
        try {
            let orderInfo = {}
            if (fieldName !== null && getStringParam(fieldName) !== '' && orderType !== null && getStringParam(orderType) !== '') {
                let cookieName = 'operations_reports_order' + object + '_' + app.me.projectId + '_' + app.me.id;
                orderInfo.fieldName = fieldName;
                orderInfo.orderType = orderType;
                setCookie(cookieName, JSON.stringify(orderInfo), constants.DEFAULT_COOKIE_EXPIRES);
            }

        } catch (error) {
            console.error("Error in 'AssetsReport.js -> setOrderInfoData()':" + error);
        }
    }

    const customEvent = (eventType, column, row, event) => {
        try {
            eventType = getStringParam(eventType);
            column = getObjectParam(column);
            row = getObjectParam(row);
            let colName = getStringParam(column.name);
            let recordId = row.id;

            if (eventType === 'is_button') {
                /* if (colName === 'login_action') {
                    let projectId = getIntParam(row.client_project_id);
                    login(projectId);
                } */
            } else if (eventType === 'is_detail_link') {
                openTab(getMultiColumnTitles(column.name, row), constants.ACCOUNTS_OBJECT, row.id);
            } else if (eventType === 'is_email_field') {
                openSendEmailDialog(constants.ACCOUNTS_OBJECT, row.id, getMultiColumnTitles(column.name, row));
            } else if (eventType === 'is_mobile_field') {
                if (column.is_twilio_sms && (column.is_twilio || column.is_ring_central)) {
                    event.preventDefault();
                    setIsTwilioPopOverOpen(true);
                    setAnchorEl(event.currentTarget);
                } else {
                    getRecordDetailsForSMS(recordId, colName);
                }
            } else if (eventType === 'is_phone_field') {

                let phoneField = true;
                let selectedPhoneNumber = getMultiColumnTitles(colName, row);
                if (column.is_ring_central) {
                    // Code for Ring Central
                    openRingCentralWebWidget();
                } else {
                    if (column.is_twilio) {
                        getCapabilityTokenForCall(constants.ACCOUNTS_OBJECT, recordId, phoneField, colName, selectedPhoneNumber);
                    } else if (eventType === 'is_mobile_field' && column.is_twilio_sms) {
                        getRecordDetailsForSMS(recordId, colName);
                    }
                }
            } else if (eventType === 'is_popover') {
                return getTwilioPopOver(column, row);
            }

        } catch (error) {
            console.error("Error in 'AssetsReport.js -> getUnits()':" + error);
        }
    }

    const openSendEmailDialog = (object, recordId, value) => {
        let labelName = getLocalizedStrings().label.COMMON.EMAIL_TO + " " + value + "";
        let data = {};
        data.id = recordId;
        data.object = object;
        data.email = value;
        data.type = constants.TYPE_EMAIL;
        dispatch(getAppDrawer(true, labelName, LinkToType.TYPE_EMAIL, data, null));
    }

    /*Start Twilio Call/SMS*/
    const getCapabilityTokenForCall = (object, recordId, phoneField, fieldName, selectedPhoneNumber) => {
        var promise = Promise.resolve(HTTPClient.get(endPoints.CALLING_INTEGRATION.GENERATE_CAPABILITY_TOKEN, null));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    let capabilityToken = getStringParam(response.token);
                    if (capabilityToken !== null && capabilityToken !== "") {
                        getCapabilityToken(capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber);
                    }
                }
            });
        }
    }

    const getCapabilityToken = (capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber) => {
        let endPoint = "";
        let params = {};
        if (object === constants.ACCOUNTS_OBJECT) {
            endPoint = endPoints.ACCOUNTS.MAP_DATA;
            params = { "id": recordId, "fields": ["company", "first_name", "last_name", "phone", "mobile", "workflow_id", "country"] };
        }

        var promise = Promise.resolve(HTTPClient.get(endPoint, params));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    let tempRecords = response.records;
                    processInfoAndOpenCallDialog(tempRecords, capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber);
                }
            });
        }
    }

    const processInfoAndOpenCallDialog = (recordsData, capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber) => {
        let firstName = "";
        let lastName = "";
        let fullName = "";
        let workflowId = "";
        let company = "";
        let title = "";
        let country = "";
        if (recordsData) {
            if (recordsData.hasOwnProperty('first_name')) {
                firstName = recordsData.first_name;
            }

            if (recordsData.hasOwnProperty('last_name')) {
                lastName = recordsData.last_name;
            }

            if (recordsData.hasOwnProperty('workflow_id')) {
                workflowId = recordsData.workflow_id;
            }

            if (recordsData.hasOwnProperty('company')) {
                company = recordsData.company;
            }

            if (recordsData.hasOwnProperty('country')) {
                country = recordsData.country;
            }
        }

        if (firstName !== null && firstName !== "" && firstName !== undefined && lastName !== null && lastName !== "" && lastName !== undefined) {
            fullName = firstName + " " + lastName;
        }
        if (firstName !== null && firstName !== "" && firstName !== undefined && (lastName === null || lastName === "" || lastName === undefined)) {
            fullName = firstName;
        }

        if ((firstName === null || firstName === "" || firstName === undefined) && lastName !== null && lastName !== "" && lastName !== undefined) {
            fullName = lastName;
        }

        if (fullName === null || fullName === "" || fullName === undefined) {
            fullName = company;
        }
        if (fullName === null || fullName === "" || fullName === undefined) {
            fullName = selectedPhoneNumber;
        }
        if (fullName !== null && fullName !== "" && fullName !== undefined) {
            title = "Call " + fullName + "";
        }

        let label = title;
        let type = LinkToType.TYPE_CLICK_TO_CALL;
        let data = {};
        data.id = recordId;
        data.object = object;
        data.token = capabilityToken;
        data.selectedPhoneNumber = selectedPhoneNumber;
        data.fieldName = fieldName;
        data.workflowId = workflowId;
        data.country = country;
        data.fullName = fullName;
        data.isDetailView = true;
        let style = {};
        style.width = '40%';
        dispatch(getCallDrawer(true, label, type, style, data, null));
    }

    const getRecordDetailsForSMS = (recordId, fieldName) => {
        let endPoint = endPoints.ACCOUNTS.MAP_DATA;
        let params = { "id": recordId, "fields": ["company", "first_name", "last_name", "phone", "mobile", "country"] };

        var promise = Promise.resolve(HTTPClient.get(endPoint, params));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    let tempRecords = response.records;
                    processInfoAndOpenSMSDialog(tempRecords, recordId, fieldName);
                }
            });
        }
    }

    const processInfoAndOpenSMSDialog = (recordsData, recordId, fieldName) => {
        let firstName = "";
        let lastName = "";
        let fullName = "";
        let company = "";
        let title = "";
        let country = "";
        let mobile = "";
        if (isValidParam(recordsData)) {
            firstName = getStringParam(recordsData.first_name);
            lastName = getStringParam(recordsData.last_name);
            company = getStringParam(recordsData.company);
            country = getStringParam(recordsData.country);
            mobile = getStringParam(recordsData.mobile);
        }

        fullName = (firstName + ' ' + lastName).trim();
        fullName = fullName === '' ? company : fullName;
        fullName = fullName === '' ? getStringParam(mobile) : fullName;
        title = fullName !== '' ? 'To ' + fullName : title;

        let label = title;
        let type = LinkToType.TYPE_CLICK_TO_SMS;
        let countryCode = "";
        let toNumber = getStringParam(mobile).trim();
        if (country === "USA" || country === "Canada") {
            countryCode = "+1";
        } else if (country === "India") {
            countryCode = "+91";
        } else if (country === "United Kingdom") {
            countryCode = "+44";
        } else if (country === "Germany") {
            countryCode = "+49";
        } else if (country === "France") {
            countryCode = "+33";
        } else if (country === "Spain") {
            countryCode = "+34";
        } else if (country === "Netherlands") {
            countryCode = "+31";
        } else if (country === "Australia") {
            countryCode = "+61";
        }

        if (toNumber.indexOf(countryCode) < 0) {
            toNumber = countryCode + toNumber;
        }

        let data = {};
        data.id = recordId;
        data.object = object;
        data.toNumber = toNumber;
        data.fieldName = fieldName;
        data.country = country;
        data.fullName = fullName;
        data.isDetailView = true;
        let style = {};
        style.width = '40%';
        dispatch(getAppCustomDrawer(true, label, type, style, data, null, false));
    }
    const getMenuItems = () => {
        let itemsArr = [];
        let items = [];
        try {
            itemsArr.push({ value: 'call', label: getLocalizedStrings().label.TWILIO.CALL, rightIconClassName: 'call' });
            itemsArr.push({ value: 'sms', label: getLocalizedStrings().label.TWILIO_SMS.SEND_SMS, rightIconClassName: 'textsms' });

            map(itemsArr, function (item, i) {
                let data = {};
                data.label = item.text;
                data.value = item.value;
                data.leftIcon = <i className={item.rightIconClassName} />;
                items.push(data);
            });
        } catch (error) {
            console.error("Error in 'assetsHome.js -> getMenuItems()':" + error);
        }
        return items;
    }
    const getTwilioPopOver = (field) => {
        let popover = null;
        try {
            popover =
                <Menu
                    open={getBooleanParam(fieldProps[field.name].isTwilioPopOverOpen)}
                    onClose={() => closeTwilioPopOver(field.name)} id='modules'
                    menuItemStyle={{ width: 168 }}
                    style={{ width: 165 }}>
                    <MenuItem key='call' id='call' value='call'
                        style={{ ...styles.popoverMenuItem, paddingLeft: 35 }}
                        onClick={(column, row, event, value) => openTwilioDialog(column, row, event, value, field, 'call')}
                    >
                        <span >
                            <Icon style={{ height: 14, width: 14, margin: 9, fontSize: 14 }}>call</Icon>
                        </span>
                        <span style={{ marginLeft: '4px' }}>{getLocalizedStrings().label.TWILIO.CALL}</span> </MenuItem>
                    <MenuItem key='sms' id='sms' value='sms'
                        style={{ ...styles.popoverMenuItem, paddingLeft: 35 }}
                        onClick={(column, row, event, value) => openTwilioDialog(column, row, event, value, field, 'sms')}
                    >
                        <span >
                            <Icon style={{ height: 16, width: 16, margin: 9, fontSize: 16 }}>textsms</Icon>
                        </span>
                        <span style={{ marginLeft: '4px' }}>{getLocalizedStrings().label.TWILIO_SMS.SEND_SMS}</span>
                    </MenuItem>
                </Menu>

        } catch (error) {
            console.error("Error in 'detailViewFields.js -> getTwilioPopOver()':" + error);
        }
        return popover;
    }

    const openTwilioPopOver = (event, fieldName) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setIsTwilioPopOverOpen(true);
    }

    const closeTwilioPopOver = (fieldName) => {
        setIsTwilioPopOverOpen(false);
    };

    const openTwilioDialog = (column, row, event, value) => {
        setIsTwilioPopOverOpen(false);
        let fieldName = column.name;
        let recordId = row.id;
        if (value === 'call') {
            if (column.is_ring_central) {
                // Code for Ring Central
                openRingCentralWebWidget();
            } else {
                if (column.is_twilio) {
                    let value = getMultiColumnTitles(fieldName, row);
                    getCapabilityTokenForCall(constants.ACCOUNTS_OBJECT, recordId, true, fieldName, value);
                }
            }
        } else if (value === 'sms') {
            getRecordDetailsForSMS(recordId, fieldName);
        }
    }

    /*End Twilio Call/SMS*/

    /* login = (projectId) => {
        try {
            let promise = franchiseLogin(projectId);
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response) && getBooleanParam(response.is_franchise_login)) {
                        localStorage.setItem('token', response.token);
                        localStorage.setItem('franchisor_token', response.franchisor_token);
                        //localStorage.removeItem('token');
                        //localStorage.setItem('isSFInvalidate', true);
                        if (constants.APP_URL === "") {
                            window.location.replace("/");
                        } else {
                            window.location.replace(constants.APP_URL);
                        }
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'AssetsReport.js -> login()':" + error);
        }
    } */

    const getMultilingualOptionsMap = () => {
        let optionsMultilingual = {
            'Today': getLocalizedStrings().label.DASHBOARD.TODAY,
            'Yesterday': getLocalizedStrings().label.DASHBOARD.YESTERDAY,
            'This Week': getLocalizedStrings().label.DASHBOARD.THIS_WEEK,
            'This Month': getLocalizedStrings().label.DASHBOARD.THIS_MONTH,
            'This Quarter': getLocalizedStrings().label.DASHBOARD.THIS_QUARTER,
            'This Year': getLocalizedStrings().label.DASHBOARD.THIS_YEAR,
            'Last Week': getLocalizedStrings().label.DASHBOARD.LAST_WEEK,
            'Last Month': getLocalizedStrings().label.DASHBOARD.LAST_MONTH,
            'Last Quarter': getLocalizedStrings().label.DASHBOARD.LAST_QUARTER,
            'Last Year': getLocalizedStrings().label.DASHBOARD.LAST_YEAR,
            'Custom': getLocalizedStrings().label.DASHBOARD.CUSTOM
        };
        return optionsMultilingual;
    }

    const generateDateSelectionField = () => {
        let optionsMultilingual = getMultilingualOptionsMap();
        try {
            return Object.keys(optionvalue).map((itemKey) => {
                return <MenuItem key={itemKey} value={itemKey} style={{ ...styles.popoverMenuItem }}>{optionsMultilingual[optionvalue[itemKey]]}</MenuItem>;
            });
        }
        catch (error) {
            console.error("Error in 'AssetsReport.js -> generateDateSelectionField()':" + error);
        }
    }
    const handleRequestClose = (fieldName) => {
        setFieldName(false);

    }

    const handleDropDownChange = (name, value) => {
         try {
            if (name === "due_by") {
                setDueBy(value);
                if (value === "cust") {
                    setCustomTimeLineDisplay('block');
                    let tempDate = moment(String(new Date())).format(dateFormat[app.me.date_format]);
                    setFrom_date(tempDate);
                    setTo_date(tempDate);
                    handleRequestClose(name + "_popup");
                    let dateTimeDiv1 = document.getElementsByClassName("dateTimeDiv-from-date");
                    if (isValidParam(dateTimeDiv1) && dateTimeDiv1.length > 0) {
                        dateTimeDiv1[0].classList.add('label-top');
                    }
                    let dateTimeDiv2 = document.getElementsByClassName("dateTimeDiv-to-date");
                    if (isValidParam(dateTimeDiv2) && dateTimeDiv2.length > 0) {
                        dateTimeDiv2[0].classList.add('label-top');
                    }
                    return;
                } else {
                    setCustomTimeLineDisplay('none');
                    setFrom_date(null);
                    setTo_date(null);
                    handleRequestClose(name + "_popup");
                }
            }

        } catch (error) {
            console.error("Error in 'AssetsReport.js -> handleDropDownChange()");
        }

    }
    const getNoRecordContent = () => {
        return (<table className="layout_maintable" style={{ width: '95%',marginLeft:'10px' }}><tr><td><span style={{ color: 'red' }}>{getLocalizedStrings().label.INXPRESS_REPORT.NO_RECORDS_FOUND}</span></td></tr></table>);
    }

    const openTab = (label, object, recordId) => {
        let activeTab = getObjectParam(getActiveTab());
        let activeTabIndex = getIntParam(activeTab.index);
        let url = '/' + object + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + recordId;
        let tab = { label: label, object: object, type: TYPE_DETAIL_VIEW, imgName: null, url: url, isActive: true, parentTabIndex: activeTabIndex };

        addTab(tab, true);
    }

    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight - 10) / 2;
    let style = { margin: 10, width: '98%', paddingTop: 0, paddingLeft: 11 };
    style = { ...style, ...getObjectParam(style) };
    let sfFormFieldContainerStyle = { ...styles.sfFormFieldContainer };
    let HHFormat = new RegExp("HH");
    let datePickerOptions = {
        enableTime: false,
        dateFormat: dateFormatFlatPicker[app.me.date_format],
        minuteIncrement: 1,
        static: true,
        time_24hr: HHFormat.test(app.me.date_format) ? true : false
    };
    return (
        <div className="row" style={{ padding: '0px' }} >
            <div>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', marginLeft: "17px" }}>
                            <div style={{ padding: 0, marginBottom: '30px' }} >
                                <FormControl variant="outlined" size="small">
                                    <InputLabel id="sf-assetsreports-simple-select-outlined-label" className="sf-assetsreports">{getLocalizedStrings().label.REPORT.DATE_RANGE}</InputLabel>
                                    <Select
                                        labelId="sf-assetsreports-simple-select-outlined-label"
                                        id="sf-assetsreports-simple-select-outlined"
                                        value={dueBy}
                                        onChange={(event) => handleDropDownChange('due_by', event.target.value)}
                                        label={getLocalizedStrings().label.REPORT.DATE_RANGE}
                                        className={"sf-fields-bg"}
                                        size="small"
                                        style={{ ...styles.sfFormFieldContainer, minWidth: 172, cursor: "pointer", color: '#717171', height: '45px', paddingLeft: 7, width: '100%', paddingRight: 7 }}
                                    >
                                        {generateDateSelectionField()}
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{ display: customTimeLineDisplay, }}>
                                <div style={{ marginRight: '7px', marginLeft: '7px' }}>
                                    <PopOver
                                        id={'from_date'}
                                        key={'from_date'}
                                        name={'from_date'}
                                        btnType={'date'}
                                        buttonStyle={{ fontSize: '16px' }}
                                        containerStyle={{ lineHeight: 1, marginBottom: '20px' }}
                                        buttonLabel={getLocalizedStrings().label.REPORT.START_DATE}
                                        onChange={(e) => setFrom_date(e)}
                                        value={from_date}
                                        innerLabelStyle={{ padding: "13 5 2 5", fontSize: '15px' }}
                                        height={45}
                                        options={datePickerOptions}
                                    />
                                </div>
                            </div>
                            <div style={{ display: customTimeLineDisplay }}>
                                <div style={{ marginRight: '7px', marginLeft: '7px' }}>
                                    <PopOver
                                        id={'to_date'}
                                        key={'to_date'}
                                        name={'to_date'}
                                        btnType={'date'}
                                        buttonStyle={{ fontSize: '16px' }}
                                        containerStyle={{ lineHeight: 1, marginBottom: '20px' }}
                                        buttonLabel={getLocalizedStrings().label.REPORT.END_DATE}
                                        onChange={(e) => setTo_date(e)}
                                        value={to_date}
                                        innerLabelStyle={{ padding: "13 5 2 5", fontSize: '15px' }}
                                        height={45}
                                        options={{ ...datePickerOptions, minDate: from_date }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-1" style={{ paddingLeft: '0px', display: 'block', width: '15%', marginLeft: '12px', paddingTop: '7px' }}>
                                <Button
                                    onClick={() => getUnitData()}
                                    style={{
                                        ...styles.primaryButton,
                                        minWidth: '56px', width: '19%',
                                        marginTop: 8
                                    }}
                                    title={getLocalizedStrings().label.REPORT.GO}
                                > {getLocalizedStrings().label.REPORT.GO}</Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {isMounted && records.length > 0 && <SFDataTable columns={fields} rowSort={rowSort} rows={records} customEvent={(eventType, column, row, event) => customEvent(eventType, column, row, event)} />}
            {isMounted && records.length == 0 && <div> {getNoRecordContent()}</div>}
            {!isMounted && <div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>}
        </div>
    );
}
export default AssetsReport;



function openRingCentralWebWidget() {
    var rcs = document.createElement("script");
    rcs.src = "https://ringcentral.github.io/ringcentral-embeddable-voice/adapter.js";
    rcs.id = "ring-central_widget_adapter";
    var rcs0 = document.getElementsByTagName("script")[0];
    if (rcs0.id !== rcs.id) {
        rcs0.parentNode.insertBefore(rcs, rcs0);
    }
    if (window.RCAdapter) {
        window.RCAdapter.setMinimized(false);
    }
}



// let login_action = { label: 'Login' };

const fields = [
    { name: 'company', label: 'Unit', width: 160, is_hidden: false, is_email_field: false, is_detail_link: true, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: {} },
    { name: 'full_name', label: 'Contact Name', width: 160, is_hidden: false, is_email_field: false, is_detail_link: false, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: {} },
    /* { name: 'email', label: 'Email', width: 160, is_hidden: false, is_email_field: true, is_detail_link: false, is_phone_field: false,  is_mobile_field: false, is_button: false, is_sortable: true, style: {} },
    { name: 'phone', label: 'Phone', width: 120, is_hidden: false, is_email_field: false, is_detail_link: false, is_phone_field: true,  is_mobile_field: false, is_button: false, is_sortable: true, style: {} },
    { name: 'mobile', label: 'Mobile', width: 120, is_hidden: false, is_email_field: false, is_detail_link: false, is_phone_field: false,  is_mobile_field: true, is_button: false, is_sortable: false, style: {} }, */
    { name: 'location', label: 'Location', width: 160, is_hidden: false, is_email_field: false, is_detail_link: false, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: {} },
    { name: 'leads', label: 'Leads', width: 40, is_hidden: false, is_email_field: false, is_detail_link: false, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: { textAlign: 'center' } },
    { name: 'workflows', label: 'Workflows', width: 40, is_hidden: false, is_email_field: false, is_detail_link: false, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: { textAlign: 'center' } },
    { name: 'templates', label: 'Templates', width: 40, is_hidden: false, is_email_field: false, is_detail_link: false, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: { textAlign: 'center' } },
    { name: 'broadcasts', label: 'Broadcasts', width: 40, is_hidden: false, is_email_field: false, is_detail_link: false, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: { textAlign: 'center' } },
    { name: 'automations', label: 'Automations', width: 40, is_hidden: false, is_email_field: false, is_detail_link: false, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: { textAlign: 'center' } },
    { name: 'assets', label: 'Assets', width: 40, is_hidden: false, is_email_field: false, is_detail_link: false, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: { textAlign: 'center' } },
];



