import React, { useState, useEffect } from 'react';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { FormControl, TextField, Button} from '@mui/material';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useDispatch, useSelector } from "react-redux";


const AddGroupDialog = () => {
    const dispatch = useDispatch();
    const appContainer = useSelector((state) => state.appContainer);
    
    const [groupName, setGroupName] = useState('');
    const [groupNameError, setGroupNameError] = useState('');
    
    useEffect(() => {
        dispatch(setAppDialogActions(getActions()))
    }, [groupName]);

    const validateForm = () => { 
        let isValid = true;
        let tempGroupName = groupName;
        if (tempGroupName.trim() === '') {
            isValid = false;
            setGroupNameError(getLocalizedStrings().message.BROADCAST_CAMPAIGNS.GROUP_NAME_BLANK)
        } else if (tempGroupName.trim().length > 128) {
            isValid = false;
            setGroupNameError(getLocalizedStrings().message.BROADCAST_CAMPAIGNS.GROUP_NAME_SIZE_LIMIT)
        }
        return isValid;
    }

    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const handleSave = (event) => {
        event.preventDefault();
        if (!validateForm()) {
            return false;
        }
        let obj = { group_name: groupName }
        appContainer.dialogProps.eventName(obj);
    }

    const handleChange = (event, value) => {
        setGroupName(value);
        if (value.trim() !== '') {
            setGroupNameError("")
        }
    };

    const getActions = () => {
        const actions = [
            <Button
                key={"save"}
                primary={"true"}
                onClick={(event)=>handleSave(event)}
                style={styles.primaryButton}
            >{getLocalizedStrings().label.COMMON.SAVE}</Button>,
            <Button
                key={"cancel"}
                primary={"true"}
                onClick={()=>handleClose()}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,

        ];
        return actions;
    }

        return (
            <div className="sf-addgroup">
                <div style={{ width: '100%' }}>
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            label={getLocalizedStrings().label.GROUP.GROUP_NAME}
                            fullWidth={true}
                            name="group_name"
                            value={groupName}
                            onChange={(event)=>handleChange(event, event.target.value)}
                            error={groupNameError !== ''}
                            helperText={groupNameError}
                            autoFocus={true}
                            autoComplete="new-password"
                            margin="dense"
                            size='small'
                            className={"sf-fields-bg"}
                        />
                    </FormControl>
                </div>
            </div>
        );
}

export default AddGroupDialog;