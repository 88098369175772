import { actions } from '..//constants/actions';
const powerCallingState = {
    queue: false,
    maxCount: false,
    callingMode: null,
    callStrated: false,
    isCallRecording: false,
    mounted: false,
    isrefresh: false,
    callCount: 0,
    callingInfo: [],
    data: {},
    noteData: "",
    defaultFromNumber: null,
    fromNumberCountryCode: null,
    fromNumberExtension: "",
    selectedCallScript: "-999",
    fieldValueForCallScriptDetails: "",
    noteButtonVisibility: false,
    skipedIds: [],
    callDrawerOpend: false,
    callDrawerLabel: null,
    callStyle: null,
    callAppBarProps: null,
    callActionType: null,
    callDetail : null,
    callCompleted:false

}

const powerCallingReducer = (state = powerCallingState, action) => {
    switch (action.type) {
        case actions.GET_CALL_DRAWER:
            if (action !== undefined && action.payload !== undefined) {
                return {
                    ...state,
                    callDrawerOpend: action.payload.isOpen,
                    callDrawerLabel: action.payload.dialogName,
                    callActionType: action.payload.actionType,
                    callStyle: action.payload.style,
                    callDetail: action.payload.data,
                    callAppBarProps: action.payload.headerProps
                }
            }
            break;
        case actions.POWER_CALLING_GET:
            return {
                ...state,
                mounted: true,
                isrefresh: false,
                callingInfo: action.payload,
                data: null,
                queue: false,
                callingMode: "both",
            }
        case actions.POWER_CALLING_SKIPTED_DATA_GET:
            return {
                ...state,
                data: null,
                selectedCallScript: "-999",
                fieldValueForCallScriptDetails: "",
                maxCount: false,
                callStrated: false,
                callingInfo: action.payload,
                mounted: false,
                isrefresh: false,
                callCount: 0,                
            }
        case 'POWERCALL_GET_FULLFILLED':
            return {
                ...state,
                mounted: true,
                isrefresh: true,
                queue: true,
                data: action.payload,
                selectedCallScript: "-999",
                fieldValueForCallScriptDetails: "",
                callCompleted:false
            }
        case actions.POWERCALL_SKIPTED_IDS_UPDATE:
            return {
                ...state,
                skipedIds: action.payload
            }
        case actions.POWERCALL_FROM_NO_UPDATE:
            return {
                ...state,
                defaultFromNumber: action.fromNumber,
                fromNumberCountryCode: action.fromCountryCode,
                fromNumberExtension: action.fromNoExtension
            }
        case actions.POWERCALL_STATUS_UPDATE:
            return {
                ...state,
                callCount: action.payload,
                callStrated: false,
            }
        case actions.POWERCALL_SCRIPT_UPDATE:
            return {
                ...state,
                selectedCallScript: action.payload,
            }
        case actions.POWERCALL_NOTE_BUTTON_VISIBILITY_UPDATE:
            return {
                ...state,
                noteButtonVisibility: action.payload,
            }
        case actions.POWERCALL_SCRIPT_DATA_UPDATE:
            return {
                ...state,
                fieldValueForCallScriptDetails: action.payload,
            }
        case actions.POWERCALL_NOTE_DATA_UPDATE:
            return {
                ...state,
                noteData: action.payload,
            }
        case actions.POWERCALL_IS_STARTED_UPDATE:
            return {
                ...state,
                callStrated: action.payload,
            }
        case actions.POWERCALL_IS_MAXCOUNT_UPDATE:
            return {
                ...state,
                maxCount: action.payload,
            }
        case actions.POWERCALLINNG_PROCESS_END:
            return {
                ...state,
                queue: false,
                callingMode: null,
                mounted: false,
                isrefresh: false,
                callCount: action.payload,
                callStrated: false,
                maxCount: false,
                callingInfo: [],
                data: {},
                selectedCallScript: "-999",
                fieldValueForCallScriptDetails: "",
                noteData: ""
            }
        default:
            return state;
    }
}

export default powerCallingReducer;