import React from 'react';
import { isValidParam, getObjectParam, getStringParam, getIntParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants, TABLEID_OBJECT_MAP, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import { Link } from 'react-router-dom';
import { addTab, TYPE_DETAIL_VIEW } from '../../../services/helper/sfTabManager';
import { getObjectSpecificEndpoint } from '../../../services/helper/utils';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { Icon } from '@mui/material';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const SMS = ({ parentObject, parentRecordId, data, callFrom }) => {
    const dispatch = useDispatch();
    const [mounted, setMounted] = useState(false);
    const [smsReportInfo, setSmsReportInfo] = useState(null);

    useEffect(() => {
        let tempCallFrom = getStringParam(callFrom);
        if (tempCallFrom === constants.NOTES) {
            getContactBySMS();
        } else {
            setMounted(true);
        }
    }, []);

    const getContactBySMS = () => {
        try {
            let tempData = getObjectParam(data);
            let parentObject = getStringParam(TABLEID_OBJECT_MAP[getIntParam(tempData.table_id)]).trim();
            let parentRecordId = getIntParam(tempData.record_id);
            if (parentObject !== '' && parentRecordId > 0) {
                let params = { "id": parentRecordId, parent_table_id: OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT] };
                let endPoint = getObjectSpecificEndpoint(parentObject, endPoints.RECORDS.RECORD_LINKED_MAP_DATA);
                if (parentObject === constants.ACCOUNTS_OBJECT || parentObject === constants.CONTACTS_OBJECT) {
                    endPoint = getObjectSpecificEndpoint(parentObject, endPoints.RECORDS.MAP_DATA);
                    params = { "id": parentRecordId, "fields": ['company', 'first_name', 'last_name', 'email'] };
                }
                var promise = Promise.resolve(HTTPClient.get(endPoint, params));
                if (isValidParam(promise)) {
                    promise.then(response => {
                        if (isValidParam(response)) {
                            setMounted(true);
                            setSmsReportInfo(response);
                        }
                    });
                }
            } else {
                setMounted(true);
            }

        } catch (error) {
            console.error("Error in 'sms.js -> getContactBySMS()':" + error);
        }
    }

    const sendSMS = (data) => {
        //this.props.getAppDrawer(true, label, type, data, callFrom);
    }

    const getNoteHeader = () => {
        let noteHeader = null;
        let elements = [];
        let title = '';
        try {
            let tempcallFrom = getStringParam(callFrom);
            let tempdata = getObjectParam(data);
            let subject = getStringParam(tempdata.sms_text);
            let smsDate = getStringParam(tempdata.date_time).trim();
            let subjectToShow = subject.length > 55 ? subject.substring(0, 55) + '...' : subject;

            if (tempcallFrom === constants.NOTES && isValidParam(smsReportInfo)) {
                let contact = getObjectParam(smsReportInfo.records);
                if (isValidParam(contact) && Object.keys(contact).length > 0) {
                    let fullName = getStringParam(contact.first_name).trim() + ' ' + getStringParam(contact.last_name).trim();
                    fullName = fullName.trim() === '' ? 'Empty' : fullName;
                    elements.push(<div style={{ lineHeight: 1.3, marginBottom: 5 }} title={getLocalizedStrings().label.SMS.TO + ': ' + fullName}>
                        <span style={{ fontWeight: 'bold', color: '#070100' }}>{getLocalizedStrings().label.SMS.TO}: </span>
                        {<Link to="#" onClick={() => openAsDetailView()}>{fullName}</Link>}
                    </div>);
                }

                elements.push(<div style={{ width: '100%' }}>
                    <div style={{display: 'flex', marginBottom: 5 }} title={getLocalizedStrings().label.SMS.WHEN + ': ' + smsDate}>
                        <span style={{
                            fontWeight: 'bold', color: '#070100', width: '55px'
                        }}>{getLocalizedStrings().label.SMS.WHEN}:</span>
                        <div style={{ width: '100%' }}>{smsDate}</div>
                    </div>
                </div>);
                elements.push(
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', marginBottom: 5 }} title={getLocalizedStrings().label.SMS.FROM_NUMBER + ': ' + getStringParam(tempdata.from_phone)}>
                            <span style={{
                                fontWeight: 'bold', color: '#070100', width: '130px'
                            }}>{getLocalizedStrings().label.SMS.FROM_NUMBER}:</span>
                            <div style={{ width: '100%' }}>{tempdata.from_phone}</div>
                        </div>
                    </div>);
                elements.push(<div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', marginBottom: 5 }} title={getLocalizedStrings().label.SMS.TO_NUMBER + ': ' + getStringParam(tempdata.to_phone)}>
                        <span style={{
                            fontWeight: 'bold', color: '#070100', width: '100px'
                        }}>{getLocalizedStrings().label.SMS.TO_NUMBER}:</span>
                        <div style={{ width: '100%' }}>{tempdata.to_phone}</div>
                    </div>
                </div>);
                elements.push(
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', marginBottom: 5 }} title={getLocalizedStrings().label.SMS.STATUS + ': ' + getStringParam(tempdata.t_status)}>
                            <span style={{
                                fontWeight: 'bold', color: '#070100', width: '60px'
                            }}>{getLocalizedStrings().label.SMS.STATUS}:</span>
                            <div style={{ width: '100%' }}>{tempdata.t_status}</div>
                        </div>
                    </div>);
                if (tempdata.t_status == 'Failed' || tempdata.t_status == 'Undelivered') {
                    elements.push(
                        <div style={{ width: '100%' }}>
                            <div style={{ display: 'flex', marginBottom: 5 }} title={getLocalizedStrings().label.SMS.FAILURE_REASON + ': ' + getStringParam(tempdata.error_reason)}>
                                <span style={{
                                    fontWeight: 'bold', color: '#070100', width: '135px'
                                }}>{getLocalizedStrings().label.SMS.FAILURE_REASON}:</span>
                                <div style={{ width: '100%' }}>{tempdata.error_reason}</div>
                            </div>
                        </div>);
                }
                noteHeader = <div title={title} style={{ marginTop: 10 }}>{elements}</div>;
            } else {
                title = subjectToShow;
                elements.push(<span>{subjectToShow}</span>);
                elements.push(<span>,&nbsp;{smsDate}</span>);
                title = title + ', ' + smsDate;
                noteHeader = <span title={title}>{elements}</span>;
            }
        } catch (error) {
            console.error("Error in 'sms.js -> getNoteHeader()':" + error);
        }
        return noteHeader;
    }

    const openAsDetailView = () => {
        try {
            let tempSmsReportInfo = getObjectParam(smsReportInfo);
            let objectName = getStringParam(parentObject);
            let id = getIntParam(parentRecordId);
            if (objectName !== constants.ACCOUNTS_OBJECT && objectName !== constants.CONTACTS_OBJECT) {
                objectName = getStringParam(TABLEID_OBJECT_MAP[tempSmsReportInfo.parentTableId]);
                id = getIntParam(tempSmsReportInfo.parentRecordId)
            }
            let linkToUrl = '/' + objectName + '/detailview/' + id;
            let labelName = '';
            let labelObj = getObjectParam(tempSmsReportInfo.records);
            if (isValidParam(labelObj)) {
                labelName = labelObj.company;
            }
            labelName = labelName.trim() === '' ? 'Empty' : labelName;

            let tab = { label: labelName, object: objectName, type: TYPE_DETAIL_VIEW, url: linkToUrl };
            dispatch(addTab(tab, true));
        } catch (error) {
            console.error("Error in 'sms.js -> openAsDetailView()':" + error);
        }
    }

    const generateImageView = (attachmentArr) => {
        let arrElements = [];
        try {
            attachmentArr = getArrayParam(attachmentArr);
            attachmentArr.forEach((attachment, index) => {
                if (isValidParam(attachment)) {
                    arrElements.push(
                        <div className='row' style={{ margin: '10 0', minHeight: 20 }}>
                            <div style={{ padding: 10, borderRadius: 5, maxWidth: '85%', float: 'left', background: '#e3e6e8' }}>
                                <div style={{ maxWidth: '100%', float: 'left' }}>
                                    <div style={{ maxHeight: 150, maxWidth: 150 }}>
                                        <a href={attachment} target="_blank" rel="noreferrer"><img alt="" src={attachment} /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            });
        } catch (error) {
            console.error("Error in 'sms.js -> generateImageView()':" + error);
        }
        return arrElements;
    }
    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight - 10) / 2;
    if (!isValidParam(data)) {
        return <div />;
    }
    let rootDivStyle = { lineHeight: '30px', paddingTop: '7px', paddingBottom: '10px', maxHeight: '90%' };
    let headerContainerStyle = { backgroundColor: '#fafafa', color: '#535353', padding: '5px 10px', width: '100%', display: 'table' };
    let headerLeftContainerStyle = { float: 'left', overflow: 'hidden', width: '100%' };
    let tempcallFrom = getStringParam(callFrom);
    if (tempcallFrom === constants.NOTES) {
        rootDivStyle = { ...rootDivStyle, margin: '0px 15px' };
        headerContainerStyle.padding = 10;
    } else {
        headerLeftContainerStyle.fontWeight = "bold";
        headerLeftContainerStyle.color = "#070100";
    }
    let isMounted = mounted;
    if (!isMounted) {
        return <div style={{ width: '100%', height: pageHeight }}>
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
        </div>
    }

    let tempdata = getObjectParam(data);

    let noteHeader = getNoteHeader();
    let noteBody = getStringParam(tempdata.sms_text).trim();
    let attachmentArr = tempdata.hasOwnProperty('t_attachment') ? getArrayParam(tempdata.t_attachment) : null;
    return <div style={rootDivStyle}>
        <div style={headerContainerStyle}>
            <div style={headerLeftContainerStyle}>
                <div style={{ lineHeight: 1 }}>
                    <Icon title={getLocalizedStrings().label.COMMON.SEND_TEXT_MESSAGE} style={{ fontSize: '18px', color: '#717171', cursor: 'pointer', paddingTop: '4px', paddingLeft: '0px', paddingBottom: '16px' }} onClick={() => sendSMS(tempdata)} >textsms</Icon>
                    {noteHeader}
                </div>
            </div>
        </div>

        <div style={{ color: '#717171', paddingLeft: '10px', paddingTop: '6px' }}>
            <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all', lineHeight: '18px', overflow: 'auto' }}>
                <div dangerouslySetInnerHTML={{ __html: noteBody }} />
            </div>
            {isValidParam(attachmentArr) && getArrayParam(attachmentArr).length > 0 && <div >{() => generateImageView(attachmentArr)}</div>}
        </div>
    </div>;
}

export default SMS;
