import * as HTTPClient from '..//helper/httpClient';
import { actions } from '..//constants/actions';
import { endPoints } from '..//constants/endPoints';
import { constants } from '..//constants/constants';

function getPendingTasks() {
    let params = {
        query_name: "All Tasks",
        query_type: "query",
    }

    return {
        type: actions.TASK_GET,
        payload: HTTPClient.get(endPoints.TASK.LIST_VIEW, params)
    }
}

function getTasksListForDetailView(parentObject, parentRecordId, workflowId, queryName, queryType, queryId, isDetailView) {
    let searchArr = [];
    let searchObj = null;
    let fields = [];
    let params = null;
    let url = null;
    let type = null;

    if (isDetailView) {
        searchObj = {
            t_status: "Pending"
        }
        searchArr.push(searchObj);
        searchObj = null;
        searchObj = {
            t_workflow_task_id: workflowId
        }
        searchArr.push(searchObj);
        searchObj = null;

        fields.push("t_name");
        fields.push("t_owner");
        fields.push("t_dueby");
        fields.push("parent_recordid");

        params = {
            search: searchArr,
            fields: fields,
            parent_object: parentObject,
            parent_record_id: parentRecordId
        }

        type = actions.TASK_DETAILVIEW_GET;
        url = endPoints.TASK.LIST;
    } else if (!isDetailView) {
        if (parentObject === constants.ACCOUNTS_OBJECT) {
            url = endPoints.ACCOUNTS.TODAYS_TASKS;
        } else if (parentObject === constants.CONTACTS_OBJECT) {
            url = endPoints.CONTACTS.TODAYS_TASKS;
        } else if (parentObject === constants.OPPORTUNITIES_OBJECT) {
            url = endPoints.OPPORTUNITIES.TODAYS_TASKS;
        }
        type = actions.TASK_HOME_GET;
    }
    return {
        type: type,
        payload: HTTPClient.get(url, params)
    }
}

function getTasksPortal(params) {
    let url = null;
    let type = null;
    type = actions.TASK_PORTAL;
    url = endPoints.TASK.PORTAL;
    return {
        type: type,
        payload: HTTPClient.get(url, params)
    }
}

function getTasksPortalScroll(params) {
    let url = null;
    let type = null;
    type = actions.TASK_PORTAL_SCROLL;
    url = endPoints.TASK.PORTAL;
    return {
        type: type,
        payload: HTTPClient.get(url, params)
    }
}

function getTasksRefresh(refresh) {
    //let state = store.getState();
    // store.dispatch(removeColumnViewScrollPosition(state.lists.object));
    // store.dispatch(removeColumnViewHorizontalScrollPosition(state.lists.object));
    let type = null;
    type = actions.TASK_PANE_REFRESH;
    return {
        type: type,
        payload: refresh
    }
}

function clearTaskReduccer(){
    return {
        type: actions.CLEAR_TASK_REDUCER,
        payload: {}
    }
}

function getUnitTasksRefresh(refresh) {
    let type = null;
    type = actions.UNIT_TASK_PANE_REFRESH;
    return {
        type: type,
        payload: refresh
    }
}


export {
    getPendingTasks,
    getTasksListForDetailView,
    getTasksPortal,
    getTasksRefresh,
    clearTaskReduccer,
    getTasksPortalScroll,
    getUnitTasksRefresh
}