import React, { useState, useEffect, useRef } from 'react';
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import ShowCircularProgress from "../components/circularProgress";
import * as HTTPClient from "../../../services/helper/httpClient";
import { endPoints } from "../../../services/constants/endPoints";
import {constants,EVENT_TYPE_KEYS} from "../../../services/constants/constants";
import { styles } from "../../../services/constants/styles";
import { setTabs } from "../../../services/actions/tabActions";
import { Navigate } from "react-router-dom";
import {getAppDialog,getAppDrawer,getAppCustomDrawer} from "../../../services/actions/appContainerActions";
import { Link } from "react-router-dom";
import { saveGroup } from "../../../services/actions/batchActions";
import {Button,Chip,FormControl,FormControlLabel,MenuItem,Radio,RadioGroup,Select as SelectField,Tabs,Tab,TextField,InputLabel,Paper} from "@mui/material";
import MessageDetails from "../components/messageDetails";
import {dateTimeFormat,timeZone,sortArrayObjectByProperty} from "../../../services/helper/utils";
import moment from "moment";
import {hasAccessPermission,addCampaignTypeIntoReduxStore,getObjectLabelByObject} from "../../../services/helper/common";
import * as sfDialogs from "../components/sfDialogs";
import {addTab,TYPE_LIST_VIEW,getActiveTab} from "../../../services/helper/sfTabManager";
import {isValidParam,getIntParam,getStringParam,getArrayParam,getObjectParam,} from "../../../services/helper/parameterVerifier";
import { setMarketingResults } from "../../../services/actions/marketingReportAction";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { refreshListView } from "../../../services/actions/listViewAcions";
import Autocomplete from "@mui/material/Autocomplete";
import { getAllCampaignTypes } from "../../../services/actions/campaignTypeActions";
import { useDispatch, useSelector } from "react-redux";
require("froala-editor/js/froala_editor.pkgd.min.js");
require("froala-editor/css/froala_editor.pkgd.min.css");

const ABCampaign = ({ type, id, object }) => {
	var _winner_by_options = [
		{
			label: "Email Views",
			value: "Email Views",
		},
		{
			label: "Email Clicks",
			value: "Email Clicks",
		},
	];
	
	var _send_remaining_options = [
		{
			label: "No",
			value: "No",
		},
		{
			label: "Yes",
			value: "Yes",
		},
	];
	
	const dispatch = useDispatch();
	const inputRef = useRef(null);
	const app = useSelector((state) => state.app);
	const sfForm = useSelector((state) => state.sfForm);
	
	const data = useSelector((state) => state.data);
	const campaignTypes = useSelector((state) => state.campaignTypes);
	const [campaign, setCampaign] = useState({
		campaign_name: "",
		id: id | 0,
		campaign_targets: [],
		view_as_web_page: true,
		t_status: null,
		winner_by: "Email Views",
		distribution_A: "",
		distribution_B: "",
		margin: "",
		send_remaining: "No",
		send_after: 0,
		campaign_type: "",
		message_details: {
			a: {
				campaign_msg_id: 0,
				name: "",
				sender_name: "",
				sender_email: "",
				view_as_web_page: true,
				template_id: 0,
				subject: "",
				email_message: "",
				selectedCategory: null,
				selectedTemplate: null,
			},
			b: {
				campaign_msg_id: 0,
				name: "",
				sender_name: "",
				sender_email: "",
				view_as_web_page: true,
				template_id: 0,
				subject: "",
				email_message: "",
				selectedCategory: null,
				selectedTemplate: null,
			},
		},
	});
	const [mounted, setMounted] = useState(false);
	const [error, setError] = useState({
		campaign_name: "",
		filter_options: "",
		distribution_A: "",
		distribution_B: "",
		distribution_AB: "",
		margin: "",
		send_after: "",
		both_tab_filled: "",
		message_details: {
			a: {
				sender_name: "",
				sender_email: "",
				subject: "",
			},
			b: {
				sender_name: "",
				sender_email: "",
				subject: "",
			},
		},
	});

	const [buttonVisible, setButtonVisible] = useState("none");
	const [verifid_email_ids, setVerifid_email_ids] = useState([]);
	const [disabled, setDisabled] = useState(false);

	const [filter_options, setFilter_options] = useState([]);
	const [selected_filter_options, setSelected_filter_options] = useState([]);
	const [filter_type, setFilter_type] = useState("group");
	const [loaded_filter_options, setLoaded_filter_options] = useState({
		group: { loaded: false, options: [] },
		contact: { loaded: false, options: [] },
		account: { loaded: false, options: [] },
	});
	const [winner_by_options, setWinner_by_options] = useState(_winner_by_options);
	const [send_remaining_options, setSend_remaining_options] = useState(_send_remaining_options);
	const [options_loading, setOptions_loading] = useState(true);

	const [multi, setMulti] = useState(true);
	const [change_template_dialog_status, setChange_template_dialog_status] = useState(false);
	const [email_credit_balance, setEmail_credit_balance] = useState(null);
	const [scheduled, setScheduled] = useState(false);

	const [scheduled_on, setScheduled_on] = useState(null);
	const [stickyStyle, setStickyStyle] = useState({});
	const [anchorMoreActionEl, setAnchorMoreActionEl] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorPersonilizeEl, setAnchorPersonilizeEl] = useState(null);
	const [selected_tab, setSelected_tab] = useState("a");
	const [both_tab_filled, setBoth_tab_filled] = useState(false);
	const [result, setResult] = useState(false);

	const [resultContent, setResultContent] = useState(null);
	const [resultExecuteStart, setResultExecuteStart] = useState(false);
	const [placeholder, setPlaceholder] = useState(null);
	const [gblStatIdSubArr, setGblStatIdSubArr] = useState([]);
	const [a_savedSenderEmail, setA_savedSenderEmail] = useState(null);
	const [b_savedSenderEmail, setB_savedSenderEmail] = useState(null);
	const [send_after, setSend_after] = useState(null);
	const [campTypelistArr, setCampTypelistArr] = useState(null);
	const [campTypeMenuItems, setCampTypeMenuItems] = useState(null);
	const [changeLog, setchangeLog] = useState({selected_filter_options:[]});

	const loadABCampaignData = (id) => {
		if (id !== undefined && id !== null && id !== 0) {
			var response = Promise.resolve(
				HTTPClient.get(endPoints.AB_CAMPAIGNS.EDIT + "/" + id, null)
			);
			response.then((response) => {
				let tempCampaign = campaign;
				const campaign_details = response.campaign_details;
				let filter_type;
				if(campaign_details.t_target_filter != undefined ){
					filter_type = campaign_details.t_target_filter[0].type;
				}
				tempCampaign["id"] = campaign_details.id;
				tempCampaign["campaign_name"] = campaign_details.t_campaign_name;
				tempCampaign["margin"] = campaign_details.t_margin;
				tempCampaign["send_after"] = campaign_details.t_send_after;
				tempCampaign["winner_by"] = campaign_details.t_winner_by[0];
				tempCampaign["send_remaining"] = campaign_details.t_send_remaining;
				tempCampaign["distribution_A"] =
					campaign_details.distribution[0].distribution_A;
				tempCampaign["distribution_B"] =
					campaign_details.distribution[0].distribution_B;
				tempCampaign["t_status"] = campaign_details.t_status;
				tempCampaign["campaign_type"] =
					campaign_details.campaign_type === 0
						? ""
						: campaign_details.campaign_type;
				tempCampaign.message_details.a = response.message_details[0];
				tempCampaign.message_details.b = response.message_details[1];

				tempCampaign.message_details.a["sender_email"] = isValidParam(response.message_details[0].sender_email) ? response.message_details[0].sender_email : '';
				tempCampaign.message_details.b["sender_email"] = isValidParam(response.message_details[1].sender_email) ? response.message_details[1].sender_email : '' ;

				tempCampaign.message_details.a["sender_name"] = isValidParam(response.message_details[0].sender_name)?response.message_details[0].sender_name : '';
				tempCampaign.message_details.b["sender_name"] = isValidParam(response.message_details[1].sender_name)?response.message_details[1].sender_name : '';
					setCampaign({...tempCampaign});
					setButtonVisible("inline-block");
					setFilter_type(filter_type);
					setVerifid_email_ids(response.verifid_email_ids);
					setEmail_credit_balance(response.email_credit_balance);

				if (
					campaign_details.t_status === "Queued" ||
					campaign_details.t_status === "Executed" ||
					campaign_details.t_status === "Sent to Winner" ||
					campaign_details.t_status === "Executed - No Winner" ||
					campaign_details.t_status === "Halted" ||
					campaign_details.t_status === "Running" ||
					campaign_details.t_status === "Sending to Winner"
				) {
					setDisabled(true);
					setScheduled(true);
					setScheduled_on(campaign_details.t_rundate);
				}

				if (campaign_details.t_status === "Running") {
					setDisabled(true);
				}

				if (campaign_details.t_target_filter) {
					let _selected_filter_options =
						campaign_details.t_target_filter.map(function (target, index) {
							if (filter_type === "group") {
								return {
									value: target.id,
									label: target.name,
									title: target.name,
								};
							} else {
								return {
									value: target.id,
									label: target.name,
								};
							}
						});
						setSelected_filter_options([..._selected_filter_options]);
						let log = changeLog;
                        log.selected_filter_options = _selected_filter_options;
                        setchangeLog({ ...log });
					let _campaign_targets = campaign_details.t_target_filter.map(function (target, index) {
							return {
								id: target.id,
								name: target.name,
								type: target.type,
							};
						});
						tempCampaign.campaign_targets = _campaign_targets;
						setCampaign({...tempCampaign});
					if (filter_type === "contact" || filter_type === "account") {
						setSelected_filter_options(_selected_filter_options[0])
						let log = changeLog;
                        log.selected_filter_options = _selected_filter_options[0];
                        setchangeLog({ ...log });
					}
				} else {
					setSelected_filter_options([]);
					let log = changeLog;
                        log.selected_filter_options = [];
                        setchangeLog({ ...log });
					tempCampaign.campaign_targets = [];
					setCampaign({...tempCampaign});
				}

				setA_savedSenderEmail(tempCampaign.message_details["a"].sender_email);
				setB_savedSenderEmail(tempCampaign.message_details["b"].sender_email);
				
				let tempError = error;

				tempError["campaign_name"] = "";
				tempError["filter_options"] = "";
				tempError.message_details.a.sender_name = "";
				tempError.message_details.a.sender_email = "";
				tempError.message_details.a.subject = "";
				tempError.message_details.b.sender_name = "";
				tempError.message_details.b.sender_email = "";
				tempError.message_details.b.subject = "";
				setError({...tempError});
				setMounted(true);

				if (type === "copy") {
					handleCopy();
				}
				loadFilterOptions(filter_type);
				if (
					type !== "copy" &&
					(campaign_details.t_status === "Executed" ||
						campaign_details.t_status === "Sent to Winner" ||
						campaign_details.t_status === "Executed - No Winner" ||
						campaign_details.t_status === "Running" ||
						campaign_details.t_status === "Sending to Winner")
				) {
					loadResults();
				}
				getCampaignTypeList();
			});
		} else {
			let params = {
				"template_id": data.obj.template_id,
			};

			response = Promise.resolve(
				HTTPClient.get(endPoints.AB_CAMPAIGNS.NEW, params)
			);
			response.then((response) => {
				let campaign_details = response.campaign_details;
				let tempCampaign = campaign;
				tempCampaign["id"] = 0;
				tempCampaign["campaign_name"] = "";
				tempCampaign["margin"] = campaign_details.t_margin;
				tempCampaign["send_after"] = 0;
				tempCampaign["send_remaining"] = campaign_details.t_send_remaining;

				tempCampaign.message_details.a["sender_email"] =
					app.me.email;
				tempCampaign.message_details.b["sender_email"] =
					app.me.email;
				tempCampaign.message_details.a["sender_name"] =
					app.me.name;
				tempCampaign.message_details.b["sender_name"] =
					app.me.name;

				tempCampaign.message_details.a["name"] =
					response.message_details[0].name;
				tempCampaign.message_details.a["subject"] =
					response.message_details[0].subject;
				tempCampaign.message_details.a["email_message"] =
					response.message_details[0].email_message;
				tempCampaign.message_details.a["view_as_web_page"] =
					response.message_details[0].view_as_web_page;
				tempCampaign.message_details.a["selectedTemplate"] =
					data.obj.template_id;
				tempCampaign.message_details.a["selectedCategory"] =
					data.obj.category_id;

				tempCampaign.message_details.b["name"] =
					response.message_details[1].name;
				tempCampaign.message_details.b["subject"] =
					response.message_details[1].subject;
				tempCampaign.message_details.b["email_message"] =
					response.message_details[1].email_message;
				tempCampaign.message_details.b["view_as_web_page"] =
					response.message_details[1].view_as_web_page;
				tempCampaign.message_details.b["selectedTemplate"] =
					data.obj.template_id;
				tempCampaign.message_details.b["selectedCategory"] =
					data.obj.category_id;
					setCampaign({...tempCampaign});
					setVerifid_email_ids(response.verifid_email_ids);
					setEmail_credit_balance(response.email_credit_balance);
					
					setPlaceholder(getLocalizedStrings().label.CAMPAIGNS.SELECT_GROUP);
					
				let temp = error;
				temp["campaign_name"] = "";
				temp["filter_options"] = "";
				temp.message_details.a.sender_name = "";
				temp.message_details.a.sender_email = "";
				temp.message_details.a.subject = "";
				temp.message_details.b.sender_name = "";
				temp.message_details.b.sender_email = "";
				temp.message_details.b.subject = "";
				setError({...temp});
				setMounted(true);
				getCampaignTypeList();
			});
			loadFilterOptions("group");
		}
		
	};
	const getCampaignTypeList = () => {
		try {
			let campaign_types = getObjectParam(campaignTypes.data);
			let campTypelistArr = [];
			let objCamp = null;
			if (Object.keys(campaign_types).length > 0) {
				Object.keys(campaign_types).forEach((itemKey) => {
					objCamp = {};
					objCamp.id = parseInt(itemKey);
					objCamp.name = campaign_types[itemKey];

					campTypelistArr.push(objCamp);

					objCamp = null;
				});
			}

			sortArrayObjectByProperty(campTypelistArr, "name");
			let campTypeMenuItems = getCampaignTypeMenuItems(campTypelistArr);
			let tempCampTypelistArr = campTypelistArr;
			let tempCampTypeMenuItems = campTypeMenuItems;

			tempCampTypelistArr = campTypelistArr;
			tempCampTypeMenuItems = campTypeMenuItems;
			setCampTypelistArr(tempCampTypelistArr);
			setCampTypeMenuItems(tempCampTypeMenuItems);
		} catch (error) {
			console.log(
				"Error in 'BroadcastCampaignForMosico.js -> getCampaignTypeList()': " +
					error
			);
		}
	};


	const getCampaignTypeMenuItems = (campTypelistArr) => {
		try {
			let tempcampaignTypes = getArrayParam(campTypelistArr);
			let campTypeItems = null;
			if (tempcampaignTypes !== null && tempcampaignTypes.length > 0) {
				campTypeItems = tempcampaignTypes.map((camTy, index) => {
					return (
						<MenuItem
							key={camTy.id}
							value={camTy.id}
							style={styles.popoverMenuItem}
						>
							{
								<div
									style={{
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
										overflow: "hidden",
										width: "142px",
									}}
									title={camTy.name}
								>
									{camTy.name}
								</div>
							}
						</MenuItem>
					);
				});
			}
			return campTypeItems;
		} catch (error) {
			console.log(
				"Error in 'ABCampaign.js -> getCampaignTypeMenuItems()': " + error
			);
		}
	};
	const handleCopy = () => {
		let hasPermission = false;
		hasPermission = hasAccessPermission(
			constants.AB_CAMPAIGNS_OBJECT,
			constants.SECURITY_LEVEL_TYPE_ACCESS,
			constants.ACCESS_TYPE_CREATE
		);
		if (hasPermission) {
			let temp = campaign;
			temp.id = 0;
			temp.message_details.a.template_id = 0;
			temp.message_details.a.campaign_msg_id = 0;
			temp.message_details.b.campaign_msg_id = 0;
			temp.message_details.b.template_id = 0;
			temp.campaign_name =
				"Copy_of_" + campaign.campaign_name;
			temp.t_status = null;
			setCampaign({...temp});
			setButtonVisible("none");
			setDisabled(false);
			setScheduled(false);
			setScheduled_on(null);
			setResult(false);
			setResultExecuteStart(false);
			let obj = {
				details: campaign.message_details[selected_tab],
				error: error.message_details[selected_tab],
			};
			updateMessageDetails(obj, "copy");
		} else {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				getLocalizedStrings().message.COMMON.ACCESS_DENIED,
				null
			);
		}
	};
	const updateMessageDetails = (obj, callFrom) => {
		if (isValidParam(callFrom) && callFrom.length > 0 && callFrom === "copy") {
			if (isValidParam(obj) && selected_tab === "a") {
				obj.details.sender_email = a_savedSenderEmail;
			} else if (isValidParam(obj) && selected_tab === "b") {
				obj.details.sender_email = b_savedSenderEmail;
			}
		}
		let temp = campaign;
		temp.message_details[selected_tab] = obj.details;
		setCampaign({...temp});
		let tempError = error;
		tempError.message_details[selected_tab] = obj.error;
		setError({...tempError});
	};
	const loadResults = () => {
		setResultExecuteStart(true);
		setResult(false);
		setResultContent(null);
		let params = {
			campaign_id: campaign.id,
			report_type: "AB Report",
		};

		var promise = Promise.resolve(
			HTTPClient.get(endPoints.AB_CAMPAIGNS.RESULTS, params)
		);

		promise.then((response) => {
			if (
				Array.isArray(response.marketing_results) &&
				response.marketing_results.length > 0
			) {
				setResult(true);
				setResultContent(response);
				setResultExecuteStart(false);
				response.marketing_results.forEach(function (resultObject, key) {
					if (resultObject.hasOwnProperty("clicked")) {
						let clickCount = resultObject.clicked;
						if (clickCount > 0) {
							gblStatIdSubArr[resultObject.id] = resultObject.t_msg_name;
						}
					}
				});
				setGblStatIdSubArr(gblStatIdSubArr);

			} else {
				setResult(true);
				setResultExecuteStart(false);
				setResultContent("");

			}
		});
	};

	const checkAndResetSelectedFilterOptions = () => {
		if (filter_type === "group") {
			let selected_filter_options = [];
			let campaign_targets = [];
			selected_filter_options.forEach(function (selectedObj) {
				filter_options.forEach(function (listObj) {
					if (selectedObj.value === listObj.value) {
						selected_filter_options.push(selectedObj);
						campaign_targets.push({
							id: selectedObj.value,
							name: selectedObj.label,
							type: "group",
						});
					}
				});
			});
			let temp = campaign;
			temp.campaign_targets = campaign_targets;
			setCampaign({...temp});
		}
	};

	const loadFilterOptions = (filter_type) => {
		setOptions_loading(true);
		if (loaded_filter_options[filter_type].loaded) {
			let multi = filter_type === "group" ? true : false;
			setFilter_options([...loaded_filter_options[filter_type].options]);
			setFilter_type(filter_type);
			setOptions_loading(false);
			setMulti(multi);
			checkAndResetSelectedFilterOptions();
			return;
		}

		let end_points, params;
		if (filter_type === "group") {
			end_points = endPoints.GROUP.LIST;
			params = {
				fields: ["name", "id"],
				search_field_name: "t_status",
				search_text: "Active",
				search_type: constants.SEARCH_TYPE_EXACT,
			};
			setMulti(true);
		} else if (filter_type === "contact") {
			end_points = endPoints.CONTACTS.CAMPAIGN_FILTER;
			params = { report_type: constants.AB_REPORT_TYPE };
			setMulti(false);
		} else if (filter_type === "account") {
			end_points = endPoints.ACCOUNTS.CAMPAIGN_FILTER;
			params = null;
			setMulti(false);
		}

		var response = Promise.resolve(HTTPClient.get(end_points, params));

		response.then((response) => {
			let options = [];
			let records;
			if (filter_type === "contact" || filter_type === "account") {
				records = response.queries;
			} else {
				records = response.records;
			}
			records.forEach(function (data) {
				let filterObject = {
					value: data.id.toString(),
					label: data.name,
				};
				if (data.hasOwnProperty("type")) {
					filterObject.type = data.type;
				}
				if (filter_type === "group") {
					filterObject.title = data.name;
				}
				options.push(filterObject);
			});

			options.sort(function (option1, option2) {
				let opt1 = option1.label.toLowerCase();
				let opt2 = option2.label.toLowerCase();
				if (opt1 < opt2) return -1;
				if (opt1 > opt2) return 1;
				return 0;
			});

			if (filter_type === "contact" || filter_type === "account") {
			}
			setFilter_options(options);
			let temp = loaded_filter_options;
			temp[filter_type].loaded = true;
			temp[filter_type].options = options;
			setLoaded_filter_options(temp);
			setFilter_type(filter_type);
			setOptions_loading(false);
			checkAndResetSelectedFilterOptions(filter_type);
			if (
				Array.isArray(changeLog.selected_filter_options) &&
				changeLog.selected_filter_options.length > 0
			) {
				reGenerateFilterOptions(changeLog.selected_filter_options);
			}
		});
	};

	const validateForm = () => {
		let isValid = true;
		let tempError = error;
		if (campaign.campaign_name.trim() === "") {
			isValid = false;
			tempError["campaign_name"] =
				getLocalizedStrings().message.AB_CAMPAIGNS.NAME_BLANK;
			setError({...tempError});

		}
		var reg = /^\d+$/;
		let invalid_ab_distribution_err = false;
		if (
			campaign.distribution_A.toString().trim() === "" ||
			campaign.distribution_B.toString().trim() === ""
		) {
			isValid = false;
			tempError["distribution_AB"] =
				getLocalizedStrings().message.AB_CAMPAIGNS.AB_DISTRIBUTION;
			setError({...tempError});

			invalid_ab_distribution_err = true;
		} else if (
			(campaign.distribution_A !== "" && !reg.test(campaign.distribution_A)) ||
			(campaign.distribution_B !== "" && !reg.test(campaign.distribution_B))
		) {
			isValid = false;
			tempError["distribution_AB"] =
				getLocalizedStrings().message.AB_CAMPAIGNS.INVALID_AB_DISTRIBUTION;
			setError({...tempError});

			invalid_ab_distribution_err = true;
		}

		if (
			!invalid_ab_distribution_err &&
			!(campaign.distribution_A >= 1 && campaign.distribution_A <= 99)
		) {
			isValid = false;
			tempError["distribution_AB"] =
				getLocalizedStrings().message.AB_CAMPAIGNS
					.AB_DISTRIBUTION_OUT_OF_RANGE + "A";
			setError({...tempError});

		} else if (
			!invalid_ab_distribution_err &&
			!(campaign.distribution_B >= 1 && campaign.distribution_B <= 99)
		) {
			isValid = false;
			tempError["distribution_AB"] =
				getLocalizedStrings().message.AB_CAMPAIGNS
					.AB_DISTRIBUTION_OUT_OF_RANGE + "B";
			setError({...tempError});

		}

		if (campaign.margin !== "" && !reg.test(campaign.margin)) {
			isValid = false;
			tempError["margin"] =
				getLocalizedStrings().message.AB_CAMPAIGNS.INVALID_WINNING_MARGIN;
			setError({...tempError});

		}

		if (campaign.margin === 0 && campaign.send_remaining === "Yes") {
			isValid = false;
			tempError["margin"] =
				getLocalizedStrings().message.AB_CAMPAIGNS.ZERO_WINNING_MARGIN;
			setError({...tempError});

		}
		if (
			campaign.id === 0 &&
			!both_tab_filled &&
			type === "new"
		) {
			isValid = false;
			tempError["both_tab_filled"] =
				getLocalizedStrings().message.AB_CAMPAIGNS.MESSAGE_B_NOT_FILLED;
			setError({...tempError});

		}

		if (campaign.message_details.a.sender_name.trim() === "") {
			isValid = false;
			tempError.message_details.a["sender_name"] =
				getLocalizedStrings().message.AB_CAMPAIGNS.FROM_NAME_BLANK;
			setError({...tempError});
			setSelected_tab("a")

		}
		if (campaign.message_details.a.subject.trim() === "") {
			isValid = false;
			tempError.message_details.a["subject"] =
				getLocalizedStrings().message.AB_CAMPAIGNS.SUBJECT_BLANK;
			setError({...tempError});
			setSelected_tab("a")
		}
		if (campaign.message_details.b.sender_name.trim() === "") {
			isValid = false;
			tempError.message_details.b["sender_name"] =
				getLocalizedStrings().message.AB_CAMPAIGNS.FROM_NAME_BLANK;
			setError({...tempError});
			setSelected_tab("b")
		}
		if (campaign.message_details.b.subject.trim() === "") {
			isValid = false;
			tempError.message_details.b["subject"] =
				getLocalizedStrings().message.AB_CAMPAIGNS.SUBJECT_BLANK;
			setError({...tempError});
			setSelected_tab("b")
		}

		if (
			campaign.message_details.a.sender_email === "-1" ||
			campaign.message_details.a.sender_email === "" ||
			campaign.message_details.a.sender_email === undefined
		) {
			isValid = false;
			tempError.message_details.a["sender_email"] =
				getLocalizedStrings().message.AB_CAMPAIGNS.FROM_EMAIL_BLANK;
			setError({...tempError});
			setSelected_tab("a")
		}

		if (
			campaign.message_details.b.sender_email === "-1" ||
			campaign.message_details.b.sender_email === "" ||
			campaign.message_details.b.sender_email === undefined
		) {
			isValid = false;
			tempError.message_details.b["sender_email"] =
				getLocalizedStrings().message.AB_CAMPAIGNS.FROM_EMAIL_BLANK;
			setError({...tempError});
			setSelected_tab("b")
		}

		let msg = {
			group: getLocalizedStrings().message.AB_CAMPAIGNS.GROUP_FILTER_BLANK,
			contact: getLocalizedStrings().message.AB_CAMPAIGNS.CONTACT_FILTER_BLANK,
			account: getLocalizedStrings().message.AB_CAMPAIGNS.ACCOUNT_FILTER_BLANK,
		};

		if (
			changeLog.selected_filter_options === "" ||
			(Array.isArray(changeLog.selected_filter_options) &&
				changeLog.selected_filter_options.length === 0)
		) {
			isValid = false;

			tempError["filter_options"] = msg[filter_type];
			setError({...tempError});
		} else if (
			typeof changeLog.selected_filter_options === "object" &&
			changeLog.selected_filter_options.value === -1
		) {
			tempError["filter_options"] = msg[filter_type];
			setError({...tempError});

		}

		if (campaign.send_remaining === "Yes" && isValid) {
			if (
				campaign.send_after.toString().trim() === "" ||
				campaign.send_after === 0
			) {
				isValid = false;
				tempError["send_after"] =
					getLocalizedStrings().message.AB_CAMPAIGNS.DAYS_BLANK;
				setError({...tempError});
			} else if (!reg.test(campaign.send_after)) {
				isValid = false;
				tempError["send_after"] =
					getLocalizedStrings().message.AB_CAMPAIGNS.INVALID_DAYS;
				setError({...tempError});
			}
			if (tempError.send_after !== "") {
				let msg_send_after = tempError.send_after;
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					msg_send_after,
					sfAlwrtCallBack(send_after)
				);
				tempError["send_after"] = "";
				tempError["both_tab_filled"]= "";
				tempError["distribution_AB"] = "";
				setError({...tempError});
			}
		}
		return isValid;
	}
	const sfAlwrtCallBack = (data) => {
		if (data !== null) {
			data.focus();
		}
	};
	const saveCampaign = () => {
		
		if (!validateForm()) {
			return false;
		}
		let tempCampaign = campaign;
		let hasPermission = false;
		if (tempCampaign.id > 0) {
			hasPermission = hasAccessPermission(
				constants.AB_CAMPAIGNS_OBJECT,
				constants.SECURITY_LEVEL_TYPE_ACCESS,
				constants.ACCESS_TYPE_EDIT
			);
		} else {
			hasPermission = true;
		}
		if (hasPermission) {
			let campaignName = tempCampaign.campaign_name.trim();
			tempCampaign.campaign_name = campaignName;
			let margin = tempCampaign.margin === "" ? 0 : tempCampaign.margin;
			tempCampaign.margin = margin;
			let campType = 0;
			if (
				isValidParam(tempCampaign.campaign_type) &&
				tempCampaign.campaign_type !== ""
			) {
				campType = tempCampaign.campaign_type;
			}
			let params = {
				id: tempCampaign.id,
				campaign_details: {
					t_campaign_name: campaignName,
					t_target_filter: tempCampaign.campaign_targets,
					distribution: [
						{
							distribution_A: tempCampaign.distribution_A,
						},
						{
							distribution_B: tempCampaign.distribution_B,
						},
					],
					t_winner_by: [tempCampaign.winner_by],
					t_margin: margin,
					t_send_remaining: tempCampaign.send_remaining,
					t_send_after: tempCampaign.send_after,
				},
				message_details: [
					tempCampaign.message_details.a,
					tempCampaign.message_details.b,
				],
				campaign_type: campType,
			};

			let response = Promise.resolve(
				HTTPClient.post(endPoints.AB_CAMPAIGNS.SAVE, params)
			);
			setMounted(false);
			let campaign_id = tempCampaign.id;
			response.then((response) => {
				
				if (response.status === 0) {
					if (params.id === 0) {
						let temp = campaign;
						temp.id = response.data.id[0];
						setButtonVisible("none");
						temp["t_status"] = "New";
						temp.message_details.a.template_id =
							response.data.message_details[0].template_id;
						temp.message_details.a.campaign_msg_id =
							response.data.message_details[0].campaign_msg_id;
						temp.message_details.b.template_id =
							response.data.message_details[1].template_id;
						temp.message_details.b.campaign_msg_id =
							response.data.message_details[1].campaign_msg_id;
						setCampaign({...temp});
					}
					if (campaign_id === 0) {
						dispatch(showCustomSnackBar(
							getLocalizedStrings().message.AB_CAMPAIGNS.SAVE,
							styles.snackbarBodyStyleSuccess,
							styles.snackBarStyleTop
						));
					} else if (campaign_id > 0) {
						dispatch(showCustomSnackBar(
							getLocalizedStrings().message.AB_CAMPAIGNS.UPDATE,
							styles.snackbarBodyStyleSuccess,
							styles.snackBarStyleTop
						));
					}
					setMounted(true);
					setButtonVisible("inline-block");
					refreshListView(constants.AB_CAMPAIGNS_OBJECT);
					dispatch(getAppDrawer(false, null, null, null, null));
				} else if (response.status === -1 && response.error.message !== "") {
					dispatch(showCustomSnackBar(
						response.error.message,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleTop
					));
					setMounted(true);
					setButtonVisible("none");
				}
			});
		} else {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				getLocalizedStrings().message.COMMON.ACCESS_DENIED,
				null
			);
		}
	};

	const reGenerateFilterOptions = (options) => {
		let new_options = loaded_filter_options["group"].options;
		new_options = new_options.filter(
			(el) => !options.filter((els) => els.value === el.value).length
		);
		if (options.length === 3) {
			new_options = new_options.map(function (option, index) {
				return { ...option, isDisabled: true, title: option.label };
			});
		} else {
			new_options = new_options.map(function (option, index) {
				return {
					label: option.label,
					value: option.value,
					title: option.label,
				};
			});
		}

		new_options.sort(function (option1, option2) {
			let opt1 = option1.label.toLowerCase();
			let opt2 = option2.label.toLowerCase();
			if (opt1 < opt2) return -1;
			if (opt1 > opt2) return 1;
			return 0;
		});
		setFilter_options(new_options);
	};

	const handleSelectChange = (event, options) => {
		let campaign_targets = [];

		if (Array.isArray(options)) {
			options.forEach(function (data) {
				campaign_targets.push({
					id: data.value,
					name: data.label,
					type: filter_type,
				});
			});
			reGenerateFilterOptions(options);
		} else {
			campaign_targets.push({
				id: options.value,
				name: options.label,
				type: filter_type,
			});
		}
		let tempCampaign = campaign;
		tempCampaign["campaign_targets"] = campaign_targets;
		setCampaign({...tempCampaign});
		setSelected_filter_options(options);
		let log = changeLog;
		log.selected_filter_options = options;
		setchangeLog({ ...log });
		setPlaceholder(null);
		let tempError = error;
		tempError["filter_options"] = "";
		setError({...tempError});
	};

	useEffect(() => {
		let abCampId = 0;
		if (id > 0) {
			abCampId = id;
		}
		loadABCampaignData(abCampId);
		if (campaignTypes.hasOwnProperty('data') && campaignTypes.data === null ) {
			dispatch(getAllCampaignTypes());
		}
	}, [id,type,campaignTypes.data]);

	const handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		let tempCampaign = campaign;
		tempCampaign[name] = value;
		setCampaign({...tempCampaign});
		if (value.trim() !== "") {
			let tempError = error;
			tempError[name] = "";
			setError(tempError);
		}

		if (
			tempCampaign.distribution_A.toString().trim() !== "" &&
			tempCampaign.distribution_B.toString().trim() !== ""
		) {
			let tempError = error;
			tempError["distribution_AB"] = "";
			setError({...tempError});
		}
	};
	const handleChangeTab = (event, value) => {
		setSelected_tab(value);
		if (campaign.id === 0 && value === "b") {
			setBoth_tab_filled(true);
			let tempError = error;
			tempError.both_tab_filled = "";
			setError({...tempError});
		}
	};

		const testMail = () => {
			if (validateForm()) {
				let params = {};
				params.email = "";
				params.frommail =
					campaign.message_details[
						selected_tab
					].sender_email;
				params.fromname =
					campaign.message_details[
						selected_tab
					].sender_name;
				params.subject =
					campaign.message_details[selected_tab].subject;
				params.templateBody =
					campaign.message_details[
						selected_tab
					].email_message;

				if (
					campaign.message_details[selected_tab]
						.view_as_web_page === true
				) {
					params.isVawp = "1";
				} else {
					params.isVawp = "0";
				}
				params.testMailType = 'marketing';
				dispatch(getAppDialog(
					true,
					constants.TEST_MAIL_DIALOG,
					null,
					null,
					params,
					null
				));
			}
		};

	
		const changeRadioButton = ( value) => {
			if (value === "group") {
				setSelected_filter_options([])
				let log = changeLog;
                        log.selected_filter_options = [];
                        setchangeLog({ ...log });
			} else {
				setSelected_filter_options("")
				let log = changeLog;
                        log.selected_filter_options = "";
                        setchangeLog({ ...log });
			}
			setFilter_options([]);
			let tempError = error;
			tempError["filter_options"] = "";
			setError({ ...tempError });
			loadFilterOptions(value);
			if (value === "group") {
				setPlaceholder(getLocalizedStrings().label.CAMPAIGNS.SELECT_GROUP);
			} else if (value === "contact") {
				setPlaceholder(getLocalizedStrings().label.CAMPAIGNS.SELECT_A_FILTER +
					getObjectLabelByObject(constants.CONTACTS_OBJECT));

			} else if (value === "account") {
				setPlaceholder(getLocalizedStrings().label.CAMPAIGNS.SELECT_A_FILTER +
					getObjectLabelByObject(constants.ACCOUNTS_OBJECT));

			}
		};

		const handleWinnerByChange = (event, value) => {
			
			let temp = campaign;
			temp["winner_by"] = value.value;
			setCampaign({...temp});
			let tempError = error;
			tempError["winner_by"] = "";
			setError({...tempError});
		};

		const handleSendRemainingChange = (event, value) => {
			let temp = campaign;
			setCampaign({...temp,send_remaining:value});
			let tempError = error;
			tempError["send_remaining"] = "";
			setError({...tempError});

			if (value === "No") {
				tempError["margin"] = "";
				setError({...tempError});

			}
		};

		const confirmSchedule = (obj) => {
			let campType = 0;
			if (isValidParam(campaign.campaign_type) && campaign.campaign_type !== "") {
				campType = campaign.campaign_type;
			}
			let params = {
				id: campaign.id,
				campaign_details: {
					t_campaign_name: campaign.campaign_name,
					t_target_filter: campaign.campaign_targets,
					distribution: [
						{ distribution_A: campaign.distribution_A },
						{ distribution_B: campaign.distribution_B },
					],
					t_winner_by: [campaign.winner_by],
					t_margin: campaign.margin,
					t_send_remaining: campaign.send_remaining,
					t_send_after: campaign.send_after,
				},
				message_details: [campaign.message_details.a, campaign.message_details.b],
				scheduled_on: obj.schedule_date,
				t_status: "scheduled",
				campaign_type: campType,
			};
			params.message_details= [campaign.message_details.a, campaign.message_details.b];
			params.scheduled_on = obj.schedule_date;
			params.t_status = "scheduled";
			params.campaign_type = campType;
				
			var promise = Promise.resolve(
				HTTPClient.post(endPoints.AB_CAMPAIGNS.SCHEDULE, params)
			);
			promise.then((response) => {
				if (response.status === 0) {
					dispatch(getAppDialog(false,constants.CONFIRM_SCHEDULE_DIALOG,null,null,null,null));
					setDisabled(true);
					setScheduled(true);
					setScheduled_on(obj.schedule_date);
					let temp = campaign;
					temp["t_status"] = response.data.t_status;
					setCampaign({...temp});
					campaign["campaign_type"] = campaign["campaign_type"] === 0 ? "": campaign["campaign_type"];
				} else if (response.status === -1 && response.error.message !== "") {
					dispatch(showCustomSnackBar(response.error.message,styles.snackbarBodyStyleError,styles.snackBarStyleTop));
				}
			});
		};

		const schedule = (obj) => {
			let momentObj = obj.date;
			let db_date = momentObj.format("YYYY-MM-DD HH:mm:ss");
			let user_date = momentObj.format(
				dateTimeFormat[app.me.date_format]
			);

			let data = { user_date: user_date, db_date: db_date };
			dispatch(getAppDialog(
				false,
				constants.SCHEDULE_DIALOG,
				null,
				null,
				null,
				null
			));
			dispatch(getAppDialog(
				true,
				constants.CONFIRM_SCHEDULE_DIALOG,
				getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
				confirmSchedule,
				data
			));
		};

		const addGroup = (obj) => {
			let params = {
				id: 0,
				name: obj.group_name,
				description: obj.group_name,
				t_status: "Active",
			};
			var response = saveGroup(object, params);
			response.then((response) => {
				if (response.status === 0) {
					filter_options.push({
						label: obj.group_name,
						value: response.data.id,
					});
					setFilter_options(filter_options);
					reGenerateFilterOptions(changeLog.selected_filter_options);
					dispatch(getAppDialog(
						false,
						constants.ADD_GROUP_DIALOG,
						null,
						null,
						null,
						null
					));
				} else if (response.status === -1 && response.error.message !== "") {
					dispatch(showCustomSnackBar(
						response.error.message,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleTop
					));
				}
			});
		};

		const handleSchedule = () => {
			dispatch(getAppDialog(
				true,
				constants.SCHEDULE_DIALOG,
				getLocalizedStrings().label.AB_CAMPAIGNS.SCHEDULE_AB_CAMPAIGN,
				schedule,
				null,
				null
			));
		};
		const handleReschedule = () => {
			dispatch(getAppDialog(
				true,
				constants.RESCHEDULE_DIALOG,
				"Reschedule Broadcast",
				schedule,
				null,
				null
			));
		};

		const handleExecute = () => {
			if (validateForm()) {
				let msg = "";
				let msgArr = [];
				msg =
					"<div>" +
					getLocalizedStrings().message.AB_CAMPAIGNS.CAMPAIGN_EXECUTE_IN_60_MIN +
					"<br />" +
					getLocalizedStrings().message.AB_CAMPAIGNS.OK_TO_CONFIRM +
					"</div>";
				msgArr.push(msg);
				sfDialogs.confirm(
					getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
					msgArr,
					() => handleSfDialogConfirmExecute(this),
					null
				);
			}
		};

		const handleSfDialogConfirmExecute = (event) => {
			let campType = 0;
			if (isValidParam(campaign.campaign_type) && campaign.campaign_type !== "") {
				campType = campaign.campaign_type;
			}
			let params = {
				id: campaign.id,
				campaign_details: {
					t_campaign_name: campaign.campaign_name,
					t_target_filter: campaign.campaign_targets,
					distribution: [
						{ distribution_A: campaign.distribution_A },
						{ distribution_B: campaign.distribution_B },
					],
					t_winner_by: [campaign.winner_by],
					t_margin: campaign.margin,
					t_send_remaining: campaign.send_remaining,
					t_send_after: campaign.send_after,
				},
				message_details: [campaign.message_details.a, campaign.message_details.b],
				scheduled_on: "",
				t_status: "scheduled",
				campaign_type: campType,
			};
			var promise2 = Promise.resolve(
				HTTPClient.post(endPoints.AB_CAMPAIGNS.EXECUTE, params)
			);
			promise2.then((response) => {
				if (response.status === 0) {
					setDisabled(true);
					setScheduled(true);
					setScheduled_on(moment().tz(
						timeZone[app.me.timezone]));

					campaign["t_status"] = response.data.t_status;
					campaign["campaign_type"] =
						campaign["campaign_type"] === 0
							? ""
							: campaign["campaign_type"];
				} else if (response.status === -1 && response.error.message !== "") {
					dispatch(showCustomSnackBar(
						response.error.message,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleTop
					));
				}
			});
		};


		const handleCancelSchedule = () => {
			let msg = "";
			let msgArr = [];
			msg =
				"<span>" +
				getLocalizedStrings().message.AB_CAMPAIGNS.EXECUTION_CANCEL_MSG +
				"</span></br>" +
				"<span >" +
				getLocalizedStrings().message.AB_CAMPAIGNS.CONFIRM +
				"</span></br>" +
				"<span>" +
				getLocalizedStrings().message.AB_CAMPAIGNS.EXECUTION_CANNOT_CANCEL_2 +
				"</span></br>";
			msgArr.push(msg);
			sfDialogs.confirm(
				getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
				msgArr,
				() => handleSfDialogConfirmCancelSchedule(),
				null
			);
		};

		const handleSfDialogConfirmCancelSchedule = (event) => {
			let params = {
				id: campaign.id,
			};
			var promise2 = Promise.resolve(
				HTTPClient.get(endPoints.AB_CAMPAIGNS.CANCEL_QUEUED, params)
			);
			promise2.then((response) => {
				if (response.is_cancel === true) {

					setDisabled(false);
					let temp = campaign;
					temp["t_status"] = "New";
					setCampaign(temp);
					setScheduled(false);
				} else {
					sfDialogs.alert(
						getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
						getLocalizedStrings().message.AB_CAMPAIGNS.EXECUTION_CANNOT_CANCEL_1,
						null
					);
				}
			});
		};

		const changeCampaignType = (event, index) => {
			let value = event.target.value;
			try {
				if (value === undefined) {
					value = event.target.value;
				}
				if (isValidParam(value)) {
					if (value !== "Manage") {
						let tempCampaign = campaign;
					setCampaign({...tempCampaign,campaign_type:value});

					} else {
						let data = {};
					data.object = constants.AUTOMATION_DESIGNER_OBJECT;
					dispatch(
						getAppDialog(
							true,
							constants.ADD_CAMPAIGN_TYPE_DIALOG,
							getLocalizedStrings().label.CAMPAIGNS.ADD_CAMPAIGN_TYPE,
							addCampaignType,
							data,
							null
						)
					);
					}
				}
			} catch (error) {
				console.log("Error in 'ABCampaign.js -> changeCampaignType()': " + error);
			}
		};

		const addCampaignType = (obj) => {
			try {
				let camObj = {
					id: obj.id,
					name: obj.name,
				};

				let camTyps = getArrayParam(campTypelistArr);
				camTyps.push(camObj);
				addCampaignTypeIntoReduxStore(camObj);
				sortArrayObjectByProperty(camTyps, "name");
				let tempcampTypeMenuItems = getCampaignTypeMenuItems(camTyps);
				setCampTypelistArr(camTyps);
				setCampTypeMenuItems(tempcampTypeMenuItems);

			} catch (error) {
				console.log("Error in 'ABCampaign.js -> addCampaignType()': " + error);
			}
		};

		const validateFilter = () => {
			let isValid = true;
			let msg = {
				group: getLocalizedStrings().message.AB_CAMPAIGNS.GROUP_FILTER_BLANK,
				contact: getLocalizedStrings().message.AB_CAMPAIGNS.CONTACT_FILTER_BLANK,
				account: getLocalizedStrings().message.AB_CAMPAIGNS.ACCOUNT_FILTER_BLANK,
			};

			if (
				changeLog.selected_filter_options === "" ||
				(Array.isArray(changeLog.selected_filter_options) &&
					changeLog.selected_filter_options.length === 0)
			) {
				isValid = false;
				let tempError = error;
				tempError["filter_options"] = msg[filter_type];
				setError(tempError);

			} else if (
				typeof changeLog.selected_filter_options === "object" &&
				changeLog.selected_filter_options.value === -1
			) {
				let tempError = error;
				tempError["filter_options"] = msg[filter_type];
				setError(tempError);
			}
			return isValid;
		};

		const handleQualifiedContacts = () => {
			let label =
				getLocalizedStrings().label.BROADCAST_CAMPAIGNS.QUALIFIED_CONTACTS;
			try {
				if (validateFilter()) {
					let url = "/" + constants.CONTACTS_OBJECT + "/queries";
					openListTab(label, url);
				}
			} catch (error) {
				console.error(
					"Error in 'ABCampaign.js -> handleQualifiedContacts()':" + error
				);
			}
		};

		const openListTab = (label, url) => {
			try {
				let activeTab = getObjectParam(getActiveTab());
				let activeTabIndex = getIntParam(activeTab.index);
				let filter = {
					id: 542,
					name: "All records",
					type: constants.SEARCH_QUERY_TYPE_ALL,
				};
				var tab = {
					label: getStringParam(label),
					object: constants.CONTACTS_OBJECT,
					type: TYPE_LIST_VIEW,
					imgName: null,
					url: url,
					isActive: true,
					parentTabIndex: activeTabIndex,
				};
				let info = {
					isReport: true,
					reportLabel: label,
					filter: filter,
					input_param: {
						call_from: constants.CAMPAIGN_QUALIFIED_CONTACTS,
						filter_options: changeLog.selected_filter_options,
						filter_type: getStringParam(filter_type),
					},
				};
				tab.info = info;
				addTab(tab, true);
				dispatch(getAppCustomDrawer(false, null, null, null, null));
			} catch (error) {
				console.error("Error in ABCampaign.js -> openListTab()':" + error);
			}
		};

		const closeActionDrawer = () => {
			dispatch(getAppCustomDrawer(false, null, null, null, null, null, null));
		};
		let pageHeight = window.innerHeight - 247;
		let top = (pageHeight - 10) / 2;
		if (!mounted) {
			return (
				<div style={{ width: "100%", height: pageHeight }}>
					<div
						className="asset-loaderh"
						style={{ paddingTop: top, paddingLeft: "45%" }}
					>
						<div
							style={{
								...styles.assetLoaderContainer,
								height: 50,
								width: 50,
								padding: 7,
							}}
						>
							<ShowCircularProgress
								size={30}
								style={{ marginTop: "3", marginLeft: "3" }}
							/>
						</div>
					</div>
				</div>
			);
		}
		
		let campaignName = getStringParam(campaign.campaign_name);

		return (
			<div style={{ paddingLeft: "26px", paddingRight: "26px" }}>
				<>
					<div>
						<div
							className="col-sm-12"
							style={{
								fontSize: "12px",
								textAlign: "left",
								color: "#717171",
								paddingLeft: "0px",
								paddingBottom: "5px",
							}}
						>
							<span>
								{new Intl.NumberFormat().format(
									email_credit_balance
								)}{" "}
								{
									getLocalizedStrings().message.BROADCAST_CAMPAIGNS
										.CREDITS_REMAINING
								}{" "}
							</span>
						</div>
						<div className="row clearfix" style={{ paddingBottom: "20px" }}>
							<div className="col-sm-12" style={stickyStyle}>
								<div style={{ float: "right" }}>
									{campaign.t_status !== "Queued" &&
										campaign.t_status !== "Executed" &&
										campaign.t_status !== "Running" &&
										campaign.t_status !== "Halted" &&
										campaign.t_status !== "Executed - No Winner" &&
										campaign.t_status !== "Sent to Winner" &&
										campaign.t_status !== "Sending to Winner" && (
											<Button
											onClick={() => saveCampaign()}
												style={styles.primaryButton}
												labelstyle={{ fontWeight: "normal" }}
											>
												{getLocalizedStrings().label.COMMON.SAVE}
											</Button>
										)}
									{campaign.t_status === "Queued" && (
										<Button
											style={styles.primaryButton}
											onClick={() => handleCancelSchedule()}
											labelstyle={{ fontWeight: "normal" }}
										>
											{getLocalizedStrings().label.COMMON.CANCEL}
										</Button>
									)}

									{campaign.t_status !== null &&
										campaign.t_status !== "Queued" &&
										campaign.t_status !== "Executed" &&
										campaign.t_status !== "Running" &&
										campaign.t_status !== "Halted" &&
										campaign.t_status !== "Executed - No Winner" &&
										campaign.t_status !== "Sent to Winner" &&
										campaign.t_status !== "Sending to Winner" && (
											<Button
											onClick={() => handleSchedule()}
												style={styles.secondaryButton}
												labelstyle={{ fontWeight: "normal" }}
											>
												{" "}
												{getLocalizedStrings().label.CAMPAIGNS.SCHEDULE}
											</Button>
										)}
									{campaign.t_status !== null &&
										campaign.t_status !== "Queued" &&
										campaign.t_status !== "Executed" &&
										campaign.t_status !== "Running" &&
										campaign.t_status !== "Halted" &&
										campaign.t_status !== "Executed - No Winner" &&
										campaign.t_status !== "Sent to Winner" &&
										campaign.t_status !== "Sending to Winner" && (
											<Button
											onClick={() => handleExecute()}
												style={styles.secondaryButton}
												labelstyle={{ fontWeight: "normal" }}
											>
												{getLocalizedStrings().label.CAMPAIGNS.EXECUTE_NOW}
											</Button>
										)}
									{campaign.t_status === "Executed" ||
										campaign.t_status === "Running" ||
										campaign.t_status === "Executed - No Winner" ||
										campaign.t_status === "Sent to Winner" ||
										campaign.t_status === "Sending to Winner" ||
										campaign.t_status === "Halted" ? (
										<Button
										onClick={() => handleCopy()}
											style={{
												...styles.primaryButton,lineHeight:'17px',
												display: buttonVisible,
											}}
											labelstyle={{ fontWeight: "normal" }}
										>
											{" "}
											{getLocalizedStrings().label.COMMON.COPY}
										</Button>
									) : (
										<Button
										onClick={() => handleCopy()}
											style={{
												...styles.secondaryButton,lineHeight:'17px',
												display: buttonVisible,
											}}
											labelstyle={{ fontWeight: "normal" }}
										>
											{" "}
											{getLocalizedStrings().label.COMMON.COPY}
										</Button>
									)}
									<Button
										onClick={() => testMail()}
										style={{
											...styles.secondaryButton,
											display: buttonVisible,lineHeight:'17px',
										}}
										labelstyle={{ fontWeight: "normal" }}
									>
										{getLocalizedStrings().label.CAMPAIGNS.TEST}
									</Button>
								</div>
								<div>
									{scheduled &&
										campaign.t_status !== "Executed" &&
										campaign.t_status !== "Halted" &&
										campaign.t_status !== "Executed - No Winner" &&
										campaign.t_status !== "Sent to Winner" &&
										campaign.t_status !== "Sending to Winner" &&
										campaign.t_status !== "Running" && (
											<span
												style={{
													fontSize: "12px",
													textAlign: "left",
													color: "#228B22",
													fontWeight: "bold",
													marginLeft:'-16px'
												}}
											>
												{getLocalizedStrings().label.CAMPAIGNS.SCHEDULED_FOR}
												&nbsp;
												{moment(scheduled_on).format(
													dateTimeFormat[app.me.date_format]
												)}
											</span>
										)}
									{(campaign.t_status === "Executed" ||
										campaign.t_status === "Executed - No Winner" ||
										campaign.t_status === "Sent to Winner") && (
											<span
												style={{
													fontSize: "12px",
													textAlign: "left",
													color: "#228B22",
													fontWeight: "bold",
													marginLeft:'-16px'
												}}
											>
												{getLocalizedStrings().label.CAMPAIGNS.EXECUTED_ON}&nbsp;
												{moment(scheduled_on).format(
													dateTimeFormat[app.me.date_format]
												)}
											</span>
										)}
									{(campaign.t_status === "Running" ||
										campaign.t_status === "Sending to Winner") && (
											<span
												style={{
													fontSize: "12px",
													textAlign: "left",
													color: "#228B22",
													fontWeight: "bold",
													paddingBttom: "5px",
												}}
											>
												{getLocalizedStrings().label.CAMPAIGNS.EXECUTING}
											</span>
										)}
									{campaign.t_status === "Halted" && (
										<span
											style={{
												fontSize: "12px",
												textAlign: "left",
												color: "#228B22",
												fontWeight: "bold",
												paddingBttom: "5px",
											}}
										>
											{getLocalizedStrings().label.CAMPAIGNS.ABORTED}
										</span>
									)}
								</div>
							</div>
						</div>
						{campaign.id > 0 &&
							(result || resultExecuteStart) && (
								<div style={{ ...styles.row, paddingBottom: "20px" }}>
									<div style={{ ...styles.sf_12 }}>
										<Results
											gblStatIdSubArr={gblStatIdSubArr}
											campaignId={campaign.id}
											campaignName={campaignName}
											setMarketingResults={() => setMarketingResults()}
											subject={{
												a: campaign.message_details.a.subject,
												b: campaign.message_details.b.subject,
											}}
											resultContent={resultContent}
											mounted={result}
										/>
									</div>
								</div>
							)}
					</div>
				</>

				<div>
					<div style={{}}>
						<div style={{ ...styles.sf_12 }}>
							<FormControl
								style={{ width: "100%" }}
								className="test"
								noValidate
								autoComplete="off"
							>
								<TextField
									variant="outlined"
									label={getLocalizedStrings().label.CAMPAIGNS.NAME_REQUIRED}
									fullWidth={true}
									name="campaign_name"
									value={campaign.campaign_name}
									onChange={(event) => handleChange(event)}
									error={error.campaign_name !== ""}
									helperText={error.campaign_name}
									disabled={disabled}
									autoFocus={campaign.id === 0 ? true : false}
									maxLength="255"
									autoComplete="new-password"
									margin="dense"
									size='small'
								/>
							</FormControl>
						</div>
					</div>
					<div style={{ display: "flex", marginTop: "14px" }}>
						<div style={{ width: "31%" }}>
							<FormControl variant="outlined" style={{ width: "100%" }}>
								<InputLabel
									id="sf-broadcastcampaign-simple-select-outlined-label"
									className="sf-broadcastcampaign"
									style={{ marginTop: "-5px" }}
								>
									{getLocalizedStrings().label.CAMPAIGNS.CAMPAIGN_TYPE_OPTIONAL}
								</InputLabel>
								<SelectField
									id="campaignType"
									value={campaign.campaign_type}
									onChange={(event, index) =>
										changeCampaignType(event, index)
									}
									className="asf"
									style={{ height: "44px" }}
									label={
										getLocalizedStrings().label.CAMPAIGNS.CAMPAIGN_TYPE_OPTIONAL
									}
									disabled={disabled}
								>
									<MenuItem value="" title="" style={styles.popoverMenuItem}>
										{""}
									</MenuItem>
									{campTypeMenuItems}
									<MenuItem
										value="Manage"
										title={getLocalizedStrings().label.CAMPAIGNS.MANAGE}
										style={styles.popoverMenuItem}
									>
										{"---" +
											getLocalizedStrings().label.CAMPAIGNS.MANAGE +
											"---"}
									</MenuItem>
								</SelectField>
							</FormControl>
						</div>
						<div style={{ width: "32%" }}>
								<div>
									<div
										className
										style={{
											...styles.customInputHolder,
											height: "auto",
											marginLeft: "12px",
										}}
									>
										<label htmlFor="tophoneno" style={styles.inputLabel}>
											{getLocalizedStrings().label.AB_CAMPAIGNS.TARGET_BY}
										</label>
										<div>
											<RadioGroup row className="sf-data-fieldset" name="layout" onChange={(event) => changeRadioButton(event.target.value)} value={filter_type}>
												<FormControlLabel value="group" control={<Radio disabled={disabled} color="default" />} label={getLocalizedStrings().label.CAMPAIGNS.GROUP} title={getLocalizedStrings().label.CAMPAIGNS.GROUP} />
												<FormControlLabel value="contact" control={<Radio disabled={disabled} color="default" />} label={getObjectLabelByObject(constants.CONTACTS_OBJECT)} title={getObjectLabelByObject(constants.CONTACTS_OBJECT)} />
												<FormControlLabel value="account" control={<Radio disabled={disabled} color="default" />} label={getObjectLabelByObject(constants.ACCOUNTS_OBJECT)} title={getObjectLabelByObject(constants.ACCOUNTS_OBJECT)} />
											</RadioGroup>
										</div>
									</div>
								</div>
							</div>
						<div style={{ marginLeft: "14px", width: "32%",marginRight:'14px' }}>
							<Autocomplete
								multiple={filter_type === "group"}
								id={"autocomplete_group"}
								style={{ height: "fit-content" ,marginLeft:'10px'}}
								name={"autocomplete_group"}
								key={"autocomplete_group"}
								className={"sf-atc-autoComplete"}
								value={changeLog.selected_filter_options}
								getOptionDisabled={(option) => option.isDisabled === true}
								options={filter_options}
								onChange={(event, options) =>
									handleSelectChange(event, options)
								}
								disableClearable={true}
								disabled={disabled}
								renderTags={(value, getTagProps) =>
									value.map((option, index) => (
										<Chip 
											style={{...styles.autocompleteChip, width: '26.5%'}}
											label={option.label}
											title={option.label}
											{...getTagProps({ index })}
										/>
									))
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label={ getLocalizedStrings().label.CAMPAIGNS.SELECT_GROUP}
										variant="outlined"
										margin="dense"
										size = 'small'
										className={"sf-atc-autoComplete-text-field"}
									/>
								)}
								
							/>
							<span style={{ fontSize: "12px", color: "#FF0000" }}>
								{error.filter_options}
							</span>
						</div>
						{campaign.t_status !== "Executed - No Winner" &&
							campaign.t_status !== "Sent to Winner" &&
							campaign.t_status !== "Sending to Winner" && (
								<div style={{  }}>
									<i
										className="fa fa-eye"
										name={"qualifiedcontacts"}
										onClick={() => handleQualifiedContacts()}
										title={
											getLocalizedStrings().label.BROADCAST_CAMPAIGNS
												.QUALIFIED_CONTACTS
										}
										style={{
											marginTop: "3px",
											marginLeft: "3px",
											fontSize: "30px",
											color: "#a8a0a0",
											cursor: "pointer",
										}}
									></i>
								</div>
							)}
					</div>
					<div>
						<div style={{ width: "50%", float: "left" }}>
							<div
								style={{
									...styles.sf_6,
									color: "rgb(113, 113, 113)",
									float: "left",
								}}
							>
								<div>
									<div style={{ ...styles.sf_12, whiteSpace: "nowrap" }}>
										{
											getLocalizedStrings().label.AB_CAMPAIGNS
												.AB_TEST_DISTRIBUTION
										}
									</div>
								</div>
								<div>
									<div style={{ width: "40%", float: "left" }}>
										<FormControl
											style={{ width: "100%" }}
											className="test"
											noValidate
											autoComplete="off"
										>
											<TextField
												variant="outlined"
												label={
													getLocalizedStrings().label.AB_CAMPAIGNS.A_PERCENT
												}
												fullWidth={true}
												name="distribution_A"
												value={campaign.distribution_A}
												onChange={(event) => handleChange(event)}
												error={error.distribution_A !== ""}
												helperText={error.distribution_A}
												disabled={disabled}
												maxLength="4"
												autoComplete="new-password"
												margin="dense"
												size='small'
											/>
										</FormControl>
									</div>
									<div
										style={{ width: "40%", float: "left", marginLeft: "15px" }}
									>
										<FormControl
											style={{ width: "100%" }}
											className="test"
											noValidate
											autoComplete="off"
										>
											<TextField
												variant="outlined"
												label={
													getLocalizedStrings().label.AB_CAMPAIGNS.B_PERCENT
												}
												fullWidth={true}
												name="distribution_B"
												value={campaign.distribution_B}
												onChange={(event) => handleChange(event)}
												error={error.distribution_B !== ""}
												helperText={error.distribution_B}
												disabled={disabled}
												maxLength="4"
												autoComplete="new-password"
												margin="dense"
												size='small'
											/>
										</FormControl>
									</div>
								</div>
								<div style={{ }}>
									<div style={{ ...styles.sf_12 }}>
										<span
											style={{
												fontSize: "12px",
												color: "#F44336",
												whiteSpace: "nowrap",
											}}
										>
											{error.distribution_AB}
										</span>
									</div>
								</div>
							</div>
							<div style={{ ...styles.sf_6, float: "left" }}>
								<div style={{ marginTop: "20px", marginLeft: "-23px" }}>
									<FormControl
										style={{ width: "100%" }}
										className="test"
										noValidate
										autoComplete="off"
									>
										<Autocomplete
											name="winner_by"
											value={winner_by_options.find(
												(e) => e.value === campaign.winner_by
											)}
											options={winner_by_options}
											onChange={(event, options) =>
												handleWinnerByChange(event, options)
											}
											
											disableClearable={true}
											disabled={disabled}
											className="broadFromEmail"
											style={{ marginBottom: "0px" }}
											renderInput={(params) => (
												<TextField
													{...params}
													label={ getLocalizedStrings().label.COMMON.WINNER_BY}
													variant="outlined"
													margin="dense"
													size='small'
													className={"sf-fields-bg"}
												/>
											)}
										/>
									</FormControl>
								</div>
							</div>
						</div>
						<div style={{ width: "50%", float: "left" }}>
							<div>
								<div style={{ ...styles.sf_4, float: "left" }}>
									<div
										style={{
											marginTop: "20px",
											marginLeft: "15px",
											width: "100%",
										}}
									>
										<div style={{ ...styles.sf_12 }}>
											<FormControl
												style={{ width: "102%" }}
												className="test"
												noValidate
												autoComplete="off"
											>
												<TextField
													variant="outlined"
													label={
														getLocalizedStrings().label.AB_CAMPAIGNS
															.MIN_MARGIN_PERCENT
													}
													fullWidth={true}
													name="margin"
													value={campaign.margin}
													onChange={(event) => handleChange(event)}
													error={error.margin !== ""}
													helperText={error.margin}
													disabled={disabled}
													maxLength="2"
													className={"sf-fields-bg"}
													margin="dense"
													size='small'
													autoComplete="new-password"
												/>
											</FormControl>
										</div>
									</div>
									<div>
										<div style={{ ...styles.sf_12, float: "left" }}>
											<span style={{ fontSize: "12px", color: "#F44336" }}>
												{error.winning_margin}
											</span>
										</div>
									</div>
								</div>
								<div style={{ ...styles.sf_8, float: "left" }}>
									<div style={{ marginTop: "20px",marginLeft:'14px' }}>
										<div style={{ width: "91.5%", marginLeft:20 }}>
											<div
												style={{
													color: "#999999",
													backgroundColor: "#fff",
													position: "absolute",
													top: "2px",
													zIndex: "1",
													left: "25px",
													fontSize: "11px",
													textOverflow: "ellipsis",
													overflow: "hidden",
													whiteSpace: "nowrap",
													maxWidth: "75%",
												}}
											></div>

											<FormControl
												style={{ width: "100%" }}
												className="test"
												noValidate
												autoComplete="off"
											>
												<Autocomplete
													name="send_remaining"
													value={send_remaining_options.find(
														(v) =>
															v.value === campaign.send_remaining
													)}
													options={send_remaining_options}
													onInputChange={(e,Value)=>handleSendRemainingChange(e,Value)}
													
													getOptionLabel={(option) => option.label}
													
													disableClearable={true}
													disabled={disabled}
													className="broadFromEmail"
													style={{ marginBottom: "0px" }}
													renderInput={(params) => (
														<TextField
															{...params}
															label={ getLocalizedStrings().label.COMMON.SEND_THE_REST}
															variant="outlined"
															margin="dense"
															size='small'
															className={"sf-fields-bg"}
														/>
													)}
												/>
											</FormControl>
										</div>

										<div style={{ ...styles.sf_6 }}>
											<div className="row">
												<div style={{ ...styles.sf_8 }}>
													{campaign.send_remaining === "Yes" && 
													<FormControl
														style={{ width: "100%" }}
														className="test"
														noValidate
														autoComplete="off"
													>
														<TextField
															variant="outlined"
															label={
																getLocalizedStrings().label.CAMPAIGNS.DAYS
															}
															fullWidth={true}
															name="send_after"
															value={campaign.send_after}
															onChange={(event) => handleChange(event)}
															disabled={disabled}
															maxLength={3}
														
															autoComplete="new-password"
															margin="dense"
															size='small'
														/>
													</FormControl>
													}
												</div>
											</div>
										</div>
										<div style={{ ...styles.sf_2 }}></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div style={{ ...styles.sf_12, paddingTop: "10px", float: "left" }}>
						<span style={{ fontSize: "12px", color: "#F44336" }}>
							{error.both_tab_filled}
						</span>
						<Paper square >
							<Tabs
								value={selected_tab}
								onChange={(event, value) => handleChangeTab(event, value)}
								variant="fullWidth"
								indicatorColor="primary"
								textColor="primary"
								aria-label="icon tabs example"
							>
								<Tab
									label={getLocalizedStrings().label.AB_CAMPAIGNS.MESSAGE_A}
									aria-label={
										getLocalizedStrings().label.AB_CAMPAIGNS.MESSAGE_A
									}
									value="a"
									className="abtabtn"
								/>
								<Tab
									label={getLocalizedStrings().label.AB_CAMPAIGNS.MESSAGE_B}
									aria-label={
										getLocalizedStrings().label.AB_CAMPAIGNS.MESSAGE_B
									}
									value="b"
									className="abtabtn"
								/>
							</Tabs>
						</Paper>
						<div style={{ marginTop: "10px" }}>
							{selected_tab === "a" && 
								<MessageDetails
									email_ids={verifid_email_ids}
									t_status={campaign.t_status}
									disabled={disabled}
									messages_details={campaign.message_details.a}
									updateMessageDetails={(obj, callFrom)=>updateMessageDetails(obj, callFrom)}
									errors={JSON.stringify(error.message_details.a)}
								/>
							}
							{selected_tab === "b" && 
								<MessageDetails
									email_ids={verifid_email_ids}
									t_status={campaign.t_status}
									disabled={disabled}
									messages_details={campaign.message_details.b}
									updateMessageDetails={(obj, callFrom)=>updateMessageDetails(obj, callFrom)}
									errors={JSON.stringify(error.message_details.b)}
								/>
							}
						</div>
					</div>
				</div>
			</div>
		);

	}

const Results = ({ gblStatIdSubArr,campaignId, campaignName,setMarketingResults,subject,resultContent,mounted}) => {
	const dispatch = useDispatch();
	const app = useSelector((state) => state.app);
	const sfForm = useSelector((state) => state.sfForm);
	
	const getPercentage = (value, sent_value) => {
		let percentage_value = 0;
		if (sent_value !== 0) {
			percentage_value = (value / sent_value) * 100;
			if (Math.round(percentage_value) !== percentage_value) {
				percentage_value = percentage_value.toFixed(2);
			}
			return percentage_value + "%";
		}
	};

	const drilldownMarketingReport = (statsId, eventType, reportType) => {
		try {
			let params = {
				name: "All records",
				tableId: 9,
			};
			var promise = Promise.resolve(
				HTTPClient.get(endPoints.REPORTS.QUERY_DETAIL, params)
			);
			promise.then((response) => {
				if (isValidParam(response)) {
					let label = getStringParam(campaignName);
					if (eventType === "click") {
						let reportName =
							gblStatIdSubArr.length > 0
								? gblStatIdSubArr[statsId]
								: null;
						let eventTypeText = getLocalizedStrings().label.CAMPAIGNS.CLICK_URL;
						label = eventTypeText + " " + reportName;
					} else {
						let eventTypeText =
							eventType.charAt(0).toUpperCase() + eventType.slice(1);
						if (reportType === constants.BROADCAST_REPORT_TYPE) {
							label =
								eventTypeText +
								" " +
								getLocalizedStrings().label.BROADCAST_CAMPAIGNS
									.EMAIL_CAMPAIGN_REPORT;
						} else if (reportType === constants.AUTO_RESPONDER_REPORT_TYPE) {
							label =
								eventTypeText +
								" " +
								getLocalizedStrings().label.AUTOMATION_DESIGNER
									.TOUCH_CAMPAIGN_REPORT;
						} else if (reportType === constants.AB_REPORT_TYPE) {
							let eventTypeTextUpper = eventTypeText.toUpperCase();
							eventTypeText =
								getLocalizedStrings().label.CAMPAIGNS.hasOwnProperty(
									eventTypeTextUpper
								)
									? getLocalizedStrings().label.CAMPAIGNS[eventTypeTextUpper]
									: eventTypeText;
							label =
								eventTypeText +
								" " +
								getLocalizedStrings().label.AB_CAMPAIGNS.AB_CAMPAIGN_REPORT;
						}
					}
					let url =
						"/" +
						constants.CONTACTS_OBJECT +
						"/" +
						constants.AB_REPORT_TYPE.toLocaleLowerCase();
					openTab(
						label,
						constants.CONTACTS_OBJECT,
						response.query_id,
						"fa fa-bullhorn",
						url,
						statsId,
						eventType
					);
				}
			});
		} catch (error) {
			console.log(
				"Error in 'ABCampaign.js -> drilldownMarketingReport()':" + error
			);
		}
	};

	const openTab = (label, component, queryId, imgName, url, statsId, eventType) => {
		let filter = {
			id: queryId,
			name: "",
			type: constants.TYPE_AUTORESPONDER_REPORT,
		};
		let tabType = TYPE_LIST_VIEW;

		let arrMarketingResults = resultContent.marketing_results;
		arrMarketingResults = arrMarketingResults.filter(function (el) {
			return el.id === statsId;
		});
		arrMarketingResults = arrMarketingResults[0];
		let tab = {
			label: label,
			object: component,
			type: tabType,
			imgName: imgName,
			info: {
				filter: filter,
				input_param: {
					stat_id: statsId,
					event_type: eventType,
				},
				isReport: true,
				reportType: constants.AB_REPORT_TYPE,
				event_type_key: EVENT_TYPE_KEYS[eventType],
				campaignId: campaignId,
				reportFieldValue: getStringParam(eventType),
				reportLabel: getStringParam(campaignName),
				recordCount: arrMarketingResults[EVENT_TYPE_KEYS[eventType]],
			},
			url: url,
			isActive: true,
		};
		dispatch(setMarketingResults(resultContent.marketing_results));
		addTab(tab, true);
		dispatch(getAppCustomDrawer(false, null, null, null, null, null, null));
	};

	const getResultsContent = (getPercentage, drilldownMarketingReport) => {
		let defaultMessageNameMultiLingual = {
			"Message A": getLocalizedStrings().label.AB_CAMPAIGNS.MESSAGE_A,
			"Message B": getLocalizedStrings().label.AB_CAMPAIGNS.MESSAGE_B,
			"Winner (based on Message A)":
				getLocalizedStrings().label.AB_CAMPAIGNS.WINNER_BASEDON_A,
			"Winner (based on Message B)":
				getLocalizedStrings().label.AB_CAMPAIGNS.WINNER_BASEDON_B,
		};
		return resultContent.marketing_results.map(function (
			object,
			key
		) {
			let marginTop = "-10px";
			if (object.t_msg_name === "Message B") {
				marginTop = "-26px";
			}

			let id = object.id;

			let sent = 0;
			let opened = 0;
			let unopened = 0;
			let clicked = 0;
			let bounced = 0;
			let unsubscribed = 0;
			let spam = 0;
			let dropped = 0;

			if (object.hasOwnProperty("sent")) {
				sent = object.sent;
			}
			if (object.hasOwnProperty("opened")) {
				opened = object.opened;
			}
			if (object.hasOwnProperty("unopened")) {
				unopened = object.unopened;
			}
			if (object.hasOwnProperty("clicked")) {
				clicked = object.clicked;
			}
			if (object.hasOwnProperty("bounced")) {
				bounced = object.bounced;
			}
			if (object.hasOwnProperty("unsubscribed")) {
				unsubscribed = object.unsubscribed;
			}
			if (object.hasOwnProperty("spam")) {
				spam = object.spam;
			}
			if (object.hasOwnProperty("dropped")) {
				dropped = object.dropped;
			}

			return (
				<div key={key}>
					<div style={{ marginBottom: "125px" }}>
						<div
							style={{
								fontSize: "18px",
								marginTop: marginTop,
								marginBottom: "20px",
								textAlign: "left",
								color: "white",
								width: "100%",
								paddingTop: "10px",
							}}
						>
							{isValidParam(defaultMessageNameMultiLingual[object.t_msg_name])
								? defaultMessageNameMultiLingual[object.t_msg_name]
								: object.t_msg_name}
						</div>
						<div className="report_box_area">
							<div className="report_box">
								<div
									style={{
										overflow: "hidden",
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
									}}
									title={getLocalizedStrings().label.CAMPAIGNS.SENT}
								>
									{getLocalizedStrings().label.CAMPAIGNS.SENT}
								</div>
								{sent !== 0 ? (
									<Link
										to={"#"}
										onClick={() =>
											drilldownMarketingReport(
												id,
												"Sent",
												constants.AB_REPORT_TYPE
											)
										}
									>
										<span style={{ fontSize: 18, color: "#F04B24" }}>
											{sent}
										</span>
									</Link>
								) : (
									<span style={{ fontSize: 18, color: "#F04B24" }}>{sent}</span>
								)}
								<br />
								{sent !== 0 && <span>&nbsp;</span>}
							</div>
							<div className="report_gap"></div>
							<div className="report_box">
								<div
									style={{
										overflow: "hidden",
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
									}}
									title={getLocalizedStrings().label.CAMPAIGNS.OPENED}
								>
									{getLocalizedStrings().label.CAMPAIGNS.OPENED}
								</div>
								{opened !== 0 ? (
									<Link
										to="#"
										onClick={() =>
											drilldownMarketingReport(
												id,
												"Open",
												constants.AB_REPORT_TYPE
											)
										}
									>
										<span style={{ fontSize: 18, color: "#F04B24" }}>
											{opened}
										</span>
									</Link>
								) : (
									<span style={{ fontSize: 18, color: "#F04B24" }}>
										{opened}
									</span>
								)}
								<br />
								{getPercentage(opened, sent)}
							</div>
							<div className="report_gap"></div>
							<div className="report_box">
								<div
									style={{
										overflow: "hidden",
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
									}}
									title={getLocalizedStrings().label.CAMPAIGNS.UNOPENED}
								>
									{getLocalizedStrings().label.CAMPAIGNS.UNOPENED}
								</div>
								{unopened !== 0 ? (
									<Link
										to="#"
										onClick={() =>
											drilldownMarketingReport(
												id,
												"Unopened",
												constants.AB_REPORT_TYPE
											)
										}
									>
										<span style={{ fontSize: 18, color: "#F04B24" }}>
											{unopened}
										</span>
									</Link>
								) : (
									<span style={{ fontSize: 18, color: "#F04B24" }}>
										{unopened}
									</span>
								)}
								<br />
								{getPercentage(unopened, sent)}
							</div>
							<div className="report_gap"></div>

							<div className="report_box">
								<div
									style={{
										overflow: "hidden",
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
									}}
									title={getLocalizedStrings().label.CAMPAIGNS.CLICKED}
								>
									{getLocalizedStrings().label.CAMPAIGNS.CLICKED}
								</div>
								{clicked !== 0 ? (
									<Link
										to="#"
										onClick={() =>
											drilldownMarketingReport(
												id,
												"click",
												constants.AB_REPORT_TYPE
											)
										}
									>
										<span style={{ fontSize: 18, color: "#F04B24" }}>
											{clicked}
										</span>
									</Link>
								) : (
									<span style={{ fontSize: 18, color: "#F04B24" }}>
										{clicked}
									</span>
								)}
								<br />
								{getPercentage(clicked, sent)}
							</div>
							<div className="report_gap"></div>

							<div className="report_box">
								<div
									style={{
										overflow: "hidden",
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
									}}
									title={getLocalizedStrings().label.CAMPAIGNS.BOUNCED}
								>
									{getLocalizedStrings().label.CAMPAIGNS.BOUNCED}
								</div>
								{bounced !== 0 ? (
									<Link
										to="#"
										onClick={() =>
											drilldownMarketingReport(
												id,
												"Bounce",
												constants.AB_REPORT_TYPE
											)
										}
									>
										<span style={{ fontSize: 18, color: "#F04B24" }}>
											{bounced}
										</span>
									</Link>
								) : (
									<span style={{ fontSize: 18, color: "#F04B24" }}>
										{bounced}
									</span>
								)}
								<br />
								{getPercentage(bounced, sent)}
							</div>
							<div className="report_gap"></div>

							<div className="report_box">
								<div
									style={{
										overflow: "hidden",
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
									}}
									title={getLocalizedStrings().label.CAMPAIGNS.UNSUBSCRIBED}
								>
									{getLocalizedStrings().label.CAMPAIGNS.UNSUBSCRIBED}
								</div>
								{unsubscribed !== 0 ? (
									<Link
										to="#"
										onClick={() =>
											drilldownMarketingReport(
												id,
												"Unsubscribed",
												constants.AB_REPORT_TYPE
											)
										}
									>
										<span style={{ fontSize: 18, color: "#F04B24" }}>
											{unsubscribed}
										</span>
									</Link>
								) : (
									<span style={{ fontSize: 18, color: "#F04B24" }}>
										{unsubscribed}
									</span>
								)}
								<br />
								{getPercentage(unsubscribed, sent)}
							</div>
							<div className="report_gap"></div>

							<div className="report_box">
								<div
									style={{
										overflow: "hidden",
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
									}}
									title={getLocalizedStrings().label.CAMPAIGNS.SPAM}
								>
									{getLocalizedStrings().label.CAMPAIGNS.SPAM}
								</div>
								{spam !== 0 ? (
									<Link
										to="#"
										onClick={() =>
											drilldownMarketingReport(
												id,
												"spamreport",
												constants.AB_REPORT_TYPE
											)
										}
									>
										<span style={{ fontSize: 18, color: "#F04B24" }}>
											{spam}
										</span>
									</Link>
								) : (
									<span style={{ fontSize: 18, color: "#F04B24" }}>{spam}</span>
								)}
								<br />
								{getPercentage(spam, sent)}
							</div>
							<div className="report_gap"></div>
							<div className="report_box">
								<div
									style={{
										overflow: "hidden",
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
									}}
									title={getLocalizedStrings().label.CAMPAIGNS.DROPPED}
								>
									{getLocalizedStrings().label.CAMPAIGNS.DROPPED}
								</div>
								{dropped !== 0 ? (
									<Link
										to="#"
										onClick={() =>
											drilldownMarketingReport(
												id,
												"dropped",
												constants.AB_REPORT_TYPE
											)
										}
									>
										<span style={{ fontSize: 18, color: "#F04B24" }}>
											{dropped}
										</span>
									</Link>
								) : (
									<span style={{ fontSize: 18, color: "#F04B24" }}>
										{dropped}
									</span>
								)}
								<br />
								{getPercentage(dropped, sent)}
							</div>
						</div>
					</div>
				</div>
			);
		});
	};


	let pageHeight = window.innerHeight - 247;
	let top = (pageHeight - 10) / 2;
	if (!mounted) {
		return (
			<div style={{ width: "100%", height: pageHeight }}>
				<div
					className="asset-loaderh"
					style={{ paddingTop: top, paddingLeft: "45%" }}
				>
					<div
						style={{
							...styles.assetLoaderContainer,
							height: 50,
							width: 50,
							padding: 7,
						}}
					>
						<ShowCircularProgress
							size={30}
							style={{ marginTop: "3", marginLeft: "3" }}
						/>
					</div>
				</div>
			</div>
		);
	} else if (typeof resultContent === "object") {
		let resultsContent = getResultsContent(getPercentage,drilldownMarketingReport);
		return (
			<div
				style={{
					paddingTop: "10px",
					paddingLeft: "30px",
					border: "1px solid #919191",
					backgroundColor: "#919191",
				}}
			>
				{resultsContent}
			</div>
		);
	} else {
		return null;
	}

}

export default ABCampaign;
