import React, { useEffect, useState } from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl, Typography } from '@mui/material';
import { isValidParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import QuickBooksIntegration from '../components/quickBooksIntegration';
import XeroIntegration from '../components/xeroIntegration';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { styles } from '../../../services/constants/styles';
import * as HTTPClient from '../../../services/helper/httpClient';
import ShowCircularProgress from '../components/circularProgress';
import { endPoints } from '../../../services/constants/endPoints';
import { updateUserXeroSetup } from '../../../services/actions/appActions';
import { useDispatch, useSelector } from "react-redux";




function IntegrationCointainer({ }) {


    const dispatch = useDispatch();
    const [isMounted, setIsMounted] = useState(false);
    const [details, setDetails] = useState({ type: "QUICKBOOKS" });

    const app = useSelector((state) => state.app);


    useEffect(() => {
        accountingIntegrationInfo();
    }, []);
    const disconnectHandle = () => {
        setDetails({ type: "QUICKBOOKS" });
    }

    const connectionHandle = () => {
        let intDetails = getObjectParam(details);
        intDetails.is_connected = true;
        setDetails({...intDetails});
    }

    const accountingIntegrationInfo = () => {
        try {
            if (app.me.hasOwnProperty('xero_integration') && app.me.xero_integration.hasOwnProperty('is_connected') && app.me.xero_integration.is_connected) {
                let details = getObjectParam(app.me.xero_integration);
                details.type = 'XERO';
                setDetails({ ...details });
                setIsMounted(true);
            } else {
                let url = endPoints.QUICKBOOKS_INTEGRATION.GET;
                let promise = Promise.resolve(HTTPClient.get(url));
                if (isValidParam(promise)) {
                    promise.then(response => {
                        if (isValidParam(response)) {
                            let details = {};
                            if (response.type === undefined) {
                                details.type = 'QUICKBOOKS';
                            } else {
                                details = response;
                            }
                            if (response.type === 'XERO') {
                                let details = {};
                                details.xero_integration_type = response.integration_type;
                                details.is_xero_invoice = response.is_invoices;
                                details.is_xero_payment = response.is_payments;
                                details.is_xero = true;
                                details.name = response.name;
                                details.is_connected = response.is_connected;
                                dispatch(updateUserXeroSetup(details));
                            }
                            setDetails({ ...details });
                            setIsMounted(true);
                        }
                    })
                };
            }
        } catch (error) {
            console.error("Error in 'xeroIntegration.js -> getXeroInfo()':" + error);
        }
    }

    const handleChange = (event, selectedOption) => {

        if (details !== null) {
            let intDetails = details;
            intDetails.type = selectedOption;
            setDetails({ ...intDetails });
        } else {
            let intDetails = new Object();
            intDetails.type = selectedOption;
            setDetails({ ...intDetails });
        }
    }


    let pageHeight = window.innerHeight - 145;
    let top = (pageHeight - 10) / 2;


    return (
        <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <div id="header" style={{ borderBottom: '2.0px solid #afafaf5c' }}><span style={{ fontWeight: 'bold', fontSize: '20px' }}>{getLocalizedStrings().label.MY_INTEGRATION.ACCOUNTING_INTEGRATION}</span></div>
            {isMounted && <div>
                <div style={{ marginTop: '10px' }} >
                    <Typography variant="subtitle1">{getLocalizedStrings().label.MY_INTEGRATION.ACCOUNTING_INTEGRATION_LABEL}</Typography>
                    <FormControl component="fieldset" >
                        <RadioGroup aria-label="gender" name="gender1" value={details.type} onChange={(event, value) => handleChange(event, value)}>
                            <FormControlLabel value="QUICKBOOKS" control={<Radio color='default' disabled={details !== null && details.is_connected && details.type !== "QUICKBOOKS"} />} label={getLocalizedStrings().label.MY_INTEGRATION.QUICKBOOKS} />
                            <FormControlLabel value="XERO" control={<Radio color='default' disabled={details !== null && details.is_connected && details.type !== "XERO"} />} label={getLocalizedStrings().label.MY_INTEGRATION.XERO} />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div>
                    {details !== null && details.type === 'QUICKBOOKS' &&
                        <QuickBooksIntegration details={details} connectionHandle={() => connectionHandle()} disconnectHandle={() => disconnectHandle()} />
                    }
                    {details !== null && details.type === 'XERO' &&
                        <XeroIntegration details={details} connectionHandle={() => connectionHandle()} disconnectHandle={() => disconnectHandle()} />
                    }
                </div>
            </div>}
            {!isMounted && <div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default IntegrationCointainer;