import { actions } from "../constants/actions";

const smsVerificationInitialState={
    selectedForm: 'Customer Details',
    formErrors:{}
};

const smsVerificationReducer = (state = smsVerificationInitialState, action) => {
    switch (action.type) {
        case actions.SET_SELECTED_BRAND_DETAILS_FORM:
            return {
                ...state,
                selectedForm: action.payload.selectedForm,
            };
        case actions.SET_IS_ERROR_SMSVERIFICATION_FORM:
                return {
                    ...state,
                    formErrors: action.payload.isError
                }
        case actions.SET_SMSVERIFICATION_FORM_ERROR:
            return {
                ...state,
                formErrors: {
                    ...state.formErrors,
                    [action.payload.field]: action.payload.error,
                },
            };
        default:
            return state;
    }
};

export default smsVerificationReducer;