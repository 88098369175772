import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import ShowCircularProgress from '../components/circularProgress';
import AssetsMenu from '../components/assetsMenu';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import PopOver from '../components/PopOver';
import { isValidParam, getStringParam, getArrayParam, getObjectParam } from '../../../services/helper/parameterVerifier';

const AssetsMenuDialog = ({ data, searchText }) => {

    const [isMount, setIsMount] = useState(false);
    const [treeData, setTreeData] = useState([]);
    const [selectedKey, setSelectedKey] = useState([]);
    const [searchOptions, setSearchOptions] = useState({});
    const [searchValue, setSearchValue] = useState(1);
    const [searchBy, setSearchBy] = useState('Name');
    const [searchByFieldOpen, setSearchByFieldOpen] = useState(false);
    const [anchorSearchByEl, setAnchorSearchByEl] = useState(null);
    const [bodyTreeData, setBodyTreeData] = useState(null);

    const appContainer = useSelector((state) => state.appContainer);

    useEffect(() => {
        getDocumentData();
        loadSearchByOptionList();
    }, []);

    const getDocumentData = () => {
        var dataArray = [];
        let maxChildId = 0;
        let rootItem = {};
        try {
            var promise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.ASSETS_GET, null));
            promise.then((response) => {
                response.map((item, index) => {
                    if (item.parentId === 0) {
                        maxChildId = item.maxChildId;
                        rootItem = item;
                        dataArray.push(rootItem);
                    }
                });

                var childArray = [];
                let selectedKey = [];
                dataArray.forEach(element => {
                    if (element.parentId === 0) {
                        selectedKey = getStringParam(element.key);
                        childArray = element.children || [];
                        element.children = childArray;
                    }
                });

                setTreeData(dataArray);
                setIsMount(true);
                setSelectedKey(selectedKey);

            });
        } catch (error) {
            console.error("Error on AssetsMenuDialog.js >> getDocumentData: " + error);
        }
    }
    const callBackData = (data) => {
        setBodyTreeData(data);
    }
    const setLoading = (mountedObject) => {
        setIsMount(...mountedObject);
    }

    const insertDocument = (src, documentName) => {
        let obj = {
            src: src,
            editor: appContainer.dialogProps.data.editor,
            type: appContainer.dialogProps.data.type,
            name: documentName
        }
        appContainer.dialogProps.eventName(obj);
    }

    const loadSearchByOptionList = () => {
        let searchOptions = [
            {
                label: getLocalizedStrings().label.DOCUMENT_LIBRARY.NAME,
                value: 'Name'
            },
            {
                label: getLocalizedStrings().label.DOCUMENT_LIBRARY.TYPE,
                value: 'Type'
            },
            {
                label: getLocalizedStrings().label.DOCUMENT_LIBRARY.OWNER,
                value: 'Owner'
            },
            {
                label: getLocalizedStrings().label.DOCUMENT_LIBRARY.ALL,
                value: 'All'
            }
        ]
        setSearchOptions(searchOptions);
    }

    const handleSearchByChange = (value) => {
        setSearchBy(value);
        setSearchByFieldOpen(false);
    };

    const getMenuList = () => {
        let items = [];
        let tempSearchOptions = searchOptions;
        if (isValidParam(searchOptions) && getArrayParam(tempSearchOptions).length > 0) {
            tempSearchOptions.map((fieldObject, i) => {
                let data = {};
                data.label = fieldObject.label;
                data.value = fieldObject.value;
                items.push(data);
            })
        }
        return items;
    }

    const changeSearchBy = (value) => {
        setSearchBy(value);
    }
    const handleSearchByOpen = (event) => {
        event.preventDefault();
        setSearchByFieldOpen(true);
        setAnchorSearchByEl(event.currentTarget);
    }

    const handleSearchByFieldClose = () => {
        setSearchByFieldOpen(false);
    }

    const handleKeyUp = (event) => {
        search(event.target.value, searchBy);
    }

    const search = (search_text, search_by) => {
        search_text = search_text.trim().toLowerCase();
        var matcher = new RegExp(search_text, "gi");
        var domElement = document.querySelectorAll('ul.rc-tree > li > ul');
        let element = null;
        let text = null;
        for (let index = 0; index < domElement[0].children.length; index++) {
            element = domElement[0].children[index].lastElementChild;
            if (element.hasChildNodes()) {
                text = element.lastElementChild.textContent;
                if (isValidParam(text) && getStringParam(text) !== '') {
                    if (text.search(matcher) < 0) {
                        $($("." + domElement[0].children[index].className)[index]).hide();
                    } else {
                        $($("." + domElement[0].children[index].className)[index]).show();
                    }
                }
            }

        }
    }

    let popOverList = getMenuList();
    if (isMount) {
        return (
            <div style={{ backgroundColor: '#fff' }}>
                <div className="col-sm-10" style={{ paddingLeft: 0, marginLeft: '70px', zIndex: 1, marginBottom: '10px' }}>
                    <div className="row">
                        <div className="col-sm-2">
                            <div style={{ marginTop: 5, cursor: "pointer", fontSize: '14px', color: '#717171', border: '1px solid #ccc', backgroundColor: '#fff', padding: '5px', width: '120px', height: '30px' }}
                                onClick={(e) => handleSearchByOpen(e)}>
                                {searchBy}
                                {/* <i className="fa fa-angle-down" aria-hidden="true" style={{ paddingLeft: '7px', float: 'right', paddingTop: '4px' }}></i>
                                    <Popover
                                        open={this.state.searchByFieldOpen}
                                        anchorEl={this.state.anchorSearchByEl}
                                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                                        animation={PopoverAnimationVertical}
                                        onClose={this.handleSearchByFieldClose}
                                    >
                                        <Menu name='searhby' value={this.state.searchBy}
                                            onChange={this.handleSearchByChange}
                                            style={{ width: '135px' }}
                                            autowidth={false}
                                        >
                                            {this.getMenuList()}
                                        </Menu>
                                    </Popover> */}
                                <PopOver
                                    id={'searhby'}
                                    key={'searhby'}
                                    name={'searhby'}
                                    buttonEndIcon={"keyboard_arrow_down"}
                                    buttonStyle={{ fontWeight: 'bold', cursor: 'pointer', marginTop: '-24px', marginLeft: '112px', fontSize: '20px' }}
                                    labelposition={"before"}
                                    buttonLabel={""}
                                    options={popOverList}
                                    onclickAction={(value) => handleSearchByChange(value)}
                                    isShowIcon={true}
                                />

                            </div>
                        </div>
                        <div className="col-sm-8" style={{ marginLeft: '45px' }}>
                            <div style={{ border: '1px solid #ccc', fontSize: '14px', height: '30px', marginTop: '5px', marginLeft: '-5px' }}>
                                <input type='text' value={searchText} onKeyUp={(e) => handleKeyUp(e)} placeholder={getLocalizedStrings().label.DOCUMENT_LIBRARY.SEARCH} style={{ outline: '0 none', color: '#888', border: 'none', paddingTop: '3px', paddingLeft: '10px', width: '85%', paddingBottom: '5px' }} />
                                <i className="fa fa-search" style={{ paddingTop: '5px', paddingLeft: '7px', color: '#ccc', float: 'right', paddingRight: '6px' }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%', display: 'flex', marginTop: '-1px', marginBottom: '20px', marginTop: '10px' }}>
                    <div style={{ backgroundColor: '#fff', width: '100%', marginLeft: '0px', marginRight: '2px', fontFamily: 'Lato', color: '#333', fontSize: '14px' }} >
                        <AssetsMenu
                            callFrom={data.callFrom}
                            treeData={treeData}
                            callBack={callBackData}
                            defaultSelected={selectedKey}
                            setLoading={(mountedObject) => setLoading(mountedObject)}
                            insertDocument={(src, documentName) => insertDocument(src, documentName)}
                            files={data.files}
                        />
                    </div>
                </div>
            </div>
        );

    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }
}

export default AssetsMenuDialog;

const loop = (data, newItem, callback) => {
    data.forEach((item, index, arr) => {
        if (item.childId === newItem.parentId) {
            item.children = item.children || [];
            item.children.push(newItem);
            if (newItem.t_type === 'folder') {
                newItem.children = newItem.children || [];
            }

            callback(item);
            return;
        }
        if (item.children) {
            loop(item.children, newItem, callback);
        }
    });
};

