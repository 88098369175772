const linkTo = {
    MARKETING_TEMPLATE_DETAIL_VIEW : 'MET',
    SALES_TEMPLATE_DETAIL_VIEW : 'SET'
}
const LinkToType = {
    TYPE_KANBAN : 'kanban',  //For Kanban View
    TYPE_QUERY : 'queries',  //For List View
    TYPE_REPORT : 'reports',  //For Report View
    TYPE_DETAIL_VIEW: 'detailview', //For Detail View
    TYPE_NEW: 'new', //For New Record
    TYPE_EDIT: 'edit', //For Edit Record
    TYPE_COPY: 'copy', //For Edit Record
    TYPE_FILTERS: 'filters',
    TYPE_AUDIT_REPORT :'audit-report',
    REPORT_DASHBOARD:'report-dashBoard',
    TYPE_LIST_VIEW_REPORT:'list-view-report',
    TYPE_CALENDAR: 'calendar', //For Calendar View
    TYPE_CALENDAR_SETTINGS: 'calendar-settings', //For Calendar settings
    TYPE_JOB_CALENDAR_SETTINGS: 'job-calendar-settings',
    TYPE_TASK_SETTINGS:'task-settings',
    AUDIT_SCORE_DETAILS : 'Audit Score Details',
    TYPE_AUDIT_PHOTOS:'audit-photos',
    AUDITS_OBJECT:'audits',
    TYPE_TASK: 'task', //For Task Portal View
    TYPE_EMAIL: 'email', //For Send Email
    TYPE_CLICK_TO_CALL: 'click-to-call',
    TYPE_CLICK_TO_SMS: 'click-to-sms',
    TYPE_WEBSITE_TRACKING_SETUP: 'website-tracking-setup', //For Website Tracking Setup
    TYPE_WEBSITE_TRACKING_DASHBOARD: 'website-tracking-dashboard', //For Website Tracking Dashboard
    TYPE_RELATIONSHIP: 'relationship', //For relationship
    TYPE_OTHER_VIEW: 'other',
    TYPE_SF_CUSTOM_DATA_TABLE: 'sf-custom-data-table',
    TYPE_SF_DASHBOARD_DRILLDOWN_DATA_TABLE: 'sf-dashboard-acc-summary-data-table',
    TYPE_COLUMN : 'column',  //For Column View
    TYPE_ACTIVITIES: 'activities',
    TYPE_CONVERSIONS: 'conversions',
    //TYPE_EMAIL_REPORT_LISTVIEW: 'reportlistview'
    TYPE_SETUP: 'setup',
    TYPE_NEWS: 'news',
    TYPE_PREVIEW_NEWS: 'previewnews',
    TYPE_REFERRAL_REPORT_LISTVIEW: 'referral-listview',
    TYPE_QUOTATIONS: 'quotations',
    TYPE_ROYALTY_PLAN: 'manageplan',
    TYPE_EXCEPTION: 'exception'
}

export {
    linkTo,
    LinkToType
}