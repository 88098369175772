import React, { useEffect, useState,useRef} from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { FormControl, TextField, MenuItem } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { isValidParam, isEmpty, getStringParam } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { refreshListView } from '../../../services/actions/listViewAcions';
import {constants} from '../../../services/constants/constants';

const LmsUsers = ({ object, data }) => {
    const app = useSelector((state) => state.app)
    const dispatch = useDispatch();
    const isFranchisor = app.me.is_franchisor;
    const roleOptions = isFranchisor ? ['User', 'Admin'] : ['User'];


    const [firstName, setFirstName] = useState('');
    const [userId, setUserId] = useState('');
    const [LMSUserId, setLMSUserId] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState(roleOptions[0]);
    const [group, setGroup] = useState('');
    const [groupOptions, setGroupOptions] = useState([])
    const [error,setError] = useState({})
    const [id,setId]= useState(0);
    const inputRef = useRef();

    useEffect(() => {
        if (data.type === constants.EDIT) {
            getFormData();
        }else{
            getGroupList()
            getLMSNode()
        }
    }, [])

    const getFormData = () => {
        let recordId = data.id
        let param = {};
        param.id = recordId;
        let promise = Promise.resolve(HTTPClient.get(endPoints.LMS_USERS.GET, param));
        promise.then((response) => {           
            if (isValidParam(response)) {
                let username = response?.username;
                let firstname = response?.firstname;
                let lastname = response?.lastname;
                let email = response?.email;
                let id = response?.id;
                let role = response?.level
                let groups = response?.groups?.elements || [];

                setLMSUserId(username.split('.')[0]);     
                setUserId(username);
                setFirstName(firstname);
                setLastName(lastname);
                setEmail(email);
                setId(id);
                setRole(role.charAt(0).toUpperCase() + role.slice(1));
                setGroupOptions(groups);
                setGroup(groups[0]); 
            }
        });
}
    const getGroupList = () => {
        const promise = Promise.resolve(HTTPClient.get(endPoints.LMS_USERS.GROUPLIST));
        promise.then((response) => {
            if (isValidParam(response)) {
                setGroupOptions(response?.groups?.elements ? response?.groups?.elements : []);
            }
        }).catch((err) => {
            console.log('Error fetching data:', err);
        })

    }

    const getLMSNode = () => {
        let lmsSetUp = app.me.is_lms_setup
        if(lmsSetUp){
            let lmsNode = app.me.lms_node
            setLMSUserId(lmsNode)
            setUserId(lmsNode);
        }      
    }


    const onChangeTextField = (event, field) => {
        let value = event.target.value
        let newErrors = { ...error };
        if (field === 'user_id') {
            setUserId(value);
        } else if (field === 'first_name') {
            setFirstName(value);
            setUserId(`${LMSUserId}.${value.toLowerCase().trim()}.${lastName.toLowerCase().trim()}`)
            newErrors.first_name = false;
        } else if (field === 'last_name') {
            setLastName(value);
            setUserId(`${LMSUserId}.${firstName.toLowerCase().trim()}.${value.toLowerCase().trim()}`)
             newErrors.last_name = false;
        } else if (field === 'password') {
            setPassword(value);
            newErrors.password = false;
        } else if (field === 'email') {
            setEmail(value);
            newErrors.email = false;
        } else if (field === 'role') {
            setRole(value)
        } else if (field === 'group') {
            setGroup(value)
        }
        setError(newErrors);
    }

    const validateForm = () => {
        try {
            let valid = true;
            let newErrors = {};

            if (!isValidParam(firstName) || isEmpty(getStringParam(firstName))) {
                newErrors.first_name = true;
                valid = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().label.LMS_USERS.FIRST_NAME_REQUIRED, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else if (!isValidParam(lastName) || isEmpty(getStringParam(lastName))) {
                newErrors.last_name = true;
                valid = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().label.LMS_USERS.LAST_NAME_REQUIRED, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else if (data.type !== constants.EDIT) { // Validate password only if not editing
                if (!isValidParam(password) || isEmpty(getStringParam(password))) {
                    newErrors.password = true;
                    valid = false;
                    dispatch(showCustomSnackBar(getLocalizedStrings().label.LMS_USERS.PASSWORD_REQUIRED, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
            } else if (!isValidParam(email) || isEmpty(getStringParam(email))) {
                newErrors.email = true;
                valid = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().label.LMS_USERS.EMAIL_REQUIRED, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
            setError(newErrors);
            return valid;
        }
        catch (error) {
            console.error("Error in 'LmsUsers.js' -> validateForm()':" + error);
        }

    }

    const isValidEmail = (email) => {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(email)) {
            return true;
        } else {
            return false;
        }
    }

    const handleSave = () => {

        let isValidForm = validateForm();
        let newErrors = {};
        if (isValidForm) {
            if (!isValidEmail(email)) {
                newErrors.email=true
                let errorMessage = getLocalizedStrings().message.COMMON.EMAIL_VALIDATION;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
                if (data.type === constants.EDIT){
                    let params = {};
                    params.id = id
                    params.username = userId;
                    params.firstname = firstName;
                    params.lastname = lastName;
                    params.email = email;
                    params.level = role;
    
                    let promise = Promise.resolve(HTTPClient.post(endPoints.LMS_USERS.EDIT, params));
                    promise.then((response) => {
                        console.log("response",response)
                        if (response.status === 0) {
                            dispatch(showCustomSnackBar("LMS user edited successfully.", styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            refreshListView(object);
                            dispatch(getAppDrawer(false, null, null, null, null));
                        } else {
                            dispatch(showCustomSnackBar("LMS user could not be edited.", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        }
                    });
                }else{
                    let params = {};
                    params.user_id = userId;
                    params.first_name = firstName;
                    params.last_name = lastName;
                    params.password = password;
                    params.email = email;
                    params.role = role;
                    params.group = group;

                    let url = endPoints.LMS_USERS.CREATE;
                    let promise = Promise.resolve(HTTPClient.post(url, params));
                    promise.then((response) => {
                        if (response.status === 0) {
                            dispatch(showCustomSnackBar("LMS user created successfully.", styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            dispatch(getAppDrawer(false, null, null, null, null));
                            refreshListView(object);
                        } else {
                            dispatch(showCustomSnackBar("LMS user could not be created.", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        }
                    });
                }
                setError(newErrors)
            }
        }
    }
    
    const closeDrawer = () => {
        dispatch(getAppDrawer(false, null, null, null, null));
    }

    const focusOntextfield = (element) =>{
        inputRef.current = element;
        inputRef.current?.focus();
    }

    return (
        <div className="row">
            <div className='col-sm-6'>
                <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        name="user_id"
                        id="user_id"
                        label={getLocalizedStrings().label.LMS_USERS.USER_ID}
                        fullWidth={true}
                        value={userId}
                        onChange={(e) => onChangeTextField(e, 'user_id')}
                        inputprops={{ maxlength: '256' }}
                        margin='dense'
                        className={"sf-fields-bg"}
                        size="small"
                        disabled={true}
                    />
                </FormControl>
            </div>
            <div className='col-sm-6'>
                <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        name="first_name"
                        id="first_name"
                        label={getLocalizedStrings().label.LMS_USERS.FIRST_NAME}
                        fullWidth={true}
                        value={firstName}
                        onChange={(e) => onChangeTextField(e, 'first_name')}
                        inputprops={{ maxlength: '256' }}
                        margin='dense'
                        className={"sf-fields-bg"}
                        size="small"
                        error={Boolean(error.first_name)}
                        inputRef={Boolean(error.first_name) ? focusOntextfield :''}
                    />
                </FormControl>
            </div>
            <div className='col-sm-6'>
                <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        name="last_name"
                        id="last_name"
                        label={getLocalizedStrings().label.LMS_USERS.LAST_NAME}
                        fullWidth={true}
                        value={lastName}
                        onChange={(e) => onChangeTextField(e, 'last_name')}
                        inputprops={{ maxlength: '256' }}
                        autoComplete="new-password"
                        margin='dense'
                        className={"sf-fields-bg"}
                        size="small"
                        error={Boolean(error.last_name)}
                        inputRef={Boolean(error.last_name) ? focusOntextfield :''}
                    />
                </FormControl>
            </div>
            {data.type !== constants.EDIT && (
                <div className='col-sm-6'>
                    <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            name="password"
                            id="password"
                            type="password"
                            label={getLocalizedStrings().label.LMS_USERS.PASSWORD}
                            fullWidth={true}
                            onPaste={function (e) { e.preventDefault(); }}
                            value={password}
                            onChange={(e) => onChangeTextField(e, 'password')}
                            inputprops={{ maxlength: '32' }}
                            autoComplete="new-password"
                            margin='dense'
                            className={"sf-fields-bg"}
                            size="small"
                            error={Boolean(error.password)}
                            inputRef={Boolean(error.password) ? focusOntextfield :''}
                        />
                    </FormControl>
                </div>
            )}
            <div className='col-sm-6'>
                <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        name="email"
                        id="email"
                        label={getLocalizedStrings().label.LMS_USERS.EMAIL}
                        fullWidth={true}
                        value={email}
                        onChange={(e) => onChangeTextField(e, 'email')}
                        inputprops={{ maxlength: '256' }}
                        margin='dense'
                        className={"sf-fields-bg"}
                        size="small"
                        error={Boolean(error.email)}
                        inputRef={Boolean(error.email) ? focusOntextfield :''}
                    />
                </FormControl>
            </div>
            <div className='col-sm-6'>
                <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                <TextField
                    variant="outlined"
                    name="role"
                    id="role"
                    label={getLocalizedStrings().label.LMS_USERS.ROLE}
                    fullWidth={true}
                    value={role}
                    onChange={(e) => onChangeTextField(e, 'role')}
                    inputprops={{ maxlength: '256' }}
                    margin='dense'
                    className={"sf-fields-bg"}
                    size="small"
                    select
                    disabled={data.type === constants.EDIT}
                >
                    {roleOptions.map((roleOption, index) =>
                    (
                        <MenuItem value={roleOption} key={index}>{roleOption}</MenuItem>
                    )
                    )}
                </TextField>
            </div>
            <div className='col-sm-6'>
                <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                <TextField
                    variant="outlined"
                    name="group"
                    id="group"
                    label={getLocalizedStrings().label.LMS_USERS.GROUP}
                    fullWidth={true}
                    value={group}
                    onChange={(e) => onChangeTextField(e, 'group')}
                    inputprops={{ maxlength: '256' }}
                    margin='dense'
                    className={"sf-fields-bg"}
                    size="small"
                    select
                    disabled={data.type === constants.EDIT}
                >
                    {
                        groupOptions?.map((fieldOption, index) => {
                            return (<MenuItem value={fieldOption} key={index}>{fieldOption}</MenuItem>)
                        })
                    }
                </TextField>
            </div>

            <div style={{ float: 'right', alignItems: 'right', justifyContent: 'right', display: 'flex', paddingBottom:'20px' }}>
                <Button
                    onClick={() => handleSave()}
                    style={{
                        ...styles.primaryButton,
                        marginRight: '8px',
                        height: '32px',
                    }}
                    title={getLocalizedStrings().label.COMMON.SAVE}>
                    {getLocalizedStrings().label.COMMON.SAVE}
                </Button>
                <Button
                    onClick={() => closeDrawer()}
                    style={{
                        ...styles.secondaryButton,
                        marginRight: '8px',
                        height: '32px',
                    }}
                    title={getLocalizedStrings().label.COMMON.CANCEL}>
                    {getLocalizedStrings().label.COMMON.CANCEL}
                </Button>
            </div>
        </div>
    )

}

export default LmsUsers;