import React from 'react';

import {
    headerText,
    section111Init,
    section11Init,
    section1Init,
    section2Init,
    section3Init,
    section4Init,
    section5Init,
    section6Init,
    section7Init,
    section8Init,
    section9Init,
} from './otherFormData';
import OtherInfoCustomForm from './OtherInfoCustomForm';

const OtherInfoForm = ({ formData, setFormData }) => {
    return (
        <>
            <div style={{ width: '100%', overflowX: 'hidden' }}>
                <h4>{headerText.SECTION1}</h4>
                <OtherInfoCustomForm jsonData={section1Init} formData={formData} setFormData={setFormData} />
                <div style={{marginBottom: '20px'}}>
                    <span>{headerText.SECTION111}</span>
                </div>
                <OtherInfoCustomForm jsonData={section111Init} formData={formData} setFormData={setFormData} />
                {/* section 1 last input field - section11*/}
                <OtherInfoCustomForm jsonData={section11Init} formData={formData} setFormData={setFormData} />
                <h4>{headerText.SECTION2}</h4>
                <OtherInfoCustomForm jsonData={section2Init} formData={formData} setFormData={setFormData} />
                <h4>{headerText.SECTION3}</h4>
                <OtherInfoCustomForm jsonData={section3Init} formData={formData} setFormData={setFormData} />
                <h4>{headerText.SECTION4}</h4>
                <OtherInfoCustomForm jsonData={section4Init} formData={formData} setFormData={setFormData} />
                <h4>{headerText.SECTION5}</h4>
                <OtherInfoCustomForm jsonData={section5Init} formData={formData} setFormData={setFormData} />
                <h4>{headerText.SECTION6}</h4>
                <OtherInfoCustomForm jsonData={section6Init} formData={formData} setFormData={setFormData} />
                <h4>{headerText.SECTION7}</h4>
                <OtherInfoCustomForm jsonData={section7Init} formData={formData} setFormData={setFormData} />
                <h4>{headerText.SECTION8}</h4>
                <OtherInfoCustomForm jsonData={section8Init} formData={formData} setFormData={setFormData} />
                <h4>{headerText.SECTION9}</h4>
                <OtherInfoCustomForm jsonData={section9Init} formData={formData} setFormData={setFormData} />
            </div>
        </>
    );
};

export default OtherInfoForm;
