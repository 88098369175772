import React from 'react';
import PropTypes from 'prop-types';
import { isValidParam, getStringParam, getObjectParam, getArrayParam, getBooleanParam, isString } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { getUniqueId } from '../../../services/helper/utils';
import Checkbox from '@mui/material/Checkbox';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { dateFormat, dateFormatFlatPicker } from '../../../services/helper/utils';
import { getDateOptions } from '../../../services/helper/common';
import { Menu, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import moment from 'moment';
import PopOver from '../components/PopOver';
import { connect } from 'react-redux';


const mapDispatchToProps = (dispatch) => {
    return {

    };
};
const textOverflowStyle = { maxWidth: 'inherit', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' };

export class SFPopover extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            isOpenPopover: false,
        }
        this.fieldProps = new FieldsProps();
    }

    getPopover = (props) => {
        let popOverProps = {};
        let element = null;
        let label = null;
        let selectedText = null;
        let labelElementProps = {};
        let textElementProps = {};
        try {
            let root = getObjectParam(props.root);
            let containerStyle = { cursor: 'pointer', /* display: 'flex', */ height: '16px', maxWidth: 'inherit' };
            labelElementProps.style = textOverflowStyle;
            textElementProps.style = { display: 'flex', };

            root.style = { ...containerStyle, ...getObjectParam(props.style), ...getObjectParam(root.style) };
            root = { onTouchTap: this.isOpenPopover.bind(this, true), ...root };

            label = isValidParam(props.label) ? props.label : null;

            let iconElement = <i className="fa fa-angle-down" aria-hidden="true" style={{ verticalAlign: "middle", marginLeft: 5, fontSize: 20, float: 'right' }}></i>;
            if (isValidParam(props.iconElement)) {
                iconElement = props.iconElement;
            }


            let popover = getObjectParam(props.popover);
            popover = this.fieldProps.filterProps(popover);
            popOverProps.className = 'overflow-y-initial';
            popOverProps = { ...popOverProps, ...popover };
            popOverProps = this.getPopoverProps(popOverProps);

            let item = null;
            let type = getStringParam(props.type);
            // let _state = this.state;
            if (type === 'dropdown' && isValidParam(props.list) && isValidParam(props.onChange)) {
                if (isValidParam(props.defaultValue) && (!isValidParam(this.state.dropDownValue) || this.state.dropDownValue !== props.defaultValue)) {
                    this.state.dropDownValue = props.defaultValue;
                }
                let item = this.getItemByValueFromList(this.state.dropDownValue);
                if (isValidParam(item)) {
                    this.state.dropDownValue = item.id;
                    selectedText = getStringParam(item.label);
                }

                
                // let menuProps = { onChange: this.onChangeForDropdown.bind(this, props), value: this.state.dropDownValue, selectedMenuItemStyle: { fontWeight: 'bold', color: themeColor } };
                // if (isValidParam(props.menuProps)) {
                //     menuProps = { ...menuProps, ...getObjectParam(props.menuProps) };
                // }
            }

            if (isValidParam(label)) {
                labelElementProps.children = label;
                if (!isValidParam(selectedText) || (isValidParam(item) && isString(selectedText) && selectedText === '')) {
                    labelElementProps.style.display = 'flex';
                    labelElementProps.children = [label, iconElement];
                }
                if (isString(label)) {
                    labelElementProps.title = label;
                }
            }

            if (isValidParam(selectedText) && isString(selectedText)) {
                textElementProps.title = selectedText;
            }
            let minMaxWidth = 250;
            if (isValidParam(this.props.fieldWidth)) {
                minMaxWidth = this.props.fieldWidth;
            }
            element = <div {...root}>
                {(isValidParam(selectedText) || type !== 'dropdown') && <div >
                    <FormControl variant="outlined" size="small">
                        <InputLabel id="sf-report-label-sffromfields-simple-select-outlined-label">{this.props.label}</InputLabel>
                        <Select
                            labelId="sf-report-label-sffromfields-simple-select-outlined-label"
                            id="sf-report-simple-select-outlined"
                            // className="sf-report-select-fromFields"
                            value={this.state.dropDownValue}
                            onChange={this.onChangeForDropdown.bind(this, props)}
                            label={this.props.label}
                            className={"sf-fields-bg"}
                            style={{ minWidth: minMaxWidth, maxWidth: minMaxWidth, paddingLeft: 7, paddingRight: 7 }}
                        >
                            {this.props.list.map((option, index) => (
                                <MenuItem
                                    key={option.id}
                                    id={option.id}
                                    value={option.id}
                                >
                                    {option.label}
                                </MenuItem>))}
                        </Select>
                    </FormControl>
                </div>}  </div>;
        } catch (error) {
            console.error("Error in 'sfFormFields.js -> getPopover()':" + error);
        }
        return element;
    }

    getPopoverProps = (props) => {
        let defaultProps = {};
        try {
            defaultProps.open = getBooleanParam(this.state.isOpenPopover);
            defaultProps.anchorEl = this.state.anchorEl;
            defaultProps.anchorOrigin = { horizontal: 'left', vertical: 'bottom' };
            defaultProps.targetOrigin = { horizontal: 'left', vertical: 'top' };
            //defaultProps.style = { width: '50%' };
            defaultProps.onRequestClose = this.isOpenPopover.bind(this, false);

            props = getObjectParam(props);
            props = { ...defaultProps, ...props };
        } catch (error) {
            console.error("Error in 'sfFormFields.js -> getPopoverStyle()':" + error);
        }
        return props;
    }

    isOpenPopover = (isOpen, event) => {
        try {
            isOpen = getBooleanParam(isOpen);
            if (isOpen) {
                event.preventDefault();
                this.setState({ isOpenPopover: true, anchorEl: event.currentTarget });
            } else {
                this.setState({ isOpenPopover: false, anchorEl: null });
            }
        } catch (error) {
            console.error("Error in 'sfFormFields.js -> openPopover()':" + error);
        }
    }

    onChangeForDropdown = (props, event, value) => {
        try {
            this.setState({ isOpenPopover: false, anchorEl: null, dropDownValue: value.props.value });
            props.onChange(event, value.props.value);
        } catch (error) {
            console.error("Error in 'sfFormFields.js -> onChangeForDropdown()':" + error);
        }
    }

    getItemByValueFromList = (value) => {
        let obj = null;
        try {
            value = getStringParam(value);
            let list = getArrayParam(this.props.list);
            if (list.length > 0) {
                if (value !== '') {
                    obj = getObjectParam(list.find(l => { return l.id == value })); // eslint-disable-line
                } else {
                    obj = list[0];
                }
            }
        } catch (error) {
            console.error("Error in 'sfFormFields.js -> getItemByValueFromList()':" + error);
        }
        return obj;
    }

    render() {
        return this.getPopover(this.props);
    }
}

SFPopover.propTypes = {
    root: PropTypes.object,
    iconElement: PropTypes.element,
    popover: PropTypes.object,
    children: PropTypes.object,
    label: PropTypes.object
};

/**
 * Props: onChnge, list
 * Exam: <SFSelectField onChange={this.onChange} list={[{id: 10, label: 'aaaaa'}]}/>
 */
export class SFSelectField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.fieldProps = new FieldsProps();
    }

    getSelectField = (props, list) => {
        let element = null;
        let minMaxWidth = 250;
        if (isValidParam(this.props.fieldWidth)) {
            minMaxWidth = this.props.fieldWidth;
        }
        try {
            props = this.fieldProps.filterProps(props);
            props = this.fieldProps.getSelectFieldProps(props);
            let isMultiple = getBooleanParam(props.multiple);
            let childComponent = this.fieldProps.getMenuItems(list, props);
            if (isMultiple) {
                props.selectionRenderer = this.fieldProps.selectionRendererForMultiSelect.bind(this, list);
            }
            element = <Select {...props} className={"sf-fields-bg"}
                style={{ minWidth: minMaxWidth, maxWidth: minMaxWidth, paddingLeft: 7, paddingRight: 7 }}
            >
                {childComponent}
            </Select>;
        } catch (error) {
            console.error("Error in 'sfFormFields.js -> getSelectFieldProps()':" + error);
        }
        return element;
    }

    render() {
        const { list, ...otherProps } = this.props;
        return this.getSelectField(otherProps, list);
    }
}

SFSelectField.propTypes = {
    list: PropTypes.array.isRequired
};

export class SFReportDatePicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fromDate: null,
            toDate: null
        };
        this.fieldProps = new FieldsProps();
        this.localizedString = getLocalizedStrings();
    }

    componentDidMount() {
        let defaultValue = getStringParam(this.props.defaultValue);
        let fromDate = isValidParam(this.props.fromDate) ? moment(String(this.props.fromDate)).format(dateFormat[this.props.app.me.date_format]) : null;
        let toDate = isValidParam(this.props.toDate) ? moment(String(this.props.toDate)).format(dateFormat[this.props.app.me.date_format]) : null;
        if (defaultValue === 'cust') {
             this.setState({fromDate:fromDate,toDate:toDate});
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.hasOwnProperty('defaultValue') && nextProps.hasOwnProperty('defaultValue')
            && this.props.defaultValue !== nextProps.defaultValue) {
                let _state = this.state;
            if (getStringParam(nextProps.defaultValue) === 'cust') {
                let fromDate = isValidParam(nextProps.fromDate) ? moment(String(nextProps.fromDate)).format(dateFormat[this.props.app.me.date_format]) : null;
                let toDate = isValidParam(nextProps.toDate) ? moment(String(nextProps.toDate)).format(dateFormat[this.props.app.me.date_format]) : null;

                _state.fromDate = fromDate;
                _state.toDate = toDate;
            } else {
                _state.fromDate = null;
                _state.toDate = null;
            }
            this.setState(_state);
        }
    }

    componentDidUpdate() {
        let defaultValue = getStringParam(this.props.defaultValue);
        if (defaultValue === 'cust') {
            let dateTimeDiv1 = document.getElementsByClassName("sfDateTimeDiv-from-date");
            if (isValidParam(dateTimeDiv1) && dateTimeDiv1.length > 0) {
                if (isValidParam(this.state.fromDate)) {
                    dateTimeDiv1[0].classList.add('label-top');
                } else {
                    dateTimeDiv1[0].classList.remove('label-top');
                }

            }
            let dateTimeDiv2 = document.getElementsByClassName("sfDateTimeDiv-to-date");
            if (isValidParam(dateTimeDiv2) && dateTimeDiv2.length > 0) {
                if (isValidParam(this.state.toDate)) {
                    dateTimeDiv2[0].classList.add('label-top');
                } else {
                    dateTimeDiv2[0].classList.remove('label-top');
                }
            }
        }
    }

    handleCustomDateChange = (date, name) => {
        try {
            let _state = this.state;
            if (isValidParam(date)) {
                _state[name] = date;
            } else {
                _state[name] = null;
            }
            this.setState(_state);
            this.props.onChange(name, this.getDate(date));
        }
        catch (error) {
            console.error("Error in 'sfFormFields.js -> handleCustomDateChange()':" + error);
        }
    }

    onChange = (event, value) => {
        this.props.onChange(event, value);
    }

    getDate = (date) => {
        let dateValue = isValidParam(date) ? new Date(date) : new Date();
        dateValue = new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate());
        return dateValue;
    }

    getDatePickerOptions = (options) => {
        try {
            options = getObjectParam(options);
            if (isValidParam(this.props.profile)) {
                let HHFormat = new RegExp("HH");
                options.dateFormat = dateFormatFlatPicker[this.props.profile.date_format];
                options.time_24hr = HHFormat.test(this.props.profile.date_format) ? true : false;
            }
        } catch (error) {
            console.error("Error in 'sfFormFields.js -> getDatePickerOptions()':" + error);
        }
        return options;
    }

    generateDateSelectionList() {
        try {
            let options = isValidParam(this.props.list) ? getArrayParam(this.props.list) : getDateOptions();
            return options.map(m => { return { id: m.value, label: m.text }; });
        } catch (error) {
            console.error("Error in 'sfFormFields.js -> generateDateSelectionList()':" + error);
        }
    }

    render() {
        let containerStyle = { display: 'flex' };
        const { fromDateOptions, toDate, toDateOptions, ...otherProps } = this.props;
        let defaultValue = getStringParam(this.props.defaultValue);
        if (defaultValue === 'cust') {
            containerStyle = { ...containerStyle, gridTemplateColumns: '33% 33% 33%', gridGap: 8, };
        } else {
            containerStyle = { ...containerStyle, gridTemplateColumns: '100%' };
        }
        //let containerProps = this.fieldProps.filterProps();
        let popoverProps = this.fieldProps.filterProps(otherProps);
        let isCallFromReport = this.props.hasOwnProperty("isCallFromReport") ? this.props.isCallFromReport : false;
        let startDivStyle = {};
        let endDivStyle = {};
        let isConversionReport = this.props.hasOwnProperty("isConversionReport") ? this.props.isConversionReport : false;
        let isCallReport = this.props.hasOwnProperty("isCallReport") ? this.props.isCallReport : false;
        if (isCallFromReport) {
            containerStyle = { display: 'flex', gridGap: 8, };
            if (isConversionReport) {
                startDivStyle = { width: "23.5%", position: "absolute", left: "26.2%", top: "0px", };
                endDivStyle = { width: "23.5%", position: "absolute", left: "50.6%", top: "0px", }

            } else if(!isCallReport ){
                startDivStyle = { width: "23.5%", position: "absolute", left: "50.8%", top: "0px", };
                endDivStyle = { width: "23.5%", position: "absolute", left: this.props.defaultValue == 'cust' ? "75.5%":"70.5%", top: "0px", }            } 
            else {
                startDivStyle = { width: "250px",} ;
                endDivStyle = { width: "250px",}

            }
        
        }
        let isActivityReport = this.props.hasOwnProperty("isActivityReport") ? this.props.isActivityReport : false;
        if (isActivityReport) {
            startDivStyle = { width: "23.5%", position: "absolute", left: "25.8%", top: "0px", };
            endDivStyle = { width: "23.5%", position: "absolute", left: "50.6%", top: "0px", }
        }
        //** 
        return <div>
            <div style={containerStyle}>
                <SFPopover {...popoverProps} list={this.generateDateSelectionList()} />
                <div style={{ ...startDivStyle, display: getStringParam(this.props.defaultValue) === 'cust' ? 'block' : 'none' }}>


                    <div className='sf-form-field sfDateTimeDiv-from-date' id="dateholder1">
                        <PopOver
                            id={'sf-date-time-picker1'}
                            className={'date-time anval'}
                            key={'fromDate'}
                            name={'fromDate'}
                            btnType={'date'}
                            buttonStyle={{ fontSize: '16px' }}
                            containerStyle={{ lineHeight: 1, marginBottom: '20px' }}
                            buttonLabel={this.localizedString.label.REPORT.START_DATE}
                            onChange={this.handleCustomDateChange}
                            value={this.state.fromDate}
                            innerLabelStyle={{ padding: "11 5 2 5", fontSize: '15px' }}
                            height={40}
                            options={this.getDatePickerOptions(fromDateOptions)}
                        />
                    </div>
                </div>
                <div style={{ ...endDivStyle, display: getStringParam(this.props.defaultValue) === 'cust' ? 'block' : 'none' }}>
                    <div className='sf-to-field sfDateTimeDiv-to-date' id="dateholder2">
                        <PopOver
                            id={'sf-date-time-picker1'}
                            className={'date-time anval'}
                            key={'toDate'}
                            name={'toDate'}
                            btnType={'date'}
                            buttonStyle={{ fontSize: '16px' }}
                            containerStyle={{ lineHeight: 1, marginBottom: '20px' }}
                            buttonLabel={this.localizedString.label.REPORT.END_DATE}
                            onChange={this.handleCustomDateChange}
                            value={this.state.toDate}
                            innerLabelStyle={{ padding: "11 5 2 5", fontSize: '15px' }}
                            height={40}
                            options={this.getDatePickerOptions(toDateOptions)}
                        />
                    </div>
                </div>
            </div>
        </div>;
    }
}

class FieldsProps {
    constructor(state) {
        this.state = state;
    }

    getSelectFieldProps = (props) => {
        let defaultProps = {};
        try {
            const { underlineFocusStyle, floatingLabelStyle, floatingLabelShrinkStyle, floatingLabelFocusStyle, inputStyle, style, underlineShow, errorStyle, iconStyle, className, labelStyle, autowidth, ...otherProps } = props;
            defaultProps.underlineFocusStyle = { ...styles.textField.underlineFocus, ...getObjectParam(underlineFocusStyle) };
            defaultProps.floatingLabelStyle = { ...styles.textField.floatingLabel, top: '10px', color: '#999999', fontWeight: 'normal', paddingLeft: 15, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', ...getObjectParam(floatingLabelStyle) };
            defaultProps.floatingLabelShrinkStyle = { ...styles.textField.floatingLabelShrink, transform: 'scale(0.7) translate(0px, -13px)', ...getObjectParam(floatingLabelShrinkStyle) };
            defaultProps.floatingLabelFocusStyle = { ...styles.textField.floatingLabelFocus, transform: 'scale(0.7) translate(0px, -13px)', ...getObjectParam(floatingLabelFocusStyle) };
            defaultProps.inputStyle = { ...styles.textField.input, marginTop: 0, ...getObjectParam(inputStyle) };
            defaultProps.style = { border: '1px solid rgb(221, 221, 221)', backgroundColor: '#fff', height: 40, float: 'left', ...getObjectParam(style) };
            defaultProps.iconStyle = { height: 'inherit', padding: 4, top: 0, right: -6, ...getObjectParam(iconStyle) };
            defaultProps.labelStyle = { height: 'inherit', lineHeight: 'inherit', top: props.floatingLabelText ? 12 : 8, paddingLeft: 10, paddingRight: 25, ...getObjectParam(labelStyle) }
            defaultProps.autowidth = isValidParam(autowidth) ? getBooleanParam(autowidth) : true;

            props = { ...defaultProps, ...getObjectParam(otherProps) };
        } catch (error) {
            console.error("Error in 'sfFormFields.js -> getSelectFieldProps()':" + error);
        }
        return props;
    }

    getLabelForMultiSelect = (list, values) => {
        let labels = [];
        let temp = null;
        try {
            list = getArrayParam(list);
            values = getArrayParam(values);
            values.forEach(m => {
                temp = list.find(f => { return f.id === m });
                if (isValidParam(temp) && isValidParam(temp.label)) {
                    labels.push(temp.label);
                }
            });
        } catch (error) {
            console.error("Error in 'sfFormFields.js -> getLabelForMultiSelect()':" + error);
        }
        return labels;
    }

    selectionRendererForMultiSelect = (list, values) => {
        try {
            list = getArrayParam(list);
            values = getArrayParam(values);
            switch (values.length) {
                case 0:
                    return '';
                default:
                    return this.getLabelForMultiSelect(list, values).join(', ');
            }
        } catch (error) {
            console.error("Error in 'sfFormFields.js -> selectionRendererForMultiSelect()':" + error);
        }
    }

    /*****************************  START: Common Methods ********************************************************/
    getMenuItems = (list, props) => {
        let menuItems = [];
        try {

            list = getArrayParam(list);
            props = this.filterProps(props);
            let isMultiple = getBooleanParam(props.multiple);
            let value = isMultiple ? getArrayParam(props.value) : props.value;
            if (list.length > 0) {
                list.forEach((element, index) => {
                    let fieldProps = {};
                    fieldProps.key = getStringParam(props.key + '-' + index);
                    fieldProps.id = getStringParam(element.label);
                    fieldProps.style = styles.popoverMenuItem;
                    fieldProps.innerDivStyle = { padding: '0px 10px 0px 10px' };
                    fieldProps.primaryText = this.getLabelElement({ title: element.label, label: element.label, style: {} });
                    fieldProps.value = element.id;
                    if (isMultiple && getStringParam(element.id) !== '' && getStringParam(element.label) !== '') {
                        fieldProps.insetChildren = true;
                        fieldProps.checked = value && value.indexOf(element.id) > -1;
                        fieldProps.leftIcon = <Checkbox checked={fieldProps.checked} color="default" style={{ marginTop: 2, left: 10 }} />;
                        fieldProps.innerDivStyle = { padding: '0px 10px 0px 45px' };
                    }
                    menuItems.push(<MenuItem {...fieldProps} />);
                });
            }
        } catch (error) {
            console.error("Error in 'sfFormFields.js -> getMenuItems()':" + error);
        }
        return menuItems;
    }

    getMenu = (props, list) => {
        let element = null;
        try {
            props = this.filterProps(props);
            list = getArrayParam(list);
            let childComponent = this.getMenuItems(list)
            element = <Menu {...props}>
                {childComponent}
            </Menu>;
        } catch (error) {
            console.error("Error in 'sfFormFields.js -> getMenu()':" + error);
        }
        return element;
    }

    filterProps = (props) => {
        try {
            props = getObjectParam(props);
            let uId = getUniqueId();
            let key = getStringParam(props.key);
            key = key !== '' ? key : uId;
            props.key = 'sf-key-' + key;

            let id = getStringParam(props.id);
            id = id !== '' ? id : uId;
            props.id = 'sf-id-' + id;

        } catch (error) {
            console.error("Error in 'sfFormFields.js -> filterProps()':" + error);
        }
        return props;
    }

    /**
     * props: label, title, style
     */
    getLabelElement = (propsObj) => {
        let labelElement = null;
        let style = { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' };
        let props = {};
        try {
            propsObj = getObjectParam(propsObj);
            props.style = { ...style, ...getObjectParam(propsObj.style) };
            props.title = getStringParam(propsObj.title);
            labelElement = <div {...props}> {getStringParam(propsObj.label)} </div>;
        } catch (error) {
            console.error("Error in 'sfFormFields.js -> getLabelElement()':" + error);
        }
        return labelElement;
    }
    /*****************************  END: Common Methods ********************************************************/
}

const mapStateToProps = (state) => {
    return {
        app: state.app,
        report: state.report,
        //emailReport: state.emailReport,
        userlist: state.userlist
    };
};
SFReportDatePicker = connect(mapStateToProps, mapDispatchToProps)(SFReportDatePicker);