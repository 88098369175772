
import React from 'react';
import { useEffect, useState,useRef  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from 'react-redux';
import { styles } from '../../../services/constants/styles';
import { Menu, FormControl, TextField, Button, MenuItem } from '@mui/material'
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { isValidParam, getStringParam, getIntParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { refreshListView } from '../../../services/actions/listViewAcions';
import { constants } from '../../../services/constants/constants';
import { hasAccessPermission } from '../../../services/helper/common';
import { getMergeField } from '../../../services/actions/smsAction';
import PopOver from '../components/PopOver';

const PhoneTemplate =({id,object,type})=>{
 const inputRef = useRef(null);
 const dispatch = useDispatch();
 let app= useSelector((state) => state.app);
 let appContainer= useSelector((state) => state.appContainer);
 const [showAddScriptContent,setHowAddScriptContent] = useState(false);
 const [dataId,setDataId] = useState(0);
 const [scriptName,setScriptName] = useState('');
 const [selectedWorkflow,setSelectedWorkflow] = useState('');
 const [scriptDetailText,setScriptDetailText] = useState('');
 const [status,setStatus] = useState('Active');
 const [workflowList,setWorkflowList] = useState([]);
 const [errorMessage,setErrorMessage] = useState(null);
 const [recordList,setRecordList] = useState(null);
 const [mounted,setMounted] = useState(false);
 const [objectList,settObjectList] = useState([]);
 const [selectedObject,setSelectedObject] = useState('');
 const [selectWFId,setSelectWFId] = useState('-1');
 const [wfList,setWfList] = useState([]);
 const [mergeFields,setMergeFields] = useState(null);
 const [anchorEl, setAnchorEl] = useState(false);
 const [template, setTemplate] = useState({
                name: '',
                script_details: '',
                id: id | 0,
                status: 'Active',
            });
 const [scriptlength, setScriptlength] = useState('0');
 const [dummydata,setDummydata] = useState(null);



    useEffect(() => {
        loadPhoneTemplateData(id);
    }, []);

    useEffect(() => {
        loadPhoneTemplateData(id);
    }, [id]);

    const loadPhoneTemplateData = (valueId) => {
        try {
            if (valueId !== null && valueId !== undefined && valueId !== 0) {
                var response = Promise.resolve(HTTPClient.get(endPoints.CALL_SCRIPT.EDIT + '/' + valueId, null));
                response.then((response) => {
                    let scriptName = response.t_script_name;
                    if (type === "copy") {
                        valueId = 0;
                        scriptName = getLocalizedStrings().label.TWILIO.COPY_OF + '_' + response.t_script_name;
                    }
                    setDataId(valueId);
                    setScriptName(scriptName);
                    setScriptDetailText(response.t_script_details);
                    setStatus(response.t_status);
                    loadObjectList(valueId);
                });
            } else {
                loadObjectList(valueId);
            }
        } catch (error) {
            console.error("Error in 'PhoneTemplate.js -> loadPhoneTemplateData()':" + error);
        }
    }

    const loadObjectList = (valueId) => {
        try {
            getMergeFieldObject();
        } catch (error) {
            console.error("Error in 'PhoneTemplate.js -> loadObjectList()':" + error);
        }
    }

    

    const setScriptNameFn = (event) => {
        let value = event.target.value
        try {
            if (isValidParam(value)) {
                setScriptName(value);
            }
        } catch (error) {
            console.log("Error in 'PhoneTemplate.js -> setScriptName()':" + error);
        }
    }


    const setScriptDetailTextFn = (event) => {
        let value = event.target.value
        try {
            if (isValidParam(value)) {
                setScriptDetailText(value);
            }
        } catch (error) {
            console.log("Error in 'PhoneTemplate.js -> setScriptDetailTextFn()':" + error);
        }
    }

    const closeDrawer = () => {
        dispatch(getAppDrawer(false, null, null, null, null));
    }

    const saveCallScript = () => {
        let obj = {};
        obj.id = getIntParam(dataId);
        obj.name = getStringParam(scriptName).trim();
        obj.detail = getStringParam(scriptDetailText).trim();
        let isValid = validateForm(obj);
        let isValidFields = isValid[0];
        let shouldUpdate = isValid[1];
            if (isValidFields) {
                let params = {};
                params.id = obj.id;
                params.t_script_name = obj.name;
                params.t_script_details = obj.detail;
                params.t_status = status;

                let response = Promise.resolve(HTTPClient.post(endPoints.CALL_SCRIPT.SAVE, params));
                response.then((response) => {
                    if (isValidParam(response)) {
                        if (response.status === 0) {
                            if (params.id > 0) {
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.CALL_SCRIPT.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            }
                            else {
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.CALL_SCRIPT.SAVE_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            }
                            closeDrawer();
                            refreshListView(constants.CALL_SCRIPT_OBJECT);
                        }
                        else {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.CALL_SCRIPT[response.error.message], styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        }
                    }
                });
            }
            else {
                if (getStringParam(shouldUpdate).length > 0) {
                    let tempErrorMessage = shouldUpdate;
                    dispatch(showCustomSnackBar(tempErrorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
            }
    }

    const validateForm = (obj) => {
        let errorMsg = '';
        let isValid = true;
        let tempErrorMessage = errorMessage;
            if (isValidParam(obj)) {
                if (getStringParam(obj.name).length === 0) {
                    errorMsg = getLocalizedStrings().message.CALL_SCRIPT.BLANK_NAME;
                    isValid = false;
                    tempErrorMessage = errorMsg;
                }
                else if (getStringParam(obj.detail).length === 0) {
                    errorMsg = getLocalizedStrings().message.CALL_SCRIPT.BLANK_DETAILS;
                    isValid = false;
                    tempErrorMessage = errorMsg;
                }
            }
            
        return [isValid,tempErrorMessage];
    }

 
    const deleteCallScript =()=>{

    }
    const openfields = () => {
        let element = null;
        let fieldItems = mergeFields;
        try {
            let popOverList = [];
            fieldItems.forEach((item, index) => {
                if (isValidParam(item)) {
                    if (isValidParam(Object.values(item)[0]) && isValidParam(Object.keys(item)[0])) {
                        let data = {};
                        data.label = Object.values(item)[0];
                        data.value = Object.keys(item)[0];
                        popOverList.push(data);  // issue is here
                    }
                }
            });
            element = <span title={getLocalizedStrings().message.SMS_INTEGRATION.MERGE_FIELD}>
                <span  style={{ marginRight: 5, maxWidth: 80, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }}>
                    <PopOver
                        id={'phoneTemplate'}
                        key={'phoneTemplate'}
                        name={'phoneTemplate'}
                        title={getLocalizedStrings().message.SMS_INTEGRATION.MERGE_FIELD}
                        buttonEndIcon={"settings,keyboard_arrow_down"}
                        buttonStyle={{ color: '#7b7272', marginTop: '0px', fontSize:18 }}
                        labelposition={"before"}
                        buttonLabel={""}
                        options={popOverList}
                        onclickAction={(value)=>menuOnChange(value)}
                        isShowIcon={true}
                        buttonLineOrigin={[28, 145]}
                    />
                </span>
            </span>
        } catch (error) {
            console.error("Error in 'SmsTemplate.js -> openfields()':" + error);
        }
        return element
    }

    const getMergeFieldObject = () => {
        try {
            let params = {};
            let promise = Promise.resolve(getMergeField(constants.CONTACTS_OBJECT, params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        setMergeFields(response);
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'PhoneTemplate.js -> getMergeFieldObject()':" + error);
        }
    }

    

   

    const menuOnChange = (value) => {
        try {
            var eventSelection = document.getElementById('scriptDetailText');
            let start = eventSelection.selectionStart,
            end = eventSelection.selectionEnd;
            let tempValue;
            if(!end){
                tempValue = getStringParam(scriptDetailText).concat(' ${' + getStringParam(value) + '} ');
            }else{
                tempValue = getStringParam(scriptDetailText).substring(0, start) + ' ${' + getStringParam(value) + '} ' + getStringParam(scriptDetailText).substring(end);
            }
            setScriptlength(tempValue.length);
            let tempTemplate = template;
            tempTemplate.script_details = tempValue;
            setTemplate(tempTemplate);
            setScriptDetailText(tempValue);
        } catch (error) {
            console.error("Error in 'PhoneTemplate.js ->  menuOnChange()':" + error);
        }
    }

    
        let workflowTitle = null;
        if (isValidParam(workflowList)) {
            for (let i = 0; i < workflowList.length; i++) {
                if (selectedWorkflow === workflowList[i].value) {
                    workflowTitle = (workflowList[i].label);
                    break;
                }
            };
        }
        let hasPermission = hasAccessPermission(object, constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_MANAGE_TEMPLATES);
        return (
            <div>
                <div className='row' style={{ marginLeft: '0px', marginRight: '0px' }}>
                    <div>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        label={getLocalizedStrings().label.CALL_SCRIPT.ADD_SCRIPT_NAME}
                                        fullWidth={true}
                                        name="callScriptName"
                                        className={"sf-fields-bg"}
                                        inputStyle={{ marginTop: '6px', color: '#333', fontSize: '15px', fontWeight: 'normal' }}
                                        inputprops={{ maxLength: 64 }}
                                        value={scriptName}
                                        onChange={(e)=>setScriptNameFn(e)}
                                        autoComplete="new-password"
                                        margin='dense'
                                        size='small'
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div style={{ height: '40px' , marginRight:'12px'}}>
                            <div style={{ marginTop: '10px', float: "right", height: 'auto' }}>
                                {openfields()}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        id="scriptDetailText"
                                        name="scriptDetailText"
                                        value={scriptDetailText}
                                        multiline={true}
                                        rows={8}
                                        maxRows={10}
                                        onChange={(e)=>setScriptDetailTextFn(e)}
                                        className={"sf-fields-bg"}
                                        label={getLocalizedStrings().label.CALL_SCRIPT.ADD_DETAILS}
                                        textareastyle={{ marginTop: '10px', height: '90%' }}
                                        inputStyle={{ overflowY: 'auto' }}
                                        fullWidth={true}
                                        autoComplete="new-password"
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div style={{ float: 'right', marginTop: '12px' }}>
                                    {hasPermission ?
                                        <Button onClick={()=>saveCallScript()} style={styles.primaryButton} >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                                        : <Button style={{ ...styles.primaryButton, backgroundColor: '#ffc5b3', cursor: 'not-allowed' }} >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                                    }

                                    {showAddScriptContent && getIntParam(dataId) !== 0 && getIntParam(dataId) > 0 &&
                                        <Button onClick={deleteCallScript} style={styles.secondaryButton} >{getLocalizedStrings().label.COMMON.DELETE}</Button>
                                    }
                                    <Button onClick={()=>closeDrawer()} style={{ ...styles.secondaryButton, marginRight: 0 }}>{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    
}


export default PhoneTemplate;