import { actions } from '..//constants/actions';

const automationCampaignInitialSate = {
    mounted: false,
    jsPlumbInst: null,
    data: [],
    error: null
}

const automationCampaignReducer = (state = automationCampaignInitialSate, action) => {
    switch (action.type) {
        case actions.AUTOMATION_CAMPAIGN_GET_FULFILLED:
            return {
                ...state,
                mounted: true,
                data: action.payload
            }
    
        case actions.AUTOMATION_CAMPAIGN_SET_JSPLUMBINST:
            return {
                ...state,
                mounted: false,
                jsPlumbInst: action.payload
            }
        case actions.AUTOMATION_CAMPAIGN_SET_TEMPLATE:
            return {
                ...state,
                mounted: true,
                data: action.payload
            }
        case actions.AUTOMATION_CAMPAIGN_ADD_NODE:
            return {
                ...state,
                mounted: true,
                data: action.payload
            }
        case actions.AUTOMATION_CAMPAIGN_SET_MOUNTED:        
            return {
                ...state,
                mounted: action.payload            
            }            
        default:
            return state;
    }
}

export default automationCampaignReducer;