import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { getFilters } from "../../../services/actions/filterMenuAction";
import { setTabs } from "../../../services/actions/tabActions";
import ShowCircularProgress from "../components/circularProgress";
import { constants } from "../../../services/constants/constants";
import { styles } from "../../../services/constants/styles";
import map from "lodash/map";
import { isValidParam } from "../../../services/helper/parameterVerifier";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import { addTab, TYPE_LIST_VIEW } from "../../../services/helper/sfTabManager";
import * as sfDialogs from "../components/sfDialogs";
import { setHeader } from "../../../services/actions/headerActions";
import {
	saveRecentFilter,
	getImageNameByObject,
} from "../../../services/helper/common";
import {
	hasAccessPermission,
	getSelectedObjectLabel,
	addFilterToReduxStore,
	getRecentFilters,
} from "../../../services/helper/common";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { getAppDrawer } from "../../../services/actions/appContainerActions";
import { removeScrollPosition } from "../../../services/actions/listViewAcions";
import {
	removeColumnViewScrollPosition,
	removeColumnViewHorizontalScrollPosition,
} from "../../../services/actions/listsActions";
import { sortArrayObjectByProperty } from "../../../services/helper/utils";
import { endPoints } from "../../../services/constants/endPoints";
import * as HTTPClient from "../../../services/helper/httpClient";
import { getAppDialog } from "../../../services/actions/appContainerActions";
import { getSelectedViewFromCookie } from "../../../services/helper/sfCookies";
import { useDispatch, useSelector } from "react-redux";
function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
	  ref.current = value;
	});
	return ref.current;
  }
const ColumnFilter = ({ object, searchText, setParentSearchResult }) => {
	const dispatch = useDispatch();
	const prevObject = usePrevious(object)
	const app = useSelector((state) => state.app);
	const tab = useSelector((state) => state.tab);
	const filters = useSelector((state) => state.filters);

	const [mounted, setMounted] = useState(false);
	const [queries, setQueries] = useState([]);
	const [contentHeight, setContentHeight] = useState(
		document.body.clientHeight - 240
	);
	const [top, setTops] = useState((contentHeight - 10) / 2);

	useEffect(() => {
		if (filters.filterMounted) setQueries(filters.queries[object]);
	}, [filters, object]);

	useEffect(() => {
		if(object !== prevObject){
			setMounted(false)
			setQueries([])
			getFiltersFunc(object);
		} else {
			setQueries(filters.queries[object])
		}
	}, [object]);

	const getFiltersFunc = (obj) => {
		let queries = filters.queries[obj];
		if (!isValidParam(queries)) {
			let promise = Promise.resolve(getFilters(obj));
			if (isValidParam(promise)) {
				promise.then((response) => {
					if (isValidParam(response)) {
						let queries = response.queries;
						queries = queries.filter((f) => {
							return f.name != "Onboarding Units";
						});
						filters.queries[obj] = queries;
						setMounted(true);
						setQueries(queries);
					}
				});
			}
		} else {
			setMounted(true);
			setQueries(queries);
		}
	};

	const openTab = (filter) => {
		dispatch(setHeader(object, null, null, true, true));
		let tab = {
			item: object,
			label: filter.name,
			object: object,
			type: TYPE_LIST_VIEW,
			imgName: getImageNameByObject(object),
			url: "/" + object + "/queries",
			info: { filter: { id: filter.id, name: filter.name, type: filter.type } },
		};
		addTab(tab, true);
		saveRecentFilter(object, filter);
	};

	const showFilterDetails = (filter) => {
		let hasPermission = null;
		hasPermission = hasAccessPermission(
			null,
			constants.SECURITY_LEVEL_TYPE_PERMISSION,
			constants.PERMISSION_NAME_MANAGE_FILTERS
		);
		if (hasPermission) {
			try {
				let label = getSelectedObjectLabel();
				let data = {};
				data.object = "filter";
				data.id = filter.id;
				data.table = object.toLowerCase();
				data.mode = "edit";
				// let title = getLocalizedStrings().label.HOME.ADD_FILTER + ' on ' + label;
				dispatch(getAppDrawer(true, filter.name, constants.FILTER, data, null));
			} catch (error) {
				console.error(
					"Error in 'columnFilter.js -> showFilterDetails()':" + error
				);
			}
		} else {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				getLocalizedStrings().message.COMMON.ACCESS_DENIED,
				null
			);
		}
	};

	const coppyFilterDetails = (filter) => {
		let hasPermission = null;
		hasPermission = hasAccessPermission(
			null,
			constants.SECURITY_LEVEL_TYPE_PERMISSION,
			constants.PERMISSION_NAME_MANAGE_FILTERS
		);
		if (hasPermission) {
			try {
				let label = getSelectedObjectLabel();
				let data = {};
				data.object = "filter";
				data.id = filter.id;
				data.table = object.toLowerCase();
				data.mode = "copy";
				// let title = getLocalizedStrings().label.HOME.ADD_FILTER + ' on ' + label;
				dispatch(
					getAppDrawer(
						true,
						getLocalizedStrings().label.FILTER.COPY_FILTER + filter.name,
						constants.FILTER,
						data,
						null
					)
				);
			} catch (error) {
				console.error(
					"Error in 'columnFilter.js -> coppyFilterDetails()':" + error
				);
			}
		} else {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				getLocalizedStrings().message.COMMON.ACCESS_DENIED,
				null
			);
		}
	};

	const deleteFilterDetails = (filter) => {
		let hasPermission = null;
		hasPermission = hasAccessPermission(
			null,
			constants.SECURITY_LEVEL_TYPE_PERMISSION,
			constants.PERMISSION_NAME_MANAGE_FILTERS
		);
		if (hasPermission) {
			try {
				var checkGroupUsedIn = Promise.resolve(
					HTTPClient.post(endPoints.FILTER.CHECK_USED_IN_CAMPAIGN, {
						id: filter.id,
					})
				);
				checkGroupUsedIn.then((response) => {
					if (Object.keys(response.data).length > 0) {
						let processMessageData = dynamicProcessFilterData(response.data, filter.name);
						let dialogData = {};
						dialogData.message = processMessageData;
						dispatch(
							getAppDialog(
								true,
								constants.ALERT_DIALOG,
								getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
								null,
								dialogData,
								constants.DETAIL_VIEW
							)
						);
					} else {
						let msg = "";
						if (filter.id > 0) {
							msg =
								'"' +
								filter.name +
								'" ' +
								getLocalizedStrings().message.HOME.FILTER_DELETE_RECORD;
						}
						sfDialogs.confirm(
							getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
							msg,
							() => deleteFilterDetailsOnConfirm(filter),
							null
						);
					}
				});

				return;
			} catch (error) {
				console.error(
					"Error in 'columnFilter.js -> deleteFilterDetails()':" + error
				);
			}
		} else {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				getLocalizedStrings().message.COMMON.ACCESS_DENIED,
				null
			);
		}
	};

	const deleteFilterDetailsOnConfirm = (filter) => {
		let params = {
			id: [filter.id],
		};

		let promise1 = Promise.resolve(
			HTTPClient.post(endPoints.FILTER.DELETE, params)
		);
		if (isValidParam(promise1)) {
			promise1.then((response) => {
				let tempReduxFilter = {};
				tempReduxFilter.id = filter.id;
				tempReduxFilter.query_id = filter.query_id;
				tempReduxFilter.name = filter.name;
				tempReduxFilter.type = filter.type;
				tempReduxFilter.modifyType = "remove";
				addFilterToReduxStore(tempReduxFilter, object);
				getRecentFilters(object);
				dispatch(
					showCustomSnackBar(
						getLocalizedStrings().message.COMMON.DELETE,
						styles.snackbarBodyStyleSuccess,
						styles.snackBarStyleTop
					)
				);
			});
		}
	};

	const generateFilternames = (action) => {
		let hasPermission = hasAccessPermission(
			null,
			constants.SECURITY_LEVEL_TYPE_PERMISSION,
			constants.PERMISSION_NAME_MANAGE_FILTERS
		);
		let filterItems = null;
		let filters = queries;
		let linkToUrl = "/" + object;
		let filterCount = 0;
		let view = getSelectedViewFromCookie(app.me.projectId, object);

		let defaultMenuListMultiLingual = {
			Accounts: getLocalizedStrings().label.HEADER.ACCOUNTS,
			"Accounts without Groups":
				getLocalizedStrings().label.HEADER.ACCOUNTS_WITHOUT_GROUP,
			"My Accounts": getLocalizedStrings().label.HEADER.MY_ACCOUNT,
			"Todays Accounts": getLocalizedStrings().label.HEADER.TODAYS_ACCCOUNTS,
			Contacts: getLocalizedStrings().label.HEADER.CONTACTS,
			"Contacts without Account":
				getLocalizedStrings().label.HEADER.CONTACTS_WITHOUT_ACCOUNT,
			"Contacts without Accounts":
				getLocalizedStrings().label.HEADER.CONTACTS_WITHOUT_ACCOUNTS,
			"Contacts without Groups":
				getLocalizedStrings().label.HEADER.CONTACTS_WITHOUT_GROUPS,
			"My Contacts": getLocalizedStrings().label.HEADER.MY_CONTACTS,
			"Accounts without Contact":
				getLocalizedStrings().label.FILTER["Accounts without Contact"],
			"Accounts without Contacts":
				getLocalizedStrings().label.FILTER["Accounts without Contacts"],
			"Todays Contacts": getLocalizedStrings().label.HEADER.TODAYS_CONTACTS,
			"All records": getLocalizedStrings().label.FILTER["All records"],
			"Added this Month":
				getLocalizedStrings().label.FILTER["Added this Month"],
			"Added this Week": getLocalizedStrings().label.FILTER["Added this Week"],
			"My Opportunities":
				getLocalizedStrings().label.FILTER["My Opportunities"],
			Opportunities: getLocalizedStrings().label.FILTER["Opportunities"],
			Cases: getLocalizedStrings().label.FILTER["Cases"],
			Projects: getLocalizedStrings().label.FILTER["Projects"],
			Issues: getLocalizedStrings().label.FILTER["Issues"],
		};
		if (filters !== null && filters !== undefined) {
			// filters.sort(function (a, b) {
			//     return a.name.localeCompare(b.name);
			// });
			// var uniq = new Set(filters.map(e => JSON.stringify(e)));
			// filters = Array.from(uniq).map(e => JSON.parse(e));
			if (object === constants.CASES_OBJECT) {
				filters = filters.filter((f) => {
					return (
						f.name !== "All Open Cases" &&
						f.name !== "All Closed Cases" &&
						f.name !== "My Open Cases" &&
						f.name !== "My Closed Cases"
					);
				});
			}
			sortArrayObjectByProperty(filters, "name");

			var results = [];
			let searchVal = searchText;
			
			if (searchVal !== undefined && searchVal !== "" && searchVal !== null) {
				for (var i = 0; i < filters.length; i++) {
					if (
						filters[i]["name"].toUpperCase().indexOf(searchVal.toUpperCase()) >
						-1
					) {
						results.push(filters[i]);
					}
				}
				
			} else {
				results = filters;
			}

			// setParentSearchResult(results, "searchResultFilter");
			filterItems = map(results, (filter, i) => {
				if (
					filter.type === "" ||
					filter.type === "all" ||
					filter.type === "query" ||
					filter.type === "filter"
				) {
					filterCount = ++i;
					let filterName = isValidParam(
						defaultMenuListMultiLingual[filter.name]
					)
						? defaultMenuListMultiLingual[filter.name]
						: filter.name;
					return (
						<div
							key={filter.id}
							className="filist"
							style={{ postion: "relative" }}
						>
							<div
								style={{
									overflow: "hidden",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									width: "82%",
									display: "inline-block",
								}}
							>
								<Link
									// to={linkToUrl + '/queries'}
									to={linkToUrl + "/" + view}
									style={{ cursor: "pointer" }}
									onClick={() => {
										action(filter);
										dispatch(removeScrollPosition(object));
										dispatch(removeColumnViewScrollPosition(object));
										dispatch(removeColumnViewHorizontalScrollPosition(object));
									}}
								>
									<span
										style={{
											color: "#ccc",
											fontSize: "14px",
											paddingLeft: "1px",
											paddingRight: "7px",
											float: "left",
											paddingTop: "10px",
										}}
									>
										<i className="fa fa-search" aria-hidden="true"></i>
									</span>
									<span className="filist"
										title={filterName}
										style={{
											fontSize: "14px",
											lineHeight: "32px",
											color: "#717171",
										}}
									>
										{filterName}
									</span>
								</Link>
							</div>

							{filter.type === "filter" && hasPermission && (
								<div
									className="sf-action-icons"
									style={{ display: "inline-block", verticalAlign: "top" }}
								>
									<div
										style={{ display: "inline-block", verticalAlign: "top" }}
									>
										<span
											style={{
												right: "62px",
												cursor: "pointer",
												paddingTop: "11px",
												position: "absolute",
												color: "#717171",
											}}
											onClick={() => showFilterDetails(filter)}
										>
											{/* <EditIcon className="sf-edit-icon" key={filter.id} style={{ 'fontSize': '0.9em' }} title={getLocalizedStrings().label.COMMON.EDIT}></EditIcon> */}
											<i
												key={filter.id}
												className={"fa fa-pencil anchor"}
												style={{ fontSize: "0.9em" }}
												title={getLocalizedStrings().label.COMMON.EDIT}
											></i>
										</span>
									</div>

									<div
										style={{ display: "inline-block", verticalAlign: "top" }}
									>
										<span
											style={{
												right: "42px",
												cursor: "pointer",
												paddingTop: "11px",
												position: "absolute",
												color: "#717171",
											}}
											onClick={() => coppyFilterDetails(filter)}
										>
											{/* <FileCopyIcon className="sf-copy-icon" key={filter.id} style={{ 'fontSize': '0.9em', fontWeight: 'normal', color: '#717171' }} title={getLocalizedStrings().label.COMMON.COPY}></FileCopyIcon> */}
											<i
												key={filter.id}
												className={"fa fa-clone anchor"}
												style={{ fontSize: "0.9em" }}
												title={getLocalizedStrings().label.COMMON.COPY}
											></i>
										</span>
									</div>

									<div
										style={{ display: "inline-block", verticalAlign: "top" }}
									>
										<span
											style={{
												right: "22px",
												cursor: "pointer",
												paddingTop: "11px",
												position: "absolute",
												color: "#717171",
											}}
											onClick={() => deleteFilterDetails(filter)}
										>
											{/* <CloseIcon  className="sf-close-icon" key={filter.id}  style={{ 'fontSize': '1.2em', fontWeight: 'normal', color: '#717171' }} title={getLocalizedStrings().label.COMMON.DELETE}></CloseIcon> */}
											<i
												key={filter.id}
												className={"fa fa-times anchor"}
												style={{ fontSize: "0.9em" }}
												title={getLocalizedStrings().label.COMMON.DELETE}
											></i>
										</span>
									</div>
								</div>
							)}
						</div>
					);
				}
			});
			return filterItems;
		}
	};

	return (
		<div>
			{mounted ? (
				<div style={{ marginLeft: "10px" }}>{generateFilternames(openTab)}</div>
			) : (
				<div style={{ width: "100%", height: contentHeight }}>
					<div
						className="asset-loaderh"
						style={{ paddingTop: top, paddingLeft: "40%" }}
					>
						<div
							style={{
								...styles.assetLoaderContainer,
								height: 50,
								width: 50,
								padding: 7,
							}}
						>
							<ShowCircularProgress
								size={30}
								style={{ marginTop: "3", marginLeft: "3" }}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ColumnFilter;

function processFilterData(response, name) {
	let aobj = response;
	var tcArr = aobj.hasOwnProperty("touch") ? aobj["touch"] : null;
	var bcArr = aobj.hasOwnProperty("broadcast") ? aobj["broadcast"] : null;
	var abArr = aobj.hasOwnProperty("ab") ? aobj["ab"] : null;

	if (
		(tcArr !== null && tcArr.length > 0) ||
		(bcArr !== null && bcArr.length > 0) ||
		(abArr !== null && abArr.length > 0)
	) {
		var tcName = "";
		var bcName = "";
		var abName = "";
		var messageTC = "";
		var messageBC = "";
		var messageAB = "";
		var message = "";
		let i = 0,
			j = 0,
			k = 0,
			l = 0;
		if (tcArr !== null && tcArr.length > 0) {
			for (j = 0; j < tcArr.length; j++) {
				if (tcName === "") {
					tcName = tcArr[j];
				} else {
					tcName = tcName + ", " + tcArr[j];
				}
			}
			messageTC = (
				<span>
					{" "}
					{getLocalizedStrings().message.HOME.FILTER_DELETE_MSG_TC}
					<br /> <b> {tcName}</b>
				</span>
			);
			tcName = "";
		}

		if (bcArr !== null && bcArr.length > 0) {
			for (k = 0; k < bcArr.length; k++) {
				if (bcName === "") {
					bcName = bcArr[k];
				} else {
					bcName = bcName + ", " + bcArr[k];
				}
			}
			messageBC = (
				<span>
					{" "}
					{getLocalizedStrings().message.HOME.FILTER_DELETE_MSG_BC} <br />{" "}
					<b>{bcName}</b>{" "}
				</span>
			);
			bcName = "";
		}
		if (abArr !== null && abArr.length > 0) {
			for (l = 0; l < abArr.length; l++) {
				if (abName === "") {
					abName = abArr[l];
				} else {
					abName = abName + ", " + abArr[l];
				}
			}
			messageAB = (
				<span>
					{getLocalizedStrings().message.HOME.FILTER_DELETE_MSG_AB}
					<br />
					<b>{abName}</b>
				</span>
			);
			abName = "";
		}

		if (
			typeof messageTC !== "undefined" &&
			messageTC !== "" &&
			typeof messageBC !== "undefined" &&
			messageBC !== "" &&
			typeof messageAB !== "undefined" &&
			messageAB !== ""
		) {
			message = (
				<span>
					{messageTC} <br /> <br />
					{getLocalizedStrings().message.HOME.FILTER_DELETE_MSG_AND}
					{messageBC}
					<br /> <br /> {" and "}
					{messageAB}
				</span>
			);
		} else if (
			messageTC !== null &&
			messageTC !== "" &&
			messageAB !== null &&
			messageAB !== ""
		) {
			message = (
				<span>
					{messageTC} <br /> <br />
					{getLocalizedStrings().message.HOME.FILTER_DELETE_MSG_AND} {messageAB}
				</span>
			);
		} else if (
			messageBC !== null &&
			messageBC !== "" &&
			messageAB !== null &&
			messageAB !== ""
		) {
			message = (
				<span>
					{messageBC}
					<br /> <br />{" "}
					{getLocalizedStrings().message.HOME.FILTER_DELETE_MSG_AND}
					{messageAB}
				</span>
			);
		} else if (
			messageBC !== null &&
			messageBC !== "" &&
			messageTC !== null &&
			messageTC !== ""
		) {
			message = (
				<span>
					{messageBC}
					<br /> <br />{" "}
					{getLocalizedStrings().message.HOME.FILTER_DELETE_MSG_AND}
					{messageTC}
				</span>
			);
		} else if (typeof messageBC !== "undefined" && messageBC !== "") {
			message = <span>{messageBC}</span>;
		} else if (typeof messageTC !== "undefined" && messageTC !== "") {
			message = <span>{messageTC} </span>;
		} else if (typeof messageAB !== "undefined" && messageAB !== "") {
			message = <span>{messageAB}</span>;
		}
		return (
			<span>
				{name + getLocalizedStrings().message.HOME.FILTER_DELETE_MSG_BEING_USED}
				{message}
			</span>
		);
	} else {
		return "";
	}
}


const dynamicProcessFilterData = (response, name) => {

	const requiredcampaigns = Object.keys(response).map(ele => ({ bbname: ele, campaigns: response[ele].join(' , ') }));

	if (requiredcampaigns.length > 0) {
		return (
			<span style={{ display: 'flex', flexDirection: 'column' }}>
				{name + getLocalizedStrings().message.GROUP.COMMON_DELETE_MSG}
				<span style={{ marginTop: '0.5rem' }}></span>
				{requiredcampaigns.map(({ bbname, campaigns }, index) => {
					return (
						<span key={index}>
							{bbname.substring(0, 1).toUpperCase() + bbname.substring(1)}- <b>{campaigns}</b>
						</span>
					)
				})}

			</span>
		);
	} else {
		return '';
	}

}


