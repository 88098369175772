import React, { useState, useEffect } from 'react';
import { isValidParam, getObjectParam, getStringParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import { constants } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import Button from '@mui/material/Button';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import ShowCircularProgress from '../components/circularProgress';
import { useDispatch, useSelector } from "react-redux";
import DualListBox from 'react-dual-listbox';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { getSFFormFieldsPromise } from '../../../services/actions/sfFormActions';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import '../../../asset/styles/css/react-dual-listbox.css';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField, Chip, FormControl } from '@mui/material';
import { getArrayParam } from '../../../services/helper/parameterVerifier';
import { InputLabel, MenuItem, Select } from '@mui/material';

const RoyaltySetupSetting = ({ linkRecord, object, id, updateLinkInfo }) => {
    const dispatch = useDispatch();
    const sfForm = useSelector((state) => state.sfForm);
    const app = useSelector((state) => state.app);
    const [isMounted, setIsMounted] = useState(false);
    const [setupId, setId] = useState(0);
    const [parentOptionList, setParentOptionList] = useState([]);
    const [selectedOptionsList, setSelectedOptionsList] = useState([]);
    const [parentPostOptionList, setParentPostOptionList] = useState([]);
    const [selectedPostOptionsList, setSelectedPostOptionsList] = useState([]);
    const [isInvoiceAllChecked, setIsInvoiceAllChecked] = useState(true);
    const [isMarkAllChecked, setIsMarkAllChecked] = useState(true);
    const [isEmailAllChecked, setIsEmailAllChecked] = useState(true);
    const [isPostAllChecked, setIsPostAllChecked] = useState(true);
    const [termList, setTermsList] = useState([])
    const [termsValue, setTermsValue] = useState('')
    const [markAllStatus, setMarkAllStatus] = useState('')
    const [markAllPaymentType, setMarkAllPaymentType] = useState('')
    const [paymentList, setPaymentList] = useState([])

    useEffect(() => {
        try {
            if (isValidParam(object)) {
                setIsMounted(false);
                let promise = getSFFormFieldsPromise(constants.REPORTED_SALES_OBJECT);
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            sfForm.data[constants.REPORTED_SALES_OBJECT] = response;
                            const parentFields = processObjectFields(response.fields || []);
                            getReportConfig(parentFields)
                        }
                    });
                }
            } else {
                setParentOptionList([]);
                setSelectedOptionsList([]);
            }
        } catch (error) {
            console.error('Error in exportSettings.js -> updateParentObjectFields() : ' + error);
        }
    }, []);

    useEffect(() => {
        try {
            if (isValidParam(object)) {
                setIsMounted(false);
                let promise = getSFFormFieldsPromise(constants.PAYMENTS_OBJECT);
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                           let paymentTypeField = response?.fields.find(field => field.name === 'payment_type');
                            let _paymentList = paymentTypeField?.list_values
                            setPaymentList(_paymentList)
                        }
                    });
                }
            }
        } catch (error) {
            console.error('Error in exportSettings.js -> updateParentObjectFields() : ' + error);
        }
    }, []);

    useEffect(() => {
        let promise = Promise.resolve(HTTPClient.get(endPoints.ROYALTY_SETUP.GET));

        promise.then((response) => {
            if (isValidParam(response)) {
                response = response.filter((f)=>{
                    return f.name != 'Custom';
                })
                let _termsList = getArrayParam(response).map((obj) => { return { value: obj.name, label: obj.name, key: obj.days } });
                setTermsList(_termsList);
            }
        })
    }, [])
    const handleOnChanceTerms = (event, name, type, objValue) => {
        let value = isValidParam(event) && isValidParam(event.currentTarget) ? event.currentTarget.value : '';
        if (name === 'terms') {
            value = objValue?.value;
            setTermsValue(value)

        }
    }

    const processObjectFields = (fields) => {
        let objectFields = [];
        if (fields.length > 0) {
            objectFields = fields
                .filter(item => item.t_tablefldid !== -1 && item.name !== "workflow_id" && item.is_hidden_field === false)
                .map(item => ({ label: item.label, value: item.name, title: item.label, name: item.name }));
        }
        sortArrayObjectByProperty(objectFields, 'label');
        return objectFields;
    }

    const processPostObjectFields = (fields) => {
        let objectFields = [];
        if (fields && fields.length > 0) {
            objectFields = fields
                .filter(item => item.name !== "created_on" && item.name !== "updated_on" &&
                    item.name !== "updated_by" && item.name !== "t_creater" && item.name !== "end_date" &&
                    item.name !== "start_date" && item.name !== 'reported_by' && item.name !== 'source' &&
                    item.name !== 't_status' && item.name !== 'submit_by' && item.name !== 'submit_date' && item.name !== 'unit')
        }
        sortArrayObjectByProperty(objectFields, 'label');
        return objectFields;
    }


    const getReportConfig = (parentFields) => {
        try {
            let promise = Promise.resolve(HTTPClient.get(endPoints.ROYALTY_REPORT.GET_REPORT_CONFIG));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let configObj = getObjectParam(response);
                    let availableParentFields = parentFields;
                    let availablePostParentFields = processPostObjectFields(parentFields);
                    let selectedReportFields = configObj?.report_fields;
                    let selectedPostFields = configObj?.post_fields
                    let invoiceAll = configObj?.invoice_all;
                    let emailAll = configObj?.email_all;
                    let postAll = configObj?.post_all;
                    let selectedReportFieldsData = [];
                    let selectedPostFieldsData = [];
                    let markPaidAll = configObj?.markpaid_all;
                    // computation of report_fields data
                    if (configObj.report_fields && configObj.report_fields.length > 0) {
                        selectedReportFields = configObj.report_fields.split(',');
                    }
                    const selectedReportFilterFields = availableParentFields.filter((item) => {
                        let selectedFieldObject;
                        if (selectedReportFields && selectedReportFields.length > 0) {
                            selectedFieldObject = selectedReportFields.find((item1) => {
                                return item1 === item.name;
                            })
                        }
                        return selectedFieldObject;
                    })
                    if (selectedReportFields && selectedReportFields.length > 0) {
                        selectedReportFields.map(item => {
                            if (selectedReportFilterFields && selectedReportFilterFields.length > 0) {
                                selectedReportFilterFields.filter((item1) => {
                                    if (item === item1.name) {
                                        selectedReportFieldsData.push(item1);
                                    }
                                    return item1;
                                })
                            }
                            return selectedReportFieldsData;
                        })
                    }

                    // computation of post_fields data
                    if (configObj.post_fields && configObj.post_fields.length > 0) {
                        selectedPostFields = configObj.post_fields.split(',');
                    }
                    const selectedPostFilterFields = availablePostParentFields.filter((item) => {
                        let selectedFieldObject;
                        if (selectedPostFields && selectedPostFields.length > 0) {
                            selectedFieldObject = selectedPostFields.find((item1) => {
                                return item1 === item.name;
                            })
                        }
                        return selectedFieldObject;
                    });
                    if (selectedPostFields && selectedPostFields.length > 0) {
                        selectedPostFields.map(item => {
                            if (selectedPostFilterFields && selectedPostFilterFields.length > 0) {
                                selectedPostFilterFields.filter((item1) => {
                                    if (item === item1.name) {
                                        selectedPostFieldsData.push(item1);
                                    }
                                    return item1;
                                })
                            }
                            return selectedPostFieldsData;
                        })
                    }

                    // set report_fields data
                    setParentOptionList(availableParentFields);
                    setSelectedOptionsList(selectedReportFieldsData);

                    // set post_fields data
                    setParentPostOptionList(availablePostParentFields);
                    setSelectedPostOptionsList((selectedPostFields === undefined || selectedPostFields === null || selectedPostFields === '') ? availablePostParentFields : selectedPostFieldsData);

                    // set Actions-fields data
                    setIsInvoiceAllChecked((invoiceAll === undefined || invoiceAll === 1 || invoiceAll === -1) ? true : false);
                    setIsMarkAllChecked((markPaidAll === undefined || markPaidAll === 1 || markPaidAll === -1) ? true : false);
                    setIsEmailAllChecked((emailAll === undefined || emailAll === 1 || emailAll === -1) ? true : false);
                    setIsPostAllChecked((postAll === undefined || postAll === 1 || postAll === -1) ? true : false);
                    setId(configObj.id || setupId);
                    console.log("configObj",configObj)
                    if(isValidParam(configObj?.invoice_setup )){
                        let newString ;
                        if (typeof configObj.invoice_setup == 'string' &&  configObj.invoice_setup !== ''){
                         newString = JSON.parse(configObj?.invoice_setup);
                        }else{
                             newString = JSON.parse(JSON.stringify(configObj?.invoice_setup));
                        }
            
                        newString = newString?.defaultTerm;
                        setTermsValue(newString)
                    }
                    if(isValidParam(configObj?.markpaid_setup )){
                        let newStringMarkPaid;
                        if (typeof configObj.markpaid_setup == 'string' && configObj.invoice_setup !== ''){
                            newStringMarkPaid = JSON.parse(configObj?.markpaid_setup)
                           }else{
                            newStringMarkPaid = JSON.parse(JSON.stringify(configObj?.markpaid_setup));
   
                           }
                    setMarkAllPaymentType(newStringMarkPaid?.paymentType);
                    setMarkAllStatus(newStringMarkPaid?.status)
                    }
                 
                    setIsMounted(true);
                }

            });
        } catch (error) {
            console.error("Error in 'royaltySetup.js -> getReportConfig()'" + error);
        }
    }

    const onSave = () => {
        try {
            const selectedReportList = selectedOptionsList.map((option) => {
                return option.value;
            })
            const selectedPostList = selectedPostOptionsList.map((option) => {
                return option.value;
            })
            let reportParams = {};
            reportParams.report_fields = selectedReportList.toString();
            //reportParams.post_fields = app.me.industry_type !== constants.UNITS_BASE ? selectedPostList.toString() : "";
            reportParams.post_fields = "";
            reportParams.invoice_all = app.me.industry_type !== constants.UNITS_BASE ? isInvoiceAllChecked ? 1 : 0 : -1;
            reportParams.email_all = app.me.industry_type !== constants.UNITS_BASE ? isEmailAllChecked ? 1 : 0 : -1;
            reportParams.post_all = app.me.industry_type !== constants.UNITS_BASE ? isPostAllChecked ? 1 : 0 : -1;
            reportParams.markpaid_all = app.me.industry_type !== constants.UNITS_BASE ? isMarkAllChecked ? 1 : 0 : -1;
            reportParams.id = setupId;
            reportParams.invoice_setup = { "defaultTerm": termsValue != '' ?  termsValue :''}
            reportParams.markpaid_setup = { "status": markAllStatus != '' ?  markAllStatus :'Full', "paymentType": markAllPaymentType != '' ?  markAllPaymentType :'ACH' }
            

            let promise = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_REPORT.SAVE_REPORT_SETUP, reportParams));
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.status === 0) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.ROYALTY_REPORT.SETUP_SAVE_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop))
                    }
                }
            });
        } catch (error) {
            console.error("Error in 'RoyaltyReportSetup.js -> saveReport()'" + error);
        }
    }

    const handleInputChange = (isChecked, name) => {
        if (name === "invoiceAll") {
            setIsInvoiceAllChecked(isChecked);
        } else if (name === "emailAll") {
            setIsEmailAllChecked(isChecked);
        } else if (name === "postAll") {
            setIsPostAllChecked(isChecked);
        }
        else if (name === "markPaidAll") {
            setIsMarkAllChecked(isChecked);
        }
    }

    const handleStatusPaymentTypeChange = (event, field) => {
        let val = event.target.value;
        if (field == 'status') {
            setMarkAllStatus(val);
        } else {
            setMarkAllPaymentType(val)
        }
    }
    let paymentArr = paymentList?.map((r, index) => {
        let label = r.value;
        return <MenuItem key={r.value} value={r.value} style={styles.popoverMenuItem} >{<div title={label} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }}>{label}</div>}</MenuItem>
    });
    console.log("markAllStatus", markAllStatus)
    console.log("markAllPaymentType", markAllPaymentType)

    if (isMounted) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                    <div className='sf-RoyaltySetup-Container'>
                        <div className='sf-RoyaltySetup-Header' style={{ ...styles.row, paddingLeft: '26px' }}>
                            <div style={{ ...styles.sf_12 }}>
                                <span style={{ fontSize: '24px', paddingLeft: '2px' }}> {getLocalizedStrings().label.COMMON.ROYALTY_SETUP} </span>
                            </div>
                            {/* Save Button */}
                            <div style={{ marginBottom: '5px', float: 'right', marginRight: '6px' }}>
                                <div style={{ marginTop: '10px', float: 'right' }}>
                                    <Button
                                        key='save'
                                        onClick={onSave}
                                        style={{ ...styles.primaryButton, verticalAlign: 'top' }}
                                    >
                                        {getLocalizedStrings().label.COMMON.SAVE}
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <br />

                        {app.me.industry_type !== constants.UNITS_BASE && (<>
                            {/* Actions */}
                            <div style={{ ...styles.sf_12, ...styles.row, marginTop: '5px', paddingLeft: '26px' }}>
                                <span style={{ fontSize: '18px', paddingLeft: '2px', fontWeight: 'bold' }}> {getLocalizedStrings().label.COMMON.ACTION} </span>
                            </div>
                            <Box sx={{ display: 'flex', flexDirection: 'row', ml: 2 }}>
                                <div style={{ ...styles.sf_3, paddingLeft: '12px' }}>
                                    <FormControlLabel label={getLocalizedStrings().label.COMMON.INVOICE_ALL} control={<Checkbox color="default" checked={isInvoiceAllChecked} value={isInvoiceAllChecked} name="invoiceAll" onChange={(e) => handleInputChange(e.target.checked, "invoiceAll")} defaultChecked={true} />} style={{}} />
                                </div>
                                <div style={{ ...styles.sf_3, paddingLeft: '12px' }}>
                                    <FormControlLabel label='Mark Paid All ' control={<Checkbox color="default" checked={isMarkAllChecked} value={isMarkAllChecked} name="markPaidAll" onChange={(e) => handleInputChange(e.target.checked, "markPaidAll")} defaultChecked={true} />} style={{}} />
                                </div>
                                <div style={{ ...styles.sf_3, paddingLeft: '26px' }}>
                                    <FormControlLabel label={getLocalizedStrings().label.COMMON.EMAIL_ALL} control={<Checkbox color="default" checked={isEmailAllChecked} value={isEmailAllChecked} name="emailAll" onChange={(e) => handleInputChange(e.target.checked, "emailAll")} defaultChecked={false} />} style={{}} />
                                </div>
                                <div style={{ ...styles.sf_3, paddingLeft: '26px' }}>
                                    <FormControlLabel label={getLocalizedStrings().label.COMMON.POST_ALL} control={<Checkbox color="default" checked={isPostAllChecked} value={isPostAllChecked} name="postAll" onChange={(e) => handleInputChange(e.target.checked, "postAll")} defaultChecked={false} />} style={{}} />
                                </div>
                            </Box>

                            <br />

                            <Box sx={{ display: 'flex', flexDirection: 'row', ml: 2 }}>
                                <div style={{ ...styles.sf_3, paddingLeft: '12px' }}>
                                    {isInvoiceAllChecked &&
                                        <div>
                                            <FormControl style={{ width: '80%' }} className="test" noValidate autoComplete="off">
                                                <Autocomplete
                                                    name='terms'
                                                    key={`terms-key-list`}
                                                    defaultValue={termList.find(v => v.value == termsValue)}
                                                    options={termList}
                                                    onChange={(e, event) => handleOnChanceTerms(e, 'terms', '', event)}
                                                    getOptionLabel={(option) => option.label}
                                                    disableClearable={false}
                                                    renderInput={(params) => <TextField {...params}
                                                        label={"Default payment terms"} variant="outlined" margin="dense" size="small" />
                                                    }
                                                />
                                            </FormControl>
                                        </div>}
                                </div>
                                <div style={{ ...styles.sf_3, paddingLeft: '12px', marginTop:'10px' }}>
                                    {isMarkAllChecked &&
                                        <FormControl variant="outlined" style={{ width: '100%' }}>
                                            <InputLabel id="sf-leadscoresetup-simple-select-outlined-label"
                                               className="sf-add-units-plan-name" style={{ marginTop: '-7px' }}>{getLocalizedStrings().label.COMMON.STATUS}</InputLabel>
                                            <Select
                                                key="status"
                                                label={getLocalizedStrings().label.COMMON.STATUS}
                                                // labelId='sf-leadscore-status'
                                                id='status'
                                                value={markAllStatus == '' ? 'Full' : markAllStatus}
                                                onChange={(e) => handleStatusPaymentTypeChange(e, "status")}
                                                name='status'
                                                style={{ height: "42px" }}
                                                className={"sf-fields-bg"}
                                            >
                                                <MenuItem value={'Full'}>Full</MenuItem>
                                                <MenuItem value={'Partial'}>Partial</MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                    {isMarkAllChecked &&
                                        <FormControl variant="outlined" style={{ width: '100%', marginTop: '15px' }}>
                                            <InputLabel id="sf-leadscoresetup-simple-select-outlined-label"
                                                className="sf-add-units-plan-name" style={{ marginTop: '-7px' }} >Payment Type</InputLabel>
                                            <Select
                                                key="payment type"
                                                label='Payment Type'
                                                // labelId='sf-leadscore-status'
                                                id='payment'
                                                value={markAllPaymentType == '' ? 'ACH':markAllPaymentType }
                                                onChange={(e) => handleStatusPaymentTypeChange(e, "paymentType")}
                                                name='status'
                                                style={{ height: "42px" }}
                                                className={"sf-fields-bg"}
                                            >
                                                {paymentArr}
                                            </Select>
                                        </FormControl>
                                    }
                                </div>
                                <div style={{ ...styles.sf_3, paddingLeft: '12px' }}>

                                </div>
                            </Box>

                            <br />


                            {/* Post Column */}
                            {/* <div style={{ ...styles.sf_12, ...styles.row, marginTop: '10px', paddingLeft: '26px' }}>
                            <span style={{ fontSize: '18px', paddingLeft: '2px', fontWeight: 'bold' }}> Post Fields: </span>
                        </div> */}
                            {/* <div className='sf-RoyaltySetup-FieldArea' style={{ marginTop:'5px', height: '35%', paddingLeft: '15px' }}>
                            <div className="section">
                                <div className='row'>
                                    <div style={{...styles.sf_6, paddingLeft: '15px', fontWeight:'500'}}>
                                        <label>{getLocalizedStrings().label.ROYALTY_REPORT.AVAILABLE_FIELDS}</label>
                                    </div>
                                    <div style={{...styles.sf_6, fontWeight:'500'}}>
                                        <label style={{paddingTop:'24px'}}>{getLocalizedStrings().label.ROYALTY_REPORT.SELECTED_FIELDS}</label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12' style={{marginTop: '8px'}}>
                                        <div>
                                            <DualListBox
                                                id="parentFlds"
                                                options={parentPostOptionList}
                                                selected={selectedPostOptionsList}
                                                onChange={(selectedValues) => {
                                                    setSelectedPostOptionsList(selectedValues);
                                                }}
                                                simpleValue={false}
                                                preserveSelectOrder={true}
                                                showOrderButtons={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  */}

                        </>)}

                        {/* <Divider light sx={{ mt:3, ml:1, border: '1px solid #D3D3D3' }} /> */}

                        {/* Report Column */}
                        <div style={{ ...styles.sf_12, ...styles.row, marginTop: '10px', paddingLeft: '26px' }}>
                            <span style={{ fontSize: '18px', paddingLeft: '2px', fontWeight: 'bold' }}> {getLocalizedStrings().label.COMMON.REPORT_FIELDS}</span>
                        </div>
                        <div className='sf-RoyaltySetup-FieldArea' style={{ marginTop: '5px', height: '35%', paddingLeft: '15px' }}>
                            <div className="section">
                                <div className='row'>
                                    <div style={{ ...styles.sf_6, paddingLeft: '15px', fontWeight: '500' }}>
                                        <label>{getLocalizedStrings().label.ROYALTY_REPORT.AVAILABLE_FIELDS}</label>
                                    </div>
                                    <div style={{ ...styles.sf_6, fontWeight: '500' }}>
                                        <label style={{ paddingTop: '24px' }}>{getLocalizedStrings().label.ROYALTY_REPORT.SELECTED_FIELDS}</label>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-12' style={{ marginTop: '8px' }}>
                                        <div>
                                            <DualListBox
                                                id="parentFlds"
                                                options={parentOptionList}
                                                selected={selectedOptionsList}
                                                onChange={(selectedValues) => {
                                                    setSelectedOptionsList(selectedValues);
                                                }}
                                                simpleValue={false}
                                                preserveSelectOrder={true}
                                                showOrderButtons={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Save Button */}
                <div style={{ marginBottom: '5px', float: 'right', marginRight: '14px' }}>
                    <div style={{ marginTop: '10px', float: 'right' }}>
                        <Button
                            key='save'
                            onClick={onSave}
                            style={{ ...styles.primaryButton, verticalAlign: 'top' }}
                        >
                            {getLocalizedStrings().label.COMMON.SAVE}
                        </Button>
                    </div>
                </div>
            </div>
        )
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }

}

export default RoyaltySetupSetting;