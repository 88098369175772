import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';


function getEventbriteUserInfo() {
    let url = endPoints.EVENTBRITE_INTEGRATION.USER_INFO_GET;
    try {
        return HTTPClient.get(url, null);
    } catch (e) { console.error("Error in 'eventbriteAction.js -> getEventbriteUserInfo()':" + e); }
}

function disconnectEventbrite() {
    let url = endPoints.EVENTBRITE_INTEGRATION.DISCONNECT;
    try {
        return HTTPClient.post(url, null);
    } catch (e) { console.error("Error in 'eventbriteAction.js -> disconnectEventbrite()':" + e); }
}

function getUserOrganisations(param) {
    let url = endPoints.EVENTBRITE_INTEGRATION.USER_ORGANISATIONS;
    try {
        return HTTPClient.get(url, param);
    } catch (e) { console.error("Error in 'eventbriteAction.js -> getUserOrganisations()':" + e); }
}

function updateEventbriteDetails(params) {
    let url = endPoints.EVENTBRITE_INTEGRATION.UPDATE_DETAILS;
    try {
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'quickBooksAction.js -> updateQuickBooksDetails()':" + e); }
}


export {
    getEventbriteUserInfo,
    disconnectEventbrite,
    getUserOrganisations,
    updateEventbriteDetails
}