import store from '../../../src/services/store';
import { constants } from '..//constants/constants';
import { endPoints } from '..//constants/endPoints';
import { actions } from '..//constants/actions';
import * as HTTPClient from '..//helper/httpClient';
import { isValidParam , getObjectParam, getStringParam, getBooleanParam, getIntParam, getArrayParam} from '..//helper/parameterVerifier';
import { addTab, getActiveTab, TYPE_DETAIL_VIEW } from '..//helper/sfTabManager';
import filter from 'lodash/filter';

function setQueueListData(data) {
    return {
        type: actions.QUEUE_RECORDS_GET_FULFILLED,
        payload: data
    };
}

function getQueueList(object, params){
    try {
        let url = endPoints.COMMON.GET_QUEUE_LIST;
        return HTTPClient.get(url, params);
    } catch (e) { 
        console.error("Error in 'queueActions.js -> getQueueList()':" + e); 
    }
}

function loadQueueList(data){

    if(isValidParam(data)){
        store.dispatch(setQueueListData(data));
    }
}


function openDetailviewQueueTab(objectName, label, recordId, index, totalCount){
    try {
        let activeTab = getObjectParam(getActiveTab());
        let info = getObjectParam(activeTab.info);

        label = getStringParam(label);
        label = label=== ''? 'EMPTY': label;
        let view = getStringParam(constants.DETAIL_VIEW);
        view = view.toLowerCase();
        let  linkUrl = '/' + objectName + "/"+view+"/" +  recordId;

        let activeTabIndex = getIntParam(activeTab.index);
        let isInXpress = getBooleanParam(info.isInXpress);

        let tab = { label: label, object: objectName, type: TYPE_DETAIL_VIEW, imgName: null, url: linkUrl, isActive: true, parentTabIndex: activeTabIndex };
        if (isInXpress) {
            info = { isInXpress: isInXpress, inXpressProjectId: getIntParam(info.inXpressProjectId) };
        }
        let queueData = {};
        queueData.is_callFrom_queue = true;
        queueData.record_index = index;
        queueData.total_count = totalCount;
        info = Object.assign(info, queueData);
        tab.info = info;
        addTab(tab, true);
    } catch (error) {
        console.error("Error in 'queueActions.js -> openRecordInQueue()':" + error); 
    }
}

function removeRecordFromRedux(object, recordId, totalCount) {
    try {
        if (isValidParam(recordId) && recordId > 0) {
            let state = store.getState();

            let queueList = state.queueList;
            let data = getObjectParam(queueList.data);
            let records = data.records;

            let newRecords = filter(records, (item) => { return item.id !== parseInt(recordId) });
            let mappedRecords = newRecords.map((obj, index)=> { return {id: obj.id, idx: index, title: getStringParam(obj.title),linkInfo:obj.linkInfo}});

            data.records = getArrayParam(mappedRecords);
            data.total_count = totalCount;
            queueList = data;            

            store.dispatch(setQueueListData(queueList));
        }
    } catch (error) {
        console.error("Error in 'queueActions.js ---> removeRecordFromRedux()':" + error);
    }
}

function removeAcoountRecordInQueueFromRedux(object, recordId, totalCount, linkInfo) {
    try {
        if (isValidParam(recordId) && recordId > 0) {
            let state = store.getState();

            let queueList = state.queueList;
            let data = getObjectParam(queueList.data);
            let records = data.records;

            let newRecords = filter(records, (item) => { return item.linkInfo !== linkInfo });
            let mappedRecords = newRecords.map((obj, index)=> { return {id: obj.id, idx: index, title: getStringParam(obj.title),linkInfo:obj.linkInfo}});

            data.records = getArrayParam(mappedRecords);
            data.total_count = totalCount;
            queueList = data;            

            store.dispatch(setQueueListData(queueList));
        }
    } catch (error) {
        console.error("Error in 'queueActions.js ---> removeRecordFromRedux()':" + error);
    }
}

function removeFollowingRecRecordFromRedux(object, recordId,followingRecid, totalCount) {
    try {
        if (isValidParam(recordId) && recordId > 0) {
            let state = store.getState();

            let queueList = state.queueList;
            let data = getObjectParam(queueList.data);
            let records = data.records;

            let newRecords = filter(records, (item) => { return item.id !== parseInt(recordId) });
             newRecords = filter(newRecords, (item) => { return item.id !== parseInt(followingRecid) });

            let mappedRecords = newRecords.map((obj, index)=> { return {id: obj.id, idx: index, title: getStringParam(obj.title),linkInfo:obj.linkInfo}});

            data.records = getArrayParam(mappedRecords);
            data.total_count = totalCount;
            queueList = data;            

            store.dispatch(setQueueListData(queueList));
        }
    } catch (error) {
        console.error("Error in 'queueActions.js ---> removeRecordFromRedux()':" + error);
    }
}
function removeRecurringRecordFromRedux(object, title, totalCount) {
    try {
        if (isValidParam(title)) {
            let state = store.getState();

            let queueList = state.queueList;
            let data = getObjectParam(queueList.data);
            let records = data.records;

            let newRecords = filter(records, (item) => { return item.title !== title});
            let mappedRecords = newRecords.map((obj, index)=> { return {id: obj.id, idx: index, title: getStringParam(obj.title),linkInfo:obj.linkInfo}});

            data.records = getArrayParam(mappedRecords);
            data.total_count = totalCount;
            queueList = data;            

            store.dispatch(setQueueListData(queueList));
        }
    } catch (error) {
        console.error("Error in 'queueActions.js ---> removeRecurringRecordFromRedux()':" + error);
    }
}
   
 export {
    loadQueueList,
    getQueueList,
    openDetailviewQueueTab,
    removeRecordFromRedux,
    removeRecurringRecordFromRedux,
    removeFollowingRecRecordFromRedux,
    removeAcoountRecordInQueueFromRedux
}