import { endPoints } from "..//constants/endPoints";
import { actions } from "..//constants/actions";
import * as HTTPClient from "..//helper/httpClient";

function getNoteList(projectId, parentObject, parentRecordId) {
	let url = null;
	let params = null;
	let searchArr = [];
	let searchObj = null;
	searchObj = {
		t_projectid: projectId,
	};
	searchArr.push(searchObj);

	let arrFields = [];
	arrFields.push("author");
	arrFields.push("note_text");
	arrFields.push("note_type");
	arrFields.push("updated_on");

	params = {
		search: searchArr,
		parent_object: parentObject,
		parent_record_id: parentRecordId,
		fields: arrFields,
	};
	url = endPoints.NOTES.LIST;

	return {
		type: actions.NOTES_GET,
		payload: HTTPClient.get(url, params),
	};
}

function updateNoteTypeListAll(noteTypes) {
	try {
		return { type: actions.NOTES_TYPE_GET_ALL, payload: noteTypes };
	} catch (e) {
		console.error("Error in 'noteAction.js -> NOTES_TYPE_GET_ALL':" + e);
	}
}
function updateSelectedNoteTypeList(selectedNoteTypes) {
	try {
		return {
			type: actions.UPDATE_SELECTED_NOTES_TYPES,
			payload: selectedNoteTypes,
		};
	} catch (e) {
		console.error("Error in 'noteAction.js -> NOTES_TYPE_GET_ALL':" + e);
	}
}
function updateChildNoteFunc (noteRecord){
	try {
		return {
			type: actions.UPDATE_PINNED_NOTES_VALUE,
			payload: noteRecord,
		};
	} catch (e) {
		console.error("Error in 'noteAction.js -> NOTES_TYPE_GET_ALL':" + e);
	}
}
function updateisAllSelected (isAllSelected){
	try {
		return {
			type: actions.UPDATE_IS_ALL_SELECTED,
			payload: isAllSelected,
		};
	} catch (e) {
		console.error("Error in 'noteAction.js -> NOTES_TYPE_GET_ALL':" + e);
	}
}
function updatePinnedNotesSelectedField (noteRecord){
	try {
		return {
			type: actions.UPDATE_NOTES_RECORD_TYPES,
			payload: noteRecord,
		};
	} catch (e) {
		console.error("Error in 'noteAction.js -> NOTES_TYPE_GET_ALL':" + e);
	}
}

function getNoteTypeList() {
	let url = null;
	let params = null;

	let arrFields = [];
	arrFields.push("t_pk");
	arrFields.push("t_note_type");
	arrFields.push("t_system_type");

	params = {
		fields: arrFields,
		query_name: "Note Types",
	};
	url = endPoints.NOTES.NOTE_TYPE_GET;

	return {
		type: actions.NOTES_TYPE_GET,
		payload: HTTPClient.get(url, params),
	};
}

export {
	getNoteList,
	getNoteTypeList,
	updateNoteTypeListAll,
	updateSelectedNoteTypeList,
	updateChildNoteFunc,
	updateisAllSelected,
	updatePinnedNotesSelectedField,
};
