import React, { useState, useEffect }  from 'react';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { getObjectParam } from '../../../services/helper/parameterVerifier';
import { timeZoneMap } from '../../../services/helper/utils';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import * as sfDialogs from '../components/sfDialogs';
import { useDispatch, useSelector } from "react-redux";



    const SchedulerAvailability = ({setAvailabilityInfoData,availabilityInfoData,errorFlag,isIntegration}) =>{
        const duration = [{ key: '1 AM', label: '1 AM' },
        { key: '2 AM', label: '2 AM' },
        { key: '3 AM', label: '3 AM' },
        { key: '4 AM', label: '4 AM' },
        { key: '5 AM', label: '5 AM' },
        { key: '6 AM', label: '6 AM' },
        { key: '7 AM', label: '7 AM' },
        { key: '8 AM', label: '8 AM' },
        { key: '9 AM', label: '9 AM' },
        { key: '10 AM', label: '10 AM' },
        { key: '11 AM', label: '11 AM' },
        { key: '12 PM', label: '12 PM' },
        { key: '13 PM', label: '1 PM' },
        { key: '14 PM', label: '2 PM' },
        { key: '15 PM', label: '3 PM' },
        { key: '16 PM', label: '4 PM' },
        { key: '17 PM', label: '5 PM' },
        { key: '18 PM', label: '6 PM' },
        { key: '19 PM', label: '7 PM' },
        { key: '20 PM', label: '8 PM' },
        { key: '21 PM', label: '9 PM' },
        { key: '22 PM', label: '10 PM' },
        { key: '23 PM', label: '11 PM' },
        { key: '00 AM', label: '12 AM' },
        { key: '-1', label: 'None' },
        
        ];

        const [days, setDays] = useState([{ key: 1, label: 'Monday' },
        { key: 2, label: 'Tuesday' },
        { key: 3, label: 'Wednesday' },
        { key: 4, label: 'Thursday' },
        { key: 5, label: 'Friday' },
        { key: 6, label: 'Saturday' },
        { key: 7, label: 'Sunday' }]);
        
        const dispatch = useDispatch();

        const [availabilityInfo, setAvailabilityInfo] = useState({});
        const [defaultStartTime, setDefaultStartTime] = useState(8);

        const [defaultEndTime, setDefaultEndTime] = useState(17);
        const [defaultStartTimeMeridiem, setDefaultStartTimeMeridiem] = useState('AM');
        const [defaultEndTimeMeridiem, setDefaultEndTimeMeridiem] = useState('PM');
        const [defaultWeekendTime, setDefaultWeekendTime] = useState('-1');
        let app = useSelector((state) => state.app);

        useEffect(() => {
        let availabilityInfo = getObjectParam(availabilityInfoData);
        if (Object.keys(availabilityInfo).length > 1) {
            setAvailabilityInfo(availabilityInfo);
        } else {
            availabilityInfo = availabilityInfo;
        }

        let obj = null;
        let dayInfo = null;
        days.map((d, index) => {
            obj = {};
            obj.day_id = d.key;

            if (availabilityInfo.hasOwnProperty(d.key)) {
                dayInfo = getObjectParam(availabilityInfo[d.key]);

                if (dayInfo.hasOwnProperty('startTime')) {
                    obj.startTime = dayInfo.startTime
                } else {
                    if (dayInfo.start_time === -1) {
                        obj.startTime = "-1";
                    } else {
                        if (dayInfo.start_time === 0) {
                            dayInfo.start_time = "00";
                        }
                        obj.startTime = dayInfo.start_time + " " + dayInfo.start_time_meridiem;
                    }
                }

                if (dayInfo.hasOwnProperty('endTime')) {
                    obj.endTime = dayInfo.endTime
                } else {
                    if (dayInfo.end_time === -1) {
                        obj.endTime = "-1";
                    } else {
                        if (dayInfo.end_time === 0) {
                            dayInfo.end_time = "00";
                        }
                        obj.endTime = dayInfo.end_time + " " + dayInfo.end_time_meridiem;
                    }
                }

                obj.start_time = dayInfo.start_time;
                obj.start_time_meridiem = dayInfo.start_time_meridiem;
                obj.end_time = dayInfo.end_time;
                obj.end_time_meridiem = dayInfo.end_time_meridiem;



                dayInfo = null;

            } else {
                if (d.key === 6 || d.key === 7) {

                    obj.startTime = defaultWeekendTime
                    obj.start_time = -1;
                    obj.start_time_meridiem = defaultWeekendTime;
                    obj.endTime = defaultWeekendTime;
                    obj.end_time = -1;
                    obj.end_time_meridiem = defaultWeekendTime;


                } else {
                    obj.startTime = defaultStartTime + ' ' + defaultStartTimeMeridiem;
                    obj.start_time = defaultStartTime;
                    obj.start_time_meridiem = defaultStartTimeMeridiem;
                    obj.endTime = defaultEndTime + ' ' + defaultEndTimeMeridiem;
                    obj.end_time = defaultEndTime;
                    obj.end_time_meridiem = defaultEndTimeMeridiem;
                }
            }

            availabilityInfo[d.key] = obj;

            obj = null;
        });

        setAvailabilityInfo(availabilityInfo);
        setAvailabilityInfoData(availabilityInfo);

    }, []);
    


    const   onChangeValue = (field, id, value) => {

        let tempavailabilityInfo = availabilityInfo;

        let obj = getObjectParam(tempavailabilityInfo[id]);
        obj.day_id = id;
        let val = value;
        if (value === '-1') {
            value = "-1" + " " + "-1";
        }

        if (field === 'start_time') {
            obj.startTime = val;
            obj.start_time = parseInt(value.split(' ')[0]);
            obj.start_time_meridiem = value.split(' ')[1];
        } else if (field === 'end_time') {
            obj.endTime = val;
            obj.end_time = parseInt(value.split(' ')[0]);
            obj.end_time_meridiem = value.split(' ')[1];
        }

        tempavailabilityInfo[id] = obj;

        setAvailabilityInfo(tempavailabilityInfo);
        setAvailabilityInfoData(tempavailabilityInfo);
    }


    const getRowFields = () => {
        let rows = [];

        let obj1 = days[0];
        let obj2 = days[1];

        rows.push(<div className='row' style={{ fontSize: '14px' }}>
            {obj1 !== null &&
                <div className='col-sm-5'><div style={{ float: 'left', width: '75px', paddingTop: '20px' }}>{obj1.label}</div> {getDropDownFields(obj1)}</div>}
            {obj2 !== null &&
                <div className='col-sm-7'><div style={{ float: 'left', width: '75px', paddingTop: '20px' }}>{obj2.label}</div>{getDropDownFields(obj2)}</div>}
        </div>)


        obj1 = days[2];
        obj2 = days[3];

        rows.push(<div className='row' style={{ fontSize: '14px' }}>
            {obj1 !== null &&
                <div className='col-sm-5'><div style={{ float: 'left', width: '75px', paddingTop: '20px' }}>{obj1.label}</div> {getDropDownFields(obj1)}</div>}
            {obj2 !== null &&
                <div className='col-sm-7'><div style={{ float: 'left', width: '75px', paddingTop: '20px' }}>{obj2.label}</div>{getDropDownFields(obj2)}</div>}
        </div>)


        obj1 = days[4];
        obj2 = days[5];

        rows.push(<div className='row' style={{ fontSize: '14px' }}>
            {obj1 !== null &&
                <div className='col-sm-5'><div style={{ float: 'left', width: '75px', paddingTop: '20px' }}>{obj1.label}</div> {getDropDownFields(obj1)}</div>}
            {obj2 !== null &&
                <div className='col-sm-7'><div style={{ float: 'left', width: '75px', paddingTop: '20px' }}>{obj2.label}</div>{getDropDownFields(obj2)}</div>}
        </div>)


        obj1 = days[6];
        rows.push(<div className='row' style={{ fontSize: '14px' }}>
            {obj1 !== null &&
                <div className='col-sm-5'><div style={{ float: 'left', width: '75px', paddingTop: '20px' }}>{obj1.label}</div> {getDropDownFields(obj1)}</div>}
        </div>)
        return rows;

    }

    const getDropDownFields = (d) => {
        let durationMenuItems = null;
        let durationTypeMenuItems = null;
        durationMenuItems = duration.map((d, index) => {
            return <MenuItem key={d.key} value={d.key} style={styles.popoverMenuItem} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={d.label}>{d.label}</div>}</MenuItem>
        });

        let start_time = "9 AM";
        let end_time = "17 PM";
        let obj = getObjectParam(availabilityInfo[d.key]);

        let style = { backgroundColor: '#fff', height: '35px', width: '87px', paddingLeft: '7px', marginBottom: '10px', marginLeft: '5px', float: 'left' }
        let startTimeStyle = {};
        if (obj.hasOwnProperty('error') && obj.error === 'start_time_error') {
            startTimeStyle = { ...style, border: '1px solid #f75723' };
        } else {
            startTimeStyle = { ...style, border: '1px solid rgb(221, 221, 221)' };
        }
        let endTimeStyle = {};
        if (obj.hasOwnProperty('error') && obj.error === 'end_time_error') {
            endTimeStyle = { ...style, border: '1px solid #f75723' };
        } else {
            endTimeStyle = { ...style, border: '1px solid rgb(221, 221, 221)' };
        }

        start_time = obj.startTime;
        end_time = obj.endTime;
        let ele = <div>
            <div style={{ float: 'left' }}>
                <FormControl variant="outlined" style={{ width: '100%', paddingTop: '10px' }}>
                    <InputLabel id="sf-scheduleravailibilitys-simple-select-outlined-label" className="sf-scheduleravailibilitys">{null}</InputLabel>
                    <Select
                        key={"start_time_" + d.key}
                        id = {"start_time_" + d.key}
                        defaultValue={start_time}
                        onChange={(e) => onChangeValue('start_time', d.key, e.target.value)}
                        name={"start_time_" + d.key}
                        className={"sf-fields-bg"}
                        style={{ minWidth: 95, maxWidth: 95, height:35 }}>
                        {durationMenuItems}
                    </Select>
                </FormControl>
            </div>
            <span style={{ float: 'left', paddingLeft: '5px', paddingRight: '5px', paddingTop: '20px' }}>to</span>
            <div style={{ float: 'left' }}>
                <FormControl variant="outlined" style={{ width: '100%', paddingTop: '10px' }}>
                    <InputLabel id="sf-scheduleravailibity-from-scheduler-simple-select-outlined-label" className="sf-scheduleravailibity-from-scheduler">{null}</InputLabel>
                    <Select
                        key={"end_time_" + d.key}
                        id={"end_time_" + d.key}
                        defaultValue={end_time}
                        onChange={(e) => onChangeValue('end_time', d.key, e.target.value)}
                        name={"end_time_" + d.key}
                        className={"sf-fields-bg"}
                        style={{ minWidth: 95, maxWidth: 95, height:35 }} >
                        {durationMenuItems}
                    </Select>
                </FormControl>
            </div>
        </div>
        return ele;

    }

    const openIntegration = () => {
        let hasPermission = true;
        if (hasPermission) {
            let callForm = 'scheduler';
            dispatch(getAppDrawer(true, getLocalizedStrings().label.MY_INTEGRATION.MY_INTEGRATIONS, constants.MY_INTEGRATIONS, null, callForm));
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.MY_INTEGRATION.NO_PERMISSION, null);
        }
    }


        let tZ = app.me.timezone;
        let isNylasSetup = availabilityInfo.isNylasSetup;
        return (
            <div style={{ paddingLeft: '120px' , width: '99%' }}>
                <div>
                    {(isNylasSetup !== undefined && !isNylasSetup) &&
                        <div className='row' style={{ paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px', fontSize: '14px' }}>
                            <div className='col-sm-12' style={{ padding: '5px', paddingLeft: '10px', backgroundColor: '#e3e6e8', width: '75%' }}>
                                <span>{getLocalizedStrings().message.SCHEDULER.CALENDAR_SYNC_MSG}</span>
                                <br /><span onClick={() => openIntegration()} style={{ color: 'blue', cursor: 'pointer' }}>{getLocalizedStrings().message.SCHEDULER.CALENDAR_SYNC_LINK}</span>
                            </div>
                        </div>
                    }
                </div>
                <div style={{ fontWeight: 'bold', paddingBottom: '12px', fontSize: '14px', marginTop: '25px', marginLeft:'12px' }}>{getLocalizedStrings().label.SCHEDULER.MY_AVAILABILITY} ({timeZoneMap[tZ]})</div>
                { availabilityInfo != undefined && Object.keys(availabilityInfo).length > 0 && 
                    getRowFields()
                }
            </div>);

}




export default (SchedulerAvailability);
