import { actions } from '..//constants/actions';
import store from "../../../src/services/store";

const defaultMailData = {
    fromEmail:  '',
    toEmail: '',
    ccEmail: '',
    bccEmail: '',
    selectedTemplate: 0,
    emailSignature: '',
    emailSignatureTag: '',
    template: {
        id: 0,
        name: '',
        subject: '',
        email_message: '',
    },
    personalize_fields: {
        "j_contacts.company": "Company",
        "j_contacts.first_name": "First name",
        "j_contacts.last_name": "Last name"
    },
    isLoading: true,
    ccTab: false,
    bccTab: false,
    isMailSent: false,
    batchMail: false,
    attachFiles: [],
    isOpenAttachmentPopover: false,
    isOpenTaskAction: false,
    taskActionValue: '',
    isDisabledToEmail: true,
    ccBccFocus: null,
    tags: {
        cc: [],
        bcc: [],
        to: []
    },
    suggestions: [],
    toSuggestion: [],
    ccSuggestions: [],
    coupons: {},
    editorLoader: true,
    sending: false,
    scheduleDate: null,
    moreActionOpen: false,
    anchorMoreActionEl: null,
    actionType: '',
    isAgreementSelected: false,
    btnIndex: 0,
    isTemplateListDisabled: false,
    agreementTemplateMsgbody: '',
    isShowAgreementTemplate: false,
    isAgreementTemplateExists: false,
    attachLoader: false,
    isFromAttachment: false,
    email_id_list: [],
    isTypeAgreement: false,
    isBackHideen: false,
    agreementMailSubject: '',
    loadAgreementEditor: true,
    anchorEl: null,
    editorType: null,
    emailContent: '',
    encodedParams: '',
    catagoryList: [
        { key: 'Sales Template', value: 'Sales Template', id: 'Sales Template', label: "Sales Templates" },
        { key: 'Marketing Template', value: 'Marketing Template', id: 'Marketing Template', label: "Marketing Templates" },
        { key: 'Agreement Template', value: 'Agreement Template', id: 'Agreement Template', label: "Agreement Templates" }
    ],
    selectedCatagory: 'Sales Template',
    selectedDataFetchType: 'Recent',
    DataFetchTypeList: [
        { key: 'Recent', value: 'Recent', id: 'Recent', label: "Recent" },
        { key: 'All', value: 'All', id: 'All', label: "All" },
    ],
    tempMarketingTemplate: [],
    tempSalesTemplate: [],
    agreementTemplateList: [],
    recentTemplateList: [],
    templateOptions: [],
    isAutoCompleteDisabled: false,
    isSendDisabled: false,
    isSendDisabled: false,
    isSendDisabled: false,
    starredMail: true,
};
const fieldsInitialState = {
    mounted: false,
    verifiedEmails: [],
    templateList: [],
    categoriesList: [],
    maildata: {...defaultMailData},
    isVerifyEmailCalled: false

}

const sendMailReducer = (state = fieldsInitialState, action) => {
    switch (action.type) {
        case actions.VERIFIED_EMAILS_GET_FULLFILLED:        
            return {
                ...state,
                mounted: true,
                isVerifyEmailCalled: true,
                verifiedEmails: action.payload
            }
            case actions.STORE_SEND_MAIL_DATA:        
                return {
                    ...state,
                    maildata: action.payload
                }
        case actions.UPDATE_TEMPLATE_LIST:        
            return {
                ...state,
                templateList: action.payload
            }
        case actions.UPDATE_TEMPLATE_CATEGORIES_LIST:        
            return {
                ...state,
                categoriesList: action.payload
            }
            case actions.RESET_SEND_MAIL_DATA:        
            return {
                ...state,
                maildata: defaultMailData
            }
            case actions.UPDATE_SEND_MAIL_DATA:        
            return {
                ...state,
                maildata: {...state.maildata,...action.payload}
            }
            case actions.UPDATE_SEND_MAIL_TEMPLATE_DATA:        
            return {
                ...state,
                maildata: {...state.maildata,template:{...state.maildata.template,...action.payload}}
            }
        default:
            return state;
    }
}
export default sendMailReducer;