
import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ShowCircularProgress from '../components/circularProgress';
import { Select, MenuItem,InputLabel,FormControl } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { isValidParam, isInvalidParam, isInteger,getObjectParam } from '../../../services/helper/parameterVerifier';
import { getUserList } from '../../../services/actions/UserListAction';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { addNode } from '../../../services/actions/automationCampaignActions';
import { removeError } from '../../../services/helper/automationUtils';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getParentDecision } from '../../../services/helper/automationUtils';
import { useDispatch, useSelector } from 'react-redux';
import * as SFFormActions from "../../../services/actions/sfFormActions";
import Autocomplete from "@mui/material/Autocomplete";

const AutomationAddTask = ({data,closeDrawer}) => {
    const dispatch = useDispatch();
    const automationCampaign = useSelector((state) => state.automationCampaign);
    const sfForm = useSelector((state) => state.sfForm);
    const userlist = useSelector((state) => state.userlist);
    const [valueUser,setValueUser] = useState('Sales Rep');
    const [reminder,setReminder] = useState(0);
    const [reminderTime,setReminderTime] = useState('minutes');
    const [reminderType,setReminderType] = useState('');
    const [daysAfter,setDaysAfter] = useState('');
    const [taskName,setTaskName] = useState('');
    const [dueBy,setDueBy] = useState(0);
    const [isShowIntervalDiv,setIsShowIntervalDiv] = useState(false);
    const [isShowDaysAfter,setIsShowDaysAfter] = useState(false);
    const [taskNameField,settaskNameField] = useState([{list_values:[],field_length:25,is_readonly:false}]);

    useEffect(() => {
         
        dispatch(getUserList());
        let nodeObject = data;
        let detailsArray = null;
        getFormData();
        if (isValidParam(nodeObject)) {
            if (nodeObject.hasOwnProperty('details') ) {

                detailsArray = nodeObject.details[0];
				let tempReminderType = reminderType;

                if (isValidParam(detailsArray)) {
                    if (detailsArray.hasOwnProperty('t_name')) {
                        setTaskName(detailsArray.t_name );
                    }
                    if (detailsArray.hasOwnProperty('t_owner')) {
                        setValueUser(detailsArray.t_owner);
                    }
                    if (detailsArray.hasOwnProperty('t_reminder')) {
                        setReminder(detailsArray.t_reminder);
                    }
                    if (detailsArray.hasOwnProperty('t_remindertime')) {
                        setReminderTime(detailsArray.t_remindertime);
                    }
                    if (detailsArray.hasOwnProperty('t_remindertype')) {
	                    tempReminderType = detailsArray.t_remindertype;
                    }
                    if (detailsArray.hasOwnProperty('days_after')) {
                        setDaysAfter(detailsArray.days_after);
                    }
                    if (detailsArray.hasOwnProperty('due_by')) {
                        setDueBy(parseInt(detailsArray.due_by));
                    }
                    if (tempReminderType && tempReminderType !== "") {
                        setIsShowIntervalDiv(true);
                    }
	                setReminderType(tempReminderType);
                    if (nodeObject.hasOwnProperty('parentId')) {
                        let parentId = nodeObject.parentId;
                        if (parentId > 0) {
                            setIsShowDaysAfter(true);
                        } else {
                            setIsShowDaysAfter(false);
                        }
                    }

                }
                 else {
                    if (nodeObject.hasOwnProperty('parentId')) {
                        let parentId = nodeObject.parentId;
                        if (parentId === 0) {
                                setIsShowDaysAfter(false);
                        } else {
                            let parentDecission = getParentDecision(automationCampaign.data.nodes, nodeObject.childId);
                            if (isValidParam(parentDecission)) {
                                if (parentDecission.hasOwnProperty('actionName')) {
                                    let actionName = parentDecission.actionName;
                                    let daysAfter = null;
                                    if (actionName === constants.AUTOMATION_DESIGNER_WEB_FORM) {
                                        daysAfter = constants.AUTOMATION_DESIGNER_DECISION_WEBFORM_SUBMITTED;
                                    } else if (actionName === constants.AUTOMATION_DESIGNER_LANDING_PAGE) {
                                        daysAfter = constants.AUTOMATION_DESIGNER_DECISION_LANDING_PAGE_SUBMITTED;
                                    } else if (actionName === constants.AUTOMATION_DESIGNER_EMAIL_OPEN) {
                                        daysAfter = constants.AUTOMATION_DESIGNER_DECISION_EMAIL_OPENED;
                                    } else if (actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK) {
                                        daysAfter = constants.AUTOMATION_DESIGNER_DECISION_LINK_CLICKED;
                                    } else if (actionName === constants.AUTOMATION_DESIGNER_UNSUBSCRIBE) {
                                        daysAfter = constants.AUTOMATION_DESIGNER_DECISION_UNSUBSCRIBED;
                                    }

                                    setDaysAfter(daysAfter);
                                    setIsShowDaysAfter(true);

                                }
                            }
                        }
                    }
                }
            }
        }

    }, []);
    const getFormData = () => {
        let tempdata ;
            if (sfForm.data[constants.TASKS] === undefined) {
                var promise = SFFormActions.getSFFormFieldsPromise(constants.TASKS);
                promise.then((response) => {
                    if (isValidParam(response)) {
                         tempdata = JSON.parse(JSON.stringify(getObjectParam(response)))
                        sfForm.data[constants.TASKS] = tempdata;

                    let tempField = tempdata.fields.filter(function (el) {
                        return el.name == 't_name';
                    });
                    settaskNameField(tempField);
                    }
                });
            }else{
                tempdata =  sfForm.data[constants.TASKS];
                let tempField = tempdata.fields.filter(function (el) {
                    return el.name == 't_name';
                });
                settaskNameField(tempField);
            }
           
        
       
    }
    const changeReminderType = (event) => {
        let value = event.target.value;
        if (isValidParam(value)) {
            let t_remindertime = reminderTime;
            let _reminder = reminder;
            let _isShowIntervalDiv = isShowIntervalDiv;
            if (value === "Pop-up" || value === "Email") {
                _isShowIntervalDiv = true;
                let nodeObject = data;
                if (isValidParam(nodeObject)) {
                    if (nodeObject.hasOwnProperty('details')) {
                        let detailsArray = nodeObject.details[0];
                        if (isValidParam(detailsArray)) {
                            if (detailsArray.hasOwnProperty('t_remindertime')) {
                                t_remindertime = detailsArray.t_remindertime;
                            }
                            if (detailsArray.hasOwnProperty('t_reminder')) {
                                _reminder = detailsArray.t_reminder;
                            }
                        }
                    }
                }
            } else {
                _isShowIntervalDiv = false;
                t_remindertime = 'minutes';
            }

                setReminder(_reminder);
                setReminderType(value);
                setIsShowIntervalDiv(_isShowIntervalDiv);
                setReminderTime(t_remindertime);

        }
    }

   const changeReminderTime = (event) => {
        let value = event.target.value;
        setReminderTime(value);
    }

    const generateUserList = () => {
        let userList = null;
        let arrayUserList = userlist.data;
        if (isValidParam(arrayUserList)) {
            userList = arrayUserList.map((objUser, i) => {
                return (
                    <MenuItem className="menuItemAddTaskSalesrep" value={objUser.value}  title={objUser.text} style={{ ...styles.popoverMenuItem }} >{objUser.text}</MenuItem>
                );
            });
        }
        return userList;
    }

    const changeUser = (event) => {
        let value = event.target.value;
        setValueUser(value);
    }

    const changeDueBy = (value) => {
        let val = value;
        if (isValidParam(val)) {
            val = val.trim();
            if (!isInteger(val)) {
                val = "";
            }
            setDueBy(val);
        }
    }

    const changeReminder = (value) => {
        let val = Number(value.trim());
        if (!isInteger(val)) {
            val = "";
        }
        setReminder(val);
    }

    const changeTaskName = (event, value) => {
        if (isValidParam(value)) {
            let tempvalue = null;
					if(value !== null){
						tempvalue =  value.inputValue !== undefined ? value.inputValue : value.value;
					}else{
						tempvalue = value;
					}
            setTaskName(tempvalue);
        }
    }

    const openDialog = (dialogData) => {
        dispatch(getAppDialog(true, constants.ALERT_DIALOG, getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, null, dialogData, constants.AUTOMATION_DESIGNER_OBJECT));
    }

    const saveTask = () => {
        let nodeObject = data;
        if (isValidParam(nodeObject)) {
            let action_type = "add_tasks";
            let days_after = daysAfter;
            let due_by = dueBy;
            let object_indentifier = 0;
            let t_owner = valueUser;
            let t_reminder = reminder;
            let t_remindertime = reminderTime;
            let t_name = taskName;
            let t_remindertype = reminderType;
            let dialogData = "";
            let globalCount = 1;

            if (isInvalidParam(t_name)) {
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.ENTER_TASK_NAME,
                    fieldId: 'taskName'
                }
                openDialog(dialogData);
            } else if (due_by === '') {
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.PROVIDE_DUEBY_FIELD,
                    fieldId: 'dueBy'
                }
                openDialog(dialogData);
            }
            else {
                if (nodeObject.hasOwnProperty('details')) {
                    due_by = due_by.toString();
                    t_reminder = t_reminder.toString();
                    let detailsArray = nodeObject.details[0];
                    let detailObject = null;
                    if (isValidParam(detailsArray)) {
                        if (detailsArray.t_name !== t_name) {
                            detailsArray.t_new = "";
                        }
                        detailsArray.t_remindertype = t_remindertype;
                        detailsArray.t_name = t_name;
                        detailsArray.due_by = due_by;
                        detailsArray.t_remindertime = t_remindertime;
                        detailsArray.t_owner = t_owner;
                        detailsArray.t_reminder = t_reminder;
                    } else {
                        detailObject = {
                            t_orderid: 1,
                            action_type: action_type,
                            t_remindertype: t_remindertype,
                            t_name: t_name,
                            due_by: due_by,
                            days_after: days_after,
                            t_remindertime: t_remindertime,
                            object_indentifier: new Date().getTime() + globalCount++,
                            t_owner: t_owner,
                            isNew: '1',
                            t_reminder: t_reminder
                        }
                        // detailsArray.push(detailObject);
                        nodeObject.details.push(detailObject);// = detailsArray;
                    }
                    nodeObject = removeError(nodeObject);
                    dispatch(addNode(automationCampaign.data));
                    closeDrawer();
                }
            }
        }
    }

    let campaignStatus = data.status;
    if (userlist.mounted) {
        let userList = generateUserList();
        return (
            <div style={{ paddingTop: '12px', paddingLeft: '10px', paddingRight: '15px', marginLeft:'20px' }}>
                <div className='row'>
                    <div className='col-sm-12' style={{ padding: "0"}}>
                         {/* <FormControl style={{width:'100%'}} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                id="taskName"
                                autoFocus={true}
                                value={taskName}
                                fullWidth={true}
                                onChange={(e)=>changeTaskName(e,e.target.value)}
                                label={getLocalizedStrings().label.AUTOMATION_DESIGNER.TASK_NAME_REQUIRED}
                                autoComplete="new-password"
                                margin='dense'
                                size='small'
                                className={"sf-fields-bg"}
                            />
                        </FormControl> */}
                        <FormControl style={{ width: "100%" }}>

                            <Autocomplete
                                value={taskName}
                                onChange={(e,value)=>changeTaskName(e,value)}
                                options = {taskNameField[0].list_values}
                                filterOptions={(options, params) => {

                                    const { inputValue } = params;
                                    let filtered = options;
                                    if (inputValue !== '') {
                                        filtered = options.filter(function (el) {
                                            return el.value.includes(inputValue);
                                        });
                                    }

                                    // Suggest the creation of a new value
                                    const isExisting = options.some((option) => inputValue === option.value);
                                    if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                            inputValue,
                                            value: `Add "${inputValue}"`,
                                        });
                                    }

                                    return filtered;
                                }}

                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                id="free-solo-with-text-demo"
                               
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.value;
                                }}
                                renderOption={(props, option) => <li {...props}>{option.value}</li>}
                                style={{ width: "100%" }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={getLocalizedStrings().label.AUTOMATION_DESIGNER.TASK_NAME_REQUIRED}
                                        inputprops={{
                                            maxlength: taskNameField[0].field_length,
                                            ...params.InputProps,
                                        }}
                                        disabled={taskNameField[0].is_readonly}
                                        fullWidth={true}
                                        margin="dense"
                                        size="small"
                                        className={"sf-fields-bg"}
                                        autoFocus={true}
                                        InputLabelProps={{
                                            style: {
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                width: "90%",
                                            },
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </div>
                </div>

                <div className="row" style={{ paddingTop: '11px' }}>
                    <div className='col-sm-4' style={{ padding: "0"}}>
                        {/*<div style={{ width: '77%' }}>*/}
                        <FormControl variant="outlined" style={{ width: '100%' }}>
                            <InputLabel id="sf-automationaddtask-simple-select-outlined-label" className="sf-automationaddtask">{getLocalizedStrings().label.AUTOMATION_DESIGNER.OWNER_REQUIRED}</InputLabel>
                            <Select
                                value={valueUser}
                                onChange={(event)=>changeUser(event)}
                                label={getLocalizedStrings().label.AUTOMATION_DESIGNER.OWNER_REQUIRED}
                                labelId="user"
                                style={{height:'42px'}}
                                className={"sf-fields-bg"}
                            >
                                <MenuItem value="Sales Rep" >Sales Rep</MenuItem>
                                {userList}
                            </Select>
                        </FormControl>
                        {/*</div>*/}
                    </div>

                    <div className='col-sm-4'>
                        <div style={{ width: '71%',
                         display: 'inline-block',marginTop:'-7px' }}>
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    id="dueBy"
                                    value={dueBy}
                                    onChange={(e)=>changeDueBy(e.target.value)}
                                    inputstyle={{ color: '#717171', fontSize: '14px' }}
                                    inputprops={{maxlength: 5 }}
                                    fullWidth={true}
                                    label={getLocalizedStrings().label.AUTOMATION_DESIGNER.DUE_BY_REQUIRED}
                                    autoComplete="new-password"
                                    margin='dense'
                                    size='small'
                                    className={"sf-fields-bg"}
                                />
                            </FormControl>
                        </div>
                        <div style={{ display: 'inline-block', paddingLeft: '5px', verticalAlign: 'top', paddingTop: '10px' }}>{getLocalizedStrings().label.CAMPAIGNS.DAYS}</div>
                    </div>

                </div>

                <div className="row" style={{ paddingTop: '15px'}}>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <div className="col-sm-4" style={{ padding: "0"}}>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                <InputLabel id="sf-automation-add-task-from-automationaddtask-simple-select-outlined-label" className="sf-automation-add-task-from-automationaddtasks" style={{ marginTop:'-5px'}}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.REMINDER}</InputLabel>
                                <Select
                                    value={reminderType}
                                    onChange={(event)=>changeReminderType(event)}
                                    style={{height:'42px'}}
                                    label={getLocalizedStrings().label.AUTOMATION_DESIGNER.REMINDER}
                                    labelId="reminder"
                                    id='sf-reminder'
                                    className={"sf-fields-bg"}
                                >
                                    <MenuItem value="" />
                                    <MenuItem value="Email"  >{getLocalizedStrings().label.AUTOMATION_DESIGNER.EMAIL}</MenuItem>
                                    {/* <MenuItem value="Pop-up" primaryText={getLocalizedStrings().label.AUTOMATION_DESIGNER.POP_UP} /> */}
                                </Select>
                            </FormControl>
                        </div>

                        {
                            (isShowIntervalDiv) &&
                            <>
                                <div className="col-sm-3">
                                    <FormControl style={{ width: '100%', marginTop:'-8px' }} className="sf-change-reminder" noValidate autoComplete="off">
                                        <TextField
                                            variant="outlined"
                                            onClick={e => e.target.focus()}
                                            fullWidth={true}
                                            id="reminder"
                                            value={reminder}
                                            onChange={(e)=>changeReminder(e.target.value)}
                                            inputprops={{maxlength: '3'}}
                                            label={getLocalizedStrings().label.AUTOMATION_DESIGNER.REMIND_BEFORE}
                                            autoComplete="new-password"
                                            margin='dense'
                                            size='small'
                                            className={'sf-atc-textfield'}
                                        />
                                    </FormControl>
                                </div>

                                <div className="col-sm-2 p-0">
                                    <FormControl variant="outlined" style={{ width: '100%' }}>
                                        <InputLabel id="sf-automation-add-tasks-results-fromautomationaddtasks-simple-select-outlined-label" className="sf-automation-add-tasks-results-fromautomation-addtasks"> </InputLabel>
                                        <Select
                                            floatinglabeltext=""
                                            value={reminderTime}
                                            onChange={(event)=>changeReminderTime(event)}
                                            labelId="remindertime"
                                            id='sf-remindertime'
                                            className={"sf-fields-bg"}
                                            style={{ height: '42px' }}
                                        >
                                            <MenuItem value="minutes" >{getLocalizedStrings().label.AUTOMATION_DESIGNER.MINUTES}</MenuItem>
                                            <MenuItem value="hours"> {getLocalizedStrings().label.AUTOMATION_DESIGNER.HOURS}</MenuItem>
                                            <MenuItem value="days" >{getLocalizedStrings().label.AUTOMATION_DESIGNER.DAYS}</MenuItem>
                                            <MenuItem value="weeks" >{getLocalizedStrings().label.AUTOMATION_DESIGNER.WEEKS}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </>
                        }
                    </div>
                </div>

                {
                    (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                    <div style={{ paddingTop: '15px' }}>
                        <Button
                            style={styles.listViewPrimaryButton}
                            onClick={()=>saveTask()}
                        >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                    </div>
                }
            </div>
        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }
}

export default AutomationAddTask;
