import React, { useState, useEffect } from 'react';
import { styles } from '../../../services/constants/styles';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import SFDataTable from './sfDataTable';
import { useDispatch, useSelector } from "react-redux";
import ShowCircularProgress from '../components/circularProgress';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';


const AuditReportDetails =({object,closeDrawer,data})=>{
    const dispatch = useDispatch();
    const [selectedRow,setSelectedRow] = useState(0);
    const [rows, setRows] = useState([]);
    const [columns,setColumns] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    useEffect(()=>{
        getAuditReportRecords(data.row.id);
    },[])

    const getAuditReportRecords =(id)=>{
        setIsLoading(true)
        let params = {};
        params.parentRecordId = id;
        const promise = Promise.resolve(HTTPClient.get(endPoints.AUDIT_REPORT_DETAILS.GET, params));
        if (promise) {
            promise.then(res => { 
                let response = res;
                let _rows = response.records;
                let rowsData = [..._rows].reverse();
                setRows(rowsData);
                let _columns = response.fields.fields;
                setColumns(_columns);
                setIsLoading(false)
            })

        }
    }
    const customEvent =(eventType, column, row, event)=>{
        
    }

    const getAuditReportDetails = ()=>{
        let view = [];
        let _rows = rows;
        let _columns = columns;
        let _columnsData = [];
        _columns.map((item,index)=>{
            if(item.name == "questions") {
                _columnsData.push(item);
            }
            if(item.name == "answers"){
                _columnsData.push(item);
            }
            if(item.name == "total_score") {
                _columnsData.push(item);
            }
            if(item.name == "audit_category_name"){
                _columnsData.push(item);
            }
           
        })
        let _rowData = [];
        _rows.map((items,index)=>{
            let data ={};
            if(items.questions){
                data.questions = items.questions;
            }
            if(items.answers){
                data.answers = items.answers;
            }
            if(items.totalScore){
                data.total_score = items.totalScore;
            }
            if(items.auditCategoryName){
                data.audit_category_name = items.auditCategoryName;
            }
            _rowData.push(data);
        })
        
        if (_rowData.length > 0 && _rowData !== null) {
            view.push(
                <SFDataTable columns={_columnsData}  rows={_rowData} customEvent={(eventType, column, row, event) => customEvent(eventType, column, row, event)} />
            )
            return view;
        }else{
            let noContent = getNoRecordContent();
            return noContent;
        }
        
    }

    const getNoRecordContent = () => {
        return (
            <div style={{display: 'flex', backgroundColor: 'white', borderBottom: '1px solid #D3D3D3'}}>
                <span style={{ color: 'red', margin: '5px 0 5px 8px', fontSize: '14px' }}>{getLocalizedStrings().label.INXPRESS_REPORT.NO_RECORDS_FOUND}</span>
            </div>
        );
    }
    let tempStyle = { marginLeft: 42, width: '96%', paddingTop: 0, paddingLeft: 11, };
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2

    return(
        <div style={{marginTop:'20px'}}>
            { !isLoading ?<div>
                    {getAuditReportDetails()}
                </div>
                :
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            }
        </div>
    )

}
export default AuditReportDetails;