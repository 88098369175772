import React, { useEffect, useState } from "react";
import { styles } from "../../../services/constants/styles";
import { constants } from "../../../services/constants/constants";
import {
	isValidParam,
	getStringParam,
	getIntParam,
	getBooleanParam,
} from "../../../services/helper/parameterVerifier";
import { getActiveTabInfo } from "../../../services/helper/sfTabManager";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import * as HTTPClient from "../../../services/helper/httpClient";
import { endPoints } from "../../../services/constants/endPoints";
import {
	addTab,
	TYPE_DETAIL_VIEW,
} from "../../../services/helper/sfTabManager";
import { LinkToType } from "../../../services/constants/link";
import { refreshDetailViewGroup } from "../../../services/actions/detailViewActions";
import { getImageNameByObject } from "../../../services/helper/common";
import { getAppDrawer } from "../../../services/actions/appContainerActions";
import Chip from "@mui/material/Chip";
import ShowCircularProgress from "../components/circularProgress";
import * as sfDialogs from "../components/sfDialogs";
import { useDispatch, useSelector } from "react-redux";

const DetailviewGroups = ({ propsDetailviewGroup }) => {
	const dispatch = useDispatch();
	const detailViewTaskAppintmentReducer = useSelector(
		(state) => state.detailViewTaskAppintmentReducer
	);
	const [groupList, setGroupList] = useState([]);
	const [refreshIndicator, setRefreshIndicator] = useState(true);
	const [contentHeight, setContentHeight] = useState(window.innerHeight - 240);
	const [top, setTops] = useState((contentHeight - 10) / 2);

	useEffect(() => {
		loadDetailviewGroupData();
	}, []);

	
	useEffect(() => {
		
		if (detailViewTaskAppintmentReducer.isRefreshDetailviewGroup === true) {
	       dispatch(refreshDetailViewGroup(false));
	        loadDetailviewGroupData();
	    }
	}, []);

	const loadDetailviewGroupData = () => {
		let info = getActiveTabInfo();
		let isInXpress = getBooleanParam(info.isInXpress);

		let params = {};
		params["parent_object"] = propsDetailviewGroup.object;
		params["parent_record_id"] = propsDetailviewGroup.recordId;
		if (isInXpress) {
			params.is_inxpress = isInXpress;
			params.inxpress_project_id = info.inXpressProjectId;
		}
		if (
			isValidParam(info) &&
			info.hasOwnProperty("isUnitsAccount") &&
			info.hasOwnProperty("UnitsAccountProjectId") &&
			info.isUnitsAccount
		) {
			params.isUnitsAccount = info.isUnitsAccount;
			params.UnitsAccountProjectId = info.UnitsAccountProjectId;
		}
		setRefreshIndicator(true);
		let promise = HTTPClient.get(endPoints.GROUP.LIST, params);
		promise.then((response) => {
			let groupRecords = response.records;
			setGroupList([...groupRecords]);
			setRefreshIndicator(false);
		});
	};

	

	const generateGroups = (groupList) => {
		let groupData = null;

		groupData = groupList.map((group, i) => {
			let groupName = group.name;
			if (groupName === "") {
				groupName = constants.EMPTY;
			}

			return (
				<div title={groupName}>
					<Chip
						className="groupChip"
						style={{
							margin: 4,
							fontSize: "13px",
							color: "#717171",
							maxWidth: "180px",
							overflow: "hidden",
							backgroundColor: "#F6F6F6",
						}}
						label={groupName}
						title={groupName}
					/>
				</div>
				
			);
		});

		if (groupList !== null && groupList.length === 0) {
			groupData = (
				<span
					style={{ paddingTop: "10px", color: "#717171", whiteSpace: "normal" , fontSize:14}}
				>
					{" "}
					{
						getLocalizedStrings().message.DETAIL_VIEW.NO_ASSOCIATED_GROUPS_FOUND
					}{" "}
				</span>
			);
		}

		return groupData;
	};

	
	const manageGroup = () => {
		let tabInfo = getActiveTabInfo();
		let isUnitsAccount = false;
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
			isUnitsAccount = tabInfo.isUnitsAccount;
		}
		if (!isInXpress && !isUnitsAccount) {
			dispatch(
				getAppDrawer(
					true,
					getLocalizedStrings().label.DETAIL_VIEW.ADD_TO_GROUP,
					"Add to Group",
					null,
					constants.DETAIL_VIEW
				)
			);
		} else {
			let permissionMessage =
				getLocalizedStrings().message.COMMON.ACCESS_DENIED;
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				permissionMessage,
				null
			);
		}
	};

	let detailViewGroupData = generateGroups(groupList);
	return (
		<div>
			{refreshIndicator && (
				<div style={{ width: "100%", height: contentHeight }}>
					<div
						className="asset-loaderh"
						style={{ paddingTop: top, paddingLeft: "48%" }}
					>
						<div
							style={{
								...styles.assetLoaderContainer,
								height: 50,
								width: 50,
								padding: 7,
							}}
						>
							<ShowCircularProgress
								size={30}
								style={{ marginTop: "3", marginLeft: "3" }}
							/>
						</div>
					</div>
				</div>
			)}
			<div
				style={{
					...styles.taskAppHeadingStyle,
					paddingBottom: "5px",
					marginBottom: "10px",
				}}
			>
				<span
					style={{
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
						overflow: "hidden",
						width: "230px",
					}}
				>
					{getLocalizedStrings().label.DETAIL_VIEW.GROUPS}
				</span>
				<a
					href="javascript:void(0);"
					style={styles.addNew}
					onClick={manageGroup}
				>
					{getLocalizedStrings().label.DETAIL_VIEW.MANAGE}
				</a>
			</div>
			<div style={{}}>{detailViewGroupData}</div>
		</div>
	);
};

export default DetailviewGroups;
