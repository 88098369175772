import React, { useEffect, useState } from 'react'
import ShowCircularProgress from './circularProgress';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {endPoints} from "../../../services/constants/endPoints";
import * as HTTPClient from "../../../services/helper/httpClient";

const AuditAnswersAttachments = ({ location }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [attachFiles, setAttachFiles] = useState([])
    const [urlParams] = useState(new URLSearchParams(window.location.hash.split("audit-answers-attachment")[1]));

    useEffect(()=>{
        setIsLoading(true)
        if(urlParams){
            getImagesData()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[urlParams])

    const getImagesData = () => {
        const encodedParams = urlParams.get('p')
        const response = Promise.resolve(HTTPClient.getWithoutToken(endPoints.AUDIT_ANSWERS.AUDIT_ANWSERS_ATTACHMENTS, {
            encodedParams: encodedParams
        }));
        response.then((response) => {
            setAttachFiles(response)
            setIsLoading(false)
        })
    }

    const settings = {
        dots: attachFiles.length > 1 ? true : false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        lazyLoad: true,
        arrows: attachFiles.length > 1 ? true : false,
    };

    if(isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <ShowCircularProgress size={30} />
            </div>
        );
    }

    return (
        <div className="slider-container">
            <Slider {...settings}>
                {Array.isArray(attachFiles) && attachFiles.map((image, index) => (
                    <div key={index}>
                        <img src={image.url} alt={`${image.file_name}`} height={'300'} width={'300'}/>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default AuditAnswersAttachments;
