import React from 'react';
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { constants,OBJECT_TABLEID_MAP,TABLEID_OBJECT_MAP } from '../../../services/constants/constants';
import { isValidParam, getArrayParam, getStringParam, getIntParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getActiveTab } from '../../../services/helper/sfTabManager';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import ShowCircularProgress from '../components/circularProgress';
import * as sfDialogs from '../components/sfDialogs';
import { dateFormat, dateFormatFlatPicker } from '../../../services/helper/utils';
import moment from 'moment';
import { Button, FormControl, TextField,MenuItem,Select,InputLabel, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { refreshListView } from '../../../services/actions/listViewAcions';
import * as SFFormActions from '../../../services/actions/sfFormActions';
import Autocomplete from '@mui/material/Autocomplete';
import PopOver from './PopOver';
import { getUniqueId } from '../../../services/helper/utils';

const RoyaltyPlanManagement = ({ object, id, type, closeDrawer }) => {
const tempObjectList = [
    {
        name: constants.REPORTED_SALES_OBJECT,
        label: 'Reported Sales'
    }
]
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const sfForm = useSelector((state) => state.sfForm);
      
    const inputRef = useRef(null);
    const [mounted, setMounted] = useState(false);
    const [royaltyPlansObject, setRoyaltyPlansObject] = useState({});
    const [isLoading, setisLoading] = useState(false);
    const [error, setError] = useState({});
    const[royalityRules,setRoyalityRules] = useState([])
    const [objectMenuList, setObjectMenuList] = useState([]);
    const [objectList, setObjectList] = useState([]);
    const [reportedSalesSelectedFields,setReportedSalesSelectedFields] = useState([]);
    const [royaltySelectedFields,setRoyaltySelectedFields] = useState([]);
    const [isDisabled,setIsDisabled] = useState(false);
    const [isTaxIncludedChecked,setIsTaxIncludedChecked] = useState(false);

    useEffect(() => {
        
        let reportedSalesData = Object.assign({}, sfForm.data[constants.REPORTED_SALES_OBJECT]);
        if((Object.keys(reportedSalesData).length === 0 )){
            if(Object.keys(reportedSalesData).length === 0){
                getFormData(constants.REPORTED_SALES_OBJECT);
            }
          
           
        }else{
            getRecordInformation();
        }
       
        inputRef.current?.focus();
    }, []);

     
    const getFormData = (object) => {
        try {
            var promise = SFFormActions.getSFFormFieldsPromise(object);
            promise.then((response) => {
                if (isValidParam(response)) {
                    sfForm.data[object] = JSON.parse(JSON.stringify(getObjectParam(response)));
                    let reportedSalesData = Object.assign({}, sfForm.data[constants.REPORTED_SALES_OBJECT]);
                    if(Object.keys(reportedSalesData).length > 0){
                        getRecordInformation();
                    }
                }
            });
        } catch (error) {
            console.error("Error in 'advanceSearchForm.js -> getFormData()':" + error);
        }
    }

    useEffect(() => {
        if (mounted && royaltyPlansObject != undefined && royaltyPlansObject.length > 0) {
            addFloatingLabelEvent();
        }
    }, [royaltyPlansObject,royalityRules]);

    useEffect(() => {
       

        let options = tempObjectList.map(m => { return { id: OBJECT_TABLEID_MAP[m.name], name: m.name, label: m.label } });
        options = options.filter(obj => {
            return obj.id === OBJECT_TABLEID_MAP[constants.ROYALTY_OBJECT] ||
                obj.id === OBJECT_TABLEID_MAP[constants.REPORTED_SALES_OBJECT]
               
        });
      
        let objectMenuList = generateObjectSelectionField(options);
        setObjectList(options);
        setObjectMenuList(objectMenuList);
    }, []);

    const generateObjectSelectionField=(options)=> {
        let objectListObj;
            objectListObj = options.map((item, index) => {
                return <MenuItem key={item.id} value={item.id}  style={styles.popoverMenuItem} >{item.label}</MenuItem>;
            });
        return objectListObj;
    }
    const getRecordInformation = () => {
        let params = null;
        var promise = null;
        let tempRoyaltyRules = [];
        try {
            params = {};
            params.id = getIntParam(id);

            promise = Promise.resolve(HTTPClient.get(endPoints.ROYALTY_PLANS.FIELDS, params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response) && response.status !== -1) {
                        let royaltyPlanObject = response;
                        let records = response.records || {};
                        let fields = response.fields || [];
                        for (let key in records) {
                            let index = -1;
                            if (key === 'royalty_fee') {
                                index = fields.findIndex(f => f.name === 'royalty_fee_val');
                            } else if (key === 'marketing_fee') {
                                index = fields.findIndex(f => f.name === 'marketing_fee_val');
                            } else if (key === 'tech_fee') {
                                index = fields.findIndex(f => f.name === 'tech_fee_val');

                            } else if (key === 'other_fee') {
                                index = fields.findIndex(f => f.name === 'other_fee_val');
                            } else if (key === 'start_date') {
                                const momentDate = moment(records[key]);
                                records[key] = momentDate.format(dateFormat[app.me.date_format]);
                                index = fields.findIndex(f => f.name === 'start_date');
                                if(!response.editStartDate  && type != 'copy'){
                                    fields[index].is_readonly = true;
                                    setIsDisabled(true);
                                }
                            } else if (key === 'plan_name' && type === 'copy') {
                                let planName = records[key];
                                records[key] = getLocalizedStrings().label.SALES_TEMPLATE.COPY_OF + '_' + planName;
                            } else if (key === 'royalty_formula' ) {
                                if(records[key] === ""){
                                    tempRoyaltyRules = [];
                                }else{
                                    tempRoyaltyRules = JSON.parse(records[key]);
                                }
                                 
                            } else if (key === 't_status') {
                                index = fields.findIndex(f => f.name === 't_status');
                                if(!response.editStatus && type != 'copy'){
                                    fields[index].is_readonly = true;
                                }
                            } 
                            if (index > -1) {
                                if (records[key] === 'Variable Amount' || records[key] === 'Variable Percentage' || records[key] === 'Not Used') {
                                    fields[index].is_disabled = true;
                                } else {
                                    fields[index].is_disabled = false;
                                }
                            }
 
                        }
                       
                        if (id > 0 && (type === "edit" || type === 'copy')) {
                            let index = fields.findIndex(f => f.name === 'plan_name');
                            if (index > -1 && type !== 'copy') {
                                fields[index].is_disabled = true;
                            }
                            if (tempRoyaltyRules != null && tempRoyaltyRules.length > 0  ) {
                                let temproyalityRules = [];
                                let filteredTempFields = [];
                                let tempReportedsalesFields = tempRoyaltyRules.filter((obj) => { return obj.object === OBJECT_TABLEID_MAP[constants.REPORTED_SALES_OBJECT] });
                                let tempreportedSalesSelectedFields = [];
                                let reportedSalesData = Object.assign({}, sfForm.data[constants.REPORTED_SALES_OBJECT]);
                              
                                if (tempReportedsalesFields.length > 0) {
                                    tempReportedsalesFields.map((f, i) => {
                                        let tempObj = new Object();
                                        reportedSalesData.fields.map((fld, index) => {
                                            if (f.calculateField == fld.name) {
                                                tempObj.id = fld.id;
                                                tempObj.name = fld.name;
                                                tempObj.label = fld.label;
                                                tempObj.field_type_id = fld.field_type_id;
                                                tempObj.t_tablefldid = fld.t_tablefldid;
                                            }
                                        });
                                        tempreportedSalesSelectedFields.push(tempObj);
                                    });
                                    setReportedSalesSelectedFields(tempreportedSalesSelectedFields);
                                }
                                tempRoyaltyRules.forEach(row => {
                                    if (row.object === OBJECT_TABLEID_MAP[constants.REPORTED_SALES_OBJECT]) {
                                        filteredTempFields = reportedSalesData.fields;
                                        tempreportedSalesSelectedFields.forEach((searchableField, index) => {
                                            filteredTempFields = filteredTempFields.filter(f => {
                                                let temp;
                                                if (f.name !== searchableField.name) {
                                                    temp = f.name;
                                                }
                                                return temp;
                                            });
                                        });

                                        let tempSelectedField = reportedSalesData.fields.filter((obj) => { return obj.name === row.calculateField });
                                        if(tempSelectedField.length > 0){
                                            filteredTempFields.push(tempSelectedField[0]);
                                        }

                                    }
                                    // if (filteredTempFields && filteredTempFields.length > 0) {
                                    //     filteredTempFields = filteredTempFields.filter((f)=>{return f?.name?.startsWith("royalty_")})
                                    //     filteredTempFields = filteredTempFields.filter(f => {
                                    //         return (f.field_type === constants.FIELD_TYPE_INTEGER
                                    //             || f.field_type === constants.FIELD_TYPE_FLOAT)
                                    //             && f.name !== 'created_on' && f.name !== 'updated_on' && f.name !== 'permission_date' && f.name !== 'other_fee' && f.name !== 'tech_fee'
                                    //             && f.name !== 'marketing_fee' && f.name !== 'royalty_fee'
                                    //     }).map(m => { return { name: m.name, t_tablefldid : m.t_tablefldid,id: m.id, label: m.label, field_type_id: m.field_type_id, field_type: m.field_type } });

                                    //     filteredTempFields = filteredTempFields.sort((obj1, obj2) => { return (obj1.label > obj2.label) ? 1 : -1 });
                                    // }
                                    filteredTempFields = filteredTempFields.sort((obj1, obj2) => { return (obj1.label > obj2.label) ? 1 : -1 });                                   
                                    row.ruleFields = filteredTempFields;
                                    temproyalityRules.push(row);
                                });
                                setRoyalityRules([...temproyalityRules]);
                            }
                            if(records?.sales_type === 'Invoices' && records?.tax_include) {
                                setIsTaxIncludedChecked(records?.tax_include === 1 ? true : false)
                            }

                        }

                        setRoyaltyPlansObject({ ...royaltyPlanObject, fields, records });
                        setMounted(true);
                    }
                })
            }
        } catch (error) {
            console.error("Error in 'RoyaltyPlanManagement.js -> getRecordInformation()':" + error);
        }
    }

    const addFloatingLabelEvent = () => {
        let { records = {} } = royaltyPlansObject;
        try {
            let fields = getArrayParam(royaltyPlansObject.fields).filter(f => {
                return (f.field_type === constants.FIELD_TYPE_DATE_TIME || f.field_type === constants.FIELD_TYPE_DATE || f.field_type === constants.FIELD_TYPE_LIST || f.is_lookup_field)
            });
            fields.map(f => {
                if (isValidParam(records[f.name])) {
                    let value = records[f.name];
                    if (value !== '') {
                        let ele = document.getElementsByClassName("customLabelDiv-" + f.name);
                        if (isValidParam(ele) && ele.length > 0) {
                            ele[0].classList.add('label-top');
                        }
                    }
                }
            });
        } catch (error) {
            console.error("Error in 'RoyaltyPlanManagement.js -> addFloatingLabelEvent()':" + error);
        }
    }

    const handleActions = (name) => {
        try {
            if (name === 'save') {
                handleSave();
            } else if (name === 'cancel') {
                closeDrawer();
            }
        } catch (error) {
            console.error("Error in 'RoyaltyPlanManagement.js -> handleActions()':" + error);
        }
    }

    const generateHeaderbuttons = () => {
        let element = [];
        try {
            let list = [{ name: 'save', label: getLocalizedStrings().label.COMMON.SAVE }, { name: 'cancel', label: getLocalizedStrings().label.COMMON.CANCEL }];
            element = list.map(item => {
                let buttonStyle = item.name === 'cancel' ? { ...styles.secondaryButton, verticalAlign: 'top' } 
                : isDisabled === false ? {...styles.primaryButton, width: '20px', verticalAlign: 'top' } 
                : {...styles.primaryButton, width: '20px', verticalAlign: 'top', opacity: '0.3'};
                let disableValue = item.name === 'cancel' ? isLoading : isDisabled === false ?  isLoading : true;
                return (
                    <Button
                        key={`key-${item.name}`}
                        onClick={() => handleActions(item.name)}
                        style={{ ...styles.primaryButton, verticalAlign: 'top', ...buttonStyle }}
                        labelstyle={{ fontWeight: 'normal' }}
                        disabled={disableValue}
                    >{item.label}</Button>
                );
            });
        } catch (error) {
            console.error("Error in 'RoyaltyPlanManagement.js -> generateHeaderbuttons()':" + error);
        }
        return element;
    }

    const validate = () => {
        let isValidate = true;
        let { records = {}, fields = [] } = royaltyPlansObject;
        try {
            let tempError = { ...error };
            let feilds = fields.filter(f => getStringParam(records[f.name]).trim() === '' && f.is_required_field);
            if (feilds.length > 0) {
                tempError[feilds[0].name] = true;
                dispatch(showCustomSnackBar(`${feilds[0].label}` + getLocalizedStrings().message.JOBS.FIELDS_CANNOT_BLANK, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                setError(tempError)
                focusElementByName(feilds[0]);
                return false;
            }
            if((records.royalty_fee == 'Fixed Amount' || records.royalty_fee == 'Fixed Percentage') && (records.royalty_fee_val == "" || records.royalty_fee_val == undefined)){
                let tempfeilds = fields.filter(f => f.name === 'royalty_fee_val');

                dispatch(showCustomSnackBar(`${tempfeilds[0].label}` + ' must have a valid number.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
              
                return false;
            }else if((records.marketing_fee == 'Fixed Amount' || records.marketing_fee == 'Fixed Percentage') && (records.marketing_fee_val == "" ||records.marketing_fee_val == undefined) ){
               
                let tempfeilds = fields.filter(f => f.name === 'marketing_fee_val');

                dispatch(showCustomSnackBar(`${tempfeilds[0].label}` + ' must have a valid number.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
              
                return false;
            }else if((records.tech_fee == 'Fixed Amount' || records.tech_fee == 'Fixed Percentage') && (records.tech_fee_val == "" || records.tech_fee_val == undefined) ){
               
                let tempfeilds = fields.filter(f => f.name === 'tech_fee_val');

                dispatch(showCustomSnackBar(`${tempfeilds[0].label}` + ' must have a valid number.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
              
                return false;
            }else if((records.other_fee == 'Fixed Amount' || records.other_fee == 'Fixed Percentage') && (records.other_fee_val == "" || records.other_fee_val == undefined)){
               
                let tempfeilds = fields.filter(f => f.name === 'other_fee_val');

                dispatch(showCustomSnackBar(`${tempfeilds[0].label}` + ' must have a valid number.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
              
                return false;
            }
            let tempRoyaltyRules = royalityRules;
            if(tempRoyaltyRules != null && tempRoyaltyRules.length > 0){
                const isAvailableRoyaltyowed  = royalityRules.filter(item => item?.calculateField === "royalty_owed")
                if( records?.reported_by === constants.REPORTED_BY_FR && royalityRules.length > 0 && !isAvailableRoyaltyowed.length) {
                    const rolatyOwedObj  = royalityRules[0]['ruleFields']?.filter(item => item?.name === "royalty_owed")
                    if(rolatyOwedObj.length > 0) {
                        dispatch(showCustomSnackBar(`${rolatyOwedObj[0].label}  must have a formula. Add a formula and try again.`, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                    return false;
                }
               for (let row = 0; row < tempRoyaltyRules.length; row++) {
                    if(tempRoyaltyRules[row].calculateField === ''){
                        dispatch(showCustomSnackBar('Please provide the Field name', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        return false;
                        break;
                    }else if(tempRoyaltyRules[row].formula === ''){
                        dispatch(showCustomSnackBar('Please provide a formula for configured Field name', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        
                        return false;
                        break;
                        
                    }
                   
                }
            }

        } catch (error) {
            console.error("Error in 'RoyaltyPlanManagement.js -> validate()':" + error);
        }
        return isValidate;
    }

    const handleSave = () => {
        // validation needs to be done
        let temproyalityRules = [];
        try {
            if (validate()) {
                let tempObjectData = { ...royaltyPlansObject };
                let { records = {}, fieldsPosList = [] } = tempObjectData;

                let fieldList = [];
                fieldsPosList.forEach(e => fieldList.push(...e.list));

                let fields = [];
                fieldList.forEach(m => {
                    if (records[m]) {
                        if (m === 'start_date') {
                            const momentDate = moment(records[m]);
                            fields.push({
                                name: m,
                                value: momentDate.format("YYYY-MM-DD")
                            });
                        } else {
                            fields.push({ name: m, value: records[m] });
                        }
                    }
                });

               // if (records?.reported_by !== constants.REPORTED_BY_FE) {
                    if(royalityRules.length > 0){
                       royalityRules.forEach(row => {
                           let tempObj = {};
                           tempObj.object = row.object;
                           tempObj.calculateField = row.calculateField;
                           tempObj.field_id = row.field_id;
                           tempObj.formula=row.formula
                           tempObj.id =row.id;
                           temproyalityRules.push(tempObj);
                           
                       });
                    }
                //}

                if(temproyalityRules.length > 0){
                   fields.push({ name: 'royalty_formula',
                   value:  JSON.stringify(temproyalityRules)});
                }else{
                       fields.push({ name: 'royalty_formula',
                       value:  null});
                }
                fields.push({
                    name: 'tax_include',
                    value: records?.sales_type === 'Invoices' && isTaxIncludedChecked ? 1 : 0
                });
                let params = {
                    id: type === "copy" ? 0 : ( tempObjectData.id || 0 ),
                    fields
                };

                setisLoading(true);
                let promise = SFFormActions.saveRecordPromise(object, params);
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (response.status !== -1) {
                            let id = response.data && getArrayParam(response.data.id).length > 0 ? getArrayParam(response.data.id)[0] : 0;
                            if (id > 0) {
                                tempObjectData.id = id;
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.ROYALTY_PLANS.ROYALTY_PLAN_SAVE_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                let activeTab = getObjectParam(getActiveTab());
                                refreshListView(activeTab.item);
                                setRoyaltyPlansObject({ ...tempObjectData });
                                closeDrawer();
                            }
                        } else {
                            if (response.error && response.error.message === 'duplicateplan') {
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_PLANS.ROYALTY_PLAN_DUPLICATE_PLAN_NAME, null);
                            }
                        }
                        setisLoading(false);
                    });
                }
            }
        } catch (error) {
            console.error("Error in 'RoyaltyPlanManagement.js -> handleSave()':" + error);
        }
    }


    const handleSelectChange = (event, field) => {
        try {
            let { fields = [], records = {} } = royaltyPlansObject;
            let value = event.currentTarget.innerText;
            let index = -1;
            if (field.name === 'royalty_fee') {
                index = fields.findIndex(f => f.name === 'royalty_fee_val');
            } else if (field.name === 'marketing_fee') {
                index = fields.findIndex(f => f.name === 'marketing_fee_val');
            } else if (field.name === 'tech_fee') {
                index = fields.findIndex(f => f.name === 'tech_fee_val');

            } else if (field.name === 'other_fee') {
                index = fields.findIndex(f => f.name === 'other_fee_val');
            }

            if (index > -1) {
                if (value === 'Variable Amount' || value === 'Variable Percentage' || value === 'Not Used') {
                    fields[index].is_disabled = true;
                } else {
                    fields[index].is_disabled = false;
                }
            }

           /* if(type === 'edit' && field.name === 'reported_by' && records?.reported_by === constants.REPORTED_BY_FR && records?.royalty_formula.length > 0) {
                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, 'You can add formula for a plan only when reported by is Franchisor. Do you want to proceed with reported by as Franchisees?', () => {
                    records[field.name] = value;
                    delete records.royalty_formula
                    setRoyaltyPlansObject({...royaltyPlansObject, fields: [...fields], records: {...records } });
                    setRoyalityRules([])
                }, null);
            } else {
                records[field.name] = value;
                setRoyaltyPlansObject({ ...royaltyPlansObject, fields: [...fields], records: { ...records } });
            }*/

            records[field.name] = value;
            setRoyaltyPlansObject({ ...royaltyPlansObject, fields: [...fields], records: { ...records } });

        } catch (error) {
            console.error("Error in 'royaltyPlanManagement.js -> handleSelectChange()':" + error);
        }
    }

    const focusElementByName = (field = {}) => {
        let inputElement = null;
        try {
            if (field && field.name) {
                 
                if (field.field_type === constants.FIELD_TYPE_DATE || field.field_type === constants.FIELD_TYPE_DATE_TIME) {
                    inputElement = document.getElementById('buttonfield-ref-' + field.name);
                    if (isValidParam(inputElement)) {
                        inputElement.click();
                    }
                } else {
                    inputElement = document.getElementById('field-ref-' + field.name);
                    if (isValidParam(inputElement)) {
                        inputElement.focus();
                    }
                }
            }
        } catch (error) {
            console.error("Error in 'RoyaltyPlanManagement.js -> focusElementByName()':" + error);
        }
    }

    const handleFlatpkrDateChange = (date, fieldName) => {
        try {
            const tempRoyaltyPlan = { ...royaltyPlansObject };
            if (isValidParam(date)) {
                let selectedDate = new Date(date);
                let dateValue = new Date(
                    selectedDate.getFullYear(),
                    selectedDate.getMonth(),
                    selectedDate.getDate()
                );
                const momentDate = moment(dateValue);
                if (moment && moment !== "") {
                    tempRoyaltyPlan.records[fieldName] = momentDate.format(dateFormat[app.me.date_format]);
                } else {
                    tempRoyaltyPlan.records[fieldName] = "";
                }
            } else {
                tempRoyaltyPlan.records[fieldName] = "";
            }
            setRoyaltyPlansObject(tempRoyaltyPlan);
         } catch (error) {
            console.error(
                "Error in 'RoyaltyPlanManagement.js -> handleFlatpkrDateChange()':" + error
            );
        }
    };

    const createRecordDetailsForms = (col_span) => {
        let elements = [];
        let { fields = [], fieldsPosList = [], records = {} } = royaltyPlansObject;

        try {
            fieldsPosList = fieldsPosList.find(f => f.col_span === col_span);

            if (fieldsPosList) {
                fields = getArrayParam(fieldsPosList.list).map(m => fields.find(f => f.name === m));
                fields = fields.filter(f => f !== undefined)
                elements = fields.map((field, index) => {
                    if (field && field.name !== "royalty_fee_min_amt") {
                        const tempRecord = records || {};
                        let fieldComponent = getFieldComponent(field, tempRecord, col_span);
                        let component;
                        if (field.name === "royalty_fee_val") {
                            const tempField = fields.find(f => f.name === "royalty_fee_min_amt");
                            let fieldComponent2 = getFieldComponent(tempField, tempRecord, col_span);
                            component = (
                                <div style={{ display: 'grid', gridTemplateColumns: '100% auto' }}>
                                    <div style={{ display: 'flex' }}>
                                        <div key={`record-${field.name}-${index}`} style={{ padding: '5px', flex: 1 }}>
                                            {fieldComponent}
                                        </div>
                                        <div key={`record-${tempField.name}-${index}`} style={{ padding: '5px', flex: 1 }}>
                                            {fieldComponent2}
                                        </div>
                                    </div>
                                </div>
                            );
                        } else if(field.name === "sales_type") {
                            component = (
                                <div style={{ display: 'grid', gridTemplateColumns: '100% auto' }}>
                                    <div style={{display: 'flex'}}>
                                        <div key={`record-${field.name}-${index}`} style={{ padding: '5px', flex: 1}}>
                                            {fieldComponent}
                                        </div>
                                        {records?.sales_type === "Invoices" && <div style={{ padding: '8px', flex: 0.7 }}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox checked={isTaxIncludedChecked} value={isTaxIncludedChecked} onChange={() => setIsTaxIncludedChecked(!isTaxIncludedChecked)} />}  style={{width:'fit-content',marginLeft:'16px'}} label={getLocalizedStrings().label.ROYALTY_PLAN.TAX_INCLUDED}/>
                                            </FormGroup>
                                        </div>}
                                    </div>
                                </div>
                            )
                        }else {
                            component = (
                                <div style={{ display: 'grid', gridTemplateColumns: '100% auto' }}>
                                    <div>
                                        <div key={`record-${field.name}-${index}`} style={{ padding: '5px' }}>
                                            {fieldComponent}
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                        return component;
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'RoyaltyPlanManagement.js -> createAccountsForm()':" + error);
        }
        return elements;
    }

    function getFieldComponent(field, records, col_span) {
        let fieldLabel = field.is_required_field ? field.label + getLocalizedStrings().label.COMMON.REQUIRED : field.label;

        let fieldValue = records[field.name] ? records[field.name] : "";

        if (field.name === 't_status' && (!fieldValue || fieldValue === '')) {
            fieldValue = field.default_value || 'Active';
            records[field.name] = fieldValue;
        }

        if ((field.name === 'royalty_fee_val'||field.name === 'marketing_fee_val' ||field.name === 'tech_fee_val'||field.name === 'other_fee_val' )&& field.is_disabled && fieldValue !="") {
            fieldValue = "";
            records[field.name] = fieldValue;
        }
        if ( field.name === 'royalty_fee_val' && records.royalty_fee == 'Fixed Percentage' && records.royalty_fee_val > 100) {
            fieldValue = "";
            records[field.name] = fieldValue;
       } else if (field.name === 'marketing_fee_val' && records.marketing_fee == 'Fixed Percentage' && records.marketing_fee_val > 100) {
        fieldValue = "";
        records[field.name] = fieldValue;
       } else if (field.name === 'tech_fee_val'&& records.tech_fee == 'Fixed Percentage' && records.tech_fee_val > 100 ) {
        fieldValue = "";
        records[field.name] = fieldValue;
       } else if ( field.name === 'other_fee_val' && records.other_fee == 'Fixed Percentage' && records.other_fee_val > 100 ) {
        fieldValue = "";
        records[field.name] = fieldValue;
       }

        let fieldComponent = ""
        if (field.field_type === constants.FIELD_TYPE_LIST) {
            let listOptions = getArrayParam(field.list_values);
            listOptions = listOptions.filter(f => f.value).map(m => { return { value: m.value, label: m.value } });
            let customStyle = {}
            if (col_span === 3) {
                customStyle.width = '48.5%'
            }

            let defaultSelectValue = {}
            if (field.field_type === 'list') {
                defaultSelectValue = {
                    label: fieldValue,
                    value: fieldValue
                }
            }

            fieldComponent =
                <div style={{ customStyle, marginTop: field.name === 't_status' ? "" : '4px' }}>
                    <Autocomplete
                        id={`field-ref-${field.name}`}
                        name={`select-field-${field.name}`}
                        key={`select-field-${field.name}`}
                        className={'sf-atc-autoComplete'}
                        defaultValue={royaltyPlansObject?.records[field.name]}
                        disabled={field.is_readonly || field.is_disabled}
                        options={listOptions}
                        onChange={(e) => handleSelectChange(e, field)}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.label
                        }
                        disableClearable={false}
                        renderInput={(params) => <TextField {...params} label={fieldLabel}
                            variant="outlined" margin="dense"
                            size='small' />
                        }
                        value={royaltyPlansObject?.records[field.name]}
                    />
                </div>

        } else if (field.field_type === constants.FIELD_TYPE_DATE) {
            let datePickerOptions = {
                enableTime: false,
                noCalendar: field.is_readonly,
                dateFormat: dateFormatFlatPicker[app.me.date_format],
                minuteIncrement: 1,
                static: true
            }
            fieldComponent = (
                <div
                    style={{ margin: "4px 0", width: "100%" }}
                    className={"sf-form-field dateTimeDiv-" + field.name}
                    id="dateholder"
                >
                    <PopOver
                        id={'field-ref-' + field.name}
                        key={field.name}
                        name={field.name}
                        btnType={field.field_type === constants.FIELD_TYPE_DATE_TIME ? "dateTime" : "date"}
                        buttonOuterLabelStyle={field.is_readonly ? { backgroundColor: '#fff', lineHeight: '0.1', marginTop: '-2px', padding: '-1px', paddingLeft: 5, paddingRight: 4, fontSize: 11, fontWeight: 500, position: 'absolute' } : null}
                        buttonStyle={{ fontSize: "16px" }}
                        containerStyle={{ lineHeight: 1 }}
                        buttonLabel={fieldLabel}
                        onChange={(date) => handleFlatpkrDateChange(date, field.name)}
                        value={records[field.name] ? records[field.name] : ""}
                        innerLabelStyle={{
                            padding: "8 5 2 5",
                            fontSize: "15px",
                            cursor: field.is_readonly ? "not-allowed" : "pointer",
                            color: field.is_readonly ? "#C6C6C6" : "default",
                        }}
                        height={40}
                        options={datePickerOptions}
                        disable={field.is_readonly == true ? true : false}
                    />
                </div>
            );
        } else {
            fieldComponent =
                <FormControl style={{ width: '100%', paddingBottom: '4px' }} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        id={`field-ref-${field.name}`}
                        key={`field-${field.name}-key`}
                        value={fieldValue}
                        title={fieldValue}
                        onChange={(e) => {
                            if(((field.name == "royalty_fee_val" && records.royalty_fee == 'Fixed Percentage')
                             || (field.name == "marketing_fee_val" && records.marketing_fee == 'Fixed Percentage')
                            ||(field.name == "tech_fee_val" && records.tech_fee == 'Fixed Percentage')
                            ||(field.name == "other_fee_val" && records.other_fee == 'Fixed Percentage')) && e.target.value >100){

                            }else{
                                let tempRecord = { ...records, [field.name]: e.target.value }
                                setRoyaltyPlansObject({ ...royaltyPlansObject, records: tempRecord })
                              }
                            
                        }}
                        disabled={field.is_readonly || field.is_disabled}
                        autoComplete="new-password"
                        label={fieldLabel}
                        InputLabelProps={{
                            style: {
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                width: '96%',
                            },
                        }}
                        inputProps={{ min: 0 }}
                        onKeyDown={(e) => {
                            if (field?.name !== 'plan_name' && (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+")) {
                                e.preventDefault()
                            }
                        }}
                        style={{ margin: 'inherit', marginTop: '4px' }}
                        margin='dense'
                        size='small'
                        className={"sf-fields-bg"}
                        type={field?.name === 'plan_name' ? '' : "number"}
                    />
                </FormControl>
        }
        return fieldComponent;
    }

    const createForms = () => {
        try {
            return (
                <div>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2,1fr)', gridColumnGap: '26px' }}>
                        <div>
                            {createRecordDetailsForms(1)}
                        </div>
                        <div style={{ marginTop: '4px' }}>
                            {createRecordDetailsForms(2)}
                        </div>
                    </div>
                </div>
            )
        } catch (error) {
            console.error("Error in 'RoyaltyPlanManagement.js -> createForms()':" + error);
        }
    }
    const addRow = (event) => {
        let tempObjectList = getArrayParam(objectList);
        let rowId = getUniqueId();
        let objectName = tempObjectList[0].name;
        let dataRow = getArrayParam(royalityRules);
        let rowObj = {
            id: rowId,
            formula: "",
            object: tempObjectList[0].id,
            objectName: objectName,
            field_id: 0,
            calculateField: '',
            ruleFields: null,
        }
        dataRow.push(rowObj);
        setRoyalityRules({ ...dataRow });
        onObjectFieldChange(rowObj, objectName);
        setRoyaltyPlansObject({ ...royaltyPlansObject});
        
    }
   const onObjectFieldChange = (rowObj, object) => {
        let data = Object.assign({}, sfForm.data[object]);
            let tempreportedSalesSelectedFields = reportedSalesSelectedFields;
            let filteredTempFields = data.fields;
            
            if (object === constants.REPORTED_SALES_OBJECT && tempreportedSalesSelectedFields.length > 0){
                tempreportedSalesSelectedFields.forEach((searchableField, index) => {
                    filteredTempFields = filteredTempFields.filter(f => {
                        let temp;
                        if (f.name !== searchableField.name) {
                            temp = f.name;
                        }
                        return temp;
                    });
                });
            }else{
                filteredTempFields = data.fields;
            }
            populateFormulaFields(rowObj.id, filteredTempFields);
        
    }
    
    const populateFormulaFields = (rowId, fields) => {
        let _tempFields = fields;
        let tempRoyalityRules = getArrayParam(royalityRules);
        if (fields && fields.length > 0) {
            _tempFields = fields.filter((f)=>{return f?.name?.startsWith("royalty_")});
            let _marketingFields = fields.filter((f)=>{return f.name === "marketing_fee"});
            let _TechFields = fields.filter((f)=>{return f?.name === "tech_fee"});
            let _OthersFields = fields.filter((f)=>{return f?.name === "other_fee"});
            let _totalSalesField = fields.filter((f)=>{return f?.name === "total_sale"});
            let allFilteredFields = [..._marketingFields,..._TechFields,..._OthersFields,..._totalSalesField];
            _tempFields = [..._tempFields ,...allFilteredFields];

            _tempFields = _tempFields.filter(f => {
                return (f.field_type === constants.FIELD_TYPE_INTEGER
                    || f.field_type === constants.FIELD_TYPE_FLOAT)
                    && f.name !== 'created_on' && f.name !== 'updated_on' && f.name !== 'permission_date' 
            }).map(m => { return { name: m.name, t_tablefldid:m.t_tablefldid,id: m.id, label: m.label, field_type_id: m.field_type_id,field_type:m.field_type } });
        }

        let sortFields = _tempFields.sort((obj1, obj2) => { return (obj1.label > obj2.label) ? 1 : -1 });
        tempRoyalityRules.map(row => {
            if (row.id === rowId) {
                if (sortFields.length > 0) {
                    row.ruleFields = sortFields;
                    row.field_id = 0;
                    row.calculateField = '';
                } else {
                    row.ruleFields = null;
                    row.field_id = 0;
                    row.calculateField = '';
                }
            }
        });
        setRoyalityRules(tempRoyalityRules);
    }
    const removeSelectedFields =(rowObj,royalityRulesArr)=>{
        let objectName = TABLEID_OBJECT_MAP[rowObj.object];
        let data = Object.assign({}, sfForm.data[objectName]);
        let tempreportedSalesSelectedFields = reportedSalesSelectedFields;
            let tempCalculatedField = rowObj.calculateField;
            let filteredTempFields = data.fields;
            let temproyalityRules = [];
            if (rowObj.object === OBJECT_TABLEID_MAP[constants.REPORTED_SALES_OBJECT]) {
                let tempExistingField = []
                if (tempCalculatedField != '') {
                    tempExistingField = data.fields.filter((obj) => { return obj.name === tempCalculatedField });
                    let index = tempreportedSalesSelectedFields.findIndex(v => v.name === tempExistingField[0].name);
                    tempreportedSalesSelectedFields.splice(index, 1);
                }
                setReportedSalesSelectedFields(tempreportedSalesSelectedFields);
                royalityRulesArr.forEach(row => {
                    if (row.object === rowObj.object) {
                        if (rowObj.object === OBJECT_TABLEID_MAP[constants.REPORTED_SALES_OBJECT]) {
                       
                            tempreportedSalesSelectedFields.forEach((searchableField, index) => {
                                filteredTempFields = filteredTempFields.filter(f => {
                                    let temp;
                                    if (f.name !== searchableField.name) {
                                        temp = f.name;
                                    }
                                    return temp;
                                });
                            });
                            if (row.id === rowObj.id) {
                            } else {
                                let tempSelectedField = data.fields.filter((obj) => { return obj.name === row.calculateField });
                                if(tempSelectedField.length > 0){
                                    filteredTempFields.push(tempSelectedField[0]);
                                }
                            }
                        }
                        // if (filteredTempFields && filteredTempFields.length > 0) {
                        //     filteredTempFields = filteredTempFields.filter((f)=>{return f?.name?.startsWith("royalty_")})
                        //     filteredTempFields = filteredTempFields.filter(f => {
                        //         return (f.field_type === constants.FIELD_TYPE_INTEGER
                        //             || f.field_type === constants.FIELD_TYPE_FLOAT)
                        //             && f.name !== 'created_on' && f.name !== 'updated_on' && f.name !== 'permission_date' && f.name !== 'other_fee' && f.name !== 'tech_fee'
                        //             && f.name !== 'marketing_fee' && f.name !== 'royalty_fee'
                        //     }).map(m => { return { name: m.name,t_tablefldid:m.t_tablefldid, id: m.id, label: m.label, field_type_id: m.field_type_id, field_type: m.field_type } });
            
                        //     filteredTempFields = filteredTempFields.sort((obj1, obj2) => { return (obj1.label > obj2.label) ? 1 : -1 });
                        // }
                        filteredTempFields = filteredTempFields.sort((obj1, obj2) => { return (obj1.label > obj2.label) ? 1 : -1 });
                        row.ruleFields = filteredTempFields;
                    }
                    temproyalityRules.push(row);
                });

                setRoyalityRules([...temproyalityRules]);
            }
            setRoyaltyPlansObject({ ...royaltyPlansObject});       
    }
    const handleDropDownChange = (event, name, rowId) => {
        let isValid = true;
        let id = event.target.value;
        let tempRoyalityRules = getArrayParam(royalityRules);
        let options = getArrayParam(objectList);
        tempRoyalityRules.forEach(row => {
             if (row.id === rowId && name === "calculateField") {
                let tempRuleFields = row.ruleFields;
                let fields = tempRuleFields.filter((obj) => { return obj.t_tablefldid === id });
                if(row.object === OBJECT_TABLEID_MAP[constants.REPORTED_SALES_OBJECT]){
                    preparesortFields(tempRoyalityRules,fields,row.object,row);
                }
                row.field_id = fields[0].t_tablefldid;
                row.calculateField = fields[0].name;
            }
        });

        setRoyalityRules([...tempRoyalityRules]);

    }
    const preparesortFields = (royaltyRules, selectedField, object, rowObj) => {
        let objectName = TABLEID_OBJECT_MAP[object];
        let data = Object.assign({}, sfForm.data[objectName]);
        let filteredTempFields = data.fields;
        let temproyalityRules = [];
        let tempreportedSalesSelectedFields = reportedSalesSelectedFields;
        let tempCalculatedField = rowObj.calculateField;
        if (object === OBJECT_TABLEID_MAP[constants.REPORTED_SALES_OBJECT]) {
            
            let tempExistingField = []
            if (tempCalculatedField != '') {
                tempExistingField = data.fields.filter((obj) => { return obj.name === tempCalculatedField });
                let index = tempreportedSalesSelectedFields.findIndex(v => v.name === tempExistingField[0].name);
                tempreportedSalesSelectedFields.splice(index, 1, selectedField[0]);
            } else {
                tempreportedSalesSelectedFields.push(selectedField[0]);
            }
            setReportedSalesSelectedFields(tempreportedSalesSelectedFields);
        }
        royaltyRules.forEach(row => {
            if (row.object === object) {
                if (object === OBJECT_TABLEID_MAP[constants.REPORTED_SALES_OBJECT]) {
                    tempreportedSalesSelectedFields.forEach((searchableField, index) => {
                        filteredTempFields = filteredTempFields.filter(f => {
                            let temp;
                            if (f.name !== searchableField.name) {
                                temp = f.name;
                            }
                            return temp;
                        });
                    });
                    if (row.id === rowObj.id) {
                        filteredTempFields.push(selectedField[0]);
                    } else {
                        let tempSelectedField = data.fields.filter((obj) => { return obj.name === row.calculateField });
                        filteredTempFields.push(tempSelectedField[0]);
                    }
                }
                // if (filteredTempFields && filteredTempFields.length > 0) {
                //     filteredTempFields = filteredTempFields.filter((f)=>{return f?.name?.startsWith("royalty_")})
                //     filteredTempFields = filteredTempFields.filter(f => {
                //         return (f.field_type === constants.FIELD_TYPE_INTEGER
                //             || f.field_type === constants.FIELD_TYPE_FLOAT)
                //             && f.name !== 'created_on' && f.name !== 'updated_on' && f.name !== 'permission_date' && f.name !== 'other_fee' && f.name !== 'tech_fee'
                //             && f.name !== 'marketing_fee' && f.name !== 'royalty_fee'
                //     }).map(m => { return { name: m.name, t_tablefldid:m.t_tablefldid, id: m.id, label: m.label, field_type_id: m.field_type_id, field_type: m.field_type } });

                    // filteredTempFields = filteredTempFields.sort((obj1, obj2) => { return (obj1.label > obj2.label) ? 1 : -1 });
                // }
                filteredTempFields = filteredTempFields.sort((obj1, obj2) => { return (obj1.label > obj2.label) ? 1 : -1 });
                row.ruleFields = filteredTempFields;
            }
            temproyalityRules.push(row);
        });

        setRoyalityRules([...temproyalityRules]);
    }
    const handleFormulaChange = (rowId, event) => {
        let tempRoyalityRules = getArrayParam(royalityRules);
        tempRoyalityRules.forEach(row => {
            if (row.id === rowId) {
                row.formula = event.target.value;
            }
        });

        setRoyalityRules(tempRoyalityRules);
        setRoyaltyPlansObject({ ...royaltyPlansObject});       
}
    const getAddFormulaRows = (row, index) => {
        let sfFormFieldContainerStyle = { ...styles.sfFormFieldContainer };
        let id = index;
        let elementName = "add_row_" + id;
        let inputTextStyle = { width: '40px', height: '35px', marginLeft: '10px', float: 'left', textAlign: 'center' };
        let fieldcontainerStyle = { width: '100%', cursor: "pointer", fontSize: '14px', color: '#717171', height: '35px', padding: 5, }
       let fields = row?.ruleFields;
       let _tempFields = fields;
       let sortFields =[]
       if (fields && fields.length > 0) {
        _tempFields = fields.filter((f)=>{return f?.name?.startsWith("royalty_")});
        let _marketingFields = fields.filter((f)=>{return f.name === "marketing_fee"});
        let _TechFields = fields.filter((f)=>{return f?.name === "tech_fee"});
        let _OthersFields = fields.filter((f)=>{return f?.name === "other_fee"});
        let _totalSalesField = fields.filter((f)=>{return f?.name === "total_sale"});
        let allFilteredFields = [..._marketingFields,..._TechFields,..._OthersFields,..._totalSalesField];
        _tempFields = [..._tempFields ,...allFilteredFields];

        _tempFields = _tempFields.filter(f => {
            return (f.field_type === constants.FIELD_TYPE_INTEGER
                || f.field_type === constants.FIELD_TYPE_FLOAT)
                && f.name !== 'created_on' && f.name !== 'updated_on' && f.name !== 'permission_date' 
        }).map(m => { return { name: m.name, t_tablefldid:m.t_tablefldid,id: m.id, label: m.label, field_type_id: m.field_type_id,field_type:m.field_type } });
        sortFields = getArrayParam(_tempFields).sort((obj1, obj2) => { return (obj1.label > obj2.label) ? 1 : -1 });
    }


        let newElement =
            <div name={elementName} className="row" style={{marginLeft:'-9px'}} key={"add-row-div-conatiner-" + index} id={elementName} >
                <div style={{ width: '100%', marginTop: '20px', fontWeight: 'normal' }} name='add_formula' id="add_formula01">

                    <div style={{ width: '20%', float: 'left', marginLeft: '10px', marginRight: '15px', marginTop: '-9px' }} >
                       
                            <div
                                style={{ ...sfFormFieldContainerStyle, width: '100%', cursor: "pointer", fontSize: 14, color: '#717171', height: '35px', padding: 5, }}

                                key={"remind_on-div" + id}
                                name={"remind_on-div" + id}
                            >
                                <FormControl variant="outlined">
                                <InputLabel id="sf-automation-fields-update-from-automationfieldupdate-simple-select-outlined-label" className="sf-automation-fields-update-from-automationfieldupdates"> </InputLabel>
                                    <Select
                                        labelId="sf-notificationformulas-simple-select-outlined-label"
                                        id="sf-notificationformulas-result-simple-select-outlined"
                                        value={(row.ruleFields !== null) ? row.field_id : getLocalizedStrings().label.NOTIFICATION_REMINDERS.NO_DATE_FIELD}
                                        style={{ width: '343px', height: '42px' }}
                                        className={"sf-fields-bg"}
                                        onChange={(event) => handleDropDownChange(event, "calculateField", row.id)}
                                    >
                                        {sortFields != null && sortFields.length > 0? getFormulaItems(sortFields) : row.ruleFields != null && getFormulaItems(row.ruleFields)}

                                    </Select>
                                </FormControl>

                            </div>
                        
                        <FormControl
                            style={{
                                width: "216%",
                                border: "0px",
                                marginTop: "-32px",
                                marginLeft: "259%",

                            }}
                            className="sf-textarea"
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                variant="outlined"
                                type="textarea"
                                multiline={true}
                                minRows={2}
                                label={'Formula'}
                                InputLabelProps={{
                                    style: {
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                    },
                                }}
                               
                                onChange={(event)=>handleFormulaChange(row.id,event)}
                                style={{ marginBottom: "5px" }}
                                value={row.formula}
                                inputprops={{
                                    maxLength:
                                       512
                                }}
                               
                                autoComplete="new-password"
                                className={"sf-fields-bg"}
                            />
                        </FormControl>
                    </div>


                    <div style={{ fontSize: '20px', float: 'right', cursor: "pointer", paddingTop: '5px' }}> <i className="fa fa-times" name={elementName} onClick={(event) => deleteRow(event, row.id)} title={getLocalizedStrings().label.COMMON.DELETE} ></i></div>
                </div>
            </div>
        return newElement;
    }
    const getFormulaItems =(ruleFields)=> {
        let tempFieldElements;
        tempFieldElements = getArrayParam(ruleFields);
        tempFieldElements = tempFieldElements.map((item, index) => {
                return <MenuItem key={item.t_tablefldid} value={item.t_tablefldid}  style={styles.popoverMenuItem} >{item.label}</MenuItem>;
            });
        return tempFieldElements;
    }
  
   const setMultiSelectValue = ( event) => {
    const {
        target: { value },
     } = event;
          setRoyaltySelectedFields(typeof value === 'string' ? value.split(',') : value);
    }
    const deleteRow = (event, id) => {
        let temproyalityRules = getArrayParam(royalityRules);
        let RowObj = temproyalityRules.filter((obj, index) => { return obj.id === id });
        temproyalityRules = temproyalityRules.filter((obj, index) => { return obj.id !== id });

        setRoyalityRules([...temproyalityRules]);
        setRoyaltyPlansObject({ ...royaltyPlansObject});
        removeSelectedFields(RowObj[0],temproyalityRules);
    }

    if (mounted) {
        let tempRoyalityRules = getArrayParam(royalityRules);
        let addRowElement = tempRoyalityRules.map((row, index) => { return getAddFormulaRows(row, index) });

        return (
            <div>
                {isLoading &&
                    <div style={{ height: '5px', paddingTop: '5px' }}>
                        <div style={{ textAlign: 'center' }}>
                            <span className="loader"><span className="loader-box"></span><span className="loader-box"></span><span className="loader-box"></span></span>
                        </div>
                    </div>
                }
                <div style={{ padding: '0px 26px' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 0' }}>
                        {generateHeaderbuttons()}
                    </div>
                    {createForms()}

                    { addRowElement }
                     <div className="row" >
                         <div style={{ width: '100%', marginTop: '20px', fontWeight: 'normal',fontSize:"14px" }} name='add_formula_label' id="add_calculated_label">
                            <span><a href='/#' onClick={(event)=>addRow(event)} >{getLocalizedStrings().label.ROYALTY_PLAN.ADD_CALCULATED_FIELD}</a></span>
                        </div> 
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 0' }}>
                        {generateHeaderbuttons()}
                    </div>
                    
                </div>
            </div>
        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }
}

export default RoyaltyPlanManagement;
