 const message = {
    COMMON: {
        NO_ACTIONS_AVAILABLE: "Aucun objet disponible pour l'action d'ajout rapide",
        NOTES_REMOVE_MESSAGE:'Les notes sélectionnées seront supprimées. Es-tu sûr?',
        SELECTED_NOTE_MESSAGE:'Une note doit être sélectionnée au moins pour effectuer cette action.',
        BR_WINDOW_OPEN_CONFORMATION: 'Nous ne prenons pas en charge la fonction glisser-déposer pour les campagnes. Souhaitez-vous ouvrir la campagne?',
        CONFIRM_DIALOG_TITLE: 'Boîte de dialogue de confirmation',
        ALERT_DIALOG_TITLE: "Boîte de dialogue d'alerte",
        XMS_SUCCESS_ALERT_DIALOG_TITLE: 'Succès',
        XMS_ERROR_ALERT_DIALOG_TITLE: 'Erreur',
        SAVE: 'Enregistré avec succès.',
        MERGE: 'Fusionné avec succès.',
        UPDATE: 'Mis à jour avec succés.',
        DELETE: 'Supprimé avec succès.',
        REMOVE: 'Supprimé avec succès.',
        COPY_EMAIL:'L identifiant de messagerie a été copié dans votre presse-papiers',
        COPY_MOBILE:'Le numéro de téléphone portable a été copié dans votre presse-papiers',
        COPY_PHONE:'Le numéro de téléphone a été copié dans votre presse-papiers',
        EMAIL_VALIDATION:'Veuillez saisir une adresse e-mail valide.',
        EMPTY_EMAIL:'Aucun email n est présent à copier',
        EMPTY_MOBILE:"Aucun mobile n'est présent pour copier",
        EMPTY_PHONE:"Aucun téléphone n'est présent pour copier",
        UNLINK: 'Dissociation réussie.',
        ERROR: 'Le système ne répond pas.',
        ACCESS_DENIED: "Désolé, vous n'êtes pas autorisé à effectuer cette opération. Veuillez contacter votre administrateur.",
        NOTIFICATION_INFO_EMPTY: 'Aucune notification disponible',
        /** START:  Validation message */
        BLANK: 'Vide',
        EMAIL: 'est une adresse e-mail invalide.',
        VALID_EMAIL: "L'adresse e-mail que vous avez saisie ne semble pas être une adresse valide. Veuillez réessayer. Conseil: veuillez vous assurer que l'adresse e-mail est au format e-mail \\ @ domain.com.",
        URL: 'est une URL non valide.',
        VALID_URL: 'Veuillez saisir une URL valide.',
        INTEGER: "n'est pas un entier.",
        VALID_INTEGER: 'Veuillez saisir un entier valide.',
        FLOAT: "n'est pas un flotteur.",
        VALID_FLOAT: 'Veuillez saisir un flottant valide.',
        VALID_PHONE: 'Les numéros de téléphone doivent être numériques!!',
        VALID_LIST: 'Veuillez sélectionner une valeur.',
        TOO_BIG_SIZE: 'La valeur que vous avez saisie est trop grande pour ce champ. Entrez une valeur inférieure.',
        NO_RECORD: 'Aucun Enregistrement Trouvé.',
        VALIDATE_BLANK: 'ne peut être vide.',
        VALIDATE_INTEGER: 'doit être un entier.',
        VALIDATE_DECIMAL: 'doit être en décimal.',
        VALIDATE_POSITIVE_DECIMAL: 'doit être supérieur à 0 et en décimal.',
        VALIDATE_URL: "Format d'URL non valide. Exemple - www.soffront.com",
        VALIDATE_EMAIL: "Format d'email invalide.",
        VALUE: 'valeur',
        LIBRARY_DIALOG_TITLE: 'Bibliothèque en ligne de Soffront',
        BLANK_EMAIL: "L'e-mail est vide",
        INVALID_EMAIL: 'Veuillez fournir un e-mail valide!',
        BLANK_NUMBER : 'Le numéro est vide',
        INVALID_NUMBER :'Veuillez fournir un numéro valide',
        EMPTY_SEARCH_CRITERIA: 'Veuillez saisir les critères de recherche',
        VALIDATE_MORE_CHECKBOX_SELECTION: 'Vous ne pouvez pas effectuer de recherche avec <*> lorsque plusieurs cases à cocher sont cochées!',
        VALIDATE_NO_CHECKBOX_SELECTION: 'Vous ne pouvez pas effectuer de recherche avec <*> lorsque plus d’aucune case à cocher est cochée!',
        NO_DATA: 'Aucune donnée disponible',
        DISCONNECT_ALERT_MSG: 'Êtes-vous sûr de déconnecter ce client?',
        DISCONNECT_SUCCESS: 'Vous avez déconnecté avec succès votre client.',
        CONFIRM_DELETE_RECORD: "L'enregistrement sera supprimé. Êtes-vous sûr?",
        DELETE_SUCCESS: ' a été supprimé avec succès.',
        COULDNOT_LOCATE: "Nous n'avons pas pu localiser ",
        DELETED_OR_ACCESS_DENIED: " Il a peut-être été supprimé ou vous n'y avez peut-être pas accès ou vous l'avez déjà ouvert.",
        CLICK: 'Cliquez sur',
        HERE: 'ici',
        MERGE_ALERT_1: 'Êtes-vous sûr de fusionner',
        WITH: 'avec',
        MERGE_ALERT_2: 'et supprimer',
        DATA_ACCESS_REQUEST_SEND_SUCCESS: "Demande d'accès aux données envoyée à",
        DATA_ACCESS_REQUEST_SEND_FAILURE: "La demande d'accès aux données ne peut pas être envoyée à",
        REQUEST_DATA_ACCESS: "Demander l'accès aux données de",
        WE_WILL_EMAIL: 'Nous vous enverrons un e-mail',
        AT: 'à',
        TO_APPROVE_REQUEST: 'pour approuver votre demande',
        HAVE_BEEN: 'a été',
        REMINDER_VALUE: 'La valeur du rappel ne peut pas être vide',
        DUPLICATE_EMAIL: "Vous avez déjà des enregistrements avec le même identifiant de messagerie. Cliquez sur OK pour continuer ou sur Annuler pour modifier l'ID de messagerie.",
        DUPLICATE_CONTACT_EMAIL: 'Vous avez déjà des enregistrements avec le même identifiant de messagerie. Veuillez saisir un autre identifiant de messagerie.',
        CLIENT_CONNECT_MESSAGE: "Ce client n'a pas de compte avec cet e-mail dans Soffront Online. Demandez d'abord au client de créer un compte avec le même e-mail.",
        BLANK_CAMPAIGN_TYPE: 'Le type de campagne ne peut pas être vide.',
        DUPLICATE_CAMPAIGN_TYPE: 'Le type de campagne existe déjà.',
        CAMPAIGN_TYPE_SAVE_SUCCESS: 'Le type de campagne a bien été enregistré.',
        /** END:  Validation message */
        STEPPER_BACK_MESSAGE: "Aimez-vous accéder à la page d'informations sur le modèle? Toutes les modifications non enregistrées seraient perdues.",
        CENCEL_MOSAICO_MESSAGE: 'Voulez-vous vraiment annuler sans enregistrer?',
        NO_RESULTS_FOUND: 'Aucun résultat trouvé',
        FOR: 'pour',
        ENABLE_MAILING_SUCCESS: "Envoi d'e-mail activé avec succès",
        DISABLE_MAILING_SUCCESS: "Envoi d'e-mails désactivé avec succès",
        MAILING_FAILURE: "Ce compte n'est pas un client CRM",
        TEST: 'Test',
        SELECT_RECORD_FOR_DELETE: 'Veuillez sélectionner un enregistrement à supprimer.',
        SELECT_RECORD_FOR_EDIT_ONE_RECORD: 'Veuillez sélectionner un enregistrement à modifier.',
        SELECT_RECORD_FOR_DELETE_ONE_RECORD: 'Veuillez sélectionner un enregistrement à supprimer.',
        ASSETMENU_DIALOG_TITLE: 'Sélectionnez un actif',
        EMPTY_RECYCLE_BIN_MSG: 'Veuillez sélectionner les enregistrements pour vider la corbeille.',
        UNIT_SELECT_ALERT_MESSAGE: 'Vous pouvez sélectionner une unité à la fois.',
        LOOKUP_MULTI_SELECT: 'Veuillez sélectionner un seul enregistrement.',
        LOOKUP_SELECT_A_RECORD: 'Veuillez sélectionner un enregistrement.',
        TITLE_FIELD_BLANK: 'Le champ de titre ne peut pas être vide',
        BOTH_COMPANY_AND_WORKFLOW_SELECTED: "Vous avez sélectionné à la fois l'entreprise et le workflow.",
        VALIDATE_ZERO: 'ne peut pas être zéro.',
        GREENLIGHT_EXPORT_NO_RECORD: "Il n'y a aucun compte dans le workflow Greenlight pour cette plage de dates.",
        WITHOUT_SAVE_MESSAGE: 'Voulez-vous continuer sans enregistrer les changements?',
        DELETE_API_KEY: 'Cela supprimera votre clé API dans CRM. Voulez-vous continuer ?',
        API_KEY_MSG_1: 'La clé api est une chaîne cryptée unique attribuée par',
        API_KEY_MSG_2: "qui identifie l'utilisateur effectuant un appel API. Cela remplace l'authentification de base par nom d'utilisateur / mot de passe.",
        API_KEY_MSG_3: 'Générer la clé API!',
        API_KEY_MSG_4: 'Clé API générée',
        SOFFRONT: 'Soffront',
        UNIT_ALERT: 'Veuillez sélectionner un enregistrement pour vous connecter.',
        THIS_FIELD_CANNOT_BLANK: 'Ce champ ne peut être vide.',
        CANNOT_DELETE_ALL_ITEM: 'Vous devez avoir au moins un élément dans le menu mobile.',
        DUPLICATE_MSG_1: 'Vous avez déjà enregistrement (s) avec la même ',
        DUPLICATE_MSG_2: '. Cliquez sur OK pour continuer ou cliquez sur Annuler pour changer la ',
        DUPLICATE_MSG_3: ' et ',
        DUPLICATE_CONTACT_EMAIL_1: 'Vous avez déjà enregistrement (s) avec la même ',
        DUPLICATE_CONTACT_EMAIL_2: ". S'il vous plaît entrer différents ",
        INVALID_TIME_ERROR_MSG: "S'il vous plaît fournir un intervalle de temps valide.",
        DOESNT_HAVE_ASSOCIATED_1:'Ce ',
        DOESNT_HAVE_ASSOCIATED_2: " n'a pas de contact associé.", 
        PHONE_CALL_NOT_ENABLED: "L'appel téléphonique n'est pas activé.",
        NOT_CONFG_CALL_SETUP : "Vous n'avez pas configuré la configuration des appels.",
        NOT_CONFG_CALL_SMS_SETUP: "Vous n'avez pas configuré la configuration des appels / SMS.",
        SEARCH_NO_CHECKBOX_SELECTION: "Vous ne pouvez pas effectuer de recherche si aucune case n'est cochée !",
        VALID_SOURCE_LIST: 'Veuillez sélectionner le champ source.',
        LMS_USER_NOT_AVAILABLE: 'LMS n\'est pas encore activé. Veuillez contacter votre administrateur.',   
        ADD_BRANCH_BILLINGMASSAGE:'Vous n\'avez actuellement aucune licence. Vous achèterez une nouvelle licence pour cette unité à 40,00 $/mois. De plus, des frais d\'installation de 100,00 $ seront facturés pour installer et former le nouveau locataire.'
    },
    SALES_TEMPLATE: {
        NAME_BLANK: 'Veuillez saisir un nom pour identifier ce modèle.',
        SUBJECT_BLANK: 'Veuillez entrer un sujet pour ce modèle.',
        DUPLICATE_NAME: "Vous disposez déjà d'un modèle nommé %%. Nous ne prenons pas en charge les modèles en double. Veuillez saisir un autre nom et réessayer.",
        DELETE: 'Le modèle sera supprimé. Êtes-vous sûr?',
        TEMPLATE_DELETED: 'Modèle supprimé avec succès',
        TEMPLATE_NAME_ERROR: 'Vous avez déjà un modèle existant avec ce nom. Veuillez saisir un autre nom et réessayer.',
    },
    AGREEMENT_TEMPLATE: {
        NAME_BLANK: 'Pveuillez saisir un nom pour identifier ce modèle.',
        // SUBJECT_BLANK: 'Please enter a subject for this template.',
        DUPLICATE_NAME: "Vous disposez déjà d'un modèle nommé %%. Nous ne prenons pas en charge les modèles en double. Veuillez saisir un autre nom et réessayer.",
        DELETE: 'Le modèle sera supprimé. Êtes-vous sûr?',
        TEMPLATE_DELETED: 'Modèle supprimé avec succès',
        TEMPLATE_NAME_ERROR: 'Vous avez déjà un modèle existant avec ce nom. Veuillez saisir un autre nom et réessayer.',
        DUPLICATE_TEMPLAE_EXIST: 'Ce nom de modèle existe déjà.',
        RECORD_NOT_EXIST: "Cet enregistrement n'existe pas.",
        SAVE_TO_DOWNLOAD: "Vous n'avez pas encore enregistré le modèle d'accord. Veuillez d'abord enregistrer le modèle de contrat pour le télécharger.",
        TEMPLATE_CAN_NOT_EMPTY: "Le nom du modèle d'accord ne peut pas être vide.",
        SELECT_TEMPLATE: 'Veuillez sélectionner un modèle.',
        SENT_OR_SIGNED: "Vous avez déjà envoyé cet accord une fois. Vous ne pouvez pas l'envoyer à nouveau.",
        SIGNATURE_TAGS_NOT_SEQUENCED_1: 'Veuillez ajouter les balises de signature dans un ordre séquentiel.',
        SIGNATURE_TAGS_NOT_SEQUENCED_2: 'Il semble que vous ayez ignoré l\'ordre séquentiel lors de l\'ajout de la balise de signature.',
        SIGNATURE_TAGS_MISSING: 'Aucune balise de signature n\'est disponible dans le corps de l\'accord. Le corps de l’accord doit avoir au moins une balise de signature.',
        SIGNATURE_TAGS_CONTACT_FIELDS_COMBO_MISSING: 'Il semble que vous ayez utilisé des champs de fusion pour une signature sans utiliser la balise de signature. Veuillez ajouter la balise de signature pour continuer.',
        AGREEMENT_EDIT: 'Vous pouvez effectuer des modifications uniquement sur les enregistrements d\'accord et non sur les autres enregistrements de pièces jointes ou d\'enregistrements d\'accord déjà envoyés.',
        AGREEMENT_EXPIRED_1: 'Cet accord a expiré le ',
        AGREEMENT_EXPIRED_2: 'Veuillez prolonger la date d\'expiration du contrat avant de l\'envoyer.',
    },
    SMS_TEMPLATE: {
        BLANK_MESSAGE: 'Veuillez saisir un message pour enregistrer ce modèle.',
        SAVE_MESSAGE: 'SMS enregistré avec succès.'
    },
    MARKETING_TEMPLATE: {
        NAME_BLANK: 'Veuillez saisir un nom pour identifier ce modèle.',
        SUBJECT_BLANK: 'Veuillez entrer un sujet pour ce modèle.',
        DUPLICATE_NAME: "Vous disposez déjà d'un modèle nommé %%. Nous ne prenons pas en charge les modèles en double. Veuillez saisir un autre nom et réessayer.",
        DELETE: 'Le modèle sera supprimé. Êtes-vous sûr?',
        BACK: 'Si vous revenez en arrière, vous perdrez vos modifications non enregistrées. Voulez-vous continuer?',
        SELECT_DESIGN: 'Choisissez parmi un design',
        TEMPLATE_UPDATE: 'Modèle (s) marketing mis à jour',
        CONFIRM1: 'de',
        CONFIRM2: 'les enregistrements seront mis à jour pour',
        CONFIRM3: 'Êtes-vous sûr?',
        ALERT1: 'hors de',
        FROM_NAME_BLANK: "Veuillez saisir un nom d'expéditeur.",
        FROM_EMAIL_BLANK: 'Veuillez choisir un e-mail.',
        DUPLICATE_TEMPLATE_NAME: 'Le nom du modèle existe déjà.'
    },
    BROADCAST_CAMPAIGNS: {
        NAME_BLANK: 'Veuillez saisir un nom pour identifier cette campagne de diffusion.',
        GROUP_FILTER_BLANK_COMMON_MSG: 'Veuillez sélectionner',
        FILTER_TYPE_GROUP: 'un groupe',
        FILTER_TYPE_CONTACT: 'un filtre de contact',
        FILTER_TYPE_ACCOUNT: 'un filtre de compte',
        SUBJECT_BLANK: 'Veuillez entrer un sujet',
        FROM_NAME_BLANK: "Veuillez saisir un nom d'expéditeur",
        FROM_EMAIL_BLANK: "Veuillez sélectionner un De l'email",
        GROUP_NAME_BLANK: 'Le nom du groupe ne peut pas être vide!',
        SAVE: 'Vous avez créé cette campagne avec succès.',
        UPDATE: 'Vous avez mis à jour cette campagne avec succès.',
        GROUP_NAME_SIZE_LIMIT: 'Le nom du groupe ne peut pas comporter plus de 128 caractères.',
        EXECUTION_CANNOT_CANCEL_1: "L'exécution ne peut pas être annulée maintenant.",
        EXECUTION_CANCEL_MSG: "L'application essaiera d'annuler l'exécution immédiatement.",
        CONFIRM: 'Veuillez confirmer.',
        EXECUTION_CANNOT_CANCEL_2: 'Une campagne déjà en cours de traitement ne peut pas être annulée.',
        PURCHASE_MORE: 'pour en acheter plus.',
        CREDITS_REMAINING: "crédits d'e-mail restants.",
        VERIFY_BLANK_EMAIL: 'Vous devez entrer une adresse e-mail pour vérifier.',
        VERIFY_INVALID_EMAIL: 'est une adresse e-mail non valide.',
        SEND_VERIFICATION_EMAIL_SUCCESS: "Nous avons envoyé l'e-mail de vérification. Veuillez vérifier votre boîte de réception et cliquer sur Vérifier cette adresse e-mail. Revenez ensuite et cliquez sur l'icône d'actualisation pour voir votre nouvelle adresse e-mail.",
        EMAIL_INTEGRATION_NEW: "Cette adresse e-mail a déjà été soumise pour vérification! Veuillez vérifier votre boîte de réception et cliquez sur Vérifier cette adresse e-mail. Revenez ensuite et cliquez sur l'icône d'actualisation pour voir votre nouvelle adresse e-mail.",
        EMAIL_INTEGRATION_VERIFIED: 'Cette adresse e-mail a déjà été vérifiée!',
        BD_NAME_COPY_ERROR: 'Vous avez une campagne existante avec ce nom. Veuillez saisir un autre nom pour cette campagne',
        STEPPER_BACK_MESSAGE: "Aimez-vous accéder à la page d'informations sur la diffusion? Toutes les modifications non enregistrées seraient perdues."
    },
    SPACE: ' ',
    LIST_VIEW: {
        SELECT_RECORD: "Veuillez sélectionner un enregistrement pour effectuer plus d'action.",
        SELECT_RECORD_FOR_CONVERT: 'Veuillez sélectionner un enregistrement à convertir.',
        SELECT_RECORD_FOR_EDIT: 'Veuillez sélectionner un enregistrement à modifier.',
        SELECT_RECORD_FOR_COPY: 'Veuillez sélectionner un enregistrement à copier.',
        SELECT_RECORD_FOR_SEND_EMAIL: 'Veuillez sélectionner un enregistrement pour envoyer un e-mail.',
        MULTIPLE_RECORD_FOR_EDIT: 'Veuillez sélectionner un enregistrement à modifier.',
        MULTIPLE_RECORD_FOR_COPY: 'Veuillez sélectionner un enregistrement à copier.',
        MULTIPLE_RECORD_FOR_MORE: 'Veuillez sélectionner un enregistrement pour PLUS.',
        SELECT_RECORD_FOR_COPY_ONE_RECORD: 'Veuillez sélectionner un enregistrement à copier.',
        CANNOT_COPY_APPOINTMENT_WORKFLOW: 'Vous ne pouvez pas copier ce flux de travail.',
        SELECT_RECORD_FOR_ATTACH_ONE_RECORD: 'Veuillez sélectionner une seule pièce jointe.',
        SELECT_MAXIMUM_FIELD_FOR_SETUP: 'Vous avez atteint la limite maximale des 15 champs sélectionnés au total.',
        CONVERT_CONTACT_TO_ACCOUNT_CONFIRM: 'Ce contact est déjà associé à un compte. Cliquez sur Ok pour voir les détails du compte.',
        SHARE_PERMISSION: "Vous n'êtes pas autorisé à partager cet enregistrement.",
        NOT_ADDED: "Tu n'as pas ajouté",
        FIELDS_TO_VIEW: 'champs à afficher.',
        SETUP_MINIMUM_FIELD_ALERT: "Veuillez ajouter un autre champ. Nous avons besoin d'un minimum de deux champs pour la vue.",
        SELECT_ANOTHER_TABLE: 'Veuillez sélectionner une autre table et réessayer.',
        WEB_FORM_ALERT1: 'hors de',
        WEB_FORM_ALERT2: 'Les formulaires Web sélectionnés ne sont pas supprimés car ils sont utilisés dans Automation Designer.',
        SETUP_WORKFLOWSTAGE_MINIMUM_FIELD_ALERT: "Veuillez ajouter un champ pour la colonne. Nous avons besoin d'un minimum de 1 champ pour la vue.",
        SELECT_RECORD_FOR_SEND_SMS: 'Veuillez sélectionner un enregistrement pour envoyer un message texte.',
        SELECT_RECORD_FOR_CALL: 'Veuillez sélectionner un enregistrement pour passer un appel.',
        SELECT_MORE_RECORD_ACTION_ALERT: "Veuillez sélectionner un enregistrement pour effectuer plus d'action.",
        SELECT_RECORD_FOR_DOWNLOAD: 'Veuillez sélectionner un enregistrement pour télécharger les comptes.',
        CHECKBOX_COLOR_GREEN_TITLE: 'Dernière mise à jour dans les 30 jours',
        CHECKBOX_COLOR_YELLOW_TITLE: 'Dernière mise à jour dans les 30 à 60 jours',
        CHECKBOX_COLOR_RED_TITLE: 'Dernière mise à jour avant 60 jours',
        STATUS_FIELD_NOT_ADDED_FOR_VIEW: "Vous n'avez ajouté aucun champ de statut à afficher.",
        SELECT_MAXIMUM_FIELD_FOR_COLUMN_SETUP: 'Vous avez atteint la limite maximale de 5 champs sélectionnés au total.',
        NO_EMAIL_TO_SEND_MAIL: "Il n'y a pas d'adresse e-mail disponible pour envoyer un e-mail.",
        REQUIRED_FIELD_CONVERT_FAILED: 'Échec de la conversion. Un ou plusieurs champs obligatoires sont manquants.',
        SELECT_RECORD_FOR_To_EXCEL: 'Veuillez sélectionner un enregistrement pour exceller.',
        SELECT_RECORD_FOR_ADD_TASK: "S'il vous plaît ase sélectionnez un enregistrement pour ajouter une tâche'",
        UNIT_TASK_MESSAGE: 'Puede agregar tareas solo a las unidades que están conectadas directamente a usted.',
        UNIT_SEND_EMAIL: "Vous pouvez ouvrir l'envoi d'e-mails uniquement aux unités qui vous sont directement connectées",
        UNIT_PHONE_NUMBER: 'Vous pouvez ouvrir un appel téléphonique uniquement vers les unités qui vous sont directement connectées.',
        FDD_AND_FA_AGREEMENT_CANNOT_BE_DELETED: 'Vous n\'êtes pas autorisé à supprimer l\'enregistrement de pièce jointe de type FDD ou FA.',
        MULTIPLE_SELECT_FDD_FA_MSG_1: 'Hors de ',
        MULTIPLE_SELECT_FDD_FA_MSG_2: ' enregistrement(s) en pièce jointe(s) sélectionné(s) ',
        MULTIPLE_SELECT_FDD_FA_MSG_3: ' Les enregistrements de pièces jointes ne seront pas supprimés car il s’agit d’enregistrements de pièces jointes de type FDD ou FA. Es-tu sûr?',
    },
    AB_CAMPAIGNS: {
        NAME_BLANK: 'Saisissez un nom pour la campagne.',
        GROUP_FILTER_BLANK_COMMON_MSG: 'Veuillez sélectionner',
        FILTER_TYPE_GROUP: 'un groupe',
        FILTER_TYPE_CONTACT: 'un filtre de contact',
        FILTER_TYPE_ACCOUNT: 'un filtre de compte',
        GROUP_FILTER_BLANK: 'Sélectionnez un groupe.',
        CONTACT_FILTER_BLANK: 'Veuillez sélectionner un filtre de contact.',
        ACCOUNT_FILTER_BLANK: 'Veuillez sélectionner un filtre de compte.',
        SUBJECT_BLANK: 'Saisissez un objet pour ce message.',
        FROM_NAME_BLANK: "Saisissez le nom de l'expéditeur.",
        FROM_EMAIL_BLANK: "Saisissez l'adresse e-mail de l'expéditeur.",
        GROUP_NAME_BLANK: 'Le nom du groupe ne peut pas être vide!',
        AB_DISTRIBUTION: 'Entrez la distribution a / b.',
        INVALID_AB_DISTRIBUTION: 'Distribution a / b non valide.',
        INVALID_WINNING_MARGIN: "La marge du gagnant n'est pas valide.",
        ZERO_WINNING_MARGIN: 'Entrez la marge pour le gagnant.',
        MESSAGE_B_NOT_FILLED: 'Veuillez saisir les détails du message B.',
        AB_DISTRIBUTION_OUT_OF_RANGE: 'Entrez une valeur entre 1 et 99 pour le test ',
        DAYS_BLANK: "Veuillez entrer le nombre de jours que vous souhaitez attendre avant d'envoyer le message gagnant à la liste restante.",
        INVALID_DAYS: "Entrée invalide. Veuillez entrer le nombre de jours que vous souhaitez attendre avant d'envoyer le message gagnant à la liste restante.",
        SAVE: 'Vous avez créé cette campagne avec succès.',
        UPDATE: 'Vous avez mis à jour cette campagne avec succès.',
        EXECUTION_CANNOT_CANCEL_1: "L'exécution ne peut pas être annulée maintenant.",
        EXECUTION_CANCEL_MSG: "L'application essaiera d'annuler l'exécution immédiatement.",
        CONFIRM: 'Veuillez confirmer.',
        EXECUTION_CANNOT_CANCEL_2: 'Une campagne déjà en cours de traitement ne peut pas être annulée.',
        CAMPAIGN_EXECUTE_IN_60_MIN: "Cette campagne s'exécutera dans 60 minutes.",
        OK_TO_CONFIRM: 'Cliquez sur Ok pour confirmer',
        ERROR_MSG_1: 'Veuillez sélectionner le modèle.',
    },
    REPEAT_DIALOG: {
        END_DATE_BLANK: 'La date de fin ne peut pas être vide.',
        INVALID_END_DATE: 'La date de début ne peut pas être postérieure ou identique à la date de fin.',
        DAY_CHECKBOX_BLANK: 'Au moins vérifier un jour.',
        DELETE_RECUR_TITLE: 'Supprimer récurrent',
        EDIT_RECUR_TITLE: 'Modifier récurrent',
        REPEAT: 'Répéter'
    },
    BATCH_ACTION: {
        CONVERT_SUCCESSFULL: ' ont été convertis avec succès.',
        SINGLE_CONVERT_SUCCESSFULL: ' a été converti avec succès.',
        CONVERT_UNSUCCESSFULL: 'Action impossible à exécuter',
        CONVERT_SAME_STAGE:  " l'enregistrement est déjà dans ",
        MULTI_SHARE_SUCCESSFULL: ' ont été partagés avec succès.',
        SINGLE_SHARE_SUCCESSFULL: ' a été partagé avec succès.',
        SHARE_UNSUCCESSFULL: 'Action impossible à exécuter',
        MULTI_ASSIGN_SUCCESSFULLY: ' ont été attribués avec succès.',
        SINGLE_ASSIGN_SUCCESSFULLY: ' a / ont été attribués avec succès.',
        ASSIGN_UNSUCCESSFULL: 'Action impossible à exécuter',
        COMPLETE_SUCCESSFULL: ' a été terminé avec succès.',
        BATCH_COMPLETE_SUCCESSFULL: ' ont été exécutés avec succès.',
        COMPLETE_UNSUCCESSFULL: 'Action impossible à exécuter',
        GROUP_ADDED_SUCCESSFULLY: 'Ajout réussi au (x) groupe (s)',
        GROUP_REMOVED_SUCCESSFULLY: 'Suppression réussie de tous les groupes.',
        GROUP_CLICK_TO_CONFIRM: ' sera ajouté au (x) groupe (s) sélectionné (s). Cliquez sur "OK" pour confirmer.',
        GROUP_BLANK_ADDED_BATCH: 'Veuillez sélectionner au moins un groupe.',
        NOTE_TXT_CANNOT_BE_BLANK: 'Le texte ne peut pas être vide.',
        WORKFLOW_CONFIRMATION: 'Le flux de travail sera modifié pour cet enregistrement. Cliquez sur Ok pour confirmer.',
        BATCH_WORKFLOW_CONFIRMATION: 'Le flux de travail sera modifié pour ces enregistrements. Cliquez sur Ok pour confirmer.',
        WORKFLOW_APPLIED: 'Workflow appliqué avec succès.',
        RECORDS_WITH_DIFFERENT_WORKFLOW: "Vous avez sélectionné des enregistrements avec différents flux de travail. Cliquez sur OK pour continuer et effectuer l'opération d'application du flux de travail ou cliquez sur Annuler pour sélectionner à nouveau les enregistrements.",
        RECORDS_WITH_DIFFERENT_WORKFLOW_STATUS: "Vous avez sélectionné des enregistrements avec un statut différent. Cliquez sur OK pour continuer et effectuer l'opération d'application du flux de travail ou cliquez sur Annuler pour sélectionner à nouveau les enregistrements.",
        ACTIVE_SUCCESSFULL: 'Le statut a été changé en Actif.',
        INACTIVE_SUCCESSFULL: 'Le statut a été changé en Inactif.',
        BATCH_EDIT_BLANK: 'Le champ de valeur ne peut pas être vide!',
        INVALID_WORKFLOW_STAGE: "Vous ne pouvez pas convertir l'enregistrement. Le statut de l'enregistrement sélectionné n'est pas disponible dans le workflow actuel. Appuyez sur OK pour modifier l'état ou le flux de travail",
        CONFIRMATION_DELETE_MSG: ' sera supprimé. Êtes-vous sûr?',
        CONFIRMATION_REMOVE_MSG: ' sera supprimé. Êtes-vous sûr?',
        CONFIRMATION_UNLINK_MSG: ' sera dissocié. Êtes-vous sûr?',
        CONVERT_ALERT_MSG: ' sera converti en ',
        CLICK_TO_CONFIRM: ' Cliquez sur Ok pour confirmer.',
        ASSIGN_ALERT_MSG: ' enregistrement (s) seront attribués à ',
        ROUND_ROBIN_BASIS: ' sur une base de tournoi à la ronde. Êtes-vous sûr?',
        CONFIRM_MSG: 'Êtes-vous sûr?',
        RECORDS: 'records',
        RECORD: 'enregistrer',
        ALL_RECORDS: 'Tous les enregistrements',
        RECORD_WILL_BE_UPDATED: 'les enregistrements seront mis à jour pour',
        CONFIRM_UNSUBSCRIBE_RECORD: ' sera désabonné. Êtes-vous sûr?',
        UNSUBSCRIBE: 'Vous avez désabonné avec succès le (s) contact (s) sélectionné (s).',
        USE_ASSIGN_RULE_ALERT_MSG: ' les enregistrements seront attribués au (x) représentant (s) des ventes conformément à ',
        EMPTY_RECYCLE_BIN: 'Tous les enregistrements seront définitivement supprimés de la corbeille. Êtes-vous sûr?',
        PERMANENT_DELETE_MSG: ' sera définitivement supprimé. Êtes-vous sûr?',
        INVALID_STATUS_FOR_WORKFLOW_STAGE: "Le statut de l'enregistrement sélectionné n'est pas disponible dans le workflow actuel. Appuyez sur Ok pour changer le statut de l'enregistrement.",
        CONTACT_STATUS_UPDATE_SUCCESS: ' le statut a été mis à jour avec succès.',
        PUBLISH_SUCCESSFULL: 'Le statut a été changé en Publié.',
        UNPUBLISH_SUCCESSFULL: 'Le statut a été changé en Non publié.',
        RECORDS_NOT_IN_WORKFLOW: 'Tous les enregistrements sélectionnés ne sont pas dans le flux de travail des contacts.',
        RECORD_NOT_IN_WORKFLOW: "Vous ne pouvez pas effectuer de conversion car l'enregistrement sélectionné ne fait pas partie du flux de travail des contacts.",
    },
    BATCH_ACTION_VALIDATION: {
        ADDTOGROUP_BLANK: "L'enregistrement sélectionné sera supprimé de tous les groupes. Cliquez sur 'OK' pour confirmer.",
        ADDTOGROUP_NAMEBLANK: 'Le nom du groupe ne peut pas être vide.',
        SHARE_WITH_BLANKUSER: 'Veuillez sélectionner au moins un utilisateur.',
        ASSIGN_BLANKUSER: 'Veuillez sélectionner un utilisateur.',
        CONVERT_SELECT_STATUS: 'Veuillez sélectionner un statut pour ',
        MAP_INSUFFICIENT_INFO: 'Informations insuffisantes pour afficher la carte',
        GROUP_ALREADY_EXIST: 'Le nom du groupe existe déjà!',
        ALREADY_ACTIVE: 'Enregistrement déjà en état Actif.',
        ALREADY_INACTIVE: 'Enregistrement déjà en état Inactif.',
        ALREADY_PUBLISH: 'Enregistrement déjà en état Publié.',
        ALREADY_UNPUBLISH: 'Enregistrement déjà au statut non publié.',
    },
    ATTACHMENT: {
        ATTACHMENT_UPLOAD: ' La pièce jointe a bien été téléversée',
        ATTACHMENT_LINK_ADDED: 'Le lien vers le document a été ajouté avec succès !',
        ATTACHMENT_UPLOAD_IMAGE: 'Image téléchargée avec succès',
        ATTACHMENTS_UPLOAD: ' Pièces jointes téléchargées avec succès',
        VALIDATE_ATTACHMENT: 'Veuillez sélectionner un fichier à joindre  ',
        ATTACHMENT_UPLOAD_FAILURE: ' Ce fichier existe déjà.',
        VALIDATE_EMPTY_FILE: ' La taille du fichier de téléchargement ne peut pas être 0 ',
        VALIDATE_FILE_SIZE: 'La taille du fichier de téléchargement ne peut pas dépasser 5 Mo',
        VALIDATE_LOGO_SIZE: 'La photo que vous avez sélectionnée est trop grande pour être utilisée. La taille de fichier maximale autorisée est de 500 Ko',
        VALIDATE_BLANK_FILE: 'Vous ne pouvez pas télécharger un fichier vierge',
        VALIDATE_LOGO_EXTENSION: 'Veuillez sélectionner un fichier jpg, gif ou png!',
        ATTACHMENT_LOGO_TEXT: 'Vous pouvez télécharger un fichier jpg, gif ou png.',
        ATTACHMENT_LOGO_SIZE_TEXT: ' La taille de fichier maximale autorisée est de 500 Ko.',
        UPLOAD_IMAGE: 'Télécharger une image',
        UPLOAD_FILE: "Sélectionnez les fichiers à télécharger (jusqu'à cinq fichiers à la fois)",
        ADD_LINK: 'Ajouter un lien externe',
        VALIDATE_MAX_ATTACHMENTS: 'Vous ne pouvez pas télécharger plus de cinq fichiers',
        SELECT_IMAGE_TO_UPLOAD: 'Sélectionnez une image à télécharger',
        MAX_SIZE_2_MB_ALERT: 'La taille du fichier de téléchargement ne peut pas dépasser 2 Mo',
        VALID_FILE_UPLOAD: 'Vous pouvez télécharger un fichier jpg, gif ou png depuis un ordinateur.',
        MAX_SIZE_2_MB: 'La taille de fichier maximale autorisée est de 2 Mo.',
        CANNOT_UPLOAD_EXECUTABLE_FILE: 'Vous ne pouvez pas télécharger le fichier exécutable',
        THE_ATTACHMENT: "L'attachement",
        DOCUMENT_LINK: 'Lien vers le document', 
        FOLDER_NAME: 'Nom de dossier',
        FILE_NAME:'Nom de fichier',
        ALREADY_EXIST: 'existe déjà.',
        CANNOT_BE_DELETED: 'ne peut pas être supprimé.',
        CLICK_TO_DELETE: 'Cliquez sur OK pour supprimer ',
        EMPTY_FOLDER_ERROR_DOWNLOAD: 'Impossible de télécharger car le dossier est vide.',
        EMPTY_FOLDER_ERROR_PUSH: 'Impossible de pousser car le dossier est vide.',
        RENAME_SUCCESSFULLY: 'Dossier renommé avec succès.',
        DELETE_SUCCESSFULLY: 'Dossier supprimé avec succès.',
        ADDED_SUCCESSFULLY: 'ajouté avec succès.',
        DOCUMENT_LINK_ADDED_SUCCESSFULLY: 'Lien vers le document ajouté avec succès.',
        DOCUMENT_LINK_UPDATE_SUCCESSFULLY: 'Lien vers le document mis à jour avec succès.',
        DOCUMENT_LINK_DELETE_SUCCESSFULLY: 'Le lien vers le document a été supprimé avec succès.',
        FILE_ADDED_SUCCESSFULLY: 'Fichier ajouté avec succès.',
        FILE_RENAME_SUCCESSFULLY: 'Fichier renommé avec succès.',
        FILE_DELETE_SUCCESSFULLY: 'Fichier supprimé avec succès.',
        IMAGE_DELETE_SUCCESSFULLY: 'Image supprimée avec succès.',
        IMAGE_RENAME_SUCCESSFULLY: 'Image renommée avec succès.',
        IMAGE_NAME: "Nom de l'image",
        MAX_SIZE_50_MB: 'La taille de fichier maximale autorisée est de 50 Mo.',
    },
    AUTOMATION_DESIGNER: {
        STEPPER_BACK_MESSAGE: "Aimez-vous accéder à la page d'informations sur le modèle? Toutes les modifications non enregistrées seraient perdues. ",
        MAX_CONNECTION: "N ° maximum des connexions ont été atteintes.",
        TOUCH_CAMPAIGN_NAME: "Veuillez sélectionner un nom pour cette campagne tactile",
        SELECT_FILTERS: "Veuillez sélectionner un",
        SELECT_FILTERS_A: "Veuillez sélectionner un",
        START_DATE: "Veuillez sélectionner une date de début pour",
        EMPTY_WEBSITE_LINK: "Vous ne pouvez pas ajouter de lien de site Web vide.",
        CANNOT_START_WITH_DECISSION: "Vous ne pouvez pas commencer par une décision. Veuillez commencer par l'une des options du menu 'Démarrer avec' à gauche.",
        CANNOT_START_WITH_ACTION: "Vous ne pouvez pas commencer par une action. Veuillez commencer par l'une des options du menu 'Démarrer avec' à gauche.",
        CONNECT_WITH_PROPER_NODE: 'Connectez-vous avec le nœud approprié',
        FROM_NAME: "Veuillez saisir un nom d'expéditeur pour",
        FROM_EMAIL: "Veuillez sélectionner un De l'email",
        RESEND_AFTER: 'Veuillez saisir Renvoyer après dans jour (s)',
        SELECT_WEB_FORM: 'Veuillez sélectionner un formulaire Web',
        SELECT_GROUP: 'Veuillez sélectionner un groupe.',
        SELECT_GROUP_OPERATION: 'Veuillez choisir une opération de groupe.',
        ENTER_NOTE: 'Veuillez saisir votre note.',
        SELECT_ONE_RECIPIENT: 'Veuillez sélectionner au moins un destinataire',
        SELECT_USER: 'Veuillez sélectionner un utilisateur.',
        ENTER_TASK_NAME: 'Veuillez saisir un nom de tâche',
        PROVIDE_DUEBY_FIELD: 'Veuillez fournir une valeur dans le champ Dû par!',
        ENTER_LINK: 'Veuillez entrer un lien.',
        PROVIDE_VALID_URL: 'Veuillez fournir une URL de site Web valide!',
        CATCH_ALL_LINK_CONNECTION: 'Vous avez déjà une condition de clic sur le lien "attraper tout"',
        ENTER_SCORE: 'Veuillez saisir un score',
        INVALID_SCORE: 'Vous avez entré un score invalide',
        ENTER_SUBJECT: 'Veuillez entrer un sujet',
        ENTER_BODY: 'Veuillez saisir le contenu du corps',
        ENTER_INTERVAL_VALUE: "Veuillez saisir une valeur pour l'intervalle",
        SELECT_ONE_HOUR: "Veuillez sélectionner 1 heure ou plus pour l'intervalle horaire.",
        BLANK_CAMPAIGN_NAME: 'Le nom de la campagne ne peut pas être vide.',
        CAMPAIGN_SAVED_SUCCESSFULLY: 'Cette campagne a été enregistrée avec succès',
        BLANK_CANVAS: 'Vous ne pouvez pas enregistrer une toile vierge.',
        NOT_ROOT_NODE: 'ne peut pas être un nœud racine.',
        START_DATE_MUST_BE_GREATER: 'La date de début doit être postérieure à la précédente campagne tactile.',
        EDIT_AND_COMPLETE_DETAILS: 'Veuillez modifier et compléter les détails de la',
        PROVIDE_WAIT_INTERVAL: "Veuillez fournir un intervalle d'attente supérieur à zéro.",
        CHECK_INTERVAL_WITH_PREVIOUS_TOUCH_CAMPAIGN: "Veuillez vérifier l'intervalle avec le (s) jour (s) de renvoi de la campagne tactile précédente.",
        CHECK_START_DATE_OR_WAIT_BEFORE_SAVE_TC: "Veuillez vérifier la date de début ou attendre avant d'enregistrer la campagne tactile.",
        PROVIDE_START_DATE: 'Veuillez fournir une date de début.',
        INTERVAL_EXPEXT_ZERO: 'Veuillez fournir un intervalle sauf zéro.',
        EMPTY_LINK_NODE: 'Vous ne pouvez pas ajouter de nœud de lien vide.',
        DELETE_NODE_CONFIRMATION: 'Le nœud sera supprimé. Voulez-vous continuer?',
        MSG_CANT_BE_DELETED: 'Ce message a été envoyé et ne peut pas être supprimé.',
        FIRST_MSG_CANT_BE_DELETED: "Vous ne pouvez pas supprimer le premier message d'une campagne tactile",
        FIRST_WAIT_CANT_BE_DELETED: "Vous ne pouvez pas supprimer la première attente d'une campagne tactile",
        BLANK_INTERVAL: "L'intervalle ne peut pas être vide",
        PROVIDE_POSITIVE_INTERVAL: 'Veuillez fournir un intervalle supérieur à 0',
        WONT_BE_ABLE_TO_DELETE: 'Vous ne pourrez pas le supprimer.',
        URL_NOT_EXIST: "L'URL qui vous est fournie n'est pas disponible dans le corps de l'e-mail.",
        GROUP_NAME_BLANK: 'Le nom du groupe ne peut pas être vide!',
        SELECT_LANDING_PAGE: 'Veuillez sélectionner une page de destination',
        RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD: "Vous avez déjà un Automation Designer ouvert, veuillez le fermer ou l'enregistrer pour ouvrir un nouveau Automation Designer.",
        SAVE_DIAGRAM_BEFORE_COPY: "Veuillez enregistrer le diagramme avant d'effectuer l'opération de copie.",
        NO_TOUCH_FOUND: 'Aucune campagne tactile trouvée.',
        ADD_ONE_FIELD: 'Veuillez ajouter au moins un champ',
        SELECT_FIELD_VALUE: 'Veuillez sélectionner une valeur de champ',
        INVALID_URL: 'est une URL non valide.',
        INVALID_INTEGER: "n'est pas un entier.",
        INVALID_EMAIL: 'est une adresse e-mail non valide.',
        ALLOWED_UPTO_FOUR_FIELDS: "La mise à jour est autorisée jusqu'à 4 champs. Vous ne pouvez pas ajouter une autre ligne de paire de valeurs de champ.",
        WILL_BE_UPDATED: 'sera mis à jour. Êtes-vous sûr?',
        DUPLICATE_TOUCH_NAME: 'Vous avez déjà nommé une campagne Send Touch',
        TOOLTIP_MESSAGE_SEND_MAIL: "Commencez par une campagne tactile qui envoie une série d'e-mails. Pour l'utiliser, faites-le glisser et déposez-le sur le canevas vers la droite.",
        TOOLTIP_MESSAGE_WEB_FORM: "Commencez par définir ce qui se passe après l'envoi d'un formulaire Web. Pour l'utiliser, faites-le glisser et déposez-le sur le canevas vers la droite.",
        TOOLTIP_MESSAGE_LANDING_PAGE: "Commencez par définir ce qui se passe après l'envoi d'une page de destination. Pour l'utiliser, faites-le glisser et déposez-le sur le canevas vers la droite.",
        TOOLTIP_MESSAGE_WEBSITE_VISIT: "Commencez par définir ce qui se passe après qu'un contact a visité une page spécifique de votre site Web. Pour l'utiliser, faites-le glisser et déposez-le sur le canevas vers la droite.",
        TOOLTIP_MESSAGE_LINK_CLICK: "Définissez ce qui se passe après qu'un contact a cliqué sur un lien de votre e-mail. Pour l'utiliser, faites-le glisser et déposez-le sur le canevas vers la droite et connectez-le avec un message tactile.",
        TOOLTIP_MESSAGE_EMAIL_OPEN: "Définissez ce qui se passe après l'ouverture d'un message tactile par un contact. Pour l'utiliser, faites-le glisser et déposez-le sur le canevas vers la droite et connectez-le avec un message tactile.",
        TOOLTIP_MESSAGE_UNSUBSCRIBE: "Définissez ce qui se passe après qu'un contact se désabonne d'un message tactile. Pour l'utiliser, faites-le glisser et déposez-le sur le canevas vers la droite et connectez-le avec un message tactile.",
        TOOLTIP_MESSAGE_SCORE_REACHED: "Définissez ce qui se passe lorsqu'un contact atteint un seuil de score. Pour l'utiliser, faites-le glisser et déposez-le sur le canevas vers la droite.",
        TOOLTIP_MESSAGE_SMS_REPLY: "Définissez ce qui se passe après qu'un contact a répondu à un sms. Pour l'utiliser, faites-le glisser et déposez-le sur le canevas vers la droite.",
        TOOLTIP_MESSAGE_AUTO_MAIL: "L'e-mail d'envoi sera utilisé pour envoyer des e-mails ponctuels et tactiles. Cette action peut être ajoutée après une campagne tactile ou toute décision ou action.",
        TOOLTIP_MESSAGE_CHANGE_GROUP: "Ajoutez ou supprimez des comptes et des contacts de groupes. Pour l'utiliser, faites-le glisser et déposez-le sur le canevas vers la droite.",
        TOOLTIP_MESSAGE_ADD_NOTE: "Ajoutez une note au compte. Pour l'utiliser, faites-le glisser et déposez-le sur le canevas vers la droite.",
        TOOLTIP_MESSAGE_NOTIFY: "Avertissez un utilisateur CRM. Pour l'utiliser, faites-le glisser et déposez-le sur le canevas vers la droite.",
        TOOLTIP_MESSAGE_ADD_TASK: "Attribuez une tâche à un autre utilisateur. Pour l'utiliser, faites-le glisser et déposez-le sur le canevas vers la droite.",
        TOOLTIP_MESSAGE_UPDATE_FIELD: "Mettez à jour la valeur d'un champ pour le compte ou le contact. Pour l'utiliser, faites-le glisser et déposez-le sur le canevas vers la droite.",
        TOOLTIP_MESSAGE_ASSIGN: "Réattribuez le compte à un autre utilisateur. Pour l'utiliser, faites-le glisser et déposez-le sur le canevas vers la droite.",
        TOOLTIP_MESSAGE_CONVERT: "Convertissez le compte en un nouveau statut. Pour l'utiliser, faites-le glisser et déposez-le sur le canevas vers la droite.",
        TOOLTIP_MESSAGE_SEND_SMS: "Envoyez des SMS à un groupe, filtre de compte ou filtre de contact. Pour l'utiliser, faites-le glisser et déposez-le sur le canevas vers la droite.",
        TOUCH_CAMPAIGN_DUPLICATE_NAME_TEXT_1: "Vous avez déjà une campagne tactile nommée",
        TOUCH_CAMPAIGN_DUPLICATE_NAME_TEXT_2: 'Veuillez saisir un autre nom. ',
        INTERVAL_MODE_ADD_MSG_1: 'Veuillez fournir un',
        INTERVAL_MODE_ADD_MSG_2: 'après la valeur précédente sauf zéro',
        AUTOMATED_CAMPAIGN_TITLE: 'Campagnes automatisées',
        CANNOT_CONNECT_MULTIPLE: 'Impossible de connecter plusieurs ',
        CONNECTION_EXIST: 'Vous avez déjà une connexion active depuis ',
        REMOVE_CONNECTION_ALERT: 'Si vous supprimez la connexion, vous perdrez toutes les étapes suivantes',
        CONTINUE_ALERT: 'Voulez-vous continuer?',
        CANNOT_ADD: 'Vous ne pouvez pas ajouter',
        DIRECT_UNDER: 'directement sous un',
        DIRECT_UNDER_AN: 'directement sous un',
        MULTIPLE_TIME_SELECTED_MSG_1: 'Vous avez sélectionné le',
        MULTIPLE_TIME_SELECTED_MSG_2: 'champ (s) plusieurs fois pour la mise à jour. Veuillez supprimer les lignes en double.',
        CANNOT_BE_LEAF_NODE: 'ne peut pas être un nœud feuille.',
        CLICK: 'Cliquez sur',
        HERE: 'ici',
        PURCHASE_MORE: 'pour en acheter plus.',
        CREDITS_REMAINING: "crédits d'e-mail restants.",
        STOP_CAMPAIGN: 'Arrêtez la campagne pour apporter des modifications.',
        SELECT_EMAIL_TEMPLATE: "Sélectionnez un modèle d'e-mail",
        NO_INFO_EXISTS: "Aucune information n'existe",
        SELECT_RECORD_FOR_COPY_ONE_RECORD: 'Veuillez sélectionner un enregistrement à copier.',
        SELECT_RECORD_FOR_EDIT_ONE_RECORD: 'Veuillez sélectionner un enregistrement à modifier.',
        CANNOT_SAVE_CAMPAIGN: "Impossible de sauvegarder cette campagne. Voir les blocs rouges dans la campagne pour les erreurs.",
        ADD_GROUP_CLICK: 'Cliquez sur',
        ADD_GROUP_CLICK_HERE: 'ici',
        CLICK_TO_ADD_NEW_GROUP: 'pour ajouter un nouveau groupe',
        MESSAGE_SAVED_SUCCESSFULLY: 'Vous avez enregistré avec succès ce message',
        MESSAGE_NOT_SAVED_SUCCESSFULLY: "Le message n'a pas été enregistré avec succès",
        CONTACT_FILTER_BLANK: 'Veuillez sélectionner un filtre de contact.',
        ACCOUNT_FILTER_BLANK: 'Veuillez sélectionner un filtre de compte.',
        QUERY_FILTER_BLANK: 'Veuillez sélectionner une requête',
        SAVE_AUTOMATION_FOR_TEST: "Veuillez enregistrer la campagne d'automatisation.",
        BIRTHDAY_CAMPAIGN_NAME: "Veuillez sélectionner un nom pour cette campagne d'anniversaire",
        BIRTHDAY_CAMPAIGN_DUPLICATE_NAME_TEXT_1: "Vous avez déjà une campagne d'anniversaire nommée",
        BIRTHDAY_CAMPAIGN_DUPLICATE_NAME_TEXT_2: 'Veuillez saisir un autre nom. ',
        BIRTHDAY_FIRST_MSG_CANT_BE_DELETED: "Vous ne pouvez pas supprimer le premier message d'une campagne d'anniversaire",
        MIGRATION: 'Cette campagne automatisée est en cours de mise à niveau vers le nouvel outil de création de campagne. Merci pour votre patience.',
        MIGRATION_DIALOG_TITLE: 'Mise à jour en cours ...',
        MIGRATION_NOTE: "Remarque: après la mise à jour, la campagne d'automatisation commencera à s'exécuter le jour où vous exécutez l'opération de démarrage.",
        BIRTHDAY_CAMPAIGN_NO_REPORT: "Campagne d'anniversaire à envoyer au (x) destinataire (s).",
        RUNNING_CAMPAIGN_CANNOT_CANCEL: 'Une campagne en cours ne peut pas être annulée.',
        CONFIRM_CANCEL_SCHEDULE: 'Souhaitez-vous annuler la campagne programmée?',
        CAMPAIGN_SCHEDULE_SUCCESSFULLY: 'Cette campagne a été planifiée avec succès.',
        SCHEDULED_CAMPAIGN_CANCEL_SUCCESSFULLY: 'Cette campagne a été annulée avec succès.',
        CAMPAIGN_START_IMMEDIATELY: 'Cette campagne a été configurée avec succès pour démarrer immédiatement.',
        ENTER_FROM_NAME: 'Veuillez saisir du nom',
        ENTER_FROM_EMAIL: 'Veuillez entrer par e-mail.'
    },
    WEBSITE_TRACKING: {
        DOMAIN_NAME_BLANK: 'Veuillez fournir un nom de domaine pour générer le code de suivi.',
        DOMAIN_NAME_INVALID: 'Veuillez fournir un nom de domaine valide.',
        TRACKING_CODE_BLANK: "Il n'y a pas de code de suivi à copier.",
        TRACKING_CODE_ALREADY_GENERATED: 'Le code de suivi est déjà généré.',
        DONE: 'Votre nom de domaine a été enregistré.',
        SET_UP_INFO: "Copiez et collez ce code dans chaque page de votre site, juste avant la balise de fin de corps. Une fois que vous avez terminé, revenez sur cette page et cliquez sur le bouton Terminé."

    },
    MAIL: {
        SUCCESS: 'Votre e mail a été envoyé.',
        BLANK_FROM_EMAIL: "L'e-mail est vide.",
        INVALID_FROM_EMAIL: "Vous avez une adresse e-mail d'expéditeur non valide dans le champ De.",
        INVALID_CC_EMAIL: 'Vous avez une adresse e-mail CC non valide dans le champ CC.',
        INVALID_BCC_EMAIL: 'Vous avez une adresse e-mail BCC non valide dans le champ BCC.',
        BLANK_SUBJECT: 'Le sujet est vide.',
        BLANK_EMAIL_BODY: "Le corps de l'e-mail est vide.",
        BATCH_SEND_MAIL_MSG_1: 'Hors de',
        BATCH_SEND_MAIL_MSG_2: 'vous avez sélectionné, seulement',
        BATCH_SEND_MAIL_MSG_3: "ont choisi de recevoir vos e-mails. Nous n'enverrions l'e-mail qu'à ces",
        BATCH_SEND_MAIL_MSG_4: 'Aucun des',
        BATCH_SEND_MAIL_MSG_5: "ont choisi de recevoir vos e-mails. Nous n'enverrons pas cet e-mail. Veuillez fermer cet onglet",
        NO_RECIPIENT: "Vous n'avez indiqué aucun destinataire pour cet e-mail",
        NO_RECIPIENT_FOUND: 'Aucun destinataire trouvé pour cet e-mail.',
        BLANK_TO_EMAIL: 'Aucune adresse e-mail dans le champ À.',
        INVALID_TO_EMAIL: 'Adresse e-mail non valide dans le champ À.',
        SCHEDULE_SUCCESS: 'Votre e-mail a été planifié avec succès.',
        RESCHEDULE_SUCCESS: "L'e-mail a été replanifié avec succès",
        TEMPLATE_NOT_SELECTED: 'Veuillez sélectionner un modèle',
        NO_ACTIVE_AGREEMENT_TEMPLATE_MSG: "Vous n'avez aucun modèle d'accord actif.",
        SELECT_DIFFERENT_SIGNER: "Please select a different signer.",
        SELECT_ALL_SIGNERS: 'Please select all the signer(s) to send the agreement email.',
    },
    APPOINTMENTS_TASKS: {
        DELETE: 'Cet enregistrement sera supprimé. Êtes-vous sûr?',
        INVALID_START_END_DATE: 'La date de début ne peut pas être postérieure ou identique à la date de fin.',
        RECURR_DELETE_DIALOG_ONLY_BTN_INFO: 'Tous les autres records de la série resteront.',
        RECURR_DELETE_DIALOG_FOLLOWING_BTN_INFO: 'Cet enregistrement et tous les enregistrements suivants seront supprimés.',
        RECURR_DELETE_DIALOG_ALL_BTN_INFO: 'Tous les enregistrements de la série seront supprimés.',
        RECURR_EDIT_DIALOG_ONLY_BTN_INFO_1: 'Tous les autres',
        RECURR_EDIT_DIALOG_ONLY_BTN_INFO_2: 'dans la série restera le même.',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_1: 'Ceci et tout ce qui suit',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_2: 'sera changé.',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_3: "Tout changement à l'avenir",
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_4: 'sera perdu.',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_1: 'Tout',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_2: 'dans la série sera modifiée.',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_3: "Toute modification apportée à d'autres",
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_4: 'sera gardé.',
        RESCHEDULE_TASK_SUCCESS: 'La tâche a été replanifiée avec succès',
        RESCHEDULE_APPOINTMENT_SUCCESS: 'Le rendez-vous a été reporté avec succès',
        NO_TASK_FOUND: 'Aucune tâche trouvée',
        NO_APPOINTMENT_FOR_TODAY: "Il n'y a pas de rendez-vous planifié disponible aujourd'hui",
        NO_PENDING_TASK_FOR_TODAY: "Aucune tâche en attente n'est disponible aujourd'hui",
        NO_TASK_APPOINTMENT_FOUND: 'Rien en attente.',
        NO_TASK_APPOINTMENT_FOUND_FOR_COMPLETED: 'Rien de terminé.',
        BLANK_START_END_DATE: 'La date de début et la date de fin ne peuvent pas être vides',
        BLANK_START_DATE: 'La date de début ne peut pas être vide',
        BLANK_END_DATE: 'La date de fin ne peut pas être vide',
        BLANK_NO_OF_DAYS: "Le nombre de jours ne peut pas être vide.",
        ZERO_NO_OF_DAYS: "Le nombre de jours ne peut pas être égal à 0.",
        BLANK_SPECIFIC_DATE: "La date ne peut pas être vide.",
        INVALID_DUEBY_END_DATE: "La date d'échéance ne peut pas être supérieure ou identique à la date de fin de répétition.",
        SEND_EMAIL_PROGRESS_MSG: "L'envoi d'e-mails est en cours, cela prendra un moment.",
        INVALID_DUEBY_START_DATE: "La date d'échéance ne peut pas être supérieure ou identique à la date de début.",
        SYNC_STARTED_MSG: "La synchronisation a commencé. Veuillez actualiser le calendrier pour voir les nouveaux rendez-vous.",
        SYNC_STARTED_MSG_FOR_SETUP: 'La synchronisation a commencé. Votre calendrier sera bientôt mis à jour.',
        ENABLE_SYNC_CAL: "Veuillez activer l'option de calendrier de synchronisation dans la configuration de l'intégration avant de procéder à la synchronisation.",
        PRIVATE_APPOINTMENT_ALERT:"Ceci est un rendez-vous privé; vous ne pouvez pas ouvrir ceci.",
        START_END_DATE_VALIDATE:'ne peut pas être supérieur ou égal à',
        CONVERT_CONFIRMATION_MESSAGE: 'Une ou plusieurs tâches ne sont pas vérifiées. Souhaitez-vous poursuivre l’opération Convert sans vérifier ces tâches ?'
    },
    TASKS:{
        DELETE_CANCELLED_STATAUS_RESTRICT_MSG:"Vous n'êtes pas autorisé à annuler une tâche d'actions correctives.",
    },
    HOME: {
        FILTER_ADD: 'Filtre ajouté avec succès',
        FILTER_NOT_FOUND: "Vous n'avez configuré aucun filtre",
        FILTER_DELETE_RECORD: 'Le filtre sera supprimé. Êtes-vous sûr?',
        FILTER_DELETE: 'Filtre supprimé avec succès',
        FILTER_ON_MAX_FIELD: "Vous avez atteint la condition de champ maximale. Nous autorisons jusqu'à 10 champs.",
        ASSIGNMENT_RULES_ON_MAX_FIELD: 'Vous avez atteint la condition de champ maximale. Nous autorisons jusqu\'à 20 champs.',
        // not found in doc
        FILTER_NAME_BLANK: 'Veuillez fournir un nom de filtre.',
        FILTER_NAME_MAX_LENGTH: 'Le nom du filtre ne peut pas comporter plus de 64 caractères.',
        FILTER_CRITERIA_EMPTY: 'Veuillez fournir au moins un critère.',
        FILTER_SQL_ERROR_NO_VALUE: ' ne peut être vide.',
        FILTER_SQL_ERROR_INTEGER: " n'est pas un entier.",
        FILTER_SQL_ERROR_FLOAT: " n'est pas un flotteur.  ",
        FILTER_SQL_ERROE_ALREADY_EXISTS: 'Le nom du filtre existe déjà!',
        FILTER_DELETE_MSG_TC: 'la ou les campagnes Touch suivantes ',
        FILTER_DELETE_MSG_BC: ' la (les) campagne (s) de diffusion suivante (s) ',
        FILTER_DELETE_MSG_AB: ' la ou les campagnes A / B suivantes ',
        FILTER_DELETE_MSG_AND: ' et ',
        FILTER_DELETE_MSG_BEING_USED: ' ne peut pas être supprimé car il est utilisé dans ',
        ADD_GROUP_SUCCESS: 'Groupe ajouté avec succès.'
    },
    DOCUMENT_LIBRARY: {
        INVALID_UPLOAD_ERROR: 'Sélectionnez un fichier image!',
        CONFIRM_DELETE_MSG: 'Êtes-vous sûr de vouloir supprimer cette image?',
        OVER_MAX_SIZE: 'Le fichier que vous avez sélectionné est trop volumineux pour être utilisé. La taille de fichier maximale autorisée est de 50 Mo',
        OVER_MAX_SIZE_IMAGE: 'Le fichier que vous avez sélectionné est trop important à utiliser. La taille maximale de fichier autorisée est de 500 kb',
        INVALID_DOCUMENT_UPLOAD_ERROR: 'Les documents exe, bat, sh ne sont pas autorisés à être téléchargés!',
        DOCUMENT_FILE_EMPTY_ERROR: 'Veuillez sélectionner un document!',
        ASSETS_ALREADY_OPEN_ALERT: "Vous avez déjà l'onglet Bibliothèque ouvert sous le module Opérations.",
        DUPLICATE_IMAGE_NAME: "L'image existe déjà avec ce nom. Veuillez sélectionner une autre image!",
        DUPLICATE_DOCUMENT_NAME: 'Le document existe déjà avec ce nom. Veuillez sélectionner un autre document!',
        UPLOAD_IMAGE: "Sélectionnez les images à télécharger (jusqu'à cinq fichiers à la fois).",
        ASSETS_ALREADY_OPEN_ALERT_HUB: "Vous avez déjà l'onglet Bibliothèque ouvert sous le module Hub.",
        BLANK_TITLE_ERROR : 'Le titre ne peut pas être vide',
        BLANK_URL_ERROR : 'Le lien ne peut pas être vide',
        INVALID_URL_ERROR : 'Veuillez fournir une URL valide',
        CONFIRM_FILE_TYPE_CHANGE: {
            LN1: 'Souhaitez-vous quitter la section actuelle ? Toutes les modifications non enregistrées seront perdues.',
            LN2: 'Cliquez sur Ok pour confirmer et quitter.',
            LN3: 'Cliquez sur Annuler pour rester dans la section actuelle.',
        },

    },
    MERGE: {
        NOT_URL: "est une URL non valide.",
        NOT_FLOAT: "n'est pas un flotteur.",
        NOT_INTEGER: "n'est pas un entier.",
        NOT_EMAIL: "est une adresse e-mail non valide.",
        MERGE_TENANT_EMAIL_ALERT_MESSAGE: 'Vous ne pouvez pas fusionner ces enregistrements. Comme vous avez choisi un compte payant à supprimer!',
    },
    SCHEDULE: {
        EMPTY: 'Veuillez sélectionner une date de planification',
        CAMPAIGN_SCHEDULE: 'Cette campagne sera diffusée le',
        CONFIRM: 'Cliquez sur Ok pour confirmer.',
        SMS_SCHEDULE: 'Ce SMS sera envoyé le'
    },
    RELATIONSHIP: {
        BLANK_RECORD: "Veuillez sélectionner la relation pour",
        BLANK_PARENT_RELATION: "Veuillez sélectionner au moins une relation parentale.",
        BLANK_CHILD_RELATION: "Veuillez sélectionner au moins une relation enfant",
        NO_PARENT_RELATION: "Vous n'avez aucune relation. Veuillez créer une relation sous personnalisation à partir de tous les paramètres",
        NO_PARENT_RELATION_FOR_USER: "Désolé, il n'y a pas de relation disponible. Veuillez contacter votre administrateur",
        NO_RELATIONSHIP_SETUP: "Vous n'avez encore configuré aucun type de relation",
        PLACEHOLDERTEXT_COMPANY: "Tapez un nom de société et appuyez sur Entrée",
        PLACEHOLDERTEXT_NAME: "Tapez un nom complet et appuyez sur Entrée",
        PLACEHOLDERTEXT_EMAIL: "Tapez un e-mail et appuyez sur Entrée",
    },
    GROUP: {
        NO_TOUCH_CAMPAIGN: "Non utilisé dans aucune campagne tactile.",
        NO_BROADCAST_CAMPAIGN: "Non utilisé dans aucune campagne de diffusion.",
        NO_AB_CAMPAIGN: "Non utilisé dans aucune campagne A / B.",
        NO_WEBFORM: "Non utilisé dans aucun formulaire Web.",
        NO_LANDINGPAGE: "Non utilisé dans aucune page de destination.",
        DELETE_MSG: 'Le ou les groupes suivants ne peuvent pas être supprimés',
        USED_IN_TOUCH_CAMPAIGN: 'est utilisé dans la ou les campagnes tactiles suivantes',
        USED_IN_BROADCAST_CAMPAIGN: 'est utilisé dans la ou les campagnes de diffusion suivantes',
        USED_IN_AB_CAMPAIGN: 'est utilisé dans la ou les campagnes A / B suivantes',
        USED_IN_WEBFORM: ' est utilisé dans le (s) formulaire (s) Web suivant (s)',
        USED_IN_LANDINGPAGE: 'est utilisé dans la ou les pages de destination suivantes',
        SELECT_GROUP: 'Sélectionnez un ou plusieurs groupes',
        NO_GROUP_AVAILABLE: 'Aucun groupe disponible. Cliquez sur',
        SELECT_RECORDS: 'Enregistrements sélectionnés',
        ALL_RECORDS: 'Tous les enregistrements',
        SELECT_OPTIONS: 'Sélectionnez les options',
        ADD_NEW_GROUP: 'pour ajouter un nouveau groupe',
        GROUP_DELETE_RECORD: 'Le groupe sera supprimé. Êtes-vous sûr?',
        COMMON_DELETE_MSG:" ne peut pas être supprimé car il est utilisé dans la ou les campagnes suivantes :",
    },
    LOGIN: {
        VALIDATE_EMPTY_USER_NAME: 'Veuillez saisir votre email et votre mot de passe',
        VALIDATE_EMPTY_PASSWORD: "s'il vous plait entrez votre mot de passe",
        VALIDATE_USERNAME_PASSWORD: "Vous avez entré un e-mail ou un mot de passe incorrect",
        INVALID_RESPONSE: 'Réponse invalide',
        LOGIN_COPYRIGHTS_TEXT_1: "droits d'auteur",
        LOGIN_COPYRIGHTS_TEXT_2: 'Soffront Software, Inc. Tous droits réservés.',
        BEST_VIEWED: 'Meilleur affichage dans Google Chrome, Mozilla Firefox et Safari.'
    },
    AUDIT_LEADSCORE: {
        NO_AUDIT_DATA: "Aucun enregistrement de piste d'audit trouvé",
        NO_SCORE_HISTORY: "Pas d'historique à afficher."
    },
    DETAIL_VIEW: {
        CHANGE_PRIMARY_CONTACT_ALERT_1: 'Vous avez sélectionné',
        CHANGE_PRIMARY_CONTACT_ALERT_2: 'comme contact principal pour',
        CLICK_TO_CONFIRM: 'Cliquez sur OK pour confirmer.',
        MAX_SELECTED_FIELD_LIMIT: 'Vous avez atteint la limite maximale de 50 champs sélectionnés',
        SELECT_ATLEAST_ONE_FIELD: 'Veuillez sélectionner au moins un champ ',
        SELECT_ATLEAST_ONE_CHILD_OBJECT: 'Veuillez sélectionner au moins un objet enfant.',
        MAX_SELECTED_CHILD_OBJECT_LIMIT: 'Vous avez atteint la limite maximale de 10 objets sélectionnés.',
        NO_ASSOCIATED_GROUPS_FOUND: "Aucun groupe associé n'est disponible",
        CONFIRM_UNSUBSCRIBE_RECORD: "L'enregistrement sera désabonné. Êtes-vous sûr?",
        BLANK_SPECIFIC_DATE: 'La date ne peut pas être vide.',
        CONFIGURE_CHILD_SETUP: 'Veuillez configurer la configuration enfant.',
        CHILD_SETUP_SELECT_ATLEAST_ONE_FIELD: 'Veuillez sélectionner au moins un champ pour la configuration enfant.',
        EMAIL_FORMAT_MSG: "- Format d'email invalide.",
        URL_FORMAT_MSG: "- Format d'URL non valide.",
        FLOAT_FORMAT_MSG: "- Format flottant non valide.",
        INTEGER_FORMAT_MSG: "- Format entier non valide.",
        ERROR_MSG: "Voulez-vous partir sans enregistrer les changements?",
    },
    CHARTS: {
        NOTHING_TO_SHOW: 'Rien à montrer pour cette période'
    },
    ADVANCE_SEARCH: {
        SEARCH_FIELDS_NOT_FOUND: 'Rien à chercher. Entrer un nombre, SVP.',
        MAX_ADD_FIELD_ALERT:'Vous ne pouvez pas rechercher avec plus de 10 champs.',
        MIN_ADD_FIELD_ALERT:'Au moins un champ est requis pour effectuer une recherche.',
        SELECT_FIELD_ALERT:"Veuillez sélectionner le champ avant d'entrer la valeur.",
        ADVANCE_SEARCH: 'Recherche Avancée',
        EMPTY_DATE: 'Veuillez saisir la date.',
        INVALID_DATE_RANGE: 'La date de début ne peut pas être supérieure à la date',
        EMPTY_FROM_TO_DATE: 'Veuillez saisir la date de début et la date de fin.',
        EMPTY_FROM_DATE: 'Veuillez saisir la date de début.',
        EMPTY_TO_DATE: 'Veuillez entrer à ce jour.'
    },
    PUSH: {
        //PUSH_SUCCESS_MSG_1: 'This document will be pushed',
        PUSH_SUCCESS_MSG_1: 'le document sera poussé',
        //PUSH_SUCCESS_MSG_1: 'You have successfully pushed',
        PUSH_SUCCESS_MSG_2: 'aux unités sélectionnées.',
        PUSH_SUCCESS_MSG_2_1: 'sera poussé',

        PUSH_SUCCESS_MSG_3: 'Une notification par e-mail a été envoyée aux unités sélectionnées.',

        PUSH_SUCCESS_MSG_4: 'Vous avez sélectionné plus de 20 enregistrements. Vous pouvez pousser au maximum 20',
        PUSH_SUCCESS_MSG_4_1: 'à la fois.',
        PUSH_SUCCESS_MSG_5: 'Veuillez sélectionner une unité à pousser',
        PUSH_SUCCESS_MSG_6: "Il n'y a pas de clients connectés disponibles pour pousser ",
        PUSH_SUCCESS_MSG_7: 'Poussé avec succès.',
        PUSH_ALERT_MSG_8: "Veuillez sélectionner au moins une unité pour pousser les modèles d'e-mail",
        PUSH_ALERT_MSG_9: "Veuillez sélectionner au moins une unité pour pousser les campagnes d'automatisation",
        PUSH_ALERT_MSG_10: 'Veuillez sélectionner au moins une unité pour pousser les campagnes de diffusion',
        PUSH_SUCCESS_MSG_11: "à l'unité sélectionnée.",
        //Added by Anindya
        PUSH_SUCCESS_MSG_11_2: 'Êtes-vous sûr?',
        //End
        PUSH_SUCCESS_MSG_12: "Une notification par e-mail a été envoyée à l'unité sélectionnée.",
        PUSH_SUCCESS_MSG_13: 'Veuillez sélectionner au moins une unité pour pousser les prospects',
        PUSH_SUCCESS_MSG_14: 'Veuillez sélectionner au moins une unité pour pousser les flux de travail',
        PUSH_FOLDER: 'Dossier',
        FAIL_USE_ASSIGNMENT_RULE_FOR_SALES_LEADS: "Aucune règle d'attribution n'est satisfaite pour pousser les prospects",
        PUSH_ALERT_MSG_12: "Veuillez sélectionner au moins une unité pour pousser les modèles d'accord",
        LANDING_PAGES: 'pages de destination',
    },
    PROFILE_DETAILS: {
        UNMATCH_NEW_CONFIRM_PASSWORD: 'Le mot de passe confirmé ne correspond pas au nouveau mot de passe.',
        NO_NEW_PASSWORD: 'Saisissez un mot de passe valide et confirmez-le.',
        NO_CONFIRM_PASSWORD: 'Confirmez le nouveau mot de passe.',
        VALIDATE_PROFILE_IMAGE: 'Sélectionnez un fichier image.',
        PROFILE_IMAGE_UPLOAD: "L'image de profil a bien été enregistrée.",
        PROFILE_IMAGE_UPLOAD_FAILURE: "L'image de profil n'a pas pu être enregistrée.",
        PROFILE_UPDATE: 'Mise à jour du profil réussie.',
        VALIDATE_PROFILE_IMAGE_SIZE: 'La photo que vous avez sélectionnée est trop grande pour être utilisée. La taille de fichier maximale autorisée est de 500 Ko.',
        PROFILE_IMAGE_ATTACHMENT_LOGO_TEXT: 'Vous pouvez télécharger un fichier jpg, gif ou png.',
        PROFILE_IMAGE_SIZE_TEXT: ' La taille de fichier maximale autorisée est de 500 Ko.',
        PROFILE_SIGNATURE_AUTOSAVED: 'Signature enregistrée avec succès.'
    },
    CUSTOMIZATION: {
        LOCATION_VAL_NOT_MATCH: "La valeur de l'emplacement ne correspond pas.",
        LOCATION_VAL_BLANK: "Aucune valeur n'est disponible dans le champ de localisation."
    },
    IMPORT: {
        SELECT_FILE: 'Veuillez sélectionner un fichier',
        SELECT_GROUP: 'Veuillez sélectionner un groupe',
        SELECT_WORKFLOW: 'Veuillez sélectionner un workflow',
        SELECT_STATUS: 'Veuillez sélectionner un statut',
        SELECT_SOURCE: 'Veuillez sélectionner une source',
        SOURCR_NAME_EXISTS: 'La valeur existe déjà dans la liste',
        SOURCR_NAME_BLANK: 'Le nom de la source ne peut pas être vide!',
        IMPORT_FILE_EXT: "Veuillez sélectionner un fichier avec l'extension .csv ou .xls / xlsx",
        NO_RECORD_TO_IMPORT: "Aucun enregistrement pour l'importation!",
        NO_MAPPING: "Il n'y a pas de mappage pour ",
        NOT_ALL_MAPPED: 'Une ou plusieurs colonnes ne sont pas mappées à CRM. Souhaitez-vous continuer sans importer ces colonnes?',
        IMPORT_CONFIRM_MSG: 'les enregistrements seront importés dans',
        IMPORT_CONFIRM_MSG_1: 'enregistrement sera importé dans',
        CONFIRM: 'Veuillez confirmer.',
        SELECT_LINK_USING: 'Veuillez sélectionner une option pour Link Using',
        SELECT_LINK_WITH: 'Veuillez sélectionner une option pour Lier avec',
        SELECT_DUPLICATE_FIELD: 'Veuillez sélectionner au moins une option pour le champ en double',
        SOURCE_ALERT_MSG_1: 'Veuillez mapper le champ "Source" du compte à une colonne contenant vos sources marketing.',
        SOURCE_ALERT_MSG_2: 'Vous avez choisi Source dans la liste déroulante ainsi que dans la section de mappage. Vous pouvez choisir la valeur du champ Source à un endroit et non à deux endroits.',
        NO_MAPPING_AVAILABLE: "Il n'y a pas de mappage disponible.",
        BLANK_MAP_NAME: 'Veuillez fournir un nom de carte!',
        VALID_MAP_NAME_LENGTH: 'Le nom de la carte ne doit pas dépasser 32 caractères!',
        DUPLICATE_MAP_NAME: 'Le nom de la carte existe déjà !!',
        MAP_SAVE_SUCCESS: 'Votre mappage de champ a été enregistré avec succès!',
        NO_PREVIOUS_MAPPING: 'Aucune cartographie précédente présente!',
        MAP_NAME: 'Nom de la carte',
        DELETE_MAP_ALERT: 'Etes-vous sûr que vous voulez supprimer',
        DELETE_MAP_SUCCESS: 'Le mappage a bien été supprimé!',
        IMPORT_SUCCESS: 'Importé avec succès.',
        WARNING: 'Avertissement!',
        WARNING_TEXT: "Veuillez ne pas fermer cette page tant que l'importation n'est pas terminée.",
        IMPORTING_RECORDS: 'Importer vos enregistrements',
        SUCCESS_IMPORT_COUNT: 'enregistrement (s) ont été importés',
        FAIL_IMPORT_COUNT: "enregistrement (s) n'a pas pu être importé",
        FOUND: 'Nous avons trouvé',
        DUPLICATES: 'duplicata (s)',
        APPLY_WORKFLOW_TEXT: 'aux enregistrements importés',
        APPLYING_WORKFLOW: 'Application du workflow',
        APPLIED_WORKFLOW: 'Application du workflow terminée',
        APPLIED_WORKFLOW_TO: 'Workflow appliqué à',
        MAX_IMPORT_RECORDS_MSG_1: 'Il y a',
        MAX_IMPORT_RECORDS_MSG_2: 'enregistrements dans le fichier. Actuellement, vous pouvez importer 10000 enregistrements à la fois!',

        // not found in doc
        VERIFY_CONTACTS_MSG_1: 'Veuillez vérifier que ces contacts vous ont donné leur',
        VERIFY_CONTACTS_MSG_2: "permission de leur envoyer un courriel. Nous n'autorisons pas les locations ou ",
        VERIFY_CONTACTS_MSG_3: 'listes achetées. Ces contacts doivent avoir demandé',
        VERIFY_CONTACTS_MSG_4: 'pour recevoir des courriels de votre part ou avoir une relation étroite avec ',
        VERIFY_CONTACTS_MSG_5: 'ton organisation.',

        FILE_TYPE_MSG: 'La première ligne du fichier CSV ou XLS / XLSX doit contenir les noms de champ.',
        DATE_FORMAT_MSG: 'La date doit être au format MM / JJ / AAAA ',
        ACCOUNTS_AND_CONTACTS: 'Comptes et contacts',
        IMPORT_CSV_FILE: "Veuillez sélectionner un fichier avec l'extension .csv.",
        RECORDS_IMPORT_SUCCESS: 'Les enregistrements ont été importés avec succès.',
        GREEN_LIGHT_STATUS_ERROR: "Une importation est déjà en cours, vous ne pouvez donc démarrer une nouvelle importation qu'après la fin du processus d'importation en cours.",
        GREEN_LIGHT_STATUS_SUCCESS: "Votre importation actuelle est en cours. Nous vous enverrons un e-mail de confirmation à l'adresse e-mail enregistrée des franchisés concernés une fois que cela sera fait.",
    },
    YOUTUBE_VIDEO: {
        EMPTY_VIDEO_LINK: 'Veuillez coller une URL de vidéo pour YouTube',
        INVALID_VIDEO_LINK: "L'URL que vous avez saisie n'est pas valide",
        INSERT_TITLE: 'Insérer une vidéo de YouTube',
        VIDEO_LINK_PLACEHOLDER: 'Coller une URL de vidéo à partir de YouTube'
    },
    WISTIA_VIDEO: {
        EMPTY_VIDEO_LINK: 'Veuillez coller une URL de vidéo pour Wistia',
        INVALID_VIDEO_LINK: "L'URL que vous avez saisie n'est pas valide",
        INSERT_TITLE: 'Insérer une vidéo de Wistia',
        VIDEO_LINK_PLACEHOLDER: 'Paste a video URL from Wistia'
    },
    INXPRESS_REPORT: {
        VALIDATE_EMPTY_FROM_DATE: 'Veuillez sélectionner une date.',
        VALIDATE_EMPTY_TO_DATE: 'Veuillez sélectionner à ce jour.',
        VALIDATE_EMPTY_COUNTRY: 'Veuillez sélectionner le pays.',
        VALIDATE_EMPTY_FRANCHISE: 'Veuillez sélectionner Franchise.',
        VALIDATE_EMPTY_WORKFLOW: 'Veuillez sélectionner le flux de travail.',
        VALIDATE_EMPTY_SALES_REP: 'Veuillez sélectionner un représentant commercial.',
        VALIDATE_EMPTY_OBJECT_NAME: 'Veuillez sélectionner Signaler le.',
        NO_RECORD_EXPORT: "Aucun enregistrement disponible pour l'exportation.",
        REPORT_NOT_READY_FOR_EXPORT: 'Veuillez patienter pendant le chargement du rapport, vous ne pouvez pas exporter maintenant.',
        BLANK_FROM_TO_DATE: 'La date ne peut pas être vide.',
        VALIDATE_EMPTY_COUNTRY_INXPRESS_REPORT16: 'Veuillez sélectionner un pays pour continuer.',
        VALIDATE_EMPTY_WORKFLOW_INXPRESS_REPORT: 'Veuillez sélectionner un workflow pour continuer.',
        VALIDATE_EMPTY_REGION_INXPRESS_REPORT: 'Veuillez sélectionner une région pour continuer.',
        VALIDATE_EMPTY_DATE_RANGE: 'Veuillez sélectionner une plage de dates pour continuer.',
        VALIDATE_EMPTY_WORKFLOW_STAGES: 'Veuillez sélectionner une étape de workflow pour continuer.',
        VALIDATE_EMPTY_CHART_TYPE: 'Veuillez sélectionner un type de graphique pour continuer.',
        VALIDATE_EMPTY_SHOW_TYPE: 'Veuillez sélectionner un type de spectacle pour continuer.',
        VALIDATE_EMPTY_ACTIVITY_TYPE: "Veuillez sélectionner un type d'activité pour continuer.",
    },

    OTHER_VIEW: {
        TENANT_MESSAGE: "Votre abonnement n'inclut pas $$@@##PRODUCT_NAME##@@$$",
        USER_MESSAGE: "Veuillez contacter votre administrateur pour ajouter $$@@##PRODUCT_NAME##@@$$ à votre abonnement",
        AFTER_SUBSCRIPTION_VENDASTA_NOT_CONFIGURED: "Nous n'avons pas encore configuré votre compte pour $$@@##PRODUCT_NAME##@@$$. Nous vous informerons dès qu'il sera disponible."
    },

    MY_INTEGRATION: {
        SAVE_SUCCESS: 'Votre paramètre a bien été enregistré.',
        APPEND_INFO: "Ajouter les coordonnées de l'entreprise et des contacts aux rendez-vous ajoutés depuis Soffront",
        DISCONNECT_CONNECTION: 'La connexion sera supprimée. Êtes-vous sûr?',
        SAVE_FAIL: "Votre paramètre n'est pas enregistré.",
        NO_CALENDAR: "Le calendrier n'est pas sélectionné.",
        NO_SYNC_CHECKED: "Vous n'avez sélectionné aucune des options de synchronisation. Vous pouvez choisir de synchroniser vos e-mails, votre calendrier ou les deux.",
        SYNC_EMAIL_INFO_1: 'Sélectionnez cette option pour enregistrer automatiquement les e-mails de votre boîte aux lettres G Suite / Office 365 dans CRM.',
        SYNC_EMAIL_INFO_2: 'Les e-mails seront enregistrés sur un compte ou un enregistrement de contact correspondant.',
        SYNC_EMAIL_INFO_3: 'Les e-mails seront synchronisés toutes les 5 minutes.',
        SYNC_CONTACT_INFO_1: 'Sélectionnez cette option pour ajouter automatiquement vos contacts CRM à G Suite / Office 365.',
        SYNC_CONTACT_INFO_2: 'Toutes les modifications que vous apportez aux contacts dans CRM seront automatiquement mises à jour dans G Suite / Office 365.',
        SYNC_CONTACT_INFO_3: 'Les contacts seront synchronisés toutes les 5 minutes.',
        SYNC_CALENDAR_INFO_1: 'Sélectionnez cette option pour synchroniser automatiquement les rendez-vous entre votre calendrier Soffront avec un calendrier de votre compte G Suite / Office 365.',
        SYNC_CALENDAR_INFO_2: 'Si vous disposez de plusieurs calendriers G Suite / Office 365, vous devrez en choisir un que vous souhaitez synchroniser.',
        SYNC_CALENDAR_INFO_3: 'Les calendriers seront synchronisés toutes les 5 minutes.',
        NO_CALENDAR_MESSAGE: 'Nous attendons les détails de votre calendrier. Veuillez fermer cet écran et revenez dans quelques minutes. Si vous continuez à voir ce message, veuillez déconnecter et reconnecter votre compte.',
        NO_PERMISSION: "L'intégration n'est pas disponible dans votre plan actuel. Veuillez vous abonner à un plan approprié ou contacter le support à smbsupport@soffront.com.",
        SELECTED_CALENDAR_NOT_FOUND: 'Nous ne trouvons pas votre calendrier sélectionné précédemment. Veuillez en sélectionner un autre et enregistrer',
        INVALID_ACCOUNT_SYNC_STATE1:'Votre',
        INVALID_ACCOUNT_SYNC_STATE2:'la connexion n\'est plus valide. Veuillez vous déconnecter et vous reconnecter.',
        SEND_SALES_EMAILS_VALIDATION1: 'Pour pouvoir envoyer des e-mails à l\'aide',
        SEND_SALES_EMAILS_VALIDATION2: 'vous devrez vous connecter en utilisant',
        SEND_SALES_EMAILS_VALIDATION3: 'Veuillez vous déconnecter et vous reconnecter en utilisant',
    },

    DOCUSIGN: {
        PROVIDE_USERNAME: "Veuillez fournir un nom d'utilisateur valide.",
        PROVIDE_PASSWORD: "Veuillez fournir un mot de passe valide.",
        PROVIDE_INTEGRATOR_KEY: "Veuillez fournir une clé d'intégrateur valide.",
        SUCCESSFULLY_SAVED: 'Vous avez configuré avec succès votre compte DocuSign.',
        FAILED_TO_SAVED: 'Impossible de configurer votre compte DocuSign.',
        DOCUSIGN_SETUP_TITLE_1: 'Sélectionnez un document et envoyez-le ',
        DOCUSIGN_SETUP_TITLE_2: 'à ',
        DOCUSIGN_SETUP_TITLE_3: ' à signer',
        PROVIDE_RECIPIENT_NAME: 'Veuillez indiquer le nom complet du destinataire',
        PROVIDE_RECIPIENT_EMAIL: "Veuillez fournir l'adresse e-mail du destinataire",
        PLEASE_SELECT_TEMPLATE: 'Veuillez sélectionner un modèle.',
        NO_TEMPLATES_FOUND: 'Aucun modèle trouvé.',
        UNABLE_TO_SEND: "Impossible d'envoyer le modèle car votre compte DocuSign a expiré.",
        TEMPLATE_NAME_LENTH: 'Le nom du modèle ne doit pas dépasser 65 caractères.',
        PLEASE_SELECT_CONTACT: 'Veuillez sélectionner au moins un contact.',
        DOCUSIGN_CONTACT_MESSAGE_1: "Aucun e-mail n'est disponible pour les contacts suivants: ",
        DOCUSIGN_CONTACT_MESSAGE_2: 'Aucun e-mail ne sera envoyé à ces contacts.',
        DOCUSIGN_CONNECTED_MESSAGE_1: "Désolé, vous n'avez pas la permission d'effectuer cette opération. S'il vous plaît contacter SOFFRONT.",
    },

    TWILIO: {
        CALL_OVER_SECURED_NETWORK: 'Vous ne pouvez passer un appel que sur un réseau sécurisé',
        SAME_TO_AND_FROM_NUMBER: "Le numéro de destination et le numéro de départ sont identiques. L'appel ne peut pas être lancé.",
        //CALLING_SUPPORT: 'We support only national calls. You can make calls within your Country',
        CALLING_SUPPORT: 'Les appels ne sont actuellement pas pris en charge dans votre pays.',
        OUTBOUND_PHONE_NOT_VERIFIED: "Votre numéro de téléphone sortant n'est pas vérifié. Veuillez le vérifier avant de passer un appel.",
        CALL_RECORDING_SAVE: 'Enregistré avec succès. Veuillez vous déconnecter et vous reconnecter pour voir le changement.',
        CALL_RECORDING_LAWS_AND_REGULATION_ALERT:"Veuillez vous assurer que vos pratiques d'enregistrement des appels sont conformes aux lois et réglementations applicables.",
        NOTE_SAVE: 'Note enregistrée avec succès',
        PHONE_LOW_BALANCE1: 'Le solde de votre téléphone est maintenant ',
        PHONE_LOW_BALANCE2: ' minutes).',
        PHONE_LOW_BALANCE3: 'Vous avez besoin de plus de 5 minutes de solde pour passer un appel.',

        PHONE_BLANK_MESSAGE: 'Veuillez saisir un numéro de téléphone.',
        PHONE_ALREADY_CONFIGURE: 'Ce numéro de téléphone est déjà configuré.',
        DELETE_PHONE_NUMBER: 'Êtes-vous sûr de vouloir supprimer ce numéro?',
        VERIFY_PHONE_NUMBER: "Veuillez vérifier avant d'ajouter un autre numéro.",
        PHONE_LIMIT: 'Vous ne pouvez pas ajouter plus de 3 numéros de téléphone.',
        VERIFICATION_CODE: "À l'invite, veuillez entrer le code de vérification.",
        VERIFICATION_MESSAGE_1: 'Nous appellerons',
        VERIFICATION_MESSAGE_2: 'pour vérifier le numéro.',
        FIELDS_ADD_LIMIT:'Vous ne pouvez pas ajouter plus de 3 champs.',
        SETUP_UPDATED_SUCCESSFULLY:'Mis à jour avec succés.',
        SETUP_UPDATED_FIELDS:'Mise à jour a échoué.'
    },
    CALL_SCRIPT: {
        BLANK_NAME: 'Veuillez saisir un nom pour ce script.',
        BLANK_DETAILS: 'Veuillez saisir les détails du script.',
        SAVE_SUCCESS: 'Vous avez enregistré avec succès ce script.',
        UPDATE: 'Vous avez mis à jour ce script avec succès.',
        DELETE: 'Vous avez supprimé avec succès ce script.',
        NO_RECORD: 'Aucun enregistrement disponible',
        'DUPLICATE_SCRIPT_NAME': "Ce nom de script existe déjà. Le nom de script en double n'est pas autorisé.",
        'EMPTY_SCRIPT_NAME_DETAILS': 'Le nom du script et les détails du script ne peuvent pas être vides.',
        'RECORD_NOT_FOUND_UPDATE': 'Enregistrement introuvable à mettre à jour.',
        DELETE_CONFIRMATION: 'Êtes-vous sûr de vouloir supprimer ce script?'
    },
    SMS_INTEGRATION: {
        PURCHASED_NUMBER: 'Vous avez acheté le numéro avec succès',
        SELECT_USER: 'Veuillez sélectionner au moins un représentant commercial pour continuer.',
        ENTER_ZIP_OR_AREA: 'Veuillez saisir un code postal ou un indicatif régional.',
        ENTER_AREA: 'Veuillez saisir un indicatif régional.',
        ENTER_VALID_ZIP: 'Veuillez entrer un code postal valide.',
        ENTER_VALID_AREA_CODE: 'Veuillez saisir un indicatif régional valide.',
        NO_AVAILABLE_PHONE_NUMBERS: "Il n'y a pas de numéro de téléphone disponible pour le code postal / régional saisi.",
        NO_AVAILABLE_PHONE_NUMBERS_FOR_ZIP: "Il n'y a pas de numéro de téléphone disponible pour l'indicatif régional saisi.",
        REMOVE_NUMBER_CONFIRMATION: 'Êtes-vous sûr de supprimer ce numéro?',
        //SMS_SUPPORT: 'You cannot send text messages to international phone numbers.',
        SMS_SUPPORT: "L'envoi de SMS n'est pas pris en charge dans votre pays.",
        SELECT_NUMBER: 'Veuillez sélectionner un numéro.',
        SELECT_AT_LEAST_ONE_NUMBER:'Veuillez sélectionner au moins un numéro à importer',
        TWILIO_NUMBER_IMPORTED_SUCCESS:'Votre (vos) numéro(s) Twilio ont été importés avec succès',
        TWILIO_NUMBER_IMPORTED_ERROR:'Votre ou vos numéros Twilio n\'ont pas pu être importés.',
        PHONE_NUMBER_CANNOT_BE_PURCHASED:'Le numéro de téléphone ne peut pas être acheté. Veuillez contacter le support Soffront pour plus de détails.',
        ENTER_SMS_TEXT: 'Veuillez saisir le texte du SMS.',
        SMS_LOW_BALANCE_1: 'Votre solde SMS est maintenant ',
        SMS_LOW_BALANCE_2: 'Vous devez acheter des crédits SMS pour envoyer des SMS.',
        TWILIO_NUMBER_PURCHASE_MSG_1: 'Votre compte sera facturé',
        TWILIO_NUMBER_PURCHASE_MSG_2: 'mois',
        TWILIO_NUMBER_PURCHASE_MSG_3: 'pour ce numéro de téléphone. Vous pouvez supprimer le numéro de votre compte à tout moment pour arrêter la facturation.',
        SMS_TOOLTIP_MESSAGE: 'Rechercher par code postal ou régional',
        SMS_TOOLTIP_MESSAGE_AREA_CODE: 'Rechercher par indicatif régional',
        INSERT_IMAGE_FILE: 'Veuillez sélectionner uniquement les fichiers jpg, jpeg, gif ou png',
        BATCH_SEND_SMS_MESSAGE: 'message envoyé avec succès',
        'INSUFFICIENT_BALANCE': 'Solde SMS insuffisant',
        'BATCH_SMS_SENT': 'message envoyé avec succès',
        'BATCH_SMS_FAILS': "Échec de l'envoi du message texte",
        NO_FROM_NUMBER_MESSAGE: "Vous devrez acheter un numéro de téléphone pour envoyer des messages texte. Veuillez acheter le numéro de téléphone dans le menu Intégrations.",
        MAX_ATTACHMENT_FILE_FOR_SMS: 'Maximum de 10 fichiers joints autorisés.',
        'NO_INPUT_STREAM_FOUND':"Aucun flux d'entrée trouvé ",
        'NO_ATTACHMENT_FOUND': 'Aucune pièce jointe trouvée ',
        'FILE_SIZE_ERROR': 'La taille du fichier doit être inférieure à 5 Mo.',
        IMAGE_SELECT: 'Sélectionnez une image',
        MERGE_FIELD: 'Sélectionnez les champs de fusion',
        DUPLICATE_FILE_NAME: 'Ce fichier existe déjà. Veuillez sélectionner un fichier différent.',
        RECORD_UNSUBSCRIBE_ALERT: "L'enregistrement sélectionné est désabonné des textes. Vous ne pouvez pas envoyer de message texte.",
        UNSUBSCRIBE_LIST_CONFIRM_MSG: "sur les enregistrements sélectionnés s'est désabonné des textes Le message texte ne peut pas être envoyé aux enregistrements désabonnés. Appuyez sur OK pour continuer.",
        UNSUBSCRIBE_LIST_CONFIRM_MSG_1: "des enregistrements sélectionnés se sont désabonnés des textes. Le message texte ne peut pas être envoyé aux enregistrements désabonnés. Appuyez sur OK pour continuer.",
        'BATCH_SMS_SCHEDULE': 'Le message texte a été planifié avec succès',
        'BATCH_SMS_SCHEDULE_FAIL': 'Échec de la planification du message texte',
        BATCH_SEND_SMS_MSG_1: 'Hors de',
        BATCH_SEND_SMS_MSG_2: 'vous avez sélectionné, seulement',
        BATCH_SEND_SMS_MSG_3: 'ont choisi de recevoir votre SMS et ont le numéro de téléphone portable. ',
        BATCH_SEND_SMS_MSG_4: 'Nous enverrions le message texte uniquement à ces',
        BATCH_SEND_SMS_MSG_5: 'Appuyez sur OK pour continuer.',
        SINGLE_SEND_SMS_MSG_1: 'Veuillez vérifier si sélectionné',
        SINGLE_SEND_SMS_MSG_2: 'a choisi de recevoir vos SMS ou a le numéro de portable.',
        RESCHEDULE_SUCCESS: 'Le SMS a été reprogrammé avec succès',
        AUTOMATION_SMS_ADD_MSG: 'Vous avez enregistré avec succès ce message texte',
        WORKFLOW_STATUS_MSG: 'Vous avez un ou plusieurs flux de travail actifs qui utilisent ce numéro pour envoyer des messages texte. Veuillez mettre à jour ces flux de travail pour utiliser un numéro différent avant de supprimer ce numéro. ',
        SCHEDULED_STATUS_MSG: 'Vous avez un ou plusieurs sms par étapes qui utilisent ce numéro. Cliquez sur OK pour annuler tous vos SMS par étapes et supprimer le numéro.',
        REMOVE_SUCCESS_MSG: 'Le numéro a été supprimé avec succès.',
        NUMBER_SAVED_SUCCESS_MSG: 'Numéro enregistré avec succès.',
        NO_LINKED_MOBILE_NUMBER_MSG:"L'enregistrement associé n'a pas de numéro de mobile. Vous ne pouvez pas envoyer de SMS.",
        NUMBER_INVALID_MSG:"Le numéro de portable du contact est incorrect !",
        CANNOT_DELETE_UNITS_NUMBER: 'Vous n\'êtes pas autorisé à supprimer ce numéro car ce numéro a été attribué à une unité.',
    },
    EVENTBRITE: {
        NO_ORGANISATION_SELECTED: 'Veuillez sélectionner au moins une organisation',
        NO_MAPPING_FOUND: "Il n'y a pas de mappage pour les champs Contact CRM et Eventbrite."
    },
    QUICKBOOKS: {
        NO_MAPPING_FOUND: "Il n'y a pas de mappage pour les comptes CRM et le champ Client Quick Books.",
        COONECT_QB_MSG: "Vous n'avez pas connecté votre compte Quickbooks. Cliquez sur Ok pour connecter votre compte."
    },
    XERO: {
        SELECT_ONE_INTEGRATION_ITEM_AND_TYPE: 'Veuillez sélectionner un élément et un type d\'intégration à intégrer.',
        SELECT_ONE_INTEGRATION_ITEM: 'Veuillez sélectionner un élément à intégrer.',
        SELECT_INTEGRATION_TYPE: 'Veuillez sélectionner le type d\'intégration.',
    },
    REPORT_SCHEDULE_DIALOG: {
        START_DATE_BLANK: 'La date de début ne peut pas être vide.',
        INVALID_END_DATE: 'La date de début ne peut pas être postérieure ou identique à la date de fin.',
        DAY_CHECKBOX_BLANK: 'Au moins vérifier un jour.',
        SEND_TO_BLANK: ' Sélectionnez au moins un utilisateur.',
        SAVE_SUCCESS: "Enregistré avec succès."
    },
    DOCUMENT_PUSH: {
        ALERT_MSG_1: 'Veuillez sélectionner au moins une entreprise pour envoyer des documents',
        DOCUMENTS: 'Document(s)',
        FAIL: 'Impossible de pousser'
    },
    OPERATIONS_REPORT: {
        NO_CUSTOM_REPORT_MESSAGE: "Vous n'avez aucun rapport personnalisé."
    },
    PINNED_NOTES: {
        CONFIRM_MESSAGE: 'Cela remplacera la note épinglée existante. Cliquez sur OK pour continuer ou sur Annuler pour annuler.'
    },
    SETTINGS: {
        EMPTY_NAME: "Entrez s'il vous plait",
        DUPLICATES_SOURCE_NAME: 'Le nom de la source existe déjà!',
        CORPORATE_CURRENCY: 'La devise sélectionnée a été mise à jour avec succès.',
        INVALID_CURRENCY_NAME: "Veuillez sélectionner un nom de devise d'entreprise valide",
        SYSTEM_NOTE_TYPE_EDIT_ALERT: 'Vous ne pouvez pas modifier ce type de note.',
        SYSTEM_APPOINTMENT_TYPE_EDIT_ALERT: 'Vous ne pouvez pas modifier ce type de rendez-vous',
        SYSTEM_NOTE_TYPE_DELETE_ALERT: 'Vous ne pouvez pas supprimer ce type de note.',
        SYSTEM_APPOINTMENT_TYPE_DELETE_ALERT: 'Vous ne pouvez pas supprimer ce type de rendez-vous.',
        SYSTEM_CAMPAIGN_TYPE_DELETE_ALERT: 'Vous ne pouvez pas supprimer ce type de campagne.',
        CORPORATE_CURRENCY_ALERT: "Veuillez sélectionner une devise d'entreprise.",
        CORPORATE_CURRENCY_CONFIRM: 'La devise sélectionnée sera enregistrée. Êtes-vous sûr?',
        'CORPORATECURRENCY': "Sélectionnez une devise d'entreprise",
        'DUPLICATE_NOTE_TYPES': "Ce type de note existe déjà. La création de types de note en double n'est pas autorisée.",
        'DUPLICATE_APPOINTMENT_TYPES': "Ce type de rendez-vous existe déjà. La création de types de rendez-vous en double n'est pas autorisée.",
        'INCORRECT_RECORD_ID': "ID d'enregistrement incorrect. Échec de la mise à jour.",
        'RECORD_DOES_NOT_EXISTS': "L'enregistrement n'existe pas. L'opération de suppression a échoué.",
        'RECORD_CANNOT_DELETED': 'Les types de notes système ou par défaut ne peuvent pas être supprimés.',
        'DUPLICATE_CAMPAIGN_TYPES': "Ce type de campagne existe déjà. La création de types de campagne en double n'est pas autorisée.",
        'DUPLICATE_RELATIONS': 'Le nom de la relation existe déjà!',
        RELATION_LINK_INFO_ALERT_MSG: "Il existe des enregistrements qui suivent cette relation. Dissociez d'abord les enregistrements respectifs qui suivent cette relation et vous pourrez ensuite supprimer cette relation."
    },
    RECYCLE_BIN: {
        SELECT_WORKFLOW: 'Veuillez sélectionner un workflow',
        SELECT_STAGE: 'Veuillez sélectionner une étape',
        NOTE: "Remarque: les enregistrements liés de l'enregistrement d'origine ne seront pas restaurés.",
        RESTORE: 'Enregistrer la restauration avec succès'
    },
    UNSUBSCRIBE: {
        SELECTED_RECORD: 'Cet enregistrement sera désabonné de ',
        ARE_YOU_SURE: '. Êtes-vous sûr?',
        SELECTED_BOTH_RECORD: 'Cet enregistrement sera désabonné des e-mails et des textes. Êtes-vous sûr?',
        'EMAILS_UNSUBSCRIBE': ' sera désabonné des e-mails. Êtes-vous sûr?',
        'TEXTS_UNSUBSCRIBE': ' sera désabonné de Textes. Êtes-vous sûr?',
        'BOTHS_UNSUBSCRIBE': ' sera désabonné des e-mails et des SMS. Êtes-vous sûr?',
    },
    WORKFLOWS: {
        STATUS_CHANGED: 'Le statut a été changé en ',
        'SAME_STATUS': 'Enregistrer déjà dans',
        'FAILED_TO_CHANGE_WORKFLOW_STATUS': "échec de la modification de l'état du flux de travail.",
        ONLY_ONE_ACTIVE_WORKFLOW: "Vous ne pouvez pas rendre ce flux de travail inactif. Il s'agit du seul flux de travail actif disponible. Une table doit avoir au moins un workflow actif.",
        ONLY_ONE_ACTIVE_WORKFLOW_CANNOT_DELETE: "Vous ne pouvez pas supprimer ce flux de travail. Il s'agit du seul flux de travail actif disponible. Une table doit avoir au moins un workflow actif.",
        DELETED_WORKFLOW: "Le workflow n'existe pas. Sélectionnez Ok pour choisir un nouveau workflow.",
        STATUS_BLANK_ALERT:'le statut ne peut pas être vide',
        SALES_REP_BLANK_ALERT : 'Sales_rep ne peut pas être vide',
    },
    WORKFLOW_DESIGNER: {
        AUTOMATED_WORKFLOW_TITLE: 'Concepteur de flux de travail',
    },
    CHANGE_LOGO: {
        SELECT_IMAGE_TO_UPLOAD: 'Sélectionnez une image pour changer de logo',
        LOGO_IMAGE_UPLOAD_FAILURE: "Le logo de l'entreprise n'a pas pu être enregistré.",
        LOGO_IMAGE_UPLOAD: "Le logo de l'entreprise a bien été enregistré.",
        VALIDATE_LOGO_IMAGE: 'Sélectionnez un fichier image.',
        VALIDATE_LOGO_IMAGE_SIZE: 'La photo que vous avez sélectionnée est trop grande pour être utilisée. La taille de fichier maximale autorisée est de 500 Ko.',
        LOGO_IMAGE_ATTACHMENT_LOGO_TEXT: 'Vous pouvez télécharger un fichier jpg, gif ou png.',
        LOGO_IMAGE_SIZE_TEXT: ' La taille de fichier maximale autorisée est de 500 Ko.',
        WEBSITE_URL: 'Site Internet',
        VALIDATE_LOGO_WEBSITE_URL: "Souhaitez-vous télécharger un logo sans URL valide?"
    },
    ACCOUNTS: {
        DUPLICATE_MAIL_DOMAIN: 'Il existe un compte pour cette société. Souhaitez-vous ajouter un contact à la place?',
        DUPLICATE_FRANCHISE: "Il s'agit d'un identifiant de franchisé en double.",
    },
    ASSIGNMENT_RULES: {
        NO_ASSIGNMENT_RULES: 'Aucune règle disponible.',
        NO_NAME: "Veuillez indiquer le nom de la règle d'attribution.",
        NAME_MAX_LENGTH: "Le nom de la règle d'attribution ne peut pas comporter plus de 64 caractères.",
        NO_CRITERIA: 'Veuillez fournir au moins un critère.',
        NO_USER: 'Veuillez indiquer au moins un utilisateur.',
        RULE_SAVE_SUCCESS: "La règle d'attribution a bien été enregistrée.",
        RULE_NAME_EXISTS: 'Le nom existe déjà!',
        ACTIVE_RULE_EXCEEDS: "Vous avez déjà 60 règles d'attribution actives.",
        SELECT_UNIT_MAXIMUM_FIELD: "Vous ne pouvez sélectionner qu'une seule unité",
        SELECT_A_UNIT_WORKFLOW: "Veuillez sélectionner un flux de travail d'unité.",
        NO_UNIT_USER: 'Veuillez fournir au moins une unité.'

    },
    COUPONS: {
        CREATE_COUPON_NAME: 'Créez un nom interne pour votre coupon',
        CREATE_DISCOUNT_TYPE: 'Quel type de réduction souhaitez-vous créer?',
        FIXED_AMOUNT: 'Montant fixé',
        PERCENTAGE: 'Pourcentage',
        PREFIX_COUPON_CODE: 'Spécifiez un préfixe pour le code de coupon',
        CAN_CLAIM_MORE_THAN_ONCE: "Peut être utilisé plus d'une fois par le même compte?",
        CALIM_LIMIT_ONE: 'Juste une fois',
        CLAIM_LIMIT_UNLIMITED: 'Illimité',
        WHYEN_CLAIM_CODE: "Date d'expiration du coupon",
        CLAIM_ANYTIME: "N'expire jamais",
        CLAIM_BEFORE_DATE: "Date d'expiration",
        SAVE_SUCCESSFULLY: 'Coupon enregistré avec succès',
        ACTIVE_SUCCESSFULL: 'Le statut a été changé en Actif.',
        INACTIVE_SUCCESSFULL: 'Le statut a été changé en Inactif.',
        DELETE_CONFIRMATION: 'Les coupons seront supprimés. Êtes-vous sûr?',
        INVALID: 'est un invalide',
        DUPLICATE_COUPONS: 'Le nom du coupon existe déjà.',
        NEGATIVE_COUPONS: 'ne peut pas être négatif.',
        SPECIFY_DATE: 'Veuillez préciser une date.',
        PODUCT_SERVICE: 'Produit / Service',
    },
    NOTIFICATION_REMINDERS: {
        SAVE_SUCCESS: 'Rappel ajouté avec succès',
        INVALID_DAY: 'Entrez un (des) jour (s) valide (s)',
        DUPLICATE_REMINDER: 'Ceci est un rappel en double, veuillez modifier',
        INVALID_DATE_FIELD: 'Veuillez sélectionner un champ Date',
        DELETE_SUCCESS: 'Rappel supprimé avec succès',
        UPDATE_SUCCESS: 'Rappel mis à jour avec succès',
    },
    EMAIL_INTEGRATION: {
        DUPLICATE_EMAIL: 'Cette adresse email existe déja!',
        EMAIL_SAVE_SUCCESS: 'Adresse e-mail enregistrée avec succès',
        SEND_VERIFICATION_EMAIL_SUCCESS_1: "Nous avons envoyé l'e-mail de vérification. Veuillez vérifier votre boîte de réception et cliquez sur ",
        SEND_VERIFICATION_EMAIL_SUCCESS_2: 'Vérifiez cette adresse e-mail. ',
        SEND_VERIFICATION_EMAIL_SUCCESS_3: "Revenez ensuite et cliquez sur l'icône d'actualisation pour voir l'état de votre adresse e-mail.",
        EMAIL_INTEGRATION_VERIFIED: 'Cette adresse e-mail a déjà été vérifiée!',
        CONFIRM_DELETE_EMAIL: "Les adresses e-mail sélectionnées seront supprimées. Êtes-vous sûr?",
        INVALID_EMAIL: 'Adresse e-mail invalide',
        EMAIL_CANT_BLANK: "L'adresse e-mail ne peut pas être vide!",
        EDIT_EMAIL_SAVE_SUCCESS: 'E-mail enregistré avec succès',
    },
    NEWS: {
        SAVE_ERROR: 'Impossible de sauvegarder.',
        VALIDATE_CATEGORY: 'La catégorie ne peut pas être vide.',
        VALIDATE_STATUS: "L'état ne peut pas être vide.",
        VALIDATE_TITLE: 'Le titre ne peut pas être vide.',
        VALIDATE_SUMMARY: 'Le résumé ne peut pas être vide.',
        VALIDATE_EXTERNAL_LINK: 'Le lien externe ne peut pas être vide.',
        VALIDATE_DETAILS: 'Les détails ne peuvent pas être vides.',
        CATEGORY_NAME_EXISTS: 'La valeur existe déjà dans la liste.',
        DUPLICATE_TITLE: "Ce titre existe déjà!",
    },
    USEFUL_LINKS: {
        CATEGORY: 'Catégorie',
        LINK: 'Relier',
        TITLE: 'Titre',
        VALID_CATEGORY: 'Veuillez sélectionner une catégorie valide',
        DUPLICATE_CATEGORY: 'Ceci est une catégorie en double, veuillez modifier',
        DUPLICATE_TITLE: "Il s'agit d'un titre en double dans la même catégorie, veuillez le modifier.",
        DUPLICATE_CATEGORY_MSG:'Une catégorie existe déjà avec le même titre. Veuillez modifier le titre de la catégorie.',
        CONFIRM_DELETE_CATEGORY: 'La catégorie sera supprimée. Êtes-vous sûr?',
        CONFIRM_DELETE_LINK: 'Le lien sera supprimé. Êtes-vous sûr?',
        VALID_URL: 'Veuillez saisir une URL valide.',
        EXISTING_LINKS_CATGORY: 'Vous avez des liens existants dans cette catégorie. ',
        PLEASE_MOVE_LINKS: 'Veuillez déplacer les liens vers',
        CLICK_TO_CONFIRM: ' Cliquez sur Ok pour confirmer.',
    },
    HUB_SETUP: {
        VALID_URL: 'Veuillez saisir un',
        URL: 'URL.',
        COMMUNITY: 'URL de la communauté.',
        MANUAL: 'URL manuelle.',
        TRAINING: 'URL de la formation.',
        INVALID_HUB_NAME: 'Veuillez saisir un nom de concentrateur valide',
    },
    CASE: {
        CASE_CLOSED_SUCCESS: 'Affaire clôturée avec succès'
    },
    ORTHOTIC_REPORT: {
        VALIDATE_EMPTY_PRODUCT: 'Veuillez sélectionner un produit.'
    },
    USER_REPORT: {
        REPORT_DELETE_RECORD: 'Le rapport sera supprimé. Êtes-vous sûr?',
        REPORT_DELETE: 'Rapport supprimé avec succès',
        INVALID_START_END_DATE: 'La date de début ne peut pas être postérieure à la date de fin.'
    },
    AUTO_FILL_VALUES: {
        CHNAGE_PARENT_OBJECT: "Voulez-vous vraiment changer l'objet parent?",
        CHANGE_CHILD_OBJECT: "Voulez-vous vraiment changer l'objet enfant?",
        SELECT_PARENT_OBJECT: 'Sélectionnez un objet parent.',
        SELECT_CHILD_OBJECT: 'Sélectionnez un objet enfant.',
        NO_MAPPING_FIELDS: 'Aucun champ de mappage disponible entre ',
        NO_MAPPING_FIELDS_AND: 'et',
        CHILD_FIELD_SIZESHOULD_GREATER: 'La taille du champ enfant doit être supérieure à la taille du champ Parent.',
        MAPPING_FROM: 'Cartographie de ',
        MAPPING_TO: ' à ',
        NO_AVAILBLE_FIELDS_FOR_MAPPING: ". Il n'y a pas de champ commun disponible pour le mappage par défaut.",
        NO_AVAILABLE_PARENT_CHILD_MAPPING: "Il n'y a pas de mappage de champ parent-enfant disponible.",
        FIELD_MAPPING_DONE_SUCCESS: 'Cartographie des champs effectuée avec succès.',

        MAP_FIELDS_TO_AUTOFILL: 'Mappez les champs à remplir automatiquement ',
        MAP_FIELDS_TO_AUTOFILL_FROM: ' de ',
        SELECT_PARENT_FIELD: 'Sélectionnez un champ Parent.',
        SELECT_CHILD_FIELD: 'Sélectionnez un champ enfant.',
        MAPPING_NOT_POSSIBLE: " n'est pas possible."
    },
    LANDING_PAGE: {
        INVALID_NAME: 'Veuillez saisir le nom de la page de destination.',
        INVALID_URL_WARNING: "Veuillez saisir le nom de l'URL.",
        SAVE: 'La page de destination a bien été enregistrée.',
        UPDATE: 'La page de destination a bien été mise à jour.',
        INVALID_FORM_BLOCK_EDIT_MSG: "Le bloc actif actuel n'a aucun champ de formulaire à modifier, veuillez sélectionner le bloc de champs de formulaire à modifier.",
        DUPLICATE_NAME: 'La page de destination existe déjà avec ce nom. Veuillez saisir un autre nom et réessayer.',
        DUPLICATE_URL: 'La page de destination existe déjà avec cette URL. Veuillez saisir une URL différente et réessayer.',
        INPUT_DATA_LOSS_MSG: 'Toutes les données saisies seront effacées. Êtes-vous sûr?',
        EMAIL_REQUIRED_MSG: 'Un e-mail est requis.',
        ATLEAST_ONE_BLOCK_ADD_MSG: 'Veuillez ajouter au moins un Design Block pour enregistrer cette page.',
        PREVIEW_ALERT_MSG: 'Veuillez enregistrer avant de prévisualiser la page de destination.',
        OPEN_URL_ALERT_MSG: "Veuillez enregistrer les modifications avant d'effectuer l'action.",
        COPY_TO_CLIPBOARD: 'Lien du modèle de page de destination copié dans le presse-papiers.',
        FORM_ADDED_MSG: 'Formulaire ajouté avec succès.',
        FORM_UPDATED_MSG: 'Formulaire mis à jour avec succès.',
        INVALID_URL: 'URL invalide.',
        LABEL_FIELD_CANNOT_BLANK: "le champ d'étiquette ne peut pas être vide.",
        FIELD_IS_ALREADY_ADDED: 'Le champ est déjà ajouté.',
        MINIMUM_ONE_FIELD: 'Au moins un champ est requis.',
        NO_MORE_FIELD_AVAILABEL: "Aucun autre champ n'est disponible."
    },
    DOWNLOAD_LEADS: {
        SELECT_CRITERIA: 'Veuillez sélectionner au moins un critère.',
        SELECT_WORKFLOW: 'Veuillez sélectionner un workflow.',
        SELECT_GROUP: 'Veuillez sélectionner un groupe.',
        SELECT_STATUS: 'Veuillez sélectionner le statut.',
        SELECT_SALESREP: 'Veuillez sélectionner un représentant commercial.',
        LEAD_EXIST_MSG_1: 'Vous avez déjà ces prospects dans votre compte - ',
        LEAD_EXIST_MSG_2: '.Cliquez sur OK pour arrêter le téléchargement et supprimer ces prospects de votre sélection.'
    },
    SCHEDULER: {
        BLANK_APPOINTMENT_NAME: 'Veuillez saisir le nom du rendez-vous.',
        BLANK_LOCATION: "Veuillez entrer l'emplacement.",
        SAVE_SUCCESS: 'La configuration du planificateur a bien été enregistrée.',
        SAVE_FAIL: "La configuration du planificateur n'a pas pu être enregistrée.",
        NO_SCHEDULER: "Veuillez créer un planificateur de rendez-vous avant d'utiliser le lien du planificateur.",
        VALIDATE_TIME: "L'heure de début ne peut pas être supérieure ou identique à l'heure de fin.",
        NO_START_TIME: "Veuillez sélectionner l'heure de début.",
        NO_END_TIME: "Veuillez sélectionner l'heure de fin.",
        SCHEDULER_LINK_COPIED_SUCCESS: 'Lien du planificateur copié dans le presse-papiers.',
        BLANK_SUBMIT_BUTTON: "L'étiquette du bouton ne peut pas être vide.",
        CALENDAR_SYNC_MSG: "Votre calendrier CRM n'est pas connecté à votre calendrier GSuite ou Office 365.",
        CALENDAR_SYNC_LINK: 'Cliquer ici pour le préparer.',
        SCHEDULER_UNSAVE_msg: 'Voulez-vous quitter ce formulaire? Les modifications que vous avez apportées ne seront pas enregistrées.',
    },
    RELATION_REPORT: {
        RANGE_FIELD_BLANK: 'Veuillez sélectionner un champ de date.',
        ROW_FIELD_BLANK: 'Veuillez sélectionner un champ de ligne.',
        COL_FIELD_BLANK: 'Veuillez sélectionner un champ de colonne.',
        REPORT_NAME_BLANK: 'Veuillez saisir le nom du rapport.',
        REPORT_SAVE_SUCCESS: 'Rapport enregistré avec succès.',
        DUE_BY_BLANK: 'Veuillez sélectionner une plage de dates.',
        NO_ROW_FIELD_VALUE: "Veuillez sélectionner une valeur pour l'étiquette de ligne.",
        NO_COLUMN_VALUE: "Veuillez sélectionner une valeur pour l'en-tête de colonne.",
        SAME_ROW_COLUMN_FIELD: 'Les champs de champ de ligne et de colonne ne peuvent pas être identiques.',
        INVALID_END_DATE: 'La date de début ne peut pas être postérieure à la date de fin.',
        'Report Name already exists': 'Le nom du rapport existe déjà.',
        VALID_REPORT_NAME: 'Veuillez saisir un nom de rapport valide',
    },
    PAYMENTS: {
        NOT_ADDED_QB: "Le devis / facture n'est pas encore ajouté au Quickbook!",
        NO_LINKED_AVAILABLE: "Il n'y a pas de devis / facture lié disponible!",
        PAYMENT_VALUE_ALERT_MSG: "La valeur du paiement ne peut pas être zéro.",
        RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD: "Vous avez un onglet devis/facture ouvert. Veuillez fermer cet onglet avant d'ouvrir ou d'ajouter un nouveau devis/facture.",
        AMOUNT_EXCEED_BALANCE_DUE: "Le paiement que vous saisissez dépasse le solde dû pour cette facture. Cliquez sur OK pour continuer le paiement. Cliquez sur Annuler pour modifier le montant du paiement."

    },
    PRODUCTS: {
        DUPLICATE_PRODUCT: 'Le nom du produit existe déjà.',
        ADDED_TO_QUICKBOOK: 'Ajouté avec succès à QuickBooks.',
        PULLED_FROM_QUICKBOOKS: 'Extrait avec succès de Quickbooks.',
        PRODUCT_PRICE_VALUE_ALERT_MSG: 'La valeur du prix ne peut pas être zéro.',
        PRODUCT_NAME_COULDNOT_CONTAIN_COLON_MSG: 'ne peut pas contenir deux-points (:).',
        NEGATIVE_PRICE: "Le prix ne peut pas être négatif pour le type d'inventaire."
    },
    COMPANY_PROFILE: {
        BLANK_BUSINESS_NAME: "Veuillez saisir le nom de l'entreprise.",
        BLANK_BUSINESS_ADDRESS: "Veuillez saisir l'adresse professionnelle.",
        BLANK_BUSINESS_PHONE: 'Veuillez saisir le numéro de téléphone professionnel.'
    },
    EXPORT_SETTINGS: {
        CONSIDERATION: 'Considération:',
        CONSIDERATION_MSG1: 'Nous prenons en charge les fichiers au format EXCEL et CSV.',
        CONSIDERATION_MSG2: "Une cellule dans Excel peut contenir un maximum de 32 767 caractères, par conséquent, dans le fichier Excel exporté, nous affichons un maximum de 32 767 caractères pour une cellule et excluons le reste des caractères.",
        NO_RECORDS_FOUND: 'Aucun enregistrement trouvé!',
        FILE_NAME_IS_EMPTY: 'Le nom du fichier est vide.',
        PLEASE_SELECT_THE_FILE_TYPE: 'Veuillez sélectionner le type de fichier.',
        PLEASE_SELECT_THE_PARENT_OBJECT: "Veuillez sélectionner l'objet parent.",
        PLEASE_SELECT_THE_QUERY_NAME: 'Veuillez sélectionner le nom de la requête.',
        PLEASE_SELECT_THE_PARENT_FIELDS: 'Veuillez sélectionner les champs parents.',
    },
    DATA_BASE: {
        FAILURE_MSG: "Échec de l'enregistrement.",
        SUCCESS_MSG: 'Votre paramètre a été enregistré avec succès. Veuillez vous déconnecter et vous reconnecter pour voir les modifications.',
        VALIDATION_MSG: 'Veuillez sélectionner au moins un objet enfant pour ',
        VALIDATION_MSG1: "Vous avez atteint la limite maximale de 15 objets sélectionnés.",
        SUCCESS_MSG1: 'Votre paramètre a été enregistré avec succès.',
    },
    UNITS: {
        BLANK_FIRST_NAME: 'Veuillez entrer le prénom.',
        BLANK_LAST_NAME: 'Veuillez entrer le nom de famille.',
        BLANK_PASSWORD: 'Veuillez entrer le mot de passe.',
        UNMATCH_RETYPE_PASSWORD: 'Le mot de passe à nouveau ne correspond pas au mot de passe.',
        BLANK_RETYPE_PASSWORD: 'Veuillez saisir à nouveau le mot de passe.',
        BLANK_EMAIL: 'Veuillez saisir votre e-mail.',
        BLANK_PHONE: 'Veuillez entrer le téléphone.',
        BLANK_COMPANY: 'Veuillez entrer la société.',
        UNIT_SAVED: 'Enregistré avec succès.',
        UNIT_NOT_SAVED: "L'unité n'a pas pu être enregistrée.",
        DUPLICATE_EMAIL: 'Vous avez déjà des enregistrements avec le même identifiant de messagerie.',
        DUPLICATE_COMPANY: "Le nom de l'entreprise existe déjà.",
        SUBSCRIPTION_NOT_VALID: "L'abonnement n'est pas valide pour l'unité sélectionnée. Veuillez contacter le support Soffront pour plus de détails.",
        NO_UNIT_TASKS: 'Aucune tâche n\'est disponible pour le propriétaire de l\'unité.',
        NO_ACCOUNT_TASKS: 'Aucune tâche n\'est disponible pour le compte.',
        BLANK_FAMILY_INDUSTRY: 'Veuillez sélectionner une industrie familiale',
        NO_FAMILY_INDUSTRY: 'Vous auriez besoin d\'un modèle de base avant de pouvoir ajouter de nouvelles unités. Veuillez contacter le support BrandWide pour configurer un modèle de base.',
        BLANK_PLAN_NAME: 'Veuillez sélectionner un nom de plan.',
        NO_AVAILABLE_PLANS: 'Aucun plan disponible ne peut être inscrit pour cette unité.',
        NO_BRANCH_WORKFLOW: 'Aucun workflow de branche n\'est disponible.',
        PLEASE_SELECT_TYPE: 'Veuillez sélectionner un type pour continuer.',
        NO_BASE_LOGIN: 'La base n\'existe pas ou n\'est pas configurée ! Résolvez le problème et réessayez.',
    },
    SHARE: {
        SHARE_SUCCESS_MSG_1: 'Les informations de partage ont été mises à jour avec succès.',
        SHARE_SUCCESS_MSG_SL: 'Les informations de partage sont mises à jour avec succès pour les niveaux de sécurité sélectionnés, le cas échéant.',
        SHARE_SUCCESS_MSG_2: "Une notification par e-mail sera envoyée aux unités nouvellement sélectionnées s'il y en a.",
        SHARE_SUCCESS_MSG_3: "Une notification par e-mail sera envoyée à l'unité nouvellement sélectionnée, le cas échéant.",
        SHARE_SUCCESS_MSG_4: 'Êtes-vous sûr?',
        SHARE_SUCCESS_MSG_5: "sera partagé avec l'unité sélectionnée.",
        SHARE_SUCCESS_MSG_6: 'seront partagés avec les Unités sélectionnées.',
        SHARE_FAILED_MSG: 'Échec du partage.',
        SHARE_SELECT_CORPORATE_DOCUMENT: 'Veuillez sélectionner au moins un utilisateur professionnel pour partager des documents.',
        SHARE_SELECT_CORPORATE_IMAGE: 'Veuillez sélectionner au moins un utilisateur professionnel pour partager l\'image.',
        SHARE_SELECT_UNIT_DOCUMENT: 'Veuillez sélectionner au moins une unité pour partager des documents.',
        SHARE_SELECT_UNIT_IMAGE: "Veuillez sélectionner au moins une unité pour partager l'image.",
        NO_CONNECTED_CLIENT: "Aucun client connecté n'est disponible pour partager des documents.",
    },
    SECURITY_LEVEL: {
        ERROR_MSG_1: "Oops! Un problème est survenu. Merci d'essayer plus tard.",
        ERROR_MSG_2: 'La description ne peut pas être vide.',
        SUCCESS_MSG_1: 'Votre paramètre a été enregistré avec succès.',
    },
    WHITE_LABEL: {
        LINK_SAVE: 'Le lien a été enregistré avec succès.',
        NOT_VALID_URL: "n'est pas une URL valide"
    },
    ROLL_UP: {
        SELECT_A_CHILD_OBJECT: 'Veuillez sélectionner un objet enfant',
        SELECT_A_PARENT_OBJECT: 'Veuillez sélectionner un objet parent',
        NO_CHILD_FIELD_SELECTED: 'Aucun champ enfant sélectionné',
        NO_PARENT_FIELD_SELECTED: 'Aucun champ parent sélectionné',
        SAME_TYPE_FIELD: 'Veuillez sélectionner le même type de champs pour les objets Enfant et Parent',
        ROLL_UP_EXIST: 'La même combinaison de champs de cumul existe déjà',
        PARENT_FIELD_EXIST: 'Un Roll up a déjà été configuré dans le champ Objet Parent sélectionné.',

    },
    ROYALTY_REPORT: {
        BLANK_ROYALTY_PERCENT: 'Veuillez saisir le pourcentage de redevance.',
        REPORT_SAVE_SUCCESS: 'Rapport enregistré avec succès.',
        SETUP_SAVE_SUCCESS: 'Configuration des redevances enregistrée avec succès.'
    },
    MENU: {
        ATLEAST_ONE_MODULE_ACTIVE: 'Désolé, au moins un module doit être actif.',
        ATLEAST_ONE_MODULE_ITEM_ACTIVE: 'Désolé, au moins un élément du module doit être actif.',
        MODULE_LABEl_CHAR_LIMIT_MSG: 'Le nom ne peut pas dépasser plus de 32 caractères.',
        DUPLICATE_NAME: 'le nom du module existe déjà.'
    },
    SALES_PURCHASE_ORDER: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_PURCHSE_RECORD: "Vous avez un onglet de bon de commande ouvert. Veuillez fermer cet onglet avant d'ajouter un nouveau bon de commande.",
        RESTRICT_TO_OPEN_TWO_OR_MORE_SALES_RECORD: "Vous avez un onglet de commande client ouvert. Veuillez fermer cet onglet avant d'ajouter une nouvelle commande client.",
        SAVE_PURCHASE_SUCCESS: 'Bon de commande enregistré avec succès.',
        SAVE_SALES_SUCCESS: 'Commande client enregistrée avec succès.',
        SALES_REP_NAME_CANT_BLANK: 'Le nom du représentant commercial ne peut pas être effacé.',
        SELECT_ONE_ITEM: "Veuillez sélectionner une information d'article.",
        DISCOUNT_AMOUNT_CANT_GREATER: "Le montant de la remise ne peut pas être supérieur au sous-total.",
        SPECIFY_TRANSC_AMOUNT: 'Vous devez spécifier un montant de transaction égal ou supérieur à 0.',
        SETUP_COMPANY_PROFILE: "Veuillez configurer le profil de votre entreprise avant d'ajouter des devis. Veuillez contacter votre administrateur CRM pour configurer le profil."

    },
    JOBS: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_JOBS_RECORD: "Vous avez un onglet d'emploi ouvert. Veuillez fermer cet onglet avant d'ajouter un nouveau travail.",
        FIELDS_CANNOT_BLANK: ' le champ ne peut pas être vide.',
        PRODUCT_NAME_NOT_CONTAIN: 'Le nom du produit ne peut pas contenir deux points (:).',
        JOB_SAVE_SUCCESS: 'Travail enregistré avec succès.',
        PRODUCT : 'Produit',
        ALREADY_EXIST: 'existe déjà.',

    },
   POWER_CALLING :{
        ALERT: {
            WARNING: 'Êtes-vous sûr',
        },
        YOU_DONT_HAVE_CALL_SCRIPT: "Vous n'avez aucun script d'appel.",
        ALERT_MSG_1: 'Hors de ',
        ALERT_MSG_2: ' vous avez sélectionné, seulement ',
        ALERT_MSG_3: ' avoir des numéros locaux valides. Vous pourrez les appeler ',
        ALERT_MSG_4: 'Parlé avec ',
        ALERT_MSG_5: "Il n'y en a plus",
        ALERT_MSG_6: "dans la file d'attente pour passer un appel.",
        ALERT_MSG_7: "Vous n'avez aucune connexion active.",
        ALERT_MSG_8: "que vous avez sélectionné, aucun d'entre eux n'a de numéro de portable ou de téléphone.",
        ALERT_MSG_9:'Vous avez déjà un Power Dialer ouvert, veuillez le fermer pour ouvrir un nouveau Power Dialer. '
    },
    LEAD_SCORE_SETUP: {
        ALREDY_DEFINED_SCORE: 'Vous avez déjà défini un score pour cette action.',
        SAVE_SUCCESSFULLY: 'Le score a été enregistré avec succès.',
        CAN_NOT_UPDATE: 'Les paramètres de score ne peuvent pas être mis à jour. Veuillez modifier correctement les paramètres de score.',
        SELECT_ONE_ACTION: 'Veuillez sélectionner au moins une action',
        SELECT_ACTION: 'Veuillez sélectionner une action',
        SELECT_OBJECT: 'Veuillez sélectionner un objet',
        SELECT_WORKFLOW: 'Veuillez sélectionner un workflow',
        CONVERT_FROM_CANT_BLANK: 'Converted From ne peut pas être vide',
        CONVERT_TO_CANT_BLANK: 'Converti en ne peut pas être vide',
        CHOOSE_DIFF_STATUS: 'Vous ne pouvez pas convertir le même statut. Veuillez choisir un autre statut. ',
        SELECT_GROUP: 'Veuillez sélectionner un groupe',
        SELECT_FIELD: 'Veuillez sélectionner un champ',
        PROVIDE_VALUE_TO_UPDATE: "Veuillez indiquer une valeur que vous souhaitez mettre à jour",
        ENTER_SCORE: 'Veuillez saisir un score compris entre 1 et 99',
        EXIST_IN_LIST: 'La valeur existe déjà dans la liste',
        SCORE_DELETED: 'Ce score sera supprimé. Êtes-vous sûr?',
        REMOVE_MSG: 'supprimer avec succès',
        UPDATE_MSG: 'mise à jour réussie'
    },
    DUPLICATE_RULE: {
        ERROR_MSG_DEFAULT: 'Vous ne pouvez pas désactiver la règle par défaut.',
        ERROR_MSG_Active: 'Vous ne pouvez pas Deactive une règle par défaut.',
        ERROR_MSG_FOR_FIELDS: "Sélectionnez tous les champs d'un bloc particulier pour enregistrer la règle.",
        ERROR_MSG_1: 'Quelque chose a mal tourné. Veuillez réessayer plus tard.',
        SUCCESS_MSG_1: 'Votre règle double (s) a été enregistré avec succès.',
        ERROR_MSG_SAME_FIELD: " est déjà sélectionné. S'il vous plaît sélectionner un autre champ.",
    },
    REQUIRED_FORM: {
        FIELD_FILL_MESG:'Ces champs doivent être remplis avant cette ',
        CAN_BE_SUBMITTED:' peut être soumis.'
    },
    GREENLIGHT: {
        COMPANY_NAME_BLANK: "Veuillez saisir un nom d'entreprise.",
        COMPANY_NAME_REQUIRED: "Le nom de l'entreprise ne peut pas comporter plus de 30 caractères.",
        ADDRESS1_REQUIRED: "L'adresse 1 est requise.",
        ADDRESS1_ADDRESS2_LIMIT: "L'adresse 1 + l'adresse 2 combinées ne peuvent pas comporter plus de 30 caractères.",
        PHONE_NUMBER_REQUIRED: "S'il vous plaît entrer un numéro de téléphone valide.",
        PHONE_NUMBER_LIMIT: 'Les numéros de téléphone ne peuvent pas comporter plus de 10 chiffres.',
        NET_ANNUAL_LIMIT: 'Net Annuel ne peut pas être vide et ne peut pas être zéro.',
        NET_ANNUAL_INVALID: 'Veuillez entrer un montant valide dans Net Annuel.'
    },
    AUDIT_QUESTIONS: {
        QUESTION_REQUIRED: 'Question est requise',
        ENTER_VALID_SCORE: "S'il vous plaît entrer un score valide",
        ENTER_VALID_CHOICES: "S'il vous plaît entrer des choix valides",
        SUCCESS: 'Succès',
        ERROR: 'Erreur',
        NO_QUESTIONS: "Aucune question trouvée",
        THANK_YOU: "Merci!",
        SOMETHING_WRONG: "Quelque chose s'est mal passé lors de la soumission de vos commentaires, veuillez réessayer!",
        FEATURE_NOT_AVAILABLE: "Cette fonctionnalité n'est pas disponible.",
        RECEIVED_FEEDBACK_1: "Bien joué! Vous avez enregistré avec succès les réponses pour le ",
        RECEIVED_FEEDBACK_2:"catégorie.",
        RECEIVED_FEEDBACK_3:"N'oubliez pas que vous pouvez toujours mettre à jour vos réponses pour cette catégorie avant la soumission finale.",
        MAIL_SENT_MESSAGE:'Email envoyé.',
        SUBMIT_AUDIT_1:'Il y a des questions obligatoires sans réponse dans les catégories suivantes : ',
        SUBMIT_AUDIT_2:"Veuillez répondre aux questions requises, puis soumettre l'audit.",
        SUBMIT_AUDIT_3:'Vous avez soumis votre audit avec succès !',
        SUBMIT_AUDIT_4:"Vous pouvez consulter vos réponses d'audit soumises et vos scores dans le CRM sous HUB > Audits.",
        SUBMIT_AUDIT_5:'Vous recevrez une notification par e-mail si le franchiseur fournit des commentaires sur votre audit.',
        SUBMIT_AUDIT_6:'Vous pouvez également voir les commentaires donnés par le franchiseur et prendre des mesures correctives depuis HUB > Audits.',
        SAVE_ALERT_1:'Attention! Tu es sur le point de quitter ça ',
        SAVE_ALERT_2:'catégorie sans enregistrer les modifications.',
        SAVE_ALERT_3:"Veuillez cliquer sur OK pour continuer après l'enregistrement, ou fermez cette alerte pour rester ici et continuer avec cette catégorie.",
        REQUEST_CORRECTIVE_SUCCESS:'Merci! Vos commentaires ont été envoyés par e-mail avec succès.',
        SUBMIT_CORRECTIVE_ACTION:'Merci! Vos informations sur les mesures correctives ont été envoyées par e-mail avec succès.',
        EMPTY_REQUEST_CORRECTIVE_MESSAGE:"Vous n'avez ajouté vos commentaires pour aucune des questions."+'\n'+"Veuillez d'abord ajouter vos commentaires, puis cliquer sur « Demander des actions correctives ».",
        EMPTY_SUBMIT_CORRECTIVE_MESSAGE:"Vous n'avez ajouté vos actions correctives à aucun des commentaires." + '\n' + "Veuillez d'abord ajouter l'action corrective, puis cliquez sur « Soumettre les actions correctives ».",
        EMPTY_AUDIT_BY_NAME_AND_EMAIL:'Votre nom et votre adresse e-mail sont vides. Veuillez saisir des valeurs dans ces champs.',
        EMPTY_AUDIT_BY_NAME:'Votre champ Nom est vide. Veuillez saisir des valeurs dans ces champs.',
        EMPTY_AUDIT_BY_EMAIL:"Votre champ d'adresse e-mail est vide. Veuillez saisir des valeurs dans ces champs.",
        AUDIT_TYPE_SELECT_VALIDATION:"Veuillez sélectionner un type d'audit pour générer le lien.",
        INVALID_AUDIT_EMAIL:'Veuillez entrer une adresse email valide.',
        INVALID_AUDIT_NAME:'Veuillez entrer un nom valide.',
    },
    AUDIT_ANSWERS:{
        NO_RECORD_FOUND:'Aucune réponse avec des questions correctives trouvées',
    },
    AUDIT_QUESTION_TYPES:{
        MAX_EXCEED:'Massimo superato',
    },
    AUDIT_CATEGORY:{
        CATEGORY_BALNK_MESSAGE:"La catégorie ne peut pas être vide.",
        CATEGORY_CHANGES_UPDATED:'Modifications de catégorie mises à jour.',
        CATEGORY_ALREADY_EXIST:"Cette catégorie existe déjà. La création de catégories en double n'est pas autorisée.",
        SAVED:'Catégorie Sauvegardée',
        MAX_EXCEED:"Vous avez atteint la limite maximale d'ajout de catégories. Nous autorisons jusqu'à 50 catégories.",
    },
    COMPLIANCE: {
        SINGLE_RECORD_EDIT: 'Veuillez sélectionner 1 enregistrement à modifier.',
    },
    SALES_AND_REFUND: {
        ALL_OPTIONS_REQUIRED: 'Veuillez sélectionner tous les filtres disponibles et réessayer.',
    },
    INTAKE_FORM : {
        RESTRICT_TO_OPEN_TWO_OR_MORE_INTAKE_FORM:'You have an open Intake form tab. Please close that tab before opening or adding a new Intake form.',
        INTAKE_FORM_SUCCESSFULLY_SAVE: 'Saved Successfully.',
        THANK_YOU: "Thank You!",
    },
    EXCEPTION: {
        EXCEPTION_SAVE_SUCESS: 'Saved Successfully.',
    },
    PHONE_NUMBERS: {
        USE_FOR_NOTIFICATION_SAVE: 'À partir du numéro mis à jour pour les notifications.',
        USE_NOTIFICATION: 'Ce numéro loué sera utilisé pour la notification. Veuillez cliquer sur OK pour confirmer.',
        REMOVE_NOTIFICATION: 'Ce numéro loué sera supprimé pour notification. Veuillez cliquer sur OK pour confirmer.',
    },
    PHONE_AND_TEXT_INTEGRATION: {
        SAVE_SUCCESS: 'Votre paramètre a été enregistré avec succès.',
        SAVE_FAIL: 'Votre paramètre n\'est pas enregistré.',
        NO_PROVIDER: 'Aucun fournisseur n\'a encore été sélectionné pour la fonctionnalité Téléphone et SMS.\n Veuillez d\'abord sélectionner un fournisseur afin d\'utiliser les fonctionnalités Téléphone et SMS.',
        NO_TWILIO_NUMBERS_FOUND: 'Aucun numéro de téléphone n\'est disponible dans votre compte Twilio connecté.\n Veuillez d\'abord ajouter le numéro dans votre compte Twilio, puis effectuez l\'opération d\'importation.',
        NONE_SELECT_CONFIRM: 'Cela désactivera l\'option Téléphone et SMS dans CRM à tous les endroits.\n Si vous disposez d\'un workflow ou d\'une campagne d\'automatisation dans laquelle le texte est déjà configuré, veuillez les modifier en conséquence car cela ne fonctionnera plus.'
    }
}
export default message