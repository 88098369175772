import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { constants } from "../../../services/constants/constants";
import {
	getActiveTabInfo,
	updateActiveTabProps,
	getActiveTab,
} from "../../../services/helper/sfTabManager";
import PopOver from "../components/PopOver";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { getObjectParam } from "../../../services/helper/parameterVerifier";
import { useSelector } from "react-redux";

let campaignList = [
	{
		value: "Automated Campaigns",
		label: getLocalizedStrings()?.label?.CAMPAIGNS?.WITH_AUTOMATION,
	},
	{
		value: "Touch Campaigns (Legacy)",
		label: getLocalizedStrings()?.label?.CAMPAIGNS?.WITHOUT_AUTOMATION,
	},
];

const CampaignToggleAutomation = ({ props }) => {
	const tab = useSelector((state) => state.tab);

	const [url, setUrl] = useState("");
	const [selectedCampaign, setSelectedCampaign] = useState(
		"Automated Campaigns"
	);
	const [openCampaignTypeList, setOpenCampaignTypeList] = useState(false);
	const [redirect, setRedirect] = useState(false);
	const [anchorEl, setAnchorEl] = useState("");

	useEffect(() => {
		let activeTab = getActiveTab();
		activeTab = getObjectParam(activeTab);
		if (activeTab["url"].includes(constants.TOUCH_CAMPAIGNS_OBJECT)) {
			setSelectedCampaign("Touch Campaigns (Legacy)");
		}
	}, []);

	// UNSAFE_componentWillReceiveProps(nextProps) {
	//     if (nextProps.object !== this.props.object) {
	//         this.state.redirect = false;
	//         if (nextProps.object === constants.AUTOMATION_DESIGNER_OBJECT) {
	//             this.state.selectedCampaign = 'Automated Campaigns';
	//         }
	//     }
	// }

	const openCampaignType = (event) => {
		event.preventDefault();
		setOpenCampaignTypeList(true);
		setAnchorEl(event.currentTarget);
	};

	const closeCampaignTypeList = () => {
		setOpenCampaignTypeList(false);
	};

	const handleChangeCampaignList = (value) => {
		let url = null;
		try {
			if (selectedCampaign === value) {
				setOpenCampaignTypeList(false);
				return false;
			}
			if (value === "Automated Campaigns") {
				url = "/" + constants.AUTOMATION_DESIGNER_OBJECT + "/queries";
			} else if (value === "Touch Campaigns (Legacy)") {
				url = "/" + constants.TOUCH_CAMPAIGNS_OBJECT + "/queries";
			}

			url = url.toLowerCase();
			url = url.replace("_", "-");

			let activeTabInfo = getObjectParam(getActiveTabInfo());
			if (activeTabInfo.hasOwnProperty("isSearchWithinList")) {
				delete activeTabInfo.isSearchWithinList;
			}
			if (activeTabInfo.hasOwnProperty("searchFieldName")) {
				delete activeTabInfo.searchFieldName;
			}
			if (activeTabInfo.hasOwnProperty("searchText")) {
				delete activeTabInfo.searchText;
			}

			delete activeTabInfo.groupByCondition;
			delete activeTabInfo.groupByConditionTotalCount;
			delete activeTabInfo.groupByFieldName;
			delete activeTabInfo.pageSize;
			delete activeTabInfo.selectedPageNo;
			delete activeTabInfo.startIndex;
			delete activeTabInfo.startPageNo;

			updateActiveTabProps({ url: url, info: activeTabInfo });
			setRedirect(true);
			setUrl(url);
			setSelectedCampaign(value);
			setOpenCampaignTypeList(false);
		} catch (error) {
			console.error(
				"Error in 'CampaignToggleAutomation.js -> handleChangeCampaignList()':" +
					error
			);
		}
	};

	if (redirect) {
		return <Navigate to={url} />;
	}
	let _selectedCampaign = selectedCampaign;

	if (selectedCampaign === "Automated Campaigns") {
		_selectedCampaign = getLocalizedStrings().label.CAMPAIGNS.WITH_AUTOMATION;
	} else if (selectedCampaign === "Touch Campaigns (Legacy)") {
		_selectedCampaign = getLocalizedStrings().label.CAMPAIGNS.WITHOUT_AUTOMATION;
	}
	return (
		<div
			style={{
				cursor: "pointer",
				paddingLeft: "0px",
				paddingRight: "0px",
				float: "left",
				marginRight: "0px",
				marginTop: "1.5px",
			}}
		>
			<div
				style={{
					display: "flex",
					fontSize: "14px",
					marginLeft: "8px",
					marginTop: "5px",
				}}
			>
				{_selectedCampaign}
				<PopOver
					id={"campaign"}
					key={"campaign"}
					name={"campaign"}
					buttonEndIcon={"keyboard_arrow_down"}
					buttonStyle={{
						paddingTop: "0px",
						paddingLeft: "2px",
						marginTop: "0px",
						marginLeft: "0px",
					}}
					labelposition={"before"}
					buttonLabel={""}
					options={campaignList}
					onclickAction={handleChangeCampaignList}
					isShowIcon={true}
					anchorEl={anchorEl}
					anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
					targetOrigin={{ horizontal: "left", vertical: "top" }}
				/>
			</div>
		</div>
	);
};

export default CampaignToggleAutomation;
