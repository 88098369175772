import { actions } from "../constants/actions";

const setSelectedSMSVerificationForm = (form) => {
    return (dispatch) => {
        dispatch({
            type: actions.SET_SELECTED_BRAND_DETAILS_FORM,
            payload: {
                selectedForm: form,
            },
        });
    };
};

const setIsErrorSMSVerificationForm = () =>{
    return (dispatch) =>{
        dispatch({
            type: actions.SET_IS_ERROR_SMSVERIFICATION_FORM,
            payload:{
                isError: {},
            }
        })
    }
}

export {
    setSelectedSMSVerificationForm,setIsErrorSMSVerificationForm
};

