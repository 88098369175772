import React, { useState, useEffect,useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, Box, Card, Divider, FormControlLabel, Checkbox, CardActions, CardHeader, CardMedia, CardActionArea, ListSubheader, Icon, FormControl, RadioGroup, List, ListItem, ListItemText, Radio } from '@mui/material';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { constants } from '../../../services/constants/constants';
import { Link } from 'react-router-dom';
import { styles } from '../../../services/constants/styles';
import MarketingTemplate from './MarketingTemplate'
import ShowCircularProgress from '../components/circularProgress';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import LandingPage from '../components/landingPage';
import { getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import { getObjectParam } from '../../../services/helper/parameterVerifier';

const PreviewEmailTemplate = ({ object }) => {
    const dispatch = useDispatch();
    const fieldRefStatus = useRef();
    const appContainer = useSelector((state) => state.appContainer);

    const [templateList, setTemplateList] = useState([]);
    const [selectedCatagoryName, setselectedCatagoryName] = useState(new Set());
    const [layout_id, setLayout_id] = useState(0);
    const [categoriesNameList, setCategoriesNameList] = useState({});
    const [viewMarketingTemplate, setViewMarketingTemplate] = useState(false);
    const [marketingTemplateId, setMarketingTemplateId] = useState(0);
    const [template_list_mounted, setTemplate_list_mounted] = useState(false);
    // const [checkedLayout, setCheckedLayout] = useState(0);
    const [editorType] = useState(constants.EDITOR_BEE);
    const [maketingLayoutList] = useState([
        {
            "name": getLocalizedStrings().label.PREVIEW_EMAIL_TEMPLATE.ALL_LAYOUT,
            value: 0,
        },
        {
            "name": getLocalizedStrings().label.PREVIEW_EMAIL_TEMPLATE.LEFT_SIDEBAR_LAYOUT,
            value: 1,
        },
        {
            "name": getLocalizedStrings().label.PREVIEW_EMAIL_TEMPLATE.RIGHT_SIDEBAR_LAYOUT,
            value: 2,
        },
        {
            "name": getLocalizedStrings().label.PREVIEW_EMAIL_TEMPLATE.HORIZONTAL_LAYOUT,
            value: 3,
        },
        {
            "name": getLocalizedStrings().label.PREVIEW_EMAIL_TEMPLATE.MULTIPLE_COLUMN_LAYOUT,
            value: 4,
        }
    ]);
    const [checkedAll, setCheckedAll] = useState(true);

    const handlePreviewEmailComponentState = (marketingTemplateId) => {
        setViewMarketingTemplate(true);
        setMarketingTemplateId(marketingTemplateId);
    }
    const handleBackComponetFromMarketingTemplate = () => {
        setViewMarketingTemplate(false);
        setMarketingTemplateId(0);
    }
    useEffect(() => {
        let endPointUrl = '';
        let categories = new Set();
        let tempCategoriesNameList = categoriesNameList;
        let marketingCategoryType = 1;
        if (object === constants.LANDING_PAGES_FROALA_OBJECT) {
            marketingCategoryType = 2;
        }
        let params = {
            fields: ["t_m_categories", "t_pk"],
            type: editorType,
            object: object,
            marketing_category_type: marketingCategoryType
        }
        var response = Promise.resolve(HTTPClient.get(endPoints.MARKETING_TEMPLATE.MARKETINGCATEGORYLISTBYTYPE, params));
        response.then((response) => {
            for (let i = 0; i < response.records.length; i++) {
                categories.add(response.records[i].t_m_categories);
                tempCategoriesNameList[response.records[i].t_m_categories] = true;
            }

            setselectedCatagoryName(categories);
            setCategoriesNameList(tempCategoriesNameList);
        });

        endPointUrl = endPoints.MARKETING_TEMPLATE.LIBRARY_TEMPLATE;
        if (object === constants.LANDING_PAGES_FROALA_OBJECT) {
            endPointUrl = endPoints.LANDING_PAGE.TEMPLATE_LIBRARY;
        }

        response = Promise.resolve(HTTPClient.get(endPointUrl, { "category": ["All"], type: editorType }));
        response.then((response) => {

            setTemplateList(response.library_templates);
            setTemplate_list_mounted(true);
        });
    }, []);


    const regenerateTemplateList = () => {
        let params = {};
        setTemplate_list_mounted(false);
        if (layout_id !== 0) {
            params['layout_id'] = layout_id;
        }

        var category = [...selectedCatagoryName];
        if (category.length !== 0) {
            let endPointUrl = endPoints.MARKETING_TEMPLATE.LIBRARY_TEMPLATE;
            if (object === constants.LANDING_PAGES_FROALA_OBJECT) {
                endPointUrl = endPoints.LANDING_PAGE.TEMPLATE_LIBRARY;
            }
            params['category'] = category;
            var response = Promise.resolve(HTTPClient.get(endPointUrl, JSON.stringify(params)));
            response.then((response) => {

                setTemplateList(response.library_templates);
                setTemplate_list_mounted(true);
            });
        } else {
            setTemplateList([]);
            setTemplate_list_mounted(true);
        }
    }

    const generateTemplateList = () => {
        return templateList.map((template, index) => {
            return (
                <div className="col-md-4"
                    key={index} style={{ width: '250px', textAlign: 'center', marginRight: '40px', marginLeft: '4px', marginBottom: '15px', paddingLeft: '7px', paddingRight: '7px' }}>
                    <PreviewTemplate template={template} handlePreviewEmailComponentState={handlePreviewEmailComponentState} object={object} />
                </div>
            )
        });
    }
    const toggleCheckbox = (category) => {
        let checkBoxName = category;
        let tempSelectedCatagoryName = selectedCatagoryName;
        let tempCategoriesNameList = categoriesNameList;

        if (checkBoxName === "ALL") {
            if (checkedAll === false) {
                let categoryObjectNameList = Object.keys(categoriesNameList);
                for (let i = 0; i < categoryObjectNameList.length; i++) {
                    let categoryName = categoryObjectNameList[i];
                    if (selectedCatagoryName.has(categoryName)) {
                        tempCategoriesNameList[categoryName] = true;
                        setCategoriesNameList(tempCategoriesNameList);
                    } else {
                        tempSelectedCatagoryName.add(categoryName);
                        setselectedCatagoryName(tempSelectedCatagoryName);
                        tempCategoriesNameList[categoryName] = true;
                        setCategoriesNameList(tempCategoriesNameList);
                    }

                    setCheckedAll(true);
                }
            } else {
                let categoryObjectNameList = Object.keys(categoriesNameList);
                for (let i = 0; i < categoryObjectNameList.length; i++) {
                    let categoryName = categoryObjectNameList[i];
                    if (selectedCatagoryName.has(categoryName)) {
                        tempSelectedCatagoryName.delete(categoryName);
                        setselectedCatagoryName(tempSelectedCatagoryName);
                        tempCategoriesNameList[categoryName] = false;
                        setCategoriesNameList(tempCategoriesNameList);
                    } else {
                        tempCategoriesNameList[categoryName] = false;
                        setCategoriesNameList(tempCategoriesNameList);
                    }
                    setCheckedAll(false);
                }
            }

        } else {
            if (selectedCatagoryName.has(checkBoxName)) {
                tempSelectedCatagoryName.delete(checkBoxName);
                setselectedCatagoryName(tempSelectedCatagoryName);
                tempCategoriesNameList[checkBoxName] = false;
                setCategoriesNameList(tempCategoriesNameList);
            } else {
                // tempSelectedCatagoryName.selectedCatagoryName.add(checkBoxName);
                tempSelectedCatagoryName.add(checkBoxName);
                setselectedCatagoryName(tempSelectedCatagoryName);
                tempCategoriesNameList[checkBoxName] = true;
                setCategoriesNameList(tempCategoriesNameList);
            }
            setCheckedAll(false);
        }
        regenerateTemplateList();
    }

    const changeRadioButton = (value) => {
        if (layout_id === value) {
            setLayout_id(0);
        } else {
            setLayout_id(value);
        }

        regenerateTemplateList();
    }

    const generateLayoutListItem = () => {
        let array = maketingLayoutList;
        return array.map((layout, index) => {
            return (
                <FormControl component="fieldset">
                    <FormControlLabel value={layout.value} control={<Radio onChange={(event) => {
                        if (layout_id === event.target.value) {
                            setLayout_id(0);
                            regenerateTemplateList();
                        }
                    }} color="default" />} label={layout.name} />

                </FormControl>
            )
        });
    }
    const generateCheckBoxHtml = (i, category, checked) => {
        let html = (<Checkbox
            edge="start"
            color="default"
            id={category + '-' + i}
            tabIndex={-1}
            checked={checked}
            disabled={false}
            name={category}
            disableRipple
            className="sfpreviewtemplste-change"
            style={{ color: "default", paddingLeft: '5px', padding: '0px', marginLeft: '1px', marginRight: '9px' }}
            inputprops={{ 'aria-labelledby': category + i }}
        />);
        return html;
    }

    let array = categoriesNameList;
    let height = window.innerHeight - 150;
    let top = (height - 10) / 2
    if (viewMarketingTemplate && marketingTemplateId !== 0) {
        if (object === constants.LANDING_PAGES_FROALA_OBJECT) {
            return(<div>
            <LandingPage id={marketingTemplateId} object={object} handleBackComponetFromMarketingTemplate={()=>handleBackComponetFromMarketingTemplate()} type={"template"} />
        </div>)
        } else {
            if (editorType === constants.EDITOR_BEE) {
                let style = {};
                style.width = '100%';
                let data = { ...getObjectParam(appContainer.customDrawerProps.data), id: marketingTemplateId };
                if (data.object === constants.MARKETING_TEMPLATE_OBJECT) {
                    data.type = 'New';
                }
                dispatch(getAppCustomDrawer(true, data.labelName, 'new', style, data, null, false));
            } else {
                return <MarketingTemplate id={marketingTemplateId} handleBackComponetFromMarketingTemplate={handleBackComponetFromMarketingTemplate()} />
            }
        }
    }
    if (template_list_mounted === false) {
        return (
            <div style={{ width: '100%', height: height }} >
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '50%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 55, width: 55, padding: 6 }}>
                        <ShowCircularProgress size={35} style={{ marginTop: '4', marginLeft: '4' }} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="drawerbox ">
            <div className="row">
                <div className="col-md-12">
                    <h4 style={{ paddingLeft: '20px' }}><b>{getLocalizedStrings().message.MARKETING_TEMPLATE.SELECT_DESIGN}</b> </h4>
                </div>
                <div className="col-md-3">
                    <div style={{ marginBottom: 24, marginRight: 0, maxWidth: '267px', width: '100%', className: "sf-fields-bg" }}>
                        <div style={{ border: 'solid 1px #D9D9D9', overflow: 'hidden' }}>

                            {/* workig here */}

                            <List>
                                <ListItem button onClick={() => toggleCheckbox("ALL")} style={{ paddingLeft: '8px', paddingTop: "3px", paddingBottom: '0px', height: '30px' }}>
                                    <Checkbox className="sf-previewemailtemp-change" name={"ALL"} color="default" checked={checkedAll} />
                                    <ListItemText primary={<div style={styles.secondaryTextStyle}>{getLocalizedStrings().label.MARKETING_TEMPLATE.CATEGORY}</div>} />
                                </ListItem>
                                <Divider />
                                {array !== undefined &&
                                    Object.keys(array).map((category, index) => {
                                        let categoryLabel = getLocalizedStrings().label.MARKETING_TEMPLATE.hasOwnProperty(category) ? getLocalizedStrings().label.MARKETING_TEMPLATE[category] : category;
                                        if (categoryLabel !== "" && categoryLabel !== null && categoryLabel !== undefined && categoryLabel !== 'undefined') {
                                            return (
                                                <ListItem
                                                    key={index}
                                                    role={undefined}
                                                    dense
                                                    button
                                                    onClick={() => toggleCheckbox(category)}
                                                >
                                                    {generateCheckBoxHtml(index, category, array[category])}
                                                    <ListItemText
                                                        primary={<div style={styles.secondaryTextStyle}>{categoryLabel}</div>}
                                                    />
                                                </ListItem>

                                            )
                                        }

                                    })
                                }
                            </List>
                            <Divider />

                            {editorType !== constants.EDITOR_BEE && 
                            <List>
                                <ListSubheader style={{ fontWeight: 'bold' }}>{getLocalizedStrings().label.MARKETING_TEMPLATE.LAYOUT} {/*Layout*/}</ListSubheader>
                                <FormControl component="fieldset">
                                    <RadioGroup ref={fieldRefStatus.rest1} name="layout" onChange={(value) => changeRadioButton(value)} defaultValue={layout_id}>
                                        {generateLayoutListItem()}
                                    </RadioGroup>
                                </FormControl>

                            </List>
                            }

                        </div>
                    </div>
                </div>

                <div className="col-md-9">
                    <div className="row">
                        {generateTemplateList()}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default PreviewEmailTemplate;


const PreviewTemplate = ({template, handlePreviewEmailComponentState, object }) => {

    const app = useSelector((state) => state.app);
    const [shadow,setShadow] = useState(1);
    const [display,setDisplay] = useState('none');
    const [cardStyle, setCardStyle] = useState({cardWrapper: {
        display: 'inline-block',
        position: 'relative',
        cursor: ' pointer',
        width: '100%',
        height: '100%',
        fontSize: '15px'
    }
    ,
    titleTypographyProps: {
        textOverflow: 'ellipsis',
        width: '240px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        fontSize: '15px',
        fontWeight: '150',

    },
    layer: {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.54)',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        display: 'none',
    }});

    const onMouseOver = () => {
        let tempStyle = cardStyle; 
        tempStyle.layer.display = "block";
        setCardStyle(tempStyle);
        setShadow(3);
        setDisplay('block');
    }

    const onMouseOut = () => {
        let tempStyle = cardStyle; 
        tempStyle.layer.display = 'none';
        setCardStyle(tempStyle);
        setShadow(1);
        setDisplay('none');
    }

    const handleClickUseThisDesign = () => {
        handlePreviewEmailComponentState(template.t_pk);
    };

    const handlePreviewTemplate = () => {
        if (object === constants.LANDING_PAGES_FROALA_OBJECT) {
            window.open(template.t_url);
        } else {
            window.open('/#/previewtemplates/' + template.t_pk)
        }
    }

    let btnWidth = '80%';
    let language = getLocalizedStrings()._language;
    if (language === "de-DE" || language === "fr-FR") {
        btnWidth = '90%';
    }
    let themeColor = app.me.background;
    return (


        <div style={{ marginTop: '0px' }}>
            <Box boxShadow={3} p={0} m={0}>
                <Card >
                    <CardActionArea onMouseOver={() => onMouseOver()}
                        onMouseOut={() => onMouseOut()}
                        style={{ width: '240px', }}>
                        <CardHeader
                            title={<div style={cardStyle.titleTypographyProps}>
                                {template.t_name}
                            </div>}
                            style={{ padding: '0px', paddingTop: '10px', paddingBottom: '10px' }}
                        />
                        <div style={{...cardStyle.cardWrapper }}>
                            <CardMedia
                                component="img"
                                image={template.t_preview_img}
                                style={{ alt: "", width: "240", height: "320" }}
                            >
                            </CardMedia>
                            <CardActions>
                                <div style={{ ...cardStyle.layer }}>
                                    <div style={{ paddingTop: '100px', textAlign: 'center' }}>

                                        <Link onClick={() => handleClickUseThisDesign()} style={{ textDecoration: 'none' }}>
                                            <Button variant="contained"
                                                labelColor="#FFF"
                                                className={"sf-pet-USE_THIS_DESIGN"}
                                                backgroundColor={themeColor}
                                                style={{ ...styles.sfUSETHISDESIGN, width: btnWidth, backgroundColor: "rgb(89, 106, 198)", color: "#fff" }}
                                                title={getLocalizedStrings().label.MARKETING_TEMPLATE.USE_THIS_DESIGN}
                                            > {getLocalizedStrings().label.MARKETING_TEMPLATE.USE_THIS_DESIGN}</Button>
                                        </Link>
                                        <br />
                                        <br />
                                        <Button variant="contained"
                                            buttonStyle={{ backgroundColor: '#808080', color: '#FFF', width: '155px' }}
                                            secondary={true}
                                            className={"sf-pet-PREVIEW"}
                                            style={{ backgroundColor: '#616161', color: '#fff' }}
                                            endIcon={<Icon>search</Icon>}
                                            onClick={() => handlePreviewTemplate()}
                                            title={getLocalizedStrings().label.COMMON.PREVIEW}
                                        >{getLocalizedStrings().label.COMMON.PREVIEW}</Button>
                                    </div>
                                </div>
                            </CardActions>
                        </div>
                    </CardActionArea>
                </Card>
            </Box>
        </div>
    );
}

