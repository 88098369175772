import { useState } from "react";
import { hasAccessPermission } from "../../../services/helper/common";
import { constants, OBJECT_TABLEID_MAP } from "../../../services/constants/constants";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { getBooleanParam, getIntParam, getObjectParam, getStringParam, isValidParam, makeValidAppUrl } from "../../../services/helper/parameterVerifier";
import { Icon, Menu, MenuItem } from "@mui/material";
import moment from "moment";
import { dateTimeFormat, dateTimeFormatFlatPicker } from "../../../services/helper/utils";
import { addTab, getActiveTabInfo, TYPE_DETAIL_VIEW } from "../../../services/helper/sfTabManager";
import { deleteAction, reschedule } from "../../../services/actions/batchActions";
import { styles } from "../../../services/constants/styles";
import { endPoints } from "../../../services/constants/endPoints";
import * as HTTPClient from '../../../services/helper/httpClient';
import * as sfDialogs from "../components/sfDialogs";
import {getAppDialog} from '../../../services/actions/appContainerActions';
import { refreshColumnView } from "../../../services/actions/listViewAcions";
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import PopOver from "./PopOver";
import { useDispatch } from "react-redux";
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getEndPointObject } from "../../../services/actions/sfFormActions";


const ColumnViewTaskApps = (props) => {
	const dispatch = useDispatch();
	const [openActionList, setOpenActionList] = useState(false);
	const [actionId, setActionId] = useState(0);
	const [parentRecurId, setParentRecurId] = useState(0);
	const [actionObject, setActionObject] = useState('');
	const [anchorE2, setAnchorE2] = useState(null);
	const [dummyState, setDummyState] = useState(false);

	// **
	function colourChangeTaskApp(taskAppDate) {
		let color = null;
		let date = new Date(taskAppDate);
		let day = date.getDate();
		let mnt = date.getMonth();
		let yer = date.getFullYear();
		let toDate = new Date();
		if (yer > toDate.getFullYear()) {
			color = "rdi-green";
		} else if (yer < toDate.getFullYear()) {
			color = "rdi-red";
		} else {
			if (mnt > toDate.getMonth()) {
				color = "rdi-green";
			} else if (mnt < toDate.getMonth()) {
				color = "rdi-red";
			} else {
				if (day > toDate.getDate()) {
					color = "rdi-green";
				} else if (day < toDate.getDate()) {
					color = "rdi-red";
				} else {
					color = "rdi-black";
				}
			}
		}
		return color;
	}

	// **
	function getObjectByType(type) {
		let object = null;
		if (type === 'task' || type === 'tasks') {
			object = constants.TASKS;
		} else if (type === 'appointment' || type === 'appointments') {
			object = constants.APPOINTMENTS;
		} else if (type === 'email') {
			object = constants.EMAIL_OBJECT;
		} else if (type === 'sms') {
			object = constants.SMS_OBJECT;
		}
		return object;
	}


	// **
	function handleFlatPickerDateChange(record, dueByDateObj, date) {
		let dateValue = new Date(date);
		let id = record.id;
		let taskType = record.taskType;
		let object = getObjectByType(record['type']);
		let selectedDate = new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate(), dateValue.getHours(), dateValue.getMinutes(), dateValue.getSeconds());
		const momentDate = moment(selectedDate);
		try {
			let value = momentDate.format('YYYY-MM-DD HH:mm:ss');
			let rescheduleDate = momentDate.format(dateTimeFormat[props.app.me.date_format]);
			let duration = "date";
			let recordIdArr = [];
			recordIdArr.push(id);
			let msg = "";
			if (object === constants.TASKS) {
				if (props.task.data.hasOwnProperty('records')) {
					msg = getLocalizedStrings().message.APPOINTMENTS_TASKS.RESCHEDULE_TASK_SUCCESS
				} else {
					msg = getLocalizedStrings().message.APPOINTMENTS_TASKS.RESCHEDULE_TASK_SUCCESS
				}
			} else if (object === constants.APPOINTMENTS) {
				msg = getLocalizedStrings().message.APPOINTMENTS_TASKS.RESCHEDULE_APPOINTMENT_SUCCESS;
			} else if (object === constants.EMAIL_OBJECT) {
				msg = getLocalizedStrings().message.MAIL.RESCHEDULE_SUCCESS;
			} else if (object === constants.SMS_OBJECT) {
				msg = getLocalizedStrings().message.SMS_INTEGRATION.RESCHEDULE_SUCCESS;
			}

			let params = {
				"id": recordIdArr,
				"type": duration,
				"date": value
			}

			if (object === constants.EMAIL_OBJECT) {
				params.emailType = taskType;
			}

			let isReschedule = dueByDateObj !== rescheduleDate;
			handleCalenderReschedule(params, object, isReschedule, msg)

		} catch (error) {
			console.error("Error in 'detailViewTaskApps.js -> handleFlatPickerDateChange()':" + error);
		}
	}

	function handleCalenderReschedule(params, object, isReschedule, msg) {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		let isUnitsAccount = false;
		if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
			isUnitsAccount = tabInfo.isUnitsAccount;
		}
		let hasPermission = null;
		if (isValidParam(params) && params.date === 'Invalid') {
			isReschedule = false;
		}
		if (isReschedule) {
			let permissionObject = object
			if (object === constants.EMAIL_OBJECT || object === constants.SMS_OBJECT) {
				permissionObject = constants.TASKS;
			}

			let listIndex = props.lists.findIndex(f => f.columnName === props.status);
			let cardIndex = props.lists[listIndex].cards.findIndex(f => f.id === props.item.id);
			let dataParams = {
				record_id: props.item.id,
				object: props.object,
				listIndex: listIndex,
				cardIndex: cardIndex,
				updateCards: props.updateCards
			}
			hasPermission = hasAccessPermission(permissionObject, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
			if (hasPermission && !isInXpress && !isUnitsAccount) {
				const response = reschedule(object, params);
				response.then((response) => {
					if (response.status === 0) {
						props.closeParentPopOver();
						if (object === constants.TASKS) {
							props.showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
							// check once
							// refreshTaskPage();
						} else if (object === constants.APPOINTMENTS) {
							//props.showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
							dispatch(showCustomSnackBar("Appointment is rescheduled successfully", styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));

						} else if (object === constants.EMAIL_OBJECT || object === constants.SMS_OBJECT) {
							props.showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);

						}
						props.getTasksRefresh(true);
						refreshColumnView(dataParams);
					}
				});

			} else {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
			}
		}
	}

	function generateMenuItem(record, isEdit, object) {
		let element = [];
		let items = [];
		let taskApp = props.propsTaskApp;
		try {
			let type = record['type'];
			if ((type === constants.EMAIL_OBJECT || type === constants.SMS_OBJECT)) {
				if (getObjectParam(taskApp).moduleId !== constants.TASKS_OVERDUE) {
					items.push('sendnow');
					if (isEdit) {
						items.push('edit');
					}
				}
			} else {
				type = constants.TASKS;
				let actions = ['complete', 'next_day', 'next_week','next_month'];
				items.push(...actions);
			}
			items.push('delete');

			element = items.map((item, i) => (
				<MenuItem
					key={'menu' + i}
					style={{ ...styles.popoverMenuItem }}
					underlineStyle={{ ...{ display: 'none' }, className: 'pomenuitem' }}
					onClick={() => {
						if (item === 'edit') {
							editAction(type, record[record.callFrom === 'taskPortal' ? 'id' : 'parentId'], record['taskName'], record['taskType']);
						} else {
							handelAction(item.replace('_', ''), record, type);
						}
					}}
				>{getLocalizedStrings().label.APPOINTMENTS_TASKS[item.toUpperCase()]}</MenuItem>
			));
		} catch (error) {
			console.error("error in 'ColumnViewTaskApps.js'-->'generateMenuItem()'" + error);
		}
		return element;
	}

	// **
	function generateMenuItemForTasks(record, isEdit, object) {
		let element = [];
		let items = [];
		let taskApp = props.propsTaskApp;
		try {
			let type = record['type'];
			if ((type === constants.EMAIL_OBJECT || type === constants.SMS_OBJECT)) {
				if (getObjectParam(taskApp).moduleId !== constants.TASKS_OVERDUE) {
					items.push('sendnow');
					if (isEdit) {
						items.push('edit');
					}
				}
			} else {
				let actions = ['mini_edit', 'complete', 'next_day', 'next_week', 'next_month'];
				items.push(...actions);
			}

			items.push('delete');

			items.map((item, i) => {
				element.push(
					<MenuItem
						key={'menu' + i}
						style={{ ...styles.popoverMenuItem }}
						onClick={() => {
							if (item === 'edit') {
								editAction(type, record[record.callFrom === 'taskPortal' ? 'id' : 'parentId'], record['taskName'], record['taskType']);
							} else {
								handelAction(item.replace('_', ''), record, type);
							}
						}}
					>{getLocalizedStrings().label.APPOINTMENTS_TASKS[item.toUpperCase()]}</MenuItem>
				);
			});
		} catch (error) {
			console.error("error in 'ColumnViewTaskApps.js'-->'generateMenuItem()'" + error);
		}
		return element;
	}

	function openPopList(object, id, recurId, taskType, event) {
		event.preventDefault();
		setOpenActionList(true);
		setAnchorE2(event.target);
		setActionId(id);
		setActionObject(object);
		setParentRecurId(recurId);
	}

	function closePopList() {
		setOpenActionList(false);
		setAnchorE2(null);
	}

	function editAction(objectName, id, subject, taskType) {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		let listIndex = props.lists.findIndex(f => f.columnName === props.status);
		let cardIndex = props.lists[listIndex].cards.findIndex(f => f.id === props.item.id);
		let tempObject = objectName;
		if (objectName === 'sms' || objectName === 'email') {
			tempObject = constants.TASKS;
		}
		let hasPermission = hasAccessPermission(tempObject, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
		closePopList();
		if (hasPermission && !isInXpress) {

			let data = {
				id: id,
				object: objectName,
				record_id: props.item.id,
				parentObject: props.object,
				listIndex: listIndex,
				cardIndex: cardIndex,
				updateCards: props.updateCards,
				callFrom: 'ColumnViewAddTaskApp',
				parent_object: props.object,
				parent_record_id: props.itemid,
				isDetailView: false,
				isColumnView: true,
			}

			let labelName = subject;
			props.closeParentPopOver();
			if (objectName === constants.TASKS) {
				dispatch(getAppDrawer(true, labelName, constants.MINI_EDIT, data, constants.COLUMN_VIEW));
			} else {
				dispatch(getAppDrawer(true, labelName, constants.NEW, data, constants.COLUMN_VIEW));

			}
		} else {
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
		}

	}

	function taskDetailView(objectName, id, labelName) {
		let linkTo = 'detailview/' + id;
		let linkToUrl = '/' + objectName + "/" + linkTo;
		linkToUrl = makeValidAppUrl(linkToUrl);

		let tab = {
			label: labelName,
			object: objectName,
			type: TYPE_DETAIL_VIEW,
			url: '/' + objectName + "/" + linkTo,
			info: {}
		};
		addTab(tab, true);
	}

	function changeLink(type, id) {
		const idName = type + id;
		if (document.getElementById(idName).style.textDecoration === 'underline') {
			document.getElementById(idName).style.textDecoration = 'none';
		} else {
			document.getElementById(idName).style.textDecoration = 'underline'
		}
	}

	function handelComplete(type, id, taskType) {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		let callFrom = constants.OTHER;
		let objectName = null;
		if (type === 'task' || type === constants.TASKS) {
			objectName = constants.TASKS
		} else if (type === 'appointment' || type === constants.APPOINTMENTS) {
			objectName = constants.APPOINTMENTS
		}
		let hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);

		if (hasPermission && !isInXpress) {
			id = parseInt(id, 10);
			let parentRecordCurrentStatus = null;
			parentRecordCurrentStatus = props.status;
			callFrom = constants.COLUMN_VIEW;
			let listIndex = props.lists.findIndex(f => f.columnName === props.status);
			let cardIndex = props.lists[listIndex].cards.findIndex(f => f.id === props.item.id);

			let data = {
				id: id,
				object: type,
				currentStatus: getStringParam(parentRecordCurrentStatus),
				record_id: props.item.id,
				parentObject: props.object,
				listIndex: listIndex,
				cardIndex: cardIndex,
				updateCards: props.updateCards,
				taskType: taskType !== null && taskType !== undefined && taskType !== "" ? taskType : "Task",
			}
			let labelName = type === constants.APPOINTMENTS || type === 'appointment' ? getLocalizedStrings().label.APPOINTMENTS_TASKS.COMPLETE_THIS_APPOINTMENT : getLocalizedStrings().label.APPOINTMENTS_TASKS.COMPLETE_THIS_TASK;
			props.closeParentPopOver();
			dispatch(getAppDrawer(true, labelName, constants.COMPLETE, data, callFrom));
		} else {
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
		}
	}


	function handelAction(actiontype, record, type) {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		if (!isInXpress) {
			let type = actionObject;
			let id = actionId;
			let parentRecId = parentRecurId;
			closePopList();
			props.closeParentPopOver();
			if (actiontype === 'complete') {
				handelComplete(type, id, record.tType);
			} else if (actiontype === 'miniedit') {
				taskDetailView(constants.TASKS, record.id, record.taskName);
			} else if (actiontype === 'nextday') {
				handleReschedule(id, "nextday", type)
			} else if (actiontype === 'nextweek') {
				handleReschedule(id, "nextweek", type)
			}else if (actiontype === 'nextmonth') {
				handleReschedule(id, "nextmonth", type)
			} else if (actiontype === 'sendnow') {
				handleSendNow(record);
			} else if (actiontype === 'delete') {
				if (parentRecId != null && parentRecId !== '' && parentRecId !== undefined && parentRecId > 0) {
					let data = {};
					if(actionObject == 'appointment'){
						data.object = "appointments";
					}else if(actionObject == 'task'){
						data.object = "tasks";
					}
					else{
						data.object = actionObject;
					}
					dispatch(
						getAppDialog(
							true,
							constants.RECURRING_DELETE_DIALOG,
							getLocalizedStrings().message.REPEAT_DIALOG.DELETE_RECUR_TITLE,
							getRecurringDeleteChoice,
							data
						)
					);
					} else {
					handelDelete(type, id, record);
				}
			}
		} else {
			let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
		}

	}

	function handelDelete(type, id, record) {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		const recordIds = [];
		id = parseInt(id, 10);
		const objectName = type;
		const permissionObject = type;
		let typeMsg = "";
		if (type === constants.TASKS) {
			recordIds.push(id);
			typeMsg = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS;
		} else if (type === constants.APPOINTMENTS) {
			recordIds.push(id);
			typeMsg = getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENTS;
		} else if (type === 'task') {
			recordIds.push(id);
			typeMsg = 'Task';
		} else if (type === 'appointment') {
			recordIds.push(id);
			typeMsg = 'Appointment';
		}

		let msg = "";

		if (id > 0) {
			if (type === 'task') {
				msg = typeMsg + getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;

			} else if (type === 'appointment') {
				msg = typeMsg + getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;

			} else {
				msg = typeMsg.charAt(0).toUpperCase() + typeMsg.slice(1, type.length - 1) + getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;

			}
		}
		let hasPermission = null;
		hasPermission = hasAccessPermission(permissionObject, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_DELETE);
		if (hasPermission && !isInXpress) {
			sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, (e) => handleDeleteOnConfirm(e, recordIds, objectName, type), null);

		} else {
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
		}
	}

	const getRecurringDeleteChoice = (val) => {
		let id = actionId;
		dispatch(
			getAppDialog(
				false,
				constants.RECURRING_DELETE_DIALOG,
				null,
				null,
				null,
				null
			)
		);
		deleteRecord(id, val);
	};
	const deleteRecord = (id, delete_action_type) => {
		let type = actionObject;
		let _id= +id;
	
		let params = {};
		let object = getObjectByType(actionObject);
		params.id = [_id];
		if (delete_action_type !== null) {
			params.delete_action_type = delete_action_type;
		}
	
			let linkObject = getEndPointObject(object);
			let url = linkObject.DELETE;
			var promise = Promise.resolve(HTTPClient.post(url, params));
			promise.then((response) => {
				if (response.status === 0) {
					
				let listIndex = props.lists.findIndex(f => f.columnName === props.status);
				let cardIndex = props.lists[listIndex].cards.findIndex(f => f.id === props.item.id);
					dispatch(
						showCustomSnackBar(
							getLocalizedStrings().message.COMMON.DELETE,
							styles.snackbarBodyStyleSuccess,
							styles.snackBarStyleTop
						)
					);
					let dataParams = {
						record_id: props.item.id,
						object: props.object,
						listIndex: listIndex,
						cardIndex: cardIndex,
						updateCards: props.updateCards
					}
					refreshColumnView(dataParams);
				} else if (response.status === -1 && response.error.message !== "") {
					dispatch(
						showCustomSnackBar(
							response.error.message,
							styles.snackbarBodyStyleError,
							styles.snackBarStyleTop
						)
					);
				}
			});
		
	};


	function handleDeleteOnConfirm(event, recordIds, objectName, type) {
		let param = {
			"id": recordIds
		}
		let msg = type.charAt(0).toUpperCase() + type.slice(1, type.length - 1) + getLocalizedStrings().message.COMMON.DELETE_SUCCESS;
		if(type==='task'){
			msg="Task has been deleted successfully."
		}
		if(type==='appointment'){
			msg="Appointment has been deleted successfully."
		}
		const request = deleteAction(objectName, param);
		request.then((response) => {
			if (response.status === 0) {
				dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
				let listIndex = props.lists.findIndex(f => f.columnName === props.status);
				let cardIndex = props.lists[listIndex].cards.findIndex(f => f.id === props.item.id);
				let dataParams = {
					record_id: props.item.id,
					object: props.object,
					listIndex: listIndex,
					cardIndex: cardIndex,
					updateCards: props.updateCards
				}
				refreshColumnView(dataParams);
			}
		});
	}

	function handleSendNow(record) {
		let id = actionId;
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		try {
			let hasPermission = hasAccessPermission(constants.TASKS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
			if (hasPermission && !isInXpress) {
				let params = {};
				params.id = id;
				params.type = record.type;
				params.taskType = record.taskType;
				params.recordId = props.detailViewRecord.id;

				let url = endPoints.TASK.SEND_NOW;
				const promise = Promise.resolve(HTTPClient.get(url, params));
				promise.then((response) => {
					if (response.status === -1) {
						if (record.type === constants.SMS_OBJECT) {
							props.showCustomSnackBar(getLocalizedStrings().message.SMS_INTEGRATION[response.data.toUpperCase()], styles.snackbarBodyStyleError, styles.snackBarStyleTop);
						}
					} else {
						if (record.type === constants.EMAIL_OBJECT) {
							props.showCustomSnackBar(getLocalizedStrings().message.APPOINTMENTS_TASKS.SEND_EMAIL_PROGRESS_MSG, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
						} else if (record.type === constants.SMS_OBJECT) {
							props.showCustomSnackBar(getLocalizedStrings().message.SMS_INTEGRATION.BATCH_SMS_SENT, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
						}
					}
				});
			} else {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
			}
		} catch (error) {
			console.error("error in 'ColumnViewTaskApps.js'-->'handleSendNow()'" + error);
		}
	}

	function handleReschedule(id, duration, object) {
		let linkLead = 0;
		let linkTableId = 0;
		let LabelName = "";
		if (object === 'task' || object === constants.TASKS) {
			object = constants.TASKS
		} else if (object === 'appointment' || object === constants.APPOINTMENTS) {
			object = constants.APPOINTMENTS
		}
		let hasPermission = null;
		hasPermission = hasAccessPermission(object, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
		if (hasPermission) {
			let recordIdArr = [];
			let msg = (object === constants.TASKS ? getLocalizedStrings().message.APPOINTMENTS_TASKS.RESCHEDULE_TASK_SUCCESS : getLocalizedStrings().message.APPOINTMENTS_TASKS.RESCHEDULE_APPOINTMENT_SUCCESS);
			recordIdArr.push(id);

			let params = {
				"id": recordIdArr,
				"type": duration
			}
			const response = reschedule(object, params);
			response.then((response) => {
				if (response.status === 0) {
					linkLead = props.itemid
					linkTableId = OBJECT_TABLEID_MAP[props.object];
					let listIndex = props.lists.findIndex(f => f.columnName === props.status);
					let cardIndex = props.lists[listIndex].cards.findIndex(f => f.id === props.item.id);
					let data = {
						parent_table_id: getIntParam(linkTableId),
						parent_record_id: linkLead,
						msg: msg,
						record_id: props.item.id,
						parentObject: props.object,
						listIndex: listIndex,
						cardIndex: cardIndex,
						updateCards: props.updateCards,
					}
					setDummyState(!dummyState);
					if (linkLead !== 0) {
						if (duration === 'nextday') {
							LabelName = getLocalizedStrings().label.APPOINTMENTS_TASKS.NEXT_DAY;
						} else if (duration === 'nextweek') {
							LabelName = getLocalizedStrings().label.APPOINTMENTS_TASKS.NEXT_WEEK;
						}else if (duration === 'nextmonth') {
							LabelName = getLocalizedStrings().label.APPOINTMENTS_TASKS.NEXT_MONTH;
						}
						props.closeParentPopOver();
						if(object== constants.TASKS){
							dispatch(showCustomSnackBar("Task is rescheduled successfully", styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
						} else if(object == constants.APPOINTMENTS ){
						dispatch(showCustomSnackBar("Appointment is rescheduled successfully", styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
	
						}
						let dataParams = {
							record_id: props.item.id,
							object: props.object,
							listIndex: listIndex,
							cardIndex: cardIndex,
							updateCards: props.updateCards
						}
                refreshColumnView(dataParams);
						dispatch(getAppDrawer(true, LabelName, constants.SAVE_NOTES, data, constants.COLUMN_VIEW));
					}
				}
			});
		} else {
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
		}
	}

	// **
	function handleOnclickDate(record) {
		let labelName = record['taskName'];
		let hasPermission = null;
		hasPermission = hasAccessPermission(constants.TASKS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
		if (hasPermission) {
			let data = {};
			data.id = record['id']
			data.object = constants.TASKS
			data.labelName = labelName
			props.closeParentPopOver();
			dispatch(getAppDrawer(true, labelName, constants.MINI_EDIT, data, constants.OTHER));
		} else {
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
		}

	}

	function hasPermissionError() {
		sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
	}

	function detailViewTaskAppData(record) {

		let object = props.object;
		let component = null;
		let iconTitle = null;
		let iconName = null;
		let type = record['type'];
		let iconText = "";
		let iconStyle = { marginTop: '5px' };
		let taskType = null;
		let HHFormat = new RegExp("HH");
		let datePickerOptions = {
			enableTime: true,
			dateFormat: dateTimeFormatFlatPicker[props.app.me.date_format],
			minuteIncrement: 1,
			time_24hr: HHFormat.test(props.app.me.date_format)
		};


		let dueByDateObj = moment(record['hdndateField']);
		dueByDateObj = dueByDateObj.format(dateTimeFormat[props.app.me.date_format]);
		let imageColor = colourChangeTaskApp(record['hdndateField']);
		let isEdit = true;
		if ((taskType === 'DirectMail' && false)) {
			isEdit = false;
		}
		if (type === 'task') {
			iconName = 'fa fa-clock-o';
			iconTitle = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS;

		} else if (type === 'appointment') {
			iconName = 'fa fa-calendar';
			iconTitle = getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENTS;
		}
		let hasPermission = hasAccessPermission(constants.APPOINTMENTS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
		let cursorPointer = { cursor: 'pointer' };
		component =
			<div key={'array-wrap' + record['id'] + props.itemid} style={{ paddingTop: '5px' }}>
				<div className='clearfix' key={'array-clear' + record['id'] + props.itemid} style={{ lineHeight: '22px', height: '24px' ,fontSize: 14, maxWidth:'210px'}}>
					<div style={{ float: 'left' }}>
						{
							(type === 'task') &&
							(
								<div onClick={() => handleOnclickDate(record)} style={{cursorPointer, display:'flex'}}>
									<span style={{ fontSize: '14px' }} title={iconTitle} className={imageColor}>
										<i className={iconName} aria-hidden="true" style={iconStyle}>{iconText}</i>
                					</span>
									<div className={" dpvalue " + colourChangeTaskApp(record['hdndateField'])} style={{marginLeft:'-12px'}}>
										<span>{dueByDateObj}</span>
									</div>
									
								</div>
							)
						}
						{
							(type !== 'task') &&
							(
								<div>
									<PopOver
										id={type + record['id']}
										key={type + record['id']}
										name={type + record['id']}
										btnType={'dateTime'}
										btnViewType={'text'}
										btnLabelClass={" dpvalue " + colourChangeTaskApp(record['hdndateField'])}
										isShowLabelIcon={true}
										btnIconSpanStyle={{ fontSize: '14px' }}
										btnIconTitle={iconTitle}
										btnIconSpanClass={imageColor}
										btnIconClass={iconName}
										btnIconStyle={iconStyle}
										btnIconText={iconText}
										containerStyle={{}}
										hasPermission={hasPermission}
										hasPermissionError={hasPermissionError}
										buttonLabel={dueByDateObj}
										btnLabelStyle={{fontSize: '14px',marginTop:'-20px',marginLeft:3}}
										onClose={(date) => handleFlatPickerDateChange(record, dueByDateObj, date)}
										value={dueByDateObj}
										options={datePickerOptions}
										disable={false}
									/>
								</div>
							)
						}
					</div>

					<div key={'popover-div' + record['id'] + props.itemid} style={{ color: '#717171', float: 'right', paddingTop: '2px' }}>

						<Icon onClick={(e) => openPopList(type, record['id'], record['parentRecurId'], record['taskType'], e)} style={{ fontSize: '17px', color: 'black', cursor: 'pointer', marginTop: "1px" }}>more_vert</Icon>
						<Menu
							open={openActionList && actionId === record['id'] && actionObject === type}
							anchorEl={anchorE2}
							anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
							transformOrigin={{ horizontal: 'left', vertical: 'top' }}
							onClose={() => closePopList()}
							keepMounted
						>
							{type === 'task' ? generateMenuItemForTasks(record, isEdit, object) : generateMenuItem(record, isEdit, object)}
						</Menu>
					</div>
				</div>
				<div key={'task-data' + record['id'] + props.itemid} style={{ position: "relative", top: "-2px" }}> {/*top: "-9px"*/}
					<div
						key={'task-name' + record['id'] + props.itemid}
						style={{...styles.detailViewTaskAppNameStyle,fontSize: 14}}
						title={record['taskName']}>
						{(!isEdit) ?
							<span id={type + record['id']} key={type + record['id']}>{record['taskName']}</span>
							:
							<span
								id={type + record['id']}
								key={type + record['id']}
								onClick={() => editAction(getObjectByType(type), record['id'], record['taskName'], record['taskType'])}
								style={{ cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '240px', fontSize: 14}}
								onMouseOver={() => changeLink(type, record['id'])}
								onMouseLeave={() => changeLink(type, record['id'])}
							>{record['taskName']}</span>
						}
					</div>
					<div
						style={{ ...styles.detailViewTaskAppNameStyle, fontSize: 14 }}
						key={'owner' + record['id'] + props.itemid}
						title={record['t_owner']}
					>
						<span>{record['t_owner']}</span>
					</div>
				</div>
			</div>
		return component
	}


	// **
	function generateDetailViewTaskAppData() {
		let data = {};
		if (props.record.hasOwnProperty('task_appointment')) {
			data = props.record['task_appointment'];
		}

		let taskAppArr = [];
		let moduleResultInfo = null;
		let fieldList = [];
		let recordList = [];
		let moduleId = null;
		let resultInfo = null;
		let detailViewTaskAppDetailStyle = styles.detailViewTaskAppDetailStyle;
		let detailViewTaskAppNameStyle = styles.detailViewTaskAppNameStyle;
		let isDetailView = true;

		if (isDetailView) {
			detailViewTaskAppDetailStyle.color = '#b5b5b5';
		} else if (!isDetailView) {
			detailViewTaskAppDetailStyle.color = '#717171';
			detailViewTaskAppNameStyle.width = '320px';
		}
		moduleResultInfo = data;
		if (moduleResultInfo.data !== null && moduleResultInfo.data !== undefined) {
			for (moduleId in moduleResultInfo.data) {
				resultInfo = moduleResultInfo.data[moduleId];
				if (resultInfo && resultInfo.length > 0) {
					fieldList = moduleResultInfo.fieldList;
					recordList = resultInfo;
					if (fieldList && fieldList.length > 0 && recordList && recordList.length > 0) {
						let record;
						for (let i = 0; fieldList && i < recordList.length; i++) {
							record = recordList[i];
							if (record) {
								taskAppArr.push(
									<div key={record['type']}>{detailViewTaskAppData(record)}</div>);
							}
						}
					}
				}
				resultInfo = null;
				fieldList = null;
				recordList = null;
			}
		}

		if (taskAppArr.length === 0) {
			taskAppArr.push(
				<div key={"default-item"} style={{
					paddingTop: '10px', color: '#717171', textAlign: 'left'
				}}>
					<div style={{
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
						width: '180px'
					}} title={getLocalizedStrings().message.APPOINTMENTS_TASKS.NO_TASK_APPOINTMENT_FOUND}>{getLocalizedStrings().message.APPOINTMENTS_TASKS.NO_TASK_APPOINTMENT_FOUND}</div>
				</div>
			)
		}
		return taskAppArr;
	}

	// **
	function addAction(value) {
		if (value === "Appointments") {
			add(constants.APPOINTMENTS);
		} else if (value === "Tasks") {
			add(constants.TASKS);
		}
	}

	function add(objectName) {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		let hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_CREATE);
		if (hasPermission && !isInXpress) {
			let listIndex = props.lists.findIndex(f => f.columnName === props.status);
			let cardIndex = props.lists[listIndex].cards.findIndex(f => f.id === props.item.id);
			let data = {
				id: 0,
				object: objectName,
				record_id: props.item.id,
				parentObject: props.object,
				listIndex: listIndex,
				cardIndex: cardIndex,
				updateCards: props.updateCards,
				callFrom: 'ColumnViewAddTaskApp',
				columnViewTaskApp:true
			}

			data['parent_object'] = props.object;
			data['parent_record_id'] = props.itemid;
			data['isDetailView'] = false;
			data['isColumnView'] = true;

			let objName = "";
			let labelName = "";
			if (objectName === constants.APPOINTMENTS) {
				objName = getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENT;
			} else if (objectName === constants.TASKS) {
				objName = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK;
			} else {
				objName = objectName.charAt(0).toUpperCase() + objectName.slice(1);
			}
			if (objectName === constants.TASKS || objectName === constants.APPOINTMENTS) {
				labelName = getLocalizedStrings().label.COMMON.ADD_NEW_APPOINTMENT + ' ' + objName;
			} else {
				labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + objName;
			}
			props.closeParentPopOver();
			dispatch(getAppDrawer(true, labelName, constants.NEW, data, constants.COLUMN_VIEW));
		} else {
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
		}
	}

	// **
	function getMenuItems() {
		return [
			{ value: 'Tasks', label: getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS },
			{ value: 'Appointments', label: getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENTS }
		];
	}

	// render()
	let selectMenuItem = getMenuItems();
	const taskAppLabel = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS + ' ' + getLocalizedStrings().label.COMMON.AND + ' ' + getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENTS;
	return (
		<div key={'task-wrap' + props.itemid} style={{ paddingTop: '19px', minHeight: '150px', padding: '22px' }}>
			<div style={{ ...styles.taskAppHeadingStyle, paddingBottom: '5px' }} key={'task-label-div' + props.itemid}>
				<span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: '230px' }} key={'task-label-span' + props.itemid}>
					{taskAppLabel}
				</span>
			</div>

			<div key={'button-div' + props.itemid}>
				<PopOver
					id={"PopOver"}
					key={"PopOver"}
					buttonEndIcon={"keyboard_arrow_down"}
					buttonStyle={{ ...styles.secondaryButton, marginLeft: '10px' }}
					labelPosition={"before"}
					buttonLabel={getLocalizedStrings().label.COMMON.ADD}
					options={selectMenuItem}
					onclickAction={addAction}
					buttonLineOrigin={[28, 100]}
				/>
			</div>

			<div key={'task-data' + props.itemid} style={{ lineHeight: '35px' }}>
				{generateDetailViewTaskAppData()}
			</div>

		</div>
	);
}


export default ColumnViewTaskApps;