import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { styles} from '../../../services/constants/styles';
import store from '../../../../src/services/store';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { isValidParam, getStringParam, getIntParam  } from '../../../services/helper/parameterVerifier';
import { getActiveTab } from '../../../services/helper/sfTabManager';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { HighchartsReact } from 'highcharts-react-official';
import Highcharts from 'highcharts'
import funnel from "highcharts/modules/funnel.js";
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFooter from '@mui/material/TableFooter';
import { drillDownMatrixReport } from '../../../services/actions/reportActions';
import { setReportFilterData } from '../../../services/actions/reportActions';

funnel(Highcharts);
NoDataToDisplay(Highcharts);

Highcharts.setOptions({
    lang: {
        thousandsSep: ',',
        noData: "No data to display"
    }
});

const ascSortIcon = <i className="fa fa-sort-amount-asc" style={{ fontSize: '15px', color: '#717171', paddingLeft: '10px' }} />;

const descSortIcon = <i className="fa fa-sort-amount-desc" style={{ fontSize: '15px', color: '#717171', paddingLeft: '10px' }} />;

const matrixReportInitialData = {
    "reportName": "",
    "column": [],
    "data": [],
    "footer": []
}

const chartInitialData = {
    "chart": {
        "type": "column"
    },
    "xAxis": {
        "categories": []//["Jan", "Feb"]
    },
    "title": {
        "text": ""//"Chart Tile"
    },
    "yAxis": {
        "allowDecimals": true,
        "title": {
            "text": ""//"Y axis Title"
        }
    },
    "series": [
        {
            "name": "value", // Show in X-axis below
            "data": [],// [{name: 'Jan', y: 56.33}]
            dataLabels: {
                enabled: true
            },
            cursor: 'pointer',
            showInLegend: true,
            point: {} // Drill downfunction pushHare 
        },

    ],
    "subtitle": {
        "text": ""//"Click the button to download as PNG, JPEG, SVG or PDF"
    },
    "credits": {
        "enabled": false
    },
    exporting: {
        //  enabled: true,
        buttons: {
            contextButton: {
                //  enabled: true,
                _titleKey: 'mytoolTip',
            }
        }
        //  filename: 'my Report'
    },
    lang: {
        mytoolTip: 'Print/Download Report'
    },
}

const optionvalue = {
    td: 'Today',
    yd: 'Yesterday',
    tw: 'This Week',
    tm: 'This Month',
    tq: 'This Quarter',
    ty: 'This Year',
    lw: 'Last Week',
    lm: 'Last Month',
    lq: 'Last Quarter',
    ly: 'Last Year',
    cust: 'Custom'

};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#F5F5F5',
        color: '#000000'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: '#000000'
    },
    [`&.${tableCellClasses.footer}`]: {
        backgroundColor: '#F5F5F5',
        color: '#000000',
        fontSize: 14,
      },

  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#e8eff7',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


class MatrixReportTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "totalValue": 0,
            "verticalSumArray": [],
        }
    }

    componentDidMount() {
        let reportmatrixData = {};
        reportmatrixData['rows'] = this.props.config?.data;
        reportmatrixData['columns'] = this.props.config?.column;
        reportmatrixData['footer'] = this.props.config?.footer;
        this.props.setReportFilterData(reportmatrixData);
    }

    sortTableRows = (name, sort_order, isRender) => {
        this.props.config.data = this.props.config.data.sort(function (a, b) {
            let x = a[name];
            let y = b[name]
            if (typeof a[name] === 'number') {

            } else if (typeof x === "string" && typeof y === "string") {
                x = x.toLowerCase();
                y = y.toLowerCase();
            }

            if (sort_order === 'asc') {
                return (x > y) ? 1 : ((y > x) ? -1 : 0);
            } else {
                return (y > x) ? 1 : ((x > y) ? -1 : 0);
            }
        });

        if (isRender === true) {

            let _state = store.getState();
            let sortConfList = _state.report.reportSortConfList;
            let sortConfObject = {};
            for (let i = 0; i < sortConfList.length; i++) {
                if (sortConfList[i].report_name === this.props.config.reportName) {
                    sortConfObject = sortConfList[i];
                }
            }
            sortConfObject.sort_field_name = name;
            sortConfObject.sort_order = sort_order;
            this.setState({});
        }
    }

    generateHeaders() {

        let reportName = this.props.config.reportName;
        let _state = store.getState();
        let sortConfList = _state.report.reportSortConfList;
        let sortConfObject = null;
        let containColumn = false;

        for (let i = 0; i < sortConfList.length; i++) {
            if (sortConfList[i].report_name === reportName) {
                sortConfObject = sortConfList[i];
            }
        }

        let cells = [];
        let tHeadStyle ={width:'50%', textAlign: "left"};
        if (sortConfObject === null) {
            cells = this.props.config.column.map((columnObject, index) => {
                return (
                    <StyledTableCell key={index}> 
                        {columnObject.label}  
                    </StyledTableCell>
                );
            });
        } else {
            cells = this.props.config.column.map((columnObject, index) => {
                if (sortConfObject.sort_field_name === columnObject.name) {
                    containColumn = true;
                    if (sortConfObject.sort_order === 'asc') {
                        return <StyledTableCell key={index} onClick={() => { this.sortTableRows(columnObject.name, 'desc', true); }}  > {columnObject.label}  {ascSortIcon} </StyledTableCell>;
                    } else {
                        return <StyledTableCell key={index} onClick={() => { this.sortTableRows(columnObject.name, 'asc', true); }} > {columnObject.label} {descSortIcon} </StyledTableCell>;
                    }

                } else {
                    return <StyledTableCell key={index} onClick={() => { this.sortTableRows(columnObject.name, 'asc', true); }}> {columnObject.label}  </StyledTableCell>;
                }
            });

            if (containColumn === true) {
                this.sortTableRows(sortConfObject.sort_field_name, sortConfObject.sort_order, false);
            }
        }
        return (
            <TableRow> 
                {cells} 
            </TableRow>
        );
    }

    generateRows() {
 
        let total = 0;
        if (this.props.config.data !== undefined && this.props.config.data.length !== 0) {
            let verticalSumArray = new Array(this.props.config.column.length);
            return this.props.config.data.map((rowObject, rowIndex) => {
                let cells = this.props.config.column.map((columnObject, colIndex) => {
                    // For Total
                   if (columnObject.isToatalField && typeof rowObject[columnObject.name] === "number") {
                        total = total + rowObject[columnObject.name];
                        verticalSumArray[colIndex] = (verticalSumArray[colIndex] === undefined ? 0 : verticalSumArray[colIndex]) + rowObject[columnObject.name];
                    }
                    if (columnObject.isDetailsLink !== undefined && columnObject.isDetailsLink === true) {
                        return (
                            <StyledTableCell key={rowIndex + ',' + colIndex}> 
                                <a href="javascript:void(0)" 
                                    onClick={() => {
                                        drillDownMatrixReport(this.props.config.reportName, "body", this.props.config.column, rowObject, this.props.config.footer, columnObject.name, this.props.rawData, rowObject[columnObject.name]);
                                    }} 
                                > 
                                    {rowObject[columnObject.name]}
                                </a> 
                            </StyledTableCell>
                        );

                    } else {
                        return (
                            <StyledTableCell key={rowIndex + ',' + colIndex}> 
                                {rowObject[columnObject.name]} 
                            </StyledTableCell>
                        )

                    }
                })

                this.state.totalValue = total;
                this.state.verticalSumArray = verticalSumArray;
                return (
                    <StyledTableRow key={rowIndex}> 
                        {cells}  
                    </StyledTableRow>
                );
            });
        } else {
            return (
                <StyledTableRow> 
                    <StyledTableCell colSpan={this.props.config.column.length + 1}> 
                        {getLocalizedStrings().message.COMMON.NO_DATA} 
                    </StyledTableCell>  
                </StyledTableRow>
            );
        }

    }

    generateFooter() {
        if (this.props.config.data !== undefined && this.props.config.data.length !== 0) {
            let cells = this.props.config.footer.map((columnFooterObject, index) => {
                if (columnFooterObject.isVisible) {
                    if (!columnFooterObject.showTotal) {
                        return <StyledTableCell style={{ borderTop: "3px solid black" }} colSpan={columnFooterObject.colspan} key={index}> {columnFooterObject.label} </StyledTableCell>;
                    } else {
                        if (columnFooterObject.isDetailsLink === true) {
                            return (
                                <StyledTableCell 
                                    style={{ borderTop: "3px solid black" }}
                                    key={index}> 
                                    <a 
                                        href="javascript:void(0)" 
                                        onClick={() => {
                                            drillDownMatrixReport(this.props.config.reportName, 
                                            "footer", 
                                            this.props.config.column, 
                                            {}, 
                                            this.props.config.footer, 
                                            columnFooterObject.name, 
                                            this.props.rawData, 
                                            this.state.verticalSumArray[index]) 
                                        }}
                                    >
                                        {this.state.verticalSumArray[index]}
                                    </a>
                                </StyledTableCell>
                            ) 


                            // return <td colSpan={columnFooterObject.colspan} key={index}> <a  > &nbsp;{columnFooterObject.label}  </a>  </td>;
                        }
                        else {
                            return (
                                <StyledTableCell 
                                    style={{ borderTop: "3px solid black" }} 
                                    key={index}
                                > 
                                    {this.state.verticalSumArray[index]} 
                                </StyledTableCell>
                            );
                        }
                    }
                }

            })

            return (
                <StyledTableRow> 
                    {cells}  
                </StyledTableRow>
            );

        } else {
            return (
                <StyledTableRow> 
                    <StyledTableCell 
                        style={{ borderTop: "3px solid black" }} 
                        colSpan={this.props.config.column.length + 1}
                    > 
                        {getLocalizedStrings().message.COMMON.NO_DATA}  
                    </StyledTableCell>  
                </StyledTableRow>
            );
        }

    }

    downloadExcel = (config) => {
        if (this.props.checkDataForExport(config)) {
            downloadExcel(config, this.state.verticalSumArray);
        }
    }

    downloadReportPdf = (config) => {
        let _state = store.getState();
        let tab = getActiveTab();
        let reportParams = tab.object === constants.SALES_OBJECT ? tab.info.report_params : _state.report.recentReportParams;
        let dueBy = reportParams.due_by;
        let dueByName = optionvalue[dueBy];
        let user = reportParams.user_names;
        let table_id = 0;
        let url = '';
        if (this.props.checkDataForExport(config)) {
            if (config.reportName === 'Matrix By Status' || config.reportName === 'Conversion Report' || config.reportName === 'Activity Matrix') {

                if (reportParams.object_name === "opportunities") {
                    table_id = OBJECT_TABLEID_MAP[reportParams.object_name];
                } else if (reportParams.object_name === "accounts") {
                    table_id = OBJECT_TABLEID_MAP[reportParams.object_name];
                }
                let documentName = "";
                if (config.reportName === 'Matrix By Status'){
                    documentName = "Conversion Matrix " + '[' + new Date().getTime() + '].pdf';
                } else {
                    documentName = config.reportName + '[' + new Date().getTime() + '].pdf';
                }
                let inputParam = {
                    'dueby': dueBy,
                    'start_time': getStringParam(reportParams.from_date),
                    'end_time': getStringParam(reportParams.to_date),
                    'owner': user
                }

                if (config.reportName === 'Activity Matrix') {
                    url = endPoints.SALES_REPORT.DOWNLOAD_ACTIVITY_PDF;
                    inputParam.activityTypes = reportParams.activityTypes
                } else if (config.reportName === 'Matrix By Status') {
                    url = endPoints.SALES_REPORT.DOWNLOAD_CONVERTION_PDF;
                    inputParam['table_id'] = table_id;
                    inputParam['conversionType'] = 0;
                    inputParam['workflow_id'] = reportParams.workflow_id;
                } else if (config.reportName === 'Conversion Report') {
                    url = endPoints.SALES_REPORT.DOWNLOAD_CONVERTION_PDF;
                    inputParam['table_id'] = table_id;
                    inputParam['conversionType'] = 1;
                }

                let selectedFranchise = getIntParam(reportParams.selectedFranchise);
                let selectedFrnProjectId = getIntParam(reportParams.selectedFrnProjectId);
                if (selectedFranchise > 0 && selectedFrnProjectId > 0) {
                    inputParam.is_inxpress = true;
                    inputParam.inxpress_project_id = selectedFrnProjectId;
                }

                var formData = new FormData();
                formData.append('input_param', JSON.stringify(inputParam));

                HTTPClient.postFormDataDownloadFile(url, formData, documentName);
                return;
            }
        }
    }

    render() {
        let headerComponents = this.generateHeaders();
        let rowComponents = this.generateRows();
        let footerComponent = this.generateFooter();
        let matrixReportName = this.props.config.reportName;
        let matrixReportNameMultilingual = {
            'Activity Matrix': getLocalizedStrings().label.REPORT.ACTIVITY_MATRIX,
            'Matrix By Status': getLocalizedStrings().label.REPORT.MATRIX_BY_STATUS,
            'Matrix By Source': getLocalizedStrings().label.REPORT.MATRIX_BY_SOURCE,
            //Service report
            'Support Matrix': getLocalizedStrings().label.REPORT.SUPPORT_MATRIX,
            'Customers by Cases': getLocalizedStrings().label.REPORT.CUSTOMER_BY_CASES,
            'Project Matrix': getLocalizedStrings().label.REPORT.PROJECT_MATRIX,
            'Issue Matrix': getLocalizedStrings().label.REPORT.ISSUE_MATRIX,
        }
        matrixReportName = matrixReportNameMultilingual.hasOwnProperty(matrixReportName) ? matrixReportNameMultilingual[matrixReportName] : matrixReportName;
        return (

            <div className="row" >
                <div className="col-md-12" style={{ width: '100%' }}>
                    <div style={{ padding: '25px 10px 12px 0px', fontWeight: 'bold', cursor: 'move' }} >
                        <span className="text"><h3 style={{ display: 'inline-block' }}>{/*this.props.config.reportName*/}{matrixReportName}</h3>
                            &nbsp;
                            &nbsp;
                            <Button
                                startIcon={<i className="far fa-file-excel" style={{ fontSize: '20px', color: 'green' }} />}
                                onClick={() => { this.downloadExcel(this.props.config) }}
                                style={{
                                    ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                                    color: '#717171',
                                    border: '0px',
                                    minWidth: '30px',
                                    padding: '0px'
                                }}
                                // title="Excel"
                                title={getLocalizedStrings().label.REPORT.EXCEL}
                            ></Button>

                            {(this.props.config.reportName === 'Matrix By Status' || this.props.config.reportName === 'Conversion Report' || this.props.config.reportName === 'Activity Matrix') &&

                                <Button
                                startIcon={<i className="fa fa-file-pdf-o" style={{ fontSize: '20px', color: 'red' }} />}
                                    onClick={() => {
                                      
                                        // downloadPdf(this.props.config);
                                        this.downloadReportPdf(this.props.config)
                                    }
                                    }
                                    style={{
                                        ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                                        color: '#717171',
                                        border: '0px',
                                        minWidth: '30px',
                                        padding: '0px'
                                    }}
                                    title="PDF"
                                ></Button>
                            }
                        </span>
                    </div>
                </div>
                <div className="col-md-12" style={{ width: '100%' }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                {headerComponents}
                            </TableHead>
                            <TableBody>
                                {rowComponents}
                            </TableBody>
                            <TableFooter >
                                {footerComponent}
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        );
    }
}

const ReactChart = ({config}) => {
    const chartComponentRef = useRef(null);
    
    let options = config;
    let titleText = config.title.text;
    let titleTextMultiLingual= {
        'Activities By Type': getLocalizedStrings().label.REPORT.ACTIVITIES_BY_TYPE,
        'Activities By Sales Rep': getLocalizedStrings().label.REPORT.ACTIVITIES_BY_SALES_REP,
        'All Conversions By Status': getLocalizedStrings().label.REPORT.ALL_CONVERSIONS_BY_STATUS,
        'Customers By Source': getLocalizedStrings().label.REPORT.CUSTOMERS_BY_SOURCE,
        'Pipeline Report': getLocalizedStrings().label.REPORT['Pipeline Report'],
        'Pipeline by Sales Rep': getLocalizedStrings().label.REPORT.DEALS_BY_SALES_PER,
        'Conversion By Sales Rep': getLocalizedStrings().label.REPORT.CONVERSION_BY_SALES_REP,
        'Conversion Matrix': getLocalizedStrings().label.REPORT.CONVERSION_MATRIX,
        'Deals by Stages': getLocalizedStrings().label.REPORT.DEALS_BY_STAGES,
        'Total Calls By Sales Rep': getLocalizedStrings().label.REPORT.TOTAL_CALLS_BY_SALES_REP,
        'Total Call Duration By Sales Rep': getLocalizedStrings().label.REPORT.TOTAL_CALL_DURATION_BY_SALES_REP,
        //Service reports
        'Cases by Status': getLocalizedStrings().label.REPORT.CASES_BY_STATUS,
        'Cases by Priority': getLocalizedStrings().label.REPORT.CASES_BY_PRIORITY,
        'Projects by Status': getLocalizedStrings().label.REPORT.PROJECTS_BY_STATUS,
        'Projects by Type': getLocalizedStrings().label.REPORT.PROJECTS_BY_TYPE,
        'Issues by Status': getLocalizedStrings().label.REPORT.ISSUES_BY_STATUS,
        'Issues by Priority': getLocalizedStrings().label.REPORT.ISSUES_BY_PRIORITY,
        'Pipeline by Sales Rep': getLocalizedStrings().label.REPORT.DEALS_BY_SALES_REP,
        'Conversion By Sales Rep' :getLocalizedStrings().label.REPORT.CONVERSION_BY_SALES_REP,
    }
    titleText = titleTextMultiLingual.hasOwnProperty(titleText)?titleTextMultiLingual[titleText]:titleText;
    options.title.text = titleText;

    return (
        <div className="row">
            <div className="col-md-12">
                <div style={{ padding: '0px', fontWeight: 'bold', cursor: 'move' }} >
                    <span className="text"><h3>{titleText}</h3></span>
                </div>
            </div>

            <div className="col-md-12">
                <HighchartsReact options={options}  highcharts={Highcharts}  ref={chartComponentRef} />
            </div>
        </div>
    );
}

const excelGenerateBeforeHeaders = (config) => {
    let cells = '';
    let _state = store.getState();
    let tab = getActiveTab();
    let reportParams = tab.object === constants.SALES_OBJECT ? tab.info.report_params : _state.report.recentReportParams;
    let user = reportParams.user_names;
    let dueBy = reportParams.due_by;
    let dueByName = optionvalue[dueBy];

    if (_state.salesReport && _state.salesReport.reportData && _state.salesReport.reportData.recent_report_params) {
        dueBy = _state.salesReport.reportData.recent_report_params.due_by;
        user = _state.salesReport.reportData.recent_report_params.user_names;
    }
    cells = cells + '<tr><td  colspan="' + (config.column.length) + '"> ' + config.reportName + ' </td></tr>';
    cells = cells + '<tr><td> Sales Rep :</td>  <td> ' + user + ' </td></tr>';
    cells = cells + '<tr><td> Date :</td>  <td> ' + dueByName + ' </td></tr>';
    return cells;
}


const excelGenerateHeaders = (config) => {
    let cells = '';
    config.column.map((columnObject, index) => {
        cells = cells + '<th  bgcolor="6A8CCB"> ' + columnObject.label + ' </th>';
    })
    return ('<tr>' + cells + '</tr>');
}


const excelGenerateRows = (config) => {
    let rowData = '';
    if (config.data !== undefined && config.data.length !== 0) {
        let cells = '';
        config.data.map((rowObject, rowIndex) => {
            let innerCells = '';
            config.column.map((columnObject, colIndex) => {
                innerCells = innerCells + '<td >' + rowObject[columnObject.name] + ' </td>';
            })
            if (rowIndex % 2 === 0) {
                cells = cells + ('<tr>' + innerCells + ' </tr>');
            } else {
                cells = cells + ('<tr  bgcolor="Lavender">' + innerCells + ' </tr>');
            }

        });
        rowData = cells;
    } else {
        rowData = ('<tr> <td colspan="' + (config.column.length + 1) + '> No data Found  </td>  </tr>');
    }

    return rowData;

}


const excelGenerateFooter = (config, verticalSumArray) => {

    if (config.data !== undefined) {
        let cells = '';
        config.footer.map((columnFooterObject, index) => {
            if (columnFooterObject.isVisible) {
                if (!columnFooterObject.showTotal) {
                    cells = cells + '<td colSpan="' + columnFooterObject.colspan + '"> ' + columnFooterObject.label + ' </td>';
                } else {
                    cells = cells + '<td > ' + verticalSumArray[index] + ' </td>';
                }
            }
        });
        return ('<tr bgcolor="gray">' + cells + '  </tr>');
    }
}

const downloadExcel = (config, verticalSumArray) => {
    let beforeHeaderComponent = excelGenerateBeforeHeaders(config);
    let headerComponents = excelGenerateHeaders(config);
    let rowComponents = excelGenerateRows(config);
    let footerComponent = excelGenerateFooter(config, verticalSumArray);
    let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead> <tbody>' + rowComponents + '  </tbody>  <foot style="border-top: 2px solid black" > ' + footerComponent + ' </foot>   </table>';
    let htmlData = htmlTable;//'<html>   <head><title> 1 </title></head>  <body>' + htmlTable + ' </body></html>'
    if (isValidParam(htmlData) && htmlData !== '') {
        htmlData = htmlData.replace("%22", "\"");
        var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
        var downloadUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = downloadUrl;
        a.download = 'ResultSet_' + new Date().getTime() + '.xls';
        document.body.appendChild(a);
        a.click();
        a.remove();
    }
}


const mapStateToProps = (state) => {
    return {
        
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setReportFilterData:(reportData) =>{
            dispatch(setReportFilterData(reportData));
        }
    }
}
MatrixReportTable = connect(mapStateToProps, mapDispatchToProps)(MatrixReportTable);


export {
    MatrixReportTable,
    ReactChart,
    matrixReportInitialData,
    chartInitialData,
    optionvalue,
}

