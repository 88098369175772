import React, { useCallback, useEffect, useRef, useState } from 'react'
import { InputLabel, MenuItem, Select } from '@mui/material';
import Button from '@mui/material/Button';
import { constants, OBJECT_TABLEID_MAP, TABLEID_OBJECT_MAP } from '../../../services/constants/constants';
import { setHeader } from '../../../services/actions/headerActions';
import { useDispatch, useSelector } from 'react-redux';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { getMyGroups, setGroups } from '../../../services/actions/filterMenuAction';
import {
    getArrayParam,
    getBooleanParam,
    getIntParam,
    getStringParam,
    isEmail,
    isFloat,
    isInteger,
    isUrl,
    isValidParam
} from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import { getWorkflowList, getWorkflowStageById } from '../../../services/actions/importAction';
import { alert, confirm } from './sfDialogs';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { phoneFormat, sortArrayObjectByProperty } from '../../../services/helper/utils';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import * as SFFormActions from '../../../services/actions/sfFormActions';
import {
    addSourceOnRedux,
    checkDuplicateSourceName,
    saveListValues,
    saveSourceName
} from '../../../services/actions/sfFormActions';
import { hasAccessPermission } from '../../../services/helper/common';


const LeadScoreSetup = ({ object, scroll }) => {
    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const groups = useSelector((state) => state.filters ? state.filters.groups : []);
    const groupMounted = useSelector((state) => state.filters ? state.filters.groupMounted : []);
    const objectList = useSelector((state) => state.app ? state.app.objectList : []);
    const sfFormFields = useSelector((state) => state.sfForm ? state.sfForm.data : []);

    const [t_status, setStatus] = useState('Active');
    const [isAddClick, setIsAddClick] = useState(false);
    const [objectArray, setObjectArray] = useState([]);
    const [editArray, setEditArray] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [leadScoreId, setLeadScoreId] = useState(0);
    const [mounted, setMounted] = useState(false);
    const [contactPerfectScore, setContactPerfectScore] = useState(null);
    const [accPerfectScore, setAccPerfectScore] = useState(null);
    const [isGroupExist, setIsGroupExist] = useState(true);
    const [isListValueExist, setIsListValueExist] = useState(true);
    const [isFieldValueExist, setIsFieldValueExist] = useState(true);

    const fetchGroupData = useCallback(() => {
        let promise = Promise.resolve(getMyGroups(constants.ACCOUNTS_OBJECT));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    dispatch(setGroups(response));
                }
            });
        }
    }, [dispatch])

    useEffect(() => {
        if (!groupMounted) {
            fetchGroupData()
        }
    }, [dispatch, fetchGroupData, groupMounted, sfFormFields]);

    useEffect(() => {
        if (groupMounted) {
            getActions(groups, sfFormFields);
        }
    }, [groupMounted, groups, sfFormFields]);

    useEffect(() => {
        dispatch(setHeader(object, getLocalizedStrings().label.MARKETING_SETTING.LEAD_SCORE, null, false, true));
    }, [dispatch, object]);


    const getActions = (groups, sfFormFields) => {
        try {
            const promise = Promise.resolve(HTTPClient.get(endPoints.LEAD_SCORES.ACTION_LIST_VIEW, null));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        const { leadScoreList, status, leadScoreId, ContactPerfectScore, AccPerfectScore } = response;
                        let responseArray = getArrayParam(leadScoreList);
                        if (responseArray.length > 0) {
                            responseArray.forEach((item, i) => {
                                item.key = i + 1;
                                let details = item.details[0];
                                let detailsArr = [];
                                if (details.t_expires_by === 0 || details.t_expires_by === null) {
                                    // details.t_expires_by = "";
                                    delete details.t_expires_by;
                                }
                                if (details.t_object_id > 0) {
                                    details.t_object_name = TABLEID_OBJECT_MAP[details.t_object_id];
                                    if (details.t_field_id > 0) {
                                        let fields = sfFormFields[details.t_object_name].fields;
                                        fields = fields.filter(f => {
                                            return (f.t_tablefldid === details.t_field_id)
                                        });
                                        if (fields.length > 0) {
                                            let fieldObject = fields[0];
                                            if ((fieldObject.field_type_id === constants.LIST_FORM_FIELD ||
                                                fieldObject.field_type_id === constants.LIST_FORM_FIELD_INTEGER_TYPE) && fieldObject.name !== "state") {
                                                if (fieldObject.field_type_id > 0) {
                                                    let values = getArrayParam(fieldObject.list_values);
                                                    let listItem = values.filter(f => {
                                                        return (f.value === details.t_value)
                                                    });
                                                    if (listItem.length > 0) {
                                                        details.t_value = listItem[0].value;
                                                    } else {
                                                        details.isListVauleExist = false;
                                                        setIsListValueExist(false);
                                                        details.t_value = '';
                                                    }

                                                }
                                            }
                                        } else {
                                            details.isFieldValuExist = false;
                                            setIsFieldValueExist(false);
                                            details.t_field_id = '';
                                            details.t_value = '';

                                        }

                                        //
                                    }
                                    if (details.t_group_id > 0) {
                                        let selectGroup = groups.filter(f => {
                                            return (f.id === details.t_group_id && f.status === 'Active')
                                        });
                                        if (selectGroup.length > 0) {
                                            details.t_group_name = selectGroup[0].name;
                                            //details.isGroupExist = true;
                                        } else {
                                            details.isGroupExist = false;
                                            setIsGroupExist(false);
                                            details.t_group_id = '';
                                        }
                                    }
                                    delete item['details'];
                                    detailsArr.push(details);
                                    item.details = detailsArr;
                                }

                            });
                            setStatus(status);
                        }
                        setObjectArray(responseArray);
                        setLeadScoreId(leadScoreId)
                        setMounted(true);
                        setContactPerfectScore(ContactPerfectScore)
                        setAccPerfectScore(AccPerfectScore)
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'LeadScoreSetup.js -> getActions()':" + error);
        }

    };

    const handleChange = (event) => {
        let value = event.target.value;
        setStatus(value);
    }

    const clickAction = () => {
        setIsAddClick(true);
        setIsEdit(false);
        scroll(objectArray.length);
    }

    const isDuplicateCheck = (actionObj, existAction) => {
        let isDuplicate;
        let detailsObj = actionObj.details[0];
        if (detailsObj.hasOwnProperty('t_object_name') && detailsObj.t_object_name !== '' && detailsObj.t_object_name !== existAction.details[0].t_object_name) {
            isDuplicate = false;
        } else if ((detailsObj.hasOwnProperty('t_workflow_id') && detailsObj.t_workflow_id !== '' && detailsObj.t_workflow_id !== existAction.details[0].t_workflow_id && detailsObj.t_object_name === existAction.details[0].t_object_name) ||
            (detailsObj.hasOwnProperty('t_workflow_id') && detailsObj.t_workflow_id !== '' && detailsObj.t_workflow_id === existAction.details[0].t_workflow_id && detailsObj.t_object_name === existAction.details[0].t_object_name && detailsObj.t_converted_from === existAction.details[0].t_converted_from && detailsObj.t_converted_to !== existAction.details[0].t_converted_to) ||
            (detailsObj.hasOwnProperty('t_workflow_id') && detailsObj.t_workflow_id !== '' && detailsObj.t_workflow_id === existAction.details[0].t_workflow_id && detailsObj.t_object_name === existAction.details[0].t_object_name && detailsObj.t_converted_from !== existAction.details[0].t_converted_from && detailsObj.t_converted_to === existAction.details[0].t_converted_to) ||
            (detailsObj.hasOwnProperty('t_workflow_id') && detailsObj.t_workflow_id !== '' && detailsObj.t_workflow_id === existAction.details[0].t_workflow_id && detailsObj.t_object_name === existAction.details[0].t_object_name && detailsObj.t_converted_from !== existAction.details[0].t_converted_from && detailsObj.t_converted_to !== existAction.details[0].t_converted_to)) {
            isDuplicate = false;
        } else if (detailsObj.hasOwnProperty('t_group_id') && detailsObj.t_group_id !== '' && detailsObj.t_group_id !== existAction.details[0].t_group_id && detailsObj.t_object_name === existAction.details[0].t_object_name) {
            isDuplicate = false;
        } else {
            isDuplicate = !((detailsObj.hasOwnProperty('t_field_id') && detailsObj.t_field_id !== '' && detailsObj.t_field_id !== existAction.details[0].t_field_id && detailsObj.t_value !== existAction.details[0].t_value && detailsObj.t_object_name === existAction.details[0].t_object_name)
                || (detailsObj.hasOwnProperty('t_field_id') && detailsObj.t_field_id !== '' && detailsObj.t_field_id === existAction.details[0].t_field_id && detailsObj.t_value !== existAction.details[0].t_value && detailsObj.t_object_name === existAction.details[0].t_object_name)
                || (detailsObj.hasOwnProperty('t_field_id') && detailsObj.t_field_id !== '' && detailsObj.t_field_id !== existAction.details[0].t_field_id && detailsObj.t_value === existAction.details[0].t_value && detailsObj.t_object_name === existAction.details[0].t_object_name));
        }
        return isDuplicate;
    }

    const callBackData = (returnObj) => {
        let actionsArr = objectArray;
        let edit = isEdit;
        let addClick = isAddClick;
        let groupExist = isGroupExist;
        let isListVauleExist = isListValueExist;
        let isFieldValuExist = isFieldValueExist;
        if (returnObj.hasOwnProperty('finalArray')) {
            let actionObj = returnObj.finalArray[0];
            let isDuplicate = false;
            groupExist = true;
            isListVauleExist = true;
            isFieldValuExist = true;
            if (returnObj.isEdit) {
                actionsArr.forEach((action) => {
                    if (actionObj.key !== action.key) {
                        if (actionObj.t_action_id === action.t_action_id) {
                            let isExist = isDuplicateCheck(actionObj, action);
                            if (!isDuplicate) {
                                isDuplicate = isExist;
                            }
                        }
                        if (action.details[0].hasOwnProperty('isGroupExist') && !action.details[0].isGroupExist) {
                            groupExist = action.details[0].isGroupExist;
                        }
                        if (action.details[0].hasOwnProperty('isListVauleExist') && !action.details[0].isListVauleExist) {
                            isListVauleExist = action.details[0].isListVauleExist;
                        }
                        if (action.details[0].hasOwnProperty('isFieldValuExist') && !action.details[0].isFieldValuExist) {
                            isFieldValuExist = action.details[0].isFieldValuExist;
                        }
                    }
                });
            } else {
                actionsArr.forEach((action) => {
                    if (actionObj.t_action_id === action.t_action_id) {
                        let isExist = isDuplicateCheck(actionObj, action);
                        if (!isDuplicate) {
                            isDuplicate = isExist;
                        }
                    }
                    if (action.details[0].hasOwnProperty('isGroupExist') && !action.details[0].isGroupExist) {
                        groupExist = action.details[0].isGroupExist;
                    }
                    if (action.details[0].hasOwnProperty('isListVauleExist') && !action.details[0].isListVauleExist) {
                        isListVauleExist = action.details[0].isListVauleExist;
                    }
                    if (action.details[0].hasOwnProperty('isFieldValuExist') && !action.details[0].isFieldValuExist) {
                        isFieldValuExist = action.details[0].isFieldValuExist;
                    }
                });
            }
            if (!isDuplicate) {
                if (returnObj.isEdit) {
                    let tempArray = [];
                    edit = !returnObj.isEdit;
                    actionsArr.forEach((obj) => {
                        if (obj.key === actionObj.key) {
                            obj = actionObj
                        }
                        tempArray.push(obj);
                    });
                    actionsArr = tempArray;
                    addClick = returnObj.isActionOpen;
                } else {
                    actionObj.key = actionsArr.length + 1;
                    actionsArr.push(actionObj);
                    addClick = returnObj.isActionOpen;
                }
            } else {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.LEAD_SCORE_SETUP.ALREDY_DEFINED_SCORE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        } else {
            edit = returnObj.isEdit;
            addClick = returnObj.isActionOpen;
        }
        setObjectArray(actionsArr);
        setIsAddClick(addClick);
        setIsEdit(edit);
        setIsGroupExist(groupExist);
        setIsListValueExist(isListVauleExist)
        setIsFieldValueExist(isFieldValuExist);
    }

    const editCallBack = (key) => {
        let editArray = objectArray.filter(f => {
            return f.key === key
        });
        setEditArray(editArray);
        setIsAddClick(true);
        setIsEdit(true);
        scroll(objectArray.length);
    }
    const deleteCallBack = (deleteObj) => {

        let actionArrayList = objectArray;
        let tempArray = [];
        let isGroupExist = true;
        let isListVauleExist = true;
        let isFieldValuExist = true;

        actionArrayList.forEach((obj) => {
            if (obj.key !== deleteObj.key) {
                tempArray.push(obj);
                if (obj.details[0].hasOwnProperty('isGroupExist') && !obj.details[0].isGroupExist) {
                    isGroupExist = obj.details[0].isGroupExist;
                }
                if (obj.details[0].hasOwnProperty('isListVauleExist') && !obj.details[0].isListVauleExist) {
                    isListVauleExist = obj.details[0].isListVauleExist;
                }
                if (obj.details[0].hasOwnProperty('isFieldValuExist') && !obj.details[0].isFieldValuExist) {
                    isFieldValuExist = obj.details[0].isFieldValuExist;
                }

            }
        });
        let addClick = isAddClick;
        let edit = isEdit;
        if (deleteObj.isEdit) {
            addClick = deleteObj.isActionOpen;
            deleteObj.isEdit = false;
            edit = deleteObj.isEdit;
        }
        setObjectArray(tempArray);
        setIsEdit(edit);
        setIsAddClick(addClick);
        setAccPerfectScore(deleteObj.AccPerfectScore);
        setContactPerfectScore(deleteObj.ContactPerfectScore);
        setIsGroupExist(isGroupExist);
        setIsListValueExist(isListVauleExist);
        setIsFieldValueExist(isFieldValuExist);
    }
    const finalSave = () => {
        let params = {};
        let actionArray = [...objectArray];
        let addClick = isAddClick;
        let edit = isEdit;
        try {
            if (actionArray.length > 0) {
                if (isGroupExist && isListValueExist && isFieldValueExist) {
                    params.leadScoreList = actionArray;
                    params.t_status = t_status;
                    params.t_pk = leadScoreId;

                    const promise = Promise.resolve(HTTPClient.post(endPoints.LEAD_SCORES.SAVE_LEADSCORE, params));
                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            if (isValidParam(response)) {

                                getActions(groups, sfFormFields);

                                dispatch(showCustomSnackBar(getLocalizedStrings().message.LEAD_SCORE_SETUP.SAVE_SUCCESSFULLY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                if (addClick) {
                                    addClick = false;
                                    edit = false;
                                }
                                setIsEdit(edit);
                                setIsAddClick(addClick);
                            }
                        });
                    }
                } else {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.LEAD_SCORE_SETUP.CAN_NOT_UPDATE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }

            } else {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.LEAD_SCORE_SETUP.SELECT_ONE_ACTION, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        } catch (error) {
            console.error("Error in 'LeadScoreSetup.js ->finalSave':" + error);
        }
    }

    if (mounted) {
        return (
            <div className='row' style={{
                backgroundColor: '#F5F5F5',
                marginRight: '16px',
                marginLeft: '15px',
                paddingBottom: '10px',
                width: '97%'
            }}>
                <div className='col-sm-12' style={{ padding: '0px', marginLeft: '18px' }}>
                    <div className='row'>
                        <div className='col-sm-6' style={{ marginLeft: '40px', padding: '0px' }}>
                            <div className='row' style={{
                                paddingTop: '15px',
                                paddingBottom: '30px',
                                marginLeft: '-2px',
                                overflow: 'hidden'
                            }}>
                                <div className="col-sm-3" style={{ width: 'auto', padding: '0px' }}>
                                    <Button
                                        key='save'
                                        onClick={() => finalSave()}

                                        style={{
                                            ...styles.primaryButton,
                                            marginRight: '12px',
                                            verticalAlign: 'top',
                                            width: '86px'
                                        }}
                                    > {getLocalizedStrings().label.COMMON.SAVE}</Button>
                                </div>
                            </div>

                            {/* <span style={{ fontSize: '15px', paddingTop: '15px', paddingBottom: '10px', marginLeft: '15px', fontWeight: 'bold' }}>Status:</span> */}
                            <div className='row'>
                                <div className='col-sm-12' style={{ padding: '0px' }}>
                                    <FormControl variant="outlined" style={{ width: '100%' }}>
                                        <InputLabel id="sf-leadscoresetup-simple-select-outlined-label"
                                            className="sf-leadscoresetup">{getLocalizedStrings().label.COMMON.STATUS}</InputLabel>
                                        <Select
                                            key="status"
                                            label={getLocalizedStrings().label.COMMON.STATUS}
                                            labelId='sf-leadscore-status'
                                            id='status'
                                            value={t_status}
                                            onChange={(e) => handleChange(e)}
                                            name='status'
                                            style={{ height: "42px" }}
                                            className={"sf-fields-bg"}
                                        >
                                            <MenuItem value={'Active'}>Active</MenuItem>
                                            <MenuItem value={'Inactive'}>Inactive</MenuItem>
                                        </Select>
                                    </FormControl>

                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6' style={{ float: 'right', width: '37%', padding: '0', marginLeft: '7%' }}>
                            {/* <div className='row'>
                                    <div className='col-sm-12'> //style={{ paddingTop: '15px', paddingBottom: '30px', marginLeft: '15px', overflow: 'hidden', float: 'right' }}> */}

                            <ShowChart ContactPerfectScore={contactPerfectScore}
                                AccPerfectScore={accPerfectScore} />

                            {/* </div>
                                </div> */}
                        </div>
                    </div>

                    <div className="row" style={{ marginLeft: '20px' }}>
                        <div className='col-sm-12' style={{ padding: '0px' }}>
                            <div>
                                <span style={{
                                    paddingTop: '15px',
                                    marginRight: '10px',
                                    marginLeft: '24px',
                                    marginTop: '15px',
                                    fontWeight: 'bold',
                                    fontSize: '15px'
                                }}>{getLocalizedStrings().label.LEAD_SCORE_SETUP.SCORES}
                                </span>
                                <span onClick={() => clickAction()}>
                                    <i className="fa fa-plus fa-lg"
                                        title={'Add Score'}
                                        aria-hidden="true" style={{
                                            cursor: 'pointer',
                                            fontSize: '18px'
                                        }}>
                                    </i>
                                </span>
                            </div>

                            <div style={{ paddingTop: '5px', paddingBottom: '1px' }}>
                                {(objectArray.length > 0 || isAddClick) ?
                                    <ShowActionList
                                        dispatch={(v) => dispatch(v)}
                                        actionArray={objectArray}
                                        editCallBack={(key) => editCallBack(key)}
                                        deleteCallBack={(obj) => deleteCallBack(obj)}
                                        isEdit={isEdit}
                                        sfFormFields={sfFormFields} />
                                    : <span style={{
                                        color: 'red',
                                        fontSize: '15px',
                                        marginLeft: '10px'
                                    }}>{getLocalizedStrings().label.LEAD_SCORE_SETUP.NO_SCORES_AVAILABLE}</span>}
                            </div>
                            <div style={{ marginTop: '15px' }}>{isAddClick &&
                                <ActionList
                                    sfFormFields={sfFormFields}
                                    objectList={objectList}
                                    groups={groups}
                                    t_status={t_status}
                                    callBack={(v) => callBackData(v)}
                                    editArray={editArray}
                                    isEdit={isEdit}
                                    app={app}
                                />
                            }</div>
                        </div>
                    </div>
                </div>
            </div>
        );

    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;

        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>);
    }
}

const ActionList = (
    {
        app,
        sfFormFields,
        groups,
        props,
        isEdit,
        editArray,
        callBack,
        objectList,
    }) => {
    const dispatch = useDispatch()
    const [actions, setActions] = useState([]);
    const [selected, setSelected] = useState('');
    const [isShowGroup, setIsShowGroup] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [t_group_name, setGroupName] = useState('');
    const [selectedObject, setSelectedObject] = useState('');
    const [points, setPoints] = useState(5);
    const [days, setDays] = useState('');
    const [selectedValue, setSelectedValue] = useState('add');
    const [addScore] = useState('add');
    const [subScore] = useState('sub');
    const [t_action_label, setActionLabel] = useState('');
    const [mounted, setMounted] = useState(false);
    const [isSelectConvert, setIsSelectConvert] = useState(false);
    const [workflowList, setWorkflowList] = useState(null);
    const [selectedWorkflow, setSelectedWorkflow] = useState('');
    const [workFlowStatusList, setWorkFlowStatusList] = useState(null);
    const [t_converted_to, setConvertedTo] = useState('');
    const [t_converted_from, setConvertedFrom] = useState('');
    const [isSelectUpdateField, setIsSelectUpdateField] = useState(false);
    const [formFields, setFormFields] = useState([]);
    const [selectedFormField, setSelectedFormField] = useState('');
    const [t_value, setValue] = useState('');
    const [fieldName, setFieldName] = useState('');
    const [isAddToThisList, setIsAddToThisList] = useState(false);

    const fieldRef = useRef({});
    const pointRef = useRef({});


    const getWorkflow = useCallback((object, selectedWfId) => {
        let promise = Promise.resolve(getWorkflowList(object));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    let item = selectedWfId;
                    let isWfSelected = false;
                    if (selectedWfId > 0) {
                        response.forEach((wf) => {
                            if (wf.id === selectedWfId) {
                                isWfSelected = true;
                            }
                        })
                    }
                    if (!isWfSelected) {
                        item = response[0].id;
                    }
                    getWorkStageByWorkflowId(item, isWfSelected);
                    setWorkflowList(response);
                    setSelectedWorkflow(item);
                }
            });
        }
    }, []);

    useEffect(() => {
        getActionList();
    }, []);

    useEffect(() => {
        const populateEditArray = (editArray, isEdit) => {
            if (isValidParam(editArray) && editArray.length > 0 && isEdit) {
                let editObj = editArray[0];
                let detailsObj = editObj.details[0];
                let isShowGroup = false;
                let isSelectConvert = false;
                let isSelectUpdateField = false;
                let fieldLabel = '';
                let t_value = detailsObj.t_value;
                if (detailsObj.t_object_id > 0) {
                    let objectName = TABLEID_OBJECT_MAP[detailsObj.t_object_id];
                    // let formFields = sfFormFields[objectName].fields;
                    let formFields = populateFields(objectName, sfFormFields);

                    if (formFields.length > 0 && detailsObj.t_field_id > 0) {
                        formFields = formFields.filter(f => {
                            return (f.t_tablefldid === detailsObj.t_field_id)
                        });

                        if (formFields.length > 0) {
                            fieldLabel = formFields[0].name;
                            let fieldObj = formFields[0];
                            if ((fieldObj.field_type_id === constants.LIST_FORM_FIELD ||
                                fieldObj.field_type_id === constants.LIST_FORM_FIELD_INTEGER_TYPE) && fieldObj.name !== "state") {
                                let list_values = fieldObj.list_values;
                                let values = list_values.filter(f => {
                                    return f.value === detailsObj.t_value
                                });
                                if (values.length === 0) {
                                    t_value = list_values[0].value;
                                }
                            }
                        }
                    } else {
                        let fieldList = formFields[0];
                        fieldLabel = fieldList.name;
                        t_value = '';
                        if ((fieldList.field_type_id === constants.LIST_FORM_FIELD ||
                            fieldList.field_type_id === constants.LIST_FORM_FIELD_INTEGER_TYPE) && fieldList.name !== "state") {
                            let list_values = fieldList.list_values;
                            t_value = list_values[0].value;

                        }
                    }
                    //
                }

                if (editObj.t_action_id === 7 || editObj.t_action_id === 6) {
                    isShowGroup = true;
                } else if (editObj.t_action_id === 5) {
                    isSelectConvert = true;
                    getWorkflow(detailsObj.t_object_name, detailsObj.t_workflow_id);
                } else if (editObj.t_action_id === 8) {
                    let populatedFields = populateFields(detailsObj.t_object_name, sfFormFields);
                    setFormFields(populatedFields);
                    isSelectUpdateField = true;
                }
                setSelected(editObj.t_action_id + "");
                setActionLabel(detailsObj.t_action_label);
                setPoints(detailsObj.t_score);
                setDays(detailsObj.t_expires_by || '');
                setSelectedValue((detailsObj.t_operator === '+') ? 'add' : 'sub');
                setSelectedGroup(detailsObj.t_group_id || '');
                setGroupName(detailsObj.t_group_name);
                setSelectedObject(detailsObj.t_object_name);
                // setSelectedWorkflow(detailsObj.t_workflow_id || '');
                setConvertedFrom(detailsObj.t_converted_from);
                setConvertedTo(detailsObj.t_converted_to);
                setSelectedFormField(fieldLabel);
                setValue(t_value);
                setIsSelectConvert(isSelectConvert);
                setIsShowGroup(isShowGroup);
                setIsSelectUpdateField(isSelectUpdateField);

            } else {
                setSelected('');
                setConvertedTo('')
                setConvertedFrom('');
                setSelectedGroup('');
                setSelectedWorkflow('');
                setIsShowGroup(false);
                setIsSelectConvert(false);
                setIsSelectUpdateField(false);
                setPoints(5);
                setDays('');
                setSelectedValue('add');
                setFormFields([]);
                setSelectedFormField('');
                setValue('');
                setFieldName('');
                setIsAddToThisList(false);
            }
        }


        let objectName = null;
        if (isEdit) {
            let editObj = editArray[0];
            let detailsObj = editObj.details[0];
            objectName = TABLEID_OBJECT_MAP[detailsObj.t_object_id];
        }
        if (isValidParam(objectName)) {
            if (sfFormFields.hasOwnProperty(objectName)) {
                populateEditArray(editArray, isEdit);
            } else {
                const promise = SFFormActions.getSFFormFieldsPromise(objectName);
                promise.then((response) => {
                    if (isValidParam(response)) {
                        sfFormFields[objectName] = Object.assign({}, response);
                        populateEditArray(editArray, isEdit);
                    }
                });
            }
        } else {
            populateEditArray(editArray, isEdit);
        }
    }, [editArray, getWorkflow, isEdit, sfFormFields]);

    // UNSAFE_componentWillReceiveProps(nextProps) {
    //     if (nextProps.object !== this.props.object) {
    //         getGroups(nextProps);
    //     }
    //     if ((this.props.editArray !== nextProps.editArray) || (this.props.isEdit !== nextProps.isEdit)) {
    //         processFormFields(nextProps.editArray, nextProps.isEdit);
    //     }
    // }

    const getActionList = () => {
        const promise = Promise.resolve(HTTPClient.get(endPoints.LEAD_SCORES.GET_ACTION_LIST));
        promise.then((response) => {
            let actionList = response.filter(f => {
                return f.t_action_id !== 29
            });
            setActions(actionList);
            setMounted(true);
        });
    }

    const getMenuItem = () => {
        try {
            if (isValidParam(actions) && actions.length > 0) {
                return actions.map((items, Index) =>
                (<MenuItem
                    value={items.t_action_id}
                    key={Index}
                    onClick={() => handleClick(Index, items.t_action_id)}
                >
                    {items.t_action_label}
                </MenuItem>)
                );
            }
        } catch (error) {
            console.error("Error in 'LeadScoreSetup.js ->onDelete():" + error);
        }
        return [];
    }
    const handleClick = (index, value) => {
        let isShowGroup = false;
        let isSelectConvert = false;
        let defaultSelectedObj = '';
        let isSelectUpdateField = false;
        if (value === 7 || value === 6) {
            isShowGroup = true;
        } else if (value === 5) {
            isSelectConvert = true;
            defaultSelectedObj = constants.ACCOUNTS_OBJECT;
            getWorkflow(defaultSelectedObj, 0);
        } else if (value === 8) {
            isSelectUpdateField = true;
            defaultSelectedObj = constants.ACCOUNTS_OBJECT;
            getFormFields(defaultSelectedObj, sfFormFields);
        }
        let actionItem = actions.filter(f => {
            return f.t_action_id === value
        });
        let item = actionItem[0];
        let actionLabel = (item.t_action_id === 6) ? 'Added to' : item.t_action_label;
        setSelected(value);
        setIsSelectConvert(isSelectConvert);
        setIsShowGroup(isShowGroup);
        setActionLabel(actionLabel);
        setSelectedObject(defaultSelectedObj);
        setIsSelectUpdateField(isSelectUpdateField);
    }
    const getGroupItem = () => {
        try {
            if (isValidParam(groups) && groups.length > 0) {
                return groups.filter(f => f.status === 'Active').map((items, Index) =>
                (<MenuItem
                    value={items.id}
                    key={Index}
                    onClick={() => groupClick(Index, items.id)}
                >
                    {items.name}
                </MenuItem>)
                );
            }
        } catch (error) {
            console.error("Error in 'LeadScoreSetup.js ->getGroupItem':" + error);
        }
        return [];
    }
    const groupClick = (index, value) => {
        let groupItems = [...groups];
        let groupItem = groupItems.filter(f => {
            return f.id === value
        });
        let group = groupItem[0];
        setSelectedGroup(value);
        setGroupName(group.name);
    }

    const handleChangeDays = (event) => {
        let value = event.target.value;
        if (((isInteger(value) && getIntParam(value) <= 999 && getIntParam(value) > 0) || value === "")) {
            setDays(value);
        }
        if (!isInteger(value) && value !== "") {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.LEAD_SCORE_SETUP.CHAR_ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
    }

    const handleChangePoints = (event) => {
        let value = event.target.value;

        if ((isInteger(value) && getIntParam(value) <= 99 && getIntParam(value) > 0) || value === "") {
            setPoints(value);
        }
    }

    const validateForm = () => {
        let isValid = true;
        const selectedInteger = getIntParam(selected);
        if (selectedInteger <= 0) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.LEAD_SCORE_SETUP.SELECT_ACTION, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else if (selectedInteger === 5 || selectedInteger === 6 || selectedInteger === 7 || selectedInteger === 8) {
            if (selectedObject.length === 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.LEAD_SCORE_SETUP.SELECT_OBJECT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;

            } else if (selectedInteger === 5) {
                if (selectedWorkflow <= 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.LEAD_SCORE_SETUP.SELECT_WORKFLOW, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;

                } else if (t_converted_from.length === 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.LEAD_SCORE_SETUP.CONVERT_FROM_CANT_BLANK, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                } else if (t_converted_to.length === 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.LEAD_SCORE_SETUP.CONVERT_TO_CANT_BLANK, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                } else if (t_converted_to === t_converted_from) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.LEAD_SCORE_SETUP.CHOOSE_DIFF_STATUS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                }
            } else if (selectedInteger === 6 || selectedInteger === 7) {
                if (selectedGroup.length === 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.LEAD_SCORE_SETUP.SELECT_GROUP, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                }
            } else if (selectedInteger === 8) {
                let fieldObject = formFields.filter(fld => {
                    return fld.name === selectedFormField
                })[0];
                if (selectedFormField.length === 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.LEAD_SCORE_SETUP.SELECT_FIELD, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                }
                if (t_value.replace(/\s+/g, '').length === 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.LEAD_SCORE_SETUP.PROVIDE_VALUE_TO_UPDATE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    fieldRef.current.focus();
                    return false;
                }
                if (fieldObject !== null) {
                    if (fieldObject.field_type_id === constants.URL_FORM_FIELD && !isUrl(t_value)) {
                        dispatch(showCustomSnackBar(t_value + " " + getLocalizedStrings().message.AUTOMATION_DESIGNER.INVALID_URL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        fieldRef.current.focus();
                        return false;

                    }
                    if (fieldObject.field_type_id === constants.FLOAT_FORM_FIELD && !isFloat(t_value)) {
                        dispatch(showCustomSnackBar(fieldObject.label + " - " + getLocalizedStrings().message.COMMON.VALIDATE_DECIMAL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        fieldRef.current.focus();
                        return false;
                    }
                    if (fieldObject.is_email_field && !isEmail(t_value)) {
                        dispatch(showCustomSnackBar(t_value + " " + getLocalizedStrings().message.AUTOMATION_DESIGNER.INVALID_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        fieldRef.current.focus();
                        return false;

                    }
                }
            }

        }
        if (points.length === 0) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.LEAD_SCORE_SETUP.ENTER_SCORE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            isValid = false;
            pointRef.current.focus();
        }

        return isValid;
    }

    const saveLeadScoreDetails = () => {
        try {
            let isValid = validateForm();
            if (isValid) {
                let tempObj = {};
                let actionArray = [];
                let detailsArray = [];
                let detailsObj = {};
                let formField = formFields;
                let fields = '';
                let fieldId = 0;
                let fieldType = '';
                let value = '';
                if (formField && formField.length > 0) {
                    fields = formField.filter(f => {
                        return (f.name === selectedFormField)
                    });
                    if (fields.length > 0) {
                        fieldId = fields[0].t_tablefldid;
                        fieldType = fields[0].field_type
                    }
                }
                tempObj.t_action_id = getIntParam(selected);
                detailsObj.t_action_label = t_action_label;
                detailsObj.t_group_id = selectedGroup === '' ? 0 : getIntParam(selectedGroup);
                detailsObj.t_group_name = t_group_name;
                detailsObj.t_operator = selectedValue === 'add' ? '+' : '-';
                detailsObj.t_score = getIntParam(points);
                detailsObj.t_object_name = selectedObject;

                if (isValidParam(selectedObject)) {
                    detailsObj.t_object_id = (((getIntParam(selected)) === 27) || ((getIntParam(selected)) === 4)) ? (getIntParam(OBJECT_TABLEID_MAP['accounts'])) : (((getIntParam(selected)) === 1) || ((getIntParam(selected)) === 2) || ((getIntParam(selected)) === 3)) ? (getIntParam(OBJECT_TABLEID_MAP['contacts'])) : (getIntParam(OBJECT_TABLEID_MAP[selectedObject]));
                }
                if (days !== '' && days > 0) {
                    detailsObj.t_expires_by = days;
                }
                detailsObj.t_converted_from = t_converted_from;
                detailsObj.t_converted_to = t_converted_to;
                detailsObj.t_workflow_id = selectedWorkflow === '' || selectedWorkflow === null ? 0 : getIntParam(selectedWorkflow);
                detailsObj.t_field_id = getIntParam(fieldId);

                if (fieldType === 'float') {
                    value = t_value.trim();
                    let valueFloat = parseFloat(value);
                    detailsObj.t_value = valueFloat.toString();
                } else {
                    detailsObj.t_value = t_value.trim();
                }
                detailsArray.push(detailsObj);
                tempObj.details = detailsArray;
                tempObj.isEdit = !!isEdit;
                let returnObj = {};

                returnObj.isActionOpen = false;
                if (isEdit) {
                    let editObject = editArray[0];
                    tempObj.key = editObject.key;
                    returnObj.isEdit = isEdit;
                    tempObj.t_pk = editObject.t_pk;
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.LEAD_SCORE_SETUP.UPDATE_MSG, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));

                }
                actionArray.push(tempObj);
                returnObj.finalArray = actionArray;
                callBack(returnObj);
            }

        } catch (error) {

            console.error("Error in 'LeadScoreSetup.js -> saveLeadScoreDetails()':" + error);
        }
    }

    const cancelLeadScore = () => {
        let returnObj = {};
        returnObj.isActionOpen = false;
        returnObj.isEdit = false;
        callBack(returnObj);
    }
    const getTabButtonCss = (selectedTabVal) => {
        let css = {};
        try {
            css = {
                float: 'left',
                width: '55px',
                height: '35px',
                padding: 5,
                borderBottom: '2.0px solid #afafaf5c',
                borderRight: '1.0px solid #afafaf5c',
                borderRadius: '0px 0px 0px',
                cursor: 'pointer',
                background: '#fff'
            };
            selectedTabVal = getStringParam(selectedTabVal);
            if (selectedValue === selectedTabVal) {
                css.background = '#00000014';
                css.borderBottom = '2.0px solid Black';
                css.borderRight = '2.0px solid Black';
                css.borderLeft = '2.0px solid Black';
                css.borderTop = '2.0px solid Black';
                css.borderRadius = '5px 5px';
            }
        } catch (error) {
            console.error("Error in 'LeadScoreSetup.js -> getTabButtonCss()':" + error);
        }
        return css;
    }
    const changeOperator = (value) => {
        setSelectedValue(value);
    }
    const getObjects = () => {
        try {
            let parentItems = [...objectList];
            const selectedInteger = getIntParam(selected);
            if (selectedInteger === 5) {
                parentItems = parentItems.filter(f => {
                    return f.name === constants.ACCOUNTS_OBJECT || f.name === constants.OPPORTUNITIES_OBJECT
                })
            } else if (selectedInteger === 6 || selectedInteger === 7) {
                parentItems = parentItems.filter(f => {
                    return f.name === constants.ACCOUNTS_OBJECT || f.name === constants.CONTACTS_OBJECT
                })
            } else if (selectedInteger === 8) {
                parentItems = parentItems.filter(f => {
                    return f.name === constants.ACCOUNTS_OBJECT || f.name === constants.CONTACTS_OBJECT
                })
            }
            if (parentItems.length > 0) {
                return parentItems.map((items, Index) =>
                (<MenuItem
                    value={items.name}
                    key={Index}
                >
                    {items.label}
                </MenuItem>)
                )
            }
        } catch (error) {
            console.error("Error in 'LeadScoreSetup.js -> getObjects()':" + error);
        }
        return [];
    }

    const changeObj = (event) => {
        let value = event.target.value;
        const selectedInteger = getIntParam(selected);
        if (selectedInteger === 5) {
            getWorkflow(value, 0);
        } else if (selectedInteger === 8) {
            getFormFields(value, sfFormFields);
        }
        setSelectedObject(value);
        setConvertedTo('');
        setConvertedFrom('');
    }

    const generateWorkflowList = () => {
        if (isValidParam(workflowList)) {
            return workflowList.map((objWorkflow) =>
            (<MenuItem value={objWorkflow.id}
                key={objWorkflow.id}
            >
                {objWorkflow.name}
            </MenuItem>)
            );
        }
        return null;
    }

    const changeWorkflow = (event) => {
        let value = event.target.value;
        setConvertedTo('');
        setConvertedFrom('');
        getWorkStageByWorkflowId(value, false);
    }

    const getWorkStageByWorkflowId = (value, isWfSelected) => {
        let params = {
            workflow_id: value
        }
        let promise = Promise.resolve(getWorkflowStageById(params));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (!isWfSelected) {
                        setConvertedFrom('');
                        setConvertedTo('');
                    }
                    setWorkFlowStatusList(response.stages);
                    setSelectedWorkflow(value);
                }
            });
        }
    }

    const generateStatusList = () => {
        if (isValidParam(workFlowStatusList)) {
            return workFlowStatusList.map((objStatus, index) => <MenuItem value={objStatus.name}
                key={index}>{objStatus.name}</MenuItem>);
        }
        return null;
    }

    const changeConvertToStatus = (event) => {
        let value = event.target.value;
        setConvertedTo(value);
    }
    const changeConvertFromStatus = (event) => {
        let value = event.target.value;
        setConvertedFrom(value);
    }
    const getFormFields = (object, fields) => {
        try {
            let populatedFields = populateFields(object, fields);
            let fieldList = populatedFields[0];
            let defaultSelectedField = fieldList.name;
            setFormFields(populatedFields);
            setSelectedFormField(defaultSelectedField);
            setValue('');
        } catch (error) {
            console.error("Error in 'LeadScoreSetup.js -> getFormFields()':" + error);
        }
    }

    const populateFields = (object, fields) => {
        let formFields = fields[object].fields;
        try {
            formFields = formFields.filter(f => {
                return (f.field_type !== "header" && f.field_type !== "" && f.field_type !== 'integer'
                    && !f.is_hidden_field && !f.is_readonly && f.name !== "workflow_name" && f.name !== "opportunity_amount" &&
                    f.name !== "sales_rep" && f.name !== "t_type" && f.name !== "lead_score" && f.field_type !== "datetime" && f.field_type !== "date" && f.name !== "visible_to");
            });
            sortArrayObjectByProperty(formFields, 'label');
        } catch (error) {
            console.error("Error in 'LeadScoreSetup.js -> populateFields()':" + error);
        }
        return formFields;
    };

    const generateFormFieldList = () => {
        if (isValidParam(formFields) && formFields.length > 0) {
            return formFields.map((fields) => <MenuItem value={fields.name} id={fields.id} key={fields.id}
                name={fields.name}>{fields.label}</MenuItem>);
        }
        return null;
    }

    const changeFormField = (event) => {
        let value = event.target.value;
        if (fieldName !== value) {
            setValue('');
        }
        let fields = [...formFields];
        fields = fields.filter(f => {
            return f.name === value
        });
        if (fields.length > 0) {
            let fieldObj = fields[0];
            if ((fieldObj.field_type_id === constants.LIST_FORM_FIELD ||
                fieldObj.field_type_id === constants.LIST_FORM_FIELD_INTEGER_TYPE) && fieldObj.name !== "state") {
                let list_values = fieldObj.list_values;
                setValue(list_values[0].value);
            }
        }
        setSelectedFormField(value);
        getDependentField(value);

    }
    const getDependentField = (value) => {
        return generateTypeWiseFields(value, [...formFields]);
    }
    const generateTypeWiseFields = (fieldName, fieldProps) => {
        let component = null;
        let hasPermission = false;
        let listValues = [];
        try {
            if (fieldProps !== null && fieldProps.length > 0) {
                let fieldObject = fieldProps.filter(fld => {
                    return fld.name === fieldName
                })[0];
                if ((fieldObject.field_type_id === constants.LIST_FORM_FIELD ||
                    fieldObject.field_type_id === constants.LIST_FORM_FIELD_INTEGER_TYPE) && fieldObject.name !== 'state') {
                    let listComponent = null;
                    let fieldValue = t_value;
                    if (getBooleanParam(isAddToThisList)) {
                        component =
                            <FormControl style={{ width: '98%' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    style={{ height: '42px', marginTop: '0px' }}
                                    key={'text_field_' + fieldName}
                                    id={fieldName + "##edit"}
                                    value={fieldValue}
                                    ref={fieldRef}
                                    onChange={(e) => setFieldValue(e, fieldName, e.target.value)}
                                    onBlur={() => addToThisList(fieldName)}
                                    autoFocus={true}
                                    inputprops={{ maxLength: '128' }}
                                    margin='dense'
                                    size='small'
                                    className={"sf-fields-bg"}
                                />
                            </FormControl>
                    } else {
                        if (fieldObject.hasOwnProperty('list_values')) {
                            listValues = fieldObject.list_values;
                            listComponent = listValues.map((listValueObject, i) => {
                                return (
                                    <MenuItem
                                        key={i}
                                        value={listValueObject.value}
                                        onClick={() => changeFieldValue(listValueObject.value, fieldName)}
                                        style={{ ...styles.popoverMenuItem }}
                                        title={listValueObject.value}>{listValueObject.value}</MenuItem>
                                );
                            });
                            let userId = getIntParam(app.me.id);
                            let isModifyList = getBooleanParam(fieldObject.is_modify_list);
                            hasPermission = hasAccessPermission("", constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_ADD_TO_LIST);
                            if (isModifyList && (userId === 1 || hasPermission)) {
                                let primaryText = fieldName === 'source' ? '---- ' + getLocalizedStrings().label.COMMON.ADD_NEW_SOURCE + ' ----' : '---- ' + getLocalizedStrings().label.COMMON.ADD_TO_THIS_LIST + ' ----';
                                listComponent.push(
                                    <MenuItem
                                        key={'addto_' + fieldName}
                                        id={'addto_' + fieldName}
                                        value={primaryText}
                                        onClick={() => changeFieldValue(primaryText, fieldName)}
                                        style={{ ...styles.popoverMenuItem }}
                                        title={primaryText}>{primaryText}</MenuItem>
                                );
                            }
                        }
                        if (listComponent === null) {
                            listComponent = <MenuItem key={listValues} value={listValues} title={listValues}
                                style={{ ...styles.popoverMenuItem }}>{listValues}</MenuItem>
                        }
                        component = <FormControl variant="outlined" style={{ width: '98%' }}>
                            <Select
                                key={'select_field_' + fieldName}
                                value={fieldValue}
                                id={fieldName + "##edit"}
                                style={{ height: '42px' }}
                                className={"sf-fields-bg"}
                            >
                                {listComponent}
                            </Select>
                        </FormControl>
                    }
                } else {
                    let fieldValue = t_value;
                    let maxLength = fieldName === 'state' ? 64 : 232;
                    component =
                        <FormControl style={{ width: '98%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                fullWidth={true}
                                style={{ height: '42px', marginTop: '0px' }}
                                key={'text_field_' + fieldName}
                                id={fieldName + "##edit"}
                                ref={fieldRef}
                                value={fieldValue}
                                onChange={(e) => setFieldValue(e, fieldName, e.target.value)}
                                inputprops={{ maxLength: maxLength }}
                                margin='dense'
                                size='small'
                                className={"sf-fields-bg"}
                            />
                        </FormControl>
                }
            }
        } catch (error) {
            console.error("Error in 'LeadScoreSetup.js -> generateTypeWiseFields()': " + error);
        }
        return component;
    }
    // stringFormat =(fieldName, event, value)=>{


    // }
    const setFieldValue = (event, fieldName, value) => {
        try {
            let fields = [...formFields];
            fields = fields.filter(fld => {
                return fld.name === fieldName
            });
            if (fields.length > 0 && fields[0].hasOwnProperty('is_phone_field')) {
                if (fields[0].is_phone_field) {
                    value = phoneFormat(value);
                }
            }
            setValue(value);

        } catch (error) {
            console.error("Error in 'LeadScoreSetup.js -> setFieldValue()': " + error);
        }
    }
    const addToThisList = (fieldName) => {
        try {
            let value = t_value;
            if (value !== '') {
                if (fieldName === 'source') {
                    let promise = checkDuplicateSourceName(value);
                    if (isValidParam(promise)) {
                        promise.then(response => {
                            saveNewListValue(fieldName, value, getBooleanParam(response));
                        });
                    }
                } else {
                    let field = formFields.filter(f => {
                        return f.name === fieldName
                    });
                    if (field.length > 0) {
                        let listValues = getArrayParam(field[0].list_values);
                        let tempValues = listValues.filter(f => {
                            return f.value.toLowerCase() === value.toLowerCase()
                        });
                        saveNewListValue(fieldName, value, tempValues.length > 0);
                    }
                }
            } else {
                setIsAddToThisList(false);
            }
        } catch (error) {
            console.error("Error in 'LeadScoreSetup.js -> addToThisList()':" + error);
        }
    }
    const saveNewListValue = (fieldName, value, isExist) => {
        try {
            let fields = [...formFields];
            if (getBooleanParam(isExist)) {
                alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.LEAD_SCORE_SETUP.EXIST_IN_LIST);
            } else if (fieldName === 'source') {
                let promise = saveSourceName(value);
                if (isValidParam(promise)) {
                    promise.then(response => {
                        if (getBooleanParam(response.data)) {
                            addSourceOnRedux(constants.CONTACTS_OBJECT, fieldName, value);
                            fields.forEach(field => {
                                if (field.name === fieldName) {
                                    field.list_values.push({ id: value, value: value });
                                    sortArrayObjectByProperty(field.list_values, 'value');
                                }
                            });
                            setFormFields(fields);
                            setIsAddToThisList(false);
                            setValue(value);
                        }
                    });
                }
            } else {
                let field = fields.filter(f => {
                    return f.name === fieldName
                });
                if (field.length > 0) {
                    let values = getArrayParam(field[0].list_values);
                    let listValues = [];
                    values.forEach(field => {
                        if (field.value !== '') {
                            listValues.push(field.value);
                        }
                    });
                    listValues.push(value);
                    let promise = saveListValues(selectedObject, field[0].id, listValues);
                    if (isValidParam(promise)) {
                        promise.then(() => {
                            fields.forEach(field => {
                                if (field.name === fieldName) {
                                    field.list_values.push({ id: value, value: value });
                                    sortArrayObjectByProperty(field.list_values, 'value');
                                }
                            });
                            setFormFields(fields);
                            setIsAddToThisList(false);
                            setValue(value);
                        });
                    }
                }

            }
        } catch (error) {
            console.error("Error in 'automationFieldUpdate.js -> saveNewListValue()':" + error);
        }
    }

    const changeFieldValue = (value, fieldName) => {
        try {
            let fieldProps = [...formFields];
            fieldProps = fieldProps.filter(f => {
                return f.name === fieldName
            });
            let fieldItem = fieldProps[0];
            let isModifyList = getBooleanParam(fieldItem.is_modify_list);
            if (isModifyList && (value === '---- ' + getLocalizedStrings().label.COMMON.ADD_NEW_SOURCE + ' ----' ||
                value === '---- ' + getLocalizedStrings().label.COMMON.ADD_TO_THIS_LIST + ' ----')) {
                setIsAddToThisList(true);
                setValue('');
            } else if (fieldItem.hasOwnProperty('name')) {
                setValue(value);
            }
        } catch (error) {
            console.error("Error in 'LeadScoreSetup.js -> changeFieldValue()': " + error);
        }
    }

    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2;
    return (
        <div className='row' style={{ backgroundColor: '#F5F5F5', marginRight: '36px', marginLeft: '-6px' }}>
            {mounted ? <div>
                <div className='col-sm-12'>
                    <div>
                        <span style={{
                            color: 'RGB(0, 13, 11)',
                            paddingTop: '20px',
                            fontSize: '15px',
                            fontWeight: 'bold',
                            marginLeft: '15px'
                        }}>{getLocalizedStrings().label.AUDIT_LEADSCORE.ACTION}:</span>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6' style={{ marginTop: '10px' }}>
                            <div>
                                {actions.length > 0 &&
                                    <FormControl variant="outlined" style={{ width: '100%' }}>
                                        <InputLabel id="sf-leadscoresetup-simple-select-outlined-label"
                                            style={{ marginTop: '-4px' }}
                                            className="sf-leadscoresetup">{getLocalizedStrings().label.AUDIT_LEADSCORE.ACTION}</InputLabel>
                                        <Select
                                            key="actions"
                                            label={getLocalizedStrings().label.AUDIT_LEADSCORE.ACTION}
                                            id='selected'
                                            value={selected}
                                            name='selected'
                                            style={{ height: '42px' }}
                                            className={"sf-fields-bg"}
                                        >
                                            {getMenuItem()}
                                        </Select>
                                    </FormControl>
                                }
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            {(isShowGroup || isSelectConvert || isSelectUpdateField) &&
                                <FormControl variant="outlined" style={{ width: '98%', marginTop: '10px' }}>
                                    <InputLabel
                                        id="sf-lead-score-setup-from-leadscoresetup-simple-select-outlined-label"
                                        style={{ marginTop: '-4px' }}
                                        className="sf-lead-score-setup-from-leadscoresetup">{getLocalizedStrings().label.LEAD_SCORE_SETUP.OBJECT}</InputLabel>
                                    <Select
                                        label={getLocalizedStrings().label.LEAD_SCORE_SETUP.OBJECT}
                                        name="parentObject"
                                        id="parentObject"
                                        value={selectedObject}
                                        style={{ height: '42px' }}
                                        onChange={(e) => changeObj(e)}
                                        className={"sf-fields-bg"}
                                    >
                                        {getObjects()}
                                    </Select>
                                </FormControl>
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6' style={{ marginTop: 16 }}>
                            {isShowGroup &&
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <InputLabel
                                        id="sf-leadscoresetup-from-lead-score-setup-simple-select-outlined-label"
                                        style={{ marginTop: '-4px' }}
                                        className="sf-leadscoresetup-from-lead-score-setup">{getLocalizedStrings().label.LEAD_SCORE_SETUP.GROUPS}</InputLabel>
                                    <Select
                                        key="groups"
                                        label={getLocalizedStrings().label.LEAD_SCORE_SETUP.GROUPS}
                                        id='groups'
                                        value={selectedGroup}
                                        name='groups'
                                        style={{ height: '42px' }}
                                        className={"sf-fields-bg"}
                                    >
                                        {getGroupItem()}
                                    </Select>
                                </FormControl>
                            }
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "10px", flexWrap: 'nowrap' }}>
                        <div className='col-sm-6'>
                            {isSelectConvert &&
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <InputLabel id="sf-leadscoresetup-results-simple-select-outlined-label"
                                        className="sf-leadscoresetup-results">{getLocalizedStrings().label.LEAD_SCORE_SETUP.WORKFLOW_NAME}</InputLabel>
                                    <Select
                                        key="workflow"
                                        label={getLocalizedStrings().label.LEAD_SCORE_SETUP.WORKFLOW_NAME}
                                        id='workflow'
                                        value={selectedWorkflow}
                                        onChange={(e) => changeWorkflow(e)}
                                        name='workflow'
                                        style={{ height: '42px' }}
                                        className={"sf-fields-bg"}
                                    >
                                        {generateWorkflowList()}
                                    </Select>
                                </FormControl>
                            }
                        </div>
                        {isSelectConvert && <div style={{ display: 'flex' }}>
                            <div className='col-sm-3'>
                                <FormControl variant="outlined" style={{ width: '96%' }}>
                                    <InputLabel
                                        id="sf-leadscoresetup-result-fromleadscoresetup-simple-select-outlined-label"
                                        className="sf-leadscoresetup-result-fromleadscoresetup">{getLocalizedStrings().label.LEAD_SCORE_SETUP.CONVERTED_FROM}</InputLabel>
                                    <Select
                                        key="Converted_From"
                                        label={getLocalizedStrings().label.LEAD_SCORE_SETUP.CONVERTED_FROM}
                                        id='convert_from'
                                        value={t_converted_from}
                                        onChange={(e) => changeConvertFromStatus(e)}
                                        name="Converted_From"
                                        style={{ height: '42px' }}
                                        className={"sf-fields-bg"}
                                    >
                                        {generateStatusList()}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-sm-3'>
                                <FormControl variant="outlined" style={{ width: '96%' }}>
                                    <InputLabel
                                        id="sf-leadscoresetup-result-from-leadscoresetup-simple-select-outlined-label"
                                        className="sf-leadscoresetup-result-from-leadscoresetup">{getLocalizedStrings().label.LEAD_SCORE_SETUP.CONVERTED_TO}</InputLabel>
                                    <Select
                                        key="Converted_To"
                                        label={getLocalizedStrings().label.LEAD_SCORE_SETUP.CONVERTED_TO}
                                        id='convert_to'
                                        value={t_converted_to}
                                        onChange={(e) => changeConvertToStatus(e)}
                                        name='Converted_To'
                                        style={{ height: '42px' }}
                                        className={"sf-fields-bg"}
                                    >
                                        {generateStatusList()}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>}
                    </div>
                    {isSelectUpdateField &&
                        <div className='row'>
                            <div className='col-sm-6'>
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <InputLabel id="sf-leadscoresetups-simple-select-outlined-label"
                                        className="sf-leadscoresetups">{getLocalizedStrings().label.LEAD_SCORE_SETUP.FIELDS_NAME}</InputLabel>
                                    <Select
                                        key="Fields"
                                        label={getLocalizedStrings().label.LEAD_SCORE_SETUP.FIELDS_NAME}
                                        id='Fields'
                                        value={selectedFormField}
                                        onChange={(e) => changeFormField(e)}
                                        name='Fields'
                                        style={{ height: '42px' }}
                                        className={"sf-fields-bg"}
                                    >
                                        {generateFormFieldList()}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-sm-6">
                                {getDependentField(selectedFormField)}
                            </div>
                        </div>}
                    <div className='row' style={{ marginTop: '10px', padding: '1px 8px', }}>
                        <div className='col-sm-3'>
                            <div style={{ height: '36px', marginLeft: '-6px', marginTop: '8px' }}>
                                <div style={{
                                    ...getTabButtonCss(addScore),
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                }} onClick={() => changeOperator(addScore)} title={'Add'}><span
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '20px',
                                        marginLeft: '14px',
                                        lineHeight: 'normal'
                                    }}>{'+'}</span></div>
                                <div style={{
                                    marginLeft: 5, ...getTabButtonCss(subScore),
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                }} onClick={() => changeOperator(subScore)}><span style={{
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                    marginLeft: '15px',
                                    lineHeight: 'normal'
                                }} title={'Sub'}>{'-'}</span></div>
                            </div>
                        </div>
                        <div className='col-sm-3' style={{ display: 'inline' }}>
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    label={getLocalizedStrings().label.LEAD_SCORE_SETUP.POINTS}
                                    name="points"
                                    id="points"
                                    ref={pointRef}
                                    value={points}
                                    onChange={(e) => handleChangePoints(e)}
                                    disabled={false}
                                    autoComplete="new-password"
                                    margin='dense'
                                    size='small'
                                    className={"sf-fields-bg"}
                                />
                            </FormControl>
                        </div>
                        <div className='col-sm-6' style={{ display: 'inline' }}>
                            <div className='row'>
                                <div className='col-sm-6'>

                                    <FormControl className="test" noValidate autoComplete="off">
                                        <TextField
                                            variant="outlined"
                                            label={getLocalizedStrings().label.LEAD_SCORE_SETUP.EXPIRES_BY}
                                            name="Expires by"
                                            value={days}
                                            onChange={(e) => handleChangeDays(e)}
                                            disabled={false}
                                            margin='dense'
                                            size='small'
                                            className={"sf-fields-bg"}
                                        />
                                    </FormControl>
                                </div>
                                <div className='col-sm-6' style={{ padding: '0px' }}>
                                    <span style={{
                                        fontSize: '16px',
                                        lineHeight: '3em'
                                    }}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.WAIT_FOR_DAYS}</span>
                                </div>
                                <div className='col-sm-12'>
                                    <div>
                                        <span style={{
                                            fontSize: '13px',
                                            marginLeft: '4px',
                                            color: 'rgb(166, 162, 161)'
                                        }}>{getLocalizedStrings().label.LEAD_SCORE_SETUP.APPLY_TO_EXISTING_SCORE}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row" style={{ paddingRight: '13px' }}>
                    <div className="col-sm-12">
                        <div
                            style={{ paddingTop: '15px', paddingBottom: '30px', overflow: 'hidden', float: 'right' }}>
                            <div className="col-sm-6" style={{ width: 'auto', padding: '0px' }}>
                                <Button
                                    key='Cancel'
                                    onClick={() => cancelLeadScore()}
                                    style={{ ...styles.secondaryButton, marginRight: '12px', verticalAlign: 'top' }}
                                >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                            </div>
                        </div>
                        <div
                            style={{ paddingTop: '15px', paddingBottom: '30px', overflow: 'hidden', float: 'right' }}>
                            <div className="col-sm-6" style={{ width: 'auto', padding: '0px' }}>
                                <Button
                                    key='save'
                                    onClick={() => saveLeadScoreDetails()}
                                    style={{ ...styles.primaryButton, marginRight: '12px', verticalAlign: 'top' }}
                                >{isEdit ? getLocalizedStrings().label.COMMON.UPDATE : getLocalizedStrings().label.COMMON.ADD}</Button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
                :
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

const ShowActionList = ({ isEdit, dispatch, editCallBack, deleteCallBack, actionArray, sfFormFields }) => {
    const onConfirmDelete = (id, key) => {
        let params = {};
        try {
            params.id = id;
            const promise = Promise.resolve(HTTPClient.post(endPoints.LEAD_SCORES.DELETE_LEADSCORE, params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        let deleteObj = {
                            key: key,
                            ContactPerfectScore: response.data.ContactPerfectScore,
                            AccPerfectScore: response.data.AccPerfectScore,
                            isActionOpen: false,
                            isEdit: isEdit,
                            id: id
                        }
                        deleteCallBack(deleteObj);
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.LEAD_SCORE_SETUP.REMOVE_MSG, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'LeadScoreSetup.js -> onDelete()':" + error);
        }
    }

    const onDelete = (key, t_pk) => {
        let id = [];
        id.push(getIntParam(t_pk));
        try {
            confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.LEAD_SCORE_SETUP.SCORE_DELETED, () => onConfirmDelete(id, key), null);
        } catch (error) {
            console.error("Error in 'LeadScoreSetup.js -> onDelete()':" + error);
        }
    }
    const populateLeadScore = () => {
        let listItems = [];
        try {
            if (actionArray.length > 0) {
                actionArray.forEach((items, Index) => {
                    let detailsObj = items.details[0];
                    let fieldLabel = '';
                    if (detailsObj.hasOwnProperty('t_field_id') && detailsObj.t_object_id > 0 && detailsObj.t_field_id > 0) {
                        let objectName = TABLEID_OBJECT_MAP[detailsObj.t_object_id];
                        let formFields = sfFormFields[objectName].fields;
                        if (formFields.length > 0) {
                            formFields = formFields.filter(f => {
                                return (f.t_tablefldid === detailsObj.t_field_id)
                            });
                            if (formFields.length > 0) {
                                fieldLabel = formFields[0].label;
                            }
                        }
                    }
                    let operatorStyle = { color: detailsObj.t_operator === '+' ? 'green' : 'red' };
                    let scoreStyle = { color: detailsObj.t_operator === '+' ? 'green' : 'red', marginLeft: '-3px' };
                    let borderStyle = ((detailsObj.hasOwnProperty('isGroupExist') && !detailsObj.isGroupExist) || (detailsObj.hasOwnProperty('isListVauleExist') && !detailsObj.isListVauleExist) || (detailsObj.hasOwnProperty('isFieldValuExist') && !detailsObj.isFieldValuExist)) ? '1px solid red' : '1px solid grey';
                    let element = (
                        <div className='col-sm-12' key={Index}>
                            <div className='row' style={{
                                border: borderStyle,
                                background: 'rgb(252, 251, 247)',
                                borderRadius: '4px',
                                marginTop: '10px',
                                marginRight: '60px',
                                marginLeft: '10px',
                                height: 'auto',
                                padding: '4px',
                                maxWidth: '100%',
                                lineHeight: '1.5em'
                            }}>
                                <div className='col-sm-12'>
                                    {
                                        ((detailsObj.hasOwnProperty('isGroupExist') && !detailsObj.isGroupExist)) ?
                                            (<span>
                                                {(items.t_action_id === 6) ? 'Added to Group' : (items.t_action_id === 7) ? 'Removed from Group' : detailsObj.t_action_label}
                                            </span>) :
                                            (<span>
                                                {(items.t_action_id === 6) ? 'Added to' : (items.t_action_id === 7) ? 'Removed from' : (items.t_action_id === 5) ? 'Converted' : (items.t_action_id === 8) ? 'Updated' : detailsObj.t_action_label}
                                            </span>)
                                    }
                                    {
                                        (items.t_action_id === 6 || items.t_action_id === 7) &&
                                        (<span style={{ marginLeft: '4px' }}>
                                            {detailsObj.t_group_name}
                                            <span style={{ marginLeft: '3px' }}>for</span>
                                        </span>)
                                    }
                                    {
                                        (items.t_action_id === 8) &&
                                        (<span style={{ marginLeft: '4px' }}>
                                            {fieldLabel} <span>of</span>
                                        </span>)
                                    }
                                    {
                                        (items.t_action_id === 5 || items.t_action_id === 6 || items.t_action_id === 7 || items.t_action_id === 8) &&
                                        (<span style={{ marginLeft: '4px' }}>
                                            {(detailsObj.t_object_id === 10) ? 'Accounts' : (detailsObj.t_object_id === 9) ? 'Contacts' : (detailsObj.t_object_id === 2) ? 'Opportunities' : detailsObj.t_object_name}
                                        </span>)
                                    }
                                    {
                                        (items.t_action_id === 5) &&
                                        (<span style={{ width: 'auto' }}>
                                            <span> From</span> <span>{detailsObj.t_converted_from}</span>
                                            <span> To </span> <span>{detailsObj.t_converted_to}</span>
                                        </span>)
                                    }
                                    {
                                        (items.t_action_id === 8) &&
                                        (<span style={{ width: 'auto' }}>
                                            <span> with</span> <span>{detailsObj.t_value}</span>
                                        </span>)
                                    }
                                    <span style={{ width: 'auto' }}>
                                        <span style={operatorStyle}> {detailsObj.t_operator}</span>
                                        <span style={scoreStyle}> {detailsObj.t_score} </span>
                                        {
                                            (detailsObj.hasOwnProperty('t_expires_by') && detailsObj.t_expires_by > 0) &&
                                            (<span>
                                                <span
                                                    style={{ color: 'red', marginLeft: '4px' }}>{getLocalizedStrings().label.LEAD_SCORE_SETUP.EXPIRES_BY} </span>
                                                <span style={{
                                                    color: 'red',
                                                    marginLeft: '4px'
                                                }}>{detailsObj.t_expires_by}</span>
                                                <span style={{ color: 'red' }}> {getLocalizedStrings().label.COMMON.DAYS} </span>
                                            </span>)
                                        }
                                    </span>
                                    <span>
                                        <span
                                            onClick={() => editCallBack(items.key)}
                                            title={'Modify'}
                                            key={Index}
                                            id={Index}>
                                            <i className="fa fa-pencil fa-lg" aria-hidden="true" style={{
                                                cursor: 'pointer',
                                                lineHeight: '1.2em',
                                                marginLeft: '5px'
                                            }}>
                                            </i>
                                        </span>
                                    </span>
                                    <span>
                                        <span
                                            onClick={() => onDelete(items.key, items.t_pk)}
                                            title={'Remove'}
                                            key={Index}
                                            id={Index}>
                                            <i className="fa fa-trash-o fa-lg"
                                                aria-hidden="true"
                                                style={{ cursor: 'pointer', lineHeight: '1.2em', marginLeft: '10px' }}>
                                            </i>
                                        </span>
                                    </span>
                                    {
                                        (detailsObj.hasOwnProperty('isGroupExist') && !detailsObj.isGroupExist) &&
                                        (<span style={{
                                            color: 'red',
                                            paddingLeft: '10px'
                                        }}> {getLocalizedStrings().label.LEAD_SCORE_SETUP.GROUP_ALREADY_REMOVE} </span>)
                                    }
                                    {
                                        (detailsObj.hasOwnProperty('isFieldValuExist') && !detailsObj.isFieldValuExist) &&
                                        (<span style={{
                                            color: 'red',
                                            paddingLeft: '10px'
                                        }}> {getLocalizedStrings().label.LEAD_SCORE_SETUP.FIELD_ALREADY_REMOVE} </span>)
                                    }
                                    {
                                        (detailsObj.hasOwnProperty('isListVauleExist') && !detailsObj.isListVauleExist) &&
                                        (<span style={{
                                            color: 'red',
                                            paddingLeft: '10px'
                                        }}> {getLocalizedStrings().label.LEAD_SCORE_SETUP.FIELD_VALUE_ALREADY_REMOVE}</span>)
                                    }
                                </div>
                            </div>
                        </div>
                    );
                    listItems.push(element);
                }
                )
            }
        } catch (error) {
            console.error('Error on populate ' + error);
        }
        return listItems;
    }
    return (
        <div>
            <div className="row">
                {populateLeadScore()}
            </div>
        </div>
    );
}

const ShowChart = ({ ContactPerfectScore, AccPerfectScore }) => {
    const showLeadScore = () => {
        return (<div className='row'>
            <div className='col-sm-12'>
                {isValidParam(AccPerfectScore) &&
                    <div>
                        <span style={{ marginRight: '5px' }}>
                            {getLocalizedStrings().label.AUDIT_LEADSCORE.ACCOUNT_SCORE + AccPerfectScore + '.'}
                        </span>
                    </div>}
                {isValidParam(ContactPerfectScore) &&
                    <div>
                        <span>{getLocalizedStrings().label.AUDIT_LEADSCORE.CONTACT_SCORE + ContactPerfectScore + '.'}</span>
                    </div>}
            </div>
        </div>);
    }

    return (
        <div className='row' style={{ marginLeft: '10px', marginRight: '-32px' }}>
            <div className='col-sm-12'>
                <div style={{
                    border: '1px solid grey',
                    background: 'rgb(252, 251, 247)',
                    marginTop: '10px',
                    marginRight: '60px',
                    marginLeft: '60px',
                    height: '130px',
                    padding: '4px',
                    fontSize: '14px'
                }}>
                    <div>
                        <span>{'81% ' + getLocalizedStrings().label.LEAD_SCORE_SETUP.AND_ABOVE}</span>
                        <span><img alt='' src='/asset/images/star10.png' /></span>
                    </div>
                    <div>
                        <span>61 - 80%</span>
                        <span style={{ marginLeft: '39px' }}><img alt='' src='/asset/images/star8.png' /></span>
                    </div>
                    <div>
                        <span>41 - 60%</span>
                        <span style={{ marginLeft: '39px' }}><img alt='' src='/asset/images/star6.png' /></span>
                    </div>

                    <div>
                        <span>21 - 40%</span>
                        <span style={{ marginLeft: '39px' }}><img alt='' src='/asset/images/star4.png' /></span>
                    </div>
                    <div>
                        <span>1 - 20%</span>
                        <span style={{ marginLeft: '47px' }}><img alt='' src='/asset/images/star2.png' /></span>
                    </div>
                    <div>
                        <span>{getLocalizedStrings().label.LEAD_SCORE_SETUP.AND_ABOVE + ' 1%'} </span>
                        <span style={{ marginLeft: '14px' }}><img alt='' src='/asset/images/star0.png' /></span>
                    </div>

                </div>
                <div style={{ marginLeft: '60px' }}>
                    {showLeadScore()}
                </div>
            </div>
        </div>
    );
}

export default LeadScoreSetup;