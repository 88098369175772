import React
	, { useRef } from 'react';
import {
	findDOMNode
} from 'react-dom';
import {
	useDrag
} from 'react-dnd';

import Card
	from './Card';
import {
	constants
} from '../../../services/constants/constants';
import {
	getActiveTab
} from '../../../services/helper/sfTabManager';
import {
	isValidParam
} from '../../../services/helper/parameterVerifier';
import {
	columnViewTaskListUpdate,
	columnViewTaskUpdate
} from '../../../services/actions/listsActions';
import {
	ItemTypes
} from "../components/ItemTypes";

function getStyles(isDragging) {
	return {
		display: isDragging ? 0.5 : 1
	};
}

const cardSource = {
	beginDrag(props, monitor, component) {
		// dispatch to redux store that drag is started
		const { item, x, y, object } = props;
		var { id, title } = item;
		const { clientWidth, clientHeight } = findDOMNode(component);
		if (title === undefined)
			title = "";
		return { id, object, item, x, y, clientWidth, clientHeight };
	},
	endDrag(props, monitor) {//alert(monitor.getItem().id);
		document.getElementById(monitor.getItem().id).style.display = 'block';
		props.stopScrolling();
	},
	isDragging(props, monitor) {
		const isDragging = props.item && props.item.id === monitor.getItem().id;
		return isDragging;
	}
};

const OPTIONS = {
	arePropsEqual: function arePropsEqual(props, otherProps) {
		let isEqual = true;
		if (props.item.id === otherProps.item.id &&
			props.x === otherProps.x &&
			props.y === otherProps.y
		) {
			isEqual = true;
		} else {
			isEqual = false;
		}
		return isEqual;
	}
};

function collectDragSource(connectDragSource, monitor) {
	return {
		connectDragSource: connectDragSource.dragSource(),
		connectDragPreview: connectDragSource.dragPreview(),
		isDragging: monitor.isDragging()
	};
}

const CardComponent = ({ id, x, y, reportBy, sortBy, selectedFields, totalCardsCount, item, fields, object, cardType, stopScrolling }) => {

	const componentRef = useRef();

	const [{ isDragging }, drag] = useDrag({
		type: ItemTypes.CARD,
		item: () => {
			let clientWidth = 0;
			let clientHeight = 0;
			if (!!componentRef.current) {
				clientWidth = componentRef.current['clientWidth'];
				clientHeight = componentRef.current['clientHeight'];
			}
			return { id: item.id, object, item, x, y, clientWidth, clientHeight }
		},
		collect: (monitor) => {
			return {
				isDragging: monitor.isDragging()
			}
		},
		isDragging: (monitor) => item.id === monitor.getItem().id,
		end: (_, monitor) => {
			document.getElementById(monitor.getItem().id).style.display = 'block';
			stopScrolling();
		}
	});

	const isDraggableItem = (item) => {
		let isValid = false;
		if (isValidParam(item) && item.type === constants.TASKS && (item.columnName === "Overdue" || item.columnName === "Future" || item.columnName === "Today" || item.columnName === "Tomorrow") && (item.status === constants.TASK_STATUS_PENDING)) {
			// if (app.me.is_tenant_admin || item.t_owner === app.me.name) {
			isValid = true;
			// }
			//return true;
		}
		return isValid;
	}

	const updateCards = (list) => {
		if(object === constants.TASKS) {
			columnViewTaskListUpdate(list)
		} else {
			columnViewTaskUpdate(list);
		}
	}

	let tab = getActiveTab();
	if (isValidParam(tab)) {
	}
	drag(componentRef);
	if (cardType === constants.COLUMN_VIEW && object !== constants.TASKS) {
		return (
			<div ref={componentRef}>
				<Card
					style={{ ...getStyles(isDragging), cursor: 'move' }}
					item={item}
					key={item.id}
					stopScrolling={stopScrolling}
					object={object}
					parentIndex={id}
					reportBy={reportBy}
					sortBy={sortBy}
					probability={item.probability}
					fields={fields}
					selectedFields={selectedFields}
					cardType={cardType}
					totalCardsCount={totalCardsCount}
					updateCards={updateCards}
				/>
			</div>
		);
	} else {
		if (cardType === constants.COLUMN_VIEW && object === constants.TASKS && isDraggableItem(item)) {
			return (
				<div ref={componentRef}>
					<Card
						style={{ ...getStyles(isDragging), cursor: 'move' }}
						item={item}
						key={item.id}
						stopScrolling={stopScrolling}
						object={object}
						parentIndex={id}
						reportBy={reportBy}
						sortBy={sortBy}
						probability={item.probability}
						fields={fields}
						selectedFields={selectedFields}
						cardType={cardType}
						totalCardsCount={totalCardsCount}
						updateCards={updateCards}
					/>
				</div>);
		} else {
			return (
				<div ref={componentRef}>
					<Card
						style={{ ...getStyles(isDragging), cursor: 'move' }}
						item={item}
						key={item.id}
						stopScrolling={stopScrolling}
						object={object}
						parentIndex={id}
						reportBy={reportBy}
						sortBy={sortBy}
						probability={item.probability}
						fields={fields}
						selectedFields={selectedFields}
						cardType={cardType}
						totalCardsCount={totalCardsCount}
						updateCards={updateCards}
					/>
				</div>
			);
		}
	}
}

export default CardComponent;