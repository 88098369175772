import { actions } from '..//constants/actions';

function updateRoyaltyReportData(resultSet) {
    return {
        type: actions.ROYALTY_REPORT_DATA_UPDATE,
        payload: resultSet
    }
}

function updateRoyaltyReportV2Data(resultSet) {
    return {
        type: actions.ROYALTY_REPORT_V2_DATA_UPDATE,
        payload: resultSet
    }
}

export {
    updateRoyaltyReportData,
    updateRoyaltyReportV2Data
}
