import React from 'react';
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import { Select, Button, Grid, Typography, FormControl, InputLabel, MenuItem } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import ShowCircularProgress from '../components/circularProgress';

const ReportedSalesSetup = () => {
    let tempSourceList = [
        {
            value: 'BW',
            label: 'BW',
        },
        {
            value: 'QBO',
            label: 'QBO'
        },
        {
            value: 'Manually',
            label: 'Manually'
        },
        {
            value: 'Store',
            label: 'Store',
        },
    ];
    const [sourceList, setSourceList] = useState(tempSourceList);
    const [source, setSource] = useState('');
    const [mounted, setMounted] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        try {
            var promise = Promise.resolve(HTTPClient.get(endPoints.COMPLIANCE_SETUP.GET, null));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (isValidParam(response) && response?.hasOwnProperty('complinaceSetup')
                            && response.complinaceSetup?.hasOwnProperty('reportedsales_source')) {
                            setSource(response.complinaceSetup.reportedsales_source);
                            setMounted(true);
                        } else {
                            setMounted(true);
                        }
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'reportedSalesSetup.js -> useEffect()':" + error);
        }

    }, []);

    const changeSource = (event) => {
        let value = event.target.value;
        try {
            setSource(value);
        } catch (error) {
            console.error("Error in 'reportedSalesSetup.js -> changeSource()': " + error);
        }
    }

    const saveData = () => {
        let params = {};
        params.reportedsales_source = source;

        if (source == null || source == undefined || source == "") {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.VALID_SOURCE_LIST, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }
        else {
            let promise = Promise.resolve(HTTPClient.post(endPoints.COMPLIANCE_SETUP.SAVE, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.status === 0) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    }
                }
            });
        }
    }

    const generateSourceList = () => {
        let listItems = sourceList.map((source, i) => {
            return (
                <MenuItem
                    key={i}
                    id={source.value}
                    value={source.value}
                    style={{ ...styles.popoverMenuItem }}
                    title={source.label} > {source.label}</MenuItem>
            );
        });

        return listItems;
    }


    if (mounted) {
        return (
            <div style={{ paddingLeft: '3%', width: '97%' }}>
                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid item xs={2}><Typography >{getLocalizedStrings().label.SETTINGS.REPORTED_SALES_SOURCE}</Typography></Grid>
                    <Grid item xs={6}> <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                        <InputLabel id="sf-whitelabel-simple-select-outlined-label" className="sf-whitelabel">{getLocalizedStrings().label.SETTINGS.REPORTED_SALES_SOURCE}</InputLabel>
                        <Select
                            onChange={(event) => changeSource(event)}
                            defaultValue={source}
                            style={{ height: '42px' }}
                            label={getLocalizedStrings().label.SETTINGS.REPORTED_SALES_SOURCE}
                            labelId="user"
                            className={"sf-fields-bg"}
                        >
                            {generateSourceList()}
                        </Select>
                    </FormControl></Grid>
                </Grid>
                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid item xs={8}>
                        <div style={{ paddingBottom: '30px', overflow: 'hidden', float: 'right' }}>
                            <Button
                                key='save'
                                onClick={() => saveData()}
                                style={{ ...styles.primaryButton, verticalAlign: 'top', marginRight: '-3px' }}
                            >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        return (<div style={{ width: '100%', height: contentHeight }}>
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
        </div>);
    }

}

export default ReportedSalesSetup;