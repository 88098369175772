import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';
import store from '../../../src/services/store';
import { actions } from '..//constants/actions';


function getUsefulLinks(params){
    let url = null;
    try {
        url = endPoints.USEFUL_LINKS.GET_USEFUL_LINKS;
        store.dispatch({type: actions.USEFUL_LINKS_GET, payload: HTTPClient.get(url, params)})
    } catch (error) {
        console.error("Error in 'UsefulLinksAction.js'---> 'getUsefulLinks()' "+error)        
    }
}

function getCatagories(){
    let url = null;
    let promise = null;
    try {
        url = endPoints.USEFUL_LINKS.GET_CATEGORIES;
        promise = Promise.resolve(HTTPClient.get(url));
    } catch (error) {
        console.error("Error in 'UsefulLinksAction.js'---> 'getCatagories()' "+error)        
    }
    return promise;
}

function deleteCatagory(params){
    let url = null;
    let promise = null;
    try {
        url = endPoints.USEFUL_LINKS.DELETE_CATEGORY;
        promise = HTTPClient.post(url,params)
    } catch (error) {
        console.error("Error in 'UsefulLinksAction.js'---> 'deleteCatagory()' "+error)        
    }
    return promise;
}

function deleteLinks(params){
    let url ="";
    let promise = null;
    try {
     url = endPoints.USEFUL_LINKS.DELETE_LINKS;
     promise = HTTPClient.post(url,params) ;  
    } catch (error) {
        console.error("Error in 'UsefulLinksAction.js' ----> 'deleteLinks()'"+error);
    }
    return promise;
}

function editCategory(params){
    let url = null;
    let promise = null;
    try {
        url = endPoints.USEFUL_LINKS.EDIT_CATEGORY;
        promise = HTTPClient.get(url,params);
    } catch (error) {
        console.error("Error in 'UsefulLinks.js' ---> 'editCategory()'"+error);
    }   
    return promise;
}
function editLink(params){
    let url = null;
    let promise= null;
    try {
        url = endPoints.USEFUL_LINKS.EDIT_LINK;
        promise = HTTPClient.get(url,params);
    } catch (error) {
        console.error("Error in 'UsefulLinks.js' ---> 'editLink()'"+error);
    }
    return promise;
}
function saveLinkCategory(params){
    let url = "";
    let promise = null;
    try {
        url = endPoints.USEFUL_LINKS.SAVE;
        promise = HTTPClient.post(url,params); 
    } catch (error) {
        console.error("Error in 'UsefulLinks.js' ---> 'saveLinkCategory()'"+error);
    }
    return promise;
}

export {
    getUsefulLinks,
    getCatagories,
    deleteCatagory,
    deleteLinks,
    editCategory,
    editLink,
    saveLinkCategory,
}