import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { FormControl, Button, TextField, Chip } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { isValidParam, getObjectParam, getStringParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import ShowCircularProgress from '../components/circularProgress';
import { showCustomSnackBar, hideSnackBar } from '../../../services/actions/snackBarAction';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getWorkFlowStage } from '../../../services/actions/batchActions';
import { getWrkflowList } from '../../../services/actions/convertToAccountActions';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import Autocomplete from '@mui/material/Autocomplete';
import { refreshListView } from '../../../services/actions/listViewAcions';
import {useSelector } from "react-redux";


const Restore = ({ object, data, callFrom }) => {

    const dispatch = useDispatch();

    const [mounted, setMounted] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [workflowList, setWorkflowList] = useState([]);
    const [workflowStages, setWorkflowStages] = useState([]);
    const [currentWorkflowId, setCurrentWorkflowId] = useState(null);
    const [workflowName, setWorkflowName] = useState('');
    const [stageName, setStageName] = useState('');
    const sfForm = useSelector((state) => state.sfForm);

    useEffect(() => {
        dispatch(setAppDialogActions(getActions()));
        getWFList();
        
    }, []);

    useEffect(()=>{
        if(currentWorkflowId != null && selectedStatus != null){
            dispatch(setAppDialogActions(getActions()));
            setMounted(true);
        }
    },[currentWorkflowId,selectedStatus]);

   const getActions = () => {
        const actions = [
            <Button

                primary={true}
                onClick={() => {
                    onRestoreSubmit();
                }}
                style={styles.primaryButton}
            >{getLocalizedStrings().label.COMMON.RESTORE}</Button>
        ];
        return actions;
    }

    const getWFList = () => {
        try {
            var response = getWrkflowList();
            response.then((response) => {
                if (isValidParam(getArrayParam(response)) && getArrayParam(response).length > 0) {
                    response.map((item) => {
                        let obj = {};
                        obj.id = item.id;
                        obj.value = item.id;
                        obj.label = item.name;
                        obj.title = item.name;
                        workflowList.push(obj);
                    })
                }
                setMounted(true);
            });

        } catch (error) {
            console.error("Error in 'Restore.js -> getWFList()':" + error);
        }
    }

    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const onRestoreSubmit = () => {
        let _workflowId = currentWorkflowId;
        let _statusId = selectedStatus;
        var promise = null;
        let url = null;
        let params = null;
        try{
            let statusfield = sfForm?.data?.accounts?.fields.filter(f => f.name == 't_status');
              let status = statusfield[0].default_value
        if (isValidParam(_workflowId)) {
            if (isValidParam(_statusId)) {
                url = endPoints.ACCOUNTS.RESTORE;
                params = {
                    'workflow_id': currentWorkflowId,
                    'stage_id': selectedStatus,
                    'record_ids': data.recordIds,
                    'workflow_name': workflowName,
                    'stage_name': stageName,
                    'contactStatus': status
                }
                promise = Promise.resolve(HTTPClient.post(url, params));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            if (response.data == true) {
                                handleClose();
                                refreshListView(callFrom);
                               dispatch(showCustomSnackBar(getLocalizedStrings().message.RECYCLE_BIN.RESTORE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            }
                        }
                    });
                }
            } else {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.RECYCLE_BIN.SELECT_STAGE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        } else {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.RECYCLE_BIN.SELECT_WORKFLOW, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
           }
        } catch (error) {
            console.error("Error in 'Restore.js -> onRestoreSubmit()':" + error);
        }
    }

    const getWorkFlowStageById = (event, option) => {
        try {
            let params = null;
            let stages = [];
            let _workflowList = workflowList;
            let name = '';
            let stageName = '';
            let workFlowId = option.id;
            if (isValidParam(workFlowId)) {
                params = {
                    workflow_id: workFlowId,
                }
                if (isValidParam(_workflowList)) {
                    _workflowList = _workflowList.filter(f => f.id == workFlowId);
                }
                if (isValidParam(_workflowList) && _workflowList.length > 0) {
                    name = _workflowList[0].title;
                }
                var response = getWorkFlowStage(object, params);
                response.then((response) => {
                    if (isValidParam(response.stages)) {
                        stageName = response.stages[0].name;
                        response.stages.map((item, i) => {
                            let obj = {};
                            obj.id = item.id;
                            obj.value = item.id;
                            obj.label = item.name;
                            obj.title = item.name;
                            stages.push(obj);
                        });
                    }
                                  setCurrentWorkflowId(workFlowId);
                                    setWorkflowStages(stages);
                                    setSelectedStatus(response.stages[0].id);
                                    setWorkflowName(name);
                                    setStageName(stageName);
                });
            }
        } catch (error) {
            console.error("Error in 'Restore.js -> getWorkFlowStageById()':" + error);
        }
    }


    const changeStatus = (event, option) => {
        let value = option.id
        try {
            let name = '';
            let stageObj = workflowStages.filter(f =>  {
                if (f.id === value) {
                    return f;
                }
            });
           if (isValidParam(stageObj) && stageObj.length > 0) {
                name = stageObj[0].title;
            }
            setSelectedStatus(value);
            setStageName(name);
            
        } catch (error) {
            console.error("Error in 'Restore.js -> changeStatus()':" + error);
        }
    }

    let pageHeight = window.innerHeight - 495;
    let top = (pageHeight - 10) / 2;
    if (mounted === true) {
        return (
            <div style={{ paddingLeft: '30px', paddingRight: '30px', marginTop: '10px', paddingBottom: '10px' }}>
                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                    <Autocomplete
                        style={{ ...styles.sfFormFieldContainer, paddingLeft: 0 }}
                        key={"number"}
                        id="combo-box-sf-restore"
                        name="resultType"
                        defaultValue={workflowList.find(v => v.value === currentWorkflowId)}
                        options={workflowList}
                        onChange={(event,option) => getWorkFlowStageById(event,option)}
                        getOptionLabel={(option) => option.label}
                        // renderOption={(option) => {
                        //     return (
                        //         <div title={option.label}>
                        //             {option.label}
                        //         </div>
                        //     );
                        // }}
                        // renderTags={(value, getTagProps) =>
                        //     value.map((option, index) => (
                        //         <Chip
                        //             label={option.label}
                        //             title={option.label}
                        //             {...getTagProps({ index })}
                        //         />
                        //     ))
                        // }
                        disableClearable={false}
                        renderInput={(params) => <TextField {...params} label={getLocalizedStrings().label.COMMON.WORKFLOW_NAME} variant="outlined" margin="dense" size='small' className={"sf-fields-bg"} />}
                    />

                </FormControl>
                <div>
                    <FormControl style={{ width: '100%', paddingTop: '10px' }} className="test" noValidate autoComplete="off">
                        <Autocomplete
                           style={{ ...styles.sfFormFieldContainer, paddingLeft: 0 }}
                           key={"number"}
                           id="combo-box-sfrestore"
                           name="resultType"
                           defaultValue={workflowStages.find(v => v.value === selectedStatus)}
                           options={workflowStages}
                           onChange={(event,option)=>changeStatus(event,option)}
                           getOptionLabel={(option) => option.label}
                        //    renderOption={(option) => {
                        //        return (
                        //            <div title={option.label}>
                        //               {option.label}
                        //            </div>
                        //        );
                        //      }}
                        //    renderTags={(value, getTagProps) =>
                        //        value.map((option, index) => (
                        //          <Chip
                        //            label={option.label}
                        //            title={option.label}
                        //            {...getTagProps({ index })}
                        //          />
                        //        ))
                        //      }
                           disableClearable={false}
                           renderInput={(params) => <TextField {...params} label={getLocalizedStrings().label.COMMON.STATUS} variant="outlined" margin="dense"  size='small' className={"sf-fields-bg"}/>}
                       />


                    </FormControl>
                </div>
            </div>
        );
    } else {
        return (
            <div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }

}
export default Restore;