import { actions } from '..//constants/actions';

const quotationInitialState = {
    isRefresh: false,
}

const quotationReducer =(state = quotationInitialState, action )=>{
    switch (action.type) {
         case actions.QUOTATIONS_IS_REFRESH:
            return {
                ...state,
                isRefresh: action.payload,
            }
            
        default:
            return state;
    }

}
export default quotationReducer;