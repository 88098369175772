import { useDragLayer } from 'react-dnd'
import CardDragPreview from './CardDragPreview';
import { ItemTypes } from "../components/ItemTypes";

const layerStyles = {
	position: 'fixed',
	pointerEvents: 'none',
	zIndex: 1000000
}

function getItemStyles(initialOffset, currentOffset, isSnapToGrid) {
	if (!initialOffset || !currentOffset) {
		return {
			display: 'none',
		}
	}
	let { x, y } = currentOffset
	const transform = `translate(${x}px, ${y}px)`
	return {
		transform,
		WebkitTransform: transform,
	}
}

const CustomDragLayer = (props) => {
	const { itemType, isDragging, item, initialOffset, currentOffset } =
		useDragLayer((monitor) => ({
			item: monitor.getItem(),
			itemType: monitor.getItemType(),
			initialOffset: monitor.getInitialSourceClientOffset(),
			currentOffset: monitor.getSourceClientOffset(),
			isDragging: monitor.isDragging(),
		}))

	function renderItem() {
		switch (itemType) {
			case ItemTypes.CARD:
				return <CardDragPreview card={item}/>
			default:
				return null
		}
	}

	if (!isDragging) {
		return null
	}
	return (
		<div style={layerStyles}>
			<div
				style={getItemStyles(initialOffset, currentOffset, props.snapToGrid)}
			>
				{renderItem()}
			</div>
		</div>
	)
}

export default CustomDragLayer;