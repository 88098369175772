import React from 'react';
import { connect } from 'react-redux';
import { isValidParam, getStringParam, getObjectParam, getIntParam, getBooleanParam, getArrayParam, makeValidAppUrl } from '../../../services/helper/parameterVerifier';
import { setHeader } from '../../../services/actions/headerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import { sortArrayObjectByProperty, sortArrayObjectByPropertyForCurrency } from '../../../services/helper/utils';
import { constants } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import SFDataTable from '../components/sfDataTable';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { addTab, getActiveTab, addNewFormTabs, TYPE_DETAIL_VIEW } from '../../../services/helper/sfTabManager';
import ShowCircularProgress from '../components/circularProgress';
import { updateRoyaltyReportData } from '../../../services/actions/royaltyReportActions';
import { LinkToType } from '../../../services/constants/link';

const optionvalue = ["", "1st", "2nd", "3rd", "4th", "5th", "6th",
    "7th", "8th", "9th", "10th", "11th", "12th", "13th", "14th", "15th", "16th",
    "17th", "18th", "19th", "20th", "21st", "22nd", "23rd", "24th", "25th", "26th",
    "27th", "28th", "29th", "30th", "31st", "32nd", "33rd", "34th", "35th", "36th",
    "37th", "38th", "39th", "40th", "41st", "42nd", "43rd", "44th", "45th", "46th",
    "47th", "48th", "49th", "50th", "51st", "52nd", "53rd"
];

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const CustomDateInputStyle = () => {
    return <style> {
        ".form-control[readonly] {background-color: #fff !important;}"
    }</style>
}

const mapStateToProps = (state) => {
    return {
        app: state.app,
        report: state.report,
        sfForm: state.sfForm,
        royaltyReport: state.royaltyReport.royaltyReportData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setHeader: (selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader) => {
            dispatch(setHeader(selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader));
        },
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
        updateRoyaltyReportData: (results) => {
            dispatch(updateRoyaltyReportData(results));
        },
    }
};

class DrillDownReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMounted: false,
            fields: [],
            records: [],
            rowSort: {
                sortFieldName: 'reported_by',
                isDescending: false,
                onSort: this.onSort
            },
            reportParams: {},
            headerLabel: "",
        }
    }

    componentDidMount() {
        try {
            let activeTab = getObjectParam(getActiveTab());
            let reportData = getObjectParam(this.props.royaltyReport.drilldown);
            let rowSort = this.state.rowSort;
            if (reportData.hasOwnProperty('configParams') && JSON.stringify(reportData.configParams) === JSON.stringify(activeTab.info)) {
                let _state = this.state;
                if (reportData.hasOwnProperty('fields')) {
                    let fields = getArrayParam(reportData.fields);
                    _state.fields = fields;
                }

                if (reportData.hasOwnProperty('records')) {
                    let records = getArrayParam(reportData.records);
                    _state.records = records;
                }

                if (activeTab.hasOwnProperty('info')) {
                    let reportParams = getObjectParam(activeTab.info);
                    _state.reportParams = reportParams;
                }

                let sortFieldName = reportData.configParams.show === "ReportedSales" ? "reported_by" : reportData.configParams.show === "Paid" ? "payment_no" : "invoice";
                rowSort.isDescending = this.cookieGetSortData();
                rowSort.sortFieldName = sortFieldName;
                _state.isMounted = true;
                _state.rowSort = rowSort;
                _state.headerLabel = activeTab.info.reportLabel;
                this.setState(_state);

            } else {
                this.getReportData(activeTab.info);
            }
            this.props.setHeader(constants.DRILL_DOWN_OBJECT, activeTab.info.reportLabel, null, false, true);
        } catch (error) {
            console.error("Error in 'drillDownReport.js -> componentDidMount()'" + error);
        }
    }

    getReportData = (reportParams) => {
        let records = [];
        let fields = [];
        let url = endPoints.ROYALTY_REPORT.GET_DRILL_DOWN_DATA;
        let promise = Promise.resolve(HTTPClient.get(url, reportParams));
        this.setState({ isMounted: false });
        promise.then((response) => {
            if (isValidParam(response)) {

                records = getArrayParam(response.records);
                fields = getArrayParam(response.fields);
                let sortFieldName = reportParams.show === "ReportedSales" ? "reported_by" : reportParams.show === "Paid" ? "payment_no" : "invoice";

                let rowSort = {
                    sortFieldName: sortFieldName,
                    isDescending: this.cookieGetSortData(),
                    // isDescending: false,
                    onSort: this.onSort
                }
                let totalRec = [];
                let tempRecordArr = [];
                if (reportParams.show === "ReportedSales") {
                    if (records.length > 0) {
                        totalRec = records.filter(f => {
                            if (f.reported_by === 'Total Sale' || f.reported_by === 'Royalty %' || f.reported_by === 'Royalty') {
                                return f;
                            }
                        });
                        tempRecordArr = records.filter(f => {
                            if (f.reported_by !== 'Total Sale' && f.reported_by !== 'Royalty %' && f.reported_by !== 'Royalty') {
                                return f;
                            }
                        });
                    }
                } else if (reportParams.show === "Paid") {
                    if (records.length > 0) {
                        totalRec = records.filter(f => {
                            if (f.type === 'Total Sale' || f.type === 'Royalty %' || f.type === 'Royalty') {
                                return f;
                            }
                        });
                        tempRecordArr = records.filter(f => {
                            if (f.type !== 'Total Sale' && f.type !== 'Royalty %' && f.type !== 'Royalty') {
                                return f;
                            }
                        });
                    }
                } else {
                    if (records.length > 0) {
                        totalRec = records.filter(f => {
                            if (f.due_dt === 'Total Sale' || f.due_dt === 'Royalty %' || f.due_dt === 'Royalty') {
                                return f;
                            }
                        });
                        tempRecordArr = records.filter(f => {
                            if (f.due_dt !== 'Total Sale' && f.due_dt !== 'Royalty %' && f.due_dt !== 'Royalty') {
                                return f;
                            }
                        });
                    }
                }

                if (getBooleanParam(rowSort.isDescending)) {
                    this.sortArrayObjectByPropertyDesc(tempRecordArr, rowSort.sortFieldName);
                } else {
                    sortArrayObjectByProperty(tempRecordArr, rowSort.sortFieldName);
                }


                if (tempRecordArr.length > 0) {
                    if (totalRec.length > 0) {
                        tempRecordArr.push(totalRec[0]);
                        tempRecordArr.push(totalRec[1]);
                        tempRecordArr.push(totalRec[2]);
                    }
                    records = tempRecordArr;
                }



                let objParams = { configParams: reportParams, fields: fields, records: records };
                let finalObject = this.props.royaltyReport;
                finalObject.drilldown = objParams;
                this.props.updateRoyaltyReportData(finalObject);

                this.setState({ isMounted: true, fields: fields, records: records, rowSort: rowSort, reportParams: reportParams, headerLabel: reportParams.reportLabel });
            }
        });

    }

    cookieSetSortData(value) {
        let userid = this.props.app.me.id;
        let cVarName = "isDescending_" + userid
        if (value) {
            document.cookie = cVarName + "=yes";
        } else {
            document.cookie = cVarName + "=no";
        }
    }

    cookieGetSortData() {
        let userid = this.props.app.me.id;
        let cVarName = "isDescending_" + userid + "=";
        let cookieVar = decodeURIComponent(document.cookie).split(';')
        for (var i = 0; i < cookieVar.length; i++) {
            var c = cookieVar[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(cVarName) === 0) {
                var value = c.substring(cVarName.length, c.length);
                if (value === "yes") {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return "";
    }

    onSort = (fieldName, isDescending) => {
        let rowSort = this.state.rowSort;
        let records = getArrayParam(this.state.records);
        let sortFieldName = getStringParam(fieldName);
        try {
            let totalRec = [];
            let tempRecordArr = [];

            if (this.state.reportParams.show === "ReportedSales") {
                if (records.length > 0) {
                    totalRec = records.filter(f => {
                        if (f.reported_by === 'Total Sale' || f.reported_by === 'Royalty %' || f.reported_by === 'Royalty') {
                            return f;
                        }
                    });
                    tempRecordArr = records.filter(f => {
                        if (f.reported_by !== 'Total Sale' && f.reported_by !== 'Royalty %' && f.reported_by !== 'Royalty') {
                            return f;
                        }
                    });
                }
            } else if (this.state.reportParams.show === "Paid") {
                if (records.length > 0) {
                    totalRec = records.filter(f => {
                        if (f.type === 'Total Sale' || f.type === 'Royalty %' || f.type === 'Royalty') {
                            return f;
                        }
                    });
                    tempRecordArr = records.filter(f => {
                        if (f.type !== 'Total Sale' && f.type !== 'Royalty %' && f.type !== 'Royalty') {
                            return f;
                        }
                    });
                }
            } else {
                if (records.length > 0) {
                    totalRec = records.filter(f => {
                        if (f.due_dt === 'Total Sale' || f.due_dt === 'Royalty %' || f.due_dt === 'Royalty') {
                            return f;
                        }
                    });
                    tempRecordArr = records.filter(f => {
                        if (f.due_dt !== 'Total Sale' && f.due_dt !== 'Royalty %' && f.due_dt !== 'Royalty') {
                            return f;
                        }
                    });
                }
            }

            if (fieldName === 'total_sale' || fieldName === 'payment' || fieldName === 'grand_total' || fieldName === 'balance_due') {
                if (getBooleanParam(rowSort.isDescending)) {
                    sortArrayObjectByPropertyForCurrency(tempRecordArr, sortFieldName, this.props.app.me.currency_symbol);
                    rowSort.isDescending = false;
                }
                else {
                    this.sortArrayObjectByPropertyDescForCurrency(tempRecordArr, sortFieldName, this.props.app.me.currency_symbol);
                    rowSort.isDescending = true;
                }
            } else {
                if (getBooleanParam(rowSort.isDescending)) {
                    sortArrayObjectByProperty(tempRecordArr, sortFieldName);
                    rowSort.isDescending = false;
                }
                else {
                    this.sortArrayObjectByPropertyDesc(tempRecordArr, sortFieldName);
                    rowSort.isDescending = true;
                }
            }

            if (tempRecordArr.length > 0) {
                if (totalRec.length > 0) {
                    tempRecordArr.push(totalRec[0]);
                    tempRecordArr.push(totalRec[1]);
                    tempRecordArr.push(totalRec[2]);
                }
                records = tempRecordArr;
            }
            this.cookieSetSortData(rowSort.isDescending);
            rowSort.sortFieldName = sortFieldName;
            this.state.rowSort = rowSort;
            this.setState({ records: records });
        }
        catch (error) {
            console.error("Error in 'drillDownReport.js -> onSort()':" + error);
        }
    }

    sortArrayObjectByPropertyDesc = (array, property) => {
        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];

                    val1 = typeof val1 === "string" ? val1.toLowerCase() : val1;
                    val2 = typeof val2 === "string" ? val2.toLowerCase() : val2;

                    if (val1 > val2) {
                        return -1;
                    } else if (val1 < val2) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
        }
    }

    sortArrayObjectByPropertyDescForCurrency = (array, property, currency) => {
        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];

                    val1 = typeof val1 === "string" ? val1.toLowerCase() : val1;
                    val2 = typeof val2 === "string" ? val2.toLowerCase() : val2;

                    val1 = val1.replace(currency, '');
                    val1 = val1.replace(',', '');
                    val1 = val1.replace('.', '');
                    val1 = val1.trim();

                    val2 = val2.replace(currency, '');
                    val2 = val2.replace(',', '');
                    val2 = val2.replace('.', '');
                    val2 = val2.trim();

                    val1 = getIntParam(val1);
                    val2 = getIntParam(val2);

                    if (val1 > val2) {
                        return -1;
                    } else if (val1 < val2) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
        }
    }

    replaceCurrencySymbols = (htmldata) => {
        htmldata = htmldata.replace(/€/gi, '&#x20AC;');
        htmldata = htmldata.replace(/￦/gi, '&#x20A9;');
        htmldata = htmldata.replace(/£/gi, '&#xa3;');
        htmldata = htmldata.replace(/₺/gi, '&#x20BA;');
        htmldata = htmldata.replace(/￥/gi, '&#xa5;');
        htmldata = htmldata.replace(/руб./gi, '&#x440;' + '&#x443;' + '&#x0431;' + ".");
        return htmldata;
    }

    downloadExcel = () => {
        let rows = getArrayParam(this.state.records);
        if (rows.length > 0) {
            let beforeHeaderComponent = this.excelGenerateBeforeHeaders();
            let headerComponents = this.excelGenerateHeaders();
            let rowComponents = this.excelGenerateRows();
            let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '</tbody></table>';
            let htmlData = htmlTable;
            if (isValidParam(htmlData) && htmlData !== '') {
                htmlData = htmlData.replace("%22", "\"");
                htmlData = this.replaceCurrencySymbols(htmlData);
                var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
                var downloadUrl = URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = downloadUrl;
                a.download = 'royaltyResultSet_' + new Date().getTime() + '.xls';
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        } else {
            this.props.showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.NO_RECORD_EXPORT, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
        }
    }

    excelGenerateBeforeHeaders = () => {
        let cells = '';
        let dueBy = '';
        let reportName = getStringParam(getLocalizedStrings().label.ROYALTY_REPORT.ROYALTY_REPORT_LABEL);
        let columns = this.state.fields.filter(f => {
            if (!f.is_hidden) { return f; }
        });
        let columnsLength = getArrayParam(columns).length;

        cells = cells + '<tr><td  colspan="' + (columnsLength - 1) + '"> ' + reportName + ' </td></tr>';
        if (reportName.length > 0) {
            let tempDueBy = getStringParam(this.state.reportParams.due_by).split("-", 2)
            let timeLine = this.state.reportParams.calculation_type === "Monthly" ? monthNames[tempDueBy[1]] + ", " : optionvalue[tempDueBy[1]] + " Week of ";
            dueBy = getStringParam(timeLine + tempDueBy[0]);
            cells = cells + '<tr><td>' + getLocalizedStrings().label.CALENDAR.DATE + ':</td>  <td colspan="' + (columnsLength - 2) + '"> ' + dueBy + ' </td></tr>';
        }
        return cells;
    }

    excelGenerateHeaders = () => {
        let cells = '';
        let reportName = getStringParam(getLocalizedStrings().label.ROYALTY_REPORT.ROYALTY_REPORT_LABEL);
        let columns = this.state.fields.filter(f => {
            if (!f.is_hidden) { return f; }
        });
        columns.map(f => {
            cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    excelGenerateRows = () => {
        let rows = this.state.records;
        let columns = getArrayParam(this.state.fields);
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            let colVal = '';
            rows.map((rowObject, rowIndex) => {
                let innerCells = '';
                columns.map((columnObject, colIndex) => {
                    if (!columnObject.is_hidden) {
                        colVal = isValidParam(rowObject[columnObject.name]) ? rowObject[columnObject.name] : '';

                        if (rowIndex === (rows.length - 1)) {
                            innerCells = innerCells + '<td  bgcolor="gray"><b>' + colVal + '</b></td>';
                        } else {

                            if (rowIndex % 2 === 0) {
                                innerCells = innerCells + '<td >' + colVal + ' </td>';
                            } else {
                                innerCells = innerCells + '<td  bgcolor="Lavender">' + colVal + ' </td>';
                            }
                        }
                    }
                })
                if (rowIndex % 2 === 0) {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                } else {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                }

            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
        }
        return rowData;

    }

    getReportFilter = () => {
        let div = <div className="row" style={{ padding: '0px', display: "flex" }}>
            <CustomDateInputStyle />
            <iframe id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute', display: 'none' }}></iframe>
            <div className='col-md-12' style={{ paddingLeft: '0px', display: 'block', marginLeft: '-4px' }}>
                <div style={{ float: 'left', width: '80%', display: 'inline-block', fontSize: '20px' }}></div>
                <div style={{ float: 'right', marginTop: '0.2%', display: 'inline-block' }}>
                    <div style={{ width: '10%', float: 'left', marginRight: '6px' }}>
                        <i onClick={() => { this.downloadExcel(); }} className="far fa-file-excel" title="Excel" style={{ fontSize: '20px', color: 'green', cursor: 'pointer', paddingTop: '3px' }} />
                    </div>
                </div>
            </div>
        </div >

        return div;
    }


    getNoRecordContent = () => {
        return (<table className="layout_maintable" style={{ width: '100%' }}><tr><td><span style={{ color: 'red' }}>{getLocalizedStrings().label.INXPRESS_REPORT.NO_RECORDS_FOUND}</span></td></tr></table>);
    }

    customEvent = (eventType, column, row, event) => {
        try {
            var royaltyRecordId = 0;
            var accountRecordId = 0;
            var clientId = 0;
            row = getObjectParam(row);
            if (column.name === 'invoice' || column.name === 'total_invoices' || column.name === 'franchisee') {
                royaltyRecordId = getArrayParam(row['royalty_recordid']);
                accountRecordId = getIntParam(row['account_record_id']);
                clientId = getIntParam(row['project_id']);
            }

            if (accountRecordId > 0) {
                // let row = getObjectParam(this.props.row); 

                if (royaltyRecordId.length > 0 && column.name === 'invoice') {
                    let info = { id: 0, title: "Add Quotes & Invoices", type: "New", object: "quotations", parentObject: "accounts", parentRecordId: accountRecordId, royalty_record_id: royaltyRecordId, tmpCid: clientId, callFor: constants.ROYALTY_OBJECT }
                    addNewFormTabs(info);
                } else if (royaltyRecordId.length > 0 && column.name === 'total_invoices') {

                    this.openRoyaltyListView(row);

                } else if (column.name === 'franchisee') {
                    this.openUnitDetailView(accountRecordId, getStringParam(row['franchisee']));
                }
            }
        }
        catch (error) {
            console.error("Error in 'drillDownReport.js -> customEvent()':" + error);
        }
    }

    openRoyaltyListView = (row) => {
        try {
            let reportLabel = "";
            let reportParams = this.state.reportParams;
            let timeLine = this.state.calculate === "Monthly" ? monthNames[this.state.timeLine] + ", " : optionvalue[this.state.timeLine] + " Week of ";
            reportLabel = row.franchisee + " (" + getStringParam(timeLine + this.state.dueBy) + ")";

            let tabParams = {
                "imgName": "fa fa-users",
                "index": 0,
                item: getActiveTab().item,
                "info": {
                    ...reportParams,
                    show: this.state.show,
                    isReport: true,
                    reportFieldValue: "All",
                    reportLabel: reportLabel,
                    parentId: row['account_record_id'],
                    total_invoiced: row['total_invoices'],
                    client_id: row['project_id'],
                    royalties_owed: row['royalties_owed'],
                    royalty_percent: row['royalty_percent'],
                    royalty_recordid: row['royalty_recordid'],
                },
                "isActive": false,
                "label": reportLabel,
                "object": constants.DRILL_DOWN_OBJECT,
                "type": 'TYPE_REPORT',
                "url": '/' + makeValidAppUrl(constants.DRILL_DOWN_OBJECT) + '/' + LinkToType.TYPE_REPORT,

            };

            addTab(tabParams, true);
        } catch (error) {
            console.error("Error in 'drillDownReport.js -> openRoyaltyListView ()':" + error);
        }
    }

    openUnitDetailView = (accountRecordId, unit) => {
        try {

            let linkUrl = '/' + constants.ACCOUNTS_OBJECT + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + accountRecordId;
            let activeTab = getObjectParam(getActiveTab());
            let activeTabIndex = getIntParam(activeTab.index);
            let info = getObjectParam(activeTab.info);
            let isInXpress = getBooleanParam(info.isInXpress);

            let tab = { label: unit, object: constants.ACCOUNTS_OBJECT, type: TYPE_DETAIL_VIEW, imgName: null, url: linkUrl, isActive: true, parentTabIndex: activeTabIndex };
            addTab(tab, true);

        } catch (error) {
            console.error("Error in 'drillDownReport.js -> openUnitDetailView()':" + error);
        }
    }

    render() {
        let headerComponent = null;
        let records = null;
        let style = { marginLeft: 42, width: '96%', paddingTop: 0, paddingLeft: 11 };
        style = { ...style, ...getObjectParam(this.props.style) };
        let isMounted = getBooleanParam(this.state.isMounted);

        let noRecordsTitle = this.getNoRecordContent();
        if (isMounted) {
            headerComponent = this.getReportFilter();
            records = getArrayParam(this.state.records);

        }
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        return (
            <div id="royaltyReportDiv" style={style}>
                {headerComponent}
                {isMounted ?
                    <div id="divcontents" key={"divcontents" + this.state.rowSort.isDescending + "-" + this.state.rowSort.sortFieldName} style={{ marginLeft: '-17px', marginTop: '10px' }}>
                        {records.length > 0 ?
                            <div>
                                <SFDataTable royalityReport={true} columns={this.state.fields} rowSort={this.state.rowSort} rows={records} customEvent={this.customEvent} />
                            </div>
                            :
                            noRecordsTitle
                        }
                    </div>
                    :
                    <div style={{ width: '100%', height: contentHeight }}>
                        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                            </div>
                        </div>
                    </div>
                }
            </div>);
    }

}
export default connect(mapStateToProps, mapDispatchToProps)(DrillDownReport);
