import { actions } from '..//constants/actions';

function updateOperationsReportData(operationsReportData) {
        return {
            type: actions.OPERATIONS_REPORT_DATA_UPDATE,
            payload: operationsReportData
        }
}

function updateOperationDuebyData(data){
    return {
        type : actions.UPDATE_OPERATIONS_DUE_BY_DATA,
        payload: data,
    }
}

export {
    updateOperationsReportData,
    updateOperationDuebyData,
}