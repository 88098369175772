import React ,{useRef, useState,useEffect} from 'react';
import { Box, MenuItem, TextField, Grid } from '@mui/material';
import { INTAKE_FORM } from '../../../../../services/constants/constants';
import moment from 'moment';
import { useSelector ,useDispatch} from 'react-redux';
import PopOver from '../../PopOver';
import { dateFormatFlatPicker } from '../../../../../services/helper/utils';
import { actions } from '../../../../../services/constants/actions';


const IntakeInfoCustomForm = ({jsonData, formData, setFormData, setStateMenuItemList }) => {
    const {formErrors} = useSelector((state)=>state.intakeForm);
    const app = useSelector((state)=> state.app);
    const dispatch = useDispatch();
    const [isScrollToDate,setIsScrollToDate] = useState(false);
    const scrollref = useRef(null);
    const inputRef =  useRef(null);

    useEffect(()=>{
        if(Object.keys(formErrors).length > 0 && formErrors['customFieldDate2'] === 'DOB is Requierd.'){
            setIsScrollToDate(true);
        }
    },[formErrors])

    useEffect(() => {
        if (isScrollToDate) {
            scrollref.current?.scrollIntoView({ behavior: 'smooth' });
            setIsScrollToDate(false);
        }
    }, [isScrollToDate]);

    const handleDropDownChange = (event, dropDownName) => {
        setFormData({
            ...formData,
            [dropDownName]: event.target.value,
        });
        dispatch({ type: actions.SET_FORM_ERROR, payload: { field: dropDownName, error: null } });

    };

    const handleCountryDropDownChange = (event) => {
        let value = event.target.value;
        setStateMenuItemList(value)
    }

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
        dispatch({ type: actions.SET_FORM_ERROR, payload: { field: event.target.name, error: null } });

    };

    const handleDateChange = (name, date) => {
        const formattedDate = moment(date).valueOf();
        setFormData({
            ...formData,
            [name]: formattedDate,
        });
    };

    const generateDatePickerField = (field, index) => {
        const { name, title } = field;
        let date = formData[name] ? moment(formData[name]).format('YYYY-MM-DD') : null;
        let datePickerOptions = {
            enableTime: false,
            dateFormat: dateFormatFlatPicker[app.me.date_format],
            static: true,
        };
        return (
            <div style={{ paddingLeft: '0px' }} >
                <div
                    style={{ marginTop: "15px",marginBottom:'3px',border:(Boolean(formErrors[field.name]) && !date) ? '0.5px solid red':''}}
                    id="dateholder"
                    ref={scrollref}
                >
                    <PopOver
                        id={'sf-date-time-picker2'}
                        className={'date-time anval'}
                        btnType={'date'}
                        buttonStyle={{ fontSize: '16px' }}
                        containerStyle={{ lineHeight: 1 }}
                        buttonLabel={title}
                        onChange={(date) => handleDateChange(field.name, date)}
                        value={date}
                        innerLabelStyle={{ padding: "12 5 2 5", fontSize: '15px' }}
                        height={41}
                        options={{ ...datePickerOptions }}
                    />
                </div>
                {(Boolean(formErrors[field.name]) && !date) &&<span style={{color:'red',fontSize:'12px'}} >{formErrors[field.name]}</span>}
            </div>
        );
    };

    const handleNumberTypeChange = (event) =>{
        if(event.target.value >= 0 && event.target.value !== '-'){
            setFormData({
                ...formData,
                [event.target.name]: event.target.value,
            });
            dispatch({ type: actions.SET_FORM_ERROR, payload: { field: event.target.name, error: null } });
        }
    }

    const focusOntextfield = (element) =>{
        inputRef.current = element;
        inputRef.current?.focus();
    }

    const generateInputField = (field, index) => {
        const { id, name, type, title, required, multiline, max_lines, inputPlaceholder } = field;
        if(type === 'tel')  {
            return (
            <TextField
                size={'small'}
                key={id}
                id={id}
                label={title}
                placeholder={inputPlaceholder}
                inputRef={Boolean(formErrors[field.name]) ? focusOntextfield :''}
                name={name}
                error={Boolean(formErrors[field.name])}
                helperText={formErrors[field.name]}
                value={formData[name] || ''}
                onChange={handleNumberTypeChange}
                type={type}
                required={required}
                multiline={multiline}
                maxRows={max_lines}
                rows={max_lines}
                fullWidth
                margin="normal"
                defaultValue={0}
            />
        );
        }
        return (
            <TextField
                size={'small'}
                key={id}
                id={id}
                label={title}
                placeholder={inputPlaceholder}
                name={name}
                // helperText={(isError && (!formData[name] || formData[name] === undefined)) ?isError:false }
                // error={(isError && (!formData[name] || formData[name] === undefined) && required) ?isError:false }
                error={Boolean(formErrors[field.name])}
                helperText={formErrors[field.name]}
                inputRef={Boolean(formErrors[field.name]) ? focusOntextfield :''}
                value={formData[name] || ''}
                onChange={handleChange}
                type={type}
                required={required}
                multiline={multiline}
                maxRows={max_lines}
                rows={max_lines}
                fullWidth
                margin="normal"
                defaultValue={0}
            />
        );
    };

    const generateDropdownField = (field, index) => {
        const { id, dropDownName, title, required, options } = field;

        if(id === 'sdd19') { // COUNTRY
            return (
                <TextField
                    size={'small'}
                    key={id}
                    select
                    style={{ width: '100%' }}
                    label={title}
                    name={dropDownName}
                    value={formData[dropDownName] || ''}
                    error={Boolean(formErrors[dropDownName])}
                    helperText={formErrors[dropDownName]}
                    autoFocus={formErrors[dropDownName]}
                    onChange={(event) =>{
                        handleDropDownChange(event, dropDownName) 
                        handleCountryDropDownChange(event)}
                    }
                    margin="normal"
                    required={required}>
                        {
                            options.map((fieldOption)=>{
                               return (<MenuItem value={fieldOption.t_country} id={fieldOption.t_country_iso2} title={fieldOption.t_country} key={fieldOption.t_country_iso2}>{fieldOption.t_country} </MenuItem>)
                            })
                        }
                </TextField>
            );
        } else if (id === 'sdd17') { // states
            return (
                <TextField
                    size={'small'}
                    key={id}
                    select
                    style={{ width: '100%' }}
                    label={title}
                    name={dropDownName}
                    value={formData[dropDownName] || ''}
                    SelectProps={{ MenuProps: { disableScrollLock: true}}}
                    error={Boolean(formErrors[dropDownName])}
                    helperText={formErrors[dropDownName]}
                    autoFocus={formErrors[dropDownName]}
                    onChange={(event) =>{
                        handleDropDownChange(event, dropDownName) 
                        handleCountryDropDownChange(event)}
                    }
                    margin="normal"
                    required={required}>
                        {
                            options.map((fieldOption, index)=>{
                               return (<MenuItem value={fieldOption.value} id={fieldOption.id} title={fieldOption.value} key={index}>{fieldOption.value}</MenuItem>)
                            })
                        }
                </TextField>
            );
        } else {
            return (
                <TextField
                    size={'small'}
                    key={id}
                    select
                    style={{ width: '100%' }}
                    label={title}
                    name={dropDownName}
                    value={formData[dropDownName] || ''}
                    error={Boolean(formErrors[dropDownName])}
                    helperText={formErrors[dropDownName]}
                    autoFocus={formErrors[dropDownName]}
                    onChange={(event) => handleDropDownChange(event, dropDownName)}
                    margin="normal"
                    required={required}>
                    {options.map((option, i) => (
                        <MenuItem key={i} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            );
        }
    };

    const generateDropDownWithInput = (field, index) => {
        const { title, required, options, dropDownName, input1Name, input1Placeholder, inputType } = field;

        return (
            <Grid container spacing={2} key={field.id}>
                <Grid item xs={4}>
                    <TextField
                        size={'small'}
                        select
                        style={{ width: '100%' }}
                        label={title}
                        title={title}
                        name={dropDownName}
                        margin='dense'
                        value={formData[dropDownName] || ''}
                        className="Text_select"
                        onChange={handleChange}
                        required={required}>
                        {options.map((option, i) => (
                            <MenuItem key={i} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        size={'small'}
                        margin='dense'
                        name={input1Name}
                        label={input1Placeholder}
                        value={formData[input1Name] || ''}
                        onChange={handleChange}
                        type={inputType}
                        fullWidth
                    />
                </Grid>
            </Grid>
        );
    };

    const renderField = (field, index) => {
        const { dataType } = field;

        switch (dataType) {
            case INTAKE_FORM.INPUT:
                return generateInputField(field, index);
            case INTAKE_FORM.DROPDOWN:
                return generateDropdownField(field, index);
            case INTAKE_FORM.DROPDOWNWITHINPUT:
                return generateDropDownWithInput(field, index);
            case INTAKE_FORM.DATE_PICKER:
                return generateDatePickerField(field, index);
            default:
                return null;
        }
    };

    return (
        <form>
            <Box component="form" noValidate autoComplete="off">
                <Grid container spacing={1}>
                    {jsonData.map((field, index) => (
                        <Grid
                            item
                            xs={field.section === 1 || field.section === 22 ? 4 : field.section === 111 ? 3 : field.section === 1111 ? 8 : 12}
                            key={index}>
                            {renderField(field)}
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </form>
    );
};

export default IntakeInfoCustomForm;
