import React, { useEffect, useState } from 'react';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { isValidParam, getStringParam,  getIntParam, isEmpty, getArrayParam } from '../../../services/helper/parameterVerifier';
import ShowCircularProgress from '../components/circularProgress';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { saveCompanyProfile, getCompanyProfile } from '../../../services/actions/UserListAction';
import * as sfDialogs from '../components/sfDialogs';
import {getHome, setUserContextData } from '../../../services/actions/appActions';
import { setHeader } from '../../../services/actions/headerActions';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { phoneFormat, sortArrayObjectByProperty } from '../../../services/helper/utils';
import { Button, Chip, FormControl, TextField, Select, MenuItem, InputLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch, useSelector } from "react-redux";

const CompanyProfile = (object) => {

    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);

    const [inProgress, setInProgress] = useState(false);
    const [attachment, setAttachment] = useState(null);
    const [website_name, setWebsiteName] = useState(null);
    const [business_name, setBusinessName] = useState(null);
    const [business_address, setBusinessAddress] = useState(null);
    const [business_phone, setBusinessPhone] = useState(null);
    const [business_email, setBusinessEmail] = useState(null);
    const [corporate_currency, setCorporateCurrency] = useState([]);
    const [currency_id, setCurrencyId] = useState(null);
    const [currency_name, setCurrencyName] = useState(null);
    const [user_currency_id, setUserCurrencyId] = useState(null);
    const [logo_id, setLogoId] = useState(0);
    const [id, setId] = useState(0);
    const [isSavedClicked, setIsSavedClicked] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [country, setCountry] = useState(null);
    const [countryMenuItems, setCountryMenuItems] = useState([]);
    const [componentMounted, setComponentMounted] = useState(false);
    const [isFranchisor, setIsFranchisor] = useState(false);
    const [isFranchise, setIsFranchise] = useState(false);
    const [isShrinkedWebsite, setIsShrinkedWebsite] = useState(false);
    const [isShrinkedName, setIsShrinkedName] = useState(false);
    const [isShrinkedAdd, setIsShrinkedAdd] = useState(false);

    useEffect(() => {
        getCompanyProfiles();
        getCorporateCurrency();
        getDetails();
       dispatch(setHeader(object, getLocalizedStrings().label.CUSTOMIZATION_SETTING.COMPANY_PROFILE, null, false, true));
    }, []);

    const getCompanyProfiles = () => {
        let promise = Promise.resolve(getCompanyProfile());
        if (isValidParam(promise)) {
            promise.then((response) => {
                let businessName = "";
                let businessAddress = "";
                let businessPhone = "";
                let businessEmail = "";
                let currencyId = "";
                let websiteName = "";
                let fileName = "";
                let logoId = 0;
                let id = 0;
                let _isFranchisor = 0;
                let _isFranchise = 0;
                if (isValidParam(response)) {
                    businessName = getStringParam(response.business_name);
                    businessAddress = getStringParam(response.business_address);
                    businessPhone = getStringParam(response.business_phone);
                    businessEmail = getStringParam(response.business_email);
                    currencyId = getIntParam(response.currency_id);
                    websiteName = getStringParam(response.website_name);
                    fileName = getStringParam(response.file_name);
                    logoId = getIntParam(response.logo_id);
                    id = getIntParam(response.id);
                    _isFranchisor = getIntParam(response.is_franchisor);
                    _isFranchise = getIntParam(response.is_franchise);
                }
                setComponentMounted(true);
                setBusinessName(businessName);
                setBusinessAddress(businessAddress);
                setBusinessPhone(businessPhone);
                setBusinessEmail(businessEmail);
                setCurrencyId(currencyId);
                setCurrencyName(currencyId);
                setWebsiteName(websiteName);
                setLogoId(logoId);
                setId(id);
                setIsFranchisor(_isFranchisor);
                setIsFranchise(_isFranchise);
                setIsShrinkedWebsite(true);
                setIsShrinkedName(true);
                setIsShrinkedAdd(true);
            });
        }
    }

    const getCorporateCurrency = () => {
        let userCurrencyId = '';
        let userCurrencyName = '';
        let currencyId = '';
        let currencyItemList = [];
        let url = endPoints.CORPORATE_CURRENCY.GET_CURRENCY_LIST;
        let promise = Promise.resolve(HTTPClient.get(url));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response.currencyList)) {
                    response.currencyList.forEach((listItem, index) => {
                        if (isValidParam(response.userSavedCurrency) && isValidParam(response.userSavedCurrency.currencyId)) {
                            if (listItem.id === response.userSavedCurrency.currencyId) {
                                userCurrencyName = listItem.name + '(' + listItem.symbol + ')';
                                currencyId = response.userSavedCurrency.currencyId;
                            }
                        }
                        let obj = {};
                        obj.id = listItem.id;
                        obj.value = listItem.id;
                        obj.label = listItem.name + '(' + listItem.symbol + ')';
                        obj.title = listItem.name + '(' + listItem.symbol + ')';
                        currencyItemList.push(obj);
                    });
                } if (isValidParam(response.userSavedCurrency)) {
                    userCurrencyId = response.userSavedCurrency.id;
                }
                setComponentMounted(true);
                setCorporateCurrency(currencyItemList);
                setCurrencyName(currencyId);
                setUserCurrencyId(userCurrencyId);
                setCurrencyId(currencyId);
            });
        }
    }
    const getActions = () => {
        const actions = [
            <Button
                primary={true}
                onClick={() => {
                    onSave();
                }}
                style={styles.primaryButton}
                disabled={isSavedClicked}
            >{getLocalizedStrings().label.COMMON.SAVE}</Button>
        ];
        return actions;
    }
    const onChangeTextField = (field,event) => {
        let currentValue = event.target.value;
        if (field == 'website_name') {
            currentValue= currentValue.replace(/\s/g, '')
            setWebsiteName(currentValue);
        }else if(field == 'business_name'){
            setBusinessName(currentValue);
        }else if(field == 'business_address'){
            setBusinessAddress(currentValue);
        }else if(field == 'business_phone'){
            setBusinessPhone(currentValue);
        }else if(field == 'business_email'){
            setBusinessEmail(currentValue);
        }

        
    }

    const handleChange = (value) => {
        let types = corporate_currency;
        let data = types.filter(f => { return f.id == value.value });
        
        if (data.length > 0) {
            data = data[0];
        }
        setCurrencyName(data.value);
        setCurrencyId(data.id);
    }

    const onChange = (e) => {
        let file = {};
            file.fileName = e.target.files[0].name;
            file.file = e.target.files[0];
            if (file.file.size === 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_BLANK_FILE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                e.target.value = null;
                return false;
            } else {
                let fileName = file.fileName;
                fileName = fileName.replace(/'/g, "");
                let fileext = fileName.substring(fileName.lastIndexOf('.') + 1);
                if (fileext.toLowerCase() !== 'jpg' && fileext.toLowerCase() !== 'jpeg' && fileext.toLowerCase() !== 'gif' && fileext.toLowerCase() !== 'png') {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_LOGO_EXTENSION, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    e.target.value = null;
                    return false;
                }
            }

            setAttachment(file);
            setInProgress(false);
    }

    const restrictKey = (event) => {
            if (event.key === ' ') {
                return false;
            } else {
                return true;
            }
    }

  
    const isValidUrl = (url) => {
        let isValid = false;
        try {
            url = url.toLowerCase();
            if (isValidParam(url)) {
                var urlregstr = /((ht|f)tp(s?)\:\/\/|~\/|\/)?(\w+:\w+@)?(([-\w]+\.)+(com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum|travel|[a-z]{2}))(:[\d]{1,5})?(((\/([-\w~!$+|.,=]|%[a-f\d]{2})+)+|\/)+|\?|#)?((\?([-\w~!$+|.,*:]|%[a-f\d{2}])+=([-\w~!$+|.,*:=]|%[a-f\d]{2})*)(&([-\w~!$+|.,*:]|%[a-f\d{2}])+=([-\w~!$+|.,*:=]|%[a-f\d]{2})*)*)*(#([-\w~!$+|.,*:=]|%[a-f\d]{2})*)?/;
                var result = -1;
                result = url.search(urlregstr);
                if (result != "-1") {
                    isValid = true;
                }
            }
        } catch (error) {
            console.error("Error in 'parameterVerifier.js -> isUrl()':" + error);
        }
        return isValid;
    }
    const isEmail =(strEmail)=> {
        let isValid = true;
        try {
            if (isValidParam(strEmail)) {
                let arrEmail = strEmail.split(',');
                arrEmail.forEach( email => {
                    email = email.trim()
                    if(isValid && email != ''){
                        var emailregstr = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,})+$/;
                        var result = -1;
                        result = email.search(emailregstr);
                        if (result == "-1") {
                            isValid = false;
                            return;
                        }
                    }                
                });       
            }
        } catch (error) {
            console.error("Error in 'parameterVerifier.js -> isEmail()':" + error);
        }
        return isValid;
    }
    const onBlurShrink = () => {
        if (website_name == null || website_name == '') {
            setIsShrinkedWebsite(false);
        }
        if (business_name == null || business_name == '') {
            setIsShrinkedName(false);
          
        }
        if (business_address == null || business_address == '') {
            setIsShrinkedAdd(false);
        }
    }
    const onSave = () => {
            if (logo_id === 0) {

                if (!isValidParam(attachment) || attachment.file.size === 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.CHANGE_LOGO.VALIDATE_LOGO_IMAGE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                }

                let fileSize = attachment.file.size;
                if (fileSize > 0 && fileSize >= constants.MAX_IMAGE_FILE_SIZE_FOR_COMPANY_LOGO) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.CHANGE_LOGO.VALIDATE_LOGO_IMAGE_SIZE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                }
            } else {
                if (attachment !== null && attachment.hasOwnProperty('file')) {
                    let fileSize = attachment.file.size;
                    if (fileSize === 0 || (fileSize > 0 && fileSize >= constants.MAX_IMAGE_FILE_SIZE_FOR_COMPANY_LOGO)) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.CHANGE_LOGO.VALIDATE_LOGO_IMAGE_SIZE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        return false;
                    }
                }


            }


            let isWebsiteBlank = false;
            let websiteURL = getStringParam(website_name);
            if (isValidParam(websiteURL) && getStringParam(websiteURL)) {
                if (!isValidUrl(websiteURL)) {
                    let errorMsg = getLocalizedStrings().message.USEFUL_LINKS.VALID_URL;
                    dispatch(showCustomSnackBar(errorMsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;

                }
            } else {
                isWebsiteBlank = true;
            }

            if (!isValidParam(business_name) || isEmpty(business_name.trim())) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMPANY_PROFILE.BLANK_BUSINESS_NAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }

            if (!isValidParam(business_address) || isEmpty(business_address.trim())) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMPANY_PROFILE.BLANK_BUSINESS_ADDRESS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }

            if (!isValidParam(business_phone) || isEmpty(business_phone.trim())) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMPANY_PROFILE.BLANK_BUSINESS_PHONE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }

            if (isValidParam(business_email) && !isEmail(business_email)) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.VALIDATE_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }

            if (isWebsiteBlank) {
                (sfDialogs.confirm(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.CHANGE_LOGO.VALIDATE_LOGO_WEBSITE_URL, doSave.bind(websiteURL), null));

            } else {
                doSave(websiteURL);
            }

    }

   const handleCheckBoxInputChange = (isChecked, name) => {
        if (name === "isFranchisor") {
            if(app.me.industry_type != "Franchisor" && app.me.industry_type != "InXpressCountry" && app.me.industry_type != "Unit Base"){
                (sfDialogs.confirm(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "Can’t check this flag! You have not registered as a Franchise business. Contact BrandWide Support for additional help.", null, null));
            } else{
                setIsFranchisor(isChecked);
            }
        }
        else {
            setIsFranchise(isChecked);
        }
    }
   
   
    const doSave = () => {
            setInProgress(true);
            setIsSavedClicked(true);
            const formData = new FormData();
            let attachedObj = attachment;
            let websiteUrl = website_name.trim();
            let businessName = business_name.trim();
            let businessAddress = business_address.trim();
            let businessPhone = business_phone.trim();
            let businessEmail = business_email.trim();
            let currencyId = currency_id;
            let userCurrencyId = user_currency_id;
            let Country = country;

            let param = {};
            param.website = websiteUrl;
            param.businessName = businessName;
            param.businessAddress = businessAddress;
            param.businessPhone = businessPhone;
            param.businessEmail = businessEmail;
            param.currencyId = currencyId;
            param.userCurrencyId = userCurrencyId;
            param.is_franchisor = isFranchisor ? 1 : 0;
            param.is_franchise = isFranchise ? 1 : 0;
            if (Country !== getStringParam(app.me.tenant_country)) {
                param.country = Country;
            }


           if (isValidParam(attachedObj)) {
                param.file_name = attachedObj.fileName;
            }

            formData.append('input_param', JSON.stringify(param));

            if (isValidParam(attachedObj)) {
                formData.append('attached_file', attachedObj.file);
            }

            let promise = saveCompanyProfile(formData);
            promise.then((response) => {
                if (response.data.status === 0) {
                    let token = localStorage.getItem('token');
                    if (token !== null) {
                        dispatch(setUserContextData(token));
                    }
                    dispatch(getHome());
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                } else {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.CHANGE_LOGO.LOGO_IMAGE_UPLOAD_FAILURE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
                setInProgress(false);
                setIsSavedClicked(false);
            });
    }


    const getDetails = () => {
        let tempObj = null;
        let tempCountryMenuItems = null;
        let tempCountry =  null;
        tempObj = getArrayParam(app.countryISO2);
        tempCountryMenuItems = getCountryOptionsList(tempObj);
        tempCountry = getStringParam(app.me.tenant_country);
        setCountryList(tempObj);
        setCountryMenuItems(tempCountryMenuItems);
        setCountry(tempCountry);
    }

    const getCountryOptionsList = (countryList) => {
        let menuItems = [];
        countryList = getArrayParam(countryList);
        sortArrayObjectByProperty(countryList, 't_country');
        if (countryList !== null && countryList.length > 0) {
            countryList.forEach((fieldOption, i) => {
                menuItems.push(
                    <MenuItem value={fieldOption.t_country} id={fieldOption.t_country_iso2} title={fieldOption.t_country} key={fieldOption.t_country_iso2} style={styles.popoverMenuItem}>{fieldOption.t_country} </MenuItem>);
            });
        }
        return menuItems;
    }

    const setSelectedCountryValue = (event) => {
        let value = event.target.value;
        setCountry(value);

    }
    if (componentMounted) {
        let corporateCurrency = corporate_currency;
        let sfFormFieldContainerStyle = { ...styles.sfFormFieldContainer };
        let sfFormFieldTextAreaStyle = { marginTop: '10px', height: '90%' };
        let sfFormFieldTextAreaInputSyle = { marginTop: '10px', overflowY: 'auto' };
        let sfFormFieldStyle = { width: '100%', backgroundColor: '#fff', border: '1px solid #dddddd', marginBottom: '12px' };
        let businessPhone = phoneFormat(business_phone);
        return (
            <div style={{ marginBottom: '10px', paddingLeft: '3%', width: '65%' }}>
                <div style={{ ...styles.row }}>
                    <div style={{ ...styles.sf_12 }}>
                        <div style={{ fontSize: '24px', paddingBottom: '12px' }}> {getLocalizedStrings().label.CUSTOMIZATION_SETTING.COMPANY_PROFILE}</div>
                    </div>
                </div>

                <div style={{ ...styles.row, paddingBottom: '12px' }}>
                    <div style={{ ...styles.sf_3 }}><label>{getLocalizedStrings().label.COMPANY_PROFILE.UPLOAD_LOGO}</label></div>
                    <div style={{ ...styles.sf_9 }}> <input type="file" name="img_name" id="img_to_upload" onChange={(e) => onChange(e)} /></div>
                </div>
                <div style={{ ...styles.row, paddingBottom: '12px' }}>
                    <div style={{ ...styles.sf_12 }}>
                        <div className='dialogtxt'>
                            {getLocalizedStrings().message.CHANGE_LOGO.LOGO_IMAGE_ATTACHMENT_LOGO_TEXT}
                        </div>
                        <div className='dialogtxt' style={{ marginBottom: '0px' }}>
                            {getLocalizedStrings().message.CHANGE_LOGO.LOGO_IMAGE_SIZE_TEXT}
                        </div>
                    </div>
                </div>

                <div style={{ ...styles.row }}>
                    <div style={{ ...styles.sf_12 }}>
                        <FormControl style={{ width: '100%', marginBottom: "12px" }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                name="website_name"
                                id="website_to_visit"
                                value={website_name}
                                label={getLocalizedStrings().label.COMPANY_PROFILE.WEBSITE}
                                onChange={(event) => onChangeTextField('website_name',event)}
                                onKeyPress={(e) => restrictKey(e)}
                                inputprops={{ maxlength: '256' }}
                                autoComplete="new-password"
                                margin="dense"
                                className={"sf-fields-bg"}
                                size="small"
                                onFocus={() => setIsShrinkedWebsite(true)}
                                onBlur={onBlurShrink}
                                InputLabelProps={{
                                    shrink: isShrinkedWebsite,
                                }}
                            />
                        </FormControl>
                    </div>
                </div>

                <div style={{ ...styles.row }}>
                    <div style={{ ...styles.sf_12 }}>
                        <FormControl style={{ width: '100%', marginBottom: "12px" }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                key="bus_name"
                                label={getLocalizedStrings().label.COMPANY_PROFILE.BUSINESS_NAME}
                                name="business_name"
                                value={business_name}
                                onChange={(event) => onChangeTextField('business_name', event)}
                                inputprops={{ maxlength: '255' }}
                                autoComplete="new-password"
                                margin="dense"
                                className={"sf-fields-bg"}
                                size="small"
                                onFocus={() => setIsShrinkedName(true)}
                                onBlur={onBlurShrink}
                                InputLabelProps={{
                                    shrink: isShrinkedName,
                                }}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className="row" style={{ ...styles.row, padding: '1 12 1 12' }}>
                    <div style={{ ...styles.sf_12 }}>
                        <FormControl style={{ width: '100%', marginBottom: "12px" }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                key="bus_add"
                                type='textarea'
                                multiline={true}
                                rows="5"
                                label={getLocalizedStrings().label.COMPANY_PROFILE.BUSINESS_ADDRESS}
                                onChange={(event) => onChangeTextField('business_address', event)}
                                inputStyle={sfFormFieldTextAreaInputSyle}
                                value={business_address}
                                inputprops={{ maxlength: '1024' }}
                                autoComplete="new-password"
                                margin="dense"
                                className={"sf-fields-bg"}
                                onFocus={() => setIsShrinkedAdd(true)}
                                onBlur={onBlurShrink}
                                InputLabelProps={{
                                    shrink: isShrinkedAdd,
                                }}
                            />
                        </FormControl>
                    </div>
                </div>
                <div style={{ ...styles.row }}>
                    <div style={{ ...styles.sf_12 }}>
                        <FormControl style={{ width: '100%', marginBottom: "12px" }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                key="bus_phn"
                                inputStyle={styles.textField.input}
                                label={getLocalizedStrings().label.COMPANY_PROFILE.BUSINESS_PHONE}
                                fullWidth={true}
                                name="business_phone"
                                value={businessPhone}
                                onChange={(event) => onChangeTextField('business_phone', event)}
                                inputprops={{ maxlength: '255' }}
                                autoComplete="new-password"
                                margin="dense"
                                className={"sf-fields-bg"}
                                size="small"
                            />
                        </FormControl>
                    </div>
                </div>
                <div style={{ ...styles.row }}>
                    <div style={{ ...styles.sf_12 }}>
                        <FormControl style={{ width: '100%' }} className="bussiness" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                key="bus_email"
                                inputStyle={styles.textField.input}
                                label={getLocalizedStrings().label.COMPANY_PROFILE.BUSINESS_EMAIL}
                                fullWidth={true}
                                name="business_email"
                                value={business_email}
                                onChange={(event) => onChangeTextField('business_email', event)}
                                inputprops={{ maxlength: '255' }}
                                autoComplete="new-password"
                                margin="dense"
                                className={"sf-fields-bg"}
                                size="small"
                            />
                        </FormControl>
                    </div>
                </div>
                <div style={{ ...styles.row }}>
                    <div style={{ ...styles.sf_12 }}>
                        <FormControl variant="outlined" size="small" style={{ width: '100%', marginBottom: "12px", marginTop: "20px", height: "42px" }}>
                            <InputLabel id="sf-company-profile-from-companyprofile-simple-select-outlined-label" className="sf-company-profile-from-companyprofile">{getLocalizedStrings().label.UNITS.COUNTRY_SELECT}</InputLabel>
                            <Select
                                label={getLocalizedStrings().label.UNITS.COUNTRY_SELECT}
                                labelId='sf-label-country'
                                id='sf-country'
                                value={country}
                                onChange={(event)=>setSelectedCountryValue(event)}
                                style={{ height: '40px' }}
                                className={"sf-fields-bg"}
                            >
                                {countryMenuItems}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div style={{ ...styles.row }}>
                    <div style={{ ...styles.sf_12 }}>
                        <FormControl style={{ width: '100%', height: '40px', marginTop: '1px' }} className="test" noValidate autoComplete="off">
                            {corporate_currency.length > 0 && <Autocomplete
                                defaultValue={corporate_currency.find(v => v.value === currency_name)}
                                options={corporate_currency}
                                onChange={(e,newValue) => handleChange(newValue)}
                                getOptionLabel={(option) => option.label}
                                disableClearable={false}
                                style={{ height: '40px' }}
                                renderInput={(params) => <TextField {...params} label={getLocalizedStrings().label.CORPORATE_CURRENCY.SELECT_CURRENCY_NAME} variant="outlined" margin="dense" className={"sf-fields-bg"} size="small"/>}
                            />
                            }

                        </FormControl>
                    </div>
                </div>
                <div style={{ marginLeft: '12px' }}>
                            <box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }} style={{}}>
                                <div style={{ ...styles.sf_12 }}> <FormControlLabel control={<Checkbox color="default" checked={isFranchisor} value={isFranchisor} name="isFranchisor" onChange={(e) => handleCheckBoxInputChange(e.target.checked, "isFranchisor")} defaultChecked={true} />} label="Franchisor" style={{ marginTop: '15px' }} />
                                </div>
                                <div style={{ ...styles.sf_12 }}>
                                    <FormControlLabel label="Franchise" control={<Checkbox color="default" checked={isFranchise} value={isFranchise} name="isFranchise" onChange={(e) => handleCheckBoxInputChange(e.target.checked, "isFranchise")} defaultChecked={false} />} style={{}} />

                                </div>
                            </box>
                        </div>
                <div className="row" style={{ ...styles.row, marginBottom: '25px', marginTop: '25px', marginRight: '-10px' }}  >
                    <div style={{ ...styles.sf_12, textAlign: 'right' }}>
                        {getActions()}
                    </div>
                </div>

            </div>
        );

    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }

}
export default CompanyProfile;
