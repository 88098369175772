import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Icon, IconButton, Menu, MenuItem, Radio, RadioGroup } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { getListViewSetupData, getSetupViewFormFields, refreshListView, saveListViewSetupData, setListViewMounted } from '../../../services/actions/listViewAcions';
import { refreshTreeView } from '../../../services/actions/treeViewAcions';
import * as ParameterVerifier from '../../../services/helper/parameterVerifier';
import { getArrayParam, getBooleanParam, getObjectParam, getStringParam, isValidParam } from '../../../services/helper/parameterVerifier';
import concat from 'lodash/concat';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { constants } from '../../../services/constants/constants';
import { getMultilingualLabelName, makeTiteleCase } from '../../../services/helper/utils';
import { getActiveTab, getActiveTabInfo, TYPE_COLUMN_VIEW, TYPE_DETAIL_VIEW, TYPE_LIST_VIEW, updateActiveTab, updateTabInfo } from '../../../services/helper/sfTabManager';
import { getChildObjectInfo, getObjectLabelByObject, getSelectedObjectLabel } from '../../../services/helper/common';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { refreshColumnView } from '../../../services/actions/listsActions';
import DraggableSelect from '../../sfPlugins/draggableSelect.tsx';
import ShowCircularProgress from '../components/circularProgress';
import { getCookie, setCookie } from "../../../services/helper/sfCookies";
import { getDetailviewData, isDetailViewSet, detailViewIsMounted, refreshDetailViewData, refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';

/**
 * Author   : Pradyut
 * Date:    : 2017-08-03
 */

const maxLimit = 30;
const cvMaxLimit = 5;
const ListViewSetup = ({ object, isDetailView, suffix,isDuplicate, showWorkflowList, objectList, closeEvent, info, columnView ,clearSearch}) => {

	const [mounted, setMounted] = useState(false);
	const [isSetupSelectorLoading, setIsSetupSelectorLoading] = useState(true);
	const [setupSelectedFieldOptions, setSetupSelectedFieldOptions] = useState([]);
	const [setupAvailableFieldOptions, setSetupAvailableFieldOptions] = useState([]);
	const [sortFieldName, setSortFieldName] = useState('');
	const [sortFieldLabel, setSortFieldLabel] = useState('');
	const [isOpenSortFieldMenu, setIsOpenSortFieldMenu] = useState(false);
	const [isDescendingChecked, setIsDescendingChecked] = useState(null);
	const [isShowPictureChecked, setIsShowPictureChecked] = useState(null);
	const [isShowTotalChecked, setIsShowTotalChecked] = useState(false);
	const [pageSize, setPageSize] = useState(0);
	const [labelName, setLabelName] = useState(null);
	const [childObjects, setChildObjects] = useState(null);
	const [childObjectSelectedField, setChildObjectSelectedField] = useState(null);
	const [groupByFieldName, setGroupByFieldName] = useState('');
	const [groupByFieldLabel, setgroupByFieldLabel] = useState('');
	const [isOpenGroupByFieldMenu, setIsOpenGroupByFieldMenu] = useState(false);
	const [groupByFieldOptions, setGroupByFieldOptions] = useState([]);
	const [isWorkflowSetup, setIsWorkflowSetup] = useState(false);
	const [isWorkflowStageSelectorLoading, setIsWorkflowStageSelectorLoading] = useState(true);
	const [selectedWorkflowStages, setSelectedWorkflowStages] = useState([]);
	const [availableWorkflowStages, setAvailableWorkflowStages] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [childObjectList, setChildObjectList] = useState([]);
	const [groupByFieldNameSort, setGroupByFieldNameSort] = useState('');
	const [selectedWorkFlowId, setselectedWorkFlowId] = useState(-9999);
	const [isOpenWorkFlowFieldMenu, setIsOpenWorkFlowFieldMenu] = useState(false);
	const [selectedWorkFlowLabel, setSelectedWorkFlowLabel] = useState(getLocalizedStrings().label.DETAIL_VIEW.NO_SETUP_OPTION);
	const _lists = useSelector(state => state.lists);

	const getListViewInstanceFromRedux = (state, isDetailView, isDuplicate) => {
		let tabInfo = getActiveTabInfo();
		let isReport = getBooleanParam(tabInfo.isReport);
		if (getBooleanParam(isDetailView)) {
			return state.childListView;
		} else if (getBooleanParam(isReport)) {
			return state.otherListView;
		} else if (getBooleanParam(isDuplicate)) {
			return state.duplicateListView;
		} else {
			return state.listView;
		}
	}

	const app = useSelector((state) => state.app);
	const listView = useSelector((state) => getListViewInstanceFromRedux(state, isDetailView, isDuplicate));
	const sfForm = useSelector((state) => state.sfForm);
	const workflowList = useSelector((state) => state.lists.wokflowList);

	const dispatch = useDispatch();

	useEffect(() => {
		getListViewSetupDataParam();
	}, []);


	function getListViewSetupDataParam() {
		try {
			let params = {};
			if (columnView) {
				let info = getObjectParam(getActiveTabInfo());
				let filter = getObjectParam(info.filter);
				let queryId = getStringParam(filter.id);
				let queryName = getStringParam(filter.name);
				let queryType = getStringParam(filter.type);
				let isWorkflowSetup = (info.workflowId && info.workflowId <= 0 && (showWorkflowList || object === constants.CONTACTS_OBJECT));
				params.query_name = queryName;
				params.query_id = queryId;
				params.query_type = queryType;
				params.is_column_view = columnView;
				params.isWorkflowSetup = isWorkflowSetup;
				if (object === constants.CONTACTS_OBJECT) {
					params.is_workflow = getBooleanParam(info.is_workflow);
				}
				setIsWorkflowSetup(isWorkflowSetup);
			}else if(suffix === constants.TASK_LIST){
				let info = getObjectParam(getActiveTabInfo());
				let filter = getObjectParam(info.filter);
				params.query_name = filter.query_name == "My Tasks" || filter.query_name == "All Tasks" ?"All records" : filter.query_name;
				params.query_id = filter.query_id;
				params.query_type = filter.query_type;
				params.parent_object = filter.parent_object;
			} else {
				params.query_name = listView.data.query_name;
				params.query_id = listView.data.query_id;
				params.query_type = listView.data.query_type;
				params.parent_object = listView.data.parent_object;
			}

			let tempObject = object;
			if (object === constants.UNITS_OBJECT) {
				tempObject = constants.ACCOUNTS_OBJECT;
			}
			if (object === "broadcast-campaigns" && listView.data.object === "ab_campaigns") {
				tempObject = constants.AB_CAMPAIGNS_OBJECT;
			}

			const promise = Promise.resolve(getListViewSetupData(tempObject, params));
			if (ParameterVerifier.isValidParam(promise)) {
				promise.then((response) => processSetupData(response));
			}
		} catch (e) {
			console.error("Error in 'listViewSetup.js -> getListViewSetupData()':" + e);
		}
	}

	function processSetupData(data) {
		try {
			let availableFields = data.available_fields;
			let selectedFields = data.selected_fields;
			let activeTab = getObjectParam(getActiveTab());

			let groupByFields = data.hasOwnProperty('group_by_fields') ? data.group_by_fields : [];
			let groupByFieldName = data.hasOwnProperty('group_by_field_name') && columnView ? data.group_by_field_name : data.hasOwnProperty('group_by_field_name') && listView.hasOwnProperty("groupByInfo") && listView.groupByInfo.group_by_field !== data.group_by_field_name ? listView.groupByInfo.group_by_field : data.group_by_field_name;
			let selectedWorkFlowId = initWorkflowId(workflowList)
			selectedWorkFlowId = selectedWorkFlowId ? selectedWorkFlowId: -9999;
			let _groupByFieldNameSort = data.hasOwnProperty('group_by_field_name_sort') ? data.group_by_field_name_sort : null;
			let groupByFieldLabel = null;
			let selectedWFStages;
			let tempWorkflowList = workflowList || [];
			let selectedWorkFlowArray = tempWorkflowList.filter(e => e.id === selectedWorkFlowId && e.id !== -9999);
			let selectedWorkFlowLabel = selectedWorkFlowArray.length > 0 ? selectedWorkFlowArray[0].name : getLocalizedStrings().label.DETAIL_VIEW.NO_SETUP_OPTION;

			let availableWFStages = data.hasOwnProperty('workflow_stage_available_fields') && data.workflow_stage_available_fields && data.workflow_stage_available_fields.length > 0 ? data.workflow_stage_available_fields : [];

			if (data.hasOwnProperty('workflow_stage_fields')) {
				selectedWFStages = data.workflow_stage_fields;
			} else {
				selectedWFStages = availableWFStages;
			}

			if (selectedWFStages && selectedWFStages.length > 0) {
				selectedWFStages = selectedWFStages.map((field) => ({
					value: field.name,
					label: field.name,
					title: field.name,
					name: field.name
				}));
			}

			if (availableWFStages && availableWFStages.length > 0) {
				availableWFStages = availableWFStages.map((field) => ({
					value: field.name,
					label: field.name,
					title: field.name,
					name: field.name
				}));
			}

			let tempSelectedFields = [];
			let isSortFieldSelected = false;
			selectedFields.forEach((field, index) => {
				field.value = field.name;
				field.label = getMultilingualLabelName(object, field.label);
				field.title = getMultilingualLabelName(object, field.label); //To show the title of selected fields //Sourav 26-02-2018
				if (columnView && index > 4) {
					if (!isSortFieldSelected && data.sort_field_name === field.name) {
						field.className = "column-setup-view-field";
						tempSelectedFields.push(field);
						isSortFieldSelected = true;
					} else {
						availableFields.push(field);
					}
				} else {
					if (data.sort_field_name === field.name) {
						isSortFieldSelected = true;
					}
					tempSelectedFields.push(field);
				}
			});

			selectedFields = tempSelectedFields;

			availableFields.forEach((field) => {
				field.value = field.name;
				field.label = getMultilingualLabelName(object, field.label);
			});

			if (groupByFields && groupByFields.length > 0) {
				groupByFields.sort((a, b) => a.label.localeCompare(b.label));
				groupByFields.forEach((field) => {
					field.value = field.name;
					field.label = getMultilingualLabelName(object, field.label);
				});

				if (groupByFieldName == null || groupByFieldName === "") {
					groupByFieldName = 't_status';
				}
				if (_groupByFieldNameSort == null || _groupByFieldNameSort === "") {
					_groupByFieldNameSort = 'count';
				}
				if (selectedWorkFlowId == null || selectedWorkFlowId === "") {
					selectedWorkFlowId = -9999;
				}
				let groupByField = groupByFields.find((f) => f.name === groupByFieldName);
				groupByFieldLabel = isValidParam(groupByField) ? groupByField.label : '';
			}

			availableFields = concat(availableFields, selectedFields);
			if (object === constants.TASKS) {
				availableFields = availableFields.filter((item) => ( item.name !== 't_attachment' && item.name !== 't_unit_owner'))
			}else if (object === constants.REPORTED_SALES_OBJECT) {
				availableFields = availableFields.filter((item) => ( item.name !== 't_attachment'))
			}
			let sortFieldName = data.sort_field_name;
			if (!sortFieldName || sortFieldName === '') {
				sortFieldName = 't_status';
			}
			let sortField = availableFields.find(function (f, index) {
				return f.name === sortFieldName
			});
			let sortFieldLabel = isValidParam(sortField) ? sortField.label : '';
			let tempObject = object;
			if (tempObject === constants.UNITS_OBJECT) {
				tempObject = constants.ACCOUNTS_OBJECT;
			}
			sortFieldLabel = getMultilingualLabelName(tempObject, sortFieldLabel);

			let childObjectSelectedField = {};
			let childObjects = getArrayParam(data.child_objects);
			while (childObjects.length < 2) {
				childObjects.push({ object: '', selected_fields: [], available_fields: [] });
			}
			childObjects.forEach(el => {
				if (isValidParam(el) && getStringParam(el.object) !== '') {
					childObjectSelectedField[el.object] = el.selected_fields;
				}
			});
			let childObjectList = getArrayParam(data.child_object_list);
			childObjectList.push({ id: -9999, name: 'NONE', label: '<None>' });
			setChildObjectSelectedField(childObjectSelectedField);
			setSetupAvailableFieldOptions(availableFields);
			setSetupSelectedFieldOptions(selectedFields);
			setIsSetupSelectorLoading(false);
			setSortFieldName(data.sort_field_name);
			setSortFieldLabel(sortFieldLabel);
			setIsDescendingChecked(data.sort_type === 'desc');
			setIsShowPictureChecked(data.is_show_pic);
			setPageSize(data.page_size);
			setIsShowTotalChecked(data.is_show_total);
			setChildObjectList(childObjectList);
			setChildObjects(childObjects);
			setGroupByFieldOptions(groupByFields);
			setGroupByFieldName(groupByFieldName);
			setgroupByFieldLabel(groupByFieldLabel);
			setSelectedWorkflowStages(selectedWFStages);
			setAvailableWorkflowStages(availableWFStages);
			setIsWorkflowStageSelectorLoading(false);
			setselectedWorkFlowId(selectedWorkFlowId);
			setSelectedWorkFlowLabel(selectedWorkFlowLabel);
			if(activeTab.info?.filter?.type == "filter" && activeTab.info.hasOwnProperty('groupByFieldNameSort')){
				_groupByFieldNameSort = activeTab.info?.groupByFieldNameSort
			}
			setGroupByFieldNameSort(_groupByFieldNameSort);

		} catch (e) {
			console.error("Error in 'listViewSetup.js -> processSetupData()':" + e);
		}
	}

	function initWorkflowId(tempWorkflowList) {
		let workflowId = -9999;
		try {
			
			let cookieName = 'select-' + object + '-workflow-' + app.me.projectId + '-' + app.me.id;
			let workflowIdFromCookie = getCookie(cookieName);
			if (workflowIdFromCookie) {
				const lastWorkflowId = parseInt(workflowIdFromCookie);
				if (lastWorkflowId !== -9999) {
					let workflowListtemp = tempWorkflowList.filter((obj) => obj.id === lastWorkflowId);
					if (workflowListtemp.length > 0) {
						workflowId = lastWorkflowId;
					}
				}
			}
			handleWorkflowIdChange(workflowId);
		} catch (error) {
			console.error("Error in 'columnView.js -> initWorkflowId()':" + error);
		}
		return workflowId;
	}

	const handleWorkflowIdChange = (id) => {
		if (id !== -9999) {
			id = ParameterVerifier.getIntParam(id);
		} else {
			id = parseInt(id, 10);
		}
		setselectedWorkFlowId(id);

		let info = getActiveTabInfo();
		info.workflowId = id;

		// let cookieName = 'select-' + object + '-workflow-' + app.me.projectId + '-' + app.me.id;
		// setCookie(cookieName, id);
	}

	function setupSave() {
		try {
			let label;
			let objectName = null;
			if (object === constants.SOLUTIONS_OBJECT) {
				label = makeTiteleCase(object);
			} else if (
				object === constants.AB_CAMPAIGNS_OBJECT ||
				object === constants.AUTOMATION_DESIGNER_OBJECT) {
				objectName = object.replace('-', ' ');
				label = objectName.charAt(0).toUpperCase() + objectName.slice(1);
			} else if (object === constants.BROADCAST_CAMPAIGNS_OBJECT && listView.data.query_name === 'A/B Tests') {
				label = 'Ab campaigns';
			} else if (object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
				label = 'Broadcast campaigns ';
			} else if (object === "opportunities") {
				label = 'Opportunities';
			} else if (object === constants.GROUP_OBJECT) {
				let activeTab = getObjectParam(getActiveTab());
				let activeTabObject = getObjectParam(activeTab.object);
				label = makeTiteleCase(activeTabObject);
			}
			else if (object === constants.ACCOUNTS_OBJECT) {
				label = 'Accounts';
			}
			else if (object === constants.CASES_OBJECT) {
				label = 'Cases';
			}
			else if (object === constants.PROJECTS_OBJECT) {
				label = 'Projects';
			}
			else if (object === constants.CONTACTS_OBJECT) {
				label = ' Contacts ';
			} else {
				label = labelName;
			}
			if (setupSelectedFieldOptions && setupSelectedFieldOptions.length === 0) {
				dispatch(showCustomSnackBar(getLocalizedStrings().message.LIST_VIEW.NOT_ADDED + ' ' + label + ' ' + getLocalizedStrings().message.LIST_VIEW.FIELDS_TO_VIEW, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
			} else if (setupSelectedFieldOptions && setupSelectedFieldOptions.length === 1) {
				let snackbarStyle = { ...styles.snackBarStyleLongMsg, left: styles.snackBarStyleLongMsg.left + 70 };
				dispatch(showCustomSnackBar(getLocalizedStrings().message.LIST_VIEW.SETUP_MINIMUM_FIELD_ALERT, styles.snackbarBodyStyleError, snackbarStyle));
			} else if (columnView && object === constants.CONTACTS_OBJECT && selectedWorkflowStages && selectedWorkflowStages.length === 0 && isWorkflowSetup) {
				dispatch(showCustomSnackBar(getLocalizedStrings().message.LIST_VIEW.STATUS_FIELD_NOT_ADDED_FOR_VIEW, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
			} else if (setupSelectedFieldOptions && setupSelectedFieldOptions.length > 1) {
				let queryId;
				let queryName;
				let queryType;
				let tempPageSize = 0;
				if (columnView) {
					let info = getObjectParam(getActiveTabInfo());
					let filter = getObjectParam(info.filter);
					queryId = getStringParam(filter.id);
					queryName = getStringParam(filter.name);
					queryType = getStringParam(filter.type);
					tempPageSize = pageSize;
				}else if(suffix === constants.TASK_LIST){
					let info = getObjectParam(getActiveTabInfo());
					let filter = getObjectParam(info.filter);
					queryName = filter.query_name == "My Tasks" || filter.query_name == "All Tasks" ?"All records" : filter.query_name;
					queryId = filter.query_id;
					queryType = '-1';
					tempPageSize = listView.data.page_size;
				} else {
					queryName = listView.data.query_name;
					queryId = listView.data.query_id;
					queryType = listView.data.query_type;
					tempPageSize = listView.data.page_size;
				}


				let params = {};
				params.query_name = queryName;
				params.query_type = queryType;
				params.query_id = queryId;
				params.page_size = tempPageSize;
				params.sort_field_name = sortFieldName;
				params.sort_type = isDescendingChecked ? 'desc' : 'asc';
				params.is_show_pic = isShowPictureChecked;
				params.is_show_total = isShowTotalChecked;
				if (columnView) {
					params.group_by_field_name = groupByFieldName;
					params.callFrom = 'column_view';
					params.isWorkflowSetup = isWorkflowSetup;
					params.group_by_field_name_sort = groupByFieldNameSort;
					let selectedWorkFlowId = initWorkflowId(workflowList)
					params.workflow_id = selectedWorkFlowId ? selectedWorkFlowId : -9999;
					if (params.workflow_id === -9999) {
      					params.is_call_for_all = true;
    				}
				} else {
					params.group_by_field_name = groupByFieldName;
					params.group_by_field_name_sort = groupByFieldNameSort;
				}

				params.field_width_info = [...setupSelectedFieldOptions];

				if (isWorkflowSetup) {
					params.workflow_stage_info = selectedWorkflowStages;
					let info = getObjectParam(getActiveTabInfo());
					info.isWorkflowSetup = true;
					if (object === constants.CONTACTS_OBJECT) {
						params.is_workflow = getBooleanParam(info.is_workflow);
					}
				}

				let tempObject = object;
				if (tempObject === constants.UNITS_OBJECT) {
					tempObject = constants.ACCOUNTS_OBJECT;
				}
				if (tempObject === constants.BROADCAST_CAMPAIGNS_OBJECT && listView.data.query_name === 'A/B Tests') {
					tempObject = constants.AB_CAMPAIGNS_OBJECT;
				}
				let promise = Promise.resolve(saveListViewSetupData(tempObject, params));
				if (isValidParam(promise)) {
					if (columnView) {
						promise.then((response) => {
							if (isValidParam(response)) {
								if (response.status === 0) {
									_lists.sort_type = isDescendingChecked ? 'desc' : 'asc';
									let tab = getActiveTab();
									let info = getObjectParam(tab.info);
									if (selectedWorkFlowId !== -9999) {
										info.input_param_sql = "workflow_id = " + selectedWorkFlowId;
									} else {
										delete info.input_param_sql;
									}
									info.workflowId = selectedWorkFlowId;
									info.group_by_field_name = groupByFieldName;
									info.group_by_field_name_sort = groupByFieldNameSort;
									updateTabInfo(info);
									dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
									refreshColumnViewData();
									onCallCloseEvent();
										clearSearch()
									

								} else {
									dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
								}
							} else {
								dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
							}
						});
					} else {
						promise.then((response) => {
							if (isValidParam(response)) {
								if (response.status === 0) {
									let tab = getActiveTab();
									if(tab.info.input_param && object !== constants.CASES_OBJECT && object !== constants.PROJECTS_OBJECT 
										&& object !== constants.ISSUES_OBJECT){
										tab.info.input_param.sort_type = isDescendingChecked ? 'desc' : 'asc';
										tab.info.input_param.sort_field_name = sortFieldName;
										tab.info.input_param.query_type = 'all';
										tab.info.input_param.selectedPageNo = 1;
										tab.info.selectedPageNo = 1;
									}
									updateActiveTab(tab);
									dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
									if (object === constants.UNITS_OBJECT) {
										setListViewMounted(false, object);
										setListViewMounted(false, tempObject);
										refreshListView(object);
									}
									if (object === 'refund') {
										dispatch(detailViewIsMounted(false));
										dispatch(isDetailViewSet(false));
										dispatch(refreshDetailViewData(true));
									}
									refreshListViewData();
									onCallCloseEvent();
								} else {
									dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
								}
							} else {
								dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
							}
						});
					}
				}
			}
		} catch (e) {
			console.error("Error in 'listViewSetup.js -> setupSave()':" + e);
		}
	}
	const onCallCloseEvent = () => {
		closeEvent();
	}
	const onSaveCloseEvent = () => {
		if (setupSelectedFieldOptions.length > 0) {
			closeEvent();
		}

	}
	const refreshListViewData = () => {
		try {
			let params = {};
			if (getBooleanParam(isDetailView)) {
				params.isDetailView = true;
			}
			let activeTab = getActiveTab();
			activeTab = getObjectParam(activeTab);
			let info = getObjectParam(activeTab.info);
			if (!info?.isReport) {
				info.groupByFieldName = groupByFieldName;
			}
			updateTabInfo(info);
			if (object === constants.UNITS_OBJECT) {
				params.group_by_field_name = groupByFieldName;
				params.group_by_field_name_sort = groupByFieldNameSort;
				params.callFrom = "setup";
				refreshListView(object, params);
				refreshTreeView(true);
			} else {
				if (info?.isReport === false || info?.isReport === undefined) {
					params.group_by_field_name = groupByFieldName;
					params.group_by_field_name_sort = groupByFieldNameSort;
					params.callFrom = "setup";
				}
				if (info.object === "ab-campaigns") {
					object = constants.AB_CAMPAIGNS_OBJECT;
				}
				if (getBooleanParam(isDetailView)) {
					dispatch(refreshDetailViewListViewData(true));
				} else {
					refreshListView(object, params,false,suffix);
				}
			}
		} catch (e) {
			console.error("Error in 'listViewSetup.js -> refreshListView()':" + e);
		}
	}

	const refreshColumnViewData = () => {
		try {
			refreshColumnView(object);
			setListViewMounted(false, object);
		} catch (e) {
			console.error("Error in 'listViewSetup.js -> refreshColumnView()':" + e);
		}
	}

	const getTotalSelectedFieldCount = () => {
		let totalCount = 0;
		try {
			totalCount = getArrayParam(setupSelectedFieldOptions).length;
			let tempChildObjectSelectedField = getObjectParam(childObjectSelectedField);
			Object.values(tempChildObjectSelectedField).forEach(element => {
				totalCount += getArrayParam(element).length;
			});

		} catch (error) {
			console.error("Error in 'listViewSetup.js -> getTotalSelectedFieldCount()':" + error);
		}
		return totalCount;
	}

	const handleSelectChange = (options) => {
		let totalSelectedFieldCount = getTotalSelectedFieldCount();
		let isValid = false;
		let tempSortFieldName = sortFieldName;
		let tempSortFieldLabel = sortFieldLabel;
		try {
			let selectedLength = getArrayParam(setupSelectedFieldOptions).length;
			let isColumnViewValid = true;
			if (isValidParam(options) && selectedLength > options.length) {
				isValid = true;
			} else if (isValidParam(options) && columnView) {
				if (totalSelectedFieldCount < cvMaxLimit) {
					isValid = true;
				} else {
					isColumnViewValid = false;
				}
			} else if (isValidParam(options) && totalSelectedFieldCount < maxLimit) {
				isValid = true;
			} else if (totalSelectedFieldCount > maxLimit) {
				isValid = false;
				isColumnViewValid = false;
			}

			if (isValid) {
				let tempOptions = options.filter(f => {
					return f.name === tempSortFieldName
				});
				if (tempOptions.length === 0 && options.length > 0) {
					tempSortFieldName = options[0].name;
					tempSortFieldLabel = options[0].label;
				}
				let arrSlectedObj = options.map((obj, index) => {
					let tempObj = {
						name: obj.name,
						label: getMultilingualLabelName(object, obj.label),
						value: obj.value,
						width: obj.width,
						title: obj.label, //To show the title of selected fields //Sourav 26-02-2018
					};
					if (columnView && index > 4) {
						tempObj.className = "column-setup-view-field";
					}
					return tempObj;
				});

				setSetupSelectedFieldOptions(arrSlectedObj);
				setSortFieldLabel(tempSortFieldLabel);
				setSortFieldName(tempSortFieldName);
			} else {
				if (!isColumnViewValid) {
					dispatch(showCustomSnackBar(getLocalizedStrings().message.LIST_VIEW.SELECT_MAXIMUM_FIELD_FOR_COLUMN_SETUP, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
				} else {
					dispatch(showCustomSnackBar(getLocalizedStrings().message.LIST_VIEW.SELECT_MAXIMUM_FIELD_FOR_LISTVIEW_SETUP, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
				}
			}
		} catch (e) {
			console.error("Error in 'listViewSetup.js -> handleSelectChange()':" + e);
		}
	}

	const handleSortFieldMenuOpen = (event) => {
		event.preventDefault();
		setIsOpenSortFieldMenu(true);
		setAnchorEl(event.currentTarget);
	}

	const handleGroupFieldMenuOpen = (event) => {
		event.preventDefault();
		setIsOpenGroupByFieldMenu(true);
		setAnchorEl(event.currentTarget);
	}

	function handleWorkFlowFieldMenuOpen(event) {
		event.preventDefault();
		setIsOpenWorkFlowFieldMenu(true);
		setAnchorEl(event.currentTarget);
	}

	const sortFieldMenuClose = () => {
		setIsOpenSortFieldMenu(false);
	}

	const groupFieldMenuClose = () => {
		setIsOpenGroupByFieldMenu(false);

	}

	const workFlowFieldMenuClose = () => {
		setIsOpenWorkFlowFieldMenu(false);
	}


	const sortFieldMenuChange = (e, value) => {
		try {
			let sortFieldLabel = setupSelectedFieldOptions.find((f) => f.name === value);
			setSortFieldName(value);
			setSortFieldLabel(sortFieldLabel.label || '');
			setIsOpenSortFieldMenu(false);
		} catch (e) {
			console.error("Error in 'listViewSetup.js -> sortFieldMenuChange()':" + e);
		}
	}

	const handleDescendingCheckBox = (event, isChecked) => {

		setIsDescendingChecked(isChecked);
	}

	const handleShowPictureCheckBox = (event, isChecked) => {

		setIsShowPictureChecked(isChecked);
	}

	const handleShowTotalCheckBox = (event, isChecked) => {

		setIsShowTotalChecked(isChecked);
	}

	const onSortEnd = (selectedOptions) => {
		setSetupSelectedFieldOptions(selectedOptions);
	}

	const generateSortFieldMenuItem = () => {
		let themeColor = app.me.background;
		let menuItemHtml = [];
		try {
			menuItemHtml = setupSelectedFieldOptions.map((m, index) => (
				<MenuItem
					key={'status-' + index + '-' + m.name}
					value={m.name} style={styles.popoverMenuItem}
					onClick={(e) => sortFieldMenuChange(e, m.name)}
				>
					<span style={{ color: m.name === sortFieldName ? themeColor : '#666666', fontWeight: m.name === sortFieldName ? 'bold' : 'normal', fontSize: '12px' }}>{getMultilingualLabelName(object, m.label)}</span>
				</MenuItem>
			));
		} catch (e) {
			console.error("Error in 'listViewSetup.js -> generateSortFieldMenuItem()':" + e);
		}
		return menuItemHtml;
	}

	const generateGroupFieldMenuItem = () => {
		let themeColor = app.me.background;
		let menuItemHtml = [];
		try {
			menuItemHtml = groupByFieldOptions.map((m, index) => (
				<MenuItem
					key={'status-' + index + '-' + m.name}
					value={m.name} style={styles.popoverMenuItem}
					onClick={(e) => groupFieldMenuChange(e, m.name)}
				>
					<span style={{ color: m.name === groupByFieldName ? themeColor : '#666666', fontWeight: m.name === groupByFieldName ? 'bold' : 'normal', fontSize: '12px' }}>{getMultilingualLabelName(object, m.label)}</span>
				</MenuItem>))
		} catch (e) {
			console.error("Error in 'listViewSetup.js -> generateGroupFieldMenuItem()':" + e);
		}
		return menuItemHtml;
	}

	const generateWorkFlowMenuItem = (workflowList) => {
		let themeColor = app.me.background;
		let menuItemHtml = [];
		try {
			let tempWorkflowList = workflowList.filter(e => e.name !== "All");
			tempWorkflowList.unshift({ id: -9999, name: getLocalizedStrings().label.DETAIL_VIEW.NO_SETUP_OPTION });
			menuItemHtml = tempWorkflowList.map((workflow, index) => (
				<MenuItem
					key={'status-' + index + '-' + workflow.name}
					value={workflow.id} style={styles.popoverMenuItem}
					onClick={() => workFlowMenuChange(workflow)}
				>
					<span style={{ color: workflow.id === selectedWorkFlowId ? themeColor : '#666666', fontWeight: workflow.id === selectedWorkFlowId ? 'bold' : 'normal', fontSize: '12px' }}>{workflow.name}</span>
				</MenuItem>))
		} catch (e) {
			console.error("Error in 'listViewSetup.js -> generateWorkFlowMenuItem()':" + e);
		}
		return menuItemHtml;
	}

	const groupFieldMenuChange = (e, value) => {
		try {
			let sortFieldLabel = groupByFieldOptions.find((f) => f.name === value);
			setGroupByFieldName(value);
			setgroupByFieldLabel(sortFieldLabel.label);
			setIsOpenGroupByFieldMenu(false);
		} catch (e) {
			console.error("Error in 'listViewSetup.js -> groupFieldMenuChange()':" + e);
		}
	}

	const workFlowMenuChange = (workflow) => {
		try {
			setGroupByFieldNameSort(workflow.id === -9999 ? "count" : groupByFieldNameSort);
			setselectedWorkFlowId(workflow.id);
			setSelectedWorkFlowLabel(workflow.name);
			setIsOpenWorkFlowFieldMenu(false);
		} catch (e) {
			console.error("Error in 'listViewSetup.js -> workFlowMenuChange()':" + e);
		}
	}

	const changeRadioButton = (event, value) => {
		try {
			setGroupByFieldNameSort(value);
		} catch (e) {
			console.error("Error in 'listViewSetup.js -> changeRadioButton()':" + e);
		}
	}

	let tempLabelName = null;
	let objectName = null;
	let themeColor = app.me.background;
	if (object === constants.SOLUTIONS_OBJECT) {
		tempLabelName = makeTiteleCase(object);
	} else if (object === constants.BROADCAST_CAMPAIGNS_OBJECT ||
		object === constants.AB_CAMPAIGNS_OBJECT ||
		object === constants.AUTOMATION_DESIGNER_OBJECT) {
		objectName = object.replace('-', ' ');
		tempLabelName = objectName.charAt(0).toUpperCase() + objectName.slice(1);
	} else if (object === constants.GROUP_OBJECT) {
		let activeTab = getObjectParam(getActiveTab());
		let activeTabObject = getObjectParam(activeTab.object);
		tempLabelName = makeTiteleCase(activeTabObject);
	} else if (object === constants.COUPONS_OBJECT) {
		let activeTab = getObjectParam(getActiveTab());
		let activeTabObject = getObjectParam(activeTab.object);
		tempLabelName = getObjectLabelByObject(activeTabObject);
	} else if (object === constants.NEWS_OBJECT) {
		tempLabelName = makeTiteleCase(object);
	} else {
		let activeTab = getObjectParam(getActiveTab());
		let activeTabObject = getObjectParam(activeTab.object);
		let type = getStringParam(activeTab.type);
		if (activeTabObject === constants.SEARCH_RESULTS || activeTabObject === constants.ADVANCE_SEARCH_RESULTS) {
			let activeTabInfo = getObjectParam(activeTab.info);
			let activeTabKeyMap = activeTabObject === constants.SEARCH_RESULTS ? getObjectParam(activeTabInfo['search']) : getObjectParam(activeTabInfo['advanceSearch']);
			objectName = getStringParam(activeTabKeyMap.object);
			tempLabelName = getObjectLabelByObject(objectName);
		} else if (type === TYPE_LIST_VIEW || type === TYPE_COLUMN_VIEW) {
			tempLabelName = getStringParam(getSelectedObjectLabel());
			if (tempLabelName.toLowerCase() === constants.ONBOARDING_OBJECT && activeTab.object === constants.PROJECTS_OBJECT) {
				tempLabelName = getObjectLabelByObject(activeTab.object);
			}

			objectName = object;
			if (tempLabelName === "Sales Reports" || tempLabelName === "Service Reports") {
				objectName = object.replace('-', ' ');
				tempLabelName = objectName.charAt(0).toUpperCase() + objectName.slice(1);
			}
		} else if (type === TYPE_DETAIL_VIEW) {
			let itemLabel = null;
			let arrChildObjectInfo = getChildObjectInfo(listView.data.parent_object, object);
			if (arrChildObjectInfo.length > 0) {
				itemLabel = arrChildObjectInfo[0].title;
				tempLabelName = itemLabel;
			}
		}
	}
	if (tempLabelName === 'Broadcast campaigns') {
		tempLabelName = getLocalizedStrings().label.MODULE_ITEMS['Broadcast Campaigns'];
	} else if (tempLabelName === 'Ab campaigns') {
		tempLabelName = getLocalizedStrings().label.MODULE_ITEMS['Ab Campaigns'];
	} else if (tempLabelName === 'Automation designer') {
		tempLabelName = getLocalizedStrings().label.MODULE_ITEMS['Automation Designer'];
	} else if (tempLabelName === 'Email Marketing') {
		tempLabelName = getLocalizedStrings().label.MODULE_ITEMS['Email Marketing'];
	} else if (tempLabelName === 'Opportunities') {
		tempLabelName = getLocalizedStrings().label.MODULE_ITEMS['Opportunities'];
	} else if (objectName === constants.ACCOUNTS_OBJECT && tempLabelName === 'Accounts') {
		tempLabelName = getLocalizedStrings().label.HEADER['Accounts'];
	} else if (objectName === constants.CONTACTS_OBJECT && tempLabelName === 'Contacts') {
		tempLabelName = getLocalizedStrings().label.HEADER['Contacts'];
	} else if (objectName === constants.CASES_OBJECT && tempLabelName === 'Cases') {
		tempLabelName = getLocalizedStrings().label.HEADER['Cases'];
	} else if (objectName === constants.PROJECTS_OBJECT && tempLabelName === 'Projects') {
		tempLabelName = getLocalizedStrings().label.HEADER['Projects'];
	} else if (objectName === constants.ISSUES_OBJECT && tempLabelName === 'Issues') {
		tempLabelName = getLocalizedStrings().label.HEADER['Issues'];
	} else if (tempLabelName === 'Solutions') {
		tempLabelName = getLocalizedStrings().label.HEADER['Solutions'];
	} else if (objectName === 'salesleads') {
		tempLabelName = getLocalizedStrings().label.HEADER['Sales Leads'];
	} else if (objectName === 'recyclebin') {
		let tempObjectList = app.objectList;
		if (isValidParam(objectList) && getArrayParam(tempObjectList).length > 0) {
			tempObjectList = tempObjectList.filter(f => f.name === constants.ACCOUNTS_OBJECT)
		}
		tempLabelName = isValidParam(tempObjectList) && getArrayParam(tempObjectList).length > 0 ? tempObjectList[0].label : getLocalizedStrings().label.HEADER['Accounts'];
	} else {

	}

	let sortFieldLabelLanguge = sortFieldLabel;
	let tempGroupByFieldLabel = groupByFieldLabel;
	let workFlowLabel = selectedWorkFlowLabel;
	if (object === constants.TOUCH_CAMPAIGNS_OBJECT ||
		constants.AUTOMATION_DESIGNER_OBJECT || constants.APPOINTMENTS ||
		constants.TASKS || constants.BROADCAST_CAMPAIGNS_OBJECT || constants.AB_CAMPAIGNS_OBJECT) {
		sortFieldLabelLanguge = getMultilingualLabelName(object, sortFieldLabel);
	}
	return (
		<div key="listview-setup-container" style={{ ...styles.customizedFieldsContainerDiv, border: '0px solid green', display: 'flex', flexDirection: 'column' }}>
			<div style={{ ...styles.customizedFieldsHeader, border: '0px solid green', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<span>
					{getLocalizedStrings().label.LIST_VIEW.SELECT_COLUMN_FOR_VIEW}{columnView && <span style={{ paddingLeft: "4px", color: themeColor }}>{"*"}</span>}
				</span>
				<IconButton
					onClick={() => onCallCloseEvent()}
					aria-label="close"
				>
					<Icon
						style={{ fontSize: '15px' }}
						title={getLocalizedStrings().label.COMMON.CLOSE}
						className="material-icons">
						close
					</Icon>
				</IconButton>

			</div>
			<div style={{ ...styles.customizedFieldsNotify, border: '0px solid red', paddingTop: '8px' }}>
				{getLocalizedStrings().label.LIST_VIEW.ADD_AND_REORDER} {labelName} {getLocalizedStrings().label.LIST_VIEW.FIELDS}: {getLocalizedStrings().label.LIST_VIEW.CLICK_TO_ADD_MORE_FIELDS}
			</div>
			<div style={{ position: "relative", zIndex: 2 }}>
				{setupAvailableFieldOptions.length > 0 && <DraggableSelect
					key={'select-draggable-' + object}
					id={'select-draggable-' + object}
					isClearable
					onSortEnd={(selectedOptions) => onSortEnd(selectedOptions)}
					closeMenuOnSelect={false}
					disabled={false}
					value={getArrayParam(setupSelectedFieldOptions)}
					selectLimit={columnView ? cvMaxLimit : maxLimit}
					limitMessage={columnView ? getLocalizedStrings().message.LIST_VIEW.SELECT_MAXIMUM_FIELD_FOR_COLUMN_SETUP : getLocalizedStrings().message.LIST_VIEW.SELECT_MAXIMUM_FIELD_FOR_LISTVIEW_SETUP}
					//placeholder={getLocalizedStrings().label.LIST_VIEW.SELECT_FIELDS}
					options={getArrayParam(setupAvailableFieldOptions)}
					onChange={(options) => handleSelectChange(options)}
					clearAllText={getLocalizedStrings().label.COMMON.CLEAR_ALL}
					className='my-cool-select-top'
				/>}
				{setupAvailableFieldOptions.length === 0 &&
					<div style={{ border: '1px solid #aeaeae', borderRadius: ' 4px', padding: '10px', paddingLeft: '45%', }}>
						<ShowCircularProgress size={25} style={{ marginTop: '0px', width: '100%' }} />
					</div>
				}
			</div>
			{columnView && (
				<div style={styles.columnViewSetupInfo}>
					<span style={{ color: themeColor }}>*</span>
					<span style={{ paddingLeft: '2px' }}>
						{getLocalizedStrings().label.LIST_VIEW.COLUMN_VIEW_SETUP_INFO}
					</span>
				</div>
			)}
			{groupByFieldOptions.length > 0 && columnView && (
				<div>
					{/* <div style={{ marginTop: '20px', display: 'table', fontSize: '14px' }}>
						<div onClick={handleWorkFlowFieldMenuOpen} style={{ maxWidth: '195px', marginRight: '10px', paddingTop: '2px', float: 'left' }}>
							<div style={{ float: 'left', color: '#717171', padding: '0px', fontWeight: 'normal' }}>{getLocalizedStrings().label.COMMON.WORKFLOW}:</div>
							<div style={{ float: 'right', padding: '0px', textAlign: 'left', paddingLeft: '6px', maxWidth: '100px' }}>
								<div style={{ float: 'left', maxWidth: '82%', fontWeight: 'normal', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={workFlowLabel}>{workFlowLabel}</div>
								<i className="fa fa-angle-down" aria-hidden="true" style={{ paddingLeft: '7px', float: 'right', paddingTop: '1px', marginTop: '2px' }} />
							</div>
						</div>
					</div> */}
					<div disable={columnView} style={{ marginTop: '20px', display: 'table', fontSize: '14px', pointerEvents: columnView ? "none" : "all", opacity: columnView ? 0.7 : 1 }}>
						<div onClick={(e) => handleGroupFieldMenuOpen(e)} style={{ maxWidth: '195px', marginRight: '10px', paddingTop: '2px', float: 'left' }}>
							<div style={{ float: 'left', color: '#717171', padding: '0px', fontWeight: 'normal' }}>{getLocalizedStrings().label.LIST_VIEW.GROUP_BY}:</div>
							<div style={{ float: 'right', padding: '0px', textAlign: 'left', paddingLeft: '6px', maxWidth: '100px' }}>
								<div style={{ float: 'left', maxWidth: '82%', fontWeight: 'normal', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={tempGroupByFieldLabel}>{tempGroupByFieldLabel}</div>
								<i className="fa fa-angle-down" aria-hidden="true" style={{ paddingLeft: '7px', float: 'right', paddingTop: '1px', marginTop: '2px' }} />
							</div>
						</div>

						<div disable={selectedWorkFlowId === -9999} style={{ marginRight: '20px', float: 'left', marginTop: '-6px', marginLeft: '20px', pointerEvents: selectedWorkFlowId === -99 ? "none" : "all", opacity: selectedWorkFlowId === -99 ? 0.7 : 1 }}>
							<FormControl component="fieldset">
								<RadioGroup row name="shipSpeed" value={groupByFieldNameSort} onChange={(event, value) => changeRadioButton(event, value)} style={{ width: 'auto' }}>
									<FormControlLabel value={"count"} control={<Radio color="default" />} label={getLocalizedStrings().label.LIST_VIEW.COUNT} />
									<FormControlLabel value={"sequential"} control={<Radio color="default" />} label={getLocalizedStrings().label.COMMON.SEQUENTIAL} />
								</RadioGroup>
							</FormControl>
						</div>
					</div>
				</div>
			)}
			{groupByFieldOptions.length > 0 && !columnView && <div disable={columnView} style={{ marginTop: '20px', display: 'table', fontSize: '14px', pointerEvents: columnView ? "none" : "all", opacity: columnView ? 0.7 : 1 }}>
				<div onClick={(e) => handleGroupFieldMenuOpen(e)} style={{ maxWidth: '195px', marginRight: '10px', paddingTop: '2px', float: 'left' }}>
					<div style={{ float: 'left', color: '#717171', padding: '0px', fontWeight: 'normal' }}>{getLocalizedStrings().label.LIST_VIEW.GROUP_BY}:</div>
					<div style={{ float: 'right', padding: '0px', textAlign: 'left', paddingLeft: '6px', maxWidth: '100px' }}>
						<div style={{ float: 'left', maxWidth: '82%', fontWeight: 'normal', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={tempGroupByFieldLabel}>{tempGroupByFieldLabel}</div>
						<i className="fa fa-angle-down" aria-hidden="true" style={{ paddingLeft: '7px', float: 'right', paddingTop: '1px', marginTop: '2px' }} />
					</div>
				</div>

				<div style={{ marginRight: '20px', float: 'left', marginTop: '-6px', marginLeft: '20px' }}>
					<FormControl component="fieldset">
						<RadioGroup row name="shipSpeed" defaultValue={groupByFieldNameSort} onChange={(event, value) => changeRadioButton(event, value)} style={{ width: 'auto' }}>
							<FormControlLabel value={"alphabetical"} control={<Radio color="default" />} label={getLocalizedStrings().label.LIST_VIEW.ALPHABETICAL} />
							<FormControlLabel value={"count"} control={<Radio color="default" />} label={getLocalizedStrings().label.LIST_VIEW.COUNT} />
						</RadioGroup>
					</FormControl>
				</div>
			</div>
			}
			<Menu
				id={'group_by_fields'}
				key={'group_by_fields'}
				name={'group_by_fields'}
				open={isOpenGroupByFieldMenu}
				anchorEl={anchorEl}
				anchororigin={{ horizontal: 'left', vertical: 'bottom' }}
				targetorigin={{ horizontal: 'left', vertical: 'top' }}
				onClose={() => groupFieldMenuClose()}
				value={groupByFieldName}
			>
				{
					generateGroupFieldMenuItem()
				}
			</Menu>
			{columnView && <Menu
				id={'workFlow_fields'}
				key={'workFlow_fields'}
				name={'workFlow_fields'}
				open={isOpenWorkFlowFieldMenu}
				anchorEl={anchorEl}
				anchororigin={{ horizontal: 'left', vertical: 'bottom' }}
				targetorigin={{ horizontal: 'left', vertical: 'top' }}
				onClose={() => workFlowFieldMenuClose()}
				value={selectedWorkFlowId}>
				{
					generateWorkFlowMenuItem(workflowList)
				}
			</Menu>}
			<div style={{ marginTop: '20px', display: 'table', fontSize: '14px' }}>
				{setupSelectedFieldOptions.length > 0 && (
					<div onClick={(event) => handleSortFieldMenuOpen(event)} style={{ maxWidth: '195px', marginRight: '10px', paddingTop: '2px', float: 'left' }}>
						<div style={{ float: 'left', color: '#717171', padding: '0px', fontWeight: 'normal' }}>{getLocalizedStrings().label.LIST_VIEW.SORT_BY}:</div>
						<div style={{ float: 'right', padding: '0px', textAlign: 'left', paddingLeft: '6px', maxWidth: '100px' }}>
							<div style={{ float: 'left', maxWidth: '82%', fontWeight: 'normal', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={sortFieldLabelLanguge}>{sortFieldLabelLanguge}</div>
							<i className="fa fa-angle-down" aria-hidden="true" style={{ paddingLeft: '7px', float: 'right', paddingTop: '1px', marginTop: '2px' }} />
						</div>
					</div>
				)}
				<Menu
					id={'sort-by-fields'}
					key={'sort-by-fields'}
					name={'sort-by-fields'}
					open={isOpenSortFieldMenu}
					anchorEl={anchorEl}
					anchororigin={{ horizontal: 'left', vertical: 'bottom' }}
					targetorigin={{ horizontal: 'left', vertical: 'top' }}
					onClose={() => sortFieldMenuClose()}
					value={sortFieldName}
				>
					{
						generateSortFieldMenuItem()
					}
				</Menu>
				<div style={{ marginRight: '20px', float: 'left', marginTop: '-6px', marginLeft: '5px' }}>
					<FormControlLabel
						control={<Checkbox
							color="default"
							onChange={(event, isChecked) => handleDescendingCheckBox(event, isChecked)}
							checked={isDescendingChecked}
							defaultChecked={isDescendingChecked}
						/>}
						label={<div style={{ color: '#717171', marginLeft: '-13px', marginRight: '5px', marginTop: '3px', paddingLeft: '7px' }}>{getLocalizedStrings().label.LIST_VIEW.DESCENDING}</div>}
						labelposition="right"
						labelstyle={{ color: '#717171', padding: '0px', width: 'auto', fontWeight: 'normal' }}
						style={{ height: 'auto' }}
					/>

				</div>
				{!columnView && (
					<div style={{ marginRight: '20px', float: 'left', marginTop: '-5px' }}>
						{(object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.OPPORTUNITIES_OBJECT || object === constants.PETS_OBJECT) && (
							<FormControlLabel
								control={(
									<Checkbox
										color="default"
										onChange={(event, isChecked) => handleShowPictureCheckBox(event, isChecked)}
										checked={isShowPictureChecked}
										defaultChecked={isShowPictureChecked}
									/>
								)}
								label={(
									<div style={{ color: '#717171', marginLeft: '-13px', marginRight: '5px', marginTop: '3px', paddingLeft: '7px' }}>
										{getLocalizedStrings().label.LIST_VIEW.SHOW_PICTURE}
									</div>
								)}
								labelposition="right"
								labelstyle={{ color: '#717171', padding: '0px', width: 'auto', fontWeight: 'normal' }}
								style={{ height: 'auto' }}
							/>
						)}
					</div>
				)}
				{!columnView  && (
					<div style={{ marginRight: '20px', float: 'left', marginTop: '-5px' }}>
						<FormControlLabel
							control={<Checkbox
								color="default"
								onChange={(event, isChecked) => handleShowTotalCheckBox(event, isChecked)}
								checked={isShowTotalChecked}
								defaultChecked={isShowTotalChecked} />}
							label={<div style={{ color: '#717171', marginLeft: '-13px', marginRight: '5px', marginTop: '3px', paddingLeft: '7px' }}>{getLocalizedStrings().label.LIST_VIEW.SHOW_TOTAL}</div>}
							labelposition="right"
							labelstyle={{ color: '#717171', padding: '0px', width: 'auto', fontWeight: 'normal' }}
							style={{ height: 'auto' }}
						/>
					</div>
				)}
			</div>
			<div style={{ textAlign: 'right', marginLeft: '-14px', marginTop: '-5px' }}>
				<Button
					onClick={setupSave}
					style={{ ...styles.primaryButton, marginRight: '0px' }}
				>{getLocalizedStrings().label.COMMON.SAVE}</Button>
				<Button
					key='close'
					onClick={() => onCallCloseEvent()}
					style={{ ...styles.secondaryButton, marginLeft: '6px', marginRight: '0px', verticalAlign: 'top' }}
				>{getLocalizedStrings().label.COMMON.CANCEL}</Button>
			</div>
		</div>
	)

}
export default ListViewSetup;