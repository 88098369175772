import { actions } from '..//constants/actions';

const complianceReportInitialState = {
    complianceReportData: {},
}
const complianceReportReducer = (state = complianceReportInitialState, action) => {
    switch (action.type) {
        case actions.COMPLIANCE_REPORT_DATA_UPDATE:
            return {
                ...state,
                complianceReportData : action.payload,
            }
        default:
            return state;
    }
}
export default complianceReportReducer;