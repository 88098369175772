import React, { useState ,useEffect,useRef ,useCallback} from 'react';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useDispatch, useSelector } from "react-redux";
import { Icon } from '@mui/material';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { WORKFLOW_SETUP, constants } from '../../../services/constants/constants';
import { setSelectedWorkflowStage } from '../../../services/actions/workflowSetupAction';
import WorkflowStagesCard from './WorkflowStagesCard';
import update from 'immutability-helper'
import {AddNewWorkflowStage} from "../../../services/actions/workflowSetupAction";

const WorkFlowSetupStages = () => {
    const dispatch = useDispatch();
    const {data} = useSelector((state) => state.workFlowSetup);
    const WorkflowStages = data.nodes;

     const plusIconFn = () => {
        let data = {
        }
        dispatch(getAppDialog(true, WORKFLOW_SETUP.CONFIGURE_WORKFLOW_STAGES, "configure workflow stages", getWFName, data, null));
    }
    
    // Extract order_ids from stage tasks and webhooks
    const orderIds = WorkflowStages?.reduce((acc, stage) => {
        // Extract order_id from stage
        acc.push(stage.order_id);

        // Extract order_id from tasks within the stage
        if (stage.tasks) {
            stage.tasks.forEach(task => {
            acc.push(task.order_id);
            });
        }

        // Extract order_id from webhooks within the stage
        if (stage.webhooks) {
            stage.webhooks.forEach(webhook => {
            acc.push(webhook.order_id);
            });
        }

        return acc;
    }, []);

    // Find the maximum order_id
    
    const getWFName = (wfName) => {
        const orderId = orderIds.length + 1
        let obj = {};
        obj.workflow_id = data.id;
        obj.details ={
                        "t_convert": 0,
                        "selected_form_fields": [],
                        "t_probability": 0,
                        "t_stages": wfName,
                    };
        obj.id = Math.floor(Math.random() * Date.now());
        obj.type = "Stage";
        obj.order_id = orderId + 1;
        obj.status = "Active";
        obj.tasks = [];
        obj.webHook = [];
        let _wfArr = data.nodes;
        _wfArr.push(obj);
        dispatch(AddNewWorkflowStage(_wfArr));

    }

    

    const renderCard = (card, index) => {
        return (
            <WorkflowStagesCard
                key={card.id}
                index={index}
                id={card.id}
                wf={card}
            />
        )
    }

    return (
        <>
            <div>
                <span style={{font:'bolder 16px Arial, Helvetica, sans-serif',}}>{getLocalizedStrings().label.COMMON.STAGES}</span>
                <div style={{display:'flex'}}>
                    <div style={{width:'fit-content',display:'flex',cursor:'grab'}}>{WorkflowStages?.map((card, index) => renderCard(card, index))}</div>
                    <div style={{border: '1px solid #000',width:'33px',height:'22px',marginTop:'5px'}}>
                        <span style={{ marginLeft: '10px',  marginRight: '10px' }}>
                            <i
                                onClick={(e) => plusIconFn(e)}
                                className="fa fa-plus" aria-hidden="true" style={{ cursor: 'pointer', marginTop: '3px' }} title="Add new menu item"></i>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};
export default WorkFlowSetupStages;