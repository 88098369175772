import React from 'react';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';

const matrixReportInitialData = {
    "reportName": "",
    "column": [
      /*  {
            "isVisible": true,
            "name": "subject",
            "label": "Subject",
            "isToatalField": false,
            "isDetailsLink": true
        },
        {
            "isVisible": true,
            "name": "count",
            "label": "Click-Through Rate(CTR) ",
            "isToatalField": true,
            "isDetailsLink": true
        }*/
    ],
    "data": [
       /* {
            "subject": "We have ",
            "count": 10
        }*/
    ],
    "footer": [
       /* {
            "isVisible": true,
            "showTotal": false,
            "colspan": 2,
            "name": "count",
            "label": "Total Level",
            "isDetailsLink": true
        },
        {
            "isVisible": false,
            "showTotal": true,
            "colspan": 1,
            "name": "count",
            "label": "",
            "isDetailsLink": true
        }*/
    ]
}



class MatrixReportTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "totalValue": 0,
            "verticalSumArray": []
        }
    }

    generateHeaders() {

        let cells = this.props.config.column.map((columnObject, index) => {
            return <th key={index}> {columnObject.label} </th>;
        })

        return (<tr> {cells}  </tr>);
    }

    generateRows() {

        let total = 0;
        if (this.props.config.data !== undefined) {
            let verticalSumArray = new Array(this.props.config.column.length);
            return this.props.config.data.map((rowObject, rowIndex) => {
                let cells = this.props.config.column.map((columnObject, colIndex) => {

                    // For Total
                    if (columnObject.isToatalField && typeof rowObject[columnObject.name] === "number") {
                        total = total + rowObject[columnObject.name];
                        verticalSumArray[colIndex] = (verticalSumArray[colIndex] === undefined ? 0 : verticalSumArray[colIndex]) + rowObject[columnObject.name];
                    }


                    if (columnObject.isDetailsLink !== undefined && columnObject.isDetailsLink === true) {

                        return <td key={rowIndex + ',' + colIndex}> <a href="javascript:void(0)" onClick={() => {

                           this.props.drillDownMatrixReport(this.props.config.reportName, "body", this.props.config.column, rowObject, this.props.config.footer, columnObject.name);
                        }} > {rowObject[columnObject.name]} </a>  </td>;

                    } else {

                        return <td key={rowIndex + ',' + colIndex}> {rowObject[columnObject.name]} </td>;

                    }

                })

                this.state.totalValue = total;
                this.state.verticalSumArray = verticalSumArray;
                return (<tr key={rowIndex}> {cells}  </tr>);
            });
        } else {
            return (<tr> <td colSpan={this.props.config.column.length + 1}> {getLocalizedStrings().message.COMMON.NO_DATA} </td>  </tr>);
        }

    }

    generateFooter() {

        if (this.props.config.data !== undefined) {

            let cells = this.props.config.footer.map((columnFooterObject, index) => {

                if (columnFooterObject.isVisible) {

                    if (!columnFooterObject.showTotal) {


                        return <td colSpan={columnFooterObject.colspan} key={index}> {columnFooterObject.label} </td>;

                    } else {


                        if (columnFooterObject.isDetailsLink === true) {
                            return <td key={index}> <a href="javascript:void(0)" onClick={() => {

                                this.props.drillDownMatrixReport(this.props.config.reportName, "footer", this.props.config.column, {}, this.props.config.footer, columnFooterObject.name);
                            }}  >{this.state.verticalSumArray[index]}</a></td>;


                            // return <td colSpan={columnFooterObject.colspan} key={index}> <a  > &nbsp;{columnFooterObject.label}  </a>  </td>;
                        }
                        else {

                            return <td key={index}> {this.state.verticalSumArray[index]} </td>;

                        }


                    }
                }

            })

            return (<tr> {cells}  </tr>);

        }

    }

    render() {

        let headerComponents = this.generateHeaders();
        let rowComponents = this.generateRows();
        let footerComponent = this.generateFooter();
        return (

            <div className="row">
                <div className="col-md-12">
                    <div style={{ padding: '25px 10px 12px 0px', fontWeight: 'bold', cursor: 'move' }} >
                        <span className="text"><h3>{this.props.config.reportName}</h3></span>
                    </div>
                </div>
                <div className="col-md-12">
                    <table className="table table-striped">
                        <thead>
                            {headerComponents}
                        </thead>
                        <tbody>
                            {rowComponents}
                        </tbody>
                        <tfoot style={{ borderTop: '2px solid black' }}>
                            {footerComponent}

                        </tfoot>
                    </table>
                </div>
            </div>
        );
    }

}




const mapStateToProps = (state) => {
    return {
        report: state.report,
        app: state.app,
    }
}

export {
    MatrixReportTable,
    matrixReportInitialData
}