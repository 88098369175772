import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { isValidParam, getBooleanParam, getArrayParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { constants } from '../../../services/constants/constants';
import { FormControlLabel, FormControl, Select as SelectField, Checkbox, MenuItem, InputLabel, Icon } from '@mui/material';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import { getOutBoundPhoneList, enableOrDisable, callRecordingSave } from '../../../services/actions/callAction';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import DraggableSelect from '../../sfPlugins/draggableSelect.tsx';
import Button from '@mui/material/Button';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import * as sfDialogs from '../components/sfDialogs';

const maxLimit = 3;
const PhoneNumberSetup = ({ object, closeDrawer, data }) => {
    const dispatch = useDispatch();
    const appState = useSelector((state) => state.app);
    const objectListData = useSelector((state) => state.app.objectList);
    const sfFormFields = useSelector((state) => state.sfForm.data);
    const [isPhoneNumberSaved, setisPhoneNumberSaved] = useState(false);
    const [isCallRecording, setisCallRecording] = useState(false);
    const [isCallRecordCheckBoxClicked, setisCallRecordCheckBoxClicked] = useState(false);
    const [showAdditionalInfo, setshowAdditionalInfo] = useState(false);
    const [showAdditionalInfoCheckBox, setshowAdditionalInfoCheckBox] = useState(false);
    const [selectedObject, setselectedObject] = useState(constants.ACCOUNTS_OBJECT);
    const [formFileds, setformFileds] = useState(null);
    const [selectedFormFields, setselectedFormFields] = useState([]);
    const [setupId, setsetupId] = useState(0);
    const [isLodding, setisLodding] = useState(false);
    const [isCheckboxChanged, setIsCheckboxChanged] = useState(false);


    useEffect(() => {
        let defaultSelectedObj = constants.ACCOUNTS_OBJECT;
        getFormFields(defaultSelectedObj);
        if (appState.me.power_calling_setup.length > 0) {
            setshowAdditionalInfo(true);
            setshowAdditionalInfoCheckBox(true);
        }

    }, [appState]);
    useState(()=>{

    },[selectedFormFields])
    useEffect(() => {
        let savedCheckboxValue = localStorage.getItem("isCallRecordingChecked");
        if (savedCheckboxValue !== null) {
            let isCheckboxChecked = savedCheckboxValue === "true";
            setisCallRecording(isCheckboxChecked);
            appState.me.is_call_record = isCheckboxChecked;
            setisCallRecordCheckBoxClicked(true);
        }
    }, [selectedObject]);
    useEffect(() => {
        setIsCheckboxChanged(false);
    }, [isCallRecordCheckBoxClicked]);

    const getFormFields = (object) => {
        try {
            let formFileds = populateFields(object);
            generateFormFieldList(formFileds,object);
        } catch (error) {
            console.error("Error in 'phoneNumberSetup.js -> getFormFields()':" + error);
        }
    }
    const changeObj = (event) => {
        let value = event.target.value;
        setselectedObject(value);
        getFormFields(value);
    }

    const generateFormFieldList = (formFileds,object) => {
        var options = [];
        let formFieldList = null;
        let arrayFormFieldList = formFileds;
        if (isValidParam(arrayFormFieldList) && arrayFormFieldList.length > 0) {
            formFieldList = arrayFormFieldList.map((fields, i) => {
                let fieldObj = {};
                fieldObj.value = fields.name;
                fieldObj.name = fields.name;
                fieldObj.label = fields.label;
                fieldObj.title = fields.label;
                options.push(fieldObj);
            }
            );
            setformFileds(options);
            getSelectedFilds(object);
        }
    }
    const populateFields = (object) => {
        let formFields = sfFormFields[object].fields;
        try {
            formFields = formFields.filter(f => {
                return (f.field_type !== "header" && f.field_type !== "" && f.field_type !== 'integer'
                    && !f.is_hidden_field && !f.is_readonly && f.name !== "workflow_name" && f.name !== "opportunity_amount" &&
                    f.name !== "sales_rep" && f.name !== "t_type" && f.name !== "lead_score" && f.field_type !== "datetime" && f.field_type !== "memo" && f.name !== "visible_to" && f.name !== "main_contact_id");
            });
            sortArrayObjectByProperty(formFields, 'label');
        } catch (error) {
            console.error("Error in 'phoneNumberSetup.js -> populateFields()':" + error);
        }
        return formFields;
    }

    const getSelectedFilds = (object) => {
        let tempSetUpId = 0;
        let tempSelectedFormFields = "";
        let tempSelectedFormFieldsArry = [];
        let data = appState.me.power_calling_setup;
        if (data !== null && data !== undefined && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].object === object) {
                    let formFields = sfFormFields[object].fields;
                    tempSetUpId = data[i].setup_id;
                    tempSelectedFormFields = data[i].object_fields;
                    let newArr = [];
                    if (tempSelectedFormFields !== '' && tempSelectedFormFields !== null) {
                        newArr = tempSelectedFormFields.split(",");
                    }
                    let tempFormFields = [];
                    for (let i = 0; i < newArr.length; i++) {
                        tempFormFields = formFields.filter(f => {
                            return (f.name === newArr[i]);
                        });
                        if (tempFormFields.length > 0) {
                            let fieldObj = {};
                            fieldObj.value = tempFormFields[0].name;
                            fieldObj.name = tempFormFields[0].name;
                            fieldObj.label = tempFormFields[0].label;
                            fieldObj.title = tempFormFields[0].label;
                            tempSelectedFormFieldsArry.push(fieldObj);
                        }
                    }
                }
            }
            setselectedFormFields([...tempSelectedFormFieldsArry]);
            setsetupId(tempSetUpId);
        } else {
            setsetupId(0);
        }
    }

    const getObjects = () => {
        let convertObjectList = [];
        try {
            let parentItems = objectListData;

            parentItems = parentItems.filter(f => { return f.name === constants.ACCOUNTS_OBJECT || f.name === constants.CONTACTS_OBJECT })

            if (parentItems.length > 0) {
                parentItems.map((items, Index) => {

                    let objectList = <MenuItem
                        value={items.name}
                        key={Index}
                    >
                        {items.label}
                    </MenuItem>;
                    convertObjectList.push(objectList);
                }
                )
            }
        } catch (error) {
            console.error("Error in 'phoneNumberSetup.js -> getObjects()':" + error);
        }
        return convertObjectList;
    }

    const handleCallRecording = (event, isInputChecked) => {
        if (event !== undefined && isInputChecked) {
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.TWILIO.CALL_RECORDING_LAWS_AND_REGULATION_ALERT, () => handleSfDialogConfirm(isInputChecked), null);
        } else {
            handleSfDialogConfirm(isInputChecked);
        }
    }
    const handleSfDialogConfirm = (isInputChecked) => {
        try {
            let callRecord = 0;
            if (isInputChecked) {
                callRecord = 1;
            }
            setisCallRecording(isInputChecked);
            setIsCheckboxChanged(true)
            let params = {};
            params.call_record = callRecord;
            let promise = Promise.resolve(callRecordingSave(params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        appState.me.is_call_record = isInputChecked;
                        setisCallRecordCheckBoxClicked(true);
                        localStorage.setItem("isCallRecordingChecked", isInputChecked);
                    }
                });
            }

        } catch (error) {
            console.error("Error in 'phoneNumberSetup.js -> handleSfDialogConfirm()':" + error);
        }
    }
    const handleShowAdditionalInfo = (event, isInputChecked) => {
        if (isInputChecked) {
            setshowAdditionalInfo(true);
            setshowAdditionalInfoCheckBox(true);
        } else {
            if (appState.me.power_calling_setup.length > 0) {
                setisLodding(true);
                handleRemoveAddFields();
            } else {
                setselectedFormFields('');
                setshowAdditionalInfo(false);
                setshowAdditionalInfoCheckBox(false);
            }
        }
    }

    const onSortEnd = (selectedOptions) => {
        setselectedFormFields([...selectedOptions]);
    };

    const handleSelectChange = (selectedOptions) => {
        let length = selectedOptions.length
        if (length <= maxLimit) {
            setselectedFormFields([...selectedOptions]);
        } else {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.TWILIO.FIELDS_ADD_LIMIT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }

    }

    const getOutBoundPhoneListData = () => {
        try {
            let promise = Promise.resolve(getOutBoundPhoneList());
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        response = getObjectParam(response);
                        let outboundPhones = getArrayParam(response.outbound_phones);
                        if (outboundPhones.length > 0) {
                            setisPhoneNumberSaved(true);
                        } else {
                            onToggle(null, true);
                        }
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'phoneNumberSetup.js -> getOutBoundPhoneList()':" + error);
        }
    }

    const onToggle = (event, isInputChecked) => {
        try {

            if (getBooleanParam(isPhoneNumberSaved)) {
                let params = {};
                params.is_enable = isInputChecked;
                let promise = Promise.resolve(enableOrDisable(params));
                if (isValidParam(promise)) {
                    promise.then(response => {
                        if (isValidParam(response)) {
                            getOutBoundPhoneListData();
                        }
                    });
                }
            }
        } catch (error) {
            console.error("Error in 'phoneNumberSetup.js -> onToggle()':" + error);
        }
    }

    const handleRemoveAddFields = () => {
        setshowAdditionalInfo(false);
        setshowAdditionalInfoCheckBox(false);
        let params = {};
        let url = endPoints.CALLING_INTEGRATION.DELETE_POWER_CALLING_RECORD_SETUP;
        let promise = Promise.resolve(HTTPClient.post(url, params));
        promise.then((response) => {
            if (isValidParam(response)) {
                if (response.status === 0) {
                    setselectedFormFields('');
                    setsetupId(0);
                    appState.me.power_calling_setup = [];
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.TWILIO.SETUP_UPDATED_SUCCESSFULLY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                } else {
                    setshowAdditionalInfoCheckBox(true);
                    setshowAdditionalInfo(true);
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.TWILIO.SETUP_UPDATED_FIELDS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
                setisLodding(false);
            }
        });
    }

    const openHelpUrl = () => {
        let url = 'http://support.soffront.com/knowledge-base/phone-recording-compliance/';
        window.open(url, '_blank');
    }

    const getCallSetupElements = () => {
        let elements = [];
        let dataTipLabel = 'info';
        if(!appState.me.is_manage_unit_phone_by_fr){
        elements.push(
            <div key='call-recording-chkb'>
                <span style={{ fontWeight: 'bold', color: '#717171' }}>{getLocalizedStrings().label.TWILIO.CALL_RECORDING}</span><br />
                <div style={{ ...styles.row }}>
                    <div>
                        <FormControlLabel
                            control={<Checkbox color="default" key='callRecording'
                                name='callRecording'
                                checked={isCallRecording}
                                value={isCallRecording}
                                onChange={(event, isInputChecked) => handleCallRecording(event, isInputChecked)} />}
                            label={getLocalizedStrings().label.TWILIO.RECORD_ALL_CALL} labelposition="right"
                            labelstyle={{ color: '#717171', fontWeight: 'normal', marginLeft: '-6px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                            style={{ height: 'auto' }} title={getLocalizedStrings().label.TWILIO.RECORD_ALL_CALL} />
                    </div>
                    <div>
                        <Icon
                            style={{ cursor: 'pointer', color: '#717171', fontSize: '24px', marginTop: '7px' }}
                            title={getLocalizedStrings().label.DETAIL_VIEW.CLICK_TO_KNOW_MORE}
                            onClick={() => openHelpUrl()}>
                            info_outline</Icon>
                    </div>
                </div>

            </div>

        );

        if (isCallRecordCheckBoxClicked && isCheckboxChanged) {
            const message = isCheckboxChanged ? getLocalizedStrings().message.TWILIO.CALL_RECORDING_SAVE : " ";
            elements.push(<div key='call-record-message' id='callRecordMessage'><span style={{ fontWeight: 'normal' }}>{message}</span>
            </div>
            );
        }
     }
        elements.push(<div key='show-additional-info' style={{ width: '100%', borderTop: '2.0px solid #afafaf5c', paddingTop: 10, marginTop: '5px' }}>
            <span style={{ fontWeight: 'bold', color: '#717171' }}>{getLocalizedStrings().label.POWER_CALLING.POWER_DIALER}</span><br />
            <FormControlLabel control={<Checkbox color="default" key='show-additional-info'
                name='show-additional-info'
                value={showAdditionalInfoCheckBox}
                onChange={(event, isInputChecked) => handleShowAdditionalInfo(event, isInputChecked)}
                checked={showAdditionalInfoCheckBox}
            />}
                label={getLocalizedStrings().label.TWILIO.ADDITIONAL_INFO} labelposition="right"
                labelstyle={{ color: '#717171', fontWeight: 'normal', marginLeft: '-6px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                style={{ height: 'auto', width: '80%' }} title={getLocalizedStrings().label.TWILIO.ADDITIONAL_INFO} />

        </div>);
        if (showAdditionalInfo) {
            elements.push(
                <div key='additional-info-settings'>
                    <div style={{ width: '100%', bottom: 10, paddingTop: 5, marginTop: '5px' }} id='callRecordMessage'>
                        <div style={{ width: '100%' }}>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                <InputLabel id="sf-call-setup-from-callsetup-simple-select-outlined-label" className="sf-call-setup-from-callsetup">{getLocalizedStrings().label.LEAD_SCORE_SETUP.OBJECT}</InputLabel>
                                <SelectField
                                    label={getLocalizedStrings().label.LEAD_SCORE_SETUP.OBJECT}
                                    name="parentObject"
                                    id="parentObject"
                                    value={selectedObject}
                                    onChange={changeObj}
                                    style={{ height: '42px' }}
                                >
                                    {getObjects()}
                                </SelectField>
                            </FormControl>
                        </div>

                        {formFileds.length > 0 && <div style={{ width: '100%', marginTop: '5px', display: 'flex' }}>

                            <div style={{ width: '75%' }} id="selChildObject">
                                <DraggableSelect
                                    isClearable
                                    onSortEnd={(value) => onSortEnd(value)}
                                    closeMenuOnSelect={false}
                                    disabled={false}
                                    value={selectedFormFields}
                                    placeholder={getLocalizedStrings().label.TWILIO.SELECT_FIELD}
                                    options={formFileds}
                                    selectLimit={maxLimit}
                                    limitMessage={getLocalizedStrings().message.TWILIO.FIELDS_ADD_LIMIT}
                                    onChange={(value) => handleSelectChange(value)}
                                    clearAllText={getLocalizedStrings().label.COMMON.CLEAR_ALL}
                                    className='my-cool-select-top'
                                />
                            </div>
                            <div style={{ width: '15%', marginLeft: '15px' }}>
                                <Button
                                    onClick={() => handleAddInfoSave()}
                                    label={getLocalizedStrings().label.COMMON.SAVE}
                                    style={{ ...styles.primaryButton, height: " 35px !important", lineHeight: ' 18px', margin: '0px' }}
                                >SAVE</Button>
                            </div>
                        </div>}

                    </div>
                </div>

            );
        }

        return elements;
    }

    const handleAddInfoSave = () => {
        let length = selectedFormFields.length
        let arrayFields = ''
        if (length === 0) {
            arrayFields = ''
        } else {
            selectedFormFields.map((item, Index) => {
                if (arrayFields === '') {
                    arrayFields = item.value;
                } else {
                    arrayFields += ',' + item.value;
                }
            })
        }

        let params = {
            "data": [
                {
                    "setup_id": setupId,
                    "object": selectedObject,
                    "object_fields": arrayFields
                }
            ]
        }
        let url = '';
        url = endPoints.CALLING_INTEGRATION.SAVE_POWER_CALLING_RECORD_SETUP;
        let promise = Promise.resolve(HTTPClient.post(url, params));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    if (response.status === 0) {
                        appState.me.power_calling_setup = response.data;
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.TWILIO.SETUP_UPDATED_SUCCESSFULLY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    } else {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.TWILIO.SETUP_UPDATED_FIELDS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                }
            });

        }
    }

    let elements = getCallSetupElements();
    return (
        <div style={{ marginLeft: '35px', marginRight: '20px' }}>
            {(!isLodding && elements.length > 0) ?
                <div>{elements}</div>
                : <div style={{ ...styles.loaderContainer }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            }
        </div>
    )
}
export default PhoneNumberSetup;