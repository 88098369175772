import React from 'react';
import { render } from 'react-dom';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { isValidParam, getStringParam, isUrl } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { uploadLogo } from '../../../services/actions/UserListAction';
import * as sfDialogs from '../components/sfDialogs';
import { setUserContextData } from '../../../services/actions/appActions';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { setHeader } from '../../../services/actions/headerActions';
import ShowCircularProgress from '../components/circularProgress';

class ChangeLogo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inProgress: false,
            id: 0,
            attachment: null,
            website: null,
        }
    }

    componentDidMount() {
        this.props.setHeader(this.props.Object, getLocalizedStrings().label.CUSTOMIZATION_SETTING.CHANGE_LOGO, null, false, true);
    }


    UNSAFE_componentWillReceiveProps() {

    }
    getActions = () => {
        const actions = [
            <Button
                primary={true}
                onClick={() => {
                    this.onFormSubmit();
                }}
                style={styles.primaryButton}
            >{getLocalizedStrings().label.ATTACHMENT.UPLOAD}</Button>
        ];
        return actions;
    }

    onChange(e) {
        let file = {};
        try {
            file.fileName = e.target.files[0].name;
            file.file = e.target.files[0];
            if (file.file.size === 0) {
                this.props.showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_BLANK_FILE, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                e.target.value = null;
                return false;
            } else {
                let fileName = file.fileName;
                fileName = fileName.replace(/'/g, "");
                let fileext = fileName.substring(fileName.lastIndexOf('.') + 1);
                if (fileext.toLowerCase() !== 'jpg' && fileext.toLowerCase() !== 'jpeg' && fileext.toLowerCase() !== 'gif' && fileext.toLowerCase() !== 'png') {
                    this.props.showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_LOGO_EXTENSION, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                    e.target.value = null;
                    return false;
                }
            }
            this.state.attachment = file;
            this.state.inProgress = false;
            this.setState(this.state);
        }
        catch (error) {
            console.error("Error in 'ChangeLogo.js -> onChange()':" + error);
        }
    }

    handleChange = (event) => {
        const target = event.target;
        let value = getStringParam(target.value);
        value = value.replace(/\s/g, '');
        this.setState({ website: value });
    }

    restrictKey = (event) => {
        try {
            if (event.key === ' ') {
                event.preventDefault();
                return false;
            } else {
                return true;
            }
        } catch (error) {
            console.error("Error in 'ChangeLogo.js ->  restrictKey()' " + error);
        }
    }

    isValidUrl = (url) => {
        let isValid = true;
        if (url = ! '' && (!isUrl(url)) || (isUrl(url) && url.startsWith("(") || url.endsWith(")"))
            || (isUrl(url) && url.startsWith("{") || url.endsWith("}"))
            || (isUrl(url) && url.startsWith("[") || url.endsWith("]"))) {
            isValid = false;
        }
        return isValid;
    }

    onFormSubmit(e) {
        try {
            if (!isValidParam(this.state.attachment) || this.state.attachment.file.size === 0) {
                this.props.showCustomSnackBar(getLocalizedStrings().message.CHANGE_LOGO.VALIDATE_LOGO_IMAGE, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                return false;
            }

            let fileSize = this.state.attachment.file.size;
            if (fileSize > 0 && fileSize >= constants.MAX_IMAGE_FILE_SIZE_FOR_COMPANY_LOGO) {
                this.props.showCustomSnackBar(getLocalizedStrings().message.CHANGE_LOGO.VALIDATE_LOGO_IMAGE_SIZE, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                return false;
            }
            let websiteURL = getStringParam(this.state.website);
            if (isValidParam(websiteURL) && getStringParam(websiteURL)) {
                if (!this.isValidUrl(websiteURL)) {
                    let errorMsg = getLocalizedStrings().message.USEFUL_LINKS.VALID_URL;
                    this.props.showCustomSnackBar(errorMsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                    return false;
                } else {
                    this.doFormSubmit(websiteURL);
                }
            } else {
                sfDialogs.confirm(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.CHANGE_LOGO.VALIDATE_LOGO_WEBSITE_URL, this.doFormSubmit.bind(websiteURL), null);
            }
        }
        catch (error) {
            console.error("Error in 'ChangeLogo.js -> onFormSubmit()':" + error);
        }
    }

    doFormSubmit = (websiteURL) => {
        try {
            this.setState({ inProgress: true });
            const formData = new FormData();
            let attachedObj = this.state.attachment;

            let param = {};
            param.id = this.state.id;
            param.website = websiteURL;

            param.file_name = attachedObj.fileName;
            formData.append('input_param', JSON.stringify(param));
            formData.append('attached_file', attachedObj.file);

            let promise = uploadLogo(formData);
            promise.then((response) => {
                if (response.data.status === 0) {
                    let token = localStorage.getItem('token');
                    if (token !== null) {
                        this.props.setUserContextData(token);
                    }
                    this.props.showCustomSnackBar(getLocalizedStrings().message.CHANGE_LOGO.LOGO_IMAGE_UPLOAD, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                } else {
                    this.props.showCustomSnackBar(getLocalizedStrings().message.CHANGE_LOGO.LOGO_IMAGE_UPLOAD_FAILURE, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                }
                this.setState({ website: '', attachment: null, inProgress: false });
            });
        }
        catch (error) {
            console.error("Error in 'ChangeLogo.js -> doFormSubmit()':" + error);
        }
    }

    render() {

        if (this.state.inProgress === false) {
            return (
                <div style={{ marginBottom: '10px', paddingLeft: '3%', width: '97%' }}>
                    <div style={{ padding: '20px 20px 17px' }}>
                        <div className="row clearfix">
                            <label className="col-sm-3">{getLocalizedStrings().message.ATTACHMENT.UPLOAD_IMAGE}</label>
                            <input className="col-sm-9" type="file" name="img_name" id="img_to_upload" onChange={(e) => this.onChange(e)} />
                        </div>
                        <div className="row clearfix" style={{ paddingTop: '20px' }}>
                            <label className="col-sm-3">{getLocalizedStrings().message.CHANGE_LOGO.WEBSITE_URL}</label>
                            <div className="col-sm-9" >
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        name="website_name"
                                        id="website_to_visit"
                                        label={getLocalizedStrings().label.COMPANY_PROFILE.WEBSITE}
                                        fullWidth={true}
                                        value={this.state.website}
                                        onChange={this.handleChange.bind(this)}
                                        onKeyPress={this.restrictKey.bind(this)}
                                        autoComplete="new-password"
                                        margin='dense'
                                        size='small'
                                        className={"sf-fields-bg"}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className='dialogtxt'>
                        {getLocalizedStrings().message.CHANGE_LOGO.LOGO_IMAGE_ATTACHMENT_LOGO_TEXT}
                    </div>
                    <div className='dialogtxt' style={{ marginBottom: '0px' }}>
                        {getLocalizedStrings().message.CHANGE_LOGO.LOGO_IMAGE_SIZE_TEXT}
                    </div>
                    <div className="row" style={{ marginBottom: '25px', marginTop: '25px' }}  >
                        <div className="col-sm-12" style={{ textAlign: 'right' }}>
                            {this.getActions()}
                        </div>
                    </div>
                </div>
            );
        } else {
            let contentHeight = window.innerHeight - 240;
            let top = (contentHeight - 10) / 2;
            return (
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            );
        }
    }
}
const mapStateToProps = (state) => {
    return {
        app: state.app,
        appContainer: state.appContainer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
        setUserContextData: (token) => {
            dispatch(setUserContextData(token));
        },
        setHeader: (selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader) => {
            dispatch(setHeader(selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader));
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangeLogo);
