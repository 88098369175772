
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Select, MenuItem, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import { styles } from '../../../services/constants/styles';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { saveGroup, saveSource } from '../../../services/actions/batchActions';
import * as sfDialogs from '../components/sfDialogs';
import { addGroupIntoReduxStore, hasAccessPermission } from '../../../services/helper/common';
import { isValidParam, getArrayParam, getBooleanParam, getStringParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { getWorkflowList, getWorkflowStageById, getSourceList, getFieldSizeByName } from '../../../services/actions/importAction';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import * as SFFormActions from '../../../services/actions/sfFormActions';
import { getSFFormFieldsPromise } from '../../../services/actions/sfFormActions';
import { isAssignRuleExists } from '../../../services/actions/assignmentRuleActions';
import { addTab as addTabObj, TYPE_LIST_VIEW, getActiveTab } from '../../../services/helper/sfTabManager';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { getMyGroups, setGroups } from '../../../services/actions/filterMenuAction';
import { useDispatch, useSelector } from "react-redux";
import { setCalendarSetup } from '../../../services/actions/appActions';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { dateTimeFormat, dateFormat, timeFormat, timeZone } from '../../../services/helper/utils';
import tz from 'moment-timezone';
const ImportFileDetails = ({ object, setImportFileDetail }) => {

    // this._input = null;
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const groups = useSelector((state) => state.filters.groups);
    const sfFormFields = useSelector((state) => state.sfForm.data);
    const [stateobject, setstateobject] = useState([]);
    const [groupList, setgroupList] = useState(null);
    const [selectedGroup, setselectedGroup] = useState(null);
    const [workflowList, setworkflowList] = useState([]);
    const [workflowMounted, setworkflowMounted] = useState(false);
    const [selectedWorkflow, setselectedWorkflow] = useState(null);
    const [statusMounted, setstatusMounted] = useState(false);
    const [tempStatusList, settempStatusList] = useState(null);
    const [selectedStatus, setselectedStatus] = useState(null);
    const [sourceList, setsourceList] = useState([]);
    const [sList, setSList] = useState([]);

    const [selectedSource, setselectedSource] = useState(null);
    const [currentDate, setcurrentDate] = useState(null);
    const [file, setfile] = useState({});
    const [importInfo, setimportInfo] = useState({
        groupId: 0,
        status: null,
        groupName: null,
        workflowId: 0,
        workflowName: null,
        groupLink: 0,
        source: null,
        tableList: [],
        checkBox: false,
        statusId: 0,
        assignRule: "0"
    });
    const [showGroupAddTextBox, setshowGroupAddTextBox] = useState(false);
    const [showSourceAddTextBox, setshowSourceAddTextBox] = useState(false);
    const [groupNameMaxLength, setgroupNameMaxLength] = useState(0);
    const [defaultStatus, setdefaultStatus] = useState(null);
    const [salesLeadsFields, setsalesLeadsFields] = useState(null);
    const [isstateAssignRuleExists, setisstateAssignRuleExists] = useState(false);
    const [isShrinkStatus, setisShrinkStatus] = useState(null);
    const [isShrinkWF, setisShrinkWF] = useState(null);

    const [selectedAssignOpt, setselectedAssignOpt] = useState("0");
    const [statusIdMounted, setstatusIdMounted] = useState(null);
    const [defaultWFName, setDefaultWFname] = useState(null);


    useEffect(() => {
        generateStatusList();
    }, [tempStatusList]);

    useEffect(() => {
        generateDefaultStatus();
    }, [tempStatusList, defaultStatus, importInfo]);
    useEffect(() => {
        let objectName = null;
        if (object === constants.SEARCH_RESULTS) {
            let activeTab = getObjectParam(getActiveTab());
            let activeTabInfo = getObjectParam(activeTab.info);
            let search = getObjectParam(activeTabInfo.search);
            objectName = getStringParam(search.object);
        } else if (object === constants.ADVANCE_SEARCH_RESULTS) {
            let activeTab = getObjectParam(getActiveTab());
            let advanceSearch = getObjectParam(activeTab.info.advanceSearch);
            objectName = getStringParam(advanceSearch.object);
        }
        else {
            objectName = object;
        }
        setstateobject(objectName);

        if (object === constants.ACCOUNTS_OBJECT ||
            object === constants.CUSTOM_TABLE1_OBJECT ||
            object === constants.CUSTOM_TABLE2_OBJECT ||
            object === constants.CUSTOM_TABLE3_OBJECT ||
            object === constants.CUSTOM_TABLE4_OBJECT ||
            object === constants.CONTACTS_OBJECT) {
            getWorkflow(objectName);
        }
        //      if (object === constants.ACCOUNTS_OBJECT ) {
        //     generateStatusList();
        // }
        if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.SALES_LEADS_OBJECT) {
            getSource();
            getFieldByName();
        } if (object === constants.SALES_LEADS_OBJECT) {
            var promise = getSFFormFieldsPromise('salesleads');
            promise.then((response) => {
                if (isValidParam(response)) {
                    setsalesLeadsFields(response.fields);
                }
            });
        }

        if (object === constants.ACCOUNTS_OBJECT) {
            isAssignmentRuleExists();
        }
        setCalendarSetup(moment().format("MM/DD/YYYY"));
        // currentDate = moment().format("MM/DD/YYYY");

        generateGroupList();

    }, []);
    useEffect(() => {
        setworkflowMounted(true);
    }, [workflowList]);

    const generateGroupList = () => {
        let groupList = null;
        let arrayGroupList = groups;

        if (!isValidParam(arrayGroupList) || arrayGroupList.length === 0) {
            let promise = Promise.resolve(getMyGroups(object));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        arrayGroupList = getArrayParam(response.groups)
                        dispatch(setGroups(response));

                        arrayGroupList = arrayGroupList.filter(f => { return f.status === "Active" });
                        sortArrayObjectByProperty(arrayGroupList, 'name');
                        groupList = arrayGroupList.map((objGroup, i) => {
                            return (
                                <MenuItem value={objGroup.id}>{objGroup.name}</MenuItem>
                            );
                        });
                        setgroupList(groupList);
                    }
                });
            }
        } else {
            arrayGroupList = arrayGroupList.filter(f => { return f.status === "Active" });
            sortArrayObjectByProperty(arrayGroupList, 'name');
            groupList = arrayGroupList.map((objGroup, i) => {
                return (
                    <MenuItem value={objGroup.id}>{objGroup.name}</MenuItem>
                );
            });
            setgroupList(groupList);
        }

    }

    const changeGroup = (event) => {
        let value = event.target.value;
        if (value === '-1') {
            let hasPermission = null;
            hasPermission = hasAccessPermission(constants.GROUP_OBJECT, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_CREATE);
            if (hasPermission) {
                setselectedGroup(value);
                setshowGroupAddTextBox(true);
                let temp = importInfo;
                temp.groupId = value;
                setimportInfo(temp);
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
            }
        } else {
            setselectedGroup(value);
            setshowGroupAddTextBox(false);
            let temp = importInfo;
            temp.groupId = value;
            setimportInfo(temp);
            setImportFileDetail(file, importInfo);
        }
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (isValidParam(this._input)) {
    //         this._input.focus();
    //     }
    // }

    const saveGroupData = () => {
        let object = null;
        var groupName = document.getElementById('groupName').value;
        groupName = groupName.trim();
        let params = {
            "id": 0,
            "name": groupName,
            "description": groupName,
            "t_status": "Active"
        }
        if (groupName !== '') {
            if (groupName.trim().length <= 128) {
                var response = saveGroup(object, params);
                response.then((response) => {
                    if (response.status === 0) {
                        if (response.data.id > 0) {
                            addGroupIntoReduxStore(response.data.id, groupName, params.t_status);
                            generateGroupList();
                            setshowGroupAddTextBox(false);
                            setselectedGroup(response.data.id);
                            let tempInfo = importInfo;
                            tempInfo.groupId = response.data.id;
                            setimportInfo(tempInfo);
                            setImportFileDetail(file, importInfo);
                        } else {
                            if (response.data.id === -1) {
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.BATCH_ACTION_VALIDATION.GROUP_ALREADY_EXIST, null);
                                return 0;
                            }
                        }
                    }
                });
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.BROADCAST_CAMPAIGNS.GROUP_NAME_SIZE_LIMIT, null);
            }
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.BATCH_ACTION_VALIDATION.ADDTOGROUP_NAMEBLANK, null);
        }
    }

    const getWorkflow = (objectName) => {
        let promise = Promise.resolve(getWorkflowList(objectName));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    setworkflowList(response);
                }
            });
        }
    }

    const generateWorkflowList = () => {
        let tempWorkflowList = null;
        let arrayWorkflowList = getArrayParam(workflowList);
        if (object === constants.ACCOUNTS_OBJECT) {
            if (app.me.is_franchisor) {
                arrayWorkflowList = arrayWorkflowList.filter(e => (e.name.toLowerCase() !== 'greenlight' && e.name !== 'Onboarding' && e.name !== 'Franchisee'));
            }
            else {
                arrayWorkflowList = arrayWorkflowList.filter(e => (e.name.toLowerCase() !== 'greenlight'));
            }

        }
        if (isValidParam(arrayWorkflowList) && arrayWorkflowList.length > 0) {
            tempWorkflowList = arrayWorkflowList.map((objWorkflow, i) => {
                return (
                    <MenuItem key={`workflow-list-${i}`} value={objWorkflow.id}>{objWorkflow.name}</MenuItem>
                );
            });
        }
        if (arrayWorkflowList !== null && arrayWorkflowList.length === 1) {
            let tempInfo = importInfo;
            tempInfo.workflowId = arrayWorkflowList[0].id;
            tempInfo.workflowName = arrayWorkflowList[0].name;
            if (object != constants.CONTACTS_OBJECT) {
                //   setimportInfo(tempInfo);
            }

            if (!statusMounted) {
                getWorkStageByWorkflowId(arrayWorkflowList[0].id);
            }
        }
        return tempWorkflowList;
    }

    const changeWorkflow = (event) => {
        let value = event.target.value;
        setselectedWorkflow(value);
        setisShrinkStatus(true);
        let selectedWF = null
        let arrayWorkflowList = getArrayParam(workflowList);
        if (isValidParam(arrayWorkflowList) && arrayWorkflowList.length > 0) {
            selectedWF = arrayWorkflowList.filter(f => { return f.id === value });
        }
        setselectedStatus(null);
        setdefaultStatus(null);
        let tempImport = importInfo;
        tempImport.workflowId = value;
        if (isValidParam(selectedWF)) {
            tempImport.workflowName = selectedWF[0].name;
        }
        if (value === 0) {
            tempImport.status = null;
        }
        setimportInfo(tempImport);
        setImportFileDetail(file, importInfo);

        if (value > 0) {
            getWorkStageByWorkflowId(value);

        } else {
            setstatusMounted(false);
            setselectedWorkflow(null);
        }

    }

    const getWorkStageByWorkflowId = (value) => {
        let params = {
            workflow_id: value
        }
        let promise = Promise.resolve(getWorkflowStageById(params));
        // var promise = Promise.resolve(HTTPClient.get(endPoints.WORKFLOWS.LIST_STAGES_GET, params));

        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    setstatusMounted(true);

                    settempStatusList(response.stages);
                }
            });
        }

    }

    const generateStatusList = () => {
        let statusList = null;
        let arrayStatusList = tempStatusList;
        let tempInfo = importInfo;
        if (isValidParam(arrayStatusList)) {
            statusList = tempStatusList.map((objStatus, i) => {
                return (
                    <MenuItem key={`workflow-status-list-${i}`} value={objStatus.name} >{objStatus.name}</MenuItem>
                );
            });
        }
        tempInfo.status = selectedStatus === null ? defaultStatus : selectedStatus;
        //    setimportInfo(tempInfo);
        //  setImportFileDetail(file, importInfo);

        return statusList;
    }
    const generateDefaultStatus = () => {
        let arrayStatusList = tempStatusList;
        let tempInfo = importInfo;
        if (isValidParam(arrayStatusList)) {
            tempStatusList.map((objStatus, i) => {
                if (i === 0) {
                    let statusName = objStatus.name;
                    setdefaultStatus(statusName)
                    tempInfo.statusId = objStatus.id;
                    setimportInfo(tempInfo);
                }
                return false
            });
        }
        tempInfo.status = selectedStatus === null ? defaultStatus : selectedStatus;
        setimportInfo(tempInfo);
        setImportFileDetail(file, importInfo);

    }
    const generateStatusListForContact = () => {
        let statusList = null;
        let statusFieldArr = [];
        let contactFields = sfFormFields.contacts.fields;
        contactFields = getArrayParam(contactFields);
        statusFieldArr = contactFields.filter(f => { return f.name === 't_status' });
        if (statusFieldArr !== null && statusFieldArr.length > 0 && statusFieldArr[0].hasOwnProperty('list_values')) {
            statusFieldArr = getArrayParam(statusFieldArr[0].list_values);
        }
        if (statusFieldArr !== null) {
            statusList = statusFieldArr.map((objStatus, i) => {
                return (
                    <MenuItem value={objStatus.value}  >{objStatus.value}</MenuItem>
                );
            });

        }
        return statusList;
    }

    const generateStatusListForSalesLeads = () => {
        let _salesLeadsFields = salesLeadsFields;
        let statusList = null;
        let statusFieldArr = [];
        _salesLeadsFields = getArrayParam(_salesLeadsFields);
        statusFieldArr = _salesLeadsFields.filter(f => { return f.name === 't_status' });
        if (statusFieldArr !== null && statusFieldArr.length > 0 && statusFieldArr[0].hasOwnProperty('list_values')) {
            statusFieldArr = getArrayParam(statusFieldArr[0].list_values);
        }
        if (statusFieldArr !== null) {
            statusList = statusFieldArr.map((objStatus, i) => {
                return (
                    <MenuItem value={objStatus.value} > {objStatus.value} </MenuItem>
                );
            });

        }
        return statusList;

    }

    const changeStatus = (event) => {
        let value = event.target.value;
        setselectedStatus(value);

        let tempInfo = importInfo;

        tempInfo.status = value;

        if (tempStatusList !== null && tempStatusList.length > 0) {

            tempStatusList.map((objStatus, i) => {
                if (objStatus.name === value) {
                    tempInfo.statusId = objStatus.id;
                    setimportInfo(tempInfo);
                    // importInfo.statusId = objStatus.id;
                }
            });
        }
        setstatusIdMounted(true);
        setimportInfo(tempInfo);
        setImportFileDetail(file, tempInfo);
    }

    const getSource = () => {
        let list = [];
        let promise = Promise.resolve(getSourceList());
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    list = response.records.map((source) => {
                        return source.t_source_name
                    })
                    setsourceList(response.records)
                }

            });
        }
    }

    const generateSourceList = () => {
        let arraySourceList = sourceList;
        sortArrayObjectByProperty(arraySourceList, 't_source_name');
        if (isValidParam(sourceList)) {
            arraySourceList = sourceList.map((objSource, i) => {
                return (
                    <MenuItem value={objSource.t_source_name} >{objSource.t_source_name} </MenuItem>
                );
            });
        }
        // let temp=importInfo
        //        temp.source = selectedSource;
        //        setimportInfo(temp)
        //  setImportFileDetail(file, importInfo);

        return arraySourceList;
    }

    const changeSource = (event) => {
        let value = event.target.value;
        let tempInfo = importInfo;

        if (value === '-1') {
            setselectedSource(value);
            setshowSourceAddTextBox(true);
            tempInfo.source = value;
            setimportInfo(tempInfo);
        } else {
            setselectedSource(value);
            setshowSourceAddTextBox(false);
            tempInfo.source = value;
            setimportInfo(tempInfo);
            setImportFileDetail(file, importInfo);
        }
    }

    const save_source = () => {
        var sourceName = document.getElementById('sourceName').value;
        sourceName = sourceName.trim();
        let params = {
            "t_pk": 0,
            "t_source_name": sourceName
        }
        if (sourceName !== '') {
            var response = saveSource(params);
            response.then((response) => {
                if (response.status === 0) {
                    if (response.data.id > 0) {
                        let newSourceObject = [];
                        newSourceObject.id = response.data.id;
                        newSourceObject.t_source_name = sourceName;
                        sourceList.push(newSourceObject);
                        setsourceList(sourceList)

                        SFFormActions.addSourceOnRedux(constants.ACCOUNTS_OBJECT, 'source', sourceName);
                        SFFormActions.addSourceOnRedux(constants.CONTACTS_OBJECT, 'source', sourceName);

                        generateSourceList();
                        setshowSourceAddTextBox(false);
                        setselectedSource(sourceName);
                        let tempInfo = importInfo;
                        tempInfo.source = sourceName;
                        setimportInfo(tempInfo);
                        setImportFileDetail(file, importInfo);
                    }
                } else {
                    if (response.status === -1) {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.SOURCR_NAME_EXISTS, null);
                        return 0;
                    }
                }
            });
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.SOURCR_NAME_BLANK, null);
        }
    }

    const onChange = (e) => {
        let tempfile = {};
        tempfile.fileName = e.target.files[0].name;
        tempfile.file = e.target.files[0];
        setfile(tempfile);
        setImportFileDetail(tempfile, importInfo);
    }

    const onChecked = (event, isInputChecked) => {
        let tempInfo = importInfo;
        tempInfo.checkBox = isInputChecked;
        setimportInfo(tempInfo);
        setImportFileDetail(file, importInfo);
    }

    const getFieldByName = () => {
        let params = {};
        params.field_name = 'name';
        params.table_id = 322;
        // let temp=getFieldSizeByName(params);
        let promise = Promise.resolve(getFieldSizeByName(params));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    let maxLength = response['name'];
                    if (maxLength > 0) {
                        setgroupNameMaxLength(maxLength);
                    }
                }
            });
        }
    }

    const isAssignmentRuleExists = () => {
        let paramObj = { table_id: OBJECT_TABLEID_MAP[object] };
        var response = Promise.resolve(isAssignRuleExists(paramObj));
        response.then((response) => {
            if (response.hasOwnProperty('isExists')) {
                if (getBooleanParam(response.isExists)) {
                    setisstateAssignRuleExists(true);
                }
            }
        });
    }

    const changeAssignSetup = (event) => {
        let value = event.target.value;
        setselectedAssignOpt(value);
        if (value === 'Setup Assignment Rule') {
            let tab = {
                item: object,
                label: getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULES,
                object: 'assignmentrules',
                imgName: 'fas fa-cog',
                url: '/setting/module',
                type: TYPE_LIST_VIEW,
            };
            tab.info = { filter: { id: 0, name: 'Assignment Rule', type: '' } };
            tab.info.selectedItem = { id: 0, name: 'assignmentrules', label: getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULES, type: "menu-item", object: 'assignmentrules' };
            addTabObj(tab, true);

        }
        let tempInfo = importInfo;
        tempInfo.assignRule = value;
        setimportInfo(tempInfo);
        setImportFileDetail(file, importInfo);

    }

    const openAssignmentRuleDrawer = () => {
        let drawerName = getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULE;

        let obj = object;
        let data = {};
        data.object = constants.ASSIGNMENT_RULES;
        data.id = null;
        data.table = obj;
        data.mode = constants.NEW;
        data.callFrom = 'Assignment Rules';

        dispatch(getAppDrawer(true, drawerName, constants.NEW, data, null));
    }
    useEffect(() => {
        let arrayWorkflowList = getArrayParam(workflowList);
        if (arrayWorkflowList.length > 0) {
            let temp = importInfo;
            temp.workflowId = arrayWorkflowList[0].id
            setimportInfo({ ...temp })
            setImportFileDetail(file, importInfo);
        }
        if (arrayWorkflowList.length > 0) {
            getWorkStageByWorkflowId(arrayWorkflowList[0].id);

        }

    }, [defaultWFName, workflowList])


    let tempworkflowList = [];
    let showGroupList = false;
    let showSourceList = false;
    let showWorkflowList = false;
    let showCheckBox = false;
    let showAddNewSource = false;
    if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT) {

        showGroupList = true;
    }
    if (object === constants.ACCOUNTS_OBJECT || object === constants.CUSTOM_TABLE1_OBJECT
        || object === constants.CUSTOM_TABLE2_OBJECT || object === constants.CUSTOM_TABLE3_OBJECT
        || object === constants.CUSTOM_TABLE4_OBJECT || object === constants.CONTACTS_OBJECT) {
        if (workflowMounted) {
            tempworkflowList = generateWorkflowList();
            if (workflowMounted && tempworkflowList !== null && tempworkflowList.length > 1) {
                showWorkflowList = true;
            }
        }

    }
    let defaultWorkflowName;
    let arrayWorkflowList = getArrayParam(workflowList);

    if (arrayWorkflowList.length > 0) {
        defaultWorkflowName = arrayWorkflowList[0].id;
    }
    let statusList = [];
    if (statusMounted && object !== constants.CONTACTS_OBJECT) {
        statusList = generateStatusList();
    }
    else if (statusMounted && object === constants.CONTACTS_OBJECT && selectedWorkflow !== null) {
        statusList = generateStatusList();
    }
    else if (object === constants.CONTACTS_OBJECT && (selectedWorkflow === null || selectedWorkflow === 0)) {
        statusList = generateStatusListForContact();
    } else if (object === constants.SALES_LEADS_OBJECT) {
        statusList = generateStatusListForSalesLeads();
    }

    if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.SALES_LEADS_OBJECT) {
        let tempsourceList = generateSourceList();
        //  tempsourceList = generateSourceList();
        showSourceList = true;
        showCheckBox = true;
        if (app.me.is_tenant || app.me.id === 1) {
            showAddNewSource = true;
        }
    }

    let workflowLabel = getLocalizedStrings().label.IMPORT.WORKFLOW;

    if (object === constants.CONTACTS_OBJECT) {
        workflowLabel = getLocalizedStrings().label.IMPORT.WORKFLOW_OPTIONAL;
    }
    let _currentDate = moment().tz(timeZone[app.me.timezone]);
    _currentDate = _currentDate.format("MM/DD/YYYY");

    let tempsourceList = generateSourceList();
    return (
        <div style={{ paddingTop: '33px', paddingLeft: '12px', paddingRight: '20px' }}>
            <div className='row clearfix ten' style={{}}>
                <div className='col-sm-6' style={{ paddingRight: '27px' }}>
                    <div>
                        <input type="file" style={{ fontSize: 16 }} name="import_file" id="import_file" onChange={(e) => onChange(e)} />
                    </div>
                    <div style={{ paddingTop: '10px', fontSize: 14 }}>
                        {getLocalizedStrings().message.IMPORT.FILE_TYPE_MSG} <br />
                        {getLocalizedStrings().message.IMPORT.DATE_FORMAT_MSG} e.g. {_currentDate}
                    </div>

                    {(showCheckBox) &&
                        <div style={{ paddingTop: '10px' }}>
                            <FormControlLabel
                                control={<Checkbox
                                    color="default"
                                    key='sales_rep'
                                    name='verifiedEmail'
                                    onChange={(event, isInputChecked) => onChecked(event, isInputChecked)}
                                    defaultChecked={false} />}
                                labelposition="right"
                                style={{ height: 'auto', paddingBottom: '10px' }}
                                labelstyle={{ color: '#717171', padding: '0px', width: 'auto', fontWeight: 'normal' }}
                            />
                            <span style={{ width: "100%", display: "flex", textAlign: "justify", fontSize: '14px' }}>
                                {getLocalizedStrings().message.IMPORT.VERIFY_CONTACTS_MSG_1}{" "}
                                {getLocalizedStrings().message.IMPORT.VERIFY_CONTACTS_MSG_2}{" "}
                                {getLocalizedStrings().message.IMPORT.VERIFY_CONTACTS_MSG_3}{" "}
                                {getLocalizedStrings().message.IMPORT.VERIFY_CONTACTS_MSG_4}{" "}
                                {getLocalizedStrings().message.IMPORT.VERIFY_CONTACTS_MSG_5}{" "}
                            </span>

                        </div>
                    }
                </div>

                <div className='col-sm-6' style={{ paddingRight: '2px' }}>
                    {(showGroupList) &&
                        <div>
                            <FormControl style={{ width: '100%' }} variant="outlined">
                                <InputLabel id="group-label" style={{ lineHeight: 1, top: '-1px' }} >{getLocalizedStrings().label.IMPORT.GROUP}</InputLabel>
                                <Select
                                    labelId='sf-label-group'
                                    id='sf-group'
                                    defaultValue={selectedGroup}
                                    onChange={(event) => changeGroup(event)}
                                    label={getLocalizedStrings().label.IMPORT.GROUP}
                                    style={{ height: '42px' }}
                                    className={"sf-fields-bg"}
                                >
                                    {groupList}
                                    <MenuItem value={"-1"} >{"---" + getLocalizedStrings().label.IMPORT.ADD_NEW_GROUP + "---"}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>}
                    {(showGroupAddTextBox) && <div style={{ marginTop: '15px' }}>
                        <FormControl style={{ width: '50%' }} className="test" noValidate autoComplete="off">
                            <TextField variant="standard" type='text' hintText={getLocalizedStrings().label.GROUP.GROUP_NAME} id='groupName' hintStyle={{
                                color: '#717171', fontWeight: 'normal',
                                fontSize: '12px', width: '150px'
                            }} style={{ width: '98%', marginRight: '10px', marginTop: '-2px' }}
                                textareaStyle={{ color: '#fff' }}
                                // ref={(c) => this._input = c}
                                inputprops={{ maxLength: groupNameMaxLength }}
                                autoComplete="new-password"
                                className={"sf-fields-bg"} />
                        </FormControl>

                        <Button onClick={saveGroupData} style={styles.primaryButton} >{getLocalizedStrings().label.COMMON.SAVE} </Button>
                    </div>}

                    {(showWorkflowList) &&
                        <div style={{ paddingTop: '20px' }}>
                            <FormControl style={{ width: '100%' }} variant="outlined">
                                <InputLabel style={{ lineHeight: 1, top: '-1px' }} id="sf-importfiledetails-simple-select-outlined-label" className="sf-importfiledetails">{workflowLabel}</InputLabel>
                                <Select
                                    labelId='sf-label-workflow'
                                    id='sf-workflow'
                                    value={selectedWorkflow === null && object !== constants.CONTACTS_OBJECT ? defaultWorkflowName : selectedWorkflow}
                                    onChange={(event) => changeWorkflow(event)}
                                    label={workflowLabel}
                                    style={{ height: '42px' }}
                                    className={"sf-fields-bg"}
                                >
                                    {tempworkflowList}
                                </Select>
                            </FormControl>
                        </div>
                    }


                    {(selectedStatus === null && defaultStatus === null) &&
                        <div style={{ paddingTop: '20px' }}>
                            <FormControl style={{ width: '100%' }} variant="outlined">
                                <InputLabel htmlFor="outlined_Status_Label" style={{ lineHeight: 1, top: '-1px' }} >
                                    {getLocalizedStrings().label.IMPORT.STATUS}
                                </InputLabel>
                                <Select
                                    name='sf-status'
                                    key='sf-status'
                                    id='sf-status'
                                    variant="outlined"
                                    value={selectedStatus === null ? defaultStatus : selectedStatus}
                                    onChange={(event) => changeStatus(event)}
                                    label={getLocalizedStrings().label.IMPORT.STATUS}
                                    className={"sf-fields-bg"}
                                    style={{ height: '42px' }}
                                    inputprops={{
                                        name: 'Status_Label',
                                        id: 'outlined_Status_Label',
                                    }}
                                >
                                    {statusList}
                                </Select>
                            </FormControl>
                        </div>
                    }
                    {(selectedStatus !== null || defaultStatus !== null) &&
                        <div style={{ paddingTop: '20px' }}>
                            <FormControl style={{ width: '100%' }} variant="outlined">
                                <InputLabel shrink htmlFor="outlined_Status_Label" style={{ lineHeight: 1, top: '-3px' }} >
                                    {getLocalizedStrings().label.IMPORT.STATUS}
                                </InputLabel>
                                <Select
                                    name='sf-status'
                                    key='sf-status'
                                    id='sf-status'
                                    variant="outlined"
                                    value={selectedStatus === null ? defaultStatus : selectedStatus}
                                    onChange={(event) => changeStatus(event)}
                                    label={getLocalizedStrings().label.IMPORT.STATUS}
                                    className={"sf-fields-bg"}
                                    style={{ height: '42px' }}
                                    inputprops={{
                                        name: 'Status_Label',
                                        id: 'outlined_Status_Label',
                                    }}
                                >
                                    {statusList}
                                </Select>
                            </FormControl>
                        </div>
                    }

                    {(showSourceList) &&
                        <div style={{ paddingTop: '20px' }}>
                            <FormControl style={{ width: '100%' }} variant="outlined">
                                <InputLabel id="source-label" style={{ lineHeight: 1, top: '-1px' }} >{getLocalizedStrings().label.IMPORT.SOURCE}</InputLabel>
                                <Select
                                    labelId='sf-label-source'
                                    id='sf-source'
                                    value={selectedSource}
                                    onChange={(event) => changeSource(event)}
                                    label={getLocalizedStrings().label.IMPORT.SOURCE}
                                    style={{ height: '42px' }}
                                    className={"sf-fields-bg"}
                                    fullWidth={true}
                                >
                                    <MenuItem value={"-111"} >{getLocalizedStrings().label.COMMON.IMPORT_CSV_SOURCE_FIELD_HEADER}</MenuItem>
                                    <MenuItem value={"-222"} >{"--------------------------------------------------------"}</MenuItem>
                                    {tempsourceList}
                                    {(showAddNewSource) &&
                                        <MenuItem value={"-1"} >{"----" + getLocalizedStrings().label.COMMON.ADD_NEW_SOURCE + "----"}</MenuItem>}
                                </Select>
                            </FormControl>
                        </div>
                    }
                    {(showSourceAddTextBox) && <div style={{ marginTop: '15px' }}>
                        <FormControl style={{ width: '50%' }} className="test" noValidate autoComplete="off">
                            <TextField variant="standard" type='text' hintText={getLocalizedStrings().label.SOURCE.SOURCE_NAME} id='sourceName' hintStyle={{
                                color: '#717171', fontWeight: 'normal',
                                fontSize: '12px', width: '150px'
                            }} style={{ width: '98%', marginRight: '10px', marginTop: '-2px' }}
                                textareaStyle={{ color: '#fff' }}
                                autoFocus
                                autoComplete="new-password"
                                className={"sf-fields-bg"} />
                        </FormControl>
                        <Button onClick={() => save_source()} style={styles.primaryButton} >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                    </div>}
                    {(object === constants.ACCOUNTS_OBJECT) &&

                        <div style={{ paddingTop: '20px' }}>
                            <FormControl style={{ width: '100%' }} variant="outlined">
                                <InputLabel id="sf-importfile-details-from-importfile-simple-select-outlined-label" className="sf-importfile-details-from-importfile">{getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULE}</InputLabel>
                                <Select
                                    labelId='sf-label-rule'
                                    id='sf-rule'
                                    value={selectedAssignOpt}
                                    onChange={(event) => changeAssignSetup(event)}
                                    label={getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULE}
                                    style={{ height: '42px' }}
                                    className={"sf-fields-bg"}
                                    fullWidth={true}
                                >
                                    {isstateAssignRuleExists &&
                                        <MenuItem value={"Use Assignment Rule"}>{"Use Assignment Rule"} </MenuItem>
                                    }
                                    <MenuItem value={"0"}>{"--------------------------------------------------------"}</MenuItem>

                                    {(getStringParam(app.me.t_name) === 'Admin' ||
                                        getBooleanParam(app.me.is_tenant)) &&
                                        <MenuItem value={"Setup Assignment Rule"} >{"Setup Assignment Rule"}</MenuItem>
                                    }

                                </Select>
                            </FormControl>
                        </div>}
                </div>
            </div>
        </div>
    );

}

export default ImportFileDetails;