import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styles } from '../../../services/constants/styles';
import PopOver from '../components/PopOver';
import { dateFormat, sortArrayObjectByProperty, dateFormatFlatPicker } from '../../../services/helper/utils';
import { Select, MenuItem, InputLabel, Icon, FormControl } from '@mui/material';
import Button from '@mui/material/Button';
import moment from 'moment';
import { isValidParam, getStringParam, getArrayParam, getBooleanParam, getIntParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { setRecentReportResponseData, drillDownMatrixReport, processChartData } from '../../../services/actions/reportActions';
import { MatrixReportTable, ReactChart, matrixReportInitialData } from '../components/reportMatrixTable.js'
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import ShowCircularProgress from '../components/circularProgress';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { constants } from '../../../services/constants/constants';
import { updatePipelineReportWorkflows } from '../../../services/actions/pipelineReportActions';

const optionvalue = {
    td: 'Today',
    yd: 'Yesterday',
    tw: 'This Week',
    tm: 'This Month',
    tq: 'This Quarter',
    ty: 'This Year',
    lw: 'Last Week',
    lm: 'Last Month',
    lq: 'Last Quarter',
    ly: 'Last Year',
    cust: 'Custom'

};

const formatDate = (date) => {
    let d = date instanceof Date ? date : new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}
const ServiceReportView = ({object,reportHeaderNames,selectedIndex}) => {

    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const report = useSelector((state) => state.report);
    const pipelineReport = useSelector((state) => state.pipelineReport);
    const userlist = useSelector((state) => state.userlist);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [reportResponseData ,setReportResponseData] = useState({});
    const [reportParams ,setReportParams] = useState({
        recent_record_id: 0, // recentreport Id 
        parameter_id: 0, // Report Id
        item_type: "",
        due_by: 'ty',
        user_names: "All",
        from_date: null,
        to_date: null,
        type: [],
        object_name: '',
        selectedFrnProjectId: null,
        selectedFranchise: null,
        selectedSalesRep: null
    },)
    const [recent_record_id ,setRecent_record_id] = useState(0);
    const [customTimeLineDisplay ,setCustomTimeLineDisplay] = useState('none');
    const [objectNameDisplay ,setObjectNameDisplay] = useState('none');
    const [dueByPopup ,setDueByPopup] = useState(false);
    const [userNamesPopup ,setUserNamesPopup] = useState(false);
    const [objectNamePopup ,setObjectNamePopup] = useState(false);
    const [franchiseList ,setFranchiseList] = useState([]);
    const [isGlobal ,setIsGlobal] = useState(false);
    const [isCountry ,setIsCountry] = useState(false);
    const [isFranchise ,setIsFranchise] = useState(false);
    const [isFranchiseListShow ,setIsFranchiseListShow] = useState(false);
    const [isFranchiseSalesRepShoe ,setIsFranchiseSalesRepShoe] = useState(false);
    const [salesRepMenu ,setSalesRepMenu] = useState(null);
    const [salesRepList ,setSalesRepList] = useState([]);
    const [objectValue ,setObjectValue] = useState({
        accounts: getLocalizedStrings().label.REPORT.REPORT_ON_OBJECTS.ACCOUNTS,
        opportunities: getLocalizedStrings().label.REPORT.REPORT_ON_OBJECTS.OPPORTUNITIES
    });
    const [workflowDisplay ,setWorkFlowDisplay] = useState('none');
    const [workflowNames ,setWorkFlowNames] = useState(null);
    const [workflowId ,setWorkflowId] = useState(-99);
    const [workflowList ,setWorkflowList] = useState(null);
    const [workflowListByObj ,setWorkflowListByObj] = useState({
        accounts: [],
        opportunities: []
    });
    const [setSelectedFrnProjectId] = useState(null);
    const [selectedFranchise, setSelectedFranchise] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [objectName, setObjectName] = useState('');
    const [fieldName, setFieldName] = useState(false);
    const [name,setName] = useState(false);
    
    useEffect(() => {
        if (object === report.reportObject) {
            loadRecentReport(reportHeaderNames[report.selectIndex]);
          }
        else {
            report.selectIndex = 0;
            loadRecentReport(reportHeaderNames[0]);
          }
    },[]);

    const loadRecentReport = (objRecentReport) => {
        let from_date = null;
        let to_date = null;
        var promise1 = Promise.resolve(HTTPClient.get(endPoints.SALES_REPORT.RECENT_REPORT, objRecentReport));
        promise1.then((response) => {
             let _reportParams = reportParams;
            if (Object.keys(response).length !== 0) {
            
                _reportParams.parameter_id = response.parameter_id;
                _reportParams.item_type = response.item_type;
                _reportParams.due_by = response.due_by;
                
                setRecent_record_id(response.recent_record_id);
                // setReportParams(_reportParams);
                if (response.due_by === "cust") {
                     from_date = moment(getStringParam(response.from_date)).format(dateFormat[app.me.date_format]);
                     to_date = moment(getStringParam(response.to_date)).format(dateFormat[app.me.date_format]);
                    setFromDate(from_date);
                    setToDate(to_date);
                    setCustomTimeLineDisplay('block');
                } else {
                    setFromDate('');
                    setToDate('');
                    setCustomTimeLineDisplay('none');
                }
               
                if (isValidParam(response.object_name) && response.object_name.length > 0) {
                     _reportParams.user_names = response.user_names;
                    _reportParams.object_name = response.object_name;
                   
                } else {
                    
                    _reportParams.object_name = constants.ACCOUNTS_OBJECT;
                    // setReportParams(_reportParams);
                }
                if (isValidParam(response.workflow_id)) {
                    setWorkflowId(response.workflow_id);
                }
                if (getStringParam(response.itemType) === "InXpress Report 18" && getBooleanParam(response.isCountry)) {
                    _reportParams.parameter_id = response.linkId;
                    _reportParams.item_type = response.itemType;

                    let _franchiseList = getArrayParam(response.franchises);
                    setReportParams({..._reportParams});
                    setFranchiseList([..._franchiseList]);
                    setIsGlobal(response.isGlobal);
                    setIsCountry(response.isCountry);
                    setIsFranchise(response.isFranchise);
                    setIsFranchiseListShow(response.isFranchiseListShow);
                    setIsFranchiseSalesRepShoe(response.isFranchiseSalesRepShoe);
                    setSelectedFrnProjectId(franchiseList[0].projectId);
                    setSelectedFranchise(franchiseList[0].value);
                }
                setReportParams({..._reportParams});

                if (reportHeaderNames[report.selectIndex].item_name === constants.PIPELINE_REPORT) {
                    loadWorkflowByObject();
                } else {
                    loadReportByIndex(report.selectIndex,from_date,to_date);
                 }
            } else {
                report.showProgressBar = true;
                _reportParams.parameter_id = reportHeaderNames[report.selectIndex].parameter_id;
                _reportParams.item_type = reportHeaderNames[report.selectIndex].item_type;
                _reportParams.recent_record_id = 0;
               _reportParams.object_name = constants.ACCOUNTS_OBJECT;

                setReportParams({..._reportParams});
                if (reportHeaderNames[report.selectIndex].item_name === constants.PIPELINE_REPORT) {
                    loadWorkflowByObject();
                } else {
                    loadReportByIndex(report.selectIndex,fromDate,toDate);
                }
            }
        });
    }

    const loadWorkflowByObject = () => {
        let reportHeaders = reportHeaderNames;
        let selectedReportIndex = report.selectIndex;
        if (reportHeaders[selectedReportIndex].item_name === constants.PIPELINE_REPORT) {
            let pipelineReportWorkflows = getObjectParam(pipelineReport.pipelineReportWorkflows);
            if (isValidParam(pipelineReportWorkflows) && Object.keys(pipelineReportWorkflows).length > 0) {
                setWorkflowListByObj(pipelineReportWorkflows);
                let workflowListData = getArrayParam(pipelineReportWorkflows[reportParams.object_name]);
                let _workflowId = workflowId;
                let _workflowName = workflowNames;
               
                if (workflowListData.length > 0 && _workflowId === -99) {
                    _workflowId = getIntParam(workflowListData[0].id);
                    _workflowName = getStringParam(workflowListData[0].name);
                    
                } else if (workflowListData.length > 0 && _workflowId > 0) {
                    workflowListData.forEach((f, i) => {
                        if (f.id === _workflowId) {
                            _workflowName = f.name;
                        }
                    });
                }
                setWorkflowList([...workflowListData]);
                setWorkflowId(_workflowId);
                setWorkFlowNames(_workflowName);
                loadReportByIndex(selectedReportIndex,fromDate,toDate);
            } else {
                getWorkflowList(reportHeaders[selectedReportIndex]);
            }
        } else {
            loadReportByIndex(selectedReportIndex,fromDate,toDate);
        }
    }

    const getWorkflowList = (objRecentReport) => {
        getWorkflowListByObject('accounts');
        getWorkflowListByObject('opportunities');
    }

    const getWorkflowListByObject = (object) => {
        let url = endPoints.WORKFLOWS.LIST_GET + "/" + object;
        try {
            let promise = Promise.resolve(HTTPClient.get(url, null));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let records = getArrayParam(response);
                    sortArrayObjectByProperty(records, "name");
                    if (records.length > 0) {
                        setWorkflowListByObj(records);
                        let _workflowListObj = getObjectParam(workflowListByObj);
                        if (_workflowListObj.hasOwnProperty('accounts') && _workflowListObj.hasOwnProperty('opportunities')) {
                            let accountWorkflowList = getArrayParam(_workflowListObj.accounts);
                            let oppWorkflowList = getArrayParam(_workflowListObj.opportunities);
                            if (accountWorkflowList.length > 0 && oppWorkflowList.length > 0) {
                                let workflowListData = getArrayParam(_workflowListObj[reportParams.object_name]);
                                dispatch(updatePipelineReportWorkflows(_workflowListObj));
                                let _workflowId = workflowId;
                                let _workflowName = workflowNames;
                                if (workflowListData.length > 0 && workflowId === -99) {
                                    _workflowId = getIntParam(workflowListData[0].id);
                                    _workflowName = getStringParam(workflowListData[0].name);
                                } else {
                                    workflowListData.forEach((f, i) => {
                                        if (f.id === workflowId) {
                                            _workflowName = f.name;
                                        }
                                    });
                                }
                                setWorkflowList(workflowListData);
                                setWorkflowId(_workflowId);
                                setWorkFlowNames(_workflowName);
                                loadReportByIndex(report.selectIndex,fromDate,toDate);
                            }
                        }
                    }
                }
            });
        } catch (error) {
            console.log("Error in 'salesServiceReport.js -> getWorkflowListByObject()':" + error)
        }
    }

    const handleRequestClose = (event,fieldName) => {
        try {
           setFieldName(false);
        
        }
        catch (error) {
            console.error("Error in 'pipelineReport.js -> handleRequestClose()':" + error);
        }
    };

    const generateDateSelectionField = () => {
        let optionsMultilingual = getMultilingualOptionsMap();
        try {
            return Object.keys(optionvalue).map((itemKey) => {
                return <MenuItem key={itemKey} value={itemKey} style={{ ...styles.popoverMenuItem}}>{optionsMultilingual[optionvalue[itemKey]]}</MenuItem>;
            });
        }
        catch (error) {
            console.error("Error in 'pipelineReport.js -> generateDateSelectionField()':" + error);
        }
    }

    const handleDropDownChange = (name, event, value) => {
       if (name === "due_by") {
            let _reportParams = reportParams;
            value = value.props.value;
         _reportParams[name] = value;
         setReportParams({..._reportParams});
            if (value === "cust") {
                let from_date = moment(getStringParam(new Date())).format(dateFormat[app.me.date_format]);
                let to_date = moment(getStringParam(new Date())).format(dateFormat[app.me.date_format]);
               
                setCustomTimeLineDisplay('block');
                setFromDate(from_date);
                setToDate(to_date);
                return;
            } else {
                 _reportParams.from_date = null;
                _reportParams.to_date = null;
                setReportParams({..._reportParams});
                setCustomTimeLineDisplay('none');
                handleRequestClose(name + "_popup");
                return;
            }
        }
        else if (name === "object_name" && reportHeaderNames[selectedIndex].item_name === constants.PIPELINE_REPORT) {
            var workflowlist = getArrayParam(workflowListByObj[value]);
            setWorkflowId(workflowlist[0].id);
            setWorkFlowNames(workflowlist[0].name);
            setWorkflowList([...workflowlist]);
            setObjectName(value);
            handleRequestClose(name + "_popup");
        }
        else if (name === "workflow_names") {
            let wf = workflowList.filter((f) => {
                return f.id === value;
            });
            setWorkflowId(wf[0].id);
            setWorkFlowNames(wf[0].name);
            handleRequestClose(name + "_popup");
        }
        else {
           
            handleRequestClose(name + "_popup");
            return;
        }
    }

    

    const handleDropDownChangeUserNames = (value) => {
        if (value === -99) {
            let _reportParams = reportParams;
            _reportParams['user_names'] = "All";
            setReportParams(_reportParams);
        } else {
            let user = userlist.data.filter((f) => {
                return f.id === value;
            });
            let _reportParams = reportParams;
            _reportParams['user_names'] = user[0].value;
            setReportParams(_reportParams);
        }
        return;
    }

    const handleDropDownChangeWorkflowName = (value) => {
        let _reportParams = reportParams;
        _reportParams['workflow_names'] = value;
        
        let wf = workflowList.filter((f) => {
            return f.id === value;
        });
        setWorkflowId(wf[0].id);
        setWorkFlowNames(wf[0].name);
        setReportParams(_reportParams);
        return;
    }

    const handleDropDownChangeObjectName = (value) => {
        let _reportParams = reportParams;
        _reportParams['object_name'] = value;
        setReportParams(_reportParams);
        if (reportHeaderNames[selectedIndex].item_name === constants.PIPELINE_REPORT) {
            var workflowlist = getArrayParam(workflowListByObj[value]);
            setWorkflowId(workflowlist[0].id);
            setWorkFlowNames(workflowlist[0].name);
            setWorkflowList(workflowlist);
            setObjectName(value);
            return;
        }
    }

    const handleDateChange = (date, name) => {
        // let field_name = name.replace('_', '-');
        // let dateTimeDiv = document.getElementsByClassName("dateTimeDiv-" + field_name);
        // if (date !==== '') {
        //     if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
        //         dateTimeDiv[0].classList.add('label-top');
        //     }
           
          setName(date);
        // }
        // else {
        //     if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
        //         dateTimeDiv[0].classList.remove('label-top');
        //     }
        //     setName('');
        // }
    }

    const generateUserNames = () => {
        let popOverListUserName = [];
        userlist.data.forEach((user, index) => {
            let data = {};
            data.label = user.value;
            data.value = user.id;
            popOverListUserName.push(data);
        });
        popOverListUserName.unshift({ label: 'All', value: -99 });

        return popOverListUserName;
    }

    const generateObjectNameValue = () => {
        let popOverObjectNameList = [];
        Object.keys(objectValue).forEach((itemKey) => {
            let data = {};
            data.label = objectValue[itemKey];
            data.value = itemKey;
            popOverObjectNameList.push(data);
        });
        return popOverObjectNameList;
    }

    const generateFranchiseMenu = () => {
        let franchiseList = [];
        if (getArrayParam(franchiseList).length > 0) {
            franchiseList.forEach((itemKey) => {
                let data = {};
                data.label = itemKey.franchise_no;
                data.value = itemKey;
                // popOverObjectNameList.push(data);

            });
        }
        return franchiseList;
    }

   const generateChart = (reportTypeName, reportData) => {
        if (reportData !== undefined && Object.keys(reportData).length > 0 && getArrayParam(reportData.report_values).length > 0) {
            return reportData.report_values.map(( /*reportName*/ reportDataMap, index) => {
                let reportName = reportData.report_values[index].name;
                if (object === "sales") {
                    if (reportTypeName === 'Activity Report') {
                        let reportConfData = reportData.report_values[index].value;
                        if (reportName === 'activity_by_salesrep' || reportName === 'activity_by_type') {
                            if (reportConfData.title.indexOf('Activities') === -1)
                                reportConfData.title = "Activities " + reportConfData.title;
                            let reportConfObject = processChartData(reportConfData, 'column', reportName);
                            return (
                                <div className="col-md-12">
                                    <ReactChart config={reportConfObject} />
                                </div>
                            );
                        } else if (reportName === 'activity_matrix') {
                            let configData = JSON.parse(JSON.stringify(matrixReportInitialData));
                            configData.reportName = "Activity Matrix";
                            configData.column = [
                                {
                                    "isVisible": true,
                                    "name": "value",
                                    "label": getLocalizedStrings().label.REPORT['ACTIVITY_TYPE'],//"Activity Type",
                                    "isToatalField": false,
                                    //  "isDetailsLink": true
                                },
                                {
                                    "isVisible": true,
                                    "name": "count",
                                    "label": getLocalizedStrings().label.REPORT['Count'],//"Count",
                                    "isToatalField": true,
                                    "isDetailsLink": true
                                }
                            ];
                            configData.data = reportConfData === undefined ? [] : reportConfData.sales_report_result;
                            configData.footer = [
                                {
                                    "isVisible": true,
                                    "showTotal": false,
                                    "colspan": 1,
                                    "label": getLocalizedStrings().label.REPORT['Grand Total'],//"Grand Total",
                                    //  "isDetailsLink": true
                                },
                                {
                                    "isVisible": true,
                                    "showTotal": true,
                                    "colspan": 1,
                                    "label": "",
                                    "isDetailsLink": true
                                }

                            ]

                            return (
                                <div className="col-md-12">
                                    <MatrixReportTable  config={configData} rawData={reportConfData} drillDownMatrixReport={(reportName, clickPosition, headerData, rowJsonData, footerData, clickJsonFieldName, rawData, clickValue)=>drillDownMatrixReport(reportName, clickPosition, headerData, rowJsonData, footerData, clickJsonFieldName, rawData, clickValue)} checkDataForExport={(obj)=>checkDataForExport(obj)} />
                                </div>
                            );
                        }
                    } else if (reportTypeName === "Conversion Report" || reportTypeName === "Franchise Conversion Report") {
                        let reportConfData = reportData.report_values[index].value;
                        if (reportName === 'conversion_by_source' || reportName === 'conversion_by_status') {
                            let reportConfObject = processChartData(reportConfData, 'column', reportName);
                            return (
                                <div className="col-md-12">
                                    <ReactChart config={reportConfObject} />
                                </div>
                            );
                        } else if (reportName === 'matrix_by_status') {
                            let configData = JSON.parse(JSON.stringify(matrixReportInitialData));
                            configData.reportName = "Matrix By Status";
                            configData.column = [
                                {
                                    "isVisible": true,
                                    "name": "value",
                                    "label": getLocalizedStrings().label.REPORT['Status'],//"Status",
                                    "isToatalField": false,
                                    //  "isDetailsLink": true
                                },
                                {
                                    "isVisible": true,
                                    "name": "count",
                                    "label": getLocalizedStrings().label.REPORT['Count'],//"Count",
                                    "isToatalField": true,
                                    "isDetailsLink": true
                                }
                            ];
                            configData.data = reportConfData === undefined ? [] : reportConfData.sales_report_result;
                            configData.footer = [
                                {
                                    "isVisible": false,
                                    "showTotal": false,
                                    "colspan": 1,
                                    "label": "",
                                    "isDetailsLink": true
                                },
                                {
                                    "isVisible": false,
                                    "showTotal": false,
                                    "colspan": 1,
                                    "label": "",
                                    "isDetailsLink": true
                                }

                            ]

                            return (
                                <div className="col-md-12">
                                    <MatrixReportTable  config={configData} rawData={reportConfData} drillDownMatrixReport={(reportName, clickPosition, headerData, rowJsonData, footerData, clickJsonFieldName, rawData, clickValue)=>drillDownMatrixReport(reportName, clickPosition, headerData, rowJsonData, footerData, clickJsonFieldName, rawData, clickValue)} checkDataForExport={(obj)=>checkDataForExport(obj)} />
                                </div>
                            );
                        } else if (reportName === 'matrix_by_source') {
                            let configData = JSON.parse(JSON.stringify(matrixReportInitialData));
                            configData.reportName = "Matrix By Source";
                            configData.column = [
                                {
                                    "isVisible": true,
                                    "name": "value",
                                    "label": getLocalizedStrings().label.REPORT['Source'],//"Source",
                                    "isToatalField": false,
                                    // "isDetailsLink": true
                                },
                                {
                                    "isVisible": true,
                                    "name": "count",
                                    "label": getLocalizedStrings().label.REPORT['Count'],//"Count",
                                    "isToatalField": true,
                                    "isDetailsLink": true
                                }
                            ];
                            configData.data = reportConfData === undefined ? [] : reportConfData.sales_report_result;
                            configData.footer = [
                                {
                                    "isVisible": false,
                                    "showTotal": false,
                                    "colspan": 1,
                                    "label": "",
                                    "isDetailsLink": true
                                },
                                {
                                    "isVisible": false,
                                    "showTotal": false,
                                    "colspan": 1,
                                    "label": "",
                                    "isDetailsLink": true
                                }

                            ]

                            return (
                                <div className="col-md-12">
                                    <MatrixReportTable config={configData} rawData={reportConfData} drillDownMatrixReport={(reportName, clickPosition, headerData, rowJsonData, footerData, clickJsonFieldName, rawData, clickValue)=>drillDownMatrixReport(reportName, clickPosition, headerData, rowJsonData, footerData, clickJsonFieldName, rawData, clickValue)} checkDataForExport={(obj)=>checkDataForExport(obj)} />
                                </div>
                            );
                        }



                    } else if (reportTypeName === "Pipeline Report") {

                        if (reportName === 'deals_by_sales_rep' || reportName === 'deals_by_stages') {
                            let reportConfObject = processChartData(reportData.report_values[index].value, 'pie', reportName);
                            return (
                                <div className="col-md-12">
                                    <ReactChart config={reportConfObject} />
                                </div>
                            );
                        } else if (reportName === 'pipeline_report') {
                            let reportConfObject = processChartData(reportData.report_values[index].value, 'funnel', reportName);
                            return (
                                <div className="col-md-12">
                                    <ReactChart config={reportConfObject} />
                                </div>
                            );
                        }

                    } else if (reportTypeName === "Call Report") {
                        if (reportName === 'total_calls_by_salesrep' || reportName === 'total_call_duration_by_salesrep') {
                            let reportConfObject = processChartData(reportData.report_values[index].value, 'column', reportName);
                            return (
                                <div className="col-md-12">
                                    <ReactChart config={reportConfObject} />
                                </div>
                            );
                        }
                    }

                } else if (object === "services") {

                    if (reportTypeName === 'Service Reports') {

                    let reportConfData = reportData.report_values[index].value;
                        if (reportName === 'cases_by_priority' || reportName === 'cases_by_status') {
                            let reportConfObject = processChartData(reportConfData, 'column', reportName);
                            return (
                                <div className="col-md-12">
                                    <ReactChart config={reportConfObject} />
                                </div>
                            );
                        } else if (reportName === 'support_matrix' || reportName === 'customers_by_cases') {

                            let configData = JSON.parse(JSON.stringify(matrixReportInitialData));
                            if (reportName === 'support_matrix') {
                                configData.reportName = "Support Matrix";
                            } else if (reportName === 'customers_by_cases') {
                                configData.reportName = "Customers by Cases";
                            }

                            configData.column = [];
                            configData.data = [];//reportConfData.service_report_result.slice(1);
                            configData.footer = []
                            let headerData = reportConfData.service_report_result.slice(0, 1);
                            headerData[0].map((fieldName, index) => {
                                let matrixHeaderLabel = getLocalizedStrings().label.REPORT.SERVICE_MATRIX_HEADER.hasOwnProperty(fieldName) ? getLocalizedStrings().label.REPORT.SERVICE_MATRIX_HEADER[fieldName] : fieldName;
                                if (index === 0) {
                                    configData.column.push({ "isVisible": true, "name": fieldName, "label": matrixHeaderLabel, "isToatalField": false, "isDetailsLink": false });
                                    configData.footer.push({ "isVisible": true, "name": fieldName, "colspan": 1, "label": getLocalizedStrings().label.REPORT.SUB_TOTAL, "showTotal": false, "isDetailsLink": false });

                                } else {
                                    configData.column.push({ "isVisible": true, "name": fieldName, "label": matrixHeaderLabel, "isToatalField": true, "isDetailsLink": true });
                                    configData.footer.push({ "isVisible": true, "name": fieldName, "colspan": 1, "label": "", "showTotal": true, "isDetailsLink": true });
                                }
                            });

                            let tableData = reportConfData.service_report_result.slice(1);
                            for (let i = 0; i < tableData.length; i++) {
                                let rowDataObject = {};

                                for (let j = 0; j < tableData[i].length; j++) {

                                    rowDataObject[headerData[0][j]] = tableData[i][j];
                                }
                                configData.data.push(rowDataObject);
                            }

                            return (
                                <div className="col-md-12">
                                    <MatrixReportTable  config={configData} rawData={reportConfData} drillDownMatrixReport={(reportName, clickPosition, headerData, rowJsonData, footerData, clickJsonFieldName, rawData, clickValue)=>drillDownMatrixReport(reportName, clickPosition, headerData, rowJsonData, footerData, clickJsonFieldName, rawData, clickValue)} checkDataForExport={(obj)=>checkDataForExport(obj)} />
                                </div>
                            );
                        }
                    } else if (reportTypeName === "Project Reports") {
                        let reportConfData = reportData.report_values[index].value;
                        if (reportName === 'projects_by_status' || reportName === 'projects_by_type') {
                            let reportConfObject = processChartData(reportConfData, 'column', reportName);
                            return (
                                <div className="col-md-12">
                                    <ReactChart config={reportConfObject} />
                                </div>
                            );
                        } else if (reportName === 'projects_matrix') {

                            let configData = JSON.parse(JSON.stringify(matrixReportInitialData));
                            configData.reportName = "Project Matrix";
                            configData.column = [];
                            configData.data = [];//reportConfData.service_report_result.slice(1);
                            configData.footer = [];
                            let headerData = reportConfData.project_report_result.slice(0, 1);
                            headerData[0].map((fieldName, index) => {
                                let matrixHeaderLabel = getLocalizedStrings().label.REPORT.SERVICE_MATRIX_HEADER.hasOwnProperty(fieldName) ? getLocalizedStrings().label.REPORT.SERVICE_MATRIX_HEADER[fieldName] : fieldName;
                                if (index === 0) {
                                    configData.column.push({ "isVisible": true, "name": fieldName, "label": matrixHeaderLabel, "isToatalField": false, "isDetailsLink": false });
                                    configData.footer.push({ "isVisible": true, "name": fieldName, "colspan": 1, "label": getLocalizedStrings().label.REPORT.SUB_TOTAL, "showTotal": false, "isDetailsLink": false });
                                } else {
                                    configData.column.push({ "isVisible": true, "name": fieldName, "label": matrixHeaderLabel, "isToatalField": true, "isDetailsLink": true });
                                    configData.footer.push({ "isVisible": true, "name": fieldName, "colspan": 1, "label": "", "showTotal": true, "isDetailsLink": true });
                                }
                            });

                            let tableData = reportConfData.project_report_result.slice(1);
                            for (let i = 0; i < tableData.length; i++) {
                                let rowDataObject = {};
                                for (let j = 0; j < tableData[i].length; j++) {
                                    rowDataObject[headerData[0][j]] = tableData[i][j];
                                }
                                configData.data.push(rowDataObject);
                            }


                            return (
                                <div className="col-md-12">
                                    <MatrixReportTable  config={configData} rawData={reportConfData} drillDownMatrixReport={(reportName, clickPosition, headerData, rowJsonData, footerData, clickJsonFieldName, rawData, clickValue)=>drillDownMatrixReport(reportName, clickPosition, headerData, rowJsonData, footerData, clickJsonFieldName, rawData, clickValue)} checkDataForExport={(obj)=>checkDataForExport(obj)} />
                                </div>
                            );

                        }

                    } else if (reportTypeName === "Issue Reports") {
                        let reportConfData = reportData.report_values[index].value;
                        if (reportName === 'issues_by_priority' || reportName === 'issues_by_status') {
                            let reportConfObject = processChartData(reportConfData, 'column', reportName);
                            return (
                                <div className="col-md-12">
                                    <ReactChart config={reportConfObject} />
                                </div>
                            );
                        } else if (reportName === 'issues_matrix') {
                            let configData = JSON.parse(JSON.stringify(matrixReportInitialData));
                            configData.reportName = "Issue Matrix";
                            configData.column = [];
                            configData.data = [];//reportConfData.service_report_result.slice(1);
                            configData.footer = [];

                            let headerData = reportConfData.issue_report_result.slice(0, 1);
                            headerData[0].map((fieldName, index) => {
                                let matrixHeaderLabel = getLocalizedStrings().label.REPORT.SERVICE_MATRIX_HEADER.hasOwnProperty(fieldName) ? getLocalizedStrings().label.REPORT.SERVICE_MATRIX_HEADER[fieldName] : fieldName;
                                if (index === 0) {
                                    configData.column.push({ "isVisible": true, "name": fieldName, "label": matrixHeaderLabel, "isToatalField": false, "isDetailsLink": false });
                                    configData.footer.push({ "isVisible": true, "name": fieldName, "colspan": 1, "label": getLocalizedStrings().label.REPORT.SUB_TOTAL, "showTotal": false, "isDetailsLink": false });

                                } else {
                                    configData.column.push({ "isVisible": true, "name": fieldName, "label": matrixHeaderLabel, "isToatalField": true, "isDetailsLink": true });
                                    configData.footer.push({ "isVisible": true, "name": fieldName, "colspan": 1, "label": "", "showTotal": true, "isDetailsLink": true });
                                }
                            });

                            let tableData = reportConfData.issue_report_result.slice(1);
                            for (let i = 0; i < tableData.length; i++) {
                                let rowDataObject = {};

                                for (let j = 0; j < tableData[i].length; j++) {
                                    //  configData.data.push();
                                    rowDataObject[headerData[0][j]] = tableData[i][j];
                                }
                                configData.data.push(rowDataObject);
                            }

                            return (
                                <div className="col-md-12">
                                    <MatrixReportTable  config={configData} rawData={reportConfData} drillDownMatrixReport={(reportName, clickPosition, headerData, rowJsonData, footerData, clickJsonFieldName, rawData, clickValue)=>drillDownMatrixReport(reportName, clickPosition, headerData, rowJsonData, footerData, clickJsonFieldName, rawData, clickValue)} checkDataForExport={(obj)=>checkDataForExport(obj)} />
                                </div>
                            );
                        }
                    }
                }
            });
        }
    }

    const generateReportTypeWiseDiv = () => {
        let reportHeaders = reportHeaderNames;
        if (reportHeaders[selectedIndex] !== undefined) {
            let reportTypeName = reportHeaders[selectedIndex].item_name;
            if (object === "sales") {
                if (reportTypeName === 'Activity Report' || reportTypeName === 'Conversion Report' || reportTypeName === 'Pipeline Report' || reportTypeName === 'Call Report' || reportTypeName === 'Franchise Conversion Report') {
                    return generateChart(reportHeaders[selectedIndex].item_name, reportResponseData);
                } else {
                    return <UnderProcessing />;
                }
            } else if (object === "services") {
                if (reportTypeName === 'Service Reports' || reportTypeName === "Project Reports" || reportTypeName === "Issue Reports") {
                    return generateChart(reportHeaders[selectedIndex].item_name, reportResponseData);
                } else {
                    return <UnderProcessing />;
                }
            } else {
                return <UnderProcessing />;
            }

        }
    }

   const reloadReport = () => {
        let reportHeaders = reportHeaderNames;
        let selectedReportIndex = report.selectIndex;
        let isValid = true;
        if (reportHeaders[selectedReportIndex] != undefined && reportHeaders[selectedReportIndex].item_name == 'Franchise Conversion Report') {
            if (getIntParam(reportParams.selectedFrnProjectId) == 0) {
                isValid = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_FRANCHISE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
            else if (getStringParam(reportParams.user_names).length == 0) {
                isValid = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_SALES_REP, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
            else if (getStringParam(reportParams.object_name).length == 0) {
                isValid = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_OBJECT_NAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
            else {
                isValid = true;
            }
        }
        if (isValid) {
           report.showProgressBar = true;
         if (reportHeaders[selectedReportIndex].item_name == constants.PIPELINE_REPORT) {
                loadWorkflowByObject();
            } else {
                loadReportByIndex(selectedIndex,fromDate,toDate);
            }
            // this.loadReportByIndex(this.props.selectedIndex);
        }
    }

    const loadReportByIndex = (index,fromDate,toDate) => {
     let report_params = JSON.parse(JSON.stringify(reportParams));
        let reportHeaders = reportHeaderNames;
        let selectedReportIndex = index;
        let isValidRequest = true;
        let errorMsg = "";
        setObjectNameDisplay('none');
        if (reportHeaders[selectedReportIndex] !== undefined) {
            let url = "";
            if (object === "sales") {
                if (reportHeaders[selectedReportIndex].item_name === 'Activity Report') {
                    url = endPoints.SALES_REPORT.ACTIVITY;
                } else if (reportHeaders[selectedReportIndex].item_name === 'Conversion Report' || reportHeaders[selectedReportIndex].item_name === 'Franchise Conversion Report') {
                    url = endPoints.SALES_REPORT.CONVERSION;
                    setObjectNameDisplay('block');
                } else if (reportHeaders[selectedReportIndex].item_name === 'Pipeline Report') {
                    url = endPoints.SALES_REPORT.PIPELINE;
                    setObjectNameDisplay('block');
                    setWorkFlowDisplay('block');
                } else if (reportHeaders[selectedReportIndex].item_name === 'Forecast Report') {
                } else if (reportHeaders[selectedReportIndex].item_name === '5 Ways Report') {
                } else if (reportHeaders[selectedReportIndex].item_name === 'Call Report') {
                    url = endPoints.SALES_REPORT.CALL;
                }
            } else if (object === "services") {
                if (reportHeaders[selectedReportIndex].item_name === 'Service Reports') {
                    url = endPoints.SERVICE_REPORT.SERVICE_REPORT;
                } else if (reportHeaders[selectedReportIndex].item_name === 'Project Reports') {
                    url = endPoints.SERVICE_REPORT.PROJECT_REPORT;
                } else if (reportHeaders[selectedReportIndex].item_name === 'Issue Reports') {
                    url = endPoints.SERVICE_REPORT.ISSUE_REPORT;
                }
            }
            if (url !== "") {
                if (report_params.due_by === 'cust') {
                     if (isValidParam(fromDate) && isValidParam(toDate)  && fromDate !== '' && toDate !== '') {
                         report_params.from_date = formatDate(fromDate);
                        report_params.to_date = formatDate(toDate);
                     }
                    else {
                        isValidRequest = false;
                        errorMsg = getLocalizedStrings().message.INXPRESS_REPORT.BLANK_FROM_TO_DATE;
                    }
                  } else {
                    report_params.from_date = "";
                    report_params.to_date = "";
                }

                if (recent_record_id !== undefined && recent_record_id !== "" && recent_record_id !== 0) {
                    report_params.recent_record_id = recent_record_id;
                } else {
                    report_params.recent_record_id = 0;
                }
                if (workflowId > 0) {
                    report_params.workflow_id = workflowId;
                    report_params.workflow_name = workflowNames;
                }
                if (isValidRequest) {
                    var response = Promise.resolve(HTTPClient.get(url, report_params));
                    response.then((response) => {
                        setReportResponseData(response);
                        dispatch(setRecentReportResponseData(response, report_params, index, reportHeaderNames, object));
                    });
                }
                else {
                    dispatch(showCustomSnackBar(errorMsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    report.showProgressBar = false;
                }
            } else {
                report.showProgressBar = false;
            }
        }
    }
    const franchiseDropDownChange = (value) => {
        let _reportParams = reportParams;
        let _franchiseList = getArrayParam(franchiseList);
        let franchise = _franchiseList.filter(f => { return f.projectId === value });
        if (franchise.length > 0) {
            getSalesRepList(franchise[0].projectId);
            _reportParams.selectedFrnProjectId = franchise[0].projectId.toString();
            _reportParams.selectedFranchise = franchise[0].franchise_no.toString();
            _reportParams.user_names = null;
            setReportParams(_reportParams);
        }
    }

    const getSalesRepList = (projectId) => {
        let salesRepMenu = null;
        let salesRepList = [];
        if (isValidParam(projectId)) {
            let params = {};
            params.project_id = projectId;
            let promise1 = Promise.resolve(HTTPClient.get(endPoints.SALES_REPORT.SALES_REP_FRANCHISE, params));
            promise1.then((response) => {
                if (isValidParam(response.salesRep)) {
                    salesRepList = response.salesRep;
                    salesRepMenu = response.salesRep.map((item) => {
                        return <MenuItem key={item} value={item} style={styles.popoverMenuItem}>{item}</MenuItem>;
                    });
                    salesRepList.unshift("All");
                    salesRepMenu.unshift(<MenuItem key={"All"} value={"All"} style={styles.popoverMenuItem} >ALL</MenuItem>);
                }
                setSalesRepMenu(salesRepMenu);
                setSalesRepList(salesRepList);
            });
        }
    }

    const salesRepDropDownChange = (value) => {
        let _reportParams = reportParams;
        let _salesRepList = getArrayParam(salesRepList);
        let salesRep = _salesRepList.filter(f => { return f === value });
        salesRep = salesRep.length > 0 ? salesRep[0] : '';
        _reportParams.user_names = salesRep;
        setReportParams(_reportParams);
    }

    const checkDataForExport = (obj) => {
        if (getArrayParam(obj.data).length > 0) {
            return true;
        }
        else {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.NO_RECORD_EXPORT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }
    }

    const generateWorkflowList = (object) => {
        let workflowList = [];
        let pipelineReportWorkflows = getObjectParam(pipelineReport.pipelineReportWorkflows);
        let workflowListData = getArrayParam(pipelineReportWorkflows[object]);
        try {
            workflowListData.forEach((wf, index) => {
                let data = {};
                data.label = wf.name;
                data.value = wf.id;
                workflowList.push(data);
            });
        }
        catch (error) {
            console.error("Error in 'salesServiceReport.js -> generateWorkflowList()':" + error);
        }
        return workflowList;
    }

    const getMultilingualOptionsMap = () => {
        let optionsMultilingual = {
            'Today': getLocalizedStrings().label.DASHBOARD.TODAY,
            'Yesterday': getLocalizedStrings().label.DASHBOARD.YESTERDAY,
            'This Week': getLocalizedStrings().label.DASHBOARD.THIS_WEEK,
            'This Month': getLocalizedStrings().label.DASHBOARD.THIS_MONTH,
            'This Quarter': getLocalizedStrings().label.DASHBOARD.THIS_QUARTER,
            'This Year': getLocalizedStrings().label.DASHBOARD.THIS_YEAR,
            'Last Week': getLocalizedStrings().label.DASHBOARD.LAST_WEEK,
            'Last Month': getLocalizedStrings().label.DASHBOARD.LAST_MONTH,
            'Last Quarter': getLocalizedStrings().label.DASHBOARD.LAST_QUARTER,
            'Last Year': getLocalizedStrings().label.DASHBOARD.LAST_YEAR,
            'Custom': getLocalizedStrings().label.DASHBOARD.CUSTOM
        };
        return optionsMultilingual;
    }
    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight-10) / 2;
    if (report.showProgressBar === true) {
        return (
            <div style={{ width: '100%', height: pageHeight }}>
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
            </div>
        )
    }
    let userNameList = generateUserNames();
    let reportTypeWiseDiv = generateReportTypeWiseDiv();
    let _franchiseList = null;
    if (reportParams.item_type === "InXpress Report 18") {
        _franchiseList = generateFranchiseMenu();
    }
    let cssDivDateRange = null;
    let cssDivFranchise = null;
    let cssSalesRep = null;
    let cssReportOn = null;
    let displayType = objectNameDisplay;
    let userNamesFieldWidth = '25%';
    if (reportParams.item_type === "InXpress Report 18" && isFranchiseListShow) {
        if (reportParams.due_by === "cust") {
            cssDivDateRange = { width: '20%' }
            cssDivFranchise = { display: 'inline-block', float: 'left', width: '20%' };
            cssSalesRep = { float: 'left', width: '21%', display: 'inline-block' };
            cssReportOn = { display: displayType,  float: 'left', width: '20%' };
        } else {
            cssDivFranchise = {  };
            cssSalesRep = {  };
            cssReportOn = { display: displayType,};
        }
    }
    else if (reportParams.item_type === constants.PIPELINE_REPORT) {
        cssDivDateRange = { width: '19%', };
        cssReportOn = { display: displayType,float: 'left', width: '19%' };
        userNamesFieldWidth = '20%';
    }
    else {
        cssReportOn = { display: displayType, };
    }
    let language = getLocalizedStrings()._language;
    if (language === 'es-ES' || language === 'pt-PT' || language === 'fr-FR') {
        userNamesFieldWidth = '32%';
    }
    let _workflowList = generateWorkflowList(reportParams.object_name);
    let reportUserName = reportParams.user_names === 'All' ? getLocalizedStrings().label.COMMON.ALL : reportParams.user_names;
    let HHFormat = new RegExp("HH");
    let datePickerOptions = {
        enableTime: false,
        dateFormat: dateFormatFlatPicker[app.me.date_format],
        minuteIncrement: 1,
        static: true,
        time_24hr: HHFormat.test(app.me.date_format) ? true : false
    };
    return (
        <div>
            <div style={{ ...styles.row }}>
                <div style={{ ...styles.sf_10 }}>
                    <div style={{ ...styles.row, paddingTop: '8px',paddingLeft:'27px' }}>
                        <div style={{ marginTop: '7px', ...cssDivDateRange }} >
                            <FormControl variant="outlined" size="small">
                                <InputLabel id="sf-pipelinereports-simple-select-outlined-label" className="sf-pipelinereports">{getLocalizedStrings().label.REPORT.DATE_RANGE}</InputLabel>
                                <Select
                                    labelId="sf-pipelinereports-simple-select-outlined-label"
                                    id="sf-pipelinereports-simple-select-outlined"
                                    value={reportParams.due_by}
                                    onChange={(event,value)=>handleDropDownChange('due_by',event,value)}
                                    label={getLocalizedStrings().label.REPORT.DATE_RANGE}
                                    className={"sf-fields-bg"}
                                    size="small"
                                    style={{ ...styles.sfFormFieldContainer, minWidth: 172, cursor: "pointer", color: '#717171', height: '45px', paddingLeft: 7, width: '100%', paddingRight: 7 }}
                                >
                                    {generateDateSelectionField()}
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ paddingLeft: '20px', paddingRight: '10px', display: customTimeLineDisplay}}>
                            <div className='sf-form-field dateTimeDiv-from-date label-top' id="dateholder">
                                <PopOver
                                    id={'from_date'}
                                    key={'from_date'}
                                    name={'from_date'}
                                    btnType={'date'}
                                    buttonStyle={{ fontSize: '16px' }}
                                    containerStyle={{ lineHeight: 1, marginTop: 9 }}
                                    buttonLabel={getLocalizedStrings().label.REPORT.START_DATE}
                                    onChange={(e)=>setFromDate(e)}
                                    value={fromDate}
                                    innerLabelStyle={{ padding: "13 5 2 5", fontSize: '15px' }}
                                    height={43}
                                    options={datePickerOptions}
                                />
                            </div>
                        </div>
                        <div style={{ paddingLeft: '10px', paddingRight: '10px', display: customTimeLineDisplay}}>
                            <div className='sf-form-field dateTimeDiv-to-date label-top' id="dateholder" >
                            <PopOver
                                id={'to_date'}
                                key={'to_date'}
                                name={'to_date'}
                                btnType={'date'}
                                buttonStyle={{ fontSize: '16px' }}
                                containerStyle={{ lineHeight: 1, marginTop: 9 }}
                                buttonLabel={getLocalizedStrings().label.REPORT.END_DATE}
                                onChange={(e)=>setToDate(e)}
                                value={toDate}
                                innerLabelStyle={{ padding: "13 5 2 5", fontSize: '15px' }}
                                height={45}
                                options={{...datePickerOptions, minDate:fromDate}}
                            />
                            </div>
                        </div>
                        {!isFranchiseListShow && 
                            <div style={{ paddingLeft: '10px', paddingRight: '10px', width: userNamesFieldWidth }}>

                                   <PopOver
                                    id={'user_names'}
                                    key={'user_names'}
                                    name={'user_names'}
                                    btnType={'div'}
                                    buttonEndIcon={"keyboard_arrow_down"}
                                    buttonStyle={{ fontSize: '16px' }}
                                    containerStyle={{ lineHeight: 1, marginTop: 9 }}
                                    buttonLineOrigin={[46, 15]}
                                    buttonLabel={getLocalizedStrings().label.REPORT.SALES_REP}
                                    isShowIcon={true}
                                    onclickAction={(value) => handleDropDownChangeUserNames(value)}
                                    value={reportUserName}
                                    innerIcon={'right'}
                                    innerLabelStyle={{ padding: "13 5 2 5", fontSize: '15px' }}
                                    height={45}
                                    options={userNameList}
                                    size="small"
                                    downArrowStyle={{ marginTop: '-2px', padding: ' 0 5 0 5px' }}
                                />
                            </div>
                        }
                        {isCountry && isFranchiseListShow && 
                            <div style={{ ...cssDivFranchise, paddingLeft: '10px', paddingRight: '10px'}}>
                                <PopOver
                                    id={'franchise'}
                                    key={'franchise'}
                                    name={'franchise'}
                                    btnType={'div'}
                                    buttonEndIcon={"keyboard_arrow_down"}
                                    buttonStyle={{ fontSize: '16px' }}
                                    containerStyle={{ lineHeight: 1, marginTop: 9 }}
                                    buttonLineOrigin={[46, 15]}
                                    buttonLabel={getLocalizedStrings().label.INXPRESS_REPORT.FRANCHISE_SELECT}
                                    isShowIcon={true}
                                    options={userNameList}
                                    onclickAction={(value)=>franchiseDropDownChange(value)}
                                    value={reportParams.selectedFranchise}
                                    innerIcon={'right'}
                                    innerLabelStyle={{ padding: "5 5 2 5", fontSize: '15px' }}
                                    height={45}
                                    downArrowStyle={{ marginTop: '-2px', padding: ' 0 5 0 5px' }}
                                />
                            </div>
                        }
                        {isCountry && isFranchiseSalesRepShoe && <div style={{ ...cssSalesRep }}> {/* marginLeft: '-5%' */}
                            <PopOver
                                id={'salesrep_names'}
                                key={'salesrep_names'}
                                name={'salesrep_names'}
                                btnType={'div'}
                                buttonEndIcon={"keyboard_arrow_down"}
                                buttonStyle={{ fontSize: '16px' }}
                                containerStyle={{ lineHeight: 1, marginTop: 9 }}
                                buttonLineOrigin={[46, 15]}
                                buttonLabel={getLocalizedStrings().label.REPORT.SALES_REP}
                                isShowIcon={true}
                                options={salesRepMenu}
                                onclickAction={(value)=>salesRepDropDownChange(value)}
                                value={reportParams.user_names}
                                innerIcon={'right'}
                                innerLabelStyle={{ padding: "5 5 2 5", fontSize: '15px' }}
                                height={45}
                                downArrowStyle={{ marginTop: '-2px', padding: ' 0 5 0 5px' }}
                            />
                        </div>
                        }
                        {/*--*/}
                        <div style={{ ...cssReportOn }}>{/* display: objectNameDisplay*/}
                            <PopOver
                                id={'object_name'}
                                key={'object_name'}
                                name={'object_name'}
                                btnType={'div'}
                                buttonEndIcon={"keyboard_arrow_down"}
                                buttonStyle={{ fontSize: '16px' }}
                                containerStyle={{ lineHeight: 1, marginTop: 9 }}
                                buttonLineOrigin={[46, 15]}
                                buttonLabel={getLocalizedStrings().label.REPORT.REPORT_ON}
                                isShowIcon={true}
                                options={generateObjectNameValue()}
                                onclickAction={(value)=>handleDropDownChangeObjectName(value)}
                                value={objectValue[reportParams.object_name]}
                                innerIcon={'right'}
                                innerLabelStyle={{ padding: "5 5 2 5", fontSize: '15px' }}
                                height={45}
                                downArrowStyle={{ marginTop: '-2px', padding: ' 0 5 0 5px' }}
                            />
                        </div>
                        <div style={{ display: workflowDisplay, marginLeft: '10', marginRight: '10', width: '20%' }}>
                            <PopOver
                                id={'workflow_names'}
                                key={'workflow_names'}
                                name={'workflow_names'}
                                btnType={'div'}
                                buttonEndIcon={"keyboard_arrow_down"}
                                buttonStyle={{ fontSize: '16px' }}
                                containerStyle={{ lineHeight: 1, marginTop: 9 }}
                                buttonLineOrigin={[46, 15]}
                                buttonLabel={getLocalizedStrings().label.REPORT.WORKFLOW_SELECT}
                                isShowIcon={true}
                                options={_workflowList}
                                onclickAction={(value)=>handleDropDownChangeWorkflowName(value)}
                                value={workflowNames}
                                innerIcon={'right'}
                                innerLabelStyle={{ padding: "5 5 2 5", fontSize: '15px' }}
                                height={45}
                                downArrowStyle={{ marginTop: '-2px', padding: ' 0 5 0 5px' }}
                            />
                        </div>
                    </div>
                        <div className="row" style={{ display: customTimeLineDisplay, paddingTop: '10px' }}>
                    </div>
                </div>
                <div style={{ ...styles.sf_2, paddingTop: '15px', textAlign: 'right', float: 'right',width:'19%' }}>
                    <Button
                        startIcon={<Icon>autorenew</Icon>}
                        onClick={() => reloadReport()}
                        style={{
                            ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                            color: '#717171',
                            border: '0px',
                            minWidth: '40px'
                        }}
                        title={getLocalizedStrings().label.REPORT.REFRESH}
                    ></Button>
                    <iframe id="ifmcontentstoprint" title="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute', display: 'none' }}></iframe>
                    <Button
                        startIcon={<Icon>print</Icon>}
                        onClick={() => {
                            var content = document.getElementById("divcontents");
                            var ifrmae = document.getElementById("ifmcontentstoprint");
                            ifrmae.style.display = 'block'
                            var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                            ifrmae.style.display = 'block'
                            pri.document.open();
                            pri.document.write(
                                content.innerHTML);
                            pri.document.close();
                            pri.focus();
                            pri.print();
                            ifrmae.style.display = 'none'
                            ifrmae.style.transform ='scale(0.8)'

                        }}
                        style={{
                            ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                            color: '#717171',
                            border: '0px',
                            minWidth: '40px',
                            // display: 'none'
                        }}
                        labelstyle={{
                        }}
                        title={getLocalizedStrings().label.REPORT.PRINT}
                    ></Button>
                </div>
            </div>
            <div style={{...styles.row}} id="divcontents">
                <div style={{...styles.sf_12}}>
                    {reportTypeWiseDiv}
                </div>
            </div>
        </div>
        );
}

const UnderProcessing = () => {
    return (
        <div className="row">
            <div className="col-md-12" style={{ textAlign: 'center', padding: '50px' }}>
                <h4 style={{ textAlign: 'center', padding: '50px' }}>{getLocalizedStrings().label.COMMON.COMING_SOON}</h4>
            </div>
        </div>
    )
}

export default ServiceReportView;
