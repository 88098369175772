
import React, { useState, useEffect } from 'react';
import * as HTTPClient from '../../../services/helper/httpClient';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import ShowCircularProgress from '../components/circularProgress';
import { constants } from '../../../services/constants/constants';
import { styles } from '../../../services/constants/styles';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import * as sfDialogs from '../components/sfDialogs';
import { endPoints } from '../../../services/constants/endPoints';
import { OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useDispatch, useSelector } from "react-redux";

const ClientConnect = ({ closeDrawer }) => {

    const dispatch = useDispatch();

    const detailViewRecord = useSelector((state) =>  state.detailView.record);

    const [mounted, setMounted] = useState(false);
    const [email, setEmail] = useState(null);
    const [name, setName] = useState(null);
    const [clientProjectId, setClientProjectId] = useState(0);

    useEffect(() => {
        getClientConnect();
    }, []);


    const getClientConnect = () => {
        var connectGetUrl = endPoints.COMMON.CLIENT_CONNECT_GET + detailViewRecord.id;
        setMounted(false);
        var response = Promise.resolve(HTTPClient.get(connectGetUrl, null));
        response.then((response) => {
            if (response.status === 0) {
                if (response.data.hasOwnProperty("validation")) {
                    if (response.data.validation === 'This customer does not have an account with this email in Soffront Online.First ask the customer to create an account with the same email.') {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.CLIENT_CONNECT_MESSAGE, null);
                    } else {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, response.data.validation, null);
                    }
                    closeDrawer();
                } else {
                    setMounted(true);
                    setClientProjectId(response.data.clientProjectId);
                    setEmail(response.data.email);
                    setName(response.data.name);
                }
            }
        })
    }

    const sendRequestConnect = () => {
        var connectGetUrl = endPoints.COMMON.CONFIRM_REQ_FOR_CLIENT_CONNECT;
        setMounted(false);
        var param = {};
        param.clientProjectId = clientProjectId;
        param.clientEmail = email;
        param.recordId = detailViewRecord.id;
        param.tableId = OBJECT_TABLEID_MAP[constants.ACCOUNTS_OBJECT];
        param.confirmNoteText = document.getElementById('notetext').value;
        var msg = getLocalizedStrings().message.COMMON.DATA_ACCESS_REQUEST_SEND_SUCCESS + " " + name;
        var response = Promise.resolve(HTTPClient.get(connectGetUrl, param));
        response.then((response) => {
            if (response.status === 0) {
                dispatch(refreshDetailViewListViewData(true));
                closeDrawer();
                dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));

            } else {
                msg = getLocalizedStrings().message.COMMON.DATA_ACCESS_REQUEST_SEND_FAILURE;
                closeDrawer();
              }
        })
    }

    if (mounted) {
        var msg1 = getLocalizedStrings().message.COMMON.REQUEST_DATA_ACCESS + " " + name + "’s account:";
        var msg2 = getLocalizedStrings().message.COMMON.WE_WILL_EMAIL + " " + name + " " + getLocalizedStrings().message.COMMON.AT + " "
            + email + " " + getLocalizedStrings().message.COMMON.TO_APPROVE_REQUEST + ".";

        return (
            <div style={{ marginTop: '-10px', paddingLeft: '20px', paddingRight: '20px' }}>
                <div style={{ color: '#717171', marginTop: '20px' }}>
                    <div style={{ color: '#717171', marginBottom: '10px' }} >
                        <span>{msg1}</span><br />
                        <span>{msg2}</span>
                    </div>
                    <div>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                type='textarea'
                                multiline={true}
                                rows={6}
                                fullWidth={true}
                                id="notetext"
                                label={getLocalizedStrings().label.COMMON.ADD_PERSONAL_NOTE_OPTIONAL + ":"}
                                autoComplete="new-password"
                                margin='dense'
                                size='small'
                                className={"sf-fields-bg"}
                            />
                        </FormControl>
                    </div>
                </div>


                <div style={{ float: 'right', marginTop: '20px' }}>
                    <Button
                        style={styles.primaryButton}
                        onClick={() => sendRequestConnect()}
                    >
                        {getLocalizedStrings().label.COMMON.SAVE}</Button>
                    <Button
                        style={styles.secondaryButton}
                        onClick={() => closeDrawer()}
                    >
                        {getLocalizedStrings().label.COMMON.CANCEL}</Button></div>


            </div>
        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }
}

export default ClientConnect;