import React, { useState, useEffect, useRef } from 'react';
import { isValidParam, getStringParam, getIntParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { OBJECT_TABLEID_MAP, TABLEID_OBJECT_MAP, constants } from '../../../services/constants/constants';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import { getImageNameByObject, getObjectLabelByObject, setSelectedModuleInfo, getObjectIdListByModule, getRecentSearchRecord, getRecentRecordFilterPromise } from '../../../services/helper/common';
import { LinkToType } from '../../../services/constants/link';
import { Link } from 'react-router-dom';
import { addTab, TYPE_HOME, TYPE_DETAIL_VIEW, TYPE_OTHER_VIEW } from '../../../services/helper/sfTabManager';
import map from 'lodash/map';
import { connect } from 'react-redux';
import { setSearchRecord } from '../../../services/actions/filterMenuAction';
import { useDispatch, useSelector } from "react-redux";

const RecentRecordsForObjects = ({objects,handleCloseDropdown}) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const filters = useSelector((state) => state.filters);

    const [mounted, setMounted] = useState(false);
    const [recentSearchRecords, setRecentSearchRecords] = useState(null);
    const [objectList, setObjectList] = useState(null);

    useEffect(() => {
        if (getArrayParam(objects).length > 0) {
            getRecentRecordsForObjects(objects);
        }    }, []);
   
   const getRecentRecordsForObjects =(objects) =>{
        try {
            let moduleName = app.selectedModuleName;
            let recentRecords = filters.recentSearchRecords[moduleName];
            if (!isValidParam(recentRecords) || recentRecords.length === 0) {
                if(app.me.is_enableTenantSearch){
                    objects = objects.filter(f => { return f.value !== 'Unit’s Accounts'});
                }
                let objList = getObjectIdListByModule(objects);
                filters.recentObjectList[moduleName] = objList;

                let promise = getRecentRecordFilterPromise(objList);
                promise.then((response) => {
                    if (isValidParam(response)) {
                        filters.recentSearchRecords[app.selectedModuleName] = response;
                        setMounted(true);
                        setRecentSearchRecords(response);
                        generateRecentRecords();
                    }
                });
            } else {
                setMounted(true);
                        setRecentSearchRecords(recentRecords);
                generateRecentRecords();
            }
        } catch (error) {
            console.error("Error in 'recentRecordsForObjects.js -> getRecentRecordsForObjects()':" + error);
        }
    }

   const generateRecentRecords = () => {
        let recentRecords = recentSearchRecords;
        let recordItems = null;

        if (isValidParam(recentRecords)) {
            if (recentRecords.length > 0) {
                recordItems = map(recentRecords, (recentItem, i) => {
                    let object = TABLEID_OBJECT_MAP[recentItem.objectId]
                    let view = LinkToType.TYPE_DETAIL_VIEW;
                    if (object === constants.QUOTATION_OBJECT || object === constants.PURCHASE_ORDER_OBJECT || object === constants.SALES_ORDER_OBJECT) {
                        view = constants.SPREADSHEET;
                    }
                    let linkToUrl = '/' + object + '/' + view + '/' + recentItem.id;
                    let imagename = getImageNameByObject(object);
                    let label = getStringParam(recentItem.label).trim().length > 0 ? getStringParam(recentItem.label) : "Empty";
                    return (
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', lineHeight: '30px' }} key={'recent' + recentItem.id}>
                            <Link
                                to={linkToUrl}
                                style={{ cursor: 'pointer' }}
                                onClick={() => { openTab(recentItem, object, linkToUrl) }}>
                                <span style={{ color: '#ccc', fontSize: '14px', paddingLeft: '1px', paddingRight: '7px' }}><i className={imagename} aria-hidden="true" style={{ width: '12px' }}></i></span>
                                <span title={label} style={{ fontSize: '14px', color: '#717171' }}>
                                    {label}
                                </span>
                            </Link>
                        </div>
                    );
                });
                return recordItems;
            } else {
                return <div style={{ color: 'rgb(113, 113, 113)', lineHeight: '30px' }}>No recent record.</div>
            }
        }
    }

   const openTab = (recentItem, object, url) => {
        let label = getStringParam(recentItem.label).trim().length > 0 ? getStringParam(recentItem.label) : "Empty";
        let tab = {
            item: object,
            label: label,
            object: object,
            type: TYPE_DETAIL_VIEW,
            url: url,
        };
        handleCloseDropdown();
        if (object === constants.QUOTATION_OBJECT || object === constants.PURCHASE_ORDER_OBJECT || object === constants.SALES_ORDER_OBJECT) {
            tab.type = TYPE_OTHER_VIEW;
            tab.info = { id: recentItem.id };
        }
        addTab(tab, true);
        setSelectedModuleInfo(object);
        //getRecentSearchRecord(this.state.objList);
    }

        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        return (
            <div > {/* style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} */}
                {mounted ?
                    <div style={{ marginLeft: '5px', marginTop: '13px' }}>
                        {generateRecentRecords()}
                    </div>
                    :
                    <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>}
            </div>
        );
}
export default RecentRecordsForObjects;