import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as HTTPClient from '../../../services/helper/httpClient';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import ShowCircularProgress from '../components/circularProgress';
import { constants } from '../../../services/constants/constants';
import { styles } from '../../../services/constants/styles';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getTasksRefresh } from '../../../services/actions/taskActions';
import * as sfDialogs from '../components/sfDialogs';
import { refreshDetailViewData, refreshDetailViewListViewData,detailViewIsMounted,isDetailViewSet } from '../../../services/actions/detailViewActions';
import { endPoints } from '../../../services/constants/endPoints';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { refreshColumnView } from '../../../services/actions/listViewAcions';

export class SaveNote extends React.Component {
    componentDidMount() {

    }

    constructor(props) {
        super(props);
        this.state = {
            mounted: true
        };
    }

    saveNote = () => {
        var notetext = document.getElementById('notetext').value;
        let params = {};
        params.link_Table_Id = this.props.data.parent_table_id;
        params.link_Lead_Id = this.props.data.parent_record_id;
        params.note_text = notetext.trim();
        var response = Promise.resolve(HTTPClient.get(endPoints.NOTES.NOTE_TASK_APP, params));

        response.then((response) => {
            this.props.closeDrawer();
            this.props.showCustomSnackBar(this.props.data.msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
            this.props.detailViewIsMounted(false);
            this.props.isDetailViewSet(false);
            this.props.refreshDetailViewListViewData(true);
            this.props.refreshDetailViewData(true);
            if (this.props.hasOwnProperty('callFrom') && this.props.callFrom === constants.COLUMN_VIEW) {
                let dataParams = {
                    record_id: this.props.data.record_id,
                    object: this.props.data.parentObject,
                    listIndex: this.props.data.listIndex,
                    cardIndex: this.props.data.cardIndex,
                    updateCards: this.props.data.updateCards
                }
                refreshColumnView(dataParams);
            }
        });

    }


    render() {
        let pageHeight = window.innerHeight - 247;
        let top = (pageHeight-10) / 2;
        if (this.state.mounted) {
            return (
                <div style={{ marginTop: '-10px', paddingLeft: '20px', paddingRight: '20px' }}>
                    <div style={{ color: '#717171', marginTop: '20px' }}>
                        <div><span></span></div>
                        <div> <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                            type='textarea'
                            variant="outlined"
                            multiline={true}
                            rows={3}
                            autoFocus={true}
                            fullWidth={true}
                            id="notetext"
                            floatingLabelStyle={{ color: '#717171', fontWeight: 'normal', fontSize: '16px', top: '10px', left: '7px' }}
                            textareastyle={{ marginTop: '10px', height: '90%' }}
                            //style={{ width: '100%', backgroundColor: '#fff', border: '1px solid #dddddd' }}
                            inputStyle={{ overflowY: 'auto' }}
                            label={getLocalizedStrings().label.APPOINTMENTS_TASKS.ADD_NOTE}
                            autoComplete="new-password"
                            className={"sf-fields-bg"}
                        />
                        </FormControl>
                        </div>
                    </div>


                    <div style={{ float: 'right', marginTop: '30px' }}>
                        <Button
                            style={styles.primaryButton}
                            onClick={this.saveNote}
                        > {getLocalizedStrings().label.COMMON.SAVE}</Button>
                        <Button
                            style={styles.secondaryButton}
                            onClick={this.props.closeDrawer}
                        > {getLocalizedStrings().label.COMMON.CANCEL}</Button></div>
                </div>
            );
        } else {
            return (<div style={{ width: '100%', height: pageHeight }}>
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
       </div>);
        }

    }
}

const mapStateToProps = (state) => {
    return {
        app: state.app,
        userlist: state.userlist,
        //  selectedRecordlist: state.listView.selectedRows,
        detailViewRecord: state.detailView.record,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
        getTasksRefresh: (isrefresh) => {
            dispatch(getTasksRefresh(isrefresh));
        },
        refreshDetailViewListViewData: (isRefresh) => {
            dispatch(refreshDetailViewListViewData(isRefresh));
        },
        detailViewIsMounted: (isRefresh) => {
            dispatch(detailViewIsMounted(isRefresh));
        },
        isDetailViewSet: (isRefresh) => {
            dispatch(isDetailViewSet(isRefresh));
        },
        refreshDetailViewData: (isRefresh) => {
            dispatch(refreshDetailViewData(isRefresh));
        }
    }
};

SaveNote = connect(mapStateToProps, mapDispatchToProps)(SaveNote);