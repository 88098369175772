import React, { useState, useEffect } from 'react';
import { styles } from '../../../services/constants/styles';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { constants } from '../../../services/constants/constants';
import { getArrayParam, isValidParam, getStringParam, getObjectParam, getBooleanParam } from '../../../services/helper/parameterVerifier';

import { Select, MenuItem, InputLabel, FormHelperText, InputAdornment } from '@mui/material';
import * as SFFormActions from '../../../services/actions/sfFormActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import {useDispatch,useSelector} from "react-redux";
import { setFieldDetails } from '../../../services/actions/sfFormActions';

const ListViewSearchField = ({ object, listViewCallBack, setSearchValue }) => {

    const appContainer = useSelector((state) => state.appContainer);
    const sfForm = useSelector((state) => state.sfForm);
    const dispatch = useDispatch();
    const { fieldDetails } = sfForm;
    const fieldName = fieldDetails[object] ?? '';
    const [stateObject, setObject] = useState('');
    // const [fieldName, setFieldName] = useState('');
    const [searchText, setSearchText] = useState('');
    const [fields, setFields] = useState([]);
    const [open, setOpen] = useState(false);
    const [isFindAndLink, setIsFindAndLink] = useState(false);
    const [isLookupField, setIsLookupField] = useState(false);
    const [changeLog, setchangeLog] = useState({searchText:''});
    const [error, setError] = useState(false);

    useEffect(() => {
        try {

            setObject(getStringParam(object));
            let tempObj = object;
            let data = getObjectParam(appContainer.dialogProps.data);
            // setFieldName(getStringParam(data.fieldName).trim());
            if (getStringParam(data.fieldName).trim()) {
                dispatch(setFieldDetails({ name: object, value: getStringParam(data.fieldName).trim() }));
            }
            let _searchText;

            if (data.hasOwnProperty("isLookupField") && data.isLookupField && data.lookupObject === constants.PRODUCTS_OBJECT && data.lookupFieldName === 'product_name' && appContainer.drawerProps.data != null && appContainer.drawerProps.data.hasOwnProperty('object') && appContainer.drawerProps.data.object === constants.OPPORTUNITIES_OBJECT) {
                _searchText = '';
            } else if (data.hasOwnProperty("isLookupField") && data.isLookupField && data.lookupObject === constants.PRODUCTS_OBJECT && data.lookupFieldName === 'product_name' && appContainer.customDrawerProps.data !== null && appContainer.customDrawerProps.data.hasOwnProperty('object') && appContainer.customDrawerProps.data.object === constants.OPPORTUNITIES_OBJECT && appContainer.customDrawerProps.actionType === 'Convert') {
                _searchText = '';
            } else {
                _searchText = getStringParam(data.searchText).trim();
            }
            _searchText = truncateString(_searchText,26);
            setSearchText(_searchText);

            setIsLookupField(getBooleanParam(data.isLookupField));
            setIsFindAndLink(getBooleanParam(data.isFindAndLink));

            if (data.isFindAndLink) {
                setObject(getStringParam(data.findAndLinkObject));
                tempObj = data.findAndLinkObject;
                let log = changeLog;
                log.stateObject = tempObj;
                setchangeLog({...log});
                getDataAndProcess();
            } else if (data.isLookupField) {
                setObject(getStringParam(data.lookupObject).trim());
                tempObj = data.lookupObject;
                let log = changeLog;
                log.stateObject = tempObj;
                log.searchText = _searchText;
                setchangeLog({...log});
                // setFieldName(getStringParam(data.lookupFieldName).trim());
                dispatch(setFieldDetails({ name: object, value: getStringParam(data.lookupFieldName).trim() }));
            }
           
        }
        catch (error) {
            console.error("Error in 'listViewSearchField.js -> componentDidMount()':" + error);
        }
    }, []);

 
const truncateString =(searchText,limit)=>{
    if(searchText.length <= limit){
        return searchText;
    }else{
        return searchText.slice(0,limit) + "...";
    }

}
    const setFieldValue = (value, fieldName) => {
        
        try {
            // setFieldName(fieldName);
            dispatch(setFieldDetails({ name: object, value: fieldName }));
            
            setSearchText(value);
            let log = changeLog;
            log.searchText = value;
            setchangeLog({...log});
        } catch (error) {
            console.error("Error in 'listViewSearchField.js -> setFieldValue()':" + error);
        }
    }

    const handleKeyUp = (event) => {
        let search_text = event.target.value;

        if (search_text.toString().length > 0) {
            setSearchText(search_text);
        } else {
            setSearchText(search_text);
        }
        let log = changeLog;
        log.searchText = search_text;
        setchangeLog({...log});
        if (event.keyCode === 13 && Boolean(changeLog.searchText.trim()) && fieldName) {
            search();
        }else{
            setError(true);
        }
    }

    const search = () => {
        
        try {
            let params = {};
            params.query_name = constants.SEARCH_QUERY_NAME;
            params.query_type = constants.SEARCH_QUERY_TYPE_ALL;
            params.search_field_name = fieldName;
            params.search_text = getStringParam(changeLog.searchText).trim();
            listViewCallBack(changeLog.stateObject, params);
            
        } catch (error) {
            console.error("Error in 'listViewSearchField.js -> search()':" + error);
        }
    }

    const getFieldlistmenu = () => {
        try {
            let listValues = null;
            let menuItems = null;
            let tempListValues = getArrayParam(changeLog.fields).filter(f => {
                if (f.name !== constants.FIELD_TYPE_HEADER && f.name !== "") {
                    return f;
                }
            });

            listValues = tempListValues.filter(f => {
                if (f.name !== 'workflow_id' && f.name !== '-1') {
                    return f;
                }
            });

            if (isValidParam(listValues)) {
                listValues = sortByKey(listValues, 'label');
            }

            if (object === constants.OPPORTUNITIES_OBJECT) {
                listValues = listValues.filter(f => (f.name !== 'probability' && f.name !== 'forecast_amount'
                    && f.name != 'link_info' && f.name !== 'product_id'));
            }
            if (isValidParam(listValues)) {
                menuItems = listValues.map((listValue, listValueIndex) => {
                    return <MenuItem key={listValue.id} value={listValue.name} id={listValue.id} style={styles.popoverMenuItem}>{listValue.label} </MenuItem>
                });
            }
            return menuItems;
        }
        catch (error) {
            console.error("Error in 'listViewSearchField.js -> getFieldlistmenu()':" + error);
        }
    }

    const sortByKey = (array, key) => {
        try {
            return array.sort(function (a, b) {
                var x = a[key]; var y = b[key];
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            });
        }
        catch (error) {
            console.error("Error in 'listViewSearchField.js -> sortByKey()':" + error);
        }
    }

    const fnSetFieldName = (event) => {
        try {
            let value = event.target.value;
            value = getStringParam(value);
            // setFieldName(value);
            dispatch(setFieldDetails({ name: object, value }));

            setOpen(false);
        } catch (error) {
            console.error("Error in 'listViewSearchField.js -> fnSetFieldName()':" + error);
        }
    }

    const handleError=()=>{
        const searchError =Boolean(changeLog.searchText.trim())
        if(!fieldName || !searchError){
            setError(true);
        }
    }

    const getDataAndProcess = () => {
        try {
            let data = sfForm.data[changeLog.stateObject];
            if (!isValidParam(data)) {
                var promise = SFFormActions.getSFFormFieldsPromise(changeLog.stateObject);
                promise.then((response) => {
                    if (isValidParam(response)) {
                        sfForm.data[changeLog.stateObject] = response;
                        setFields(response.fields);
                        let log = changeLog;
                        log.fields = response.fields;
                        setchangeLog({...log});
                    }
                });
            } else if (isValidParam(data)) {
                setFields(data.fields);
                let log = changeLog;
                        log.fields = data.fields;
                        setchangeLog({...log});
            }
        } catch (error) {
            console.error("Error in 'listViewSearchField.js -> getDataAndProcess()':" + error);
        }
    }


    let fieldlistmenu = getFieldlistmenu();

    return (

        <div style={{ display: 'flex' }}>

            {isFindAndLink &&
                <div style={{ ...styles.sf_12, paddingLeft: '0px', height: '38px', top: '0px', marginRight: '15px' ,marginTop:'5px'}}>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel id="sf-leadscoresetup-results-simple-select-outlined-label" className="sf-leadscoresetup-results" style={{ top: '-3px' }}>{getLocalizedStrings().label.LIST_VIEW.FIND_BY_FIELD}</InputLabel>
                        <Select
                            key="searchField"
                            label={getLocalizedStrings().label.LIST_VIEW.FIND_BY_FIELD}
                            id='searchField'
                            value={fieldName}
                            onChange={(e) => fnSetFieldName(e)}
                            style={{ height: '42px' }}
                            error={error && !fieldName}
                            >
                            {fieldlistmenu}
                            
                        </Select>
                       {error && !fieldName && <FormHelperText style={{ color: 'red' }}>Please select a field.</FormHelperText>}
                    </FormControl>
                </div>
            }
            <div style={
                {
                    // border: '1px solid black',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '30px',
                    width: appContainer.dialogProps.data.isFindAndLink ?'100%':'90%',
                    marginTop:5,
                    zIndex: 1

                }
            }>
                <div style={{ display: 'flex' }}>
                    <div style={{ ...styles.sf_12 }}>
                        {<FormControl style={{ width: appContainer.dialogProps.data.isFindAndLink ?'140%':'115%', top: '-7px', marginLeft: '15px' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                key='-9999'
                                id={fieldName}
                                label={getLocalizedStrings().label.COMMON.SEARCH}
                                fullWidth={true}
                                name={fieldName}
                                value={changeLog.searchText}
                                onChange={(e) => setFieldValue(e.target.value, fieldName)}
                                onKeyUp={(e) => handleKeyUp(e)}
                                className="form-field-search-icon"
                                autoComplete="new-password"
                                margin='dense'
                                size='small'
                                style={{ height: '38px' }}
                                InputLabelProps={{ classes: { paddingTop: '30px' } }}
                                // inputProps={{ style: { width: '82%' } }}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                       <span className="search-field-icon fa fa-search" style={{cursor:'pointer',color:'#000000'}} onClick={()=>fieldName && Boolean(changeLog.searchText.trim()) ?search():handleError()}></span>
                                      </InputAdornment>
                                    ),
                                  }}
                                error={error && !Boolean(changeLog.searchText.trim())}
                                 />
                        </FormControl>}
                    </div>
                    {/* <div style={{ zIndex: 1 }}>
                        <span className="search-field-icon fa fa-search" style={{ right: '52px', paddingTop: '10px',cursor:'pointer',marginLeft: appContainer.dialogProps.data.isFindAndLink ?'630%':'15px' }} onClick={()=>fieldName && Boolean(changeLog.searchText.trim()) ?search():handleError()}></span>
                    </div> */}
                </div>
            </div>
        </div>
    )
};

export default ListViewSearchField;

