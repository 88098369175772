
import React, { useState, useEffect } from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { assignAction } from '../../../services/actions/batchActions';
import { isValidParam, getObjectParam, getStringParam, getBooleanParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import map from 'lodash/map';
import * as sfDialogs from '../components/sfDialogs';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { refreshDetailViewData, refreshDetailViewListViewData, isDetailViewSet ,detailViewIsMounted} from '../../../services/actions/detailViewActions';
import { getTasksRefresh } from '../../../services/actions/taskActions';
import { getActiveTab, TYPE_COLUMN_VIEW, addTab, TYPE_LIST_VIEW } from '../../../services/helper/sfTabManager';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { refreshColumnView } from '../../../services/actions/listsActions';
import { isAssignRuleExists } from '../../../services/actions/assignmentRuleActions';
import { getSFFormFieldsPromise } from '../../../services/actions/sfFormActions';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from "react-redux";
import { fontSize } from '@mui/system';
import { getListData, refreshListView } from '../../../services/actions/listViewAcions';
import {updateActiveTab} from '../../../services/helper/sfTabManager';

const Assign = ({ object, selectedRecords, callFrom, closeDrawer }) => {
    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const sfForm = useSelector((state) => state.sfForm);
    const userlist = useSelector((state) => state.userlist);
    const detailViewRecord = useSelector((state) => state.detailView.record);
    const detailView = useSelector((state) => state.detailView);

    const [mounted, setMounted] = useState(true);
    const [salesRepFieldName, setSalesRepFieldName] = useState(getLocalizedStrings().label.COMMON.SALESREP);
    //const [disabled,setDisabled] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [stateIsAssignRuleExists, setIsAssignRuleExists] = useState(false);
    const [isSendMailChecked, setIsSendMailChecked] = useState(true);
    const [isBatchSendMailChecked, setBatchIsSendMailChecked] = useState(true);
    const [stateObject, setStateObject] = useState('');
    const [textarea, setTextArea] = useState(null);

    const [isTextNotifyChecked, setIsTextNotifyChecked] = useState(true);
    const [isBatchTextNotifyChecked, setBatchIsTextNotifyChecked] = useState(true);

    useEffect(() => {
        let obj = getObject();
        setStateObject(obj);
    }, []);

    useEffect(() => {
        if (stateObject !== '') {
            getSalesRepFieldLabel();
            fnIsAssignmentRuleExists();
        }
    }, [stateObject]);

    const getObject = () => {
        let tempObject = object;
        if (tempObject === constants.SEARCH_RESULTS) {
            let activeTab = getObjectParam(getActiveTab());
            let activeTabInfo = getObjectParam(activeTab.info);
            let search = getObjectParam(activeTabInfo.search);
            tempObject = getStringParam(search.object);
        } else if (tempObject === constants.ADVANCE_SEARCH_RESULTS) {
            let activeTab = getObjectParam(getActiveTab());
            let advanceSearch = getObjectParam(activeTab.info.advanceSearch);
            tempObject = getStringParam(advanceSearch.object);
        }
        return tempObject;
    }

    const getFormData = () => {
        try {
            var promise = getSFFormFieldsPromise(stateObject);
            promise.then((response) => {
                if (isValidParam(response)) {
                    sfForm.data[stateObject] = Object.assign({}, response);
                    let fields = getArrayParam(response.fields);
                    let field = fields.find(f => { return f.name === 'sales_rep' });
                    if (isValidParam(field)) {
                        setSalesRepFieldName(getStringParam(field.label));
                    }
                }
            });
        } catch (error) {
            console.error("Error in 'assign.js -> getFormData()':" + error);
        }
    }

    const getSalesRepFieldLabel = () => {
        try {
            let data = Object.assign({}, sfForm.data[stateObject]);
            if (Object.keys(data).length === 0) {
                getFormData();
            } else {
                data = getObjectParam(data);
                let fields = getArrayParam(data.fields);
                let field = fields.find(f => { return f.name === 'sales_rep' });
                if (isValidParam(field)) {
                    setSalesRepFieldName(getStringParam(field.label));
                }
            }
        } catch (error) {
            console.error("Error in 'assign.js -> getSalesRepFieldLabel()':" + error);
        }
    }


    const handleSelectChange = (event, value) => {

        let lastIndex = 0;
        try {
            if (value.length > 0) {
                lastIndex = value.length - 1;
                if (isValidParam(value) && value[lastIndex].value.includes("Setup Assignment Rule")) {
                    let tab = {
                        item: stateObject,
                        label: getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULES,
                        object: 'assignmentrules',
                        imgName: 'fas fa-cog',
                        url: '/setting/module',
                        type: TYPE_LIST_VIEW,
                    };
                    tab.info = { filter: { id: 0, name: 'Assignment Rule', type: '' } };
                    tab.info.selectedItem = { id: 0, name: 'assignmentrules', label: getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULES, type: "menu-item", object: 'assignmentrules' };
                    addTab(tab, true);
                }
                if (isValidParam(value) && value[lastIndex].value.includes("Use Assignment Rule")) {
                    value[lastIndex].value = "Use Assignment Rule";
                    let tempSelectedOptions = [];
                    tempSelectedOptions.push(value[lastIndex]);
                    setSelectedOptions(tempSelectedOptions);
                } else {
                    setSelectedOptions(value);
                }
            } else {
                setSelectedOptions(value);
            }
            let isSalesRep;
            if(selectedRecords.length > 1){
                if (value.length  == 1 ){
                    value.map((val)=>{
                        if(val.title === app.me.name){
                            return isSalesRep = false;
                        }else{
                            return isSalesRep = true;
                        }
                       })
                }else {
                     isSalesRep = true;
                }
            setBatchIsSendMailChecked(isSalesRep);
            setBatchIsTextNotifyChecked(isSalesRep)
            }
           
        } catch (error) {
            console.error("Error in 'assign.js -> handleSelectChange()':" + error);
        }
    }

    const handleSelectChangeObject = (event, value) => {
        try {
            if (value !== null && isValidParam(value) && value.value.includes("Setup Assignment Rule")) {
                let tab = {
                    item: stateObject,
                    label: getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULES,
                    object: 'assignmentrules',
                    imgName: 'fas fa-cog',
                    url: '/setting/module',
                    type: TYPE_LIST_VIEW,
                };
                tab.info = { filter: { id: 0, name: 'Assignment Rule', type: '' } };
                tab.info.selectedItem = { id: 0, name: 'assignmentrules', label: getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULES, type: "menu-item", object: 'assignmentrules' };
                addTab(tab, true);
            }
            if (value !== null && isValidParam(value) && value.value.includes("Use Assignment Rule")) {
                value.value = "Use Assignment Rule";
            }
            let tempSelectedOptions = [];
            if (value !== null) {
                tempSelectedOptions.push(value);
            }
            setSelectedOptions(tempSelectedOptions);
            if(value.label == app.me.name){
                setIsSendMailChecked(false);
                setIsTextNotifyChecked(false);
            }else{
                setIsSendMailChecked(true);
                setIsTextNotifyChecked(true);
            }
        } catch (error) {
            console.error("Error in 'assign.js -> handleSelectChangeObject()':" + error);
        }
    }

    const doAssign = () => {
        var records = [];
        var recordIds = [];
        let msg = null
        var username = "";
        let confirmMsg = '';
        let objectLabel = "";
        try {
            if (callFrom === constants.LIST_VIEW || callFrom === constants.KANBAN) {
                records = selectedRecords;
                for (var i = 0; i < records.length; i++) {
                    recordIds.push(records[i].id);
                }
            } else if (callFrom === constants.DETAIL_VIEW) {
                recordIds[0] = detailViewRecord.id;
            }
            let object = stateObject;
            let recLength = recordIds.length;
            var selectedUserList = selectedOptions !== null ? selectedOptions : [];
            var notetext = document.getElementById('notetext').value;
            var sendmailchkbox = document.getElementById('sendmail');
            var sendmail = sendmailchkbox.checked ? true : false;
            var textNotifyCheckBox = document.getElementById('textnotify');
            var textNotify = textNotifyCheckBox && textNotifyCheckBox?.checked ? true : false; 
            var uservals = [];
            var accountvals = [];
            if (selectedOptions.length === 1 && selectedOptions[0].value === "Use Assignment Rule") {
                uservals.push(-9999);
                username = "Use Assignment Rule";
            } else {
                selectedUserList.map((item, i) => {
                    uservals.push(item.value);
                });
                selectedUserList = userlist.data;
                for (let j = 0; j < uservals.length; j++) {
                    map(selectedUserList, function (userObject, i) {
                        if (uservals[j] === userObject.id.toString()) {
                            if (username === "") {
                                username = userObject.value;
                            } else {
                                username = username + "," + userObject.value;
                            }
                        }

                    });
                }
            }
            let tempObj = app.objectList.filter(f => { return f.name === object });
            objectLabel = tempObj[0].label;
            if (uservals.length > 1) {
                if (isValidParam(getLocalizedStrings().label.HEADER[objectLabel])) {
                    msg = recLength + ' ' + getLocalizedStrings().label.HEADER[objectLabel] + getLocalizedStrings().message.BATCH_ACTION.MULTI_ASSIGN_SUCCESSFULLY;
                } else {
                    msg = recLength + ' ' + objectLabel + getLocalizedStrings().message.BATCH_ACTION.MULTI_ASSIGN_SUCCESSFULLY;
                }
                confirmMsg = recLength + getLocalizedStrings().message.BATCH_ACTION.ASSIGN_ALERT_MSG + username + getLocalizedStrings().message.BATCH_ACTION.ROUND_ROBIN_BASIS;
            } else {
                if (isValidParam(getLocalizedStrings().label.HEADER[objectLabel])) {
                    msg = getLocalizedStrings().label.HEADER[objectLabel] + getLocalizedStrings().message.BATCH_ACTION.SINGLE_ASSIGN_SUCCESSFULLY;
                } else {
                    msg = objectLabel + getLocalizedStrings().message.BATCH_ACTION.SINGLE_ASSIGN_SUCCESSFULLY;
                }
                if (username === "Use Assignment Rule") {
                    confirmMsg = recLength + getLocalizedStrings().message.BATCH_ACTION.USE_ASSIGN_RULE_ALERT_MSG + username + '. ' + getLocalizedStrings().message.BATCH_ACTION.CONFIRM_MSG;
                } else {
                    confirmMsg = recLength + getLocalizedStrings().message.BATCH_ACTION.ASSIGN_ALERT_MSG + username + '. ' + getLocalizedStrings().message.BATCH_ACTION.CONFIRM_MSG;
                }
            }
            if (isValidParam(selectedUserList) && isValidParam(recordIds) && isValidParam(notetext)) {

                let params = {
                    "id": recordIds,
                    "user_id": uservals,
                    "note_text": notetext,
                    "is_send_email": sendmail,
                    "is_text_notify_sales_rep": textNotify,
                }
                if (username !== "") {
                    sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, confirmMsg, () => onConfirmDoAssign(params, msg), null);
                } else {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.BATCH_ACTION_VALIDATION.ASSIGN_BLANKUSER, null);
                }

            }
        } catch (error) {
            console.error("Error in 'assign.js -> doassign()':" + error);
        }
    }

    const onConfirmDoAssign = (params, msg) => {
        try {
            var response = assignAction(stateObject, params)
            setMounted(false);
            closeDrawer();
            response.then((response) => {
                if (response.status === 0) {
                    dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    let tab = getObjectParam(getActiveTab());
                    let tabInfo = getObjectParam(tab.info);
                    params = null;
                    if (tabInfo.hasOwnProperty('searchFieldName') && tabInfo.hasOwnProperty('searchText')) {
                        params = {
                            search_field_name: getStringParam(tabInfo.searchFieldName),
                            search_text: getStringParam(tabInfo.searchText)
                        }
                    }
                    if (detailView.isDetailView === true) {
                        dispatch(detailViewIsMounted(false));
                        dispatch(isDetailViewSet(false));
                        dispatch(refreshDetailViewData(true));
                        dispatch(refreshDetailViewListViewData(true));
                        dispatch(getTasksRefresh(true));
                        params = { ...params, isDetailView: true };
                    }
                    if (tab.type === TYPE_COLUMN_VIEW) {
                        dispatch(refreshColumnView(stateObject));
                    } else {
                        let activeTab = getActiveTab();
                        activeTab.info = { ...activeTab.info, rememberSelection: { ...activeTab.info.rememberSelection ?? {}, [object]: new Set()} };
                        updateActiveTab(activeTab);
                        refreshListView(stateObject, params);
                    }
                } else if (response.status === -1) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION.ASSIGN_UNSUCCESSFULL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
            });
        } catch (error) {
            console.error("Error in 'assign.js -> onConfirmDoAssign()':" + error);
        }
    }

	const onCheckedSendMail = (isSendMail) => {
		setIsSendMailChecked(isSendMail);
        if(selectedRecords.length > 1){
            setBatchIsSendMailChecked(isSendMail);
        }
	};

    const onCheckedTextNotify = (isTextNotify) => {
		setIsTextNotifyChecked(isTextNotify);
        if(selectedRecords.length > 1){
            setBatchIsTextNotifyChecked(isTextNotify);
        }
	};

    const fnIsAssignmentRuleExists = () => {
        let paramObj = { table_id: OBJECT_TABLEID_MAP[stateObject] };
        var response = Promise.resolve(isAssignRuleExists(paramObj));
        response.then((response) => {
            if (response.hasOwnProperty('isExists')) {
                setIsAssignRuleExists(getBooleanParam(response.isExists));
            }
        });
    }


    let disableOpts = false;
    if (callFrom === constants.LIST_VIEW || callFrom === constants.KANBAN) {
        if (selectedRecords.length > 1 && selectedOptions.length === 1 && selectedOptions[0].value === 'Use Assignment Rule') {
            disableOpts = true;
        }
    }
    let chkboxstyle = {
        fontWeight: 'normal',
        fontSize: '15px',
        marginLeft: '1px',
        padding: '16px 16px 0px 48px',
    };
    var tempUserlist = userlist.data;
    let userObj = null;
    var options = [];
    map(tempUserlist, function (userObject, i) {
        userObj = {};
        userObj.value = userObject.id.toString();
        userObj.label = userObject.value;
        userObj.title = userObject.value;
        userObj.disabled = disableOpts;
        options.push(userObj);
    });
    sortArrayObjectByProperty(options, 'label');

    if (getBooleanParam(stateIsAssignRuleExists)) {
        let obj = {};

        obj.value = 'Use Assignment Rule';
        obj.label = 'Use Assignment Rule';
        obj.title = 'Use Assignment Rule';
        obj.disabled = false;
        options.splice(0, 0, obj);
    }

    if ((getStringParam(app.me.t_name) === 'Admin' ||
        getBooleanParam(app.me.is_tenant)) && stateObject !== constants.CONTACTS_OBJECT) {

        let obj = {};
        obj.value = 'Setup Assignment Rule';
        obj.label = 'Setup Assignment Rule';
        obj.title = 'Setup Assignment Rule';
        obj.disabled = false;
        options.push(obj);
    }

    let tempPlaceHolderValue = (selectedRecords.length > 1) ?
        getLocalizedStrings().label.COMMON.SELECT_MORE + salesRepFieldName + "(s)" :
        getLocalizedStrings().label.COMMON.SELECT + ' ' + salesRepFieldName;
    let selectedOption = [];
    if (callFrom === constants.LIST_VIEW || callFrom === constants.KANBAN) {
        if (selectedRecords.length === 1) {
            selectedOption = {};
            selectedOption = selectedOptions[0];
        } else if (selectedRecords.length > 1) {
            selectedOption = [];
            selectedOption = selectedOptions;
            let tempOptions = [];
            tempOptions = options.filter(el => !selectedOption.filter(y => y.value === el.value).length);
            if (selectedOption.length === 1 && selectedOption[0].value.includes("Use Assignment Rule")) {
                options = [];
                tempOptions.map((tempOption, index) => {
                    tempOption.disabled = true;
                    options.push(tempOption);
                });
            } else {
                options = tempOptions;
            }
        }
    } else if (callFrom === constants.DETAIL_VIEW) {
        selectedOption = {};
        selectedOption = selectedOptions[0];
    }
    
    if (mounted) {
        return (
            <div style={{ marginLeft: '36px', marginRight: '42px' }}>
                <div style={{ marginTop: '24px' }}>

                    {(callFrom === constants.LIST_VIEW || callFrom === constants.KANBAN) && <div>
                        {(selectedRecords.length === 1) &&
                            <div>
                                <Autocomplete
                                    id={"single_autocomplete_salesrep"}
                                    name={"single_autocomplete_salesrep"}
                                    key={"single_autocomplete_salesrep"}
                                    value={selectedOption}
                                    getOptionDisabled={(option) => option.disabled === true}
                                    options={options}
                                    onChange={(event, value) => handleSelectChangeObject(event, value)}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable={false}
                                    renderInput={(params) => <TextField {...params}
                                        margin='dense' size='small' className={"sf-fields-bg"}
                                        label={tempPlaceHolderValue}
                                    />}
                                />
                            </div>
                        }
                        {(selectedRecords.length > 1) &&
                            <div>
                                <Autocomplete
                                    multiple
                                    id={"single_autocomplete_salesrepmultiple"}
                                    name={"single_autocomplete_salesrepmultiple"}
                                    key={"single_autocomplete_salesrepmultiple"}
                                    value={selectedOption}
                                    getOptionDisabled={(option) => option.disabled === true}
                                    options={options}
                                    onChange={(event, value) => handleSelectChange(event, value)}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable={false}
                                    renderInput={(params) => <TextField {...params} margin='dense' size='small' className={"sf-fields-bg"}
                                        label={tempPlaceHolderValue} />}
                                />
                            </div>
                        }
                    </div>}
                    {(callFrom === constants.DETAIL_VIEW) && <div>
                        <Autocomplete
                            id={"single_autocomplete_salesreps"}
                            name={"single_autocomplete_salesreps"}
                            key={"single_autocomplete_salesreps"}
                            value={selectedOption}
                            getOptionDisabled={(option) => option.disabled === true}
                            options={options}
                            onChange={(event, value) => handleSelectChangeObject(event, value)}
                            getOptionLabel={(option) => option.label}
                            disableClearable={false}
                            renderInput={(params) => <TextField {...params} placeholder={callFrom !== constants.DETAIL_VIEW ? getLocalizedStrings().label.MARKETING_TEMPLATE.SELECT_TEMPLATE : ''}
                                margin='dense' size='small' className={"sf-fields-bg"} label={tempPlaceHolderValue} />}
                        />

                    </div>}
                </div>

                <div style={{ color: '#717171', marginTop: '10px' }}>
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            id='notetext'
                            multiline={true}
                            rows={4}
                            textareastyle={{ marginTop: '10px', height: '90%', fontSize: 16 }}
                            autoComplete="new-password"
                            margin='dense' size='small' className={"sf-fields-bg"}
                            ref={(input) => { setTextArea(input) }}
                            label={getLocalizedStrings().label.APPOINTMENTS_TASKS.ADD_NOTE}
                        />

                    </FormControl>
                </div>
                <div>
                    <div style={{ marginTop: '5px' }}>
                        <FormControlLabel control={<Checkbox color="default" id="sendmail"
                            onChange={(e) =>
                                onCheckedSendMail(e.target.checked)
                            }
                            checked={selectedRecords.length > 1? isBatchSendMailChecked: isSendMailChecked}
                            />} label=<span style={{ fontSize: 14 }}>{getLocalizedStrings().label.COMMON.EMAIL+" "+getLocalizedStrings().label.COMMON.SALESREP} </span> style={{ marginRight: '5px', padding: '0px' }} labelstyle={{ color: '#717171', margin: '0px', padding: '0px', fontWeight: 'normal' }} labelposition="right" />
                    </div>
                    {app.me.phone_text_provider && app.me.phone_text_provider!= constants.NONE && app.me.notify_text_salesrep && stateObject == constants.ACCOUNTS_OBJECT && <div style={{ marginTop: '5px' }}>
                        <FormControlLabel control={<Checkbox color="default" id="textnotify"
                            onChange={(e) =>
                                onCheckedTextNotify(e.target.checked)
                            }
                            checked={selectedRecords.length > 1 ? isBatchTextNotifyChecked : isTextNotifyChecked}
                        />} label=<span style={{ fontSize: 14 }}>{getLocalizedStrings().label.COMMON.TEXT + " " + getLocalizedStrings().label.COMMON.SALESREP} </span> style={{ marginRight: '5px', padding: '0px' }} labelstyle={{ color: '#717171', margin: '0px', padding: '0px', fontWeight: 'normal' }} labelposition="right" />
                    </div>}
                    <div>
                        <div style={{ float: 'right' }}>
                            <Button
                                style={{ ...styles.primaryButton, fontSize: 16 }}
                                onClick={(e) => doAssign()}
                            >{getLocalizedStrings().label.COMMON.ASSIGN}</Button>
                            <Button
                                onClick={() => closeDrawer()}
                                style={{ ...styles.secondaryButton, fontSize: 16 }} >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                        </div>

                    </div>
                </div>
            </div>
        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }

}

export default Assign;