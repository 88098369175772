import * as HTTPClient from '../helper/httpClient';
import { actions } from '../constants/actions';
import { endPoints } from '../constants/endPoints';
import { constants } from '../constants/constants';
import { isValidParam } from '../helper/parameterVerifier';
import store from '../../../src/services/store';
import moment from 'moment';
import { dateFormat } from '../helper/utils';

function getAllAppointments(condition) {
    let params = {
        query_name: "All Appointments",
        query_type: "query",
        search: condition,
        page_size: -1,
        search_type: constants.SEARCH_TYPE_EXACT,
        fields: ["id", "subject", 'assign', 'company', 'opportunity_name', 'project_name', 't_visibility', 'start_date', 'end_date', 't_status', 'user_id', 'visible_to', 'created_on', 'link_info', 'parent_recordid', 'event_type', 'fullname', 'location', 'text']
    }

    return {
        type: actions.APPOINTMENT_GET,
        payload: HTTPClient.get(endPoints.APPOINTMENT.LIST, params)
    }
}

function formatDateYMD(date, time) {
    let tempDt = '';
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    tempDt = [year, month, day].join('-');
    if (time !== 'na') {
        tempDt = tempDt.trim() + time.trim();
    }
    return tempDt;
}
function getDayAvailability(userTimeAvailability, eventDate, user) {
    let eventData = {isBlock:false,start:eventDate.start,end:eventDate.end};
    userTimeAvailability.map((data, i) => {
        var date = new Date(eventDate.start);
        let dayId = date.getDay();
        dayId = dayId === 0 ? 7 : dayId;
        if (dayId === data.day_id && data.technician_id === user) {
            if (data.to_time === -1) {
                eventData.isBlock = true;
            } else {
                let ft = data.from_time < 10 ? "0" + data.from_time : data.from_time;
                ft = "T" + ft + ":00:00.000";
                let tt = data.to_time < 10 ? "0" + data.to_time : data.to_time;
                tt = "T" + tt + ":00:00.000";
                let sDate = formatDateYMD(eventDate.start, ft);
                let eDate = formatDateYMD(eventDate.end, tt);

                var g1 = new Date(sDate);
                var g2 = new Date(eventDate.start);
                if (g1.getTime() < g2.getTime()) {
                    sDate = eventDate.start;
                    let dt = new Date(sDate);
                    eDate =  new Date(dt.setMinutes(dt.getMinutes() + 30));
                }else{
                    sDate = sDate;
                    let dt = new Date(sDate);
                    eDate =  new Date(dt.setMinutes(dt.getMinutes() + 30));

                }   
                         
                eventData.isBlock = false;
                eventData.start = sDate;
                eventData.end = eDate;
                
            }
        }
    });
    return eventData;
}

function prepareAllDateEvents(eventObj, userArr, dateRange) {
    let dateArr = [];
    let events = [];
    var date1 = new Date(formatDateYMD(dateRange.startDate, 'na'));
    var date2 = new Date(formatDateYMD(dateRange.endDate, 'na'));
    let userName = '';
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    for (let i = 0; i <= Difference_In_Days; i++) {
        var date = new Date(dateRange.startDate);
        date.setDate(date.getDate() + i);
        let dayId = date.getDay();
        dayId = dayId === 0 ? 7 : dayId;
        if (dayId === eventObj.day_id) {
            dateArr.push(date)
        }
    }

    userArr.map((user, i) => {
        if (eventObj.technician_id === user.Id) {
            userName = user.Text;
        }
    })
    dateArr.map((data, i) => {
        let allDay = false;
        if (eventObj.to_time === -1) {
            allDay = true;
            let sDate = formatDateYMD(data, "T00:00:00.000");
            let eDate = formatDateYMD(data, "T23:59:59.000");
            events.push(getprepareAllDateEventsObj(eventObj, allDay, sDate, eDate, userName));

        } else {
            allDay = false;
            let t1 = eventObj.from_time < 10 ? "0" + eventObj.from_time : eventObj.from_time;
            t1 = "T" + t1 + ":00:00.000";
            let t2 = eventObj.to_time < 10 ? "0" + eventObj.to_time : eventObj.to_time;
            t2 = "T" + t2 + ":00:00.000";
            let sDate1 = formatDateYMD(data, "T00:00:00.000");
            let eDate1 = formatDateYMD(data, t1);
            events.push(getprepareAllDateEventsObj(eventObj, allDay, sDate1, eDate1, userName));
            let sDate2 = formatDateYMD(data, t2);
            let eDate2 = formatDateYMD(data, "T23:59:59.000");
            events.push(getprepareAllDateEventsObj(eventObj, allDay, sDate2, eDate2, userName));

        }
    })

    return events;
}
function getprepareAllDateEventsObj(eventObj, allDay, sDate, eDate, userName) {
    let tempObj = {
        "id": new Date().getTime(),
        "subject": "",
        "description": "",
        "company": "",
        "linkInfo": "",
        "assign_user": userName,
        "parent_recordid": "",
        "type": "",
        "contact": "",
        "IsBlock": true,
        "allDay": allDay,
        "start": sDate,
        "end": eDate,
        "location": eventObj.zip_code,
        "visibility": "Public",
        "opportunityName": "",
        "jobName": "Not available"
    }
    return tempObj;

}
function getAllJobAppointments(condition) {
    let params = {
        query_name: "All Appointments",
        query_type: "query",
        search: condition,
        click_form: "job_scheduler",
        page_size: -1,
        search_type: constants.SEARCH_TYPE_EXACT,
        fields: ["id", "subject", 'assign', 'company', 'opportunity_name', 'project_name', 't_visibility', 'start_date', 'end_date', 't_status', 'user_id', 'visible_to', 'created_on', 'link_info', 'parent_recordid', 'event_type', 'fullname', 'location', 'text']
    }

    return {
        type: actions.JOB_APPOINTMENT_GET,
        payload: HTTPClient.get(endPoints.APPOINTMENT.LIST, params)
    }
}

function getAllBroadcasts(params) {
    return {
        type: actions.BROADCAST_GET,
        payload: HTTPClient.get(endPoints.BROADCAST_CAMPAIGNS.LIST_GET, params)
    }
}

function updateAppointmentStore(dataObject) {
    return {
        type: actions.APPOINTMENT_UPDATE,
        payload: dataObject
    }
}

function deleteFromAppointmentStore(id) {
    return {
        type: actions.APPOINTMENT_DELETE,
        payload: id
    }
}

function updateAppointmentStoreByDragandDrop(dataObject) {
    return {
        type: actions.APPOINTMENT_UPDATE_DRAG_AND_DROP,
        payload: dataObject
    }
}

function getCalendarRefresh(refresh) {
    let type = null;
    type = actions.CALENDAR_REFRESH;
    return {
        type: type,
        payload: refresh
    }
}

function getJobCalendarRefresh(refresh) {
    let type = null;
    type = actions.JOB_CALENDAR_REFRESH;
    return {
        type: type,
        payload: refresh
    }
}

function refreshCalendarForBroadcast(isRefresh) {
    let type = actions.CALENDAR_REFRESH_FOR_BROADCAST;
    return {
        type: type,
        payload: isRefresh,
    }
}

function setCalendarInfo(searchCriteria) {
    return function (dispatch) {
        dispatch({
            type: actions.SET_CALENDAR_INFO,
            payload: searchCriteria
        })
    }
}

function setSelectedDate(date) {
    try {
        return function (dispatch) {
            dispatch({
                type: actions.SELECTED_DATE_SET_FULFILLED,
                payload: date
            })
        }
    }
    catch (e) {
        console.error("Error in 'calendarAction.js -> SELECTED_DATE_SET_FULFILLED':" + e);
    }
}
function selectedDateRangeUpdate(data) {
    try {
        return function (dispatch) {
            dispatch({
                type: actions.SELECTED_DATE_RANGE_UPDATE,
                payload: data
            })
        }
    }
    catch (e) {
        console.error("Error in 'calendarAction.js -> SELECTED_DATE_RANGE_UPDATE':" + e);
    }
}
function userTimeAvailabilityUpdate(data) {
    try {
        return function (dispatch) {
            dispatch({
                type: actions.USER_TIME_AVAILABILITY_UPDATE,
                payload: data
            })
        }
    }
    catch (e) {
        console.error("Error in 'calendarAction.js -> USER_TIME_AVAILABILITY_UPDATE':" + e);
    }
}
function formatDate(date, type) {
    let tempDate = (moment(String(date)).format(dateFormat["MM/dd/yy hh:mm a"]));
    try {

        if (isValidParam(tempDate)) {
            let d = tempDate instanceof Date ? tempDate : new Date(tempDate);
            let month = '' + (d.getMonth() + 1);
            let day = '' + d.getDate();
            let year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            let opDate = [year, month, day].join('-');
            let dateTime = opDate;
            if (type === 'start') {
                dateTime = opDate + " 00:00:00";
            } else {
                dateTime = opDate + " 23:59:59";
            }
            return dateTime;
        }
        else {
            return null;
        }
    }
    catch (e) {
        console.error("Error in 'calendarAction.js -> formatDate':" + e);
    }
}
function removejobById(id) {
    let state = store.getState();
    let unscheduledJobs = state.calendar.calendarInfo.unscheduledJobs.filter(e => e.jobId !== id);
    let data = state.calendar.calendarInfo;
    data.unscheduledJobs = unscheduledJobs;
    store.dispatch({ type: actions.JOB_APPOINTMENT_UPDATE, payload: data });
}

function getDateRange(mode) {
    try {
        var dateRange = {};
        var date = new Date();
        var startDate = new Date();
        var endDate = new Date();
        var first = null;
        var last = null;
        if (mode === 'Day' || mode === 'day') {
            startDate = date;
            endDate = date;
        }
        else if (mode === 'Week' || mode === 'week') {
            first = date.getDate() - date.getDay();
            last = first + 6;
            startDate = new Date(startDate.setDate(first));
            endDate = new Date(endDate.setDate(last));
        }
        else if (mode === 'Agenda' || mode === 'agenda') {
            startDate = new Date();
            endDate = new Date(date.setDate(date.getDate() + 6));
        }
        else if (mode === 'WorkWeek' || mode === 'workweek') {
            var days = date.getDate() - date.getDay();
            first = days + 1;
            last = days + 5;
            startDate = new Date(startDate.setDate(first));
            endDate = new Date(endDate.setDate(last));
        }
        else if (mode === 'Month' || mode === 'month') {
            startDate = new Date(date.getFullYear(), date.getMonth(), 1);
            endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            startDate = new Date(startDate.setDate(startDate.getDate() - startDate.getDay()));
            endDate = new Date(endDate.setDate(endDate.getDate() + (6 - endDate.getDay())));
        }
        else {
            startDate = date;
            endDate = date;
        }
        dateRange.startDate = formatDate(startDate, 'start');
        dateRange.endDate = formatDate(endDate, 'end');
        return dateRange;

    }
    catch (e) {
        console.error("Error in 'calendarAction.js -> getDateRange':" + e);
    }
}

export {
    getAllAppointments,
    getAllJobAppointments,
    updateAppointmentStore,
    deleteFromAppointmentStore,
    updateAppointmentStoreByDragandDrop,
    getCalendarRefresh,
    getJobCalendarRefresh,
    setCalendarInfo,
    refreshCalendarForBroadcast,
    getAllBroadcasts,
    formatDate,
    getDateRange,
    getprepareAllDateEventsObj,
    selectedDateRangeUpdate,
    setSelectedDate,
    removejobById,
    prepareAllDateEvents,
    getDayAvailability,
    userTimeAvailabilityUpdate,
    formatDateYMD
}
