import React from 'react';
import { connect } from 'react-redux';
import { getPendingTasks } from '../../../services/actions/taskActions';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import { setHeader } from '../../../services/actions/headerActions';
import ListView from './ListView';
import filter from 'lodash/filter';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';

class Task extends React.Component {
    componentDidMount() {
        this.props.getPendingTasks();

        let label = 'Tasks';
        let kanbanObject = {};
        kanbanObject.currentView = "calendarOrTask";
        kanbanObject.show = false;
        this.props.setHeader(this.props.object, label, kanbanObject, true, true);
    }

    constructor(props) {
        super(props);
        this.state = { imgName: null };
    }

    fetchAllTasks = (e) => {
        this.setHeaderIcon();
    }

    fetchPendingTasks = (e) => {
        this.props.getPendingTasks();
    }

    setHeaderIcon = () => {
        let activeTabIndex = this.props.tab.activeTabIndex;
        let tab = filter(this.props.tab.tabs, function (tab, index) {
            return (tab.value === activeTabIndex)
        });
        let imgName = tab[0].imgName !== null ? tab[0].imgName : null;
        let _state = this.state;
        _state.imgName = imgName;
        this.setState(_state);
    }

    render() {
        if (this.props.task.mounted) {
            return (
                <div style={styles.workAreaContainer}>
                    <div style={{ paddingLeft: '26px', paddingRight: '26px' }}>
                        {this.props.task.isListView
                            ? <div style={{ margin: '0px', height: '100%', padding: '0px', backgroundColor: '#ffffff' }}>
                                <ListView object={this.props.object} />
                            </div>
                            : <div>{getLocalizedStrings().label.APPOINTMENTS_TASKS.PENDING_TASKS}</div>
                        }
                    </div>
                </div>
            );
        } else {
            let contentHeight = window.innerHeight - 240;
            let top = (contentHeight - 10) / 2
            return (
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        app: state.app,
        tab: state.tab,
        task: state.task
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPendingTasks: () => {
            dispatch(getPendingTasks());
        },
        setHeader: (selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader) => {
            dispatch(setHeader(selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader));
        }
    }

};

Task = connect(mapStateToProps, mapDispatchToProps)(Task);

export default Task;
