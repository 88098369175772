
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isValidParam, getStringParam, getObjectParam, getIntParam, getBooleanParam, getArrayParam, getFloatParam } from '../../../services/helper/parameterVerifier';
import { setHeader } from '../../../services/actions/headerActions';
import {styles} from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import PopOver from '../components/PopOver';
import {Menu,  Select, MenuItem,InputLabel} from '@mui/material';
import Button from '@mui/material/Button';
import SFDataTable from '../components/sfDataTable';
import NoRecords from '../components/noRecord';
import ShowCircularProgress from '../components/circularProgress';
import { addTab, getActiveTab, TYPE_LIST_VIEW, getActiveTabInfo, updateTabInfo } from '../../../services/helper/sfTabManager';
import { ORTHOTIC_REPORT, constants } from '../../../services/constants/constants';
import { dateFormat, dateFormatFlatPicker, sortArrayObjectByProperty } from '../../../services/helper/utils';
import moment from 'moment';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import 'flatpickr/dist/flatpickr.min.css';
import Flatpickr from 'react-flatpickr';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


const optionvalue = {
    td: 'Today',
    yd: 'Yesterday',
    tw: 'This Week',
    tm: 'This Month',
    tq: 'This Quarter',
    ty: 'This Year',
    lw: 'Last Week',
    lm: 'Last Month',
    lq: 'Last Quarter',
    ly: 'Last Year',
    cust: 'Custom'
};

function formatDate(date) {
    if (isValidParam(date)) {
        let d = date instanceof Date ? date : new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }
    return null;
}

function toDate(dateStr) {
    var parts = dateStr.split("-");
    return new Date(parts[0], parts[1] - 1, parts[2]);
}

const OrthoticReport =()=> {
    const dispatch = useDispatch();
    const [dueBy, setDueBy] = useState("tm");
    const [from_date, setFrom_date] = useState(null);
    const [to_date, setTo_date] = useState(null);
    const [productId, setProductId] = useState('');
    const [product, setProduct] = useState('');
    const [productList, setProductList] = useState(null);
    const [reportParams, setReportParams] = useState({
                    due_by: 'tm',
                    from_date: null,
                    to_date: null,
                    productId: ""
                },);
    const [isMounted, setIsMounted] = useState(false);
    const [due_by_popup, setDue_by_popup] = useState(false);
    const [fields, setFields] = useState([]);
    const [records, setRecords] = useState(null);
    const [rowSort, setRowSort] = useState({
                    sortFieldName: 'id',
                    isDescending: true,
                    onSort: this.onSort
                },);
    const [customTimeLineDisplay, setCustomTimeLineDisplay] = useState('none');
    const [startIndex, setStartIndex] = useState(ORTHOTIC_REPORT.START_INDEX);
    const [fetchSize, setFetchSize] = useState(ORTHOTIC_REPORT.FETCH_SIZE);
    const [isShowMore, setIsShowMore] = useState(false);
    const [isMoreClicked, setIsMoreClicked] = useState(false);
    const [isFltrStyle, setIsFltrStyle] = useState(false);
    const [tempFieldName, settempFieldName] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [name, setName] = useState(null);


    const appState = useSelector((state) => state.app);
    const report = useSelector((state) => state.report);


    useEffect(() => {
        report.showProgressBar = false;
        let rowSort = rowSort;
        loadProductList();
      }, [report]);

    const loadProductList = () => {
        let url = endPoints.ORTHOTIC_REPORT.ORTHOTIC_PRODUCT_LIST_GET;
        let params = {};
        let products = [];
        let list = null;
        let distinctProducts = null;
        let strArr = null;
        try{
        let promise = Promise.resolve(HTTPClient.get(url, params));
        promise.then((response) => {
            if (isValidParam(response.orthoticProductList)) {
                list = getArrayParam(response.orthoticProductList);
                list.map((item, index) => {
                    strArr = getStringParam(item.id).split(",");
                    strArr.map((p, i) => {
                        products.push(p);
                    })
                    strArr = null;
                })
                distinctProducts = [...new Set(products)];
                setIsMounted(true);
                setProductList(distinctProducts);
            }
        });
        }
        catch(error){
            console.error("Error in 'orthoticReport.js -> loadProductList()':" + error);
        }
    }

    const setReportParamsfun = () => {
        let tempDueBy = getStringParam(dueBy);
        let fromDate = from_date;
        let toDate = to_date;
        let tempProductId = getStringParam(productId);
        let start_index = isMoreClicked ? startIndex : ORTHOTIC_REPORT.START_INDEX;
        let fetch_size = fetchSize;
        let params = {
            due_by: tempDueBy,
            from_date: formatDate(fromDate),
            to_date: formatDate(toDate),
            product_id: tempProductId,
            start_index: start_index,
            fetch_size: fetch_size
        }
        setReportParams(params);
        return params;
    }
    const getMultilingualOptionsMap = () => {
        let optionsMultilingual = {
            'Today': getLocalizedStrings().label.DASHBOARD.TODAY,
            'Yesterday': getLocalizedStrings().label.DASHBOARD.YESTERDAY,
            'This Week': getLocalizedStrings().label.DASHBOARD.THIS_WEEK,
            'This Month': getLocalizedStrings().label.DASHBOARD.THIS_MONTH,
            'This Quarter': getLocalizedStrings().label.DASHBOARD.THIS_QUARTER,
            'This Year': getLocalizedStrings().label.DASHBOARD.THIS_YEAR,
            'Last Week': getLocalizedStrings().label.DASHBOARD.LAST_WEEK,
            'Last Month': getLocalizedStrings().label.DASHBOARD.LAST_MONTH,
            'Last Quarter': getLocalizedStrings().label.DASHBOARD.LAST_QUARTER,
            'Last Year': getLocalizedStrings().label.DASHBOARD.LAST_YEAR,
            'Custom': getLocalizedStrings().label.DASHBOARD.CUSTOM
        };
        return optionsMultilingual;
    }

    const generateDateSelectionField=()=> {
        let optionsMultilingual = getMultilingualOptionsMap();
        let popOverList = [];
        Object.keys(optionvalue).map((itemKey) => {
            let data = {};
            data.label = optionsMultilingual[optionvalue[itemKey]];
            data.value = itemKey;
            popOverList.push(data);
        });
        return popOverList; 
    }

    const generateProductSelectionField=()=> {
        let tempProductList = getArrayParam(productList);
        let options = [];
        try {
            tempProductList.map((item, index) => {
                let data = {};
                data.label = item;
                data.value = item;
                options.push(data);
            });

        } catch (error) {
            console.error("Error in 'orthoticReport.js -> generateProductSelectionField()':" + error);
        }
        return options;
    }

    const handlePopover = (fieldName, event) => {
        try {
            settempFieldName(true);
            setAnchorEl(event.currentTarget);
        }
        catch (error) {
            console.error("Error in 'orthoticReport.js -> handlePopover()':" + error);
        }
    }

    const handleRequestClose = (fieldName) => {
        try {
            settempFieldName(false);
        }
        catch (error) {
            console.error("Error in 'orthoticReport.js -> handleRequestClose()':" + error);
        }
    };

    const handleDropDownChange = ( event, name,value) => {
        try {
            if (name === "due_by") {
                setDueBy(value);
                if (value === "cust") {
                    setCustomTimeLineDisplay('block');
                    setFrom_date(new Date());
                    setTo_date(new Date());
                    setIsFltrStyle(true);
                    setStartIndex(ORTHOTIC_REPORT.START_INDEX);
                    setRecords([]);
                    let dateTimeDiv1 = document.getElementsByClassName("dateTimeDiv-from-date");
                    if (isValidParam(dateTimeDiv1) && dateTimeDiv1.length > 0) {
                        dateTimeDiv1[0].classList.add('label-top');
                    }
                    let dateTimeDiv2 = document.getElementsByClassName("dateTimeDiv-to-date");
                    if (isValidParam(dateTimeDiv2) && dateTimeDiv2.length > 0) {
                        dateTimeDiv2[0].classList.add('label-top');
                    }
                } else {
                    setCustomTimeLineDisplay('none');
                    setIsFltrStyle(false);
                    setFrom_date(null);
                    setTo_date(null);
                }
            }
            else if (name === "product") {
                let productValue = null;
                if (getArrayParam(productList).length > 0) {
                    productList.map((f) => {
                        if (f === value) {
                            productValue = value;
                        }
                    });
                }
                setProductId(productValue);
                setProduct(productValue);
            }
            setStartIndex(ORTHOTIC_REPORT.START_INDEX);
            setFetchSize(ORTHOTIC_REPORT.FETCH_SIZE);
            setRecords([]);
            handleRequestClose(name + "_popup");
        }
        catch (error) {
            console.error("Error in 'orthoticReport.js -> handleDropDownChange()':" + error);
        }
    }

    const handleDateChange = (date) => {
        setName(date);
    }

    const handleDateFocus = (fieldName, event) => {
        let dateTimeDiv = document.getElementsByClassName("dateTimeDiv-" + fieldName);
        if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
            dateTimeDiv[0].classList.add('label-top');
        }
    }

    const handleDateBlur = (fieldName, event) => {
        let field_name = fieldName.replace('-', '_');
        let value = getStringParam(field_name);
        if (value === '') {
            let dateTimeDiv = document.getElementsByClassName("dateTimeDiv-" + fieldName);
            if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
                dateTimeDiv[0].classList.remove('label-top');
            }
        }
    }

    const verify = () => {
        if (getStringParam(productId).length > 0) {
            reloadReport();
        } else {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.ORTHOTIC_REPORT.VALIDATE_EMPTY_PRODUCT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
    }
    const getButtonSet = () => {
        return (
            <div>
                <iframe id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute', display: 'none' }}></iframe>
                <Button
                    onClick={() => { verify() }}
                    style={{
                        ...styles.primaryButton,
                        minWidth: '40px'
                    }}
                    title={getLocalizedStrings().label.REPORT.GO}
                >{getLocalizedStrings().label.REPORT.GO}</Button>
                <Button
                    onClick={() => {
                        var content = document.getElementById("divcontents");
                        var ifrmae = document.getElementById("ifmcontentstoprint");
                        ifrmae.style.display = 'block'
                        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                        ifrmae.style.display = 'block'

                        pri.document.open();
                        pri.document.write(

                            content.innerHTML);
                        pri.document.close();
                        pri.focus();
                        pri.print();
                        ifrmae.style.display = 'none'
                    }}

                    style={{
                        ...styles.secondaryButton,
                        minWidth: '40px'
                    }}
                    title={getLocalizedStrings().label.REPORT.PRINT}
                >{getLocalizedStrings().label.REPORT.PRINT}</Button>
                <Button
                    startIcon={<i className="far fa-file-excel" style={{ fontSize: '20px', color: 'green' }} />}
                    onClick={() => { downloadExcel(); }}
                    style={{
                        ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                        color: '#717171',
                        border: '0px',
                        minWidth: '30px'
                    }}
                    title="Excel"
                ></Button>
            </div>
        );
    }

    const getReportFilter = () => {
        let buttonSet = getButtonSet();
        let datePopOverList= generateDateSelectionField();
        let productPopOverList= generateProductSelectionField();
        let sfFormFieldContainerStyle = { ...styles.sfFormFieldContainer };
        let marginStyle = isFltrStyle ? { marginLeft: '20px' } : { marginLeft: '0px' };
        let HHFormat = new RegExp("HH");
        let datePickerOptions = {
            enableTime: false,
            dateFormat: dateFormatFlatPicker[appState.me.date_format],
            minuteIncrement: 1,
            static: true,
            time_24hr: HHFormat.test(appState.me.date_format) ? true : false
        };
        return (
            <div className="row" style={{ marginLeft: '-13px', padding: '0px' }} >
                <div className="col-md-12">
                    <div className="col-md-2" style={{ paddingLeft: '7px' }} >
                        <div
                            style={{ ...sfFormFieldContainerStyle, width: '100%', cursor: "pointer", fontSize: '14px', color: '#717171', height: '47px' }}
                            onClick={(event)=>handlePopover('due_by_popup',event)}
                            id='due_by'
                            key='due_by'
                        >
                            <div style={{ paddingLeft: '7px' }}>{getLocalizedStrings().label.REPORT.DATE_RANGE}</div>
                            <span style={{ paddingLeft: '7px' }} className="ellip">{optionvalue[dueBy]}</span>
                            <PopOver
                                id={'due_by'}
                                key={'due_by'}
                                name={'due_by'}
                                buttonEndIcon={"keyboard_arrow_down"}
                                buttonStyle={{ fontWeight: 'bold', paddingLeft: '2px', marginTop: '-16px', marginLeft: '77px' }}
                                labelposition={"before"}
                                buttonLabel={""}
                                options={datePopOverList}
                                onclickAction={(event)=>handleDropDownChange(event,'due_by', 'due_by')}
                                isShowIcon={true}
                                anchorEl={anchorEl}
                                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                            />
                        </div>
                    </div>

                    <div className="col-md-3" style={{ paddingLeft: '39px', paddingRight: '39px', display: customTimeLineDisplay, marginLeft: '-3%' }}>

                        <div className='sf-form-field dateTimeDiv-from-date label-top' id="dateholder" style={{ ...sfFormFieldContainerStyle, paddingLeft: '0px', height: '47px' }}>
                            <label className="date-label">{getLocalizedStrings().label.REPORT.START_DATE}</label>

                            <Flatpickr style={{ height: '45px', width: '235px', border: '0px' }}
                                onChange={()=>handleDateChange('from_date')}
                                value={Date.parse(from_date)}
                                options={datePickerOptions}
                                className='flatpickr'
                            />
                        </div>
                    </div>

                    <div className="col-md-3" style={{ paddingLeft: '39px', paddingRight: '39px', display: customTimeLineDisplay, marginLeft: '-7%', marginRight: -47 }}>
                        <div className='sf-form-field dateTimeDiv-to-date label-top' id="dateholder" style={{ ...sfFormFieldContainerStyle, paddingLeft: '0px', height: '47px' }}>
                            <label className="date-label">{getLocalizedStrings().label.REPORT.END_DATE}</label>

                            <Flatpickr style={{ height: '45px', width: '235px', border: '0px' }}
                                onChange={()=>handleDateChange('to_date')}
                                value={Date.parse(to_date)}
                                options={datePickerOptions}
                                className='flatpickr'
                                disable={(date) => {
                                    return date.isAfter((from_date).subtract(1, 'day'))
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-md-2" style={{ ...marginStyle, padding: 0 }} >
                        <div
                            style={{ ...sfFormFieldContainerStyle, width: '100%', cursor: "pointer", fontSize: '14px', color: '#717171', height: '47px' }}
                            onClick={(event)=>handlePopover('product_popup',event)}
                            id='product'
                            key='product'
                        >
                            <div style={{ paddingLeft: '7px' }}>{getLocalizedStrings().label.REPORT.PRODUCT_SELECT}</div>
                            <div style={{ paddingLeft: '7px', width: '80%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', float: 'left' }}
                                title={product}>
                                {product}
                            </div>
                            <PopOver
                                id={'product'}
                                key={'product'}
                                name={'product'}
                                buttonEndIcon={"keyboard_arrow_down"}
                                buttonStyle={{ fontWeight: 'bold', paddingLeft: '2px', paddingTop: '5px' }}
                                labelposition={"before"}
                                buttonLabel={""}
                                options={productPopOverList}
                                onclickAction={(event)=>handleDropDownChange(event,'product', 'product')}
                                isShowIcon={true}
                                anchorEl={anchorEl}
                                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                            />
                        </div>
                    </div>
                    <div className="col-md-3" style={{ marginTop: '10px' }}>{buttonSet}</div>
                </div>
            </div>
        )
    }

    const getReportHeaderName = () => {
        let component = null;
        component = (
            <div className="col-md-12">
                <div className="row" style={{ paddingLeft: "2px", paddingBottom: "15px" }}>
                    <span className="text" style={{ margin: '0px', fontSize: '22px' }}><h3 style={{ fontWeight: 'bold' }}>{getLocalizedStrings().label.ORTHOTIC_REPORT.ORTHOTIC_REPORT_LABEL}</h3></span>
                </div>
            </div>)
        return component;
    }

    const getFieldList = () => {
        let columnHeaderList = [
            { field_type: "integer", is_date: false, is_hidden: true, is_detail_link: false, is_sql_col: false, label: "Id", name: "id" },
            { field_type: "text", is_date: false, is_hidden: false, is_detail_link: false, is_sql_col: false, label: "Company Name", name: "company", style: { textAlign: 'left', width:'100px' } },
            { field_type: "text", is_date: false, is_hidden: false, is_detail_link: false, is_sql_col: false, label: "First Name", name: "first_name", style: { textAlign: 'left', width:'100px' } },
            { field_type: "text", is_date: false, is_hidden: false, is_detail_link: false, is_sql_col: false, label: "Last Name", name: "last_name", style: { textAlign: 'left', width:'100px' } },
            { field_type: "text", is_date: false, is_hidden: false, is_detail_link: false, is_sql_col: false, label: "Email", name: "email", style: { textAlign: 'left', width:'100px' } },
            { field_type: "text", is_date: false, is_hidden: false, is_detail_link: false, is_sql_col: false, label: "Status", name: "status", style: { textAlign: 'left', width:'100px' } },
            { field_type: "text", is_date: false, is_hidden: false, is_detail_link: false, is_sql_col: false, label: "Phone", name: "phone", style: { textAlign: 'left', width:'100px' } },
            { field_type: "text", is_date: false, is_hidden: false, is_detail_link: false, is_sql_col: false, label: "Sales Rep", name: "sales_rep", style: { textAlign: 'left', width:'100px' } },
            { field_type: "text", is_date: false, is_hidden: false, is_detail_link: false, is_sql_col: false, label: "Product Last Purchased On", name: "invoice_date", style: { textAlign: 'left',width:'100px' } },
        ]
        return columnHeaderList;
    }

    const reloadReport = () => {
        let params = setReportParamsfun();
        let tempRowSort = getObjectParam(rowSort);
        let tempDueBy = getStringParam(dueBy);
        let tempRecords = isMoreClicked ? getArrayParam(records) : [];

        let tempProductId = getStringParam(productId);
        let tempProduct = getStringParam(product);
        let fields = getFieldList();
        let url = endPoints.ORTHOTIC_REPORT.ORTHOTIC_REPORT_GET;
        let tempIsShowMore = isShowMore;
        try {
            params.due_by = tempDueBy;
            setIsMounted(false);
            tempRowSort.isDescending = false;
            let promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {

                if (isValidParam(response)) {
                    let subset = getArrayParam(response.orthoticReport);
                    if (tempRecords.length === 0) {
                        tempRecords = getArrayParam(subset);
                    } else {
                        tempRecords = tempRecords.concat(subset);
                    }

                    if (tempRecords.length < fetchSize) {
                        tempIsShowMore = false;
                    } else if (getArrayParam(subset).length < fetchSize) {
                        tempIsShowMore = false;
                    } else {
                        tempIsShowMore = true;
                    }

                    sortArrayObjectByPropertyDesc(tempRecords, "id");
                    setIsMounted(true);
                    setFields(fields);
                    setRecords(tempRecords);
                    setRowSort(tempRowSort);
                    setProductId(tempProductId);
                    setProduct(tempProduct);
                    setIsShowMore(tempIsShowMore);
                    setIsMoreClicked(false);
                }
            });
        } catch (error) {
            console.log("Error in 'orthoticReport.js -> reloadReport()':" + error)
        }
    }

    const getNoRecordContent = () => {
        return (<table className="layout_maintable" style={{ width: '100%' }}><tr><td><span style={{ color: 'red' }}>{getLocalizedStrings().label.INXPRESS_REPORT.NO_RECORDS_FOUND}</span></td></tr></table>);
    }

    const excelGenerateBeforeHeaders = () => {
        let optionsMultilingual = getMultilingualOptionsMap();
        let tempreportParams = getObjectParam(reportParams);
        let cells = '';
        let dueBy = '';
        let reportName = getStringParam(getLocalizedStrings().label.ORTHOTIC_REPORT.ORTHOTIC_REPORT_LABEL);
        let columns = fields.filter(f => {
            if (!f.is_hidden) { return f; }
        });
        let columnsLength = getArrayParam(columns).length;

        cells = cells + '<tr><td  colspan="' + (columnsLength) + '"> ' + reportName + ' </td></tr>';
        if (reportName.length > 0) {
            dueBy = getStringParam(optionsMultilingual[optionvalue[tempreportParams.due_by]]);
            cells = cells + '<tr><td> Date :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + dueBy + ' </td></tr>';
        }
        return cells;
    }

    const excelGenerateHeaders = () => {
        let cells = '';
        let reportName = getStringParam(getLocalizedStrings().label.ORTHOTIC_REPORT.ORTHOTIC__REPORT_LABEL);
        let columns = fields.filter(f => {
            if (!f.is_hidden) { return f; }
        });
        columns.map(f => {
            cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    const excelGenerateRows = () => {
        let rows = getArrayParam(records);
        let columns = getArrayParam(fields);
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            rows.map((rowObject, rowIndex) => {
                let innerCells = '';
                columns.map((columnObject, colIndex) => {
                    if (!columnObject.is_hidden) {
                        innerCells = innerCells + '<td >' + rowObject[columnObject.name] + ' </td>';
                    }
                })
                if (rowIndex % 2 === 0) {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                } else {
                    cells = cells + ('<tr  bgcolor="Lavender">' + innerCells + ' </tr>');
                }

            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
        }
        return rowData;
    }

    const downloadExcel = () => {
        let rows = getArrayParam(records);
        if (rows.length > 0) {
            let beforeHeaderComponent = excelGenerateBeforeHeaders();
            let headerComponents = excelGenerateHeaders();
            let rowComponents = excelGenerateRows();
            let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '</tbody></table>';
            let htmlData = htmlTable;
            if (isValidParam(htmlData) && htmlData !== '') {
                htmlData = htmlData.replace("%22", "\"");
                var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
                var downloadUrl = URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = downloadUrl;
                a.download = 'OrthoticReportResultSet_' + new Date().getTime() + '.xls';
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        } else {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.NO_RECORD_EXPORT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
    }

    const customEvent = (eventType, column, row, event) => {
        try {
            let columnValue = getStringParam(row[column.name + '_value']).trim();
            columnValue = parseFloat(columnValue);
            if (isValidParam(columnValue) && columnValue !== "" && columnValue > 0.0) {

            }
        } catch (error) {
            console.error("Error in 'orthoticReport.js -> customEvent()':" + error);
        }
    }

    const sortArrayObjectByPropertyDesc = (array, property) => {
        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];

                    val1 = val1 instanceof String ? val1.toLowerCase() : val1;
                    val2 = val2 instanceof String ? val2.toLowerCase() : val2;

                    if (val1 > val2) {
                        return -1;
                    } else if (val1 < val2) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
        }
    }

    const onSort = (fieldName, isDescending) => {
        let tempRowSort =rowSort;
        let tempRecords = getArrayParam(records);
        let sortFieldName = getStringParam(rowSort.sortFieldName);
        try {
            if (!getBooleanParam(tempRowSort.isDescending)) {
                sortArrayObjectByPropertyDesc(tempRecords, sortFieldName);
                tempRowSort.isDescending = true;
            }
            else {
                sortArrayObjectByProperty(tempRecords, sortFieldName);
                tempRowSort.isDescending = false;
            }
        }
        catch (error) {
            console.error("Error in 'orthoticReport.js -> onSort()':" + error);
        }
        setRowSort(tempRowSort);
    }

    const handleMore = () => {

        let tempStartIndex = startIndex + fetchSize;
        setStartIndex(tempStartIndex)
        setIsMoreClicked(true);
        reloadReport();
    }


        let headerComponent = null;
        let reportHeaderName = null;
        let style = { margin: 10, width: '98%', paddingTop: 0, paddingLeft: 11 };
        style = { ...style, ...getObjectParam(style) };
        let tempisMounted = getBooleanParam(isMounted);
        if (tempisMounted) {
            headerComponent = getReportFilter();
            reportHeaderName = getReportHeaderName();
        }
        let noRecordsTitle = getNoRecordContent()
        let tempRecords = getArrayParam(records);
        return (<div style={style}>
            <div> {headerComponent} {reportHeaderName}</div>
            {tempisMounted ?
                <div id="divcontents">
                    {tempRecords.length > 0 ?
                        <div><SFDataTable columns={fields} rowSort={rowSort} rows={tempRecords} customEvent={(event)=>customEvent(event)} />
                            {isShowMore && <div style={{ paddingTop: '15px', marginLeft: '-10px' }}><label style={{ background: '#f75723', width: 74, fontStyle: 'normal', marginLeft: 11, cursor: 'pointer', color: '#ffffff', fontWeight: 500, textAlign: 'center' }} onClick={handleMore}>More</label></div>}
                        </div>
                        :
                        noRecordsTitle
                    }
                </div>
                :
                <div style={{ ...styles.loaderContainer, marginTop: 200 }}> 
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                 </div>
            }
        </div>);
}

export default  OrthoticReport;


