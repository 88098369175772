import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import ShowCircularProgress from '../components/circularProgress';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { refreshDetailViewData, refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';

const FieldValueDialog = () => {

    const [isLoadded, setIsLoadded] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [data, setData] = useState("");
    const [fieldValue, setFieldValue] = useState([]);
    const [fieldData, setFieldData] = useState([]);
    const [isDisable, setIsDisable] = useState(false);

    const appContainer = useSelector((state) => state.appContainer);

    const dispatch = useDispatch();


    useEffect(() => {
        getData();
    }, []);

    const processFormData = () => {
        let component =
            <div key={'sfas-cointainer-from'} style={{ marginLeft: '22px', marginRight: '22px' }}>
                <div style={{ width: '100%', display: 'inline-table' }}>
                    <div style={{ float: 'right', marginTop: '10px', paddingBottom: '15px', display: 'flex', marginRight: '-5px' }}>
                        {getActionButtons()}
                    </div>
                </div>
                <div className='sfas-cointainer-from-class' id='sfas-cointainer-from-id' >
                    { isDisable ? <h4 style={{ fontWeight: 'bold' }}>The following values are <span style={{ fontWeight: 'bold', color:'red'}}>missing</span> and are required to push this account to XMS</h4> : <h4 style={{ fontWeight: 'bold' }}>The following account details will be pushed to XMS</h4> }
                </div>

                {
                    fieldData.map((f) => {
                        if (f.name == 'custom_field15') {
                            return <div style={{ paddingBottom: '5px' }}>
                                <span style={{ fontSize: '16px' }}>{isValidParam(f.label) ? f.label : f.name}</span>
                                <span style={{ paddingLeft: '1px', paddingRight: '4px', fontSize: '16px', fontWeight: 'bold' }}>:</span>
                                <span style={{ fontWeight: 'bold', fontSize: '16px', color: 'black' }}>{f.value}</span>
                            </div>
                        } else {
                            return <div style={{ paddingBottom: '5px' }}>
                                <span style={{ fontSize: '16px' }}>{isValidParam(f.label) ? f.label : f.name}</span>
                                <span style={{ paddingLeft: '1px', paddingRight: '4px', fontSize: '16px', fontWeight: 'bold' }}>:</span>
                                <span style={{ fontWeight: 'bold', fontSize: '16px', color: isValidParam(f.value) && f.value !== "" ? 'black' : 'red' }}>{isValidParam(f.value) && f.value !== "" ? f.value : 'Missing'}</span>
                            </div>
                        }
                    })
                }

                <div style={{ float: 'right', marginTop: '10px' }}>
                    { getActionButtons()}
                </div>
            </div>
        return component;
    }

    const getActionButtons = () => {
        let buttons = [];
        { isDisable === false && buttons.push(
            <Button
                primary={true}
                onClick={() => pushData()}
                style={{...styles.primaryButton, opacity: isDisable ? 0.6 : 1 }}
            > {getLocalizedStrings().label.COMMON.PUSH}</Button>
          );
        }
        buttons.push(
            <Button
                primary={true}
                onClick={() => handleClose()}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
        );
        return buttons;
    }
    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }
    const getData = () => {
        let fieldData = appContainer.dialogProps.data?.fieldValue;
        let isMissing = false;
        let assortedFieldData = fieldData;
        let listIdField = fieldData.filter(f => f.name === 'custom_field15' && (f.value === '' || f.value === null));
        if(listIdField!= null && listIdField.length > 0) {
            assortedFieldData = fieldData.filter(f => f.name !== 'custom_field15'); // Removed List ID from Validations.
        }
        let tempFieldDataWithBlank = assortedFieldData.filter(f => (f.value === '' || f.value === null));
        if (tempFieldDataWithBlank !== null && tempFieldDataWithBlank.length > 0) {
            isMissing = true;
        }
        let data = appContainer.dialogProps.data.data;
        setIsLoadded(true);
        setFieldData(fieldData);
        setData(data);
        setIsDisable(isMissing);
    }
    const pushData = () => {
        let recordId = data.recordId;
        let countryCode = data.countryCode;
        let fields = data.fields;
        let params = {};
        params.recordId = recordId;
        params.countryCode = countryCode;
        params.fields = fields;
        setIsLoadded(false);
        let promise = Promise.resolve(HTTPClient.post(endPoints.ACCOUNTS.TO_XMS, params));
        promise.then((response) => {
            if (isValidParam(response) && response.status === 0) {
                if (response.data.errorCode === "SUCCESS") {
                    appContainer.dialogProps.eventName("This account is successfully pushed to XMS", true);
                    dispatch(getAppDialog(false, null, null, null, null, null));
                    dispatch(refreshDetailViewData(true));
                    dispatch(refreshDetailViewListViewData(true));
                }
            } else if (response.status === -1) {
                if (response.data.status === 401) {
                    dispatch(getAppDialog(false, null, null, null, null, null));
                    appContainer.dialogProps.eventName(response.data.message, false);
                } else if (isValidParam(response.error.message) && response.error.message !== null
                    && response.error.message !== '') {
                    dispatch(getAppDialog(false, null, null, null, null, null));
                    appContainer.dialogProps.eventName(response.error.message, false);
                }
            }
        })
    }

    let contentHeight = window.innerHeight - 300;
    let top = (contentHeight - 10) / 2;
    if (isLoadded) {
        return (
            <div>
                {
                    errorMsg === '' &&
                    processFormData()
                }
            </div>
        )
    }
    else {
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        )
    }
}
export default FieldValueDialog;