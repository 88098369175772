import React, { useEffect, useState } from 'react';
import { headerText, section1Init, section2Init } from './intakeInfoFormData';
import { Button } from '@mui/material';
import { getLocalizedStrings } from '../../../../../services/constants/MultiLingual';
import { styles } from '../../../../../services/constants/styles';
import IntakeInfoCustomForm from './IntakeInfoCustomForm';
import * as HTTPClient from '../../../../../services/helper/httpClient';
import { endPoints } from '../../../../../services/constants/endPoints';
import { useDispatch, useSelector } from 'react-redux';
import { getIntakeformData, getLinkIntakeformData, saveIntakeFormId, setIsLoading, setSelectedIntakeForm,setIsErrorIntakeForm,DeleteIntakeFormData, getIntialIntakeformData } from '../../../../../services/actions/IntakeFormAction';
import { constants } from '../../../../../services/constants/constants';
import { getAppCustomDrawer } from '../../../../../services/actions/appContainerActions';
import { LinkToType } from '../../../../../services/constants/link';
import { getArrayParam, getIntParam, getObjectParam, isValidParam } from '../../../../../services/helper/parameterVerifier';
import SignatureDialog from './signatureDialog';
import OtherInfoForm from '../OtherInfoForm';
import { showCustomSnackBar } from '../../../../../services/actions/snackBarAction';
import * as sfDialogs from '../../../components/sfDialogs';
import ShowCircularProgress from '../../circularProgress';
import { actions } from '../../../../../services/constants/actions';
import NoRecords from '../../noRecord';

const IntakeInfoForm = ({id,object}) => {
    const { data: {intake, other}, intakeFormId, otherFormId, selectedForm, fullName, signatureData, isLoading, customAutofillData, countryList, appThemeColor } = useSelector((state) => state.intakeForm);
    const { record } = useSelector((state) => state.detailView);
    const UserEmail = intake?.customField13
    const [section1InitData, setSection1InitData] = useState(section1Init)
    const app = useSelector((state) => state.app);
    const [intakeFormData, setIntakeFormData] = useState({});
    const [otherFormData, setOtherFormData] = useState({});
    const [signature, setSignature] = useState(false);
    const [signData, setSignData] = useState({});
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(window.location.hash.split('intake-form')[1]);
    let encodedParams = urlParams.get('p'); // if encodedParams = null -> form is in CRM else form is open from link

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const form = ['Intake Info', 'Additional Info'];

    const setStateMenuItemList = (value, updatedData) => {
		try {
			let params = {};
			params.country = value;
			if (value?.length > 0) {
                let promise;
                if(encodedParams !== null) {
                    promise = Promise.resolve(HTTPClient.getWithoutToken(endPoints.INTAKE_FORM.STATES, {
                        encodedParams: encodedParams,
                        country: value
                    })) 
                } else {
                    promise = Promise.resolve(HTTPClient.get(endPoints.INTAKE_FORM.STATES, params));
                }
                 
				promise.then((response) => {
					if (isValidParam(response)) {
                        if(response.length > 0) {
                            if(updatedData && Object.keys(updatedData).length > 0) {
                                let updatedSection1Init = updatedData.map((item) => {
                                    if (item?.dropDownName === 'customFieldList5') {
                                        return { ...item, options: getArrayParam(response) };
                                    }
                                    return item; 
                                })
                                setSection1InitData(updatedSection1Init)
                            } else {
                                let updatedSection1Init = section1InitData.map((item) => {
                                    if (item?.dropDownName === 'customFieldList5') {
                                        return { ...item, options: getArrayParam(response) };
                                    }
                                    return item; 
                                })
                                setSection1InitData(updatedSection1Init)
                            }
                        }
                    }
                });
			} 
		} catch (error) {
			console.error("Error in 'sfForm.js -> setStateMenuItemList()':" + error);
		}
	};

    useEffect(() => {
        let initialIntakeFormData = intake || {}
        let initialOtherFormData = other || {}
        // check if encodedParams are there and if yes then set the initialIntakeFormData
        if (encodedParams !== null) {
            if (Object.keys(initialIntakeFormData).length > 0) {
                initialIntakeFormData = {...initialIntakeFormData, key: 'intake'}
                setIntakeFormData(initialIntakeFormData);
            }
            // check if CRM intake form has already intialData- EDIT state - setIntakeFormData from reux  
        } else if (Object.keys(initialIntakeFormData).length > 0) {
            initialIntakeFormData = {...initialIntakeFormData, key: 'intake'}
            setIntakeFormData(initialIntakeFormData);
        } else { // set CRM initial intake form data from NEW state

            initialIntakeFormData.customField1 = customAutofillData.custom_field1 ? customAutofillData.custom_field1 : '' // last_name
            initialIntakeFormData.customField2 = customAutofillData.custom_field2 ? customAutofillData.custom_field2 : '' // first_name
            initialIntakeFormData.customFieldList5 = record?.state ? record?.state : '' // state
            initialIntakeFormData.customFieldList6 = record?.country ? record?.country : '' // country
            initialIntakeFormData.customField10 = customAutofillData.custom_field10 ? customAutofillData?.custom_field10 : '' // Address2
            initialIntakeFormData.customField11 = customAutofillData.custom_field11 ? customAutofillData.custom_field11 : '' // city
            initialIntakeFormData.customField12  = customAutofillData.custom_field12 ? customAutofillData.custom_field12 : '' // zip code
            initialIntakeFormData.customField13 = customAutofillData.custom_field13 ? customAutofillData.custom_field13 : '' // email
            initialIntakeFormData.customField14 = customAutofillData.custom_field14 ? customAutofillData?.custom_field14 : '' // phone no
            initialIntakeFormData.key = 'intake';
            setIntakeFormData(initialIntakeFormData);
        }
        if(Object.keys(initialOtherFormData).length > 0 && encodedParams === null) {
            initialOtherFormData = {...initialOtherFormData, key: 'other'}
            setOtherFormData(initialOtherFormData);
        } else if(Object.keys(initialOtherFormData).length === 0 && encodedParams === null) {
            initialOtherFormData.key = 'other';
            setOtherFormData(initialOtherFormData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intake, other, customAutofillData]);
  

    useEffect(()=>{
        // update country list options in section1 data
        if (encodedParams !== null) {
            let updatedSection1Init = section1InitData.map((item) => {
                if (item?.dropDownName === 'customFieldList6') {
                    return { ...item, options: getArrayParam(countryList) };
                }
                return item;
            });
            setSection1InitData(updatedSection1Init)
            if (intakeFormData.customFieldList6) {
                setStateMenuItemList(intakeFormData.customFieldList6, updatedSection1Init)
            }
        } else {
            let filterStateItem = section1InitData.filter((item)=>item.dropDownName === 'customFieldList5')
            let updatedSection1Init = section1InitData.map((item) => {
                if (item?.dropDownName === 'customFieldList6') {
                    return { ...item, options: getArrayParam(app.countryISO2) };
                }
                return item;
            });
            setSection1InitData(updatedSection1Init)
            if (intakeFormData.customFieldList6 && filterStateItem[0]?.options.length === 0) {
                setStateMenuItemList(intakeFormData.customFieldList6, updatedSection1Init)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[intakeFormData.customFieldList6])

    useEffect(() => {
        dispatch(setIsLoading(true));
        dispatch(setIsErrorIntakeForm());
        if (isValidParam(encodedParams)) { // check encodedParams for open recrod form email link 
            dispatch(getLinkIntakeformData(encodedParams));
        } else if (isValidParam(id) && id > 0) { // edit intakeForm and otherForm record from listview crm
            dispatch(getIntakeformData(id));
        } else if (intakeFormId > 0 && otherFormId > 0) { // get initial saved intakeForm and otherForm record in crm 
            dispatch(getIntakeformData(intakeFormId, otherFormId));
        } else { // get new intakeForm and otherForm data open record in crm
            dispatch(getIntakeformData(null,null,record.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intakeFormId, otherFormId]);
    
    useEffect(()=>{
        dispatch(setSelectedIntakeForm(form[0]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const isValidEmail = (email) =>{
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(email) ) {
            return true;
        } else {
           return false;
        }
    }

    const getValidFormStatus = (formDataParams) => {
        if(!formDataParams.customField1){
            dispatch({
                type: actions.SET_FORM_ERROR,
                payload: { field: 'customField1', error: getLocalizedStrings().label.INTAKE_FORM.LAST_NAME_REQUIERD},
                });
                return; // Do not proceed with form submission if there are errors
        }else if(!formDataParams.customField2){
            dispatch({
                type: actions.SET_FORM_ERROR,
                payload: { field: 'customField2', error: getLocalizedStrings().label.INTAKE_FORM.FIRST_NAME_REQUIERD },
                });
                return; // Do not proceed with form submission if there are errors
        }else if(!formDataParams.customField43){
            dispatch({
                type: actions.SET_FORM_ERROR,
                payload: { field: 'customField43', error:getLocalizedStrings().label.INTAKE_FORM.DOB_REQUIRED },
                });
                return; // Do not proceed with form submission if there are errors
        }else if(!formDataParams.customFieldList2){
            dispatch({
                type: actions.SET_FORM_ERROR,
                payload: { field: 'customFieldList2', error:getLocalizedStrings().label.INTAKE_FORM.GENDER_REQUIRED },
                });
                return; // Do not proceed with form submission if there are error
        }else if(!formDataParams.customField6){
            dispatch({
                type: actions.SET_FORM_ERROR,
                payload: { field: 'customField6', error: getLocalizedStrings().label.INTAKE_FORM.PRONOUNS_REQUIERD },
                });
                return; // Do not proceed with form submission if there are errors
        }else if(!formDataParams.customField7){
            dispatch({
                type: actions.SET_FORM_ERROR,
                payload: { field: 'customField7', error: getLocalizedStrings().label.INTAKE_FORM.ALLERGIES_REQUIERD},
                });
                return; // Do not proceed with form submission if there are errors
        }else if(!formDataParams.customFieldList3){
            dispatch({
                type: actions.SET_FORM_ERROR,
                payload: { field: 'customFieldList3', error: getLocalizedStrings().label.INTAKE_FORM.SMOKING_REQUIRED },
                });
                return; // Do not proceed with form submission if there are error
        }else if(!formDataParams.customFieldList4){
            dispatch({
                type: actions.SET_FORM_ERROR,
                payload: { field: 'customFieldList4', error: getLocalizedStrings().label.INTAKE_FORM.MARITAL_STATUS_REQUIRED},
                });
                return; // Do not proceed with form submission if there are error
        }else if(!formDataParams.customField10){
            dispatch({
                type: actions.SET_FORM_ERROR,
                payload: { field: 'customField10', error:getLocalizedStrings().label.INTAKE_FORM.ADDRESS_REQUIRED },
                });
                return; // Do not proceed with form submission if there are errors
        }else if(!formDataParams.customField11){
            dispatch({
                type: actions.SET_FORM_ERROR,
                payload: { field: 'customField11', error:getLocalizedStrings().label.INTAKE_FORM.CITY_REQUIRED},
                });
                return; // Do not proceed with form submission if there are errors
        }else if(!formDataParams.customFieldList5){
            dispatch({
                type: actions.SET_FORM_ERROR,
                payload: { field: 'customFieldList5', error: getLocalizedStrings().label.INTAKE_FORM.STATE_REQUIRED},
                });
                return; // Do not proceed with form submission if there are error
        }else if(!formDataParams.customField12){
            dispatch({
                type: actions.SET_FORM_ERROR,
                payload: { field: 'customField12', error:getLocalizedStrings().label.INTAKE_FORM.ZIPCODE_REQUIRED },
                });
                return; // Do not proceed with form submission if there are error
        }else if(!formDataParams.customField13){
            dispatch({
                type: actions.SET_FORM_ERROR,
                payload: { field: 'customField13', error:  getLocalizedStrings().label.INTAKE_FORM.EMAIL_REQUIRED},
                });
                return; // Do not proceed with form submission if there are error
        }else if(!formDataParams.customField14){
            dispatch({
                type: actions.SET_FORM_ERROR,
                payload: { field: 'customField14', error: getLocalizedStrings().label.INTAKE_FORM.PHONE_REQUIRED },
                });
                return; // Do not proceed with form submission if there are error
        }else {
            return true;
        }
    }

    const SaveIntakeForm = () => {
        let formDataParams = intakeFormData;
        let isValidForm = getValidFormStatus(formDataParams);
        if (selectedForm === form[1] && !isValidForm) { 
            sfDialogs.alert('Error', 'There are required fields in the Intake Info tab and those need to be entered before saving.', () => {
                dispatch(setSelectedIntakeForm(form[0]));
            });
        }

        if(isValidForm) {
            if ((formDataParams.customField13 && !isValidEmail(formDataParams.customField13)) || (formDataParams.customField33 && !isValidEmail(formDataParams.customField33)) || (formDataParams.customField39 && !isValidEmail(formDataParams.customField39)) || (formDataParams.customField42 && !isValidEmail(formDataParams.customField42))) {
                let errorMessage = getLocalizedStrings().message.COMMON.EMAIL_VALIDATION;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else if(formDataParams.customField5 < 0 || formDataParams.customField9 < 0){
                let errorMessage = getLocalizedStrings().message.COMMON.AGE_VALIDATION;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
                dispatch(setIsErrorIntakeForm(false));
                if (encodedParams !== null) {
                    if(Object.keys(signData).length > 0) {
                        formDataParams =  {...formDataParams, signature: JSON.stringify(signData)};
                    }
                    let encodedSaveParams = { form_data: [formDataParams], encodedParams: encodedParams };
                    const promise = Promise.resolve(HTTPClient.postWithoutToken(endPoints.INTAKE_FORM.SAVE, encodedSaveParams));
                    promise.then((response) =>{
                        dispatch(
                            showCustomSnackBar(
                                getLocalizedStrings().message.INTAKE_FORM.INTAKE_FORM_SUCCESSFULLY_SAVE,
                                styles.snackbarBodyStyleSuccess,
                                styles.snackBarStyleTop
                            )
                        );
                        sfDialogs.alert(getLocalizedStrings().message.AUDIT_QUESTIONS.THANK_YOU, getLocalizedStrings().message.INTAKE_FORM.INTAKE_FORM_SUCCESSFULLY_SAVE, () => {
                                //    window.close()
                                //    window.location.reload()
                        });
                    } ).catch((err) => console.log('err: SaveIntakeForm-->', err));
                } else {
                    if(Object.keys(signData).length > 0) {
                        formDataParams =  {...formDataParams, signature: JSON.stringify(signData)};
                    }
                    let params = { form_data: [formDataParams, otherFormData], parent_record_id: record?.id };
                    const promise = Promise.resolve(HTTPClient.post(endPoints.INTAKE_FORM.SAVE, params));
                    promise.then((response) => {
                        const {intake, other} = response.data
                        dispatch(saveIntakeFormId(intake, other))
                        dispatch(
                            showCustomSnackBar(
                                getLocalizedStrings().message.INTAKE_FORM.INTAKE_FORM_SUCCESSFULLY_SAVE,
                                styles.snackbarBodyStyleSuccess,
                                styles.snackBarStyleTop
                            )
                        );
                    }).catch((err) => console.log('err: SaveIntakeForm-->', err));
                }
            }
        }
    };

    const handleChangeForm = (Selectedform) => {
        dispatch(setSelectedIntakeForm(Selectedform));
    };

    const opensignatureDalog = () => {
        setSignature(true);
    };

    const getSignatureData = (data) => {
        setSignData(data);
    };

    const SendIntakeForm = () => { // if saved then only show send 
        let data = {
            email: UserEmail ? UserEmail : getObjectParam(record)?.email, 
            id: getIntParam(record?.id),
            callFrom: constants.INTAKE_FORM,
            record_id: getIntParam(record?.id),
            table_id: 376, //customTable 1 table id
            form_id: intake?.id ? intake.id : 0, 
            object: constants.ACCOUNTS_OBJECT,
        };
        let style = {};
        style.width = '90%';
        let labelName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
        dispatch(getAppCustomDrawer(true, labelName, LinkToType.TYPE_EMAIL, style, data, null, true));
    };

    if (isLoading) {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 3
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                {intake?.status !== "Deleted" ? <div style={{ marginLeft: '40px' }}>
                    {/* tabs - intake info - addition info */}
                    <div style={{ display: 'flex', marginTop: '15px'}}>
                        <div style={{ display: 'flex', borderBottom: 'solid 1px #D3D3D3', width: '100%' }}>
                            <div
                                onClick={() => handleChangeForm(form[0])}
                                style={{ backgroundColor: selectedForm === form[0] ? app.me.background ? app.me.background : appThemeColor : '#D3D3D3', borderColor: selectedForm === form[0] ? app.me.background : '#D3D3D3', borderTopLeftRadius: '10px', padding: '7px' , cursor: 'pointer' }}>
                                <span style={{ fontWeight: '500', fontSize: '15px', color: '#FFFFFF' }}>{form[0]}</span>
                            </div>
                            {encodedParams === null && (
                                <div
                                    onClick={() => handleChangeForm(form[1])}
                                    style={{ backgroundColor: selectedForm === form[1] ? app.me.background ? app.me.background : appThemeColor : '#D3D3D3', borderColor: selectedForm === form[1] ? app.me.background : '#D3D3D3', borderTopLeftRadius: '10px', padding: '7px', marginLeft: '10px', cursor: 'pointer'  }}>
                                    <span style={{ fontWeight: '500',fontSize: '15px', color: '#FFFFFF' }}>{form[1]}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div style={{marginRight: '30%'}}>
            
                        {/* save and send action button */}
                        <div style={{ marginTop: '20px', float: 'right', alignItems: 'right', justifyContent: 'right', display: 'flex' }}>
                            <Button
                                onClick={() => SaveIntakeForm()}
                                style={{
                                    ...styles.primaryButton,
                                    marginRight: intake?.id && selectedForm === form[0] ? '10px' : '0px',
                                    minWidth: '56px',
                                    width: '96%',
                                    height: '32px',
                                    backgroundColor: app.me.background ? app.me.background : appThemeColor,
                                }}
                                title={getLocalizedStrings().label.COMMON.SAVE}>
                                {getLocalizedStrings().label.COMMON.SAVE}
                            </Button>
                            {selectedForm === form[0] && encodedParams === null && intake?.id ? (
                                <Button
                                    onClick={() => SendIntakeForm()}
                                    style={{
                                        ...styles.primaryButton,
                                        minWidth: '56px',
                                        marginRight: '0px',
                                        width: '96%',
                                        height: '32px',
                                        backgroundColor: app.me.background ? app.me.background : appThemeColor,
                                    }}
                                    title={getLocalizedStrings().label.COMMON.SEND}>
                                    {getLocalizedStrings().label.COMMON.SEND}
                                </Button>
                            ) : null}
                        </div>
            
                        {/* intake info form */}
                        {selectedForm === form[0] && (
                            <div style={{ width: '100%', overflowX: 'hidden' }}>
                                <IntakeInfoCustomForm jsonData={section1InitData} formData={intakeFormData} setStateMenuItemList={setStateMenuItemList} setFormData={setIntakeFormData} />
                                <h2>{headerText.SECTION2}</h2>
                                <IntakeInfoCustomForm jsonData={section2Init} formData={intakeFormData} setFormData={setIntakeFormData} setStateMenuItemList={setStateMenuItemList} />

                                {/* signature dialog */}
                                    <div style={{ display: 'flex', marginBottom: '20px' }}>
                                        {signature && <SignatureDialog setSignature={setSignature} getSignatureData={getSignatureData} fullName={fullName} />}
                                        {signData.userSign || signatureData?.userSign ? (
                                            <div style={{ background: 'white', padding: '20px' }}>
                                                <span
                                                    style={{
                                                        fontFamily: signData.fontFamily ? signData.fontFamily : signatureData?.fontFamily,
                                                        fontSize: 'x-large',
                                                    }}>
                                                    {signData.userSign ? signData.userSign : signatureData?.userSign}
                                                </span>
                                            </div>
                                        ) : <span
                                                style={{ cursor: 'pointer', color: app.me.background, fontWeight: 'bold' }}
                                                onClick={() => opensignatureDalog()}>
                                                <u>Sign here</u>
                                            </span>
                                        }
                                    </div>
                            </div>
                        )}
            
                        {/* other Form - addition */}
                        {selectedForm === form[1] && (
                            <div style={{ width: '100%', overflowX: 'hidden' }}>
                                <OtherInfoForm formData={otherFormData} setFormData={setOtherFormData} />
                            </div>
                        )}

                        {/*save and send action button  */}
                        <div style={{ marginTop: '20px', float: 'right', alignItems: 'right', justifyContent: 'right', display: 'flex',paddingBottom:'10px' }}>
                            <Button
                                onClick={() => SaveIntakeForm()}
                                style={{
                                    ...styles.primaryButton,
                                    marginRight: intake?.id && selectedForm === form[0] ? '10px' : '0px',
                                    minWidth: '56px',
                                    width: '96%',
                                    height: '32px',
                                }}
                                title={getLocalizedStrings().label.COMMON.SAVE}>
                                {getLocalizedStrings().label.COMMON.SAVE}
                            </Button>
                            {selectedForm === form[0] && encodedParams === null && intake?.id ? (
                                <Button
                                    onClick={() => SendIntakeForm()}
                                    style={{
                                        ...styles.primaryButton,
                                        marginRight: '0px',
                                        minWidth: '56px',
                                        width: '96%',
                                        height: '32px',
                                    }}
                                    title={getLocalizedStrings().label.COMMON.SEND}>
                                    {getLocalizedStrings().label.COMMON.SEND}
                                </Button>
                            ) : null}
                        </div>
                    </div>
                </div>
                : 
                <NoRecords  object={object}/>}
            </div>

        );
    }

};
export default IntakeInfoForm;
