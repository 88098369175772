import React from 'react';
import ListView from '../containers/ListView';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import {Button,Chip,TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { styles } from '../../../services/constants/styles';
import { endPoints } from '../../../services/constants/endPoints';
import { getStringParam, getIntParam, isValidParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import * as HTTPClient from '../../../services/helper/httpClient';
import { updateTabInfo, getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { setHeader } from '../../../services/actions/headerActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { populateFiltersFromActiveTab, refreshListView } from '../../../services/actions/listViewAcions';



 const FindDuplicate = ({object,duplicateField,duplicate_object_id,query_id,duplicate_object})=> {
   
    const dispatch = useDispatch();
    const [objectId, setObjectId] = useState(10);
    const [tempDuplicateField, setDuplicateField] = useState('');
    const [isMounted, setIsMounted] = useState(false);
    const [queryId, setQueryId] = useState(null);
    const [listView, setListView] = useState(null);
    const [queryIdList, setQueryIdList] = useState(null);
    const [tableNameOpts, setTableNameOpts] = useState([
        { label: 'Accounts', value: constants.ACCOUNTS_OBJECT },
        { label: 'Contacts', value: constants.CONTACTS_OBJECT }
    ]);
    const [objectList, setObjectList] = useState([]);
    const [duplicateFieldList, setDuplicateFieldList] = useState([]);

    const app = useSelector((state) => state.app);
    const duplicateListView = useSelector((state) => state.duplicateListView);
    const sfForm = useSelector((state) => state.sfForm.data);

    let temoObjectList = [
        {
            id: 10,
            label: 'accounts'
        },
        {
            id: 9,
            label: 'contacts'
        }
    ]
    

    useEffect(() => {
       getQueryIds();
       getObjectList();

       fetchDuplicatesFromProps();
        dispatch( setHeader(object, getLocalizedStrings().label.FIND_DUPLICATES.FIND_DUPLICATES, null, false, true));
    },[]);

    

    const getObjectList = () => {
        let tableNameArr = tableNameOpts.map((r, index) => {
            let objectList = app.objectList;
            let label = r.label;
            if (isValidParam(objectList) && getArrayParam(objectList).length > 0) {
                objectList = objectList.filter(f => f.name === r.value);
                if (objectList.length > 0) {
                    label = objectList[0].label;
                }
            }
            return { id: OBJECT_TABLEID_MAP[r.value], label: label, name: r.value }
        });
        getRuleData(constants.ACCOUNTS_OBJECT);
        setObjectList(tableNameArr);
    }

    const getRuleData = (objectName) => {
        let duplicateRules = [];
        let defaultValue = '';
    let isExist = false;
        try {
            if (objectName !== '') {
                let tableId = OBJECT_TABLEID_MAP[objectName];
                if (tableId > 0) {
                    let params = { table_id: tableId };
                    let url = endPoints.DUPLICATE_RULE.GET;
                    let promise = Promise.resolve(HTTPClient.get(url, params));
                    promise.then((response) => {
                        if (isValidParam(response) && Array.isArray(sfForm[objectName].fields)) {
                            let objectFields = sfForm[objectName].fields;
                            response.map((items, Index) => {
                                if (items.is_active === 1) {
                                    let rule = {};
                                    let label = '';
                                    let valuesFld = '';
                                    if (items.field_names.length > 0) {
                                        for (var i = 0; i < items.field_names.length; i++) {
                                            let dataFld = objectFields.find(e => e.name === items.field_names[i].field_name);
                                            if (dataFld !== undefined) {
                                                isExist = true;
                                                if (label === '') {
                                                    label = items.rule_name + " (" + dataFld.label;
                                                } else {
                                                    label += ", " + dataFld.label;
                                                }


                                                if (valuesFld === "") {
                                                    valuesFld = items.field_names[i].field_name;
                                                } else {
                                                    valuesFld += "," + items.field_names[i].field_name;
                                                }
                                            }
                                        }
                                    }
                                    if(isExist){
                                    label += ")";
                                    rule.label = label;
                                    rule.id = valuesFld;
                                    duplicateRules.push(rule);
                                    }
                                    if (items.is_default === 1) {
                                        defaultValue = valuesFld;
                                    }
                                }
                            })
                            setDuplicateFieldList(duplicateRules);
                            setDuplicateField(defaultValue);
                        }
                    });
                }
            }
        } catch (error) {
            console.error('Error in findDuplicate.js -> getRuleData() : ' + error);
        }
    }

    const handleChange = (fieldName, value) => {
        try {
            
            setListView(null);
            if (fieldName == 'objectId') {
                setObjectId(value.id);
            } else {
                setDuplicateField(value.id)
            }
            if (fieldName === 'objectId') {
                let obj = objectList.filter((obj) => { return obj.id === value.id });
                getRuleData(obj[0].name);
            }
            dispatch(setHeader(object, getLocalizedStrings().label.FIND_DUPLICATES.FIND_DUPLICATES, null, false, true));
        } catch (error) {
            console.error("Error in 'findDuplicate.js --> handleChange()':" + error);
        }
    }

    const getQueryIds = () => {
        let url = null;
        let param = {};
        param.query_name = 'Duplicate Search';
        let queryIdList = null;
        try {
            url = endPoints.FIND_DUPLICATE.GET_QUERY_IDS;
            let promise = Promise.resolve(HTTPClient.get(url, param));
            promise.then((response) => {
                if (isValidParam(response) && response.status === 0) {
                    queryIdList = response.data;
                    setQueryIdList(queryIdList);
                }
            })
        } catch (error) {
            console.error("Error in 'findDuplicate.js --> getQueryIds()':" + error);
        }
    }


    const fetchDuplicatesFromProps = () => {
        let elements = null;
        let params = {};
        try {
            if (isValidParam(duplicate_object_id) && duplicate_object_id !== null
                && isValidParam(duplicateField) && duplicateField !== null
                && isValidParam(query_id) && query_id !== null) {
                let duplicateField = getStringParam(duplicateField);
                let objectId = duplicate_object_id;
                let queryId = query_id;
                let duplicateObject = duplicate_object;

                params.duplicate_field = duplicateField;
                params.table_id = objectId;
                params.query_id = queryId;
                params.object = duplicateObject;
                params.sort_field_name = getStringParam(duplicateListView.data.sort_field_name);
                params.query_name = 'Duplicate Search';
                params.sort_type = getStringParam(duplicateListView.data.sort_type);

                let inputParam = {};
                inputParam.input_param = params;
                inputParam.isFetchListView = true;
                inputParam.is_duplicate_object = true;
                let info = getActiveTabInfo();

                info = { ...info, ...inputParam };
                updateTabInfo(info);

                elements = <ListView object={constants.FIND_DUPLICATE_OBJECT} isDuplicate={true} childObject={duplicateObject} />
                setListView(elements);
                setDuplicateField(duplicateField);
                setObjectId(objectId);
                
            }
        } catch (error) {
            console.error("Error in 'findDuplicate.js --> fetchDuplicatesFromProps()':" + error);
        }
    }

    const getDuplicates = () => {
        let elements = null;
        let params = {};
        let childObject = '';
        try {
            let obj=constants.FIND_DUPLICATE_OBJECT
            let tableId = getIntParam(objectId);
            let _queryIdList = getArrayParam(queryIdList);
            let queryObj = _queryIdList.filter((obj) => { return obj.t_formid === tableId });
            let object = temoObjectList.filter((obj) => { return obj.id === tableId });
            childObject = object[0].label;

            params.object = childObject;
            params.duplicate_field = getStringParam(tempDuplicateField);
            params.table_id = getIntParam(objectId);
            params.query_id = isValidParam(queryObj) && queryObj.length > 0 ? queryObj[0].t_pk : 0;
            params.query_name = 'Duplicate Search';
            params.sort_field_name = constants.DUPLICATE_SORT_FIELD;
            params.sort_type = 'ASC';
            let inputParam = {};
            inputParam.input_param = params;
            inputParam.isFetchListView = true;
            inputParam.is_duplicate_object = true;
            let info = getActiveTabInfo();

            info = { ...info, ...inputParam };
            updateTabInfo(info);

            elements = <ListView object={constants.FIND_DUPLICATE_OBJECT} isDuplicate={true} childObject={childObject} />
            refreshListView(obj);

            setListView(elements);

        } catch (error) {
            console.error("Error in 'findDuplicate.js --> getDuplicates()':" + error);
        }
    }

        return (
            <div style={{ marginLeft: 'auto', marginTop: 0 }} >
                <div className='row' style={{ height: '35px', marginBottom: 20, marginLeft: 10 }}>
                    <div className="col-sm-6" style={{ paddingTop: '15px' , paddingLeft:'30px'}}>
                        <span style={{ fontSize: '20px' }}>{getLocalizedStrings().label.DUPLICATE_RULE.DUPLICATE_RULE_HEAD_LABEL}</span>
                    </div>
                </div>
                <div className='row' style={{ height: '35px', marginLeft: '25px'}}>
                    <div className='col-sm-2' style={{marginRight:'-31px'}}>
                       {objectList.length>0 && <Autocomplete
                            openOnFocus
                            id="disabled-options-sf-findduplicate"
                            options={objectList}
                            value = {objectList.find(v => v.id === objectId)}
                            onChange={(fieldNmae,value)=>handleChange( 'objectId',value)} 
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <TextField {...params} label={getLocalizedStrings().label.FIND_DUPLICATES.SELECT_OBJECT} variant="outlined" margin="dense"  size='small' className={"sf-fields-bg"} />
                            )}
                        />}
                    </div>

                    <div className='col-sm-4' style={{ marginLeft: '2%' }}>
                       
                       {duplicateFieldList.length>0 && <Autocomplete
                            openOnFocus
                            id="disabled-options-sf-findduplicates"
                            options={duplicateFieldList}
                            value = {duplicateFieldList.find(v => v.id === tempDuplicateField)}
                            onChange={(fieldNmae,value)=>handleChange( 'duplicateField',value)} 
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <TextField {...params} label={getLocalizedStrings().label.DUPLICATE_RULE.SELECT_RULES} variant="outlined" margin="dense" size='small' className={"sf-fields-bg"}/>
                            )}
                        />
                            }
                    </div>
                    <div className='col-sm-3' style={{ marginTop: '9px', marginLeft: '-15px '}}>
                        <Button
                            key='Find'
                            style={{ ...styles.primaryButton, verticalAlign: 'top', marginTop: 5 }}
                            onClick={()=>getDuplicates()}
                        >{getLocalizedStrings().label.DUPLICATE_RULE.CHECK_DUPLICATES}</Button>
                    </div>
                </div>
                <div className='row' style={{ marginTop: '3%', height: '100%' }}>
                    <div className='col-sm-12'>
                        {listView}
                    </div>
                </div>
            </div>
        )
    }


export default FindDuplicate;



