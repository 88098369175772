import { styled } from '@mui/material';
import { actions } from '..//constants/actions';
import { isValidParam, getBooleanParam } from '..//helper/parameterVerifier';

const toUpDialogInitialSate = {
    isOpenDialog: false,
    dialogType: null,
    dialogTitle: null,
    dialogActions: null,
    eventName: null,
    data: {},
    callFrom: null,
    style: {}
}

const toUpDialogReducer = (state = toUpDialogInitialSate, action) => {
    switch (action.type) {

        case actions.TOP_UP_DIALOG_OPEN_CLOSE:
            return {
                ...state,
                isOpenDialog: action.payload.isOpenDialog,
                dialogType: action.payload.dialogType,
                dialogTitle: action.payload.dialogTitle,
                eventName: action.payload.eventName,
                dialogActions: action.payload.actions,
                data: action.payload.data,
                callFrom: action.payload.callFrom,
                style: action.payload.style
            }
        case actions.TOP_UP_DIALOG_SET_ACTION:
            return {
                ...state,
                dialogActions: action.payload.dialogActions
            }

        default:
            return state;
    }
}

export default toUpDialogReducer;