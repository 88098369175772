import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import ShowCircularProgress from '../components/circularProgress';
import {styles} from '../../../services/constants/styles';
import {showCustomSnackBar} from '../../../services/actions/snackBarAction';
import {refreshDetailViewData, refreshDetailViewListViewData} from '../../../services/actions/detailViewActions';
import {getIntParam, isValidParam} from '../../../services/helper/parameterVerifier';
import {getLocalizedStrings} from '../../../services/constants/MultiLingual';
import {endPoints} from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import {
    isDetailViewSet,
    detailViewIsMounted,
} from '../../../services/actions/detailViewActions';

const HubCaseClose = ({ data, closeDrawer }) => {
    const dispatch = useDispatch();

    const [mounted, setMounted] = useState(true);

    const doCaseClose = () => {
        try {
            const notetext = document.getElementById('notetext').value;
            let url = endPoints.CASES.CASE_CLOSE;
            let params = {
                "id": getIntParam(data.record_id),
                "note_text": notetext
            }
            let promise =  Promise.resolve(HTTPClient.post(url, params));
            promise.then((response)=>{
                if(isValidParam(response) && response.status === 0){
                    dispatch(detailViewIsMounted(false));
                    dispatch(isDetailViewSet(false));
                    dispatch(refreshDetailViewData(true));
                    dispatch(refreshDetailViewListViewData(true));
                    closeDrawer();
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.CASE.CASE_CLOSED_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                }
            });
    
        } catch (error) {
            console.error("Error in 'hubCaseClose.js--> 'doCaseClose()'");        
        }
    }

    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight-10) / 2;
    if (mounted) {
        return (
            <div style={{ marginTop: '-10px', paddingLeft: '20px', paddingRight: '20px' }}>
                <div style={{ color: '#717171', marginTop: '20px' }}>
                    <div><span/></div>
                    <div>
                        <FormControl style={{width:'100%'}} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                type='textarea'
                                multiline={true}
                                rows={3}
                                fullWidth={true}
                                id="notetext"
                                textareastyle={{ marginTop: '10px', height: '90%' }}
                                //style={{ width: '100%', backgroundColor: '#fff', border: '1px solid #dddddd' }}
                                inputStyle={{ overflowY: 'auto' }}
                                label={getLocalizedStrings().label.APPOINTMENTS_TASKS.ADD_NOTE}
                                autoComplete="new-password"
                                className={"sf-fields-bg"}
                            />
                        </FormControl>
                    </div>
                </div>
                <div style={{ float: 'right', marginTop: '20px' }}>
                    <Button

                        style={styles.primaryButton}
                        onClick={() => doCaseClose()}
                    >{getLocalizedStrings().label.COMMON.CLOSE_CASE}</Button>
                    <Button

                        style={styles.secondaryButton}
                        onClick={() => closeDrawer()}
                    >{getLocalizedStrings().label.COMMON.CANCEL}</Button></div>
            </div>
        );
    } else {
        return (<div className="loaderh">
            {
                <div style={{ width: '100%', height: pageHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            }
        </div>);
    }
}

export default HubCaseClose;
