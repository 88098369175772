import { actions } from '..//constants/actions';

const tasksReportInitialState = {
    tasksReportData: {},
    tasksReportWorkflows: [],
}
const tasksReportReducer = (state = tasksReportInitialState, action) => {
    switch (action.type) {
        case actions.TASKS_REPORT_DATA_UPDATE:
            return {
                ...state,
                tasksReportData: action.payload
            }
        case actions.TASKS_REPORT_WORKFLOW_LIST_UPDATE:
            return {
                ...state,
                tasksReportWorkflows: action.payload
        }
        default:
            return state;
    }
}
export default tasksReportReducer;