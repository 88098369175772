import { actions } from '..//constants/actions';
import { getObjectParam, getStringParam, isValidParam, makeValidObjectName } from '..//helper/parameterVerifier';
import { getActiveTab } from '..//helper/sfTabManager';
import { constants } from '..//constants/constants';
import { getObjectNameByLabel } from '..//helper/common';

const assignListViewInitialSate = {
    mounted: false,
    data: {
        object: '',
    },
    error: null,
    actionsOnSelectedRows: [],
    selectedRows: null,
    queryName: '',
    isSetHeader: false,
    isShowGroupBy: false,
    groupByInfo: {
        object: '',
    },
    isGroupByMounted: false,
};

const assignListViewReducer = (state = assignListViewInitialSate, action) => {
    let isMount = false;
    switch (action.type) {
        case actions.ASSIGN_LISTVIEW_GET_FULFILLED:
            isMount = isAssignListViewMount(action.payload, action.state);
            if (isMount) {
                return {
                    ...state,
                    mounted: true,
                    data: action.payload,
                    actionsOnSelectedRows: [],
                    selectedRows: [],
                    queryName: action.payload !== null ? action.payload.query_name : '',
                    isSetHeader: action.payload.records != null && action.payload.records.length > 0 ? true : false,
                    isShowGroupBy: false,
                };
            } else {
                return {
                    ...state,
                };
            }
        case actions.ASSIGN_LISTVIEW_GROUPBY_INFO_GET_FULFILLED:
            isMount = isAssignListViewMount(action.payload, action.state);
            if (isMount) {
                return {
                    ...state,
                    isGroupByMounted: true,
                    groupByInfo: action.payload,
                };
            } else {
                return {
                    ...state,
                };
            }
        case actions.ASSIGN_LISTVIEW_GET_SELECTED_RECORD:
            return {
                ...state,
                selectedRows: action.payload,
            };
        case actions.ASSIGN_LISTVIEW_SET_MOUNTED:
            return {
                ...state,
                mounted: action.payload,
                isGroupByMounted: action.payload,
            };
        case actions.ASSIGN_LISTVIEW_GROUPBY_SHOW_HIDE:
            return {
                ...state,
                isShowGroupBy: state.isShowGroupBy ? false : true,
            };
        default:
            return state;
    }
};

export default assignListViewReducer;

const isAssignListViewMount = (payload, state) => {
    let activeTab = getObjectParam(getActiveTab(state));
    let activeTabObject = getStringParam(activeTab.object);
    let payloadObject = '';
    activeTabObject = makeValidObjectName(activeTabObject);
    if (activeTabObject === constants.SEARCH_RESULTS && isValidParam(activeTab.info.search) && getStringParam(activeTab.info.search.object) !== '') {
        activeTabObject = getStringParam(activeTab.info.search.object);
    }

    payloadObject = getStringParam(getStringParam(payload.object));
    payloadObject = getObjectNameByLabel(payloadObject);
    payloadObject = payloadObject === constants.AB_CAMPAIGNS_OBJECT ? constants.BROADCAST_CAMPAIGNS_OBJECT : payloadObject;
    payloadObject = payloadObject === constants.TOUCH_CAMPAIGNS_OBJECT ? constants.AUTOMATION_DESIGNER_OBJECT : payloadObject;

    if (activeTab && activeTabObject.toUpperCase() === payloadObject.toUpperCase()) {
        return true;
    } else {
        return false;
    }
};
