import { actions } from "../constants/actions"

const initialState = {
    duration: 'This Year',
    categoryData: [],
    categories: 'All',
    questionsData: [],
    questions: 'All',
    reportData: [],
    averageScore: 0,
    initialLoad: true,
    reportParams:{},
  }


const auditPerformanceReportReducer = (state = initialState, action) => {

  switch (action.type) {

    case actions.SET_AUDIT_PERFORMANCE_DURATION:
      return { ...state, duration: action.payload }
    case actions.SET_AUDIT_PERFORMANCE_CATEGORY_DATA:
      return { ...state, categoryData: action.payload }
    case actions.SET_AUDIT_PERFORMANCE_CATEGORIES:
      return { ...state, categories: action.payload }
    case actions.SET_AUDIT_PERFORMANCE_QUESTIONS_DATA:
      return { ...state, questionsData: action.payload }
    case actions.SET_AUDIT_PERFORMANCE_QUESTIONS:
      return { ...state, questions: action.payload }

    case actions.SET_AUDIT_PERFORMANCE_REPORT_DATA:
      return { ...state, reportData: action.payload,averageScore:(action.payload.length>0 && action.payload.reduce((v, { unit_avg_score }) => v + Number(unit_avg_score), 0))/action.payload.length }

    case actions.SET_AUDIT_PERFORMANCE_INITIAL_LOAD:
      return { ...state, initialLoad: action.payload }

    case actions.SET_AUDIT_PERFORMANCE_REPORT_PARAMS:
      return { ...state, reportParams: action.payload }
    default:
      return state
  }
}
export default auditPerformanceReportReducer