import { actions } from '..//constants/actions';
const smsInbox = {
    selectedContact: null,
    selectedToNumber: null,
    selectedFromNumer: null,
    fromNoScrollPosition: null,
    toNoScrollPosition: null,
    smsData: [],
    newSms: null,
    isRefresh: false

}

const smsReducer = (state = smsInbox, action) => {
    switch (action.type) {
        case actions.UPDATE_SMS_DATA:
            if (action !== undefined && action.payload !== undefined) {
                return {
                    ...state,
                    smsData: action.payload.data,
                }
            }
            break;
        case actions.UPDATE_SELECTED_CONTACT:
            if (action !== undefined && action.payload !== undefined) {
                return {
                    ...state,
                    selectedContact: action.payload.data,
                }
            }
            break;
        case actions.UPDATE_SELECTED_TONUMBER:
            if (action !== undefined && action.payload !== undefined) {
                return {
                    ...state,
                    selectedToNumber: action.payload,
                }
            }
            break;
        case actions.UPDATE_SELECTED_FROM_NUMBER:
            if (action !== undefined && action.payload !== undefined) {
                return {
                    ...state,
                    selectedFromNumer: action.payload,
                }
            }
            break;
        case actions.UPDATE_SCROLL_POSITION_FROM_NUMBER: {
            return {
                ...state,
                fromNoScrollPosition: action.payload
            }
        }
        case actions.UPDATE_SCROLL_POSITION_TO_NUMBER: {
            return {
                ...state,
                toNoScrollPosition: action.payload
            }
        }
    
        case actions.CLEAR_SELECTED_CONTACT:
            if (action !==undefined && action.payload !== undefined && action.payload === true) {
                return {
                    ...state,
                    selectedContact: null,
                }
            }
            break;
        case actions.UPDATE_NEW_SMS:
            if (action !== undefined && action.payload !== undefined) {
                return {
                    ...state,
                    newSms: action.payload.data,
                }
            }
            break;
        case actions.GET_SMS_REFRESH:
            return {
                ...state,
                isRefresh: action.payload,
            }
        default:
            return state;
    }
}

export default smsReducer;