import * as ParameterVerifier from '..//helper/parameterVerifier';
import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';
import { actions } from '..//constants/actions';

function saveSchedulingSetup(param) {
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(param)) {
             promise = Promise.resolve(HTTPClient.post(endPoints.SCHEDULER.SAVE_SETUP, param)); 
        }
    } catch (error) {
        console.error("Error in 'schedulerActions.js -> saveSchedulingSetup()':" + error);
    }
    return promise;
}

function getSchedulerLinkInfo() {
    var promise = null;
    try {
       
        promise = Promise.resolve(HTTPClient.get(endPoints.SCHEDULER.LINK_INFO)); 
        
    } catch (error) {
        console.error("Error in 'schedulerActions.js -> getSchedulerLinkInfo()':" + error);
    }
    return promise;
}

function getSchedulerDetails(params) {
    var promise = null;
    try {
       
        promise = Promise.resolve(HTTPClient.get(endPoints.SCHEDULER.SCHEDULER_DETAILS, params)); 
        
    } catch (error) {
        console.error("Error in 'schedulerActions.js -> getSchedulerDetails()':" + error);
    }
    return promise;
}

function setSchedulerDetails(data) {
    return function (dispatch) {
        dispatch({
            type: actions.SCHEDULER_DETAILS,
            payload:data
        })
    }
}

function getSchedulerWorkflowList(workflowList) {
    return {
        type: actions.SCHEDULER_WORKFLOW_LIST_GET,
        payload: workflowList
    }
}

function updateSchedulerLinkDetails(data) {
    return function (dispatch) {
        dispatch({
            type: actions.SCHEDULER_LINK_DETAILS,
            payload:data
        })
    }
}

function deleteScheduler(param) {
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(param)) {
             promise = Promise.resolve(HTTPClient.post(endPoints.SCHEDULER.DELETE, param)); 
        }
    } catch (error) {
        console.error("Error in 'schedulerActions.js -> deleteScheduler()':" + error);
    }
    return promise;
}


export {
    saveSchedulingSetup,
    getSchedulerLinkInfo,
    getSchedulerDetails,
    setSchedulerDetails,
    getSchedulerWorkflowList,
    updateSchedulerLinkDetails,
    deleteScheduler
}