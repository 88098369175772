import React from 'react';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const EditorSelectionDialog =()=> {
    const dispatch = useDispatch();
	const [selected, setSelected] = useState('New');

	const appState = useSelector((state) => state.app);
	const appContainer = useSelector((state) => state.appContainer);

    const handleClose = () => {
        dispatch(getAppDialog(false, constants.EDITOR_SELECTION_DIALOG, null, null, null, null));
    }

    const handleSelect = () => {
        appContainer.dialogProps.data.data = { ...appContainer.dialogProps.data.data, type: selected } 
        appContainer.dialogProps.eventName(appContainer.dialogProps.data);
    }


    const changeRadioButton = (event, value) => {
        setSelected(value);
    }
    useEffect(() => {
        setAppDialogActions(getActions());
	},[setAppDialogActions]);


    const getActions = () => {
        let className = '';
        const actions = [
            <Button
                primary={true}
                onClick={()=>handleSelect()}
                style={{ ...styles.primaryButton, display: 'inline-grid' }}
                className={className}
            > {getLocalizedStrings().label.REPORT.GO}</Button>,
            <Button  
                primary={true}
                onClick={()=>handleClose()}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,

        ];
        return actions;
    }

        return (
            <div>
                  <FormControl component="fieldset">
                    <RadioGroup name="selectEditor" onChange={(event,value)=>changeRadioButton(event,value)} defaultValue={selected} style={{marginTop: '10px', fontSize: '15px'}} >
                    <FormControlLabel value="New"  control={<Radio    color="default" />}  label={getLocalizedStrings().label.MARKETING_TEMPLATE.DRAG_AND_DROP_BUILDER} />
                    <FormControlLabel value="Legacy"  control={<Radio   color="default" />} label={getLocalizedStrings().label.MARKETING_TEMPLATE.LEGACY_BUILDER} />
                       
                    </RadioGroup>
                    </FormControl>
            </div>
        );
}

export default EditorSelectionDialog;