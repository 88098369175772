import React, { useEffect, useState }  from 'react';
import Button from '@mui/material/Button';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants } from '../../../services/constants/constants';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import * as sfDialogs from '../components/sfDialogs';
import NoRecord from '../components/noRecord';
import { useDispatch, useSelector } from "react-redux";

const DocusignSetup =({}) => {
   
    const dispatch = useDispatch();
    const [isConnected, setIsConnected] = useState(false); 
    const [isMounted, setIsMounted] = useState(false);
    const [connecting, setConnecting] = useState(false);
    const [disconnect, setDisconnect] = useState(false);
    const [docusignUserEmail, setDocusignUserEmail] = useState("");
    const [isNoTemplateFound, setIsNoTemplateFound] = useState(false);

    useEffect(() => {
        isDocusignConnected();
    }, []);

   const isDocusignConnected = () => {
        try {
            let promise = Promise.resolve(HTTPClient.get(endPoints.DOCUSIGN.ENVELOP_LIST_GET, null));
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response === null || response === "" || response === undefined || response.status === -1) {
                        let errorMsg = response?.error?.message;
                        if (isValidParam(errorMsg) && errorMsg === "Authentication failed") {
                        }
                        setIsMounted(true);
                        setIsConnected(false);
                    } else {
                        if (response.hasOwnProperty('template_list')) {
                            let templateList = response['template_list'];
                            let docusignUserEmail = response['docusign_user_email'];
                            if (isValidParam(templateList) && templateList !== null && templateList.length > 0) {
                                setIsMounted(true);
                                setIsConnected(true);
                                setDocusignUserEmail(docusignUserEmail);
                            } else {
                                setIsMounted(true);
                                setIsConnected(true);
                                setDocusignUserEmail(docusignUserEmail);
                            }
                        }
                    }
                }
            })
        } catch (error) {
            console.log("Error in 'DocusignSetup.js -> isDocusignConnected()':" + error);
        }
    }

    const openConfiguration = () => {
        try {
            setConnecting(true);
           
            let token = localStorage.getItem('token');
            let url = constants.DOCUSIGN_AUTH_API + 'response_type=code' +
                '&state=' + token + '&scope=signature&prompt=login&client_id=fc9c09eb-7135-4d76-9826-fa86d5196b92&redirect_uri=' + constants.DOCUSIGN_REDIRECT_URI;
            var myWindow = window.open("", "", 'scrollbars=1,width=850,height=550,location=no');
            myWindow.location = url;
            let refreshIntervalId = 0;

            setTimeout(function () {
               getDocusignSetup();
            }, 30000);
        } catch (error) {
            console.log("Error in 'DocusignSetup.js -> openConfiguration()':" + error);
        }
    }

    const getDocusignSetup = () => {
        try {
            let promise = Promise.resolve(HTTPClient.get(endPoints.DOCUSIGN.ENVELOP_LIST_GET, null));
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.status === -1) {
                        let errorMsg = response.error.message;
                        if (isValidParam(errorMsg) && errorMsg === "Authentication failed") {
                        }
                        setIsMounted(true);
                        setIsConnected(false);
                        setConnecting(false);
                        setDisconnect(false);
                      
                    } else {
                        if (response.hasOwnProperty('template_list')) {
                            let templateList = response['template_list'];
                            let docusignUserEmail = response['docusign_user_email'];
                            if (isValidParam(templateList) && templateList !== null && templateList.length > 0) {
                                setIsMounted(true);
                                setIsConnected(true);
                                setConnecting(false);
                                setDisconnect(false);
                                setDocusignUserEmail(docusignUserEmail);
                                
                            } else {
                                setIsMounted(true);
                                setIsConnected(true);
                                setConnecting(false);
                                setDisconnect(false);
                                setDocusignUserEmail(docusignUserEmail);
                            }
                        }
                    }
                }
            });


        } catch (error) {
            console.log(error.message);
        }
    }

    const disconnectDocusign = () => {
        try {
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.MY_INTEGRATION.DISCONNECT_CONNECTION,()=>disconnectDocusignOnConfirm(),null);
          
        } catch (error) {
            console.log("Error in 'DocusignSetup.js -> disconnectDocusign()':" + error);
        }
    }
    const disconnectDocusignOnConfirm=()=>{
        setDisconnect(false);

        let promise = Promise.resolve(HTTPClient.post(endPoints.DOCUSIGN.DISCONNECT_DOCUSIGN, null));
        promise.then((response) => {
            if (isValidParam(response)) {
                if (isValidParam(response.data.disconnected) && response.data.disconnected) {
                    setIsConnected(false);
                    setConnecting(false);
                    setDisconnect(false);
                }
            }
        });

    }
   
        let height = window.innerHeight - 150;
        let top = (height-10) / 2;
        return (
            <div style={{ marginLeft: '3%' }}>
                <div id="header" style={{ borderBottom: '2.0px solid #afafaf5c' }}><span style={{ fontWeight: 'bold', fontSize: '20px' }}>{getLocalizedStrings().label.DOCUSIGN.DOCUSIGN_SETUP_HEADER}</span></div>
                {isMounted &&
                    <div style={{ marginTop: '10px' }}>
                        {
                            (!isConnected && !isNoTemplateFound) &&
                            <div>
                                <div style={{ fontSize: '14px' }}>{getLocalizedStrings().label.DOCUSIGN.DOCUSIGN_CONNECT_TITLE}</div>
                                <div style={{ paddingTop: '10px' }}>

                                    {connecting &&
                                        <div>
                                            <span className="msg-import-progress" >
                                                {getLocalizedStrings().label.MY_INTEGRATION.CONNECTING}
                                                <img src="/asset/images/ajLoader2.gif" />
                                            </span>
                                        </div>
                                    }
                                    {!connecting &&

                                        <Button
                                            
                                            style={styles.primaryButton}
                                            onClick={() => openConfiguration()}
                                        >{getLocalizedStrings().label.DOCUSIGN.CONNECT_BUTTON}</Button>
                                    }
                                </div>
                            </div>
                        }
                        {
                            (isConnected) &&
                            <div>
                                <div style={{ padding: '8px', fontSize: '15px', height: '40px', background: 'rgb(227, 230, 232)', marginRight: '1%' }}>
                                    {getLocalizedStrings().label.COMMON.CONNECTED_AS} {docusignUserEmail}
                                </div>
                                <div style={{ paddingTop: '15px' }}>

                                    {disconnect &&

                                        <div style={{ paddingLeft: '10px' }}><span className="msg-import-progress" >{getLocalizedStrings().label.MY_INTEGRATION.DISCONNECTING}<img src="/asset/images/ajLoader2.gif" /></span></div>

                                    }
                                    {!disconnect &&
                                        <Button
                                            
                                            style={{ ...styles.secondaryButton, float: 'right' }}
                                            onClick={() => disconnectDocusign()}
                                            
                                        >{getLocalizedStrings().label.DOCUSIGN.DISCONNECT_BUTTON}</Button>
                                    }
                                </div>
                            </div>
                        }
                        {
                            (isNoTemplateFound) &&
                            <NoRecord object={constants.DOCUSIGN} parentObject={constants.ACCOUNTS_OBJECT} />
                        }
                    </div>
                }
                {!isMounted &&
                    <div style={{ width: '100%', height: height }} >
                        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }} >
                            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        );

    
}



export default DocusignSetup;