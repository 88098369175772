import { VERSION } from '..//constants/constants';
import store from '../../../src/services/store';
import { getStringParam, getObjectParam } from '..//helper/parameterVerifier';
import { constants } from '..//constants/constants';
/**
 * @author Pradyut(2017-11-02) 
 */

// this function gets the cookie, if it exists
function getCookie(keyName) {
    let start = document.cookie.indexOf(keyName + "=");
    let len = start + keyName.length + 1;
    if ((!start) && (keyName !== document.cookie.substring(0, keyName.length))) {
        return null;
    }
    if (start === -1) return null;
    let end = document.cookie.indexOf(";", len);
    if (end === -1) end = document.cookie.length;
    return unescape(document.cookie.substring(len, end));
}

/**
only the first 2 parameters are required, the cookie keyName, the cookie
value. Cookie time is in milliseconds, so the below expires will make the 
number you pass in the Set_Cookie function call the number of days the cookie
lasts, if you want it to be hours or minutes, just get rid of 24 and 60.

Generally you don't need to worry about domain, path or secure for most applications
so unless you need that, leave those parameters blank in the function call.
*/
function setCookie(keyName, value, expires, path, domain, secure) {
    // set time, it's in milliseconds
    let today = new Date();
    today.setTime(today.getTime());
    // if the expires variable is set, make the correct expires time, the
    // current script below will set it for x number of days, to make it
    // for hours, delete * 24, for minutes, delete * 60 * 24
    if (expires) {
        expires = expires * 1000 * 60 * 60 * 24;
    }

    let expires_date = new Date(today.getTime() + (expires));

    document.cookie = keyName + "=" + escape(value) +
        ((expires) ? ";expires=" + expires_date.toGMTString() : "") + //expires.toGMTString()
        ((path) ? ";path=" + path : "") +
        ((domain) ? ";domain=" + domain : "") +
        ((secure) ? ";secure" : "");
}

// this deletes the cookie when called
function deleteCookie(keyName, path, domain) {
    if (getCookie(keyName)) document.cookie = keyName + "=" +
        ((path) ? ";path=" + path : "") +
        ((domain) ? ";domain=" + domain : "") +
        ";expires=Thu, 01-Jan-1970 00:00:01 GMT";
}

function deleteAllTabCookies() {
    let currentCookies = null;
    let currentVersion = VERSION.COOKIE;
    let isDelete = false;
    try {
        currentCookies = document.cookie.split(";");
        for (var i = 0; i < currentCookies.length; i++) {
            let equals = currentCookies[i].indexOf("=");
            let name = equals > -1 ? currentCookies[i].substr(0, equals) : currentCookies[i];
            if (name) {
                /** START: This section used to delete all Tab cookie when version update. */
                if (name.indexOf('TabInfo') > -1 && name.indexOf(currentVersion) === -1) {
                    try {
                        isDelete = true;
                        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                    } catch (e) { }
                }

                if (name.indexOf(currentVersion) === -1 && !isDelete) {
                    if (name.indexOf('TabInfo') > -1) {
                        try {
                            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                        } catch (e) { }
                    }
                }
                /** END: This section used to delete all Tab cookie when version update. */
            }

            name = null;
            equals = null;
        }
    } catch (error) {
        console.error("Error in 'sfCookies.js -> deleteAllAppCookies()':" + error);
    }
}

function deleteAllTabCookiesAfterlogin() {
    let currentCookies = null;
    try {
        currentCookies = document.cookie.split(";");
        for (var i = 0; i < currentCookies.length; i++) {
            let equals = currentCookies[i].indexOf("=");
            let name = equals > -1 ? currentCookies[i].substr(0, equals) : currentCookies[i];
            if (name) {
                if (name.indexOf('TabInfo') > -1) {
                    try {
                        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                    } catch (e) { }
                }
            }
            name = null;
            equals = null;
        }
    } catch (error) {
        console.error("Error in 'sfCookies.js -> deleteAllTabCookiesAfterlogin()':" + error);
    }
}

function setLasetSelectedModuleName(moduleName) {
    let expiresDays = 365 * 5;
    let cookieName = 'last_selected_module';
    try {
        moduleName = getStringParam(moduleName);
        if (moduleName === '') {
            moduleName = getStringParam(store.getState().app.selectedModuleName);
        }
        if (moduleName !== '') {
            let state = store.getState();
            let userId = state.app.me.id;
            let projectId = state.app.me.projectId;
            cookieName = cookieName + '_' + projectId + '_' + userId; 
            setCookie(cookieName, moduleName, expiresDays);
        }        
    } catch (error) {
        console.error("Error in 'common.js -> setLasetSelectedModuleName()':" + error);
    }
}

function getLasetSelectedModuleName() {
    let cookieName = 'last_selected_module';
    let moduleName = '';
    try {
        let state = store.getState();
        let userId = state.app.me.id;
        let projectId = state.app.me.projectId;
        cookieName = cookieName + '_' + projectId + '_' + userId;
        moduleName = getStringParam(getCookie(cookieName));
    } catch (error) {
        console.error("Error in 'common.js -> getLasetSelectedModuleName()':" + error);
    }
    return moduleName;
}

function getSelectedViewFromCookie(projectId, object) {
    let view = 'queries';
    let selectedView = getObjectParam(JSON.parse(getCookie("view_" + object + "_" + projectId)));
    if (selectedView.hasOwnProperty(object)) {
        let obj = getObjectParam(selectedView[object]);
        if (obj.hasOwnProperty('defaultView') && obj.defaultView === 'Column') {
            view = 'column';
        } else if (obj.hasOwnProperty('defaultView') && object === constants.OPPORTUNITIES_OBJECT && obj.defaultView === 'Forecast') {
            view = 'kanban';
        }
    } else {
        if (object === constants.OPPORTUNITIES_OBJECT) {
            view = 'column';
        }
    }
    return view;
}

export {
    getCookie,
    setCookie,
    deleteCookie,
    deleteAllTabCookies,
    setLasetSelectedModuleName,
    getLasetSelectedModuleName,
    deleteAllTabCookiesAfterlogin,
    getSelectedViewFromCookie
}