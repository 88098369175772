import React, { useEffect, useState } from 'react';
import { styles } from '../../../services/constants/styles';
import { isValidParam, getStringParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import * as HTTPClient from '../../../services/helper/httpClient';
import ShowCircularProgress from '../components/circularProgress';
import { endPoints } from '../../../services/constants/endPoints';
import Axios from 'axios';
import { getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { useSelector } from "react-redux";

const AttachmentDialog = ({ data }) => {
    const [attachFiles, setAttachFiles] = useState([]) 
    
	useEffect(()=>{
        const params = {id: data.id}
        let promise = Promise.resolve(HTTPClient.get(endPoints.ATTACHMENT.GET_TASK_ATTACHMENT, params));
			if (isValidParam(promise)) {
				promise.then((response) => {
					if(response?.attached_files?.length > 0) {
						setAttachFiles(response.attached_files)
					} 
				});
			}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const downloadAs = (item) => {
		if (item.uid > 0 || (item.urlS3 != null && item.urlS3 != undefined)) {
			let tabInfo = getActiveTabInfo();
			let isUnitsAccount = false;
			let UnitsAccountProjectId = 0;
			if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
				isUnitsAccount = tabInfo.isUnitsAccount;
				UnitsAccountProjectId = tabInfo.UnitsAccountProjectId;
			}
			let url = '';
			var formData = new FormData();
			let documentName = null;
			let documentType = '';
			let fileparams = {}
			url = endPoints.ATTACHMENT.DOWNLOAD_ATTACHMENT;
			documentName = item.name;

			if (UnitsAccountProjectId > 0) {
				fileparams = { 'id': item.uid, 'url': item.hasOwnProperty('urlS3') && item.urlS3 != null ? item.urlS3 : "", 'isUnitsAccount': isUnitsAccount, 'UnitsAccountProjectId': UnitsAccountProjectId };
			} else {
				fileparams = { 'id': item.uid, 'url': item.hasOwnProperty('urlS3') && item.urlS3 != null ? item.urlS3 : "" };
			}

			formData.append('input_param', JSON.stringify(fileparams));
			documentName = getStringParam(documentName);
			documentType = documentName.split('.').pop();
			if (documentName != '') {
				HTTPClient.postFormDataDownloadFile(url, formData, documentName);
			}
		} else {
			Axios.get(item.urlS3, {
				headers: {
					"Content-Type": "application/octet-stream"
				},
				responseType: "blob"
			})
				.then(response => {

					const a = document.createElement("a");
					const url = window.URL.createObjectURL(response.data);
					a.href = url;
					a.download = item.name;
					a.click();
				})
				.catch(err => {
					console.log("error", err);
				});
		}

	};

    const getAttachmetFiles = () => {
		let element = [];
		try {
			let _attachmentDetails = isValidParam(attachFiles) ? getArrayParam(attachFiles) : [];

			if (isValidParam(_attachmentDetails)) {
				if (isValidParam(_attachmentDetails) && getArrayParam(_attachmentDetails).length > 0) {
						<tbody>
							{_attachmentDetails.map((attachment, index) => (
							element.push(<tr key={index} style={{ marginTop: '5px'}}>
								<td>
									<button 
										style={{
											background: 'none!important',
											border: 'none',
											padding: '0!important',
											fontFamily: 'arial, sans-serif',
											color: '#069',
											textDecoration: 'underline',
											cursor: 'pointer',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap', 
        									overflow: 'hidden'
										}} 
										title={attachment.name} 
										onClick={() => downloadAs(attachment)}
									>
										{attachment.name.length > 25 ? (
											attachment.name.substring(0, 25) + '...'
										) : (
											attachment.name
										)}
									</button>
								</td>
								<td>
									<span style={{
										background: 'none!important',
										border: 'none',
										padding: '8px',
										fontFamily: 'arial, sans-serif',
										color: '#000000',
										cursor: 'pointer',
										fontSize: '14px'
									}}>
										{attachment.created_date}
									</span>
								</td>
							</tr>)
							))}
						</tbody>
				}
			}
		} catch (error) {
			console.error("error in CustomForm.js --> 'createAttachmentInfo'" + error);
		}
		return element;
	}
    let height = window.innerHeight - 580;
    let top = (height - 10) / 2;
    if (data.id && attachFiles.length > 0) {
        return(
			<div>
				<table>
					<thead>
						<tr>
							<th style={{ padding: '10 4 10 10', color: '#616161', backgroundColor: '#e3e6e8' }}>
								<span style={{float: 'left'}}>
									Attachment Name
								</span>
							</th>
							<th style={{ padding: '10 4 10 10', color: '#616161', backgroundColor: '#e3e6e8' }}>
								<span style={{float: 'left'}}>
									Date and Time
								</span>
							</th>
						</tr>
					</thead>
					{(attachFiles?.length > 0) &&
						getAttachmetFiles()
					}
				</table>
			</div>
		)
    } else {
        return (
            <div style={{ minHeight: '150px' }}>
                <div className="col-md-12" style={{ marginTop: '30px', paddingBottom: '80px' }}>
                    <div style={{ width: '100%', height: height }} >
                        {
                            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default AttachmentDialog;
