import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { getStringParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { makeTiteleCase } from '../../../services/helper/utils';
import filter from 'lodash/filter';
import { getActiveTabInfo, updateTabInfo } from '../../../services/helper/sfTabManager';
import { refreshListView } from '../../../services/actions/listViewAcions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import Button from '@mui/material/Button';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { setHeader } from '../../../services/actions/headerActions';

const NoAssignmentRule = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        setHeader();
    }, []);

    const openAssignmentRuleDrawer = () => {
        let drawerName = getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULE;

        let data = {};
        data.object = constants.ASSIGNMENT_RULES;
        data.id = null;
        data.mode = constants.NEW;

        dispatch(getAppDrawer(true, drawerName, constants.NEW, data, constants.LIST_VIEW));
    }

    const setHeader = () => {

        let objectName = "Assignment Rules";
        let obj = {};
        dispatch(setHeader(constants.ASSIGNMENT_RULES, objectName, null, false, true));
    }

    let msg = getLocalizedStrings().message.ASSIGNMENT_RULES.NO_ASSIGNMENT_RULES;
    // this.setHeader();
    return (
        <div className="pageBg clearfix">
            <div style={{ textAlign: 'center', padding: '60px' }}>
                <div style={{ padding: '15px' }}><label style={{ color: '#777', fontSize: '25px' }}>{msg}</label></div>
                <div style={{ padding: '15px' }}><Button
                    labelstyle={{ textTransform: 'none', fontSize: '22px', fontWeight: 'bold' }}
                    style={{ ...styles.primaryButton, height: '45px', minWidth: '265px', borderRadius: '20px' }}
                    onClick={() => openAssignmentRuleDrawer()}
                >{getLocalizedStrings().label.ASSIGNMENT_RULES.SETUP_FIRST_RULE}</Button></div>
            </div>
        </div>

    )

}

export default NoAssignmentRule;