import React, { useState, useEffect } from 'react';
import { styles } from '../../../services/constants/styles';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { getStringParam, getObjectParam, isValidParam, getIntParam, getArrayParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import Button from '@mui/material/Button';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, TextField } from '@mui/material';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { useDispatch, useSelector } from "react-redux";
import { Select, MenuItem, InputLabel} from '@mui/material';
import ShowCircularProgress from "../components/circularProgress";
import * as sfDialogs from '../components/sfDialogs';

const SelectAccountForUnitDilog = () => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);
    const [changeLog, setChangeLog] = useState({ AfInfo: {}, accountTypeList: [] });
    const [autoCompleteDataSource, setAutoCompleteDataSource] = useState({});
    const [lookupValue, setLookupValue] = useState("");
    const [lookupInfo, setLookupInfo] = useState({});
    const [baseValue, setBaseValue] = useState('');
    const [mounted, setMounted] = useState( appContainer.dialogProps.data?.object == "units" ? false : true);

    useEffect(() => { 
        if((app.me?.franchise_industry == undefined || app.me?.franchise_industry == "" ) && appContainer.dialogProps.data?.object == "units"){
            getAccountType();
        }
        if(app.me?.franchise_industry !== null && app.me?.franchise_industry !== undefined  && app.me?.franchise_industry !== ""){
            setAccountData('company','');
        }
    }, []);

    const getAccountType=()=>{
        var Url = endPoints.COMMON.GET_ACCOUNT_TYPE;
        let param = {};
        var response = Promise.resolve(HTTPClient.get(Url, param));
        if (isValidParam(response)) {
            response.then((response) => {
                let _type = getArrayParam(response?.accountType);
                let temp = changeLog;
                temp.accountTypeList = _type;
                setChangeLog({ ...temp });
                setMounted(true);
            });
        }
    }
    const setAccountData = (fieldName, accountType) => {
        var Url = endPoints.COMMON.GET_ACCOUNTS;
        let data = appContainer.dialogProps.data
        let param = {};
        let _accountype = "";
        param.type = data.object == "Add Branch" ? "Branch" : data.object == "Add Base" ? "Base" : "Units";
        if(accountType!== null && accountType!== undefined && accountType !== ""){
            _accountype = accountType.split(" ")[0];
        }
        param.accountType = _accountype;
        var response = Promise.resolve(HTTPClient.get(Url, param));
        if (isValidParam(response)) {
            response.then((response) => {
                let options = getArrayParam(response.unitList);
                sortArrayObjectByProperty(options, 'name');
                let temp = autoCompleteDataSource;
                if (options.length > 0) {
                    temp[fieldName] = options;
                } else {
                    temp[fieldName] = [];
                }
                setLookupValue("");
                setAutoCompleteDataSource({ ...temp });
                setMounted(true);
            });
        }
    }

    const setAutoCompleteValue = (fieldName, lookupFieldName, lookupObject, obj) => {
        try {
            lookupObject = getStringParam(lookupObject);
            lookupFieldName = getStringParam(lookupFieldName);
            fieldName = getStringParam(fieldName);
            obj = getObjectParam(obj);
            let id = getIntParam(obj.id);
            let name = getStringParam(obj.name);

            let _lookupInfo = { lookup_object_name: lookupObject, lookup_field_name: lookupFieldName, record_id: id, title: name };
            setLookupInfo({ ..._lookupInfo });
            setLookupValue(name);
            
        } catch (error) {
            console.error("Error in 'sfForm.js -> setAutoCompleteValue()':" + error);
        }
    }
const onTypeChange = (event) => {
    let val = event.target.value;
    setAccountData('company',val);
    setBaseValueForUnit(val)
};
    const setBaseValueForUnit = (val) => {
       
        setBaseValue(val);
        if (val == "MU Base" || val == "Store Base") {
            let url = endPoints.UNITS.GET_AF_LIST;
            let params = {};
            let promise = Promise.resolve(HTTPClient.get(url, params));;
            if (isValidParam(promise)) {
                promise.then((response) => {
                    console.log("AFList", response)
                    let arr = response;
                    if (arr?.length > 0) {

                        let temp = changeLog;
                        temp.AfInfo = arr;
                        setChangeLog({ ...temp });
                    }
                });
            }
        }
    }


    const baseMenuListForUnit = () => {
        let menuItems = [];
        let typeArr = changeLog.accountTypeList;
        if (typeArr.length > 0) {
            typeArr?.map((fieldOption, i) => {
                let _fieldOption = fieldOption?.type
                menuItems.push(
                    <MenuItem value={_fieldOption} id={_fieldOption} title={_fieldOption} key={_fieldOption} style={styles.popoverMenuItem} >{_fieldOption} </MenuItem>);
            });
        }

        return menuItems;
    }

    const searchClient = (obj) => {
        let isContinue = true;
        let isBaseLoggedIn = true;
        if (obj == constants.UNITS_OBJECT && changeLog.accountTypeList &&
            changeLog.accountTypeList.length && baseValue == '') {
            isContinue = false;
        }

        if(obj == constants.UNITS_OBJECT){
            if(baseValue!== null && changeLog.accountTypeList.length) {
                let selectedTypeObj = changeLog.accountTypeList.find(f => f.type == baseValue);
                isBaseLoggedIn = getBooleanParam(selectedTypeObj?.copy_flag); 
            } else {
                isBaseLoggedIn = getBooleanParam(app.me?.base_copy_flag);
            }
        }

        if (isContinue) {
            if (isBaseLoggedIn) {
                if ((lookupValue != "" && lookupValue != undefined && lookupValue != null)
                    || obj == constants.UNITS_OBJECT) {
                    var Url = endPoints.COMMON.SEARCH_CLIENT;
                    var param = new Object;
                    if ((lookupInfo.record_id != undefined && lookupInfo.record_id > 0)
                        || obj == constants.UNITS_OBJECT) {
                        var recordId = 0;
                        recordId = lookupInfo?.record_id;
                        if (recordId == undefined || recordId == "") {
                            recordId = 0;
                        }
                        param.recordId = recordId;
                        param.tableId = OBJECT_TABLEID_MAP[constants.ACCOUNTS_OBJECT];
                        var response = Promise.resolve(HTTPClient.get(Url, param));
                        response.then((response) => {
                            if (isValidParam(response)) {

                                if (obj == constants.ADD_BRANCH) {
                                    openBranchForm(response);
                                    dispatch(getAppDialog(false, constants.ADD_BRANCH, null, null, null, null));
                                }
                                else if (obj == constants.ADD_BASE) {
                                    addUnitBase(response);
                                    dispatch(getAppDialog(false, constants.ADD_UNIT_BASE, null, null, null, null));
                                } else {
                                    openUnitForm(response);

                                    dispatch(getAppDialog(false, constants.SELECT_ACCOUNT, null, null, null, null));
                                }
                            }
                            else {

                            }
                        });
                    } else {
                        dispatch(showCustomSnackBar("No account found for " + lookupValue + ". Please try again.", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }

                } else if (obj == constants.ADD_BRANCH) {
                    openBranchForm();
                    dispatch(getAppDialog(false, constants.ADD_BRANCH, null, null, null, null));
                } else if (obj == constants.ADD_BASE) {
                    addUnitBase();
                    dispatch(getAppDialog(false, constants.ADD_UNIT_BASE, null, null, null, null));
                }
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                    getLocalizedStrings().message.UNITS.NO_BASE_LOGIN, null);
            }
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                getLocalizedStrings().message.UNITS.PLEASE_SELECT_TYPE, null);
        }
    }



    const openUnitForm = (recordDetails) => {
        let baseIndustryArr = {}
        changeLog.accountTypeList.map((el) => {
            if (el.type === baseValue) {
                baseIndustryArr.type = el.type;
                baseIndustryArr.company = el.company;
            }
        })
        let labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + getLocalizedStrings().label.SIDE_NAV.UNITS;
        let data = {};
        data.object = constants.UNITS_OBJECT;
        data.mode = "QUICK";
        data.records = recordDetails?.records;
        data.mainContactName = recordDetails?.mainContactName;
        data.accountRecordId = lookupInfo.record_id;
        data.clientProjectId = recordDetails?.clientProjectId;
        data.clientEmail = recordDetails?.email;
        data.click_form = 'addunit';
        let _baseValue = baseValue.split(" ")[0];
        data.baseValue = _baseValue;
        data.billingMessage = getStringParam(recordDetails?.billingMessage);
        data.AfInfo = changeLog.AfInfo;
        data.baseIndustry = baseIndustryArr;
        dispatch(getAppDrawer(true, labelName, 'New', data, null));
    }
    const openBranchForm = (recordDetails) => {
        let labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + 'Branch';
        let data = {};
        data.object = constants.ADD_BRANCH;
        data.mode = "QUICK";
        data.records = recordDetails?.records;
        data.mainContactName = recordDetails?.mainContactName;
        data.accountRecordId = lookupInfo?.record_id;
        data.clientProjectId = recordDetails?.clientProjectId;
        data.clientEmail = recordDetails?.email;
        data.click_form = 'addBranch';
        data.billingMessage = getStringParam(recordDetails?.billingMessage);
        dispatch(getAppDrawer(true, labelName, 'New', data, null));
    }

    const addUnitBase = (recordDetails) => {
        let labelName = "Add Base";
        let data = {};
        data.object = constants.ADD_UNIT_BASE;
        data.mode = "QUICK";
        data.records = recordDetails?.records;
        data.mainContactName = recordDetails?.mainContactName;
        data.accountRecordId = lookupInfo.record_id;
        data.clientProjectId = 0;
        data.clientEmail = recordDetails?.email;
        data.click_form = 'addunit';
        data.billingMessage = getStringParam(recordDetails?.billingMessage);
        dispatch(getAppDrawer(true, labelName, 'New', data, null));
    }
    let data = appContainer.dialogProps.data;
    console.log("accountTypeList", changeLog.accountTypeList)
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2
    if(mounted){
        return (
            <div>
                <div style={{ width: data.object == "units" ? '85%' : '' , marginTop: data.object == "units"  && changeLog.accountTypeList.length == 0 ? '15px' : ''}}>
                    {data.object !== "Add Branch" && data.object !== "Add Base" && changeLog.accountTypeList.length > 0 &&
                        <FormControl style={{ width: data.object == "units" ? '90%' : '70%', marginLeft: '25px', marginTop: '10px', marginBottom:changeLog.accountTypeList.length == 0 ? '10px':''  }} variant="outlined">
                            <InputLabel id="sf-addunites-from-simple-select-outlined-label" className="sf-add-units-plan-name" style={{ marginTop: '-8px' }}>Type</InputLabel>
                            <Select
                                label="Type"
                                labelId='sf-label-time-zone'
                                id='sf-time-zone'
                                value={baseValue}
                                onChange={(e) => onTypeChange(e)}
                                style={{ height: '37px' }}
                                className={"sf-fields-bg"}
                                autoFocus={true}
                            >
                                {baseMenuListForUnit()}
                            </Select>
                        </FormControl>
                    }
    
                    {data.object !== "Add Base" && <Autocomplete
                        style={{ width: data.object == "units" ? '90%' : '70%', marginLeft: data.object == "units" ? '25px' : '' }}
                        disableClearable
                        filterOptions={(x) => x}
                        getOptionLabel={(option) =>
                            typeof option === 'string' ? option : option.name
                        }
                        id={'sf-autocomplete-multiple-textfield-' + 'company'}
                        options={isValidParam(autoCompleteDataSource['company']) && autoCompleteDataSource['company'] ? autoCompleteDataSource['company'] : []}
    
                        value={lookupValue}
    
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={data.object == "Add Branch" ? getLocalizedStrings().label.COMMON.BRANCH_COMPANY_NAME : getLocalizedStrings().label.COMMON.FRENCHISEE_COMPANY_NAME}
                                InputLabelProps={{
                                    style: {
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        width: '96%',
                                    }
                                }}
                                className={'input-text-autocomplete-' + 'company'}
                                variant="outlined"
                                fullWidth
                                margin='dense'
                                autoFocus= {data.object == "units" && changeLog.accountTypeList.length >0 ? false : true }
                                size="small"
                            />
    
                        )}
                        onChange={(e, newValue) => setAutoCompleteValue('company', 'company', constants.ACCOUNTS_OBJECT, newValue)}
                    />}
                    {data.object == "Add Base" && <Autocomplete
                        style={{ width: '70%' }}
                        disableClearable
                        filterOptions={(x) => x}
                        getOptionLabel={(option) =>
                            typeof option === 'string' ? option : option.name
                        }
                        id={'sf-autocomplete-multiple-textfield-' + 'company'}
                        options={isValidParam(autoCompleteDataSource['company']) && autoCompleteDataSource['company'] ? autoCompleteDataSource['company'] : []}
    
                        value={lookupValue}
    
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Base"
                                InputLabelProps={{
                                    style: {
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        width: '96%',
                                    }
                                }}
                                className={'input-text-autocomplete-' + 'company'}
                                variant="outlined"
                                fullWidth
                                margin='dense'
                                autoFocus
                                size="small"
                            />
    
                        )}
                        onChange={(e, newValue) => setAutoCompleteValue('company', 'company', constants.ACCOUNTS_OBJECT, newValue)}
                    />}
                    <Button
                        key='CONTINUE'
                        onClick={() => searchClient(data.object)}
                        style={{
                            ...styles.primaryButton, marginLeft: data.object !== "units" ? '73%' : '',
                            marginTop: data.object !== "units" ? '-37px' : data.object == "units" ? '-39px' : '10px', float: data.object == "units" ? 'right' : '',
                            marginRight: data.object == "units" ? "-17%" : ''
                        }}
                    >{getLocalizedStrings().label.COMMON.CONTINUE}</Button>
                </div>
            </div>
    
        );
    }
    else {
        return (
            <div style={{ width: "100%", height: contentHeight }}>
                <div
                    className="asset-loaderh"
                    style={{ paddingTop: 30, paddingLeft: "45%" }}
                >
                    <div
                        style={{
                            ...styles.assetLoaderContainer,
                            height: 50,
                            width: 50,
                            padding: 7,
                        }}
                    >
                        <ShowCircularProgress
                            size={30}
                            style={{ marginTop: "3", marginLeft: "3" }}
                        />
                    </div>
                </div>
            </div>
        );
    }

}

export default SelectAccountForUnitDilog;