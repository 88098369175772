
import React from 'react';
import { Link } from 'react-router-dom';
import { getStringParam, getArrayParam, getObjectParam, getBooleanParam, getIntParam} from '../../../services/helper/parameterVerifier';
import { constants } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { addTab, TYPE_DETAIL_VIEW, getActiveTab} from '../../../services/helper/sfTabManager';
/**
 * 
 * @Author  : Pradyut.s 
 * @Date:   : 2018-09-25
 */
export default class SFCustomDataTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    getHeader = (columns) => {
        let arrTh = [];
        let header = null;
        try {
            columns = getArrayParam(columns);
            let style = { border: '1px solid #ddd', padding: 8, fontWeight: 'bold',textAlign:'left' };
            columns.forEach((element, index) => {
                let width = getIntParam(element.width);
                if (width > 0) {
                    style.width = width;
                }
                arrTh.push(<th key={'th-' + element.name + '-' + index} name={element.name} title={element.label} style={style}>{element.label}</th>);
            });
            if (arrTh.length > 0) {
                header = <thead><tr>{arrTh}</tr></thead>;
            }
        } catch (error) {
            console.error("Error in 'sfCustomDataTable.js -> getHeader()':" + error);
        }
        return header;
    }

    getBody = (columns, records) => {
        let arrTrs = [];
        let body = null;
        let arrTds = null;
        try {
            columns = getArrayParam(columns);
            records = getArrayParam(records);

            records.forEach((row, rowId) => {
                arrTds = [];
                columns.forEach((col, colId) => {
                    arrTds.push(this.getTd(colId, col, row));
                });
                let style = {};
                if (rowId % 2 === 0) {
                    style.backgroundColor = '#f2f2f2';
                }
                arrTrs.push(<tr style={style}>{arrTds}</tr>);
            });

            if (arrTrs.length > 0) {
                body = <tbody>{arrTrs}</tbody>;
            }else {
                let data = getObjectParam(this.props.data);
                let msg = getStringParam(data.msg);
                if(msg.length > 0){
                    body = <tbody><tr><td colSpan={columns.length} style={{paddingTop: '25px', textAlign: 'center'}}>{msg}</td></tr></tbody>;
                } else {
                    body = <tbody><tr><td colSpan={columns.length} style={{paddingTop: '25px', textAlign: 'center'}}>{getLocalizedStrings().message.EXPORT_SETTINGS.NO_RECORDS_FOUND}</td></tr></tbody>;
                }
            }
        } catch (error) {
            console.error("Error in 'sfCustomDataTable.js -> getBody()':" + error);
        }
        return body;
    }

    getTd = (colIndex, column, rowData) => {
        let tdElement = null;
        try {
            let style = { border: '1px solid #ddd', padding: 8, width: getIntParam(column.width) };
            let colName = column.name;
            let value = rowData[colName];
            let title = value;
            if (getBooleanParam(column.is_detail_link)) {
                value = <Link title={title} to={'#'} onClick={this.openTab.bind(this, title, rowData["j_leads.id"])} >{value}</Link>;
            }
            tdElement = <td key={'td-' + colName + '-' + colIndex} name={colName} title={title} style={style}>
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: getIntParam(column.width) }}>{value}</div>
            </td>;
        } catch (error) {
            console.error("Error in 'sfCustomDataTable.js -> getTd()':" + error);
        }
        return tdElement;
    }

    getTable = (data) => {
        let tableElement = null

        try {
            let data = getObjectParam(this.props.data);
            let columns = getArrayParam(data.columns);
            let records = getArrayParam(data.records);
            columns = columns.filter(f => { return !f.hasOwnProperty('is_hidden') || !f.is_hidden });
            let style = { width: '100%', borderCollapse: 'collapse', fontStyle: 'normal', fontSize: 14 };
            tableElement = <table ref='sfCustomTable' id="sfCustomTable" style={style}>
                {this.getHeader(columns)}
                {this.getBody(columns, records)}
            </table>;

        } catch (error) {
            console.error("Error in 'sfCustomDataTable.js -> getTable()':" + error);
        }
        return tableElement;
    }

    openTab = (label, id, event) => {
        let recordId = getIntParam(id);
        let labelName = getStringParam(label);
        let object = constants.ACCOUNTS_OBJECT;
        let linkToUrl = '/' + object + '/detailview/' + recordId;

        let activeTab = getObjectParam(getActiveTab());
        let activeTabIndex = getIntParam(activeTab.index);
        let tab = { label: labelName, object: object, type: TYPE_DETAIL_VIEW, imgName: null, url: linkToUrl, isActive: true, parentTabIndex: activeTabIndex };
        addTab(tab, true);
    }

    render() {
        let style = { margin: 10, width: '98%', maxHeight: 600, overflow: 'auto' };
        style = { ...style, ...getObjectParam(this.props.style) };
        return <div ref='sfCustomTableContainer' style={style}>
            {this.getTable(this.props.data)}
        </div>;
    }
}