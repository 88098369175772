import { actions } from '..//constants/actions';
import { dispatch } from '../../services/store';


function updateDocumentLibrary(data) {
    let maxChildId = 0
    data.forEach((item) => {
        if (item?.childId > maxChildId) {
            maxChildId = item.childId;
        }
    });
    data.map((item) => {
        item.maxChildId = maxChildId;
        return item;
    });
    return function (dispatch) {
        dispatch({
            type: actions.ASSETS_MAIN_DATA_UPDATE,
            payload: { data, maxChildId }
        })
    }
}

function updateInBodyAction(actionOn, actionType, params) {
    return function (dispatch) {
        dispatch({
            type: actions.ASSETS_INBODY_ACTION_UPDATE,
            payload: { actionOn: actionOn, actionType: actionType, actionsParam: params }
        })
    }
}
function updateDocumentMaxChildId(data) {
    return function (dispatch) {
        dispatch({
            type: actions.ASSETS_MAX_CHILD_ID__UPDATE,
            payload: data
        })
    }
}
function updateSelectedTree(data) {
    return function (dispatch) {
        dispatch({
            type: actions.ASSETS_SELECTED_TREE_UPDATE,
            payload: data
        })
    }
}
function updateDocumentCallFrom(data) {
    return function (dispatch) {
        dispatch({
            type: actions.ASSETS_CALL_FROM_UPDATE,
            payload: data
        })
    }
}
function updateDocumentObject(data) {
    return function (dispatch) {
        dispatch({
            type: actions.ASSETS_SELECTED_OBJECT_UPDATE,
            payload: data
        })
    }
}
function updateExpandedNode(data) {
    return function (dispatch) {
        dispatch({
            type: actions.ASSETS_EXPANDED_NODE_UPDATE,
            payload: data
        })
    }
}
function updateSelectedNode(data) {
    let numberValue = parseFloat(data);
    if (isNaN(numberValue)) {
        numberValue = 0
    }
    return function (dispatch) {
        dispatch({
            type: actions.ASSETS_SELECTED_NODE_UPDATE,
            payload: numberValue
        })
    }
}
function doRefreshAssetsTree(refresh) {
    return {
        type: actions.REFRESH_ASSETS_TREE,
        payload: {
            refresh: refresh,
        }
    }
}
function updateisAssetsUpdated(data) {
    return function (dispatch) {
        dispatch({
            type: actions.ASSETS_DOCUMENT_IS_UPDATE,
            payload: data
        })
    }
}

export {
    doRefreshAssetsTree,
    updateExpandedNode,
    updateSelectedNode,
    updateDocumentLibrary,
    updateDocumentMaxChildId,
    updateSelectedTree,
    updateDocumentCallFrom,
    updateDocumentObject,
    updateisAssetsUpdated,
    updateInBodyAction
}