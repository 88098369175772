import React ,{useRef}from 'react';
import { Box, MenuItem, TextField,FormControl,FormControlLabel,FormLabel,RadioGroup,Radio,FormHelperText} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { SMS_VERIFICATION } from '../../../../../services/constants/constants';
import { useSelector ,useDispatch} from 'react-redux';
import { actions } from '../../../../../services/constants/actions';

const FormFields = ({jsonData,customerDetails,setCustomerDetails, brandDetails, campaignDetails, 
                setBrandDetails, setCampaignDetails, setStateMenuItemList, customerDetailsFormFieldsDisable,
            brandDetailsFormFieldsDisable,campaignDetailsFormFieldsDisable}) => {
    const dispatch = useDispatch();
    const inputRef =  useRef(null);
    const { selectedForm ,formErrors} = useSelector((state) => state.smsVerfication);


    let selectedFiledsValue;
    if(selectedForm === 'Customer Details') {
        selectedFiledsValue = customerDetails
    } else if(selectedForm === 'Brand Details') {
        selectedFiledsValue = brandDetails
    } else{
        selectedFiledsValue = campaignDetails
    }
       
    let selectedFormFeildsDisable;
    if(selectedForm === 'Customer Details') {
        selectedFormFeildsDisable = customerDetailsFormFieldsDisable
    } else if(selectedForm === 'Brand Details') {
        selectedFormFeildsDisable = brandDetailsFormFieldsDisable
    } else{
        selectedFormFeildsDisable = campaignDetailsFormFieldsDisable
    }
    
    const handleDropDownChange = (event, dropDownName) => {
        const { value } = event.target;
        console.log('value',value);
            if (selectedForm === 'Customer Details') {
                setCustomerDetails({
                    ...customerDetails,
                    [dropDownName]: value
                });
            }else if (selectedForm === 'Brand Details') {
                setBrandDetails({
                    ...brandDetails,
                    [dropDownName]: value
                });
            } else {
                setCampaignDetails({
                    ...campaignDetails,
                    [dropDownName]: value
                });
            }  
            dispatch({ type: actions.SET_SMSVERIFICATION_FORM_ERROR, payload: { field: dropDownName, error: null } });
    };

    const handleCountryDropDownChange = (event) => {
        let value = event.target.value;
        setStateMenuItemList(value)
    }

    const handleChange = (event) => {  
        const { name, value } = event.target;
        console.log('use_case_description==>',name, value)
            if (selectedForm === 'Customer Details') {
                setCustomerDetails({
                    ...customerDetails,
                    [name]: value
                });
            } else if (selectedForm === 'Brand Details') {
                setBrandDetails({
                    ...brandDetails,
                    [name]: value
                });
            }  else {
                if(name === 'use_case') {
                    setCampaignDetails({
                        ...campaignDetails,
                        [name]: value,
                    });
                } else {
                    setCampaignDetails({
                        ...campaignDetails,
                        [name]: value,
                    });
                }
            } 
            dispatch({ type: actions.SET_SMSVERIFICATION_FORM_ERROR, payload: { field: event.target.name, error: null } });  
    };


    const handleNumberTypeChange = (event) =>{
        if(event.target.value >= 0 && event.target.value !== '-'){
            setCustomerDetails({
                ...customerDetails,
                [event.target.name]: event.target.value,
            });
        }
    }

    const focusOntextfield = (element) =>{
        inputRef.current = element;
        inputRef.current?.focus();
    }


    const generateInputField = (field, index) => {
        const { id, name, type, label, required, multiline, max_lines} = field;
        
        if(type === 'tel')  {
            return (
                <TextField
                    size={'small'}
                    key={id}
                    id={id}
                    label={label}
                    inputRef={Boolean(formErrors[field.name]) ? focusOntextfield :''}
                    name={name}
                    error={Boolean(formErrors[field.name])}
                    helperText={formErrors[field.name]}
                    value={selectedFiledsValue[name] || ''}
                    onChange={handleNumberTypeChange}
                    type={type}
                    required={required}
                    multiline={multiline}
                    maxRows={max_lines}
                    rows={max_lines}
                    fullWidth
                    margin="normal"
                    defaultValue={0}
                    disabled={selectedFormFeildsDisable}
                />
            );
        }else{
            return (
                <TextField
                    id={name}
                    name={name}
                    label={label}
                    value={selectedFiledsValue[name] || ''}
                    onChange={handleChange}
                    required={required}
                    fullWidth
                    error={Boolean(formErrors[field.name])}
                    helperText={formErrors[field.name]}
                    multiline={multiline}
                    size={'small'}
                    key={id}
                    inputRef={Boolean(formErrors[field.name]) ? focusOntextfield :''}
                    type={type}
                    rows={max_lines}
                    margin="normal"
                    disabled={selectedFormFeildsDisable}
                />
            );
        }
    };

    const generateDropdownField = (field, index) => {
        const { id, dropDownName, required, options, label } = field;
        
        if(id === 'si7') { // COUNTRY
            return (
                <TextField
                    size={'small'}
                    key={id}
                    select
                    style={{ width: '100%' }}
                    label={label}
                    name={dropDownName}
                    value={selectedFiledsValue[dropDownName] || ''}
                    error={Boolean(formErrors[dropDownName])}
                    helperText={formErrors[dropDownName]}
                    autoFocus={formErrors[dropDownName]}
                    onChange={(event) =>{
                        handleDropDownChange(event, dropDownName) 
                        handleCountryDropDownChange(event)}
                    }
                    placeholder={label}
                    margin="normal"
                    disabled={selectedFormFeildsDisable}
                    required={required}>
                        {
                            options.map((fieldOption)=>{
                               return (<MenuItem value={fieldOption.t_country} id={fieldOption.t_country_iso2} title={fieldOption.t_country} key={fieldOption.t_country_iso2}>{fieldOption.t_country} </MenuItem>)
                            })
                        }
                </TextField>
            );
        } else if (id === 'si9') { // states
            return (
                <TextField
                    size={'small'}
                    key={id}
                    select
                    style={{ width: '100%' }}
                    label={label}
                    name={dropDownName}
                    value={selectedFiledsValue[dropDownName] || ''}
                    SelectProps={{ MenuProps: { disableScrollLock: true}}}
                    error={Boolean(formErrors[dropDownName])}
                    helperText={formErrors[dropDownName]}
                    autoFocus={formErrors[dropDownName]}
                    onChange={(event) =>{
                        handleDropDownChange(event, dropDownName) 
                        setStateMenuItemList(event.target.value)
                    }}
                    margin="normal"
                    disabled={selectedFormFeildsDisable}
                    required={required}>
                        {
                            options.map((fieldOption, index)=>{
                               return (<MenuItem value={fieldOption.value} id={fieldOption.id} title={fieldOption.value} key={index}>{fieldOption.value}</MenuItem>)
                            })
                        }
                </TextField>
            );
        }else if (id === 'si22') { // use_case
            return (
                <TextField
                    size={'small'}
                    key={id}
                    select
                    style={{ width: '100%' }}
                    label={label}
                    name={dropDownName}
                    value={selectedFiledsValue[dropDownName] || ''}
                    SelectProps={{ MenuProps: { disableScrollLock: true}}}
                    error={Boolean(formErrors[dropDownName])}
                    helperText={formErrors[dropDownName]}
                    autoFocus={formErrors[dropDownName]}
                    onChange={handleChange}                    
                    margin="normal"
                    disabled={selectedFormFeildsDisable}
                    required={required}>
                        {
                            options.map((fieldOption, index)=>{
                               return (<MenuItem value={fieldOption} id={fieldOption.id} title={fieldOption.name} key={index}>{fieldOption.name}</MenuItem>)
                            })
                        }
                </TextField>
            );
        } else {
            return (
                <TextField
                    size={'small'}
                    select
                    style={{ width: '100%' }}
                    label={label}
                    title={label}
                    name={dropDownName}
                    placeholder={label}
                    error={Boolean(formErrors[dropDownName])}
                    helperText={formErrors[dropDownName]}
                    autoFocus={formErrors[dropDownName]}
                    value={selectedFiledsValue[dropDownName] || ''}
                    className="Text_select"
                    onChange={handleChange}
                    required={required}
                    disabled={selectedFormFeildsDisable}
                    margin="normal"
                >
                    {options?.map((option, i) => (
                        <MenuItem key={i} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            );
        }
    };

    const generateRadioField = (field, index) => {
        const { id, label, options, radioButtonName } = field;
        
        return (
            <FormControl component="fieldset" key={id} error={Boolean(formErrors[radioButtonName])}>
                <FormLabel component="legend">{label}</FormLabel>
                <RadioGroup
                    aria-label={label}
                    name={radioButtonName}
                    value={selectedFiledsValue[radioButtonName] || ''}
                    onChange={handleChange}
                    autoFocus={formErrors[radioButtonName]} // Autofocus if there's an error
                   
                >
                    {options.map((option, i) => (
                        <FormControlLabel
                            key={i}
                            value={option}
                            disabled={selectedFormFeildsDisable}
                            control={<Radio />}
                            label={option}
                        />
                    ))}
                </RadioGroup>
                <FormHelperText>{formErrors[radioButtonName]}</FormHelperText>
            </FormControl>
        );
    };

    const renderField = (field, index) => {
        const { dataType } = field;
        switch (dataType) {
            case SMS_VERIFICATION.INPUT:
                return generateInputField(field, index);
            case SMS_VERIFICATION.DROPDOWN:
                return generateDropdownField(field, index);
            case SMS_VERIFICATION.RADIOBUTTON:
                return generateRadioField(field, index);
            default:
                return null;
        }
    };

    return (
        <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={1}>
                {jsonData.map((field, index) => (
                    <Grid
                        xs={field.section === 1 || field.section === 21 || field.section === 3 || field.section === 4 ? 6 : 12}
                        key={index}>
                        {renderField(field)}
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default FormFields;
