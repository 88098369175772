import React, { useState, useEffect } from 'react';
import { styles } from '../../../services/constants/styles';
import { OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { Button, Select, FormControl, MenuItem, InputLabel } from '@mui/material';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { isValidParam, getStringParam, getIntParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import * as sfDialogs from '../components/sfDialogs';
import { useDispatch, useSelector } from "react-redux";

const CallScript = ({ }) => {

    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);

    const [showAddScriptContent, setShowAddScriptContent] = useState(false);
    const [isRecordAvailable, setIsRecordAvailable] = useState(false);
    const [id, setId] = useState(0);
    const [scriptName, setScriptName] = useState('');
    const [selectedWorkflow, setSelectedWorkflow] = useState('');
    const [scriptDetailText, setScriptDetailText] = useState('');
    const [workflowList, setWorkflowList] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [recordList, setRecordList] = useState(null);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        loadCallScriptList();
        loadWorkFlowList();
    }, []);

    const addNewScriptDetail = () => {
        let showContent = showAddScriptContent;
        try {
            if (!showContent) {
                showContent = true;
            }
        }
        catch (error) {
            console.error("Error in 'callScript.js -> addNewScriptDetail()':" + error);
        }
        setShowAddScriptContent(showContent);
        setId(0);
        setScriptName('');
        setSelectedWorkflow('');
        setScriptDetailText('');
    }

    const loadCallScriptList = () => {
        let fields = null;
        let records = null;
        let columns = '';
        let params = { sort_field_name: "t_pk", sort_type: "asc" };
        var response = Promise.resolve(HTTPClient.get(endPoints.CALL_SCRIPT.GET_CALL_SCRIPT_LIST, params));
        response.then((response) => {
            if (isValidParam(response)) {
                fields = getArrayParam(response.fields);
                records = getArrayParam(response.records);
            }
        });
    }

    const loadWorkFlowList = () => {
        let object = "accounts";
        let wfList = null;
        let _workflowList = workflowList;
        let params = { table_id: OBJECT_TABLEID_MAP[object] }
        try {
            var result = Promise.resolve(HTTPClient.get(endPoints.CALL_SCRIPT.GET_WORKFLOW_LIST, params));
            result.then((response) => {
                if (isValidParam(response.data)) {
                    wfList = getArrayParam(response.data);
                    _workflowList = wfList.map((wf, i) => {
                        return (
                            <MenuItem value={wf.id} title={wf.name} style={{ ...styles.popoverMenuItem, }}>{wf.name}</MenuItem>
                        );
                    });
                    _workflowList.unshift(<MenuItem value={'-1'} title={''} style={{ ...styles.popoverMenuItem }}> </MenuItem>);
                    setWorkflowList(_workflowList);
                }
            });
        } catch (error) {
            console.log("Error in 'callScript.js -> loadWorkFlowList()':" + error);
        }
    }

    const fnSetScriptName = (event, value) => {
        try {
            if (isValidParam(value)) {
                this.setState({ scriptName: value });
            }
        } catch (error) {
            console.log("Error in 'callScript.js -> fnSetScriptName()':" + error);
        }
    }

    const fnSetSelectedWorkflow = (event) => {
        try {
            let value = event.target.value;
            this.setState({ selectedWorkflow: value });
        } catch (error) {
            console.log("Error in 'callScript.js -> fnSetSelectedWorkflow()':" + error);
        }
    }

    const fnSetScriptDetailText = (event, value) => {
        try {
            if (isValidParam(value)) {
                this.setState({ scriptDetailText: value });
            }
        } catch (error) {
            console.log("Error in 'callScript.js -> fnSetScriptDetailText()':" + error);
        }
    }

    const validateForm = (obj) => {
        let errorMsg = '';
        let isValid = true;
        try {
            if (isValidParam(obj)) {
                if (getStringParam(obj.name).length === 0) {
                    errorMsg = getLocalizedStrings().message.CALL_SCRIPT.BLANK_NAME;
                    isValid = false;
                    setErrorMessage(errorMessage);
                }
                else if (getStringParam(obj.detail).length === 0) {
                    errorMsg = getLocalizedStrings().message.CALL_SCRIPT.BLANK_DETAILS;
                    isValid = false;
                    setErrorMessage(errorMessage);
                }
            }
        } catch (error) {
            console.log("Error in 'callScript.js -> validateForm()':" + error);
        }
        return isValid;
    }

    const saveCallScript = () => {
        let obj = {};
        obj.id = getIntParam(id);
        obj.name = getStringParam(scriptName).trim();
        obj.workflowId = selectedWorkflow > 0 ? getStringParam(selectedWorkflow) : '-1';
        obj.detail = getStringParam(scriptDetailText).trim();
        try {
            if (validateForm(obj)) {
                let params = {};
                params.id = obj.id;
                params.t_script_name = obj.name;
                params.t_workflow_id = obj.workflowId.toString();
                params.t_script_details = obj.detail;

                let response = Promise.resolve(HTTPClient.post(endPoints.CALL_SCRIPT.SAVE, params));
                response.then((response) => {
                    if (isValidParam(response)) {
                        if (response.status === 0) {
                            if (params.id > 0) {
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.CALL_SCRIPT.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            } else {
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.CALL_SCRIPT.SAVE_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            }
                            loadCallScriptList();
                            setShowAddScriptContent(false);
                        } else {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.CALL_SCRIPT[response.error.message], styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        }
                    }
                });
            }
            else {
                if (getStringParam(errorMessage).length > 0) {
                    let errMessage = errorMessage;
                    dispatch(showCustomSnackBar(errMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
            }
        }
        catch (error) {
            console.log("Error in 'callScript.js -> saveCallScript()':" + error);
        }
    }

    const closeForm = () => {
        if (showAddScriptContent) {
            setShowAddScriptContent(false);
        }
    }

    const deleteCallScript = () => {
        let recordId = getIntParam(id);
        if (recordId > 0) {
            let message = getLocalizedStrings().message.CALL_SCRIPT.DELETE_CONFIRMATION;
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, message, () => deleteCallScriptOnConfirm(recordId), null);

        }
    }

    const deleteCallScriptOnConfirm = (recordId) => {
        let params = {};
        params.id = [];
        params.id.push(recordId);
        let response = Promise.resolve(HTTPClient.post(endPoints.CALL_SCRIPT.DELETE, params));
        response.then((response) => {
            if (isValidParam(response.status) && response.status === 0) {
                loadCallScriptList();
                dispatch(showCustomSnackBar(getLocalizedStrings().message.CALL_SCRIPT.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                closeForm();
            }
        });
    }


    let mainContentTopMargin = '0px';
    let workflowTitle = null;
    if (showAddScriptContent && isValidParam(workflowList)) {
        for (let i = 0; i < workflowList.length; i++) {
            if (selectedWorkflow === workflowList[i].props.value) {
                workflowTitle = (workflowList[i].props.primaryText);
                break;
            }
        };
    }
    return (
        <div style={{ marginTop: mainContentTopMargin }}>
            <div id="header" style={{ paddingLeft: '0px', width: '100%', fontSize: '20px', borderBottom: '2.0px solid #afafaf5c' }}>
                <label style={{ color: '#717171' }}>{getLocalizedStrings().label.COMMON.CALL_SCRIPT}</label>
                {!showAddScriptContent && <Button onClick={() => addNewScriptDetail()} style={{ ...styles.primaryButton, marginRight: 0, float: 'right' }} >{getLocalizedStrings().label.COMMON.ADD} </Button>}
            </div>
            {!showAddScriptContent &&
                <div style={{ marginTop: '10px' }}>
                    {mounted && !isRecordAvailable && <div>
                        <span>{getLocalizedStrings().message.CALL_SCRIPT.NO_RECORD}</span>
                    </div>
                    }
                    {mounted && isRecordAvailable && <div className="data-table-container" > {/* style={{ width: '100%', height: '50%', overflow: 'scroll', overflowX: 'auto', overflowY: 'auto' }} */}
                        {recordList}
                    </div>
                    }
                </div>
            }
            {showAddScriptContent && <div style={{ marginTop: '12px' }}>
                <div style={{ width: '49%', float: 'right' }}>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel id="sf-call-script-from-callscript-simple-select-outlined-label" className="sf-call-script-from-callscripts">{getLocalizedStrings().label.CALL_SCRIPT.WORKFLOW_NAME}</InputLabel>
                        <Select
                            key='workflowList'
                            label={getLocalizedStrings().label.CALL_SCRIPT.WORKFLOW_NAME}
                            value={selectedWorkflow}
                            onChange={() => setSelectedWorkflow()}
                            name="workflowList"
                            title={isValidParam(workflowTitle) ? workflowTitle : ''}
                            className={"sf-fields-bg"}>
                            {workflowList}
                        </Select>
                    </FormControl>
                </div>
                <div style={{ float: 'right', marginTop: '12px' }}>
                    <Button onClick={() => saveCallScript()} style={styles.primaryButton}>{getLocalizedStrings().label.COMMON.SAVE} </Button>
                    {showAddScriptContent && getIntParam(id) !== 0 && getIntParam(id) > 0 &&
                        <Button onClick={() => deleteCallScript()} style={styles.secondaryButton}>{getLocalizedStrings().label.COMMON.DELETE}</Button>
                    }
                    <Button onClick={() => closeForm()} style={{ ...styles.secondaryButton, marginRight: 0 }}>{getLocalizedStrings().label.COMMON.CANCEL} </Button>
                </div>
            </div>
            }
        </div>

    );
};

export default CallScript;