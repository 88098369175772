import React, { useState, useEffect } from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { dateFormat, dateFormatFlatPicker } from '../../../services/helper/utils';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';
import PopOver from './PopOver';
import { useDispatch, useSelector } from "react-redux";

const RepeatDialog = ({ object }) => {
    let weekday = new Array(7);
    weekday[0] = "sunday";
    weekday[1] = "monday";
    weekday[2] = "tuesday";
    weekday[3] = "wednesday";
    weekday[4] = "thursday";
    weekday[5] = "friday";
    weekday[6] = "saturday";
    let toDay = new Date();
    let dayName = weekday[toDay.getDay()];

    var _selected_repeat_on_days = new Set();
    _selected_repeat_on_days.add(dayName);

    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);

    const [id, setId] = useState(0);
    const [parent_record_id, setParent_record_id] = useState(0);
    const [repeat_type_options, setRepeat_type_options] = useState([
        {
            label: getLocalizedStrings().label.APPOINTMENTS_TASKS.DAILY,
            value: 'daily'
        },
        {
            label: getLocalizedStrings().label.APPOINTMENTS_TASKS.WEEKLY,
            value: 'weekly'
        },
        {
            label: getLocalizedStrings().label.APPOINTMENTS_TASKS.MONTHLY,
            value: 'monthly'
        },
        {
            label: getLocalizedStrings().label.APPOINTMENTS_TASKS.YEARLY,
            value: 'yearly'
        }
    ]);
    const [repeat_type, setRepeat_type] = useState('daily');
    const [start_date, setStart_date] = useState(moment(appContainer.dialogProps.data.start_date).hours(0).minutes(0).seconds(0).milliseconds(0));
    const [end_date, setEnd_date] = useState('');
    const [repeat_every, setRepeat_every] = useState(1);
    const [repeat_by, setRepeat_by] = useState('dayOfTheMonth');
    const [selected_repeat_on_days, setSelected_repeat_on_days] = useState(_selected_repeat_on_days);
    const [selected_repeat_by_option, setSelected_repeat_by_option] = useState('');

    const [recurring_details, setRecurring_details] = useState(1);
    const [recurring_edit, setRecurring_edit] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [updateFiltre, setupdateFiltre] = useState(false);
    const [name, setName] = useState('');
    const [error, setError] = useState({
        end_date: '',
        repeat_on_days: ''
    });
    const [log, setChangeLog] = useState({parent_record_id:0,repeat_type:'daily',repeat_by:'dayOfTheMonth',selected_repeat_on_days:_selected_repeat_on_days,selected_repeat_by_option:selected_repeat_by_option,repeat_every:1});

    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const validateForm = () => {
        let isValid = true;

        if (log.end_date === '' || log.end_date === 'Invalid date' || log.end_date == undefined) {
            isValid = false;
            let temperror = {...error};
            temperror['end_date'] = getLocalizedStrings().message.REPEAT_DIALOG.END_DATE_BLANK;
            setError({...temperror});
        }
        else {
            let startDateMomentObj = moment(start_date);
            let endDateMomentObj = moment(log.end_date);
            if (endDateMomentObj.diff(startDateMomentObj) <= 0) {
                isValid = false;
                let temperror = error;
                temperror['end_date'] = getLocalizedStrings().message.REPEAT_DIALOG.INVALID_END_DATE
                setError({ ...temperror });

            }
        }

        if (log.repeat_type === 'weekly' && log.selected_repeat_on_days.size === 0) {
            isValid = false;
            let tempError = error;
            tempError['repeat_on_days'] = getLocalizedStrings().message.REPEAT_DIALOG.DAY_CHECKBOX_BLANK
            setError({...tempError});
        }

        return isValid;
    }

    const handleSave = () => {
        if (!validateForm()) {
            return false;
        }
        let templog = log;
        let dateValue = log.end_date;
        let selectedDate = new Date(dateValue);
        const momentDate = moment(selectedDate);
        let temprecurring_details = {};
        temprecurring_details.end_date = momentDate.format("YYYY-MM-DD");
        temprecurring_details.recurring_type = log.repeat_type;
        temprecurring_details.repeat_every = parseInt(repeat_every, 10);
        temprecurring_details.id = id;
        if (log.repeat_type === 'monthly') {
            temprecurring_details.repeat_by = [log.repeat_by];
        } else if (log.repeat_type === 'weekly') {
            temprecurring_details.repeat_by = [...log.selected_repeat_on_days];
        }
        temprecurring_details.parent_record_id = log.parent_record_id;
        temprecurring_details.start_date = start_date.format("YYYY-MM-DD");
        setRecurring_details({...temprecurring_details});
        let obj = { recurring_details: temprecurring_details }
        appContainer.dialogProps.eventName(obj);
    }

    useEffect(() => {

        if (appContainer.dialogProps.data.recurring_enable && !loaded) {
            let recurring_data = appContainer.dialogProps.data.recurring_data;
            setStart_date(moment(recurring_data.start_date));
            let selectedDateValue = new Date(moment(recurring_data.end_date));
            setEnd_date(selectedDateValue);
            setRepeat_type(recurring_data.recurring_type);
            setRepeat_every(recurring_data.repeat_every.toString());
            setRecurring_edit(true);
            setId(recurring_data.id);
            setParent_record_id(recurring_data.parent_record_id)
            if (recurring_data.recurring_type === 'monthly') {
                setRepeat_by(recurring_data.repeat_by[0]);
            } else if (recurring_data.recurring_type === 'weekly') {
                setSelected_repeat_on_days(new Set(recurring_data.repeat_by));
            }
            setLoaded(true);
            let changelog = log;
            changelog.id=recurring_data.id;
            changelog.end_date=selectedDateValue;
            changelog.repeat_type=recurring_data.recurring_type;
            changelog.repeat_every=recurring_data.repeat_every.toString();
            if (recurring_data.recurring_type === 'monthly') {
            changelog.repeat_by=recurring_data.repeat_by[0];
            }else if (recurring_data.recurring_type === 'weekly') {
                changelog.selected_repeat_on_days=new Set(recurring_data.repeat_by);
            }
           
            changelog.parent_record_id = recurring_data.parent_record_id;
            setChangeLog({...changelog});
        }else{
            let _changelog = log;
            setChangeLog({..._changelog});
        }
        // dispatch(setAppDialogActions(getActions()));
    }, [loaded,updateFiltre]);

    const handlRepeatTypeChange = (event, value) => {
        let changelog = log;
        if (value.value === 'weekly') {
            log.selected_repeat_on_days.clear();
            if (!recurring_edit) {
                log.selected_repeat_on_days.add(dayName);
            }

        } else if (value.value === 'monthly') {
            setRepeat_by('dayOfTheMonth');
            changelog.repeat_by = 'dayOfTheMonth';
        }
        setRepeat_type(value.value);
        setRepeat_every(1);
        setLoaded(true);

        setChangeLog({...changelog,repeat_type:value.value,repeat_every:1 });
        
    }

    const handleTextChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        setName(value);

    }

    const handleDateChange = (date) => {
        let dateVal = new Date(date);
        let selectedDate = new Date(dateVal.getFullYear(), dateVal.getMonth(), dateVal.getDate(), dateVal.getHours(), dateVal.getMinutes());
        const momentDate = moment(selectedDate);
        setEnd_date(momentDate.format(dateFormat[app.me.date_format]));
        let changelog = log;
        changelog.end_date=momentDate.format(dateFormat[app.me.date_format]);
        setChangeLog({...changelog});
        setLoaded(true);
    }

    const updateRepeteFilter = (obj) => {
        setSelected_repeat_on_days(obj.repeat_filter.selected_repeat_on_days);
        setRepeat_every(obj.repeat_filter.repeat_every);
        setRepeat_by(obj.repeat_filter.repeat_by);
        let changelog = log;

        changelog.repeat_by = obj.repeat_filter.repeat_by;
        changelog.repeat_every = obj.repeat_filter.repeat_every;
        changelog.selected_repeat_on_days = obj.repeat_filter.selected_repeat_on_days;
        setChangeLog({...changelog});
        
        setError(obj.repeat_filter.error);
        setLoaded(true);
        setupdateFiltre(true);
    }

    const getActions = () => {
        const actions = [
            <Button

                primary={true}
                onClick={()=>handleSave()}
                style={styles.primaryButton}
            >  {getLocalizedStrings().label.COMMON.SAVE}</Button>,
            <Button

                primary={true}
                onClick={handleClose}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,

        ];
        return actions;
    }


    let HHFormat = new RegExp("HH");
    let datePickerOptions = {
        enableTime: false,
        dateFormat: dateFormatFlatPicker[app.me.date_format],
        minuteIncrement: 1,
        static: true,
        time_24hr: HHFormat.test(app.me.date_format) ? true : false
    };
    console.log("error.end_date",error)
    return (
        <>
            <div style={{ paddingLeft: 30, paddingRight: 100 }}>
                <div className="row" style={{ paddingTop: '5px' }} >
                    <div className="col-sm-4">
                        <span style={{ fontWeight: 'bold', color: '#717171', fontSize: 'medium' }}>{getLocalizedStrings().label.APPOINTMENTS_TASKS.START_DATE}</span>
                    </div>
                    <div className="col-sm-8">
                        <div className="row">
                            <input type="text" name="start_date" value={start_date.format(dateFormat[app.me.date_format])} onChange={handleTextChange} style={{ width: '100%', height: '38px', paddingLeft: '10px', fontSize:16 }} disabled />
                        </div>
                    </div>
                </div>
                <div className="row" style={{ paddingTop: '15px' }} >
                    <div className="col-sm-4">
                        <span style={{ fontWeight: 'bold', color: '#717171', fontSize: 'medium' }}>{getLocalizedStrings().label.APPOINTMENTS_TASKS.END_DATE}</span>
                    </div>
                    <div className="col-sm-8">
                        <div className="row">
                            <PopOver
                                id={'date'}
                                key={'date'}
                                btnType={'date'}
                                buttonStyle={{ fontSize: '16px' }}
                                buttonLabel={""}
                                containerStyle={{ lineHeight: 2 }}
                                onChange={(date)=>handleDateChange(date)}
                                value={moment(new Date(log.end_date)).isValid() ? moment(log.end_date).format(dateFormat[app.me.date_format]) : ''}
                                innerLabelStyle={{ padding: "0 2 2 5", fontSize: '15px' }}
                                height={38}
                                options={datePickerOptions}
                            />
                        </div>
                        <div className="row" style={{ fontSize: '12px', color: '#FF0000', paddingTop: '5px' }}>{error.end_date}</div>
                    </div>

                </div>
                <div className="row" style={{ paddingTop: '5px', marginTop: '10px' }} >
                    <div className="col-sm-4">
                        <span style={{ fontWeight: 'bold', color: '#717171', fontSize: 'medium' }}>{getLocalizedStrings().label.APPOINTMENTS_TASKS.REPEAT_TYPE}</span>
                    </div>
                    <div className="col-sm-8">
                        <div className="row">
                            <FormControl style={{ width: '100%', marginTop: '-10px' }} className="test" noValidate autoComplete="off">
                                <Autocomplete
                                    name="repeat_type"
                                    value={repeat_type_options.find(v => v.value === log.repeat_type)}
                                    options={repeat_type_options}
                                    onChange={(event, options) => handlRepeatTypeChange(event, options)}
                                    disableClearable={false}
                                    renderInput={(params) => <TextField {...params}
                                        variant="outlined" margin="dense" 
                                        size='small' className={"sf-fields-bg"} />
                                    }
                                />
                            </FormControl>
                        </div>
                    </div>
                </div>
                <RepeatFilter
                    error={error}
                    repeat_every={log.repeat_every.toString()}
                    repeat_type={log.repeat_type}
                    updateRepeteFilter={updateRepeteFilter}
                    repeat_by={log.repeat_by}
                    selected_repeat_on_days={log.selected_repeat_on_days}
                    selected_repeat_by_option={selected_repeat_by_option}
                    loaded = {loaded}
                />
            </div>
            <div style={{float: 'right'}}>
                    {getActions()}
            </div>
        </>
    );

}



const RepeatFilter = ( props ) => {
    let _repeat_every_options = [];

    for (let i = 1; i <= 30; i++) {
        _repeat_every_options.push({
            label: i.toString(),
            value: i.toString()
        });
    }
    
    let checkboxes = [
        {
            label: 'M',
            value: 'monday',
            name: 'day1'
        },
        {
            label: 'T',
            value: 'tuesday',
            name: 'day2'
        },
        {
            label: 'W',
            value: 'wednesday',
            name: 'day3'
        },
        {
            label: 'T',
            value: 'thursday',
            name: 'day4'
        },
        {
            label: 'F',
            value: 'friday',
            name: 'day5'
        },
        {
            label: 'S',
            value: 'saturday',
            name: 'day6'
        },
        {
            label: 'S',
            value: 'sunday',
            name: 'day7'
        },
    ];
    
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const [repeat_every_options, setRepeat_every_options] = useState(_repeat_every_options);
    const [repeat_by, setRepeat_by] = useState(props.repeat_by);
    const [repeat_every, setRepeat_every] = useState(props.repeat_every);
    const [selected_repeat_on_days, setSelected_repeat_on_days] = useState(props.selected_repeat_on_days);
    const [selected_repeat_by_option, setSelected_repeat_by_option] = useState(props.selected_repeat_by_option);
    const [error, setError] = useState(props.error);
    const [repeatData, setrepeatData] = useState({});

    const handlRepeatEveryChange = (event, value) => {
        setRepeat_every(value.value)
        let tempObj = {
            repeat_every_options: repeat_every_options,
            repeat_by: props.repeat_by,
            repeat_every: value.value,
            selected_repeat_on_days: props.selected_repeat_on_days,
            repeat_every_options: props.repeat_every_options,
            error: props.error,
        }
        setrepeatData({...tempObj});
        props.updateRepeteFilter({ repeat_filter: tempObj });
    }

    const handleCheckBox = (e, isInputChecked) => {
        let value = e.target.value;
        if (isInputChecked) {
            if (!props.selected_repeat_on_days.has(value)) {
                props.selected_repeat_on_days.add(value);
            }
        }
        else {
            if (props.selected_repeat_on_days.has(value)) {
              props.selected_repeat_on_days.delete(value)
            }
        }
        let tempError = error;
        tempError['repeat_on_days'] = '';
        setError(tempError);
        let tempObj = {
            repeat_every_options: repeat_every_options,
            repeat_by: props.repeat_by,
            repeat_every: props.repeat_every,
            selected_repeat_on_days: props.selected_repeat_on_days,
            repeat_every_options: props.repeat_every_options,
            error: props.error,
        }
        setrepeatData({...tempObj});
        props.updateRepeteFilter({ repeat_filter: tempObj });

    }

    const changeRadioButton = (event, value) => {
        setRepeat_by(value);
        let tempObj = {
            repeat_every_options: repeat_every_options,
            repeat_by: value,
            repeat_every: props.repeat_every,
            selected_repeat_on_days: props.selected_repeat_on_days,
            repeat_every_options: props.repeat_every_options,
            error: props.error,
        }
        setrepeatData({...tempObj});
        props.updateRepeteFilter({ repeat_filter: tempObj });

    }

    let repeat_label = '';
    let repeat_every_label = '';
    if (props.repeat_type === 'weekly') {
        repeat_label = getLocalizedStrings().label.APPOINTMENTS_TASKS.REPEAT_ON;
        repeat_every_label = getLocalizedStrings().label.APPOINTMENTS_TASKS.WEEKS;

    } else if (props.repeat_type === 'monthly') {
        repeat_label = getLocalizedStrings().label.APPOINTMENTS_TASKS.REPEAT_BY;
        repeat_every_label = getLocalizedStrings().label.APPOINTMENTS_TASKS.MONTHS;;
    } else if (props.repeat_type === 'daily') {
        repeat_every_label = getLocalizedStrings().label.APPOINTMENTS_TASKS.DAYS_S;
    } else if (props.repeat_type === 'yearly') {
        repeat_every_label = getLocalizedStrings().label.APPOINTMENTS_TASKS.YEARS;;
    }

    let _repeat_every;
    let _selected_repeat_on_days;
    let _repeat_by;

    if (Object.keys(repeatData).length == 0) {
        _repeat_every = props.repeat_every;
        _selected_repeat_on_days = props.selected_repeat_on_days;
        _repeat_by = props.repeat_by;
    } else {
        _repeat_every = repeatData.repeat_every;
        _selected_repeat_on_days = repeatData.selected_repeat_on_days
        _repeat_by = repeatData.repeat_by
    }
    
    return (
        <div>
            <div className="row" style={{ paddingTop: '5px', marginTop: '10px' }} >
                <div className="col-sm-4">
                    <span style={{ fontWeight: 'bold', color: '#717171', fontSize: 'medium' }}>{getLocalizedStrings().label.APPOINTMENTS_TASKS.REPEAT_EVERY}</span>
                </div>
                <div className="col-sm-6" style={{ marginTop: '-10px' }}>
                    <div className="row">
                        {repeat_every_options.length > 0 && <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <Autocomplete
                                name="repeat_every"
                                value={repeat_every_options.find(v => v.value === _repeat_every)}
                                options={repeat_every_options}
                                onChange={(event, options) =>handlRepeatEveryChange(event, options)}
                                disableClearable={false}
                                renderInput={(params) => <TextField {...params}
                                    variant="outlined" margin="dense" size='small' className={"sf-fields-bg"} />
                                }
                            />
                        </FormControl>
                        }
                    </div>
                </div>
                <div className="col-sm-2">
                    <div style={{ paddingTop: '7px' }}>{repeat_every_label}</div>
                </div>
            </div>
            <div className="row" style={{ paddingTop: '5px' }} >
                <div className="col-sm-4">
                    <span style={{ fontWeight: 'bold', color: '#717171', fontSize: 'medium' }}>{repeat_label}</span>
                </div>
                <div className="col-sm-8" style={{ marginTop: '-8px' }}>
                    {
                        props.repeat_type === 'weekly' &&
                        <div>
                            <div className="row" style={{ display: 'flex' }}>
                                {checkboxes.map((obj, index) => {
                                    let style = {};
                                    if (index === 0) {
                                        style = { width: '13%', float: 'left' };
                                    } else {
                                        style = { width: '13%', float: 'left'};
                                    }
                                    return (
                                        <FormControlLabel
                                            control={<Checkbox
                                                color="default"
                                                key={index}
                                                name={obj.name}
                                                value={obj.value}
                                                onChange={handleCheckBox}
                                                checked={_selected_repeat_on_days.has(obj.value)} />}
                                            label={obj.label}
                                            labelposition="right"
                                            style={{ width: '18px', marginRight: '5px', ...style }}
                                            labelstyle={{ color: '#717171', padding: '0px', width: 'auto', fontWeight: 'normal', fontSize: '14px' }}
                                            title={obj.label} />
                                    );
                                })}
                            </div>
                            <div className="row" style={{ fontSize: '12px', color: '#FF0000' }}>
                                {error.repeat_on_days}
                            </div>
                        </div>
                    }
                    {
                        props.repeat_type === 'monthly' &&
                        <div>

                            <FormControl component="fieldset">
                                <RadioGroup name="repeatBy" onChange={changeRadioButton} defaultValue={_repeat_by} style={{ display: 'flex', flexDirection: 'row' }}>
                                    <FormControlLabel value="dayOfTheMonth" control={<Radio color="default" />} label={getLocalizedStrings().label.APPOINTMENTS_TASKS.DAY_OF_THE_MONTH} />
                                    <FormControlLabel value="dayOfTheWeek" control={<Radio color="default" />} label={getLocalizedStrings().label.APPOINTMENTS_TASKS.DAY_OF_THE_WEEK} />

                                </RadioGroup>
                            </FormControl>

                        </div>
                    }
                </div>
            </div>
        </div>

    );

}

export default RepeatDialog;