import { actions } from '..//constants/actions';
import { getArrayParam, getBooleanParam, getIntParam } from '..//helper/parameterVerifier';

const notificationInitialState = {
    notificationInfo: {},
    notificationAccess:{}
}

const notificationReducer = (state = notificationInitialState, action) => {

    switch (action.type) {

        case actions.NOTIFICATION_INFO_GET_FULFILLED:
            return {
                ...state,
                notificationInfo: appendNotificationInfo(state.notificationInfo, action.payload)
            }
        case actions.NOTIFICATION_INFO_UPDATE:
            return {
                ...state,
                notificationInfo: action.payload
            }
        case actions.NOTIFICATION_ACCESS_UPDATE:
            return {
                ...state,
                notificationAccess: action.payload
            }
        default:
            return state;
    }
}

const appendNotificationInfo = (notificationInfo, newNotificationInfo) => {

    let isViewed = getBooleanParam(notificationInfo.isViewed);
    let count = 0;
    let notificationDisplayCount = getIntParam(notificationInfo.notificationDisplayCount);
    //fetch old tasks and appointments
    let oldRemindersList = getArrayParam(notificationInfo.remindersList);
    //fetch new tasks and appointments
    let newRemindersList = getArrayParam(newNotificationInfo.remindersList);
    //join new tasks and appointments
    oldRemindersList = oldRemindersList.concat(newRemindersList);

    if (isViewed) {
        isViewed = false;
        count = newRemindersList.length;
    } else {
        count = (oldRemindersList.length - notificationDisplayCount);
    }
    notificationInfo = { remindersList: oldRemindersList, count: count, isViewed: isViewed, notificationDisplayCount: notificationDisplayCount };
    return notificationInfo;
}

export default notificationReducer;