import { actions } from "../constants/actions";

const initialState = {
    workFlows: [],
    workflowValue: '',
    stages: [],
    stagesValue: [],
    tableData: [],
    msg: '',
    showTable: false,
    initialLoad: false,
    totalData: {},
    sortable: false,
    currentColumn: '',
    tableHeaders: [],
    stagesValueError: false,
}
const salesPipelineReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_PIPE_LINE_DATA:
            return {...state, ...action.payload};

        case actions.SET_WORKFLOWS:
            return {...state, workFlows: action.payload,workflowValue:action.payload[0]?.workflow_name};
        case actions.SET_WORKFLOW_VALUE:
            return {...state, workflowValue: action.payload};

        case actions.SET_STAGES:
            return {...state, stages: action.payload};

        case actions.SET_STAGE_VALUE:
            return {...state, stagesValue: action.payload};

        case actions.SET_TABLE_DATA:
            const { tableHeaders, tableData,showTable ,totalData} = action.payload;
            return {...state, tableHeaders, tableData,showTable,totalData};

        case actions.SET_SORTABLE:
            const { sortable, currentColumn } = action.payload;
            return {...state, sortable, currentColumn };

        case actions.SORTED_DATA:
            return {...state, tableData: action.payload};

        case actions.SET_STAGES_VALUES_ERROR:
            return {...state, stagesValueError: action.payload};

        case actions.SET_INITIAL_MSG:
            return {...state, msg: action.payload};
        default:
            return state;
    }
}

export default salesPipelineReportReducer;
