import store from '../../../src/services/store';
import { constants } from '..//constants/constants';
import { endPoints } from '..//constants/endPoints';
import { actions } from '..//constants/actions';
import * as HTTPClient from '..//helper/httpClient';
import { isValidParam} from '..//helper/parameterVerifier';

export function getTimelineData(object, params) {
    return dispatch => {
        store.dispatch({ type: actions.GET_TIMELINE_START, timelineView: [], isFetching: false, object });
        var promise = Promise.resolve(getTimeline(object, params));
        if (isValidParam(promise)) {
            promise.then((response) => {
                let data = response.data;
                dispatch({
                    type: actions.GET_TIMELINE,
                    payload: {
                        fields: data.fields,
                        records: data.records,
                        sort_field_name: data.sort_field_name,
                        sort_type: data.sort_type,
                        mounted: true,
                        isFetching: true,
                        queryName: params.query_name,
                        queryType: params.query_type,
                        totalCount: data.total_count,
                        object: object,
                        view: 'TimelineView',
                        isFilterChanged: true,
                        actions: data.actions,
                        groupByFieldName: data.groupByFieldName,
                    }
                });
            });
        }
    };
}

export function setTimelineData(object, data) {
    return function(dispatch){
        dispatch({
            type: actions.GET_TIMELINE,
            payload: {
                fields: data.fields,
                records: data.records,
                sort_field_name: data.sort_field_name,
                sort_type: data.sort_type,
                mounted: true,
                isFetching: true,
                // queryName: params.query_name,
                // queryType: params.query_type,
                totalCount: data.total_count,
                object: object,
                view: 'TimelineView',
                isFilterChanged: true,
                actions: data.actions,
                groupByFieldName: data.groupByFieldName,
            }
        });
    }
}

export function getTimeline(object, params) {
    let url = null;
    try {
        if (object === constants.PROJECTS_OBJECT) {
            if(params.isDetailView){
                url = endPoints.PROJECTS.TIMELINE_VIEW_TASK_APP;
            }else{
                url = endPoints.PROJECTS.TIMELINE_VIEW;
            }
        }
        return HTTPClient.get(url, params);
    } catch (e) {
        console.error("Error in 'timelineViewActions.js -> getTimeline()':" + e);
    }
}

export function setTimelineViewMounted(isMounted) {
    store.dispatch({
        type: actions.TIMELINE_VIEW_MOUNTED,
        payload: { 
            mounted: isMounted ,
        } 
    });
}

/* export {
    getTimeline,
    getTimelineData,
} */