import React, { useEffect, useState ,useRef} from 'react';
import { useDispatch, useSelector } from "react-redux";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { styles } from '../../../services/constants/styles';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { testMail } from '../../../services/actions/sendMailActions';
import { isValidParam, getStringParam, getObjectParam, isEmail } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';

/**
 * 
 * @Author  : Pradyut.s 
 * @Date:   : 2017-08-31
 */

function TestMail({ data }) {
    const inputRef = useRef(null);
    const [email, setEmail] = useState(null);
    const [sending, setSending] = useState(false);
    const [isSendDisabled, setIsSendDisabled] = useState(false);

    const dispatch = useDispatch();

    const appContainer = useSelector((state) => state.appContainer);
    const app = useSelector((state) => state.app);

    useEffect(() => {
        dispatch(setAppDialogActions(getActions()));
        setEmail( data.frommail);
}, []);



    useEffect(() => {
        let dialogProps = getObjectParam(appContainer.dialogProps);
        if (dialogProps.isOpenDialog && !dialogProps.dialogActions) {
            dispatch(setAppDialogActions(getActions()));

        }
    }, [appContainer]);

    const getActions = () => {
        const actions = [
            <Button
                primary={true}
                onClick={() => sendMail()}
                style={styles.primaryButton}
            > {getLocalizedStrings().label.MAIL.SEND_A_TEST_EMAIL}</Button>,
            <Button

                primary={true}
                onClick={() => dispatch(getAppDialog(false, null, null, null, null, null))}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CLOSE}</Button>,

        ];
        return actions;
    }

    const onChangeSetEmail = (event) => {
        setEmail(event.target.value);
    }

    const sendMail = () => {
        let params = getObjectParam(appContainer.dialogProps.data);
        let testMailType = params.testMailType;
        let isAurinkoReAuthRequired = false;
        let isSendMailUsingAurinko = false;
        if(testMailType!= null && testMailType!="" && testMailType == 'sales') {
            isAurinkoReAuthRequired = app.me.is_aurinko_reauth_required;
            isSendMailUsingAurinko = app.me.is_send_mail_using_aurinko; 
        }
        if (isSendMailUsingAurinko && isAurinkoReAuthRequired) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MY_INTEGRATION.RE_AUTHENTICATE_AURINKO, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else {
            if (!isSendDisabled) {
                setIsSendDisabled(true);
                let value = inputRef.current.getElementsByTagName("input")['email'].value;
                let _email = getStringParam(value).trim();
                if (_email.length === 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.BLANK_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                } else if (!isEmail(_email)) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.INVALID_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                } else {
                    let params = getObjectParam(appContainer.dialogProps.data);
                    params.email = _email;
                    params.subject = "[" + getLocalizedStrings().message.COMMON.TEST + "] " + params.subject;
                    params.testMailType = params.testMailType;
                    params.templateBody = params.templateBody;
                    var promise = testMail(params);
                    setSending(true);
                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            if (isValidParam(response) && response.hasOwnProperty('status')) {
                                if (response.status === 0) {
                                    setSending(false);
                                    dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                    dispatch(getAppDialog(false, null, null, null, null, null));
                                } else if (response.status > 0) {
                                    setSending(false);
                                    dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                } else {
                                    setSending(false);
                                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                }
                            }
                        });
                    } else {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                }
            }
        }
    }

    return (
        <div className='row'>
            <div className="col-sm-12 col-two">
                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        key='email'
                        name='email'
                        value={email}
                        ref={inputRef}
                        onChange={(e) => onChangeSetEmail(e)}
                        underlineFocusStyle={styles.textField.underlineFocus}
                        label={getLocalizedStrings().label.MAIL.EMAIL_FOR_TESTING}
                        fullWidth={true}
                        className={"sf-fields-bg"}
                        size= 'small'
                        autoComplete="new-password"
                        margin='dense'
                    /></FormControl>
            </div>
            <div className="col-sm-12 col-two">
                {sending && <span style={{ display: 'inline-block', fontSize: 15, marginTop: '10px', color: '#717171', fontWeight: '600', paddingLeft: 2 }}>{getLocalizedStrings().label.MAIL.SENDING_TEST_MAIL} </span>}
            </div>
        </div>
    )

}
export default TestMail;