import { actions } from '..//constants/actions'

const fieldsInitialState = {
    mounted: false,
    files: {}
}

const attachmentUploadReducer = (state = fieldsInitialState, action) => {
    switch (action.type) {
        case actions.ATTACHMENT_UPLOAD_GET_FULLFILLED:
            return {
                ...state,
                mounted: true,
                files: action.payload
            }
        default:
            return state;
    }
}
export default attachmentUploadReducer;