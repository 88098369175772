import React, { useState, useEffect,useCallback } from 'react';
import { connect } from 'react-redux';
import { constants } from '../../../services/constants/constants';
import { Select, MenuItem, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styles } from '../../../services/constants/styles';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { isValidParam, getIntParam, getArrayParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { alert } from '../components/sfDialogs';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import concat from 'lodash/concat';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { getSchedulerWorkflowList } from '../../../services/actions/schedulerActions';
import { getWorkflowStageById } from '../../../services/actions/importAction';
import { useDispatch, useSelector } from "react-redux";

const SchedulerIntakeForm = ({ setIntakeFormInfo, intakeFormInfo, errorFlag }) => {
    const [fieldList, setFieldList] = useState([]);
    const [defaultFormFiels, setDefaultFormFiels] = useState([]);
    const [customFormFiels, setCustomFormFiels] = useState([]);
    const [availabeFields, setAvailabeFields] = useState([]);
    const [bookingButtonLabel, setBookingButtonLabel] = useState('Schedule My Meeting');
    const [thankYouMsg, setThankYouMsg] = useState('Your meeting is confirmed. I look forward to speaking with you.');
    const [formFieldsInfo, setFormFieldsInfo] = useState({});
    const [workflowList, setWorkflowList] = useState([]);
    const [selectedWorkflow, setSelectedWorkflow] = useState('');
    const [changeLog, setChangeLog] = useState({ formFieldsInfo: {} });
    const [workflowStatusList, setWorkflowStatusList] = useState(null);
    const [defaultSelectedStatus, setDefaultSelectedStatus] = useState('');
    const [wfSetupKey, setWfSetupKey] = useState([
        { key: 1,workflowSetUpCStatusList:[],workflowSetUpNStatusList:[]},
        { key: 2,workflowSetUpCStatusList:[],workflowSetUpNStatusList:[]},
        { key: 3,workflowSetUpCStatusList:[],workflowSetUpNStatusList:[]},
        { key: 4,workflowSetUpCStatusList:[],workflowSetUpNStatusList:[]},
        { key: 5,workflowSetUpCStatusList:[],workflowSetUpNStatusList:[]}]);
    const [wf_setup_details, setWfSetupDetails] = useState({});
    const [, updateNState] = useState();
	const handleForceupdateMethod = useCallback(() => updateNState({}), []);
    let app = useSelector((state) => state.app);
    let appContainer = useSelector((state) => state.appContainer);
    let sfForm = useSelector((state) => state.sfForm);
    let schedulerDetails = useSelector((state) => state.schedulerDetails);
    const dispatch = useDispatch();
    useEffect(() => {
        populateFields();
        getWorkflowListByObject(constants.ACCOUNTS_OBJECT);
    }, []);

    const populateFields = () => {
        try {
            let fieldsList = sfForm.data[constants.ACCOUNTS_OBJECT];
            let wfSetupDetails = null;
            let _wfSetupKey =   wfSetupKey;
            let fields = fieldsList.fields;
            fields = fields.filter(f => {
                return (f.field_type !== "header" && f.field_type !== "" && f.field_type !== 'integer'
                    && !f.is_hidden_field && !f.is_readonly && f.name !== "workflow_name" && f.name !== "opportunity_amount" &&
                    f.name !== "sales_rep" && f.name !== "t_type" && f.name !== "lead_score");
            });
            let tempFields = [];
            let availabeFields = fields;
            if (fields.length > 0) {
                let fNameObj = {};
                let lNameObj = {};
                let emailObj = {};
                fields.map((f, i) => {
                    if (f.name === 'first_name') {
                        fNameObj.id = f.id;
                        fNameObj.name = f.name;
                        fNameObj.label = f.label;
                        fNameObj.type = f.field_type_id;
                        fNameObj.isRequire = true;
                        fNameObj.isDelete = false;
                        availabeFields = availabeFields.filter(field => { return f.id !== field.id });
                    }
                    if (f.name === 'last_name') {
                        lNameObj.id = f.id;
                        lNameObj.name = f.name;
                        lNameObj.label = f.label;
                        lNameObj.type = f.field_type_id;
                        lNameObj.isRequire = true;
                        lNameObj.isDelete = false;
                        availabeFields = availabeFields.filter(field => { return f.id !== field.id });
                    }
                    if (f.name === 'email') {
                        emailObj.id = f.id;
                        emailObj.name = f.name;
                        emailObj.label = f.label;
                        emailObj.type = f.field_type_id;
                        emailObj.isRequire = true;
                        emailObj.isDelete = false;
                        availabeFields = availabeFields.filter(field => { return f.id !== field.id });
                    }
                });
                tempFields.push(fNameObj);
                tempFields.push(lNameObj);
                tempFields.push(emailObj);
                sortArrayObjectByProperty(availabeFields, 'label');
            }

            let _formFieldsInfo = {};

            let fieldInfo = getObjectParam(intakeFormInfo);
            if (Object.keys(fieldInfo).length > 0 && (intakeFormInfo.bookingButtonLabel !== '' || intakeFormInfo.thankYouMsg !== '')) {
                _formFieldsInfo = fieldInfo;
            } else {
                _formFieldsInfo = {
                    selectedFields: tempFields,
                    bookingButtonLabel: bookingButtonLabel,
                    thankYouMsg: thankYouMsg,
                    workflow_id: selectedWorkflow,
                    status_name: defaultSelectedStatus,
                };
            }

            let customFields = customFormFiels;
            if (_formFieldsInfo.hasOwnProperty('selectedFields')) {
            let selectedFields = getArrayParam(_formFieldsInfo.selectedFields);
            let tempCustomFields = selectedFields.filter(f => {
                return (f.name !== 'first_name' &&
                    f.name !== 'last_name' &&
                    f.name !== 'email')
            });
            tempCustomFields.map((f, i) => {
                let tempObj = {};
                availabeFields.map((fld, index) => {
                    if (f.name === fld.name) {
                        tempObj.id = fld.id;
                        tempObj.name = f.name;
                        tempObj.label = fld.label;
                        tempObj.type = fld.field_type_id;
                        tempObj.isRequire = f.isRequire;
                    }
                });
                customFields.push(tempObj);
            });
            setCustomFormFiels(customFields);
            setBookingButtonLabel(_formFieldsInfo.bookingButtonLabel);
            setThankYouMsg(_formFieldsInfo.thankYouMsg);
            setSelectedWorkflow(_formFieldsInfo.workflow_id);
            setDefaultSelectedStatus(_formFieldsInfo.status_name);
        }
        if(_formFieldsInfo.hasOwnProperty('wf_setup_details')){
            wfSetupDetails = _formFieldsInfo.wf_setup_details != null && _formFieldsInfo.wf_setup_details != '' ? JSON.parse(_formFieldsInfo.wf_setup_details): wf_setup_details;
          }else{
            wfSetupDetails = wf_setup_details;
          }
        let obj = null;
        let wfSetUpInfo = null;
        _wfSetupKey.map((d, index) => {
            obj = new Object();
            obj.setup_id = d.key;
           

            if (wfSetupDetails.hasOwnProperty(d.key)) {
                wfSetUpInfo = getObjectParam(wfSetupDetails[d.key]);

                if( wfSetUpInfo.cWorkflow != null &&  wfSetUpInfo.cWorkflow >0 && wfSetUpInfo.nWorkflow != null && wfSetUpInfo.nWorkflow> 0){
                    if(wfSetUpInfo.cWorkflow != null &&  wfSetUpInfo.cWorkflow >0){
                        getWorkStagesListByWorkflowId(d.key,wfSetUpInfo.cWorkflow,'current');
                    }
                    if(wfSetUpInfo.nWorkflow != null &&  wfSetUpInfo.nWorkflow >0){
                        getWorkStagesListByWorkflowId(d.key,wfSetUpInfo.nWorkflow,'new');
                    }
                   
                    obj.cWorkflow = wfSetUpInfo.cWorkflow;
                    obj.cStatus = wfSetUpInfo.cStatus;
                    obj.nWorkflow = wfSetUpInfo.nWorkflow;
                    obj.nStatus = wfSetUpInfo.nStatus;
                    obj.nStatusId = wfSetUpInfo.nStatusId;
    
                    wfSetUpInfo = null;
                }else  if( wfSetUpInfo.cWorkflow != null &&  wfSetUpInfo.cWorkflow >0 && wfSetUpInfo.nWorkflow == null ){
                    if(wfSetUpInfo.cWorkflow != null &&  wfSetUpInfo.cWorkflow >0){
                       getWorkStagesListByWorkflowId(d.key,wfSetUpInfo.cWorkflow,'current');
                    }
                   
                   
                    obj.cWorkflow = wfSetUpInfo.cWorkflow;
                    obj.cStatus = wfSetUpInfo.cStatus;
                    obj.nWorkflow = wfSetUpInfo.nWorkflow;
                    obj.nStatus = wfSetUpInfo.nStatus;
                    obj.nStatusId = wfSetUpInfo.nStatusId;
    
                    wfSetUpInfo = null;
                }
                else  if( wfSetUpInfo.cWorkflow == null && wfSetUpInfo.nWorkflow != null && wfSetUpInfo.nWorkflow> 0){
                  
                    if(wfSetUpInfo.nWorkflow != null &&  wfSetUpInfo.nWorkflow >0){
                        getWorkStagesListByWorkflowId(d.key,wfSetUpInfo.nWorkflow,'new');
                    }
                   
                    obj.cWorkflow = wfSetUpInfo.cWorkflow;
                    obj.cStatus = wfSetUpInfo.cStatus;
                    obj.nWorkflow = wfSetUpInfo.nWorkflow;
                    obj.nStatus = wfSetUpInfo.nStatus;
                    obj.nStatusId = wfSetUpInfo.nStatusId;
    
                    wfSetUpInfo = null;
                }
                else{
                    
                obj.cWorkflow = wfSetUpInfo.cWorkflow;
                obj.cStatus = wfSetUpInfo.cStatus;
                obj.nWorkflow = wfSetUpInfo.nWorkflow;
                obj.nStatus = wfSetUpInfo.nStatus;
                obj.nStatusId = wfSetUpInfo.nStatusId;

                wfSetUpInfo = null;
                }
               

            } else {
               
                obj.cWorkflow = null;
                obj.cStatus = null;
                obj.nWorkflow = null;
                obj.nStatus = null;
                obj.nStatusId = null;
            }

            wfSetupDetails[d.key] = obj;

            obj = null;
        });

            setDefaultFormFiels(tempFields);
            setFieldList(fields);
            setAvailabeFields(availabeFields);
            let temp = changeLog;
            temp.formFieldsInfo = _formFieldsInfo;
            setChangeLog({ ...temp });
            setWfSetupDetails(wfSetupDetails);
            setIntakeFormInfo(_formFieldsInfo);
        } catch (error) {
            console.error('Error on SchedulerIntakeForm -> populateFields() ' + error);
        }
    }
   const getWorkStagesListByWorkflowId = (id,workflowId,type) => {
        let _wfSetupKey =   wfSetupKey;
        try {
            let params = {
                workflow_id: workflowId
            }
            let promise = Promise.resolve(getWorkflowStageById(params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                         if(type === 'current'){
                            _wfSetupKey[id-1].workflowSetUpCStatusList = response.stages;
                           }else{
                            _wfSetupKey[id-1].workflowSetUpNStatusList = response.stages;
                           }
                           setWfSetupKey(_wfSetupKey);
                    }
                   
                });
            }
        } catch (error) {
            console.log("Error in 'SchedulerIntakeForm.js -> getWorkStageByWorkflowId()':" + error)
        }
       
    }
    const getWorkflowListByObject = (object) => {
        let url = endPoints.WORKFLOWS.LIST_GET + "/" + object;
        let workflowList = getObjectParam(schedulerDetails.workflowList);
        try {
            if (workflowList.hasOwnProperty(object)) {
                let records = workflowList[object];
                if (records.length > 0) {
                    let fieldInfo = getObjectParam(intakeFormInfo);

                    let tempselectedWorkflow = fieldInfo.workflow_id;
                    if (!isValidParam(tempselectedWorkflow) || tempselectedWorkflow === "") {
                        tempselectedWorkflow = records[0].id == null ?records[1].id:records[0].id
                    }
                    let tempformFieldsInfo = getObjectParam(changeLog.formFieldsInfo);
                    tempformFieldsInfo.workflow_id = tempselectedWorkflow;
                    let isNoneExist = records.filter(e => (e.name == 'NONE'));
                    if(isNoneExist.length === 0){
                        records.unshift({id:null,name:'NONE'});
                    }
                    setWorkflowList(records);
                    setSelectedWorkflow(tempselectedWorkflow);
                    let temp = changeLog;
                    temp.formFieldsInfo = tempformFieldsInfo;
                    setChangeLog({ ...temp });
                    setIntakeFormInfo(tempformFieldsInfo);
                    getWorkStageByWorkflowId(tempselectedWorkflow);
                }
            } else {
                let promise = Promise.resolve(HTTPClient.get(url, null));
                promise.then((response) => {
                    if (isValidParam(response)) {
                        let records = getArrayParam(response);
                        if (records.length > 0) {
                            records = records.filter(e => (e.name != 'Onboarding' && e.name != 'Franchisee'));
                            let workflowListObj = {};
                            workflowListObj[object] = records;
                            dispatch(getSchedulerWorkflowList(workflowListObj));
                            let _selectedWorkflow = records[0].id == null ?records[1].id:records[0].id;
                            let tempformFieldsInfo = getObjectParam(changeLog.formFieldsInfo);
                            tempformFieldsInfo.workflow_id = _selectedWorkflow;
                            let isNoneExist = records.filter(e => (e.name == 'NONE'));
                            if(isNoneExist.length === 0){
                                records.unshift({id:null,name:'NONE'});
                            }
                            records.unshift({id:null,name:'NONE'});
                            setWorkflowList(records);
                            setSelectedWorkflow(_selectedWorkflow);
                            let temp = changeLog;
                            temp.formFieldsInfo = tempformFieldsInfo;
                            setChangeLog({ ...temp });
                            setIntakeFormInfo(tempformFieldsInfo);
                            getWorkStageByWorkflowId(_selectedWorkflow);
                        }
                    }
                });
            }
        } catch (error) {
            console.log("Error in 'SchedulerIntakeForm.js -> getWorkflowListByObject()':" + error)
        }
    }

    const generateWorkflowList = (type) => {
        let tempworkflowList = null;
        let arrayWorkflowList = workflowList;
        if(type != 'setup'){
            arrayWorkflowList = arrayWorkflowList.filter(e => (e.name != 'NONE'));
        }
        try {
            if (isValidParam(arrayWorkflowList)) {
                tempworkflowList = arrayWorkflowList.map((objWorkflow, i) => {
                    return (
                        <MenuItem key={i} id={i} value={objWorkflow.id}  >{objWorkflow.name}</MenuItem>
                    );
                });
            }
        } catch (error) {
            console.log("Error in 'SchedulerIntakeForm.js -> generateWorkflowList()':" + error)
        }
        return tempworkflowList;
    }

    const changeWorkflow = (value) => {
        let tempformFieldsInfo = getObjectParam(changeLog.formFieldsInfo);
        tempformFieldsInfo.workflow_id = value;
        setSelectedWorkflow(value);
        let temp = changeLog;
        temp.formFieldsInfo = tempformFieldsInfo;
        setChangeLog({ ...temp });
        setIntakeFormInfo(tempformFieldsInfo);
        getWorkStageByWorkflowId(value);

    }

    const getWorkStageByWorkflowId = (workflowId) => {
        try {
            let params = {
                workflow_id: workflowId
            }
            let promise = Promise.resolve(getWorkflowStageById(params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        let tempformFieldsInfo = getObjectParam(changeLog.formFieldsInfo);
                        let selectedStatus = response.stages[0].name;
                        let tempStatus = response.stages.filter(f => { return f.name === tempformFieldsInfo.status_name });
                        if (tempStatus.length > 0) {
                            selectedStatus = tempformFieldsInfo.status_name;
                        }

                        tempformFieldsInfo.status_name = selectedStatus;
                        setWorkflowStatusList(response.stages)
                        setDefaultSelectedStatus(selectedStatus);
                        let temp = changeLog;
                        temp.formFieldsInfo = tempformFieldsInfo;
                        setChangeLog({ ...temp });
                        setIntakeFormInfo(tempformFieldsInfo);
                    }
                });
            }
        } catch (error) {
            console.log("Error in 'SchedulerIntakeForm.js -> getWorkStageByWorkflowId()':" + error)
        }
    }

    const generateStatusList = () => {
        let statusList = null;
        let arrayStatusList = workflowStatusList;
        try {
            if (isValidParam(arrayStatusList)) {
                statusList = arrayStatusList.map((objStatus, i) => {
                    return (
                        <MenuItem key={i} id={i} value={objStatus.name}  >{objStatus.name}</MenuItem>
                    );
                });
            }
        } catch (error) {
            console.log("Error in 'SchedulerIntakeForm.js -> generateStatusList()':" + error)
        }
        return statusList;
    }
    const generateSetupStatusList = (id,type) => {
        let statusList = null;
        let _wfSetupKey = wfSetupKey;
        let arrayStatusList ;
        id =  id -1;
        if(type == 'current'){
            arrayStatusList =   _wfSetupKey[id].workflowSetUpCStatusList;
        }else{
            arrayStatusList =   _wfSetupKey[id].workflowSetUpNStatusList;
        }
      
        try {
            if (isValidParam(arrayStatusList)) {
                statusList = arrayStatusList.map((objStatus, i) => {
                    return (
                        <MenuItem key={i} id={i} value={objStatus.name}  >{objStatus.name}</MenuItem>
                    );
                });
            }
        } catch (error) {
            console.log("Error in 'SchedulerIntakeForm.js -> generateSetupStatusList()':" + error)
        }
        return statusList;
    }
    const changeStatus = (value) => {
        let tempformFieldsInfo = getObjectParam(changeLog.formFieldsInfo);
        tempformFieldsInfo.status_name = value;
        setDefaultSelectedStatus(value);
        let temp = changeLog;
        temp.formFieldsInfo = tempformFieldsInfo;
        setChangeLog({ ...temp });
        setIntakeFormInfo(tempformFieldsInfo);
    }

    const setDefaultFields = () => {
        let element = null;
        defaultFormFiels.map((f, i) => {
            let tempElement = (<div>
                <div style={{ display: 'flex', marginBottom: '5px' }}>
                    <div style={{ ...styles.sf_3 }}>
                        <span style={{ width: '100%', display: 'inline-block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={f.label}>{f.label}</span>
                    </div>
                    <div style={{ ...styles.sf_4 }}>
                        <div style={{ width: '8%', float: 'left', marginTop: '-8px' }}>
                            <FormControlLabel
                                control={<Checkbox
                                    color="primary"
                                    value={f.isRequire}
                                    disabled
                                    checked
                                />}
                                labelposition="left"
                                labelstyle={{ color: '#717171', padding: '0px', width: 'auto', fontWeight: 'normal' }}
                                style={{ height: 'auto' }}
                                title={getLocalizedStrings().label.SCHEDULER.REQUIRED} />

                        </div>
                        <div style={{ paddingLeft: '30px', marginTop: '0px' }}>{getLocalizedStrings().label.SCHEDULER.REQUIRED}</div>
                    </div>
                </div>
            </div>);
            element = <div>{element} {tempElement}</div>
        });
        return element;
    }

    const changeTextFieldValue = (event) => {
        let formFieldInfo = changeLog.formFieldsInfo;
        if (event.target.name === 'buttonlabel') {
            if (formFieldInfo.hasOwnProperty('error')) {
                delete formFieldInfo.error;
            }
            formFieldInfo.bookingButtonLabel = event.target.value;
            setBookingButtonLabel(event.target.value);
            let temp = changeLog;
            temp.formFieldsInfo = formFieldInfo;
            setChangeLog({ ...temp });
        } else if (event.target.name === 'thankyoumsg') {
            formFieldInfo.thankYouMsg = event.target.value;
            setThankYouMsg(event.target.value);
            let temp = changeLog;
            temp.formFieldsInfo = formFieldInfo;
            setChangeLog({ ...temp });
        }

        setIntakeFormInfo(formFieldInfo);
    }

    const addFormField = () => {
        try {
            let tempcustomFormFiels = customFormFiels;
            let tempavailabeFields = availabeFields;
            let isSelected = false;
            let tempField = null;
            if (tempcustomFormFiels.length === 0) {
                tempField = tempavailabeFields[0];
            } else {
                let tempList = tempavailabeFields.filter(f => {
                    let ele = tempcustomFormFiels.find(e => {
                        return e.id === f.id;
                    });
                    if (isValidParam(ele)) {
                        isSelected = true;
                    } else {
                        isSelected = false;
                    }
                    if (!isSelected) return f;
                });
                tempField = tempList[0];
            }
            if (isValidParam(tempField)) {
                let tempObj = {};
                tempObj.id = tempField.id;
                tempObj.name = tempField.name;
                tempObj.label = tempField.label;
                tempObj.type = tempField.field_type_id;
                tempObj.isRequire = false;
                tempObj.isDelete = true;

                tempcustomFormFiels.push(tempObj);
            }
            let tempformFieldInfo = changeLog.formFieldsInfo;

            let tempselectedFields = concat(defaultFormFiels, tempcustomFormFiels);

            setCustomFormFiels(tempcustomFormFiels);
            let temp = changeLog;
            temp.formFieldsInfo = { ...tempformFieldInfo, selectedFields: tempselectedFields };
            setChangeLog({ ...temp });

            setIntakeFormInfo(tempformFieldInfo);
        } catch (error) {
            console.error('Error on schedulerIntakeForm => addFormField() ' + error);
        }
    }

    const changeField = (key, fldId) => {
        try {
            key = getIntParam(key);
            let tempcustomFormFiels = customFormFiels;
            let tempavailabeFields = availabeFields;
            let selectedField = tempavailabeFields.filter(f => { return f.id === fldId });
            let customSelectc = tempcustomFormFiels.filter(f => { return f.id === fldId });
            let isSelected = false;
            if (customSelectc.length > 0) {
                isSelected = true;
            }
            let tempArray = [];
            if (!isSelected) {
                tempcustomFormFiels.map((fld, i) => {
                    let tempObj = {};
                    tempObj = fld;
                    if (i === key) {
                        tempObj = selectedField[0];
                        tempObj.id = selectedField[0].id;
                        tempObj.name = selectedField[0].name;
                        tempObj.label = selectedField[0].label;
                        tempObj.type = selectedField[0].field_type_id;
                        tempObj.isRequire = fld.isRequire;
                        tempObj.isDelete = fld.isDelete;
                    }
                    tempArray.push(tempObj);
                });
                let formFieldInfo = changeLog.formFieldsInfo;
                formFieldInfo.selectedFields = concat(defaultFormFiels, tempArray);
                setCustomFormFiels(tempArray);
                let temp = changeLog;
                temp.formFieldsInfo = formFieldInfo;
                setChangeLog({ ...temp });
                setIntakeFormInfo(formFieldInfo);
            } else {
                alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.MULTIPLE_TIME_SELECTED_MSG_1 + ' ' + selectedField[0].label + '  multiple times. Please remove the duplicate rows. ');
                return false;
            }
        } catch (error) {
            console.error('Error on schedulerIntakeForm => changeField() ' + error);
        }
    }

    const setAdditionalFields = () => {
        try {
            let tempcustomFormFiels = customFormFiels;
            let tempavailabeFields = availabeFields;
            let customFieldRows = [];
            let keyCount = 0;
            if (tempcustomFormFiels.length > 0) {
                tempcustomFormFiels.map((fld, key) => {
                    ++keyCount;
                    let listComponent = [];
                    tempavailabeFields.map((fld, i) => {
                        let menuList = <MenuItem
                            onClick={() => changeField(key, fld.id)}
                            value={fld.id}
                            style={{ ...styles.popoverMenuItem }}
                            className='menuItemIntakeFields'
                        >{fld.label}</MenuItem>
                        listComponent.push(menuList);
                    });
                    customFieldRows.push(
                        <div key={"1_" + key} style={{ ...styles.row }}>
                            <div key={"2_" + key} style={{ width: '24%' }}>
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <InputLabel id="sf-schedulerintakeform-simple-select-outlined-label">{null}</InputLabel>
                                    <Select
                                        key={"3_" + key}
                                        value={fld.id}
                                        title={fld.label}
                                        style={{ height: '40px', marginBottom: '10px' }}
                                    >
                                        {listComponent}
                                    </Select>
                                </FormControl>
                                <input key={'input_hidden_' + keyCount} type="hidden" id={"field_name_" + keyCount} value={key} />
                            </div>
                            <div style={{ ...styles.sf_2, marginLeft: '15px' }}>
                                <div style={{ width: '8%', float: 'left' }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            color="default"
                                            checked={fld.isRequire}
                                            value={fld.isRequire}
                                            onChange={(e) => handleRepeatCheckBox(key, e)}
                                        />}
                                        labelposition="left"
                                        labelstyle={{ color: '#717171', padding: '0px', width: 'auto', fontWeight: 'normal' }}
                                        style={{ height: 'auto' }}
                                        title={getLocalizedStrings().label.SCHEDULER.REQUIRED} />

                                </div>
                                <div style={{ paddingLeft: '30px', marginTop: '9px' }}>{getLocalizedStrings().label.SCHEDULER.REQUIRED}</div>
                            </div>

                            <div key={'cross_icon_div_' + keyCount} style={{ ...styles.sf_1, paddingTop: '14px' }}>
                                <i id={key}
                                    key={'cross_icon_' + keyCount}
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                    style={{ cursor: 'pointer', fontSize: '14px' }}
                                    onClick={() => removeField(key)}
                                    title={getLocalizedStrings().label.COMMON.DELETE}
                                ></i>
                            </div>
                        </div>
                    );
                })
            }
            return customFieldRows;
        } catch (error) {
            console.error('Error on schedulerIntakeForm => setAdditionalFields() ' + error);
        }
    }

    const removeField = (key) => {
        key = getIntParam(key);
        let tempcustomFormFiels = customFormFiels;
        let removeField = tempcustomFormFiels[key];
        tempcustomFormFiels = tempcustomFormFiels.filter(f => {
            return f.id !== removeField.id;
        });
        let formFieldInfo = changeLog.formFieldsInfo;
        formFieldInfo.selectedFields = concat(defaultFormFiels, tempcustomFormFiels);
        setCustomFormFiels(tempcustomFormFiels);
        let temp = changeLog;
        temp.formFieldsInfo = formFieldInfo;
        setChangeLog({ ...temp });
        setIntakeFormInfo(formFieldInfo);
    }

    const handleRepeatCheckBox = (key, event) => {
        key = getIntParam(key);
        let tempcustomFormFiels = customFormFiels;
        let tempArray = [];
        tempcustomFormFiels.map((fld, i) => {
            let tempObj = {};
            tempObj = fld;
            if (i === key) {
                tempObj.isRequire = event.target.checked;
            }
            tempArray.push(tempObj);
        });
        let formFieldInfo = changeLog.formFieldsInfo;
        formFieldInfo.selectedFields = concat(defaultFormFiels, tempArray);
        setCustomFormFiels(tempArray);
        setIntakeFormInfo(formFieldInfo);
    }
    const getWorkStageBySetUpWorkflowId = (workflowId,obj,wfSetupDetails,type,id) => {
        let selectedStatus;
        let _wfSetupKey =  wfSetupKey;
        try {
            if (workflowId != null && workflowId > 0) {
                let params = {
                    workflow_id: workflowId
                }
                let promise = Promise.resolve(getWorkflowStageById(params));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            selectedStatus = response.stages[0].name;
                            if (type === 'current') {
                                obj.cWorkflow = workflowId;
                                obj.cStatus = selectedStatus;
                                _wfSetupKey[id - 1].workflowSetUpCStatusList = response.stages;
                            } else {
                                obj.nWorkflow = workflowId;
                                obj.nStatus = selectedStatus;
                                obj.nStatusId = response.stages[0].id;
                                _wfSetupKey[id - 1].workflowSetUpNStatusList = response.stages;
                            }
                            wfSetupDetails[id] = obj;
                            setWfSetupDetails(wfSetupDetails);
                            setWfSetupKey(_wfSetupKey)
                            handleForceupdateMethod()
                            let _formFieldsInfo = getObjectParam(changeLog.formFieldsInfo);
                            _formFieldsInfo.wf_setup_details = JSON.stringify(wfSetupDetails);
                            let temp = changeLog;
                            temp.formFieldsInfo = _formFieldsInfo;
                            setChangeLog({ ...temp });
                            setIntakeFormInfo(_formFieldsInfo);

                        }

                    });
                }
            }else{
                selectedStatus = null;
                if (type === 'current') {
                    obj.cWorkflow = workflowId;
                    obj.cStatus = selectedStatus;
                    _wfSetupKey[id - 1].workflowSetUpCStatusList = [];
                    obj.nWorkflow = workflowId;
                    obj.nStatus = selectedStatus;
                    obj.nStatusId = null;
                    _wfSetupKey[id - 1].workflowSetUpNStatusList = [];
                } else {
                    obj.cWorkflow = workflowId;
                    obj.cStatus = selectedStatus;
                    _wfSetupKey[id - 1].workflowSetUpCStatusList = [];
                    obj.nWorkflow = workflowId;
                    obj.nStatus = selectedStatus;
                    obj.nStatusId = null;
                    _wfSetupKey[id - 1].workflowSetUpNStatusList = [];
                }
                wfSetupDetails[id] = obj;
                setWfSetupDetails(wfSetupDetails);
                setWfSetupKey(_wfSetupKey)
                handleForceupdateMethod();
                let _formFieldsInfo = getObjectParam(changeLog.formFieldsInfo);
                _formFieldsInfo.wf_setup_details = JSON.stringify(wfSetupDetails);
                let temp = changeLog;
                temp.formFieldsInfo = _formFieldsInfo;
                setChangeLog({ ...temp });
                setIntakeFormInfo(_formFieldsInfo);
            }
        } catch (error) {
            console.log("Error in 'SchedulerIntakeForm.js -> getWorkStageByWorkflowId()':" + error)
        }
       
    }
    const onchangeWorkflowSetup = (type, id, value) => {
        let wfSetupDetails = wf_setup_details;
        let obj = wfSetupDetails[id];

        obj.setup_id = id;
        getWorkStageBySetUpWorkflowId(value,obj,wfSetupDetails,type,id);
    }
    const onchangeStatusSetup = (type, id, value) => {

        let wfSetupDetails = wf_setup_details;
        let obj = wfSetupDetails[id];
        obj.setup_id = id;
        let val = value;
       
        let _wfSetupKey = wfSetupKey;
        let arrayStatusList ;
        if(type == 'current'){
            arrayStatusList =   _wfSetupKey[id-1].workflowSetUpCStatusList;
        }else{
            arrayStatusList =   _wfSetupKey[id-1].workflowSetUpNStatusList;
        }
        let statusArr = arrayStatusList.filter(f => { return f.name == value });
        if (type === 'current') {
            obj.cStatus = val;
        } else {
            obj.nStatus = val;
            obj.nStatusId = statusArr[0].id
        }
           wfSetupDetails[id] = obj;
           setWfSetupDetails(wfSetupDetails);
           handleForceupdateMethod();
            let _formFieldsInfo = getObjectParam(changeLog.formFieldsInfo);
            _formFieldsInfo.wf_setup_details = JSON.stringify(wfSetupDetails);
            let temp = changeLog;
                temp.formFieldsInfo = _formFieldsInfo;
                setChangeLog({ ...temp });
            setIntakeFormInfo(_formFieldsInfo);
    }
  const getWfSetUpFields = ()=>{
      let rows = new Array();
      let _wfSetupKey = wfSetupKey;
      _wfSetupKey.map((item, index) => {
        let wfSetupDetails = wf_setup_details;
        let obj = wfSetupDetails[item.key];

        rows.push(
            <div style={{ display: 'flex', marginTop: 20 }}>
                <span style={{ lineHeight: '2.5em' }}>If</span>
                <div style={{ width: '18%', marginLeft: 10 }} >
  
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel id="sf-schedulerintakeform-from-schedulerintakeform-simple-select-outlined-label" style = {{ marginTop :obj.cWorkflow != null?'': '-5px',background:'white'}} className="sf-schedulerintakeform-from-schedulerintakeform">{getLocalizedStrings().label.COMMON.WORKFLOW}</InputLabel>
                        <Select
                             key={"setup" + item.key}
                            value={obj.cWorkflow}
                            onChange={(e) => onchangeWorkflowSetup('current',item.key,e.target.value)}
                            label={getLocalizedStrings().label.COMMON.WORKFLOW}
                            style={{ height: '40px' }}
                            className={"sf-fields-bg"}>
                            {generateWorkflowList('setup')}
                        </Select>
                    </FormControl>
                </div>
                <span style={{ lineHeight: '2.5em', marginLeft: 7 }}>and</span>
                <div style={{ width: '18%', marginLeft: 7 }} >
  
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel shrink = {obj.cStatus != null? true: false} style = {{ marginTop :obj.cStatus != null?'': '-5px',background:'white'}} id="sf-scheduler-intakeform-from-schedulerintakeform-simple-select-outlined-label" className="sf-scheduler-intakeform-from-schedulerintakeform">{getLocalizedStrings().label.COMMON.STATUS}</InputLabel>
                        <Select
                            key={"setup" + item.key}
                            value={ obj.cStatus}
                            onChange={(e) => onchangeStatusSetup('current',item.key,e.target.value)}
                            label={getLocalizedStrings().label.COMMON.STATUS}
                            style={{ height: '40px' }}
                            className={"sf-fields-bg"}>
                            {generateSetupStatusList(item.key,'current')}
                        </Select>
                    </FormControl>
                </div>
                <span style={{ lineHeight: '2.5em', marginLeft: 7 }}>then convert to</span>
                <div style={{ width: '18%', marginLeft: 7 }} >
  
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel id="sf-schedulerintakeform-from-schedulerintakeform-simple-select-outlined-label" style = {{ marginTop :obj.nWorkflow != null?'': '-5px',background:'white'}} className="sf-schedulerintakeform-from-schedulerintakeform">{getLocalizedStrings().label.COMMON.WORKFLOW}</InputLabel>
                        <Select
                         key={"setup" + item.key}
                            value={ obj.nWorkflow}
                            onChange={(e) => onchangeWorkflowSetup('new',item.key,e.target.value)}
                            label={getLocalizedStrings().label.COMMON.WORKFLOW}
                            style={{ height: '40px' }}
                           
                            className={"sf-fields-bg"}>
                            {generateWorkflowList('setup')}
                        </Select>
                    </FormControl>
                </div>
                <span style={{ lineHeight: '2.5em', marginLeft: 7 }}>and</span>
                <div style={{ width: '18%', marginLeft: 7 }} >
  
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel shrink = {obj.nStatus != null? true: false} style = {{ marginTop :obj.nStatus != null?'': '-5px',background:'white'}}id="sf-scheduler-intakeform-from-schedulerintakeform-simple-select-outlined-label" className="sf-scheduler-intakeform-from-schedulerintakeform">{getLocalizedStrings().label.COMMON.STATUS}</InputLabel>
                        <Select
                         key={"setup" + item.key}
                            value={ obj.nStatus}
                            onChange={(e) => onchangeStatusSetup('new',item.key,e.target.value)}
                            label={getLocalizedStrings().label.COMMON.STATUS}
                            style={{ height: '40px' }}
                            className={"sf-fields-bg"}>
                            {generateSetupStatusList(item.key,'new')}
                        </Select>
                    </FormControl>
                </div>
            </div>
        )
        
      })
      return rows;
  }
    let defaultFields = setDefaultFields();
    let additionalFields = setAdditionalFields()
    let textFldStyle = { backgroundColor: '#fff', height: '42px', paddingLeft: '7px', marginBottom: '20px', width: '100%' };
    let formTexFldStyle = {};
    if (changeLog.formFieldsInfo.hasOwnProperty('error') && changeLog.formFieldsInfo.error === 'submit_button_error') {
        formTexFldStyle = { ...textFldStyle, border: '1px solid #f75723' };
    } else {
        formTexFldStyle = { ...textFldStyle, border: '1px solid rgb(221, 221, 221)' };
    }
    return (
        <div style={{ paddingLeft: '200px', width: '97.5%' }}>
            <div>
                <div id="header" style={{ paddingLeft: '0px', width: '100%', fontSize: '26px', paddingBottom: '12px', marginTop: '30px', marginLeft: '-3px' }}>{getLocalizedStrings().label.SCHEDULER.INTAKE_FORM}</div>
                <div style={{ paddingTop: '5px' }}>
                    <div id="fieldheader" style={{ paddingLeft: '0px', width: '100%', fontSize: '17px', fontWeight: 'bold', paddingBottom: '12px' }}>{getLocalizedStrings().label.SCHEDULER.DEFINE_YOUR_INTAKE_FORM}</div>
                    <div style={{ paddingBottom: 10, marginLeft: '12px' }}>
                        {defaultFields}
                    </div>
                    <div>
                        {additionalFields}
                        {availabeFields.length > customFormFiels.length &&
                            <div style={{ paddingBottom: '30px' }}>
                                <span style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue', marginLeft: '15px' }} onClick={() => addFormField()}>
                                    {getLocalizedStrings().label.SCHEDULER.ADD_ANOTHER_FIELD}
                                </span>
                            </div>}
                    </div>
                </div>
                <div style={{ paddingTop: '25px', ...styles.row }}>
                    <div style={{ width: '65%', marginLeft: '-15px' }}>
                        <FormControl style={{ width: '100%' }} noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                // key={field.id}
                                name='buttonlabel'
                                type='text'
                                multiline={false}
                                label={getLocalizedStrings().label.SCHEDULER.SUBMIT_BUTTON_LABEL}
                                disabled={false}
                                onChange={changeTextFieldValue}
                                value={bookingButtonLabel}
                                inputprops={{ maxLength: 32 }}
                                autoComplete="new-password"
                                margin='dense'
                                size='small'
                            />
                        </FormControl>
                    </div>
                </div>
                <div style={{ paddingTop: '20px' }}>
                    <div style={{ width: '64%', marginLeft: '-5px' }}>
                        <div id="fieldheader" style={{ paddingLeft: '0px', width: '100%', fontSize: '17px', fontWeight: 'bold', paddingBottom: '12px' }}>{getLocalizedStrings().label.SCHEDULER.DEFINE_YOUR_THANK_YOU_MESSAGE}</div>
                        <FormControl style={{ width: '100%' }} noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                // key={field.id}
                                name='thankyoumsg'
                                type='textarea'
                                multiline={true}
                                label={getLocalizedStrings().label.SCHEDULER.THANK_YOU_MESSAGE}
                                textareastyle={{ marginTop: '13px', height: '120px', resize: 'none' }}
                                disabled={false}
                                onChange={changeTextFieldValue}
                                style={{ width: '100%', maxHeight: '150px' }}
                                value={thankYouMsg}
                                autoComplete="new-password"
                            /></FormControl>
                    </div>
                </div>
                {workflowList.length > 1 &&
                    <div  style = {{border: '1px solid #d3d3d3', padding:'1%', margin: '3% 0%',background:'#e3e6e8',marginLeft:'-4px',}}>
                        <div style={{ width: '100%', fontSize: '17px',paddingTop:'7px', fontWeight: 'bold', paddingBottom: '12px', marginLeft: '6px' }}>{getLocalizedStrings().label.SCHEDULER.WORKFLOW_STUP_REULES}</div>
                        <div style={{ marginLeft: '-19px', width: '100%', display: 'flex' }}>
                        <span  style={{ ...styles.sf_3,lineHeight: '2.5em',marginLeft:'22px'}}>If</span>
                            <span style={{ lineHeight: '2.5em',marginLeft:'-22.5%'}}> new Lead then assign</span>
                            <div style={{ ...styles.sf_4, marginLeft: '34px' }}>
                                <FormControl variant="outlined" style={{ width: '111%' }}>
                                    <InputLabel id="sf-schedulerintakeform-from-schedulerintakeform-simple-select-outlined-label">{getLocalizedStrings().label.IMPORT.WORKFLOW}</InputLabel>
                                    <Select
                                        value={selectedWorkflow}
                                        onChange={(e) => changeWorkflow(e.target.value)}
                                        label={getLocalizedStrings().label.IMPORT.WORKFLOW}
                                        style={{ height: '40px' }}>
                                        {generateWorkflowList('required')}
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{ ...styles.sf_4, marginLeft: '50px', paddingRight: '46px' }}>
                                <FormControl variant="outlined" style={{ width: '131%' }}>
                                    <InputLabel id="sf-scheduler-intakeform-from-schedulerintakeform-simple-select-outlined-label" >{getLocalizedStrings().label.IMPORT.STATUS}</InputLabel>
                                    <Select
                                        value={defaultSelectedStatus}
                                        onChange={(e) => changeStatus(e.target.value)}
                                        label={getLocalizedStrings().label.IMPORT.STATUS}
                                        style={{ height: '40px' }}>
                                        {generateStatusList()}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        { getWfSetUpFields()}
                    </div>}
                
            </div>
        </div>
    )
}

    ;



export default (SchedulerIntakeForm);