
import axios from 'axios';
import { constants } from '..//constants/constants';
import * as ParameterVerifier from '..//helper/parameterVerifier';

function post(url, params) {
    return new Promise((resolve, reject) => {
        let HOST = constants.endPoint;
        let token = localStorage.getItem('token');
        if (url !== null && url !== '') {
            url = HOST + url;
            if (token !== null) {
                let imputParams = new URLSearchParams();
                imputParams.append('input_param', JSON.stringify(params));
                axios.post(url, imputParams,
                    {
                        headers: {
                            'dataType': 'jsonp',
                            'contentType': 'application/json; charset=utf-8',
                            'Authentication': JSON.stringify({ token: token })
                        }
                    })
                    .then((resp) => {
                        if (resp.data.status.toString() === constants.STATUS_FAILURE) {
                            if (resp.data.error.message === constants.INVALID_TOKEN) {
                                removeToken();
                            } else {
                                resolve(resp.data);
                            }
                        } else {
                            resolve(resp.data);
                        }
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
        }
    });
}

function get(url, params) {
    url = ParameterVerifier.getStringParam(url);
    if (url !== null && url !== '') {
        return new Promise((resolve, reject) => {
            let HOST = constants.endPoint;
            let token = localStorage.getItem('token');
            url = HOST + url;
            if (token !== null) {
                axios.get(url, {
                    headers: {
                        'dataType': 'jsonp',
                        'contentType': 'application/json; charset=utf-8',
                        'Authentication': JSON.stringify({ token: token })
                    },
                    params: { input_param: params },
                }).then((resp) => {
                    if (resp.data?.status?.toString() === constants.STATUS_FAILURE) {
                        if (resp.data.error.message === constants.INVALID_TOKEN) {
                            removeToken();
                        } else {
                            resolve(resp.data);
                        }
                    } else {
                        resolve(resp.data.data);
                    }
                }).catch(err => {
                    reject(err);
                });
            }
        });
    }
}


function getToken(url, email, password) {
    return new Promise((resolve, reject) => {
        let HOST = constants.endPoint;
        if (url !== null && url !== '') {
            url = HOST + url;
            if (ParameterVerifier.isValidParam(email) && ParameterVerifier.isValidParam(password)) {
                axios.get(url, {
                    headers: {
                        'dataType': 'jsonp',
                        'contentType': 'application/json; charset=utf-8',
                        'Authentication': JSON.stringify({ email: email, password: password })
                    }
                })
                    .then((resp) => {
                        resolve(resp.data);
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
        }
    });
}

function getVideo(url, params) {

    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then((resp) => {
            resolve(resp.data);
        })
            .catch(err => {
                reject(err);
            });
    });

}

function postFormData(url, formData) {
    return new Promise((resolve, reject) => {
        let HOST = constants.endPoint;
        let token = localStorage.getItem('token');
        if (url !== null && url !== '') {
            url = HOST + url;
            if (token !== null) {
                return axios.post(url, formData, {
                    headers: {
                        'dataType': 'jsonp',
                        'contentType': 'application/json; charset=utf-8',
                        'Authentication': JSON.stringify({ token: token })
                    }
                })
                    .then((resp) => {
                        if (resp.data.status.toString() === constants.STATUS_FAILURE) {
                            if (resp.data.error.message === constants.INVALID_TOKEN) {
                                removeToken();
                            } else {
                                resolve(resp);
                            }
                        } else {
                            resolve(resp);
                        }
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
        }
    });
}


function postFormDataDownloadFile(url, formData, fileName) {
    var request = new XMLHttpRequest();
    let HOST = constants.endPoint;
    let token = localStorage.getItem('token');
    if (url !== null && url !== '') {
        url = HOST + url;
        request.open("POST", url);
        let authObject = { token: token };
        request.setRequestHeader("Authentication", JSON.stringify(authObject) /*'{"token":"eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJCdWRkaGFzaXMgTWl0cmEiLCJ1aWQiOjUxOTAsImNyZWF0ZWQiOjE1MDMzOTg2NDU2OTUsImV4cCI6MTUwMzgzMDY0NX0.kV5rtHkf7v-m6K_SZUj7Mc34io4Nh26CuFav-vDebjKvuHvcLVt3-bk6qWbBAw1ScE_0L-1hnJGVzR-m4LsEMQ"}'*/);
        request.responseType = 'blob';
        request.send(formData);
        request.onload = function (e) {

            if (this.status === 200) {

                //var type = request.getResponseHeader('Content-Type');
                let blob = new Blob([this.response], { type: "application/octet-stream" });

                if (ParameterVerifier.isValidParam(blob) && blob.size > 0) {
                    let a = document.createElement("a");
                    a.style = "display: none";
                    document.body.appendChild(a);

                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    // Send File Name used Hare
                    a.download = fileName;
                    a.click();
                    setTimeout(function () {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 100);
                    // window.URL.revokeObjectURL(url);
                }
            } else {
                //deal with your error state here
            }
        }
    };
}

function forgotPassword(url, email) {
    return new Promise((resolve, reject) => {
        let HOST = constants.endPoint;
        if(url!== null && url!== ''){
            url = HOST + url;
            if (ParameterVerifier.isValidParam(email)) {
                axios.get(url, {
                    headers: {
                        'dataType': 'jsonp',
                        'contentType': 'application/json; charset=utf-8',
                        'Authentication': JSON.stringify({ email: email })
                    }
                })
                    .then((resp) => {
                        resolve(resp.data);
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
        }
    });
}

function removeToken(type) {
    try {
        localStorage.removeItem('token');
        localStorage.removeItem('is_franchisor_token');
        localStorage.removeItem('global_franchisor_token');
        if (type !== 'logout') {
            localStorage.removeItem('franchiseAppLoginDetails');
            localStorage.setItem('isSFInvalidate', true);
        }
        if (constants.APP_URL === "") {
            window.location.replace("/");
        } else {
            window.location.replace(constants.APP_URL);
        }
    } catch (error) {
        console.error('httpClient.js -> removeToken:' + error);
    }
}

function getWithoutToken(url, params) {
    url = ParameterVerifier.getStringParam(url);
    if (url!== null && url !== '') {
        return new Promise((resolve, reject) => {
            let HOST = constants.endPoint;
            url = HOST + url;
            axios.get(url, {
                headers: {
                    'dataType': 'jsonp',
                    'contentType': 'application/json; charset=utf-8',
                },
                params: { input_param: params },
            }).then((resp) => {
                if (resp.data.status.toString() === constants.STATUS_FAILURE) {
                    resolve(resp.data);
                } else {
                    resolve(resp.data.data);
                }
            }).catch(err => {
                reject(err);
            });
        });
    }
}

function getFullResponseWithoutToken(url, params) {
    url = ParameterVerifier.getStringParam(url);
    if (url!== null && url !== '') {
        return new Promise((resolve, reject) => {
            let HOST = constants.endPoint;
            url = HOST + url;
            axios.get(url, {
                headers: {
                    'dataType': 'jsonp',
                    'contentType': 'application/json; charset=utf-8',
                },
                params: { input_param: params },
            }).then((resp) => {
                resolve(resp.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
}
function getFullResponse(url, params) {
    url = ParameterVerifier.getStringParam(url);
    if (url !== null && url !== '') {
        return new Promise((resolve, reject) => {
            let HOST = constants.endPoint;
            let token = localStorage.getItem('token');
            url = HOST + url;
            if (token !== null) {
                axios.get(url, {
                    headers: {
                        'dataType': 'jsonp',
                        'contentType': 'application/json; charset=utf-8',
                        'Authentication': JSON.stringify({ token: token })
                    },
                    params: { input_param: params },
                }).then((resp) => {
                    if (resp.data.status.toString() === constants.STATUS_FAILURE) {
                        if (resp.data.error.message === constants.INVALID_TOKEN) {
                            removeToken();
                        } else {
                            resolve(resp.data);
                        }
                    } else {
                        resolve(resp.data);
                    }
                }).catch(err => {
                    reject(err);
                });
            }
        });
    }
}
function postWithoutToken(url, params) {
    return new Promise((resolve, reject) => {
        let HOST = constants.endPoint;
        if (url !== null && url !== '') {
            url = HOST + url;
            let imputParams = new URLSearchParams();
            imputParams.append('input_param', JSON.stringify(params));
            axios.post(url, imputParams,
                {
                    headers: {
                        'dataType': 'jsonp',
                        'contentType': 'application/json; charset=utf-8'
                    }
                })
                .then((resp) => {
                    if (resp.data.status.toString() === constants.STATUS_FAILURE) {
                        resolve(resp.data);
                    } else {
                        resolve(resp.data);
                    }
                })
                .catch(err => {
                    reject(err);
                });
        }
    });
}
function postFormDataWithoutToken(url, formData) {
    return new Promise((resolve, reject) => {
        let HOST = constants.endPoint;
        if (url !== null && url !== '') {
            url = HOST + url;
            return axios.post(url, formData, {
                headers: {
                    'dataType': 'jsonp',
                    'contentType': 'application/json; charset=utf-8'
                }
            })
            .then((resp) => {
                if (resp.data.status.toString() === constants.STATUS_FAILURE) {
                    resolve(resp.data);
                } else {
                    resolve(resp.data);
                }
            })
            .catch(err => {
                reject(err);
            });
        }
    });
}

export { get, post, getToken, postFormData, postFormDataDownloadFile, getFullResponseWithoutToken,getFullResponse, forgotPassword, getVideo, removeToken, getWithoutToken, postWithoutToken, postFormDataWithoutToken }
