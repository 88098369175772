import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
	getMyGroups,
	setGroups,
} from "../../../services/actions/filterMenuAction";
import ShowCircularProgress from "../components/circularProgress";
import { constants } from "../../../services/constants/constants";
import { styles } from "../../../services/constants/styles";
import map from "lodash/map";
import {
	isValidParam,
	getIntParam,
	getStringParam,
	getArrayParam,
} from "../../../services/helper/parameterVerifier";
import {
	addTab,
	TYPE_LIST_VIEW,
	TYPE_DETAIL_VIEW,
} from "../../../services/helper/sfTabManager";
import { LinkToType } from "../../../services/constants/link";
import { deleteAction } from "../../../services/actions/batchActions";
import * as sfDialogs from "../components/sfDialogs";
import { getAppDialog } from "../../../services/actions/appContainerActions";
import { groupUsedInCampaigns } from "../../../services/actions/detailViewActions";
import {
	saveRecentFilter,
	getImageNameByObject,
	hasAccessPermission,
	getRecentFilters,
	getGroups,
} from "../../../services/helper/common";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { getSelectedViewFromCookie } from "../../../services/helper/sfCookies";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import { useDispatch, useSelector } from "react-redux";
function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
	  ref.current = value;
	});
	return ref.current;
  }
const ColumnGroup = ({
	object,
	status,
	search,
	searchText,
	setParentSearchResult,
	updateGroupStatus,
}) => {
	const dispatch = useDispatch();

	const app = useSelector((state) => state.app);
	const tab = useSelector((state) => state.tab);
	const filters = useSelector((state) => state.filters);
	const prevObject = usePrevious(object)

	const [mounted, setMounted] = useState(false);
	const [group, setGroup] = useState([]);
	const [groupRecordCount, setGroupRecordCount] = useState(0);
	const [contentHeight, setContentHeight] = useState(
		document.body.clientHeight - 240
	);
	const [top, setTops] = useState((contentHeight - 10) / 2);

	useEffect(() => {
		if (filters.groupMounted) setGroup(filters.groups);
	}, [filters]);

	useEffect(() => {
		if(object !== prevObject){
			setMounted(false)
			setGroup([])
			getMyGroupsFunc(object);
		} else {
			setGroup(filters.groups)
		}
	}, [object]);

	// UNSAFE_componentWillReceiveProps(nextProps) {
	//     if (nextProps.object !== this.props.object) {
	//         this.getMyGroups(nextProps);
	//     }
	// }

	const getMyGroupsFunc = (obj) => {
		let groups = filters.groups;
		if (!isValidParam(groups) || groups.length === 0) {
			let promise = Promise.resolve(getMyGroups(obj));
			if (isValidParam(promise)) {
				promise.then((response) => {
					if (isValidParam(response)) {
						setMounted(true);
						setGroup(response.groups);
						dispatch(setGroups(response));
						updateGroupStatus();
					}
				});
			}
		} else {
			setMounted(true);
			setGroup(groups);
		}
	};

	const generateGroupNames = (action) => {
		let groupssData = null;
		let filters = Object.assign([], getArrayParam(group));
		let statusString = getStringParam(status);
		if (statusString !== "All") {
			filters = filters.filter((f) => {
				return f.status === statusString;
			});
		}

		let linkToUrl = "/" + object;
		let view = getSelectedViewFromCookie(app.me.projectId, object);

		if (filters !== null && filters !== undefined) {
			let results = [];

			let searchVal = searchText;
			if (searchVal !== undefined && searchVal !== "" && searchVal !== null) {
				for (let i = 0; i < filters.length; i++) {
					if (
						filters[i]["name"].toUpperCase().indexOf(searchVal.toUpperCase()) >
						-1
					) {
						results.push(filters[i]);
					}
				}
			} else {
				results = filters;
			}
			setParentSearchResult(results, "searchResultGorup");

			groupssData = map(results, (filter, i) => {
				if (filter.type === "group") {
					let groupName = filter.name;
					if (groupName === "") {
						groupName = constants.EMPTY;
					}
					return (
						<div
							className="groupList"
							style={{ postion: "relative" }}
							key={filter.type + i}
						>
							<div
								style={{
									overflow: "hidden",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									width: "88%",
									display: "inline-block",
								}}
							>
								<Link
									//to={linkToUrl + '/queries'}
									to={linkToUrl + "/" + view}
									style={{ cursor: "pointer" }}
									onClick={() => {
										action(filter);
									}}
								>
									<span
										style={{
											color: "#ccc",
											fontSize: "14px",
											paddingLeft: "1px",
											paddingRight: "7px",
										}}
									>
										<i className="fa fa-users" aria-hidden="true"></i>
									</span>
									<span className="groupList"
										title={groupName}
										style={{
											fontSize: "14px",
											lineHeight: "32px",
											color: "#717171",
										}}
									>
										{groupName}
									</span>
								</Link>
							</div>

							<div
								className="sf-action-icons"
								style={{ display: "inline-block", verticalAlign: "top" }}
							>
								<div style={{ display: "inline-block", verticalAlign: "top" }}>
									<span
										style={{
											right: "42px",
											cursor: "pointer",
											paddingTop: "11px",
											position: "absolute",
											color: "#717171",
										}}
										onClick={() => showGroupDetails(filter)}
									>
										<i
											key={filter.id}
											className={"fa fa-external-link anchor"}
											style={{ fontSize: "0.9em" }}
											title={getLocalizedStrings().label.HOME.VIEW}
										></i>
									</span>
								</div>
								<div style={{ display: "inline-block", verticalAlign: "top" }}>
									<span
										style={{
											right: "22px",
											cursor: "pointer",
											paddingTop: "9px",
											position: "absolute",
											color: "#717171",
										}}
										onClick={() => deleteGroup(filter)}
									>
										{/* <CloseIcon key={filter.id} style={{ 'fontSize': '1.2em', fontWeight: 'normal', color: '#717171' }} title={getLocalizedStrings().label.HOME.DELETE}></CloseIcon> */}
										{/* <i key={filter.id} className={"fa fa-external-link anchor"} style={{ 'fontSize': '0.9em' }} title={getLocalizedStrings().label.HOME.VIEW}></i> */}
										<i
											key={filter.id}
											className={"fa fa-times anchor"}
											style={{ fontSize: "0.9em" }}
											title={getLocalizedStrings().label.HOME.DELETE}
										></i>
									</span>
								</div>
							</div>
						</div>
					);
				}
			});
		}
		return groupssData;
	};

	const openTab = (filter) => {
		let tab = {
			item: object,
			label: filter.name,
			object: object,
			type: TYPE_LIST_VIEW,
			imgName: getImageNameByObject(object),
			url: "/" + object + "/queries",
			info: { filter: filter },
		};
		addTab(tab, true);
		saveRecentFilter(object, filter);
	};

	const getMoreGroups = () => {
		let params = {
			fetch_size: groupRecordCount,
		};
		getMyGroups(object, params);
	};

	const showGroupDetails = (filter) => {
		try {
			if (isValidParam(filter)) {
				let id = getIntParam(filter.id);
				let name = getStringParam(filter.name);
				let type = getStringParam(filter.type);
				if (id > 0) {
					let url =
						"/" +
						constants.GROUP_OBJECT +
						"/" +
						LinkToType.TYPE_DETAIL_VIEW +
						"/" +
						id;
					let tab = {
						label: name,
						object: constants.GROUP_OBJECT,
						type: TYPE_DETAIL_VIEW,
						imgName: getImageNameByObject(constants.GROUP_OBJECT),
						url: url,
						isActive: true,
					};
					tab.info = { filter: { id: id, name: name, type: type } };
					addTab(tab, true);
					saveRecentFilter(object, filter);
				}
			}
		} catch (error) {
			console.error("Error in 'columnGroup.js -> showGroupDetails()':" + error);
		}
	};

	const deleteGroup = (filter) => {
		let hasPermission = false;
		let msg = "";
		try {
			if (isValidParam(filter)) {
				let id = getIntParam(filter.id);
				if (id > 0) {
					hasPermission = hasAccessPermission(
						constants.GROUP_OBJECT,
						constants.SECURITY_LEVEL_TYPE_ACCESS,
						constants.ACCESS_TYPE_DELETE
					);
					if (hasPermission) {
						let recordIds = [];
						recordIds[0] = id;
						let objParam = {};
						objParam.recordIds = recordIds;
						let promise = groupUsedInCampaigns(objParam);
						let groupAlert = "";
						if (promise !== null) {
							promise.then((response) => {
								if (response !== null && response !== "") {
									groupAlert = dynamicProcessGroupData(response);
									let dialogData = {};
									dialogData.message = groupAlert;
									dialogData.fullWidth = true;
									dialogData.maxWidth = "sm";
									dispatch(getAppDialog(
										true,
										constants.ALERT_DIALOG,
										getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
										null,
										dialogData,
										constants.DETAIL_VIEW
									));
								} else {
									if (id > 0) {
										msg =
											getLocalizedStrings().message.GROUP.GROUP_DELETE_RECORD;
									}
									sfDialogs.confirm(
										getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
										msg,
										() => deleteGroupOnConfirm(id),
										null
									);
								}
							});
						}
					} else {
						sfDialogs.alert(
							getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
							getLocalizedStrings().message.COMMON.ACCESS_DENIED,
							null
						);
					}
				}
			}
		} catch (error) {
			console.error("Error in 'columnGroup.js -> deleteGroup()':" + error);
		}
	};
	const deleteGroupOnConfirm = (id) => {
		let recordIdList = [];
		recordIdList.push(id);
		let param = {
			id: recordIdList,
		};
		let response = deleteAction(constants.GROUP_OBJECT, param);
		response.then((response) => {
			if (response.status === 0) {
				getRecentFilters(constants.ACCOUNTS_OBJECT);
				getRecentFilters(constants.CONTACTS_OBJECT);
				getGroups(constants.ACCOUNTS_OBJECT);
				dispatch(
					showCustomSnackBar(
						getLocalizedStrings().message.COMMON.DELETE,
						styles.snackbarBodyStyleSuccess,
						styles.snackBarStyleTop
					)
				);
			}
		});
	};

	return (
		<div>
			{mounted ? (
				<div style={{ marginLeft: "10px" }}>{generateGroupNames(openTab)}</div>
			) : (
				<div style={{ width: "100%", height: contentHeight }}>
					<div
						className="asset-loaderh"
						style={{ paddingTop: top, paddingLeft: "40%" }}
					>
						<div
							style={{
								...styles.assetLoaderContainer,
								height: 50,
								width: 50,
								padding: 7,
							}}
						>
							<ShowCircularProgress
								size={30}
								style={{ marginTop: "3", marginLeft: "3" }}
							/>
						</div>
					</div>
				</div>
			)}{" "}
		</div>
	);
};

function processGroupData(response) {
	let tcAlert = [],
		tcMsgAlert = "";
	let bcAlert = [],
		bcMsgAlert = "";
	let abAlert = [],
		abMsgAlert = "";
	let webFormAlert = [],
		webFormMsgAlert = "";
	let landingPageAlert = [],
		landingPageMsgAlert = "";
	let message = [];
	let alertMsg = "";

	if (response !== null && response !== "") {
		if (response.touchmsg !== null) {
			tcMsgAlert = response.touchmsg;
		}
		if (response.broadcastmsg !== null) {
			bcMsgAlert = response.broadcastmsg;
		}
		if (response.abmsg !== null) {
			abMsgAlert = response.abmsg;
		}
		if (response.webFormMsg !== null) {
			webFormMsgAlert = response.webFormMsg;
		}
		if (response.landingPageMsg !== null) {
			landingPageMsgAlert = response.landingPageMsg;
		}

		if (
			tcMsgAlert ||
			bcMsgAlert ||
			abMsgAlert ||
			(webFormMsgAlert !== null && webFormMsgAlert !== "") ||
			(landingPageMsgAlert !== null && landingPageMsgAlert !== "")
		) {
			if (tcMsgAlert) {
				for (let i = 0; i < tcMsgAlert.length; i++) {
					let touchArr = tcMsgAlert[i].hasOwnProperty("touch")
						? tcMsgAlert[i]["touch"]
						: null;
					let tcGroup = tcMsgAlert[i].hasOwnProperty("tcGroup")
						? tcMsgAlert[i]["tcGroup"]
						: null;

					if (touchArr !== null && touchArr.length > 0) {
						let tcName = "";
						let messageTC = "";

						for (let j = 0; j < touchArr.length; j++) {
							if (tcName === "") {
								tcName = touchArr[j];
							} else {
								tcName = tcName + ", " + touchArr[j];
							}
						}
						messageTC = (
							<span>
								<b>
									<i>{tcGroup}</i>
								</b>{" "}
								{getLocalizedStrings().message.GROUP.USED_IN_TOUCH_CAMPAIGN}{" "}
								<br />
								<b>
									{tcName}
									<br />
									<br />
								</b>
							</span>
						);
						tcName = "";
						tcAlert.push(messageTC);
					}
				}
			}

			if (bcMsgAlert) {
				for (let i = 0; i < bcMsgAlert.length; i++) {
					let broadcastArr = bcMsgAlert[i].hasOwnProperty("broadcast")
						? bcMsgAlert[i]["broadcast"]
						: null;
					let broadcastGroup = bcMsgAlert[i].hasOwnProperty("bcGroup")
						? bcMsgAlert[i]["bcGroup"]
						: null;

					if (broadcastArr !== null && broadcastArr.length > 0) {
						let bcName = "";
						let messageBC = "";

						for (let j = 0; j < broadcastArr.length; j++) {
							if (bcName === "") {
								bcName = broadcastArr[j];
							} else {
								bcName = bcName + ", " + broadcastArr[j];
							}
						}

						messageBC = (
							<span>
								<b>
									<i>{broadcastGroup}</i>
								</b>{" "}
								{getLocalizedStrings().message.GROUP.USED_IN_BROADCAST_CAMPAIGN}{" "}
								<br />
								<b>
									{bcName}
									<br />
									<br />
								</b>
							</span>
						);
						bcName = "";

						bcAlert.push(messageBC);
					}
				}
			}

			if (abMsgAlert) {
				for (let i = 0; i < abMsgAlert.length; i++) {
					let abArr = abMsgAlert[i].hasOwnProperty("ab")
						? abMsgAlert[i]["ab"]
						: null;
					let abGroup = abMsgAlert[i].hasOwnProperty("abGroup")
						? abMsgAlert[i]["abGroup"]
						: null;

					if (abArr !== null && abArr.length > 0) {
						let abName = "";
						let messageAB = "";

						for (let j = 0; j < abArr.length; j++) {
							if (abName === "") {
								abName = abArr[j];
							} else {
								abName = abName + ", " + abArr[j];
							}
						}

						messageAB = (
							<span>
								<b>
									<i>{abGroup}</i>
								</b>{" "}
								{getLocalizedStrings().message.GROUP.USED_IN_AB_CAMPAIGN} <br />
								<b>
									{abName}
									<br />
									<br />
								</b>
							</span>
						);
						abName = "";

						abAlert.push(messageAB);
					}
				}
			}

			if (webFormMsgAlert) {
				for (let i = 0; i < webFormMsgAlert.length; i++) {
					let webformArr = webFormMsgAlert[i].hasOwnProperty("webForm")
						? webFormMsgAlert[i]["webForm"]
						: null;
					let webformGroup = webFormMsgAlert[i].hasOwnProperty("webFormGroup")
						? webFormMsgAlert[i]["webFormGroup"]
						: null;

					if (webformArr !== null && webformArr.length > 0) {
						let webformName = "";
						let messageWebform = "";

						for (let j = 0; j < webformArr.length; j++) {
							if (webformName === "") {
								webformName = webformArr[j];
							} else {
								webformName = webformName + ", " + webformArr[j];
							}
						}

						messageWebform = (
							<span>
								<b>
									<i>{webformGroup}</i>
								</b>{" "}
								{getLocalizedStrings().message.GROUP.USED_IN_WEBFORM} <br />
								<b>
									{webformName}
									<br />
									<br />
								</b>
							</span>
						);
						webformName = "";
						webFormAlert.push(messageWebform);
					}
				}
			}

			if (landingPageMsgAlert) {
				for (let i = 0; i < landingPageMsgAlert?.length; i++) {
					let landingpageArr = landingPageMsgAlert[i].hasOwnProperty(
						"landingPage"
					)
						? landingPageMsgAlert[i]["landingPage"]
						: null;
					let landingpageGroup = landingPageMsgAlert[i].hasOwnProperty(
						"landingPageGroup"
					)
						? landingPageMsgAlert[i]["landingPageGroup"]
						: null;

					if (landingpageArr !== null && landingpageArr.length > 0) {
						let landingpageName = "";
						let messageLandingpage = "";

						for (let j = 0; j < landingpageArr.length; j++) {
							if (landingpageName === "") {
								landingpageName = landingpageArr[j];
							} else {
								landingpageName = landingpageName + ", " + landingpageArr[j];
							}
						}

						messageLandingpage = (
							<span>
								<b>
									<i>{landingpageGroup}</i>
								</b>{" "}
								{getLocalizedStrings().message.GROUP.USED_IN_LANDINGPAGE} <br />
								<b>
									{landingpageName}
									<br />
									<br />
								</b>
							</span>
						);
						landingpageName = "";
						landingPageAlert.push(messageLandingpage);
					}
				}
			}
		}

		if (tcAlert !== null) {
			message.push(tcAlert);
		}

		if (bcAlert !== null) {
			message.push(bcAlert);
		}

		if (abAlert !== null) {
			message.push(abAlert);
		}
		if (webFormAlert !== null) {
			message.push(webFormAlert);
		}

		if (landingPageAlert !== null) {
			message.push(landingPageAlert);
		}

		alertMsg = (
			<div style={{ overflow: "auto", maxHeight: "400px", fontSize: "16px" }}>
				<b>{getLocalizedStrings().message.GROUP.DELETE_MSG} :</b>
				<br />
				<br />
				{message}
			</div>
		);
		return alertMsg;
	} else {
		return alertMsg;
	}
}

export default ColumnGroup;


const dynamicProcessGroupData = (response) => {

	let data = [];
	let name = '';

	if (Object.keys(response).length > 0) {

		Object.keys(response).forEach(key => {
			response[key].forEach(element => {
				Object.keys(element).forEach(key => {

					if (Array.isArray(element[key])) {
						data.push({
							bbname: key, groups: element[key].join(' , ')
						})
					} else if (typeof element[key] === 'string') {
						name = element[key];
					}

				})

			})
		});

	}



	if (data.length > 0) {
		return (
			<span style={{ display: 'flex', flexDirection: 'column' }}>
				{name + getLocalizedStrings().message.GROUP.COMMON_DELETE_MSG}
				<span style={{ marginTop: '0.5rem' }}></span>
				{data.map(({ bbname, groups }, index) => {
					return (
						<span key={index}>
							{bbname.substring(0, 1).toUpperCase() + bbname.substring(1)}- <b>{groups}</b>
						</span>
					)
				})}

			</span>
		);
	} else {
		return '';
	}
}
