
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isValidParam, getObjectParam, getIntParam, getArrayParam, getBooleanParam, getStringParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants, TABLEID_OBJECT_MAP } from '../../../services/constants/constants';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { Icon, IconButton } from '@mui/material';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const SMSReportListView = ({ info, closePanel }) => {
    const dispatch = useDispatch();
    const [mounted, setMounted] = useState(false);
    const [records, setRecords] = useState(null);
    const [data, setData] = useState(null);
    const [noRecords, setNoRecords] = useState(false);

    useEffect(() => {
        let tempinfo = info;
        if (isValidParam(tempinfo)) {
            let params = tempinfo.input_param;
            params.t_type = constants.SMS;
            if (isValidParam(params)) {
                getDrillDownDetails(params);
            }
        }
    }, []);

    const getDrillDownDetails = (params) => {
        let records = null;
        params = getObjectParam(params);
        let promise = Promise.resolve(HTTPClient.get(endPoints.SMS_REPORT.DRILLDOWN_GET, params));
        promise.then((response) => {
            if (isValidParam(response)) {
                records = getArrayParam(response);
                if (records != null && records.length > 0) {
                    setRecords(records);
                    setData(params);
                    setMounted(true);
                } else {
                    setNoRecords(true);
                }

            }
        });
    }

    const getHeader = (record) => {
        let header = null;
        let style = { lineHieght: '30px', backgroundColor: '#fafafa', color: '#535353', paddingTop: '10px', paddingLeft: '7px' };
        try {
            if (isValidParam(record)) {
                let headerText = getHeaderText(record);
                header = <div style={style}>
                    <div style={{ paddingLeft: '10px', fontWeight: 'bold', color: '#070100' }}>
                        {headerText}
                    </div>
                </div>;
            }
        } catch (error) {
            console.error("Error in 'SMSReportListView.js -> getHeader()':" + error);
        }
        return header;
    }

    const getHeaderText = (object) => {
        let headerText = "";
        let record = getObjectParam(object);
        try {
            let author = getStringParam(record.note_author).trim();
            let date = getStringParam(record.date_time).trim();
            if (author !== "") {
                headerText = author;
            }
            if (date !== "") {
                headerText += headerText !== "" ? ", " + date : date;
            }
        } catch (error) {
            console.error("Error in 'SMSReportListView.js -> getHeaderText()':" + error);
        }
        return headerText;
    }

    const getNoteSubText = (object) => {
        let subjectContent = null;
        let subject = "";
        let record = getObjectParam(object);
        let style = { backgroundColor: '#ffffff', paddingLeft: "17px", paddingRight: 17 };
        let subjectText = null;
        let column_name = null;
        try {
            if (isValidParam(object)) {
                let smsObj = getObjectParam(object);
                let col = getStringParam(data.column_name);
                subjectText = getStringParam(record.sms_text).trim();
                if (subjectText !== "") {
                    subject = subjectText;
                    subjectContent = <div style={style}>
                        <div style={{ whiteSpace: "pre-wrap", wordBreak: "break-all", lineHeight: "30px" }}>{subject}
                            <span style={{ paddingLeft: '10px' }}>
                                <a href="javascript:void(0)" onClick={() => openSMS(smsObj)}>
                                    {getLocalizedStrings().label.COMMON.MORE + "..."}
                                </a>
                            </span>
                        </div>
                    </div>

                }
            }
        } catch (error) {
            console.error("Error in 'SMSReportListView.js -> getNoteSubText()':" + error);
        }
        return subjectContent;
    }

    const generateContent = () => {
        let mainContent = [];
        let content = null;
        if (getArrayParam(records).length > 0) {
            records.map((record, index) => {
                let header = getHeader(record);
                let subText = getNoteSubText(record);
                content = <div>{header}{subText}</div>
                mainContent.push(content);
                content = null;
            });
        }
        return mainContent;
    }

    const openSMS = (recordObj) => {
        try {
            if (isValidParam(recordObj)) {
                let record = getObjectParam(recordObj);
                if (isValidParam(record)) {
                    let data = {};
                    let object = TABLEID_OBJECT_MAP[getIntParam(record.table_id)];
                    data.parentObject = getStringParam(object);
                    data.parentRecordId = getIntParam(record.record_id);
                    data.record = record;
                    let subject = getStringParam(record.sms_text);
                    let label = subject.length > 55 ? subject.substring(0, 55) + '...' : subject;
                    let type = constants.SMS_DETAILS;
                    let callFrom = constants.NOTES;
                    dispatch(getAppDrawer(true, label, type, data, callFrom));
                }
            }

        } catch (error) {
            console.log("Error in 'SMSReportListView.js -> openSMS()':" + error);
        }
    }
    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight - 10) / 2;
    if (mounted) {
        let heading = data?.reportLabel;
        return (
            <div className="container" id="sms-list-view-container" style={{ width: '100%' }}>
                <div className="row">
                    <div style={{ backgroundColor: '#ffffff', paddingTop: "1px", paddingBottom: "20px" }}>
                        <div style={{ marginTop: '0px' }}>
                            <div className="text" style={{ fontSize: '22px', float: 'left', textAlign: 'center', width: '90%', padding: '0px 10px' }}>
                                <h3 style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 10, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {heading}
                                </h3>
                            </div>
                            <div className="text" style={{ fontSize: '22px', float: 'right', textAlign: 'center', width: '10%', padding: '2.8% 2%' }}>
                                <IconButton onClick={() => closePanel()} >
                                    <i class="fas fa-times" style={{ cursor: 'pointer' }} title="Close" />
                                </IconButton>
                            </div>
                        </div>
                        {generateContent(records)}
                    </div>
                </div>
            </div>
        );
    } else if (noRecords) {
        return (<div className="container" id="sms-list-view-container" style={{ width: '100%' }}>
            <div className="row">
                <div style={{ backgroundColor: '#ffffff', paddingTop: "1px"}}>
                    <div style={{ marginTop: '0px' }}>
                        <div className="text" style={{ fontSize: '22px', float: 'right', textAlign: 'center', width: '10%', padding: '2.8% 2%' }}>
                            <IconButton onClick={() => closePanel()} >
                                <i class="fas fa-times" style={{ cursor: 'pointer' }} title="Close" />
                            </IconButton>
                        </div>
                        <div className="text" style={{ fontSize: '14px', float: 'left', textAlign: 'center', width: '90%', padding: '50px 0px' }}>
                            <span style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 10, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                Sorry! We could not fetch the details for the failed SMS.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    } else {
        return (<div style={{ width: '100%', height: pageHeight }}>
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
        </div>);
    }





}

export default SMSReportListView;