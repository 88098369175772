import React, { useState, useEffect,useRef } from 'react';
import Button from '@mui/material/Button';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { constants } from '../../../services/constants/constants';
import { isValidParam, isEmail } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { useDispatch, useSelector } from "react-redux";


const VerifyEmailDialog = ({ }) => {
    const inputRef = useRef(null);
    const dispatch = useDispatch();

    const appContainer = useSelector((state) => state.appContainer);

    const [verifyEmail, setVerifyEmail] = useState('');
    const [fromName, setFromName] = useState('');
    const [verifiedEmailOptions, setVerifiedEmailOptions] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');


    useEffect(() => {
        dispatch(setAppDialogActions(getActions()));
        let _fromName = '';
        let _verifiedEmailOptions = [];
        if (appContainer.dialogProps.dialogType !== null && isValidParam(appContainer.dialogProps.dialogType)) {
            let dialogType = appContainer.dialogProps.dialogType;
            if (dialogType === constants.VERIFY_EMAIL_DIALOG) {
                _fromName = appContainer.dialogProps.data.fromName;
                _verifiedEmailOptions = appContainer.dialogProps.data.verifiedEmailOptions;
                if (isValidParam(_fromName) && _fromName.trim().length > 0 && isValidParam(_verifiedEmailOptions) && _verifiedEmailOptions.length > 0) {
                    setFromName(_fromName);
                    setVerifiedEmailOptions(_verifiedEmailOptions);
                }
            }
        }
    }, []);


    const getActions = () => {
        const actions = [
            <Button
                key={'sendVerify'}
                primary={"true"}
                onClick={() => sendVerificationEmail()}
                style={styles.primaryButton}
            >{getLocalizedStrings().label.COMMON.SEND_VERIFICATION_EMAIL}</Button>,
            <Button
                key={'cancel'}
                primary={"true"}
                onClick={() => handleClose()}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,

        ];
        return actions;
    }

    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const validateVerificationEmail = (verifyEmail) => {
        let isValid = false;
        try {
            if (isValidParam(verifyEmail)) {
                if (verifyEmail.trim().length === 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.BROADCAST_CAMPAIGNS.VERIFY_BLANK_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
                else if (verifyEmail.trim().length > 0 && !isEmail(verifyEmail)) {
                    dispatch(showCustomSnackBar(verifyEmail + ' ' + getLocalizedStrings().message.BROADCAST_CAMPAIGNS.VERIFY_INVALID_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
                else if (verifyEmail.trim().length > 0) {
                    isValid = true;
                }
            }
        }
        catch (error) {
            console.log("Error in validateVerificationEmail() in verifyEmailDialog.js : " + error);
        }
        return isValid;

    }

    const sendVerificationEmail = () => {
        try {
            let value = inputRef.current.getElementsByTagName("input")['verify_email'].value;
            if (validateVerificationEmail(value)) {
                let params = {};
                params.email = value;
                params.fromName = fromName;
                let promise = Promise.resolve(HTTPClient.post(endPoints.BROADCAST_CAMPAIGNS.SEND_VERIFICATION_EMAIL, params));
                promise.then((result) => {
                    if (isValidParam(result)) {
                        let tempErrorMessage = errorMessage;
                        if (result.data === 0) {
                            tempErrorMessage = getLocalizedStrings().message.BROADCAST_CAMPAIGNS.EMAIL_INTEGRATION_NEW;
                        }
                        else if (result.data === 1) {
                            tempErrorMessage = getLocalizedStrings().message.BROADCAST_CAMPAIGNS.EMAIL_INTEGRATION_VERIFIED;
                        }
                        else if (result.data === -1) {
                            if (result.status === 0) {
                                let bodyStyle = { ...styles.snackbarBodyStyleSuccess, maxWidth: '1200px' };
                                let topStyle = { ...styles.snackBarStyleTop, left: styles.snackBarStyleTop.left - 350 };
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.BROADCAST_CAMPAIGNS.SEND_VERIFICATION_EMAIL_SUCCESS, bodyStyle, topStyle));
                                handleClose();
                            }
                        }
                        setErrorMessage(tempErrorMessage);
                    }

                });
            }
        } catch (error) {
            console.log("Error in sendVerificationEmail() in verifyEmailDialog.js : " + error);
        }

    }

    const fnSetVerifyEmail = (event) => {
        let email = event.target.value;
        let tempErrorMessage = errorMessage;
        tempErrorMessage = '';
        setVerifyEmail(email);
        setErrorMessage(tempErrorMessage);
    }


    let verify_email_placeholder = null;
    verify_email_placeholder = getLocalizedStrings().label.BROADCAST_CAMPAIGNS.VERIFY_EMAIL_ADDRESS;

    return (
        <div>
            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                <TextField
                    variant="outlined"
                    label={verify_email_placeholder}
                    InputLabelProps={{
                        style: {
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            width: '96%',
                        }
                    }}
                    fullWidth={true}
                    name="verify_email"
                    ref={inputRef}
                    value={verifyEmail}
                    onChange={(e) => fnSetVerifyEmail(e)}
                    autoFocus={true}
                    autoComplete="new-password"
                    margin='dense'
                    size='small'
                    className={"sf-fields-bg"}
                /></FormControl>
            <div><span style={{ fontSize: '12px', color: '#f44336', marginTop: '10px', display: 'block' }}>{errorMessage}</span></div>
        </div>
    );
}

export default VerifyEmailDialog