import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import map from 'lodash/map';
import { addNode } from '../../../services/actions/automationCampaignActions';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import AutomationNode from '../components/automationNode';
import { fetchAutomationIcon } from '../../../services/helper/utils';
import { constants } from '../../../services/constants/constants';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { getIntParam, getStringParam, isValidParam } from '../../../services/helper/parameterVerifier';
import {
	getActionByName,
	getMaxId,
	getNodeByChildId,
	getNodeByParentId,
	getParentDecision,
	removeError,
	showPlusIcon,
} from '../../../services/helper/automationUtils';
import * as sfDialogs from '../components/sfDialogs';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { useDrop } from 'react-dnd';
import { ItemTypes } from './ItemTypes';

const style = {
	width: '5000px',
	height: '5000px'
};

const AutomationBuilder = ({ propsNodes }) => {
	const dispatch = useDispatch();
	const automationCampaign = useSelector(state => state.automationCampaign);
	const [jsPlumbInst] = useState(automationCampaign.jsPlumbInst);
	const [diagram, setDiagram] = useState(null);
	const [refreshCount, setRefreshCount] = useState(0);

	const [, drop] = useDrop(() => ({
		accept: ItemTypes.ACTION,
		drop(_props, monitor) {
			const delta = monitor.getClientOffset();
			const container = document.getElementById('divContainer');
			let left = 0;
			let top = 0;
			if (container) {
				const rect = container.getBoundingClientRect();
				left = Math.round(delta.x - rect.left);
				top = Math.round(delta.y - rect.top);
			}
			return {
				name: 'AutomationBuilder',
				left: left,
				top: top
			};
		},
	}))

	useEffect(() => {
		return () => {
			if (jsPlumbInst !== null) {
				jsPlumbInst.destroy();
			}
		}
	}, []);

	useEffect(() => {
		try {
			if(jsPlumbInst?.reset != null && jsPlumbInst?.reset != undefined){
				jsPlumbInst?.reset(false);
			}
		} catch (error) {
			console.error("Error in 'automationBuilder.js -> useEffect()': " + error);
		}
		let diagram = propsNodes
			.filter(node => node.nodeStatusFlg !== 3 && node.detailStatusFlg !== 3)
			.map((node) => getAutomationNode(node, automationCampaign.data));
		setDiagram(diagram);
		setRefreshCount(refreshCount + 1);
	}, [propsNodes, automationCampaign])

	function getAutomationNode(node, automationData) {
		const { status, actions } = automationData;
		let icon = fetchAutomationIcon(node.actionName, actions);
		return (
			<AutomationNode
				key={node.childId}
				node={node}
				jsPlumbInst={jsPlumbInst}
				iconName={icon}
				campStatus={status}
			/>
		);
	}

	useEffect(() => {
		if (refreshCount > 0) {
			connectDiagram(propsNodes);
			console.log("checkBeforeDrop");
			checkBeforeDrop();
		}
	}, [refreshCount]);

	function openDialog(dialogData) {
		dispatch(getAppDialog(true, constants.ALERT_DIALOG, getLocalizedStrings().message.AUTOMATION_DESIGNER.AUTOMATED_CAMPAIGN_TITLE, () => console.log("clicked ok"), dialogData, constants.AUTOMATION_DESIGNER_OBJECT));
	}

	function getActionById(actionId) {
		let arrActions = automationCampaign.data.actions;
		arrActions = arrActions.filter((el) => el.actionId.toString() === actionId.toString().replace("T_", ""));
		return (!!arrActions && arrActions.length > 0) ? arrActions[0] : {};
	}

	function targetNeedsToChange(nodes, targetNode, sourceNode) {
		let isNeedsToChange = false;
		let currentNode = sourceNode;
		if (currentNode != null) {
			while (currentNode.parentId) {
				if (currentNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL) {
					isNeedsToChange = true;
					break;
				}else if (currentNode.actionName === constants.AUTOMATION_DESIGNER_ADD_SMS) {
					isNeedsToChange = true;
					break;
				} else if (currentNode.type === 1) {
					isNeedsToChange = false;
					break;
				}
				currentNode = getNodeByChildId(nodes, currentNode.parentId);
			}
		}
		return isNeedsToChange ;
	}

	function checkBeforeDrop() {
		try {
			let dialogData = null;
			jsPlumbInst?.bind("beforeDrop", (info) => {
				let nodesArray = automationCampaign.data;
				if (isValidParam(info)) {
					let isConnect = false;
					let sourceId = info.sourceId;
					let targetId = info.targetId;
					let sourceNode = getNodeByChildId(nodesArray.nodes, sourceId);
					let targetNode = getNodeByChildId(nodesArray.nodes, targetId);

					let objSourceAction = getActionById(sourceNode.actionId);
					let arrConnectionList = objSourceAction.connectAction.split(',');
					arrConnectionList = arrConnectionList.filter(el => el.toString() === targetNode.actionId.toString());
					if (arrConnectionList.length === 0) {
						if (targetNode.actionName !== constants.AUTOMATION_DESIGNER_WAIT) {
							let directUnder = getLocalizedStrings().message.AUTOMATION_DESIGNER.DIRECT_UNDER;
							if (sourceNode.hasOwnProperty('actionName') && sourceNode.actionName === constants.AUTOMATION_DESIGNER_EMAIL_OPEN) {
								directUnder = getLocalizedStrings().message.AUTOMATION_DESIGNER.DIRECT_UNDER_AN;
							}
							dialogData = {
								message: getLocalizedStrings().message.AUTOMATION_DESIGNER.CANNOT_ADD + ' ' + targetNode.actionLabel + ' ' + directUnder + ' ' + sourceNode.actionLabel,
							}
							openDialog(dialogData);
							return false;
						}
						if (targetNode.actionName === constants.AUTOMATION_DESIGNER_WAIT && sourceNode.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
							dialogData = {
								message: getLocalizedStrings().message.AUTOMATION_DESIGNER.CANNOT_ADD + ' ' + targetNode.actionLabel + ' ' + getLocalizedStrings().message.AUTOMATION_DESIGNER.DIRECT_UNDER + ' ' + sourceNode.actionLabel,
							}
							openDialog(dialogData);
							return false;
						}
					}

					if (targetNode.actionName === constants.AUTOMATION_DESIGNER_WAIT && sourceNode.actionName === constants.AUTOMATION_DESIGNER_SCORE_REACHED) {
						dialogData = {
							message: getLocalizedStrings().message.AUTOMATION_DESIGNER.CANNOT_ADD + ' ' + targetNode.actionLabel + ' ' + getLocalizedStrings().message.AUTOMATION_DESIGNER.DIRECT_UNDER + ' ' + sourceNode.actionLabel,
						}
						openDialog(dialogData);
						return false;
					}

					if (sourceNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE && targetNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
						document.getElementById(sourceNode.childId + '_P').style.display = 'none';
					}
					if (sourceNode.actionName === constants.AUTOMATION_DESIGNER_WAIT && targetNode.actionName !== constants.AUTOMATION_DESIGNER_AUTO_MAIL && targetNode.actionName !== constants.AUTOMATION_DESIGNER_ADD_MESSAGE && targetNode.actionName !== constants.AUTOMATION_DESIGNER_SEND_SMS && targetNode.actionName !== constants.AUTOMATION_DESIGNER_ADD_SMS ) {
						if (targetNode.hasOwnProperty('type') && targetNode.type === 2) {
							let tempPrevNode = getNodeByChildId(nodesArray.nodes, sourceNode.parentId);
							let hasDecision = false;
							do {
								if (tempPrevNode.hasOwnProperty('type') && tempPrevNode.type === 1) {
									hasDecision = true;
									break;
								}
								tempPrevNode = getNodeByChildId(nodesArray.nodes, tempPrevNode.parentId);
							} while (tempPrevNode.parentId !== 0);
							if (!hasDecision) {
								dialogData = {
									message: getLocalizedStrings().message.AUTOMATION_DESIGNER.CANNOT_ADD + ' ' + targetNode.actionLabel + ' ' + getLocalizedStrings().message.AUTOMATION_DESIGNER.DIRECT_UNDER + ' ' + sourceNode.actionLabel,
								}
								openDialog(dialogData);
								return false;
							}
						}
					}

					if (sourceNode.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
						if (targetNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
							if (targetNode.hasOwnProperty('details')) {
								let targetNodeDetailsArray = targetNode.details[0];
								let sourceNodeDetailsArray = sourceNode.details[0];
								if (isValidParam(targetNodeDetailsArray)) {
									if (sourceNodeDetailsArray.interval_mode === 'days') {
										targetNodeDetailsArray.dbp = sourceNode.details[0].interval / 24;
										targetNodeDetailsArray.interval_mode = 'days';
									} else {
										targetNodeDetailsArray.dbp = sourceNode.details[0].interval;
										targetNodeDetailsArray.interval_mode = 'hours';
									}
								}
							}
						} else if (targetNode.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
							if (targetNode.hasOwnProperty('details')) {
								let targetNodeDetailsArray = targetNode.details[0];
								let sourceNodeDetailsArray = sourceNode.details[0];
								if (isValidParam(targetNodeDetailsArray)) {
									if (sourceNodeDetailsArray.interval_mode === 'days') {
										targetNodeDetailsArray.interval = sourceNode.details[0].interval / 24;
										targetNodeDetailsArray.interval_mode = 'days';
									} else {
										targetNodeDetailsArray.interval = sourceNode.details[0].interval;
										targetNodeDetailsArray.interval_mode = 'hours';
									}
								}
							}
						}
					}

					let websiteUrlLinkNodeStatus = getWebsiteUrlLinkNodeStatus(propsNodes, sourceNode.childId);
					if (isValidParam(websiteUrlLinkNodeStatus)) {
						if (websiteUrlLinkNodeStatus.nodeCount > 0 && websiteUrlLinkNodeStatus.status === "LINK_EXIST" &&
							sourceNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE &&
							targetNode.actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK &&
							(!targetNode.hasOwnProperty('details') || (targetNode.hasOwnProperty('details') &&
									targetNode.details.length === 0) ||
								(targetNode.hasOwnProperty('details') && targetNode.details.length > 0 &&
									targetNode.details[0].hasOwnProperty('websiteUrl') && targetNode.details[0].websiteUrl === ""))) {
							dialogData = {
								message: getLocalizedStrings().message.AUTOMATION_DESIGNER.CATCH_ALL_LINK_CONNECTION,
							}
							openDialog(dialogData);
							return false;
						}
					}

					if (sourceNode.type.toString() === "0" && targetNode.type.toString() === "1") {
						dialogData = {
							message: getLocalizedStrings().message.AUTOMATION_DESIGNER.CANNOT_START_WITH_DECISSION,
						}
						openDialog(dialogData);
						return false;
					}

					if (sourceNode.type.toString() === "0" && targetNode.type.toString() === "2") {
						dialogData = {
							message: getLocalizedStrings().message.AUTOMATION_DESIGNER.CANNOT_START_WITH_ACTION
						}
						openDialog(dialogData);
						return false;
					}
					if (sourceNode.childId.toString() === targetNode.childId.toString()) {
						dialogData = {
							message: getLocalizedStrings().message.AUTOMATION_DESIGNER.CONNECT_WITH_PROPER_NODE
						}
						openDialog(dialogData);
						return false;
					}
					if (arrConnectionList && arrConnectionList.length === 0) {
						isConnect = true;
					}
					if (sourceNode.type.toString() === "1") {
						if (info.sourceId.indexOf("_N") !== -1) {
							if (targetNode.actionName !== constants.AUTOMATION_DESIGNER_WAIT) {
								let objWaitAction = getActionByName(automationCampaign.data.actions, constants.AUTOMATION_DESIGNER_WAIT);
								let newNodeObject = generateNodeObject(objWaitAction, sourceNode);
								newNodeObject.details[0] = {
									interval: 120,
									interval_mode: 'days'
								}
								newNodeObject.interval_mode = 'days';
								newNodeObject.parentId = sourceNode.childId;
								newNodeObject.decisionFlg = 2;
								targetNode.parentId = newNodeObject.childId;
								nodesArray.nodes.push(newNodeObject);
								dispatch(addNode(nodesArray));
								return false;
							}
							targetNode.decisionFlg = 2;
							isConnect = true;
						} else if (info.sourceId.indexOf("_Y") !== -1) {
							isConnect = true;
							targetNode.decisionFlg = 1;
						}
					} else {
						let arrChildNodes = getNodeByParentId(nodesArray.nodes, sourceId);
						arrChildNodes = arrChildNodes.filter(function (el) {
							return el.nodeStatusFlg.toString() !== "3";
						});
	
						if (sourceNode.actionName === constants.AUTOMATION_DESIGNER_ADD_SMS || sourceNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE  || sourceNode.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
							let arrMatchingNodes = arrChildNodes.filter((el) => el.actionName === targetNode.actionName && el.nodeStatusFlg.toString() !== "3");
							if (arrMatchingNodes.length > 0 && targetNode.actionName !== constants.AUTOMATION_DESIGNER_LINK_CLICK) {
								dialogData = {
									message: getLocalizedStrings().message.AUTOMATION_DESIGNER.CANNOT_CONNECT_MULTIPLE + targetNode.actionLabel,
								}
								openDialog(dialogData);
								return false;
							}
							// to restrict the mutliple wait to connect the send sms node
							if(arrChildNodes.length > 0 && targetNode.actionName === constants.AUTOMATION_DESIGNER_SEND_SMS){
								dialogData = {
									message: getLocalizedStrings().message.AUTOMATION_DESIGNER.CANNOT_CONNECT_MULTIPLE + arrChildNodes[0].actionLabel,
								}
								openDialog(dialogData);
								return false;
							}
						} else {
							if (arrChildNodes !== undefined && arrChildNodes !== null && arrChildNodes.length >= 1) {
								dialogData = {
									message: getLocalizedStrings().message.AUTOMATION_DESIGNER.CONNECTION_EXIST + sourceNode.actionLabel + '.',
								}
								openDialog(dialogData);
								return false;
							}
						}
						console.log(" isConnect  true")
						isConnect = true;
						targetNode.decisionFlg = sourceNode.decisionFlg;
					}

					if ((targetNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE && sourceNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE)
						|| (targetNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE && sourceNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL)
					) {
						let objWaitAction = getActionByName(automationCampaign.data.actions, constants.AUTOMATION_DESIGNER_WAIT);
						let newNodeObject = generateNodeObject(objWaitAction, sourceNode);
						let defaultInterval = sourceNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL ? 0 : 120;
						newNodeObject.details[0] = {
							interval: defaultInterval,
							interval_mode: 'days'
						}
						newNodeObject.interval_mode = 'days';
						newNodeObject.parentId = sourceNode.childId;
						newNodeObject.decisionFlg = 0;
						targetNode.parentId = newNodeObject.childId;
						if (targetNode.hasOwnProperty('details') && isValidParam(targetNode.details[0])) {
							targetNode.details[0].dbp = defaultInterval / 24;
							targetNode.details[0].interval_mode = 'days';
						}
						nodesArray.nodes.push(newNodeObject);
						dispatch(addNode(nodesArray));
						return false;
					}

					if (targetNode.hasOwnProperty('actionName') && targetNode.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
						// not allow to add send email directly under birthday campaign
						let autoTempName = getStringParam(nodesArray.template_name);
						if (autoTempName === constants.BIRTHDAY_CAMPAIGN_TEMPLATE_NAME) {
							if (sourceNode.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
								dialogData = {
									message: getLocalizedStrings().message.AUTOMATION_DESIGNER.CANNOT_ADD + ' ' + targetNode.actionLabel + ' ' + getLocalizedStrings().message.AUTOMATION_DESIGNER.DIRECT_UNDER + ' ' + sourceNode.actionLabel,
								}
								openDialog(dialogData);
								return false;
							}
						}

						// not allow if Send Email is under Unsubscribed action
						let tSourceNode = sourceNode;
						do {
							if (tSourceNode.actionName === constants.AUTOMATION_DESIGNER_UNSUBSCRIBE) {
								dialogData = {
									message: getLocalizedStrings().message.AUTOMATION_DESIGNER.CANNOT_ADD + ' ' + targetNode.actionLabel + ' ' + getLocalizedStrings().message.AUTOMATION_DESIGNER.DIRECT_UNDER + ' ' + tSourceNode.actionLabel,
								}
								openDialog(dialogData);
								return false;
							}
							tSourceNode = getNodeByChildId(nodesArray.nodes, tSourceNode.parentId);
						} while (tSourceNode);


						if (sourceNode.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
							if (sourceNode.parentId.toString() !== "0") {
								let sourceNodeParent = getNodeByChildId(nodesArray.nodes, sourceNode.parentId);
								if (isValidParam(sourceNodeParent) && sourceNodeParent.actionName === constants.AUTOMATION_DESIGNER_UNSUBSCRIBE) {
									dialogData = {
										message: getLocalizedStrings().message.AUTOMATION_DESIGNER.CANNOT_ADD + ' ' + targetNode.actionLabel + ' ' + getLocalizedStrings().message.AUTOMATION_DESIGNER.DIRECT_UNDER + ' ' + sourceNodeParent.actionLabel,
									}
									openDialog(dialogData);
									return false;
								}
							}
						}

						// adding wait between the send email nodes
						let changeTargetNode = false;
						changeTargetNode = targetNeedsToChange(nodesArray.nodes, targetNode, sourceNode);
						if (changeTargetNode) {
							let childNode = targetNode;
							do {
								if (childNode.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
									let tempSourceNode = null;
									if (childNode.parentId.toString() !== "0") {
										tempSourceNode = getNodeByChildId(nodesArray.nodes, childNode.parentId);
									} else {
										tempSourceNode = sourceNode;
									}
									changeNode(childNode, nodesArray, tempSourceNode);
								}

								childNode = getNodeByParentId(nodesArray.nodes, childNode.childId);
								childNode = childNode.filter(n => n.nodeStatusFlg.toString() !== "3");
								childNode = childNode[0];

								if (isValidParam(childNode) && childNode.hasOwnProperty('type') && childNode.type.toString() === "1") {
									break;
								}
							} while (childNode);
							return sourceNode.actionName === constants.AUTOMATION_DESIGNER_WAIT;
						} else {
							if (sourceNode.hasOwnProperty('actionName') && sourceNode.actionName !== constants.AUTOMATION_DESIGNER_WAIT) {
								let objWaitAction = getActionByName(automationCampaign.data.actions, constants.AUTOMATION_DESIGNER_WAIT);
								let newNodeObject = generateNodeObject(objWaitAction, sourceNode);
								newNodeObject.details[0] = {
									interval: 120,
									interval_mode: 'days'
								}
								newNodeObject.interval_mode = 'days';
								newNodeObject.parentId = sourceNode.childId;
								if (sourceNode.type.toString() === "1") {
									newNodeObject.decisionFlg = targetNode.decisionFlg;
								} else {
									newNodeObject.decisionFlg = sourceNode.decisionFlg;
								}

								targetNode.parentId = newNodeObject.childId;
								if (targetNode.hasOwnProperty('details') && isValidParam(targetNode.details[0])) {
									targetNode.details[0].interval = 5;
									targetNode.details[0].interval_mode = 'days';
								}
								nodesArray.nodes.push(newNodeObject);
								dispatch(addNode(nodesArray));
								return false;
							}
						}
					}
					if (sourceNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL && targetNode.actionName === constants.AUTOMATION_DESIGNER_WAIT) {

						let changeTargetNode = false;
						changeTargetNode = targetNeedsToChange(nodesArray.nodes, targetNode, sourceNode);
						if (changeTargetNode) {
							let childNode = targetNode;
							do {
								if (childNode.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
									let tempSourceNode = null;
									if (childNode.parentId.toString() !== "0") {
										tempSourceNode = getNodeByChildId(nodesArray.nodes, childNode.parentId);
									} else {
										tempSourceNode = sourceNode;
									}
									changeNode(childNode, nodesArray, tempSourceNode);
								}

								childNode = getNodeByParentId(nodesArray.nodes, childNode.childId);
								childNode = childNode.filter(n => n.nodeStatusFlg.toString() !== "3");
								childNode = childNode[0];

								if (isValidParam(childNode) && childNode.hasOwnProperty('type') && childNode.type.toString() === "1") {
									break;
								}
							} while (childNode);
							if (sourceNode.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
								return true;
							}
						}

					}
					if (sourceNode.hasOwnProperty('type') && sourceNode.type.toString() === "1") {
						let childNode = getNodeByChildId(nodesArray.nodes, targetNode.childId);
						do {
							if (childNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL) {
								break;
							}
							if (childNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
								if (automationCampaign.data.id > 0 && childNode.hasOwnProperty('id') && childNode.id > 0) {
									let copyCurrNode = { ...childNode };
									copyCurrNode.nodeStatusFlg = 3;
									copyCurrNode.detailStatusFlg = 3;
									childNode.prevDetails = copyCurrNode;
								}
								childNode.actionId = 26;
								childNode.actionName = constants.AUTOMATION_DESIGNER_AUTO_MAIL;
								childNode.actionLabel = getLocalizedStrings().label.AUTOMATION_DESIGNER['Send Email'];
								childNode.processId = null;
								childNode.secProcessId = 0;
								childNode.decisionFlg = 0;
								// childNode.details = [];
								childNode.referenceId = 0;
								childNode.detailStatusFlg = 1;
								childNode.id = 0;
								if (childNode.hasOwnProperty('details')) {
									let detailsArray = childNode.details[0];
									if (isValidParam(detailsArray) && detailsArray.hasOwnProperty('message_id')) {
										detailsArray.id = 0;
										detailsArray.t_status = 'New';
										delete detailsArray.message_id;
										delete detailsArray.dbp;
										delete detailsArray.interval_mode;
										delete detailsArray.message_name;
									}
									let prevWaitNode = getNodeByChildId(nodesArray.nodes, childNode.parentId);
									if (prevWaitNode.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
										detailsArray.interval_mode = prevWaitNode.details[0].interval_mode;
										if (prevWaitNode.details[0].interval_mode === 'days') {
											detailsArray.interval = prevWaitNode.details[0].interval / 24;
										} else {
											detailsArray.interval = prevWaitNode.details[0].interval;
										}
									}
								}
							}
							childNode = getNodeByParentId(nodesArray.nodes, childNode.childId);
							childNode = childNode.filter(n => n.nodeStatusFlg.toString() !== "3");
							childNode = childNode[0];
						} while (childNode);
					}
					// if sourceNode is Unsubscribed then not allow Send Email under it
					if (sourceNode.actionName === constants.AUTOMATION_DESIGNER_UNSUBSCRIBE) {
						let tSourceNode = targetNode;
						do {
							if (tSourceNode.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
								dialogData = {
									message: getLocalizedStrings().message.AUTOMATION_DESIGNER.CANNOT_ADD + ' ' + tSourceNode.actionLabel + ' ' + getLocalizedStrings().message.AUTOMATION_DESIGNER.DIRECT_UNDER + ' ' + sourceNode.actionLabel,
								}
								openDialog(dialogData);
								return false;
							}
							tSourceNode = getNodeByParentId(nodesArray.nodes, tSourceNode.childId);
							if (isValidParam(tSourceNode)) {
								tSourceNode = tSourceNode.filter(n => n.nodeStatusFlg.toString() !== "3");
								tSourceNode = tSourceNode[0];
							}
						} while (tSourceNode);
					}

					// if sourceNode is Touch Message then convert all the Send Email action to Touch Message
					if (sourceNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
						let childNode = targetNode;
						do {
							if (isValidParam(childNode) && childNode.hasOwnProperty('type') && childNode.type.toString() === "1") {
								break;
							}
							if (childNode.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
								let tempSourceNode = null;
								if (childNode.parentId.toString() !== "0") {
									tempSourceNode = getNodeByChildId(nodesArray.nodes, childNode.parentId);
								} else {
									tempSourceNode = sourceNode;
								}
								changeNode(childNode, nodesArray, tempSourceNode);
							}
							childNode = getNodeByParentId(nodesArray.nodes, childNode.childId);
							childNode = childNode.filter(n => n.nodeStatusFlg.toString() !== "3");
							childNode = childNode[0];

						} while (childNode);
					}
					targetNode = removeError(targetNode);
					sourceNode = removeError(sourceNode);
					targetNode.parentId = sourceNode.childId;
					if (isConnect) {
						console.log("adding node")
						dispatch(addNode(nodesArray));
					}
					//adding wait node between send sms
					if(targetNode.hasOwnProperty('actionName') && targetNode.actionName === constants.AUTOMATION_DESIGNER_SEND_SMS && nodesArray.templateName === constants.SMS_DRIP_CAMPAIGN){
						let changeTargetNode = false;
						changeTargetNode = targetNeedsToChange(nodesArray.nodes, targetNode, sourceNode);
						if (changeTargetNode) {
							let childNode = targetNode;
							do {
								if (childNode.actionName === constants.AUTOMATION_DESIGNER_SEND_SMS) {
									let tempSourceNode = null;
									if (childNode.parentId.toString() !== "0") {
										tempSourceNode = getNodeByChildId(nodesArray.nodes, childNode.parentId);
									} else {
										tempSourceNode = sourceNode;
									}
									changeNode(childNode, nodesArray, tempSourceNode);
								}

								childNode = getNodeByParentId(nodesArray.nodes, childNode.childId);
								childNode = childNode.filter(n => n.nodeStatusFlg.toString() !== "3");
								childNode = childNode[0];

								if (isValidParam(childNode) && childNode.hasOwnProperty('type') && childNode.type.toString() === "1") {
									break;
								}
							} while (childNode);
							return sourceNode.actionName === constants.AUTOMATION_DESIGNER_WAIT;
						} 
					}	

					return false;
				}
			});

			jsPlumbInst?.bind('connectionDrag', (info) => {
				jsPlumbInst.repaintEverything();
			});

			jsPlumbInst?.bind("contextmenu", (conn, event) => {
				let nodesArray = automationCampaign.data;
				if (automationCampaign.data.status !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) { //campStatus
					event.preventDefault();
					let sourceNode = getNodeByChildId(nodesArray.nodes, getIntParam(conn.sourceId));
					let targetNode = getNodeByChildId(nodesArray.nodes, getIntParam(conn.targetId));
					let isRemove = true;

					if (targetNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
						let nodesArray = automationCampaign.data;
						let parentInfo = getNodeByChildId(nodesArray.nodes, targetNode.parentId);
						if (isValidParam(parentInfo) && parentInfo.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL &&
							targetNode.parentId.toString() === parentInfo.childId.toString()) {
						}
					}

					if (isRemove) {
						removeConnection(isRemove, sourceNode, targetNode, conn, jsPlumbInst);
						return false;
					}
				}
			});
		} catch (error) {
			console.error("Error in 'automationBuilder.js -> checkBeforeDrop()':" + error);
		}
	}

	function changeNode(targetNode, nodesArray, sourceNode) {
		let interval = 120;
		let interval_mode = 'days';
		if (sourceNode.hasOwnProperty('actionName') && sourceNode.actionName !== constants.AUTOMATION_DESIGNER_WAIT) {
			let objWaitAction = getActionByName(automationCampaign.data.actions, constants.AUTOMATION_DESIGNER_WAIT);
			let newNodeObject = generateNodeObject(objWaitAction, sourceNode);
			newNodeObject.details[0] = {
				interval: 120,
				interval_mode: 'days',
			}
			newNodeObject.interval_mode = 'days';
			newNodeObject.parentId = sourceNode.childId;
			newNodeObject.decisionFlg = 0;
			targetNode.parentId = newNodeObject.childId;
			nodesArray.nodes.push(newNodeObject);
		} else if (sourceNode.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
			interval = sourceNode.details[0].interval;
			interval_mode = sourceNode.details[0].interval_mode;
		}

		if (automationCampaign.data.id > 0 && targetNode.hasOwnProperty('id') && targetNode.id > 0) {
			let copyCurrNode = { ...targetNode };
			copyCurrNode.nodeStatusFlg = 3;
			copyCurrNode.detailStatusFlg = 3;
			targetNode.prevDetails = copyCurrNode;
		}

	    if(targetNode.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL){
			targetNode.id = "";
			targetNode.actionId = 19;
			targetNode.actionName = constants.AUTOMATION_DESIGNER_ADD_MESSAGE;
			targetNode.actionLabel = getLocalizedStrings().label.AUTOMATION_DESIGNER['Send Email'];
			targetNode.processId = "";
			targetNode.secProcessId = "";
			targetNode.decisionFlg = 0;
			targetNode.detailStatusFlg = 1;
			targetNode.message_id = 0;
			if (targetNode.hasOwnProperty('details')) {
				let detailsArray = targetNode.details[0];
				if (isValidParam(detailsArray) && detailsArray.hasOwnProperty('id')) {
					detailsArray.message_id = 0;
					delete detailsArray.dbp;
					delete detailsArray.interval_mode;
					delete detailsArray.message_name;
					if (interval_mode === 'days') {
						interval = interval / 24;
					}
					detailsArray.dbp = interval;
					detailsArray.interval_mode = interval_mode;
					delete detailsArray.id;
				}
			}
		}else if(targetNode.actionName === constants.AUTOMATION_DESIGNER_SEND_SMS){
			targetNode.id = "";
			targetNode.actionId = 32;
			targetNode.actionName = constants.AUTOMATION_DESIGNER_ADD_SMS;
			targetNode.actionLabel = getLocalizedStrings().label.AUTOMATION_DESIGNER['Send SMS'];
			targetNode.processId = "";
			targetNode.secProcessId = "";
			targetNode.decisionFlg = 0;
			targetNode.detailStatusFlg = 1;
			targetNode.message_id = 0;
			if (targetNode.hasOwnProperty('details')) {
				let detailsArray = targetNode.details[0];
				if (isValidParam(detailsArray)) {
					if (interval_mode === 'days') {
						interval = interval / 24;
					}
					detailsArray.dbp = interval;
					detailsArray.interval_mode = interval_mode;
				}
			}
		}
		// delete targetNode.referenceId;
		dispatch(addNode(nodesArray));
		return false;
	}

	function removeConnectionCallBack(sourceNode, targetNode, conn, _jsPlumbInst) {
		let msg = getLocalizedStrings().message.AUTOMATION_DESIGNER.REMOVE_CONNECTION_ALERT + ' ' + targetNode.actionLabel + '.' + getLocalizedStrings().message.AUTOMATION_DESIGNER.CONTINUE_ALERT;
		sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => onConfirmDeleteConnection(sourceNode, targetNode, conn, _jsPlumbInst), null);
	}

	function onConfirmDeleteConnection(sourceNode, targetNode, conn, _jsPlumbInst) {
		if (targetNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
			let nodesArray = automationCampaign.data.nodes;
			let targetShouldChange = targetNeedsToChange(nodesArray, targetNode, sourceNode);
			if (targetShouldChange) {
				let currNode = targetNode;
				do {
					if (currNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL || (currNode.acionType && currNode.acionType.toString() === '1')) {
						break;
					}
					if (currNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
						if (automationCampaign.data.id > 0 && currNode.hasOwnProperty('id') && currNode.id > 0) {
							let copyCurrNode = { ...currNode };
							copyCurrNode.nodeStatusFlg = 3;
							copyCurrNode.detailStatusFlg = 3;
							currNode.prevDetails = copyCurrNode;
						}
						currNode.actionId = 26;
						currNode.actionName = constants.AUTOMATION_DESIGNER_AUTO_MAIL;
						currNode.actionLabel = getLocalizedStrings().label.AUTOMATION_DESIGNER['Send Email'];
						currNode.processId = null;
						currNode.secProcessId = 0;
						currNode.decisionFlg = 0;
						currNode.referenceId = 0;
						currNode.detailStatusFlg = 1;
						currNode.id = 0;
						if (currNode.hasOwnProperty('details')) {
							let detailsArray = currNode.details[0];
							if (isValidParam(detailsArray) && detailsArray.hasOwnProperty('message_id')) {
								detailsArray.id = 0;
								detailsArray.t_status = 'New';
								delete detailsArray.message_id;
								delete detailsArray.dbp;
								delete detailsArray.interval_mode;
								delete detailsArray.message_name;
							}
							let prevWaitNode = getNodeByChildId(nodesArray, currNode.parentId);
							if (prevWaitNode.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
								detailsArray.interval_mode = prevWaitNode.details[0].interval_mode;
								if (prevWaitNode.details[0].interval_mode === 'days') {
									detailsArray.interval = prevWaitNode.details[0].interval / 24;
								} else {
									detailsArray.interval = prevWaitNode.details[0].interval;
								}
							}
						}
					}
					currNode = getNodeByParentId(nodesArray, currNode.childId);
					if (isValidParam(currNode)) {
						currNode = currNode.filter(n => n.nodeStatusFlg !== 3);
						currNode = currNode[0];
					}
				} while (currNode);
			}
		}
		_jsPlumbInst?.deleteConnection(conn);
		showPlusIcon(automationCampaign.data.nodes, targetNode);
		targetNode.parentId = 0;
		//dispatch(addNode(automationCampaign.data));

		if (sourceNode !== undefined && sourceNode.hasOwnProperty('actionName') && sourceNode.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
			if (targetNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
				if (targetNode.hasOwnProperty('details')) {
					let targetNodeDetailsArray = targetNode.details[0];
					if (isValidParam(targetNodeDetailsArray)) {
						targetNodeDetailsArray.dbp = 0;
					}
				}
			}
		}

	}

	function removeConnection(isRemove, sourceNode, targetNode, conn, _jsPlumbInst) {
		 
		try {
			let dialogData = null;
			let msg = null;
			if (isRemove) {
				if (targetNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
					let message_id = null;
					if (targetNode.hasOwnProperty('details') && isValidParam(targetNode.details[0]) && targetNode.details[0].hasOwnProperty('message_id')) {
						message_id = targetNode.details[0].message_id;
					}
					if (message_id != null) {
						let url = endPoints.AUTOMATION_DESIGNER.CAN_MSG_BE_DELETED;
						let params = {
							messageId: message_id,
						}
						let promise = Promise.resolve(HTTPClient.get(url, params));
						promise.then((response) => {
							if (response.can_msg_be_deleted === true) {
								removeConnectionCallBack(sourceNode, targetNode, conn, _jsPlumbInst);
							} else {
								dialogData = {
									message: getLocalizedStrings().message.AUTOMATION_DESIGNER.WONT_BE_ABLE_TO_DELETE,
								}
								openDialog(dialogData);
								return false;
							}
						});
					} else {
						removeConnectionCallBack(sourceNode, targetNode, conn, _jsPlumbInst);
					}
				} else {

					msg = getLocalizedStrings().message.AUTOMATION_DESIGNER.REMOVE_CONNECTION_ALERT + ' ' + targetNode.actionLabel + '.' + getLocalizedStrings().message.AUTOMATION_DESIGNER.CONTINUE_ALERT;
					sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => onConfirmDeleteConnectionAutoMation(sourceNode, targetNode, conn, _jsPlumbInst), null);

				}

			} else {
				dialogData = {
					message: getLocalizedStrings().message.AUTOMATION_DESIGNER.MSG_CANT_BE_DELETED,
				}
				openDialog(dialogData);
				return false;
			}
		} catch (error) {
			console.error("Error in 'automationBuilder.js -> removeConnection()':" + error);
		}
	}

	function onConfirmDeleteConnectionAutoMation(sourceNode, targetNode, conn, _jsPlumbInst) {
		_jsPlumbInst?.deleteConnection(conn);
		showPlusIcon(automationCampaign.data.nodes, targetNode);
		targetNode.parentId = 0;
		//dispatch(addNode(automationCampaign.data));

		if (sourceNode !== undefined && sourceNode.hasOwnProperty('actionName') && sourceNode.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
			if (targetNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE || targetNode.actionName === constants.AUTOMATION_DESIGNER_ADD_SMS) {
				if (targetNode.hasOwnProperty('details')) {
					let targetNodeDetailsArray = targetNode.details[0];
					if (isValidParam(targetNodeDetailsArray)) {
						targetNodeDetailsArray.dbp = 0;
					}
				}
			}
		}

		// convert all the Touch messages to Auto Email and the Add SMS to Send SMS
		let nodesArray = automationCampaign.data.nodes;
		let targetShouldChange = targetNeedsToChange(nodesArray, targetNode, sourceNode);
		if (targetShouldChange) {
			let currNode = targetNode;
			do {
				if (currNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL || (currNode.acionType && currNode.acionType.toString() === '1')) {
					break;
				}
				if (currNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
					if (automationCampaign.data.id > 0 && currNode.hasOwnProperty('id') && currNode.id > 0) {
						let copyCurrNode = { ...currNode };
						copyCurrNode.nodeStatusFlg = 3;
						copyCurrNode.detailStatusFlg = 3;
						currNode.prevDetails = copyCurrNode;
					}
					currNode.actionId = 26;
					currNode.actionName = constants.AUTOMATION_DESIGNER_AUTO_MAIL;
					currNode.actionLabel = getLocalizedStrings().label.AUTOMATION_DESIGNER['Send Email'];
					currNode.processId = null;
					currNode.secProcessId = 0;
					currNode.decisionFlg = 0;
					currNode.referenceId = 0;
					currNode.detailStatusFlg = 1;
					currNode.id = 0;
					if (currNode.hasOwnProperty('details')) {
						let detailsArray = currNode.details[0];
						if (isValidParam(detailsArray) && detailsArray.hasOwnProperty('message_id')) {
							detailsArray.id = 0;
							detailsArray.t_status = 'New';
							delete detailsArray.message_id;
							delete detailsArray.dbp;
							delete detailsArray.interval_mode;
							delete detailsArray.message_name;
						}
						let prevWaitNode = getNodeByChildId(nodesArray, currNode.parentId);
						if (prevWaitNode.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
							detailsArray.interval_mode = prevWaitNode.details[0].interval_mode;
							if (prevWaitNode.details[0].interval_mode === 'days') {
								detailsArray.interval = prevWaitNode.details[0].interval / 24;
							} else {
								detailsArray.interval = prevWaitNode.details[0].interval;
							}
						}

					}
				}
				if (currNode.actionName === constants.AUTOMATION_DESIGNER_ADD_SMS) {
					if (automationCampaign.data.id > 0 && currNode.hasOwnProperty('id') && currNode.id > 0) {
						let copyCurrNode = { ...currNode };
						copyCurrNode.nodeStatusFlg = 3;
						copyCurrNode.detailStatusFlg = 3;
						currNode.prevDetails = copyCurrNode;
					}
					currNode.actionId = 30;
					currNode.actionName = constants.AUTOMATION_DESIGNER_SEND_SMS;
					currNode.actionLabel = getLocalizedStrings().label.AUTOMATION_DESIGNER['Send SMS'];
					currNode.processId = null;
					currNode.secProcessId = 0;
					currNode.decisionFlg = 0;
					currNode.referenceId = 0;
					currNode.detailStatusFlg = 1;
					currNode.id = 0;
				}
				currNode = getNodeByParentId(nodesArray, currNode.childId);
				if (isValidParam(currNode)) {
					currNode = currNode.filter(n => n.nodeStatusFlg !== 3);
					currNode = currNode[0];
				}
			} while (currNode);
		}

	}

	function generateNodeObject(objActions, sourceNode) {
		let arrayNodes = automationCampaign.data.nodes;
		let maxId = 0;
		maxId = getMaxId(arrayNodes);
		let childId = 0;
		childId = maxId + 1;
		let processId = 0;
		if (objActions.actionName === constants.AUTOMATION_DESIGNER_SCORE_REACHED) {
			processId = "";
		}
		return {
			id: 0,
			parentId: 0,
			childId: childId,
			actionId: objActions.actionId,
			actionName: objActions.actionName,
			actionLabel: objActions.actionLabel,
			nodeStatusFlg: 1,
			detailStatusFlg: 1,
			status: "",
			processId: processId,
			secProcessId: 0,
			type: objActions.actionType,
			decisionFlg: 0,
			posX: sourceNode.posX,
			posY: sourceNode.posY + 100,
			details: [],
			referenceId: 0
		};
	}

	function getWebsiteUrlLinkNodeStatus(arrNodes, nodeId) {
		if (isValidParam(nodeId)) {
			let arrChildObject = getNodeByParentId(arrNodes, nodeId);
			arrChildObject = arrChildObject.filter(n => n.nodeStatusFlg !== 3);
			let count = 0;
			let details = {};
			if (isValidParam(arrChildObject) && arrChildObject.length > 0) {
				arrChildObject.filter((obj) => {
					if (obj != null && obj !== "undefined" && obj !== "" && obj.type === 1 && obj.actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK) {
						if (obj.parentId === nodeId) {
							count++;
						}
					}
				});
				details.nodeCount = count;

				count = 0;
				arrChildObject.filter((obj) => {
					if (obj != null && obj !== "undefined" && obj !== "" && obj.type === 1 && obj.actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK) {
						if (obj.parentId === nodeId && obj.hasOwnProperty("details") && obj.details.length > 0 && obj.details[0].websiteUrl !== "") {
							count++;
						}
					}
				});

				if (count > 0) {
					details.status = "LINK_EXIST";
				} else {
					details.status = "LINK_NOT_EXIST";
				}
			} else {
				details.nodeCount = 0;
				details.status = "NODE_NOT_FOUND";
			}
			return details;
		}
	}

	function makeSource(childId) {
		if (isValidParam(childId)) {
			let _jsPlumbInst = automationCampaign.jsPlumbInst;
			let sourceId = null;
			if (childId.includes("_N") || childId.includes("_Y")) {
				sourceId = childId;
			} else {
				sourceId = childId.toString() + '_I';
			}
			let nodeObject = getNodeByChildId(automationCampaign.data.nodes, sourceId);
			let maxConn = 1;
			if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
				maxConn = 4;
			}
			_jsPlumbInst?.makeSource(sourceId, {
				anchor: [
					"Continuous",
					{
						faces: [
							"top",
							"left",
							"bottom",
							"right"
						]
					}
				],
				connectionsDetachable: false,
				maxConnections: maxConn,
				onMaxConnections: (info, e) => {
					if (info.element.id.indexOf("_I") !== -1) {
						let dialogData = {
							message: getLocalizedStrings().message.AUTOMATION_DESIGNER.MAX_CONNECTION,
						}
						openDialog(dialogData);
					}
				}
			});
		}
	}

	function makeTarget(childId) {
		if (isValidParam(childId)) {
			let _jsPlumbInst = automationCampaign.jsPlumbInst;
			let targetId = childId.toString();
			_jsPlumbInst?.makeTarget(targetId, {
				anchor: ["Continuous", {
					faces: ["left", "right", "top", "bottom"]
				}],
				dropOptions: {
					hoverClass: "dragHover"
				},
				allowLoopback: false,
				connectionsDetachable: false,
				maxConnections: 1,
				onMaxConnections: (info, e) => {
					let dialogData = {
						message: getLocalizedStrings().message.AUTOMATION_DESIGNER.MAX_CONNECTION,
					}
					openDialog(dialogData);
				}
			});
		}
	}

	function connectDiagram(nodeList) {
		let campaignStatus = automationCampaign.data.status;
		map(nodeList, (node) => {
			if (node.parentId != null && node.detailStatusFlg !== 3 && node.nodeStatusFlg !== 3) {
				let sourceId = node.parentId;
				let targetId = node.childId;
				if (node.type === 2) {
					let sourceNode = getNodeByChildId(propsNodes, sourceId);
					if (node.actionName === "WAIT") {
						if (isValidParam(sourceNode)) {
							if (sourceNode.type === 1) {
								if (node.decisionFlg === 2) {
									sourceId = sourceId + '_N';
								} else {
									sourceId = sourceId + "_Y";
								}
							}
						}
					} else {
						if (isValidParam(sourceNode)) {
							if (sourceNode.type === 1) {
								if (sourceNode.decisionFlg === 2) {
									sourceId = sourceId + '_N';
								} else {
									sourceId = sourceId + "_Y";
								}
							}
						}
					}
				}

				if (document.getElementById(sourceId) && document.getElementById(targetId)) {
					 
					const conn = jsPlumbInst?.connect({
						source: sourceId.toString(),
						target: targetId.toString(),
						anchor: [
							"Continuous",
							{
								faces: [
									"top",
									"left",
									"bottom",
									"right"
								]
							}
						]
					});
				}
				if (node.actionName === constants.AUTOMATION_DESIGNER_ADD_TASK ||
					node.actionName === constants.AUTOMATION_DESIGNER_ASSIGN) {
					let parentNodeDetails = getParentDecision(propsNodes, node.childId);
					if (isValidParam(parentNodeDetails)) {
						if (parentNodeDetails.hasOwnProperty('actionName')) {
							let actionName = parentNodeDetails.actionName;
							if (isValidParam(actionName)) {
								if (node.hasOwnProperty('details')) {
									let detailsArray = node.details[0];
									if (isValidParam(detailsArray)) {
										if (node.actionName === constants.AUTOMATION_DESIGNER_ADD_TASK) {
											if (detailsArray.hasOwnProperty('days_after')) {
												if (actionName === constants.AUTOMATION_DESIGNER_WEB_FORM ||
													actionName === constants.AUTOMATION_DESIGNER_WEB_FORM_SUBMIT) {
													detailsArray.days_after = constants.AUTOMATION_DESIGNER_DECISION_WEBFORM_SUBMITTED;
												} else if (actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK) {
													detailsArray.days_after = constants.AUTOMATION_DESIGNER_DECISION_LINK_CLICKED;
												} else if (actionName === constants.AUTOMATION_DESIGNER_EMAIL_OPEN) {
													detailsArray.days_after = constants.AUTOMATION_DESIGNER_DECISION_EMAIL_OPENED;
												} else if (actionName === constants.AUTOMATION_DESIGNER_UNSUBSCRIBE) {
													detailsArray.days_after = constants.AUTOMATION_DESIGNER_DECISION_UNSUBSCRIBED;
												} else if (actionName === constants.AUTOMATION_DESIGNER_LANDING_PAGE) {
													detailsArray.days_after = constants.AUTOMATION_DESIGNER_DECISION_LANDING_PAGE_SUBMITTED;
												}
											}
										} else if (node.actionName === constants.AUTOMATION_DESIGNER_ASSIGN) {
											if (detailsArray.hasOwnProperty('qualifier_1')) {
												if (actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK) {
													detailsArray.qualifier_1 = constants.AUTOMATION_DESIGNER_DECISION_LINK_CLICKED;
												} else if (actionName === constants.AUTOMATION_DESIGNER_EMAIL_OPEN) {
													detailsArray.qualifier_1 = constants.AUTOMATION_DESIGNER_DECISION_EMAIL_OPENED;
												} else if (actionName === constants.AUTOMATION_DESIGNER_UNSUBSCRIBE) {
													detailsArray.qualifier_1 = constants.AUTOMATION_DESIGNER_DECISION_UNSUBSCRIBED;
												} else if (actionName === constants.AUTOMATION_DESIGNER_WEB_FORM) {
													detailsArray.qualifier_1 = constants.AUTOMATION_DESIGNER_DECISION_WEBFORM_SUBMITTED;
												}
											}
										}
									}
								}
							}
						}
					}
				}

				if (node.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
					let parentId = node.parentId;
					if (isValidParam(parentId)) {
						let parentObject = getNodeByChildId(propsNodes, parentId);
						if (isValidParam(parentObject)) {
							if (parentObject.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL) {
								if (node.hasOwnProperty('details')) {
									let childDetailsArray = node.details[0];
									if (isValidParam(childDetailsArray)) {
										if (childDetailsArray.hasOwnProperty('startDate')) {
											let childStartDate = childDetailsArray.startDate;
											let childHdate = childDetailsArray.hDate;
											if (!!childStartDate && childStartDate !== "") {
												if (parentObject.hasOwnProperty('details')) {
													let parentDetailsArray = parentObject.details[0];
													if (isValidParam(parentDetailsArray)) {
														parentDetailsArray.startDate = childStartDate;
														parentDetailsArray.hDate = childHdate;
														childDetailsArray.startDate = "";
														childDetailsArray.hDate = "";
													}
												}
											}
										}
									}
								}
							}
							if (parentObject.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
								document.getElementById(parentObject.childId + '_P').style.display = 'none';
							}
						}
					}
				}
			}
		});

		let data = automationCampaign.data;
		let autoTempName = getStringParam(data.template_name);

		map(nodeList, (node) => {
			if (node.parentId != null && node.detailStatusFlg !== 3 && node.nodeStatusFlg !== 3) {
				if (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) {
					if (node.actionName === constants.AUTOMATION_DESIGNER_START) {
						makeSource(node.childId.toString());
					} else if (node.actionName !== constants.AUTOMATION_DESIGNER_START) {
						if (node.type === 1) {
							if (node.actionName === constants.AUTOMATION_DESIGNER_WEB_FORM_SUBMIT ||
								node.actionName === constants.AUTOMATION_DESIGNER_UNSUBSCRIBE ||
								node.actionName === constants.AUTOMATION_DESIGNER_VISITED_WEBSITE ||
								node.actionName === constants.AUTOMATION_DESIGNER_SCORE_REACHED ||
								(node.actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK &&
									node.hasOwnProperty('details') && node.details.length > 0 && node.details[0].hasOwnProperty("websiteUrl") &&
									node.details[0].websiteUrl !== null && node.details[0].websiteUrl !== undefined &&
									node.details[0].websiteUrl !== "")) {
								// disable no
							} else {
								if (autoTempName !== constants.BIRTHDAY_CAMPAIGN_TEMPLATE_NAME &&
									autoTempName !== constants.PET_BIRTHDAY_CAMPAIGN_TEMPLATE_NAME) {
									makeSource(node.childId.toString() + "_N");
								}
							}
							makeSource(node.childId.toString() + "_Y");
							makeTarget(node.childId.toString());
						} else {
							makeSource(node.childId.toString());
							makeTarget(node.childId.toString());
						}
					}
				}
			}
		});

		jsPlumbInst?.repaintEverything();
	}

	if (diagram != null) {
		return (
			<div ref={drop} style={{ ...style }}>
				{diagram}
			</div>
		)
	} else {
		let contentHeight = window.innerHeight - 240;
		let top = (contentHeight - 10) / 2
		return (
			<div style={{ width: '100%', height: contentHeight }}>
				<div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
					<div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
						<ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }}/>
					</div>
				</div>
			</div>
		);
	}
}
export default AutomationBuilder;
