
import React from 'react';
import { useRef,forwardRef,useImperativeHandle } from 'react';
import { constants, OBJECT_TABLEID_MAP, TABLEID_OBJECT_MAP } from '../../../services/constants/constants';
import { isValidParam, getArrayParam, getBooleanParam, getStringParam, getIntParam, isUrl, getObjectParam, getFloatParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { getAppDrawer, getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import { LinkToType } from '../../../services/constants/link';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { addTab, getActiveTabInfo, updateTabInfo, TYPE_LIST_VIEW } from '../../../services/helper/sfTabManager';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import ShowCircularProgress from '../components/circularProgress';
import {Button, Chip, FormControl, MenuItem, InputLabel, Select,TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Flatpickr from 'react-flatpickr';
import { dateFormat, dateFormatFlatPicker, formatCurrency, phoneFormat } from '../../../services/helper/utils';
import { setHeader } from '../../../services/actions/headerActions';
import moment from 'moment';
import * as sfDialogs from '../components/sfDialogs';
import { hasAccessPermission, saveRecentRecord, getRecentRecord } from '../../../services/helper/common';
import { getCookie, setCookie } from '../../../services/helper/sfCookies';
import { refreshPurchaseOrder } from '../../../services/actions/detailViewActions';
import NoRecords from '../components/noRecord';
import _ from 'lodash';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMounted } from '../../../services/actions/listViewAcions';

const PurchaseOrder =(object)=> {
    const dispatch = useDispatch();
    const editPurchaseOrder = useRef(null);
    const [mounted, setMounted] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [purchaseObject, setPurchaseObject] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSavedDisabled, setIsSavedDisabled] = useState(false);
    const [orderObj, setOrderObj] = useState(null);
    const [id, setId] = useState(null);
    const [parentRecordId, setParentRecordId] = useState(null);
    const [parentObject, setParentObject] = useState(null);


    const appState = useSelector((state) => state.app);
    const sfForm = useSelector((state) => state.sfForm);
    const purchaseOrder = useSelector((state) => state.purchaseOrder);

    useEffect(() => {
        dispatch(setHeader(object, null, null, false, false));
        getRecordInformation();
      }, []);
    useEffect(() => {
        if (purchaseOrder.isRefresh) {
            getRecordInformation();
        }
    }, []);

    const getRecordInformation = () => {
        let params = null;
        var promise = null;
            let activeTabInfo = getObjectParam(getActiveTabInfo());
            setId(getIntParam(activeTabInfo.id));
            setParentRecordId(getIntParam(activeTabInfo.parentRecordId));
            setParentObject(getStringParam(activeTabInfo.parentObject));

            let call_from = getStringParam(activeTabInfo.object);
            params = {};

            params.orderid = getIntParam(activeTabInfo.id);
            params.parentRecordId = getIntParam(activeTabInfo.parentRecordId);
            params.parentTableId = getIntParam(OBJECT_TABLEID_MAP[getStringParam(activeTabInfo.parentObject)]);
            params.tableId = OBJECT_TABLEID_MAP[object.object];

            promise = Promise.resolve(HTTPClient.get(endPoints.PURCHASE_ORDER.GET_PURCHASE_ORDER, params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response) && response.status !== -1) {
                        let orderObj = response.orderInfo;
                        if (activeTabInfo.type == constants.NEW) {
                            setIsEditMode(true);
                            orderObj.orderNo = new Date().valueOf().toString();
                        } if (activeTabInfo.type == constants.EDIT && orderObj.status == constants.NEW) {
                            setIsEditMode(true);
                            delete activeTabInfo.type;
                            updateTabInfo(activeTabInfo);
                        }
                        setOrderObj(orderObj);

                        if (getIntParam(orderObj.orderid) > 0) {
                            let recentParams = { recordId: orderObj.orderid, type: "Object" };
                            saveRecentRecord(object, recentParams);
                            getRecentRecord(object);
                        }
                        setMounted(true);
                        dispatch(refreshPurchaseOrder(false));
                    }
                })
            }
    }

    const generateQuoteTemplate = (callFor) => {
        let tempOrderObj = orderObj;
        let template = null;
        let lineiteminfo = getArrayParam(orderObj.lineiteminfo);
        let productRowList = ['name', 'desc', 'qty', 'uprice', 'amount'];
            let templateObj = templatesList.find(f => f.object == object.object);
            if (isValidParam(templateObj)) {
                template = templateObj.template;
                let tempDetails = tempOrderDetails.find(f => f.object == object.object);
                tempDetails = isValidParam(tempDetails) ? tempDetails : {};

                template = template.replace('ORDER-NO', getStringParam(tempDetails.number));
                template = template.replace('ORDER-DATE', getStringParam(tempDetails.orderDate));

                let productTableRow = '';
                let subtotal = '';
                lineiteminfo.forEach((item, index) => {
                    let productTableData = '';
                    if (item.name !== '') {
                        productRowList.forEach(key => {
                            let textAlign = (key == 'name' || key == 'desc') ? 'left' : 'center';
                            let value = item[key];
                            if (key === 'amount') {
                                value = Number(item['qty']) * Number(item['uprice']);
                                subtotal = Number(subtotal) + Number(value);
                            }
                            if (key == 'amount' || key == 'uprice') {
                                value = (value !== '' || value !== 0) ? '$' + formatCurrency(value) : '';
                            }
                            productTableData += `<td style="padding: 10px; border: 1px solid #908989; border-radius: 10px; text-align: ${textAlign};">${value}</td>`
                        });
                        productTableRow += `<tr style="padding: 5px; height: inherit;">${productTableData}</tr>`
                    }
                });
                let discountTR = ` <tr style="height: inherit;">
                                <td colspan="3"></td>
                                <td style="font-weight: bold;">DISCOUNT</td>
                                <td style="padding: 3px;">$discount</td>
                             </tr>`

                subtotal = getFloatParam(subtotal);
                let grandtotal = getFloatParam((Number(subtotal) + Number(tempOrderObj.taskrate)) - Number(tempOrderObj.discount));
                let blanceDue = getFloatParam(Number(grandtotal) - Number(getFloatParam(tempOrderObj.paymentAmount)));
                let taskrate = formatCurrency(getFloatParam(tempOrderObj.taskrate));
                subtotal = formatCurrency(subtotal);
                grandtotal = formatCurrency(grandtotal);
                blanceDue = formatCurrency(blanceDue);

                template = template.replace('productTable', productTableRow);
                template = template.replace('subtotal', subtotal);
                template = template.replace('grandtotal', grandtotal);
                template = template.replace('balancedue', blanceDue);
                template = template.replace('taskrate', taskrate);

                let paymentAmount = Number(getFloatParam(tempOrderObj.paymentAmount));
                let paymentTR = ` <tr style="height: inherit;">
                                <td colspan="3"></td>
                                <td style="font-weight: bold;">AMOUNT RECEIVED</td>
                                <td style="padding: 3px;">$paymentAmount</td>
                            </tr>`;

                let PaymentTR1 = paymentAmount > 0 ? paymentTR.replace('paymentAmount', formatCurrency(getFloatParam(paymentAmount))) : '';
                template = template.replace('PAYMENT_AMOUNT', PaymentTR1);

                Object.keys(tempOrderObj).forEach(name => {
                    if (name == 'billinfo') {
                        let tdInfo = `  <td>info</td>`;
                        let emptyCustomInfoArr = [];
                        Object.keys(tempOrderObj['billinfo']).forEach(ele => {
                            if (getStringParam(tempOrderObj.billinfo[ele]) === '') {
                                emptyCustomInfoArr.unshift('billinfo-' + ele);
                            } else if (getStringParam(tempOrderObj.billinfo[ele]) !== '' && emptyCustomInfoArr.length === 0) {
                                template = template.replace('billinfo-' + ele, getStringParam(tempOrderObj.billinfo[ele]));
                            } else if (getStringParam(tempOrderObj.billinfo[ele]) !== '' && emptyCustomInfoArr.length !== 0) {
                                template = template.replace(emptyCustomInfoArr[emptyCustomInfoArr.length - 1], getStringParam(tempOrderObj.billinfo[ele]));
                                emptyCustomInfoArr.pop();
                                emptyCustomInfoArr.unshift('billinfo-' + ele);
                            }
                        });
                        if (emptyCustomInfoArr.length > 0) {
                            emptyCustomInfoArr.forEach((info) => {
                                template = template.replace(info, '');
                            })
                        }
                    } else if (name === 'companyinfo') {
                        Object.keys(tempOrderObj['companyinfo']).forEach(ele => {
                            template = template.replace('companyinfo-' + ele, getStringParam(tempOrderObj.companyinfo[ele]));
                        });
                    } else {
                        if (name === 'discount') {
                            template = getFloatParam(tempOrderObj.discount) > 0 ? template.replace('DISCOUNT', discountTR).replace('discount', formatCurrency(getStringParam(tempOrderObj[name]))) : template.replace('DISCOUNT', '');
                        } else if (name === 'currency') {
                            template = template.replace(/\$/g, getStringParam(tempOrderObj[name]));
                        } else {
                            template = template.replace(name, getStringParam(tempOrderObj[name]));
                        }
                        if (name === 'header') {
                            template = template.replace(name.toUpperCase(), getStringParam(tempOrderObj[name].toUpperCase()));
                        }
                    }
                });
            }
        return template;
    }

    const generateHeaderbuttons = () => {
        let element = [];
        let tempOrderObj = orderObj;
        let myclientId = tempOrderObj.hasOwnProperty('myclientId') ? getIntParam(tempOrderObj.myclientId) : 0;
        let status = tempOrderObj.hasOwnProperty('status') ? getStringParam(tempOrderObj.status) : 'New';
        try {
            let list = [];
            if (isEditMode) {
                if (tempOrderObj.hasOwnProperty('companyinfo')) {
                    list = [{ name: 'save', label: getLocalizedStrings().label.COMMON.SAVE, status: '' }];
                    if (tempOrderObj.orderid > 0) {
                        list.push({ name: 'cancel', label: getLocalizedStrings().label.COMMON.CANCEL, status: '' });
                    }
                }
            } else {
                if (status === 'New') {
                    list = [{ name: 'send', label: getLocalizedStrings().label.MAIL.SEND, status: 'Sent' }, { name: 'edit', label: getLocalizedStrings().label.COMMON.EDIT, status: '' }, { name: 'print', label: getLocalizedStrings().label.COMMON.PRINT, status: '' }];
                } else if (status === 'Sent') {
                    list = [{ name: 'receive', label: getLocalizedStrings().label.COMMON.RECEIVE, status: 'Received' }, { name: 'print', label: getLocalizedStrings().label.COMMON.PRINT, status: '' }];
                } else if (status === 'Received') {
                    list = [{ name: 'close', label: getLocalizedStrings().label.COMMON.CLOSE, status: 'Closed' }, { name: 'print', label: getLocalizedStrings().label.COMMON.PRINT, status: '' }];
                } else if (status === 'Closed') {
                    list = [{ name: 'print', label: getLocalizedStrings().label.COMMON.PRINT, status: '' }];
                }
            }

            element = list.map(item => {
                let buttonStyle = item.name === 'cancel' ? { ...styles.secondaryButton, verticalAlign: 'top' } : { ...styles.primaryButton, verticalAlign: 'top' }
                return (
                    <Button
                        key={`key-${item.name}`}
                        onClick={()=>handleActions(item.name, item.status)}
                        style={{ ...styles.primaryButton, verticalAlign: 'top' }}
                        labelstyle={{ fontWeight: 'normal' }}
                        disabled={isSavedDisabled}
                    >{item.label}</Button>
                );
            });
        } catch (error) {
            console.error("Error in 'purchaseOrder,js -> generateHeaderbuttons()':" + error);
        }
        return element;
    }

    const generateSalesHeaderbuttons = () => {
        let element = [];
        let tempOrderObj = orderObj;
        let status = tempOrderObj.hasOwnProperty('status') ? getStringParam(tempOrderObj.status) : 'New';
        try {
            let list = [];
            if (isEditMode) {
                if (tempOrderObj.hasOwnProperty('companyinfo')) {
                    list = [{ name: 'save', label: 'Save', status: '' }];
                    if (tempOrderObj.orderid > 0) {
                        list.push({ name: 'cancel', label: 'Cancel', status: '' });
                    }
                }
            } else {
                if (status === 'New') {
                    list = [{ name: 'accept', label: 'Accept', status: 'Accepted' }, { name: 'edit', label: 'Edit', status: '' }, { name: 'print', label: 'Print', status: '' }];
                } else if (status === 'Accepted') {
                    list = [{ name: 'confirm', label: 'Confirm', status: 'Confirmed' }, { name: 'edit', label: 'Edit', status: '' }, { name: 'print', label: 'Print', status: '' }];

                } else if (status === 'Confirmed') {
                    list = [{ name: 'fulfill', label: 'Fulfill', status: 'Fulfilled' }, { name: 'print', label: 'Print', status: '' }];

                } else if (status === 'Fulfilled') {
                    list = [{ name: 'ship', label: 'Ship', status: 'Shipped' }, { name: 'print', label: 'Print', status: '' }];
                } else if (status === 'Shipped') {
                    list = [{ name: 'print', label: 'Print', status: '' }]
                }
            }

            element = list.map(item => {
                let buttonStyle = item.name === 'cancel' ? { ...styles.secondaryButton, verticalAlign: 'top' } : { ...styles.primaryButton, verticalAlign: 'top' }
                return (
                    <Button
                        key={`key-${item.name}`}
                        onClick={()=>handleActions(item.name, item.status)}

                        style={{ ...styles.primaryButton, verticalAlign: 'top' }}
                        labelstyle={{ fontWeight: 'normal' }}
                    > {item.label}</Button>
                );
            });
        } catch (error) {
            console.error("Error in 'purchaseOrder,js -> generateHeaderbuttons()':" + error);
        }
        return element;
    }

    const handleActions = (name, status) => {
        try {
            if (name === 'save') {
                handleSave();
            } else if (name === 'modify') {
                // editQuote();
            } else if (name === 'send') {
                handleSend();
            } else if (name === 'print') {
                handlePrint();
            } else if (name === 'receive' || name === 'close' || name === 'accept' || name === 'confirm' || name === 'fulfill' || name === 'ship') {
                handleStatusUpdate(status);
            } else if (name === 'edit') {
                let hasPermission = hasAccessPermission(constants.QUOTATION_OBJECT, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
                if (hasPermission) {
                    changeEditMode();
                } else {
                    let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null)
                }
            } else if (name === 'download') {
                // downloadPdf();
            } else if (name === 'cancel') {
                changeEditMode();
            }

        } catch (error) {
            console.error("Error in 'purchaseOrder.js -> handleActions()':" + error);
        }
    }

    const handleSave = () => {
        let params = null;
        var promise = null;
        let subtotal = 0;
        let tempOrderObj ;
        if (isValidParam(editPurchaseOrder)) {
            tempOrderObj = editPurchaseOrder.current.tempOrderObj;
        }
                let isValid = validate(tempOrderObj);
                if (isValid) {
                    getArrayParam(tempOrderObj.lineiteminfo).forEach(e => {
                        subtotal = Number(subtotal) + (Number(e.qty) * Number(e.uprice));
                    });
                    subtotal = getFloatParam(subtotal);
                    let grandtotal = getFloatParam((Number(subtotal) + Number(tempOrderObj.taskrate)) - Number(tempOrderObj.discount));
                    tempOrderObj.subtotal = getStringParam(subtotal);
                    tempOrderObj.grandtotal = getStringParam(grandtotal);
                    params = {
                        id: getIntParam(orderObj.orderid),
                        parentRecordId: parentRecordId,
                        parentTableId: OBJECT_TABLEID_MAP[parentObject],
                        tableId: OBJECT_TABLEID_MAP[object.object],
                        orderInfo: tempOrderObj
                    }

                    tempOrderObj.lineiteminfo = tempOrderObj.lineiteminfo.filter(u => { return u.name !== 'Add a new product' });

                    if (params.id === 0) {
                        let cookieName = "LastPurchaseOrderSelectionType_" + appState.me.id + "_" + appState.me.projectId;
                        setCookie(cookieName, getStringParam(tempOrderObj.header));
                    }
                    setIsLoading(true);
                    setIsSavedDisabled(true);
                    promise = Promise.resolve(HTTPClient.post(endPoints.PURCHASE_ORDER.SAVE_PURCHASE_ORDER, params));
                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            if (response.status === 0) {
                                if (isValidParam(response.data)) {
                                    let tempOrderObj = response.data.orderInfo;
                                    setOrderObj(tempOrderObj);
                                    setIsEditMode(false);
                                    setIsLoading(false);
                                    setIsSavedDisabled(false);
                                    let message = '';
                                    if (object.object === constants.PURCHASE_ORDER_OBJECT) {
                                        message = getLocalizedStrings().message.SALES_PURCHASE_ORDER.SAVE_PURCHASE_SUCCESS;
                                    } else if (object.object === constants.SALES_ORDER_OBJECT) {
                                        message = getLocalizedStrings().message.SALES_PURCHASE_ORDER.SAVE_SALES_SUCCESS;
                                    }
                                    dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                    let activeTabInfo = getObjectParam(getActiveTabInfo());
                                    if (activeTabInfo.type === constants.NEW) {
                                        delete activeTabInfo.type;
                                        activeTabInfo.id = response.data.orderInfo.orderid;
                                        updateTabInfo(activeTabInfo);
                                    }
                                }
                            } else if (response.status === -1) {
                                if (isValidParam(response.error.message) && response.error.message === 'Duplicate Product Name') {
                                    let arrValue = getArrayParam(response.data);
                                    let msg = "";
                                    let msgArr = [];
                                    if (arrValue.length > 0) {
                                        arrValue.forEach(val => {
                                            msg = <span>{getLocalizedStrings().message.JOBS.PRODUCT + ' '} <b>{val}</b> {' ' + getLocalizedStrings().message.JOBS.ALREADY_EXIST}<br /></span>;
                                            msgArr.push(msg);
                                        });
                                        let alertMsg = <div style={{ overflow: 'auto', maxHeight: '400px', fontSize: '16px' }}>{msgArr}</div>;

                                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMsg, null);
                                        setIsLoading(false);
                                    }
                                }

                            }
                        })
                    }
                }
            
    }

    const validate = (orderObj) => {
        let isValidate = true;
        try {
            if (isValidParam(orderObj)) {

                getArrayParam(orderObj.lineiteminfo).forEach(e => {
                    e.name = e.name.trim();
                    if (e.productid === "-99" && e.name === "") {
                        e.name = 'Add a new product';
                    }
                });
                let lineiteminfo = getArrayParam(orderObj.lineiteminfo);

                lineiteminfo = lineiteminfo.filter(u => { return u.name !== 'Add a new product' });

                let subtotal = 0;
                getArrayParam(orderObj.lineiteminfo).forEach(e => {
                    subtotal = Number(subtotal) + (Number(e.qty) * Number(e.uprice));
                });
                subtotal = getFloatParam(subtotal);
                let grandtotal = getFloatParam((Number(subtotal) + Number(orderObj.taskrate)) - Number(orderObj.discount));
                let discount = Number(orderObj.discount);
                if (orderObj.salesRep === '') {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.SALES_PURCHASE_ORDER.SALES_REP_NAME_CANT_BLANK, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                } else if (lineiteminfo.length === 1 && lineiteminfo[0].name === '') {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.SALES_PURCHASE_ORDER.SELECT_ONE_ITEM, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                } else if (discount > 0 && (discount > subtotal)) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.SALES_PURCHASE_ORDER.DISCOUNT_AMOUNT_CANT_GREATER, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                } else if (grandtotal < 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.SALES_PURCHASE_ORDER.SPECIFY_TRANSC_AMOUNT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                } else {
                    let lineInfoArr = getArrayParam(orderObj.lineiteminfo);
                    let tempLineInfoArr = lineInfoArr.filter(u => { return u.name.indexOf(":") > -1 });

                    if (tempLineInfoArr.length > 0) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.JOBS.PRODUCT_NAME_NOT_CONTAIN, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        return false;
                    }
                }
            }
        } catch (error) {
            console.error("Error in 'purchaseOrder.js -> validate()':" + error);
        }
        return isValidate;
    }

    const handlePrint = () => {
        try {

            let divContents = generateQuoteTemplate();
            var a = window.open('', '', 'height=1000, width=1000');
            a.document.write(divContents);
            a.document.close();
            a.print();
            a.close();
        } catch (error) {
            console.error("Error in 'purchaseOrder.js --> handlePrint()':" + error);
        }
    }

    const handleSend = () => {
        let url = null;
        let promise = null;
        let tempOrderObj = getObjectParam(orderObj);
        try {
            let html = generateQuoteTemplate('send');
            let orderid = getIntParam(tempOrderObj.orderid);
            let type = "Purchase Order";
            if (object.object === constants.SALES_ORDER_OBJECT) {
                type = 'Sale Order';
            }
            let params = {
                recordId: orderid,
                tableId: OBJECT_TABLEID_MAP[object.object],
                type,
                html
            }
            setIsLoading(true);
            url = endPoints.PURCHASE_ORDER.CREATE_PDF;
            promise = Promise.resolve(HTTPClient.post(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let fileName = type + '.pdf';
                    let fileUrl = getStringParam(response.data.url);
                    let data = {
                        email: getObjectParam(tempOrderObj.billinfo).email,
                        id: getIntParam(tempOrderObj.parentRecordId),
                        object: getStringParam(TABLEID_OBJECT_MAP[tempOrderObj.parentTableId]),
                        callFrom: object.object,
                        orderid: orderid,
                        attachFiles: [{
                            name: fileName,
                            src: fileUrl
                        }]
                    }
                    let style = {};
                    style.width = '90%';
                    let labelName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
                    dispatch(getAppCustomDrawer(true, labelName, LinkToType.TYPE_EMAIL, style, data, null, false));
                    setIsLoading(false);
                }
            })
        } catch (error) {
            console.error("Error in 'purchaseOrder.js -> handleSend()':" + error);
        }
    }

    const changeEditMode = () => {
        try {
            setIsEditMode(!getBooleanParam(isEditMode))
        } catch (error) {
            console.error("Error in 'purchaseOrder.js -> changeEditMode()':" + error);
        }
    }

    const handleStatusUpdate = (status) => {
        try {
            let orderid = orderObj.orderid;
            status = getStringParam(status);
            updatePurchaseOrderStatus(orderid, status, status + " Successfully.");
            dispatch(refreshPurchaseOrder(true));

        } catch (error) {
            console.error("Error in 'purchaseOrder.js -> handleStatusUpdate()':" + error);
        }
    }


    const updatePurchaseOrderStatus = (orderid, status, msg) => {
        let url = '';
        let param = null;
        try {
            param = {
                orderid: orderid,
                status: status,
                tableId: OBJECT_TABLEID_MAP[object.object]
            };
            url = endPoints.PURCHASE_ORDER.UPDATE_FIELDS;
            let promise = Promise.resolve(HTTPClient.get(url, param));
            promise.then((response) => {
                if (isValidParam(response.status !== -1)) {
                    dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    let tempOrderObj = orderObj;
                    setOrderObj({ ...tempOrderObj, status: status });
                    dispatch(refreshPurchaseOrder(true));
                }
            });

        } catch (error) {
            console.error("Error in 'sendMail.js -> updatePurchaseOrderStatus()':" + error);
        }
    }
        if (mounted) {
            let template = generateQuoteTemplate();
            let tempOrderObj = _.cloneDeep(orderObj);
            let isTenant = appState.me.is_tenant;
            let isShowQuote = orderObj.hasOwnProperty('companyinfo') ? true : isTenant ? true : false;
            let headerActions = null
            if (object.object == constants.PURCHASE_ORDER_OBJECT) {
                headerActions = generateHeaderbuttons();
            } else if (object.object == constants.SALES_ORDER_OBJECT) {
                headerActions = generateSalesHeaderbuttons();
            }
            if (getStringParam(tempOrderObj.status) == 'Deleted') {
                return <NoRecords object={object} />
            }
            if (isShowQuote) {
                return (
                    <div style={{ display: 'grid', gridTemplateColumns: 'minmax(1000px,1fr)', overflowY: 'auto' }}>
                        <div>
                            <div style={{ height: '5px', paddingTop: '5px' }}>
                                {isLoading &&
                                    <div style={{ textAlign: 'center' }}>
                                        <span className="loader"><span className="loader-box"></span><span className="loader-box"></span><span className="loader-box"></span></span>
                                    </div>}
                            </div>
                            <div style={{ textAlign: 'right', padding: '10px 20px 0px 0px' }}>
                                {headerActions}
                            </div>
                            <div style={{ padding: '20px 3%', width: '95%' }}>
                                {(!isEditMode) ? <span id={'order-id'} dangerouslySetInnerHTML={{ __html: (template) }} /> :
                                    <PurchaseOrderEdit ref= {editPurchaseOrder} orderObj={tempOrderObj} object={object} />
                                }
                            </div>
                            {(isEditMode) && <div style={{ textAlign: 'right', padding: '10px 20px 0px 0px' }}>
                                {headerActions}
                            </div>}
                        </div >
                    </div>
                );
            } else {
                let msg = getLocalizedStrings().message.SALES_PURCHASE_ORDER.SETUP_COMPANY_PROFILE;
                return (
                    <div style={{ transform: 'translateY(30%)', padding: '0px 20%' }}>
                        <div className="panel panel-danger">
                            <div className="panel-heading">Alert</div>
                            <div className="panel-body" style={{ height: '30%' }}>
                                <h4>{msg}</h4>
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            let contentHeight = window.innerHeight - 240;
            let top = (contentHeight - 10) / 2
            return (
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            );
        }
}



    const PurchaseOrderEdit = forwardRef(({ orderObj,object },ref) => {

    const dispatch = useDispatch();
    const [tempOrderObj, settempOrderObj] = useState(orderObj);
    const [actionsList, setActionsList] = useState({});
    const [mounted, setMounted] = useState(false);
    const [quoteNameList, setQuoteNameList] = useState(null);
    const [productinfoList, setProductinfoList] = useState(null);
    const [termsList, setTermsList] = useState(null);
    const sfForm = useSelector((state) => state.sfForm);
    const appState = useSelector((state) => state.app);
    const [dummyData, setDummyData] = useState(null);


    useEffect(() => {
        processData();
      }, []);

    const processData = () => {
        let tempOrderObjData = getObjectParam(tempOrderObj);
        let lineiteminfo = getArrayParam(tempOrderObjData.lineiteminfo);
            let quoteNameList = getArrayParam(tempOrderObjData.quotetype).map(m => { return { value: m, label: m } });

            let productinfoList = getArrayParam(tempOrderObjData.productinfo).map(m => { return { value: m.name, label: m.name, productid: m.productid, data: m } });
            let newOpt = { desc: "", name: 'Add a new product', productid: "-99", qty: "0", tax: "No", uprice: "0" };
            productinfoList.push({ value: 'Add a new product', label: 'Add a new product', productid: "-99", data: newOpt });

            let termsList = getArrayParam(tempOrderObjData.termsList).map((obj, index) => { return { value: obj.name, label: obj.name, key: obj.days } });

            let actionsList = { taskRateAction: { isEdit: false }, discountAction: { isEdit: false } };
            setMounted(true);
            setQuoteNameList(quoteNameList);
            setProductinfoList(productinfoList);
            setActionsList(actionsList);
            setTermsList(termsList);

            let subtotal = 0;
            lineiteminfo.forEach(e => {
                subtotal = Number(subtotal) + (Number(e.qty) * Number(e.uprice));
            });
            let taskratePerc = (Number(tempOrderObjData.taskrate) * 100) / subtotal;
            tempOrderObjData.taskratePerc = getFloatParam(taskratePerc);
            settempOrderObj(tempOrderObjData);
    }

    const handleAction = (name, type, event) => {
        let isUpdate = true;
            let value = isValidParam(event) && isValidParam(event.currentTarget) ? event.currentTarget.value : '';
            if(name === 'terms'){
                value= event.value;
            }
            let tempOrderObjData = tempOrderObj;
            if (value !== '') {
                if (name === 'discount' || name === 'taskratePerc') {
                    value = value.replace(/[^\d.-]/g, '');
                    let regExp = name === 'discount' ? /^\d+(?:\.\d{0,2})?$/ : name === 'taskratePerc' ? /^\d{0,2}(?:\.\d{0,2})?$/ : null;
                    isUpdate = regExp !== null ? regExp.test(value) : true;
                } else if (type === 'billinfo' && name === 'phone') {
                    let regExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
                    isUpdate = regExp.test(value);
                } else if (name === 'purchaseOrderNo') {
                    let regExp = /^[0-9]*$/g;
                    isUpdate = regExp.test(value);
                }
            }
            if (isUpdate) {
                if (type == 'billinfo') {
                    let billinfo = tempOrderObjData.billinfo;
                    billinfo[name] = value;
                    tempOrderObjData.billinfo = billinfo;
                } else if (tempOrderObjData.hasOwnProperty(name)) {
                    if (name === 'header') {
                        value = event.value;
                    }
                    if (name === 'terms') {
                        value = event.value;
                        if (value !== 'Custom') {
                            let key = event.key;
                            let pOValidDateObj = getStringParam(tempOrderObjData.orderDate) ? moment(tempOrderObjData.orderDate).add(key, 'days') : moment(new Date()).add(key, 'days');
                            pOValidDateObj = pOValidDateObj.format(dateFormat[appState.me.date_format]);
                            tempOrderObjData['quotevalid'] = pOValidDateObj;
                        }

                    }
                    tempOrderObjData[name] = value;
                }
                settempOrderObj({...tempOrderObjData});
                setDummyData(null);
            }
            if(name === 'taskratePerc'){
                let temp = tempOrderObj;
                temp.taskratePerc = value;
                settempOrderObj({...temp});
                let tempOrderObjData = tempOrderObj;
                let lineiteminfo = getArrayParam(tempOrderObjData.lineiteminfo);
                let subtotal = 0;
                lineiteminfo.forEach(e => {
                    subtotal = Number(subtotal) + (Number(e.qty) * Number(e.uprice));
                });
                subtotal = getFloatParam(subtotal);
                let taskrate = (Number(subtotal) * Number(value)) / 100;
                tempOrderObjData.taskrate = getFloatParam(taskrate);
                settempOrderObj({...tempOrderObjData});
            }
    }

    const handleEditAction = (isEdit, type) => {
        try {
            let tempActionsList = actionsList;
            if (type === 'taskrate') {
                let taskRateAction = getObjectParam(tempActionsList.taskRateAction);
                taskRateAction.isEdit = isEdit;
                tempActionsList.taskRateAction = taskRateAction;
            } else if (type === 'discount') {
                let discountAction = getObjectParam(tempActionsList.discountAction);
                discountAction.isEdit = isEdit;
                tempActionsList.discountAction = discountAction;
            }
            setActionsList({...tempActionsList});
        } catch (error) {
            console.error("Error in 'purchaseOrder,js -> PurchaseOrderEdit -> handleEditAction()':" + error);
        }
    }

    const handleFlatPickrDateChange = (type, date) => {
        try {
            let dateValue = new Date(date[0]);
            let selectedDate = new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate(), dateValue.getHours(), dateValue.getMinutes(), dateValue.getSeconds());
            const momentDate = moment(selectedDate);

            let value = momentDate.format(dateFormat[appState.me.date_format]);
            let tempOrderObjData = tempOrderObj;
            if (type === 'orderDate') {
                tempOrderObjData.orderDate = value;
            }
            settempOrderObj(tempOrderObjData);
        } catch (error) {
            console.error("Error in 'purchaseOrder,js -> PurchaseOrderEdit -> handleFlatPickrDateChange()':" + error);
        }
    }

    const onChangeProduct = (event,name, rowId, type) => {
        let tempOrderObjData = tempOrderObj;
        let lineiteminfo = getArrayParam(tempOrderObjData.lineiteminfo);
        try {

            let value = event.target.value;
            if (type === 'field') {
                let isUpdate = true;
                if (value !== '' && (name === 'qty' || name === 'uprice')) {
                    let regExp = name === 'qty' ? /^\d{0,4}(?:\.\d{0,2})?$/ : /^-?\d*(?:\.\d{0,2})?$/;
                    isUpdate = regExp.test(value);
                }
                if (isUpdate) {
                    lineiteminfo = lineiteminfo.map((m, index) => {
                        return (index === rowId) ? { ...m, [name]: value } : m;
                    });
                    settempOrderObj({...tempOrderObjData,lineiteminfo:lineiteminfo});
                }
            } else if (type === 'select') {
                let tempProductinfoList = getObjectParam(productinfoList);
                tempProductinfoList = tempProductinfoList.find(f => f.productid === value);
                if (tempProductinfoList) {
                    value = getStringParam(tempProductinfoList.value);

                    let productDataList = getObjectParam(tempProductinfoList.data);
                    lineiteminfo = lineiteminfo.map((m, index) => {
                        let obj = m;
                        if (index === rowId) {
                            Object.keys(m).forEach(e => {
                                if (e !== 'id') {
                                    obj = { ...obj, [e]: productDataList[e] };
                                }
                            })
                        }
                        return obj;
                    });

                    if (lineiteminfo.length > 0 && lineiteminfo.length === rowId + 1) {
                        let obj = {};
                        Object.keys(lineiteminfo[0]).forEach(e => {
                            if (e !== 'id') {
                                obj = { ...obj, [e]: '' };
                            }
                        });
                        lineiteminfo.push(obj);
                    }
                    settempOrderObj({...tempOrderObjData,lineiteminfo:lineiteminfo});
                }
            }
        } catch (error) {
            console.error("Error in 'purchaseOrder,js -> PurchaseOrderEdit -> onChangeProduct()':" + error);
        }
    }

    const handleDeleteLineItems = (index, id, event) => {
        try {
            let lineiteminfo = getArrayParam(tempOrderObj.lineiteminfo);
            let deletedLineItemsIds = [];
            if (lineiteminfo.length > 1) {
                lineiteminfo.splice(index, 1);
                let tempOrderObjData = tempOrderObj;
                settempOrderObj({...tempOrderObjData,lineiteminfo:lineiteminfo});
            } else {
                let obj = {};
                Object.keys(lineiteminfo[0]).forEach(e => {
                    if (e !== 'id') obj = { ...obj, [e]: '' };
                });
                let tempOrderObjData = tempOrderObj;
                tempOrderObjData.lineiteminfo[0] = obj
                settempOrderObj({...tempOrderObjData});
            }

            if (id > 0) {
                deletedLineItemsIds = getArrayParam(tempOrderObj.deletedLineItemsIds);
                deletedLineItemsIds.push({ 'id': id });
                let tempOrderObjData = tempOrderObj;
                tempOrderObjData.deletedLineItemsIds = deletedLineItemsIds;
                settempOrderObj({...tempOrderObjData});
            }
        } catch (error) {
            console.error("Error in 'purchaseOrder,js -> PurchaseOrderEdit -> handleDeleteLineItems()':" + error);
        }
    }

    const getMenuItems = () => {
        let objectItems = null;
        try {
            let objectList = getArrayParam(productinfoList);
            objectItems = objectList.map((item, index) => {
                return <MenuItem key={index + '-' + item.value} id={item.value} value={getObjectParam(item.data).productid} style={styles.popoverMenuItem} title={item.label} dataset={item.data}>{item.label}</MenuItem>;
            });
        } catch (error) {
            console.error("Error in 'purchaseOrder.js -> PurchaseOrderEdit -> getMenuItems()':" + error);
        }
        return objectItems;
    }

    const generateProductItems = () => {
        let tempOrderObjData = tempOrderObj;
        let lineiteminfo = getArrayParam(tempOrderObjData.lineiteminfo);
        let productRowList = [{ name: 'name', label: 'ITEM' }, { name: 'desc', label: 'DESCRIPTION' }, { name: 'qty', label: 'QTY' },
        { name: 'uprice', label: 'RATE' }, { name: 'amount', label: 'AMOUNT' }, { name: 'delete', label: '' }];
        let tempActionsList = actionsList;
        let subtotal = 0;
        lineiteminfo.forEach(e => {
            subtotal = Number(subtotal) + (Number(e.qty) * Number(e.uprice));
        });
        subtotal = getFloatParam(subtotal);
        let taskrate = (Number(subtotal) * Number(tempOrderObjData.taskratePerc)) / 100;
        // tempOrderObjData.taskrate = getFloatParam(taskrate);
        // settempOrderObj(tempOrderObjData);

        let grandtotal = getFloatParam((Number(subtotal) + Number(taskrate)) - Number(tempOrderObjData.discount));
        let balnceDue = getFloatParam(Number(grandtotal) - Number(getFloatParam(tempOrderObjData.paymentAmount)));
        let symbol = isValidParam(tempOrderObjData.currency) && tempOrderObjData.currency !== '' ? tempOrderObjData.currency : '$';
        let paymentAmount = Number(getFloatParam(tempOrderObjData.paymentAmount));

        let headerStyle = { border: '1px solid #ddd', height: 'inherit', paddingTop: '6px' };
        let tableGridTemplateColumns = '20% 39% 10% 13% 13% 5%';
        let btmColumnsStyle = { display: 'grid', gridTemplateColumns: '63% 17% 20%', height: '32px' };

            return (
                <div style={{ width: "100%", textAlign: "center" }} >
                    <div style={{ display: 'grid', gridTemplateColumns: tableGridTemplateColumns, height: '40Px', color: '#333', fontWeight: 'bold' }} >
                        {productRowList.map(obj => {
                            return <div style={headerStyle} title={obj.label}>{obj.label}</div>
                        })}
                    </div>
                    {lineiteminfo.map((item, index) => {
                        let editItemList = ['name', 'desc', 'qty', 'uprice'];
                        let lineItemId = getIntParam(item.id);

                        return (
                            <div className="quote-table" key={`line-item-tr-${item.productid}-${index}`} style={{ display: 'grid', gridTemplateColumns: tableGridTemplateColumns, textAlign: 'center', height: '101px' }}>
                                {productRowList.map(obj => {
                                    let key = obj.name;
                                    let components = null;
                                    let value = item[key];
                                    let pId = item['productid'];
                                    if (key === 'amount') {
                                        value = Number(item['qty']) * Number(item['uprice']);
                                        value = value !== 0 ? value : '';
                                    }
                                    if (editItemList.includes(key)) 
                                    {
                                        if (key === 'name' && pId !== "-99") {
                                            components =
                                                <div key={`line-item-td-${key}-${index}`} style={{ height: '100%', padding: '2px 2px' }}>
                                                    <FormControl variant="outlined" style={{ width: '100%'}}>
                                                        <InputLabel id="sf-purchaseorder-simple-select-outlined-label" className="sf-purchaseorder"></InputLabel>
                                                        <Select
                                                            placeholder='Select Item'
                                                            key={`product-list-${key}-${index}`}
                                                            value={pId}
                                                            title={value}
                                                            name={'product-' + key}
                                                            style={{ height: '95px' }}
                                                            className={"sf-fields-bg"}
                                                            onChange={(event)=>onChangeProduct(event, key, index, 'select')}>
                                                            {getMenuItems()}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                        } else {
                                            components =
                                                <div key={`line-item-td-${key}-${index}`} style={{ height: '95px', padding: '2px 2px' }}>
                                                    <FormControl style={{ width: '100%' }} className="sf-form-control" noValidate autoComplete="off">
                                                        <TextField
                                                            variant="outlined"
                                                            id={`product-field-${key}`}
                                                            key={`field-${key}-key`}
                                                            maxRows={3}
                                                            type='textarea'
                                                            rows={3}
                                                            value={value === 'Add a new product' ? "" : value}
                                                            title={value}
                                                            onChange={(event)=>onChangeProduct(event,key, index, 'field')}
                                                            inputprops={{maxLength:key === 'name' ? 128 : 1024 ,height: '95px'}}
                                                            autoFocus={value === 'Add a new product' ? true : false}
                                                            multiline={key === 'desc' ? true : false}
                                                            autoComplete="new-password"
                                                            className={"sf-fields-bg"}
                                                        />
                                                    </FormControl>
                                                </div>
                                        }
                                    } else {
                                        let isDelete = true;
                                        if (index + 1 === lineiteminfo.length && item.name === '') {
                                            isDelete = false;
                                        }
                                        if ((key === 'amount' || key === 'uprice') && value !== '') {
                                            value = symbol + formatCurrency(getFloatParam(value));
                                        }
                                        if (key === 'delete') {
                                            components =
                                                <div key={`line-item-td-${key}-${index}`} style={{ padding: '5px', height: '100%', borderLeft: '1px solid #ddd', lineHeight: '72px' }}>
                                                    {isDelete && <span className='page-action' title='Delete' style={{ color: '#717171', cursor: 'pointer', padding: '5px', fontSize: '16px' }} onClick={(event)=>handleDeleteLineItems( index, lineItemId,event)}><i className="fa fa-times"></i></span>}
                                                </div>
                                        } else {
                                            components =
                                                <div key={`line-item-td-${key}-${index}`} title={value} style={{ height: '100%', paddingTop: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: '60px' }}>
                                                    {value}
                                                </div>
                                        }
                                    }
                                    return components;
                                }
                                )}
                            </div>
                        );
                    })}
                    <div>
                        <div style={{ ...btmColumnsStyle, marginTop: '15px' }}>
                            <div></div>
                            <div className="text-ellipsis" style={{ fontWeight: 'bold', padding: '0px', textAlign: 'right' }}>SUBTOTAL</div>
                            <div title={formatCurrency(subtotal)}>{symbol}{formatCurrency(subtotal)}</div>
                        </div>
                        <div style={btmColumnsStyle}>
                            <div></div>

                            <div style={{ padding: '0px' }}>
                                <div style={{ display: 'flex' }}>
                                    <div className="text-ellipsis" style={{ fontWeight: 'bold', width: '100%', marginLeft: '20px' }}>TAX </div>
                                    {!actionsList.taskRateAction.isEdit ? <div className='page-row' id="purchase_tax" title={getFloatParam(tempOrderObj.taskratePerc) + "%"} onClick={()=>handleEditAction( true, 'taskrate')} style={{ marginLeft: '10px', cursor: 'pointer', width: '100%', whiteSpace: 'nowrap' }}>{getFloatParam(tempOrderObj.taskratePerc)} %</div> :
                                        <div style={{ display: 'flex' }}>
                                            <FormControl style={{ width: '100%' }} className="sf-form-control" noValidate autoComplete="off">
                                                <TextField
                                                    variant="outlined"
                                                    id={`task-rate-perc`}
                                                    key={`task-rate-perc-key`}
                                                    value={tempOrderObj.taskratePerc}
                                                    title={tempOrderObj.taskratePerc}
                                                    onChange={(event)=>handleAction( 'taskratePerc', '',event)}
                                                    inputprops={{
                                                        style: { height: '28px', borderRadius: '0px' }
                                                    }}
                                                    onBlur={()=>handleEditAction( false, 'taskrate')}
                                                    autoFocus={true}
                                                    autoComplete="new-password"
                                                    className={"sf-fields-bg"}
                                                /></FormControl>
                                                <div style={{ paddingLeft: '5px' }}>%</div>
                                        </div>}
                                </div>
                            </div>
                            <div><span title={formatCurrency(getFloatParam(tempOrderObj.taskrate))} >{symbol}{formatCurrency(getFloatParam(tempOrderObj.taskrate))}</span></div>
                        </div>
                        <div style={btmColumnsStyle}>
                            <div></div>
                            <div className="text-ellipsis" style={{ fontWeight: 'bold', padding: '0px', textAlign: 'right' }}>DISCOUNT</div>
                            <div>
                                {!actionsList.discountAction.isEdit ? <span className='page-row' title={formatCurrency(getFloatParam(tempOrderObj.discount))} id="purchase_tax" onClick={()=>handleEditAction(true, 'discount')} style={{ padding: '5px 15px', cursor: 'pointer' }}>{symbol}{formatCurrency(getFloatParam(tempOrderObj.discount))}</span> :
                                    <FormControl style={{ width: '100%' }} className="sf-form-control" noValidate autoComplete="off">
                                        <TextField
                                            variant="outlined"
                                            id={`discount`}
                                            key={`discount-key`}
                                            value={tempOrderObj.discount}
                                            title={tempOrderObj.discount}
                                            onChange={(event)=>handleAction( 'discount', '',event)}
                                            inputprops={{
                                                style: { height: '28px', borderRadius: '0px', width: '30%', placeSelf: 'center' }
                                            }}
                                            onBlur={()=>handleEditAction(false, 'discount')}
                                            autoFocus={true}
                                            autoComplete="new-password"
                                            className={"sf-fields-bg"}
                                        /></FormControl>}
                            </div>
                        </div>
                        <div style={btmColumnsStyle}>
                            <div></div>
                            <div className="text-ellipsis" style={{ fontWeight: 'bold', padding: '0px', textAlign: 'right' }}>TOTAL</div>
                            <div title={formatCurrency(grandtotal)}>{symbol}{formatCurrency(grandtotal)}</div>
                        </div>
                        {paymentAmount > 0 &&
                            <div style={btmColumnsStyle}>
                                <div></div>
                                <div className="text-ellipsis" style={{ fontWeight: 'bold', padding: '0px', textAlign: 'right' }}>AMOUNT RECEIVED</div>
                                <div title={formatCurrency(paymentAmount)}>{symbol}{formatCurrency(paymentAmount)}</div>
                            </div>
                        }

                    </div>
                </div>
            )
    }

    const openCompanyProfileTab = () => {
        try {
            let tab = {
                item: constants.SETTING,
                label: getLocalizedStrings().label.COMMON.SETTINGS,
                object: 'company-profile',
                imgName: 'fas fa-cog',
                url: '/setting/module',
                type: TYPE_LIST_VIEW,
            };
            tab.info = { filter: { id: 0, name: getLocalizedStrings().label.CUSTOMIZATION_SETTING.COMPANY_PROFILE, type: '' } };
            tab.info.selectedItem = { id: 0, name: 'company-profile', label: getLocalizedStrings().label.CUSTOMIZATION_SETTING.COMPANY_PROFILE, type: "menu-item", object: 'company-profile' };
            addTab(tab, true);
        } catch (error) {
            console.error("Error in 'purchaseOrder,js -> PurchaseOrderEdit -> openCompanyProfileTab()':" + error);
        }
    }


    const generateBillToDetails = () => {
        let element = [];
        let tempOrderObjData = tempOrderObj;
            let accountFieldObj = sfForm.data['accounts'].fields.find((obj) => { return obj.name === 'company' });
            let companyLabel = accountFieldObj ? accountFieldObj.label : 'Company';
            let billinfo = getObjectParam(tempOrderObjData.billinfo);
            let customerDetails = [{ name: 'name', label: 'Name' }, { name: 'company', label: companyLabel }, { name: 'address', label: 'Address' }, { name: 'phone', label: 'Phone' }, { name: 'email', label: 'Email' }];

            customerDetails.forEach(field => {
                let key = field.name;
                if (isValidParam(billinfo[key])) {
                    let fieldValue = key === 'phone' ? phoneFormat(billinfo[key]) : billinfo[key];
                    element.push(
                        <div style={{ padding: '5px' }}>
                            <FormControl style={{ width: '100%' }} className="sf-form-control" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    id={`form-field-${key}`}
                                    key={`field-${key}-key`}
                                    value={fieldValue}
                                    title={fieldValue}
                                    onChange={(event)=>handleAction(key, 'billinfo',event)}
                                    label={field.label}
                                    className={"sf-fields-bg"}
                                />

                            </FormControl>
                        </div>
                    )
                }
            });
        return element;
    }
    useImperativeHandle(ref, () => ({
        tempOrderObj
            }));

        let pageHeight = window.innerHeight - 247;
        let top = (pageHeight-10) / 2;
        if (mounted) {

            console.log("rendering............")
            let tempOrderObjData = tempOrderObj;
            let companyinfo = getObjectParam(tempOrderObjData.companyinfo);
            let purchase_dateObj = getStringParam(tempOrderObjData.orderDate) ? moment(tempOrderObjData.orderDate) : moment(new Date());
            purchase_dateObj = purchase_dateObj.format(dateFormat[appState.me.date_format]);

            let HHFormat = new RegExp("HH");
            let datePickerOptions = {
                dateFormat: dateFormatFlatPicker[appState.me.date_format],
                minuteIncrement: 1,
                time_24hr: HHFormat.test(appState.me.date_format) ? true : false
            };

            let tempDetails = tempOrderDetails.find(f => f.object == object.object);
            tempDetails = isValidParam(tempDetails) ? tempDetails : {};

            let tdStyle = { color: '#97989e', placeSelf: 'center right', fontSize: '16px' };
            let rowMiddleStyle = { display: 'grid', gridTemplateColumns: '40% 1fr', gridColumnGap: '20px', height: '54px', margin: '10px 0px' }

            let isValidcompanyinfo = tempOrderObjData.hasOwnProperty('companyinfo') ? true : false;
            return (
                <div>
                    <div style={{ display: 'grid', gridTemplateColumns: '49% 9% 42%', gridColumnGap: '15px' }}>
                        <div>
                            {isValidcompanyinfo ? Object.keys(getObjectParam(companyinfo)).map(key => {
                                if (isValidParam(companyinfo[key]) && companyinfo[key] !== '') {
                                    if (key === 'company') {
                                        return (<div style={{ padding: '3px', fontWeight: 'bold', fontSize: '17px' }}>{companyinfo[key]}</div>);
                                    } else {
                                        return (<div style={{ padding: '3px', fontSize: '15px', whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis' }} >{companyinfo[key]}</div>);
                                    }
                                }
                            }) :
                                <div style={{ paddingTop: '15px' }}>
                                    <div style={{ cursor: 'pointer', color: '#337ab7', textDecoration: 'underline' }} onClick={()=>openCompanyProfileTab()}>Enter Company Information</div>
                                </div>
                            }
                        </div>
                        
                        <div></div>
                        <div>
                            <img src={tempOrderObjData.logourl} style={{ maxHeight: '100px', maxWidth: '200px' }}></img>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4" style={{ padding: '20px 15px' }}>
                        </div>
                    </div>


                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2,1fr)', gridColumnGap: '15px' }}>
                        <div>
                            <div style={{ color: '#97989e', padding: '2px' }} >BILL TO/SHIP TO</div>

                            {generateBillToDetails()}
                        </div>

                        <div>
                            <div style={{ height: '20px' }}></div>
                            <div style={rowMiddleStyle}>
                                <div className="text-ellipsis" style={tdStyle}>{getStringParam(tempDetails.number)}</div>
                                <div style={{ placeSelf: 'center start' }} title={tempOrderObjData.orderNo}>{tempOrderObjData.orderNo}</div>
                            </div>
                            {object.object == constants.SALES_ORDER_OBJECT && <div style={rowMiddleStyle}>
                                <div className="text-ellipsis" style={tdStyle}>{tempDetails.purchaseOrderNo}</div>
                                <div>
                                    <FormControl style={{ width: '100%' }} className="sf-form-control" noValidate autoComplete="off">
                                        <TextField
                                            variant="outlined"
                                            id={`form-field-po-number`}
                                            key={`field-key-po-number`}
                                            name='purchaseOrderNo'
                                            value={tempOrderObjData.purchaseOrderNo}
                                            title={tempOrderObjData.purchaseOrderNo}
                                            fullWidth={true}
                                            onChange={(event)=>handleAction('purchaseOrderNo', '',event)}
                                            className={"sf-fields-bg"}
                                        />
                                    </FormControl>
                                </div>
                            </div>}

                            <div style={rowMiddleStyle}>
                                <div className="text-ellipsis" style={tdStyle}>SALES REP</div>
                                <div>
                                    <FormControl style={{ width: '100%' }} className="sf-form-control" noValidate autoComplete="off">
                                        <TextField
                                            variant="outlined"
                                            id={`form-field-sales-rep`}
                                            key={`field-key0-sales-rep`}
                                            name='preparer'
                                            value={tempOrderObjData.salesRep}
                                            title={tempOrderObjData.salesRep}
                                            onChange={(event)=>handleAction('salesRep', '',event)}
                                            className={"sf-fields-bg"}
                                        /></FormControl>
                                </div>
                            </div>

                            <div style={rowMiddleStyle}>
                                <div className="text-ellipsis" style={tdStyle}>TERMS</div>
                                <div>
                                    <FormControl style={{ width: '100%' }} className="sf-form-control" noValidate autoComplete="off">
                                        {termsList.length > 0 && <Autocomplete
                                            name='terms'
                                            key={`terms-key-list`}
                                            defaultValue={termsList.find(v => v.value === tempOrderObjData.terms)}
                                            options={termsList}
                                            onChange={(event,value)=>handleAction('terms', '',value)}
                                            getOptionLabel={(option) => option.label}
                                            disableClearable={false}
                                            className="sendMail"
                                            renderInput={(params) => <TextField {...params} variant="outlined" margin="dense"  size='small' className={"sf-fields-bg"} />}
                                        />
                                        }
                                    </FormControl>
                                </div>
                            </div>

                            <div style={rowMiddleStyle}>
                                <div className="text-ellipsis" style={tdStyle}>{getStringParam(tempDetails.orderDate)}</div>
                                <div>
                                    <Flatpickr
                                        key={'flatpicker-1'}
                                        style={{ width: '100%', border: '1px solid #c7c1c1', height: '54px' }}
                                        value={Date.parse(purchase_dateObj)}
                                        title={purchase_dateObj}
                                        onChange={(type)=>handleFlatPickrDateChange(type,'orderDate')}
                                        options={datePickerOptions}
                                        className='flatpickr'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* problem is from here */}

                    <div className="row" style={{ paddingTop: '30px' }}>
                        <div style={{ backgroundColor: 'white' }}>
                            {generateProductItems()}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12" style={{ padding: '15px 0px' }}>
                            <FormControl style={{ width: '100%' }} className="sf-form-control" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    id='note-text'
                                    type='textarea'
                                    multiline={true}
                                    rows={4}
                                    maxRows={4}
                                    fullWidth={true}
                                    value={tempOrderObjData.notetext}
                                    onChange={(event)=>handleAction('notetext', '',event)}
                                    label={'Customer Note'}
                                    autoComplete="new-password"
                                    className={"sf-fields-bg"}
                                />
                            </FormControl>
                        </div>
                    </div>

                </div >
            );
        } else {
            return (
            <div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
           </div>
            );
        }
})

export default PurchaseOrder;

var tempOrderDetails = [
    { object: constants.PURCHASE_ORDER_OBJECT, number: 'PURCHASE ORDER NO', terms: 'Terms', orderDate: 'PURCHASE ORDER DATE' },
    { object: constants.SALES_ORDER_OBJECT, number: 'SALES ORDER NO', terms: 'Terms', orderDate: 'SALES ORDER DATE', purchaseOrderNo: 'PURCHASE ORDER NO' }
]


var templatesList = [
    {
        object: constants.PURCHASE_ORDER_OBJECT,
        template: `
    <html>
        <head> </head>
        <style>
        .productHeader {
            border: 1px solid #908989;
            border-radius: 5px;
            background-color: #ccc;
         }
        </style>
        <body>
            <div>
                <table style="width: 100%;">
                <tbody style="height: 28px;">
                    <tr style="height: inherit;">
                        <td style="font-weight: bold; font-size: 17px; width: 48%;">companyinfo-company</td>
                        <td rowspan="4" colspan="2" style="text-align: center;"><img src="logourl" style="height: 86px;"/></td>
                    </tr>
                    <tr style="height: inherit;">
                        <td style="white-space: pre-wrap;">companyinfo-address</td>
                    </tr>
                    <tr style="height: inherit;">
                        <td>companyinfo-phone</td>
                    </tr>
                    <tr style="height: inherit;">
                        <td>companyinfo-email</td>
                    </tr>
                    <tr style="height: inherit;">
                        <td></td>
                        <td style="color: #97989e; text-align:right;"></td>
                        <td style=" padding-left: 20px;"></td>
                    </tr>
                    <tr style="height: inherit;">
                        <td style="color: #97989e;">BILL TO/SHIP TO</td>
                    </tr>
                    <tr style="height: inherit;">
                        <td style="width: 48%;">billinfo-name</td>
                        <td style="width: 20%; color: #97989e; text-align:right;">ORDER-NO</td>
                        <td style=" padding-left: 20px;">orderNo</td>
                    </tr>
                    <tr style="height: inherit;">
                        <td>billinfo-address</td>
                        <td style="color: #97989e; text-align:right;">SALES REP</td>
                        <td style=" padding-left: 20px;">salesRep</td>
                    </tr>
                    <tr style="height: inherit;">
                        <td>billinfo-phone</td>
                         <td style="color: #97989e; text-align:right;">TERMS</td>
                        <td style=" padding-left: 20px;">terms</td>
                    </tr>
                    <tr style="height: inherit;">
                         <td>billinfo-email</td>
                        <td style="color: #97989e; text-align:right;">ORDER-DATE</td>
                        <td style=" padding-left: 20px;">orderDate</td>
                    </tr>
                </tbody>
                </table>
                <div style="padding: 10px;" ;></div>
                <table style="width: 100%; border-collapse: inherit; border-spacing: 2px;">
                    <tbody style="text-align: center; height: 28px;">
                        <tr style="height: 30px; color: #333; padding: 5px; font-weight:bold">
                            <td class="productHeader" style="width: 20%;">ITEM</td>
                            <td class="productHeader" style="width: 25%;">DESCRIPTION</td>
                            <td class="productHeader" style="width: 15%;">QTY</td>
                            <td class="productHeader" style="width: 20%;">RATE</td>
                            <td class="productHeader" style="width: 15%;">AMOUNT</td>
                        </tr>
                        productTable
                        <tr style="height: 10px;">
                            <td colspan="5"></td>
                        </tr>
                        <tr style="height: inherit;">
                            <td colspan="3"></td>
                            <td style="font-weight: bold;">SUBTOTAL</td>
                            <td style="padding: 3px;">$subtotal</td>
                        </tr>
                        <tr style="height: inherit;">
                            <td colspan="3"></td>
                            <td style="font-weight: bold;">TAX</td>
                            <td style="padding: 3px;">$taskrate</td>
                        </tr>
                        DISCOUNT
                        <tr style="height: inherit;">
                            <td colspan="3"></td>
                            <td style="font-weight: bold;">TOTAL</td>
                            <td style="padding: 3px;">$grandtotal</td>
                        </tr>
                        PAYMENT_AMOUNT
                    </tbody>
                </table>
                <div>
                    <div colspan="4" rowspan="3" style="padding: 5px; float:left;white-space: pre-wrap;">notetext</div>
                </div>
            </div>
        </body>
    </html>                
`},
    {
        object: constants.SALES_ORDER_OBJECT,
        template: `
<html>
    <head> </head>
    <style>
    .productHeader {
        border: 1px solid #908989;
        border-radius: 5px;
        background-color: #ccc;
     }
    </style>
    <body>
        <div>
            <table style="width: 100%;">
            <tbody style="height: 28px;">
                <tr style="height: inherit;">
                    <td style="font-weight: bold; font-size: 17px; width: 48%;">companyinfo-company</td>
                    <td rowspan="4" colspan="2" style="text-align: center;"><img src="logourl" style="height: 86px;"/></td>
                </tr>
                <tr style="height: inherit;">
                    <td style="white-space: pre-wrap;">companyinfo-address</td>
                </tr>
                <tr style="height: inherit;">
                    <td>companyinfo-phone</td>
                </tr>
                <tr style="height: inherit;">
                    <td>companyinfo-email</td>
                </tr>
                <tr style="height: inherit;">
                    <td></td>
                    <td style="color: #97989e; text-align:right;"></td>
                    <td style=" padding-left: 20px;"></td>
                </tr>
                <tr style="height: inherit;">
                    <td style="color: #97989e;">BILL TO/SHIP TO</td>
                </tr>
                <tr style="height: inherit;">
                    <td style="width: 48%;">billinfo-name</td>
                    <td style="width: 20%; color: #97989e; text-align:right;">ORDER-NO</td>
                    <td style=" padding-left: 20px;">orderNo</td>
                </tr>
                <tr style="height: inherit;">
                    <td>billinfo-address</td>
                    <td style="width: 20%; color: #97989e; text-align:right;">PURCHASE ORDER NO</td>
                    <td style=" padding-left: 20px;">purchaseOrderNo</td>
                </tr>
                <tr style="height: inherit;">
                    <td>billinfo-phone</td>
                    <td style="color: #97989e; text-align:right;">SALES REP</td>
                    <td style=" padding-left: 20px;">salesRep</td>
                </tr>
                <tr style="height: inherit;">
                    <td>billinfo-email</td>
                    <td style="color: #97989e; text-align:right;">TERMS</td>
                    <td style=" padding-left: 20px;">terms</td>
                </tr>
                <tr style="height: inherit;">
                    <td></td>
                    <td style="color: #97989e; text-align:right;">ORDER-DATE</td>
                    <td style=" padding-left: 20px;">orderDate</td>
                </tr>
            </tbody>
            </table>
            <div style="padding: 10px;" ;></div>
            <table style="width: 100%; border-collapse: inherit; border-spacing: 2px;">
                <tbody style="text-align: center; height: 28px;">
                    <tr style="height: 30px; color: #333; padding: 5px; font-weight:bold">
                        <td class="productHeader" style="width: 20%;">ITEM</td>
                        <td class="productHeader" style="width: 25%;">DESCRIPTION</td>
                        <td class="productHeader" style="width: 15%;">QTY</td>
                        <td class="productHeader" style="width: 20%;">RATE</td>
                        <td class="productHeader" style="width: 15%;">AMOUNT</td>
                    </tr>
                    productTable
                    <tr style="height: 10px;">
                        <td colspan="5"></td>
                    </tr>
                    <tr style="height: inherit;">
                        <td colspan="3"></td>
                        <td style="font-weight: bold;">SUBTOTAL</td>
                        <td style="padding: 3px;">$subtotal</td>
                    </tr>
                    <tr style="height: inherit;">
                        <td colspan="3"></td>
                        <td style="font-weight: bold;">TAX</td>
                        <td style="padding: 3px;">$taskrate</td>
                    </tr>
                    DISCOUNT
                    <tr style="height: inherit;">
                        <td colspan="3"></td>
                        <td style="font-weight: bold;">TOTAL</td>
                        <td style="padding: 3px;">$grandtotal</td>
                    </tr>
                    PAYMENT_AMOUNT
                </tbody>
            </table>
            <div>
                <div colspan="4" rowspan="3" style="padding: 5px; float:left;white-space: pre-wrap;">notetext</div>
            </div>
        </div>
    </body>
</html>                
`}
]

