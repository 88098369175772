
import React from 'react';
import { connect } from 'react-redux';
import { styles } from '../../../services/constants/styles';
import { constants, OBJECT_TABLEID_MAP, COUNTRY_CODE_MAP } from '../../../services/constants/constants';
import { getStringParam, getObjectParam, isValidParam, getIntParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import Button from '@mui/material/Button';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { getAppDialog, getAppDrawer } from '../../../services/actions/appContainerActions';
import { FormControl, TextField } from '@mui/material';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getSearchedContactList, updateSelectedContact } from '../../../services/actions/smsAction';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const  PickAndAddContactDialogForSms =(object)=>{

    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState(null);
    const [searchOptions, setSearchOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const appState = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);

    const handleChange = (event,value) => {
        if(searchOptions.length>0){
            setSelectedValue(value);
            addNewContactDetail(value);
        }
    }

    const addNewContactDetail = (value) => {
        dispatch(getAppDialog(false, null, null, null, null, null));
        if (isValidParam(value) && value !== null) {
            let params = {};
            params.to_phone = value.mobile
            params.table_id = OBJECT_TABLEID_MAP[getStringParam(constants.CONTACTS_OBJECT)];
            params.record_id = value.id;
            params.name = value.name;
            if (params !== null) {
                updateSelectedContact(params);
            }
        }
    }
    const handelInputChange = (events, value) => {
        let tenantCountry = getStringParam(appState.me.tenant_country);
        let countryCode = COUNTRY_CODE_MAP[tenantCountry];
        countryCode = countryCode.split("+")[1];
        let input = getStringParam(value);
        if (input === null || input === '' || !input.trim()) {
            let errorMsg = "Contact Can't be Blank";
            dispatch(showCustomSnackBar(errorMsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else {
            if (input.length > 2) {
                if (object.object == constants.INBOX_OBJECT) {
                    let params = {};
                    params.searchText = input;
                    var promise = Promise.resolve(getSearchedContactList(params));
                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            let tempOptions = getArrayParam(response);
                            let options = tempOptions.map((obj, i) => {
                                let tempObj = obj;
                                let mobile = obj.mobile;
                                let tenantCountry = appState.me.tenant_country;
                                let countryCode = COUNTRY_CODE_MAP[tenantCountry];
                                let _appendCountryCode = countryCode;
                                countryCode = countryCode.split("+")[1];
                                if (mobile.indexOf("+", 0) === 0) {
                                    mobile = mobile.split("+")[1];
                                }
                                if (mobile.indexOf(countryCode, 0) === 0) {
                                    mobile = mobile.replace(countryCode, '');
                                }    
                                if (mobile.indexOf("+") < 0) {
                                    mobile = _appendCountryCode + mobile;
                                }
                                tempObj.label = `${obj.name} - ${obj.company} (${mobile})`;
                                tempObj.mobile = mobile;
                                return tempObj;
                            });
                            sortArrayObjectByProperty(options, 'name');
                            if (options.length > 0) {
                                setSearchOptions(options);
                            } else {
                                setSearchOptions([]);
                            }
                        });
                    }
                }
            } else {
                setSearchOptions([]);
            }
        }

    }
        let fieldLabel = 'Search by name';
        let options = searchOptions;
        return (
            <div>
                <div>
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <Autocomplete
                                style={{ width: '100%', }}
                                freeSolo
                                filterOptions={(x) => x}
                                getOptionLabel={(option) =>
                                    typeof option === 'string' ? option : option.label
                                }
                                options={options}
                                value={selectedValue}
                                onInputChange={(e, value) => handelInputChange(e, value)}
                                onChange={(e, newValue) => handleChange(e,newValue)}
                                className={"sf-fields-bg"}
                                disableClearable={false}
                                renderInput={(params) => <TextField {...params} label={fieldLabel}  margin='dense' size='small' className={"sf-fields-bg"}/>}
                            />
                    </FormControl>
                </div>
            </div>
        );
}

export default PickAndAddContactDialogForSms;