import React, { useState, useEffect } from 'react';
import { setHeader } from '../../../services/actions/headerActions';
import { getDetailviewData, isDetailViewSet, detailViewIsMounted, refreshDetailViewData,refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';
import DetailViewHeader from '../components/detailViewHeader';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import { getActiveTab,getActiveTabInfo,closeTab } from '../../../services/helper/sfTabManager';
import { isValidParam,getObjectParam, getArrayParam,getStringParam,getIntParam,getBooleanParam} from '../../../services/helper/parameterVerifier';
import NoRecords from '../components/noRecord';
import { getCacheFromFieldsWithPromise } from '../../../services/actions/sfFormActions';
import { constants,OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import SFForm from '../components/sfForm';
import { useDispatch, useSelector } from "react-redux";
import { openDetailviewQueueTab, removeRecordFromRedux } from '../../../services/actions/queueActions';
import _ from 'lodash';
const TaskAppDetailView = ({object,id}) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const sfForm = useSelector((state) => state.sfForm);
    const detailView = useSelector((state) => state.detailView);
    const [fieldsData, setFieldsData] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const reduxQueueList = useSelector((state) => state.queueList, _.isEqual);
    const customDrawer = useSelector((state) => state.appContainer.customDrawerProps);

    useEffect(() => {
        loadData();
    }, []);

    const refreshDeatilsView = () => {
		dispatch(detailViewIsMounted(false));
		dispatch(isDetailViewSet(false));
		dispatch(refreshDetailViewData(true));
	};
   const loadData = () => {
            dispatch(isDetailViewSet(true));
        let label = null;
        let kanbanObject = {};
        kanbanObject.currentView = null;
        kanbanObject.show = false;
    dispatch(setHeader(object, label, kanbanObject, false, false));
        let urlParams = {
            object: object,
            recordId: id
        }
        

    dispatch(getDetailviewData(urlParams));

        let data = sfForm.data[object];

        if (data !== undefined && data !== null) {
            setFieldsData(data.fields);
            setIsEdit(false);
        } else {
            var promise = getCacheFromFieldsWithPromise(object);
            promise.then((response) => {
                if (isValidParam(response)) {
                    setFieldsData( response.fields);
            setIsEdit(false);
                }
            });
        }

    }

   
    useEffect(() => {
        dispatch(detailViewIsMounted(false));
        dispatch(isDetailViewSet(false));
        dispatch(refreshDetailViewData(false));
    }, []);
   
   const refreshView = () => {
        detailView.mounted = false;
        loadData();
    }

   const isEdited = (data) => {
    setIsEdit(data);
    }
    const removeFromQueue = (detailViewId, action) => {
        let nextRecordIndex = 0;
        let newIdx = 0;
            if (detailViewId > 0) {
				let tempQueList = reduxQueueList;
                let data = getObjectParam(reduxQueueList.data);
                let _queueList = getArrayParam(data.records);
                let recordId = parseInt(detailViewId);
                let queueObj = _queueList.filter((obj) => { return obj.id == recordId });
                let index = isValidParam(queueObj) && queueObj.length > 0 ? queueObj[0].idx : -1;
                let objectName = getStringParam(object);

                if (action == constants.DELETE && _queueList.length <= 1) {
                    let activeTab = getActiveTab();
                    if (isValidParam(activeTab)) {
                        closeTab(activeTab.item, activeTab.index);
                    }
                } else {
                    if (index >= 0) {
                        if (index + 1 < _queueList.length) {
                            nextRecordIndex = index + 1;
                            newIdx = index;
                            // open next records for detailview
                        } else {
                            nextRecordIndex = index - 1;
                            newIdx = nextRecordIndex;
                            // get the last record  for detailview 
                        }

                        let prevObj = _queueList[nextRecordIndex];
                        let preId = isValidParam(prevObj) && prevObj != null ? getIntParam(prevObj.id) : 0;
                        if (preId > 0) {
                            let total_count = getIntParam(data.total_count);
                            total_count = total_count - 1;
                            removeRecordFromRedux(objectName, recordId, total_count);

                            let label = getStringParam(prevObj.title);
                            tempQueList.data['openedRecordInQueueId'] = preId;
                            tempQueList.data['openedRecordInQueueIndex'] = newIdx;
                            tempQueList.data['openedRecordInQueuelabel'] = label;
                            if (objectName == constants.TASKS) {
                               
                                let linkInfo = prevObj.linkInfo;
                                if (linkInfo != null && linkInfo != undefined) {
                                    let linkInfoArr = linkInfo.split(',');
                                    let selectedObjId = getIntParam(OBJECT_TABLEID_MAP[tempQueList.data.selecteddrpdwnObj]);
                                    linkInfoArr.map((item, i) => {
                                        let ObjRecordArr = item.split('_');
                                        let tableId = getIntParam(ObjRecordArr[0].trim());
                                        if (selectedObjId == tableId) {
                                            preId = getIntParam(ObjRecordArr[1]);
                                            objectName = tempQueList.data.selecteddrpdwnObj;
                                        }

                                    });
                                }
                            }
                           openDetailviewQueueTab(objectName, label, preId, newIdx, total_count);
                           dispatch(refreshDetailViewListViewData(true,recordId));
						   let urlParams = {
							object: objectName,
							recordId: preId
						}
						dispatch(getDetailviewData(urlParams));
						refreshDeatilsView();
                        }
                    }
                }

            }
    }

        let moduleItems = app.selectedModuleItems;
        let iconImage = null;
        let contentHeight = window.innerHeight - 150;
        let top = (contentHeight - 10) / 2
        let drHeight = window.innerHeight - 180;
        if (moduleItems !== null && moduleItems !== undefined) {
            for (var i = 0; i < moduleItems.length; i++) {
                var itemObject = moduleItems[i];
                if (itemObject.object ===  object && itemObject.object !== constants.COUPONS_OBJECT) {
                    iconImage = itemObject.image_name;
                    break;
                }
            }

            if (iconImage ===  null) {
                iconImage = object ===  constants.TASKS ? 'fa fa-clock-o' : app.objectIconImageMap[object];
            }

        }

        if (detailView.mounted && /*this.props.detailView.fieldsMounted */ sfForm.data[object] !== undefined) {
            let tabInfo = getActiveTabInfo();
            let is_callFrom_queue = tabInfo.hasOwnProperty('is_callFrom_queue') ? getBooleanParam(tabInfo.is_callFrom_queue) : false;
            let queueObj = reduxQueueList && reduxQueueList.hasOwnProperty('data') && reduxQueueList.data ? reduxQueueList.data.object : "";

            if (detailView.record === undefined) {
                if (is_callFrom_queue && queueObj == constants.APPOINTMENTS) {
                    removeFromQueue(id, constants.DELETE);
                    return null;
                } else {
                    return <NoRecords object={object} />
                }
            }
            let headerProps = {
                title: detailView.data.title,
                actions: detailView.actions,
                iconImage: iconImage,
                leadScore: detailView.data.lead_score,
                titleFieldName: detailView.data.title_field_name
            }

            
            let propsChildObjects = {
                childObjects: detailView.childObjects,
                records: detailView.record,
                object: object,
                projectId: app.me.projectId,
                parent_record_id: id
            }

            let selectedChildObjects = detailView.data.child_objects;
            let availableChildObjects = detailView.data.available_objects;
            let isAppointmentInChild = false;
            let isTaskInChild = false;

            if (app.selectedModuleName ===  constants.MODULE_HUB && object === constants.CASES_OBJECT) {
                selectedChildObjects = selectedChildObjects.filter(f => { return f.child_object ===  constants.NOTES || f.child_objec === constants.ATTACHMENT_OBJECT });
                propsChildObjects.childObjects = propsChildObjects.childObjects.filter(f => { return f.child_object ===  constants.NOTES || f.child_objec === constants.ATTACHMENT_OBJECT });
            }

            if (selectedChildObjects !== null && selectedChildObjects.length > 0) {
                let tempObject = selectedChildObjects.filter(f => { return f.child_object ===  constants.APPOINTMENTS });
                let tempTasksObject = selectedChildObjects.filter(f => { return f.child_object ===  constants.TASKS });

                if (tempObject !== null && tempObject.length > 0) {
                    isAppointmentInChild = true;
                }

                if (tempTasksObject !== null && tempTasksObject.length > 0) {
                    isTaskInChild = true;
                }
            }

            if (isAppointmentInChild ===  false && availableChildObjects !== null && availableChildObjects.length > 0) {
                let tempObject = null;
                if (object !== constants.ISSUES_OBJECT && object !== constants.PROJECTS_OBJECT) {
                    tempObject = availableChildObjects.filter(f => { return f.t_child_tableid ===  142 });
                }

                if (tempObject !== null && tempObject.length > 0) {
                    isAppointmentInChild = true;
                }
            }

            if (isTaskInChild ===  false && availableChildObjects !== null && availableChildObjects.length > 0) {
                let tempObject = availableChildObjects.filter(f => { return f.t_child_tableid ===  349 });

                if (tempObject !== null && tempObject.length > 0) {
                    //isTaskInChild = true;
                }
            }
            
           
            let parentDetails = {};
            parentDetails.parent_object = '';
            parentDetails.parent_record_id = 0;
            return (
                <div>
                    <div className="headerDetail" style={{ width: '99%',overflowX: customDrawer.isMinimize == true ? 'hidden':'', overflowY: customDrawer.isMinimize == true ? 'auto':'', height:customDrawer.isMinimize == true ? drHeight:''}}>
                        <div className="headerDetailView">
                            <DetailViewHeader headerProps={headerProps} object={object} refreshView={refreshView} isEdit={isEdit} callFrom={'queueView'} />
                        </div>

                        <div className="taskMain adetailview" style={{ width: '100%', backgroundColor: '#fff', marginLeft: '5px', borderRadius: '6px', boxShadow: '0 0 12px #ccc', marginTop: 1 }}>
                            <div className="container" id="detail-view-container" style={{ width: '100%', height:object=== 'appointments' ? contentHeight :'' }}>
                                <div className="row">
                                    <div className="col-sm-12" style={{ width: '100%', backgroundColor: '#f5f5f5' }}>
                                        <SFForm object={object} openMode={constants.SF_FORM_OPEN_MODE_CUSTOM} recordId={id} isDetailView={true} reminderBlock={true} callFrom={'queueView'} parentDetails={parentDetails} isEdited={isEdited} refreshView={refreshView} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (<div style={{ width: '100%', height: contentHeight }} >
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>);
        }
    
}

export default TaskAppDetailView;