import React, { useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setHeader } from '../../../services/actions/headerActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { isValidParam, getStringParam, getIntParam,getArrayParam,getBooleanParam} from '../../../services/helper/parameterVerifier';
import { constants } from '../../../services/constants/constants';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from './circularProgress';
import SFDataTable from './sfDataTable';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { sortArrayObjectByProperty,sortArrayObjectByPropertyForCurrency } from '../../../services/helper/utils';

const TotalReportedSalesDrillDown = () =>{
    const dispatch = useDispatch();
    const appState = useSelector((state) => state.app);
    const tabs = useSelector((state)=> state.tab?.tabs);
    const [isLoading,setIsLoading] = useState(true);
    const [rows,setRows] = useState([]);
    const [columns,setColumns]= useState([]);
    
    const [rowSort, setRowSort] = useState({
        isDescending: false,
        onSort: (fieldName, isDescending, rows)=> onSortFn (fieldName, isDescending, rows)
    });

    useEffect(()=>{
        let activeTab = tabs?.find((tab) => {
			return tab.type === constants.ROYALTY_REPORTED_SALES_DRILLDOWN && tab.object === constants.ROYALTY_REPORTED_SALES_DRILLDOWN;
		});
        let recordId = activeTab?.info?.id;
        let {unit,start_date,end_date} = activeTab?.info?.row;
        let tabHeader;
        if(start_date && end_date && unit){
            tabHeader =  unit + '(' + start_date + ' - ' + end_date +')';
        } else if(unit && !start_date && !end_date){
            tabHeader =  unit;
        }else if(!unit && start_date && end_date){
            tabHeader =  '(' + start_date + ' - ' + end_date +')';
        }else if(unit && (!start_date ||!end_date)){
            tabHeader =  unit;
        } else{
            tabHeader = constants.ROYALTY_REPORTED_SALES_DRILLDOWN;
        }
        dispatch(setHeader(constants.ROYALTY_REPORTED_SALES_DRILLDOWN,tabHeader, null, false, true));
        getReportData(recordId);
    },[])

    const getReportData = (recordId) =>{
        let params = {};
        params.record_id = recordId;
        const promise = Promise.resolve(HTTPClient.get(endPoints.TOTAL_REPORTED_SALES_DRILLDOWN.GET, params));
        if (promise) {
            promise.then(res => {
                let response = res;
                setRows(response.records);
                setColumns(response.fields);
                setIsLoading(false);
            })
        }
    }

    const onSortFn = (fieldName, isDescending, records) => {
        let _rowSort = rowSort;
        let _records;
           _records = getArrayParam(records);
       let sortFieldName = getStringParam(fieldName); 
       let totalRec = _records.filter(f => {
        if (f.units === "Total") {
            return f;
        }
        });
        let tempRecordArr = _records.filter(f => {
            if (f.units !== 'Total') {
                return f;
            }
        });

       if(fieldName === 'units'){
            if (getBooleanParam(_rowSort.isDescending)) {
                sortArrayObjectByProperty(tempRecordArr, sortFieldName);
                _rowSort.isDescending = false;
            }
            else {
                sortArrayObjectByPropertyDesc(tempRecordArr, sortFieldName);
                _rowSort.isDescending = true;
            }
       }else{
            if (getBooleanParam(_rowSort.isDescending)) {
                sortArrayObjectByPropertyForCurrency(tempRecordArr, sortFieldName, appState.me.currency_symbol);
                _rowSort.isDescending = false;
            }
            else {
                sortArrayObjectByPropertyDescForCurrency(tempRecordArr, sortFieldName, appState.me.currency_symbol);
                _rowSort.isDescending = true;
            }
       }
       if (tempRecordArr.length > 0) {
            if (totalRec.length > 0) {
                tempRecordArr.push(totalRec[0]);
            }
            _records = tempRecordArr;
        }
        cookieSetSortData(_rowSort.isDescending);
        _rowSort.sortFieldName = sortFieldName;
        setRowSort({ ..._rowSort })
        setRows(_records);
    }

    const cookieSetSortData = (value) => {
        let userid = appState.me.id;
        let cVarName = "isDescending_" + userid;
        if (value) {
            document.cookie = cVarName + "=yes";
        } else {
            document.cookie = cVarName + "=no";
        }
    }


    const sortArrayObjectByPropertyDescForCurrency = (array, property, currency) => {
        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];

                    val1 = typeof val1 === "string" ? val1.toLowerCase() : val1;
                    val2 = typeof val2 === "string" ? val2.toLowerCase() : val2;

                    val1 = val1.replace(currency, '');
                    val1 = val1.replace(',', '');
                    val1 = val1.replace('.', '');
                    val1 = val1.trim();

                    val2 = val2.replace(currency, '');
                    val2 = val2.replace(',', '');
                    val2 = val2.replace('.', '');
                    val2 = val2.trim();

                    val1 = getIntParam(val1);
                    val2 = getIntParam(val2);

                    if (val1 > val2) {
                        return -1;
                    } else if (val1 < val2) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
        }
    }

    const sortArrayObjectByPropertyDesc = (array, property) => {
        let temp;
        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];
                    val1 = val1 instanceof String ? val1.toLowerCase() : val1;
                    val2 = val2 instanceof String ? val2.toLowerCase() : val2;
                    if (val1 > val2) {
                        temp= -1;
                    } else if (val1 < val2) {
                        temp= 1;
                    } else {
                        temp= 0;
                    }
                }
                return temp;
            });
        }
    }


    const customEvent = (eventType, column, row, event) =>{

    }

    
    function getNoRecordContent() {
        return (
            <div className="row" style={{ padding: '0px', display: "flex"}}>
                <h5 style={{ color: 'red' }}>{getLocalizedStrings().message.ROYALTY_REPORT_V2.NO_RECORDS}</h5>
            </div>
        )
    }

    const getReportView = () =>{
        let view = [];
        if (!rows || Object.keys(rows).length === 0) {
            return getNoRecordContent();
        }else{
             view.push(<div>
                <div id="divcontents" key={"divcontents" + rowSort.isDescending + "-" + rowSort.sortFieldName} >
                    <SFDataTable columns={columns} rowSort={rowSort} rows={rows} customEvent={(eventType, column, row, event) => customEvent(eventType, column, row, event)} />
                </div> </div>)

            return view;
        }
    }
    
    let tempStyle = { marginLeft: 42, width: '96%', paddingLeft: 11};
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2
    return(
        <div style={tempStyle}>
            <div style={{ marginBottom: '1.5rem',marginTop:'15px',marginLeft:'-14px' }}>
                {
                    !isLoading ? getReportView() : ( 
                            <div style={{ width: '100%', height: contentHeight }}>
                                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                    </div>
                                </div>
                            </div>
                        )
                }
            </div>
    </div>
    )
}
export default TotalReportedSalesDrillDown;