import React, { useState, useEffect} from 'react';
import ModuleMenuItem from './ModuleMenuItem';
import { ItemTypes } from './ItemTypes.js'
import { useDrag, useDrop } from 'react-dnd';


const MenuContainer = (props) => {

    const { updateIsEditProps, section,updateModuleItem, prevMobileItems,isDragging} = props;
    const [ sectionItems, setSectionItems ] = useState(section?.items);

    useEffect(() => {
        setSectionItems(section?.items);
    },[section]);

    const [{ isOver, canDrop, handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
         canDrop(item, monitor) {
            return true;
        },
        drop(item, monitor) {
            if (parseInt(props.columnId) !== 1) {

                props.handleSave(item.selectedItem, 1);
            } else {
                props.handleRemoveForcefix(item.selectedItem);
            }
        },
        hover(item, monitor) {
            const draggingItem = monitor.getItem();
            if (draggingItem.id !== item.id) {
                props.moveCard(item, draggingItem.id);
            }
        },

        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            handlerId: monitor.getHandlerId(),
        }),
    });
    let opacity = isDragging ? 0 : 1;
    
    let height = window.innerHeight;
    let menuCardHeight = height - 240;
    let persentile = 100 / props.col
    let width = `calc(${persentile}% - 5px)`;
    return (
       <div key={`MenuContainer-${props.key}`} className="desk sf-desk" style={{ opacity,height:menuCardHeight, width: width , float:"left", border: '1px light gray',backgroundColor: 'white',margin: '15px',paddingTop:'10px',paddingLeft:'0px',marginLeft:'4px' }}>
            <div className="desk-head" >
                <div className="desk-name" style={{ display: 'flex', opacity  }}>
                    <span title={section.label} style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',textAlign:'center',paddingBottom:'10px' }}>{section.label}</span>
                </div>
                </div>
            {section && section?.items.length > 0 ? 
                <div style={{ height: menuCardHeight-60, paddingTop: '5px',overflow:'auto'}} key={"desk-items-" + section.key} id={"desk-items-" + section.key}>
                    {section?.items.map((item, index) => <ModuleMenuItem
                                item={item}
                                updateIsEditProps={updateIsEditProps}
                                mMenuItems={section?.items}
                                {...props}
                                columnIndex={index}
                                parentKey={section.key}
                                updateModuleItem={updateModuleItem}
                                prevMobileItems={prevMobileItems} />
                    )}
                </div> 
            :<div ref={drop} style={{ height: menuCardHeight-60, textAlign: 'center', fontSize: '14px' }}>Empty</div>}
        </div>
       
    )
}

export default MenuContainer;