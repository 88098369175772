import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import { styles } from "../../../services/constants/styles";
import { setTabs } from "../../../services/actions/tabActions";
import * as sfDialogs from "../components/sfDialogs";
import { constants } from "../../../services/constants/constants";
import * as HTTPClient from "../../../services/helper/httpClient";
import { endPoints } from "../../../services/constants/endPoints";
import {
  isValidParam,
  getStringParam,
  getArrayParam,
  makeValidAppUrl,
  getObjectParam,
  getBooleanParam,
  makeValidObjectName,
  getIntParam,
} from "../../../services/helper/parameterVerifier";
import ShowCircularProgress from "../components/circularProgress";
import map from "lodash/map";
import { sortArrayObjectByProperty } from "../../../services/helper/utils";
import {
  addTab,
  TYPE_LIST_VIEW,
  TYPE_HOME,
  TYPE_REPORT,
  TYPE_WEBSITE_TRACKING,
  TYPE_DASHBOARD,
  getActiveTab,
  updateTabInfo,
  updateActiveTabProps,
  TYPE_OTHER_VIEW,
  TYPE_COLUMN_VIEW,
  getCalendarTab,
  getCalenderPageType,
} from "../../../services/helper/sfTabManager";
import {
  setSelectedModuleInfo,
  getSelectedObject,
  getSelectedModuleItem,
  getImageNameByObject,
  getRecentFiltersPromise,
  getObjectLabelByObject,
  getItemName,
} from "../../../services/helper/common";
import { setHeader } from "../../../services/actions/headerActions";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import {
  refreshListView,
  setMounted,
} from "../../../services/actions/listViewAcions";
import store from "../../../../src/services/store";
import { LinkToType } from "../../../services/constants/link";
import { openLeftSideMenu } from "../../../services/actions/appActions";
import { getSelectedViewFromCookie } from "../../../services/helper/sfCookies";
import { refreshCalendarForBroadcast } from "../../../services/actions/calendarActions";
import {
  hasAccessPermission,
  saveRecentReportItems,
  saveRecentFilter,
  getIconClass,
} from "../../../services/helper/common";
import ColumnRecent from "../components/columnRecent";
import { getReportUrl } from "../../../services/actions/SalesReportAction";
import {
  addRecentFilterInRedux,
  addRecentRecordInRedux,
} from "../../../services/actions/SalesReportAction";
import filterMenuReducer from "../../../services/reducers/filterMenuReducer";


const TopNavBar = (props) => {
  const {
    moduleItems,
    closeSubMenu,
    subMenuOpen,
    changeSubMenuPosition,
    openSubMenu,
  } = props;
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const automationCampaign = useSelector((state) => state.automationCampaign);
  const tab = useSelector((state) => state.tab);
  const filters = useSelector((state) => state.filters);
  const listViewMounted = useSelector((state) => state.listView.mounted);
  const listViewObject = useSelector((state) =>
    getStringParam(state.listView.data.object).toLowerCase()
  );
  const calendarInfo = useSelector((state) => state.calendar.calendarInfo);
  const appContainer = useSelector((state) => state.appContainer);
  let isDisabled =
    appContainer?.customDrawerProps?.isOpen &&
    !appContainer?.customDrawerProps?.isMinimize
      ? true
      : false;
  const [divIdVal, setDivIdVal] = useState("Calendar/Tasks");
  const [hasRecentFilters, setHasRecentFilters] = useState(true);
  const [isOpenRecentFilter, setIsOpenRecentFilter] = useState(false);
  const [recentFilters, setRecentFilters] = useState("");
  const [prevDivIdVal, setPrevDivIdVal] = useState("Calendar/Tasks");
  const [showOverFlowButton, setShowOverFlowButton] = useState(false);
  const [moduleItms, setModuleItms] = useState([]);

  const { listItemName } = useSelector((state) => state.filters);

  useEffect(() => {
    if (
      app.selectedModuleName !== app.lastSelectedModuleName ||
      divIdVal !== prevDivIdVal
    ) {
      generateModuleItemList();
    }
  }, [app, divIdVal, prevDivIdVal, appContainer.customDrawerProps]);

  useEffect(() => {
    document.addEventListener("click", outsideSubMenuClickListener);
    return () => {
      document.removeEventListener("click", outsideSubMenuClickListener);
    };
  }, []);

  const outsideSubMenuClickListener = (event) => {
    if (subMenuOpen) {
      closeSubMenu();
    }
  };

  const generateModuleItemList = () => {
    let _moduleItems = null;
    let props = {};
    let moduleItemId = 0;
    try {
      let _isDisabled = getBooleanParam(isDisabled);
      let isOpen = true;
      let mItems = getArrayParam(moduleItems).filter((f) => f.is_show === 1);
      let isfranchisor = app.me.is_franchisor;
      let isfranchisee = app.me.is_franchisee;
      let isIndustryFlag = app.me.industry_flag;
      let industry_type = app.me.industry_type;

      if (isfranchisor && isIndustryFlag === false) {
        mItems = mItems.filter((f) => f.object !== constants.COMPLIANCE_OBJECT);
      }
      if (isfranchisee) {
        mItems = mItems.filter((f) => f.object !== "compliance_report");
      }
      /*if(isfranchisor && industry_type !== "Unit Base"){
				mItems = mItems.filter(
					(f) =>
						f.object !== constants.REPORTED_SALES_OBJECT);
			}*/
      if (!isfranchisor) {
        mItems = mItems.filter((f) => f.object !== constants.ROYALTY);
      }
      if (!isfranchisee) {
        mItems = mItems.filter(
          (f) => f.object !== constants.ROYALTY_REPORT_OBJECT_V2
        );
      }
      if (mItems.length > 0) {
        let selectedObjectName = getSelectedObject();
        selectedObjectName =
          selectedObjectName === constants.SETTING ? "" : selectedObjectName;
        selectedObjectName = makeValidObjectName(selectedObjectName);
        if (
          selectedObjectName !== "" &&
          selectedObjectName !== constants.LANDING_PAGES_OBJECT
        ) {
          setDivIdVal(selectedObjectName);
          let selectedModuleItem = getSelectedModuleItem();
          moduleItemId = selectedModuleItem.id;
          if (selectedObjectName === "calendar/tasks") {
            setDivIdVal("Calendar/Tasks");
          }
        } else {
          let itmeObj = getObjectParam(mItems[0]);
          let divIdVal = makeValidObjectName(itmeObj.object);
          setDivIdVal(divIdVal);

          moduleItemId = itmeObj.id;
          if (itmeObj.object === constants.APPOINTMENTS) {
            setDivIdVal(itmeObj.name);
          }
          setSelectedModuleInfo(itmeObj.object, moduleItemId);
        }
        _moduleItems = mItems.map((item) => {
          props = {};
          let url = null;
          let divId = item.object;
          divId = divId.replace(" ", "_");
          divId = makeValidAppUrl(divId);
          if (item.type === "Calendar") {
            divId = item.name;
            let selectedModuleName = getStringParam(app.selectedModuleName);

            url = "/" + item.object + "/" + "calendar";
          } else if (
            item.object === constants.LEAD_CAPTURE ||
            item.object === constants.EMAIL_MARKETING ||
            makeValidObjectName(item.object) === constants.SOCIAL_MARKETING ||
            item.object === constants.ONLINE_REPUTATION ||
            makeValidObjectName(item.object) === constants.OPERATIONS_REPORTS ||
            item.object === constants.OFFERS ||
            item.object === constants.OPERATIONS_ONBOARDING ||
            item.object === constants.HUB ||
            item.object === constants.SALES_MODULE_TEMPLATE ||
            item.object === constants.OPERATIONS_ORDER_OBJECT ||
            item.object === constants.REPORTS_DASHBORD ||
            item.object === constants.ROYALTY ||
            item.object === constants.LMS_MENU_OBJECT
          ) {
            url = "#";
          } else if (item.object === constants.DASHBOARD) {
            let id = "";
            if (getArrayParam(app?.dashboardInfo)) {
              app?.dashboardInfo?.map((obj) => {
                if (
                  obj.module_type.toLowerCase() ===
                    app.selectedModuleName.toLowerCase() &&
                  obj.info.length > 0
                ) {
                  obj.info.map((obj1) => {
                    if (
                      app.selectedModuleName.toLowerCase() ===
                      obj1.label.toLowerCase()
                    ) {
                      id = obj1.id;
                    }
                  });
                }
              });
            }

            if (id !== "" && id !== undefined) {
              url = makeValidAppUrl(
                "/" + item.object + "/" + app.selectedModuleName + "/" + id
              );
            } else {
              url = makeValidAppUrl("/" + item.object + "/" + item.home_name);
            }
            /**operation Dashboard Url Generation -- Lalu  */
          } else if (item.object === constants.OPERATIONS_DASHBOARD) {
            url = makeValidAppUrl("/" + item.object + "/" + "reports");
          } else if (item.object === constants.AUDIT_ANSWERS_OBJECT) {
            url = "/" + item.object + "/" + item.type;
            url = url.toLowerCase();
          } else if (item.object === constants.AUDITS_OBJECT) {
            url = "/" + item.object + "/" + item.type;
            url = url.toLowerCase();
          } else if (item.object === constants.INBOX_OBJECT) {
            url = "/" + item.object + "/" + "sms-inbox";
            url = url.toLowerCase();
          } else if (item.object !== "website_tracking") {
            url = makeValidAppUrl("/" + item.object + "/" + item.type);
          } else {
            url = "#";
          }
          if (
            item.object === constants.CASES_OBJECT &&
            app.selectedModuleName === constants.MODULE_HUB
          ) {
            item.has_filter = false;
          }
          // || (item.object === constants.DASHBOARD && app.selectedModuleName === constants.MODULE_OPERATIONS)
          if (item.object === constants.ASSETS_OBJECT) {
            let hasManageAssetsPermission = hasAccessPermission(
              constants.ASSETS_OBJECT,
              constants.SECURITY_LEVEL_TYPE_PERMISSION,
              constants.PERMISSION_NAME_MANAGE_ASSETS
            );
            url = hasManageAssetsPermission ? url : "#";
          }

          let backgroundColor = "#515151";
          let color =
            divId == divIdVal || moduleItemId == item.id
              ? "#f0f0f0"
              : "#b5b5b5";
          if (isOpen) {
            if (
              !item.has_filter &&
              (item.object === constants.LEAD_CAPTURE ||
                item.object === constants.EMAIL_MARKETING ||
                item.object === constants.OFFERS ||
                item.object === constants.OPERATIONS_ONBOARDING ||
                item.object === constants.HUB ||
                // || (item.object === constants.OPERATIONS_DASHBOARD && app.selectedModuleName === constants.MODULE_OPERATIONS) - Lalu
                makeValidObjectName(item.object) ===
                  constants.OPERATIONS_REPORTS ||
                item.object === constants.SALES_MODULE_TEMPLATE ||
                item.object === constants.OPERATIONS_ORDER_OBJECT ||
                item.object === constants.REPORTS_DASHBORD ||
                item.object === constants.ROYALTY ||
                ((item.object === "social_marketing" ||
                  item.object === constants.SOCIAL_MARKETING) &&
                  app.me.is_distributed_marketing))
            ) {
              if (!_isDisabled) {
                props.containerelement = <Link to={url} />;
              }
              let title = item.label;
              if (
                isValidParam(
                  getLocalizedStrings().label.SIDE_NAV[item.label.toUpperCase()]
                )
              ) {
                title =
                  getLocalizedStrings().label.SIDE_NAV[
                    item.label.toUpperCase()
                  ];
              }
              return (
                <div
                  key={divId + item.id}
                  id={divId + item.id}
                  className="filter-menu"
                >
                  <ListItem
                    onMouseEnter={(event) =>
                      recentFilterOpen(
                        item.object,
                        item,
                        divId,
                        divIdVal,
                        event
                      )
                    }
                    style={{
                      padding: "11px 2px",
                      backgroundColor: backgroundColor,
                      height: "40px",
                    }}
                    {...props}
                  >
                    <ListItemButton>
                      <ListItemText
                        primary={<div style={{ color }}>{title}</div>}
                      />
                    </ListItemButton>
                  </ListItem>
                </div>
              );
            } else if (
              !item.has_filter &&
              item.object !== constants.SALES_OBJECT
            ) {
              let title = item.label;
              if (
                isValidParam(
                  getLocalizedStrings().label.SIDE_NAV[item.label.toUpperCase()]
                )
              ) {
                title =
                  getLocalizedStrings().label.SIDE_NAV[
                    item.label.toUpperCase()
                  ];
              }

              if (
                item.type === "community" ||
                item.type === "manual" ||
                item.type === "training"
              ) {
                props.onClick = () => openLink(item);
                url = "#";
              } else {
                props.onClick = () => onClick(item, divId, url);
              }

              if (!_isDisabled) {
                props.containerelement = <Link to={url} />;
              }
              return (
                <div
                  key={divId + item.id}
                  id={divId + item.id}
                  className="filter-menu"
                >
                  <ListItem
                    onMouseEnter={() => closeSubMenu()}
                    style={{
                      padding: "11px 2px",
                      backgroundColor: backgroundColor,
                      height: "40px",
                    }}
                    {...props}
                  >
                    <ListItemButton>
                      <ListItemText
                        primary={<div style={{ color }}>{title}</div>}
                      />
                    </ListItemButton>
                  </ListItem>
                </div>
              );
            } else {
              let objectName = makeValidObjectName(item.object);
              let title = item.label;
              //url = "/" + objectName + (item.object === constants.SALES_OBJECT ? "/" + LinkToType.TYPE_REPORT : "/filters");

              if (
                isValidParam(
                  getLocalizedStrings().label.SIDE_NAV[item.label.toUpperCase()]
                )
              ) {
                title =
                  getLocalizedStrings().label.SIDE_NAV[
                    item.label.toUpperCase()
                  ];
              }
              if (item.label === "Opportunities") {
                title =
                  getLocalizedStrings().label.SIDE_NAV[
                    item.label.toUpperCase()
                  ];
              }
              // if (item.label === "Opportunities") {
              // 	title = "Pipeline";
              // }
              if (!_isDisabled) {
                props.containerelement = <Link to={url} />;
              }
              let itemName = getItemName(item);
              // console.log('filters: ', filters.recentFilters)
              return (
                <div
                  key={divId + item.id}
                  id={divId + item.id}
                  className="filter-menu"
                >
                  <ListItem
                    onMouseEnter={(event) =>
                      recentFilterOpen(objectName, item, divId, divIdVal, event)
                    }
                    onClick={(event) => onClick(item, divId, url, event)}
                    onMouseLeave={() => closeRecentFilter()}
                    style={{
                      padding: "11px 2px",
                      backgroundColor: backgroundColor,
                      height: "40px",
                    }}
                    {...props}
                  >
                    <ListItemButton>
                      <ListItemText
                        primary={<div style={{ color }}>{title}</div>}
                      />
                    </ListItemButton>
                  </ListItem>
                </div>
              );
            }
          }
        });
      }
    } catch (e) {
      console.error("Error in 'TopNavBar.js -> generateModuleItemList()':" + e);
    }
    setModuleItms(_moduleItems);
  };

  const handlePosition = (ele, top) => {
    let innerWidth = document.body.clientWidth;
    try {
      if (isValidParam(ele)) {
        let rect = ele?.getBoundingClientRect();
        if (rect?.right > innerWidth) {
          let obj = { left: innerWidth - (rect?.width + 12), top: top };
          changeSubMenuPosition(obj);
        }
      }
    } catch (error) {
      console.error("Error in 'TopNavBar.js -> handlePosition()':" + error);
    }
  };

  const recentFilterOpen = (object, item, divId, prevDivIdVal, event) => {
    dispatch({
      type: "SET_SUB_MENU",
      payload: { parent: "", child: "" },
    });
    let _isDisabled = getBooleanParam(isDisabled);
    if (!_isDisabled) {
      closeSubMenu();
      setIsOpenRecentFilter(true);
      // let anchorEl = event.currentTarget;
      let recentFilters = filters.recentFilters[object];
      let el = document.getElementById(divId + item.id);
      let rect = el?.getBoundingClientRect();
      let left = 0;
      let top = 0;

      //left = rect.right;
      left = rect?.left > 0 ? rect?.left : 0;

      if (object === constants.SALES_MODULE_TEMPLATE) {
        let subItemCount = item.sub_items.length;
        let menuHeight = subItemCount * 46;
        let contentHeight = document.body.clientHeight;
        let tempTop = rect?.top;
        tempTop +=
          document.body.scrollTop || document.documentElement.scrollTop;
        contentHeight +=
          document.body.scrollTop || document.documentElement.scrollTop;
        let calMenuHeight = contentHeight - tempTop;
        top = rect?.top + rect?.height + document.body.scrollTop || document.documentElement.scrollTop;
      } else {
        //top = rect.top;
        top = rect?.top + rect?.height;
        top += document.body.scrollTop || document.documentElement.scrollTop;
      }
      let currentDiv = null;
      if (divId !== divIdVal) {
        currentDiv = divId;
      }

      let position = { left: left };
      if (top > 0) {
        position.top = top;
      }
      if (object === constants.OPERATIONS_REPORTS) {
        let tab = getActiveTab();
        let tabType = getStringParam(tab?.type);
        if (isValidParam(tabType) && tabType === TYPE_LIST_VIEW) {
          let contentHeight = document.body.clientHeight;
          let menuHeight = contentHeight - top;
          if (menuHeight < 225) {
            position.maxHeight = 140;
          }
        }
      }
      // console.log(salesRecentMenu);
      if (
        item.object === constants.LEAD_CAPTURE ||
        item.object === constants.EMAIL_MARKETING ||
        item.object === constants.OFFERS ||
        item.object === constants.OPERATIONS_ONBOARDING ||
        item.object === constants.HUB ||
        makeValidObjectName(item.object).toLowerCase() ===
          constants.OPERATIONS_REPORTS ||
        item.object === constants.SALES_MODULE_TEMPLATE ||
        item.object === constants.OPERATIONS_ORDER_OBJECT ||
        item.object === constants.REPORTS_DASHBORD ||
        item.object === constants.ROYALTY ||
        (item.object === "social_marketing" && app.me.is_distributed_marketing)
      ) {
        let itemLabel = item.label;
        itemLabel = getLocalizedStrings().label.HEADER.hasOwnProperty(itemLabel)
          ? getLocalizedStrings().label.HEADER[itemLabel]
          : itemLabel;
        // console.log(item);
        let content = (
          <div
            style={{ display: "flex", overflowX: "hidden" }}
            ref={(el) => handlePosition(el, top)}
            className="recentFilterItems"
          >
            <div className="sf-RecentFilters" style={{ float: "left" }}>
            <RecentFilters
              key="recentFilters"
              selectPrevItem={selectPrevItem}
              selectCurrentItem={selectCurrentItem}
              divId={divId}
              mounted={true}
              object={object}
              recentFilterClose={() => recentFilterClose()}
              closeSubMenu={closeSubMenu}
              item={item}
              
            />
            </div>
            <div className="vertical-divider" style={{ float: "right" }}>
            <div style={{ marginLeft: "-10px" }} className="sf-RecentFilters">
              <ColumnRecent
                object={item.object}
                callFrom="menu"
                itemLabel={itemLabel}
                moduleItemId={item.id}
                changeSubMenuPosition={changeSubMenuPosition}
                closeSubMenu={closeSubMenu}
                item={item}
               
              />
            </div>
            </div>
          </div>
        );
        setRecentFilters(content);
        setPrevDivIdVal(prevDivIdVal);
        openSubMenu(content, position, currentDiv);
      } else if (!isValidParam(recentFilters)) {
        let promise = getRecentFiltersPromise(object);
        if (isValidParam(promise)) {
          promise.then((response) => {
            if (isValidParam(response)) {
              filters.recentFilters[object] = response.recentFilters;
              if (1 === 1) {
                let itemLabel = item.label;
                itemLabel = getLocalizedStrings().label.HEADER.hasOwnProperty(
                  itemLabel
                )
                  ? getLocalizedStrings().label.HEADER[itemLabel]
                  : itemLabel;
                let itemName = getItemName(item);
                if (
                  object === constants.SALES_OBJECT &&
                  item.type === "Reports"
                ) {
                  let subItemCount = response.recentFilters.length;
                  let menuHeight = subItemCount * 46;
                  let contentHeight = document.body.clientHeight;
                  //let tempTop = rect.top;
                  let tempTop = top;
                  contentHeight +=
                    document.body.scrollTop ||
                    document.documentElement.scrollTop;
                  let calMenuHeight = contentHeight - tempTop;
                  if (menuHeight > calMenuHeight) {
                    position.maxHeight = calMenuHeight;
                  }
                }

                let content = (
                  <div
                    className="recentFilterItems"
                    ref={(el) => handlePosition(el, top)}
                    style={{ display: "flex", overflowX: "hidden" }}
                  >
                    <div style={{ float: "left" }}>
                      <div className="sbbmenu">
                        <Link
                          to={"#"}
                          style={{
                            color: "#E0E0E0",
                            textDecoration: "none",
                            lineHeight: "30px",
                          }}
                          onClick={() =>
                            handleTouch(
                              item.has_filter,
                              object,
                              itemName +
                                " " +
                                getLocalizedStrings().label.HOME.HOME,
                              item.type,
                              item.image_name,
                              divId,
                              "/" + object + "/filters",
                              item.id
                            )
                          }
                        >
                          <span style={{ marginRight: "10px" }}>
                            <i
                              className="fa fa-home"
                              style={{ fontSize: "17px" }}
                            />
                          </span>
                          <span>
                            {itemLabel} {getLocalizedStrings().label.HOME.HOME}
                          </span>
                        </Link>
                      </div>

                      <div className="sf-RecentFilters">
                        <RecentFilters
                          key="recentFilters"
                          selectPrevItem={selectPrevItem}
                          selectCurrentItem={selectCurrentItem}
                          divId={divId}
                          mounted={true}
                          recentFilters={response.recentFilters}
                          object={object}
                          recentFilterClose={() => recentFilterClose()}
                          closeSubMenu={closeSubMenu}
                          item={item}
                        />
                      </div>
                    </div>
                    {object !== constants.SALES_OBJECT && (
                      <div
                        className="vertical-divider"
                        style={{ float: "right" }}
                      >
                        {/* <div className='vertical-divider'> */}
                        <div style={{ marginLeft: "-10px" }}>
                          <ColumnRecent
                            object={item.object}
                            callFrom="menu"
                            itemLabel={itemLabel}
                            moduleItemId={item.id}
                            changeSubMenuPosition={changeSubMenuPosition}
                            closeSubMenu={closeSubMenu}
                          />
                        </div>
                        {/* </div> */}
                      </div>
                    )}
                  </div>
                );
                setRecentFilters(content);
                setPrevDivIdVal(prevDivIdVal);
                openSubMenu(content, position, currentDiv);
              }
            }
          });
        }
      } else {
        let url =
          "/" +
          object +
          (object === constants.SALES_OBJECT
            ? "/" + LinkToType.TYPE_REPORT
            : "/filters");
        let itemLabel = item.label;

        itemLabel = getLocalizedStrings().label.HEADER.hasOwnProperty(itemLabel)
          ? getLocalizedStrings().label.HEADER[itemLabel]
          : itemLabel;
        let itemName = getItemName(item);
        if (object === constants.SALES_OBJECT && item.type === "Reports") {
          let subItemCount = recentFilters.length;
          let menuHeight = subItemCount * 46;
          let contentHeight = document.body.clientHeight;
          //let tempTop = rect.top;
          let tempTop = top;
          tempTop +=
            document.body.scrollTop || document.documentElement.scrollTop;
          contentHeight +=
            document.body.scrollTop || document.documentElement.scrollTop;
          let calMenuHeight = contentHeight - tempTop;
          if (menuHeight > calMenuHeight) {
            position.maxHeight = calMenuHeight;
          }
        }
        let content = (
          <div
            className="recentFilterItems"
            ref={(el) => handlePosition(el, top)}
            style={{ display: "flex", overflowX: "hidden" }}
          >
            <div style={{ float: "left" }}>
              <div className="sbbmenu">
                <Link
                  to={url}
                  style={{
                    color: "#E0E0E0",
                    textDecoration: "none",
                    lineHeight: "30px",
                  }}
                  onClick={() =>
                    handleTouch(
                      item.has_filter,
                      object,
                      itemName + " " + getLocalizedStrings().label.HOME.HOME,
                      item.type,
                      item.image_name,
                      divId,
                      "/" + object + "/filters",
                      item.id
                    )
                  }
                >
                  <span style={{ marginRight: "10px" }}>
                    <i className="fa fa-home" style={{ fontSize: "17px" }} />
                  </span>
                  <span>
                    {itemLabel} {getLocalizedStrings().label.HOME.HOME}
                  </span>
                </Link>
              </div>
              <div className="sf-RecentFilters">
                <RecentFilters
                  key="recentFilters"
                  selectPrevItem={selectPrevItem}
                  selectCurrentItem={selectCurrentItem}
                  divId={divId}
                  mounted={true}
                  recentFilters={recentFilters}
                  object={object}
                  recentFilterClose={() => recentFilterClose()}
                  closeSubMenu={closeSubMenu}
                  item={item}
                />
              </div>
            </div>
            {object !== constants.SALES_OBJECT && (
              <div className="vertical-divider" style={{ float: "right" }}>
                {/* <div className='vertical-divider'> */}
                <div style={{ marginLeft: "-10px" }}>
                  <ColumnRecent
                    object={item.object}
                    callFrom="menu"
                    itemLabel={itemLabel}
                    moduleItemId={item.id}
                    changeSubMenuPosition={changeSubMenuPosition}
                    closeSubMenu={closeSubMenu}
                  />
                </div>
                {/* </div> */}
              </div>
            )}
          </div>
        );
        setRecentFilters(content);
        setPrevDivIdVal(prevDivIdVal);
        openSubMenu(content, position, currentDiv);
      }
    }
  };

  const recentFilterClose = () => {
    selectCurrentItem(prevDivIdVal);
  };

  const onClick = (itemDetails, divId, url) => {
    try {
      let name = itemDetails.name;
      let label = itemDetails.label;
      let component = itemDetails.object;
      let type = itemDetails.type;
      let imgName = itemDetails.image_name;
      let hasFilter = itemDetails.has_filter;
      let parameterId = itemDetails.parameter_id;
      if (
        component === constants.APPOINTMENTS ||
        component === constants.TASKS
      ) {
        itemDetails.object = constants.APPOINTMENTS;
        url = "/" + constants.APPOINTMENTS + "/" + "calendar";
      }
      let _isDisabled = getBooleanParam(isDisabled);
      if (!_isDisabled && component !== constants.LMS_MENU_OBJECT) {
        if (automationCampaign.jsPlumbInst !== null) {
          automationCampaign.jsPlumbInst.deleteEveryConnection();
        }
        if (component === constants.ASSETS_OBJECT) {
          let hasManageAssetsPermission = hasAccessPermission(
            constants.ASSETS_OBJECT,
            constants.SECURITY_LEVEL_TYPE_PERMISSION,
            constants.PERMISSION_NAME_MANAGE_ASSETS
          );
          if (!hasManageAssetsPermission) {
            sfDialogs.alert(
              getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
              getLocalizedStrings().message.COMMON.ACCESS_DENIED,
              null
            );
            return false;
          }
        }
        if (component === constants.INBOX_OBJECT) {
          let phone_text_provider =
            app.me.phone_text_provider ?? constants.NONE;
          if (phone_text_provider == constants.NONE) {
            sfDialogs.alert(
              getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
              getLocalizedStrings().message.PHONE_AND_TEXT_INTEGRATION
                .NO_PROVIDER,
              null
            );
            return false;
          }
        }
        if (component !== constants.AUDIT_ANSWERS_OBJECT) {
          component = makeValidAppUrl(component);
        }
        setSelectedModuleInfo(component, itemDetails.id);
        selectCurrentItem(divId);

        let objTabs = [];
        let activeTabIndex = 0;
        let item = null;
        if (name.toLowerCase() === LinkToType.TYPE_REPORT) {
          type = TYPE_REPORT;
          store.dispatch(openLeftSideMenu(false));
        }

        if (component === "appointments") {
          if (app.selectedModuleName === "Marketing") {
            let calAssign = getArrayParam(calendarInfo.assign);
            if (isValidParam(calAssign) && calAssign.length > 0) {
              calAssign = calAssign.filter(function (el) {
                return el.value === "Campaigns";
              });
              calAssign = getArrayParam(calAssign);
              if (isValidParam(calAssign) && calAssign.length > 0) {
                dispatch(refreshCalendarForBroadcast(true));
              }
            }
          }
          item = "calendartask";
          type = TYPE_HOME;
        } else if (component === constants.TASKS) {
          item = constants.TASKS;
          type = TYPE_HOME;
        }
        // else if (component === constants.WEBSITE_TRACKING) {
        // 	item = "website-tracking";
        // 	type = TYPE_WEBSITE_TRACKING;
        // }
        else if (
          component === constants.DASHBOARD ||
          component === constants.SALES_OBJECT
        ) {
          item = component;
          type = TYPE_DASHBOARD;
          if (name.toLowerCase() === LinkToType.TYPE_REPORT) {
            type = TYPE_HOME;
            label = label + " " + getLocalizedStrings().label.HOME.HOME;
          }
        } else if (component === constants.ONBOARDING_OBJECT) {
          item = component;
          type = TYPE_LIST_VIEW;
        } else if (
          type === constants.OPERATION_REPORT_ACTIVITIES ||
          type === constants.OPERATION_REPORT_CONVERSIONS ||
          type === constants.OPERATION_REPORT_CAMPAIGNS ||
          component === constants.OPERATIONS_DASHBOARD
        ) {
          item = component;
          type = TYPE_REPORT;
        } else if (type === "Queries") {
          item = component;
          type = TYPE_LIST_VIEW;
        } else if (itemDetails.type == "externallink") {
          if (
            itemDetails.url.indexOf("https://") == 0 ||
            itemDetails.url.indexOf("http://") == 0
          ) {
            window.open(itemDetails.url, "_blank");
          } else {
            itemDetails.url = "https://" + itemDetails.url;
            window.open(itemDetails.url, "_blank");
          }
        } else {
          item = component;
          type = TYPE_OTHER_VIEW;
        }

        document.documentElement.style.overflow = "auto";

        // if (makeValidObjectName(component) === constants.WEBSITE_TRACKING) {
        // 	let websiteObject = {};
        // 	websiteObject.isWebsiteTracking = false;
        // 	websiteObject.isWebsiteDashboard = false;
        // 	let promise = Promise.resolve(
        // 		HTTPClient.get(endPoints.WEBSITE_TRACKING.IS_EXISTS, null)
        // 	);
        // 	promise.then((response) => {
        // 		let responseObject = response;
        // 		if (
        // 			responseObject !== null &&
        // 			responseObject !== undefined &&
        // 			responseObject.hasOwnProperty("id") &&
        // 			responseObject.id > 0
        // 		) {
        // 			websiteObject.isWebsiteDashboard = true;
        // 			websiteObject.userName = responseObject.clicky_user_name;
        // 		} else {
        // 			websiteObject.isWebsiteTracking = true;
        // 		}
        // 		let tab = {
        // 			item: item,
        // 			label: label,
        // 			object: component,
        // 			type: type,
        // 			imgName: imgName,
        // 			url: url,
        // 			info: { websiteObject: websiteObject },
        // 			isActive: true,
        // 		};
        // 		addTab(tab);
        // 	});
        // } else
        if (
          component === constants.SOCIAL_MARKETING ||
          component === constants.ONLINE_REPUTATION
        ) {
          let hasSecurityRolePermission = false;
          let marketingProps = { ...props, app };
          if (app.me.is_tenant) {
            redirectMarketingIntegration(component, marketingProps);
          } else {
            let landingPagePromise = getLandingPageAndMIPermission(app.me.t_id);
            if (landingPagePromise !== null) {
              landingPagePromise.then((response) => {
                if (response.status === 0) {
                  hasSecurityRolePermission =
                    response.hasSecurityRolePermission;
                } else {
                  hasSecurityRolePermission = false;
                }

                if (hasSecurityRolePermission) {
                  redirectMarketingIntegration(component, marketingProps);
                } else {
                  sfDialogs.alert(
                    getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                    getLocalizedStrings().message.COMMON.ACCESS_DENIED,
                    null
                  );
                }
              });
            }
          }
          return false;
        } else {
          let activeTab = getActiveTab();
          let isRedirect = type == "externallink" ? false : true;
          let info = {};
          if (component === constants.ONBOARDING_OBJECT) {
            info = {
              filter: {
                id: constants.ONBOARDING_QUERY_ID,
                name: getLocalizedStrings().label.HEADER["Onboarding"],
                type: "query",
              },
            };
          } else if (
            component === constants.CASES_OBJECT &&
            constants.MODULE_HUB === app.selectedModuleName
          ) {
            info.query_link_id = parameterId;
          }

          if (component === constants.TASKS) {
            label = getLocalizedStrings().label.HEADER.TASKS_PAGE;
          }

          let tab = {
            item: item,
            label: label,
            object: component,
            type: type,
            imgName: imgName,
            url: url,
            info: info,
            isActive: true,
          };
          if (
            (component === constants.SALES_MODULE_TEMPLATE ||
              component === constants.MARKETING_TEMPLATE_OBJECT ||
              component === constants.WEB_FORMS ||
              component === constants.SOLUTIONS_OBJECT ||
              component === constants.AUDIT_ANSWERS_OBJECT) &&
            getBooleanParam(listViewMounted) &&
            component === listViewMounted
          ) {
            let params = {};
            if (
              activeTab.info.hasOwnProperty("startIndex") &&
              activeTab.info.hasOwnProperty("pageSize")
            ) {
              params.start_index = activeTab.info.startIndex;
              params.page_size = activeTab.info.pageSize;
            }
            refreshListView(component, params);
          } else if (
            component === constants.SALES_MODULE_TEMPLATE ||
            component === constants.MARKETING_TEMPLATE_OBJECT ||
            component === constants.WEB_FORMS ||
            component === constants.SOLUTIONS_OBJECT ||
            component === constants.AUDIT_ANSWERS_OBJECT
          ) {
            store.dispatch(setMounted(false));
          } else if (component === constants.DASHBOARD) {
            store.dispatch(openLeftSideMenu(false));
          } else if (component === constants.APPOINTMENTS) {
            // tab = getCalendarTab(tab);
          }
          if (type === TYPE_LIST_VIEW) {
            let recentFilters = getArrayParam(
              getObjectParam(filters.recentFilters)[component]
            );
            recentFilters = recentFilters.filter((f) => f.name);
            if (recentFilters && recentFilters.length > 0) {
              let filter = recentFilters[0];
              tab.info = { filter, object: component };
              tab.label = filter.name;
            }
            if (itemDetails.object === constants.REPORTED_SALES_OBJECT) {
              tab.object = itemDetails.object;
            }
          }

          addTab(type == "externallink" ? "" : tab, isRedirect);
          /** START:  Used for click on Broadcast campaign menu to reset tab from AB Campaign to Broadcast Campaign */
          activeTab = getActiveTab();
          if (component === constants.BROADCAST_CAMPAIGNS_OBJECT) {
            if (
              isValidParam(activeTab) &&
              isValidParam(activeTab.info) &&
              getStringParam(activeTab.info.object) ===
                constants.AB_CAMPAIGNS_OBJECT
            ) {
              activeTab.info = {
                filter: getObjectParam(activeTab.info.filter),
                isShowGroupBy: getBooleanParam(activeTab.info.isShowGroupBy),
              };
              updateTabInfo(activeTab.info);
            }
          } else if (
            component === constants.AUTOMATION_DESIGNER_OBJECT &&
            component === activeTab["object"]
          ) {
            if (activeTab["url"].includes(constants.TOUCH_CAMPAIGNS_OBJECT)) {
              updateActiveTabProps({ url: tab.url });
            }
          }
          /** END:  Used for click on Broadcast campaign menu to reset tab from AB Campaign to Broadcast Campaign */
        }
      }
      if (component === constants.LMS_MENU_OBJECT) {
        const promise = Promise.resolve(HTTPClient.get("/v3/lms/auth"));
        if (isValidParam(promise)) {
          promise.then((response) => {
            if (response?.length > 0) {
              window.open(response, "LMS_TRAINING_WINDOW"); // name: 'LMS_TRAINING_WINDOW' - open tab in existing open tab if its already open else new tab will be	opened.
            } else {
              sfDialogs.alert(
                getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                getLocalizedStrings().message.COMMON.LMS_USER_NOT_AVAILABLE,
                null
              );
            }
          });
        }
      }
    } catch (error) {
      console.error(
        "Error in 'TopNavBar.js -> onClick() -> on menu click ':" + error
      );
    }
  };

  const openLink = (item) => {
    let externalLink = item.url.toLowerCase();
    if (
      externalLink.indexOf("http:") !== -1 ||
      externalLink.indexOf("https:") !== -1
    ) {
      if (item.object === "community") {
        let projectId = app.me.projectId;
        let userId = app.me.id;
        projectId = projectId * 19 + 7;
        if (externalLink.indexOf("/#/") <= -1) {
          externalLink = externalLink + "/#/";
        }
        externalLink = externalLink + projectId + "-" + userId;
      }
      window.open(externalLink);
    } else {
      window.open("http://" + externalLink);
    }
  };

  const handleTouch = (
    hasFilter,
    object,
    label,
    type,
    imgName,
    divId,
    url,
    moduleItemId,
    event
  ) => {
    let _isDisabled = getBooleanParam(isDisabled);
    if (!_isDisabled) {
      selectCurrentItem(divId);
      // event.preventDefault();
      setSelectedModuleInfo(object, moduleItemId);
      let homeTab = {
        item: object,
        label: label,
        object: object,
        type: TYPE_HOME,
        imgName: "fa fa-home",
        url: url,
        info: {},
        isActive: true,
      };
      addTab(homeTab, true);

      document.documentElement.style.overflow = "auto";
      closeSubMenu();
    }
  };

  const handleTouchFilter = (
    hasFilter,
    object,
    label,
    type,
    imgName,
    divId,
    url,
    event
  ) => {
    let _isDisabled = getBooleanParam(isDisabled);
    if (!_isDisabled) {
      selectCurrentItem(divId);
      event.preventDefault();
      setSelectedModuleInfo(object);

      let homeTab = {
        item: object,
        label: label,
        object: object,
        type: TYPE_HOME,
        imgName: "fa fa-home",
        url: url,
      };
      addTab(homeTab, true);

      document.documentElement.style.overflow = "auto";
    }
  };

  const selectCurrentItem = (divId) => {
    try {
      setPrevDivIdVal(divId);
    } catch (error) {
      console.error("Error in 'TopNavBar.js -> selectCurrentItem()':" + error);
    }
  };

  const selectPrevItem = (divId) => {
    try {
      setPrevDivIdVal(divId);
    } catch (error) {
      console.error("Error in 'TopNavBar.js -> selectPrevItem()':" + error);
    }
  };

  const closeRecentFilter = () => {
    try {
      setIsOpenRecentFilter(false);
    } catch (error) {
      console.error("Error in 'TopNavBar.js -> closeRecentFilter()':" + error);
    }
  };

  const isModulueItemOverflow = (el) => {
    let _showOverFlowButton = false;
    try {
      if (el) {
        let moduleItemsEle = document.getElementsByClassName("menu-overflow");
        if (moduleItemsEle.length > 0) {
          moduleItemsEle = moduleItemsEle[0];
          if (moduleItemsEle.scrollWidth > el.clientWidth) {
            _showOverFlowButton = true;
          }
        }
        if (getBooleanParam(showOverFlowButton) !== _showOverFlowButton) {
          setShowOverFlowButton(_showOverFlowButton);
        }
      }
    } catch (error) {
      console.error(
        "Error in 'TopNavBar.js -> isModulueItemOverflow()':" + error
      );
    }
  };

  const scroll = (direction) => {
    try {
      let scrollAmount = 0;
      let moduleItemsEle = document.getElementsByClassName("menu-overflow");
      if (moduleItemsEle.length > 0) {
        moduleItemsEle = moduleItemsEle[0];
        let slideTimer = setInterval(function () {
          if (direction === "left") {
            moduleItemsEle.scrollLeft -= 20;
          } else {
            moduleItemsEle.scrollLeft += 20;
          }
          scrollAmount += 20;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 50);
      }
    } catch (error) {
      console.error("Error in 'TopNavBar.js -> scroll()':" + error);
    }
  };

  return (
    <div
      ref={(el) => isModulueItemOverflow(el)}
      style={{
        display: "flex",
        backgroundColor: "#515151",
        width: "auto",
        height: 40,
      }}
    >
      {/* <span style={{ paddingLeft: '20px' }}></span> */}
      <List
        className="menu-overflow"
        style={{
          display: "flex",
          overflow: "hidden",
          padding: "0px",
          paddingLeft: "20px",
          height: "40px",
          width: "100%",
        }}
      >
        {moduleItms}
      </List>

      {showOverFlowButton && (
        <span
          onClick={() => scroll("left")}
          title={getLocalizedStrings().label.SIDE_NAV.SCROLL_TO_LEFT}
          style={{ ...styles.chevron, padding: "11px 5px 10px 15px" }}
        >
          <i className="fas fa-chevron-circle-left"></i>
        </span>
      )}
      {showOverFlowButton && (
        <span
          onClick={() => scroll()}
          title={getLocalizedStrings().label.SIDE_NAV.SCROLL_TO_RIGHT}
          style={{ ...styles.chevron, marginRight: "25px" }}
        >
          <i className="fas fa-chevron-circle-right"></i>
        </span>
      )}
    </div>
  );
};

// RecentFilters component
const RecentFilters = (props) => {
  const {
    selectCurrentItem,
    selectPrevItem,
    closeSubMenu,
    mounted,
    object,
    item,
    divId,
    recentFilters,
    
  } = props;
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  let mitem = item;

  const openTab = (filter) => {
    let view = "queries";
    let _item = JSON.parse(JSON.stringify(item));
    setSelectedModuleInfo(object, _item.id);
    let itemName = getItemName(_item);
    let homeTab = {
      item: object,
      label: itemName + " " + getLocalizedStrings().label.HOME.HOME,
      object: object,
      type: TYPE_HOME,
      imgName: "fa fa-home",
      url: "/" + object + "/filters",
      info: {},
      isActive: true,
    };
    if (object === constants.SALES_OBJECT) {
      view = LinkToType.TYPE_REPORT;
    } else {
      dispatch(setHeader(object, null, null, true, true));
    }

    let tab = {
      item: object,
      label: filter.name,
      object: object,
      type: TYPE_LIST_VIEW,
      imgName: getImageNameByObject[object],
      info: {
        filter: { id: filter.id, name: filter.name, type: filter.type },
        object: object,
      },
      url: "/" + object + "/" + view,
    };
    if (filter.type === LinkToType.TYPE_REPORT) {
      tab.type = TYPE_REPORT;
      tab.info = {
        selectedReport: {
          item_name: filter.name,
          item_type: filter.report_type,
          parameter_id: filter.id,
          report_subtype: filter.report_subtype,
        },
      };

      if (filter.report_type === constants.USER_REPORT_TYPE) {
        tab.url = getReportUrl(
          object,
          filter.report_type + "-" + filter.report_subtype,
          filter.id
        );
      } else {
        tab.url = getReportUrl(object, filter.report_type, filter.id);
      }

      addTab(tab, true, homeTab, true);
      saveRecentReportItems(filter.name, filter.report_type, filter.id, object);
      let params = {
        id: filter.id,
        type: LinkToType.TYPE_REPORT,
        name: filter.name,
        report_type: filter.report_type,
        report_subtype: filter.report_subtype,
      };
      addRecentFilterInRedux(params);
      addRecentRecordInRedux({ ...params, recent: filter.name });
    } else {
      addTab(tab, true, homeTab, true);
      saveRecentFilter(object, filter);
    }

    selectCurrentItem(divId);
    selectPrevItem(divId);
    closeSubMenu();
  };

  const openTabByItem = (item, moduleItem) => {
    let tabObj = null;
    let objTabs = [];
    let activeTabIndex = 0;
    let userSecurityLevel = app.me.t_name;
    let object = makeValidObjectName(item.object);
    if (object === constants.LANDING_PAGES_OBJECT) {
      let hasSecurityRolePermission = false;
      if (app.me.is_tenant) {
        redirectLandingPage();
      } else {
        let landingPagePromise = getLandingPageAndMIPermission(app.me.t_id);
        if (landingPagePromise !== null) {
          landingPagePromise.then((response) => {
            if (response.status === 0) {
              hasSecurityRolePermission = response.hasSecurityRolePermission;
            } else {
              hasSecurityRolePermission = false;
            }

            if (hasSecurityRolePermission) {
              redirectLandingPage();
            } else {
              sfDialogs.alert(
                getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                getLocalizedStrings().message.COMMON.ACCESS_DENIED,
                null
              );
            }
          });
        }
      }
    } else if (
      object === constants.BUSINESS_LISTINGS ||
      ((object === constants.SOCIAL_MARKETING ||
        object === "social_marketing") &&
        app.me.is_distributed_marketing)
    ) {
      let hasSecurityRolePermission = false;
      if (app.me.is_tenant) {
        redirectMarketingIntegration(item.object, props, item.temp_object);
      } else {
        let landingPagePromise = getLandingPageAndMIPermission(app.me.t_id);
        if (landingPagePromise !== null) {
          landingPagePromise.then((response) => {
            if (response.status === 0) {
              hasSecurityRolePermission = response.hasSecurityRolePermission;
            } else {
              hasSecurityRolePermission = false;
            }

            if (hasSecurityRolePermission) {
              redirectMarketingIntegration(
                item.object,
                props,
                item.temp_object
              );
            } else {
              sfDialogs.alert(
                getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                getLocalizedStrings().message.COMMON.ACCESS_DENIED,
                null
              );
            }
          });
        }
      }
      return false;
    } else if (object === constants.LANDING_PAGES_FROALA_OBJECT) {
      if (
        app.me.is_tenant ||
        userSecurityLevel === "Marketing" ||
        userSecurityLevel === "Admin"
      ) {
        openNewTab(item, moduleItem);
      } else {
        sfDialogs.alert(
          getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
          getLocalizedStrings().message.COMMON.ACCESS_DENIED,
          null
        );
        return false;
      }
    } else {
      openNewTab(item, moduleItem);
    }
    closeSubMenu();
  };

  const openNewTab = (item, moduleItem) => {
    let object = makeValidObjectName(item.object);
    let hasPermission = false;
    let hasConfigured = false;
    let me = getObjectParam(app.me);
    if (
      object === constants.MARKETING_TEMPLATE_OBJECT ||
      object === constants.AUTOMATION_DESIGNER_OBJECT ||
      object === constants.BROADCAST_CAMPAIGNS_OBJECT
    ) {
      hasPermission = getBooleanParam(me.is_email_marketing);
    } else {
      hasPermission = true;
    }
    let url = "";
    if (hasPermission) {
      setSelectedModuleInfo(object, moduleItem.id, mitem);
      let tab = {
        item: object,
        label: item.label,
        object: object,
        imgName: getImageNameByObject[object],
        url: url,
      };
      url = "/" + object + "/queries";
      if (tab.item === constants.SOCIAL_MARKETING) {
        url = "/" + object + "/other";
      }
      let type = TYPE_LIST_VIEW;
      if (
        getStringParam(item.type).toLowerCase() === LinkToType.TYPE_REPORT ||
        object === constants.OPERATIONS_DASHBOARD
      ) {
        url = "/" + makeValidAppUrl(object) + "/" + LinkToType.TYPE_REPORT;
        if (object === constants.SALES_OBJECT) {
          type = TYPE_HOME;
          tab.label = item.name + " " + getLocalizedStrings().label.HOME.HOME;
        }
      } else if (object === constants.DASHBOARD) {
        url = makeValidAppUrl("/" + item.object + "/" + item.home_name);
        type = TYPE_DASHBOARD;
      } else if (
        object === constants.OPERATIONS_CAMPAIGNS ||
        object === constants.OPERATIONS_CONVERSIONS ||
        object === constants.OPERATIONS_ACTIVITIES ||
        object === constants.CUSTOM_OPERATIONS_REPORTS ||
        object === constants.OPERATIONS_ASSETS_REPORTS ||
        object === constants.ROYALTY_REPORT_OBJECT ||
        object === constants.ROYALTY_REPORT_OBJECT_V2 ||
        object === constants.COMPLIANCE_REPORT_OBJ ||
        object === constants.COMPLIANCE_REPORT_OBJECT ||
        object === constants.AUDIT_REPORT
      ) {
        url =
          "/" + makeValidAppUrl(item.object) + "/" + makeValidAppUrl(item.type);
      } else if (object === constants.ONBOARDING_OBJECT) {
        url = makeValidAppUrl("/" + object + "/" + item.type);
        type = TYPE_LIST_VIEW;
        tab.info = {
          filter: {
            id: constants.ONBOARDING_QUERY_ID,
            name: "Onboarding Units",
            type: "query",
          },
        };
        dispatch(setHeader(object, null, null, false, true));
      } else if (object === constants.PROJECTS_OBJECT) {
        url = makeValidAppUrl("/" + object + "/filters");
        type = TYPE_HOME;
        let itemName = getItemName(item);
        tab.label = itemName + " " + getLocalizedStrings().label.HOME.HOME;
        dispatch(setHeader(object, tab.label, null, false, true));
      } else if (
        object === constants.NEWS_OBJECT &&
        constants.MODULE_OPERATIONS === app.selectedModuleName
      ) {
        tab.info = { isShowGroupBy: true };
      } else if (object === constants.COUPONS_OBJECT) {
        tab.label = item.label;
      } else if (object === constants.ROYALTY_RUN_OBJECT) {
        tab.info = { is_royalty_run_create: true };
      }
      tab = {
        ...tab,
        type: type,
        url: url,
      };
      addTab(tab, true);
      selectCurrentItem(divId);
      selectPrevItem(divId);
     
    } else {
      addTabForMarketingIntegration(
        props,
        object,
        hasPermission,
        hasConfigured
      );
    }
  };

  const redirectLandingPage = () => {
    let isValidUser = false;
    let params = {
      email: app.me.email,
    };
    let promise = Promise.resolve(
      HTTPClient.get(endPoints.LANDING_PAGE.VALID_USER, params)
    );
    promise.then((response) => {
      isValidUser = response.isValid;
      if (isValidUser) {
        doLandingPageLogin();
      } else {
        doLandingPageRegistration();
      }
    });
    return false;
  };

  const doLandingPageLogin = () => {
    if (document.getElementById("login") === null) {
      let form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("action", constants.LANDING_PAGE_LOGIN_URL);
      form.setAttribute("target", "_blank");
      form.setAttribute("id", "login");
      form.setAttribute("style", "display:none");

      let emailField = document.createElement("input");
      emailField.setAttribute("name", "email");
      emailField.setAttribute("id", "email");
      emailField.setAttribute("type", "hidden");
      emailField.setAttribute("value", app.me.email);
      form.appendChild(emailField);

      let passwordField = document.createElement("input");
      passwordField.setAttribute("name", "password");
      passwordField.setAttribute("id", "password");
      passwordField.setAttribute("type", "hidden");
      passwordField.setAttribute(
        "value",
        app.me.projectId + "_" + app.me.email
      );
      form.appendChild(passwordField);

      document.body.appendChild(form);
      form.submit();
    } else {
      document.getElementById("login").submit();
    }
  };

  const doLandingPageRegistration = () => {
    if (document.getElementById("regist") === null) {
      let form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("action", constants.LANDING_PAGE_SIGNUP_URL);
      form.setAttribute("target", "_blank");
      form.setAttribute("id", "regist");

      let userNameField = document.createElement("input");
      userNameField.setAttribute("name", "username");
      userNameField.setAttribute("id", "username");
      userNameField.setAttribute("type", "hidden");
      userNameField.setAttribute("value", app.me.name);
      form.appendChild(userNameField);

      let emailField = document.createElement("input");
      emailField.setAttribute("name", "email");
      emailField.setAttribute("id", "email");
      emailField.setAttribute("type", "hidden");
      emailField.setAttribute("value", app.me.email);
      form.appendChild(emailField);

      let passwordField = document.createElement("input");
      passwordField.setAttribute("name", "password");
      passwordField.setAttribute("id", "password");
      passwordField.setAttribute("type", "hidden");
      passwordField.setAttribute(
        "value",
        app.me.projectId + "_" + app.me.email
      );
      form.appendChild(passwordField);

      let projectidField = document.createElement("input");
      projectidField.setAttribute("name", "projectid");
      projectidField.setAttribute("id", "projectid");
      projectidField.setAttribute("type", "hidden");
      projectidField.setAttribute("value", app.me.projectId);
      form.appendChild(projectidField);

      document.body.appendChild(form);
      form.submit();
    } else {
      document.getElementById("regist").submit();
    }
  };

  const generateFilternames = (action) => {
    let filterItems = null;
    let filters = recentFilters;
    let filterCount = 0;
    let userSecurityLevel = app.me.t_name;
    let view = getSelectedViewFromCookie(app.me.projectId, object);
    if (object === constants.SALES_OBJECT) {
      view = LinkToType.TYPE_REPORT;
    }

    if (filters !== null && filters !== undefined) {
      let uniq = new Set(filters.map((e) => JSON.stringify(e)));
      filters = Array.from(uniq).map((e) => JSON.parse(e));
      // sortArrayObjectByProperty(filters, 'name');
      filterItems = map(filters, (filter, i) => {
        let linkToUrl = "/" + object;
        let reportIcon = null;
        let reportSubType = "";
        if (filter.type === LinkToType.TYPE_REPORT) {
          if (filter.report_type === constants.USER_REPORT_TYPE) {
            reportSubType = getIntParam(filter.report_subtype);
            reportIcon = getIconClass(filter.report_type, reportSubType);
            linkToUrl = getReportUrl(
              object,
              filter.report_type + "-" + filter.report_subtype,
              filter.id
            );
          } else {
            reportIcon = getIconClass("system", filter.report_type);
            linkToUrl = getReportUrl(object, filter.report_type);
          }
        } else {
          linkToUrl = linkToUrl + "/" + view;
        }
        let imageName =
          filter.type === "group"
            ? "fa fa-users"
            : filter.type === LinkToType.TYPE_REPORT
            ? reportIcon
            : "fa fa-search";
        if (filter.hasOwnProperty("name")) {
          if (
            filter.type === "" ||
            filter.type === "all" ||
            filter.type === "query" ||
            filter.type === "filter" ||
            filter.type === "group" ||
            filter.type === LinkToType.TYPE_REPORT
          ) {
            filterCount = ++i;
            let filterLabel = getLocalizedStrings().label.FILTER.hasOwnProperty(
              filter.name
            )
              ? getLocalizedStrings().label.FILTER[filter.name]
              : filter.name;
            return (
              <div
                className="sbbmenu"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                key={filter.type + i}
              >
                <Link
                  to={linkToUrl}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    action(filter);
                  }}
                >
                  <span
                    style={{ lineHeight: "30px", marginRight: "10px" }}
                    onMouseEnter={() => {
                      console.log("onMouseEnter");
                    }}
                  >
                    <i className={imageName} />
                  </span>
                  <span
                    style={{
                      fontSize: "14px",
                      lineHeight: "30px",
                      color: "#E0E0E0 !important",
                    }}
                  >
                    {/* {filter.name} */}
                    {filterLabel}
                  </span>
                </Link>
              </div>
            );
          }
        }
      });
      return filterItems;
    } else {
      let linkToUrl = "/" + object;
      let items = getArrayParam(item.sub_items);
      let uniq = new Set(items.map((e) => JSON.stringify(e)));
      items = Array.from(uniq).map((e) => JSON.parse(e));
      if (
        object !== constants.EMAIL_MARKETING &&
        object !== constants.ONBOARDING_OBJECT &&
        object !== constants.OPERATIONS_ONBOARDING &&
        object !== constants.HUB &&
        object !== constants.OFFERS &&
        makeValidObjectName(object) !== constants.OPERATIONS_REPORTS &&
        object !== constants.OPERATIONS_DASHBOARD &&
        object !== constants.OPERATIONS_ORDER_OBJECT &&
        object !== constants.REPORTS_DASHBORD &&
        object !== constants.SALES_MODULE_TEMPLATE &&
        object !== constants.ROYALTY
      ) {
        sortArrayObjectByProperty(items, "label");
      }

      let subItems = map(items, (item, i) => {
        let object = makeValidObjectName(item.object);
        let isAccessLandingPage = false;
        if (
          object === constants.LANDING_PAGES_FROALA_OBJECT &&
          (app.me.is_tenant || userSecurityLevel === "Marketing")
        ) {
          isAccessLandingPage = true;
        }
        if (
          object === constants.LANDING_PAGES_OBJECT ||
          // object === constants.WEBSITE_TRACKING ||
          object === constants.BUSINESS_LISTINGS ||
          (object === constants.LANDING_PAGES_FROALA_OBJECT &&
            !isAccessLandingPage)
        ) {
          linkToUrl = "#";
        } else if (
          object === constants.MARKETING_OBJECT &&
          getStringParam(item.type).toLowerCase() === LinkToType.TYPE_REPORT
        ) {
          linkToUrl = makeValidAppUrl(
            "/" + object + "/" + LinkToType.TYPE_REPORT
          );
        } else if (object === constants.DASHBOARD) {
          let itemName = getStringParam(item.name).toLowerCase();
          itemName = itemName.replace(" ", "-");
          linkToUrl = makeValidAppUrl(
            "/" + object + "/" + itemName + "/" + item.id
          );
        } else if (
          object === constants.OPERATIONS_CAMPAIGNS ||
          object === constants.OPERATIONS_CONVERSIONS ||
          object === constants.OPERATIONS_ACTIVITIES ||
          object === constants.CUSTOM_OPERATIONS_REPORTS ||
          object === constants.ONBOARDING_OBJECT
        ) {
          linkToUrl = makeValidAppUrl("/" + object + "/" + item.type);
        } else if (object === constants.PROJECTS_OBJECT) {
          linkToUrl = makeValidAppUrl("/" + object + "/filters");
        } else if (
          object === constants.SOCIAL_MARKETING &&
          (item.temp_object === "for_me" || item.temp_object === "for_my_units")
        ) {
          linkToUrl = "#";
        } else {
          linkToUrl = makeValidAppUrl("/" + object + "/queries");
        }

        let imageName = item.image_name;
        let label = item.label ? item.label : item.name;

        /** Multilingual for Sub Menu Items  of HUB */
        if (
          item.object === constants.USEFUL_LINK_OBJECT ||
          item.object === constants.NEWS_OBJECT ||
          item.object === constants.SALES_TEMPLATE ||
          item.object === constants.MARKETING_TEMPLATE ||
          item.object === constants.SMS_TEMPLATE ||
          item.object === constants.CALL_SCRIPT_TEMPLATE ||
          item.object === constants.AGREEMENT_TEMPLATE
        ) {
          label = isValidParam(
            getLocalizedStrings().label.SIDE_NAV[item.label.toUpperCase()]
          )
            ? getLocalizedStrings().label.SIDE_NAV[item.label.toUpperCase()]
            : item.name;
        }
        /** Multilingual for Sub Menu Items  End */

        if (item.hasOwnProperty("name")) {
          filterCount = ++i;
          return (
            <div
              className="sbbmenu"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              key={item.type + i}
            >
              <Link
                to={linkToUrl}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  action(item, getObjectParam(item));
                }}
                onMouseEnter={(e) => {
                  e.stopPropagation();
                  dispatch({
                    type: "SET_SUB_MENU",
                    payload: { parent: item.temp_object, child: label },
                  });

                  // console.log("sub menu hover", label);
                }}
              >
                <span style={{ lineHeight: "30px", marginRight: "10px" }}>
                  <i className={imageName} />
                </span>
                <span
                  style={{
                    fontSize: "14px",
                    lineHeight: "30px",
                    color: "#E0E0E0 !important",
                  }}
                  title={label}
                >
                  {label}
                </span>
              </Link>
            </div>
          );
        }
      });
      return subItems;
    }
  };

  let pageHeight = window.innerHeight - 247;
  let top = (pageHeight - 10) / 2;
  if (!mounted) {
    return (
      <div style={{ width: "100%", height: pageHeight }}>
        <div
          className="asset-loaderh"
          style={{ paddingTop: top, paddingLeft: "45%" }}
        >
          <div
            style={{
              ...styles.assetLoaderContainer,
              height: 50,
              width: 50,
              padding: 7,
            }}
          >
            <ShowCircularProgress
              size={30}
              style={{ marginTop: "3", marginLeft: "3" }}
            />
          </div>
        </div>
      </div>
    );
  }
  let obj = makeValidObjectName(item.object);
  let action =
    obj === constants.LEAD_CAPTURE ||
    obj === constants.EMAIL_MARKETING ||
    obj === constants.OPERATIONS_ONBOARDING ||
    // || obj === constants.OPERATIONS_DASHBOARD   (operationDashboad filter has removed so comment)--   Lalu
    obj === constants.ONBOARDING_OBJECT ||
    obj === constants.HUB ||
    obj === constants.OPERATIONS_REPORTS ||
    obj === constants.OFFERS ||
    obj === constants.OPERATIONS_ORDER_OBJECT ||
    obj === constants.REPORTS_DASHBORD ||
    obj === constants.SALES_MODULE_TEMPLATE ||
    obj === constants.ROYALTY ||
    (obj === constants.SOCIAL_MARKETING && app.me.is_distributed_marketing)
      ? openTabByItem
      : openTab;
  return (
    <div className="sf-generateFilternames" style={{ width: "100%" }}>
      {generateFilternames(action)}
    </div>
  );
};

export { TopNavBar };

const getLandingPageAndMIPermission = (templateId) => {
  let promise = null;
  let params = {
    security_level_name: "Admin,Marketing",
    template_id: templateId,
  };
  promise = Promise.resolve(
    HTTPClient.get(endPoints.COMMON.HAS_SECURITY_ROLE_PERMISSION, params)
  );
  return promise;
};

const redirectMarketingIntegration = (object, props, tempObject = null) => {
  try {
    if (isValidParam(props) && isValidParam(props.app)) {
      let hasPermission = false;
      let me = getObjectParam(props.app.me);

      if (object === constants.SOCIAL_MARKETING) {
        hasPermission = getBooleanParam(me.is_social_marketing);
      } else if (object === constants.ONLINE_REPUTATION) {
        hasPermission = getBooleanParam(me.is_reputation_management);
      } else if (object === constants.BUSINESS_LISTINGS) {
        hasPermission = getBooleanParam(me.is_business_listings);
      }

      if (hasPermission) {
        let promise = Promise.resolve(
          HTTPClient.get(endPoints.MARKETING_INTEGRATION.HAS_CONFIGURED, null)
        );
        if (isValidParam(promise)) {
          promise.then((response) => {
            if (isValidParam(response)) {
              let hasConfigured = getBooleanParam(response.is_configured);
              if (hasConfigured) {
                let accountContext = getStringParam(response.account_context);
                let integrationType = null;
                if (
                  object === constants.SOCIAL_MARKETING ||
                  object === "social_marketing"
                ) {
                  if (tempObject === "for_my_units") {
                    integrationType = "VBC";
                  } else {
                    integrationType = "SM";
                  }
                } else if (object === constants.ONLINE_REPUTATION) {
                  integrationType = "RM";
                } else if (object === constants.BUSINESS_LISTINGS) {
                  integrationType = "MS";
                }
                let token = localStorage.getItem("token");
                let url =
                  constants.endPoint +
                  endPoints.MARKETING_INTEGRATION.GET +
                  "?type=" +
                  integrationType +
                  "&account_context=" +
                  accountContext +
                  "&token=" +
                  token;
                // window.location = url;
                window.open(url);
              } else {
                addTabForMarketingIntegration(
                  props,
                  object,
                  hasPermission,
                  hasConfigured
                );
              }
            }
          });
        }
      } else {
        addTabForMarketingIntegration(props, object, hasPermission);
      }
    }
  } catch (e) {
    console.error(
      "Error in 'TopNavBar.js -> redirectMarketingIntegration()':" + e
    );
  }
};

const addTabForMarketingIntegration = (
  props,
  object,
  hasPermission,
  hasConfigured
) => {
  try {
    if (
      object !== constants.SOCIAL_MARKETING &&
      object !== constants.ONLINE_REPUTATION
    ) {
      setSelectedModuleInfo(props.object);
      props.selectCurrentItem(props.divId);
      props.selectPrevItem(props.divId);
    }

    hasPermission = getBooleanParam(hasPermission);
    hasConfigured = getBooleanParam(hasConfigured);
    let label = getObjectLabelByObject(object);
    if (
      object === constants.BROADCAST_CAMPAIGNS_OBJECT ||
      object === constants.AUTOMATION_DESIGNER_OBJECT
    ) {
      let arrItem = props.item.sub_items.filter((f) => {
        return makeValidObjectName(f.object) === object;
      });
      label = arrItem.length > 0 ? getStringParam(arrItem[0].label) : "";
    }
    let tab = {
      item: object,
      label: label,
      object: object,
      type: TYPE_OTHER_VIEW,
      imgName: getImageNameByObject(object),
      isActive: true,
      url: "/" + object + "/" + LinkToType.TYPE_OTHER_VIEW,
      info: { hasPermission: hasPermission, hasConfigured: hasConfigured },
    };
    addTab(tab, true);
  } catch (error) {
    console.error(
      "Error in 'TopNavBar.js -> addTabForMarketingIntegration()':" + error
    );
  }
};
