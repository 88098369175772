import * as React from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux';
//import { DragDropContext, DropTarget, DragSource } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { constants } from '../../../services/constants/constants';
import { isValidParam, getArrayParam, getBooleanParam, getStringParam, getIntParam, isUrl, getObjectParam, getFloatParam, makeValidObjectName } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import * as sfDialogs from '../components/sfDialogs';
import concat from 'lodash/concat';
import { Icon } from '@mui/material';
import _ from 'lodash';

const MODULE_LIST = {
    AVAILABLE_MENU_ITEMS: 'Available Menu Items'
}
let allMenuItems = [

    { name: 'quotes', label: 'My Quotes' },
    { name: 'invoices', label: 'My Invoices' },
    { name: 'appointments', label: 'My Requests' },
    { name: 'cases', label: 'My Cases' },
    { name: 'documents', label: 'Library' }
];
const menuTarget = {
    canDrop(props, monitor) {
        return true;
    },

    hover(props, monitor, component) {
        const draggingItem = monitor.getItem();
        if (draggingItem.name !== props.name) {
            props.moveCard(props, draggingItem.name);
        }
    },
}

const menuSource = {
    canDrag(props) {
        let isDragging = true;
        return isDragging;
    },

    isDragging(props, monitor) {
        return monitor.getItem().name === props.name
    },

    beginDrag(props, monitor, component) {
        const item = { name: props.name }
        return item;
    },

    endDrag(props, monitor, component) {
        props.handleSave();
    }
}

/*function collectDragSource(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    }
}*/

/*function collectDragTarget(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType()
    }
}*/

const mapStateToProps = (state) => {
    return {
        app: state.app,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },

    }
};

//@DragDropContext(HTML5Backend)
class PortalMenuConfiguration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            availableModuleList: [],
            allModuleItems: []
        }
    }
    UNSAFE_componentWillMount() {
        this.generateData(this.props);
    }

    componentDidMount() {
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    generateData = (props) => {
        try {

            this.getAvailableModuleItemList();
        } catch (error) {
            console.error("Error in 'PortalMenuConfiguration.js -> generateData()':" + error);
        }
    }

    getAvailableModuleItemList = () => {
        try {
            let promise = Promise.resolve(HTTPClient.get(endPoints.HOME.GET_AVAILABEL_PORTAL_MODULES, {}));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response) && response.status !== -1) {
                        let userMenus = getArrayParam(response.menus);
                        let modules = _.cloneDeep(getArrayParam(response.menus));
                        let allUserMenuItems = [];
                        modules.forEach(menu => {
                            allUserMenuItems.push(...menu.items)
                        });

                        let availableItems = allMenuItems.filter(ar => !allUserMenuItems.find(rm => (rm.name === ar.name)));
                        let availabelMenu = { name: 'availablemenu', label: MODULE_LIST.AVAILABLE_MENU_ITEMS, items: availableItems }


                        let availabelModules = { id: -111, name: "availablemenu", module: MODULE_LIST.AVAILABLE_MENU_ITEMS, items: availableItems, label: MODULE_LIST.AVAILABLE_MENU_ITEMS }
                        modules.unshift(availabelModules);
                        let allModuleItems = [];
                        modules.forEach(module => {
                            allModuleItems.push(...module.items)
                        })
                        modules = modules.map(module => {
                            let moduleItemIds = module.items.map(m => m.name).filter((f, index, arr) => arr.indexOf(f) === index);
                            //  delete module.module_items;
                            // delete module.objectList;
                            module.tempitems = module.items;
                            module.items = [];

                            return { ...module, moduleItemIds };
                        });
                        this.setState({ modules, allModuleItems });



                    }
                });
            }
        } catch (error) {
            console.error("Error in 'MenuConfiguration.js -> getAvailableModuleItemList()':" + error);
        }
    }

    moveCard = (props, menuId) => {
        var { columnId, columnIndex } = props;
        let modules = this.state.modules;
        try {
            modules = modules.map(module => {
                let ids = module.moduleItemIds.filter(id => id !== menuId);
                if (module.id === columnId) {
                    ids = [...ids.slice(0, columnIndex), menuId, ...ids.slice(columnIndex)];
                }
                return { ...module, moduleItemIds: ids };
            });
            this.setState({ modules });
        } catch (error) {
            console.error("Error in 'PortalMenuConfiguration.js -> moveCard()':" + error);
        }
    }



    handleSave = () => {
        try {
            let modules = getArrayParam(this.state.modules);
            modules = modules.filter(f => f.name !== 'availablemenu');
            let allModuleItems = getArrayParam(this.state.allModuleItems);
            modules.forEach(module => {
                var itemIds = module.moduleItemIds;
                itemIds.forEach(id => {
                    var item = "";
                    if (allModuleItems !== null && allModuleItems.length > 0) {
                        item = allModuleItems.filter(f => f.name === id);
                    } else {
                        item = allMenuItems.filter(f => f.name === id);
                    }

                    if (item !== null && item.length > 0) {
                        module.items.push(item[0]);
                    }
                });
            })
            let params = { isSaveAll: true, modules: modules };
            let promise = Promise.resolve(HTTPClient.post(endPoints.HOME.UPDATE_PORTAL_MODULE, params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response) && response.status !== -1) {
                        this.getAvailableModuleItemList();
                        this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'PortalMenuConfiguration.js -> MenuContainer.js -> saveOrUpdateModule()':" + error);
        }
    }

    updateMenuContents = () => {
        this.getAvailableModuleItemList();

    }

    getDataContainer = () => {
        let element = [];
        let modules = getArrayParam(this.state.modules);
        let props = {};
        try {
            props.moveCard = this.moveCard;
            props.handleSave = this.handleSave;
            element = modules.map((item, index) => {
                return (<MenuContainer key={`${item.name}-module`}  {...props} columnId={item.id} module={item} allModuleItems={this.state.allModuleItems} updateMenuContents={this.updateMenuContents} modules={modules} />);
            });

            element = <div id="module-config-id" className="kanbanBord overflow-x" style={{ width: '100%', height: 'calc(100vh - 210px)', padding: '0px 10px' }}>{element}</div>
        } catch (error) {
            console.error("Error in 'PortalMenuConfiguration.js -> getDataContainer()':" + error);
        }
        return element;
    }

    render() {
        return (
            <div>{this.getDataContainer()}</div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PortalMenuConfiguration);

connect(mapStateToProps, mapDispatchToProps)
//DropTarget('menu', menuTarget, collectDragTarget)
class MenuContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    UNSAFE_componentWillMount() {
        this.generateData(this.props);
    }

    componentDidMount() {
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let isEqual = _.isEqual(this.props.module, nextProps.module);
        if (!isEqual) {
            this.generateData(nextProps);
        }
    }

    generateData = (props) => {
        try {
            this.setState({ module: Object.assign({}, props.module), prevModule: Object.assign({}, props.module), modules: getArrayParam(props.modules) });
        } catch (error) {
            console.error("Error in 'PortalMenuConfiguration.js -> MenuContainer.js -> handleEditAction()':" + error);
        }
    }

    handleEditAction = (isEdit) => {
        try {
            this.setState({ isEdit });
        } catch (error) {
            console.error("Error in 'PortalMenuConfiguration.js -> MenuContainer.js -> handleEditAction()':" + error);
        }
    }

    onChangeModule = (event) => {
        try {
            let value = event.currentTarget.value;
            let module = getObjectParam(this.state.module);
            module.label = value;
            this.setState({ module });
        } catch (error) {
            console.error("Error in 'PortalMenuConfiguration.js -> MenuContainer.js -> onChangeModule()':" + error);
        }
    }

    isValidate = () => {
        let isValidate = true;
        let module = getObjectParam(this.state.module);
        let moduleLabel = getStringParam(module.label).trim();
        try {
            if (moduleLabel === '') {
                this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.THIS_FIELD_CANNOT_BLANK, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                return false;
            } else if (moduleLabel.length > 32) {
                this.props.showCustomSnackBar(getLocalizedStrings().message.MENU.MODULE_LABEl_CHAR_LIMIT_MSG, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                return false;
            } else {
                let modules = getArrayParam(this.state.modules);
                let moduleObj = modules.filter(f => f.name !== module.name && getStringParam(f.label).toLowerCase() === moduleLabel.toLowerCase());
                if (moduleObj.length > 0) {
                    this.props.showCustomSnackBar(moduleLabel + ' ' + getLocalizedStrings().message.MENU.DUPLICATE_NAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                    return false;
                }
            }
        } catch (error) {
            console.error("Error in 'PortalMenuConfiguration.js -> MenuContainer.js -> isValidate()':" + error);
        }
        return isValidate;
    }

    saveModule = () => {
        let params = {};
        let allModules = getArrayParam(this.state.modules);
        let module = getObjectParam(this.state.module);

        try {
            let activeModules = allModules.filter(f => f.name !== 'availablemenu');
            let isEqual = _.isEqual(module, this.state.prevModule);
            if (!isEqual) {
                let isValidate = this.isValidate();
                if (isValidate) {
                    this.handleEditAction(false);
                    params.id = getIntParam(module.id);
                    params.label = getStringParam(module.label).trim();
                    params.name = getStringParam(module.name).trim();
                    params.call_from = 'changeModuleLable';
                    if (module.ownSetup) {
                        params.isSaveAll = false;

                    } else {
                        params.isSaveAll = true;
                        activeModules.forEach(moduleobj => {
                            if (getStringParam(moduleobj.name).trim() === params.name) {
                                moduleobj.label = params.label;
                            }
                            moduleobj.items = moduleobj.tempitems;

                        });
                        params.modules = activeModules;
                    }
                    this.saveOrUpdateModule(params);
                }
            } else {
                this.handleEditAction(false);
            }
        } catch (error) {
            console.error("Error in 'MenuConfiguration.js -> MenuContainer.js -> saveModuleItem()':" + error);
        }
    }

    handleModuleHideAndShow = (value) => {
        let params = {};
        let module = getObjectParam(this.state.module);
        let modules = getArrayParam(this.state.modules);
        try {
            let activeModules = modules.filter(f => f.name !== 'availablemenu');
            if (activeModules.length === 1 && value === 0) {
                this.props.showCustomSnackBar(getLocalizedStrings().message.MENU.ATLEAST_ONE_MODULE_ACTIVE, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                return false;
            }

            params.id = getIntParam(module.id);
            params.is_show = value;
            module.is_show = value;
            params.name = getStringParam(module.name).trim();
            params.call_from = 'showHideModule';
            if (module.ownSetup) {
                params.isSaveAll = false;

            } else {
                params.isSaveAll = true;

                activeModules.forEach(moduleobj => {
                    if (getStringParam(moduleobj.name).trim() === params.name) {
                        moduleobj.is_show = params.is_show;
                    }
                    moduleobj.items = moduleobj.tempitems;

                });
                params.modules = activeModules;
            }
            this.saveOrUpdateModule(params);



        } catch (error) {
            console.error("Error in 'PortalMenuConfiguration.js -> MenuContainer.js -> handleModuleHideAndShow()':" + error);
        }
    }

    saveOrUpdateModule = (params) => {
        try {
            let modules = getArrayParam(params.modules);
            let promise = Promise.resolve(HTTPClient.post(endPoints.HOME.UPDATE_PORTAL_MODULE, params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response) && response.status !== -1) {
                        this.updateMenuContents();
                        this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'PortalMenuConfiguration.js -> MenuContainer.js -> saveOrUpdateModule()':" + error);
        }
    }

    updateMenuContents = () => {
        this.props.updateMenuContents();
    }

    render() {
        const { connectDropTarget } = this.props;
        let props = Object.assign({}, this.props);
        let module = getObjectParam(this.state.module);
        let moduleName = getStringParam(module.name);
        let moduleLabel = getStringParam(module.label);
        let allModuleItems = getArrayParam(this.props.allModuleItems);
        let moduleItemIds = getArrayParam(module.moduleItemIds);
        let isShow = module.is_show === 1 ? true : false;

        let modules = getArrayParam(this.state.modules);
        let totalModule = modules.length;
        let width = (totalModule >= 2 && totalModule < 5) ? `calc(100%/${totalModule + 1} - 25px)` : `calc(100%/4 - 25px)`;

        return (
            <div className="desk sf-desk" style={{ opacity: 1, width }}>
                <div className="desk-head" >
                    <div className="desk-name" style={{ display: 'flex' }}>
                        {getBooleanParam(this.state.isEdit) ?
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    id={`module-name-${moduleName}`}
                                    key={`module-field-${moduleName}-key`}
                                    value={moduleLabel}
                                    title={moduleLabel}
                                    onChange={this.onChangeModule.bind(this)}
                                    style={{ border: '1px solid rgb(153, 153, 153)', backgroundColor: '#fff', width: '100%', height: '30px', padding: '0px' }}
                                    inputStyle={{ color: '#333', fontSize: '15px', fontWeight: 'normal', textAlign: 'center' }}
                                    onBlur={this.saveModule}
                                    autoFocus={true}
                                    autoComplete="new-password"
                                    className={"sf-fields-bg"}
                                />
                            </FormControl>
                            : <span title={moduleLabel} style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{moduleLabel}</span>}
                        {getIntParam(module.id) > 0 && !getBooleanParam(this.state.isEdit) &&
                            <span style={{ display: 'flex', cursor: 'pointer', color: '#717171' }}>
                                <span>
                                    {isShow ? <i key={`module-edit-${moduleName}`} style={{ padding: '5px' }} className="far fa-eye-slash" title={getLocalizedStrings().label.COMMON.HIDE} onClick={this.handleModuleHideAndShow.bind(this, 0)}></i>
                                        : <i key={`module-edit-${moduleName}`} style={{ padding: '5px' }} className="far fa-eye" title={getLocalizedStrings().label.COMMON.SHOW} onClick={this.handleModuleHideAndShow.bind(this, 1)}></i>}
                                </span>
                                <span><Icon key={`module-edit-${moduleName}`} style={{ 'fontSize': '1em', padding: '5px' }} onClick={this.handleEditAction.bind(this, true)} title={getLocalizedStrings().label.COMMON.EDIT}>edit</Icon></span>
                            </span>}
                    </div>
                </div>
                {moduleItemIds.length > 0 ? <div className="overflow-y" style={{ height: '100%' }} key={"desk-items-" + moduleName} id={"desk-items-" + moduleName} >
                    {moduleItemIds.map((itemId, index) => {
                        return allModuleItems.filter(f => f.name === itemId).map(item => {
                            return (<ModuleMenuItem key={`${moduleName}-module-item-${item.name}`} {...props} id={item.id} name={item.name} columnIndex={index} moduleName={moduleName} moduleItem={item} module={module} updateMenuContents={this.updateMenuContents} modules={this.state.modules} />);
                        });
                    })}
                </div> :
                    connectDropTarget(<div style={{ height: '100%', textAlign: 'center' }}>Empty</div>)}
            </div>
        );
    }
}


connect(mapStateToProps, mapDispatchToProps)
//DropTarget('menu', menuTarget, collectDragTarget)
//DragSource('menu', menuSource, collectDragSource)
class ModuleMenuItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    UNSAFE_componentWillMount() {
        this.generateData(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let isEqual = _.isEqual(this.props.moduleItem, nextProps.moduleItem);
        let isModuleEqual = _.isEqual(this.props.module, nextProps.module);
        if (!isEqual || (isEqual && !isModuleEqual)) {
            this.generateData(nextProps);
        }
    }

    componentDidMount() {
    }

    generateData = (props) => {
        try {
            let moduleItem = props.moduleItem;
            this.setState({ modules: getArrayParam(props.modules), module: Object.assign({}, props.module), moduleItem: Object.assign({}, moduleItem), prevModuleItem: Object.assign({}, moduleItem) });
        } catch (error) {
            console.error("Error in 'PortalMenuConfiguration.js -> ModuleMenuItem.js -> handleEditAction()':" + error);
        }
    }

    handleEditAction = (isEdit) => {
        try {
            this.setState({ isEdit });
        } catch (error) {
            console.error("Error in 'PortalMenuConfiguration.js -> ModuleMenuItem.js -> handleEditAction()':" + error);
        }
    }

    onChangeModuleItem = (event) => {
        try {
            let value = event.currentTarget.value;
            let moduleItem = getObjectParam(this.state.moduleItem);
            moduleItem.label = value;
            this.setState({ moduleItem });
        } catch (error) {
            console.error("Error in 'PortalMenuConfiguration.js -> ModuleMenuItem.js -> onChangeModuleItem()':" + error);
        }
    }

    handleRemove = () => {
        let params = {};
        let moduleItem = getObjectParam(this.state.moduleItem);
        let modules = getArrayParam(this.state.modules);

        try {

            let activeModules = modules.filter(f => f.name !== 'availablemenu' && f.is_show === 1);
            let moduleItems = [];
            activeModules.forEach(module => {
                moduleItems = concat(moduleItems, module.tempitems);
            });
            if (moduleItems.length === 1) {
                this.props.showCustomSnackBar(getLocalizedStrings().message.MENU.ATLEAST_ONE_MODULE_ITEM_ACTIVE, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                return false;
            } else {
                params.id = moduleItem.id;
                params.is_remove = true;
                let msg = moduleItem.label + getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_REMOVE_MSG;
                // let promise = new Promise(function (resolve, reject) {
                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, this.saveOrUpdateModuleItem.bind(params), null);
                // });
                // promise.then((isConfirmed) => {
                //     if (isConfirmed) {
                //         params.id = moduleItem.id;
                //         params.is_remove = true;
                //         this.saveOrUpdateModuleItem(params);
                //     }
                // });
            }
        } catch (error) {
            console.error("Error in 'PortalMenuConfiguration.js -> ModuleMenuItem.js -> handleRemove()':" + error);
        }
    }

    isValidate = () => {
        let isValidate = true;
        let moduleItem = getObjectParam(this.state.moduleItem);
        let itemLabel = getStringParam(moduleItem.label).trim();
        let moduleName = this.props.moduleName;
        try {
            if (itemLabel === '') {
                this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.THIS_FIELD_CANNOT_BLANK, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                return false;
            } else if (itemLabel.length > 32) {
                this.props.showCustomSnackBar(getLocalizedStrings().message.MENU.MODULE_LABEl_CHAR_LIMIT_MSG, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                return false;
            } else {
                let modules = getArrayParam(this.state.modules);
                let moduleObj = modules.find(f => f.name === moduleName);
                if (isValidParam(moduleObj)) {
                    let moduleItems = moduleObj.tempitems;
                    moduleItems = moduleItems.filter(f => f.name !== moduleItem.name && getStringParam(f.label).toLowerCase() === itemLabel.toLowerCase());
                    if (moduleItems.length > 0) {
                        this.props.showCustomSnackBar(itemLabel + ' item name already exists in ' + moduleObj.label + ' module.', styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                        return false;
                    }
                }
            }
        } catch (error) {
            console.error("Error in 'PortalMenuConfiguration.js -> ModuleMenuItem.js -> isValidate()':" + error);
        }
        return isValidate;
    }

    saveModuleItem = () => {
        let params = {};
        let allModules = getArrayParam(this.state.modules);
        let module = getObjectParam(this.state.module);
        let moduleItem = getObjectParam(this.state.moduleItem);

        try {
            let activeModules = allModules.filter(f => f.name !== 'availablemenu');
            let isEqual = _.isEqual(moduleItem, this.state.prevModuleItem);
            if (!isEqual) {
                let isValidate = this.isValidate();
                if (isValidate) {
                    this.handleEditAction(false);
                    params.id = getIntParam(moduleItem.id);
                    params.label = getStringParam(moduleItem.label).trim();
                    params.name = getStringParam(moduleItem.name).trim();
                    params.call_from = 'changeModuleItemLable';
                    if (module.ownSetup) {
                        params.isSaveAll = false;

                    } else {
                        params.isSaveAll = true;
                        activeModules.forEach(moduleobj => {
                            if (getStringParam(moduleobj.name).trim() === module.name) {
                                var item = moduleobj.tempitems.filter(t => t.name === params.name);
                                item[0].label = params.label;
                            }
                            moduleobj.items = moduleobj.tempitems;

                        });

                    }

                    params.modules = activeModules;
                    this.saveOrUpdateModuleItem(params);
                }
            } else {
                this.handleEditAction(false);
            }
        } catch (error) {
            console.error("Error in 'PortalMenuConfiguration.js -> MenuContainer.js -> saveModuleItem()':" + error);
        }
    }


    saveOrUpdateModuleItem = (params) => {
        try {
            let modules = getArrayParam(params.modules);
            let promise = Promise.resolve(HTTPClient.post(endPoints.HOME.UPDATE_PORTAL_MODULE, params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response) && response.status !== -1) {
                        let message = getLocalizedStrings().message.COMMON.UPDATE;
                        if (getBooleanParam(params.is_remove)) {
                            message = getLocalizedStrings().message.COMMON.REMOVE;

                        }
                        this.props.updateMenuContents();

                        this.props.showCustomSnackBar(message, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'PortalMenuConfiguration.js -> MenuContainer.js -> saveOrUpdateModule()':" + error);
        }
    }


    render() {
        const { connectDropTarget, connectDragSource, isDragging } = this.props;
        let moduleItem = getObjectParam(this.state.moduleItem);
        let module = getObjectParam(this.state.module);
        let opacity = isDragging ? 0 : 1;
        let showActionButtons = false;
        if (!getBooleanParam(this.state.isEdit) && getIntParam(moduleItem.id) > 0 && this.props.moduleName !== MODULE_LIST.AVAILABLE_MENU_ITEMS) {
            showActionButtons = true;
        }


        let isShowDeleteIcon = false;

        let isGrab = true;
        let isShowEditIcon = false;
        if (getBooleanParam(module.canDelete)) {
            isShowEditIcon = true;
            isShowDeleteIcon = true;
        }

        return connectDragSource(connectDropTarget(
            <div className="item" id={`module-item-${moduleItem.id}`} style={{ height: '30px', marginTop: '0px', backgroundColor: isDragging ? '#efefef' : '', cursor: isGrab ? 'grab' : '' }}>
                <div className="item-container" style={{ opacity, height: '100%' }}>
                    <div className="item-content" style={{ padding: getBooleanParam(this.state.isEdit) ? '0px 10px' : '5px 10px' }}>
                        <div style={{ display: 'flex' }}>
                            {getBooleanParam(this.state.isEdit) ?
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        id={`module-item-name-${moduleItem.id}`}
                                        key={`module-item-field-${moduleItem.id}-key`}
                                        value={moduleItem.label}
                                        title={moduleItem.label}
                                        onChange={this.onChangeModuleItem.bind(this)}
                                        style={{ border: '1px solid rgb(153, 153, 153)', backgroundColor: '#fff', width: '100%', height: '30px', padding: '0px 7px' }}
                                        inputStyle={{ color: '#333', fontSize: '15px', fontWeight: 'normal' }}
                                        onBlur={this.saveModuleItem}
                                        autoFocus={true}
                                        autoComplete="new-password"
                                        className={"sf-fields-bg"}
                                    /></FormControl> :
                                <div title={moduleItem.label} style={{ width: '100%', float: 'left', color: '#717171', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{moduleItem.label}</div>}
                            {showActionButtons && <div>


                                {isShowEditIcon && <span style={{ padding: '5px' }}>
                                    <Icon key={`module-item-edit-${moduleItem.id}`} style={{ 'fontSize': '1em', cursor: 'pointer', color: '#717171' }} title={getLocalizedStrings().label.COMMON.EDIT} onClick={this.handleEditAction.bind(this, true)}>edit</Icon>
                                </span>}

                                {isShowDeleteIcon && <span style={{ padding: '5px' }} >
                                    <Icon key={`module-item-delete-${moduleItem.id}`} style={{ 'fontSize': '1.2em', fontWeight: 'normal', color: '#717171', cursor: 'pointer' }} title={getLocalizedStrings().label.COMMON.DELETE} onClick={this.handleRemove.bind(this, moduleItem.id)}>close</Icon>
                                </span>}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        ));
    }
}

