import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getAppDialog, setAppDialogActions } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { timeZone } from '../../../services/helper/utils';
import moment from 'moment';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import 'flatpickr/dist/flatpickr.min.css';
import Flatpickr from 'react-flatpickr';
import { constants } from '../../../services/constants/constants';

const SFCalendarDialog = () => {
	const dispatch = useDispatch();
	const [btnClicked, setbtnClicked] = useState(false);
	const appState = useSelector((state) => state.app);
	const appContainer = useSelector((state) => state.appContainer);
	const [selected_date, setSelected_date] = useState(moment().tz(timeZone[appState.me.timezone]).add(2, 'days'));
	const [date, setDate] = useState('');
	const [time, setTime] = useState('');


	function handleClose() {
		dispatch(getAppDialog(false, null, null, null, null, null));
	}

	function handleMoveTask() {
		let data = appContainer.dialogProps.data;
		let _time = data.params.time;
		data.params.datetime = selected_date;
		data.params.date = date == '' ? selected_date.format('YYYY-MM-DD'): date;
		data.params.time = time != ''? time :_time;
		if(data.object == "opportunities" && data.value== "forecastReport"){
			data.params.dateValue = date;
		}
		appContainer.dialogProps.eventName(data);
	}

	function handleChange(dateObj) {
		const dateMoment = moment(dateObj[0]);
		let dateString = dateMoment.format('YYYY-MM-DD');
		if(data.value !== undefined && data.value === 'forecastReport'){
			dateString=dateMoment.format('YYYY-MM-DD')
		}
		else if (data !== null && data.params.hasOwnProperty('object') && data.params.object === constants.OPPORTUNITIES_OBJECT) {
			dateString = null;
		}
		setDate(dateString);
		setSelected_date(dateMoment);
	}

	useEffect(() => {
		dispatch(setAppDialogActions(getActions()));
		let data = appContainer.dialogProps.data;
		let time = data.params.time;
		let dateString = selected_date.format('YYYY-MM-DD');
		if (data !== null && data.params.hasOwnProperty('object') && data.params.object === constants.OPPORTUNITIES_OBJECT) {
			dateString = null;
		}
		setDate(dateString);
		setTime(time);
	}, [selected_date]);

	function getActions() {
		return [
			<Button
				key={'handleMoveTask'}
				color={'primary'}
				onClick={handleMoveTask}
				style={styles.primaryButton}
			> MOVE </Button>,
			<Button
				key={'handleClose'}
				color={'primary'}
				onClick={handleClose}
				style={styles.secondaryButton}
			>{getLocalizedStrings().label.COMMON.CANCEL}</Button>,

		];
	}
	let futureInitialDate = moment().add(2, 'days');
	let initialTime = futureInitialDate.tz(timeZone[appState.me.timezone]).format('YYYY-MM-DD');
	let datePickerOptions = {
		minDate: initialTime,
		inline: true,
		allowInput: false
	};

	let data = appContainer.dialogProps.data;
	if (data !== null && data.params.hasOwnProperty('object') && data.params.object === constants.OPPORTUNITIES_OBJECT) {
		let dateString = data.params.next_stage;
		let arr = dateString.split(' ');
		let newDateString = '1 ' + arr[0] + ' ' + arr[1];
		let momentDate = moment(newDateString);
		let startDate = momentDate.startOf('month').format('YYYY-MM-DD');
		let endDate = momentDate.endOf('month').format('YYYY-MM-DD');
		datePickerOptions = {
			minDate: startDate,
			maxDate: endDate,
			inline: true,
			allowInput: false
		};

	}

	return (
		<div style={{ margin: 'auto', width: '60%', textAlign: 'center' }}>
			<div className="row">
				<div className="col-sm-12 sfcalendar-dialog" style={{ margin: 'auto' }}>
					<Flatpickr style={{ border: '1px solid rgb(221, 221, 221)', width: '100%' }}
						onChange={(dateObj) => handleChange(dateObj)}
						value={selected_date.toDate()}
						options={datePickerOptions}
						className='sf-calendar-picker'
					/>
				</div>
			</div>
		</div>
	);
}

export default SFCalendarDialog;