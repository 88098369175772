import React from 'react';
import { connect } from 'react-redux';
import FroalaEditor from "react-froala-wysiwyg";
import Froalaeditor from 'froala-editor';
import $ from "jquery";
import Tooltip from '@mui/material/Tooltip';
import * as HTTPClient from '../../../services/helper/httpClient';
import { TextField, Button, FormControlLabel, Checkbox, Select, MenuItem, InputLabel, Icon, FormControl } from '@mui/material';
import { getCouponsAction } from '../../../services/actions/couponActions';
import { styles } from '../../../services/constants/styles';
import { isValidParam, isInvalidParam, isInteger, getIntParam, getObjectParam, getStringParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { constants } from '../../../services/constants/constants';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { endPoints } from '../../../services/constants/endPoints';
import { addNode } from '../../../services/actions/automationCampaignActions';
import { getNodeByChildId, getNodeByParentId, getRequiredParentNode } from '../../../services/helper/automationUtils';
import { removeError } from '../../../services/helper/automationUtils';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { sortArrayObjectByProperty, isEmailExistsInList } from '../../../services/helper/utils';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import ShowCircularProgress from '../components/circularProgress';
import { getCoupons } from '../../../services/helper/common';
import * as sfDialogs from '../components/sfDialogs';
import InfoIcon from '@mui/icons-material/Info';

require("froala-editor/js/froala_editor.pkgd.min.js");
require("froala-editor/css/froala_editor.pkgd.min.css");
require("froala-editor/js/plugins/font_family.min.js");
require("froala-editor/css/froala_style.css");

function mapStateToProps(state) {
    return {
        automationCampaign: state.automationCampaign,
        app: state.app,
        margefields: state.sfForm.data.contacts.fields,
        coupons: state.coupons
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAppDialog: (isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom) => {
            dispatch(getAppDialog(isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom));
        },
        addNode: (arrayNodes) => {
            dispatch(addNode(arrayNodes));
        },
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
        getCouponsAction: (data) => {
            dispatch(getCouponsAction(data));
        }
    }
}

class AutomationAddMessage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            subject: "",
            emailMessage: "",
            templateId: 0,
            isCheckViewAsWeb: false,
            interval: "",
            intervalMode: 'days',
            personalizeFields: null,
            isOpenPersonalize: false,
            anchorPersonilizeEl: null,
            messageSequence: 0,
            isShowTestButton: false,
            cursorPosition: 0,
            isFullScreenEditor: false,
            coupons: {},
            editorLoader: true,
            sender_name: '',
            sender_email: '',
            verifiedEmailIds: null,
            verifiedEmailOptions: null,
            isDisableSenderEmail: false,
            isDisableRefresh: false
        }
    }

    populateAddMessageData = (data) => {
        let detailsArray = null;
        let dbp = this.state.interval;
        let interval_mode = this.state.intervalMode;
        let sequence = this.state.messageSequence;
        let view_as_web = null;
        let isCheckViewAsWeb = this.state.isCheckViewAsWeb;
        let subject = this.state.subject;
        let emailMessage = this.state.emailMessage;
        let templateId = this.state.templateId;
        let templateObject = null;
        let isShowTestButton = this.state.isShowTestButton;
        if (isValidParam(data)) {
            if (data.hasOwnProperty('details')) {
                detailsArray = data.details[0];
                if (isValidParam(detailsArray)) {
                    if (detailsArray.hasOwnProperty('dbp')) {
                        dbp = detailsArray.dbp;
                        let immediateprevId = 0;
                        let immediateprevnodeDetails = null;
                        let actionName = null;
                        if (isValidParam(data.parentId) && data.parentId > 0) {
                            immediateprevId = data.parentId;
                            immediateprevnodeDetails = getNodeByChildId(this.props.automationCampaign.data.nodes,
                                immediateprevId);
                            if (isValidParam(immediateprevnodeDetails)) {
                                actionName = immediateprevnodeDetails.hasOwnProperty('actionName') ? immediateprevnodeDetails.actionName : "";
                                if (actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE && dbp === 0) {
                                    dbp = "";
                                }
                            }
                        }
                    }
                    if (detailsArray.hasOwnProperty('interval_mode')) {
                        interval_mode = detailsArray.interval_mode;
                    }
                    if (detailsArray.hasOwnProperty('sequence')) {
                        sequence = detailsArray.sequence;
                    }
                    if (detailsArray.hasOwnProperty('view_as_web')) {
                        view_as_web = detailsArray.view_as_web;
                        if (view_as_web === "Yes") {
                            isCheckViewAsWeb = true;
                        } else if (view_as_web === "No") {
                            isCheckViewAsWeb = false;
                        }
                    }
                    if (detailsArray.hasOwnProperty('message_id')) {
                        let message_id = detailsArray.message_id;
                        if (message_id > 0) {
                            isShowTestButton = true;
                        }
                    }
                    if (detailsArray.hasOwnProperty('template')) {
                        templateObject = detailsArray.template;
                        if (templateObject.hasOwnProperty('emailBody')) {
                            emailMessage = templateObject.emailBody;
                        }
                        if (templateObject.hasOwnProperty('subject')) {
                            subject = templateObject.subject;
                        }
                        if (templateObject.hasOwnProperty('template_id')) {
                            templateId = templateObject.template_id;
                        }
                    }
                }



                this.setState({
                    interval: dbp,
                    intervalMode: interval_mode,
                    isCheckViewAsWeb: isCheckViewAsWeb,
                    emailMessage: emailMessage,
                    subject: subject,
                    messageSequence: sequence,
                    templateId: templateId,
                    isShowTestButton: isShowTestButton,
                    cursorPosition: subject.length,
                    editorLoader: (Object.keys(this.state.coupons).length === 0 && (!this.props.coupons.couponMounted)) ? true:false
                });
            }
        }
    }

    setTemplateData = (object) => {
        let params = {
            template_id: object.template_id
        }
        if (params.template_id > 0) {
            var promise = Promise.resolve(HTTPClient.get(endPoints.MARKETING_TEMPLATE.TEMPLATE_GET, params));
            promise.then((response) => {
                this.props.getAppDialog(false, constants.TEMPLATE_DIALOG, null, null, null, null);
                let mailBody = response.mail_body;
                let subject = response.subject;
                this.setState({
                    emailMessage: mailBody,
                    subject: subject,
                    templateId: params.template_id,
                });
            });
        } else {
            let emailBody = null;
            let subject = null;
            // let templateId = 0;
            if (object.hasOwnProperty('emailBody')) {
                emailBody = object.emailBody;
            }
            if (object.hasOwnProperty('subject')) {
                subject = object.subject;
            }
            // if (object.hasOwnProperty('template_id')) {
            //     templateId = object.template_id;
            // }
            this.setState({
                emailMessage: emailBody,
                subject: subject,
                // templateId: templateId,
            })
        }
    }

    populateAutoMailData = (data) => {
        let detailsArray = null;
        let dbp = this.state.interval;
        let interval_mode = this.state.intervalMode;
        let view_as_web = null;
        let isCheckViewAsWeb = this.state.isCheckViewAsWeb;
        let subject = this.state.subject;
        let emailMessage = this.state.emailMessage;
        let templateObject = null;
        let templateId = this.state.templateId;
        if (isValidParam(data)) {
            detailsArray = data.details[0];
            if (isValidParam(detailsArray)) {
                if (detailsArray.hasOwnProperty('interval')) {
                    dbp = detailsArray.interval;
                }
                if (detailsArray.hasOwnProperty('interval_mode')) {
                    interval_mode = detailsArray.interval_mode;
                }
                if (detailsArray.hasOwnProperty('view_as_web')) {
                    view_as_web = detailsArray.view_as_web;
                    if (view_as_web === "Yes") {
                        isCheckViewAsWeb = true;
                    } else if (view_as_web === "No") {
                        isCheckViewAsWeb = false;
                    }
                }
                if (detailsArray.hasOwnProperty('template')) {
                    templateObject = detailsArray.template;
                    if (templateObject.hasOwnProperty('emailBody')) {
                        emailMessage = templateObject.emailBody;
                    }
                    if (templateObject.hasOwnProperty('subject')) {
                        subject = templateObject.subject;
                    }
                    if (templateObject.hasOwnProperty('template_id')) {
                        templateId = templateObject.template_id;
                    }
                }

                let actionName = "";
                if (data.hasOwnProperty('actionName')) {
                    actionName = data.actionName;
                }
                let _state = this.state;
                if (actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                    let arrayNodes = this.props.automationCampaign.data.nodes;

                    let autoNode = arrayNodes.filter(f => (f.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL));
                    let touchNode = arrayNodes.filter(f => (f.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL));


                    if (touchNode.length > 0 && touchNode[0].hasOwnProperty('details') && getArrayParam(touchNode[0].details).length > 0) {
                        let touchNodeDetails = touchNode[0].details[0];

                        if (isValidParam(touchNodeDetails.from_name) && touchNodeDetails.from_name !== '') {
                            _state.sender_name = touchNodeDetails.from_name;
                        }
                        if (isValidParam(touchNodeDetails.sender_email) && touchNodeDetails.sender_email !== '') {
                            _state.sender_email = touchNodeDetails.sender_email;
                        }
                    }

                    if (isValidParam(detailsArray.sender_name) && detailsArray.sender_name !== '') {
                        _state.sender_name = detailsArray.sender_name;
                    }

                    if (isValidParam(detailsArray.sender_email) && detailsArray.sender_email !== '') {
                        _state.sender_email = detailsArray.sender_email;
                    }

                    if (_state.sender_name === constants.CONSTANT_RECORD_OWNER) {
                        _state.sender_email = '';
                    }

                    if (_state.sender_name === constants.CONSTANT_RECORD_OWNER && _state.sender_email === '') {
                        _state.isDisableSenderEmail = true;
                        _state.isDisableRefresh = true;
                    }

                }

                this.setState({
                    interval: dbp,
                    intervalMode: interval_mode,
                    isCheckViewAsWeb: isCheckViewAsWeb,
                    emailMessage: emailMessage,
                    subject: subject,
                    templateId: templateId,
                    cursorPosition: subject.length,
                });
            } else {
                this.props.getAppDialog(true, constants.TEMPLATE_DIALOG, constants.TEMPLATE_DIALOG_TITLE, this.setTemplateData, null, constants.AUTOMATION_DESIGNER_OBJECT);
            }
        }
    }

    componentDidMount() {
        this.loadCoupons();
        let userDetail = this.props.app.me;
        this.state.sender_name = userDetail.name;
        this.state.sender_email = userDetail.email;
        let data = this.props.data;
        let actionName = null;
        if (isValidParam(data)) {
            if (data.hasOwnProperty('actionName')) {
                actionName = data.actionName;
                if (actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE || actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
                    this.populateAddMessageData(data);
                } else if (actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                    this.populateAutoMailData(data);
                    this.generateVerifiedEmailIds();
                }
            }
        } else {
            let nodesLength = this.props.automationCampaign.data.nodes.length;
            let arrayNodes = this.props.automationCampaign.data.nodes;
            let nodeIndex = nodesLength - 1;
            let nodeObject = arrayNodes[nodeIndex];
            if (isValidParam(nodeObject)) {
                if (nodeObject.hasOwnProperty('actionName')) {
                    actionName = nodeObject.actionName;
                    if (actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE || actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
                        this.populateAddMessageData(nodeObject);
                    } else if (actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                        this.populateAutoMailData(nodeObject);
                        this.generateVerifiedEmailIds();
                    }
                }
            }
        }
    }

    generateVerifiedEmailIds = (isReloaded, verifiedEmailIdList) => {
        let verifid_email_options = [];
        let emailIds = [];
        let verifyEmailAddress = "Verify another email address";
        verifyEmailAddress = getLocalizedStrings().label.AUTOMATION_DESIGNER.VERIFY_EMAIL_ADDRESS_OPTION;
        try {
            if (isValidParam(isReloaded) && isReloaded) {
                verifiedEmailIdList = getArrayParam(verifiedEmailIdList);
                verifiedEmailIdList.forEach((value) => {
                    if (!isEmailExistsInList(verifid_email_options, value)) {
                        verifid_email_options.push(
                            {
                                value: value,
                                label: value
                            }
                        );
                    }
                });
                if (!isEmailExistsInList(verifid_email_options, this.props.app.me.email)) {
                    verifid_email_options.unshift(
                        {
                            value: this.props.app.me.email,
                            label: this.props.app.me.email
                        }
                    );
                }
                verifid_email_options.push(
                    {
                        value: '-1',
                        label: '---' + verifyEmailAddress + '---'
                    }
                );
                emailIds = verifid_email_options.map((emailId, i) => {
                    return (
                        <MenuItem value={emailId.value} title={emailId.label} >{emailId.label}</MenuItem>
                    );
                });
                this.state.sender_email = verifid_email_options[0].value;
                this.setState({

                    verifiedEmailIds: emailIds,
                    verifiedEmailOptions: verifid_email_options
                });
            }
            else {
                var response = Promise.resolve(HTTPClient.get(endPoints.AUTOMATION_DESIGNER.GET_VERIFIED_EMAIL_IDS, null));
                response.then((response) => {
                    let loggedInUserEmail = this.props.app.me.email;
                    if (isValidParam(response)) {
                        emailIds = response.verifid_email_ids;
                        /** verify email added by Priyanka */
                        if (emailIds !== null && Array.isArray(emailIds)) {
                            emailIds.forEach((value) => {
                                if (!isEmailExistsInList(verifid_email_options, value)) {
                                    verifid_email_options.push(
                                        {
                                            value: value,
                                            label: value
                                        }
                                    );
                                }
                            });
                            if (!isEmailExistsInList(verifid_email_options, this.props.app.me.email)) {
                                verifid_email_options.unshift(
                                    {
                                        value: this.props.app.me.email,
                                        label: this.props.app.me.email
                                    }
                                );
                            }
                            verifid_email_options.push(
                                {
                                    value: '-1',
                                    label: '---' + verifyEmailAddress + '---'
                                }
                            );
                            emailIds = verifid_email_options.map((emailId, i) => {
                                return (
                                    <MenuItem value={emailId.value} title={emailId.label} >{emailId.label}</MenuItem>
                                );
                            });
                        }
                        if (!isEmailExistsInList(verifid_email_options, this.state.sender_email)) {
                            if (this.state.sender_name !== constants.CONSTANT_RECORD_OWNER) {
                                this.state.sender_email = verifid_email_options[0].value;
                                this.setState({

                                })
                            }
                        }
                        this.setState({
                            verifiedEmailIds: emailIds,
                            verifiedEmailOptions: verifid_email_options
                        });
                    }

                });
            }
        }
        catch (error) {
            console.error("Error in 'automationAddMessage.js -> generateVerifiedEmailIds():'" + error);
        }
    }

    changeSenderEmail = (event) => {
        let value = event.target.value;
        try {
            if (isValidParam(value)) {
                if (value === '-1') {
                    this.openVerifyEmailPopup();

                }
                this.state.sender_email = value;
                this.setState({});
            }
        }
        catch (error) {
            console.error("Error in 'automationAddMessage.js -> changeSenderEmail():'" + error);
        }
    }

    handlePreview = () => {

        window.open('/#previewemailtemplate/' + this.state.templateId);

    }

    openVerifyEmailPopup = () => {
        let verifiedEmailOptions = getArrayParam(this.state.verifiedEmailOptions);
        try {
            let data = { fromName: this.props.app.me.name, verifiedEmailOptions: verifiedEmailOptions };
            this.props.getAppDialog(true, constants.VERIFY_EMAIL_DIALOG, getLocalizedStrings().label.BROADCAST_CAMPAIGNS.VERIFY_EMAIL, null, data, constants.AUTOMATION_DESIGNER_OBJECT);
        }
        catch (error) {
            console.error("Error in 'automationAddMessage.js -> openVerifyEmailPopup():'" + error);
        }
    }

    loadVerifiedEmailIds = () => {
        let verifid_email_ids = [];
        let isReloaded = false;
        try {
            let url = endPoints.BROADCAST_CAMPAIGNS.GET_VERIFIED_EMAIL_IDS;
            let params = {};
            params.getUserMailIds = false;
            let promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    isReloaded = true;
                    this.generateVerifiedEmailIds(isReloaded, response.verified_email_ids);
                }
            });
        }
        catch (error) {
            console.error("Error in 'automationAddMessage.js -> loadVerifiedEmailIds():'" + error);
        }
    }

    loadCoupons = () => {
        try {
            let coupons = {};
            if (!this.props.coupons?.couponMounted) {
                let promise = getCoupons()
                promise.then((response) => {
                    if (isValidParam(response.records)) {
                        this.props.getCouponsAction(response.records)
                        let records = getArrayParam(response.records);
                        records.forEach(record => {
                            let discount = record.discount_type.toLowerCase() === 'percentage' ? record.discount + '%' : '$' + record.discount;
                            coupons[' ' + record.name] = `${record.name} (${discount})`;
                        });
                        let _state = this.state
                        _state.coupons = coupons;
                        if(_state.emailMessage !== ""){
                            _state.editorLoader = false ;
                        }
                        this.setState(_state);
                    }
                })
            } else {
                if (isValidParam(this.props.coupons.coupons)) {
                    let records = getArrayParam(this.props.coupons.coupons);
                    records.forEach(record => {
                        let discount = record.discount_type.toLowerCase() === 'percentage' ? record.discount + '%' : '$' + record.discount;
                        coupons[' ' + record.name] = `${record.name} (${discount})`;
                    });
                }
                let _state = this.state
                _state.coupons = coupons;
                if(_state.emailMessage !== ""){
                    _state.editorLoader = false ;
                }
                this.setState(_state);
            }
           
        } catch (error) {
            console.error("Error in 'automationAddMessage.js -> loadCoupons()':" + error);
        }
    }

    onCheckViewAsWeb = (event, isChecked) => {
        this.setState({
            isCheckViewAsWeb: isChecked
        })
    }

    changeIntervalMode = (event, index, value) => {
        if (isValidParam(value)) {
            this.setState({
                intervalMode: value
            })
        }
    }

    changeInterval = (event, value) => {
        if (isValidParam(value)) {
            value = value.trim();
            if (!isInteger(value)) {
                value = "";
            }
            this.setState({
                interval: value
            })
        }
    }

    selectPersonalize = (event) => {
        event.preventDefault();
        this.setState({
            isOpenPersonalize: true,
            anchorPersonilizeEl: event.currentTarget
        })
    }

    isClosePersonalize = () => {
        this.setState({
            isOpenPersonalize: false
        });
    }

    renderPersonalizeField = () => { 
        let fields = this.props.margefields;
        let templateName = this.props.automationCampaign.data.templateName;
        fields = fields.filter(f => (f.name !== 'header' && f.name !== ''));
        let mergeFields = {};
        let petMergeFields = {};
        sortArrayObjectByProperty(fields, 'label');
        for (let i = 0; i < fields.length; i++) {
            let fieldsName = "j_contacts." + fields[i].name;
            mergeFields[fieldsName] = fields[i].label;
        }
        if (templateName === constants.PET_BIRTHDAY_CAMPAIGN_NAME) {
            let petFieldName = "j_pets.name";
            petMergeFields[petFieldName] = "Pet Name";
            mergeFields = { ...petMergeFields, ...mergeFields }
        }
        this.state.personalizeFields = mergeFields;
        let personalizeObject = this.state["personalizeFields"];
        return Object.keys(personalizeObject).map((itemKey, index) => {
            return <MenuItem key={index} value={itemKey} >{personalizeObject[itemKey]}</MenuItem>;
        });
    }

    handleSelectPersonilizeChange = (fieldName, event, value) => {
        let personalizeObject = this.state["personalizeFields"];
        // value = Object.keys(personalizeObject)[value];
        const _state = this.state;
        _state[fieldName] = value;
        _state['personilizeOpen'] = false;
        let front = (_state['subject']).substring(0, this.state.cursorPosition);
        let back = (_state['subject']).substring(this.state.cursorPosition, _state['subject'].length);
        _state['subject'] = front + ' \${' + value + '}' + back;
        _state['cursorPosition'] = _state['subject'].length;
        // _state['subject'] = _state['subject'] + ' \${' + value + '}';
        this.setState(_state);
    }

    changeTemplate = () => {
        let nodeObject = this.props.data;
        this.props.getAppDialog(true, constants.TEMPLATE_DIALOG, getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_EMAIL_TEMPLATE, this.setTemplateData, nodeObject, constants.AUTOMATION_DESIGNER_OBJECT);
    }

    changeSubject = (event, value) => {
        if (isValidParam(value)) {
            this.setState({
                subject: value,
                cursorPosition: event.target.selectionStart,
            })
        }
    }

    changeFromName = (event, value) => {
        let sendername = document.getElementById('sender_name').value;
        this.state.sender_name = sendername;
        this.setState({});
        if (sendername === constants.CONSTANT_RECORD_OWNER ||
            sendername.trim().replace(/ /g, '').toLowerCase() === constants.CONSTANT_RECORD_OWNER.replace(/ /g, '').toLowerCase()) {
            this.state.sender_name = constants.CONSTANT_RECORD_OWNER;
            this.state.sender_email = '';

            this.setState({
                isDisableSenderEmail: true,
                isDisableRefresh: true
            });
        } else {
            this.setState({
                isDisableSenderEmail: false,
                isDisableRefresh: false,
            });
            // this.loadVerifiedEmailIds();
            if (this.state.sender_email === '') {
                this.state.sender_email = this.props.app.me.email;
                let arrayNodes = this.props.automationCampaign.data.nodes;
                let touchNode = arrayNodes.filter(f => (f.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL));
                if (touchNode[0].hasOwnProperty('details')) {
                    let touchNodeDetails = touchNode[0].details[0];

                    if (isValidParam(touchNodeDetails.sender_email) && touchNodeDetails.sender_email !== '') {
                        this.state.sender_email = touchNodeDetails.sender_email;
                    }
                }

                let autoNode = getObjectParam(this.props.data);
                if (autoNode.hasOwnProperty('details')) {
                    let autoNodeDetails = autoNode.details[0];
                    if (isValidParam(autoNodeDetails.sender_email) && autoNodeDetails.sender_email !== '') {
                        this.state.sender_email = autoNodeDetails.sender_email;
                    }
                }

                this.setState({});
            }

        }
    }

    testMail = () => {
        let frommail = this.props.app.me.email;
        let fromname = this.props.app.me.name;
        let allNodes = this.props.automationCampaign.data.nodes;
        let touchNode = allNodes.filter(node => {
            return node.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL && node.hasOwnProperty('details') && node.details[0].hasOwnProperty('sender_email') && isValidParam(node.details[0].sender_email);
        });

        if (isValidParam(touchNode[0])) {
            frommail = touchNode[0].details[0].sender_email;
            fromname = touchNode[0].details[0].from_name;
        }
        let params = {};
        params.email = '';
        params.frommail = frommail;
        params.fromname = fromname;
        params.subject = this.state.subject;
        params.templateBody = this.state.emailMessage;
        if (this.state.isCheckViewAsWeb === true) {
            params.isVawp = '1';
        } else {
            params.isVawp = '0';
        }

        this.props.getAppDialog(true, constants.TEST_MAIL_DIALOG, null, null, params, null);
    }

    saveMessage = () => {
        let nodeObject = this.props.data;
        let detailsArray = null;
        let arrayNodes = this.props.automationCampaign.data.nodes;

        if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
            let dbp = this.state.interval;
            // dbp = dbp.toString();
            // dbp = dbp.trim();
            // dbp = getIntParam(dbp);
            let interval_mode = this.state.intervalMode;
            let isCheckViewAsWeb = this.state.isCheckViewAsWeb;
            let emailBody = this.state.emailMessage;
            let subject = this.state.subject;
            subject = subject.trim();
            let template_id = this.state.templateId;

            let dialogData = null;
            let actionName = "";
            let view_as_web = 'No';
            if (isCheckViewAsWeb) {
                view_as_web = 'Yes';
            }

            if (isValidParam(nodeObject) && nodeObject.hasOwnProperty('actionName')) {
                let immediateprevId = 0;
                let immediateprevnodeDetails = null;
                if (isValidParam(nodeObject.parentId) && nodeObject.parentId > 0) {
                    immediateprevId = nodeObject.parentId;
                    immediateprevnodeDetails = getNodeByChildId(arrayNodes, immediateprevId);
                    if (isValidParam(immediateprevnodeDetails)) {
                        actionName = immediateprevnodeDetails.hasOwnProperty('actionName') ? immediateprevnodeDetails.actionName : "";
                    }
                }
            }

            let childInfo = getNodeByParentId(arrayNodes, nodeObject.childId);
            let websiteVisitUrl = null;
            if (childInfo[0] !== null) {
                if (childInfo[0].hasOwnProperty("actionName")) {
                    if (childInfo[0].actionName !== null && childInfo[0].actionName !== "" &&
                        childInfo[0].actionName === constants.AUTOMATION_DESIGNER_WEBSITE_VISIT) {
                        if (childInfo[0].hasOwnProperty("details")) {
                            if (childInfo[0].details !== null && childInfo[0].details !== "undefined" &&
                                childInfo[0].details.length > 0) {
                                if (childInfo[0].details[0].hasOwnProperty("websiteUrl")) {
                                    websiteVisitUrl = childInfo[0].details[0].websiteUrl;
                                }
                            }
                        }
                    }
                }
            }

            if (websiteVisitUrl !== null && websiteVisitUrl !== "" && websiteVisitUrl !== "undefined" &&
                emailBody.indexOf(websiteVisitUrl) === -1) {
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.URL_NOT_EXIST,
                }
                this.openDialog(dialogData);
            } else if (isInvalidParam(subject)) {
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.ENTER_SUBJECT,
                    fieldId: 'subject'
                }
                this.openDialog(dialogData);
            } else if (dbp.toString() === "" || dbp === null || dbp === undefined) {
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.ENTER_INTERVAL_VALUE,
                    fieldId: 'dap'
                }
                this.openDialog(dialogData);
            } else if (isInvalidParam(emailBody)) {
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.ENTER_BODY,
                }
                this.openDialog(dialogData);
            } else if (dbp === 0 && actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE && interval_mode !== "") {
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.INTERVAL_MODE_ADD_MSG_1 + ' ' + interval_mode + ' ' + getLocalizedStrings().message.AUTOMATION_DESIGNER.INTERVAL_MODE_ADD_MSG_2,
                    fieldId: 'dap'
                }
                this.openDialog(dialogData);
            } else if (dbp < 1 && actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE && interval_mode === 'hours') {
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_ONE_HOUR,
                    fieldId: 'dap'
                }
                this.openDialog(dialogData);
            } else {
                if (isValidParam(nodeObject)) {
                    if (nodeObject.hasOwnProperty('is_new')) {
                        delete nodeObject['is_new'];
                    }
                    if (nodeObject.hasOwnProperty('details')) {
                        detailsArray = nodeObject.details[0];
                        if (isValidParam(detailsArray)) {
                            detailsArray.dbp = dbp;
                            detailsArray.interval_mode = interval_mode;
                            detailsArray.view_as_web = view_as_web;
                            detailsArray.template = {
                                emailBody: emailBody,
                                subject: subject,
                                template_id: template_id,
                            }
                            nodeObject = removeError(nodeObject);
                            let campaignId = 0;
                            campaignId = this.props.automationCampaign.data.id;
                            if (detailsArray.hasOwnProperty('message_id') && detailsArray.message_id > 0 && campaignId > 0) {
                                let sourceObject = getObjectParam(getRequiredParentNode(nodeObject.childId, constants.AUTOMATION_DESIGNER_SEND_MAIL));
                                if (isValidParam(sourceObject) && sourceObject.hasOwnProperty('details') &&
                                    isValidParam(sourceObject['details'])) {
                                    let autoResponderId = sourceObject.details[0].autoresponder_id;
                                    // detailsArray.startDate = sourceObject.details[0].startDate;
                                    // detailsArray.hDate = sourceObject.details[0].hDate;
                                    let params = {
                                        msg_details: detailsArray,
                                        auto_responder_id: autoResponderId,
                                    }
                                    var promise = Promise.resolve(HTTPClient.post(endPoints.AUTOMATION_DESIGNER.SAVE_MESSAGE, params));
                                    promise.then((response) => {
                                        if (isValidParam(response)) {
                                            if (response.status === 0) {
                                                this.props.showCustomSnackBar(getLocalizedStrings().message.AUTOMATION_DESIGNER.MESSAGE_SAVED_SUCCESSFULLY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                                            } else {
                                                this.props.showCustomSnackBar(getLocalizedStrings().message.AUTOMATION_DESIGNER.MESSAGE_NOT_SAVED_SUCCESSFULLY, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                                            }
                                        }
                                        this.props.addNode(this.props.automationCampaign.data);
                                        this.props.closeDrawer();
                                    })
                                } else {
                                    this.props.addNode(this.props.automationCampaign.data);
                                    this.props.closeDrawer();
                                }
                            } else {
                                this.props.addNode(this.props.automationCampaign.data);
                                this.props.closeDrawer();
                            }
                            // this.props.addNode(this.props.automationCampaign.data);
                            // this.props.closeDrawer();
                        }
                    }
                }
            }
        } else if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
            let dbp = this.state.interval;
            dbp = dbp.toString();
            dbp = dbp.trim();
            let interval_mode = this.state.intervalMode;
            let isCheckViewAsWeb = this.state.isCheckViewAsWeb;
            let emailBody = this.state.emailMessage;
            let subject = this.state.subject;
            subject = subject.trim();
            let sender_name = this.state.sender_name;
            let sender_email = this.state.sender_email;
            let template_id = this.state.templateId;

            let dialogData = null;
            let actionName = nodeObject.actionName;
            let view_as_web = 'No';
            if (isCheckViewAsWeb) {
                view_as_web = 'Yes';
            }
            if (isInvalidParam(subject)) {
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.ENTER_SUBJECT,
                    fieldId: 'subject'
                }
                this.openDialog(dialogData);
            } else if (dbp === "" || dbp === null || dbp === undefined) {
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.ENTER_INTERVAL_VALUE,
                    fieldId: 'dap'
                }
                this.openDialog(dialogData);
            } else if (isInvalidParam(emailBody)) {
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.ENTER_BODY,
                }
                this.openDialog(dialogData);
            } else if (isInvalidParam(sender_name)) {
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.ENTER_FROM_NAME,
                }
                this.openDialog(dialogData);
            } else if ((isInvalidParam(sender_email) || sender_email === "-1") && sender_name !== constants.CONSTANT_RECORD_OWNER) {
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.ENTER_FROM_EMAIL,
                }
                this.openDialog(dialogData);
            }
            else {
                if (isValidParam(nodeObject)) {
                    if (nodeObject.hasOwnProperty('is_new')) {
                        delete nodeObject['is_new'];
                    }
                    if (nodeObject.hasOwnProperty('details')) {
                        detailsArray = nodeObject.details[0];
                        if (isValidParam(detailsArray)) {
                            detailsArray.interval = dbp;
                            detailsArray.interval_mode = interval_mode;
                            detailsArray.view_as_web = view_as_web;
                            detailsArray.sender_name = sender_name;
                            detailsArray.sender_email = sender_email;
                            detailsArray.template = {
                                emailBody: emailBody,
                                subject: subject,
                                template_id: template_id,
                            }
                            nodeObject = removeError(nodeObject);
                            this.props.addNode(this.props.automationCampaign.data);
                            this.props.closeDrawer();
                        }
                    }
                }
            }
        } else if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
            let isCheckViewAsWeb = this.state.isCheckViewAsWeb;
            let emailBody = this.state.emailMessage;
            let subject = this.state.subject;
            subject = subject.trim();
            let template_id = this.state.templateId;

            let dialogData = null;
            let actionName = nodeObject.actionName;
            let view_as_web = 'No';
            if (isCheckViewAsWeb) {
                view_as_web = 'Yes';
            }
            if (isInvalidParam(subject)) {
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.ENTER_SUBJECT,
                    fieldId: 'subject'
                }
                this.openDialog(dialogData);
            } else if (isInvalidParam(emailBody)) {
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.ENTER_BODY,
                }
                this.openDialog(dialogData);
            } else {
                if (isValidParam(nodeObject)) {
                    if (nodeObject.hasOwnProperty('is_new')) {
                        delete nodeObject['is_new'];
                    }
                    if (nodeObject.hasOwnProperty('details')) {
                        detailsArray = nodeObject.details[0];
                        if (isValidParam(detailsArray)) {
                            detailsArray.view_as_web = view_as_web;
                            detailsArray.template = {
                                emailBody: emailBody,
                                subject: subject,
                                template_id: template_id,
                            }
                            nodeObject = removeError(nodeObject);
                            let campaignId = 0;
                            campaignId = this.props.automationCampaign.data.id;
                            if (campaignId > 0) {
                                let sourceObject = getObjectParam(getRequiredParentNode(nodeObject.childId, constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL));
                                if (isValidParam(sourceObject) && sourceObject.hasOwnProperty('details') &&
                                    isValidParam(sourceObject['details'])) {
                                    let autoResponderId = sourceObject.details[0].autoresponder_id;
                                    detailsArray.startDate = sourceObject.details[0].startDate;
                                    detailsArray.hDate = sourceObject.details[0].hDate;
                                    let params = {
                                        msg_details: detailsArray,
                                        auto_responder_id: autoResponderId,
                                        is_bday_campaign: true,
                                        processId: sourceObject.processId

                                    }
                                    var promise = Promise.resolve(HTTPClient.post(endPoints.AUTOMATION_DESIGNER.SAVE_MESSAGE, params));
                                    promise.then((response) => {
                                        if (isValidParam(response)) {
                                            if (response.status === 0) {
                                                this.props.showCustomSnackBar(getLocalizedStrings().message.AUTOMATION_DESIGNER.MESSAGE_SAVED_SUCCESSFULLY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                                            } else {
                                                this.props.showCustomSnackBar(getLocalizedStrings().message.AUTOMATION_DESIGNER.MESSAGE_NOT_SAVED_SUCCESSFULLY, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                                            }
                                        }
                                        this.props.addNode(this.props.automationCampaign.data);
                                        this.props.closeDrawer();
                                    })
                                } else {
                                    this.props.addNode(this.props.automationCampaign.data);
                                    this.props.closeDrawer();
                                }
                            } else {
                                this.props.addNode(this.props.automationCampaign.data);
                                this.props.closeDrawer();
                            }
                        }
                    }
                }
            }
        }
    }

    openDialog = (dialogData) => {
        this.props.getAppDialog(true, constants.ALERT_DIALOG, getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, null, dialogData, constants.AUTOMATION_DESIGNER_OBJECT);
    }

    changeTemplateData = (event) => {
        if (isValidParam(event)) {
            this.setState({
                emailMessage: event
            });
        }
    }

    getLibrary = (editor, type) => {
        editor.events.disableBlur();
        let data = {
            editor: editor,
            contentStyle: { width: '80%', maxWidth: 'none' },
            type: type,
            fullWidth: true,
            maxWidth: 'md',
        }
        this.props.getAppDialog(true, constants.LIBRARY_DIALOG, getLocalizedStrings().message.COMMON.LIBRARY_DIALOG_TITLE, this.handleLibraryResponse, data, null);

    }

    handleLibraryResponse = (obj) => {
        obj.editor.events.enableBlur();
        if (obj.type === 'link' && obj.src !== '') {
            document.getElementsByName('href')[0].value = obj.src;
        } else if (obj.type === 'image' && obj.src !== '') {
            obj.editor.image.insert(obj.src)
        }
        this.props.getAppDialog(false, constants.LIBRARY_DIALOG, null, null, null, null);
    }

    getVideoInsertDialog = (editor, videoType) => {
        editor.events.disableBlur();
        let data = {
            editor: editor,
            contentStyle: { width: '80%', maxWidth: 'none' },
            videoType: videoType
        }
        let title = null;
        if (videoType === constants.VIDEO_TYPE_YOUTUBE) {
            title = getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE;
        } else if (videoType === constants.VIDEO_TYPE_WISTIA) {
            title = getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE;
        }
        this.props.getAppDialog(true, constants.VIDEO_INSERT_DIALOG, title, this.handleVideoInsertResponse, data, null);

    }

    handleVideoInsertResponse = (obj) => {
        obj.editor.events.focus();
        this.props.getAppDialog(false, constants.VIDEO_INSERT_DIALOG, null, null, null, null);
        obj.editor.html.insert('<a href="' + obj.video_link + '" target="_blank" class="editor_video-selector"><img  height="220" width="380" "src="' + obj.thumbnail.url + '" title="' + obj.title + '" /></a>');
        obj.editor.events.focus();
    }

    focusSubject = (event, value) => {
        try {
            this.setState({
                cursorPosition: event.target.selectionStart
            })
        } catch (error) {
            console.log("Error in focusSubject() in automationAddMessage.js : " + error);
        }
    }

    expandEditor = () => {
        try {
            this.setState({
                isFullScreenEditor: !this.state.isFullScreenEditor,
            })
            if (this.state.isFullScreenEditor) {
                $('[id^=expand_editor]').find($(".fa")).removeClass('fa fa-expand').addClass('fa fa-compress');
                setTimeout(function () { $('[id^=expand_editor]').addClass('fr-active'); }, 500);
            } else
                $('[id^=expand_editor]').find($(".fa")).removeClass('fa fa-compress').addClass('fa fa-expand');
        } catch (error) {
            console.error("Error in 'MarketingTemplate.js -> expandEditor()':" + error);
        }
    }

    getSchedulerLink = (event) => {
        try {
            let slug = this.props.app.appointment_scheduler_slug;
            if (slug === null) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SCHEDULER.NO_SCHEDULER, null);
            } else {
                event.html.insert(' [Start Scheduler Link] Book an Appointment [End Scheduler Link] ');
            }
        } catch (error) {
            console.error("Error in 'automationAddMessage.js -> getSchedulerLink()':" + error);
        }
    }

    render() {
        let contentHeight = window.innerHeight - 250;
        let top = (contentHeight - 10) / 2
        let templateName = "";
        let nodeActionName = isValidParam(this.props.data) ? getStringParam(this.props.data.actionName) : "";
        if (nodeActionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
            templateName = "Birthday Campaign";
        }
        let personalizeFieldList = this.renderPersonalizeField();
        let campaignStatus = this.props.automationCampaign.data.status;
        let intervalModeValue = null;
        let data = this.props.data;
        let actionName = null;
        if (isValidParam(data)) {
            if (data.hasOwnProperty('actionName')) {
                actionName = data.actionName;
                if (actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
                    intervalModeValue = getLocalizedStrings().label.AUTOMATION_DESIGNER['Hours'];//"Hours";
                } else if (actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                    intervalModeValue = getLocalizedStrings().label.AUTOMATION_DESIGNER['Hour(s)'];//"Hour(s)";
                }
            }
        } else {
            let nodesLength = this.props.automationCampaign.data.nodes.length;
            let arrayNodes = this.props.automationCampaign.data.nodes;
            let nodeIndex = nodesLength - 1;
            let nodeObject = arrayNodes[nodeIndex];
            if (isValidParam(nodeObject)) {
                if (nodeObject.hasOwnProperty('actionName')) {
                    actionName = nodeObject.actionName;
                    if (actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
                        intervalModeValue = getLocalizedStrings().label.AUTOMATION_DESIGNER['Hours'];//"Hours";
                    } else if (actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                        intervalModeValue = getLocalizedStrings().label.AUTOMATION_DESIGNER['Hour(s)'];//"Hour(s)";
                    }
                }
            }
        }


        Froalaeditor.DefineIcon("personalize_dropdown", { NAME: "fa-duotone fa-gear" , template: "font_awesome"});
        Froalaeditor.RegisterCommand("personalize_dropdown", {
            title: getLocalizedStrings().label.CAMPAIGNS.PERSONALIZE,
            type: "dropdown",
            focus: false,
            undo: true,
            refreshAfterCallback: true,
    
            options: { "[Start Opt-in Link] Enter your display text [End Opt-in Link]": "Opt-in Link", "[Start Opt-out Link] Enter your display text [End Opt-out Link]": "Opt-out Link", ...this.state.personalizeFields },
            callback: function (cmd, val) {
                if (val.indexOf("[Start Opt-in Link]") > -1 || val.indexOf("[Start Opt-out Link]") > -1) {
                    this.html.insert(val);
                } else {
                    this.html.insert(' ${' + val + '} ');
                }
            },
            // Callback on refresh.
            refresh: function ($btn) {},
            // Callback on dropdown show.
            refreshOnShow: function ($btn, $dropdown) {},
        });
      

        let getLibrary = this.getLibrary
        let getVideoInsertDialog = this.getVideoInsertDialog;
        let expandEditor = this.expandEditor;

        Froalaeditor.DefineIcon('library', { NAME: 'image', template: "font_awesome" });
        Froalaeditor.RegisterCommand('library', {
            title: getLocalizedStrings().label.CAMPAIGNS.INSERT_IMAGE,
            focus: false,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                getLibrary(this, 'image')
            }
        });
    
        Froalaeditor.DefineIcon("insertYouTubeVideo", {
            NAME: "fa-brands fa-youtube", template: "font_awesome"
        });
        Froalaeditor.RegisterCommand('insertYouTubeVideo', {
            title: getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE,
            focus: true,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                getVideoInsertDialog(this, constants.VIDEO_TYPE_YOUTUBE)
            }
        });

        Froalaeditor.DefineIcon("insertWistiaVideo", {
            SRC: "/asset/images/wistia.png",
            ALT: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
            template: "image",
        });
        Froalaeditor.RegisterCommand('insertWistiaVideo', {
            title: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
            focus: true,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                getVideoInsertDialog(this, constants.VIDEO_TYPE_WISTIA)
            }
        });

        Froalaeditor.DefineIcon("expand_editor", { NAME: "expand" });
	Froalaeditor.RegisterCommand("expand_editor", {
		title: getLocalizedStrings().label.MARKETING_TEMPLATE.EXPAND_EDITOR,
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			expandEditor(this, "link");
		},
	});
	Froalaeditor.DefineIcon('coupon', { NAME: 'ticket', template: "font_awesome" });
    Froalaeditor.RegisterCommand('coupon', {
        title: getLocalizedStrings().label.COMMON.COUPONS,
        type: 'dropdown',
        focus: false,
        undo: true,
        refreshAfterCallback: true,
        options: this.state.coupons,
            callback: function (cmd, val) {
                val = val.trim();
                this.html.insert(' ${coupon_code.' + val + '} ');
            },
        // Callback on refresh.
        refresh: function ($btn) {
        },
        // Callback on dropdown show.
        refreshOnShow: function ($btn, $dropdown) {
        }
    });
    Froalaeditor.DefineIcon('link_library', { NAME: 'file-archive-o', template: "font_awesome" });
    Froalaeditor.RegisterCommand('link_library', {
        title: getLocalizedStrings().label.AUTOMATION_DESIGNER.SELECT_FROM_LIBRARY,
        focus: false,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            getLibrary(this, 'link')
        }
    });

       //add signature
	Froalaeditor.DefineIcon('signature', {  NAME: "fa-regular fa-file-signature", template: "font_awesome" });
	Froalaeditor.RegisterCommand('signature', {
		title: getLocalizedStrings().label.AUTOMATION_DESIGNER.ADD_SIGNATURE,
		focus: false,
		undo: true,
		icon: 'signature',
		refreshAfterCallback: true,
		callback: function () {
			this.html.insert('${Signature}');

		}
	});
        let getSchedulerLink = this.getSchedulerLink;
       
	  Froalaeditor.DefineIcon('scheduler-editor', { NAME: 'fas fa-calendar-minus', template: "font_awesome" });
      Froalaeditor.RegisterCommand('scheduler-editor', {
          title: getLocalizedStrings().label.COMMON.SCHEDULER_LINK,
          focus: false,
          undo: true,
          refreshAfterCallback: true,
          callback: function () {
              getSchedulerLink(this);
          }
      });

        let FroalaConfig = {
            key: constants.FROALA_EDITOR_KEY,
            placeholderText: getLocalizedStrings().label.CAMPAIGNS.TYPE_HERE,
            height: document.body.clientHeight - 205,
            toolbarButtons: constants.AUTOMATION_ADD_MESSAGE_EDITOR_TOOLBAR_CONFIGURATION,
            toolbarButtonsMD: constants.AUTOMATION_ADD_MESSAGE_EDITOR_TOOLBAR_CONFIGURATION,
            toolbarButtonsSM: constants.AUTOMATION_ADD_MESSAGE_EDITOR_TOOLBAR_CONFIGURATION,
            toolbarButtonsXS: constants.AUTOMATION_ADD_MESSAGE_EDITOR_TOOLBAR_CONFIGURATION,
            colorsBackground: constants.EDITOR_TOOLBAR_BACKGROUND_COLOR_PALETTE,
            colorsText: constants.EDITOR_TOOLBAR_TEXT_COLOR_PALETTE,
            toolbarSticky: false,
            enter: FroalaEditor.ENTER_BR,
            iframe: true,
            htmlUntouched: true,
            attribution: false,
            inlineMode: false,
            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
            linkInsertButtons: ['linkEdit', 'linkRemove', 'link_library'],
            imageEditButtons: ['imageAlign', 'imageRemove', 'imageLink', 'imageDisplay', 'imageAlt', 'imageSize', 'linkOpen', 'linkEdit', 'linkRemove'],
            tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', '-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign'],
            quickInsertButtons: [],
            imageUpload: false,
            imagePaste: false,
            fileUpload: false,
            fontSize: constants.EDITOR_FONT_SIZE,
            fontFamily: {
                'Adobe Garamond Pro': 'Adobe Garamond Pro',
                'Arial,Helvetica,sans-serif': 'Arial',
                'Futura Std': 'Futura Std',
                'Georgia,serif': 'Georgia',
                'Impact,Charcoal,sans-serif': 'Impact',
                "RobotoSegoe": 'Roboto',
                'Tahoma,Geneva,sans-serif': 'Tahoma',
                "'Times New Roman',Times,serif": 'Times New Roman',
                'Verdana,Geneva,sans-serif': 'Verdana',
            },
            fontFamilyDefaultSelection: 'Roboto',
            fontFamilySelection: true,
            events: {
                'froalaEditor.initialized': function (e, editor) {
                    $('.fr-command').on('click', function () {
                        if ($(this).data('cmd') === 'insertLink') {
                            $('input[name="target"]').prop('checked', true);
                        }
                    });
                }
            },
            events: {
                'initialized': function() {
                    window.WEBSPELLCHECKER.init({
                        container: this.$iframe ? this.$iframe[0] : this.el
                    });
                 }
             },       
        }



        return (
            <div style={{ paddingLeft: '20px', paddingTop: '3px', paddingRight: '25px' }}>
                {
                    (!this.state.isFullScreenEditor) &&
                    <div>
                        <div style={{ marginBottom: '12px' }}>
                            {
                                (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                                <div style={{ display: 'inline-block', paddingRight: '12px' }}>
                                    <Button
                                        style={styles.listViewPrimaryButton}
                                        onClick={this.saveMessage}
                                    >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                                </div>
                            }

                            {
                                (this.state.isShowTestButton) &&
                                <div style={{ display: 'inline-block', paddingRight: '7px' }}>
                                    <Button
                                        style={styles.secondaryButton}
                                        onClick={this.testMail}
                                    >{getLocalizedStrings().label.AUTOMATION_DESIGNER.TEST}</Button>
                                </div>
                            }

                            <div style={{ display: 'inline-block' }}>
                                <Button
                                    style={styles.secondaryButton}
                                    onClick={this.changeTemplate}
                                >{getLocalizedStrings().label.CAMPAIGNS.CHANGE_TEMPLATE}</Button>
                            </div>
                            {

                                <div style={{ display: 'inline-block', paddingRight: '7px' }}>
                                    <Button
                                        style={styles.secondaryButton}
                                        onClick={this.handlePreview}
                                    >{getLocalizedStrings().label.COMMON.PREVIEW}</Button>
                                </div>
                            }
                        </div>

                        <div>
                            <div className="row">
                                <div className="col-sm-6">

                                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                        <TextField
                                            variant="outlined"
                                            autoFocus
                                            label={getLocalizedStrings().label.CAMPAIGNS.SUBJECT_REQUIRED}
                                            fullWidth={true}
                                            name="subject"
                                            inputprops={{ maxlength: '256' }}
                                            id="subject"
                                            value={this.state.subject}
                                            onChange={(e) => this.changeSubject(e, e.target.value)}
                                            onClick={this.focusSubject}
                                            autoComplete="new-password"
                                            margin='dense'
                                            size='small'
                                            className={"sf-fields-bg"}
                                        />
                                    </FormControl>
                                </div>

                                <div className="col-sm-3">
                                    <div title={getLocalizedStrings().label.CAMPAIGNS.PERSONALIZE} style={{  marginTop: 5 }}>
                                        <FormControl style={{ width: '100%' }} variant="outlined">
                                            <InputLabel id="sf-automation-add-message-fromautomation-simple-select-outlined-label" className="sf-automation-add-message-fromautomationaddmessage">{getLocalizedStrings().label.CAMPAIGNS.PERSONALIZE}</InputLabel>
                                            <Select
                                                id='noteTypeList'
                                                name='noteTypeList'
                                                label={getLocalizedStrings().label.CAMPAIGNS.PERSONALIZE}
                                                labelId="sf-noteTypeList"
                                                style={{ height: '42px' }}
                                                className={"sf-fields-bg"}
                                                onChange={(e) => this.handleSelectPersonilizeChange('personalize', e, e.target.value)}
                                            >
                                                {personalizeFieldList}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            {actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL &&
                                <div className="row" style={{ marginTop: '12px' }}>
                                    <div className="col-sm-6">
                                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                            <TextField
                                                variant="outlined"
                                                id="sender_name"
                                                name="sender_name"
                                                value={this.state.sender_name}
                                                onChange={this.changeFromName}
                                                label={getLocalizedStrings().label.CAMPAIGNS.FROM_NAME_REQUIRED}
                                                inputprops={{ maxLength: 64 }}
                                                autoComplete="new-password"
                                                autoFocus
                                                margin='dense'
                                                size='small'
                                                className={"sf-fields-bg"}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-sm-6">
                                        <FormControl variant="outlined">
                                            <InputLabel id="sf-automation-add-message-fromautomationmsg-simple-select-outlined-label" className="sf-automation-add-message-fromautomationmessage">{getLocalizedStrings().label.CAMPAIGNS.FROM_EMAIL_REQUIRED}</InputLabel>
                                            <Select
                                                id="senderemail"
                                                value={this.state.sender_email}
                                                onChange={this.changeSenderEmail}
                                                label={getLocalizedStrings().label.CAMPAIGNS.FROM_EMAIL_REQUIRED}
                                                labelId="sf-senderemail"
                                                style={{ height: '42px' }}
                                                className={"sf-fields-bg"}
                                                disabled={this.state.isDisableSenderEmail}
                                            >
                                                {/*<MenuItem value={this.props.app.me.email} primaryText={this.props.app.me.email} title={this.props.app.me.email} />*/}
                                                {
                                                    (this.state.verifiedEmailIds !== null) &&
                                                    this.state.verifiedEmailIds
                                                }
                                            </Select>
                                        </FormControl>
                                        <div style={{ float: 'right' }}>
                                            <Button
                                                endIcon={<Icon>autorenew</Icon>}
                                                onClick={this.loadVerifiedEmailIds}
                                                disabled={this.state.isDisableRefresh}
                                                style={{
                                                    ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                                                    color: '#717171',
                                                    border: '0px',
                                                    minWidth: '0px',
                                                    paddingTop: '6px'
                                                }}
                                                title={getLocalizedStrings().label.REPORT.REFRESH}
                                            ></Button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div style={{ marginBottom: '4px' ,display:'flex'}}>
                            <div className='viewAsWeb' title={getLocalizedStrings().label.CAMPAIGNS.VIEW_AS_WEB_PAGE} style={{ display: 'inline-block', verticalAlign: 'top', whiteSpace: 'nowrap' }}>
                                <FormControlLabel control={<Checkbox color="default" checked={this.state.isCheckViewAsWeb} value={this.state.isCheckViewAsWeb}
                                    onChange={this.onCheckViewAsWeb} />} label={<div style={{ color: '#717171', marginRight: '5px', fontWeight: 'normal', whiteSpace: 'nowrap', width: 'auto' }}>{getLocalizedStrings().label.CAMPAIGNS.VIEW_AS_WEB_PAGE}</div>} labelposition="right" labelstyle={{ color: '#717171' }} style={{marginRight: '5px' }} />

                            </div>

                                <div>
            <Tooltip title={getLocalizedStrings().label.AUTOMATION_DESIGNER.VIEW_AS_WEBPAGE_INFO}  style={{ marginTop:11 }} arrow  >
                <div>
                    <InfoIcon   fontSize="small"/>
                </div>
            </Tooltip>
        </div> 
                        </div>
                    </div>
                }

                <div style={{ width: '100%', height: contentHeight, marginBottom: "20%" }} >
                    {this.state.editorLoader ?
                        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                            </div>
                        </div> :
                        <FroalaEditor tag='textarea' model={this.state.emailMessage} onModelChange={this.changeTemplateData} config={FroalaConfig} />
                    }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutomationAddMessage);