import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import * as HTTPClient from '../../../services/helper/httpClient';
import {Link} from 'react-router-dom';
import {isValidParam} from '../../../services/helper/parameterVerifier';
import {constants, EVENT_TYPE_KEYS} from '../../../services/constants/constants';
import {endPoints} from '../../../services/constants/endPoints';
import ShowCircularProgress from '../components/circularProgress';
import {styles} from '../../../services/constants/styles';
import {setMarketingResults} from '../../../services/actions/marketingReportAction';
import {addTab, TYPE_LIST_VIEW} from '../../../services/helper/sfTabManager';
import {getNodesByActionName} from '../../../services/helper/automationUtils';
import {getLocalizedStrings} from '../../../services/constants/MultiLingual';
import PopOver from './PopOver';

const AutoResponderReport = ({data, closeDrawer}) => {
	const dispatch = useDispatch();

	const [arrReportDetail, setArrReportDetail] = useState([]);
	const [gblStatIdSubArr, setGblStatIdSubArr] = useState([]);
	const [marketingResults, setMarketingResultsL] = useState([]);
	const [campaignId, setCampaignId] = useState(0);
	const [arrTouchNames, setArrTouchNames] = useState([]);
	const [campaignName, setCampaignName] = useState(null);
	const [isCampListOpen, setIsCampListOpen] = useState(false);
	const [reportType, setReportType] = useState(constants.AUTO_RESPONDER_REPORT_TYPE);

	useEffect(() => {
		generateTouchCampaignsList();
		if (isValidParam(data)) {
			if (data.hasOwnProperty('campaignId')) {
				fetchMarketingReport(data);
			}
		}
	}, []);


	const fetchMarketingReport = (data) => {
		try {
			let campaignId = data.campaignId;
			let params = {
				campaign_id: campaignId,
			}
			let reportType = constants.AUTO_RESPONDER_REPORT_TYPE;

			if (data.hasOwnProperty('templateName') && data.templateName === 'Birthday Campaign') {
				params.report_type = constants.BROADCAST_REPORT;
				reportType = constants.BROADCAST_REPORT_TYPE;
			} else {
				params.report_type = constants.AUTO_RESPONDER_REPORT;
			}
			const promise = Promise.resolve(HTTPClient.get(endPoints.TOUCH_CAMPAIGNS.RESULTS, params));
			promise.then((response) => {
				let arrReportDetail = [];
				let marketingResults = [];
				const tempGblStatIdSubArr = [...gblStatIdSubArr];
				if (isValidParam(response)) {
					if (response.hasOwnProperty('marketing_results')) {
						marketingResults = response['marketing_results'];
						if (marketingResults !== null && marketingResults !== undefined && marketingResults.length > 0) {
							marketingResults.forEach((resultObject) => {
								if (resultObject.hasOwnProperty('clicked')) {
									let clickCount = resultObject.clicked;
									if (clickCount > 0) {
										if (reportType === constants.BROADCAST_REPORT_TYPE) {
											tempGblStatIdSubArr[resultObject.id] = resultObject.campaign_name;
										} else {
											tempGblStatIdSubArr[resultObject.id] = resultObject.msg_name;
										}
									}
								}
								let reportDetail = generateReport(resultObject, marketingResults);
								arrReportDetail.push(reportDetail);
							});
						} else {
							arrReportDetail.push(<div
								className="data_less">{getLocalizedStrings().message.AUTOMATION_DESIGNER.NO_TOUCH_FOUND}</div>);
						}
					} else {
						arrReportDetail.push(<div
							className="data_less">{getLocalizedStrings().message.AUTOMATION_DESIGNER.NO_TOUCH_FOUND}</div>);
					}
				} else {
					arrReportDetail.push(<div
						className="data_less">{getLocalizedStrings().message.AUTOMATION_DESIGNER.NO_TOUCH_FOUND}</div>);
				}
				setArrReportDetail(arrReportDetail);
				setGblStatIdSubArr(tempGblStatIdSubArr);
				setMarketingResultsL(marketingResults);
				setCampaignId(campaignId);
				setReportType(reportType);
			});
		} catch (error) {
			console.error("Error in 'AutoResponderReport.js -> fetchMarketingReport()':" + error);
		}
	}

	const generateTouchCampaignsList = () => {
		let arrTouchNames = [];
		let campaignId = data.campaignId;
		let campaignName = null;
		try {
			let touchCampArr = getNodesByActionName(constants.AUTOMATION_DESIGNER_SEND_MAIL);
			if (touchCampArr.length > 1) {
				touchCampArr.forEach((objectTouch) => {
					let touchCampDetail = objectTouch['details'][0];
					if ((campaignName === null || campaignName === undefined || campaignName === '') &&
						(touchCampDetail['autoresponder_id'] === campaignId)) {
						campaignName = touchCampDetail['name'];
					}
					if (objectTouch['id'] > 0) {
						arrTouchNames.push(touchCampDetail);
					}
				});
				setArrTouchNames(arrTouchNames);
				setCampaignName(campaignName);
			}
		} catch (error) {
			console.error("Error in 'AutoResponderReport.js -> generateTouchCampaignsList()':" + error);
		}
	}

	const openTab = (label, component, queryId, imgName, url, statsId, eventType, marketingResults) => {
		let filter = {
			id: queryId,
			name: '',
			type: constants.TYPE_AUTORESPONDER_REPORT,
		}
		let tabType = TYPE_LIST_VIEW;
		let reportFieldValue = eventType;
		if (eventType === 'click') {
			reportFieldValue = 'Clicked';
		}
		let campaignName = null;
		let reportObj = marketingResults.filter((el) => el.id === statsId);
		campaignName = reportObj[0].campaign_name;
		let tab = {
			label: label,
			object: component,
			type: tabType,
			imgName: imgName,
			info: {
				filter: filter,
				input_param: {
					stat_id: statsId,
					event_type: eventType,
				},
				isReport: true,
				reportType: reportType,
				event_type_key: EVENT_TYPE_KEYS[eventType],
				campaignId: campaignId,
				reportFieldValue: reportFieldValue,
				reportLabel: campaignName,
				recordCount: reportObj[0][EVENT_TYPE_KEYS[eventType]],
			},
			url: url,
			isActive: true
		};
		dispatch(setMarketingResults(marketingResults));
		addTab(tab, true);
		closeDrawer();
	}

	function drillDownMarketingReport(statsId, eventType, reportType, marketingResults) {
		try {
			let params = {
				name: 'All records',
				tableId: 9,
			}

			const promise = Promise.resolve(HTTPClient.get(endPoints.REPORTS.QUERY_DETAIL, params));
			promise.then((response) => {
				if (isValidParam(response)) {
					let label = "";
					if (eventType === 'click') {
						let reportName = gblStatIdSubArr.length > 0 ? gblStatIdSubArr[statsId] : null;
						let eventTypeText = getLocalizedStrings().label.CAMPAIGNS.CLICK_URL;
						label = eventTypeText + " " + reportName;
					} else {
						let eventTypeText = eventType.charAt(0).toUpperCase() + eventType.slice(1);
						if (reportType === constants.BROADCAST_REPORT_TYPE) {
							label = eventTypeText + " " + getLocalizedStrings().label.BROADCAST_CAMPAIGNS.EMAIL_CAMPAIGN_REPORT;
						} else if (reportType === constants.AUTO_RESPONDER_REPORT_TYPE) {
							let eventTypeTextUpper = eventTypeText.toUpperCase();
							eventTypeText = getLocalizedStrings().label.CAMPAIGNS.hasOwnProperty(eventTypeTextUpper) ? getLocalizedStrings().label.CAMPAIGNS[eventTypeTextUpper] : eventTypeText;
							label = eventTypeText + " " + getLocalizedStrings().label.AUTOMATION_DESIGNER.TOUCH_CAMPAIGN_REPORT;
						} else if (reportType === constants.AB_REPORT_TYPE) {
							label = eventTypeText + " " + getLocalizedStrings().label.AB_CAMPAIGNS.AB_CAMPAIGN_REPORT;
						}
					}
					let url = "/" + constants.CONTACTS_OBJECT + "/" + constants.AUTO_RESPONDER_REPORT_TYPE.toLocaleLowerCase();
					openTab(label, constants.CONTACTS_OBJECT, response.query_id, "fa fa-user", url, statsId, eventType, marketingResults);
				}
			});
		} catch (error) {
			console.error("Error in 'AutoResponderReport.js -> drillDownMarketingReport()':" + error);
		}
	}

	const generateReport = (resultObject, marketingResults) => {
		if (isValidParam(resultObject)) {
			let msg_name = null;
			let sent = 0;
			let opened = 0;
			let unopened = 0;
			let clicked = 0;
			let bounced = 0;
			let unsubscribed = 0;
			let spam = 0;
			let dropped = 0;
			if (resultObject.hasOwnProperty('sent')) {
				sent = resultObject.sent;
			}
			if (resultObject.hasOwnProperty('opened')) {
				opened = resultObject.opened;
			}
			if (resultObject.hasOwnProperty('unopened')) {
				unopened = resultObject.unopened;
			}
			if (resultObject.hasOwnProperty('clicked')) {
				clicked = resultObject.clicked;
			}
			if (resultObject.hasOwnProperty('bounced')) {
				bounced = resultObject.bounced;
			}
			if (resultObject.hasOwnProperty('unsubscribed')) {
				unsubscribed = resultObject.unsubscribed;
			}
			if (resultObject.hasOwnProperty('spam')) {
				spam = resultObject.spam;
			}
			if (resultObject.hasOwnProperty('dropped')) {
				dropped = resultObject.dropped;
			}

			if (resultObject.hasOwnProperty('msg_name')) {
				msg_name = resultObject.msg_name;
				let msg_name_split = msg_name.split("-");
				if (msg_name_split.length === 2 && msg_name_split[0] === 'Message') {
					msg_name = getLocalizedStrings().label.AUTOMATION_DESIGNER.MESSAGE + "-" + msg_name_split[1];
				}
			} else if (resultObject.hasOwnProperty('campaign_name')) {
				msg_name = resultObject.campaign_name;
			}

			return (
				<div>
					<div style={{fontSize: '19px', paddingBottom: '30px', paddingTop: '10px'}}>{msg_name}</div>
					<div className="report_box_area" style={{paddingBottom: '9%'}}>
						<div className="report_box">
							{/* {getLocalizedStrings().label.CAMPAIGNS.SENT} <br /> */}
							<div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
							     title={getLocalizedStrings().label.CAMPAIGNS.SENT}>{getLocalizedStrings().label.CAMPAIGNS.SENT}</div>
							{
								sent > 0 &&
								<Link to="#"
								      onClick={() => drillDownMarketingReport(resultObject.id, 'Sent', constants.AUTO_RESPONDER_REPORT_TYPE, marketingResults)}>
									<span style={{fontSize: 16, color: '#F04B24'}}>{sent}</span>
								</Link>
							}
							{
								sent === 0 &&
								<span style={{fontSize: 16, color: '#F04B24'}}>{sent}</span>
							}
							<br/>
							{sent !== 0 && <span>&nbsp;</span>}
						</div>
						<div className="report_gap"/>

						<div className="report_box">
							<div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
							     title={getLocalizedStrings().label.CAMPAIGNS.OPENED}>{getLocalizedStrings().label.CAMPAIGNS.OPENED}</div>
							{
								opened > 0 &&
								<Link to="#"
								      onClick={() => drillDownMarketingReport(resultObject.id, 'Open', constants.AUTO_RESPONDER_REPORT_TYPE, marketingResults)}>
									<span style={{fontSize: 16, color: '#F04B24'}}>{opened}</span>
								</Link>
							}
							{
								opened === 0 && <span style={{fontSize: 16, color: '#F04B24'}}>{opened}</span>
							}
							<br/>
							{getPercentage(opened, sent)}
						</div>
						<div className="report_gap"/>

						<div className="report_box">
							<div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
							     title={getLocalizedStrings().label.CAMPAIGNS.UNOPENED}>{getLocalizedStrings().label.CAMPAIGNS.UNOPENED}</div>
							{
								unopened > 0 &&
								<Link to="#"
								      onClick={() => drillDownMarketingReport(resultObject.id, 'Unopened', constants.AUTO_RESPONDER_REPORT_TYPE, marketingResults)}>
									<span style={{fontSize: 16, color: '#F04B24'}}>{unopened}</span>
								</Link>
							}
							{
								unopened === 0 &&
								<span style={{fontSize: 16, color: '#F04B24'}}>{unopened}</span>
							}
							<br/>
							{getPercentage(unopened, sent)}
						</div>
						<div className="report_gap"/>

						<div className="report_box">
							<div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
							     title={getLocalizedStrings().label.CAMPAIGNS.CLICKED}>{getLocalizedStrings().label.CAMPAIGNS.CLICKED}</div>
							{
								clicked > 0 &&
								<Link to="#"
								      onClick={() => drillDownMarketingReport(resultObject.id, 'click', constants.AUTO_RESPONDER_REPORT_TYPE, marketingResults)}>
									<span style={{fontSize: 16, color: '#F04B24'}}>{clicked}</span>
								</Link>
							}
							{
								clicked === 0 &&
								<span style={{fontSize: 16, color: '#F04B24'}}>{clicked}</span>
							}
							<br/>
							{getPercentage(clicked, sent)}
						</div>
						<div className="report_gap"/>

						<div className="report_box">
							<div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
							     title={getLocalizedStrings().label.CAMPAIGNS.BOUNCED}>{getLocalizedStrings().label.CAMPAIGNS.BOUNCED}</div>
							{
								bounced > 0 &&
								<Link to="#"
								      onClick={() => drillDownMarketingReport(resultObject.id, 'Bounce', constants.AUTO_RESPONDER_REPORT_TYPE, marketingResults)}>
									<span style={{fontSize: 16, color: '#F04B24'}}>{bounced}</span>
								</Link>
							}
							{
								bounced === 0 &&
								<span style={{fontSize: 16, color: '#F04B24'}}>{bounced}</span>
							}
							<br/>
							{getPercentage(bounced, sent)}
						</div>
						<div className="report_gap"/>

						<div className="report_box">
							<div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
							     title={getLocalizedStrings().label.CAMPAIGNS.UNSUBSCRIBED}>{getLocalizedStrings().label.CAMPAIGNS.UNSUBSCRIBED}</div>
							{
								unsubscribed > 0 &&
								<Link to="#"
								      onClick={() => drillDownMarketingReport(resultObject.id, 'Unsubscribed', constants.AUTO_RESPONDER_REPORT_TYPE, marketingResults)}>
									<span style={{fontSize: 16, color: '#F04B24'}}>{unsubscribed}</span>
								</Link>
							}
							{
								unsubscribed === 0 &&
								<span style={{fontSize: 16, color: '#F04B24'}}>{unsubscribed}</span>
							}
							<br/>
							{getPercentage(unsubscribed, sent)}
						</div>
						<div className="report_gap"/>

						<div className="report_box">
							<div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
							     title={getLocalizedStrings().label.CAMPAIGNS.SPAM}>{getLocalizedStrings().label.CAMPAIGNS.SPAM}</div>
							{
								spam > 0 &&
								<Link to="#"
								      onClick={() => drillDownMarketingReport(resultObject.id, 'spamreport', constants.AUTO_RESPONDER_REPORT_TYPE, marketingResults)}>
									<span style={{fontSize: 16, color: '#F04B24'}}>{spam}</span>
								</Link>
							}
							{
								spam === 0 &&
								<span style={{fontSize: 16, color: '#F04B24'}}>{spam}</span>
							}
							<br/>
							{getPercentage(spam, sent)}
						</div>
						<div className="report_gap"/>

						<div className="report_box">
							<div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
							     title={getLocalizedStrings().label.CAMPAIGNS.DROPPED}>{getLocalizedStrings().label.CAMPAIGNS.DROPPED}</div>
							{
								dropped > 0 &&
								<Link to="#"
								      onClick={() => drillDownMarketingReport(resultObject.id, 'dropped', constants.AUTO_RESPONDER_REPORT_TYPE, marketingResults)}>
									<span style={{fontSize: 16, color: '#F04B24'}}>{dropped}</span>
								</Link>
							}
							{
								dropped === 0 &&
								<span style={{fontSize: 16, color: '#F04B24'}}>{dropped}</span>
							}
							<br/>
							{getPercentage(dropped, sent)}
						</div>
					</div>
				</div>
			);
		}
	}

	const getPercentage = (value, sent_value) => {
		let percentage_value = 0;
		if (sent_value !== 0) {
			percentage_value = (value / sent_value) * 100;
			if (Math.round(percentage_value) !== percentage_value) {
				percentage_value = percentage_value.toFixed(2);
			}
			return percentage_value + '%';
		}
	}

	function handleCampListOpen(event) {
		event.preventDefault();
		setIsCampListOpen(true);
	}

	function changeCampName(value) {
		try {
			fetchMarketingReport({campaignId: value});
			let campaignName = null;
			if (arrTouchNames !== null && arrTouchNames.length > 0) {
				let objTouchName = arrTouchNames.filter(function (el) {
					return value === el.autoresponder_id;
				});
				objTouchName = objTouchName[0];
				campaignName = objTouchName.name;
			}

			setIsCampListOpen(false);
			setCampaignName(campaignName);
		} catch (error) {
			console.error("Error in 'AutoResponderReport.js -> changeCampName()':" + error);
		}
	}


	let pageHeight = window.innerHeight - 100;
	let top = (pageHeight - 10) / 2
	return (
		<div style={{paddingLeft: '25px', paddingTop: '10px', height: '100%'}}>
			{(arrTouchNames !== null && arrTouchNames.length > 0) &&
				<div>
					<div style={{
						cursor: 'pointer',
						fontSize: '20px',
						width: '230px',
						border: '1px solid #ccc',
						backgroundColor: '#fff',
						height: '38px',
						paddingTop: '4px',
						paddingLeft: '5px'
					}} onClick={handleCampListOpen}>
						<div style={{
							display: 'inline-block',
							width: '197px',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis'
						}} title={campaignName}>
							{campaignName}
						</div>
						<div style={{display: 'inline-block', verticalAlign: 'top', paddingTop: '6px'}} title="">
							<PopOver
								id={'modules'}
								key={'modules'}
								name={'modules'}
								buttonEndIcon={"keyboard_arrow_down"}
								buttonStyle={{
									float: 'left',
									maxWidth: '60px',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									display: 'inline-block'
								}}
								labelposition={"before"}
								buttonLabel={""}
								onclickAction={changeCampName}
								isShowIcon={true}
								title={""}
								value={""}
							/>
						</div>
					</div>
				</div>
			}

			{
				(arrReportDetail !== null && arrReportDetail.length > 0) &&
				arrReportDetail
			}

			{
				(arrReportDetail === null || arrReportDetail.length === 0) &&
				<div className="loaderh">
					{<div style={{width: '100%', height: pageHeight}}>
						<div className="asset-loaderh" style={{paddingTop: top, paddingLeft: '45%'}}>
							<div style={{...styles.assetLoaderContainer, height: 50, width: 50, padding: 7}}>
								<ShowCircularProgress size={30} style={{marginTop: '3', marginLeft: '3'}}/>
							</div>
						</div>
					</div>}
				</div>
			}
		</div>
	);
}


export default AutoResponderReport;

