import { getLocalizedStrings } from '../../../../../services/constants/MultiLingual';

// s1 - Codependency and process addictions behaviors ("C" for Current, "D" Denies or "H" for History)
export const section1Init = [
    {
        id: 's11',
        section: 1,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList17',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.CARETAKING,
        options: ['C', 'D', 'H'],
        input1Name: 'customField43',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.EXPLANATION,
        inputType: 'text',
    },
    {
        id: 's12',
        section: 1,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList18',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.PEOPLE_PLEASING,
        options: ['C', 'D', 'H'],
        input1Name: 'customField44',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.EXPLANATION,
        inputType: 'text',
    },
    {
        id: 's13',
        section: 1,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList19',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.RELATIONSHIPS,
        options: ['C', 'D', 'H'],
        input1Name: 'customField45',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.EXPLANATION,
        inputType: 'text',
    },
    {
        id: 's14',
        section: 1,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList20',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.PERFECTIONISM,
        options: ['C', 'D', 'H'],
        input1Name: 'customField46',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.EXPLANATION,
        inputType: 'text',
    },
    {
        id: 's15',
        section: 1,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList21',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.CONTROL,
        options: ['C', 'D', 'H'],
        input1Name: 'customField47',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.EXPLANATION,
        inputType: 'text',
    },
    {
        id: 's16',
        section: 1,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList22',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.WORKAHOLISM,
        options: ['C', 'D', 'H'],
        input1Name: 'customField48',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.EXPLANATION,
        inputType: 'text',
    },
    {
        id: 's17',
        section: 1,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList23',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.SHOPPING_SPENDING,
        options: ['C', 'D', 'H'],
        input1Name: 'customField49',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.EXPLANATION,
        inputType: 'text',
    },
    {
        id: 's18',
        section: 1,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList24',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.RELIGION_OR_ABUSE,
        options: ['C', 'D', 'H'],
        input1Name: 'customField50',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.EXPLANATION,
        inputType: 'text',
    },
    {
        id: 's19',
        section: 1,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList25',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.RISK_TAKING,
        options: ['C', 'D', 'H'],
        input1Name: 'customField51',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.EXPLANATION,
        inputType: 'text',
    },
    {
        id: 's110',
        section: 1,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList26',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.EXCESSIVE_SCREEN_TIME,
        options: ['C', 'D', 'H'],
        input1Name: 'customField52',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.EXPLANATION,
        inputType: 'text',
    },
    {
        id: 's111',
        section: 1,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList27',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.DEPRESSION,
        options: ['C', 'D', 'H'],
        input1Name: 'customField53',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.EXPLANATION,
        inputType: 'text',
    },
    {
        id: 's112',
        section: 1,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList28',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.ANXIETY,
        options: ['C', 'D', 'H'],
        input1Name: 'customField54',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.EXPLANATION,
        inputType: 'text',
    },
    {
        id: 's115',
        section: 1,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList29',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.ANGER_OR_RAGE,
        options: ['C', 'D', 'H'],
        input1Name: 'customField55',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.EXPLANATION,
        inputType: 'text',
    },
    {
        id: 's116',
        section: 1,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList30',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.SELF_EXTEEM,
        options: ['C', 'D', 'H'],
        input1Name: 'customField56',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.EXPLANATION,
        inputType: 'text',
    },
    // sub - section1 - 11 - xs - 12
    {
        id: 's116',
        section: 11,
        dataType: 'input',
        name: 'customField57',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.PAINFUL_OR_TROMATIC,
        required: false,
        multiline: true,
        max_lines: 2,
    },
];

export const section111Init = [
    // sub - section1 - 111 - xs - 4
    {
        id: 's117',
        section: 111,
        dataType: 'input',
        name: 'customField58',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.MOTHER,
        required: false,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 's118',
        section: 111,
        dataType: 'input',
        name: 'customField59',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.FATHER,
        required: false,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 's119',
        section: 111,
        dataType: 'input',
        name: 'customField60',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.SIBLINGS,
        required: false,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 's120',
        section: 111,
        dataType: 'input',
        name: 'customField61',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.CHILDREN,
        required: false,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 's121',
        section: 111,
        dataType: 'input',
        name: 'customField62',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.SIG_OTHER,
        required: false,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 's122',
        section: 111,
        dataType: 'input',
        name: 'customField63',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.OTHER,
        required: false,
        multiline: false,
        max_lines: 1,
    },
];

export const section11Init = [
    // sub - section1 - 11 - xs - 12
    {
        id: 's116',
        section: 11,
        dataType: 'input',
        name: 'customField64',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.PRESENTING_PROBLEM,
        required: false,
        multiline: true,
        max_lines: 2,
    },
];

// section 2 - Substance history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use
export const section2Init = [
    {
        id: 's21',
        secion: 2,
        dataType: 'dropdownWithThreeInput',
        dropDownName: 'customFieldList31',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.ALCOHAL,
        options: ['C', 'D', 'H'],

        input1Name: 'customField65',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_MUCH,
        input1Type: 'input',

        input2Name: 'customField75',
        input2Value: '',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_OFTEN,
        input2Type: 'input',

        input3Name: 'customFieldDate12',
        input3Value: '',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.DATE_LAST_USED,
        input3Type: 'date-picker',
    },
    {
        id: 's22',
        section: 2,
        dataType: 'dropdownWithThreeInput',
        dropDownName: 'customFieldList32',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.COCAINE_OR_CRACK,
        options: ['C', 'D', 'H'],

        input1Name: 'customField66',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_MUCH,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField76',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_OFTEN,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customFieldDate3',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.DATE_LAST_USED,
        input3Type: 'date-picker',
        input3Value: '',
    },
    {
        id: 's23',
        section: 2,
        dataType: 'dropdownWithThreeInput',
        dropDownName: 'customFieldList33',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.HEROINE,
        options: ['C', 'D', 'H'],

        input1Name: 'customField67',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_MUCH,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField77',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_OFTEN,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customFieldDate4',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.DATE_LAST_USED,
        input3Type: 'date-picker',
        input3Value: '',
    },
    {
        id: 's24',
        section: 2,
        dataType: 'dropdownWithThreeInput',
        dropDownName: 'customFieldList34',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.MARIJUANA,
        options: ['C', 'D', 'H'],

        input1Name: 'customField68',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_MUCH,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField78',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_OFTEN,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customFieldDate5',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.DATE_LAST_USED,
        input3Type: 'date-picker',
        input3Value: '',
    },
    {
        id: 's25',
        section: 2,
        dataType: 'dropdownWithThreeInput',
        dropDownName: 'customFieldList35',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.METHADONE_OR_SUBOXONE,
        options: ['C', 'D', 'H'],
        input1Name: 'customField69',

        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_MUCH,
        input1Type: 'input',
        input1Value: '',
        input2Name: 'customField79',

        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_OFTEN,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customFieldDate6',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.DATE_LAST_USED,
        input3Type: 'date-picker',
        input3Value: '',
    },
    {
        id: 's26',
        section: 2,
        dataType: 'dropdownWithThreeInput',
        dropDownName: 'customFieldList36',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.AMPHETAMINES,
        options: ['C', 'D', 'H'],

        input1Name: 'customField70',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_MUCH,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField80',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_OFTEN,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customFieldDate7',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.DATE_LAST_USED,
        input3Type: 'date-picker',
        input3Value: '',
    },
    {
        id: 's27',
        section: 2,
        dataType: 'dropdownWithThreeInput',
        dropDownName: 'customFieldList37',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.NARCOTICS_OR_OPIATES,
        options: ['C', 'D', 'H'],

        input1Name: 'customField71',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_MUCH,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField81',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_OFTEN,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customFieldDate8',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.DATE_LAST_USED,
        input3Type: 'date-picker',
        input3Value: '',
    },
    {
        id: 's28',
        section: 2,
        dataType: 'dropdownWithThreeInput',
        dropDownName: 'customFieldList38',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.BENZODIAZEPINES,
        options: ['C', 'D', 'H'],

        input1Name: 'customField72',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_MUCH,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField82',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_OFTEN,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customFieldDate9',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.DATE_LAST_USED,
        input3Type: 'date-picker',
        input3Value: '',
    },
    {
        id: 's29',
        section: 2,
        dataType: 'dropdownWithThreeInput',
        dropDownName: 'customFieldList39',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.TOBACCO,
        options: ['C', 'D', 'H'],

        input1Name: 'customField73',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_MUCH,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField83',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_OFTEN,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customFieldDate10',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.DATE_LAST_USED,
        input3Type: 'date-picker',
        input3Value: '',
    },
    {
        id: 's210',
        section: 2,
        dataType: 'dropdownWithThreeInput',
        dropDownName: 'customFieldList40',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.OTHER_ETC,
        options: ['C', 'D', 'H'],

        input1Name: 'customField74',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_MUCH,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField84',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_OFTEN,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customFieldDate11',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.DATE_LAST_USED,
        input3Type: 'date-picker',
        input3Value: '',
    },
];

// section 3 - Substance treatment history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use
export const section3Init = [
    {
        id: 's31',
        section: 3,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList41',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.WITHDRAWEL_SYMPTOMS,
        options: ['C', 'D', 'H'],
        input1Name: 'customField85',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.EXPLANATION_HISTORY_TIMELINE,
        inputType: 'text',
    },
    {
        id: 's32',
        section: 3,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList42',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.MEDICAL_DETOX,
        options: ['C', 'D', 'H'],
        input1Name: 'customField86',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.EXPLANATION_HISTORY_TIMELINE,
        input1Value: '',
        inputType: 'text',
    },
    {
        id: 's33',
        section: 3,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList43',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.PRIMARY_TRATMENT_OR_IOP,
        options: ['C', 'D', 'H'],
        input1Name: 'customField87',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.EXPLANATION_HISTORY_TIMELINE,
        input1Value: '',
        inputType: 'text',
    },
];

//section 4 -  Suicidal ideation / self harm behaviors ("C" for Current, "D" Denies or "H" for History)
export const section4Init = [
    {
        id: 's41',
        section: 4,
        dataType: 'dropdown',
        dropDownName: 'customFieldList44',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.SUCIDE_THOUGHT,
        options: ['C', 'D', 'H'],
        required: false,
    },
    {
        id: 's42',
        section: 4,
        dataType: 'dropdown',
        dropDownName: 'customFieldList45',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.ENGAGED_IN_SELF_HARD,
        options: ['C', 'D', 'H'],
        required: false,
    },
    {
        id: 's43',
        section: 4,
        dataType: 'dropdown',
        dropDownName: 'customFieldList46',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.ATTEMPED_SUCIDE,
        options: ['C', 'D', 'H'],
        required: false,
    },
    {
        id: 's44',
        section: 4,
        dataType: 'dropdown',
        dropDownName: 'customFieldList47',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.ENGAGED_IN_CUTTING,
        options: ['C', 'D', 'H'],
        required: false,
    },
    {
        id: 's45',
        section: 4,
        dataType: 'dropdown',
        dropDownName: 'customFieldList67', // TBD
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.ATTEMPED_SUCIDE_WHITE_SOBER,
        options: ['C', 'D', 'H'],
        required: false,
    },
    {
        id: 's46',
        section: 4,
        dataType: 'dropdown',
        dropDownName: 'customFieldList68', // TBD
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.ENGEGED_IN_SCORINCATION,
        options: ['C', 'D', 'H'],
        required: false,
    },
    {
        id: 's47',
        section: 4,
        dataType: 'dropdown',
        dropDownName: 'customFieldList48',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.HOSPITALIZED_FOR_A_SUICIDE_ATTEMPT,
        options: ['C', 'D', 'H'],
        required: false,
    },
    {
        id: 's48',
        section: 4,
        dataType: 'dropdown',
        dropDownName: 'customFieldList49',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.ENGEGED_IN_BODY_MUTATION,
        options: ['C', 'D', 'H'],
        required: false,
    },
    {
        id: 's49',
        section: 44,
        dataType: 'input',
        name: 'customField88',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.HISTORY_EXPLANATION,
        required: false,
        multiline: true,
        max_lines: 2,
    },
];

//section 5 - Mental health/ psychiatric diagnosis history- ("C" for Current, "D" Denies or "H" for History)
export const section5Init = [
    {
        id: 's51',
        section: 5,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList50',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.PHYCHIATRIC_DIANOSIS,
        options: ['C', 'D', 'H'],
        input1Name: 'customField89',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.DATES_DIAGNOSIS_TREATMENT,
        input1Value: '',
        inputType: 'text',
    },
    {
        id: 's52',
        section: 5,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList51',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.MENTAL_BREAKDOWNS_OR_HOSPITALIZATIONS,
        options: ['C', 'D', 'H'],
        input1Name: 'customField90',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.DATES_DIAGNOSIS_TREATMENT,
        input1Value: '',
        inputType: 'text',
    },
];

// section 6 - Eating disorder behaviors - ("C" for Current, "D" Denies or "H" for History)
export const section6Init = [
    {
        id: 's61',
        section: 6,
        dataType: 'dropdownWithSevenInput',
        dropDownName: 'customFieldList52',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.ANOREXIA,
        options: ['C', 'D', 'H'],

        input1Name: 'customField91',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.TREATMENT_HISTORY,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField95',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.PERIODS_OF_ABSTINENCE,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customField99',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.LAST_EPISODE,
        input3Type: 'input',
        input3Value: '',

        input4Name: 'customField103',
        input4Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.LOWEST_WEIGHT,
        input4Type: 'input',
        input4Value: '',

        input5Name: 'customFielde107',
        input5Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HIGHEST_WEIGHT,
        input5Type: 'input',
        input5Value: '',

        input6Name: 'customField111',
        input6Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.CURRENT_WEIGHT_OR_HEIGHT,
        input6Type: 'input',
        input6Value: '',

        input7Name: 'customFielde115',
        input7Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_LONG_AT_CURRENT_WEIGHT,
        input7Type: 'input',
        input7Value: '',
    },
    {
        id: 's62',
        section: 6,
        dataType: 'dropdownWithSevenInput',
        dropDownName: 'customField53',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.BULIMIA,
        options: ['C', 'D', 'H'],

        input1Name: 'customField92',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.TREATMENT_HISTORY,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField96',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.PERIODS_OF_ABSTINENCE,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customField100',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.LAST_EPISODE,
        input3Type: 'input',
        input3Value: '',

        input4Name: 'customField104',
        input4Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.LOWEST_WEIGHT,
        input4Type: 'input',
        input4Value: '',

        input5Name: 'customFielde108',
        input5Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HIGHEST_WEIGHT,
        input5Type: 'input',
        input5Value: '',

        input6Name: 'customField112',
        input6Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.CURRENT_WEIGHT_OR_HEIGHT,
        input6Type: 'input',
        input6Value: '',

        input7Name: 'customFielde116',
        input7Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_LONG_AT_CURRENT_WEIGHT,
        input7Type: 'input',
        input7Value: '',
    },
    {
        id: 's63',
        section: 6,
        dataType: 'dropdownWithSevenInput',
        dropDownName: 'customField54',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.OVEREATING,
        options: ['C', 'D', 'H'],

        input1Name: 'customField93',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.TREATMENT_HISTORY,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField97',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.PERIODS_OF_ABSTINENCE,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customField101',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.LAST_EPISODE,
        input3Type: 'input',
        input3Value: '',

        input4Name: 'customField105',
        input4Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.LOWEST_WEIGHT,
        input4Type: 'input',
        input4Value: '',

        input5Name: 'customFielde109',
        input5Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HIGHEST_WEIGHT,
        input5Type: 'input',
        input5Value: '',

        input6Name: 'customField113',
        input6Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.CURRENT_WEIGHT_OR_HEIGHT,
        input6Type: 'input',
        input6Value: '',

        input7Name: 'customFielde117',
        input7Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_LONG_AT_CURRENT_WEIGHT,
        input7Type: 'input',
        input7Value: '',
    },
    {
        id: 's64',
        section: 6,
        dataType: 'dropdownWithSevenInput',
        dropDownName: 'customField55',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.EXCESSING_EXCERCISING,
        options: ['C', 'D', 'H'],

        input1Name: 'customField94',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.TREATMENT_HISTORY,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField98',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.PERIODS_OF_ABSTINENCE,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customField102',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.LAST_EPISODE,
        input3Type: 'input',
        input3Value: '',

        input4Name: 'customField106',
        input4Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.LOWEST_WEIGHT,
        input4Type: 'input',
        input4Value: '',

        input5Name: 'customFielde110',
        input5Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HIGHEST_WEIGHT,
        input5Type: 'input',
        input5Value: '',

        input6Name: 'customField114',
        input6Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.CURRENT_WEIGHT_OR_HEIGHT,
        input6Type: 'input',
        input6Value: '',

        input7Name: 'customFielde118',
        input7Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.HOW_LONG_AT_CURRENT_WEIGHT,
        input7Type: 'input',
        input7Value: '',
    },
    {
        id: 's65',
        section: 6,
        dataType: 'input',
        name: 'customField119',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.DETAILS_SOBRIETY_LENGTH_PATTERNS,
        required: false,
        multiline: true,
        max_lines: 2,
    },
];

// section 7 - Gambling behaviors - ("C" for Current, "D" Denies or "H" for History)
export const section7Init = [
    {
        id: 's71',
        section: 7,
        dataType: 'dropdownWithSixInput',
        dropDownName: 'customField56',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.BETTING,
        options: ['C', 'D', 'H'],

        input1Name: 'customField120',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.ONSET,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField123',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.TREATMENT,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customField126',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.PERIODS_OF_ABSTINENCE,
        input3Type: 'input',
        input3Value: '',

        input4Name: 'customField129',
        input4Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.LAST_EPISODE,
        input4Type: 'input',
        input4Value: '',

        input5Name: 'customFielde132',
        input5Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.AMOUNT_OF_DEBT_ACCUMULAATED,
        input5Type: 'input',
        input5Value: '',

        input6Name: 'customField135',
        input6Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.TIME_PER_DAY_OR_WEEK_SPENT_GAMBLING,
        input6Type: 'input',
        input6Value: '',
    },
    {
        id: 's72',
        section: 7,
        dataType: 'dropdownWithSixInput',
        dropDownName: 'customField57',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.GAMING_OR_CASINOS,
        options: ['C', 'D', 'H'],

        input1Name: 'customField121',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.ONSET,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField124',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.TREATMENT,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customField127',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.PERIODS_OF_ABSTINENCE,
        input3Type: 'input',
        input3Value: '',

        input4Name: 'customField130',
        input4Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.LAST_EPISODE,
        input4Type: 'input',
        input4Value: '',

        input5Name: 'customFielde133',
        input5Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.AMOUNT_OF_DEBT_ACCUMULAATED,
        input5Type: 'input',
        input5Value: '',

        input6Name: 'customField136',
        input6Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.TIME_PER_DAY_OR_WEEK_SPENT_GAMBLING,
        input6Type: 'input',
        input6Value: '',
    },
    {
        id: 's73',
        section: 7,
        dataType: 'dropdownWithSixInput',
        dropDownName: 'customField58',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.ONLINE_GAMES_OR_APPS,
        options: ['C', 'D', 'H'],

        input1Name: 'customField122',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.ONSET,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField125',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.TREATMENT,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customField128',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.PERIODS_OF_ABSTINENCE,
        input3Type: 'input',
        input3Value: '',

        input4Name: 'customField131',
        input4Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.LAST_EPISODE,
        input4Type: 'input',
        input4Value: '',

        input5Name: 'customFielde134',
        input5Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.AMOUNT_OF_DEBT_ACCUMULAATED,
        input5Type: 'input',
        input5Value: '',

        input6Name: 'customField137',
        input6Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.TIME_PER_DAY_OR_WEEK_SPENT_GAMBLING,
        input6Type: 'input',
        input6Value: '',
    },
    {
        id: 's74',
        section: 7,
        dataType: 'input',
        name: 'customField138',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.DETAILS_SOBRIETY_LENGTH_PATTERNS,
        required: false,
        multiline: true,
        max_lines: 2,
    },
];

// section 8 - Sexual/relationship/love behaviors- ("C" for Current, "D" Denies or "H" for History)
export const section8Init = [
    {
        id: 's81',
        section: 8,
        dataType: 'dropdownWithFiveInput',
        dropDownName: 'customFieldList59',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.PORNOGRAPHY,
        options: ['C', 'D', 'H'],

        input1Name: 'customField139',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.ONSET,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField147',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.TREATMENT,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customField155',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.PERIODS_OF_ABSTINENCE,
        input3Type: 'input',
        input3Value: '',

        input4Name: 'customField163',
        input4Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.LAST_EPISODE,
        input4Type: 'input',
        input4Value: '',

        input5Name: 'customField171',
        input5Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.FIRED,
        input5Type: 'input',
        input5Value: '',
    },
    {
        id: 's82',
        section: 8,
        dataType: 'dropdownWithFiveInput',
        dropDownName: 'customFieldList60',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.EXCESSIVE_MASTURBATION,
        options: ['C', 'D', 'H'],

        input1Name: 'customField140',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.ONSET,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField148',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.TREATMENT,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customField156',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.PERIODS_OF_ABSTINENCE,
        input3Type: 'input',
        input3Value: '',

        input4Name: 'customField164',
        input4Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.LAST_EPISODE,
        input4Type: 'input',
        input4Value: '',

        input5Name: 'customField172',
        input5Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.FIRED,
        input5Type: 'input',
        input5Value: '',
    },
    {
        id: 's83',
        section: 8,
        dataType: 'dropdownWithFiveInput',
        dropDownName: 'customFieldList61',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.VOYEURISM_OR_EXHIBITIONIST,
        options: ['C', 'D', 'H'],

        input1Name: 'customField141',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.ONSET,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField149',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.TREATMENT,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customField157',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.PERIODS_OF_ABSTINENCE,
        input3Type: 'input',
        input3Value: '',

        input4Name: 'customField165',
        input4Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.LAST_EPISODE,
        input4Type: 'input',
        input4Value: '',

        input5Name: 'customField173',
        input5Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.FIRED,
        input5Type: 'input',
        input5Value: '',
    },
    {
        id: 's84',
        section: 8,
        dataType: 'dropdownWithFiveInput',
        dropDownName: 'customFieldList62',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.ANONYMOUS_SEX,
        options: ['C', 'D', 'H'],

        input1Name: 'customField142',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.ONSET,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField150',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.TREATMENT,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customField158',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.PERIODS_OF_ABSTINENCE,
        input3Type: 'input',
        input3Value: '',

        input4Name: 'customField166',
        input4Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.LAST_EPISODE,
        input4Type: 'input',
        input4Value: '',

        input5Name: 'customField174',
        input5Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.FIRED,
        input5Type: 'input',
        input5Value: '',
    },
    {
        id: 's85',
        section: 8,
        dataType: 'dropdownWithFiveInput',
        dropDownName: 'customFieldList63',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.PROSTITUTION,
        options: ['C', 'D', 'H'],

        input1Name: 'customField143',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.ONSET,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField151',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.TREATMENT,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customField159',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.PERIODS_OF_ABSTINENCE,
        input3Type: 'input',
        input3Value: '',

        input4Name: 'customField167',
        input4Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.LAST_EPISODE,
        input4Type: 'input',
        input4Value: '',

        input5Name: 'customField175',
        input5Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.FIRED,
        input5Type: 'input',
        input5Value: '',
    },
    {
        id: 's86',
        section: 8,
        dataType: 'dropdownWithFiveInput',
        dropDownName: 'customFieldList64',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.AFFAIRS,
        options: ['C', 'D', 'H'],

        input1Name: 'customField144',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.ONSET,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField152',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.TREATMENT,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customField160',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.PERIODS_OF_ABSTINENCE,
        input3Type: 'input',
        input3Value: '',

        input4Name: 'customField168',
        input4Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.LAST_EPISODE,
        input4Type: 'input',
        input4Value: '',

        input5Name: 'customField176',
        input5Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.FIRED,
        input5Type: 'input',
        input5Value: '',
    },
    {
        id: 's87',
        section: 8,
        dataType: 'dropdownWithFiveInput',
        dropDownName: 'customFieldList65',
        dropdownValue: '',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.SERIAL_RELATIONSHIP,
        options: ['C', 'D', 'H'],

        input1Name: 'customField145',
        input1Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.ONSET,
        input1Type: 'input',
        input1Value: '',

        input2Name: 'customField153',
        input2Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.TREATMENT,
        input2Type: 'input',
        input2Value: '',

        input3Name: 'customField161',
        input3Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.PERIODS_OF_ABSTINENCE,
        input3Type: 'input',
        input3Value: '',

        input4Name: 'customField169',
        input4Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.LAST_EPISODE,
        input4Type: 'input',
        input4Value: '',

        input5Name: 'customField177',
        input5Placeholder: getLocalizedStrings().label.OTHER_FORM_DATA.FIRED,
        input5Type: 'input',
        input5Value: '',
    },
    {
        id: 's88',
        section: 8,
        dataType: 'input',
        name: 'customField179',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.DETAILS_SOBRIETY_LENGTH_PATTERNS,
        required: false,
        multiline: true,
        max_lines: 2,
    },
];

// section 9 - Arrest history (including prior/current and pending)
export const section9Init = [
    {
        id: 's91',
        section: 9,
        dataType: 'input',
        name: 'customField180',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.INCLUDE_DATE_TEASON_OR_CHARGE,
        required: false,
        multiline: true,
        max_lines: 4,
    },
    {
        id: 's92',
        section: 9,
        dataType: 'input',
        name: 'customField181',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.OTHER_FORM_DATA.DETAILS_SOBRIETY_LENGTH_PATTERNS,
        required: false,
        multiline: true,
        max_lines: 2,
    },
];

export const headerText = {
    SECTION1: getLocalizedStrings().label.OTHER_FORM_DATA.CODEPENDENCY_AND_PROCESS_ADDITIONS_BEHAVIOURS,
    SECTION111: getLocalizedStrings().label.OTHER_FORM_DATA.RELATIONSHIP_AND_FAMILY_HISTORY,
    SECTION2: getLocalizedStrings().label.OTHER_FORM_DATA.SUBSTANCE_HISTORY,
    SECTION3: getLocalizedStrings().label.OTHER_FORM_DATA.SUBSTANCE_TREATMENT_HISTORY,
    SECTION4: getLocalizedStrings().label.OTHER_FORM_DATA.SUICIDIAL_IDEATION,
    SECTION5: getLocalizedStrings().label.OTHER_FORM_DATA.MENTAL_HEALTH_AND_PSHYATRATIC_DIAGNOSIS_HISTORY,
    SECTION6: getLocalizedStrings().label.OTHER_FORM_DATA.EATING_DISORDER_BEHAVIER,
    SECTION7: getLocalizedStrings().label.OTHER_FORM_DATA.GAMBLIMG_BEHAVIER,
    SECTION8: getLocalizedStrings().label.OTHER_FORM_DATA.SEXUAL_RELATIONSHIP_LOVE_BEHAVIOUR,
    SECTION9: getLocalizedStrings().label.OTHER_FORM_DATA.ARRESTED_HISTORY,
};
