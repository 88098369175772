import React, { useState, useEffect,useRef } from 'react';
import Button from '@mui/material/Button';
import { styles } from '../../../services/constants/styles';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction'
import { isValidParam, getStringParam } from '../../../services/helper/parameterVerifier';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { useDispatch, useSelector } from "react-redux";


const AddCampaignTypeDialog = () => {
    const inputRef = useRef(null);
    const dispatch = useDispatch();

    const appContainer = useSelector((state) => state.appContainer);

    const [campaignType, setCampaignType] = useState('');
    const [error, setError] = useState({
        campaignType: ''
    });

    useEffect(() => {
        dispatch(setAppDialogActions(getActions()));
    }, []);

    const getActions = () => {
        const actions = [
            <Button
                primary={'true'}
                onClick={() => handleSave()}
                style={styles.primaryButton}
            >{getLocalizedStrings().label.COMMON.SAVE}</Button>,
            <Button
                primary={'true'}
                onClick={() => handleClose()}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,

        ];
        return actions;
    }

    const handleChange = (event) => {
        let target = event.target;
        let value = target.value;
        setCampaignType(value) ;
        if (value.trim() !== '') {
            setError({...error,campaignType:''});
        }

    };

    const handleSave = () => {
        try {
            let campType = getStringParam(inputRef.current.getElementsByTagName("input")['campaignType'].value).trim();
            if (!isValidParam(campType) || campType === '') {
                let tempError = error;
                tempError.campaignType = getLocalizedStrings().message.COMMON.BLANK_CAMPAIGN_TYPE;
                setError({...tempError});
            } else {
                let params = { campaign_type: campType };
                const promise = Promise.resolve(HTTPClient.post(endPoints.COMMON.SAVE_CAMPAIGN_TYPE, params));

                promise.then((response) => {
                    if (isValidParam(response) && response.hasOwnProperty('data') && isValidParam(response.data)) {
                        if (response.data !== '' && response.data > 0) {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.CAMPAIGN_TYPE_SAVE_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            let obj = { id: response.data, name: campType };
                            appContainer.dialogProps.eventName(obj);
                            dispatch(getAppDialog(false, null, null, null, null, null));

                        } else {
                            if (response.data === 'exists') {
                                let tempError = error;
                                tempError.campaignType = getLocalizedStrings().message.COMMON.DUPLICATE_CAMPAIGN_TYPE;
                                setError(tempError);
                            }
                        }
                    }
                });
            }

        } catch (error) {
            console.log("Error in 'autoCampaignTypeDialog.js -> handleSave()': " + error);
        }
    }

    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    return (
        <div>
            <div>
                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        label={getLocalizedStrings().label.CAMPAIGNS.CAMPAIGN_TYPE}
                        fullWidth={true}
                        name="campaignType"
                        ref={inputRef}
                        value={campaignType}
                        onChange={(e) => handleChange(e)}
                        error={error.campaignType !== ""}
                        InputLabelProps={{
                            error: false
                        }}
                        helperText={error.campaignType}
                        inputprops={{ maxlength: '256' }}
                        autoComplete="new-password"
                        margin='dense'
                        size='small'
                        autoFocus={true}
                        className={"sf-fields-bg"}
                    />
                </FormControl>
            </div>
        </div>
    );
};

export default AddCampaignTypeDialog;