import { actions } from '..//constants/actions';

const appointmentInitialState = {
    mounted: false,
    data: []
}

const appointmentReducer = (state = appointmentInitialState, action) => {
    switch (action.type) {
        case actions.APPOINTMENT_GET_FULFILLED:
            return {
                ...state,
                mounted: true,
                data: action.payload
            }
        case actions.APPOINTMENT_DETAILVIEW_GET_FULFILLED:
            return {
                ...state,
                mounted: true,
                data: action.payload
            }
        case actions.APPOINTMENT_HOME_GET_FULFILLED:
            return {
                ...state,
                mounted: true,
                data: action.payload
            }
        case actions.CLEAR_APPOINTMENT_REDUCER:
            return appointmentInitialState

        default:
            return state;
    }
}

export default appointmentReducer;