export const constants = {

	/** Production **/
	endPoint: "https://apiv2.soffront.com/",
	APP_URL: '',
	snapshotUrl: 'https://srv1.snapshotcrm.com/homeFromReact.page?SFReact=true',
	webformUrl: 'https://srv1.snapshotcrm.com/getWebForm.page?SFReact=true',
	snapshotSubscriptionUrl:'https://srv1.snapshotcrm.com/RedirectUser.jsp', 
	snapshotOrderItemsUrl:'https://srv1.snapshotcrm.com/OrderItems.jsp',
	snapshotRegistrationUrlForInXpress: 'https://srv1.snapshotcrm.com/getstarted/Registration.jsp',
	snapshotRegistrationUrl: 'https://srv1.snapshotcrm.com/getstarted/subscribe.jsp',
	scaytCustomerId: '1:rKCPe3-lr4gT1-ZhWxb-djXYp1-CbsiV-IvOSq3-5Hvar1-UWWzM2-6O4yY3-bokgW2-RA7cK1-Li3',
	LANDING_PAGE_LOGIN_URL: 'https://pages.snapshotcrm.com/silent_login',
	LANDING_PAGE_SIGNUP_URL: 'https://pages.snapshotcrm.com/silent_signup',
	DOCUSIGN_REDIRECT_URI : 'https://apiv2.soffront.com/v3/docusign/getdocusignresponse',
	nodeServerURL: 'https://appeditor.soffront.com',
	CORPORATE_ACCOUNT_PROJECTID: 417,
	CORPORATE_ACCOUNT_PARTNER_WORKFLOW: 'Partner workflow',
	ONBOARDING_QUERY_ID: 6487,
	APP_MODE :'Production',
	webformJSLink :"https://s3.amazonaws.com/smb-webform/webform.process.js?v=",
	DOCUMENT_BUCKET: 'soffrontsmb-documents/production/',
	APPOINTMENT_SCHEDULER_URL: 'https://scheduler.soffront.com/#/',
	RECURLY_APP_URL: 'https://snapshot-crm.recurly.com/',
	LANDIND_PAGE_BASEPATH:'https://pages.soffront.com/#/',
	LANDIND_PAGE_KEY:'XGyC-8C-7hJA7bB5A2G3A2F2E2yQNDMIJg1IQNSEa1EUAi1XVFQd1EaG3C2A5A4C4E3C1D4E2H2==',
	BEE_EDITOR_CLIENT_ID:'46a1499a-b588-4b9e-9a88-ad3293d0ce5e',
	BEE_EDITOR_CLIENT_SECRET_ID:'lUMtAcGYbRdsZz86ZLg60ZK7o3di6MHLr37xZeis6IYPPHl5HEYr',

	/** Test Server **/
	   /*endPoint: "https://testapiv2vpc.soffront.com/",
 	   APP_URL: '',
	   snapshotUrl: 'https://testvpc.snapshotcrm.com/homeFromReact.page?SFReact=true',
	   webformUrl: 'https://testvpc.snapshotcrm.com/getWebForm.page?SFReact=true',
	   snapshotSubscriptionUrl:'https://testvpc.snapshotcrm.com/RedirectUser.jsp',
	   snapshotOrderItemsUrl:'https://testvpc.snapshotcrm.com/OrderItems.jsp',
	   snapshotRegistrationUrlForInXpress: 'https://testvpc.snapshotcrm.com/getstarted/Registration.jsp',
	   snapshotRegistrationUrl: 'https://testvpc.snapshotcrm.com/getstarted/subscribe.jsp',
	   scaytCustomerId: '1:rKCPe3-lr4gT1-ZhWxb-djXYp1-CbsiV-IvOSq3-5Hvar1-UWWzM2-6O4yY3-bokgW2-RA7cK1-Li3',
	   LANDING_PAGE_LOGIN_URL: 'https://testpages.snapshotcrm.com/silent_login',
	   LANDING_PAGE_SIGNUP_URL: 'https://testpages.snapshotcrm.com/silent_signup',
	   DOCUSIGN_REDIRECT_URI : 'https://testapiv2vpc.soffront.com/v3/docusign/getdocusignresponse',
	   nodeServerURL: 'https://apptesteditor.soffront.com',
	   CORPORATE_ACCOUNT_PROJECTID: 417,
	   APP_MODE :'Test',
	   webformJSLink : 'https://s3.amazonaws.com/smb-webform/webform.process.testserver.js?v=',
	   CORPORATE_ACCOUNT_PARTNER_WORKFLOW: 'Partner workflow',
	   ONBOARDING_QUERY_ID: 5666,
	   DOCUMENT_BUCKET: 'soffrontsmb-documents/testserver/',
	   APPOINTMENT_SCHEDULER_URL: 'https://schedulertest.soffront.com/#/',
	   RECURLY_APP_URL: 'https://soffronttestaccount.recurly.com/',
	   LANDIND_PAGE_BASEPATH:'https://testpages.soffront.com/#/',
	   LANDIND_PAGE_KEY:'XGyC-8C-7hJA7bB5A2G3A2F2E2yQNDMIJg1IQNSEa1EUAi1XVFQd1EaG3C2A5A4C4E3C1D4E2H2==',
	   BEE_EDITOR_CLIENT_ID:'fa011e55-530d-4f3f-a427-0328a3b68f08',
	   BEE_EDITOR_CLIENT_SECRET_ID:'GyjsyNotk9qfO578NLctIT1JnJ4GjsaCnCI6J7jNeDWutIZ0iTWp',*/

	/** Local **/
	// endPoint: "http://localhost:9080/sfapiv2/",
	// endPoint: "https://e7e3-2409-4061-2e0c-258e-3438-303f-fbf6-5a10.ngrok-free.app//sfapiv2/",
	// endPoint: "https://testapiv2vpc.soffront.com/",
	// endPoint: "https://apiv2.soffront.com/",
	/*APP_URL: '',
	snapshotUrl: 'http://localhost:8080/snapshot/homeFromReact.page?SFReact=true',
	webformUrl: 'http://localhost:8080/snapshot/getWebForm.page?SFReact=true',
	snapshotSubscriptionUrl: 'http://localhost:8080/snapshot/RedirectUser.jsp',
	snapshotOrderItemsUrl: 'http://localhost:8080/snapshot/OrderItems.jsp',
	snapshotRegistrationUrlForInXpress: 'http://localhost:8080/snapshot/getstarted/Registration.jsp',
	snapshotRegistrationUrl: 'http://localhost:8080/snapshot/getstarted/subscribe.jsp',
	scaytCustomerId: '1:WvF0D4-UtPqN1-43nkD4-NKvUm2-daQqk3-LmNiI-z7Ysb4-mwry24-T8YrS3-Q2tpq2',
	LANDING_PAGE_LOGIN_URL: 'http://sflaravel.com/silent_login',
	LANDING_PAGE_SIGNUP_URL: 'http://sflaravel.com/silent_signup',
	DOCUSIGN_REDIRECT_URI: 'http://localhost:9080/sfapi/v3/docusign/getdocusignresponse',
	nodeServerURL: 'http://localhost:5000',
	CORPORATE_ACCOUNT_PROJECTID: 3873,
	CORPORATE_ACCOUNT_PARTNER_WORKFLOW: 'Accounts workflow',
	ONBOARDING_QUERY_ID: 3485,
	DOCUMENT_BUCKET: 'soffrontsmb-documents/local/',
	APPOINTMENT_SCHEDULER_URL: 'http://localhost:3000/#/',
	RECURLY_APP_URL: 'https://helloapi.recurly.com/',
	LANDIND_PAGE_BASEPATH: 'http://localhost:3002/#/',
	LANDIND_PAGE_KEY: 'XGyC-8C-7hJA7bB5A2G3A2F2E2yQNDMIJg1IQNSEa1EUAi1XVFQd1EaG3C2A5A4C4E3C1D4E2H2==',
	BEE_EDITOR_CLIENT_ID: '0227b0fd-dfd3-47b5-ba1b-b8d77784ac37',
	BEE_EDITOR_CLIENT_SECRET_ID: 'p5mRHUTbudJmrUJlHGjxkBDBmN1yUYd8K8G5mtDuJJ3GrlbKkjIg',
	// APP_MODE :'Local',
	// webformJSLink : 'http://localhost:8080/snapshot/scripts/webform/webform.process.js?v=',*/
	
	MODULE_ID_AVAILABLE_MENU_ITEMS: 0,
	MODULE_AVAILABLE_MENU_ITEMS: 'Available Menu Items',

	MODULE_ID_SALES: 1,
	MODULE_SALES: 'Sales',

	MODULE_ID_MARKETING: 2,
	MODULE_MARKETING: 'Marketing',

	MODULE_ID_SERVICE: 3,
	MODULE_SERVICE: 'Service',

	MODULE_ID_OPERATIONS: 5,
	MODULE_OPERATIONS: 'Operations',

	MODULE_ID_HUB: 6,
	MODULE_HUB: 'HUB',

	MODULE_ID_SALES_MARKETING: 7,
	MODULE_SALES_MARKETING: 'Sales & Marketing', // Used for Contact Object 

	YEAR: '2023',
	WSTNEW: "https://support.soffront.com/knowledge-base/release-notes-aug-2024",
	HELP: "http://support.soffront.com/",

	SUBSCRIPTION_PLAN_TYPE_PROFESSIONAL: 3,

	DEFAULT_COOKIE_EXPIRES: 60,

	BEE_EDITOR_AUTH_API: 'https://auth.getbee.io/apiauth',

	GOOGLE_MAP_API_KEY: 'AIzaSyBQKPSqMt3mXPYlVD0ZHdOqhv3Q91zkXtY',
	FROALA_EDITOR_KEY: 'BWC6D-16D3C3E3F2E1A16B8wc2DBKSPJ1WKTUCQOd1OURPE1KDc1C-7J2A4D4C4C6E2E1F4E1I1==',

	INPUT_DATE_TIME_FORMAT: 'YYYY-MM-DD hh:mm:ss',
	INPUT_DATE_TIME_FORMAT_24: 'YYYY-MM-DD HH:mm:ss',
	INPUT_DATE_FORMAT: 'YYYY-MM-DD',

	RECORDSET_DELIMITER: "#@#@#@#@#@#@#@#@#@#",
	IMPORT_DELIMITER: "#@@#@@",
	SETUPVIEW_DELIMITER: "#@@#@@",
	GLOBALSEARCH_DELIMITER: "%%@@@##@@@##@@@%%",
	REPLACE_DELIMITER: "@@@@@@@@@@@@@@@@@",

	TYPE_DASHBOARD_STRING_QUERY: "query",
	TYPE_DASHBOARD_STRING_REPORT: "report",
	TYPE_DASHBOARD_STRING_CUSTOM: "custom",
	DASHBOARD: 'dashboard',
	NOTES: 'notes',
	NOTES_OBJECT: 'Notes',
	NOTE_PLAIN_TEXT: 'note',
	NOTE_OBJECTS: 'Note',
	NOTES_NAME: 'note_text',
	NOTES_FIELD_TYPE: '205',
	APPOINTMENTS: 'appointments',
	DETAILS_OBJECT: 'details',
	TASKS: 'tasks',
	UNIT_TASKS: 'unit-tasks',
	OPPORTUNITY_STAGE: 'Opportunity',
	EVENT_BRITE_OBJECT: 'eventsbrite',
	FDD:'FDD',
	FRANHCISE_AGREEMENT:'Franchise Agreement',
	AGREEMENT:'Agreement',

	MARKETING_TEMPLATE_OBJECT: 'marketing-templates',
	MARKETING_TEMPLATE: 'marketing_templates',
	WEB_FORMS: 'web-forms',
	CALENDAR_TASK: 'calendartask',
	CONTACTS_OBJECT: 'contacts',
	INTAKE_FORM: 'intake-form',
	AUTOMATION_DESIGNER_OBJECT: 'automation-designer',
	BROADCAST_CAMPAIGNS_OBJECT: 'broadcast-campaigns',
	TOUCH_CAMPAIGNS_OBJECT: 'touch-campaigns',
	AB_CAMPAIGNS_OBJECT: 'ab-campaigns',
	SOCIAL_CAMPAIGNS_OBJECT: 'social-campaigns',
	WEBSITE_TRACKING: 'website-tracking',
	LANDING_PAGES_OBJECT: 'landing-pages',

	TYPE_TASKS : 'task',
	TYPE_APPOINTMENTS : 'appointment',
	TYPE_EMAILS : 'email',
	TYPE_SMS: 'sms',

	INBOX_OBJECT:"inbox",
	SMS_TEMPLATE_OBJECT: 'sms-templates',
	SMS_TEMPLATE: 'sms_templates',
	SALES_TEMPLATE_OBJECT: 'sales-templates',
	AGREEMENT_TEMPLATE_FDD_OBJECT:'fdd',
	AGREEMENT_TEMPLATE_FRANCHISE_OBJECT:'franchise',
	AGREEMENT_TEMPLATE_OBJECT: 'agreement-templates',
	ACCOUNTS_OBJECT: 'accounts',
	OPPORTUNITIES_OBJECT: 'opportunities',
	SALES_OBJECT: 'sales',
	SERVICE_OBJECT: 'services',
	MARKETING_OBJECT: 'marketing',
	PHONE_TEMPLATE_OBJECT: 'phone-templates',
	CALL_SCRIPT_OBJECT: 'call-scripts',
	CALL_SCRIPT_TEMPLATE: 'call_scripts',
	

	ORDER_ITEMS_OBJECTS: 'orderitems',
	ORDERS_OBJECTS: 'orders',
	PETS_OBJECT: 'pets',
	CASES_OBJECT: 'cases',
	CASES: 'Cases',
	SOLUTIONS_OBJECT: 'solutions',
	PROJECTS_OBJECT: 'projects',
	PRODUCTS_OBJECT: 'products',
	ISSUES_OBJECT: 'issues',
	NOTE_OBJECT: 'note',
	GANTT_OBJECT: 'gantt',
	GROUP_OBJECT: 'groups',
	AUDIT_SCORE_DETAILS : 'Audit Score Details',
	INVOICES_OBJECT: 'invoices',
	ATTACHMENT_OBJECT: 'attachments',
	CUSTOM_TABLE1_OBJECT: 'customobjectone',
	CUSTOM_TABLE2_OBJECT: 'customobjecttwo',
	CUSTOM_TABLE3_OBJECT: 'customobjectthree',
	CUSTOM_TABLE4_OBJECT: 'customobjectfour',
	MESSAGE_DETAILS_OBJECT: 'msg_details',
	SOCIAL_MARKETING: 'social-marketing',
	ONLINE_REPUTATION: 'reputation',
	BUSINESS_LISTINGS: 'listings',
	LEAD_CAPTURE: 'lead-capture',
	EMAIL_MARKETING: 'email-marketing',
	SALES_MODULE_TEMPLATE: 'sales-module-template',
	HUB: 'hub',
	EMAIL_OBJECT: 'email',
	DOWNLOAD_LEADS_OBJECT: 'download-leads',
	SURVEY_RESPONSES:'surveyresponses',
	SMS_OBJECT: 'sms',
	EVENTS_OBJECT: 'events',
	UNITS_OBJECT: 'units',
	UNITS_BASE: 'Unit Base',
	ADD_UNIT_BASE:'Add Unit Base',
	ASSETS_OBJECT: 'assets',
	ONBOARDING_OBJECT: 'onboarding',
	COUPONS_OBJECT: 'coupons',
	CLAIMS_OBJECT: 'claims',
	NEWS_OBJECT: 'news',
	SALES_TEMPLATE: 'sales_templates',
	AGREEMENT_TEMPLATE: 'agreement_templates',
	OPERATIONS_ONBOARDING: 'operations-onboarding',
	OPERATIONS_ACTIVITIES: 'operations-activities',
	OPERATIONS_CONVERSIONS: 'operations-conversions',
	OPERATIONS_REPORTS: 'operations-reports',
	OPERATIONS_DASHBOARD: 'operations-dashboard',
	OPERATIONS_ASSETS_REPORTS: 'operations-assets-reports',
	CUSTOM_OPERATIONS_REPORTS: 'custom-operations-reports',
	SALES_EMAIL_REPORT: 'email-report',
	ACTIVITIES: 'activities',
	CONVERSIONS: 'conversions',
	TASKS_REPORT: 'Task Report',
	EMAIL_REPORT: 'Email Report',
	SMS_REPORT: 'SMS Report',
	SMS: 'SMS',
	PIPELINE_REPORT: 'Pipeline by Sales Rep',
	CONVERSION_REPORT: 'Conversion Report',
	DEALS_BY_SALES_REP: 'Deals by Sales Rep',
	CONVERSION_BY_SALES_REP:'Conversion By Sales Rep',
	CONVERSION_MATRIX:'Conversion Matrix',
	OPERATION_REPORT_ACTIVITIES: 'Activities',
	OPERATION_REPORT_CONVERSIONS: 'Conversions',
	OPERATION_REPORT_CAMPAIGNS: 'Campaigns',
	OPERATION_REPORT_ASSETS: 'Assets',
	ENABLE_MAILING: 'Enable Email Sending',
	INVOICE_RECIPIENTS: 'Invoice Recipients',
	SHARE_WITH_UNITS : "Share with",
	MARK_PAID_ALL: 'Mark All Paid',
	DISABLE_MAILING: 'Disable Email Sending',
	OPERATIONS_CAMPAIGNS: 'operations-campaigns',
	OPERATIONS_RECENT_CAMPAIGNS: 'operations-recent-campaigns',
	ASSIGNMENT_RULES: 'assignment-rules',
	COUPONS_CLAIM_REPORTS: 'coupons-reports',
	SALES_SUMMARY_REPORT:  'Pipeline Summary',
	ORTHOTIC_REPORT: 'Orthotic Report',
	RELATION_REPORT: 'Relation Report',
	/* Record owner constant for automation designer */
	CONSTANT_RECORD_OWNER: '[Record Owner]',
	MODULE_SETTING: 'module',
	SETTING: 'setting',
	SOURCE_OBJECT: 'source',
	LOCATION_OBJECT:'location',
	TASKSLIST_OBJECT:'taskinfo',
	NOTE_TYPES_OBJECT: 'notetypes',
	APPOINTMENT_TYPES_OBJECT: 'appointmenttypes',
	ROLL_UP_OBJECT: 'rollup',
	EMAIL_TO_CASE_SETUP: 'email-to-case-setup',
	DUPLICATES_SOURCE_NAME: 'duplicate source name',
	CAMPAIGN_TYPES_OBJECT: 'campaigntypes',
	CORPORATE_CURRENCY_OBJECT: 'corporatecurrency',
	SALES_LEADS_OBJECT: 'salesleads',
	RELATIONS_OBJECT: 'relations',
	WORKFLOW_OBJECT: 'workflows',
	RECYCLE_BIN_OBJECT: 'recyclebin',
	SCHEDULER_AVAILABILITY:'schedulerAvailability',
	SCHEDULER_APPOINTMENT_TYPES:'schedulerAppointmentTypes',
	SCHEDULER_INTAKE_FORM: 'schedulerIntakeForm',
	SCHEDULER_LINK: 'schedulerLink',
	USERS_OBJECT: 'users',
	LMS_USERS_OBJECT: 'lmsusers',
	SECURITY_LEVEL_OBJECT: 'securitylevel',
	EMAIL_INTEGRATION_OBJECT: 'emailintegration',
	GENERATE_API_KEY_OBJECT: 'generateapikey',
	LIBRARY_OBJECT: 'library',
	ASSIGNMENT_RULES_OBJECT: 'assignmentrules',
	CHANGE_LOGO_OBJECT: 'changelogo',	
	USEFUL_LINK_OBJECT: 'useful-link',
	USEFUL_LINK_OBJECT_CATEGORY: 'useful-link-category',
	LANDING_PAGES_FROALA_OBJECT: 'landing-pages-froala',
	MERGE_FIELD: 'mergeField',
	ASSETMENU_DIALOG: 'assetMenuDialog',
	RESTORE: 'Restore',
	DATABASE: 'database',
	LEAD_SCORES: 'leadscores',
	WHITE_LABEL: 'whitelabellink',
	SCHEDULING_OBJECT: 'scheduling',
	SALES_REPORT_OBJECT: 'sales-report',
	FIND_DUPLICATE_OBJECT: 'duplicate-search',
	WORKFLOW_DESIGNER_OBJECT: 'workflow-designer',
	QUOTATION_OBJECT: 'quotations',
	PAYMENTS_OBJECT: 'payments',
	AGREEMENT_OBJECT: 'agreement',
	MENU_CONFIGURATION_OBJECT: 'menu-configuration',
	PURCHASE_ORDER_OBJECT: 'purchaseorder',
	SALES_ORDER_OBJECT: 'salesorder',
	INVENTORY_OBJECT: 'inventory',
	JOBS_OBJECT: 'jobs',
	TIME_OBJECT: 'time',
	LINE_ITEMS_OBJECT: 'lineitems',
	OPERATIONS_ORDER_OBJECT: 'operations-orders',
	REPORTS_DASHBORD: 'reports-dashboard',
	MOBILE_MENU_CONFIGURATION_OBJECT: 'mobile_menu-configuration',
	PORTAL_MENU_CONFIGURATION_OBJECT: 'portal_menu-configuration',
	DUPLICATE_RULE_OBJECT: 'duplicate-rule',
	ROYALTY_SETUP_OBJECT: 'royalty_setup',
	ROYALTY_REPORT_SETUP_OBJECT: 'royalty_report_setup',
	UNIT_CRM_OBJECT: 'unit_crm',
	COMPLIANCE_SETUP : 'compliance_setup',
	LMS_SETUP : 'lms-setup',
	LMS_OBJECT:'lms',
	LMS_TRAINING_MENU_ITEM:'lms-training',
	REPORTED_SALES_SETUP: 'reported_sales_setup',
	AUDIT_QUESTIONS_OBJECT: 'audit_questions',
	AUDIT_ANSWERS_OBJECT: 'audit_answers',
	AUDIT_ANSWER_OBJECT:'audit-answers',
	LMS_MENU_OBJECT:'lms_training',
	AUDITS_OBJECT: 'audits',
	COMPLIANCE_OBJECT: 'compliance',
	REPORTED_SALES_OBJECT: 'reportedsales',
	REFUND_OBJECT: 'refund',
	ROYALTY: 'royalty',
	ROYALTY_PLANS_OBJECT: 'royaltyplan',
	ROYALTY_RUN_OBJECT: 'royaltyrun',
	/** Used for Profile Settings */
	SPREADSHEET: 'spreadsheet',
	JOBSVIEW: 'jobsview',
	PROFILE_DETAILS: 'Profile Details',
	ASSETS: 'Assets',
	COUPONS: 'coupons',
	OFFERS: 'offers',
	CLAIMS: 'claims',
	ROYALTY_OBJECT: 'royalties',
	DRILL_DOWN_OBJECT: 'drilldown',
	SAVE_AND_SCHEDULE: 'save & schedule',
	OMW: 'OMW',
	PHONE_NUMBERS_OBJECT: 'phonenumbers',	
	/********************************************************/
	/* Record Actions
	/********************************************************/
	SF_FORM: 'SFForm',
	ADD_NOTES: 'Add Notes',
	CONVERT: 'Convert',
	PLAN: 'Plan',
	AUDIT_REPORT :'audit-report',
	AUDIT_REPORT_DEATILS :'audit-report-details',
	AUDIT_TASK_REPORT_DETAILS:'audit-task-report-details',
	AUDIT_WEAKNESS_REPORT:'audit-weakness-report',
	AUDIT_PERFORMANCE_REPORT:'audit-performance-report',
	GREENLIGHT: 'Greenlight',
	CONVERT_TO_ACCOUNTS: 'Convert to Accounts',
	ASSIGN: 'Assign',
	SHARE: 'Share',
	ADD_TO_GROUP: 'Add to Group',
	PUSH: 'Push',
	SEND_EMAIL: 'Send Email',
	EMAIL: 'Email',
	EMAIL_INVOICE: 'Email Invoice',
	EMAIL_TO_UNIT: 'Email to Unit',
	SEND_SMS: 'SEND_BATCH_SMS',
	ADD_BATCH_TASK:'ADD_TASK',
	SEND_TEXT: 'Send SMS',
	EDIT: 'Edit',
	MINI_EDIT: 'Mini Edit',
	BATCH_EDIT: 'Batch Edit',
	BATCH_CONVERT: 'Batch Convert',
	MERGE: 'Merge',
	MAP: 'Map',
	CALL: 'Call',
	POWER_CALLING: 'powercall',
	GANTT_CHART: 'ganttchart',
	TASK_LIST: 'tasklist',
	SAVE_NOTES: 'Save Notes',
	SEARCH_KB: 'Search KB',
	VERIFY_EMAIL: 'Verify Email',
	GENERATE_INVOICE: 'Generate Invoice',
	ADD_PAYMENT: 'Add Payment',
	ADVANCE_SEARCH_DATE_DIALOG:'ADVANCE_SEARCH_DATE_DIALOG',
	TO_EXCEL: 'To Excel',
	PRINT: 'Print',
	RESCHEDULE: 'Reschedule',
	NOTE: 'To Excel',
	INACTIVE: 'Inactive',
	SALES_AND_REFUND_DETAIL:'Sales and Refund Details',
	SALES_AND_REFUND_DETAILS:'sales and refund details',
	SALES_REFUND :'sales-refund',
	TASK_REPORT:'task-report',
	CORRECTIVE_ACTIONS_REPORT_DETAILS:'corrective_actions_task_report',
	AUDIT_WEAKNESS:'audit-weakness',
	AUDIT_PERFORMANCE_REPORT:'audit-performance-report',
	REPORT_DASHBOARD:'data-summury-report',
	CORRECTIVE_ACTION_DETAILS:'correactive_action_details',
	ACTIVE: 'Active',
	UNPUBLISH: 'Unpublish',
	PUBLISH: 'Publish',
	COMPLETE: 'Complete',
	COMPLETE_NEW : 'Complete & new',
	DAY: '+ Day',
	WEEK: '+ Week',
	MONTH: '+ Month',
	AUDIT: 'See Field History',
	COPY: 'Copy',
	NEW: 'New',
	DELETE: 'Delete',
	UNLINK: 'Unlink',
	EMPTY: 'Empty',
	DOWNLOAD: 'Download',
	LEAD_SCORE_HISTORY: 'Lead Score History',
	DASHBOARD_EMAIL_SUMMERY_DATA: 'dashboard email summery data',
	DASHBOARD_TOP_MARKETING_EMAIL_BY_CTR: 'Top marketing email by CTR',
	RELATIONSHIP: 'Relationship',
	CONNECT: 'Connect',
	DISCONNECT: 'Disconnect',
	FILTER: 'filter',
	UNSUBSCRIBE: 'Unsubscribe',
	EMAIL_DETAILS: 'Email Details',
	SMS_DETAILS: 'SMS Details',
	DOCUSIGN: 'DocuSign',
	SEND_DOCUSIGN: 'Send DocuSign',
	REPORT_SCHEDULE_DIALOG: 'ReportScheduleDialog',
	MY_INTEGRATIONS: 'Integrations',
	CALL_SCRIPT: 'Call Script',
	USER_TEMPLATE_FILTER: 2,
	NOTIFICATION_REMINDERS: 'Notification',
	ROLL_UP: 'Roll-up',
	USEFUL_LINK: 'useful-link',
	LOGIN: 'Login',
	HUB_CASE_CLOSE: 'hub-case-close',
	WORKFLOW_SMS: 'Workflow SMS',
	SCHEDULE_SMS: 'Schedule SMS',
	WORKFLOW_DIALOG: 'Workflow Dialog',
	SCHEDULER: 'scheduler',
	JOB_SCHEDULER: 'jobscheduler',
	JOB_RESCHEDULER: 'jobsrecheduler',
	PUBLISHED: 'Published',
	UNPUBLISHED: 'Unpublished',
	ADD_QUICKBOOKS: 'Add to Quickbooks',
	ADD_XERO: 'Add to Xero',
	PUSH_TO_QUICKBOOKS: 'Push to QB',
	QUEUE: 'Queue',
	NEXT: 'Next',
	PREVIOUS: 'Previous',
	REMOVE: 'Remove',
	CLOSE: 'Close',
	CANCEL: 'Cancel',
	TASK_APP_OPEN: 'All Open',
	TASK_APP_DONE: 'All Done',
	TASK_APP_MY_OPEN: 'My Open',
	TASK_APP_MY_DONE: 'My Done',
	VIEW: 'View',
	PHOTO_VIEW: 'photoview',
	ROYALTY_REPORT_OBJECT: 'royalty-report',
	ROYALTY_REPORT_OBJECT_V2: 'royalty-report-v2',
	ROYALTY_REPORT: 'Royalty Report',
	COMPLIANCE_REPORT_OBJECT: 'compliance_report',
	COMPLIANCE_REPORT_OBJ: 'compliance-report',
	COMPLIANCE_REPORT: 'Compliance Report',
	LEASE_NUMBER: 'lease number',
	BRING_YOUR_OWN: 'bring your own',
	IMPORT_NUMBER: 'import number',
	PHONE_NUMBER_SETUP: 'phonenumber-setup',
	TWILIO_NUMBER_VERIFICATION: 'twilio-number-verification',
	TO_XMS: 'To XMS',
	DETAIL:'detail',
	LIST:'list',
	COLUMN:'column',
	ADD_TASK: 'Add Task',
	INVOICE: 'Invoice',
	COLLECT: 'Collect',
	POST: 'Post',
	PLAN_NAME: 'Add To Plan',
	LOGIN_TO_BASE:'Login to Base',
	EXCEPTION: 'Royalty Exceptions',
	ADD_TO_PLAN:'Add To Plan',
	LEASED_NUMBER_NOTIFICATION: 'Notification',
	NEEDS_APPROVAL:'needs_approval',
	
	
	//TO_QUICKBOOKS: 'To Quickbooks',

	/********************************************************/
	/* Sales Templates
	/********************************************************/
	SALES_TEMPLATE_CREATE_TAB_LABEL: 'Create Sales Template',
	SALES_TEMPLATE_EDIT_TAB_LABEL: 'Edit Sales Template',
	SALES_TEMPLATE_COPY_TAB_LABEL: 'Copy Sales Template',
	
	/********************************************************/
	/* waveForm
	/********************************************************/
	Large :'Large(75%)',
	COMMENTS : 'comments',
	FIRST_NAME:'first_name',
	COMPANY :'company',


	/* Docusign type */
	DOCUSIGN_TYPE: 'Docusign',
	/********************************************************/
	/* Agreement Template
	/********************************************************/
	AGREEMENT_TEMPLATE_CREATE_TAB_LABEL: 'Create Agreement Template',
	AGREEMENT_TEMPLATE_EDIT_TAB_LABEL: 'Edit Agreement Template',
	AGREEMENT_TEMPLATE_COPY_TAB_LABEL: 'Copy Agreement Template',

	/********************************************************/
	/* Security Role
	/********************************************************/
	SECURITY_ROLE_ADMIN: 'Admin',
	SECURITY_ROLE_MANAGER: 'Manager',
	SECURITY_ROLE_REGIONAL_USERS: 'Regional Users',


	/********************************************************/
	/* Marketing Templates
	/********************************************************/
	MARKETING_TEMPLATE_CREATE_TAB_LABEL: 'Create Marketing Template',
	MARKETING_TEMPLATE_EDIT_TAB_LABEL: 'Edit Marketing Template',
	MARKETING_TEMPLATE_COPY_TAB_LABEL: 'Copy Marketing Template',
	DOWNLOAD_LEADS: 'download_leads',

	
	SEND_MAIL_EDITOR_TOOLBAR_CONFIGURATION:
	{
		moreText: {
			buttons: ['bold', 'italic', 'underline', "strikeThrough","clearFormatting"],
			buttonsVisible: 0,
		},
		moreParagraph: {
			buttons: ['formatUL', 'formatOL', "paragraphFormat", "align", "outdent", "indent"],
			buttonsVisible: 0,
		},
		font: {
			buttons: ["fontFamily", "fontSize", "textColor",'undo', 'redo'],
			buttonsVisible: 5,
		},
		insertButtons: {
			buttons: ['specialCharacters',"insertLink", "library", "insertYouTubeVideo", "insertWistiaVideo"],
			buttonsVisible: 5,
		},
		customButtons: {
			buttons: ["signature", "coupon", "scheduler-editor", "survay_link"],
			buttonsVisible: 5,
		},
		// moreMisc: {
		// 	buttons: ['undo', 'redo',"clearFormatting",'fullscreen' ],
		// 	align: 'right',
		// 	buttonsVisible: 4,
		// },
	},


	NEWS_EDITOR_TOOLBAR_CONFIGURATION:{
		moreText: {
			buttons: ['bold', 'italic', 'underline', "strikeThrough","clearFormatting"],
			buttonsVisible: 0,
		},
		moreParagraph: {
			buttons: ['formatUL', 'formatOL', "paragraphFormat", "align", "outdent", "indent"],
			buttonsVisible: 0,
		},
		font: {
			buttons: ["fontFamily", "fontSize", "textColor",'undo', 'redo'],
			buttonsVisible: 5,
		},
		insertButtons: {
			buttons: ['specialCharacters',"insertLink", "library", "insertYouTubeVideo", "insertWistiaVideo"],
			buttonsVisible: 5,
		},
		moreMisc: {
			buttons: ["html"],
			buttonsVisible: 1,
		},
	},

	EDITOR_TOOLBAR_CONFIGURATION: {
		moreText: {
			buttons: ['bold', 'italic', 'underline', "strikeThrough","clearFormatting"],
			buttonsVisible: 0,
		},
		moreParagraph: {
			buttons: ['formatUL', 'formatOL', "paragraphFormat", "align", "outdent", "indent"],
			buttonsVisible: 0,
		},
		font: {
			buttons: ["fontFamily", "fontSize", "textColor",'undo', 'redo'],
			buttonsVisible: 5,
		},
		insertButtons: {
			buttons: ['specialCharacters',"insertLink", "library", "insertYouTubeVideo", "insertWistiaVideo","insertHR" ],
			buttonsVisible: 6,
		},
		customButtons: {
			buttons: ["object_dropdown","personalize_dropdown","signature", "scheduler-editor", "survay_link"],
			buttonsVisible: 6,
		},
		moreMisc: {
			buttons: ["html","expand_editor"],
			buttonsVisible: 0,
		},
	},
	AGREEMENT_EDITOR_TOOLBAR_CONFIGURATION:{
		moreText: {
			buttons: ['bold', 'italic', 'underline', "strikeThrough","clearFormatting"],
			buttonsVisible: 0,
		},
		moreParagraph: {
			buttons: ['formatUL', 'formatOL', "paragraphFormat", "align", "outdent", "indent"],
			buttonsVisible: 0,
		},
		font: {
			buttons: ["fontFamily", "fontSize", "textColor",'undo', 'redo'],
			buttonsVisible: 5,
		},
		insertButtons: {
			buttons: ['specialCharacters',"insertLink", "library", "insertYouTubeVideo", "insertWistiaVideo","insertHR" ],
			buttonsVisible: 6,
		},
		customButtons: {
            buttons: ["personalize_dropdown", "current_date_tag", "signature_tag"],
            buttonsVisible: 3,
        },
		moreMisc: {
			buttons: ["html","expand_editor"],
			buttonsVisible: 0,
		},
	},

	MARKETING_TEMPLATE_EDITOR_TOOLBAR_CONFIGURATION:
	{
		moreText: {
			buttons: ['bold', 'italic', 'underline', "strikeThrough","clearFormatting"],
			buttonsVisible: 0,
		},
		moreParagraph: {
			buttons: ['formatUL', 'formatOL', "paragraphFormat", "align", "outdent", "indent"],
			buttonsVisible: 0,
		},
		font: {
			buttons: ["fontFamily", "fontSize", "textColor",'undo', 'redo'],
			buttonsVisible: 5,
		},
		insertButtons: {
			buttons: ['specialCharacters',"insertLink", "library", "insertYouTubeVideo", "insertWistiaVideo","insertHR" ],
			buttonsVisible: 6,
		},
		customButtons: {
			buttons: ["personalize_dropdown","signature","coupon","scheduler-editor"],
			buttonsVisible: 4,
		},
		moreMisc: {
			buttons: ["html","expand_editor"],
			buttonsVisible: 0,
		},
	},

	AUTOMATION_ADD_MESSAGE_EDITOR_TOOLBAR_CONFIGURATION:
	{
		moreText: {
			buttons: ['bold', 'italic', 'underline', "strikeThrough","clearFormatting"],
			buttonsVisible: 0,
		},
		moreParagraph: {
			buttons: ['formatUL', 'formatOL', "paragraphFormat", "align", "outdent", "indent"],
			buttonsVisible: 0,
		},
		font: {
			buttons: ["fontFamily", "fontSize", "textColor",'undo', 'redo'],
			buttonsVisible: 5,
		},
		insertButtons: {
			buttons: ['specialCharacters',"insertLink", "library", "insertYouTubeVideo", "insertWistiaVideo","insertHR" ],
			buttonsVisible: 6,
		},
		customButtons: {
			buttons: ["personalize_dropdown","signature","coupon","scheduler-editor"],
			buttonsVisible: 4,
		},
		moreMisc: {
			buttons: ["html","expand_editor"],
			buttonsVisible: 0,
		},
	},

	PROFILE_EDITOR_TOOLBAR_CONFIGURATION: 
	{
		moreText: {
			buttons: ['bold', 'italic', 'underline', "strikeThrough","clearFormatting"],
			buttonsVisible: 0,
		},
		moreParagraph: {
			buttons: ['formatUL', 'formatOL', "paragraphFormat", "align", "outdent", "indent"],
			buttonsVisible: 0,
		},
		font: {
			buttons: ["fontFamily", "fontSize", "textColor",'undo', 'redo'],
			buttonsVisible: 5,
		},
		insertButtons: {
			buttons: ['specialCharacters',"insertLink", "library" ],
			buttonsVisible: 3,
		},
		customButtons: {
			buttons: ["scheduler-editor"],
			buttonsVisible: 1,
		}
	},

	EDITOR_TOOLBAR_BACKGROUND_COLOR_PALETTE: [
		'#800000', '#8B4513', '#2F4F4F', '#008080', '#000080', '#4B0082', '#000', '#B22222', '#A52A2A',
		'#DAA520', '#006400', '#40E0D0', '#0000CD', '#800080', '#808080', '#F00', '#FF8C00', '#FFD700',
		'#008000', '#0FF', '#00F', '#EE82EE', '#A9A9A9', '#FFA07A', '#FFA500', '#FFFF00', '#00FF00',
		'#AFEEEE', '#ADD8E6', '#DDA0DD', '#D3D3D3', '#FFF0F5', '#FAEBD7', '#FFFFE0', '#F0FFF0', '#F0FFFF',
		'#F0F8FF', '#E6E6FA', '#FFF', '#CF3025', '#00548F', '#909093', '#065361', '#e6d384', '#b9a030',
		'REMOVE'
	],
	EDITOR_TOOLBAR_TEXT_COLOR_PALETTE: [
		'#800000', '#8B4513', '#2F4F4F', '#008080', '#000080', '#4B0082', '#000', '#B22222', '#A52A2A',
		'#DAA520', '#006400', '#40E0D0', '#0000CD', '#800080', '#808080', '#F00', '#FF8C00', '#FFD700',
		'#008000', '#0FF', '#00F', '#EE82EE', '#A9A9A9', '#FFA07A', '#FFA500', '#FFFF00', '#00FF00',
		'#AFEEEE', '#ADD8E6', '#DDA0DD', '#D3D3D3', '#FFF0F5', '#FAEBD7', '#FFFFE0', '#F0FFF0', '#F0FFFF',
		'#F0F8FF', '#E6E6FA', '#FFF', '#CF3025', '#00548F', '#909093', '#065361', '#e6d384', '#b9a030',
		'REMOVE'
	],
	BROADCAST_CAMPAIGN_CREATE_TAB_LABEL: 'Create Broadcast Campaign',
	BROADCAST_CAMPAIGN_EDIT_TAB_LABEL: 'Edit Broadcast Campaign',
	BROADCAST_CAMPAIGN_COPY_TAB_LABEL: 'Copy Broadcast Campaign',

	SOCIAL_CAMPAIGN_CREATE_TAB_LABEL: 'Create Social Campaign',
	SOCIAL_CAMPAIGN_EDIT_TAB_LABEL: 'Edit Social Campaign',
	SOCIAL_CAMPAIGN_COPY_TAB_LABEL: 'Copy Social Campaign',

	AB_CAMPAIGN_CREATE_TAB_LABEL: 'Create A/B Campaign',
	AB_CAMPAIGN_EDIT_TAB_LABEL: 'Edit A/B Campaign',
	AB_CAMPAIGN_COPY_TAB_LABEL: 'Copy A/B Campaign',

	WEB_FORM_CREATE_TAB_LABEL: 'Create Web Forms',
	WEB_FORM_EDIT_TAB_LABEL: 'Edit Web Forms',
	WEB_FORM_COPY_TAB_LABEL: 'Copy Web Forms',

	AUTOMATION_CAMPAIGN_CREATE_TAB_LABEL: 'New Automated Campaign',
	AUTOMATION_CAMPAIGN_EDIT_TAB_LABEL: 'Edit A/B Campaign',
	AUTOMATION_CAMPAIGN_COPY_TAB_LABEL: 'Copy A/B Campaign',

	/********************************************************/
	/* Constant query id(s)
	/********************************************************/
	MY_TODAYS_TASK: 1999,
	TODAYS_APPOINTMENTS: 1994,

	/********************************************************/
	/* Form field type
	/********************************************************/
	FIELD_TYPE_HEADER: 'header',
	FIELD_TYPE_TEXT: 'text',
	FIELD_TYPE_INTEGER: 'integer',
	FIELD_TYPE_FLOAT: 'float',
	FIELD_TYPE_DATE: 'date',
	FIELD_TYPE_DATE_TIME: 'datetime',
	FIELD_TYPE_URL: 'url',
	FIELD_TYPE_MEMO: 'memo',
	FIELD_TYPE_LIST: 'list',
	LIST_FIELD_TYPE_REGULAR: 'regular',
	LIST_FIELD_TYPE_MULTI_SELECT: 'multi-select',
	LIST_FIELD_TYPE_USER: 'user',
	LIST_FIELD_TYPE_EXTERNAL: 'external',
	FIELD_TYPE_LIST_INTEGER: 'list-integer-type',
	FIELD_TYPE_CHECKBOX: 'checkbox',

	SF_FORM_OPEN_MODE_QUICK: 'QUICK',
	SF_FORM_OPEN_MODE_EXPANDED: 'EXPANDED',
	SF_FORM_OPEN_MODE_CUSTOM: 'CUSTOM',

	LIST_VIEW: 'Listview',
	COLUMN_VIEW: 'columnview',
	DETAIL_VIEW: 'Detailview',
	OTHER: 'Other',
	COLUMNVIEW_DRAGDROP: 'ColumnviewDragDrop',
	AUTOMATION_DESIGNER: 'Automation',
	TIMELINE_VIEW: 'timelineview',

	TEMPLATE_DIALOG: 'TemplateDialog',
	TEMPLATE_DIALOG_TITLE: 'Select a Template',
	VERIFY_EMAIL_DIALOG: 'VerifyEmailDialog',
	SCHEDULE_DIALOG: 'ScheduleDialog',
	SMS_SCHEDULE_DIALOG: 'SMSScheduleDialog',
	SMS_CONTACT_PICKING_DIALOG:'pick a contact',
	SMS_CONFIRM_SCHEDULE_DIALOG: 'SMSConfirmScheduleDialog',
	AUTOMATION_SCHEDULE_DIALOG: 'AutomationScheduleDialog',
	RESCHEDULE_DIALOG: 'RescheduleDialog',
	CONFIRM_SCHEDULE_DIALOG: 'ConfirmScheduleDialog',
	EXECUTE_DIALOG: 'ExecuteDialog',
	ADD_GROUP_DIALOG: 'AddGroupDialog',
	ADD_CAMPAIGN_TYPE_DIALOG: 'AddCampaignTypeDialog',
	SELECT_UNIT: 'Select Unit',
	SELECT_ACCOUNT:'Select Account',
	ADD_BRANCH:'Add Branch',
	ADD_BASE:'Add Base',
	REPEAT_DIALOG: 'RepeatDialog',
	RECURRING_EDIT_CONFIRM_DIALOG: 'RecurringEditConfirmDialog',
	RECURRING_DELETE_DIALOG: 'RecurringDeleteDialog',
	LIST_VIEW_LOGO_UPLOAD_DIALOG: 'ListViewLogoUploadDialog',
	DETAIL_VIEW_LOGO_UPLOAD_DIALOG: 'DetailViewLogoUploadDialog',
	LISTVIEW_ATTACHMENT_UPLOAD_DIALOG: 'listviewAttachmentUploadDialog',
	LOOKUP_DIALOG: 'LookupDialog',
	ALERT_DIALOG: 'AlertDialog',
	SF_ALERT_DIALOG: 'SFAlertDialog',
	TEST_MAIL_DIALOG: 'TestMailDialog',
	SMS_MESSAGE_DIALOG : 'SmsMessageDialog',
	AUTOMATION_TEST_MAIL_DIALOG: 'AutomationTestMailDialog',
	SEND_MAIL_ATTACHMENT_UPLOAD_DIALOG: 'sendMailAttachmentUploadDialog',
	LIBRARY_DIALOG: 'LibraryDialog',
	LEAD_SCORE_HISTORY_HEADER: ['Date', 'Action Name', 'Score', 'Score Details'],
	PROFILE_DETAILS_LOGO_UPLOAD_DIALOG: 'ProfileDetailsLogoUploadDialog',
	VIDEO_INSERT_DIALOG: 'VideoInsertDialog',
	LIST_VIEW_DATE_PICKER: 'ListViewDatePicker',
	EDITOR_SELECTION_DIALOG: 'EditorSelectionDialog',
	EDITOR_TEMPLATE_DIALOG: 'EditorTemplateDialog',
	DOCUMENT_PUSH_DIALOG: 'DocumentPushDialog',
	XMS_PUSH_DIALOG:'XmsPushDialog',
	REJECTION_REASION_DIALOG:'RejectionReasonDialog',
	DOCUMENT_UPLOAD_DIALOG: 'DocumentUploadDialog',
	ASSETS_FOLDER_ADD_DIALOG: 'AssetsFolderAddDialog',
	CHANGE_LOGO_UPLOAD_DIALOG: 'ChangeLogoUploadDialog',
	NEWS_IMAGE_UPLOAD_DIALOG: 'NewsImageUploadDialog',
	WORKFLOW_TEMPLATE_SELECTION_DIALOG: 'WorkflowTemplateSelectionDialog',
	LEADSTORE_DOWNLOAD_DIALOG: 'LeadstoreDownloadDialog',
	LANDING_PAGE_FORM_BUILDER_DIALOG: 'LandingPageFormBuilderDialog',
	CAMPAIGN_QUALIFIED_CONTACTS: 'QualifiedContacts',
	DOCUMENT_SHARE_DIALOG: 'DocumentShareDialog',
	EXTERNAL_LISTS_DIALOG: 'externalLinkDialog',
	
	//--------------SHARE WITH FIELDS NAME----------------//
	SHARE_WITH: 'visible_to',


	AUDIT_CATEGORY_TYPE_ADD_DIALOG: 'auditCategoryTypeAdd',

	/********************************************************/
	/* Automation Designer action_names
	/********************************************************/

	AUTOMATION_DESIGNER_START: 'START',
	AUTOMATION_DESIGNER_SEND_MAIL: 'SEND_MAIL',
	AUTOMATION_DESIGNER_WEB_FORM: 'WEB_FORM',
	AUTOMATION_DESIGNER_LANDING_PAGE: 'LANDING_PAGE',
	AUTOMATION_DESIGNER_WEBSITE_VISIT: 'WEBSITE_VISIT',
	AUTOMATION_DESIGNER_SMS_CAMPAIGN: 'SMS_CAMPAIGN',

	AUTOMATION_DESIGNER_LINK_CLICK: 'LINK_CLICK',
	AUTOMATION_DESIGNER_EMAIL_OPEN: 'EMAIL_OPEN',
	AUTOMATION_DESIGNER_UNSUBSCRIBE: 'UNSUBSCRIBE',
	AUTOMATION_DESIGNER_WEB_FORM_SUBMIT: 'WEB_FORM_SUBMIT',
	AUTOMATION_DESIGNER_ADD_MESSAGE: 'ADD_MESSAGE',
	AUTOMATION_DESIGNER_SCORE_REACHED: 'SCORE_REACHED',
	AUTOMATION_DESIGNER_VISITED_WEBSITE: 'VISITED_WEBSITE',

	AUTOMATION_DESIGNER_AUTO_MAIL: 'AUTO_MAIL',
	AUTOMATION_DESIGNER_CHANGE_GROUP: 'CHANGE_GROUP',
	AUTOMATION_DESIGNER_ADD_NOTE: 'ADD_NOTE',
	AUTOMATION_DESIGNER_NOTIFY: 'NOTIFY',
	AUTOMATION_DESIGNER_ADD_TASK: 'ADD_TASK',
	AUTOMATION_DESIGNER_UPDATE_FIELD: 'UPDATE_FIELD',
	AUTOMATION_DESIGNER_ASSIGN: 'ASSIGN',
	AUTOMATION_DESIGNER_CONVERT: 'CONVERT',

	AUTOMATION_DESIGNER_WAIT: 'WAIT',
	AUTOMATION_DESIGNER_BIRTHDAY_MAIL: 'BIRTHDAY_MAIL',
	AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE: 'BIRTHDAY_MESSAGE',

	AUTOMATION_DESIGNER_SMS: 'SMS',
	AUTOMATION_DESIGNER_SMS_REPLY: 'SMS_REPLY',
	AUTOMATION_DESIGNER_SEND_SMS: 'SEND_SMS',

	AUTOMATION_DESIGNER_ADD_SMS: 'ADD_SMS',

	/********************************************************/
	/* Automation Designer status
	/********************************************************/
	AUTOMATION_DESIGNER_STATUS_ACTIVE: 'Active',
	AUTOMATION_DESIGNER_STATUS_RUNNING: 'Running',
	AUTOMATION_DESIGNER_STATUS_STOPPED: 'Stopped',

	AUTOMATION_DESIGNER_CONSTANT_COPY_OF: 'Copy of',

	/********************************************************/
	/* Automation Designer actions dialog call from
	/********************************************************/
	AUTOMATION_DESIGNER_SIDE_MENU: 'AUTOMATION_DESIGNER_SIDE_MENU',
	AUTOMATION_DESIGNER_CANVAS: 'AUTOMATION_DESIGNER_CANVAS',

	/********************************************************/
	/* Automation Designer decission constants
	/********************************************************/
	AUTOMATION_DESIGNER_DECISION_LINK_CLICKED: 'Link Clicked',
	AUTOMATION_DESIGNER_DECISION_EMAIL_OPENED: 'Opened',
	AUTOMATION_DESIGNER_DECISION_UNSUBSCRIBED: 'Unsubscribed',
	AUTOMATION_DESIGNER_DECISION_WEBFORM_SUBMITTED: 'Webform Submitted',
	AUTOMATION_DESIGNER_DECISION_LANDING_PAGE_SUBMITTED: 'LandingPage Submitted',

	/********************************************************/
	/* Automation Designer actiontype
	/********************************************************/
	AUTOMATION_DESIGNER_ACTIONTYPE_ONE: 1,
	AUTOMATION_DESIGNER_ACTIONTYPE_TWO: 2,
	AUTOMATION_DESIGNER_ACTIONTYPE_THREE: 3,

	/********************************************************/
	/* Automation Designer applied when
	/********************************************************/
	AUTOMATION_APPLIEDWHEN_WEB_FORM_SUBMIT: 'WEB_FORM_SUBMIT',
	AUTOMATION_APPLIEDWHEN_VISITED_WEBSITE: 'VISITED_WEBSITE',
	AUTOMATION_APPLIEDWHEN_LANDING_PAGE_SUBMIT: 'LANDING_PAGE_SUBMIT',
	AUTOMATION_APPLIEDWHEN_DELIVERED: 'DELIVERED',

	/********************************************************/
	/* Automation Designer action names for notify
	/********************************************************/
	ACTION_NOTIFY_SENDER: "notify_sender",
	ACTION_NOTIFY_SALESREP: "notify_salesrep",
	ACTION_NOTIFY_BOTH: "notify_both",
	ACTION_NOTIFY_USERS: "notify_users",

	/********************************************************/
	/* Autoresponder report name
	/********************************************************/
	AUTO_RESPONDER_REPORT: 'Auto Responder Report',
	AUTO_RESPONDER_REPORT_TYPE: 'AutoResponderReport',

	SMS_CAMPAIGN_REPORT: 'Sms Campaign Report',
	
	BROADCAST_REPORT_TYPE: 'BroadcastReport',
	BROADCAST_REPORT: 'Broadcast Report',

	BIRTHDAY_REPORT_TYPE: 'BirthdayCampaignReport',

	AB_REPORT_TYPE: 'ABReport',
	AB_REPORT: 'AB Report',
	TYPE_AUTORESPONDER_REPORT: 77,

	LINK_CLICK_INFO: 'linkclickinfo',


	/********************************************************/
	/* Workflow Designer Decision Names
	/********************************************************/
	WORKFLOW_DESIGNER_REQUIRED_TASKS_DONE: 'WA_REQUIRED_TASKS_DONE',
	WORKFLOW_DESIGNER_NOT_CONVERTED: 'WA_NOT_CONVERTED',
	WORKFLOW_DESIGNER_LINK_CLICK: 'WA_LINK_CLICK',
	WORKFLOW_DESIGNER_EMAIL_OPEN: 'WA_EMAIL_OPEN',
	WORKFLOW_DESIGNER_UNSUBSCRIBE: 'WA_UNSUBSCRIBE',
	WORKFLOW_DESIGNER_SCORE_REACHED: 'WA_SCORE_REACHED',

	/********************************************************/
	/* Workflow Designer Action Names
	/********************************************************/
	WORKFLOW_DESIGNER_ADD_TASK: 'WA_ADD_TASK',
	WORKFLOW_DESIGNER_AUTO_MAIL: 'WA_AUTO_MAIL',
	WORKFLOW_DESIGNER_SEND_SMS: 'WA_SEND_SMS',

	WORKFLOW_DESIGNER_CHANGE_GROUP: 'WA_CHANGE_GROUP',
	WORKFLOW_DESIGNER_ADD_NOTE: 'WA_ADD_NOTE',
	WORKFLOW_DESIGNER_NOTIFY: 'WA_NOTIFY',
	WORKFLOW_DESIGNER_UPDATE_FIELD: 'WA_UPDATE_FIELD',
	WORKFLOW_DESIGNER_CONVERT: 'WA_CONVERT',
	WORKFLOW_DESIGNER_ASSIGN: 'WA_ASSIGN',


	/********************************************************/
	/* Sales Report type
	/********************************************************/
	REPORT_TYPE_SALES_SUMMARY: 'sales-summary-report',
	REPORT_TYPE_ACTIVITY: 'activity-report',
	REPORT_TYPE_ACTIVITY_BY_SALES_REP: 'activities-by-sales-rep',
	REPORT_TYPE_EMAIL: 'email-report',
	REPORT_TYPE_FORECAST: 'forecast-report',
	REPORT_TYPE_SMS: 'sms-report',
	REPORT_TYPE_FIVE_WAYS: '5-ways-report',
	REPORT_TYPE_TASKS: 'tasks-report',
	REPORT_TYPE_PIPELINE: 'pipeline-report',
	REPORT_TYPE_CONVERSION: 'conversion-report',
	REPORT_TRYPE_INXPRESS: 'inxpress-report',
	REPORT_TYPE_CALL: 'call-report',
	REPORT_TYPE_ORTHOTIC: 'orthotic-report',
	REPORT_TYPE_RELATION: 'relation-matrix',
	REPORT_TYPE_CUSTOM_RELATION: 'user-relation-matrix',
	REPORT_TYPE_USER: 'user',
	REPORT_TYPE_GREENFIELD_IMPORT: 'greenfield-import',
	REPORT_TYPE_DEALS_BY_SALES_REP: 'deals-by-sales-rep',
	REPORT_TYPE_PIPELINE_BY_SALES_REP: 'pipeline-by-sales-rep',
	REPORT_TYPE_CONVERSION_BY_SALES_REP: 'conversion-by-sales-rep',
	REPORT_TYPE_CONVERSION_MATRIX: 'conversion-matrix',
	REPORT_TYPE_ACTIVITY_MATRIX: 'activity-matrix',


	SALES_SERVICE_REPORT_TYPE: 'saleservice',
	ACTIVITY_REPORT_TYPE: 'activity',
	EMAIL_REPORT_TYPE: 'email',
	SMS_REPORT_TYPE: 'sms',
	CALL_REPORT_TYPE: 'call',
	TASK_REPORT_TYPE: 'tasks',
	PIPELINE_REPORT_TYPE: 'pipeline',
	CONVERSION_REPORT_TYPE: 'conversion',
	CUSTOMER_STATUS_REPORT_TYPE: 'status',
	CUSTOMER_SERVICE_REPORT_TYPE: 'service',
	CUSTOMER_ASSESSEMENT_REPORT_TYPE: 'assessement',
	INXPRESS_REPORT_TYPE: 'inexpress',
	ORTHOTIC_REPORT_TYPE: 'orthotic',
	/********************************************************/
	/* Campaign query type
	/********************************************************/
	CAMPAIGN_QUERY_TYPE: 666,

	/********************************************************/
	/* Field types for Automation Designer
	/********************************************************/
	LIST_FORM_FIELD_INTEGER_TYPE: 223,
	LIST_FORM_FIELD: 207,
	LIST_COMBO: 15,
	DATETIME_FORM_FIELD: 204,
	DATE_AND_TIME_FORM_FIELD: 224,
	INTEGER_FORM_FIELD: 202,
	FLOAT_FORM_FIELD: 203,
	URL_FORM_FIELD: 217,
	EMAIL_FORM_FIELD: 218,

	/********************************************************/
	/* Search
	/********************************************************/
	SEARCH_RESULTS: 'search-results',
	SEARCH_RESULTS_LABEL: 'Search Result for ',
	ADVANCE_SEARCH_LABEL: 'Advance Search',
	ADVANCE_SEARCH_RESULTS: 'advance-search-results',
	SEARCH_TYPE_EXACT: 'exact',
	SEARCH_TYPE_ANYWHERE: 'anywhere',
	SEARCH_TYPE_START_WITH: 'start_with',
	SEARCH_QUERY_NAME: 'All records',
	SEARCH_QUERY_NAME_FRANCHISE: 'All Franchise records',
	SEARCH_QUERY_TYPE_GLOBAL: 'global',
	SEARCH_QUERY_TYPE_ADVANCE: 'advance',
	SEARCH_QUERY_TYPE_ALL: 'all',


	/********************************************************/
	/* Website Tracking
	/********************************************************/
	WEBSITE_TRACKING_LABEL: 'Website Analytics',
	WEBSITE_TRACKING_SETUP_LABEL: 'Website Analytics Setup',

	/**************************************************/
	/*       Audit Trail Type 
	/**************************************************/

	INLINE_UPDATE_AUDIT_TRAIL_RECORD: 'Inline/Quick updated',
	MAIN_CONTACT_UPDATE_AUDIT_TRAIL_RECORD: 'Main Contact updated',
	/**********************************************************/
	STATUS_CLOSE: 'Close',

	/** START: SECURITY LEVEL */
	SECURITY_LEVEL_TYPE_ACCESS: 'accessList',
	SECURITY_LEVEL_TYPE_PERMISSION: 'permission',
	ACCESS_TYPE_CREATE: 1,
	ACCESS_TYPE_EDIT: 2,
	ACCESS_TYPE_DELETE: 3,
	PERMISSION_NAME_IMPORT_RECORDS: 'Import Records',
	PERMISSION_NAME_SETUP_LEAD_SCORE: 'Setup Lead Scores',
	PERMISSION_NAME_SETUP_WHITE_LABEL: 'Setup White Label',
	PERMISSION_NAME_DESIGN_FORMS: 'Design Forms',
	PERMISSION_NAME_MANAGE_INTEGRATIONS: 'Manage Integrations',
	PERMISSION_NAME_EXPORT_RECORDS: 'Export Records',
	PERMISSION_NAME_MANAGE_SECURITY_LEVELS: 'Manage Security Levels',
	PERMISSION_NAME_MANAGE_USERS: 'Manage Users',
	PERMISSION_NAME_BATCH_DELETE: 'Batch Delete',
	PERMISSION_NAME_UPLOAD_LOGO: 'Upload Logo',
	PERMISSION_NAME_MERGE_RECORDS: 'Merge Records',
	PERMISSION_NAME_MANAGE_FILTERS: 'Manage Filters',
	PERMISSION_NAME_MANAGE_TEMPLATES: 'Manage Templates',
	PERMISSION_NAME_BATCH_UPDATE: 'Batch update',
	PERMISSION_NAME_DESIGN_WORKFLOWS: 'Design Workflows',
	PERMISSION_NAME_MANAGE_ASSETS: 'Manage Assets',
	PERMISSION_NAME_MANAGE_REPORTS: 'Manage Reports',
	PERMISSION_NAME_ADD_TO_LIST: 'Add to Lists',
	/** END: SECURITY LEVEL */

	CALENDER_INVITE_STATUS_REQUESTED: 'Requested',
	CALENDER_INVITE_STATUS_ACCEPTED: 'Accepted',
	CALENDER_INVITE_STATUS_DECLINED: 'Declined',
	CALENDER_INVITE_STATUS_TENTATIVELY_ACCEPTED: 'Tentatively Accepted',
	CALENDER_INVITE_OWNER_YES: 'Yes',
	CALENDER_INVITE_OWNER_NO: 'No',
	CALENDER_DEFAULT_INTERVAL: 30,

	/** Used for List and Detail View Logo(500kb)	*/
	MAX_IMAGE_FILE_SIZE_FOR_LOGO: 1024 * 500,
	/** Used for Profile Image(500kb)	*/
	MAX_IMAGE_FILE_SIZE_FOR_PROFILE: 1024 * 500,
	/** Used for Change Logo Image(500kb)	*/
	MAX_IMAGE_FILE_SIZE_FOR_COMPANY_LOGO: 1024 * 500,

	/**Used for document upload in library (5mb) */
	MAX_LIBERAY_DOCUMENT_FILE_SIZE: 1024 * 5,

	MS_CLIENT_CONNECT: 101,
	INVALID_TOKEN: '-111',
	STATUS_FAILURE: '-1',

	/** IMPORT */
	IMPORT: 'Import',

	TYPE_NOTIFY: 1,
	TYPE_EMAIL: 2,
	TYPE_URL: 4,
	TYPE_GROUP: 5,
	TYPE_BCC: 6,
	SALES_EMAIL: 'SALES_EMAIL',
	VIDEO_TYPE_YOUTUBE: 'YouTube',
	VIDEO_TYPE_WISTIA: 'Wistia',
	ICON_YOUTUBE: 'youtube',
	ICON_WISTIA: 'video-camera',
	YOUTUBE_API_URL: 'https://www.googleapis.com/youtube/v3/videos?key=AIzaSyBQKPSqMt3mXPYlVD0ZHdOqhv3Q91zkXtY&part=snippet&id=',
	WISTIA_API_URL: '//fast.wistia.com/oembed?url=',

	KANBAN: 'kanban',
	APPLICATION_SELECTION: 'Application Selection',
	CURRENT: 'Current',
	LEGACY: 'Legacy',
	MTD_INVOICES_QUERYID: 971,
	SALE_TYPE: 'custom_field_list1',
	EDITOR_FONT_SIZE: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '30', '36', '48', '60', '72', '96'],

	NYLAS_AUTH_API: 'https://api.nylas.com/oauth/authorize?',
	AURINKO_AUTH_API: 'https://api.aurinko.io/v1/auth/authorize?',

	CONSTANT_GOOGLE_CALENDAR: 'Google_Calendar',

	DOCUSIGN_AUTH_API: 'https://account.docusign.com/oauth/auth?',
	/* DocuSign table-id*/
	MS_DOCUSIGN: 107,
	FORECAST: 'Forecast',

	EMAIL_AND_CALENDER_INTEGRATION: 'EMAIL_AND_CALENDER_INTEGRATION',
	PHONE_AND_TEXT_INTEGRATION: 'PHONE_AND_TEXT_INTEGRATION',
	SMS_INTEGRATION: 'SMS_INTEGRATION',
	DOCUSIGN_INTEGRATION: 'DOCUSIGN_INTEGRATION',
	QUICKBOOKS_INTEGRATION: 'QUICKBOOKS_INTEGRATION',
	EVENTBRITE_INTEGRATION: 'EVENTBRITE_INTEGRATION',
	PAYMENT_INTEGRATION: 'PAYMENT_INTEGRATION',

	EVENTBRITE_AUTH_API: 'https://www.eventbrite.com/oauth/authorize?',

	EDITOR_NEW: 'New',
	EDITOR_BEE: 'Bee',
	EDITOR_LEGACY: 'Legacy',

	LIBRARY_TEMPLATE: 'library',
	USER_TEMPLATE: 'user',

	RECORD_OWNER: '[Record Owner]',
	ONBOARDING_WORKFLOW: 'Onboarding',
	CONTACT_SUPPORT_LINK: "http://support.soffront.com/contact-support/",
	TYPE_DASHBOARD_INXPRESS_COUNTRY: 'Inxpress-GC',
	TYPE_DASHBOARD_INXPRESS_FRANCHISE: 'Inxpress-F',
	INDUSTRY_TYPE_INXPRESS_GLOBAL: 4069, //inxpress global project id
	INDUSTRY_TYPE_INXPRESS_COUNTRY: 'InXpressCountry',
	INDUSTRY_TYPE_INXPRESS_FRANCHISE: 'InXpressFranchise',
	INDUSTRY_TYPE_INXPRESS_US_FRANCHISE: 'InXpressUSFranchise',

	TASK_STATUS_PENDING: 'Pending',
	TASK_STATUS_COMPLETED: 'Completed',

	BIRTHDAY_CAMPAIGN_TEMPLATE_NAME: 'Birthday Campaign Automation',
	BIRTHDAY_CAMPAIGN_NAME: 'Birthday Campaign',
	PET_BIRTHDAY_CAMPAIGN_TEMPLATE_NAME: 'Pet Birthday Greetings',
	PET_BIRTHDAY_CAMPAIGN_NAME: 'Pet Birthday Campaign',
	SMS_DRIP_CAMPAIGN: 'SMS Drip Campaign',
	ASSIGNMENT_RULE_SETUP: 'ASSIGNMENT_RULE_SETUP',

	PERMISSION_TYPE_OPERATIONS: 3,
	PERMISSION_TYPE_ADMINISTRATION: 4,

	RECURLY_PLAN_CODE: '0001',
	TASK_DEPENDENT: 'Task Dependent',
	SF_SCHEDULE_DIALOG: 'SFScheduleDialog',
	SF_CALENDAR_DIALOG: 'SFCalendarDialog',
	USER_REPORT: 'user-report',
	USER_REPORT_TYPE: 'user',
	TASKS_OVERDUE: 'Overdue',
	TASKS_TODAY: 'Today',
	TASKS_TOMORROW: 'Tomorrow',
	TASKS_FUTURE: 'Future',

	USER_REPORT_TYPE_ID_TABULAR: 21,
	USER_REPORT_TYPE_ID_SUMMARY: 22,
	USER_REPORT_TYPE_ID_PIVOT: 23,
	/********************************************************/
	/* Field types for AutoFill Values
	/********************************************************/
	TEXT_FORM_FIELD: 201,
	REGULAR_LIST: 10,
	USER_LIST: 11,
	GROUP_LIST: 12,
	USER_AND_GROUP_LIST: 13,
	EXTERNAL_LIST: 14,
	MULTI_SELECT_LIST: 16,
	MEMO_FORM_FIELD: 205,
	CURRENCY_FORM_FIELD: 206,
	LOOKUP_FORM_FIELD: 222,


	FARMINPEX_PROJECT_ID: 2430, //3873

	/****** Library constants (14-Jan-2020) -Malay */
	DOCUMENT_LIBRARY: 'documents',
	IMAGES_LIBRARY: 'images',

	DEFAULT_COLUMN_VIEW_CUT_OFF_DATE: '2020/05/04',

	GREENLIGHT_TO_EMAIL: 'Americas.Helpdesk@inxpress.com',
	GREENLIGHT_STATUS_FIELD: 'custom_field43',
	GREENLIGHT_STATUS_RESUBMITTED: 'Resubmitted',
	GREENLIGHT_STAGE_DENIAL_INQUIRY: 'Denial Inquiry',
	GREENLIGHT_EXPORT: 'Greenlight Export',
	GREENLIGHT_IMPORT: 'greenfield import',
	GREENLIGHT_STAGE: 'Greenlight',
	GREENLIGHT_WORKFLOW: 'Greenlight',
	DUPLICATE_SORT_FIELD: 'company',

	/********************************************************/
	/* Royalty reported sales drilldown
	/********************************************************/
	ROYALTY_REPORTED_SALES_DRILLDOWN:'royalty reported sales drilldown',

	/********************************************************/
	/* SFtab Types
	/********************************************************/

	MAX_TAB_SIZE: 7,
	TYPE_HOME:'TYPE_HOME',
	TYPE_LIST_VIEW: 'TYPE_LIST_VIEW',
	TYPE_DETAIL_VIEW: 'TYPE_DETAIL_VIEW',
	TYPE_REPORT: 'TYPE_REPORT',
	TYPE_WEBSITE_TRACKING: 'TYPE_WEBSITE_TRACKING',
	TYPE_LINK_CLICK_PAGE: 'TYPE_LINK_CLICK_PAGE',
	TYPE_DASHBOARD: 'TYPE_DASHBOARD',
	TYPE_OTHER_VIEW: 'TYPE_OTHER_VIEW',
	TYPE_COLUMN_VIEW: 'TYPE_COLUMN_VIEW',
	TYPE_EMAIL_REPORT_LISTVIEW: 'TYPE_EMAIL_REPORT_LIST_VIEW',
	TYPE_POWER_DIALER: 'TYPE_POWER_DIALER',
	TYPE_JOB_SCHEDULER: 'TYPE_JOB_SCHEDULER',

	REPORTED_BY_FR: 'Franchisor',
	REPORTED_BY_FE: 'Franchisees',

	// Phone and Text Integration
	NONE: 'None',
	SOFFRONT: 'Soffront',
	TWILIO: 'Twilio',
}

export const EVENT_TYPES = {
	"sent": "Sent",
	"opened": "Open",
	"unopened": "Unopened",
	"clicked": "click",
	"bounced": "Bounce",
	"unsubscribed": "Unsubscribed",
	"spam": "spamreport",
	"dropped": "dropped",
}

export const EVENT_TYPE_KEYS = {
	"Sent": "sent",
	"Open": "opened",
	"Unopened": "unopened",
	"click": "clicked",
	"Bounce": "bounced",
	"Unsubscribed": "unsubscribed",
	"spamreport": "spam",
	"dropped": "dropped",
}

export const CARD_HEIGHT = 161;  // height of a single card(excluding marginBottom/paddingBottom)
export const CARD_MARGIN = 10;  // height of a marginBottom+paddingBottom
export const OFFSET_HEIGHT = 84; // height offset from the top of the page

export const HOME_MODULE_ID_NAME_MAP = {
	[constants.MODULE_SALES]: constants.MODULE_ID_SALES,
	[constants.MODULE_MARKETING]: constants.MODULE_ID_MARKETING,
	[constants.MODULE_SERVICE]: constants.MODULE_ID_SERVICE,
	[constants.MODULE_OPERATIONS]: constants.MODULE_ID_OPERATIONS,
	[constants.MODULE_HUB]: constants.MODULE_ID_HUB
};
export const OBJECT_TABLEID_MAP = {
	'accounts': 10,
	'contacts': 9,
	'opportunities': 2,
	'cases': 13,
	'projects': 4,
	'products': 3,
	'solutions': 315,
	'appointments': 142,
	'tasks': 349,
	'notes': 14,
	'groups': 322,
	'attachments': 29,
	'customobjectone': 376,
	'customobjecttwo': 377,
	'customobjectthree': 378,
	'customobjectfour': 447,
	'broadcast-campaigns': 318,
	'touch-campaigns': 319,
	'campaign-rules': 374,
	'issues': 424,
	'ab-campaigns': 486,
	'automation-designer': 489,
	'msg-details': 320,
	'invoices': 444,
	'email': 490,
	'salesleads': 501,  // For Test , Production Use 501
	'coupons': 504,     // For Test , Production Use 504
	'claims': 505,	    // For Test , Production Use 505
	'units': 10,
	'pets': 520,       // For Test Use 518, Production Use 520
	'orders': 527,       // For Test Use 526, Production Use 527 
	'orderitems': 528,       // For Test Use 527, Production Use 528
	'eventsbrite': 358,
	'quotations': 280,
    'payments': 539, // For Test Use 537
	'purchaseorder': 550, // For Test Use 550
	'salesorder': 551,   // For Test Use 551
	'inventory': 552,     // For Test Use 552
	'jobs': 567,         // For Test Use 567
	'time': 568,          // For Test Use 568
	'lineitems': 282,
	'royalties': 589,
	'compliance': 605,
	'surveyresponses': 610,
	'reportedsales': 619, // For Test/Production Use 619
	'refund': 621,
	'royaltyplan': 624, // For Test/Production Use 624
	'royaltyrun': 625, // For Test/Production Use 625
};

export const TABLEID_OBJECT_MAP = {
	10: 'accounts',
	9: 'contacts',
	2: 'opportunities',
	13: 'cases',
	4: 'projects',
	3: 'products',
	315: 'solutions',
	142: 'appointments',
	349: 'tasks',
	14: 'notes',
	322: 'groups',
	29: 'attachments',
	376: 'customobjectone',
	377: 'customobjecttwo',
	378: 'customobjectthree',
	447: 'customobjectfour',
	318: 'broadcast-campaigns',
	319: 'touch-campaigns',
	374: 'campaign-rules',
	424: 'issues',
	486: 'ab-campaigns',
	489: 'automation-designer',
	320: 'msg-details',
	444: 'invoices',
	490: 'email',
	501: 'salesleads',
	504: 'coupons',
	505: 'claims',
	358: 'eventsbrite',
	550: 'purchaseorder',
	551: 'salesorder',
	552: 'inventory',
	568: 'time',
	567: 'jobs',
	282: 'lineitems',
	589: 'royalties',
	280: 'quotations',
	610: 'surveyresponses',
	605: 'compliance',
	619: 'reportedsales',
	539:'payments',
	621: 'refund',
	624: 'royaltyplan',
	625: 'royaltyrun',
};

export const DATE_KEY = {
	ALL: 1,
	TODAY: 2,
	THIS_WEEK: 3,
	THIS_WEEK_TO_DATE: 4,
	THIS_MONTH: 5,
	THIS_MONTH_TO_DATE: 6,
	THIS_FISCAL_QUARTER: 7,
	THIS_FISCAL_QUARTER_TO_DATE: 8,
	THIS_FISCAL_YEAR: 9,
	THIS_FISCAL_YEAR_TO_DATE: 10,
	YESTERDAY: 11,
	LAST_WEEK: 12,
	LAST_WEEK_TO_DATE: 13,
	LAST_MONTH: 14,
	LAST_MONTH_TO_DATE: 15,
	LAST_FISCAL_QUARTER: 16,
	LAST_FISCAL_QUARTER_TO_DATE: 17,
	LAST_FISCAL_YEAR: 18,
	LAST_FISCAL_YEAR_TO_DATE: 19,
	NEXT_WEEK: 20,
	NEXT_FOUR_WEEKS: 21,
	NEXT_MONTH: 22,
	NEXT_FISCAL_QUARTER: 23,
	NEXT_FISCAL_YEAR: 24,
	CUSTOM: 25,
}

export const ADVANCE_SEARCH_DATE_CONSTANT = {
	2: 'Today',
	3: 'This week',
	4: 'This week-to-date',
	5: 'This month',
	6: 'This month-to-date',
	7: 'This fiscal quarter',
	8: 'This fiscal quarter-to-date',
	9: 'This fiscal year',
	10: 'This fiscal year-to-date',
	11: 'Yesterday',
	12: 'Last week',
	13: 'Last week-to-date',
	14: 'Last month',
	15: 'Last-month-to-date',
	16: 'Last fiscal quarter',
	17: 'Last fiscal quarter-to-date',
	18: 'Last fiscal year',
	19: 'Last fiscal year-to-date',
	20: 'Next week',
	21: 'Next 4 weeks',
	22: 'Next month',
	23: 'Next fiscal quarter',
	24: 'Next fiscal year',
	25: 'Custom',
}

export const SOURDOUGH_LOCAL_FILENAME_MAP = {
	'Local': 'Local Service Order_V2.xlsx',
	'Northbound-Fairbanks': 'FairBank Northbound_V3.xls',
	'Northbound-Anchorage': 'Anchorage Northbound_V3.xls',
	'Southbound-Fairbanks': 'Fairbanks Southbound_V3.xls',
	'Southbound-Anchorage': 'Anchorage Southbound_V3.xls',
}

export const VERSION = {
	COOKIE: '0.0.01',
};

export const INXPRESS_REPORT_MAP = {

	TYPE_INXPRESS_REPORT_ONE: 120,
	TYPE_INXPRESS_REPORT_TWO: 121,
	TYPE_INXPRESS_REPORT_THREE: 122,
	TYPE_INXPRESS_REPORT_FOUR: 123,
	TYPE_INXPRESS_REPORT_FIVE: 124,
	TYPE_INXPRESS_REPORT_SIX: 125,
	TYPE_INXPRESS_REPORT_SEVEN: 126,
	TYPE_INXPRESS_REPORT_EIGHT: 127,
	TYPE_INXPRESS_REPORT_NINE: 128,
	TYPE_INXPRESS_REPORT_TEN: 129,
	TYPE_INXPRESS_REPORT_ELEVEN: 130,
	TYPE_INXPRESS_REPORT_TWELVE: 131,
	TYPE_INXPRESS_REPORT_THIRTEEN: 132,
	TYPE_INXPRESS_REPORT_FOURTEEN: 133,
	TYPE_INXPRESS_REPORT_FIFTEEN: 134,
	TYPE_INXPRESS_REPORT_SIXTEEN: 135,
	TYPE_INXPRESS_REPORT_SEVENTEEN: 136,
	TYPE_INXPRESS_REPORT_EIGHTEEN: 137,
	TYPE_INXPRESS_REPORT_NINETEEN: 138,
	TYPE_INXPRESS_REPORT_TWENTY: 139,
	TYPE_INXPRESS_REPORT_TWENTYONE: 140,
};

export const OPERATIONS_REPORTS_MAP = {
	TYPE_FRANCHISE_ACTIVITY_REPORT: 200,
	TYPE_FRANCHISE_CONVERSION_REPORT: 201
}

export const INXPRESS_REPORT = {

	FETCH_SIZE: 500,
	FETCH_COUNT_START: 0,
	ACTIVITY_FETCH_SIZE: 10,
};

export const TASKS_REPORTS_MAP = {
	TYPE_TASKS_REPORT: 300,
}

export const COUNTRY_LIST_FOR_PHONE_SMS = [
	"USA",
	"Canada",
	"United Kingdom",
	"Germany",
	"France",
	"Spain",
	"Netherlands",
	"Australia",
	"India"
]

export const COUNTRY_CODE_MAP = {

	"USA": "+1",
	"Canada": "+1",
	"United Kingdom": "+44",
	"Germany": "+49",
	"France": "+33",
	"Spain": "+34",
	"Netherlands": "+31",
	"Australia": "+61",
	"India": "+91",
};

export const DASHBOARD = {
	FETCH_SIZE: 50,
};

export const PIPELINE_REPORTS_MAP = {
	TYPE_PIPELINE_REPORT: 400,
}

export const ORTHOTIC_REPORT = {

	FETCH_SIZE: 50,
	START_INDEX: 0
};

export const USER_REPORT_TYPE_ID_NAME_MAP = {
	'21': 'Tabular',
	'22': 'Summary',
	'23': 'Pivot'
}
export const REPORT_TYPE_ICON_MAP = {
	'user_Tabular': 'fas fa-table',
	'user_Summary': 'fa fa-list-alt',
	'user_Pivot': 'fa fa-columns',
	'system_': 'fa fa-bar-chart',
	'system_Summary': 'fa fa-list-alt',
}

export const USER_REPORT_TYPE = {
	TABULAR: constants.USER_REPORT_TYPE_ID_TABULAR,
	SUMMARY: constants.USER_REPORT_TYPE_ID_SUMMARY,
	PIVOT: constants.USER_REPORT_TYPE_ID_PIVOT
}

export const RELATION_REPORT_MAP = {
	FIELD_TYPE_DATE: 'date',
	FIELD_TYPE_ROW: 'row',
	TYPE_RELATION_REPORT: 500
}
export const QUEUE_LIST = {
	FETCH_SIZE: 200,

};
export const JOB_ACTIONS_ARRY= ['New' , 'Scheduled' , 'Done' , 'Invoiced' , 'Paid'];
export const JOB_ACTIONS = {
	NEW :'New',
	SCHEDULED :'Scheduled',
	RESCHEDULE :'Reschedule',
	DONE:'Done',
	INVOICED:'Invoiced',
	PAID:'Paid'

};
export const ROYALTY_RUN_ACTIONS_ARRY= ['Report Sales', 'Process', 'Submit', 'Invoice All', 'Collect All', 'Email All', 'Post All'];
export const ROYALTY_RUN_ACTIONS = {
	REPORT_SALES :'Report Sales',
	PROCESS :'Process',
	SUBMIT:'Submit',
	INVOICE_ALL :'Invoice All',
	MARKPAID_ALL :'Mark Paid All',
	COLLECT:'Collect All',
	EMAIL_ALL:'Email All',
	POST_ALL: 'Post All',
	TO_UNITS:'To Units',
	TO_QUICKBOOKS:'To Quickbooks'
};
export const REPORTED_SALES_ACTIONS_ARRY= ['Update' , 'Close'];
export const REPORTED_SALES_ACTIONS = {
	UPDATE :'Update',
	CLOSE :'Close'
};
export const TASKS_ACTIONS_ARRY= ['Edit' , 'Complete' , 'Complete & New' , '+Day' , '+Week', 'Delete'];
export const TASKS_ACTIONS = {
	EDIT :'Edit',
	COMPLETE :'Complete',
	COMPLETE_NEW :'Complete & New',
	DAY:'+ Day',
	WEEK:'+ Week',
	DELETE:'Delete'

};
export const WEB_FORM  = {
	version: "1.3",
	jqueryLink: "https://ajax.googleapis.com/ajax/libs/jquery/1.11.1/jquery.min.js",
	bootstrapJSLink: "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/js/bootstrap.min.js",
	bootstrapCSSLink: "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/css/bootstrap.min.css",
	webformCookieJS: "https://s3.amazonaws.com/smb-webform/cookieUtils.js",
	webformCSSLink : "https://s3.amazonaws.com/smb-webform/webform-widget.css",

}


export const MODULES  = [{name:constants.MODULE_AVAILABLE_MENU_ITEMS,id:constants.MODULE_ID_AVAILABLE_MENU_ITEMS},
	{name:constants.MODULE_SALES,id:constants.MODULE_ID_SALES},
	{name:constants.MODULE_SERVICE,id:constants.MODULE_ID_SERVICE},
	{name:constants.MODULE_MARKETING,id:constants.MODULE_ID_MARKETING},
	{name:constants.MODULE_OPERATIONS,id:constants.MODULE_ID_OPERATIONS},
	{name:constants.MODULE_HUB,id:constants.MODULE_ID_HUB},
	{name:constants.MODULE_SALES_MARKETING,id:constants.MODULE_ID_SALES_MARKETING},
]

export const WORKFLOW_SETUP = {
	// workFlow Setup
	CONFIGURE_WORKFLOW_STAGES: 'CONFIGURE_WORKFLOW_STAGES',
	SETUP:'workflow-setup',
	TYPE_WORKFLOW: "Workflow",
	TYPE_STAGE: "Stage",
	TYPE_TASK: "Task",
	TYPE_AUTO_MAIL: "Auto_Mail",
	TYPE_ESCALATION: "Escalation",
	TYPE_TEXT_MESSAGE: "Text_Message",
	NEW_REMINDER: "Reminder",
	TYPE_APPOINTMENT_SUB: "New_Appointment",
	TYPE_WEBHOOK: "webHook",
	UNCHANGE: 0, 
	NEW: 1, 
	UPDATED: 2, 
	DELETED: 3, 
	COPY: 4,
}

export const INTAKE_FORM = {
    INPUT: 'input',
    DROPDOWN: 'dropdown',
    DROPDOWNWITHINPUT: 'dropdownWithInput',
    DROPDOWNTHREEINPUT: 'dropdownWithThreeInput',
    DROPDOWNWITHFIVEINPUT: 'dropdownWithFiveInput',
    DROPDOWNWITHSIXINPUT: 'dropdownWithSixInput',
    DROPDOWNWITHSEVENINPUT: 'dropdownWithSevenInput',
    DATE_PICKER: 'date-picker',
	TENENT_PROJECTS_ID: [ 893, 4676 ]
};

export const SMS_VERIFICATION = {
    INPUT: 'input',
    DROPDOWN: 'dropdown',
	RADIOBUTTON:'radiobutton'
};