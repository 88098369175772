
import React, { useState, useEffect }  from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { isValidParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { getSavedMapping, saveMapping, getPreviousMappingFields, deleteMapping } from '../../../services/actions/importAction';
import { Select, MenuItem, InputLabel } from '@mui/material';
import * as sfDialogs from '../components/sfDialogs';
import { constants } from '../../../services/constants/constants';
import { useDispatch, useSelector } from "react-redux";

const ImportSaveMapping = ({object,callFrom,hideImportMapping,importMappingProps,usePrevFieldMapping })=>  
{
    
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);

    const [isMounted, setisMounted] = useState(false);
    const [existingMapArr, setexistingMapArr] = useState([]);
    const [showCustomTextField, setshowCustomTextField] = useState(false);
    const [existingMapSelectedValue, setexistingMapSelectedValue] = useState(0);

   
    useEffect(() => {
        if (object !== null) {
            loadExistingMaps(callFrom);
        }

    }, []);
    
    useEffect(() => {
        existingMapVal();

    }, [existingMapSelectedValue]);
    // useEffect((nextProps) => {
    //     if (callFrom !== nextProps.callFrom) {
    //         showCustomTextField = false;
    //         existingMapSelectedValue = 0;
    //         isMounted = false;
    //        loadExistingMaps(nextProps.callFrom);
    //     }

    // }, []);
    
   const loadExistingMaps = (callFrom) => {
        let mounted = false;
        let params = {};
        params.object_name = object;

        let promise = Promise.resolve(getSavedMapping(params));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.hasOwnProperty('saved_mapping_details')) {
                        let existingMaps = getArrayParam(response.saved_mapping_details);
                        if (existingMaps !== null && existingMaps.length > 0) {
                            sortArrayObjectByProperty(existingMaps, 't_mapping_name');

                            mounted = true;
                        }
                        setisMounted(mounted);
                        setexistingMapArr(existingMaps);

                    } else {
                        if (callFrom === 'usesavedmap' || callFrom === 'deletemap') {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.NO_PREVIOUS_MAPPING, null);
                            hideImportMapping();
                        } else {
                        setisMounted(true);
                        setexistingMapArr([]);
                        }
                    }
                }

            });
        }
    }

   const validateForm=()=> {

        var mapName = '';
        if (document.getElementById('mapName')) {
            mapName = document.getElementById('mapName').value;
            mapName =mapName.trim();
        }

        var existingMapValue = existingMapSelectedValue;

        if (existingMapValue === '' || existingMapValue === undefined || existingMapValue === 0 || existingMapValue === '-1') {
            if (mapName === '' || mapName === null) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.BLANK_MAP_NAME, null);
                return false;
            }
        }

        if (mapName !== null && mapName.length > 32) {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.VALID_MAP_NAME_LENGTH, null);
            return false;
        }

        return true;
    }


   const handleExistingMapChange = (field, event, index) => {
        let value = event.target.value;
        if (value === "-1") {
            setshowCustomTextField(true);
            setexistingMapSelectedValue(value);
            
        } else {
            setshowCustomTextField(false);
            setexistingMapSelectedValue(value);
            
        }
    }


   const saveImportMapping = () => {
        if (validateForm()) {
            let mapName = '';
            if (document.getElementById('mapName')) {
                mapName = document.getElementById('mapName').value;
            }

            let params = {};
            params.object_name = object;

            if (mapName !== null && mapName.length > 0) {
                params.t_mapping_name = mapName.trim();
            } else {
                params.id = existingMapSelectedValue;
            }

            let sourceFieldMapProps = importMappingProps.sourceFieldMap;
            let sourceDestFieldProps = importMappingProps.sourceDestFieldMap;
            let destFieldProps = importMappingProps.destFieldArr;
            let tableFieldsColumnProps = importMappingProps.tableFieldsColumn;
            if (!tableFieldsColumnProps.hasOwnProperty('-999')) {
                var objKey = "-999";
                tableFieldsColumnProps[objKey] = constants.NOTES_NAME;
            }

            let field_map_obj = null;
            let field_map_obj_arr = [];

            if (sourceDestFieldProps !== null && sourceDestFieldProps !== undefined) {

                Object.keys(sourceDestFieldProps).map((key) => {

                    if (sourceDestFieldProps[key] !== "-1") {
                        field_map_obj = {};
                        let sKey = parseInt(key);
                        field_map_obj.t_source_field_name = sourceFieldMapProps[key];
                        field_map_obj.t_destination_field_id = sourceDestFieldProps[key];
                        field_map_obj.t_destination_field_name = tableFieldsColumnProps[sourceDestFieldProps[key]];

                        field_map_obj_arr.push(field_map_obj);
                    }

                });
            }

            params.field_mapping_details = field_map_obj_arr;

            let promise = Promise.resolve(saveMapping(params));

            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (response.hasOwnProperty('data')) {
                            if (response.data.id > 0) {
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.MAP_SAVE_SUCCESS, null);
                                hideImportMapping();
                            } else {
                                if (response.data.id === -111) {
                                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.DUPLICATE_MAP_NAME, null);
                                    if (document.getElementById('mapName')) {
                                        document.getElementById('mapName').value = '';
                                    }
                                    return false;
                                }
                            }
                        }

                    }

                });
            }

        }

    }
   const cancelMapping = () => {
        let mounted = false;
        hideImportMapping();
        setisMounted(mounted);
    }

  const usePreviousMapping = () => {
        let mapId = existingMapSelectedValue;
        usePrevFieldMapping(mapId);
    }

   const deleteImportMapping = () => {
        var mapId = existingMapSelectedValue;
        var params = {};
        params.id = mapId;

        var mapName = '';

        if (existingMapArr !== null && existingMapArr.length > 0) {
            existingMapArr.map((eMap, i) => {
                if (eMap.id === mapId) {
                    mapName = eMap.t_mapping_name;
                }
            });
        }

        let msg = getLocalizedStrings().message.IMPORT.DELETE_MAP_ALERT + ' ' + mapName + '?';
        sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, ()=>onConfirmDeleteMapping(this, params), null);


    }


    const onConfirmDeleteMapping = (event, params) => {
        let promise = Promise.resolve(deleteMapping(params));

        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.hasOwnProperty('data')) {
                        var id = response.data.id;
                        if (id > 0) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.DELETE_MAP_SUCCESS, null);
                            hideImportMapping();
                        }
                    }
                }

            });
        }
    }
    const existingMapVal = ()=>{
        let existingMapValue =0;
        existingMapArr.map((f, i)=>{
            if(i===0){
                existingMapValue =existingMapSelectedValue === 0 ? f.id : existingMapSelectedValue;
            //    let existingMapSelectedValue = existingMapSelectedValue === 0 ? existingMapArr[i].id : existingMapSelectedValue;
            setexistingMapSelectedValue(existingMapValue);
            return false;
            }
           
        })
    
    }
        if (isMounted) {
            let map = callFrom;
            let sfFormFieldStyle = { width: '100%', backgroundColor: '#fff', border: '1px solid #dddddd', marginBottom: '12px' };
            let sfFormFieldContainerStyle = { ...styles.sfFormFieldContainer };

            let existing_map_menu = [];
let existingMapValue =0;
            if (Array.isArray(existingMapArr)) {
                
                for (var i = 0; i < existingMapArr.length; i++) {
                    // if (i === 0) {
                    //    let existingMapSelectedValue = existingMapSelectedValue === 0 ? existingMapArr[i].id : existingMapSelectedValue;
                    //    setexistingMapSelectedValue(mapSelectedValue);
                    // }
                    existing_map_menu.push(
                        <MenuItem value={existingMapArr[i].id}
                            id={'existing_map_' + existingMapArr[i].id}
                            key={'existing_map_' + existingMapArr[i].id}
                            insetChildren={true}
                            checked={false}
                            style={styles.popoverMenuItem}>{existingMapArr[i].t_mapping_name}</MenuItem>
                    );
                }

                if (callFrom === 'savemap') {

                    existing_map_menu.push(
                        <MenuItem value="-1"
                            id="-1"
                            key="-1"
                            insetChildren={true}
                            checked={false}
                            style={styles.popoverMenuItem} >---- Add a new map ----</MenuItem>
                    );
                }
            }

            let mapDropDownLabel = 'Existing Map';
            if (callFrom === 'usesavedmap') {
                mapDropDownLabel = 'Previous Map';
            }


            return (

                <div>
                    <div className="row clearfix ten" style={{ marginTop: '10px', marginBottom: '10px', minHeight: '28px' }}>
                        <div className="col-sm-8" ></div>
                        <div className="col-sm-4" >
                            <div >
                                <FormControl variant="outlined" style={{ width: '100%',margin:'10 0' }}>
                                    <InputLabel id="sf-importsavemapping-simple-select-outlined-label" className="sf-importsavemapping-simple">{mapDropDownLabel}</InputLabel>
                                    <Select
                                        key="existingMap"
                                        labelId='sf-label-existingMap'
                                        id='sf-existingMap'
                                        label={mapDropDownLabel}
                                        value={existingMapSelectedValue}
                                        className={"sf-fields-bg"}
                                        style={{ height: '37px' }}
                                        onChange={handleExistingMapChange.bind(this, 'existingMap')}
                                    >
                                        {existing_map_menu}
                                    </Select>
                                </FormControl>
                            </div>

                            {showCustomTextField &&
                                <div >
                                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                        <TextField variant="outlined" type='text' hintText={getLocalizedStrings().label.IMPORT.MAP_NAME} id='mapName' hintStyle={{
                                            color: '#717171', fontWeight: 'normal',
                                            fontSize: '12px', width: '150px'
                                        }} style={{ width: '100%', marginRight: '10px', marginTop: '-2px' }}
                                            textareaStyle={{ color: '#fff' }}
                                            autoFocus={true}
                                            inputprops={{ maxlength: '32' }}
                                            autoComplete="new-password"
                                            className={"sf-fields-bg"}
                                            margin='dense'
                                            size='small' />
                                    </FormControl>
                                </div>
                            }
                            <div style={{ float: 'right', marginRight: '-10px' }}>
                                {callFrom === 'savemap' &&
                                    <Button key='save' id='save' onClick={()=>saveImportMapping()} style={{ ...styles.primaryButton, marginTop: '6px' }} >Save</Button>
                                }
                                {callFrom === 'usesavedmap' &&
                                    <Button key='select' id='select' onClick={usePreviousMapping} style={{ ...styles.primaryButton, marginTop: '6px' }} >Select</Button>
                                }
                                {callFrom === 'deletemap' &&
                                    <Button key='delete' id='delete' onClick={()=>deleteImportMapping()} style={{ ...styles.primaryButton, marginTop: '6px' }} >Delete</Button>
                                }
                                <Button key='cancel' id='cancel' onClick={()=>cancelMapping()} style={{ ...styles.secondaryButton, marginTop: '6px', marginRight: '10px' }} >Cancel</Button>
                            </div>

                        </div>

                    </div>

                </div>
            );
        } else {
            return <div></div>;
        }
    
}



// ImportSaveMapping.propTypes = {
//     object: PropTypes.string,
//     importMappingProps: PropTypes.object,
//     callFrom: PropTypes.string,
//     usePrevFieldMapping: PropTypes.func,
//     hideImportMapping: PropTypes.func
// };


export default ImportSaveMapping;
