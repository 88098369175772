import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isValidParam, getStringParam, getObjectParam, getIntParam, getBooleanParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { setHeader } from '../../../services/actions/headerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { FormControl, Select, MenuItem, InputLabel} from '@mui/material';
import Button from '@mui/material/Button';
import moment from 'moment';
import { dateFormatFlatPicker,dateFormat,sortArrayObjectByProperty} from '../../../services/helper/utils.js';
import SFDataTable from '../components/sfDataTable';
import { OPERATIONS_REPORTS_MAP, INXPRESS_REPORT, constants } from '../../../services/constants/constants';
import NoRecords from '../components/noRecord';
import ShowCircularProgress from '../components/circularProgress';
import { addTab, getActiveTab, TYPE_LIST_VIEW, getActiveTabInfo, updateTabInfo, TYPE_DETAIL_VIEW } from '../../../services/helper/sfTabManager';
import { updateOperationDuebyData, updateOperationsReportData } from '../../../services/actions/operationsReportActions';
import { LinkToType } from '../../../services/constants/link';
import { sortArrayObjectByPropertyOrderType } from '../../../services/helper/utils'
import { getCookie, setCookie } from '../../../services/helper/sfCookies';
import PopOver from '../components/PopOver';
import Grid from '@mui/material/Grid'; 

const optionvalue = {
    td: 'Today',
    yd: 'Yesterday',
    tw: 'This Week',
    tm: 'This Month',
    tq: 'This Quarter',
    ty: 'This Year',
    lw: 'Last Week',
    lm: 'Last Month',
    lq: 'Last Quarter',
    ly: 'Last Year',
    cust: 'Custom',
};

const OperationsReports = ({object, type, style}) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const operationsReport = useSelector((state) => state.operationsReport);
    const {dueByData} = useSelector((state) => state.operationsReport)

    const [reportParams ,setReportParams] = useState({
        parameterId: null,
        reportName: null,
        due_by: 'td',
        type: null,
        recentReportId: 0,
        hdn_from_date:'',
        hdn_to_date:'',
    });
    const [isMounted ,setIsMounted] = useState(false);
    const [dueByPopup ,setDueByPopup] = useState(false);
    const [startIndex ,setStartIndex] = useState(INXPRESS_REPORT.FETCH_COUNT_START);
    const [fetchSize ,setFetchSize] = useState(INXPRESS_REPORT.FETCH_SIZE);
    const [records ,setRecords] = useState([]);
    const [fields ,setFields] = useState([]);
    const [fieldName, setFieldName] = useState(false);
    const [from_date, setFrom_date] = useState(null);
    const [to_date, setTo_date] = useState(null)
    const [customTimeLineDisplay, setCustomTimeLineDisplay] = useState('none');
    const [dummy,setDummy] = useState('');
    const [rowSort, setRowSort] = useState({
        enableAllColumn: true,
        sortFieldName: '',
        isDescending: false,
        onSort:  (sortFieldName, isDescending,records) => onSortFn (sortFieldName, isDescending,records)
    });
  
    useEffect(() => {
        let tab = getActiveTab();
        let tempReportParams = reportParams;
        let tempRowSort = rowSort;
        let tabObject = '';
        let info = getActiveTabInfo();
            if ((type === constants.ACTIVITIES || type === constants.CONVERSIONS) && isValidParam(info) && Object.keys(info).length > 0 && info.hasOwnProperty("dueBy")) {
                let cols = [];
                let rows = [];
                tempReportParams = {...tempReportParams, due_by: info.dueBy , hdn_from_date : info.hdn_from_date , hdn_to_date : info.hdn_to_date }
                if (info.hasOwnProperty("parameterId")) {
                    tempReportParams = {...tempReportParams, parameterId: info.parameterId}
                }
                if (info.hasOwnProperty("reportName")) {
                   tempReportParams = {...tempReportParams, reportName: info.reportName}
                }
                if (info.hasOwnProperty("type")) {
                    tempReportParams = {...tempReportParams, type: info.type}
                }
                if (info.hasOwnProperty("isDescending")) {
                    tempRowSort = {...tempRowSort, isDescending: info.isDescending}
                }
                if (info.hasOwnProperty("sortFieldName")) {
                    tempRowSort = {...tempRowSort, sortFieldName: info.sortFieldName}
                }
                if (isValidParam(operationsReport)) {
                    let _operationsReport = getObjectParam(operationsReport);
                    cols = getArrayParam(_operationsReport.operationsReportData.fields);
                    rows = getArrayParam(_operationsReport.operationsReportData.records);
                }
                let sortInfo = getOrderInfoData();
                if (sortInfo !== null && Object.keys(sortInfo).length > 0) {
                    let fieldName = sortInfo.fieldName;
                    let orderType = sortInfo.orderType;
                    sortArrayObjectByPropertyOrderType(rows, fieldName, orderType);
                    tempRowSort = {
                        enableAllColumn: true,
                        sortFieldName: fieldName,
                        isDescending: orderType === 'asc' ? false : true,
                        onSort:  (fieldName, isDescending) => onSortFn (fieldName, isDescending,rows)
                    }
                }
                setIsMounted(true);
                setReportParams({...tempReportParams});
                setFields([...cols]);
                setRecords([...rows]);
                setRowSort({...tempRowSort});
            }
            else {
                getOperationsReportHeaders(type);
            }
            if (type === constants.ACTIVITIES) {
                tabObject = constants.ACTIVITIES;
            }
            else {
                
                tabObject = constants.CONVERSIONS;
           
        }
           let label = getLabelName(tab.label, tab.object);
            dispatch(setHeader(tabObject, label, null, false, true));
            setReportParams({...tempReportParams});
    },[]);
    
    useEffect(() => {
        setCustomTimeLineDisplay('none');
        let tab = getActiveTab();
        let info = getActiveTabInfo();
        let tempReportParams = reportParams;
        let tempRowSort = rowSort;
        let tabObject = '';
        tempRowSort.isDescending = false;
        getOperationsReportHeaders(type);
        if (type === constants.ACTIVITIES) {
            tabObject = constants.ACTIVITIES;
        }
        else if (type === constants.CONVERSIONS) {
            tabObject = constants.CONVERSIONS;
        }
        tempReportParams.due_by = 'td';
        setReportParams(tempReportParams);
        let label = getLabelName(tab.label, tab.object);
        dispatch(setHeader(tabObject, label, null, false, true));
        setIsMounted(false);
        setFields([]);
        setRecords([]);
        setRowSort(tempRowSort);
         
    },[reportParams,rowSort,type,dummy]);

    const getLabelName = (label, object) => {
        let labelName = "";
        if (object === 'operations-activities') {
            labelName = getLocalizedStrings().label.HEADER['Activities'];
        }
        if (object === 'operations-conversions') {
            labelName = getLocalizedStrings().label.HEADER['Conversions'];
        }
        return labelName;
    }

    const getMultilingualOptionsMap = () => {
        let optionsMultilingual = {
            'Today': getLocalizedStrings().label.DASHBOARD.TODAY,
            'Yesterday': getLocalizedStrings().label.DASHBOARD.YESTERDAY,
            'This Week': getLocalizedStrings().label.DASHBOARD.THIS_WEEK,
            'This Month': getLocalizedStrings().label.DASHBOARD.THIS_MONTH,
            'This Quarter': getLocalizedStrings().label.DASHBOARD.THIS_QUARTER,
            'This Year': getLocalizedStrings().label.DASHBOARD.THIS_YEAR,
            'Last Week': getLocalizedStrings().label.DASHBOARD.LAST_WEEK,
            'Last Month': getLocalizedStrings().label.DASHBOARD.LAST_MONTH,
            'Last Quarter': getLocalizedStrings().label.DASHBOARD.LAST_QUARTER,
            'Last Year': getLocalizedStrings().label.DASHBOARD.LAST_YEAR,
            'Custom': getLocalizedStrings().label.DASHBOARD.CUSTOM,
            
        };
        return optionsMultilingual;
    }

    const generateDateSelectionField = () => {
        let optionsMultilingual = getMultilingualOptionsMap();
        try {
            return Object.keys(optionvalue).map((itemKey) => {
                return <MenuItem key={itemKey} value={itemKey} style={styles.popoverMenuItem} >{optionsMultilingual[optionvalue[itemKey]]}</MenuItem>;
            });
        }
        catch (error) {
            console.error("Error in 'operationsReports.js -> generateDateSelectionField()':" + error);
        }
    }

    const handleRequestClose = (fieldName) => {
        try {
            setFieldName(false);
        }
        catch (error) {
            console.error("Error in 'operationsReports.js -> handleRequestClose()':" + error);
        }
    };

    const handleDropDownChange = (name, value) => {
        try {
            if (value === "cust") {
                setCustomTimeLineDisplay('block');
                let tempDate = moment(String(new Date())).format(dateFormat[app.me.date_format]);
                setFrom_date(tempDate);
                setTo_date(tempDate);
                let _date = moment(tempDate)
                _date =  _date.format('YYYY-MM-DD');
                let tempReportParams = reportParams;
                tempReportParams.hdn_from_date = _date;
                tempReportParams.hdn_to_date = _date;
                let tempRowSort = rowSort;
                tempReportParams[name] = value;
                tempRowSort["isDescending"] = false;
                setReportParams(tempReportParams);
                setRowSort(tempRowSort);
                handleRequestClose(name + "_popup");
            }else{
                setCustomTimeLineDisplay('none');
                let tempReportParams = reportParams;
                let tempRowSort = rowSort;
                tempReportParams[name] = value;
                tempRowSort["isDescending"] = false;
                setReportParams(tempReportParams);
                setRowSort(tempRowSort);
                setIsMounted(false);
                handleRequestClose(name + "_popup");
                reloadReport();
                return;
            }
        }
        catch (error) {
            console.error("Error in 'operationsReports.js -> handleDropDownChange()':" + error);
        }
    }

    const getActivitiesReportFilters = () => {
        let datePickerOptions = {
            enableTime: false,
            dateFormat: dateFormatFlatPicker[app.me.date_format],
            minuteIncrement: 1,
            static: true
        }
        let buttonSet = getButtonSet();
        return (
            <div className="row" style={{  padding: '0px',marginBottom:'12px',display:'flex',width:'100%' }} >
                <div>
                    <Grid container spacing={0}>
                        <Grid item xs={12} style={{ display:'flex',marginBottom: '12px'}}>
                            <div style={{ display:'flex',marginLeft: "20px"}}>
                                <div style={{ padding: 0 }} >
                                    <FormControl variant="outlined" style={{ width: '100%' }} className='appontmentdd-FormControl'>
                                        <InputLabel id="sf-operationreports-simple-select-outlined-label" className="sf-operationreports">{getLocalizedStrings().label.REPORT.DATE_RANGE}</InputLabel>
                                        <Select
                                            key="due_by"
                                            name="due_by"
                                            labelId='noteTypeList-label'
                                            id='sf-duebylist'
                                            value={reportParams.due_by}
                                            style={{minWidth:250,maxWidth:250,height:40}}
                                            label={getLocalizedStrings().label.REPORT.DATE_RANGE}
                                            onChange={(e)=>handleDropDownChange('due_by',e.target.value)}
                                        >
                                        {generateDateSelectionField()}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div style={{ width: '100%',display:'flex' }}>
                                    {/* date_picker */}
                                    <div style={{ display: customTimeLineDisplay }}>
                                        <div id="dateholder" style={{ marginLeft: '10px', width: '8rem' }}>
                                            <PopOver
                                                id={'from_date'}
                                                key={'from_date'}
                                                name={'from_date'}
                                                btnType={'date'}
                                                buttonStyle={{ fontSize: '16px' }}
                                                containerStyle={{ lineHeight: 1 }}
                                                buttonLabel={getLocalizedStrings().label.REPORT.START_DATE}
                                                onChange={(date) => setFrom_date(date)}
                                                value={from_date}
                                                innerLabelStyle={{ paddingTop: '10px', fontSize: '15px', paddingLeft: '3px' }}
                                                height={40}
                                                options={datePickerOptions}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: customTimeLineDisplay }}>
                                        <div id="dateholder" style={{ marginLeft: '10px', width: '8rem' }}>
                                            <PopOver
                                                id={'to_date'}
                                                key={'to_date'}
                                                name={'to_date'}
                                                btnType={'date'}
                                                buttonStyle={{ fontSize: '16px' }}
                                                containerStyle={{ lineHeight: 1 }}
                                                buttonLabel={getLocalizedStrings().label.REPORT.END_DATE}
                                                onChange={(date) => setTo_date(date)}
                                                value={to_date}
                                                innerLabelStyle={{ paddingTop: '10px', fontSize: '15px', paddingLeft: '3px' }}
                                                height={40}
                                                options={{ ...datePickerOptions, minDate: from_date }}
                                            />
                                        </div>
                                    </div>
                                    {reportParams.due_by === 'cust' && <div style={{ width: '3rem',marginLeft:'15px'}}>
                                        <Button
                                            onClick={() => getReportDetails()}
                                            style={{
                                                ...styles.primaryButton,
                                                minWidth: '56px',
                                                width: '20px',
                                                marginTop: '5px',
                                                marginRight:'5px'
                                            }}
                                            title={getLocalizedStrings().label.REPORT.GO}
                                        > {getLocalizedStrings().label.REPORT.GO}</Button>
                                    </div>}
                                </div>
                            </div>
                            <div  style={{ marginTop: '5px' }}>{buttonSet}</div>
                        </Grid>
                    </Grid>
                </div>
        </div>
        )
    }

    const getReportDetails = () =>{
        let tempReportParams = reportParams;
        let tempRowSort = rowSort;
        let fromDate = moment(from_date)
        fromDate =  fromDate.format('YYYY-MM-DD');
        let toDate = moment(to_date)
        toDate =  toDate.format('YYYY-MM-DD');
        tempReportParams.hdn_from_date = fromDate;
        tempReportParams.hdn_to_date = toDate;
        let data = {};
        data.fromDate = fromDate;
        data.toDate = toDate;
        data.type = type;
        dispatch(updateOperationDuebyData(data));
        setReportParams(tempReportParams);
        setRowSort(tempRowSort);
        setIsMounted(false);
        reloadReport();
        return;
    }

    const getOperationsReportHeaders = (type) => {
        let tempReportParams = reportParams;
        let url = endPoints.OPERATIONS_REPORT.HEADERS_GET;
        let params ={};
        try {
            params.report_type = getStringParam(type);
            let promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                tempReportParams.type = getStringParam(type);
                tempReportParams.parameterId = getStringParam(response.report_names.parameter_id);
                tempReportParams.reportName = getStringParam(response.report_names.item_name);
                tempReportParams.due_by = response.hasOwnProperty("recent_due_by") ? getStringParam(response.recent_due_by) : 'td';                setReportParams(tempReportParams);
                reloadReport();
            });
        } catch (error) {
            console.error("Error in 'operationsReports.js -> getOperationsReportHeaders()':" + error);
        }
    }

    const getConversionReportFilters = () => {
        let datePickerOptions = {
            enableTime: false,
            dateFormat: dateFormatFlatPicker[app.me.date_format],
            minuteIncrement: 1,
            static: true
        }
        let buttonSet = getButtonSet();
        return (
            <div className="row" style={{  padding: '0px',marginBottom:'12px' }} >
            <div >
                <Grid container spacing={0}>
                    <Grid item xs={12} style={{ display:'flex',marginLeft: "21px"}}>
                        <div style={{ display:'flex',marginLeft: "1px"}}>
                            <div style={{ padding: 0 }} >
                                <FormControl variant="outlined" style={{ width: '100%' }} className='appontmentdd-FormControl'>
                                    <InputLabel id="sf-operationreports-simple-select-outlined-label" className="sf-operationreports">{getLocalizedStrings().label.REPORT.DATE_RANGE}</InputLabel>
                                    <Select
                                        key="due_by"
                                        name="due_by"
                                        labelId='noteTypeList-label'
                                        id='sf-duebylist'
                                        value={reportParams.due_by}
                                        style={{ minWidth: 250, maxWidth: 250, height: 40 }}
                                        label={getLocalizedStrings().label.REPORT.DATE_RANGE}
                                        onChange={(e)=> handleDropDownChange('due_by',e.target.value)}
                                    >
                                    {generateDateSelectionField()}
                                    </Select>
                                </FormControl>
                            </div>  

                            <div style={{ width: '100%', display: 'flex' }}>
                                {/* date_picker */}
                                <div style={{ display: customTimeLineDisplay }}>
                                    <div id="dateholder" style={{ marginLeft: '10px', width: '8rem' }}>
                                        <PopOver
                                            id={'from_date'}
                                            key={'from_date'}
                                            name={'from_date'}
                                            btnType={'date'}
                                            buttonStyle={{ fontSize: '16px' }}
                                            containerStyle={{ lineHeight: 1 }}
                                            buttonLabel={getLocalizedStrings().label.REPORT.START_DATE}
                                            onChange={(date) => setFrom_date(date)}
                                            value={from_date}
                                            innerLabelStyle={{ paddingTop: '10px', fontSize: '15px', paddingLeft: '3px' }}
                                            height={40}
                                            options={datePickerOptions}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: customTimeLineDisplay }}>
                                    <div id="dateholder" style={{ marginLeft: '10px', width: '8rem' }}>
                                        <PopOver
                                            id={'to_date'}
                                            key={'to_date'}
                                            name={'to_date'}
                                            btnType={'date'}
                                            buttonStyle={{ fontSize: '16px' }}
                                            containerStyle={{ lineHeight: 1 }}
                                            buttonLabel={getLocalizedStrings().label.REPORT.END_DATE}
                                            onChange={(date) => setTo_date(date)}
                                            value={to_date}
                                            innerLabelStyle={{ paddingTop: '10px', fontSize: '15px', paddingLeft: '3px' }}
                                            height={40}
                                            options={{ ...datePickerOptions, minDate: from_date }}
                                        />
                                    </div>
                                </div>
                                {reportParams.due_by === 'cust' && <div style={{ width: '3rem', marginLeft: '15px' }}>
                                    <Button
                                        onClick={() => getReportDetails()}
                                        style={{
                                            ...styles.primaryButton,
                                            minWidth: '56px',
                                            width: '20px',
                                            marginTop: '5px',
                                            marginRight: '5px'
                                        }}
                                        title={getLocalizedStrings().label.REPORT.GO}
                                    > {getLocalizedStrings().label.REPORT.GO}</Button>
                                </div>}
                            </div>  
                        </div>
                        <div  style={{ marginTop: '10px' }}>{buttonSet}</div>
                    </Grid>
                </Grid>
            </div>
        </div>)
    }

    const getReportFilter = (type) => {
        let headerComponent = null;
        if (getStringParam(type).length > 0 && type === constants.ACTIVITIES) {
            headerComponent = getActivitiesReportFilters();
        }
        else if (getStringParam(type).length > 0 && type === constants.CONVERSIONS) {
            headerComponent = getConversionReportFilters()
        }
        return headerComponent;
    }

    const generateHeadersToPrint = (records,fields) => {
        let cells = '', temp;
        let tempColumns = getArrayParam(fields);
        let columns = tempColumns.filter(f => {
            if (!f.is_hidden) {
                temp = f;
            }
            return temp;
        });
        columns.forEach(f => {
            cells = cells + '<th style="border:1px solid black;"> ' + f.label + ' </th>';
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    const generateRowsToPrint = (records,fields) => {
        let rows = getArrayParam(records);
        let columns = getArrayParam(fields);
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            rows.forEach((rowObject, rowIndex) => {
                let innerCells = '';
                columns.forEach((columnObject, colIndex) => {
                    if (!columnObject.is_hidden) {
                        if(rowObject[columnObject.name] !== undefined)
                        {
                            innerCells = innerCells + '<td style="border:1px solid black;">' + rowObject[columnObject.name] + ' </td>';
                        }else if(rowObject[columnObject.name] == undefined){
                            innerCells = innerCells + '<td style="border:1px solid black;">' + ' ' + ' </td>';
                        }
                    }
                })
                cells = cells + ('<tr>' + innerCells + ' </tr>');
            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '" style="border:1px solid black;"> No data Found  </td>  </tr>');
        }
        return rowData;

    }

    const pdfGenerateBeforeHeaders = () =>{
        let optionsMultilingual = getMultilingualOptionsMap();
        let _reportParams = getObjectParam(reportParams);
        let cells = '';
        let dueBy = '';
        let tempReportName = getStringParam(_reportParams.reportName);
        let columns = fields.filter(f => {        
            let temp;
            if (!f.is_hidden) { 
                temp= f; 
            }
            return temp;
        });
        let columnsLength = getArrayParam(columns).length;

        cells = cells + '<tr><td  colspan="' + (columnsLength) + '"> ' + tempReportName + ' </td></tr>';
        if (tempReportName.length > 0) {
            dueBy = getStringParam(optionsMultilingual[optionvalue[_reportParams.due_by]]);
            if(_reportParams.due_by === 'cust'){
                let fromDate,toDate;
                if(_reportParams.hdn_from_date){
                    fromDate = moment(_reportParams.hdn_from_date)
                    fromDate =  fromDate.format('YYYY/MM/DD');
                }else{
                    fromDate = moment(from_date)
                    fromDate =  fromDate.format('YYYY/MM/DD');
                }
                if(_reportParams.hdn_to_date){
                    toDate = moment(_reportParams.hdn_to_date)
                    toDate =  toDate.format('YYYY/MM/DD');
                }else{
                    toDate = moment(to_date)
                    toDate =  toDate.format('YYYY/MM/DD');
                }

                cells = cells + '<tr><td> Date :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + dueBy +" (" + fromDate + " - " + toDate + ")"+' </td></tr>';

            }else{
                cells = cells + '<tr><td> Date :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + dueBy + ' </td></tr>';
            }
        }
        return cells;
    }

    function printPdf() {
        let _fields = fields;
        let _records = records;
        if (getArrayParam(_records).length > 0 && getArrayParam(_fields).length > 0) {
            _fields = _fields.filter((ele)=>{return ele.is_hidden !== true});
            let beforeHeaderComponent = pdfGenerateBeforeHeaders();
            let headerComponents = generateHeadersToPrint(_records,_fields);
            let rowComponents = generateRowsToPrint(_records,_fields);
            let htmlTable = '<table style="border:1px solid black;border-collapse:collapse;">'+'<thead>' + beforeHeaderComponent + '</thead><thead>'  + headerComponents + ' </thead><tbody>' + rowComponents + '  </tbody> </table>';
            let htmlData = htmlTable;
            if (isValidParam(htmlData) && htmlData !== '') {
                var ifrmae = document.getElementById("ifmcontentstoprint");
                ifrmae.style.display = 'block'
                var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                ifrmae.style.display = 'block'

                pri.document.open();
                pri.document.write(htmlData);
                pri.document.close();
                pri.focus();
                pri.print();
                ifrmae.style.display = 'none';
            }
        }
    }

    const getButtonSet = () => {
        return (
            <div style={{marginTop:type === "conversions" ? '-5px':''}}>
                <iframe title="ifmcontentstoprint" id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute', display: 'none' }}></iframe>
                <Button
                    labelposition="after"
                    onClick={() => { printPdf(); }}
                    style={{
                        ...styles.secondaryButton,
                        minWidth: '75px',
                        marginLeft: '23px'
                    }}
                    title={getLocalizedStrings().label.REPORT.PRINT}
                >{getLocalizedStrings().label.REPORT.PRINT}</Button>
                <Button
                    startIcon={<i className="fas fa-file-excel"  style={{ fontSize: '20px', color: 'green' }} ></i> }
                    onClick={() => { downloadExcel() }}
                    style={{
                        ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                        color: '#717171',
                        border: '0px',
                        minWidth: '30px'
                    }}
                    title="Excel"
                />
            </div>
        );
    }

    const getOperationsReportId = (reportTypeName) => {
        let reportId = 0;
        try {
            if (isValidParam(reportTypeName)) {
                if (reportTypeName == constants.ACTIVITIES) {
                    reportId = OPERATIONS_REPORTS_MAP.TYPE_FRANCHISE_ACTIVITY_REPORT;
                }
                else if (reportTypeName == constants.CONVERSIONS) {
                    reportId = OPERATIONS_REPORTS_MAP.TYPE_FRANCHISE_CONVERSION_REPORT;
                }
            }
        } catch (error) {
            console.error("Error in 'operationsReports.js -> getOperationsReportId()':" + error);
        }
        return reportId;
    }

    const reloadReport = (params) => {
        try {
            params = getObjectParam(params);
            let fields = null;
            let records = null;
            let _toDate, _fromDate = '';
            let url = endPoints.OPERATIONS_REPORT.OPERATION_REPORT_GET;
            let tempReportParams = getObjectParam(reportParams);
            let reportId = getOperationsReportId(tempReportParams.type);
            if (tempReportParams.type === constants.ACTIVITIES || tempReportParams.type === constants.CONVERSIONS) {
                if (tempReportParams.due_by === 'cust' && (type === dueByData.type || dueByData.type === undefined)) {
                    setCustomTimeLineDisplay('block');
                    if (tempReportParams.hdn_from_date === '' && tempReportParams.hdn_to_date === '') {
                        if (!to_date && !from_date && dueByData.fromDate && dueByData.toDate) {
                            _fromDate = dueByData.fromDate;
                            _toDate = dueByData.toDate;
                            let tempfromDate = moment(_fromDate).format(dateFormat[app.me.date_format]);
                            let tempToDate = moment(_toDate).format(dateFormat[app.me.date_format]);
                            setFrom_date(tempfromDate);
                            setTo_date(tempToDate);
                            setCustomTimeLineDisplay('block');
                        } else if (!to_date && !from_date && !dueByData.fromDate && !dueByData.toDate) {
                            let tempDate = moment(String(new Date())).format(dateFormat[app.me.date_format]);
                            setFrom_date(tempDate);
                            setTo_date(tempDate);
                            setCustomTimeLineDisplay('block');
                            let currentDate = moment(tempDate);
                            currentDate = currentDate.format('YYYY-MM-DD');
                            _fromDate = currentDate;
                            _toDate = currentDate;
                        }
                    } else {
                        _fromDate = tempReportParams.hdn_from_date;
                        _toDate = tempReportParams.hdn_to_date;
                    }
                }
                else{
                    if(tempReportParams.due_by === 'cust'){
                        let tempDate = moment(String(new Date())).format(dateFormat[app.me.date_format]);
                        setFrom_date(tempDate);
                        setTo_date(tempDate);
                        setCustomTimeLineDisplay('block');
                        let currentDate = moment(tempDate);
                        currentDate = currentDate.format('YYYY-MM-DD');
                        _fromDate = currentDate;
                        _toDate = currentDate;  
                    }
                }
                let activeTab = getActiveTab();
                let info = getObjectParam(activeTab.info);
                info.dueBy = tempReportParams.due_by;
                info.reportName = tempReportParams.reportName;
                info.parameterId = tempReportParams.parameterId;
                info.type = tempReportParams.type;
                info.isDescending = getBooleanParam(params.isDescending);
                if (tempReportParams.due_by === 'cust') {
                    info.hdn_from_date = _fromDate;
                    info.hdn_to_date = _toDate;
                }
                if (tempReportParams.type === constants.ACTIVITIES || tempReportParams.type === constants.CONVERSIONS) {
                    info.sortFieldName = "franchise_name";
                }
                updateTabInfo(info);
            }

            if (tempReportParams.type === constants.ACTIVITIES || tempReportParams.type === constants.CONVERSIONS) {
                params.parameter_id = tempReportParams.parameterId;
                params.report_id = reportId;
                params.report_type_id = reportId;
                params.due_by = tempReportParams.due_by;
                params.drill_down = "1";
                params.start_index = getIntParam(startIndex);
                params.page_size = getIntParam(fetchSize);
                params.item_type = getStringParam(tempReportParams.reportName);//OPERATION_REPORT_ACTIVITIES
                params.recent_record_id = getIntParam(tempReportParams.recentReportId);
                if (tempReportParams.due_by === 'cust') {
                    params.hdn_from_date = _fromDate;
                    params.hdn_to_date = _toDate;
                }
            }
            let promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let tempRowSort = getObjectParam(rowSort);
                    if (tempReportParams.type === constants.ACTIVITIES || tempReportParams.type === constants.CONVERSIONS) {
                        dispatch(updateOperationsReportData(response));
                    }
                    fields = getArrayParam(response.fields);
                    records = getArrayParam(response.records);
                    fields.forEach((col, i) => {
                        if ((col.field_type === "integer" || col.name === "convert_percent") && !col.is_hidden) {
                            col.style = { textAlign: 'center' };
                        }
                    });
                    if (fields.length > 0) {
                        let arr = fields.filter(f => { return f.is_sortable });
                        let sortFieldName = arr.length > 0 ? arr[0].name : '';
                        tempRowSort["sortFieldName"] = sortFieldName;

                    }
                    if (tempReportParams.type === constants.ACTIVITIES || tempReportParams.type === constants.CONVERSIONS) {
                        dispatch(updateOperationsReportData(response));

                        let sortInfo = getOrderInfoData();
                        if (sortInfo !== null && Object.keys(sortInfo).length > 0) {
                            let fieldName = sortInfo.fieldName;
                            let orderType = sortInfo.orderType;
                            sortArrayObjectByPropertyOrderType(records, fieldName, orderType);
                            tempRowSort["sortFieldName"] = fieldName;
                            tempRowSort["isDescending"] = orderType === 'asc' ? false : true;
                        }

                    }
                    setIsMounted(true);
                    setFields(fields);
                    setRecords(records);
                    setRowSort(tempRowSort);
                    setReportParams(tempReportParams);
                }
            });
        }
        catch (error) {
            console.error("Error in 'operationsReports.js -> reloadReport()':" + error);
        }
    }

    const onSortFn = (fieldName, isDescending,recordsData) => {
        try {
            let tempRecords = recordsData;
            let orderType = isDescending ? 'desc' : 'asc';
            sortArrayObjectByPropertyOrderType(tempRecords, fieldName, orderType);
            setOrderInfoData(fieldName, orderType);
            setRecords(tempRecords);
        }
        catch (error) {
            console.error("Error in 'operationsReports.js -> onSort()':" + error);
        }
    }

    const getOrderInfoData = () => {
        try {
            let orderInfo = {};
            let cookieName = 'operations_reports_order' + object + '_' + app.me.projectId + '_' + app.me.id;
            let cookieValue = getObjectParam(JSON.parse(getCookie(cookieName)));
            if (Object.keys(cookieValue).length === 0) {
                return orderInfo;
            } else {
                let fieldName = cookieValue['fieldName'];
                let orderType = cookieValue['orderType'];
                orderInfo.fieldName = fieldName;
                orderInfo.orderType = orderType;
                return orderInfo;
            }
        } catch (error) {
            console.error("Error in 'operationsReports.js -> getOrderInfoData()':" + error);
        }
    }

    const setOrderInfoData = (fieldName, orderType) => {
        try {
            let orderInfo = {}
            if (fieldName !== null && getStringParam(fieldName) !== '' && orderType !== null && getStringParam(orderType) !== '') {
                let cookieName = 'operations_reports_order' + object + '_' + app.me.projectId + '_' + app.me.id;
                orderInfo.fieldName = fieldName;
                orderInfo.orderType = orderType;
                setCookie(cookieName, JSON.stringify(orderInfo), constants.DEFAULT_COOKIE_EXPIRES);
            }

        } catch (error) {
            console.error("Error in 'operationsReports.js -> setOrderInfoData()':" + error);
        }
    }

    const customEvent = (eventType,column, row, event) => {
        if (isValidParam(column) && column.name === "franchise_name") {
            openDetailView(column, row);
        }
        else {
            openListView(column, row);
        }
    }

    const openListView = (column, row) => {
        if (isValidParam(row) && isValidParam(column) && row[column.name] > 0) {
            let object = constants.ACCOUNTS_OBJECT;
            let url = '/' + object + '/queries';
            let label = getStringParam(column.label);
            let projectId = getIntParam(row.myclient_id);
            let activeTab = getObjectParam(getActiveTab());
            let activeTabIndex = getIntParam(activeTab.index);
            let tab = { label: label, object: object, type: TYPE_LIST_VIEW, parentTabIndex: activeTabIndex, url: url };
            let tabInfo = {};
            tabInfo.filter = { id: 542, name: 'All records', type: constants.SEARCH_QUERY_TYPE_ALL };
            tabInfo.isReport = true;
            tabInfo.isInXpress = true;
            tabInfo.inXpressProjectId = projectId;
            if (reportParams.type === constants.CONVERSIONS && label === 'New Customers') {
                tabInfo.input_param = {
                    query_id: 716,
                    report_to_date: "",
                    query_name: "",
                    report_due_by: getStringParam(reportParams.due_by),
                    group_by_field_name: "t_status",
                    group_by_condition: 'Customer',
                    query_type: "11",
                    report_from_date: "",
                    report_type: "11",
                    is_report: true,
                    report_action_coach_type: "102"
                }
                
                if(reportParams.due_by === 'cust'){
                    let _fromDate = moment(from_date);
                    let _toDate = moment(to_date);
                    _fromDate =  _fromDate.format('YYYY-MM-DD');
                    _toDate =  _toDate.format('YYYY-MM-DD');
                    tabInfo.input_param.report_from_date = _fromDate;
                    tabInfo.input_param.report_to_date = _toDate
                }
            }
            else {
                tabInfo.input_param = {
                    query_id: 716,
                    query_type: "11",
                    query_name: "",
                    report_due_by: getStringParam(reportParams.due_by),
                    report_type: 201,
                    is_report: true
                }
                if(reportParams.due_by === 'cust'){
                    let _fromDate = moment(from_date);
                    let _toDate = moment(to_date);
                    _fromDate =  _fromDate.format('YYYY-MM-DD');
                    _toDate =  _toDate.format('YYYY-MM-DD');
                    tabInfo.input_param.report_from_date = _fromDate;
                    tabInfo.input_param.report_to_date = _toDate
                }
            }
            tabInfo.reportLabel = label;
            tab.info = tabInfo;
            addTab(tab, true);
        }
    }

    const openDetailView = (column, row) => {
        let activeTab = getObjectParam(getActiveTab());
        let activeTabIndex = getIntParam(activeTab.index);
        let object = constants.ACCOUNTS_OBJECT;
        let recordId = row["record_id"];
        let label = getStringParam(row[column.name]);
        let url = '/' + object + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + recordId;
        let tab = { label: label, object: object, type: TYPE_DETAIL_VIEW, imgName: null, url: url, isActive: true, parentTabIndex: activeTabIndex };

        addTab(tab, true);
    }

    const excelGenerateBeforeHeaders = () => {
        let optionsMultilingual = getMultilingualOptionsMap();
        let _reportParams = getObjectParam(reportParams);
        let cells = '';
        let dueBy = '';
        let tempReportName = getStringParam(_reportParams.reportName);
        let columns = fields.filter(f => {        
            let temp;
            if (!f.is_hidden) { 
                temp= f; 
            }
            return temp;
        });
        let columnsLength = getArrayParam(columns).length;

        cells = cells + '<tr><td  colspan="' + (columnsLength) + '"> ' + tempReportName + ' </td></tr>';
        if (tempReportName.length > 0) {
            dueBy = getStringParam(optionsMultilingual[optionvalue[_reportParams.due_by]]);
            if(_reportParams.due_by === 'cust'){
                let fromDate,toDate;
                if(_reportParams.hdn_from_date){
                    fromDate = moment(_reportParams.hdn_from_date)
                    fromDate =  fromDate.format('YYYY/MM/DD');
                }else{
                    fromDate = moment(from_date)
                    fromDate =  fromDate.format('YYYY/MM/DD');
                }
                if(_reportParams.hdn_to_date){
                    toDate = moment(_reportParams.hdn_to_date)
                    toDate =  toDate.format('YYYY/MM/DD');
                }else{
                    toDate = moment(to_date)
                    toDate =  toDate.format('YYYY/MM/DD');
                }

                cells = cells + '<tr><td> Date :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + dueBy +" (" + fromDate + " - " + toDate + ")"+' </td></tr>';

            }else{
                cells = cells + '<tr><td> Date :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + dueBy + ' </td></tr>';
            }
        }
        return cells;
    }

    const excelGenerateHeaders = () => {
        let cells = '',temp;
        let columns = fields.filter(f => {
            if (!f.is_hidden) { temp=f; }
            return temp;
        });
        columns.forEach(f => {
            cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;

    }

    const excelGenerateRows = () => {
        let rows = getArrayParam(records);
        let columns = getArrayParam(fields);
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            rows.forEach((rowObject, rowIndex) => {
                let innerCells = '';
                columns.forEach((columnObject, colIndex) => {
                    if (!columnObject.is_hidden) {
                        innerCells = innerCells + '<td >' + rowObject[columnObject.name] + ' </td>';
                    }
                })
                if (rowIndex % 2 === 0) {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                } else {
                    cells = cells + ('<tr  bgcolor="Lavender">' + innerCells + ' </tr>');
                }
            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
        }
        return rowData;
    }

    const downloadExcel = () => {
        let beforeHeaderComponent = excelGenerateBeforeHeaders();
        let headerComponents = excelGenerateHeaders();
        let rowComponents = excelGenerateRows();

        let htmlTable = '<table border="1">' + beforeHeaderComponent +
            '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '  </tbody> </table>';
        let htmlData = htmlTable;
        if (isValidParam(htmlData) && htmlData !== '') {
            htmlData = htmlData.replace("%22", "\"");
            let blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
            let downloadUrl = URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = downloadUrl;
            a.download = 'OperationsResultSet_' + new Date().getTime() + '.xls';
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    }

    let _style = { margin: 10, width: '98%', paddingTop: 0, paddingLeft: 11 };
    _style = { ..._style, ...getObjectParam(style) };
    let reportType = isValidParam(type) ? getStringParam(type) : "";
    let headerComponent = getReportFilter(reportType);
    let noRecordsTitle = reportParams.type === constants.ACTIVITIES ? constants.ACTIVITIES : constants.CONVERSIONS;

    let contentHeight = document.body.clientHeight - 300;
    let top = (contentHeight - 10) / 2;

     return (
        <div style={style}>
            <div>
                {headerComponent}
                {isMounted ?
                    <div id="divcontents" style={{marginLeft:'21px',marginRight:'16px'}}>
                        {records.length > 0 ? <SFDataTable columns={fields} rowSort={rowSort} rows={records} customEvent={(eventType,column, row, event)=>customEvent(eventType,column, row, event)} /> :
                            <NoRecords key={'no-records'} object={noRecordsTitle} />
                        }
                    </div>
                    :
                     <div style={{ width: '100%', height: contentHeight }}>
                         <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                             <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                 <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                             </div>
                         </div>
                     </div>
                }
            </div>
        </div>
    );
}
export default OperationsReports;
