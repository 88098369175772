import { actions } from '..//constants/actions';
import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';

function getTemplateList() {
    return {
        type: actions.AUTOMATION_CAMPAIGN_TEMPLATES_GET,
        payload: HTTPClient.get(endPoints.AUTOMATION_DESIGNER.TEMPLATES_GET, null)
    }
}

export { getTemplateList }