import React from 'react';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { styles } from '../../../services/constants/styles';
import Button from '@mui/material/Button';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { Radio, RadioGroup, FormControl, FormControlLabel, TextField } from '@mui/material'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { savePhoneTextIntegration, getPhoneTextIntegration } from '../../../services/actions/UserListAction';
import { setPhoneTextProvider, updateManageUnitPhoneChange } from '../../../services/actions/appActions';
import { constants } from '../../../services/constants/constants';
import * as sfDialogs from '../components/sfDialogs';
import Checkbox from '@mui/material/Checkbox';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

const PhoneTextIntegration = ({ }) => {

    const dispatch = useDispatch();
    const [phoneTextintegrationOption, setPhoneTextintegrationOption] = useState();
    const [accountSid, setAccountSid] = useState('');
    const [authToken, setAuthToken] = useState('');
    const [fieldType, setFieldType] = useState('text');
    const [previousProvider, setPreviousProvider] = useState('');
    const [isManageUnitPhone, setIsManageUnitPhone] = useState(false);
    const [isTipOpen, setIsTipOpen] = useState(false);
    let app = useSelector((state) => state.app);


    useEffect(() => {
        getPhoneTextIntegrationDetails();
    }, []);

    const changePhoneTextIntegration = (value) => {
        setPhoneTextintegrationOption(value);
        if (app.me.is_franchisor && value == constants.SOFFRONT) {
            setIsManageUnitPhone(true);
        } else {
            setIsManageUnitPhone(false);
        }
    }

    const onChangeTextField = (value, fieldName) => {
        if (fieldName == 'accountSid') {
            setAccountSid(value);
        } else if (fieldName === 'authToken') {
            setAuthToken(value);
        }
    }

    const getPhoneTextIntegrationDetails = () => {
        try {
            let promise = getPhoneTextIntegration();
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        setPhoneTextIntegrationDetails(response);
                    }
                });
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const setPhoneTextIntegrationDetails = (data) => {
        if (data != null) {
            const _provider = data.provider ?? "";
            const _accountSid = data.account_sid ?? "";
            const _authtoken = data.auth_token ?? "";
            const _isManageUnitPhone = data.is_manage_unit_phone ?? false;
            setPhoneTextintegrationOption(_provider);
            setPreviousProvider(_provider);
            setAccountSid(_accountSid);
            setAuthToken(_authtoken);
            setIsManageUnitPhone(_isManageUnitPhone);
            if (_provider != null && _provider != "" && _provider.trim() == constants.TWILIO) {
                if (_accountSid != null && _accountSid != "" && _authtoken != null && _authtoken != "") {
                    setFieldType('password');
                }
            }
        }
    }

    const savePhoneTextIntegrationSetup = () => {
        try {
            let valid = true;
            let _provider = phoneTextintegrationOption;
            let _previousProvider = previousProvider;
            let _accountSid = accountSid;
            let _authToken = authToken;
            let _isManageUnitPhone = isManageUnitPhone;
            if (_provider === "") {
                valid = false;
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "Please select a provider", null);
            }
            if (_provider == constants.TWILIO) {

                if (_accountSid === null || _accountSid === "" || _accountSid === undefined) {
                    valid = false;
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "Account SID cannot be blank.", null);
                } else if (_authToken === null || _authToken === "" || _authToken === undefined) {
                    valid = false;
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "Auth token  cannot be blank.", null);
                }
            }
            if (valid) {
                let param = {
                    provider: _provider,
                };
                if(_provider == constants.NONE  || _provider == constants.TWILIO){
                    param = {
                        ...param,
                        is_manage_unit_phone: _isManageUnitPhone,
                    }
                }
                if (_provider == constants.TWILIO) {
                    param = {
                        ...param,
                        account_sid: _accountSid,
                        auth_token: _authToken,
                    }
                }
                if (_provider == constants.SOFFRONT && app.me.is_franchisor) {
                    param = {
                        ...param,
                        is_manage_unit_phone: _isManageUnitPhone,
                    }
                }

                if ((_previousProvider == constants.SOFFRONT || _previousProvider == constants.TWILIO) && _provider == constants.NONE) {
                    let confirmMsg = getLocalizedStrings().message.PHONE_AND_TEXT_INTEGRATION.NONE_SELECT_CONFIRM;
                    sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, confirmMsg, () => savePhoneTextInt(param, _provider, _isManageUnitPhone), () => onCancel());
                } else {
                    savePhoneTextInt(param, _provider, _isManageUnitPhone);
                }
            }
        } catch (error) {
            console.log(error.message);
        }

    }

    const savePhoneTextInt = (param, _provider, _isManageUnitPhone) => {
        try {
            let success_msg = getLocalizedStrings().message.PHONE_AND_TEXT_INTEGRATION.SAVE_SUCCESS;
            let promise = savePhoneTextIntegration(param);
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (response.status === 0) {
                            dispatch(showCustomSnackBar(success_msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            if (_provider == constants.NONE || _provider == constants.SOFFRONT) {
                                setAccountSid('');
                                setAuthToken('');
                            }
                            if (_provider == constants.NONE || _provider == constants.TWILIO) {
                                setIsManageUnitPhone(false);
                            }
                            dispatch(setPhoneTextProvider(_provider));
                            dispatch(updateManageUnitPhoneChange(_isManageUnitPhone));
                        } else {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.PHONE_AND_TEXT_INTEGRATION.SAVE_FAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        }
                    }
                });
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const onCancel = () => {
        let _previousProvider = previousProvider;
        setPhoneTextintegrationOption(_previousProvider);
    }

    const togglePasswpordVisibility = () => {
        setFieldType(fieldType === 'password' ? 'text' : 'password')
    }

    const onCheckedMangeUnitPhone = (isManageUnitPhone) => {
        setIsManageUnitPhone(isManageUnitPhone);
    };

    const handleTooltipClose = () => {
		setIsTipOpen(false);
	};
	const handleTooltipOpen = () => {
		setIsTipOpen(true);
	};

    return <div style={{ marginLeft: '3%' }}>
        <div style={{ borderBottom: '2.0px solid #afafaf5c' }}><span style={{ fontWeight: 'bold', fontSize: '20px' }}>{getLocalizedStrings().label.MY_INTEGRATION.PHONE_AND_TEXT_INTEGRATION}</span></div>

        <div style={{ marginTop: '10px' }} key={phoneTextintegrationOption}>
            <div><span style={{ fontWeight: 'bold', fontSize: '18px' }}>{getLocalizedStrings().label.MY_INTEGRATION.PHONE_AND_TEXT_PROVIDER}</span></div>
            <FormControl component="fieldset">
                <RadioGroup name="serviceradio" onChange={(e) => changePhoneTextIntegration(e.target.value)} defaultValue={phoneTextintegrationOption} style={{ marginTop: '10px', fontSize: '15px' }} >
                    <FormControlLabel value="None" control={<Radio color="default" />} label="None" />
                    <FormControlLabel value="Soffront" control={<Radio color="default" />} label="Soffront" />
                    {app.me.is_franchisor == true && phoneTextintegrationOption == "Soffront" && <div style={{ ...styles.row, marginLeft: '20px' }}>
                        <div style={{ ...styles.sf_12 }}>
                            <FormControlLabel control={<Checkbox color="default" id="manage_cb"
                                key="manage_cb"
                                name="manage_cb"
                                onChange={(e) =>
                                    onCheckedMangeUnitPhone(e.target.checked)
                                }
                                checked={isManageUnitPhone}
                            />} label=<span style={{ fontSize: 14 }}>{getLocalizedStrings().label.PHONE_AND_TEXT_INTEGRATION.MANAGE_UNIT_PHONE} </span> style={{ marginRight: '5px', padding: '0px' }} labelstyle={{ color: '#717171', margin: '0px', padding: '0px', fontWeight: 'normal' }} labelposition="right" />
                        </div>
                        <div style={{ width: '20px', marginTop: '2px' }}>
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                <Tooltip
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={isTipOpen}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={"You will be paying for all your franchisee's phone numbers and credits.If you uncheck this option, each franchisee has to get approval from Twilio for texting which can take weeks!"}
                                    placement="top"
                                    arrow>
                                    <Icon
                                        id='clickButton'
                                        color="primary"
                                        title={getLocalizedStrings().label.DETAIL_VIEW.CLICK_TO_KNOW_MORE}
                                        style={{ fontSize: "25px", verticalAlign: "middle", marginTop: '6px' }}
                                        onClick={handleTooltipOpen}>
                                        info_outline
                                    </Icon>
                                </Tooltip>
                            </ClickAwayListener>
                        </div>
                    </div>}
                    <FormControlLabel value="Twilio" control={<Radio color="default" />} label="Twilio" />
                </RadioGroup>
            </FormControl>
            {phoneTextintegrationOption == "Twilio" && <div style={{ ...styles.row, gap: 10 }}>
                <div style={{ ...styles.sf_6 }} >
                    <FormControl style={{ width: '100%', marginBottom: '20px', marginTop: '8px' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            name="accountSid"
                            id="accountSid"
                            type={fieldType}
                            value={accountSid}
                            label={"Account SID (required)"}
                            onChange={(e) => onChangeTextField(e.target.value, 'accountSid')}
                            autoComplete="new-password"
                            margin="dense"
                            size='small'
                            className={"sf-fields-bg"}
                        />
                    </FormControl>
                </div>
                <div style={{ ...styles.sf_6 }}>
                    <FormControl style={{ width: '100%', marginBottom: '20px', marginTop: '8px' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            name="authToken"
                            id="authToken"
                            value={authToken}
                            type={fieldType}
                            label={"Auth Token (required)"}
                            onChange={(e) => onChangeTextField(e.target.value, 'authToken')}
                            autoComplete="new-password"
                            margin="dense"
                            size='small'
                            className={"sf-fields-bg"}
                        />
                    </FormControl>
                </div>
                <div style={{ width: '50px' }}>
                    <i
                        className={
                            fieldType === 'text' ?
                                "fa fa-eye"
                                : "fa fa-eye-slash"
                        }
                        style={{
                            marginTop: "25px",
                            fontSize: 18,
                            cursor: "pointer",
                        }}
                        onClick={() => togglePasswpordVisibility()}
                    />
                </div>
            </div>}
            <div style={{ textAlign: 'right', display: 'table', width: '100%' }}>
                <div style={{ display: 'inline-block' }}>
                    <Button
                        key='save'
                        onClick={() => savePhoneTextIntegrationSetup()}

                        style={{ ...styles.primaryButton, marginLeft: '6px', marginTop: '1.5%', marginRight: '0px' }}
                    >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                </div>
            </div>

        </div>

    </div>;

}
export default PhoneTextIntegration