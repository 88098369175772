import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {styles} from '../../../services/constants/styles';
import {getLocalizedStrings} from '../../../services/constants/MultiLingual';
import {Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField} from '@mui/material';
import {
    getBooleanParam,
    getFloatParam,
    getIntParam,
    getStringParam,
    isFloat,
    isValidParam
} from '../../../services/helper/parameterVerifier';
import * as HTTPClient from '../../../services/helper/httpClient';
import {endPoints} from '../../../services/constants/endPoints';
import {showCustomSnackBar} from '../../../services/actions/snackBarAction';
import {getAppDrawer} from '../../../services/actions/appContainerActions';
import {dateFormat, dateFormatFlatPicker} from '../../../services/helper/utils';
import moment from 'moment';
import {refreshListView} from '../../../services/actions/listViewAcions';
import {addTab, TYPE_DETAIL_VIEW} from '../../../services/helper/sfTabManager';
import PopOver from '../components/PopOver';


const CreateCoupon = ({object}) => {
    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);

    const [couponName, setCouponName] = useState('');
    const [discountType, setDiscountType] = useState('Fixed Amount');
    const [discountValue, setDiscountValue] = useState('');
    const [couponPrefix, setCouponPrefix] = useState('FREE');
    const [claimLimit, setClaimLimit] = useState('1');
    const [claimTime, setClaimTime] = useState('anyTime');
    const [expiration_date, setExpiration_date] = useState(moment().add(1, 'days').format(dateFormat[app.me.date_format]));
    const [placeholderDiscountValue, setPlaceholderDiscountValue] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [service, setService] = useState('');

    useEffect(() => {
        setAmountPlaceholder(discountType);
    }, [discountType]);

    const handleChange = (event, updateStateFieldValue) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        updateStateFieldValue(value);
        if (name === 'discountType') {
            setAmountPlaceholder(value);
        }
    };


    const setAmountPlaceholder = (value) => {
        let placeholderVal = (value === 'fixedAmount') ? " Fixed Amount" : (value === 'percentage' ? "Percentage" : "")
        placeholderVal = placeholderVal + " " + getLocalizedStrings().label.COMMON.REQUIRED;
        setPlaceholderDiscountValue(placeholderVal);
    }

    const getActionButtons = () => {
        let buttons = [];
        buttons.push(
            <Button
                key='save'
                onClick={() => validateFormData()}

                style={{...styles.primaryButton, marginRight: '0px', verticalAlign: 'top'}}
                disabled={isSaving}
            >{getLocalizedStrings().label.COMMON.SAVE}</Button>
        );
        buttons.push(
            <Button
                onClick={() => closeDrawer()}

                style={{...styles.secondaryButton, display: 'inline-grid', marginLeft: '6px', marginRight: '0px'}}
            >  {getLocalizedStrings().label.COMMON.CANCEL}</Button>
        );
        return buttons;
    }
    const setCouponPrefixDefaultValue = () => {
        let coupon = getStringParam(couponPrefix).trim()
        if (coupon === '') {
            setCouponPrefix('FREE');
        }
    }


    const validateFormData = () => {
        let tCouponName = getStringParam(couponName).trim();
        let tDiscountAmount = getStringParam(discountValue).trim();
        let dateVal = expiration_date;
        let tClaimTime = getStringParam(claimTime);

        let isError = false;
        let errorFieldName = '';

        let errorMsg = getLocalizedStrings().message.HOME.FILTER_SQL_ERROR_NO_VALUE;
        if (!isValidParam(tCouponName) || tCouponName === '' || tCouponName === 'undefined') {
            errorFieldName = getLocalizedStrings().label.COUPONS['Coupon Name'];
            isError = true;
            setCouponName(tCouponName);

        } else if (!isValidParam(tDiscountAmount) || tDiscountAmount === '' || tDiscountAmount === 'undefined') {
            // checking for blank value
            errorFieldName = getLocalizedStrings().label.COUPONS['Discount Value'];
            isError = true;
            setDiscountValue(tDiscountAmount);

        } else if (tDiscountAmount < 0) {
            // checking for blank value
            errorMsg = getLocalizedStrings().message.COUPONS.NEGATIVE_COUPONS;
            errorFieldName = getLocalizedStrings().label.COUPONS['Discount Value'];
            isError = true;

        } else if ((!isFloat(tDiscountAmount) && tDiscountAmount !== '') || getFloatParam(tDiscountAmount) === 0.00) {
            // checking for Invalid Value
            errorMsg = getLocalizedStrings().message.COUPONS.INVALID;
            errorFieldName = getLocalizedStrings().label.COUPONS['Discount Value'];
            isError = true;
        } else if ((dateVal === '' || dateVal === null || dateVal === 'undefined') && tClaimTime === 'beforeDate') {
            // checking for empty Date
            errorMsg = getLocalizedStrings().message.COUPONS.SPECIFY_DATE;
            errorFieldName = "";
            isError = true;
        }
        if (isError) {
            dispatch(showCustomSnackBar(errorFieldName + " " + errorMsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else {
            processCoupons();
        }
    }

    const processCoupons = () => {
        try {
            let params = {};
            params.coupons_name = getStringParam(couponName);
            let promise = Promise.resolve(HTTPClient.get(endPoints.COUPONS.CHECK_DUPLICATE_COUPONS, params));
            let isCouponExist = false;
            promise.then((response) => {
                if (isValidParam(response)) {

                    isCouponExist = getBooleanParam(response.is_coupon_exist)
                    if (isCouponExist) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COUPONS.DUPLICATE_COUPONS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    } else {
                        saveCoupons();
                    }
                }
            });

        } catch (error) {
            console.error("Error in 'createCoupon.js -> processCoupons()':" + error);
        }


    }
    const saveCoupons = () => {
        try {
            setIsSaving(true);
            let params = {};
            params.coupons_name = getStringParam(couponName);
            params.discount_type = getStringParam(discountType);
            params.discount_value = getStringParam(discountValue);
            params.coupons_prefix = getStringParam(couponPrefix);
            params.coupons_claim_limit = getStringParam(claimLimit);
            params.coupons_claim_time = getStringParam(claimTime);
            params.service = getStringParam(service);

            if (claimTime === 'anyTime') {
                params.coupons_claim_date = 'claim before date';
            } else if (claimTime === 'beforeDate') {
                params.coupons_expired_date = formatDate(expiration_date);
            }

            let promise = Promise.resolve(HTTPClient.post(endPoints.COUPONS.SAVE, params));
            promise.then((response) => {
                if (isValidParam(response) && response.status === 0) {
                    closeDrawer();
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COUPONS.SAVE_SUCCESSFULLY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    refreshListView(object);
                    if (response.hasOwnProperty("data")) {
                        let id = getIntParam(response.data.id)
                        openAsDetailView(id);
                    }
                }
            });
        } catch (error) {
            console.error("Error in 'createCoupon.js -> saveCoupons()':" + error);
        }
    }

    const openAsDetailView = (id) => {
        try {
            let tab = {
                label: couponName,
                object: object,
                type: TYPE_DETAIL_VIEW,
                url: '/' + object + '/detailview/' + id,
            };
            addTab(tab, true);
        } catch (error) {
            console.error("Error in 'createCoupons.js -> openAsDetailView()':" + error);
        }
    }

    const closeDrawer = () => {
        dispatch(getAppDrawer(false, null, null, null, null));
    }

    const handleDateChange = (date, fieldName, updateStateFieldValue) => {
        let dateTimeDiv = document.getElementsByClassName("dateTimeDiv-" + fieldName);
        if (isValidParam(date) && date.length > 0) {
            let dateVal = new Date(date);
            let selectedDate = new Date(dateVal.getFullYear(), dateVal.getMonth(), dateVal.getDate());
            const momentDate = moment(selectedDate);
            selectedDate = momentDate.format(dateFormat[app.me.date_format]);
            if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
                dateTimeDiv[0].classList.add('label-top');
            }
            updateStateFieldValue(selectedDate);
        } else {
            if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
                dateTimeDiv[0].classList.remove('label-top');
            }
            updateStateFieldValue('');
        }
    }

    let sfFormFieldContainerStyle = {...styles.sfFormFieldContainer};

    let datePickerOptions = {
        enableTime: false,
        dateFormat: dateFormatFlatPicker[app.me.date_format],
        minuteIncrement: 1,
        minDate: new Date().fp_incr(1),
    };
    // let styleMarginRow ={
    //     style={ marginTop: 6, marginBottom: 6 }
    // };

    return (<div style={styles.sfFormContainer}>
        <TextHeader labelName={getLocalizedStrings().message.COUPONS.CREATE_COUPON_NAME}/>
        <div className="row" style={{marginBottom: 12}}>
            <div className='col-sm-12'>
                <FormControl style={{width: '100%'}} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        id="coupon_name"
                        key={"coupon_name_1"}
                        disabled={false}
                        label={getLocalizedStrings().label.COUPONS['Coupon Name'] + " " + getLocalizedStrings().label.COMMON.REQUIRED}
                        fullwidth={true}
                        autoFocus
                        name={"couponName"}
                        value={couponName}
                        onChange={(e) => handleChange(e, (v) => setCouponName(v))}
                        style={sfFormFieldContainerStyle}
                        className={"sf-fields-bg"}
                        inputprops={{maxlength: '100'}}
                        autoComplete="new-password"
                        margin='dense'
                        size='small'
                    />
                </FormControl>
            </div>
        </div>
        <TextHeader labelName={getLocalizedStrings().message.COUPONS.CREATE_DISCOUNT_TYPE}/>
        <div className="row">
            <div className='col-sm-12'>
                <FormControl component="fieldset">
                    <RadioGroup name="discountType" className="sf-data-fieldset"
                                onChange={(e) => handleChange(e, (v) => setDiscountType(v))} defaultValue={discountType}
                                style={{paddingRight: 80, marginTop: -3}}>
                        <FormControlLabel value="Fixed Amount" control={<Radio color="default"/>}
                                          label={getLocalizedStrings().message.COUPONS.FIXED_AMOUNT}/>
                        <FormControlLabel value="Percentage" control={<Radio color="default"/>}
                                          label={getLocalizedStrings().message.COUPONS.PERCENTAGE}/>
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
        <div className="row" style={{marginBottom: 12,}}>
            <div className='col-sm-12'>
                <FormControl style={{width: '100%'}} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        id="discountType_text"
                        key={"discountType_text_1"}
                        inputStyle={styles.textField.input}
                        disabled={false}
                        label={placeholderDiscountValue}
                        InputLabelProps={{
                            style: {
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                width: '96%',
                            }
                        }}
                        fullWidth={true}
                        name={"discountValue"}
                        value={discountValue}
                        onChange={(e) => handleChange(e, (v) => setDiscountValue(v))}
                        style={sfFormFieldContainerStyle}
                        className={"sf-fields-bg"}
                        inputprops={{maxlength: '100'}}
                        autoComplete="new-password"
                        margin='dense'
                        size='small'
                    />
                </FormControl>
            </div>
        </div>
        <TextHeader labelName={getLocalizedStrings().message.COUPONS.PREFIX_COUPON_CODE}/>
        <div className="row" style={{marginBottom: 12}}>
            <div className='col-sm-12'>
                <FormControl style={{width: '100%'}} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        id="coupon_prefix"
                        key={"coupon_prefix_1"}
                        inputStyle={styles.textField.input}
                        disabled={false}
                        label={getLocalizedStrings().label.COUPONS.PREFIX}
                        fullWidth={true}
                        name={"couponPrefix"}
                        defaultValue="FREE"
                        value={couponPrefix}
                        onChange={(e) => handleChange(e, (v) => setCouponPrefix(v))}
                        onBlur={() => setCouponPrefixDefaultValue()}
                        style={sfFormFieldContainerStyle}
                        className={"sf-fields-bg"}
                        inputprops={{maxlength: '4'}}
                        autoComplete="new-password"
                        margin='dense'
                        size='small'
                    />
                </FormControl>
            </div>
        </div>
        <TextHeader labelName={getLocalizedStrings().message.COUPONS.PODUCT_SERVICE}/>
        <div className="row" style={{marginBottom: 12}}>
            <div className='col-sm-12'>
                <FormControl style={{width: '100%'}} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        id="product_services"
                        key={"product_services_1"}
                        inputStyle={styles.textField.input}
                        disabled={false}
                        label={getLocalizedStrings().label.COUPONS.PODUCT_SERVICE}
                        fullWidth={true}
                        name={"service"}
                        value={service}
                        onChange={(e) => handleChange(e, (v) => setService(v))}
                        style={sfFormFieldContainerStyle}
                        className={"sf-fields-bg"}
                        inputprops={{maxlength: '100'}}
                        autoComplete="new-password"
                        margin='dense'
                        size='small'
                    />
                </FormControl>
            </div>
        </div>
        <TextHeader labelName={getLocalizedStrings().message.COUPONS.CAN_CLAIM_MORE_THAN_ONCE}/>
        <div className="row" style={{marginBottom: 12}}>
            <div className='col-sm-12'>
                <FormControl component="fieldset">
                    <RadioGroup name="claimLimit" className="sf-data-fieldset"
                                onChange={(e) => handleChange(e, (v) => setClaimLimit(v))} defaultValue={claimLimit}
                                style={{paddingRight: 80, marginTop: -3}}>
                        <FormControlLabel value="1" control={<Radio color="default"/>}
                                          label={getLocalizedStrings().message.COUPONS.CALIM_LIMIT_ONE}/>
                        <FormControlLabel value="0" control={<Radio color="default"/>}
                                          label={getLocalizedStrings().message.COUPONS.CLAIM_LIMIT_UNLIMITED}/>
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
        <TextHeader labelName={getLocalizedStrings().message.COUPONS.WHYEN_CLAIM_CODE}/>
        <div className="row">
            <div className='col-sm-12'>
                <FormControl component="fieldset">
                    <RadioGroup name="claimTime" className="sf-data-fieldset"
                                onChange={(e) => handleChange(e, (v) => setClaimTime(v))} defaultValue={claimTime}
                                style={{paddingRight: 80, marginTop: -3}}>
                        <FormControlLabel value="anyTime" control={<Radio color="default"/>}
                                          label={getLocalizedStrings().message.COUPONS.CLAIM_ANYTIME}/>
                        <FormControlLabel value="beforeDate" control={<Radio color="default"/>}
                                          label={getLocalizedStrings().message.COUPONS.CLAIM_BEFORE_DATE}/>
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
        {claimTime === 'beforeDate' &&
        <div className="row" style={{marginTop: 5}}>
            <div className='col-sm-12'>
                <div className={'sf-form-field dateTimeDiv-expiration_date label-top'} id="dateholder">
                    <PopOver
                        id={'expiration_date'}
                        className={'date-time anval'}
                        key={'expiration_date'}
                        name={'expiration_date'}
                        btnType={'date'}
                        buttonStyle={{fontSize: '16px'}}
                        containerStyle={{lineHeight: 1}}
                        buttonLabel={getLocalizedStrings().label.COUPONS.EXPIRATION_DATE}
                        onChange={(date, fieldName) => handleDateChange(date, fieldName, (v) => setExpiration_date(v))}
                        value={expiration_date}
                        innerLabelStyle={{padding: "8 5 2 5", fontSize: '15px'}}
                        height={45}
                        options={datePickerOptions}
                    />
                </div>
            </div>
        </div>
        }

        <div className="row" style={{marginBottom: '25px', marginTop: '25px'}}>
            <div className="col-sm-12" style={{textAlign: 'right'}}>
                {getActionButtons()}
            </div>
        </div>
    </div>)
}


const TextHeader = ({labelName}) => {
    let label = '';
    if (isValidParam(labelName)) {
        label = labelName + " ";
    }
    return (
        <div className="row" style={{marginTop: 6, marginBottom: 6}}>
            <div className='col-sm-12'>
                <span>{label}</span>
            </div>
        </div>

    )
}

export function updateCouponsStatus(params, recordId) {
    try {
        let url = endPoints.COUPONS.UPDATE_STATUS;
        url = url.replace('{recordid}', recordId);
        return HTTPClient.post(url, params);

    } catch (error) {
        console.error("Error in 'createCoupons.js -> updateStatus()':" + error)
    }
}

export function deleteCoupons(recordId) {
    try {
        let url = endPoints.COUPONS.DELETE;
        url = url.replace('{recordid}', recordId);
        return HTTPClient.post(url);

    } catch (error) {
        console.error("Error in 'createCoupons.js -> deleteCoupons()':" + error)
    }
}

function formatDate(date) {
    if (isValidParam(date)) {
        let d = date instanceof Date ? date : new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }
    return null;
}

export default CreateCoupon;

