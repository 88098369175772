
import React from 'react';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { isValidParam, getStringParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { getPhoneIntegrationSetup, saveRingCentralSetUp } from '../../../services/actions/UserListAction';
import CallSetup from '../components/callSetup';
import RingCentralSetup from '../components/ringCentralSetup';
import { getActiveTab, TYPE_LIST_VIEW, TYPE_DETAIL_VIEW } from '../../../services/helper/sfTabManager';
import { refreshDetailViewData } from '../../../services/actions/detailViewActions';
import { refreshListView } from '../../../services/actions/listViewAcions';
import { constants } from '../../../services/constants/constants';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from 'react-redux';


const PhoneIntegration = () => {
    let SOFFRONT = 'SOFFRONT';
    let RINGCENTRAL = 'RINGCENTRAL';
    const [selectedValue, setSelectedValue] = useState('SOFFRONT');

    const getPhoneIntegrationSetup = () => {
        try {
            let promise = getPhoneIntegrationSetup();
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (response.hasOwnProperty('is_ring_central') && getBooleanParam(response.is_ring_central)) {
                            setSelectedValue(RINGCENTRAL);
                        } else if (response.hasOwnProperty('is_soffront') && getBooleanParam(response.is_soffront)) {
                            setSelectedValue(SOFFRONT);
                        } else {
                            setSelectedValue(SOFFRONT);
                        }
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'phoneIntegration.js -> getPhoneIntegrationSetup()':" + error);
        }
    }

    const onChangeButton = (event, value) => {
        try {
            value = getStringParam(value);
            setSelectedValue(value);
        } catch (error) {
            console.error("Error in 'phoneIntegration.js -> onChangeButton()':" + error);
        }
    }

    return <div style={{ marginLeft: '3%' }}>
        <div style={{ borderBottom: '2.0px solid #afafaf5c' }}><span style={{ fontWeight: 'bold', fontSize: '22px' }}>{getLocalizedStrings().label.MY_INTEGRATION.PHONE_INTEGRATION}</span></div>
        <div style={{}}>
            {selectedValue == SOFFRONT && <SFPhoneSetup />}
            {selectedValue == RINGCENTRAL && <RingCentralSetup />}
        </div>
    </div>;
}

export default PhoneIntegration;


const SFPhoneSetup = () => {
    let CALL_SETUP = 'CALL_SETUP';
    let CALL_SCRIPT = 'CALL_SCRIPT';
    const dispatch = useDispatch();
    const [isEnable, setIsEnable] = useState(true);
    const [selectedValue, setSelectedValue] = useState(CALL_SETUP);


    // useEffect(() => {
    //     getPhoneIntegrationSetup();
    // }, []);

    const getPhoneIntegrationSetup = () => {
        try {
            let promise = getPhoneIntegrationSetup();
            if (isValidParam(promise)) {
                promise.then((response) => {
                    let isEnable = isValidParam(response) ? getBooleanParam(response.is_soffront) : false;
                    setIsEnable(isEnable);
                });
            }
        } catch (error) {
            console.error("Error in 'phoneIntegration.js -> getPhoneIntegrationSetup()':" + error);
        }
    }

    const getTabButtonCss = (selectedTabVal) => {
        let css = {};
        try {
            css = { float: 'left', width: '100px', padding: 5, borderBottom: '2.0px solid #afafaf5c', borderRight: '1.0px solid #afafaf5c', borderRadius: '0px 0px 5px', cursor: 'pointer' };
            selectedTabVal = getStringParam(selectedTabVal);
            if (selectedValue === selectedTabVal) {
                css.background = '#00000014';
            }
        } catch (error) {
            console.error("Error in 'phoneIntegration.js -> getTabButtonCss()':" + error);
        }
        return css;
    }

    const onTabChange = (value, event) => {
        try {
            setSelectedValue(value);
        } catch (error) {
            console.error("Error in 'phoneIntegration.js -> onTabChange()':" + error);
        }
    }

    const enablePhoneSetup = (event, isInputChecked) => {
        try {
            setIsEnable(isInputChecked)
            let param = { is_soffront: isInputChecked };
            let promise = saveRingCentralSetUp(param);
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        let isSaved = isValidParam(response.data) ? getBooleanParam(response.data.is_saved) : false;
                        if (isSaved) {
                            let activeTab = getActiveTab();
                            let object = getStringParam(activeTab.object);
                            if (activeTab.type === TYPE_LIST_VIEW && (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT)) {
                                refreshListView(object);
                            } else if (activeTab.type == TYPE_DETAIL_VIEW && (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT)) {
                                dispatch(refreshDetailViewData(true));
                            }
                        }
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'phoneIntegration.js -> enablePhoneSetup()':" + error);
        }
    }


    return <div>
        {getBooleanParam(isEnable) && <div>

            <div style={{ marginTop: '15px', fontSize: "15px" }}>
                {CALL_SETUP === selectedValue && <CallSetup />}

            </div>
        </div>}


    </div>;

}
