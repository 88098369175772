import React, { useEffect, useRef, useState } from 'react';
import { isValidParam, getStringParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants } from '../../../services/constants/constants';
import { useDispatch, useSelector } from "react-redux";
import { getAppDialog } from '../../../services/actions/appContainerActions';
import {showCustomSnackBar} from '../../../services/actions/snackBarAction';

import {
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    TextField,
} from '@mui/material';
import {updateActionTaskInStage,updateWorkflowSetupAddNewTask,setActions, setisaddNewTextMessage, setisEditTextMessage} from '../../../services/actions/workflowSetupAction';
import ClearIcon from '@mui/icons-material/Clear';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { getOrderId } from '../../../services/helper/utils';
import { isNull } from 'lodash';
import { element } from 'prop-types';


const WorkflowSetupMessage = ({task}) =>{
    const {isEditTextmessage, selectedStage,isFocusOnMessageTask,data} = useSelector((state) => state.workFlowSetup);
    let {t_from_number,t_intervalDateFieldId,t_interval_type,t_message,t_status,due_by,days_after} = isEditTextmessage?task?.details : [];
    const accountMergeFields = useSelector((state) => state.workFlowSetup.accountMergeFields);
    const inputRef = useRef(null);    
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const [fromNumberList,setfromNumberList] = useState([
        {id:23456 , number: '+18336520815'},
        {id:64783 , number: '+12155154738'},
        {id:87695 , number: '+13237927812'},
        {id:76584 , number: '+15155015807'}
    ])
    const [messagetimelist,setmessagetimelist] = useState([
        {id:'7777' , name:'days' , label:'Days'},
        {id:'8888' ,name:'hours', label:'Hours'}
    ])
    const [messageafterList,setmessageafterList] = useState([
        {id:22222,name:'Created Date',label:'Created Date'},
        {id:33333,name:'Current Date' ,label:'Current Date'},
        {id:44444,name:'The preceding task is complated',label:'The preceding task is complated'},
        {id:55555,name:'The proceding email is sent',label:'The proceding email is sent'}
    ])
    const [units, setUnits] = useState([]);
    const [fromNumber,setfromNumber] = useState(isEditTextmessage ? t_from_number : fromNumberList[0].number );
    const [dueBy,setdueBy] = useState(isEditTextmessage ?due_by :'2');
    const [intervalType,setintervalType] = useState(isEditTextmessage ? t_interval_type : messagetimelist[0].name);
    const [messageAfter,setmessageAfter] = useState(isEditTextmessage ?days_after :messageafterList[0].name );
    const [attachFiles, setAttachFiles] = useState(isEditTextmessage ? [] :[]);
    const [smslength,setSmslength] = useState(isEditTextmessage ? t_message.length : 0);
    const [selectedField,setselectedField] = useState('');
    const [message,setMessage] = useState(isEditTextmessage ? t_message : '');
  
    useEffect(()=>{
        let _length = message?.length;
        setSmslength(_length);
    },[message,smslength])
    const closeWorkflowSetupMessage = () =>{
        dispatch(setisaddNewTextMessage(false));
        dispatch(setisEditTextMessage(false));
        dispatch(setActions('--Add--'));
    }

    const updateAddNewMessage = () =>{
        if(!message){
            let errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EMAPTY_MESSAGE;
            dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            inputRef.current?.focus();
        }else if(!dueBy){
            let errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.DUE_BY_EMPTY;
            dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            inputRef.current?.focus();
        } else{
            const orderId = getOrderId([{...selectedStage}])
            if(isEditTextmessage){
                let params =  {
                    "workflow_id": task.workflow_id,
                    "stage_id": task.stage_id,
                    "details": {
                            "t_message": message,
                            "t_from_number": fromNumber,
                            "due_by": dueBy,
                            "days_after": messageAfter,
                            "t_status": task?.details?.t_status,
                            "t_intervalDateFieldId":task?.details?.t_intervalDateFieldId,  // need to revisit
                            "t_interval_type": intervalType,
                            "t_images_obj" : [],
                            "detailStatusFlg": 1,  // need to revisit for new message task
                        },
                    "id": task.id,
                    "type": task.type,
                    "order_id": task.order_id,   // need to revisit here tp provide order id for new message task 
                    "status": task.status
                }
                dispatch(updateActionTaskInStage(params));
            }else{
                let Obj =  {
                    "id":Math.floor(Math.random() * Date.now()),
                    "workflow_id": selectedStage.workflow_id,
                    "stage_id": selectedStage.id,
                    "order_id": orderId,
                    "type": "Text_Message",
                    "status": "Active",
                    "details": {
                        "t_from_number": fromNumber,
                        "due_by": dueBy,
                        "days_after":messageAfter ,
                        "t_intervalDateFieldId": "191", // TBD
                        "t_message": message,
                        "detailStatusFlg": 1,
                        "t_interval_type": intervalType,
                        "t_images_obj": attachFiles
                    },
                  };
                dispatch(updateWorkflowSetupAddNewTask(Obj))
            }
            dispatch(setisaddNewTextMessage(false));
            dispatch(setisEditTextMessage(false));
            dispatch(setActions('--Add--'));
        }
    }

    const getFromActionList = () =>{
        let List = fromNumberList;
        if (List !== undefined) {
            return List.map((item, i) => {
                return <MenuItem value={item.number} style={styles.popoverMenuItem} >{item.number}</MenuItem>;
            })
        }
    }
     
    const onChangeFromNumberActions = (value) =>{
        setfromNumber(value);
    }
    const onChangeSendFunc = (value) =>{
        setdueBy(value);
    }
    const getTimeActionList = () =>{
        let List = messagetimelist;
        if (List !== undefined) {
            return List.map((item, i) => {
                return <MenuItem value={item.name} style={styles.popoverMenuItem} >{item.label}</MenuItem>;
            })
        }
    }


    const onChangeTimeActions = (value) =>{
        setintervalType(value);
    }

    const onChangeAfterActions = (value) =>{
        setmessageAfter(value)
    }
    const getMessageAfterList = () =>{
        let List = messageafterList;
        if (List !== undefined) {
            return List.map((item, i) => {
                return <MenuItem value={item.name} style={styles.popoverMenuItem} >{item.label}</MenuItem>;
            })
        }
    }
    const openAttachment = () =>{
        let data = {
            contentStyle: { width: '80%', maxWidth: 'none' },
            type: "link",
            fullWidth: true,
            maxWidth: 'md',
        }
        data.callFrom = constants.SEND_SMS;
        data.files = getArrayParam(attachFiles);
        dispatch(getAppDialog(true, constants.LIBRARY_DIALOG, getLocalizedStrings().message.COMMON.ASSETMENU_DIALOG_TITLE, (sendData) => {

            let attachFile = {};
            attachFile.name = sendData.name;
            attachFile.src = sendData.src;
            let tempattachFiles = [...attachFiles, attachFile];
            dispatch(getAppDialog(false, null, null, null, null, null));
            setAttachFiles(tempattachFiles);
        }, data, constants.SEND_SMS));
    }
    const getAttachmetFiles=()=>{
        let files = getArrayParam(attachFiles);
        let ul = null;
            let li = files.map((f, index) => {
                return (
                <div key={f.name + index} style={{display:'flex'}}>
                    <AttachmentIcon  stye={{}}/>
                    <span style={{  overflow: 'hidden',
                        textOverflow: 'ellipsis', display: 'block', whiteSpace: 'nowrap', width: '150px'}} 
                        title={f.fileName}> 
                        {f.name} 
                    </span>
                    <ClearIcon
                    style={{ paddingRight: '5px', cursor: 'pointer' }}
                        onClick={() => {
                            let tempAttachFiles = attachFiles;
                            tempAttachFiles.splice(index, 1);
                            dispatch(getAppDialog(false, null, null, null, null, null));
                            setAttachFiles(tempAttachFiles);
                        }}
                    />
                    
                </div>)
            });
            if (li != null && li.length > 0) {
                return li;
            }
    }
    const getInsertFieldList = () =>{
        let popOverList = [];
        accountMergeFields.forEach((item, index) => {
                if (isValidParam(item)) {
                    if (isValidParam(Object.values(item)[0]) && isValidParam(Object.keys(item)[0])) {
                        let data = {};
                        data.label = Object.values(item)[0];
                        data.value = Object.keys(item)[0];
                        popOverList.push(data);
                    }
                }
            });
            if (popOverList !== undefined) {
                return popOverList.map((item, i) => {
                    return <MenuItem value={item.value} style={styles.popoverMenuItem} >{item.label}</MenuItem>;
                })
            }
    }
    const onChangInsertFieldsActions = (value) =>{
        setselectedField(value);
        var eventSelection = document.getElementById('message');
        let start = eventSelection.selectionStart,
            end = eventSelection.selectionEnd;
            if(!end ){
                value = getStringParam(message).concat(' ${' + getStringParam(value) + '} ');
            }else{
                value = getStringParam(message).substring(0, start) + ' ${' + getStringParam(value) + '} ' + getStringParam(message).substring(end);
            }
        setMessage(value);
        // setisFocus(true);

    }

    const onChangeMessageFunc = (value) =>{
        let tempValue = value;
        let length = tempValue.length;
        setSmslength(length);
        setMessage(value);
    }
    const setTextInputRef = (element) => {
        inputRef.current = element;
      };

    const setTextInputdueByRef = (element) =>{
        inputRef.current = element;
    }

    return(
        <div style={{width:'85%',marginLeft:'1.4rem',marginTop:'1rem',height:'fit-content'}}>
            <div className="btn-section" style={{diplay:'flex'}}> 
                <Button
                    onClick={() => updateAddNewMessage(fromNumber,dueBy,intervalType,messageAfter,attachFiles,selectedField,message)}
                    style={{
                        ...styles.primaryButton,
                        minWidth: '20px',
                        width: '7%',
                        marginTop:'20px',
                    }}
                    title={getLocalizedStrings().label.COMMON.ADD}
                > { isEditTextmessage ? getLocalizedStrings().label.COMMON.UPDATE : getLocalizedStrings().label.COMMON.ADD}</Button>
                <Button
                    onClick={() => closeWorkflowSetupMessage()}
                    style={{
                        ...styles.primaryButton,
                        minWidth: '20px',
                        width: '7%',
                        marginTop:'20px',
                    }}
                    title={getLocalizedStrings().label.COMMON.CANCEL}
                > {getLocalizedStrings().label.COMMON.CANCEL}</Button>

            </div>
            <div className='Messagebody_section' style={{marginTop:'15px'}}>
                <FormControl variant="outlined" style={{width:'13rem',marginRight:'10px'}} className='audit-select'>
                    <InputLabel id="sf-advanced-search-form-simple-select-outlined-label" className="sf-advanced-search-forms-fromadvancesearch" style={{marginTop:'4px'}}>{getLocalizedStrings().label.WORKFLOW_SETUP.FROM}</InputLabel>
                    <Select
                        key="sort-by"
                        name="from"
                        labelId='from-by-label'
                        id='from'
                        autoFocus={isFocusOnMessageTask}
                        label="from"
                        value={fromNumber}
                        style={{width:'13rem'}}
                        size="small"
                        onChange={(event) => onChangeFromNumberActions(event.target.value)}
                    >
                        {getFromActionList()}
                    </Select>
                </FormControl>
                <div style={{display:'inline-flex'}}>
                    <h1 style={{fontSize:'15px',color:'red',fontWeight:'bold',marginRight:'10px'}}>Send</h1>
                </div>
                <FormControl style={{ width:'6rem' ,marginTop:'-8px',marginRight:'33px'}} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        name="Send"
                        id='Send'
                        // label="Send"
                        inputRef={!dueBy ? setTextInputdueByRef : ''}
                        fullWidth={true}
                        value={dueBy}
                        style={{width:'6rem'}}
                        onChange={(event) => onChangeSendFunc(event.target.value)}
                        inputprops={{ maxlength: '256' }}
                        autoComplete="new-password"
                        margin='dense'
                        className={"sf-fields-bg"}
                        size="small"
                    />
                </FormControl>

                <FormControl variant="outlined" style={{width:'13rem',marginRight:'10px'}} className='audit-select'>
                    <Select
                        key="sort-by"
                        name="time"
                        labelId='time-by-label'
                        id='time'
                        value={intervalType}
                        style={{width:'13rem'}}
                        size="small"
                        onChange={(event) => onChangeTimeActions(event.target.value)}
                    >
                        {getTimeActionList()}
                    </Select>
                </FormControl>
                <div style={{display:'inline-flex'}}>
                    <h1 style={{fontSize:'15px',color:'red',fontWeight:'bold',marginRight:'10px'}}>{getLocalizedStrings().label.WORKFLOW_SETUP.AFTER}</h1>
                </div>
                <FormControl variant="outlined" style={{width:'18rem'}} className='audit-select'>
                    <Select
                        key="sort-by"
                        name="after"
                        labelId='after-by-label'
                        id='after'
                        value={messageAfter}
                        style={{width:'18rem'}}
                        size="small"
                        onChange={(event) => onChangeAfterActions(event.target.value)}
                    >
                        {getMessageAfterList()}
                    </Select>
                </FormControl>
            </div>
            <div style={{marginTop:'10px',display:'flex',marginLeft:'42%'}}>
                <Button
                        onClick={() => openAttachment()}
                        style={{
                            ...styles.primaryButton,
                            minWidth: '20px',
                            width: 'fit-content',
                            marginTop:'15px',
                        }}
                        title={getLocalizedStrings().label.WORKFLOW_SETUP.INSERT_IMAGE}
                    > {getLocalizedStrings().label.WORKFLOW_SETUP.INSERT_IMAGE}</Button>

                <FormControl variant="outlined" style={{width:'12rem',marginTop:'15px'}} className='audit-select'>
                    <InputLabel id="sf-advanced-search-form-simple-select-outlined-label" className="sf-advanced-search-forms-fromadvancesearch" style={{marginTop:selectedField.length > 0 ?'4px' :'-4px'}}>{getLocalizedStrings().label.WORKFLOW_SETUP.INSERT_FIELD}</InputLabel>
                    <Select
                        key="sort-by"
                        name="insert_fields"
                        labelId='insert_fields-by-label'
                        label="Insert Field"
                        id='insert_fields'
                        value={selectedField}
                        style={{width:'12rem'}}
                        size="small"
                        onChange={(event) => onChangInsertFieldsActions(event.target.value)}
                    >
                        {getInsertFieldList()}
                    </Select>
                </FormControl>
            </div>
            <div style={{marginTop:'2px',display:'inline'}}>
                {getAttachmetFiles()}
            </div>
            <div className='message_box' style={{width:'100%'}}>
                <FormControl style={{ width:'70%' ,marginTop:'10px', backgroundColor: '#fff',height:'fit-content' }} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        name="message"
                        multiline={true}
                        type='textarea'
                        rows={8}
                        inputRef={!message? setTextInputRef:''}
                        id='message'
                        onChange={(event) => onChangeMessageFunc(event.target.value)}
                        fullWidth={true}
                        textareastyle={{ marginTop: '5px', resize: 'none', marginBottom: '5px' }}
                        inputStyle={{ marginTop: '10px', paddingBottam: '10px', }}
                        value={message}
                        className={"sf-fields-bg"}
                    />
                </FormControl>
            </div>
            <div style={{width:'100%'}}>
                <span style={{fontSize:'14px'}}>{smslength}{getLocalizedStrings().label.WORKFLOW_SETUP.CHARS}</span>
            </div>

        </div>
    )
}

export default WorkflowSetupMessage;