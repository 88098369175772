import React, { useEffect }  from 'react';

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
const Logout = ({ history }) => {

    let app = useSelector((state) => state.app);
    useEffect(() => {
        localStorage.removeItem('type_'+ app.me.projectId+"_"+ app.me.id)
        localStorage.removeItem('returnUrl_'+ app.me.projectId+"_"+ app.me.id);
        localStorage.removeItem('selectedTab_'+ app.me.projectId+"_"+ app.me.id);
        localStorage.removeItem('selectedModuleInfo_'+ app.me.projectId+"_"+ app.me.id);
        localStorage.removeItem('selectedModuleItems_'+ app.me.projectId+"_"+ app.me.id);
        localStorage.removeItem('selectedModuleName_'+ app.me.projectId+"_"+ app.me.id);
        localStorage.removeItem('selectedTabObject_'+ app.me.projectId+"_"+ app.me.id);
        localStorage.removeItem('activeTabIndex_'+ app.me.projectId+"_"+ app.me.id);
        localStorage.removeItem('rememberedTab_'+ app.me.projectId+"_"+ app.me.id);
        localStorage.removeItem('listselectedTab_'+ app.me.projectId+"_"+ app.me.id);
        localStorage.removeItem('listrememberedTab_'+ app.me.projectId+"_"+ app.me.id);

        localStorage.removeItem('token');
        history.push('/');
        window.location.reload();
    }, []);

    return (
        <div >
        </div>
    );
};
export default Logout;