import React, { useEffect, useState, useCallback } from 'react';
import { Day, ScheduleComponent, ViewsDirective, ViewDirective, Inject, Resize, DragAndDrop } from '@syncfusion/ej2-react-schedule';
import { connect } from 'react-redux';
import { isValidParam, getStringParam, getArrayParam, getIntParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import moment from 'moment';
import { dateTimeFormat, dateFormat, timeFormat } from '../../../services/helper/utils';
import { constants } from '../../../services/constants/constants';
import { getPendingAppTask, updateAppTasks, getAppTaskRefresh } from '../../../services/actions/appTaskAction';
import { styles } from '../../../services/constants/styles'
import ShowCircularProgress from '../components/circularProgress';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useDispatch, useSelector } from 'react-redux';


const AppTaskCalendar = ({ object, record, id, updateDnD, dt }) => {
    let scheduleObj;
    const [staterecord, setStateRecord] = useState(null);
    const [calendarDate, setCalendarDate] = useState(new Date());
    const [startWkHr, setStartWkHr] = useState('9:00');
    const [endWkHr, setEndWkHr] = useState('17:00');
    const [pvrSt, setPvrSt] = useState(null);
    const [userName, setUserName] = useState('');
    const [events, setEvents] = useState([]);

    const app = useSelector((state) => state.app);
    const userlist = useSelector((state) => state.userlist.data);
    const appTasks = useSelector((state) => state.appTasks);

    const dispatch = useDispatch();

    useEffect(() => {
        let _userName = app.me.name;
        if (!getBooleanParam(appTasks.mounted)) {
            if (object === constants.APPOINTMENTS && record.hasOwnProperty('assign')) {
                _userName = record.assign.value;
            }
            if (object === constants.TASKS && record.hasOwnProperty('t_owner')) {
                _userName = record.t_owner.value;
            }
          
        }
        getPendingTasksApp(_userName);
        populateRecord(record);

    }, [record, dt,userName])

    useEffect(() => {
        setTasksAppoinmentData(appTasks.data);
    }, [])

    const getPendingTasksApp = (_userName) => {
        try {
            let params = {};
            let appObj = {
                query_name: 'All Appointments',
                query_type: 'query',
                search: [
                    { assign: _userName, t_status: 'Scheduled' }
                ],
                page_size: -1,
                search_type: 'exact',
                fields: ['id', 'subject', 'assign', 'start_date', 'end_date', 't_status']
            }
            let taskObj = {
                query_name: "All Tasks",
                query_type: "query",
                search: [
                    { t_owner: _userName, t_status: 'Pending' }
                ],
                search_type: 'exact',
                fields: ['id', 't_name', 't_status', 't_owner', 't_dueby'],
                click_form:'addTaskCal'
            }
            params.appObj = appObj;
            params.taskObj = taskObj;
            params.userName = _userName;
            setUserName(_userName);
            dispatch(getPendingAppTask(params));
        } catch (error) {
            console.error('Error on AppTaskCalendar.js -> getPendingTasksApp() : ' + error);
        }
    }

    const populateRecord = (_record) => {
        try {
            let dateFrmt = app.me.date_format;
            let newRecord = {};
            let startDate = new Date();
            let newPvrSt = null;
            if (getIntParam(id) > 0) {
                newRecord.isDnD = true;
            }
            newRecord.Id = getIntParam(id) > 0 ? getIntParam(id) : '-1_new';
            let endDate = new Date();
            if (_record !== null) {
                for (let key in _record) {
                    if (key === 'subject' || key === 't_name') {
                        newRecord.Subject = (isValidParam(_record[key].value) && _record[key].value !== "") ? _record[key].value : '';
                    } else if (key === 'start_date') {
                        startDate = new Date(_record[key].value);
                    } else if (key === 'end_date') {
                        endDate = new Date(_record[key].value);
                    } else if (key === 't_dueby') {
                        let startTime = _record[key].value;
                        startDate = new Date(startTime);
                        let endDate = moment(startDate).add(15, 'm').format(dateTimeFormat[dateFrmt]);
                        endDate = new Date(endDate);
                    }
                }
            }
            let allDay = false;
            if (object === constants.APPOINTMENTS) {
                let duration = (endDate - startDate) / (1000 * 60 * 60);
                if (duration >= 24) {
                    allDay = true;
                }
            } else if (object === constants.TASKS) {
                allDay = true;
            }
            newRecord.StartTime = startDate;
            newRecord.EndTime = endDate;

            let startWkHr = moment(startDate).format('HH');
            startWkHr = getIntParam(startWkHr) - 1;
            let endWkHr = getIntParam(startWkHr) + 8;
            if (getIntParam(endWkHr) > 24) {
                let excesTm = getIntParam(endWkHr) - 24;
                startWkHr = getIntParam(startWkHr) - excesTm;
                endWkHr = '23:59';
            } else {
                endWkHr = endWkHr + ':00';
            }
            startWkHr = startWkHr + ':00';

            newRecord.ConferenceId = [0];
            newRecord.type = object;
            newRecord.IsAllDay = allDay;
            newRecord.Color = '#A9A9A9';
            if (appTasks.isrefresh) {
                let appTaskData = {
                    data: appTasks.data,
                    mounted: true,
                    isrefresh: false,
                }
                dispatch(updateAppTasks(appTaskData));
            }
            if (object === constants.APPOINTMENTS) {
                newPvrSt = _record.start_date.value
            } else if (object === constants.TASKS) {
                newPvrSt = _record.t_dueby.value
            }
            setStateRecord(newRecord);
            setCalendarDate(startDate);
            setStartWkHr(startWkHr);
            setEndWkHr(endWkHr);
            setPvrSt(newPvrSt);

        } catch (error) {
            console.error('Error on AppTaskCalendar.js -> populateRecord() : ' + error);
        }
    }

    const setTasksAppoinmentData = (apptasksData) => {
        let modifiedEventArr = [];
        let conId = 0;
        let selectedUser = userlist?.filter((f) => { return f.value === userName });
        if (selectedUser.length > 0) {
            conId = selectedUser[0].id;
        }
        let dateFrmt = app.me.date_format;
        let eventArr = getArrayParam(apptasksData?.events?.records);
        eventArr.map(function (item) {
            let startDate = new Date(item.start_date);
            let endDate = new Date(item.end_date);
            let duration = (endDate - startDate) / (1000 * 60 * 60);
            let allDay = false;
            if (duration >= 24) {
                allDay = true;
            } else {
                allDay = false;
            }
            let appObject = {
                Id: item.id + '_event',
                Subject: item.subject,
                StartTime: startDate,
                EndTime: endDate,
                IsAllDay: allDay,
                ConferenceId: [conId],
                Color: '#A9A9A9',
                type: constants.APPOINTMENTS,
            }
            modifiedEventArr.push(appObject);
        });

        let tasksArr = getArrayParam(apptasksData?.tasks?.records);
        tasksArr = tasksArr.filter((f) => { return (f.t_status === 'Pending' || f.t_status === 'In-progress') });
        tasksArr.map((item, i) => {
            let startDate = new Date(item.t_dueby);
            let endDate = moment(startDate).add(15, 'm').format(dateTimeFormat[dateFrmt]);
            endDate = new Date(endDate);

            let taskObject = {
                Id: item.id + '_task',
                Subject: item.t_name,
                StartTime: startDate,
                EndTime: endDate,
                IsAllDay: true,
                ConferenceId: [conId],
                Color: '#A9A9A9',
                type: constants.TASKS,
            }
            modifiedEventArr.push(taskObject);
        });
        setEvents(modifiedEventArr);
    }

    const triggerAction = (args) => {
        try {
            let requestType = getStringParam(args.requestType);
            if (requestType === 'eventCreated') {

            } else if (requestType === 'eventChanged') {
                let records = args.changedRecords[0]
                if (records.Id === '-1_new' || getBooleanParam(records.isDnD)) {
                    let dateFrmt = app.me.date_format;
                    let eventObj = {};
                    eventObj.type = records.type;
                    if (records.type === constants.APPOINTMENTS) {
                        let StartTime = records.StartTime;
                        let start_date = moment(StartTime).format(dateTimeFormat[dateFrmt]);
                        eventObj.start_date = start_date;
                        let EndTime = records.EndTime;
                        let end_date = moment(EndTime).format(dateTimeFormat[dateFrmt]);
                        eventObj.end_date = end_date;

                    } else {
                        let StartTime = records.StartTime;
                        let t_dueby = moment(StartTime).format(dateTimeFormat[dateFrmt]);
                        eventObj.t_dueby = t_dueby;
                    }
                    updateDnD(eventObj);
                } else {
                    args.cancel = true;
                    return false;
                }
            } else if (requestType === 'eventRemoved') {

            } else if (requestType === 'viewNavigate') {
            }
        } catch (error) {
            console.error('Error on AppTaskCalendar.js -> triggerAction() : ' + error);
        }
    }

    const onEventRendered = (args) => {
        try {
            if (scheduleObj !== undefined && scheduleObj.currentView !== 'Agenda') {
                let color = '#AAA7A7';
                if (args.data.Id === '-1_new' || getBooleanParam(args.data.isDnD)) {
                    color = '#FE5722';
                }
                args.element.style.backgroundColor = color;
                if (args.data.type === constants.TASKS) {
                    let StartTime = args.data.StartTime;
                    StartTime = moment(StartTime).format("hh:mm A");
                    args.element.textContent = StartTime + ' - ' + args.data.Subject;
                    args.element.style.fontSize = 13;
                    args.element.style.fontWeight = 500;
                }
            }
        } catch (error) {
            console.error('Error on AppTaskCalendar.js -> onEventRendered() : ' + error);
        }
    }

    const tooltipShowTemplate = (props) => {
        try {
            let momentStartDate = moment(props.StartTime);
            let momentEndDate = moment(props.EndTime);

            if (props.type !== undefined && props.type === constants.TASKS) {
                let eventDate = momentStartDate.format(dateTimeFormat[app.me.date_format]);
                return (
                    <div className="tooltip-wrap">
                        <div className="content-area">
                            <div className="name">{props.Subject}</div>
                            <div className="time">{eventDate}</div>
                            <div className="time">{'Tasks'}</div>
                        </div>
                    </div>
                );
            } else {
                let eventDate = momentStartDate.format(dateFormat[app.me.date_format]);
                let eventStartTime = momentStartDate.format(timeFormat[app.me.date_format]);
                let eventEndTime = momentEndDate.format(timeFormat[app.me.date_format]);
                return (
                    <div className="tooltip-wrap">
                        <div className="content-area">
                            <div className="name">{props.Subject}</div>
                            <div className="time">{eventDate}</div>
                            <div className="time">{eventStartTime} - {eventEndTime}</div>
                            <div className="time">{'Appointments'}</div>
                        </div>
                    </div>
                );
            }
        } catch (error) {
            console.error('Error in AppTaskCalendar.js -> tooltipShowTemplate() : ' + error);
        }
    }

    const cellDoubleClick = (event) => {
        event.cancel = true;
        return false;
    }

    const onPopupOpen = (event) => {
        event.cancel = true;
        return false;
    }



    let mounted = appTasks.mounted;
    if (mounted) {
        let calendarHeight = '590px';
        if (object === constants.APPOINTMENTS) {
            calendarHeight = '770px';
        }
        const taskHeader = `<div class = "app_task_Header_fontsize">
    ${getLocalizedStrings().label.APPOINTMENTS_TASKS.MY_TASKS + ' Due'}
</div>`
        return (
            <div>
                {mounted &&
                    <ScheduleComponent
                        id='schedule'
                        dateHeaderTemplate={taskHeader}
                        allowDragAndDrop={false}
                        showQuickInfo={true}
                        currentView={'Day'}
                        selectedDate={calendarDate}
                        width='100%'
                        height={calendarHeight}
                        ref={schedule => scheduleObj = schedule}
                        allowResizing={false}
                        eventSettings={{
                            dataSource: events,
                            fields: {
                                id: 'Id',
                                subject: { name: 'Subject', title: 'Event Name', value: 'Follow' },
                                location: { name: 'Company', title: 'Company' },
                                description: { name: 'Description', title: 'Event Description' },
                                startTime: { name: 'StartTime', title: 'Start Duration' },
                                endTime: { name: 'EndTime', title: 'End Duration' },
                                attendees: { name: 'Attendees', title: 'Assign To' }
                            },
                            enableTooltip: true,
                            tooltipTemplate: tooltipShowTemplate.bind(this)
                        }}
                        eventRendered={onEventRendered.bind(this)}
                        cellDoubleClick={cellDoubleClick.bind(this)}
                        actionComplete={triggerAction.bind(this)}
                        popupOpen={onPopupOpen.bind(this)}
                        quickPopupHide={true}
                        workHours={{
                            start: startWkHr,
                            end: endWkHr
                        }}
                    >
                        <ViewsDirective>
                            <ViewDirective option='Day' />
                        </ViewsDirective>
                        <Inject services={[Day, Resize, DragAndDrop]} />
                    </ScheduleComponent>}
            </div>
        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }

}


export default AppTaskCalendar;