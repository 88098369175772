import { actions } from '..//constants/actions';
import { isValidParam, getArrayParam, getObjectParam, getBooleanParam, getStringParam } from '..//helper/parameterVerifier';
import { constants } from '..//constants/constants';

const salesReportInitialState = {     
    mounted : false,
    data: null,
    error: null,
    reportInfo: {
        mounted: false,
    },
    reportData: {
        mounted: false,
    }
}

const salesReportReducer = (state = salesReportInitialState, action) => {
    let reportNames = null;
    switch (action.type) {
        case actions.SALES_REPORT_GET_PENDING:        
            return {
                ...state,
                mounted: false,
                data: null
            }
        case actions.SALES_REPORT_GET_FULFILLED:
            let data = action.payload;
            let uniqueReportNames = [];
            if(data?.report_names && data?.user_type) {
                data = { ...data };
                data.report_names = filterHeaderNames(data.report_names, data.user_type, action.state);
                 uniqueReportNames = data.report_names.filter((report, index) => {
                    return (index === data.report_names.findIndex((r) => r.item_name === report.item_name && 
                      r.item_type === report.item_type && r.parameter_id === report.parameter_id)
                    );
                  });
                  data.report_names = uniqueReportNames;
                }            
            return {
                ...state,
                mounted: true,
                data: data
            }
        case actions.SALES_REPORT_ADD:
            return {
                ...state,
                data: { ...getObjectParam(state.data), report_names: action.payload } 
            }
        case actions.SALES_REPORT_REMOVE:
            reportNames = isValidParam(state.data) ? getArrayParam(state.data.report_names) : [];
            reportNames = reportNames.filter( f => {return f.parameter_id !== action.payload});
            return {
                ...state,
                data: { ...getObjectParam(state.data), report_names: reportNames }
            }            
        case 'SALESREPORT_ERROR' :
            return {
                ...state,
                mounted: false,
                error: action.payload
            }
        case 'RECENT_REPORT_GET' :
            return {
                ...state,
                mounted: true,
                recentreport: action.payload
            }
        case 'RECENT_REPORT_ERROR' :
            return {
                ...state,
                mounted: false,
                error: action.payload
            }
        case actions.SALES_REPORT_INFO_GET_PENDING:
            return {
                ...state,
                reportInfo: {
                    mounted: false,
                }
            }
        case actions.SALES_REPORT_INFO_GET_FULFILLED:
            return {
                ...state,
                reportInfo: {
                    mounted: true,
                    ...getObjectParam(action.payload)
                }
            }
            case actions.SALES_REPORT_DATA_GET_PENDING:
            return {
                ...state,
                reportData: {
                    mounted: false,
                }
            }
            case actions.SALES_REPORT_DATA_GET_PARTIAL_FULFILLED:
            return {
                ...state,
                reportData: {
                    mounted: false,
                    ...getObjectParam(action.payload)
                }
            }
        case actions.SALES_REPORT_DATA_GET_FULFILLED:
            return {
                ...state,
                reportData: {
                    mounted: true,
                    ...getObjectParam(action.payload)
                }
            }
        default:
            return state;
    }
}

export default salesReportReducer;



const filterHeaderNames = (headerNames, userType,state) => {
    
    let headerNamesList = getArrayParam(headerNames);
    let isGlobal = getObjectParam(userType).hasOwnProperty("isGlobal") ? getBooleanParam(userType.isGlobal) : false;
    let isCountry = getObjectParam(userType).hasOwnProperty("isCountry") ? getBooleanParam(userType.isCountry) : false;
    let isAdminOrMngr = isAdminOrManager(state.app.me.t_name);

    let names = [];
    headerNamesList.forEach((f) => {
        if (f.item_type === constants.TASKS_REPORT && isAdminOrMngr) {
            names.push(f);
        }
        if(f.item_type === constants.ORTHOTIC_REPORT){
            names.push(f);
        }
        else {
            if (isCountry) {
                if (f.item_type === 'InXpress Report 2' || f.item_type === 'InXpress Report 3' || f.item_type === 'InXpress Report 5' || f.item_type === 'InXpress Report 13'
                    || f.item_type === 'InXpress Report 14' || f.item_type === 'InXpress Report 16' || f.item_type === 'InXpress Report 17' || f.item_type === 'InXpress Report 18'
                    || (f.item_type === 'InXpress Report 19' && constants.SECURITY_ROLE_ADMIN === getStringParam(state.app.me.t_name))
                    || ((f.item_type.indexOf('InXpress Report') < 0) && f.item_type !== constants.TASKS_REPORT)
                    || ((f.item_type === 'InXpress Report 20' || f.item_type === 'InXpress Report 21') && (constants.SECURITY_ROLE_ADMIN === getStringParam(state.app.me.t_name) || constants.SECURITY_ROLE_REGIONAL_USERS === getStringParam(state.app.me.t_name)))) {
                    if (f.item_type === constants.GREENLIGHT_IMPORT && constants.SECURITY_ROLE_ADMIN !== getStringParam(state.app.me.t_name)) { }
                    else {
                        names.push (f);
                    }
                }
            } else if (isGlobal) {
                if (f.item_type === 'InXpress Report 5' || f.item_type === 'InXpress Report 13' || f.item_type === 'InXpress Report 14' || f.item_type === 'InXpress Report 15' ||
                    f.item_type === 'InXpress Report 16' || f.item_type === 'InXpress Report 20' || (f.item_type === 'InXpress Report 21' && constants.SECURITY_ROLE_ADMIN === getStringParam(state.app.me.t_name))
                    || ((f.item_type.indexOf('InXpress Report') < 0) && f.item_type !== constants.TASKS_REPORT)) {
                    if (f.item_type === constants.GREENLIGHT_IMPORT && constants.SECURITY_ROLE_ADMIN !== getStringParam(state.app.me.t_name)) { }
                    else {
                        names.push(f);
                    }
                }
            }
            else {
                if (f.item_type === 'InXpress Report 5' || f.item_type === 'InXpress Report 13' || f.item_type === 'InXpress Report 14' || f.item_type === 'InXpress Report 15' ||
                    f.item_type === 'InXpress Report 16' || ((f.item_type.indexOf('InXpress Report') < 0) && f.item_type !== constants.TASKS_REPORT)) {
                    names.push(f);
                }
                else{
                    return null;
                    
                }
            }
        }
    });    
    return names;
}

const isAdminOrManager = (roleName) => {
    let isValidRole = false;
    let role = getStringParam(roleName);
    if (role === constants.SECURITY_ROLE_ADMIN || role === constants.SECURITY_ROLE_MANAGER) {
        isValidRole = true;
    }
    return isValidRole;
}