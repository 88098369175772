import React from 'react';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { FormControlLabel, Checkbox, FormControl, Button, TextField, Select, MenuItem, InputLabel, Typography, Icon, IconButton } from '@mui/material';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { styles } from '../../../services/constants/styles';
import { isValidParam, getArrayParam, getStringParam } from '../../../services/helper/parameterVerifier';
import ShowCircularProgress from '../components/circularProgress';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import * as sfDialogs from '../components/sfDialogs';
import { getRemainingCallMinutes, getCallScriptList, getCallDrawer, getCallScriptDetails, saveNoteAfterCall } from '../../../services/actions/callAction';
import { getNoteType } from '../../../services/actions/batchActions';
import { minimizeAppCustomDrawer, getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import { OBJECT_TABLEID_MAP, COUNTRY_LIST_FOR_PHONE_SMS, COUNTRY_CODE_MAP, constants } from '../../../services/constants/constants';
import { refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';
import { setCookie, getCookie } from '../../../services/helper/sfCookies';
import { getObjectLabelByObject } from '../../../services/helper/common';
import { Device } from 'twilio-client';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import '../../../asset/styles/css/dailpad.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
let gblcountryISO2 = null;
let gblCountryArr = [];
const states = {
    CONNECTING: "Connecting",
    READY: "Ready",
    INCOMING: "Incoming",
    ON_CALL: "On call",
    OFFLINE: "Offline"
};
const CallDialog = ({ data, label }) => {
    const dispatch = useDispatch();
    const [isCallingSupport, setIsCallingSupport] = useState(true);
    const [isOutBoundPhoneNumberVerfied, setisOutBoundPhoneNumberVerfied] = useState(true);
    const [outboundPhoneNumberArr, setoutboundPhoneNumberArr] = useState([]);
    const [defaultFromNumber, setDefaultFromNumber] = useState(null);
    const [fromNumberCountryCode, setFromNumberCountryCode] = useState(null);
    const [fromNumberExtension, setfromNumberExtension] = useState("");
    const [mounted, setMounted] = useState(false);
    const [toNumber, setToNumber] = useState('');
    const [toNumberWithoutCountryCode, setToNumberWithoutCountryCode] = useState(null);
    const [formatedToNumber, setFormatedToNumber] = useState(null);
    const [dialCode, setDialCode] = useState(null);
    const [toExtension, setToExtension] = useState("");
    const [callScriptListArr, setCallScriptListArr] = useState([]);
    const [selectedCallScript, setselectedCallScript] = useState(0);
    const [callScriptDetails, setCallScriptDetails] = useState(null);
    const [callingNoteObject, setcallingNoteObject] = useState({ primaryKey: "-999", scriptName: "Note", workflowId: "-9999" });
    const [noteTypeList, setNoteTypeList] = useState(null);
    const [selectedNoteType, setSelectedNoteType] = useState('Dialed');
    const [noteText, setNoteText] = useState(null);
    const [fieldValueForCallScriptDetails, setFieldValueForCallScriptDetails] = useState("");
    const [showCallSection, setShowCallSection] = useState(false);
    const [showCallScriptLink, setShowCallScriptLink] = useState(false);
    const [showCallScriptSection, setShowCallScriptSection] = useState(false);
    const [showNoteSection, setshowNoteSection] = useState(false);
    const [showMessageSection, setShowMessageSection] = useState(false);
    const [isMinimize, setIsMinimize] = useState(false);
    const [dialogTitle, setDialogTitle] = useState(null);
    const [isCalling, setIsCalling] = useState(false);
    const [isCallRecording, setisCallRecording] = useState(false);
    const [selectedValue, setSelectedValue] = useState('scripts');
    const [noteButtonVisibility, setNoteButtonVisibility] = useState(false);
    const [callStrated, setCallStrated] = useState(false);
    const [gblCallStartTime, setGblCallStartTime] = useState("");
    const [isbtnDisable, setIsbtnDisable] = useState(false);
    const [noteData, setNoteData] = useState(null);
    const [device, setDevice] = useState(null);
    const [conn, setConn] = useState(null);
    const [state, setState] = useState(states.CONNECTING);

    const appState = useSelector((state) => state.app);

    useEffect(() => {
        let _isCallRecording = appState.me.is_call_record;
        setisCallRecording(_isCallRecording)
    }, [appState]);
    useEffect(() => {
        getTenantCountry();
    }, [])


    useEffect(() => {
        let selectedPhoneNumber = null;
        let tenantCountry = getStringParam(appState.me.tenant_country);
        if (data !== undefined && data.hasOwnProperty("selectedPhoneNumber") && data.selectedPhoneNumber !== null && data.selectedPhoneNumber !== undefined) {
            selectedPhoneNumber = data.selectedPhoneNumber;
            let countryCode = COUNTRY_CODE_MAP[tenantCountry];
            countryCode = countryCode.split("+")[1];
            if (selectedPhoneNumber.indexOf("+", 0) === 0) {
                selectedPhoneNumber = selectedPhoneNumber.split("+")[1];
            }
            if (selectedPhoneNumber.indexOf(countryCode, 0) === 0) {
                selectedPhoneNumber = selectedPhoneNumber.replace(countryCode, '');

            }
            setDialCode(countryCode)
        }
        if (selectedPhoneNumber === null || selectedPhoneNumber === undefined) {
            handleClose();
        }
        setToNumber(selectedPhoneNumber);
        setDialogTitle(data.fullName);
        setNoteText('Spoke with ' + data.fullName);
        let token = data.token;
        if (token) {
            const device = new Device();

            device.setup(token, { debug: true });

            device.on("ready", () => {
                setDevice(device);
                setState(states.READY);
            });
            device.on("connect", connection => {
                setConn(connection);
                setState(states.ON_CALL);
            });
            device.on("disconnect", () => {
                setState(states.READY);
                setConn(null);
                setIsMinimize(false);
                let dialogTitle = "Call Ended";
                let headerProps = {};
                setMounted(false);
                setIsCalling(false);
                setNoteButtonVisibility(true);
                getNoteTypeList();
                // device.disconnectAll();

                // }
            });


            device.on("incoming", connection => {
                setState(states.INCOMING);
                setConn(connection);
                connection.on("reject", () => {
                    setState(states.READY);
                    setConn(null);
                });
            });
            device.on("cancel", () => {
                setState(states.READY);
                setConn(null);
            });
            device.on("reject", () => {
                setState(states.READY);
                setConn(null);
            });

        }
    }, [data])


    const getTenantCountry = () => {
        let tenantCountry = getStringParam(appState.me.tenant_country);
        let countryRecord = getStringParam(data.country);
        let gblCountry = null;

        let countryISO2CodeArr = appState.countryISO2;
        if (countryRecord === '') {
            gblCountry = tenantCountry;
            setIsCallingSupport(true);
        } else {
            if (COUNTRY_LIST_FOR_PHONE_SMS.indexOf(tenantCountry) > -1) {
                gblCountry = tenantCountry;
                setIsCallingSupport(true);
            } else {
                setIsCallingSupport(false);
                setShowCallSection(false);
                setShowCallScriptLink(false);
                setMounted(false);
            }
        }

        if (gblCountry !== null && gblCountry !== '' && isCallingSupport) {
            var countryISO2Arr = countryISO2CodeArr.filter(f => {
                return f.t_country === gblCountry;
            });
            if (countryISO2Arr !== null && countryISO2Arr.length > 0) {
                var countryISO2 = countryISO2Arr[0].t_country_iso2;
                gblcountryISO2 = countryISO2;
                gblCountryArr.push(countryISO2);
            }

            getCallScriptListFunc();
            getOutBoundPhoneNumbers();


        }

    }


    const getOutBoundPhoneNumbers = () => {
        let params = null;
        let cookieFromNo = cookieGetCallFromPhoneNo();
        let promise = Promise.resolve(HTTPClient.get(endPoints.CALLING_INTEGRATION.OUTBOUND_PHONE_NUMBERS_GET, params));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    let outboundPhoneNumbers = getArrayParam(response.outbound_phonenumbers);
                    if (outboundPhoneNumbers.length > 0) {
                        setoutboundPhoneNumberArr(outboundPhoneNumbers);
                        setShowCallSection(true);
                        setMounted(true);
                        if (cookieFromNo === null || cookieFromNo === "") {
                            setDefaultFromNumber(outboundPhoneNumbers[0].phoneNumber);
                            setFromNumberCountryCode(outboundPhoneNumbers[0].countryCode);
                            setfromNumberExtension(outboundPhoneNumbers[0].extension);
                        } else {
                            outboundPhoneNumbers.map((items, Index) => {
                                if (cookieFromNo === items.phoneNumber) {
                                    setDefaultFromNumber(items.phoneNumber);
                                    setFromNumberCountryCode(items.countryCode);
                                    setfromNumberExtension(items.extension);
                                }
                            })
                        }
                    } else {
                        setisOutBoundPhoneNumberVerfied(false);
                        setMounted(false);
                    }
                }
            });
        }
    }

    const cookieSetCallFromPhoneNo = (value) => {
        let userid = appState.me.id;
        let cVarName = "callFromPhoneNo_" + userid
        document.cookie = cVarName + "=" + value;
    }

    const cookieGetCallFromPhoneNo = () => {
        let userid = appState.me.id;
        let cVarName = "callFromPhoneNo_" + userid + "=";
        let cookieVar = decodeURIComponent(document.cookie).split(';')
        for (var i = 0; i < cookieVar.length; i++) {
            var c = cookieVar[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(cVarName) === 0) {
                return c.substring(cVarName.length, c.length);
            }
        }
        return "";
    }

    const populateFromPhoneList = () => {
        let outboundPhoneList = '';
        let outboundPhoneListArr = outboundPhoneNumberArr;
        if (outboundPhoneListArr !== null && outboundPhoneListArr !== '' && outboundPhoneListArr !== undefined) {
            return outboundPhoneListArr.map((outboundPhoneObj, i) => {
                return <MenuItem value={outboundPhoneObj.phoneNumber}
                    style={styles.popoverMenuItem}>{outboundPhoneObj.phoneNumber}</MenuItem>;
            }
            )
        }
        return outboundPhoneList;
    }

    const changeFromNumber = (event) => {
        let value = event.target.value;
        let phoneArr = [];
        let fromNumberArr = outboundPhoneNumberArr;
        phoneArr = fromNumberArr.filter(f => { return f.phoneNumber === value });
        if (phoneArr !== null && phoneArr.length > 0 && phoneArr[0].hasOwnProperty('phoneNumber')) {
            setDefaultFromNumber(phoneArr[0].phoneNumber);
            setFromNumberCountryCode(phoneArr[0].countryCode);
            setfromNumberExtension(phoneArr[0].extension);
        }
    }

    const prepareToNumber = (val, code) => {
        var number = "";
        var ext = "";
        let value = val ? val : document.getElementById('tophoneno').value;
        if (value?.includes("ext.") || value?.includes("x")) {
            let tempNumber = "";
            if (value?.includes("ext.")) {
                tempNumber = value.split("ext.");
            } else {
                tempNumber = value.split("x");
            }
            if (tempNumber !== null && tempNumber !== "") {
                number = tempNumber[0].trimEnd();
                ext = tempNumber[1].trimStart();
            }
        } else {
            number = value;
        }
        number = number?.replaceAll('.', '')?.replaceAll('-', '');
        setToNumberWithoutCountryCode(number);
        let dialCode = document.getElementsByClassName('selected-dial-code')[0] ? document.getElementsByClassName('selected-dial-code')[0].innerHTML : code;
        let _formatedNo = isValidParam(dialCode) ? (dialCode + number) : null;
        setFormatedToNumber(_formatedNo);
        setToExtension(ext);
        checkPhoneTextProvider(ext, _formatedNo);
    }

    const checkPhoneTextProvider = (ext, _formatedNo) => {
        let phone_text_provider = appState.me.phone_text_provider ?? constants.NONE;
        if (phone_text_provider == constants.SOFFRONT) {
            callAfterRemainingCountCheck(ext, _formatedNo);
        } else if (phone_text_provider == constants.TWILIO) {
            call(ext, _formatedNo);
        }
    }

    const callAfterRemainingCountCheck = (ext, _formatedNo) => {
        let promise = Promise.resolve(getRemainingCallMinutes());
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    let remainingCallMins = response.remaining_call_mins;
                    if (remainingCallMins > 5) {
                        call(ext, _formatedNo);
                    } else {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.TWILIO.PHONE_LOW_BALANCE1 + remainingCallMins + getLocalizedStrings().message.TWILIO.PHONE_LOW_BALANCE2 + "\n" + getLocalizedStrings().message.TWILIO.PHONE_LOW_BALANCE3, null);
                    }
                }
            });
        }
    }

    const call = (ext, formatedNo) => {
        var makeCall = true;
        var protocol = window.location.protocol;
        let tempToExtension = ext;
        var protocolStr = "https:"
        if (protocol !== null && protocol !== protocolStr) {
            //makeCall = false; // for local enviroment comment out this line.
        }
        if (makeCall) {
            let fromNumber = "";
            let tempfromNumberCountryCode = fromNumberCountryCode;
            let fromPhone = defaultFromNumber;
            fromNumber = "+" + tempfromNumberCountryCode + "" + fromPhone;
            let gblToPhoneNumber = toNumberWithoutCountryCode;

            if (gblToPhoneNumber === fromPhone && (tempToExtension === "" || tempToExtension.length === 0)) {
                makeCall = false;
            }

            if (makeCall) {
                cookieSetCallFromPhoneNo(defaultFromNumber);
                let tempgblCallStartTime = new Date().getTime();
                setGblCallStartTime(tempgblCallStartTime);
                setNoteButtonVisibility(false);
                setIsCalling(true);
                setCallStrated(true);
                var params = {
                    "toNumber": formatedNo,
                    "toExtension": ext,
                    "fromNumber": fromNumber,
                    "fromExtension": fromNumberExtension,
                    "record": isCallRecording,
                    "projectId": appState.me.projectId,
                    "userId": appState.me.id,
                    "callStartTime": tempgblCallStartTime
                };

                device.connect(params);
                if (!showCallScriptSection) {
                    setIsMinimize(false);
                    let tempDialogTitle = dialogTitle;
                    tempDialogTitle = getLocalizedStrings().label.TWILIO.CALLING + " " + tempDialogTitle;
                    let headerProps = {};

                    dispatch(minimizeAppCustomDrawer(false, tempDialogTitle, null, null, headerProps, null));
                } else {
                    let tempDialogTitle = dialogTitle;
                    tempDialogTitle = getLocalizedStrings().label.TWILIO.CALLING + " " + tempDialogTitle;

                    let headerProps = {};
                    dispatch(minimizeAppCustomDrawer(false, tempDialogTitle, null, null, headerProps, null));
                }
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.TWILIO.SAME_TO_AND_FROM_NUMBER, null);
            }
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.TWILIO.CALL_OVER_SECURED_NETWORK, null);
        }
    }

    const getMinimizeHeader = () => {
        let titleDiv = <div>
            <span title={getLocalizedStrings().label.TWILIO.CALLING + " " + dialogTitle}>{getLocalizedStrings().label.TWILIO.CALLING + "..."}</span>
        </div>;
        return titleDiv;
    }


    const getCallScriptListFunc = () => {
        let params = {}
        let promise = Promise.resolve(getCallScriptList(params));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {

                    let callScriptList = [];
                    callScriptList.push(callingNoteObject);
                    let newCallScriptList = [];
                    newCallScriptList = callScriptList.concat(getArrayParam(response.callScriptList));
                    setCallScriptListArr(newCallScriptList);
                    if (newCallScriptList !== null && newCallScriptList.length > 0) {
                        let callScriptValue = newCallScriptList.length > 0 ? newCallScriptList[0].primaryKey : newCallScriptList[0].primaryKey;
                        let cookieCallScript = getCoockieData(callScriptValue);
                        setselectedCallScript(callScriptValue);

                        let callscriptObj = newCallScriptList.filter((obj) => { return obj.primaryKey === cookieCallScript });
                        let _callScriptId = callscriptObj.length > 0 ? callscriptObj[0].primaryKey : newCallScriptList[0].primaryKey;
                        setselectedCallScript(_callScriptId);
                        setShowCallScriptLink(true);
                        getCallScriptDetailsFunc(_callScriptId);
                    } else {
                        setShowCallScriptLink(false);
                    }
                }
            });
        }
    }

    const populateCallScriptList = () => {
        let callScriptList = '';
        let tempCallScriptListArr = callScriptListArr;
        if (tempCallScriptListArr !== null && tempCallScriptListArr !== '' && tempCallScriptListArr !== undefined) {
            return tempCallScriptListArr.map((callScriptListObj, i) => {
                return <MenuItem value={callScriptListObj.primaryKey}
                    style={styles.popoverMenuItem}>{callScriptListObj.scriptName}</MenuItem>;
            }
            )
        }
        return callScriptList;
    }

    const changeCallScript = (event) => {
        let value = event.target.value;
        let callScriptArr = [];
        let _callScriptId = '-999';
        let csArr = callScriptListArr;
        callScriptArr = csArr.filter(f => { return f.primaryKey === value });
        if (callScriptArr !== null && callScriptArr.length > 0 && callScriptArr[0].hasOwnProperty('scriptName')) {
            _callScriptId = callScriptArr[0].primaryKey;
            setselectedCallScript(_callScriptId);
            setCookiesData(_callScriptId);
        }
        getCallScriptDetailsFunc(_callScriptId);
    }


    const getCallScriptDetailsFunc = (_callScriptId) => {
        if (_callScriptId !== "-999") {
            let params = {
                "call_script_id": _callScriptId,
                "record_id": data.id,
                "object_name": data.object
            }
            let promise = Promise.resolve(getCallScriptDetails(params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        let callScriptDetails = getStringParam(response.callScriptDetails);
                        setCallScriptDetails(callScriptDetails);
                        let noteText = "";
                        if (noteData != null || noteData != undefined) {
                            noteText = noteData;
                        }
                        let tempfieldValueForCallScriptDetails = noteText + " " + callScriptDetails;
                        setFieldValueForCallScriptDetails(tempfieldValueForCallScriptDetails);
                    }
                });
            }
        } else {
            let tempfieldValueForCallScriptDetails = noteData === undefined || noteData === null ? '' : noteData;
            setFieldValueForCallScriptDetails(tempfieldValueForCallScriptDetails);
        }
    }

    const getNoteTypeList = () => {
        var response = getNoteType();
        response.then((response) => {
            setNoteTypeList(response.note_type);
            setshowNoteSection(true);
            setMounted(true);
        });
    }

    const populateNoteTypeList = () => {
        let tempnoteTypeList = '';
        let noteTypeArr = noteTypeList;
        if (noteTypeArr !== null && noteTypeArr !== '' && noteTypeArr !== undefined) {
            return noteTypeArr.sort().map((item, i) => {
                return <MenuItem value={item} style={styles.popoverMenuItem} >{item}</MenuItem>;
            }
            )
        }
        return tempnoteTypeList;
    }

    const saveNote = () => {
        var newNoteData = document.getElementById('noteTextArea').value;
        setIsbtnDisable(true);
        let tableId = null;
        let object = data.object;
        tableId = OBJECT_TABLEID_MAP[object];
        let params = {
            "to_number": toNumber,
            "table_id": tableId,
            "record_id": data.id,
            "note_type": 'Dialed',
            "note_text": getStringParam(newNoteData),
            "call_start_time": gblCallStartTime.toString(),
            "call_script_id": selectedCallScript,
        }
        if (data.hasOwnProperty('opportunity_info') && data.opportunity_info !== null &&
            data.opportunity_info != undefined && data.opportunity_info !== '') {
            params.opportunity_info = data.opportunity_info;
        }
        let promise = Promise.resolve(saveNoteAfterCall(params));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (data.isDetailView) {
                        refreshDetailViewListViewData(true);
                        setIsbtnDisable(false);
                    }
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.TWILIO.NOTE_SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    dispatch(getCallDrawer(false, null, null, null, null, null));
                }
            });
        }
    }

    const closeCallDialogDrawer = () => {
        dispatch(getCallDrawer(false, null, null, null, null, null));
    }
    const hangup = () => {
        device.disconnectAll();
    }

    const disconnectCall = () => {
        hangup();
    }

    const setCookiesData = (scriptId) => {
        let projectId = appState.me.projectId;
        let userId = appState.me.id;
        let cookieName = 'callscript_' + projectId + '_' + userId;
        if (isValidParam(scriptId)) {
            setCookie(cookieName, scriptId, constants.DEFAULT_COOKIE_EXPIRES);
        }
    }

    const getCoockieData = (scriptId) => {
        let selectionField = null;
        try {
            let projectId = appState.me.projectId;
            let userId = appState.me.id;
            let cookieName = 'callscript_' + projectId + '_' + userId;
            let cookieValue = getCookie(cookieName);
            if (!isValidParam(cookieValue) || (isValidParam(cookieValue) && getStringParam(cookieValue) === '')) {
                selectionField = scriptId;
            } else {
                selectionField = cookieValue;
            }
        } catch (error) {
            console.error("Error in 'callDialog.js --> getCoockieData()':" + error);
        }
        return selectionField;
    }

    const openHelpUrl = () => {
        let url = 'http://support.soffront.com/knowledge-base/phone-recording-compliance/';
        window.open(url, '_blank');
    }

    const handleCallRecording = (event, isInputChecked) => {
        if (event !== undefined && isInputChecked) {
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.TWILIO.CALL_RECORDING_LAWS_AND_REGULATION_ALERT, () => handleSfDialogConfirm(isInputChecked), null);
        } else {
            handleSfDialogConfirm(isInputChecked);
        }
    }

    const handleSfDialogConfirm = (isInputChecked) => {
        setisCallRecording(isInputChecked);
    }

    const getDailPad = () => {
        let daiPadArr = [{ num: '1', text: '' }, { num: '2', text: 'abc' }, { num: '3', text: 'def' }, { num: '4', text: 'ghi' }, { num: '5', text: 'jkl' },
        { num: '6', text: 'mno' }, { num: '7', text: 'pqrs' }, { num: '8', text: 'tuv' }, { num: '9', text: 'wxyz' }, { num: '*', text: '' },
        { num: '0', text: '+' }, { num: '#', text: '' }];
        let padElements = [];
        try {
            daiPadArr.forEach((obj) => {
                let text = getStringParam(obj.text);
                let num = getStringParam(obj.num);

                let customizeStyle = { marginTop: '-7px' };
                let customizeStyle2 = { paddingTop: '15px' };
                let element = <li className="digits" key={'digits' + num} onClick={() => clickDailPad(num)} ><p key={'p-' + num}><div style={customizeStyle2}><strong>{num}</strong></div><div className='divStyle' style={customizeStyle}>{text}</div></p></li>;

                if (text === "") {
                    element = <li className="digits" key={'digits-' + num} onClick={() => clickDailPad(num)} ><p key={'p-' + num}><div style={customizeStyle2}><strong>{num}</strong></div></p></li>;
                }

                if (num === '*') {
                    element = <li className="digits" key={'digits-' + num} onClick={() => clickDailPad(num)} ><p key={'p-' + num}><div style={customizeStyle2} ><strong style={{ fontSize: '30px', marginTop: '14px' }}>{num}</strong></div></p></li>;

                }
                padElements.push(element)

            })

        } catch (error) {
            console.error("Error in 'callDialog.js -> getDailPad()':" + error);
        }
        return padElements;
    }

    const clickDailPad = (num) => {
        try {
            var conn = device.activeConnection();
            if (num && num !== null && conn !== null && conn !== undefined && conn._status === 'open') {
                conn.sendDigits(num.trim());
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You do not have any active connection', null);
            }
        } catch (error) {
            console.error("Error in 'callDialog.js -> clickDailPad()':" + error);
        }
    }
    const tempsetNoteData = (value) => {

        var newNoteData = value;
        var newData = noteData;

        var noteScriptData = "";
        if (selectedCallScript === "-999") {
            newData = newNoteData;
            noteScriptData = newData;
        } else {
            noteScriptData = newNoteData;
        }
        setNoteData(newData);

        setFieldValueForCallScriptDetails(noteScriptData);
    }

    const onChangePhone = (value, data, event, formattedValue) => {
        setDialCode(data.dialCode)
    }

    const getCallInnerComponent = () => {
        if (mounted && device !== null) {
            let fromPhoneList = populateFromPhoneList();
            let callScriptList = populateCallScriptList();
            let noteTypeList = populateNoteTypeList();
            let dataTipLabel = 'info';
            let innerWidth = document.body.clientWidth;
            let widthOfNoteandCallScript = (innerWidth * 18) / 100;
            return (
                <div style={{ width: '100%' }}>
                    <div style={{ paddingLeft: '15px', paddingRight: '15px', marginTop: '10px' }}>
                        <div>

                            <div style={{ ...styles.row, marginTop: '20px' }}>
                                <FormControl variant="outlined" style={{ width: '200px' }}>
                                    <InputLabel id="sf-call-dialog-from-calldialog-simple-select-outlined-label" className="sf-call-dialog-from-calldialog" style={{ fontWeight: 'bold' }}>{getLocalizedStrings().label.POWER_CALLING.CALLER_ID}</InputLabel>
                                    <Select
                                        id="fromNumber"
                                        value={defaultFromNumber}
                                        onChange={(event) => changeFromNumber(event)}
                                        name="fromNumber"
                                        style={{ height: '42px', backgroundColor: '#fff', maxWidth: 255 }}
                                        label={getLocalizedStrings().label.POWER_CALLING.CALLER_ID}
                                        className={"sf-fields-bg"}
                                    >
                                        {fromPhoneList}
                                    </Select>
                                </FormControl>
                                <div style={{ paddingLeft: '10px' }}>
                                    <div >
                                        <FormControlLabel control={<Checkbox color="default" key='callRecording'
                                            name='callRecording'
                                            checked={isCallRecording}
                                            value={isCallRecording}
                                            onChange={(e) => handleCallRecording(e, e.target.checked)} />} label='Record this call' labelposition="right"
                                            labelstyle={{ color: '#717171', fontWeight: 'normal', marginLeft: '-6px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                            style={{ height: 'auto', width: '100%' }} title='Record this call' />
                                    </div>
                                </div>
                                <div style={{ paddingLeft: '5px' }}>
                                    <div >
                                        <Icon
                                            style={{ cursor: 'pointer', color: '#717171', fontSize: '24px', marginTop: '7px' }}
                                            title={getLocalizedStrings().label.DETAIL_VIEW.CLICK_TO_KNOW_MORE}
                                            onClick={() => openHelpUrl()}>
                                            info_outline</Icon>
                                    </div>
                                </div>
                            </div>

                            <div style={{ ...styles.row }}>
                                <div style={{ float: 'left', marginRight: '10px', paddingTop: '10px' }}>
                                    <PhoneInput
                                        inputProps={{
                                            name: "phone",
                                            required: true,
                                            autoFocus: true
                                        }}
                                        key="toNum"
                                        country={gblcountryISO2}
                                        countryCodeEditable={false}
                                        onlyCountries={gblCountryArr}
                                        value={`${dialCode} + ${toNumber}`}
                                        onChange={onChangePhone}
                                        preferredCountries={[]}
                                    />
                                </div>
                                <div style={{ paddingTop: '10px' }}>
                                    {isCalling && <Button
                                        key='phonedisconnect'
                                        onClick={() => { disconnectCall() }}
                                        startIcon={<Icon style={{ fontWeight: 'normal', fontSize: '1.4em', marginTop: -3 }}>call_end</Icon>}
                                        style={{ ...styles.primaryButton, height: "42", padding: '16px' }}
                                    >End Call</Button>}
                                    {!isCalling && <Button
                                        key='phoneconnect'
                                        onClick={() => { prepareToNumber(toNumber, dialCode) }}
                                        startIcon={<Icon style={{ fontWeight: 'normal', fontSize: '1.4em', marginTop: -3 }}>call</Icon>}
                                        style={{ ...styles.successButton, height: "41", padding: '16px' }}
                                    >{getLocalizedStrings().label.TWILIO.CALL}</Button>}
                                </div>
                            </div>

                            <div style={{ ...styles.row }}>
                                <div style={{ marginTop: '35px', width: '44%', float: 'left' }}>
                                    <div style={{ marginLeft: -50 }}>
                                        <div className="dialPad compact" key='dailPad'>
                                            <div className="dials" key='dails-1' >
                                                <ol> {getDailPad()} </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ marginTop: '10px', width: '56%', float: 'left' }}>
                                    <div>
                                        <div style={{ display: ' inline-block', marginTop: '28px', marginLeft: 20 }}>
                                            {showCallScriptLink &&
                                                <div style={{ marginTop: '10px', marginLeft: '-10px', }}>

                                                    <div style={{ marginLeft: '10px', width: widthOfNoteandCallScript }}>

                                                        <FormControl variant="outlined" style={{ width: widthOfNoteandCallScript }}>
                                                            <InputLabel id="sf-call-dialog-from-calldialog-result-simple-select-outlined-label" className="sf-call-dialog-from-calldialog-result">Note and Call Scripts</InputLabel>
                                                            <Select
                                                                id='callScriptList'
                                                                value={selectedCallScript}
                                                                onChange={(event) => changeCallScript(event)}
                                                                name='callScriptList'
                                                                style={{ height: '42px', lineHeight: 2, Width: widthOfNoteandCallScript }}
                                                                label={"Note and Call Scripts"}
                                                                className={"sf-fields-bg"}
                                                            >
                                                                {callScriptList}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div style={{ marginTop: '10px', marginLeft: '10px', width: widthOfNoteandCallScript, height: '210px' }}>
                                                        <FormControl style={{ width: widthOfNoteandCallScript, backgroundColor: '#fff' }} className="test" noValidate autoComplete="off">
                                                            <TextField
                                                                variant="outlined"
                                                                name="call_script_details"
                                                                multiline={true}
                                                                type='textarea'
                                                                rows={8}
                                                                id='noteTextArea'
                                                                onChange={(e) => tempsetNoteData(e.target.value)}
                                                                fullWidth={true}
                                                                textareastyle={{ marginTop: '5px', resize: 'none', marginBottom: '5px', height: '215px' }}
                                                                inputStyle={{ marginTop: '10px', paddingBottam: '10px', }}
                                                                value={fieldValueForCallScriptDetails}
                                                                className={"sf-fields-bg"}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            }
                                            {!showCallScriptLink &&
                                                <div style={{ borderLeft: '1px solid', height: '270px', padding: '40px', marginLeft: 20, paddingRight: 0, marginTop: 25, paddingTop: 100, paddingLeft: 25 }}>
                                                    <div>
                                                        <span>You don't have any call scripts</span>
                                                    </div>
                                                    <div style={{ paddingLeft: '0px', marginTop: 10 }} ><a href="javascript:void(0);"
                                                        onClick={() => openAddScriptDrawer()} >{'Add a new call script'}</a>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {noteButtonVisibility &&
                                <span style={{ float: 'right', paddingRight: '60px' }}>
                                    <Button
                                        onClick={() => saveNote()}
                                        disabled={isbtnDisable}
                                        style={styles.primaryButton}
                                    > {getLocalizedStrings().label.COMMON.SAVE}</Button>
                                    <Button
                                        onClick={() => closeCallDialogDrawer()}
                                        style={styles.primaryButton}
                                    > {getLocalizedStrings().label.COMMON.CLOSE}</Button>
                                </span>
                            }
                        </div>
                    </div >
                </div>
            );
        } else if (!isCallingSupport) {
            return (<div style={{ marginTop: '10px' }}>

                {getLocalizedStrings().message.TWILIO.CALLING_SUPPORT}

            </div>

            );
        } else if (!isOutBoundPhoneNumberVerfied) {
            return (<div style={{ marginTop: '10px' }}>

                {getLocalizedStrings().message.TWILIO.OUTBOUND_PHONE_NOT_VERIFIED}

            </div>

            );
        } else {
            let contentHeight = window.innerHeight - 240;
            let top = (contentHeight - 10) / 2
            return (
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            );
        }
    }
    const openAddScriptDrawer = () => {
        try {
            let data = {};
            data.id = 0;
            data.object = constants.CALL_SCRIPT_OBJECT;
            data.mode = constants.SF_FORM_OPEN_MODE_QUICK;
            data.parent_object = data.object;
            dispatch(getAppCustomDrawer(false, null, null, null, null, null, null));
            let objectLabel = getObjectLabelByObject(constants.CALL_SCRIPT_OBJECT);
            let labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + objectLabel;
            dispatch(getAppDrawer(true, labelName, 'New', data, 'list'));
        } catch (error) {
            console.error("Error in 'callDialog.js -> clickDailPad()':" + error);
        }
    }
    const handleClose = () => {
        if (callStrated) {
            setCallStrated(false);
        }
        dispatch(getCallDrawer(false, null, null, null, null, null));
    }
    var fullWidth = window.innerWidth;
    let callDrawer = window.innerHeight - 110;
    var drawerWidthP = '41%';
    var dw = drawerWidthP.split("%")[0];
    var drawerWidth = fullWidth * 0.01 * parseInt(dw);

    if (isMinimize) {
        return (
            <div class='minimized-mail-bar'>
                <div style={{ backgroundColor: '#717171' }}>
                    <div style={{ display: 'flex' }} >
                        <div style={{ color: '#fff', paddingLeft: '20px', textAlign: 'left', height: '40px', lineHeight: '40px' }}>
                            <div style={{ display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={getMinimizeHeader()}>
                                <span style={{ color: '#fff', fontSize: '20px' }}> {getMinimizeHeader()}</span>
                            </div>

                        </div>
                        <div >
                            {<IconButton>
                                <div style={{ textAlign: 'right' }} className='sf-disconnect-button'>
                                    <Icon onClick={() => {
                                        setIsMinimize(false);
                                        disconnectCall();
                                    }} title="End Call" style={{ fontWeight: 'normal', fontSize: '1.0em', cursor: 'pointer', color: '#f75723' }}>call_end</Icon>
                                </div>
                            </IconButton>}
                        </div>

                        <div >
                            <IconButton>
                                <i className="fas fa-window-maximize" title="Maximize"
                                    style={{ color: '#fff', fontWeight: 'normal', fontSize: '1.0em', cursor: 'pointer' }}
                                    onClick={() => {
                                        setIsMinimize(false);
                                    }}></i>
                            </IconButton>

                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className='sf-call-drawer-backdrop' >
                <div className='sf-call-drawer' style={{ height: callDrawer, width: drawerWidth }}>
                    <div className='sf-call-drawer-toolbar'>
                        {<div className='sf-drawer-title'>
                            <Typography variant="h6" style={{ color: '#fff', paddingLeft: "24px", fontWeight: 400, fontSize: '24px', textAlign: 'left', height: '48px', lineHeight: '48px' }}>
                                {dialogTitle}
                            </Typography>
                            <span className='sf-call-drawer-button-right'>
                                {!isCalling && <IconButton
                                    onClick={() => handleClose()}
                                    title={getLocalizedStrings().label.COMMON.CLOSE}>
                                    <Icon style={{ color: '#fff' }}> close</Icon>
                                </IconButton>
                                }
                                {isCalling && <IconButton onClick={() => {
                                    let titleDiv = getMinimizeHeader();
                                    let headerProps = {};
                                    setIsMinimize(true);
                                }}
                                    title={'Minimize'}>
                                    <i className="fas fa-window-minimize" title="Minimize"
                                        style={{ fontWeight: 'normal', fontSize: '1.0em', cursor: 'pointer', color: '#fff', marginLeft: 5 }}
                                    ></i>
                                </IconButton>}
                            </span>
                        </div>}
                    </div>
                    <div className='sf-call-drawer-body' style={{ overflowY: 'scroll' }}>
                        <div>{getCallInnerComponent()}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CallDialog;