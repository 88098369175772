
import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { isValidParam, isInteger} from '../../../services/helper/parameterVerifier';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { addNode } from '../../../services/actions/automationCampaignActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getNodeByChildId, getNodeByParentId} from '../../../services/helper/automationUtils';
import { Select, MenuItem,InputLabel} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';

const AutomationWait = ({data,closeDrawer}) => {
    const dispatch = useDispatch();
    const automationCampaign = useSelector((state) => state.automationCampaign);
    const [interval,setInterval] = useState(5);
    const [intervalMode,setIntervalMode] = useState('days');

    useEffect(() => {
        if (data !== null) {
            if (data.hasOwnProperty('details')) {
                let detailsArray = data.details[0];
                if (detailsArray !== null && detailsArray.hasOwnProperty('interval')) {
                    let intervalMode = detailsArray.interval_mode;
                    let intervalHours = detailsArray.interval;
                    let intervalDays = Math.floor(intervalHours / 24);
                    setInterval(intervalMode === 'days' ? intervalDays : intervalHours);
                    setIntervalMode(intervalMode);
                    
                }
            }
        }
    }, []);

    
    const changeInterval = (event, value) => {

        if (isValidParam(value)) {
            value = value.trim();
            if (!isInteger(value)) {
                value = "";
            }
            setInterval(value);            
        }
    }

    const saveWait = () => {
        let _interval = interval;
        let dialogData = null;
        let skipValidation = false;
        let objNode = data;
        
        let arrayNodes = automationCampaign.data.nodes;
        let immediateNextId = objNode.childId;
        let immediateNextNodeDetails = null;
        immediateNextNodeDetails = getNodeByParentId(arrayNodes, immediateNextId);
        if (isValidParam(immediateNextNodeDetails)) {
            immediateNextNodeDetails = immediateNextNodeDetails.filter(n => n.nodeStatusFlg !== 3);
            immediateNextNodeDetails = immediateNextNodeDetails[0];
        }
        if (objNode.decisionFlg === 0 || objNode.decisionFlg === 1) {
            skipValidation = true;
        } else if(objNode.decisionFlg === 2) {
            let immediatePreviousId = objNode.parentId;
            let immediateParentNodeDetails = getNodeByChildId(arrayNodes, immediatePreviousId);
            if (isValidParam(immediateParentNodeDetails) && immediateParentNodeDetails.type === 1) {
                skipValidation = false;
            } else {
                skipValidation = true;
            }
        }

        if (objNode.parentId !== 0) {
            let tempPaentNode = getNodeByChildId(automationCampaign.data.nodes, objNode.parentId);
            if (tempPaentNode.hasOwnProperty('actionName') && tempPaentNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL) {
                skipValidation = true;
            } else if (isValidParam(immediateNextNodeDetails)  && immediateNextNodeDetails.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
                skipValidation = false;
            }
        }
        if (skipValidation && _interval === '') {
            dialogData = {
                message: getLocalizedStrings().message.AUTOMATION_DESIGNER.BLANK_INTERVAL,
                fieldId: 'interval'
            }
            openDialog(dialogData);
        } else if (!skipValidation && (_interval === '' || parseInt(_interval) < 1)) {
            dialogData = {
                message: getLocalizedStrings().message.AUTOMATION_DESIGNER.PROVIDE_POSITIVE_INTERVAL,
                fieldId: 'interval'
            }
            openDialog(dialogData);
        } else {
            _interval = parseInt(interval);
            if (isValidParam(data)) {
                if (data.hasOwnProperty('details')) {
                    let detailsArray = data.details[0];
                    if (isValidParam(detailsArray) && detailsArray.hasOwnProperty('interval')) {
                        if (intervalMode === 'days') {
                            _interval = Math.floor(_interval * 24);
                        }
                        detailsArray.interval = _interval;
                        detailsArray.interval_mode = intervalMode;
                        data.interval_mode = intervalMode;

                        let arrayNodes = automationCampaign.data.nodes;
                        let nodeObject = data;
                        if (isValidParam(nodeObject) && nodeObject.hasOwnProperty('actionName')) {
                            let immediateNextId = 0;
                            let immediateNextNodeDetails = null;
                            if (isValidParam(nodeObject.childId) && nodeObject.childId > 0) {
                                immediateNextId = nodeObject.childId;
                                immediateNextNodeDetails = getNodeByParentId(arrayNodes, immediateNextId);
                                immediateNextNodeDetails = immediateNextNodeDetails.filter(n => n.nodeStatusFlg !== 3);
                                immediateNextNodeDetails = immediateNextNodeDetails[0];
                                if (isValidParam(immediateNextNodeDetails)) {
                                    let nextNodeActionName = immediateNextNodeDetails.hasOwnProperty('actionName') ? immediateNextNodeDetails.actionName : "";
                                    if (nextNodeActionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
                                        if (immediateNextNodeDetails.hasOwnProperty('details')) {
                                            let nextNodeDetailsArray = immediateNextNodeDetails.details[0];
                                            if (isValidParam(nextNodeDetailsArray)) {
                                                if(intervalMode === 'days') {
                                                    nextNodeDetailsArray.dbp = _interval / 24;
                                                    nextNodeDetailsArray.interval_mode = 'days';
                                                } else {
                                                    nextNodeDetailsArray.dbp = _interval;
                                                    nextNodeDetailsArray.interval_mode = 'hours';
                                                }
                                            }
                                            if (immediateNextNodeDetails.hasOwnProperty('detailStatusFlg')) {
                                                immediateNextNodeDetails.detailStatusFlg = constants.AUTOMATION_DESIGNER_ACTIONTYPE_TWO;
                                            }
                                        }

                                    } else if (nextNodeActionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                                        if (immediateNextNodeDetails.hasOwnProperty('details')) {
                                            let nextNodeDetailsArray = immediateNextNodeDetails.details[0];
                                            if (isValidParam(nextNodeDetailsArray)) {
                                                if(intervalMode === 'days') {
                                                    nextNodeDetailsArray.interval = _interval / 24;
                                                    nextNodeDetailsArray.interval_mode = 'days';
                                                } else {
                                                    nextNodeDetailsArray.interval = _interval;
                                                    nextNodeDetailsArray.interval_mode = 'hours';
                                                }
                                            }
                                            if (immediateNextNodeDetails.hasOwnProperty('detailStatusFlg')) {
                                                immediateNextNodeDetails.detailStatusFlg = constants.AUTOMATION_DESIGNER_ACTIONTYPE_TWO;
                                            }
                                        }
                                    } else if (nextNodeActionName === constants.AUTOMATION_DESIGNER_ADD_SMS) {
                                        if (immediateNextNodeDetails.hasOwnProperty('details')) {
                                            let nextNodeDetailsArray = immediateNextNodeDetails.details[0];
                                            if (isValidParam(nextNodeDetailsArray)) {
                                                if(intervalMode === 'days') {
                                                    nextNodeDetailsArray.dbp = _interval / 24;
                                                    nextNodeDetailsArray.interval_mode = 'days';
                                                } else {
                                                    nextNodeDetailsArray.dbp = _interval;
                                                    nextNodeDetailsArray.interval_mode = 'hours';
                                                }
                                            }
                                            if (immediateNextNodeDetails.hasOwnProperty('detailStatusFlg')) {
                                                immediateNextNodeDetails.detailStatusFlg = constants.AUTOMATION_DESIGNER_ACTIONTYPE_TWO;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        dispatch(addNode(automationCampaign.data));
                        closeDrawer();
                    }
                }
            }
        }
    }

   const changeIntervalMode = (event) => {
        let value = event.target.value;
        if (isValidParam(value)) {
            setIntervalMode(value);
            
        }
    }

   const openDialog = (dialogData) => {
        dispatch(getAppDialog(true, constants.ALERT_DIALOG, getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, null, dialogData, constants.AUTOMATION_DESIGNER_OBJECT));
    }

        let campaignStatus = automationCampaign.data.status;
        let intervalModeValue = getLocalizedStrings().label.AUTOMATION_DESIGNER['Hour(s)'];
        let language = getLocalizedStrings()._language;
        let txtFldWidth = 150;
        if (language === 'de-DE') {
                txtFldWidth = 175;
        } 
        return (
            <div style={{ paddingLeft: '18px' }}>

                <div style={{ display: 'inline-block', width: txtFldWidth }}>
                <FormControl style={{width:'100%'}} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            autoFocus
                            label={getLocalizedStrings().label.AUTOMATION_DESIGNER.INTERVAL_REQUIRED}
                            id="interval"
                            value={interval}
                            onChange={(e)=>changeInterval(e,e.target.value)}
                            inputprops={{maxlength: '3'}}
                            autoComplete="new-password"
                            margin='dense'
                            size='small'
                            className={"sf-fields-bg"}
                        />
                    </FormControl>
                </div>

                {/* <div style={{ display: 'inline-block', paddingLeft: '10px' }}>
                    {getLocalizedStrings().label.AUTOMATION_DESIGNER.WAIT_FOR_DAYS}
                </div> */}
                <div style={{ display: 'inline-block', paddingLeft: '10px' , paddingTop:'10px', width: '105px'  }}>
                    <FormControl variant="outlined" style={{ width: '100%', marginTop:'8px' }}>
                        <InputLabel id="sf-automation-wait-from-automationwait-simple-select-outlined-label" className="sf-automation-wait-from-automationwaits">Interval Type</InputLabel>
                        <Select
                            value={intervalMode}
                            onChange={(event)=>changeIntervalMode(event)}
                            label="Interval Type"
                            labelId='sf-label-interval'
                            id='sf-interval'
                            style={{height:'39px'}}
                            className={"sf-fields-bg"}
                        >
                            <MenuItem value="days" style={styles.popoverMenuItem} >{getLocalizedStrings().label.CAMPAIGNS.DAYS} </MenuItem>
                            <MenuItem value="hours" style={styles.popoverMenuItem} >{intervalModeValue}</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                {
                    (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                    <div style={{marginTop:'15px'}}>
                        <Button
                            style={styles.listViewPrimaryButton}
                            onClick={()=>saveWait()}
                        >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                    </div>
                }
            </div>
        );
    }


export default AutomationWait;

