import { actions } from "..//constants/actions";

const filterMenuInitialState = {
  filterMounted: false,
  groupMounted: false,
  recentRecordMounted: false,
  queries: {},
  groups: [],
  recentRecords: {},
  recentFilters: {},
  groupTotalCount: 0,
  filterTotalCount: 0,
  error: null,
  user_group_status: null,
  recentSearchesText: {},
  recentSearchRecords: {},
  recentObjectList: {},
  advanceSearchRecord: {},
  listItemName: {},
};

const filterMenuReducer = (state = filterMenuInitialState, action) => {
  switch (action.type) {
    case actions.GROUPS_GET_PENDING:
      return {
        ...state,
        groupMounted: false,
      };
    case actions.GROUPS_GET_FULFILLED:
      return {
        ...state,
        groupMounted: true,
        groups: action.payload.groups,
        user_group_status: action.payload.user_saved_status,
      };
    case actions.RECENT_RECORD_GET_FULFILLED:
      return {
        ...state,
        recentRecordMounted: true,
        recentRecords: action.payload.records,
      };
    case actions.FILTER_SET_FULFILLED:
      return {
        ...state,
        filterMounted: true,
        queries: action.payload,
      };
    // case actions.FILTERS_GET_PENDING:
    //     return {
    //         ...state,
    //         filterMounted: false
    //     }
    // case actions.FILTERS_GET_FULFILLED:
    //     return {
    //         ...state,
    //         filterMounted: true,
    //         queries: action.payload.queries,
    //     }
    // case actions.RECENT_RECORD_GET_PENDING:
    //     return {
    //         ...state,
    //         recentMounted: false
    //     }
    // case actions.RECENT_RECORD_GET_FULFILLED:
    //     return {
    //         ...state,
    //         recentMounted: true,
    //         recentRecords: action.payload.recentRecords,
    //     }
    case actions.RECENT_SEARCHES_TEXT:
      return {
        ...state,
        recentSearchMounted: true,
        recentSearchesText: action.payload.recentSearchesText,
      };
    case actions.RECENT_SEARCHES_RECORD:
      return {
        ...state,
        recentSearchRecords: action.payload.recentSearchRecords,
      };
    case actions.RECENT_OBJECT_TABLE_LIST:
      return {
        ...state,
        recentObjectList: action.payload.recentObjectList,
      };
    case actions.RECENT_ADVANCE_SEARCH_LIST:
      return {
        ...state,
        advanceSearchRecord: action.payload,
      };
    case actions.SET_REPORT_RECENT_FILTER:
      return {
        ...state,
        recentFilters: action.payload,
      };
    case actions.SET_REPORT_RECENT_RECORD:
      return {
        ...state,
        recentRecords: action.payload,
      };
    case "SET_SUB_MENU":
      return {
        ...state,
        listItemName: action.payload,
      };
    default:
      return state;
  }
};

export default filterMenuReducer;
