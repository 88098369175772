import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import Button from '@mui/material/Button';
import { setHeader } from '../../../services/actions/headerActions';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { isValidParam, getObjectParam } from '../../../services/helper/parameterVerifier';

import * as sfDialogs from '../components/sfDialogs';

const GenerateAPIkey = ({ object }) => {
    const dispatch = useDispatch();

    const [isKey, setIsKey] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [generatedOn, setGeneratedOn] = useState('');
    const [updatedOn, setupdatedOn] = useState('');
    const [mounted, setMounted] = useState(false);


    useEffect(() => {
        dispatch(setHeader(object, getLocalizedStrings().label.CUSTOMIZATION_SETTING.API_KEY, null, false, true));
        getGeneratedKey();
    }, []);

    const getGeneratedKey = () => {
        try {
            var promise = Promise.resolve(HTTPClient.get(endPoints.LOGIN.GET_GENERATE_API_KEY, null));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response) && getObjectParam(response) && response.status !== -1) {

                        setApiKey(response.apiKey);
                        setGeneratedOn(response.generatedOn);
                        setupdatedOn(response.updatedOn);
                        setIsKey(true);
                        setMounted(true);

                    } else {
                        setIsKey(false);
                        setMounted(true);
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'GenerateAPIkey.js -> getUrl()':" + error);
        }
    }
    const generateAPIKey = () => {
        try {
            var promise = Promise.resolve(HTTPClient.post(endPoints.LOGIN.GENERATE_API_KEY, null));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        setApiKey(response.data.apiKey);
                        setGeneratedOn(response.data.generatedOn);
                        setupdatedOn(response.data.updatedOn);
                        setIsKey(true);
                        setMounted(true);

                    } else {
                        setIsKey(true);
                        setMounted(true);
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'GenerateAPIkey.js -> getUrl()':" + error);
        }
    }
    const ReGenerateAPIkey = () => {
        try {
            var promise = Promise.resolve(HTTPClient.post(endPoints.LOGIN.RE_GENERATE_API_KEY, null));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {

                        setApiKey(response.data.apiKey);
                        setupdatedOn(response.data.updatedOn);
                        setIsKey(true);
                        setMounted(true);

                    } else {
                        setIsKey(true);
                        setMounted(true);
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'GenerateAPIkey.js -> getUrl()':" + error);
        }
    }
    const deleteGenerateAPIkey = () => {
        try {
            let msg = getLocalizedStrings().message.COMMON.DELETE_API_KEY;
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, onConfirmDeleteApiKey.bind(), null);
        } catch (error) {
            console.error("Error in 'GenerateAPIkey.js ---> deleteEmail() '" + error);
        }
    }
    const onConfirmDeleteApiKey = () => {
        let promise1 = Promise.resolve(HTTPClient.get(endPoints.LOGIN.DELETE_GENERATE_API_KEY, null));
        promise1.then((response) => {
            if (isValidParam(response)) {

                setIsKey(false);
                setMounted(true);
            }
        });
    }

    let generateLeft = '37px';
    let updateLeft = '50px';
    let language = getLocalizedStrings()._language;
    if (language === 'de-DE') {
        generateLeft = '77px';
        updateLeft = '64px';
    }

    if (mounted) {
        return (

            <div>
                {!isKey ?
                    <div style={{ width:'95%', border: '1px solid grey', boxShadow: '3px 3px 5px 2px #aaaaaa', padding: '25px', margin: '30px' }}>
                        <div style={{ marginTop: '-10px', paddingBottom: '10px' }} >
                            <p><h2>{getLocalizedStrings().message.COMMON.API_KEY_MSG_3}</h2></p>
                            <p>{getLocalizedStrings().message.COMMON.API_KEY_MSG_1}<b style={{ marginLeft: '1px' }}> {getLocalizedStrings().message.COMMON.SOFFRONT} </b>
                                {getLocalizedStrings().message.COMMON.API_KEY_MSG_2}
                            </p>
                        </div>
                        <div style={{ width: 'auto', padding: '0px' }}>
                            <Button
                                key='Generate API Key'
                                onClick={() => generateAPIKey()}
                                style={{ ...styles.primaryButton, marginRight: '12px', verticalAlign: 'top' }}
                            >{getLocalizedStrings().label.CUSTOMIZATION_SETTING.API_KEY}</Button>
                        </div>

                    </div>
                    : <div className="row" style={{ paddingRight: '13px' }}>

                        <div className="col-sm-12">
                            <div style={{ paddingTop: '15px', paddingBottom: '30px', marginLeft: '15px', overflow: 'hidden', float: 'left' }}>
                                <div className="col-sm-6" style={{ width: 'auto', padding: '0px' }}>
                                    <Button
                                        key='Generate API Key'
                                        onClick={() => ReGenerateAPIkey()}
                                        style={{ ...styles.primaryButton, marginRight: '12px', verticalAlign: 'top' }}
                                    >{getLocalizedStrings().label.CUSTOMIZATION_SETTING.RE_API_KEY}</Button>
                                </div>
                            </div>
                            <div style={{ paddingTop: '15px', paddingBottom: '30px', overflow: 'hidden', float: 'left' }}>
                                <div className="col-sm-6" style={{ width: 'auto', padding: '0px' }}>
                                    <Button
                                        key='Delete'
                                        onClick={() => deleteGenerateAPIkey()}
                                        style={{ ...styles.primaryButton, marginRight: '12px', verticalAlign: 'top' }}
                                    >{getLocalizedStrings().label.COMMON.DELETE}</Button>
                                </div>
                            </div>

                        </div>
                        <span style={{ marginLeft: '30px', fontSize: '20px' }}>{getLocalizedStrings().message.COMMON.API_KEY_MSG_4}</span>
                        <div style={{width: '95%', border: '1px solid grey', boxShadow: '3px 3px 5px 2px #aaaaaa', padding: '25px', margin: '30px', fontSize: '14px' }}>
                            <div> <span>{getLocalizedStrings().label.CUSTOMIZATION_SETTING.API_KEY}: </span> <span style={{ marginLeft: '15px' }}> {apiKey} </span></div>
                            <div style={{ paddingTop: '15px', marginleft: '10px' }}> <span>{getLocalizedStrings().label.CUSTOMIZATION_SETTING.GENERATED_ON}: </span> <span style={{ marginLeft: generateLeft }}> {generatedOn} </span></div>
                            <div style={{ paddingTop: '15px', marginleft: '10px' }}> <span>{getLocalizedStrings().label.CUSTOMIZATION_SETTING.UPDATED_ON}: </span> <span style={{ marginLeft: updateLeft }}> {updatedOn} </span></div>
                        </div>
                    </div>}
            </div>
        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        return (<div style={{ width: '90%', height: contentHeight }}>
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
        </div>);
    }

}

export default GenerateAPIkey;

