import React, { useState, useEffect } from 'react';
import { styles } from '../../../services/constants/styles';
import TextField from '@mui/material/TextField';
import { Menu, Select, MenuItem, InputLabel, Button, FormControl } from '@mui/material';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getObjectParam } from '../../../services/helper/parameterVerifier';
import { useDispatch, useSelector } from "react-redux";


const SchedulerInfo = ({setScheduleInfoData, schedulerInfoData, errorFlag,schedulerId}) => {
    const dispatch = useDispatch();
    const duration_type = {
        min: 'Min(s)',
        hr: 'Hour(s)'
    }
    const advance_notice_type = {
        hours: 'Hour(s)',
        days: 'Day(s)'
    }
    const [scheduleInfo, setScheduleInfo] = useState({
        
            appt_name: "",
            location: "",
            appointmentType: null,
            duration: 30,
            duration_type: "min",
            min_advance_notice: 2,
            min_advance_notice_type: "hours",
            description: "",
            remainder: 30,
            isShrinkAppointment: false,
            appointmentTypes: []
    });

    let app = useSelector((state) => state.app);
    let calendar = useSelector((state) => state.calendar);

    useEffect(() => {
        let schInfo = getObjectParam(schedulerInfoData);
        let appointmentTypes = schInfo.appointmentTypes ; 
        let calendarInfoFromRedux = getObjectParam(calendar.calendarInfo);
        if(schedulerId === 0 ){
            schInfo = scheduleInfo ;
        }
        if (schInfo.hasOwnProperty('appt_name') || schInfo.hasOwnProperty('location')) {
            if (schedulerId > 0) {
                setScheduleInfo(schInfo);
            } else {
                schInfo["appt_name"] = "Appointment with " + app.me.name;
            }
    }
        if (schInfo.hasOwnProperty('appointmentType') && schInfo.appointmentType !== null && schInfo.appointmentType !== "") {
            schInfo["isShrinkAppointment"] = true;
        }
        if (schInfo.hasOwnProperty('appointmentTypes')) {
            schInfo["appointmentTypes"] = appointmentTypes;
        }else{
            schInfo["appointmentTypes"] = calendarInfoFromRedux.appTypes;
        }
        setScheduleInfo(schInfo);
        setScheduleInfoData(schInfo);
    }, []);

    useEffect(() => {
        setScheduleInfo(schedulerInfoData);
    },[schedulerInfoData]);

    const onChangeTextField = ( event,field) => {
        let tempScheduleInfo = scheduleInfo;
       
        if(field ==='appt_name'){
            setScheduleInfo({...tempScheduleInfo,appt_name:event.target.value});
        }else if (field ==='location'){
            setScheduleInfo({...tempScheduleInfo,location:event.target.value});
        }else{
            setScheduleInfo({...tempScheduleInfo,description:event.target.value});
        }
        tempScheduleInfo[field] = event.target.value;
        setScheduleInfoData(tempScheduleInfo);
    }

    const onChangeValue = (event,field) => {

        let scheduleInfodata = scheduleInfo;
        scheduleInfodata[field] = event.target.value;

        if( field === "duration"){
            setScheduleInfo({...scheduleInfodata,duration:event.target.value});
        }
        if (field === 'duration_type') {
            if (event.target.value === 'hr') {
                let temp =scheduleInfo;
                temp.duration= 1;
                temp.duration_type=event.target.value;
                setScheduleInfo({...temp});
            } else {
                let temp =scheduleInfo;
                temp.duration= 30;
                temp.duration_type=event.target.value;
                setScheduleInfo({...temp});
            }
        }
        if( field === "min_advance_notice"){
            setScheduleInfo({...scheduleInfodata,min_advance_notice:event.target.value});
        }
        if (field === 'min_advance_notice_type') {
            if (event.target.value === 'hours') {
                scheduleInfodata['min_advance_notice'] = 2;
                setScheduleInfo({...scheduleInfodata,min_advance_notice:2,min_advance_notice_type:event.target.value});
            } else {
                scheduleInfodata['min_advance_notice'] = 2;
                setScheduleInfo({...scheduleInfodata,min_advance_notice:2,min_advance_notice_type:event.target.value});
            }
        }
        setScheduleInfoData(scheduleInfodata);

    }

    let duration = [];
    let advance_notice = [];

    if (scheduleInfo.duration_type === 'hr') {
        for (var i = 1; i <= 23; i++) {
            duration.push(i);
        }
    } else {
        duration = [15, 30, 45, 60, 75, 90, 105, 120, 135, 150];
    }

    let adv_notice_len = 5;
    if (scheduleInfo.min_advance_notice_type === 'hours') {
        adv_notice_len = 23;
    } else if (scheduleInfo.min_advance_notice_type === 'days') {
        adv_notice_len = 30;
    }
    for (var i = 0; i <= adv_notice_len; i++) {
        advance_notice.push(i);
    }
    let appointmentTypeMenuItems = null;
    if (schedulerInfoData.appointmentTypes !== null && schedulerInfoData.appointmentTypes !== undefined && schedulerInfoData.appointmentTypes.length > 0) {
        let tempOption = schedulerInfoData.appointmentTypes;
        tempOption = tempOption.filter(e => e.label !== "Empty");
        appointmentTypeMenuItems = tempOption.map((key) => {
            return <MenuItem key={key.color} value={key.label} style={styles.popoverMenuItem}>{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }} title={key.label}>{key.label}</div>}</MenuItem>
        });
    }

    let durationMenuItems = null;
    durationMenuItems = duration.map((d, index) => {
        return <MenuItem key={d} value={d} style={styles.popoverMenuItem}>{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }} title={d}>{d}</div>}</MenuItem>
    });
    let durationTypeMenuItems = null;
    durationTypeMenuItems = Object.keys(duration_type).map((key) => {
        return <MenuItem key={key} value={key} style={styles.popoverMenuItem} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }} title={duration_type[key]}>{duration_type[key]}</div>}</MenuItem>
    });

    let noticeMenuItems = null;
    let noticeTypeMenuItems = null;
    noticeMenuItems = advance_notice.map((d, index) => {
        return <MenuItem key={d} value={d} style={styles.popoverMenuItem} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }} title={d}>{d}</div>}</MenuItem>
    });
    noticeTypeMenuItems = Object.keys(advance_notice_type).map((key) => {
        return <MenuItem key={key} value={key} style={styles.popoverMenuItem} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }} title={advance_notice_type[key]}>{advance_notice_type[key]}</div>}</MenuItem>
    });
    let apptTexFldStyle = {};
    let textFldStyle = { backgroundColor: '#fff', height: '42px', paddingLeft: '7px', marginBottom: '12px', marginLeft: '5px' };

    if (scheduleInfo.hasOwnProperty('error') && scheduleInfo.error === 'appt_name_error') {
        apptTexFldStyle = { ...textFldStyle, border: '1px solid #f75723' };
    } else {
        apptTexFldStyle = { ...textFldStyle, border: '1px solid rgb(221, 221, 221)' };
    }

  
    return (
        <div style={{ paddingLeft: '175px', width: '82%', marginTop: '25px' }}>
            <div style={{ ...styles.row }}>
                <div style={{ ...styles.sf_12 }}>
                    <FormControl style={{ width: '100%' }} noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            label={getLocalizedStrings().label.SCHEDULER.APPOINTMENT_NAME}
                            name="apptname"
                            value={scheduleInfo.appt_name}
                            onChange={(e)=>onChangeTextField(e, 'appt_name')}
                            inputprops={{ maxlength: '255' }}
                            autoComplete="new-password"
                            margin="dense"
                            size='small'
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </div>
            </div>
            <div style={{ ...styles.row }}>
                <div style={{ ...styles.sf_12 }}>
                    <FormControl style={{ width: '100%' }} noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            label={getLocalizedStrings().label.SCHEDULER.LOCATION}
                            fullWidth={true}
                            name="location"
                            value={scheduleInfo.location}
                            onChange={(e)=>onChangeTextField(e, 'location')}
                            inputprops={{ maxlength: '255' }}
                            autoComplete="new-password"
                            margin="dense"
                            size='small'
                        />
                    </FormControl>
                </div>
            </div>
            <div style={{ ...styles.row }}>
                <div style={{ ...styles.sf_12 }}>
                    <FormControl variant="outlined" style={{ width: '100%', marginTop: '5px' }}>
                        <InputLabel shrink={scheduleInfo.isShrinkAppointment} id="sf-schedulerinfo-simple-select-outlined-label" style={{ height:15, marginTop:'5px' , lineHeight:0.8, background:'#fafafa' }}>{getLocalizedStrings().label.SCHEDULER.APPOINTMENT_TYPE}</InputLabel>
                        <Select
                            key="appointmentType"
                            id='appointmentType'
                            label={getLocalizedStrings().label.SCHEDULER.APPOINTMENT_TYPE}
                            variant="outlined"
                            value={scheduleInfo.appointmentType}
                            onChange={(e)=>onChangeValue(e, 'appointmentType')}
                            name='appointmentType'
                            onFocus={() => setScheduleInfo({ ...scheduleInfo, isShrinkAppointment: true })}
                            style={{ height: '43px', width: '100%', marginTop:'5px' }}
                        >
                            {appointmentTypeMenuItems}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div style={{...styles.row, marginTop: '7px' }}>
            <div style={{ ...styles.sf_6 }}>
                    <span style={{ marginLeft: '5px', fontSize: '14px' }}>{getLocalizedStrings().label.SCHEDULER.DURATION}</span>
                </div>
                <div style={{ ...styles.sf_6 }}>
                    <span style={{ marginLeft: '5px', fontSize: '14px' }}>{getLocalizedStrings().label.SCHEDULER.MIN_ADVANCE_NOTICE}</span>
                </div>
            </div>
            <div style={{...styles.row}} >
            <div style={{...styles.sf_6  }}>
                    <FormControl variant="outlined">
                        <InputLabel id="sf-schedulerinfo-simple-select-outlined-label">{null}</InputLabel>
                        <Select
                            key="duration"
                            id='duration'
                            value={scheduleInfo.duration}
                            onChange={(e)=>onChangeValue(e, 'duration')}
                            name='duration'
                            style={{ height: '35px', width: '80px' }}
                        >
                            {durationMenuItems}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" style={{ paddingLeft: '5px' }} >
                        <InputLabel id="sf-scheduler-info-simple-select-outlined-label" >{null}</InputLabel>
                        <Select
                            key="durationt_ype"
                            id='duration_type'
                            value={scheduleInfo.duration_type}
                            onChange={(e)=>onChangeValue(e, 'duration_type')}
                            name='duration_type'
                            style={{ height: '35px', width: '90px' }} >
                            {durationTypeMenuItems}
                        </Select>
                    </FormControl>
                </div>

                <div style={{ ...styles.sf_6 }}>
                    <FormControl variant="outlined">
                        <InputLabel id="sf-schedulerinfo-from-scheduler-simple-select-outlined-label">{null}</InputLabel>
                        <Select
                            key="min_advance_notice"
                            id='min_advance_notice'
                            value={scheduleInfo.min_advance_notice}
                            onChange={(e)=> onChangeValue(e, 'min_advance_notice')}
                            name='min_advance_notice'
                            style={{ height: '35px', width: '80px' }} >
                            {noticeMenuItems}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" style={{ paddingLeft: '5px' }}>
                        <InputLabel id="sf-schedulerinfo-from-schedulerinfo-simple-select-outlined-label" >{null}</InputLabel>
                        <Select
                            key="min_advance_notice_type"
                            id='min_advance_notice_type'
                            value={scheduleInfo.min_advance_notice_type}
                            onChange={(e)=>onChangeValue(e, 'min_advance_notice_type')}
                            name='min_advance_notice_type'
                            style={{ height: '35px', width: '90px' }} >
                            {noticeTypeMenuItems}
                        </Select>
                    </FormControl>
                </div>
            </div>

            <div style={{...styles.row}} >
            <div style={{ ...styles.sf_12 }}>
                    <FormControl style={{ width: '100%', paddingTop: '12px' }} noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            type='textarea'
                            multiline={true}
                            label={getLocalizedStrings().label.SCHEDULER.DESCRIPTION}
                            fullWidth={true}
                            name="description"
                            value={scheduleInfo.description}
                            minRows={3}
                            onChange={(e)=>onChangeTextField(e, 'description')}
                            textareastyle={{ marginTop: '10px', height: '90px', resize: 'none' }}
                            style={{ maxHeight: '90px', height: '90px' }}
                            inputprops={{ maxlength: '1024' }}
                            autoComplete="new-password"
                        />
                    </FormControl>
                </div>
            </div>
        </div>);
}

export default (SchedulerInfo);
