import React from 'react';
import * as HTTPClient from '../../../services/helper/httpClient';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import { FormControl, FormControlLabel, RadioGroup, Button, Radio, Typography } from '@mui/material';
import { constants } from '../../../services/constants/constants';
import { isValidParam, getStringParam, getObjectParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { getXeroInfo, disconnect } from '../../../services/actions/xeroAction';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { openWindow } from '../../../services/helper/utils';
import { updateUserXeroSetup } from '../../../services/actions/appActions';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const XeroIntegration =({details, connectionHandle, disconnectHandle})=> {
    let _attempts = 0;
    const dispatch = useDispatch();
    const [isShowConnectButton, setIsShowConnectButton] = useState(false);
    const [isConnected, setIsConnected] = useState(false); 
    const [isConnecting, setIsConnecting] = useState(false);
    //const [attempts, setAttempts] = useState(0);
    const [intdetails, setDetails] = useState(null);
    const [isMounted, setIsMounted] = useState(false);

    const app = useSelector((state) => state.app);

    useEffect(() => {
        loadData();
     },[details]);
   

    const loadData = () => {
        try {
            if (details !== null && details !== undefined && details.is_connected) {
                setDetails({...details});
                setIsConnected(true);
                setIsShowConnectButton(false);
                setIsMounted(true);
            } else {
                setIsShowConnectButton(true);
            }
        } catch (error) {
            console.error("Error in 'xeroIntegration.js -> loadData()':" + error);
        }
    }
    const getXeroInfoDetails = () => {
        try {
            let promise = Promise.resolve(getXeroInfo());
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response) && getBooleanParam(response.is_connected)) {
                        setDetails(response);
                        setIsConnected(true);
                        setIsShowConnectButton(false);
                        setIsMounted(true);
                    } else {
                        setIsShowConnectButton(true);
                    }
                })
            }
        } catch (error) {
            console.error("Error in 'xeroIntegration.js -> getXeroInfoDetails()':" + error);
        }
    }
    const connectXero = () => {
        try {
            let url = constants.endPoint + endPoints.XERO_INTEGRATION.CONNECT + '?token=' + localStorage.getItem('token');
            let openQBWindow = openWindow({ location: url, spaces: 'scrollbars=1, location=no', width: 850, height: 650 });
            setIsConnecting(true);
            _attempts = 10;
            setTimeout(() => {
                checkIsConnected(openQBWindow);// This method will call after 20000 millisecond or 5 second
            }, 20000);//20000 millisecond = 5 second
        } catch (error) {
            console.error("Error in 'xeroIntegration.js -> connectXero()':" + error);
        }
    }

    const checkIsConnected = (openQBWindow) => {
        try {
            let refreshIntervalId = window.setInterval(() => {
                if (_attempts > 0) {
                    let promise = Promise.resolve(getXeroInfo());
                    if (isValidParam(promise)) {
                        promise.then(response => {
                            _attempts--;
                            if (isValidParam(response) && getBooleanParam(response.is_connected)) {
                                clearInterval(refreshIntervalId); 
                                let details = {};
                                details.xero_integration_type= null;
                                details.is_xero_invoice= false;
                                details.is_xero_payment= false;
                                details.is_xero= false;
                                details.name = response.name;
                                dispatch(updateUserXeroSetup(details));
                                connectionHandle();
                                _attempts = 0;
                                setDetails(response);
                                setIsConnected(true);
                                setIsConnecting(false);
                                setIsShowConnectButton(false);
                                
                            }
                        });
                    }
                } else if (_attempts === 0) {
                    openQBWindow.close();
                    clearInterval(refreshIntervalId);
                    if (isConnecting) {
                        _attempts = 0;
                        setDetails(null);
                        setIsConnecting(false);
                    }
                }
            }, 20000);// Above code will execute after every 20000 millisecond or 5 second 
        } catch (error) {
            console.error("Error in 'xeroIntegration.js -> checkIsConnected()':" + error);
        }
    }

    const disconnectXero = () => {
        try {
            let promise = Promise.resolve(disconnect());
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response) && response.status === 0 && getBooleanParam(response.data)) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DISCONNECT_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        let details = {};
                        details.xero_integration_type= null;
                        details.is_xero_invoice= false;
                        details.is_xero_payment= false;
                        details.is_xero= false;
                        dispatch(updateUserXeroSetup(details));
                        disconnectHandle();
                        
                        setDetails(null);
                        setIsConnected(false);
                        setIsShowConnectButton(true);
                    }
                })
            }
        } catch (error) {
            console.error("Error in 'xeroIntegration.js -> disconnectXero()':" + error);
        }
    }

    
        return <div>
            {/* <div style={{ borderBottom: '2.0px solid #afafaf5c' }}><span style={{ fontWeight: 'bold', fontSize: '20px' }}>{'Xero Integration'}</span></div> */}
            {isShowConnectButton && <div style={{ marginTop: '10px' }}>
                <div style={{ marginBottom: '10px' }}><span style={{ fontWeight: 'normal', fontSize: '14px' }}>{'To setup the integration, connect to your Xero Account'}</span></div>
                <Button style={styles.primaryButton} onClick={()=>connectXero()} >{getLocalizedStrings().label.QUICKBOOKS.CONNECT_BUTTON}</Button>
            </div>}
            {isConnecting && <span style={{ width: '68%' }}>Connecting<img src='/asset/images/processing.gif' alt='Connecting' style={{ height: '10px', width: '50px' }} /></span>}
            {isConnected && <ConnectedView details={intdetails}  getXeroInfo={()=>getXeroInfoDetails()} isConnected={isConnected} disconnect={()=>disconnectXero()} />}
        </div>;
    
}
export default (XeroIntegration);

const ConnectedView =({isConnected,disconnect})=>{
    
    const dispatch = useDispatch();
    const [isLoaded, setIsLoaded] = useState(true);
    const [integration_type, setIntegrationType] = useState(null); 
    const [integration_item, setIntegrationItem] = useState(null);
    const [is_invoices, seIsInvoices] = useState(false);
    const [is_payments, setIsPayments] = useState(false);

    const app = useSelector((state) => state.app);
    
    useEffect(() => { 
        let details = getObjectParam(app.me.xero_integration);
        let integrationType = getStringParam(details.xero_integration_type);
        let isInvoices = getBooleanParam(details.is_xero_invoice);
        let isPayments = getBooleanParam(details.is_xero_payment);
        let integration_item = null;
        
        if (isConnected) {
            setIntegrationType(integrationType);
            seIsInvoices(isInvoices);
            setIsPayments(isPayments);
            if(isInvoices){
                integration_item = "INVOICES";
            }
            if(isPayments){
                integration_item = "PAYMENTS";
            }
            setIntegrationItem(integration_item);

            
        }
    },[]);

   const handleItemIntegration = ( event,value) => {
      
        if (value === "INVOICES") {
            seIsInvoices(true);
            setIsPayments(false);
        } else {
            seIsInvoices(false);
            setIsPayments(true);
        }
        setIntegrationItem(value);

       
    }
    const handleChange = (event,selectedOption) => {
        setIntegrationType(selectedOption);
    }
    const xeroIntrgrationSave = () => {
        let data = {};
        data.integration_type = integration_type;
        data.is_invoices = is_invoices;
        data.is_payments = is_payments;
        let url = endPoints.XERO_INTEGRATION.UPDATE_DETAILS;
        if (integration_type === '' && !is_invoices && !is_payments) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.XERO.SELECT_ONE_INTEGRATION_ITEM_AND_TYPE, styles.snackbarBodyStyleError, styles.snackBarStyleTop))
        }else if (integration_type !== null &&  integration_type !== '' && !is_invoices && !is_payments) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.XERO.SELECT_ONE_INTEGRATION_ITEM, styles.snackbarBodyStyleError, styles.snackBarStyleTop))
        }else if ( integration_type === '' && (is_invoices || is_payments)) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.XERO.SELECT_INTEGRATION_TYPE, styles.snackbarBodyStyleError, styles.snackBarStyleTop))
        } else {
            let promise = Promise.resolve(HTTPClient.post(url, data));
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.status === 0) {
                        let details = {};
                        details.xero_integration_type= integration_type;
                        details.is_xero_invoice= is_invoices;
                        details.is_xero_payment= is_payments;
                        details.is_xero= true;
                        details.name = getStringParam(app.me.xero_integration.name);
                        dispatch(updateUserXeroSetup(details));
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    }
                }
            });
        }
    }
   
        let pageHeight = window.innerHeight - 247;
        let top = (pageHeight - 10) / 2;
        let details = getObjectParam(app.me.xero_integration);
        return <div style={{ marginTop: '10px' }}>
            {!isLoaded && <div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>}
            {isLoaded && <div>
                <div>
                    <div style={{ padding: '8px', fontSize: '15px', height: '40px', background: 'rgb(227, 230, 232)', marginRight: '1%' }}>
                        You have connected as {getStringParam(details.name)}
                        <Button
                            style={{ ...styles.secondaryButton, float: 'right' }}
                            onClick={() => {dispatch(()=>disconnect())}}
                        >{getLocalizedStrings().label.DOCUSIGN.DISCONNECT_BUTTON}</Button></div>
                </div>
                <div style={{ paddingTop: '15px' }}>
                    <Typography variant="subtitle1">{getLocalizedStrings().label.MY_INTEGRATION.SELECT_INTEGRATION_ITEMS}</Typography>
                    <FormControl component="fieldset" >
                        <RadioGroup aria-label={getLocalizedStrings().label.MY_INTEGRATION.INTEGRATE} name="gender1" value={integration_item} onChange={(event,value) => handleItemIntegration(event,value)}>
                            <FormControlLabel value="INVOICES" control={<Radio color='default' />} label={getLocalizedStrings().label.MY_INTEGRATION.INVOICES} />
                            <FormControlLabel value="PAYMENTS" control={<Radio color='default' />} label={getLocalizedStrings().label.MY_INTEGRATION.INVOICES+' '+getLocalizedStrings().label.COMMON.AND+' '+getLocalizedStrings().label.MY_INTEGRATION.PAYMENTS} />
                        </RadioGroup>
                    </FormControl>  
                </div>
                <div>
                    <Typography variant="subtitle1">{getLocalizedStrings().label.MY_INTEGRATION.INTEGRATE}</Typography>
                    <FormControl component="fieldset" >
                        <RadioGroup aria-label={getLocalizedStrings().label.MY_INTEGRATION.INTEGRATE} name="gender1" value={integration_type} onChange={(event,value) => handleChange(event,value)}>
                            <FormControlLabel value="MANUALLY" control={<Radio color='default' />} label={getLocalizedStrings().label.MY_INTEGRATION.MANNUALLY} />
                            <FormControlLabel value="AUTOMATICALLY" control={<Radio color='default' />} label={getLocalizedStrings().label.MY_INTEGRATION.AUTOMATICALLY} />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div style={{ paddingTop: '15px' }}>
                    <Button
                        style={{ ...styles.primaryButton, float: 'right' }}
                        onClick={() => xeroIntrgrationSave() }
                    >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                </div>
            </div>}

        </div>
    
}
