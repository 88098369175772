
// import { actions } from '..//constants/actions';
import {actions} from '../constants/actions'

function getAppDrawer(isOpenDrawer, actionDialogName, actionType, data, callFrom) {
        return function (dispatch) {
            try {
            dispatch({
                type: actions.APP_CONTAINER_DRAWER_OPEN_CLOSE,
                payload: {
                    isOpenDrawer: isOpenDrawer,
                    actionDialogName: actionDialogName,
                    actionType: actionType,
                    callFrom: callFrom,
                    data:data
                }
            })
        } catch (e) { console.error("Error in 'appContainerActions.js -> getAppDrawer()':" + e); }
    }
}

function getAppDialog(isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom) {
     return function (dispatch) {
        try {
            dispatch({
                type: actions.APP_CONTAINER_DIALOG_OPEN_CLOSE,
                payload: {
                    isOpenDialog: isOpenDialog,
                    dialogType: dialogType,
                    dialogTitle: dialogTitle,
                    eventName: eventName,
                    data: data,
                    callFrom: callFrom
                }
            })
        } catch (e) { console.error("Error in 'appContainerActions.js -> getAppDialog()':" + e); }
    }
}

function updateAppDialogTitle( dialogTitle ) {
    return function (dispatch) {
       try {
           dispatch({
               type: actions.APP_CONTAINER_DIALOG_TITLE_UPDATE,
               payload: {
                   dialogTitle: dialogTitle,
               }
           })
       } catch (e) { console.error("Error in 'appContainerActions.js -> updateAppDialogTitle()':" + e); }
   }
}
function setAppDialogCallBackActions(callBackActions) {
    return function (dispatch) {
        try {
        dispatch({
            type: actions.APP_CONTAINER_DIALOG_SET_CALLBACK_ACTIONS,
            payload: {
                callBackActions: callBackActions
            }
        })
    } catch (e) { console.error("Error in 'appContainerActions.js -> setAppDialogActions()':" + e); }
}
}
function setAppDialogActions(dialogActions) {
    return function (dispatch) {
        try {
        dispatch({
            type: actions.APP_CONTAINER_DIALOG_SET_ACTIONS,
            payload: {
                dialogActions: dialogActions
            }
        })
    } catch (e) { console.error("Error in 'appContainerActions.js -> setAppDialogActions()':" + e); }
}
}
function reSetAppDialogCallBackActions() {
        return function (dispatch) {
            try {
            dispatch({
                type: actions.APP_CONTAINER_DIALOG_RESET_CALLBACK_ACTIONS,
                payload: {
                    callBackActions: null
                }
            })
        } catch (e) { console.error("Error in 'appContainerActions.js -> setAppDialogActions()':" + e); }
    }
}

function getAppCustomDrawer(isOpen, dialogName, actionType, style, data, headerProps, minimizable, callFrom) {
    try {   
        if(callFrom === undefined){
            callFrom = null;
        }
        return function (dispatch) {
            dispatch({
                type: actions.APP_CONTAINER_CUSTOM_DRAWER_OPEN_CLOSE,
                payload: {
                    isOpen: isOpen,
                    dialogName: dialogName,
                    actionType: actionType,
                    style: style,
                    callFrom: callFrom,
                    data: data,
                    headerProps: headerProps,
                    minimizable: minimizable
                }
            })
        }
    } catch (e) { console.error("Error in 'appContainerActions.js -> getAppDrawer()':" + e); }
}

function minimizeAppCustomDrawer(isMinimize, dialogName, style, data, headerProps,minimizeList) {
    try {
        let payload = {
            isMinimize: isMinimize, 
            minimizeList:minimizeList,
            dialogName: dialogName,
            style: style,
            data: data,
            headerProps: headerProps
        };

        return function (dispatch) {
            dispatch({
                type: actions.APP_CONTAINER_CUSTOM_DRAWER_MINIMIZE_MAXIMIZE,
                payload: payload
            })
        }
    } catch (e) { console.error("Error in 'appContainerActions.js -> getAppDrawer()':" + e); }
}

function setAppDrawerTitle(drawerTitle) {
        return function (dispatch) {
            try {
            dispatch({
                type: actions.APP_CONTAINER_DRAWER_SET_TITLE,
                payload: {
                    actionDialogName: drawerTitle,
                }
            })
        } catch (e) { console.error("Error in 'appContainerActions.js -> getAppDrawer()':" + e); }
    }
}

export {
    getAppDrawer,
    getAppDialog,
    updateAppDialogTitle,
    setAppDialogActions,
    getAppCustomDrawer,
    minimizeAppCustomDrawer,
    setAppDialogCallBackActions,
    reSetAppDialogCallBackActions,
    setAppDrawerTitle
}