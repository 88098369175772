import { actions } from '..//constants/actions';

function updateReferralReportData(resultSet) {
    return {
        type: actions.REFERRAL_REPORT_DATA_UPDATE,
        payload: resultSet
    }
}

export {
updateReferralReportData,
}