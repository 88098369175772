import React, { useCallback, useRef} from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../services/constants/actions";
import update from 'immutability-helper'
import { updateAuditCategory } from '../../../services/actions/auditActions';

const AuditCategoryDialogCard  = ({key,index,id,card}) =>{
    const dispatch = useDispatch();
    const categoryList = useSelector((state) => state.auditDetails.category);
    const ref = useRef(null)

    const [, drop] = useDrop({
      accept: actions.CATEGORY_CARD,
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        moveCard(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    });

    const moveCard = useCallback(
        (dragIndex, hoverIndex) => {
          const dragCard = categoryList[dragIndex]
          let rearrangeRows = update(categoryList, {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
              ],
            });
            dispatch(updateAuditCategory(rearrangeRows));
        },[dispatch, categoryList],
    )

    const [{ isDragging }, drag] = useDrag({
        type: actions.CATEGORY_CARD,
        item: {  id, index },
        collect: (monitor) => ({
          isDragging: monitor.isDragging()
        })
      });
    drag(drop(ref));

    return(
        <>
            <div style={{marginTop:'3px',display:'flex',width:'100%',cursor: 'grab',borderRadius:'10%'}} ref={ref}>
                <div style={{display:'grid',width:'94%',textOverflow:'ellipsis',overflow:'hidden',paddingTop:'4px',paddingBottom:'4px',paddingLeft:'10px',backgroundColor:'#FFFF'}}>
                    <span style={{fontSize:'16px',fontWeight:'400',fontFamily:'-apple-system, Roboto, Helvetica, Arial, sans-serif',overflow:'hidden', textOverflow:'ellipsis',whiteSpace:'nowrap',paddingBottom:'1px'}}>{card.t_category_name} </span>
                </div>
            </div>
        </>
    )
}

export default AuditCategoryDialogCard;