import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import ShowCircularProgress from '../components/circularProgress';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { isValidParam, getArrayParam, getStringParam, getIntParam, getObjectParam } from '../../../services/helper/parameterVerifier';

import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { getAppCustomDrawer, getAppDrawer } from '../../../services/actions/appContainerActions';
import { refreshDetailViewData, refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';
import { Button, TextField, Chip, FormControl, Popover, Select as SelectField, MenuItem, InputLabel, Dialog } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid, Typography } from "@mui/material";
import * as SFFormActions from '../../../services/actions/sfFormActions';
import { constants, OBJECT_TABLEID_MAP, TABLEID_OBJECT_MAP } from '../../../services/constants/constants';
import { styled } from '@mui/material/styles';
import {getActiveTabInfo} from '../../../services/helper/sfTabManager';
import {updateTabInfo } from '../../../services/helper/sfTabManager';

const ReportedSalesUpdate = (data) => {
    const appContainer = useSelector((state) => state.appContainer);
    const childListView = useSelector((state) => state.childListView);
    const detailView = useSelector((state) => state.detailView);
    const [finalArray, setFinalArray] = useState([]);
    const [records, setRecords] = useState([]);
    const [mounted, setMounted] = useState(false);
    const [reportedSalesUpdateData, setReportedSalesUpdateData] = useState({});
    const dispatch = useDispatch();
    const sfForm = useSelector((state) => state.sfForm);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#e0e0e0',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    let drHeight = window.innerHeight - 180;

    useEffect(() => {
        let _records = childListView.data.records;
        setRecords([..._records])
    }, [childListView.data.records, reportedSalesUpdateData]);

    useEffect(() => {
        let colArr = Object.assign({}, sfForm.data[constants.REPORTED_SALES_OBJECT]);
        if (Object.keys(colArr).length == 0) {
            getFormdata(constants.REPORTED_SALES_OBJECT);
        } else {
            setMounted(true);
        }
    }, [])

    const getFormdata = (object) => {
        setMounted(false);
        var promise = SFFormActions.getSFFormFieldsPromise(object);
        promise?.then((response) => {
            if (isValidParam(response)) {
                sfForm.data[object] = JSON.parse(JSON.stringify(getObjectParam(response)));
                let reportedSalesData = Object.assign({}, sfForm.data[object]);
                setReportedSalesUpdateData({ ...reportedSalesData });
                setMounted(true);
            }
        });

    }
    const handleChange = (value, index, colName, row) => {
        let selectedIds = appContainer.drawerProps.data.selectedRecordId;
        let _newRecordIds = appContainer.drawerProps.data?.newRecordDetails?.data?.newRecordIds;
        var newRecordIds = _newRecordIds?.split(',').map(function (item) {
            return parseInt(item, 10);
        });
        let allRecordIds = [];
        let isOkClick = appContainer.drawerProps.data.isYesButton;
        let isNoClick = appContainer.drawerProps.data.isCancelButton;
        let onAllRecordEdit = appContainer.drawerProps.data.onAllRecordEdit;

        let _records = [];

        if (isOkClick == true) {
            if (newRecordIds?.length > 0) {
                allRecordIds = newRecordIds.concat(selectedIds);
            }
            else {
                allRecordIds = selectedIds;
            }
            if (newRecordIds?.length > 0 || selectedIds?.length > 0) {
                records.map((rec) => {
                    allRecordIds.map((id) => {
                        if (rec.id == id) {
                            return _records.push(rec)
                        }
                    })
                })
            }

        } else if (isNoClick) {
            allRecordIds = selectedIds;
            if (allRecordIds.length > 0) {
                records.map((rec) => {
                    allRecordIds.map((id) => {
                        if (rec.id == id) {
                            return _records.push(rec)
                        }
                    })
                })
            }
        } else if (onAllRecordEdit) {
            _records = records;
        }
        let formArr = [..._records];
        let tempData = { ...formArr[index] };
        tempData[colName] = value;
        formArr[index] = tempData;
        setRecords([...formArr]);
        let _row = finalArray;
        let editRow = formArr.filter((f) => {
            return f.id == row.id;
        })

        let duplicateArr = _row.filter((f) => {
            return f.id == row.id;
        })
        let rowIndex = -1;
        if (duplicateArr.length > 0) {
            rowIndex = _row.findIndex(o => row.id === o.id);
        };
        if (rowIndex !== -1) {
            _row.splice(rowIndex, 1);

        }
        if (editRow.length > 0) {
            _row.push(editRow[0]);
        }

        setFinalArray(_row);
    };


    const onButtonClick = () => {
        let record = records.length > 0 ? records[0] : {};
        let col = Object.keys(record);
        col = col.filter((c) => {
            return c != "id";
        })
        let selectedIds = appContainer.drawerProps.data.selectedRecordId;
        let _newRecordIds = appContainer.drawerProps.data?.newRecordDetails?.data?.newRecordIds;
        var newRecordIds = _newRecordIds?.split(',').map(function (item) {
            return parseInt(item, 10);
        });
        let allRecordIds = [];
        let isOkClick = appContainer.drawerProps.data.isYesButton;
        let isNoClick = appContainer.drawerProps.data.isCancelButton;
        let onAllRecordEdit = appContainer.drawerProps.data.onAllRecordEdit;

        let _records = [];

        if (isOkClick == true) {
            if (newRecordIds?.length > 0) {
                allRecordIds = newRecordIds.concat(selectedIds);
            }
            else {
                allRecordIds = selectedIds;
            }
            if (newRecordIds?.length > 0 || selectedIds?.length > 0) {
                records.map((rec) => {
                    allRecordIds.map((id) => {
                        if (rec.id == id) {
                            return _records.push(rec)
                        }
                    })
                })
            }

        } else if (isNoClick) {
            allRecordIds = selectedIds;
            if (allRecordIds.length > 0) {
                records.map((rec) => {
                    allRecordIds.map((id) => {
                        if (rec.id == id) {
                            return _records.push(rec)
                        }
                    })
                })
            }
        } else if (onAllRecordEdit) {
            _records = records;
        }
        let colRecord = Object.keys(reportedSalesUpdateData).length > 0 ? reportedSalesUpdateData?.fields : sfForm.data?.reportedsales?.fields;
        let colArr = [];
        col.map((cols) => {
            colRecord?.map((val) => {
                if (cols == val.name) {
                    return colArr.push(val);
                }
            })
        });

        colArr = colArr.filter((c) => {
                return c.name == 'unit' || c.is_readonly !== true 
            
        })

        let element = <div className="row" style={{ width: '100%', paddingRight: '20px', paddingLeft: '12px' }}>

            {_records.length > 0 && <Grid
                xs={12}
                md={12}
                sm={12}
                style={{
                    marginTop: "10px",
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <TableContainer component={Paper} sx={{ maxHeight: drHeight }}>
                    <Table stickyHeader aria-label="sales report update table">
                        <TableHead>
                            <TableRow style={{}}>
                                {colArr.map((col) => (<StyledTableCell className="tableColumn" title={col.label} style={{
                                    whiteSpace: "nowrap",
                                    width: '60px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    minWidth:col.label == "Unit" ?'120px':'85px',
                                    maxWidth: col.label == "Unit" ?'120px':'80px'
                                }}>{col.label}</StyledTableCell>))}

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_records.map((row, index) => (
                                <TableRow key={row.name}>
                                    {colArr.map((col, i) => (
                                        <TableCell className='tableCell' align='left' >
                                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                                <TextField
                                                    variant="outlined"
                                                    type='textarea'
                                                    rows={4}
                                                    defaultValue={col.is_currency_field == true ? row[col.name].replace(/[^0-9\.-]+/g, "") : row[col.name]}
                                                    title={row[col.name]}
                                                    autoFocus={col.is_readonly == false && index == 0 && i == colArr.findIndex((c) => {
                                                        return !c.is_readonly
                                                    }) ? true : false}
                                                    disabled={col.is_readonly == true}
                                                    onBlur={(event) => handleChange(event.target.value, index, col.name, row)}
                                                    size="small"
                                                    inputProps={{
                                                        style: {
                                                            height: "13px",
                                                            fontSize:12
                                                        },
                                                    }}
                                                />
                                            </FormControl>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Grid>}


        </div>
        return element;
    }
    const handleClose = () => {      
        let _info = getActiveTabInfo();
            _info.selectedPageNo = 1;
            updateTabInfo(_info);
            dispatch(refreshDetailViewData(true));
            dispatch(refreshDetailViewListViewData(true));
        dispatch(getAppDrawer(false, null, null, null, null, null));
    }

    const onSave = () => {
        let fieldArr = [];
        let tempFields = Object.keys(reportedSalesUpdateData).length > 0 ? reportedSalesUpdateData?.fields : sfForm.data?.reportedsales?.fields;
        let royaltyArr = [];
        finalArray?.map((item, i) => {
            if (Object.keys(item).length > 0) {
                Object.keys(item).map((itemKey) => {
                    if (itemKey != 'id') {
                        let tempField = tempFields.filter((f) => {
                            return f.name == itemKey;
                        })
                        let fieldObj = {};
                        fieldObj.name = itemKey;
                        let value = item[itemKey].toString();
                        if (tempField[0].field_type === 'float' || tempField[0].field_type === "integer") {
                            value = value.replace(/[&\/\\#, +()$~%'":*?<>{}]/g, '');
                        }
                        fieldObj.value = value;
                        fieldObj.field_type = tempField[0].field_type;
                        if (!tempField[0].is_readonly) {
                            fieldArr.push(fieldObj);
                        }
                        fieldObj = null;

                    }

                });
            }
            let typeObj = {};
            typeObj.id = item.id;
            typeObj.fields = fieldArr;
            fieldArr = [];
            royaltyArr.push(typeObj);
        })
        let params = {
            "royalty_run_id": detailView.record.id,
            "rs_update_arr": royaltyArr
        }
        var response = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_RUN.REPORT_SALES_UPDATE, params));
        response.then((response) => {
            if (response.status == 0) {
                setMounted(false)
              let _info = getActiveTabInfo();
              if (_info.selectedPageNo == 1) {
                dispatch(refreshDetailViewData(true));
                dispatch(refreshDetailViewListViewData(true));
                }
                if(_info !== undefined)   {
                    setMounted(true)
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));    
                }            
                   }
        });
    }

    let selectedIds = appContainer.drawerProps.data.selectedRecordId;
    let height = window.innerHeight - 150;
    let top = (height - 10) / 2;
    if (mounted) {
        return (
            <div style={{}}>
                <div>
                    {onButtonClick()}
                </div>

                <div style={{ float: 'right', paddingRight: '20px', paddingTop: '12px' }}>
                    <Button
                        primary={true}
                        onClick={() => onSave()}
                        style={{ ...styles.primaryButton }}
                    > {getLocalizedStrings().label.COMMON.SAVE}</Button>
                    <Button
                        primary={true}
                        onClick={() => handleClose()}
                        style={styles.secondaryButton}
                    >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                </div>

            </div>
        )

    } else {
        return (
            <div style={{ width: '100%', height: height }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        )
    }


}
export default ReportedSalesUpdate;