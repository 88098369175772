import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import * as HTTPClient from "..//../../services/helper/httpClient";
import {
  getItemName,
  getRecentFiltersPromise,
  getRecentRecords,
  getRecentRecordsTemplate,
} from "../../../services/helper/common";
import { setTabs } from "../../../services/actions/tabActions";
import ShowCircularProgress from "../components/circularProgress";
import { constants } from "../../../services/constants/constants";
import { styles } from "../../../services/constants/styles";
import { LinkToType } from "../../../services/constants/link";
import map from "lodash/map";
import {
  isValidParam,
  getArrayParam,
  getStringParam,
  makeValidObjectName,
  getObjectParam,
  getBooleanParam,
  makeValidAppUrl,
} from "../../../services/helper/parameterVerifier";
import {
  addTab,
  TYPE_DETAIL_VIEW,
  TYPE_REPORT,
  TYPE_OTHER_VIEW,
  addNewFormTabsOnConfirm,
  getTabsByItem,
  TYPE_LIST_VIEW,
  TYPE_HOME,
  TYPE_DASHBOARD,
} from "../../../services/helper/sfTabManager";
import {
  getImageNameByObject,
  setSelectedModuleInfo,
  saveRecentReportItems,
  getIconClass,
} from "../../../services/helper/common";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import {
  addRecentFilterInRedux,
  addRecentRecordInRedux,
  getReportUrl,
} from "../../../services/actions/SalesReportAction";
import * as sfDialogs from "../components/sfDialogs";
import { useDispatch, useSelector } from "react-redux";
import { endPoints } from "../../../services/constants/endPoints";

import { setHeader } from "../../../services/actions/headerActions";

const ColumnRecent = ({
  object,
  callFrom,
  itemLabel,
  closeSubMenu,
  changeSubMenuPosition,
  moduleItemId,
  item,
  }) => {
  const mitem = item;
  const app = useSelector((state) => state.app);
  const tab = useSelector((state) => state.tab);
  const filters = useSelector((state) => state.filters);
  const { listItemName } = filters;

  const [mounted, setMounted] = useState(false);
  const [recentRecords, setRecentRecords] = useState(null);
  const [hasRecord, setHasRecord] = useState(false);
  const [contentHeight, setContentHeight] = useState(
    document.body.clientHeight - 240
  );
  const [top, setTops] = useState((contentHeight - 10) / 2);
  const dispatch = useDispatch();
  const columnRef = useRef(null);
  const containerDetails =columnRef?.current?.getBoundingClientRect();

  //     componentWillUnmount() {
  //     this.props.filters.recentRecordMounted = false;
  // }
  let callFromMenu = callFrom === "menu" ? true : false;

  useEffect(() => {
    let timer;
    if (object === constants.SALES_MODULE_TEMPLATE) {
      timer = setTimeout(() => {
        getRecentRecordsTemplate(listItemName)
        .then((response) => {
          setRecentRecords(response?.recentRecords);
        })
        .catch((error) => {
          console.error(
            "Error in 'TopNavBar.js -> getRecordsTemplateMenu()':" + error
          );
        });
      }, 100);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [object, listItemName.child]);

  useEffect(() => {
    getRecords(object);
    return () => {
      filters.recentRecordMounted = false;
      filters.recentRecords = [];
    };
  }, [filters, object]);

  useEffect(() => {
    if (object || object === constants.SALES_OBJECT) {
      getRecords(object);
    }
  }, [object]);

  useEffect(() => {
    if (filters.recentRecordMounted) {
      setRecentRecords(filters.recentRecords[object]);
    }
  }, [filters, object]);

  // componentDidMount() {
  //     getRecords(this.props);
  // }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //     if (nextProps.object !== this.props.object || nextProps.object === constants.SALES_OBJECT) {
  //         this.getRecords(nextProps);
  //
  // }

  const getRecords = (object) => {
    let recentRecords = getArrayParam(filters.recentRecords[object]);
    setHasRecord(true);
    setMounted(true);
    setRecentRecords(recentRecords);

    if (isValidParam(recentRecords) || recentRecords.length === 0) {
      let promise = getRecentRecords(object);
      if (isValidParam(promise)) {
        promise.then((response) => {
          if (isValidParam(response)) {
            filters.recentRecords[object] = response.recentRecords;
            if (
              !isValidParam(response.recentRecords) ||
              response.recentRecords.length === 0
            ) {
              setHasRecord(false);
            }
            setMounted(true);
            setRecentRecords(response.recentRecords);
          }
        });
      }
    }
  };

  const handlePosition = (ele) => {
    let innerWidth = document.body.clientWidth;
    try {
      if (isValidParam(ele)) {
        //var rect = ele.getBoundingClientRect();
        var recentItemEle =
          document.getElementsByClassName("recentFilterItems");
        if (recentItemEle.length > 0) {
          recentItemEle = recentItemEle[0];
          var rect = recentItemEle.getBoundingClientRect();
          if (rect.right > innerWidth) {
            let obj = { left: innerWidth - (rect.width + 12) };
            // changeSubMenuPosition(obj);
          }
        }
      }
    } catch (error) {
      console.error("Error in 'TopNavBar.js -> handlePosition()':" + error);
    }
  };

  // This handler is responsible for opening template sub menu recent filter
  const openTemplateSubMenu = (item, moduleItem, info) => {
    let object = makeValidObjectName(item.object);
    let hasPermission = false;
    let hasConfigured = false;
    let me = getObjectParam(app.me);
    if (
      object === constants.MARKETING_TEMPLATE_OBJECT ||
      object === constants.AUTOMATION_DESIGNER_OBJECT ||
      object === constants.BROADCAST_CAMPAIGNS_OBJECT
    ) {
      hasPermission = getBooleanParam(me.is_email_marketing);
    } else {
      hasPermission = true;
    }
    let url = "";
    if (hasPermission) {
      setSelectedModuleInfo(object, moduleItem.id, mitem);
      let tab = {
        item: object,
        label: item.label,
        object: object,
        imgName: getImageNameByObject[object],
        url: url,
      };
      url = "/" + object + "/queries";
      if (tab.item === constants.SOCIAL_MARKETING) {
        url = "/" + object + "/other";
      }
      let type = TYPE_LIST_VIEW;
      if (
        getStringParam(item.type).toLowerCase() === LinkToType.TYPE_REPORT ||
        object === constants.OPERATIONS_DASHBOARD
      ) {
        url = "/" + makeValidAppUrl(object) + "/" + LinkToType.TYPE_REPORT;
        if (object === constants.SALES_OBJECT) {
          type = TYPE_HOME;
          tab.label = item.name + " " + getLocalizedStrings().label.HOME.HOME;
        }
      }
      tab = {
        ...tab,
        type: type,
        url: url,
      };
      addTab(tab, true);
    }
    if (object === "sales-templates") {
      dispatch({
        type: "APP_CONTAINER_CUSTOM_DRAWER_OPEN_CLOSE",
        payload: {
          dialogName: info.recent,
          actionType: "edit",
          callFrom: null,
          data: {
            id: info.id,
            object,
          },
          headerProps: null,
          isOpen: true,
          minimizable: false,
          style: { width: "83%" },
        },
      });
    }else if (object==='marketing-templates') {
      dispatch({
        type: "APP_CONTAINER_CUSTOM_DRAWER_OPEN_CLOSE",
        payload: {
          dialogName: info.recent,
          actionType: "edit",
          callFrom: null,
          data: {
            id: info.id,
            object,
            type:'Bee'
          },
          headerProps: null,
          isOpen: true,
          minimizable: false,
          style: { width: "100%" },
        },
      });
    }
    else if(object == "agreement-templates"){    
      dispatch({
        type: "APP_CONTAINER_CUSTOM_DRAWER_OPEN_CLOSE",
        payload: {
          dialogName: info.recent,
          actionType: "edit",
          callFrom: null,
          data: {
            id: info.id,
            object,
          },
          headerProps: null,
          isOpen: true,
          minimizable: true,
          style: { width: "83%" },
        },
      });
  
    }
    else {
      dispatch({
        type: "APP_CONTAINER_DRAWER_OPEN_CLOSE",
        payload: {
          actionDialogName: info.recent,
          actionType: "edit",
          callFrom: null,
          data: {
            id: info.id,
            object,
          },
          isOpenDrawer: true,
        },
      });
    }
    closeSubMenu();
  };

  const generateRecentRecords = () => {
    let recordItems = null;
    let groupCount = 0;
    let className = "";
    let imgStyle = {
      color: "#ccc",
      fontSize: "14px",
      paddingLeft: "1px",
      paddingRight: "7px",
    };
    let textStyle = { fontSize: "14px", lineHeight: "32px", color: "#717171" };

    if (isValidParam(callFrom) && callFrom === "menu") {
      className = "sbbmenu";
      imgStyle = { lineHeight: "30px", marginRight: "10px" };
      textStyle = {
        fontSize: "14px",
        lineHeight: "30px",
        color: "#E0E0E0 !important",
      };
    }
    let _recentRecords = recentRecords;
    let test = mitem?.sub_items.find(
      (item) => item.label === listItemName?.child
    );

    if (object === constants.SALES_MODULE_TEMPLATE && listItemName.child) {
      recordItems = map(_recentRecords, (item) => {
        return (
          <div
            className={className}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            key={"recent" + item.id}
          >
            <Link
              to={item.url}
              style={{ cursor: "pointer" }}
              onClick={() => openTemplateSubMenu(test, test, item)}
            >
              <span style={imgStyle}>
                <i
                  className={getImageNameByObject(object)}
                  aria-hidden="true"
                ></i>
              </span>
              <span
                title={item.recent}
                style={textStyle}
                className={callFromMenu ? "" : "filist"}
              >
                {item.recent}
              </span>
            </Link>
          </div>
        );
      });
      recordItems = <div ref={(el) => handlePosition(el)}>{recordItems}</div>;
      return recordItems;
    } else if (
      isValidParam(_recentRecords) &&
      object !== constants.SALES_MODULE_TEMPLATE
    ) {
      if (Object.keys(_recentRecords).length > 0) {
        _recentRecords = _recentRecords.filter((obj, index) => {
          return (
            index ===
            _recentRecords.findIndex(
              (o) => obj.id.toString() === o.id.toString()
            )
          );
        });
      }
      recordItems = map(_recentRecords, (recentItem, i) => {
        let linkToUrl =
          "/" +
          object +
          "/" +
          LinkToType.TYPE_DETAIL_VIEW +
          "/" +
          recentItem.id;
        if (object === constants.SALES_MODULE_TEMPLATE) {
          linkToUrl = `/sales-templates/queries`;
        }
        if (
          object === constants.QUOTATION_OBJECT ||
          object === constants.PURCHASE_ORDER_OBJECT ||
          object === constants.SALES_ORDER_OBJECT
        ) {
          linkToUrl = `/${object}/${constants.SPREADSHEET}/${recentItem.recent}`;
        }
        let imagename = "";
        if (
          object === constants.SALES_OBJECT &&
          recentItem.type === LinkToType.TYPE_REPORT
        ) {
          let reportSubType = "";
          if (recentItem.report_type === constants.USER_REPORT_TYPE) {
            imagename = getIconClass(
              recentItem.report_type,
              recentItem.report_subtype
            );
            linkToUrl = getReportUrl(
              object,
              recentItem.report_type + "-" + recentItem.report_subtype,
              recentItem.id
            );
          } else {
            imagename = getIconClass("system", recentItem.report_type);
            linkToUrl = getReportUrl(
              object,
              recentItem.report_type,
              recentItem.id
            );
          }
        } else {
          imagename = getImageNameByObject(object);
        }

        return (
          <div
            className={className}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            key={"recent" + recentItem.id}
          >
            <Link
              to={linkToUrl}
              style={{ cursor: "pointer" }}
              onClick={() => openTab(recentItem, object, linkToUrl)}
            >
              <span style={imgStyle}>
                <i className={imagename} aria-hidden="true"></i>
              </span>
              <span
                title={recentItem.recent}
                style={textStyle}
                className={callFromMenu ? "" : "filist"}
              >
                {recentItem.recent}
              </span>
            </Link>
          </div>
        );
      });
      recordItems = <div ref={(el) => handlePosition(el)}>{recordItems}</div>;
      return recordItems;
    }
  };

  const openTab = (recentItem, object, url) => {
    if (callFrom === "menu") {
      setSelectedModuleInfo(object, moduleItemId);
    }

    let tab = {
      item: object,
      label: recentItem.recent,
      object: object,
      type: TYPE_DETAIL_VIEW,
      url: url,
    };
    if (object === constants.SALES_MODULE_TEMPLATE) {
      addTab(
        {
          ...tab,
          label: listItemName.parent,
          object,
          url: "/sales-templates/queries",
        },
        true
      );
    }

    if (
      recentItem.type === LinkToType.TYPE_REPORT &&
      object === constants.SALES_OBJECT
    ) {
      tab.type = TYPE_REPORT;
      tab.info = {
        selectedReport: {
          item_name: recentItem.name,
          item_type: recentItem.report_type,
          parameter_id: recentItem.id,
          report_subtype: recentItem.report_subtype,
        },
      };
    }
    if (
      object === constants.QUOTATION_OBJECT ||
      object == constants.PURCHASE_ORDER_OBJECT ||
      object === constants.SALES_ORDER_OBJECT
    ) {
      tab.type = TYPE_OTHER_VIEW;
      tab.info = { id: recentItem.id };

      // let state = store.getState();
      let tabs = getTabsByItem(object);
      let tempTab = tabs.find((tab) => {
        return tab.type === TYPE_OTHER_VIEW && tab.object === object;
      });

      if (!isValidParam(tempTab)) {
        addTab(tab);
      } else {
        let url = tempTab.url;
        if (url.includes(getStringParam(recentItem.recent))) {
          addTab(tempTab);
        } else {
          let message = "";
          if (object === constants.QUOTATION_OBJECT) {
            message =
              getLocalizedStrings().message.PAYMENTS
                .RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD;
            addTab(tab);
          } else if (object === constants.PURCHASE_ORDER_OBJECT) {
            message =
              getLocalizedStrings().message.SALES_PURCHASE_ORDER
                .RESTRICT_TO_OPEN_TWO_OR_MORE_PURCHSE_RECORD;
          } else if (object === constants.SALES_ORDER_OBJECT) {
            message =
              getLocalizedStrings().message.SALES_PURCHASE_ORDER
                .RESTRICT_TO_OPEN_TWO_OR_MORE_SALES_RECORD;
          } else if (object === constants.JOBS_OBJECT) {
            message =
              getLocalizedStrings().message.JOBS
                .RESTRICT_TO_OPEN_TWO_OR_MORE_JOBS_RECORD;
          }
          sfDialogs.alert(
            getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
            message,
            addNewFormTabsOnConfirm(tempTab)
          );
        }
      }
    } else {
      addTab(tab, true);
    }

    if (
      object === constants.SALES_OBJECT &&
      recentItem.type === LinkToType.TYPE_REPORT
    ) {
      let params = {};
      params.id = recentItem.id;
      params.type = LinkToType.TYPE_REPORT;
      params.name = recentItem.recent;
      params.report_type = recentItem.report_type;
      params.report_subtype = recentItem.report_subtype;

      saveRecentReportItems(
        recentItem.recent,
        recentItem.report_type,
        recentItem.id,
        object
      );
      addRecentFilterInRedux(params);
      addRecentRecordInRedux({ ...params, recent: recentItem.recent });
    }
    if (isValidParam(closeSubMenu)) {
      closeSubMenu();
    }
  };


  useEffect(() => {
   if(object ==="sales-module-template") {
      const { left, width } = columnRef?.current?.getBoundingClientRect();
    const windowSize = window.innerWidth;
    if (left + width > windowSize) {
        {
          const mainContainer = document.getElementById("filter-sub-menu");
          mainContainer.style.left = `${windowSize - (width * 2.1)}px`;

        }
    }
  }

  }, [containerDetails ,object]);

  return (
    <div>
      {mounted || callFromMenu ? (
        <div style={{ marginLeft: "10px" }} ref={columnRef}>{generateRecentRecords()}</div>
      ) : (
        <div style={{ width: "100%", height: contentHeight }}>
          <div
            className="asset-loaderh"
            style={{ paddingTop: top, paddingLeft: "40%" }}
          >
            <div
              style={{
                ...styles.assetLoaderContainer,
                height: 50,
                width: 50,
                padding: 7,
                backgroundColor: "#fff",
              }}
            >
              <ShowCircularProgress
                size={30}
                style={{ marginTop: "3", marginLeft: "3" }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ColumnRecent;
