import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants } from '../../../services/constants/constants';
import ShowCircularProgress from '../components/circularProgress';
import { pushcompanylist, pushaction } from '../../../services/actions/batchActions';
import { List, ListItem, ListItemText } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import * as sfDialogs from '../components/sfDialogs';
import Checkbox from '@mui/material/Checkbox';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getIntParam, getStringParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { useDispatch, useSelector } from "react-redux";

const DocumentPush = ({ object }) => {
    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);

    const [companyList, setCompanyList] = useState([]);
    const [mounted, setMounted] = useState(false);
    const [all, setAll] = useState(true);
    const [userArray, setUserArray] = useState([]);
    const [withoutConfirm, setWithoutConfirm] = useState(true);
    const [isDuplicate, setIsDuplicate] = useState(false);
    const [snackMsg, setSnackMsg] = useState("");
    const [pushRecords, setPushRecords] = useState([]);
    const [hasPush, setHasPush] = useState(true);

    useEffect(() => {
        getCompany();
    }, []);

    const getCompany = () => {
        let params = {}
        setMounted(false);
        var response = pushcompanylist(object, params);
        response.then((response) => {
            if (response.records !== null) {
                if (response.records.length > 0) {
                    let userArry = [];
                    for (var i = 0; i <= response.records.length; i++) {
                        userArry.push(false);
                    }
                    if (object === constants.ACCOUNTS_OBJECT) {
                        setCompanyList(response.records);
                        setMounted(true);
                        setUserArray(userArry);
                    } else {
                        setCompanyList(response.records);
                        setMounted(true);
                        setUserArray(userArry);
                    }
                } else {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_6 + 'Documents' + ".", null);
                    dispatch(getAppDialog(false, null, null, null, null, null));
                }
            } else {
            }
        });
    }

    const doPush = () => {
        var recordIds = getStringParam(appContainer.dialogProps.data.documentID);
        let folderName = getStringParam(appContainer.dialogProps.data.folderName);
        let folderType = getBooleanParam(appContainer.dialogProps.data.folderType);
        let obj = object;
        var recordsLen = 0;
        var checkboxes = document.getElementsByName('checkedUser');
        let msg = null
        let confirmMsg = "";
        let myclientId = "";
        let isPushValid = false;
        let alertMsg = getLocalizedStrings().message.DOCUMENT_PUSH.ALERT_MSG_1;
        let withoutConfirm = true;

        recordIds = recordIds.split(',');
        recordIds = recordIds.map(m => {
            return getIntParam(m);
        });

        for (var i = 1, n = checkboxes.length; i < n; i++) {
            if (checkboxes[i].checked) {
                if (myclientId == "") {
                    myclientId = checkboxes[i].value;
                } else {
                    myclientId = myclientId + "," + checkboxes[i].value;
                }
                isPushValid = true;

            }
        }

        recordsLen = recordIds.length;
        if (isPushValid) {
            if (folderType) {
                if (myclientId.split(',').length === 1) {
                    confirmMsg = getLocalizedStrings().message.PUSH.PUSH_FOLDER + " '" + folderName + "' " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_2_1 + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_11 + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_11_2;
                } else {
                    confirmMsg = getLocalizedStrings().message.PUSH.PUSH_FOLDER + " '" + folderName + "' " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_2_1 + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_2 + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_11_2;
                }
            } else {
                if (myclientId.split(',').length === 1) {
                    confirmMsg = recordsLen + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_1 + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_11 + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_11_2;
                } else {
                    confirmMsg = recordsLen + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_1 + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_2 + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_11_2;
                }
            }
            msg = getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_7;

            let param = {};
            param.recordIds = recordIds;
            param.type = "Document_Library";
            param.selectionType = 'option2';
            param.clientIdList = myclientId.toString();
            param.withoutConfirm = withoutConfirm;
            if (param.clientIdList.length > 0) {
                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, confirmMsg, () => doPushOnConfirm(param, msg, myclientId), null);
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_5 + ' ' + obj, null);
            }
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMsg, null);
        }
    }

    const doPushOnConfirm = (param, msg, myclientId) => {
        let records = [];
        var response = pushaction('', param);
        setMounted(false);
        response.then((response) => {
            if (response.data.isDuplicate) {
                records = response.data.pushRecords;
                if (withoutConfirm) {
                    msg = getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_7;
                } else {
                    if (response.status === 0) {
                        if (myclientId.split(',').length === 1) {
                            msg = msg + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_12;
                        } else {
                            msg = msg + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_3;
                        }
                    } else if (response.status === -1) {
                        msg = getLocalizedStrings().message.DOCUMENT_PUSH.FAIL;
                    }
                }
                setMounted(true);
                setSnackMsg(msg);
                setPushRecords(records);
                setIsDuplicate(response.data.isDuplicate);
                setHasPush(response.data.hasPush);
             } else {
                if (withoutConfirm) {
                    msg = getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_7;
                    dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    dispatch(getAppDialog(false, null, null, null, null, null));
                }
                else {
                    setMounted(true);
                    if (response.status === 0) {
                        if (myclientId.split(',').length === 1) {
                            msg = msg + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_12;
                            dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleLongMsg));
                        }
                        else {
                            msg = msg + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_3;
                            dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        }
                        dispatch(getAppDialog(false, null, null, null, null, null));
                    } else if (response.status === -1) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_PUSH.FAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        appContainer.dialogProps.eventName('documents');
                    }
                }
            }
        });
    }

    const generatDuplicateList = () => {
        let themeColor = app.me.background;
        let unitStyle = { width: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block' };
        if (isDuplicate) {
            return pushRecords.map((clientByRecord, index) => {
                let duplicateRecords = clientByRecord.duplicateRecord;
                if (duplicateRecords.length > 0) {
                    let unitNmae = clientByRecord.company;
                    var docName = null;
                    duplicateRecords.map((record, i) => {
                        if (i === 0) {
                            docName = record.documentName;
                        } else {
                            docName = docName + ', ' + record.documentName;
                        }
                    })
                    return (<div style={{ color: themeColor, display: 'flex' }}><span style={unitStyle} title={unitNmae}>{unitNmae}</span><span style={{ fontSize: '14px' }}>{': ' + docName}</span></div>);
                }

            })
        }
    }

    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const handleOk = () => {
        if (hasPush) {
            dispatch(showCustomSnackBar(snackMsg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
        }
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const clickAll = (_userArray,val) => {
        if (val === 0) {
            var isAllChecked = _userArray[0];
            for (var i = 0; i < companyList.length; i++) {
                let ele = document.getElementById('checkedUser-' + i);
                let disabled = ele.disabled;
                if (!disabled) {
                    _userArray[i] = !isAllChecked;
                }
                _userArray[i] = !isAllChecked;
            }
        } else {
            if (_userArray[val] === true) {
                _userArray[0] = !_userArray[val];
                _userArray[val] = !_userArray[val];
            } else {
                _userArray[val] = !_userArray[val];
                var flag = true;
                var allFlag = _userArray[0];
                for (var i = 1; i < companyList.length; i++) {
                    flag = (flag && _userArray[i] ? true : false);
                    if (!flag) {
                        allFlag = false;
                    }
                }
                _userArray[0] = allFlag;
            }

        }
        setUserArray([..._userArray]);
        setMounted(true);
    }

   

    let contentHeight = window.innerHeight - 300;
    let top = (contentHeight - 10) / 2;
    var usrobj = companyList[0];
    if (usrobj !== undefined && usrobj["myclientId"] !== 0) {
        let compObj = {};
        compObj.company = "All";
        compObj.myclientId = 0;
        companyList.reverse();
        companyList.push(compObj);
        companyList.reverse();

    }
    let duplicateList = generatDuplicateList();

    if (mounted) {
        return (
            <div>
                {isDuplicate &&
                    <div style={{ width: '100%', maxHeight: 250, overflow: 'auto' }} >
                        <div style={{ paddingBottom: '10px' }} >
                            {'Following documents could not be pushed to the Units as those are already pushed earlier. Please find the details below:'}
                        </div>
                        <div>
                            {duplicateList}
                        </div>
                    </div>
                }
                {!isDuplicate &&
                    <div>
                        <div>
                            <div style={{ height:contentHeight-50, width: '100%', overflow: 'auto' }} >
                                <List>
                                    {companyList.map((comp, i) => {
                                        return (
                                            <div onClick={() => clickAll(userArray,i)} style={{padding:0}}>
                                            <ListItem key={'checkedUser-' + i + 'list'} style={{padding:0}} >
                                                <ListItemButton role={undefined}  dense>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            id={'checkedUser-' + i}
                                                            checked={userArray[i]}
                                                            value={comp.myclientId}
                                                            tabIndex={-1}
                                                            name={'checkedUser'}
                                                            color="default"
                                                            disabled={false}
                                                            inputprops={{ 'aria-labelledby': 'checkedUser-' + i + comp.myclientId }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={`labelId${i}`} primary={comp.company} />
                                                </ListItemButton>
                                            </ListItem>
                                            </div>)
                                    })}
                                </List>
                            </div>
                        </div>

                    </div>
                }
                <div style={{height:"50px",float:"right",padding:"20px 0px"}}>
                    {isDuplicate ? (
                    <Button
                        key={'ok'}
                        primary={'true'}
                        onClick={() => handleOk()}
                        style={styles.primaryButton}
                        >{getLocalizedStrings().label.COMMON.OK}</Button>) : <>
                        <Button
                            key={"do-push"}
                            primary={'true'}
                            onClick={() => doPush()}
                            style={styles.primaryButton}
                        > {getLocalizedStrings().label.COMMON.PUSH}</Button>
                        <Button
                            key={'close'}
                            primary={'true'}
                            onClick={() => handleClose()}
                            style={styles.secondaryButton}
                        >{getLocalizedStrings().label.COMMON.CANCEL}</Button> 
                        </>
                    }
                </div>
            </div>
        );
    } else {
        return (
            <div style={{ minHeight: '200px' }}>
                <div className="col-md-12" style={{ marginTop: '50px', paddingBottom: '80px' }}>
                    <div style={{ width: '100%', height: contentHeight }} >
                        {
                            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }} >
                                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
};

export default DocumentPush;