import React, {useEffect, useState, useRef, createRef} from 'react';
import { Step, Stepper, StepLabel } from '@mui/material';
import { getArrayParam } from '../../../services/helper/parameterVerifier';
import Button from '@mui/material/Button';
import { isValidParam, getStringParam, getIntParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { OBJECT_TABLEID_MAP, USER_REPORT_TYPE } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { styles } from '../../../services/constants/styles';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { saveRecentReportItems, updateRecentInRedux } from '../../../services/helper/common';
import ShowCircularProgress from '../components/circularProgress';
import UserReportStepOne from '../components/userReportStepOne';
import UserReportTabular from '../components/userReportTabular';
import UserReportPivot from '../components/userReportPivot';
import moment from 'moment';
import { addReportInRedux, addRecentFilterInRedux, addRecentRecordInRedux, getReportUrl } from '../../../services/actions/SalesReportAction';
import { addTab, TYPE_REPORT } from '../../../services/helper/sfTabManager';
import { LinkToType } from '../../../services/constants/link';
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';

const StyledStep = styled('div')(({ theme, status }) => ({
	zIndex: 1,
	color: '#fff',
	backgroundColor:'#717171',
	width: 22,
	height: 22,
	display: 'flex',
	borderRadius: '50%',
    fontSize:12,
	justifyContent: 'center',
	alignItems: 'center',
	...(status.active && {
	  color:'white',
	  backgroundColor: localStorage.getItem('background'),
	}),
  }));

function ColoredStep(props) {
	const { active, completed, className } = props;
	return (
	  <StyledStep status={{ completed, active }} className={className}>
		{props.icon}
	  </StyledStep>
	);
  }

const UserReport =({object,data,id})=> {

        const step1 = useRef(null);
        const step2 = useRef(null);
        const step2_pivot  = useRef(null);

        const dispatch = useDispatch();
        const [stepIndex, setStepIndex] = useState(0);
        const [finished, setFinished] = useState(false);
        const [step1Report, setStep1Report] = useState({
            id: 0,
            reportName: '',
            filterId: null,
            filterName: '',
            filterType: null,
            dateRange: '',
            dateRangeLabel: '',
            fromDate: null,
            toDate: null,
            reportType: null,
            reportTypeLabel: '',
            rangeField: '',
            rangeFieldLabel: ''
        });
    const [step2Report, setStep2Report] = useState({
        viewFields: null,
        selected_fields: null,
            available_fields: null,
            sortField: null,
            sortType: null,
            aggregateField: null,
            aggregateType: null,
       
    });
    const [pivotReport, setPivotReport] = useState({
          columns: [],
            rows: [],
            filters: [],
            values: [],
            chart_type: null,
            showGrandTotal: true,
            showSubTotal: true,
            chart_type_Obj: {},
       
    })

        const [isShowLoader, setIsShowLoader] = useState(true);
        const [isSaved, setIsSaved] = useState(false);
        const [reportName,setReportName] = useState(null);
        const app = useSelector((state) => state.app);
        const reportNames = useSelector((state) => state.salesReport.data.report_names);
        const [errorMessage, setErrorMessage] = useState(undefined)


    useEffect(() => {
        if (id > 0) {
            loadReportData(id);
        } else {
            setIsShowLoader(false);
        }
        }, []);

        const loadReportData = (id) => {
            let _Step1Report = {...step1Report};
            let _Step2Report = {...step2Report};
            let _PivotReport = {...pivotReport};
                if (id > 0) {
                    let params = { id: id, type: data.type };
                    let promise = Promise.resolve(HTTPClient.get(endPoints.USERREPORT.GET, params));            
                    promise.then((response) => {
                        _Step1Report.id = response.id;
                        _Step1Report.reportName = response.t_name;
                        _Step1Report.filterId = response.t_queryid;
                        _Step1Report.filterType = response.t_querytype;
                        _Step1Report.reportType = response.t_type;
                        _Step1Report.dateRange = response.t_date_range;
                        _Step1Report.fromDate = response.from_date;
                        _Step1Report.toDate = response.to_date;
                        _Step1Report.rangeField = response.t_range_field;
                        setStep1Report({..._Step1Report});

                         if (response.t_type === USER_REPORT_TYPE.TABULAR) {
                            _Step2Report.viewFields= response.viewFields;
                            _Step2Report.selected_fields= response.selected_fields;
                            _Step2Report.available_fields= response.available_fields;
                            _Step2Report.sortField= response.t_sort_field;
                            _Step2Report.sortType= response.t_sort_type;
                            _Step2Report.aggregateField= response.t_aggregate_field;
                            _Step2Report.aggregateType= response.t_aggregate_type;
                            setStep2Report({..._Step2Report});
        
                        } else if (response.t_type === USER_REPORT_TYPE.PIVOT || response.t_type === USER_REPORT_TYPE.SUMMARY) {
                            _PivotReport.rows= JSON.parse(response.t_rows);
                            _PivotReport.columns= JSON.parse(response.t_columns);
                            _PivotReport.values= JSON.parse(response.t_values);
                            _PivotReport.showGrandTotal= response.showGrandTotal;
                            _PivotReport.showSubTotal= response.showSubTotal;
                            _PivotReport.chart_type= response.t_charttype;
                            setPivotReport({..._PivotReport})
                        }
                        setIsShowLoader(false);
                        
                       });
                } else {
                    setIsShowLoader(false);
                }
            }

    const handleNext = () => {
        let tempStepIndex = stepIndex;
        if (tempStepIndex === 0) {
            setStepIndex(tempStepIndex+1);
            setFinished(stepIndex >= 2);
        }
    }

    const handlePrev = () => {
       let reportType = step1Report.reportType;
        if (reportType === USER_REPORT_TYPE.TABULAR) {
            let step2Data = step2Report;
            if (isValidParam(step2Data.selected_fields) && step2Data.selected_fields.length === 0) {
                dispatch(showCustomSnackBar('Please select fields for report.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }
        }
        if (reportType === USER_REPORT_TYPE.PIVOT) {
            let tempStep2_pivot = step2_pivot.current;
            let pivotData = tempStep2_pivot;
            let _report = pivotReport;
            _report.pivotData = pivotData;
           setPivotReport({..._report});
          }
        let _stepIndex = stepIndex;
        if (_stepIndex > 0) {
            setStepIndex(_stepIndex-1);
        }
    }

    const setStep1Data = (data) => {
        setStep1Report({...data});
    }

    const setStep2Data = (data) => {
        setStep2Report({...data});
    }

    const getStepContent=(stepIndex)=> {
         switch (stepIndex) {
            case 0:
                return (
                    <div key={'index-report-step-one'}>
                        <UserReportStepOne
                            ref={step1}
                            setParentState={(data)=>setStep1Data(data)}
                            object={data.reportObj}
                            data={step1Report}
                            setErrorMessage={setErrorMessage}
                         />
                    </div>
                );
            case 1:
                let _reportType = step1Report.reportType;
                if (_reportType === USER_REPORT_TYPE.TABULAR) {
                    return (
                        <div key={_reportType}>
                            <UserReportTabular
                                ref={step2}
                                object={data.reportObj}
                                setParentState={(data)=>setStep2Data(data)}
                                data={step2Report}
                                id={id}
                            />
                        </div>
                    );
                } else if (_reportType == USER_REPORT_TYPE.PIVOT || _reportType == USER_REPORT_TYPE.SUMMARY) {
                    return (
                        <div>
                            <UserReportPivot
                                 ref={step2_pivot}
                                 object={data.reportObj}
                                 fieldInfo={step2Report}
                                 setParentState={(value)=>setStep2Data(value)}
                                 reportId={id}
                                 data={pivotReport}
                                 reportType={_reportType}
                            />
                        </div>
                    );

                }
                break;
            default:
                return 'Soffront User Report Module';
        }
    }

    const step2TabularValidation = () => {
        let step2Data =step2Report;

        if (isValidParam(step2Data.selected_fields) && step2Data.selected_fields.length === 0) {
            dispatch(showCustomSnackBar('Please select fields for report.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }
        if (isValidParam(step2Data.aggregateType) && step2Data.aggregateType !== '' && isValidParam(step2Data.aggregateField)) {
            dispatch(showCustomSnackBar('Please select aggregate field.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }
        return true;
    }

    const step2PivotValidation = () => {
        let tempstep2_pivot = step2_pivot.current.fields;
        
        if (isValidParam(tempstep2_pivot.rows) && tempstep2_pivot.rows.length === 0) {
            dispatch(showCustomSnackBar('Please add fields to Rows', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }
        if (isValidParam(tempstep2_pivot.columns) && tempstep2_pivot.columns.length === 0) {
            dispatch(showCustomSnackBar('Please add fields to Columns', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }
        if (isValidParam(tempstep2_pivot.values) && tempstep2_pivot.values.length === 0) {
            dispatch(showCustomSnackBar('Please add fields to Values', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }

        return true;
    }

    const rearrangeSequence = () => {
        let parentElem = document.getElementById("selChildObject");
        if (parentElem !== null) {
            let arrayFieldList = getArrayParam(step2Report.selected_fields);
            let elements = parentElem.getElementsByClassName('css-12jo7m5');
            let arrayFields = [];
            if (elements !== null) {
                for (let i = 0; i < elements.length; i++) {
                    var elem = arrayFieldList.filter(function (el) {
                        return el.label.trim().replace(/  +/g, ' ').toString() === elements[i].innerText.trim().replace(/  +/g, ' ').toString();
                    });
                    if (elem.length > 0) {
                        arrayFields.push(elem[0]);
                    }
                }
            }
            if (arrayFields.length > 0) {
                let _step2Report = {...step2Report};
                _step2Report.selected_fields = arrayFields;
                setStep2Report({..._step2Report});

            }
        }
        
    }

    const saveReport = () => {
        let _reportType = step1Report.reportType;
        if (_reportType == USER_REPORT_TYPE.TABULAR) {
            if (!step2TabularValidation()) {
                return false;
            }
            saveTabularReport();
        } else if (_reportType == USER_REPORT_TYPE.PIVOT || _reportType == USER_REPORT_TYPE.SUMMARY) {
            if (!step2PivotValidation()) {
                return false;
            }
            savePivotReport();
        }
    }
    
    const savePivotReport = () => {
        let _step2_pivot = step2_pivot.current.fields;
        let pivotData = _step2_pivot;
        let step1Data = step1Report;
        let fromDate = "";
        let toDate = "";
        if (step1Data.dateRange == 'cust') {
            if (Array.isArray(step1Data.fromDate)) {
                fromDate = moment(step1Data.fromDate[0]).format('YYYY-MM-DD');
            } else {
                fromDate = moment(step1Data.fromDate).format('YYYY-MM-DD');
            }
            if (Array.isArray(step1Data.toDate)) {
                toDate = moment(step1Data.toDate[0]).format('YYYY-MM-DD');
            } else {
                toDate = moment(step1Data.toDate).format('YYYY-MM-DD');
            }
        }

        let tempData = {
            "id": step1Report.id,
            "t_name": step1Data.reportName,
            "t_formid": OBJECT_TABLEID_MAP[data.reportObj],
            "t_queryid": step1Data.filterId,
            "t_querytype": step1Data.filterType,
            "t_date_range": step1Data.dateRange,
            "from_date": fromDate,
            "to_date": toDate,
            "t_type": step1Data.reportType,
            "t_range_field": getStringParam(step1Data.rangeField),
            "rows": pivotData.rows,
            "columns": pivotData.columns,
            "filters": pivotData.filters,
            "values": pivotData.values,
            "showGrandTotal": pivotData.showGrandTotal || true,
            "showSubTotal": pivotData.showSubTotal || true,
            "chart_type": pivotData.chart_type ? pivotData.chart_type.value : null

        }
        let promise = Promise.resolve(HTTPClient.post(endPoints.USERREPORT.SAVE, tempData));

        promise.then((response) => {
            if (response.status == 0) {
                dispatch(showCustomSnackBar('Report Saved Sucessfully.', styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                let savedReportData = {
                    item_name: step1Data.reportName,
                    item_type: "user",
                    parameter_id: response.data.id,
                    report_object: getStringParam(data.reportObj),
                    report_subtype: step1Data.reportType,
                    owner_id: app.me.id,
                }
                let _step1Report = step1Report;
                updateRecentInRedux(object, response.data.id, step1Data.reportName);
                addReportInRedux(savedReportData);
                _step1Report.id = response.data.id;
                setStep1Report({ ..._step1Report });
                setIsSaved(true);

            }
            else if (response.status == -1 && response.error.message != "") {
                dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        });
    }


    const saveTabularReport = () => {
        let step1Data = step1Report;
        let step2Data = step2Report;

        let viewfields = [];
        rearrangeSequence();
        step2Data.selected_fields.map(function (element) {
            viewfields.push(element.id);
        });
        let fromDate = "";
        let toDate = "";
        if (step1Data.dateRange === 'cust') {
            if (Array.isArray(step1Data.fromDate)) {
                fromDate = moment(step1Data.fromDate[0]).format('YYYY-MM-DD');
            } else {
                fromDate = moment(step1Data.fromDate).format('YYYY-MM-DD');
            }
            if (Array.isArray(step1Data.toDate)) {
                toDate = moment(step1Data.toDate[0]).format('YYYY-MM-DD');
            } else {
                toDate = moment(step1Data.toDate).format('YYYY-MM-DD');
            }
        }

        let tempData = {
            "id": step1Report.id,
            "t_name": step1Data.reportName,
            "t_formid": OBJECT_TABLEID_MAP[data.reportObj],
            "t_queryid": step1Data.filterId,
            "t_querytype": step1Data.filterType,
            "t_date_range": step1Data.dateRange,
            "from_date": fromDate,
            "to_date": toDate,
            "t_aggregate_field": step2Data.aggregateField,
            "t_aggregate_type": step2Data.aggregateType,
            "t_sort_type": step2Data.sortType === 'asc' ? 1 : 0,
            "t_sort_field": step2Data.sortField,
            "t_type": step1Data.reportType,
            "t_view_fields": viewfields,
            "t_range_field": getStringParam(step1Data.rangeField)
        }

        let promise = Promise.resolve(HTTPClient.post(endPoints.USERREPORT.SAVE, tempData));

        promise.then((response) => {
            if (response.status === 0) {
                dispatch(showCustomSnackBar('Report Saved Sucessfully.', styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                let savedReportData = {
                    item_name: step1Data.reportName,
                    item_type: "user",
                    parameter_id: response.data.id,
                    report_object: getStringParam(data.reportObj),
                    report_subtype: step1Data.reportType,
                    owner_id: app.me.id,
                }
                updateRecentInRedux(object, response.data.id, step1Data.reportName);

                addReportInRedux(savedReportData);
                let _step1Report = step1Report;
                _step1Report.id = response.data.id;
                setStep1Report({..._step1Report});
                setIsSaved(true);
            }
            else if (response.status === -1 && response.error.message !== "") {
                dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        });

    }

    const validate = () => {
        let step1Data = step1Report;
        if (isValidParam(step1Data.reportName) && step1Data.reportName.trim().length === 0) {
            dispatch(showCustomSnackBar('Please provide a Report Name.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }

        if (isValidParam(step1Data.reportName) && isDuplicateReportName(step1Data.reportName)) {
            dispatch(showCustomSnackBar('Report Name already exists.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }

       if (!isValidParam(step1Data.reportType) && step1Data.reportType === null) {
            dispatch(showCustomSnackBar('Please select Report Type', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }

        if (!isValidParam(step1Data.filterId) && step1Data.filterId === null) {
            dispatch(showCustomSnackBar('Please select a Filter', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }

        if (step1Data.dateRange === 'cust') {
            if (!isValidParam(step1Data.fromDate) || step1Data.fromDate.length === 0) {
                dispatch(showCustomSnackBar('Please select Start Date', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }
            if (!isValidParam(step1Data.toDate) || step1Data.toDate.length === 0) {
                dispatch(showCustomSnackBar('Please select End Date', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }
        }
        if(errorMessage) {
            dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }
        return true;

    }

    const isDuplicateReportName = (reportName) => {

        let isExists = false;
        if (getStringParam(reportName).length > 0) {
            let _reportNames = getArrayParam(reportNames);
                let tempReportNames = getArrayParam(_reportNames);
                let tempreport = tempReportNames.filter((r) => {
                    let temp ;
                    if (r.item_name === reportName && r.parameter_id !== step1Report.id) {
                        temp= r;
                    }
                    return temp;
                });
                if (tempreport.length > 0) {
                    isExists = true;
                }
        }
        return isExists;
    }

    const runReport = () => {
        try {
            let parameterId = getIntParam(step1Report.id);
            let subType = getIntParam(step1Report.reportType);
             let type = 'user';
            let label = '';
            if (getBooleanParam(isSaved)) {
                label = step1Report.reportName;
            } else {
                let _reportNames = reportNames;
                let oldReportObj = _reportNames.filter((obj) => { return obj.parameter_id == parameterId });
                label = oldReportObj[0].item_name;
            }

            let selectedReportObject = {};
            selectedReportObject.item_name = label;
            selectedReportObject.item_type = type;
            selectedReportObject.parameter_id = parameterId;
            selectedReportObject.report_subtype = subType;

            let url = getReportUrl(object, type + '-' + subType, parameterId);
            saveRecentReportItems(label, type, parameterId, object);
            let params = { id: parameterId, type: LinkToType.TYPE_REPORT, name: label, report_type: type, report_subtype: step1Report.reportType };
            addRecentFilterInRedux(params);
            addRecentRecordInRedux({ ...params, recent: label });
            openTab(label, object, url, type, selectedReportObject);
        } catch (error) {
            console.error("Error in 'userReport.js ---> runReport()':" + error);
        }
    }

    const openTab = (label, object, url, reportType, selectedReport) => {
        let info = { report_type: reportType, reportHeaderNames: reportName, selectedReport: selectedReport };
        try {
            let tab = {
                item: object,
                label: label,
                object: object,
                type: TYPE_REPORT,
                url: url,
                info: info,
            };

            addTab(tab);
        } catch (error) {
            console.error("Error in 'userReport.js ---> openTab()':" + error);
        }
    }


        let pageHeight = window.innerHeight - 247;
        let top = (pageHeight-10) / 2;
        if (isShowLoader) {
            return (<div style={{ width: '100%', height: pageHeight }}>
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
       </div>);
        }

        let reportId = isValidParam(step1Report) ? getIntParam(step1Report.id) : 0;
        return (
            <div>
                <div>
                    <   >
                        <Stepper nonLinear activeStep={stepIndex} style={{ backgroundColor: '#f5f5f5', marginTop:'20px' }}>
                            <Step style={{marginLeft:'30px'}}>
                                <StepLabel StepIconComponent={ColoredStep} style={{ color: stepIndex === 1 ? '#717171' : '#FC4513', fontWeight: 'bold' }}>Step 1</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel StepIconComponent={ColoredStep} style={{ fontWeight: 'bold',marginRight:'41px' }}>Step 2</StepLabel>
                            </Step>
                        </Stepper>
                    </ >
                </div>
                <div style={{ marginTop: 12, paddingLeft: 8, paddingRight: '26px' }}>{getStepContent(stepIndex)}</div>
                <div>
                    {!finished &&
                        <div style={{ marginTop: 20, paddingLeft: 19, marginTop: "3%",marginBottom:'60px' }}>
                            {stepIndex === 1 &&
                                <div>
                                    <Button

                                        primary={true}
                                        onClick={()=>saveReport()}
                                        style={styles.primaryButton}
                                    >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                                    {reportId > 0 &&
                                        <Button

                                            disabled={stepIndex === 0}
                                            onClick={()=>runReport()}
                                            style={{ ...styles.secondaryButton }}
                                        >{getLocalizedStrings().label.COMMON.RUN}</Button>
                                    }
                                    <Button

                                        disabled={stepIndex === 0}
                                        onClick={()=>handlePrev()}
                                        style={{ ...styles.secondaryButton, marginLeft: 5 }}
                                    >{getLocalizedStrings().label.FILTER.PREVIOUS_BUTTON_TEXT}</Button>
                                </div>
                            }
                            {stepIndex === 0 &&
                                <div>
                                    <Button
                                        id="next"
                                        primary={true}
                                        onClick={() => {
                                            if (!validate()) return;
                                            handleNext();
                                        }}
                                        style={styles.primaryButton}
                                    >{getLocalizedStrings().label.FILTER.NEXT_BUTTON_TEXT}</Button>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>

        );
}

export default UserReport;
