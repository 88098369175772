import React, {useEffect, useState} from 'react';
import {getLocalizedStrings} from '../../../services/constants/MultiLingual';
import {styles} from '../../../services/constants/styles';
import * as sfDialogs from '../components/sfDialogs';
import {getArrayParam, getBooleanParam, getIntParam, getStringParam, isValidParam} from '../../../services/helper/parameterVerifier';
import {getAllocatePhoneNumbers, getMergeField, getRemainingSMSCount} from '../../../services/actions/smsAction';
import ShowCircularProgress from '../components/circularProgress';
import {Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, Menu, MenuItem, Select, TextField} from '@mui/material';
import {constants} from '../../../services/constants/constants';
import {getAppDialog} from '../../../services/actions/appContainerActions';
import * as HTTPClient from '../../../services/helper/httpClient';
import {endPoints} from '../../../services/constants/endPoints';
import {showCustomSnackBar} from '../../../services/actions/snackBarAction';
import {removeError} from '../../../services/helper/automationUtils';
import {addNode} from '../../../services/actions/automationCampaignActions';
import Autocomplete from '@mui/material/Autocomplete';
import {useDispatch, useSelector} from 'react-redux';

const duration = [
    {key: '0', label: '', value: 0},
    {key: '1', label: '1', value: 1},
    {key: '2', label: '2', value: 2},
    {key: '3', label: '3', value: 3},
    {key: '4', label: '4', value: 4},
    {key: '5', label: '5', value: 5},
    {key: '6', label: '6', value: 6},
    {key: '7', label: '7', value: 7},
    {key: '8', label: '8', value: 8},
    {key: '9', label: '9', value: 9},
    {key: '10', label: '10', value: 10},
    {key: '11', label: '11', value: 11},
    {key: '12', label: '12', value: 12}
];

const toTimeAmPMArr = [
    {key: 'AM', label: 'AM', value: 'AM'},
    {key: 'PM', label: 'PM', value: 'PM'}
];

const AutomationSendSMS = ({data, closeDrawer}) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const automationCampaign = useSelector((state) => state.automationCampaign);

    const [ismobileNumberLoaded, setIsmobileNumberLoaded] = useState(false);
    const [fromNumber, setFromNumber] = useState('');
    const [smsText, setSmsText] = useState('');
    const [phoneNumberList, setPhoneNumberList] = useState([]);
    const [smslength, setSmslength] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(false);
    const [ismergeFieldLoaded, setIsmergeFieldLoaded] = useState(false);
    const [mergeFields, setMergeFields] = useState(null);
    const [attachFiles, setAttachFiles] = useState([]);
    const [noFromNumber, setNoFromNumber] = useState(false);
    const [fromNumberIndex, setFromNumberIndex] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(0);
    const [templatesList, setTemplatesList] = useState([]);
    const [smsShrink, setSmsShrink] = useState(false);
    const [fromTimeStr, setFromTimeStr] = useState('08:00:00');
    const [fromTime, setFromTime] = useState('8');
    const [fromTimeAmPM, setFromTimeAmPM] = useState('AM');
    const [toTimeStr, setToTimeStr] = useState('17:00:00');
    const [toTime, setToTime] = useState('5');
    const [toTimeAmPM, setToTimeAmPM] = useState('PM');
    const [isWeekdaysOnly, setIsWeekdaysOnly] = useState(false);


    useEffect(() => {
        getFromPhoneNumbers();
        getMergeFieldObject();
        loadTemplateList();
    }, []);

    const getFromPhoneNumbers = () => {
        try {
            let tempPhoneNumbersArr = null;
            let phoneNumbers = [];
            let params = {};
            params.call_from = 'campaignSMS';
            let promise = Promise.resolve(getAllocatePhoneNumbers(params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        let twilioNumbersArr = getArrayParam(response['twilio_numbers']);
                        if (twilioNumbersArr !== null && twilioNumbersArr.length > 0) {
                            tempPhoneNumbersArr = twilioNumbersArr.filter(f => {
                                let availableToArr = f.available_to.split(',');
                                if (availableToArr.indexOf(String(-9999)) > -1) {
                                    return true;
                                } else if (availableToArr.indexOf(String(app.me.id)) > -1) {
                                    return true;
                                }
                                return false;
                            });
                            if (tempPhoneNumbersArr.length > 0) {
                                setFromNumberIndex(tempPhoneNumbersArr[0].id);
                                setFromNumber(tempPhoneNumbersArr[0].phone_number);
                            }
                            phoneNumbers = tempPhoneNumbersArr.map((listValue) => {
                                let obj = {};
                                obj.value = listValue.id;
                                obj.label = listValue.phone_number;
                                obj.title = listValue.phone_number;
                                return obj;
                            });
                            setPhoneNumberList(phoneNumbers);
                            setIsmobileNumberLoaded(true);
                            setSMSInfo(phoneNumbers);
                        } else {
                            setNoFromNumber(true);
                        }
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'AutomationSendSMS.js -> getFromPhoneNumbers()':" + error);
        }
    }

    const getMergeFieldObject = () => {
        try {
            let promise = Promise.resolve(getMergeField(constants.CONTACTS_OBJECT, {}));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        setMergeFields(response);
                        setIsmergeFieldLoaded(true);
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'AutomationSendSMS.js -> getMergeFieldObject()':" + error);
        }

    }

    const setSMSInfo = (phoneNumberList) => {
        let fromNumberId = 0;
        let tempPhoneNumbers = [];
        try {
            let nodeObject = {...data};
            if (nodeObject.hasOwnProperty('details') && nodeObject.details && nodeObject.details.length > 0) {
                let data = nodeObject.details[0];
                tempPhoneNumbers = phoneNumberList.filter(f => f.title === data.from_number);
                if (tempPhoneNumbers.length > 0) {
                    fromNumberId = tempPhoneNumbers[0].value;
                }
                let fromTime = 0;
                let fromTimeAmPM = 'AM';
                let toTime = 0;
                let toTimeAmPM = 'AM';
                if (data.t_start_time !== "" && data.t_end_time !== "") {
                    fromTime = parseInt(data.t_start_time.slice(0, data.t_start_time.indexOf(':')));
                    fromTimeAmPM = fromTime >= 12 ? 'PM' : 'AM';
                    toTime = parseInt(data.t_end_time.slice(0, data.t_end_time.indexOf(':')));
                    toTimeAmPM = toTime >= 12 ? 'PM' : 'AM';
                    if (fromTime === 0) {
                        fromTime = 12;
                    } else if (fromTime > 12) {
                        fromTime = fromTime % 12;
                    }
                    if (toTime === 0) {
                        toTime = 12;
                    } else if (toTime > 12) {
                        toTime = toTime % 12;
                    }
                }
                setSmsText(getStringParam(data.message));
                setFromNumber(tempPhoneNumbers[0].label);
                setSmsShrink(true);
                setFromNumberIndex(fromNumberId);
                setAttachFiles(getArrayParam(data.attachment_info));
                setSelectedTemplate(getIntParam(data.sms_template_id));
                setSmslength(getStringParam(data.message).length);
                setFromTime(fromTime);
                setFromTimeStr(data.t_start_time);
                setFromTimeAmPM(fromTimeAmPM);
                setToTimeStr(data.t_end_time);
                setToTime(toTime);
                setToTimeAmPM(toTimeAmPM);
                setIsWeekdaysOnly(data.t_is_weekdays !== 0);
            }
        } catch (error) {
            console.error("Error in 'AutomationSendSMS.js -> setSMSInfo()':" + error);
        }
    }

    const setSMSBody = (event, value, callFrom) => {
        let tempValue = value || event.target.value;
        try {
            if (callFrom === constants.MERGE_FIELD) {
                const eventSelection = document.getElementById('send-batchsms-text-box');
                let start = eventSelection.selectionStart;
                let end = eventSelection.selectionEnd;
                tempValue = getStringParam(smsText).substring(0, start) + ' ${' + getStringParam(tempValue) + '} ' + getStringParam(smsText).substring(end)
            } else {
                tempValue = getStringParam(tempValue);
            }
            let smsSize = tempValue.length;
            setSmsText(tempValue);
            setSmslength(smsSize);
            setSmsShrink(true);
        } catch (error) {
            console.error("Error in 'AutomationSendSMS.js -> setSMSBody()':" + error);
        }
    }

    const validateSMSForm = () => {
        try {
            if (!fromNumber || fromNumber === '') {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SMS_INTEGRATION.SELECT_NUMBER, null);
                return false;
            } else if (!smsText || smsText === '') {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SMS_INTEGRATION.ENTER_SMS_TEXT, null);
                return false;
            } else if ((!fromTimeStr || fromTimeStr === '') && toTimeStr && toTimeStr !== "") {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SCHEDULER.NO_START_TIME, null);
                return false;
            } else if (fromTimeStr && fromTimeStr !== '' && (!toTimeStr || toTimeStr === "")) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SCHEDULER.NO_END_TIME, null);
                return false;
            } else {
                return true;
            }
        } catch (error) {
            console.error("Error in 'AutomationSendSMS.js -> validateSMSForm()':" + error);
        }
    }

    const checkCallTextProvider = () => {
        let phone_text_provider = app.me.phone_text_provider ?? constants.NONE;
        if (phone_text_provider == constants.SOFFRONT) {
            saveSMSAfterRemainingCountCheck();
        } else if (phone_text_provider == constants.TWILIO) {
            saveSMS();
        }
    }

    const saveSMSAfterRemainingCountCheck = () => {
        try {
            if (validateSMSForm()) {
                let smsCountPromise = Promise.resolve(getRemainingSMSCount());
                if (isValidParam(smsCountPromise)) {
                    smsCountPromise.then((response) => {
                        if (isValidParam(response)) {
                            let remainingSMSCount = response['remaining_sms_count'];
                            if (remainingSMSCount > 0) {
                                saveSMS();
                            } else {
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SMS_INTEGRATION.SMS_LOW_BALANCE_1 + remainingSMSCount + '.\n' + getLocalizedStrings().message.SMS_INTEGRATION.SMS_LOW_BALANCE_2, null);
                            }
                        }
                    });
                }
            } 
        } catch (error) {
            console.error("Error in 'sendSMS.js -> saveSMSAfterRemainingCountCheck()':" + error);
        }
    }

    const saveSMS = () => {
        try {
            let _attachFiles = getArrayParam(attachFiles);
            let _fromNumber = getStringParam(fromNumber).trim();
            let _smsText = getStringParam(smsText).trim();
            let _fromTimeStr = getStringParam(fromTimeStr);
            let _toTimeStr = getStringParam(toTimeStr);
            let _isWeekdaysOnly = getBooleanParam(isWeekdaysOnly) === true ? 1 : 0;
            let nodeObject = data;
            let detailsArray = {};
            if (isValidParam(nodeObject) && nodeObject.hasOwnProperty('details') && getArrayParam(nodeObject.details).length > 0) {
                detailsArray = nodeObject.details[0];
                if (isValidParam(detailsArray)) {
                    detailsArray.from_number = _fromNumber;
                    detailsArray.sms_template_id = getIntParam(selectedTemplate);
                    detailsArray.message = _smsText;
                    detailsArray.attachment_info = _attachFiles;
                    detailsArray.t_start_time = _fromTimeStr;
                    detailsArray.t_end_time = _toTimeStr;
                    detailsArray.t_is_weekdays = _isWeekdaysOnly;
                }
            } else {
                detailsArray.from_number = _fromNumber;
                detailsArray.sms_template_id = getIntParam(selectedTemplate);
                detailsArray.message = _smsText;
                detailsArray.attachment_info = _attachFiles;
                detailsArray.t_start_time = _fromTimeStr;
                detailsArray.t_end_time = _toTimeStr;
                detailsArray.t_is_weekdays = _isWeekdaysOnly;
                nodeObject.details = [];
                nodeObject.details.push(detailsArray);
            }
            nodeObject = removeError(nodeObject);
            dispatch(addNode(automationCampaign.data));
            closeDrawer();
            dispatch(showCustomSnackBar(getLocalizedStrings().message.SMS_INTEGRATION.AUTOMATION_SMS_ADD_MSG, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
        } catch (error) {
            console.error("Error in 'AutomationSendSMS.js -> saveSMS()':" + error);
        }
    }

    const openAttachment = () => {
        try {
            let data = {
                contentStyle: {width: '80%', maxWidth: 'none'},
                fullWidth: true,
                maxWidth: 'md',
            }
            data.callFrom = constants.SEND_SMS;
            data.files = getArrayParam(attachFiles);
            dispatch(getAppDialog(true, constants.LIBRARY_DIALOG, getLocalizedStrings().message.COMMON.ASSETMENU_DIALOG_TITLE, (sendData) => {
                // Check for duplicates before adding
               const isDuplicate = attachFiles.some(file => file.id == sendData.id);

               if (isDuplicate) {
                   sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,"Duplicate files are not allowed.", null);
               } else {
                   let attachFile = {
                       name: sendData.name,
                       src: sendData.src,
                       id: sendData.id,
                   };
                   setAttachFiles([...attachFiles, attachFile]);
                   dispatch(getAppDialog(false, null, null, null, null, null));
               }
            }, data, constants.SEND_SMS));
        } catch (error) {
            console.error("Error in 'AutomationSendSMS.js -> openAttachment()':" + error);
        }
    }

    const handleClick = (event) => {
        event.preventDefault();
        setIsOpen(true);
        setAnchorEl(event.currentTarget);
    }

    const menuOnChange = (event, value) => {
        try {
            let callFrom = constants.MERGE_FIELD;
            setSMSBody(event, value, callFrom);
            setIsOpen(false);
            setSmsShrink(true);
        } catch (error) {
            console.error("Error in 'AutomationSendSMS.js ->  menuOnChange()':" + error);
        }
    }

    const getMenu = (fieldItems) => {
        let primaryText = null;
        let items = [];
        try {
            fieldItems = getArrayParam(fieldItems);
            if (fieldItems.length > 0) {
                items = fieldItems.map((item) => {
                    if (isValidParam(item)) {
                        if (isValidParam(Object.values(item)[0]) && isValidParam(Object.keys(item)[0])) {
                            primaryText = Object.values(item)[0];
                            let value = Object.keys(item)[0];
                            return (
                                <MenuItem
                                    style={styles.popoverMenuItem}
                                    value={value}
                                    onClick={(event) => menuOnChange(event, value)}
                                >
                                    {primaryText}
                                </MenuItem>
                            )
                        }
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'PhoneTemplate.js ->  getMenu()':" + error);
        }
        return items;
    }

    const openfields = () => {
        let element = null;
        try {
            element = (
                <span title={getLocalizedStrings().message.SMS_INTEGRATION.MERGE_FIELD}>
                    <span
                        style={{
                            marginRight: 5,
                            maxWidth: 80,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            cursor: 'pointer'
                        }}
                        onClick={(e) => handleClick(e)}>
                        <i className="fa fa-cog" style={{fontSize: '20px', paddingRight: '5px'}}/>
                        <i className="fa fa-angle-down" style={{fontSize: 18, marginTop: 2}}/>
                    </span>
                    <Menu
                        key={'mergeFieldmenu'}
                        anchorEl={anchorEl}
                        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                        targetOrigin={{horizontal: 'left', vertical: 'top'}}
                        // animation={PopoverAnimationVertical}
                        open={isOpen}
                        onClose={() => setIsOpen(false)}
                    >
                        {getMenu(mergeFields)}
                    </Menu>
                </span>
            );
        } catch (error) {
            console.error("Error in 'PhoneTemplate.js -> openfields()':" + error);
        }
        return element
    }

    const getAttachmetFiles = () => {
        let files = getArrayParam(attachFiles);
        let ul = null;
        try {
            let li = files.map((f, index) => {
                return (
                    <li key={f.name + index}>
                        <span
                            title={f.name}
                            style={{
                                float: 'left', textAlign: 'left', overflow: 'hidden',
                                textOverflow: 'ellipsis', display: 'block', whiteSpace: 'nowrap', width: '150px'
                            }}
                        >
                            {f.name}
                        </span>
                        &nbsp;
                        <i className="fa fa-times"
                           title={getLocalizedStrings().label.COMMON.DELETE}
                           aria-hidden="true"
                           style={{paddingRight: '5px', cursor: 'pointer'}}
                           onClick={() => {
                            let tempAttachFiles = attachFiles;
                            tempAttachFiles.splice(index, 1);
                            dispatch(getAppDialog(false, null, null, null, null, null));
                            setAttachFiles(tempAttachFiles);
                           }}
                        />
                    </li>
                )
            });
            if (li !== null && li.length > 0) {
                ul = <ul>{li}</ul>;
            }
        } catch (error) {
            console.error("Error in 'AutomationSendSMS.js -> getAttachmetFiles()':" + error);
        }
        return ul;
    }

    function handleFromNumberChange(value) {
        try {
            if (isValidParam(value)) {
                setFromNumberIndex(value.value);
                setFromNumber(value.title);
            }
        } catch (error) {
            console.error("Error in 'AutomationSendSMS.js -> handleFromNumberChange()':" + error);
        }
    }

    const loadTemplateList = () => {
        try {
            let promise = Promise.resolve(HTTPClient.get(endPoints.SMS_TEMPLATE.GET_TEMPLATE, null));
            promise.then((response) => {
                if (isValidParam(response) && response.status === 0) {
                    let responseData = getArrayParam(response.data);
                    let templateList = [];
                    responseData.forEach(template => {
                        templateList.push({
                            label: template.name,
                            value: template.id,
                            title: template.name,
                            sms_message: template.sms_message,
                            image_object: template.image_object
                        });
                    });
                    setTemplatesList(templateList);

                    if (response.alert) {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, response.alert, null);
                    }
                }
            });
        } catch (error) {
            console.error("Error in 'AutomationSendSMS.js -> loadTemplateList()':" + error);
        }
    }

    const handleTemplateChange = (event, option) => {
        try {
            let recordIds = getArrayParam(data.recordIds);
            if (recordIds.length > 1) {
                setTemplateMsgData(option);
            } else {
                let params = {};
                params.templateId = getIntParam(option.value);
                let promise = Promise.resolve(HTTPClient.get(endPoints.SMS_TEMPLATE.GET_TEMPLATE_DATA, params));
                promise.then((response) => {
                    if (isValidParam(response) && response.status === 0) {
                        setTemplateMsgData(response.data);
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'AutomationSendSMS.js -> handleTemplateChange()':" + error);
        }
    }

    const setTemplateMsgData = (data) => {
        try {
            if (isValidParam(data)) {
                let sms_message = getStringParam(data.sms_message);
                let smslength = sms_message.length;
                let selectedId = getIntParam(data.value);
                let tempAttach = []; 
                if (data.image_object !== null && data.image_object !== "") {
                    let attach = [];
                    attach = data.image_object.split('###');
                    if (attach.length > 0) {
                        attach.forEach(element => {
                            let obj = {};
                            let elm = element.split('@#@');
                            obj.name = elm[0];
                            obj.src = elm[1];
                            obj.id = elm[2];
                            tempAttach.push(obj);
                        });
                    }
                }
                setSmsText(sms_message);
                setSelectedTemplate(selectedId);
                setSmslength(smslength);
                setAttachFiles(tempAttach);
                setSmsShrink(true);
            }
        } catch (error) {
            console.error("Error in 'AutomationSendSMS.js -> setTemplateMsgData()':" + error);
        }
    }

    const onBlurShrink = () => {
        if (!smsText || smsText === '') {
            setSmsShrink(false);
        }
    }

    const generateMSGForm = () => {
        return (
            <div>
                <Grid container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center" spacing={2}>
                    <Grid item xs={6}>
                        <FormControl style={{width: '100%'}} className="test" noValidate autoComplete="off">
                            <Autocomplete
                                style={{...styles.sfFormFieldContainer, paddingLeft: 0}}
                                key={"number"}
                                id="combo-box-sf-automation-send-sms"
                                name="number"
                                defaultValue={phoneNumberList.find(v => v.value === fromNumberIndex)}
                                options={phoneNumberList}
                                onChange={(e, newValue) => handleFromNumberChange(newValue)}
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option) => {
                                    return (
                                        <div title={option.label} {...props}>
                                            {option.label}
                                        </div>
                                    );
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            label={option.label}
                                            title={option.label}
                                            {...getTagProps({index})}
                                        />
                                    ))
                                }
                                disableClearable={false}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder={getLocalizedStrings().message.SMS_INTEGRATION.SELECT_NUMBER}
                                        variant="outlined"
                                        margin="dense"
                                        size='small'
                                        className={"sf-fields-bg"}
                                    />
                                )}
                            />

                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            style={{width: '100%'}}
                            className="test"
                            noValidate autoComplete="off"
                        >
                            {(templatesList.length > 0) && (
                                <Autocomplete
                                    style={{...styles.sfFormFieldContainer, paddingLeft: 0}}
                                    key={"template"}
                                    id="combo-box-sf-automationsendsms"
                                    name="template"
                                    defaultValue={templatesList.find(v => v.value === selectedTemplate)}
                                    options={templatesList}
                                    onChange={(event, option) => handleTemplateChange(event, option)}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable={false}
                                    renderOption={(props, option) => {
                                        return (
                                        <li {...props} key={option.id}>
                                            {option.label}
                                        </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={getLocalizedStrings().label.MARKETING_TEMPLATE.SELECT_TEMPLATE}
                                            variant="outlined" margin="dense" size='small'
                                            className={"sf-fields-bg"}/>
                                    )}
                                />
                            )}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl style={{width: '100%'}} className="test" noValidate>
                            <TextField
                                variant="outlined"
                                id='send-batchsms-text-box'
                                type='textarea'
                                multiline={true}
                                rows={4}
                                onFocus={() => setSmsShrink(true)}
                                onBlur={onBlurShrink}
                                fullWidth={true}
                                style={{minHeight: '30%'}}
                                label={getLocalizedStrings().label.BATCH_SMS.MESSAGE + getLocalizedStrings().label.COMMON.REQUIRED}
                                value={smsText}
                                onChange={(event) => setSMSBody(event)}
                                margin="dense"
                                size='small'
                                InputLabelProps={{
                                    shrink: smsShrink || !!smsText,
                                }}
                                className={"sf-fields-bg"}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{height: '40px'}}>
                    <span>{smslength}{getLocalizedStrings().label.BATCH_SMS.CHARS}</span>
                    <div style={{marginTop: '10px', float: "right", height: 'auto'}}>
                        {(app.me['tenant_country'] === 'USA' || app.me['tenant_country'] === 'Canada') && (
                            <span
                                onClick={() => openAttachment()}
                                style={{cursor: 'pointer', padding: '10px'}}
                                title={getLocalizedStrings().message.SMS_INTEGRATION.IMAGE_SELECT}
                            >
                                <i className="fa fa-file-image" style={{fontSize: '20px'}}/>
                            </span>
                        )}
                        {openfields()}
                    </div>
                </div>
            </div>
        );
    }

    const handleCheckChanges = (event) => {
        try {
            setIsWeekdaysOnly(event.target.checked);
        } catch (error) {
            console.error("Error in AutomaTionSendSms.js -> 'handleCheckChanges()'" + error);
        }
    }

    const populateTimeListS = (value) => {
        let timeList = '';
        let timeArr = duration;
        if (timeArr !== null && timeArr !== '' && timeArr !== undefined) {
            return timeArr.map((timeObj, i) => {
                    return <MenuItem key={timeObj.key + value} value={timeObj.value} style={styles.popoverMenuItem}>{<div
                        style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
                        title={timeObj.label}>{timeObj.label}</div>}</MenuItem>
                        ;
                }
            )
        }
        return timeList;

    }

    const populateTimeListSAMPM = (value) => {
        let timeList = '';
        let timeArr = toTimeAmPMArr;
        if (timeArr !== null && timeArr !== '' && timeArr !== undefined) {
            return timeArr.map((timeObj, i) => {
                    return <MenuItem key={timeObj.key + value} value={timeObj.value} style={styles.popoverMenuItem}>{<div
                        style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
                        title={timeObj.label}>{timeObj.label}</div>}</MenuItem>
                        ;
                }
            )
        }
        return timeList;

    }

    const onChangeValue = (field, value) => {
        let hours = parseInt(value);
        let _fromTime = fromTime;
        let _fromTimeStr = fromTimeStr;
        let _toTime = toTime;
        let _toTimeStr = toTimeStr;
        if (field === 'start_time') {
            if (hours === 12) {
                hours = 0;
            }
            if (fromTimeAmPM === 'PM') {
                hours = parseInt(hours, 10) + 12;
            }
            _fromTime = parseInt(value);
            if (parseInt(value) !== 0) {
                _fromTimeStr = hours < 10 ? '0' + hours + ":00:00" : hours + ":00:00";
            } else {
                _fromTimeStr = "";
            }
        } else if (field === 'end_time') {
            if (hours === 12) {
                hours = 0;
            }
            if (toTimeAmPM === 'PM') {
                hours = parseInt(hours, 10) + 12;
            }
            _toTime = parseInt(value);
            if (parseInt(value) !== 0) {
                _toTimeStr = hours < 10 ? '0' + hours + ":00:00" : hours + ":00:00";
            } else {
                _toTimeStr = "";
            }
        }

        setToTimeStr(_toTimeStr);
        setToTime(_toTime);
        setFromTime(_fromTime);
        setFromTimeStr(_fromTimeStr);
    }

    const onChangeValueAmPm = (field, value) => {
        let hours = 0;
        let _fromTimeAmPM = fromTimeAmPM;
        let _toTimeAmPM = toTimeAmPM;
        let _fromTimeStr = fromTimeStr;
        let _toTimeStr = toTimeStr;
        if (field === 'start_time_AMPM') {
            hours = fromTime;
            if (hours === 12) {
                hours = 0;
            }
            if (value === 'PM') {
                hours = parseInt(hours, 10) + 12;
            }
            _fromTimeAmPM = value;
            if (fromTimeStr !== "") {
                _fromTimeStr = hours < 10 ? '0' + hours + ":00:00" : hours + ":00:00";
            }
        } else if (field === 'end_time_AMPM') {
            hours = toTime;
            if (hours === 12) {
                hours = 0;
            }
            if (value === 'PM') {
                hours = parseInt(hours, 10) + 12;
            }
            _toTimeAmPM = value;
            if (toTimeStr !== "") {
                _toTimeStr = hours < 10 ? '0' + hours + ":00:00" : hours + ":00:00";
            }
        }
        setFromTimeAmPM(_fromTimeAmPM);
        setFromTimeStr(_fromTimeStr);
        setToTimeAmPM(_toTimeAmPM);
        setToTimeStr(_toTimeStr);
    }

    let timeListStart = populateTimeListS("Start");
    let timeListStartAMPM = populateTimeListSAMPM("Start");
    let timeListEnd = populateTimeListS("End");
    let timeListEndAMPM = populateTimeListSAMPM("End");
    let campaignStatus = automationCampaign.data.status;
    let mounted = false;
    if (ismergeFieldLoaded && ismobileNumberLoaded) {
        mounted = true;
    }
    if (mounted) {
        return (
            <div style={{paddingLeft: '15px', paddingRight: '15px', marginTop: '10px'}}>
                <div className="row">
                    {generateMSGForm()}
                    <div style={{float: "right", position: 'relative'}}>
                        <div style={{height: 'auto',marginLeft:'59%',marginTop:'-1%'}}>
                            {attachFiles.length > 0 &&
                                <span className="row" style={{}}>
                                    {getAttachmetFiles()}
                                </span>
                            }
                        </div>
                    </div>
                    <div style={{float: "left", paddingTop: '5px', position: 'relative'}}>
                        <div style={{height: 'auto'}}>
                            <div>
                                <label className='time-input'
                                       style={{...styles.customTimeInputLebel, marginTop: '7px'}}>
                                    {getLocalizedStrings().label.CAMPAIGNS.BETWEEN}
                                </label>
                            </div>
                            <div className="working-hour" style={styles.customTimeHolder}>
                                <div style={{display: 'flex'}}>
                                    <div style={{float: 'left'}}>
                                        <FormControl
                                            margin="dense"
                                            size='small'
                                            variant="outlined"
                                            style={{width: '100%'}}
                                        >
                                            <Select
                                                key={"start_time"}
                                                labelId='sf-label-start_time'
                                                id={"start_time"}
                                                value={fromTime}
                                                onChange={(e) => onChangeValue("start_time", e.target.value)}
                                                name={"start_time"}
                                                margin="dense"
                                                size='small'
                                                className={"sf-fields-bg"}
                                            >
                                                {timeListStart}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={{float: 'left', marginLeft: '1%'}}>
                                        <FormControl
                                            margin="dense"
                                            size='small'
                                            variant="outlined"
                                            style={{width: '100%'}}
                                        >
                                            <Select
                                                key={"start_time_AMPM"}
                                                labelId='sf-label-start_time_AMPM'
                                                id={"start_time_AMPM"}
                                                value={fromTimeAmPM}
                                                onChange={(e) => onChangeValueAmPm("start_time_AMPM", e.target.value)}
                                                name={"start_time_AMPM"}
                                                margin="dense"
                                                size='small'
                                                className={"sf-fields-bg"}
                                            >
                                                {timeListStartAMPM}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <span style={{
                                        float: 'left',
                                        paddingLeft: '5px',
                                        paddingRight: '5px',
                                        paddingTop: '16px',
                                        marginLeft: '2%',
                                        marginRight: '2%'
                                    }}>{getLocalizedStrings().label.COMMON.TO}</span>
                                    <div style={{float: 'left'}}>
                                        <FormControl
                                            margin="dense"
                                            size='small'
                                            variant="outlined"
                                            style={{width: '100%'}}
                                        >
                                            <Select
                                                key={"end_time"}
                                                labelId='sf-label-end_time'
                                                id={"end_time"}
                                                value={toTime}
                                                onChange={(e) => onChangeValue("end_time", e.target.value)}
                                                name={"end_time"}
                                                margin="dense"
                                                size='small'
                                                className={"sf-fields-bg"}
                                            >
                                                {timeListEnd}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={{float: 'left', marginLeft: '1%'}}>
                                        <FormControl
                                            margin="dense"
                                            size='small'
                                            variant="outlined"
                                            style={{width: '100%'}}
                                        >
                                            <Select
                                                key={"end_time_AMPM"}
                                                labelId='sf-label-end_time_AMPM'
                                                id={"end_time_AMPM"}
                                                value={toTimeAmPM}
                                                onChange={(e) => onChangeValueAmPm("end_time_AMPM", e.target.value)}
                                                name={"end_time_AMPM"}
                                                margin="dense"
                                                size='small'
                                                className={"sf-fields-bg"}
                                            >
                                                {timeListEndAMPM}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <span style={{
                                        float: 'left',
                                        paddingLeft: '5px',
                                        paddingRight: '5px',
                                        paddingTop: '4px',
                                        marginLeft: '10%'
                                    }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="default"
                                                    checked={isWeekdaysOnly}
                                                    value={isWeekdaysOnly}
                                                    onChange={(e) => handleCheckChanges(e)}
                                                    defaultChecked={false}
                                                />
                                            }
                                            label={getLocalizedStrings().label.CAMPAIGNS.WEEKDAYS_ONLY}
                                            labelposition="right" style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            marginBottom: '-16px'
                                        }}
                                            labelstyle={{
                                                color: '#717171',
                                                padding: '0px',
                                                width: 'auto',
                                                fontWeight: 'normal'
                                            }}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{float: "right", paddingTop: '10px', position: 'relative'}}>
                        {campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING &&
                            <div style={{float: 'right'}}>
                                <Button
                                    onClick={() => checkCallTextProvider()}
                                    style={{...styles.primaryButton, marginLeft: '6px', marginRight: '0px'}}
                                >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                                <Button
                                    onClick={() => closeDrawer()}
                                    style={{...styles.secondaryButton, marginLeft: '10px', marginRight: '0px'}}
                                >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    } else if (noFromNumber) {
        return (<div style={{margin: '10px'}}>
                {getLocalizedStrings().message.SMS_INTEGRATION.NO_FROM_NUMBER_MESSAGE}
            </div>
        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div style={{width: '100%', height: contentHeight}}>
                <div className="asset-loaderh" style={{paddingTop: top, paddingLeft: '48%'}}>
                    <div style={{...styles.assetLoaderContainer, height: 50, width: 50, padding: 7}}>
                        <ShowCircularProgress size={30} style={{marginTop: '3', marginLeft: '3'}}/>
                    </div>
                </div>
            </div>
        );
    }
}


export default AutomationSendSMS;
