import React, { useState, useEffect } from 'react';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { Select, MenuItem, InputLabel, Icon } from '@mui/material';
import Button from '@mui/material/Button';
import { isValidParam, getBooleanParam, getObjectParam, getArrayParam, isInvalidParam } from '../../../services/helper/parameterVerifier';
import { getAurinkoConfigDetails, disconnectAurinko, saveAurinkoSetupForSync, checkIfContactGroupExists, getAllCalendarList, getContactFilters, syncCalendarNow, setNylasConfigDetails } from '../../../services/actions/UserListAction';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints'
import ShowCircularProgress from '../components/circularProgress';
import Checkbox from '@mui/material/Checkbox';
import * as sfDialogs from '../components/sfDialogs';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import { setSchedulerDetails } from '../../../services/actions/schedulerActions';
import { useDispatch, useSelector } from "react-redux";
import { updateReAuthenticateAurinko, updateReAuthenticateAurinkoAll, updateIsSendMailUsingAurinko} from "../../../services/actions/appActions";

const AurinkoIntegration = () => {

    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const schedulerDetails = useSelector((state) => state.schedulerDetails);
    const userlist = useSelector((state) => state.userlist);

    const [integrationSetUpObj, setIntegrationSetUpObj] = useState(null);
    const [connectClicked, setConnectClicked] = useState(false);
    const [attempts, setAttempts] = useState(10);
    const [disconnect, setDisconnect] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [appendCompany, setAppendCompany] = useState(false);
    const [appendContact, setAppendContact] = useState(false);
    const [integrationOption, setIntegrationOption] = useState("Microsoft Office 365");
    const [token_flag, setTokenFlag] = useState(0);
    const [syncEmail, setSyncEmail] = useState(false);
    const [syncContact, setSyncContact] = useState(false);
    const [syncCalendar, setSyncCalendar] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);
    const [selectedContactFilter, setSelectedContactFilter] = useState(null);
    const [soffrontGroupId, setSoffrontGroupId] = useState(null);
    const [contactLoader, setContactLoader] = useState(false);
    const [calendarList, setCalendarList] = useState(null);
    const [selectedCalendarForSync, setSelectedCalendarForSync] = useState(null);
    const [calendarLoader, setCalendarLoader] = useState(false);
    const [contactFilterList, setContactFilterList] = useState(null);
    const [isAccountSyncStateValid, setIsAccountSyncStateValid] = useState(true);
    const [syncEmailWebHookId, setSyncEmailWebHookId] = useState(0);
    const [syncCalendarWebHookId, setSyncCalendarWebHookId] = useState(0);
    const [sendEmailWebHookId, setSendEmailWebHookId] = useState(0);
    
    useEffect(() => {
        getIntegrationDetails();
    }, []);

    const getIntegrationDetails = () => {
        try {
            let param = { onload: 'onload', integration_type : 'Aurinko' };
            let promise = getAurinkoConfigDetails(param);
            if (isValidParam(promise)) {
                promise.then((response) => {

                    if (isValidParam(response)) {
                        setIntegrationDetails(response);
                    }
                });
            }
        } catch (error) {
            console.log(error.message);
        }

    }
   const setIntegrationDetails = (list) => {
        let obj = null;
        let data = {};
        if (list !== null && list.length > 0) {
            setDataLoaded(true);
            data.ConfigDetails = list;
            data.isNylasSetup = true;
            for (var i = 0; i < list.length; i++) {
                obj = list[i];
                if (isValidParam(obj)) {
                    if (obj.type === 'GoogleSuite') {
                        setIntegrationOption("Google Workspace");
                    }
                    else if (obj.type === 'MSE') {
                        setIntegrationOption("Microsoft Office 365");
                    }

                    setConnectClicked(false);

                    if (obj.append_company === 'y') {
                        setAppendCompany(true);
                    } else {
                        setAppendCompany(false);
                    }

                    if (obj.append_contact === 'y') {
                        setAppendContact(true);
                    } else {
                        setAppendContact(false);
                    }

                    if (obj.sync_email === 'y') {
                        setSyncEmail(true);
                        setSyncEmailWebHookId(obj.sync_email_webhook_id);
                    } else {
                        setSyncEmail(false);
                    }

                    if (obj.send_email === 'y') {
                        setSendEmail(true);
                        setSendEmailWebHookId(obj.send_email_webhook_id);
                    } else {
                        setSendEmail(false);
                    }

                    if (obj.sync_contact === 'y') {
                        setSelectedContactFilter(obj['contact_filter_id']);
                        setSoffrontGroupId(obj['contact_group_id']);
                        setSyncContact(true);

                    } else {
                        setSyncContact(false);
                    }

                    if (obj.hasOwnProperty("contact_filters")) {
                        let filter_arr = getArrayParam(obj.contact_filters);
                        if (filter_arr !== null && filter_arr.length > 0) {
                            if (!isValidParam(selectedContactFilter) && selectedContactFilter === '') {
                                setSelectedContactFilter(filter_arr[0].id + '_' + filter_arr[0].type);
                            }
                        }
                        setContactFilterList(filter_arr);
                    }

                    if (obj.sync_cal === 'y') {
                        setSelectedCalendarForSync(obj['calendar_id']);
                        setSyncCalendarWebHookId(obj.sync_cal_webhook_id);
                        let calendarArray = [];
                        let calObj = getObjectParam(obj.calendar_list);
                        Object.keys(calObj).map((key, index) => {
                            if (index === 0 && !isValidParam(selectedCalendarForSync)) {
                                setSelectedCalendarForSync(key);
                            }
                            calendarArray.push(<MenuItem key={key} id={key} value={key}>{calObj[key]}</MenuItem>);
                        });

                        setCalendarList(calendarArray);

                        setSyncCalendar(true);

                        if (isValidParam(selectedCalendarForSync)) {
                            if (Object.keys(calObj).length > 0 && Object.keys(calObj).indexOf(selectedCalendarForSync) < 0) {
                                setSelectedCalendarForSync(Object.keys(calObj)[0]);
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.MY_INTEGRATION.SELECTED_CALENDAR_NOT_FOUND, null);
                            }
                        }
                        updateSchedulerProps(true);
                    } else {
                        setSyncCalendar(false);
                    }

                    let accountSyncStatus = obj.account_sync_status;
                    if (accountSyncStatus !== null && (accountSyncStatus !== 'running' &&
                            accountSyncStatus !== 'partial' && accountSyncStatus !== 'downloading')) {
                            setIsAccountSyncStateValid(false);
                            dispatch(updateReAuthenticateAurinko(true));
                    }
                }
            }
        } else {
            setDataLoaded(true);
            data.ConfigDetails = [{}];
            data.isNylasSetup = false;
        }
        dispatch(setNylasConfigDetails(data));
        setIntegrationSetUpObj(obj);
    }

    const connectToAurinkoAPI = () => {
        let provider = integrationOption;
        let serviceType = "Google";
        if(provider!= null && provider!=""){
            if(provider == "Microsoft Office 365"){
                serviceType = "Office365";
            }
        }
        setConnectClicked(true);
        setAttempts(10);
        let refreshIntervalId = 0;
        setTokenFlag(0);
        let token = localStorage.getItem('token');
        let url = constants.AURINKO_AUTH_API + 'clientId=' + app.aurinko_integration_apikey +
            '&responseType=token&serviceType='+serviceType+'&scopes=Mail.Read Mail.Send Mail.Drafts Calendar.ReadWrite&returnUrl=' + constants.endPoint + endPoints.AURINKO_INTEGRATION.REDIRECT_URI + "&state=" + token;
        var myWindow = window.open("", "", 'scrollbars=1,width=850,height=550,location=no');
        myWindow.location = url;
        setTimeout(function () {
            fnGetEmailConfigDetails(refreshIntervalId)
        }, 20000);

    }

    const fnGetEmailConfigDetails = (refreshIntervalId) => {
        try {
            let param = { onload: 'onload', integration_type : 'Aurinko' };
            let data = {};
            let _attempts = attempts;
            refreshIntervalId = window.setInterval(function () {
                if (token_flag === 0 && _attempts > 0) {
                    let promise = getAurinkoConfigDetails(param);
                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            setAttempts(_attempts-1);
                            if (isValidParam(response)) {
                                let list = response;
                                let tokenFlag = 0;
                                if (list !== null && list.length > 0) {
                                    data.ConfigDetails = list;
                                    data.isNylasSetup = true;
                                    for (var i = 0; i < list.length; i++) {
                                        let obj = list[i];
                                        if (obj !== null) {
                                            if (obj.type === 'GoogleSuite') {
                                                setIntegrationOption("Google Workspace");
                                            } else if (obj.type === 'MSE') {
                                                setIntegrationOption("Microsoft Office 365");
                                            }
                                            setConnectClicked(false);
                                            setAppendCompany(false);
                                            setAppendContact(false);
                                            setSyncEmail(false);
                                            setSendEmail(false);
                                            setSyncContact(false);
                                            setSyncCalendar(false);
                                            dispatch(updateReAuthenticateAurinkoAll(false));
                                            dispatch(updateIsSendMailUsingAurinko(false));
                                        }

                                        tokenFlag = obj.is_token_exist;
                                        if (tokenFlag) {
                                            setAttempts(0);
                                            setTokenFlag(1);
                                            clearInterval(refreshIntervalId);
                                            setIntegrationSetUpObj(obj);
                                        }
                                    }
                                } else {
                                    data.ConfigDetails = [{}];
                                    data.isNylasSetup = false;
                                }
                            } else {
                                data.ConfigDetails = [{}];
                                data.isNylasSetup = false;
                            }
                            dispatch(setNylasConfigDetails(data));
                        });
                    }
                }

            }, 5000);
        } catch (error) {
            console.log(error.message);
        }
    }


    const setCalendarForSync = (value) => {
        setSelectedCalendarForSync(value);
    }

    const setContactFilterForSync = (value) => {
        setSelectedContactFilter(value);
    }

    const disconnectFromAurinko = (event, provider) => {
        try {
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.MY_INTEGRATION.DISCONNECT_CONNECTION, ()=>confirmDisconnectFromAurinko(), null);
        } catch (error) {
            console.log(error.message);
        }

    }

    const confirmDisconnectFromAurinko = () => {
        try {

            let param = { access_token: integrationSetUpObj['access_token'], 
                integration_type: integrationSetUpObj['integration_type'], account_id: integrationSetUpObj['account_id'] };
            setDisconnect(true);
            let promise = disconnectAurinko(param);
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (response.hasOwnProperty('is_revoked') && response.is_revoked) {
                        setIntegrationSetUpObj(null);
                        setConnectClicked(false);
                        setDisconnect(false);
                        setSelectedCalendarForSync(null);
                        setAppendCompany(false);
                        setAppendContact(false);
                        setSyncEmail(false);
                        setSendEmail(false);
                        setSyncContact(false);
                        setSyncCalendar(false);
                        setSelectedContactFilter(null);
                        setContactFilterList(null);
                        setSoffrontGroupId(null);
                        setSyncEmailWebHookId(0);
                        setSyncCalendarWebHookId(0);
                        setSendEmailWebHookId(0);
                        setAttempts(10);
                        setTokenFlag(0);
                        updateSchedulerProps(false);
                        setIsAccountSyncStateValid(true);
                        dispatch(updateReAuthenticateAurinkoAll(false));
                        dispatch(updateIsSendMailUsingAurinko(false));
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'aurinkoIntegration.js -> confirmDisconnectFromAurinko()':" + error);
        }
    }

    const syncNow = () => {
        try {
            let isAurinkoReAuthRequired = app.me.is_aurinko_reauth_required;
            if (isAurinkoReAuthRequired) {
                let msg = getLocalizedStrings().message.MY_INTEGRATION.RE_AUTHENTICATE_AURINKO;
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
            } else {
                let promise = syncCalendarNow();
                if (isValidParam(promise)) {
                    promise.then((response) => {
                            if (isValidParam(response) && response.hasOwnProperty('status')) {
                                if (response.status === 0) {
                                    dispatch(showCustomSnackBar(getLocalizedStrings().message.APPOINTMENTS_TASKS.SYNC_STARTED_MSG, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                } else {
                                    dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                }
                            }                      
                    });
                }
            }
        } catch (error) {
            console.error("Error in 'aurinkoIntegration.js -> syncNow()':" + error);
        }
    }

    const updateSchedulerProps = (syncCalendar) => {
        try {
            let availabilityInfoObj = {};
            let scheduleObj = schedulerDetails.data;
            if (scheduleObj.hasOwnProperty('availabilityInfo')) {
                availabilityInfoObj = scheduleObj.availabilityInfo;
            }
            availabilityInfoObj.isNylasSetup = syncCalendar;
            scheduleObj.availabilityInfo = availabilityInfoObj;
            dispatch(setSchedulerDetails(scheduleObj));
        } catch (error) {
            console.log(error.message);
        }
    }

    const saveIntegrationSetup = () => {
        try {
            let valid = true;
            if (syncEmail === false && syncContact === false && syncCalendar === false) {
                valid = false;
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.MY_INTEGRATION.NO_SYNC_CHECKED, null);
            }

            if (syncCalendar && (selectedCalendarForSync === null || selectedCalendarForSync === '')) {
                valid = false;
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.MY_INTEGRATION.NO_CALENDAR, null);
            }

            if (syncContact) {
                if (soffrontGroupId === null || soffrontGroupId === '' || soffrontGroupId === undefined) {
                    valid = false;
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "Contact group is not found", null);
                } else {
                    if (selectedContactFilter === null || selectedContactFilter === '' || selectedContactFilter === undefined) {
                        valid = false;
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "Contact filter is not found", null);
                    }
                }
            }

            if (valid) {
                let param = {
                    append_company: appendCompany,
                    append_contact: appendContact,
                    sync_email: syncEmail,
                    send_email: sendEmail,
                    sync_contact: syncContact,
                    sync_calendar: syncCalendar,
                    calendar_id: selectedCalendarForSync !== null ? selectedCalendarForSync : "",
                    account_id: integrationSetUpObj['account_id'],
                    accessToken: integrationSetUpObj['access_token'],
                    syncEmailWebhookId: syncEmailWebHookId,
                    sendEmailWebhookId: sendEmailWebHookId,
                    syncCalWebhookId: syncCalendarWebHookId,
                    integrationType: integrationSetUpObj['integration_type'],
                };

                if (syncContact) {
                    param.contact_filter_id = selectedContactFilter;
                }
                if (userlist.nylasConfigDetails !== null &&
                    userlist.nylasConfigDetails.ConfigDetails.length > 0 && userlist.nylasConfigDetails.isNylasSetup) {
                    let list = userlist.nylasConfigDetails.ConfigDetails[0];
                    list.append_company = appendCompany ? "y" : "n";
                    list.append_contact = appendContact ? "y" : "n";
                    list.sync_email = syncEmail ? "y" : "n";
                    list.send_email = sendEmail ? "y" : "n";
                    list.sync_contact = syncContact ? "y" : "n";
                    list.sync_cal = syncCalendar ? "y" : "n";
                    list.calendar_id = selectedCalendarForSync !== null ? selectedCalendarForSync : "";
                    list.account_id = integrationSetUpObj['account_id'];
                    if (syncContact) {
                        list.contact_filter_id = selectedContactFilter;
                    }
                    let data = {};
                    data.ConfigDetails = list;
                    data.isNylasSetup = true;
                    dispatch(setNylasConfigDetails(data));
                }
                let success_msg = getLocalizedStrings().message.MY_INTEGRATION.SAVE_SUCCESS;
                let promise = saveAurinkoSetupForSync(param);
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            if (response.hasOwnProperty('data') && response.data.hasOwnProperty('is_saved') && response.data.is_saved) {
                                setSyncEmailWebHookId(response.data.sync_email_webhook_id);
                                setSendEmailWebHookId(response.data.send_email_webhook_id);
                                setSyncCalendarWebHookId(response.data.sync_cal_webhook_id);
                                updateSchedulerProps(syncCalendar);
                                dispatch(updateIsSendMailUsingAurinko(sendEmail));
                                dispatch(showCustomSnackBar(success_msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            } else {
                                dispatch(updateIsSendMailUsingAurinko(false));
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.MY_INTEGRATION.SAVE_FAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            }
                        }
                    });
                }
            }
        } catch (error) {
            console.log(error.message);
        }

    }

    const onCheckAppend = (isInputChecked, name) => {
        if(name ==='appendCompany'){
            setAppendCompany(isInputChecked);
        }else if(name === 'appendContact'){
            setAppendContact(isInputChecked);
        }
    }

    const onCheckSyncOpt = (isInputChecked, name) => {
        if (name === "sendEmail") {
            let connectedEmail = integrationSetUpObj['connected_email'];
            let crmEmail = app.me.email;
            let provider = integrationOption;
            if (isInputChecked) {
                if (crmEmail.toLowerCase() === connectedEmail.toLowerCase()) {
                    if (syncEmail === false) {
                        setSendEmail(true);
                    } else {
                        setSendEmail(isInputChecked);
                    }
                } else {
                    let msg = "";
                    let msgArr = [];
                    msg = getLocalizedStrings().message.MY_INTEGRATION.SEND_SALES_EMAILS_VALIDATION1+' <b>'+provider+'</b>, '+
                    getLocalizedStrings().message.MY_INTEGRATION.SEND_SALES_EMAILS_VALIDATION2+" <b>"+crmEmail+"</b>. <br/>"+
                    getLocalizedStrings().message.MY_INTEGRATION.SEND_SALES_EMAILS_VALIDATION3+" <b>"+crmEmail+"</b>."    
                    msgArr.push(msg);
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msgArr, null);
                }

            } else {
                setSendEmail(isInputChecked);
            }
        } else {
            setSyncEmail(isInputChecked);
        }
    }

    const onCheckCalendarSyncOpt = (isInputChecked, name, obj) => {
        if (isInputChecked) {
            getCalendarListForSync(obj, name);
        } else {
            setSyncCalendar(isInputChecked);
            setAppendCompany(false);
            setAppendContact(false);
        }
    }

    const getCalendarListForSync = (obj, name) => {
        try {
            setSyncCalendar(true);
            setCalendarLoader(true);
            setAppendCompany(true);
            setAppendContact(true);
            let param = {
                access_token: obj.access_token,
                provider: obj.email_provider,
                sync_email: obj.connected_email
            };
            let promise = getAllCalendarList(param);
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (response.hasOwnProperty('calendar_list')) {

                            let calendarArray = [];
                            let calObj = response.calendar_list;
                            Object.keys(calObj).map((key, index) => {
                                if (index === 0) {
                                    setSelectedCalendarForSync(key);
                                }
                                calendarArray.push(<MenuItem key={key} id={key} value={key}>{calObj[key]}</MenuItem>);
                            });

                            if (calendarArray !== null && calendarArray.length > 0) {
                                setCalendarList(calendarArray);
                                setCalendarLoader(false);
                            } else {
                                setCalendarLoader(false);
                            }
                        }
                    }
                });
            }
        } catch (error) {
            console.log(error.message);
        }

    }

    const onCheckContactSyncOpt = (isInputChecked, name, obj) => {

        if (isInputChecked) {
            fnCheckIfContactGroupExists(obj, name);
        } else {
            setSyncContact(isInputChecked);
        }

    }

    const fnCheckIfContactGroupExists = (obj, name) => {
        try {
            setSyncContact(true);
            setContactLoader(true);
            let param = {
                access_token: obj.access_token,
                account_id: obj.account_id,
                type: obj.type
            };
            let promise = checkIfContactGroupExists(param);
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (response.hasOwnProperty('group_id')) {
                            if (isValidParam(response.group_id) && response.group_id !== '') {

                                let farr = getArrayParam(contactFilterList);

                                if (farr !== null && farr.length > 0) {
                                    setSoffrontGroupId(response.group_id);
                                    setContactLoader(false);
                                } else {
                                    getFilters(response.group_id);
                                }
                            } else {
                                setSoffrontGroupId(response.group_id);
                                setContactLoader(false);
                            }
                        }
                    }

                });
            }
        } catch (error) {
            console.log(error.message);
        }

    }

    const generateContactFiltersMenu = () => {
        let filterArray = [];
        let filter_arr = getArrayParam(contactFilterList);
        if (filter_arr !== null && filter_arr.length > 0) {
            filter_arr.forEach(function (obj, index) {
                filterArray.push(<MenuItem key={obj.id + '_' + obj.type} id={obj.id + '_' + obj.type} value={obj.id + '_' + obj.type}>{obj.name}</MenuItem>);
            });
        }
        return filterArray;
    }

    const getFilters = (groupId) => {
        try {
            let param = { table_id: 9 };
            let promise = getContactFilters(param);

            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (response.hasOwnProperty('contact_filters')) {
                            let filter_arr = getArrayParam(response.contact_filters);
                            let fid = 0;
                            if (filter_arr !== null && filter_arr.length > 0 && ((!isValidParam(selectedContactFilter) ||
                                (isValidParam(selectedContactFilter) && selectedContactFilter === '')))) {
                                fid = filter_arr[0].id + '_' + filter_arr[0].type;
                            } else {
                                fid = selectedContactFilter;
                            }
                            setSoffrontGroupId(groupId);
                            setContactFilterList(filter_arr);
                            setContactLoader(false);
                            setSelectedContactFilter(fid);
                        }
                    }

                });
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const getSetupSection = () => {
        let filterArrObj = getArrayParam(contactFilterList);
        let arr = [];
        let language = getLocalizedStrings()._language;
        let emailDisabled = false;
        arr.push(
            <div style={{ float: 'left', marginTop: 5 }}>
                {((!isValidParam(integrationSetUpObj) || !getBooleanParam(integrationSetUpObj.is_token_exist)) && !connectClicked) &&
                    <div style={{ paddingLeft: '10px' }}><Button
                        key='connect'
                        onClick={()=>connectToAurinkoAPI()}

                        style={{ ...styles.primaryButton, verticalAlign: 'top' }}
                    >{getLocalizedStrings().label.COMMON.CONNECT}</Button></div>
                }
                {connectClicked &&
                    <div style={{ paddingLeft: '10px' }}> <span className="msg-import-progress" >{getLocalizedStrings().label.MY_INTEGRATION.CONNECTING}<img src="/asset/images/ajLoader2.gif" /></span></div>
                }

                {disconnect &&

                    <div style={{ paddingLeft: '10px' }}><span className="msg-import-progress" >{getLocalizedStrings().label.MY_INTEGRATION.DISCONNECTING}<img src="/asset/images/ajLoader2.gif" /></span></div>

                }
                {integrationSetUpObj !== null && integrationSetUpObj['is_token_exist'] && !disconnect &&

                    <div style={{ width: '100%'}}>
                        <div style={{ padding: 11, fontSize: '15px', background: '#e3e6e8', marginBottom: 10 }}>{getLocalizedStrings().label.COMMON.CONNECTED_AS} {integrationSetUpObj['connected_email']}
                        </div>

                        <div style={{ width: '100%', float: 'left', display: 'inline-flex' }}>
                            <div style={{ width: '91%', float: 'left' }}>
                                <FormControlLabel
                                    control={<Checkbox
                                        color="default"
                                        checked={syncEmail}
                                        value={syncEmail}
                                        onChange={(e)=>onCheckSyncOpt(e.target.checked, 'syncEmail')}
                                    />}
                                    label={getLocalizedStrings().label.MY_INTEGRATION.SYNC_EMAIL}//"Synchronize Emails"
                                    labelposition="right"
                                    labelstyle={{ width: '100%' }}
                                    disabled={emailDisabled}
                                />
                                <div style={{ paddingLeft: '30px' }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            color="default"
                                            checked={sendEmail}
                                            value={sendEmail}
                                            onChange={(e)=>onCheckSyncOpt(e.target.checked, 'sendEmail')}
                                        />}
                                        label={getLocalizedStrings().label.MY_INTEGRATION.SEND_SALES_EMAILS_USING + " " + integrationOption}
                                        labelposition="right"
                                        labelstyle={{ width: '100%' }}
                                        disabled={emailDisabled}
                                    />
                                </div>
                            </div>
                            <div style={{ float: 'right', marginLeft: '5px' }} >

                                <Tooltip title={
                                    <div style={{ padding: '5px' }}>
                                        <p style={{ ...styles.automationTooltipStyle, width: '100%' }}>{getLocalizedStrings().message.MY_INTEGRATION.SYNC_EMAIL_INFO_1}</p>
                                        <p style={{ ...styles.automationTooltipStyle, width: '100%' }}>{getLocalizedStrings().message.MY_INTEGRATION.SYNC_EMAIL_INFO_2}</p>
                                        <p style={{ ...styles.automationTooltipStyle, width: '100%' }}>{getLocalizedStrings().message.MY_INTEGRATION.SYNC_EMAIL_INFO_3}</p>
                                    </div>
                                } placement="right-end">
                                    <Icon
                                        title={getLocalizedStrings().label.DETAIL_VIEW.CLICK_TO_KNOW_MORE}
                                        style={{ fontSize: '22px', color: '#cccccc', cursor: 'pointer' }}
                                        data-tip data-for="email-info"
                                        data-event='click focus'
                                    >
                                        info_outline
                                    </Icon>
                                </Tooltip>

                            </div>
                        </div>
                        {/* <div style={{ width: '100%', float: 'left', display: 'inline-flex' }}>
                            <div style={{ width: '91%', float: 'left' }}>
                                <FormControlLabel
                                    control={<Checkbox
                                        color="default"
                                        checked={syncContact}
                                        value={syncContact}
                                        onChange={(e)=>onCheckContactSyncOpt(e.target.checked, 'syncContact', integrationSetUpObj)}
                                    />}
                                    label={getLocalizedStrings().label.MY_INTEGRATION.SYNC_CONTACT}//"Synchronize Emails"
                                    labelposition="right"
                                    labelstyle={{ width: '100%' }}

                                />
                            </div>
                            <div style={{ float: 'right', marginLeft: '5px' }} >
                                <Tooltip title={<div style={{ padding: '5px' }}>
                                    <p style={{ ...styles.automationTooltipStyle, width: '100%' }}>{getLocalizedStrings().message.MY_INTEGRATION.SYNC_CONTACT_INFO_1}</p>
                                    <p style={{ ...styles.automationTooltipStyle, width: '100%' }}>{getLocalizedStrings().message.MY_INTEGRATION.SYNC_CONTACT_INFO_2}</p>
                                    <p style={{ ...styles.automationTooltipStyle, width: '100%' }}>{getLocalizedStrings().message.MY_INTEGRATION.SYNC_CONTACT_INFO_3}</p>
                                </div>} placement="right-end">
                                    <Icon
                                        title={getLocalizedStrings().label.DETAIL_VIEW.CLICK_TO_KNOW_MORE}
                                        style={{ fontSize: '22px', color: '#cccccc', cursor: 'pointer' }}
                                        data-tip data-for="contact-info"
                                        data-event='click focus'
                                    >
                                        info_outline
                                    </Icon>
                                </Tooltip>
                            </div>
                        </div> */}

                        {/* {syncContact &&
                            <div style={{ paddingTop: '5px', paddingLeft: '40px', paddingBottom: '8px', width: '100%', display: 'table' }}>
                                {contactLoader &&
                                    <div>
                                        <span className="msg-import-progress" >{getLocalizedStrings().label.COMMON.LOADING}<img src="/asset/images/ajLoader2.gif" /></span>
                                    </div>
                                }

                                {!contactLoader &&
                                    <div>
                                        {isInvalidParam(soffrontGroupId) &&
                                            <div>
                                                <span style={{ float: 'left', color: 'red' }}>{getLocalizedStrings().label.MY_INTEGRATION.CONTACT_GROUP_NOT_FIND} <a style={{ cursor: 'pointer' }} onClick={(e)=>fnCheckIfContactGroupExists(e, integrationSetUpObj)}>{getLocalizedStrings().label.MY_INTEGRATION.HERE}</a> {getLocalizedStrings().label.MY_INTEGRATION.TO_TRY_AGAIN}</span>
                                            </div>
                                        }
                                        {isValidParam(soffrontGroupId) && soffrontGroupId !== '' && filterArrObj.length === 0 &&
                                            <div>
                                                <span style={{ float: 'left', color: 'red' }}>{getLocalizedStrings().label.MY_INTEGRATION.CONTACT_FILTER_NOT_FIND}</span>
                                            </div>
                                        }
                                        {filterArrObj.length > 0 &&
                                            <div>
                                                <FormControl variant="outlined" style={{ width: '50%' }}>
                                                    <InputLabel id="sf-nylasintegration-simple-select-outlined-label" className="sf-nylasintegration">{getLocalizedStrings().label.MY_INTEGRATION.CRM_CONTACT_FILTER_SELECT}</InputLabel>
                                                    <Select
                                                        label={getLocalizedStrings().label.MY_INTEGRATION.CRM_CONTACT_FILTER_SELECT}
                                                        labelId='sf-label-contact-filter'
                                                        value={selectedContactFilter}
                                                        className={"sf-fields-bg"}
                                                        style={{ height: '42px' }}
                                                        onChange={(e)=>setContactFilterForSync(e.target.value)}
                                                    >
                                                        {generateContactFiltersMenu()}
                                                    </Select>
                                                </FormControl>
                                            </div>}
                                    </div>}
                            </div>
                        } */}
                        <div style={{ width: '100%', float: 'left', display: 'inline-flex' }}>
                            <div style={{ width: '91%', float: 'left' }}>
                                <FormControlLabel
                                    control={<Checkbox
                                        color="default"
                                        checked={syncCalendar}
                                        value={syncCalendar}
                                        onChange={(e)=>onCheckCalendarSyncOpt(e.target.checked, 'syncCalendar', integrationSetUpObj)}
                                    />}
                                    label={getLocalizedStrings().label.MY_INTEGRATION.SYNC_CALENDAR}//"Synchronize Emails"
                                    labelposition="right"
                                    labelstyle={{ width: '100%' }}

                                />

                            </div>
                            <div style={{ float: 'right', marginLeft: '5px' }} >
                                <Tooltip title={
                                    <div style={{ padding: '5px' }}>
                                        <p style={{ ...styles.automationTooltipStyle, width: '100%' }}>{getLocalizedStrings().message.MY_INTEGRATION.SYNC_CALENDAR_INFO_1}</p>
                                        <p style={{ ...styles.automationTooltipStyle, width: '100%' }}>{getLocalizedStrings().message.MY_INTEGRATION.SYNC_CALENDAR_INFO_2}</p>
                                        <p style={{ ...styles.automationTooltipStyle, width: '100%' }}>{getLocalizedStrings().message.MY_INTEGRATION.SYNC_CALENDAR_INFO_3}</p>
                                    </div>
                                } placement="right-end">
                                    <Icon
                                        title={getLocalizedStrings().label.DETAIL_VIEW.CLICK_TO_KNOW_MORE}
                                        style={{ fontSize: '22px', color: '#cccccc', cursor: 'pointer' }}
                                        data-tip data-for="cal-info"
                                        data-event='click focus'
                                    >
                                        info_outline
                                    </Icon>
                                </Tooltip>
                            </div>
                        </div>
                        {(syncCalendar || !isAccountSyncStateValid) &&
                            <div style={{ paddingTop: '5px', paddingLeft: '40px', paddingBottom: '8px', width: '100%', display: 'table', fontSize: '15px' }}>
                                {calendarLoader &&
                                    <div>
                                        <span className="msg-import-progress" >{getLocalizedStrings().label.COMMON.LOADING}<img src="/asset/images/ajLoader2.gif" /></span>
                                    </div>
                                }
                                {!calendarLoader &&
                                    <div>
                                        {(isAccountSyncStateValid && (calendarList === null || (calendarList !== null && calendarList.length === 0))) &&
                                            <div>
                                                <span style={{ float: 'left', color: 'red' }}>{getLocalizedStrings().message.MY_INTEGRATION.NO_CALENDAR_MESSAGE}</span>
                                            </div>
                                        }

                                        {
                                            <div>
                                                {!isAccountSyncStateValid &&
                                                    <span style={{ float: 'left', color: 'red' }}>{getLocalizedStrings().message.MY_INTEGRATION.INVALID_ACCOUNT_SYNC_STATE1 + " " + integrationOption + " " + getLocalizedStrings().message.MY_INTEGRATION.INVALID_ACCOUNT_SYNC_STATE2}</span>}
                                            </div>
                                        }


                                        {isValidParam(calendarList) && (calendarList !== null && calendarList.length > 0) &&
                                            <div>
                                                <div>    <FormControl variant="outlined" style={{ width: '50%' }}>
                                                    <InputLabel id="sf-nylasintegration-result-simple-select-outlined-label" className="sf-nylasintegration-result">{getLocalizedStrings().label.MY_INTEGRATION.SELECT_A + " " + integrationOption + " " + getLocalizedStrings().label.MY_INTEGRATION.CALENDAR}</InputLabel>
                                                    <Select
                                                        label={getLocalizedStrings().label.MY_INTEGRATION.SELECT_A + " " + integrationOption + " " + getLocalizedStrings().label.MY_INTEGRATION.CALENDAR}
                                                        labelId='sf-label-callScriptList'
                                                        value={selectedCalendarForSync}
                                                        className={"sf-fields-bg"}
                                                        style={{ height: '42px' }}
                                                        onChange={(e)=>setCalendarForSync(e.target.value)}
                                                    >
                                                        {calendarList}
                                                    </Select>
                                                </FormControl>
                                                </div>
                                                <div style={{ display: 'inline-block', paddingTop: '15px' }}>{getLocalizedStrings().message.MY_INTEGRATION.APPEND_INFO}</div>
                                                <div style={{ paddingTop: '10px' }}>
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            color="default"
                                                            checked={appendCompany}
                                                            value={appendCompany}
                                                            onChange={(e)=>onCheckAppend(e.target.checked, 'appendCompany')}
                                                        />}
                                                        label={getLocalizedStrings().label.MY_INTEGRATION.APPEND_COMPANY}//"Synchronize Emails"
                                                        labelposition="right"
                                                        labelstyle={{ fontWeight: 'normal' }}

                                                    />
                                                </div>
                                                <div>
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            color="default"
                                                            checked={appendContact}
                                                            value={appendContact}
                                                            onChange={(e)=>onCheckAppend(e.target.checked, 'appendContact')}
                                                        />}
                                                        label={getLocalizedStrings().label.MY_INTEGRATION.APPEND_CONTACT}//"Synchronize Emails"
                                                        labelposition="right"
                                                        labelstyle={{ fontWeight: 'normal' }}

                                                    />

                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        }
                        <div style={{ textAlign: 'right', display: 'table', width: '100%' }}>
                            <div style={{ display: 'inline-block' }}>
                                <Button
                                    key='save'
                                    onClick={()=>saveIntegrationSetup()}

                                    style={{ ...styles.primaryButton, marginLeft: '6px', marginTop: '1.5%', marginRight: '0px' }}
                                >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                            </div>
                            {syncCalendar &&
                                <div style={{ display: 'inline-block' }}>
                                    <Button
                                        key='sync'
                                        onClick={()=>syncNow()}

                                        style={{ ...styles.primaryButton, marginLeft: '6px', marginTop: '1.5%', marginRight: '0px' }}
                                    >{getLocalizedStrings().label.COMMON.SYNC_NOW}</Button>
                                </div>
                            }
                            <div style={{ display: 'inline-block' }}>
                                <Button
                                    key='disconnect'
                                    onClick={(e)=>disconnectFromAurinko(e, 'gmail')}

                                    style={{ ...styles.secondaryButton, marginLeft: '6px', marginTop: '1.5%', marginRight: '0px' }}
                                >{getLocalizedStrings().label.COMMON.DISCONNECT}</Button>
                            </div>
                        </div>
                    </div>
                }

            </div>
        );

        return arr;
    }

    const changeIntegration = (value) => {
        setIntegrationOption(value);
    }


        let disableGsuite = false;
        let disabledOffice = false;

        if (integrationOption === "Google Workspace" && isValidParam(integrationSetUpObj) &&
            getBooleanParam(integrationSetUpObj.is_token_exist) && integrationSetUpObj.type === 'GoogleSuite') {
            disabledOffice = true;
        }

        if (integrationOption === "Microsoft Office 365" && isValidParam(integrationSetUpObj) &&
            getBooleanParam(integrationSetUpObj.is_token_exist) && integrationSetUpObj.type === 'MSE') {
            disableGsuite = true;
        }

        let height = window.innerHeight - 150;
        let top = (height - 10) / 2;

        return (
            <div style={{ marginLeft: '3%', height: '100%' }} key={integrationOption + "_1"}>
                <div id="header" style={{ borderBottom: '2.0px solid #afafaf5c' }}><span style={{ fontWeight: 'bold', fontSize: '20px' }}>{getLocalizedStrings().label.MY_INTEGRATION.INTEGRATION_HEADER}</span></div>
                {!dataLoaded &&
                    <div style={{ width: '100%', height: height }} key={integrationOption}>
                        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                            </div>
                        </div>
                    </div>
                }
                {dataLoaded &&
                    <div style={{ marginTop: '10px' }} key={integrationOption}>
                        <FormControl component="fieldset">
                            <RadioGroup name="serviceradio" onChange={(e)=>changeIntegration(e.target.value)} defaultValue={integrationOption} style={{ marginTop: '10px', fontSize: '15px' }} >
                                <FormControlLabel value="Microsoft Office 365" control={<Radio color="default" disabled={disabledOffice} />} label="Microsoft Office 365" />
                                <FormControlLabel value="Google Workspace" control={<Radio color="default" disabled={disableGsuite} />} label="Google Workspace" />
                            </RadioGroup>
                        </FormControl>

                        <div>
                            {getSetupSection()}
                        </div>
                    </div>
                }
            </div>);

};

export default AurinkoIntegration;