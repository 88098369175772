import React from 'react';
import { getLinkChildInfo } from '../../../services/actions/detailViewActions';
import { isValidParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import PropTypes from 'prop-types';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { updateTabInfo, getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const GroupDetailViewMenu =({id,listviewCallBack,selectedMenu,isDetailviewRefresh })=> {
    const dispatch = useDispatch();
    const [linkchildInfo, setLinkchildInfo] = useState(null);
    const [mounted, setMounted] = useState(false);
    const[changeLog,setchangeLog]=useState({linkchildInfo:null})

    useEffect(() => {
        var objParam = {};
        objParam.recordId = id;
        getTempLinkChildInfo(objParam);
      }, [id,isDetailviewRefresh]);
  

    const getTempLinkChildInfo = (objParam) => {
        let promise = Promise.resolve(getLinkChildInfo(objParam));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    setMounted(true);
                    setLinkchildInfo(response);
                    let log = changeLog;
                    log.linkchildInfo = response;
                    setchangeLog({...log});

                }
            });
        }

    }

    const generateMenu = (linkchildInfo) => {
        if (linkchildInfo !== null) {
            let arr = [];
            let objectArr = linkchildInfo.linkChildInfo;
            let tempselectedMenu = selectedMenu;
            if (tempselectedMenu === 'Details') {
                arr.push(<div style={{ color: '#039be5', fontSize: '14px', height: '25px', paddingTop: '5px' }} onClick={() => callBackListview('Details')}>{getLocalizedStrings().label.GROUP.DETAILS}</div>);
            } else {
                arr.push(<div style={{ color: '#717171', fontSize: '14px', height: '25px', paddingTop: '5px' }} onClick={() => callBackListview('Details')}>{getLocalizedStrings().label.GROUP.DETAILS}</div>);
            }
            if (objectArr !== null && objectArr.length > 0) {
                objectArr.forEach((obj, i) => {
                    let objTitle = obj.title;
                    if (isValidParam(getLocalizedStrings().label.HEADER[obj.title])) {
                        objTitle = getLocalizedStrings().label.HEADER[obj.title];
                    }
                    if (tempselectedMenu === obj.objectName) {
                        arr.push(<div style={{ height: '25px', paddingTop: '5px' }} title={obj.title + "(" + obj.totalCount + ")"} onClick={() => callBackListview(obj.objectName)}><div style={{ color: '#039be5', fontSize: '14px', maxWidth: '60%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', float: 'left' }}>{objTitle}</div> <div style={{ color: '#039be5', fontSize: '14px', maxWidth: '40%', overflow: 'hidden', textOverflow: 'ellipsis', float: 'left' }}> &nbsp;({obj.totalCount})</div></div>);
                    } else {
                        arr.push(<div style={{ height: '25px', paddingTop: '5px' }} title={obj.title + "(" + obj.totalCount + ")"} onClick={() => callBackListview(obj.objectName)}><div style={{ color: '#717171', fontSize: '14px', maxWidth: '60%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', float: 'left' }}>{objTitle}</div> <div style={{ color: '#717171', fontSize: '14px', maxWidth: '40%', overflow: 'hidden', textOverflow: 'ellipsis', float: 'left' }}> &nbsp;({obj.totalCount})</div></div>);
                    }

                });
            }

            return arr;
        }
    }

    const callBackListview = (childObj) => {
        let tabinfo = getObjectParam(getActiveTabInfo());
        delete tabinfo.selectedPageNo;
        delete tabinfo.startIndex;
        updateTabInfo(tabinfo);
        listviewCallBack(childObj);
    }
        if (mounted) {
            return (
                <div className='group_menu'>

                    {generateMenu(changeLog.linkchildInfo)}

                </div>);
        } else {
            let contentHeight = window.innerHeight - 240;
            let top = (contentHeight - 10) / 2;
            return (
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>);
        }

}

export default GroupDetailViewMenu;

GroupDetailViewMenu.propTypes = {
    id: PropTypes.number,
    listviewCallBack: PropTypes.func,
    selectedMenu: PropTypes.string,
    isDetailviewRefresh: PropTypes.bool
};
