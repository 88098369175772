import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReactDOM from "react-dom/client";
import App from "./app/scripts/containers/App";
import reportWebVitals from "./appPerformance/reportWebVitals";
import store from "./services/store";
import { Provider } from "react-redux";
import { registerLicense } from "@syncfusion/ej2-base";
import { StyledEngineProvider, createTheme, ThemeProvider } from "@mui/material/styles";
import { HashRouter } from "react-router-dom";
import SfTopUpDialog from "./app/sfPlugins/sfTopUpDialog";
import { useDispatch, useSelector } from "react-redux";

registerLicense("ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5XdEJiWH9Yc3dVRWVc");

const theme = createTheme({
	typography: {
		fontFamily: [
			'-apple-system',
			'Roboto',
			'Helvetica',
			'Arial',
			'sans-serif',
		].join(','),

		root: {
			fontSize: 11,
		},
		button: {
			fontSize: 14, fontWeight: 400
		},
	},
	components: {
		MuiFormControlLabel: {
		  styleOverrides: {
			label: {
				fontSize: 14.5,
				fontWeight: 400,
			},
		  },
		},
	  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<Provider store={store}>
		<DndProvider debugMode={true} backend={HTML5Backend}>
			<ThemeProvider theme={theme}>
				<StyledEngineProvider injectFirst>
					<HashRouter>
						<App />
						<SfTopUpDialog />
					</HashRouter>
				</StyledEngineProvider>
			</ThemeProvider>
		</DndProvider>
	</Provider>
);
reportWebVitals();
