import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { styles } from '../../../services/constants/styles';
import { isValidParam, getBooleanParam, getStringParam } from '../../../services/helper/parameterVerifier';
import { getPhoneIntegrationSetup, saveRingCentralSetUp } from '../../../services/actions/UserListAction';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import ShowCircularProgress from '../components/circularProgress';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getActiveTab, TYPE_LIST_VIEW, TYPE_DETAIL_VIEW } from '../../../services/helper/sfTabManager';
import { refreshDetailViewData } from '../../../services/actions/detailViewActions';
import { refreshListView } from '../../../services/actions/listViewAcions';
import { constants } from '../../../services/constants/constants';

 const RingCentralSetup = () => {


    const [dataLoaded,setDataLoaded] = useState(false);
    const [ringcentralToggle, setRingcentralToggle] = useState(false);

    const dispatch = useDispatch();

    useEffect(()=>{
        getPhoneIntegrationSetupData();
    },[]);
        
    const getPhoneIntegrationSetupData = () => {
        try {
            let promise = getPhoneIntegrationSetup();
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (response.hasOwnProperty('is_ring_central')) {
                           
                            setRingcentralToggle(getBooleanParam(response.is_ring_central));
                            setDataLoaded(true);
                        } else {
                            
                            setRingcentralToggle(false);
                            setDataLoaded(true);
                        }
                    }
                });
            }

        } catch (error) {
            console.log(error.message);
        }
    }
    
   const onChangeRingCentralToggle = (event, isInputChecked) => {
        try {
            setRingcentralToggle(isInputChecked);
            let param = { is_ring_central: isInputChecked };
            let promise = saveRingCentralSetUp(param);
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (response.hasOwnProperty('data') && response.data.hasOwnProperty('is_saved') && response.data.is_saved) {
                            let activeTab = getActiveTab();
                            let object = getStringParam(activeTab.object);
                            if (activeTab.type === TYPE_LIST_VIEW && (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT)) {
                                refreshListView(object);
                            } else if (activeTab.type === TYPE_DETAIL_VIEW && (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT)) {
                                dispatch(refreshDetailViewData(true));
                            }
                        }
                    }
                });
            }

        } catch (error) {
            console.error("Error in 'ringCentralSetup.js -> onChangeRingCentralToggle()':" + error);
        }
    }
        let pageHeight = window.innerHeight - 247;
        let top = (pageHeight-10) / 2;
        return (
            <div style={{ }}>                
                {dataLoaded ? <div style={{ display:"flex" }}>
                <span style={{  paddingTop: 11 }}>{getLocalizedStrings().label.PROFILE_DETAILS.OFF}</span>
                        <FormControlLabel
                            control={<Switch checked={ringcentralToggle} value={ringcentralToggle} onChange={(event, isInputChecked)=>onChangeRingCentralToggle(event, isInputChecked)} color="default"name="isTextReply"   />}
                            style={{  paddingTop: 1, margin:"0px" }}
                        />
                         <span style={{  paddingTop: 11 }}>{getLocalizedStrings().label.PROFILE_DETAILS.ON}</span>
                   
                </div> : <div style={{ width: '100%', height: pageHeight }}>
                            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                </div>
                            </div>
                       </div>}
            </div>);
    
}

export default RingCentralSetup;


