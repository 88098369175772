import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as HTTPClient from '../../../services/helper/httpClient';
import { Link } from 'react-router-dom';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { constants, EVENT_TYPE_KEYS } from '../../../services/constants/constants';
import { endPoints } from '../../../services/constants/endPoints';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import { setMarketingResults } from '../../../services/actions/marketingReportAction';
import { addTab, TYPE_LIST_VIEW } from '../../../services/helper/sfTabManager';
import { getNodesByActionName } from '../../../services/helper/automationUtils';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import PopOver from './PopOver';


const AutomationSMSCampaignReport = ({ data, closeDrawer }) => {
    const dispatch = useDispatch();

    const [arrReportDetail, setArrReportDetail] = useState([]);
    const [gblStatIdSubArr, setGblStatIdSubArr] = useState([]);

    useEffect(() => {
        if (isValidParam(data)) {
            if (data.hasOwnProperty('campaignId')) {
                fetchMarketingReport(data);
            }
        }
    }, []);


    const fetchMarketingReport = (data) => {
        try {
            let campaignId = data.campaignId;
            let params = {
                campaign_id: campaignId,
                report_type: 'SMS Campaign Report',
            }

            const promise = Promise.resolve(HTTPClient.get(endPoints.TOUCH_CAMPAIGNS.REPORT_LIST, params));
            promise.then((response) => {
                console.log("response", response)

                let arrReportDetail = [];
                let marketingResults = [];
                const tempGblStatIdSubArr = [...gblStatIdSubArr];
                if (isValidParam(response)) {
                    if (response.hasOwnProperty('marketing_results')) {
                        marketingResults = response['marketing_results'];
                        if (marketingResults !== null && marketingResults !== undefined && marketingResults.length > 0) {
                            marketingResults.forEach((resultObject) => {
                                let reportDetail = generateReport(resultObject, marketingResults);
                                arrReportDetail.push(reportDetail);
                            });
                        } else {
                            arrReportDetail.push(<div
                                className="data_less">{getLocalizedStrings().message.AUTOMATION_DESIGNER.NO_SMS_CAMPAIGN_FOUND}</div>);
                        }
                    } else {
                        arrReportDetail.push(<div
                            className="data_less">{getLocalizedStrings().message.AUTOMATION_DESIGNER.NO_SMS_CAMPAIGN_FOUND}</div>);
                    }
                } else {
                    arrReportDetail.push(<div
                        className="data_less">{getLocalizedStrings().message.AUTOMATION_DESIGNER.NO_SMS_CAMPAIGN_FOUND}</div>);
                }
                setArrReportDetail(arrReportDetail);
                setGblStatIdSubArr(tempGblStatIdSubArr);

            });
        } catch (error) {
            console.error("Error in 'AutoResponderReport.js -> fetchMarketingReport()':" + error);
        }
    }

    const generateReport = (resultObject, marketingResults) => {
        let msg_name = null;
        let sent = 0;
        let delivered = 0;
        let replied = 0;
        let failed = 0;

        if (resultObject.hasOwnProperty('sent')) {
            sent = resultObject.sent;
        }
        if (resultObject.hasOwnProperty('delivered')) {
            delivered = resultObject.delivered;
        }
        if (resultObject.hasOwnProperty('replied')) {
            replied = resultObject.replied;
        }
        if (resultObject.hasOwnProperty('failed')) {
            failed = resultObject.failed;
        }

        if (resultObject.hasOwnProperty('msg_name')) {
            msg_name = resultObject.msg_name;
            /*let msg_name_split = msg_name.split("-");
            if (msg_name_split.length === 2 && msg_name_split[0] === 'Message') {
                msg_name = getLocalizedStrings().label.AUTOMATION_DESIGNER.MESSAGE + "-" + msg_name_split[1];
            }*/
        } else if (resultObject.hasOwnProperty('campaign_name')) {
            msg_name = resultObject.campaign_name;
        }

        return (
            <div>
                <div style={{ fontSize: '19px', paddingBottom: '30px', paddingTop: '10px',paddingLeft:"20px" }}>{msg_name}</div>
                <div className="report_box_area" style={{ paddingBottom: '9%',paddingLeft:"20px"}}>
                    <div className="report_box" style={{ width: '30%' }}>
                        {/* {getLocalizedStrings().label.CAMPAIGNS.SENT} <br /> */}
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                            title={getLocalizedStrings().label.CAMPAIGNS.SENT}>{getLocalizedStrings().label.CAMPAIGNS.SENT}</div>
                        {
                            sent > 0 &&
                            <Link to="#"
                            //   onClick={() => drillDownMarketingReport(resultObject.id, 'Sent', constants.AUTO_RESPONDER_REPORT_TYPE, marketingResults)}
                            >
                                <span style={{ fontSize: 16, color: '#F04B24' }}>{sent}</span>
                            </Link>
                        }
                        {
                            sent === 0 &&
                            <span style={{ fontSize: 16, color: '#F04B24' }}>{sent}</span>
                        }
                        <br />
                        {sent !== 0 && <span>&nbsp;</span>}
                    </div>
                    <div className="report_gap" />

                    <div className="report_box" style={{ width: '30%' }}>
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                            title='delivered'>Delivered</div>
                        {
                            delivered > 0 &&
                            <Link to="#"
                            //   onClick={() => drillDownMarketingReport(resultObject.id, 'Open', constants.AUTO_RESPONDER_REPORT_TYPE, marketingResults)}
                            >
                                <span style={{ fontSize: 16, color: '#F04B24' }}>{delivered}</span>
                            </Link>
                        }
                        {
                            delivered === 0 && <span style={{ fontSize: 16, color: '#F04B24' }}>{delivered}</span>
                        }
                        <br />
                        {getPercentage(delivered, sent)}
                    </div>
                    <div className="report_gap" />

                    {/* <div className="report_box" style={{ width: '20%' }}>
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                            title='Replied'>Replied</div>
                        {
                            replied > 0 &&
                            <Link to="#"
                            //   onClick={() => drillDownMarketingReport(resultObject.id, 'Unopened', constants.AUTO_RESPONDER_REPORT_TYPE, marketingResults)}
                            >
                                <span style={{ fontSize: 16, color: '#F04B24' }}>{replied}</span>
                            </Link>
                        }
                        {
                            replied === 0 &&
                            <span style={{ fontSize: 16, color: '#F04B24' }}>{replied}</span>
                        }
                        <br />
                        {getPercentage(replied, sent)}
                    </div>
                    <div className="report_gap" /> */}

                    <div className="report_box" style={{ width: '30%' }}>
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                            title='failed'>Failed</div>
                        {
                            failed > 0 &&
                            <Link to="#"
                            //   onClick={() => drillDownMarketingReport(resultObject.id, 'click', constants.AUTO_RESPONDER_REPORT_TYPE, marketingResults)}
                            >
                                <span style={{ fontSize: 16, color: '#F04B24' }}>{failed}</span>
                            </Link>
                        }
                        {
                            failed === 0 &&
                            <span style={{ fontSize: 16, color: '#F04B24' }}>{failed}</span>
                        }
                        <br />
                        {getPercentage(failed, sent)}
                    </div>


                </div>
            </div>
        );

    }

    const getPercentage = (value, sent_value) => {
        let percentage_value = 0;
        if (sent_value !== 0) {
            percentage_value = (value / sent_value) * 100;
            if (Math.round(percentage_value) !== percentage_value) {
                percentage_value = percentage_value.toFixed(2);
            }
            return percentage_value + '%';
        }
    }



    let pageHeight = window.innerHeight - 100;
    let top = (pageHeight - 10) / 2
    return (
        <div style={{ paddingLeft: '25px', paddingTop: '10px', height: '100%' }}>

            {
                (arrReportDetail !== null && arrReportDetail.length > 0) &&
                arrReportDetail
            }

            {
                (arrReportDetail === null || arrReportDetail.length === 0) &&
                <div className="loaderh">
                    {<div style={{ width: '100%', height: pageHeight }}>
                        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                            </div>
                        </div>
                    </div>}
                </div>
            }
        </div>
    );




}
export default AutomationSMSCampaignReport;
