
import React from 'react';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { getStringParam, getObjectParam, isValidParam, getIntParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import Button from '@mui/material/Button';
import { LinkToType } from '../../../services/constants/link';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import * as sfDialogs from '../components/sfDialogs';
import { hasAccessPermission, getSelectedObjectLabel, getObjectLabelByObject } from '../../../services/helper/common';
import { getActiveTab, getActiveTabInfo } from '../../../services/helper/sfTabManager';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, TextField } from '@mui/material';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const SelectUnitDialog =()=> {
    
	const dispatch = useDispatch();
	const [selectedUnit, setselectedUnit] = useState(0);
	const appContainer = useSelector((state) => state.appContainer);

    const handleChange = (event, value) => {
        setselectedUnit(value.value);

    }

    const nextForm = () => {
        if(selectedUnit > 0 ){
            addNewRecord(constants.NEW);
            getAppDialog(false, null, null, null, null, null);
        }else{
            dispatch(showCustomSnackBar("Please select a Unit", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
        

    }
    const addNewRecord = (value) => {
        let data = {};
        let labelName = null;
        let objectName = null;
        let linkTo = null;
        let isOpenDrawer = true;
        let hasPermission = false;
        let filter = null;
        try {
            objectName = appContainer.dialogProps.data.object;
            value = getStringParam(value);
            hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_CREATE);
            let activeTab = getObjectParam(getActiveTab());
            if (hasPermission) {
                if (value === constants.NEW) {
                    let itemLabel = null;
                   
                    itemLabel = getObjectLabelByObject(constants.ACCOUNTS_OBJECT);

                    if (itemLabel === null) {
                        itemLabel = getSelectedObjectLabel();
                    }
                    labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + itemLabel;
                    data.mode = constants.SF_FORM_OPEN_MODE_QUICK;
                    linkTo = LinkToType.TYPE_NEW;
                }
                if (isOpenDrawer) {
                    data.object = objectName;
                    if (objectName === constants.ACCOUNTS_OBJECT || objectName === constants.CONTACTS_OBJECT) {
                        let activeTabInfo = getObjectParam(getActiveTabInfo());
                        filter = getObjectParam(activeTabInfo.filter);
                        let filterId = getIntParam(filter.id);
                        let type = getStringParam(filter.type);
                        if (type === 'group') {
                            data.parent_object = constants.GROUP_OBJECT;
                            data.parent_record_id = filterId;
                        }
                    }
                    let activeTabInfo = getObjectParam(activeTab.info);
                    if (activeTabInfo.hasOwnProperty('isUnitsListView') && activeTabInfo.isUnitsListView && objectName === constants.ACCOUNTS_OBJECT) {
                        data.isUnitForm = true;
                        data.unitProjectId = selectedUnit;
                    }
                    getAppDrawer(true, labelName, value, data, null);
                }
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
            }
        } catch (error) {
            console.error("Error in 'noRecord.js -> addNewRecord()':" + error);
        }
    };
        let data = appContainer.dialogProps.data;
        return (
                <div>
                    <div>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            {isValidParam(data) && isValidParam(data.unitsList) && data.unitsList.length > 0 && <Autocomplete
                                defaultValue={data.unitsList.find(v => v.value === selectedUnit)}
                                options={data.unitsList}
                                onChange={(event,value)=>handleChange(event,value)}
                                getOptionLabel={(option) => option.label}
                                renderOption={(option) => {
                                    return (
                                        <div title={option.label}>
                                            {option.label}
                                        </div>
                                    );
                                }}
                                disableClearable={false}
                                renderInput={(params) => <TextField {...params} label={'Select Unit'} variant="outlined" margin="dense" size='small' className={"sf-fields-bg"} />}
                            />
                            }
                        </FormControl>
                    </div>
                    <div style={{ width: 'auto', padding: '0px', marginTop: '10px' }}>
                        <Button
                            key='Generate API Key'
                            onClick={() => nextForm()}
                            style={{ ...styles.primaryButton, marginRight: '12px', verticalAlign: 'top' }}
                        >{'Next'}</Button>
                    </div>
                </div>
           
        );
}

export default SelectUnitDialog;