import React from "react";
import PropTypes from 'prop-types';
import { isValidParam, getStringParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import filter from 'lodash/filter';
import { Popover, TextField, MenuItem, Checkbox, Paper } from '@mui/material';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      change: true,
      selectSfCalendarPopupIsOpen: false,
      calendarAanchorEl: null,
      options: this.props.options,
      searchString: '',
      searchFieldView: false,
      leftCheckPosition: true,
      rightCheckPosition: false,
      defaultSelected: []

    };
  }
  componentDidMount() {
    let _state = this.state;
    let props = this.props;
    let checkPosition = isValidParam(props.checkPosition) ? props.checkPosition : false;
    let value = isValidParam(props.value) ? props.value : [];
    let showAutocompleteThreshold = isValidParam(props.showAutocompleteThreshold) ? props.showAutocompleteThreshold : 10;
    _state.defaultSelected = value;
    let options = props.options;
    this.selectedDataManipulation(value, options);
    if (checkPosition === 'right') {
      _state.leftCheckPosition = false;
      _state.rightCheckPosition = true;
    }
    else if (checkPosition === 'left') {
      _state.leftCheckPosition = true;
      _state.rightCheckPosition = false;
    }
    else if (!checkPosition) {
      _state.leftCheckPosition = false;
      _state.rightCheckPosition = false;
    }
    if (showAutocompleteThreshold < props.options.length) {
      _state.searchFieldView = true;
    }
    if (showAutocompleteThreshold === 0) {
      _state.searchFieldView = false;
    }
    this.setState(_state);
  }
  UNSAFE_componentWillReceiveProps(props) {
    let _state = this.state;
    let value = isValidParam(props.value) ? props.value : [];
    let options = props.options;
    if (_state.defaultSelected !== value) {
      _state.defaultSelected = value;
    }
    if (_state.options !== options) {
      _state.options = options;
    }
    if ((this.state.options === options) || (this.state.defaultSelected === value)) {
      this.setState(_state);
      this.selectedDataManipulation(value, options);
    }
  }
  selectedDataManipulation(selectedArr, options) {
    let _state = this.state;
    let tempOption = [];
    options.forEach((option, index) => {
      let tempOptionObj = {};
      tempOptionObj.label = option.label;
      tempOptionObj.value = option.value;
      tempOptionObj.color = option.color;
      tempOptionObj.selected = false;
      if (selectedArr.length > 0) {
        for (let i = 0; i < selectedArr.length; i++) {
          if (selectedArr[i].value === option.value) {
            tempOptionObj.selected = true;
          }
        }
      }
      tempOption.push(tempOptionObj);
    })
    _state.options = tempOption;
    this.setState(_state)
  }
  selectSfCalendarPopup = (event, data) => {
    if (data === "sfmodal") {
      this.setState({ selectSfCalendarPopupIsOpen: true, calendarAanchorEl: event.currentTarget })
    }
  }
  selectSfCalendarPopupClose = (event) => {
    let onCloseAction = isValidParam(this.props.onClose) ? true : false;
    this.setState({ selectSfCalendarPopupIsOpen: false, calendarAanchorEl: null })
    if (onCloseAction) {
      this.props.onClose();
    }
  }
  SearchForObjectsWithName = (arrayOfVariable, sortValue) => {
    let ansArray = [];
    arrayOfVariable.map((students) => {
      let toAdd = false
      Object.values(students).forEach((val) => {
        if (val !== undefined && val !== null) {
          let mainString = val.toString().replace(/\s/g, '').toLowerCase()
          let subString = sortValue.replace(/\s/g, '').toLowerCase()
          if (mainString.includes(subString)) {
            toAdd = true
          }
        }
      })
      if (toAdd) {
        ansArray.push(students)
      }
    })
    return (ansArray);
  }
  handleMenuItemClick = (event, val, name) => {
    let multiple = isValidParam(this.props.multiple) ? getBooleanParam(this.props.multiple) : false;
    let onSelectAction = isValidParam(this.props.onSelect) ? true : false;
    let onChangeAction = isValidParam(this.props.onChange) ? true : false;
    let tempVal = this.state.defaultSelected;
    if (multiple) {
      if (val.selected) {
        let dataFilter = filter(tempVal, function (userobj, i) {
          return userobj.value !== val.value;
        });
        tempVal = dataFilter;
      } else if (!val.selected) {
        let obj = {};
        obj.value = val.value;
        obj.label = val.label;
        tempVal.push(obj);
      }
    } else {
      if (val.selected) {
        let singleValueArr = [];
        tempVal = singleValueArr

      } else {
        let singleValueArr = [];
        let obj = {};
        obj.value = val.value;
        obj.label = val.label;
        singleValueArr.push(obj);
        tempVal = singleValueArr
      }

    }
    this.selectedDataManipulation(tempVal, this.state.options);
    let _state = this.state;
    _state.defaultSelected = tempVal;
    this.setState(_state);
    if (onChangeAction) {
      this.props.onChange(tempVal);
    }
    if (onSelectAction) {
      this.props.onSelect(tempVal, name, val);
    }
    event.preventDefault();
  }
  getButtonLabel = () => {
    let vfieldLabel = '';
    let length = this.state.defaultSelected.length
    let defaultLabel = isValidParam(this.props.defaultLabel) ? this.props.defaultLabel : 'Please Select';
    if (length > 0) {
      for (let j = 0; j < length; j++) {
        let val = this.state.defaultSelected[j].label
        if (j !== (length - 1)) {
          if (val !== null && val !== "" && val !== undefined) {

            vfieldLabel += val + ", ";
          }
        } else {
          if (val !== null && val !== "" && val !== undefined) {
            vfieldLabel += val;
          }
        }
      }
    } else {
      vfieldLabel = defaultLabel
    }

    return vfieldLabel
  }
  render() {
    var { options, searchString, searchFieldView, leftCheckPosition, rightCheckPosition } = this.state
    this.dataSearchList = options;
    if (searchString.replace(/\s/g, '') !== '') {
      this.dataSearchList = this.SearchForObjectsWithName(this.dataSearchList, searchString)
    }
    let colorBox = isValidParam(this.props.colorBox) ? getBooleanParam(this.props.colorBox) : false;
    let colorStyle = isValidParam(this.props.colorStyle) ? getStringParam(this.props.colorStyle) : { width: 17, height: 17 };
    let width = isValidParam(this.props.width) ? { width: this.props.width } : { width: 200 };
    let height = isValidParam(this.props.height) ? { height: this.props.height } : { height: 30 };
    let buttonStyle = isValidParam(this.props.buttonStyle) ? (this.props.buttonStyle) : { cursor: 'pointer', color: 'rgb(97, 97, 97)', outline: 'none', float: 'right', backgroundColor: 'rgb(255, 255, 255)', border: '1px solid #717171;', padding: '1px 8px', borderRadius: 3 };
    let className = isValidParam(this.props.className) || getStringParam(this.props.className) !== "" ? getStringParam(this.props.className) : 'sf-super-select-field';
    let buttonOuterLabel = isValidParam(this.props.buttonOuterLabel) ? getStringParam(this.props.buttonOuterLabel) : null;
    let defaultLabelstyle = { backgroundColor: 'inherit', lineHeight: '0.1', marginTop: '-2px', padding: '-1px', paddingLeft: 5, paddingRight: 4, fontSize: 13, fontWeight: 500, color: '#717171' };
    if (buttonOuterLabel == null) {
      defaultLabelstyle = { lineHeight: '0.1', marginTop: '-2px', padding: '-1px', paddingLeft: 5, paddingRight: 4, fontSize: 13, fontWeight: 500, color: '#717171' };
    }
    let buttonOuterLabelStyle = isValidParam(this.props.colorStyle) ? getStringParam(this.props.colorStyle) : defaultLabelstyle;
    let id = isValidParam(this.props.id) || getStringParam(this.props.id) !== "" ? getStringParam(this.props.id) : 'sf-super-select-fild';
    let name = isValidParam(this.props.name) || getStringParam(this.props.name) !== "" ? getStringParam(this.props.name) : 'sf-super-select-fild-name';
    let anchorOrigin = isValidParam(this.props.anchorOrigin) ? this.props.anchorOrigin : { vertical: 'top', horizontal: 'left' };
    let transformOrigin = isValidParam(this.props.transformOrigin) ? this.props.transformOrigin : { vertical: 'top', horizontal: 'left' };
    let downArrowStyle = isValidParam(this.props.downArrowStyle) ? this.props.downArrowStyle : { marginTop: '2px' };
    if (document.getElementById('sf-super-select-label') !== null) {
      var mySpan = document.getElementById("sf-super-select-label");

      var spanWidth = mySpan.getBoundingClientRect().width;
      console.log(spanWidth)
    }
    return (
      <div className={className} id={id} name={name}>
        <div onClick={(e) => { this.selectSfCalendarPopup(e, 'sfmodal') }} tabIndex={0} title='' style={{ ...buttonStyle, ...width, ...height, ...{ cursor: 'pointer' } }}>
          <label style={{ ...buttonOuterLabelStyle }}>{buttonOuterLabel}</label>
          <span style={{ display: 'flex', fontSize: '15px' }}>
            <div style={{ ...width, display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={this.getButtonLabel()}>
              <span id='sf-super-select-label'  >{this.getButtonLabel()}</span>
            </div>
            <div style={downArrowStyle}>
              <i className="fas fa-sort-down"></i>
            </div>
          </span>
          <div >
          </div>
        </div>
        <Popover
          open={this.state.selectSfCalendarPopupIsOpen}
          onClose={() => this.selectSfCalendarPopupClose()}
          anchorEl={this.state.calendarAanchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          <div style={{ ...width }}>
            <Paper className='sf-super-select-popover-paper' style={{ padding: '5px' }} variant="outlined" >
              {searchFieldView && <TextField
                style={{ width: '100%' }}
                placeholder={this.props.hintTextAutocomplete}
                onChange={(e) => this.setState({ searchString: e.target.value })}
                id="sf-super-select-basic-input" />}
              <div>
                {this.dataSearchList.map((option, index) => (
                  <MenuItem
                    key={option.value}
                    disabled={option.disabled}
                    selected={option.selected}
                    onClick={(event) => this.handleMenuItemClick(event, option, name)}
                  >
                    {leftCheckPosition &&
                      <Checkbox
                        color="default"
                        checked={option.selected}
                        edge="start"
                        disableRipple
                      />}
                    {colorBox && <span style={{ ...colorStyle, margin: '0px 8px 0px 0px', backgroundColor: option.color }}></span>}
                    <div> {option.label}</div>

                    {rightCheckPosition &&
                      <div style={{ paddingLeft: '10px' }}>
                        <Checkbox
                          color="default"
                          checked={option.selected}
                          edge="start"
                          disableRipple
                        /></div>}
                  </MenuItem>
                ))}
              </div>
            </Paper>
          </div>
        </Popover>
      </div>
    );
  }
}

App.propTypes = {
  multiple: PropTypes.bool,
  defaultLabel: PropTypes.string,
  options: PropTypes.array
};
export default App;