import React from 'react';
import Button from '@mui/material/Button';
import {constants} from '../../../services/constants/constants';
import {isValidParam} from '../../../services/helper/parameterVerifier';
import {styles} from '../../../services/constants/styles';
import {getLocalizedStrings} from '../../../services/constants/MultiLingual';
import {useDispatch} from "react-redux";
import {getAppDialog} from "../../../services/actions/appContainerActions";

const AlertDialog = (props) => {
	const dispatch = useDispatch();

	function closeDialog() {
		dispatch(getAppDialog(false, constants.ALERT_DIALOG, null, null, null, null));

		let data = props.data;
		let fieldId = null;
		if (isValidParam(data)) {
			if (data.hasOwnProperty('fieldId')) {
				fieldId = data.fieldId;
				if (isValidParam(fieldId)) {
					if (isValidParam(document.getElementById(fieldId))) {
						document.getElementById(fieldId).focus();
					}
				}
			}
		}
	}

	let data = props.data;
	let message = null;
	if (isValidParam(data)) {
		if (data.hasOwnProperty('message')) {
			message = data.message;
		}
	}
	return (
		<div>
			<div>
				{message}
			</div>

			<div style={{paddingTop: '10px',float:'right', left:0,alignItems:'right',justifyContent:'right'}}>
				<Button
					primary={true}
					style={styles.primaryButton}
					onClick={closeDialog}
				>{getLocalizedStrings().label.COMMON.OK}</Button>
			</div>
		</div>
	);
}

export default AlertDialog;