import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CardsContainer from './CardsContainer';
import CustomDragLayer from './CustomDragLayer';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import * as ListsActions from '../../../services/actions/listsActions';
import '../../../asset/styles/styl/kanban.styl';
import { styles } from '../../../services/constants/styles';
import { setHeader } from '../../../services/actions/headerActions';
import { isValidParam, getObjectParam, getStringParam } from '../../../services/helper/parameterVerifier';
import ShowCircularProgress from '../components/circularProgress';
import { getActiveTabInfo, getActiveTab, TYPE_LIST_VIEW, addTab, getTabsByItem, updateCurrentTabSet } from '../../../services/helper/sfTabManager';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { sortArrayOfString, sortArrayObjectByProperty } from '../../../services/helper/utils';
import { constants } from '../../../services/constants/constants';
import { setListViewMounted } from '../../../services/actions/listViewAcions';
import { updateColumnViewHorizontalScrollPosition, updateColumnViewScrollPosition } from '../../../services/actions/listsActions';
import { getObjectLabelByObject } from '../../../services/helper/common';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
function mapStateToProps(state) {
    return {
        lists: state.lists,
        isFetching: state.lists.isFetching,
        app: state.app,
        userList: state.lists.userList,
        wokflowList: state.lists.wokflowList,
        totalForecast: state.lists.totalForecast,
        selectedHeader: state.header.selectedHeader,
        header: state.header,
        queueList: state.queueList,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(ListsActions, dispatch)
        ,
        setHeader: (selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader) => {
            dispatch(setHeader(selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader));
        },
        updateColumnViewScrollPosition: (pos, obj) => {
            dispatch(updateColumnViewScrollPosition(pos, obj));
        },
        updateColumnViewHorizontalScrollPosition: (pos, obj) => {
            dispatch(updateColumnViewHorizontalScrollPosition(pos, obj));
        },
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
    }
}

//@DragDropContext(HTML5Backend)
class KanbanView extends React.Component {
    static propTypes = {
        getLists: PropTypes.func.isRequired,
        moveCard: PropTypes.func.isRequired,
        moveList: PropTypes.func.isRequired,
        lists: PropTypes.array.isRequired,
    }

    constructor(props) {
        super(props);

        this.moveCard = this.moveCard.bind(this);
        this.moveList = this.moveList.bind(this);
        this.scrollRight = this.scrollRight.bind(this);
        this.scrollLeft = this.scrollLeft.bind(this);
        this.stopScrolling = this.stopScrolling.bind(this);
        this.startScrolling = this.startScrolling.bind(this);
        this.state = {
            isScrolling: false, currentView: null,
            workflowId: '', workflowMenuItemList: [],
            userName: '',
            userList: [],
            duration: '',
            durationList: [
                {
                    label: getLocalizedStrings().label.DASHBOARD.LAST_MONTH,
                    value: 3
                },
                {
                    label: getLocalizedStrings().label.DASHBOARD.LAST_QUARTER,
                    value: 12
                },
                {
                    label: getLocalizedStrings().label.DASHBOARD.LAST_YEAR,
                    value: 13
                },
                {
                    label: getLocalizedStrings().label.DASHBOARD.NEXT_MONTH,
                    value: 5
                },
                {
                    label: getLocalizedStrings().label.DASHBOARD.NEXT_QUARTER,
                    value: 10
                },
                {
                    label: getLocalizedStrings().label.DASHBOARD.NEXT_THREE_MONTHS,
                    value: 7
                },
                {
                    label: getLocalizedStrings().label.DASHBOARD.NEXT_TWO_MONTHS,
                    value: 6
                },

                {
                    label: getLocalizedStrings().label.DASHBOARD.THIS_MONTH,
                    value: 4
                },
                {
                    label: getLocalizedStrings().label.DASHBOARD.THIS_QUARTER,
                    value: 9
                },
                {
                    label: getLocalizedStrings().label.DASHBOARD.THIS_YEAR,
                    value: 11
                },
            ],
            reportBy: 'month',
            reportByList: [],
            sortBy: 'forecast_amount',
            sortByList: [],
            managerSalesMap: {},
            durationLabel: "",
            showWorkflowList: false,
            openUserList: false,
            query_name: '',
            query_type: '',
            query_id: 0,
            workflowList: [],
            mounted: false
        };
    }

    componentDidMount() {
        let activeTab = getObjectParam(getActiveTab());
        this.loadDataForKanban(activeTab);
    }
    loadDataForKanban = (activeTab) => {
        // let activeTab = getObjectParam(getActiveTab());
        let _state = this.state;
        let info = getObjectParam(getActiveTabInfo());
        if (!info.hasOwnProperty('duration') && this.props.hasOwnProperty('duration')) {
            info.duration = this.props.duration;
            info.workflowId = null;
        }
        if (activeTab.hasOwnProperty('info')) {
            let tabInfo = activeTab.info;
            if (tabInfo.hasOwnProperty('filter')) {
                let filter = tabInfo.filter;
                _state.query_name = filter.name;
                _state.query_type = filter.type;
                _state.query_id = filter.id;
            }
        }
        let durationValue = info !== null ? info.duration : 0;
        let workflow_id = info !== null && info.forecastWorkflowId !== null && info.forecastWorkflowId !== undefined ? info.forecastWorkflowId : this.state.workflowId;
        let sales_rep = info !== null && info.salesRep !== null && info.salesRep != undefined ? info.salesRep : this.state.userName;
        let report_by = info !== null && info.reportBy !== null && info.reportBy != undefined ? info.reportBy : this.state.reportBy;
        let sort_by = info !== null && info.sortBy !== null && info.sortBy !== undefined ? info.sortBy : this.state.sortBy;

        _state.duration = durationValue;
        _state.userName = sales_rep;
        _state.workflowId = workflow_id;
        _state.reportBy = report_by;
        _state.sortBy = sort_by;

        if (info === null || (info !== null && !(isValidParam(info.workflowId) || isValidParam(info.salesRep)
            || isValidParam(info.reportBy) || isValidParam(info.sortBy)))) {
            this.getUserList(_state);
        } else {

            let response = this.props.userList;
            let list = response !== null ? response.salesValueList : [];
            sortArrayOfString(list);
            let salesRepMap = response !== null ? response.mgrSalesMap : {};
            _state.managerSalesMap = response.mgrSalesMap;

            let sArr = salesRepMap[this.props.app.me.name];
            let resultArr = [];
            _state.userList = this.createUserMenuList(sArr, salesRepMap, this.props.app.me.name, resultArr);

            let wfList = info.forecastWorkflowList;
            if (wfList !== null && wfList.length > 1) {
                _state.showWorkflowList = true;
            }
            _state.workflowList = wfList;
            _state.mounted = true;
            _state.workflowMenuItemList = wfList.map((wf, index) => {
                return <MenuItem key={wf.id} value={wf.id} style={styles.popoverMenuItem} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '142px' }} title={wf.name}>{wf.name}</div>}</MenuItem>
            });

            if (info !== null && (info.actionType === "Edit" || info.actionType === constants.NEW || this.props.lists.view === 'ColumnView')) {
                delete info.actionType;
                this.getForeCastReportData(_state);
            } else {
                this.props.lists.isFetching = true;
            }
        }
        this.setKanbanViewHeader();
        this.setState(_state);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let activeTab = getObjectParam(getActiveTab());
        let queryName = "";
        let filter = {};
        if (activeTab.hasOwnProperty('info')) {
            let tabInfo = activeTab.info;
            if (tabInfo.hasOwnProperty('filter')) {
                filter = tabInfo.filter;
                queryName = filter.name;
            }
        }
        if (activeTab.type === "TYPE_COLUMN_VIEW" && ((this.state.query_name !== queryName) || (this.state.query_name === queryName && nextProps.header.kanban === null && nextProps.header.selectedHeader === null))) {
            this.loadDataForKanban(activeTab);
        }
    }

    componentDidUpdate() {
        let scrollInfo = null;
        if (isValidParam(this.props.lists.HorizontalscrollPosition[this.props.object])) {
            scrollInfo = this.props.lists.HorizontalscrollPosition[this.props.object];
        }
        if (scrollInfo !== null) {
            $('.kanbanBord').scrollLeft(scrollInfo);
        }
    }

    manageScroll = () => {
        let leftPos = $('.kanbanBord').scrollLeft();
        let tempPositionsObj = this.props.lists.HorizontalscrollPosition;
        tempPositionsObj = {
            //...this.props.lists.HorizontalscrollPosition,
            [this.props.object]: leftPos
        }
        this.props.updateColumnViewHorizontalScrollPosition(tempPositionsObj, this.props.object);
    }

    getUserList = (_state) => {
        var promise = Promise.resolve(ListsActions.getSalesRepList());
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    this.props.storeSaleRepList(response);
                    let list = response.salesValueList;
                    sortArrayOfString(list);
                    let salesRepMap = response.mgrSalesMap;
                    _state.managerSalesMap = response.mgrSalesMap;
                    if (isValidParam(salesRepMap)) {
                        let sArr = [];
                        let resultArr = [];
                        let user_name = '';
                        _state.userName = this.props.app.me.name;

                        sArr = salesRepMap[this.props.app.me.name];
                        user_name = this.props.app.me.name;

                        _state.userList = this.createUserMenuList(sArr, salesRepMap, user_name, resultArr);
                    }
                    this.getWorkflowList(_state);
                }
                this.setState(_state);
            });

        }
    }

    createUserMenuList = (sArr, salesRepMap, userName, resultArr) => {
        if (isValidParam(sArr)) {
            sArr = sArr.filter(f => (f !== userName));
            resultArr.push(<MenuItem key={userName} value={userName} style={{ ...styles.popoverMenuItem, paddingLeft: '10px', fontWeight: 'bold' }} >{this.props.app.me.name}</MenuItem>);
            resultArr = this.createInnerOptions(sArr, salesRepMap, userName, resultArr);
        } else {
            let salesRepList = salesRepMap[userName];
            if (isValidParam(salesRepList)) {
                resultArr.push(<MenuItem key={userName} value={userName} style={{ ...styles.popoverMenuItem, paddingLeft: '10px', fontWeight: 'bold' }} >{userName}</MenuItem>);
            } else {
                resultArr.push(<MenuItem key={userName} value={userName} style={{ ...styles.popoverMenuItem, paddingLeft: '10px' }} >{userName}</MenuItem>);
            }
        }
        return resultArr;
    }

    createInnerOptions = (sArr, salesRepMap, userName, resultArr) => {
        if (sArr !== null && sArr.length > 0) {
            let cnt = 0;
            for (var i = 0; i < sArr.length; i++) {
                let obj = null;
                let index = 0;
                let padding = '0px';
                for (var j = 0; j < resultArr.length; j++) {
                    obj = resultArr[j];
                    if (obj.key === userName) {
                        cnt = cnt + 1;
                        index = j;
                        let p = obj.props.style.paddingLeft;
                        p = p.replace('px', '');
                        padding = (parseInt(p) + 20) + 'px';
                    }
                }
                let salesRepList = salesRepMap[sArr[i]];

                if (isValidParam(salesRepList)) {
                    resultArr.splice(index + cnt, 0, <MenuItem key={sArr[i]} value={sArr[i]} style={{ ...styles.popoverMenuItem, paddingLeft: padding, fontWeight: 'bold' }} >{sArr[i]}</MenuItem>);
                } else {
                    resultArr.splice(index + cnt, 0, <MenuItem key={sArr[i]} value={sArr[i]} style={{ ...styles.popoverMenuItem, paddingLeft: padding }} >{sArr[i]}</MenuItem>);
                }

            }

            for (var k = 0; k < sArr.length; k++) {
                let cArr = salesRepMap[sArr[k]];
                if (isValidParam(cArr)) {
                    cArr = cArr.filter(f => (f !== sArr[k]));
                    this.createInnerOptions(cArr, salesRepMap, sArr[k], resultArr, sArr[k])
                }
            }
        }

        return resultArr;
    }

    getWorkflowList = (_state) => {
        var promise = Promise.resolve(ListsActions.getWorkflowList(this.props.object));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    this.props.storeWorkFlowList(response);
                    let list = response;
                    if (list !== null && list.length > 0) {
                        _state.showWorkflowList = true;
                        sortArrayObjectByProperty(list, 'name');
                        let info = getObjectParam(getActiveTabInfo());
                        info.forecastWorkflowList = list;
                    }
                    _state.workflowList = list;
                    _state.mounted = true;
                    _state.workflowMenuItemList = list.map((wf, index) => {
                        if (index === 0) {
                            _state.workflowId = wf.id;
                            let info = getObjectParam(getActiveTabInfo());
                            info.forecastWorkflowId = wf.id;
                        }
                        return <MenuItem key={wf.id} value={wf.id} style={styles.popoverMenuItem} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '142px' }} title={wf.name}>{wf.name}</div>}</MenuItem>

                    });

                    this.getForeCastReportData(_state);
                    this.setState(_state);
                }

            });

        }
    }

    getForeCastReportData = (_state) => {
        let params = {};
        params.workflowId = _state.workflowId;
        params.salesRep = _state.userName;
        params.duration = _state.duration;
        params.reportBy = _state.reportBy;
        params.sortBy = _state.sortBy;

        params.query_name = _state.query_name;
        params.query_type = _state.query_type;
        params.query_id = _state.query_id;

        let info = getObjectParam(getActiveTabInfo());
        info.workflowId = _state.workflowId;
        info.salesRep = _state.userName;
        info.duration = _state.duration;
        info.reportBy = _state.reportBy;
        info.sortBy = _state.sortBy;

        info.query_name = _state.query_name;
        info.query_type = _state.query_type;
        info.query_id = _state.query_id;

        _state.durationList.forEach((d, index) => {
            if (d.value === _state.duration) {
                this.setState({ durationLabel: d.label });
            }
        });
        info.durationLabel = this.state.durationLabel;

        this.props.getForeCastReportData(this.props.object, params);
    }

    updateForecastListView = () => {
        let object = getStringParam(this.props.object);

        let tabs = getTabsByItem(object);
        let tempTabs = tabs.filter(f => { return f.info.hasOwnProperty('isForecast') && f.info.isForecast });
        if (tempTabs.length > 0) {
            let inputParam = {
                click_form: 'Forecast',
                workflow_id: this.state.workflowId,
                sales_rep: this.state.userName,
                duration: this.state.duration
            };

            let tabIndex = tempTabs[0].index;
            let info = tabs[tabIndex].info;
            info.input_param = inputParam;
            tabs[tabIndex].label = this.state.durationLabel + " " + getObjectLabelByObject(object);
            updateCurrentTabSet(object, tabs);
            setListViewMounted(false, object);
        }
    }

    componentWillUnmount() {
        ListsActions.setKanbanFetch(false);
    }

    startScrolling(direction) {
        switch (direction) {
            case 'toLeft':
                this.setState({ isScrolling: true }, this.scrollLeft());
                break;
            case 'toRight':
                this.setState({ isScrolling: true }, this.scrollRight());
                break;
            default:
                break;
        }
    }

    scrollRight() {
        function scroll() {
            let element = document.getElementsByTagName('main')
            if (element && element.length > 0 && element[0].scrollLeft) {
                element[0].scrollLeft += 10;
            }
        }
        this.scrollInterval = setInterval(scroll, 10);
    }

    scrollLeft() {
        function scroll() {
            let element = document.getElementsByTagName('main')
            if (element && element.length > 0 && element[0].scrollLeft) {
                element[0].scrollLeft -= 10;

            }
        }
        this.scrollInterval = setInterval(scroll, 10);
    }

    stopScrolling() {
        this.setState({ isScrolling: false }, clearInterval(this.scrollInterval));
    }

    moveCard(lastX, lastY, nextX, nextY) {
        this.props.moveCard(lastX, lastY, nextX, nextY);
    }

    moveList(listId, nextX) {
        const { lastX } = this.findList(listId);
        this.props.moveList(lastX, nextX);
    }

    findList(id) {
        const { lists } = this.props.lists;
        const list = lists.filter(l => l.id === id)[0];

        return {
            list,
            lastX: lists.indexOf(list)
        };
    }

    setKanbanViewHeader = () => {
        let objectName = "Forecast";
        let kanbanObject = {};
        kanbanObject.currentView = 'kanban';
        kanbanObject.show = true;
        this.props.setHeader(this.props.object, objectName, kanbanObject, true, true);
    }

    onChangeValue = (field, event, index, value) => {
        if (value === undefined) {
            value = event.target.value;
        }
        if (field === 'workflow') {
            this.setState({ workflowId: value });
        } else if (field === 'user') {
            this.setState({ userName: value });
        } else if (field === 'reportBy') {
            this.setState({ reportBy: value });
        } else if (field === 'sortBy') {
            this.setState({ sortBy: value });
        }
    }

    onChangeDuration = (event, index, value) => {
        if (value === undefined) {
            value = event.target.value;
        }
        let durationLabel = '';
        this.state.durationList.forEach((d, index) => {
            if (d.value === value) {
                durationLabel = d.label;
            }
        });


        this.setState({ duration: value, durationLabel: durationLabel, openUserList: false, reportBy: this.state.reportBy });
    }

    refreshKanbanReport = () => {
        this.getForeCastReportData(this.state);
        this.updateListView();
        let info = getObjectParam(getActiveTabInfo());
        info.forecastWorkflowId = this.state.workflowId;
        info.salesRep = this.state.userName;
        info.duration = this.state.duration;
        let leftPos = 0;
        let tempPositionsObj = this.props.lists.HorizontalscrollPosition;
        tempPositionsObj = {
            //...this.props.lists.HorizontalscrollPosition,
            [this.props.object]: leftPos
        }
        this.props.updateColumnViewHorizontalScrollPosition(tempPositionsObj, this.props.object);
    }

    updateListView = () => {
        let object = getStringParam(this.props.object);
        let tabs = getTabsByItem(object);

        let tempTabs = tabs.filter(f => { return f.info.hasOwnProperty('isForecast') && f.info.isForecast });

        if (tempTabs !== null && tempTabs.length > 0) {
            let inputParam = tempTabs[0].info.input_param;

            if (inputParam.duration === this.state.duration) {
                this.updateForecastListView();
            }
        }
    }

    openListView = (object, queryName) => {
        let label = this.state.durationLabel + " " + getObjectLabelByObject(object);
        let _info = getObjectParam(getActiveTabInfo());
        _info.workflowId = this.state.workflowId;
        _info.salesRep = this.state.userName;
        _info.duration = this.state.duration;
        _info.reportBy = this.state.reportBy;
        _info.sortBy = this.state.sortBy;

        this.state.durationList.forEach((d, index) => {
            if (d.value === this.state.duration) {
                this.setState({ durationLabel: d.label });
            }
        });
        _info.durationLabel = this.state.durationLabel;

        this.updateForecastListView();

        let url = '/' + object + '/queries';
        let filter = { id: 556, name: queryName, type: "all" };
        let info = {};
        info.filter = filter;
        info.input_param = { click_form: 'Forecast', workflow_id: this.state.workflowId, sales_rep: this.state.userName, duration: this.state.duration };
        info.isForecast = true;
        let tab = { item: constants.OPPORTUNITIES_OBJECT, label: label, object: object, type: TYPE_LIST_VIEW, url: url };
        tab.info = info;
        addTab(tab, true);


    }


    render() {
        const { lists } = this.props.lists;
        let contentHeight;
        contentHeight = document.body.clientHeight - 255;
        let themeColor = this.props.app.me.background;

        let total_forecast_amount = this.props.totalForecast;

        let durationArr = this.state.durationList.map((d, index) => {
            if (this.state.duration === d.value) {
                return <MenuItem key={d.value} value={d.value} style={{ ...styles.popoverMenuItem, color: themeColor }} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '250px' }} title={d.label}>{d.label}</div>}</MenuItem>
            } else {
                return <MenuItem key={d.value} value={d.value} style={styles.popoverMenuItem} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '250px' }} title={d.label}>{d.label}</div>}</MenuItem>
            }
        });
        if (Object.keys(this.state.managerSalesMap).length > 0) {
            let salesRepList = this.state.managerSalesMap[this.state.userName];
            let showSalesRepOpt = salesRepList !== null && salesRepList?.length > 0 ? true : false;
            if (!showSalesRepOpt && this.state.sortBy === 'sales_rep') {
                this.state.sortBy = 'forecast_amount';
            }

            if (this.state.reportBy === 'status' && this.state.sortBy === 'probability') {
                this.state.sortBy = 'forecast_amount';
            }

            this.state.sortByList = [{ label: 'Close date', value: 'close_date' }, { label: 'Forecast amount', value: 'forecast_amount' }];

            if (this.state.reportBy !== 'status') {
                let obj = {
                    label: 'Probability',
                    value: 'probability'
                };
                this.state.sortByList.push(obj);
            }

            if (showSalesRepOpt) {
                let obj = {
                    label: 'Sales rep',
                    value: 'sales_rep'
                };
                this.state.sortByList.push(obj);
            }

            sortArrayObjectByProperty(this.state.sortByList, 'label');

            this.state.reportByList = [{ label: 'Status', value: 'status' }];

            let managerSalesMap = this.state.managerSalesMap;
            if (isValidParam(managerSalesMap) && managerSalesMap.hasOwnProperty(this.state.userName)) {
                this.state.reportByList.push({ label: 'Sales rep', value: 'sales_rep' });
            } else if (this.state.reportBy === 'sales_rep') {
                this.state.reportBy = 'status';
            }




        }

        return (
            <div style={styles.workAreaContainer}>
                <div id="divKanbanView" onScroll={this.manageScroll} style={{ height: contentHeight, padding: '0px', marginTop: '-5px' }}>
                    {(this.state.mounted) ?
                        <div>
                            <div style={{ width: '100%', height: '45px', paddingLeft: '5px' }}>
                                <div style={{ width: '100%', paddingLeft: '15px', /*backgroundColor: '#ffffff'*/ }}>
                                    <div style={{ float: 'left', verticalAlign: 'top', color: '#555', /*backgroundColor: '#ffffff'*/ }}>
                                        {this.props.isFetching && lists !== null && lists.length > 0 &&
                                            <div style={{ fontSize: '21px', fontWeight: 'bold', float: 'left' }} title={this.state.durationLabel + " (" + total_forecast_amount + ")"}>
                                            </div>
                                        }
                                    </div>

                                    {lists !== null && lists.length > 0 &&
                                        <div style={{ ...styles.row, float: 'left' }}>
                                            <div>
                                                <FormControl variant="outlined">
                                                    <InputLabel id="sf-kanbanview-simple-select-outlined-label" className="sf-kanbanview">Timeline</InputLabel>
                                                    <Select
                                                        key="timeline"
                                                        label={'Timeline'}
                                                        id='timeline'
                                                        labelId='timeline-label'
                                                        value={this.state.duration}
                                                        onChange={this.onChangeDuration}
                                                        name='timeline'
                                                        className={"sf-fields-bg"}
                                                        style={{ height: '35px', marginRight: '5px', width: '200px' }}
                                                    >
                                                        {durationArr}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div >
                                                <FormControl variant="outlined" >
                                                    <InputLabel id="sf-kanban-view-simple-select-outlined-label" className="sf-kanban-view">{getLocalizedStrings().label.KANBAN_REPORT.USER}</InputLabel>
                                                    <Select
                                                        key="userlist"
                                                        label={getLocalizedStrings().label.KANBAN_REPORT.USER}
                                                        id='userlist'
                                                        value={this.state.userName}
                                                        onChange={this.onChangeValue.bind(this, 'user')}
                                                        name='userlist'
                                                        className={"sf-fields-bg"}
                                                        style={{ height: '35px', marginRight: '5px', width: '200px' }}
                                                    >
                                                        {this.state.userList}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div >
                                                {this.state.showWorkflowList &&
                                                    <FormControl variant="outlined" >
                                                        <InputLabel id="sf-kanbanviewreport-simple-select-outlined-label" className="sf-kanbanviewreport">{getLocalizedStrings().label.KANBAN_REPORT.WORKFLOW}</InputLabel>
                                                        <Select
                                                            key="workflowlist"
                                                            label={getLocalizedStrings().label.KANBAN_REPORT.WORKFLOW}
                                                            labelId='workflowlist'
                                                            id='workflowlist'
                                                            value={this.state.workflowId}
                                                            onChange={this.onChangeValue.bind(this, 'workflow')}
                                                            name='workflowlist'
                                                            className={"sf-fields-bg"}
                                                            style={{ height: '35px', marginRight: '5px', width: '200px' }}
                                                        >
                                                            {this.state.workflowMenuItemList}

                                                        </Select>
                                                    </FormControl>


                                                }
                                            </div>
                                            <div style={{ display: 'inline-block', height: '35px' }}>
                                                <span
                                                    title={'Refresh'}
                                                    style={{ position: 'absolute', marginLeft: 5, marginTop: 9 }}>
                                                    <span
                                                        style={{ fontSize: 12, fontWeight: 'bold', color: '#039be5', cursor: 'pointer' }}
                                                        children="Refresh"
                                                        onClick={this.refreshKanbanReport} />
                                                </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div>
                                {this.props.isFetching && lists !== null && lists.length > 0 ?
                                    <div className="kanbanBord" style={{ width: '100%', overflowX: 'auto', paddingBottom: '0px', paddingTop: '10px', paddingLeft: '20px', paddingRight: '0px', margin: '0px' }}>
                                        <CustomDragLayer snapToGrid={false} />
                                        {lists.map((item, i) =>
                                            <CardsContainer
                                                key={item.id}
                                                id={item.id}
                                                item={item}
                                                moveCard={this.moveCard}
                                                x={i}
                                                object={this.props.object}
                                                reportBy={this.state.reportBy}
                                                sortBy={this.state.sortBy}
                                                salesRep={this.state.userName}
                                                duration={this.state.duration}
                                                workflowId={this.state.workflowId}
                                                startScrolling={this.startScrolling}
                                                stopScrolling={this.stopScrolling}
                                                isScrolling={this.state.isScrolling}
                                                selectedHeader={this.props.selectedHeader}
                                                queueList={this.props.hasOwnProperty('queueList') && this.props.queueList !== null ? this.props.queueList : null}
                                                app={this.props.app}
                                                showCustomSnackBar={this.props.showCustomSnackBar}
                                            />
                                        )}
                                    </div>
                                    :
                                    <div style={{ ...styles.loaderContainer, marginTop: '100px' }}>

                                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7, marginLeft: '47%' }}>
                                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />

                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        :
                        <div style={{ ...styles.loaderContainer, marginTop: '100px' }}>
                            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7, marginLeft: '47%' }}>
                                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />

                            </div>
                        </div>}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KanbanView);

