import React, { useState, useEffect } from "react";
import {
	GridComponent,
	ColumnsDirective,
	ColumnDirective,
} from "@syncfusion/ej2-react-grids";
import { getListViewData } from "../../../services/actions/listViewAcions";
import { constants } from "../../../services/constants/constants";
import {
	isValidParam,
	getBooleanParam,
	getStringParam,
	getArrayParam,
} from "../../../services/helper/parameterVerifier";
import NoRecord from "../components/noRecord";
import ShowCircularProgress from "../components/circularProgress";
import {
	addTab,
	TYPE_DETAIL_VIEW,
	getActiveTabInfo,
} from "../../../services/helper/sfTabManager";
import Button from "@mui/material/Button";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { styles } from "../../../services/constants/styles";
import { getAppDrawer } from "../../../services/actions/appContainerActions";
import { getAppDialog } from "../../../services/actions/appContainerActions";
import ListViewSetup from "../components/listViewSetup";
import { getUniqueId } from "../../../services/helper/utils";
import { Icon, IconButton, Popper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const useStyles = theme => ({
    root: {
        fontSize: 13,
        backgroundColor: '#fff',
    },
    popper: {
        border: '1px solid rgba(27,31,35,.15)',
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        borderRadius: 3,
        zIndex: 1300,
        fontSize: 13,
        color: '#586069',
        backgroundColor: '#f6f8fa',
    },
});


const Pets =({object,parentObject,parentRecordId,isDetailView,classes})=> {

	const dispatch = useDispatch();

	const app = useSelector((state) => state.app);
	const note = useSelector((state) => state.note);
	const tab = useSelector((state) => state.tab);
	const listView = useSelector((state) =>state.childListView);
	const userlist = useSelector((state) => state.userlist);
	const detailView = useSelector((state) => state.detailView);

	const [setupComponent, setSetupComponent] = useState(null);
	const [isOpenSetupPopover, setIsOpenSetupPopover] = useState(false);
	const [anchorEl, setAnchorEl] = useState(false);
	const [domUniqueKey, setDomUniqueKey] = useState(getUniqueId());

        // this.template = gridTemplate();
        // getLocalizedStrings() = getLocalizedStrings();
       

   const handleAction = (action, event) => {
        try {
            let label = getLocalizedStrings().label.COMMON.ADD + ' Pets';
            let data = {
                object: object,
                parent_object: parentObject,
                parent_record_id: parentRecordId,
                mode: constants.SF_FORM_OPEN_MODE_QUICK
            }
           dispatch(getAppDrawer(true, label, action, data, constants.PETS_OBJECT));
        } catch (error) {
            console.log("Error in 'petsComponent.js -> handleAction()':" + error);
        }
    }

	useEffect(()=>{
        getData();

            let info = getActiveTabInfo();
            info.getAllPets = getData;
      
	},[])
   

    // UNSAFE_componentWillReceiveProps(nextProps) {
    //     if (JSON.stringify(this.props.listView.data) != JSON.stringify(nextProps.listView.data)) {
    //         this.setState({ domUniqueKey: getUniqueId() });
    //     }
    // }
   const getData = () => {
        try {
           
            let params = {
                parent_object: parentObject,
                parent_record_id: parentRecordId,
				isDetailView: true
            };
            dispatch(getListViewData(constants.PETS_OBJECT, params));
        } catch (error) {
            console.log("Error in 'petsComponent.js -> getData()':" + error);
        }
    }

   
	
  const  openTab = (label, url) => {
        let tab = { label: label, object: object, type: TYPE_DETAIL_VIEW, imgName: null, url: url, isActive: true };
        addTab(tab, true);
    }

   const addPhoto = (props, event) => {
        try {
            dispatch(getAppDialog(true, constants.LIST_VIEW_LOGO_UPLOAD_DIALOG, getLocalizedStrings().message.ATTACHMENT.SELECT_IMAGE_TO_UPLOAD, null, { id: props.id, fieldName: props.name, setLogoOnRecord: setLogoOnRecord, object: object }, null));
        } catch (error) {
            console.log("Error in 'petsComponent.js -> addPhoto()':" + error);
        }
    }

   const setLogoOnRecord = (imageUrl, id, fieldName) => {
        let imgUrl = getStringParam(imageUrl);
        try {
            if (imgUrl != '') {
                let records = isValidParam(listView.data) ? listView.data.records : [];
                records = getArrayParam(records);
                records.forEach((row, index) => {
                    if (row.id == id) {
                      listView.data.records[index].show_pic_url = imageUrl;
					  setDomUniqueKey(domUniqueKey);
                    getUniqueId();
                        return;
                    }
                });
            }
        } catch (error) {
            console.log("Error in 'petsComponent.js -> setLogoOnRecord()':" + error);
        }
    }

  const  closeSetupPopOver = () => {
        console.log(`close popover`)
		setIsOpenSetupPopover(false);
		setSetupComponent(null)
		setAnchorEl(null)
       
    }


   const openSetupPopOver = (event) => {
        event.preventDefault();

		setIsOpenSetupPopover(true);
		setSetupComponent(<ListViewSetup
			object={object}
			closeEvent={closeSetupPopOver.bind()}
			isDetailView={isDetailView}
		/>)
		setAnchorEl(event.currentTarget)
    }

   const getTr = (field, props) => {
        return <tr style={{ height: 20 }}>
            <td className="CardHeader" style={{ fontWeight: 'bold', color: 'rgb(181, 181, 181)' }} title={field.label}> {field.label} </td>
            <td style={{ color: 'rgb(98, 100, 100)' }} title={props[field.name]}>{field.name == 'name' ? <a href='javascript:void(0)' onClick={() => openTab(props.name, '/pets/detailview/' + props.id)}>{props[field.name]}</a> : props[field.name]} </td>
        </tr>
    }

    const gridTemplate =(arrFields, props) =>{
        let fields = getArrayParam(arrFields).filter(f => { return !f.is_hidden });
        let src = getStringParam(props.show_pic_url);
        let isShowPic = getBooleanParam(listView.data.is_show_pic);
        return (<tr className="templateRow" style={{ height: 120 }}>
            {isShowPic && <td className="photos" style={{ textAlign: 'center', padding: '10px 15px', borderWidth: '0px 0px 1px 0px', borderStyle: 'solid', borderColor: '#e0e0e0', verticalAlign: 'top' }}>
                {src != '' && <img onClick={addPhoto.bind(this, props)} title={'Upload Image'} style={{ width: 100, height: 100, borderRadius: 50, boxShadow: 'inset 0 0 1px #e0e0e0, inset 0 0 14px rgba(0,0,0,0.2)', cursor: 'pointer' }} src={src} />}
                {src == '' && <i onClick={addPhoto.bind(this, props)} title={'Upload Image'} className="fas fa-paw" style={{ width: 100, height: 100, borderRadius: 50, boxShadow: 'inset 0 0 1px #e0e0e0, inset 0 0 14px rgba(0,0,0,0.2)', padding: 7, cursor: 'pointer', color: 'rgb(248, 151, 100)', fontSize: 80 }} />}
            </td>}
            <td className="details" style={{ padding: '10px 15px', borderColor: '#e0e0e0', borderStyle: 'solid', borderWidth: '0px 0px 1px 0px' }}>
                <table className="CardTable" cellPadding={3} cellSpacing={2} style={{ fontFamily: 'Lato, sans-serif', fontSize: 13, }}>
                    <colgroup>
                        <col style={{ width: "50%" }} />
                        <col style={{ width: "50%" }} />
                    </colgroup>
                    <tbody>
                        {fields.map(m => {
                            return getTr(m, props);
                        })}
                    </tbody>
                </table>
            </td>
        </tr>);
    }

        let mounted = listView.mounted;
		console.log("listView",listView)
		console.log("listView.mounted",listView.mounted)

        const { fields, records } = listView.data;
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;

        return <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '0px', height: 40, border: '0px solid lightgray', zIndex: '100' }}>
                <Button
                    startIcon={<i className="fa fa-plus" style={{ color: '#717171', marginTop: '2px', fontSize:14 }}></i>}
                    onClick={handleAction.bind(this, constants.NEW)}
                    style={{...styles.secondaryButton, width:'88px' }}
                >{getLocalizedStrings().label.COMMON.ADD}</Button>
                {mounted &&
                    records.length > 0 &&
                    <div
                        style={{
                            border: '0px solid red',
                            cursor: 'pointer',
                        }}
                    >
                        <IconButton onClick={(e) => openSetupPopOver(e)} style={{ color: '#717171', marginTop: '6px', padding: 0, border: '0px solid red' }}>
                            <Icon style={{ opacity: 0.7, fontSize: '16px', paddingLeft: '0px' }} title={getLocalizedStrings().label.LIST_VIEW.SET_UP}>settings</Icon>
                        </IconButton>
                        <Popper
                            open={isOpenSetupPopover}
                            anchorEl={anchorEl}
                            placement="left"
                            style={{ width: '50%' }}
							sx={{ ...styles.popperStyle }}
							>
                            {setupComponent}

                        </Popper>
                    </div>}
            </div>
            <div>
                {mounted && records.length > 0 && <div>
                    <div
                    >
                        <GridComponent key={domUniqueKey} dataSource={records} rowTemplate={gridTemplate.bind(this, fields)} width='auto'>
                            <ColumnsDirective>
                                <ColumnDirective customAttributes={{ style: { display: 'none' } }} />
                            </ColumnsDirective>
                        </GridComponent>
                    </div>
                </div>}
            </div>
            <div>{mounted && records.length == 0 && <NoRecord object={object} parentObject={parentObject} view={constants.LIST_VIEW} />}</div>
            <div>
                {!mounted && <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>}
            </div>
        </div>;
    
}

export default Pets;
