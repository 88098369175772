import { actions } from '../constants/actions';

const intakeFormInitialState = {
    selectedForm: 'Intake Info',
    data: {intake: {}, other:{}},
    intakeFormId: 0,
    otherFormId: 0,
    fullName: '',
    signatureData: {},
    countryList: [],
    appThemeColor: '',
    isLoading: null,
    isError:false,
    customAutofillData: {},
    formErrors: {},
};

const intakeFormReducer = (state = intakeFormInitialState, action) => {
    switch (action.type) {
        case actions.SET_SELECTED_INTAKE_FORM:
            return {
                ...state,
                selectedForm: action.payload.selectedForm,
            };
        case actions.SET_INTAKE_FORM_DATA:
            return {
                ...state,
                signatureData: action.payload.intakeForm.intake?.signature ? JSON.parse(action.payload.intakeForm.intake?.signature) : {} ,
                data: action.payload.intakeForm,
                isLoading: action.payload.isLoading
            };
        case actions.SAVE_INTAKE_FORM_ID:
            return {
                ...state,
                intakeFormId: action.payload.intakeFormId,
                otherFormId: action.payload.otherFormId,
                isLoading: action.payload.isLoading
            };
        case actions.SET_LINK_INTAKE_FORM_DATA:
            const { intake, intake: { signature }, full_name, color,  country_iso2} = action.payload.linkIntakeFormData
            return {
                ...state,
                data: { 
                    ...state.data,
                    intake: intake,
                },
                isLoading: action.payload.isLoading,
                signatureData: JSON.parse(signature),
                fullName: full_name,
                appThemeColor: color,
                countryList: country_iso2,
            };
        case actions.SET_IS_LOADING : 
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case actions.SET_IS_ERROR_INTAKE_FORM:
            return {
                ...state,
                formErrors: action.payload.isError
            }
        case actions.SET_CUSTOM_TABLE_ONE_AUTOFILL_DATA: 
            return {
                ...state,
                customAutofillData: action.payload.customAutofillData
            }
        case actions.SET_FORM_ERROR:
            return {
                ...state,
                formErrors: {
                    ...state.formErrors,
                    [action.payload.field]: action.payload.error,
                },
            };
        case actions.DELETE_INTAKE_FORM_DATA:
            return {
                ...state,
                data:{},
                intakeFormId:0,
                otherFormId:0,
            }
        default:
            return state;
    }
};

export default intakeFormReducer;
