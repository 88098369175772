import { actions } from '..//constants/actions';

const timelineViewInitialSate = {
    records: [],
    fields: [],
    sort_field_name: '',
    sort_type: '',
    mounted: false,
    isFetching: false,
    queryName: '',
    queryType: '',
    totalCount: 0,
    object: '',
    view: '',
    isFilterChanged: false,
    groupByFieldName: '',
    actions: [],
}

const timelineViewReducer = (state = timelineViewInitialSate, action) => {
    switch (action.type) {
        case actions.GET_TIMELINE_START:
          return {
            ...state,
            isFetching: action.isFetching,
          }
        case actions.GET_TIMELINE:
          return {
              ...state,
              fields: action.payload.fields,
              records: action.payload.records,
              sort_field_name: action.payload.sort_field_name,
              sort_type: action.payload.sort_type,
              mounted: action.payload.mounted,
              isFetching: action.payload.isFetching,
              queryName: action.payload.queryName,
              queryType: action.payload.queryType,
              totalCount: action.payload.totalCount,
              object: action.payload.object,
              view: action.payload.view,
              isFilterChanged: action.payload.isFilterChanged,
              actions: action.payload.actions,
              groupByFieldName: action.payload.groupByFieldName,
          }
        case actions.TIMELINE_VIEW_MOUNTED:
          return {
            ...state,
            mounted: action.payload.mounted,
          }
        default:
          return state;
    }
}

export default timelineViewReducer;

// const isTimelineViewMount = (payload) => {
//     let activeTab = getObjectParam(getActiveTab());
//     let activeTabObject = getStringParam(activeTab.object);
//     let payloadObject = "";
//     activeTabObject = makeValidObjectName(activeTabObject);
//     if (activeTabObject === constants.SEARCH_RESULTS && isValidParam(activeTab.info.search) && getStringParam(activeTab.info.search.object) !== "") {
//         activeTabObject = getStringParam(activeTab.info.search.object);
//     } else if (activeTabObject === constants.ADVANCE_SEARCH_RESULTS && isValidParam(activeTab.info.advanceSearch) && getStringParam(activeTab.info.advanceSearch.object) !== "") {
//         activeTabObject = getStringParam(activeTab.info.advanceSearch.object);
//     }
//     if (activeTabObject === constants.RECYCLE_BIN_OBJECT) {
//         activeTabObject = constants.ACCOUNTS_OBJECT;
//     }
//     payloadObject = getStringParam(getStringParam(payload.object));
//     payloadObject = getObjectNameByLabel(payloadObject);
//     payloadObject = (payloadObject === constants.AB_CAMPAIGNS_OBJECT) ? constants.BROADCAST_CAMPAIGNS_OBJECT : payloadObject;
//     payloadObject = (payloadObject === constants.TOUCH_CAMPAIGNS_OBJECT) ? constants.AUTOMATION_DESIGNER_OBJECT : payloadObject;

    //     if (activeTab && activeTabObject.toUpperCase() === payloadObject.toUpperCase()) {
//         return true;
//     } else {
//         return false;
//     }
// }