import { actions } from '../constants/actions';
import { getActiveTab } from '../helper/sfTabManager';
import { getObjectParam, getStringParam, isValidParam, makeValidObjectName } from '../helper/parameterVerifier';
import { getObjectNameByLabel } from '../helper/common';
import { constants } from '../constants/constants';

const detailViewTaskListInitialSate = {
    mounted: false,
    data: {
        object: '',
    },
    pages: {},
    error: null,
    actionsOnSelectedRows: [],
    selectedRows: null,
    queryName: '',
    isSetHeader: false,
    isShowGroupBy: false,
    groupByInfo: {
        object: '',
    },
    filterByInfo: {
        filterObject: '',
        filterList: [],
        filterValue: '',
    },
    isGroupByMounted: false,
    isSearchFieldInfoMounted: false,
    searchFieldInfo: {
        object: '',
    },
    isShowLeadstoreSearchFld: false,
    isRefresh: false,
    requiredFieldsMap: {},
    scrollPosition: {},
    workflowList: []
};

const detailViewTaskListReducer = (state = detailViewTaskListInitialSate, action) => {
    let isMount = false;
    switch (action.type) {
        case actions.DETAIL_VIEW_TASK_LISTVIEW_GET_PENDING:
            return {
                ...state,
                mounted: false,
            };
        case actions.DETAIL_VIEW_TASK_LISTVIEW_GET_FULFILLED:
            isMount = isListViewMount(action.payload, action.state);
            if (isMount) {
                return {
                    ...state,
                    mounted: true,
                    data: action.payload,
                    actionsOnSelectedRows: [],
                    selectedRows: [],
                    queryName: action.payload !== null ? action.payload.query_name : '',
                    isSetHeader: true,
                    isShowGroupBy: false,
                };
            } else {
                return {
                    ...state,
                };
            }
        case actions.DETAIL_VIEW_TASK_GROUPBY_INFO_GET_FULFILLED:
            isMount = isListViewMount(action.payload, action.state);
            if (isMount) {
                return {
                    ...state,
                    isGroupByMounted: true,
                    groupByInfo: action.payload,
                };
            } else {
                return {
                    ...state,
                };
            }
        case actions.DETAIL_VIEW_TASK_UPDATE_FILTERBY_INFO:
            return {
                ...state,
                filterByInfo: action.payload,
            };
        case actions.DETAIL_VIEW_TASK_GET_SELECTED_RECORD:
            return {
                ...state,
                selectedRows: action.payload,
            };
        case actions.DETAIL_VIEW_TASK_LISTVIEW_SET_MOUNTED:
            return {
                ...state,
                mounted: action.payload,
                isGroupByMounted: action.payload,
            };
        case actions.DETAIL_VIEW_TASK_LISTVIEW_REFRESH:
            return {
                ...state,
                isRefresh: action.payload,
                selectedRows: action.payload ? [] : state.selectedRows,
            };
        case actions.DETAIL_VIEW_TASK_GROUPBY_SHOW_HIDE:
            return {
                ...state,
                isShowGroupBy: state.isShowGroupBy ? false : true,
            };
        case actions.DETAIL_VIEW_TASK_WORKFLOW_LIST_UPDATE:
            return {
                ...state,
                workflowList: action.payload,
            };
        default:
            return state;
    }
};


export default detailViewTaskListReducer;

const isListViewMount = (payload, state) => {
    let activeTab = getObjectParam(getActiveTab(state));
    let activeTabObject = getStringParam(activeTab.object);
    let payloadObject = '';
    activeTabObject = makeValidObjectName(activeTabObject);
    payloadObject = getStringParam(getStringParam(payload.object));
    payloadObject = getObjectNameByLabel(payloadObject);

    if (activeTab && activeTabObject.toUpperCase() === payloadObject.toUpperCase()) {
        return true;
    } else {
        return false;
    }
};
