import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedSMSVerificationForm ,setIsErrorSMSVerificationForm} from '../../../../services/actions/smsVerificationAction.js';
import { Button } from '@mui/material';
import { getLocalizedStrings } from '../../../../services/constants/MultiLingual.js';
import { styles } from '../../../../services/constants/styles.js';
import FormFields from './FormFields.js/index.js';
import { customerDetailsSection1,customerDetailsSection2,customerDetailsHeaderText } from './customerDetailsFormData.js';
import { campaignDetailsSection1 } from './campaignDetailsFormData.js';
import { brandDetailsSection1} from './brandDetailsFormData.js';
import { getArrayParam, isUrl, isValidParam } from '../../../../services/helper/parameterVerifier.js';
import * as HTTPClient from '../../../../services/helper/httpClient';
import { endPoints } from '../../../../services/constants/endPoints.js';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { actions } from '../../../../services/constants/actions.js';
import { showCustomSnackBar } from '../../../../services/actions/snackBarAction.js';
import { getAppDrawer } from '../../../../services/actions/appContainerActions.js';
import ShowCircularProgress from '../circularProgress.js';
import * as sfDialogs from "../../components/sfDialogs";


const TwilioSMSVerification = () => {
    const form = ['Customer Details','Brand Details', 'Campaign Details'];
    const app = useSelector((state) => state.app);
    const { selectedForm } = useSelector((state) => state.smsVerfication);
    const [customerDetailsSection2Data, setCustomerDetailsSection2Data] = useState(customerDetailsSection2);
    const [campaignDetailsSection1Data, setCampaignDetailsSection1Data] = useState(campaignDetailsSection1);
    const [customerDetails,setCustomerDetails]= useState({
        legal_entity_name: '',
        website_url: '',
        first_name: '',
        last_name: '',
        email_address: '',
        phone_number: '',
        country: '',
        address: '',
        state: '',
        city:'',
        zip: '',
        business_registration_identifier:'',
        business_registration_number:'',
        business_type:'',
        business_industry:'',
        business_title:'',
        job_position:'',
    })
    const [brandDetails, setBrandDetails] = useState({
        brand_name:'',
        brand_type:'',
        company_type:''
    })
    const [campaignDetails, setCampaignDetails] = useState({
        use_case: '',
        use_case_description: '',
        opt_in_message: '',
        sample_message_1: '',
        sample_message_2: '',
    });
    const [customerDetailsFormFieldsDisable, setCustomerDetailsFormFieldsDisable] = useState(false);
    const [brandDetailsFormFieldsDisable, setBrandDetailsFormFieldsDisable] = useState(false);
    const [campaignDetailsFormFieldsDisable, setCampaignDetailsFormFieldsDisable] = useState(false);
    const [brandDetailsTabDisable,setbrandDetailsTabDisable] = useState(false);
    const [campaignDetailsTabDisable,setcampaignDetailsTabDisable] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    
    const dispatch = useDispatch();

    useEffect(()=>{
        getFormData()
        dispatch(setSelectedSMSVerificationForm(form[0]))
    },[])

    const getFormData = () => {
        setIsLoading(true)
        const promise = Promise.resolve(HTTPClient.get(endPoints.TWILIO_SMS_VERIFIACTION.GET));
        promise.then((response) => {
            if (isValidParam(response) && response?.customerDetails) { // get customerDetails tab 
                const { customerDetails } = response;
                
                    const country= app.countryISO2.find((country)=>country.t_country_iso2.toUpperCase() === customerDetails.country)
                    customerDetails.business_industry = customerDetails.business_industry.charAt(0).toUpperCase() + customerDetails.business_industry.slice(1).toLowerCase();                    
                    setCustomerDetails({...customerDetails, country: country.t_country})
                    let updatedSection2Data = customerDetailsSection2.map((item) => {
                        if (item?.dropDownName === 'country') {
                            return { ...item, options: getArrayParam(app.countryISO2) };
                        }
                        return item;
                    });
                    setStateMenuItemList(country.t_country, updatedSection2Data);

                    console.log("customer details",customerDetails);

                    // customer form status - saved - in-review - twilio-approved
                    
                    if (customerDetails.customer_status === "Saved") {
                        setCustomerDetailsFormFieldsDisable(false);
                        setbrandDetailsTabDisable(true)
                        setcampaignDetailsTabDisable(true)
                    } else if (customerDetails.customer_status === "in-review") {
                        setCustomerDetailsFormFieldsDisable(true);
                        setbrandDetailsTabDisable(true)
                        setcampaignDetailsTabDisable(true)
                    } else if (customerDetails.customer_status === "draft"){
                        const failureReason = `<span style="color: red;">${customerDetails.failure_reason}</span>`;
                        sfDialogs.alert(
							getLocalizedStrings().message.COMMON.XMS_ERROR_ALERT_DIALOG_TITLE,
							failureReason,
							() => {
                                setCustomerDetailsFormFieldsDisable(false);
                                setbrandDetailsTabDisable(true)
                                setcampaignDetailsTabDisable(true)
                            }
						);
                    }  
                    else if (customerDetails.customer_status === "twilio-rejected" ) {
                        const failureReason = `<span style="color: red;">${customerDetails.failure_reason}</span>`;
                        sfDialogs.alert(
							getLocalizedStrings().message.COMMON.XMS_ERROR_ALERT_DIALOG_TITLE,
							failureReason,
							() => {
                                setCustomerDetailsFormFieldsDisable(false);
                                setbrandDetailsTabDisable(true)
                                setcampaignDetailsTabDisable(true)
                            }
						);

                    } else if (customerDetails.customer_status === "twilio-approved") {
                        setCustomerDetailsFormFieldsDisable(true);
                        setbrandDetailsTabDisable(false)
                        setcampaignDetailsTabDisable(true)
                    }
            
            } else { // for new form set intial data country option and country name
                let updatedSection2Data = customerDetailsSection2.map((item) => {
                    if (item?.dropDownName === 'country') {
                        return { ...item, options: getArrayParam(app.countryISO2) };
                    }
                    return item;
                });
                setCustomerDetailsSection2Data(updatedSection2Data)
                setCustomerDetails({...customerDetails, country: app.me.tenant_country})
                setStateMenuItemList(app.me.tenant_country, updatedSection2Data);
                setbrandDetailsTabDisable(true)
                setcampaignDetailsTabDisable(true)
            }

            // get brandDetails tab 
            if(isValidParam(response) && response?.brandDetails) {
                const { brandDetails } = response;
                if(brandDetails.brand_type){
                    if (brandDetails.brand_type === "LOW_VOLUME") {
                        brandDetails.brand_type = "Low-volume standard $4 one-time fee";
                    }else{
                        brandDetails.brand_type = "Standard $44 one-time fee";
                    }
                }
                
                if (brandDetails.company_type === "us non profit"){
                    brandDetails.company_type = "US Non Profit"
                } else if (brandDetails.company_type === "us government"){
                    brandDetails.company_type = "US Government"
                }else{
                    brandDetails.company_type = brandDetails.company_type.charAt(0).toUpperCase() + brandDetails.company_type.slice(1);
                }
                setBrandDetails(brandDetails);

                // brand form status - saved - in review - twilio-approved
                if (brandDetails.brand_status === "Saved" && brandDetails.trust_product_status === "Saved"){
                    setCustomerDetailsFormFieldsDisable(true);
                    setBrandDetailsFormFieldsDisable(false)
                    setcampaignDetailsTabDisable(true);
                }else if (brandDetails.brand_status === "FAILED" || brandDetails.trust_product_status === "twilio-rejected" ){
                    const failureReason = `<span style="color: red;">${brandDetails.failure_reason}</span>`;
                    sfDialogs.alert(
                        getLocalizedStrings().message.COMMON.XMS_ERROR_ALERT_DIALOG_TITLE,
                        failureReason,
                        () => {
                            setCustomerDetailsFormFieldsDisable(false);
                            setbrandDetailsTabDisable(true)
                            setcampaignDetailsTabDisable(true)
                        }
                    );
                }else if (brandDetails.brand_status === "APPROVED" && brandDetails.trust_product_status === "twilio-approved") {
                    setcampaignDetailsTabDisable(false)
                    setBrandDetailsFormFieldsDisable(true);
                    setCustomerDetailsFormFieldsDisable(true)
                }else{
                    setcampaignDetailsTabDisable(true)
                    setBrandDetailsFormFieldsDisable(true);
                    setCustomerDetailsFormFieldsDisable(true)
                }
                
                // get campaignDetails tab
                if(brandDetails?.brand_id && (brandDetails.brand_status === "APPROVED" && brandDetails.trust_product_status === "twilio-approved") ) {
                    const endpoint= endPoints.TWILIO_SMS_VERIFIACTION.GET_USECASES + brandDetails.brand_id;
                    const useCasePromise = Promise.resolve(HTTPClient.get(endpoint));
                    useCasePromise.then((useCaseResponse) => {
                        if (isValidParam(useCaseResponse)) {
                            console.log("response useCasePromisee",useCaseResponse);
                            let updateCampaignDetailsSection1Data = campaignDetailsSection1.map((item) => {            
                                        if (item?.dropDownName === 'use_case') {
                                            return { ...item, options: useCaseResponse};
                                        }
                                        return item;
                                    });
                                    setCampaignDetailsSection1Data(updateCampaignDetailsSection1Data);

                                    if(isValidParam(response) && response?.campaignDetails) {
                                        const { campaignDetails } = response;
                                        console.log("campaign details",campaignDetails, useCaseResponse.length > 0);
                                            if(campaignDetails.use_case && useCaseResponse.length > 0 ) {
                                            let useCaseValue = getUseCaseCodeNameFromUseCaseOption(campaignDetails.use_case, useCaseResponse)
                                            setCampaignDetails({...campaignDetails, use_case: useCaseValue });  
                                            } else {
                                            setCampaignDetails(campaignDetails);  
                                            }
                                        
                                            if (campaignDetails.campaign_status === "IN_PROGRESS" ) {
                                                setCampaignDetailsFormFieldsDisable(true)
                                            }else if(campaignDetails.campaign_status === "success") {
                                                setCampaignDetailsFormFieldsDisable(true)
                                            }
                                    }
                        }
                     setIsLoading(false);  
                    }).catch((err) => {
                        console.log('Error fetching data:', err);
                    });
                }
            }
            setIsLoading(false)
        }).catch((err) => {
            console.log('Error fetching data:', err);
        });
    }

   
    //convert usecase from code to name
    const getUseCaseCodeNameFromUseCaseOption = (use_case_value, useCaseOptions) => {
        const useCasefound = useCaseOptions.find((option)=>option.code === use_case_value);   
        if (useCasefound) {
            return useCasefound;
        }
    };
 
    // get states 
    const setStateMenuItemList = (value, updatedData) => {
        try {
            let params = {};
            params.country = value;
            if (value?.length > 0) {
                let promise;
                    promise = Promise.resolve(HTTPClient.get(endPoints.INTAKE_FORM.STATES, params));
                 
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if(response.length > 0) {
                            if(updatedData && Object.keys(updatedData)?.length > 0) {
                                let updatedSection2Init = updatedData.map((item) => {
                                    if (item?.dropDownName === 'state') {
                                        return { ...item, options: getArrayParam(response) };
                                    }
                                    return item; 
                                })
                               setCustomerDetailsSection2Data(updatedSection2Init)
                            } else {
                                let updatedSection2Init = customerDetailsSection2Data.map((item) => {
                                    if (item?.dropDownName === 'state') {
                                        return { ...item, options: getArrayParam(response) };
                                    }
                                    return item; 
                                })
                                setCustomerDetailsSection2Data(updatedSection2Init)
                            }
                        }
                    }
                });
            } 
        } catch (error) {
            console.error("Error in 'sfForm.js -> setStateMenuItemList()':" + error);
        }
    };

    const handleChangeForm = (event,value) => {
        dispatch(setSelectedSMSVerificationForm(value))
    };
     
    const isValidEmail = (email) =>{
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(email) ) {
            return true;
        } else {
           return false;
        }
    }
 
    //validation 
    const getValidFormStatus =(formDataParams)=>{
        if(selectedForm === form[0]){
            if(!formDataParams.legal_entity_name){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'legal_entity_name', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.LEGAL_ENTITY_NAME_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.website_url){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'website_url', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.WEBSITE_URL_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.first_name){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'first_name', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.FIRST_NAME__REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.last_name){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'last_name', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.LAST_NAME__REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.email_address){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'email_address', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.EMAIL_ADDRESS_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.phone_number){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'phone_number', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.PHONE_NUMBER_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.country){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'country', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.COUNTRY_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.address){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'address', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.ADDRESS_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.state){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'state', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.STATE_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.city){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'city', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.CITY_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.zip){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'zip', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.ZIP_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.business_registration_identifier){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'business_registration_identifier', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.BUSINESS_REGISTRATION_IDENTIFIER_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.business_registration_number){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'business_registration_number', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.BUSINESS_REGISTRATION_NUMBER_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.business_type){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'business_type', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.BUSINESS_TYPE_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.business_industry){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'business_industry', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.BUSINESS_INDUSTRY_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.business_title){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'business_title', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.BUSINESS_TITLE_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.job_position){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'job_position', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.JOB_POSITION_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }
        }else if(selectedForm === form[1]){
            if(!formDataParams.brand_name){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'brand_name', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.BRAND_NAME_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors

            }else if(!formDataParams.brand_type){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'brand_type', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.BRAND_TYPE_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.company_type){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'company_type', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.COMPANY_TYPE_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }
        }else{
            if(!formDataParams.use_case){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'use_case', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.USE_CASE_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.use_case_description){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'use_case_description', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.USE_CASE_DESCRIPTION_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.opt_in_message){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'opt_in_message', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.OPT_IN_MESSAGE_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.sample_message_1){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'sample_message_1', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.SAMPLE_MESSAGE_1_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }else if(!formDataParams.sample_message_2){
                dispatch({
                    type: actions.SET_SMSVERIFICATION_FORM_ERROR,
                    payload: { field: 'sample_message_2', error: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.SAMPLE_MESSAGE_2_REQUIRED},
                    });
                    return; // Do not proceed with form submission if there are errors
            }
        }

        return true;
    }
    
    const getISOCodeFromCountryName = (countryName) => {
        const country = app.countryISO2.find(country => country.t_country === countryName);   
        if (country) {
            return country.t_country_iso2.toUpperCase();
        }
    };

    const SaveForm = () => {
        let formDataParams = selectedForm === form[0] ? { ...customerDetails } : selectedForm === form[1] ? { ...brandDetails } : { ...campaignDetails };
        let isValidForm = getValidFormStatus(formDataParams);
        if(isValidForm) {
            if ((formDataParams.email_address && !isValidEmail(formDataParams.email_address))) {
                let errorMessage = getLocalizedStrings().message.COMMON.EMAIL_VALIDATION;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }else if(formDataParams.website_url && !isUrl(formDataParams.website_url)){
                let errorMessage = getLocalizedStrings().message.COMMON.VALID_URL;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }else if(formDataParams.business_registration_number && formDataParams.business_registration_number?.length !==9){
                let errorMessage = getLocalizedStrings().message.TWILIO_SMS_VERIFICATION.MSG_FOR_BUSINESS_REGISTRATION_NUMBER;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }else if(formDataParams.use_case_description && (formDataParams.use_case_description?.length <= 40 || formDataParams.use_case_description?.length >= 4096)){
                let errorMessage = getLocalizedStrings().message.TWILIO_SMS_VERIFICATION.MSG_FOR_DESCRIPTION;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }else if(formDataParams.opt_in_message && (formDataParams.opt_in_message?.length <= 40 || formDataParams.opt_in_message?.length >= 2049)){
                let errorMessage = getLocalizedStrings().message.TWILIO_SMS_VERIFICATION.MSG_FOR_OPT_IN_MESSAGE;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }else if ((formDataParams.sample_message_1) && (formDataParams.sample_message_1?.length <= 20 || formDataParams.sample_message_1?.length >= 1024)){
                let errorMessage = getLocalizedStrings().message.TWILIO_SMS_VERIFICATION.MSG_FOR_SAMPLE_MESSAGES_1;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }else if ((formDataParams.sample_message_2) &&  (formDataParams.sample_message_2?.length <= 20 || formDataParams.sample_message_2?.length >= 1024)){
                let errorMessage = getLocalizedStrings().message.TWILIO_SMS_VERIFICATION.MSG_FOR_SAMPLE_MESSAGES_2
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
                dispatch(setIsErrorSMSVerificationForm(false)); 
                // Convert country name to ISO code
                if (formDataParams.country) {
                    formDataParams.country = getISOCodeFromCountryName(formDataParams.country);
                }
                // Convert business industry to uppercase
                if (formDataParams.business_industry) {
                    formDataParams.business_industry = formDataParams.business_industry.toUpperCase();
                }
                // Convert brand type to LOW_VOLUME or STANDARD on the basis of the value
                if (formDataParams.brand_type)
                {
                    if (formDataParams.brand_type === "Low-volume standard $4 one-time fee") {
                        formDataParams.brand_type = "LOW_VOLUME";
                    }else{
                        formDataParams.brand_type = "STANDARD";
                    }
                }
                // Convert company type to lowercase
                if (formDataParams.company_type) {
                    formDataParams.company_type = formDataParams.company_type.toLowerCase();
                }
                // Extract use case code
                if (formDataParams.use_case) {
                    formDataParams = {...formDataParams,use_case: formDataParams.use_case.code};
                }

                let params = {} 
                // customer details tab
                if(selectedForm === form[0]) {
                    if(customerDetails.customer_id && (customerDetails.customer_status === "Saved" || customerDetails.customer_status === "twilio-rejected" || customerDetails.customer_status === "draft")){ // for update
                        params.tab = "customer";
                        params.customerDetails = formDataParams; 
                        params.customer_id = customerDetails.customer_id;
                        params.brand_id = 0;
                        params.campaign_id = 0;
    
                    } else { // for new
                        params.tab = "customer";
                        params.customerDetails = formDataParams;
                        params.customer_id = 0
                        params.brand_id = 0
                        params.campaign_id = 0
                    }
                }

                // brand details tab
                if(selectedForm === form[1]) {
                    if(brandDetails.brand_id &&  (customerDetails.customer_status === "twilio-approved" || brandDetails.brand_status === "FAILED" )){  // for update
                        params.tab = "brand";
                        params.brandDetails = formDataParams;
                        params.customer_id = customerDetails.customer_id
                        params.brand_id =  brandDetails.brand_id
                        params.campaign_id = 0
    
                    } else {  // for new
                        params.tab = "brand";
                        params.brandDetails = formDataParams;
                        params.customer_id = customerDetails.customer_id
                        params.brand_id =  0
                        params.campaign_id=0
                    }
                }

                // campaign details tab
                if(selectedForm === form[2]) {
                    if(campaignDetails.campaign_id &&  campaignDetails.campaign_status === "Saved" || campaignDetails.campaign_status === "failure"){ // for update
                        params.tab = "campaign";
                        params.campaignDetails = formDataParams;
                        params.customer_id = customerDetails.customer_id
                        params.brand_id = brandDetails.brand_id
                        params.campaign_id = campaignDetails.campaign_id
                    } else { // for save
                        params.tab = "campaign";
                        params.campaignDetails = formDataParams;
                        params.customer_id = customerDetails.customer_id
                        params.brand_id = brandDetails.brand_id
                        params.campaign_id = 0
                    }
                }
                
                const promise = Promise.resolve(HTTPClient.post(endPoints.TWILIO_SMS_VERIFIACTION.SAVE, params));
                promise.then((response) =>{
                    if(response.status === 0) {
                        dispatch(
                            showCustomSnackBar(
                                getLocalizedStrings().message.TWILIO_SMS_VERIFICATION.FORM_SUCCESSFULLY_SAVE,
                                styles.snackbarBodyStyleSuccess,
                                styles.snackBarStyleTop
                            )
                        );
                        getFormData()
                    }
                } ).catch((err) => console.log('err: SaveForm-->', err));                
            }
        }
    };

    const SaveAndSubmitForm = () =>{
        let formDataParams = selectedForm === form[0] ? { ...customerDetails } : selectedForm === form[1] ? { ...brandDetails } : { ...campaignDetails };
        let isValidForm = getValidFormStatus(formDataParams);
        if(isValidForm) {
            if ((formDataParams.email_address && !isValidEmail(formDataParams.email_address))) {
                let errorMessage = getLocalizedStrings().message.COMMON.EMAIL_VALIDATION;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }else if(formDataParams.website_url && !isUrl(formDataParams.website_url)){
                let errorMessage = getLocalizedStrings().message.COMMON.VALID_URL;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }else if(formDataParams.business_registration_number && formDataParams.business_registration_number?.length !==9){
                let errorMessage = getLocalizedStrings().message.TWILIO_SMS_VERIFICATION.MSG_FOR_BUSINESS_REGISTRATION_NUMBER;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }else if(formDataParams.use_case_description && (formDataParams.use_case_description?.length <= 40 || formDataParams.use_case_description?.length >= 4096)){
                let errorMessage = getLocalizedStrings().message.TWILIO_SMS_VERIFICATION.MSG_FOR_DESCRIPTION;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }else if(formDataParams.opt_in_message && formDataParams.opt_in_message?.length <= 40 || formDataParams.opt_in_message?.length >= 2049){
                let errorMessage = getLocalizedStrings().message.TWILIO_SMS_VERIFICATION.MSG_FOR_OPT_IN_MESSAGE;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else if (formDataParams.sample_message_1 &&  formDataParams.sample_message_1?.length <= 20 || formDataParams.sample_message_1?.length >= 1024){
                let errorMessage = getLocalizedStrings().message.TWILIO_SMS_VERIFICATION.MSG_FOR_SAMPLE_MESSAGES_1;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else if (formDataParams.sample_message_2 &&  formDataParams.sample_message_2?.length <= 20 || formDataParams.sample_message_2?.length >= 1024){
                let errorMessage = getLocalizedStrings().message.TWILIO_SMS_VERIFICATION.MSG_FOR_SAMPLE_MESSAGES_2
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
                dispatch(setIsErrorSMSVerificationForm(false)); 
                // Convert country name to ISO code
                if (formDataParams.country) {
                    formDataParams.country = getISOCodeFromCountryName(formDataParams.country);
                }
                // Convert business industry to uppercase
                if (formDataParams.business_industry) {
                    formDataParams.business_industry = formDataParams.business_industry.toUpperCase();
                }
                // Convert brand type to LOW_VOLUME or STANDARD on the basis of the value
                if (formDataParams.brand_type)
                {
                    if (formDataParams.brand_type === "Low-volume standard $4 one-time fee") {
                        formDataParams.brand_type = "LOW_VOLUME";
                    }else{
                        formDataParams.brand_type = "STANDARD";
                    }
                }
                // Convert company type to lowercase
                if (formDataParams.company_type) {
                    formDataParams.company_type = formDataParams.company_type.toLowerCase();
                }
                // Extract use case code
                if (formDataParams.use_case) {
                    formDataParams = {...formDataParams,use_case: formDataParams.use_case.code};
                }

                let params = {} 

                // customer details tab
                if(selectedForm === form[0]) {
                    if(customerDetails.customer_id && customerDetails.customer_status === "Saved" || customerDetails.customer_status === "draft"){ //after save 
                        params.tab = "customer";
                        params.customerDetails = formDataParams; 
                        params.customer_id = customerDetails.customer_id;
                        params.is_for_submit = true   
                    } else { // directly save and sumbit the form
                        params.tab = "customer";
                        params.customerDetails = formDataParams;
                        params.customer_id = 0
                        params.is_for_submit = true
                    }
                }

                // brand details tab
                if(selectedForm === form[1]) {
                    if(brandDetails.brand_id && brandDetails.brand_status === "Saved"){  //after save
                        params.tab = "brand";
                        params.brandDetails = formDataParams;
                        params.customer_id = customerDetails.customer_id
                        params.brand_id =  brandDetails.brand_id
                        params.is_for_submit=true   
                    } else {  // directly save and sumbit the form
                        params.tab = "brand";
                        params.brandDetails = formDataParams;
                        params.customer_id = customerDetails.customer_id
                        params.brand_id =  0
                        params.is_for_submit=true
                    }
                }

                // campaign details tab
                if(selectedForm === form[2]) { 
                    if(campaignDetails.campaign_id && campaignDetails.campaign_status === "Saved" || campaignDetails.campaign_status === "failure" ){ // after save
                        params.tab = "campaign";
                        params.campaignDetails = formDataParams;
                        params.customer_id = customerDetails.customer_id
                        params.brand_id = brandDetails.brand_id
                        params.campaign_id = campaignDetails.campaign_id
                        params.is_for_submit = true
                    }else { // directly save and sumbit the form
                        params.tab = "campaign";
                        params.campaignDetails = formDataParams;
                        params.customer_id = customerDetails.customer_id
                        params.brand_id = brandDetails.brand_id
                        params.campaign_id = 0
                        params.is_for_submit = true
                    }
                }
                
                const promise = Promise.resolve(HTTPClient.post(endPoints.TWILIO_SMS_VERIFIACTION.SAVE, params));
                    promise.then((response) =>{
                       console.log("response",response);
                       if(response.status === 0) {
                           dispatch(
                               showCustomSnackBar(
                                   getLocalizedStrings().message.TWILIO_SMS_VERIFICATION.FORM_SUCCESSFULLY_SAVE_SUMBIT,
                                   styles.snackbarBodyStyleSuccess,
                                   styles.snackBarStyleTop
                               )
                           );
                           getFormData() 
                       }
                    } ).catch((err) => console.log('err: SaveForm-->', err));
                
            }
        }

    }

    const getDisableValue = () => {
        let disableValue = false ;
        if(selectedForm === form[0] && (customerDetails?.customer_status === 'in-review' || customerDetails.customer_status === 'twilio-approved')) { // customerStatus -> in-review - approved - disable->true
            return disableValue = true;
        } 

        if(selectedForm === form[1] && (brandDetails?.brand_status === 'PENDING' || brandDetails.brand_status === 'APPROVED')){
           return disableValue = true;
        }

        if(selectedForm === form[2] && (campaignDetails?.campaign_status === 'IN_PROGRESS' || campaignDetails.campaign_status === 'success')){
           return disableValue = true
        }
    }

    const saveAndSubmitDisable = getDisableValue();

    if(!isLoading){
        return (
            <div>
                <div style={{ marginLeft: '40px' }}>
                    {/* tabs - customer details - Brand Details - Campaign Details */}
                    <div style={{ display: 'flex', marginTop: '15px'}}>
                        <div style={{ display: 'flex', borderBottom: 'solid 1px #D3D3D3', width: '100%' }}>
                            <Box sx={{ width: '100%'}}>
                                <TabContext value={selectedForm}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChangeForm} aria-label="Form tabs">
                                        <Tab label={form[0]} value= {form[0]}/>
                                        <Tab label={form[1]} value= {form[1]} disabled={brandDetailsTabDisable} />
                                        <Tab label={form[2]} value= {form[2]} disabled={campaignDetailsTabDisable}/>
                                    </TabList>
                                    </Box>
                                    </TabContext>
                            </Box>
                        </div>
                    </div>

                    {/*showing status of forms*/}
                    { selectedForm === form[0] && (
                        <div style={{float: 'right', marginRight: '10px'}}> 
                            <h4>
                                {customerDetails.customer_status === 'in-review' ? 'In-Review' : customerDetails.customer_status === 'twilio-rejected' ? 'Twilio-Rejected' : customerDetails.customer_status === 'twilio-approved' ? 'Twilio-Approved' : " "}
                            </h4>
                        </div>)
                    }

                    { selectedForm === form[1] && (
                        <div style={{float: 'right', marginRight: '10px'}}> 
                            <h4>
                                {(brandDetails.brand_status === 'PENDING' || brandDetails.brand_status === 'in-review' || brandDetails.trust_product_status === 'in-review') ? 'In-Review'  :  brandDetails.brand_status === 'FAILED' ? 'Twilio-Rejected' : brandDetails.brand_status === 'APPROVED' ? 'Twilio-Approved' : " "}
                            </h4>
                        </div>)
                    }

                    { selectedForm === form[2] && (
                        <div style={{float: 'right', marginRight: '10px'}}> 
                            <h4>
                                { campaignDetails.campaign_status === 'IN_PROGRESS' ? 'In-Review' : campaignDetails.campaign_status === 'twilio-rejected' || campaignDetails.campaign_status === 'failure' ? 'Twilio-Rejected' : campaignDetails.campaign_status === 'success' ? 'Twilio-Approved' : " "}
                            </h4>
                        </div>)
                    }

                    <div style={{marginRight: '10%'}}>           
                        {/* Customer Details form */}
                        {selectedForm === form[0] && (
                            <div style={{ width: '100%' }}>
                                <FormFields jsonData={customerDetailsSection1} customerDetails={customerDetails} customerDetailsFormFieldsDisable={customerDetailsFormFieldsDisable} setCustomerDetails={setCustomerDetails} setStateMenuItemList={setStateMenuItemList}  />
                                <span style={{fontWeight: 'bold', fontSize: '18px'}}>{customerDetailsHeaderText.SECTION2}</span>
                                <FormFields jsonData={customerDetailsSection2Data} customerDetails={customerDetails} customerDetailsFormFieldsDisable={customerDetailsFormFieldsDisable} setCustomerDetails={setCustomerDetails} setStateMenuItemList={setStateMenuItemList}/>
                            </div>
                        )}

                        {/* Brand Details form */}
                        {selectedForm === form[1] && (
                            <div style={{ width: '100%' }}>
                                <FormFields jsonData={brandDetailsSection1} brandDetails={brandDetails} setBrandDetails={setBrandDetails} brandDetailsFormFieldsDisable={brandDetailsFormFieldsDisable}/>
                            </div>
                        )}
            
                        {/* Campaign Details Form */}
                        {selectedForm === form[2] && (
                            <div style={{ width: '100%' }}>
                                <FormFields jsonData={campaignDetailsSection1Data} campaignDetails={campaignDetails} setCampaignDetails={setCampaignDetails} campaignDetailsFormFieldsDisable={campaignDetailsFormFieldsDisable} />
                            </div>
                        )}

                        {/* save ,save and sumbit , cancel action button  */}
                        <div style={{ float: 'right', alignItems: 'right', justifyContent: 'right', display: 'flex', paddingBottom:'10px' }}>
                            <Button
                                onClick={() => SaveForm()}
                                style={{
                                    ...styles.primaryButton,
                                    marginRight: '8px',
                                    height: '32px',
                                    backgroundColor: saveAndSubmitDisable ? '#D3D3D3' : app.me.background
                                }}
                                disabled={saveAndSubmitDisable}
                                title={getLocalizedStrings().label.COMMON.SAVE}>
                                {getLocalizedStrings().label.COMMON.SAVE}
                            </Button>
                            <Button
                                onClick={() => SaveAndSubmitForm()}
                                style={{
                                    ...styles.primaryButton,
                                    marginRight: '8px',
                                    height: '32px',
                                    backgroundColor: saveAndSubmitDisable ? '#D3D3D3' : app.me.background
                                }}
                                disabled={saveAndSubmitDisable}
                                title={getLocalizedStrings().label.COMMON.SAVE_SUBMIT}>
                                {getLocalizedStrings().label.COMMON.SAVE_SUBMIT}
                            </Button>
                                <Button
                                    onClick={() => {
                                        dispatch(setIsErrorSMSVerificationForm(false)); 
                                        dispatch(getAppDrawer(null, null, null, null))
                                    }}
                                    style={{
                                        ...styles.primaryButton,
                                        marginRight: '8px',
                                        height: '32px',
                                    }}
                                    title={getLocalizedStrings().label.COMMON.CANCEL}>
                                    {getLocalizedStrings().label.COMMON.CANCEL}
                                </Button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }else{
        let contentHeight = window.innerHeight - 250;
        let top = (contentHeight - 10) / 2;
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }

};
export default TwilioSMSVerification;
