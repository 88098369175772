import React, { useState, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styles } from '../../../services/constants/styles';
import Button from '@mui/material/Button';
import * as ParameterVerifier from '../../../services/helper/parameterVerifier';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useDispatch } from "react-redux";


const UnitCrm = () => {

    const dispatch = useDispatch();

    const [isFormsChecked, setIsFormsChecked] = useState(true);
    const [isWorkflowChecked, setIsWorkflowChecked] = useState(true);
    const [mounted, setMounted] = useState(false);
    const [isStageChecked,setIsStageChecked] = useState(false);
    const [isTaskChecked,setIsTaskChecked] = useState(false);


    const handleInputChange = (isChecked, name) => {
        if (name === "isFormsChecked") {
            setIsFormsChecked(isChecked);
        } else if(name === "isWorkflowChecked"){
            setIsWorkflowChecked(isChecked);
            setIsStageChecked(isChecked);
            setIsTaskChecked(isChecked);
        }else if(name === "isStageChecked"){
            if(isChecked){
                setIsWorkflowChecked(isChecked);
            }else if(!isChecked && !isTaskChecked){
                setIsWorkflowChecked(isChecked);
            }
            setIsStageChecked(isChecked);
        }else if(name === 'isTaskChecked'){
            if(isChecked){
                setIsWorkflowChecked(isChecked);
            }else if(!isChecked && !isStageChecked){
                setIsWorkflowChecked(isChecked);
            }
            setIsTaskChecked(isChecked);
        }
    }


    useEffect(() => {
        getUnitCrmSetup();
    }, []);


    const getUnitCrmSetup = () => {
        try {
            var promise = Promise.resolve(HTTPClient.get(endPoints.UNIT_CRM.UNIT_CRM_SETUP_GET, null));
            if (ParameterVerifier.isValidParam(promise)) {
                promise.then((response) => {
                    if (ParameterVerifier.isValidParam(response)) {
                        let tempIsFormChecked = response.fromCustomize === 1 ? true : false;
                        let tempIsWorkflowChecked = response.workflowCustomize === 1 ? true : false;
                        let _isStageChecked = response.updateStage === 1 ? true : false;
                        let _isTaskChecked = response.updateTasks === 1 ? true : false;
                        setIsFormsChecked(tempIsFormChecked);
                        setIsWorkflowChecked(tempIsWorkflowChecked);
                        setIsStageChecked(_isStageChecked);
                        setIsTaskChecked(_isTaskChecked);
                        setMounted(true);
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'UnitCrm.js -> getUnitCrmSetup()':" + error);
        }

    }

    const saveUnitCrmSetup = () => {
        try {
            //let _state = this.state;
            let params = {};
            params.fromCustomize = isFormsChecked ? 1 : 0;
            params.workflowCustomize = isWorkflowChecked ? 1 : 0;
            params.updateStage = isStageChecked ? 1 : 0;
            params.updateTasks = isTaskChecked ? 1 : 0;
            var promise = Promise.resolve(HTTPClient.post(endPoints.UNIT_CRM.UNIT_CRM_SETUP_SAVE, params));
            if (ParameterVerifier.isValidParam(promise)) {
                promise.then((response) => {
                    if (ParameterVerifier.isValidParam(response)) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.REVIEW_WEBSITE.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    }
                    //this.setState(_state);
                });
            }
        } catch (error) {
            console.error("Error in 'UnitCrm.js -> getUnitCrmSetup()':" + error);
        }

    }


    return (
        <>
            {mounted &&
                <div style={{ marginTop: '40px', marginLeft: '6%' }}>
                    <div style={{ ...styles.sf_12 }}>
                        <div style={{ fontSize: '24px' }}> {getLocalizedStrings().label.COMMON.UNIT_CRM} </div>
                    </div>
                    <div style={{ ...styles.sf_12 }}>
                        <div style={{ fontSize: '18px', marginTop: '18px' }}> {getLocalizedStrings().label.COMMON.UNIT_CRM_LABEL} </div>
                    </div>
                    <div>
                        <box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                            <div style={{ ...styles.sf_12 ,width:'50%'}}>
                                <FormControlLabel label={getLocalizedStrings().label.COMMON.UNIT_CRM_CUSTOMIZE_WORKFLOW} control={<Checkbox color="default" checked={isWorkflowChecked} value={isWorkflowChecked} name="isWorkflowChecked" onChange={(e) => handleInputChange(e.target.checked, "isWorkflowChecked")} defaultChecked={false} />} style={{ marginTop: '15px', display:'inherit' }}  />
                                <div className='WorkflowsubChild' style={{paddingLeft:'30px'}}>
                                    <FormControlLabel control={<Checkbox color="default" checked={isStageChecked} value={isStageChecked} name="isFormsChecked" onChange={(e) => handleInputChange(e.target.checked, "isStageChecked")} defaultChecked={false} />} label={getLocalizedStrings().label.COMMON.UNIT_CRM_UPDATE_STAGES} style={{display:'inherit'}} />
                                    <FormControlLabel control={<Checkbox color="default" checked={isTaskChecked} value={isTaskChecked} name="isTaskChecked" onChange={(e) => handleInputChange(e.target.checked, "isTaskChecked")} defaultChecked={false} />} label={getLocalizedStrings().label.COMMON.UNIT_CRM_UPDATE_TASKS} style={{display:'inherit'}}  />
                                </div>
                            </div>
                            <div style={{ ...styles.sf_12, width:'50%' }}> 
                                <FormControlLabel control={<Checkbox color="default" checked={isFormsChecked} value={isFormsChecked} name="isFormsChecked" onChange={(e) => handleInputChange(e.target.checked, "isFormsChecked")} defaultChecked={true} />} label={getLocalizedStrings().label.COMMON.UNIT_CRM_CUSTOMIZE_FORMS} style={{display:'inherit'}} />
                            </div>
                        </box>
                    </div>
                    <div style={{ marginLeft: '169px' }}>
                        <Button variant="contained" style={{ ...styles.primaryButton, fontSize: 13, marginTop: "20px", }} size="small" onClick={() => saveUnitCrmSetup()} >{getLocalizedStrings().label.COMMON.SAVE} </Button>
                    </div>
                </div>
            }
        </>
    );
};
export default UnitCrm;