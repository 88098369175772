import React from "react";
import store from "../../../src/services/store";
import {
  isValidParam,
  getObjectParam,
  getArrayParam,
  getStringParam,
  getIntParam,
  makeValidObjectName,
} from "./parameterVerifier";
import { constants, OBJECT_TABLEID_MAP } from "..//constants/constants";
import {
  setGroups,
  getMyGroups,
  setRecords,
  setQueryFilter,
  setRecentFilterReport,
  setRecentReport,
} from "..//actions/filterMenuAction";
import { endPoints } from "..//constants/endPoints";
import * as HTTPClient from "..//helper/httpClient";
import filter from "lodash/filter";
import { getLocalizedStrings } from "..//constants/MultiLingual";
import { setCampaignType } from "..//actions/campaignTypeActions";
import {
  REPORT_TYPE_ICON_MAP,
  USER_REPORT_TYPE_ID_NAME_MAP,
} from "..//constants/constants";
import { Icon, IconButton } from "@mui/material";
import { isNull } from "lodash";
/**
 *
 * @Author  : Pradyut.s
 * @Date:   : 2017-08-30
 */

function getTabTitleImageName() {
  let state = store.getState();
  let tabs = getArrayParam(state.tab.tabs);
  let imageName = tabs.length > 0 ? tabs[0].imgName : "";
  return imageName;
}

function addGroupIntoReduxStore(id, name, status) {
  let recentFilterObject = ["accounts", "contacts", "opportunities"];
  let state = store.getState();
  let groups = getArrayParam(state.filters.groups);
  let tempGroup = groups.filter((f) => {
    return f.id === id;
  });
  if (tempGroup.length > 0) {
    groups.forEach((item) => {
      if (item.id === id) {
        item.name = name;
        item.status = status;
      }
    });
  } else {
    groups.push({ id: id, name: name, type: "group", status: status });
    sortArrayObjectByProperty(groups, "name");
  }

  recentFilterObject.forEach((m) => {
    getArrayParam(state.filters.recentFilters[m]).forEach((m) => {
      if (m.type === "group" && m.id === id && m.name !== name) {
        m.name = name;
      }
    });
  });

  let groupDetails = {
    groups: groups,
    user_saved_status: getObjectParam(state.filters.user_group_status),
  };
  store.dispatch(setGroups(groupDetails));
}

function sortArrayObjectByProperty(array, property) {
  let temp;
  if (Array.isArray(array)) {
    array.sort((obj1, obj2) => {
      if (
        isValidParam(obj1) &&
        obj1.hasOwnProperty(property) &&
        isValidParam(obj2) &&
        obj2.hasOwnProperty(property)
      ) {
        let val1 = obj1[property];
        let val2 = obj2[property];

        val1 = val1 instanceof String ? val1.toLowerCase() : val1;
        val2 = val2 instanceof String ? val2.toLowerCase() : val2;

        if (val1 > val2) {
          temp = 1;
        } else if (val1 < val2) {
          temp = -1;
        } else {
          temp = 0;
        }
      }
      return temp;
    });
  }
}

function addFilterToReduxStore(addFilterObject, object) {
  let state = store.getState();
  let queries = state.filters.queries;

  let filters = state.filters.queries[object];

  if (
    addFilterObject.hasOwnProperty("modifyType") &&
    addFilterObject.modifyType === "remove"
  ) {
    let updateFilters = filter(filters, (item, index) => {
      return item.id !== addFilterObject.id;
    });
    queries[object] = updateFilters;
    store.dispatch(setQueryFilter({ ...queries }));
    return;
  }

  let selectedRecord = filter(filters, (item, index) => {
    return item.id === addFilterObject.id;
  });

  if (selectedRecord.length === 1) {
    selectedRecord[0]["name"] = addFilterObject.name;
  } else {
    filters.push(addFilterObject);
  }

  store.dispatch(setQueryFilter(queries));
}

function hasAccessPermission(object, type, value) {
  let state = store.getState();
  object = getStringParam(object);
  type = getStringParam(type);
  let tableId = getStringParam(OBJECT_TABLEID_MAP[object]);
  if (type === constants.SECURITY_LEVEL_TYPE_ACCESS) {
    let accessList = getObjectParam(state.app.securityInfo.accessList);
    let arrSecurityInfo = getArrayParam(accessList[tableId]);
    arrSecurityInfo = arrSecurityInfo.filter((f) => {
      return (
        f.tempId === state.app.me.t_id &&
        f.type === constants.USER_TEMPLATE_FILTER
      );
    });
    let permission = false;
    if (arrSecurityInfo.length === 0) {
      permission = true;
    } else {
      arrSecurityInfo = arrSecurityInfo.filter((f) => {
        return f.selected;
      });
      let securityInfo =
        arrSecurityInfo.length > 0 ? getObjectParam(arrSecurityInfo[0]) : {};
      let strFlag = getStringParam(securityInfo.flag);
      if (strFlag.indexOf(value) > -1) {
        permission = true;
      }
    }
    return permission;
  } else if (type === constants.SECURITY_LEVEL_TYPE_PERMISSION) {
    let permission = getObjectParam(state.app.securityInfo.permission);
    let permObj = getObjectParam(permission[value]);
    return permObj.checked ? true : false;
  }
  return false;
}

function isObjectChildExists(object, childObject) {
  let state = store.getState();
  object = getStringParam(object);
  let tableId = getIntParam(OBJECT_TABLEID_MAP[object]);
  let childTableId = getIntParam(OBJECT_TABLEID_MAP[childObject]);
  let linkChildArrList = getObjectParam(state.app.linkChildInfo);
  if (linkChildArrList !== null && linkChildArrList !== "") {
    let childObjectInfo = getArrayParam(linkChildArrList[tableId]);
    childObjectInfo = childObjectInfo.filter((f) => {
      return f.childTableId === childTableId;
    });
    return childObjectInfo.length > 0 ? true : false;
  }
  return false;
}

function getChildObjectInfo(object, childObject) {
  let arrChildObjectInfo = [];
  let state = store.getState();
  object = getStringParam(object);
  let tableId = getIntParam(OBJECT_TABLEID_MAP[object]);
  let childTableId = getIntParam(OBJECT_TABLEID_MAP[childObject]);
  let linkChildArrList = getObjectParam(state.app.linkChildInfo);
  if (isValidParam(linkChildArrList)) {
    arrChildObjectInfo = getArrayParam(linkChildArrList[tableId]);
    arrChildObjectInfo = arrChildObjectInfo.filter((f) => {
      return f.childTableId === childTableId;
    });
  }
  return arrChildObjectInfo;
}

function retriveEscapeHtml(unsafe) {
  let returnString = "";

  if (typeof unsafe === "string") {
    returnString = unsafe.replace(/&quot;/g, '"');
    returnString = returnString.replace(/&#039;/g, "'");
    returnString = returnString.replace(/&#39;/g, "'");
    returnString = returnString.replace(/&lt;/g, "<");
    returnString = returnString.replace(/&gt;/g, ">");
    returnString = returnString.replace(/&amp;/g, "&");
    returnString = returnString.replace(/&nbsp;/g, " ");
  } else {
    returnString = unsafe;
  }

  return returnString;
}

function escapeHtml(unsafe) {
  if (typeof unsafe === "string") {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;")
      .replace(/\\/g, "&#092;")
      .replace(/\//g, "&#047;");
  } else {
    return unsafe;
  }
}

function getUserInfo(initialState) {
  let state = initialState || store.getState();
  let userInfo = getObjectParam(state.app.me);
  return userInfo;
}

function setSelectedModuleInfo(selectedObjectName, moduleItemId = 0, item) {
  let state = store.getState();
  let selectedModuleName = null;
  let selectedModuleInfo = null;
  let selectedModuleItems = null;
  let selectedModuleItem = null;
  try {
    selectedObjectName = getStringParam(selectedObjectName);
    selectedModuleName = getStringParam(state.app.selectedModuleName);
    selectedModuleItems = getArrayParam(state.app.selectedModuleItems);
    selectedModuleInfo = getObjectParam(state.app.selectedModuleInfo);
    if (selectedModuleName !== "") {
      selectedModuleItems = selectedModuleItems.filter((f) => {
        return f.object === selectedObjectName;
      });
      selectedModuleItem =
        selectedModuleItems.length > 0 ? selectedModuleItems[0] : {};

      if (moduleItemId > 0) {
        selectedModuleItem =
          selectedModuleItems.find((f) => f.id === moduleItemId) ||
          selectedModuleItem;
      }
      if (item) {
        selectedModuleItem = item;
      }
      selectedModuleInfo[selectedModuleName] = {
        object: selectedObjectName,
        item: selectedModuleItem,
      };
      state.app.selectedModuleInfo = selectedModuleInfo;
      let objects = new Set();
      objects.add(selectedObjectName);
      if (!isNull(state.app.selectedSearchObject))
        state.app.selectedSearchObject[selectedModuleName] = objects;
    }
  } catch (error) {
    console.error("Error in 'common.js -> setSelectedModuleInfo()':" + error);
  }
}

function getSelectedModuleInfo() {
  let state = store.getState();
  let selectedModuleInfo = null;
  let selectedModuleName = null;
  let selectedObjectInfo = null;
  try {
    selectedModuleName = getStringParam(state.app.selectedModuleName);
    selectedModuleInfo = getObjectParam(state.app.selectedModuleInfo);
    selectedObjectInfo = getObjectParam(selectedModuleInfo[selectedModuleName]);
  } catch (error) {
    console.error("Error in 'common.js -> getSelectedModuleInfo()':" + error);
  }
  return selectedObjectInfo;
}

function getSelectedObject(argApp, initialState) {
  let state = initialState || store.getState();
  let selectedModuleInfo = null;
  let selectedModuleName = null;
  let selectedObjectInfo = null;
  let selectedObject = "";
  try {
    let app = isValidParam(argApp) ? argApp : state.app;
    selectedModuleName = getStringParam(app.selectedModuleName);
    selectedModuleInfo = getObjectParam(app.selectedModuleInfo);
    selectedObjectInfo = getObjectParam(selectedModuleInfo[selectedModuleName]);
    if (
      isValidParam(selectedObjectInfo.item) &&
      selectedObjectInfo.item.type === "Calendar"
    ) {
      selectedObject = selectedObjectInfo.item.name;
    } else {
      selectedObject = getStringParam(selectedObjectInfo.object);
    }
  } catch (error) {
    console.error("Error in 'common.js -> getSelectedObject()':" + error);
  }
  return selectedObject;
}
function getSelectedModuleItem() {
  let state = store.getState();
  let selectedModuleItem = {};
  try {
    let app = state.app;
    let selectedModuleName = getStringParam(app.selectedModuleName);
    let selectedModuleInfo = getObjectParam(app.selectedModuleInfo);
    let selectedObjectInfo = getObjectParam(
      selectedModuleInfo[selectedModuleName]
    );
    selectedModuleItem = getObjectParam(selectedObjectInfo.item);
  } catch (error) {
    console.error("Error in 'common.js -> getSelectedModuleItem()':" + error);
  }
  // console.log('selectedModuleItem=>', selectedModuleItem)
  return selectedModuleItem;
}

function getSelectedObjectLabel() {
  let selectedObjectInfo = null;
  let selectedObjectLabel = "";
  try {
    selectedObjectInfo = getObjectParam(getSelectedModuleInfo());
    selectedObjectLabel = isValidParam(selectedObjectInfo.item)
      ? getStringParam(selectedObjectInfo.item.label)
      : "";
  } catch (error) {
    console.error("Error in 'common.js -> getSelectedObjectLabel()':" + error);
  }
  return selectedObjectLabel;
}

function isLinkedChildObject(parentObject, childObject) {
  let isLinked = false;
  try {
    let state = store.getState();
    parentObject = getStringParam(parentObject);
    childObject = getStringParam(childObject);
    if (parentObject !== "" && childObject !== "") {
      let linkChildInfo = getObjectParam(state.app.linkChildInfo);
      let linkObjects = linkChildInfo[OBJECT_TABLEID_MAP[parentObject]];
      linkObjects = getArrayParam(linkObjects);
      linkObjects = linkObjects.filter((f) => {
        return f.objectName === childObject;
      });
      isLinked = linkObjects.length > 0 ? true : false;
    }
  } catch (error) {
    console.error("Error in 'common.js -> isLinkedObject()':" + error);
  }
  return isLinked;
}

function getImageNameByObject(object, initialState) {
  let imageName = "";
  try {
    object = getStringParam(object);
    if (object !== "") {
      if (object === constants.TASKS) {
        imageName = "fa fa-clock-o";
      } else if (object === constants.EMAIL_OBJECT) {
        imageName = "fa fa-envelope";
      } else if (object === constants.SMS_OBJECT) {
        imageName = "fa fa-comment-alt";
      } else if (object === constants.OPERATIONS_RECENT_CAMPAIGNS) {
        imageName = "fa fa-bars";
      } else {
        let state = initialState || store.getState();
        if (state.app.home && state.app.home.objectIconImageMap)
          imageName = getStringParam(state.app.home.objectIconImageMap[object]);
      }
    }
  } catch (error) {
    console.error("Error in 'common.js -> getImageNameByObject()':" + error);
  }
  return imageName;
}
function sortHomeItems(data, state) {
  state = state || store.getState();

  let arrModules = [
    constants.MODULE_HUB,
    constants.MODULE_SALES,
    constants.MODULE_MARKETING,
    constants.MODULE_SERVICE,
    constants.MODULE_OPERATIONS,
    constants.MODULE_SALES_MARKETING,
  ];
  let objectIconImageMap = {};
  let moduleMap = {};
  try {
    let multiObjectList = getArrayParam(data.multiObjectList);
    let modules = isValidParam(data) ? getArrayParam(data.modules) : [];
    let marketing = [],
      sales = [],
      hub = [],
      salesMarketing = [];

    let allModuleItems = [];
    arrModules.forEach((name) => {
      let module = modules.find((f) => {
        return f.module === name;
      });
      if (isValidParam(module)) {
        moduleMap[name] = module;
        allModuleItems.push(...module.module_items);
        if (name === constants.MODULE_HUB) {
          hub = getArrayParam(module.module_items);
        } else if (name === constants.MODULE_SALES) {
          sales = getArrayParam(module.module_items);
        } else if (name === constants.MODULE_MARKETING) {
          marketing = getArrayParam(module.module_items);
        } else if (name === constants.MODULE_SERVICE) {
          //service = getArrayParam(module.module_items);
        } else if (name === constants.MODULE_OPERATIONS) {
          //operations = getArrayParam(module.module_items);
        } else if (name === constants.MODULE_SALES_MARKETING) {
          salesMarketing = getArrayParam(module.module_items);
        }
      }
    });

    /** Start : filter all templates */
    let templateItems = [];
    if (sales.length > 0) {
      let emailTemplate = sales.find((f) => {
        return f.object === constants.SALES_TEMPLATE;
      });
      let smsTemplate = sales.find((f) => {
        return f.object === "sms_templates";
      });
      let callScript = sales.find((f) => {
        return f.object === "call_scripts";
      });
      let agreementTemplate = sales.find((f) => {
        return f.object === "agreement_templates";
      });

      if (isValidParam(emailTemplate)) {
        emailTemplate = {
          ...emailTemplate,
          name: "Sales Email Templates",
          label: "Sales Email Templates",
        };
        templateItems.push(emailTemplate);
      }
      if (isValidParam(smsTemplate)) {
        templateItems.push(smsTemplate);
      }
      if (isValidParam(callScript)) {
        templateItems.push(callScript);
      }
      if (isValidParam(agreementTemplate)) {
        templateItems.push(agreementTemplate);
      }
    }
    if (marketing.length > 0) {
      let marketingTemplate = marketing.find((f) => {
        return f.object === "marketing_templates";
      });
      if (isValidParam(marketingTemplate)) {
        marketingTemplate = {
          ...marketingTemplate,
          name: "Marketing Email Templates",
          label: "Marketing Email Templates",
        };
        templateItems.splice(1, 0, marketingTemplate);
      }
    }
    /** End */

    arrModules.forEach((name) => {
      let module = modules.find((f) => {
        return f.module === name;
      });
      if (isValidParam(module)) {
        moduleMap[name] = module;
        let moduleObjectList = getArrayParam(module.objectList);
        let moduleItems = getArrayParam(module.module_items);
        let sortedModuleItems = [];
        moduleObjectList.forEach((obj, index) => {
          let items = moduleItems.filter((f) => {
            return f.object === obj && obj === "appointments";
          });

          if (obj === constants.LEAD_CAPTURE) {
            let newItems = [];
            let tempItems = moduleItems.filter((f) => {
              return f.object === obj;
            });
            if (tempItems.length > 0) {
              ["landing_pages_froala", "web_forms"].forEach((obj) => {
                let temp = moduleItems.filter((f) => {
                  return f.object === obj;
                });
                if (temp.length > 0) {
                  newItems.push(temp[0]);
                }
              });

              let leadCapture = { ...tempItems[0], sub_items: newItems };
              sortedModuleItems.push(leadCapture);
            }
          } else if (obj === constants.EMAIL_MARKETING) {
            let tempItems = moduleItems.filter((f) => {
              return f.object === obj;
            });
            if (tempItems.length > 0) {
              items = moduleItems.filter((f) => {
                return (
                  f.object === "automation_designer" ||
                  f.object === "broadcast_campaigns" ||
                  f.object === "marketing"
                );
              });
              // marketingTemplate = marketing.find(f => { return f.object == 'marketing_templates' });
              let newItems = [];
              [
                "marketing_templates",
                "broadcast_campaigns",
                "automation_designer",
                "marketing",
              ].forEach((obj) => {
                let temp = items.filter((f) => {
                  return f.object === obj;
                });
                if (temp.length > 0) {
                  newItems.push(temp[0]);
                }
              });

              let emailMarketing = { ...tempItems[0], sub_items: newItems };
              sortedModuleItems.push(emailMarketing);
            }
          } else if (
            (obj === "social_marketing" ||
              obj === constants.SOCIAL_MARKETING) &&
            state.app.me?.is_distributed_marketing
          ) {
            let tempItems = moduleItems.filter((f) => {
              return f.object === "social_marketing";
            });
            if (tempItems.length > 0) {
              let newItems = [];
              newItems.push({
                has_filter: false,
                home_name: "Marketing",
                image_name: "fa fa-bullhorn",
                is_accessible: true,
                is_show: 1,
                is_system_object: false,
                label: "For Me",
                name: "For Me",
                object: "social-marketing",
                temp_object: "for_me",
                type: "Social Marketing",
              });
              newItems.push({
                has_filter: false,
                home_name: "Marketing",
                image_name: "fa fa-bullhorn",
                is_accessible: true,
                is_show: 1,
                is_system_object: false,
                label: "For My Units",
                name: "For My Units",
                object: "social-marketing",
                temp_object: "for_my_units",
                type: "Social Marketing",
              });
              let socialMarketing = { ...tempItems[0], sub_items: newItems };
              sortedModuleItems.push(socialMarketing);
            }
          } else if (
            obj === "sales-sales_templates" ||
            obj === "marketing-sales_templates"
          ) {
            // obj == constants.SALES_TEMPLATE
            let tempItems = moduleItems.find((f) => {
              return f.temp_object === obj;
            });
            if (isValidParam(tempItems)) {
              sortedModuleItems.push({
                ...tempItems,
                object: constants.SALES_MODULE_TEMPLATE,
                sub_items: templateItems,
              });
            }
          } else if (obj === constants.OFFERS) {
            let tempItems = moduleItems.filter((f) => {
              return f.object === obj;
            });
            if (tempItems.length > 0) {
              items = moduleItems.filter((f) => {
                return f.object === constants.COUPONS || "coupons_reports";
              });
              let newItems = [];
              [constants.COUPONS, "coupons_reports"].forEach((obj) => {
                let temp = items.filter((f) => {
                  return f.object === obj;
                });
                if (temp.length > 0) {
                  newItems.push(temp[0]);
                }
              });

              let couponsOffers = { ...tempItems[0], sub_items: newItems };

              sortedModuleItems.push(couponsOffers);
            }
          } else if (obj === constants.HUB) {
            let tempItems = moduleItems.filter((f) => {
              return f.object === obj;
            });
            if (tempItems.length > 0) {
              let newItems = [];
              ["operations-news", "operations-useful-link"].forEach((obj) => {
                let temp = moduleItems.find((f) => {
                  return f.temp_object === obj;
                });
                if (temp) {
                  newItems.push(temp);
                }
              });

              let hub = { ...tempItems[0], sub_items: newItems };
              sortedModuleItems.push(hub);
            }
          } else if (obj === constants.ONBOARDING_OBJECT) {
            let onboarding = moduleItems.find((f) => {
              return f.object === obj;
            });
            let project = moduleItems.find((f) => {
              return f.temp_object === "operations-projects";
            });
            if (isValidParam(onboarding) && isValidParam(project)) {
              let newItems = [
                {
                  ...onboarding,
                  name: "Ongoing Onboarding",
                  label: getLocalizedStrings(state).label.COMMON.ONGOING,
                },
                project,
              ];

              let onboardingObject = {
                ...onboarding,
                object: constants.OPERATIONS_ONBOARDING,
                sub_items: newItems,
              };
              sortedModuleItems.push(onboardingObject);
            }
          } else if (obj === constants.OPERATIONS_REPORTS) {
            let tempItems = moduleItems.filter((f) => {
              return f.object === obj;
            });
            console.log("moduleItems=======>   ",moduleItems)
            let tempObj = {
              "is_system_object": false,
              "is_accessible": true,
              "label": "Audit & Compilance",
              "type": "Audit_Compilance",
              "is_show": 1,
              "image_name": "fa fa-bars",
              "home_name": "Operations",
              "name": "Audit & Compilance",
              "temp_object": "Audit_Compilance",
              "id": 3067587,
              "has_filter": false,
              "parameter_id": 135,
              "object": "Audit_Compilance"
          }
            if (tempItems.length > 0) {
              let items = moduleItems.filter((f) => {
                return (
                  f.object === "operations_ACTIVITIES" ||
                  f.object === "operations_CONVERSIONS" ||
                  f.object === "operations_CAMPAIGNS" ||
                  f.object === "custom_operations_Reports" ||
                  f.object === "operations_ASSETS_REPORTS" ||
                  f.object === "sales_refund" ||
                  f.object === "royalty_report" ||
                  f.object === "royalty_report_v2" ||
                  f.object === "compliance_report" ||
                  f.object === "audit_report" ||
                  f.object === "audit_weakness_report" ||
                  f.object === "audit_performance_report" ||
                  f.object === "sales_pipeline_report" ||
                  f.object === "corrective_action_report"||
                  f.object === constants.ONBOARDING_OBJECT ||
                  f.object === constants.OPERATIONS_DASHBOARD
                );
              });
              if (items.length > 0) {
                let newItems = [];

                [
                  constants.OPERATIONS_DASHBOARD,
                  constants.ONBOARDING_OBJECT,
                  "royalty_report_v2",
                  "sales_refund",
                  "royalty_report",
                  "compliance_report",
                  "audit_report",
                  "audit_weakness_report",
                  "corrective_action_report",
                  "audit_performance_report",
                  "operations_ASSETS_REPORTS",
                  "operations_ACTIVITIES",
                  "sales_pipeline_report",
                  "operations_CONVERSIONS",
                  "operations_CAMPAIGNS",
                  "custom_operations_Reports",

                ].forEach((obj) => {
                  console.log("items=========>   ",items);
                  console.log("obj======>  ",obj)
                  let temp = items.filter((f) => {
                    return f.object === obj;
                  });
                  let auxTemp = {};

                  if (temp.length > 0) {
                    if (obj === "operations_ACTIVITIES") {
                      temp[0].label =
                        getLocalizedStrings(
                          state
                        ).label.MODULE_ITEMS.Activities;
                    } else if (obj === "operations_CONVERSIONS") {
                      temp[0].label =
                        getLocalizedStrings(
                          state
                        ).label.MODULE_ITEMS.Conversion;
                    } else if (obj === "operations_CAMPAIGNS") {
                      temp[0].label =
                        getLocalizedStrings(state).label.MODULE_ITEMS.Campaigns;
                      auxTemp = {
                        ...temp[0],
                      };
                      auxTemp.label = "Recent Campaign Report";
                      auxTemp.object = "operations_RECENT_CAMPAIGNS";
                    } else if (obj === "custom_operations_Reports") {
                      temp[0].label =
                        getLocalizedStrings(state).label.MODULE_ITEMS.Custom;
                    } else if (obj === "operations_ASSETS_REPORTS") {
                      temp[0].label =
                        getLocalizedStrings(
                          state
                        ).label.MODULE_ITEMS.ASSETS_DISTRIBUTION;
                    } else if (obj === constants.OPERATIONS_DASHBOARD) {
                      temp[0].label =
                        getLocalizedStrings(state).label.MODULE_ITEMS.Dashboard;
                    } else if (obj === constants.ONBOARDING_OBJECT) {
                      temp[0].label =
                        getLocalizedStrings(state).label.HEADER.ONBOARDING;
                    } /*else if (obj === 'compliance_report') {
                                            temp[0].label = getLocalizedStrings(state).label.MODULE_ITEMS.COMPLIANCE_REPORT;
                                        }*/
                    newItems.push(temp[0]);
                    newItems.push(auxTemp);
                  }
                });

                let operationsReports = {
                  ...tempItems[0],
                  sub_items: newItems,
                };
                sortedModuleItems.push(operationsReports);
              }
            }
          } else if (obj === constants.OPERATIONS_ORDER_OBJECT) {
            let items = moduleItems.filter((f) => {
              return f.object === obj;
            });
            let newItems = [];
            [
              constants.PURCHASE_ORDER_OBJECT,
              constants.SALES_ORDER_OBJECT,
              constants.INVENTORY_OBJECT,
            ].forEach((obj) => {
              let temp = moduleItems.filter((f) => {
                return f.object === obj;
              });
              if (temp.length > 0) {
                newItems.push(temp[0]);
              }
            });
            if (items.length > 0) {
              let tempObj = { ...items[0], sub_items: newItems };
              sortedModuleItems.push(tempObj);
            }
          } else if (obj === constants.REPORTS_DASHBORD) {
            let items = moduleItems.filter((f) => {
              return f.object === obj;
            });
            let newItems = [];
            let salesReports = moduleItems.find((f) => {
              return f.object === constants.SALES_OBJECT;
            });
            let salesDashboard = moduleItems.find((f) => {
              return f.temp_object === "sales-dashboard";
            });
            if (salesDashboard) {
              newItems.push(salesDashboard);
            }
            if (salesReports) {
              newItems.push(salesReports);
            }

            if (items.length > 0) {
              let tempObj = { ...items[0], sub_items: newItems };
              sortedModuleItems.push(tempObj);
            }
          } else if (obj === constants.ROYALTY) {
            let newItems = [];
            let tempItems = moduleItems.filter((f) => {
              return f.object === obj;
            });
            if (tempItems.length > 0) {
              ["royaltyrun", "royaltyplan"].forEach((obj) => {
                let temp = moduleItems.filter((f) => {
                  return f.object === obj;
                });
                if (temp.length > 0) {
                  newItems.push(temp[0]);
                }
              });

              let royalty = { ...tempItems[0], sub_items: newItems };
              sortedModuleItems.push(royalty);
            }
          } else if (multiObjectList.includes(obj)) {
            let items = moduleItems.filter((f) => {
              return f.temp_object === obj;
            });
            if (items.length > 0) {
              let tempObj = items[0];
              sortedModuleItems.push(tempObj);
            }
          } else {
            let items = moduleItems.find((f) => {
              return f.object === obj;
            });
            if (isValidParam(items)) {
              sortedModuleItems.push(items);
            }
            if (
              name === constants.MODULE_MARKETING ||
              name === constants.MODULE_SALES
            ) {
              if (obj === "contacts") {
                let contactItems = salesMarketing.filter((f) => {
                  return f.object === obj;
                });
                if (contactItems.length > 0) {
                  sortedModuleItems.push(contactItems[0]);
                }
              }
            }
          }
        });
        moduleMap[name].module_items = sortedModuleItems;
      }
    });

    allModuleItems.forEach((element) => {
      let tempObject = makeValidObjectName(element.object);
      objectIconImageMap[tempObject] = getStringParam(element.image_name);
    });

    /** START : HUB */
    if (hub.length > 0) {
      hub.forEach((item, i) => {
        let object = getStringParam(item.object);
        let imageName = getStringParam(item.image_name);
        if (object !== "" && imageName !== "") {
          objectIconImageMap[object] = imageName;
        }
      });
      // if (hub.length > 0 && moduleMap.hasOwnProperty(constants.MODULE_HUB)) {
      //     moduleMap[constants.MODULE_HUB].module_items = hub;
      // }
    }
    let homeItemLableList = [];
    arrModules.forEach((name) => {
      let moduleObj = moduleMap[name];
      if (isValidParam(moduleObj)) {
        getArrayParam(moduleObj.module_items).forEach((item) => {
          homeItemLableList.push({
            id: item.id,
            homeId: moduleObj.homeId,
            object: makeValidObjectName(item.object),
            name: item.name,
            label: item.label,
          });
          if (getArrayParam(item.sub_items).length > 0) {
            getArrayParam(item.sub_items).forEach((subItem) => {
              homeItemLableList.push({
                id: subItem.id,
                homeId: moduleObj.homeId,
                object: makeValidObjectName(subItem.object),
                name: subItem.name,
                label: subItem.label,
              });
            });
          }
        });
      }
    });

    if (isValidParam(state)) {
      objectIconImageMap[constants.NOTES] = "fa fa-pencil-square-o";
      objectIconImageMap[constants.GROUP_OBJECT] = "fa fa-users";
      objectIconImageMap[constants.SEARCH_RESULTS] = "fa fa-search";
      objectIconImageMap[constants.ADVANCE_SEARCH_RESULTS] = "fa fa-search";
      //state.app.objectIconImageMap = objectIconImageMap;
      //state.app.homeItemLableList = homeItemLableList;
    }

    data.modules = [];
    data.objectIconImageMap = objectIconImageMap;
    data.homeItemLableList = homeItemLableList;
    arrModules.forEach((name) => {
      let module = moduleMap[name];
      if (isValidParam(module)) {
        data.modules.push(module);
      }
    });
  } catch (error) {
    console.error("Error in 'common.js -> sortHomeItems()':" + error);
  }
  return data;
}

function getLabelByObjectAndHomeId(object) {
  let objectLable = "";
  try {
    if (getStringParam(object) !== "") {
      let state = store.getState();
      let selectedModuleName = getStringParam(state.app.selectedModuleName);
      let selectedModuleInfo = getObjectParam(state.app.selectedModuleInfo);
      let modules = getArrayParam(state.app.home.modules);
      let moduleObj = modules.find((f) => f.module === selectedModuleName);
      if (moduleObj) {
        let homeItemLableList = getArrayParam(state.app.homeItemLableList);
        let items = homeItemLableList.filter(
          (f) => f.homeId === moduleObj.homeId && f.object === object
        );
        if (items.length > 0) {
          let item = items[0];
          if (selectedModuleInfo[selectedModuleName]) {
            let selectedModuleItem = selectedModuleInfo[selectedModuleName];
            item =
              items.find(
                (f) => f.id === getObjectParam(selectedModuleItem.item).id
              ) || item;
          }
          if (item) {
            objectLable = item.label;
          }
        }
      }
    }
  } catch (error) {
    console.error(
      "Error in 'common.js -> getLabelByObjectAndHomeId()':" + error
    );
  }
  return objectLable;
}

function getObjectLabelByObject(object) {
  let objectLabel = "";
  try {
    object = getStringParam(object);
    if (object !== "") {
      objectLabel = getLabelByObjectAndHomeId(object);
      if (objectLabel === "") {
        let state = store.getState();
        let objectList = getArrayParam(state.app.objectList);
        let tempList = objectList.filter((f) => {
          return f.name === object;
        });
        if (tempList.length > 0) {
          objectLabel = getStringParam(tempList[0].label);
        }
      }

      if (objectLabel === "") {
        if (object === constants.GROUP_OBJECT) {
          objectLabel = "Groups";
        } else if (object === constants.APPOINTMENTS) {
          objectLabel = "Appointments";
        } else if (object === constants.TASKS) {
          objectLabel = "Tasks";
        } else if (object === constants.AB_CAMPAIGNS_OBJECT) {
          objectLabel = "AB Campaigns";
        } else if (object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
          objectLabel = "Broadcast Campaigns";
        } else if (object === constants.ONLINE_REPUTATION) {
          objectLabel = "Online Reputation";
        } else if (object === constants.SOCIAL_MARKETING) {
          objectLabel = "Social Marketing";
        } else if (object === constants.BUSINESS_LISTINGS) {
          objectLabel = "Business Listings";
        } else if (object === constants.MARKETING_TEMPLATE_OBJECT) {
          objectLabel = "Marketing Templates";
        } else if (object === constants.SALES_TEMPLATE_OBJECT) {
          objectLabel = "Sales Templates";
        } else if (object === constants.NOTES) {
          objectLabel = "Notes";
        } else if (object === constants.UNITS_OBJECT) {
          objectLabel = "Units";
        } else if (object === constants.ASSETS_OBJECT) {
          objectLabel = "Assets";
        } else if (object === constants.DASHBOARD) {
          objectLabel = "Dashboard";
        } else if (object === constants.RECYCLE_BIN_OBJECT) {
          objectLabel = "Accounts";
        } else if (object === constants.SMS_TEMPLATE_OBJECT) {
          objectLabel = "SMS Template";
        } else if (object === constants.PETS_OBJECT) {
          objectLabel = "Pet";
        } else if (object === constants.SALES_OBJECT) {
          objectLabel = "Reports";
        } else if (object === constants.CALL_SCRIPT_OBJECT) {
          objectLabel = "Call Scripts";
        } else if (object === constants.LINE_ITEMS_OBJECT) {
          objectLabel = "Line Items";
        } else if (object === constants.SALES_ORDER_OBJECT) {
          objectLabel = "Sales Order";
        }
        /*else if (object == constants.QUOTATION_OBJECT) {
                    objectLabel = 'Quotes & Invoices'
                }*/
      }
      if (
        objectLabel &&
        isValidParam(
          getLocalizedStrings().label.SIDE_NAV[objectLabel.toUpperCase()]
        )
      ) {
        objectLabel =
          getLocalizedStrings().label.SIDE_NAV[objectLabel.toUpperCase()];
      }
    }
  } catch (error) {
    console.error("Error in 'common.js -> getObjectLabelByObject()':" + error);
  }
  return objectLabel;
}

function saveRecentData(object, params) {
  let endPoint = null;
  if (object === constants.ACCOUNTS_OBJECT) {
    endPoint = endPoints.ACCOUNTS.SAVE_RECENT_RECORD;
  } else if (object === constants.CONTACTS_OBJECT) {
    endPoint = endPoints.CONTACTS.SAVE_RECENT_RECORD;
  } else if (object === constants.OPPORTUNITIES_OBJECT) {
    endPoint = endPoints.OPPORTUNITIES.SAVE_RECENT_RECORD;
  } else if (object === constants.PRODUCTS_OBJECT) {
    endPoint = endPoints.PRODUCTS.SAVE_RECENT_RECORD;
  } else if (object === constants.CASES_OBJECT) {
    endPoint = endPoints.CASES.SAVE_RECENT_RECORD;
  } else if (object === constants.PROJECTS_OBJECT) {
    endPoint = endPoints.PROJECTS.SAVE_RECENT_RECORD;
  } else if (object === constants.ISSUES_OBJECT) {
    endPoint = endPoints.ISSUES.SAVE_RECENT_RECORD;
  } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
    endPoint = endPoints.CUSTOM_TABLE1.SAVE_RECENT_RECORD;
  } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
    endPoint = endPoints.CUSTOM_TABLE2.SAVE_RECENT_RECORD;
  } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
    endPoint = endPoints.CUSTOM_TABLE3.SAVE_RECENT_RECORD;
  } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
    endPoint = endPoints.CUSTOM_TABLE4.SAVE_RECENT_RECORD;
  } else if (object === constants.COUPONS_OBJECT) {
    endPoint = endPoints.COUPONS.SAVE_RECENT_RECORD;
  } else if (object === constants.SALES_LEADS_OBJECT) {
    endPoint = endPoints.SALES_LEADS.SAVE_RECENT_RECORD;
  } else if (object === constants.PETS_OBJECT) {
    endPoint = endPoints.PETS.SAVE_RECENT_RECORD;
  } else if (object === constants.SALES_OBJECT) {
    endPoint = endPoints.SALES_REPORT.SAVE_RECENT_REPORT;
  } else if (object === constants.ORDERS_OBJECTS) {
    endPoint = endPoints.ORDERS.SAVE_RECENT_RECORD;
  } else if (object === constants.ORDER_ITEMS_OBJECTS) {
    endPoint = endPoints.ORDER_ITEMS.SAVE_RECENT_RECORD;
  } else if (object === constants.QUOTATION_OBJECT) {
    endPoint = endPoints.QUOTATIONS.SAVE_RECENT_RECORD;
  } else if (object === constants.PURCHASE_ORDER_OBJECT) {
    endPoint = endPoints.PURCHASE_ORDER.SAVE_RECENT_RECORD;
  } else if (object === constants.SALES_ORDER_OBJECT) {
    endPoint = endPoints.SALES_ORDER.SAVE_RECENT_RECORD;
  } else if (object === constants.INVENTORY_OBJECT) {
    endPoint = endPoints.INVENTORY.SAVE_RECENT_RECORD;
  } else if (object === constants.JOBS_OBJECT) {
    endPoint = endPoints.JOBS.SAVE_RECENT_RECORD;
  } else if (object === constants.COMPLIANCE_OBJECT) {
    endPoint = endPoints.COMPLIANCE.SAVE_RECENT_RECORD;
  }
  var promise = Promise.resolve(HTTPClient.post(endPoint, params));
  return promise;
}

function getRecentRecords(object) {
  let url = null;
  // let type = actions.RECENT_RECORD_GET;
  if (object === constants.ACCOUNTS_OBJECT) {
    url = endPoints.ACCOUNTS.RECENT_RECORD_GET;
  } else if (object === constants.CONTACTS_OBJECT) {
    url = endPoints.CONTACTS.RECENT_RECORD_GET;
  } else if (object === constants.OPPORTUNITIES_OBJECT) {
    url = endPoints.OPPORTUNITIES.RECENT_RECORD_GET;
  } else if (object === constants.PRODUCTS_OBJECT) {
    url = endPoints.PRODUCTS.RECENT_RECORD_GET;
  } else if (object === constants.CASES_OBJECT) {
    url = endPoints.CASES.RECENT_RECORD_GET;
  } else if (object === constants.PROJECTS_OBJECT) {
    url = endPoints.PROJECTS.RECENT_RECORD_GET;
  } else if (object === constants.ISSUES_OBJECT) {
    url = endPoints.ISSUES.RECENT_RECORD_GET;
  } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
    url = endPoints.CUSTOM_TABLE1.RECENT_RECORD_GET;
  } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
    url = endPoints.CUSTOM_TABLE2.RECENT_RECORD_GET;
  } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
    url = endPoints.CUSTOM_TABLE3.RECENT_RECORD_GET;
  } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
    url = endPoints.CUSTOM_TABLE4.RECENT_RECORD_GET;
  } else if (object === constants.SALES_LEADS_OBJECT) {
    url = endPoints.SALES_LEADS.RECENT_RECORD_GET;
  } else if (object === constants.SALES_OBJECT) {
    url = endPoints.SALES_REPORT.GET_REPORT_RECORDS;
  } else if (object === constants.ORDERS_OBJECTS) {
    url = endPoints.ORDERS.RECENT_RECORD_GET;
  } else if (object === constants.QUOTATION_OBJECT) {
    url = endPoints.QUOTATIONS.RECENT_RECORD_GET;
  } else if (object === constants.PURCHASE_ORDER_OBJECT) {
    url = endPoints.PURCHASE_ORDER.RECENT_RECORD_GET;
  } else if (object === constants.SALES_ORDER_OBJECT) {
    url = endPoints.SALES_ORDER.RECENT_RECORD_GET;
  } else if (object === constants.INVENTORY_OBJECT) {
    url = endPoints.INVENTORY.RECENT_RECORD_GET;
  } else if (object === constants.JOBS_OBJECT) {
    url = endPoints.JOBS.RECENT_RECORD_GET;
  } else if (object === constants.COMPLIANCE_OBJECT) {
    url = endPoints.COMPLIANCE.RECENT_RECORD_GET;
  }
  return Promise.resolve(HTTPClient.get(url, null));
}

function getRecentFiltersPromise(object) {
  let url = null;

  //let type = actions.FILTERS_GET;
  if (object === constants.ACCOUNTS_OBJECT) {
    url = endPoints.RECENT_FILTER.ACCOUNTS;
  } else if (object === constants.CONTACTS_OBJECT) {
    url = endPoints.RECENT_FILTER.CONTACTS;
  } else if (object === constants.OPPORTUNITIES_OBJECT) {
    url = endPoints.RECENT_FILTER.OPPORTUNITIES;
  } else if (object === constants.PRODUCTS_OBJECT) {
    url = endPoints.RECENT_FILTER.PRODUCTS;
  } else if (object === constants.CASES_OBJECT) {
    url = endPoints.RECENT_FILTER.CASES;
  } else if (object === constants.ISSUES_OBJECT) {
    url = endPoints.RECENT_FILTER.ISSUES;
  } else if (object === constants.PROJECTS_OBJECT) {
    url = endPoints.RECENT_FILTER.PROJECTS;
  } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
    url = endPoints.RECENT_FILTER.CUSTOM_TABLE1;
  } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
    url = endPoints.RECENT_FILTER.CUSTOM_TABLE2;
  } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
    url = endPoints.RECENT_FILTER.CUSTOM_TABLE3;
  } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
    url = endPoints.RECENT_FILTER.CUSTOM_TABLE4;
  } else if (object === constants.SALES_LEADS_OBJECT) {
    url = endPoints.RECENT_FILTER.SALES_LEADS;
  } else if (object === constants.SALES_OBJECT) {
    url = endPoints.REPORTS.RECENT_REPORTS_GET;
  } else if (object === constants.ORDERS_OBJECTS) {
    url = endPoints.RECENT_FILTER.ORDERS;
  } else if (object === constants.QUOTATION_OBJECT) {
    url = endPoints.RECENT_FILTER.QUOTATIONS;
  } else if (object === constants.PURCHASE_ORDER_OBJECT) {
    url = endPoints.RECENT_FILTER.PURCHASE_ORDER;
  } else if (object === constants.SALES_ORDER_OBJECT) {
    url = endPoints.RECENT_FILTER.SALES_ORDER;
  } else if (object === constants.INVENTORY_OBJECT) {
    url = endPoints.RECENT_FILTER.INVENTORY;
  } else if (object === constants.JOBS_OBJECT) {
    url = endPoints.RECENT_FILTER.JOBS;
  } else if (object === constants.COMPLIANCE_OBJECT) {
    url = endPoints.RECENT_FILTER.COMPLIANCE;
  }
  // else if (object === constants.SALES_MODULE_TEMPLATE) {
  //   url = endPoints.RECENT_FILTER.SALES_SMS_TEMPLATE;
  // }
  return Promise.resolve(HTTPClient.get(url, null));
}

const getRecentRecordsTemplate = (object) => {
  let url = "";
  const { child } = object;
  if (child === "SMS Templates") {
    url = "/v3/templates/sms-templates/recent/get";
  } else if (child === "Call Scripts") {
    url = "/v3/templates/call-scripts/recent/get";
  } else if (child === "Agreement Templates") {
    url = "/v3/templates/agreement-templates/recent/get";
  } else if (child === "Marketing Email Templates") {
    url = "/v3/templates/marketing-templates/recent/get";
  } else if (child === "Sales Email Templates") {
    url = "/v3/templates/sales-templates/recent/get";
  }

  return Promise.resolve(HTTPClient.get(url, null));
};

function saveRecentFilter(object, filter) {
  let state = null;
  let id = 0;
  let name = "";
  let type = "";
  let report_type = "";
  let params = null;
  try {
    object = getStringParam(object);
    state = store.getState();
    if (isValidParam(state.filters) && object !== "" && isValidParam(filter)) {
      id = getIntParam(filter.id);
      name = getStringParam(filter.name);
      type = getStringParam(filter.type);
      report_type = getStringParam(filter.report_type);
      type = type === "" ? "all" : type;
      if (id > 0) {
        filter = { id: id, name: name, type: type, report_type: report_type };
        params = {
          recordId: id,
          name: name,
          type: type,
          report_type: report_type,
        };
        let promise = saveRecentData(object, params);
        if (isValidParam(promise)) {
          promise.then((response) => {
            if (isValidParam(response)) {
              if (response.status === 0) {
                state.filters.recentFilters[object] = getArrayParam(
                  response.data
                );
              }
            }
          });
        }
      }
    }
  } catch (error) {
    console.error("Error in 'common.js -> saveRecentFilter()':" + error);
  }
}

function saveRecentRecord(object, params) {
  let recordId = 0;
  let state = null;
  try {
    object = getStringParam(object);
    params = getObjectParam(params);
    recordId = getIntParam(params.recordId);
    if (recordId > 0 && object !== "") {
      state = store.getState();

      let selectedModuleName = state.app.selectedModuleName;
      let isRecentRecord = isValidParam(
        state.filters.recentSearchRecords[selectedModuleName]
      )
        ? true
        : false;
      let objectList = isValidParam(
        state.filters.recentObjectList[selectedModuleName]
      )
        ? state.filters.recentObjectList[selectedModuleName]
        : "";

      let promise = saveRecentData(object, params);
      if (isValidParam(promise)) {
        promise.then((response) => {
          if (isValidParam(response)) {
            if (response.status === 0) {
              state.filters.recentRecords[object] = getArrayParam(
                response.data
              );
              if (isRecentRecord) {
                updateRecentRecordFilter(selectedModuleName, objectList);
              }
            }
          }
        });
      }
    }
  } catch (error) {
    console.error("Error in 'common.js -> saveRecentRecord()':" + error);
  }
}

function getRecentRecord(object) {
  let state = null;
  try {
    object = getStringParam(object);
    if (object !== "") {
      state = store.getState();
      let promise = getRecentRecords(object);
      if (isValidParam(promise)) {
        promise.then((response) => {
          if (isValidParam(response)) {
            state.filters.recentRecords[object] = getArrayParam(
              response.recentRecords
            );
            store.dispatch(setRecords(state.filters.recentRecords));
          }
        });
      }
    }
  } catch (error) {
    console.error("Error in 'common.js -> getRecentRecord()':" + error);
  }
}

function getRecentFilters(object) {
  let state = null;
  try {
    object = getStringParam(object);
    if (object !== "") {
      state = store.getState();
      let promise = getRecentFiltersPromise(object);
      if (isValidParam(promise)) {
        promise.then((response) => {
          if (isValidParam(response)) {
            state.filters.recentFilters[object] = getArrayParam(
              response.recentFilters
            );
          }
        });
      }
    }
  } catch (error) {
    console.error("Error in 'common.js -> getRecentFilters()':" + error);
  }
}

function getGroups(object) {
  try {
    object = getStringParam(object);
    if (object !== "") {
      let promise = Promise.resolve(getMyGroups(object));
      if (isValidParam(promise)) {
        promise.then((response) => {
          if (isValidParam(response)) {
            store.dispatch(setGroups(response));
          }
        });
      }
    }
  } catch (error) {
    console.error("Error in 'common.js -> getGroups()':" + error);
  }
}

function getObjectNameByLabel(object) {
  try {
    object = getStringParam(object);
    if (object === "Custom Table 1") {
      object = "customobjectone";
    } else if (object === "Custom Table 2") {
      object = "customobjecttwo";
    } else if (object === "Custom Table 3") {
      object = "customobjectthree";
    } else if (object === "Custom Table 4") {
      object = "customobjectfour";
    } else if (object === "Sales Leads") {
      object = "salesleads";
    }
    object = object.toLowerCase();
    object = object.replace("_", "-");
  } catch (error) {
    console.error("Error in 'common.js -> getObjectNameByLabel()':" + error);
  }
  return object;
}

function getMultiLingualLabel(label) {
  try {
    label = getStringParam(label);
    if (label === "Note type") {
      label = getLocalizedStrings().label.COMMON.NOTE_TYPE;
    } else if (label === "Text") {
      label = getLocalizedStrings().label.COMMON.TEXT;
    } else if (label === "Subject") {
      label = getLocalizedStrings().label.APPOINTMENTS_TASKS.SUBJECT;
    } else if (label === "Start date") {
      label = getLocalizedStrings().label.APPOINTMENTS_TASKS.START_DATE;
    } else if (label === "End date") {
      label = getLocalizedStrings().label.APPOINTMENTS_TASKS.END_DATE;
    } else if (label === "Status") {
      label = getLocalizedStrings().label.COMMON.STATUS;
    } else if (label === "Assign to") {
      label = getLocalizedStrings().label.APPOINTMENTS_TASKS.ASSIGN_TO;
    } else if (label === "Company") {
      label = getLocalizedStrings().label.APPOINTMENTS_TASKS.COMPANY;
    } else if (label === "Contact Name") {
      label = getLocalizedStrings().label.APPOINTMENTS_TASKS.CONTACT_NAME;
    } else if (label === "Outlook Sync") {
      label = getLocalizedStrings().label.APPOINTMENTS_TASKS.OUTLOOK_SYNC;
    } else if (label === "Google Sync") {
      label = getLocalizedStrings().label.APPOINTMENTS_TASKS.GOOGLE_SYNC;
    } else if (label === "Task Name") {
      label = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK_NAME;
    } else if (label === "Due By") {
      label = getLocalizedStrings().label.APPOINTMENTS_TASKS.DUE_BY;
    } else if (label === "Description") {
      label = getLocalizedStrings().label.APPOINTMENTS_TASKS.DESCRIPTION;
    } else if (
      label === "Scheduled" ||
      label === "Completed" ||
      label === "Cancelled" ||
      label === "Pending"
    ) {
      label = getLocalizedStrings().label.APPOINTMENTS_TASKS[label];
    } else if (label === "Done") {
      label = getLocalizedStrings().label.COMMON.DONE;
    } else if (label === "Yes") {
      label = getLocalizedStrings().label.COMMON.YES;
    } else if (label === "No") {
      label = getLocalizedStrings().label.COMMON.NO;
    } else if (label === "Active") {
      label = getLocalizedStrings().label.COMMON.ACTIVE;
    } else if (label === "Inactive") {
      label = getLocalizedStrings().label.COMMON.INACTIVE;
    } else if (label === "Everyone" || label === "Just me") {
      label = getLocalizedStrings().label.APPOINTMENTS_TASKS[label];
    } else if (label === "duration" || label === "days_after") {
      label = getLocalizedStrings().label.APPOINTMENTS_TASKS[label];
    } else if (label === "t_dep_task_id") {
      label = getLocalizedStrings().label.APPOINTMENTS_TASKS.DEP_TASK_NAME;
    } else if (label === "Opportunity Name") {
      label = getLocalizedStrings().label.APPOINTMENTS_TASKS.OPPORTUNITY_NAME;
    }
  } catch (error) {
    console.error("Error in 'common.js -> getMultiLingualLabel()':" + error);
  }
  return label;
}

function addCampaignTypeIntoReduxStore(obj) {
  let state = store.getState();
  let campaignTypes = getObjectParam(state.campaignTypes.data);
  obj = getObjectParam(obj);
  campaignTypes[obj.id] = obj.name;
  store.dispatch(setCampaignType(campaignTypes));
}

function getCampaignTypeNames(campaignTypesId) {
  let campaignTypeNames = "";
  try {
    let state = store.getState();
    let campaignTypes = getObjectParam(state.campaignTypes.data);
    campaignTypesId = getStringParam(campaignTypesId).trim();
    if (campaignTypesId !== "" && Object.keys(campaignTypes).length > 0) {
      let arrCampaignTypeIds = campaignTypesId.split(",");
      arrCampaignTypeIds.forEach((val) => {
        val = getStringParam(val).trim();
        val = val !== "" ? getStringParam(campaignTypes[val]) : "";
        if (val !== "") {
          campaignTypeNames += campaignTypeNames === "" ? val : "," + val;
        }
      });
    }
  } catch (error) {
    console.error("Error in 'common.js -> getCampaignTypeName()':" + error);
  }
  return campaignTypeNames;
}

function getCampaignTypeListValues(campaignTypeIds) {
  let campaignTypeObj = null;
  let campaignTypeList = [];
  try {
    campaignTypeIds = getStringParam(campaignTypeIds).trim();
    let campaignTypeNames = getCampaignTypeNames(campaignTypeIds);
    campaignTypeNames = getStringParam(campaignTypeNames).trim();

    if (campaignTypeNames !== "") {
      let campaignTypes = campaignTypeNames.split(",");
      campaignTypes.forEach((val) => {
        val = getStringParam(val).trim();
        if (val !== "") {
          campaignTypeObj = {};
          campaignTypeObj.id = val;
          campaignTypeObj.value = val;

          campaignTypeList.push(campaignTypeObj);
        }
      });
    }
  } catch (error) {
    console.error(
      "Error in 'common.js -> getCampaignTypeListValues()':" + error
    );
  }
  return campaignTypeList;
}

function getMultiColumnValues(columnName, rowData) {
  let value = "";
  try {
    columnName = getStringParam(columnName);
    let arrColName = columnName.split(",");
    if (arrColName.length > 1) {
      let arrVal = [];
      arrColName.forEach((name) => {
        let val = getStringParam(rowData[name]).trim();
        if (val !== "") {
          arrVal.push(<div>{val}</div>);
        }
      });
      value = arrVal;
    } else {
      value = rowData[columnName];
    }
  } catch (error) {
    console.error("Error in 'common.js -> getMultiColumnValues()':" + error);
  }
  return value;
}

function getMultiColumnTitles(columnName, rowData) {
  let title = "";
  try {
    columnName = getStringParam(columnName);
    let arrColName = columnName.split(",");
    if (arrColName.length > 1) {
      arrColName.forEach((name) => {
        let val = getStringParam(rowData[name]).trim();
        if (val !== "") {
          title = title === "" ? rowData[name] : title + ", " + rowData[name];
        }
      });
    } else {
      title = rowData[columnName];
    }
  } catch (error) {
    console.error("Error in 'common.js -> getMultiColumnTitles()':" + error);
  }
  return title;
}
function getSelectedModuleObjectName() {
  let state = store.getState();
  let selectedModuleInfo = null;
  let selectedModuleName = null;
  let selectedObjectInfo = null;
  let selectedObjectName = "";
  let item = null;
  try {
    selectedModuleName = getStringParam(state.app.selectedModuleName);
    selectedModuleInfo = getObjectParam(state.app.selectedModuleInfo);
    selectedObjectInfo = getObjectParam(selectedModuleInfo[selectedModuleName]);
    item = getObjectParam(selectedObjectInfo.item);
    selectedObjectName = getStringParam(item.name);
  } catch (error) {
    console.error(
      "Error in 'common.js -> getSelectedModuleObjectName()':" + error
    );
  }
  return selectedObjectName;
}
function getObjectIdListByModule(objects) {
  let size = getArrayParam(objects).length;
  let objectTableIds = "";
  let tempObjectTableIds = "";
  try {
    if (size > 0) {
      objects.forEach((obj, index) => {
        tempObjectTableIds = OBJECT_TABLEID_MAP[getStringParam(obj.value)];
        if (
          tempObjectTableIds !== null &&
          tempObjectTableIds !== undefined &&
          tempObjectTableIds !== ""
        ) {
          if (objectTableIds === "") {
            objectTableIds = tempObjectTableIds;
          } else {
            objectTableIds = objectTableIds + "," + tempObjectTableIds;
          }
        }
      });
    }
  } catch (error) {
    console.error("Error in 'common.js -> getObjectIdListByModule()':" + error);
  }
  return objectTableIds;
}

function getRecentRecordFilterPromise(objects) {
  let url = endPoints.HOME.ALL_OBJECTS_RECENT_RECORDS;
  let params = {};
  let promise = "";
  try {
    params.table_ids = objects;
    promise = Promise.resolve(HTTPClient.get(url, params));
  } catch (error) {
    console.error(
      "Error in 'common.js -> getRecentRecordFilterPromise()':" + error
    );
  }

  return promise;
}

function updateRecentRecordFilter(module, objects) {
  let state = store.getState();
  try {
    let promise = getRecentRecordFilterPromise(objects);
    promise.then((response) => {
      if (isValidParam(response)) {
        state.filters.recentSearchRecords[module] = response;
      }
    });
  } catch (error) {
    console.error(
      "Error in 'common.js -> updateRecentRecordFilter()':" + error
    );
  }
}

function getItemName(item) {
  let label = item.label;
  let object = item.object;
  try {
    if (object === "accounts" && label === "Accounts") {
      label = getLocalizedStrings().label.HEADER["Accounts"];
    }
    if (object === "contacts" && label === "Contacts") {
      label = getLocalizedStrings().label.HEADER["Contacts"];
    }
    if (object === "opportunities" && label === "Opportunities") {
      label = getLocalizedStrings().label.HEADER["Opportunities"];
    }
    if (object === "cases" && label === "Cases") {
      label = getLocalizedStrings().label.HEADER["Cases"];
    }
    if (object === "projects" && label === "Projects") {
      label = getLocalizedStrings().label.HEADER["Projects"];
    }
    if (object === "issues" && label === "Issues") {
      label = getLocalizedStrings().label.HEADER["Issues"];
    }
    if (object === "salesleads" && label === "Sales Leads") {
      label = getLocalizedStrings().label.HEADER["Sales Leads"];
    }
    if (object === "sales" && label === "Sales Reports") {
      label = getLocalizedStrings().label.HEADER.REPORTS;
    }
    if (object === "products" && label === "Products") {
      label = getLocalizedStrings().label.HEADER["Products"];
    }
    if (!isValidParam(label) || label === "undefined") {
      label = item.label;
    }
  } catch (error) {}

  return label;
}

const getCloseButton = (onClickEvent) => {
  return (
    <IconButton
      aria-label="close"
      style={{ color: "#fff", marginRight: "10px", marginLeft: "10px" }}
      onClick={onClickEvent}
    >
      <Icon fontSize="large">close</Icon>
    </IconButton>
  );
};

function getAssetsTree(data, key, callback) {
  if (isValidParam(data) && getArrayParam(data).length > 0) {
    data.forEach((item, index, arr) => {
      if (item.key === key) {
        callback(item, index, arr);
        return;
      }
      if (item.children) {
        getAssetsTree(item.children, key, callback);
      }
    });
  }
  return callback;
}

function getAssetsTreeItem(childArray, callback) {
  childArray.forEach((item, index) => {
    if (item.children) {
      getAssetsTreeItem(item.children, callback);
    }
    callback(item);
    return;
  });
  return callback;
}

function findTopNode(data, key, topNode) {
  let parentNode = {};

  function findNode(nodes, key) {
    for (let node of nodes) {
      if (node.key === key) {
        return node;
      }
      if (node.children && node.children.length > 0) {
        let found = findNode(node.children, key);
        if (found) {
          parentNode = node;
          return found;
        }
      }
    }
    return null;
  }

  findNode(data, key);
  return parentNode.parentId === topNode
    ? parentNode
    : findTopNode(data, parentNode.parentId, topNode);
}

function getUserNames(userIds) {
  let userNames = "";
  let obj = "";
  try {
    let state = store.getState();
    let userList = getArrayParam(state.userlist.data);
    userIds = getStringParam(userIds).trim();
    if (userIds !== "" && userList.length > 0) {
      let userIdsArr = userIds.split(",");
      userIdsArr.forEach((val) => {
        val = getStringParam(val).trim();
        obj = userList.filter((f) => f.id === parseInt(val));
        val = val !== "" ? getStringParam(obj[0].value) : "";
        if (val !== "") {
          userNames += userNames === "" ? val : "," + val;
        }
      });
    }
  } catch (error) {
    console.error("Error in 'common.js -> getUserNames()':" + error);
  }
  return userNames;
}

function getCoupons() {
  let promise = null;
  try {
    let params = { sort_field_name: "created_on", sort_type: "desc" };
    params.input_param_sql = "t_status = 'Active'";
    promise = Promise.resolve(HTTPClient.get(endPoints.COUPONS.GET, params));
  } catch (error) {
    console.error("Error in 'common.js -> getCoupons()':" + error);
  }
  return promise;
}

function showNotification(type, message) {
  try {
    let state = store.getState();
    let notification = { type, message };
    state.app.notification = notification;
  } catch (error) {
    console.error("Error in 'common.js -> showNotification()':" + error);
  }
}

const dateOptions = {
  blank: "",
  td: "Today",
  yd: "Yesterday",
  tw: "This Week",
  tm: "This Month",
  tq: "This Quarter",
  ty: "This Year",
  lw: "Last Week",
  lm: "Last Month",
  lq: "Last Quarter",
  ly: "Last Year",
  cust: "Custom",
};

function getDateOptions(excludeOptions) {
  let options = [];
  try {
    let locaalizedString = getLocalizedStrings();
    let optionvalue = { ...dateOptions };
    excludeOptions = getArrayParam(excludeOptions);
    excludeOptions.forEach((m) => {
      delete optionvalue[m];
    });
    let optionsMultilingual = {
      Today: locaalizedString.label.DASHBOARD.TODAY,
      Yesterday: locaalizedString.label.DASHBOARD.YESTERDAY,
      "This Week": locaalizedString.label.DASHBOARD.THIS_WEEK,
      "This Month": locaalizedString.label.DASHBOARD.THIS_MONTH,
      "This Quarter": locaalizedString.label.DASHBOARD.THIS_QUARTER,
      "This Year": locaalizedString.label.DASHBOARD.THIS_YEAR,
      "Last Week": locaalizedString.label.DASHBOARD.LAST_WEEK,
      "Last Month": locaalizedString.label.DASHBOARD.LAST_MONTH,
      "Last Quarter": locaalizedString.label.DASHBOARD.LAST_QUARTER,
      "Last Year": locaalizedString.label.DASHBOARD.LAST_YEAR,
      Custom: locaalizedString.label.DASHBOARD.CUSTOM,
    };

    options = Object.keys(optionvalue).map((key) => {
      return {
        value: key,
        text: getStringParam(optionsMultilingual[optionvalue[key]]),
      };
    });
  } catch (error) {
    console.error("Error in 'common.js -> getDateOptions()':" + error);
  }
  return options;
}

function saveRecentReportItems(name, reportType, parameterId, object) {
  let promise = null;
  let id = 0;
  try {
    let params = {
      recordId: parameterId,
      name: name,
      type: "reports",
      report_type: reportType,
    };
    promise = saveRecentData(object, params);
    promise.then((response) => {
      if (isValidParam(response) && response.status === 0) {
        id = getIntParam(response.id);
      }
    });
  } catch (error) {
    console.error("Error in 'common.js ---> saveRecentReportItems()':" + error);
  }
  return id;
}

function getIconClass(itemType, reportSubTypeId) {
  let iconClass = null;
  let key = "";
  try {
    if (itemType === constants.USER_REPORT_TYPE) {
      key = itemType + "_" + USER_REPORT_TYPE_ID_NAME_MAP[reportSubTypeId];
    } else {
      let reportType = reportSubTypeId.toLowerCase().replace(/ /g, "-");
      if (
        reportType === constants.REPORT_TYPE_SALES_SUMMARY ||
        reportType === constants.REPORT_TYPE_EMAIL ||
        reportType === constants.REPORT_TYPE_SMS ||
        reportType === constants.REPORT_TYPE_TASKS
      ) {
        key = itemType + "_Summary";
      } else {
        reportSubTypeId = "";
        key = itemType + "_" + reportSubTypeId;
      }
    }
    if (isValidParam(REPORT_TYPE_ICON_MAP[key])) {
      iconClass = REPORT_TYPE_ICON_MAP[key];
    }
  } catch (error) {
    console.error("Error in 'common.js ---> getIconElement()':" + error);
  }
  return iconClass;
}

function getUserReportSubType(id) {
  let report_subtype = "";
  try {
    if (isValidParam(id) && id > 0) {
      let state = store.getState();
      let data = state.salesReport.data;
      if (data !== null) {
        let reportItems = state.salesReport.data.report_names;
        let reportObject = reportItems.filter((item) => {
          return item.parameter_id === id;
        });
        if (reportObject.length > 0) {
          report_subtype = getIntParam(reportObject[0].report_subtype);
        }
      }
    }
  } catch (error) {
    console.error("Error in 'common.js ---> getUserReportSubType()':" + error);
  }

  return report_subtype;
}

function removeRecentFromRedux(object, recordId) {
  try {
    if (isValidParam(recordId) && recordId > 0) {
      let state = store.getState();

      let recentFilter = state.filters.recentFilters;
      let salesRecentFilter = recentFilter[object];
      let newRecentFilter = filter(salesRecentFilter, (item) => {
        return item.id !== recordId;
      });
      recentFilter[object] = newRecentFilter;

      let recentRecords = state.filters.recentRecords;
      let recentSalesRecords = recentRecords[object];
      let newRecentRecords = filter(recentSalesRecords, (item) => {
        return item.id !== recordId;
      });
      recentRecords[object] = newRecentRecords;

      store.dispatch(setRecentFilterReport(recentFilter));
      store.dispatch(setRecentReport(recentRecords));
    }
  } catch (error) {
    console.error("Error in 'common.js ---> removeRecentFromRedux()':" + error);
  }
}
function updateRecentInRedux(object, recordId, newName) {
  try {
    if (isValidParam(recordId) && recordId > 0) {
      let state = store.getState();

      let recentFilter = state.filters.recentFilters;
      let salesRecentFilter = recentFilter[object];
      salesRecentFilter.forEach((obj) => {
        if (obj.id === recordId) {
          obj.name = newName;
        }
      });
      recentFilter[object] = salesRecentFilter;

      let recentRecords = state.filters.recentRecords;
      let recentSalesRecords = recentRecords[object];
      recentSalesRecords.forEach((obj) => {
        if (obj.id === recordId) {
          obj.name = newName;
          obj.recent = newName;
        }
      });

      recentRecords[object] = recentSalesRecords;
      store.dispatch(setRecentFilterReport(recentFilter));
      store.dispatch(setRecentReport(recentRecords));
    }
  } catch (error) {
    console.error("Error in 'common.js ---> updateRecentInRedux()':" + error);
  }
}

function reportFormatDate(date) {
  let d = date instanceof Date ? date : new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function reportToDate(dateStr) {
  var parts = dateStr.split("-");
  return new Date(parts[0], parts[1] - 1, parts[2]);
}

function evictUserData() {
  let promise = null;
  let isEvict = false;
  try {
    promise = Promise.resolve(HTTPClient.get(endPoints.LOGIN.EVICT_USER));
    if (isValidParam(promise)) {
      promise.then((response) => {
        if (isValidParam(response) && response.status === 0) {
          isEvict = true;
        }
      });
    }
  } catch (error) {
    console.error("Error in 'common.js -> evictUserData()':" + error);
  }
  return isEvict;
}

function getDefaultBeeEditorData() {
  let defaultBeeEditorJosn = {};
  try {
    defaultBeeEditorJosn = {
      page: {
        body: {
          container: { style: { "background-color": "#FFFFFF" } },
          content: {
            computedStyle: {
              linkColor: "#0068A5",
              messageBackgroundColor: "transparent",
              messageWidth: "500px",
            },
            style: {
              color: "#000000",
              "font-family": "Arial, Helvetica Neue, Helvetica, sans-serif",
            },
          },
          type: "mailup-bee-page-proprerties",
          webFonts: [],
        },
        description: "Test template for BEE",
        rows: [
          {
            columns: [
              {
                "grid-columns": 12,
                modules: [],
                style: {
                  "background-color": "transparent",
                  "border-bottom": "0px solid transparent",
                  "border-left": "0px solid transparent",
                  "border-right": "0px solid transparent",
                  "border-top": "0px solid transparent",
                  "padding-bottom": "5px",
                  "padding-left": "0px",
                  "padding-right": "0px",
                  "padding-top": "5px",
                },
                uuid: "94efab30-03a4-4ad6-b13b-31c7fea66516",
              },
            ],
            container: {
              style: {
                "background-color": "transparent",
                "background-image": "none",
                "background-position": "top left",
                "background-repeat": "no-repeat",
              },
            },
            content: {
              computedStyle: {
                rowColStackOnMobile: true,
                rowReverseColStackOnMobile: false,
              },
              style: {
                "background-color": "transparent",
                "background-image": "none",
                "background-position": "top left",
                "background-repeat": "no-repeat",
                color: "#000000",
                width: "500px",
              },
            },
            type: "one-column-empty",
            uuid: "8939a1f7-ed30-420d-a261-3fba17a45dfc",
          },
        ],
        template: { name: "template-base", type: "basic", version: "0.0.1" },
        title: "Template Base",
      },
      comments: {},
    };
  } catch (error) {
    console.error("Error in 'common.js -> getDefaultBeeEditorData()':" + error);
  }
  return defaultBeeEditorJosn;
}

function getLinkedRecordId(params) {
  try {
    if (params) {
      return HTTPClient.get(endPoints.COMMON.GET_LINKED_RECORD_ID, params);
    }
  } catch (error) {
    console.error("Error in 'common.js -> getLinkedRecordId()':" + error);
  }
}

function isFranchiseRecord(params) {
  try {
    if (params) {
      return HTTPClient.get(endPoints.ACCOUNTS.IS_FRANCHISE, params);
    }
  } catch (error) {
    console.error("Error in 'common.js -> isFranchiseRecord()':" + error);
  }
}

export {
  getTabTitleImageName,
  addGroupIntoReduxStore,
  hasAccessPermission,
  retriveEscapeHtml,
  escapeHtml,
  isObjectChildExists,
  getChildObjectInfo,
  getUserInfo,
  setSelectedModuleInfo,
  getSelectedModuleInfo,
  getSelectedObject,
  getSelectedModuleItem,
  getSelectedModuleObjectName,
  getSelectedObjectLabel,
  isLinkedChildObject,
  getImageNameByObject,
  sortHomeItems,
  getObjectLabelByObject,
  saveRecentFilter,
  saveRecentRecord,
  getRecentRecord,
  getRecentFiltersPromise,
  getRecentFilters,
  getRecentRecords,
  getGroups,
  addFilterToReduxStore,
  getObjectNameByLabel,
  getMultiLingualLabel,
  addCampaignTypeIntoReduxStore,
  getCampaignTypeNames,
  getCampaignTypeListValues,
  getMultiColumnValues,
  getMultiColumnTitles,
  getObjectIdListByModule,
  updateRecentRecordFilter,
  getRecentRecordFilterPromise,
  getCloseButton,
  getItemName,
  getAssetsTree,
  findTopNode,
  getAssetsTreeItem,
  getUserNames,
  getCoupons,
  showNotification,
  dateOptions,
  getDateOptions,
  saveRecentReportItems,
  getIconClass,
  getUserReportSubType,
  removeRecentFromRedux,
  reportFormatDate,
  reportToDate,
  updateRecentInRedux,
  evictUserData,
  getDefaultBeeEditorData,
  getLinkedRecordId,
  isFranchiseRecord,
  getRecentRecordsTemplate,
};
