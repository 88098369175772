import React from 'react';
import TouchCampaigns from '../components/TouchCampaignsForGroup';
import BroadcastCampaigns from '../components/BroadcastCampaignsForGroup';
import ABCampaigns from '../components/ABCampaignsForGroup';
import WebForms from '../components/WebFormsForGroup';
import LandingPages from '../components/LandingPagesForGroup';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import PropTypes from 'prop-types';

const DEFAULT_PAGE_SIZE = 20;

const GroupCampaignDetails=(id)=>{

    return( 
    <div style={{ width: '100%'}} className='container'>
            <div className="row" style={{ height: '50px' }}>
            <div className="col-sm-12" style={{fontWeight:'bold',fontSize:'20px',paddingTop:'20px',color:'#666'}}>
                {getLocalizedStrings().label.GROUP.USED_IN}
            </div>
        </div>    
        <div className="row" style={{  height: 'auto'}}>
            <div className="col-sm-12">
                <table className="group_table_container">
                    <tbody>
                        <tr>
                            <td style={{borderRight:"1px solid #ddd"}}><TouchCampaigns recordId = {id} defaultPageSize = {DEFAULT_PAGE_SIZE}/></td>
                            <td><BroadcastCampaigns recordId = {id} defaultPageSize = {DEFAULT_PAGE_SIZE}/></td>
                        </tr>
                        <tr>
                            {/* <td style={{borderRight:"1px solid #ddd"}}><ABCampaigns recordId = {id} defaultPageSize = {DEFAULT_PAGE_SIZE}/></td> */}
                            <td><WebForms recordId = {id} defaultPageSize = {DEFAULT_PAGE_SIZE}/></td>
                        </tr>
                        <tr>
                            <td style={{borderRight:"1px solid #ddd"}}><LandingPages recordId = {id} defaultPageSize = {DEFAULT_PAGE_SIZE}/></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>);

}

GroupCampaignDetails.propTypes = {
    id: PropTypes.number
};
export default GroupCampaignDetails;
