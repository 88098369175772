import React, { useRef, useCallback, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { OBJECT_TABLEID_MAP, WORKFLOW_SETUP, constants } from '../../../services/constants/constants';
import * as sfDialogs from './sfDialogs';
import { useDispatch, useSelector } from "react-redux";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import { actions } from "../../../services/constants/actions";
import update from 'immutability-helper'
import CloseIcon from '@mui/icons-material/Close';
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import { deleteDndRowCards, setIsDndCardDroped, updateDndCard, updateWorkflowSetupAddNewTask ,setTaskListkOrderErrorId} from "../../../services/actions/workflowSetupAction";
import { getHighestOrderId, getOrderId } from "../../../services/helper/utils";
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { isValidParam } from "../../../services/helper/parameterVerifier";
const style = {
    border: "1px dashed gray",
    padding: "0.5rem 1rem",
    marginBottom: ".5rem",
    backgroundColor: "white",
    cursor: "move"
  };

const WorkflowSetupCard = ({ key,index,id, task, editTask,allTask}) => {
    const {isEditTask,isAddNewTask,isaddNewTextMessage,isAddAppointment,taskListOrderErrorId,isTaskOrderError,isOpenEmailTask,showRequiredField,isShowWebhook, selectedStage,data, customDateFormFId} = useSelector((state) => state.workFlowSetup);
    const {tasks} = selectedStage;
    const dispatch = useDispatch();
    const positionRef = React.useRef({
      x: 0,
      y: 0,
    });
    const popperRef = React.useRef(null);
    const ref = useRef(null);
    const taskPositionRef = useRef(null);
    let baseImgPath = "https://testvpc.snapshotcrm.com/theme/blue/"
    let ImageSource = '';
    const [, drop] = useDrop({
      accept: actions.CARD,
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        moveCard(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
      drop: (droppedItem, monitor) => {
        dispatch(setIsDndCardDroped(true))
      }
    });

    const [{ isDragging }, drag] = useDrag({
      type: actions.CARD,
      item: {  id, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    });

    useEffect(()=>{
      if(taskListOrderErrorId > 0 && isTaskOrderError){
        taskPositionRef.current?.scrollIntoView({behavior: 'smooth'});
        dispatch(setTaskListkOrderErrorId(taskListOrderErrorId,false));
      }
    },[taskListOrderErrorId,isTaskOrderError])

    const copyTask = (task) =>{
        if(isShowWebhook || isEditTask || isAddNewTask || isaddNewTextMessage || isAddAppointment || isOpenEmailTask){
            let errorMessage;
            if(isShowWebhook){
              errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.WEBHOOK_OPEN;
            }else if(isEditTask){
              errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EDIT_ERROR;
            }else if(isAddNewTask){
              errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EDIT_ERROR;
            }else if(isaddNewTextMessage){
              errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.TEXT_MESSAGE_ERROR;
            }else if(isAddAppointment){
              errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.APPOINMENT_ERROR;
            }else if(isOpenEmailTask){
              errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.CHECK_EMAIL_TASK_OPEN;
            }
            dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }else if(task.type === "New_Appointment"){
          let errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.NOT_ALLOWED_TO_COPY_APPOINTMENT_MSG;
          dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }else if(data?.edit_tasks !== 1){
          let errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.COPY_NOT_ALLOWED;
          errorMessage =  errorMessage +" "+task.type;
          dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }else{
          const orderId = getOrderId([{...selectedStage}])
          let copyTask = task;
          copyTask.order_id =  orderId ;
          dispatch(updateWorkflowSetupAddNewTask(copyTask, constants.COPY));
        }
    }
    const moveCard = useCallback(
        (dragIndex, hoverIndex) => {
          const dragCard = tasks[dragIndex]
            let rearrangeRows = update(tasks, {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
              ],
            });
            dispatch(updateDndCard(rearrangeRows, 'tasks'));
        },
        [dispatch, tasks],
    )

    const deleteTask = (task) =>{ 
        if(isShowWebhook || isEditTask || isAddNewTask || isaddNewTextMessage || isAddAppointment || isOpenEmailTask){
            let errorMessage;
            if(isShowWebhook){
              errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.WEBHOOK_OPEN;
            }else if(isEditTask){
              errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EDIT_ERROR;
            }else if(isAddNewTask){
              errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EDIT_ERROR;
            }else if(isaddNewTextMessage){
              errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.TEXT_MESSAGE_ERROR;
            }else if(isAddAppointment){
              errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.APPOINMENT_ERROR;
            }else if(isOpenEmailTask){
              errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.CHECK_EMAIL_TASK_OPEN;
            }
            dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }else if(data.edit_tasks !== 1){
          let errorMessage;
          errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.DELETE_NOT_ALLOWED_MSG;
          errorMessage = errorMessage + " " + task?.type;
          dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }else{
          let _errorMessage = 'This' + " " + task.type + " ";
          if(task.type === "Auto_Mail"){
            _errorMessage = _errorMessage + getLocalizedStrings().message.WORKFLOW_SETUP.DELETE_MSG_FOR_EMAIL;
          }else{
            _errorMessage = _errorMessage + getLocalizedStrings().message.WORKFLOW_SETUP.DELETE_MSG_FOR_TASK;
          }
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, _errorMessage, () => handleSfDialogConfirm(task), null);
        }
    }
    const handleSfDialogConfirm =(task)=>{
        dispatch(deleteDndRowCards(task, 'tasks'));
    }
    const handleMouseMove = (event) => {
      positionRef.current = { x: event.clientX, y: event.clientY };
  
      if (popperRef.current != null) {
        popperRef.current.update();
      }
    };

    const handleMove = (event) =>{

    }

    // getTaskDaysAfterData(details.t_days_after, details.t_taskCreated, details.t_interval_mode)
    const getTaskDaysAfterData = (key, type, interval_mode) => {
        let daysAfter = [];
        let _customDateFormFId = [];
        customDateFormFId.forEach((item, index) => {
                if (isValidParam(item)) {
                    if (isValidParam(Object.values(item)[0]) && isValidParam(Object.keys(item)[0])) {
                        let data = {};
                        data.key = Object.keys(item)[0];
                        data.value = Object.values(item)[0];
                        _customDateFormFId.push(data);
                    }
                }
            });

        if(type == 'After'){
            daysAfter.push({"key":"Create Date", "value":"Created Date"});
            daysAfter.push({"key":"Current_Date", "value":"Current Date"});
            if (_customDateFormFId && _customDateFormFId.length > 0){
                _customDateFormFId.forEach(( item, index )=>{
                     let dateType = item?.value?.substring(item?.value?.indexOf("_") + 1);
                    let subValue = item?.value?.substring(0,item?.value?.indexOf("_"));
                    if(interval_mode == "days" && (dateType == "204" || dateType == "224")){
                        daysAfter.push({"key":item.key, "value":subValue});
                    } 
                    if(interval_mode == "hours" && dateType == "224") {
                        daysAfter.push({"key":item.key, "value":subValue});
                    }
                })
            }
            daysAfter.push({"key":"Previous Task", "value":"The preceding task is created"});
            daysAfter.push({"key":"Previous_Task_Completed", "value":"The preceding task is completed"});
            if(OBJECT_TABLEID_MAP[data.t_tableid] == OBJECT_TABLEID_MAP.accounts){
                daysAfter.push({"key":"Previous_Mail_Is_Sent", "value":"The preceding email is sent"});
                daysAfter.push({"key":"Previous_Text_Message_Is_Sent", "value":"The preceding text message is sent"});
            }
        } else {
            if (_customDateFormFId && _customDateFormFId.length > 0){
                _customDateFormFId.forEach((item, index )=>{
                    let dateType =  item?.value?.substring(item?.value?.indexOf("_") + 1);
                    let subValue = item?.value?.substring(0,item?.value?.indexOf("_"));
                    if(interval_mode == "days" && (dateType == "204" || dateType == "224")){
                        daysAfter.push({"key":item.key, "value":subValue});
                    }
                    if(interval_mode == "hours"  && dateType == "224") {
                        daysAfter.push({"key":item.key, "value":subValue});
                    }
                })
            }
        }
        
        if(daysAfter.length == 0) {
            daysAfter.push({"key":"-999", "value":"No date fields found"});
        }
        
        if(key){
            let findVal = daysAfter.filter((obj)=> obj.key == key)
            if(findVal.length > 0){
                return findVal[0].value;
            }else{
                return "";
            }
        } else {
            return daysAfter;
        }   

    }
    let titleText = '';
    let detailsText = '';
    if(task.type === WORKFLOW_SETUP.TYPE_TASK){
        ImageSource = baseImgPath+"images/NewDesign/workflow-time.png";
        titleText = task.details.t_name;	
				let daysAfter = task.details.t_days_after ? getTaskDaysAfterData(task.details.t_days_after, task.details.t_taskCreated, task.details.t_interval_mode) : "";
				let intervalMode = task.details.t_interval && task.details.t_interval > 1 ? task.details.t_interval_mode : task.details.t_interval_mode.substring(0, task.details.t_interval_mode.length - 1); 
				detailsText = "Due "+ task.details.t_interval + " " + intervalMode + " "+task.details.t_taskCreated.toLowerCase() +" "+ daysAfter.toLowerCase() + ". Assigned to " + task.details.t_owner + ".";
    }else if(task.type === WORKFLOW_SETUP.TYPE_AUTO_MAIL){
      ImageSource = baseImgPath+"images/NewDesign/workflow-mail.png";
      titleText = task.details.template.subject;
      let intervalMode;
      if(task.details.t_interval_type === "hours"){
        intervalMode = isValidParam(task.details.t_interval) && task.details.t_interval > 1 ? "hours" : "hour";
      } else{
        intervalMode = isValidParam(task.details.t_interval) && task.details.t_interval > 1 ? "days" : "day";
      }
      detailsText = "Send " + task.details.t_interval + " " + intervalMode +" after "+ (isValidParam(task.details.t_interval_mode) ? task.details.t_interval_mode.toLowerCase() : "") + ".";
    }else if(task.type === WORKFLOW_SETUP.TYPE_ESCALATION){
      ImageSource = baseImgPath+"images/NewDesign/workflow-escalation.png";
      titleText = task.details.t_escalationTxt;
      let intervalMode = isValidParam(task.details.t_interval) && task.details.t_interval > 1 ? "days" : "day";
      detailsText = "If not converted by " + task.details.t_interval + " " + intervalMode +" after " + (isValidParam(task.details.t_interval_mode) ? task.details.t_interval_mode.toLowerCase() : "") + ".";
    }else if(task.type === WORKFLOW_SETUP.TYPE_TEXT_MESSAGE){
      ImageSource = baseImgPath+"images/NewDesign/workflow-sms.png";
      titleText = (task.details.t_message||'').length > 160 ? (task.details.t_message||'').substring(0,160)+"..." : (task.details.t_message||'').substring(0,160);
      let intervalMode;
      if(task.details.t_interval_type === "hours"){
        intervalMode = isValidParam(task.details.due_by) && task.details.due_by > 1 ? "hours" : "hour";
      } else {
        intervalMode = isValidParam(task.details.due_by) && task.details.due_by > 1 ? "days" : "day";
      }
      detailsText = "Send "+task.details.due_by+" "+intervalMode+" after "+task.details.days_after.toLowerCase()+ ".";
    }else if(task.type === WORKFLOW_SETUP.TYPE_APPOINTMENT_SUB){
      ImageSource = baseImgPath+"images/NewDesign/workflow-tasks.png";
      titleText = task.details.t_subject;
      detailsText = "Appointment subject: " + task.details.t_subject + " " + ".";
    }else if(task.type === WORKFLOW_SETUP.NEW_REMINDER){
      ImageSource = baseImgPath+"images/NewDesign/workflow-mail1.png";
      if(task.details.t_reminderType == "Send Text"){
        ImageSource = baseImgPath+"images/NewDesign/workflow-sms1.png";
      }
      let timeType = '';
      if(task.details.t_timeType === 'hours'){
        timeType = 'Hour(s)'
      }else{
        timeType = 'Day(s)'
      }
      titleText = "Remind before "+ task.details.t_timeValue +" "+ timeType;
      if(task.details.t_reminderType == "Send Text"){
        detailsText = (task.details.t_remainderText||'').length > 160 ? (task.details.t_remainderText||'').substring(0,160)+"..." : (task.details.t_remainderText||'').substring(0,160);
      } else{
        let reminderText = task.details.t_remainderText.replace(/(<([^>]+)>)/ig,"");
        reminderText = reminderText.replace(/&nbsp;/g, '');
        detailsText = (reminderText||'').length > 160 ? (reminderText||'').substring(0,160)+"..." : (reminderText||'').substring(0,160);
      }
    }
  
    drag(drop(ref));
    return (
        <>
          <Tooltip
            title={task?.errorMessage ? task.errorMessage : ''}
            placement="top"
            arrow={task?.errorMessage}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: '#f1807e',
                  color:'#454545',
                  '& .MuiTooltip-arrow': {
                    color: '#f1807e',
                  },
                },
              },
            }}
            PopperProps={{
              popperRef,
              anchorEl: {
                getBoundingClientRect: () => {
                  return new DOMRect(
                    positionRef.current.x,
                    ref.current.getBoundingClientRect().y,
                    0,
                    0,
                  );
                },
              },
            }}
          >
            <div onMouseMove={task?.errorMessage ? handleMouseMove : handleMove} sx={{bgcolor: 'red',color: 'black',p: 2,}} style={{marginTop:'3px',paddingTop:'4px',paddingBottom:'1px',background:'white',marginLeft:'2%',width:'94%',cursor: 'grab',border: task?.errorMessage ? '2px solid red':''}}  ref={task?.errorMessage ? taskPositionRef: null }>
              <div ref={ref} style={{display:'flex',width:'100%'}}>
                <div style={{width:'4%'}}><img style={{marginTop:'5px',marginLeft:'10px',marginRight:'10px',width:'26px'}} alt='' src={ImageSource} /></div>
                  <div style={{display:'grid',width:'100%',textOverflow:'ellipsis',overflow:'hidden'}}>
                    <span style={{fontSize:'15px',fontWeight:'bold',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',paddingBottom:'1px'}}>{titleText}</span>
                    <span style={{fontSize:'13px',paddingBottom:'5px',color:'rgb(98, 100, 100)',fontWeight:'normal',display:'inline-block',overflow:'hidden',textOverflow:'ellipsis'}}>{detailsText}</span>
                  </div>
                <div style={{float:'right',alignItems:'right',alignSelf:'right',width:'10%',display:'flex',paddingTop:'10px',paddingLeft:'30px'}}>
                    <ContentCopyIcon title="copy" style={{fontSize:'18px',marginRight:'4px',color:'rgb(5, 138, 229)',cursor:'pointer'}} onClick={() => copyTask(task)}/>
                    <EditIcon title="edit" style={{fontSize:'18px',marginRight:'4px',color:'rgb(5, 138, 229)',cursor:'pointer'}} onClick={() => editTask(task)}/>
                    <CloseIcon title="close" style={{fontSize:'18px',color:'rgb(5, 138, 229)',cursor:'pointer'}} onClick={() => deleteTask(task)}/>
                </div>
              </div>
            </div>
          </Tooltip>
        </>
    )
}

export default WorkflowSetupCard;