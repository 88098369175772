import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';


function isQuickBooksConnected() {
    let url = endPoints.QUICKBOOKS_INTEGRATION.IS_CONNECTED;
    try {
        return HTTPClient.get(url, null);
    } catch (e) { console.error("Error in 'quickBooksAction.js -> isQuickBooksConnected()':" + e); }
}

function getQuickBooksInfo() {
    let url = endPoints.QUICKBOOKS_INTEGRATION.GET;
    try {
        return HTTPClient.get(url, null);
    } catch (e) { console.error("Error in 'quickBooksAction.js -> getQuickBooksInfo()':" + e); }
}

function saveQuickBooksFieldMapping(params) {
    let url = endPoints.QUICKBOOKS_INTEGRATION.SAVE_FIELD_MAPPING;
    try {
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'quickBooksAction.js -> saveQuickBooksFieldMapping()':" + e); }
}

function updateQuickBooksDetails(params) {
    let url = endPoints.QUICKBOOKS_INTEGRATION.UPDATE_DETAILS;
    try {
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'quickBooksAction.js -> updateQuickBooksDetails()':" + e); }
}

function disconnect() {
    let url = endPoints.QUICKBOOKS_INTEGRATION.DISCONNECT;
    try {
        return HTTPClient.post(url, null);
    } catch (e) { console.error("Error in 'quickBooksAction.js -> disconnect()':" + e); }
}

export {
    isQuickBooksConnected,
    getQuickBooksInfo,
    saveQuickBooksFieldMapping,
    updateQuickBooksDetails,
    disconnect
}
