import React, {useEffect, useState, useRef} from 'react';
import Button from '@mui/material/Button';
import {MenuItem} from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { getUserList } from '../../../services/actions/UserListAction';
import ShowCircularProgress from '../components/circularProgress';
import { isValidParam, isInvalidParam, getBooleanParam, getStringParam } from '../../../services/helper/parameterVerifier';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { addNode } from '../../../services/actions/automationCampaignActions';
import { removeError } from '../../../services/helper/automationUtils';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import SFAutoComplete from '../components/sfAutoComplete';
import {useDispatch, useSelector} from 'react-redux';
import { isAssignRuleExists } from '../../../services/actions/assignmentRuleActions';
 
const AutomationAssign = ({data,closeDrawer}) => {
    const dispatch = useDispatch();
	const fieldRefStatus = useRef('field-ref-status');
    const app = useSelector((state) => state.app);
    const automationCampaign = useSelector((state) => state.automationCampaign);
    const userlist = useSelector((state) => state.userlist);
    const [valueUser,setValueUser] = useState(0);
    const [userName,setUserName] = useState("");
    const [isAssignRuleExist,setIsAssignRuleExist] = useState(false);


    useEffect(() => {
        dispatch(getUserList());
        isAssignmentRuleExist();
        let nodeObject = data;
        if (isValidParam(nodeObject)) {
            let detailsArray = null;
            if (nodeObject.hasOwnProperty('details')) {
                let detailsArray = nodeObject.details[0];
                if (isValidParam(detailsArray)) {
                    let paramValue = valueUser;
                    let object_indentifierText = userName;
                    if (detailsArray.hasOwnProperty('param_value')) {
                        paramValue = detailsArray.param_value;
                        paramValue = parseInt(paramValue);
                    }
                    if (detailsArray.hasOwnProperty('object_indentifierText')) {
                        object_indentifierText = detailsArray.object_indentifierText;
                    }
                    if (object_indentifierText === null || object_indentifierText === undefined || object_indentifierText === "") {
                        if (paramValue == -999) {
                            object_indentifierText = 'Use Assignment Rule';

                        }
                        if (paramValue !== -999) {
                            paramValue = 0;
                        }
                     
                    }
                    
                    setValueUser(paramValue);
                    setUserName(object_indentifierText);
                }
            }
        }
    }, []);

    const isAssignmentRuleExist = () => {
        let paramObj = { table_id: OBJECT_TABLEID_MAP[constants.ACCOUNTS_OBJECT] };
        let response = Promise.resolve(isAssignRuleExists(paramObj));
        response.then((response) => {
            if (response.hasOwnProperty('isExists')) {
                setIsAssignRuleExist(getBooleanParam(response.isExists) );
            }

        });
    }

    const generateUserList = () => {
        let userList = [];
        let arrayUserList = [...userlist.data];

        if (isValidParam(arrayUserList)) {

            if (getBooleanParam(isAssignRuleExist)) {
                let obj = {
                    id: -999,
                    text: 'Use Assignment Rule',
                    value: 'Use Assignment Rule'
                };

                arrayUserList.splice(0, 0, obj);
            }

            if (getStringParam(app.me.t_name) === 'Admin' ||
                getBooleanParam(app.me.is_tenant)) {
                let obj = {
                    id: 'Setup Assignment Rule',
                    text: 'Setup Assignment Rule',
                    value: 'Setup Assignment Rule'
                }

                arrayUserList.push(obj);
            }

            userList = arrayUserList.map((objUser, i) => {
                return (
                    <MenuItem key={objUser.text} value={objUser.text} id={objUser.text}  style={styles.popoverMenuItem} >{objUser.text}</MenuItem>
                    // <MenuItem value={objUser.id}  onClick={() => this.selectUser(objUser)} >{objUser.text}</MenuItem>
                );
            });
        }
        return userList;
    }

    const selectUser = (fieldName,event, value) => {
        try {
            if (isValidParam(value)) {
                
                let assignId = valueUser;
                if(value !== "Setup Assignment Rule" && value !== "Use Assignment Rule"){
                    let arrayUserList = [...userlist.data];
                    let groupItem = arrayUserList.filter(f => { return f.text === value });
                    let item= groupItem[0];
                    assignId =item.id;
                } else if(value === "Use Assignment Rule"){
                    assignId = -999;
                } else {
                    assignId = value;
                }
                
                setValueUser(assignId);
                setUserName(value);
                
                if (value !== null && value === "Setup Assignment Rule") {
                    dispatch(getAppDrawer(false, null, null, null, null));
                    openAssignmentRuleDrawer();
                }
            }
        } catch (e) { }
    }

    

  const  openAssignmentRuleDrawer = () => {
        let drawerName = getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULE;

        let obj = constants.ACCOUNTS_OBJECT;
        let data = {};
        data.object = constants.ASSIGNMENT_RULES;
        data.id = null;
        data.table = obj;
        data.mode = constants.NEW;
        data.callFrom = 'Assignment Rules';

        dispatch(getAppDrawer(true, drawerName, constants.NEW, data, null));
    }


   const saveAssignTo = () => {
        let nodeObject = data;
        let object_indentifierText = userName;
        let param_value = valueUser;
        let dialogData = null;
        if (object_indentifierText == "" ) {
            dialogData = {
                message: getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_USER,
            }
            openDialog(dialogData);
        } else {
            if (isValidParam(nodeObject)) {
                if (nodeObject.hasOwnProperty('details')) {
                    let detailsArray = nodeObject.details[0];
                    if (isValidParam(detailsArray)) {
                        detailsArray.object_indentifierText = object_indentifierText;
                        detailsArray.param_value = param_value;
                    } else {
                        detailsArray = {
                            param_value: param_value,
                            action_type: "assign",
                            qualifier_1: "",
                            object_indentifierText: object_indentifierText
                        }
                        nodeObject.details.push(detailsArray);
                    }
                    nodeObject = removeError(nodeObject);
                    dispatch(addNode(automationCampaign.data));
                    closeDrawer();
                }
            }
        }
    }

   const openDialog = (dialogData) => {
        dispatch(getAppDialog(true, constants.ALERT_DIALOG, getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, null, dialogData, constants.AUTOMATION_DESIGNER_OBJECT));
    }

        let campaignStatus = automationCampaign.data.status;
        let contentHeight = window.innerHeight - 200;
        let top = (contentHeight - 10) / 2;
        if (userlist.mounted) {
            let userList = generateUserList();
            return (
                <div style={{ paddingTop: '8px', paddingLeft: '20px', paddingRight: '20px' }}>
                    <div>
                        <SFAutoComplete
                            key={'sf-auto-complete-assign'}
                            id={'assign'}
                            name={'assign'}
                            label={getLocalizedStrings().label.AUTOMATION_DESIGNER.SELECT_USER}
                            menuItems={userList}
                            disabled={false}
                            onChange={(e,value)=>selectUser('assign',e,value)}
                            value={userName}
                            underlineFocusStyle={styles.textField.underlineFocus}
                            floatingLabelStyle={{ ...styles.textField.floatingLabel, top: '10px', left: '7px' }}
                            inputStyle={{...styles.textField.input, paddingLeft: '6px'}}
                            menuItemStyle={styles.popoverMenuItem}
                            style={{...styles.sfFormFieldContainer, paddingLeft: '0px'}}
                            ref={fieldRefStatus}
                            isFullHeight={false}
                        />
                    </div>

                    {
                        (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                        <div style={{ paddingTop: '10px' }}>
                            <Button
                                style={styles.listViewPrimaryButton}
                                onClick={()=>saveAssignTo()}
                            >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                        </div>
                    }
                </div>
            );
        } else {
            return (
                <div style={{ width: '100%', height: contentHeight }} >
                    {
                        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }} >
                            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                            </div>
                        </div>
                    }
                </div>
            );
        }
    }


export default AutomationAssign;