import React from 'react';
import { useSelector } from "react-redux";

const TotalRecordCount = ({ totalCount }) => {

    const app = useSelector((state) => state.app);

    let themeColor = app.me.background;
    let borderStyle = '2px solid ' + themeColor;
    return (
        <div className="group_count" style={{ border: borderStyle }}><h2 title={totalCount} style={{ marginTop: '13px', }}>{totalCount}</h2></div>
    );

};

export default TotalRecordCount;
