import { actions } from '..//constants/actions';

const campaignTypeInitialSate = {
    data: null,
   
}

const campaignTypeReducer = (state = campaignTypeInitialSate, action) => {
    switch (action.type) {
        case actions.CAMAPIGN_TYPE_GET_FULFILLED:
            return {
                ...state,
                data: action.payload,
            }

        case actions.CAMAPIGN_TYPE_SET:
            return {
                ...state,
                data: action.payload,
            }
     
        default:
            return state;
    }
}

export default campaignTypeReducer;