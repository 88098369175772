import React from 'react';
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { isValidParam, getArrayParam, getStringParam, getIntParam, getObjectParam, getFloatParam,isInvalidParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { LinkToType } from '../../../services/constants/link';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { addTab, getActiveTabInfo, updateTabInfo, getActiveTab, closeTab, TYPE_DETAIL_VIEW ,addNewFormTabs} from '../../../services/helper/sfTabManager';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import ShowCircularProgress from '../components/circularProgress';
import AsyncSelect from 'react-select/async';
import { dateFormat, dateFormatFlatPicker, formatCurrency, phoneFormat, sortArrayObjectByProperty } from '../../../services/helper/utils';
import moment from 'moment';
import { setHeader } from '../../../services/actions/headerActions';
import { saveRecentRecord, getRecentRecord } from '../../../services/helper/common';
import { Button, Chip, FormControl, MenuItem, InputLabel,TextField, Select as SelectField} from '@mui/material';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { getListData, refreshListView } from '../../../services/actions/listViewAcions';
import { getContactInfoForLookup, getLookupAutocompleteInfo } from '../../../services/actions/sfFormActions';
import * as SFFormActions from '../../../services/actions/sfFormActions';
import * as sfDialogs from '../components/sfDialogs';
import { refreshColumnView } from '../../../services/actions/listsActions';
import Autocomplete from '@mui/material/Autocomplete';
import PopOver from './PopOver';

const JobManagement =({object,id})=> {
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const [mounted, setMounted] = useState(false);
    const [jobObject, setJobObject] = useState({});
    const [isLoading, setisLoading] = useState(false);
    const [autoCompleteObj, setautoCompleteObj] = useState({});
    const [error, setError] = useState({});
    const [disableInvoiceInterval, setdisableInvoiceInterval] = useState(false);
    const [parentRecordId, setparentRecordId] = useState(null);
    const [parentObject, setParentObject] = useState(null);
    const [type, setType] = useState(null);
    const [productinfoList, setProductinfoList] = useState(null);
    const [changeData, setChangeData] = useState({ field: '', value: '' });
    const appState = useSelector((state) => state.app);
	const reduxDetailView = useSelector((state) => state.detailView);

    useEffect(() => {
        dispatch(setHeader(object, null, null, false, false));
        getRecordInformation();
        inputRef.current?.focus();
    }, []);

    useEffect(() => {
        if(mounted && jobObject!= undefined && jobObject.length > 0){
            addFloatingLabelEvent();
        }
    },[jobObject]);



    const  getRecordInformation = () => {
        let params = null;
        var promise = null;
        try {
            let activeTabInfo = getObjectParam(getActiveTabInfo());
            let call_from = getStringParam(activeTabInfo.object);
            params = {};
            if (call_from === constants.PAYMENTS_OBJECT) {
                params.paymentId = activeTabInfo.id;
            } else {
                params.id = getIntParam(activeTabInfo.id);
            }
            params.parentRecordId = getIntParam(activeTabInfo.parentRecordId);
            params.parentTableId = getIntParam(OBJECT_TABLEID_MAP[getStringParam(activeTabInfo.parentObject)]);
            params.callFrom = activeTabInfo.callFrom;
            params.quoteId = activeTabInfo.quoteId;
            
            promise = Promise.resolve(HTTPClient.get(endPoints.JOBS.GET, params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response) && response.status !== -1) {
                        let _jobObject = response;
                        let records = getObjectParam(response.records);
                        if (records.hasOwnProperty('account')) {
                            let accountVal = getStringParam(records.account);
                            let lookupObjVal = { id: 0, label: accountVal, value: accountVal };
                            _jobObject.lookupObjValue = lookupObjVal;
                        }

                        setJobObject({..._jobObject});
                        if (getIntParam(_jobObject.id) > 0) {
                            let recentParams = { recordId: _jobObject.id, type: "Object" };
                            saveRecentRecord(object, recentParams);
                            getRecentRecord(object);
                        }
                        let tempParentRecordId = getIntParam(activeTabInfo.parentRecordId);
                        let tempParentObject = getStringParam(activeTabInfo.parentObject);
                        let tempType = activeTabInfo.type;
                        setparentRecordId(tempParentRecordId);
                        setParentObject(tempParentObject);
                        setMounted(true);
                        setType(tempType);
                        if(_jobObject.fields.length>0){
                            processData(_jobObject, tempParentRecordId, tempParentObject, tempType);

                        }
                    }
                })
            }
        } catch (error) {
            console.error("Error in 'JobManagement.js -> getRecordInformation()':" + error);
        }
    }

    const processData = (tempJobObject, parentRecordId, parentObject, type) => {
        let { records = {} } = tempJobObject;
        try {
            let _productinfoList = getArrayParam(tempJobObject.productinfo).map(m => { return { value: m.name, label: m.name, productid: m.productid, data: m } });
            let newOpt = { desc: "", name: 'Add a new product', productid: "-99", qty: "0", tax: "No", uprice: "0" };
            _productinfoList.push({ value: 'Add a new product', label: 'Add a new product', productid: "-99", data: newOpt });
            setProductinfoList(_productinfoList)
            if (type === 'New' || type === 'Copy') {
                records['job_id'] = new Date().valueOf().toString();
                let fieldList = [];
                if (type === 'New') getArrayParam(tempJobObject.fieldsPosList).forEach(e => fieldList.push(...e.list));
                else if (type === 'Copy') fieldList.push('t_status');
                let tempRecords = {};
                fieldList.forEach(e => {
                    let obj = getArrayParam(tempJobObject.fields).find(f => f.name === e);
                    if (obj && obj.default_value) {
                        tempRecords[e] = obj.default_value;
                    }
                });
                records = { ...records, ...tempRecords };
                if (type === 'Copy' || (tempJobObject.id === 0 && tempJobObject.lineiteminfo)) {
                    tempJobObject.id = 0;
                    let lineiteminfo = getArrayParam(tempJobObject.lineiteminfo).map(m => { return { ...m, id: 0 } });
                    tempJobObject.lineiteminfo = lineiteminfo;
                }
                 tempJobObject.fieldsPosList = [...tempJobObject.fieldsPosList, {validFieldsList: fieldList}]
                tempJobObject.records = records;
                setJobObject({...tempJobObject});       
                if (getIntParam(parentRecordId) > 0) {
                    setLookupFields(parentRecordId, parentObject,tempJobObject);
                }
            }
            if (type !== 'New') {
                manageAutoCompleteList();
            }
        } catch (error) {
            console.error("Error in 'JobManagement.js -> processData()':" + error);
        }
    }

    const addFloatingLabelEvent = () => {
        let { records = {} } = jobObject;
        try {
            let fields = getArrayParam(jobObject.fields).filter(f => {
                return (f.field_type === constants.FIELD_TYPE_DATE_TIME || f.field_type === constants.FIELD_TYPE_DATE || f.field_type === constants.FIELD_TYPE_LIST || f.is_lookup_field)
            });
            fields.map(f => {
                if (isValidParam(records[f.name])) {
                    let value = records[f.name];
                    if (value !== '') {
                        let ele = document.getElementsByClassName("customLabelDiv-" + f.name);
                        if (isValidParam(ele) && ele.length > 0) {
                            ele[0].classList.add('label-top');
                        }
                    }
                }
            });
        } catch (error) {
            console.error("Error in 'JobManagement.js -> addFloatingLabelEvent()':" + error);
        }
    }

    const manageAutoCompleteList = () => {
        let { records = {} } = jobObject;
        let tempAutoCompleteObj = autoCompleteObj;
        try {
            getArrayParam(jobObject.fields).filter(m => m.is_lookup_field).forEach(e => {
                let obj = getArrayParam(jobObject.lookup_info).find(f => f.lookup_object_name === e.lookup_object);
                if (obj) {
                    tempAutoCompleteObj[e.name] = [{ label: records[e.name], value: records[e.name], id: obj.record_id }];
                    setautoCompleteObj(tempAutoCompleteObj);
                }
            });
        } catch (error) {
            console.error("Error in 'JobManagement.js -> manageAutoCompleteList()':" + error);
        }
    }

    const handleActions = (name) => {
        try {
            if (name === 'save' || name === constants.SAVE_AND_SCHEDULE) {
                handleSave(name);
            } else if (name === 'cancel') {
                handleCancel();
            }
        } catch (error) {
            console.error("Error in 'JobManagement.js -> handleActions()':" + error);
        }
    }

    const generateHeaderbuttons = () => {
        let element = [];
        try {
            let list = [{ name: 'save', label: getLocalizedStrings().label.COMMON.SAVE }, { name: constants.SAVE_AND_SCHEDULE, label: getLocalizedStrings().label.COMMON.SAVE_AND_SCHEDULE }, { name: 'cancel', label: getLocalizedStrings().label.COMMON.CANCEL }];
            element = list.map(item => {
                let buttonStyle = item.name === 'cancel' ? { ...styles.secondaryButton, verticalAlign: 'top' } : { ...styles.primaryButton,width:item.name=='save & schedule'?'':'20px' , verticalAlign: 'top' }
                return (
                    <Button
                        key={`key-${item.name}`}
                        onClick={() => handleActions(item.name)}                        
                        style={{...styles.primaryButton, verticalAlign: 'top',...buttonStyle }}
                        labelstyle={{ fontWeight: 'normal' }}
                        disabled={isLoading}
                    >{item.label}</Button>
                );
            });
        } catch (error) {
            console.error("Error in 'JobManagement.js -> generateHeaderbuttons()':" + error);
        }
        return element;
    }

    const validate = () => {
        let isValidate = true;
        let { records = {}, fields = [] } = jobObject;
        try {
            let fieldList = [];
            let tempError = error;
            getArrayParam(jobObject.fieldsPosList).forEach(e => fieldList.push(e.list));
            let feilds = fields.filter(f => getStringParam(records[f.name]).trim() === '');
            
            if (feilds.length > 0 && (jobObject.records.job_name === "" || jobObject.records.job_name === undefined ||jobObject.records.account === "" || jobObject.records.account === undefined)) {
                tempError[feilds[0].name] = true;
                dispatch(showCustomSnackBar(`${feilds[0].label}` + getLocalizedStrings().message.JOBS.FIELDS_CANNOT_BLANK, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                setError(tempError)
                focusElementByName(feilds[0]);
                return false;
            }
            setError(tempError);

            let date = new Date(records['end_date']) - new Date(records['start_date']);
            if (date < 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.USER_REPORT.INVALID_START_END_DATE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }

            let lineInfoArr = getArrayParam(jobObject.lineiteminfo);
            let tempLineInfoArr = lineInfoArr.filter(u => { return u.name.indexOf(":") > -1 });
            if (tempLineInfoArr.length > 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.USER_REPORT.INVALID_START_END_DATE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }
        } catch (error) {
            console.error("Error in 'JobManagement.js -> validate()':" + error);
        }
        return isValidate;
    }

    const handleSave = (type) => { 
        try {
            if (validate()) {
                let params = {};
                let newProductList = getArrayParam(jobObject.lineiteminfo).filter(f => f.productid === "-99" && f.name !=="Add a new product").map(m => m.name);
                if (newProductList.length > 0) {
                    params.name_list = newProductList;
                    let promise = Promise.resolve(HTTPClient.post(endPoints.PRODUCTS.CHECK_DUPLICATE_PRODUCTS, params));
                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            let arrValue = getArrayParam(response.data);
                            if (arrValue.length > 0) {
                                let msg = "";
                                let msgArr = [];
                                if (arrValue.length > 0) {
                                    arrValue.forEach(val => {
                                        msg = '<span>' + getLocalizedStrings().message.JOBS.PRODUCT + ' ' + '<b>' + val +'</b> '+ getLocalizedStrings().message.JOBS.ALREADY_EXIST+'<br /></span>';
                                        msgArr.push(msg);
                                    });
                                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msgArr, null);
                                }
                            } else {
                               processSave(type);
                            }
                        });
                    }
                } else {
                    processSave(type);
                }
            }
        } catch (error) {
            console.error("Error in 'JobManagement.js -> handleSave()':" + error);
        }
    }

    const processSave = (type) => {
        let { records = {} } = jobObject;
        let tempObjectData = jobObject;
        try {
            let params = {};
            params.id = tempObjectData.id;
            let fieldList = [];
            getArrayParam(tempObjectData.fieldsPosList).forEach(e => fieldList.push(e.list));
            let fields = [];
            fieldList.push("contact")
            fieldList.push("address")
            fieldList.push("email")
            fieldList.push("job_name")
            fieldList.push("account")
            fieldList.push("job_id")
            fieldList.push("mobile")
            fieldList.push("phone")
            fieldList.push("sales_rep")
            fieldList.push("invoice_mode")
            fieldList.push("address_notes")
            fieldList.push("instructions")
            fieldList.push("invoice_interval")

            fieldList.forEach(m => isValidParam(records[m]) && fields.push({ name: m, value: records[m] }));
            params.fields = fields;
            params.lookup_info = tempObjectData.lookup_info;
            let lineiteminfo = tempObjectData.lineiteminfo.filter(f =>  f.name !=="Add a new product")
            params.lineiteminfo = lineiteminfo;
            params.deletedLineItemsIds = tempObjectData.deletedLineItemsIds;
            if (tempObjectData.id === 0) {
                params.is_notify_sales_rep = true;
            }
            setisLoading(true);
            let promise = SFFormActions.saveRecordPromise(object, params);
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (response.data && response.status !== -1) {
                        let id = getArrayParam(response.data.id).length > 0 ? getArrayParam(response.data.id)[0] : 0;
                        if (id > 0) {
                            tempObjectData.id = id;
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.JOBS.JOB_SAVE_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            let activeTabInfo = getObjectParam(getActiveTabInfo());
                            activeTabInfo.isClose = true;
                            updateTabInfo(activeTabInfo);
                            let activeTab = getObjectParam(getActiveTab());
                            closeTab(activeTab.item, activeTab.index);
                            let callFrom = activeTab.info.callFrom;
                            if (callFrom === constants.LIST_VIEW) {
                                refreshListView(activeTab.item);
                            } else if (callFrom === constants.COLUMN_VIEW) {
                                refreshColumnView(activeTab.item);
                            }
                            let label = getStringParam(jobObject.records.job_name);
                            label = label === '' ? "EMPTY" : label;
                            let linkUrl = '/' + object + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + id;
                            let tab = { label: getStringParam(label), object: object, type: TYPE_DETAIL_VIEW, url: linkUrl, isActive: true };
                            if (type === constants.SAVE_AND_SCHEDULE) {
                                tab.callFor = type;
                            }
                            addTab(tab, true);
                            setJobObject({...tempObjectData});
                            let info = {
                                title: "Schedule for " + tempObjectData.records.job_id,
                                type: "jobscheduler",
                                object: "jobs",
                                detailView_recordid: tempObjectData.id,
                                jobName: tempObjectData.records.job_name,
                            };
                            if(type === constants.SAVE_AND_SCHEDULE) {
                                addNewFormTabs(info);
                            }
                        }
                    }
                    setisLoading(false);
                });
            }
        } catch (error) {
            console.error("Error in 'JobManagement.js -> processSave()':" + error);
        }
    }

    const handleCancel = () => {
        try {
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.SCHEDULER.SCHEDULER_UNSAVE_msg, ()=>handleSFDialogCancel(), null);
        } catch (error) {
            console.error("Error in 'JobManagement.js -> handleCancel()':" + error);
        }
    }

    const handleSFDialogCancel = () => {
        let activeTab = getObjectParam(getActiveTab());
        closeTab(activeTab.item, activeTab.index);
    }

    const onChangeProduct = (name, rowId, type, event) => {
        let lineiteminfo = getArrayParam(jobObject.lineiteminfo);
        let tempjobObject = jobObject;
        let value = event.target.value;
       
            if (type === 'field') {
                let isUpdate = true;
                if (value !== '' && (name === 'qty' || name === 'uprice')) {
                    let regExp = name === 'qty' ? /^\d{0,4}(?:\.\d{0,2})?$/ : /^-?\d*(?:\.\d{0,2})?$/;
                    isUpdate = regExp.test(value);
                }
                if (isUpdate) {
                    lineiteminfo[rowId] =  { ...lineiteminfo[rowId], [name]: value } ;
                    tempjobObject.lineiteminfo = lineiteminfo;
                    setJobObject({...tempjobObject});
                    setChangeData({ field: rowId, value: value });
                }
            } else if (type === 'select') {
                let _productinfoList = getArrayParam(jobObject.productinfo).map(m => { return { value: m.name, label: m.name, productid: m.productid, data: m } });
                    let newOpt = { desc: "", name: 'Add a new product', productid: "-99", qty: "0", tax: "No", uprice: "0" };
                    _productinfoList.push({ value: 'Add a new product', label: 'Add a new product', productid: "-99", data: newOpt });
                 
                let tempProductinfoList = getObjectParam(_productinfoList);
                tempProductinfoList = tempProductinfoList.find(f => f.productid === value);

                if (tempProductinfoList) {
                    value = getStringParam(tempProductinfoList.value);

                    let productDataList = getObjectParam(tempProductinfoList.data);
                    lineiteminfo = lineiteminfo.map((m, index) => {
                        let obj = m;
                        if (index === rowId) {
                            Object.keys(m).forEach(e => {
                                if (e !== 'id') {
                                    obj = { ...obj, [e]: productDataList[e] };
                                }
                            })
                        }
                        return obj;
                    });

                    if (lineiteminfo.length > 0 && lineiteminfo.length === rowId + 1) {
                        let obj = {};
                        Object.keys(lineiteminfo[0]).forEach(e => {
                            if (e !== 'id') {
                                obj = { ...obj, [e]: '' };
                            }
                        });
                        lineiteminfo.push(obj);
                    }
                    tempjobObject.lineiteminfo = lineiteminfo;
                    setJobObject({...tempjobObject});
                    setChangeData({ field: rowId, value: value });
                }
            }
        
    }

    const getMenuItems = () => {
        let _productinfoList = getArrayParam(jobObject.productinfo).map(m => { return { value: m.name, label: m.name, productid: m.productid, data: m } });
            let newOpt = { desc: "", name: 'Add a new product', productid: "-99", qty: "0", tax: "No", uprice: "0" };
            _productinfoList.push({ value: 'Add a new product', label: 'Add a new product', productid: "-99", data: newOpt });
         
        let objectItems = null;
        try {
            let objectList = getArrayParam(_productinfoList);
            objectItems = objectList.map((item, index) => {
                return <MenuItem key={index + '-' + item.value} id={item.value} value={getObjectParam(item.data).productid} style={styles.popoverMenuItem} title={item.label} dataset={item.data}>{item.label}</MenuItem>;
            });
        } catch (error) {
            console.error("Error in 'JobManagement.js -> getMenuItems()':" + error);
        }
        return objectItems;
    }

    const handleDeleteLineItems = (index, id) => {
        try {
            let lineiteminfo = getArrayParam(jobObject.lineiteminfo);
            let deletedLineItemsIds = [];
            let tempJobObject = jobObject;
            if (lineiteminfo.length > 1) {
                lineiteminfo.splice(index, 1);
                tempJobObject.lineiteminfo = lineiteminfo;
                setJobObject({...tempJobObject});
            } else {
                let obj = {};
                Object.keys(lineiteminfo[0]).forEach(e => {
                    if (e !== 'id') obj = { ...obj, [e]: '' };
                });
                tempJobObject.lineiteminfo[0] = obj;
            }

            if (id > 0) {
                deletedLineItemsIds = getArrayParam(jobObject.deletedLineItemsIds);
                deletedLineItemsIds.push({ 'id': id });
                tempJobObject.deletedLineItemsIds = deletedLineItemsIds;
            }
            setJobObject({...tempJobObject});
        } catch (error) {
            console.error("Error in 'JobManagement.js -> handleDeleteLineItems()':" + error);
        }
    }

    const generateProductItems = () => {
        let lineiteminfo = getArrayParam(jobObject.lineiteminfo);
        let productRowList = [{ name: 'name', label: 'ITEM' }, { name: 'desc', label: 'DESCRIPTION' }, { name: 'qty', label: 'QTY' },
        { name: 'uprice', label: 'RATE' }, { name: 'amount', label: 'AMOUNT' }, { name: 'delete', label: '' }];
        let symbol = appState.me.currency_symbol ? appState.me.currency_symbol : '$';

        let headerStyle = { border: '1px solid #ddd', height: 'inherit', paddingTop: '6px' };
        let gridTemplateColumns = '20% 39% 10% 13% 13% 5%';

        try {
            return (
                <div style={{ width: "100%" }} >
                    <div style={{ display: 'grid', gridTemplateColumns, textAlign: "center", height: '40Px', color: '#333', fontWeight: 'bold' }} >
                        {productRowList.map(obj => {
                            return <div style={headerStyle}>{obj.label}</div>
                        })}
                    </div>
                    {lineiteminfo.map((item, index) => {
                        let editItemList = ['name', 'desc', 'qty', 'uprice'];
                        let lineItemId = getIntParam(item.productid);
                        return (
                            <div className="quote-table" key={`line-item-tr-${item.productid}-${index}`} style={{ display: 'grid', gridTemplateColumns, textAlign: 'center', height: '101px' }}>
                                {productRowList.map(obj => {
                                    let key = obj.name;
                                    let components = null;
                                    let value = item[key];
                                    let pId = item['productid'];
                                    if (key === 'amount') {
                                        value = Number(item['qty']) * Number(item['uprice']);
                                        value = value !== 0 ? value : '';
                                    }
                                    if (editItemList.includes(key)) {
                                        if (key === 'name' && pId !== "-99") {
                                            components =
                                                <div key={`line-item-td-${key}-${index}`} style={{ height: '100%', padding: '2px 2px' }}>

                                                    <FormControl variant="outlined" style={{ width: '100%' }}>
                                                        <InputLabel id="sf-jobmanagement-simple-select-outlined-label" className="sf-jobmanagement"></InputLabel>
                                                        <SelectField
                                                            placeholder='Select Item'
                                                            key={`product-list-${key}-${index}`}
                                                            value={pId}
                                                            title={value}
                                                            name={'product-' + key}
                                                            style={{ height: '95px' }}
                                                            className={"sf-fields-bg"}
                                                            onChange={(event)=>onChangeProduct("name", index, 'select',event)}
                                                        >
                                                            {getMenuItems()}
                                                        </SelectField>
                                                    </FormControl>
                                                </div>
                                        } else {
                                            components =
                                                <div key={`line-item-td-${key}-${index}`} style={{ height: '95px', padding: '2px 2px' }}>
                                                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                                        <TextField
                                                            variant="outlined"
                                                            id={`product-field-${key}`}
                                                            key={`field-${key}-key`}
                                                            maxRows={3}
                                                            type='textarea'
                                                            rows={3}
                                                            value={value === 'Add a new product' ? "" : value}
                                                            title={value}
                                                            onChange={(event)=>onChangeProduct(key, index, 'field',event)}
                                                            inputprops={{
                                                                maxLength: key === 'name' ? 128 : 1024,
                                                                style: { height: '95px',textAlign: 'center' }
                                                            }}                                                            
                                                            autoFocus={value === 'Add a new product' ? true : false}
                                                            multiline={key === 'desc' ? true : false}
                                                            autoComplete="new-password"
                                                            className={"sf-fields-bg"}
                                                        />
                                                    </FormControl>
                                                </div>
                                        }
                                    } else {
                                        let isDelete = true;
                                        if (index + 1 === lineiteminfo.length && item.name === '') {
                                            isDelete = false;
                                        }
                                        if ((key === 'amount' || key === 'uprice') && value !== '') {
                                            value = symbol + formatCurrency(getFloatParam(value));
                                        }
                                        if (key === 'delete') {
                                            components =
                                            <div style= {{marginBottom:'8px'}}>
                                                <div key={`line-item-td-${key}-${index}`} style={{ padding: '5px', height: '100%', borderLeft: '1px solid #ddd', lineHeight: '72px', backgroundColor: 'white' }}>
                                                    {isDelete && <span className='page-action' title='Delete' style={{ color: '#717171', cursor: 'pointer', padding: '5px', fontSize: '16px' }} onClick={()=>handleDeleteLineItems(index, lineItemId)}><i className="fa fa-times"></i></span>}
                                                </div>
                                                </div>
                                        } else {
                                            components =
                                            <div style= {{marginBottom:'8px'}}>
                                                <div key={`line-item-td-${key}-${index}`} title={value} style={{ height: '100%', paddingTop: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: '60px', backgroundColor: '#fff' }}>
                                                    {value}
                                                </div>
                                                </div>
                                        }
                                    }
                                    return components;
                                })}
                            </div>
                        );
                    })}

                </div>
            )
        } catch (error) {
            console.error("Error in 'JobManagement.js -> generateProductItems()':" + error);
        }
    }

    const openLookup = (field) => {
        let { records = {} } = jobObject;
        try {
            if (field) {
                let value = records[field.name] ? records[field.name] : '';
                let data = {};
                data.fieldName = getStringParam(field.lookup_field_name);
                data.searchText = getStringParam(value);
                data.isLookupField = true;
                data.lookupFieldName = getStringParam(field.lookup_field_name);
                data.lookupObject = getStringParam(field.lookup_object);
                dispatch(getAppDialog(true, constants.LOOKUP_DIALOG, `Find and Link ${field?.label}`, getLookupSelectedRecord, data, null));
            }
        } catch (error) {
            console.error("Error in 'JobManagement.js -> openLookup()':" + error);
        }
    }

    const setContactLookupInfo = (recordId,tempJobObject) => {
        let params = {};
        let { records = {}, fields = [] } = tempJobObject;
        try {
            let contactField = fields.find(f => f.lookup_object === constants.CONTACTS_OBJECT);
            if (contactField) {
                params.object = constants.ACCOUNTS_OBJECT;
                params.record_id = recordId;
                params.child_table_id = OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT];
                params.is_get_primary_contact = true;
                let promise = Promise.resolve(getContactInfoForLookup(params));
                
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            let childRecordArr = getArrayParam(response.records);
                            sortArrayObjectByProperty(childRecordArr, 'value');
                            if (childRecordArr.length > 0) {
                                let tempChildObj = childRecordArr[0];
                                let obj = childRecordArr.find(f => f.id === getObjectParam(response.primary_contact).id);
                                if (obj) {
                                    tempChildObj = obj;
                                    records[contactField.name] = tempChildObj.full_name;
                                }
                                setDepFields(tempChildObj, constants.CONTACTS_OBJECT, false,tempJobObject);
                                setContactRecordList(childRecordArr);
                            }
                        }
                    });
                }
            }
        } catch (error) {
            console.error("Error in 'JobManagement.js -> setContactLookupInfo()':" + error);
        }
    }

    const setDepFields = (tempRecord, lookupObject, isSkip,tempJobObject) => {
        let { records = {}, lookup_info = [] } = tempJobObject;
      
        let tempjobObject = tempJobObject;
        let depFieldList = getDepFieldsList(lookupObject)
        try {
            if (depFieldList) {
                depFieldList.forEach(name => {
                    let obj = mapFields.find(f => f.name === name);
                    if (obj) {
                        let fieldLabels = '';
                        getArrayParam(obj.mapFieldsList).forEach(e => {
                            if (tempRecord[e])
                                fieldLabels += fieldLabels === '' ? tempRecord[e] : ', ' + tempRecord[e];
                        });
                        records[name] = getStringParam(fieldLabels).trim();
                    }
                });
                let isPresent = lookup_info.some(f => f.lookup_object_name === lookupObject);
                if (isPresent) {
                    lookup_info = lookup_info.map(m => m.lookup_object_name === lookupObject ? { ...m, record_id: tempRecord.id } : m);
                }
                else {
                    lookup_info.push({ record_id: tempRecord.id, lookup_object_name: lookupObject });
                }
                tempjobObject.records = records;
                if (records.hasOwnProperty('account')) {
                    let accountVal = getStringParam(records.account);
                    let lookupObjVal = { id: 0, label: accountVal, value: accountVal };
                    tempjobObject.lookupObjValue = lookupObjVal;

                    if (records.hasOwnProperty('job_name')) {
                        let jobNameVal = getStringParam(records.job_name);
                        if (isInvalidParam(jobNameVal)) {
                            records['job_name'] = "Job for " + accountVal || '';
                        }
                    } else {
                        records['job_name'] = "Job for " + accountVal || '';
                    }
                }
                tempjobObject.lookup_info = lookup_info;
                setJobObject({...tempjobObject});
                if (!isSkip) {
                    manageAutoCompleteList();
                }
            }
        } catch (error) {
            console.error("Error in 'JobManagement.js -> setDepFields()':" + error);
        }
    }

    const setLookupFields = (recordId, lookupObject,tempJobObject) => {
        let fieldList = [];
        let depFieldList = getDepFieldsList(lookupObject);
        try {
            if (depFieldList) {
                depFieldList.forEach(name => {
                    let obj = mapFields.find(f => f.name === name);
                    obj && fieldList.push(...obj.mapFieldsList);
                });
                let params = { fields: fieldList, search_field_name: 'id', search_text: recordId, start_index: 0, page_size: 1,  
                query_name: 'All records', query_type: 'all'};
                let promise = Promise.resolve(getListData(lookupObject, params));
                promise.then((response) => {
                    let tempRecords = isValidParam(response) ? getArrayParam(response.records) : [];
                    let tempRecord = tempRecords.length > 0 ? tempRecords[0] : {};
                    if (Object.keys(tempRecord).length > 0) {
                        setDepFields(tempRecord, lookupObject,false,tempJobObject);
                        if (lookupObject === constants.ACCOUNTS_OBJECT) {
                            setContactLookupInfo(recordId,tempJobObject);
                        } else if (lookupObject === constants.CONTACTS_OBJECT) {
                            setContactRecordList(tempRecords);
                        }
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'JobManagement.js -> setLookupFields()':" + error);
        }
    }

    const setContactRecordList = (tempRecords) => {
        let { fields = [] } = jobObject;
        try {
            let field = fields.find(f => f.lookup_object === constants.CONTACTS_OBJECT);
            if (field) {
                let listOptions = tempRecords.map(m => {
                    let fullName = getStringParam(m.full_name).trim();
                    return { value: fullName, label: fullName, id: m.id, data: m };
                });
                setautoCompleteObj({...autoCompleteObj, [field.name]:listOptions});

            }
        } catch (error) {
            console.error("Error in 'JobManagement.js -> setContactRecordList()':" + error);
        }
    }

    const getLookupSelectedRecord = (tempLookupInfo) => {
        let { fields = [], records = {}, lookup_info = [] } = jobObject;
        try {
            if (tempLookupInfo) {
                let recordId = tempLookupInfo.record_id;
                let lookupObject = tempLookupInfo.lookup_object_name;
                let field = fields.find(f => f.lookup_object === lookupObject);
                if (field) {
                    if (lookupObject === constants.CONTACTS_OBJECT) {
                        setLookupFields(recordId, lookupObject);
                    } else {
                        records[field.name] = tempLookupInfo.title || '';
                        records['job_name'] = "Job for " + tempLookupInfo.title || '';
                        if (lookupObject === constants.ACCOUNTS_OBJECT) {
                            setContactLookupInfo(recordId,jobObject);
                            // this.setAccountLookupInfo(recordId)
                            // this.setLookupFields(recordId, lookupObject);
                        }
                        let isPresent = lookup_info.some(f => f.lookup_object_name === lookupObject);
                        if (isPresent) {
                            lookup_info = lookup_info.map(m => m.lookup_object_name === lookupObject ? { ...m, record_id: recordId } : m);
                        } else {
                            lookup_info.push({ record_id: recordId, lookup_object_name: lookupObject });
                        }
                        let tempjobObject = jobObject;
                        tempjobObject.records = records;
                        tempjobObject.lookup_info = lookup_info;
                        tempjobObject.lookupObjValue = { id: tempLookupInfo.record_id, label: tempLookupInfo.title, value: tempLookupInfo.title };
                        setJobObject({...tempjobObject});
                        manageAutoCompleteList();
                    }
                }
            }
        } catch (error) {
            console.error("Error in 'JobManagement.js -> getLookupSelectedRecord()':" + error);
        }
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const handleSelectChange = (event, field) => {
        let { tempRecords = {} } = jobObject;
        let value = event.currentTarget.innerText;
        if (field.name === 'invoice_mode' && value === 'Manually') {
            setdisableInvoiceInterval(true);
        } else {
            setdisableInvoiceInterval(false);
        }
        try {
            tempRecords[field.name] = value;
            let temp = jobObject;
            temp.records.invoice_mode = value;
            setJobObject({...temp});
            setChangeData({ field: field, value: value });
        } catch (error) {
            console.error("Error in 'assign.js -> handleSelectChange()':" + error);
        }
    }

    const onRecordFieldChange = (data) => { 
        let isUpdate = true;
        let isfetch = false;
        let { records = {} } = jobObject;
        let field = jobObject.fields[0]
        try {
            let value = null;

            if (field.field_type === 'list') {
                value = data.value;
            } else if (field.field_type === 'date' || field.field_type === 'datetime') {
                let dateValue = new Date(data[0]);
                let selectedDate = new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate(), dateValue.getHours(), dateValue.getMinutes(), dateValue.getSeconds());
                let momentDate = moment(selectedDate);
                value = momentDate.format(constants.INPUT_DATE_TIME_FORMAT);
            } else if (field.is_lookup_field) {
                if (field.field_type === 'text' && getObjectParam(data).value !== undefined) {
                    value = data.value;
                    isfetch = records[field.name] !== value ? true : false;
                }

            } else {
                value = data.value;
            }
            if (value !== '') {
                if (field.is_phone_field) {
                    let regExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
                    isUpdate = regExp.test(value);
                }
            }
            if (isUpdate) {
                records[field.name] = value;
                if (field.name === 'account') {
                    records['job_name'] = "Job for " + value || '';
                }
                let tempJobObject = jobObject;
                tempJobObject.records = records;
                if (field.is_lookup_field) {
                    tempJobObject.lookupObjValue = data;
                }
                setJobObject({...tempJobObject});
                if (field.is_lookup_field && isfetch) {
                    if (field.lookup_object === constants.CONTACTS_OBJECT && data.data) {
                        setDepFields(data.value, constants.CONTACTS_OBJECT, true);
                    } else {
                        setLookupFields(data.id, field.lookup_object,tempJobObject);
                    }
                }
                setChangeData({ field: field, value: value });
            }
        } catch (error) {
            console.error("Error in 'JobManagement.js -> onRecordFieldChange()':" + error);
        }
    }

    const handleFlatPickrDateChange = (date, type) => {
        try {
            let dateValue = new Date(date);
            let selectedDate = new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate());
            let momentDate = moment(dateValue);
            let value = momentDate.format(dateFormat[appState.me.date_format]);
            let tempjobObject = jobObject;
            if (type === 'start_date') {
                tempjobObject.records['start_date'] = value;
            } else if (type === 'end_date') {
                tempjobObject.records['end_date'] = value;
            }
            setJobObject({...tempjobObject});
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> QuoteEdit -> handleFlatPickrDateChange()':" + error);
        }
    }

    const focusElementByName = (field = {}) => {
        let inputElement = null;
        try {
            let fieldName = field.name;
            if (field && isValidParam('field-ref-' + fieldName)) {
                inputElement = 'field-ref-' + fieldName;
                if (field.field_type === constants.FIELD_TYPE_DATE || field.field_type === constants.FIELD_TYPE_DATE_TIME) {
                    inputElement = inputElement.node;
                }
                if (isValidParam(inputElement)) {
                    inputElement.focus();
                }
            }
        } catch (error) {
            console.error("Error in 'JobManagement.js -> focusElementByName()':" + error);
        }
    }

    const getOptions = (value) => {
        try {
            if (!value || (value && value.length < 3)) {
                return Promise.resolve(autoCompleteObj['accounts']);
            } else {
                let params = {};
                params.field_name = getStringParam("company");
                params.object = getStringParam('accounts').toLowerCase();
                params.search_text = value;
                params.query_name = 'All records';
                params.query_type = 'all';

                return getLookupAutocompleteInfo(params)
                    .then((response) => {
                        let options = getArrayParam(response);
                        sortArrayObjectByProperty(options, 'name');
                        let tempArr = options.map(m => { return { label: m.name, value: m.name, id: m.id }; })                        
                        return tempArr;
                    });
            }
        } catch (error) {
            console.error("Error in 'JobManagement.js -> getOptions()':" + error);
        }
    }

    const getCustomDateComponents = () => {
        let elements = [];
        let { fields = [], records = {} } = jobObject;
        let HHFormat = new RegExp("HH");
        try {
            let fieldsPosList = ['start_date', 'end_date'];
            fields = getArrayParam(fieldsPosList).map(m => fields.find(f => f.name === m));
            let customElements = fields.map((field, index) => {
                let errorStyle = error[field.name] ? { border: '1px solid #f00' } : {};
                let fieldComponent = [];
                if (field) {
                    let fieldValue = records[field.name] ? records[field.name] : "";
                    let dateObj = fieldValue ? moment(fieldValue) : moment(new Date());
                    dateObj = dateObj.format(dateFormat[appState.me.date_format]);
                    let dateOptions = {
                        enableTime: field.field_type === constants.FIELD_TYPE_DATE_TIME ? true : false,
                        dateFormat: dateFormatFlatPicker[appState.me.date_format],
                        minuteIncrement: 1,
                        time_24hr: HHFormat.test(appState.me.date_format) ? true : false
                    };
                    fieldComponent =
                        <div className={'customLabelDiv-' + field.name} >
                        <PopOver
                            key={`flatpicker-${field.name}`}
                            name={field.name}
                            btnType={'date'}
                            buttonStyle={{ fontSize: '16px' }}
                            containerStyle={{ lineHeight: 1 }}
                            buttonLabel={field.label}
                            onChange={(date,type)=>handleFlatPickrDateChange(date,type)}
                            value={dateObj}
                            innerLabelStyle={{ padding: "8 5 2 5", fontSize: '15px' }}
                            height={40}
                            options={dateOptions}
                        />
                        </div>
                }
                return fieldComponent;
            });
            elements = <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '15px' }}>
                {customElements}
            </div>
        } catch (error) {
            console.error("Error in 'JobManagement.js -> getCustomDateComponents()':" + error);
        }
        return elements;
    }

    const handleSelectFieldBlurAndFocus = (name, type, event) => {
        let { records = {} } = jobObject;
        try {
            if (!records[name]) {
                let ele = document.getElementsByClassName("customLabelDiv-" + name);
                if (isValidParam(ele) && ele.length > 0) {
                    if (type === 'focus') {
                        ele[0].classList.add('label-top');
                    } else if (type === 'blur') {
                        ele[0].classList.remove('label-top');
                    }
                }
            }
        } catch (error) {
            console.error("Error in 'JobManagement.js -> handleSelectFieldBlurAndFocus()':" + error);
        }
    }

    const createRecordDetailsForms = (col_span) => {
        let elements = [];
        let { fields = [], fieldsPosList = [], records = {} } = jobObject;

        try {
            if (object === constants.JOBS_OBJECT) {
                fields = fields.filter(f => f.name !== 'end_date' && f.name !== 'job_id');
            }
            fieldsPosList = fieldsPosList.find(f => f.col_span === col_span);

            if (fieldsPosList) {
                fields = getArrayParam(fieldsPosList.list).map(m => fields.find(f => f.name === m));
                fields = fields.filter(f => f !== undefined)
                elements = fields.map((field, index) => {
                    if (field) {
                        let fieldLabel = field.is_required_field ? field.label + getLocalizedStrings().label.COMMON.REQUIRED : field.label;
                        let errorStyle = error[field.name] ? { border: '1px solid #f00' } : {};

                        let fieldValue = records[field.name] ? records[field.name] : "";
                        fieldValue = field.is_phone_field ? phoneFormat(fieldValue) : fieldValue;
                        let fieldComponent = [];
                        if (field.field_type === constants.FIELD_TYPE_LIST || field.is_lookup_field) {
                            let listOptions = getArrayParam(field.list_values);
                            listOptions = listOptions.filter(f => f.value).map(m => { return { value: m.value, label: m.value } });

                            if (field.is_lookup_field) {
                                listOptions = getArrayParam(autoCompleteObj[field.name]);
                            }
                         
                            if (field.is_lookup_field && field.lookup_object !== constants.CONTACTS_OBJECT) {
                                fieldComponent =
                                    <AsyncSelect
                                        key={`select-field-${field.name}`}
                                        value={jobObject.lookupObjValue}
                                        title={fieldValue}
                                        className={'lookup-select-zone select-floating-label select-async-job svg'}
                                        placeholder={fieldLabel}
                                        autoload={false}
                                        cacheOptions={false}
                                        style={{ borderRadius: '0px', height: '45px', ...errorStyle, input: { top: '10px' } }}
                                        loadOptions={getOptions}
                                        clearable={false}
                                        options={listOptions}
                                        getOptionLabel={e => e.label}
                                        getOptionValue={e => e.value}
                                        onChange={onRecordFieldChange}
                                        ref={inputRef}
                                        onFocus={(event)=>handleSelectFieldBlurAndFocus(field.name, 'focus',event)}
                                        onBlur={(event)=>handleSelectFieldBlurAndFocus(field.name, 'blur',event)}
                                        styles={{ menu: base => ({ ...base, zIndex: 999 }) }}
                                        components={{
                                            IndicatorSeparator: () => null
                                          }}
                                    />
                            } else {
                                let customStyle = {}
                                if (col_span === 3) {
                                    customStyle.width = '48.5%'
                                }
                                let defaultSelectValue = {}
                                if (field.field_type === 'list') {
                                    defaultSelectValue = {
                                        label: fieldValue,
                                        value: fieldValue
                                    }
                                }

                                // if (field.name === 'invoice_mode' && fieldValue === 'Manually') {
                                //     setdisableInvoiceInterval(true);
                                // }
                                fieldComponent =
                                    <div style={{customStyle , marginTop:field.name === 't_status'? "" :'4px' }}>
                                        {field.name !== 'invoice_interval' && <Autocomplete
                                            disabled={disableInvoiceInterval && (field.name === 'invoice_interval')}
                                            id={`select-field-${field.name}`}
                                            name={`select-field-${field.name}`}
                                            key={`select-field-${field.name}`}
                                            className={'sf-atc-autoComplete'}
                                            defaultValue={defaultSelectValue}
                                            options={listOptions}
                                            onChange={(e) => handleSelectChange(e, field)}
                                            getOptionLabel={(listOptions) => listOptions.label}
                                            disableClearable={false}
                                            renderInput={(params) => <TextField {...params} label={fieldLabel}
                                            variant="outlined" margin="dense"
                                            size='small' />
                                          }
                                        />
                                        }
                                    </div>
                            }
                        } else if (field.field_type === constants.FIELD_TYPE_MEMO) {
                            
                            fieldComponent =
                                <FormControl style={{ width: '100%',paddingTop: '4px' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        id={`record-memo-field-${field.name}`}
                                        key={`field-${field.name}-key`}
                                        type='textarea'
                                        multiline={true}
                                        rows={3}
                                        maxRows={3}
                                        fullWidth={true}
                                        inputprops={{
                                            maxLength: field.name === 'address_note' ? 1024 : 256
                                        }}
                                        value={fieldValue}
                                        title={fieldValue}
                                        onChange={(e)=>{
                                            let tempRecord = {...records, [field.name]: e.target.value}
                                            setJobObject({...jobObject, records: tempRecord})
                                        }}
                                        autoComplete="new-password"
                                        label={fieldLabel}
                                        InputLabelProps={{
                                            style: {
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                width: '96%',
                                            }
                                        }}
                                        className={"sf-fields-bg"}
                                    />
                                </FormControl>
                        } else {
                            fieldComponent =
                                <FormControl style={{ width: '100%', }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        id={`record-field-${field.name}`}
                                        key={`field-${field.name}-key`}
                                        value={fieldValue}
                                        title={fieldValue}
                                        onChange={(e)=>{
                                            let tempRecord = {...records, [field.name]: e.target.value}
                                            setJobObject({...jobObject, records: tempRecord})
                                        }}
                                        disabled={field.is_readonly}
                                        autoComplete="new-password"
                                        label={fieldLabel}
                                        InputLabelProps={{
                                            style: {
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                width: '96%',
                                            }
                                        }}
                                        style={{margin: 'inherit', marginTop: '4px'}}
                                        margin='dense'
                                        size='small'
                                        className={"sf-fields-bg"}
                                    />
                                </FormControl>
                        }
                        if ((field.field_type === constants.FIELD_TYPE_DATE || field.field_type === constants.FIELD_TYPE_DATE_TIME) && object === constants.JOBS_OBJECT && field.name !== 'start_date') {
                            fieldComponent =
                                <div className={'customLabelDiv-' + field.name} >
                                    <label className="date-label job-custom-label" >{fieldLabel}</label>
                                    {fieldComponent}
                                </div>;
                        }

                        if (field.is_lookup_field) {
                            fieldComponent = <div id={field.name} key={field.name} style={{ display: 'grid', gridTemplateColumns: '100% auto' }}>
                                {fieldComponent}
                                <span className="search-field-icon fa fa-search" style={{ position: 'relative', marginLeft: '-4rem', marginTop: '11px' }} onClick={()=>openLookup(field)}></span>
                            </div>;
                        }
                        let component =
                            <div style={{ display: 'grid', gridTemplateColumns: '100% auto' }}>
                                <div >
                                    <div key={`record-${field.name}-${index}`} style={{ padding: '5px' }}>
                                        {fieldComponent}
                                    </div>
                                </div>
                            </div >

                        return component;
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'JobManagement.js -> createAccountsForm()':" + error);
        }
        return elements;
    }

    const createForms = () => {
        try {
            return (
                <div>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2,1fr)', gridColumnGap: '30px' }}>
                        <div>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                <div style={styles.formHeaderLabel}>{'Job Address'}</div>
                            </div>
                            {createRecordDetailsForms(1)}
                        </div>
                        <div style={{marginTop: '4px'}}>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr' , paddingLeft: '3px'}}>
                                <div style={{fontSize: '24px', color: '#555', paddingTop: '2px'}}>{'Job Details'}</div>
                            </div>
                            {createRecordDetailsForms(2)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div style={{ ...styles.formHeaderLabel, padding: '10px' }} >{'Job Items'}</div>
                           {generateProductItems()}
                        </div>
                    </div>
                </div>
            )
        } catch (error) {
            console.error("Error in 'JobManagement.js -> createForms()':" + error);
        }
    }

    let drHeight = window.innerHeight - 150;

        if (mounted) {
            return (
                <div style={{ display: 'grid', gridTemplateColumns: 'minmax(1000px,1fr)', overflowY: 'auto' , height: drHeight }}>
                    <div>
                        <div style={{ height: '5px', paddingTop: '5px' }}>
                            {isLoading &&
                                <div style={{ textAlign: 'center' }}>
                                    <span className="loader"><span className="loader-box"></span><span className="loader-box"></span><span className="loader-box"></span></span>
                                </div>}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 4%' }}>
                            {generateHeaderbuttons()}
                        </div>
                        <div style={{ padding: '0px 4%' }}>
                            {createForms()}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 4%' }}>
                             {generateHeaderbuttons()}
                        </div>
                    </div >
                </div>
            );
        } else {
            let contentHeight = window.innerHeight - 240;
            let top = (contentHeight - 10) / 2
            return (
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            );
        }
}

export default JobManagement;



var getDepFieldsList = (lookupObject) => {
    let list = null;
    try {
        if (lookupObject === constants.ACCOUNTS_OBJECT) {
            list = ['account', 'phone', 'mobile', 'email', 'address'];
        } else if (lookupObject === constants.CONTACTS_OBJECT) {
            list = ['contact', 'phone', 'mobile', 'email', 'address'];
        }
    } catch (error) {
        console.error("Error in 'JobManagement.js -> getDepFieldsList()':" + error);
    }
    return list;
}

var mapFields = [
    { name: 'account', mapFieldsList: ['company'] },
    { name: 'contact', mapFieldsList: ['full_name'] },
    { name: 'phone', mapFieldsList: ['phone'] },
    { name: 'mobile', mapFieldsList: ['mobile'] },
    { name: 'email', mapFieldsList: ['email'] },
    { name: 'address', mapFieldsList: ["address1", "address2", "city", "state", "zip_code", "country"] }
];

