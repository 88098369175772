import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';
import { constants } from '..//constants/constants';
import { actions } from '..//constants/actions';
import store from '../../../src/services/store';
import {  isValidParam, getObjectParam} from '..//helper/parameterVerifier';


function getSearchedContactList(params) {
    let url = endPoints.SMS_INTEGRATION.GET_CONTACT_LIST;
    try {
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'smsAction.js -> getSearchedContactList()':" + e); }
}

function getAvailablePhoneNumbers(params) {
    let url = endPoints.SMS_INTEGRATION.GET_AVAILABLE_NUMBERS;
    try {
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'smsAction.js -> getAvailablePhoneNumbers()':" + e); }
}
function purchasePhoneNumber(params) {
    let url = endPoints.SMS_INTEGRATION.PURCHASE_PHONE_NUMBER;
    try {
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'smsAction.js -> purchasePhoneNumber()':" + e); }
}

function removePhoneNumber(params) {
    let url = endPoints.SMS_INTEGRATION.REMOVE_PHONE_NUMBER;
    try {
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'smsAction.js -> removePhoneNumber()':" + e); }
}
function getAllocatePhoneNumbers(params) {
    //let tempParams = {};
    //tempParams.call_from = '';
    //params = isValidParam(params)?getObjectParam(params):getObjectParam(params);
    let url = endPoints.SMS_INTEGRATION.GET_ALLOCATED_PHONE_NUMBERS;
    try {
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'smsAction.js -> getAllocatePhoneNumbers()':" + e); }
}
function getAllToNumbers(params) {
    let url = endPoints.SMS_INTEGRATION.GET_TO_NUMBERS;
    try {
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'smsAction.js -> getAllToNumbers()':" + e); }
}
function getSMS(params) {
    let url = endPoints.SMS_INTEGRATION.GET_SMS;
    try {
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'smsAction.js -> getSMS()':" + e); }
}
function smsReadcountUpdate(params){
    let url = endPoints.SMS_INTEGRATION.UPDATE_READ_COUNT;
    try {
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'smsAction.js -> getSMS()':" + e); }
}
function getRemainingSMSCount() {
    let url = endPoints.SMS_INTEGRATION.REMAINING_SMS_COUNT_GET;
    try {
        return HTTPClient.get(url, null);
    } catch (e) { console.error("Error in 'smsAction.js -> getRemainingSMSCount()':" + e); }
}

function sendSMS(params) {
    let url = endPoints.SMS_INTEGRATION.SEND_SMS;
    try {
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'smsAction.js -> sendSMS()':" + e); }
}

function tollFreeVerification(params) {
    let url = endPoints.SMS_INTEGRATION.TOLL_FREE_VERIFICATION;
    try {
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'smsAction.js -> tollFreeVerification()':" + e); }
}

function getMergeField(object, params) {
    let url = null;
    if (object === constants.ACCOUNTS_OBJECT) {
         url = endPoints.ACCOUNTS.GET_MERGE_FIELDS;
    } else if (object === constants.CONTACTS_OBJECT) {
         url = endPoints.CONTACTS.GET_MERGE_FIELDS;
    }
    try {
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'smsAction.js -> getMergeField()':" + e); }
}
function updateRedirectNumber(params){
    let url = endPoints.SMS_INTEGRATION.UPDATE_REDIRECT_NUMBER;
    try {
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'smsAction.js -> updateRedirectNumber()':" + e); }
}

function checkPhoneNumberStatus(params) {
    let url = endPoints.SMS_INTEGRATION.CHECK_PHONE_NUMBER_STATUS;
    try {
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'smsAction.js -> checkPhoneNumberStatus()':" + e); }
}

function updateSMSData(data){
    try {
        store.dispatch({type: actions.UPDATE_SMS_DATA, payload:{data:data}});
    }
     catch (e) { console.error("Error in 'smsAction.js -> updateSMSData':" + e); }
   
}
function updateSelectedContact(data){
    try {
        store.dispatch({type: actions.UPDATE_SELECTED_CONTACT, payload:{data:data}});
    }
     catch (e) { console.error("Error in 'smsAction.js -> updateSelectedContact':" + e); }
   
}
function updateSelectedToNumber(data){
    try {
        store.dispatch({type: actions.UPDATE_SELECTED_TONUMBER, payload:data});
    }
     catch (e) { console.error("Error in 'smsAction.js -> updateSelectedToNumber':" + e); }
   
}
function updateFromNoScrollPosition(data){
    try {
        store.dispatch({type: actions.UPDATE_SCROLL_POSITION_FROM_NUMBER, payload:data});
    }
     catch (e) { console.error("Error in 'smsAction.js -> updateSelectedToNumber':" + e); }
   
}
function updateToNoScrollPosition(data){
    try {
        store.dispatch({type: actions.UPDATE_SCROLL_POSITION_TO_NUMBER, payload:data});
    }
     catch (e) { console.error("Error in 'smsAction.js -> updateSelectedToNumber':" + e); }
   
}
function updateSelectedFromNumber(data){
    try {
        store.dispatch({type: actions.UPDATE_SELECTED_FROM_NUMBER, payload:data});
    }
     catch (e) { console.error("Error in 'smsAction.js -> updateSelectedToNumber':" + e); }
   
}
function clearSelectedContact(data){
    try {
        store.dispatch({type: actions.CLEAR_SELECTED_CONTACT, payload:data});
    }
     catch (e) { console.error("Error in 'smsAction.js -> clearSelectedContact':" + e); }
   
}
function updateNewSMSData(data){
    try {
        store.dispatch({type: actions.UPDATE_NEW_SMS, payload:{data:data}});
    }
     catch (e) { console.error("Error in 'smsAction.js -> updateNewSMSData':" + e); }
   
}
function refreshSmsInbox(isRefresh){
    try {
        store.dispatch({type: actions.GET_SMS_REFRESH, payload:isRefresh});
    }
     catch (e) { console.error("Error in 'smsAction.js -> updateNewSMSData':" + e); }
   
}
function getImportTwilioPhoneNumbers() {
    let url = endPoints.SMS_INTEGRATION.GET_IMPORT_TWILIO_NUMBERS;
    try {
        return HTTPClient.get(url, null);
    } catch (e) { console.error("Error in 'smsAction.js -> getImportTwilioPhoneNumbers()':" + e); }
}
function saveImportTwilioPhoneNumbers(params) {
    let url = endPoints.SMS_INTEGRATION.SAVE_IMPORT_TWILIO_NUMBERS;
    try {
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'smsAction.js -> saveImportTwilioPhoneNumbers()':" + e); }
}
export {
    getAvailablePhoneNumbers,
    purchasePhoneNumber,
    getSearchedContactList,
    removePhoneNumber,
    getAllocatePhoneNumbers,
    getAllToNumbers,
    getSMS,
    smsReadcountUpdate,
    getRemainingSMSCount,
    sendSMS,
    getMergeField,
    updateRedirectNumber,
    checkPhoneNumberStatus,
    updateSMSData,
    updateSelectedContact,
    updateSelectedToNumber,
    updateSelectedFromNumber,
    clearSelectedContact,
    updateFromNoScrollPosition,
    updateToNoScrollPosition,
    updateNewSMSData,
    refreshSmsInbox,
    tollFreeVerification,
    getImportTwilioPhoneNumbers,
    saveImportTwilioPhoneNumbers
}