import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const ShowCircularProgress = (props) => {
  let size = props.size !== null || props.size !== undefined ? props.size : 45;
  let style = props.style !== null || props.style !== undefined ? props.style : { marginTop: '26px', width: '100%' };
  return (
    <div style={style}>
      <CircularProgress size={size} thickness={4} color='inherit' />
    </div>)
};

export default ShowCircularProgress;