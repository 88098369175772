import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';

function getWorkflowList(object) {
    let url = endPoints.WORKFLOWS.LIST_GET + "/" + object;
    try {
        return HTTPClient.get(url, null);
    } catch (e) { console.error("Error in 'importAction.js -> getWorkflowList()':" + e); }
}

function getWorkflowStageById(params) {
    let url = endPoints.WORKFLOWS.LIST_STAGES_GET;
    try {
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'importAction.js -> getWorkflowStageById()':" + e); }
}

function getSourceList() {
    let url = endPoints.SOURCE.LIST_GET;
    let params = {
        fields : ["t_source_name"]
    }
    try {
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'importAction.js -> getSourceList()':" + e); }
}
function getLocationList() {
    let url = endPoints.LOCATION.LIST_GET;
    let params = {
        fields : ["t_location"]
    }
    try {
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'importAction.js -> getLocationList()':" + e); }
}
function getAvailableLocationList() {
    let url = endPoints.LOCATION.GET_AVAILABEL_LOCATIONS;
    try {
        return HTTPClient.get(url, null);
    } catch (e) { console.error("Error in 'importAction.js -> getLocationList()':" + e); }
}
function getImportFormInfo(params) {
    let endPoint = endPoints.IMPORT.SELECT_FILE;
    var response = Promise.resolve(HTTPClient.postFormData(endPoint, params));
    return response;
}

function saveImportRecords(params) {
    let endPoint = endPoints.IMPORT.IMPORT_RECORDS;
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}

function getWorkflowStagesTask(params) {
    let endPoint = endPoints.WORKFLOWS.LIST_TASKS_GET;
    var response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

function getFieldSizeByName(params) {
    let endPoint = endPoints.COMMON.FIELD_SIZE_GET;
    var response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

function sendMailForImportedRecords(params) {
    let endPoint = endPoints.IMPORT.SEND_MAIL;
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}

function getSavedMapping(params) {
    let endPoint = endPoints.IMPORT.GET_SAVE_MAPPING;
    var response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

function saveMapping(params) {
    let endPoint = endPoints.IMPORT.SAVE_MAPPING;
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}

function getPreviousMappingFields(params) {
    let endPoint = endPoints.IMPORT.USE_SAVE_MAPPING;
    var response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

function deleteMapping(params) {
    let endPoint = endPoints.IMPORT.DELETE_MAPPING;
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}

function importGreenFieldData(params){
    let endPoint = endPoints.IMPORT.IMPORT_GREEN_FIELD;
    var response = Promise.resolve(HTTPClient.postFormData(endPoint, params));
    return response;
}

function getStatusOfLastImport(params){
    let endPoint = endPoints.IMPORT.IMPORT_GREEN_FIELD_STATUS;
    var response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

export {
    getWorkflowList,
    getWorkflowStageById,
    getSourceList,
    getImportFormInfo,
    saveImportRecords,
    getWorkflowStagesTask,
    getFieldSizeByName,
    sendMailForImportedRecords,
    getSavedMapping,
    saveMapping,
    getLocationList,
    getPreviousMappingFields,
    deleteMapping,
    importGreenFieldData,
    getStatusOfLastImport,
    getAvailableLocationList
}
