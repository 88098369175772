import { actions } from '..//constants/actions';
let convertInitialState = {
    convertProps: {
        convert: null,
    },
    batchConvertProps: {
        batchConvert: null,
    }
}


const convertReducer = (state = convertInitialState, action) => {
    switch (action.type) {
        case actions.UPDATE_CONVERT_DATA:
            return {
                ...state,
                convertProps: {
                    convert: action.payload.convert,
                },
            }
        case actions.UPDATE_CONVERT_DATA_BY_KEY:
            return {
                ...state,
                convertProps: {
                    convert: { ...state.convertProps.convert.action, [action.payload.key]: action.payload.data },
                },
            }
        case actions.UPDATE_BATCH_CONVERT_DATA:
            return {
                ...state,
                batchConvertProps: {
                    batchConvert: action.payload.batchConvert,
                }
            }
        default:
            return state;

    }
}
export default convertReducer;