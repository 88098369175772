
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { styles } from '../../../services/constants/styles';
import Button from '@mui/material/Button';
import { constants } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getActiveTab } from '../../../services/helper/sfTabManager';
import { isValidParam, getObjectParam, getBooleanParam, getStringParam } from '../../../services/helper/parameterVerifier';
import { setHeader } from '../../../services/actions/headerActions';
import { getObjectLabelByObject } from '../../../services/helper/common';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

 const SalesPage =({object})=> {
    const dispatch = useDispatch();
    const appState = useSelector((state) => state.app);

    useEffect(() => {
        if (object) {
            setHeaderData(object);
        }
	}, [object]);

    const setHeaderData = (object) => {
        let activeTab = getActiveTab();
        let tempObject = getStringParam(activeTab.object);
        let label = getObjectLabelByObject(object);
        dispatch(setHeader(tempObject, label, null, false, true));
    }

    const openOrderItemTab = () => {
        let redirectUrl = constants.snapshotOrderItemsUrl;
        let tId = appState.me.projectId;
        redirectUrl += '?td=' + tId;
        window.open(redirectUrl);
    }

        let tempObject = getStringParam(object);
        let activeTab = getActiveTab();
        let imageName = getStringParam(activeTab.imgName);
        let activeTabInfo = getObjectParam(activeTab.info);
        let hasPermission = getBooleanParam(activeTabInfo.hasPermission);
        let hasConfigured = getBooleanParam(activeTabInfo.hasConfigured);
        let isTenant = isValidParam(appState) && isValidParam(appState.me) ? getBooleanParam(appState.me.is_tenant) : false;
        let industryType = isValidParam(appState) && isValidParam(appState.me) ? getStringParam(appState.me.industry_type) : null;
        let isBAI_Industry = false;
        if (industryType !== null && industryType === "BAI") {
            isBAI_Industry = true;
        }
        let productNameLabel = getObjectLabelByObject(tempObject);

        let customDiv = null;
        if (!hasPermission) {
            if (isTenant) {
                if (isBAI_Industry) {
                    customDiv = "";
                } else {
                    customDiv = <Button 
                        labelstyle={{ textTransform: 'none', fontSize: '29px', fontWeight: 'bold' }}
                        style={{ ...styles.primaryButton, height: '75px', minWidth: '265px', borderRadius: '20px' }}
                        onClick={()=>openOrderItemTab()} >{getLocalizedStrings().label.OTHER_VIEW.SUBSCRIBE_LABEL}</Button>;
                }
            } else {
                let msg = getLocalizedStrings().message.OTHER_VIEW.USER_MESSAGE;
                msg = msg.replace('$$@@##PRODUCT_NAME##@@$$', productNameLabel.toLowerCase());
                customDiv = <label style={{ color: '#777', fontSize: '20px' }}>{msg}</label>
            }
        } else if (hasPermission && !hasConfigured) {
            let msg = getLocalizedStrings().message.OTHER_VIEW.AFTER_SUBSCRIPTION_VENDASTA_NOT_CONFIGURED;
            msg = msg.replace('$$@@##PRODUCT_NAME##@@$$', productNameLabel);
            customDiv = <label style={{ color: '#777', fontSize: '20px' }}>{msg}</label>;
        }

        if (!hasPermission && customDiv !== null) {
            let msg = getLocalizedStrings().message.OTHER_VIEW.TENANT_MESSAGE;
            msg = msg.replace('$$@@##PRODUCT_NAME##@@$$', productNameLabel);
            customDiv = [
                <div style={{ textAlign: 'center', padding: '15px' }}>
                    <label style={{ color: '#777', fontSize: '25px' }}>{msg}</label>
                </div>,
                <div style={{ textAlign: 'center', padding: '15px' }}>{customDiv}</div>
            ]
        } else if (customDiv !== null) {
            customDiv = <div style={{ textAlign: 'center', padding: '15px' }}>{customDiv}</div>;
        }

        return (
            <div className="sf-display-grid">
                <div style={{ textAlign: 'center', padding: '15px' }}>
                    <i className={imageName} aria-hidden="true" style={{ fontSize: '160px', color: '#939393' }} ></i><br />
                </div>
                {customDiv}
            </div>
        );


}

export default SalesPage;

