import React, { useState, useEffect } from 'react';
import { getFilters } from '../../../services/actions/filterMenuAction';
import { Menu, MenuItem, Icon } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { isValidParam, getStringParam, getBooleanParam, getIntParam, getObjectParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { getActiveTab, updateActiveTabProps, getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { saveRecentFilter } from '../../../services/helper/common';
import { refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';
import { constants } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { setKanbanMounted } from '../../../services/actions/listsActions';
import { setListViewMounted, refreshListView, removeScrollPosition } from '../../../services/actions/listViewAcions';
import { setTimelineViewMounted } from '../../../services/actions/timelineViewActions';
import { removeColumnViewScrollPosition, removeColumnViewHorizontalScrollPosition } from '../../../services/actions/listsActions';
import { useDispatch, useSelector } from "react-redux";

/**
 * Author   : Pradyut.s
 * Date:    : 2017-12-29
 */

const getListViewInstanceFromRedux = (state, props) => {
    let tabInfo = getActiveTabInfo();
    let isReport = getBooleanParam(tabInfo.isReport);
    if (getBooleanParam(props.isDetailView)) {
        return state.childListView;
    } else if (getBooleanParam(isReport)) {
        return state.otherListView;
    } else {
        return state.listView;
    }
}

const ListViewFilterDropDown = ({ props, object, queryName, isDetailView }) => {
   const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const filters = useSelector((state) => state.filters);
    const listView = useSelector((state) => getBooleanParam(isDetailView) ? getListViewInstanceFromRedux(state, props) : null);
    const reduxListView = useSelector((state) => state.listView);
    const [mounted, setMounted] = useState(false);
    const [queries, setQueries] = useState(null);
    const [stateQueryName, setStateQueryName] = useState('');
    const [openPopOverList, setOpenPopOverList] = useState(false);
    const [anchorEl, setAnchorEl] = useState('');
    const [defaultMenuListMultiLingual] = useState({
        'Accounts': getLocalizedStrings().label.HEADER.ACCOUNTS,
        'Accounts without Groups': getLocalizedStrings().label.HEADER.ACCOUNTS_WITHOUT_GROUP,
        'My Accounts': getLocalizedStrings().label.HEADER.MY_ACCOUNT,
        'Todays Accounts': getLocalizedStrings().label.HEADER.TODAYS_ACCCOUNTS,
        'Accounts without Contact': getLocalizedStrings().label.FILTER['Accounts without Contact'],
        'Accounts without Contacts': getLocalizedStrings().label.FILTER['Accounts without Contacts'],

        'Contacts': getLocalizedStrings().label.HEADER.CONTACTS,
        'Contacts without Account': getLocalizedStrings().label.HEADER.CONTACTS_WITHOUT_ACCOUNT,
        'Contacts without Accounts': getLocalizedStrings().label.HEADER.CONTACTS_WITHOUT_ACCOUNTS,
        'Contacts without Groups': getLocalizedStrings().label.HEADER.CONTACTS_WITHOUT_GROUPS,
        'My Contacts': getLocalizedStrings().label.HEADER.MY_CONTACTS,

        'All Appointments': getLocalizedStrings().label.HEADER.ALL_APPOINMENTS,
        'My Appointments': getLocalizedStrings().label.HEADER.MY_APPOINMENTS,
        'My Completed Appointments': getLocalizedStrings().label.HEADER.MY_COMPLETED_APPOINMENTS,
        'My Missed Appointments': getLocalizedStrings().label.HEADER.MY_MISSED_APPOINMENTS,
        'Todays Appointments': getLocalizedStrings().label.HEADER.TODAYS_APPOINMENTS,

        'All Tasks': getLocalizedStrings().label.HEADER.ALL_TASKS,
        'My Completed Tasks': getLocalizedStrings().label.HEADER.MY_COMPLETED_TASKS,
        'My Future Tasks': getLocalizedStrings().label.HEADER.MY_FUTURE_TASKS,
        'My Pending Tasks': getLocalizedStrings().label.HEADER.MY_PENDING_TASKS,
        'My Todays Tasks': getLocalizedStrings().label.HEADER.MY_TODAYS_TASKS,

        'All records': getLocalizedStrings().label.FILTER['All records'],

        'Added this Month': getLocalizedStrings().label.FILTER['Added this Month'],
        'Added this Week': getLocalizedStrings().label.FILTER['Added this Week'],
        'My Opportunities': getLocalizedStrings().label.FILTER['My Opportunities'],
        'Opportunities': getLocalizedStrings().label.FILTER['Opportunities'],

        'Cases': getLocalizedStrings().label.FILTER['Cases'],
        'Projects': getLocalizedStrings().label.FILTER['Projects'],
        'Issues': getLocalizedStrings().label.FILTER['Issues'],
    });

    useEffect(() => {
        try {
            fnGetFilters(object, queryName);
        } catch (error) {
            console.error("Error in 'listViewFilterDropDown.js -> componentDidMount()':" + error);
        }
    }, [object, queryName]);

    /*useEffect(() => {
        // componentWillReceiveProps
        try {
            if (object !== nextProps.object || (this.props.object === nextProps.object && this.props.queryName !== nextProps.queryName)) {
                fnGetFilters(nextProps.object, nextProps.queryName);
            }
        } catch (error) {
            console.error("Error in 'listViewFilterDropDown.js -> UNSAFE_componentWillReceiveProps()':" + error);
        }
    }, []);*/

    const fnGetFilters = (object, queryName) => {
        try {
            let queries = filters.queries[object];
            if (!isValidParam(queries)) {
                let promise = Promise.resolve(getFilters(object));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            filters.queries[object] = response.queries;
                            setMounted(true);
                            setQueries(response.queries);
                            setStateQueryName(queryName);
                        }
                    });
                }
            } else {
                setMounted(true);
                setQueries(queries);
                setStateQueryName(queryName);
            }
        } catch (error) {
            console.error("Error in 'listViewFilterDropDown.js -> fnGetFilters()':" + error);
        }
    }

    const getListViewQueries = (object, queries, queryName) => {
        
        let component = null;
        let themeColor = app.me.background;
        try {
            queries = getArrayParam(queries);
            queries = queries.filter(f => { return f.type !== 'group' });
            queryName = getStringParam(queryName);
               if (app.selectedModuleName === constants.MODULE_HUB && object === constants.CASES_OBJECT && queryName =="Cases") {
                    let listData = getObjectParam(reduxListView?.data);
                    queryName = listData?.query_name ;
                }
            if (queries.length > 0) {
                let defaultMenuItem = queries.filter(f => { return f.type === '' || f.type === 'all' || f.type === 'query' });
                if (object === constants.CASES_OBJECT) {
                    if (app.selectedModuleName === constants.MODULE_HUB) {
                        defaultMenuItem = defaultMenuItem.filter(f => { return f.name !== 'Cases' });
                    } else {
                        defaultMenuItem = defaultMenuItem.filter(f => {
                            return f.name !== "All Open Cases" &&
                                f.name !== "All Closed Cases" && f.name !== "My Open Cases" && f.name !== "My Closed Cases"
                        });
                    }

                }
                sortArrayObjectByProperty(defaultMenuItem, 'name');
                defaultMenuItem = defaultMenuItem.map((query, i) => {
                    let style = { ...styles.popoverMenuItem };
                    let _taskQueryName = queryName
                    if ( _taskQueryName.includes(app.me.name) == true) {
                        _taskQueryName ="All Tasks"
                    }
                    if (_taskQueryName === query.name) {
                        style.color = themeColor;
                        style.fontWeight = 900;
                    }
                   let primaryText = isValidParam(defaultMenuListMultiLingual[query.name]) ? defaultMenuListMultiLingual[query.name] : query.name;
                    return <MenuItem
                        key={query.id}
                        value={query.id}
                        fullwidth={"true"}
                        autowidth={"true"}
                        onClick={() => changeQuery(object, query.id, query.name, query.type, true)} >{<div style={{ ...style, ...{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '192px' }}} title={primaryText}>{primaryText}</div>}</MenuItem>
                });

                let filterMenuItem = queries.filter(f => { return f.type === 'filter' });
                sortArrayObjectByProperty(filterMenuItem, 'name');
                filterMenuItem = filterMenuItem.map((query, i) => {
                    let style = { ...styles.popoverMenuItem };
                    if (queryName === query.name) {
                        style.color = themeColor;
                        style.fontWeight = 900;
                    }
                    if (query.type === 'filter') {
                        return <MenuItem
                            key={query.id}
                            value={query.id}
                            fullwidth={"true"}
                            autowidth={"true"}
                            onClick={() => changeQuery(object, query.id, query.name, query.type, false)} >{<div style={{ ...style, ...{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '192px' } }} title={query.name}>{query.name}</div>}</MenuItem>
                    }
                });

                component = <div><Icon name="popOverIcon" key={"icon"} id={"icon"} style={{ cursor: 'pointer', fontSize: '29px', marginLeft: '5px' }} className='headicon' onClick={(e) => openPopOver(e)} >keyboard_arrow_down</Icon>
                    <Menu
                        name="popOverMenu"
                        key={"popOverMenu"}
                        id={"popOverMenu"}
                        getcontentanchorel={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        style={{ top: '9px', marginLeft: '5px', cursor: 'pointer', fontSize: '36px' }}
                        className='headicon'
                        open={openPopOverList}
                        anchorEl={anchorEl}
                        onClose={() => closePopOver()}
                    >
                        {defaultMenuItem.length > 0 &&
                            <MenuItem
                                key={'default-menu-item'}
                                disabled={true}
                                style={{ ...styles.popoverMenuItem, fontWeight: 900, minWidth: '100px', maxWidth: '150px', color: 'rgb(0 14 0)' }}
                                underlinestyle={{ display: 'none' }}
                                autowidth={"true"} fullwidth={"true"} >{getLocalizedStrings().label.LIST_VIEW.FILTER_TYPE.DEFAULT_FILTERS}</MenuItem>}
                        {defaultMenuItem}
                        {filterMenuItem.length > 0 &&
                            <MenuItem
                            key={'filter-menu-item'}
                                disabled={true}
                                style={{ ...styles.popoverMenuItem, fontWeight: 900, minWidth: '100px', maxWidth: '150px', color: 'rgb(0 14 0)' }}
                                underlinestyle={{ display: 'none' }}
                                autowidth={"true"} fullwidth={"true"} >{getLocalizedStrings().label.LIST_VIEW.FILTER_TYPE.SHARED_FILTERS}</MenuItem>}
                        {filterMenuItem}
                    </Menu></div>;

                if (object === constants.INVOICES_OBJECT) {
                    let css = { float: 'left', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 150, paddingTop: 7, marginRight: -5 };
                    component = <div style={{ float: 'left' }}>
                        <div title={queryName} style={css}>{queryName}</div>
                        {component}
                    </div>;
                }
            }
        } catch (error) {
            console.error("Error in 'listViewFilterDropDown.js -> getListViewQueries()':" + error);
        }
        return component;
    }

    const openPopOver = (event) => {
        event.preventDefault();
        setOpenPopOverList(true);
        setAnchorEl(event.currentTarget);
    }

    const closePopOver = () => {
        setOpenPopOverList(false);
    }

    const changeQuery = (object, id, name, type, isDefaultMenu) => {
        try {
            object = getStringParam(object);
            id = getIntParam(id);
            name = getStringParam(name);
            type = getStringParam(type);

            if (isDetailView && object === constants.INVOICES_OBJECT) {
                /*this.props.listView.data.query_id = id;
                this.props.listView.data.query_name = name;
                this.props.listView.data.query_type = type;*/
                listView.data.query_id = id;
                listView.data.query_name = name;
                listView.data.query_type = type;
                dispatch(refreshDetailViewListViewData(true));
            } else {
                let tab = getActiveTab();
                if (isValidParam(tab)) {
                    let info = getObjectParam(tab.info);
                    let filter = getObjectParam(info.filter);
                    if (filter.id !== id || filter.name !== name) {
                        filter.id = id;
                        filter.name = name;
                        filter.type = type;

                        let props = {};
                        props.label = isDefaultMenu ? (isValidParam(defaultMenuListMultiLingual[name]) ? defaultMenuListMultiLingual[name] : name) : name;
                        props.info = { filter: filter };
                        if (info.hasOwnProperty('isShowGroupBy')) {
                            props.info.isShowGroupBy = getBooleanParam(info.isShowGroupBy);
                        }

                        updateActiveTabProps(props);
                        saveRecentFilter(object, filter);
                        setKanbanMounted(false);
                        setListViewMounted(false, object);
                        refreshListView(object);
                    }
                    setTimelineViewMounted(false);
                    dispatch(removeScrollPosition(object));
                    dispatch(removeColumnViewScrollPosition(object));
                    dispatch(removeColumnViewHorizontalScrollPosition(object));

                }
            }
            setOpenPopOverList(false);
        } catch (error) {
            console.error("Error in 'listViewFilterDropDown.js -> changeQuery()':" + error);
        }
    }


    return mounted ? getListViewQueries(object, queries, stateQueryName) : null;

};

export default ListViewFilterDropDown;

