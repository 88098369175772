import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FroalaEditor from "react-froala-wysiwyg";
import Froalaeditor from 'froala-editor'
import $ from "jquery";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { Popover, MenuItem, InputLabel, Button, Stack } from "@mui/material";
import ShowCircularProgress from "../components/circularProgress";
import * as HTTPClient from "../../../services/helper/httpClient";
import { endPoints } from "../../../services/constants/endPoints";
import PopOver from "../components/PopOver";
import { constants } from "../../../services/constants/constants";
import { styles } from "../../../services/constants/styles";
import store from "../../../services/store";
import { Navigate, json } from "react-router-dom";
import * as sfDialogs from "../components/sfDialogs";
import { getAppDialog } from "../../../services/actions/appContainerActions";
import { getAppDrawer } from "../../../services/actions/appContainerActions";
import { refreshListView } from "../../../services/actions/listViewAcions";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import {
	getIntParam,
	getStringParam,
	getArrayParam,
	getObjectParam,
} from "../../../services/helper/parameterVerifier";
import { hasAccessPermission } from "../../../services/helper/common";
import { storeTemplateListInRedux } from "../../../services/actions/sendMailActions";
import { sortArrayObjectByProperty } from "../../../services/helper/utils";
import Select from "@mui/material/Select";
import { dateFormatFlatPicker } from '../../../services/helper/utils';
import { dateFormat } from "../../../services/helper/utils";
import moment from "moment";
import { isValidParam } from "../../../services/helper/parameterVerifier";
import * as SFFormActions from '../../../services/actions/sfFormActions';
import { updateAgreementTemplateData } from '../../../services/actions/templateAction';

import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/third_party/embedly.min.js";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";

require("froala-editor/js/froala_editor.pkgd.min.js");
require("froala-editor/css/froala_editor.pkgd.min.css");
require("froala-editor/js/plugins/font_family.min.js");


let SignatureList = {
	Signature: "Signature 1",
	Signature2: "Signature 2",
	Signature3: "Signature 3",
	Signature4: "Signature 4",
	Signature5: "Signature 5",
	Signature6: "Signature 6",
	Signature7: "Signature 7",
	Signature8: "Signature 8",
	Signature9: "Signature 9",
	Signature10: "Signature 10"
}
const currentDateList = {
	"date": "Current Date",
	"date+30": "Current Date + 30 days",
	"date+60": "Current Date + 60 days",
	"date+90": "Current Date + 90 days",
}
const stickyStyle = { width: "100%" }
const AgreementTemplate = ({ id, type, object, closeDrawer, tempdata }) => {
	const redirectUrl = false;
	const dispatch = useDispatch();
	const optionvalue = {
		Copy: "Copy",
		Delete: "Delete",
		Preview: "Preview",
	};
	const [template, setTemplate] = useState({
		name: '',
		email_message: "",
		id: id | 0,
		status: "Active",
		agreement_type: '',
	});
	const [mounted, setMounted] = useState(false);
	const [error, setError] = useState({
		name: "",
	});
	const [buttonVisible, setButtonVisible] = useState("inline-block");
	const [redirect, setRedirect] = useState(false);
	const [personalize_fields, setPersonalize_fields] = useState();
	const [changeLog, setchangeLog] = useState({ expirationDate: null, attachedPDF: '', attachedPDFId: null, country: null, state: null, coverLetterId: 0 })
	const [states, setStates] = useState([]);
	const [countryList, setCountryList] = useState([]);
	const [countryMenuItems, setCountryMenuItems] = useState([]);
	const [stateMenuItems, setStateMenuItems] = useState([]);
	const [editor, setEditor] = useState(null);
	const [selectedSigner, setSelectedSigner] = useState('Signature');
	const [selectedmergeFields, setSelectedmergeFields] = useState([]);
	const [isOpenSignatureTag, setIsOpenSignatureTag] = useState(false);
	const [isOpenCurrentDateTag, setIsOpenCurrentDateTag] = useState(false);
	const [saveClicked, setSaveClicked] = useState(false);
	const [coverLetterMenuItems, setCoverLetterMenuItems] = useState([]);
	const [coverLetterList, setCoverLetterList] = useState([]);

	const app = useSelector((state) => state.app);
	const sendMail = useSelector((state) => state.sendMail);
	let agreementRedux = useSelector((state) => state.template);
	let customDrawer = useSelector((state) => state.appContainer.customDrawerProps);

	let froalaRef;

	useEffect(() => {
		document.addEventListener('click', handleDocumentClick);
		return () => {
			document.removeEventListener('click', handleDocumentClick);
		};
	}, []);

	useEffect(() => {
		let minimizeList = customDrawer.minimizeList;
		if ((minimizeList.hasOwnProperty('New') && minimizeList.New) || (minimizeList.hasOwnProperty('edit') && minimizeList.edit) || (minimizeList.hasOwnProperty('Edit') && minimizeList.Edit) || (minimizeList.hasOwnProperty('Copy') && minimizeList.Copy)) {
			let _data = agreementRedux.agreementTemplateData;
			if (_data !== null) {

				if (_data.hasOwnProperty("template")) {
					setTemplate(_data?.template);
				}
				if (_data.hasOwnProperty("changeLog")) {
					setchangeLog(_data?.changeLog);
				}
				if (_data.hasOwnProperty("buttonVisible") && buttonVisible == 'none') {
					setButtonVisible(_data?.buttonVisible);
				}
				if (_data.hasOwnProperty("saveClicked")) {
					setSaveClicked(_data?.saveClicked);
				}
			}
		}
	}, []);

	useEffect(() => {
		let agreementObj = {
			template: template,
			changeLog: changeLog,
			states: states,
			buttonVisible: buttonVisible,
			saveClicked: saveClicked
		}
		dispatch(updateAgreementTemplateData(agreementObj));

	}, [template, changeLog, states, buttonVisible, saveClicked, changeLog.country])


	useEffect(() => {
		let _country = changeLog.country;
		let minimizeList = customDrawer?.minimizeList;
		if ((minimizeList.hasOwnProperty('New') && minimizeList?.New) || (minimizeList.hasOwnProperty('edit') && minimizeList?.edit) || (minimizeList.hasOwnProperty('Edit') && minimizeList.Edit) || (minimizeList.hasOwnProperty('Copy') && minimizeList.Copy)) {
			//bypass
		} else {
			if (changeLog.country === null || changeLog.country === "" || changeLog.country === undefined) {
				_country = app.me.tenant_country;
				let temp = changeLog;
				temp.country = _country;

				setchangeLog({ ...temp });

			}
		}

		setCountryMenuItems(getCountryOptionsList(getArrayParam(app.countryISO2)));

	}, [countryList]);

	useEffect(() => {
		let _data = agreementRedux.agreementTemplateData;
		if (changeLog.country != null && changeLog.country != "") {

			getStateList(changeLog.country);

		}
	}, [changeLog]);

	useEffect(() => {
		if (states != null && states != "") {
			setStateMenuItems(getStateOptionsList(states));

		}
	}, [states]);

	useEffect(() => {
		if (coverLetterList != null && coverLetterList != "") {
			setCoverLetterMenuItems(getCoverLetterOptionsList(coverLetterList));
		}
	}, [coverLetterList]);

	const getStateList = (value) => {
		try {
			let params = {};
			params.object = constants.ACCOUNTS_OBJECT;
			params.name = 'state';
			params.value = value;
			params.list_type = constants.LIST_FIELD_TYPE_EXTERNAL;

			var promise = SFFormActions.getFieldListValuesPromise(params);
			promise.then((response) => {
				if (isValidParam(response)) {
					setStates(getArrayParam(response));
				}
			});
		} catch (error) {
			console.error("Error in 'addBRANCH.js -> getStateList()':" + error);
		}
	}
	const getStateOptionsList = (stateList) => {
		let menuItems = [];
		stateList = getArrayParam(stateList)
		if (stateList.length > 0) {
			stateList.map((s, i) => {
				menuItems.push(<MenuItem value={s.id} id={s.id} title={s.value} key={s.id} style={styles.popoverMenuItem} >{s.value}</MenuItem>);
			});
		}
		return menuItems;
	}
	const getCoverLetterOptionsList = (coverLetterList) => {
		let menuItems = [];
		coverLetterList = getArrayParam(coverLetterList)
		if (coverLetterList.length > 0) {
			coverLetterList.map((s, i) => {
				menuItems.push(<MenuItem value={s.id} id={s.id} title={s.name} key={s.id} style={styles.popoverMenuItem} >{s.name}</MenuItem>);
			});
		}
		return menuItems;
	}
	const handleEditorFocus = () => {
		handlePopUpClose();
	};
	const disableEditorBlurEffect = () => {
		const divElement = document.querySelector('.fr-popup');
		if (divElement) {
			divElement.classList.add('fr-block');
		}
	}
	const handleDocumentClick = (event) => {
		if (popUpClose(event.target)) {
			handlePopUpClose()

		}
	};
	const getCountryOptionsList = (_countryList) => {
		let menuItems = [];
		_countryList = getArrayParam(_countryList);
		if (_countryList !== null && _countryList.length > 0) {
			_countryList.map((fieldOption, i) => {
				menuItems.push(
					<MenuItem value={fieldOption.t_country} id={fieldOption.t_country_iso2} title={fieldOption.t_country} key={fieldOption.t_country_iso2} style={styles.popoverMenuItem} >{fieldOption.t_country} </MenuItem>);
			});
		}
		return menuItems;
	}
	const handlePopUpClose = () => {
		let _store = store.getState()
		const divElement = document.querySelector('.fr-popup');
		if (divElement && !_store.appContainer.dialogProps.isOpenDialog && !_store.topUpDialog.isOpenDialog) {
			divElement.classList.remove('fr-block');
			if (froalaRef && froalaRef?.current) {
				froalaRef.current.events.enableBlur();
			}
		}
	};
	const popUpClose = (element) => {
		let _popUp = false;
		const popUpElemrnt = document.querySelector('.fr-popup');
		const dialogElement = document.querySelector('.MuiDialog-paper');
		const insertElement = document.querySelector('.btn-insert');
		if (!insertElement && !dialogElement && !popUpElemrnt?.contains(element)) {
			_popUp = true;
		} else {
			_popUp = false;
		}
		if (dialogElement && !dialogElement.contains(element)) {
			if (popUpElemrnt && !popUpElemrnt.contains(element)) {
				if (insertElement && !insertElement.contains(element)) {
					_popUp = false;
				}
			}
		}
		return _popUp;
	}

	const loadAgreementTemplateData = (id) => {
		if (id !== null && id !== undefined && id !== 0 && id > 0) {
			var response = Promise.resolve(
				HTTPClient.get(endPoints.AGREEMENT_TEMPLATE.EDIT + "/" + id, null)
			);
			response.then((response) => {
				let tempTemplate = template;
				let tempError = error;
				let _template_details = response.template_details;
				tempTemplate.name = _template_details.name;
				tempTemplate.status = _template_details.status;
				tempTemplate.email_message = _template_details.email_message;
				tempTemplate.id = _template_details.id;
				tempTemplate.agreement_type = _template_details.agreement_type;
				let minimizeList = customDrawer?.minimizeList;
				if ((minimizeList.hasOwnProperty('edit') && minimizeList?.edit) || (minimizeList.hasOwnProperty('Edit') && minimizeList.Edit) || (minimizeList.hasOwnProperty('Copy') && minimizeList?.Copy)) {
					//bypass
				} else {
					setTemplate({ ...tempTemplate });

				}
				setMounted(true);
				if ((minimizeList.hasOwnProperty('New') && minimizeList?.New) || (minimizeList.hasOwnProperty('edit') && minimizeList?.edit) || (minimizeList.hasOwnProperty('Edit') && minimizeList.Edit) || (minimizeList.hasOwnProperty('Copy') && minimizeList.Copy)) {
					//bypass
				} else {
					setButtonVisible("inline-block");
				}

				tempError["name"] = "";
				tempError["status"] = "";
				setError({ ...tempError });
				if (type === "copy") {
					tempTemplate.id = 0;
					tempTemplate.name =
						getLocalizedStrings().label.AGREEMENT_TEMPLATE.COPY_OF +
						"_" +
						template.name;
					let minimizeList = customDrawer?.minimizeList;
					if ((minimizeList.hasOwnProperty('edit') && minimizeList?.edit) || (minimizeList.hasOwnProperty('Edit') && minimizeList.Edit) || (minimizeList.hasOwnProperty('Copy') && minimizeList?.Copy)) {
						//bypass
					} else {
						setTemplate({ ...tempTemplate });

					}
					setButtonVisible("inline-block");
				}

				let personalizeArray = {};
				let i;
				let j;
				let mergeFields = response?.mmFields?.available_mail_merge_fields;
				for (i in mergeFields) {
					for (j in mergeFields[i]) {
						personalizeArray[j] = mergeFields[i][j];

					}
				}
				let tempPersonalize_fields = personalize_fields;
				tempPersonalize_fields = personalizeArray;
				setPersonalize_fields(tempPersonalize_fields);

				let coverLetterList = response?.agCoverLetterList;
				setCoverLetterList(coverLetterList);

				let date;
				let responseExpDate = _template_details.expiration_date;
				const momentDate = moment(_template_details.expiration_date);
				if (moment !== null && moment !== "" && responseExpDate != undefined) {
					date = momentDate.format(dateFormat[app.me.date_format]);
				}
				let temp = changeLog;
				temp.country = _template_details.country;
				temp.state = _template_details.state;
				temp.expirationDate = date;
				temp.coverLetterId = _template_details.cover_letter_id;
				temp.attachedPDFId = parseInt(_template_details.FDD_file_id);
				if ((minimizeList.hasOwnProperty('edit') && minimizeList?.edit) || (minimizeList.hasOwnProperty('Edit') && minimizeList.Edit) || (minimizeList.hasOwnProperty('Copy') && minimizeList?.Copy)) {
					//bypass
				} else {
					setchangeLog({ ...temp });

				}
				let _id = parseInt(_template_details.FDD_file_id);
				libraryList(_id);
			});
		}
		if (id === 0) {
			response = Promise.resolve(
				HTTPClient.get(endPoints.AGREEMENT_TEMPLATE.NEW, null)
			);
			response.then((response) => {
				let tempTemplate = template;

				tempTemplate["name"] = "";
				tempTemplate["id"] = 0;
				tempTemplate["email_message"] = "";
				tempTemplate["agreement_type"] = tempdata.agreement_type;
				let minimizeList = customDrawer?.minimizeList;
				if (minimizeList.hasOwnProperty('New') && minimizeList?.New) {
					//bypass
				} else {
					setTemplate(tempTemplate);

				}
				setMounted(true);
				let _data = agreementRedux.agreementTemplateData;

				if (_data?.saveClicked == true) {
					//bypass
				} else {
					setButtonVisible("none");
				}

				let line1 = "FDD Receipt"
				let line2 = "This disclosure document summarizes certain provisions of the franchise agreement and other information in plain language. Read this disclosure document and all agreements carefully."
				let line3 = "Suppose ${frname} offers you a franchise. In that case, it must provide this disclosure document to you 14 calendar-days before you sign a binding agreement with, or make a payment to, the franchisor or an affiliate in connection with the proposed franchise sale."
				let line4 = "If  ${frname} does not deliver this disclosure document on time or if it contains a false or misleading statement, or a material omission, a violation of federal law and state law may have occurred and should be reported to the Federal Trade Commission, Washington, D.C. 20580 and (insert state agency)"
				let line5 = "I received a disclosure document dated ${date}."
				let line6 = "${Signature}"
				let fddRecieptMsg = `${line1} <br/> <br/> ${line2} <br/> <br/> ${line3} <br/> <br/> ${line4} <br/> <br/> ${line5} <br/> <br/> <br/> ${line6} `
				tempTemplate["email_message"] = tempdata.agreement_type == "FDD" ? fddRecieptMsg : getLocalizedStrings().label.AGREEMENT_TEMPLATE.TYPE_HERE;

				let personalizeArray = {};
				let i;
				let j;
				let mergeFields = response?.mmFields?.available_mail_merge_fields;
				for (i in mergeFields) {
					for (j in mergeFields[i]) {
						personalizeArray[j] = mergeFields[i][j];
					}
				}
				let tempPersonalize_fields = personalize_fields;
				tempPersonalize_fields = personalizeArray;
				setPersonalize_fields(tempPersonalize_fields);

				let coverLetterList = response?.agCoverLetterList;
				setCoverLetterList(coverLetterList);
			});

		}
	};

	useEffect(
		(id) => {
			loadAgreementTemplateData(id);
		},
		[id]
	);

	useEffect(() => {
		loadAgreementTemplateData(id);
	}, []);

	const libraryList = (id) => {
		let params = {
			fields: ["document_name", "t_type", "creator"],
			"search_field_name": "document_type", "search_text": "through_library", "push_count": true
		}
		var promise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.DOCUMENT_LIST, params));
		promise.then((response) => {
			let records = response.records;
			let _attachedPDF = ''
			records.map((rec) => {
				if (rec.id == id) {
					return _attachedPDF = rec.document_name;
				}
			})
			let temp = changeLog;
			temp.attachedPDF = _attachedPDF;
			let minimizeList = customDrawer.minimizeList;

			if ((minimizeList.hasOwnProperty('edit') && minimizeList?.edit) || (minimizeList.hasOwnProperty('Edit') && minimizeList.Edit) || (minimizeList.hasOwnProperty('Copy') && minimizeList?.Copy)) {
				//bypass
			} else {
				setchangeLog({ ...temp });

			}

		});

	}

	const handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		let tempTemplate = template;
		if (name === "Name") {
			setTemplate({ ...tempTemplate, name: value });

		} else {
			setTemplate({ ...tempTemplate, name: value });

		}
		if (value.trim() !== "") {
			let tempError = error;
			tempError[name] = "";
			setError({ ...tempError });
		}
	};

	const changeStatus = (event) => {
		let tempTemplate = template;
		const target = event.target;
		const value = target.value;
		setTemplate({ ...tempTemplate, status: value });

	};

	const changeCoverLetter = (event) => {
		let temp = changeLog;
		const target = event.target;
		const value = target.value;
		temp.coverLetterId = value;
		setchangeLog({ ...temp });
	};

	const handleMoreActionChange = (value) => {
		let hasPermission = hasAccessPermission(
			object,
			constants.SECURITY_LEVEL_TYPE_PERMISSION,
			constants.PERMISSION_NAME_MANAGE_TEMPLATES
		);
		if (hasPermission) {
			if (app.me.t_name !== "Admin") {
				hasPermission = false;
			}
		}
		switch (value) {
			case "Copy":
				if (hasPermission) {
					setTemplate({ ...template, id: 0, name: getLocalizedStrings().label.AGREEMENT_TEMPLATE.COPY_OF + '_' + template.name })
					setButtonVisible("none");

				} else {
					sfDialogs.alert(
						getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
						getLocalizedStrings().message.COMMON.ACCESS_DENIED,
						null
					);
				}
				break;
			case "Delete":
				if (hasPermission) {
					sfDialogs.confirm(
						getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
						getLocalizedStrings().message.AGREEMENT_TEMPLATE.DELETE,
						deleteTemplate.bind(),
						null
					);
				} else {
					sfDialogs.alert(
						getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
						getLocalizedStrings().message.COMMON.ACCESS_DENIED,
						null
					);
				}
				break;
			case "Preview":
				window.open("/#/previewAgreementTemplate/" + template.id);
				break;
			default:
				return false;
		}
	};

	const handleModelChange = (event) => {
		let tempTemplate = template;
		setTemplate({ ...tempTemplate, email_message: event });

	};

	const saveTemplate = () => {
		if (!validateForm()) {
			return false;
		}

		var metaData = {};
		metaData.page_name = "AgreementTemplate";
		metaData.template_name = getStringParam(template.name);
		metaData.table_name = constants.AGREEMENT_TEMPLATE_OBJECT;

		var eventData = {};
		eventData.name =
			getIntParam(template.id) > 0 ? "editing record" : "creating record";
		eventData.meta_data = metaData;
		let date;
		const momentDate = moment(changeLog.expirationDate);
		if (moment !== null && moment !== "") {
			date = momentDate.format("YYYY-MM-DD");
		}
		let params = {
			id: template.id,
			name: template.name,
			email_message: template.email_message,
			status: template.status,
			expiration_date: date,
			agreement_type: template.agreement_type,
			country: template.agreement_type == "FDD" ? changeLog.country : '',
			state: template.agreement_type == "FDD" ? changeLog.state : '',
			FDD_file_id: template.agreement_type == "FDD" ? changeLog.attachedPDFId : '',
			cover_letter_id: changeLog.coverLetterId,
		};
		var response = Promise.resolve(
			HTTPClient.post(endPoints.AGREEMENT_TEMPLATE.SAVE, params)
		);
		setMounted(false);
		response.then((response) => {
			if (response.status === 0) {
				if (params.id === 0) {
					let tempTemplate = template;
					tempTemplate.id = response.data.id[0];
					setButtonVisible("none");
				}

				let templateList = getArrayParam(sendMail.templateList);
				if (templateList.length > 0) {
					let templateListObj = getObjectParam(templateList[0]);
					let agreementTempList = getArrayParam(
						templateListObj["Agreement Template"]
					);

					let templateObj = {
						email_status: template.status,
						execution_time: null,
						id: template.id,
						name: template.name,
						agreement_type: template.agreement_type,
						preview_image_url: "",
					};

					if (params.id === 0) {
						if (template.status === constants.ACTIVE) {
							agreementTempList.push(templateObj);
						}
					} else {
						let agreementTemplateObj = agreementTempList.filter(
							(f) => f.id === params.id
						);
						if (agreementTemplateObj.length > 0) {
							if (template.status === constants.ACTIVE) {
								agreementTemplateObj[0].name = templateObj.name;
								agreementTemplateObj[0].email_status = templateObj.email_status;
							} else {
								agreementTempList = agreementTempList.filter(
									(f) => f.id !== params.id
								);
							}
						} else if (template.status === constants.ACTIVE) {
							agreementTempList.push(templateObj);
						}
					}

					sortArrayObjectByProperty(agreementTempList, "name");
					templateListObj["Agreement Template"] = agreementTempList;

					templateList = new Array(templateListObj);

					dispatch(storeTemplateListInRedux(templateList));
				}
				dispatch(
					showCustomSnackBar(
						getLocalizedStrings().message.COMMON.SAVE,
						styles.snackbarBodyStyleSuccess,
						styles.snackBarStyleTop
					)
				);
				setMounted(true);
				setButtonVisible("inline-block");
				setSaveClicked(true);
				refreshListView(constants.AGREEMENT_TEMPLATE_OBJECT);
			} else if (response.status === -1 && response.error.message !== "") {
				dispatch(
					showCustomSnackBar(
						getLocalizedStrings().message.AGREEMENT_TEMPLATE
							.TEMPLATE_NAME_ERROR,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleLongMsg
					)
				);
				setMounted(true);
				setButtonVisible("none");
			}
		});
	};

	const deleteTemplate = () => {
		let params = {
			id: [template.id],
		};
		var response = Promise.resolve(
			HTTPClient.post(endPoints.AGREEMENT_TEMPLATE.DELETE, params)
		);
		setMounted(false);
		response.then((response) => {
			dispatch(
				showCustomSnackBar(
					getLocalizedStrings().message.AGREEMENT_TEMPLATE.TEMPLATE_DELETED,
					styles.snackbarBodyStyleSuccess,
					styles.snackBarStyleTop
				)
			);
			dispatch(getAppDrawer(false, null, null, null, null));
			setMounted(true);
			refreshListView(constants.AGREEMENT_TEMPLATE_OBJECT);
		});
	};

	const validateForm = () => {
		let tempTemplate = template;
		let tempError = error;
		let isValid = true;
		if (tempTemplate.name.trim() === "") {
			isValid = false;
			tempError["name"] =
				getLocalizedStrings().message.AGREEMENT_TEMPLATE.NAME_BLANK;
			setError({ ...tempError });
		}
		if (tempTemplate.email_message.trim() !== "") {
			const tagCount = 10;
			let signatureList = [];
			for (let i = 0; i < tagCount; i++) {
				signatureList.push(i == 0 ? 'Signature' : `Signature${i + 1}`);
			}
			let templateBody = tempTemplate.email_message;
			let isSequenced = true;
			let isMessageValid = true;
			let isTagPresent = true;
			let count = 0;
			signatureList.forEach(e => {
				if (isMessageValid) {
					let sIdx = '${' + e + '.';
					let sTag = '${' + e + '}';
					if (templateBody.includes(sIdx) || templateBody.includes(sTag)) {
						count++;
						if (!isSequenced) {
							isMessageValid = false;
						}
						if (templateBody.includes(sIdx) && !templateBody.includes(sTag)) {
							isTagPresent = false;
						}
						if (!templateBody.includes(sTag)) {
							isMessageValid = false;
						}
					} else {
						isSequenced = false;
					}
				}
			})

			if (!isMessageValid) {
				isValid = false;
				let msg = '';
				msg += "<span>" + getLocalizedStrings().message.AGREEMENT_TEMPLATE.SIGNATURE_TAGS_NOT_SEQUENCED_1 + "<br /></span>";
				msg += "<span>" + getLocalizedStrings().message.AGREEMENT_TEMPLATE.SIGNATURE_TAGS_NOT_SEQUENCED_2 + "<br /></span>";
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					msg,
					null
				);
			}
			if (!count) {
				isValid = false;
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					getLocalizedStrings().message.AGREEMENT_TEMPLATE.SIGNATURE_TAGS_MISSING,
					null
				);
			}

			if (!isTagPresent) {
				isValid = false;
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					getLocalizedStrings().message.AGREEMENT_TEMPLATE.SIGNATURE_TAGS_CONTACT_FIELDS_COMBO_MISSING,
					null
				);
			}
		}

		return isValid;
	};

	const onClickUploadAttachment = () => {
		let data = {
			type: "link",
			is_library: false,
			is_attachment: true,
		}
		data.callFrom = constants.AGREEMENT_OBJECT;
		data.fullWidth = true;
		data.maxWidth = 'md';
		dispatch(getAppDialog(true, constants.LIBRARY_DIALOG, getLocalizedStrings().message.COMMON.LIBRARY_DIALOG_TITLE, (sendData) => {
			console.log("sendData", sendData);
			const _itemType = sendData.itemType;
			if (_itemType && _itemType !== "pdf") {
				dispatch(showCustomSnackBar("Please select pdf file.", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
			} else {
				let temp = changeLog;
				temp.attachedPDF = sendData.name;
				temp.attachedPDFId = sendData.id;
				setchangeLog({ ...temp });
				dispatch(getAppDialog(false, null, null, null, null, null));

			}

		}, data, constants.AGREEMENT_OBJECT));

	}
	const handleFlatpkrDateChange = (date) => {
		try {
			if (isValidParam(date)) {
				let selectedDate = new Date(date);
				let dateValue = new Date(
					selectedDate.getFullYear(),
					selectedDate.getMonth(),
					selectedDate.getDate(),
					selectedDate.getHours(),
					selectedDate.getMinutes()
				);
				const momentDate = moment(dateValue);
				if (moment !== null && moment !== "") {
					date = momentDate.format(dateFormat[app.me.date_format]);
				}
			}

			let temp = changeLog;
			temp.expirationDate = date;
			setchangeLog({ ...temp })

		} catch (error) {
			console.error(
				"Error in 'AgreementTemplate.js -> handleFlatpkrDateChange()':" + error
			);
		}
	};
	const setSelectedCountryValue = (event) => {
		let value = event.target.value;
		let temp = changeLog;
		temp.country = value;
		setchangeLog({ ...temp });

	}

	const setSelectedStateValue = (event) => {
		let value = event.target.value;
		let temp = changeLog;
		temp.state = value;
		setchangeLog({ ...temp });

	}

	const getLibrary = (editor, type) => {
		if (type != "image") {
			editor.events.disableBlur();
			disableEditorBlurEffect();
		}
		let data = {
			editor: editor,
			contentStyle: { width: "80%", maxWidth: "none" },
			type: type,
			fullWidth: true,
			maxWidth: "md",
		};
		dispatch(
			getAppDialog(
				true,
				constants.LIBRARY_DIALOG,
				getLocalizedStrings().message.COMMON.LIBRARY_DIALOG_TITLE,
				handleLibraryResponse,
				data,
				null
			)
		);
	};


	const handleLibraryResponse = (obj) => {
		obj.editor.events.enableBlur();
		if (obj.type === "link" && obj.src !== "") {
			document.getElementsByName("href")[0].value = obj.src;
		} else if (obj.type === "image" && obj.src !== "") {
			obj.editor.image.insert(obj.src);
		}
		dispatch(
			getAppDialog(false, constants.LIBRARY_DIALOG, null, null, null, null)
		);
	};

	const getVideoInsertDialog = (editor, videoType) => {
		editor.events.disableBlur();
		let data = {
			editor: editor,
			contentStyle: { width: "80%", maxWidth: "none" },
			videoType: videoType,
		};
		let title = null;
		if (videoType === constants.VIDEO_TYPE_YOUTUBE) {
			title = getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE;
		} else if (videoType === constants.VIDEO_TYPE_WISTIA) {
			title = getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE;
		}
		dispatch(
			getAppDialog(
				true,
				constants.VIDEO_INSERT_DIALOG,
				title,
				(event) => handleVideoInsertResponse(event),
				data,
				null
			)
		);
	};

	const handleVideoInsertResponse = (obj) => {
		obj.editor.events.focus();
		dispatch(getAppDialog(false, constants.VIDEO_INSERT_DIALOG, null, null, null, null));
		obj.editor.html.insert('<a href="' + obj.video_link + '" target="_blank" className="editor_video-selector"><img height="220" width="380" src="' + obj.thumbnail.url + '" title="' + obj.title + '" /></a>');
		obj.editor.events.focus();
		obj.editor.events.enableBlur();
	};



	const getMoreMenuList = () => {
		let moreMenuList = {
			Copy: getLocalizedStrings().label.COMMON.COPY,
			Delete: getLocalizedStrings().label.COMMON.DELETE,
			Preview: getLocalizedStrings().label.COMMON.PREVIEW,
		};
		return moreMenuList;
	};

	Froalaeditor.DefineIcon("personalize_dropdown", { NAME: "fa-duotone fa-gear", template: "font_awesome" });
	Froalaeditor.RegisterCommand("personalize_dropdown", {
		title: getLocalizedStrings().label.AGREEMENT_TEMPLATE.PERSONALIZE,
		type: "dropdown",
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		options: personalize_fields,
		callback: function (cmd, val) {
			this.html.insert(" ${" + val + "} ");
		},
		// Callback on refresh.
		refresh: function ($btn) { },
		// Callback on dropdown show.
		refreshOnShow: function ($btn, $dropdown) { },
	});

	Froalaeditor.DefineIcon('link_library', { NAME: 'file-archive-o', template: "font_awesome" });
	Froalaeditor.RegisterCommand("link_library", {
		title: getLocalizedStrings().label.MARKETING_TEMPLATE.SELECT_FROM_LIBRARY,
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			getLibrary(this, "link");
		},
	});

	Froalaeditor.DefineIcon('library', { NAME: 'image', template: "font_awesome" });
	Froalaeditor.RegisterCommand("library", {
		title: getLocalizedStrings().label.SALES_TEMPLATE.INSERT_IMAGE,
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			getLibrary(this, "image");
		},
	});

	Froalaeditor.DefineIcon("insertYouTubeVideo", {
		NAME: "fa-brands fa-youtube", template: "font_awesome"
	});
	Froalaeditor.RegisterCommand("insertYouTubeVideo", {
		title: getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE,
		focus: true,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			getVideoInsertDialog(this, constants.VIDEO_TYPE_YOUTUBE);
		},
	});

	Froalaeditor.DefineIcon("insertWistiaVideo", {
		SRC: "/asset/images/wistia.png",
		ALT: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
		template: "image",
	});
	Froalaeditor.RegisterCommand("insertWistiaVideo", {
		title: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
		focus: true,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			getVideoInsertDialog(this, constants.VIDEO_TYPE_WISTIA);
		},
	});

	Froalaeditor.DefineIcon("signature_tag", { NAME: "fa-regular fa-file-signature", template: "font_awesome" });
	Froalaeditor.RegisterCommand("signature_tag", {
		title: getLocalizedStrings().label.BROADCAST_CAMPAIGNS.ADD_SIGNATURE,
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			setEditor(this);
			setIsOpenSignatureTag(true);
		},
	});

	Froalaeditor.DefineIcon("current_date_tag", { NAME: "fa-regular fa fa-calendar", template: "font_awesome" });
	Froalaeditor.RegisterCommand("current_date_tag", {
		title: "Insert Date",
		type: 'dropdown',
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		options: currentDateList,
		callback: function (cmd, val) {
			this.html.insert(" ${" + val + "} ");
		},
		// Callback on refresh.
		refresh: function ($btn) {
		},
		// Callback on dropdown show.
		refreshOnShow: function ($btn, $dropdown) {
		}
	});

	let height = window.innerHeight - 150;
	let top = (height - 10) / 2;
	if (!mounted) {
		return (
			<div style={{ width: "100%", height: height }}>
				<div
					className="asset-loaderh"
					style={{ paddingTop: top, paddingLeft: "45%" }}
				>
					<div
						style={{
							...styles.assetLoaderContainer,
							height: 55,
							width: 55,
							padding: 6,
						}}
					>
						<ShowCircularProgress
							size={35}
							style={{ marginTop: "4", marginLeft: "4" }}
						/>
					</div>
				</div>
			</div>
		);
	}

	if (redirect) {
		setRedirect(false);
		return <Navigate push to={redirectUrl} />;
	}

	const handelSignerSection = (value) => {
		setSelectedSigner(value);
		setSelectedmergeFields([]);
	}
	const handelFieldsSection = (value) => {
		let _fieldList = selectedmergeFields;
		const fi = _fieldList.findIndex((i) => i === value);
		if (fi > -1) {
			_fieldList.splice(fi, 1);
		} else {
			_fieldList.push(value);
		}
		setSelectedmergeFields([..._fieldList]);
	}
	const handelCloseSigner = () => {
		setEditor(null);
		setSelectedSigner('Signature');
		setSelectedmergeFields([]);
		setIsOpenSignatureTag(false);
	}
	const handelSignerSet = () => {
		let _tag = "";
		if (selectedmergeFields.length) {
			selectedmergeFields.forEach(field => { _tag += "${" + selectedSigner + "." + field + "}" });
		} else {
			_tag = " ${" + selectedSigner + "} ";
		}
		setSelectedSigner('Signature');
		setSelectedmergeFields([]);
		setIsOpenSignatureTag(false);
		editor.html.insert(_tag);
		handleModelChange(editor.html.get());
	}
	let FroalaConfig = {
		key: constants.FROALA_EDITOR_KEY,
		placeholderText: getLocalizedStrings().label.AGREEMENT_TEMPLATE.TYPE_HERE,
		height: document.body.clientHeight - 200,
		toolbarButtons: constants.AGREEMENT_EDITOR_TOOLBAR_CONFIGURATION,
		toolbarButtonsMD: constants.AGREEMENT_EDITOR_TOOLBAR_CONFIGURATION,
		toolbarButtonsSM: constants.AGREEMENT_EDITOR_TOOLBAR_CONFIGURATION,
		toolbarButtonsXS: constants.AGREEMENT_EDITOR_TOOLBAR_CONFIGURATION,
		colorsBackground: constants.EDITOR_TOOLBAR_BACKGROUND_COLOR_PALETTE,
		colorsText: constants.EDITOR_TOOLBAR_TEXT_COLOR_PALETTE,
		toolbarSticky: false,
		enter: FroalaEditor.ENTER_BR,
		iframe: true,
		htmlUntouched: true,
		attribution: false,
		inlineMode: false,
		linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
		linkInsertButtons: ['linkEdit', 'linkRemove', 'link_library'],
		imageEditButtons: ['imageAlign', 'imageRemove', 'imageLink', 'imageDisplay', 'imageAlt', 'imageSize', 'linkOpen', 'linkEdit', 'linkRemove'],
		tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', '-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign'],
		quickInsertButtons: [],
		imageUpload: false,
		imagePaste: false,
		fileUpload: false,
		// autofocus: autoFocus,
		fontSize: constants.EDITOR_FONT_SIZE,
		fontFamily: {
			'Adobe Garamond Pro': 'Adobe Garamond Pro',
			'Arial,Helvetica,sans-serif': 'Arial',
			'Futura Std': 'Futura Std',
			'Georgia,serif': 'Georgia',
			'Impact,Charcoal,sans-serif': 'Impact',
			"RobotoSegoe": 'Roboto',
			'Tahoma,Geneva,sans-serif': 'Tahoma',
			"'Times New Roman',Times,serif": 'Times New Roman',
			'Verdana,Geneva,sans-serif': 'Verdana',
		},
		fontFamilySelection: true,
		events: {
			'froalaEditor.initialized': function (e, editor) {
				$('.fr-command').on('click', function () {
					if ($(this).data('cmd') === 'insertLink') {
						$('input[name="target"]').prop('checked', true);
					}
				});
			}
		},
		events: {
			'initialized': function () {
				window.WEBSPELLCHECKER.init({
					container: this.$iframe ? this.$iframe[0] : this.el
				});
			}
		},
		events: {
			focus: handleEditorFocus,
		},
		zIndex: 2501
	}
	let hasPermission = hasAccessPermission(
		object,
		constants.SECURITY_LEVEL_TYPE_PERMISSION,
		constants.PERMISSION_NAME_MANAGE_TEMPLATES
	);
	if (hasPermission) {
		if (app.me.t_name !== "Admin") {
			hasPermission = false;
		}
	}
	let saveBtnStyle = hasPermission
		? { ...styles.primaryButton, verticalAlign: "top" }
		: {
			...styles.primaryButton,
			verticalAlign: "top",
			backgroundColor: "#ffc5b3",
			cursor: "not-allowed",
		};

	let menuList = [];
	let tempMenuList = getMoreMenuList();
	Object.keys(optionvalue).forEach((itemKey) => {
		let data = {};
		data.label = tempMenuList[optionvalue[itemKey]];
		data.value = itemKey;
		menuList.push(data);
	});
	let currentDate = new Date();
	currentDate = moment(currentDate)
	currentDate = currentDate.format(dateFormat[app.me.date_format])
	let HHFormat = new RegExp("HH");
	let datePickerOptions = {
		enableTime: false,
		dateFormat: dateFormatFlatPicker[app.me.date_format],
		minuteIncrement: 1,
		static: true,
		time_24hr: HHFormat.test(app.me.date_format) ? true : false
	};
	return (
		<div style={styles.workAreaContainer}>
			<div style={{ paddingLeft: "26px", paddingRight: "26px" }}>
				<div>

					<div style={{ ...stickyStyle, paddingTop: "8px" }}>
						<Button
							onClick={() => saveTemplate()}
							style={saveBtnStyle}
							labelstyle={{ fontWeight: "normal" }}
							disabled={hasPermission ? false : true}
						>
							{getLocalizedStrings().label.COMMON.SAVE}
						</Button>
						<Button
							onClick={closeDrawer.bind(this)}
							style={{ ...styles.secondaryButton, verticalAlign: "top" }}
							labelstyle={{ fontWeight: "normal" }}
						>
							{getLocalizedStrings().label.COMMON.CANCEL}
						</Button>

						<div
							style={{
								paddingRight: "20px",
								paddingTop: "0px",
								display: buttonVisible,
							}}
						>
							<div
								style={{
									...styles.togButton,
									backgroundColor: "#ffffff",
									height: "27px",
									width: "28px",
									border: "1px solid rgb(224,224,224)",
								}}
							>
								<PopOver
									id={"sales-template-more"}
									key={"sales-template-more"}
									name={"sales-template-more"}
									buttonEndIcon={"more_vert"}
									buttonStyle={{
										fontSize: "20px",
										color: "#717171",
										cursor: "pointer",
										paddingTop: "3px",
										paddingLeft: "3px",
									}}
									labelposition={"before"}
									buttonLabel={""}
									options={menuList}
									onclickAction={(value) => handleMoreActionChange(value)}
									isShowIcon={true}
									title={getLocalizedStrings().label.COMMON.MORE}
									// anchorEl={anchorMoreActionEl}
									buttonLineOrigin={[22, 20]}
								/>
							</div>
						</div>
					</div>

					<div style={{ paddingTop: "20px" }}>
						<div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
							<div style={{ width: '32%' }}>
								<FormControl
									style={{ width: "100%" }}
									className="test"
									noValidate
									autoComplete="off"
								>
									<TextField
										variant="outlined"
										underlineFocusStyle={styles.textField.underlineFocus}
										label={
											getLocalizedStrings().label.AGREEMENT_TEMPLATE
												.NAME_REQUIRED
										}
										fullWidth={true}
										name="Name"
										value={template.name}
										onChange={(e) => handleChange(e)}
										className={"sf-fields-bg"}
										error={error.name !== ""}
										helperText={error.name}
										autoFocus
										autoComplete="new-password"
										margin="dense"
										size="small"
									/>
								</FormControl>
							</div>

							<div style={{ width: "22%" }}>
								<div style={{ width: "100%" }}>
									<FormControl
										style={{ width: "100%" }}
										className="test"
										noValidate
										variant="outlined"
										autoComplete="off"
									>
										<InputLabel
											id="sf-list-view-header-from-listviewheader-simple-select-outlined-label"
											className="sf-list-view-header-from-listviewheader"
											style={{ marginTop: '4px' }}
										>
											{getLocalizedStrings().label.SALES_TEMPLATE.STATUS}
										</InputLabel>
										<Select
											id="status"
											className="appontmentdd"
											key={"sf-auto-complete-status"}
											name="status"
											value={template.status}
											onChange={(e) => changeStatus(e)}
											floatingLabelText={
												getLocalizedStrings().label.SALES_TEMPLATE.STATUS
											}
											textFieldStyle={styles.datePicker}
											size="small"
											MenuProps={{
												anchorOrigin: {
													vertical: "bottom",
													horizontal: "left",
												},
												transformOrigin: {
													vertical: "top",
													horizontal: "left",
												},
												getContentAnchorEl: null,
											}}
											fullWidth={true}
											menuItemStyle={{}}
											style={{ marginTop: "4px" }}
											label={
												getLocalizedStrings().label.SALES_TEMPLATE.STATUS
											}
										>
											<MenuItem
												value={"Active"}
												primaryText={
													getLocalizedStrings().label.SALES_TEMPLATE.ACTIVE
												}
												style={styles.popoverMenuItem}
											>
												{getLocalizedStrings().label.SALES_TEMPLATE.ACTIVE}
											</MenuItem>
											<MenuItem
												value={"Inactive"}
												primaryText={
													getLocalizedStrings().label.SALES_TEMPLATE.INACTIVE
												}
												style={styles.popoverMenuItem}
											>
												{getLocalizedStrings().label.SALES_TEMPLATE.INACTIVE}
											</MenuItem>
										</Select>
									</FormControl>
									{/* </div> */}
								</div>
							</div>

							<div style={{ width: "22%" }}>
								<div style={{ margin: "15px 0", width: "100%" }} className={"sf-form-field dateTimeDiv-fa-expiration-date"} id="dateholder">
									<PopOver
										id={'field-ref-fa-expiration-date'}
										key={"fa-expiration-date"}
										name={"fa-expiration-date"}
										btnType={"date"}
										buttonOuterLabelStyle={{ backgroundColor: '#fff', lineHeight: '0.1', marginTop: '-2px', padding: '-1px', paddingLeft: 5, paddingRight: 4, fontSize: 11, fontWeight: 500, position: 'absolute' }}
										buttonStyle={{ fontSize: "16px" }}
										containerStyle={{ lineHeight: 1 }}
										buttonLabel="Expiration Date"
										value={changeLog.expirationDate}
										onChange={(date) => handleFlatpkrDateChange(date)}
										innerLabelStyle={{
											padding: "11 5 2 5",
											fontSize: "15px",
										}}
										height={40}
										options={{ ...datePickerOptions, minDate: currentDate }}

									/>
								</div>
							</div>

							<div style={{ width: "22%"}}>
								<div style={{ width: "100%" }}>
									<FormControl style={{ width: "100%" }} className="test" noValidate variant="outlined" autoComplete="off">
										<TextField
												id="status"
												className="appontmentdd"
												key={"sf-auto-complete-status"}
												select
												label="Cover Letter"
												size="small"
												value={changeLog.coverLetterId || ''}
										        onChange={(e) => changeCoverLetter(e)}
											>
												{coverLetterMenuItems}
											</TextField>
									</FormControl>
								</div>
							</div>

						</div>
					</div>
				</div>
				{template.agreement_type == "FDD" &&
					<div style={{ paddingTop: "15px" }}>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10 }}>
							<div style={{ width: '15%' }}>
								<Button
									primary={true}
									onClick={() => onClickUploadAttachment()}
									style={{ ...styles.secondaryButton, height: '38px',width:"85%"}}
								>
									{<label>select fdd</label>}

								</Button>
							</div>
							<div style={{ width: '39%'}} title={changeLog.attachedPDF}> {changeLog.attachedPDF.length > 50 ? changeLog.attachedPDF.substring(0, 50) + '...' : changeLog.attachedPDF} </div>

							<div style={{ width: '22%' }}>
								<div style={{ fontSize: '12px' }}></div>
								<FormControl style={{ width: '100%', height: '38px' }} variant="outlined">
									<InputLabel className="sf-push-units-from-sfforms" style={{ marginTop: '-7px' }}>{getLocalizedStrings().label.UNITS.COUNTRY_SELECT}</InputLabel>
									<Select
										labelId='sf-label-country'
										id='sf-country'
										label={getLocalizedStrings().label.UNITS.COUNTRY_SELECT}
										value={changeLog.country}
										onChange={(e) => setSelectedCountryValue(e)}
										style={{ height: '38px' }}
										className={"sf-fields-bg"}
									>
										{countryMenuItems}
									</Select>
								</FormControl>
							</div>
							<div style={{ width: '22%' }}>
								<div style={{ fontSize: '12px' }}></div>
								<FormControl style={{ width: '100%', height: '38px' }} variant="outlined">
									<InputLabel id="sf-add-units-from-simple-select-outlined-label" className="sf-add-units-plan-name" style={{ marginTop: '-7px' }}>{getLocalizedStrings().label.UNITS.STATE_SELECT}</InputLabel>
									<Select
										labelId='sf-label-menu'
										id='sf-menu'
										label={getLocalizedStrings().label.UNITS.STATE_SELECT}
										value={changeLog.state}
										onChange={(e) => setSelectedStateValue(e)}
										autowidth={"true"}
										style={{ height: '38px' }}
										className={"sf-fields-bg"}
									>
										{stateMenuItems}
									</Select>
								</FormControl>
							</div>
						</div>
					</div>
				}

				{/* <div style={{ marginTop: '10px', }}>
					<div className="row">
						<div style={{ width: "40%" }}>
							<div style={{ width: "100%" }}>
								<FormControl style={{ width: "100%" }} className="test" noValidate variant="outlined" autoComplete="off">
									<InputLabel
										id="sf-list-view-header-from-listviewheader-simple-select-outlined-label"
										className="sf-list-view-header-from-listviewheader"
										style={{ marginTop: '4px' }}
									>{"Cover Letter"}
									</InputLabel>
									<Select
										id="status"
										className="appontmentdd"
										key={"sf-auto-complete-status"}
										name="status"
										value={changeLog.coverLetterId}
										onChange={(e) => changeCoverLetter(e)}
										floatingLabelText={"Cover Letter"}
										size="small"
										fullWidth={true}
										menuItemStyle={{}}
										style={{ height: '38px' }}
										label={"Cover Letter"}
									 >
										{coverLetterMenuItems}
									</Select>
								</FormControl>
							</div>
						</div>
					</div>
				</div> */}

				<div style={{ paddingTop: "20px", marginBottom: "50px" }}>
					<FroalaEditor
						tag="textarea"
						model={template.email_message}
						onModelChange={(e) => handleModelChange(e)}
						config={FroalaConfig}
						ref={(ref) => froalaRef = ref}
					/>

				</div><Popover
					name="noteTypeList"
					key={"sf-auto-complete-noteTypeList"}
					id={"noteTypeList"}
					anchorEl={document.querySelector('[id*="signature_tag"]')}
					anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
					targetOrigin={{ horizontal: "left", vertical: "top" }}
					open={isOpenSignatureTag}
					onClose={() => handelCloseSigner()}
				>
					<div style={{ width: 300, padding: 5 }}>
						<div style={{ display: 'flex' }}>
							<div style={{ height: 300, overflow: 'auto', borderRight: '1px solid', borderColor: '#aeaeae' }}>
								{Object.keys(SignatureList).map((key) => {
									return <MenuItem value={key} style={{ backgroundColor: selectedSigner === key ? '#00000042' : '', ...styles.popoverMenuItem }} onClick={() => handelSignerSection(key)}>
										<span>
											{SignatureList[key]}
										</span>
									</MenuItem>
								})}
							</div>
							<div style={{ height: 300, overflow: 'auto' }}>
								{Object.keys(personalize_fields).map((key) => {
									return <MenuItem value={key} style={{ backgroundColor: selectedmergeFields.find((i) => i === key) ? '#00000042' : '', ...styles.popoverMenuItem }} onClick={() => handelFieldsSection(key)}>
										<span>
											{personalize_fields[key]}
										</span>
									</MenuItem>
								})}
							</div>
						</div>
						<div style={{ padding: 5, borderTop: '1px solid', borderColor: '#aeaeae', width: '100%' }}>
							<Stack spacing={2} direction="row" style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Button variant="outlined" size="small" style={styles.secondaryButton} onClick={() => handelCloseSigner()}>cancel</Button>
								<Button variant="contained" size="small" style={styles.primaryButton} onClick={() => handelSignerSet()}>Ok</Button>
							</Stack>
						</div>
					</div>
				</Popover >
			</div>
		</div>
	);
};

export default AgreementTemplate;