import React, { useState, useEffect} from "react";
import { Navigate } from "react-router-dom";
import { constants } from "../../../services/constants/constants";
import {
	getActiveTabInfo,
	updateActiveTabProps,updateTabInfo,updateActiveTab
} from "../../../services/helper/sfTabManager";
import PopOver from "../components/PopOver";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import {
	getObjectParam,
	getStringParam,
} from "../../../services/helper/parameterVerifier";
import { setListViewMounted, refreshListView, removeScrollPosition } from '../../../services/actions/listViewAcions';
import {setHeader} from "../../../services/actions/headerActions";
import {useDispatch, useSelector} from 'react-redux';

let campaignList = [
	{
		value: "Without a/b test",
		label: getLocalizedStrings()?.label?.CAMPAIGNS.WITHOUT_AB_TEST,
	},
	{
		value: "With a/b test",
		label: getLocalizedStrings()?.label?.CAMPAIGNS.WITH_AB_TEST,
	},
];

const CampaignToggle = ({ object }) => {
	const dispatch = useDispatch();
	const [url, setUrl] = useState("");
	const [selectedCampaign, setSelectedCampaign] = useState("Without a/b test");
	const [openCampaignTypeList, setOpenCampaignTypeList] = useState(false);
	const [redirect, setRedirect] = useState(false);
	const [campaignLabel, setCampaignLabel] = useState({
		"Without a/b test": getLocalizedStrings().label.CAMPAIGNS.WITHOUT_AB_TEST,
		"With a/b test": getLocalizedStrings().label.CAMPAIGNS.WITH_AB_TEST,
	});
	const [anchorEl, setAnchorEl] = useState("");

	useEffect(() => {
		let info = getActiveTabInfo();
		info = getObjectParam(info);
		if (getStringParam(info.object) === constants.AB_CAMPAIGNS_OBJECT) {
			setSelectedCampaign("With a/b test");
		}
	}, []);

	const fnOpenCampaignTypeList = (event) => {
		event.preventDefault();
		setOpenCampaignTypeList(true);
		 setAnchorEl(event.currentTarget);
	};

	const handleChangeCampaignList = (value) => {
		let activeTabInfo = getObjectParam(getActiveTabInfo());
		let url = null;
		if (selectedCampaign === value) {
			setOpenCampaignTypeList(false);
			return false;
		}

		if (value === "Without a/b test") {
			url = "/" + constants.BROADCAST_CAMPAIGNS_OBJECT + "/queries";
			activeTabInfo.object = constants.BROADCAST_CAMPAIGNS_OBJECT;
		} else if (value === "With a/b test") {
			url = "/" + constants.AB_CAMPAIGNS_OBJECT + "/queries";
			activeTabInfo.object = constants.AB_CAMPAIGNS_OBJECT;
		}

		url = url.toLowerCase();
		url = url.replace("_", "-");

		if (activeTabInfo.hasOwnProperty("isSearchWithinList")) {
			delete activeTabInfo.isSearchWithinList;
		}
		if (activeTabInfo.hasOwnProperty("searchFieldName")) {
			delete activeTabInfo.searchFieldName;
		}
		if (activeTabInfo.hasOwnProperty("searchText")) {
			delete activeTabInfo.searchText;
		}

		delete activeTabInfo.groupByCondition;
		delete activeTabInfo.groupByConditionTotalCount;
		delete activeTabInfo.groupByFieldName;
		delete activeTabInfo.pageSize;
		delete activeTabInfo.selectedPageNo;
		delete activeTabInfo.startIndex;
		delete activeTabInfo.startPageNo;

		updateActiveTabProps({ url: url, info: activeTabInfo });
		updateTabInfo(activeTabInfo);
		refreshListView(activeTabInfo.object);
		setRedirect(true);
		setUrl(url);
		dispatch(setHeader(
			activeTabInfo.object,
			null,
			activeTabInfo.object,
			true,
			true
		));
		setSelectedCampaign(value);
		setOpenCampaignTypeList(false);
	};

	

	if (redirect) {
		return <Navigate to={url} />;
	}
	return (
		<div
			style={{
				cursor: "pointer",
				paddingLeft: "0px",
				paddingRight: "0px",
				float: "left",
				marginRight: "0px",
				marginTop: "1.5px",
			}}
		>
			<div
				onClick={(e) => fnOpenCampaignTypeList(e)}
				style={{
					display: "flex",
					fontSize: "14px",
					marginLeft: "3px",
					marginTop: "5px",
				}}
			>
				{campaignLabel[selectedCampaign]}
				<PopOver
					id={"campaign"}
					key={"campaign"}
					name={"campaign"}
					buttonEndIcon={"keyboard_arrow_down"}
					buttonStyle={{
						paddingTop: "0px",
						paddingLeft: "2px",
						marginTop: "0px",
						marginLeft: "0px",
					}}
					labelposition={"before"}
					buttonLabel={""}
					options={campaignList}
					onclickAction={(value) => handleChangeCampaignList(value)}
					isShowIcon={true}
					anchorEl={anchorEl}
					anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
					targetOrigin={{ horizontal: "left", vertical: "top" }}
				/>
			</div>
		</div>
	);
};

export default CampaignToggle;
