import React from 'react';
import { styles } from '../../../services/constants/styles';
import { Button, Chip, TextField, FormControl } from '@mui/material';
import { isValidParam, getArrayParam, getStringParam, getIntParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { constants } from '../../../services/constants/constants';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import * as sfDialogs from '../components/sfDialogs';
import { setHeader } from '../../../services/actions/headerActions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const AutoFillValues = () => {

    const dispatch = useDispatch();
    const [tableList, setTableList] = useState([]);
    const [linkedChildTableList, setLinkedChildTableList] = useState([]);
    const [parentObjectId, setParentObjectId] = useState('');
    const [childObjectId, setChildObjectId] = useState('');
    const [parentObjectName, setParentObjectName] = useState('');
    const [childObjectName, setChildObjectName] = useState('');
    const [parentFieldList, setParentFieldList] = useState(null);
    const [childFieldList, setChildFieldList] = useState(null);
    const [parentObjectFieldId, setParentObjectFieldId] = useState('');
    const [childObjectFieldId, setChildObjectFieldId] = useState('');
    const [parentChildFieldMapList, setParentChildFieldMapList] = useState(null);
    const [parentFieldDrpdn, setParentFieldDrpdn] = useState([]);
    const [childFieldDrpdn, setChildFieldDrpdn] = useState([]);
    const [dataTypeList, setDataTypeList] = useState(null);
    const [formFieldTypeList, setFormFieldTypeList] = useState(null);
    const [initialMapCount, setInitialMapCount] = useState(0);
    const [oldChildObjId, setOldChildObjId] = useState('');

    const app = useSelector((state) => state.app);

    useEffect(() => {
        getTableNdDataTypeList();
        dispatch(setHeader(constants.SETTING, getLocalizedStrings().label.CUSTOMIZATION_SETTING['AUTO_FILL_VALUES'], null, false, true));
    }, []);

    const onConfirmCancel = (event) => {
        let tempOldChildObjId = oldChildObjId;
        setChildObjectId(tempOldChildObjId);
    }

    const onObjectChange = (fieldName, event, value, index) => {
        let msg = '';
        let tempValue = value.id;
        let oldchildonjid = childObjectId;
        if (fieldName === 'parentObjectId') {
            if (parentObjectId !== tempValue && parentFieldList !== null && childFieldList !== null) {
                msg = getLocalizedStrings().message.AUTO_FILL_VALUES.CHNAGE_PARENT_OBJECT;
                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, (e) => onObjectChangeonConfirmPerentObj(e, tableList, tempValue), (e) => onConfirmCancel(e));

            } else if (parentObjectId !== tempValue) {
                setParentObjectId(tempValue);
                let tempParentObjectName = getObjectName(tableList, tempValue);
                setParentObjectName(tempParentObjectName);
                getLinkedChildTableList(tempValue);
            }
        } else {
            if (oldchildonjid !== tempValue && parentFieldList !== null && childFieldList !== null) {
                msg = getLocalizedStrings().message.AUTO_FILL_VALUES.CHANGE_CHILD_OBJECT;

                setOldChildObjId(oldchildonjid);
                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, (e, tempValue) => onObjectChangeonConfirmChildObj(e, tempValue), (e) => onConfirmCancel(e));

            } else if (oldchildonjid !== tempValue) {
                setChildObjectId(tempValue);
                let tempChildObjectName = getObjectName(linkedChildTableList, tempValue);
                setChildObjectName(tempChildObjectName);
            }
        }
    }
    const onObjectChangeonConfirmPerentObj = (event, tableList, value) => {
        let parentObjectName = getObjectName(tableList, value);
        getLinkedChildTableList(value);
        setParentObjectId(value);
        setParentObjectName(parentObjectName);
        setParentFieldList(null);
        setChildFieldList(null);
    }
    const onObjectChangeonConfirmChildObj = (event, value) => {
        setChildObjectId(value);
        let tempChildObjectName = getObjectName(linkedChildTableList, value);
        setChildObjectName(tempChildObjectName);
        setParentFieldList(null);
        setChildFieldList(null);
    }
    const onFieldChange = (fieldName, event, value, index) => {
        let _parentObjectFieldId = parentObjectFieldId;
        let _childObjectFieldId = childObjectFieldId;
        if (fieldName === 'parentObjectFieldId') {
            _parentObjectFieldId = value.id;
        } else {
            _childObjectFieldId = value.id;
        }
        setParentObjectFieldId(_parentObjectFieldId);
        setChildObjectFieldId(_childObjectFieldId);
    }

    const getTableNdDataTypeList = () => {
        let url = null;
        let tableList = null;
        let dataTypeList = null;
        let formFieldType = null;
        let parentList = null;
        let childList = [];
        url = endPoints.AUTOFILL.GET_TABLE_DATA_TYPE_LIST;
        let promise = Promise.resolve(HTTPClient.get(url))
        promise.then((response) => {
            if (isValidParam(response)) {
                tableList = response.tableList;
                dataTypeList = response.dataTypeList;
                formFieldType = response.formFieldType;
                if (tableList !== null && isValidParam(tableList)) {
                    parentList = tableList.map((obj) => { return { id: obj.id, label: obj.value } });
                }
                sortArrayObjectByProperty(parentList, 'label');

                parentList.unshift({ id: -1, label: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_PARENT });
                childList.unshift({ id: -1, label: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_CHILD });
                setTableList(parentList);
                setParentObjectId(-1);
                setLinkedChildTableList(childList);
                setChildObjectId(-1);
                setFormFieldTypeList(formFieldType);
                setDataTypeList(dataTypeList);
            }
        })
    }

    const getLinkedChildTableList = (parentTableId) => {
        let url = null;
        let param = {};
        let linkedChildTableList = null;
        let childList = [];
        if (parentTableId > 0) {
            url = endPoints.AUTOFILL.GET_LINKED_CHILD_TABLE_LIST;
            param.pTableId = parentTableId;
            let promise = Promise.resolve(HTTPClient.get(url, param));
            promise.then((response) => {
                linkedChildTableList = getArrayParam(response);
                childList = linkedChildTableList.map((obj) => { return { id: obj.id, label: obj.value } });
                sortArrayObjectByProperty(childList, 'label');
                childList.unshift({ id: -1, label: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_CHILD });
                setLinkedChildTableList(childList);
                setChildObjectId(-1);
            })
        } else {
            childList.unshift({ id: -1, label: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_CHILD });

            setLinkedChildTableList(childList);
            setChildObjectId(-1);
        }
    }

    const getAllFieldMappings = (event) => {
        let params = null;
        let url = null;
        let errorMsg = '';
        let pObjectId = getIntParam(parentObjectId);
        let cObjectId = getIntParam(childObjectId);
        if (pObjectId > 0 && cObjectId > 0) {
            params = { "parentTableId": pObjectId, "childTableId": cObjectId }
            url = endPoints.AUTOFILL.GET_FIELD_MAPPINGS;
            let promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    processFieldMapping(response);
                }
            })
        } else {
            errorMsg = pObjectId > 0 ? getLocalizedStrings().message.AUTO_FILL_VALUES.SELECT_CHILD_OBJECT : getLocalizedStrings().message.AUTO_FILL_VALUES.SELECT_PARENT_OBJECT;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, errorMsg, null);
        }
    }

    const processFieldMapping = (response) => {
        let arrParentFields;
        let arrChildFields;
        let parentChildFieldMapData = [];
        let parentFieldList = response.parentFieldList;
        let childFieldList = response.childFieldList;
        let parentChildFieldMapList = response.parentChildFieldMapList;

        parentChildFieldMapList.forEach((obj, index) => {
            arrParentFields = parentFieldList.filter((parentObj) => { return parentObj.id == obj.parentFieldId });
            if (arrParentFields.length > 0) {
                arrChildFields = childFieldList.filter((childObj) => { return childObj.id == obj.childFieldId });
            }

            if (arrParentFields.length > 0 && arrChildFields.length > 0) {
                obj.parentObjectLabel = parentObjectName;
                obj.parentFieldLabel = arrParentFields[0].value;
                obj.childObjectLabel = childObjectName;
                obj.childFieldLabel = arrChildFields[0].value;
                obj.pSystemField = arrParentFields[0].systemField;
                obj.cSystemField = arrChildFields[0].systemField;
                obj.pType = arrParentFields[0].type;
                obj.cType = arrChildFields[0].type;
                obj.pFormFieldType = arrParentFields[0].formFieldType;
                obj.cFormFieldType = arrChildFields[0].formFieldType;
                obj.pEmailField = arrParentFields[0].emailField;
                obj.cEmailField = arrChildFields[0].emailField;
                obj.pPhoneField = arrParentFields[0].phoneField;
                obj.cPhoneField = arrChildFields[0].phoneField;
                obj.pListType = arrParentFields[0].listType;
                obj.cListType = arrChildFields[0].listType;
                obj.pCurrencyField = arrParentFields[0].currencyField;
                obj.cCurrencyField = arrChildFields[0].currencyField;
                obj.pSize = arrParentFields[0].size;
                obj.cSize = arrChildFields[0].size;
                parentChildFieldMapData.push(obj);
            }
            arrParentFields = null;
            arrChildFields = null;
        })

        parentFieldList = parentFieldList.filter((parentFields) => {
            let arr = parentChildFieldMapData.filter((mapFileds) => { return mapFileds.parentFieldId == parentFields.id });
            return arr.length == 0 || (arr.length > 0 && arr[0].parentFieldId != parentFields.id);
        })

        childFieldList = childFieldList.filter((childFields) => {
            let arr = parentChildFieldMapData.filter((mapFileds) => { return mapFileds.childFieldId.toString() === childFields.id });
            return arr.length == 0 || (arr.length > 0 && arr[0].childFieldId != childFields.id);
        })

        sortArrayObjectByProperty(parentFieldList, 'value');
        sortArrayObjectByProperty(childFieldList, 'value');

        let parentFieldDrpdn = parentFieldList.map((parentFieldObj) => { return { id: parentFieldObj.id, label: parentFieldObj.value + ' (' + getFielsTypeSuffixText(parentFieldObj) + ')' } });
        let childFieldDrpdn = childFieldList.map((childFieldObj) => { return { id: childFieldObj.id, label: childFieldObj.value + ' (' + getFielsTypeSuffixText(childFieldObj) + ')' } });
        parentFieldDrpdn.unshift({ id: -1, label: getObjectName(tableList, parentObjectId) + ' Fields' });
        childFieldDrpdn.unshift({ id: -1, label: getObjectName(linkedChildTableList, childObjectId) + ' Fields' });
        let initialMapCount = parentChildFieldMapData.length;
        sortArrayObjectByProperty(parentChildFieldMapData, 'parentFieldLabel');
        setParentFieldList(parentFieldList);
        setChildFieldList(childFieldList);
        setParentChildFieldMapList(parentChildFieldMapData);
        setParentFieldDrpdn(parentFieldDrpdn);
        setChildFieldDrpdn(childFieldDrpdn);
        setParentObjectFieldId(-1);
        setChildObjectFieldId(-1);
        setInitialMapCount(initialMapCount);
    }

    const getObjectName = (objectList, id) => {
        let objectName = ''
        if (objectList !== null && id > 0) {
            let selectedObject = objectList.filter((obj) => { return obj.id === id });
            if (isValidParam(selectedObject) && selectedObject.length > 0) {
                objectName = getStringParam(selectedObject[0].label);
            }
        }
        return objectName;
    }

    const getFieldMap = () => {
        let elementArray = [];
        let linkedElement = parentChildFieldMapList;
        let parentFieldLabel = null;
        let childFieldLabel = null;
        if (linkedElement !== null && linkedElement.length > 0) {
            linkedElement.forEach((obj, index) => {
                let isError = isValidParam(obj.isError) ? getBooleanParam(obj.isError) : false;
                let parentObjForSuffix = {
                    formFieldType: obj.pFormFieldType,
                    emailField: obj.pEmailField,
                    phoneField: obj.pPhoneField,
                    listType: obj.pListType,
                    currencyField: obj.pCurrencyField
                }

                let childObjForSuffix = {
                    formFieldType: obj.cFormFieldType,
                    emailField: obj.cEmailField,
                    phoneField: obj.cPhoneField,
                    listType: obj.cListType,
                    currencyField: obj.cCurrencyField
                }

                parentFieldLabel = getStringParam(obj.parentFieldLabel) + ' (' + getFielsTypeSuffixText(parentObjForSuffix) + ')';
                childFieldLabel = getStringParam(obj.childFieldLabel) + ' (' + getFielsTypeSuffixText(childObjForSuffix) + ')';
                elementArray.push(getMapElement(obj.parentObjectLabel, parentFieldLabel, obj.childObjectLabel, childFieldLabel, obj.pSystemField, obj.cSystemField, obj.parentFieldId, obj.childFieldId, isError));
                parentObjForSuffix = null;
                childObjForSuffix = null;
            });
        } else {
            let noMappingMsg = getLocalizedStrings().message.AUTO_FILL_VALUES.NO_MAPPING_FIELDS + parentObjectName + ' ' + getLocalizedStrings().message.AUTO_FILL_VALUES.NO_MAPPING_FIELDS_AND + ' ' + childObjectName;
            elementArray.push(
                <div className='row' style={{ marginLeft: 0, marginTop: 30, width: '64%' }}>
                    <span>{noMappingMsg}.</span>
                </div>
            )
        }
        return elementArray;
    }

    const isValidField = (pFieldId, cFieldId, isCallFromAdd) => {
        let mappingOk = false;
        let errorMsg = '';
        let mappingObj = {};
        let pFieldList = parentFieldList;
        let cFieldList = childFieldList;
        let cFieldObj = null;
        let pFieldObj = null;
        let pObjectId = parentObjectId;
        let cObjectId = childObjectId;

        let pFormFieldType = 0;
        let pFieldType = 0;
        let pEmailField = 0;
        let pPhoneField = 0;
        let pListType = 0;
        let pCurrencyField = 0;
        let pFieldSize = 0;

        let cFormFieldType = 0;
        let cFieldType = 0;
        let cEmailField = 0;
        let cPhoneField = 0;
        let cListType = 0;
        let cCurrencyField = 0;
        let cFieldSize = 0;

        try {
            if (isCallFromAdd) {
                pFieldObj = pFieldList.filter((obj) => { return obj.id === pFieldId });
                cFieldObj = cFieldList.filter((obj) => { return obj.id === cFieldId });
                if (isValidParam(pFieldObj) && pFieldObj.length > 0 && isValidParam(cFieldObj) && cFieldObj.length > 0) {

                    pFormFieldType = getIntParam(pFieldObj[0].formFieldType);
                    pFieldType = getIntParam(pFieldObj[0].type);
                    pEmailField = getIntParam(pFieldObj[0].emailField);
                    pPhoneField = getIntParam(pFieldObj[0].phoneField);
                    pListType = getIntParam(pFieldObj[0].listType);
                    pCurrencyField = getIntParam(pFieldObj[0].currencyField);
                    pFieldSize = getIntParam(pFieldObj[0].size);

                    cFormFieldType = cFieldObj[0].formFieldType;
                    cFieldType = cFieldObj[0].type;
                    cEmailField = cFieldObj[0].emailField;
                    cPhoneField = cFieldObj[0].phoneField;
                    cListType = cFieldObj[0].listType;
                    cCurrencyField = cFieldObj[0].currencyField;
                    cFieldSize = getIntParam(cFieldObj[0].size);
                }

            } else {
                let mappedRecord = parentChildFieldMapList;
                pFieldObj = mappedRecord.filter((obj) => { return obj.parentFieldId === pFieldId && obj.childFieldId === cFieldId });

                if (isValidParam(pFieldObj) && pFieldObj.length > 0) {

                    pFormFieldType = getIntParam(pFieldObj[0].pFormFieldType);
                    pFieldType = getIntParam(pFieldObj[0].pType);
                    pEmailField = getIntParam(pFieldObj[0].pEmailField);
                    pPhoneField = getIntParam(pFieldObj[0].pPhoneField);
                    pListType = getIntParam(pFieldObj[0].pListType);
                    pCurrencyField = getIntParam(pFieldObj[0].pCurrencyField);
                    pFieldSize = getIntParam(pFieldObj[0].pSize);

                    cFormFieldType = pFieldObj[0].cFormFieldType;
                    cFieldType = pFieldObj[0].cType;
                    cEmailField = pFieldObj[0].cEmailField;
                    cPhoneField = pFieldObj[0].cPhoneField;
                    cListType = pFieldObj[0].cListType;
                    cCurrencyField = pFieldObj[0].cCurrencyField;
                    cFieldSize = getIntParam(pFieldObj[0].cSize);
                }
            }


            if (getBooleanParam(isCallFromAdd)) {
                if (isValidParam(pObjectId) && pObjectId <= 0) {
                    errorMsg = getLocalizedStrings().message.AUTO_FILL_VALUES.SELECT_PARENT_OBJECT;
                } else if (isValidParam(cObjectId) && cObjectId <= 0) {
                    errorMsg = getLocalizedStrings().message.AUTO_FILL_VALUES.SELECT_CHILD_OBJECT;
                } else if (isValidParam(pFieldId) && pFieldId <= 0) {
                    errorMsg = getLocalizedStrings().message.AUTO_FILL_VALUES.SELECT_PARENT_FIELD;
                } else if (isValidParam(cFieldId) && cFieldId <= 0) {
                    errorMsg = getLocalizedStrings().message.AUTO_FILL_VALUES.SELECT_CHILD_FIELD;
                } else {
                    mappingOk = true;
                }
            } else {
                mappingOk = true;
            }

            if (mappingOk) {
                if (pFormFieldType == cFormFieldType) {
                    if (pFormFieldType == constants.TEXT_FORM_FIELD) {
                        mappingOk = pEmailField == 1 && cPhoneField == 1 ? false : mappingOk;
                        mappingOk = pPhoneField == 1 && cEmailField == 1 ? false : mappingOk;
                    } else if (pFormFieldType == constants.LIST_FORM_FIELD) {
                        if (pListType == constants.REGULAR_LIST && (cListType == constants.USER_LIST || cListType == constants.GROUP_LIST
                            || cListType == constants.USER_AND_GROUP_LIST || cListType == constants.EXTERNAL_LIST || cListType == constants.MULTI_SELECT_LIST)) {
                            mappingOk = false;
                        }
                        if (pListType == constants.USER_LIST) {
                            if (cListType == constants.REGULAR_LIST || cListType == constants.GROUP_LIST || cListType == constants.USER_AND_GROUP_LIST
                                || cListType == constants.EXTERNAL_LIST || cListType == constants.MULTI_SELECT_LIST) {
                                mappingOk = false;
                            }
                        }
                        if (pListType == constants.GROUP_LIST) {
                            if (cListType == constants.REGULAR_LIST || cListType == constants.USER_LIST || cListType == constants.USER_AND_GROUP_LIST
                                || cListType == constants.EXTERNAL_LIST || cListType == constants.MULTI_SELECT_LIST) {
                                mappingOk = false;
                            }
                        }
                        if (pListType == constants.USER_AND_GROUP_LIST) {
                            if (cListType == constants.REGULAR_LIST || cListType == constants.USER_LIST || cListType == constants.GROUP_LIST
                                || cListType == constants.EXTERNAL_LIST || cListType == constants.MULTI_SELECT_LIST) {
                                mappingOk = false;
                            }
                        }
                        if (pListType == constants.EXTERNAL_LIST) {
                            if (cListType == constants.REGULAR_LIST || cListType == constants.USER_LIST || cListType == constants.GROUP_LIST
                                || cListType == constants.USER_AND_GROUP_LIST || cListType == constants.MULTI_SELECT_LIST) {
                                mappingOk = false;
                            }
                        }
                        if (pListType == constants.MULTI_SELECT_LIST) {
                            if (cListType == constants.REGULAR_LIST || cListType == constants.USER_LIST || cListType == constants.GROUP_LIST
                                || cListType == constants.USER_AND_GROUP_LIST || cListType == constants.EXTERNAL_LIST) {
                                mappingOk = false;
                            }
                        }
                    } else if (pFormFieldType == constants.FLOAT_FORM_FIELD) {
                        mappingOk = pCurrencyField == 1 && cCurrencyField != 1 ? false : mappingOk
                    }
                } else {
                    if (pFormFieldType == constants.TEXT_FORM_FIELD && (pEmailField != 1 || pPhoneField != 1)) {
                        mappingOk = cFormFieldType == constants.MEMO_FORM_FIELD ? true : false;
                    } else if (pFormFieldType == constants.INTEGER_FORM_FIELD) {
                        if (cFormFieldType == constants.FLOAT_FORM_FIELD || cFormFieldType == constants.MEMO_FORM_FIELD
                            || (cFormFieldType == constants.TEXT_FORM_FIELD && cEmailField != 1 && cPhoneField != 1 && cCurrencyField != 1)) {
                            mappingOk = true;
                        } else {
                            mappingOk = false;
                        }
                    } else if (pFormFieldType == constants.FLOAT_FORM_FIELD || pFormFieldType == constants.CURRENCY_FORM_FIELD || pFormFieldType == constants.LIST_FORM_FIELD
                        || pFormFieldType == constants.USERLIST_FORM_FIELD || pFormFieldType == constants.URL_FORM_FIELD || pFormFieldType == constants.EMAIL_FORM_FIELD
                        || pFormFieldType == constants.LOOKUP_FORM_FIELD || pFormFieldType == constants.LIST_FORM_FIELD_INTEGER_TYPE) {
                        if (cFormFieldType == constants.MEMO_FORM_FIELD || cFormFieldType == constants.TEXT_FORM_FIELD && (cEmailField != 1 && cPhoneField != 1 && cCurrencyField != 1)) {
                            mappingOk = true;
                        } else {
                            mappingOk = false;
                        }
                    } else {
                        mappingOk = false;
                    }
                }
            }
            if (isValidParam(pFieldSize) && isValidParam(cFieldSize) && mappingOk) {
                if (pFieldSize > cFieldSize) {
                    mappingOk = false;
                    errorMsg = getLocalizedStrings().message.AUTO_FILL_VALUES.CHILD_FIELD_SIZESHOULD_GREATER;
                }
            }
            if (!mappingOk) {
                if (errorMsg == '') {
                    let pObject = {};
                    pObject.formFieldType = pFormFieldType;
                    pObject.emailField = pEmailField;
                    pObject.phoneField = pPhoneField;
                    pObject.listType = pListType;
                    pObject.currencyField = pCurrencyField;

                    let cObject = {};
                    cObject.formFieldType = cFormFieldType;
                    cObject.emailField = cEmailField;
                    cObject.phoneField = cPhoneField;
                    cObject.listType = cListType;
                    cObject.currencyField = cCurrencyField;

                    let pFieldTypeName = getFielsTypeSuffixText(pObject);
                    let cFieldTypeName = getFielsTypeSuffixText(cObject);
                    if (isCallFromAdd) {
                        errorMsg = getLocalizedStrings().message.AUTO_FILL_VALUES.MAPPING_FROM + pFieldTypeName + getLocalizedStrings().message.AUTO_FILL_VALUES.MAPPING_TO + cFieldTypeName + getLocalizedStrings().message.AUTO_FILL_VALUES.NO_AVAILBLE_FIELDS_FOR_MAPPING;
                    } else {
                        errorMsg = getLocalizedStrings().message.AUTO_FILL_VALUES.MAPPING_FROM + pFieldTypeName + getLocalizedStrings().message.AUTO_FILL_VALUES.MAPPING_TO + cFieldTypeName + getLocalizedStrings().message.AUTO_FILL_VALUES.MAPPING_NOT_POSSIBLE;
                    }
                }

            }
        } catch (error) {
            console.error("Error in 'autoFillValues.js --> isValidField()':" + error);
        }
        mappingObj.isMappingOk = mappingOk;
        mappingObj.errorMsg = errorMsg;
        return mappingObj;
    }

    const mapFields = () => {
        let pFieldId = parentObjectFieldId;
        let cFieldId = childObjectFieldId;
        let validateMap = isValidField(pFieldId, cFieldId, true);
        if (!validateMap.isMappingOk) {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, validateMap.errorMsg, null);
        } else {
            let fieldMapList = parentChildFieldMapList;

            let pFieldLiist = parentFieldList;
            let cFieldLiist = childFieldList;
            let pFieldDrpdn = parentFieldDrpdn;
            let cFieldDrpdn = childFieldDrpdn;

            let pFieldObj = pFieldLiist.filter((obj) => { return obj.id === pFieldId });
            let cFieldObj = cFieldLiist.filter((obj) => { return obj.id === cFieldId });

            let newMapObject = {};
            newMapObject.parentObjectLabel = parentObjectName;
            newMapObject.parentFieldLabel = pFieldObj[0].value;
            newMapObject.childObjectLabel = childObjectName;
            newMapObject.childFieldLabel = cFieldObj[0].value;

            newMapObject.pSystemField = pFieldObj[0].systemField;
            newMapObject.cSystemField = cFieldObj[0].systemField;
            newMapObject.pType = pFieldObj[0].type;
            newMapObject.pFormFieldType = pFieldObj[0].formFieldType;
            newMapObject.cFormFieldType = cFieldObj[0].formFieldType;
            newMapObject.pEmailField = pFieldObj[0].emailField;
            newMapObject.cEmailField = cFieldObj[0].emailField;
            newMapObject.pPhoneField = pFieldObj[0].phoneField;
            newMapObject.cPhoneField = cFieldObj[0].phoneField;
            newMapObject.pListType = pFieldObj[0].listType;
            newMapObject.cListType = cFieldObj[0].listType;
            newMapObject.pCurrencyField = pFieldObj[0].currencyField;
            newMapObject.cCurrencyField = cFieldObj[0].currencyField;
            newMapObject.pSize = pFieldObj[0].size;
            newMapObject.cSize = cFieldObj[0].size;
            newMapObject.childFieldId = cFieldObj[0].id;
            newMapObject.parentFieldId = pFieldObj[0].id;
            fieldMapList.unshift(newMapObject);

            pFieldLiist = pFieldLiist.filter((pObj) => { return pObj.id !== pFieldId });
            cFieldLiist = cFieldLiist.filter((cObj) => { return cObj.id !== cFieldId });
            pFieldDrpdn = pFieldDrpdn.filter((pObj) => { return pObj.id !== pFieldId });
            cFieldDrpdn = cFieldDrpdn.filter((cObj) => { return cObj.id !== cFieldId });

            setParentChildFieldMapList(fieldMapList);
            setParentFieldList(pFieldLiist);
            setChildFieldList(cFieldLiist);
            setParentFieldDrpdn(pFieldDrpdn);
            setChildFieldDrpdn(cFieldDrpdn);
            setParentObjectFieldId(-1);
            setChildObjectFieldId(-1)

        }
    }


    const handleDeleteMap = (pFieldId, cFieldId) => {
        let fieldMapList = parentChildFieldMapList;
        let pFieldList = parentFieldList;
        let cFieldList = childFieldList;
        let pFieldDrpdn = parentFieldDrpdn;
        let cFieldDrpdn = childFieldDrpdn;

        let mapObj = fieldMapList.filter((mapObj) => { return mapObj.parentFieldId == pFieldId && mapObj.childFieldId == cFieldId });
        let pFieldObj = {};
        let cFieldObj = {};


        pFieldObj.currencyField = mapObj[0].pCurrencyField;
        pFieldObj.emailField = mapObj[0].pEmailField;
        pFieldObj.formFieldType = mapObj[0].pFormFieldType;
        pFieldObj.id = mapObj[0].parentFieldId;
        pFieldObj.listType = mapObj[0].pListType;
        pFieldObj.phoneField = mapObj[0].pPhoneField;
        pFieldObj.size = mapObj[0].pSize;
        pFieldObj.systemField = mapObj[0].pSystemField;
        pFieldObj.type = mapObj[0].pType;
        pFieldObj.value = mapObj[0].parentFieldLabel;

        pFieldList.push(pFieldObj);
        pFieldDrpdn = pFieldDrpdn.filter((obj) => { return obj.id !== -1 });

        pFieldDrpdn.push({ id: mapObj[0].parentFieldId, label: mapObj[0].parentFieldLabel + ' (' + getFielsTypeSuffixText(pFieldObj) + ')' })
        sortArrayObjectByProperty(pFieldDrpdn, 'label');
        pFieldDrpdn.unshift({ id: -1, label: parentObjectName + ' fields' });

        cFieldObj.currencyField = mapObj[0].cCurrencyField;
        cFieldObj.emailField = mapObj[0].cEmailField;
        cFieldObj.formFieldType = mapObj[0].cFormFieldType;
        cFieldObj.id = mapObj[0].childFieldId;
        cFieldObj.listType = mapObj[0].cListType;
        cFieldObj.phoneField = mapObj[0].cPhoneField;
        cFieldObj.size = mapObj[0].cSize;
        cFieldObj.systemField = mapObj[0].cSystemField;
        cFieldObj.type = mapObj[0].cType;
        cFieldObj.value = mapObj[0].childFieldLabel;

        cFieldList.push(cFieldObj);
        cFieldDrpdn = cFieldDrpdn.filter((obj) => { return obj.id !== -1 });

        cFieldDrpdn.push({ id: mapObj[0].childFieldId, label: mapObj[0].childFieldLabel + ' (' + getFielsTypeSuffixText(cFieldObj) + ')' });
        sortArrayObjectByProperty(cFieldDrpdn, 'label');
        cFieldDrpdn.unshift({ id: -1, label: childObjectName + ' fields' });

        let newMapList = fieldMapList.filter((obj) => { return obj.parentFieldId !== pFieldId && obj.childFieldId !== cFieldId });
        setParentChildFieldMapList(newMapList);
        setParentFieldList(pFieldList);
        setChildFieldList(cFieldList);
        setParentFieldDrpdn(pFieldDrpdn);
        setChildFieldDrpdn(cFieldDrpdn);
    }

    const getMapElement = (pObjectLabel, pFieldLabel, cObjectLabel, cFieldLabel, pSystemField, cSystemField, pFieldId, cFieldId, isError) => {
        let rowStyle = { marginLeft: 0, marginTop: 30, background: '#eee', width: '80%'};
        if (isError) {
            rowStyle = { marginLeft: 0, marginTop: 30, background: '#eee', width: '80%', border: '1px solid red', borderColor: 'red' };
        }
        return (
            <div  style={{ width: '100%' }} id={'id-groupList-' + pFieldId + '-' + cFieldId} key={'key-groupList-' + pFieldId + '-' + cFieldId}>
                <div className='row' style={rowStyle} id={'id-row-' + pFieldId + '-' + cFieldId} key={'key-row-' + pFieldId + '-' + cFieldId}>
                    <div className='col-sm-4 col-xs-4 col-md-4' id={'id-col-1-' + pFieldId + '-' + cFieldId} key={'key-col-1-' + pFieldId + '-' + cFieldId} >
                        <span style={{ fontSize: 13, color: '#9A9A9A' }} title={getStringParam(pObjectLabel)} key={'key-col-1-span-' + pFieldId + '-' + cFieldId}> {getStringParam(pObjectLabel)}   </span>
                        <h5 style={{ fontSize: '14px', fontWeight:'normal' }} title={pFieldLabel}> {pFieldLabel}</h5>
                    </div>
                    <div className='col-sm-3 col-xs-1 col-md-3' style={{ fontSize: '1.4em', marginTop: '32px' }} id={'id-col-2-' + pFieldId + '-' + cFieldId} key={'key-col-2-' + pFieldId + '-' + cFieldId}> <i className="fas fa-arrow-right" style={{ lineHeight: '0px' }} key={'key-col-2-arrow-' + pFieldId + '-' + cFieldId}></i></div>
                    <div className='col-sm-4 col-xs-4 col-md-4' id={'col-3-' + pFieldId + '-' + cFieldId} >
                        <span style={{ fontSize: 13, color: '#9A9A9A' }} title={getStringParam(cObjectLabel)} > {getStringParam(cObjectLabel)}   </span>
                        <h5 style={{ fontSize: '14px',fontWeight:'normal' }} title={cFieldLabel}> {cFieldLabel}</h5>
                    </div>
                    {pSystemField != 1 && cSystemField != 1 &&
                        <div className='col-sm-1 col-xs-1' style={{ display: 'inline-block', marginInlineEnd: 'relative', height: 35 }} id={'col-4-' + pFieldId + '-' + cFieldId}>
                            <i className={"material-icons"} style={{ fontSize: '25px', color: '#717171', cursor: 'pointer', paddingTop: '18px', marginLeft: '45px' }} onClick={() => handleDeleteMap(pFieldId, cFieldId)} title={getLocalizedStrings().label.COMMON.DELETE} >delete_outline</i>
                        </div>
                    }
                </div>
            </div>
        )
    }

    const getFieldList = () => {
        let tempParentObjectId = parentObjectId;
        let tempChildObjectId = childObjectId;
        let fieldElement = null;
        let language = getLocalizedStrings()._language;
        let sfWidth = 200;
        if (language = 'de-DE') {
            sfWidth = 229;
        }
        if (tempParentObjectId !== '' && tempChildObjectId !== '') {
            let parentObjectName = getObjectName(tableList, tempParentObjectId);
            let childObjectName = getObjectName(linkedChildTableList, tempChildObjectId);
            if (isValidParam(parentObjectName) && parentObjectName !== '' && isValidParam(childObjectName) && childObjectName !== '') {
                let label = getLocalizedStrings().message.AUTO_FILL_VALUES.MAP_FIELDS_TO_AUTOFILL + childObjectName + getLocalizedStrings().message.AUTO_FILL_VALUES.MAP_FIELDS_TO_AUTOFILL_FROM + parentObjectName;

                fieldElement = <div>
                    <div className='row' style={{ height: '20px', marginTop: 30, paddingLeft: 15, }}>
                        <div className='col-sm-12 col-xs-12 col-md-12'>
                            <span>{label}</span>
                        </div>
                    </div>
                    <div className='row' style={{ height: '35px', marginTop: 10, marginLeft:'-15px' }}>
                        <div className='col-sm-4 col-md-3' >
                            {parentFieldDrpdn.length > 0 && <FormControl style={{ width: '100%', height: '30px' }} className="test" noValidate autoComplete="off">
                                <Autocomplete
                                    openOnFocus
                                    id="disabled-options-sfauto-fill-values"
                                    options={parentFieldDrpdn}
                                    value={parentFieldDrpdn.find(v => v.id === parentObjectFieldId)}
                                    onChange={(event, value, index) => onFieldChange('parentObjectFieldId', event, value, index)}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable={true}
                                    sx={{ width: 198 }}
                                    renderInput={(params) => <TextField {...params} label={getLocalizedStrings().label.AUTO_FILL_VALUES.PARENT_FIELDS} variant="outlined" margin="dense" size='small' className={"sf-fields-bg"} />}
                                />
                            </FormControl>
                            }


                        </div>
                        <div className='col-sm-1 col-md-1 ' style={{ fontSize: '1.4em', marginTop: '40px', width: '30px', paddingRight: '30px', marginRight: '30px' }}> <i className="fas fa-arrow-right" style={{ lineHeight: '0px' }}></i></div>
                        <div className='col-sm-4 col-md-3 ' >
                         {childFieldDrpdn.length > 0 && <FormControl style={{ width: '100%', height: '30px' }} className="test" noValidate autoComplete="off">
                                <Autocomplete
                                    openOnFocus
                                    id="disabled-options-sfauto-fill-values"
                                    options={childFieldDrpdn}
                                    value={childFieldDrpdn.find(v => v.id === childObjectFieldId)}
                                    onChange={(event, value, index) => onFieldChange('childObjectFieldId', event, value, index)}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable={true}
                                    sx={{ width: 198 }}
                                    renderInput={(params) => <TextField {...params} label={getLocalizedStrings().label.AUTO_FILL_VALUES.CHILD_FIELDS} variant="outlined" margin="dense" size='small' className={"sf-fields-bg"} />}
                                />
                            </FormControl>
                            }

                        </div>
                        <div className='col-xs-1 col-md-2 col-sm-2 ' style={{ marginTop: '14px' }}>
                            <Button
                                key='Add'
                                onClick={()=> mapFields()}
                                style={{ ...styles.secondaryButton, }}
                            >{getLocalizedStrings().label.COMMON.ADD}</Button>
                        </div>
                    </div>
                    <div className='row' style={{ height: '20px' }}>
                        <div className='col-sm-12 col-md-12 col-xs-12'>
                        </div>
                    </div>
                </div>
            }
        }
        return fieldElement;
    }

    const getFielsTypeSuffixText = (obj) => {
        let formFieldType = getStringParam(obj.formFieldType);
        let emailField = getIntParam(obj.emailField);
        let phoneField = getIntParam(obj.phoneField);
        let listType = getStringParam(obj.listType);
        let currencyField = getIntParam(obj.currencyField);
        let suffix = '';
        
            if (formFieldType == constants.TEXT_FORM_FIELD) {
                suffix = 'Text'
                if (emailField == 1) {
                    suffix = 'Email';
                }
                if (phoneField == 1) {
                    suffix = 'Phone';
                }
            } else if (formFieldType == constants.LIST_FORM_FIELD) {
                suffix = 'List';
                if (listType == constants.REGULAR_LIST) {
                    suffix = 'Regular list';
                } else if (listType == constants.USER_LIST) {
                    suffix = 'User list';
                } else if (listType == constants.GROUP_LIST) {
                    suffix = 'Group list';
                } else if (listType == constants.USER_AND_GROUP_LIST) {
                    suffix = 'User & Group list';
                } else if (listType == constants.EXTERNAL_LIST) {
                    suffix = 'External list';
                }
            } else if (formFieldType == constants.FLOAT_FORM_FIELD) {
                suffix = 'Float';
                if (currencyField == 1) {
                    suffix = 'Currency';
                }
            } else {
                suffix = formFieldTypeList[formFieldType];
            }
       
        return suffix;
    }
    const getActionsButtons = () => {
        return (
            <div className="col-sm-12">
                <div className="row">
                    <div style={{ overflow: 'hidden', float: 'right' }}>
                        <Button
                            key='save'
                            onClick={() => saveMap()}
                            style={{ ...styles.primaryButton, float: 'right', marginRight: '6px', verticalAlign: 'top' }}
                        >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                    </div>
                </div>
            </div>
        )
    }

    const isValidObjectField = () => {
        let errorMsg = null;
        let isvalid = true;
        let validnObj = {};
        let pObjectId = getIntParam(parentObjectId);
        let cObjectId = getIntParam(childObjectId);

        if (isValidParam(pObjectId) && pObjectId <= 0) {
            errorMsg = getLocalizedStrings().message.AUTO_FILL_VALUES.SELECT_PARENT_OBJECT;
            isvalid = false;
        } else if (isValidParam(cObjectId) && cObjectId <= 0) {
            errorMsg = getLocalizedStrings().message.AUTO_FILL_VALUES.SELECT_CHILD_OBJECT;
            isvalid = false;
        }
        if (initialMapCount === 0 && parentChildFieldMapList.length === 0) {
            errorMsg = getLocalizedStrings().message.AUTO_FILL_VALUES.NO_AVAILABLE_PARENT_CHILD_MAPPING;
            isvalid = false;
        }
        validnObj.isvalid = isvalid;
        validnObj.errorMsg = errorMsg;
        return validnObj;
    }

    const isMappedError = () => {
        let mappedElement = parentChildFieldMapList;
        let isMappedError = false;
        let errorMsg = '';
        mappedElement.forEach((obj, index) => {
            let validateMap = isValidField(obj.parentFieldId, obj.childFieldId, false);
            let isError = validateMap.isMappingOk ? false : true;
            obj.isError = isError;
            isMappedError = isError ? true : isMappedError;
            errorMsg = isError && errorMsg === '' ? validateMap.errorMsg : errorMsg;
        });
        if (isMappedError && errorMsg !== '') {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, errorMsg, null);
        }
        setParentChildFieldMapList(mappedElement);
        return isMappedError;
    }

    const saveMap = () => {
        let url = null;
        let params = {};
        let validnCheck = isValidObjectField();
        if (validnCheck.isvalid) {
            if (!isMappedError()) {
                params.parentTableId = parentObjectId;
                params.childTableId = childObjectId;
                params.mappedFieldList = parentChildFieldMapList;

                url = endPoints.AUTOFILL.SAVE_MAPPINGS;
                let promise = Promise.resolve(HTTPClient.post(url, params));
                promise.then((response) => {
                    if (isValidParam(response) && response.status === 0) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.AUTO_FILL_VALUES.FIELD_MAPPING_DONE_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        processFieldMapping(response.data);
                    }
                })
            }
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, validnCheck.errorMsg, null);
        }
    }

    const objectDropDownList = () => {
        var element = <div>
            <div className="container">
                <div className="section">
                    <div className='row'style={{marginLeft:'-15px'}}>
                    <div className='col-sm-4' style={{marginLeft:'-15px'}} >
                            {tableList.length > 0 && <FormControl style={{ width: '100%', height: '30px' }} className="test" noValidate autoComplete="off">
                                <Autocomplete
                                    openOnFocus
                                    id="disabled-options-sfauto-fill-values"
                                    options={tableList}
                                    value={tableList.find(v => v.id === parentObjectId)}
                                    onChange={(event, value, index) => onObjectChange('parentObjectId', event, value, index)}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable={true}
                                    sx={{ width: 198 }}
                                    renderInput={(params) => <TextField {...params} label={getLocalizedStrings().label.ROLL_UP.PARENT_OBJECT_NAME} variant="outlined" margin="dense" size='small' className={"sf-fields-bg"} />}
                                />
                            </FormControl>
                            }
                        </div>
                        <div className='col-sm-1 col-md-1' style={{ fontSize: '1.4em', marginTop: '26px', width: '30px', paddingRight: '30px', marginRight: '30px' }}> <i className="fas fa-arrow-right" style={{ lineHeight: '0px' }}></i></div>
                        <div className='col-sm-4'>
                            {linkedChildTableList.length > 0 && <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <Autocomplete
                                    openOnFocus
                                    id="disabled-options-sf-auto-fill-values"
                                    options={linkedChildTableList}
                                    value={linkedChildTableList.find(v => v.id === childObjectId)}
                                    onChange={(event, value, index) => onObjectChange('childObjectId', event, value, index)}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable={true}
                                    sx={{ width: 198 }}

                                    renderInput={(params) => <TextField
                                        {...params}
                                        label={getLocalizedStrings().label.ROLL_UP.CHILD_OBJECT_NAME} variant="outlined"
                                        margin="dense" size='small' className={"sf-fields-bg"} height='medium' />}
                                />
                            </FormControl>
                            }
                        </div>
                        <div className='col-sm-2 col-md-1 col-xs-1' style={{ marginTop: '13px'}}>
                        <Button
                            key='Go'
                            style={{ ...styles.primaryButton, display: 'flex', alignItems: 'center' }}
                            onClick={(event) => getAllFieldMappings(event)}
                        >{getLocalizedStrings().label.REPORT.GO}</Button>
                    </div>
                    </div>
                </div>
            </div>

        </div>
        return element;
    }

    let fieldListElement = null;
    let actionButton = null;
    let FieldMapedElement = null;
    let objectmapElement = objectDropDownList();
    if (parentFieldList !== null && childFieldList !== null) {
        fieldListElement = getFieldList();
        FieldMapedElement = getFieldMap();
        actionButton = getActionsButtons();
    }
    let language = getLocalizedStrings()._language;
    let sfWidth = 200;
    if (language = 'de-DE') {
        sfWidth = 229;
    }

    return (
        <div>
            <div style={{ marginLeft: '30px', marginRight: '30px', width: '80%', marginTop: '3px' }}>
                {actionButton !== null &&
                    <div className='row' style={{ marginLeft: 0, marginBottom: 25, width: '82%' }}>
                        {actionButton}
                    </div>
                }
                <div className='row' style={{height: '35px', }}>
                    <div className='col-sm-9'>
                        {objectmapElement}
                    </div>
                  
                </div>
                {fieldListElement}
                {FieldMapedElement}
                <div className='row' style={{ marginLeft: 0, marginTop: 25, width: '82%' }}>
                    {actionButton}
                </div>
            </div>
        </div>
    )
}

export default AutoFillValues;

