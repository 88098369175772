import React, {useEffect, useState} from 'react';
import {deleteAssignmentRule, getAssignmentRules} from '../../../services/actions/assignmentRuleActions';
import {OBJECT_TABLEID_MAP} from '../../../services/constants/constants';
import {styles} from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import {getArrayParam, getIntParam, getObjectParam, getStringParam} from '../../../services/helper/parameterVerifier';
import SFDataTable from '../components/sfDataTable';
import {getLocalizedStrings} from '../../../services/constants/MultiLingual';
import {getUserNames} from '../../../services/helper/common';
import Button from '@mui/material/Button';
import * as sfDialogs from '../components/sfDialogs';

const AssignmentRuleList = ({data, loadAssignmentRule, addRule, refreshListData}) => {

    const [isMounted, setIsMounted] = useState(false);
    const [ruleResults, setRuleResults] = useState({
        fields: [],
        records: [],
        rowSort: {},
    });
    const [selectedRecordIds, setSelectedRecordIds] = useState([]);
    const [refreshList, setRefreshList] = useState(false);

    useEffect(() => {
        fetchAssignmentRules();
    }, []);

    useEffect(() => {
        if (refreshListData !== refreshList) {
            fetchAssignmentRules();
            setRefreshList(refreshListData);
        }
    }, [refreshListData]);

    const fetchAssignmentRules = (action) => {
        try {
            let obj = data.table;
            let paramObj = {table_id: OBJECT_TABLEID_MAP[obj]};

            const response = Promise.resolve(getAssignmentRules(paramObj));
            response.then((response) => {
                let fields = getArrayParam(response.fields);
                let results = getArrayParam(response.results);
                let tempRuleResults = {...ruleResults};
                tempRuleResults.fields = fields;
                tempRuleResults.records = results;

                if (action && action === 'delete') {
                    if (results.length === 0) {
                        addRule('delete-no-records');
                    } else {
                        addRule('delete');
                    }
                }
                setRuleResults(tempRuleResults);
                setIsMounted(true);

            });
        } catch (error) {
            console.log("Error in 'AssignmentRuleList.js -> fetchAssignmentRules()':" + error);
        }
    }

    const getData = (recordObj) => {
        try {
            let records = getArrayParam(recordObj.records);
            let fields = getArrayParam(recordObj.fields);
            let userIds = '';
            let userNames = '';
            let recordArr = [];
            let obj = null;
            if (records.length > 0) {
                records.forEach((record) => {
                    obj = {};
                    obj.id = record.id;
                    obj.name = record.name;
                    obj.status = record.status;
                    userIds = getStringParam(record.assignTo).trim();
                    userNames = getUserNames(userIds);
                    obj.assignTo = userNames;
                    recordArr.push(obj);
                });
                return (<div style={{paddingBottom: '8px', paddingTop: '8px'}} key={records.length}>
                    <SFDataTable
                        columns={fields}
                        rowSort={ruleResults.rowSort}
                        rows={recordArr}
                        customEvent={customEvent}
                        rowSelection={{
                            showCheckbox: true,
                            onRowsSelected: onRowsSelected,
                        }}
                        style={{maxHeight: 'auto', overflowY: 'hidden'}}
                    />
                </div>);
            }
        } catch (error) {
            console.log("Error in 'AssignmentRuleList.js -> getData()':" + error);
        }

    }

    function customEvent(eventType, column, row) {
        try {
            let value = 0;
            row = getObjectParam(row);

            value = getIntParam(row['id']);

            if (value > 0) {
                editAssignmentRule(value);
            }
        } catch (error) {
            console.error("Error in 'AssignmentRuleList.js -> customEvent()':" + error);
        }
    }

    function onRowsSelected(selectedRow) {
        try {
            let tempSelectedRecordIds = selectedRecordIds;
            let selected_rows = getArrayParam(selectedRow);
            let idArr = [];
            if (selected_rows.length > 0) {

                selected_rows.forEach((row) => {
                    idArr.push(row.id);

                });
                tempSelectedRecordIds = idArr;

            } else {
                tempSelectedRecordIds = idArr;
            }
            setSelectedRecordIds(tempSelectedRecordIds);

        } catch (error) {
            console.error("Error in 'AssignmentRuleList.js -> onRowsSelected()':" + error);
        }
    }

    function editAssignmentRule(id) {
        loadAssignmentRule(id);
    }

    const deleteRules = () => {
        try {
            let ids = getArrayParam(selectedRecordIds);
            if (ids.length > 0) {
                let confirmMsg = getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;
                if (ids.length === 1) {
                    confirmMsg = ids.length + ' ' + getLocalizedStrings().message.BATCH_ACTION.RECORD + confirmMsg;
                } else if (ids.length > 1) {
                    confirmMsg = ids.length + ' ' + getLocalizedStrings().message.BATCH_ACTION.RECORDS + confirmMsg;
                }
                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, confirmMsg, deleteRulesOnConfirm.bind(ids), null);
            } else {
                let msg = getLocalizedStrings().message.COMMON.SELECT_RECORD_FOR_DELETE;
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null)
            }

        } catch (error) {
            console.error("Error in 'AssignmentRuleList.js -> deleteRules()':" + error);
        }

    }

    const deleteRulesOnConfirm = (ids) => {
        let paramObj = {id: ids};
        deleteRecord(paramObj);
    }

    const deleteRecord = (paramObj) => {
        try {
            let tempSelectedRecordIds = selectedRecordIds;
            const response = Promise.resolve(deleteAssignmentRule(paramObj));
            response.then((response) => {

                if (response.status === 0) {
                    fetchAssignmentRules('delete');
                    tempSelectedRecordIds = [];
                }
                setSelectedRecordIds(tempSelectedRecordIds);
            });
        } catch (error) {
            console.error("Error in 'AssignmentRuleList.js -> deleteRecord()':" + error);
        }
    }

    const handleAddRule = () => {
        try {
            const records = getArrayParam(ruleResults.records);
            let name = "";
            if (records.length > 0) {
                name = "add-with-records";
            } else {
                name = "add-no-records";
            }
            addRule(name);
        } catch (error) {
            console.error("Error in 'AssignmentRuleList.js -> handleAddRule()':" + error);
        }
    }


    let msg = getLocalizedStrings().message.ASSIGNMENT_RULES.NO_ASSIGNMENT_RULES;
    let records = getArrayParam(ruleResults.records);
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2;
    return (
        <div key='rule-div'>
            <div style={{textAlign: 'right'}}>
                <Button
                    key='add'
                    onClick={() => handleAddRule()}
                    style={{
                        ...styles.primaryButton,
                        marginLeft: '6px',
                        marginRight: '0px'
                    }}
                >{getLocalizedStrings().label.COMMON.ADD}</Button>
                {records.length > 0 &&
                    <Button
                        key='delete'
                        onClick={() => deleteRules()}
                        style={{
                            ...styles.secondaryButton,
                            marginLeft: '6px',
                            marginRight: '0px'
                        }}
                    > {getLocalizedStrings().label.COMMON.DELETE}</Button>
                }
            </div>

            {isMounted ?
                (records.length > 0) ?
                    getData(ruleResults) :
                    (
                        <div style={{textAlign: 'center', paddingTop: '100px'}}>
                            <label style={{color: '#777', fontSize: '25px'}}>{msg}</label>
                        </div>
                    )
                :
                (
                    <div style={{width: '100%', height: contentHeight}}>
                        <div className="asset-loaderh" style={{paddingTop: top, paddingLeft: '48%'}}>
                            <div style={{...styles.assetLoaderContainer, height: 50, width: 50, padding: 7}}>
                                <ShowCircularProgress size={30} style={{marginTop: '3', marginLeft: '3'}}/>
                            </div>
                        </div>
                    </div>
                )
            }

        </div>
    );
}
export default AssignmentRuleList;
