
import React from 'react';
import PropTypes from 'prop-types';
import { render } from 'react-dom';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import {Menu,  Select, MenuItem,InputLabel} from '@mui/material';

import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { isValidParam, isInvalidParam, isUrl } from '../../../services/helper/parameterVerifier';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { addNode } from '../../../services/actions/automationCampaignActions';
import { removeError } from '../../../services/helper/automationUtils';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';

function mapStateToProps(state) {
    return {
        automationCampaign: state.automationCampaign
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addNode: (arrayNodes) => {
            dispatch(addNode(arrayNodes));
        },
        getAppDialog: (isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom) => {
            dispatch(getAppDialog(isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom));
        },
    }
}

class AutomationSMSReply extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            replyText: ""
        }
    }

    componentDidMount = () => {
        let data = this.props.data;
        if (isValidParam(data)) {
            let detailsArray = null;
            let replyText = this.state.replyText;
            if (data.hasOwnProperty('details')) {
                detailsArray = data.details[0];
                if (isValidParam(detailsArray)) {
                    if (detailsArray.hasOwnProperty('replyText')) {
                        replyText = detailsArray.replyText;
                        this.setState({
                            replyText: replyText
                        });
                    }
                }
            }
        }
    }

    setReplyText = (event, value) => {
        if (isValidParam(value)) {
            this.setState({
                replyText: value
            })
        }
    }

    saveReplyText = () => {
        let nodeObject = this.props.data;
        let replyText = this.state.replyText;
        let dialogData = null;

        if (isValidParam(nodeObject)) {
            let detailsArray = null;
            if (nodeObject.hasOwnProperty('details')) {
                detailsArray = nodeObject.details[0];
                if (isValidParam(detailsArray) && detailsArray.hasOwnProperty('id')) {
                    detailsArray.replyText = replyText
                } else {
                    detailsArray = {
                        id: 0,
                        replyText: replyText
                    }
                    nodeObject.details.push(detailsArray);
                }
                nodeObject = removeError(nodeObject);
                this.props.addNode(this.props.automationCampaign.data);
                this.props.closeDrawer();
            } else {
                if (replyText !== null && replyText !== undefined && replyText !== "") {
                    detailsArray = [];
                    let detailObj = {
                        id: 0,
                        replyText: replyText
                    }
                    detailsArray.push(detailObj);
                    nodeObject.details = detailsArray;
                }
                nodeObject = removeError(nodeObject);
                this.props.addNode(this.props.automationCampaign.data);
                this.props.closeDrawer();
            }

        }
    }

    openDialog = (dialogData) => {
        this.props.getAppDialog(true, constants.ALERT_DIALOG, getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, null, dialogData, constants.AUTOMATION_DESIGNER_OBJECT);
    }

    render() {
        let campaignStatus = this.props.automationCampaign.data.status;
        let nodeObject = this.props.data;
        let floatingLabelText = "Enter SMS Reply Text (0ptional).";
        let actionName = null;

        return (
            <div style={{ paddingTop: '5px', paddingLeft: '20px', paddingRight: '20px' }}>

                <div style={{ paddingBottom: '6px' }}>
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            id="replyText"
                            value={this.state.replyText}
                            inputprops={{maxlength: '256' }}
                            onChange={this.setReplyText}
                            label={floatingLabelText}
                            InputLabelProps={{
                                style: {
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '96%',
                                }
                            }}
                            autoComplete="new-password"
                            className={"sf-fields-bg"}
                        />
                    </FormControl>
                </div>

                {
                    (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                    <div style={{ paddingTop: '10px' }}>
                        <Button
                            style={styles.listViewPrimaryButton}
                            onClick={this.saveReplyText}
                        >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                    </div>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutomationSMSReply);
