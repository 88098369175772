import * as ParameterVerifier from '..//helper/parameterVerifier';
import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';
import { actions } from '..//constants/actions';

function saveAssignmentRule(param) {
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(param)) {
             promise = Promise.resolve(HTTPClient.post(endPoints.ASSIGNMENT_RULE.SAVE, param)); 
        }
    } catch (error) {
        console.error("Error in 'assignmentRuleActions.js -> saveAssignmentRule()':" + error);
    }
    return promise;
}

function editAssignmentRule(id) {
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(id) && id > 0) {
             promise = Promise.resolve(HTTPClient.get(endPoints.ASSIGNMENT_RULE.EDIT + id, {})); 
        }
    } catch (error) {
        console.error("Error in 'assignmentRuleActions.js -> editAssignmentRule()':" + error);
    }
    return promise;
}

function isAssignRuleExists(param) {
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(param)) {
             promise = Promise.resolve(HTTPClient.get(endPoints.ASSIGNMENT_RULE.IS_RULE_EXISTS, param)); 
        }
    } catch (error) {
        console.error("Error in 'assignmentRuleActions.js -> isAssignRuleExists()':" + error);
    }
    return promise;
}

function setMounted(isMounted) {
    return {
        type: actions.ASSIGN_LISTVIEW_SET_MOUNTED,
        payload: isMounted
    };
}

function getAssignmentRules(param) {
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(param)) {
             promise = Promise.resolve(HTTPClient.get(endPoints.ASSIGNMENT_RULE.GET_RULES, param)); 
        }
    } catch (error) {
        console.error("Error in 'assignmentRuleActions.js -> getAssignmentRules()':" + error);
    }
    return promise;
}

function deleteAssignmentRule(param) {
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(param)) {
             promise = Promise.resolve(HTTPClient.post(endPoints.ASSIGNMENT_RULE.DELETE, param)); 
        }
    } catch (error) {
        console.error("Error in 'assignmentRuleActions.js -> deleteAssignmentRule()':" + error);
    }
    return promise;
}

export {
    saveAssignmentRule,
    editAssignmentRule,
    isAssignRuleExists,
    setMounted,
    getAssignmentRules,
    deleteAssignmentRule
}