import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery';
import { useDrop } from 'react-dnd';
import filter from 'lodash/filter';
import concat from 'lodash/concat';
import moment from 'moment';
import Card from './DraggableCard';
import { CARD_HEIGHT, CARD_MARGIN, constants, OFFSET_HEIGHT } from '../../../services/constants/constants';
import { columnViewContactUpdate, columnViewTaskUpdate, columnViewOpportunitiesUpdate, DEFAULT_PAGE_SIZE, getListsData, moveCard, refreshColumnView, updateColumnViewScrollPosition, moveTaskCard, columnViewTaskListUpdate, columnViewTaskOpportunitiesUpdate } from '../../../services/actions/listsActions';
import { getArrayParam, getIntParam, getObjectParam, getStringParam, isValidParam } from '../../../services/helper/parameterVerifier';
import { getActiveTab, getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { dateTimeFormat, makeTiteleCase, timeZone } from '../../../services/helper/utils';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getObjectLabelByObject, hasAccessPermission } from '../../../services/helper/common';
import { styles } from '../../../services/constants/styles';
import { getAppCustomDrawer, getAppDialog } from '../../../services/actions/appContainerActions';
import * as sfDialogs from '../components/sfDialogs';
import _ from 'lodash';
import { ItemTypes } from "../components/ItemTypes";


const Cards = ({ cards, object, x, id, columnName, startScrolling, stopScrolling, isScrolling, reportBy, sortType, sortBy, fields, selectedFields, cardType, totalCardsCount, canMove }) => {

	const componentRef = useRef();

	const dispatch = useDispatch();

	let query_name = useSelector((state) => state.lists.queryName);
	let query_type = useSelector((state) => state.lists.queryType);
	let group_by_field_name = useSelector((state) => state.lists.groupByFieldName);
	let lists = useSelector((state) => object === constants.TASKS ? state.lists.taskLists : state.lists.lists);
	let scrollPosition = useSelector((state) => state.lists.scrollPosition);
	let app = useSelector((state) => state.app);
	const appContainer = useSelector((state) => state.appContainer);
	const [placeholderIndex, setPlaceholderIndex] = useState(0);
	const [lastScrollPos, setLastScrollPos] = useState(0);
	const [startIndex, setStartIndex] = useState(0);
	const [cardItems, setCardItems] = useState([]);
	const [dataAdded, setDataAdded] = useState(false);

	const [{ canDrop, isOver }, drop] = useDrop({
		accept: ItemTypes.CARD,
		drop: (item, monitor) => {
			let hasPermission = hasAccessPermission(object, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
			if (hasPermission) {
				document.getElementById(monitor.getItem().id).style.display = 'block';
				const lastX = monitor.getItem().x;
				const lastY = monitor.getItem().y;
				const nextX = x;
				let nextY = placeholderIndex;
				let taskObj = null;
				let isMoveCard = false;
				let itemType = null;
				if (object === constants.TASKS) {
					taskObj = {};
					itemType = getStringParam(monitor.getItem().item.type);
					if (itemType === constants.EMAIL_OBJECT || itemType === constants.SMS_OBJECT) {
						isMoveCard = false;
					} else {
						isMoveCard = canMove(lastX, nextX);
					}
					if (isMoveCard) {
						let list = getArrayParam(lists[lastX].cards);
						let cards = list.filter(f => f.id === monitor.getItem().id);
						let nextCards = null;
						if (lastX === 3 && nextX === 2) {
							let nextCardList = getArrayParam(lists[nextX].cards);
							nextCards = nextCardList.filter((c) => c.id === cards[0].id);
						}
						if (getArrayParam(nextCards).length > 0) {
							isMoveCard = false;
							return;
						} else {
							try {
								let taskDate = getArrayParam(cards).length > 0 ? getStringParam(cards[0].hdndateField) : null;
								let time = taskDate.split(" ")[1];
								if (columnName === 'Today') {
									taskDate = moment().tz(timeZone[app.me.timezone]);
									taskDate = taskDate.format('YYYY-MM-DD').concat(" " + time);
								} else if (columnName === 'Tomorrow') {
									taskDate = moment().tz(timeZone[app.me.timezone]);
									taskDate = taskDate.add(1, 'days');
									taskDate = taskDate.format('YYYY-MM-DD').concat(" " + time);
								}
								taskObj.dueBy = getStringParam(taskDate).length > 0 ? getStringParam(taskDate) : null;
							} catch (error) {
								console.log("Error in 'Card.js -> drop() taskObj.dueBy':" + error)
							}
						}
					}
				}

				if (lastY > nextY) {
					nextY += 1;
				} else if (lastX !== nextX) {
					nextY += 1;
				}

				if (lastX === nextX && lastY === nextY) {
					return;
				}

				let itemId = monitor.getItem().id;
				let deskItem = columnName;

				let moveProps = { itemId, deskItem, lastX, lastY, nextX, nextY };

				let currentStage = null;
				let listData = getArrayParam(lists);
				if (listData.length > 0) {
					currentStage = listData[lastX].columnName;

					if (currentStage !== columnName) {
						let params = {};
						params.id = monitor.getItem().id;
						params.object = object;
						params.next_stage = columnName;
						params.current_stage = currentStage;
						let record = monitor.getItem();
						if (record.hasOwnProperty('item')) {
							if (record.item.hasOwnProperty('workflow_id')) {
								params.workflow_id = record.item.workflow_id;
							}
							if (object === constants.TASKS && taskObj !== null && taskObj.dueBy !== null) {
								params.dueBy = getStringParam(taskObj.dueBy);
								params.lastX = lastX;
							}
						}
						let info = getActiveTabInfo();

						let activeTab = getActiveTab();
						let forecastReport = false;
						if (activeTab !== null && activeTab !== undefined && activeTab.hasOwnProperty('url') && activeTab.url === '/opportunities/kanban') {
							forecastReport = true;
						}
						if (!forecastReport && !info.hasOwnProperty('input_param_sql') && object !== constants.TASKS) {
							let dataObj = {
								id: monitor.getItem().id,
								title: currentStage,
								object: object,
								cardType: record.item.cardType,
								parentIndex: undefined,
								nextStage: columnName
							}
							convert(dataObj);
						} else if (object === constants.TASKS) {
							let _item = monitor.getItem();
							if (_item.hasOwnProperty('item') && _item.item.hasOwnProperty('type') && (_item.item.type === 'email' || _item.item.type === 'sms')) {
								return;
							}
							if (columnName === 'Future') {
								let _list = getArrayParam(lists[lastX].cards);
								let _cards = _list.filter(f => f.id == monitor.getItem().id);

								let _taskDate = getArrayParam(_cards).length > 0 ? getStringParam(_cards[0].hdndateField) : null;
								let _time = _taskDate.split(" ")[1];
								params.lastX = lastX;
								params.time = _time;
								let _data = {
									object: object,
									params: params,
									moveProps: moveProps,
									dialogContentHeight: 450
								}
								dispatch(getAppDialog(true, constants.SF_CALENDAR_DIALOG, 'Select Date', handleCalendarDialogResponse, _data, null));
								return;
							}
							if (!!taskObj && taskObj.hasOwnProperty("dueBy") && getStringParam(taskObj.dueBy).length > 0 && isMoveCard) {
								movecard(moveProps);
								automaticConvert(params);
							}
						} else if (forecastReport && object === constants.OPPORTUNITIES_OBJECT) {
							let data = {
								object: object,
								params: params,
								moveProps: moveProps,
								dialogContentHeight: 450,
								value: 'forecastReport'
							}
							console.log("params",params)
							dispatch(getAppDialog(true, constants.SF_CALENDAR_DIALOG, 'Select Close Date', handleCalendarDialogResponseForOpportunity, data, null));
						} else {
							let dataObj = {
								id: monitor.getItem().id,
								title: currentStage,
								object: object,
								cardType: record.item.cardType,
								parentIndex: undefined,
								nextStage: columnName,
								my_clientId: record.item.myclient_id,

							}
							convert(dataObj, constants.COLUMNVIEW_DRAGDROP);
						}
					}
				}
			} else {
				let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
			}
		},
		hover: (_, monitor) => {
			// defines where placeholder is rendered
			const scrollTop = componentRef.current['scrollTop'] || 0;
			const placeholderIndex = getPlaceholderIndex(monitor.getClientOffset().y, scrollTop);
			// horizontal scroll
			if (!isScrolling) {
				if (window.innerWidth - monitor.getClientOffset().x < 200) {
					startScrolling('toRight');
				} else if (monitor.getClientOffset().x < 200) {
					startScrolling('toLeft');
				}
			} else {
				if (window.innerWidth - monitor.getClientOffset().x > 200 &&
					monitor.getClientOffset().x > 200
				) {
					stopScrolling();
				}
			}

			// IMPORTANT!
			// HACK! Since there is an open bug in react-dnd, making it impossible
			// to get the current client offset through the collect function as the
			// user moves the mouse, we do this awful hack and set the state (!!)
			// on the component from here outside the component.
			// https://github.com/gaearon/react-dnd/issues/179
			setPlaceholderIndex(placeholderIndex);
			// when drag begins, we hide the card and only display cardDragPreview
			const item = monitor.getItem();
			const el = document.getElementById(item.id);
			if (!!el) {
				el.style.display = 'none';
			}
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	});

	// side effects

	useEffect(() => {
		setCardItems(cards);
		let scrollInfo = null;
		if (isValidParam(scrollPosition[object])) {
			scrollInfo = scrollPosition[object];
		}
		if (scrollInfo !== null && scrollInfo.hasOwnProperty("desk-items-" + columnName)) {
			$("#desk-items-" + columnName).scrollTop(scrollInfo["desk-items-" + columnName]);
		}
	}, []);

	useEffect(() => {
		const deskItems = $(".desk-items");
		if (cardType !== null && cardType === 'columnview') {
			if (deskItems && deskItems.length > 0 && deskItems[0]) {
				if (navigator.userAgent.indexOf("Firefox") !== -1) {
					deskItems.attr('style', 'height: calc(100% - 35px)');
				} else {
					deskItems.attr('style', 'height: 100%');
				}
			}
		}

		let activeTab = getActiveTab();
		if (activeTab !== null && activeTab !== undefined && activeTab.hasOwnProperty('url') && activeTab.url === '/opportunities/kanban') {
			if (deskItems && deskItems.length > 0 && deskItems[0]) {
				if (navigator.userAgent.indexOf("Firefox") !== -1) {
					deskItems.attr('style', 'height: calc(100% - 35px)');
				} else {
					deskItems.attr('style', 'height: 100%');
				}
			}
		} else if (cardType !== null && cardType === 'columnview' && activeTab !== null && activeTab !== undefined && activeTab.hasOwnProperty('url') && activeTab.url === '/tasks/taskpage') {
			if (deskItems && deskItems.length > 0 && deskItems[0]) {
				deskItems.attr('style', 'height: 100%');
			}
		}
	}, [cardType]);

	useEffect(() => {
		let isCardEquall = _.isEqual(cardItems, cards);
		if (!isCardEquall) {
			setCardItems(cards);
			if (cardItems.length < DEFAULT_PAGE_SIZE && totalCardsCount > cardItems.length && !dataAdded) {
				setDataAdded(true);
				//getCardsData();
			}
			if (cardItems.length < (DEFAULT_PAGE_SIZE - 2 && totalCardsCount > cardItems.length && dataAdded)) {
				setDataAdded(false);
			}
		}
	}, [cards]);

	// functions
	function getPlaceholderIndex(y, scrollY) {
		// shift placeholder if y position more than card height / 2
		const yPos = y - OFFSET_HEIGHT + scrollY;
		let placeholderIndex;
		if (yPos < CARD_HEIGHT / 2) {
			placeholderIndex = -1; // place at the start
		} else {
			placeholderIndex = Math.floor((yPos - CARD_HEIGHT / 2) / (CARD_HEIGHT + CARD_MARGIN));
		}
		return placeholderIndex;
	}

	function updateOpportunity(props) {
		let { params } = props;
		try {
			let url = 'v3/opportunities/save';
			let data = { "id": params.id, "fields": [{ "name": "close_date", "value": params.close_date }], "auditrail_action_type": "Inline/Quick updated" }
			const promise = Promise.resolve(HTTPClient.post(url, data));
			promise.then((response) => {
				if (response.status === 0) {
					let msg = '';
					if (object === constants.TASKS) {
						columnViewTaskOpportunitiesUpdate(props);
					} else {
						columnViewOpportunitiesUpdate(props);
					}
					dispatch(getAppDialog(false, null, null, null, null, null));
					msg = 'Opportunity has been moved successfully and also Close Date has been updated.';
					dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
				}
			});
		} catch (error) {
			console.error("Error in 'Card.js -> updateOpportunity()':" + error);
		}
	}


	function convert(dataObj, callFrom = 'columnview') {
		try {
			let labelName = getLocalizedStrings().label.KANBAN_REPORT[constants.CONVERT];
			let value = constants.CONVERT;
			if (callFrom === 'columnview') {
				let selectedRecord = [];
				selectedRecord.push({ id: dataObj.id, title: dataObj.title });
				let data = {
					selectedRecords: selectedRecord,
					callBack: getConvertCallBack,
					object: dataObj.object,
					currentStage: dataObj.title,
					stage: dataObj.nextStage,
					columnViewDragDropForAll: true
				}
				let style = {};
				style.width = '40%';
				let minimizeList = appContainer.customDrawerProps.minimizeList;
				if (minimizeList[value]) {
					const alertMsg = 'A convert dialog is already opened in minimised mode'
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMsg, null);

				} else {

					dispatch(getAppCustomDrawer(true, labelName, value, style, data, null, true, callFrom));
				}

			} else if (callFrom === constants.COLUMNVIEW_DRAGDROP) {
				let data = { stage: dataObj.nextStage, object: dataObj.object, id: dataObj.id, callBack: getConvertCallBack, currentStage: dataObj.title, myClientId: dataObj.my_clientId };
				let style = {};
				style.width = '40%';
				dispatch(getAppCustomDrawer(true, labelName, value, style, data, null, true, callFrom));
			}

		} catch (error) {
			console.error("Error in 'Card.js -> convert()':" + error);
		}
	}

	function getConvertCallBack(stageName) {
		let tab = getObjectParam(getActiveTab());
		refreshColumnView(tab.object);
	}

	function handleCalendarDialogResponse(response) {
		try {
			dispatch(getAppDialog(false, constants.SF_CALENDAR_DIALOG, null, null, null, null));
			if (response.params.date !== null && response.params.time !== null) {
				response.params.dueBy = response.params.date + ' ' + response.params.time;
				let lastX = response.params.lastX;
				if (lastX === 2 && x === 3) {
					let index = lists[3].cards.findIndex(f => f.id === response.params.id);
					if (index !== -1) {
						lists[3].cards.splice(index, 1);
						lists[3].total_count -= 1;
					}
				}
				if (isValidParam(response.params) && response.params.hasOwnProperty("dueBy") && getStringParam(response.params.dueBy).length > 0) {
					movecard(response.moveProps);
					automaticConvert(response.params);
				}
			}

		} catch (error) {
			console.log("Error in 'Card.js -> handleCalendarDialogResponse()':" + error)
		}

	}


	function handleCalendarDialogResponseForOpportunity(response) {
		try {

			if (response.params.date !== null && response.params?.dateValue !== '') {
				delete response.params.datetime;
				delete response.params.time
				response.params.close_date = response.params.date;
				if (isValidParam(response.params) && response.params.hasOwnProperty("close_date") && getStringParam(response.params.close_date).length > 0) {
					let props = { ...response }
					updateOpportunity(props);
				}
			}else{
				dispatch(showCustomSnackBar('Please select date.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
			}
		} catch (error) {
			console.log("Error in 'Card.js -> handleCalendarDialogResponse()':" + error)
		}
	}

	function getUpdatedContactList(recordId) {
		let list = null;
		try {
			if (isValidParam(lists)) {
				lists.map(f => {
					if (f.id === id) {
						let cardsList = f.cards;
						let cards = getArrayParam(cardsList).filter(c => {
							if (c.id === recordId) {
								return c;
							}
						});
						if (cards.length > 0 && isValidParam(cards[0])) {
							let contact = getObjectParam(cards[0]);
							if (contact.hasOwnProperty("updated_on")) {
								let currentTime = moment().tz(timeZone[app.me.timezone]);
								currentTime = currentTime.format(dateTimeFormat[app.me.date_format]);
								contact.updated_on = currentTime;
							}
							if (contact.hasOwnProperty("updated_by")) {
								contact.updated_by = getStringParam(app.me.name);
							}
						}
					}
				});
				list = getArrayParam(lists);
			}
		} catch (error) {
			console.error("Error in 'Card.js -> getUpdatedContactList()':" + error);
		}
		return list;
	}


	function movecard(props) {
		const deskItem = $("#desk-items-" + props.deskItem);
		try {
			deskItem.scrollTop(0);
		} catch (error) {
			console.error(error);
		}
		if (object === constants.TASKS) {
			dispatch(moveTaskCard(props.lastX, props.lastY, props.nextX, props.nextY));

		} else {
			dispatch(moveCard(props.lastX, props.lastY, props.nextX, props.nextY));
		}
		setTimeout(function () {
			let scrollPos = 0;
			scrollPos = $("#" + props.itemId).position()?.top - ((deskItem.offset()?.top) - 100);
			if (scrollPos < 0) {
				scrollPos = 0;
			}
			deskItem.animate({ scrollTop: props.scrollPos }, "slow");
		}, 500);
	}

	function automaticConvert(params) {
		try {
			let url = null;
			if (params.object === constants.ACCOUNTS_OBJECT) {
				url = endPoints.ACCOUNTS.AUTOMATIC_CONVERT;
			} else if (params.object === constants.OPPORTUNITIES_OBJECT) {
				url = endPoints.OPPORTUNITIES.AUTOMATIC_CONVERT;
			} else if (params.object === constants.CASES_OBJECT) {
				url = endPoints.CASES.AUTOMATIC_CONVERT;
			} else if (params.object === constants.PROJECTS_OBJECT) {
				url = endPoints.PROJECTS.AUTOMATIC_CONVERT;
			} else if (params.object === constants.ISSUES_OBJECT) {
				url = endPoints.ISSUES.AUTOMATIC_CONVERT;
			} else if (params.object === constants.CUSTOM_TABLE1_OBJECT) {
				url = endPoints.CUSTOM_TABLE1.AUTOMATIC_CONVERT;
			} else if (params.object === constants.CUSTOM_TABLE2_OBJECT) {
				url = endPoints.CUSTOM_TABLE2.AUTOMATIC_CONVERT;
			} else if (params.object === constants.CUSTOM_TABLE3_OBJECT) {
				url = endPoints.CUSTOM_TABLE3.AUTOMATIC_CONVERT;
			} else if (params.object === constants.CUSTOM_TABLE4_OBJECT) {
				url = endPoints.CUSTOM_TABLE4.AUTOMATIC_CONVERT;
			} else if (params.object === constants.TASKS) {
				url = endPoints.TASKS.AUTOMATIC_CONVERT;
			} else if (params.object === constants.CONTACTS_OBJECT) {
				url = endPoints.CONTACTS.AUTOMATIC_CONVERT;
			}

			const response = Promise.resolve(HTTPClient.post(url, params));
			response.then((response) => {
				if (response.status === 0) {
					let objectLabel = getObjectLabelByObject(params.object);
					let msg;
					if (isValidParam(params.object) && getStringParam(params.object) === constants.TASKS) {
						if (isValidParam(response.data)) {
							let taskId = getStringParam(response.data.id);
							let value = getStringParam(response.data.dueBy);
							let taskDate = value;
							let hdndate = moment(value).format("YYYY-MM-DD HH:mm");
							let lastX = params.lastX;
							lists[x].cards.forEach((f) => {
								if (f.id === taskId) {
									f.dateField = taskDate;
									f.hdndateField = hdndate;
									let index = lists[lastX].cards.findIndex(f => f.id === taskId);
									if (index !== -1) {
										lists[lastX].cards.splice(index, 1);
										lists[lastX].total_count -= 1;
									}
								}
							})
							//sort
							lists[x].cards.sort((a, b) => new Date(a.hdndateField) - new Date(b.hdndateField));
							if (params.object === constants.TASKS) {
								columnViewTaskListUpdate(lists);
								refreshColumnView(params.object);

							} else {
								columnViewTaskUpdate(lists);
							}
						}
						msg = getLocalizedStrings().message.APPOINTMENTS_TASKS.RESCHEDULE_TASK_SUCCESS;
					} else if (isValidParam(getLocalizedStrings().label.HEADER[objectLabel])) {
						if (params.object === constants.CONTACTS_OBJECT) {
							//update contact fields if required
							let updatedContacts = null;
							if ((selectedFields.indexOf('updated_on') > -1 && selectedFields.indexOf('updated_on') < 5)
								|| (selectedFields.indexOf('updated_by') > -1 && selectedFields.indexOf('updated_by') < 5)) {
								updatedContacts = getUpdatedContactList(params.id);
							}
							//sort
							if (sortBy.length > 0) {
								let sortFields = fields.filter(f => f.name === sortBy);
								if (updatedContacts == null) {
									updatedContacts = lists;
								}
								if (sortFields[0].field_type === 'datetime') {
									if (sortType === "desc") {
										updatedContacts[x].cards.sort((a, b) => new Date(b[sortBy]) - new Date(a[sortBy]));
									} else {
										updatedContacts[x].cards.sort((a, b) => new Date(a[sortBy]) - new Date(b[sortBy]));
									}
								} else if (sortFields[0].field_type === 'text') {
									if (sortType === "desc") {
										updatedContacts[x].cards.sort((a, b) => b[sortBy] > a[sortBy]);
									} else {
										updatedContacts[x].cards.sort((a, b) => a[sortBy] < b[sortBy]);
									}
								} else if (sortFields[0].field_type === 'integer' || sortFields[0].field_type === 'float') {
									if (sortType === "desc") {
										updatedContacts[x].cards.sort((a, b) => b[sortBy] - a[sortBy]);
									} else {
										updatedContacts[x].cards.sort((a, b) => a[sortBy] - b[sortBy]);
									}
								}
							}

							if (getArrayParam(updatedContacts).length > 0) {
								columnViewContactUpdate(updatedContacts);
							}
							msg = getLocalizedStrings().label.HEADER[objectLabel] + getLocalizedStrings().message.BATCH_ACTION.CONTACT_STATUS_UPDATE_SUCCESS;
						} else {
							msg = getLocalizedStrings().label.HEADER[objectLabel] + getLocalizedStrings().message.BATCH_ACTION.SINGLE_CONVERT_SUCCESSFULL;
						}
					} else {
						msg = objectLabel + getLocalizedStrings().message.BATCH_ACTION.SINGLE_CONVERT_SUCCESSFULL;
					}
					if (params.hasOwnProperty('prevWorkflowId') && params.hasOwnProperty('workflow_id') && params.prevWorkflowId !== params.workflow_id) {
						msg = getLocalizedStrings().message.BATCH_ACTION.WORKFLOW_APPLIED;
					}
					dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
					if (params.hasOwnProperty('total_count') && params.total_count === 1) {
						refreshColumnView(params.object);
					}
				}
			});
		} catch (error) {
			console.error("Error in 'Card.js -> share()':" + error);
		}
	}

	const handleScroll = (event, id) => {
		if (isValidParam(cardType) && cardType === 'columnview') {
			let scrollTop = event.currentTarget.scrollTop;
			let tempPositionsObj;
			let tempInner = {};
			if (isValidParam(scrollPosition[object])) {
				tempInner = scrollPosition[object];
			}
			tempInner[id] = scrollTop;
			tempPositionsObj = {
				[object]: tempInner
			}
			dispatch(updateColumnViewScrollPosition(tempPositionsObj, object));
			scrollTop = parseInt(scrollTop);
			if (lastScrollPos > scrollTop) {
				setLastScrollPos(scrollTop);
			} else if (lastScrollPos < scrollTop) {
				let deskItem = document.getElementById("desk-items-" + columnName);
				let scrollHeight = deskItem.scrollHeight;
				let offsetHeight = deskItem.offsetHeight;
				let lastDivPos = scrollHeight - offsetHeight;
				if (scrollTop > lastDivPos * 0.97) {
					getCardsData();
				}
			}
		}
	}

	const getCardsData = () => {
		let deskItem = document.getElementById("desk-items-" + columnName);
		let scrollTop = deskItem.scrollTop;
		let info = getObjectParam(getActiveTabInfo());
		let tempStartIndex = startIndex + DEFAULT_PAGE_SIZE;
		let params = {};
		if (object === constants.TASKS) {
			params.module_id = columnName;
			params.start_index = tempStartIndex;
			params.fetch_size = DEFAULT_PAGE_SIZE;

			params.sales_rep = getStringParam(info.userName);
			params.status = getArrayParam(info.status).join();
		} else {
			params.query_name = query_name;
			params.query_type = query_type;
			params.group_by_field_name = group_by_field_name;
			params.group_by_condition = columnName;
			params.start_index = tempStartIndex;
			params.page_size = DEFAULT_PAGE_SIZE;
		}
		if (info.hasOwnProperty('searchFieldName') && info.hasOwnProperty('searchText')) {
			params.search_field_name = getStringParam(info.searchFieldName);
			params.search_type = constants.SEARCH_TYPE_ANYWHERE;
			params.search_text = getStringParam(info.searchText);
		}
		if (info.hasOwnProperty('workflowId')) {
			if (info.workflowId.toString() !== '-9999') {
				params.input_param_sql = 'workflow_id=' + info.workflowId;
			}
		}
		params.call_from = 'columnview';
		params.is_column_view = true;

		let data = filter(lists, (item) => {
			return item.columnName === columnName;
		});
		let totalRecordLength = 0;
		let currentRecordLength = 0
		if (data.length > 0) {
			currentRecordLength = data[0].cards.length;
			totalRecordLength = data[0].total_count;
		}
		if (currentRecordLength !== totalRecordLength) {
			let cards = null;
			const promise = Promise.resolve(getListsData(object, params));
			if (isValidParam(promise)) {
				promise.then((response) => {
					if (isValidParam(lists) && lists.length > 0 && response?.list) {
						let newRecords = response?.list[0];
						lists.forEach((item) => {
							if (item.columnName === newRecords.columnName) {
								item.cards = concat(item.cards, newRecords.cards);
								cards = item.cards;
							}
						});
						setStartIndex(tempStartIndex);
						setCardItems(cards);
					}
				});
			}
		}
		setLastScrollPos(scrollTop);
	}

	const getLabelForNoRecords = () => {

		let objectName = getStringParam(object);
		objectName = objectName.replace('_', '-');
		if (objectName === constants.RECYCLE_BIN_OBJECT) {
			objectName = constants.ACCOUNTS_OBJECT;
		}
		let labelName = null;
		if (objectName.length > 1) {
			if (objectName === constants.TASKS || objectName === constants.PROJECTS_OBJECT) {

				labelName = makeTiteleCase(objectName);
				labelName = getLocalizedStrings().label.NO_RECORD.hasOwnProperty(labelName) ? getLocalizedStrings().label.NO_RECORD[labelName] : labelName;

			} else {
				let objectListArr = app.objectList;
				const currentObject = filter(objectListArr, function (o) {
					if (o.name === objectName) return o;
				});
				let itemLabel = currentObject.length > 0 && isValidParam(currentObject[0]) ? getStringParam(currentObject[0].label).trim() : '';
				itemLabel = getLocalizedStrings().label.NO_RECORD.hasOwnProperty(itemLabel) ? getLocalizedStrings().label.NO_RECORD[itemLabel] : itemLabel;
				itemLabel = itemLabel === '' ? makeTiteleCase(objectName) : itemLabel;
				labelName = itemLabel;
			}
		}
		let label;
		if (labelName && labelName.length > 0) {
			label = getLocalizedStrings().label.COMMON.NO + ' ' + labelName + ' ' + getLocalizedStrings().label.COMMON.FOUND;
		} else {
			label = 'No record found';
		}
		if (app.me.language === "zh-CN") {
			if (objectName === 'accounts') {
				label = '未发现账户';
			} else if (objectName === 'contacts') {
				label = '未发现联系人';
			} else if (objectName === 'opportunities') {
				label = '未发现商机';
			} else if (objectName === 'web-forms') {
				label = '未发现Web表单';
			} else if (objectName === 'sales-templates') {
				label = '未发现销售模板';
			} else if (objectName === 'cases') {
				label = '未发现案例';
			} else if (objectName === 'solutions') {
				label = '未发现解决方案';
			}
		}
		return label;
	}

	// rendering component
	let isPlaceHold = false;
	let cardList = [];
	let _cards = cardItems.filter((obj, index, self) =>
		index === self.findIndex((t) => (
			t.id === obj.id
		))
	);
	_cards.forEach((item, i) => {
		if (isOver && canDrop) {
			isPlaceHold = false;
			if (i === 0 && placeholderIndex === -1) {
				cardList.push(
					<div key={"placeholder" + i} className="item placeholder" />);
			} else if (placeholderIndex > i) {
				isPlaceHold = true;
			}
		}
		if (!!item) {
			item["object"] = object;
			item["sortType"] = sortType;
			item["fields"] = fields;
			item["cardType"] = cardType;
			item['columnName'] = columnName;

			if (object !== constants.TASKS && !item.hasOwnProperty('selectedFields')) {
				item["selectedFields"] = selectedFields;
			} else {
				selectedFields = item.selectedFields;
			}

			cardList.push(
				<Card
					x={x}
					y={i}
					item={item}
					key={item.id + x + i + "y" + i}
					stopScrolling={stopScrolling}
					object={object}
					parentIndex={id}
					reportBy={reportBy}
					sortBy={sortBy}
					sortType={sortType}
					probability={item.probability}
					fields={fields}
					selectedFields={selectedFields}
					cardType={cardType}
					totalCardsCount={totalCardsCount}
				/>
			);
		}
		if (isOver && canDrop && placeholderIndex === i) {
			cardList.push(
				<div key={"placeholder" + i} className="item placeholder" />);
		}
	});

	if (isPlaceHold) {
		cardList.push(
			<div key="placeholder" className="item placeholder" />);
	}

	if (isOver && canDrop && cardItems.length === 0) {
		cardList.push(
			<div key="placeholder" className="item placeholder" />);
	}

	drop(componentRef);

	if (cardItems.length === 0) {
		return (
			<div
				ref={componentRef}
				className="desk-items desk-items-gray"
				key={"desk-items-" + columnName}
				onScroll={(e) => handleScroll(e, "desk-items-" + columnName)}
				id={"desk-items-" + columnName}
			>
				<div id="0" style={{ height: '100px', marginTop: '0px', paddingRight: '5px' }}>
					<div className="item-container" style={{ height: '100%' }}>
						<div className="item-content">
							<textarea
								className="custom-textarea"
								disabled style={{ width: '100%', paddingTop: 10, color: '#717171', resize: 'none' }}
								value={getLabelForNoRecords()}
							>
							</textarea>
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div ref={componentRef} className="desk-items" key={"desk-items-" + columnName} onScroll={(e) => handleScroll(e, "desk-items-" + columnName)} id={"desk-items-" + columnName}>
				{cardList}
			</div>
		);
	}
}

export default Cards;