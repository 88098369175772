import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { isValidParam, getObjectParam, getBooleanParam, getStringParam, getIntParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { getDatabaseSetupInfo, saveDatabaseSetupInfo } from '../../../services/actions/databaseActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import concat from 'lodash/concat';
import { Icon, Menu, MenuItem } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { getUniqueId } from '../../../services/helper/utils';
import Button from '@mui/material/Button';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import ShowCircularProgress from '../components/circularProgress';
import { setHeader } from '../../../services/actions/headerActions';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import DraggableSelect from '../../sfPlugins/draggableSelect.tsx';
import * as sfDialogs from '../components/sfDialogs';
const textOverflow = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };
/**
 * 
 * @Author  : Pradyut.s 
 * @Date:   : 2019-07-01
 */

const mapStateToProps = (state) => {
    return {
        app: state.app
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
        setHeader: (selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader) => {
            dispatch(setHeader(selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader));
        },
    }
};
const maxLimit = 15;
class DatabaseContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMounted: false,
            setupInfo: null,
            selectedLinkOject: {},
            isSavedDisabled: false,
        };
    }

    componentDidMount() {
        this.setHeader(this.props);
        this.getDatabaseSetupInfo();
    }

    setHeader = (props) => {
        this.props.setHeader(props.object, getLocalizedStrings().label.CUSTOMIZATION_SETTING.DATA_BASE, null, false, true);
    }

    getDatabaseSetupInfo = () => {
        try {
            let promise = getDatabaseSetupInfo();
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        let objects = getArrayParam(response.objects);
                        let isfranchisor = this.props.app.me.is_franchisor
                        if (isfranchisor) {
                            let obj = objects;
                            obj = obj.filter(f => (f.name !== constants.COMPLIANCE_OBJECT && f.name !== constants.ROYALTY_PLANS_OBJECT && f.name !== constants.ROYALTY_RUN_OBJECT));
                            objects = obj;
                        }
                        let objectIdMap = {};
                        objects.forEach(obj => {
                            objectIdMap[getIntParam(obj.id)] = obj;
                        });
                        response.objectIdMap = objectIdMap;
                        this.setState({ isMounted: true, setupInfo: response });
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'DatabaseContainer.js -> getDatabaseSetupInfo()':" + error);
        }
    }

    getObjectItems = (setupInfo) => {
        let elements = [];
        try {
            if (isValidParam(setupInfo)) {
                let moduleApp = this.props.app.me;
                let moduleAccessItem = moduleApp.module_access;
                let homeList = getObjectParam(setupInfo.home_list);
                let linkObjects = getObjectParam(setupInfo.link_objects);
                let objectIdMap = getObjectParam(setupInfo.objectIdMap);
                let objects = setupInfo.objects;
                let objectIds = objects.map(m => { return m.id });
                let isService = true;
                if (moduleAccessItem.Service === 0) {
                    isService = false;
                }
                if (moduleApp.plan_type !== constants.SUBSCRIPTION_PLAN_TYPE_PROFESSIONAL) {
                    objectIds = objectIds.filter(f => f !== OBJECT_TABLEID_MAP['customobjectone'] && f !== OBJECT_TABLEID_MAP['customobjecttwo'] && f !== OBJECT_TABLEID_MAP['customobjectthree'] && f !== OBJECT_TABLEID_MAP['customobjectfour']);
                }

                objectIds.forEach((objectId, index) => {
                    if(objectIdMap!= null && objectIdMap.hasOwnProperty(objectId)){
                        let style = {}
                        if (index % 2 === 0) {
                            style.backgroundColor = '#fcd9d075';
                        }
    
                        let props = {};
                        props.keyId = 'module-link-object-' + objectId + '-' + index;
                        props.object = objectIdMap[objectId];
                        if (objectId === 13) {
                            props.module = homeList[3];
                        } else {
                            props.module = homeList[objectIdMap[objectId].home_id];
                        }
                        if ((objectId === OBJECT_TABLEID_MAP['projects'] || objectId === OBJECT_TABLEID_MAP['cases'])) {
                            props.module = isService ? homeList[3] : homeList[5];
                        }
                        props.linkInfo = linkObjects[objectId];
                        props.homeList = homeList;
                        props.style = style;
                        props.updateInfoForSave = this.updateInfoForSave;
                        props.showCustomSnackBar = this.props.showCustomSnackBar;
                        props.app = this.props.app;
                        elements.push(<ModuleLinkObject {...props} />);
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'DatabaseContainer.js -> getObjectItems()':" + error);
        }
        return elements;
    }

    updateInfoForSave = (objectId, updatedInfo) => {
        try {
            let selectedLinkOject = getObjectParam(this.state.selectedLinkOject);
            selectedLinkOject[objectId] = updatedInfo;
        } catch (error) {
            console.error("Error in 'DatabaseContainer.js -> updateInfoForSave()':" + error);
        }
    }

    saveDatabaseSetup = () => {
        let arrData = [];
        let tempObj = null;
        try {
            let emptyFlag = false;
            let parentObjectName = '';
            let objectIdMap = getObjectParam(this.state.setupInfo.objectIdMap);
            let linkObjects = getObjectParam(this.state.setupInfo.link_objects);
            let selectedLinkOject = getObjectParam(this.state.selectedLinkOject);

            let objectIds = Object.keys(selectedLinkOject);
            objectIds.forEach(id => {

                tempObj = {};
                tempObj.id = id;
                tempObj.type = objectIdMap[id].type;

                if (selectedLinkOject[id].hasOwnProperty('label')) {
                    tempObj.label = selectedLinkOject[id].label;
                } else {
                    tempObj.label = objectIdMap[id].label;
                }

                if (selectedLinkOject[id].hasOwnProperty('module_id')) {
                    tempObj.home_id = getIntParam(selectedLinkOject[id].module_id);
                } else {
                    tempObj.home_id = objectIdMap[id].home_id;
                }

                if (selectedLinkOject[id].hasOwnProperty('selected_objects')) {
                    if (selectedLinkOject[id].selected_objects.length === 0 && linkObjects[id].selected_objects.length > 0 && !selectedLinkOject[id].hasOwnProperty('module_id')) {
                        emptyFlag = true;
                        parentObjectName = objectIdMap[id].label
                        return;
                    } else {
                        tempObj.link_child_objects = getArrayParam(selectedLinkOject[id].selected_objects);
                    }
                } else {
                    tempObj.link_child_objects = getArrayParam(linkObjects[id]);
                }
                arrData.push(tempObj);
            });

            if (arrData.length > 0 && !emptyFlag) {
                let promise = saveDatabaseSetupInfo({ database_setup_info: arrData });
                if (isValidParam(promise)) {
                    promise.then(response => {
                        if (isValidParam(response) && response.status === 0) {
                            let error = getArrayParam(response.error);
                            if (error.length > 0) {
                                this.props.showCustomSnackBar(getLocalizedStrings().message.DATA_BASE.FAILURE_MSG, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                            } else {
                               // this.state.selectedLinkOject = {};
                                this.setState({selectedLinkOject:{}});
                                this.props.showCustomSnackBar(getLocalizedStrings().message.DATA_BASE.SUCCESS_MSG, styles.snackbarBodyStyleSuccess, styles.snackBarStyleLongMsg);
                            }
                        } else if (isValidParam(response)) {
                            this.props.showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                        }
                    })
                }
            } else if (emptyFlag) {
                this.props.showCustomSnackBar(getLocalizedStrings().message.DATA_BASE.VALIDATION_MSG + parentObjectName + ".", styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            } else {
                this.props.showCustomSnackBar(getLocalizedStrings().message.DATA_BASE.SUCCESS_MSG, styles.snackbarBodyStyleSuccess, styles.snackBarStyleLongMsg);
            }
        } catch (error) {
            console.error("Error in 'DatabaseContainer.js -> saveDatabaseSetup()':" + error);
        }
    }

    render() {
        let elements = this.getObjectItems(this.state.setupInfo);
        let buttonProps = { onClick: this.saveDatabaseSetup, disabled: this.state.isSavedDisabled };
        let buttonStyle = { ...styles.primaryButton };
        delete buttonStyle.marginRight;
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;

        return <div style={{ marginLeft: 30, marginRight: 30 }}>
            {
                !this.state.isMounted && <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            }
            {
                this.state.isMounted && [
                    <div style={{ textAlign: 'end' }}><Button key='save1' style={{ ...buttonStyle, marginBottom: 10 }} {...buttonProps}> {getLocalizedStrings().label.COMMON.SAVE}</Button></div>,
                    <div style={{ display: 'flex', marginBottom: 10, fontWeight: 600, color: '#333333c2', fontSize: '14px' }}>
                        <div style={{ width: '5%' }}></div>
                        <div style={{ width: '22%' }}>Tables <span style={{ color: '#00000073' }}>(Click a link to customize)</span></div>
                        <div style={{ width: '13%' }}>Add to Module</div>
                        <div style={{ width: '60%' }}>{getLocalizedStrings().label.IMPORT.LINK_WITH}</div>
                    </div>,
                    <div style={{ fontSize: '14px' }}>{elements}</div>,
                    <div style={{ textAlign: 'end' }}><Button key='save2' style={{ ...buttonStyle, marginTop: 10 }} {...buttonProps}>{getLocalizedStrings().label.COMMON.SAVE}</Button></div>
                ]
            }
        </div>

    }
}


class ModuleLinkObject extends React.Component {
    constructor(props) {
        super(props);
        this._input = null;
        this.state = {
            object: null,
            module: {},
            availableObjects: [],
            selectedObjects: [],
            isInlineEdit: false,
            updatedInfo: {},
            objectLabel: '',
        }
    }

    componentDidMount() {
        this.processData();
    }

    processData = () => {
        try {
            let object = this.props.object;
            let module = this.props.module;
            let linkInfo = Object.assign({}, getObjectParam(this.props.linkInfo));
            let availableObjects = getArrayParam(linkInfo.available_objects);
            let selectedObjects = getArrayParam(linkInfo.selected_objects);

            availableObjects.forEach((obj) => {
                obj.key = obj.t_child_tableid;
                obj.value = obj.t_child_tableid;
                obj.label = obj.name;
                obj.title = obj.name;//To show the title of selected fields 
            });

            selectedObjects.forEach((obj) => {
                obj.key = obj.t_child_tableid;
                obj.value = obj.t_child_tableid;
                obj.label = obj.name;
                obj.title = obj.name;//To show the title of selected fields               
            });

            availableObjects = concat(availableObjects, selectedObjects);
            this.setState({ object: object, objectLabel: object.label, module: module, selectedObjects: selectedObjects, availableObjects: availableObjects });

        } catch (error) {
            console.error("Error in 'DatabaseContainer.js -> ModuleLinkObject.class -> processData()':" + error);
        }
    }

    onSortEnd = (selectedOptions) => {
        this.props.updateInfoForSave(this.state.object.id, this.state.updatedInfo);
        let _state = this.state;
        _state.updatedInfo.selected_objects = selectedOptions;
        _state.selectedObjects = selectedOptions;
        this.setState({ _state});
    };

    getLinkObjectDropDown = () => {
        let element = null;
        console.log('nn' )
        console.log(this.state.selectedObjects )
        console.log(this.state.availableObjects )
        try {
            let avData = this.state.availableObjects.length;
            if (avData > 0) {
                element = <DraggableSelect
                    key={'multi-select-' + this.props.keyId}
                    id={'multi-select-' + this.props.keyId}
                    selectId={'multi-select-' + this.props.keyId}
                    onSortEnd={this.onSortEnd}
                    variant="inline"
                    closeMenuOnSelect={false}
                    disabled={false}
                    value={this.state.selectedObjects}
                    placeholder={"Select linked child"}
                    selectLimit ={maxLimit}
                    limitMessage ={getLocalizedStrings().message.DATA_BASE.VALIDATION_MSG1}
                    options={this.state.availableObjects}
                    onChange={this.onSelectChange}
                    clearAllText={getLocalizedStrings().label.COMMON.CLEAR_ALL}
                    className='my-cool-select-top'
                />;
            } else {
                element = null;
            }

        } catch (error) {
            console.error("Error in 'DatabaseContainer.js -> ModuleLinkObject.class -> getLinkObjectDropDown()':" + error);
        }
        return element;
    }

    onSelectChange = (options) => {
        try {
            if (options.length <= maxLimit) {
               this.props.updateInfoForSave(this.state.object.id, this.state.updatedInfo);
                let _state = this.state;
                _state.updatedInfo.selected_objects = options;
                _state.selectedObjects = options;
                this.setState({_state});
            } else {
                this.props.showCustomSnackBar(getLocalizedStrings().message.DATA_BASE.VALIDATION_MSG1, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            }

        } catch (error) {
            console.error("Error in 'DatabaseContainer.js -> ModuleLinkObject.class -> onSelectChange()':" + error);
        }
    }

    openTab = () => {
        try {
            let object = getObjectParam(this.state.object);
            let token = localStorage.getItem('token');
            let redirectUrl = constants.snapshotUrl;
            redirectUrl += '&token=' + token;
            redirectUrl += '&callingfor=editForm';
            redirectUrl += '&label=' + encodeURIComponent(object.label);
            redirectUrl += '&tableId=' + object.id;
            if(this.props.app.me.is_customize_form){
                window.open(redirectUrl);
            }else{
                let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
            }
        } catch (error) {
            console.error("Error in 'DatabaseContainer.js -> ModuleLinkObject.class -> openTab()':" + error);
        }
    }

    updateObjectLabel = (event) => {
        try {
            let label = getStringParam(event.currentTarget.value);
            if (label.length <= 20) {
                this.setState({ objectLabel: label });
            }

        } catch (error) {
            console.error("Error in 'DatabaseContainer.js -> ModuleLinkObject.class -> updateObjectLabel()':" + error);
        }
    }

    updateObjectLabelOnBlur = (event) => {
        try {
            let object = this.state.object;
            let label = getStringParam(this.state.objectLabel);
            if (label !== '') {
                object.label = label;
                 let _state = this.state;
                _state.updatedInfo.label = getStringParam(object.label);
                _state.object = object;
                this.setState({_state});
                this.props.updateInfoForSave(this.state.object.id, this.state.updatedInfo);
            } else {
                this.setState({objectLabel:object.label});
            }
            this.setState({ isInlineEdit: false });

        } catch (error) {
            console.error("Error in 'DatabaseContainer.js -> ModuleLinkObject.class -> updateObjectLabelOnBlur()':" + error);
        }
    }

    getObjectEditLabelElement = (object) => {
        let element = null;
        try {
            element = <span onClick={this.openTab}>{object.label}</span>;
            let isInlineEdit = getBooleanParam(this.state.isInlineEdit);
            if (object.type === 'Custom Object') {
                element = [];
                element.push();
                if (isInlineEdit) {
                    element.push(<input
                        key={'edit-label-' + object.id}
                        ref={c => (this._input = c)}
                        type='text'
                        value={this.state.objectLabel}
                        autofocus={true}
                        onChange={this.updateObjectLabel}
                        onBlur={this.updateObjectLabelOnBlur}
                    />);
                } else {
                    element.push(<span onClick={this.openTab}>{object.label}</span>);
                }
            }
        } catch (error) {
            console.error("Error in 'DatabaseContainer.js -> ModuleLinkObject.class -> getObjectEditLabelElement()':" + error);
        }
        return element;
    }

    getEditLabelIcon = (type) => {
        let element = null;
        try {
            if (type === 'Custom Object') {
                element = <span style={{ cursor: 'pointer', color: 'rgb(113, 113, 113)', float: 'right' }}
                    onClick={() => { this.setState({ isInlineEdit: true }) }}>
                    <Icon title="Edit" style={{ fontSize: '0.9em' }}>edit</Icon>
                </span>;
            }
        } catch (error) {
            console.error("Error in 'DatabaseContainer.js -> ModuleLinkObject.class -> getEditLabelIcon()':" + error);
        }
        return element;
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            let isInlineEdit = getBooleanParam(this.state.isInlineEdit);
            if (isInlineEdit) {
                this._input.focus();
            }
            if (!isValidParam(this.state.module)) {
                $("div.multi-select-" + this.state.object.name).css("display", "none");
            }
        } catch (error) {
            console.error("Error in 'DatabaseContainer.js -> ModuleLinkObject.class -> componentDidUpdate()':" + error);
        }
    }

    getModuleElement = () => {
        let element = null;
        let module = getObjectParam(this.state.module);
        let object = getObjectParam(this.state.object);
        let homeList = getObjectParam(this.props.homeList);
        try {
            let homeKeys = Object.keys(homeList);
            if (object.id === 2 || object.id === 3 || object.id === 280 || object.id === 9
                || object.id === OBJECT_TABLEID_MAP['purchaseorder'] || object.id === OBJECT_TABLEID_MAP['salesorder'] || object.id === OBJECT_TABLEID_MAP['inventory']) {
                if (object.id === 9) {
                    homeKeys = homeKeys.filter(f => { return f < 3 });
                } else {
                    homeKeys = homeKeys.filter(f => { return f < 2 });
                }
            } else {
                homeKeys = homeKeys.filter(f => { return this.props.app.me.module_access.Service === 1 ? f < 4 : f < 3 });
            }
            let homeItem = homeKeys.map(m => { return { value: homeList[m].id, label: homeList[m].label } });
            homeItem.unshift({ value: 0, label: '<None>' });
            if (object.id === 9) {
                homeItem.length > 2 && homeItem.push({ value: 7, label: `${homeItem[1].label} & ${homeItem[2].label}` });
            }

            let id = getIntParam(module.id) > 0 ? getIntParam(module.id) : getUniqueId();
            let label = getStringParam(module.label);
            let filterItem = homeItem.filter(f => { return f.value === id });
            if (filterItem.length > 0) {
                label = filterItem[0].label;
            }
            label = label === '' ? '<None>' : label;
            element = <MenuPopover
                key={'module-' + id}
                label={label}
                selectedId={getIntParam(module.id)}
                menuItems={homeItem}
                onRequestClose={this.handleRequestClose}
                onModuleOnChange={this.onModuleOnChange}
                width={"200px"}
                isShowMenu={object.type === 'Custom Object' ? true : (object.id === 2 || object.id === 3 || object.id === 280 || object.id === 9 || object.id === OBJECT_TABLEID_MAP['purchaseorder'] || object.id === OBJECT_TABLEID_MAP['salesorder'] || object.id === OBJECT_TABLEID_MAP['inventory']) ? true : false}
            />;
        } catch (error) {
            console.error("Error in 'DatabaseContainer.js -> ModuleLinkObject.class -> getModuleElement()':" + error);
        }
        return element;
    }

    onModuleOnChange = (moduleId) => {
        try {
            let _state = this.state;
            _state.updatedInfo.module_id = moduleId;
            let objectName = this.state.object.name;
            if (moduleId === 0) {
                $("div.multi-select-" + objectName).css("display", "none");
                _state.selectedObjects = [];
            } else {
                $("div.multi-select-" + objectName).css("display", "block");
            }
            let module = { id: moduleId };
            _state.module = module;
            _state.updatedInfo.selected_objects = this.state.selectedObjects;
            this.setState({ _state });
            this.props.updateInfoForSave(_state.object.id, _state.updatedInfo);
        } catch (error) {
            console.error("Error in 'DatabaseContainer.js -> ModuleLinkObject.class -> onModuleOnChange()':" + error);
        }
    }

    render() {
        let { key, style } = this.props;

        let object = getObjectParam(this.state.object);
        return <div key={'card-' + key} style={{ padding: 10, display: 'flex', ...style }}>
            <div style={{ width: '5%', ...textOverflow, padding: '12px 2px', display: 'flex', alignItems: 'center' }}>{/*this.getEditLabelIcon(object.type)*/}</div>
            <div style={{ width: '22%', padding: '8px 0px', ...textOverflow, fontWeight: 600, color: '#333333c2', display: 'flex', alignItems: 'center' }}>
                <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>{this.getObjectEditLabelElement(object)}</span>
            </div>
            <div style={{ width: '13%', padding: '8px 0px', ...textOverflow, fontWeight: 600, color: '#333333c2', display: 'flex', alignItems: 'center' }}>
                <span>{this.getModuleElement()}</span>
            </div>
            <div style={{ width: '60%' }}>
                {this.getLinkObjectDropDown()}
            </div>
        </div>
    }
}

class MenuPopover extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            isOpen: false,
            label: null,
            selectedId: 0
        }
    }

    UNSAFE_componentWillMount() {
        this.setState({selectedId:this.props.selectedId});
    }

    handleClick = (event) => {
        // This prevents ghost click.
        event.preventDefault();
        this.setState({ isOpen: true, anchorEl: event.currentTarget, });
    };

    handleRequestClose = () => {
        this.setState({ isOpen: false });
    };

    getMenu = (menuItems) => {
        let items = [];
        try {
            let selectedId = getIntParam(this.state.selectedId);
            menuItems = getArrayParam(menuItems);
            if (menuItems.length > 0) {
                items = menuItems.map((item, index) => {
                    let { value, label, ...others } = item;
                    let style = { ...styles.popoverMenuItem };

                    if (selectedId === value) {
                        style.backgroundColor = '#e9e9e9';
                    }
                    let primaryText = <div style={{ ...textOverflow }}>{label}</div>;
                    return <MenuItem style={style}  {...others} onClick={this.menuOnChange.bind(this, value, label)} >{primaryText}</MenuItem>
                });
                // menu = <Menu key={'menu-' + this.props.key}>{items}</Menu>;
            }

        } catch (error) {
            console.error("Error in 'DatabaseContainer.js -> MenuPopover.class -> getMenu()':" + error);
        }
        return items;
    }

    menuOnChange = (value, label, event) => {
        try {
            this.props.onModuleOnChange(value);
            this.setState({ isOpen: false, selectedId: value, label: label });
        } catch (error) {
            console.error("Error in 'DatabaseContainer.js -> MenuPopover.class -> menuOnChange()':" + error);
        }
    }

    getLabel = () => {
        return this.state.label === null ? this.props.label : this.state.label;
    }

    render() {
        const { key, menuItems } = this.props;
        let labelProps = {};
        labelProps.children = [<div style={{ float: 'left', marginRight: 5, maxWidth: 80, ...textOverflow }}>{this.getLabel()}</div>];
        if (this.props.isShowMenu) {
            labelProps.children.push(<div><i className="fa fa-angle-down" style={{ fontSize: 18, marginTop: 2 }}></i></div>);
            labelProps.style = { cursor: 'pointer' };
            labelProps.onClick = this.handleClick;
        }

        return <div key={key}>
            <span {...labelProps} />
            {this.props.isShowMenu &&

                <Menu key={'mergeFieldmenu'}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                    open={this.state.isOpen}
                    onClose={() => { this.setState({ isOpen: false }); }}
                >
                    {this.getMenu(menuItems)}
                </Menu>

            }
        </div>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatabaseContainer);


