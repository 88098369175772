
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { styles } from '../../../services/constants/styles';
import Button from '@mui/material/Button';
import { constants } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { setHeader } from '../../../services/actions/headerActions';
import {  getActiveTab } from '../../../services/helper/sfTabManager';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";


const OperationsCustomReport =({object,type})=> {
    const dispatch =  useDispatch();
    useEffect(() => {
        let tab = getActiveTab();
        let label = tab.label;
        if (label === 'Reports') {
            label = getLocalizedStrings().label.HEADER['Reports'];
        }
        dispatch(setHeader(tab.item, label, null, false, true));
      });


    const openRequestCustomReport = () =>{
        window.open(constants.CONTACT_SUPPORT_LINK);
    }
        let msg = getLocalizedStrings().message.OPERATIONS_REPORT.NO_CUSTOM_REPORT_MESSAGE;
        return(
            <div className="pageBg clearfix" style={{ display: 'block', margin: 'auto' }}>
                <div style={{ textAlign: 'center', padding: '60px' }}>
                    <div style={{ padding: '15px'}}><label style={{ color: '#777', fontSize: '25px' }}>{msg}</label></div>
                    <div style={{ padding: '15px' }}><Button 
                        labelstyle={{ textTransform: 'none', fontSize: '22px', fontWeight: 'bold' }}
                        style={{ ...styles.primaryButton, height: '45px', minWidth: '265px', borderRadius: '20px' }}
                        onClick={()=>openRequestCustomReport(object,type)}
                    >{getLocalizedStrings().label.OPERATIONS_REPORT.REQUEST_NEW_REPORT}</Button></div>
                </div>
            </div>
    );
}

export default  OperationsCustomReport;

