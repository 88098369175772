
import React from 'react';
// import { SnackbarProvider, useSnackbar } from 'notistack';

// implement material UI snackbar


class CustomNotification extends React.Component {
    constructor(props) {
        super();
        this.state={}
       
    }
    
    render() {
        return (
            <></>
            // <Alert stack={{limit: 20, spacing: 10}} /*beep={{success: 'file:///home/pradyut/Downloads/lenka.mp3'}}*/ offset={20}/>;
        )
    }
 }
 export default CustomNotification;