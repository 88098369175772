import React, { useState, useEffect } from 'react';
import { isValidParam, getStringParam, getIntParam,getArrayParam,getObjectParam ,makeValidAppUrl,getBooleanParam} from '../../../services/helper/parameterVerifier';
import { setHeader } from '../../../services/actions/headerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import * as sfDialogs from './sfDialogs';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { addNewFormTabs } from '../../../services/helper/sfTabManager';
import { useDispatch, useSelector } from "react-redux";
import PopOver from './PopOver';
import moment from 'moment';
import { dateFormatFlatPicker,dateFormat,sortArrayObjectByPropertyForCurrency,sortArrayObjectByProperty} from '../../../services/helper/utils';
import {UpdateSalesAndRefundData} from '../../../services/actions/SalesAndRefundAction';
import { LinkToType } from '../../../services/constants/link';

import {
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    TextField,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    tableCellClasses,
    Icon
} from '@mui/material';
import styled from '@emotion/styled';
import AutocompleteMultiSelect from './AutoCompleteMultiselect';
import ShowCircularProgress from './circularProgress';
import { addTab, TYPE_DETAIL_VIEW, getActiveTabInfo ,getActiveTab} from '../../../services/helper/sfTabManager';

const CustomDateInputStyle = () => {
    return <style> {
        ".form-control[readonly] {background-color: #fff !important;}"
    }</style>
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        border: '1px solid #D3D3D3',
        textAlign: 'left',
        padding: '2px 10px 2px 3px'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
        color: '#000000',
        padding: '3px 10px 3px 3px'

    },
    [`&.${tableCellClasses.footer}`]: {
        backgroundColor: '#F5F5F5',
        color: '#000000',
        fontSize: 14,
      },

  }));

const PERIOD_ITEMS = ["This Month", "This Quarter", "This Year", "Last month", "Last Quarter", "Last Year", "Custom"];
const BASED_ITEMS= ["Invoices","Payments"];

const SalesAndRefundReport = (object) => {
    const dispatch = useDispatch();
    const appState = useSelector((state) => state.app);
    const SalesAndRefundCategory = useSelector((state) => state.salesAndRefundReport.salesAndRefundCategory);


    const [period, setPeriod] = useState('');
    const [basedItem, setBasedItem] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [scrollPosition,setScrollPosition]= useState(0);
    const [tempBasedItem,setTempBasedItem] = useState('');
    const [rowSort, setRowSort] = useState({
        sortFieldName: '',
        isDescending: false,
        onSort: (fieldName, isDescending, rows)=> onSortFn (fieldName, isDescending, rows)
    });
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortedColumn, setSortedColumn] = useState(null);
    const [rows, setRows] = useState({});
    const [columns, setColumns] = useState({});

    useEffect(()=>{
        dispatch(setHeader(constants.SALES_REFUND, getLocalizedStrings().label.SALES_AND_REFUND.SALES_AND_REFUND_TITLE, null, false, true));
        if(Object.keys(SalesAndRefundCategory).length !== 0){
            if (SalesAndRefundCategory.hasOwnProperty('selectedParams')) {
                const selectedParams = SalesAndRefundCategory.selectedParams;
                if (selectedParams) {
                    if (selectedParams.hasOwnProperty('period')) {
                        let _period = selectedParams.period;
                        setPeriod(_period);
                    }
                    if (selectedParams.hasOwnProperty('startDate')) {
                        let _startDate = selectedParams.startDate;
                        setStartDate(_startDate);
                    }
                    if (selectedParams.hasOwnProperty('endDate')) {
                        let _endDate = selectedParams.endDate;
                        setEndDate(_endDate);
                    }
                    if (selectedParams.hasOwnProperty('basedItem')) {
                        let _basedItem = selectedParams.basedItem;
                        setBasedItem(_basedItem);
                    }
                }
            }
            if(SalesAndRefundCategory){
                setTempBasedItem(SalesAndRefundCategory.Item)
            }
            if (SalesAndRefundCategory.hasOwnProperty('rows')) {
                setRows(SalesAndRefundCategory.rows);
                setShowReport(true);
            }
            if (SalesAndRefundCategory.hasOwnProperty('columns')) {
                setColumns(SalesAndRefundCategory.columns);
                setShowReport(true);
            }
         }
    },[])

    const handlePeriodChange=(tempPeriod, autoSelectPlanName)=>{
        if (tempPeriod && tempPeriod !== "") {
            let m = moment();
            let startDate = '';
            let endDate = '';
            switch (tempPeriod.toLocaleLowerCase()) {
                case 'custom':                    
                    break;
                case 'this month':
                    startDate = m.startOf('month').format(dateFormat[appState.me.date_format]);
                    endDate = m.endOf('month').format(dateFormat[appState.me.date_format]);                    
                    break;
                case 'this quarter':
                    startDate = m.startOf('quarter').format(dateFormat[appState.me.date_format]);
                    endDate = m.endOf('quarter').format(dateFormat[appState.me.date_format]);                    
                    break;
                case 'this year':
                    startDate = m.startOf('year').format(dateFormat[appState.me.date_format]);
                    endDate = m.endOf('year').format(dateFormat[appState.me.date_format]);                    
                    break;
                case 'last month':
                    m.subtract(1, 'months');
                    startDate = m.startOf('month').format(dateFormat[appState.me.date_format]);
                    endDate = m.endOf('month').format(dateFormat[appState.me.date_format]);                    
                    break;

                case 'last year':
                    m.subtract(1, 'years');                    
                    startDate = m.startOf('year').format(dateFormat[appState.me.date_format]);
                    endDate = m.endOf('year').format(dateFormat[appState.me.date_format]);                    
                    break;

                case 'last quarter':
                    m.subtract(1, 'quarters');
                    startDate = m.startOf('quarter').format(dateFormat[appState.me.date_format]);
                    endDate = m.endOf('quarter').format(dateFormat[appState.me.date_format]);                    
                    break;

                default: break;
            }
            setStartDate(startDate);
            setEndDate(endDate);
        }
        setPeriod(tempPeriod);
    }

    const handleFlatpkrDateChange =(date, field)=>{
        try {
            if (isValidParam(date)) {
                let selectedDate = new Date(date);
                let dateValue = new Date(
                    selectedDate.getFullYear(),
                    selectedDate.getMonth(),
                    selectedDate.getDate()
                );
                const momentDate = moment(dateValue);
                if (field === 'start_date') {
                    setStartDate(momentDate.format(dateFormat[appState.me.date_format]));
                }
                if (field === 'end_date') {
                    setEndDate(momentDate.format(dateFormat[appState.me.date_format]));
                }
            } else {
                if (field === 'start_date') {
                    setStartDate("");
                }
                if (field === 'end_date') {
                    setEndDate("");
                }
            }
        } catch (error) {
            console.error(
                "Error in 'RoyaltyReportV2.js -> handleFlatpkrDateChange()':" + error
            );
        }
    }
    
    const handleBasedItemChange=(value)=>{
        let tempvalue = value;
        setBasedItem(tempvalue);
    }

    const getSalesAndRefundReport = () =>{
        let params={};
        let valid = true;
        let _basedItem = basedItem;
        if(basedItem){
            setTempBasedItem(basedItem);
        }
        if(period && period.length > 0){
            if(period == 'This Month'){
                params.period =  'tm';
            }else if(period == 'Custom'){
                params.period =  'cust';
                if(startDate){
                    let currentDate = moment(startDate)
                    currentDate = currentDate.format('YYYY-MM-DD')  
                    params.from_date = currentDate;
                }else{
                    valid = false;
                }
                if(endDate){
                    let toDate = moment(endDate)
                    toDate = toDate.format('YYYY-MM-DD')
                    params.to_date = toDate;
                }else{
                    valid = false;
                }
            }else if(period == 'This Quarter'){
                params.period =  'tq';
            }else if(period == 'This Year'){
                params.period =  'ty';
            }else if(period == 'Last month'){
                params.period =  'lm';
            }else if(period == 'Last Year'){
                params.period =  'ly';
            }else if(period == 'Last Quarter'){
                params.period =  'lq';
            }
        }else{
            valid = false;
        }
        if(basedItem){
            params.show = basedItem;
        }else{
            valid = false;
        }

        if (!valid) {
            setIsLoading(false);
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SALES_AND_REFUND.ALL_OPTIONS_REQUIRED, null);
            return;
        }else{
            setIsLoading(true);
            setShowReport(true);
        }
        const promise = Promise.resolve(HTTPClient.get(endPoints.SALES_AND_REFUND.GET, params));
        if (promise) {
            promise.then(res => {
                let response = res;
                setRows(response.records);
                setColumns(response.fields);
                response.records.forEach(record => {
                    record.net_sales = record.net_sales.replace(/[()]/g, '');
                  });
                let tempdata = {
                    selectedParams: {period,startDate,endDate,basedItem},
                    Item:_basedItem,
                    rows : response.records,
                    columns : response.fields
                };
                dispatch(UpdateSalesAndRefundData(tempdata));
                setShowReport(true);
                setIsLoading(false);
            })
        }

        
    }
   const onSortFn = (fieldName, isDescending, records) => {
        let _rowSort = rowSort;
        let _records = getArrayParam(records);
        let sortFieldName = getStringParam(fieldName);
        try {
            let totalRec = _records.filter(f => {
                if (f.franchisee === 'Total') {
                    return f;
                }
            });
            let tempRecordArr = _records.filter(f => {
                if (f.franchisee !== 'Total') {
                    return f;
                }
            });

            if (fieldName === 'total_sales' || fieldName === 'total_refunds'|| fieldName === 'net_sales') {
                if (getBooleanParam(_rowSort.isDescending)) {
                    sortArrayObjectByPropertyForCurrency(tempRecordArr, sortFieldName, appState.me.currency_symbol);
                    _rowSort.isDescending = false;
                }
                else {
                    sortArrayObjectByPropertyDescForCurrency(tempRecordArr, sortFieldName, appState.me.currency_symbol);
                    _rowSort.isDescending = true;
                }
            } else {
                if (getBooleanParam(_rowSort.isDescending)) {
                    sortArrayObjectByProperty(tempRecordArr, sortFieldName);
                    _rowSort.isDescending = false;
                }
                else {
                    sortArrayObjectByPropertyDesc(tempRecordArr, sortFieldName);
                    _rowSort.isDescending = true;
                }
            }

            if (tempRecordArr.length > 0) {
                if (totalRec.length > 0) {
                    tempRecordArr.push(totalRec[0]);
                }
                _records = tempRecordArr;
            }
            cookieSetSortData(_rowSort.isDescending);
            _rowSort.sortFieldName = sortFieldName;
            setRowSort({ ..._rowSort });
            setRows(_records);
        }
        catch (error) {
            console.error("Error in 'SalesAndRefundReport.js -> onSort()':" + error);
        }
    }

   const sortArrayObjectByPropertyDescForCurrency = (array, property, currency) => {
    if (Array.isArray(array)) {
        array.sort((obj1, obj2) => {
            if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                let val1 = obj1[property];
                let val2 = obj2[property];

                val1 = typeof val1 === "string" ? val1.toLowerCase() : val1;
                val2 = typeof val2 === "string" ? val2.toLowerCase() : val2;

                val1 = val1.replace(currency, '');
                val1 = val1.replace(',', '');
                val1 = val1.replace('.', '');
                val1 = val1.trim();

                val2 = val2.replace(currency, '');
                val2 = val2.replace(',', '');
                val2 = val2.replace('.', '');
                val2 = val2.trim();

                val1 = getIntParam(val1);
                val2 = getIntParam(val2);

                if (val1 > val2) {
                    return -1;
                } else if (val1 < val2) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
    }
}

const sortArrayObjectByPropertyDesc = (array, property) => {
    if (Array.isArray(array)) {
        array.sort((obj1, obj2) => {
            if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                let val1 = obj1[property];
                let val2 = obj2[property];

                val1 = typeof val1 === "string" ? val1.toLowerCase() : val1;
                val2 = typeof val2 === "string" ? val2.toLowerCase() : val2;

                if (val1 > val2) {
                    return -1;
                } else if (val1 < val2) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
    }
}

    const handleSortClick = (columnName,isDescending) => {
         if (columnName === sortedColumn) {

            isDescending = sortOrder === 'asc' ? 'desc' : 'asc';
        } else {
            isDescending = 'asc';
            setSortedColumn(columnName);
        }
        setSortOrder(isDescending);
        onSortFn(columnName, isDescending , rows);
    };
  


const cookieSetSortData = (value) => {
    let userid = appState.me.id;
    let cVarName = "isDescending_" + userid;
    if (value) {
        document.cookie = cVarName + "=yes";
    } else {
        document.cookie = cVarName + "=no";
    }
}

    function getNoRecordContent() {
        return (
            <div className="row" style={{ padding: '0px', display: "flex"}}>
                <h5 style={{ color: 'red' }}>{getLocalizedStrings().message.ROYALTY_REPORT_V2.NO_RECORDS}</h5>
            </div>
        )
    }

    function excelGenerateBeforeHeaders(columnsLength) {
        let cells = '';
        let reportName = getStringParam(getLocalizedStrings().label.SALES_AND_REFUND.SALES_AND_REFUND_REPORT);

        cells = cells + '<tr><td style="text-align:center; font-weight:bold" colspan="' + (columnsLength) + '"> ' + reportName + ' </td></tr>';
        if (reportName.length > 0) {
            let start_date = moment(startDate);
            start_date = start_date.format('DD/MM/YYYY');
            let end_date = moment(endDate);
            end_date = end_date.format('DD/MM/YYYY')
            cells = cells + '<tr><td style="text-align:center;">' + getLocalizedStrings().label.SALES_AND_REFUND.START_DATE + ':</td>  <td style="text-align:center;">' + start_date + ' </td>  <td style="text-align:center;">' + getLocalizedStrings().label.SALES_AND_REFUND.END_DATE + ':</td> <td style="text-align:center;">' + end_date + ' </td></tr>';
            cells = cells + '<tr><td style="text-align:center;">'+ getLocalizedStrings().label.SALES_AND_REFUND.BASED_ON +':</td> <td style="text-align:center;">'+basedItem+'</td></tr>'
        }
        return cells;
    }

    function getBlankTDs(num) {
        let p = '';
        while (num > 0) {
            p += '<td></td>';
            num--;
        }
        return p;
    }

    function excelGenerateHeaders(columns) {
        let cells = '';
        columns.map(f => {
            if (f.is_currency) {
                cells = cells + '<th bgcolor="6A8CCB" style="text-align: right"> ' + f.label + ' </th>';
            } else {
                cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
            }
            
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }


    function excelGenerateRows(columns, rows) {
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            let colVal = '';
            rows.map((rowObject, rowIndex) => {
                let innerCells = '';
                columns.map((columnObject, colIndex) => {
                    colVal = isValidParam(rowObject[columnObject.name]) ? rowObject[columnObject.name] : '';
                    let align = 'left'
                    if (columnObject.is_currency) {
                        align = "right"
                    }
                    colVal = colVal.toString();

                    if (rowIndex === (rows.length - 1)) {
                        innerCells = innerCells + '<td  bgcolor="gray" style="color: white;font-weight:bold;text-align:' + align +'"><b>' + colVal + '</b></td>';
                    } else {
                        if (rowIndex % 2 === 0) {
                            innerCells = innerCells + '<td style="text-align:' + align +'">' + colVal + ' </td>';
                        } else {
                            innerCells = innerCells + '<td bgcolor="Lavender" style="text-align:' + align +'">' + colVal + ' </td>';
                        }
                    }
                })
                if (rowIndex % 2 === 0) {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                } else {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                }

            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
        }
        return rowData;

    }

    function replaceCurrencySymbols(htmldata) {
        htmldata = htmldata.replace(/€/gi, '&#x20AC;');
        htmldata = htmldata.replace(/￦/gi, '&#x20A9;');
        htmldata = htmldata.replace(/£/gi, '&#xa3;');
        htmldata = htmldata.replace(/₺/gi, '&#x20BA;');
        htmldata = htmldata.replace(/￥/gi, '&#xa5;');
        htmldata = htmldata.replace(/руб./gi, '&#x440;' + '&#x443;' + '&#x0431;' + ".");
        return htmldata;
    }


    function downloadExcel() {
        let _columns = columns.filter(f => !f.is_hidden);
        let columnsLength = columns.length;
        if (Object.keys(rows).length > 0) {
            let beforeHeaderComponent = excelGenerateBeforeHeaders(columnsLength);
            let htmlContent = '<table border="1">' + beforeHeaderComponent;
            let headerComponents = excelGenerateHeaders(_columns);
            let rowComponent = excelGenerateRows(_columns, rows);
            htmlContent += '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponent + '</tbody>';
            htmlContent += '</table>';
            let htmlData = htmlContent;
            if (isValidParam(htmlData) && htmlData !== '') {
                htmlData = htmlData.replace("%22", "\"");
                htmlData = replaceCurrencySymbols(htmlData);
                var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
                var downloadUrl = URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = downloadUrl;
                a.download = 'SalesAndRefundSet_' + new Date().getTime() + '.xls';
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        } else {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.NO_RECORD_EXPORT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
    }


    const generateHeadersToPrint = (records,fields) => {
        let cells = '', temp;
        let tempColumns = getArrayParam(fields);
        let columns = tempColumns.filter(f => {
            if (!f.is_hidden) {
                temp = f;
            }
            return temp;
        });
        columns.forEach(f => {
            cells = cells + '<th style="border:1px solid black;"> ' + f.label + ' </th>';
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    const generateRowsToPrint = (records,fields) => {
        let rows = getArrayParam(records);
        let columns = getArrayParam(fields);
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            rows.forEach((rowObject, rowIndex) => {
                let innerCells = '';
                columns.forEach((columnObject, colIndex) => {
                    if (!columnObject.is_hidden) {
                        if(rowObject[columnObject.name] !== undefined)
                        {
                            innerCells = innerCells + '<td style="border:1px solid black;">' + rowObject[columnObject.name] + ' </td>';
                        }else if(rowObject[columnObject.name] == undefined){
                            innerCells = innerCells + '<td style="border:1px solid black;">' + ' ' + ' </td>';
                        }
                    }
                })
                cells = cells + ('<tr>' + innerCells + ' </tr>');
            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '" style="border:1px solid black;"> No data Found  </td>  </tr>');
        }
        return rowData;

    }

    
    function printPdf(){
        let fields = columns;
        let records = rows;
        if (getArrayParam(records).length > 0 && getArrayParam(fields).length > 0) {
            let headerComponents = generateHeadersToPrint(records,fields);
            let rowComponents = generateRowsToPrint(records,fields);
            let htmlTable = '<table style="border:1px solid black;border-collapse:collapse;"><thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '  </tbody> </table>';
            let htmlData = htmlTable;

            if (isValidParam(htmlData) && htmlData !== '') {
                    var ifrmae = document.getElementById("ifmcontentstoprint");
                    ifrmae.style.display = 'block'
                    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                    ifrmae.style.display = 'block'

                    pri.document.open();
                    pri.document.write(htmlData);
                    pri.document.close();
                    pri.focus();
                    pri.print();
                    ifrmae.style.display = 'none';
                }
        }
    }

    const getReportFilter=()=>{
        let datePickerOptions = {
            enableTime: false,
            dateFormat: dateFormatFlatPicker[appState.me.date_format],
            minuteIncrement: 1,
            static: true
        }
        let div = (
            <>
                <div className="row" style={{ padding: '0px', display: "flex", marginLeft: '-14px', marginTop: '20px' }}>
                    <CustomDateInputStyle />
                    <iframe id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute', display: 'none' }}></iframe>

                    <div className="col-md-2" style={{ paddingLeft: '0px',display:'flex',width:'12rem' }}>
                        <FormControl variant="outlined" style={{  }} className='rrfc-select'>
                            <InputLabel id="sf-salesandrefund-simple-select-timeline-outlined-label" style={{marginTop:period.length > 0 ?'3px' :0}} className="sf-salesandrefund">{getLocalizedStrings().label.SALES_AND_REFUND.PERIOD}</InputLabel>
                            <Select
                                key="timeline"
                                name="timeline"
                                labelId='timeline-label'
                                id='SalesandrefundPeriod'
                                style={{width:'12rem'}}
                                value={period}
                                size="small"
                                className={"sf-select-height"}
                                label={getLocalizedStrings().label.SALES_AND_REFUND.PERIOD}
                                onChange={(event) => handlePeriodChange(event.target.value)}
                            >
                                {
                                    PERIOD_ITEMS.map(item => <MenuItem key={item} value={item} style={styles.popoverMenuItem} >{item}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-2" style={{ paddingLeft: '0px',display:'flex',width:'11rem'}} >
                        <div
                            style={{ marginLeft:'15px' }}
                            className={"sf-form-field dateTimeDiv-start_date"}
                            id="dateholder"
                        >
                            <PopOver
                                id="SalesandrefundDate"
                                key={"start_date"}
                                name={"start_date"}
                                btnType={"date"}
                                buttonStyle={{ fontSize: "16px" }}
                                containerStyle={{ lineHeight: 1,width:'11rem'}}
                                buttonLabel={getLocalizedStrings().label.SALES_AND_REFUND.START_DATE}
                                onChange={(date) => handleFlatpkrDateChange(date, "start_date")}
                                innerLabelStyle={{
                                    padding: "14 5 2 5",
                                    fontSize: "15px",
                                }}
                                value={startDate}
                                height={47}
                                options={datePickerOptions}
                                disable={!period || period === '' || period.toLocaleLowerCase() !== 'custom'}
                            />
                        </div>
                    </div>
                    <div className="col-md-2" style={{ display:'flex',width:'11rem',marginLeft:'15px'}} >
                        <div
                            style={{}}
                            className={"sf-form-field dateTimeDiv-end_date"}
                            id="dateholder2"
                        >
                            <PopOver
                                id="SalesandrefundDate1"
                                key={"end_date"}
                                name={"end_date"}
                                btnType={"date"}
                                buttonStyle={{ fontSize: "16px" }}
                                containerStyle={{ lineHeight: 1,width:'11rem',marginLeft:'0px'}}
                                buttonLabel={getLocalizedStrings().label.SALES_AND_REFUND.END_DATE}
                                onChange={(date) => handleFlatpkrDateChange(date, "end_date")}
                                innerLabelStyle={{
                                    padding: "14 5 2 5",
                                    fontSize: "15px",
                                }}
                                height={47}
                                options={{ ...datePickerOptions, minDate: startDate }}
                                value={endDate}
                                disable={!period || period === '' || period.toLocaleLowerCase() !== 'custom'}
                            />
                        </div>
                        <div className="col-md-2" style={{ }}>
                            <FormControl variant="outlined" style={{ }} className='rrfc-select'>
                                <InputLabel id="sf-salesandrefund-simple-select-timeline-outlined-label" style={{marginTop:basedItem.length > 0 ?'3px' :0}} className="sf-salesandrefund">{getLocalizedStrings().label.SALES_AND_REFUND.BASED_ON}</InputLabel>
                                <Select
                                    key="timeline"
                                    name="timeline"
                                    labelId='timeline-label'
                                    style={{width:'12rem'}}
                                    id='basedOn'
                                    value={basedItem}
                                    size="small"
                                    className={"sf-select-height"}
                                    label={getLocalizedStrings().label.SALES_AND_REFUND.BASED_ON}
                                    onChange={(event) => handleBasedItemChange(event.target.value)}
                                >
                                    {
                                        BASED_ITEMS.map(item => <MenuItem key={item} value={item} style={styles.popoverMenuItem} >{item}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-1" style={{ paddingLeft: '0px', display: 'flex', height: '47px', alignItems: 'center' ,marginLeft:'12rem'}}>
                            <Button
                                onClick={() => getSalesAndRefundReport()}
                                style={{
                                    ...styles.primaryButton,
                                    minWidth: '56px',
                                    width: '19%'
                                }}
                                title={getLocalizedStrings().label.REPORT.GO}
                            > {getLocalizedStrings().label.REPORT.GO}</Button>
                            {
                                showReport && (
                                    <div style={{ display:'flex'}}>
                                        <Icon onClick={() => { printPdf(); }} title={getLocalizedStrings().label.REPORT.PRINT} style={{ fontSize: '23px',cursor: 'pointer', color: '#717171',paddingTop: '3px',marginLeft:'5px' }}>print</Icon>
                                        <i onClick={() => { downloadExcel(); }} className="far fa-file-excel" title="Excel" style={{ fontSize: '21px', color: 'green', cursor: 'pointer', paddingTop: '3px',marginLeft:'10px' }} />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>

            </>
        )
        return div;
    }

    const generateHeaders = (column) => {
        let cells = []; 
        let ascSortIcon = '▲';
        let descSortIcon = '▼';
        cells = column.map((columnObject, index) => {
            if (!columnObject.is_hidden) {
                let isSorted = columnObject.name === sortedColumn;
                let _isDescending = rowSort.isDescending;
                let sortIcon = isSorted && _isDescending ? descSortIcon : ascSortIcon;
                return (
                    <StyledTableCell key={index}
                        onClick={() => handleSortClick(columnObject.name,sortIcon)}>
                        <span style={{ fontSize: '15px' }}>
                            {columnObject.label}
                        </span>
                        <div style={{ float: 'right' }}>
                            {isSorted ? sortIcon : null}
                        </div>
                    </StyledTableCell>
                );
            }
        });
        return (
            <TableRow>
                {cells}
            </TableRow>
        );
    }

    const generateRows = (columns, rows) => {
        return rows.map((rowObject, rowIndex) => {
          let cells = columns.map((columnObject, colIndex) => {
           let openLink = rowObject.franchisee !== "Total" && columnObject.is_detail_link;
         if (!columnObject.is_hidden) {
              return (
                <StyledTableCell key={rowIndex + ',' + colIndex}>
                  <div onClick={openLink ? () => openQuotationTab(rowObject, columnObject) : null} style={{ float: 'left', cursor: openLink ? 'pointer' : 'default' }} >
                    <span style={{fontWeight: rowObject.isTextBold ? 'bold' : '400', color: openLink ? '#337ab7' : '#000000',fontSize: '15px',}}>{rowObject[columnObject.name]}</span>
                  </div>
                </StyledTableCell>
              )
            }
          });
        return (
            <TableRow>
              {cells}
            </TableRow>
          );
        });
      };
      
    const openSalesAndRefundListView =(columnObject,rowdata)=>{
        if((columnObject.name === "total_sales" && rowdata.total_sales !== "$0.00") || (columnObject.name === "total_refunds" && rowdata.total_refunds !== "$0.00"))
        {
            let reportLabel = rowdata.franchisee;
            let tabParams = {
                "imgName": "fa fa-users",
                "index": 0,
                "info": {
                    isReport: true,
                    reportLabel: reportLabel,
                    parentId: rowdata['account_record_id'],
                    row: rowdata,
                    from_date:startDate,
                    to_date : endDate,
                    period : period,
                    show : (tempBasedItem == basedItem)?basedItem:tempBasedItem ,
                },
                "columns" : columnObject,
                "isActive": false,
                "label": reportLabel,
                "object": constants.SALES_AND_REFUND_DETAIL,
                "type": 'TYPE_REPORT',
                "url": '/' + makeValidAppUrl(constants.SALES_AND_REFUND_DETAIL) + '/' + LinkToType.TYPE_REPORT,

            };
            addTab(tabParams, true);
        }
    }
    const openUnitDetailView = (columnObject,rowdata)=>{
        let accountRecordId = rowdata.account_record_id;
        try {
            let linkUrl = '/' + constants.ACCOUNTS_OBJECT + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + accountRecordId;
            let activeTab = getObjectParam(getActiveTab());
            let activeTabIndex = getIntParam(activeTab.index);
            let info = getObjectParam(activeTab.info);
            let unit = rowdata.franchisee;
            let tab = { label: unit, object: constants.ACCOUNTS_OBJECT, type: TYPE_DETAIL_VIEW, imgName: null, url: linkUrl, isActive: true, parentTabIndex: activeTabIndex };
            addTab(tab, true);

        } catch (error) {
            console.error("Error in 'complianceReport.js -> openUnitDetailView()':" + error);
        }
    }
    
    const openQuotationTab = (rowdata,columnObject) => {
        if (columnObject.name !== "franchisee" || columnObject.label !== "Unit" ) {
            openSalesAndRefundListView(columnObject,rowdata);
        } else {
            openUnitDetailView(columnObject,rowdata);
        }
    }

    const getReportView =()=>{
        let view = [];
        let _rows = rows;
        let _columns= columns
        if ((!_rows || _rows.length === 0)) {
            return getNoRecordContent();
        }else{
           let headerComponents = generateHeaders(_columns);
            let rowComponents = generateRows(_columns, _rows);
            view.push(
                <div style={{...styles.sf_12, display: 'flex', flexDirection: 'column'}}>
                    <div id="divcontents" key={"divcontents" + rowSort.isDescending + "-" + rowSort.sortFieldName}>
                   <TableContainer component={Paper} >
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead style={{cursor: 'pointer'}}>
                                {headerComponents}
                            </TableHead>
                            <TableBody>
                                {rowComponents}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </div></div>
            )
        }
        return view;
    }



    let tempStyle = { marginLeft: 42, width: '96%', paddingTop: 0 ,height:'68%',overflowY : !isLoading ?'scroll':'hidden',paddingLeft:'25px'};
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 3
    return(
        <div style={tempStyle}>
            {getReportFilter()}
            <div style={{ marginBottom: '1.5rem',marginTop:'15px',marginLeft:'-14px' }}>
                {rows && showReport && ( !isLoading ? getReportView() : ( 
                            <div style={{ width: '100%', height: contentHeight }}>
                                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }
            </div>
        </div>
    )
}
export default SalesAndRefundReport;