import React from 'react';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { Link } from 'react-router-dom';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';

const mapStateToProps = (state) => {
    return {
        app: state.app,
        appContainer: state.appContainer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAppDialogActions: (actions) => {
            dispatch(setAppDialogActions(actions));
        },
        getAppDialog: (isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom) => {
            dispatch(getAppDialog(isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom));
        }
    }
};

class ExecuteDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleClose = () => {
        this.props.getAppDialog(false, null, null, null, null, null);
    }

    handleOk = () => {
        this.props.appContainer.dialogProps.eventName();
    }

    componentDidMount() {
        this.props.setAppDialogActions(this.getActions());
    }

    getActions = () => {
        const actions = [
            <Button
                primary={true}
                onClick={this.handleOk}
                style={styles.primaryButton}
            >{getLocalizedStrings().label.COMMON.OK}</Button>,
            <Button
                primary={true}
                onClick={this.handleClose}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,

        ];
        return actions;
    }

    render() {
        return (
            <div style={{ paddingLeft: 2, paddingRight: 100 }}>
                <div className="row">
                    {getLocalizedStrings().message.AB_CAMPAIGNS.CAMPAIGN_EXECUTE_IN_60_MIN}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExecuteDialog);