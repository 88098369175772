import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import { setHeader } from '../../../services/actions/headerActions';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { Chip, FormControl, TextField } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { isValidParam,getArrayParam } from '../../../services/helper/parameterVerifier';
import { OBJECT_TABLEID_MAP, TABLEID_OBJECT_MAP } from '../../../services/constants/constants';
import { getSFFormFieldsPromise } from '../../../services/actions/sfFormActions';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { refreshListView } from '../../../services/actions/listViewAcions';
import Autocomplete from '@mui/material/Autocomplete';


const RollUpSetUp = ({ object }) => {

        const [selectedChildObject, setSelectedChildObject] = useState('');
        const [childObjectList, setChildObjectList] = useState([{ label: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_CHILD, value: '' }]);
        const [parentObjectList, setParentObjectList] = useState([{ label: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_PARENT, value: '' }]);
        const [selectedParentObject, setSelectedParentObject] = useState('');
        const [parentOptionList, setParentOptionList] = useState([{ label: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_PARENT_FIELD, value: '' }]);
        const [childOptionList, setChildOptionList] = useState([{ label: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_CHILD_FIELD, value: '' }]);
        const [selectedChildField, setSelectedChildField] = useState('');
        const [selectedParentField, setSelectedParentField] = useState('');
        const [rollUpId, setRollUpId] = useState(0);
        const [selectedOptionsListChild, setSelectedOptionsListChild] = useState([]);
        const [fields, setFields] = useState([]);
    
        const dispatch = useDispatch();
    
        const menuItemChildObject = useSelector((state) => state.app.objectList);
        const sfForm = useSelector((state) => state.sfForm);
        const appContainer = useSelector((state) => state.appContainer);
        const menuItemParentObject = useSelector((state) => state.app.linkChildInfo);
        const listViewData = useSelector((state) => state.listView);

    useEffect(() => {
        let recordId = 0;
        let selectedRows = listViewData.selectedRows;
        for (let i = 0; i < selectedRows.length; i++) {
            recordId = selectedRows[i].id;
        }
        if (recordId > 0) {
            let _fields =  listViewData.data.fields;
            try {
                 var promise = Promise.resolve(HTTPClient.get(endPoints.USERS.EDIT_ROLLUP_RULES + '/' + recordId, null));
                 if (isValidParam(promise)) {
                     promise.then((response) => {
                         if (isValidParam(response)) {
     
                             setRollUpId(response.id);
                             setSelectedChildObject(TABLEID_OBJECT_MAP[response.childId]);
                             setSelectedParentObject(TABLEID_OBJECT_MAP[response.parentId]);
                             setSelectedChildField(response.childFieldName);
                             setSelectedParentField(response.parentFieldName);
                          } 
                        
                     });
                 }
                 getDropDownObjects();
             } catch (error) {
                 console.error("Error in 'RollUpSetUp.js -> getRollUp()':" + error);
             }
        }
        getDropDownObjects();

    }, [rollUpId]);

    const getDropDownObjects = () => {
        let dropDownList = [];
        let selectedItem = selectedChildObject;
         try {
            let cItems = menuItemChildObject;
            let parentItems = cItems.sort((a, b) => (a.name > b.name) ? 1 : -1);
            if (parentItems.length > 0) {
                parentItems.map((items) => {
                    let objectList = {
                        label: items.label,
                        value: items.name,
                        title: items.label,
                        name: items.name,
                    };

                    dropDownList.push(objectList);
                });
            }
            sortArrayObjectByProperty(dropDownList, 'label');
            dropDownList.unshift({ label: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_CHILD, value: '' });

        } catch (error) {
            console.error('Error in RollUpSetUp.js -> getDropDownObjects() : ' + error);
        }
        let pObject = getDropDownParentObject("normalCall", selectedItem);
        setChildObjectList(dropDownList);
        setParentObjectList(pObject.ParentList);
        getObjectFieldsChild(selectedItem);
        getObjectFeildsParent("normalCall", pObject.selectedParentObject);
    }
   const handleOnClickchildDropDown = (event, value) => {
        let pObject = getDropDownParentObject("handelCallChange", value.value);
        let _selectedChildObject = selectedChildObject;
        _selectedChildObject= value.value;
        let dropDownParentList = pObject.ParentList.filter(f => { return f.value !== _selectedChildObject });
        if (value.value != '') {
            setParentObjectList(dropDownParentList);
        } else {
            setParentObjectList(pObject.ParentList);
        }
        setSelectedParentObject('');
        setSelectedParentField('');
        setSelectedChildField('');
        setSelectedChildObject(_selectedChildObject);
        setParentOptionList([{ label: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_PARENT_FIELD, value: '' }]);

        if (_selectedChildObject !== '') {
            getObjectFieldsChild(value.value);
        }
        if (selectedParentObject !== '' || selectedChildObject !== '') {
            getObjectFeildsParent("normalCall", selectedParentObject);
        }
    }

     const handleOnClickParentdDropDown = (event, value) => {
        let _listViewData = listViewData.data.records;
        let cItems = menuItemChildObject;
        let existObj = [];
        let selectedChildObj = cItems.filter(f => { return f.name == selectedChildObject });
        let selectedParentObj = cItems.filter(f => { return f.name == value.value });
        if (selectedParentObj.length > 0 && selectedChildObj.length > 0) {
            existObj = _listViewData.filter(f => { return f.child_table_id == selectedChildObj[0].label && f.parent_table_id == selectedParentObj[0].label });
        }
        setSelectedParentField('');
        if (existObj.length > 0) {
            getObjectFeildsParent("ChangeCall", value.value)
            setSelectedParentField(existObj[0].temp_parent_field_name);

        } else if (value.value != '') {
            getObjectFeildsParent("handleChangeCall", value.value);
        } else {
            setSelectedParentObject(value.value);
            setParentOptionList([{ label: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_PARENT_FIELD, value: '' }]);
        }
    }

 const handelOnChangeChildField = (event, value, index) => {
        
        setSelectedChildField(value.value);
    }
    const handelOnChangeParentField = (event, value, index) => {
        setSelectedParentField(value.value);
    }
   const getDropDownParentObject = (callType, cObject) => {
        let dropDownParentList = [];
        let selectedParentItem = selectedParentObject;
        let cItems = menuItemChildObject;
        let parentOBJ = [];
        try {
            if (isValidParam(menuItemParentObject) && cObject !== '') {
                let parentObjectList = [];
                let objects = Object.keys(menuItemParentObject);
                objects.map(function (object) {
                    let childObjList = menuItemParentObject[object];
                    let objName = TABLEID_OBJECT_MAP[object];
                    let childObj = childObjList.filter(f => { return f.objectName === cObject });
                    if (childObj.length > 0) {
                        parentOBJ = cItems.filter(f => { return f.name === objName });
                        if (parentOBJ.length > 0) {
                            parentObjectList.push(parentOBJ[0]);
                        }
                    }

                });
                parentObjectList.map((items, Index) => {
                    let objectList = {
                        label: items.label,
                        value: items.name,
                        title: items.label,
                        name: items.name,
                    };
                    dropDownParentList.push(objectList);
                }
                )
                sortArrayObjectByProperty(dropDownParentList, 'label');
                dropDownParentList.unshift({ label: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_PARENT, value: '' });
            } else {
                dropDownParentList.unshift({ label: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_PARENT, value: '' });

            }
        } catch (error) {
            console.error('Error in RollUpSetUp.js -> getDropDownParentObject() : ' + error);
        }
        var pObject = { ParentList: dropDownParentList, selectedParentObject: selectedParentItem };
        return pObject;
    }



    const getObjectFieldsChild = (objectNames) => {
        let selectChildObjetList = [];
        let optionsListchildItem = [];
        let _selectedChildField = selectedChildField;
        try {
            let optionsList = sfForm.data[objectNames];
            if (objectNames !== getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_CHILD) {
                if (!isValidParam(optionsList)) {
                    let promise = getSFFormFieldsPromise(objectNames);
                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            if (isValidParam(response)) {
                                sfForm.data[objectNames] = response;
                                optionsListchildItem = response.fields;
                                if (optionsListchildItem.length > 0) {
                                    optionsListchildItem.map((items, Index) => {
                                        if (items.field_type === "integer" || items.field_type === "float") {
                                            if (items.name !== "workflow_id" && items.name !== "main_contact_id" && items.name !== "lead_score") {
                                                let objectList = {
                                                    label: items.label + ' (' + items.field_type + ')',
                                                    value: items.name,
                                                    title: items.label,
                                                    name: items.name,
                                                    id: items.field_type_id
                                                };
                                                selectChildObjetList.push(objectList);
                                            }
                                        }
                                    }
                                    )
                                }

                                sortArrayObjectByProperty(selectChildObjetList, 'label');
                                selectChildObjetList.unshift({ label: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_CHILD_FIELD, value: '' });
                                setChildOptionList(selectChildObjetList);
                                if (_selectedChildField === '' && selectChildObjetList.length > 0) {
                                    setSelectedChildField(selectChildObjetList[0].value);
                                }

                            }
                        });
                    }
                } else {
                    optionsListchildItem = optionsList.fields;
                    if (optionsListchildItem.length > 0) {
                        optionsListchildItem.map((items, Index) => {
                            if (items.field_type === "integer" || items.field_type === "float") {
                                if (items.name !== "workflow_id" && items.name !== "main_contact_id" && items.name !== "lead_score") {
                                    let objectList = {
                                        label: items.label + ' (' + items.field_type + ')',
                                        value: items.name,
                                        title: items.label,
                                        name: items.name,
                                        id: items.field_type_id
                                    };
                                    selectChildObjetList.push(objectList);
                                }
                            }
                        }
                        )
                    }
                    sortArrayObjectByProperty(selectChildObjetList, 'label');
                    selectChildObjetList.unshift({ label: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_CHILD_FIELD, value: '' });
                    setChildOptionList(selectChildObjetList);
                    if (_selectedChildField === '' && selectChildObjetList.length > 0) {
                        setSelectedChildField(selectChildObjetList[0].value);
                    }

                }
            } else {

                let childOptionList = childOptionList;
                childOptionList.unshift({ label: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_CHILD_FIELD, value: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_CHILD_FIELD });
                let _selectedChildField = childOptionList[0].label;
                setSelectedChildField(_selectedChildField);
                setChildOptionList(childOptionList);
            }
        } catch (error) {
            console.error('Error in RollUpSetUp.js -> getObjectFieldsChild() : ' + error);
        }
    }
    const getObjectFeildsParent = (callType, objName) => {
        let selectParentObjetList = [];
        let optionsListParentItem = [];
        let _selectedParentField = selectedParentField;
        let _listViewData = listViewData.data.records;
        let cItems = menuItemChildObject;
        let selectedChildObj = cItems.filter(f => { return f.name === selectedChildObject });
        let selectedParentObj = cItems.filter(f => { return f.name === objName });
        let existObj = [];
        if (selectedParentObj.length > 0) {
            existObj = _listViewData.filter(f => { return f.child_table_id === selectedChildObj[0].label && f.parent_table_id === selectedParentObj[0].label });
        }
        try {
            let objectId = OBJECT_TABLEID_MAP[objName];
            let objectName = TABLEID_OBJECT_MAP[objectId];
            let optionsList = sfForm.data[objectName];
            if (objName !== getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_PARENT) {
                if (!isValidParam(optionsList)) {
                    let promise = getSFFormFieldsPromise(objectName);
                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            if (isValidParam(response)) {
                                sfForm.data[objectName] = response;
                                optionsListParentItem = response.fields;
                                if (optionsListParentItem.length > 0) {
                                    optionsListParentItem.map((items, Index) => {
                                        if (items.field_type === "integer" || items.field_type === "float") {
                                            if (items.name !== "workflow_id" && items.name !== "main_contact_id" && items.name !== "lead_score") {
                                                let objectList = {
                                                    label: items.label + ' (' + items.field_type + ')',
                                                    value: items.name,
                                                    title: items.label,
                                                    name: items.name,
                                                    id: items.field_type_id,
                                                };
                                                selectParentObjetList.push(objectList);
                                            }
                                        }
                                    }
                                    )
                                }
                                sortArrayObjectByProperty(selectParentObjetList, 'label');
                                selectParentObjetList.unshift({ label: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_PARENT_FIELD, value: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_PARENT_FIELD });
                                if (_selectedParentField === '' && selectParentObjetList.length > 0) {
                                    _selectedParentField = selectParentObjetList[0].value;
                                }
                                if (callType === "handleChangeCall") {
                                    setSelectedParentObject(objName);
                                    setParentOptionList(selectParentObjetList);
                                    setSelectedParentField(_selectedParentField);
                                } else if (callType === "ChangeCall") {
                                    setSelectedParentObject(objName);
                                    setParentOptionList(selectParentObjetList);
                                    setSelectedParentField(existObj[0].temp_parent_field_name);


                                } else {
                                    setParentOptionList(selectParentObjetList);
                                    setSelectedParentField(_selectedParentField);
                                }
                            }
                        });
                    }
                } else {
                    optionsListParentItem = optionsList.fields;
                    if (optionsListParentItem.length > 0) {
                        optionsListParentItem.map((items, Index) => {
                            if (items.field_type === "integer" || items.field_type === "float") {
                                if (items.name !== "workflow_id" && items.name !== "main_contact_id" && items.name !== "lead_score") {
                                    let objectList = {
                                        label: items.label + ' (' + items.field_type + ')',
                                        value: items.name,
                                        title: items.label,
                                        name: items.name,
                                        id: items.field_type_id
                                    };
                                    selectParentObjetList.push(objectList);
                                }
                            }
                        }
                        )
                    }
                    sortArrayObjectByProperty(selectParentObjetList, 'label');
                    selectParentObjetList.unshift({ label: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_PARENT_FIELD, value: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_PARENT_FIELD });
                    if (_selectedParentField === '' && selectParentObjetList.length > 0) {
                        _selectedParentField = selectParentObjetList[0].value;
                    }
                    if (callType === "handleChangeCall") {
                        setSelectedParentObject(objName);
                        setParentOptionList(selectParentObjetList);
                        setSelectedOptionsListChild([]);
                        setSelectedParentField(_selectedParentField);

                    } else if (callType === "ChangeCall") {
                        setSelectedParentObject(objName);
                        setParentOptionList(selectParentObjetList);
                        setSelectedParentField(existObj[0].temp_parent_field_name);

                    } else {
                        _selectedParentField = selectParentObjetList[0].value;
                        setParentOptionList(selectParentObjetList);
                        setSelectedParentField(_selectedParentField);
                      }
                }
            } else {
                let parentOptionList = parentOptionList;
                parentOptionList.unshift({ label: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_PARENT_FIELD, value: getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_PARENT_FIELD });
                let _selectedParentField = parentOptionList[0].label;

                setSelectedParentField(_selectedParentField);
                setParentOptionList(parentOptionList);
            }
        } catch (error) {
            console.error('Error in RollUpSetUp.js -> getObjectFeildsParent() : ' + error);
        }
    }
    const validateForm = () => {
        let isValid = true;
        let childFieldList = childOptionList;
        let parentFieldList = parentOptionList;
        let childfieldType = 0;
        let parentFieldType = 0;
        let _selectedChildField = selectedChildField;
        let _selectedParentField = selectedParentField;
        let childobj = childFieldList.filter(f => { return f.name === _selectedChildField });
        let parentdobj = parentFieldList.filter(f => { return f.name === _selectedParentField });
        if (childobj.length > 0) {
            childfieldType = childobj[0].id;
        }
        if (parentdobj.length > 0) {
            parentFieldType = parentdobj[0].id;
        }
        let _listViewData = listViewData.data.records;
        let cItems = menuItemChildObject;
        let selectedChildObj = cItems.filter(f => { return f.name === selectedChildObject });
        let selectedParentObj = cItems.filter(f => { return f.name === selectedParentObject });
        let existObj = [];
        let existParentField = [];
        let _actionType = appContainer.drawerProps.actionType;
        if (selectedParentObj.length > 0) {
            existObj = _listViewData.filter(f => { return f.child_table_id === selectedChildObj[0].label && f.parent_table_id === selectedParentObj[0].label && f.temp_child_field_name === selectedChildField && f.temp_parent_field_name === selectedParentField });
        }
        if (selectedParentObj.length > 0) {
            existParentField = _listViewData.filter(f => { return f.parent_table_id === selectedParentObj[0].label && f.temp_parent_field_name === selectedParentField });
        }
        if (selectedChildObject === "") {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.ROLL_UP.SELECT_A_CHILD_OBJECT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            isValid = false;
            return false;

        } else if (selectedParentObject === "") {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.ROLL_UP.SELECT_A_PARENT_OBJECT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            isValid = false;
            return false;
        } else if (_selectedChildField === getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_CHILD_FIELD) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.ROLL_UP.NO_CHILD_FIELD_SELECTED, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            isValid = false;
            return false;
        } else if (_selectedChildField === "") {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.ROLL_UP.NO_CHILD_FIELD_SELECTED, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            isValid = false;
            return false;
        } else if (_selectedParentField === getLocalizedStrings().label.AUTO_FILL_VALUES.SELECT_PARENT_FIELD) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.ROLL_UP.NO_PARENT_FIELD_SELECTED, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            isValid = false;
            return false;
        } else if (_selectedParentField === "") {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.ROLL_UP.NO_PARENT_FIELD_SELECTED, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            isValid = false;
            return false;
        } else if (childfieldType !== parentFieldType) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.ROLL_UP.SAME_TYPE_FIELD, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            isValid = false;
            return false;
        } else if (existObj.length > 0) {
            if(_actionType === 'Edit'){
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
            }else{
                dispatch(showCustomSnackBar(getLocalizedStrings().message.ROLL_UP.ROLL_UP_EXIST, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                isValid = false;
                return false;
            }
        } else if (existParentField.length > 0) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.ROLL_UP.PARENT_FIELD_EXIST, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            isValid = false;
            return false;
        }



        return isValid;
    }
    const closeDrawer = () => {
        dispatch(getAppDrawer(false, null, null, null, null));
    }
    const saveRollUp = () => {
        
        let params = {};
        let childId = OBJECT_TABLEID_MAP[selectedChildObject];
        let parentId = OBJECT_TABLEID_MAP[selectedParentObject];
        let rollUplid = rollUpId;
        let childFieldName = selectedChildField;
        let parentFieldName = selectedParentField;
        try {
            let isValid = validateForm();
            if (isValid) {
                params.t_pk = rollUplid;
                params.t_chilId = childId;
                params.t_childFieldName = childFieldName;
                params.t_parentId = parentId;
                params.t_parentFieldName = parentFieldName;

                var promise = Promise.resolve(HTTPClient.post(endPoints.USERS.SAVE_ROLLUP, params));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {

                            dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            let ids = response.data.id;
                            setRollUpId(ids[0]);
                            closeDrawer();
                            refreshListView(object);
                        }
                    });

                }
            }
        } catch (error) {
            console.error("Error in 'RollUpSetUp.js -> saveRollUp()':" + error);
        }
    }
    //  getRollUp = () => {
    //    try {
    //               var promise = Promise.resolve(HTTPClient.get(endPoints.USERS.GET_ROLLUP, null));
    //         if (isValidParam(promise)) {
    //             promise.then((response) => {
    //                 if (isValidParam(response)) {

    //                     this.setState({
    //                         rollUpId: response.id,
    //                         selectedChildObject: TABLEID_OBJECT_MAP[response.childId],
    //                         selectedParentObject: TABLEID_OBJECT_MAP[response.parentId],
    //                         selectedChildField: response.childFieldName,
    //                         selectedParentField: response.parentFieldName,

    //                     });
    //                     this.getDropDownObjects();
    //                 }// else {
    //                 this.getDropDownObjects();
    //                 //}
    //             });
    //         }
    //     } catch (error) {
    //         console.error("Error in 'RollUpSetUp.js -> getRollUp()':" + error);
    //     }
    // }
   
    return (
            <div style={{ width: '96%', paddingLeft: '10px' }}>
                <div className="row">
                    <div className="col-sm-6" style={{ width: '50%' }}>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <Autocomplete
                                style={{ marginBottom: '3px' }}
                                name="childObject"
                                id="childObject"
                                className='selectStatus'
                                value={childObjectList.find(v => v.value === selectedChildObject)}
                                onChange={(event,value)=>handleOnClickchildDropDown(event,value)}
                                options={childObjectList}
                                getOptionLabel={(option) => option.label}
                               disableClearable={true}
                                renderInput={(params) => <TextField {...params} label={getLocalizedStrings().label.ROLL_UP.CHILD_OBJECT_NAME} 
                                    variant="outlined" margin="dense" size="small" className={"sf-fields-bg"} />
                                }
                            />
                        </FormControl>
                    </div>

                    <div className="col-sm-6" style={{ width: '50%' }}>
                        <FormControl style={{ width: '105%' }} className="test" noValidate autoComplete="off">
                            <Autocomplete
                                name='Child Fields'
                                id="Child_Fields"
                                options={childOptionList}
                                onChange={(event,value,index)=>handelOnChangeChildField(event,value,index)}
                                value={childOptionList.find(v => v.value === selectedChildField)}
                                getOptionLabel={(option) => option.label}
                                disableClearable={true}
                                renderInput={(params) => <TextField {...params} label={getLocalizedStrings().label.ROLL_UP.CHILD_FIELD_NAME} 
                                    variant="outlined" margin="dense" size="small" className={"sf-fields-bg"}/>
                                }
                            />
                        </FormControl>
                    </div>
                </div>

                <div className="row" style={{ marginTop: 10 , width: '100%' }}>
                    <div className="col-sm-6" style={{marginTop: 3 }}>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <Autocomplete
                                name="parentObject"
                                id="parentObject"
                                onChange={(event,value)=>handleOnClickParentdDropDown(event,value)}
                                options={parentObjectList}
                                value={parentObjectList.find(v => v.value === selectedParentObject)}
                                getOptionLabel={(option) => option.label}
                                disableClearable={true}
                                renderInput={(params) => <TextField {...params} label={getLocalizedStrings().label.ROLL_UP.PARENT_OBJECT_NAME} 
                                    variant="outlined" margin="dense" size="small" className={"sf-fields-bg"}/>
                                }
                            />
                        </FormControl>
                    </div>

                    <div className="col-sm-6" style={{marginTop: 3 }}>
                        <FormControl style={{ width: '105%' }} className="test" noValidate autoComplete="off" >

                            <Autocomplete
                                name='Parent Fields'
                                id="Parent_Fields"
                                options={parentOptionList}
                                onChange={(event,value,index)=>handelOnChangeParentField(event,value,index)}
                                value={parentOptionList.find(v => v.value === selectedParentField)}
                                getOptionLabel={(option) => option.label}
                                disableClearable={true}
                                renderInput={(params) => <TextField {...params} label={getLocalizedStrings().label.ROLL_UP.PARENT_FIELD_NAME} 
                                InputLabelProps={{
                                    style: {
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                    },
                                }}
                                variant="outlined" margin="dense" size="small" className={"sf-fields-bg"} />
                                }
                            />
                        </FormControl>
                    </div>
                </div>
                <div>
                    <div style={{ width: 'auto', padding: '0px', float: 'right', marginRight: '15px', marginTop: '17px' }}>
                        <Button
                            key='save'
                            onClick={()=>saveRollUp()}

                            style={{ ...styles.primaryButton, marginRight: '5px', verticalAlign: 'top' }}
                        > {getLocalizedStrings().label.COMMON.SAVE}</Button>
                        <Button
                            key='cancel'
                            onClick={()=>closeDrawer()}

                            style={{ ...styles.secondaryButton, marginLeft: '6px', marginRight: '0px' }}
                        >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                    </div>
                </div>
            </div>
        );

    }

export default RollUpSetUp;

