
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { styles } from '../../../services/constants/styles';
import { constants,TABLEID_OBJECT_MAP } from '../../../services/constants/constants';
import { getIntParam,getStringParam, getObjectParam, isValidParam, getBooleanParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ShowCircularProgress from '../components/circularProgress';
import * as HTTPClient from '../../../services/helper/httpClient';
import * as sfDialogs from '../components/sfDialogs';
import { hasAccessPermission } from '../../../services/helper/common';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { endPoints } from '../../../services/constants/endPoints';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { addReportInRedux} from '../../../services/actions/SalesReportAction';
import { LinkToType } from '../../../services/constants/link';
import { addRecentFilterInRedux, addRecentRecordInRedux } from '../../../services/actions/SalesReportAction';
import { useDispatch, useSelector } from "react-redux";

const ReportSetup = ({ reportConfig,closeEvent,setReportTitle }) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);
    const sfForm = useSelector((state) => state.sfForm);
    const recentFilters = useSelector((state) => state.filters.recentFilters);
 const [reportName, setReportName] = useState(null);
    const [isMounted, setIsMounted] = useState(true);
    
        
   // handleClickOutside = this.handleClickOutside.bind(this);
    
   useEffect(() => {
        document.addEventListener('click', handleClickOutside);
    }, []);

    useEffect(() => {
        document.removeEventListener('click',handleClickOutside, true);
    }, []);
 
    const setFieldValue = (value) => {
        try {
            setReportName(value);

        } catch (error) {
            console.error("Error in 'reportSetup.js -> setFieldValue()':" + error);
        }
    }

   const getRequestParamObject = (reportConfigVal) =>{
        let params =  {};
        try{
          if(isValidParam(reportConfigVal)){  
            params.form_id = getIntParam(reportConfigVal.tableId);
            params.t_name =  getStringParam(reportName);
            params.t_type =  13;
            params.t_reporttype = constants.REPORT_TYPE_CUSTOM_RELATION;
            params.t_date_range = getStringParam(reportConfigVal.date.dueBy);
            params.t_range_field = getStringParam(reportConfigVal.date.name);
            params.from_date = getStringParam(reportConfigVal.date.from_date);
            params.to_date = getStringParam(reportConfigVal.date.to_date);
            params.t_row_field_id = getStringParam(reportConfigVal.row.id);
            params.t_col_field_id = getStringParam(reportConfigVal.column.id);
            
            let setup  = {};
            setup.relation_id = getIntParam(reportConfigVal.relationId);
            setup.relation_name = getStringParam(reportConfigVal.relationName);
            setup.row_field_name = getStringParam(reportConfigVal.row.name);
            setup.selected_row_values = getArrayParam(reportConfigVal.row.values);
            setup.col_field_name = getStringParam(reportConfigVal.column.name);
            setup.selected_col_values = getArrayParam(reportConfigVal.column.values);

            params.t_matrix_info = getObjectParam(setup);
          }
        }catch(error){
            console.error("Error in 'reportSetup -> getRequestParamObject()'"+error)
        }
        return params;
    }

  const  validateAndSave = () => {
        let isValid = true;
        let errMsg = "";
        let hasPermission = hasAccessPermission(null, constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_MANAGE_REPORTS);
        if (hasPermission) {
            if (getStringParam(reportName).length === 0) {
                isValid = false;
                errMsg = getLocalizedStrings().message.RELATION_REPORT.REPORT_NAME_BLANK;
            }
            if (isValid) {
                if (/^\s/.test(reportName)) {
                    isValid = false;
                    errMsg = getLocalizedStrings().message.RELATION_REPORT.VALID_REPORT_NAME;
                }
            }
            if (isValid) {
                let url = endPoints.RELATION_REPORT.REPORT_CUSTOMIZATION_SAVE;
                let tempreportConfig = getObjectParam(reportConfig());
                let params = getRequestParamObject(tempreportConfig);
                let promise = Promise.resolve(HTTPClient.post(url, params));
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (response.status === 0 && response.data > 0) {                          
                            let object = TABLEID_OBJECT_MAP[getStringParam(tempreportConfig.tableId)]
                            setReportTitle(reportName,'save_report');
                            let savedReportData = {
                                item_name: getStringParam(reportName),
                                item_type: constants.REPORT_TYPE_CUSTOM_RELATION,
                                parameter_id: getIntParam(response.data),
                                report_object: object,
                                report_subtype: 13,
                                owner_id: app.me.id,
                            }
                                let params = { 
                                    id: getIntParam(response.data), 
                                    type: LinkToType.TYPE_REPORT, 
                                    name: getStringParam(reportName), 
                                    report_type: "user-relation-matrix", 
                                };
                                addRecentFilterInRedux(params);
                                addRecentRecordInRedux({ ...params, recent: getStringParam(reportName) });
                                addReportInRedux(savedReportData);
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.RELATION_REPORT.REPORT_SAVE_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop))
                            closeSetup();
                        }else if (response.status === -1 && isValidParam(response.error)){
                            let error = getObjectParam(response.error);
                            errMsg = getLocalizedStrings().message.RELATION_REPORT[getStringParam(error.message)];
                            dispatch(showCustomSnackBar(errMsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        }
                    }
                });
            } else {
                dispatch(showCustomSnackBar(errMsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        } else {
            let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage,null);
        }
    }

  const  saveReport = () => {
        try {
        validateAndSave();
        } catch (error) {
            console.error("Error in 'reportSetup.js -> saveReport()':" + error);
        }
    }

   const closeSetup = () => {
        try {
        closeEvent();
        } catch (error) {
            console.error("Error in 'reportSetup.js -> closeSetup()':" + error);
        }
    }

   const handleClickOutside = (event) => {
        try{
            let target = $(event.target);
            let isSaveButton = false;
            let node = document.getElementById("report-save-container");
            const saveBtn = document.getElementById("save_report");
            if(event.target.offsetParent!=null && event.target.offsetParent.contains(saveBtn)){
                isSaveButton = true;
            }
            if (node !== null && target.closest("#report-save-container").length === 0 &&  !isSaveButton) {
                // clicked outside of the popup;
        closeEvent();
            }
        }catch(error){
            console.error("Error in reportSetup.js -> handleClickOutside() "+error)
        }
    } 

    
        let sfFormFieldContainerStyle = { ...styles.sfFormFieldContainer };
        let tempsfFormFieldContainerStyle = sfFormFieldContainerStyle;
        let pageHeight = window.innerHeight - 247;
        let top = (pageHeight-10) / 2;
        if (isMounted) {
            return (
                <div key="report-save-container" id="report-save-container" style={{...styles.customizedFieldsContainerDiv,width:'448px'}}>
                    <div style={{...styles.customizedFieldsHeaderDiv, paddingTop: "10px"}}>
                        <span style={styles.filterMenuHeaderStyle}>
                            {getLocalizedStrings().label.RELATION_REPORT.REPORT_SAVE_LABEL}
                        </span>
                    </div>
                    <div style={{height: '100px', paddingTop: "22px", marginLeft: "10px", marginRight: "10px" , display:'flex'}}>
                        <span style={{ ...styles.customizedFieldsHeader, marginTop: '10px', paddingRight: '15px', float: 'left',fontSize:'15px' }}>
                            {getLocalizedStrings().label.RELATION_REPORT.REPORT_NAME_LABEL}
                        </span>
                       
                        <TextField
                            id={'report_name_text_field'}
                            // ref={'report_name_text_field_ref'}
                            key={'report_name_text_field'}
                            name={'report_name'}
                            value={reportName}
                            onChange={(e)=>setFieldValue(e.target.value)}
                            autoFocus={true}
                            style={{width:'250px'}}
                            inputprops={{maxLength:225}}
                            autoComplete="new-password"
                            className={"sf-fields-bg"}
                            size="small"
                        />
                       
                    </div>

                    <div style={{ paddingTop: "5px", paddingBottom: "10px", marginLeft: "10px", marginRight: "10px" }}>
                        <Button
                            key='save'
                            onClick={()=>saveReport()}
                           
                            style={{ ...styles.primaryButton, display: 'inline-grid', marginRight: '0px', verticalAlign: 'top',lineHeight:'18px' }}
                        > {getLocalizedStrings().label.COMMON.SAVE}</Button>
                        <Button
                            onClick={()=>closeSetup()}
                            
                            style={{ ...styles.secondaryButton, display: 'inline-grid', marginLeft: '6px', marginRight: '0px',lineHeight:'18px' }}
                        >{getLocalizedStrings().label.COMMON.CLOSE}</Button>
                    </div>
                </div>
            )
        } else {
            return (<div style={{ width: '100%', height: pageHeight }}>
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
       </div>)
        }

    

}

export default ReportSetup;
