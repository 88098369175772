import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';
import {actions}  from '..//constants/actions';
import store from '../../../src/services/store';

function getRemainingCallMinutes() {
    let url = endPoints.CALLING_INTEGRATION.REMAINING_CALL_MINUTES_GET;
    try {
        return HTTPClient.get(url, null);
    } catch (e) { console.error("Error in 'callAction.js -> getRemainingCallMinutes()':" + e); }
}

function getCallScriptList(params) {
    let url = endPoints.CALLING_INTEGRATION.CALL_SCRIPT_LIST_GET;
    try {
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'callAction.js -> getCallScriptList()':" + e); }
}

function getCallScriptDetails(params) {
    let url = endPoints.CALLING_INTEGRATION.CALL_SCRIPT_DETAILS_GET;
    try {
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'callAction.js -> getCallScriptDetails()':" + e); }
}

function saveNoteAfterCall(params) {
    let url = endPoints.CALLING_INTEGRATION.NOTE_SAVE_AFTER_CALL;
    try {
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'callAction.js -> saveNoteAfterCall()':" + e); }
}

function getOutBoundPhoneList() {
    try {
        let url = endPoints.CALLING_INTEGRATION.OUTBOUND_PHONE_LIST_GET;
        return HTTPClient.get(url, null);
    } catch (e) { console.error("Error in 'callAction.js -> getOutBoundPhoneList()':" + e); }
}

function verifyOutboundPhone(params) {
    let url = '';
    try {
        url = endPoints.CALLING_INTEGRATION.VERIFY_OUTGOING_CALLER_ID;
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'callAction.js -> verifyOutboundPhone()':" + e); }
}

function cancelVerficationCall(params) {
    let url = '';
    try {
        url = endPoints.CALLING_INTEGRATION.CANCEL_VERIFICATION_CALL;
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'callAction.js -> cancelVerficationCall()':" + e); }
}

function deleteOutboundPhone(params) {
    let url = '';
    try {
        url = endPoints.CALLING_INTEGRATION.DELETE_OUTGOING_CALLER_ID;
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'callAction.js -> deleteOutboundPhone()':" + e); }
}

function checkDuplicatePhoneNumber(params) {
    let url = '';
    try {
        url = endPoints.CALLING_INTEGRATION.CHECK_DUPLICATE_OUTBOUND_PHONE;
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'callAction.js -> checkDuplicatePhoneNumber()':" + e); }
}

function getVerficationStatus(params) {
    let url = '';
    try {
        url = endPoints.CALLING_INTEGRATION.OUTBOUND_PHONE_VERIFICATION_STATUS;
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'callAction.js -> getVerficationStatus()':" + e); }
}

function postProcessVerification(params) {
    let url = '';
    try {
         url = endPoints.CALLING_INTEGRATION.OUTBOUND_CALLERID_SID_GET;
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'callAction.js -> postProcessVerification()':" + e); }
}

function enableOrDisable(params){
    let url = '';
    try {
         url = endPoints.CALLING_INTEGRATION.OUTBOUND_PHONE_ENABLE_ONOFF;
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'callAction.js -> enableOrDisable()':" + e); }
}

function callRecordingSave(params){
    let url = '';
    try {
         url = endPoints.CALLING_INTEGRATION.CALL_RECORDING_SAVE;
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'callAction.js -> callRecordingSave()':" + e); }
}

function callRecordingGet(params){
    let url = '';
    try {
         url = endPoints.CALLING_INTEGRATION.CALL_RECORDING_GET;
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'callAction.js -> callRecordingGet()':" + e); }
}




function getPowerCallingData(params,response){
    try {
        store.dispatch({type: actions.POWER_CALLING_GET, payload: params}); 
        store.dispatch({type:'POWERCALL_GET_FULLFILLED', payload: response});
    }
     catch (e) { console.error("Error in 'callAction.js -> getPowerCallingData()':" + e); }
}

function setPowerCallingStatusUpdate(newCount){
    try {
        store.dispatch({type: actions.POWERCALL_STATUS_UPDATE, payload: newCount});
    }
     catch (e) { console.error("Error in 'callAction.js -> POWERCALL_STATUS_UPDATE':" + e); }
   
}

function setPowerCallingIsCallingUpdate(isStrated){
    try {
        store.dispatch({type: actions.POWERCALL_IS_STARTED_UPDATE, payload: isStrated});
    }
     catch (e) { console.error("Error in 'callAction.js -> POWERCALL_IS_STARTED_UPDATE':" + e); }
   
}

function setPowerCallingMaxCountUpdate(isMaxCount){
    try {
        store.dispatch({type: actions.POWERCALL_IS_MAXCOUNT_UPDATE, payload:isMaxCount});
    }
     catch (e) { console.error("Error in 'callAction.js -> POWERCALL_IS_MAXCOUNT_UPDATE':" + e); }
   
}
function setPowerCallingScriptUpdate(selectedCallScript){
    try {
        store.dispatch({type: actions.POWERCALL_SCRIPT_UPDATE, payload:selectedCallScript});
    }
     catch (e) { console.error("Error in 'callAction.js -> POWERCALL_IS_MAXCOUNT_UPDATE':" + e); }
   
}
function getCallDrawer(isOpen, dialogName, actionType, style, data, headerProps){
   
        return function (dispatch) {
            try {
        dispatch({
            type: actions.GET_CALL_DRAWER,
            payload: {
                isOpen: isOpen,
                dialogName: dialogName,
                actionType: actionType,
                style: style,
                data: data,
                headerProps: headerProps
            }
        })}
        catch (e) { console.error("Error in 'callAction.js -> GET_CALL_DRAWER':" + e); }
        
    }


}

function setPowerCallingScriptDataUpdate(callScriptData){
    try {
        store.dispatch({type: actions.POWERCALL_SCRIPT_DATA_UPDATE, payload:callScriptData});
    }
     catch (e) { console.error("Error in 'callAction.js -> POWERCALL_IS_MAXCOUNT_UPDATE':" + e); }
   
}

function setPowerCallingSkipedIdsUpdate(ids){
    try {
        store.dispatch({type: actions.POWERCALL_SKIPTED_IDS_UPDATE, payload:ids});
    }
     catch (e) { console.error("Error in 'callAction.js -> POWERCALL_SKIPTED_IDS_UPDATE':" + e); }
   
}
function getPowerCallingSkipedData(ids){
    try {
        store.dispatch({type: actions.POWER_CALLING_SKIPTED_DATA_GET, payload:ids});
    }
     catch (e) { console.error("Error in 'callAction.js -> POWER_CALLING_SKIPTED_DATA_GET':" + e); }
   
}
function setPowerCallingFromNoUpdate(fromNo,fromCountry,fromExtension ){
    try {
        store.dispatch({
            type: actions.POWERCALL_FROM_NO_UPDATE, 
            fromNumber: fromNo,
            fromCountryCode: fromCountry,
            fromNoExtension: fromExtension 
        });
    }
     catch (e) { console.error("Error in 'callAction.js -> POWERCALL_IS_MAXCOUNT_UPDATE':" + e); }
   
}
function setPowerCallingNoteDataUpdate(noteData){
    try {
        store.dispatch({type: actions.POWERCALL_NOTE_DATA_UPDATE, payload:noteData});
    }
     catch (e) { console.error("Error in 'callAction.js -> POWERCALL_NOTE_DATA_UPDATE':" + e); }
   
}
function setPowerCallingNoteButtonVisibilityUpdate(noteData){
    try {
        store.dispatch({type: actions.POWERCALL_NOTE_BUTTON_VISIBILITY_UPDATE, payload:noteData});
    }
     catch (e) { console.error("Error in 'callAction.js -> POWERCALL_NOTE_BUTTON_VISIBILITY_UPDATE':" + e); }
   
}
function resetPowerCallingData(callData){
    try {
        store.dispatch({type: actions.POWERCALLINNG_PROCESS_END, payload:callData});
    }
     catch (e) { console.error("Error in 'callAction.js -> POWERCALLINNG_PROCESS_END':" + e); }
   
}

export {
    getCallDrawer,
    getRemainingCallMinutes,
    getCallScriptList,
    getPowerCallingSkipedData,
    getCallScriptDetails,
    saveNoteAfterCall,
    getOutBoundPhoneList,
    verifyOutboundPhone,
    cancelVerficationCall,
    deleteOutboundPhone,
    checkDuplicatePhoneNumber,
    getVerficationStatus,
    postProcessVerification,
    enableOrDisable,
    callRecordingSave,
    callRecordingGet,
    getPowerCallingData,
    setPowerCallingFromNoUpdate,
    setPowerCallingStatusUpdate,
    setPowerCallingIsCallingUpdate,
    setPowerCallingMaxCountUpdate,
    setPowerCallingScriptUpdate,
    setPowerCallingScriptDataUpdate,
    setPowerCallingNoteDataUpdate,
    resetPowerCallingData,
    setPowerCallingNoteButtonVisibilityUpdate,
    setPowerCallingSkipedIdsUpdate
}