import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import ShowCircularProgress from './circularProgress';
import { styles } from '../../../services/constants/styles';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { constants } from '../../../services/constants/constants';
import { getDefaultBeeEditorData } from '../../../services/helper/common';
import { getStringParam } from '../../../services/helper/parameterVerifier';
import Bee from "@mailupinc/bee-plugin";
import { setMounted } from '../../../services/actions/listViewAcions';

const PreviewTemplates=()=>{
    const [emailsubject, setEmailsubject] = useState("");
    const [emailBody, setEmailBody] = useState("");
    const [content, setContent] = useState("");
    const [isMounted, setIsMounted] = useState(false);


    let location= window.location.href;
    let currentLocation = location.split('previewtemplates/');
    let urlId = currentLocation[1];

    useEffect(()=>{
        let params = {};
        params.template_id = urlId;
        params.call_from = 'preview_template';
        var promise = Promise.resolve(HTTPClient.get(endPoints.MARKETING_TEMPLATE.TEMPLATE_GET, params));
        promise.then((response) => {
            let _emailBody = response.mail_body;
            let _emailSubjest = response.subject;
            setEmailBody(_emailBody);
            setEmailsubject(_emailSubjest);
            setIsMounted(true);
        })
    },[])

    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight - 10) / 2
    if (isMounted){
        return (
            <div id="preview" style={{ backgroundColor: '#eeeeee', padding: '15px' }}>
                    <table width="100%" border="0" cellSpacing="0" cellPadding="0" align="center">
                        <tr>
                            <td>
                                <div className="preview_title_box" style={{marginBottom:'10px'}}>{emailsubject}</div>
                                <div className="preview_content_box">
                                    <div id="main">
                                        <div id="msgBody" className="divBody">
                                            <p dangerouslySetInnerHTML=
                                                {{ __html: (emailBody) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
        )
    }else {
        return (
            <div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        )
    }
}
export default PreviewTemplates;