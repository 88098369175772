
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { addNode } from '../../../services/actions/automationCampaignActions';
import { removeError } from '../../../services/helper/automationUtils';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useDispatch, useSelector } from "react-redux";


const AutomationLandingPage = ({ data, closeDrawer }) => {
    const dispatch = useDispatch();
    const automationCampaign = useSelector((state) => state.automationCampaign);
    const [selectedLandingPage, setSelectedLandingPage] = useState(0);
    const [landingPageList, setLandingPageList] = useState(null);
    const [landingPageName, setLandingPageName] = useState("");

    useEffect(() => {
        let nodeObject = data;
        if (isValidParam(nodeObject)) {
            let detailsArray = null;
            let selectedLpId = 0;
            let landingPageName = "";
            if (nodeObject.hasOwnProperty('details')) {
                detailsArray = nodeObject.details[0];
            }
            if (isValidParam(detailsArray)) {
                selectedLpId = detailsArray.landingPageId;
                landingPageName = detailsArray.object_indentifierText;
                generateLandingPageList(selectedLpId, landingPageName);
            } else {
                selectedLpId = 0;
                generateLandingPageList(selectedLpId, landingPageName);
            }
        }
    }, []);


    const generateLandingPageList = (selectedLpId, landingPageName) => {
        if (isValidParam(selectedLpId)) {
            let params = {
                id: selectedLpId
            }
            var response = Promise.resolve(HTTPClient.get(endPoints.AUTOMATION_DESIGNER.GET_ACTIVE_LANDINGPAGE, params));
            response.then((response) => {
                let landingPageList = [];
                if (isValidParam(response)) {
                    if (response.hasOwnProperty('landingpage_list')) {
                        let arrLandingPageList = response.landingpage_list;
                        landingPageList = arrLandingPageList.map((objLandingPage, i) => {
                            return (
                                <MenuItem value={objLandingPage.id} title={objLandingPage.name} onClick={() => selectLandingPage(objLandingPage)} >{objLandingPage.name}</MenuItem>
                            );
                        });
                        setSelectedLandingPage(selectedLpId);
                        setLandingPageList(landingPageList);
                        setLandingPageName(landingPageName)

                    }
                }
            });
        }
    }

    const selectLandingPage = (objLandingPage) => {
        setSelectedLandingPage(objLandingPage.id);
        setLandingPageName(objLandingPage.name);
    }

    const openDialog = (dialogData) => {
        dispatch(getAppDialog(true, constants.ALERT_DIALOG, getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, null, dialogData, constants.AUTOMATION_DESIGNER_OBJECT));
    }

    const saveLandingPage = () => {
        try {
            let nodeObject = data;
            let landingPageId = selectedLandingPage;
            let object_indentifierText = landingPageName;
            let dialogData = null;
            if (landingPageId <= 0) {
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_LANDING_PAGE,
                }
                openDialog(dialogData);
            } else {
                let detailsArray = null;
                if (nodeObject.hasOwnProperty('details')) {
                    detailsArray = nodeObject.details[0];
                }
                if (isValidParam(detailsArray)) {
                    detailsArray.landingPageId = landingPageId;
                    detailsArray.object_indentifierText = object_indentifierText;
                } else {
                    let array = [];
                    detailsArray = {
                        landingPageId: landingPageId,
                        object_indentifierText: object_indentifierText,
                    }
                    array.push(detailsArray);
                    nodeObject.details = array;
                }
                nodeObject = removeError(nodeObject);
                dispatch(addNode(automationCampaign.data));
                closeDrawer();
            }
        } catch (error) {
            console.error("Error in 'AutomationLandingPage.js -> saveLandingPage()': " + error);
        }
    }


    let campaignStatus = automationCampaign.data.status;
    return (
        <div style={
            {
                boder: '1px solid black',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }
            // { paddingTop: '10px', paddingLeft: '20px', paddingRight: '20px' }
        }>
            <div style={
                {
                    display: 'flex',
                    padding: '10px'
                }
                // { display: 'inline-block' }
            }>
                <FormControl variant="outlined" style={{ width: '150px', marginTop: '3px' }} size="large">
                    <InputLabel id="sf-automation-landing-page-from-automationlandingpage-simple-select-outlined-label" className="sf-automation-landing-page-from-automationlandingpage-results">{getLocalizedStrings().label.AUTOMATION_DESIGNER.SELECT_PAGE}</InputLabel>
                    <Select
                        value={selectedLandingPage}
                        label={getLocalizedStrings().label.AUTOMATION_DESIGNER.SELECT_PAGE}
                        labelId='sf-label-landingpage'
                        id='sf-landingpage'
                        title={landingPageName}
                        className={"sf-fields-bg"}
                        margin="dense"
                        size='small'
                        style={{
                            height: '45px'
                        }}
                    >
                        {landingPageList}
                    </Select>
                </FormControl>

                {
                    (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                    <div style={{
                        marginLeft: '10px',
                        marginTop: '10px'
                    }}>
                        <Button
                            style={styles.listViewPrimaryButton}
                            onClick={saveLandingPage}
                        >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                    </div>
                }

            </div>

            <div style={{ padding: '10px' }}>
                <span>
                    <i className="fa fa-refresh" aria-hidden="true" style={{ cursor: 'pointer' }} title={getLocalizedStrings().label.REFRESH}></i>
                </span>
            </div>

        </div>
    );

}

export default AutomationLandingPage;