import { actions } from '..//constants/actions';

const notesInitialState = {
    mounted: true,
    data: null,
    fields: null,
    records: null,
    allNoteTypes:[],
    selectedNoteTypes:[],
    isAllSelected:false,
    noteTypeRedord: {},
    noteNameTypeMap: {}
}

const noteReducer = (state = notesInitialState, action) => {
    switch (action.type) {
        case actions.NOTES_GET_FULFILLED:
            return {
                ...state,
                mounted: true,
                data: action.payload,
                fields: action.payload.fields,
                records: action.payload.records
            }
        case actions.NOTES_TYPE_GET_ALL:
            return {
                ...state,
                allNoteTypes: action.payload
            }
        case actions.UPDATE_SELECTED_NOTES_TYPES:
            return {
                ...state,
                selectedNoteTypes: action.payload
            }
        case actions.UPDATE_NOTES_RECORD_TYPES:
            return {
                ...state,
                data:{
                    ...state.data,
                    records : action.payload,
                }
            }
        case actions.UPDATE_PINNED_NOTES_VALUE:
            return {
                ...state,
                pinnedNote:{
                    ...state.pinnedNote,
                    pinnedNotes : action.payload,
                }
            }
            case actions.UPDATE_IS_ALL_SELECTED:
                return {
                    ...state,
                    isAllSelected : action.payload,
                }
        case actions.NOTES_TYPE_GET_FULFILLED:
          
            let reordList = action.payload.records;
            let noteNameTypeMap = {};
            for (let i = 0; i < reordList.length; i++) {

                noteNameTypeMap[reordList[i].t_note_type] = reordList[i].t_system_type;

            }


            return {
                ...state,

                noteTypeRedord: action.payload.records,
                noteNameTypeMap: noteNameTypeMap
            }
        default:
            return state
    }
}

export default noteReducer