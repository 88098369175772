import { actions } from '..//constants/actions';
import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';

function getNotificationDetails() {
    return {
        type: actions.NOTIFICATION_INFO_GET,
        payload: HTTPClient.get(endPoints.TASK.POPUP_REMINDER)
    }
}

function updateNotificationInfo(notificationInfo) {
    return {
        type: actions.NOTIFICATION_INFO_UPDATE,
        payload: notificationInfo
    }
}

function updateNotificationAccess(notificationAccess) {
    return {
        type: actions.NOTIFICATION_ACCESS_UPDATE,
        payload: notificationAccess
    }
}

export {
    getNotificationDetails,
    updateNotificationInfo,
    updateNotificationAccess
}
