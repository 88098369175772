import { actions } from "../constants/actions";


const setAuditPerformanceDuration= (payload) => ({ type: actions.SET_AUDIT_PERFORMANCE_DURATION, payload });
const setAuditPerformanceCategoryData = (payload) => ({ type: actions.SET_AUDIT_PERFORMANCE_CATEGORY_DATA, payload });
const setAuditPerformanceCategories = (payload) => ({ type: actions.SET_AUDIT_PERFORMANCE_CATEGORIES, payload });
const setAuditPerformanceQuestionsData = (payload) => ({ type: actions.SET_AUDIT_PERFORMANCE_QUESTIONS_DATA, payload });
const setAuditPerformanceQuestions = (payload) => ({ type: actions.SET_AUDIT_PERFORMANCE_QUESTIONS, payload });
const setAuditPerformanceReportData = (payload) => ({ type: actions.SET_AUDIT_PERFORMANCE_REPORT_DATA, payload });
const setAuditPerformanceInitialLoad = (payload) => ({ type: actions.SET_AUDIT_PERFORMANCE_INITIAL_LOAD, payload });
const setAuditPerformanceReportParams = (payload) => ({ type: actions.SET_AUDIT_PERFORMANCE_REPORT_PARAMS, payload });


export {
    setAuditPerformanceDuration,
    setAuditPerformanceCategoryData,
    setAuditPerformanceCategories,
    setAuditPerformanceQuestionsData,
    setAuditPerformanceQuestions,
    setAuditPerformanceReportData,
    setAuditPerformanceInitialLoad,
    setAuditPerformanceReportParams
}