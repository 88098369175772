import React, { useEffect } from "react";

import { TextField, Autocomplete, Checkbox, Chip } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";

export default function AutocompleteMultiSelect({
  items,
  placeholderText,
  selectAllLabel,
  onChange,
  value,
  // isPlanChange, setIsPlanChange - only required from royaltyReportV2.js
  isPlanChange,
  setIsPlanChange
}) {
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const allSelected = items.length === selectedOptions.length;
  
  useEffect(()=>{
    if(isPlanChange) {
      setSelectedOptions([])
      setIsPlanChange(false);
    } else {
      setSelectedOptions(value)
    }
  },[value, selectedOptions, isPlanChange, setIsPlanChange]);

  const handleToggleOption = (selectedOps) => setSelectedOptions(selectedOps);
  const handleClearOptions = () => {
    setSelectedOptions([]);
    onChange([])
  } 

  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedOptions(items);
    } else {
      handleClearOptions();
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleChange = (event, selectedOps, reason) => {
    if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOps.find((option) => option.label === selectAllLabel)) {
        const result = items.filter((el) => el.label !== selectAllLabel);
        onChange(result);
        handleToggleSelectAll();
      } else {
        handleToggleOption && handleToggleOption(selectedOps);
        return onChange(selectedOps);
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
    }
  };

  const filter = createFilterOptions();

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      options={items}
      value={selectedOptions}
      getOptionLabel={(option) => option.label}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (!options?.length) {
          return [];
        } else {
          return [{ id: -9999, label: selectAllLabel }, ...filtered];
        }
      }}
      renderTags={(value, getTagProps) => {
        const numTags = value.length;
        const limitTags = 2;

        return (
        <>
            {value.slice(0, limitTags).map((option, index) => (
            <Chip
                style={{ height: '29px' ,width: (option.label).length > 15 ? '100px' : 'auto'}}
                label={option.label}
                title={option.label}
                {...getTagProps({ index })}
            />
            ))}

            {numTags > limitTags && ` +${numTags - limitTags}`}
        </>
        );
      }}
      renderOption={(props, option, { selected }) => {
        // To control the state of 'All' checkbox
        const selectAllProps = option.label === selectAllLabel ? { checked: allSelected } : {};

        return (
          <li {...props}>
            <Checkbox checked={selected} {...selectAllProps} />
            {option.label}
          </li>
        );
      }}
      fullWidth
      renderInput={(params) => (
        <TextField 
          {...params}
          sx={{ '.MuiAutocomplete-inputRoot': { height: '47px' },
        '.MuiFormLabel-root[data-shrink=false]': { top: '4px'} }}
          variant="outlined"
          margin="none"
          size='small'
          label={placeholderText} 
          placeholder={selectedOptions?.length > 0 ? '' : placeholderText} 
        />
      )}
      onChange={handleChange}
    />
  );
}

AutocompleteMultiSelect.defaultProps = {
  items: [],
  selectedValues: []
};
