import React, { useEffect, useState } from 'react';
import TotalRecordCount from '../components/TotalRecordCount';
import { GroupDataTable } from '../components/GroupDataTable';
import { GroupPagination } from '../components/GroupPagination';
import { getGroupCampaignDetails } from '../../../services/actions/detailViewActions';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import PropTypes from 'prop-types';

const LandingPagesForGroup = ({recordId, defaultPageSize, objParam }) =>{

    const [campaignDetails, setCampaignDetails] = useState(null);
    const [mounted, setMounted] = useState(false);
    const[changeLog,setchangeLog]=useState({campaignDetails:null})


        useEffect(() => {
        var objParam = {};
        objParam.recordId = recordId.id;
        objParam.type = "landingPages";
        objParam.fetchFrom = 0;
        objParam.fetchCount = defaultPageSize;
        objParam.isRecordCount = true;
        getCampaignDetails(objParam);
        },[recordId.id]);

      

    const getCampaignDetails = (objParam) => {
        setMounted(false);
        let promise = Promise.resolve(getGroupCampaignDetails(objParam));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                   
                    setMounted(true);
                    setCampaignDetails(response);
                    let log = changeLog;
                    log.campaignDetails = response;
                    setchangeLog({...log});

                    
                }
            });
        }

    }

        if (mounted) {
            let objDetails = changeLog.campaignDetails;
            let pgDetails = {};
            pgDetails.campType = 'landingPages';
            pgDetails.totalRecordCount = objDetails.landingPagesCount;
            pgDetails.startNo = 1;
            pgDetails.defaultPageSize = defaultPageSize;
            pgDetails.recordId = recordId.id;
            let landingPagesLabel = getLocalizedStrings().label.MODULE_ITEMS[objDetails.landingPagesLabel];
            return (
                <div>
                    <div className="group_campaign_title">
                        <TotalRecordCount totalCount={objDetails.landingPagesCount} />
                        <span style={{ paddingTop: "2px" }}>{landingPagesLabel}</span>
                    </div>
                    <div>
                        {objDetails.landingPages !== undefined && objDetails.landingPages !== null && objDetails.landingPages.length > 0 &&
                            <GroupDataTable data={objDetails.landingPages} />
                        }
                    </div>
                    {!(objDetails.landingPages !== undefined && objDetails.landingPages !== null && objDetails.landingPages.length > 0) &&
                        <div className="group_campaign_norecord">{getLocalizedStrings().message.GROUP.NO_LANDINGPAGE}</div>
                    }
                    <div>
                        {objDetails.landingPages !== undefined  && objDetails.landingPages !== null && objDetails.landingPages.length > 0 &&
                            <GroupPagination pgDetails={pgDetails} getGroupCampaignDetails={(objParam)=>getCampaignDetails(objParam)} />
                        }
                    </div>
                </div>
            );
        } else {
            let contentHeight = window.innerHeight - 240;
            let top = (contentHeight - 10) / 2;
            return (
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            );
        }
    }


LandingPagesForGroup.propTypes = {
    recordId: PropTypes.number,
    defaultPageSize: PropTypes.number
};


export default LandingPagesForGroup;