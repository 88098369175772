import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants } from '../../../services/constants/constants';
import { useDispatch, useSelector } from "react-redux";
import {
    refreshListView
} from '../../../services/actions/listViewAcions';
import { refreshDetailViewData, refreshDetailViewListViewData, isDetailViewSet ,detailViewIsMounted} from '../../../services/actions/detailViewActions';

const ConfirmScheduleDialog = ({ data, callFrom }) => {

    const dispatch = useDispatch();

    const appContainer = useSelector((state) => state.appContainer);

    useEffect(() => {
        dispatch(setAppDialogActions(getActions()));
    }, []);


    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const handleOk = () => {
        let obj = { schedule_date: data.db_date }
        appContainer.dialogProps.eventName(obj);
       let object="broadcast-campaigns"
          refreshListView(object);
          dispatch(detailViewIsMounted(false));
          dispatch(isDetailViewSet(false));
          dispatch(refreshDetailViewData(true));
          dispatch(refreshDetailViewListViewData(true));

    }

    const getActions = () => {
        const actions = [
            <Button
                key={'ok'}
                primary={'true'}
                onClick={() => handleOk()}
                style={styles.primaryButton}
            >{getLocalizedStrings().label.COMMON.OK}</Button>,
            <Button
                key={'cancel'}
                primary={'true'}
                onClick={() => handleClose()}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>

        ];
        return actions;
    }


    let scheduleMessage = getLocalizedStrings().message.SCHEDULE.CAMPAIGN_SCHEDULE;
    if (callFrom!== undefined && callFrom!== null & callFrom === constants.SMS_OBJECT) {
        scheduleMessage = getLocalizedStrings().message.SCHEDULE.SMS_SCHEDULE;
    }
    return (
        <div style={{ paddingLeft: 15, paddingRight: 100, marginTop: '-10px' , height:'40px' }}>
            <div className="row" style={{ marginTop:'10px' }}>
                {scheduleMessage}
            </div>
            <div className="row" style={{ fontWeight: 'bold' }}>
                {data.user_date}
            </div>
            <div className="row">
                {getLocalizedStrings().message.SCHEDULE.CONFIRM}
            </div>
        </div>
    );
}

export default ConfirmScheduleDialog;