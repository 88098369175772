import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Icon} from '@mui/material';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';

    function SFToolTip ({callFrom,placement,title}) {
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <div>

           
                <div>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Tooltip
                        PopperProps={{
                            disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={callFrom =="convert" ? title : getLocalizedStrings().label.DETAIL_VIEW.SHOW_LABELS_AGAINST_FIELD}
                         placement={placement}
                        arrow
                    >
                        <Icon
                            id='clickButton'
                            style={{  cursor: 'pointer', color: '#717171' , marginBottom: callFrom =="convert" ? '-6px' : '' }}
                            title={getLocalizedStrings().label.DETAIL_VIEW.CLICK_TO_KNOW_MORE}
                            onClick={handleTooltipOpen}
                        >
                            info_outline
                        </Icon>
                    </Tooltip>
                    </ClickAwayListener>
                </div>
           


        </div>
    );
}
export default SFToolTip;
