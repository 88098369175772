import React, { useState, useEffect } from 'react';
import { styles } from '../../../services/constants/styles';
import Button from '@mui/material/Button';
import * as ParameterVerifier from '../../../services/helper/parameterVerifier';
import { constants } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { connect} from "react-redux";
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { Menu, Select, MenuItem, InputLabel, FormControl, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import * as sfDialogs from '../components/sfDialogs';
import ShowCircularProgress from '../components/circularProgress';
import { useDispatch, useSelector } from "react-redux";


const CompanySchedulerAvppointentType =()=> {

    const me = useSelector((state) => state.app.me);
    const calendar = useSelector((state) => state.calendar);
    const app = useSelector((state) => state.app);
    const schedulerDetails = useSelector((state) => state.schedulerDetails);
    const userlist = useSelector((state) => state.userlist);
    const dispatch = useDispatch();

    const [buttonVisibility, setButtonVisibility] = useState(true);
    const [appointmentListTextfield, setAppointmentListTextfield] = useState(false);
    const [showDetailFields, setShowDetailFields] = useState(false);
    const [appointmentTypeListValue, setAppointmentTypeListValue] = useState([]);
    const [showAddButton, setShowAddButton] = useState(false);
    const [onChangeAppointmentType, setOnChangeAppointmentType] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [showAppointmentName, setShowAppointmentName] = useState(false);

    
    const [showSingleType, setShowSingleType] = useState(false);
    const [appointmentType, setAppointmentType] = useState({
        appointmentType: '',
        appt_name: '',
        description: '',
        duration: 30,
        duration_type: "min",
    });
    const [appTypes, setAppTypes] = useState([]);
    const [mounted, setMounted] = useState(false);
   
     
    useEffect(() => {
        setMounted(true);
        getAllAppointmentsTypes();

    },[])

   const getData = () => {
        try {
            let _appointmentType = appointmentType;
            let appointmentTypeListValue = [];
            var promise = Promise.resolve(HTTPClient.get(endPoints.COMPANY_SCHEDULER.GET_COMPANY_SCHEDULER_APPOINTMENT_TYPES, null));
            if (ParameterVerifier.isValidParam(promise)) {
                promise.then((response) => {
                    if (response.hasOwnProperty('scheduleInfo')) {
                        _appointmentType = response.scheduleInfo;
                        appointmentTypeListValue = response.appoTypesList;
                        let typeList = appTypes;
                        typeList = typeList.map((e) => {
                            return e.value;
                        })
                        setAppointmentType(_appointmentType);
                        setSelectedType(appointmentTypeListValue[0]);
                        setMounted(true);
                        setShowDetailFields(true);
                        setAppointmentTypeListValue(appointmentTypeListValue)
                        setShowAppointmentName(true);
                        setShowSingleType(true);
                       
                    } else {

                        setAppointmentType(_appointmentType);
                        setAppointmentTypeListValue(appointmentTypeListValue)
                        setShowAppointmentName(true);

                    }


                });
            }

        } catch (error) {
            console.error("Error in '.js -> getData()':" + error);
        }


    }

    const showAppointmentListTextfield = () => {
        setAppointmentListTextfield(true);
        setButtonVisibility(false);
        setMounted(true);
        setShowAppointmentName(true);
        setShowDetailFields(false);
        setShowAddButton(false);
        setOnChangeAppointmentType('');
      
    }
   const onChangeType = (event) => {
        let value = event.target.value;
        setOnChangeAppointmentType(value);
     
    }
   const showSchedulerType = () => {
        if (onChangeAppointmentType == "") {
           dispatch(showCustomSnackBar("Please select an appointment type ", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else {
            let typeList = appTypes;
            typeList = typeList.map((e) => {
                return e.value;
            })
            let singleSelectedValue = [];
            singleSelectedValue = typeList.find(e => {
                return e == onChangeAppointmentType;
            });
            let _appointmentTypeListValue = appointmentTypeListValue;

            if (_appointmentTypeListValue.includes(onChangeAppointmentType)) {
                dispatch(showCustomSnackBar("Appointment type already exists", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
                _appointmentTypeListValue.push(singleSelectedValue);

            }
            getAppTypeInfo(singleSelectedValue);
            setShowAppointmentName(true);
            setAppointmentTypeListValue(_appointmentTypeListValue);
            setAppointmentListTextfield(false);
            setShowAddButton(true);
            setButtonVisibility(true);
        }
    }

   const getAppTypeInfo = (type) => {
        let _appointmentType = {
            appointmentType: type,
            appt_name: '',
            description: '',
            duration: 30,
            duration_type: "min",
        };
        let params = {
            appoType: type
        }
        var promise = Promise.resolve(HTTPClient.get(endPoints.COMPANY_SCHEDULER.GET_COMPANY_SCHEDULER_APPOINTMENT_TYPES_BYNAME, params));
        promise.then((response) => {
            if (response.hasOwnProperty('scheduleInfo')) {
                _appointmentType = response.scheduleInfo;
            }
            setAppointmentType(_appointmentType);
            setMounted(true);
            setShowDetailFields(true);
            setAppointmentListTextfield(false);
            setShowAppointmentName(true);
            setShowSingleType(false);
            setSelectedType(type);
            setButtonVisibility(true);

        });

    }
    const deleteAppoType = (type) => {
        let appointment = appointmentTypeListValue;
        appointment = appointment.filter(f => {
            return f != type;
        });
        let params = {
            appoTypeName: type
        }
        var promise = Promise.resolve(HTTPClient.post(endPoints.COMPANY_SCHEDULER.DELETE_COMPANY_SCHEDULER_APPOINTMENT_TYPES, params));
        promise.then((response) => {
            if (ParameterVerifier.isValidParam(response)) {
                if (response.data.isDelete) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                   setAppointmentTypeListValue(appointment);
                   setShowDetailFields(false);
                }
            }

        })
    }
   const removeAppoTypes = (type, callFrom) => {
        if (callFrom == 'typelevel') {
            let msg = "Removing " + type + " will remove the information you already saved. Click OK to continue. Click CANCEL to keep " + type + ".";
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => deleteAppoType(type), null);
        } else {
            setShowDetailFields(false);
        }
    }

    const displayAppointentType = (type, index) => {
        let newElement;
        let selectedName = appointmentTypeListValue.map((type, index) => {
            return type
        }
        )
        newElement = <div style={{ display: 'flex', marginTop: '25px', marginLeft: '25px' }} >
            <div style={{ display: 'flex' }}>
                <div  >
                    <Link
                        to={'#'}
                        onClick={()=> getAppTypeInfo (type)}
                        value={type}
                        style={{ cursor: 'pointer' }}>
                        <span style={{ fontWeight: selectedType == type ? 'bold' : '', textDecorationLine: selectedType == type ? 'underline' : '' }} > {type} </span>
                    </Link>
                </div>
                <div style={{ marginLeft: '30px' }}>
                    <i
                        key={'cross_icon_'}
                        className="fa fa-times"
                        aria-hidden="true"
                        style={{ cursor: 'pointer', fontSize: '14px', marginTop: '3px' }}
                        onClick={() => removeAppoTypes(type, 'typelevel')}
                        title={getLocalizedStrings().label.COMMON.DELETE}
                    ></i>
                </div>
            </div>

        </div>

        return newElement;
    }


   const onChangeTextField = (field, event) => {
        let _appointmentType = appointmentType;
        _appointmentType[field] = event.target.value;
        setAppointmentType({..._appointmentType});
    }

    const displayFields = () => {
        let duration = [];

        if (appointmentType.duration_type == 'min') {
            duration = [15, 30, 45, 60, 75, 90, 105, 120, 135, 150];

        }
        else {
            for (var i = 1; i <= 23; i++) {
                duration.push(i);
            }
        }
        let durationMenuItems = null;
        durationMenuItems = duration.map((d, index) => {
            return <MenuItem key={d} value={d} style={styles.popoverMenuItem}>{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }} title={d}>{d}</div>}</MenuItem>
        });
        let duration_type = ['hr', 'min'];
        let duration_type_label_obj = { 'hr': 'hour(s)', 'min': 'min(s)' }
        let durationTypeMenuItems = null;
        durationTypeMenuItems = duration_type.map((key) => {
            return <MenuItem key={key} value={key} style={styles.popoverMenuItem} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }} title={duration_type_label_obj[key]}>{duration_type_label_obj[key]}</div>}</MenuItem>
        });
        let element;
        element =
            <div style={{ marginTop: '20px', marginLeft: '50px' }}>
                <div style={{ display: 'flex' }} >
                    <div style={{ marginTop: '5px' }}>Duration </div>
                    <FormControl variant="outlined" style={{ paddingLeft: '20px' }} >
                        <InputLabel id="sf-schedulerinfo-simple-select-outlined-label" className="sf-schedulerinfo">{null}</InputLabel>
                        <Select
                            key="duration"
                            id='duration'
                            value={appointmentType.duration}
                            defaultValue={appointmentType.duration}
                            onChange={(e) => onChangeValue(e, 'duration')}
                            name='duration'
                            style={{ height: '35px', width: '80px' }}
                            className={"sf-fields-bg"}>
                            {durationMenuItems}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" style={{ paddingLeft: '10px' }} >
                        <InputLabel id="sf-scheduler-info-simple-select-outlined-label" className="sf-scheduler-info">{null}</InputLabel>
                        <Select
                            key="durationt_ype"
                            id='duration_type'
                            value={appointmentType.duration_type}
                            defaultValue={appointmentType.duration_type}
                            onChange={(e) => onChangeValue(e, 'duration_type')}
                            name='duration_type'
                            className={"sf-fields-bg"}
                            style={{ height: '35px', width: '90px' }} >
                            {durationTypeMenuItems}
                        </Select>
                    </FormControl>
                </div>
                <div className='row'>
                    <div>
                        <FormControl style={{ width: '40%', marginTop: '10px' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                label={getLocalizedStrings().label.SCHEDULER.APPOINTMENT_NAME}
                                name="apptname"
                                value={appointmentType.appt_name}
                                onChange={(event)=> onChangeTextField( 'appt_name', event)}
                                inputprops={{ maxlength: '255' }}
                                autoComplete="new-password"
                                margin="dense"
                                size = 'small'
                                className={"sf-fields-bg"}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className='row'>
                    <div>
                        <FormControl style={{ width: '40%', paddingTop: '10px' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                type='textarea'
                                multiline={true}
                                label={getLocalizedStrings().label.SCHEDULER.DESCRIPTION}
                                fullWidth={true}
                                name="description"
                                value={appointmentType.description}
                                minRows={3}
                                onChange={(event)=> onChangeTextField('description', event)}
                                textareastyle={{ marginTop: '10px', height: '90px', resize: 'none' }}
                                style={{ maxHeight: '90px', height: '90px' }}
                                inputprops={{ maxlength: '1024' }}
                                autoComplete="new-password"
                                className={"sf-fields-bg"}
                            />
                        </FormControl>
                    </div>
                </div>
                <div style={{ width: '41%', paddingBottom: '50px' }}>
                    <Button variant="contained" style={{ ...styles.primaryButton,border:'', fontSize: 13, marginTop: "25px", marginLeft: '50px', float: 'right', border:'' }} size="small" onClick={() => save()}> Save</Button>
                </div>
            </div>


        return element;
    }
    const onChangeValue = (event, field) => {
        let _appointmentType = appointmentType;

        if (field == 'duration_type') {
            if (event.target.value == 'hr') {
                _appointmentType['duration'] = 1;
            } else {
                _appointmentType['duration'] = 30;
            }
            _appointmentType.duration_type = event.target.value
        }
        else {
            _appointmentType.duration = event.target.value;
        }

       setAppointmentType({..._appointmentType});
    }


   const validate = (appointmentTypeInfo) => {
        let isValid = true;
        let msg = "";

        if (appointmentTypeInfo != null && appointmentTypeInfo != {} && appointmentTypeInfo.appt_name == '') {
            isValid = false;
            msg = 'Appointment name cannot be blank';

        }
        if (appointmentTypeInfo != null && appointmentTypeInfo != {} && appointmentTypeInfo.duration == '') {
            isValid = false;
            msg = 'duration cannot be blank';

        }

        if (!isValid) {
            dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }

        return isValid;
    }
   const save = () => {
        let appointmentTypeInfo = appointmentType;
        let isValid = validate(appointmentTypeInfo);
        if (isValid) {
            let params = new Object();
            params.apptInfo = appointmentTypeInfo;
            var promise = Promise.resolve(HTTPClient.post(endPoints.COMPANY_SCHEDULER.SAVE_COMPANY_SCHEDULER_APPOINTMENT_TYPES, params));
            promise.then((response) => {
                if (ParameterVerifier.isValidParam(response)) {
                    if (response.status == 0) {
                       dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));

                    }
                    else {
                       dispatch(showCustomSnackBar(getLocalizedStrings().message.SCHEDULER.SAVE_FAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));

                    }
                }
            });
        }
    }
   const getAllAppointmentsTypes = () => {
        let calendarInfoFromRedux = ParameterVerifier.getObjectParam(calendar.calendarInfo);
        if (!calendarInfoFromRedux.hasOwnProperty('appTypes') && appTypes.length == 0) {
            let param = new Object();
            param.object = constants.APPOINTMENTS;
            param.name = 'event_type';
            param.list_type = 'external';

            let promise = Promise.resolve(HTTPClient.get(endPoints.FORM.APPOINTMENT_TYPE_LIST, param));

            promise.then((response) => {
                if (ParameterVerifier.isValidParam(response)) {
                    let type = ParameterVerifier.getArrayParam(response.record);
                    let _appTypes = type.map((f, i) => {
                        let typeObj = new Object();
                        typeObj.value = f.value;
                        typeObj.label = f.value;
                        typeObj.color = f.color;
                        return typeObj;
                    })
                   
                    sortArrayObjectByProperty(_appTypes, 'label');
                    getData();
                    calendar.calendarInfo.appTypes = _appTypes;
                    _appTypes = _appTypes.filter(f => { return f.value !='Empty' });
setAppTypes(_appTypes);
setMounted(true);
                   
                }

            });
        } else {
            let __appTypes = calendarInfoFromRedux.appTypes.filter(f => { return f.value !='Empty' });
            setAppTypes(__appTypes)
            setMounted(true);
            getData();
        }

    }
        let _appointmentType = [];
        _appointmentType = appTypes.map((e) => {
            return e.value;
        });
        let appointmentTypeMenuItems = null;
        appointmentTypeMenuItems = _appointmentType.map((key) => {
            return <MenuItem key={key} value={key} style={styles.popoverMenuItem}>{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }} title={key}>{key}</div>}</MenuItem>
        });

        let appointmentTypeName = appointmentTypeListValue.map((type, index) => { return displayAppointentType(type, index) })
        let selectedName = appointmentTypeListValue.map((type, index) => {
            return type
        }
        )

        let _showDetailFields = displayFields()
        if (mounted) {

            return (
                <>
                    <div style={{ width: '90%' }}>
                        <div>
                            <div style={{ ...styles.sf_12 }}>
                                <div id="header" style={{ paddingLeft: '0px', width: '100%', fontSize: '24px', paddingBottom: '5px', marginTop: '18px', marginLeft: '50px' }}>Appointment Types</div>

                                {/* <div style={{ fontSize: '30px', marginTop: '18px', fontWeight: 'bold', marginLeft: '50px' }}> Appointment Types </div> */}
                            </div>

                            <div style={{ display: 'flex', marginLeft: '25px', }}>

                                {showAppointmentName &&
                                    <span style={{ fontSize: '14px', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', maxWidth: '80%' }}>{appointmentTypeName}</span>
                                }
                                {buttonVisibility &&

                                    <Button variant="contained" style={{ ...styles.primaryButton,width:'', fontSize: 13, marginTop: "20px", marginLeft: appointmentTypeName == '' ? '25' : '60px' , border:''}} size="small" onClick={() =>showAppointmentListTextfield()}><span>Add Type</span>  </Button>
                                }

                            </div>

                            {appointmentListTextfield &&
                                <div style={{ marginTop: '15px' }}>
                                    <span style={{ fontSize: '20px', marginTop: '18px', fontWeight: 'bold', marginLeft: '50px' }} > Add an appointment type </span>
                                    <div className="boxContent">
                                        <FormControl variant="outlined" style={{ width: '25%', marginTop: '20px', marginLeft: '50px' }}>
                                            <InputLabel id="sf-schedulerinfo-simple-select-outlined-label" style={{ marginTop: '-5px' }} className="sf-schedulerinfo">{getLocalizedStrings().label.SCHEDULER.APPOINTMENT_TYPE}</InputLabel>
                                            <Select
                                                key="appointmentType"
                                                id='appointmentType'
                                                label={getLocalizedStrings().label.SCHEDULER.APPOINTMENT_TYPE}
                                                variant="outlined"
                                                value={onChangeAppointmentType}
                                                onChange={(e) => onChangeType(e)}
                                                name='appointmentType'
                                                style={{ height: '40px', width: '100%' }}
                                            >
                                                {appointmentTypeMenuItems}
                                            </Select>
                                        </FormControl>

                                    </div>

                                    <div style={{ width: '76.5%', marginTop: '15px', float: 'right' }}>

                                        <Button
                                            key='search'
                                            onClick={() => showSchedulerType()}
                                            style={{ ...styles.primaryButton, marginRight: '6px', verticalAlign: 'top' }}
                                        >{getLocalizedStrings().label.COMMON.ADD}</Button>

                                    </div>
                                </div>

                            }

                        </div>

                        {showDetailFields &&
                            <div>
                                <div style={{ marginLeft: '50px', marginTop: showDetailFields == true ? '20px' : '50px' }}>
                                    <span style={{ fontWeight: selectedType ? 'bold' : '', fontSize: '18px' }}>

                                        {showSingleType ? selectedName[0] : selectedType}
                                    </span>

                                </div>
                                {_showDetailFields}
                            </div>
                        }
                    </div>
                </>
            );
        }
        else {
            let contentHeight = window.innerHeight - 240;
            let top = (contentHeight - 10) / 2;
            return (
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            );
        }
    
}
export default CompanySchedulerAvppointentType;