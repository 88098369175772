import { getLocalizedStrings } from '../../../../../services/constants/MultiLingual';

export const section1Init = [
    {
        id: 'si1',
        section: 1,
        dataType: 'input',
        name: 'customField1',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.LAST_NAME,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si2',
        section: 1,
        dataType: 'input',
        name: 'customField2',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.FIRST_NAME,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si3',
        section: 1,
        dataType: 'input',
        name: 'customField3',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.MIDDLE_NAME,
        required: false,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si4',
        section: 111,
        dataType: 'date-picker',
        name: 'customFieldDate1',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.ADMISSION_DATE,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'sdd5',
        section: 111,
        dataType: 'dropdown',
        dropDownName: 'customFieldList1',
        dropdownValue: '',
        title: getLocalizedStrings().label.INTAKE_FORM.ADMIT,
        options: ['2 Weeks', '4 Weeks', '6 Weeks'],
        required: false,
    },
    {
        id: 'sd6',
        section: 111,
        dataType: 'input',
        name: 'customField43',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.DOB,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si7',
        section: 111,
        dataType: 'input',
        name: 'customField5',
        value: '',
        type: 'tel',
        title: getLocalizedStrings().label.INTAKE_FORM.AGE,
        required: false,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'sdd8',
        section: 111,
        dataType: 'dropdown',
        dropDownName: 'customFieldList2',
        dropdownValue: '',
        title: getLocalizedStrings().label.INTAKE_FORM.GENDER,
        options: ['Male', 'Female', 'Binary'],
        required: true,
    },
    {
        id: 'si9',
        section: 111,
        dataType: 'input',
        name: 'customField6',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.PRONUONS,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si10',
        section: 111,
        dataType: 'input',
        name: 'customField7',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.ALLERGIES,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'sdd11',
        section: 111,
        dataType: 'dropdown',
        dropDownName: 'customFieldList3',
        dropdownValue: '',
        title: getLocalizedStrings().label.INTAKE_FORM.SMOKING,
        options: ['Yes', 'No'],
        required: true,
    },
    {
        id: 'si12',
        section: 1,
        dataType: 'input',
        name: 'customField8',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.RELIGION,
        required: false,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'sdd13',
        section: 1,
        dataType: 'dropdown',
        dropDownName: 'customFieldList4',
        dropdownValue: '',
        title: getLocalizedStrings().label.INTAKE_FORM.MARITAL_STATUS,
        options: ['Married', 'Divorced', 'Widow', 'Widower'],
        required: true,
    },
    {
        id: 'si14',
        section: 1,
        dataType: 'input',
        name: 'customField9',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.AGE_OF_CHILDREN,
        required: false,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si15',
        section: 1111,
        dataType: 'input',
        name: 'customField10',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.ADDRESS,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si16',
        section: 1,
        dataType: 'input',
        name: 'customField11',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.CITY,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'sdd17',
        section: 1,
        dataType: 'dropdown',
        dropDownName: 'customFieldList5',
        dropdownValue: '',
        title: getLocalizedStrings().label.INTAKE_FORM.STATE,
        options: [],
        required: true,
    },
    {
        id: 'si18',
        section: 1,
        dataType: 'input',
        name: 'customField12',
        value: '',
        type: 'tel',
        title: getLocalizedStrings().label.INTAKE_FORM.ZIPCODE,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'sdd19',
        section: 1,
        dataType: 'dropdown',
        dropDownName: 'customFieldList6',
        dropdownValue: '',
        title: getLocalizedStrings().label.INTAKE_FORM.COUNTRY,
        options: [],
        required: false,
    },
    {
        id: 'si20',
        section: 1,
        dataType: 'input',
        name: 'customField13',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.EMAIL_REQUIRED_LABEL,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si21',
        section: 1,
        dataType: 'input',
        name: 'customField14',
        value: '',
        type: 'tel',
        title: getLocalizedStrings().label.INTAKE_FORM.PHONE,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si22',
        section: 1,
        dataType: 'input',
        name: 'customField15',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.OCCUPATION,
        required: false,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si23',
        section: 11,
        dataType: 'input',
        name: 'customField16',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.CURRENT_TRAETMENT,
        required: false,
        multiline: true,
        max_lines: 4,
        inputPlaceholder: getLocalizedStrings().label.INTAKE_FORM.CURRENT_TRAETMENT_PLACEHOLDER,
    },
    {
        id: 'si24',
        section: 11,
        dataType: 'input',
        name: 'customField17',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.DESCRIBE_EXPOSURE,
        required: false,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si25',
        section: 11,
        dataType: 'input',
        name: 'customField18',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.CURRENT_MEDICATIONS,
        required: false,
        multiline: true,
        max_lines: 4,
        inputPlaceholder: getLocalizedStrings().label.INTAKE_FORM.CURRENT_MEDICATIONS_PLACEHOLDER,
    },
    {
        id: 'si26',
        section: 11,
        dataType: 'input',
        name: 'customField19',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.MEDICATION_CHANGES,
        required: false,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 'si27',
        section: 11,
        dataType: 'input',
        name: 'customField20',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.EFFECT_OF_MEDICATION,
        required: false,
        multiline: false,
        max_lines: 1,
    },
];

export const section2Init = [
    // dropdownWithInput
    {
        id: 'dwi1',
        section: 2,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList7',
        dropdownValue: '',
        title: getLocalizedStrings().label.INTAKE_FORM.NAVIGATE_STAIRS,
        options: ['Yes', 'No'],
        input1Name: 'customField21',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.INTAKE_FORM.EXPLANTION,
        inputType: 'text',
    },
    {
        id: 'dwi2',
        section: 2,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList8',
        dropdownValue: '',
        title: getLocalizedStrings().label.INTAKE_FORM.CARE,
        options: ['Yes', 'No'],
        input1Name: 'customField22',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.INTAKE_FORM.EXPLANTION,
        inputType: 'text',
    },
    {
        id: 'dwi3',
        section: 2,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList9',
        dropdownValue: '',
        title: getLocalizedStrings().label.INTAKE_FORM.MEDICAL_ALLERGIES,
        options: ['Yes', 'No'],
        input1Name: 'customField23',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.INTAKE_FORM.EXPLANTION,
        inputType: 'text',
    },
    {
        id: 'dwi4',
        section: 2,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList10',
        dropdownValue: '',
        title: getLocalizedStrings().label.INTAKE_FORM.FOOD_ALLERGIES,
        options: ['Yes', 'No'],
        input1Name: 'customField24',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.INTAKE_FORM.EXPLANTION,
        inputType: 'text',
    },
    {
        id: 'dwi5',
        section: 2,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList11',
        dropdownValue: '',
        title: getLocalizedStrings().label.INTAKE_FORM.SPECIAL_DIET,
        options: ['Yes', 'No'],
        input1Name: 'customField25',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.INTAKE_FORM.EXPLANTION,
        inputType: 'text',
    },
    {
        id: 'dwi6',
        section: 2,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList12',
        dropdownValue: '',
        title: getLocalizedStrings().label.INTAKE_FORM.MEDICAL_CONDITION,
        options: ['Yes', 'No'],
        input1Name: 'customField26',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.INTAKE_FORM.EXPLANTION,
        inputType: 'text',
    },
    {
        id: 'dwi7',
        section: 2,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList13',
        dropdownValue: '',
        title: getLocalizedStrings().label.INTAKE_FORM.MEDICAL_NEEDS,
        options: ['Yes', 'No'],
        input1Name: 'customField27',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.INTAKE_FORM.EXPLANTION,
        inputType: 'text',
    },
    {
        id: 'dwi8',
        section: 2,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList14',
        dropdownValue: '',
        title: getLocalizedStrings().label.INTAKE_FORM.SURGERIES_OR_MEDICAL_PROCEDURES,
        options: ['Yes', 'No'],
        input1Name: 'customField28',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.INTAKE_FORM.EXPLANTION,
        inputType: 'text',
    },
    {
        id: 'dwi9',
        section: 2,
        dataType: 'dropdownWithInput',
        dropDownName: 'customFieldList15',
        dropdownValue: '',
        title: getLocalizedStrings().label.INTAKE_FORM.MEDICAL_PROCEDURE,
        options: ['Yes', 'No'],
        input1Name: 'customField29',
        input1Value: '',
        input1Placeholder: getLocalizedStrings().label.INTAKE_FORM.EXPLANTION,
        inputType: 'text',
    },
    {
        id: 's2i11',
        section: 21,
        dataType: 'input',
        name: 'customField30',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.PHYSICAL_HEALTH,
        required: false,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 's2i1',
        section: 22,
        dataType: 'input',
        name: 'customField31',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.FINANCIAL_PAYOR,
        required: false,
        multiline: false,
        max_lines: 1,
    },
    {
        id: 's2i2',
        section: 22,
        dataType: 'input',
        name: 'customField32',
        value: '',
        type: 'tel',
        title: getLocalizedStrings().label.INTAKE_FORM.CONTACT_NUMBER,
        required: false,
    },
    {
        id: 's2i3',
        section: 22,
        dataType: 'input',
        name: 'customField33',
        value: '',
        type: 'email',
        title: getLocalizedStrings().label.INTAKE_FORM.EMAIL,
        required: false,
    },
    {
        id: 's2i4',
        section: 22,
        dataType: 'input',
        name: 'customField34',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.EMERGENCY_CONTACT,
        required: false,
    },
    {
        id: 's2i4',
        section: 22,
        dataType: 'input',
        name: 'customField35',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.RELATIONSHIP,
        required: false,
    },
    {
        id: 's2i5',
        section: 22,
        dataType: 'input',
        name: 'customField36',
        value: '',
        type: 'tel',
        title: getLocalizedStrings().label.INTAKE_FORM.CONTACT_NUMBER,
        required: false,
    },
    {
        id: 's2i6',
        section: 22,
        dataType: 'input',
        name: 'customField37',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.THERAPIST_NAME,
        required: false,
    },
    {
        id: 's2i7',
        section: 22,
        dataType: 'input',
        name: 'customField38',
        value: '',
        type: 'tel',
        title: getLocalizedStrings().label.INTAKE_FORM.CONTACT_NUMBER,
        required: false,
    },
    {
        id: 's2i8',
        section: 22,
        dataType: 'input',
        name: 'customField39',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.EMAIL,
        required: false,
    },
    {
        id: 's2i9',
        section: 22,
        dataType: 'input',
        name: 'customField40',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.REFERRED_BY,
        required: false,
    },
    {
        id: 's2i10',
        section: 22,
        dataType: 'input',
        name: 'customField41',
        value: '',
        type: 'tel',
        title: getLocalizedStrings().label.INTAKE_FORM.CONTACT_NUMBER,
        required: false,
    },
    {
        id: 's2i11',
        section: 22,
        dataType: 'input',
        name: 'customField42',
        value: '',
        type: 'text',
        title: getLocalizedStrings().label.INTAKE_FORM.EMAIL,
        required: false,
    },
];

export const headerText = {
    SECTION2: getLocalizedStrings().label.INTAKE_FORM.MEDICAL_RESTRICTIONS,
};
