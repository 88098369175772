import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { endPoints } from "../../../services/constants/endPoints";
import * as HTTPClient from "../../../services/helper/httpClient";
import ShowCircularProgress from "../components/circularProgress";
import Icon from "@mui/material/Icon";
import {
	isValidParam,
	getArrayParam,
	getObjectParam,
	getBooleanParam,
	getStringParam,
	getIntParam,
} from "../../../services/helper/parameterVerifier";
import { addTab, TYPE_REPORT } from "../../../services/helper/sfTabManager";
import { constants } from "../../../services/constants/constants";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import map from "lodash/map";
import {
	setReportHeaderData,
	getReportType,
} from "../../../services/actions/reportActions";
import {
	hasAccessPermission,
	getObjectNameByLabel,
	saveRecentReportItems,
	getIconClass,
	removeRecentFromRedux,
} from "../../../services/helper/common";
import { sortArrayObjectByProperty } from "../../../services/helper/utils";
import { styles } from "../../../services/constants/styles";
import { getAppDrawer } from "../../../services/actions/appContainerActions";
import {
	getSalesReports,
	removeReportInRedux,
	addRecentFilterInRedux,
	addRecentRecordInRedux,
	getReportUrl,
} from "../../../services/actions/SalesReportAction";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import * as sfDialogs from "../components/sfDialogs";
import { LinkToType } from "../../../services/constants/link";
import { useDispatch, useSelector } from "react-redux";

const ColumnReports = ({ object, searchText }) => {
    const dispatch = useDispatch();
    
	const app = useSelector((state) => state.app);
	const report = useSelector((state) => state.salesReport);
	const [mounted, setMounted] = useState(report.mounted);
    
	const [reportNamesColumn1, setReportNamesColumn1] = useState(null);
	const [reportNamesColumn2, setReportNamesColumn2] = useState(null);
	const [reportNames, setReportNames] = useState(null);
	
	useEffect(() => {
        if (!mounted) {
		    dispatch(getSalesReports());
        }
	}, []);

	const generateReportsLink = (_reportName) => {
        let reportName = _reportName;
        let reportList = null;
        if (isValidParam(reportName)) {
            reportName = filterReportNames(reportName);
            if (reportName.length > 0) {
                reportList = map(reportName, (item, index) => {
                    let reportType = '';
                    let iconClass = null;
                    let linkToUrl = '';
                    if (item.item_type != constants.USER_REPORT_TYPE) {
                        reportType = getReportType(object, item.item_name);
                        iconClass = getIconClass('system', item.item_type);
                        linkToUrl = getReportUrl(object, item.item_type, item.parameter_id);
                    } else {
                        reportType = getReportType(object, item.item_type);
                        iconClass = getIconClass(item.item_type, item.report_subtype,);
                        linkToUrl = getReportUrl(object, item.item_type + '-' + item.report_subtype, item.parameter_id);
                    }

                    return (
                        <div key={item.parameter_id} className='filist' style={{ position: 'relative' }} >
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '80%' }} key={'sales_report_' + item.item_name}>
                                <span style={{ color: '#CCCCCC', fontSize: '14px' }}>
                                    <i className={iconClass}></i>
                                </span>
                                <Link
                                    to={linkToUrl}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleReportChange(item.item_name, object, linkToUrl, reportType, index)}>
                                    <span style={{ color: '#ccc', fontSize: '14px', paddingLeft: '1px', paddingRight: '7px' }}></span>
                                    <span title={item.item_name} style={{ fontSize: '14px', lineHeight: '32px', color: '#717171' }}>
                                        {item.item_name}
                                    </span>
                                </Link>

                                {item.item_type == constants.USER_REPORT_TYPE && (getBooleanParam(app.me.is_tenant) || app.me.id == item.owner_id) &&
                                    <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                                        <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                                            <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                                                <span style={{ right: 30, cursor: 'pointer', paddingTop: '11px', position: 'absolute', color: '#717171' }} onClick={() =>  openReportBuilder(item, item.report_subtype) }>
                                                    <Icon key={'edit_' + index} style={{ 'fontSize': '0.9em' }} title={getLocalizedStrings().label.COMMON.EDIT}>edit</Icon>
                                                </span>
                                            </div>
                                        </div>
                                        <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                                            <span style={{ right: 10, cursor: 'pointer', paddingTop: '9px', position: 'absolute', color: '#717171' }} onClick={() => { deleteUserReport(item.parameter_id, item.report_subtype) }}>
                                                <Icon key={'delete_' + index} style={{ 'fontSize': '1.2em', fontWeight: 'normal', color: '#717171' }} title={getLocalizedStrings().label.COMMON.DELETE}>close</Icon>
                                            </span>
                                        </div>
                                    </div>
                                }

                                {item.item_type == constants.REPORT_TYPE_CUSTOM_RELATION && (getBooleanParam(app.me.is_tenant) || app.me.id == item.owner_id) &&
                                    <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                                        <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                                            <span style={{ right: 10, cursor: 'pointer', paddingTop: '9px', position: 'absolute', color: '#717171' }} onClick={() => { deleteUserReport(item.parameter_id, item.item_type) }}>
                                                <Icon key={'delete_' + index} style={{ 'fontSize': '1.2em', fontWeight: 'normal', color: '#717171' }} title={getLocalizedStrings().label.COMMON.DELETE}>close</Icon>
                                            </span>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    );
                });
            } else {
                let label = getLocalizedStrings().label.REPORTS.NO_REPORTS_FOUND;
                reportList = <div style={{ fontSize: '14px', lineHeight: '32px', color: '#717171' }}>
                    <span title={label}> {label} </span>
                </div>
            }
            return reportList;
        }
    }

	const generateReportsLinkWithHeaders = (_reportName) => {
        let reportName = _reportName
        let reportList = null;
        if (isValidParam(reportName)) {
            reportName = filterReportNames(reportName);
            let categoryList = generateReportHeaders(reportName);
            if (categoryList.length > 0) {
                reportList = map(categoryList, (item, index) => {
                    let listItems = generateReportsLink(item.reportName);
                    return (
                        <div name={item.name} id={item.id} key={item.id} style={{ fontSize: '14px', lineHeight: '32px', color: '#717171', marginTop: "1%" }}>
                            <span name={item.name + index} id={item.id + index} key={item.id + index} title={item.name} style={{ fontSize: "20px", fontWeight: "bold" }}> {item.name} </span>
                            <div style={{ display: 'grid', gridTemplateColumns: '50% 50%', marginTop: "1%"}}>
                                {listItems}
                            </div>
                        </div>
                    );
                });
            } else {
                let label = getLocalizedStrings().label.REPORTS.NO_REPORTS_FOUND;
                reportList = <div style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
                    <div style={{ fontSize: '14px', lineHeight: '32px', color: '#717171' }}>
                        <span title={label}> {label} </span>
                    </div>
                </div>
            }
            return reportList;
        }
    }
    const generateReportHeaders = (reportName) => {
        let categoryItemLists = ["Activity", "Pipeline", "Conversion", "Other", "Custom"];
        let finalReportNames = [];
        categoryItemLists.map((categoryItemList, index) => {
            let categoryObject = {};
            categoryObject.name = categoryItemList;
            categoryObject.id = categoryItemList;
            if (categoryItemList == "Activity") {
                let tempReports = reportName.filter(e => e.item_type == "Activity Matrix" || e.item_type == "Activities By Sales Rep" || e.item_type == "Call Report" || e.item_type == "Email Report" || e.item_type == "SMS Report" || e.item_type == "Tasks Report");
                categoryObject.reportName = tempReports;
            } else if (categoryItemList == "Pipeline") {
                let tempReports = reportName.filter(e => e.item_type == "Pipeline by Sales Rep" || e.item_type == "Pipeline Report" || e.item_type == "SALES SUMMARY REPORT");
                categoryObject.reportName = tempReports;
            } else if (categoryItemList == "Conversion") {
                let tempReports = reportName.filter(e => e.item_type == "Conversion By Sales Rep" || e.item_type == "Conversion Matrix" || e.item_type == "Conversion Report");
                categoryObject.reportName = tempReports;
            } else if (categoryItemList == "Other") {
                let tempReports = reportName.filter(e => ( e.item_type == "Audit Report" && app.me.is_franchisor == true ) || e.item_type == "Relation Matrix" || e.item_type.includes("InXpress") || e.item_type === "user-relation-matrix");
                categoryObject.reportName = tempReports;
            } else if (categoryItemList == "Custom") {
                let tempReports = reportName.filter(e => e.item_type == "user");
                categoryObject.reportName = tempReports;
            }
            finalReportNames.push(categoryObject);
        });
        return finalReportNames
    }

    const handleReportChange = (label, object, url, reportType, index) => {
        try {
            let headersName = report.data.report_names;
            let selectedReport = headersName.filter((item) => { return item.item_name == label });
            let selectedReportObject = new Object();
            let type = selectedReport[0].item_type;
            let parameterId = selectedReport[0].parameter_id;

            selectedReportObject.item_name = selectedReport[0].item_name;
            selectedReportObject.item_type = type;
            selectedReportObject.parameter_id = parameterId;
            selectedReportObject.report_subtype = selectedReport[0].report_subtype;

            saveRecentReportItems(label, type, parameterId, object);
            let params = { id: parameterId, type: LinkToType.TYPE_REPORT, name: label, report_type: type, report_subtype: selectedReport[0].report_subtype };
            addRecentFilterInRedux(params);
            addRecentRecordInRedux({ ...params, recent: label });
            openTab(label, object, url, reportType, selectedReportObject);
        } catch (error) {
            console.error("Error in 'columnReports.js ---> handleReportChange()':" + error);
        }
    }

    const openTab = (label, object, url, reportType, selectedReport) => {
        let info = { report_type: reportType, reportHeaderNames: reportNames, selectedReport: selectedReport };
        try {
            let tab = {
                item: object,
                label: label,
                object: object,
                type: TYPE_REPORT,
                url: url,
                info: info,
            };

            addTab(tab);
        } catch (error) {
            console.error("Error in 'columnReports.js ---> openTab()':" + error);
        }
    }

    const openReportBuilder = (report, type) => {
        try {
            let hasPermission = null;
            let drawerLabel = null;
            hasPermission = hasAccessPermission(null, constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_MANAGE_REPORTS);
            if (hasPermission) {
                let data = {};
                data.object = object.toLowerCase();
                let reportObjectName = getStringParam(report.report_object);
                if (reportObjectName == 'Custom Table 1' || reportObjectName == 'Custom Table 2' || reportObjectName == 'Custom Table 3' || reportObjectName == 'Custom Table 4') {
                    data.reportObj = getObjectNameByLabel(reportObjectName).toLowerCase();
                }
                else {
                    data.reportObj = getStringParam(reportObjectName).toLowerCase();
                }
                data.type = type;
                drawerLabel = getLocalizedStrings().label.COMMON.EDIT + ' ' + report.item_name;
                data.id = getIntParam(report.parameter_id);
                data.table = object.toLowerCase();
                data.mode = 'edit';
               dispatch(getAppDrawer(true, drawerLabel, constants.USER_REPORT, data, null));
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
            }
        } catch (error) {
            console.error("Error in 'columnReports.js -> openReportBuilder()':" + error);
        }
    }
   const filterReportNames = (reportName) => {
        try {
            reportName = reportName.filter(f => { return f.item_name != "Forecast Report" && f.item_name != "5 Ways Report" });
            sortArrayObjectByProperty(reportName, 'item_name');
            if (searchText) {
                reportName = reportName.filter(f => { return f?.item_name.toUpperCase().indexOf(searchText.toUpperCase()) > -1 });
            }
        } catch (error) {
            console.error("Error in 'columnReports.js -> filterReportNames()':" + error);
        }
        return reportName;
    }

	 const deleteUserReport = (reportId, reportType) => {
	 	try {
			let hasPermission = null;
	 		hasPermission = hasAccessPermission(
	 			null,
	 			constants.SECURITY_LEVEL_TYPE_PERMISSION,
	 			constants.PERMISSION_NAME_MANAGE_REPORTS
	 		);
	 		if (hasPermission) {
	 			if (reportId > 0) {
	 				let msg =
 					getLocalizedStrings().message.USER_REPORT.REPORT_DELETE_RECORD;
					sfDialogs.confirm(
	 					getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
	 					msg,
	 					() => deleteUserReportOnConfirm(reportId, reportType),
	 					null
	 				);
				}
	 		} else {
 			sfDialogs.alert(
	 				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
	 				getLocalizedStrings().message.COMMON.ACCESS_DENIED,
	 				null
	 			);
	 		}
	 	} catch (error) {
	 		console.error(
	 			"Error in 'columnReports.js -> deleteUserReport()':" + error
	 		);
	 	}
	 };

	
	const deleteUserReportOnConfirm = (reportId, reportType) => {
		let params = {};
		params.id = getIntParam(reportId);
		params.type = getStringParam(reportType);
		let url =
			getStringParam(reportType) === constants.REPORT_TYPE_CUSTOM_RELATION
				? endPoints.RELATION_REPORT.DELETE
				: endPoints.USERREPORT.DELETE;
		let promise = Promise.resolve(HTTPClient.post(url, params));
		promise.then((response) => {
			if (getIntParam(response.data.id) > 0 && response.status === 0) {
				removeReportInRedux(reportId);
				removeRecentFromRedux(object, reportId);
				dispatch(showCustomSnackBar(
					getLocalizedStrings().message.USER_REPORT.REPORT_DELETE,
					styles.snackbarBodyStyleSuccess,
					styles.snackBarStyleTop
                ));
			}
		});
	};

    let isContainSearch = searchText ? true : false;
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2;
    let isMounted = report.mounted;
    let reportName = isMounted ? report.data.report_names : [];
    return (
        <div style={{ paddingTop: '0px', paddingLeft: '11px' }}>
            {isMounted && isContainSearch ?
                <div style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
                    {generateReportsLink(reportName)}
                </div> :
                <div>
                    {isMounted && !isContainSearch ?
                        <div>
                            {generateReportsLinkWithHeaders(reportName)}
                        </div> :
                        <div style={{ width: '100%', height: contentHeight }}>
                            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                </div>
                            </div>
                        </div>}
                </div>
            }
        </div>
    )


};

export default ColumnReports;
