import React, { useEffect, useState } from 'react';
import { setHeader } from '../../../services/actions/headerActions';
import { constants } from '../../../services/constants/constants';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import { isValidParam, getStringParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import ListView from '../containers/ListView';
import { getActiveTab } from '../../../services/helper/sfTabManager';
import { useDispatch, useSelector } from "react-redux";


const AdvanceSearch = (object) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const activeTab = useSelector((state) => getActiveTab(state));
    const [isMounted, setIsMounted] = useState(false);
    const [stateObject, setObject] = useState(null);
    const [searchParams, setSearchParams] = useState(null);

    useEffect(() => {
        let kanbanObject = {};
        kanbanObject.currentView = '';
        kanbanObject.show = false;
        dispatch(setHeader(object, constants.ADVANCE_SEARCH_LABEL, kanbanObject, false, true));
        compStateDetails();
    }, [activeTab]);

    const compStateDetails = () => {
        let activeInfo = isValidParam(activeTab.info) ? getObjectParam(activeTab.info) : {};
        if ((!isValidParam(app.advanceSearch) || Object.keys(app.advanceSearch).length === 0) || activeInfo.hasOwnProperty('advanceSearch')) {
            app.advanceSearch = getObjectParam(activeInfo.advanceSearch);
        }

        let advanceSearch = getObjectParam(app.advanceSearch);
        setObject(getStringParam(advanceSearch.object))

        let queryType = "";
        if (object === constants.CUSTOM_TABLE1_OBJECT
            || object === constants.CUSTOM_TABLE2_OBJECT
            || object === constants.CUSTOM_TABLE3_OBJECT
            || object === constants.CUSTOM_TABLE4_OBJECT) {
            queryType = constants.SEARCH_QUERY_TYPE_ADVANCE;
        }
        setSearchParams({ ...advanceSearch.searchParams, query_name: constants.SEARCH_QUERY_NAME, query_type: queryType });
        setIsMounted(true);
    }


    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2;
    return (
        <   >
            <div style={{ paddingLeft: '26px', paddingRight: '0px', marginTop: '0px', width: '98%' }} >
                <div className="row">
                    <div className='col-md-12'>
                        <div>
                            {isMounted ?
                                <ListView isSearch={true} object={stateObject} searchParams={searchParams} searchText={searchParams.search_text} />
                                : <div style={{ width: '100%', height: contentHeight }}>
                                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </ >
    )
}




export default AdvanceSearch;
