import { getLocalizedStrings } from '../../../../services/constants/MultiLingual';

export const campaignDetailsSection1 = [
    {
        id: 'si22',
        section: 4,
        dataType: 'dropdown',
        dropDownName: 'use_case',
        dropDownValue: '',
        type: 'select',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.USE_CASE,
        options:[],
        required: true,
        
    },
    {
        id: 'si23',
        section: 41,
        dataType: 'input',
        name: 'use_case_description',
        value: '',
        type: 'text',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.USE_CASE_DESCRIPTION,
        required: true,
        multiline: true,
        max_lines: 3,
    },
    {
        id: 'si24',
        section: 41,
        dataType: 'input',
        name: 'opt_in_message',
        value: '',
        type: 'text',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.OPT_IN_MESSAGE,
        required: true,
        multiline: false,
        max_lines: 1,
    },
    // {
    //     id: 'si25',
    //     section: 41,
    //     dataType: 'input',
    //     name: 'consent_proof',
    //     value: '',
    //     type: 'text',
    //     label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.CONSENT_PROOF,
    //     required: true,
    //     multiline: false,
    //     max_lines: 1,
    // },
    {
        id: 'si26',
        section: 41,
        dataType: 'input',
        name: 'sample_message_1',
        value: '',
        type: 'text',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.SAMPLE_MESSAGE_1,
        required: true,
        multiline: true,
        max_lines: 3,
    },
    {
        id: 'si27',
        section: 41,
        dataType: 'input',
        name: 'sample_message_2',
        value: '',
        type: 'text',
        label: getLocalizedStrings().label.TWILIO_SMS_VERIFIACTION.SAMPLE_MESSAGE_2,
        required: true,
        multiline: true,
        max_lines: 3,
    }  
    
]