import React, { useState, useEffect, useRef } from "react";
import * as HTTPClient from "../../../services/helper/httpClient";
import { getAppDialog } from "../../../services/actions/appContainerActions";
import {
  Button,
  FormControl,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { endPoints } from "../../../services/constants/endPoints";
import { styles } from "../../../services/constants/styles";
import { isValidParam,getArrayParam } from "../../../services/helper/parameterVerifier";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { constants } from "../../../services/constants/constants";
import ShowCircularProgress from "../components/circularProgress";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import { useDispatch, useSelector } from "react-redux";
import AuditCategoryDialogCard from "./AuditCategoryDialogCard";
import { updateAuditCategory } from "../../../services/actions/auditActions";
import { getAuditCategory } from "../../../services/actions/auditActions";
import { refreshListView } from "../../../services/actions/listViewAcions";
import {
  getActiveTab,
  updateActiveTab,
} from "../../../services/helper/sfTabManager";

const AuditCategoriesDialog = (object) => {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const categoryList = useSelector(
    (state) => state.auditDetails.category
  );
  const bottomRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [isCategoryNameEmpty, setisCategoryNameEmpty] = useState(false);

  useEffect(() => {
    // Scroll to the bottom of the page on initial render (after reload)
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  useEffect(() => {
    getAuditCategoryList();
  }, []);

  const getAuditCategoryList = () => {
    setIsMounted(true);
    let promise = Promise.resolve(getAuditCategory());
    if (isValidParam(promise)) {
      promise.then((response) => {
        dispatch(updateAuditCategory(response));
        setIsMounted(false);
      });
    }
  };

  const handleClose = () => {
    setCategoryName("");
    setisCategoryNameEmpty(false);
    setisOpen(false);
  };

  const openAddCateogyPopup = () => {
    setisOpen(true);
  };

  const handleCategoryChange = (event) => {
    setisCategoryNameEmpty(false);
    setCategoryName(event.target.value);
  };

  const handleCloseauditDialog = () => {
    setisOpen(false);
    dispatch(getAppDialog(false, null, null, null, null, null));
  };

  const AddNewCategory = () => {
    let _newCategory = categoryName;
    let _orderId = categoryList?.length;
    if (_newCategory) {
      try {
        let params = {
          categoryId: 0,
          categoryName: _newCategory,
          orderId: _orderId,
        };
        let url = endPoints.AUDIT_QUESTIONS.SAVE_CATEGORY;
        let promise = Promise.resolve(HTTPClient.post(url, params));
        if (isValidParam(promise)) {
          promise.then((response) => {
            if (isValidParam(response)) {
              if (response.status === 0) {
                 let tempData = getArrayParam(categoryList);
                  tempData.push(response.data);
                  dispatch(updateAuditCategory(tempData));
                dispatch(
                  showCustomSnackBar(
                    getLocalizedStrings().message.AUDIT_CATEGORY.SAVED,
                    styles.snackbarBodyStyleSuccess,
                    styles.snackBarStyleTop
                  )
                );
                getAuditCategoryList();
                handleClose();
              } else if (
                response.status === -1 &&
                response.error.message === "Limit exceed"
              ) {
                let message =
                  getLocalizedStrings().message.AUDIT_CATEGORY.MAX_EXCEED;
                dispatch(
                  showCustomSnackBar(
                    message,
                    styles.snackbarBodyStyleError,
                    styles.snackBarStyleTop
                  )
                );
              } else {
                let inputElement = document.getElementById("category_name");
                if (isValidParam(inputElement) && inputElement !== null) {
                  inputElement.focus();
                }
                dispatch(
                  showCustomSnackBar(
                    getLocalizedStrings().message.AUDIT_CATEGORY
                      .CATEGORY_ALREADY_EXIST,
                    styles.snackbarBodyStyleError,
                    styles.snackBarStyleTop
                  )
                );
              }
            }
          });
        }
      } catch (error) {
        console.error(
          "Error in 'AddCategoryDialog.js -> saveNewCategory()':" + error
        );
      }
    } else {
      setisCategoryNameEmpty(true);
      let inputElement = document.getElementById("category_name");
      if (isValidParam(inputElement) && inputElement !== null) {
        inputElement.focus();
      }
      let message =
        getLocalizedStrings().message.AUDIT_CATEGORY.CATEGORY_BALNK_MESSAGE;
      dispatch(
        showCustomSnackBar(
          message,
          styles.snackbarBodyStyleError,
          styles.snackBarStyleTop
        )
      );
    }
  };

  const submitAuditCategoryReorderChanges = () => {
    let url = endPoints.AUDIT_CATEGORY.UPDATE;
    let promise = Promise.resolve(HTTPClient.post(url, categoryList));
    if (isValidParam(promise)) {
      promise.then((response) => {
        if (isValidParam(response)) {
          if (response.status === 0) {
            dispatch(
              showCustomSnackBar(
                getLocalizedStrings().message.AUDIT_CATEGORY
                  .CATEGORY_CHANGES_UPDATED,
                styles.snackbarBodyStyleSuccess,
                styles.snackBarStyleTop
              )
            );
            handleCloseauditDialog();
            let activeTab = getActiveTab();
            activeTab.info = {
              ...activeTab.info,
              rememberSelection: {
                ...(activeTab.info.rememberSelection ?? {}),
                [object]: new Set(),
              },
            };
            updateActiveTab(activeTab);
            refreshListView(constants.AUDIT_QUESTIONS_OBJECT);
          } else {
            dispatch(
              showCustomSnackBar(
                response.error.message,
                styles.snackbarBodyStyleError,
                styles.snackBarStyleTop
              )
            );
          }
        }
      });
    }
  };

  const renderCard = (card, index) => {
    return (
      <AuditCategoryDialogCard
        key={card.id}
        index={index}
        id={card.id}
        card={card}
      />
    );
  };

  let tempStyle = { width: "100%" };
  let contentHeight = window.innerHeight - 240;
  let top = (contentHeight - 10) / 2;

  return (
    <div style={tempStyle}>
      {!isMounted ? (
        <div
          style={{
            paddingTop: "7px",
            backgroundColor: "#D3D3D3",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              marginBottom: categoryList?.length > 0 ? "20px" : "",
              marginLeft: "17px",
            }}
          >
            {categoryList?.map((card, i) => renderCard(card, i))}
          </div>
          <div style={{ width: "100%", marginLeft: "20px", marginTop: "10px" }}>
            <a
              style={{
                fontSize: "16px",
                fontWeight: "normal",
                fontFamily:
                  "-apple-system, Roboto, Helvetica, Arial, sans-serif",
                cursor: "pointer",
              }}
              onClick={openAddCateogyPopup}
            >
              {getLocalizedStrings().label.AUDIT_CATEGORY.ADD_NEW_CATEGORY}
            </a>
          </div>
          <div
            ref={bottomRef}
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              marginTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Button
              onClick={() => submitAuditCategoryReorderChanges()}
              style={styles.primaryButton}
            >
              {getLocalizedStrings().label.COMMON.SAVE}
            </Button>
            <Button
              onClick={() => handleCloseauditDialog()}
              style={styles.primaryButton}
            >
              {getLocalizedStrings().label.COMMON.CLOSE}
            </Button>
          </div>
        </div>
      ) : (
        <div style={{ width: "100%", height: contentHeight }}>
          <div
            className="asset-loaderh"
            style={{ paddingTop: top, paddingLeft: "48%" }}
          >
            <div
              style={{
                ...styles.assetLoaderContainer,
                height: 50,
                width: 50,
                padding: 7,
              }}
            >
              <ShowCircularProgress
                size={30}
                style={{ marginTop: "3", marginLeft: "3" }}
              />
            </div>
          </div>
        </div>
      )}
      <div key="sign-dialog">
        <Dialog
          aria-labelledby="customized-dialog-title"
          onClose={handleClose}
          open={isOpen}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <div style={{ display: "flex" }}>
              <div
                className="modal-header"
                style={{
                  marginTop: 5,
                  display: "block",
                  alignItems: "center",
                  width: "100%",
                }}
                title={
                  getLocalizedStrings().label.AUDIT_CATEGORY.ADD_NEW_CATEGORY
                }
              >
                {getLocalizedStrings().label.AUDIT_CATEGORY.ADD_NEW_CATEGORY}
              </div>
              <div
                style={{
                  width: "50px",
                  float: "right",
                  alignItems: "right",
                  textAlign: "right",
                  alignSelf: "right",
                  marginLeft: "20px",
                }}
              >
                <IconButton
                  style={{ marginTop: "8px" }}
                  aria-label="close"
                  onClick={() => handleClose()}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                marginBottom: "1rem",
                width: "45rem",
                height: "400px",
                display: "inline",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="col-sm-9">
                <FormControl
                  style={{ width: "100%" }}
                  className="test"
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="category_name"
                    variant="outlined"
                    title={categoryName}
                    value={categoryName}
                    error={isCategoryNameEmpty}
                    autoFocus={isCategoryNameEmpty}
                    onChange={(event) => handleCategoryChange(event)}
                    size="small"
                    InputProps={{ style: { height: "35px" } }}
                    style={{ zIndex: 0, width: "27rem" }}
                    margin="dense"
                    className={"sf-fields-bg"}
                  />
                </FormControl>
              </div>
              <div
                style={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "10px",
                }}
              >
                <Button
                  onClick={() => AddNewCategory()}
                  style={styles.primaryButton}
                >
                  {getLocalizedStrings().label.COMMON.SAVE}
                </Button>
                <Button
                  onClick={() => handleClose()}
                  style={styles.primaryButton}
                >
                  {getLocalizedStrings().label.COMMON.CLOSE}
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default AuditCategoriesDialog;
