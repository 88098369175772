import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import { getInXpressReportId } from '../../../services/helper/utils';
import Button from '@mui/material/Button';
import { isValidParam, getStringParam, getIntParam, getArrayParam, getBooleanParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { getFranchiseListPromise, getFranchiseWFListPromise, setInXpressFranchiseList, setInXpressWorkflowList, setInXpressReportData } from '../../../services/actions/reportActions';
import { INXPRESS_REPORT } from '../../../services/constants/constants';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getActiveTab, getActiveTabInfo, updateTabInfo } from '../../../services/helper/sfTabManager';
import { constants } from '../../../services/constants/constants';
import InexpressReportTable from '../components/inexpressReportTable';
import { setHeader } from '../../../services/actions/headerActions';
import { dateOptions, getDateOptions } from '../../../services/helper/common';
import { SFPopover, SFReportDatePicker } from './sfFormFields';
import moment from 'moment';
import { dateFormat, sortArrayObjectByProperty } from '../../../services/helper/utils';
import { getInXpressReports } from '../../../services/actions/reportActions';
import { Icon } from '@mui/material';
import SFDataTable from '../components/sfDataTable';
import Pagination from '../components/pagination';
import { sortArrayObjectByPropertyOrderType } from '../../../services/helper/utils'
import { getCookie, setCookie } from '../../../services/helper/sfCookies';

const InexpressReport = ({ object }) => {

    const reportTable = useRef();
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const report = useSelector((state) => state.report);
    const userlist = useSelector((state) => state.userlist);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [reportParams, setReportParams] = useState({
        report_id: 0,
        item_type: "",
        due_by: "tw",
        user_names: "All",
    },)
    const [userNames, setUserNames] = useState('All');
    const [projectId, setProjectId] = useState(null);
    const [isGlobal, setIsGlobal] = useState(false)
    const [isCountry, setIsCountry] = useState(false);
    const [isFranchise, setIsFranchise] = useState(false);
    const [isFranchiseListShow, setIsFranchiseListShow] = useState(false);
    const [countries, setCountries] = useState([]);
    const [franchises, setFranchises] = useState([]);
    const [workflowList, setWorkflowList] = useState([]);
    const [selectedCountryId, setSelectedCountryId] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedFranchiseId, setSelectedFranchiseId] = useState(null);
    const [selectedFranchise, setSelectedFranchise] = useState(null);
    const [isTimelineShow, setIsTimelineShow] = useState(null);
    const [isWorkflowShow, setIsWorkflowShow] = useState(null);
    const [selectedWorkflowId, setSelectedWorkflowId] = useState(null);
    const [selectedWorkflow, setSelectedWorkflow] = useState(null);
    const [menuItemCountries, setMenuItemCountries] = useState([]);
    const [menuItemFranchises, setMenuItemFranchises] = useState([]);
    const [menuItemWorkflows, setMenuItemWorkflows] = useState([]);
    const [isMounted, setIsMounted] = useState(false);
    const [startIndex, setStartIndex] = useState(INXPRESS_REPORT.FETCH_COUNT_START);
    const [fetchSize, setFetchSize] = useState(INXPRESS_REPORT.FETCH_SIZE);
    const [isScroll, setIsScroll] = useState(false);
    const [isReload, setIsReload] = useState(false);
    const [isDisableScroll, setIsDisableScroll] = useState(false);
    const [regions, setRegions] = useState([]);
    const [selectedReport, setSelectedReport] = useState({});
    const [menuItemRegions, setMenuItemRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [greenlightParams, setGreenlightParams] = useState({
        fromDates: [],
        toDates: [],
        displayDates: [],
        fileName: null,
        downloadFileData: null
    },)
    const [rowSort, setRowSort] = useState({
        sortFieldName: 'company',
        isDescending: false,
        onSort: (fieldName, isDescending, records) => onSortFn(fieldName, isDescending, records)
    });
    const [fields, setFields] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [exportRecords, setExportRecords] = useState([]);
    const [records, setRecords] = useState([]);
    let reportRecordArr = [];
    useEffect(() => {
        updateStateFromTabInfo();
        let _selectedReport = getSelectedReport();
        loadReportFilterDetails(_selectedReport);
        let label = _selectedReport?.item_name ? _selectedReport.item_name : "";
        dispatch(setHeader(constants.SALES_OBJECT, label, null, false, true));
    }, [selectedReport]);

    const getSelectedReport = () => {
        let info = getActiveTabInfo();
        return info.selectedReport;

    }

    const loadReportFilterDetails = (objRecentReport) => {
        try {
            if (objRecentReport.hasOwnProperty("item_type") && (objRecentReport.item_type === "InXpress Report 20" || objRecentReport.item_type === "InXpress Report 21")) {
                objRecentReport.call_for = "region";
            }
            let hasDataInRedux = false;
            if (objRecentReport.hasOwnProperty("item_type") && report.reportData !== undefined && ((objRecentReport.item_type === 'InXpress Report 20' && report.reportData.hasOwnProperty('InXpressReport20')) || (objRecentReport.item_type === 'InXpress Report 21' && report.reportData.hasOwnProperty('InXpressReport21')))) {
                hasDataInRedux = true;
            }
            if (!hasDataInRedux) {
                var promise1 = Promise.resolve(HTTPClient.get(endPoints.SALES_REPORT.RECENT_REPORT, objRecentReport));
                promise1.then((response) => {
                    if (Object.keys(response).length !== 0) {
                        // let reportId = getIntParam(response.parameterId) > 0 ? getIntParam(response.parameterId) : getIntParam(response.reportTypeId);
                        let _reportParams = reportParams;
                        _reportParams.report_id = getIntParam(response.parameterId) > 0 ? getIntParam(response.parameterId) : getIntParam(response.reportTypeId);
                        _reportParams.item_type = response.itemType;
                        _reportParams.due_by = response.due_by;
                        setReportParams({ ..._reportParams });
                        setIsGlobal(response.isGlobal);
                        setIsCountry(response.isCountry);
                        setIsFranchise(response.isFranchise);
                        setIsFranchiseListShow(response.isFranchiseListShow);
                        setIsTimelineShow(response.isTimelineShow);
                        setIsWorkflowShow(response.isWorkflow);
                        setFranchises(response.franchises);
                        setWorkflowList(response.franchiseWFList);
                        setProjectId(response.projectId);
                        setRegions(response.regions);
                        setSelectedReport(objRecentReport);

                        if (response.due_by === "cust") {
                            let from_date = fromDate;
                            let to_date = toDate;
                            from_date = _toDate(response.from_date);
                            to_date = _toDate(response.to_date);
                            setFromDate(from_date);
                            setToDate(to_date);
                        } else {
                            setFromDate('');
                            setToDate('');
                        }

                        if (response.isGlobal && response.hasOwnProperty("countries")) {
                            let _countries = getArrayParam(response.countries);
                            setCountries([..._countries]);
                            if (getArrayParam(_countries).length > 0) {
                                let filteredCounmtry = _countries.filter(f => {
                                    if (f.countryName === "USA") { return f; }
                                }
                                );
                                let _selectedCountry = getStringParam(filteredCounmtry[0].countryName);
                                let _selectedCountryId = getStringParam(filteredCounmtry[0].myClientId);
                                setSelectedCountry(_selectedCountry);
                                setSelectedCountryId(_selectedCountryId);
                            }
                        }
                        if ((response.isGlobal || response.isCountry) && objRecentReport.hasOwnProperty("item_type") && (objRecentReport.item_type === "InXpress Report 20" || objRecentReport.item_type === "InXpress Report 21")) {
                            let _menuItemWorkflows = menuItemWorkflows;
                            let _menuItemCountries = menuItemCountries;
                            _menuItemWorkflows = [{ id: "-999", label: "" }];
                            _menuItemCountries = [{ id: "-998", label: "" }];
                            setMenuItemWorkflows(_menuItemWorkflows);
                            setCountries([]);
                            setMenuItemCountries(_menuItemCountries);
                            setSelectedWorkflow('');
                            setSelectedCountryId('');
                            setFetchSize(INXPRESS_REPORT.ACTIVITY_FETCH_SIZE)
                        } else {
                            setMenuItemWorkflows(null);

                        }
                        setSelectedFranchise(null);
                        setSelectedFranchiseId(null);

                        if (isValidParam(response.itemType) && isValidParam(response.itemType)
                            && response.itemType === "InXpress Report 5" && response.isFranchise) {
                            setSelectedWorkflow('All');
                            setSelectedWorkflowId(-99);
                        }
                        else {
                            setSelectedWorkflow(null);
                            setSelectedWorkflowId(null);
                        }

                        if (isValidParam(response.itemType) && isValidParam(response.itemType) && response.itemType === "InXpress Report 19") {
                            let currentDate = moment();
                            //.tz(timeZone[app.me.timezone]);
                            addWeekdays(currentDate, 5);
                        }
                        /** --- activeTab --- */
                        updateStateFromTabInfo();
                        setIsMounted(true);
                        populateFieldList(isGlobal, isCountry, isFranchise);

                    }
                });
            }
        }
        catch (error) {
            console.error("Error in 'inexpressReport.js -> loadReportFilterDetails()':" + error);
        }

    }

    const updateStateFromTabInfo = () => {
        try {
            let reportData = {};
            let info = getActiveTabInfo();
            let _reportParams = reportParams;
            let selectedReport = info.selectedReport;
            if (isValidParam(info)) {
                if (isValidParam(info.dueBy)) {
                    _reportParams.due_by = info.dueBy;
                    setReportParams({ ..._reportParams });
                    if (info.dueBy === 'cust') {
                        if (isValidParam(info.from_date)) {
                            setFromDate(info.from_date);
                        }
                        if (isValidParam(info.to_date)) {
                            setToDate(info.to_date);
                        }
                    }
                }
                if (isValidParam(info.wfName)) {
                    setSelectedWorkflow(info.wfName);
                }
                if (isValidParam(info.wfId)) {
                    setSelectedWorkflow(info.wfName);
                    setSelectedWorkflowId(info.wfId);
                    dispatch(setInXpressWorkflowList(report.workflowList));
                    populateWorkFlowMenuItems(report.workflowList, projectId);
                }

                if (isValidParam(info.isFranchiseListShow)) {
                    setIsFranchiseListShow(info.isFranchiseListShow)

                }

                if (isValidParam(info.isTimelineShow)) {
                    setIsTimelineShow(info.isTimelineShow);
                }

                if (isValidParam(info.selectedCountry)) {
                    setSelectedCountry(info.selectedCountry);
                }

                if (isValidParam(info.selectedCountryId)) {
                    setSelectedCountryId(info.selectedCountryId);
                }

                if (isValidParam(info.selectedFranchise)) {
                    setSelectedFranchise(info.selectedFranchise);
                }

                if (isValidParam(info.selectedFranchiseId)) {
                    setSelectedFranchiseId(info.selectedFranchiseId);
                }

                if (isValidParam(info.selectedFranchiseId) && isValidParam(report.franchiseList)) {
                    setFranchises(report.franchiseList);
                }
                if (isValidParam(info.userNames)) {
                    _reportParams.user_names = getStringParam(info.userNames);

                    setReportParams({ ..._reportParams });
                }
                if (selectedReport !== undefined && report.reportData !== undefined && selectedReport.hasOwnProperty("item_type") && ((selectedReport.item_type === 'InXpress Report 20' && report.reportData.hasOwnProperty('InXpressReport20')) || (selectedReport.item_type === 'InXpress Report 21' && report.reportData.hasOwnProperty('InXpressReport21')))) {

                    if (selectedReport.item_type === 'InXpress Report 20') {
                        reportData = report.reportData.InXpressReport20;
                     } else {
                        reportData = report.reportData.InXpressReport21;
                    }
                    setRegions([...reportData.regions]);
                    setSelectedRegion([...reportData.selectedRegion]);
                    setCountries([...reportData.countries]);
                    setSelectedCountryId(reportData.selectedCountryId);
                    setSelectedCountry(reportData.selectedCountry);
                    setWorkflowList([...reportData.workflowList]);
                    setSelectedWorkflow(reportData.selectedWorkflow);
                    setSelectedWorkflowId(reportData.selectedWorkflowId);
                    setFetchSize(reportData.fetchSize);
                    setFields([...reportData.fields]);
                    setRecords([...reportData.records]);
                    setTotalCount(reportData.totalCount);
                    setRowSort({...reportData.rowSort});
                    setIsMounted(true);
                    setIsGlobal(reportData.isGlobal);
                    setIsCountry(reportData.isCountry);
                    setIsFranchise(reportData.isFranchise);
                    setSelectedReport(reportData.selectedReport);
                    setReportParams(reportData.reportParamsObj);
                }
                if (selectedReport != undefined && report.reportData != undefined && selectedReport.hasOwnProperty("item_type") && ((selectedReport.item_type == 'InXpress Report 20' && report.reportData.hasOwnProperty('InXpressReport20')) || (selectedReport.item_type == 'InXpress Report 21' && report.reportData.hasOwnProperty('InXpressReport21')))) {
                    populateRegionsMenuItems();
                    populateCountryMenuItems(reportData.countries);
                    populateWorkFlowMenuItems(reportData.workflowList, projectId);
                }

            }
        } catch (error) {
            console.error("Error in 'inexpressReport.js -> updateStateFromTabInfo()':" + error);
        }
    }

    const stopScrolling = () => {
        setIsDisableScroll(true);
    }

    const getReportParamsObj = () => {
        let _selectedReport = getSelectedReport();
        let reportTypeName = _selectedReport.item_type;
        let reportTypeId = getInXpressReportId(reportTypeName);
        let reportName = _selectedReport.item_name;
        let reportParamsObj = {};
        try {
            if (getStringParam(reportTypeName.length) > 0 && reportTypeName === 'InXpress Report 12') {
                reportParamsObj.parameterId = _selectedReport.parameter_id;
                reportParamsObj.reportType = reportTypeName;
                reportParamsObj.reportTypeId = reportTypeId;
                reportParamsObj.reportId = reportTypeId;
                reportParamsObj.dueBy = reportParams.due_by;
                reportParamsObj.country = selectedCountry;
                reportParamsObj.selectedCountryId = selectedCountryId;
                reportParamsObj.startIndex = startIndex;
                reportParamsObj.isGlobal = isGlobal;
                reportParamsObj.isCountry = isCountry;
                reportParamsObj.isFranchise = isFranchise;
                reportParamsObj.fromDate = "";
                reportParamsObj.toDate = "";
            }
            else {
                reportParamsObj.parameterId = _selectedReport.parameter_id;
                reportParamsObj.reportType = reportTypeName;
                reportParamsObj.reportTypeId = reportTypeId;
                reportParamsObj.reportId = getInXpressReportId(reportTypeName);
                reportParamsObj.dueBy = reportParams.due_by;
                reportParamsObj.country = selectedCountry;
                reportParamsObj.selectedCountryId = selectedCountryId;
                reportParamsObj.franchiseId = selectedFranchise;
                reportParamsObj.franProjectId = selectedFranchiseId;

                if (reportTypeName !== 'InXpress Report 15' && reportTypeName !== 'InXpress Report 16') {
                    reportParamsObj.selectedWorkflowId = selectedWorkflowId;
                    reportParamsObj.selectedWorkflow = selectedWorkflow;
                    reportParamsObj.workflowList = workflowList;
                }

                reportParamsObj.startIndex = startIndex;
                reportParamsObj.isGlobal = isGlobal;
                reportParamsObj.isCountry = isCountry;
                reportParamsObj.isFranchise = isFranchise;
                reportParamsObj.fromDate = "";
                reportParamsObj.toDate = "";
                if (reportTypeName === 'InXpress Report 15' || reportTypeName === 'InXpress Report 16' || reportTypeName === 'InXpress Report 17') {
                    reportParamsObj.userNames = reportParams.user_names;
                }
                if (reportTypeName === 'InXpress Report 16') {
                    reportParamsObj.page_size = INXPRESS_REPORT.ACTIVITY_FETCH_SIZE;
                }
            }

            if (isValidParam(reportParamsObj.dueBy) && reportParamsObj.dueBy === 'cust') {

                let startDate = formatDate(fromDate);
                let endDate = formatDate(toDate);
                if (isValidParam(startDate) && isValidParam(endDate)) {

                    let selectedFromDate = new Date(startDate);
                    reportParamsObj.fromDate = formatDate(selectedFromDate);
                    let selectedToDate = new Date(endDate);
                    reportParamsObj.toDate = formatDate(selectedToDate);
                }
            }
            /** excel */
            reportParamsObj.reportName = reportName;
            reportParamsObj.dueBy = getStringParam(reportParamsObj.dueBy);
            if (reportParamsObj.dueBy !== 'cust') {
                reportParamsObj.dueByName = dateOptions[reportParamsObj.dueBy];
            }
            else if (reportParamsObj.dueBy === 'cust') {
                reportParamsObj.dueByName = reportParamsObj.fromDate + ' - ' + reportParamsObj.toDate;
            }
            /** excel */
            if (selectedReport.hasOwnProperty("item_type") && (selectedReport.item_type === "InXpress Report 20" || selectedReport.item_type === "InXpress Report 21")) {
                reportParamsObj.my_client_id = selectedCountryId;
                reportParamsObj.workflow_id = selectedWorkflowId;
                reportParamsObj.workflow_name = selectedWorkflow;
                reportParamsObj.selectedRegion = selectedRegion;
                reportParamsObj.country = selectedCountry;
            }
            return reportParamsObj;

        }
        catch (error) {
            console.error("Error in 'inexpressReport.js -> getReportParamsObj()':" + error);
        }
    }

    const generateReport = () => {
        let component = null;
        try {
            let reportParamsObj = getReportParamsObj();
            reportParamsObj = getObjectParam(reportParamsObj);
            let parameterId = getStringParam(reportParamsObj.parameterId);
            if (reportParamsObj.reportType === 'InXpress Report 19') {
                component = getGreenlightReport();
            } else if (reportParamsObj.reportType === 'InXpress Report 20') {
                if (totalCount > 0) {
                    component = <SFDataTable columns={fields} rowSort={rowSort} rows={records} customEvent={customEvent} />;
                } else {
                    component = getNoRecordContent();
                }
            } else if (parameterId !== '') {
                component = <InexpressReportTable
                    key={parameterId} data={{ reportParams: reportParamsObj, object: object }}
                    isScroll={isScroll} resetScroll={resetScroll} isReload={isReload}
                    resetReload={resetReload} startIndex={startIndex}
                    stopScrolling={stopScrolling} ref={reportTable}
                    handleMore={(event) => handleMore(event)} />;
            }

        }
        catch (error) {
            console.error("Error in 'inexpressReport.js -> generateReport()':" + error);
        }
        return component;
    }

    const downloadExcelReport = (exportRecordsData, _fields) => {
        let beforeHeaderComponent = excelGenerateBeforeHeaders();
        let headerComponents = excelGenerateHeaders(_fields);
        let rowComponents = excelGenerateRows(exportRecordsData, _fields);

        let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '  </tbody> </table>';
        let htmlData = htmlTable;
        if (isValidParam(htmlData) && htmlData !== '') {
            htmlData = htmlData.replace("%22", "\"");
            var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
            var downloadUrl = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = downloadUrl;
            a.download = 'ResultSet_' + new Date().getTime() + '.xls';
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    }

    const excelGenerateBeforeHeaders = () => {
        //     data={{ reportParams: reportParamsObj, object: object }}
        //     let reportParamsObj = getReportParamsObj();
        let reportParams = getReportParamsObj();
        let cells = '';
        let dueBy = '';
        let franchise = '';
        let workflow = '';
        let user = '';
        let region = '';
        let country = '';

        let reportName = getStringParam(reportParams.reportName);
        let reportType = getStringParam(reportParams.reportType);
        let columns = fields.filter(f => {
            if (!f.is_hidden) { return f; }
        });
        let columnsLength = getArrayParam(columns).length;

        cells = cells + '<tr><td  colspan="' + (columnsLength) + '"> ' + reportName + ' </td></tr>';
        if (reportType.length > 0) {

            if (reportType === 'InXpress Report 15' || reportType === 'InXpress Report 17') {
                user = getStringParam(reportParams.userNames);
                cells = cells + '<tr><td> User Name :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + user + ' </td></tr>';
            }
            if (reportType === 'InXpress Report 20' || reportType === 'InXpress Report 21') {
                region = getStringParam(reportParams.selectedRegion);
                country = getStringParam(reportParams.country);
                cells = cells + '<tr><td> Region :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + region + ' </td></tr>';
                cells = cells + '<tr><td> Country :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + country + ' </td></tr>';
            }
            if (reportType !== 'InXpress Report 13' && reportType !== 'InXpress Report 14' && reportType !== 'InXpress Report 20' && reportType !== 'InXpress Report 21') {
                dueBy = getStringParam(reportParams.dueByName);
                cells = cells + '<tr><td> Date :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + dueBy + ' </td></tr>';
            }
            if (reportType !== 'InXpress Report 15' && reportType !== 'InXpress Report 16' && reportType !== 'InXpress Report 17' && reportType !== 'InXpress Report 20' && reportType !== 'InXpress Report 21') {
                if (reportParams.isCountry) {
                    franchise = getStringParam(reportParams.franchiseId);
                    cells = cells + '<tr><td> Franchise :</td>  <td  align=\'left\'  colspan="' + (columnsLength - 1) + '"> ' + ' ' + franchise + ' </td></tr>';
                }
            }
            if (reportType !== 'InXpress Report 13' && reportType !== 'InXpress Report 14' && reportType !== 'InXpress Report 16' && reportType !== 'InXpress Report 15') {
                workflow = getStringParam(reportParams.selectedWorkflow);
                cells = cells + '<tr><td> Workflow :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + workflow + ' </td></tr>';
            }
        }
        return cells;
    }

    const excelGenerateHeaders = (_fields) => {
        let columns = getArrayParam(_fields);
        let cells = '';
        columns.map((columnObject, index) => {
            if (!columnObject.is_hidden) {
                cells = cells + '<th  bgcolor="6A8CCB"> ' + columnObject.label + ' </th>';
            }
        })
        return ('<tr>' + cells + '</tr>');
    }

    const excelGenerateRows = (exportRecordsData, _fields) => {
        let reportParamsObj = getReportParamsObj();
        reportParamsObj = getObjectParam(reportParamsObj);
        let rows = getArrayParam(exportRecordsData);
        let columns = getArrayParam(_fields);
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            rows.map((rowObject, rowIndex) => {
                let innerCells = '';
                columns.map((columnObject, colIndex) => {
                    if (!columnObject.is_hidden) {
                        innerCells = innerCells + '<td >' + rowObject[columnObject.name] + ' </td>';
                    }
                })
                if (rowIndex % 2 === 0) {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                } else {
                    cells = cells + ('<tr  bgcolor="Lavender">' + innerCells + ' </tr>');
                }

            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
        }
        return rowData;
    }

    const customEvent = (eventType, column, row, event) => {
        try {

        }
        catch (error) {
            console.error("Error in 'inexpressReport.js -> customEvent()':" + error);
        }
    }

    const onSortFn = (fieldName, isDescending,recordsData) => {
        try {
            let tempRecords = recordsData;
            let orderType = isDescending ? 'desc' : 'asc';
            sortArrayObjectByPropertyOrderType(tempRecords, fieldName, orderType);
            setOrderInfoData(fieldName, orderType);
            setRecords(tempRecords);
        }
        catch (error) {
            console.error("Error in 'inexpressReport.js -> onSort()':" + error);
        }
    }
    const sortArrayObjectByPropertyDesc = (array, property) => {
        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];

                    val1 = typeof val1 === "string" ? val1.toLowerCase() : val1;
                    val2 = typeof val2 === "string" ? val2.toLowerCase() : val2;

                    if (val1 > val2) {
                        return -1;
                    } else if (val1 < val2) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
        }
    }

    const cookieSetSortData = (value) => {
        let userid = app.me.id;
        let cVarName = "isDescending_" + userid
        if (value) {
            document.cookie = cVarName + "=yes";
        } else {
            document.cookie = cVarName + "=no";
        }
    }

    const cookieGetSortData = () => {
        let userid = app.me.id;
        let cVarName = "isDescending_" + userid + "=";
        let cookieVar = decodeURIComponent(document.cookie).split(';')
        for (var i = 0; i < cookieVar.length; i++) {
            var c = cookieVar[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(cVarName) === 0) {
                var value = c.substring(cVarName.length, c.length);
                if (value === "yes") {
                    return true;
                 } else {
                    return false;
                }
            }
        }
        return "";
    }

    const handleDropDownChange = (name, event, value) => {
        try {
            let _reportParams = reportParams;
            _reportParams[name] = value;
            setIsScroll(false);
            if (name == "due_by") {
                if (event == 'fromDate' || event == 'toDate') {
                    _reportParams[name] = 'cust';
                    if (event == 'fromDate') {
                        setFromDate(value);
                    } else if (event == 'toDate') {
                        setToDate(value);
                    }
                    setReportParams({ ..._reportParams });
                } else if (value == "cust") {
                    _reportParams.from_date = getDate();
                    _reportParams.to_date = getDate();
                    let from_date = getDate();
                    let to_date = getDate();
                    setFromDate(from_date);
                    setToDate(to_date);
                    setReportParams({ ..._reportParams });

                    return;
                } else {
                    _reportParams.from_date = null;
                    _reportParams.to_date = null;
                    setReportParams({ ..._reportParams });
                    return;
                }
            } else {
                setReportParams({ ..._reportParams });
                return;
            }
        }
        catch (error) {
            console.error("Error in 'inexpressReport.js -> handleDropDownChange()':" + error);
        }
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    const populateFieldList = (isGlobal, isCountry, isFranchise) => {
        try {
            let info = getActiveTabInfo();
            let reportType = getStringParam(reportParams.item_type);
            if (isValidParam(isGlobal) && isValidParam(isCountry) && isValidParam(isFranchise)) {

                if (isGlobal) {
                    if (selectedReport.hasOwnProperty("item_type") && !(selectedReport.item_type === "InXpress Report 20" || selectedReport.item_type === "InXpress Report 21")) {
                        populateCountryMenuItems(countries);
                        if (isValidParam(info)) {

                            if (reportType.length > 0 && reportType !== 'InXpress Report 12') {
                                if (info.hasOwnProperty('wfId') && info.hasOwnProperty('wfName')) {
                                    populateWorkFlowMenuItems(workflowList, projectId);
                                }
                                if (info.hasOwnProperty('selectedFranchiseId') && info.hasOwnProperty('selectedFranchise')) {
                                    populateFranchiseMenuItems();
                                }
                            }
                        }
                    } else {
                        populateRegionsMenuItems();
                    }
                } else if (isCountry) {
                    if (selectedReport.hasOwnProperty("item_type") && (selectedReport.item_type === "InXpress Report 20" || selectedReport.item_type === "InXpress Report 21")) {
                        populateRegionsMenuItems();
                    }
                }

                if (isGlobal || isCountry) {
                    if (reportType.length > 0) {
                        //---added by Priyanka
                        //if report 12 or 13 or 14 is selected the selectedCountry & selectedCountryId is auto populated from backend call    
                        if (reportType === 'InXpress Report 12' || reportType === 'InXpress Report 13' || reportType === 'InXpress Report 14') {
                            if (isCountry && (reportType === 'InXpress Report 13' || reportType === 'InXpress Report 14' && getBooleanParam(isFranchiseListShow))) {
                                let _franchises = getArrayParam(franchises);
                                let franchisePrjId = null;
                                let franchiseCode = null;
                                franchisePrjId = getIntParam(_franchises[0].projectId);
                                franchiseCode = getIntParam(_franchises[0].franchise_no);
                                setSelectedFranchise(franchiseCode)
                                setSelectedFranchiseId(franchisePrjId)
                            } else if (isCountry) {
                                let _countries = getArrayParam(countries);
                                let countryId = null;
                                let countryName = null;
                                countryId = getIntParam(_countries[0].myClientId);
                                countryName = getStringParam(_countries[0].countryName);
                                setSelectedCountry(countryName)
                                setSelectedCountryId(countryId)
                            }
                        }
                        //for other reports except report 12 franchise is populated  
                        if (reportType !== 'InXpress Report 2' && reportType !== 'InXpress Report 3' && reportType !== 'InXpress Report 5' && reportType !== 'InXpress Report 12' && reportType !== 'InXpress Report 14' && reportType !== 'InXpress Report 20' && reportType !== 'InXpress Report 21') {
                            if (isValidParam(projectId)) {
                                populateFranchiseMenuItems(projectId);
                            }
                        }
                        else if (reportType === 'InXpress Report 2' || reportType === 'InXpress Report 3' || reportType === 'InXpress Report 5' || reportType === 'InXpress Report 14') {
                            populateFranchiseMenuItems(null); //only franchise is required
                        }

                    }
                }

                if (reportType.length > 0 && (reportType !== 'InXpress Report 12' && reportType !== 'InXpress Report 13' && reportType !== 'InXpress Report 14')) {
                    populateWorkFlowMenuItems(null);
                }
            }
        }
        catch (error) {
            console.error("Error in 'inexpressReport.js -> populateFieldList()':" + error);
        }
    }

    const populateFranchiseMenuItems = (clientId) => {
        let reportType = getStringParam(reportParams.item_type);
        let _isFranchiseListShow = isFranchiseListShow;
        let _menuItemFranchises = [];
        let _franchises = [];
        let _selectedFranchise = selectedFranchise;
        try {
            if (isValidParam(clientId) && clientId > 0 && isValidParam(reportType) && reportType.length > 0 && reportParams.item_type !== "InXpress Report 20" && reportParams.item_type !== "InXpress Report 21") {

                if (!_isFranchiseListShow) {
                    let projectId = clientId;
                    populateWorkFlowMenuItems(workflowList, projectId);
                }
                else {

                    let params = {};
                    params.my_client_id = clientId;
                    params.report_type = reportType;
                    let promise = getFranchiseListPromise(params);
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            _franchises = response.franchises;
                            if (isValidParam(_franchises)) {

                                for (let i = 0; i < _franchises.length; i++) {
                                    let obj = _franchises[i];
                                    _menuItemFranchises.push({ id: obj.projectId, label: obj.franchise_no });
                                }

                                if (reportType === 'InXpress Report 13' || reportType === 'InXpress Report 19') {
                                    let obj = { projectId: -99, franchise_no: 'All' };
                                    _franchises.push(obj);
                                    _menuItemFranchises.push({ id: "-99", label: "All" });
                                    _selectedFranchise = obj.franchise_no;
                                    setSelectedFranchiseId(obj.projectId)
                                }
                                setMenuItemFranchises(_menuItemFranchises)
                                setFranchises(_franchises);
                                setSelectedFranchise(_selectedFranchise);
                            }
                        }
                    });
                }
            }
            else {
                //get the franchise default list from state.
                let _franchises = franchises;
                if (isValidParam(_franchises)) {
                    for (let i = 0; i < _franchises.length; i++) {
                        let obj = _franchises[i];
                        _menuItemFranchises.push({ id: obj.projectId, label: obj.franchise_no });
                    }
                    if (reportType !== 'InXpress Report 2' && reportType !== 'InXpress Report 3' && reportType !== 'InXpress Report 5') {
                        _menuItemFranchises.unshift({ id: "-99", label: "All" });
                        _franchises.unshift({ projectId: -99, franchise_no: 'All' });
                    }

                    let obj;
                    let info = getActiveTabInfo();
                    if (isValidParam(info.selectedFranchiseId)) {
                        obj = _franchises.find(f => { return f.projectId === info.selectedFranchiseId });
                    }
                    if (!isValidParam(obj) && _franchises.length > 0) {
                        obj = _franchises[0];
                    }
                    if (isValidParam(obj)) {
                        setSelectedFranchise(obj.franchise_no)
                        setSelectedFranchiseId(obj.projectId)
                    }
                    setMenuItemFranchises(_menuItemFranchises)
                    // setFranchises(_franchises)
                    if (reportType === 'InXpress Report 2' || reportType === 'InXpress Report 3' || reportType === 'InXpress Report 5') {
                        populateWorkFlowMenuItems(workflowList, projectId);
                    }
                }
            }
        } catch (error) {
            console.error("Error in 'inexpressReport.js -> populateFranchiseMenuItems()':" + error);
        }

    }

    const populateWorkFlowMenuItems = (workflow, projectId) => {
        let _selectedReport = getSelectedReport();
        let _menuItemFrnWF = [];
        let franchiseWFList = null;
        let selectedWorkflowId = -99;
        let selectedWorkflow = 'All';
        let reportType = _selectedReport.item_type;
        try {
            if (isValidParam(projectId) && projectId > 0) {
                let params = {};
                params.franchise_proj_id = projectId;
                let promise = getFranchiseWFListPromise(params);
                promise.then((response) => {

                    if (isValidParam(response)) {
                        franchiseWFList = response.franchiseWFList;
                        if (isValidParam(franchiseWFList)) {

                            if (app.selectedModuleName !== constants.MODULE_OPERATIONS) {
                                franchiseWFList = franchiseWFList.filter(f => { return f.NAME !== 'Onboarding' });
                            }

                            _menuItemFrnWF.push({ id: -99, label: 'All' });

                            for (let i = 0; i < franchiseWFList.length; i++) {
                                let obj = franchiseWFList[i];
                                _menuItemFrnWF.push({ id: obj.ID, label: obj.NAME });
                            }
                            /* if isGlobal and selected country==null or selected franchise==null  then selected Workflow is also null */
                            if (isGlobal) {
                                if ((reportType === 'InXpress Report 7' || reportType === 'InXpress Report 8' || reportType === 'InXpress Report 10' || reportType === 'InXpress Report 11')) {
                                    if (selectedCountryId === null) {
                                        selectedWorkflowId = null;
                                        selectedWorkflow = null;
                                    }
                                }
                                else if ((reportType === 'InXpress Report 9')) {
                                    if (selectedCountryId === null || selectedFranchiseId === null) {
                                        selectedWorkflowId = null;
                                        selectedWorkflow = null;
                                    }
                                }

                            } else if (isCountry && (reportType === 'InXpress Report 7' || reportType === 'InXpress Report 8' || reportType === 'InXpress Report 10' || reportType === 'InXpress Report 11')) {
                                selectedWorkflowId = null;
                                selectedWorkflow = null;
                            }
                            setWorkflowList(franchiseWFList);
                            setMenuItemWorkflows([..._menuItemFrnWF])
                            setSelectedWorkflowId(selectedWorkflowId);
                            setSelectedWorkflow(selectedWorkflow);
                        }
                    }
                });
            }
            else {
                franchiseWFList = workflow;
                if (isValidParam(franchiseWFList)) {
                    if (selectedReport.hasOwnProperty("item_type") && (selectedReport.item_type === "InXpress Report 20" || selectedReport.item_type === "InXpress Report 21")) {
                        _menuItemFrnWF.push({ id: "-999", label: "" });
                        for (let i = 0; i < franchiseWFList.length; i++) {
                            let obj = franchiseWFList[i];
                            _menuItemFrnWF.push({ id: obj.id, label: obj.name });
                        };
                    } else {
                        _menuItemFrnWF.push({ id: -99, label: 'All' });
                        // franchiseWFList.forEach(i => {
                        for (let i = 0; i < franchiseWFList.length; i++) {
                            let obj = franchiseWFList[i];
                            _menuItemFrnWF.push({ id: obj.ID, label: obj.NAME });

                        };
                    }

                    setWorkflowList(franchiseWFList);
                    setMenuItemWorkflows([..._menuItemFrnWF]);
                }
            }
        }
        catch (error) {
            console.error("Error in 'inexpressReport.js -> populateWorkFlowMenuItems()':" + error);
        }

    }

    const generateFieldsForGlobal = () => {
        let popoverStyle = { ...styles.sfFormFieldContainer, cursor: "pointer", fontSize: '14px', color: '#717171', height: '47px', paddingLeft: 7, width: '100%', paddingRight: 7 };
        try {
            let elements = [];
            if (selectedReport.hasOwnProperty("item_type") && !(selectedReport.item_type === "InXpress Report 20" || selectedReport.item_type === "InXpress Report 21")) {
                elements = [<SFPopover
                    label={getLocalizedStrings().label.INXPRESS_REPORT.COUNTRY_SELECT}
                    onChange={(event, value) => setHandleSelectedCountry(event, value)}
                    type={"dropdown"}
                    list={menuItemCountries}
                    defaultValue={selectedCountryId}
                    style={popoverStyle} />];

                if (isFranchiseListShow) {
                    elements.push(<SFPopover
                        label={getLocalizedStrings().label.INXPRESS_REPORT.FRANCHISE_SELECT}
                        onChange={(event, value) => setHandleSelectedFranchise(event, value)}
                        type={"dropdown"}
                        list={menuItemFranchises}
                        defaultValue={selectedFranchise === "All" ? '-99' : selectedFranchise}
                        style={{ ...popoverStyle, marginLeft: elements.length > 0 ? 10 : 0 }} />);
                }

                if (isWorkflowShow) {
                    elements.push(<SFPopover
                        label={getLocalizedStrings().label.INXPRESS_REPORT.WORKFLOW_SELECT}
                        onChange={(event, value) => setHandleSelectedWorkflow(event, value)}
                        type={"dropdown"}
                        list={menuItemWorkflows}
                        defaultValue={selectedWorkflow == "All" ? '-99' : selectedWorkflowId}
                        style={{ ...popoverStyle, marginLeft: elements.length > 0 ? 10 : 0 }} />);
                }
            } else {
                elements.push(<SFPopover
                    label={getLocalizedStrings().label.INXPRESS_REPORT.REGION_SELECT}
                    onChange={(event, value) => setHandleSelectedRegion(event, value)}
                    type={"dropdown"}
                    list={menuItemRegions}
                    defaultValue={selectedRegion}
                    style={popoverStyle} />);
                elements.push(<SFPopover
                    label={getLocalizedStrings().label.INXPRESS_REPORT.COUNTRY_SELECT}
                    onChange={(event, value) => setHandleSelectedCountry(event, value)}
                    type={"dropdown"}
                    list={menuItemCountries}
                    defaultValue={selectedCountryId}
                    style={popoverStyle} />);
                elements.push(<SFPopover
                    label={getLocalizedStrings().label.INXPRESS_REPORT.WORKFLOW_SELECT}
                    onChange={(event, value) => setHandleSelectedWorkflow(event, value)}
                    type={"dropdown"}
                    list={menuItemWorkflows}
                    defaultValue={selectedWorkflowId}
                    style={popoverStyle} />);
            }
            return <div style={{ display: 'flex' }}>{elements}</div>;
        } catch (error) {
            console.error("Error in 'inexpressReport.js -> generateFieldsForGlobal()':" + error);
        }
    }

    const getFranchiseValue = (franchise_no) => {
        let _franchises = getArrayParam(franchises);
        let tempArr = _franchises.filter(f => { return f.franchise_no === franchise_no });
        let projectId = tempArr.length > 0 ? tempArr[0].projectId : null;
        return projectId;
    }

    const generateFieldsForCountry = () => {
        let reportType = getStringParam(reportParams.item_type);
        let popoverStyle = { ...styles.sfFormFieldContainer, cursor: "pointer", fontSize: '14px', color: '#717171', height: '47px', paddingLeft: 7, width: '100%', paddingRight: 7 };
        try {
            let userNameList = null;
            let _reportParams = reportParams;
            if (reportType === 'InXpress Report 17') {
                userNameList = generateUserNames();
            }

            if (reportType === "InXpress Report 12" || reportType === "InXpress Report 16") {
                return null;
            }
            let securityLevelName = app.me.t_name;
            let isShowUserList = false;
            if (isValidParam(securityLevelName) && (securityLevelName === "Admin" ||
                securityLevelName === "Country Admin" || securityLevelName === "Country Support Center" ||
                securityLevelName === "Country Franchise Sales" || securityLevelName === "Country Management")) {
                isShowUserList = true
            }
            if (isValidParam(securityLevelName) && (securityLevelName !== "Admin" &&
                securityLevelName !== "Country Admin" && securityLevelName !== "Country Support Center" &&
                securityLevelName !== "Country Franchise Sales" && securityLevelName !== "Country Management")) {
                if (reportType === 'InXpress Report 17' && !app.me.is_tenant) {
                    _reportParams.user_names = app.me.name;
                    setReportParams({ ..._reportParams });
                }
                isShowUserList = false;
            }

            let elements = [];
            if (selectedReport.hasOwnProperty("item_type") && !(selectedReport.item_type === "InXpress Report 20" || selectedReport.item_type === "InXpress Report 21")) {
                if (reportType.length > 0 && reportType === 'InXpress Report 17' && isShowUserList) {
                    elements.push(<SFPopover
                        label={getLocalizedStrings().label.REPORT.SALES_REP}
                        onChange={(event, value) => handleDropDownChange('user_names', event, value)}
                        type={"dropdown"}
                        list={[{ id: 'All', label: 'All' }, ...userNameList]}
                        defaultValue={reportParams.user_names}
                        style={popoverStyle} />);
                }

                if (reportType.length > 0 && reportType !== 'InXpress Report 12' && isValidParam(isFranchiseListShow) && isFranchiseListShow) {
                    elements.push(<SFPopover
                        label={getLocalizedStrings().label.INXPRESS_REPORT.FRANCHISE_SELECT}
                        onChange={(event, value) => setHandleSelectedFranchise(event, value)}
                        type={"dropdown"}
                        list={menuItemFranchises}
                        defaultValue={getFranchiseValue(selectedFranchise)}
                        style={{ ...popoverStyle, marginLeft: elements.length > 0 ? 10 : 0 }} />);
                }

                if (reportType.length > 0 && reportType !== 'InXpress Report 12' && isValidParam(isWorkflowShow) && isWorkflowShow) {
                    elements.push(<SFPopover
                        label={getLocalizedStrings().label.INXPRESS_REPORT.WORKFLOW_SELECT}
                        onChange={(event, value) => setHandleSelectedWorkflow(event, value)}
                        type={"dropdown"}
                        list={menuItemWorkflows}
                        defaultValue={selectedWorkflow == "All" ? '-99' : selectedWorkflowId}
                        style={{ ...popoverStyle, marginLeft: elements.length > 0 ? 10 : 0 }} />);
                }
            } else {
                elements.push(<SFPopover
                    label={getLocalizedStrings().label.INXPRESS_REPORT.REGION_SELECT}
                    onChange={(event, value) => setHandleSelectedRegion(event, value)}
                    type={"dropdown"}
                    list={menuItemRegions}
                    defaultValue={selectedRegion}
                    style={popoverStyle} />);
                elements.push(<SFPopover
                    label={getLocalizedStrings().label.INXPRESS_REPORT.COUNTRY_SELECT}
                    onChange={(event, value) => setHandleSelectedCountry(event, value)}
                    type={"dropdown"}
                    list={menuItemCountries}
                    defaultValue={selectedCountryId}
                    style={popoverStyle} />);
                elements.push(<SFPopover
                    label={getLocalizedStrings().label.INXPRESS_REPORT.WORKFLOW_SELECT}
                    onChange={(event, value) => setHandleSelectedWorkflow(event, value)}
                    type={"dropdown"}
                    list={menuItemWorkflows}
                    defaultValue={selectedWorkflowId}
                    style={{ ...popoverStyle, marginLeft: elements.length > 0 ? 10 : 0 }} />);
            }
            return <div style={{ display: 'flex' }}>{elements}</div>;
        }
        catch (error) {
            console.error("Error in 'inexpressReport.js -> generateFieldsForCountry()':" + error);
        }
    }

    const generateFieldsForFranchise = () => {
        let reportType = getStringParam(reportParams.item_type);
        let colCss = null;
        let marginCss = null;
        let userNameList = null;
        if (reportType === "InXpress Report 15") {
            userNameList = generateUserNames();
            colCss = "col-md-12";
            marginCss = { marginLeft: '-5%' }
        }
        let popoverStyle = { ...styles.sfFormFieldContainer, cursor: "pointer", fontSize: '14px', color: '#717171', height: '47px', paddingLeft: 7, width: '100%', paddingRight: 7 };
        try {
            let elements = [];

            if (isWorkflowShow) {
                elements.push(<SFPopover
                    label={getLocalizedStrings().label.INXPRESS_REPORT.WORKFLOW_SELECT}
                    onChange={(event, value) => setHandleSelectedWorkflow(event, value)}
                    type={"dropdown"}
                    list={menuItemWorkflows}
                    defaultValue={selectedWorkflow == "All" ? '-99' : selectedWorkflowId}
                    style={popoverStyle} />)
            }

            if (reportType === "InXpress Report 15") {
                elements.push(<SFPopover
                    label={getLocalizedStrings().label.REPORT.SALES_REP}
                    onChange={(event, value) => handleDropDownChange('user_names', event, value)}
                    type={"dropdown"}
                    list={[{ id: 'All', label: 'All' }, ...userNameList]}
                    defaultValue={reportParams.user_names}
                    style={{ ...popoverStyle, marginLeft: elements.length > 0 ? 10 : 0 }} />);
            }

            return <div style={{ display: 'flex' }}>{elements}</div>;
        }
        catch (error) {
            console.error("Error in 'inexpressReport.js -> generateFieldsForFranchise()':" + error);
        }
    }

    const populateCountryMenuItems = (_countries) => {
        let _menuItemCountries = [];
        if (selectedReport.hasOwnProperty("item_type") && (selectedReport.item_type === "InXpress Report 20" || selectedReport.item_type === "InXpress Report 21")) {
            _menuItemCountries.push({ id: "-999", label: "" });
        }
        try {
            if (isValidParam(_countries)) {
                for (let i = 0; i < _countries.length; i++) {
                    let obj = _countries[i];
                    _menuItemCountries.push({ id: obj.myClientId, label: obj.countryName });
                }
                setCountries([..._countries]);
                setMenuItemCountries(_menuItemCountries);
            }
        }
        catch (error) {
            console.error("Error in 'inexpressReport.js -> populateCountryMenuItems()':" + error);
        }
    }

    const populateRegionsMenuItems = () => {
        let _menuItemRegions = [];
        _menuItemRegions.push({ id: "-999", label: "" });
        try {
            if (isValidParam(regions)) {
                for (let i = 0; i < regions.length; i++) {
                    let obj = regions[i];
                    _menuItemRegions.push({ id: regions[i], label: regions[i] });
                }
                setMenuItemRegions([..._menuItemRegions]);
            }
        }
        catch (error) {
            console.error("Error in 'inexpressReport.js -> populateRegionsMenuItems()':" + error);
        }
    }

    const setHandleSelectedCountry = (event, value) => {
        let selectedMyClientId = value;
        let countryValueSelected = null;
        let reportType = getStringParam(reportParams.item_type);
        try {
            if (isValidParam(selectedMyClientId)) {
                for (let i = 0; i < countries.length; i++) {
                    let item = countries[i];
                    if (selectedMyClientId === item.myClientId) {
                        countryValueSelected = item.countryName;
                        break;
                    }
                }
                if (reportType.length > 0 && reportType !== 'InXpress Report 12') {
                    populateFranchiseMenuItems(selectedMyClientId);
                }
            }

            if (isValidParam(countryValueSelected)) {
                setSelectedCountry(countryValueSelected)
                setSelectedCountryId(selectedMyClientId)
            }
            populateFilterData("country", "workflow", selectedMyClientId);
        }
        catch (error) {
            console.error("Error in 'inexpressReport.js -> setHandleSelectedCountry()':" + error);
        }
    }

    const setHandleSelectedRegion = (event, value) => {
        try {
            populateFilterData("region", "country", value);
        }
        catch (error) {
            console.error("Error in 'inexpressReport.js -> setSelectedRegion()':" + error);
        }
    }

    const populateFilterData = (callFrom, callFor, value) => {
        try {
            let _selectedReport = selectedReport;
            let _countries = countries;
            if (callFrom == "region" && callFor == "country") {
                _selectedReport.call_for = "country";
                _selectedReport.region = value;
                setSelectedRegion(value);
                _selectedReport.t_name = app.me.t_name;
                setSelectedWorkflow("");
                setSelectedCountryId("");
                setSelectedWorkflowId("");
            } else if (callFrom == "country" && callFor == "workflow") {
                _selectedReport.call_for = "workflow";
                _selectedReport.myClientId = value;
                setSelectedCountryId(value);
                _selectedReport.t_name = app.me.t_name;

                setSelectedWorkflow("");
                setSelectedWorkflowId("");
            }
            var promise1 = Promise.resolve(HTTPClient.get(endPoints.SALES_REPORT.RECENT_REPORT, _selectedReport));
            promise1.then((response) => {
                if (callFrom == "region" && callFor === "country") {
                    _countries = response.regionsCountries;
                    setSelectedReport(_selectedReport);
                    populateCountryMenuItems(_countries);
                } else if (callFrom === "country" && callFor === "workflow") {
                    let _workFlow = response.countriesWorkflow;
                    sortArrayObjectByProperty(_workFlow, 'name');
                    setSelectedReport(_selectedReport);
                    setWorkflowList(_workFlow);
                    populateWorkFlowMenuItems(_workFlow);
                    setCountries([..._countries]);

                }
            });
        }
        catch (error) {
            console.error("Error in 'inexpressReport.js -> populateFilterData()':" + error);
        }
    }

    const setHandleSelectedFranchise = (event, value) => {
        let selectedFrnProjectId = value;
        let selectedFranchiseNo = null;
        let wfList = [];
        let reportType = getStringParam(reportParams.item_type);
        try {
            if (isValidParam(selectedFrnProjectId)) {
                let _franchises = franchises;
                for (let i = 0; i < _franchises.length; i++) {
                    let item = _franchises[i];
                    if (selectedFrnProjectId === item.projectId) {
                        selectedFranchiseNo = item.franchise_no;
                        break;
                    }
                }
                if (reportType.length > 0 && reportType !== 'InXpress Report 12' && reportType !== 'InXpress Report 13' && reportType !== 'InXpress Report 14') {
                    populateWorkFlowMenuItems(workflowList, selectedFrnProjectId);
                }
            }
            if (isValidParam(selectedFranchiseNo)) {
                setSelectedFranchise(selectedFranchiseNo)
                setSelectedFranchiseId(selectedFrnProjectId)
            }
        } catch (error) {
            console.error("Error in 'inexpressReport.js -> setHandleSelectedFranchise()':" + error);
        }
    }

    const setHandleSelectedWorkflow = (event, value) => {
        try {
            let wfId = value;
            let selectedWfValue = null;
            let _isScroll = isScroll;
            if (isValidParam(wfId)) {

                if (wfId == '-99') {
                    selectedWfValue = 'All';
                }
                else {
                    let wfList = [];
                    if (selectedReport.hasOwnProperty("item_type") && !(selectedReport.item_type === "InXpress Report 20" || selectedReport.item_type === "InXpress Report 21")) {
                        wfList = getArrayParam(workflowList);
                        for (let i = 0; i < wfList.length; i++) {
                            let item = wfList[i];
                            if (wfId === item.ID) {
                                selectedWfValue = item.NAME;
                                break;
                            }
                        }
                    } else {
                        wfList = getArrayParam(workflowList);
                        for (let i = 0; i < wfList.length; i++) {
                            let item = wfList[i];
                            if (wfId === item.id) {
                                selectedWfValue = item.name;
                                break;
                            }
                        }
                    }
                }

            }

            if (_isScroll) {
                setIsScroll(false);
            }
            if (isValidParam(selectedWfValue)) {
                setSelectedWorkflow(selectedWfValue);
                setSelectedWorkflowId(wfId);
                setIsScroll(_isScroll);
            }
        }
        catch (error) {
            console.error("Error in 'inexpressReport.js -> setSelectedWorkflow()':" + error);
        }
    }

    /** Fields For Header */
    const generateHeaderFields = () => {
        let headerComponent = null;
        try {
            if (isGlobal && !isCountry && !isFranchise) {
                headerComponent = generateFieldsForGlobal();
            }
            else if (isCountry && !isGlobal && !isFranchise) {
                headerComponent = generateFieldsForCountry();
            }
            else if (isFranchise && !isGlobal && !isCountry) {
                headerComponent = generateFieldsForFranchise();
            }
        }
        catch (error) {
            console.error("Error in 'inexpressReport.js -> generateHeaderFields()':" + error);
        }
        return headerComponent;
    }

    const validateReportRequest = () => {
        let selectedReport = getSelectedReport();
        try {
            let isValidRequest = false;
            if (isGlobal) {
                let reportType = selectedReport.item_type;

                if (reportType === 'InXpress Report 1' || reportType === 'InXpress Report 2' || reportType === 'InXpress Report 3'
                    || reportType === 'InXpress Report 4' || reportType === 'InXpress Report 5' || reportType === 'InXpress Report 6' || reportType === 'InXpress Report 9') {

                    if (selectedCountryId === null) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_COUNTRY, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                    else if (selectedFranchiseId === null) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_FRANCHISE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                    else if (selectedWorkflowId === null) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_WORKFLOW, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                    else {
                        isValidRequest = true;
                    }
                }
                else if (reportType === 'InXpress Report 7' || reportType === 'InXpress Report 8' || reportType === 'InXpress Report 10'
                    || reportType === 'InXpress Report 11') {

                    if (selectedCountryId === null) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_COUNTRY, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                    else if (selectedWorkflowId === null) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_WORKFLOW, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                    else {
                        isValidRequest = true;
                    }
                }
                // for InXpress Report 12
                else if (reportType === 'InXpress Report 12' || reportType === 'InXpress Report 16') {
                    let msg = reportType === 'InXpress Report 12' ? getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_COUNTRY :
                        getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_COUNTRY_INXPRESS_REPORT16;
                    if (selectedCountryId == null) {
                        dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                    else {
                        isValidRequest = true;
                    }
                } else if (reportType == 'InXpress Report 20' || reportType == 'InXpress Report 21') {
                    let msg = "";
                    if (selectedRegion === null || selectedRegion === '' || selectedRegion === undefined) {
                        msg = getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_REGION_INXPRESS_REPORT
                        dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    } else if (selectedCountryId === null || selectedCountryId === '' || selectedCountryId === undefined) {
                        msg = getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_COUNTRY_INXPRESS_REPORT16
                        dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    } else if (selectedWorkflowId === null || selectedWorkflowId === 0 || selectedWorkflowId === undefined) {
                        msg = getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_WORKFLOW_INXPRESS_REPORT;
                        dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    } else {
                        isValidRequest = true;
                    }
                }

            }/** validation for isCountry */
            else if (isCountry) {
                let reportType = selectedReport.item_type;
                if (reportType === 'InXpress Report 1' || reportType === 'InXpress Report 2' || reportType === 'InXpress Report 3'
                    || reportType === 'InXpress Report 4' || reportType === 'InXpress Report 5' || reportType === 'InXpress Report 6'
                    || reportType === 'InXpress Report 9') {

                    if (selectedFranchiseId === null) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_FRANCHISE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                    else if (selectedWorkflowId === null) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_WORKFLOW, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                    else {
                        isValidRequest = true;
                    }
                }
                else if (reportType === 'InXpress Report 7' || reportType === 'InXpress Report 8' || reportType === 'InXpress Report 10'
                    || reportType === 'InXpress Report 11') {
                    if (selectedWorkflowId === null) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_WORKFLOW, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                    else {
                        isValidRequest = true;
                    }
                }
                else if (reportType === 'InXpress Report 13' || reportType === 'InXpress Report 14' || reportType === 'InXpress Report 19') {

                    if (selectedFranchiseId === null) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_FRANCHISE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                    else {
                        isValidRequest = true;
                    }
                }
                else if (reportType === 'InXpress Report 12') {
                    isValidRequest = true;
                }
                else if (reportType === 'InXpress Report 16') {
                    isValidRequest = true;
                }
                else if (reportType === 'InXpress Report 17') {
                    isValidRequest = true;
                } else if (reportType == 'InXpress Report 20' || reportType == 'InXpress Report 21') {
                    let msg = "";
                    if (selectedRegion == null || selectedRegion == '' || selectedRegion == undefined) {
                        msg = getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_REGION_INXPRESS_REPORT
                        dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    } else if (selectedCountryId == null || selectedCountryId == '' || selectedCountryId == undefined) {
                        msg = getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_COUNTRY_INXPRESS_REPORT16
                        dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    } else if (selectedWorkflowId == null || selectedWorkflowId == 0 || selectedWorkflowId == undefined) {
                        msg = getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_WORKFLOW_INXPRESS_REPORT;
                        dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    } else {
                        isValidRequest = true;
                    }
                }
            }
            else if (isFranchise) {
                let reportType = selectedReport.item_type;
                if (reportType === 'InXpress Report 15') {
                    isValidRequest = true;
                } else {
                    if (selectedWorkflowId === null) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_WORKFLOW, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                    else {
                        isValidRequest = true;
                    }
                }
            }
            return isValidRequest;
        } catch (error) {
            console.error("Error in 'inexpressReport.js -> validateReportRequest()':" + error);
        }
    }

    const reloadReport = (callFrom) => {

        let activeTab = getActiveTab();
        let info = getObjectParam(activeTab.info);
        let isValidRequest = false;
        let _exportRecords = exportRecords;
        let reportType = getStringParam(reportParams.item_type);
        isValidRequest = validateReportRequest();
        if (isValidRequest) {
            if (isValidParam(info)) {
                info.dueBy = reportParams.due_by;
                info.wfId = selectedWorkflowId;
                info.wfName = selectedWorkflow;
                info.from_date = fromDate;
                info.to_date = toDate;
                info.isFranchiseListShow = isFranchiseListShow;
                info.isTimelineShow = isTimelineShow;
                info.selectedCountry = getStringParam(selectedCountry);//.toString()
                info.selectedCountryId = selectedCountryId;
                info.selectedFranchise = selectedFranchise;
                info.selectedFranchiseId = selectedFranchiseId;
                //For report type 15
                if (reportType === 'InXpress Report 15' || reportType === 'InXpress Report 16' || reportType === 'InXpress Report 17') {
                    info.userNames = reportParams.user_names;
                    dispatch(setInXpressReportData(info));
                }

                //store in redux- franchise list 
                if (getArrayParam(franchises).length > 0) {
                    dispatch(setInXpressFranchiseList(franchises));
                }
                //store in redux- workflow list
                if (getArrayParam(workflowList).length > 0) {
                    dispatch(setInXpressWorkflowList(workflowList));
                }
            }
            updateTabInfo(info);
            if (reportType === 'InXpress Report 20') {
                let reportParamsObj = getReportParamsObj();
                let paramObj = {};
                paramObj.parameter_id = reportParamsObj.parameterId;
                paramObj.report_id = reportParamsObj.reportId;
                paramObj.report_type_id = reportParamsObj.reportTypeId;
                paramObj.due_by = reportParamsObj.dueBy;
                paramObj.hdn_from_date = "";
                paramObj.hdn_to_date = "";
                paramObj.country = "";
                paramObj.my_client_id = reportParamsObj.selectedCountryId;
                paramObj.franchise_id = "";
                paramObj.workflow_id = getStringParam(reportParamsObj.selectedWorkflowId);
                paramObj.workflow_name = reportParamsObj.selectedWorkflow;
                paramObj.drill_down = "1";
                paramObj.franProjectId = "";
                paramObj.start_index = reportParamsObj.startIndex;
                paramObj.page_size = fetchSize;
                paramObj.selectedRegion = reportParamsObj.selectedRegion;
                if (callFrom === "export") {
                    paramObj.callFor = "export";
                }
                if (isValidParam(paramObj)) {
                    let promise = getInXpressReports(paramObj);
                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            if (isValidParam(response) && response.fields.length > 0) {
                                let _recordsData = getArrayParam(response.records);
                                if (callFrom !== "export") {
                                    let _rowSort = {};
                                    if (callFrom !== "pagination" && callFrom !== "refresh") {
                                        let sortInfo = getOrderInfoData();
                                        if (sortInfo !== null && Object.keys(sortInfo).length > 0) {
                                            let fieldName = sortInfo.fieldName;
                                            let orderType = sortInfo.orderType;
                                            sortArrayObjectByPropertyOrderType(_recordsData, fieldName, orderType);
                                            _rowSort = {
                                                enableAllColumn: true,
                                                sortFieldName: fieldName,
                                                isDescending: orderType === 'asc' ? false : true,
                                                onSort: (fieldName, isDescending) => onSortFn(fieldName, isDescending, _recordsData)
                                            }
                                        }
                                    } else {
                                        _rowSort = rowSort;
                                    }
                                    if (_recordsData.length > 0) {
                                        let tempRecordArr = _recordsData;
                                        if (getBooleanParam(_rowSort.isDescending)) {
                                            sortArrayObjectByPropertyDesc(tempRecordArr, _rowSort.sortFieldName);
                                        } else {
                                            sortArrayObjectByProperty(tempRecordArr, _rowSort.sortFieldName);
                                        }
                                        if (tempRecordArr.length > 0) {
                                            _recordsData = tempRecordArr;
                                        }
                                    }
                                    let reportData = {};

                                    reportData.reportParams = paramObj;
                                    reportData.regions = regions;
                                    reportData.selectedRegion = selectedRegion;
                                    reportData.countries = countries;
                                    reportData.selectedCountryId = selectedCountryId;
                                    reportData.selectedCountry = selectedCountry;
                                    reportData.workflowList = workflowList;
                                    reportData.selectedWorkflowId = selectedWorkflowId;
                                    reportData.selectedWorkflow = selectedWorkflow;
                                    reportData.startIndex = startIndex;
                                    reportData.fetchSize = fetchSize;
                                    reportData.fields = response.fields;
                                    reportData.records = _recordsData;
                                    reportData.totalCount = response.total_count;
                                    reportData.rowSort = rowSort;
                                    reportData.isGlobal = isGlobal;
                                    reportData.isCountry = isCountry;
                                    reportData.isFranchise = isFranchise;
                                    reportData.selectedReport = selectedReport;
                                    reportData.reportParamsObj = reportParams
                                    let reportDataObj = report.hasOwnProperty('reportData') ? report.reportData : {};
                                    reportDataObj.InXpressReport20 = reportData;
                                    dispatch(setInXpressReportData(reportData));
                                    if (response.total_count === 0) {
                                        if (!getBooleanParam(_rowSort.isDescending)) {
                                            sortArrayObjectByProperty(_recordsData, _rowSort.sortFieldName);
                                        } else {
                                            sortArrayObjectByPropertyDesc(_recordsData, _rowSort.sortFieldName);
                                        }
                                    }
                                    let _fields = fields;
                                    _fields = response.total_count === 0 ? [] : response.fields;
                                    _recordsData = response.total_count === 0 ? [] : _recordsData;
                                    setFields([..._fields]);
                                    setRecords([..._recordsData]);
                                    reportRecordArr = _recordsData;
                                    setTotalCount(response.total_count);
                                    setIsMounted(true);
                                    setIsReload(true);
                                    setIsDisableScroll(false);
                                    setRowSort(_rowSort);
                                    
                                } else {
                                    if (response.total_count > 0) {
                                        let _fields = fields;
                                        _fields = response.total_count === 0 ? [] : response.fields;
                                        _exportRecords = response.total_count === 0 ? [] : _recordsData;
                                        setExportRecords([..._exportRecords]);
                                        downloadExcelReport(_exportRecords, _fields);
                                    } else {
                                        dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.NO_RECORD_EXPORT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                    }
                                }
                            }
                        });
                    }
                }
            } else {
                if (reportType === 'InXpress Report 21') {
                    let reportParamsObj = getReportParamsObj();
                    let paramObj = {};
                    paramObj.parameter_id = reportParamsObj.parameterId;
                    paramObj.report_id = reportParamsObj.reportId;
                    paramObj.report_type_id = reportParamsObj.reportTypeId;
                    paramObj.due_by = reportParamsObj.dueBy;
                    paramObj.hdn_from_date = "";
                    paramObj.hdn_to_date = "";
                    paramObj.country = "";
                    paramObj.my_client_id = reportParamsObj.selectedCountryId;
                    paramObj.franchise_id = "";
                    paramObj.workflow_id = getStringParam(reportParamsObj.selectedWorkflowId);
                    paramObj.workflow_name = reportParamsObj.selectedWorkflow;
                    paramObj.drill_down = "1";
                    paramObj.franProjectId = "";
                    paramObj.start_index = reportParamsObj.startIndex;
                    paramObj.page_size = fetchSize;
                    paramObj.selectedRegion = reportParamsObj.selectedRegion;
                    let reportData = {};
                    reportData.reportParams = paramObj;
                    reportData.regions = regions;
                    reportData.selectedRegion = selectedRegion;
                    reportData.countries = countries;
                    reportData.selectedCountryId = selectedCountryId;
                    reportData.selectedCountry = selectedCountry;
                    reportData.workflowList = workflowList;
                    reportData.selectedWorkflowId = selectedWorkflowId;
                    reportData.selectedWorkflow = selectedWorkflow;
                    reportData.startIndex = startIndex;
                    reportData.fetchSize = fetchSize;
                    reportData.fields = [];
                    reportData.records = [];
                    reportData.totalCount = 0;
                    reportData.rowSort = rowSort;
                    reportData.isGlobal = isGlobal;
                    reportData.isCountry = isCountry;
                    reportData.isFranchise = isFranchise;
                    reportData.selectedReport = selectedReport;
                    reportData.reportParamsObj = reportParams
                    let reportDataObj = report.hasOwnProperty('reportData') ? report.reportData : {};
                    reportDataObj.InXpressReport21 = reportData;
                    dispatch(setInXpressReportData(reportData));
                }
                setIsMounted(true);
                setIsReload(true);
                setIsDisableScroll(false);
            }
        }
    }

    const getOrderInfoData = () => {
        try {
            let orderInfo = {};
            let cookieName = 'inexpress_reports_order' + object + '_' + app.me.projectId + '_' + app.me.id;
            let cookieValue = getObjectParam(JSON.parse(getCookie(cookieName)));
            if (Object.keys(cookieValue).length === 0) {
                return orderInfo;
            } else {
                let fieldName = cookieValue['fieldName'];
                let orderType = cookieValue['orderType'];
                orderInfo.fieldName = fieldName;
                orderInfo.orderType = orderType;
                return orderInfo;
            }
        } catch (error) {
            console.error("Error in 'inexpressReport.js -> getOrderInfoData()':" + error);
        }
    }

    const setOrderInfoData = (fieldName, orderType) => {
        try {
            let orderInfo = {}
            if (fieldName !== null && getStringParam(fieldName) !== '' && orderType !== null && getStringParam(orderType) !== '') {
                let cookieName = 'inexpress_reports_order' + object + '_' + app.me.projectId + '_' + app.me.id;
                orderInfo.fieldName = fieldName;
                orderInfo.orderType = orderType;
                setCookie(cookieName, JSON.stringify(orderInfo), constants.DEFAULT_COOKIE_EXPIRES);
            }

        } catch (error) {
            console.error("Error in 'inexpressReport.js -> setOrderInfoData()':" + error);
        }
    }

    const handleScroll = (event) => {
        try {
            let reportType = getReportType();
            if (reportType === 'InXpress Report 16' && !isDisableScroll) {
                let elem = document.getElementById('data-table-container');
                if (elem && Math.round(event.currentTarget.scrollTop) === (elem.scrollHeight - elem.offsetHeight)) {
                    let _startIndex = startIndex + 10;
                    setStartIndex(_startIndex)
                    setIsScroll(true)
                    reloadReport();
                }
            }
        } catch (error) {
            console.error("Error in 'inexpressReport.js -> handleScroll()':" + error);
        }
    }

    const handleMore = (event) => {
        try {
            let reportType = getReportType();
            if (reportType !== 'InXpress Report 14') {
                let _startIndex = reportType === 'InXpress Report 16' ? startIndex + INXPRESS_REPORT.ACTIVITY_FETCH_SIZE : startIndex + fetchSize;
                setStartIndex(_startIndex);
                setIsScroll(true);
                reloadReport();
            }
        } catch (error) {
            console.error("Error in 'inexpressReport.js -> handleMore()':" + error);
        }
    }

    const handleRefresh = () => {
        document.getElementById('data-table-container').scrollTop = 0;
        setStartIndex(INXPRESS_REPORT.FETCH_COUNT_START)
        setIsScroll(false);
        reloadReport("refresh");
    }

    const resetScroll = () => {
        setIsScroll(false)
    }

    const resetReload = () => {
        setIsReload(false);
    }

    const getNoRecordContent = () => {
        return (<table className="layout_maintable" style={{ width: '100%' }}><tr><td><span style={{ color: 'red' }}>{getLocalizedStrings().label.INXPRESS_REPORT.NO_RECORDS_FOUND}</span></td></tr></table>);
    }

    const generateUserNames = () => {
        return userlist.data.map((user, index) => {
            return { id: user.value, label: user.value };
        });
    }

    const getReportType = () => {
        let selectedReport = getSelectedReport();
        let reportType = getObjectParam(selectedReport).item_type;
        reportType = getStringParam(reportType).trim();
        return reportType;
    }

    const getButtonSet = (selectedReport) => {
        let isShow = false;
        if (selectedReport !== undefined && selectedReport.hasOwnProperty("item_type") && (selectedReport.item_type === 'InXpress Report 20' || selectedReport.item_type === 'InXpress Report 21')) {
            isShow = true;
        }
        return (
            <div>
                {!isShow &&
                    <Button
                        onClick={handleRefresh}

                        style={{
                            ...styles.primaryButton,
                            minWidth: '40px'
                        }}
                        title={getLocalizedStrings().label.REPORT.GO}
                    > {getLocalizedStrings().label.REPORT.GO}</Button>
                }
                {isShow &&
                    <Button
                        startIcon={<Icon style={{ fontSize: '20px' }} >autorenew</Icon>}
                        onClick={handleRefresh}

                        style={{
                            ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                            color: '#717171',
                            border: '0px',
                        }}
                        title={getLocalizedStrings().label.REPORT.GO}
                    ></Button>
                }
                <iframe id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute', display: 'none' }}></iframe>
                {!isShow &&
                    <Button

                        onClick={() => {

                            let content = document.getElementById("divcontents");
                            let ifrmae = document.getElementById("ifmcontentstoprint");
                            ifrmae.style.display = 'block'
                            let pri = document.getElementById("ifmcontentstoprint").contentWindow;
                            ifrmae.style.display = 'block'

                            pri.document.open();
                            pri.document.write(

                                content.innerHTML);
                            pri.document.close();
                            pri.focus();
                            pri.print();
                            ifrmae.style.display = 'none'
                        }}

                        style={{
                            ...styles.secondaryButton,
                            minWidth: '40px'
                        }}
                        title={getLocalizedStrings().label.REPORT.PRINT}
                    >{getLocalizedStrings().label.REPORT.PRINT}</Button>
                }
                {isShow && <Button
                    startIcon={<i className="far fa-file-excel" style={{ fontSize: '20px', color: 'green' }} />}
                    onClick={() => downloadExcel()} //config
                    style={{
                        ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                        color: '#717171',
                        border: '0px',
                        minWidth: '30px',
                        float: 'right'
                    }}
                    title="Excel"
                ></Button>}
                {isShow &&
                    <Button
                        startIcon={<Icon style={{ fontSize: '20px' }} >print</Icon>}
                        onClick={() => {

                            let content = document.getElementById("data-table-container");
                            let ifrmae = document.getElementById("ifmcontentstoprint");
                            ifrmae.style.display = 'block'
                            let pri = document.getElementById("ifmcontentstoprint").contentWindow;
                            ifrmae.style.display = 'block'

                            pri.document.open();
                            pri.document.write(

                                content.innerHTML);
                            pri.document.close();
                            pri.focus();
                            pri.print();
                            ifrmae.style.display = 'none'
                        }}

                        style={{
                            ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                            color: '#717171',
                            border: '0px',
                            minWidth: '30px',
                            float: 'right'
                        }}
                        title={getLocalizedStrings().label.REPORT.PRINT}
                    ></Button>
                }
                {!isShow && <Button
                    startIcon={<i className="far fa-file-excel" style={{ fontSize: '20px', color: 'green' }} />}
                    onClick={() => downloadExcel()} //config
                    style={{
                        ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                        color: '#717171',
                        border: '0px',
                        minWidth: '30px',
                        float: 'right'
                    }}
                    title="Excel"
                ></Button>}
                {/*  <Button
                    startIcon={<i className="fa fa-file-pdf-o" style={{ fontSize: '20px', color: 'red' }}/>}
                    onClick={() => {

                        
                        downloadPdf();
                    }
                    }
                    style={{
                        ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                        color: '#717171',
                        border: '0px',
                        minWidth: '30px'
                    }}
                    title="Pdf"
               ></Button> */}
            </div>
        );
    }

    const getPaginationData = (object, params) => {
        try {
            if (isValidParam(params) !== null) {
                let startIndex = params.hasOwnProperty("start_index") ? getIntParam(params.start_index) : 0;
                let pageSize = params.hasOwnProperty("page_size") ? getIntParam(params.page_size) : 50;
                setStartIndex(startIndex)
                setFetchSize(pageSize)
                reloadReport("pagination");
            }
        }
        catch (error) {
            console.error("Error in 'inexpressReport.js -> getPaginationData()':" + error);
        }
    }

    /** excel */
    const downloadExcel = () => {
        let _total = report.inExpressReportData.records?.length || 0;
        if (_total > 0) {
            downloadExcelReport(report.inExpressReportData.records, report.inExpressReportData.fields);
        }
        else {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.NO_RECORD_EXPORT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }


    }


    /** excel */
    /** pdf */
    /* downloadPdf = () =>{
         if(isValidParam(refs.reportTable)){
             let instance =reportTable.getWrappedInstance();
             instance.downloadPdfReport();
         }
         else{
             dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.NO_RECORD_EXPORT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
         }
     } */
    /** */

    const getDate = (date) => {
        let dateValue = isValidParam(date) ? new Date(date) : new Date();
        dateValue = new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate());
        return dateValue;
    }

    const getGreenlightReport = () => {
        let component = null;
        let rowStyle = { borderBottomWidth: '1px', borderBottomStyle: 'solid', fontSize: '15px', padding: '20px', margin: '10px 0px 0px 10px', width: '85%' }
        try {
            let arr = getArrayParam(getLastFiveWorkingDays());
            component = arr.map((item, i) => {
                {
                    return (
                        <div key={"row_" + (i + 1)} style={rowStyle}>
                            <div style={{ display: 'inline-block' }}>{item}</div>
                            <div style={{ display: 'inline-block', marginLeft: '350px' }}><Button key={"btn_" + (i + 1)} onClick={() => { generateGreenlightReport(i) }} style={{ ...styles.primaryButton, minWidth: '40px' }} title={getLocalizedStrings().label.COMMON.GREENLIGHT_EXPORT_DOWNLOAD}>{getLocalizedStrings().label.COMMON.GREENLIGHT_EXPORT_DOWNLOAD} </Button></div>
                        </div>
                    )
                }
            });

        } catch (error) {
            console.log("Error in 'inexpressReport.js -> getGreenlightReport()':" + error);
        }
        return component;
    }

    const getLastFiveWorkingDays = () => {
        let displayDates = getArrayParam(greenlightParams.displayDates);
        return displayDates;
    }

    const addWeekdays = (date, days) => {
        let dateList = [];
        let toDates = [];
        let fromDates = [];
        let dateParams = greenlightParams;
        try {
            date = moment(date);
            let prevDate = moment(date);
            prevDate = prevDate.add(-1, 'days');
            while (days > 0) {
                if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
                    let formattedDate = date.format(dateFormat[app.me.date_format])
                    toDates.push(formattedDate);
                    // decrease "days" only if it's a weekday.
                    days -= 1;
                }
                date = date.add(-1, 'days');
            }

            days = 5;
            while (days > 0) {
                if (prevDate.isoWeekday() !== 6 && prevDate.isoWeekday() !== 7) {
                    let formattedDate = prevDate.format(dateFormat[app.me.date_format])
                    fromDates.push(formattedDate);
                    days -= 1;
                }
                prevDate = prevDate.add(-1, 'days');
            }

            for (let i = 0; i < 5; i++) {
                let fromDate = fromDates[i];
                let toDate = toDates[i];
                let item = fromDate + " 1PM MT " + " - " + toDate + " 1PM MT ";
                dateList.push(item);
            }
            dateParams.fromDates = fromDates;
            dateParams.toDates = toDates;
            dateParams.displayDates = dateList;
            setGreenlightParams({ ...dateParams })
        } catch (error) {
            console.log("Error in 'inexpressReport.js -> addWeekdays()':" + error);
        }

    }

    const generateGreenlightReport = (row) => {
        if (isValidParam(row)) {
            let dateParams = greenlightParams;
            let fromDates = dateParams.fromDates;
            let toDates = dateParams.toDates;
            if (fromDates !== null && toDates !== null && fromDates.length > 0 && toDates.length > 0) {
                if (fromDates[row] !== null && toDates[row] !== null) {
                    let startDate = formatDate(fromDates[row]);
                    let endDate = formatDate(toDates[row]);
                    startDate = startDate + " 13:00:00";
                    endDate = endDate + " 13:00:00";
                    let newDate = new Date();
                    newDate = newDate.getTime().toString();
                    dateParams.fileName = getStringParam("GreenlightReport_" + newDate + ".csv");
                    if (isValidParam(reportParams) && reportParams.hasOwnProperty("report_id")) {
                        let params = {};
                        params.report_id = getIntParam(reportParams.report_id);
                        params.report_type_id = getInXpressReportId(getStringParam(reportParams.item_type));
                        params.hdn_from_date = getStringParam(startDate);
                        params.hdn_to_date = getStringParam(endDate);
                        params.file_name = getStringParam(dateParams.fileName);
                        let promise = getInXpressReports(params);
                        if (isValidParam(promise)) {
                            promise.then((response) => {
                                if (isValidParam(response)) {
                                    if (response.dataList !== null && getStringParam(response.dataList) === "No record found") {
                                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.GREENLIGHT_EXPORT_NO_RECORD, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                    } else if (response.dataList !== null && getArrayParam(response.dataList).length > 0) {
                                        setGreenlightParams({ ...greenlightParams, downloadFileData: response.dataList })
                                        let dataList =response.dataList;
                                        downloadFile(dataList);
                                    }
                                }
                            });
                        }
                    }
                }

            }
        }
    }

    const downloadFile = (dataList) => {
        try {
            let filedataDownload = greenlightParams.downloadFileData !== null ? greenlightParams.downloadFileData : dataList ;
            let fileName = getStringParam(greenlightParams.fileName);
            if (filedataDownload !== null) {
                let csvRows = [];
                for (var i = 0; i < filedataDownload.length; i++) {
                    for (var j = 0; j < filedataDownload[i].length; j++) {
                        if (filedataDownload[i][j].includes('\"')) {
                            filedataDownload[i][j] = filedataDownload[i][j].replace(/"/g, '""');
                        }
                        if (filedataDownload[i][j].includes(',')) {
                            filedataDownload[i][j] = '\"' + filedataDownload[i][j] + '\"';  // Handle elements that contain commas
                        }
                    }
                    csvRows.push(filedataDownload[i].join(','));
                }
                let csvString = csvRows.join('\r\n');
                let blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
            }
        } catch (error) {
            console.log("Error in 'inexpressReport.js -> downloadFile()':" + error);
        }
    }

    let _selectedReport = getSelectedReport();
    let headerComponent = null;
    let reportTitle = null;
    let reportTypeWiseDiv = null;
    let minDateVal = null;
    let buttonSet = getButtonSet(_selectedReport);
    let reportStyle = { height: '100%', overflow: 'hidden', overflowX: 'auto', overflowY: 'auto' };
    let margin = '13px 0px 0px 0px';
    if (_selectedReport !== undefined && _selectedReport.hasOwnProperty("item_type") && (_selectedReport.item_type === 'InXpress Report 20' || _selectedReport.item_type === 'InXpress Report 21')) {
        margin = '7px 0px 0px 0px';
    }
    if (isValidParam(fromDate)) {
        let fromDateVal = new Date(fromDate);
        minDateVal = new Date(fromDateVal.getFullYear(), fromDateVal.getMonth(), fromDateVal.getDate());
    }
    let isShow = false;
    if (selectedReport !== undefined && selectedReport.hasOwnProperty("item_type") && (selectedReport.item_type === 'InXpress Report 20' || selectedReport.item_type === 'InXpress Report 21')) {
        isShow = true;
    }
    let removeFilterOptions = ['ty', 'ly', 'cust', 'blank'];
    if (getStringParam(reportParams.item_type) === 'InXpress Report 16') {
        removeFilterOptions = ['ty', 'ly', 'blank'];
    }
    let data = {};
    data.object = object;
    data.total_count = totalCount;
    data.page_size = fetchSize;
    let listviewData = {};
    listviewData.data = data;
    if (!isMounted) {
        return <Loader />
    }
    else {
        if (isMounted && isValidParam(_selectedReport)) {
            reportTitle = _selectedReport.item_name;
            reportTitle = getLocalizedStrings().label.REPORT.hasOwnProperty(reportTitle) ? getLocalizedStrings().label.REPORT[reportTitle] : reportTitle;
            headerComponent = generateHeaderFields();
            reportTypeWiseDiv = generateReport();
            if (_selectedReport.hasOwnProperty("item_type") && _selectedReport.item_type === 'InXpress Report 19') {
                buttonSet = null;
                reportTitle = null;
                reportStyle.overflowX = 'hidden';
                reportStyle.overflowY = 'hidden';
            } else if (_selectedReport.hasOwnProperty("item_type") && (_selectedReport.item_type === 'InXpress Report 20' || _selectedReport.item_type === 'InXpress Report 21')) {
                reportTitle = "";
            }
        }
        return (
            <div style={{ width: '97%', marginLeft: 20 }}>
                <div className="row" style={{}} >
                    <div className="col-md-12" style={{ display: 'flex', marginLeft: '-5px' }}>
                        {isTimelineShow && <div style={{ margin: '0px 8px 0px 0px' }} >
                            <SFReportDatePicker
                                label={getLocalizedStrings().label.REPORT.DATE_RANGE}
                                onChange={(event, value) => handleDropDownChange('due_by', event, value)}
                                type={"dropdown"}
                                list={getDateOptions(removeFilterOptions)}
                                defaultValue={reportParams.due_by}
                                fromDateOptions={{ maxDate: new Date() }}
                                toDateOptions={{ minDate: minDateVal }}
                                profile={app.me}
                                fromDate={reportParams.from_date}
                                toDate={reportParams.to_date}
                            //    style={{ ...styles.sfFormFieldContainer, minWidth: 172, cursor: "pointer", fontSize: '14px', color: '#717171', height: '47px', paddingLeft: 7, width: '100%', paddingRight: 7 }}
                            />
                        </div>}
                        {headerComponent}
                        {!isShow &&
                            <div className="col-md-3" style={{ margin: margin, textAlign: 'left' }}>{buttonSet}</div>
                        }
                        {isShow &&
                            <div style={{ margin: margin, width: '100%' }}>{buttonSet}</div>
                        }
                    </div>
                </div>
                <div className="row" id="divcontents" style={{ marginLeft: 0, height: '50%' }}>
                    <div className="col-md-12" style={{ paddingLeft: 7 }}>
                        {isValidParam(reportTitle) && <div style={{ padding: '0px 0px' }}>
                            <span className="text">
                                <h3>{reportTitle}</h3>
                            </span>
                        </div>}
                        <div>
                            <div id='data-table-container' style={{ ...reportStyle }} /* onScroll={handleScroll} */ >{isValidParam(reportTypeWiseDiv) ? reportTypeWiseDiv : getNoRecordContent()}</div>
                        </div>
                        {totalCount > 0 && _selectedReport !== undefined && _selectedReport.hasOwnProperty("item_type") && _selectedReport.item_type === "InXpress Report 20" &&
                            <div id="row_more" style={{ marginTop: '1%' }}>
                                <Pagination
                                    totalRecordCount={totalCount}
                                    object={object}
                                    listView={listviewData}
                                    isLookup={false}
                                    listViewCallBack={getPaginationData}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const _toDate = (dateStr) => {
    let parts = dateStr.split("-");
    let date = new Date(parts[0], parts[1] - 1, parts[2]);
    return date;
}

const Loader = () => {
    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight - 10) / 2;
    return <div style={{ width: '100%', height: pageHeight }}>
        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
            </div>
        </div>
    </div>
}

export default InexpressReport;