import React from 'react';
import {Link} from 'react-router-dom';
import PopOver from '../components/PopOver';
import {List} from '@mui/material';
import ListView from './ListView';
import {styles} from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import {setHeader} from '../../../services/actions/headerActions';
import {constants, EVENT_TYPES, EVENT_TYPE_KEYS} from '../../../services/constants/constants';
import {isValidParam, getObjectParam, getStringParam, getIntParam, getArrayParam} from '../../../services/helper/parameterVerifier';
import * as HTTPClient from '../../../services/helper/httpClient';
import {endPoints} from '../../../services/constants/endPoints';
import {getActiveTab, updateActiveTab, updateActiveTabProps} from '../../../services/helper/sfTabManager';
import {getListViewData} from '../../../services/actions/listViewAcions';
import LinkClickInfo from '../components/linkClickInfo';
import {getLocalizedStrings} from '../../../services/constants/MultiLingual';
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";


const MarketingReport = () => {

	const [isMounted, setIsMounted] = useState(false);
	const [menuItems, setMenuItems] = useState([]);
	const [selectedMsgName, setSelectedMsgName] = useState("");
	const [isMsgListOpen, setIsMsgListOpen] = useState(false);
	const [msgName, setMsgName] = useState("");
	const [marketingResults, setMarketingResults] = useState([]);
	const [eventType, setEventType] = useState("");
	const [reportType, setReportType] = useState("");
	const [eventTypeKey, setEventTypeKey] = useState('');
	const [msgNameList, setMsgNameList] = useState(null);
	const [statId, setStatId] = useState(0);
	const [isShowListView, setIsShowListView] = useState(false);

	const dispatch = useDispatch();

	const app = useSelector((state) => state.app);
	const marketingResultsData = useSelector((state) => state.marketingReportResults);
	const listView = useSelector((state) => state.listView);


	useEffect(() => {
		dispatch(setHeader(constants.CONTACTS_OBJECT, getLocalizedStrings().label.HEADER.REPORTS, null, false, true));

		let activeTab = getObjectParam(getActiveTab());
		if (isValidParam(activeTab)) {
				let info = activeTab.info;
				if (isValidParam(info)) {
					generateReportCountColumn(info);
				}
			
		}
	}, []);


	function generateReportCountColumn(info) {
		try {
			let marketing_result = marketingResultsData.marketingResults;
			if (!marketing_result || marketing_result.length === 0) {
				let report_Type = getStringParam(info.reportType);
				let campaignId = getStringParam(info.campaignId);
				let url = null;

				if (report_Type === constants.AUTO_RESPONDER_REPORT_TYPE) {
					report_Type = constants.AUTO_RESPONDER_REPORT;
					url = endPoints.TOUCH_CAMPAIGNS.RESULTS;
				}
				if (report_Type === constants.BROADCAST_REPORT_TYPE) {
					report_Type = constants.BROADCAST_REPORT;
					url = endPoints.BROADCAST_CAMPAIGNS.RESULTS;
				}
				if (report_Type === constants.AB_REPORT_TYPE) {
					report_Type = constants.AB_REPORT;
					url = endPoints.AB_CAMPAIGNS.RESULTS;
				}
				if (report_Type === constants.BIRTHDAY_REPORT_TYPE) {
					report_Type = constants.BROADCAST_REPORT;
					url = endPoints.BROADCAST_CAMPAIGNS.RESULTS;
				}

				let params = {
					campaign_id: campaignId,
					report_type: report_Type,
				}
				const promise = Promise.resolve(HTTPClient.get(url, params));
				promise.then((response) => {
					 
					if (isValidParam(response)) {
						setMarketingResults(getArrayParam(response['marketing_results']))
						populateReportCountColum(info, response['marketing_results']);
					}
				});
			} else {
				setMarketingResults(marketing_result);
				populateReportCountColum(info, marketing_result);
			}
		} catch (error) {
			console.error("Error in 'MarketingReport.js -> generateReportCountColumn()':" + error);
		}
	}

	function populateReportCountColum(info, marketingResult) {
		let stat_Id = null;
		let event_Type = null;
		let report_Type = '';
		let _eventTypeKey = null;
		if (info.input_param) {
			let inputParam = info.input_param;
			stat_Id = inputParam.stat_id;
			event_Type = inputParam.event_type;
		}

		if (info.reportType) {
			report_Type = info.reportType;
		}

		if (info.event_type_key) {
			_eventTypeKey = info.event_type_key;
		}

		let msgObject = null;
		let msgValue;
		if (isValidParam(marketingResult)) {
			msgObject = marketingResult.filter(el => el.id === stat_Id);
		}

		if (report_Type === constants.AUTO_RESPONDER_REPORT_TYPE) {
			if (msgObject && msgObject.length >=0 && msgObject[0].msg_name) {
				msgValue = msgObject[0].msg_name;
			}
		}

		if (msgObject && msgObject.length >=0 && report_Type === constants.AB_REPORT_TYPE) {
			if (msgObject[0]!= undefined &&  msgObject[0].t_msg_name) {
				msgValue = msgObject[0].t_msg_name;
			}
		}
		if (msgObject && msgObject.length >=0 && report_Type === constants.BROADCAST_REPORT_TYPE) {
			if (msgObject[0]!= undefined && msgObject[0].campaign_name) {
				msgValue = msgObject[0].campaign_name;
			}
		}
		let menuItems = generateMenuItems(msgObject[0], _eventTypeKey, marketingResult);
		let _msgNameList = null;
		if (report_Type !== constants.BROADCAST_REPORT_TYPE && report_Type !== constants.BIRTHDAY_REPORT_TYPE) {
			_msgNameList = generateMsgList(marketingResult, report_Type);
		}


		if ( isValidParam(info['event_type_key']) && info['event_type_key'] === "clicked") {
			setReportHeaderForClick(msgObject[0], _eventTypeKey);
		}

		setSelectedMsgName(msgValue)
		setMenuItems(menuItems);
		setEventType(event_Type);
		setReportType(report_Type);
		setEventTypeKey(_eventTypeKey);
		setMsgNameList(_msgNameList);
		setStatId(stat_Id);
		setIsMounted(true);
	}

	function generateFilterNameAndCount(filterName, count) {
		let filterAndCount = null;
		try {
			filterAndCount = (
				<div style={{float: 'right', fontSize: '24px', position: 'relative', marginLeft: '4px', marginTop: '-3px'}}>
					{filterName}
					<span style={{fontSize: '14px', display: 'inline-block', marginLeft: '2px'}}>{' (' + count + ')'}</span>
				</div>
			);
		} catch (error) {
			console.error("Error in 'listView.js -> getFilterNameWithCount()':" + error);
		}
		return filterAndCount;
	}

	function generateMenuItems(msgObject, _eventTypeKey, marketingResult) {
		let menuItems = [];
		let themeColor = app.me.background;
		try {
			if (isValidParam(msgObject)) {
				for (let key in msgObject) {
					if (key !== 'campaign_name' && key !== 'created_on' && key !== 'dbp' && key !== 'id' && key !== 'msg_name' &&
						key !== 'sequence' && key !== 'campaign_name' && key !== 'subject' && key !== 't_campaign_name' &&
						key !== 't_msg_name' && key !== 'campaign_id' && key !== 'project_id') {
						let style = key.toUpperCase() === _eventTypeKey.toUpperCase() ? {color: themeColor, fontWeight: 'bold'} : {color: '#717171'};
						let count = msgObject[key];
						if (getIntParam(count) > 0) {
							let keyValue = key.charAt(0).toUpperCase() + key.slice(1);
							let keyValueForLabel = keyValue.toUpperCase();
							keyValueForLabel = getLocalizedStrings().label.CAMPAIGNS.hasOwnProperty(keyValueForLabel) ? getLocalizedStrings().label.CAMPAIGNS[keyValueForLabel] : keyValue;

							let label = keyValueForLabel + ' (' + count + ')';
							let constEvnType = EVENT_TYPES[key];
							menuItems.push(
								<div style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} key={key}>
									<Link
										to={'#'}
										style={{cursor: 'pointer', ...style}}
										onClick={() => changeListView(keyValue, constEvnType, count, marketingResult)}>
										<span style={{fontSize: '14px', lineHeight: '32px'}} title={label}>{label}</span>
									</Link>
								</div>
							);
						}
					}
				}
			}
		} catch (error) {
			console.error("Error in 'MarketingReport.js -> generateMenuItems()':" + error);
		}
		return menuItems;
	}


	function generateMsgList(marketingResult, _reportType) {
		let msgNameList = [];
		try {
			if (marketingResult && marketingResult.length > 0) {
				msgNameList = marketingResult.map((marketingObject, i) => {
					let _msgName = null;
					if (_reportType === constants.AUTO_RESPONDER_REPORT_TYPE) {
						_msgName = marketingObject.msg_name;
						let msg_name_split = _msgName.split("-");
						if (msg_name_split.length === 2 && msg_name_split[0] === 'Message') {
							_msgName = getLocalizedStrings().label.AUTOMATION_DESIGNER.MESSAGE + "-" + msg_name_split[1];
						}
					}
					if (_reportType === constants.AB_REPORT_TYPE) {
						let defaultMessageNameMultiLingual = {
							'Message A': getLocalizedStrings().label.AB_CAMPAIGNS.MESSAGE_A,
							'Message B': getLocalizedStrings().label.AB_CAMPAIGNS.MESSAGE_B,
							'Winner (based on Message A)': getLocalizedStrings().label.AB_CAMPAIGNS.WINNER_BASEDON_A,
							'Winner (based on Message B)': getLocalizedStrings().label.AB_CAMPAIGNS.WINNER_BASEDON_B,
						};
						_msgName = isValidParam(defaultMessageNameMultiLingual[marketingObject.t_msg_name]) ? defaultMessageNameMultiLingual[marketingObject.t_msg_name] : marketingObject.t_msg_name;
					}
					return {
						label: _msgName,
						value: marketingObject.id
					}
				});

			}
		} catch (error) {
			console.error("Error in 'MarketingReport.js -> generateMsgList()':" + error);
		}
		return msgNameList;
	}

	function changeMsgName(value) {
		try {
			let marketingResult;
			if (isValidParam(marketingResults)) {
				let filteredResults = marketingResults.filter((el) => el.id === value && (reportType === constants.AUTO_RESPONDER_REPORT_TYPE || reportType === constants.AB_REPORT_TYPE));
				if (filteredResults && filteredResults.length > 0) {
					marketingResult = filteredResults[0];
				}
			}
			if (reportType === constants.AUTO_RESPONDER_REPORT_TYPE && marketingResult) {
				if (marketingResult.hasOwnProperty('msg_name')) {
					setSelectedMsgName(marketingResult.msg_name);
				}
			}
			if (reportType === constants.AB_REPORT_TYPE && marketingResult) {
				if (marketingResult.hasOwnProperty('t_msg_name')) {
					setSelectedMsgName(marketingResult.t_msg_name);
				}
			}
			let menuItems = generateMenuItems(marketingResult, eventTypeKey, marketingResults);

			// start Handle for menu items which is not present in either Messages -- Lalu
			let defaultGroupType = eventTypeKey;
			let isEventTypeExist = menuItems.findIndex(item => item.key === eventTypeKey) > -1;
			if (!isEventTypeExist) {
				defaultGroupType = menuItems[0].key;
				menuItems = generateMenuItems(marketingResult, defaultGroupType, marketingResult);
			}

			let activeTab = getObjectParam(getActiveTab());
			let keyValue = EVENT_TYPES[defaultGroupType];
			let obj = getObjectParam(marketingResult);
			activeTab.info.reportFieldValue = keyValue;
			activeTab.info.recordCount = obj[keyValue.toLowerCase()];
			activeTab.info.event_type_key = EVENT_TYPE_KEYS[keyValue];
			updateActiveTab(activeTab);
			// End Handle for menu items which is not present in either Messages -- Lalu

			refreshListView(marketingResult, keyValue);
			setMenuItems(menuItems);
			setIsMsgListOpen(false);
			setStatId(value);
			setEventType(defaultGroupType);
			setEventTypeKey(keyValue);

		} catch (error) {
			console.error("Error in 'MarketingReport.js -> changeMsgName()':" + error);
		}
	}

	function refreshListView(msgObject, _eventType) {
		try {
			let tab = getObjectParam(getActiveTab());
			let filter = getObjectParam(tab.info.filter);
			let params = {};
			params.query_id = filter.id;
			params.query_name = '';
			params.query_type = filter.type;
			params.stat_id = msgObject.id;
			params.event_type = _eventType;
			params.isReport = true;

			let info = getObjectParam(tab.info);
			let _eventTypeKey = EVENT_TYPE_KEYS[eventType];
			info.event_type_key = _eventTypeKey;
			info.input_param.stat_id = msgObject.id;
			info.input_param.event_type = _eventType

			if (info['event_type_key'] !== "clicked") {
				delete info.input_param.url;
			}
			delete info.startIndex;
			delete info.pageSize;
			delete info.selectedPageNo;
			delete info.startPageNo;

			let label = null;
			if (_eventType === 'click') {
				let eventTypeText = getLocalizedStrings().label.CAMPAIGNS.CLICK_URL;
				label = eventTypeText + " " + selectedMsgName;
			} else {
				let eventTypeText = _eventType.charAt(0).toUpperCase() + _eventType.slice(1);
				let eventTypeTextUpper = eventTypeText.toUpperCase();
				eventTypeText = getLocalizedStrings().label.CAMPAIGNS.hasOwnProperty(eventTypeTextUpper) ? getLocalizedStrings().label.CAMPAIGNS[eventTypeTextUpper] : eventTypeText;
				if (info.reportType === constants.BROADCAST_REPORT_TYPE) {
					label = eventTypeText + " " + getLocalizedStrings().label.BROADCAST_CAMPAIGNS.EMAIL_CAMPAIGN_REPORT;
				} else if (info.reportType === constants.AUTO_RESPONDER_REPORT_TYPE) {
					label = eventTypeText + " " + getLocalizedStrings().label.AUTOMATION_DESIGNER.TOUCH_CAMPAIGN_REPORT;
				} else if (info.reportType === constants.AB_REPORT_TYPE) {
					label = eventTypeText + " " + getLocalizedStrings().label.AB_CAMPAIGNS.AB_CAMPAIGN_REPORT;
				} else if (info.reportType === constants.BIRTHDAY_REPORT_TYPE) {
					label = eventTypeText + " " + getLocalizedStrings().label.AUTOMATION_DESIGNER.BIRTHDAY_CAMPAIGN_REPORT;
				}
			}
			updateActiveTabProps({info, label});
			if (info['event_type_key'] === "clicked") {
				setReportHeaderForClick(msgObject, _eventTypeKey);
				let clickCount = msgObject[_eventTypeKey];
				if (clickCount > 0) {
					setIsShowListView(false);
				} else {
					setIsShowListView(true);
					dispatch(getListViewData(constants.CONTACTS_OBJECT, params));
				}
				// getMarketingResults(info);
			} else {
				setIsShowListView(true);
				dispatch(getListViewData(constants.CONTACTS_OBJECT, params));
				// this.props.getMarketingResults(info);
			}
		} catch (error) {
			console.error("Error in 'MarketingReport.js -> refreshListView()':" + error);
		}
	}

	function setReportHeaderForClick(msgObject, _eventTypeKey) {
		try {
			let keyValue = _eventTypeKey;
			let labelHeader = (!isValidParam(msgObject['campaign_name']) ? msgObject['t_campaign_name'] : msgObject['campaign_name']) + ' - ' + keyValue.charAt(0).toUpperCase() + keyValue.slice(1);
			let kanbanObject = {};
			kanbanObject.currentView = '';
			kanbanObject.show = false;
			let filterAndCount = generateFilterNameAndCount(labelHeader, msgObject[keyValue]);
			dispatch(setHeader(constants.CONTACTS_OBJECT, filterAndCount, kanbanObject, false, true));
		} catch (error) {
			console.error("Error in 'MarketingReport.js -> setReportHeaderForClick()':" + error);
		}
	}

	function changeListView(keyValue, constEvnType, count, marketingResult) {
		let activeTab = getObjectParam(getActiveTab());

		let info = getObjectParam(activeTab.info);
		let report_Type = info.reportType;
		// check statId
		let stat_Id = info.input_param.stat_id;
		let msgObject = marketingResult.filter((el) => {
			if (report_Type === constants.AUTO_RESPONDER_REPORT_TYPE) {
				return el.id === stat_Id;
			} else if (report_Type === constants.AB_REPORT_TYPE) {
				return el.id === stat_Id;
			} else if (report_Type === "BroadcastReport") {
				return el.id === stat_Id;
			} else if (report_Type === constants.BIRTHDAY_REPORT_TYPE) {
				return el.id === stat_Id;
			} else {
				return false;
			}
		});

		if (constEvnType === 'click') {
			activeTab.info.input_param.event_type = constEvnType;
		} else {
			if (keyValue === 'Opened') {
				activeTab.info.input_param.event_type = 'Open';
			} else if (keyValue === 'Bounced') {
				activeTab.info.input_param.event_type = 'Bounce';
			} else if (keyValue === 'Spam') {
				activeTab.info.input_param.event_type = 'spamreport';
			} else if (keyValue === 'Dropped') {
				activeTab.info.input_param.event_type = 'dropped';
			} else {
				activeTab.info.input_param.event_type = keyValue;
			}
		}
		activeTab.info.reportFieldValue = keyValue;
		activeTab.info.recordCount = count;
		updateActiveTab(activeTab);

		if (isValidParam(listView.data) && listView.data.hasOwnProperty('statKey')) {
			delete listView.data.statKey;
		}
		refreshListView(msgObject[0], constEvnType);

		let menuItems = generateMenuItems(msgObject[0], keyValue,marketingResult);
		keyValue = EVENT_TYPE_KEYS[constEvnType];
		keyValue = keyValue.toLowerCase();
		setMenuItems(menuItems);
		setEventType(constEvnType);
		setEventTypeKey(keyValue);
	}

	let tabInfo = getActiveTab().info;
	let _eventTypeKey = isMounted ? getStringParam(tabInfo.event_type_key) : '';

	let listViewWidth = '100%';
	if (menuItems.length > 0) {
		listViewWidth = '89%';
	}

	let styleIntermediatePage;
	if (reportType !== constants.BROADCAST_REPORT_TYPE) {
		styleIntermediatePage = {paddingTop: '5px'};
	} else {
		styleIntermediatePage = {paddingTop: '0px', marginTop: '-16px'};
	}

	let selectedMsgMultilingual = selectedMsgName;
	if (reportType === constants.AUTO_RESPONDER_REPORT_TYPE) {
		let msg_name_split = selectedMsgName.split("-");
		if (msg_name_split.length === 2 && msg_name_split[0] === 'Message') {
			selectedMsgMultilingual = getLocalizedStrings().label.AUTOMATION_DESIGNER.MESSAGE + "-" + msg_name_split[1];
		}
	} else if (reportType === constants.AB_REPORT_TYPE) {
		let defaultMessageNameMultiLingual = {
			'Message A': getLocalizedStrings().label.AB_CAMPAIGNS.MESSAGE_A,
			'Message B': getLocalizedStrings().label.AB_CAMPAIGNS.MESSAGE_B,
			'Winner (based on Message A)': getLocalizedStrings().label.AB_CAMPAIGNS.WINNER_BASEDON_A,
			'Winner (based on Message B)': getLocalizedStrings().label.AB_CAMPAIGNS.WINNER_BASEDON_B,
		};
		selectedMsgMultilingual = isValidParam(defaultMessageNameMultiLingual[selectedMsgName]) ? defaultMessageNameMultiLingual[selectedMsgName] : selectedMsgName;
	}
	return (
		<>
			<div style={{paddingLeft: '26px', paddingRight: '0px', marginTop: '0px', width: '98%'}}>
				<div className="row">
					{
						menuItems.length > 0 &&
						(
							<div className="col-md-1" style={{marginTop: '-10px', paddingRight: '0px', width: '11%'}}>
								{
									(msgNameList && msgNameList.length > 0) &&
									(
										<div>
											<div style={{maxWidth: '70%', float: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', cursor: "pointer", fontSize: '16px', fontWeight: 'bold'}}
												//onClick={this.handleMsgListOpen}
												 title={selectedMsgMultilingual}>
												{selectedMsgMultilingual}
											</div>
											<PopOver
												id={'marketing-report-msgnamelist'}
												key={'marketing-report-msgnamelist'}
												name={'marketing-report-msgnamelist'}
												buttonEndIcon={"keyboard_arrow_down"}
												buttonStyle={{fontWeight: 'bold', paddingLeft: '2px', paddingTop: '5px'}}
												labelposition={"before"}
												buttonLabel={""}
												options={msgNameList}
												onclickAction={(value) => changeMsgName(value)}
												isShowIcon={true}
												// anchorEl={anchorEl}
												anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
												targetOrigin={{horizontal: 'left', vertical: 'top'}}
											/>
										</div>
									)
								}
								<div>
									<List> {menuItems} </List>
								</div>
							</div>
						)
					}
					{!isMounted && (
						<div className='col-md-11' style={{width: listViewWidth}}>
							<div className="asset-loaderh" style={{paddingLeft: '48%'}}>
								<div style={{...styles.assetLoaderContainer, height: 40, width: 40, padding: 7}}>
									<ShowCircularProgress size={20} style={{marginTop: '3', marginLeft: '3'}}/>
								</div>
							</div>
						</div>
					)}
					{isMounted && (
						<div className='col-md-11' style={{width: listViewWidth}}>
							{_eventTypeKey !== 'clicked' && <ListView object={constants.CONTACTS_OBJECT} isReport={true}/>}
							{_eventTypeKey === 'clicked' && <div style={styleIntermediatePage}>
								<LinkClickInfo selectedMsg={selectedMsgName}/>
							</div>}
						</div>
					)}
				</div>
			</div>
		</  >
	);

}

export default MarketingReport;
