import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import $ from 'jquery';
import ListViewHeader from '../components/listViewHeader';
import { getListViewData, setListViewData, getListViewSelectedRecord, setMounted, groupByShowHide, populateFiltersFromActiveTab } from '../../../services/actions/listViewAcions';
import { setTreeViewSelectedRecord } from '../../../services/actions/treeViewAcions';
import { setHeader } from '../../../services/actions/headerActions';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { isValidParam, getStringParam, getBooleanParam, getIntParam, getObjectParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { getActiveTab, getActiveTabInfo, updateTabInfo, addTab, TYPE_DETAIL_VIEW } from '../../../services/helper/sfTabManager';
import GroupByFieldComponent from '../components/groupByFieldComponent';
import { makeTiteleCase, getObjectSpecificEndpoint } from '../../../services/helper/utils';
import ListViewFilterDropDown from '../components/listViewFilterDropDown';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { hasAccessPermission } from '../../../services/helper/common';
import { getAppDrawer, getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import { getSelectedObject } from '../../../services/helper/common';
import { getQueryDetails } from '../../../services/actions/appActions';
import { getListViewSetupData } from '../../../services/actions/listViewAcions';
import { TreeGridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Toolbar, Resize } from '@syncfusion/ej2-react-treegrid';
import { DataManager, WebApiAdaptor} from '@syncfusion/ej2-data';
import { LinkToType } from '../../../services/constants/link';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { saveListViewLayoutRowWidth } from '../../../services/actions/listViewAcions';
import { getCallDrawer } from '../../../services/actions/callAction';
import * as sfDialogs from '../components/sfDialogs';

const getListViewInstanceFromRedux = (state, props) => {
    let tabInfo = getActiveTabInfo();
    let isReport = getBooleanParam(tabInfo.isReport);
    let isAssignRule = getBooleanParam(tabInfo.isAssignRule);
    if (getBooleanParam(props.isDetailView)) {
        return state.childListView;
    } else if (getBooleanParam(isReport)) {
        return state.otherListView;
    } else if (getBooleanParam(isAssignRule)) {
        return state.assignListView;
    } else if (getBooleanParam(props.isDuplicate)) {
        return state.duplicateListView;
    }
    else {
        return state.listView;
    }
}

const mapStateToProps = (state, props) => {
    return {
        app: state.app,
        tab: state.tab,
        listView: getListViewInstanceFromRedux(state, props),
        treeView: state.treeView,
        sfForm: state.sfForm

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getListViewData: (object, params) => {
            dispatch(getListViewData(object, params));
        },
        setHeader: (selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader) => {
            dispatch(setHeader(selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader));
        },
        setListViewData: (data) => {
            dispatch(setListViewData(data));
        },
        setMounted: (isMounted, isDetailView, isReport, isAssignRule, isDuplicate) => {
            dispatch(setMounted(isMounted, isDetailView, isReport, isAssignRule, isDuplicate));
        },
        getListViewSelectedRecord: (selectedRows, isDetailView, isExpandedTaskList) => {
            dispatch(getListViewSelectedRecord(selectedRows, isDetailView, isExpandedTaskList));
        },
        groupByShowHide: () => {
            dispatch(groupByShowHide());
        },
        getAppDrawer: (isOpen, actionDialogName, actionType, data, callFrom) => {
            dispatch(getAppDrawer(isOpen, actionDialogName, actionType, data, callFrom));
        },
        setTreeViewSelectedRecord: (selectedRows) => {
            dispatch(setTreeViewSelectedRecord(selectedRows));
        },
        getAppCustomDrawer: (isOpen, dialogName, actionType, style, data, headerProps, minimizable) => {
            dispatch(getAppCustomDrawer(isOpen, dialogName, actionType, style, data, headerProps, minimizable));
        },getCallDrawer: (isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom) => {
            dispatch(getCallDrawer(isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom));
        }
    }
};


class TreeView extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            detailViewParams: {
                parent_object: '',
                parent_record_id: ''
            },
            queryId: 0,
            queryName: null,
            queryType: null,
            isGlobalSearch: false,
            last_run_query_id: -1,
            lastRunQueryType: '',
            lastQueryName: '',
            noRecordFilterText: '',

        }
    }

    componentWillReceiveProps(nextProps) {
        try {
            let isLookup = getBooleanParam(nextProps.isLookup);

            if (!isLookup) {
                let object = getStringParam(this.props.object);
                let nextPropsObject = getStringParam(nextProps.object);
                let parentObject = getStringParam(nextProps.parentObject);
                let isDetailView = getBooleanParam(nextProps.isDetailView);
                let parentRecordId = getIntParam(nextProps.parentRecordId);
                let params = populateFiltersFromActiveTab();
                let isSearch = getBooleanParam(nextProps.isSearch);
                let searchParams = getObjectParam(nextProps.searchParams);
                let childQueryParams = getObjectParam(nextProps.childQueryParams);
                let isFetchListView = false;

                if (isValidParam(params)) {
                    this.state.queryId = getIntParam(params.query_id);
                    this.state.queryName = getStringParam(params.query_name);
                    this.state.queryType = getStringParam(params.query_type);
                }
            

                if (parentObject === constants.RECYCLE_BIN_OBJECT) {
                    parentObject = constants.ACCOUNTS_OBJECT;
                }
                if (isDetailView) {
                    if (nextPropsObject === constants.ACCOUNTS_OBJECT && parentObject === constants.ACCOUNTS_OBJECT) {
                        params = {
                            parent_object: parentObject,
                            parent_record_id: parentRecordId,
                            relationTableId: 10,

                        }
                    } else {
                        params = {
                            parent_object: parentObject,
                            parent_record_id: parentRecordId,
                            query_id: getIntParam(childQueryParams.query_id),
                            query_name: getStringParam(childQueryParams.query_name),
                            query_type: getStringParam(childQueryParams.query_type),
                        }
                    }
                    this.state.detailViewParams = params;
                }

                if (isSearch) {
                    params = searchParams;
                    this.state.isGlobalSearch = true;
                }

                isFetchListView = this.isNewFilter(params, nextPropsObject, nextProps);

                // obvject !== BROADCAST_CAMPAIGNS_OBJECT -- Stop to send request for broadcast Listview  , need contacfs listView Data
            }

        } catch (error) {
            console.error("Error in 'listView.js -> componentWillReceiveProps()':" + error);
        }
    }

    isNewFilter = (params, nextPropsObject, nextProps) => {
        let isValid = false;
        let object = null;
        try {

            let tab = getActiveTab();
            tab = getObjectParam(tab);
            object = getStringParam(tab.object);
            if (isValidParam(params)) {
                params = getObjectParam(params);
                let queryId = getIntParam(params.query_id);
                let queryType = getStringParam(params.query_type);
                let queryName = getStringParam(params.query_name);

                let stateQueryId = this.state.last_run_query_id;
                let stateQueryType = this.state.lastRunQueryType;
                let stateQueryName = this.state.lastQueryName;

                if (stateQueryType === '' && stateQueryId === -1 && stateQueryName === '') {
                    isValid = true;
                } else if (object === nextPropsObject) {
                    if (stateQueryType !== queryType) {
                        isValid = true;
                    } else if (stateQueryType === queryType) {
                        if (stateQueryName !== queryName) {
                            isValid = true;
                        } else if (stateQueryName === queryName) {
                            if (stateQueryId !== queryId) {
                                isValid = true;
                            } else if (isValidParam(nextProps) && nextProps.isDetailView) {
                                if (nextProps.parentRecordId !== this.props.parentRecordId) {
                                    isValid = true;
                                }
                            }
                        }
                    }
                } else if (isValidParam(nextProps) && nextProps.isDetailView) {
                    if (nextProps.parentRecordId !== this.props.parentRecordId) {
                        isValid = true;
                    }
                }

                if (getBooleanParam(tab.info.isFetchListView)) {
                    isValid = true;
                    delete tab.info.isFetchListView;
                    updateTabInfo(tab.info);
                }

                if (isValid) {
                    this.state.last_run_query_id = queryId;
                    this.state.lastRunQueryType = queryType
                    this.state.lastQueryName = queryName;
                }
            }

        } catch (error) {
            console.error("Error in 'listView.js -> isNewFilter()':" + error);
        }
        return isValid;
    }

    componentDidMount() {
        let isLookup = getBooleanParam(this.props.isLookup);
        if (!isLookup) {
            let object = getStringParam(this.props.object);
            let parentObject = getStringParam(this.props.parentObject);
            let isDetailView = getBooleanParam(this.props.isDetailView);
            let parentRecordId = getIntParam(this.props.parentRecordId);
            let childQueryParams = getObjectParam(this.props.childQueryParams);

            let isSearch = getBooleanParam(this.props.isSearch);
            let searchParams = getObjectParam(this.props.searchParams);

            let params = populateFiltersFromActiveTab();

            if (isValidParam(params)) {
                this.state.queryId = getIntParam(params.query_id);
                this.state.queryName = getStringParam(params.query_name);
                this.state.queryType = getStringParam(params.query_type);
            }
            if (parentObject === constants.RECYCLE_BIN_OBJECT) {
                parentObject = constants.ACCOUNTS_OBJECT;
            }
            if (isDetailView) {
                if (object === constants.ACCOUNTS_OBJECT && parentObject === constants.ACCOUNTS_OBJECT) {
                    params = {
                        parent_object: parentObject,
                        parent_record_id: parentRecordId,
                        relationTableId: 10
                    }
                } else {
                    params = {
                        parent_object: parentObject,
                        parent_record_id: parentRecordId,
                        query_id: getIntParam(childQueryParams.query_id),
                        query_name: getStringParam(childQueryParams.query_name),
                        query_type: getStringParam(childQueryParams.query_type),
                    }
                }
                this.state.detailViewParams = params;
            }

            if (isSearch) {
                params = { ...searchParams };
            }

            this.listViewCallBack(object, params); 
        }

    }


    listViewCallBack = (object, params, isRefresh) => {
        isRefresh = getBooleanParam(isRefresh);
        let isDetailView = getBooleanParam(this.props.isDetailView);
        let isLookup = getBooleanParam(this.props.isLookup);
        if (isLookup) {
            this.state.noRecordFilterText = getStringParam(params.search_text);
            this.props.getLookupListViewData(object, params);
        } else {
            if (isRefresh || isDetailView) {
                this.props.setMounted(false, isDetailView);
            }

            let activeTab = getActiveTab();
            activeTab = getObjectParam(activeTab);
            let info = getObjectParam(activeTab.info);
            let startIndex = getIntParam(info.startIndex);
            let pageSize = getIntParam(info.pageSize);
            let groupByFieldName = getStringParam(info.groupByFieldName);
            let groupByCondition = getStringParam(info.groupByCondition);
            let searchText = getStringParam(info.searchText);
            let searchFieldName = getStringParam(info.searchFieldName);
            let inputParam = getObjectParam(info.input_param);
            let isReport = getBooleanParam(info.isReport);
            let isSearchWithinList = getBooleanParam(info.isSearchWithinList);
            let isAssignRule = getBooleanParam(info.isAssignRule);
            let statusfieldName = getStringParam(info.statusfieldName);

            params = getObjectParam(params);
            if (groupByFieldName === '' && (object === constants.SALES_TEMPLATE_OBJECT || object === constants.SMS_TEMPLATE_OBJECT || object === constants.CALL_SCRIPT_OBJECT || object === constants.AGREEMENT_TEMPLATE_OBJECT)) {
                groupByCondition = 'Active';
                info.groupByCondition = 'Active';
                updateTabInfo(info);
            }else {
                if (groupByCondition === 'Active') {
                    groupByCondition = '';
                    info.groupByCondition = '';
                    updateTabInfo(info);
                }
            }

            if (groupByFieldName !== '') {
                params.group_by_field_name = groupByFieldName;
            }

            if (groupByCondition !== '') {
                params.group_by_condition = groupByCondition;
            }

            if (searchFieldName !== '') {
                params.search_field_name = searchFieldName;
                params.search_type = constants.SEARCH_TYPE_ANYWHERE;
            }

            if (searchText !== '') {
                params.search_text = searchText;
            }
            if (this.props.object === constants.WORKFLOW_OBJECT && statusfieldName !== '') {
                params.status_field_name = statusfieldName;
            }

            if (activeTab.object === constants.SEARCH_RESULTS || activeTab.object === constants.ADVANCE_SEARCH_RESULTS) {
                params.query_id = 0;
                params.query_name = constants.SEARCH_QUERY_NAME;
                if (activeTab.object === constants.SEARCH_RESULTS) {
                    params.query_type = constants.SEARCH_QUERY_TYPE_GLOBAL;
                    let search = getObjectParam(info.search);
                    params.search_text = getStringParam(search.searchText);
                } else if (activeTab.object === constants.ADVANCE_SEARCH_RESULTS) {
                    params.query_type = constants.SEARCH_QUERY_TYPE_ADVANCE;
                    params.search_type = constants.SEARCH_TYPE_START_WITH;
                    let advanceSearch = getObjectParam(info.advanceSearch);
                    let searchParams = getObjectParam(advanceSearch.searchParams);
                    params.search = getArrayParam(searchParams.search);
                }
            } else if (isReport) {
                let filterObj = getObjectParam(activeTab.info.filter);
                let queryId = getIntParam(filterObj.id);
                let queryName = getStringParam(filterObj.name);
                let queryType = getStringParam(filterObj.type);

                params.query_id = queryId;
                params.query_name = queryName;
                params.query_type = queryType;
            } else {
                let filterObj = getObjectParam(activeTab.info.filter);
                let queryId = getIntParam(filterObj.id);
                let queryName = getStringParam(filterObj.name);
                let queryType = getStringParam(filterObj.type);

                if (!params.hasOwnProperty('query_id')) {
                    params.query_id = queryId;
                }

                if (!params.hasOwnProperty('query_name')) {
                    params.query_name = queryName;
                }

                if (!params.hasOwnProperty('query_type')) {
                    params.query_type = queryType;
                }
            }
            if (isValidParam(params.sort_field_name) && isValidParam(params.sort_type)) {
                inputParam.sort_field_name = getStringParam(params.sort_field_name);
                inputParam.sort_type = getStringParam(params.sort_type);
            }

            params = { ...params, ...inputParam };

            if (!isValidParam(params) && startIndex >= 0 && pageSize > 0) {
                params = {};
                params.start_index = startIndex;
                params.page_size = pageSize;
            } else if (isValidParam(params) && !params.hasOwnProperty('start_index') && !params.hasOwnProperty('page_size')
                && startIndex >= 0 && pageSize > 0) {
                params.start_index = startIndex;
                params.page_size = pageSize;
            }


            if (isDetailView) {
                params.isDetailView = true;
            } else if (isReport) {
                params.isReport = true;
            } else if (isAssignRule) {
                let object = getSelectedObject();
                if (object === constants.EMAIL_MARKETING) {
                    object = constants.CONTACTS_OBJECT;
                }
                params.parent_object = object;
            }

            let settingObject = object;
            if (activeTab.object === 'settings' && isValidParam(info.is_duplicate_object) && getBooleanParam(info.is_duplicate_object)) {
                settingObject = constants.FIND_DUPLICATE_OBJECT
            }
            if (object === constants.DOWNLOAD_LEADS_OBJECT) {

                if (info.hasOwnProperty('leadStoreSearch') && getBooleanParam(info.leadStoreSearch)) {
                    let param = {};
                    if (info.hasOwnProperty('company')) {
                        param.company = getStringParam(info.company);
                    }
                    if (info.hasOwnProperty('industry')) {
                        param.industry = getStringParam(info.industry);
                    }
                    if (info.hasOwnProperty('state')) {
                        param.state = getStringParam(info.state);
                    }
                    if (info.hasOwnProperty('city')) {
                        param.city = getStringParam(info.city);
                    }
                    if (info.hasOwnProperty('zip')) {
                        param.zip = getStringParam(info.zip);
                    }
                    param.leadStoreSearch = getBooleanParam(info.leadStoreSearch)
                    params.leadstoreSearchObj = param;

                }
            }

            let isMounted = getBooleanParam(this.props.listView.mounted);
            if (!isMounted || isRefresh || isSearchWithinList) {
                this.props.getListViewData(settingObject, params);
            }
            if (object === constants.DOWNLOAD_LEADS_OBJECT) {
                let isSearchFieldInfoMounted = getBooleanParam(this.props.listView.isSearchFieldInfoMounted);
                if (!isSearchFieldInfoMounted) {
                    let searchInfoParams = {
                        is_search_field_info: true,
                        ...params
                    };
                    this.props.getListViewData(object, searchInfoParams);
                }

                if ((info.hasOwnProperty('leadStoreSearch') &&
                    getBooleanParam(info.leadStoreSearch)) && !isRefresh) {
                    this.props.getListViewData(object, params)
                }

            } else {
                let isGroupByMounted = getBooleanParam(this.props.listView.isGroupByMounted);
                if ((!isGroupByMounted || isRefresh || isSearchWithinList) && !isReport && !isLookup && !isDetailView) {
                    let groupByParams = {
                        is_group_by_info: true,
                        ...params
                    };
                    this.props.getListViewData(settingObject, groupByParams);
                }
            }
        }
    }

    componentWillUnmount() {
        let isDetailView = getBooleanParam(this.props.isDetailView);
        let isLookup = getBooleanParam(this.props.isLookup);
        let isReport = getBooleanParam(this.props.isReport);
        let isAssignRule = getBooleanParam(this.props.isAssignRule);
        let isDuplicate = getBooleanParam(this.props.isDuplicate);
        if (!isLookup && (isDetailView || isReport || isAssignRule || isDuplicate)) {
            this.props.setMounted(false, isDetailView, isReport, isAssignRule, isDuplicate);
        }
    }

    getFilterNameWithCount() {
        let headerLabel = '';
        let filterAndCount = null;
        let filterName = '';
        let arrGroupByObj = null;
        let groupByCondition = '';
        let totalCount = 0;
        let activeTab = null;
        let tabInfo = null;
        let object = null;
        let isReport = false;
        let filter = null;
        let queryType = '';
        let isShowQueryByFilter = false;
        let isForecast = false;
        let hasPermission = false;

        if (this.props.listView.mounted) {
            activeTab = getObjectParam(getActiveTab());
            tabInfo = getObjectParam(activeTab.info);
            object = getStringParam(activeTab.object);
            isReport = getBooleanParam(tabInfo.isReport);
            isForecast = getBooleanParam(tabInfo.isForecast);

            if (!isReport) {
                filter = getObjectParam(tabInfo.filter);
                queryType = getStringParam(filter.type);
            }

            if (!isReport && object !== constants.SEARCH_RESULTS && object !== constants.ADVANCE_SEARCH_RESULTS
                && object !== constants.AUTOMATION_DESIGNER_OBJECT && object !== constants.BROADCAST_CAMPAIGNS_OBJECT
                && object !== constants.SALES_TEMPLATE_OBJECT && object !== constants.MARKETING_TEMPLATE_OBJECT && object !== constants.AGREEMENT_TEMPLATE_OBJECT
                && object !== constants.SOLUTIONS_OBJECT && object !== constants.ASSIGNMENT_RULES_OBJECT && object !== constants.COUPONS_OBJECT
                && object !== constants.UNITS_OBJECT && object !== constants.NEWS_OBJECT
                && queryType !== 'group' && !isForecast && object !== constants.CALL_SCRIPT_OBJECT && object !== constants.SMS_TEMPLATE_OBJECT) {
                isShowQueryByFilter = true;
            }

            let listViewData = getObjectParam(this.props.listView.data);
            if (isReport) {
                filterName = getStringParam(tabInfo.reportLabel);
                groupByCondition = getStringParam(tabInfo.reportFieldValue);
                if (groupByCondition === 'Sent') {
                    groupByCondition = getLocalizedStrings().label.CAMPAIGNS.SENT;
                }
                if (groupByCondition === 'Opened' || groupByCondition === 'open') {
                    groupByCondition = getLocalizedStrings().label.CAMPAIGNS.OPENED;
                }
                if (groupByCondition === 'Unopened') {
                    groupByCondition = getLocalizedStrings().label.CAMPAIGNS.UNOPENED;
                }
                if (groupByCondition === 'Clicked') {
                    groupByCondition = getLocalizedStrings().label.CAMPAIGNS.CLICKED;
                }
                if (groupByCondition === 'Bounced' || groupByCondition === 'bounce') {
                    groupByCondition = getLocalizedStrings().label.CAMPAIGNS.BOUNCED;
                }
                if (groupByCondition === 'Unsubscribed') {
                    groupByCondition = getLocalizedStrings().label.CAMPAIGNS.UNSUBSCRIBED;
                }
                if (groupByCondition === 'Spam') {
                    groupByCondition = getLocalizedStrings().label.CAMPAIGNS.SPAM;
                }
                if (groupByCondition === 'Dropped') {
                    groupByCondition = getLocalizedStrings().label.CAMPAIGNS.DROPPED;
                }
                if (groupByCondition === 'Bounce') {
                    groupByCondition = getLocalizedStrings().label.CAMPAIGNS.BOUNCE;
                }
            } else {
                if (object === constants.SEARCH_RESULTS) {
                    let search = getObjectParam(tabInfo.search);
                    filterName = getStringParam(search.searchText);
                } else if (object === constants.SALES_TEMPLATE_OBJECT || object === constants.MARKETING_TEMPLATE_OBJECT || object === constants.WEB_FORMS
                    || object === constants.SMS_TEMPLATE_OBJECT || object === constants.CALL_SCRIPT_OBJECT || object === constants.AGREEMENT_TEMPLATE_OBJECT) {
                    let tempObject = object;
                    // if(object === constants.CALL_SCRIPT_OBJECT){
                    //     tempObject = constants.PHONE_TEMPLATE_OBJECT;
                    // }
                    tempObject = tempObject.replace('-', ' ');
                    tempObject = tempObject.replace('_', ' ');
                    if (object === constants.SALES_TEMPLATE_OBJECT || object === constants.SMS_TEMPLATE_OBJECT || object === constants.AGREEMENT_TEMPLATE_OBJECT) {
                        filterName = makeTiteleCase(activeTab.label);
                    } else {
                        filterName = makeTiteleCase(tempObject);
                    }

                } else {
                    filterName = getStringParam(listViewData.query_name)
                }
                //priyanka 15.1.20
                if (isValidParam(tabInfo) && tabInfo.hasOwnProperty("groupByCondition") && tabInfo.hasOwnProperty("groupByConditionTotalCount")) {
                    groupByCondition = tabInfo.groupByCondition;
                }//
                else {
                    groupByCondition = getStringParam(listViewData.group_by_condition);
                }
            }

            if (groupByCondition !== '' && !isReport) {
                let listViewGroupByInfo = getObjectParam(this.props.listView.groupByInfo);
                arrGroupByObj = listViewGroupByInfo.hasOwnProperty('group_by') ? getArrayParam(listViewGroupByInfo.group_by) : [];
                arrGroupByObj = arrGroupByObj.filter(f => { return f.label === groupByCondition });
                if (arrGroupByObj.length > 0) {
                    groupByCondition = arrGroupByObj[0].label;
                    if (groupByCondition === 'All') {
                        groupByCondition = getLocalizedStrings().label.COMMON.ALL;
                    } else if (object === constants.APPOINTMENTS || object === constants.TASKS) {
                        groupByCondition = getLocalizedStrings().label.APPOINTMENTS_TASKS.hasOwnProperty(groupByCondition) ? getLocalizedStrings().label.APPOINTMENTS_TASKS[groupByCondition] : groupByCondition;
                    } else {
                        groupByCondition = getLocalizedStrings().label.CAMPAIGNS.hasOwnProperty(groupByCondition) ? getLocalizedStrings().label.CAMPAIGNS[groupByCondition] : groupByCondition;
                    }
                    totalCount = arrGroupByObj[0].record_count;
                }
            } else {
                totalCount = listViewData.hasOwnProperty('total_count') ? getIntParam(listViewData.total_count) : 0;
            }
            if (this.props.object === constants.WORKFLOW_OBJECT || this.props.object === constants.ASSIGNMENT_RULES_OBJECT) {
                groupByCondition = getStringParam(listViewData.group_by_condition);
                filterName = getStringParam(listViewData.query_name);
                let objectList = this.props.app.objectList;
                if (isValidParam(objectList) && getArrayParam(objectList).length > 0) {
                    let temp;
                    objectList = getArrayParam(objectList).filter(f => {
                        if (f.name === groupByCondition.toLowerCase()) {
                            temp = true;
                        }
                        return temp;
                    });
                    if (objectList.length > 0) {
                        groupByCondition = objectList[0].label;
                    }
                }
            }
            if (object === constants.WEB_FORMS && filterName === 'Web Forms') {
                filterName = getLocalizedStrings().label.MODULE_ITEMS['Web Forms'];
            }
            if (object === constants.MARKETING_TEMPLATE_OBJECT && filterName === 'Marketing Templates') {
                filterName = getLocalizedStrings().label.MODULE_ITEMS['Marketing Templates'];
            }
            if (object === constants.BROADCAST_CAMPAIGNS_OBJECT && filterName === 'All Broadcasts') {
                filterName = getLocalizedStrings().label.BROADCAST_CAMPAIGNS.All_BROADCASTS;
            }
            if (object === constants.BROADCAST_CAMPAIGNS_OBJECT && filterName === 'All AB Campaigns') {
                filterName = getLocalizedStrings().label.AB_CAMPAIGNS.ALL_AB_CAMPAIGNS;
            }
            if (object === constants.AUTOMATION_DESIGNER_OBJECT && filterName === 'All Automated Campaigns') {
                filterName = getLocalizedStrings().label.AUTOMATION_DESIGNER.ALL_AUTOMATED_CAMPAIGNS;
            }
            if (object === constants.AUTOMATION_DESIGNER_OBJECT && filterName === 'All Touch Campaigns') {
                filterName = getLocalizedStrings().label.TOUCH_CAMPAIGNS.ALL_TOUCH_CAMPAIGNS;
            }
            if (object === constants.BROADCAST_CAMPAIGNS_OBJECT && filterName === 'Broadcasts') {
                filterName = getLocalizedStrings().label.BROADCAST_CAMPAIGNS.BROADCASTS;
            }
            if (object === constants.BROADCAST_CAMPAIGNS_OBJECT && filterName === 'A/B Tests') {
                filterName = getLocalizedStrings().label.AB_CAMPAIGNS.AB_TESTS;
            }
            if (object === constants.APPOINTMENTS && filterName === 'All Appointments') {
                filterName = getLocalizedStrings().label.HEADER.ALL_APPOINMENTS;
            }
            if (object === constants.APPOINTMENTS && filterName === 'My Appointments') {
                filterName = getLocalizedStrings().label.HEADER.MY_APPOINMENTS;
            }
            if (object === constants.APPOINTMENTS && filterName === 'My Completed Appointments') {
                filterName = getLocalizedStrings().label.HEADER.MY_COMPLETED_APPOINMENTS;
            }
            if (object === constants.APPOINTMENTS && filterName === 'My Missed Appointments') {
                filterName = getLocalizedStrings().label.HEADER.MY_MISSED_APPOINMENTS;
            }
            if (object === constants.APPOINTMENTS && filterName === 'Todays Appointments') {
                filterName = getLocalizedStrings().label.HEADER.TODAYS_APPOINMENTS;
            }
            if (object === constants.TASKS && filterName === 'All Tasks') {
                filterName = getLocalizedStrings().label.HEADER.ALL_TASKS;
            }
            if (object === constants.TASKS && filterName === 'My Completed Tasks') {
                filterName = getLocalizedStrings().label.HEADER.MY_COMPLETED_TASKS;
            }
            if (object === constants.TASKS && filterName === 'My Future Tasks') {
                filterName = getLocalizedStrings().label.HEADER.MY_FUTURE_TASKS;
            }
            if (object === constants.TASKS && filterName === 'My Pending Tasks') {
                filterName = getLocalizedStrings().label.HEADER.MY_PENDING_TASKS;
            }
            if (object === constants.TASKS && filterName === 'My Todays Tasks') {
                filterName = getLocalizedStrings().label.HEADER.MY_TODAYS_TASKS;
            }
            if (object === constants.AUTOMATION_DESIGNER_OBJECT && filterName === 'Automated Campaigns') {
                filterName = getLocalizedStrings().label.MODULE_ITEMS['Automated Campaigns'];
            }
            if (object === constants.CONTACTS_OBJECT) {
                filterName = getLocalizedStrings().label.FILTER.hasOwnProperty(filterName) ? getLocalizedStrings().label.FILTER[filterName] : filterName;
            }
            if (filterName === 'All records') {
                filterName = getLocalizedStrings().label.FILTER['All records'];
            }
            if (object === constants.SALES_TEMPLATE_OBJECT && filterName === 'Sales Templates') {
                filterName = getLocalizedStrings().label.SALES_TEMPLATE.SALES_TEMPLATES;
            } if (object === constants.AGREEMENT_TEMPLATE_OBJECT && filterName === 'Agreement Templates') {
                filterName = getLocalizedStrings().label.AGREEMENT_TEMPLATE.AGREEMENT_TEMPLATES;
            } if (object === constants.OPPORTUNITIES_OBJECT) {
                if (filterName === 'Added this Month') {
                    filterName = getLocalizedStrings().label.FILTER['Added this Month'];
                }
                if (filterName === 'Added this Week') {
                    filterName = getLocalizedStrings().label.FILTER['Added this Week'];
                }
                if (filterName === 'My Opportunities') {
                    filterName = getLocalizedStrings().label.FILTER['My Opportunities'];
                }
                if (filterName === 'Opportunities') {
                    filterName = getLocalizedStrings().label.FILTER['Opportunities'];
                }
            }
            if (object === constants.ACCOUNTS_OBJECT) {
                filterName = getLocalizedStrings().label.FILTER.hasOwnProperty(filterName) ? getLocalizedStrings().label.FILTER[filterName] : filterName;
            }
            if (object === constants.CASES_OBJECT && filterName === 'Cases') {
                filterName = getLocalizedStrings().label.FILTER['Cases'];
            }
            if (object === constants.PROJECTS_OBJECT && filterName === 'Projects') {
                filterName = getLocalizedStrings().label.FILTER['Projects'];
            }
            if (object === constants.ISSUES_OBJECT && filterName === 'Issues') {
                filterName = getLocalizedStrings().label.FILTER['Issues'];
            }
            if (object === constants.ASSIGNMENT_RULES) {
                filterName = getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULES;
            }
            if (object === constants.LANDING_PAGES_FROALA_OBJECT) {
                filterName = getLocalizedStrings().label.LANDING_PAGE.LANDING_PAGES;
            }
            if (object === constants.SOURCE_OBJECT || object === constants.RELATIONS_OBJECT || object === constants.CAMPAIGN_TYPES_OBJECT || object === constants.NOTE_TYPES_OBJECT || object === constants.APPOINTMENT_TYPES_OBJECT ||
                object === constants.LIBRARY_OBJECT || object === constants.USERS_OBJECT || object === constants.SECURITY_LEVEL_OBJECT || object === constants.EMAIL_INTEGRATION_OBJECT ||
                object === constants.WORKFLOW_OBJECT ||object === constants.LOCATION_OBJECT||object === constants.TASKSLIST_OBJECT|| object === constants.RECYCLE_BIN_OBJECT || object === constants.ASSIGNMENT_RULES_OBJECT) {
                let suffixName = "";
                if (object === constants.SOURCE_OBJECT || object === constants.LOCATION_OBJECT||object === constants.TASKSLIST_OBJECT||object === constants.RELATIONS_OBJECT || object === constants.CAMPAIGN_TYPES_OBJECT || object === constants.NOTE_TYPES_OBJECT || object === constants.APPOINTMENT_TYPES_OBJECT) {
                    suffixName = "s"
                }
                filterName = getLocalizedStrings().label.SETTINGS[object.toUpperCase()] + suffixName;
            }
            if (isReport) {
                //****For Sales Report******
                if (filterName === 'Activities By Type') {
                    filterName = getLocalizedStrings().label.REPORT.ACTIVITIES_BY_TYPE;
                }
                if (filterName === 'Activities By Sales Rep') {
                    filterName = getLocalizedStrings().label.REPORT.ACTIVITIES_BY_SALES_REP;
                }
                if (filterName === 'All Conversions By Status') {
                    filterName = getLocalizedStrings().label.REPORT.ALL_CONVERSIONS_BY_STATUS;
                }
                if (filterName === 'Customers By Source') {
                    filterName = getLocalizedStrings().label.REPORT.CUSTOMERS_BY_SOURCE;
                }
                if (filterName === 'Pipeline Report') {
                    filterName = getLocalizedStrings().label.REPORT['Pipeline Report'];
                }
                if (filterName === 'Pipeline by Sales Rep') {
                    filterName = getLocalizedStrings().label.REPORT.DEALS_BY_SALES_PER;
                }
                if (filterName === 'Deals by Stages') {
                    filterName = getLocalizedStrings().label.REPORT.DEALS_BY_STAGES;
                }
                if (filterName === 'Total Calls By Sales Rep') {
                    filterName = getLocalizedStrings().label.REPORT.TOTAL_CALLS_BY_SALES_REP;
                }
                if (filterName === 'Total Call Duration By Sales Rep') {
                    filterName = getLocalizedStrings().label.REPORT.TOTAL_CALL_DURATION_BY_SALES_REP;
                }
                if (filterName === 'Activity Matrix') {
                    filterName = getLocalizedStrings().label.REPORT.ACTIVITY_MATRIX;
                }
                if (filterName === 'Matrix By Status') {
                    filterName = getLocalizedStrings().label.REPORT.MATRIX_BY_STATUS;
                }
                if (filterName === 'Matrix By Source') {
                    filterName = getLocalizedStrings().label.REPORT.MATRIX_BY_SOURCE;
                }

                //***** For Service Report *****/
                if (filterName === 'Cases by Status') {
                    filterName = getLocalizedStrings().label.REPORT.CASES_BY_STATUS;
                }
                if (filterName === 'Cases by Priority') {
                    filterName = getLocalizedStrings().label.REPORT.CASES_BY_PRIORITY;
                }
                if (filterName === 'Projects by Status') {
                    filterName = getLocalizedStrings().label.REPORT.PROJECTS_BY_STATUS;
                }
                if (filterName === 'Projects by Type') {
                    filterName = getLocalizedStrings().label.REPORT.PROJECTS_BY_TYPE;
                }
                if (filterName === 'Issues by Status') {
                    filterName = getLocalizedStrings().label.REPORT.ISSUES_BY_STATUS;
                }
                if (filterName === 'Issues by Priority') {
                    filterName = getLocalizedStrings().label.REPORT.ISSUES_BY_PRIORITY;
                }
                if (filterName === 'Support Matrix') {
                    filterName = getLocalizedStrings().label.REPORT.SUPPORT_MATRIX;
                }
                if (filterName === 'Customers by Cases') {
                    filterName = getLocalizedStrings().label.REPORT.CUSTOMER_BY_CASES;
                }
                if (filterName === 'Project Matrix') {
                    filterName = getLocalizedStrings().label.REPORT.PROJECT_MATRIX;
                }
                if (filterName === 'Issue Matrix') {
                    filterName = getLocalizedStrings().label.REPORT.ISSUE_MATRIX;
                }
            }

            headerLabel = this.getFilterNameCountsElement(filterName, groupByCondition);
            hasPermission = hasAccessPermission(null, constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_MANAGE_FILTERS);
            try {
                filterAndCount = <div className="hdrfiltr" style={{ float: 'right', fontSize: '24px', position: 'relative', marginTop: '0px', height: 31 }}>
                    {headerLabel}
                    <div style={{ fontSize: '14px', display: 'inline-block', marginLeft: '2px', marginTop: 10 }}>{' (' + totalCount + ')'}</div>
                    {isShowQueryByFilter && <ListViewFilterDropDown object={object} queryName={listViewData.query_name} />}
                    {hasPermission && isValidParam(filter) && filter.type === 'filter' &&
                        <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            <span style={{ cursor: 'pointer', color: '#717171' }} onTouchTap={() => this.editFilter(filter)}>
                                <i key={filter.id} className={"material-icons"} style={{ 'fontSize': '0.9em' }} title={getLocalizedStrings().label.COMMON.EDIT}>edit</i>
                            </span>
                        </div>
                    }
                </div>;
            } catch (error) {
                console.error("Error in 'listView.js -> getFilterNameWithCount()':" + error);
            }
        }
        return filterAndCount;
    }

    getFilterNameCountsElement = (filterName, groupByCondition) => {
        let elements = [];
        try {
            filterName = getStringParam(filterName);
            if (this.props.object === constants.FIND_DUPLICATE_OBJECT) {
                filterName = getLocalizedStrings().label.FIND_DUPLICATES.FIND_DUPLICATES;
            }
            groupByCondition = getStringParam(groupByCondition);
            if (filterName !== '') {
                elements.push(<div title={filterName} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 930, float: 'left' }}>{filterName}</div>);

                elements.push(<div title={groupByCondition} style={{ float: 'left' }}>&nbsp;{"- " + groupByCondition}</div>);
            } else {
                elements.push(<div title={groupByCondition} style={{ float: 'left' }}>{groupByCondition}</div>);
            }
        } catch (error) {
            console.error("Error in 'listView.js -> getFilterNameCountsElement()':" + error);
        }
        return elements;
    }

    setListViewHeader = () => {
        let filterNameWithCount = this.getFilterNameWithCount();
        let show = false;
        let currentView = null;
        let showAddIcon = true;
        let showHeader = true;
        if (this.props.object === constants.BROADCAST_CAMPAIGNS_OBJECT || this.props.object === constants.AB_CAMPAIGNS_OBJECT ||
            ((this.props.object === constants.AUTOMATION_DESIGNER_OBJECT || this.props.object === constants.TOUCH_CAMPAIGNS_OBJECT) && this.props.app.me.is_show_touch)
            || this.props.object === constants.ACCOUNTS_OBJECT || this.props.object === constants.CONTACTS_OBJECT
            || this.props.object === constants.OPPORTUNITIES_OBJECT || (this.props.object === constants.CASES_OBJECT && this.props.app.selectedModuleName !== constants.MODULE_HUB)
            || this.props.object === constants.PROJECTS_OBJECT || this.props.object === constants.ISSUES_OBJECT
            || this.props.object === constants.CUSTOM_TABLE1_OBJECT || this.props.object === constants.CUSTOM_TABLE2_OBJECT
            || this.props.object === constants.CUSTOM_TABLE3_OBJECT || this.props.object === constants.CUSTOM_TABLE4_OBJECT) {
            show = true;
            currentView = 'list';
        }

        let kanbanObject = {};
        if (!this.state.isGlobalSearch) {
            kanbanObject.currentView = currentView;
            kanbanObject.show = show;
        }

        if (this.props.object === constants.APPOINTMENTS || this.props.object === constants.TASKS
            || this.props.object === constants.UNITS_OBJECT || this.props.object === constants.FIND_DUPLICATE_OBJECT) {
            showAddIcon = false;
        }

        if (this.props.object === constants.NEWS_OBJECT) {
            let isAdmin = this.props.app.me.t_name;
            if (isAdmin !== "Admin") {
                showAddIcon = false;
            }
        }

        if (this.props.object === constants.DOWNLOAD_LEADS_OBJECT) {
            showAddIcon = false;
            showHeader = false;
        }

        this.props.setHeader(this.props.object, filterNameWithCount, kanbanObject, showAddIcon, showHeader);
        //this.props.listView.isSetHeader = false;
    }

    getSelectedRecord = (selectedRows) => {
        let isLookup = getBooleanParam(this.props.isLookup);
        let isDetailView = getBooleanParam(this.props.isDetailView);
        if (isLookup) {
            this.props.getLookupSelectedRecord(selectedRows);
        } else {
            this.props.getListViewSelectedRecord(selectedRows, isDetailView, false);
        }
    }

    getGroupByShowHide = () => {
        let tab = getActiveTab();
        let object = this.props.object;
        if (isValidParam(tab)) {
            let info = getObjectParam(tab.info);
            if (info.hasOwnProperty('isShowGroupBy')) {
                if (object === constants.SOURCE_OBJECT || object === constants.LOCATION_OBJECT||object === constants.TASKSLIST_OBJECT|| object === constants.RELATIONS_OBJECT || object === constants.CAMPAIGN_TYPES_OBJECT || object === constants.NOTE_TYPES_OBJECT || object === constants.APPOINTMENT_TYPES_OBJECT ||
                    object === constants.LIBRARY_OBJECT || object === constants.DOWNLOAD_LEADS_OBJECT) {
                    this.props.listView.isShowGroupBy = false;
                } else {
                    this.props.listView.isShowGroupBy = getBooleanParam(info.isShowGroupBy);
                }
            } else if (object === constants.ASSIGNMENT_RULES_OBJECT || object === constants.WORKFLOW_OBJECT || object === constants.BROADCAST_CAMPAIGNS_OBJECT || object === constants.AUTOMATION_DESIGNER_OBJECT ||
                object === constants.AB_CAMPAIGNS_OBJECT || object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.OPPORTUNITIES_OBJECT ||
                object === constants.CUSTOM_TABLE1_OBJECT || object === constants.CUSTOM_TABLE2_OBJECT || object === constants.CUSTOM_TABLE3_OBJECT || object === constants.CUSTOM_TABLE4_OBJECT ||
                object === constants.SMS_TEMPLATE_OBJECT || object === constants.SALES_TEMPLATE_OBJECT || object === constants.MARKETING_TEMPLATE_OBJECT || object === constants.COUPONS_OBJECT ||
                object === constants.WEB_FORMS || object === constants.CASES_OBJECT || object === constants.SOLUTIONS_OBJECT || object === constants.PROJECTS_OBJECT || object === constants.ISSUES_OBJECT ||
                object === constants.UNITS_OBJECT || object === constants.SALES_LEADS_OBJECT || object === constants.NEWS_OBJECT || object === constants.TASKS || object === constants.APPOINTMENTS ||
                object === constants.CALL_SCRIPT_OBJECT || object === constants.AGREEMENT_TEMPLATE_OBJECT || object === constants.SECURITY_LEVEL_OBJECT) {
                if (this.props.listView.hasOwnProperty("groupByInfo") && this.props.listView.groupByInfo.hasOwnProperty("total_count") && this.props.listView.groupByInfo.total_count === 0) {
                    this.props.listView.isShowGroupBy = false;
                } else {
                    this.props.listView.isShowGroupBy = true;
                }
            } else {
                this.props.listView.isShowGroupBy = false;
            }
            return getBooleanParam(this.props.listView.isShowGroupBy);
        }
    }

    editFilter = (filter) => {
        if (isValidParam(filter)) {
            var hasPermission = null;
            hasPermission = hasAccessPermission(null, constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_MANAGE_FILTERS);
            if (hasPermission) {
                try {
                    var label = filter.name;
                    var data = {};
                    data.object = 'filter';
                    data.id = filter.id;
                    data.table = this.props.object.toLowerCase();
                    data.mode = 'edit';
                    data.callFromListView = true;
                    this.props.getAppDrawer(true, label, constants.FILTER, data, null);
                } catch (error) {
                    console.error("Error in 'listView.js -> editFilter()':" + error);
                }
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED,null);
            }
        }
    }

    getLeadstoreSearchFldShowHide = () => {
        return getBooleanParam(this.props.listView.isShowLeadstoreSearchFld);
    }


    render() {
        let pageHeight = window.innerHeight - 200;
        let top = (pageHeight-10) / 2;
        let activeTabInfo = getActiveTabInfo();
        let isDetailView = getBooleanParam(this.props.isDetailView);
        let isSetHeader = getBooleanParam(this.props.listView.isSetHeader);
        let isLookup = getBooleanParam(this.props.isLookup);
        let isReport = getBooleanParam(activeTabInfo.isReport);
        let isAssignRule = getBooleanParam(activeTabInfo.isAssignRule);
        if (isValidParam(this.props.isShowHeader) && !this.props.isShowHeader) {
            isSetHeader = false;
        }
        //let isGroupByShow = getBooleanParam(this.props.listView.isShowGroupBy);
        let isGroupByShow = isReport ? false : this.getGroupByShowHide();
        let isLeadstoreSearchFld = false;
        if (this.props.object === constants.DOWNLOAD_LEADS_OBJECT) {
            isLeadstoreSearchFld = this.getLeadstoreSearchFldShowHide();
        }
        let listViewData = null;
        let totalRecordCount = 0;
        if (!isDetailView) {

            let reportType = isReport ? getStringParam(activeTabInfo.reportType) : "";
            if (!isReport || (reportType !== constants.AUTO_RESPONDER_REPORT_TYPE && reportType !== constants.BROADCAST_REPORT_TYPE)) {
                document.documentElement.style.overflow = 'hidden';
            }
        }

        if (isLookup) {
            listViewData = getObjectParam(this.props.data);
        } else {
            listViewData = getObjectParam(this.props.listView);
        }

        let isMounted = getBooleanParam(listViewData.mounted);

        let params = populateFiltersFromActiveTab();
        let queryDetails = {};

        if (params !== null && isValidParam(params)) {
            queryDetails.queryId = getIntParam(params.query_id);
            queryDetails.queryName = getStringParam(params.query_name);
            queryDetails.queryType = getStringParam(params.query_type);
        }

        var isSearch = getBooleanParam(this.props.isSearch);
        var searchParams = getObjectParam(this.props.searchParams);
        if (isSearch) {
            queryDetails.isSearch = isSearch;
            queryDetails.search = getArrayParam(searchParams.search);
            queryDetails.searchText = getStringParam(searchParams.search_text);
            queryDetails.queryName = getStringParam(searchParams.query_name);
            queryDetails.queryType = getStringParam(searchParams.query_type);
        }

        if (isMounted && isSetHeader && !isDetailView && !isLookup) {
            this.setListViewHeader();
        }

        totalRecordCount = isMounted ? getIntParam(listViewData.data.total_count) : 0;
        if (isMounted && this.props.object !== constants.DOWNLOAD_LEADS_OBJECT) {
            if (!isDetailView && !isLookup && !isReport && !isAssignRule) {
                totalRecordCount = getIntParam(listViewData.groupByInfo.total_count);
                listViewData.data.total_count = totalRecordCount;
            }
        }

        let contentHeight = document.body.clientHeight - 180;

        let contentWidth = '86%';
        if (isLookup || isDetailView) {
            contentWidth = '100%'
        } else if (!isGroupByShow && !isLeadstoreSearchFld) {
            contentWidth = '98.5%'
        }

        let listViewWidth = '98%';
        if (isSearch) {
            listViewWidth = '100%';
        }
        // 
        // let tabInfo = getObjectParam(getActiveTabInfo());
        // if (tabInfo.hasOwnProperty('advanceSearch') && isValidParam(tabInfo['advanceSearch'])) {
        //     contentWidth = '101%';
        //     if (isGroupByShow) {
        //         contentWidth = '86%'
        //     }
        // }
        let object = this.props.object;
        if (this.props.object === constants.FIND_DUPLICATE_OBJECT && isValidParam(this.props.tab.tabs[0].info.is_duplicate_object)) {
            object = getBooleanParam(this.props.tab.tabs[0].info.is_duplicate_object) ? getStringParam(this.props.tab.tabs[0].info.input_param.object) : object;
        }

        let divPadding = "0px";
        if (this.props.object === constants.DOWNLOAD_LEADS_OBJECT) {
            divPadding = "16px";
        }

        let contentLeft = (!isGroupByShow) ? '27px':'0px';
        return (
            <div className="pageBg clearfix" key={this.props.object} style={{ padding: divPadding, width: listViewWidth, marginBottom: '0px' }}>
                {
                    (isGroupByShow && !isLookup && !isDetailView) &&
                    <div style={{ float: 'left', width: '14%', overflowY: 'auto', height: contentHeight }}>
                        <GroupByFieldComponent isSearch={isSearch} object={object} listViewCallBack={this.listViewCallBack} />
                    </div>
                }

                {isMounted &&
                    <div style={{ float: 'right', width: contentWidth ,marginLeft: contentLeft}}>
                        <div style={{ height: '40px', width: '100%' }}>
                            <ListViewHeader parentObject={this.props.parentObject} object={this.props.object} listView={listViewData} isDetailView={this.props.isDetailView} detailViewParams={this.state.detailViewParams} isLookup={isLookup} listViewCallBack={this.listViewCallBack} isSearch={this.props.isSearch} childObject={object} />
                        </div>
                        <div className='control-pane'>
                            <div className='control-section'>
                                <RenderTreeGrid {...this.props} />
                            </div>
                        </div>
                    </div>
                }
                {!isMounted &&
                   <div style={{ width: '100%', height: pageHeight }}>
                   <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                       <div style={{ ...styles.assetLoaderContainer, height: 55, width: 55, padding: 6 }}>
                           <ShowCircularProgress size={35} style={{ marginTop: '4', marginLeft: '4' }} />
                       </div>
                   </div>
               </div>
                }

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TreeView)

class RenderTreeGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fieldsMounted: false,
            selected_fields: [],
            query_id: null,
            query_name: null,
            query_type: null
        }

        let token = localStorage.getItem('token');
        this.selectionSettings = { checkboxOnly: true };

        let activeTab = getActiveTab();
        activeTab = getObjectParam(activeTab);
        let info = getObjectParam(activeTab.info);
        let groupByFieldName = getStringParam(info.groupByFieldName);
        let groupByCondition = getStringParam(info.groupByCondition);
        let params = { call_object: 'units' };
        if (groupByFieldName !== '') {
            params.group_by_field_name = groupByFieldName;
        }
        if (groupByCondition !== '') {
            params.group_by_condition = groupByCondition;
        }
        params = encodeURIComponent(JSON.stringify(params));

        this.data = new DataManager({
            url: constants.endPoint + '/v3/accounts/get/unit/treeview?input_param=' + params,
            adaptor: new WebApiAdaptor(), headers: [{
                'dataType': 'jsonp',
                'contentType': 'application/json; charset=utf-8',
                'Authentication': JSON.stringify({ token: token })
            }]
        })

        this.toolbarOptions = ['Search'];
        this.treegridInstance = null;
        this.customAttributes = { class: 'treegridcss' };
    }
    componentDidMount() {
        this.getFieldInfo();
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.treeView.isRefreshTreeView === true) {
            nextProps.treeView.isRefreshTreeView = false;
            this.state.mounted = false;
            this.state.fieldsMounted = false;
            this.setState({...this.state});
            this.getFieldInfo();
        }

    }

    getFieldInfo = () => {
        let params = {};
        params.queries = [];
        params.queries.push({ object: 'accounts', query_name: 'Units' });
        this.state.mounted = false;
        this.setState({ ...this.state });
        let promise = getQueryDetails(params);
        if (isValidParam(promise)) {
            promise.then((response) => {
                response = getObjectParam(response);
                let params2 = {};
                if (response.hasOwnProperty('Units')) {
                    params2.query_name = response.Units.query_name;
                    params2.query_id = response.Units.query_id;
                    params2.query_type = response.Units.query_type;

                    this.state.query_name = response.Units.query_name;
                    this.state.query_id = response.Units.query_id;
                    this.state.query_type = response.Units.query_type;
                }
                var promise2 = Promise.resolve(getListViewSetupData('accounts', params2));
                if (isValidParam(promise)) {
                    promise2.then((response) => {
                        this.state.fieldsMounted = true;
                        this.state.selected_fields = response.selected_fields;
                        this.setState({ ...this.state });
                    });
                }
            });
        }

    }

    getTreeGridFields = () => {
        return this.state.selected_fields.map((el, index) => {
            let showCheckbox = index === 0 ? true : false;
            return (
                <ColumnDirective 
                    customAttributes={this.customAttributes} 
                    showCheckbox={showCheckbox} 
                    key={el.id} field={el.name} 
                    headerText={el.label} 
                    width={el.width} 
                    textAlign='Left'
                >
                </ColumnDirective>
            )
        });
    }

    rowSelected = (row) => {
        if (row.checked) {
            let isParent = false;
            if (row.rowData.parentItem === 0) {
                isParent = true;
            }
            this.props.treeView.selectedRows.push({ rowIdx: row.rowElement.rowIndex, id: row.rowData.id, title: row.rowData.company, email: row.rowData.email, isParent: isParent, myclient_id: row.rowData.myclient_id });
            this.props.setTreeViewSelectedRecord(this.props.treeView.selectedRows);
        } else {
            this.props.treeView.selectedRows = this.props.treeView.selectedRows.filter((el) => {
                return el.rowIdx !== row.rowElement.rowIndex;
            });
            this.props.setTreeViewSelectedRecord(this.props.treeView.selectedRows);
        }
    }

    componentWillUnmount() {
        this.props.treeView.selectedRows = [];
        this.props.setTreeViewSelectedRecord(this.props.treeView.selectedRows);
    }

    componentDidUpdate() {
        try {
            let el = $('#_gridcontrol_searchbar');
            if(el.length === 1) {
                $.extend($.expr[":"], {
                    "containsIN": function (elem, i, match, array) {
                        return (elem.textContent || elem.innerText || "").toLowerCase().indexOf((match[3] || "").toLowerCase()) >= 0;
                    }
                });

                let searchbar = $('#custom_searchbar');
                if(searchbar.length === 0) {
                    $('.e-toolbar-right').empty();
                    $('.e-toolbar-right').append('<div class="e-toolbar-item e-search-wrapper" aria-disabled="false" title="Search"><div class="e-input-group e-search" role="search">        <input id="custom_searchbar" class="e-input" name="input" type="search" placeholder="Search">        <span id="_gridcontrol_searchbutton" class="e-input-group-icon e-search-icon e-icons" tabindex="-1" title="Search" aria-label="search"></span>         </div></div>');
                    $('#custom_searchbar').focus(function() {
                        $('.e-search').addClass('e-input-focus');
                    });
                    $('#custom_searchbar').blur(function() {
                        $('.e-search').removeClass('e-input-focus');
                    });
                    $('#custom_searchbar').on('keyup',function(event){
                        if (event.keyCode === 13) {
                            event.preventDefault();
                            return false;
                        }
                        var text = $(this).val();
                        $('.e-row').hide();
                        $('.e-row:containsIN("'+text+'")').show();
                    });
                
                }
            }
            
        } catch (error) {
           console.log(error);
        }
    }

    openTab = (label, object, type, url, isRedirect, filter) => {
        let activeTab = getObjectParam(getActiveTab());
        let activeTabIndex = getIntParam(activeTab.index);
        let info = getObjectParam(activeTab.info);
        let isInXpress = getBooleanParam(info.isInXpress);

        let tab = { label: label, object: object, type: TYPE_DETAIL_VIEW, imgName: null, url: url, isActive: true, parentTabIndex: activeTabIndex };
        if (isInXpress) {
            tab.info = { isInXpress: isInXpress, inXpressProjectId: getIntParam(info.inXpressProjectId) };
        }

        addTab(tab, isRedirect);
    }

    login = (projectId) => {
        try {
            let promise = franchiseLogin(projectId);
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response) && getBooleanParam(response.is_franchise_login)) {
                        localStorage.setItem('token', response.token);
                        localStorage.setItem('is_franchisor_token', true);
                        if (constants.APP_URL === "") {
                            window.location.replace("/");
                        } else {
                            window.location.replace(constants.APP_URL);
                        }
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'listViewHeader.js -> login()':" + error);
        }
    }

    detailLink = (data) => {
        let label = data.company;
        if(label === ''){
            label = getStringParam(data.first_name)+' '+ getStringParam(data.last_name);
        }
        if(label.trim()===''){
            label ='EMPTY'
        }

        let linkUrl = '/accounts' + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + data.id;
        this.openTab(label, 'accounts', TYPE_DETAIL_VIEW, linkUrl, true, '');
    }

    sendEmail = (valueObj, event) => {
        let selectedId = valueObj.id;
        let object = "";
        object = this.props.object;
        
        let labelName = null;
        let isEmail = true;
        let recordId = 0;
        if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.UNITS_OBJECT) {
            let endPoint = "";
            let params = {};
            recordId = selectedId;

            if (object === constants.ACCOUNTS_OBJECT || object === constants.UNITS_OBJECT) {
                endPoint = endPoints.ACCOUNTS.MAP_DATA;
                params = { "id": recordId, "fields": ["company", "first_name", "last_name", "email"] };
            } 

            var promise = Promise.resolve(HTTPClient.get(endPoint, params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        let records = response.records;
                        let firstName = "";
                        let lastName = "";
                        let fullName = "";
                        let company = "";
                        let email = "";
                        let isFullName = false;
                        if (records) {
                            if (records.hasOwnProperty('first_name')) {
                                firstName = records.first_name;
                            }
                            if (records.hasOwnProperty('last_name')) {
                                lastName = records.last_name;
                            }
                            if (records.hasOwnProperty('company')) {
                                company = records.company;
                            }
                            if (records.hasOwnProperty('email')) {
                                email = records.email;
                            }
                        }

                        if (getStringParam(email) !== '') {
                            if (firstName !== null && firstName !== "" && firstName !== undefined && lastName !== null && lastName !== "" && lastName !== undefined) {
                                fullName = firstName + " " + lastName;
                            }
                            if (firstName !== null && firstName !== "" && firstName !== undefined && (lastName === null || lastName === "" || lastName === undefined)) {
                                fullName = firstName;
                            }

                            if ((firstName === null || firstName === "" || firstName === undefined) && lastName !== null && lastName !== "" && lastName !== undefined) {
                                fullName = lastName;
                            }

                            if (fullName === null || fullName === "" || fullName === undefined) {
                                fullName = company;
                            }

                            if (fullName === null || fullName === "" || fullName === undefined) {
                                fullName = email;
                            }

                            if (fullName === null || fullName === "" || fullName === undefined) {
                                fullName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
                                isEmail = false;
                            }

                            if (fullName !== null && fullName !== "" && fullName !== undefined) {
                                if (isEmail) {
                                    labelName = getLocalizedStrings().label.COMMON.EMAIL_TO + " " + fullName + "";
                                } else if (!isEmail && !isFullName) {
                                    labelName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
                                }
                            }
                                this.openSendEmailDialog(object, selectedId, labelName, firstName, email);
                        } else {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.LIST_VIEW.NO_EMAIL_TO_SEND_MAIL);
                        }

                    }
                });
            }
        }
    }

    openSendEmailDialog = (object, selectedId, labelName, firstName, email) => {
        let emailList = [];
        emailList.push({id: selectedId, email:email});
        //let value = LinkToType.TYPE_EMAIL;
        let data = {};
        data.email_id_list = emailList;
        data.object = object;
        data.batch_mail = false;
        data.type = constants.TYPE_EMAIL;
        data.firstName = firstName;
        data.fieldName = 'email';
        let style = {};
        style.width = '90%';
    
        this.props.getAppCustomDrawer(true, labelName, LinkToType.TYPE_EMAIL, style, data, null, true);

    }


    openTwiloDialog = (value, event) => {
        let recordId = value.id;
        let url = endPoints.UNITS.GET_PHONE_SETUP_INFO;
        let promise = Promise.resolve(HTTPClient.get(url, null));
        promise.then((response)=>{
            if(isValidParam(response)){
                let phoneSetup = response.phoneSetup;

                if (phoneSetup.is_ring_central) {
                    // Code for Ring Central
                    openRingCentralWebWidget();
                } else {
                    if (phoneSetup.is_twilio) {
                        let object = this.props.object;
                        if(this.props.object === constants.UNITS_OBJECT){
                            object =  constants.ACCOUNTS_OBJECT;
                        }
                        this.getCapabilityTokenForCall(object, recordId, true, value.name, value.phone);
                    }
                }
            }
        })

       
    }

    getCapabilityTokenForCall = (object, recordId, phoneField, fieldName, selectedPhoneNumber) => {
        var promise = Promise.resolve(HTTPClient.get(endPoints.CALLING_INTEGRATION.GENERATE_CAPABILITY_TOKEN, null));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    let capabilityToken = getStringParam(response.token);
                    if (capabilityToken !== null && capabilityToken !== "") {
                        this.getCapabilityToken(capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber);
                    }
                }
            });
        }
    }
    getCapabilityToken = (capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber) => {
        let params = { "id": recordId, parent_table_id: OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT] };
        let endPoint = getObjectSpecificEndpoint(object, endPoints.RECORDS.RECORD_LINKED_MAP_DATA);
        if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT) {
            endPoint = getObjectSpecificEndpoint(object, endPoints.RECORDS.MAP_DATA);
            params = { "id": recordId, "fields": ["company", "first_name", "last_name", "phone", "mobile", "workflow_id", "country"] };
        }
        var promise = Promise.resolve(HTTPClient.get(endPoint, params));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    let records = response.records;
                    this.processInfoAndOpenCallDialog(records, capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber);
                }
            });
        }

    }

    processInfoAndOpenCallDialog = (records, capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber) => {
        let firstName = "";
        let lastName = "";
        let fullName = "";
        let workflowId = "";
        let company = "";
        let title = "";
        let country = "";
        if (records) {
            if (records.hasOwnProperty('first_name')) {
                firstName = records.first_name;
            }

            if (records.hasOwnProperty('last_name')) {
                lastName = records.last_name;
            }

            if (records.hasOwnProperty('workflow_id')) {
                workflowId = records.workflow_id;
            }

            if (records.hasOwnProperty('company')) {
                company = records.company;
            }

            if (records.hasOwnProperty('country')) {
                country = records.country;
            }
        }

        if (firstName !== null && firstName !== "" && firstName !== undefined && lastName !== null && lastName !== "" && lastName !== undefined) {
            fullName = firstName + " " + lastName;
        }
        if (firstName !== null && firstName !== "" && firstName !== undefined && (lastName === null || lastName === "" || lastName === undefined)) {
            fullName = firstName;
        }

        if ((firstName === null || firstName === "" || firstName === undefined) && lastName !== null && lastName !== "" && lastName !== undefined) {
            fullName = lastName;
        }

        if (fullName === null || fullName === "" || fullName === undefined) {
            fullName = company;
        }
        if (fullName === null || fullName === "" || fullName === undefined) {
            fullName = selectedPhoneNumber;
        }
        if (fullName !== null && fullName !== "" && fullName !== undefined) {
            title = "Call " + fullName + "";
        }

        let label = title;
        let type = LinkToType.TYPE_CLICK_TO_CALL;
        let data = {};
        data.id = recordId;
        data.object = object;
        //data.type = dataType;
        data.token = capabilityToken;
        data.selectedPhoneNumber = selectedPhoneNumber;
        data.fieldName = fieldName;
        data.workflowId = workflowId;
        data.country = country;
        data.fullName = fullName;
        data.isDetailView = this.props.isDetailView;
        let style = {};
        style.width = '40%';

        let selectedRecord = [{ rowIdx: this.props.rowIdx, id: recordId, title: title }];
        data.selectedRecords = selectedRecord;
        data.call_from = constants.LIST_VIEW;
        this.props.getCallDrawer(true, label, type, style, data, null);
    }
    
    

    rowDataBound = (args) => {
        try {
            if (isValidParam(args) && isValidParam(args.row)) {
                this.props.treeView.selectedRows = [];
                var element = args.row.firstChild;
                if (isValidParam(element)) {
                    let parentItem = getObjectParam(args.data).parentItem;
                    if (parentItem === 0) {
                        var rowElement = element.querySelector(".e-treecell");
                        if (isValidParam(rowElement)) {
                            let text = rowElement.innerText;
                            text = text !== '' ? text : 'Empty';
                            rowElement.innerHTML = '';

                            var hyperLinkEl = document.createElement('a');
                            hyperLinkEl.style.cursor = 'pointer'
                            hyperLinkEl.innerText = text;
                            hyperLinkEl.title = text;

                            hyperLinkEl.addEventListener("click", this.detailLink.bind(this, args.data));
                            rowElement.appendChild(hyperLinkEl);

                            let fields = getArrayParam(this.props.sfForm.data.accounts.fields);
                        let emailField = fields.filter(field => { return field !== null && !field.is_hidden_field && field.is_email_field && field.name ==='email' });
                        let emailheader =''
                        if(emailField !== null && emailField.length > 0){ 
                            emailheader = emailField[0].label;
                        }
                        let phoneField = fields.filter(field => { return field !== null && !field.is_hidden_field && field.is_phone_field && field.name ==='phone' });
                        let phoneheader =''
                        if(phoneField !== null && phoneField.length > 0){
                            phoneheader = phoneField[0].label;
                        }
                        let extraField = fields.filter(field => { return field !== null && !field.is_hidden_field && field.name ==='plan_name' });
                       
                        let extraheader ='';
                        if(extraField !== null && extraField.length > 0){
                            extraheader = extraField[0].label;
                        }
                        var rowsEl = args.row.getElementsByClassName("e-rowcell treegridcss");
                        var extraFieldElement = [];
                        Array.from(rowsEl).forEach(v => v.getAttribute('aria-label').endsWith("column header "+extraheader) ? extraFieldElement.push(v) : v);
                        rowElement = extraFieldElement.length > 0 ?  extraFieldElement[0]: {};
                        if (isValidParam(rowElement)) {
                            let text = rowElement.innerText;
                            if(text !== ''){
                                rowElement.innerHTML = '';
                                var spanElement = document.createElement('span');
                                spanElement.innerText = text;
                                spanElement.title = text;
                                rowElement.appendChild(spanElement);
                            }
                        }
                        var arrPhoneElement = [];
                        Array.from(rowsEl).forEach(v => v.getAttribute('aria-label').endsWith("column header "+phoneheader) ? arrPhoneElement.push(v) : v);

                        var arrEmailElement = [];
                        Array.from(rowsEl).forEach(v => v.getAttribute('aria-label').endsWith("column header "+emailheader) ? arrEmailElement.push(v) : v);

                        rowElement = arrPhoneElement.length > 0 ?  arrPhoneElement[0]: {};
                        if (isValidParam(rowElement)) {
                            let text = rowElement.innerText;
                            if(text !== ''){
                                rowElement.innerHTML = '';

                                var hyperLinkE2 = document.createElement('a');
                                hyperLinkE2.style.cursor = 'pointer'
                                hyperLinkE2.innerText = text;
                                hyperLinkE2.title = text;
    
                                hyperLinkE2.addEventListener("click", this.openTwiloDialog.bind(this, args.data));
                                rowElement.appendChild(hyperLinkE2);
                            }
                        }

                        rowElement = arrEmailElement.length > 0 ?  arrEmailElement[0]: {};
                        if (isValidParam(rowElement)) {
                            let text = rowElement.innerText;
                            if(text !== ''){
                                rowElement.innerHTML = '';

                                var hyperLinkE3 = document.createElement('a');
                                hyperLinkE3.style.cursor = 'pointer'
                                hyperLinkE3.innerText = text;
                                hyperLinkE3.title = text;
    
                                hyperLinkE3.addEventListener("click", this.sendEmail.bind(this, args.data));
                                rowElement.appendChild(hyperLinkE3);
                            }
                        }
                        }
                    }
                   
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    resizeStop = (arg) => {
        let colIndex = arg.column.index;
        let width = getIntParam(arg.column.width.replace('px', ''));
        let isLookup = false;
        let colPos = 1;
        let updatedFieldId = 0;
        let params = {};
        try {
            let info = getObjectParam(getActiveTabInfo());
            let isInXpress = getBooleanParam(info.isInXpress);
            isLookup = getBooleanParam(this.props.isLookup);

            width = width < 80 ? 80 : width;

            // listViewData = this.props.listView.data;
            if (!isLookup && !isInXpress ) {
                
                params.query_name = this.state.query_name;
                params.query_type = this.state.query_type;
                params.query_id = this.state.query_id;

                params.field_width_info = [];

                // colPos = isShowPic ? colPos + 1 : colPos;
                let fields = getArrayParam(this.props.sfForm.data.accounts.fields);
                fields = fields.filter(field => { return field !== null && !field.is_hidden });
                fields.forEach((field, index) => {
                    params.field_width_info.push({
                        id: field.id,
                        name: field.name,
                        width: colIndex === colPos ? width : field.width
                    });
                    if (colIndex === colPos) {
                        updatedFieldId = field.id;
                    }
                    colPos++;
                });

                var promise = Promise.resolve(saveListViewLayoutRowWidth('accounts', params));
                if (isValidParam(promise)) {
                    promise.updateRowOnRedux = this.updateRowOnRedux;
                    promise.then((response) => {
                    });
                }
            }
        } catch (error) {
            console.error("Error in 'dataTable.js -> onColumnResize()':" + error);
        }

    }

    render() {
        let pageHeight = window.innerHeight - 247;
        let top = (pageHeight-10) / 2;
        if (!this.state.fieldsMounted) {
            return (<div style={{ width: '100%', height: pageHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 55, width: 55, padding: 6 }}>
                            <ShowCircularProgress size={35} style={{ marginTop: '4', marginLeft: '4' }} />
                        </div>
                    </div>
                </div>);
        }
        let treeGridFields = null;
        if (this.state.fieldsMounted) {
            treeGridFields = this.getTreeGridFields();
        }
        return (<div style={{height:2}}>
            <TreeGridComponent 
                rowHeight="30"
                /*allowResizing={true} 
                resizeStop={this.resizeStop}*/ 
                checkboxChange={this.rowSelected} 
                rowDataBound={this.rowDataBound} 
                drawNode={this.drawNode}  
                /*selectedRowIndex={2}*/ 
                toolbar={['Search']} 
                dataSource={this.data} 
                ref={treegrid => this.treegridInstance = treegrid} 
                hasChildMapping='isParent' height={pageHeight-120} 
                pageSettings={{ pageCount: 3, pageSize: 100  }} 
                treeColumnIndex={0} 
                allowPaging='true' 
                idMapping='myclient_id' 
                parentIdMapping='parentItem' 
                selectionSettings={this.selectionSettings} 
                rowDeselected={this.rowDeselected} 
                rowSelected={this.rowSelected} 
            >
                <ColumnsDirective>
                    {treeGridFields}
                </ColumnsDirective>
                <Inject services={[Filter, Toolbar, Page, Resize]} />
            </TreeGridComponent>
        </div>)
    }
}

TreeView.propTypes = {
    object: PropTypes.string,
    parentObject: PropTypes.string,
    parentRecordId: PropTypes.number,
    isDetailView: PropTypes.bool,
    isLookup: PropTypes.bool,
    data: PropTypes.object
};

function franchiseLogin(projectId) {
    let url = null;
    var promise = null;
    try {
        url = endPoints.LOGIN.FRANCHISE_LOGIN_CRED;
        promise = HTTPClient.get(url, { franchise_tenant_id: projectId });
    } catch (e) { console.error("Error in 'unitsActions.js -> submitLoginCredential()':" + e); }
    return promise;
}

function openRingCentralWebWidget() {
    var rcs = document.createElement("script");
    //rcs.src =  rcs.src = "https://ringcentral.github.io/ringcentral-embeddable-voice/adapter.js?appKey=MLf6gyKLTrygTsae59AZyw&appSecret=EHB8hNgDTs-kCmS5LvX1IwVNfmBHwPS-2CAlyWs0SQHw&appServer=https://platform.devtest.ringcentral.com&redirectUri=https://ringcentral.github.io/ringcentral-embeddable-voice/redirect.html";

    rcs.src = "https://ringcentral.github.io/ringcentral-embeddable-voice/adapter.js";
    rcs.id = "ring-central_widget_adapter";
    var rcs0 = document.getElementsByTagName("script")[0];
    if (rcs0.id !== rcs.id) {
        rcs0.parentNode.insertBefore(rcs, rcs0);
    }
    if (window.RCAdapter) {
        window.RCAdapter.setMinimized(false);
    }
}

