import React, { useState, useEffect } from 'react';
import { setHeader } from '../../../services/actions/headerActions';
import { connect } from 'react-redux';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { constants } from '../../../services/constants/constants';
import { Icon } from '@mui/material';
import copy from 'copy-to-clipboard';
import ShowCircularProgress from '../components/circularProgress';
import { useDispatch, useSelector } from "react-redux";


const CompanySchedulerLink =({})=> {
    const app = useSelector((state) => state.app);
    const dispatch = useDispatch();

    const [CompanySchedulerLink, setCompanySchedulerLink] = useState("");
    const [setupAvailable, setSetupAvailable] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [copyTitle, setCopyTitle] = useState(getLocalizedStrings().label.SCHEDULER.COPY_TO_CLIPBOARD);
   

    useEffect(()=>{
        getCompanySchedulerLink();
    },[])
  
    const getCompanySchedulerLink = () => {
        try {
            let CompanySchedulerLinks = "";
            var promise = Promise.resolve(HTTPClient.get(endPoints.COMPANY_SCHEDULER.GET_COMPANY_SCHEDULER_LINK, null));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (response.slug != "") {
                        CompanySchedulerLinks = response.slug;
                        setCompanySchedulerLink(CompanySchedulerLinks);
                        setSetupAvailable(true);
                        setMounted(true);
                    } else {
                        setSetupAvailable(false);
                        setMounted(true);                    }
                });
            }
        } catch (error) {
            console.error("Error in '.js -> getData()':" + error);
        }
    }
   const copyToClipboard = (schedulerURL) => {
        copy(schedulerURL);
        dispatch(showCustomSnackBar(getLocalizedStrings().message.SCHEDULER.SCHEDULER_LINK_COPIED_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
        setCopyTitle(schedulerURL)
    }
    
        let schedulerURL = constants.APPOINTMENT_SCHEDULER_URL + CompanySchedulerLink;
        if (mounted) {
        return (
            <div>
                {setupAvailable &&
                    <div style={{ marginLeft: '25px' ,float: 'left', paddingLeft: '20px', paddingTop: '10px', paddingBottom: '10px', maxHeight: '200px', overflowY: 'auto', width: '100%', backgroundColor: '#e3e6e8', marginBottom: '10px' }}>
                        <div style={{ fontSize: '20px' }}>Company Scheduler Link</div>
                        <div style= {{ marginTop: '15px'}}>
                            <span style={{ paddingRight: '5px', fontSize: "14px " }}><a href={schedulerURL} target="_new" onClick={() => { }} >{schedulerURL} </a></span>
                            <span title={copyTitle} style={{ height: '27px', width: '28px', marginLeft: '-2px' }}
                                onClick={() => copyToClipboard(schedulerURL)}>
                                <Icon style={{ fontSize: '16px', color: '#717171', cursor: 'pointer' }}>content_copy</Icon>
                            </span>
                        </div>
                    </div>
                }
                {!setupAvailable &&
                    <div style={{  marginTop: '150px', textAlign:'center' }}>
                        <span>Scheduler setup is not available</span>
                    </div>
                }

            </div>
        );
            }
            else {
                let contentHeight = window.innerHeight - 240;
                let top = (contentHeight - 10) / 2;
                return (
                    <div style={{ width: '100%', height: contentHeight }}>
                        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                            </div>
                        </div>
                    </div>
                );
            }
    
}

export default CompanySchedulerLink;

