
import React from 'react';
import { endPoints } from '../../../services/constants/endPoints';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import ShowCircularProgress from '../components/circularProgress';
import * as HTTPClient from '../../../services/helper/httpClient';
import * as ParameterVerifier from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import PopOver from '../components/PopOver';
import DraggableSelect from '../../sfPlugins/draggableSelect.tsx';
import find from 'lodash/find';
import concat from 'lodash/concat';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { isValidParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { useEffect, useState } from "react";
import { useDispatch} from "react-redux";


let aggregate_options = [
    {
        label: '',
        value: ''
    },
    {
        label: 'Sum',
        value: 'Sum'
    },
    {
        label: 'Average',
        value: 'Average'
    },
    {
        label: 'Min',
        value: 'Min'
    },
    {
        label: 'Max',
        value: 'Max'
    },
    {
        label: 'Count',
        value: 'Count'
    },
];

let popOverStatusOptions = [
    { value: 'asc', label: 'Ascending' },
    { value: 'desc', label: 'Descending' }
];

const UserReportTabular = ({object,setParentState,data,id}) => {

    const dispatch = useDispatch();

    const [isMounted, setIsMounted] = useState(false);
    const [fieldInfo, setFieldInfo] = useState({
                    selected_fields: [],
                    available_fields: [],
                    fieldSelectorLoading: true,
                    sortFieldLabel: '',
                    sortField: '',
                    sortType: 'desc',
                    fieldsCount: 0,
                    aggregate_fields: [],
                    aggregateField: null,
                    aggregate_field_name: '',
                    aggregateType: ''
                },);
    const [isOpenSortFieldMenu, setIsOpenSortFieldMenu] = useState(false);
    const [isOpenOrderMenu, setIsOpenOrderMenu] = useState(false);
    const [isOpenAggregateFuncMenu, setIsOpenAggregateFuncMenu] = useState(false);

    useEffect(() => {
        if (id == 0 || data == null) {
            getFieldInfo();
        } else if (id > 0 && data != null) {
            processFieldData(data);
        }
      }, []);

    const updateParentState = () => {
        if (setParentState !== undefined) {
            setParentState({...fieldInfo});
        }
    }

    const getFieldInfo = () => {
        try {
            let params = {};

            params.query_name = '';
            params.query_id = 0;
            params.query_type = 'filter';
            params.object = object;
            var promise = Promise.resolve(HTTPClient.get(endPoints.FILTER.LIST_VIEW_SETUP_GET, params));
            if (ParameterVerifier.isValidParam(promise)) {
                promise.then((response) => {
                    processFieldData(response);
                });
            }
        } catch (e) { console.error("Error in 'userReportTabular.js -> getFieldInfo()':" + e); }
    }

const processFieldData = (data) => {
    let _fieldInfo =  fieldInfo;
    let selectedFields = data.selected_fields.map((field) => {
        return { value: field.id, label: field.label, name: field.name, width: field.width, title: field.label, id: field.id, type: field.type };
    });

    let availableFields = data.available_fields.map((field) => {
        return { value: field.id, label: field.label, name: field.name, width: field.width, title: field.label, id: field.id, type: field.type }
    });
    let aggregate_fields = [];
    data.selected_fields.forEach(function (element) {
        if (element.type == 'INTEGER' || element.type == 'BIGINT' || element.type == 'DECIMAL') {
            aggregate_fields.push(element);
        }
    });

    let sortField = null;
    let sortFieldId = null;
    let sortFieldLabel = null;
    let allFields = concat(availableFields, selectedFields);
    if (id === 0) {
        if (isValidParam(data.sort_field_id)) {
            sortField = find(allFields, function (f) { return f.id === data.sort_field_id });
            if(sortField){
                sortFieldLabel = sortField?.label;
                sortFieldId = sortField?.id;
            }else{
                sortField = selectedFields[0];
                sortFieldLabel = sortField?.label;
                sortFieldId = sortField?.id;
            }
        } else {
            sortField = selectedFields[0];
            sortFieldLabel = sortField?.label;
            sortFieldId = sortField?.id;
        }
    } else {
        sortField = find(allFields, function (f) { return f.id == data.sortField });
        sortFieldLabel = isValidParam(sortField) ? sortField.label : '';
        sortFieldId = sortField.id;
    }
    if (id > 0 || (id == 0 && data != null)) {
        if (isValidParam(data.aggregateField) && data.aggregateField != 0 && aggregate_fields.length > 0) {
            let aggregateField = find(allFields, function (f) { return f.id == data.aggregateField });
            _fieldInfo.aggregateField = data.aggregateField;
            _fieldInfo.aggregate_field_name = aggregateField.label;
            _fieldInfo.aggregateType = data.aggregateType;
        }
        _fieldInfo.sortType = (data.sortType == 1 || data.sortType == 'asc') ? 'asc' : 'desc';
    }
    _fieldInfo.available_fields = allFields;
    _fieldInfo.selected_fields = selectedFields;
    _fieldInfo.fieldsCount = data.selected_fields.length;
    _fieldInfo.fieldSelectorLoading = false;
    _fieldInfo.sortFieldLabel = sortFieldLabel;
    _fieldInfo.sortField = sortFieldId;
    _fieldInfo.sortOrderLabel = data.sort_type == 'desc' ? 'Descending' : 'Ascending';
    _fieldInfo.aggregate_fields = aggregate_fields;
    setIsMounted(true);
    setFieldInfo({..._fieldInfo});
     updateParentState();
   

}



    const sortFieldMenuChange = (value) => {
        try {
             let _fieldInfo = fieldInfo; 
            let sortFieldLabel = find(fieldInfo.selected_fields, function (f) { return f.id == value }).label;
            _fieldInfo.sortField = value;
            _fieldInfo.sortFieldLabel = sortFieldLabel;
            setFieldInfo({..._fieldInfo});
            setIsOpenSortFieldMenu(false);
            updateParentState();
        } catch (e) { console.error("Error in 'userReportTabular.js -> sortFieldMenuChange()':" + e); }
    }

    const orderMenuChange = (value) => {
        try {
            let tempFieldInfo = fieldInfo;
            tempFieldInfo.sortType = value;
            setFieldInfo({...tempFieldInfo});
            setIsOpenOrderMenu(false);
            updateParentState();
        } catch (e) { console.error("Error in 'userReportTabular.js -> orderMenuChange()':" + e); }
    }

    const aggregateFuncMenuChange = (value) => {
        try {
            let tempFieldInfo = fieldInfo;
            tempFieldInfo.aggregateType = value;
            setFieldInfo({...tempFieldInfo});
            setIsOpenAggregateFuncMenu(false);
            updateParentState();
        } catch (e) { console.error("Error in 'userReportTabular.js -> aggregateFuncMenuChange()':" + e); }
    }

    const aggregateFieldMenuChange = (field) => {
        try {
            let aggregateField = find(fieldInfo.aggregate_fields, function (f) { return f.id === field });
            let tempFieldInfo = fieldInfo;
            tempFieldInfo.aggregateField = aggregateField.id;
            tempFieldInfo.aggregate_field_name = aggregateField.label;
            setFieldInfo({...tempFieldInfo});
            setIsOpenAggregateFuncMenu(false);
            updateParentState();
        } catch (e) { console.error("Error in 'userReportTabular.js -> aggregateFieldMenuChange()':" + e); }
    }

   const handleSelectChange = (options) => {
        let totalSelectedFieldCount = fieldInfo.selected_fields.length;
        let isValid = false;
        let _fieldInfo = fieldInfo; 
        try {
            if (isValidParam(options) && options.length == 0) {
                _fieldInfo.aggregate_fields.length = 0
                _fieldInfo.aggregateType = '';
                _fieldInfo.aggregateField = null;
                _fieldInfo.aggregate_field_name = '';
              
            }

            if (isValidParam(options) && options.length <= totalSelectedFieldCount) {
                isValid = true;
            } else {
                if (isValidParam(options) && options.length <= 15) {
                    isValid = true;
                }
            }
            if (isValid) {
                let tempOptions = options.filter(f => { return f.value === fieldInfo.sortField });
                if (tempOptions.length == 0 && options.length > 0) {
                    _fieldInfo.sortField = options[0].id;
                    _fieldInfo.sortFieldLabel = options[0].label;
                }
                let arrSlectedObj = options.map((obj) => {
                    return {
                        name: obj.name,
                        label: obj.label,
                        value: obj.id,
                        width: obj.width,
                        title: obj.label,
                        id: obj.id,
                        type: obj.type
                    }
                });

                let aggregate_fields = [];
                arrSlectedObj.forEach(function (element) {
                    if (element.type == 'INTEGER' || element.type == 'BIGINT' || element.type == 'DECIMAL') {
                        aggregate_fields.push(element);
                    }
                });

                _fieldInfo.fieldsCount = options.length;
                _fieldInfo.selected_fields = arrSlectedObj;
                _fieldInfo.aggregate_fields = aggregate_fields;
                if (aggregate_fields.length > 0 && (id > 0 || data != null)) {
                    if (data.aggregateField !== 0) {
                        let aggField = aggregate_fields.find((el) => el.id == data.aggregateField);
                        if (!isValidParam(aggField)) {
                            _fieldInfo.aggregateField = null;
                            _fieldInfo.aggregate_field_name = '';
                        }
                    }
                }
                setFieldInfo({..._fieldInfo});
                updateParentState();
               
            } else {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.LIST_VIEW.SELECT_MAXIMUM_FIELD_FOR_SETUP, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        } catch (e) { console.error("Error in 'userReportTabular.js -> handleSelectChange()':" + e); }
    }

    const rearrangeSequence = (options) => {
        let tempFieldInfo = fieldInfo;
        if (options !== null && options !== undefined)
        tempFieldInfo.selected_fields = options;
        setFieldInfo({...tempFieldInfo});
        updateParentState()
    }
        let pageHeight = window.innerHeight - 247;
        let top = (pageHeight-10) / 2;
        let orderLabel = fieldInfo.sortType === 'asc' ? 'Ascending' : 'Descending';
        let aggregate_fields = getArrayParam(fieldInfo.aggregate_fields);
        return (
            <div>
                {!isMounted &&
                    <div style={{ width: '100%', height: pageHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
               </div>}
                {isMounted && <div id="selChildObject" style={{ marginLeft:'10px'}}>
                    <div style={{ fontSize: 14, marginBottom: 5, marginTop:'35px' }}>Report Columns (Required)</div>
                    <DraggableSelect
                        className={'multi-select-' + object}
                        loadingPlaceholder={getLocalizedStrings().label.COMMON.LOADING + "..."}
                        isClearable
                        value={fieldInfo.selected_fields}
                        options={fieldInfo.available_fields}
                        selectLimit ={15}        
                        limitMessage ={getLocalizedStrings().message.LIST_VIEW.SELECT_MAXIMUM_FIELD_FOR_SETUP} 
                        placeholder={getLocalizedStrings().label.LIST_VIEW.SELECT_FIELDS}
                        onChange={(options)=>handleSelectChange(options)}
                        onSortEnd={(options)=>rearrangeSequence(options)}
                        clearAllText={getLocalizedStrings().label.COMMON.CLEAR_ALL}
                        style={{ border: '1px solid rgb(221, 221, 221)', backgroundColor: '#fff', height: '42px', marginLeft: '10px', marginBottom: '20px', borderRadius: 0 }}
                    />

                {fieldInfo.selected_fields.length > 0 && <div style={{ display: 'flex', marginTop: '20px', width: '100%', overflow: 'hidden' }}>
                        <div style={{ display: 'flex', maxWidth: '300px', marginRight: '20px', paddingTop: '2px' }}>
                            <div style={{ float: 'left', color: '#717171', padding: '0px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>{getLocalizedStrings().label.LIST_VIEW.SORT_BY}:</div>
                            <div style={{ maxWidth: '70%', display: 'flex', float: 'right', padding: '0px', textAlign: 'left', paddingLeft: '6px' }}>
                                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={fieldInfo.sortFieldLabel} >
                                    {fieldInfo.sortFieldLabel}
                                </div>
                                <PopOver
                                    id={"sort_Fld"}
                                    key={"sort_Fld"}
                                    name={"sort_Fld"}
                                    buttonEndIcon={"keyboard_arrow_down"}
                                    buttonStyle={{ float: 'left', maxWidth: '60px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block' }}
                                    labelposition={"before"}
                                    buttonLabel={""}
                                    options={fieldInfo.selected_fields}
                                    onclickAction={(value) => sortFieldMenuChange(value)}
                                    isShowIcon={true}
                                    title={""}
                                    value={fieldInfo.sortField}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', maxWidth: '400px', marginRight: '20px', paddingTop: '2px' }}>
                            <div style={{ float: 'left', color: '#717171', padding: '0px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>{'Order'}:</div>
                            <div style={{ maxWidth: '100%', display: 'flex', float: 'right', padding: '0px', textAlign: 'left', paddingLeft: '6px' }}>
                                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={orderLabel} >
                                    {orderLabel}
                                </div>
                                <PopOver
                                    id={'status'}
                                    key={'status'}
                                    name={'status'}
                                    buttonEndIcon={"keyboard_arrow_down"}
                                    buttonStyle={{ float: 'left', maxWidth: '60px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block' }}
                                    labelposition={"before"}
                                    buttonLabel={""}
                                    options={popOverStatusOptions}
                                    onclickAction={(value) => orderMenuChange(value)}
                                    isShowIcon={true}
                                    title={""}
                                    value={fieldInfo.sortType}
                                />
                            </div>
                        </div>
                    </div>
                    }

                    {aggregate_fields.length > 0 && <div style={{ display: 'flex', marginTop: '20px', width: '100%', overflow: 'hidden' }}>
                        <div style={{ display: 'flex', maxWidth: '400px', marginRight: '20px', paddingTop: '2px' }}>
                            <div style={{ float: 'left', color: '#717171', padding: '0px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>Show:</div>
                            <div style={{ maxWidth: '100%', display: 'flex', float: 'right', padding: '0px', textAlign: 'left', paddingLeft: '6px' }}>
                                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={fieldInfo.aggregateType} >
                                    {fieldInfo.aggregateType}
                                </div>
                                <PopOver
                                    id={'agg_func'}
                                    key={'agg_func'}
                                    name={'agg_func'}
                                    buttonEndIcon={"keyboard_arrow_down"}
                                    buttonStyle={{ float: 'left', maxWidth: '60px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block' }}
                                    labelposition={"before"}
                                    buttonLabel={""}
                                    options={aggregate_options}
                                    onclickAction={(value) => aggregateFuncMenuChange(value)}
                                    isShowIcon={true}
                                    title={""}
                                    value={fieldInfo.aggregateType}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', maxWidth: '400px', marginRight: '20px', paddingTop: '2px' }}>
                            <div style={{ float: 'left', color: '#717171', padding: '0px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>For:</div>
                            <div style={{ maxWidth: '60%', display: 'flex', float: 'right', padding: '0px', textAlign: 'left', paddingLeft: '6px' }}>
                                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={fieldInfo.aggregate_field_name} >
                                    {fieldInfo.aggregate_field_name}
                                </div>
                                <PopOver
                                    id={'agg_fields'}
                                    key={'agg_fields'}
                                    name={'agg_fields'}
                                    buttonEndIcon={"keyboard_arrow_down"}
                                    buttonStyle={{ float: 'left', maxWidth: '60px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block' }}
                                    labelposition={"before"}
                                    buttonLabel={""}
                                    options={fieldInfo.aggregate_fields}
                                    onclickAction={(field) => aggregateFieldMenuChange(field)}
                                    isShowIcon={true}
                                    title={""}
                                    value={fieldInfo.aggregateField}
                                />
                            </div>
                        </div>
                    </div>
                    }
                </div>}
            </div>
        );
}

export default UserReportTabular;