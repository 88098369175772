import React, { useCallback, useRef} from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import { actions } from "../../../services/constants/actions";
import CloseIcon from '@mui/icons-material/Close';
import { closeWorkflowSetupDrawer, deleteDndRowCards, setisOpenEditWebhook, setisShowWebhook, updateDndCard } from "../../../services/actions/workflowSetupAction";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import * as sfDialogs from './sfDialogs';
import update from 'immutability-helper'
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import { styles } from '../../../services/constants/styles';

const WorkflowExternalWebhookCard = ({key,index,id,webhook,editWebhookTask}) =>{
    const dispatch = useDispatch();
    const { selectedStage,data } = useSelector((state) => state.workFlowSetup);
    const { webhooks } =  selectedStage;
    const ref = useRef(null)
    const [, drop] = useDrop({
      accept: actions.WEBHOOKS_CARD,
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        moveCard(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    });

    const moveCard = useCallback(
        (dragIndex, hoverIndex) => {
          const dragCard = webhooks[dragIndex]
          let rearrangeRows = update(webhooks, {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
              ],
            });
            dispatch(updateDndCard(rearrangeRows, 'webhooks'));
        },[dispatch, webhooks],
    )

    const deleteWebhookTask = (webhook) =>{
      if(data.edit_tasks === 1){
        let _errorMessage = 'This' + " " + webhook.type + " ";
        _errorMessage = _errorMessage + getLocalizedStrings().message.WORKFLOW_SETUP.DELETE_MSG_FOR_TASK;
        sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, _errorMessage, () => handleSfDialogConfirm(webhook), null);
      }else{
        let errorMessage;
        errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.DELETE_NOT_ALLOWED_MSG;
        errorMessage = errorMessage + " " + webhook?.type;
        dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
      }
    }

    const handleSfDialogConfirm =(webhook)=>{
      dispatch(deleteDndRowCards(webhook, 'webhooks'));
    }
    const [{ isDragging }, drag] = useDrag({
      type: actions.WEBHOOKS_CARD,
      item: {  id, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    });
    const opacity = isDragging ? 0 : 1;
    let ImageSource = 'https://testvpc.snapshotcrm.com/theme/blue/images/NewDesign/workflow-task.png';
    let status = webhook.details.status == 1?"Active":"Inactive";
    let titleText = webhook.details.name + " (" +status+ ")";
    let detailsText = "Endpoint: " + webhook.details.webhook_url + ".";
    
    drag(drop(ref));
    return (
        <>
            <div style={{marginTop:'3px',background:'white',display:'flex',width:'96%',cursor: 'grab'}} ref={ref}>
                <div style={{width:'3%',marginRight:'10px'}}><img style={{marginTop:'7px',marginLeft:'10px',marginRight:'10px',width:'26px'}} src={ImageSource} /></div>
                <div style={{display:'grid',width:'100%',textOverflow:'ellipsis',overflow:'hidden',paddingTop:'4px',paddingBottom:'1px',}}>
                    <span style={{fontSize:'15px',fontWeight:'bold',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',paddingBottom:'1px'}}>{titleText} </span>
                    <span style={{fontSize:'13px',paddingBottom:'5px',color:'rgb(98, 100, 100)',fontWeight:'normal',display:'inline-block',overflow:'hidden',textOverflow:'ellipsis'}}>{detailsText}</span>
                </div>
                <div style={{float:'right',alignItems:'right',alignSelf:'right',width:'10%',display:'flex',paddingTop:'10px',paddingLeft:'5%'}}>
                    <EditIcon title="edit" style={{fontSize:'18px',marginRight:'4px',color:'rgb(5, 138, 229)',cursor:'pointer'}} onClick={() => editWebhookTask(webhook)}/>
                    <CloseIcon title="close" style={{fontSize:'18px',color:'rgb(5, 138, 229)',cursor:'pointer'}} onClick={() => deleteWebhookTask(webhook)}/>
                </div>
            </div>
        </>
    )
}


export default WorkflowExternalWebhookCard;