import React, { useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { styles } from '../../../services/constants/styles';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { isValidParam, isInvalidParam, isFloat, isInteger, getArrayParam, getStringParam, getBooleanParam, getIntParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import AssignmentRuleCriteria from '../components/assignmentRuleCriteria';
import ShowCircularProgress from '../components/circularProgress';
import { getCacheFromFieldsWithPromise, getFieldListValuesPromise } from '../../../services/actions/sfFormActions';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import map from 'lodash/map';
import { Button, Select as SelectField, MenuItem, TextField, InputLabel, Chip } from '@mui/material';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { saveAssignmentRule, isAssignRuleExists, editAssignmentRule,setMounted } from '../../../services/actions/assignmentRuleActions';
import FormControl from '@mui/material/FormControl';
import { refreshListView } from '../../../services/actions/listViewAcions';
import moment from 'moment';
import { getUnits } from '../../../services/actions/unitsAction.js';
import { pushwflist } from '../../../services/actions/batchActions';
import Autocomplete from '@mui/material/Autocomplete';
import { TroubleshootSharp } from '@mui/icons-material';
import { connect } from 'react-redux';
import {
	getMyGroups,
} from "../../../services/actions/filterMenuAction";
import {getActiveTab, updateActiveTab} from '../../../services/helper/sfTabManager';

const mapStateToProps = (state) => {
    return {
        sfForm: state.sfForm,
        filters: state.filters,
        userlist: state.userlist,
        app: state.app
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAppDrawer: (isOpen, actionDialogName, actionType, data, callFrom) => {
            dispatch(getAppDrawer(isOpen, actionDialogName, actionType, data, callFrom));
        },
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
        setMounted: (isMounted) => {
            dispatch(setMounted(isMounted));
        },
    }
};

class AssignmentRuleSetup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            mounted: false,
            fromFieldData: {},
            name: '',
            selectedUsers: '',
            salesRepFieldName: getLocalizedStrings().label.COMMON.SALESREP,
            statusOpts: [
                { label: 'Active', value: 'Active' },
                { label: 'Inactive', value: 'Inactive' }
            ],
            tableNameOpts: [
                { label: 'Accounts', value: constants.ACCOUNTS_OBJECT },
                { label: 'Cases', value: constants.CASES_OBJECT },
                { label: 'Issues', value: constants.ISSUES_OBJECT },
                { label: 'Sales Leads', value: constants.SALES_LEADS_OBJECT },
            ],
            status: 'Active',
            table: constants.ACCOUNTS_OBJECT,
            filter: {},
            isSavedDisabled: false,
            isdisableTableField: false,
            workflowList: [],
            selectedWorkflow: 0
        }
    }

    componentDidMount() {
        let data = getObjectParam(this.props.data);
        let object = data.table;
        if (object == constants.EMAIL_MARKETING) {
            object = constants.CONTACTS_OBJECT;
        }
        if(object == 'accounts'){
            let promise = Promise.resolve(getMyGroups(object));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                       let groups=response.groups;  
                        this.getFromFieldData(object, groups);
      
                    }
                });
            
        }        this.getUnitList();
        }
      else{
        this.getFromFieldData(object);
        this.getUnitList();
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let dataObj_1 = getObjectParam(this.props.data);
        let dataObj_2 = getObjectParam(nextProps.data);
        if (this.props.refreshSetup != nextProps.refreshSetup) {
            let object = dataObj_2.table;

            if (dataObj_2.mode == constants.NEW) {
                this.state.filter = {};
                this.state.status = 'Active'
                this.state.selectedUsers = '';
                this.state.name = '';

            }
            if(object == 'accounts'){
                let promise = Promise.resolve(getMyGroups(object));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                           let groups=response.groups;  
                            this.getFromFieldData(object, groups);
          
                        }
                    });
                
            }
            }else{
                this.getFromFieldData(object);

            }
          
        }
    }

    getFromFieldData = (object, groupList) => {
        let fromFieldData = this.props.sfForm.data[object];
        if (isValidParam(fromFieldData)) {
            fromFieldData = JSON.parse(JSON.stringify(fromFieldData));
            if (object == constants.ACCOUNTS_OBJECT || object == constants.CONTACTS_OBJECT) {
                let groups = [];
                if (isValidParam(this.props.filters.groups) &&  this.props.filters.groups.length > 0) {
                    groups = Object.assign([], getArrayParam(this.props.filters.groups));
                    groups = groups.filter(f => { return f.status == 'Active' });
                }else if(groupList!==undefined && groupList.length > 0){

                    groups = Object.assign([], getArrayParam(groupList));
                    groups = groups.filter(f => { return f.status == 'Active' });  
                    
                }
                
                let groupsArray = [];
                groups.map((groupObject, index) => {
                    groupsArray.push({
                        "id": groupObject.name,
                        "value": groupObject.name

                    });
                });

                let fields = getArrayParam(fromFieldData.fields);
                fields.map((field, index) => {
                    if (field.field_type == constants.FIELD_TYPE_LIST) {
                        let multiSelectList = [];
                        for (let x = 0; x < field.list_values.length; x++) {
                            if (field.list_values[x].value != "") {
                                multiSelectList.push(field.list_values[x]);
                            }
                        }
                        field.list_values = multiSelectList;
                    }
                });

                fromFieldData.fields = fields;
                fromFieldData.fields.push({
                    id: -9999,
                    name: "Groups",
                    label: getLocalizedStrings().label.HOME.GROUPS,//"Groups",
                    field_type: "list",
                    field_length: 0,
                    default_value: "",
                    t_tablefldid: -9999,
                    list_type: "regular",
                    list_values: groupsArray
                });
            }

            this.state.fromFieldData = fromFieldData;
            this.state.mounted = true;
            this.state.table = object;

            if (this.props.data.mode == 'edit' || this.props.data.mode == 'copy') {

                this.loadFilterData(this.props.data.id);
            } else {
                this.setState({});
            }
        } else {
            var promise = getCacheFromFieldsWithPromise(object);
            promise.then((response) => {
                this.getFromFieldData(object);
            });
        }
    }

    handleChangeTextField = (event) => {
        let target = event.target;
        let value = target.value;
        let name = target.name;
        this.state.name = value;
        this.setState(this.state);
    }

    loadFilterData = (id) => {
        var response = Promise.resolve(editAssignmentRule(id));
        response.then((response) => {
            let filterObject = {};
            filterObject.id = id;
            filterObject.t_formid = response.t_formid;
            this.state.name = this.props.data.mode == 'copy' ? ` Copy_of_${response.t_name}` : response.t_name;
            this.state.status = response.t_status;
            this.state.selectedUsers = response.t_userid+"";
            let sqlArray = response.sql_array;
            let fieldContainer = {};
            let filterIdArray = [];
            let i = 0;
            if (isValidParam(response.t_unit_workflow) && response.hasOwnProperty('t_unit_workflow')) {
                this.getWFList(getIntParam(response.t_userid), true);
                this.state.selectedWorkflow = response.t_unit_workflow;
            }
            for (i = 0; i < sqlArray.length; i++) {
                let savedConditon = sqlArray[i];
                let tableFiledId = savedConditon.t_fldid;
                if (fieldContainer.hasOwnProperty(tableFiledId)) {
                    let field = fieldContainer[tableFiledId];
                    let condition = {};
                    condition.t_logicalop = savedConditon.t_logicalop;
                    condition.t_operator = savedConditon.t_operator;

                    condition.t_value = savedConditon.t_value;
                    condition.conditionFieldProps = {
                        isOpenPopover: false,
                        popoverTarget: null,
                        isShowConditionValue: true
                    }
                    let conditionArray = field.conditions;
                    let selectFieldProps = field.filedProps;

                    condition = this.processConditionValue(condition, selectFieldProps);
                    conditionArray.push(condition);
                    fieldContainer[tableFiledId] = field;

                } else {
                    let field = {};
                    let selectFieldProps = this.getFiledPropsByFieldId(tableFiledId, filterObject.t_formid.toLowerCase());
                    field.t_fldname = selectFieldProps.label;
                    field.t_fldid = selectFieldProps.t_tablefldid;

                    let condition = {};
                    condition.t_logicalop = savedConditon.t_logicalop;
                    condition.t_operator = savedConditon.t_operator;
                    condition.t_value = savedConditon.t_value;
                    condition.conditionFieldProps = {
                        isOpenPopover: false,
                        popoverTarget: null,
                        isShowConditionValue: true
                    }

                    condition = this.processConditionValue(condition, selectFieldProps);
                    let conditionArray = [];
                    conditionArray.push(condition);
                    field.conditions = conditionArray;
                    field.filedProps = selectFieldProps;

                    fieldContainer[tableFiledId] = field;
                    filterIdArray.push(tableFiledId);
                }
            }

            let filterSqlArray = [];
            for (let i = 0; i < filterIdArray.length; i++) {
                filterSqlArray.push(fieldContainer[filterIdArray[i]])
            }
            filterObject.sql_array = filterSqlArray;
            this.setState({ filter: filterObject });
            for (let i = 0; i < filterSqlArray.length; i++) {
                if (filterSqlArray[i].filedProps.name == 't_status') {
                    let params = new Object();
                    params.object = filterObject.t_formid.toLowerCase();
                    params.name = filterSqlArray[i].filedProps.name;
                    params.list_type = constants.LIST_FIELD_TYPE_REGULAR;
                    let promise = getFieldListValuesPromise(params);
                    promise.then((response) => {
                        response = getArrayParam(response);
                        if (response.length > 0) {
                            sortArrayObjectByProperty(response, 'value');
                            filterSqlArray[i].filedProps.list_values = response;

                            this.setState({ filter: filterObject });
                        }
                    });
                }
            }
        });
    }

    processConditionValue = (condition, fileldProps) => {
        let fieldType = fileldProps.field_type;
        let operator = condition.t_operator;
        if (fieldType == constants.FIELD_TYPE_DATE || fieldType == constants.FIELD_TYPE_DATE_TIME) {
            condition.t_value = condition.t_value.replace(/'/g, '');
        } else if (!(fieldType == constants.FIELD_TYPE_INTEGER || fieldType == constants.FIELD_TYPE_FLOAT || fieldType == constants.FIELD_TYPE_DATE_TIME || fieldType == constants.FIELD_TYPE_DATE)) {
            if (operator != "IS_NULL") {

                condition.t_value = condition.t_value.substr(1).slice(0, -1);
            }
        } else if (condition.t_value.charAt(0) == "'" && condition.t_value.charAt(condition.t_value.length - 1) == "'") {
            condition.t_value = condition.t_value.substr(1).slice(0, -1);
        }

        if (operator == "LIKE" || operator == "NOT_LIKE") {
            if (condition.t_value.charAt(0) == "%" && condition.t_value.charAt(condition.t_value.length - 1) == "%") {
                condition.t_value = condition.t_value.substr(1).slice(0, -1);
            }
        }
        if (condition.t_logicalop.indexOf("NOT") > -1) {

            if (operator == "=") {
                condition.t_operator = "NOT_EQUAL";
            } else if (operator == "LIKE" || operator == "BETWEEN") {
                condition.t_operator = "NOT_LIKE";
            }
            condition.t_logicalop = condition.t_logicalop.replace("NOT", "").trim();
        } else if (operator == "BETWEEN") {
            condition.t_operator = "LIKE";
        }

        if (operator == "IS") {
            condition.t_operator = "IS_NULL";
            condition.t_value = "";
            condition.conditionFieldProps.isShowConditionValue = false;
        }
        return condition;
    }

    getFiledPropsByFieldId = (fileldId, object) => {
        let selectFieldProps = {};
        let fromFieldData = this.state.fromFieldData;
        fromFieldData.fields.map((obj) => {
            if (obj.t_tablefldid == fileldId) {
                selectFieldProps = obj;
            }
        });
        return selectFieldProps;
    }

    getWFList = (value, isChanged) => {
        let params = {
            myclient_id: getIntParam(value)
        }
        let WFList = []
        var response = pushwflist(this.state.object, params);
        response.then((response) => {
            if (isValidParam(response)) {
                getArrayParam(response).map((item, i) => {
                    let obj = new Object();
                    obj.id = item.id;
                    obj.value = item.id;
                    obj.label = item.t_name;
                    obj.title = item.t_name;
                    WFList.push(obj);
                });
                if (isValidParam(getArrayParam(WFList)) && getArrayParam(WFList).length > 0) {
                    if (this.props.data.mode == 'edit'|| this.props.data.mode == 'copy' && isValidParam(isChanged) ? isChanged : false) {
                        this.setState({ workflowList: WFList, selectedWorkflow: this.state.selectedWorkflow });
                    } else {
                        this.setState({ selectedWorkflow: WFList[0].id, workflowList: WFList });
                    }
                }
            }
        });
    }

    handleUnitWorkflowChange = (event, value) => {
        try {
            this.setState({ selectedWorkflow: value.value });
        } catch (error) {
            console.error("error in assignmentRuleSetup.js -> 'handleUnitWorkflowChange()'" + error)
        }
    }

    handleUserChange = (event, arr) => {
        if(this.state.table == constants.SALES_LEADS_OBJECT){
            let arr1 = new Array(); 
            arr1.push(arr);

            arr = arr1;
        }
        let value = '';
        for (var i = 0; i < arr.length; i++) {
            if(value != ''){
                value =  value + "," + arr[i].value;
            } else {
                value = arr[i].value;
            }
        }
        if (this.state.table == constants.SALES_LEADS_OBJECT) {
            let isChanged = true;
            if (this.props.data.mode == 'edit' || this.props.data.mode == 'copy') {
                isChanged = false;
            }
            this.getWFList(value, isChanged);
        }
        this.setState({ selectedUsers: value });
    }

    handleStatusChange = (event) => {
        let value = event.target.value;
        this.setState({ status: value });
    }

    getUnitList = () => {
        let params = new Object();
        params.sort_field_name = 'company';
        params.sort_type = 'asc';
        let promise = getUnits(params);
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response) && response.hasOwnProperty('records')) {
                    this.setState({ unitUserList: getArrayParam(response.records) });
                }
            });
        }
    }

    handleTableNameChange = (event) => {
        let value = event.target.value;
        this.getFromFieldData(value);
        this.setState({ table: value, isDisabledUserField: false });
        if (value == constants.SALES_LEADS_OBJECT) {
            this.state.selectedUsers = '';
        }
    }

    setCriteriaInfo = (criteria) => {
        this.state.filter = criteria;
        if (isValidParam(criteria.sql_array[0].conditions)) {
            if (getArrayParam(criteria.sql_array[0].conditions).length > 0) {
                this.setState({ isdisableTableField: true });
            } else {
                this.setState({ isdisableTableField: false });
            }
        }
    }

    closeSetupSec = () => {
        if (this.props.object == constants.ASSIGNMENT_RULES_OBJECT) {
            this.props.getAppDrawer(false, null, null, null, null);
        } else {
            this.props.closeSetup();
        }
    }

    validate = () => {
        let name = this.state.name.trim();
        let criteria = this.state.filter;
        let userIds = this.state.selectedUsers;
        if (isInvalidParam(name)) {
            this.props.showCustomSnackBar(getLocalizedStrings().message.ASSIGNMENT_RULES.NO_NAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            return false;
        } else if (name.length > 64) {
            this.props.showCustomSnackBar(getLocalizedStrings().message.ASSIGNMENT_RULES.NAME_MAX_LENGTH, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            return false;
        } else if (!isValidParam(criteria.sql_array) || criteria.sql_array.length == 0) {
            this.props.showCustomSnackBar(getLocalizedStrings().message.ASSIGNMENT_RULES.NO_CRITERIA, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            return false;
        } else if (!isValidParam(userIds) || userIds.length == 0) {
            if (this.state.table == constants.SALES_LEADS_OBJECT) {
                this.props.showCustomSnackBar(getLocalizedStrings().message.ASSIGNMENT_RULES.NO_UNIT_USER, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                return false;
            } else {
                this.props.showCustomSnackBar(getLocalizedStrings().message.ASSIGNMENT_RULES.NO_USER, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                return false;
            }
        }

        let error = false;
        let errorType = '';
        let errorFieldName = '';
        let errorFieldValue = '';
        criteria.sql_array.forEach((field) => {

            if (error) return;

            field.conditions.forEach((condition) => {
                let fieldName = field.t_fldname;
                let filedProps = field.filedProps;
                let operator = condition.t_operator;
                let value = condition.t_value;
                let fieldType = filedProps.field_type;
                if (operator != "IS_NULL") {
                    if (fieldType == constants.FIELD_TYPE_DATE_TIME || fieldType == constants.FIELD_TYPE_DATE) {
                        if (isValidParam(value) && value.trim().length == 0) {
                            error = true;
                            errorType = "NO_VALUE";
                            errorFieldName = fieldName;
                            errorFieldValue = value;
                            return false;
                        } else if ((operator == "LIKE" || operator == "NOT_LIKE") && (value.indexOf("CURDATE") >= 0 || value.indexOf("CURWEEK") >= 0 || value.indexOf("CURMONTH") >= 0)) {
                            error = true;
                            errorType = "NO_VALUE";
                            errorFieldName = fieldName;
                            errorFieldValue = value;
                            return false
                        }
                    } else if (isValidParam(value) && value.trim().length == 0) {
                        error = true;
                        errorType = "NO_VALUE";
                        errorFieldName = fieldName;
                        errorFieldValue = value;
                        return false
                    } if (fieldType == constants.FIELD_TYPE_INTEGER || fieldType == constants.FIELD_TYPE_FLOAT) {
                        if (fieldType == constants.FIELD_TYPE_INTEGER && !isInteger(value)) {
                            error = true;
                            errorType = "INT";
                            errorFieldName = fieldName;
                            errorFieldValue = value;
                            return false
                        }
                        if (fieldType == constants.FIELD_TYPE_FLOAT && !isFloat(value)) {
                            error = true;
                            errorType = "FLOAT";
                            errorFieldName = fieldName;
                            errorFieldValue = value;
                            return false
                        }
                    }
                }
            });
        });
        if (error) {
            if (errorType == "NO_VALUE") {
                this.props.showCustomSnackBar(errorFieldName + getLocalizedStrings().message.HOME.FILTER_SQL_ERROR_NO_VALUE, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                return false;
            } else if (errorType == "INT") {
                this.props.showCustomSnackBar(errorFieldName + ' - ' + errorFieldValue + getLocalizedStrings().message.HOME.FILTER_SQL_ERROR_INTEGER, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                return false;
            } else if (errorType == "FLOAT") {
                this.props.showCustomSnackBar(errorFieldName + '  -  ' + errorFieldValue + getLocalizedStrings().message.HOME.FILTER_SQL_ERROR_FLOAT, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                return false;
            }
            return false;
        } else {
            return true;
        }

    }

    saveAssignmentRuleSetup = () => {
        let criteria = this.state.filter;
        let status = this.state.status;
        let userIds = this.state.selectedUsers;
        let sql_arr = [];
        let filed_cnt = 0;
        if (this.validate()) {
            criteria.sql_array.forEach(function (field) {
                filed_cnt++;
                let condition_cnt = 0;
                field.conditions.forEach(function (condition) {

                    let fieldName = field.t_fldname;
                    let filedProps = field.filedProps;
                    let fieldType = filedProps.field_type;
                    let operator = condition.t_operator;
                    let value = condition.t_value;

                    let temp_condition = {};
                    temp_condition.t_fldid = field.t_fldid;
                    temp_condition.t_operator = condition.t_operator;
                    temp_condition.t_value = condition.t_value;
                    temp_condition.t_logicalop = condition.t_logicalop;
                    condition_cnt++;

                    if (condition_cnt == 1 && field.conditions.length > 1) {
                        temp_condition.is_start = true;
                        temp_condition.is_end = false;
                    } else if (condition_cnt == 1 && field.conditions.length == 1) {
                        temp_condition.is_start = true;
                        temp_condition.is_end = true;
                    } else if (condition_cnt > 1 && condition_cnt != field.conditions.length) {
                        temp_condition.is_start = false;
                        temp_condition.is_end = false;
                    } else if (condition_cnt > 1 && condition_cnt == field.conditions.length) {
                        temp_condition.is_start = false;
                        temp_condition.is_end = true;
                    }
                    if (fieldType == constants.FIELD_TYPE_DATE_TIME || fieldType == constants.FIELD_TYPE_DATE) {
                        if (isValidParam(condition.t_value)) {
                            if (condition.t_operator == 'LIKE' || condition.t_operator == 'NOT_LIKE') {
                                let splitDate = condition.t_value.split('AND');

                                let fromDate = moment(splitDate[0].trim());
                                fromDate = isValidParam(fromDate) ? fromDate.format("YYYY-MM-DD HH:mm") : '';
                                let toDate = moment(splitDate[1].trim());
                                toDate = isValidParam(toDate) ? toDate.format("YYYY-MM-DD HH:mm") : '';

                                temp_condition.t_value = fromDate != "" && toDate != "" ? "'" + fromDate + "' AND '" + toDate + "'" : "";

                            } else if (condition.t_value == 'CURDATE()' || condition.t_value == 'CURWEEK()' || condition.t_value == 'CURMONTH()') {
                                //by pass
                            } else {
                                let dateFormat = 'YYYY-MM-DD';
                                if (condition.t_operator == '>' || condition.t_operator == '>=' || condition.t_operator == '<') {
                                    dateFormat = 'YYYY-MM-DD HH:mm';
                                }
                                let dateValue = moment(condition.t_value);
                                if (isValidParam(dateValue)) {
                                    temp_condition.t_value = "'" + dateValue.format(dateFormat) + "'";
                                } else {
                                    temp_condition.t_value = '';
                                }
                            }
                        }
                    } else {
                        if (operator == "LIKE" || operator == "NOT_LIKE") {
                            temp_condition.t_value = '\'%' + condition.t_value + '%\''
                        } else if (fieldType == constants.FIELD_TYPE_INTEGER || fieldType == constants.FIELD_TYPE_FLOAT) {
                            temp_condition.t_value = condition.t_value;
                        } else {
                            temp_condition.t_value = '\'' + condition.t_value + '\'';
                        }
                    }

                    // Not logical operator 
                    if (operator == "NOT_EQUAL" || operator == "NOT_LIKE") {
                        if ((fieldType == constants.FIELD_TYPE_DATE || fieldType == constants.FIELD_TYPE_DATE_TIME) && operator == "NOT_LIKE") {
                            temp_condition.t_operator = "BETWEEN";
                        } else if (operator == "NOT_EQUAL") {
                            temp_condition.t_operator = "=";
                        } else if (operator == "NOT_LIKE") {
                            temp_condition.t_operator = "LIKE";
                        }
                        temp_condition.t_logicalop = condition.t_logicalop + ' ' + 'NOT'
                    } else if (operator == "LIKE") {
                        if (fieldType == constants.FIELD_TYPE_DATE || fieldType == constants.FIELD_TYPE_DATE_TIME) {
                            temp_condition.t_operator = "BETWEEN";
                        }
                    }
                    if (operator == "IS_NULL") {
                        temp_condition.t_operator = "IS";
                        temp_condition.t_value = "NULL";
                    }

                    sql_arr.push(temp_condition)

                });
            });

            let paramObj = {};

            paramObj.objectName = this.state.table;
            paramObj.t_name = this.state.name.trim();
            paramObj.status = status;
            paramObj.sql_array = sql_arr;
            paramObj.user_ids = userIds;

            var id = 0;
            if (isValidParam(this.props.data.id)) {
                id = getIntParam(this.props.data.id);
                if (id > 0  &&  this.props.data.mode == 'edit') {
                    paramObj.id = id 
                }
            }
            this.saveRule(paramObj);
        }
    }

    saveRule = (paramObj) => {
        if (this.state.table == constants.SALES_LEADS_OBJECT) {
            if (this.state.selectedWorkflow ==  0) {
                this.props.showCustomSnackBar(getLocalizedStrings().message.ASSIGNMENT_RULES.SELECT_A_UNIT_WORKFLOW, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                return false;
            } else {
                Object.assign(paramObj, { unit_worklow_id: this.state.selectedWorkflow });
            }
        }
        this.setState({ isSavedDisabled: true });
        var response = Promise.resolve(saveAssignmentRule(paramObj));
        response.then((response) => {
            if (response.status == 0) {
                this.props.showCustomSnackBar(getLocalizedStrings().message.ASSIGNMENT_RULES.RULE_SAVE_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                this.setState({});
                if (this.props.object == constants.ASSIGNMENT_RULES_OBJECT) {
                    this.props.getAppDrawer(false, null, null, null, null);
                    let tab = getActiveTab();
                    // tab.info = { ...tab.info, groupByCondition: "All" };
                    delete tab.info.startIndex;
                    delete tab.info.pageSize;
                    delete tab.info.selectedPageNo;
                    tab.info = { ...tab.info, rememberSelection: { ...tab.info.rememberSelection ?? {}, [this.props.object]: new Set()} };
                    updateActiveTab(tab);
                    refreshListView(this.props.object);
                } else {
                    this.props.refreshDataTable();
                    this.props.addRule();
                }

            } else if (response.status == -1 && response.error.message != "") {
                if (response.error.message == 'Name already exists!') {
                    this.props.showCustomSnackBar(getLocalizedStrings().message.ASSIGNMENT_RULES.RULE_NAME_EXISTS, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                } else if (response.error.message == 'Active rules exceeds!') {
                    this.props.showCustomSnackBar(getLocalizedStrings().message.ASSIGNMENT_RULES.ACTIVE_RULE_EXCEEDS, styles.snackbarBodyStyleError, styles.snackBarStyleTop);

                } else {
                    this.props.showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                }
            }
            this.setState({ isSavedDisabled: false });
        });
    }

    render() {
        var userlist = this.props.userlist.data;
        let userObj = null;
        var options = new Array();
        let selectedUserObj = new Object();
        let selectedUserObjArr = new Array();
        let selectLabel = getLocalizedStrings().label.COMMON.SELECT_A + this.state.salesRepFieldName;
        if (this.state.table == constants.SALES_LEADS_OBJECT) {
            selectLabel = getLocalizedStrings().label.COMMON.SELECT_UNIT_NAME;
            let unitUserList = this.state.unitUserList;
            if (isValidParam(unitUserList)) {
                
                unitUserList = unitUserList.map(userObject => {
                   
                        userObj = new Object();
                        userObj.value = userObject.client_project_id.toString();
                        userObj.label = userObject.company;
                        userObj.title = userObject.company;
                        options.push(userObj);
                    
                });
            }
        } else {
            let tempArr = new Array();
            if(this.state.selectedUsers != null && this.state.selectedUsers != ''){
                tempArr =  this.state.selectedUsers.split(',');
            }
            map(userlist, function (userObject, i) {
                if(tempArr.indexOf(userObject.id.toString()) > -1){

                    selectedUserObj = new Object();
                    selectedUserObj.value = userObject.id.toString();
                    selectedUserObj.label = userObject.value;
                    selectedUserObj.title = userObject.value;
                    selectedUserObjArr.push(selectedUserObj);

                 
                } else {
                    userObj = new Object();
                    userObj.value = userObject.id.toString();
                    userObj.label = userObject.value;
                    userObj.title = userObject.value;
                    options.push(userObj);
                }
            });
        }
        sortArrayObjectByProperty(options, 'label');
        if(getArrayParam(selectedUserObjArr).length > 0){
            sortArrayObjectByProperty(selectedUserObjArr, 'label');
        }

        let statusArr = this.state.statusOpts.map((r, index) => {
            let label = r.label;
            return <MenuItem key={r.value} value={r.value} style={styles.popoverMenuItem} >{<div title={label} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }}>{label}</div>}</MenuItem>
        });
        let tableNameArr = this.state.tableNameOpts.map((r, index) => {
            let objectList = this.props.app.objectList;
            let label = r.label;
            if (isValidParam(objectList) && getArrayParam(objectList).length > 0) {
                objectList = objectList.filter(f => f.name == r.value);
                if (objectList.length > 0) {
                    label = objectList[0].label;
                }
            }
            return <MenuItem key={r.value} value={r.value} style={styles.popoverMenuItem} >{<div title={label} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }}>{label}</div>}</MenuItem>
        });
        let data = new Object();
        data.table = this.state.table;
        data.callFrom = 'Assignment Rules';
        data.mode = 'New';
        if (this.props.data.mode == 'edit') {
            data.mode = 'edit';
            data.id = this.props.data.id;
            this.state.isdisableTableField = true;
            delete data.callFrom;
        }else if (this.props.data.mode == 'copy') {
            data.mode = 'copy';
            data.id = this.props.data.id;
            this.state.isdisableTableField = true;
            delete data.callFrom;
        }
        let showUnitWorkflow = false;
        if (isValidParam(this.state.selectedUsers) && getStringParam(this.state.selectedUsers) != '') {
            if ((this.state.table == constants.SALES_LEADS_OBJECT && getArrayParam(getStringParam(this.state.selectedUsers).split(',')).length == 1)) {
                showUnitWorkflow = true;
            }
        }
        let uKey = Math.random();
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        return (
            <div>
                {this.state.mounted ?
                    <div style={{marginBottom: '50px'}}>
                        <div className="row">
                            <div className="col-sm-12">
                                <div style={{ textAlign: 'right', paddingLeft: '19px', paddingRight: '25px', paddingBottom: '10px' }}>
                                    <Button
                                        key='save'
                                        onClick={this.saveAssignmentRuleSetup}
                                        style={{ ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top' }}
                                        disabled={this.state.isSavedDisabled}
                                    >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                                    <Button
                                        onClick={this.closeSetupSec}
                                        style={{ ...styles.secondaryButton, marginLeft: '6px', marginRight: '0px' }}
                                    >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div style={{ paddingLeft: '19px', paddingRight: '26px', paddingBottom: '23px' }}>
                                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                        <TextField
                                            variant="outlined"
                                            label={getLocalizedStrings().label.ASSIGNMENT_RULES.NAME_REQUIRED}
                                            fullWidth={true}
                                            name="name"
                                            value={this.state.name}
                                            size="small"
                                            onChange={this.handleChangeTextField}
                                            inputStyle={{ marginTop: '6px', color: '#333', fontSize: '15px', fontWeight: 'normal' }}
                                            autoComplete="new-password"
                                            className={"sf-fields-bg"}
                                            margin='dense'
                                            autoFocus
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div style={{ paddingLeft: '19px', paddingRight: '10px', paddingBottom: '10px' }}>
                                    <FormControl style={{ width: '100%' }} variant="outlined">
                                        <InputLabel id="sf-assignment-rule-setup-from-assignmentrulesetup-simple-select-outlined-label" className="sf-assignment-rule-setup-from-assignmentrulesetups">{getLocalizedStrings().label.ASSIGNMENT_RULES.STATUS}</InputLabel>
                                        <SelectField 
                                            variant="outlined"
                                            key="status"
                                            label={getLocalizedStrings().label.ASSIGNMENT_RULES.STATUS}
                                            labelId='sf-label-status'
                                            id='sf-status'
                                            value={this.state.status}
                                            onChange={this.handleStatusChange}
                                            name='status'
                                            style={{height:'40px'}}
                                            size="small"
                                            className={"sf-fields-bg"}
                                        >
                                            {statusArr}

                                        </SelectField>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div style={{ paddingLeft: '5px', paddingRight: '26px', paddingBottom: '10px' }}>
                                    <FormControl style={{ width: '100%' }} variant="outlined">
                                        <InputLabel id="sf-assignment-rule-setups-from-assignmentrulesetup-simple-select-outlined-label" className="sf-assignment-rule-setups-from-assignmentrulesetups">{getLocalizedStrings().label.ASSIGNMENT_RULES.TABLE_NAME}</InputLabel>
                                        <SelectField 
                                            variant="outlined"
                                            key="tableName"
                                            label={getLocalizedStrings().label.ASSIGNMENT_RULES.TABLE_NAME}
                                            labelId='sf-tableName'
                                            id='tableName'
                                            value={this.state.table}
                                            onChange={this.handleTableNameChange}
                                            name='tableName'
                                            className={"sf-fields-bg"}
                                            style={{height:'40px'}}
                                            size="small"
                                            disabled={this.state.isdisableTableField}
                                        >
                                            {tableNameArr}

                                        </SelectField>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div style={{ paddingLeft: '19px', paddingRight: '26px', paddingBottom: '10px' }}>
                                    <AssignmentRuleCriteria data={data}
                                        fromFieldData={this.state.fromFieldData}
                                        ref='filter'
                                        object={this.state.table}
                                        setCriteriaInfo={this.setCriteriaInfo}
                                        criteria={this.state.filter}
                                    />
                                </div>
                            </div>
                        </div>
                        {this.state.table == constants.SALES_LEADS_OBJECT ?
                            <div className="row" key={'user-row-' + uKey}>
                                <div className="col-sm-12">
                                    <Autocomplete
                                    key={'user-auto-' + uKey}
                                        name="user"
                                        id="user"
                                        options={options}
                                        onChange={this.handleUserChange}
                                        value={options.find(v =>  v.value == this.state.selectedUsers )}
                                        getOptionLabel={(option) => option.label}
                                        disableClearable={true}
                                        size="small"
                                        getOptionSelected={(option) => option.value === this.state.selectedUsers}
                                        renderInput={(params) => <TextField {...params}
                                            label={selectLabel}
                                            variant="outlined"  margin="dense" className={"sf-fields-bg"} />
                                        }
                                        style={{ width: '96%', marginLeft: '20px' }}
                                    />
                                </div>
                            </div> :
                            <div className="row">
                                <div className="col-sm-12">
                                 <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <Autocomplete
                                        multiple
                                        name="user"
                                        id="tags-standard"
                                        value={selectedUserObjArr}
                                        options={options}
                                        onChange={this.handleUserChange}
                                        getOptionLabel={(option) => option.label}
                                        disableClearable={true}
                                        size="small"
                                        renderInput={(params) => <TextField {...params}
                                            label={selectLabel}
                                            InputLabelProps={{
                                                style: {
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    width: '96%',
                                                }
                                            }}
                                            variant="outlined"  margin="dense" className={"sf-fields-bg"} />
                                        }
                                        style={{ width: '96%', marginLeft: '20px' }}
                                    />
                                </FormControl>
                                </div>
                            </div>}
                        {showUnitWorkflow && <div className="row" key={'wrkflow-row-' + uKey}>
                            <div className="col-sm-12">
                                <div style={{ paddingLeft: '19px', paddingRight: '26px', paddingBottom: '10px' }}>
                                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                        <Autocomplete
                                            name="uniworkflow"
                                            id="unitworkflow"
                                            key={"unitworkflow" + uKey} 
                                            options={this.state.workflowList}
                                            onChange={this.handleUnitWorkflowChange}
                                            value={this.state.workflowList.find(v => (this.state.selectedWorkflow != 0 ? v.value == this.state.selectedWorkflow : v.value[0]))}
                                            getOptionLabel={(option) => option.label}
                                            disableClearable={true}
                                            size="small"
                                            getOptionSelected={(option) => option.value === this.state.selectedWorkflow}
                                            renderInput={(params) => <TextField {...params}
                                                label={getLocalizedStrings().label.ASSIGNMENT_RULES.SELECT_UNIT_WORKFLOW}
                                                variant="outlined"  margin="dense" className={"sf-fields-bg"} />
                                            }
                                            style={{ width: '100%'}}
                                        />
                                    </FormControl> 
                                </div>
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-sm-12">
                                <div style={{ textAlign: 'right', paddingLeft: '19px', paddingRight: '26px', paddingBottom: '10px', paddingTop: '10px'}}>
                                    <Button
                                        key='save'
                                        onClick={this.saveAssignmentRuleSetup}
                                        style={{ ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top' }}
                                        disabled={this.state.isSavedDisabled}
                                    >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                                    <Button
                                        onClick={this.closeSetupSec}
                                        style={{ ...styles.secondaryButton, marginLeft: '6px', marginRight: '0px' }}
                                    >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentRuleSetup);

