import React, { useState, useEffect } from 'react';
import { isValidParam, getStringParam, getIntParam,getArrayParam,getObjectParam ,getBooleanParam,makeValidAppUrl} from '../../../services/helper/parameterVerifier';
import { setHeader } from '../../../services/actions/headerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import {UpdateSalesAndRefundDetails} from '../../../services/actions/SalesAndRefundAction';
import { LinkToType } from '../../../services/constants/link';
import ShowCircularProgress from './circularProgress';
import SFDataTable from './sfDataTable';
import { getActiveTab, updateTabInfo } from '../../../services/helper/sfTabManager';
import { sortArrayObjectByProperty, dateFormat,sortArrayObjectByPropertyForCurrency } from '../../../services/helper/utils';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';

const SalesAndRefundDetails = () =>{
    const dispatch = useDispatch();
    const appState = useSelector((state) => state.app);
    const tabs = useSelector((state) => state.tab.tabs);
    const SalesAndRefundRxd =  useSelector((state) => state.salesAndRefundReport.salesAndRefundDetailscategory);

    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState({});
    const [columns, setColumns] = useState({});
    const [showReport, setShowReport] = useState(false);
    const [tabsData,setTabsData] = useState({})
    const [rowSort, setRowSort] = useState({
        isDescending: false,
        onSort: (fieldName, isDescending, rows)=> onSortFn (fieldName, isDescending, rows)
    });
    
  
   
  useEffect(()=>{
        let Tabinfo = tabs.filter((ele)=> ele.object === constants.SALES_AND_REFUND_DETAIL);
        Tabinfo = Tabinfo[0] ? Tabinfo[0]:[];
        dispatch(setHeader(Tabinfo?.label, Tabinfo?.label, null, false, true));
        if(Object.keys(SalesAndRefundRxd).length !== 0){
            setIsLoading(true);
            if (SalesAndRefundRxd.hasOwnProperty('rows')) {
                const selectedRows = SalesAndRefundRxd.rows;
                setRows(selectedRows);
            }
            if (SalesAndRefundRxd.hasOwnProperty('columns')) {
                const selectedColumns = SalesAndRefundRxd.columns;
                setColumns(selectedColumns);
            }
            setIsLoading(false);
            setShowReport(true);
        }  
        getReportFilter();

    },[])

    const getReportFilter =() =>{
        let temptabs = tabs;
        let Tabinfo = temptabs.filter((ele)=> ele.object === constants.SALES_AND_REFUND_DETAIL);
        Tabinfo = Tabinfo[0] ? Tabinfo[0]:{};
        setTabsData(Tabinfo);
        let params = {};
        let _period = Tabinfo.info.period;
        if(_period == 'Custom'){
            params.period = 'cust';
            let _fromDate = Tabinfo.info.from_date;
            _fromDate = moment(_fromDate)
            _fromDate = _fromDate.format('YYYY-MM-DD')  
            params.from_date = _fromDate;
            let toDate = Tabinfo.info.to_date;
            toDate = moment(toDate)
            toDate = toDate.format('YYYY-MM-DD')
            params.to_date = toDate;
        }else if(_period == 'Last Year'){
            params.period = 'ly';
        }else if(_period == 'Last Quarter'){
            params.period = 'lq';
        }else if(_period == 'Last month'){
            params.period = 'lm';
        }else if(_period == 'This Year'){
            params.period = 'ty';
        }else if(_period == 'This Quarter'){
            params.period = 'tq';
        }else if(_period == 'This Month'){
            params.period = 'tm';
        }
        
        params.project_id = Tabinfo.info.row.project_id;
        if(Tabinfo.columns.name == "total_sales"){
            params.total_sales = Tabinfo.info.row.total_sales;
            params.total_refunds = '';
        }else if(Tabinfo.columns.name == "total_refunds"){
            params.total_sales = '';
            params.total_refunds = Tabinfo.info.row.total_refunds;
        }
        params.show =  Tabinfo.info.show;
        setIsLoading(true);
        const promise = Promise.resolve(HTTPClient.get(endPoints.SALES_AND_REFUND_DETAILS.GET, params));
        if (promise) {
            promise.then(res => {
                let response = res;
                setRows(response.records);
                setColumns(response.fields);
                let data={
                    rows : response.records,
                    columns : response.fields
                }
                dispatch(UpdateSalesAndRefundDetails(data));
                setShowReport(true);
                setIsLoading(false);
                })
        }

    }

    function getNoRecordContent() {
        return (
            <div className="row" style={{ padding: '0px', display: "flex"}}>
                <h5 style={{ color: 'red' }}>{getLocalizedStrings().message.ROYALTY_REPORT_V2.NO_RECORDS}</h5>
            </div>
        )
    }

    const sortArrayObjectByPropertyDesc = (array, property) => {
        let temp;
        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];
                    val1 = val1 instanceof String ? val1.toLowerCase() : val1;
                    val2 = val2 instanceof String ? val2.toLowerCase() : val2;
                    if (val1 > val2) {
                        temp= -1;
                    } else if (val1 < val2) {
                        temp= 1;
                    } else {
                        temp= 0;
                    }
                }
                return temp;
            });
        }
    }
    const sortArrayObjectByPropertyDescForCurrency = (array, property, currency) => {
        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];

                    val1 = typeof val1 === "string" ? val1.toLowerCase() : val1;
                    val2 = typeof val2 === "string" ? val2.toLowerCase() : val2;

                    val1 = val1.replace(currency, '');
                    val1 = val1.replace(',', '');
                    val1 = val1.replace('.', '');
                    val1 = val1.trim();

                    val2 = val2.replace(currency, '');
                    val2 = val2.replace(',', '');
                    val2 = val2.replace('.', '');
                    val2 = val2.trim();

                    val1 = getIntParam(val1);
                    val2 = getIntParam(val2);

                    if (val1 > val2) {
                        return -1;
                    } else if (val1 < val2) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
        }
    }

    const onSortFn = (fieldName, isDescending, records) => {
         let _rowSort = rowSort;
         let _records;
            _records = getArrayParam(records);
        let sortFieldName = getStringParam(fieldName);
        try {
                let totalRec = _records.filter(f => {
                        if (f.due_dt === 'Total Sales' || f.type === 'Total Payment' || f.tax === 'Total Amount') {
                            return f;
                        }
                    });
                    let tempRecordArr = _records.filter(f => {
                        if (f.due_dt !== 'Total Sales' && f.type !== 'Total Payment' && f.tax !== 'Total Amount') {
                            return f;
                        }
                    });
               
             if (fieldName === 'total_sales' || fieldName === 'payment' || fieldName === 'grand_total' || fieldName === 'balance_due' || fieldName === 'amount') {
                if (getBooleanParam(_rowSort.isDescending)) {
                    sortArrayObjectByPropertyForCurrency(tempRecordArr, sortFieldName, appState.me.currency_symbol);
                    _rowSort.isDescending = false;
                }
                else {
                     sortArrayObjectByPropertyDescForCurrency(tempRecordArr, sortFieldName, appState.me.currency_symbol);
                    _rowSort.isDescending = true;
                }
            } else {
                if (getBooleanParam(_rowSort.isDescending)) {
                    sortArrayObjectByProperty(tempRecordArr, sortFieldName);
                    _rowSort.isDescending = false;
                }
                else {
                    sortArrayObjectByPropertyDesc(tempRecordArr, sortFieldName);
                    _rowSort.isDescending = true;
                }
            }
            // date logic
            if (fieldName === 'due_dt' || fieldName === 'invoice_dt' || fieldName === 'receipt_date' || fieldName === 'payment_dt') {
                tempRecordArr.sort(function (a, b) {
                    var dateA = new Date(a[fieldName]);
                    var dateB = new Date(b[fieldName]);
                    return dateA - dateB;
                });
                if (!getBooleanParam(isDescending)) {
                    _rowSort.isDescending = isDescending;
                } else {
                    _rowSort.isDescending = isDescending;
                    tempRecordArr = tempRecordArr.reverse();
                }
            }
          // end date logic
           
         if (tempRecordArr.length > 0) {
                if (totalRec.length > 0) {
                    tempRecordArr.push(totalRec[0]);
                 }
                _records = tempRecordArr;
            }
            cookieSetSortData(_rowSort.isDescending);
            _rowSort.sortFieldName = sortFieldName;
            setRowSort({ ..._rowSort })
           setRows(_records);
            
        }
        catch (error) {
            console.error("Error in 'SalesAndRefundDetails.js -> onSort()':" + error);
        }
    }

    const cookieSetSortData = (value) => {
        let userid = appState.me.id;
        let cVarName = "isDescending_" + userid;
        if (value) {
            document.cookie = cVarName + "=yes";
        } else {
            document.cookie = cVarName + "=no";
        }
    }

    const customEvent = (eventType, column, row, event) =>{

    }

    function excelGenerateBeforeHeaders(columnsLength) {
        let cells = '';
        let reportName = getStringParam(getLocalizedStrings().label.SALES_AND_REFUND_DETAILS_REPORT.SALES_AND_REFUND_DETAILS);

        cells = cells + '<tr><td style="text-align:center; font-weight:bold" colspan="' + (columnsLength) + '"> ' + reportName + ' </td></tr>';
        if (reportName.length > 0) {
            let temptabs = tabsData;
            if(temptabs){
                let fromDate = temptabs.info.from_date;
                fromDate = moment(fromDate);
                fromDate = fromDate.format('YYYY-MM-DD');
                let toDate = temptabs.info.to_date;
                toDate = moment(toDate);
                toDate = toDate.format('YYYY-MM-DD');
                let BasedItem = temptabs.info.show;
                cells = cells + '<tr><td style="text-align:center;">' + getLocalizedStrings().label.SALES_AND_REFUND_DETAILS_REPORT.START_DATE + ':</td>  <td style="text-align:center;">' + fromDate + ' </td>  <td style="text-align:center;">' + getLocalizedStrings().label.SALES_AND_REFUND.END_DATE + ':</td> <td style="text-align:center;">' + toDate + ' </td></tr>';
                cells = cells + '<tr><td style="text-align:center;">'+ getLocalizedStrings().label.SALES_AND_REFUND_DETAILS_REPORT.BASED_ON +':</td> <td style="text-align:center;">'+BasedItem+'</td></tr>';

            }
        }
        return cells;
    }

    function excelGenerateHeaders(columns) {
        let cells = '';
        columns.map(f => {
            if (f.is_currency) {
                cells = cells + '<th bgcolor="6A8CCB" style="text-align: right"> ' + f.label + ' </th>';
            } else {
                cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
            }
            
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    function excelGenerateRows(columns, rows) {
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            let colVal = '';
            rows.map((rowObject, rowIndex) => {
                let innerCells = '';
                columns.map((columnObject, colIndex) => {
                    colVal = isValidParam(rowObject[columnObject.name]) ? rowObject[columnObject.name] : '';
                    let align = 'left'
                    if (columnObject.is_currency) {
                        align = "right"
                    }
                    colVal = colVal.toString();

                    if (rowIndex === (rows.length - 1)) {
                        innerCells = innerCells + '<td  bgcolor="gray" style="color: white;font-weight:bold;text-align:' + align +'"><b>' + colVal + '</b></td>';
                    } else {
                        if (rowIndex % 2 === 0) {
                            innerCells = innerCells + '<td style="text-align:' + align +'">' + colVal + ' </td>';
                        } else {
                            innerCells = innerCells + '<td bgcolor="Lavender" style="text-align:' + align +'">' + colVal + ' </td>';
                        }
                    }
                })
                if (rowIndex % 2 === 0) {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                } else {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                }

            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
        }
        return rowData;

    }


    function replaceCurrencySymbols(htmldata) {
        htmldata = htmldata.replace(/€/gi, '&#x20AC;');
        htmldata = htmldata.replace(/￦/gi, '&#x20A9;');
        htmldata = htmldata.replace(/£/gi, '&#xa3;');
        htmldata = htmldata.replace(/₺/gi, '&#x20BA;');
        htmldata = htmldata.replace(/￥/gi, '&#xa5;');
        htmldata = htmldata.replace(/руб./gi, '&#x440;' + '&#x443;' + '&#x0431;' + ".");
        return htmldata;
    }

    function downloadExcel(){
        let _columns = columns.filter(f => !f.is_hidden);
        let columnsLength = columns.length;
        if (Object.keys(rows).length > 0) {
            let beforeHeaderComponent = excelGenerateBeforeHeaders(columnsLength);
            let htmlContent = '<table border="1">' + beforeHeaderComponent;
            let headerComponents = excelGenerateHeaders(_columns);
            let rowComponent = excelGenerateRows(_columns, rows);
            htmlContent += '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponent + '</tbody>';
            htmlContent += '</table>';
            let htmlData = htmlContent;
            if (isValidParam(htmlData) && htmlData !== '') {
                htmlData = htmlData.replace("%22", "\"");
                htmlData = replaceCurrencySymbols(htmlData);
                var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
                var downloadUrl = URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = downloadUrl;
                a.download = 'SalesAndRefundDetailsSet_' + new Date().getTime() + '.xls';
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        } else {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.NO_RECORD_EXPORT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
    }

    const getReportView = () =>{
        let view = [];
        let _rows = rows;
        let _columns= columns;
        if (!_rows || Object.keys(_rows).length === 0) {
            return getNoRecordContent();
        }else{
             view.push(<div>
                <div style={{float:'right',marginBottom:'0.5rem', marginRight:'1.1rem'}}>
                    <i onClick={() => { downloadExcel(); }} className="far fa-file-excel" title="Excel" style={{ fontSize: '21px', color: 'green', cursor: 'pointer', paddingTop: '3px',marginLeft:'10px' }} />
                </div>
                <div id="divcontents" key={"divcontents" + rowSort.isDescending + "-" + rowSort.sortFieldName}>
                    <SFDataTable columns={_columns} rowSort={rowSort} rows={_rows} customEvent={(eventType, column, row, event) => customEvent(eventType, column, row, event)} />
                </div> </div>)

            return view;
        }
    }
    
    let tempStyle = { marginLeft: 42, width: '96%', paddingLeft: 11};
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2
    return(
        <div style={tempStyle}>
            <div style={{ marginBottom: '1.5rem',marginTop:'15px',marginLeft:'-14px' }}>
                {
                    showReport && (
                       !isLoading ? getReportView() : ( 
                            <div style={{ width: '100%', height: contentHeight }}>
                                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }
            </div>
        </div>
    )
}
export default SalesAndRefundDetails;