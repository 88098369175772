import * as HTTPClient from '..//helper/httpClient';
import {endPoints } from '..//constants/endPoints';
import { actions } from '..//constants/actions';
import store from '../../../src/services/store';
import { isValidParam} from '..//helper/parameterVerifier';

export function getOperationDashboardReportData(params){
    let url = "";
    let promise = null;
    try {
        if(isValidParam(params)) {
            url = endPoints.OPERATIONS_DASHBOARD.GET_OPERATION_REPORT_DATA;
            store.dispatch({type: actions.DASHBOARD_REPORT_DATA_GET_PENDING, payload: null});

            promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                if(isValidParam(response)) {
                    store.dispatch({type: actions.DASHBOARD_REPORT_DATA_GET_FULFILLED, payload: response});
                }
            })
        }
        
    } catch (error) {
        console.log("Error in 'operationDashboardAction.js -> getOperationDashboardReportData()':" + error)
    }
}


export const getOpertaionDashboardInfo = (selectedReport) => {
    try {
        if(isValidParam(selectedReport)) {
            let promise = Promise.resolve(HTTPClient.get(endPoints.SALES_REPORT.RECENT_REPORT, selectedReport));
            
            store.dispatch({type: actions.DASHBOARD_REPORT_INFO_GET_PENDING, payload: null});
            promise.then((response) => {
                let reportInfo = {};
                if(isValidParam(response)) {
                    reportInfo.recentReport = response;
                    reportInfo.objectWorkflow = 0;
                }
                store.dispatch({type: actions.DASHBOARD_REPORT_INFO_GET_FULFILLED, payload: reportInfo});
            });
        }
        
    } catch (error) {
        console.log("Error in 'operationDashboardAction.js -> getOpertaionDashboardInfo()':" + error)
    }
}


export const getOperationReport = (params) =>{ 
    let promise  = null;
    if(isValidParam(params)){
        promise = Promise.resolve(HTTPClient.get(endPoints.OPERATIONS_DASHBOARD.GET_OPERATION_REPORT_DATA, params))
        promise.then((response)=>{
            if(isValidParam(response)){
                console.log(response);
            }
        })
    }
    return promise;
}