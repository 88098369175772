import React, { useState, useEffect,useRef ,useCallback} from 'react';
import $ from 'jquery';
import { isValidParam, getStringParam, getObjectParam, getBooleanParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { setHeader } from '../../../services/actions/headerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import SFDataTable from './sfDataTable';
import * as sfDialogs from './sfDialogs';
import { useDispatch, useSelector } from "react-redux";
import PopOver from './PopOver';
import moment from 'moment';
import { updateAuditCategoryData } from '../../../services/actions/auditActions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import { getAppDialog, getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import {showCustomSnackBar} from '../../../services/actions/snackBarAction';

import FroalaEditor from 'react-froala-wysiwyg';
import Froalaeditor from 'froala-editor'
import {
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Chip,
    TextField,
    TextArea,
    Autocomplete
} from '@mui/material';
import {updateWorkflowSetupAddNewTask,updateActionTaskInStage, setActions,setisOpenEmailTask, setisEditEmailTask} from '../../../services/actions/workflowSetupAction';
import { getOrderId } from '../../../services/helper/utils';


const WorkflowSetupEmail = ({task}) =>{
    const dispatch = useDispatch();
    const {isOpenEmailTask,isEditEmailTask,isFocusOnEmailTask, selectedStage,data,emailTemplateMergeFields,selectedTemplateInfo,emailTemplateList} = useSelector((state) => state.workFlowSetup);
    let selectedTask = isEditEmailTask ? (task?.details) : []; 
    let froalaRef;
    const inputRef = useRef(null);    
    const [emailSubject,setemailSubject] = useState(isEditEmailTask ?selectedTask.template.subject : '');
    const [interval,setInterval] = useState(isEditEmailTask ?selectedTask.t_interval : 0);
    const [timeBy,settimeBy] = useState(isEditEmailTask ?selectedTask.t_interval_type : '');
    const [timeByList,settimeByList] = useState([
        {id:2345,name:'days',label:'Days'},
        {id:5678,name:'hours',label:'Hours'}
    ])
    const [timeByField,settimeByField] = useState(isEditEmailTask ?selectedTask.t_interval_mode : '');

    const [timeByFieldList,settimeByFieldList] = useState([
        {id:12345,name:'Created Date',label:'Created Date'},
        {id:12345,name:'Current Date',label:'Current Date'},
        {id:12345,name:'The preceding task is completed',label:'The preceding task is completed'},
        {id:12345,name:'The preceding text message is sent',label:'The preceding text message is sent'},
    ])
    const [template, setTemplate] = useState({
		subject: isEditEmailTask ? selectedTask.template.subject : '',
		content:isEditEmailTask ? selectedTask.template.subject : '',
		email_message: isEditEmailTask ? selectedTask.template.emailBody : '',
		id: isEditEmailTask ? selectedTask.template.template_id : 0,
		editorType: isEditEmailTask ? selectedTask.template.editorType : '',
        metadata :  isEditEmailTask ? selectedTask.template.metadata : '',
	});

    const [salectedtemplate,setSalectedtemplate] = useState(null);

    useEffect(()=>{
        if(isEditEmailTask && !salectedtemplate){
            const options = [];
            Object.keys(emailTemplateList? emailTemplateList[0] : [])?.forEach((key) => {
            emailTemplateList[0][key]?.forEach((option) => {
                options.push({ ...option, templateGroup: key });
            });
            });
            let _selectedTemplteId = selectedTask.template.template_id
            let _selectedTemplate = options.find((element) => element?.id === _selectedTemplteId);
            setSalectedtemplate(_selectedTemplate);
        }
    },[selectedTemplateInfo])

    useEffect(()=>{
        if(!timeBy){
            settimeBy(timeByList[0].name);
        }
        if(!timeByField){
            settimeByField(timeByFieldList[0].name);
        }
    },[template])


    const updateWorkflowSetupEmail = () =>{
        if(!emailSubject || !template.email_message || !interval){
            let errorMessage;
            if(!emailSubject){
                errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EMPTY_SUBJECT;
                inputRef.current?.focus();
            }else if(!template.email_message){
                errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.BODY_ERROR_EMAIL;
                inputRef.current?.focus();
            }else if(!interval){
                errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.INTERVEL_ERROR;
                inputRef.current?.focus();
            }
            dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));    
        }
        else{
            const orderId = getOrderId([{...selectedStage}])
            if(isOpenEmailTask){
                let obj = {
                    "workflow_id": selectedStage.workflow_id,
                    "stage_id": selectedStage.id,
                    "details":{
                        "t_status": "New",
                        "t_interval": interval,
                        "t_intervalDateFieldId": "-9999", // TBD
                        "t_interval_mode": timeByField,
                        "t_subCategory": "6395", // TBD
                        "t_view_as_web": "No", // TBD
                        "detailStatusFlg": 1, // TBD
                        "t_interval_type": timeBy,
                        "template": {
                            "metadata": "",
                            "emailBody":template.email_message,
                            "subject": emailSubject,
                            "template_id": salectedtemplate.id,
                            "editorType": "New",
                            "content": ""
                        }
                    },
                    "id":  Math.floor(Math.random() * Date.now()),
                    "type": "Auto_Mail",
                    "order_id": orderId,
                    "status": "Active"
                };
                dispatch(updateWorkflowSetupAddNewTask(obj));
            }else if(isEditEmailTask){
                let obj = {
                    "workflow_id": task.workflow_id,
                    "stage_id": task.stage_id,
                    "details": {
                        "t_status": "New",
                        "t_interval": interval,
                        "t_intervalDateFieldId": "-9999",
                        "t_interval_mode": timeByField,
                        "t_subCategory": "6395",
                        "t_view_as_web": "No",
                        "detailStatusFlg": 1,
                        "t_interval_type": timeBy,
                        "template": {
                            "metadata": "",
                            "emailBody":template.email_message,
                            "subject": emailSubject,
                            "template_id": salectedtemplate.id,
                            "editorType": "New",
                            "content": ""
                        }
                    },
                    "id":  task.id,
                    "type": task.type,
                    "order_id": task.order_id,
                    "status": task.status,
                };
                dispatch(updateActionTaskInStage(obj));
            }
            dispatch(setActions('--Add--'));
            setemailSubject('');
            setInterval(0);
            settimeBy('');
            settimeByField('');
            setSalectedtemplate({});
            dispatch(setisOpenEmailTask(false));
            dispatch(setisEditEmailTask(false));
        }
    }
    const closeWorkflowSetupEmail = () =>{
        setemailSubject('');
        setInterval(0);
        settimeBy('');
        settimeByField('');
        setSalectedtemplate({});
        dispatch(setisOpenEmailTask(false));
        dispatch(setisEditEmailTask(false));     
        dispatch(setActions('--Add--'));
    }
    const menuOnChange = (value,event) =>{
        var eventSelection = document.getElementById('emailSubject');
        let start = eventSelection.selectionStart,
            end = eventSelection.selectionEnd;
            if(!end ){
                value = getStringParam(emailSubject).concat(' ${' + getStringParam(value) + '} ');
            }else{
                value = getStringParam(emailSubject).substring(0, start) + ' ${' + getStringParam(value) + '} ' + getStringParam(emailSubject).substring(end);
            }
        setemailSubject(value);
    }
    const onChangeEmailSubject = (value) =>{
        setemailSubject(value);
    }
    
    const handleSelectTemplate = (event,value) =>{
        let params={};
        params.template_id = value.id;
        setSalectedtemplate(value);
        const promise = Promise.resolve(HTTPClient.get(endPoints.WORKFLOW_SETUP.GET_WORKFLOW_EMAIL_TEMPLATE_BODY, params));
        if (isValidParam(promise)) {
          promise.then((response) => {
            let _template = template;
            _template.email_message = response.mail_body;
            _template.subject = response.subject;
            setTemplate(_template);
            setemailSubject(response.subject);
          })
        }

    }
    const onChangeInterValFunc = (value) =>{
        setInterval(value);
    }
    const onChangeActions =(value,type) =>{
        if(type ==  'timeBy'){
            settimeBy(value);
        }else if(type == 'timeByField'){
            settimeByField(value);
        }

    }
    const getActionList = (type) =>{
        let List;
        if(type == 'timeBy') {
            List = timeByList;
        }else if(type == 'timeByField'){
            List = timeByFieldList;
        }
        if (List !== undefined) {
            return List.map((item, i) => {
                return <MenuItem value={item.name} style={styles.popoverMenuItem} >{item.label}</MenuItem>;
            })
        }
    }
    const handleModelChange = (event) =>{
        let tempTemplate = template;
		tempTemplate["email_message"] = event;
		setTemplate(tempTemplate);
    }
    let templateBodyMsg;
    let FroalaConfig = {
        heightMin: 400,
        heightMax: 450,
        width: 1200,
        key: constants.FROALA_EDITOR_KEY,
        placeholderText: getLocalizedStrings().label.MAIL.TYPE_HERE,
        toolbarButtons: constants.SEND_MAIL_EDITOR_TOOLBAR_CONFIGURATION,
        toolbarButtonsMD: constants.SEND_MAIL_EDITOR_TOOLBAR_CONFIGURATION,
        toolbarButtonsSM: constants.SEND_MAIL_EDITOR_TOOLBAR_CONFIGURATION,
        toolbarButtonsXS: constants.SEND_MAIL_EDITOR_TOOLBAR_CONFIGURATION,
        colorsBackground: constants.EDITOR_TOOLBAR_BACKGROUND_COLOR_PALETTE,
        colorsText: constants.EDITOR_TOOLBAR_TEXT_COLOR_PALETTE,
        toolbarSticky: false,
        enter: FroalaEditor.ENTER_BR,
        iframe: true,
        htmlUntouched: true,
        attribution: false,
        inlineMode: false,
        linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
        linkInsertButtons: ['linkEdit', 'linkRemove', 'link_library'],
        imageEditButtons: ['imageAlign', 'imageRemove', 'imageLink', 'imageDisplay', 'imageAlt', 'imageSize', 'linkOpen', 'linkEdit', 'linkRemove'],
        tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', '-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign'],
        quickInsertButtons: [],
        imageUpload: false,
        imagePaste: false,
        fileUpload: false,
        // autofocus: autoFocus,
        fontSize: constants.EDITOR_FONT_SIZE,
        fontFamily: {
            'Adobe Garamond Pro': 'Adobe Garamond Pro',
            'Arial,Helvetica,sans-serif': 'Arial',
            'Futura Std': 'Futura Std',
            'Georgia,serif': 'Georgia',
            'Impact,Charcoal,sans-serif': 'Impact',
            "RobotoSegoe": 'Roboto',
            'Tahoma,Geneva,sans-serif': 'Tahoma',
            "'Times New Roman',Times,serif": 'Times New Roman',
            'Verdana,Geneva,sans-serif': 'Verdana',
        },
        fontFamilySelection: true,
        events: {
            'froalaEditor.initialized': function (e, editor) {
                $('.fr-command').on('click', function () {
                    if ($(this).data('cmd') === 'insertLink') {
                        $('input[name="target"]').prop('checked', true);
                    }
                });
            }
        },
        events: {
            'initialized': function () {
                window.WEBSPELLCHECKER.init({
                    container: this.$iframe ? this.$iframe[0] : this.el
                });
            }
        },
        zIndex: 25000
    }
    const getEditor = (templateBodyMsg, FroalaConfig) => {
        let editor = null;
        editor = <FroalaEditor
                        style={{ width: '300px', paddingTop: '20px' }} 
						tag="textarea"
						model={template.email_message}
                        autoFocus={!template.email_message}
						onModelChange={(e) => handleModelChange(e)}
						config={FroalaConfig}
						ref={(ref) => froalaRef = ref}
					/>       
        return editor;
    };

    const setTextInputRef = (element) =>{
        inputRef.current = element;
    }

    const options = [];
    Object.keys(emailTemplateList? emailTemplateList[0] : [])?.forEach((key) => {
      emailTemplateList[0][key]?.forEach((option) => {
        options.push({ ...option, templateGroup: key });
      });
    });

      let popOverList = [];
      emailTemplateMergeFields.forEach((item, index) => {
            if (isValidParam(item)) {
                if (isValidParam(Object.values(item)[0]) && isValidParam(Object.keys(item)[0])) {
                    let data = {};
                    data.label = Object.values(item)[0];
                    data.value = Object.keys(item)[0];
                    popOverList.push(data);
                }
            }
        });

    return(
        <div style={{width:'100%',height:'100%',background:'#E5E5E5'}}>
        <div style={{display:'flex',width:'100%',height:'63px',marginLeft:'1.5rem'}}>
                <Button
                    onClick={() => updateWorkflowSetupEmail()}
                    style={{
                        ...styles.primaryButton,
                        minWidth: '20px',
                        width: '7%',
                        marginTop:'20px',
                    }}
                    title={ isEditEmailTask ? getLocalizedStrings().label.COMMON.UPDATE :getLocalizedStrings().label.COMMON.SAVE }
                > {isEditEmailTask ? getLocalizedStrings().label.COMMON.UPDATE :getLocalizedStrings().label.COMMON.SAVE}</Button>
                <Button
                    onClick={() => closeWorkflowSetupEmail()}
                    style={{
                        ...styles.primaryButton,
                        minWidth: '20px',
                        width: '7%',
                        marginTop:'20px',
                    }}
                    title={getLocalizedStrings().label.COMMON.CLOSE}
                > {getLocalizedStrings().label.COMMON.CLOSE}</Button>
        </div>
        <div style={{width:'100%',display:'flex',paddingLeft:'40%'}} >
                <div>
                    <span style={{font:'bolder 15px Arial, Helvetica, sans-serif'}}>{getLocalizedStrings().message.WORKFLOW_SETUP.PERSONALIZE}</span>
                </div>
                <div>
                    <span title={getLocalizedStrings().message.WORKFLOW_SETUP.PERSONALIZE}>
                        <span  style={{ marginRight: 5, maxWidth: 80, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }}>
                            <PopOver
                                id={'phoneTemplate'}
                                key={'phoneTemplate'}
                                name={'phoneTemplate'}
                                title={getLocalizedStrings().message.WORKFLOW_SETUP.PERSONALIZE}
                                buttonEndIcon={"keyboard_arrow_down"}
                                buttonStyle={{ color: '#7b7272', marginTop: '0px', fontSize:18 }}
                                labelposition={"before"}
                                buttonLabel={""}
                                options={popOverList}
                                onclickAction={(value,event)=>menuOnChange(value,event)}
                                isShowIcon={true}
                                buttonLineOrigin={[28, 145]}
                            />
                        </span>
                    </span>
                </div>
        </div>
        <div style={{width:'100%',display:'flex',paddingLeft:'1.5rem'}}>
                <FormControl style={{ width: '35rem' ,marginTop:'2px'}} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        name="emailSubject"
                        id='emailSubject'
                        label="Subject"
                        fullWidth={true}
                        value={emailSubject}
                        inputRef={!emailSubject? setTextInputRef:''}
                        style={{width:'100%'}}
                        onChange={(event) => onChangeEmailSubject(event.target.value)}
                        inputprops={{ maxlength: '256' }}
                        autoComplete="new-password"
                        margin='dense'
                        className={"sf-fields-bg"}
                        size="small"
                    />
                </FormControl>
                <Autocomplete
                    id="grouped_demo"
                    options={options.sort(
                        (a, b) => -b.templateGroup.localeCompare(a.templateGroup)
                    )}
                    groupBy={(option) => option.templateGroup}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => handleSelectTemplate(event, value)}
                    value={salectedtemplate}
                    sx={{ width: '35rem',marginLeft:'10px',marginTop:'10px'}}
                    renderInput={(params) => <TextField {...params}  label="Select Template" size="small"/>}
                />
        </div>
        <div style={{width:'100%',display:'flex',paddingLeft:'1.5rem',marginTop:'5px'}}>
                    <span style={{marginTop:'10px',font:'bolder 15px Arial, Helvetica, sans-serif'}}>{getLocalizedStrings().label.COMMON.SEND}</span>
                    <FormControl style={{ width: '5rem' ,marginTop:'-8px',marginLeft:'10px'}} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            name="interval"
                            id='interval'
                            label="interval"
                            fullWidth={true}
                            value={interval}
                            inputRef={!interval ? setTextInputRef : ''}
                            style={{width:'100%'}}
                            onChange={(event) => onChangeInterValFunc(event.target.value)}
                            inputprops={{ maxlength: '256' }}
                            autoComplete="new-password"
                            margin='dense'
                            className={"sf-fields-bg"}
                            size="small"
                        />
                    </FormControl>
                    <FormControl variant="outlined" style={{ width: '15rem',marginBottom:'3px',marginLeft:'3px' }} className='audit-select'>
                        <Select
                            key="sort-by"
                            name="time-by"
                            labelId='time-by-label'
                            autoFocus={isFocusOnEmailTask}
                            id='timeBy'
                            value={timeBy}
                            style={{width:'100%'}}
                            size="small"
                            onChange={(event) => onChangeActions(event.target.value,'timeBy')}
                        >
                            {getActionList('timeBy')}
                        </Select>
                    </FormControl>
                    <span style={{marginTop:'10px',font:'bolder 15px Arial, Helvetica, sans-serif',marginLeft:'10px',marginRight:'10px'}}>{getLocalizedStrings().label.WORKFLOW_SETUP.AFTER}</span>
                    <FormControl variant="outlined" style={{ width: '18rem',marginBottom:'3px',marginLeft:'3px' }} className='audit-select'>
                        <Select
                            key="sort-by"
                            name="time-by-field"
                            labelId='time-by-label'
                            id='time-by-field'
                            value={timeByField}
                            style={{width:'100%'}}
                            size="small"
                            onChange={(event) => onChangeActions(event.target.value,'timeByField')}
                        >
                            {getActionList('timeByField')}
                        </Select>
                    </FormControl>

        </div>
        <div className='flora_editor_section' id="froala-editor" style={{width:'96%',display:'flex',paddingLeft:'1.5rem',marginTop:'5px',height:'fit-content'}}>
                {getEditor(templateBodyMsg, FroalaConfig)}
        </div>
        </div>
    )
}
export default WorkflowSetupEmail;