import React, { useState, useEffect, useRef } from 'react';
import FroalaEditor from "react-froala-wysiwyg";
import Froalaeditor from 'froala-editor'
import $ from "jquery";
import { connect } from "react-redux";
import {Button,Checkbox,Chip,FormControl,FormControlLabel,TextField,Menu,MenuItem,Icon,IconButton,} from "@mui/material";
import * as HTTPClient from "../../../services/helper/httpClient";
import { endPoints } from "../../../services/constants/endPoints";
import Autocomplete from "@mui/material/Autocomplete";
import { constants } from "../../../services/constants/constants";
import { styles } from "../../../services/constants/styles";
import { getAppDialog } from "../../../services/actions/appContainerActions";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { isEmailExistsInList } from "../../../services/helper/utils";
import { sortArrayObjectByProperty } from "../../../services/helper/utils";
import { isValidParam } from "../../../services/helper/parameterVerifier";
import * as sfDialogs from "../components/sfDialogs";
import { useDispatch, useSelector } from "react-redux";

require("froala-editor/js/froala_editor.pkgd.min.js");
require("froala-editor/css/froala_editor.pkgd.min.css");
require("froala-editor/js/plugins/font_family.min.js");

const MessageDetails = ({ email_ids,t_status,disabled,messages_details,updateMessageDetails,errors }) => {
	const dispatch = useDispatch();
	const app = useSelector((state) => state.app);
	const data = useSelector((state) => state.data);
	const margefields = useSelector((state) => state.sfForm.data.contacts.fields);
	const [message_details, setMessage_details] = useState({...messages_details});
	const [personalize_fields, setPersonalize_fields] = useState({
		"j_contacts.company": "Company",
		"j_contacts.first_name": "First name",
		"j_contacts.last_name": "Last name",
	});
	const [personalize, setPersonalize] = useState(-1);
	const [personilizeOpen, setPersonilizeOpen] = useState(false);
	const [anchorPersonilizeEl, setAnchorPersonilizeEl] = useState(null);
	const [error, setError] = useState(JSON.parse(errors));
	const [sender_email_options, setsender_email_options] = useState([]);
	const [cursorposition, setCursorposition] = useState(messages_details["subject"].length);

	const handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		setCursorposition(event.target.selectionStart);
		let temp = message_details;

		temp[name] = value;
		setMessage_details({...temp});
		updateMessageDetails({
			details: message_details,
			error: error,
		});
	};

	// UNSAFE_componentWillReceiveProps(nextProps) {
	// 	if (this.props.error !== nextProps.error) {
	// 		this.setState({ error: JSON.parse(nextProps.error) });
	// 	}

	// 	if (this.state.editorInstance !== null) {
	// 		if (nextProps.disabled) {
	// 			this.state.editorInstance.edit.off();
	// 		} else if (!nextProps.disabled) {
	// 			this.state.editorInstance.edit.on();
	// 		}
	// 	}
	// }

	useEffect(() => {
		let fields = margefields;
		fields = fields.filter((f) => f.name !== "header" && f.name !== "");
		let mergeFields = {};
		sortArrayObjectByProperty(fields, "label");
		for (let i = 0; i < fields.length; i++) {
			let fieldsName = "j_contacts." + fields[i].name;
			mergeFields[fieldsName] = fields[i].label;
		}
		setPersonalize_fields(mergeFields);
		generateFromEmailOptions();

	}, [messages_details]);
	const generateFromEmailOptions = (verifid_email_ids, isReloaded) => {
		let sender_email_options = [];
		if (isValidParam(verifid_email_ids)) {
			verifid_email_ids.forEach(function (value) {
				if (!isEmailExistsInList(sender_email_options, value)) {
					sender_email_options.push({
						value: value,
						label: value,
					});
				}
			});
		} else {
			if (
				email_ids !== undefined &&
				Array.isArray(email_ids) &&
				email_ids.length > 0
			) {
				email_ids.forEach(function (value) {
					if (!isEmailExistsInList(sender_email_options, value)) {
						sender_email_options.push({
							value: value,
							label: value,
						});
					}
				});
			}
		}

		if (!isEmailExistsInList(sender_email_options, app.me.email)) {
			sender_email_options.unshift({
				value: app.me.email,
				label: app.me.email,
			});
		}
		sender_email_options.push({
			value: "-1",
			label:
				"---" +
				getLocalizedStrings().label.AB_CAMPAIGNS.VERIFY_EMAIL_ADDRESS_OPTION +
				"---",
		});

		if (!isEmailExistsInList(sender_email_options,message_details.sender_email)
		) {
			let temp = message_details;
			temp.sender_email = app.me.email;
			setMessage_details({...temp});
		} else {
            if (isValidParam(isReloaded) && isReloaded) {
				handleSenderEmailChange({},sender_email_options[0].value);
			}
			setMessage_details(message_details);
			setsender_email_options(sender_email_options);
		}
	};
	const handleSenderEmailChange =(event,value) => {

		if (value.value === "-1") {
			openVerifyEmailPopup();
			let temp = message_details;
			temp["sender_email"] = value;
			setMessage_details(temp);
		} else {
			let temp = message_details;
			temp["sender_email"] = value;
			setMessage_details(temp);
		}
		error["sender_email"] = "";
		setError({...error});
		updateMessageDetails({
			details: message_details,
			error: error,
		});
	};
	const openVerifyEmailPopup = () => {
		try {
			let data = {
				fromName: app.me.name,
				verifiedEmailOptions: sender_email_options,
			};
			dispatch(getAppDialog(
				true,
				constants.VERIFY_EMAIL_DIALOG,
				getLocalizedStrings().label.BROADCAST_CAMPAIGNS.VERIFY_EMAIL,
				null,
				data,
				constants.AB_CAMPAIGNS_OBJECT
			));
		} catch (error) {
			console.error(
				"Error in 'messageDetails.js -> openVerifyEmailPopup():'" + error
			);
		}
	};
	const renderPersonalizeField = () => {
		let fields = margefields;
		fields = fields.filter((f) => f.name !== "header" && f.name !== "");
		let mergeFields = {};
		sortArrayObjectByProperty(fields, "label");
		for (let i = 0; i < fields.length; i++) {
			let fieldsName = "j_contacts." + fields[i].name;
			mergeFields[fieldsName] = fields[i].label;
		}
		
		let personalizeObject = personalize_fields;
		return Object.keys(personalizeObject).map((itemKey, index) => {
			return (
				<MenuItem
					key={itemKey + index}
					value={itemKey}
					style={styles.popoverMenuItem}
					fullWidth={true}
					labelstyle={{ color: "#479ccf" }}
					underlinestyle={{ display: "none" }}
					autowidth={"true"}
					onClick={(event) =>
						handleSelectPersonilizeChange( event,itemKey)
					}
					
				>
					{personalizeObject[itemKey]}
				</MenuItem>
			);
		});
	};
	const handleSelectPersonilizeChange = ( event,value) => {
		setPersonalize(value);
		setPersonilizeOpen(false);
		let front = message_details["subject"].substring(
			0,
			cursorposition
		);
		let back = message_details["subject"].substring(
			cursorposition,
			message_details["subject"].length
		);
		let _message_details = message_details;
		setMessage_details({..._message_details,subject:front + " ${" + value + "}" + back})
		setCursorposition(message_details.subject.length);
	};

	const handleModelChange = (event) => {
		let temp = message_details;
		temp["email_message"] = event;
		setMessage_details({...temp});
		updateMessageDetails({
			details: message_details,
			error: error,
		});
	};

	const handlePersonilizeOpen = (event) => {
		event.preventDefault();
		if (t_status === "Executed" ||t_status === "Queued") {
			setPersonilizeOpen(false);
		} else {
			setPersonilizeOpen(true);
			setAnchorPersonilizeEl(event.currentTarget);

		};
	}
		const handlePersonilizeClose = () => {
			setPersonilizeOpen(false);
		};
	
		
		const handleChangeTemplate = () => {
			let data = null;
			if (
				message_details.selectedTemplate !== null &&
				message_details.selectedCategory !== null
			) {
				data = {
					selectedTemplate: message_details.selectedTemplate,
					selectedCategory: message_details.selectedCategory,
				};
			}
			dispatch(getAppDialog(
				true,
				constants.TEMPLATE_DIALOG,
				getLocalizedStrings().label.CAMPAIGNS.TEMPLATE_DIALOG_TITLE,
				setTemplateData,
				data,
				constants.BROADCAST_CAMPAIGNS_OBJECT
			));
		};

		const setTemplateData = (obj) => {
			let params = {
				template_id: obj.template_id,
			};
			let temp = message_details;
			temp.selectedTemplate = obj.template_id;
			temp.selectedCategory = obj.category_id;
			setMessage_details(temp);
			var promise = Promise.resolve(
				HTTPClient.get(endPoints.MARKETING_TEMPLATE.TEMPLATE_GET, params)
			);

			promise.then((response) => {
				let temp = message_details;

				temp["subject"] = response.subject;
				temp["email_message"] = response.mail_body;
				setMessage_details({...temp});
				setCursorposition(response.subject.length);
				updateMessageDetails({
					details: message_details,
					error: error,
				});
				dispatch(getAppDialog(
					false,
					constants.TEMPLATE_DIALOG,
					null,
					null,
					null,
					null
				));
			});
		};

		const handleCheckBox = (event, isInputChecked) => {
			let temp = message_details;
			temp.view_as_web_page = event;
			setMessage_details({...temp});
			updateMessageDetails({
				details: message_details,
				error: error,
			});
		};

		const getLibrary = (editor, type) => {
			editor.events.disableBlur();
			let data = {
				editor: editor,
				contentStyle: { width: "80%", maxWidth: "none" },
				type: type,
				fullWidth: true,
				maxWidth: "md",
			};
			dispatch(getAppDialog(
				true,
				constants.LIBRARY_DIALOG,
				getLocalizedStrings().message.COMMON.LIBRARY_DIALOG_TITLE,
				handleLibraryResponse,
				data,
				null
			));
		};

		const handleLibraryResponse = (obj) => {
			obj.editor.events.enableBlur();
			if (obj.type === "link" && obj.src !== "") {
				document.getElementsByName("href")[0].value = obj.src;
			} else if (obj.type === "image" && obj.src !== "") {
				obj.editor.image.insert(obj.src);
			}
			dispatch(getAppDialog(
				false,
				constants.LIBRARY_DIALOG,
				null,
				null,
				null,
				null
			));
		};

		const getVideoInsertDialog = (editor, videoType) => {
			editor.events.disableBlur();
			let data = {
				editor: editor,
				contentStyle: { width: "80%", maxWidth: "none" },
				videoType: videoType,
			};
			let title = null;
			if (videoType === constants.VIDEO_TYPE_YOUTUBE) {
				title = getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE;
			} else if (videoType === constants.VIDEO_TYPE_WISTIA) {
				title = getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE;
			}
			dispatch(getAppDialog(
				true,
				constants.VIDEO_INSERT_DIALOG,
				title,
				(event)=>handleVideoInsertResponse(event),
				data,
				null
			));
		};

		const handleVideoInsertResponse = (obj) => {
			obj.editor.events.focus();
			dispatch(getAppDialog(
				false,
				constants.VIDEO_INSERT_DIALOG,
				null,
				null,
				null,
				null
			));
			obj.editor.html.insert(
				'<a href="' +
				obj.video_link +
				'" target="_blank" class="editor_video-selector"><img  height="220" width="380" "src="' +
				obj.thumbnail.url +
				'" title="' +
				obj.title +
				'" /></a>'
			);
			obj.editor.events.focus();
		};

		const focusSubject = (event) => {
			try {
				setCursorposition(event.target.selectionStart)

			} catch (error) {
				console.log("Error in focusSubject() in salseTemplate.js : " + error);
			}
		};
		const setEditorInstance = (editor) => {
			setEditorInstance(editor);
		};

		

		const loadVerifiedEmailIds = (event) => {
			let verifid_email_ids = [];
			let isReloaded = false;
			try {
				let url = endPoints.BROADCAST_CAMPAIGNS.GET_VERIFIED_EMAIL_IDS;
				let params = {};
				params.getUserMailIds = false;
				let promise = Promise.resolve(HTTPClient.get(url, params));
				promise.then((response) => {
					if (isValidParam(response)) {
						if (
							response.verified_email_ids !== null &&
							Array.isArray(response.verified_email_ids)
						) {
							isReloaded = true;
							verifid_email_ids = response.verified_email_ids;
							generateFromEmailOptions(verifid_email_ids, isReloaded);
						}
					}
				});
			} catch (error) {
				console.error(
					"Error in 'messageDetails.js -> loadVerifiedEmailIds():'" + error
				);
			}
		};

		const getSchedulerLink = (event) => {
			try {
				let slug = app.appointment_scheduler_slug;
				if (slug === null) {
					sfDialogs.alert(
						getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
						getLocalizedStrings().message.SCHEDULER.NO_SCHEDULER,
						null
					);
				} else {
					event.html.insert(
						" [Start Scheduler Link] Book an Appointment [End Scheduler Link] "
					);
				}
			} catch (error) {
				console.error(
					"Error in 'messageDetails.js -> getSchedulerLink()':" + error
				);
			}
		};

		

		Froalaeditor.DefineIcon('library', { NAME: 'image', template: "font_awesome" });
		Froalaeditor.RegisterCommand('library', {
			title: getLocalizedStrings().label.MAIL.INSERT_IMAGE,
			focus: false,
			undo: true,
			refreshAfterCallback: true,
			callback: function () {
				getLibrary(this, 'image')
			}
		});
	
		Froalaeditor.DefineIcon("personalize_dropdown", { NAME: "fa-duotone fa-gear" , template: "font_awesome"});
		Froalaeditor.RegisterCommand("personalize_dropdown", {
			title: getLocalizedStrings().label.AGREEMENT_TEMPLATE.PERSONALIZE,
			type: "dropdown",
			focus: false,
			undo: true,
			refreshAfterCallback: true,
	
			options: personalize_fields,
			callback: function (cmd, val) {
				this.html.insert(" ${" + val + "} ");
			},
			// Callback on refresh.
			refresh: function ($btn) {},
			// Callback on dropdown show.
			refreshOnShow: function ($btn, $dropdown) {},
		});
		
	
		
	
		Froalaeditor.DefineIcon("insertYouTubeVideo", {
			NAME: "fa-brands fa-youtube", template: "font_awesome"
		});
		Froalaeditor.RegisterCommand('insertYouTubeVideo', {
			title: getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE,
			focus: true,
			undo: true,
			refreshAfterCallback: true,
			callback: function () {
				getVideoInsertDialog(this, constants.VIDEO_TYPE_YOUTUBE)
			}
		});
	
		Froalaeditor.DefineIcon("insertWistiaVideo", {
			SRC: "/asset/images/wistia.png",
			ALT: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
			template: "image",
		});
		Froalaeditor.RegisterCommand('insertWistiaVideo', {
			title: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
			focus: true,
			undo: true,
			refreshAfterCallback: true,
			callback: function () {
				getVideoInsertDialog(this, constants.VIDEO_TYPE_WISTIA)
			}
		});
	
		Froalaeditor.DefineIcon('link_library', { NAME: 'file-archive-o', template: "font_awesome" });
		Froalaeditor.RegisterCommand('link_library', {
			title: getLocalizedStrings().label.MARKETING_TEMPLATE.SELECT_FROM_LIBRARY,
			focus: false,
			undo: true,
			refreshAfterCallback: true,
			callback: function () {
				getLibrary(this, 'link')
			}
		});
	
		  Froalaeditor.DefineIcon('scheduler-editor', { NAME: 'fas fa-calendar-minus', template: "font_awesome" });
		Froalaeditor.RegisterCommand('scheduler-editor', {
			title: getLocalizedStrings().label.COMMON.SCHEDULER_LINK,
			focus: false,
			undo: true,
			refreshAfterCallback: true,
			callback: function () {
				getSchedulerLink(this);
			}
		});
		let campaignStatus = t_status;
		let personalizeFieldList = renderPersonalizeField();

		let FroalaConfig = {
			key: constants.FROALA_EDITOR_KEY,
			placeholderText: getLocalizedStrings().label.AGREEMENT_TEMPLATE.TYPE_HERE,
			height: document.body.clientHeight - 200,
			toolbarButtons: constants.AGREEMENT_EDITOR_TOOLBAR_CONFIGURATION,
			toolbarButtonsMD: constants.AGREEMENT_EDITOR_TOOLBAR_CONFIGURATION,
			toolbarButtonsSM: constants.AGREEMENT_EDITOR_TOOLBAR_CONFIGURATION,
			toolbarButtonsXS: constants.AGREEMENT_EDITOR_TOOLBAR_CONFIGURATION,
			colorsBackground: constants.EDITOR_TOOLBAR_BACKGROUND_COLOR_PALETTE,
			colorsText: constants.EDITOR_TOOLBAR_TEXT_COLOR_PALETTE,
			toolbarSticky: false,
			enter: FroalaEditor.ENTER_BR,
			iframe: true,
			htmlUntouched: true,
			attribution: false,
			inlineMode: false,
			linkEditButtons: ["linkOpen", "linkEdit", "linkRemove"],
			linkInsertButtons: ["linkEdit", "linkRemove", "link_library"],
			imageEditButtons: [
				"imageAlign",
				"imageRemove",
				"imageLink",
				"imageDisplay",
				"imageAlt",
				"imageSize",
				"linkOpen",
				"linkEdit",
				"linkRemove",
			],
			tableEditButtons: [
				"tableHeader",
				"tableRemove",
				"|",
				"tableRows",
				"tableColumns",
				"-",
				"tableCells",
				"tableCellBackground",
				"tableCellVerticalAlign",
				"tableCellHorizontalAlign",
			],
			linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
			linkInsertButtons: ['linkEdit', 'linkRemove', 'link_library'],
			imageEditButtons: ['imageAlign', 'imageRemove', 'imageLink', 'imageDisplay', 'imageAlt', 'imageSize', 'linkOpen', 'linkEdit', 'linkRemove'],
			tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', '-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign'],
			quickInsertButtons: [],
			imageUpload: false,
			imagePaste: false,
			fileUpload: false,
			fontSize: constants.EDITOR_FONT_SIZE,
			fontFamily: {
				'Adobe Garamond Pro': 'Adobe Garamond Pro',
				'Arial,Helvetica,sans-serif': 'Arial',
				'Futura Std': 'Futura Std',
				'Georgia,serif': 'Georgia',
				'Impact,Charcoal,sans-serif': 'Impact',
				"RobotoSegoe": 'Roboto',
				'Tahoma,Geneva,sans-serif': 'Tahoma',
				"'Times New Roman',Times,serif": 'Times New Roman',
				'Verdana,Geneva,sans-serif': 'Verdana',
			},
				quickInsertButtons: [],
				imageUpload: false,
				imagePaste: false,
				fileUpload: false,
				fontSize: constants.EDITOR_FONT_SIZE,
				fontFamily: {
					"Adobe Garamond Pro": "Adobe Garamond Pro",
					"Arial,Helvetica,sans-serif": "Arial",
					"Futura Std": "Futura Std",
					"Georgia,serif": "Georgia",
					"Impact,Charcoal,sans-serif": "Impact",
					RobotoSegoe: "Roboto",
					"Tahoma,Geneva,sans-serif": "Tahoma",
					"'Times New Roman',Times,serif": "Times New Roman",
					"Verdana,Geneva,sans-serif": "Verdana",
				},
			fontFamilySelection: true,
			events: {
				"froalaEditor.initialized": function (e, editor) {
					if (campaignStatus === "Executed" || campaignStatus === "Running") {
						editor.edit.off();
					}
					setEditorInstance(editor);
					$(".fr-command").on("click", function (event) {
						if ($(event).data("cmd") === "insertLink") {
							$('input[name="target"]').prop("checked", true);
						}
					});
				},
			},
			events: {
				'initialized': function() {
					window.WEBSPELLCHECKER.init({
						container: this.$iframe ? this.$iframe[0] : this.el
					});
				 }
			 },
		};

		

		FroalaConfig["events"] = {
			"froalaEditor.initialized": function (e, editor) {
				if (campaignStatus === "Executed" || campaignStatus === "Running") {
					editor.edit.off();
				}
				setEditorInstance(editor);
				$(".fr-command").on("click", function () {
					if ($(this).data("cmd") === "insertLink") {
						$('input[name="target"]').prop("checked", true);
					}
				});
			},
		};
		return (
			<div>
				<div className="row" style={{ marginLeft:'-13px'}} >
					<div style={{ ...styles.sf_4, float: "left", paddingLeft: "15px" }}>
						<FormControl
							style={{ width: "100%" }}
							className="test"
							noValidate
							autoComplete="off"
						>
							<TextField
								variant="outlined"
								label={getLocalizedStrings().label.CAMPAIGNS.FROM_NAME_REQUIRED}
								fullWidth={true}
								name="sender_name"
								value={message_details.sender_name}
								onChange={(event) => handleChange(event)}
								error={error.sender_name !== ""}
								helperText={error.sender_name}
								disabled={disabled}
								autoComplete="new-password"
								margin="dense"
								size='small'
							/>
						</FormControl>
					</div>
					<div style={{ ...styles.sf_4, float: "left", paddingLeft: "15px" }}>
						<div style={{ float: "left", width: "100%" }}>
							<div
								style={{
									color: "#999999",
									position: "absolute",
									top: "2px",
									zIndex: "1",
									left: "25px",
									fontSize: "11px",
								}}
							>
								{/* {this.state.disabledFrmEmail ? getLocalizedStrings().label.CAMPAIGNS.FROM_EMAIL : getLocalizedStrings().label.BROADCAST_CAMPAIGNS.FROM_EMAIL_REQUIRED} */}
							</div>
							<FormControl
								style={{ width: "100%" }}
								className="test"
								noValidate
								autoComplete="off"
							>
								{sender_email_options != undefined &&
									sender_email_options.length > 0 && (
										<Autocomplete
											name="sender_email"
											defaultValue={sender_email_options.find(
												(v) =>
													v.value === message_details.sender_email
											)}
											options={sender_email_options}
											onChange={(event, value) =>
												handleSenderEmailChange(event, value)
											}
											getOptionLabel={(option) => option.label}
											
											disableClearable={true}
											disabled={disabled}
											className="broadFromEmail"
											style={{ marginBottom: "0px" }}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="outlined"
													label={
														getLocalizedStrings().label.BROADCAST_CAMPAIGNS
															.FROM_EMAIL_REQUIRED
													}
													margin="dense"
													size='small'
													className={"sf-fields-bg"}
												/>
											)}
										/>
									)}
							</FormControl>
							<span
								style={{
									fontSize: "12px",
									color: "#f44336",
									marginTop: "10px",
									marginBottom: "5px",
									display: "block",
								}}
							>
								{error.sender_email}
							</span>
						</div>
					</div>
					<div>
						<div style={{ marginLeft:'69%',marginTop:'-56px' }}>
							<IconButton
								onClick={(e) => loadVerifiedEmailIds(e)}
								style={{ color: "#949494", fontSize: "16px" }}
							>
								<Icon
									style={{
										fontSize: "25px",
										paddingLeft: "0px",
										color: "#666161",
										marginTop: "3px",
									}}
									title={getLocalizedStrings().label.REPORT.REFRESH}
								>
									autorenew
								</Icon>
							</IconButton>
						</div>
					</div>

					<div
						style={{
							...styles.sf_3,
							
							paddingLeft: "2px",
							marginTop: "-52px",
							marginLeft:'74%'
						}}
					>
						<div>
							<FormControlLabel
								control={
									<Checkbox
										color="default"
										value={message_details.view_as_web_page}
										onChange={(event, isInputChecked) =>
											handleCheckBox(event, isInputChecked)
										}
										defaultChecked={message_details.view_as_web_page}
									/>
								}
								label={getLocalizedStrings().label.CAMPAIGNS.VIEW_AS_WEB_PAGE}
								labelposition="right"
								disabled={disabled}
							/>
						</div>
					</div>
				</div>
				<div style={{ ...styles.row, padding: 0 }}>
					<div style={{ ...styles.sf_12 }}>
						<FormControl
							style={{ width: "100%", marginBottom: "15px" }}
							className="test"
							noValidate
							autoComplete="off"
						>
							<TextField
								variant="outlined"
								label={getLocalizedStrings().label.CAMPAIGNS.SUBJECT_REQUIRED}
								fullWidth={true}
								name="subject"
								value={message_details.subject}
								onChange={(event) => handleChange(event)}
								onClick={(event) => focusSubject(event)}
								error={error.subject !== ""}
								helperText={error.subject}
								disabled={disabled}
								className="asel1"
								autoComplete="new-password"
								margin="dense"
								size='small'
							/>
						</FormControl>
					</div>
					<div
						title={getLocalizedStrings().label.CAMPAIGNS.PERSONALIZE}
						style={{
							width: "5%",
							marginTop: "15px",
							zIndex: 1500,
							marginLeft: "-5%",
						}}
					>
						<div
							style={{ marginTop: "4%", marginLeft: "8px" }}
							onClick={(event)=>handlePersonilizeOpen(event)}
						>
							<Icon
								style={{
									fontSize: "17px",
									color: "black",
									cursor: "pointer",
									marginTop: "1px",
									marginLeft: "-2px",
								}}
							>
								settings
							</Icon>
							<Icon
								style={{
									fontSize: "17px",
									color: "black",
									cursor: "pointer",
									marginTop: "1px",
								}}
							>
								keyboard_arrow_down
							</Icon>
						</div>
						<Menu
							name="noteTypeList"
							key={"sf-auto-complete-noteTypeList"}
							id={"noteTypeList"}
							style={{ height: "50%" }}
							open={personilizeOpen}
							anchorEl={anchorPersonilizeEl}
							anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
							targetOrigin={{ horizontal: "left", vertical: "top" }}
							onClose={() => handlePersonilizeClose()}
						>
							{personalizeFieldList}
						</Menu>
					</div>
				</div>
				<div>
					<div style={{ ...styles.sf_12 }}>
						<div style={{ textAlign: "right", paddingBottom: "10px" }}>
							<Button
								style={styles.secondaryButton}
								onClick={() => handleChangeTemplate()}
								disabled={disabled}
							>
								{" "}
								{getLocalizedStrings().label.CAMPAIGNS.CHANGE_TEMPLATE}
							</Button>
						</div>

						<div style={{ marginBottom: "60px" }}>
							<FroalaEditor
								tag="textarea"
								model={message_details.email_message}
								onModelChange={(event) => handleModelChange(event)}
								config={FroalaConfig}
							/>
						</div>
					</div>
				</div>
			</div>
		);

	



}
export default MessageDetails;
