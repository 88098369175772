import React, { useState, useEffect } from "react";
import map from "lodash/map";
import { getTasksPortal, getTasksRefresh, } from "../../../services/actions/taskActions";
import { refreshDetailViewListViewData, getDetailViewTaskAppointment, refreshDetailViewData, clearDetailViewTaskAppointmentReducer, saveDetailViewTaskAppointmentParam, getUnitOwnerTasks, detailViewIsMounted, isDetailViewSet } from "../../../services/actions/detailViewActions";
import { styles } from "../../../services/constants/styles";
import { constants, OBJECT_TABLEID_MAP, } from "../../../services/constants/constants";
import { isValidParam, makeValidAppUrl, getArrayParam, getIntParam, getStringParam, getBooleanParam, getObjectParam, } from "../../../services/helper/parameterVerifier";
import { deleteAction } from "../../../services/actions/batchActions";
import { getAppDrawer, getAppCustomDrawer, } from "../../../services/actions/appContainerActions";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import Button from "@mui/material/Button";
import PopOver from "../components/PopOver";

import { Menu, MenuItem, Icon } from "@mui/material";
import { reschedule } from "../../../services/actions/batchActions";
import { dateTimeFormat, dateTimeFormatFlatPicker, getDateObject, timeZone, } from "../../../services/helper/utils";
import moment from "moment";
import tz from "moment-timezone";
import { Navigate } from "react-router-dom";
import * as sfDialogs from "../components/sfDialogs";
import { LinkToType } from "../../../services/constants/link";
import { hasAccessPermission, isObjectChildExists, getLinkedRecordId, } from "../../../services/helper/common";
import { getAppDialog } from "../../../services/actions/appContainerActions";
import { getEndPointObject } from "../../../services/actions/sfFormActions";
import * as HTTPClient from "../../../services/helper/httpClient";
import { getActiveTab as tabManagerActiveTab, addTab, TYPE_DETAIL_VIEW, getActiveTabInfo, addNewFormTabs, closeTab, } from "../../../services/helper/sfTabManager";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { updateNotificationInfo } from "../../../services/actions/notificationActions";
import { endPoints } from "../../../services/constants/endPoints";
import ShowCircularProgress from "../components/circularProgress";
import { openDetailviewQueueTab, removeRecordFromRedux, } from "../../../services/actions/queueActions";
import { useDispatch, useSelector } from "react-redux";
import { refreshColumnView } from "../../../services/actions/listsActions";
import { getCalendarRefresh} from '../../../services/actions/calendarActions';


let popOverList = [
	{
		value: constants.TASK_APP_MY_OPEN,
		label: "My Open",
	},
	{
		value: constants.TASK_APP_OPEN,
		label: "All Open",
	},
	{
		value: constants.TASK_APP_MY_DONE,
		label: "My Done",
	},
	{
		value: constants.TASK_APP_DONE,
		label: "All Done",
	},
];

const DetailViewTaskApps = ({
	propsTaskApp,
	isUnitownerTasks,
	isFranchiseRecord,
	parentObject,
	resetScroll,
}) => {
	const dispatch = useDispatch();

	const appContainer = useSelector((state) => state.appContainer);
	const app = useSelector((state) => state.app);
	const task = useSelector((state) => state.task);
	const tab = useSelector((state) => state.tab);
	const appointment = useSelector((state) => state.appointment);
	const taskPortalCount = useSelector((state) => state.task.taskPortalCount);
	const refresh = useSelector((state) => state.task.isrefresh);
	const detailViewRecord = useSelector((state) => state.detailView.record);
	const [taskAppData, setTaskAppData] = useState({});
	const taskAppMounted = useSelector(
		(state) => state.detailViewTaskAppintmentReducer.taskAppMounted
	);
	const _taskAppData = useSelector(
		(state) => state.detailViewTaskAppintmentReducer.taskAppointment
	);
	const detailViewTaskAppintmentReducer = useSelector(
		(state) => state.detailViewTaskAppintmentReducer
	);
	const detailViewMounted = useSelector((state) => state.detailView.mounted);
	const detailViewTaskAppParam = useSelector(
		(state) =>
			state.detailViewTaskAppintmentReducer.detailViewTaskAppintmentParam
	);
	const notification = useSelector((state) => state.notification);
	const userlist = useSelector((state) => state.userlist);
	const sfForm = useSelector((state) => state.sfForm);
	const unitOwnerTasks = useSelector(
		(state) => state.detailViewTaskAppintmentReducer.unitOwnerTasks
	);
	const workflow = useSelector((state) => state.detailView.workflow);
	const queueList = useSelector((state) => state.queueList);

	const [openAddList, setOpenAddList] = useState(null);
	const [clickCount, setClickCount] = useState(0);
	const [redirectUrl, setRedirectUrl] = useState("");
	const [redirect, setRedirect] = useState(false);
	const [openActionList, setOpenActionList] = useState(false);
	const [dummyState, setDummyState] = useState(false);
	const [start_index, setStart_index] = useState(0);
	const [isTaskChild, setIsTaskChild] = useState(false);
	const [isAppointmentChild, setIsAppointmentChild] = useState(false);
	const [taskType, setTaskType] = useState(null);
	const [taskAppValue, setTaskAppValue] = useState(constants.TASK_APP_OPEN);
	const [pageHeight, setPageHeight] = useState(window.innerHeight - 247);
	const [top, setTop] = useState((pageHeight - 10) / 2);
	const [isMounted, setIsMounted] = useState(
		propsTaskApp.callFrom === "DetailView" && taskAppMounted ? true : false
	);
	const [paddingTop, setPaddingTop] = useState(null);

	const [marginbtnLeft, setMarginbtnLeft] = useState("0px");

	const [isUnitownerTasksVal, setIsUnitownerTasksVal] =
		useState(isUnitownerTasks);
	const [isFranchiseRecordVal, setIsFranchiseRecordVal] = useState();

	const [anchorE2, setAnchorE2] = useState();
	const [actionid, setActionid] = useState();
	const [actionobject, setActionobject] = useState();
	const [parentRecurId, setParentRecurId] = useState();
	const [openCompletedMenuList, setOpenCompletedMenuList] = useState();
	const [changeLog, setchangeLog] = useState({
		isTaskChild: false, isAppointmentChild: false, taskAppValue: detailViewTaskAppintmentReducer.taskAppValue !== undefined ? detailViewTaskAppintmentReducer.taskAppValue : constants.TASK_APP_MY_OPEN, taskAppLabel: propsTaskApp.callFrom === "DetailView" && isTaskChild
			? getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS
			: ""
	});
	const [appValue, setAppValue] = useState("");

	const [showTaskApp, setShowTaskApp] = useState(false);
	const [taskAppLabel, setTaskAppLabel] = useState(
		propsTaskApp.callFrom === "DetailView" && changeLog.isTaskChild
			? getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS
			: ""
	);

	useEffect(() => {
		let temp = _taskAppData;
		setTaskAppData({ ...temp })
		console.log("_taskAppData", taskAppData)
	}, [_taskAppData])


	const isTaskAppInChildObject = (parentObject, isAppointmentInChild) => {
		let isTaskInChild = false;
		isTaskInChild = isObjectChildExists(parentObject, constants.TASKS);
		setIsTaskChild(isTaskInChild);
		let log = changeLog;
		log.isTaskChild = isTaskInChild;
		log.isAppointmentChild = isAppointmentInChild;
		setchangeLog({ ...log });
		setIsAppointmentChild(isAppointmentInChild);

	};

	const getObjectByType = (type) => {
		let object = null;
		if (type === "task" || type === "tasks") {
			object = constants.TASKS;
		} else if (type === "appointment" || type === "appointments") {
			object = constants.APPOINTMENTS;
		} else if (type === "email") {
			object = constants.EMAIL_OBJECT;
		} else if (type === "sms") {
			object = constants.SMS_OBJECT;
		}
		return object;
	};

	const handleCalenderReschedule = (params, object, isReschedule, msg) => {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		let isUnitsAccount = false;
		if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
			isUnitsAccount = tabInfo.isUnitsAccount;
		}
		let hasPermission = null;
		if (isValidParam(params) && params.date === "Invalid") {
			isReschedule = false;
		}
		if (isReschedule) {
			let permissionObject = object;
			if (
				object === constants.EMAIL_OBJECT ||
				object === constants.SMS_OBJECT
			) {
				permissionObject = constants.TASKS;
			}
			hasPermission = hasAccessPermission(
				permissionObject,
				constants.SECURITY_LEVEL_TYPE_ACCESS,
				constants.ACCESS_TYPE_EDIT
			);
			if (hasPermission && !isInXpress && !isUnitsAccount) {
				var response = reschedule(object, params);
				response.then((response) => {
					if (response.status === 0) {
						if (object === constants.TASKS) {
							dispatch(
								showCustomSnackBar(
									msg,
									styles.snackbarBodyStyleSuccess,
									styles.snackBarStyleTop
								)
							);
							dispatch(getTasksRefresh(true));
						} else if (object === constants.APPOINTMENTS) {
							dispatch(
								showCustomSnackBar(
									msg,
									styles.snackbarBodyStyleSuccess,
									styles.snackBarStyleTop
								)
							);
							dispatch(getTasksRefresh(true));
							let parentTableIds = null;
							if (changeLog.isTaskChild) {
								parentTableIds = "349";
							}
							if (propsTaskApp.isAppointmentInChild) {
								if (parentTableIds === null) {
									parentTableIds = "142";
								} else {
									parentTableIds += ",142";
								}
							}
							let _data = {};
							_data.parent_table_id = parentTableIds;
							_data.child_table_id = OBJECT_TABLEID_MAP[propsTaskApp.object];
							_data.child_record_id = propsTaskApp.recordId;
							_data.task_app_value = changeLog.taskAppValue;
							dispatch(getDetailViewTaskAppointment(propsTaskApp.object, _data));

						} else if (
							object === constants.EMAIL_OBJECT ||
							object === constants.SMS_OBJECT
						) {
							dispatch(
								showCustomSnackBar(
									msg,
									styles.snackbarBodyStyleSuccess,
									styles.snackBarStyleTop
								)
							);
							dispatch(detailViewIsMounted(false));
							dispatch(getTasksRefresh(true));
							dispatch(refreshDetailViewData(true));

						}
					}
				});
			} else {
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					getLocalizedStrings().message.COMMON.ACCESS_DENIED,
					null
				);
			}
		}
	};

	const handelDelete = (type, id, record) => {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		let isUnitsAccount = false;
		if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
			isUnitsAccount = tabInfo.isUnitsAccount;
		}
		var recordIds = [];
		var id = parseInt(id, 10);
		var objectName = type;
		var permissionObject = type;
		let typeMsg = "";
		let _taskType = "";
		if (objectName === constants.TASKS) {
			recordIds.push(id);
			typeMsg = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS;
		} else if (objectName === constants.APPOINTMENTS) {
			recordIds.push(id);
			typeMsg = getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENTS;
		} else if (objectName === "sms") {
			recordIds.push(id);
			typeMsg = "SMS";
			_taskType = taskType;
			permissionObject = constants.TASKS;
		} else if (objectName === "email") {
			recordIds.push(id);
			typeMsg = "Email";
			_taskType = taskType;
			permissionObject = constants.TASKS;
		}
		let msg = "";
		let errorMessage = "";
		if (id > 0) {
			if (objectName === "email" || objectName === "sms") {
				msg =
					typeMsg +
					getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;
			} else {
				msg =
					typeMsg.charAt(0).toUpperCase() +
					typeMsg.slice(1, type.length - 1) +
					getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;
			}
		}
		let hasPermission = null;
		hasPermission = hasAccessPermission(
			permissionObject,
			constants.SECURITY_LEVEL_TYPE_ACCESS,
			constants.ACCESS_TYPE_DELETE
		);
		if (hasPermission && !isInXpress && !isUnitsAccount) {
			sfDialogs.confirm(
				getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
				msg,
				(e) =>
					handelDeleteOnConfirm(
						e,
						type,
						_taskType,
						recordIds,
						record,
						objectName
					),
				null
			);
		} else {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				getLocalizedStrings().message.COMMON.ACCESS_DENIED,
				null
			);
		}
	};

	const handelDeleteOnConfirm = (
		event,
		type,
		taskType,
		recordIds,
		record,
		objectName
	) => {
		let msg = null;
		let typeMsg = null;
		let param = {
			id: recordIds,
		};
		if (type === "email") {
			param.taskType = taskType;
			if (taskType === "AutoMail") {
				param.isDelete = false;
			} else {
				if (record.callFrom === "taskPortal") {
					param.parent_record_id = getIntParam(record.recordId);
				} else {
					param.parent_table_id = OBJECT_TABLEID_MAP[propsTaskApp.object];
					param.parent_record_id = getIntParam(detailViewRecord.id);
				}
			}
			typeMsg = "Email";
			msg = typeMsg + getLocalizedStrings().message.COMMON.DELETE_SUCCESS;
		} else if (type === "sms") {
			param.taskType = taskType;
			if (taskType === "Workflow SMS") {
				param.isDelete = false;
			}
			param.isDetailView = true;
			typeMsg = "SMS";
			msg = typeMsg + getLocalizedStrings().message.COMMON.DELETE_SUCCESS;
		} else {
			msg =
				type.charAt(0).toUpperCase() +
				type.slice(1, type.length - 1) +
				getLocalizedStrings().message.COMMON.DELETE_SUCCESS;
		}
		var response = deleteAction(objectName, param);
		response.then((response) => {
			if (response.status === 0) {
				// ************************ START: Notification delete.  ************************
				/** when deleted notification will be removed from  pop up menu */
				// deleteNotificationInfo(objectName, recordIds);
				/** ------- */
				// ************************ END: Notification delete.  ************************
				dispatch(detailViewIsMounted(false));
				dispatch(isDetailViewSet(false));
				dispatch(refreshDetailViewData(true));
				dispatch(refreshDetailViewListViewData(true));
				if (taskType === "AutoMail" || taskType === "Workflow SMS") {
					let data = response.data;
					if (data.isDependent !== undefined && data.isDependent) {
						sfDialogs.confirm(
							"This scheduled Email/SMS has dependent tasks and those dependent tasks will not be created.",
							null,
							handelDeleteOnConfirmEmailSms(
								recordIds,
								record,
								objectName,
								taskType
							),
							null

						);

					} else {
						dispatch(getTasksRefresh(true));
						if (propsTaskApp.isDetailView) {
							dispatch(refreshDetailViewListViewData(true));
						}
						dispatch(
							showCustomSnackBar(
								msg,
								styles.snackbarBodyStyleSuccess,
								styles.snackBarStyleTop
							)
						);
					}
				} else {

					dispatch(getTasksRefresh(true));
					if (propsTaskApp.callFrom === "Tasks") {
						let params = {
							module_id: propsTaskApp.moduleId,
							start_index: start_index,
							fetch_size: 20,
							sales_rep: propsTaskApp.salesRep,
							sort_order: propsTaskApp.sortOrder,
						};
						dispatch(getTasksPortal(params));
					}
					let queueObj =
						queueList !== null &&
							queueList !== undefined &&
							queueList.hasOwnProperty("data") &&
							queueList.data !== null
							? queueList.data.object
							: "";
					if (queueObj === constants.TASKS) {
						removeFromQueue(recordIds, constants.DELETE, record);
					}
					if (propsTaskApp.isDetailView) {
						dispatch(refreshDetailViewListViewData(true));
					}
					dispatch(
						showCustomSnackBar(
							msg,
							styles.snackbarBodyStyleSuccess,
							styles.snackBarStyleTop
						)
					);
				}
			}
		});
	};

	const handelDeleteOnConfirmEmailSms = (
		recordIds,
		record,
		objectName,
		taskType
	) => {
		let params = {
			id: recordIds,
			taskType: taskType,
			isDelete: true,
			isDetailView: true,
		};
		var response = deleteAction(objectName, params);
		response.then((response) => {
			if (response.status === 0) {
				dispatch(getTasksRefresh(true));
				if (propsTaskApp.isDetailView) {
					dispatch(refreshDetailViewListViewData(true));
				}
				let msg = "Deleted Sucessfully";
				dispatch(
					showCustomSnackBar(
						msg,
						styles.snackbarBodyStyleSuccess,
						styles.snackBarStyleTop
					)
				);

			}
		});
	};

	const handelComplete = (type, id, taskType) => {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		let isUnitsAccount = false;
		if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
			isUnitsAccount = tabInfo.isUnitsAccount;
		}
		let callFrom = constants.OTHER;
		let objectName = null;

		let hasPermission = hasAccessPermission(
			type,
			constants.SECURITY_LEVEL_TYPE_ACCESS,
			constants.ACCESS_TYPE_EDIT
		);

		if (hasPermission && !isInXpress && !isUnitsAccount) {
			var id = parseInt(id, 10);
			let parentRecordCurrentStatus = null;
			if (propsTaskApp.callFrom === "DetailView") {
				parentRecordCurrentStatus = propsTaskApp.currentStatus;
				callFrom = constants.DETAIL_VIEW;
			}
			let data = {
				id: id,
				object: type,
				currentStatus: getStringParam(parentRecordCurrentStatus),
				taskType:
					taskType !== null && taskType !== undefined && taskType !== ""
						? taskType
						: "Task",
			};
			if (propsTaskApp.isDetailView) {
				data.parentDetails = {
					parent_object: propsTaskApp.object,
					parent_record_id: propsTaskApp.recordId,
					isDetailView: propsTaskApp.isDetailView,
				};
				if (type === constants.TASKS) {
					data.isFranchiseRecordVal = getBooleanParam(isFranchiseRecordVal);
				}
			}
			if (propsTaskApp.callFrom === "Tasks") {
				data.propsTaskApp = {
					module_id: propsTaskApp.moduleId,
					start_index: start_index,
					fetch_size: 20,
					sales_rep: propsTaskApp.salesRep,
					sort_order: propsTaskApp.sortOrder,
				};
			}
			let labelName =
				type === constants.APPOINTMENTS
					? getLocalizedStrings().label.APPOINTMENTS_TASKS
						.COMPLETE_THIS_APPOINTMENT
					: getLocalizedStrings().label.APPOINTMENTS_TASKS.COMPLETE_THIS_TASK;
			dispatch(
				getAppDrawer(true, labelName, constants.COMPLETE, data, callFrom)
			);
		} else {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				getLocalizedStrings().message.COMMON.ACCESS_DENIED,
				null
			);
		}
	};

	const add = (objectName, value) => {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		let isUnitsAccount = false;
		if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
			isUnitsAccount = tabInfo.isUnitsAccount;
		}
		let hasPermission = hasAccessPermission(
			objectName,
			constants.SECURITY_LEVEL_TYPE_ACCESS,
			constants.ACCESS_TYPE_CREATE
		);
		if (hasPermission && !isInXpress && !isUnitsAccount) {
			if (
				propsTaskApp.object !== constants.JOBS_OBJECT &&
				(objectName === constants.APPOINTMENTS ||
					objectName === constants.TASKS ||
					objectName === constants.UNIT_TASKS)
			) {
				let data = {
					id: 0,
					object: objectName,
					//  parent_object:propsTaskApp.object,
					//  parent_record_id:propsTaskApp.recordId
				};
				if (propsTaskApp.isDetailView) {
					data["parent_object"] = propsTaskApp.object;
					data["parent_record_id"] = propsTaskApp.recordId;
					data["isDetailView"] = propsTaskApp.isDetailView;
				}
				let objName = "";
				if (objectName === constants.APPOINTMENTS) {
					// objName = "Appointment"
					objName = getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENT;
				} else if (objectName === constants.TASKS) {
					// objName = "Task"
					objName = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK;
					data.isFranchiseRecord = getBooleanParam(isFranchiseRecordVal);
					data.selectedOpt = value;
					if (value === constants.UNIT_TASKS) {
						data.isUnitownerTasks = true;
					}
				} else {
					objName = objectName.charAt(0).toUpperCase() + objectName.slice(1);
				}
				let add =
					objectName === constants.APPOINTMENTS ||
						objectName === constants.TASKS
						? getLocalizedStrings().label.COMMON.ADD_NEW_APPOINTMENT
						: getLocalizedStrings().label.COMMON.ADD;
				let labelName = add + " " + objName;
				dispatch(
					getAppDrawer(true, labelName, constants.NEW, data, constants.OTHER)
				);
			} else {
				let labelName = "Schedule for " + detailViewRecord.job_id;
				let info = {
					title: labelName,
					type: constants.JOB_SCHEDULER,
					object: propsTaskApp.object,
					detailView_recordid: detailViewRecord.id,
					jobName: detailViewRecord.job_name,
				};
				addNewFormTabs(info);
			}
		} else {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				getLocalizedStrings().message.COMMON.ACCESS_DENIED,
				null
			);
		}
	};

	const editAction = (objectName, id, subject, taskType) => {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		let isUnitsAccount = false;
		if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
			isUnitsAccount = tabInfo.isUnitsAccount;
		}
		let tempObject = objectName;
		if (objectName === "sms" || objectName === "email") {
			tempObject = constants.TASKS;
		}
		let isUnitownerTasksValNew = getBooleanParam(isUnitownerTasksVal);
		let hasPermission = hasAccessPermission(
			tempObject,
			constants.SECURITY_LEVEL_TYPE_ACCESS,
			constants.ACCESS_TYPE_EDIT
		);
		closePopList();
		if (hasPermission && !isInXpress && !isUnitsAccount) {
			let data = {};
			data.id = id;
			data.object = objectName;
			data.isDetailView = false;

			if (propsTaskApp.isDetailView) {
				data.isDetailView = true;
				data.parent_object = propsTaskApp.object;
				data.parent_record_id = propsTaskApp.recordId;
				data.callFrom = propsTaskApp.callFrom;

				if (objectName === constants.TASKS) {
					data.isUnitownerTasksVal = isUnitownerTasksValNew;
					data.isFranchiseRecordVal = getBooleanParam(isFranchiseRecordVal);
					data.propsTaskApp = propsTaskApp;

				}
			}
			let labelName = subject;
			if (objectName === constants.EMAIL_OBJECT) {
				data.type = constants.TYPE_EMAIL;
				data.emailType = taskType;
				let style = {};
				style.width = "90%";
				dispatch(
					getAppCustomDrawer(
						true,
						labelName,
						LinkToType.TYPE_EMAIL,
						style,
						data,
						null,
						true
					)
				);
			} else if (objectName === constants.SMS_OBJECT) {
				data.objectName = objectName;
				data.actionType = "edit";
				data.smsType = taskType;
				dispatch(
					getAppDrawer(
						true,
						"Edit SMS",
						constants.SEND_SMS,
						data,
						constants.LIST_VIEW
					)
				);
			} else if (objectName === constants.TASKS) {
				data.labelName = labelName;
				if (isUnitownerTasks) {
					data.isUnitsAccount = true;
					data.UnitsAccountProjectId = detailViewRecord.myclient_Id;
				}
				if (propsTaskApp.callFrom === "Tasks") {
					data.propsTaskApp = {
						module_id: propsTaskApp.moduleId,
						start_index: start_index,
						fetch_size: 20,
						sales_rep: propsTaskApp.salesRep,
						sort_order: propsTaskApp.sortOrder,
					};
				}
				dispatch(
					getAppDrawer(
						true,
						labelName,
						constants.MINI_EDIT,
						data,
						constants.OTHER
					)
				);

			} else {
				dispatch(
					getAppDrawer(true, labelName, constants.NEW, data, constants.OTHER)
				);
			}
			// getAppDrawer(true, labelName, constants.NEW, data, constants.OTHER);
		} else {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				getLocalizedStrings().message.COMMON.ACCESS_DENIED,
				null
			);
		}
	};

	const openTaskDetailView = (objectName, id, labelName) => {
		let linkTo = "detailview/" + id;
		let linkToUrl = "/" + objectName + "/" + linkTo;
		linkToUrl = makeValidAppUrl(linkToUrl);

		let tab = {
			label: labelName,
			object: objectName,
			type: TYPE_DETAIL_VIEW,
			url: "/" + objectName + "/" + linkTo,
			info: {},
		};
		addTab(tab);
		setRedirect(true);
		setRedirectUrl(linkToUrl);
	};




	const openDetailView = (id, parentTableId, name) => {
		let recordId = id.trim();
		let objectName = null;
		for (var i in OBJECT_TABLEID_MAP) {
			if (OBJECT_TABLEID_MAP[i] === parseInt(parentTableId)) {
				objectName = i;
				break;
			}
		}
		let linkTo = "detailview/" + recordId;
		let labelName = name;
		let linkToUrl = "/" + objectName + "/" + linkTo;
		linkToUrl = makeValidAppUrl(linkToUrl);

		let tab = {
			label: name,
			object: objectName,
			type: TYPE_DETAIL_VIEW,
			url: "/" + objectName + "/" + linkTo,
			info: {},
		};
		addTab(tab);

		setRedirect(true);
		setRedirectUrl(linkToUrl);
	};

	const handleReschedule = (id, duration, object) => {
		let records = null;
		let linkLead = 0;
		let linkTableId = 0;
		let LabelName = "";
		if (object === "task" || object === constants.TASKS) {
			object = constants.TASKS;
		} else if (object === "appointment" || object === constants.APPOINTMENTS) {
			object = constants.APPOINTMENTS;
		}
		let hasPermission = null;
		hasPermission = hasAccessPermission(
			object,
			constants.SECURITY_LEVEL_TYPE_ACCESS,
			constants.ACCESS_TYPE_EDIT
		);
		if (hasPermission) {
			let recordIdArr = [];
			let msg =
				object === constants.TASKS
					? getLocalizedStrings().message.APPOINTMENTS_TASKS
						.RESCHEDULE_TASK_SUCCESS
					: getLocalizedStrings().message.APPOINTMENTS_TASKS
						.RESCHEDULE_APPOINTMENT_SUCCESS;
			recordIdArr.push(id);

			let params = {
				id: recordIdArr,
				type: duration,
			};
			var response = reschedule(object, params);
			response.then((response) => {
				if (response.status === 0) {
					if (propsTaskApp.isDetailView) {
						linkLead = detailViewRecord.id;
						linkTableId = OBJECT_TABLEID_MAP[propsTaskApp.object];

						let parentTableIds = null;
						if (changeLog.isTaskChild) {
							parentTableIds = "349";
						}
						if (propsTaskApp.isAppointmentInChild) {
							if (parentTableIds === null) {
								parentTableIds = "142";
							} else {
								parentTableIds += ",142";
							}
						}
						let _data = {};
						_data.parent_table_id = parentTableIds;
						_data.child_table_id = OBJECT_TABLEID_MAP[propsTaskApp.object];
						_data.child_record_id = propsTaskApp.recordId;
						_data.task_app_value = changeLog.taskAppValue;
						dispatch(getDetailViewTaskAppointment(propsTaskApp.object, _data));
						detailViewTaskAppintmentReducer.taskAppValue = changeLog.taskAppValue;
						if (object == constants.TASKS) {
							dispatch(showCustomSnackBar("Task is rescheduled successfully", styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
						} else if (object == constants.APPOINTMENTS) {
							dispatch(showCustomSnackBar("Appointment is rescheduled successfully", styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));

						}

					} else {
						if (object === constants.TASKS) {
							records = task.data.filter((f) => {
								return f["id"] === id;
							});
							let tempArray = records[0].linkInfo;
							let commaArray = tempArray.split(",");
							let underScoreArray = commaArray[0].split("_");
							linkLead = underScoreArray[1];
							linkTableId = underScoreArray[0];
							if (propsTaskApp.callFrom === "Tasks") {
								let params = {
									module_id: propsTaskApp.moduleId,
									start_index: start_index,
									fetch_size: 20,
									sales_rep: propsTaskApp.salesRep,
									sort_order: propsTaskApp.sortOrder,
								};
								dispatch(getTasksPortal(params));
							}
						}
					}
					let data = {
						parent_table_id: getIntParam(linkTableId),
						parent_record_id: linkLead,
						msg: msg,
					};
					dispatch(getTasksRefresh(true));
					setDummyState(!dummyState);
					if (linkLead !== 0) {
						if (duration === "nextday") {
							LabelName =
								getLocalizedStrings().label.APPOINTMENTS_TASKS.NEXT_DAY;
						} else if (duration === "nextweek") {
							LabelName =
								getLocalizedStrings().label.APPOINTMENTS_TASKS.NEXT_WEEK;
						} else if (duration === "nextmonth") {
							LabelName =
								getLocalizedStrings().label.APPOINTMENTS_TASKS.NEXT_MONTH;
						}
						dispatch(
							getAppDrawer(
								true,
								LabelName,
								constants.SAVE_NOTES,
								data,
								constants.LIST_VIEW
							)
						);
					}
				}
			});
		} else {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				getLocalizedStrings().message.COMMON.ACCESS_DENIED,
				null
			);
		}
	};

	const openPopList = (event, record, callFrom) => {
		let id = record["parentId"];
		if (callFrom != null && callFrom != undefined && callFrom === "tasks") {
			id = record["id"];
		}
		let parentRecurId = record["parentRecurId"];
		let taskType = record["taskType"];
		let object = null;
		let type = record["type"];
		event.preventDefault();
		setOpenActionList(true);
		setAnchorE2(event.currentTarget);
		setActionid(id);
		setActionobject(type);
		setParentRecurId(parentRecurId);
		setTaskType(taskType);
	};

	const closePopList = () => {
		//event.preventDefault();
		setOpenActionList(false);
		setAnchorE2(null);
	};

	const getRecurringDeleteChoice = (val) => {
		dispatch(
			getAppDialog(
				false,
				constants.RECURRING_DELETE_DIALOG,
				null,
				null,
				null,
				null
			)
		);
		deleteRecord(actionid, val);
	};

	const deleteRecord = (id, delete_action_type) => {
		let params = {};
		params.id = [id];
		if (delete_action_type !== null) {
			params.delete_action_type = delete_action_type;
		}
		let object = getObjectByType(actionobject);
		if (
			id > 0 &&
			(actionobject === constants.TYPE_APPOINTMENTS ||
				actionobject === constants.TYPE_TASKS || actionobject === 'tasks')
		) {
			let linkObject = getEndPointObject(object);
			let url = linkObject.DELETE;
			var promise = Promise.resolve(HTTPClient.post(url, params));
			promise.then((response) => {
				if (response.status === 0) {
					if (
						actionobject === constants.TYPE_APPOINTMENTS ||
						actionobject === constants.TYPE_TASKS
						|| actionobject === 'tasks'
					) {
						dispatch(getTasksRefresh(true));
						let _data = {
							module_id: propsTaskApp.moduleId,
							start_index: start_index,
							fetch_size: 20,
							sales_rep: propsTaskApp.salesRep,
							sort_order: propsTaskApp.sortOrder,
						};
						dispatch(getTasksPortal(_data));

						let queueObj =
							queueList !== null &&
								queueList !== undefined &&
								queueList.hasOwnProperty("data") &&
								queueList.data !== null
								? queueList.data.object
								: "";
						if (queueObj === constants.TASKS) {
							removeFromQueue([id], constants.DELETE);
						}
					}
					dispatch(detailViewIsMounted(false));
					dispatch(isDetailViewSet(false));
					dispatch(refreshDetailViewData(true));
					dispatch(refreshDetailViewListViewData(true));
					dispatch(
						showCustomSnackBar(
							getLocalizedStrings().message.COMMON.DELETE,
							styles.snackbarBodyStyleSuccess,
							styles.snackBarStyleTop
						)
					);
				} else if (response.status === -1 && response.error.message !== "") {
					dispatch(
						showCustomSnackBar(
							response.error.message,
							styles.snackbarBodyStyleError,
							styles.snackBarStyleTop
						)
					);
				}
			});
		}
	};

	const removeFromQueue = (detailViewId, action, recordInfo) => {
		let nextRecordIndex = 0;
		let newIdx = 0;
		try {
			if (detailViewId > 0) {
				let data = queueList?.data;
				let _queueList = data?.records;
				let recordId = detailViewId[0];
				let queueObj = _queueList?.filter((obj) => {
					return obj.id === recordId;
				});
				let index =
					isValidParam(queueObj) && queueObj.length > 0 ? queueObj[0].idx : -1;
				let objectName = getStringParam(queueList.data.object);

				if (action === constants.DELETE && _queueList.length <= 1) {
					let activeTab = tabManagerActiveTab();
					if (isValidParam(activeTab) && data?.openedRecordInQueuelabel == recordInfo?.taskName) {
						closeTab(activeTab.item, activeTab.index);
						dispatch(getCalendarRefresh(true));
						dispatch(getTasksRefresh(true));
                        refreshColumnView(constants.TASKS);
					}
				} else {
					if (index >= 0) {
						if (index + 1 < _queueList.length) {
							nextRecordIndex = index + 1;
							newIdx = index;
							// open next records for detailview
						} else {
							nextRecordIndex = index - 1;
							newIdx = nextRecordIndex;
							// get the last record  for detailview
						}

						let prevObj = _queueList[nextRecordIndex];
						let preId =
							isValidParam(prevObj) && prevObj !== null
								? getIntParam(prevObj.id)
								: 0;
						if (preId > 0) {
							let total_count = getIntParam(data.total_count);
							total_count = total_count - 1;
							let label = getStringParam(prevObj.title);
							removeRecordFromRedux(objectName, recordId, total_count);
								if (objectName === constants.TASKS) {
									queueList.data["openedRecordInQueueId"] = preId;
									queueList.data["openedRecordInQueueIndex"] = newIdx;
									queueList.data["openedRecordInQueuelabel"] = label;
									let linkInfo = prevObj.linkInfo;
									if (linkInfo !== null && linkInfo !== undefined) {
										let linkInfoArr = linkInfo.split(",");
										let selectedObjId = getIntParam(
											OBJECT_TABLEID_MAP[queueList.data.selecteddrpdwnObj]
										);
										linkInfoArr.map((item, i) => {
											let ObjRecordArr = item.split("_");
											let tableId = getIntParam(ObjRecordArr[0].trim());
											if (selectedObjId === tableId) {
												preId = getIntParam(ObjRecordArr[1]);
												objectName = queueList.data.selecteddrpdwnObj;
											}
										});
									}
								}
								
								openDetailviewQueueTab(
									objectName,
									label,
									preId,
									newIdx,
									total_count
								);
							
						
							dispatch(refreshDetailViewListViewData(true, recordId));
						}
					}
				}
			}
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js -> removeFromQueue()':" + error
			);
		}
	};

	const handleSendNow = (record) => {
		let id = actionid;
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		let isUnitsAccount = false;
		if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
			isUnitsAccount = tabInfo.isUnitsAccount;
		}
		try {
			let hasPermission = hasAccessPermission(
				constants.TASKS,
				constants.SECURITY_LEVEL_TYPE_ACCESS,
				constants.ACCESS_TYPE_EDIT
			);
			let isAurinkoReAuthRequired = false;
			let isSendMailUsingAurinko = false;
			let _recordType = record.type;
			if(_recordType!= null && _recordType!="" && _recordType == constants.EMAIL_OBJECT) {
				isAurinkoReAuthRequired = app.me.is_aurinko_reauth_required;
				isSendMailUsingAurinko = app.me.is_send_mail_using_aurinko;
			}			 
			if (isSendMailUsingAurinko && isAurinkoReAuthRequired) {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.MY_INTEGRATION.RE_AUTHENTICATE_AURINKO, null);
			} else {
				if (hasPermission && !isInXpress && !isUnitsAccount) {
					let params = {};
					params.id = id;
					params.type = record.type;
					params.taskType = record.taskType;
					params.recordId = detailViewRecord.id;
					params.audit_trail_tableIds = getStringParam(app.me.audit_trail_table_ids);
					if(_recordType!= null && _recordType!="" && _recordType == constants.EMAIL_OBJECT) {
						params.is_send_mail_using_aurinko = app.me.is_send_mail_using_aurinko;
					}
					let url = endPoints.TASK.SEND_NOW;
					var promise = Promise.resolve(HTTPClient.get(url, params));
					promise.then((response) => {
						if (response.status === -1) {
							if (record.type === constants.SMS_OBJECT) {
								/*dispatch(
									showCustomSnackBar(
										getLocalizedStrings().message.SMS_INTEGRATION[
										response.data.toUpperCase()
										],
										styles.snackbarBodyStyleError,
										styles.snackBarStyleTop
									)
								);*/
								dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
							}
						} else {
							if (record.type === constants.EMAIL_OBJECT) {
								dispatch(
									showCustomSnackBar(
										getLocalizedStrings().message.APPOINTMENTS_TASKS
											.SEND_EMAIL_PROGRESS_MSG,
										styles.snackbarBodyStyleSuccess,
										styles.snackBarStyleTop
									)
								);
							} else if (record.type === constants.SMS_OBJECT) {
								dispatch(
									showCustomSnackBar(
										getLocalizedStrings().message.SMS_INTEGRATION.BATCH_SMS_SENT,
										styles.snackbarBodyStyleSuccess,
										styles.snackBarStyleTop
									)
								);
							}
						}
						dispatch(getTasksRefresh(true));
					});
				} else {
					sfDialogs.alert(
						getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
						getLocalizedStrings().message.COMMON.ACCESS_DENIED,
						null
					);
				}
			}
		} catch (error) {
			console.error(
				"error in 'detailViewTaskApps.js'-->'handleSendNow()'" + error
			);
		}
	};

	const handelAction = (actiontype, record) => {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		let isUnitsAccount = false;
		if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
			isUnitsAccount = tabInfo.isUnitsAccount;
		}
		if (!isInXpress && !isUnitsAccount) {
			let type = actionobject;
			let object = getObjectByType(type);
			let id = actionid;
			let parentRecId = parentRecurId;
			closePopList();
			if (actiontype === "complete") {
				handelComplete(object, id, record.tType);
			} else if (actiontype === "miniedit") {
				openTaskDetailView(constants.TASKS, id, record.taskName);
			} else if (actiontype === "nextday") {
				handleReschedule(id, "nextday", object);
			} else if (actiontype === "nextweek") {
				handleReschedule(id, "nextweek", object);
			} else if (actiontype === "nextmonth") {
				handleReschedule(id, "nextmonth", object);
			} else if (actiontype === "sendnow") {
				handleSendNow(record);
			} else if (actiontype === "delete") {
				if (
					parentRecId !== null &&
					parentRecId !== "" &&
					parentRecId !== undefined &&
					parentRecId > 0
				) {
					let hasPermission = null;
					hasPermission = hasAccessPermission(
						object,
						constants.SECURITY_LEVEL_TYPE_ACCESS,
						constants.ACCESS_TYPE_DELETE
					);
					if (hasPermission && !isInXpress && !isUnitsAccount) {
						let data = {};
						data.object = object;
						dispatch(
							getAppDialog(
								true,
								constants.RECURRING_DELETE_DIALOG,
								getLocalizedStrings().message.REPEAT_DIALOG.DELETE_RECUR_TITLE,
								getRecurringDeleteChoice,
								data
							)
						);
					} else {
						sfDialogs.alert(
							getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
							getLocalizedStrings().message.COMMON.ACCESS_DENIED,
							null
						);
					}
				} else {
					handelDelete(object, id, record);
				}
			}
		} else {
			let permissionMessage =
				getLocalizedStrings().message.COMMON.ACCESS_DENIED;
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				permissionMessage,
				null
			);
		}
	};

	const changeLink = (type, id, name) => {
		var idName = type + id + name;

		if (document.getElementById(idName).style.textDecoration === "underline") {
			document.getElementById(idName).style.textDecoration = "none";
		} else {
			document.getElementById(idName).style.textDecoration = "underline";
		}
	};


	const changeLinkFields = (type, record, name) => {
		var idName = type + record['id'] + name;
		if (document.getElementById(idName).style.textDecoration === "underline") {
			document.getElementById(idName).style.textDecoration = "none";
		} else {
			document.getElementById(idName).style.textDecoration = "underline";
		}
		if (record.hasOwnProperty('t_unit_owner') && record['t_unit_owner'] == '1') {
			document.getElementById(idName).style.textDecoration = 'none';
		}
	};

	const colourChangeTaskApp = (taskAppDate) => {
		if (propsTaskApp.isDetailView) {
			let color = null;
			let date = new Date(taskAppDate);
			let day = date.getDate();
			let mnt = date.getMonth();
			let yer = date.getFullYear();
			let toDate = new Date();
			let today = toDate.getDate();
			let tomnt = toDate.getMonth();
			let toyer = toDate.getFullYear();
			if (yer > toDate.getFullYear()) {
				color = "rdi-green"; //"#398be8"
			} else if (yer < toDate.getFullYear()) {
				color = "rdi-red";
			} else {
				if (mnt > toDate.getMonth()) {
					color = "rdi-green";
				} else if (mnt < toDate.getMonth()) {
					color = "rdi-red";
				} else {
					if (day > toDate.getDate()) {
						color = "rdi-green";
					} else if (day < toDate.getDate()) {
						color = "rdi-red";
					} else {
						color = "rdi-black";
					}
				}
			}
			if (changeLog.taskAppValue === constants.TASK_APP_DONE || changeLog.taskAppValue === constants.TASK_APP_MY_DONE) {
				color = "rdi-black";
			}
			return color;
		}
	};

	const generateMenuItem = (record, isEdit) => {
		let element = [];
		let items = [];
		let taskApp = propsTaskApp;
		try {
			let type = record["type"];
			if (type === constants.TYPE_EMAILS || type === constants.TYPE_SMS) {
				if (getObjectParam(taskApp).moduleId !== constants.TASKS_OVERDUE) {
					items.push("sendnow");
					if (isEdit) {
						items.push("edit");
					}
				}
			} else {
				type = constants.TYPE_TASKS;
				let actions = ["complete", "next_day", "next_week", "next_month"];
				items.push(...actions);
			}
			items.push("delete");

			let props = {
				style: { ...styles.popoverMenuItem },
				fullWidth: true,
				labelStyle: { ...{ color: "#479ccf", fontSize: "12px" } },
				underlinestyle: { ...{ display: "none" }, className: "pomenuitem" },
			};
			let object = getObjectByType(record["type"]);
			items.map((item, i) => {
				let action = {};
				if (item === "edit" && type !== constants.TYPE_TASKS) {
					action = editAction.bind(
						this,
						object,
						record[record.callFrom === "taskPortal" ? "id" : "parentId"],
						record["taskName"],
						record["taskType"]
					);
				} else {
					action = handelAction.bind(this, item.replace("_", ""), record);
				}
				element.push(
					<MenuItem {...props} onClick={action}>
						{getLocalizedStrings().label.APPOINTMENTS_TASKS[item.toUpperCase()]}
					</MenuItem>
				);
			});
		} catch (error) {
			console.error(
				"error in 'detailViewTaskApps.js'-->'generateMenuItem()'" + error
			);
		}
		return element;
	};

	const generateMenuItemForTasks = (record, isEdit) => {
		let element = [];
		let items = [];
		let taskApp = propsTaskApp;
		try {
			let type = record["type"];
			if (type === constants.TYPE_EMAILS || type === constants.TYPE_SMS) {
				if (getObjectParam(taskApp).moduleId !== constants.TASKS_OVERDUE) {
					items.push("sendnow");
					if (isEdit) {
						items.push("edit");
					}
				}
			} else {
				let actions = ["complete", "next_day", "next_week", "next_month"];
				items.push(...actions);
			}

			items.push("delete");

			let props = {
				style: { ...styles.popoverMenuItem },
				fullWidth: true,
				labelStyle: { ...{ color: "#479ccf", fontSize: "12px" } },
				underlinestyle: { ...{ display: "none" }, className: "pomenuitem" },
			};

			let object = getObjectByType(record["type"]);
			items.map((item, i) => {
				let action = {};
				if (item === "edit") {
					action = editAction.bind(
						this,
						object,
						record[record.callFrom === "taskPortal" ? "id" : "parentId"],
						record["taskName"],
						record["taskType"]
					);
				} else {
					action = handelAction.bind(
						this,
						item.replace("_", ""),
						record,
						object
					);
				}
				element.push(
					<MenuItem key={"menu" + i} {...props} onClick={action}>
						{getLocalizedStrings().label.APPOINTMENTS_TASKS[item.toUpperCase()]}{" "}
					</MenuItem>
				);
			});
		} catch (error) {
			console.error(
				"error in 'ColumnViewTaskApps.js'-->'generateMenuItem()'" + error
			);
		}
		return element;
	};

	const handleOnclickDate = (record) => {
		let labelName = record["taskName"];
		let tabInfo = getActiveTabInfo();
		let isUnitsAccount = false;
		if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
			isUnitsAccount = tabInfo.isUnitsAccount;
		}
		let tempObject = record["taskType"];

		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		let _isUnitownerTasksVal = getBooleanParam(isUnitownerTasksVal);
		let hasPermission = null;
		hasPermission = hasAccessPermission(
			constants.TASKS,
			constants.SECURITY_LEVEL_TYPE_ACCESS,
			constants.ACCESS_TYPE_EDIT
		);
		if (hasPermission) {
			let data = {};
			data.id = record["parentId"];
			data.object = constants.TASKS;
			data.labelName = labelName;
			data.propsTaskApp = propsTaskApp

			if (!_isUnitownerTasksVal) {
				dispatch(
					getAppDrawer(
						true,
						labelName,
						constants.MINI_EDIT,
						data,
						constants.OTHER
					)
				);
			}
		} else {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				getLocalizedStrings().message.COMMON.ACCESS_DENIED,
				null
			);
		}
	};

	const hasPermissionError = () => {
		sfDialogs.alert(
			getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
			getLocalizedStrings().message.COMMON.ACCESS_DENIED,
			null
		);
	};
	
	const generateDetailViewTaskAppData = () => {
		let currentTime = getDateObject(
			moment().tz(timeZone[app.me.timezone]).toObject()
		);
		let HHFormat = new RegExp("HH");
		let datePickerOptions = {
			enableTime: true,
			dateFormat: dateTimeFormatFlatPicker[app.me.date_format],
			minuteIncrement: 1,
			time_24hr: HHFormat.test(app.me.date_format) ? true : false,
		};

		let taskAppArr = [];
		let moduleResultInfo = null;
		let fieldList = [];
		let recordList = [];
		let moduleId = null;
		let resultInfo = null;
		let detailViewTaskAppDetailStyle = styles.detailViewTaskAppDetailStyle;
		let detailViewTaskAppNameStyle = styles.detailViewTaskAppNameStyle;
		let isDetailView = propsTaskApp.isDetailView;
		let iconTitle = null;
		let iconName = null;
		let type = null;
		let object = null;
		if (isDetailView) {
			detailViewTaskAppDetailStyle.color = "#b5b5b5";
		} else if (!isDetailView) {
			detailViewTaskAppDetailStyle.color = "#717171";
			detailViewTaskAppNameStyle.width = "320px";
		}

		let isTenantAdmin = app.me.is_tenant;
		let loginUsername = app.me.name;
		let userList = getArrayParam(userlist.data);

		let isUnitownerTasksValNew = getBooleanParam(isUnitownerTasksVal);
		if (isUnitownerTasksValNew && !isUnitownerTasks) {
			moduleResultInfo = unitOwnerTasks;
		} else {
			moduleResultInfo = taskAppData;
		}

		if ((changeLog.taskAppValue === constants.TASK_APP_DONE || changeLog.taskAppValue === constants.TASK_APP_MY_DONE) && isValidParam(taskAppData?.futureTask?.recordList)) {
			taskAppData?.futureTask?.recordList?.map((task) => {
				task?.future?.map((ftask) => {
					if (ftask.type == "email") {
						delete task.future;
					}
				})
			})
		}
		if ((changeLog.taskAppValue === constants.TASK_APP_DONE || changeLog.taskAppValue === constants.TASK_APP_MY_DONE) && isValidParam(taskAppData?.dueTask?.recordList)) {
			taskAppData?.dueTask?.recordList?.map((due) => {
				due.due?.map((type) => {
					if (type.type == "email") {
						delete due.due;
					}
				})
			})
		}

		if (moduleResultInfo !== null && moduleResultInfo !== undefined) {
			for (moduleId in moduleResultInfo) {
				resultInfo = moduleResultInfo[moduleId];
				if (
					resultInfo &&
					resultInfo.hasOwnProperty("fieldList") &&
					resultInfo.hasOwnProperty("recordList")
				) {
					fieldList = resultInfo.fieldList;
					recordList = resultInfo.recordList;
					if (
						fieldList &&
						fieldList.length > 0 &&
						recordList &&
						recordList.length > 0
					) {
						var recordInfo, records, record, key, recordStatus, parentRecordId;
						var totalRecordCount = 0;
						for (var l = 0; fieldList && l < recordList.length; l++) {
							recordInfo = recordList[l];
							if (recordInfo) {
								for (key in recordInfo) {
									recordStatus = key;
									records = recordInfo[key];
									if (records && records.length > 0) {
										totalRecordCount += records.length;
										map(records, (record, i) => {
											let iconText = "";
											let iconStyle = { marginTop: "3px" };
											let taskType = null;
											let cursorPointer = null;
											if (!isUnitownerTasksValNew) {
												cursorPointer = { cursor: "pointer" };
											}
											let toIdList = null;
											if (record["type"] === "task") {
												iconName = "fa fa-clock-o";
												iconTitle =
													getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS; //'Task';
												object = constants.TASKS;
											} else if (record["type"] === "appointment") {
												iconName = "fa fa-calendar";
												iconTitle =
													getLocalizedStrings().label.APPOINTMENTS_TASKS
														.APPOINTMENTS; //'Appointment';
												object = constants.APPOINTMENTS;
											} else if (record["type"] === "email") {
												iconName = "fa fa-paper-plane";
												iconTitle = "Email"; //'Appointment';
												object = constants.EMAIL_OBJECT;
												taskType = record["taskType"];
												if (
													record["toIdList"] !== undefined &&
													record["toIdList"] !== ""
												) {
													toIdList = record["toIdList"];
												}
												datePickerOptions.minDate = currentTime;
											} else if (record["type"] === "sms") {
												iconName = "material-icons";
												iconTitle = "SMS";
												object = constants.SMS_OBJECT;
												iconStyle = { ...iconStyle, fontSize: "15px" };
												iconText = "textsms";
												datePickerOptions.minDate = currentTime;
											}

											let dueByDateObj = moment(record["hdndateField"]);
											dueByDateObj = dueByDateObj.format(
												dateTimeFormat[app.me.date_format]
											);
											let CustomColorTask =
												"rech_date " +
												colourChangeTaskApp(record["hdndateField"]);
											let imageColor = colourChangeTaskApp(
												record["hdndateField"]
											);
											let isEdit = true;
											if (
												(taskType === "DirectMail" && toIdList !== null) ||
												changeLog.taskAppValue === constants.TASK_APP_DONE
											) {
												isEdit = false;
											}
											let hasPermission = hasAccessPermission(
												object,
												constants.SECURITY_LEVEL_TYPE_ACCESS,
												constants.ACCESS_TYPE_EDIT
											);

											taskAppArr.push(
												<div
													key={record["parentId"]}
													style={{
														paddingTop: "5px",
														border: "0px solid blue",
														position: "relative",
													}}
												>
													<div
														className="clearfix"
														style={{ lineHeight: "22px", height: "24px" }}
													>
														<div style={{ float: "left" }}>
															{object === constants.TASKS && (
																<div
																	onClick={() => handleOnclickDate(record)}
																	style={cursorPointer}
																>
																	<span
																		style={{ fontSize: "14px" }}
																		title={iconTitle}
																		className={imageColor}
																	>
																		<i
																			className={iconName}
																			aria-hidden="true"
																			style={iconStyle}
																		>
																			{iconText}
																		</i>
																	</span>
																	<div style={{ marginTop: '-19px' }}
																		className={
																			" dpvalue " +
																			colourChangeTaskApp(
																				record["hdndateField"]
																			)
																		}
																	>
																		<span>{dueByDateObj}</span>
																	</div>

																</div>
															)}
															{object !== constants.TASKS && (
																<div>
																	<PopOver
																		id={type + record["parentId"]}
																		key={type + record["parentId"]}
																		name={type + record["parentId"]}
																		btnType={"dateTime"}
																		btnViewType={"text"}
																		btnLabelClass={
																			" dpvalue " +
																			colourChangeTaskApp(
																				record["hdndateField"]
																			)
																		}
																		btnLabelStyle={{ fontSize: '14px', marginTop: '-18px' }}
																		isShowLabelIcon={true}
																		btnIconSpanStyle={{ fontSize: "14px" }}
																		btnIconTitle={iconTitle}
																		btnIconSpanClass={imageColor}
																		btnIconClass={iconName}
																		btnIconStyle={iconStyle}
																		btnIconText={iconText}
																		containerStyle={{}}
																		hasPermission={hasPermission}
																		hasPermissionError={hasPermissionError}
																		buttonLabel={dueByDateObj}
																		onClose={(date) =>
																			handleFlatPickrDateChange(
																				record,
																				dueByDateObj,
																				date
																			)
																		}
																		
																		value={dueByDateObj}
																		options={datePickerOptions}
																		disable={false}
																	/>
																</div>
															)}
														</div>
														{(changeLog.taskAppValue !== constants.TASK_APP_DONE && changeLog.taskAppValue !== constants.TASK_APP_MY_DONE) && (
															<div
																style={{
																	color: "#717171",
																}}
															>
																{!isUnitownerTasksValNew && (
																	<Icon
																		onClick={(e) =>
																			openPopList(e, record, "task-app")
																		}
																		style={{
																			fontSize: "17px",
																			color: "black",
																			cursor: "pointer",
																			marginTop: "1px",
																			border: "0px solid green",
																		}}
																	>
																		more_vert
																	</Icon>
																)}
																<Menu
																	open={
																		openActionList &&
																		actionid === record["parentId"] &&
																		actionobject === record["type"]
																	}
																	anchorEl={anchorE2}
																	anchorOrigin={{
																		horizontal: "right",
																		vertical: "bottom",
																	}}
																	transformOrigin={{
																		horizontal: "right",
																		vertical: "top",
																	}}
																	onClose={closePopList}
																	getContentAnchorEl={null}
																>
																	{record["type"] === constants.TYPE_TASKS
																		? generateMenuItemForTasks(record, isEdit)
																		: generateMenuItem(record, isEdit)}
																</Menu>
															</div>
														)}
													</div>
													<div style={{ position: "relative", top: "-2px" }}>
														{" "}
														{/*top: "-9px"*/}
														<div
															style={styles.detailViewTaskAppNameStyle}
															title={record["taskName"]}
														>
															<span
																id={
																	record["type"] +
																	record["parentId"] +
																	"taskName"
																}
																onClick={editAction.bind(
																	this,
																	getObjectByType(record["type"]),
																	record["parentId"],
																	record["taskName"],
																	record["taskType"]
																)}
																style={cursorPointer}
																onMouseOver={
																	changeLink.bind(
																		this,
																		record["type"],
																		record["parentId"],
																		"taskName"
																	)

																}
																onMouseLeave={
																	changeLink.bind(
																		this,
																		record["type"],
																		record["parentId"],
																		"taskName"
																	)

																}
															>
																{record["taskName"]}
															</span>
														</div>

														<div
															style={{
																...styles.detailViewTaskAppNameStyle,
																fontSize: 12,
															}}
															title={record["t_status"]}
														>
															<span>{record["t_status"]}</span>
														</div>

														<div
															style={{
																...styles.detailViewTaskAppNameStyle,
																fontSize: 12,
															}}
															title={record["t_owner"]}
														>
															<span>{record["t_owner"]}</span>
														</div>

													</div>
												</div>
											);
										});
									}
								}
							}
						}
					} else {
						//manageNotFound(moduleId, false);
					}
				} else {
					//manageNotFound(moduleId, false);
				}
				resultInfo = null;
				fieldList = null;
				recordList = null;
			}
		}
		if (taskAppArr.length === 0) {
			let titleName =
				getLocalizedStrings().message.APPOINTMENTS_TASKS
					.NO_TASK_APPOINTMENT_FOUND;
			if (detailViewTaskAppintmentReducer.taskAppValue === constants.TASK_APP_DONE) {
				titleName =
					getLocalizedStrings().message.APPOINTMENTS_TASKS
						.NO_TASK_APPOINTMENT_FOUND_FOR_COMPLETED;
			}
			taskAppArr.push(
				<div
					style={{ paddingTop: taskAppArr.length === 0 ? "" : "10px", color: "#717171", textAlign: "left" }}
				>
					<div
						style={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
							width: "180px",
						}}
						title={titleName}
					>
						{titleName}
					</div>
				</div>
			);
		}
		return taskAppArr;
	};



	const openContactDetailView = (record) => {
		try {
			if (isValidParam(record.type)) {
				let linkinfo = record["linkInfo"];
				let parentRecordId = null;
				if (isValidParam(linkinfo)) {
					let commaArray = linkinfo.split(",");
					if (record.type === constants.TYPE_TASKS) {
						for (let index = 0; index < commaArray.length; index++) {
							let underScoreArray = commaArray[index].split("_");
							if (underScoreArray.length === 2 && underScoreArray[0] === 9) {
								parentRecordId = underScoreArray[1];
								openDetailView(parentRecordId, 9, record.fullname);
							}
						}
					} else {
						let underScoreArray = commaArray[0].split("_");
						if (underScoreArray[0] === "9") {
							openDetailView(underScoreArray[1], 9, record.fullname);
						} else {
							let params = {};
							params.child_table_id = underScoreArray[0];
							params.child_record_id = underScoreArray[1];
							params.parent_table_id =
								OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT];
							let promise = Promise.resolve(getLinkedRecordId(params));
							if (isValidParam(promise)) {
								promise.then((response) => {
									if (isValidParam(response) && response.recordId > 0) {
										openDetailView(
											getStringParam(response.recordId),
											9,
											record.fullname
										);
									}
								});
							}
						}
					}
				}
			}
		} catch (error) {
			console.error(
				"Error in 'detailViewTaskApp.js -> openContactDetailView()':" + error
			);
		}
	};

	const openLinkDetails = (recordId, object, labelName) => {
		try {
			let url = "/" + object + "/detailview/" + recordId;
			let tabParams = {
				label: labelName,
				object: object,
				type: TYPE_DETAIL_VIEW,
				url: url,
			};
			addTab(tabParams, true);
		} catch (error) {
			console.error("Error in Calendar.js -> openLinkDetails() : " + error);
		}
	};

	const generateTasksPortal = (type) => {
		let HHFormat = new RegExp("HH");
		let datePickerOptions = {
			enableTime: true,
			dateFormat: dateTimeFormatFlatPicker[app.me.date_format],
			minuteIncrement: 1,
			time_24hr: HHFormat.test(app.me.date_format) ? true : false,
		};
		let responseData = null;
		let records = [];

		let dueBy = "hdndateField";
		let subject = "taskName";
		let owner = "t_owner";
		let company = "company";
		let location = "location";
		let linkinfo = "linkInfo";
		let iconName = "fa fa-clock-o";
		let iconTitle = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS;
		// let iconTitle = 'Task';
		let objectType = null;
		let object = constants.TASKS;
		let parentTableId = 0;
		let parentRecordId = null;
		let recordCount = 0;
		let iconText = "";
		//parentRecurId
		let taskApp = propsTaskApp;
		records = getArrayParam(task.data);
		recordCount = getIntParam(task.taskPortalCount);
		let communicationFields = {};
		let sfFormNew = sfForm.data;
		communicationFields = {};
		let objects = Object.keys(sfFormNew);
		let phoneSetup = task.phoneSetup;
		objects.forEach(function (object) {
			let communication_field = sfFormNew[object].fields.filter(
				(f) => f.is_email_field === true || f.is_phone_field === true
			);
			let communication_temp_field = [];
			communication_field.map((field, i) => {
				if (field.is_phone_field) {
					field = { ...field, ...phoneSetup };
				}
				communication_temp_field.push(field);
			});
			communicationFields[object] = communication_temp_field;
		});
		task.communicationFields = communicationFields;

		if (records.length > 0) {
			responseData = map(records, (record, i) => {
				iconName = "fa fa-clock-o";
				iconText = "";
				record.callFrom = "taskPortal";
				if (isValidParam(record.type)) {
					if (record.type === constants.TYPE_EMAIL) {
						iconName = "fa fa-paper-plane";
						iconTitle = "Email";
					} else if (record.type === constants.TYPE_SMS) {
						iconName = "material-icons";
						iconText = "textsms";
						iconTitle = "SMS";
					}
				}
				parentRecordId = record[linkinfo];
				if (isValidParam(parentRecordId)) {
					let commaArray = parentRecordId.split(",");
					let underScoreArray = commaArray[0].split("_");
					parentTableId = underScoreArray[0];
					parentRecordId = underScoreArray[1];
				}
				let dueByDateObj = moment(record[dueBy]);
				dueByDateObj = dueByDateObj.format(dateTimeFormat[app.me.date_format]);
				if (isValidParam(record.type)) {
					type = record.type;
				}
				let isEdit = true;
				if (
					(record.type === constants.TYPE_EMAILS ||
						record.type === constants.TYPE_SMS) &&
					(getObjectParam(taskApp).moduleId === constants.TASKS_OVERDUE ||
						(record.taskType === "DirectMail" &&
							isValidParam(record.emailIdList) &&
							getStringParam(record.emailIdList) !== ""))
				) {
					isEdit = false;
				}
				let linkRecord = "";
				if (record.hasOwnProperty("linkInfo")) {
					let linkinfo = record.linkInfo.split("_");
					linkinfo = getArrayParam(linkinfo);
					let tableid = linkinfo[0];
					if (tableid === "10") {
						linkRecord = record.company;
					} else if (tableid === "9") {
						linkRecord = record.fullname;
					} else if (tableid === "2") {
						linkRecord = record.opportunity_name;
					} else if (
						isValidParam(record.opportunity_name) &&
						record.opportunity_name.trim() !== ""
					) {
						linkRecord = record.opportunity_name;
					} else if (
						isValidParam(record.company) &&
						record.company.trim() !== ""
					) {
						linkRecord = record.company;
					} else {
						linkRecord = record.fullname;
					}
				}

				let childRecordArr = [];
				let leadId = 0;
				let contactId = 0;
				let opportunityId = 0;

				if (record.hasOwnProperty("linkInfo")) {
					if (record.linkInfo !== undefined && record.linkInfo !== "") {
						childRecordArr = record.linkInfo.split(",");
					}
					childRecordArr.map((item, i) => {
						let ObjRecordArr = item.split("_");
						let tableId = getIntParam(ObjRecordArr[0].trim());
						if (tableId === OBJECT_TABLEID_MAP.accounts) {
							leadId = getIntParam(ObjRecordArr[1]);
						} else if (tableId === OBJECT_TABLEID_MAP.contacts) {
							contactId = getIntParam(ObjRecordArr[1]);
						} else if (tableId === OBJECT_TABLEID_MAP.opportunities) {
							opportunityId = getIntParam(ObjRecordArr[1]);
						}
					});
				}

				let taskInfo = [];
				if (
					app.me.calendar_setup.length > 0 &&
					app.me.calendar_setup[0].taskInfo !== null &&
					app.me.calendar_setup[0].taskInfo !== undefined
				) {
					taskInfo = app.me.calendar_setup[0].taskInfo;
				}
				let isAccount = 1;
				let isContact = 0;
				let isOpportunity = 0;

				if (taskInfo.length > 0) {
					let checkInfoArr = taskInfo.split(",");

					checkInfoArr.map((item, i) => {
						let ObjRecordArr = item.split("_");
						let tableId = getIntParam(ObjRecordArr[0].trim());
						if (tableId === OBJECT_TABLEID_MAP.accounts) {
							isAccount = getIntParam(ObjRecordArr[1]);
						} else if (tableId === OBJECT_TABLEID_MAP.contacts) {
							isContact = getIntParam(ObjRecordArr[1]);
						} else if (tableId === OBJECT_TABLEID_MAP.opportunities) {
							isOpportunity = getIntParam(ObjRecordArr[1]);
						}
					});
				}

				let margin_left = "0px";
				if (record.type !== constants.TYPE_TASKS) {
					margin_left = "2px";
				}
				return (
					<div>
						<div
							key={record["id"] + "-" + i}
							style={{ padding: 10, paddingTop: "5px", paddingLeft: 15 }}
						>
							<div
								className="clearfix"
								style={
									{
										/*lineHeight: '22px', height: '24px'*/
									}
								}
							>
								<div style={{ float: "left", width: "90%" }}>
									{/* <div className=" dpvalue">{dueByDateObj}</div>  */}
									{isEdit ? (
										<div
											style={{
												color: "#717171",
												lineHeight: "19px",
												paddingLeft: 17,
												marginBottom: -19,
												paddingTop: 2,
												marginLeft: margin_left,
											}}
											title={record[subject]}
										>
											<div
												id={record["type"] + record["id"] + "subject"}
												onClick={editAction.bind(
													this,
													type,
													record["id"],
													record[subject],
													record["taskType"]
												)}
												style={{
													cursor: "pointer",
													fontSize: "14px",
													fontWeight: "bold",
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
												}}
												onMouseOver={changeLink.bind(
													this,
													record["type"],
													record["id"],
													"subject"
												)}
												onMouseLeave={changeLink.bind(
													this,
													record["type"],
													record["id"],
													"subject"
												)}
											>
												{record[subject]}
											</div>
										</div>
									) : (
										<div
											style={{
												color: "#717171",
												lineHeight: "19px",
												paddingLeft: 17,
												marginBottom: -19,
												paddingTop: 2,
												marginLeft: margin_left,
											}}
											title={record[subject]}
										>
											<div
												id={record["type"] + record["id"]}
												style={{
													fontSize: "14px",
													fontWeight: "bold",
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
												}}
											>
												{record[subject]}
											</div>
										</div>
									)}

									<span
										style={{ color: "#717171", fontSize: "14px" }}
										title={iconTitle}
									>
										<i
											className={iconName}
											aria-hidden="true"
											style={{ marginTop: "3px", fontSize: "15px" }}
										>
											{iconText}
										</i>
									</span>
								</div>
								{/*//code for action start*/}
								<div
									style={{
										color: "#717171",
									}}
								>
									<Icon
										onClick={(e) => openPopList(e, record, "tasks")}
										style={{
											fontSize: "17px",
											color: "black",
											cursor: "pointer",
											marginTop: "3.5px",
										}}
									>
										more_vert
									</Icon>
									<Menu
										open={
											openActionList &&
											actionid === record["id"] &&
											actionobject === type
										}
										anchorEl={anchorE2}
										anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
										targetOrigin={{ horizontal: "left", vertical: "top" }}
										onClose={closePopList}
										getContentAnchorEl={null}
									>
										{/* <Menu > */}
										{record["type"] === constants.TYPE_TASKS ||
											record["type"] == "tasks"
											? generateMenuItemForTasks(record, isEdit)
											: generateMenuItem(record, isEdit)}
									</Menu>
									{/* </Popover> */}
								</div>
								{/*code for action end*/}
							</div>
							<div>
								<div
									style={{ color: "#717171", lineHeight: 1.5, width: record.status === "In-progress" ? '45%' : '' }}
									title={record.recordStatus}
								>
									{propsTaskApp.callFrom === "Tasks"
										?
										<span
											id={type + record["id"] + "status"}
											style={{ fontSize: "12px" }}
										>
											{record.status}
										</span>
										:
										<span
											id={type + record["id"] + "status"}
											style={{ cursor: "pointer", fontSize: "12px" }}
											onMouseOver={() => changeLinkFields(type, record, 'status')}
											onMouseLeave={() => changeLinkFields(type, record, 'status')}
										>
											{record.status}
										</span>
									}

								</div>

								<div
									id={record["type"] + record["id"] + "date"}
									className=" dpvalue "
									style={{
										padding: 0,
										fontSize: 12,
										marginTop: 0,
										marginBottom: 0,
										color: "#717171",
										lineHeight: 1.5,
									}}
								>
									<span
										id={record["type"] + record["id"] + "date"}
										onClick={editAction.bind(
											this,
											type,
											record["id"],
											record[subject],
											record["taskType"]
										)}
										onMouseOver={changeLink.bind(
											this,
											record["type"],
											record["id"],
											"date"
										)}
										onMouseLeave={changeLink.bind(
											this,
											record["type"],
											record["id"],
											"date"
										)}
										style={{ cursor: "pointer" }}
									>
										{dueByDateObj}
									</span>
									{/** ----- call from Tasks ------ */}
									<div
										className="rech_date dpinvisible calendartaskdp"
										style={{ width: "60%" }}
									></div>{" "}
								</div>

								{record.fullname && isContact > 0 && (
									<div
										style={{ color: "#717171", lineHeight: 1.5 }}
										title={record.fullname}
									>
										<span
											id={type + record["id"] + "contact"}
											onClick={openLinkDetails.bind(
												this,
												contactId,
												constants.CONTACTS_OBJECT,
												record.fullname
											)}
											style={{ cursor: "pointer", fontSize: "12px" }}
											onMouseOver={() => changeLinkFields(type, record, 'contact')}
											onMouseLeave={() => changeLinkFields(type, record, 'contact')}
										>
											{record.fullname}
										</span>
									</div>
								)}

								{isAccount > 0 && (
									<div
										//style={styles.detailViewTaskAppNameStyle}
										style={{ color: "#717171", lineHeight: 1.5 }}
										title={record.company}
									>
										<span
											id={type + record["id"] + "company"}
											onClick={openLinkDetails.bind(
												this,
												leadId,
												constants.ACCOUNTS_OBJECT,
												record.company
											)}
											style={{ cursor: "pointer", fontSize: "12px" }}
											onMouseOver={() => changeLinkFields(type, record, "company")}
											onMouseLeave={() => changeLinkFields(type, record, "company")}
										>
											{record.company}
										</span>
									</div>
								)}

								{record.opportunity_name && isOpportunity > 0 && (
									<div
										style={{ color: "#717171", lineHeight: 1.5 }}
										title={record.opportunity_name}
									>
										<span
											id={type + record["id"] + "opportunity"}
											onClick={openLinkDetails.bind(
												this,
												opportunityId,
												constants.OPPORTUNITIES_OBJECT,
												record.opportunity_name
											)}
											style={{ cursor: "pointer", fontSize: "12px" }}
											onMouseOver={() => changeLinkFields(type, record, 'opportunity')}
											onMouseLeave={() => changeLinkFields(type, record, 'opportunity')}
										>
											{record.opportunity_name}
										</span>
									</div>
								)}
							</div>
						</div>
						<div>
							{/* <hr style={{ margin: 0,color:'rgb(224, 224, 224)'}} /> */}
							<hr style={{ margin: 0, color: "rgb(207, 207, 207)", borderTop: '0.5px solid #eee', height: '0', boxSizing: 'content-box', fontWeight: 'normal' }} />
						</div>
					</div>
				);
			});
		} else {
			return (
				<div
					style={{ paddingTop: "10px", color: "#717171", textAlign: "center" }}
				>
					<div>
						{getLocalizedStrings().message.APPOINTMENTS_TASKS.NO_TASK_FOUND}
					</div>
				</div>
			);
		}
		return responseData;
	};

	// const cookieGetSalesRepOption = () => {
	//     let userid = app.me.id;
	//     let userName = app.me.name;
	//     let cVarName = "salesrep_" + userid + "=";
	//     let cookieVar = decodeURIComponent(document.cookie).split(';');
	//     let tempUser = app.me.name;
	//     if (userlist.mounted) {
	//         let tab = tabManagerActiveTab();
	//         let userList = userlist.data;
	//         for (var i = 0; i < cookieVar.length; i++) {
	//             var c = cookieVar[i];
	//             while (c.charAt(0) === ' ') {
	//                 c = c.substring(1);
	//             }
	//             if (c.indexOf(cVarName) === 0) {
	//                 let uId = c.substring(cVarName.length, c.length);
	//                 uId = parseInt(uId);
	//                 let tempUserList = userList.filter(f => { return f.id === uId });
	//                 if (tempUserList.length > 0) {
	//                     // setSelectedUser(uId)
	//                     // setSelectedSalesRep(tempUserList[0].value)
	//                     tempUser = tempUserList[0].value;
	//                     return tempUserList[0].value;
	//                 }
	//             }
	//             // if(tab!==null){
	//             //     tab.info = { ...tab.info, userName: tempUser, status: ["Pending"], callFrom: 'task_listview' };
	//             //     updateActiveTab(tab);
	//             // }
	//         }
	//         return userName;

	//     }

	// }
	const addActionFnc = (value) => {
		setOpenAddList(false);
		setOpenCompletedMenuList(false);

		if (value === "Appointments") {
			add(constants.APPOINTMENTS);
		} else if (value === "Tasks" || value === constants.UNIT_TASKS) {
			add(constants.TASKS, value);
		} else if (
			value === constants.TASK_APP_OPEN ||
			value === constants.TASK_APP_DONE ||
			value === constants.TASK_APP_MY_OPEN ||
			value === constants.TASK_APP_MY_DONE
		) {
			let log = changeLog;
			log.taskAppValue = value;
			setchangeLog({ ...log });
			setTaskAppValue(value);
			setAppValue(value);
			detailViewTaskAppintmentReducer.taskAppValue = value;

			let parentObject = propsTaskApp.object;
			let recordId = propsTaskApp.recordId;
			let parentTableIds = "";
			if (changeLog.isTaskChild) {
				parentTableIds = "349";
			}
			if (changeLog.isAppointmentChild) {
				parentTableIds += parentTableIds === "" ? "142" : ",142";
			}

			let params = {};
			params.parent_table_id = parentTableIds;
			params.child_table_id = OBJECT_TABLEID_MAP[parentObject];
			params.child_record_id = recordId;
			params.task_app_value = value;

			// if (value === constants.TASK_APP_MY_OPEN || value === constants.TASK_APP_MY_DONE) {
			// 	params.salesRep = cookieGetSalesRepOption();
			// }
			dispatch(getDetailViewTaskAppointment(parentObject, params));
			if (value === constants.TASK_APP_OPEN || value === constants.TASK_APP_DONE) {
				dispatch(getUnitOwnerTasks(params));
			}
		}
	};

	const handleAdd = () => {
		try {
			if (changeLog.isAppointmentChild) {
				add(constants.APPOINTMENTS);
			} else {
				add(constants.TASKS);
			}
		} catch (error) {
			console.error("Error in 'detailViewTaskApp.js -> handleAdd()':" + error);
		}
	};

	const generateAddActions = () => {
		let actions = [];
		let arrActions = [];
		let mapActions = {};
		if (changeLog.isTaskChild) {
			arrActions.push(
				constants.TASKS.charAt(0).toUpperCase() + constants.TASKS.slice(1)
			);
			mapActions[
				constants.TASKS.charAt(0).toUpperCase() + constants.TASKS.slice(1)
			] = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS;

			if (isFranchiseRecordVal) {
				arrActions.push(constants.UNIT_TASKS);
				mapActions[constants.UNIT_TASKS] =
					getLocalizedStrings().label.APPOINTMENTS_TASKS.UNIT_TASKS;
			}
		}
		if (changeLog.isAppointmentChild) {
			arrActions.push(
				constants.APPOINTMENTS.charAt(0).toUpperCase() +
				constants.APPOINTMENTS.slice(1)
			);
			mapActions[
				constants.APPOINTMENTS.charAt(0).toUpperCase() +
				constants.APPOINTMENTS.slice(1)
			] = getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENTS;
		}
		if (arrActions !== null && arrActions.length > 0) {
			if (isValidParam(arrActions)) {
				arrActions.map((actionName, i) => {
					if (actionName !== "Edit") {
						let data = {};
						data.label = mapActions[actionName];
						data.value = actionName;
						actions.push(data);
					}
				});
			}
		}
		if((propsTaskApp.object === constants.ACCOUNTS_OBJECT||propsTaskApp.object === constants.CONTACTS_OBJECT )&& propsTaskApp.callFrom === "DetailView"){
			actions = actions.filter((ele)=> ele.value !== "unit-tasks");
		}
		return actions;
	};

	// const [addAction, setAddAction] = useState(generateAddActions());

	const handleFlatPickrDateChange = (record, dueByDateObj, date) => {
		let newDate = date
		let dateValue = new Date(newDate);
		let id = record.parentId;
		let taskType = record.taskType;
		let object = getObjectByType(record["type"]);
		let selectedDate = new Date(
			dateValue.getFullYear(),
			dateValue.getMonth(),
			dateValue.getDate(),
			dateValue.getHours(),
			dateValue.getMinutes(),
			dateValue.getSeconds()
		);
		const momentDate = moment(selectedDate);
		try {
			let value = momentDate.format("YYYY-MM-DD HH:mm:ss");
			let rescheduledate = momentDate.format(
				dateTimeFormat[app.me.date_format]
			);
			let duration = "date";
			let recordIdArr = [];
			recordIdArr.push(id);
			let msg = "";
			let updateArray = [{ fieldName: "t_dueby", fieldValue: rescheduledate }];
			if (object === constants.TASKS) {
				if (task.data.hasOwnProperty("records")) {
					msg =
						getLocalizedStrings().message.APPOINTMENTS_TASKS
							.RESCHEDULE_TASK_SUCCESS;
					updateArray = [{ fieldName: "t_dueby", fieldValue: rescheduledate }];
				} else {
					msg =
						getLocalizedStrings().message.APPOINTMENTS_TASKS
							.RESCHEDULE_TASK_SUCCESS;
					updateArray = [
						{ fieldName: "hdndateField", fieldValue: rescheduledate },
					];
				}
			} else if (object === constants.APPOINTMENTS) {
				msg =
					getLocalizedStrings().message.APPOINTMENTS_TASKS
						.RESCHEDULE_APPOINTMENT_SUCCESS;
				updateArray = [{ fieldName: "start_date", fieldValue: rescheduledate }];
			} else if (object === constants.EMAIL_OBJECT) {
				msg = getLocalizedStrings().message.MAIL.RESCHEDULE_SUCCESS;
			} else if (object === constants.SMS_OBJECT) {
				msg = getLocalizedStrings().message.SMS_INTEGRATION.RESCHEDULE_SUCCESS;
			}

			let params = {
				id: recordIdArr,
				type: duration,
				date: value,
			};

			if (object === constants.EMAIL_OBJECT) {
				params.emailType = taskType;
			}
			let isReschedule = dueByDateObj !== rescheduledate ? true : false;
			handleCalenderReschedule(params, object, isReschedule, msg);
			if (object === "appointments" && propsTaskApp.isDetailView) {
				// dispatch(detailViewIsMounted(false));
				// dispatch(isDetailViewSet(false));
				// dispatch(refreshDetailViewData(true));
				// dispatch(refreshDetailViewListViewData(true));

				let parentObject = propsTaskApp.object;
				let parentTableIds = "";
				if (changeLog.isTaskChild) {
					parentTableIds = "349";
				}
				if (changeLog.isAppointmentChild) {
					parentTableIds += parentTableIds === "" ? "142" : ",142";
				}
				let param = {};
				param.parent_table_id = parentTableIds;
				param.child_table_id = OBJECT_TABLEID_MAP[propsTaskApp.object];
				param.child_record_id = propsTaskApp.recordId;
				param.task_app_value = changeLog.taskAppValue;
				dispatch(getDetailViewTaskAppointment(propsTaskApp.object, param));
			}

		} catch (error) {
			console.error(
				"Error in 'detailViewTaskApps.js -> handleFlatPickrDateChange()':" +
				error
			);
		}
	};

	useEffect(() => {
		if (isValidParam(taskAppData)) {
			setTimeout(() => {
				setShowTaskApp(true)
			}, 1500)
		}
	}, []);

	useEffect(() => {
		if (redirect) {
			return <Navigate push to={redirectUrl} />;
		}
	}, [redirect]);

	useEffect(() => {
		let taskAppLabelNew = "";
		if (propsTaskApp.isDetailView && (changeLog.isTaskChild || changeLog.isAppointmentChild)) {
			if (app.me.language === "de-DE") {
				setMarginbtnLeft("-10px");
			}
		}
		if (propsTaskApp.callFrom === "DetailView") {
			setPaddingTop("19px");

			if (taskAppMounted) {
				setIsMounted(true);
			}

			if (changeLog.isTaskChild) {
				taskAppLabelNew = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS;
			}
			if (changeLog.isAppointmentChild) {
				if (taskAppLabelNew == "") {
					taskAppLabelNew =
						getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENTS;
				} else {
					taskAppLabelNew +=
						" " +
						getLocalizedStrings().label.COMMON.AND +
						" " +
						getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENTS;
				}
				if (getObjectParam(propsTaskApp).object === constants.JOBS_OBJECT) {
					taskAppLabelNew =
						getLocalizedStrings().label.APPOINTMENTS_TASKS.VISITS;
				}
			}
			if (getBooleanParam(isUnitownerTasks)) {
				taskAppLabelNew =
					getLocalizedStrings().label.APPOINTMENTS_TASKS.UNIT_OWNER_TASKS;
				setPaddingTop("0px");
			}
		} else if (propsTaskApp.callFrom === "Home") {
			setPaddingTop("0px");
			if (task.taskHomeMounted || appointment.mounted) {
				setIsMounted(true);
			}
		} else if (propsTaskApp.callFrom === "Tasks") {
			if (task.taskPortalMounted) {
				setIsMounted(true);
			}
		}

		setTaskAppLabel(taskAppLabelNew);
		let log = changeLog;
		log.taskAppLabel = taskAppLabelNew;
		setchangeLog({ ...log });
	}, [propsTaskApp, task, taskAppMounted, isTaskChild]);

	useEffect(() => {
		let recordId = null;
		let workflowId = null;
		let queryNameForTask = null;
		let queryNameForAppointment = null;
		let queryType = null;
		let queryIdFortask = null;
		let queryIdForAppointment = null;
		let isTaskObject = false;
		let moduleId = null;
		let isAppointmentInChild = false;
		let parentRecordCurrentStatus = null;
		let salesRep = null;
		let sortOrder = null;
		let parentObject = propsTaskApp.object;
		let isDetailView = propsTaskApp.isDetailView;
		let callFrom = propsTaskApp.callFrom;
		if (callFrom === "DetailView") {
			workflowId = propsTaskApp.workflowId;
			recordId = propsTaskApp.recordId;
			isAppointmentInChild = propsTaskApp.isAppointmentInChild;
			//parentRecordCurrentStatus = propsTaskApp.currentStatus;
			isTaskAppInChildObject(parentObject, isAppointmentInChild);
		} else if (callFrom === "Home") {
			queryNameForTask = propsTaskApp.queryNameForTask;
			queryNameForAppointment = propsTaskApp.queryNameForAppointment;
			queryType = propsTaskApp.queryType;
			queryIdFortask = propsTaskApp.queryIdFortask;
			queryIdForAppointment = propsTaskApp.queryIdForAppointment;
		} else if (callFrom === "Tasks") {
			isTaskObject = true;
			moduleId = propsTaskApp.moduleId;
			salesRep = propsTaskApp.salesRep;
			sortOrder = propsTaskApp.sortOrder;
		}
		if (isDetailView) {
			workflowId = propsTaskApp.workflowId;
			recordId = propsTaskApp.recordId;
			let isUnitownerTasksNew = getBooleanParam(isUnitownerTasks);
			setIsUnitownerTasksVal(isUnitownerTasksNew);
			setIsFranchiseRecordVal(getBooleanParam(isFranchiseRecord));
		} else if (!isDetailView) {
			queryNameForTask = propsTaskApp.queryNameForTask;
			queryNameForAppointment = propsTaskApp.queryNameForAppointment;
			queryType = propsTaskApp.queryType;
			queryIdFortask = propsTaskApp.queryIdFortask;
			queryIdForAppointment = propsTaskApp.queryIdForAppointment;
		}
		if (isTaskObject) {
			let params = {
				module_id: moduleId,
				start_index: start_index,
				fetch_size: 20,
				sales_rep: salesRep,
				sort_order: sortOrder,
			};
			dispatch(getTasksPortal(params));
		} else {
			let parentTableIds = null;
			if (changeLog.isTaskChild) {
				parentTableIds = "349";
			}
			if (isAppointmentInChild) {
				if (parentTableIds === null) {
					parentTableIds = "142";
				} else {
					parentTableIds += ",142";
				}
			}
			detailViewTaskAppintmentReducer.taskAppValue = changeLog.taskAppValue;
			let _recordId = recordId;
			let queueObj =
				queueList !== null &&
					queueList !== undefined &&
					queueList.hasOwnProperty("data") &&
					queueList.data !== null
					? queueList.data.object
					: "";
			if (queueObj === constants.TASKS) {
				_recordId = detailViewRecord.id;
			}

			let params = {};
			params.parent_table_id = parentTableIds;
			params.child_table_id = OBJECT_TABLEID_MAP[parentObject];
			params.child_record_id = _recordId;
			params.task_app_value = detailViewTaskAppintmentReducer.taskAppValue !== undefined ? detailViewTaskAppintmentReducer.taskAppValue : changeLog.taskAppValue;
			dispatch(getDetailViewTaskAppointment(parentObject, params));

		}

	}, [propsTaskApp.salesRep, propsTaskApp.sortOrder, propsTaskApp.moduleId, detailViewRecord, taskAppValue, setTaskAppData]);

	let addAction = generateAddActions();
	return (
		<>
			{isMounted ? (
				<div
					style={{
						paddingTop: paddingTop,
						//minHeight: "150px",
						paddingBottom: "21px",
					}}
				>
					{propsTaskApp.isDetailView && (changeLog.isTaskChild || changeLog.isAppointmentChild) && !isUnitownerTasks && (
						<div
							style={{ ...styles.taskAppHeadingStyle, paddingBottom: "5px" }}
						>
							<span
								style={{
									whiteSpace: "nowrap",
									textOverflow: "ellipsis",
									overflow: "hidden",
									width: "230px",
								}}
							>
								{changeLog.taskAppLabel}
							</span>
						</div>
					)}

					{propsTaskApp.isDetailView &&
						propsTaskApp.object !== constants.JOBS_OBJECT && 
						(changeLog.isTaskChild || changeLog.isAppointmentChild) &&
						!getBooleanParam(isUnitownerTasksVal) && (
							<div
								style={{
									display: "flex",
									border: "0px solid green",
									justifyContent: "space-between",
									paddingBottom: '10px'
								}}
							>
								{changeLog.isTaskChild && changeLog.isAppointmentChild ? (
									<div>
										<PopOver
											id={"add"}
											key={"add"}
											name={"add"}
											buttonEndIcon={"keyboard_arrow_down"}
											buttonStyle={{ ...styles.secondaryButton }}
											labelposition={"before"}
											buttonLabel={getLocalizedStrings().label.COMMON.ADD}
											options={addAction}
											onclickAction={(value) => addActionFnc(value)}
											isShowIcon={false}
											title={getLocalizedStrings().label.COMMON.ADD}
											value={""}
										/>
									</div>
								) : (
									<div>
										<Button
											style={{
												...styles.secondaryButton,
												marginLeft: marginbtnLeft,
											}}
											onClick={handleAdd}
										>
											{getLocalizedStrings().label.COMMON.ADD}
										</Button>
									</div>
								)}
								<div>
									<PopOver
										buttonLineOrigin={[22, 0]}
										id={"actionType"}
										key={"actionType"}
										name={"actionType"}
										buttonEndIcon={"keyboard_arrow_down"}
										buttonStyle={{
											...styles.secondaryButton,
											minWidth: "105px",
										}}
										labelposition={"before"}
										buttonLabel={changeLog.taskAppValue}
										options={popOverList}
										onclickAction={(value) => addActionFnc(value)}
										isShowIcon={false}
										title={changeLog.taskAppValue}
										value={""}
										callFrom={"tasksFilter"}
									/>
								</div>
							</div>
						)}
						
					{propsTaskApp.callFrom === "DetailView" && showTaskApp == true && (
						<div style={{ lineHeight: "35px", fontSize: "14px" }}>
							{generateDetailViewTaskAppData()}
						</div>
					)}

					{propsTaskApp.callFrom === "DetailView" && showTaskApp == false && (
						<div style={{ width: "100%", height: pageHeight }}>
							<div
								className="asset-loaderh"
								style={{ paddingTop: top, paddingLeft: "45%" }}
							>
								<div
									style={{
										...styles.assetLoaderContainer,
										height: 50,
										width: 50,
										padding: 7,
									}}
								>
									<ShowCircularProgress
										size={30}
										style={{ marginTop: "3", marginLeft: "3" }}
									/>
								</div>
							</div>
						</div>
					)}
					
					{propsTaskApp.callFrom === "Home" && (
						<div style={{ lineHeight: "35px" }}>
							{generateTasksPortal(constants.TASKS)}
						</div>
					)}
					{propsTaskApp.callFrom === "Tasks" && (
						<div style={{ lineHeight: "35px" }}>
							{sfForm.loaded ? (
								generateTasksPortal(constants.TASKS)
							) : (
								<div style={{ width: "100%", height: pageHeight }}>
									<div
										className="asset-loaderh"
										style={{ paddingTop: top, paddingLeft: "45%" }}
									>
										<div
											style={{
												...styles.assetLoaderContainer,
												height: 50,
												width: 50,
												padding: 7,
											}}
										>
											<ShowCircularProgress
												size={30}
												style={{ marginTop: "3", marginLeft: "3" }}
											/>
										</div>
									</div>
								</div>
							)}
						</div>
					)}
				</div>
			) : (
				<div></div>
			)}
		</>
	);
};

export default DetailViewTaskApps;
