import { actions } from '..//constants/actions';

const referralReportInitialState = {
    referralReportData: {},
}
const referralReportReducer = (state = referralReportInitialState, action) => {
    switch (action.type) {
        case actions.REFERRAL_REPORT_DATA_UPDATE:
            return {
                ...state,
                referralReportData : action.payload,
            }
        default:
            return state;
    }
}
export default referralReportReducer;