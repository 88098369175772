import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { styles } from '../../../services/constants/styles';
import Button from '@mui/material/Button';
import * as ParameterVerifier from '../../../services/helper/parameterVerifier';
import { constants } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { Menu, FormControl, Select, TextField, MenuItem, InputLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";

function ReviewWebsite() {

    const dispatch = useDispatch();
    
	const [isReviewWebsite, setIsReviewWebsite] = useState(false);
    const [reviewWebsiteUrl, setReviewWebsiteUrl] = useState(null);
    const [reviewWebsiteName, setReviewWebsiteName] = useState(null);
    const [isShrinkedName, setIsShrinkedName] = useState(false);
    const [isShrinkedUrl, setIsShrinkedUrl] = useState(false);
    const [isShrinkedDesc, setIsShrinkedDesc] = useState(false);
    const [isShrinkedTitle, setIsShrinkedTitle] = useState(false);
    const [isShrinkedYes, setIsShrinkedYes] = useState(false);
    const [isShrinkedNo, setIsShrinkedNo] = useState(false);
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [yesField, setYesField] = useState(null);
    const [noField, setNoField] = useState(null);
    const[isShrinked,setIsShrinked] = useState(false);

    const handleWebsiteNameChange = (event) => {
        let target = event.target;
        let value = target.value;
       
        setReviewWebsiteName(value);
        setIsShrinked(true);

    }
    const handleWebsiteURLChange = (event) => {
        let target = event.target;
        let value = target.value;
        
       setReviewWebsiteUrl(value);
        setIsShrinked(true);
    }
    const handleSurvey = (event, name) => {
       let value = event.target.value;
       if(name=='title')
        {
        setTitle(value);
        setIsShrinked(true);
    }else if(name== 'description'){
        setDescription(value);
        setIsShrinked(true);
    }
    else if(name== 'yesField'){
        setYesField(value)
        setIsShrinked(true);
    }
    else if(name== 'noField'){
        setNoField(value);
        setIsShrinked(true);
    }
}

   useEffect(() => {
        getWebsiteReview();
    }, []);
    
    const  isValidUrl = (url) => {
        let isValid = false;
        let is_url = ParameterVerifier.isUrl(url);
        if (url !== '' && (!is_url || (is_url && url.startsWith("(") || url.endsWith(")"))
            || (is_url && url.startsWith("{") || url.endsWith("}"))
            || (is_url && url.startsWith("[") || url.endsWith("]")))) {
                dispatch (showCustomSnackBar(getLocalizedStrings().message.REVIEW_WEBSITE.VALID_URL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));

            isValid = false;
        } else {
            isValid = true;
        }
        return isValid;
    }
    const getWebsiteReview = () => {

        try {
           let _reviewWebsiteName = null;
            let _reviewWebsiteUrl = null;
            let _title = null;
            let _description = null;
            let _yesField = null;
            let  _noField = null;
            var promise = Promise.resolve(HTTPClient.get(endPoints.REVIEW_WEBSITE.REVIEW_WEBSITE_GET, null));
            if (ParameterVerifier.isValidParam(promise)) {
                promise.then((response) => {
                    if (ParameterVerifier.isValidParam(response)) {
                        if (response !== null && response.hasOwnProperty("reviewWebsite")) {
                            _reviewWebsiteName = response.reviewWebsite;
                            setIsShrinkedName(true);
                            setReviewWebsiteName(_reviewWebsiteName);

                        }
                        if (response !== null && response.hasOwnProperty("reviewWebsiteUrl")) {
                            _reviewWebsiteUrl = response.reviewWebsiteUrl;
                        setReviewWebsiteUrl(_reviewWebsiteUrl);
                            setIsShrinkedUrl(true);
                        }
                        if (response !== null && response.hasOwnProperty("thumbsUpTitle")) {
                           _title = response.thumbsUpTitle;
                           setIsShrinkedTitle(true);
                           setTitle(_title);

                        }
                        else{
                            _title = 'Thank You!';
                            setTitle(_title);
                            setIsShrinkedTitle(true);
                        }
                        if (response.hasOwnProperty("thumbsUpDesc")) {
                            _description = response.thumbsUpDesc;
                            setDescription(_description);
                            setIsShrinkedDesc(true);

                        }
                        else{
                            _description = 'We appreciate your feedback.If you have a minute,please review us on Yelp';
                            setDescription(_description);
                            setIsShrinkedDesc(true);
                        }
                        if (response.hasOwnProperty("thumbsUpYesBtn")) {
                            _yesField  = response.thumbsUpYesBtn;
                            setYesField(_yesField);
                            setIsShrinkedYes(true);

                        }
                        else{
                            _yesField = 'Yes, take me to Yelp';
                            setYesField(_yesField);
                            setIsShrinkedYes(true);
                        }
                        if (response.hasOwnProperty("thumbsUpNoBtn")) {
                            _noField  = response.thumbsUpNoBtn;
                            setNoField(_noField);
                            setIsShrinkedNo(true);

                        }
                        else{
                            _noField = 'Not at this time';
                            setNoField(_noField);
                            setIsShrinkedNo(true);

                        }
                        
                    }
                    // else{
                    //     this.setState({  title : hiiii });
                    //     //this.state.title = 'hiiii';
                    //     this.state.isShrinkedTitle = true;        
                    // }
                });
            }
           
        } catch (error) {
            console.error("Error in 'ReviewWebsite.js -> getWebsiteReview()':" + error);
        }
    }
    const saveReview = () => {
        
        if (!reviewWebsiteName) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.REVIEW_WEBSITE.WEBSITE_NAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else if (!reviewWebsiteUrl) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.REVIEW_WEBSITE.WEBSITE_URL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
      
        else {
            try{
                let isValid = isValidUrl(reviewWebsiteUrl);
                if(isValid){
                           let params = { "reviewWebsite": reviewWebsiteName, "reviewWebsiteUrl": reviewWebsiteUrl ,"thumbsUpTitle": title,"thumbsUpDesc" : description, "thumbsUpYesBtn" : yesField , "thumbsUpNoBtn" : noField}
                        
                           var promise = Promise.resolve(HTTPClient.post(endPoints.REVIEW_WEBSITE.REVIEW_WEBSITE_SAVE, params));
                           promise.then((response) => {
                               if (ParameterVerifier.isValidParam(response)) {
                                   if (response.status === 0) {
                                    setIsReviewWebsite(true);
                                       dispatch(showCustomSnackBar(getLocalizedStrings().message.REVIEW_WEBSITE.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                   } else {
               
                                   }
               
                               }
                           })
                       }
               
            }
            catch (error) {
                console.error("Error in 'ReviewWebsite.js -> saveReview()':" + error);
            }
        }
       
    
    }
    const onBlurShrink = () => {
        if (reviewWebsiteName === null || reviewWebsiteName === '') {
           setIsShrinkedName(false);
        }
        if (reviewWebsiteUrl === null || reviewWebsiteUrl === '') {
            setIsShrinkedUrl(false);
        }
        if (title === null || title === '') {
           setIsShrinkedTitle(false);
        }
        if (description === null || description === '') {
           setIsShrinkedDesc(false);
        }
        if (yesField === null || yesField === '') {
           setIsShrinkedYes(false);
        }
         if (noField === null || noField === '') {
            setIsShrinkedNo(false);
        }
    }
  
        return (
            <>
                <div style={{  marginLeft: '6%' , width:'85%'}}>
<div><span style={{fontSize: 24}}>{getLocalizedStrings().label.COMMON.SURVEY_SETUP} </span> </div>
                    <div style={{marginTop:'10px', marginBottom: '2px'}}><span style={{fontSize: 19}}> {getLocalizedStrings().label.COMMON.WEBSITE_REVIEW} </span></div>
                    <div className="row">
                        <div style={{width:'60%'}}>
                            <FormControl style={{ width: '100%', marginLeft: '0px' }} className="test" noValidate autoComplete="off">
                                <TextField style={{}}
                                    variant="outlined"
                                    label={getLocalizedStrings().label.EXPORT_SETTINGS.REVIEW_WEBSITE_REQUIRED}
                                    fullWidth={true}
                                    name="name"
                                    id="name"
                                    onFocus={()=> setIsShrinkedName(true)}
                                    value={reviewWebsiteName}
                                    onChange={(e)=>handleWebsiteNameChange(e)}
                                    disabled={false}
                                    autoFocus
                                    inputprops={{ maxlength: '128' }}
                                    autoComplete="new-password"
                                    margin='dense'
                                    size='small'
                                    className={"sf-fields-bg"}
                                    InputLabelProps={{
                                        shrink: isShrinkedName,
                                    }}
                                    onBlur={()=>onBlurShrink()}

                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className="row">
                        <div style={{width:'60%'}}>
                            <FormControl style={{ width: '100%', marginTop: '8px' }} className="test" noValidate autoComplete="off">
                                <TextField style={{}}
                                    variant="outlined"
                                    label= {getLocalizedStrings().label.COMMON.REVIEW_WEBSITE_REQUIRED}
                                    fullWidth={true}
                                    name="name"
                                    onFocus={()=>setIsShrinkedUrl(true)}
                                    id="name"
                                    value={reviewWebsiteUrl}
                                    onChange={(e)=>handleWebsiteURLChange(e)}
                                    disabled={false}
                                    inputprops={{ }}
                                    autoComplete="new-password"
                                    margin='dense'
                                    size='small'
                                    className={"sf-fields-bg"}
                                    InputLabelProps={{
                                        shrink: isShrinkedUrl,
                                    }}
                                    onBlur={()=>onBlurShrink()}

                                />
                            </FormControl>
                        </div>
                    </div>
                    <div style={{marginTop:'8px'}}><span style={{fontSize: 19}}>{getLocalizedStrings().label.COMMON.THANKS_SURVEY}  </span></div>
                    <div className="row">
                        <div style={{width:'60%'}}>
                            <FormControl style={{ width: '100%'}} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    label={getLocalizedStrings().label.COMMON.THUMBS_UP_TITLE}
                                    fullWidth={true}
                                    name="title"
                                    id="name"
                                    value={title}
                                    onChange={(e) => handleSurvey(e, 'title')}
                                    disabled={false}
                                    margin='dense'
                                    size='small'
                                    className={"sf-fields-bg"}
                                    onBlur={()=>onBlurShrink()}
                                    InputLabelProps={{
                                        shrink: isShrinkedTitle,
                                    }}
                                   onFocus={()=>setIsShrinkedTitle(true)}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className="row">
                        <div style={{width:'60%'}}>
                        <FormControl style={{ width: '100%', marginTop: '18px' }} className="test" noValidate autoComplete="off">
                            <TextField
                                type='textarea'
                                variant="outlined"
                                id='notetext'
                                multiline={true}
                                rows={4}
                                label={getLocalizedStrings().label.COMMON.THUMBS_UP_DESC}
                                value={description}
                                onChange={(e) => handleSurvey(e, 'description')}
                                textareastyle={{ marginTop: '10px', height: '90%' }}
                                autoComplete="new-password"
                                className={"sf-fields-bg"}
                                InputLabelProps={{
                                    shrink: isShrinkedDesc,
                                }}
                                onBlur={()=>onBlurShrink()}
                               onFocus={()=>setIsShrinkedDesc(true)}

                            />
                        </FormControl>
                        </div>
                    </div>
                      <div style={{display: 'flex', marginLeft:'1px'}}>
                        <div style={{width:'30%'}}>
                            <FormControl style={{marginTop: '13px', width:'100%'}} noValidate autoComplete="off">
                                <TextField style={{}}
                                    variant="outlined"
                                    label= {getLocalizedStrings().label.COMMON.YES_BUTTON}
                                    fullWidth={true}
                                    name="yesField"
                                    //onFocus={() => this.setState({ isShrinkedUrl: true })}
                                    value={yesField}
                                    onChange={(e) => handleSurvey(e, 'yesField')}
                                    disabled={false}
                                    margin='dense'
                                    size='small'
                                    className={"sf-fields-bg"}
                                    InputLabelProps={{
                                        shrink: isShrinkedYes,
                                    }}
                                    onBlur={()=>onBlurShrink()}
                                     onFocus={() => setIsShrinkedYes(true)}
                                />
                            </FormControl>
                        </div>
                        <div  style={{width:'28.5%' }}>
                            <FormControl style={{ marginTop: '13px',  width:'100%', marginLeft: '15px' }} noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    label= {getLocalizedStrings().label.COMMON.NO_BUTTON}
                                    fullWidth={true}
                                    name="noField"
                                    onFocus={ ()=>setIsShrinkedNo(true)}
                                   value={noField}
                                    onChange={(e) => handleSurvey(e, 'noField')}
                                    disabled={false}
                                    margin='dense'
                                    size='small'
                                    className={"sf-fields-bg"}
                                    InputLabelProps={{
                                        shrink: isShrinkedNo,
                                    }}
                                    onBlur={()=>onBlurShrink()}
    

                                />
                            </FormControl>
                        </div>
                    </div>
                  
                    <div style={{ float: 'right', width: '52%'}}>
                        <Button variant="contained" style={{ ...styles.primaryButton, border: '', fontSize: 13, marginTop: '20px', marginBottom: '21px', marginLeft: '61px' }} color="primary" size="small" primary={true} onClick={() => saveReview()} >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                    </div>

                </div>
            </>
        );
    // }

    
}
export default ReviewWebsite;
