import { actions } from '..//constants/actions';
import { isValidParam, getBooleanParam } from '..//helper/parameterVerifier';

const appContainerInitialSate = {
    mounted: false,
    view: null,
    error: null,
    drawerProps: {
        isOpenDrawer: false,
        actionDialogName: null,
        actionType: null,
        callFrom: null,
        data: null,
        isWaiting: false
    },
    dialogProps: {
        isOpenDialog: false,
        dialogType: null,
        dialogTitle: null,
        dialogActions: null,
        eventName: null,
        data: {},
        callFrom: null,
        callBackActions: null,
        
    },
    customDrawerProps: {
        isOpen: false,
        dialogName: '',
        actionType: '',
        callFrom: null,
        data: {},
        style: {},
        appBarProps: {},
        isMinimize: false,
        minimizeList: {},
        minimizeStyle: {},
        minimizable: false,
    },
}

const appContainerReducer = (state = appContainerInitialSate, action) => {
    let isCustomDrawerOpen = getBooleanParam(state.customDrawerProps.isOpen);
    let isMinimize = getBooleanParam(state.customDrawerProps.isMinimize);
    switch (action.type) {
        case actions.APP_CONTAINER_LOADER:
            return {
                ...state,
                mounted: true,
                view: action.payload
            }
        case actions.APP_CONTAINER_DRAWER_OPEN_CLOSE:
            if (isCustomDrawerOpen && !isMinimize) {
                return {
                    ...state,
                }
            } else {
                return {
                    ...state,
                    drawerProps: {
                        isOpenDrawer: action.payload.isOpenDrawer,
                        actionDialogName: action.payload.actionDialogName,
                        actionType: action.payload.actionType,
                        callFrom: action.payload.callFrom,
                        data: action.payload.data
                    }
                }
            }
        case actions.APP_CONTAINER_DIALOG_OPEN_CLOSE:
            return {
                ...state,
                dialogProps: {
                    isOpenDialog: action.payload.isOpenDialog,
                    dialogType: action.payload.dialogType,
                    dialogTitle: action.payload.dialogTitle,
                    eventName: action.payload.eventName,
                    dialogActions: null,
                    data: action.payload.data,
                    callFrom: action.payload.callFrom
                }
            }
        case actions.APP_CONTAINER_DIALOG_TITLE_UPDATE:
            return {
                ...state,
                dialogProps: {
                    ...state.dialogProps,
                    dialogTitle: action.payload.dialogTitle,
                }
            }
        case actions.APP_CONTAINER_DIALOG_SET_ACTIONS:
            return {
                ...state,
                dialogProps: {
                    ...state.dialogProps,
                    dialogActions: action.payload.dialogActions
                }
            }
        case actions.APP_CONTAINER_DIALOG_SET_CALLBACK_ACTIONS:
            return {
                ...state,
                dialogProps: {
                    ...state.dialogProps,
                    callBackActions: action.payload.callBackActions
                }
            }
        case actions.APP_CONTAINER_DIALOG_RESET_CALLBACK_ACTIONS:
            return {
                ...state,
                dialogProps: {
                    ...state.dialogProps,
                    callBackActions: null
                }
            }
        case actions.APP_CONTAINER_CUSTOM_DRAWER_OPEN_CLOSE:
            if (isMinimize) {
                return {
                    ...state,
                }
            } else {
                return {
                    ...state,
                    customDrawerProps: action.payload.isOpen ? {
                        ...state.customDrawerProps,
                        isOpen: action.payload.isOpen,
                        dialogName: action.payload.dialogName,
                        actionType: action.payload.actionType,
                        style: action.payload.style,
                        callFrom: action.payload.callFrom,
                        data: action.payload.data,
                        appBarProps: action.payload.headerProps,
                        minimizable: action.payload.minimizable
                    } :
                        appContainerInitialSate.customDrawerProps
                }
            }
        case actions.APP_CONTAINER_CUSTOM_DRAWER_MINIMIZE_MAXIMIZE:
            let dispatchObj = {};
            dispatchObj.isMinimize = action.payload.isMinimize;
            if (isValidParam(action.payload.dialogName)) {
                dispatchObj.dialogName = action.payload.dialogName;
            }

            if (isValidParam(action.payload.style)) {
                dispatchObj.minimizeStyle = action.payload.style;
            }

            if (isValidParam(action.payload.data)) {
                dispatchObj.data = { ...state.customDrawerProps.data, ...action.payload.data };
            }

            dispatchObj.appBarProps = action.payload.headerProps;

            return {
                ...state,
                customDrawerProps: {
                    ...state.customDrawerProps,
                    ...dispatchObj
                }
            }
        case actions.APP_CONTAINER_DRAWER_SET_TITLE:
            return {
                ...state,
                drawerProps: {
                    ...state.drawerProps,
                    actionDialogName: action.payload.actionDialogName,
                }
            }
        default:
            return state;
    }
}

export default appContainerReducer;