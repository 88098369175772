import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { constants } from "../../../services/constants/constants";
import Cookies from "universal-cookie";
import {
	submitLoginCredential,
	submitForgotPassword,
} from "../../../services/actions/loginAction.js";
import * as ParameterVerifier from "../../../services/helper/parameterVerifier";
import { deleteAllTabCookiesAfterlogin } from "../../../services/helper/sfCookies";
import Box from "@mui/material/Box";
// import { styles } from '../../../services/constants/styles'
import { Icon } from "@mui/material";

const styles = {
	labelRoot: {
		fontSize: 30,
		color: "red",
	},
	labelFocused: {},
	root: {
		minWidth: 275,
	},
	bullet: {
		display: "inline-block",
		margin: "0 2px",
		transform: "scale(0.8)",
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
};

function Login(props) {
	const cookies = new Cookies();
	const [state, setState] = useState({
		componentView: "login",
		isPasswordShown: false,
		user: {
			userName: cookies.get("userName") || "",
			password: "",
			isRemember: false,
			isAutenticated: false,
			loginMessage: "",
			errorTextEmail: "",
			errorTextPassword: "",
			is_subscription_overdue: false,
			utid: "",
		},
		forgotPw: {
			userName: "",
			errorTextEmail: "",
			loginMessage: "",
		},
		isForgotPw: false,
		isMailSend: false,
		isMailNotSend: false,
	});

	const handleInputChange = (value, name) => {
		let user = state.user;
		user[name] = value;
		if (name === "userName" && value.trim() !== "") {
			user["errorTextEmail"] = "";
		}
		if (name === "password" && value !== "") {
			user["errorTextPassword"] = "";
		}
		state.user = user;
		setState({ ...state });
	};

	const handleInputChangePw = (e) => {
		const forgotPw = state.forgotPw;
		const target = e.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;
		forgotPw["userName"] = value;
		if (name === "userName" && value.trim() !== "") {
			forgotPw["errorTextEmail"] = "";
		}
		state.forgotPw = forgotPw;
		setState({ ...state });
	};

	const submitLoginCredentialByEnter = (e) => {
		var key = e.which;
		if (key === 13) {
			// the enter key code
			submitLogin(e);
		}
	};

	const submitForgotpwCredentialByEnter = (e) => {
		var key = e.which;
		if (key === 13) {
			// the enter key code
			forgotPassword(e);
		}
	};

	const toggleForgotpwLogin = (e, flag) => {
		const forgotPw = {
			userName: "",
			errorTextEmail: "",
			loginMessage: "",
		};
		state.isForgotPw = flag;
		state.isMailSend = false;
		state.forgotPw = forgotPw;
		setState({ ...state });
	};
	const toggleResetpwLogin = (e, flag) => {
		const forgotPw = {
			userName: "",
			errorTextEmail: "",
			loginMessage: "",
		};
		state.isForgotPw = flag;
		state.isMailSend = false;
		state.forgotPw = forgotPw;
		state.isMailNotSend = false;
		setState({ ...state });
	};

	const validateForm = () => {
		const user = state.user;
		let validReq = true;
		if (user.userName.trim() === "") {
			validReq = false;
			user["errorTextEmail"] = "Please enter your email";
			state.user = user;
			setState({ ...state });
		}
		if (user.password === "") {
			validReq = false;
			user["errorTextPassword"] = "Please enter your password";
			state.user = user;
			setState({ ...state });
		}
		return !validReq ? false : true;
	};

	const validateFormForForgotpw = () => {
		const forgotPw = state.forgotPw;
		let validReq = true;
		if (forgotPw["userName"].trim() === "") {
			validReq = false;
			forgotPw["errorTextEmail"] = "Please enter your email address.";
			state.forgotPw = forgotPw;
			setState({ ...state });
		}
		return !validReq ? false : true;
	};

	const submitLogin = (e) => {
		if (localStorage.getItem("isSFInvalidate") !== null) {
			localStorage.removeItem("isSFInvalidate");
		}
		if (!validateForm()) {
			return false;
		}

		if (state.user.isRemember === true) {
			cookies.set("userName", state.user.userName, { path: "/" });
		}
		// reset the state loginMessage
		const user = state.user;
		user["loginMessage"] = "";
		state.user = user;
		setState({ ...state });
		let promise = submitLoginCredential(
			state.user.userName.trim(),
			state.user.password
		);
		if (promise !== null) {
			promise.then((response) => {
				if (ParameterVerifier.isValidParam(response)) {
					if (response.status === 0) {
						let sfFavicon = document.getElementById("sf-favicon");
						if (sfFavicon !== null) {
							sfFavicon.href = null;
						}
						localStorage.setItem("token", response.data.token);
						localStorage.setItem(
							"global_franchisor_token",
							response.data.token
						);
						localStorage.setItem("isLogin", true);
						deleteAllTabCookiesAfterlogin();
						let isSSAdmin = false;
						isSSAdmin = response.data.is_admin;
						if (isSSAdmin) {
							let redirectUrl = constants.snapshotUrl;
							redirectUrl += "&token=" + response.data.token;
							redirectUrl += '&callingfor=admin';
							window.location.replace(redirectUrl);
							localStorage.removeItem("token");
						} else {
							if (constants.APP_URL === "") {
								window.location.replace("/");
							} else {
								window.location.replace(constants.APP_URL);
							}
						}
					} else {
						if (
							response.data.hasOwnProperty("is_subscription_overdue") === true
						) {
							user["loginMessage"] =
								"Your subscription is overdue. Please click on continue.";
							let tempState= state;
								tempState.user.is_subscription_overdue= true;
								tempState.loginMessage="Your subscription is overdue. Please click on continue.";
								tempState.user.utid=response.data.utid;
								setState({...tempState});
						} else {
							user["loginMessage"] =
								"You have entered an incorrect email or password";
							user.password = "";
							state.user = user;
							setState({ ...state });
						}
					}
				} else {
					user["loginMessage"] = "Invalid Response.";
					state.user = user;
					setState({ ...state });
				}
			});
			promise.catch((error) => {
				if (ParameterVerifier.isValidParam(error)) {
					user["loginMessage"] = error.message;
					state.user = user;
					setState({ ...state });
				}
			});
		}
	};
	const forgotPassword = (e) => {
		if (localStorage.getItem("isSFInvalidate") !== null) {
			localStorage.removeItem("isSFInvalidate");
		}
		if (!validateFormForForgotpw()) {
			return false;
		}

		const forgotPw = state.forgotPw;
		forgotPw["loginMessage"] = "";
		state.forgotPw = forgotPw;
		setState({ ...state });

		let promise = submitForgotPassword(state.forgotPw["userName"].trim());
		if (promise !== null) {
			promise.then((response) => {
				if (ParameterVerifier.isValidParam(response)) {
					if (response.status === 0) {
						forgotPw["errorTextEmail"] = "";
						state.isMailSend = true;
						state.isMailNotSend = false;
						state.isForgotPw = false;
						state.forgotPw = forgotPw;
						setState({ ...state });
					} else if (response.status === -1) {
						state.isMailSend = false;
						state.isMailNotSend = true;
						state.isForgotPw = false;
						setState({ ...state });
					}
				} else {
					forgotPw["loginMessage"] = "Invalid Response.";
					state.forgotPw = forgotPw;
					setState({ ...state });
				}
			});
			promise.catch((error) => {
				if (ParameterVerifier.isValidParam(error)) {
					forgotPw["loginMessage"] = error.message;
					state.forgotPw = forgotPw;
					setState({ ...state });
				}
			});
		}
	};

	const openSubscriptionTab = (e) => {
		let redirectUrl = constants.snapshotSubscriptionUrl;
		let utid = state.user.utid;
		redirectUrl += "?utid=" + utid;
		window.open(redirectUrl);
	};

	const toggleSubscriptionLogin = (e, flag) => {
		const user = {
			userName: cookies.get("userName") || "",
			password: "",
			isRemember: false,
			isAutenticated: false,
			loginMessage: "",
			errorTextEmail: "",
			errorTextPassword: "",
			is_subscription_overdue: false,
			utid: "",
		};
		state.user = user;
		setState({ ...state });
	};
	const togglePasswpordVisibility = (e) => {
		const { isPasswordShown } = state;
		state.isPasswordShown = !isPasswordShown;
		setState({ ...state });
	};

	const { isPasswordShown } = state;
	let isSFInvalidate = localStorage.getItem("isSFInvalidate");
	const loginError = {
		paddingLeft: "5px",
		font: "16px arial",
		color: "#FF0000",
	};
	console.log("subscription", state.user.is_subscription_overdue)
	return (
		<div className="col-md-6 offset-md-3" style={{overflowY:'hidden', height:'100%'}}>
			<div className="col-md-10 offset-md-1">
				<div>
					<>
						<div
							className="abc"
							style={{
								width: state.isMailSend ? "500px" : "514px",
								marginTop: "32%",
								height:'100%',
								marginLeft: state.isMailSend ? "32px" : "",
							}}
						>
							{!state.isForgotPw &&
								!state.isMailSend &&
								!state.isMailNotSend &&
								!state.user.is_subscription_overdue && (
									<div>
										<Box boxShadow={3} className="sf-login-box">
											<Card>
												<CardContent className="sf-tlength-card">
													<Typography
														className="sf-login-Typography"
														color="textSecondary"
														gutterBottom
														bottommargin="10px"
													>
														Welcome back!
													</Typography>
													{state.user.loginMessage !== "" && (
														<div className="error" style={loginError}>
															{state.user.loginMessage}
														</div>
													)}
													{isSFInvalidate ? (
														<div className="error" style={loginError}>
															{
																"You have been signed out due to inactivity. Please log in again."
															}
														</div>
													) : null}
													<div className="sf-email-adr">
														<TextField
															error={state.user.errorTextEmail !== ""}
															helperText={state.user.errorTextEmail}
															variant="standard"
															label="Email address"
															fullWidth={true}
															value={state.user.userName}
															name="userName"
															onChange={(e) =>
																handleInputChange(e.target.value, "userName")
															}
															onKeyPress={(e) =>
																submitLoginCredentialByEnter(e)
															}
														/>
													</div>
													<div className="sf-pwd-adr">
														<TextField
															error={state.user.errorTextPassword !== ""}
															helperText={state.user.errorTextPassword}
															variant="standard"
															label="Password"
															type={isPasswordShown ? "text" : "password"}
															fullWidth={true}
															value={state.user.password}
															name="password"
															onChange={(e) =>
																handleInputChange(e.target.value, "password")
															}
															onKeyPress={(e) =>
																submitLoginCredentialByEnter(e)
															}
														/>

														<i
															className={
																isPasswordShown
																	? "fa fa-eye"
																	: "fa fa-eye-slash"
															}
															style={{
																position: "absolute",
																marginTop: "18px",
																marginLeft: "-20px",
																fontSize: 18,
																cursor: "pointer",
															}}
															onClick={(e) => togglePasswpordVisibility(e)}
														/>
													</div>
													<FormControlLabel
														control={
															<Checkbox
																color="default"
																checked={state.user.isRemember}
																value={state.user.isRemember}
																name="isRemember"
																onChange={(e) =>
																	handleInputChange(
																		e.target.checked,
																		"isRemember"
																	)
																}
																defaultChecked={false}
															/>
														}
														label="Remember me"
														style={{
															marginLeft: "-12px",
															marginTop: "15px",
															float: "left",
															width: "40%",
															fontWeight: "normal",
														}}
													/>
												</CardContent>
												<CardActions style={{width:'100%'}}>
													<Button
														variant="contained"
														className="offset-md-5"
														style={{
															backgroundColor: "#3fc7f6",
															fontSize: 14,
															marginBottom: "21px",
														}}
														color="primary"
														size="large"
														onClick={(e) => submitLogin(e)}
													>
														Login
													</Button>
												</CardActions>
											</Card>
										</Box>
										<div style={{ paddingTop: "10px" }}>
											<div>
												<a
													onClick={(e) => toggleForgotpwLogin(e, true)}
													style={{
														cursor: "pointer",
														float: "left",
														fontSize: "16px",
													}}
												>
													{" "}
													Forgot password?
												</a>
											</div>
										</div>
									</div>
								)}

							{/*code for forgot password*/}
							{state.isForgotPw &&
								!state.isMailSend &&
								!state.user.is_subscription_overdue && (
									<Box boxShadow={3} className="sf-login-box">
										<Card
										
											style={{ paddingRight: "9px", paddingLeft: "9px" }}
										>
											<CardContent style={{ marginTop: "20px" }}>
												<Typography
													className="sf-login-Typography"
													color="textSecondary"
													gutterBottom
													bottommargin="10px"
												>
													Reset Your Password
												</Typography>
												<Typography
													color="textSecondary"
													gutterBottom
													align="justify"
													style={{ fontSize: 15 }}
												>
													Please enter your email address. We will send you the
													instructions on how to reset your password.
												</Typography>
												{state.forgotPw.loginMessage !== "" && (
													<div className="error" style={loginError}>
														{state.forgotPw.loginMessage}
													</div>
												)}
												{/* <CardText style={{ paddingTop: '0px', paddingButton: '0px' }}> */}
												<TextField
													error={state.forgotPw.errorTextEmail !== ""}
													helperText={state.forgotPw.errorTextEmail}
													variant="standard"
													label="Email address"
													fullWidth={true}
													value={state.forgotPw.userName}
													name="userName"
													onChange={(e) => handleInputChangePw(e)}
													onKeyPress={(e) => submitForgotpwCredentialByEnter(e)}
												/>{" "}
											</CardContent>
											<CardActions
												style={{ float: "right", paddingRight: "17px" }}
											>
												<div style={{ textAlign: "center" }}>
													<Button
														variant="contained"
														style={{
															backgroundColor: "#3fc7f6",
															fontSize: 13,
															marginTop: "30px",
															marginBottom: "21px",
															marginRight: "10px",
														}}
														color="primary"
														size="large"
														primary={true}
														onClick={(e) => forgotPassword(e)}
													>
														Send
													</Button>
													<Button
														variant="contained"
														style={{
															backgroundColor: "#ffffff",
															fontSize: 13,
															marginTop: "30px",
															marginBottom: "21px",
															color: "#000000",
															fontWeight: "normal",
														}}
														size="large"
														onClick={(e) => toggleForgotpwLogin(e, false)}
													>
														Cancel
													</Button>
												</div>
											</CardActions>
										</Card>
									</Box>
								)}
							{state.isMailSend && (
								<div>
									<Box boxShadow={3} className="sf-login-box">
										<Card
										
											style={{ height: "300px" }}
										>
											<div style={{ marginTop: "50px", marginLeft: "44%" }}>
												<i
													className="fa fa-envelope fa-w-16 fa-2x"
													style={{ fontSize: "4em" }}
												></i>{" "}
											</div>
											<CardContent>
												<Typography
													className="sf-login-Typography"
													color="textSecondary"
													align="center"
													gutterBottom
													bottommargin="10px"
												>
													Check Your Inbox
												</Typography>
												<Typography
													color="textSecondary"
													gutterBottom
													align="justify"
													style={{ fontSize: 15 }}
												>
													We have emailed you the instructions to reset your
													password. Please check your spam folder if you don't
													see the email.
												</Typography>
											</CardContent>
										</Card>
									</Box>
								</div>
							)}
							{state.isMailNotSend && (
								<div>
									<Box boxShadow={3} className="sf-login-box">
										<Card
										
											style={{ height: "340px" }}
										>
											<div style={{ marginTop: "60px", marginLeft: "45%" }}>
												<i
													className="fas fa-exclamation-circle	"
													style={{ fontSize: "4em" }}
												></i>{" "}
											</div>
											<CardContent>
												<Typography
													className="sf-login-Typography"
													color="textSecondary"
													align="center"
													gutterBottom
													bottommargin="10px"
												>
													Unknown Email Address
												</Typography>
												<Typography
													color="textSecondary"
													gutterBottom
													align="justify"
													style={{
														fontWeight: "400",
														fontSize: 15,
														paddingLeft: "45px",
														paddingRight: "45px",
													}}
												>
													We could'nt find your email address in our database.
													Click the button below to try again.
												</Typography>
											</CardContent>
											<div style={{ textAlign: "center" }}>
												<Button
													variant="contained"
													style={{
														backgroundColor: "#3fc7f6",
														fontSize: 13,
														marginRight: "15px",
													}}
													color="primary"
													size="large"
													onClick={(e) => toggleResetpwLogin(e, true)}
												>
													Try again
												</Button>
											</div>
										</Card>
									</Box>
								</div>
							)}
							{state.user.is_subscription_overdue && (
								<div>
									<Box boxShadow={3} className="sf-login-box">
										<Card>
											<CardContent>
												<div style={{ display:'flex', marginLeft:'15px'}}>
													<div
														className="icon_sent"
														style={{
															fontSize: "24px !important",
															marginTop: "4px",
															marginLeft: "-15px",
															marginRight: "12px",
															color: "#fe5722 ",
														}}
													>
														<Icon>error</Icon>{" "}
													</div>
													<Typography
														color="textSecondary"
														gutterBottom
														style={{
															fontSize: "27px",
															color: "#000",
															display: "block",
															lineHeight: "36px",
															marginBottom: "15px",
														}}
													>
														Subscription Error
													</Typography>
												</div>
												
												<Typography
													style={{ fontSize: "14px", paddingTop: "0px" }}
												>
													Your subscription is not valid. Click the Continue
													button for details.
												</Typography>
											</CardContent>
											<CardActions>
												<div
													style={{
														textAlign: "center",
														marginLeft: "27.5%",
														height: "78px",
														marginTop: "-17px",
													}}
												>
													<Button
														variant="contained"
														style={{
															marginTop: "30px",
															marginBottom: "21px",
															marginRight: "10px",
															backgroundColor: "#3fc7f6",
															fontSize: 13,
															width: "45%",
														}}
														color="primary"
														size="large"
														onClick={(e) => openSubscriptionTab(e, false)}
													>
														Continue
													</Button>
													<Button
														variant="contained"
														style={{
															backgroundColor: "#ffffff",
															color: "#717171",
															fontSize: 13,
															marginTop: "30px",
															marginBottom: "21px",
															width: "39%",
														}}
														size="large"
														onClick={(e) => toggleSubscriptionLogin(e, false)}
													>
														Cancel
													</Button>
												</div>
											</CardActions>
										</Card>
									</Box>
								</div>
							)}
						</div>
					</>
				</div>
			</div>
		</div>
	);
}

export default Login;
