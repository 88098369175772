import React, { useState, useEffect } from "react";
import { getListViewData } from "../../../services/actions/listViewAcions";
import {
	isValidParam,
	getStringParam,
	getIntParam,
	getObjectParam,
	getBooleanParam,
	getArrayParam,
} from "../../../services/helper/parameterVerifier";
import ShowCircularProgress from "../components/circularProgress";
import { styles } from "../../../services/constants/styles";
import { getActiveTabInfo } from "../../../services/helper/sfTabManager";
import Email from "../components/email";
import { constants } from "../../../services/constants/constants";
import NoRecords from "../components/noRecord";
import Pagination from "../components/pagination";
import { useDispatch, useSelector } from "react-redux";

const EmailContainer = ({
	isDetailView,
	parentObject,
	parentRecordId,
	childQueryParams,
	object,
}) => {
	const dispatch = useDispatch();
	const listView = useSelector((state) => {
		let tabInfo = getActiveTabInfo();
		let isReport = getBooleanParam(tabInfo.isReport);
		if (getBooleanParam(isDetailView)) {
			return state.childListView;
		} else if (getBooleanParam(isReport)) {
			return state.otherListView;
		} else {
			return state.listView;
		}
	});
	const [pageHeight] = useState(window.innerHeight - 247);
	const [top] = useState((pageHeight - 10) / 2);
	const [data, setData] = useState(null);
	const [records, setRecords] = useState([]);
	const [isMounted, setIsMounted] = useState([]);

	useEffect(() => {
		let mount = getBooleanParam(listView.mounted);
		setIsMounted(mount);
		if (mount) {
			setData(getObjectParam(listView.data));
		}
	}, [listView]);

	useEffect(() => {
		if (isMounted && data !== null){
			setRecords(getArrayParam(data.records));
		} 
	}, [isMounted, data]);

	const getEmailData = (objectValue, params) => {
		object = getStringParam(object);
		object = object === "" ? objectValue : object;
		params = getObjectParam(params);
		params = { ...params, ...childQueryParams };
		params.parent_object = getStringParam(parentObject);
		params.parent_record_id = getIntParam(parentRecordId);
		params.isDetailView = true;

		dispatch(getListViewData(object, params));
	};

	useEffect(() => {
		getEmailData();
	}, []);

	const getEmailToRender = (records) => {
		let arrEmailComponent = [];
		try {
			records = getArrayParam(records);
			if (records.length > 0) {
				records.forEach((record, index) => {
					if (isValidParam(record) && Object.keys(record).length > 0) {
						if(record.t_status !== "Archived") {
							arrEmailComponent.push(
								<Email
									key={"email_" + index}
									parentObject={parentObject}
									parentRecordId={parentRecordId}
									data={record}
									getListViewEmailChildData={getEmailData}
								/>
							);
						}
					}
				});
			} else {
				arrEmailComponent.push(<NoRecords object={constants.EMAIL_OBJECT} />);
			}
		} catch (error) {
			console.error(
				"Error in 'emailContainer.js -> getEmailToRender()':" + error
			);
		}
		return arrEmailComponent;
	};

	return (
		<div style={{ paddingTop: "7px" }}>
			{isMounted && (
				<div className="col-md-12">
					{records.length > 0 && (
						<div className="row">
							<Pagination
								object={object}
								listView={listView}
								listViewCallBack={getEmailData}
							/>
						</div>
					)}
					{getEmailToRender(records)}
				</div>
			)}
			{!isMounted && (
				<div style={{ width: "100%", height: pageHeight }}>
					<div
						className="asset-loaderh"
						style={{ paddingTop: top, paddingLeft: "45%" }}
					>
						<div
							style={{
								...styles.assetLoaderContainer,
								height: 50,
								width: 50,
								padding: 7,
							}}
						>
							<ShowCircularProgress
								size={30}
								style={{ marginTop: "3", marginLeft: "3" }}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default EmailContainer;
