import { actions } from '..//constants/actions'

const fieldsInitialState = {
    sfFromData: {},
    sfRecentData: {},
    sfRecentField:['t_name'],
    reminder: [
        {
            t_reminder_type: 'Email',
            t_time_value: '10',
            t_time_type: 'minutes'
        }
    ],
    data: {},
    noteType: [],
    loaded: false,
    //memorizing selected value for find and unlink
    fieldDetails:{}
}

const sfFormReducer = (state = fieldsInitialState, action) => {
    switch (action.type) {
        case actions.FIELDS_GET_FULFILLED:
            let objectName = action.payload.object;
            let object = state.data;
            object[objectName] = action.payload;
            return {
                ...state,
                data: object,
                loaded: true
            }
        case actions.SF_FROM_DATA_UPDATE:
            return {
                ...state,
                sfFromData: action.payload
            }
        case actions.UPDATE_SF_RECENT_DATA:
            return {
                ...state,
                sfRecentData: {...state.sfRecentData,[action.payload.object]:action.payload.data}
            }
        case actions.CLEAR_SF_RECENT_DATA:
            return {
                ...state,
                sfRecentData: {}
            }
            case actions.UPDATE_SFFORM_REMINDER_FUNC:
                return {
                    ...state,
                    reminder : action.payload.data,
                }

        case actions.SET_FIELD_DETAILS:
            const { name, value } = action.payload;
            return {
                ...state,
                fieldDetails: { ...state.fieldDetails, [name]: value }
            }
        default:
            return state;
    }
}
export default sfFormReducer;