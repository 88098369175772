import React from 'react';
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isValidParam, getStringParam, getObjectParam, getIntParam, getBooleanParam, getArrayParam, getFloatParam } from '../../../services/helper/parameterVerifier';
import { setHeader } from '../../../services/actions/headerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { Select, MenuItem, InputLabel, FormControl, ListItemText, Checkbox } from '@mui/material';
import Button from '@mui/material/Button';
import SFDataTable from '../components/sfDataTable';
import ShowCircularProgress from '../components/circularProgress';
import { addTab, getActiveTab, TYPE_LIST_VIEW, getActiveTabInfo, updateTabInfo } from '../../../services/helper/sfTabManager';
import { TASKS_REPORTS_MAP, constants } from '../../../services/constants/constants';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { updateTasksReportData, updateTasksReportWorkflows } from '../../../services/actions/tasksReportActions';
import { dateFormat, dateFormatFlatPicker } from '../../../services/helper/utils';
import moment from 'moment';
import PopOver from '../components/PopOver';
import Grid from '@mui/material/Grid';
import SFAutoComplete from '../components/sfAutoComplete';

const optionvalue = {
    td: 'Today',
    yd: 'Yesterday',
    tw: 'This Week',
    tm: 'This Month',
    tq: 'This Quarter',
    ty: 'This Year',
    lw: 'Last Week',
    lm: 'Last Month',
    lq: 'Last Quarter',
    ly: 'Last Year',
    cust: 'Custom'
};

function formatDate(date) {
    if (isValidParam(date)) {
        let d = date instanceof Date ? date : new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('/');
    }
    return null;
}


const TasksReport = (props) => {
    const { object } = props;
    const inputRef = useRef(null);
    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const report = useSelector((state) => state.report);
    const tasksReport = useSelector((state) => state.tasksReport);
    const userlist = useSelector((state) => state.userlist);

    const [dueBy, setDueBy] = useState("tm");
    const [from_date, setFrom_date] = useState(null);
    const [to_date, setTo_date] = useState(null);
    const [workflowId, setWorkflowId] = useState(-99);
    const [workflow, setWorkflow] = useState('');
    const [workflowList, setWorkflowList] = useState(null);
    const [reportParams, setReportParams] = useState({
        due_by: 'tm',
        from_date: null,
        to_date: null,

    },);
    const [isMounted, setIsMounted] = useState(false);
    const [fields, setFields] = useState([]);
    const [records, setRecords] = useState([]);
    const [rowSort, setRowSort] = useState({
        sortFieldName: 'name',
        isDescending: false,
        onSort: (fieldName, isDescending, rows)=> onSort (fieldName, isDescending, rows)
    });
    const [customTimeLineDisplay, setCustomTimeLineDisplay] = useState('none');
    const [total, setTotal] = useState(0);
    const [userNames, setUserNames] = useState([app.me.name]);
    const [fieldName, setFieldName] = useState(null);
    const [userListOptions, setUserListOptions] = useState([]);
    
    useEffect(() => {
        report.showProgressBar = false;
        if (isValidParam(props)) {
            let info = getActiveTabInfo();
            if (isValidParam(info) && Object.keys(info).length > 0 && info.hasOwnProperty("due_by")) {
                let cols = [];
                let rows = [];
                let tempWorkflowList = [];
                let tempTotal = 0;
                setStateFromTabInfo();
                if (isValidParam(tasksReport)) {
                    let tasksReportWorkflows = getArrayParam(tasksReport.tasksReportWorkflows);
                    tempWorkflowList = getArrayParam(tasksReportWorkflows);
                    if (tempWorkflowList.length > 0 && (workflowId === -99 && !info.workflowId)) {
                        let workflowId = getIntParam(tempWorkflowList[0].ID);
                        let workflow = getStringParam(tempWorkflowList[0].NAME);
                        setWorkflow(workflow);
                        setWorkflowId(workflowId);
                    }

                    let tasksReportData = getArrayParam(tasksReport.tasksReportData);
                    cols = getFieldList();
                    rows = getArrayParam(tasksReportData);
                    tempTotal = setTotalTasks(rows);
                }
                setIsMounted(true);
                setFields(cols)
                setRecords(rows)
                setRowSort(rowSort)
                setTotal(tempTotal);
                if(tempWorkflowList.length >0){
                    setWorkflowList(tempWorkflowList);
                }else{
                    reloadReport();
                }

            } else {
                reloadReport();
            }
            dispatch(setHeader(object, getLocalizedStrings().label.REPORT['Task Report'], null, false, true));
        }
    }, []);

    useEffect(()=>{
        let tempUserlist = getArrayParam(userlist.data);
        let indx = tempUserlist.findIndex((obj) => obj.id === -999)
        if (indx !== -1) {
            tempUserlist.pop();
        }
        tempUserlist = tempUserlist.map((user, index) => {
            return { id: user.value, label: user.value };
        });
        setUserListOptions(tempUserlist)
    },[])

    const setStateFromTabInfo = () => {
        let info = getActiveTabInfo();
        if (isValidParam(info) && Object.keys(info).length > 0 && info.hasOwnProperty("due_by")) {
            let tempRowSort = getObjectParam(rowSort);
            setDueBy(info.due_by);
            setWorkflowId(info.workflowId);
            setWorkflow(info.workflow);
            setUserNames(info.userNames);
            if (info.hasOwnProperty("isDescending")) {
                tempRowSort.isDescending = info.isDescending;
            }
            if (info.due_by === 'cust') {
                let From_date = moment(getStringParam(info.from_date)).format(dateFormat[app.me.date_format])
                let To_date = moment(getStringParam(info.to_date)).format(dateFormat[app.me.date_format])
                setFrom_date(From_date);
                setTo_date(To_date);
                setCustomTimeLineDisplay('block');
            }
            setRowSort(tempRowSort);
            setReportParamsData();
        }
    }

    const setReportParamsData = () => {
        let tempDueBy = getStringParam(dueBy);
        let tempFromDate = from_date;
        let tempToDate = to_date;
        let tempWorkflowId = workflowId;
        let tempUserNames = userNames.filter(f => (f !== 'All'));
        tempUserNames = tempUserNames.toString();
        let params = {
            due_by: tempDueBy,
            from_date: formatDate(tempFromDate),
            to_date: formatDate(tempToDate),
            workflow_id: tempWorkflowId,
            users: tempUserNames,
        };
        setReportParams(params);
        return params;
    }

    const getMultilingualOptionsMap = () => {
        let optionsMultilingual = {
            'Today': getLocalizedStrings().label.DASHBOARD.TODAY,
            'Yesterday': getLocalizedStrings().label.DASHBOARD.YESTERDAY,
            'This Week': getLocalizedStrings().label.DASHBOARD.THIS_WEEK,
            'This Month': getLocalizedStrings().label.DASHBOARD.THIS_MONTH,
            'This Quarter': getLocalizedStrings().label.DASHBOARD.THIS_QUARTER,
            'This Year': getLocalizedStrings().label.DASHBOARD.THIS_YEAR,
            'Last Week': getLocalizedStrings().label.DASHBOARD.LAST_WEEK,
            'Last Month': getLocalizedStrings().label.DASHBOARD.LAST_MONTH,
            'Last Quarter': getLocalizedStrings().label.DASHBOARD.LAST_QUARTER,
            'Last Year': getLocalizedStrings().label.DASHBOARD.LAST_YEAR,
            'Custom': getLocalizedStrings().label.DASHBOARD.CUSTOM
        };
        return optionsMultilingual;
    }

    const generateDateSelectionField = () => {
        let optionsMultilingual = getMultilingualOptionsMap();
        try {
            return Object.keys(optionvalue).map((itemKey) => {
                return <MenuItem key={itemKey} value={itemKey} style={styles.popoverMenuItem}>{optionsMultilingual[optionvalue[itemKey]]}</MenuItem>;
            });
        }
        catch (error) {
            console.error("Error in 'pipelineReport.js -> generateDateSelectionField()':" + error);
        }
    }

    const generateWorkflowSelectionField = () => {
        let tempWorkflowList = getArrayParam(workflowList);
        try {
            let isExist = tempWorkflowList.filter((f) => {
                return f.ID === 0;
            });
            if (isExist.length === 0) {
                tempWorkflowList.unshift({ ID: 0, NAME: 'All' });
            }

            return tempWorkflowList.map((item, index) => {
                return <MenuItem key={item.ID} id={item.ID} value={item.NAME} style={styles.popoverMenuItem}>{item.NAME}</MenuItem>;
            });
        }
        catch (error) {
            console.error("Error in 'tasksReport.js -> generateWorkflowSelectionField()':" + error);
        }
    }

    const handleRequestClose = (fieldName) => {
        try {
            setFieldName(false);
        }
        catch (error) {
            console.error("Error in 'tasksReport.js -> handleRequestClose()':" + error);
        }
    };

    const getButtonSet = () => {
        return (
            <div>
                <iframe id="ifmcontentstoprint" title="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute', display: 'none' }}></iframe>
                <Button
                    onClick={() => reloadReport()}


                    style={{
                        ...styles.primaryButton,
                        minWidth: '56px'
                    }}
                    title={getLocalizedStrings().label.REPORT.GO}
                > {getLocalizedStrings().label.REPORT.GO}</Button>
                <Button


                    onClick={() => {
                        var content = document.getElementById("divcontents");
                        var ifrmae = document.getElementById("ifmcontentstoprint");
                        ifrmae.style.display = 'block'
                        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                        ifrmae.style.display = 'block'

                        pri.document.open();
                        pri.document.write(

                            content.innerHTML);
                        pri.document.close();
                        pri.focus();
                        pri.print();
                        ifrmae.style.display = 'none'
                    }}

                    style={{
                        ...styles.secondaryButton,
                        minWidth: '40px'
                    }}
                    title={getLocalizedStrings().label.REPORT.PRINT}
                > {getLocalizedStrings().label.REPORT.PRINT}</Button>
                <Button
                    startIcon={<i className="far fa-file-excel" style={{ fontSize: '20px', color: 'green' }} />}
                    onClick={() => { downloadExcel(); }}

                    style={{
                        ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                        color: '#717171',
                        border: '0px',
                        minWidth: '30px'
                    }}
                    title="Excel"
                ></Button>
            </div>
        );
    }
    
    const handleDropDownChange = (name, event, value) => {
        try {
            let tempWorkflowId = workflowId
            let tempWorkflow = workflow;
            if (name === "due_by") {
                value = value.props.value;
                setDueBy(value);
                if (value === "cust") {
                    setCustomTimeLineDisplay('block');
                    let tempDate = moment(String(new Date())).format(dateFormat[app.me.date_format]);
                    setFrom_date(tempDate);
                    setTo_date(tempDate);
                } else {
                    setCustomTimeLineDisplay('none');
                    setFrom_date(null);
                    setTo_date(null);

                }
            }
            else if (name === "workflow") {

                let workflowObj = workflowList.filter((f) => {
                    return f.NAME === value;
                });

                tempWorkflowId = workflowObj[0].ID;
                tempWorkflow = workflowObj[0].NAME;
            }
            else if (name === "userNames") {
                let finalData = [];
                userListOptions.map((user) => {
                    let data = getStringParam(user.id);
                    if (data !== "") {
                        finalData.push(data);
                    }
                });
                if(userNames.length === 0 && value.indexOf("All")  !== -1) {
                    setUserNames(['All',...finalData]);
                    return;
                }
                if (value.indexOf("All") === -1) {
                    if(value.length === finalData.length) {
                        setUserNames((prevState) => { 
                            let data;
                            if(prevState.length >= finalData.length) {
                                data = []
                            } else {
                                data = ['All',...finalData]
                            }
                            return data;
                        })
                        return;
                    }
                } 
                if(userNames.length  <= finalData.length && value.indexOf("All") !== -1) {
                    setUserNames(['All',...finalData]);
                    return;
                }
                if(value.length >= finalData.length && value.indexOf("All")  !== -1) {
                    setUserNames(value.splice(1));
                    return;
                }
                setUserNames(value);

            }
            setWorkflowId(tempWorkflowId);
            setWorkflow(tempWorkflow);
            handleRequestClose(name + "_popup");
        }
        catch (error) {
            console.error("Error in 'tasksReport.js -> handleDropDownChange()':" + error);
        }
    }

    const getReportFilter = () => {
        let buttonSet = getButtonSet();

        let HHFormat = new RegExp("HH");
        let datePickerOptions = {
            enableTime: false,
            dateFormat: dateFormatFlatPicker[app.me.date_format],
            minuteIncrement: 1,
            static: true,
            time_24hr: HHFormat.test(app.me.date_format) ? true : false
        };
        let wFList = generateWorkflowSelectionField();

        let optionsMultilingual = getMultilingualOptionsMap();
        let popOverList = [];
        Object.keys(optionvalue).forEach((itemKey) => {
            let data = {};
            data.label = optionsMultilingual[optionvalue[itemKey]];
            data.value = itemKey;
            popOverList.push(data);
        });

        let popOverWorkflowList = [];
        let tempWorkflowList = getArrayParam(workflowList);
        tempWorkflowList.forEach((workflow, index) => {
            let data = {};
            data.label = workflow.NAME;
            data.value = workflow.ID;
            popOverWorkflowList.push(data);
        });
        let userNameOptions = [{id: "All", label: "All"}, ...userListOptions]
        return (   
            <div className="row" style={{ padding: '0px' }} >
                <div >
                    <Grid container spacing={0} >
                        <Grid item xs={12} style={{ display: 'flex', marginLeft: "17px" }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ padding: 0 }} >
                                    <div
                                        style={{ width: '100%', fontSize: '14px', color: '#717171' }}
                                        id='userNames'
                                        key='userNames'
                                    >
                                        <FormControl variant="outlined" style={{ marginLeft: 10, marginTop: 9 }}>
                                            <InputLabel style={{ margin: '-6px' }} id="sf-taskreport-simple-select-outlined-label-user" className="sf-sfformresults">{"Select User"}</InputLabel>
                                            <Select
                                                key={"userNames"}
                                                multiple
                                                renderValue={(selected) => selected.join(', ')}
                                                onChange={(event) => handleDropDownChange('userNames', event,event.target.value)}
                                                label={"Select user"}
                                                style={{ minWidth: 250, maxWidth: 250, height: '36px' }}
                                                value={userNames}
                                            >
                                                {
                                                    userNameOptions.map((listValue, listValueIndex) => {
                                                        return <MenuItem id={listValue.id} key={listValue.id} value={listValue.id} title={listValue.label}>
                                                            <Checkbox checked={userNames?.filter(e => e === listValue.id).length > 0 ? true : false} />
                                                            <ListItemText primary={listValue.label} />
                                                        </MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>

                                </div>
                                <div  >
                                    <FormControl variant="outlined" style={{ marginLeft: 10, width: '248px' }}>
                                        <SFAutoComplete
                                            key={'sf-auto-complete-workflowList'}
                                            id="sf-taskreport-simple-select-outlined"
                                            name={'workflowList'}
                                            label={getLocalizedStrings().label.REPORT.WORKFLOW_SELECT}
                                            menuItems={wFList}
                                            disabled={false}
                                            onChange={(event, value) => handleDropDownChange('workflow', event, value)}
                                            value={workflow}
                                            ref={inputRef}
                                            inputStyle={styles.textField.input}
                                            menuItemStyle={styles.popoverMenuItem}
                                            isFullHeight={false}


                                        />
                                    </FormControl>
                                </div>
                                <div style={{ paddingLeft: '7px' }} >
                                    <div
                                        style={{ width: '100%', fontSize: '14px', color: '#717171', height: '47px', marginRight: "7px", marginTop: 9 }}
                                        id='due_by'
                                        key='due_by'
                                    >
                                        <FormControl variant="outlined">
                                            <InputLabel id="sf-task-report-fromtaskreport-simple-select-outlined-label" className="sf-task-report-fromtaskreport">{getLocalizedStrings().label.REPORT.DATE_RANGE}</InputLabel>
                                            <Select
                                                labelId="sf-task-report-fromtaskreport-simple-select-outlined-label"
                                                id="sf-task-report-fromtaskreport-simple-select-outlined"
                                                value={dueBy}
                                                className={"sf-fields-bg"}
                                                onChange={(event, value) => handleDropDownChange('due_by',event, value)}
                                                label={getLocalizedStrings().label.REPORT.DATE_RANGE}
                                                style={{ minWidth: 250, maxWidth: 250, height: '36px' }}
                                            >
                                                {generateDateSelectionField()}
                                            </Select>
                                        </FormControl>

                                    </div>
                                </div>

                                <div style={{ display: customTimeLineDisplay }}>
                                    <div id="dateholder" style={{ margin: '9px 7px 0 0'}}>
                                        <PopOver
                                            id={'from_date'}
                                            key={'from_date'}
                                            name={'from_date'}
                                            btnType={'date'}
                                            buttonStyle={{ fontSize: '16px'}}
                                            containerStyle={{ lineHeight: 1 }}
                                            buttonLabel={getLocalizedStrings().label.REPORT.START_DATE}
                                            onChange={(date) => setFrom_date(date)}
                                            value={from_date}
                                            innerLabelStyle={{ padding: "8 5 2 5", fontSize: '15px' }}
                                            height={36}
                                            options={datePickerOptions}
                                        />
                                    </div>

                                </div>
                                <div style={{ display: customTimeLineDisplay }}>
                                    <div id="dateholder" style={{ margin: '9px 7px 0 0'}}>
                                        <PopOver
                                            id={'to_date'}
                                            key={'to_date'}
                                            name={'to_date'}
                                            btnType={'date'}
                                            buttonStyle={{ fontSize: '16px' }}
                                            containerStyle={{ lineHeight: 1 }}
                                            buttonLabel={getLocalizedStrings().label.REPORT.END_DATE}
                                            onChange={(date) => setTo_date(date)}
                                            value={to_date}
                                            innerLabelStyle={{ padding: "8 5 2 5", fontSize: '15px' }}
                                            height={36}
                                            options={{ ...datePickerOptions, minDate: from_date }}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div style={{ marginTop: '10px' }}>{buttonSet}</div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    const getReportHeaderName = () => {
        let component = null;
        component = (
            <div style={{ ...styles.sf_12 }}>
                <div className="row" style={{ paddingLeft: "2px" }}>
                    <span className="text" style={{ margin: '0px', fontSize: '22px' }}><h3 style={{ fontWeight: 'bold' }}>{getLocalizedStrings().label.TASKS_REPORT.TASKS_REPORT_LABEL}</h3></span>
                </div>
            </div>)
        return component;
    }

    const getFieldList = () => {
        let columnHeaderList = [
            { field_type: "text", is_date: false, is_hidden: false, is_detail_link: false, is_sql_col: false, label: "User name", name: "name" },
            { field_type: "integer", is_date: false, is_hidden: false, is_detail_link: false, is_sql_col: false, label: "Number of Tasks", name: "tasks", style: { textAlign: 'center' } },
            { field_type: "text", is_date: false, is_hidden: false, is_detail_link: true, is_sql_col: false, label: "Number of Tasks Completed", name: "completed", style: { textAlign: 'center' } },
            { field_type: "integer", is_date: false, is_hidden: false, is_detail_link: true, is_sql_col: false, label: "Number of Tasks Overdue", name: "overdue", style: { textAlign: 'center' } },
            { field_type: "integer", is_date: false, is_hidden: false, is_detail_link: true, is_sql_col: false, label: "Number of Tasks Rescheduled", name: "rescheduled", style: { textAlign: 'center' } },
        ]
        return columnHeaderList;
    }

    const setTotalTasks = (result) => {
        let records = getArrayParam(result);
        let total = 0;
        records.forEach((row, index) => {
            total += getIntParam(row.tasks);
        });
        return total;
    }

    const reloadReport = () => {
        let params = setReportParamsData();
        let _rowSort = getObjectParam(rowSort);
        let records = null;
        let _workflowList = null;
        let _workflowId = workflowId;
        let _workflow = getStringParam(workflow);
        let fields = getFieldList();
        let total = 0;
        let url = endPoints.TASKS_REPORT.TASK_REPORT_GET;
        let activeTab = getActiveTab();
        let info = getObjectParam(activeTab.info);
        try {
            params.due_by = dueBy;
            setIsMounted(false);
            _rowSort.isDescending = false;
            info.workflowId = params.workflow_id;
            info.workflow = workflow;
            info.due_by = params.due_by;
            info.userNames = userNames;
            info.isDescending = getBooleanParam(rowSort.isDescending);
            //info.total = this.state.total;
            if (dueBy === 'cust') {
                info.from_date = getStringParam(params.from_date);
                info.to_date = getStringParam(params.to_date);
            }
            updateTabInfo(info);
            let promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    records = getArrayParam(response.tasksReport);
                    sortArrayObjectByProperty(records, "name");
                    total = setTotalTasks(records);
                    dispatch(updateTasksReportData(records));
                    _workflowList = getArrayParam(response.tasksReportWFList);
                    if (_workflowList.length > 0 && _workflowId === -99) {
                        _workflowId = getIntParam(_workflowList[0].ID);
                        _workflow = getStringParam(_workflowList[0].NAME);
                    }
                    dispatch(updateTasksReportWorkflows(workflowList));
                    setIsMounted(true);
                    setFields(fields);
                    setRecords(records);
                    setRowSort(_rowSort);
                    setWorkflowList(_workflowList);
                    setWorkflowId(_workflowId);
                    setWorkflow(_workflow);
                    setTotal(total);
                }
            });
        } catch (error) {
            console.log("Error in 'tasksReport.js -> reloadReport()':" + error)
        }
    }

    const getNoRecordContent = () => {
        return (
            <div style={{display: 'flex', backgroundColor: 'white', borderBottom: '1px solid #D3D3D3' }}>
                <span style={{ color: 'red', margin: '5px 0 5px 8px', fontSize: '14px' }}>{getLocalizedStrings().label.INXPRESS_REPORT.NO_RECORDS_FOUND}</span>
            </div>
        );
    }

    const excelGenerateBeforeHeaders = () => {
        let optionsMultilingual = getMultilingualOptionsMap();
        let tempReportParams = getObjectParam(reportParams);
        let cells = '', temp;
        let dueBy = '';
        let reportName = getStringParam(getLocalizedStrings().label.TASKS_REPORT.TASKS_REPORT_LABEL);
        let columns = fields.filter(f => {
            if (!f.is_hidden) {
                temp = f;
            }
            return temp;
        });
        let columnsLength = getArrayParam(columns).length;

        cells = cells + '<tr><td  colspan="' + (columnsLength) + '"> ' + reportName + ' </td></tr>';
        if (reportName.length > 0) {
            dueBy = getStringParam(optionsMultilingual[optionvalue[tempReportParams.due_by]]);
            cells = cells + '<tr><td> Date :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + dueBy + ' </td></tr>';
        }
        return cells;
    }

    const excelGenerateHeaders = () => {
        let cells = '', temp;
        let columns = fields.filter(f => {
            if (!f.is_hidden) {
                temp = f;
            }
            return temp;
        });
        columns.forEach(f => {
            cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    const excelGenerateRows = () => {
        let rows = formatData(records);
        let columns = getArrayParam(fields);
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            rows.forEach((rowObject, rowIndex) => {
                let innerCells = '';
                columns.forEach((columnObject, colIndex) => {
                    if (!columnObject.is_hidden) {
                        innerCells = innerCells + '<td >' + rowObject[columnObject.name] + ' </td>';
                    }
                })
                if (rowIndex % 2 === 0) {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                } else {
                    cells = cells + ('<tr  bgcolor="Lavender">' + innerCells + ' </tr>');
                }

            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
        }
        return rowData;

    }

    const downloadExcel = () => {
        let beforeHeaderComponent = excelGenerateBeforeHeaders();
        let headerComponents = excelGenerateHeaders();
        let rowComponents = excelGenerateRows();
        let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '</tbody></table>';
        let htmlData = htmlTable;
        if (isValidParam(htmlData) && htmlData !== '') {
            htmlData = htmlData.replace("%22", "\"");
            var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
            var downloadUrl = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = downloadUrl;
            a.download = 'TasksReportResultSet_' + new Date().getTime() + '.xls';
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    }

    const customEvent = (eventType, column, row, event) => {
        try {
            let columnValue = getStringParam(row[column.name + '_value']).trim();
            columnValue = parseFloat(columnValue);
            if (isValidParam(columnValue) && columnValue !== "" && columnValue > 0.0) {
                let status = '';
                if (column.name === 'completed') {
                    status = "Completed";
                } else if (column.name === 'overdue') {
                    status = "Overdue";
                } else if (column.name === 'rescheduled') {
                    status = "Rescheduled";
                }

                let label = getStringParam(getLocalizedStrings().label.TASKS_REPORT.TASKS_REPORT_LABEL);
                let object = constants.TASKS;
                let reportLabel = getStringParam(column.label);
                let url = '/' + object + '/queries';
                let activeTab = getObjectParam(getActiveTab());
                let activeTabIndex = getIntParam(activeTab.index);
                let tab = { label: label, object: object, type: TYPE_LIST_VIEW, parentTabIndex: activeTabIndex, url: url };
                let tabInfo = {};
                tabInfo.isReport = true;
                tabInfo.reportLabel = reportLabel
                tabInfo.input_param = {
                    report_due_by: getStringParam(dueBy),
                    report_user_names: getStringParam(row.name),
                    is_report: true,
                    report_type: TASKS_REPORTS_MAP.TYPE_TASKS_REPORT,
                    report_click_from: 'DRILLDOWN',
                    status: status,

                };
                if (workflowId > 0) {
                    tabInfo.input_param.report_search_condition = "t_workflowid = " + workflowId;
                }
                if (tabInfo.input_param.report_due_by === 'cust') {
                    tabInfo.input_param.report_from_date = getStringParam(from_date);
                    tabInfo.input_param.report_to_date = getStringParam(to_date);
                }
                tab.info = tabInfo;
                setWorkflowId(workflowId);
                addTab(tab, true);
            }
        } catch (error) {
            console.error("Error in 'tasksReport.js -> customEvent()':" + error);
        }
    }

    const sortArrayObjectByPropertyDesc = (array, property) => {
        let temp;
        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];

                    val1 = val1 instanceof String ? val1.toLowerCase() : val1;
                    val2 = val2 instanceof String ? val2.toLowerCase() : val2;

                    if (val1 > val2) {
                        temp = -1;
                    } else if (val1 < val2) {
                        temp = 1;
                    } else {
                        temp = 0;
                    }
                }
                return temp;
            });
        }
    }

    const onSort = (fieldName, isDescending, records) => {
        let tempRowSort = rowSort;
        let tempRecords = getArrayParam(records);
        let sortFieldName = getStringParam(tempRowSort.sortFieldName);
        let activeTab = getActiveTab();
        let info = getObjectParam(activeTab.info);
        try {
            if (!getBooleanParam(tempRowSort.isDescending)) {
                sortArrayObjectByPropertyDesc(tempRecords, sortFieldName);
                tempRowSort.isDescending = isDescending;//true;
            }
            else {
                sortArrayObjectByProperty(tempRecords, sortFieldName);
                tempRowSort.isDescending = isDescending;//false;
            }
        }
        catch (error) {
            console.error("Error in 'tasksReport.js -> onSort()':" + error);
        }
        info.isDescending = tempRowSort.isDescending;
        updateTabInfo(info);
        setRowSort(tempRowSort);
    }

    const formatData = (records) => {
         let newRecordSet = [];
        try {
            records = getArrayParam(records);
            records.forEach(r => {

                let tempRecord = { ...r, completed_value: r.completed, overdue_value: r.overdue, rescheduled_value: r.rescheduled, tasks: r.tasks };
                tempRecord.completed = r.completed + ' (' + getFloatParam(((r.completed / r.tasks) * 100), 2) + '%)';
                tempRecord.overdue = r.overdue + ' (' + getFloatParam(((r.overdue / r.tasks) * 100), 2) + '%)';
                tempRecord.rescheduled = r.rescheduled + ' (' + getFloatParam(((r.rescheduled / r.tasks) * 100), 2) + '%)';
                newRecordSet.push(tempRecord);
            });
        } catch (error) {
            console.error("Error in 'taskReport.js -> formatData()':" + error);
        }
        return newRecordSet;
    }

    let style = { margin: 10, width: '98%', paddingTop: 0, paddingLeft: 11 };
    style = { ...style, ...getObjectParam(style) };
    let tempRecords = formatData(records);
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2
    return (
        <div style={style}>
            {isMounted ?
                <div>
                    <div>
                        {getReportFilter()} 
                        {getReportHeaderName()}
                    </div>

                    <div id="divcontents">
                        {tempRecords.length > 0 ?
                            <div>
                                <SFDataTable columns={fields} callfrom={TASKS_REPORTS_MAP.TYPE_TASKS_REPORT} rowSort={rowSort} rows={tempRecords} customEvent={customEvent} />
                                <div style={{ paddingTop: "10px", marginLeft: "7px" }}>
                                    {isValidParam(total) ? " Total : " + getIntParam(total) : " Total : " + 0}
                                </div>
                            </div>
                            :
                            <div> {getNoRecordContent()}</div>
                        }
                    </div>
                </div>
                :
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            }
        </div>
    );

}

export default TasksReport;

