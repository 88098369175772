import React, {useEffect, useState, useRef} from 'react';
import Button from '@mui/material/Button';
import {MenuItem} from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import {styles} from '../../../services/constants/styles';
import {constants} from '../../../services/constants/constants';
import {isValidParam, isInvalidParam, getStringParam, getArrayParam} from '../../../services/helper/parameterVerifier';
import * as SFFormActions from '../../../services/actions/sfFormActions';
import {getAppDialog} from '../../../services/actions/appContainerActions';
import {addNode} from '../../../services/actions/automationCampaignActions';
import {removeError} from '../../../services/helper/automationUtils';
import {sortArrayObjectByProperty} from '../../../services/helper/utils';
import {getLocalizedStrings} from '../../../services/constants/MultiLingual';
import {showCustomSnackBar} from '../../../services/actions/snackBarAction';
import {getCookie, setCookie, deleteCookie} from '../../../services/helper/sfCookies';
import SFAutoComplete from '../components/sfAutoComplete';
import {hasAccessPermission} from '../../../services/helper/common';
import {useDispatch, useSelector} from 'react-redux';

const AutomationAddNote = ({data, closeDrawer}) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const fieldRefStatus = useRef();

    const automationCampaign = useSelector((state) => state.automationCampaign);

    const [noteText, setNoteText] = useState('');
    const [noteTypeList, setNoteTypeList] = useState(null);
    const [valueNoteType, setValueNoteType] = useState(null);
    const [notetypeName, setNotetypeName] = useState('');
    const [noteTypesList, setNoteTypesList] = useState([]);
    const [addToNote, setAddToNote] = useState(false);
    const [addNoteValue, setAddNoteValue] = useState('');
    const [noteTypeValue, setNoteTypeValue] = useState();

    useEffect(() => {
        try {
            if (isValidParam(data)) {
                let detailsArray = null;
                let noteText = null;
                let noteTypeValue = null;
                if (data.hasOwnProperty('details')) {
                    let promise = SFFormActions.getSFFormFieldsPromise(constants.NOTE_OBJECT);
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            generateNoteTypesList(response);
                            detailsArray = data.details[0];
                            if (isValidParam(detailsArray)) {
                                if (detailsArray.hasOwnProperty('note_text')) {
                                    noteText = detailsArray.note_text;
                                }
                                if (detailsArray.hasOwnProperty('qualifier_1')) {
                                    noteTypeValue = detailsArray.qualifier_1;
                                }
                                let filteredNoteTypesList = noteTypesList.filter(function (el) {
                                    return el.id === noteTypeValue;
                                });
                                if (filteredNoteTypesList.length === 0 && noteTypesList.length > 0) {
                                    noteTypeValue = noteTypesList[0].id;
                                }
                                setNoteText(noteText);
                                setValueNoteType(noteTypeValue);
                            }
                        }
                    });
                }
            }
        } catch (error) {
            console.error("Error in 'sfForm.js -> getFormData()':" + error);
        }
    }, []);

    const generateNoteTypesList = (response) => {
        if (isValidParam(response)) {
            if (response.hasOwnProperty('fields')) {
                let fieldsArray = response.fields.filter(function (el) {
                    return el.name === 'note_type';
                });
                fieldsArray = fieldsArray[0];
                if (isValidParam('fieldsArray')) {
                    let listValues = null;
                    if (fieldsArray.hasOwnProperty('list_values')) {
                        listValues = fieldsArray.list_values;

                        let rcType = getStringParam(getCookie('rc_note_type'));
                        if (isValidParam(rcType) && rcType !== '') {
                            let arr = getArrayParam(listValues).filter(f => f.value === rcType);
                            if (arr.length > 0) {
                                setNoteTypeValue(rcType);
                            } else {
                                deleteCookie(rcType);
                            }
                        }
                        generateMenuItemList(listValues);
                    }
                }
            }
        }
    }

    const generateMenuItemList = (listValues, valueNoteType) => {
        let hasPermission = false;
        let noteTypes = null;
        try {
            if (isValidParam(listValues) && getArrayParam(listValues).length > 0) {
                listValues = listValues.filter(f => f.id !== 'Add to this List');
                sortArrayObjectByProperty(listValues, 'value');
                hasPermission = hasAccessPermission("", constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_ADD_TO_LIST);
                if (hasPermission) {
                    listValues.push({
                        id: 'Add to this List',
                        value: '---- ' + getLocalizedStrings().label.COMMON.ADD_TO_THIS_LIST + ' ----'
                    });
                }
                noteTypes = getArrayParam(listValues).map(objNoteType => {
                    return (
                        <MenuItem key={objNoteType.value}
                                  value={objNoteType.value}
                                  id={objNoteType.value}
                                  style={styles.popoverMenuItem}
                                  className='menuitemNoteTypes'
                        >{objNoteType.value} </MenuItem>

                    );
                });
                 
                let _valueNoteType = isValidParam(valueNoteType) && valueNoteType !== '' ? valueNoteType : isValidParam(noteTypeValue) ? noteTypeValue : listValues[0].id;
                let selectedNote = listValues.filter(f => {
                    return f.id === _valueNoteType
                });
                let notetypeName = selectedNote[0].value;
                setNoteTypeList(noteTypes);
                setValueNoteType(_valueNoteType);
                setNoteTypesList(listValues);
                setNotetypeName(notetypeName);
            }
        } catch (error) {
            console.error("Error in 'sfForm.js -> generateMenuItemList()':" + error);
        }
    }

    const changeNoteText = (event, value) => {
        if (isValidParam(value)) {
            setNoteText(value);
        }
    }

    const saveNote = () => {
        let nodeObject = data;
        let dialogData = null;

        if (isInvalidParam(noteText)) {
            dialogData = {
                message: getLocalizedStrings().message.AUTOMATION_DESIGNER.ENTER_NOTE,
                fieldId: 'noteText'
            }
            openDialog(dialogData);
        } else {
            if (isValidParam(nodeObject)) {
                if (nodeObject.hasOwnProperty('details')) {
                    let detailsArray = nodeObject.details[0];
                    if (isValidParam(detailsArray)) {
                        detailsArray.note_text = noteText;
                        detailsArray.qualifier_1 = valueNoteType;
                    } else {
                        detailsArray = {
                            action_type: "add_note",
                            qualifier_1: valueNoteType,
                            note_text: noteText,
                        }
                        nodeObject.details.push(detailsArray);
                    }
                    setCookie('rc_note_type', valueNoteType);
                    nodeObject = removeError(nodeObject);
                    dispatch(addNode(automationCampaign.data));
                    closeDrawer();
                }
            }
        }
    }

    const openDialog = (dialogData) => {
        dispatch(getAppDialog(true, constants.ALERT_DIALOG, getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, null, dialogData, constants.AUTOMATION_DESIGNER_OBJECT));
    }

    const changeNoteType = (event, value) => {
        let noteType = noteTypesList.filter(f => {
            return f.value === value
        });
        let item = noteType[0];
        let note_id = item.id;
        if (isValidParam(value)) {
            if (value === '---- Add to this List ----') {
                setAddToNote(true);
            } else {
                setValueNoteType(note_id);
                setNotetypeName(value);
            }
        }
    }

    function setFieldValue(event) {
        try {
            setAddNoteValue(event.currentTarget.value);
        } catch (error) {
            console.error("Error in 'sfForm.js -> setFieldValue()':" + error);
        }
    }

    function addToThisList() {
        try {
            let listValues = noteTypesList;
            let value = addNoteValue;
            if (isValidParam(value) && getStringParam(value) !== '') {
                let list = getArrayParam(listValues).filter(f => f.value === value);
                if (list.length > 0) {
                    dispatch(showCustomSnackBar('Value already exists in List', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                } else {
                    let promise = SFFormActions.saveListFieldName('note_type', value);
                    if (isValidParam(promise)) {
                        promise.then(response => {
                             
                            if (isValidParam(response.data)) {
                                listValues.push({id: value, value});
                                setValueNoteType(value);
                                generateMenuItemList(listValues, value);
                                setAddToNote(false);
                                setAddNoteValue('');
                            }
                        });
                    }
                }
            }
        } catch (error) {
            console.error("Error in 'sfForm.js -> addToThisList()':" + error);
        }
    }

    let campaignStatus = automationCampaign.data.status;
    return (
        <div style={{paddingTop: '5px', paddingLeft: '20px', paddingRight: '20px'}}>
            <div>
                <div style={{width: '215px'}}>
                    {!addToNote ?
                        <SFAutoComplete
                            key={'sf-auto-complete-addnote'}
                            id={'addnote'}
                            name={'addnote'}
                            label={getLocalizedStrings().label.AUTOMATION_DESIGNER.NOTE_TYPE}
                            menuItems={noteTypeList}
                            disabled={false}
                            onChange={(e, value) => changeNoteType(e, value)}
                            value={valueNoteType}
                            underlineFocusStyle={styles.textField.underlineFocus}
                            floatingLabelStyle={{...styles.textField.floatingLabel, top: '10px', left: '7px'}}
                            inputStyle={{...styles.textField.input, paddingLeft: '6px'}}
                            menuItemStyle={styles.popoverMenuItem}
                            style={{...styles.sfFormFieldContainer, paddingLeft: '0px'}}
                            ref={fieldRefStatus}
                            isFullHeight={false}
                        />
                        :
                        <FormControl style={{width: '100%'}} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                fullWidth={true}
                                inputStyle={{
                                    ...styles.textField.input,
                                    color: '#333',
                                    fontSize: '15px',
                                    fontWeight: 'normal'
                                }}
                                key={'text_field_Add_Note'}
                                style={{paddingBottom: '20px'}}
                                id={"Add_Note"}
                                value={addNoteValue}
                                onChange={(event) => setFieldValue(event)}
                                onBlur={addToThisList}
                                autoFocus={true}
                                autoComplete="new-password"
                                className={"sf-fields-bg"}
                                margin="dense"
                                size='small'
                            />
                        </FormControl>
                    }
                </div>
            </div>

            <div>

                <div>
                    <FormControl style={{width: '100%'}} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            id="noteText"
                            value={noteText}
                            multiline={true}
                            rows={5}
                            maxRows={10}
                            onChange={(e) => changeNoteText(e, e.target.value)}
                            label={getLocalizedStrings().label.AUTOMATION_DESIGNER.TEXT_REQUIRED}
                            fullWidth={true}
                            autoComplete="new-password"
                            className={"sf-fields-bg"}
                        />
                    </FormControl>
                </div>
            </div>

            {
                (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                <div style={{float: 'left'}}>
                    <Button
                        style={{...styles.listViewPrimaryButton, marginTop: '13px'}}
                        onClick={() => saveNote()}
                    >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                </div>
            }
        </div>
    );
}


export default AutomationAddNote;