import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { isValidParam, getStringParam, getObjectParam, getBooleanParam, getArrayParam, getFloatParam, getIntParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import Button from '@mui/material/Button';
import SFDataTable from '../components/sfDataTable';
import ShowCircularProgress from '../components/circularProgress';
import { constants } from '../../../services/constants/constants';
import { dateFormat, dateFormatFlatPicker, sortArrayObjectByProperty } from '../../../services/helper/utils';
import moment from 'moment';
import EmailReportListView from '../components/emailReportListView';
import { getActiveTab, updateTabInfo } from '../../../services/helper/sfTabManager';
import { SFPopover } from './sfFormFields';
import PopOver from '../components/PopOver';
import { setHeader } from '../../../services/actions/headerActions';
import Grid from '@mui/material/Grid';

const optionvalue = {
    td: 'Today',
    yd: 'Yesterday',
    tw: 'This Week',
    tm: 'This Month',
    tq: 'This Quarter',
    ty: 'This Year',
    lw: 'Last Week',
    lm: 'Last Month',
    lq: 'Last Quarter',
    ly: 'Last Year',
    cust: 'Custom'
};

function formatDate(date) {
    if (isValidParam(date)) {
        let d = date instanceof Date ? date : new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }
    return null;
}

function toDate(dateStr) {
    var parts = dateStr.split("-");
    return new Date(parts[0], parts[1] - 1, parts[2]);
}

const EmailReport = ({ object }) => {

    const [dueBy, setDueBy] = useState("td");
    const [user_names, setUser_names] = useState('All');
    const [user_id, setUser_id] = useState(-99);
    const [from_date, setFrom_date] = useState(null);
    const [to_date, setTo_date] = useState(null);
    const [reportParams, setReportParams] = useState({

        due_by: 'tm',
        from_date: null,
        to_date: null,
        user_names: 'All',
        user_id: -99

    });
    const [isMounted, setIsMounted] = useState(false);
    const [due_by_popup, setDue_by_popup] = useState(false);
    const [user_names_popup, setUser_names_popup] = useState(false);
    const [fields, setFields] = useState([]);
    const [records, setRecords] = useState([]);
    const [rowSort, setRowSort] = useState({
        sortFieldName: 'sales_rep',
        isDescending: false,
        onSort: (fieldName, isDescending, rows) => onSortFn (fieldName, isDescending, rows)
    });
    const [customTimeLineDisplay, setCustomTimeLineDisplay] = useState('none');
    const [isPanelVisible, setIsPanelVisible] = useState(false);
    const [tab, setTab] = useState({});
    const [name, setName] = useState('');
    const [fieldName, setFieldName] = useState('');

    const dispatch = useDispatch();
    const appState = useSelector((state) => state.app);
    const report = useSelector((state) => state.report);
    const userlist = useSelector((state) => state.userlist);

    useEffect(() => {
        report.showProgressBar = false;
        let tempRowSort = rowSort;
        setRowSort(tempRowSort);
        reloadReport();
        onSortFn();
        dispatch(setHeader(object, getLocalizedStrings().label.REPORT['Email Report'], null, false, true));
    }, []);

   
    const setReportParamsData = () => {
        let tempDueBy = getStringParam(dueBy);
        let fromDate = from_date;
        let toDate = to_date;
        let userId = user_id;
        let userNames = user_names;
        let params = {
            due_by: tempDueBy,
            from_date: formatDate(fromDate),
            to_date: formatDate(toDate),
            user_names: userNames,
            user_id: userId
        };
        setReportParams(params);
        return params;
    }

    const getMultilingualOptionsMap = () => {
        let optionsMultilingual = {
            'Today': getLocalizedStrings().label.DASHBOARD.TODAY,
            'Yesterday': getLocalizedStrings().label.DASHBOARD.YESTERDAY,
            'This Week': getLocalizedStrings().label.DASHBOARD.THIS_WEEK,
            'This Month': getLocalizedStrings().label.DASHBOARD.THIS_MONTH,
            'This Quarter': getLocalizedStrings().label.DASHBOARD.THIS_QUARTER,
            'This Year': getLocalizedStrings().label.DASHBOARD.THIS_YEAR,
            'Last Week': getLocalizedStrings().label.DASHBOARD.LAST_WEEK,
            'Last Month': getLocalizedStrings().label.DASHBOARD.LAST_MONTH,
            'Last Quarter': getLocalizedStrings().label.DASHBOARD.LAST_QUARTER,
            'Last Year': getLocalizedStrings().label.DASHBOARD.LAST_YEAR,
            'Custom': getLocalizedStrings().label.DASHBOARD.CUSTOM
        };
        return optionsMultilingual;
    }

    const generateDateSelectionField = () => {
        let optionsMultilingual = getMultilingualOptionsMap();
        try {
            return Object.keys(optionvalue).map((itemKey) => {
                return { id: itemKey, label: optionsMultilingual[optionvalue[itemKey]] };
            });
        } catch (error) {
            console.error("Error in 'emailReport.js -> generateDateSelectionField()':" + error);
        }
    }

    const generateUserSelectionFieldList = () => {
        let usernamesList = [];
        try {
            usernamesList = userlist.data.map((user, index) => {
                return <MenuItem value={user.id} style={styles.popoverMenuItem} >{user.value}</MenuItem>;
            });
            usernamesList.unshift(<MenuItem value={-99} style={styles.popoverMenuItem} >All</MenuItem>);
        }
        catch (error) {
            console.error("Error in 'emailReport.js -> generateUserSelectionFieldList()':" + error);
        }
        return usernamesList;
    }

    const handleRequestClose = (fieldName) => {
        try {
            setFieldName(false);

        }
        catch (error) {
            console.error("Error in 'emailReport.js -> handleRequestClose()':" + error);
        }
    };

    const handleDropDownChange = (name, event,value) => {
        const val = event.target.value;
         try {
            if (name === "due_by") {
                setDueBy(value);
                if (value === "cust") {
                    setCustomTimeLineDisplay('block');
                    let tempDate = moment(String(new Date())).format(dateFormat[appState.me.date_format]);
                    setFrom_date(tempDate);
                    setTo_date(tempDate);
                    handleRequestClose(name + "_popup");
                    let dateTimeDiv1 = document.getElementsByClassName("dateTimeDiv-from-date");
                    if (isValidParam(dateTimeDiv1) && dateTimeDiv1.length > 0) {
                        dateTimeDiv1[0].classList.add('label-top');
                    }
                    let dateTimeDiv2 = document.getElementsByClassName("dateTimeDiv-to-date");
                    if (isValidParam(dateTimeDiv2) && dateTimeDiv2.length > 0) {
                        dateTimeDiv2[0].classList.add('label-top');
                    }
                    return;
                } else {
                    setCustomTimeLineDisplay('none');
                    setFrom_date(null);
                    setTo_date(null);
                    handleRequestClose(name + "_popup");
                }
            }
            else if (name === "user_names") {
                if (val == -99) {
                    setUser_id(val);
                    setUser_names("All");
                } else {
                    let user = userlist.data.filter((f) => {
                        return f.id === val;
                    });
                    let tempUser_id = user[0].id;
                    setUser_id(tempUser_id);
                    let tempUser_names = user[0].value;
                    setUser_names(tempUser_names);
                }
                handleRequestClose(name + "_popup");
            }
            else {
               handleRequestClose(name + "_popup");
            }
        }
        catch (error) {
            console.error("Error in 'emailReport.js -> handleDropDownChange()':" + error);
        }
    }

    const handleDateChange = (date) => {
        setName(date);
    }

    const handleDateFocus = (fieldName) => {
        let dateTimeDiv = document.getElementsByClassName("dateTimeDiv-" + fieldName);
        if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
            dateTimeDiv[0].classList.add('label-top');
        }
    }

    const handleDateBlur = (fieldName, event) => {
        let field_name = fieldName.replace('-', '_');
        let value = getStringParam([field_name]);
        if (value === '') {
            let dateTimeDiv = document.getElementsByClassName("dateTimeDiv-" + fieldName);
            if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
                dateTimeDiv[0].classList.remove('label-top');
            }
        }
    }

    const getButtonSet = () => {
        return (
            <div>
                <iframe id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute', display: 'none' }}></iframe>
                <Button
                    onClick={() => reloadReport()}
                    style={{
                        ...styles.primaryButton,
                        minWidth: '58px'
                    }}
                    title={getLocalizedStrings().label.REPORT.GO}
                >{getLocalizedStrings().label.REPORT.GO}</Button>
                <Button

                    onClick={() => {
                        var content = document.getElementById("divcontents");
                        var ifrmae = document.getElementById("ifmcontentstoprint");
                        ifrmae.style.display = 'block'
                        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                        ifrmae.style.display = 'block'

                        pri.document.open();
                        pri.document.write(
                        `<h3 style="text-align: center; margin-bottom: 20px;">${getLocalizedStrings().label.REPORT['Email Report']}</h3>` +
                        content.innerHTML.replace(/<a/g, '<a style="text-decoration: none; color: black;')
                        );
                        pri.document.close();
                        pri.focus();
                        pri.print();
                        ifrmae.style.display = 'none'
                    }}

                    style={{
                        ...styles.secondaryButton,
                        minWidth: '40px'
                    }}
                    title={getLocalizedStrings().label.REPORT.PRINT}
                > {getLocalizedStrings().label.REPORT.PRINT}</Button>


            </div>
        );
    }

    const getReportFilter = () => {
        let buttonSet = getButtonSet();
        let sfFormFieldContainerStyle = { ...styles.sfFormFieldContainer };
        let HHFormat = new RegExp("HH");
        let datePickerOptions = {
            enableTime: false,
            dateFormat: dateFormatFlatPicker[appState.me.date_format],
            minuteIncrement: 1,
            static: true,
            time_24hr: HHFormat.test(appState.me.date_format) ? true : false
        };
        let userNameList = getArrayParam(userlist.data).map(m => { return { id: m.id, label: m.value } });
        let isContain = userNameList.filter((e => e.label === "All")).length > 0 ? true : false;
        if (!isContain) {
            userNameList.unshift({ id: -99, label: 'All' });
        }

        return (
            //*imp
            <div className="row" style={{ padding: '0px' }} >
                <div>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', marginLeft: "17px" }}>
                                <div style={{ padding: 0, marginBottom: '30px' }} >
                                    <SFPopover
                                        label={getLocalizedStrings().label.REPORT.DATE_RANGE}
                                        onChange={(event,value) => handleDropDownChange('due_by',event,value)}
                                        type={"dropdown"}
                                        list={generateDateSelectionField()}
                                        value={dueBy}
                                        style={{ ...sfFormFieldContainerStyle, width: '100%', cursor: "pointer", fontSize: '14px', color: '#717171', height: '47px', width: '100%', paddingRight: 7 }} />
                                </div>

                                <div style={{ display: customTimeLineDisplay, }}>

                                    <div style={{ marginRight: '7px', marginLeft: '7px' }}>
                                        <PopOver
                                            id={'from_date'}
                                            key={'from_date'}
                                            name={'from_date'}
                                            btnType={'date'}
                                            buttonStyle={{ fontSize: '16px' }}
                                            containerStyle={{ lineHeight: 1, marginBottom: '20px' }}
                                            buttonLabel={getLocalizedStrings().label.REPORT.START_DATE}
                                            // onChange={(date) => handleDateChange(date)}
                                            onChange={(e) => setFrom_date(e)}
                                            value={from_date}
                                            innerLabelStyle={{ padding: "8 5 2 5", fontSize: '15px' }}
                                            height={45}
                                            options={datePickerOptions}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: customTimeLineDisplay }}>

                                    <div style={{ marginRight: '7px', marginLeft: '7px' }}>
                                        <PopOver
                                            id={'to_date'}
                                            key={'to_date'}
                                            name={'to_date'}
                                            btnType={'date'}
                                            buttonStyle={{ fontSize: '16px' }}
                                            containerStyle={{ lineHeight: 1, marginBottom: '20px' }}
                                            buttonLabel={getLocalizedStrings().label.REPORT.END_DATE}
                                            // onChange={(date) => handleDateChange(date)}
                                            onChange={(e) => setTo_date(e)}
                                            value={to_date}
                                            innerLabelStyle={{ padding: "8 5 2 5", fontSize: '15px' }}
                                            height={45}
                                            options={{ ...datePickerOptions, minDate: from_date }}
                                        />
                                    </div>
                                </div>

                                {/* username dropdown */}
                                <div style={{ paddingLeft: 0 }} >
                                    <div
                                        style={{ width: '100%', fontSize: '14px', color: '#717171', height: '47px', marginLeft: "7px" }}
                                        // onClick={(event, value)=>handleDropDownChange('user_names',event,value)}
                                        id='user_names'
                                        key='user_names'
                                    >
                                        <FormControl variant="outlined" size="small">
                                            <InputLabel id="sf-email-report-from-emailreport-simple-select-outlined-labedeml" className="sf-sms-report-from-smsreport">{getLocalizedStrings().label.REPORT.SALES_REP}</InputLabel>
                                            <Select
                                                labelId="sf-email-report-from-emailreport-simple-select-outlined-label"
                                                id="sf-emailreport-simple-select-outlined"
                                                value={user_id}
                                                onChange={(event, value)=>handleDropDownChange('user_names',event,value)}
                                                label={getLocalizedStrings().label.REPORT.SALES_REP}
                                                className={"sf-fields-bg"}
                                                style={{ minWidth: 250,  maxWidth: 250 }}
                                            >
                                                {generateUserSelectionFieldList()}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div style={{ marginTop: '10px', paddingLeft: '10px' }}>{buttonSet}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    const getReportHeaderName = () => {
        let component = null;
        component = (
            <div >
                <div style={{ ...styles.row, paddingLeft: "2px", paddingBottom: "15px" }}>
                    <span className="text" style={{ margin: '0px', fontSize: '22px' }}><h3 style={{ fontWeight: 'bold' }}>{getLocalizedStrings().label.EMAIL_REPORT.EMAIL_REPORT_LABEL}</h3></span>
                </div>
            </div>)
        return component;
    }

    const getFieldList = () => {
        let columnHeaderList = [
            { field_type: "integer", is_date: false, is_hidden: true, is_detail_link: false, is_sql_col: false, label: "userid", name: "user_id", style: { textAlign: 'center' } },
            { field_type: "text", is_date: false, is_hidden: false, is_detail_link: false, is_sql_col: false, label: "User", name: "sales_rep" },
            { field_type: "integer", is_date: false, is_hidden: false, is_detail_link: true, is_sql_col: false, label: "Sent", name: "sent", style: { textAlign: 'center' } },
            { field_type: "integer", is_date: false, is_hidden: false, is_detail_link: true, is_sql_col: false, label: "Opened", name: "open", style: { textAlign: 'center' } },
            { field_type: "integer", is_date: false, is_hidden: false, is_detail_link: true, is_sql_col: false, label: "Clicked", name: "click", style: { textAlign: 'center' } },
            { field_type: "integer", is_date: false, is_hidden: false, is_detail_link: true, is_sql_col: false, label: "Bounced", name: "bounce", style: { textAlign: 'center' } },
        ]
        return columnHeaderList;
    }

    const reloadReport = () => {
        let params = setReportParamsData();
        let tempRowSort = getObjectParam(rowSort);
        let records = null;
        let fields = getFieldList();
        let url = endPoints.EMAIL_REPORT.EMAIL_REPORT_GET;
        try {
            setIsMounted(false);
            tempRowSort.isDescending = false;
            let promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    records = getArrayParam(response);
                    sortArrayObjectByProperty(records, "sales_rep");

                    setIsMounted(true);
                    setFields(fields);
                    setRecords(records);
                    setRowSort(tempRowSort);
                    setIsPanelVisible(false);
                }
            });
        } catch (error) {
            console.log("Error in 'emailReport.js -> reloadReport()':" + error)
        }
    }

    const getNoRecordContent = () => {
        return (<table className="layout_maintable" style={{ width: '100%' }}><tr><td><span style={{ color: 'red' }}>{getLocalizedStrings().label.INXPRESS_REPORT.NO_RECORDS_FOUND}</span></td></tr></table>);
    }

    const excelGenerateBeforeHeaders = () => {
        let optionsMultilingual = getMultilingualOptionsMap();
        let reportParams = getObjectParam(reportParams);
        let cells = '';
        let dueBy = '';
        let reportName = getStringParam(getLocalizedStrings().label.EMAIL_REPORT.EMAIL_REPORT_LABEL);
        let columns = fields.filter(f => {
            if (!f.is_hidden) { return f; }
        });
        let columnsLength = getArrayParam(columns).length;

        cells = cells + '<tr><td  colspan="' + (columnsLength) + '"> ' + reportName + ' </td></tr>';
        if (reportName.length > 0) {
            dueBy = getStringParam(optionsMultilingual[optionvalue[reportParams.due_by]]);
            cells = cells + '<tr><td> Date :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + dueBy + ' </td></tr>';
        }
        return cells;
    }

    const excelGenerateHeaders = () => {
        let cells = '';
        let reportName = getStringParam(getLocalizedStrings().label.EMAIL_REPORT.EMAIL_REPORT_LABEL);
        let columns = fields.filter(f => {
            if (!f.is_hidden) { return f; }
        });
        columns.map(f => {
            cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    const excelGenerateRows = () => {
        let rows = formatData(records);
        let columns = getArrayParam(fields);
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            rows.map((rowObject, rowIndex) => {
                let innerCells = '';
                columns.map((columnObject, colIndex) => {
                    if (!columnObject.is_hidden) {
                        innerCells = innerCells + '<td >' + rowObject[columnObject.name] + ' </td>';
                    }
                })
                if (rowIndex % 2 === 0) {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                } else {
                    cells = cells + ('<tr  bgcolor="Lavender">' + innerCells + ' </tr>');
                }

            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
        }
        return rowData;

    }

    const downloadExcel = () => {
        let beforeHeaderComponent = excelGenerateBeforeHeaders();
        let headerComponents = excelGenerateHeaders();
        let rowComponents = excelGenerateRows();
        let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '</tbody></table>';
        let htmlData = htmlTable;
        if (isValidParam(htmlData) && htmlData !== '') {
            htmlData = htmlData.replace("%22", "\"");
            var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
            var downloadUrl = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = downloadUrl;
            a.download = 'EmailReportResultSet_' + new Date().getTime() + '.xls';
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    }

    const customEvent = (eventType, column, row, event) => {
        try {
            if (row[column.name + '_value'] > 0) {
                let label = getStringParam(getLocalizedStrings().label.EMAIL_REPORT.EMAIL_REPORT_LABEL);
                let object = constants.EMAIL_OBJECT;
                let reportLabel = getStringParam(row.sales_rep) + ' - ' + getStringParam(column.label);
                let fromDate = from_date;
                let toDate = to_date;
                let tempTab = {}
                tempTab.label = label;
                tempTab.object = object;
                tempTab.isReport = true;
                tempTab.reportLabel = reportLabel;
                tempTab.input_param = {};
                tempTab.input_param.user_id = getIntParam(row.user_id);
                tempTab.input_param.due_by = reportParams.due_by;
                tempTab.input_param.from_date = formatDate(fromDate);
                tempTab.input_param.to_date = formatDate(toDate);
                tempTab.input_param.column_name = column.name;
                tempTab.input_param.status = reportLabel;
                tempTab.input_param.reportLabel = reportLabel;
                if (tempTab.input_param.report_due_by === 'cust') {
                    tempTab.input_param.report_from_date = getStringParam(reportParams.from_date);
                    tempTab.input_param.report_to_date = getStringParam(reportParams.to_date);
                }
                setIsPanelVisible(true);
                setTab(tempTab);
            }

        } catch (error) {
            console.error("Error in 'emailReport.js -> customEvent()':" + error);
        }
    }

    const sortArrayObjectByPropertyDesc = (array, property) => {
        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];

                    val1 = val1 instanceof String ? val1.toLowerCase() : val1;
                    val2 = val2 instanceof String ? val2.toLowerCase() : val2;

                    if (val1 > val2) {
                        return -1;
                    } else if (val1 < val2) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
        }
    }

    const onSortFn = (fieldName, isDescending, records) => {

        let tempRowSort = rowSort;
        let tempRecords = getArrayParam(records);
        let sortFieldName = getStringParam(tempRowSort.sortFieldName);
        let activeTab = getActiveTab();
        let info = getObjectParam(activeTab.info);
        try {
            if (!getBooleanParam(tempRowSort.isDescending)) {
                sortArrayObjectByPropertyDesc(tempRecords, sortFieldName);
                tempRowSort.isDescending = isDescending;//true;
            }
            else {
                sortArrayObjectByProperty(tempRecords, sortFieldName);
                tempRowSort.isDescending = isDescending;//false;
            }
        }
        catch (error) {
            console.error("Error in 'emailReport.js -> onSort()':" + error);
        }
        info.isDescending = tempRowSort.isDescending;
        updateTabInfo(info);
        setRowSort(tempRowSort);
    }

    const formatData = (records) => {
        let newRecordSet = [];
        try {
            records = getArrayParam(records);
            records.forEach(r => {

                let record = { ...r, bounce_value: r.bounce, click_value: r.click, open_value: r.open, sent_value: r.sent };
                record.bounce = r.bounce + ' (' + getFloatParam(((r.bounce / r.sent) * 100), 2) + '%)';
                record.click = r.click + ' (' + getFloatParam(((r.click / r.sent) * 100), 2) + '%)';
                if (getFloatParam(((r.click / r.sent) * 100), 2) > 100) {
                    record.click = r.click + ' (100.00%)';
                }
                record.open = r.open + ' (' + getFloatParam(((r.open / r.sent) * 100), 2) + '%)';
                newRecordSet.push(record);
            });
        } catch (error) {
            console.error("Error in 'emailReport.js -> formatData()':" + error);
        }
        return newRecordSet;
    }

    const closePanel = () => {
        setIsPanelVisible(false);
        setTab({});
    }

    let style = { margin: 10, width: '98%', paddingTop: 0, paddingLeft: 11 };
    style = { ...style, ...getObjectParam(style) };
    let tempIsMounted = getBooleanParam(isMounted);
    let headerComponent = getReportFilter();
    let reportHeaderName = getReportHeaderName();
    let tempRecords = formatData(records);
    let reportListViewData = getObjectParam(tab);
    let customReportStyle = null;
    let customPanelStyle = null;
    let tempIsPanelVisible = getBooleanParam(isPanelVisible);
    if (isPanelVisible) {
        customReportStyle = {};
        customPanelStyle = {};
        customReportStyle.width = '56%';
        customReportStyle.float = 'left';

        customPanelStyle.width = '40%';
        customPanelStyle.float = 'right';
        customPanelStyle.marginLeft = '2%';
        customPanelStyle.marginRight = '2%';
        customPanelStyle.marginTop = '-52px';
    }
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2
    return (<div style={style}>
        <div>{headerComponent} {reportHeaderName}</div>
        {tempIsMounted ?
            <div id="divcontents">
                {tempRecords.length > 0 ?
                    <div>

                        <div style={customReportStyle}>
                            <SFDataTable columns={fields} rowSort={rowSort} rows={tempRecords} customEvent={customEvent} />
                        </div>
                        {tempIsPanelVisible ?
                            <div key={tab.reportLabel} style={customPanelStyle}>
                                <EmailReportListView isPanelVisible={isPanelVisible} info={reportListViewData} closePanel={() => closePanel()} />
                            </div> :
                            null}
                    </div> :
                    <div> {getNoRecordContent()}</div>
                }
            </div>
            :
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        }
    </div>);
}


export default EmailReport;

