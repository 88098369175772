import React from 'react';
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { Select, MenuItem, InputLabel, Icon, List, ListSubheader } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import ShowCircularProgress from '../components/circularProgress';
import { getTasksRefresh, getUnitTasksRefresh } from '../../../services/actions/taskActions';
import { getConvert, saveConvert, getWorkFlowStage, applyWorkFlow, isOpenOpportunityExits } from '../../../services/actions/batchActions';
import { styles } from '../../../services/constants/styles';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { isValidParam, getObjectParam, getStringParam, getArrayParam, getIntParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import map from 'lodash/map';
import {
	refreshDetailViewData, refreshDetailViewListViewData, detailViewIsMounted,
	getDetailviewData,
	isDetailViewSet,
} from '../../../services/actions/detailViewActions';
import { getAppDrawer, getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import * as sfDialogs from '../components/sfDialogs';
import { getActiveTab } from '../../../services/helper/sfTabManager';
import { refreshListView, setListViewMounted, setRequiredFieldData, removeRequiredFieldIds } from '../../../services/actions/listViewAcions';
import { getActiveTabInfo, TYPE_LIST_VIEW, TYPE_COLUMN_VIEW } from '../../../services/helper/sfTabManager';
import { getObjectLabelByObject, hasAccessPermission } from '../../../services/helper/common';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { refreshColumnView, setKanbanMounted } from '../../../services/actions/listsActions';
import { deleteAction } from '../../../services/actions/batchActions';
import { getEndPointObject } from '../../../services/actions/sfFormActions';
import * as SFFormActions from '../../../services/actions/sfFormActions';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { makeTiteleCase } from '../../../services/helper/utils';
import RequiredForms from '../components/requiredForms';
import { LinkToType } from '../../../services/constants/link';
import { updateConvertData, updateConvertDataByKey } from '../../../services/actions/convertAction';
import moment from 'moment';
import { dateTimeFormat, dateFormat, timeFormat, timeZone } from '../../../services/helper/utils';
import SFToolTip from '../components/sfTooltip';
import tz from 'moment-timezone';
import { openDetailviewQueueTab, removeRecordFromRedux, } from "../../../services/actions/queueActions";
import { useParams } from 'react-router-dom'

const Convert = ({ object, callFrom, data, selectedRecords, closeDrawer }) => {
	const dispatch = useDispatch();

    const { suffix } = useParams();
	const requiredForms = useRef(null);
	const [recordId, setRecordId] = useState(0);
	const [mounted, setMounted] = useState(false);
	const [currentWorkflow, setCurrentWorkflow] = useState(null);
	const [currentStage, setCurrentStage] = useState(null);
	const [createTask, setCreateTask] = useState(true);
	const [stages, setStages] = useState([]);
	const [pendingTasks, setPendingTasks] = useState([]);
	const [noteText, setNoteText] = useState(null);
	const [childObject, setChildObject] = useState(null);
	const [childTableId, setChildTableId] = useState(0);
	const [childStages, setChildStages] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState('');
	const [childSelectedStatus, setChildSelectedStatus] = useState('-1');
	const [isApplyWorkflow, setIsApplyWorkflow] = useState(false);
	const [applyWorkflowStages, setApplyWorkflowStages] = useState([]);
	const [selectedStage, setSelectedStage] = useState(null);
	const [currentWorkflowId, setCurrentWorkflowId] = useState(0);
	const [unitOwnerPendingTasks, setUnitOwnerPendingTasks] = useState(null);
	const [isFranchiseRecord, setIsFranchiseRecord] = useState(false);
	const [isFAExpirationDate, setIsFAExpirationDate] = useState(false);
	const [actionid, setActionid] = useState(0);
	const [actionobject, setActionobject] = useState(null);
	const [isShowChildStatusList, setIsShowChildStatusList] = useState(false);
	const [stateObject, setStateObject] = useState(null);
	const [isShowOppConvertButton, setIsShowOppConvertButton] = useState(false);
	const [showRequiredFormFields, setShowRequiredFormFields] = useState({ showRequiredFormFields: false });
	const [cancelEmailSMSList, setCancelEmailSMSList] = useState([]);
	const [pendingTaskArr, setPendingTaskArr] = useState([]);
	const [prevWorkflowId, setPrevWorkflowId] = useState(null);
	const [requiredFields, setRequiredFields] = useState(null);
	const [isWorkFlowObject, setIsWorkFlowObject] = useState(false);
	const [isAddToThisList, setIsAddToThisList] = useState(false);
	const [changedWorkflow, setChangedWorkflow] = useState(null);
	const [workflowList, setWorkflowList] = useState([]);
	const [isSaved, setIsSaved] = useState(false);
	const [cancelTaskId, setCancelTaskId] = useState(0);

	// redux states
	let app = useSelector((state) => state.app);
	let selectDetailviewRecords = useSelector((state) => state.detailView.record);
	let detailView = useSelector((state) => state.detailView);
	let appContainer = useSelector((state) => state.appContainer);
	let customDrawer = useSelector((state) => state.appContainer.customDrawerProps);
	let convert = useSelector((state) => state.convert.convertProps.convert);
	let tab = useSelector((state) => state.tab);
	let detailViewRecord = useSelector((state) => state.detailView.record);
	let sfForm = useSelector((state) => state.sfForm);
	const queueList = useSelector((state) => state.queueList);

	useEffect(() => {
		let minimizeList = customDrawer.minimizeList;
		if (minimizeList.hasOwnProperty('Convert') && minimizeList.Convert && convert !== null) {
			setChildSelectedStatus(convert.childSelectedStatus);
			setPendingTasks(convert.pendingTasks);
			setUnitOwnerPendingTasks(convert.unitOwnerPendingTasks);
			setMounted(true);
			setWorkflowList(convert.workflowList);
			setStages(convert.stages);
			setSelectedStage(convert.selectedStage);
			setCreateTask(convert.createTask);
			setSelectedStatus(convert.selectedStatus);
			setPrevWorkflowId(convert.prevWorkflowId);
			setCurrentWorkflowId(convert.currentWorkflowId);
			setCurrentWorkflow(convert.currentWorkflow);
			setCurrentStage(convert.currentStage);
			setChangedWorkflow(convert.changedWorkflow);
			setRecordId(convert.recordId);
			setRequiredFields(convert.requiredFields);
			setApplyWorkflowStages(convert.applyWorkflowStages);
			setIsShowChildStatusList(convert.isShowChildStatusList);
			setIsApplyWorkflow(convert.isApplyWorkflow);
			setIsWorkFlowObject(convert.isWorkFlowObject);
			setChildStages(convert.childStages);
			setChildObject(convert.childObject);
			setNoteText(convert.noteText);
			setStateObject(object);
		} else {
			getAndUpdateConvertData();
		}
	}, []);

	const getAndUpdateConvertData = () => {
		let tempObject;
		if (object === constants.SEARCH_RESULTS) {
			let activeTab = getObjectParam(getActiveTab());
			let activeTabInfo = getObjectParam(activeTab.info);
			let search = getObjectParam(activeTabInfo.search);
			tempObject = getStringParam(search.object);
		} else if (object === constants.ADVANCE_SEARCH_RESULTS) {
			let activeTab = getObjectParam(getActiveTab());
			let advanceSearch = getObjectParam(activeTab.info.advanceSearch);
			tempObject = getStringParam(advanceSearch.object);
		} else {
			tempObject = object;
		}
		setStateObject(tempObject);
		getConvertDetails(tempObject);
	}

	const getConvertDetails = (objectName) => {
		let params = null;
		let workStageFromDetailView = null;
		let recordIds = [];
		let wfDeleteStatus = false;
		let tempObject = objectName;

		let tempPendingTasks = [];
		let tempUnitOwnerPendingTasks = [];
		let tempSelectedStatus;


		if (callFrom === constants.OTHER) {
			workStageFromDetailView = data.stage;
			tempObject = data.object;
			if (detailView.isDetailView === true) {
				recordIds.push(data.hasOwnProperty('id') ? data.id : selectDetailviewRecords.id);
			} else {
				recordIds.push(data.id);
			}
		} else if (callFrom === constants.COLUMNVIEW_DRAGDROP) {
			workStageFromDetailView = data.stage;
			recordIds.push(data.id);
			tempObject = data.object;

		} else if (callFrom === constants.LIST_VIEW || callFrom === constants.KANBAN || callFrom === constants.COLUMN_VIEW) {
			if (selectedRecords && selectedRecords.length > 0) {
				recordIds.push(selectedRecords[0].id);
			}
		} else if (callFrom === constants.DETAIL_VIEW) {
			if (data.hasOwnProperty('isDetailviewChild') && data.isDetailviewChild === false) {
				recordIds.push(selectDetailviewRecords.id);
			} else if (selectedRecords !== null && selectedRecords.length > 0) {
				recordIds.push(selectedRecords[0].id);
			}
			if (detailView.hasOwnProperty('workflow')) {
				wfDeleteStatus = detailView.workflow.hasOwnProperty('is_workflow_deleted') && detailView.workflow.is_workflow_deleted;
			}
		}

		let isRecycleBinObject = false;
		if (tempObject === constants.RECYCLE_BIN_OBJECT) {
			isRecycleBinObject = true;
			tempObject = constants.ACCOUNTS_OBJECT;
		}

		if (recordIds.length > 0) {
			params = {
				id: recordIds[0]
			}
		}
		const response = getConvert(tempObject, params);
		response.then((response) => {
			let currentStageId = 0;
			let tempStageId = null;
			let tempCurrentStageobj = null;
			let workflowList = getArrayParam(response.workflow_list);
			setRecordId(recordIds[0]);
			setMounted(true);
			setCurrentWorkflow(response.current_workflow);
			setChangedWorkflow(response.current_workflow);
			let tempCurrentWorkflowId = response.current_workflow_id;
			let workflowStages = response.stages;
			let selectedStage = response.current_stage;
			let isWorkflow = response.hasOwnProperty('is_workflow') && response.is_workflow;
			let overwriteCurrentStage = false;
			let overwriteStageId = 0;
			if (object === constants.CONTACTS_OBJECT && response.current_workflow_id === 0) {
				let statusField = sfForm.data.contacts.fields.filter(f => f.name === 't_status');
				if (Array.isArray(statusField) && statusField.length === 1) {
					workflowStages = statusField[0].list_values.map((f) => ({ id: f.id, name: f.value }));
					if (isValidParam(data.stage)) {
						let targetStage = workflowStages.filter(f => f.name === data.stage);
						if (Array.isArray(targetStage) && targetStage.length === 0) {
							getWorkflowListByStage(response.current_workflow_id, response.current_workflow_id, workflowStages);
						}
					}
				}
				workflowList.unshift({ id: 0, name: '' });
				response.stages = workflowStages;
				response.workflow_list = workflowList;
				response.current_workflow = '';
			}
			let tempIsApplyWorkflow = isApplyWorkflow;
			let tempPrevWorkflowId = prevWorkflowId;
			if (object === constants.CONTACTS_OBJECT) {
				tempIsApplyWorkflow = true;
				setApplyWorkflowStages(workflowStages);
				tempPrevWorkflowId = response.current_workflow_id;
			}
			if (object !== constants.CONTACTS_OBJECT) {
				setIsWorkFlowObject(true);
				setApplyWorkflowStages(workflowStages);
				tempPrevWorkflowId = response.current_workflow_id;
			}

			let tempCurrentStage = currentStage;

			if (response.current_workflow_id !== 0 && data.hasOwnProperty('columnViewDragDropForAll') && data.columnViewDragDropForAll === true && workflowStages.length > 0) {
				tempCurrentStage = response.current_stage;
				getWorkflowListByStage(response.current_workflow_id, response.current_workflow_id, workflowStages);
				tempPendingTasks = response.pending_tasks.map(f => {
					let currentDate = moment().tz(timeZone[app.me.timezone]);
					currentDate = currentDate.format(dateFormat[app.me.date_format]);
					let duebyDate = f.dueby;
					duebyDate = moment(duebyDate);
					duebyDate = duebyDate.format(dateFormat[app.me.date_format]);
					if (duebyDate > currentDate) {
						f.isSelected = false;
						return f
					} else {
						f.isSelected = true;
						return f
					}
				});
				tempCurrentWorkflowId = response.current_workflow_id;
				tempPrevWorkflowId = response.current_workflow_id;
				let filterStages = workflowStages.filter(f => f.name === data.stage);
				if (Array.isArray(filterStages) && filterStages.length === 1) {
					overwriteCurrentStage = true;
					overwriteStageId = filterStages[0].id;
				}
			}

			if (response.current_workflow_id === 0 && data.hasOwnProperty('columnViewDragDropForAll') && data.columnViewDragDropForAll === true && workflowStages.length > 0) {
				tempPendingTasks = response.pending_tasks.map(f => {
					let currentDate = moment().tz(timeZone[app.me.timezone]);
					currentDate = currentDate.format(dateFormat[app.me.date_format]);
					let duebyDate = f.dueby;
					duebyDate = moment(duebyDate);
					duebyDate = duebyDate.format(dateFormat[app.me.date_format]);
					if (duebyDate > currentDate) {
						f.isSelected = false;
						return f
					} else {
						f.isSelected = true;
						return f
					}
				});
				tempCurrentStage = response.current_stage;
				getWorkflowListByStage(response.current_workflow_id, response.current_workflow_id, workflowStages);
			}
			if (workflowStages.length > 0) {
				tempStageId = workflowStages[0].id;
			}
			let tempIsShowChildStatusList = response.is_child_present;
			map(workflowStages, function (stage, index) {
				if (callFrom !== constants.OTHER && callFrom !== constants.COLUMNVIEW_DRAGDROP) {
					if (stage.name === selectedStage) {
						if ((index + 1) === workflowStages.length) {
							currentStageId = tempStageId;
						} else {
							let i = index + 1;
							currentStageId = workflowStages[i].id;
						}
					}
				} else {
					if (workStageFromDetailView === "END_STAGE") {
						currentStageId = workflowStages[(workflowStages.length - 1)].id
					} else {
						if (stage.name === workStageFromDetailView) {
							currentStageId = stage.id;
						}
					}
				}
			});

			if (overwriteCurrentStage) {
				currentStageId = overwriteStageId;
			}
			tempSelectedStatus = currentStageId;
			tempCurrentStage = response.current_stage;
			tempPendingTasks = response.pending_tasks.map(f => {
				let currentDate = moment().tz(timeZone[app.me.timezone]);
				currentDate = currentDate.format(dateFormat[app.me.date_format]);
				currentDate = moment(currentDate);
				let duebyDate = moment(f.dueby);
				duebyDate = duebyDate.format(dateFormat[app.me.date_format]);
				duebyDate = moment(duebyDate);
				if (duebyDate > currentDate) {
					f.isSelected = false;
					return f
				} else {
					f.isSelected = true;
					return f
				}
			});
			if (response.hasOwnProperty("unitowner_pending_tasks")) {
				tempUnitOwnerPendingTasks = response.unitowner_pending_tasks.map(f => {
					f.isSelected = false;
					return f;
				})
				setUnitOwnerPendingTasks(tempUnitOwnerPendingTasks);
			}
			if (response.hasOwnProperty("isFranchiseRecord")) {
				setIsFranchiseRecord(getBooleanParam(response.isFranchiseRecord));
			}
			if (response.hasOwnProperty("is_fa_exipration")) {
				setIsFAExpirationDate(getBooleanParam(response.is_fa_exipration));
			}
			setWorkflowList(response.workflow_list);
			setSelectedStage(selectedStage);
			let stages = response.stages;
			let tempStage = stages.filter(f => {
				return f.id === currentStageId
			});

			if (tempStage.length > 0 && tempStage[0].name === constants.OPPORTUNITY_STAGE) {
				openOpportunityExits();
			}

			if (response.hasOwnProperty('child_object_label')) {
				let linkTableLabel = response.child_object_label;
				let linkTableLabelTrim = linkTableLabel.substring(0, linkTableLabel.length - 1);
				linkTableLabelTrim = linkTableLabelTrim.toLowerCase();
				setChildObject(linkTableLabelTrim);
				setChildTableId(response.child_tableid);
				setChildStages(response.child_object_stages);
			}
			if (wfDeleteStatus) {
				getWorkFlowStageById(tempCurrentWorkflowId, false, 'other', workflowList);
				tempIsApplyWorkflow = true;
			} else if (isWorkflow) {
				if (objectName === constants.ACCOUNTS_OBJECT) {
					tempCurrentStageobj = workflowStages.filter((el) => el.id === currentStageId);
				}
				if (tempCurrentStageobj && tempCurrentStageobj.length > 0) {
					map(response.child_object_stages, (stage, index) => {
						if (stage === tempCurrentStageobj[0].name) {
							setChildSelectedStatus(stage);
						}
					});
				}
			} else {
				if (isRecycleBinObject) {
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.BATCH_ACTION.INVALID_STATUS_FOR_WORKFLOW_STAGE, null);
					closeDrawer();
				} else {
					if (object === constants.CONTACTS_OBJECT) {
						tempIsApplyWorkflow = true;
						if (isValidParam(data.stage) && isValidParam(data.currentStage)) {
							tempSelectedStatus = data.stage;
							tempCurrentStage = data.currentStage;
						}
					} else {
						getWorkFlowStageById(tempCurrentWorkflowId, false, 'other', response.workflow_list);
						tempIsApplyWorkflow = true;
					}
				}
			}
			if(tempCurrentWorkflowId == 0 && app.me.is_franchisee && object === constants.CONTACTS_OBJECT ){
				let wflist = response.workflow_list.filter((f)=> f.name !=='')
				let firstWFId = wflist[0].id;
				tempCurrentWorkflowId =firstWFId;
			}
			setPrevWorkflowId(tempPrevWorkflowId);
			setIsShowChildStatusList(tempIsShowChildStatusList);
			setPendingTasks(tempPendingTasks);
			setCurrentWorkflowId(tempCurrentWorkflowId);
			setCurrentStage(tempCurrentStage);
			setStages(workflowStages);
			setSelectedStatus(tempSelectedStatus);
			setIsApplyWorkflow(tempIsApplyWorkflow);
		});
	}

	useEffect(() => {
		const convertObj = {
			pendingTasks: pendingTasks,
			unitOwnerPendingTasks: unitOwnerPendingTasks,
			childSelectedStatus: childSelectedStatus,
			workflowList: workflowList,
			stages: stages,
			selectedStatus: selectedStatus,
			selectedStage: selectedStage,
			prevWorkflowId: prevWorkflowId,
			currentWorkflowId: currentWorkflowId,
			currentWorkflow: currentWorkflow,
			currentStage: currentStage,
			changedWorkflow: changedWorkflow,
			mounted: true,
			recordId: recordId,
			requiredFields: requiredFields,
			applyWorkflowStages: applyWorkflowStages,
			isApplyWorkflow: isApplyWorkflow,
			isWorkFlowObject: isWorkFlowObject,
			isShowChildStatusList: isShowChildStatusList,
			childStages: childStages,
			childObject: childObject,
			createTask: createTask,
			noteText: noteText
		};
		if (JSON.stringify(convert) !== JSON.stringify(convertObj)) {
			dispatch(updateConvertData(convertObj));
		}
	}, [applyWorkflowStages, createTask, changedWorkflow, childObject, childSelectedStatus, childStages, currentStage, currentWorkflow, currentWorkflowId, dispatch, isApplyWorkflow, isShowChildStatusList, pendingTasks, prevWorkflowId, recordId, requiredFields, selectedStage, selectedStatus, stages, unitOwnerPendingTasks, workflowList, requiredFields, noteText])

	useEffect(() => {
		try {
			if (convert) {
				let params = {};
				let localStages = isApplyWorkflow ? getArrayParam(applyWorkflowStages) : getArrayParam(stages);
				let selectedStageArr = localStages.filter(f => f.id === selectedStatus);
				if (selectedStageArr.length > 0) {
					params = {
						record_id: recordId,
						table_id: OBJECT_TABLEID_MAP[object],
					}
					if (stateObject === constants.ACCOUNTS_OBJECT && isApplyWorkflow) {
						params.stage_name = selectedStageArr[0].name;
						params.workflow_id = convert.currentWorkflowId;
					} else {
						params.stage_name = selectedStageArr[0].name;
						params.workflow_id = currentWorkflowId;

					}
					const promise = Promise.resolve(HTTPClient.get(endPoints.WORKFLOWS.GET_REQUIRED_FIELDS, params));
					if (isValidParam(promise)) {
						promise.then((response) => {
							if (isValidParam(response)) {
								if (object === constants.OPPORTUNITIES_OBJECT) {
									let includesProduct = response.includes("product");
									if (includesProduct) {
										response.push("product_id");
									}
								}
								setRequiredFields(getArrayParam(response));
								onChangeWorkflow(callFrom)
							}
						});
					}
				} else {
					setRequiredFields([]);
				}
			}
		} catch (error) {
			console.error("Error in 'convert.js -> getRequiredFields()':" + error);
		}
	}, [convert])

	const populateStatusList = () => {
		let statusList = [];

		let hasPermission = hasAccessPermission("", constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_ADD_TO_LIST);
		if (isApplyWorkflow) {
			if (applyWorkflowStages && applyWorkflowStages.length > 0) {
				statusList = applyWorkflowStages.map((item, i) => (<MenuItem value={item.id} style={styles.popoverMenuItem}>{item.name}</MenuItem>));			}
		} else {
			if (stages && stages.length > 0) {
				statusList = stages.map((item, i) => {
					return (<MenuItem value={item.id} disabled={item.name === selectedStage}>{item.name}</MenuItem>);				});
			}
		}

		return statusList;
	}

	const updatePendingTasks = (type, id) => {
		let taskArray = pendingTasks;
		try {
			taskArray = taskArray.filter(f => !(id === f.id && f.type === type));
			setPendingTasks(taskArray);
		} catch (error) {
			console.error("error in 'convert.js'-->'updatePendingTasks()'" + error);
		}
	}

	const updateUnitPendingTasks = (type, id) => {
		let taskArray = unitOwnerPendingTasks;

		try {
			taskArray = taskArray.filter(f => !(id === f.id && f.type === type));
			setUnitOwnerPendingTasks(taskArray);
		} catch (error) {
			console.error("error in 'convert.js'-->'updateUnitPendingTasks()'" + error);
		}
	}

	const getRecurringDeleteChoice = (val) => {
		dispatch(getAppDialog(false, constants.RECURRING_DELETE_DIALOG, null, null, null, null));
		deleteRecord(actionid, val);
	}

	const deleteRecord = (id, delete_action_type) => {
		let params = {};
		try {
			params.id = [id];
			if (delete_action_type !== null) {
				params.delete_action_type = delete_action_type;
			}
			if (id > 0 && actionobject === constants.TASKS) {
				let linkObject = getEndPointObject(actionobject);
				let url = linkObject.DELETE;
				const promise = Promise.resolve(HTTPClient.post(url, params));
				promise.then((response) => {
					if (response.status === 0) {
						if (actionobject === constants.TASKS) {
							dispatch(getTasksRefresh(true));
							getConvertDetails(stateObject);
						}
						dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
					} else if (response.status === -1 && response.error.message !== "") {
						dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
					}
				});
			}
		} catch (error) {
			console.error("error in 'convert.js'-->'deleteRecord()'" + error);
		}
	}

	const handelDelete = (record, isUnitTask) => {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		const recordIds = [];
		let type = record.type;
		const id = parseInt(record.id, 10);
		let objectName = type;
		let typeMsg = "";
		try {
			if (type === constants.TASKS) {
				recordIds.push(id);
				typeMsg = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS;
				if (isValidParam(record.parentRecurId) && record.parentRecurId > 0) {
					setActionid(id);
					setActionobject(type);
					let data = {};
					data.object = type;
					dispatch(getAppDialog(true, constants.RECURRING_DELETE_DIALOG, getLocalizedStrings().message.REPEAT_DIALOG.DELETE_RECUR_TITLE, getRecurringDeleteChoice, data));
					return false;
				}
			} else if (type === constants.APPOINTMENTS) {
				recordIds.push(id);
				typeMsg = getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENTS;
			} else if (type === constants.WORKFLOW_SMS || type === constants.SCHEDULE_SMS) {
				recordIds.push(id);
				typeMsg = 'SMS';
				objectName = constants.SMS_OBJECT;
			} else if (type === 'email_auto' || type === 'email_dm') {
				recordIds.push(id);
				typeMsg = 'Email';
				objectName = constants.EMAIL_OBJECT;
			}
			let msg = "";
			if (id > 0) {
				if (type === 'email_auto' || type === 'email_dm' || type === constants.WORKFLOW_SMS || type === constants.SCHEDULE_SMS) {
					msg = typeMsg + getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;
				} else {
					msg = typeMsg.charAt(0).toUpperCase() + typeMsg.slice(1, type.length - 1) + getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;
				}

			}
			let hasPermission = null;
			hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_DELETE);
			if (hasPermission && !isInXpress) {
				sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => handleSfDialogDelete(recordIds, type, typeMsg, msg, objectName, id, isUnitTask), null);
			} else {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
			}
		} catch (error) {
			console.error("error in 'convert.js'-->'handelDelete()'" + error);
		}
	}
	const handleSfDialogDelete = (recordIds, type, typeMsg, msg, objectName, id, isUnitTask) => {

		let param = {
			"id": recordIds
		}
		setCancelTaskId(id);

		if (type === 'email_auto' || type === 'email_dm') {
			if (type === 'email_auto') {
				param.taskType = 'AutoMail';
				param.isDelete = false;
			} else {
				param.taskType = 'DirectMail';
				param.parent_table_id = OBJECT_TABLEID_MAP[object];
				param.parent_record_id = getIntParam(detailViewRecord.id);
			}
			msg = typeMsg + getLocalizedStrings().message.COMMON.DELETE_SUCCESS;
		} else if (type === constants.WORKFLOW_SMS || type === constants.SCHEDULE_SMS) {
			param.taskType = type;
			if (type === 'Workflow SMS') {
				param.isDelete = false;
			}
			msg = typeMsg + getLocalizedStrings().message.COMMON.DELETE_SUCCESS;
		} else {
			msg = type.charAt(0).toUpperCase() + type.slice(1, type.length - 1) + getLocalizedStrings().message.COMMON.DELETE_SUCCESS;
		}
		const response = deleteAction(objectName, param);
		response.then((response) => {
			if (response.status === 0) {
				if (type === 'email_auto' || type === constants.WORKFLOW_SMS || type === constants.SCHEDULE_SMS) {
					let data = response.data;
					if (data.hasOwnProperty('isDependent') && data.isDependent) {
						sfDialogs.confirm("", 'This scheduled Email/SMS has dependent tasks and those dependent tasks will not be created.', () => handleSfDialogDeleteAction(objectName, type, id, msg, recordIds, typeMsg), null);

					} else {
						if (getBooleanParam(isUnitTask)) {
							updateUnitPendingTasks(type, id);
							dispatch(getUnitTasksRefresh(true));
						} else {
							dispatch(getTasksRefresh(true));
							updatePendingTasks(type, id);
						}
						if (callFrom === 'Detailview') {
							dispatch(refreshDetailViewListViewData(true));
						}
						dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
					}
				} else {
					if (getBooleanParam(isUnitTask)) {
						updateUnitPendingTasks(type, id);
						dispatch(getUnitTasksRefresh(true));
					} else {
						dispatch(getTasksRefresh(true));
						updatePendingTasks(type, id);
					}
					if (callFrom === 'Detailview') {
						dispatch(refreshDetailViewListViewData(true));
					}
					dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
				}
			}
		});
	}

	const handleSfDialogDeleteAction = (objectName, type, id, msg, recordIds, taskType) => {

		let params = {
			"id": recordIds,
			'taskType': taskType,
			'isDelete': true,
		}
		const response = deleteAction(objectName, params);
		response.then((response) => {
			if (response.status === 0) {
				dispatch(getTasksRefresh(true));
				updatePendingTasks(type, id);
				if (callFrom === 'Detailview') {
					dispatch(refreshDetailViewListViewData(true));
				}
				dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
			}
		});
	}

	const populatePendingTasks = () => {
		let pendingTaskList = '';
		let iconName = null;
		let iconText = null;
		let loginInfo = app.me;
		let iconStyle = { marginTop: '1px', float: 'left', paddingRight: "10px", fontSize: '15px', color: '#b5b5b5' };
		let isTenantAdmin = loginInfo.hasOwnProperty('is_tenant') && loginInfo.is_tenant;
		let loginUserId = loginInfo.id;
		let loginUsername = loginInfo.name;
		let count = 0;

		try {
			if (pendingTasks && pendingTasks.length > 0) {
				return pendingTasks.map((item, i) => {
					let isTaskOwner = false;
					let isShowDeleteAction = true;
					iconText = "";
					if (item.type === constants.SCHEDULE_SMS || item.type === 'email_dm') {
						if (loginUserId === getIntParam(item.userId)) {
							isTaskOwner = true;
						}
					} else if (item.type === 'tasks' && getStringParam(item.userName) === loginUsername) {
						isTaskOwner = true;
					}
					if (item.isRequired === 1 || (!isTenantAdmin && !isTaskOwner)) {
						isShowDeleteAction = false;
					}
					if (item.type === 'tasks') {
						iconName = 'fa fa-clock-o';
					} else if (item.type === 'email_auto' || item.type === 'email_dm') {
						isShowDeleteAction = false;
						iconName = 'fa fa-paper-plane';
					} else if (item.type === constants.WORKFLOW_SMS || item.type === constants.SCHEDULE_SMS) {
						isShowDeleteAction = false;
						iconName = 'material-icons';
						iconText = "textsms";
					}
					let htmlText = (
						<div style={{ display: 'inline-flex', position: 'relative', fontSize: '12px' }}>
							<i className={iconName} aria-hidden="true" style={iconStyle}>{iconText}</i>
							<div style={{ float: "left", paddingRight: "10px", maxWidth: '380px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
								{item.name}
							</div>
						</div>
					);

					if (item.type !== 'tasks') {
						count++;
					}
					return (
						<div>
							{count === 1 && <div style={{ paddingLeft: '5px', paddingBottom: '5px', marginBottom: '6px', fontWeight: 'normal', fontSize: '14px' }}>
								{getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS_CANCEL}
							</div>}
							<div className='checklist' style={{ display: 'flex', marginTop: '-8px' }}>
								<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'inline-block' }}>
									<FormControlLabel
										control={
											<Checkbox
												color="default"
												key={"convert_" + item.id}
												id={"tasks_cb_" + item.id}
												name="tasks_cb"
												checked={item.isSelected}
												value={item.isSelected}
												title={item.name}
												onChange={(e) => changeConvertDrawerCheckBoX(e.target.checked, item.id)}
												defaultChecked={true}
											/>
										}
										label={htmlText}
										labelposition="right"
										labelstyle={{ color: '#717171', fontWeight: 'normal', fontSize: '13px', marginLeft: '-8px' }}
										style={{ height: 'auto', width: '45%' }} title={item.name}
									/>
								</div>
								{isShowDeleteAction && (
									<div style={{ display: 'inline-block', verticalAlign: 'top', paddingLeft: '30px' }}>
										<span style={{ right: '22px', cursor: 'pointer', paddingTop: '9px', position: 'relative', color: '#717171' }} onClick={() => handelDelete(item, false)}>
											<Icon key={item.id} style={{ 'fontSize': '1.2em', fontWeight: 'normal', color: '#717171', paddingTop: '3px', marginTop: '10px', marginLeft: '-18px' }} title={getLocalizedStrings().label.COMMON.DELETE}>close</Icon>
										</span>
									</div>
								)}
							</div>
						</div>
					);
				});
			}
		} catch (error) {
			console.error("error in 'convert.js'-->'populatePendingTasks()'" + error);
		}
		return pendingTaskList;
	}

	const populateUnitOwnerPendingTasks = () => {
		let pendingTaskList = '';
		let iconName = null;
		let iconText = null;
		let loginInfo = app.me;
		let iconStyle = { marginTop: '3px', float: 'left', paddingRight: "10px", fontSize: '15px', color: '#b5b5b5' };
		let isTenantAdmin = loginInfo.hasOwnProperty('is_tenant') && loginInfo.is_tenant;
		let loginUsername = loginInfo.name;
		let count = 0;
		try {
			if (unitOwnerPendingTasks && unitOwnerPendingTasks.length > 0) {
				return unitOwnerPendingTasks.map((item, i) => {
					let isTaskOwner = false;
					let isShowDeleteAction = true;
					iconText = "";
					if (item.type === 'tasks' && getStringParam(item.userName) === loginUsername) {
						isTaskOwner = true;
					}
					if (item.isRequired === 1 || (!isTenantAdmin && !isTaskOwner)) {
						isShowDeleteAction = false;
					}
					if (item.type === 'tasks') {
						iconName = 'fa fa-clock-o';
					}
					let htmlText = (
						<div style={{ display: 'inline-flex', position: 'relative' }}>
							<i className={iconName} aria-hidden="true" style={iconStyle}>{iconText}</i>
							<div style={{ float: "left", paddingRight: "10px", maxWidth: '380px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
								{item.name}
								{item.isRequired === 1 && <span style={{ paddingLeft: "10px", fontSize: "11px", color: "#ff0000" }}>(required)</span>}
							</div>
						</div>
					);

					return (
						<div>
							{count === 1 && <div style={{ paddingLeft: '5px', paddingBottom: '5px', marginBottom: '6px', fontWeight: 'normal', fontSize: '14px' }}>
								{getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS_CANCEL}
							</div>}
							<div className='checklist' style={{ display: 'flex', marginTop: '-10px' }}>
								<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'inline-block' }}>

									<FormControlLabel
										control={
											<Checkbox
												color="default"
												key={"convert_" + item.id}
												id={"tasks_cb_" + item.id}
												name="tasks_cb"
												value={item.id}
												title={item.name}
												onChange={(e) => changeTaskOwnerPendingTask(e.target.checked, item.id)}
												defaultChecked={false}
												disabled={item.isRequired === 1}
											/>
										}
										label={htmlText} labelposition="right"
										labelstyle={{ color: '#717171', fontWeight: 'normal', fontSize: '13px', marginLeft: '-8px' }}
										style={{ height: 'auto', width: '45%' }}
										title={item.name}
									/>
								</div>
								{isShowDeleteAction && (
									<div style={{ display: 'inline-block', verticalAlign: 'top', paddingLeft: '30px' }}>
										<span style={{ right: '22px', cursor: 'pointer', paddingTop: '9px', position: 'relative', color: '#717171' }} onClick={() => handelDelete(item, true)}>
											<Icon key={item.id} style={{ 'fontSize': '1.2em', fontWeight: 'normal', color: '#717171', paddingTop: '3px', marginTop: '10px', marginLeft: '-18px' }} title={getLocalizedStrings().label.COMMON.DELETE}>close</Icon>
										</span>
									</div>
								)}
							</div>
						</div>
					);

				});
			}
		} catch (error) {
			console.error("error in 'convert.js'-->'populateUnitOwnerPendingTasks()'" + error);
		}
		return pendingTaskList;
	}

	const populateChildStatusList = () => {
		let childStatusList = [];
		if (childStages && childStages.length > 0) {
			childStatusList = childStages.map((item) => (
				<MenuItem value={item} style={styles.popoverMenuItem} onClick={(e) => changeChildStatus(item, e)}>{item}</MenuItem>))
		}
		return childStatusList;
	}

	function changeStatus(event) {

		let value = event.target.value;
		let tempChldStatus = "-1";
		let convertObj = convert;

		if (value === 'addto_status' && object === constants.CONTACTS_OBJECT && isValidParam(workflowList) && workflowList.length === 1) {
			setIsAddToThisList(true);
			convertObj.isAddToThisList = true;
			value = '';
		} else {
			if (isApplyWorkflow) {
				let tempStages = applyWorkflowStages;

				let tempCurrentStageobj = tempStages.filter(function (el) {
					if (el.id === value) {
						return el;
					}
				});

				map(childStages, (stage, index) => {
					if (stage === tempCurrentStageobj[0].name) {
						tempChldStatus = stage;
					}
				});

			} else {
				if (stateObject === constants.ACCOUNTS_OBJECT) {
					let tempCurrentStageobj = stages.filter((el) => el.id === value);

					if (tempCurrentStageobj.length > 0 && tempCurrentStageobj[0].name === constants.OPPORTUNITY_STAGE) {
						openOpportunityExits();
					}

					map(childStages, function (stage, index) {
						if (stage === tempCurrentStageobj[0].name) {
							tempChldStatus = stage;
						}
					});
				}
			}
		}
		setSelectedStatus(value);
		setChildSelectedStatus(tempChldStatus);
	}

	const addNewStatus = (event) => {
		setSelectedStatus(event.target.value);
	}

	const changeChildStatus = (value, event) => {
		if (value !== '-1') {
			setChildSelectedStatus(value)
		} else {
			setChildSelectedStatus(value)
		}
		convert.childSelectedStatus = value;
	}

	const handleChangeCreateTask = (event, isInputChecked) => {
		if (event) {
			setCreateTask(isInputChecked);
			dispatch(updateConvertDataByKey('createTask', isInputChecked));
		}
	}
	const changeConvertDrawerCheckBoX = (value, id) => {
		setIsSaved(false);
		let tempPending = pendingTasks.map((e) => {
			if (e.id === id) {
				e.isSelected = value;
			}
			return e;
		});
		setPendingTasks(tempPending)
	}
	const changeTaskOwnerPendingTask = (value, id) => {
		let tempUnitOwnerPendingTasks = unitOwnerPendingTasks.map((e) => {
			if (e.id === id) {
				e.isSelected = value;
			}
			return e;
		})
		setUnitOwnerPendingTasks(tempUnitOwnerPendingTasks)
	}

	function showWorkflowList() {
		setIsApplyWorkflow(false);
		getWorkFlowStageById(currentWorkflowId, false, 'other', workflowList);
	}

	const getWorkflowListByStage = (prevWorkflowId, currentWorkflowId, stages) => {
		let url = endPoints.ACCOUNTS.WORKFLOW_LIST_BY_STAGE;
		url = url.replace('{object}', data.object);
		let param = {
			object: data.object,
			stage_name: data.stage
		}
		setMounted(false);
		const response = Promise.resolve(HTTPClient.get(url, param));
		response.then((response) => {
			setMounted(true);
			if (isValidParam(getArrayParam(response)) && response !== '') {
				if (object === constants.CONTACTS_OBJECT && currentWorkflowId === 0) {
					let selectedStage = stages.filter(f => f.name === data.stage);
					if (Array.isArray(selectedStage) && selectedStage.length === 1) {
						response.unshift({ id: 0, name: '' });
						setSelectedStatus(selectedStage[0].id);
					}
				}
				let currentWF = response.filter(f => f.id === prevWorkflowId);
				let currentWfID;
				if (Array.isArray(currentWF) && currentWF.length === 1) {
					setCurrentWorkflowId(currentWF[0].id);
					setCurrentWorkflow(currentWF[0].name);
					setIsApplyWorkflow(false);
					currentWfID = currentWF[0].id;
				} else {
					setCurrentWorkflowId(response[0].id);
					setCurrentWorkflow(response[0].name);
					setIsApplyWorkflow(true);
					currentWfID = response[0].id;
				}

				setWorkflowList(response);
				if (data.object === constants.CONTACTS_OBJECT) {
					setIsApplyWorkflow(false);
				}
				if (currentWfID !== 0) {
					getWorkFlowStageById(currentWfID, true, 'other', response);
				}

			} else {
				if (object === constants.CONTACTS_OBJECT && currentWorkflowId === 0) {
					let selectedStage = stages.filter(f => f.name === data.stage);
					if (Array.isArray(selectedStage) && selectedStage.length === 1) {
						setSelectedStatus(selectedStage[0].id);
						setIsApplyWorkflow(true);
						setWorkflowList([{ id: 0, name: '' }]);
					}
				} else {
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You cannot move. The target status does not belong to any workflow.', null);
					closeDrawer();
				}
			}
		});
	}

	const getWFList = () => {
		let _workFlowList = [];
		let List = getArrayParam(workflowList);
		if( stateObject === constants.ACCOUNTS_OBJECT && (!app.me.is_franchisor && app.me.is_franchisee)){
			List = List?.filter((f)=>{
				return f?.name != "Onboarding"
			})
		}
		List.map((item, i) => {
			_workFlowList.push(<MenuItem value={item.id} style={styles.popoverMenuItem}>{item.name}</MenuItem>);
		})
		return _workFlowList;
	}
	const changeWorkflow = (event) => {
		let value = event.target.value;
		let List = getArrayParam(workflowList);
		let changedWorkflowName = null;
		let tempPendingTasks = pendingTasks;
		List.map((item, i) => {
			if (item.id === value) {
				changedWorkflowName = item.name;
			}
		})
		tempPendingTasks = pendingTasks.map(f => {
			f.isSelected = true;
			return f
		});

		setCurrentWorkflowId(value);
		setChangedWorkflow(changedWorkflowName);
		setPendingTasks(tempPendingTasks);
		if (object !== constants.CONTACTS_OBJECT) {
			setIsApplyWorkflow(true);
		}
		getWorkFlowStageById(value, false, 'onchange', List);
	}

	const getWorkFlowStageById = (workFlowId, defaultValue, callFrom, workflowList) => {

		const isExistWf = workflowList.findIndex((el) => el.id === workFlowId) > -1;
		let tempSelectedStatus;
		let tempChldStatus = null;
		let params = null;
		if (isExistWf) {
			params = {
				workflow_id: workFlowId,
			}
		} else {
			params = {
				workflow_id: workflowList[0].id,
			}
			setCurrentWorkflowId(workflowList[0].id);
		}

		const response = getWorkFlowStage(stateObject, params);
		response.then((response) => {
			tempSelectedStatus = response.stages[0].id;
			setApplyWorkflowStages(response.stages);
			if (defaultValue) {
				let stages = response.stages.filter(f => f.name === data.stage);
				if (stages.length === 1) {
					tempSelectedStatus = stages[0].id;
				}
			}
			if (data.hasOwnProperty('columnViewDragDropForAll') && data.columnViewDragDropForAll === true && response.stages.length > 0) {
				let filterStages = response.stages.filter(f => f.name === data.stage);
				if (Array.isArray(filterStages) && filterStages.length === 1) {
					tempSelectedStatus = filterStages[0].id;
				}
				setStages(response.stages);
				setApplyWorkflowStages(response.stages);
				let defaultParentObjectStage = response.stages[0].name

				map(childStages, function (stage, index) {
					if (stage === defaultParentObjectStage) {
						tempChldStatus = stage;
					}
				});
				setChildSelectedStatus(tempChldStatus)
			}
			setSelectedStatus(tempSelectedStatus);
			if (isApplyWorkflow) {
				let defaultParentObjectStage = response.stages[0].name
				map(childStages, function (stage, index) {
					if (stage === defaultParentObjectStage) {
						tempChldStatus = stage;
					}
				});
				setChildSelectedStatus(tempChldStatus);
			}
		});
	}
	const onChangeWorkflow = (callFrom) => {
		let recordId = 0;
		if (callFrom === 'Detailview') {
			recordId = detailView.data.record.id;
		} else if (callFrom === "Listview") {
			recordId = selectedRecords[0].id;
		} else if (callFrom === "columnview") {
			recordId = selectedRecords[0].id;
		} else if (callFrom === constants.COLUMNVIEW_DRAGDROP) {
			recordId = data.id;
		}

		if (callFrom === 'onchange') {
			if ((changedWorkflow === 'Onboarding' || changedWorkflow === 'Franchisee')) {
				if (app.me.franchisor_add_unit) {
					if (!isFranchiseRecord) {
						if (!isFAExpirationDate) {
							let msg = "The 'FA Expiration Date' is not valid. Please update it with a valid date and then convert this record.";
							sfDialogs.alert(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, null);
						} else {
							getAccountRecord(recordId);
						}
					} else if (isFranchiseRecord && changedWorkflow === 'Onboarding' && currentWorkflow === 'Franchisee') {
						let msg = "You cannot move the record from Franchisee to Onboarding WF.";
						sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
					}
				} else {
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
				}
			}
		}

	}

	const getAccountRecord = (recordId) => {

		const Url = endPoints.COMMON.SEARCH_CLIENT;
		const param = {};
		if (recordId && recordId > 0) {
			param.recordId = recordId;
			param.tableId = OBJECT_TABLEID_MAP[constants.ACCOUNTS_OBJECT];
			const response = Promise.resolve(HTTPClient.get(Url, param));
			response.then((response) => {
				if (isValidParam(response)) {
					dispatch(getAppCustomDrawer(false, null, null, null, null, null, null));
					openUnitForm(response, recordId);
				} else {

				}
			});
		} else {
			dispatch(showCustomSnackBar("No account found for " + ". Please try again.", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
		}

	}

	const openUnitForm = (recordDetails, recordId) => {
		let pendingTaskArray = [];
		let cancelEmailSMSList = [];
		let tempSelectedArr = pendingTasks.filter(e => e.isSelected == true);
		pendingTaskArray = tempSelectedArr.filter(e => e.type == 'tasks');
		cancelEmailSMSList = tempSelectedArr.filter(e => e.type != 'tasks');
		let _customeNote = getStringParam(noteText);
		_customeNote = _customeNote.trim();

		let labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + getLocalizedStrings().label.SIDE_NAV.UNITS;
		
		let data = {};
		data.object = constants.UNITS_OBJECT;
		data.mode = "QUICK";
		data.records = recordDetails.records;
		data.mainContactName = recordDetails.mainContactName;
		data.accountRecordId = recordId;
		data.clientProjectId = recordDetails.clientProjectId;
		data.clientEmail = recordDetails.email;
		data.callFrom = callFrom;
		data.cancel_email_SMS_list = cancelEmailSMSList;
		data.current_workflow_name = currentWorkflow;
		data.pending_task = pendingTaskArray;
		data.current_status = currentStage;
		data.is_create_next_task = createTask;
		data.note_text = _customeNote;
		data.child_tableid = childTableId;
		data.click_form = 'Convert';
		data.mainContactId = recordDetails.mainContactId;
		data.billingMessage = getStringParam(recordDetails.billingMessage);
		data.callFrom = "Detailview";
		dispatch(getAppDrawer(true, labelName, 'New', data, null));
	}
	const convertAndAddOpp = () => {
		let hasPermission = null;
		hasPermission = hasAccessPermission(constants.OPPORTUNITIES_OBJECT, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_CREATE);
		if (hasPermission) {

			let pendingTaskArray = pendingTasks.filter(e => e.isSelected === true);
			let stageName = null;
			let sameStatusMsg = '';
			let _customeNote = getStringParam(noteText);
			_customeNote = _customeNote.trim();
			let statusId = selectedStatus;
			let object = stateObject;
			let objectLabel = getObjectLabelByObject(object);
			let refreshobj = data.hasOwnProperty('refresh_object') ? data.refresh_object : stateObject;
			let workflowStageList = stages;
			map(workflowStageList, function (stage, index) {
				if (stage.id === statusId) {
					stageName = stage.name;
				}
			});
			if (!isApplyWorkflow) {
				if (currentStage !== stageName) {
					let params = {
						"id": recordId,
						"current_status": currentStage,
						"changed_status": stageName,
						"is_create_next_task": createTask,
						"pending_task": pendingTaskArray,
						"note_text": _customeNote,
						"child_tableid": childTableId,
						"child_status": childSelectedStatus === '-1' ? '' : childSelectedStatus,
						"convert_type": constants.SINGLE_CONVERT_REC,
						"current_workflow_name": currentWorkflow,
						"change_workflow_name": changedWorkflow
					}
					const confirmMsg = objectLabel + getLocalizedStrings().message.BATCH_ACTION.CONVERT_ALERT_MSG + stageName + "." + getLocalizedStrings().message.BATCH_ACTION.CLICK_TO_CONFIRM;
					sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, confirmMsg, () => handleSfDialogConvertAndAddOpp(params, refreshobj, stageName), null);
				} else {
					let object = stateObject;
					object = object.substring(0, object.length - 1);
					sameStatusMsg = object + getLocalizedStrings().message.BATCH_ACTION.CONVERT_SAME_STAGE + stageName + ' status.';
					dispatch(showCustomSnackBar(sameStatusMsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
				}
			}
		} else {
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
		}
	}

	const handleSfDialogConvertAndAddOpp = (params, refreshobj, stageName) => {
		const response = saveConvert(stateObject, params);
		setMounted(false);
		response.then((response) => {
			if (response.status === 0) {
				if (detailView.isDetailView === true) {
					dispatch(detailViewIsMounted(false));
					dispatch(isDetailViewSet(false));
					dispatch(refreshDetailViewData(true));
					dispatch(refreshDetailViewListViewData(true));
					dispatch(getTasksRefresh(true));
					closeDrawer();
				}
				if ((stateObject === constants.ACCOUNTS_OBJECT) && stageName === constants.OPPORTUNITY_STAGE) {
					let label = getLocalizedStrings().label.COMMON.ADD + ' ' + constants.OPPORTUNITIES_OBJECT.charAt(0).toUpperCase() + constants.OPPORTUNITIES_OBJECT.slice(1, constants.OPPORTUNITIES_OBJECT.length);
					let data = {};
					data.object = constants.OPPORTUNITIES_OBJECT;
					data.mode = constants.SF_FORM_OPEN_MODE_QUICK;
					data.parent_object = stateObject;
					data.parent_record_id = recordId;
					data.isDetailView = true;
					dispatch(getAppDrawer(true, label, constants.NEW, data, callFrom));
				} else {
					let msg = "Converted Successfully";
					dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
					closeDrawer();
				}
			} else if (response.status === -1) {
				dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION.CONVERT_UNSUCCESSFULL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
			}
		});
	}

	const onFieldConvert = (type) => {
		try {
			if (type === 'oppConvert') {
				let temp = showRequiredFormFields;
				temp.showRequiredFormFields = false
				setShowRequiredFormFields(temp);
				setRequiredFields([]);
				convertAndAddOpp();
			} else if (type === 'InvalidForm') {
				setIsSaved(false);
			} else {
				fnConvert();
			}
		} catch (error) {
			console.error("error in 'convert.js'-->'onFieldConvert()'" + error);
		}
	}
	const doConvert = (convertType) => {
		let type = convertType === 'oppConvert' ? convertType : null;
		setIsSaved(true);
		let isConvert = true;
		let tempRecordId = 0;
		try {
			if (callFrom === 'Detailview') {
				tempRecordId = detailView.data.record.id;
			} else if (callFrom === "Listview") {
				tempRecordId = selectedRecords[0].id;
			} else if (callFrom === "columnview") {
				tempRecordId = selectedRecords[0].id;
			} else if (callFrom === constants.COLUMNVIEW_DRAGDROP) {
				tempRecordId = data.id;
			}
			if ((changedWorkflow === 'Onboarding' || changedWorkflow === 'Franchisee')) {
				if (app.me.franchisor_add_unit) {
					if (!isFranchiseRecord) {
						if (!isFAExpirationDate) {
							let msg = "The 'FA Expiration Date' is not valid. Please update it with a valid date and then convert this record.";
							isConvert = false;
							sfDialogs.alert(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, null);
						} else {
							isConvert = false;
							getAccountRecord(tempRecordId);
						}
					} else if (isFranchiseRecord && changedWorkflow === 'Onboarding' && currentWorkflow === 'Franchisee') {
						isConvert = false;
						let msg = "You cannot move the record from Franchisee to Onboarding WF.";
						sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
					}
				} else {
					isConvert = false;
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
				}
			}
			if (isConvert) {
				if (isValidParam(requiredForms.current)) {
					let requriredFormInstance = requiredForms;
					if (isValidParam(requriredFormInstance.current)) {
						let isvalid = requriredFormInstance.current.saveSFForm(type);
						if (isvalid) {
							setMounted(false);
						}
					}
				} else {
					if (type === 'oppConvert') {
						convertAndAddOpp();
					} else {
						fnConvert();
					}
				}
			}
		} catch (error) {
			console.error("error in 'convert.js'-->'doConvert()'" + error);
		}
	}


	const fnConvert = () => {
		let pendingTaskArr = document.getElementsByName('tasks_cb');

		let allNotSelected = false;
		for (let i = 0; i < pendingTaskArr.length; i++) {
			if (!pendingTaskArr[i].checked) {
				allNotSelected = true;
				break;
			}
		}

		if (allNotSelected) {
			let msg = getLocalizedStrings().message.APPOINTMENTS_TASKS.CONVERT_CONFIRMATION_MESSAGE;
			sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => doConvertOperation(), null);
			setIsSaved(false);
		} else {
			doConvertOperation();
		}
	}

	const doConvertOperation = () => {
		let recordIds = [];
		let stageName = null;
		let msg = '';
		let sameStatusMsg = '';
		let pendingTaskArray = [];
		let tempCancelEmailSMSList = [];
		let tempSelectedArr = pendingTasks.filter(e => e.isSelected === true);
		pendingTaskArray = tempSelectedArr.filter(e => e.type === 'tasks');
		tempCancelEmailSMSList = tempSelectedArr.filter(e => e.type !== 'tasks');
		setCancelEmailSMSList(tempCancelEmailSMSList);
		let tempCustomeNote = getStringParam(noteText);

		tempCustomeNote = tempCustomeNote.trim();
		let pendingTaskFromState = pendingTasks;
		if (getArrayParam(unitOwnerPendingTasks).length > 0) {
			pendingTaskFromState = pendingTaskFromState.concat(unitOwnerPendingTasks);
		}
		let client_Id = 0;
		if (detailView.isDetailView === true) {
			client_Id = detailView.data.record.myclient_Id;
		}

		let UncheckObjectList = pendingTaskFromState.filter(e => e.isSelected === false);
		pendingTaskFromState = pendingTaskFromState.filter(e => e.isSelected === true);
		let statusId = selectedStatus;
		let object = stateObject;
		let objectLabel = getObjectLabelByObject(object);
		let appointmentSub = '';
		let hasWorkflowAppointment = false;
		let refreshobj = data.hasOwnProperty('refresh_object') ? data.refresh_object : stateObject;
		if (object === constants.CONTACTS_OBJECT && currentWorkflowId === 0) {
			setCreateTask(false);
		}

		let workflowStageList = stages;
		map(workflowStageList, function (stage, index) {
			if (stage.id === statusId) {
				stageName = stage.name;
			}
		});
		if (!isApplyWorkflow) {
			if (currentStage !== stageName) {
				let params = {
					"id": recordId,
					"current_status": currentStage,
					"changed_status": stageName,
					"is_create_next_task": createTask,
					"pending_task": pendingTaskFromState,
					"note_text": tempCustomeNote,
					"child_tableid": childTableId,
					"child_status": childSelectedStatus === '-1' ? '' : childSelectedStatus,
					"convert_type": constants.SINGLE_CONVERT_REC,
					"uncheked_task": UncheckObjectList,
					"cancel_email_SMS_list": tempCancelEmailSMSList,
					"stage_id": statusId,
					"current_workflow_name": currentWorkflow,
					"change_workflow_name": changedWorkflow
				}
				msg = objectLabel + getLocalizedStrings().message.BATCH_ACTION.SINGLE_CONVERT_SUCCESSFULL;
				const response = saveConvert(stateObject, params);
				setMounted(false);
				response.then((response) => {
					if (response.status === 0) {
						dispatch(isDetailViewSet(false));
						if (response.data.hasOwnProperty('Appointment_sub')) {
							appointmentSub = response.data.Appointment_sub;
							hasWorkflowAppointment = response.data.hasWorkflowAppointment;
						}
						if (detailView.isDetailView && callFrom !== 'Listview') {
							dispatch(detailViewIsMounted(false));
							dispatch(isDetailViewSet(false));
							dispatch(refreshDetailViewData(true));
							dispatch(refreshDetailViewListViewData(true));
							dispatch(getTasksRefresh(true));
							params = {
								...params,
								isDetailView: true
							};
							refreshListView(stateObject, params,null,suffix);
							if (client_Id > 0) {
								dispatch(getUnitTasksRefresh(true));
							}

							let listViewTab = tab.tabs.find(f => {
								return f.type === TYPE_LIST_VIEW || f.type === TYPE_COLUMN_VIEW
							});
							if (isValidParam(listViewTab) && listViewTab.type === TYPE_LIST_VIEW) {
								let tabInfo = listViewTab.info;
								let groupByCondition = getStringParam(tabInfo.groupByCondition);
								if (groupByCondition !== '' && groupByCondition !== stageName && getIntParam(tabInfo.groupByConditionTotalCount) === 1) {
									delete tabInfo.groupByCondition;
									delete tabInfo.groupByConditionTotalCount;
								}
								setListViewMounted(false, refreshobj);
							} else if (isValidParam(listViewTab) && listViewTab.type === TYPE_COLUMN_VIEW) {
								refreshColumnView(stateObject)
								setKanbanMounted(false);
							}
						} else {
							if (stateObject === constants.OPPORTUNITIES_OBJECT && callFrom === constants.KANBAN) {
								appContainer.customDrawerProps.data.callBack(stageName);
							} else if ((stateObject === constants.ACCOUNTS_OBJECT || stateObject === constants.OPPORTUNITIES_OBJECT
								|| stateObject === constants.CASES_OBJECT || stateObject === constants.PROJECTS_OBJECT
								|| stateObject === constants.ISSUES_OBJECT || stateObject === constants.CUSTOM_TABLE1_OBJECT
								|| stateObject === constants.CUSTOM_TABLE2_OBJECT || stateObject === constants.CUSTOM_TABLE3_OBJECT
								|| stateObject === constants.CUSTOM_TABLE4_OBJECT || stateObject === constants.CONTACTS_OBJECT) && callFrom === constants.COLUMN_VIEW) {
								appContainer.customDrawerProps.data.callBack(stageName);

								const isRequiredListViewRefresh = getBooleanParam(appContainer.customDrawerProps.data.isRequiredListViewRefresh);
								if (isRequiredListViewRefresh) {
									setListViewMounted(false, stateObject);
									let tabInfo = getObjectParam(getActiveTabInfo());
									delete tabInfo.groupByCondition;
								}
								dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
								closeDrawer();
							} else if (callFrom === constants.COLUMNVIEW_DRAGDROP) {
								appContainer.customDrawerProps.data.callBack(stageName);

							} else {
								let tabInfo = getObjectParam(getActiveTabInfo());
								if (tabInfo.hasOwnProperty('searchFieldName') && tabInfo.hasOwnProperty('searchText')) {
									params = {
										...params,
										search_field_name: getStringParam(tabInfo.searchFieldName),
										search_text: getStringParam(tabInfo.searchText)
									}
								}
								let listViewObject = data.hasOwnProperty("listViewObject") ? data.listViewObject : "";
								if (listViewObject === constants.FIND_DUPLICATE_OBJECT) {
									refreshListView(data.listViewObject, params,null,suffix);

								} else {
								}

							}

						}

						if ((stateObject === constants.CASES_OBJECT) && stageName === constants.STATUS_CLOSE) {
							let label = getLocalizedStrings().label.COMMON.ADD + ' ' + constants.SOLUTIONS_OBJECT.charAt(0).toUpperCase() + constants.SOLUTIONS_OBJECT.slice(1, constants.SOLUTIONS_OBJECT.length);

							let data = {};
							data.object = constants.SOLUTIONS_OBJECT;
							data.mode = constants.SF_FORM_OPEN_MODE_QUICK;
							data.parent_object = stateObject;
							data.parent_record_id = recordId;
							dispatch(getAppDrawer(true, label, constants.NEW, data, callFrom));
						} else if ((stateObject === constants.OPPORTUNITIES_OBJECT) && stageName === workflowStageList[workflowStageList.length - 1].name) {
							let value = constants.CONVERT;
							let labelName = constants.CONVERT;
							let linkData = {
								parent_object: stateObject,
								parent_record_id: recordId,
								fields: ["t_status", "id"]
							}
							const childResponse = Promise.resolve(HTTPClient.get(endPoints.ACCOUNTS.LIST_GET, linkData));
							childResponse.then((childResponse) => {
								if (childResponse.records !== null && childResponse.records.length > 0) {
									let childRecord = childResponse.records[0];
									if (childRecord.hasOwnProperty('t_status')) {
										dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
										closeDrawer();
										if (childRecord.t_status !== 'Customer') {
											let data = {
												stage: "END_STAGE",
												id: childResponse.records[0].id,
												object: constants.ACCOUNTS_OBJECT,
												refresh_object: constants.OPPORTUNITIES_OBJECT,
												isRefresh: false
											}
											let style = {};
											style.width = '40%';
											let type = constants.OTHER;
											dispatch(getAppCustomDrawer(true, labelName, value, style, data, null, true, constants.OTHER));

										} else {
											dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
											closeDrawer();
										}
									}
								} else {
									dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
									closeDrawer();
								}
							})
						} else {
							dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));

							if (stateObject === constants.ACCOUNTS_OBJECT && getStringParam(stageName).length > 0 && stageName === constants.GREENLIGHT_STAGE_DENIAL_INQUIRY) {
								closeDrawer();
								let email = constants.GREENLIGHT_TO_EMAIL;
								let sendMailLabel = getLocalizedStrings().label.COMMON.EMAIL_TO + " " + email + "";
								let type = LinkToType.TYPE_EMAIL;
								let data = {};
								data.id = recordId;
								data.email = email;
								data.parentObject = getStringParam(stateObject);
								data.type = constants.TYPE_EMAIL;
								data.stageName = constants.GREENLIGHT_STAGE_DENIAL_INQUIRY;
								dispatch(getAppCustomDrawer(true, sendMailLabel, type, { width: '90%' }, data, null, true));
							}
						}
						if (stageName !== constants.GREENLIGHT_STAGE_DENIAL_INQUIRY) {
							closeDrawer();
						}
						if ((stateObject === constants.ACCOUNTS_OBJECT || stateObject === constants.CONTACTS_OBJECT) && appointmentSub !== '') {

							let _data = {
								id: 0,
								object: constants.APPOINTMENTS,

							}
							if (detailView.isDetailView === true) {
								_data['parent_object'] = object;
								_data['parent_record_id'] = detailViewRecord.id;
								_data['isDetailView'] = detailView.isDetailView;
								_data['appointmentSub'] = appointmentSub;
								_data['hasWorkflowAppointment'] = hasWorkflowAppointment;
								_data['callType'] = 'TYPE_DETAIL_VIEW';
							} else {
								_data['parent_object'] = object;
								_data['parent_record_id'] = selectedRecords[0].id;
								_data['isDetailView'] = true;
								_data['appointmentSub'] = appointmentSub;
								_data['hasWorkflowAppointment'] = hasWorkflowAppointment;
								_data['callType'] = 'TYPE_LIST_VIEW';
							}
							let add = getLocalizedStrings().label.COMMON.ADD_NEW_APPOINTMENT;
							let _labelName = add + ' ' + getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENT;
							dispatch(getAppDrawer(true, _labelName, constants.NEW, _data, constants.OTHER));
						}
						
						let tempIdList = [];
						tempIdList.push(recordId);
						dispatch(removeRequiredFieldIds({ [stateObject]: tempIdList }));
						dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));


						if (queueList?.call_from == "Listview" && queueList.data.object == 'tasks') {
							let nextRecordIndex = 0;
							let newIdx = 0;
							let detailViewId = cancelTaskId;
							if (detailViewId > 0) {
								let data = queueList?.data;
								let _queueList = data?.records;
								let queueObj = _queueList?.filter((obj) => {
									return obj.id === detailViewId;
								});
								let index =
									isValidParam(queueObj) && queueObj.length > 0 ? queueObj[0].idx : -1;
								let objectName = getStringParam(queueList.data.object);

								if (index >= 0) {
									if (index + 1 < _queueList.length) {
										nextRecordIndex = index + 1;
										newIdx = index;
									} else {
										nextRecordIndex = index - 1;
										newIdx = nextRecordIndex;
									}

									let prevObj = _queueList[nextRecordIndex];
									let preId =
										isValidParam(prevObj) && prevObj !== null
											? getIntParam(prevObj.id)
											: 0;
									if (preId > 0) {
										let total_count = getIntParam(data.total_count);
										total_count = total_count - 1;
										let _label = prevObj.title;
										removeRecordFromRedux(objectName, detailViewId, total_count);
										if (detailViewId === queueList.data.openedRecordInQueueId) {
											if (objectName === constants.TASKS) {
												queueList.data["openedRecordInQueueId"] = preId;
												queueList.data["openedRecordInQueueIndex"] = newIdx;
												queueList.data["openedRecordInQueuelabel"] = _label;
												let linkInfo = prevObj.linkInfo;
												if (linkInfo !== null && linkInfo !== undefined) {
													let linkInfoArr = linkInfo.split(",");
													let selectedObjId = getIntParam(
														OBJECT_TABLEID_MAP[queueList.data.selecteddrpdwnObj]
													);
													linkInfoArr.map((item, i) => {
														let ObjRecordArr = item.split("_");
														let tableId = getIntParam(ObjRecordArr[0].trim());
														if (selectedObjId === tableId) {
															preId = getIntParam(ObjRecordArr[1]);
															objectName = queueList.data.selecteddrpdwnObj;
														}
													});
												}
											}
											openDetailviewQueueTab(
												objectName,
												_label,
												preId,
												newIdx,
												total_count
											);
										}
										dispatch(refreshDetailViewListViewData(true, detailViewId));
									}
								}

							}
						}


					} else if (response.status === -1) {
						dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION.CONVERT_UNSUCCESSFULL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
					}
				});
			} else {
				let object = stateObject;
				object = object.substring(0, object.length - 1);
				sameStatusMsg = makeTiteleCase(object) + getLocalizedStrings().message.BATCH_ACTION.CONVERT_SAME_STAGE + stageName + ' status.';
				dispatch(showCustomSnackBar(sameStatusMsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
				setIsSaved(false);
			}
		} else {
			recordIds.push(recordId);
			let isRefresh = data.hasOwnProperty('isRefresh') ? getBooleanParam(data.isRefresh) : true;
			let stageName = null;
			let statusId = selectedStatus;
			let wfStageList = applyWorkflowStages;
			map(wfStageList, function (stage, index) {
				if (stage.id === statusId) {
					stageName = stage.name;
				}

			});
			if (stateObject === constants.ACCOUNTS_OBJECT && constants.GREENLIGHT_STAGE === getStringParam(stageName)) {
				let currentWf = getStringParam(currentWorkflow);
				let _currentStage = getStringParam(currentStage);
				let txt = 'Converted to Greenlight from ' + currentWf + ' , stage ' + _currentStage;
				if (tempCustomeNote.length > 0) {
					tempCustomeNote = tempCustomeNote.concat('\n').concat(txt);
				} else {
					tempCustomeNote = getStringParam(txt);
				}
			}

			let params = {
				"id": recordIds,
				"workflow_id": currentWorkflowId,
				"stage": stageName,
				"note_text": tempCustomeNote,
				"child_tableid": childTableId,
				"child_status": childSelectedStatus === '-1' ? '' : childSelectedStatus,
				"current_status": currentStage,
				"changed_status": stageName,
				"is_create_next_task": createTask,
				"pending_task": pendingTaskArray,
				"stage_id": currentWorkflowId > 0 ? selectedStatus : 0,
				"cancel_email_SMS_list": tempCancelEmailSMSList,
				"current_workflow_name": currentWorkflow,
				"change_workflow_name": changedWorkflow
			}

			const response = applyWorkFlow(stateObject, params);
			response.then((response) => {
				if (response.status === 0) {
					dispatch(isDetailViewSet(false));
					if (response.data.hasOwnProperty('Appointment_sub')) {
						appointmentSub = response.data.Appointment_sub;
						hasWorkflowAppointment = response.data.hasWorkflowAppointment;
					}
					closeDrawer();
					if (((data.hasOwnProperty('columnViewDragDropForAll') && data.columnViewDragDropForAll === true) || (object === constants.CONTACTS_OBJECT)) && prevWorkflowId !== null && prevWorkflowId === currentWorkflowId) {
						msg = objectLabel + getLocalizedStrings().message.BATCH_ACTION.SINGLE_CONVERT_SUCCESSFULL;
						dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
					} else {
						dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION.WORKFLOW_APPLIED, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
					}
					if (stateObject === constants.ACCOUNTS_OBJECT && getStringParam(stageName).length > 0 && stageName === constants.GREENLIGHT_STAGE_DENIAL_INQUIRY) {
						let email = constants.GREENLIGHT_TO_EMAIL;
						let sendMailLabel = getLocalizedStrings().label.COMMON.EMAIL_TO + " " + email + "";
						let type = LinkToType.TYPE_EMAIL;
						let data = {};
						data.id = recordId;
						data.email = email;
						data.parentObject = getStringParam(stateObject);
						data.type = constants.TYPE_EMAIL;
						data.stageName = constants.GREENLIGHT_STAGE_DENIAL_INQUIRY;
						dispatch(getAppCustomDrawer(true, sendMailLabel, type, { width: '90%' }, data, null, true));
					}
					dispatch(refreshDetailViewData(true));
					dispatch(refreshDetailViewListViewData(true));
					dispatch(getTasksRefresh(true));
					if (client_Id > 0) {
						dispatch(getUnitTasksRefresh(true));
					}
					let tabInfo = getObjectParam(getActiveTabInfo());
					let params = {};
					if (tabInfo.hasOwnProperty('searchFieldName') && tabInfo.hasOwnProperty('searchText')) {
						params = {
							search_field_name: getStringParam(tabInfo.searchFieldName),
							search_text: getStringParam(tabInfo.searchText)
						}
					}
					if (stateObject === constants.OPPORTUNITIES_OBJECT && callFrom === constants.KANBAN) {
						appContainer.customDrawerProps.data.callBack(stageName);


						let tabs = tab.tabs;
						let tempTabs = tabs.filter(t => {
							return t.type === TYPE_LIST_VIEW &&
								t.info.hasOwnProperty('isForecast') && t.info.isForecast
						});

						if (tempTabs !== null && tempTabs.length > 0) {
							let inputParam = getObjectParam(tempTabs[0].info.input_param);

							if (inputParam.duration === tabInfo.duration) {
								params.query_id = 556;
								params.query_type = 'all';
								params.query_name = 'All Opportunities';
								params.click_form = 'Forecast';
								params.workflow_id = getIntParam(tabInfo.workflowId);
								params.sales_rep = getStringParam(tabInfo.salesRep);
								params.duration = getIntParam(tabInfo.duration);
							}
						}
					} else if (callFrom === constants.COLUMNVIEW_DRAGDROP && (stateObject === constants.ACCOUNTS_OBJECT || stateObject === constants.OPPORTUNITIES_OBJECT || stateObject === constants.CONTACTS_OBJECT)) {
						appContainer.customDrawerProps.data.callBack(stageName);
					} else if ((stateObject === constants.ACCOUNTS_OBJECT || stateObject === constants.OPPORTUNITIES_OBJECT
						|| stateObject === constants.CASES_OBJECT || stateObject === constants.PROJECTS_OBJECT
						|| stateObject === constants.ISSUES_OBJECT || stateObject === constants.CUSTOM_TABLE1_OBJECT
						|| stateObject === constants.CUSTOM_TABLE2_OBJECT || stateObject === constants.CUSTOM_TABLE3_OBJECT
						|| stateObject === constants.CUSTOM_TABLE4_OBJECT || stateObject === constants.CONTACTS_OBJECT) && callFrom === constants.COLUMN_VIEW) {
						appContainer.customDrawerProps.data.callBack(stageName);
					} else {
						if (isRefresh) {
							let listViewObject = data.hasOwnProperty("listViewObject") ? data.listViewObject : "";
							if (listViewObject === constants.FIND_DUPLICATE_OBJECT) {
								refreshListView(data.listViewObject, params,null,suffix);

							} else {

							}
						}
					}

					if ((stateObject === constants.ACCOUNTS_OBJECT || stateObject === constants.CONTACTS_OBJECT) && appointmentSub !== '') {

						let data = {
							id: 0,
							object: constants.APPOINTMENTS,

						}
						if (detailView.isDetailView === true) {
							data['parent_object'] = object;
							data['parent_record_id'] = detailViewRecord.id;
							data['isDetailView'] = detailView.isDetailView;
							data['appointmentSub'] = appointmentSub;
							data['hasWorkflowAppointment'] = hasWorkflowAppointment;
							data['callType'] = 'TYPE_DETAIL_VIEW';

						} else {
							data['parent_object'] = object;
							data['parent_record_id'] = selectedRecords[0].id;
							data['isDetailView'] = true;
							data['appointmentSub'] = appointmentSub;
							data['hasWorkflowAppointment'] = hasWorkflowAppointment;
							data['callType'] = 'TYPE_LIST_VIEW';
						}
						let add = getLocalizedStrings().label.COMMON.ADD_NEW_APPOINTMENT;
						let labelName = add + ' ' + getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENT;
						dispatch(getAppDrawer(true, labelName, constants.NEW, data, constants.OTHER));

					}

					dispatch(removeRequiredFieldIds({ [stateObject]: recordIds }));
				} else if (response.status === -1) {
					dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION.CONVERT_UNSUCCESSFULL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
				}
				if (stateObject === constants.CONTACTS_OBJECT || stateObject === constants.ACCOUNTS_OBJECT || stateObject === constants.OPPORTUNITIES_OBJECT || stateObject === constants.CASES_OBJECT || stateObject === constants.CUSTOM_TABLE1_OBJECT
					|| stateObject === constants.CUSTOM_TABLE2_OBJECT || stateObject === constants.CUSTOM_TABLE3_OBJECT || stateObject === constants.CUSTOM_TABLE4_OBJECT) {
					dispatch(detailViewIsMounted(false));
					dispatch(isDetailViewSet(false));
					dispatch(refreshDetailViewData(true));
					dispatch(refreshDetailViewListViewData(true));
					dispatch(getTasksRefresh(true));
				}
			});

		}

	}

	const openOpportunityExits = () => {
		let params = null;
		if (recordId !== null && recordId > 0) {
			params = {
				id: recordId
			}
		}
		const response = isOpenOpportunityExits(params);
		response.then((response) => {
			let isOpenOpportunityExist = response.isOpenOpportunityExist;
			if (!isOpenOpportunityExist) {
				setIsShowOppConvertButton(true);
			}
		});
	}

	const getStageById = (id) => {
		let stage = '';
		let stages = [];
		if (isApplyWorkflow) {
			stages = getArrayParam(applyWorkflowStages);
		} else {
			stages = getArrayParam(stages);
		}

		stages = stages.filter(f => {
			return f.id === id
		});
		stage = stages.length > 0 ? getStringParam(stages[0].name) : '';

		if (isApplyWorkflow) {
			stage = getLocalizedStrings().label.COMMON.CONVERTING + ' ' + getLocalizedStrings().label.COMMON.TO + ': ' + stage;
		} else {
			stage = getLocalizedStrings().label.COMMON.CONVERTING + ' ' + currentStage + ' ' + getLocalizedStrings().label.COMMON.TO + ': ' + stage;
		}

		return stage;
	}

	const getPendingTask = () => {

		let tempPendingTaskArr = [];
		let cancelEmailSMSLjist = getArrayParam(cancelEmailSMSList);
		let taskArray = getArrayParam(pendingTasks);
		if (getArrayParam(unitOwnerPendingTasks).length > 0) {
			taskArray = taskArray.concat(unitOwnerPendingTasks);
		}
		try {
			if (getBooleanParam(showRequiredFormFields.showRequiredFormFields)) {
				tempPendingTaskArr = pendingTaskArr;
			} else {
				let taskArr = document.getElementsByName('tasks_cb');
				for (let i = 0; i < taskArr.length; i++) {
					if (taskArr[i].checked) {
						let tempObj = taskArray.find(f => f.isSelected === taskArr[i].value);
						if (tempObj) {
							if (tempObj.type === 'tasks') {
								tempPendingTaskArr.push(taskArr[i].value);
							} else {
								tempObj && cancelEmailSMSLjist.push(tempObj);
							}
						}
					}
				}
			}
		} catch (error) {
			console.error("error in 'convert.js'-->'getPendingTask()'" + error);
		}
		return tempPendingTaskArr;
	}

	const handleRequiredFields = () => {

		try {
			let isConvert = true;
			let recordId = 0;
			if (callFrom === 'Detailview') {
				recordId = detailView.data.record.id;
			} else if (callFrom === "Listview") {
				recordId = selectedRecords[0].id;
			} else if (callFrom === "columnview") {
				recordId = selectedRecords[0].id;
			} else if (callFrom === constants.COLUMNVIEW_DRAGDROP) {
				recordId = data.id;
			}
			if ((changedWorkflow === 'Onboarding' ||  changedWorkflow === 'Franchisee')) {
				if (app.me.hasOwnProperty('franchisor_add_unit') && app.me.franchisor_add_unit) {
					if (!isFranchiseRecord) {
						if (!isFAExpirationDate) {
							let msg = "The 'FA Expiration Date' is not valid. Please update it with a valid date and then convert this record.";
							isConvert = false;
							sfDialogs.alert(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, null);
						} else {
							let msg = "We couldn't find a BrandWide account for this record. Converting to the '" + changedWorkflow + "' workflow requires a BrandWide account. Click OK to set up the account now. Click CANCEL to cancel converting the record.";
							isConvert = false;
							sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => getAccountRecord(recordId), null);
						}
					} else if (isFranchiseRecord && changedWorkflow === 'Onboarding' && currentWorkflow === 'Franchisee') {
						isConvert = false;
						let msg = "You cannot move the record from Franchisee to Onboarding WF";
						sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
					}
				} else {
					isConvert = false;
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
				}

			}
			if (isConvert) {
				let temp = showRequiredFormFields;
				temp.showRequiredFormFields = true
				setShowRequiredFormFields({ ...temp });
				getPendingTask()
			}
		} catch (error) {
			console.error("error in 'convert.js'-->'handleRequiredFields()'" + error);
		}
	}

	const fnSetNoteText = (event) => {
		try {
			setNoteText(getStringParam(event.currentTarget.value));
			convert.convertProps.convert.noteText(getStringParam(event.currentTarget.value));
		} catch (error) {
			console.error("error in 'convert.js'-->'fnSetNoteText()'" + error);
		}

	}

	const addToThisList = () => {
		try {
			let value = selectedStatus;
			value = getStringParam(value).trim();
			if (value !== '') {
				let listValues = getArrayParam(applyWorkflowStages);
				let tempValues = listValues.filter(f => {
					return f.name.toLowerCase() === value.toLowerCase()
				});
				saveNewListValue(value, tempValues.length > 0);
			} else {
				setIsAddToThisList(false);
				setSelectedStatus('');
			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> addToThisList()':" + error);
		}
	}

	const saveNewListValue = (value, isExist) => {
		try {
			if (getBooleanParam(isExist)) {
				dispatch(showCustomSnackBar('Value already exists in List', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
			} else {
				let field = sfForm.data.contacts.fields.filter(f => f.name === 't_status');
				if (field.length > 0) {
					let values = getArrayParam(applyWorkflowStages);
					let listValues = [];
					values.forEach(field => {
						if (field.id !== '') {
							listValues.push(field.name);
						}
					});
					listValues.push(value);
					let promise = SFFormActions.saveListValues(object, field[0].id, listValues);
					if (isValidParam(promise)) {
						promise.then(response => {
							setIsAddToThisList(false);
							setSelectedStatus(value);
							field[0].list_values.push({ id: value, value: value });
							values.push({ id: value, name: value });
						});
					}
				}

			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> saveNewListValue()':" + error);
		}
	}

	const generateHtmlForWorkFlow = (wfList) => {
		let htmlEl = null;
		if (wfList.length > 0) {
			
			htmlEl = <FormControl style={{ width: '16.2rem' }} variant="outlined">
				<InputLabel id="sf-conert-from-convert-simple-select-outlined-label" className="sf-conert-from-convert">{getLocalizedStrings().label.COMMON.CURRENT_WORKFLOW + ":"}</InputLabel>
				<Select
					labelId='sf-label-workflowlist'
					id='sf-workflowlist'
					value={currentWorkflowId}
					onChange={changeWorkflow}
					name='workflowlist'
					className={"sf-fields-bg"}
					style={{ height: '42px' }}
					label={getLocalizedStrings().label.COMMON.CURRENT_WORKFLOW + ":"}
				>
					{wfList}
				</Select>
			</FormControl>;
		}
		return htmlEl;
	}


	const generateHtmlForStatusList = (statusList) => {
		let htmlEl = null;
		if (statusList.length > 0) {
			htmlEl = selectedStatus !== null && <Select
				labelId='sf-label-statusList'
				id='sf-statusList'
				name='statusList'
				value={selectedStatus}
				onChange={changeStatus}
				className={"sf-fields-bg"}
				style={{ height: '42px' }}
				title={getStageById(selectedStatus)}
				label={isApplyWorkflow || isWorkFlowObject ?
					<label style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{getLocalizedStrings().label.COMMON.CONVERTING} {getLocalizedStrings().label.COMMON.TO}:</label>
					: <label style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{getLocalizedStrings().label.COMMON.CONVERTING} {currentStage} {getLocalizedStrings().label.COMMON.TO}:</label>}

			>
				{statusList}
			</Select>;
		} else {
			htmlEl = selectedStatus && selectedStatus !== '' && <Select
				labelId='sf-label-statusList'
				id='sf-statusList'
				name='statusList'
				value={selectedStatus}
				onChange={changeStatus}
				className={"sf-fields-bg"}
				style={{ height: '42px' }}
				title={getStageById(selectedStatus)}
				label={isApplyWorkflow || isWorkFlowObject ?
					<label style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{getLocalizedStrings().label.COMMON.CONVERTING} {getLocalizedStrings().label.COMMON.TO}:</label>
					: <label style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{getLocalizedStrings().label.COMMON.CONVERTING} {currentStage} {getLocalizedStrings().label.COMMON.TO}:</label>}

			>
				<MenuItem value='-1' style={styles.popoverMenuItem}> {''}</MenuItem>
			</Select>;
		}
		return htmlEl;
	}
	const generateHtmlForChildStatusList = (childStatusList, childLabel) => {
		let htmlEl = null;
		if (childStatusList.length > 0) {
			htmlEl = <Select
				labelId='sf-label-relatedTableStatusList'
				id='sf-relatedTableStatusList'
				name='relatedTableStatusList'
				className={"sf-fields-bg"}
				value={childSelectedStatus}
				label={childLabel}
				fullWidth={true}
				style={{ height: '42px' }}
			>
				<MenuItem value='-1' style={styles.popoverMenuItem} onClick={(e) => changeChildStatus('-1', e)}> {'<None>'}</MenuItem>
				{childStatusList}
			</Select>;
		}
		return htmlEl;
	}

	if (mounted) {
		let stageName = '';
		let statusArray = (isApplyWorkflow || isWorkFlowObject) ? applyWorkflowStages : stages;
		let statusList = populateStatusList();
		let pendingTasksElement = populatePendingTasks();

		let childStatusList = populateChildStatusList();
		let unitOwnerPendingTasksElement = populateUnitOwnerPendingTasks();
		let wfList = [];
		let childLabel = getLocalizedStrings().label.COMMON.UPDATE + " " + childObject + " " + getLocalizedStrings().label.COMMON.STATUS_TO_OPTIONAL;
		map(statusArray, (stage, index) => {
			if (stage.id === selectedStatus) {
				stageName = stage.name;
			}
		});
		if (isApplyWorkflow || isWorkFlowObject) {
			wfList = getWFList();
		}

		let tasksArr = getArrayParam(pendingTasks).filter(f => f.type === constants.TASKS);
		let creatNextTaskLabel = getLocalizedStrings().label.APPOINTMENTS_TASKS.CREATE_NEXT_TASKS;
		let completTaskLabel = getLocalizedStrings().label.APPOINTMENTS_TASKS.COMPLETE_TASK_OPTIONAL;
		let completUnitTaskLabel = getLocalizedStrings().label.APPOINTMENTS_TASKS.COMPLETE_UNIT_TASK_OPTIONAL;
		let dataTipLabel = getLocalizedStrings().label.DETAIL_VIEW.DATA_TIP_1 + stageName + getLocalizedStrings().label.DETAIL_VIEW.DATA_TIP_3;
		if (object === constants.ACCOUNTS_OBJECT) {
			dataTipLabel = getLocalizedStrings().label.DETAIL_VIEW.DATA_TIP_2 + stageName + getLocalizedStrings().label.DETAIL_VIEW.DATA_TIP_3;
		}
		const workflowArr = isValidParam(workflowList) ? workflowList.length : 0;
		let hasRequiredFields = false;
		if (getArrayParam(requiredFields).length > 0 && !getBooleanParam(showRequiredFormFields.showRequiredFormFields)) {
			let hasPermission = hasAccessPermission(object, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_CREATE);
			if (hasPermission) {
				hasRequiredFields = true;
			}
		}
		let parentDetails = {};
		let objectLabel = object;
		let objectList = getArrayParam(app.objectList);
		objectList = objectList.filter(f => f.name === object);
		if (objectList.length > 0) {
			objectLabel = getStringParam(objectList[0].label).toLowerCase();
		}

		let showNextTaskChkBox = true;
		if (object === constants.CONTACTS_OBJECT && currentWorkflowId === 0) {
			showNextTaskChkBox = false;
		}
		// check this code update
		let tempIsApplyWorkflow = isApplyWorkflow;
		if (convert.changedWorkflow === "Greenlight" && showRequiredFormFields.showRequiredFormFields === false) {
			if(getArrayParam(requiredFields).length > 0){
				hasRequiredFields = true;
			}
			tempIsApplyWorkflow = true;
		}
	
		return (
			<div style={{ height: '100%', marginTop: '30px' }}>
				{getBooleanParam(showRequiredFormFields.showRequiredFormFields) ?
					(
						<div>
							<div style={{ padding: '10px 25px 0px 25px' }}>These fields need to be filled in before this {objectLabel} can be converted</div>
							<div style={{ padding: '10px 0px' }}>
								<RequiredForms
									ref={requiredForms}
									object={object}
									openMode={constants.SF_FORM_OPEN_MODE_QUICK}
									recordId={recordId}
									requiredFields={requiredFields}
									callFrom={constants.CONVERT}
									isDetailView={true}
									parentDetails={parentDetails}
									onFieldConvert={onFieldConvert}
								/>
							</div>
						</div>
					) :
					(
						<div style={{ paddingLeft: '25px', paddingRight: '25px', marginTop: '10px' }}>
							{(workflowArr > 1 || (object !== constants.CONTACTS_OBJECT && workflowArr > 0) || (object === constants.CONTACTS_OBJECT && workflowArr > 0) || (data.hasOwnProperty('columnViewDragDropForAll') && data.columnViewDragDropForAll === true && tempIsApplyWorkflow && workflowArr > 0)) ? (tempIsApplyWorkflow || isWorkFlowObject) ?
								(
									<div>
										{generateHtmlForWorkFlow(wfList)}
									</div>
								)
								: (
									<div style={{ color: '#717171', display: 'inline-block', }}>
										<span style={{ marginLeft: "0px", display: 'inline-block', overflow: 'hidden', color: '#717171' }}> {getLocalizedStrings().label.COMMON.CURRENT_WORKFLOW}: {currentWorkflow}
											<Icon title={getLocalizedStrings().label.COMMON.CHANGE_WORKFLOW} style={{ fontSize: '13px', paddingLeft: '10px', display: 'inline-block', cursor: 'pointer' }} onClick={showWorkflowList}>edit</Icon>
										</span>
									</div>) : <div />
							}
							<div className="row clearfix" style={{ marginTop: '8px', marginBottom: getArrayParam(tasksArr).length == 0 ? '17px' : '' }}>
								{object === constants.CONTACTS_OBJECT && isAddToThisList ?
									<div className="col-sm-6">
										<FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
											<TextField
												variant="outlined"
												key='statusList'
												inputStyle={styles.textField.input}
												label='Status'
												fullWidth={true}
												name='statusList'
												value={selectedStatus}
												onChange={(e) => addNewStatus(e)}
												onBlur={addToThisList}
												className={"sf-fields-bg"}
												maxLength='100'
												autoFocus={true}
												autoComplete="new-password"
												margin='dense'
												size='small'
											/>
										</FormControl>
									</div>
									: <div className="col-sm-6">
										<FormControl style={{ width: '260px', marginLeft: '-14px', marginTop: '10px' }} variant="outlined">
											<InputLabel id="sf-convert-fromconvert-simple-select-outlined-label" className="sf-convert-fromconvertsimple">
												{tempIsApplyWorkflow || isWorkFlowObject ?
													<label style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{getLocalizedStrings().label.COMMON.CONVERTING} {getLocalizedStrings().label.COMMON.TO}:</label>
													: <label style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{getLocalizedStrings().label.COMMON.CONVERTING} {currentStage} {getLocalizedStrings().label.COMMON.TO}:</label>
												}
											</InputLabel>
											{generateHtmlForStatusList(statusList)}
										</FormControl>
									</div>
								}
								{showNextTaskChkBox &&
									<div className="col-sm-6" style={{ display: 'flex' }}>
										{<div style={{ marginTop: '10px', height: '30px', marginLeft: '15px', maxWidth: '90%' }}>
											<FormControlLabel
												className="sf-convert-test"
												control={<Checkbox
													color="default"
													id="t_cb"
													value={createTask}
													onChange={handleChangeCreateTask}
													defaultChecked={createTask}
												/>}
												label={creatNextTaskLabel} labelposition="right"
												labelstyle={{ ...styles.text, maxWidth: '90%', lineHeight: '24px' }}
												style={{ ...styles.checkbox, fontSize: "8px", width: '100%' }}
												title={creatNextTaskLabel} />

										</div>}
										<div style={{ marginTop: '19px' }}>
											<SFToolTip
												callFrom={"convert"}
												placement="right-end"
												title={dataTipLabel}

											/>
										</div>

									</div>
								}
							</div>
							{pendingTasksElement !== "" &&
								<div>
									<List>
										{getArrayParam(tasksArr).length > 0 && <ListSubheader style={{ color: '#717171', paddingLeft: '2px', lineHeight: 'normal', paddingBottom: '15px', marginTop: '6px', fontSize: '14px', position: 'relative', background: 'none' }}>{completTaskLabel} </ListSubheader>}
										<div>{pendingTasksElement}</div>
									</List>
								</div>}
							{unitOwnerPendingTasksElement !== "" &&
								<div>
									<List innerDivStyle={{}}>
										{getArrayParam(unitOwnerPendingTasks).length > 0 && <ListSubheader style={{ color: '#717171', paddingLeft: '2px', lineHeight: 'normal', paddingBottom: '15px', position: 'relative', fontSize: '14px', backgroundColor: 'whitesmoke' }}>{completUnitTaskLabel} </ListSubheader>}
										<div> {unitOwnerPendingTasksElement}</div>
									</List>
								</div>}
							<div>
								<div>
									<FormControl style={{ width: '100%', marginBottom: !isShowChildStatusList ? '20px' : '0px' }} className="test" noValidate autoComplete="off">
										<TextField
											variant="outlined"
											id='Note_Text'
											type='textarea'
											multiline={true}
											minRows={3}
											fullWidth={true}
											textareastyle={{ marginTop: '10px', height: '90%' }}
											label={getLocalizedStrings().label.APPOINTMENTS_TASKS.ADD_NOTE}
											onChange={fnSetNoteText}
											value={noteText}
											autoComplete="new-password"
											className={"sf-fields-bg"}
										/>
									</FormControl>
								</div>
							</div>
							{
								((stateObject === constants.ACCOUNTS_OBJECT) || (stateObject === constants.OPPORTUNITIES_OBJECT)) &&
								(isShowChildStatusList) &&
								<div>
									<div style={{ paddingBottom: '20px' }}>
										<FormControl style={{ width: '100%', marginTop: '17px' }} variant="outlined">
											<InputLabel id="sf-convert-result-from-convert-simple-select-outlined-label" className="sf-convert-result-from-converts">{childLabel}</InputLabel>
											{generateHtmlForChildStatusList(childStatusList, childLabel)}
										</FormControl>
									</div>
								</div>
							}
						</div>
					)
				}
				<div style={{
					textAlign: 'right', whiteSpace: 'nowrap', height: '28px', marginBottom: '12%', paddingRight: getBooleanParam(showRequiredFormFields.showRequiredFormFields) ? '30px' : '20px', marginTop:
						stateObject === constants.CASES_OBJECT || stateObject === constants.PROJECTS_OBJECT
							|| stateObject === constants.ISSUES_OBJECT || stateObject === constants.CUSTOM_TABLE1_OBJECT
							|| stateObject === constants.CUSTOM_TABLE2_OBJECT || stateObject === constants.CUSTOM_TABLE3_OBJECT
							|| stateObject === constants.CUSTOM_TABLE4_OBJECT || stateObject === constants.CONTACTS_OBJECT ? '20px' : ''
				}}>
					{!hasRequiredFields ? <span>
						{(isShowOppConvertButton && stageName === constants.OPPORTUNITY_STAGE && !tempIsApplyWorkflow) &&
							<Button
								onClick={() => doConvert('oppConvert')}
								labelstyle={{ paddingLeft: '5px', paddingRight: '5px', display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}
								style={{ ...styles.primaryButton, maxWidth: '232px', verticalAlign: 'middle' }}
								title={getLocalizedStrings().label.COMMON.CONVERT_AND_ADD_OPP}
							>{getLocalizedStrings().label.COMMON.CONVERT_AND_ADD_OPP}</Button>}

						{(stageName === constants.OPPORTUNITY_STAGE && !tempIsApplyWorkflow) &&
							<Button
								onClick={doConvert}
								style={{ ...!isShowOppConvertButton ? styles.primaryButton : styles.secondaryButton }}
								title={getLocalizedStrings().label.COMMON.CONVERT}
							>{getLocalizedStrings().label.COMMON.CONVERT}</Button>}


						{(stageName !== constants.OPPORTUNITY_STAGE || tempIsApplyWorkflow) && !hasRequiredFields &&
							<Button
								onClick={doConvert}
								disabled={isSaved}
								style={styles.primaryButton}
								title={getLocalizedStrings().label.COMMON.CONVERT}
							> {getLocalizedStrings().label.COMMON.CONVERT}</Button>}
					</span> :
						<Button
							onClick={() => handleRequiredFields()}
							style={styles.primaryButton}
							title={getLocalizedStrings().label.COMMON.NEXT}
							// disabled = {(changedWorkflow == "Franchisee" || changedWorkflow === 'Onboarding' ) && stageIndex == applyWorkflowStages.length -1 && true  }
						> {getLocalizedStrings().label.COMMON.NEXT}</Button>
					}
					<Button
						onClick={(e) => closeDrawer(e)}
						style={styles.secondaryButton}
						title={getLocalizedStrings().label.COMMON.CANCEL}
					> {getLocalizedStrings().label.COMMON.CANCEL}</Button>
				</div>
			</div>
		);
	} else {
		let contentHeight = window.innerHeight - 240;
		let top = (contentHeight - 10) / 2
		return (
			<div style={{ width: '100%', height: contentHeight }}>
				<div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
					<div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
						<ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
					</div>
				</div>
			</div>
		);
	}
}

export default Convert;