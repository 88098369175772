import React, { useState, useEffect } from 'react';
import { styles } from '../../../services/constants/styles';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import SFDataTable from './sfDataTable';
import { useDispatch, useSelector } from "react-redux";
import ShowCircularProgress from './circularProgress';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import moment from 'moment';
import { setHeader } from '../../../services/actions/headerActions.js';
import { constants } from '../../../services/constants/constants.js';

const CorrectiveActionReportDetails = ({object,closeDrawer,data}) =>{
    const dispatch = useDispatch();
    const [rows, setRows] = useState([]);
    const [columns,setColumns] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const AuditTask = useSelector((state)=> state.auditDetails.AuditTask);

    useEffect(()=>{
        dispatch(setHeader(constants.CORRECTIVE_ACTIONS_REPORT_DETAILS, getLocalizedStrings().label.TASK_REPORT.CORRECTIVE_ACTION_REPORT_DETAILS, null, false, true));
        getReportData();
    },[])

    const getReportData = () =>{
        setIsLoading(true)
        let {units,report_type,period,from_date,to_date} =  AuditTask?.selectedParams;
        let params = {};
        let dataLable = data?.column?.label;
        if(dataLable === "Remaining"){
            params.parent = 'Pending';
        }else{
            params.parent = dataLable;
        }
        if(data.row.id){
            params.unitid = [data.row.id];
        }
        params.type = report_type;
        if(period){
            if(period === 'cust'){
                if(from_date){
                    let fromDate = moment(from_date)
                    fromDate =  fromDate.format('YYYY-MM-DD');
                    params.from_date = fromDate;
                }
                if(to_date){
                    let toDate = moment(to_date);
                    toDate = toDate.format('YYYY-MM-DD');
                    params.to_date = toDate;
                }
            }
            params.period = period;
        }
        const promise = Promise.resolve(HTTPClient.get(endPoints.AUDIT_TASK_REPORT.GET_AUDIT_TASK, params));
        if (promise) {
            promise.then(res => { 
                let response = res;
                setRows(response.records);
                let _columns = response.fields;
                setColumns(_columns);
                setIsLoading(false)
            })
        }
    }

    const getReportView = () =>{
        let _rows = rows;
        let _columns = columns;
        let view = [];
        if (!_rows || Object.keys(_rows).length === 0) {
            return getNoRecordContent();
        }else{
            view.push(<div style={{height:'fit-content'}}>
                    <SFDataTable columns={_columns}  rows={_rows} />
                </div>)
            return view;
        }
    }

    function getNoRecordContent() {
        return (
            <div className="row" style={{ padding: '0px', display: "flex",marginLeft:'10px'}}>
                <h5 style={{ color: 'red' }}>{getLocalizedStrings().label.TASK_REPORT.NO_TASK_FOUND}</h5>
            </div>
        )
    }

    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2
    return(
        <div>
            <div style={{ marginTop:'15px',marginLeft:'1.3rem'}}>
                {!isLoading ? getReportView() : (
                    <div style={{ width: '100%', height: contentHeight }}>
                        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                            </div>
                        </div>
                    </div>
                )
                }
            </div>
        </div>
    )
}

export default CorrectiveActionReportDetails;