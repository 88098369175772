import { actions } from '..//constants/actions';

function setData(obj) {
    return function (dispatch) {
        dispatch({
            type: actions.SET_DATA,
            payload: {
                obj: obj
            }
        })
    }
}

export {
    setData
}
