import { actions } from '..//constants/actions'

const fieldsInitialState = {
    mounted: false,
    data: null,
    actions: null
}

const fieldsReducer = (state = fieldsInitialState, action) => {
    switch (action.type) {
        case actions.FIELDS_GET_FULLFILLED:
            return {
                ...state,
                mounted : true,
                data: action.payload
            }
        default:
            return state;
    }
}

export default fieldsReducer;