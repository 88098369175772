import { actions } from '..//constants/actions';

function updateComplianceReportData(resultSet) {
    return {
        type: actions.COMPLIANCE_REPORT_DATA_UPDATE,
        payload: resultSet
    }
}

export {
    updateComplianceReportData,
}