import React from 'react';
import $ from 'jquery';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { TextField, Select, FormControl, MenuItem, Button, InputLabel } from '@mui/material';
import FroalaEditor from "react-froala-wysiwyg";
import Froalaeditor from 'froala-editor';
import { isValidParam, getStringParam, getIntParam, getBooleanParam, getArrayParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { getUserDetailsPromise, saveUserDetailsPromise ,setUserListData} from '../../../services/actions/UserListAction';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { setUserContextData } from '../../../services/actions/appActions';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { updateUserLanguage } from '../../../services/actions/appActions';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { setHeader } from '../../../services/actions/headerActions';
import * as sfDialogs from '../components/sfDialogs';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TwitterPicker } from 'react-color';

require("froala-editor/js/froala_editor.pkgd.min.js");
require("froala-editor/css/froala_editor.pkgd.min.css");
require("froala-editor/js/plugins/font_family.min.js");

const myColors = ['#FF6900', '#00D084', '#0693E3', '#EB144C', '#596ac6']

const ProfileDetails = (object) => {
    const dispatch = useDispatch();
    const [mounted, setMounted] = useState(true);
    const [id, setId] = useState(null);
    const [tId, setTid] = useState(null);
    const [name, setName] = useState(null);
    const [emailId, setEmailId] = useState(null);
    const [currentPassword, setCurrentPassword] = useState(null);
    const [currentSLevel, setCurrentSLevel] = useState(null);
    const [isManager, setIsManager] = useState(null);
    const [managerName, setManagerName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [selectedTimeZone, setSelectedTimeZone] = useState(null);
    const [selectedDateFormat, setSelectedDateFormat] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [selectedTheme, setSelectedTheme] = useState(null);
    const [allTimeZones, setAllTimeZones] = useState({});
    const [allDateFormats, setAllDateFormats] = useState({});
    const [allThemes, setAllThemes] = useState({});
    const [allLanguages, setAllLanguages] = useState({});
    const [menuItemTimeZones, setMenuItemTimeZones] = useState([]);
    const [menuItemDateFormats, setMenuItemDateFormats] = useState([]);
    const [menuItemThemes, setMenuItemThemes] = useState([]);
    const [menuItemLanguages, setMenuItemLanguages] = useState([]);
    const [emailSignature, setEmailSignature] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [verifiedEmailIds, setVerifiedEmailIds] = useState(null);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [menuItemEmailIds, setMenuItemEmailIds] = useState([]);
    const [isPowerCalling, setIsPowerCalling] = useState(0);
    const [pickerOpen, setPickerOpen] = useState(false);
    const [isFullScreenEditor, setIsFullScreenEditor] = useState(false);

    const [colorCode, setColorCode] = useState('#FF6900');
    const [profileImgUpdateLog, setProfileImgUpdateLog] = useState({np:null,op:null,tou:new Date()});
    const appState = useSelector((state) => state.app);
    const userList = useSelector((state) => state.userlist);
    const calendar = useSelector(state => state.calendar.calendarInfo)


    useEffect(() => {
        dispatch(setHeader(object, getLocalizedStrings().label.PROFILE_DETAILS.PROFILE_DETAILS_LABEL, null, false, true));
        getUserDetails();
    }, []);
    
    useEffect(() => {
        let _profileImage = appState.me.profile_image;
        setProfileImage(_profileImage);
    }, [appState.me.profile_image]);


    useEffect(() => {
        populateTimezones();
        populateDateFormats();
        populateLanguage();
        populateEmailIds();
    }, [allTimeZones])

    const getUserDetails = () => {
        let tempId = null;
        let td = null;
        let profileName = null;
        let email = null;
        let currentPass = null;
        let currentLevel = null;
        let ismanager = null;
        let managername = null;
        let currentphone = null;
        let currentTimeZone = null;
        let currentDateFormat = null;
        let language = null;
        let currentTheme = null;
        let recordid = getIntParam(appState.me.id);
        let tempAllTimeZones = null;
        let tempAllDateFormats = null;
        let tempAllThemes = null;
        let tempAllLanguages = null;
        let emailsignature = null;
        let verifiedEmailids = null;
        let _selectedemail = null;
        let powerCalling = null;
        let userColorCode = null;

        if (recordid > 0) {
            let promise = getUserDetailsPromise(recordid);
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        let userInfo = isValidParam(response.user) ? response.user : null;
                        if (isValidParam(userInfo)) {
                            tempId = getIntParam(userInfo.t_pk);
                            td = getIntParam(userInfo.t_id);
                            profileName = getStringParam(userInfo.t_name);
                            email = getStringParam(userInfo.t_email);
                            currentPass = getStringParam(userInfo.t_enc_pwd);
                            currentLevel = getStringParam(userInfo.Security_level);
                            ismanager = getStringParam(userInfo.manager);
                            managername = getStringParam(userInfo.manager_name);
                            currentphone = getStringParam(userInfo.t_phone);
                            currentTimeZone = getStringParam(userInfo.t_timezone);
                            currentDateFormat = getStringParam(userInfo.t_date_format);
                            language = getStringParam(userInfo.t_language);
                            currentTheme = getStringParam(userInfo.theme);
                            powerCalling = getIntParam(userInfo.t_ispowercalling);
                            tempAllTimeZones = response['allTimeZones'];
                            tempAllDateFormats = response['allDateFormat'];
                            tempAllThemes = response['allTheme'];
                            tempAllLanguages = response['allLanguages'];
                            emailsignature = response['signature'].t_signature;
                            verifiedEmailids = getArrayParam(response['verifiedemailids']);
                            userColorCode = getStringParam(userInfo.t_colorcode);

                            _selectedemail = getStringParam(response['signature'].t_signature_email_id).length > 0 ? getStringParam(response['signature'].t_signature_email_id) : getStringParam(userInfo.t_email);
                            setId(tempId);
                            setTid(td);
                            setName(profileName);
                            setEmailId(email);
                            setCurrentPassword(currentPass)
                            setCurrentSLevel(currentLevel);
                            setIsManager(ismanager);
                            setManagerName(managername);
                            setPhone(currentphone);
                            setSelectedTimeZone(currentTimeZone);
                            setSelectedDateFormat(currentDateFormat);
                            setSelectedLanguage(language);
                            setSelectedTheme(currentTheme);
                            setAllTimeZones(tempAllTimeZones);
                            setAllDateFormats(tempAllDateFormats);
                            setAllThemes(tempAllThemes);
                            setAllLanguages(tempAllLanguages);
                            setEmailSignature(emailsignature);
                            setVerifiedEmailIds(verifiedEmailids);
                            setSelectedEmail(_selectedemail);
                            setIsPowerCalling(powerCalling);
                            setColorCode(userColorCode);

                        }
                    }
                });
            }
        }

    }

    const populateTimezones = () => {
        let _menuItemTimeZones = [];
        let timezones = null;
        timezones = allTimeZones;
        if (isValidParam(timezones)) {
            for (let key in timezones) {
                _menuItemTimeZones.push(<MenuItem key={key} id={key} value={key}  >{timezones[key]}</MenuItem>);
            }
            setMenuItemTimeZones(_menuItemTimeZones);
        }
    }
    const populateDateFormats = () => {
        let _menuItemDateFormats = [];
        let dateFormats = null;
        dateFormats = allDateFormats;
        if (isValidParam(dateFormats)) {
            for (let key in dateFormats) {
                _menuItemDateFormats.push(<MenuItem key={key} id={key} value={key} >{dateFormats[key]}</MenuItem>);
            }
            setMenuItemDateFormats(_menuItemDateFormats);
        }
    }
    const populateLanguage = () => {
        let _menuItemLanguage = [];
        let language = null;
        language = allLanguages;
        if (isValidParam(language)) {
            for (let key in language) {
                _menuItemLanguage.push(<MenuItem key={key} id={key} value={key}> {language[key]} </MenuItem>);
            }
            setMenuItemLanguages(_menuItemLanguage);
        }
    }
    const handleChangePhone = (event) => {
        let target = event.target;
        let value = target.value;
        setPhone(value);

    }
    const handleChangeNewPswd = (event) => {
        let target = event.target;
        let value = target.value;
        setNewPassword(value);
    }

    const handleChangeCnfPswd = (event) => {
        let target = event.target;
        let value = target.value;
        setConfirmPassword(value);
    }
    // problems are here to display
    const setSelectedTimeZoneValue = (event) => {
        let value = event.target.value;
        setSelectedTimeZone(value);
    }
    const setSelectedDateFormatValue = (event) => {
        let value = event.target.value;
        setSelectedDateFormat(value);
    }

    const setSelectedLanguageValue = (event) => {
        let value = event.target.value;
        setSelectedLanguage(value);
    }
    const validateProfileDetailsForm = () => {

        let errorMessage = null;
        let isNewPasswdValid = false;
        let newPswd = getStringParam(newPassword);
        let cnfPswd = getStringParam(confirmPassword);
        if (newPswd.length === 0 && cnfPswd.length === 0) {
            isNewPasswdValid = true;
        }
        else {
            if (newPswd.length > 0 && cnfPswd.length === 0) {
                errorMessage = getLocalizedStrings().message.PROFILE_DETAILS.NO_CONFIRM_PASSWORD;
            }
            else if (newPswd.length === 0 && cnfPswd.length > 0) {
                errorMessage = getLocalizedStrings().message.PROFILE_DETAILS.NO_NEW_PASSWORD;
            }
            else if (newPswd.length > 0 && cnfPswd.length > 0) {
                if (!newPswd.replace(/\s/g, '').length || !cnfPswd.replace(/\s/g, '').length) {
                    errorMessage = getLocalizedStrings().message.PROFILE_DETAILS.NO_NEW_PASSWORD;
                }
                else if (cnfPswd !== newPswd) {
                    errorMessage = getLocalizedStrings().message.PROFILE_DETAILS.UNMATCH_NEW_CONFIRM_PASSWORD;
                }
                else {
                    isNewPasswdValid = true;
                }
            }

        }
        if (isNewPasswdValid) {
            saveProfileDetailsForm();
        }
        else {
            errorMessage = getStringParam(errorMessage);
            if (errorMessage.length > 0) {
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        }
    }
    const saveProfileDetailsForm = () => {
        let user_id = appState.me.id;

        let params = {};
        let tempTId = getStringParam(tId);
        let profileName = getStringParam(name);
        let email = getStringParam(emailId);
        let phoneNo = getStringParam(phone);
        let currentPass = getStringParam(currentPassword);
        let newPass = getStringParam(newPassword);
        let confirmPass = getStringParam(confirmPassword);
        let currentLevel = getStringParam(currentSLevel);
        let ismanager = getStringParam(isManager);
        let managername = getStringParam(managerName);
        let timezone = getStringParam(selectedTimeZone);
        let dateFormat = getStringParam(selectedDateFormat);
        let language = getStringParam(selectedLanguage);
        let theme = getStringParam(selectedTheme);
        let emailsignature = getStringParam(emailSignature);
        let powerCalling = getIntParam(isPowerCalling);
        let userColorCode = getStringParam(colorCode);

        let signatureEmailId = getStringParam(selectedEmail);

        params.t_pk = id;
        params.t_id = tempTId;
        params.t_name = profileName;
        params.t_email = email;
        params.t_phone = phoneNo;
        params.Security_level = currentLevel;
        params.manager = ismanager;
        params.manager_name = managername;
        params.t_timezone = timezone;
        params.t_date_format = dateFormat;
        params.t_language = language;
        params.theme = theme;
        params.t_signature = emailsignature;
        params.isPowerCalling = powerCalling;
        params.signature_email_id = signatureEmailId;
        params.t_colorcode = userColorCode;

        if (newPass.trim().length > 0) {
            params.t_enc_pwd = newPass;
        }
        else {
            params.t_enc_pwd = currentPass;
        }

        let promise = saveUserDetailsPromise(params);
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.status === 0 && response['data'].isProfileDetailsSaved) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.PROFILE_DETAILS.PROFILE_UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        let token = localStorage.getItem('token');
                        if (token !== null) {
                            dispatch(setUserContextData(token));
                           dispatch(updateUserLanguage(language));
                        
                        }
                        setNewPassword('');
                        setConfirmPassword('');
                        let newUserList = [];
                        
                            let userListValue = getArrayParam(userList.data);
                            userListValue.map((usr, i) => {
                                if (usr.id == user_id) {
                                    usr.colorCode = userColorCode;
                                }
                                newUserList.push(usr);
                            })
                            userList.data = newUserList;
                        
                    }
                }
            });
        }
    }

    const handleModelChange = (event) => {
        let emailsignature = event;
        setEmailSignature(emailsignature);
    }

    const uploadProfilePicture = () => {
            let obj = {
                id: id,
                object: 'user',
                setProfilePicture: (imageUrl) => setProfileCurrentPicture(imageUrl)
            };
          dispatch( getAppDialog(true, constants.PROFILE_DETAILS_LOGO_UPLOAD_DIALOG, getLocalizedStrings().message.ATTACHMENT.SELECT_IMAGE_TO_UPLOAD, null, obj, null));  //{ id: id, fieldName: fieldName, setLogoOnRecord: setLogoOnRecord, object: object }
        //dispatch(getAppDialog(true, constants.PROFILE_DETAILS_LOGO_UPLOAD_DIALOG, getLocalizedStrings().message.ATTACHMENT.SELECT_IMAGE_TO_UPLOAD, null, { id: id, object: 'user', setProfilePicture: setProfileCurrentPicture }, null));  //{ id: id, fieldName: fieldName, setLogoOnRecord: this.setLogoOnRecord, object: object }

    }


    const setProfileCurrentPicture = (imageUrl) => {
        
        let newUserList = [];
        let newAssignList = [];
        let user_id = appState.me.id;
        let calendarInfoFromRedux = getObjectParam(calendar);
        let token = localStorage.getItem('token');
        if (token !== null) {
            dispatch(setUserContextData(token));
        }
        setProfileImage(imageUrl);
        let tempPUObj = profileImgUpdateLog;
        tempPUObj.np = imageUrl;
        tempPUObj.op = profileImage;
        tempPUObj.tou = new Date();
        setProfileImgUpdateLog({...tempPUObj})
        let tempUserList = getArrayParam(userList.data);
        tempUserList.map((usr, i) => {
            if (usr.id === user_id) {
                //usr.profile_image_url = imageUrl;
                setProfileImage(usr.profile_image_url);
            }
            newUserList.push(usr);
        })
        // userList.data = newUserList;
        setUserListData(newUserList);

        

        if (calendarInfoFromRedux !== null && calendarInfoFromRedux !== '' &&
            calendarInfoFromRedux.hasOwnProperty('assign')) {
            let userArr = calendarInfoFromRedux.assign;
            userArr.map((usr, i) => {
                if (usr.value === user_id) {
                   // usr.profileImage = imageUrl;
                    setProfileImage(usr.profileImage)
                }
                newAssignList.push(usr);
            })
            calendar.assign = newAssignList;
        }
    }

    const getLibrary = (editor, type) => {
        editor.events.disableBlur();
        let data = {
            editor: editor,
            contentStyle: { width: '80%', maxWidth: 'none' },
            type: type,
            object: 'user',
            fullWidth: true,
            maxWidth: 'md',
        }
        dispatch(getAppDialog(true, constants.LIBRARY_DIALOG, getLocalizedStrings().message.COMMON.LIBRARY_DIALOG_TITLE, (event)=>handleLibraryResponse(event), data, null));
    }

    const handleLibraryResponse = (obj) => {
        obj.editor.events.enableBlur();
        if (obj.type === 'image' && obj.src !== '') {
            obj.editor.image.insert(obj.src)
        }
        dispatch(getAppDialog(false, constants.LIBRARY_DIALOG, null, null, null, null));
    }
    const populateEmailIds = () => {
        let _menuItemEmailIds = [];
        if (isValidParam(verifiedEmailIds)) {
            verifiedEmailIds.unshift(getStringParam(emailId));
            verifiedEmailIds.map((item, i) => {
                _menuItemEmailIds.push(<MenuItem key={item} id={item} value={item} > {(item === appState.me.email) ? getStringParam(item + " (default)") : item}</MenuItem>);
            });
            setMenuItemEmailIds(_menuItemEmailIds);
        }
    }
    const setSelectedEmailId = (event) => {
        let value = event.target.value;
        let signature = emailSignature;
        let params = null;
        if (getStringParam(value).length > 0) {
            params = {};
            params.signature_email_id = getStringParam(value);
            let promise = Promise.resolve(HTTPClient.get(endPoints.USERS.GET_EMAIL_SIGNATURE, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    signature = getStringParam(response.t_signature);
                }
                setSelectedEmail(getStringParam(value));
                setEmailSignature(signature);
                autoSaveEmailSignature();
            });
        }
    }
    const autoSaveEmailSignature = (e, editor) => {
        let signature = getStringParam(emailSignature);
        let tempSelectedEmail = getStringParam(selectedEmail);
        let params = {};
        params.t_signature = signature;
        params.signature_email_id = tempSelectedEmail;
        let promise = Promise.resolve(HTTPClient.post(endPoints.USERS.SAVE_EMAIL_SIGNATURE, params));
        promise.then((response) => {
            if (isValidParam(response) && getBooleanParam(response.data.is_signature_saved)) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.PROFILE_DETAILS.PROFILE_SIGNATURE_AUTOSAVED, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
            }
        });
    }
    const getSchedulerLink = (event) => {
        let slug = appState.appointment_scheduler_slug;
        if (slug === null) {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SCHEDULER.NO_SCHEDULER, null);
        } else {
            var link = constants.APPOINTMENT_SCHEDULER_URL + slug;
            event.html.insert(' <a target="_blank" href="' + link + '">Book an appointment</a> ');
        }
    }
    const getVideoInsertDialog = (editor, videoType) => {
		editor.events.disableBlur();
		let data = {
			editor: editor,
			contentStyle: { width: "80%", maxWidth: "none" },
			videoType: videoType,
		};
		let title = null;
		if (videoType === constants.VIDEO_TYPE_YOUTUBE) {
			title = getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE;
		} else if (videoType === constants.VIDEO_TYPE_WISTIA) {
			title = getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE;
		}
		dispatch(
			getAppDialog(
				true,
				constants.VIDEO_INSERT_DIALOG,
				title,
				(event)=>handleVideoInsertResponse(event),
				data,
				null
			)
		);
	};
    const handleVideoInsertResponse = (obj) => {
		obj.editor.events.focus();
		dispatch(
			getAppDialog(false, constants.VIDEO_INSERT_DIALOG, null, null, null, null)
		);
		obj.editor.html.insert(
			'<a href="' +
			obj.video_link +
			'" target="_blank" class="editor_video-selector"><img  height="220" width="380" "src="' +
			obj.thumbnail.url +
			'" title="' +
			obj.title +
			'" /></a>'
		);
		obj.editor.events.focus();
	};

    const getAuditSurveyLink = (event) => {
		event.html.insert("${audit_survey_link}");
	};
	const getSurveyLink = (event) => {
		event.html.insert("${Thumbs_Survey}");
	};




    const expandEditor = () => {
        try {
            setIsFullScreenEditor(!isFullScreenEditor);
            if (isFullScreenEditor) {
                $("[id^=expand_editor]")
                    .find($(".fa"))
                    .removeClass("fa fa-expand")
                    .addClass("fa fa-compress");
                setTimeout(function () {
                    $("[id^=expand_editor]").addClass("fr-active");
                }, 500);
            } else
                $("[id^=expand_editor]")
                    .find($(".fa"))
                    .removeClass("fa fa-compress")
                    .addClass("fa fa-expand");
        } catch (error) {
            console.error(
                "Error in 'MarketingTemplate.js -> expandEditor()':" + error
            );
        }
    };

    const handleClose = () => {
        setPickerOpen(false);
    };
    const handelColorPicker = () => {
        setPickerOpen(!pickerOpen);
    };
    const handleChangeComplete = (color) => {
        let hexCode = color.hex.replace(/#/g, "");
        setColorCode(hexCode);
    };

    let mainContentTopMargin = '0px';

    if (mounted) {
        // let getLibrary = getLibrary;

        Froalaeditor.DefineIcon('library', { NAME: 'image', template: "font_awesome" });
        Froalaeditor.RegisterCommand('library', {
            title: getLocalizedStrings().label.MARKETING_TEMPLATE.INSERT_IMAGE,
            focus: false,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                getLibrary(this, 'image')
            }
        });

        Froalaeditor.DefineIcon('link_library', { NAME: 'file-archive-o', template: "font_awesome" });
        Froalaeditor.RegisterCommand("link_library", {
            title: getLocalizedStrings().label.MARKETING_TEMPLATE.SELECT_FROM_LIBRARY,
            focus: false,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                getLibrary("link");
            },
        });
        Froalaeditor.DefineIcon("insertYouTubeVideo", {
            NAME: "fa-brands fa-youtube", template: "font_awesome"
        });

        // let getSchedulerLink = getSchedulerLink;
        Froalaeditor.DefineIcon("scheduler-editor", {
            NAME: "fas fa-calendar-minus",
            template: "font_awesome"
        });
        Froalaeditor.RegisterCommand('scheduler-editor', {
            title: getLocalizedStrings().label.COMMON.SCHEDULER_LINK,
            focus: false,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                getSchedulerLink(this);
            }
        });
        Froalaeditor.RegisterCommand("insertYouTubeVideo", {
            title: getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE,
            focus: true,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                getVideoInsertDialog(this, constants.VIDEO_TYPE_YOUTUBE);
            },
        });

        Froalaeditor.DefineIcon("insertWistiaVideo", {
            SRC: "/asset/images/wistia.png",
            ALT: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
            template: "image",
        });
        Froalaeditor.RegisterCommand("insertWistiaVideo", {
            title: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
            focus: true,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                getVideoInsertDialog(this, constants.VIDEO_TYPE_WISTIA);
            },
        });
        Froalaeditor.DefineIcon('signature', { NAME: "fa-regular fa-file-signature", template: "font_awesome" });
	    Froalaeditor.RegisterCommand("signature", {
            title: getLocalizedStrings().label.SALES_TEMPLATE.ADD_SIGNATURE,
            focus: false,
            undo: true,
            icon: "signature",
            refreshAfterCallback: true,
            callback: function () {
                this.html.insert(" ${Email_Signature} ");
            },
        });

        Froalaeditor.DefineIcon("expand_editor", { NAME: "expand", template: "font_awesome" });
        Froalaeditor.RegisterCommand("expand_editor", {
            title: getLocalizedStrings().label.MARKETING_TEMPLATE.EXPAND_EDITOR,
            focus: false,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                expandEditor(this, "link");
            },
        });

        Froalaeditor.RegisterCommand("audit_link", {
            title: getLocalizedStrings().label.AUDIT_QUESTIONS.SURVEY_LINK,
            focus: false,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                getAuditSurveyLink(this);
            },
        });
        // let tempGetSurveyLink = getSurveyLink();
        Froalaeditor.DefineIcon("survay_link", { NAME: "fas fa-link", template: "font_awesome" });
        Froalaeditor.RegisterCommand("survay_link", {
            title: "Insert Thumbs Up/Down Survey ",
            focus: false,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                getSurveyLink(this);
            },
        });

    

        //working

        let FroalaConfig = {
            key: constants.FROALA_EDITOR_KEY,
            placeholderText: getLocalizedStrings().label.SALES_TEMPLATE.TYPE_HERE,
            heightMin: 250,
            heightMax: 300,
            toolbarButtons: constants.PROFILE_EDITOR_TOOLBAR_CONFIGURATION,
            toolbarButtonsMD: constants.PROFILE_EDITOR_TOOLBAR_CONFIGURATION,
            toolbarButtonsSM: constants.PROFILE_EDITOR_TOOLBAR_CONFIGURATION,
            toolbarButtonsXS: constants.PROFILE_EDITOR_TOOLBAR_CONFIGURATION,
            colorsBackground: constants.EDITOR_TOOLBAR_BACKGROUND_COLOR_PALETTE,
            colorsText: constants.EDITOR_TOOLBAR_TEXT_COLOR_PALETTE,
            toolbarSticky: false,
            enter: FroalaEditor.ENTER_BR,
            iframe: true,
            htmlUntouched: true,
            attribution: false,
            inlineMode: false,
            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
            linkInsertButtons: ['linkEdit', 'linkRemove'],
            imageEditButtons: ['imageAlign', 'imageRemove', 'imageLink', 'imageDisplay', 'imageAlt', 'imageSize', 'linkOpen', 'linkEdit', 'linkRemove'],
            tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', '-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign'],
            quickInsertButtons: [],
            imageUpload: false,
            imagePaste: false,
            fileUpload: false,
            fontSize: constants.EDITOR_FONT_SIZE,
            fontFamily: {
                'Adobe Garamond Pro': 'Adobe Garamond Pro',
                'Arial,Helvetica,sans-serif': 'Arial',
                'Futura Std': 'Futura Std',
                'Georgia,serif': 'Georgia',
                'Impact,Charcoal,sans-serif': 'Impact',
                "RobotoSegoe": 'Roboto',
                'Tahoma,Geneva,sans-serif': 'Tahoma',
                "'Times New Roman',Times,serif": 'Times New Roman',
                'Verdana,Geneva,sans-serif': 'Verdana',
            },
            fontFamilySelection: true,
            events: {
                "froalaEditor.initialized": function (e, editor) {
                    $(".fr-command").on("blur", function () {
                        autoSaveEmailSignature(e, editor)
                    });
                },
            },
            events: {
                'initialized': function() {
                    window.WEBSPELLCHECKER.init({
                        container: this.$iframe ? this.$iframe[0] : this.el
                    });
                 }
             }
        }

        let token = localStorage.getItem('token');
        let redirectUrl = constants.snapshotUrl;
        redirectUrl += '&token=' + token;
        redirectUrl += '&callingfor=' + "googleCalendar";
        redirectUrl += '&label=Social Media';
        let color = colorCode;
        let open = pickerOpen;
        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        return (
            <div style={{ paddingLeft: '26px', paddingRight: '30px' }}>
                <div className="row" style={{ marginTop: mainContentTopMargin }}>
                    <div className="col-sm-12 p-0">
                        <div className="row">
                            <div className="leftBlock col-sm-6">
                                <div className="row">
                                    <div>
                                        <div id="header" style={{ paddingLeft: '0px', width: '100%', fontSize: '24px', paddingBottom: '12px' }}>{getLocalizedStrings().label.PROFILE_DETAILS.PERSONAL_INFORMATION}</div>
                                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                            <TextField
                                                variant="outlined"
                                                label={getLocalizedStrings().label.PROFILE_DETAILS.NAME_REQUIRED}
                                                name="name"
                                                value={name}
                                                disabled={true}
                                                autoFocus
                                                inputprops={{ maxLength: 128 }}
                                                className={"sf-fields-bg"}
                                                autoComplete="new-password"
                                                margin="dense"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                size="small"
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row">
                                    <div style={{ paddingLeft: '15px' }}>{getLocalizedStrings().label.PROFILE_DETAILS.USERNAME_LABEL}</div>
                                    <div>
                                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                            <TextField
                                                variant="outlined"
                                                label={getLocalizedStrings().label.PROFILE_DETAILS.EMAIL_REQUIRED}
                                                name="email"
                                                value={emailId}
                                                disabled={true}
                                                margin="dense"
                                                className={"sf-fields-bg"}
                                                autoComplete="new-password"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                size="small"
                                            />
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row">
                                    <div>
                                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                            <TextField
                                                variant="outlined"
                                                label={getLocalizedStrings().label.PROFILE_DETAILS.PHONE}
                                                name="phone"
                                                value={phone}
                                                onChange={(event) => handleChangePhone(event)}
                                                disabled={false}
                                                className={"sf-fields-bg"}
                                                autoComplete="new-password"
                                                margin="dense"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                size="small"
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row">
                                    <div>
                                        <div style={{ paddingTop: '20px' }}>
                                            {selectedTimeZone !== null && <FormControl style={{ width: '100%' }} variant="outlined">
                                                <InputLabel id="time-zone-label">{getLocalizedStrings().label.PROFILE_DETAILS.TIME_ZONE_REQUIRED}</InputLabel>
                                                <Select
                                                    label={getLocalizedStrings().label.PROFILE_DETAILS.TIME_ZONE_REQUIRED}
                                                    value={selectedTimeZone}
                                                    onChange={(e) => setSelectedTimeZoneValue(e)}
                                                    labelId='sf-label-time-zone'
                                                    id='sf-time-zone'
                                                    style={{ height: '40px' }}
                                                    className={"sf-fields-bg"}>
                                                    {menuItemTimeZones}
                                                </Select>
                                            </FormControl>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div>
                                        <div style={{ paddingTop: '20px' }}>
                                            {selectedDateFormat !== null && <FormControl style={{ width: '100%' }} variant="outlined">
                                                <InputLabel id="date-format-label">{getLocalizedStrings().label.PROFILE_DETAILS.DATE_FORMAT}</InputLabel>
                                                <Select
                                                    label={getLocalizedStrings().label.PROFILE_DETAILS.DATE_FORMAT}
                                                    value={selectedDateFormat}
                                                    onChange={(e) => setSelectedDateFormatValue(e)}
                                                    labelId='sf-label-date'
                                                    id='sf-date'
                                                    style={{ height: '40px' }}
                                                    className={"sf-fields-bg"}>
                                                    {menuItemDateFormats}
                                                </Select>
                                            </FormControl>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div>
                                        <div style={{ paddingTop: '20px' }}>
                                            {selectedLanguage !== null && <FormControl style={{ width: '100%' }} variant="outlined">
                                                <InputLabel shrink id="language-label">{getLocalizedStrings().label.PROFILE_DETAILS.LANGUAGE}</InputLabel>
                                                <Select
                                                    label={getLocalizedStrings().label.PROFILE_DETAILS.LANGUAGE}
                                                    value={selectedLanguage}
                                                    onChange={(e) => setSelectedLanguageValue(e)}
                                                    labelId='sf-label-language'
                                                    id='sf-language'
                                                    style={{ height: '40px' }}
                                                    className={"sf-fields-bg"}>
                                                    {menuItemLanguages}
                                                </Select>
                                            </FormControl>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                    </div>
                                </div>

                            </div>
                            <div className="rightBlock col-sm-6">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div style={{ width: '54%', height: 'auto', marginTop: '45px', marginLeft: '30px' }}>
                                            {profileImage ? <img src={profileImage} alt={'profile img'} style={{ maxWidth: '160px', minHeight: '160px', height: '160px', width: '160px', border: '0px' }} />
                                                : <i className='fa fa-user' style={{ fontSize: '150px', paddingLeft: '25px' }}></i>}
                                            <Button
                                                key='changePhoto'
                                                onClick={() => uploadProfilePicture()}

                                                style={{ ...styles.primaryButton, verticalAlign: 'top', width: 160, marginTop: 5 }}
                                            >{getLocalizedStrings().label.PROFILE_DETAILS.CHANGE_PHOTO}</Button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div id="header" style={{ paddingLeft: '20px', width: '100%', fontSize: '24px', paddingTop: '20px', paddingBottom: '10px' }}>{getLocalizedStrings().label.PROFILE_DETAILS.USER_COLOR}</div>
                        <div className="col-sm-6">
                            <div >
                                <Button label="" key="colorchange" style={{ ...{ borderRadius: '0px', border: '0px', height: '20px', backgroundColor: color, width: 20, minWidth: 20, marginTop: 10, marginLeft: 19 } }} onClick={() => handelColorPicker()} />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {open &&
                            <div className="col-sm-6">
                                <div style={{ paddingTop: '20px' }}>
                                    <div style={cover} onClick={() => handleClose()} />
                                    <TwitterPicker
                                        color={colorCode}
                                        onChangeComplete={handleChangeComplete}
                                        width='204px'
                                        colors={myColors}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <div>
                        <div className="row">
                            <div id="header" style={{ paddingLeft: '15px', width: '100%', fontSize: '24px', paddingTop: '20px', paddingBottom: '10px' }}>{getLocalizedStrings().label.PROFILE_DETAILS.CHANGE_PASSWORD}</div>
                            <div className="col-sm-6" style={{ paddingLeft: '15px', width: '51%' }}>
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        type="password"
                                        label={getLocalizedStrings().label.PROFILE_DETAILS.NEW_PASSWORD}
                                        name="newPassword"
                                        value={newPassword}
                                        onChange={(event) => handleChangeNewPswd(event)}
                                        onPaste={function (e) { e.preventDefault(); }}
                                        disabled={false}
                                        inputprops={{ maxLength: 32 }}
                                        className={"sf-fields-bg"}
                                        autoComplete="new-password"
                                        margin="dense"
                                        size="small"
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6" style={{ paddingLeft: '15px', width: '51%' }}>
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        type="password"
                                        label={getLocalizedStrings().label.PROFILE_DETAILS.CONFIRM_PASSWORD}
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(event) => handleChangeCnfPswd(event)}
                                        onPaste={function (e) { e.preventDefault(); }}
                                        disabled={false}
                                        inputprops={{ maxLength: 32 }}
                                        className={"sf-fields-bg"}
                                        autoComplete="new-password"
                                        margin="dense"
                                        size="small"
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="row" >
                            <div id="header" style={{ paddingLeft: '15px', width: '100%', fontSize: '24px', paddingTop: '0px', paddingBottom: '12px' }}>{getLocalizedStrings().label.PROFILE_DETAILS.EMAIL_SIGNATURE}</div>
                            <div style={{ paddingLeft: '15px', paddingBottom: '15px' }}>
                                <FormControl style={{ width: '49%' }} variant="outlined">
                                    <InputLabel id="verified-email-label"></InputLabel>
                                    <Select
                                        onChange={(event) => setSelectedEmailId(event)}
                                        style={{ height: '40px' }}
                                        className={"sf-fields-bg"}
                                        labelId='sf-label-sign'
                                        id='sf-sign'
                                        value={selectedEmail}
                                        defaultValue={selectedEmail}

                                        >
                                        {menuItemEmailIds}
                                    </Select>
                                </FormControl>
                            </div>
                            <div id="froala-editor" style={{ paddingLeft: '15px' }}>
                                <FroalaEditor 
                                    tag='textarea' 
                                    style={{ width: '300px', paddingTop: '20px' }} 
                                    model={emailSignature} 
                                    onModelChange={(e) => handleModelChange(e)} 
                                    config={FroalaConfig} 
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row">
                            <div style={{ paddingTop: '15px', paddingBottom: '30px', overflow: 'hidden', float: 'right' }}>
                                    <Button
                                        key='save'
                                        onClick={() => validateProfileDetailsForm()}

                                        style={{ ...styles.primaryButton,float: 'right', marginRight: '6px', verticalAlign: 'top' }}
                                    >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default ProfileDetails;