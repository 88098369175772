import { actions } from '..//constants/actions';
import map from 'lodash/map';

const userListInitialState = {
    mounted: false,
    userList: { data: [] },
    data: [],
    error: null,
    nylasConfigDetails: null,
}

const userListReducer = (state = userListInitialState, action) => {
    switch (action.type) {
        case actions.USERLIST_GET_FULFILLED:
            let data = action.payload.users;
            var userArr = [];
            if (data !== null && data !== undefined) {

                let userObj = null;
                map(data, function (userObject, i) {
                    userObj = {};
                    userObj.value = userObject.name;
                    userObj.email = userObject.email;
                    userObj.phone = userObject.phone;
                    userObj.text = userObject.name;
                    userObj.id = userObject.id;
                    userObj.colorCode = userObject.colorCode;
                    userObj.profile_image_url = userObject.profile_image_url;
                    userArr.push(userObj);
                });
            }

            userArr = userArr.sort(function (a, b) { return (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0); });
            return {
                ...state,
                method: true,
                mounted: true,
                data: userArr
            }
            case actions.USERLIST_ERROR:
                return {
                    ...state,
                    mounted: false,
                    error: action.payload
                }
                case actions.SETUSERLIST_DATA:
                    return {
                        ...state,
                        mounted: true,
                        data: action.payload
                    }
            case actions.SET_NYLAS_CONFIG_DETAILS:
                return{
                    ...state,
                    mounted: true,
                    nylasConfigDetails:action.payload
                }
        default:
            return state;
    }
}

export default userListReducer;