
import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { isValidParam, isInvalidParam, getIntParam, isInteger } from '../../../services/helper/parameterVerifier';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { addNode } from '../../../services/actions/automationCampaignActions';
import { removeError } from '../../../services/helper/automationUtils';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import {useDispatch, useSelector} from 'react-redux';

const AutomationScoreReached = ({data,closeDrawer}) => {
    const dispatch = useDispatch();
    const automationCampaign = useSelector((state) => state.automationCampaign);
    const [scoreValue,setScoreValue] = useState(0);

    useEffect(() => {
        if (isValidParam(data)) {
            if (data.hasOwnProperty('secProcessId')) {
                setScoreValue(data.secProcessId);
            }
        }
    }, []);

    const changeScore = (event, value) => {
        if (isValidParam(value)) {
            value = value.trim();
            if (!isInteger(value)) {
                value = "";
            }
            setScoreValue(value);
        }
    }

    const saveScore = () => {
        let nodeObject = data;
        let scoreVal = parseInt(scoreValue);
        let dialogData = null;
        if (isInvalidParam(scoreVal)) {
            dialogData = {
                message: getLocalizedStrings().message.AUTOMATION_DESIGNER.ENTER_SCORE,
                fieldId: 'scoreStart',
            }
            openDialog(dialogData);
        } else if (scoreVal !== "" && isValidParam(scoreVal) && !getIntParam(scoreVal)) {
            dialogData = {
                message: getLocalizedStrings().message.AUTOMATION_DESIGNER.INVALID_SCORE,
                fieldId: 'scoreStart',
            }
            openDialog(dialogData);
        } else {
            if (isValidParam(nodeObject)) {
                if (nodeObject.hasOwnProperty('secProcessId')) {
                    nodeObject.secProcessId = scoreVal;
                    nodeObject = removeError(nodeObject);
                    dispatch(addNode(automationCampaign.data));
                    closeDrawer();
                }
            }
        }
    }

    const openDialog = (dialogData) => {
        dispatch(getAppDialog(true, constants.ALERT_DIALOG, getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, null, dialogData, constants.AUTOMATION_DESIGNER_OBJECT));
    }
        
        return (
            <div style={{ paddingTop: '5px', paddingLeft: '20px', paddingRight: '20px' }}>
                <div style={{display: 'inline-block'}}>
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            value={scoreValue}
                            onChange={(e)=>changeScore(e,e.target.value)}
                            inputprops={{maxlength: '4' }}
                            id="scoreStart"
                            label={getLocalizedStrings().label.AUTOMATION_DESIGNER.SCORE}
                            autoComplete="new-password"
                            margin='dense'
                            size='small'
                            className={"sf-fields-bg"}
                        />
                    </FormControl>
                </div>
                {
                    (automationCampaign.data.status !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                    <div style={{ paddingLeft: '10px', display: 'inline-block', verticalAlign: 'top', paddingTop: '5px' }}>
                        <Button
                            style={{...styles.listViewPrimaryButton , marginBottom: '10px' }}
                            onClick={()=>saveScore()}
                        >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                    </div>
                }
            </div>
        );
    }


export default AutomationScoreReached;


