import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import Tooltip from '@mui/material/Tooltip';
import { Step, Stepper, StepLabel, Icon } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import Button from '@mui/material/Button';
import { constants } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { getStringParam, isValidParam, isInteger, getObjectParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { Menu, Select, MenuItem, InputLabel } from '@mui/material';
import ShowCircularProgress from '../components/circularProgress';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IframeComm from "react-iframe-comm";
import { addNode } from '../../../services/actions/automationCampaignActions';
import { getNodeByChildId, getNodeByParentId, getRequiredParentNode, updateNodesData } from '../../../services/helper/automationUtils';
import { sortArrayObjectByProperty, getBeeEditorFontList, isEmailExistsInList } from '../../../services/helper/utils';
import { getAppCustomDrawer, getAppDialog } from '../../../services/actions/appContainerActions';
import * as sfDialogs from '../components/sfDialogs';
import { getCoupons, getDefaultBeeEditorData } from '../../../services/helper/common';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getCouponsAction } from '../../../services/actions/couponActions';
import Bee from "@mailupinc/bee-plugin";

class AutomationMosaicoTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            steps: null,
            stepIndex: 0,
            finished: false,
            template: {
                name: '',
                subject: '',
                email_message: '',
                id: this.props.id | 0,
                status: 'Active',
                t_library: 1,
                category: '',
                shareMarketingTemplate: false,
                metadata: '',
                content: '',
                endPoint: constants.endPoint,
                personalize_fields: [],
                user_name: this.props.app.me.name,
                user_email: this.props.app.me.email,
                object: constants.AUTOMATION_DESIGNER_OBJECT,
                interval: '0',
                intervalMode: 'days',
                nodeObject: this.props.nodeObject,
                isCheckViewAsWeb: false,
                messageSequence: 0,
                isShowTestButton: false,
                parentActionName: '',
                template_name: '',
                template_type: '',
                language: this.props.app.me.language,
                token: localStorage.getItem('token'),
                automationCampaignData: this.props.automationCampaign.data,
                coupons: [],
                sender_name: '',
                sender_email: '',
                scheduler_slug: this.props.app.appointment_scheduler_slug
            },
            personalize: -1,
            mounted: false,
            statusOpen: false,
            personilizeOpen: false,
            couponLoader: true,
            error: {
                subject: '',
                interval: '',
                intervalMode: '',
                sender_name: '',
                sender_email: ''
            },
            verifiedEmailIds: null,
            verifiedEmailOptions: null,
            isDisableSenderEmail: false,
            isDisableRefresh: false,
            isOpenNoteTypeList: false,
            anchorELNoteTypeList: null,
            bee: null,
            beeToken: null
        };
       this.beeTest = new Bee(this.state.beeToken);

    }

    componentDidMount() {
        let userDetail = this.props.app.me;
        this.state.template.sender_name = userDetail.name;
        this.state.template.sender_email = userDetail.email;
        this.setState({});
        // this.loadCoupons();
        this.loadAutomationTemplateData(this.props.nodeObject);
        this.generateVerifiedEmailIds();
    }
    
    validateForm = () => {
        const template = this.state.template;
        const error = this.state.error;
        let isValid = true;
        if (template['interval'].toString() === null || template['interval'].toString() === undefined || template['interval'].toString() === "") {
            isValid = false;
            error['interval'] = getLocalizedStrings().message.AUTOMATION_DESIGNER.ENTER_INTERVAL_VALUE;
            this.setState({
                error
            });
        }
        if (template['interval'] === 0 && template['parentActionName'] === constants.AUTOMATION_DESIGNER_ADD_MESSAGE &&
            template['intervalMode'] !== "") {
            isValid = false;
            error['interval'] = getLocalizedStrings().message.AUTOMATION_DESIGNER.INTERVAL_MODE_ADD_MSG_1 + ' ' + template['intervalMode'] + ' ' + getLocalizedStrings().message.AUTOMATION_DESIGNER.INTERVAL_MODE_ADD_MSG_2;
            this.setState({
                error
            });
        }
        if (template['interval'] < 1 && template['parentActionName'] === constants.AUTOMATION_DESIGNER_ADD_MESSAGE &&
            template['intervalMode'] === "hours") {
            isValid = false;
            error['interval'] = getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_ONE_HOUR;
            this.setState({
                error
            });
        }
        if (template.subject.trim() === '') {
            isValid = false;
            error['subject'] = getLocalizedStrings().message.MARKETING_TEMPLATE.SUBJECT_BLANK;
            this.setState({
                error
            });
        }

        if (template.nodeObject.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
            if (template.sender_name.trim() === '') {
                isValid = false;
                error['sender_name'] = getLocalizedStrings().message.MARKETING_TEMPLATE.FROM_NAME_BLANK;
                this.setState({
                    error
                });
            }

            if (template.sender_email.trim() === '-1') {
                isValid = false;
                error['sender_email'] = getLocalizedStrings().message.MARKETING_TEMPLATE.FROM_EMAIL_BLANK;
                this.setState({
                    error
                });
            }
        }
        return isValid;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.id !== this.props.id) { }
    }

    loadCoupons = () => {
        try {
            let coupons = {};
            let _state = this.state
            if (!this.props.coupons?.couponMounted) {
                let promise = getCoupons()
                promise.then((response) => {
                    if (isValidParam(response.records)) {
                        this.props.getCouponsAction(response.records)
                        let records = getArrayParam(response.records);
                        records.forEach(record => {
                            let discount = record.discount_type.toLowerCase() === 'percentage' ? record.discount + '%' : '$' + record.discount;
                            coupons[' ' + record.name] = `${record.name} (${discount})`;
                        });
                        _state.coupons = coupons;
                        _state.couponLoader= false ;
                        this.setState(_state);
                    }
                })
            } else {
                if (isValidParam(this.props.coupons.coupons)) {
                    let records = getArrayParam(this.props.coupons.coupons);
                    records.forEach(record => {
                        let discount = record.discount_type.toLowerCase() === 'percentage' ? record.discount + '%' : '$' + record.discount;
                        coupons[' ' + record.name] = `${record.name} (${discount})`;
                    });
                }
                _state.coupons = coupons;
                _state.couponLoader= false ;
                this.setState(_state);
            }
            

        } catch (error) {
            console.error("Error in 'AutomationMosaicoTemplate.js -> loadCoupons()':" + error);
        }
    }

    loadAutomationTemplateData = (nodeObject) => {
        try {
            let _state = this.state;
            let dbp = _state.template.interval;
            let interval_mode = _state.template.intervalMode;
            let emailMessage = _state.template.email_message;
            let subject = _state.template.subject;
            let templateId = _state.template.id;
            let metadata = _state.template.metadata;
            let content = _state.template.content;
            let templateObject = null;
            let sequence = _state.template.messageSequence;
            let isCheckViewAsWeb = _state.template.isCheckViewAsWeb;
            let view_as_web = null;
            let isShowTestButton = _state.template.isShowTestButton;
            let detailsArray = nodeObject.hasOwnProperty('details') ? nodeObject['details'][0] : null;
            let templateName = _state.template.templateName;
            let templateType = _state.template.template_type;
            if (nodeObject.hasOwnProperty('template_name') && isValidParam(nodeObject['template_name'])) {
                templateName = nodeObject['template_name'];
                _state.template.template_name = templateName;
            }
            if (nodeObject.hasOwnProperty('template_type') && isValidParam(nodeObject['template_type'])) {
                templateType = nodeObject['template_type'];
                _state.template.template_type = templateType;
            }
            if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
                let arrayNodes = this.props.automationCampaign.data.nodes;
                let childInfo = getNodeByParentId(arrayNodes, nodeObject.childId);
                let websiteVisitUrl = null;
                if (childInfo && childInfo.length > 0) {
                    if (childInfo[0].hasOwnProperty("actionName")) {
                        if (childInfo[0].actionName !== null && childInfo[0].actionName !== "" &&
                            childInfo[0].actionName === constants.AUTOMATION_DESIGNER_WEBSITE_VISIT) {
                            if (childInfo[0].hasOwnProperty("details")) {
                                if (childInfo[0].details !== null && childInfo[0].details !== "undefined" &&
                                    childInfo[0].details.length > 0) {
                                    if (childInfo[0].details[0].hasOwnProperty("websiteUrl")) {
                                        websiteVisitUrl = childInfo[0].details[0].websiteUrl;
                                        _state.template.websiteVisitUrl = websiteVisitUrl;
                                    }
                                }
                            }
                        }
                    }
                }
                let touchNode = arrayNodes.filter(f => (f.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL));
                if (touchNode[0].hasOwnProperty('details')) {
                    let touchNodeDetails = touchNode[0].details[0];
                    _state.template.sender_name = touchNodeDetails.from_name;
                    _state.template.sender_email = touchNodeDetails.sender_email;
                }
            }

            if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                let arrayNodes = this.props.automationCampaign.data.nodes;
                let touchNode = arrayNodes.filter(f => (f.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL));

                if (touchNode.length > 0 && touchNode[0].hasOwnProperty('details') && getArrayParam(touchNode[0].details).length > 0) {
                    let touchNodeDetails = touchNode[0].details[0];

                    if (isValidParam(touchNodeDetails.from_name) && touchNodeDetails.from_name !== '') {
                        _state.template.sender_name = touchNodeDetails.from_name;
                    }
                    if (isValidParam(touchNodeDetails.sender_email) && touchNodeDetails.sender_email !== '') {
                        _state.template.sender_email = touchNodeDetails.sender_email;
                    }
                }

                if (nodeObject !== null && nodeObject.hasOwnProperty('details') && getArrayParam(nodeObject.details).length > 0) {
                    let autoNodeDetails = nodeObject.details[0];

                    if (isValidParam(autoNodeDetails.sender_name) && autoNodeDetails.sender_name !== '') {
                        _state.template.sender_name = autoNodeDetails.sender_name;
                    }

                    if (isValidParam(autoNodeDetails.sender_email) && autoNodeDetails.sender_email !== '') {
                        _state.template.sender_email = autoNodeDetails.sender_email;
                    }
                }

                if (_state.template.sender_name === constants.CONSTANT_RECORD_OWNER) {
                    _state.template.sender_email = '';
                }

                if (_state.template.sender_name === constants.CONSTANT_RECORD_OWNER && _state.template.sender_email === '') {
                    _state.isDisableSenderEmail = true;
                    _state.isDisableRefresh = true;
                }
            }
            if (isValidParam(detailsArray)) {
                if (detailsArray.hasOwnProperty('dbp')) {
                    dbp = detailsArray.dbp;
                    let immediateprevId = 0;
                    let immediateprevnodeDetails = null;
                    let actionName = null;
                    if (isValidParam(nodeObject.parentId) && nodeObject.parentId > 0) {
                        immediateprevId = nodeObject.parentId;
                        immediateprevnodeDetails = getNodeByChildId(this.props.automationCampaign.data.nodes, immediateprevId);
                        if (isValidParam(immediateprevnodeDetails)) {
                            actionName = immediateprevnodeDetails.hasOwnProperty('actionName') ? immediateprevnodeDetails.actionName : "";
                            if (actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE && dbp === 0) {
                                dbp = "";
                            }
                        }
                    }
                    _state.template.interval = dbp;
                    _state.template.parentActionName = actionName;
                }

                if (detailsArray.hasOwnProperty('interval_mode')) {
                    interval_mode = detailsArray.interval_mode;
                    _state.template.intervalMode = interval_mode;
                }

                if (detailsArray.hasOwnProperty('sequence')) {
                    sequence = detailsArray.sequence;
                    _state.template.messageSequence = sequence;
                }

                if (detailsArray.hasOwnProperty('view_as_web')) {
                    view_as_web = detailsArray.view_as_web;
                    if (view_as_web === "Yes") {
                        isCheckViewAsWeb = true;
                    } else if (view_as_web === "No") {
                        isCheckViewAsWeb = false;
                    }
                    _state.template.isCheckViewAsWeb = isCheckViewAsWeb;
                }

                if (detailsArray.hasOwnProperty('message_id')) {
                    let message_id = detailsArray.message_id;
                    if (message_id > 0) {
                        isShowTestButton = true;
                        _state.template.isShowTestButton = isShowTestButton;
                    }
                }

                if (detailsArray.hasOwnProperty('template')) {
                    templateObject = detailsArray.template;
                    if (templateObject.hasOwnProperty('emailBody')) {
                        emailMessage = templateObject.emailBody;
                        _state.template.email_message = emailMessage;
                    }
                    if (templateObject.hasOwnProperty('subject')) {
                        subject = templateObject.subject;
                        _state.template.subject = subject;
                    }
                    if (templateObject.hasOwnProperty('template_id')) {
                        templateId = templateObject.template_id;
                        _state.template.id = templateId;
                    }
                    if (templateObject.hasOwnProperty('metadata')) {
                        metadata = templateObject.metadata;
                        _state.template.metadata = metadata;
                    }
                    if (templateObject.hasOwnProperty('content')) {
                        content = templateObject.content;
                        _state.template.content = content;
                    }
                    if (templateObject.hasOwnProperty('editorType')) {
                        _state.template.editor_type = templateObject.editorType;
                        //_state.template.editor_type = constants.EDITOR_NEW;
                    }
                }
                let templateName = this.props.automationCampaign.data.templateName;
                let fields = this.props.margefields;
                fields = fields.filter(f => (f.name !== 'header' && f.name !== ''));
                let mergeFields = {};
                let petMergeFields = {};
                sortArrayObjectByProperty(fields, 'label');
                for (let i = 0; i < fields.length; i++) {
                    let fieldsName = "j_contacts." + fields[i].name;
                    mergeFields[fieldsName] = fields[i].label;
                }
                if (templateName === constants.PET_BIRTHDAY_CAMPAIGN_NAME) {
                    let petFieldName = "j_pets.name";
                    petMergeFields[petFieldName] = "Pet Name";
                    mergeFields = { ...petMergeFields, ...mergeFields }
                }
                _state.template.personalize_fields = mergeFields;
                _state['mounted'] = true;
                _state.cursorposition = _state.template.subject.length;
                // this.setState({ _state });
                this.setState(_state);
            } else {
                let immediateprevId = 0;
                let immediateprevnodeDetails = null;
                let actionName = null;
                if (isValidParam(nodeObject.parentId) && nodeObject.parentId > 0) {
                    immediateprevId = nodeObject.parentId;
                    immediateprevnodeDetails = getNodeByChildId(this.props.automationCampaign.data.nodes, immediateprevId);
                    if (isValidParam(immediateprevnodeDetails)) {
                        actionName = immediateprevnodeDetails.hasOwnProperty('actionName') ? immediateprevnodeDetails.actionName : "";
                        if (actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE && dbp === 0) {
                            dbp = "";
                            _state.template.interval = dbp;
                            _state.template.parentActionName = actionName;
                        }
                    }
                }

                _state['mounted'] = true;
                let fields = this.props.margefields;
                fields = fields.filter(f => (f.name !== 'header' && f.name !== ''));
                let mergeFields = {};
                sortArrayObjectByProperty(fields, 'label');
                for (let i = 0; i < fields.length; i++) {
                    let fieldsName = "j_contacts." + fields[i].name;
                    mergeFields[fieldsName] = fields[i].label;
                }
                _state.template.personalize_fields = mergeFields;
                this.setState({ _state }, () => {
                    if (this.state.stepIndex === 1 && this.state.template.editor_type === constants.EDITOR_BEE) {
                        this.registerBeeEditor();
                    }
                });
            }
        } catch (error) {
            console.log('Error in AutomationMosaicoTemplate.js at loadAutomationTemplateData() : ' + error);
        }
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const _state = this.state;
        _state.cursorposition = event.target.selectionStart;
        _state.template[name] = value;
        if (value.trim() !== '') {
            _state.error[name] = "";
        }
        this.setState(_state);
    };

    changeIntervalMode = (event, index, value) => {
        if (isValidParam(value)) {
            let _state = this.state;
            _state.template['intervalMode'] = value;
            this.setState(_state);
        }
    }

    changeInterval = (event, value) => {
        if (isValidParam(value)) {
            value = value.trim();
            let _state = this.state;
            if (!isInteger(value)) {
                value = '';
                _state.template['interval'] = value;
            } else {
                _state.template['interval'] = value;
            }
            if (value.trim() !== '') {
                _state.error['interval'] = "";
            }

            this.setState(_state);
        }
    }

    setTemplateName = () => {
        let tname = getStringParam(this.state.template.name);
        if (tname === '' && getStringParam(this.state.templateName) !== '') {
            this.state.template.name = this.state.templateName;
            this.setState({});
        }
    }

    checkBoxShare = (event, isInputChecked) => {
        const _state = this.state;
        _state.template['shareMarketingTemplate'] = isInputChecked;
        this.setState(_state);
    }

    onCheckViewAsWeb = (event, isChecked) => {
        let _state = this.state;
        _state.template.isCheckViewAsWeb = isChecked;
        this.setState(_state);
    }

    handleStatusChange = (fieldName, event, value) => {
        value = value === 0 ? 'Active' : 'Inactive';
        const _state = this.state;
        _state.template[fieldName] = value;
        _state['statusOpen'] = false;
        this.setState(_state);
    };

    handleStatusOpen = (event) => {
        event.preventDefault();
        this.setState({ statusOpen: true, anchorEl: event.currentTarget });
    }

    handleStatusClose = () => {
        this.setState({ statusOpen: false });
    }
    handleSelectPersonilizeChange = (fieldName, value, event) => {
        const _state = this.state;
        _state[fieldName] = value;
        _state['personalize'] = value;
        _state['personilizeOpen'] = false;
        let front = (_state.template['subject']).substring(0, this.state.cursorposition);
        let back = (_state.template['subject']).substring(this.state.cursorposition, _state.template['subject'].length);
        _state.template['subject'] = front + ' ${' + value + '}' + back;
        _state.cursorposition = _state.template['subject'].length;
        _state.isOpenNoteTypeList = false;

        this.setState(_state);
    };
 

    handlePersonilizeClose = () => {
        this.setState({ personilizeOpen: false });
    }

    handlePersonilizeOpen = (event) => {
        event.preventDefault();
        this.setState({ personilizeOpen: true, anchorPersonilizeEl: event.currentTarget });
    }

    renderPersonalizeField = () => {

        let personalizeObject =  getObjectParam(this.state.template.personalize_fields);
        return Object.keys(personalizeObject).map((itemKey, index) => {

            return <MenuItem
                key={itemKey + index}
                value={itemKey}
                style={styles.popoverMenuItem}
                fullWidth={true}
                labelstyle={{ color: '#479ccf' }}
                underlinestyle={{ display: 'none' }}
                autowidth={"true"}
                onClick={this.handleSelectPersonilizeChange.bind(this, 'personalize', itemKey)}>
                {personalizeObject[itemKey]}
            </MenuItem>;
        });
    }

    changeTemplate = () => {
        try {
            let labelName = getLocalizedStrings().label.AUTOMATION_DESIGNER['Add Touch Message'];
            if (this.state.template.nodeObject.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                labelName = getLocalizedStrings().label.COMMON.SEND_EMAIL;//getLocalizedStrings().label.AUTOMATION_DESIGNER['Send One-off Email'];
            }
            if (this.state.template.nodeObject.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
                labelName = getLocalizedStrings().label.AUTOMATION_DESIGNER['Add Birthday Message'];
            }
            let nodeObject = this.state.template.nodeObject;
            let callbackData = {
                labelName: labelName,
                value: "New",
                data: {},
                nodeobject: nodeObject,
                type: this.state.template.editor_type,
                callFrom: 'template change'
            }
            this.props.getAppDialog(true, constants.EDITOR_TEMPLATE_DIALOG, getLocalizedStrings().label.CAMPAIGNS.TEMPLATE_DIALOG_TITLE, this.setTemplateData, callbackData, constants.AUTOMATION_DESIGNER_OBJECT);
        } catch (error) {
            console.log('Error in AutomationMosaicoTemplate.js at changeTemplate() : ' + error);
        }
    }

    setTemplateData = (templateObject, isNodeobject) => {
         
        try {
            let _state = this.state;
            if (isValidParam(isNodeobject) && isNodeobject) {
                let nodeobject = templateObject;
                if (nodeobject.hasOwnProperty('template_type')) {
                    // _state.template.nodeObject['template_type'] = nodeobject['template_type'];
                    _state.template.template_type = nodeobject['template_type'];
                }
                if (nodeobject.hasOwnProperty('template_name')) {
                    // _state.template.nodeObject['template_name'] = nodeobject['template_name'];
                    _state.template.template_name = nodeobject['template_name'];
                }
                _state.template.subject = '';
                _state.template.email_message = '';
                _state.template.metadata = '';
                _state.template.content = '';
            } else {
                if (templateObject.hasOwnProperty('content')) {
                    _state.template.content = templateObject['content'];
                    // _state.template.nodeObject['details'][0].template.content = templateObject['content'];
                }
                if (templateObject.hasOwnProperty('metadata')) {
                    _state.template.metadata = templateObject['metadata'];
                    // _state.template.nodeObject['details'][0].template.metadata = templateObject['metadata'];
                }
                if (templateObject.hasOwnProperty('emailBody')) {
                    _state.template.email_message = templateObject['emailBody'];
                    // _state.template.nodeObject['details'][0].template.emailBody = templateObject['emailBody'];
                }
                if (templateObject.hasOwnProperty('subject')) {
                    _state.template.subject = templateObject['subject'];
                    // _state.template.nodeObject['details'][0].template.subject = templateObject['subject'];
                }
            }
            this.props.getAppDialog(false, constants.EDITOR_TEMPLATE_DIALOG, null, null, null, null);
            this.setState(_state);
        } catch (error) {
            console.log('Error in AutomationMosaicoTemplate.js at setTemplateData() : ' + error);
        }

    }

    getSpecialLinks = () => {
        let specialLinks = [];
        try {
            let slug = this.props.app.appointment_scheduler_slug;
            if (isValidParam(slug) && slug !== '') {
                let link = { type: 'Appointment', label: 'Book an appointment', link: constants.APPOINTMENT_SCHEDULER_URL + slug }
                specialLinks.push(link);
            }
        } catch (error) {
            console.error("Error in 'BroadcastCampaignForMosaico.js -> getSchedulerLink()':" + error);
        }
        return specialLinks;
    }

    getMergeTags = () => {
        let mergeTags = [];
        let mergeFields = getObjectParam(this.state.template.personalize_fields);
        try {
            mergeTags = [
                  { name: 'Company Logo', value:"${company.logo}"},
                 { name: 'Company Name', value:"${company.business_name}"},
                 { name: 'Company Website', value:"${company.website}"},
                 { name: 'Company Address', value:"${company.business_address}"},
                 { name: 'Company Phone', value:"${company.business_phone}"},
                 { name: 'Company Email', value:"${company.business_email}"},
                 // { name: 'Company Country', value:"${company.country}"},
                { name: 'Opt-in Link', value: '[Start Opt-in Link] Enter your display text [End Opt-in Link]' },
                { name: 'Opt-out Link', value: '[Start Opt-out Link] Enter your display text [End Opt-out Link]' },
                { name: 'Signature', value: '${Email_Signature}' },
            ];
            let tempTags = Object.keys(mergeFields).map(key => {
                return { name: mergeFields[key], value: '$' + `{${key}}` };
            });

            mergeTags.push(...tempTags);
        } catch (error) {
            console.error("Error in 'AutomationMosaicoTemplate.js -> getMergeTags()':" + error);
        }
        return mergeTags;
    }

    handleSave = () => {
        try {
            if (isValidParam(this.beeTest)) {
                this.beeTest.saveAsTemplate();
            }
        } catch (error) {
            console.error("Error in 'MosaicoTemplate.js -> handleSave()':" + error);
        }
    }
    handlePreview = (type) => {
        if (isValidParam(this.beeTest)) {
            this.setState({ beeEditorAction: type }, () => {
                this.beeTest.preview();
            });
        }

    }

    handleLegacyPreview = () => {
        window.open('/#previewemailtemplate/' + this.state.template.id);


    }
    registerBeeEditor = async () => {
        let _this = this;
        let template = this.state.template;
        try {
            if (template.editor_type === constants.EDITOR_BEE) {
                let tenantId = getStringParam(this.props.app.me.projectId);
                let mergeTags = this.getMergeTags();
                let specialLinks = this.getSpecialLinks();
                let beeConfig = {
                    uid: tenantId,
                    container: 'bee-plugin-container',
                    language: 'en-US',
                    mergeTags: mergeTags,
                    specialLinks: specialLinks,
                    editorFonts: {
                        showDefaultFonts: false,
                        customFonts: getBeeEditorFontList()
                    },
                    titleDefaultConfig: {
                        bold: true
                    },
                    titleDefaultStyles: {
                        h1: {
                            color: '#555555',
                            'font-size': '34px',
                            'font-family': "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            'link-color': '#E01253',
                            'line-height': '120%',
                            'text-align': 'center',
                            'direction': 'ltr',
                            'letter-spacing': 0,
                        },
                        h2: {
                            color: '#555555',
                            'font-size': '23px',
                            'font-family': "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            'link-color': '#E01253',
                            'line-height': '120%',
                            'text-align': 'center',
                            'direction': 'ltr',
                            'letter-spacing': 0,
                        },
                        h3: {
                            color: '#555555',
                            'font-size': '18px',
                            'font-family': "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            'link-color': '#E01253',
                            'line-height': '120%',
                            'text-align': 'center',
                            'direction': 'ltr',
                            'letter-spacing': 0,
                        },
                    },
                    contentDefaults: {
                        text: {
                            html: "I'm a new Text block ready for your content.",
                            styles: {
                                color: "#555555",
                                linkColor: "#0068A5",
                                fontSize: '14px',
                                lineHeight: "120%",
                                fontFamily: "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            },
                        },
                    },
                    onSaveAsTemplate: (jsonFile) => {
                        /*Call content service api to get Responsible HTML for email */
                        let response = Promise.resolve(HTTPClient.post(endPoints.MARKETING_TEMPLATE.GET_BEE_MESSAGE_HTML, { page: JSON.parse(jsonFile) }));
                        response.then((response) => {
                            if (isValidParam(response) && isValidParam(response.data) && response.data !== '') {
                                let html = response.data;
                                template.email_message = html;
                                template.content = jsonFile;
                                let nodeObject = template.nodeObject;
                                let nodeObjectDetails = isValidParam(nodeObject.details) ? nodeObject.details[0] : null;
                                if (isValidParam(nodeObjectDetails) && isValidParam(nodeObjectDetails.template)) {
                                    nodeObjectDetails.template.editorType = constants.EDITOR_BEE;
                                    nodeObjectDetails.template.emailBody = html;
                                    nodeObjectDetails.template.content = jsonFile;
                                    nodeObjectDetails.template.subject = this.state.template.subject;
                                    nodeObjectDetails.sender_name = this.state.template.sender_name;
                                    nodeObjectDetails.sender_email = this.state.template.sender_email;
                                    nodeObjectDetails.view_as_web = this.state.template.isCheckViewAsWeb === true ? 'Yes' : 'No';
                                    delete nodeObject.is_new;
                                    updateNodesData(nodeObject);
                                    _this.updateAutomationRedux(_this.props.automationCampaign.data.nodes, nodeObject);
                                    _this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                                }
                            }
                        });
                    },
                }
                // let endpoint = constants.BEE_EDITOR_AUTH_API;
                // let payload = {
                //     client_id: constants.BEE_EDITOR_CLIENT_ID,
                //     client_secret: constants.BEE_EDITOR_CLIENT_SECRET_ID,
                // };
                // $.post(endpoint, payload)
                //     .done(function (data) {
                //         //let token = data;
                //         const BeePlugin = new Bee();
                //         let templateObj = getStringParam(template.content) !== "" ? JSON.parse(template.content) : getDefaultBeeEditorData();
                //         BeePlugin.start(beeConfig, templateObj);
                //         /*BeePlugin.create(token, beeConfig, function (instance) {
                //             bee = instance;
                //             let templateObj = getStringParam(template.content) !== "" ? JSON.parse(template.content) : getDefaultBeeEditorData();
                //             bee.start(templateObj);
                //             _this.bee = bee;
                //         });*/
                //     }).fail(function () {
                //         alert('Network connection error... Check your network settings and try again later');
                //     });
                let templateObj = getStringParam(template.content) !== "" ? JSON.parse(template.content) : getDefaultBeeEditorData();
                 
                console.log(this.beeTest);
                if (this.beeTest.token && this.beeTest.token.hasOwnProperty('access_token') && this.beeTest.token.access_token && this.beeTest.token.access_token.trim() !== "") {
                    this.beeTest.start(beeConfig, templateObj)
                        .then(instance => {
                            this.setState({
                                bee: instance
                            })
                        })
                } else {
                    await this.beeTest.getToken(
                        constants.BEE_EDITOR_CLIENT_ID,
                        constants.BEE_EDITOR_CLIENT_SECRET_ID
                    )
                        .then((response) => {
                            this.setState({
                                beeToken: response.access_token
                            })
                        })
                        .then(() => {
                            this.beeTest.start(beeConfig, templateObj)
                                .then(instance => {
                                    this.setState({
                                        bee: instance
                                    })
                                })
                        }).catch((error) => console.error('error during iniziatialization --> ', error))
                }
            }
        } catch (error) {
            console.error("Error in 'AutomationMosaicoTemplate.js -> registerBeeEditor()':" + error);
        }
    }

    generateVerifiedEmailIds = (isReloaded, verifiedEmailIdList) => {
        let verifid_email_options = [];
        let emailIds = [];
        let verifyEmailAddress = "Verify another email address";
        verifyEmailAddress = getLocalizedStrings().label.AUTOMATION_DESIGNER.VERIFY_EMAIL_ADDRESS_OPTION;
        try {
            if (isValidParam(isReloaded) && isReloaded) {
                verifiedEmailIdList = getArrayParam(verifiedEmailIdList);
                verifiedEmailIdList.forEach((value) => {
                    if (!isEmailExistsInList(verifid_email_options, value)) {
                        verifid_email_options.push(
                            {
                                value: value,
                                label: value
                            }
                        );
                    }
                });
                if (!isEmailExistsInList(verifid_email_options, this.props.app.me.email)) {
                    verifid_email_options.unshift(
                        {
                            value: this.props.app.me.email,
                            label: this.props.app.me.email
                        }
                    );
                }
                verifid_email_options.push(
                    {
                        value: '-1',
                        label: '---' + verifyEmailAddress + '---'
                    }
                );
                emailIds = verifid_email_options.map((emailId, i) => {
                    return (
                        <MenuItem value={emailId.value} title={emailId.label} >{emailId.label} </MenuItem>
                    );
                });
                this.state.template.sender_email = verifid_email_options[0].value;
                this.setState({
                    verifiedEmailIds: emailIds,
                    verifiedEmailOptions: verifid_email_options
                });
            }
            else {
                let response = Promise.resolve(HTTPClient.get(endPoints.AUTOMATION_DESIGNER.GET_VERIFIED_EMAIL_IDS, null));
                response.then((response) => {
                    if (isValidParam(response)) {
                        emailIds = response.verifid_email_ids;
                        /** verify email added by Priyanka */
                        if (emailIds !== null && Array.isArray(emailIds)) {
                            emailIds.forEach((value) => {
                                if (!isEmailExistsInList(verifid_email_options, value)) {
                                    verifid_email_options.push(
                                        {
                                            value: value,
                                            label: value
                                        }
                                    );
                                }
                            });
                            if (!isEmailExistsInList(verifid_email_options, this.props.app.me.email)) {
                                verifid_email_options.unshift(
                                    {
                                        value: this.props.app.me.email,
                                        label: this.props.app.me.email
                                    }
                                );
                            }
                            verifid_email_options.push(
                                {
                                    value: '-1',
                                    label: '---' + verifyEmailAddress + '---'
                                }
                            );
                            emailIds = verifid_email_options.map((emailId, i) => {
                                return (
                                    <MenuItem value={emailId.value} title={emailId.label} >{emailId.label} </MenuItem>
                                );
                            });
                        }
                        if (!isEmailExistsInList(verifid_email_options, this.state.template.sender_email)) {
                            if (this.state.template.sender_name !== constants.CONSTANT_RECORD_OWNER) {
                                this.state.template.sender_email = verifid_email_options[0].value;
                                this.setState({

                                })
                            }
                        }
                        this.setState({
                            verifiedEmailIds: emailIds,
                            verifiedEmailOptions: verifid_email_options
                        });
                    }

                });
            }
        }
        catch (error) {
            console.error("Error in 'automationMosaicoTemplate.js -> generateVerifiedEmailIds():'" + error);
        }
    }

    changeSenderEmail = (event) => {
        let value = event.target.value;
        try {
            if (isValidParam(value)) {
                if (value === '-1') {
                    this.openVerifyEmailPopup();

                }
                this.state.template.sender_email = value;
                this.setState({});
            }
        }
        catch (error) {
            console.error("Error in 'automationTouchCampaign.js -> changeSenderEmail():'" + error);
        }
    }

    openVerifyEmailPopup = () => {
        let verifiedEmailOptions = getArrayParam(this.state.verifiedEmailOptions);
        try {
            let data = { fromName: this.props.app.me.name, verifiedEmailOptions: verifiedEmailOptions };
            this.props.getAppDialog(true, constants.VERIFY_EMAIL_DIALOG, getLocalizedStrings().label.BROADCAST_CAMPAIGNS.VERIFY_EMAIL, null, data, constants.AUTOMATION_DESIGNER_OBJECT);
        }
        catch (error) {
            console.error("Error in 'automationTouchCampaign.js -> openVerifyEmailPopup():'" + error);
        }
    }

    loadVerifiedEmailIds = () => {
        let isReloaded = false;
        try {
            let url = endPoints.BROADCAST_CAMPAIGNS.GET_VERIFIED_EMAIL_IDS;
            let params = {};
            params.getUserMailIds = false;
            let promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    isReloaded = true;
                    this.generateVerifiedEmailIds(isReloaded, response.verified_email_ids);
                }
            });
        }
        catch (error) {
            console.error("Error in 'BroadcastCampaign.js -> loadVerifiedEmailIds():'" + error);
        }
    }

    changeSenderName = () => {
        let sendername = document.getElementById('sender_name').value;
        this.state.template.sender_name = sendername;
        this.setState({});
        if (sendername === constants.CONSTANT_RECORD_OWNER ||
            sendername.trim().replace(/ /g, '').toLowerCase() === constants.CONSTANT_RECORD_OWNER.replace(/ /g, '').toLowerCase()) {
            this.state.template.sender_name = constants.CONSTANT_RECORD_OWNER;
            this.state.template.sender_email = '';

            this.setState({
                isDisableSenderEmail: true,
                isDisableRefresh: true
            });
        } else {
            this.setState({
                isDisableSenderEmail: false,
                isDisableRefresh: false,
            });
            // this.loadVerifiedEmailIds();
            if (this.state.template.sender_email === '') {
                this.state.template.sender_email = this.props.app.me.email;
                let arrayNodes = this.props.automationCampaign.data.nodes;
                let touchNode = arrayNodes.filter(f => (f.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL));
                if (touchNode[0].hasOwnProperty('details')) {
                    let touchNodeDetails = touchNode[0].details[0];
                    if (isValidParam(touchNodeDetails.sender_email) && touchNodeDetails.sender_email !== '') {
                        this.state.template.sender_email = touchNodeDetails.sender_email;
                    }
                }

                let autoNode = getObjectParam(this.props.nodeObject);
                if (autoNode.hasOwnProperty('details')) {
                    let autoNodeDetails = autoNode.details[0];

                    if (isValidParam(autoNodeDetails.sender_email) && autoNodeDetails.sender_email !== '') {
                        this.state.template.sender_email = autoNodeDetails.sender_email;
                    }
                }

                this.setState({});
            }
        }
    }
    openNoteTypeList = (event) => {
        event.preventDefault();
        this.setState({ isOpenNoteTypeList: true, anchorELNoteTypeList: event.currentTarget });
    }
    getStepContent = (stepIndex) => {
        let nodeObj = this.props.nodeObject;
        switch (stepIndex) {
            case 0:
                let personalizeFieldList = this.renderPersonalizeField();
                return (
                    <div>
                        <div>
                            <div style={{ width: '100%' , marginBottom: '13px' }}>
                                
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        label={getLocalizedStrings().label.MARKETING_TEMPLATE.SUBJECT_REQUIRED}
                                        fullWidth={true} name="subject"
                                        value={this.state.template.subject}
                                        onChange={this.handleChange}
                                        onClick={this.focusSubject}
                                        error={this.state.error.subject !== ''}
                                        helperText={this.state.error.subject}
                                        autoComplete="new-password"
                                        margin='dense'
                                        size="small"
                                        className={"sf-fields-bg"}
                                    />
                                    <div title={getLocalizedStrings().label.CAMPAIGNS.PERSONALIZE} style={{ width: '10%',  zIndex: 1,marginTop: '-35px', marginLeft: '96%' }}>
                                        <div style={{  }} onClick={this.openNoteTypeList}>
                                            <Icon style={{ fontSize: '17px', color: 'black', cursor: 'pointer', marginTop: "1px", marginLeft: '-2px' }}>settings</Icon>
                                            <Icon style={{ fontSize: '17px', color: 'black', cursor: 'pointer', marginTop: "1px" }}>keyboard_arrow_down</Icon>
                                        </div>
                                        <Menu
                                            name="personalizeFieldList"
                                            key={'sf-auto-complete-noteTypeList'}
                                            id={"personalizeFieldList"}
                                            style={{ height: '100%' }}
                                            anchorEl={this.state.anchorELNoteTypeList}
                                            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                                            open={this.state.isOpenNoteTypeList}
                                            onClose={() => { this.setState({ isOpenNoteTypeList: false }); }}
                                        >
                                            {personalizeFieldList}
                                        </Menu>
                                    </div>
                                </FormControl>
                            </div>
                        </div>

                        {this.state.template.nodeObject.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL &&
                            <div style={{display: 'flex', width:'100%'}}>
                                <div style={{...styles.sf_6}}>
                                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                        <TextField
                                            variant="outlined"
                                            id="sender_name"
                                            name="sender_name"
                                            value={this.state.template.sender_name}
                                            onChange={() => this.changeSenderName()}
                                            underlinefocusstyle={styles.textField.underlineFocus}
                                            error={this.state.error.sender_name!==''}
                                            helperText={this.state.error.sender_name}
                                            label={getLocalizedStrings().label.CAMPAIGNS.FROM_NAME_REQUIRED}
                                            autoFocus
                                            autoComplete="new-password"
                                            margin='dense'
                                            size="small"
                                            className={"sf-fields-bg"}
                                        /></FormControl>
                                </div>
                                <div style={{...styles.sf_6, marginLeft: '18px'}}>
                                    <FormControl variant="outlined" style={{ width: '85%', marginTop:'8px',height:'38px' }}>
                                        <InputLabel id="sf-automation-touch-campaign-from-result-automationtouchcampaign-simple-select-outlined-label" className='sf-automation-touch-campaign-from-result-automationtouchcampaigns-simple'>{getLocalizedStrings().label.CAMPAIGNS.FROM_EMAIL_REQUIRED}</InputLabel>
                                        <Select
                                            id="senderemail"
                                            value={this.state.template.sender_email}
                                            onChange={this.changeSenderEmail}
                                            className={"sf-fields-bg"}
                                            style={{height:'42px'}}
                                            label={getLocalizedStrings().label.CAMPAIGNS.FROM_EMAIL_REQUIRED}
                                            disabled={this.state.isDisableSenderEmail}
                                        >
                                            {
                                                (this.state.verifiedEmailIds !== null) &&
                                                this.state.verifiedEmailIds
                                            }
                                        </Select>
                                    </FormControl> 
                                    <div style={{ float: 'right', marginTop:' 12px' }}>
                                        <Button
                                            startIcon={<Icon>autorenew</Icon>}
                                            onClick={this.loadVerifiedEmailIds}

                                            disabled={this.state.isDisableRefresh}
                                            style={{
                                                ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                                                color: '#717171',
                                                border: '0px',
                                                minWidth: '0px',
                                                paddingTop: '6px',
                                                marginRight:'4px'
                                            }}
                                            title={getLocalizedStrings().label.REPORT.REFRESH}
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="row">
                            <div className="col-sm-6" style={{ marginTop: '10px', paddingLeft: 0}}>
                                <div className='viewAsWeb' title={getLocalizedStrings().label.CAMPAIGNS.VIEW_AS_WEB_PAGE} style={{ display: 'inline-block', verticalAlign: 'top', paddingTop: '3px', whiteSpace: 'nowrap' }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            color="default"
                                            checked={this.state.template.isCheckViewAsWeb}
                                            value={this.state.template.isCheckViewAsWeb}
                                            onChange={this.onCheckViewAsWeb}
                                        />}
                                        label={getLocalizedStrings().label.CAMPAIGNS.VIEW_AS_WEB_PAGE}
                                        labelposition="right"
                                        style={{ left: "6" , height: 'auto', width: '95%' }}
                                    />

                                </div>
                                <div
                                    title={getLocalizedStrings().label.AUTOMATION_DESIGNER.VIEW_AS_WEBPAGE_INFO}
                                    style={{
                                        display: 'inline-block',
                                        verticalAlign: 'top',
                                        paddingTop: '16px',
                                        marginLeft: '5px',
                                        //paddingRight: '17px'
                                    }}>

                                    <i
                                        className="fa fa-info-circle"
                                        aria-hidden="true"
                                        style={{ cursor: 'pointer' }}
                                        data-tip={getLocalizedStrings().label.AUTOMATION_DESIGNER.VIEW_AS_WEBPAGE_INFO}
                                        data-event='click focus'
                                    />
                                </div>
                                <div style={{
                                    display: 'inline-block',
                                    paddingTop: '4px',
                                    paddingLeft: '10px',
                                }}>
                                    {/* <Button
                                        label={getLocalizedStrings().label.CAMPAIGNS.CHANGE_TEMPLATE}
                                        style={styles.secondaryButton}
                                        onClick={this.changeTemplate}
                                    /> */}
                                </div>
                            </div>

                        </div>
                    </div>

                );
            case 1:
                let iframeSrc = constants.nodeServerURL + "/index.html"; //"./mosaico/index.html";
                let nodeObject = this.state.template.nodeObject;
                if (nodeObject.hasOwnProperty('details')) {
                    if (isValidParam(nodeObject['details'][0])) {
                        let nodeDetails = nodeObject['details'][0];
                        if (nodeDetails.hasOwnProperty('template')) {
                            let template = getObjectParam(nodeDetails['template']);
                            if (isValidParam(template)) {
                                iframeSrc = constants.nodeServerURL + "/editor.html";
                            }
                        }
                    }
                }
                // if (this.state.template.id > 0) {
                //     iframeSrc = constants.nodeServerURL + "/editor.html";
                // }
                return (
                    <div>
                        <div className="row">
                            <div className="col-sm-12" style={{ paddingBottom: '20px' }}>
                                <div style={{ float: 'right' }}>
                                    {this.state.template.editor_type === constants.EDITOR_BEE &&
                                        <Button

                                            style={styles.primaryButton}
                                            onClick={this.handleSave}
                                        >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                                    }

                                    {this.state.template.editor_type === constants.EDITOR_BEE &&
                                        <Button

                                            style={styles.secondaryButton}
                                            onClick={this.handlePreview.bind(this, 'preview')}
                                        >{getLocalizedStrings().label.COMMON.PREVIEW}</Button>
                                    }
                                    {this.state.template.editor_type !== constants.EDITOR_BEE &&
                                        <Button

                                            style={styles.secondaryButton}
                                            onClick={this.handleLegacyPreview}
                                        > {getLocalizedStrings().label.COMMON.PREVIEW}</Button>
                                    }
                                </div>
                            </div>
                        </div>
                        {this.state.template.editor_type === constants.EDITOR_BEE && <div id='bee-plugin-container' style={{marginBottom:'430px'}}></div>}
                        {this.state.template.editor_type === constants.EDITOR_NEW && <MosaicoFrame
                            postMessageData={this.state.template}
                            iframeSrc={iframeSrc}
                            callbackAutomation={this.updateAutomationRedux}
                            callbackSaveSuccess={this.updateState}
                            nodeArray={this.props.automationCampaign.data.nodes} />}
                    </div>
                );

            // let iframeSrc = "./mosaico/index.html";
            // if (this.state.template.id > 0) {
            //     iframeSrc = "./mosaico/editor.html";
            // }
            // return (
            //     <div>
            //         <iframe src={iframeSrc} style={{ width: '100%', height: 'calc(100% - 220px)' }}></iframe>
            //     </div>
            // );

            default:
                return 'Soffront Template Module';
        }
    }

    updateState = (nodeobject) => {
        try {
            let templateObject = nodeobject['details'][0]['template'];
            let _state = this.state;
            _state.template.metadata = templateObject.metadata;
            _state.template.content = templateObject.content;
            _state.template.email_message = templateObject.emailBody;
            _state.template.nodeObject = nodeobject;
            this.setState(_state);
        } catch (error) {
            console.log('Error in AutomationMosaicoTemplate.js at updateState() : ' + error);
        }
    }

    updateAutomationRedux = (arrayNodes, nodeObjectData) => {
        try {
            let campaignId = 0;
            campaignId = this.props.automationCampaign.data.id;
            let detailsArray = nodeObjectData.details[0];
            let isBdayMsg = false
            if (nodeObjectData.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
                isBdayMsg = true;
            }
            if ((detailsArray.hasOwnProperty('message_id') && detailsArray.message_id > 0 && campaignId > 0) || (isBdayMsg && campaignId > 0)) {
                let sourceObject = null;
                if (isBdayMsg) {
                    sourceObject = getObjectParam(getRequiredParentNode(nodeObjectData.childId,
                        constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL));
                } else {
                    sourceObject = getObjectParam(getRequiredParentNode(nodeObjectData.childId,
                        constants.AUTOMATION_DESIGNER_SEND_MAIL));
                }
                if (isValidParam(sourceObject) && sourceObject.hasOwnProperty('details') &&
                    isValidParam(sourceObject['details'])) {
                    let autoResponderId = sourceObject.details[0].autoresponder_id;
                    // detailsArray.startDate = sourceObject.details[0].startDate;
                    // detailsArray.hDate = sourceObject.details[0].hDate;
                    let params = {
                        msg_details: detailsArray,
                        auto_responder_id: autoResponderId,
                    }

                    if (isBdayMsg) {
                        params.is_bday_campaign = true;
                        params.processId = sourceObject.processId;
                    }

                    let promise = Promise.resolve(HTTPClient.post(endPoints.AUTOMATION_DESIGNER.SAVE_MESSAGE, params));
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            // if (response.status === 0) {
                            //     this.props.showCustomSnackBar(getLocalizedStrings().message.AUTOMATION_DESIGNER.MESSAGE_SAVED_SUCCESSFULLY);
                            // } else {
                            //     this.props.showCustomSnackBar(getLocalizedStrings().message.AUTOMATION_DESIGNER.MESSAGE_NOT_SAVED_SUCCESSFULLY);
                            // }
                        }
                        this.props.automationCampaign.data.nodes = arrayNodes;
                        this.props.addNode(this.props.automationCampaign.data);
                    })
                } else {
                    this.props.automationCampaign.data.nodes = arrayNodes;
                    this.props.addNode(this.props.automationCampaign.data);
                }
            } else {
                this.props.automationCampaign.data.nodes = arrayNodes;
                this.props.addNode(this.props.automationCampaign.data);
            }

            // this.props.automationCampaign.data.nodes = arrayNodes;
            // this.props.addNode(this.props.automationCampaign.data);
        } catch (error) {
            console.log('Error in AutomationMosaicoTemplate.js at updateAutomationRedux() : ' + error);
        }
    }

    setTemplateInfoToDomElement = () => {
        if (document.getElementById("templateInfo") === null) {
            let templateInfo = document.createElement("input");
            templateInfo.setAttribute("name", "templateInfo");
            templateInfo.setAttribute("id", "templateInfo");
            templateInfo.setAttribute("type", "hidden");
            templateInfo.setAttribute("value", JSON.stringify(this.state.template));
            document.body.appendChild(templateInfo);
        } else {
            let templateInfo = document.getElementById("templateInfo");
            templateInfo.setAttribute("value", JSON.stringify(this.state.template));
        }
    }

    handleNext = () => {
        try {
            if (!this.validateForm()) {
                return false;
            }
            let stepIndex = this.state.stepIndex;
            if (stepIndex === 0) {
                this.setTemplateInfoToDomElement();
                this.setState({
                    stepIndex: stepIndex + 1,
                    finished: stepIndex >= 2,
                }, () => { this.registerBeeEditor() });
            }
        } catch (error) {
            console.log('Error in AutomationMosaicoTemplate.js at handleNext() : ' + error);
        }
    };


    handlePrev = () => {
        let stepIndex = this.state.stepIndex;
        if (stepIndex > 0) {
            // let promisedialog = new Promise(function (resolve, reject) {
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
                getLocalizedStrings().message.AUTOMATION_DESIGNER.STEPPER_BACK_MESSAGE, this.sfDialogConfirnHandlePrev.bind(this, stepIndex), null);
            // });
            // promisedialog.then((isConfirmed) => {
            //     if (isConfirmed) {
            //         this.setState({ stepIndex: stepIndex - 1 });
            //     }
            // }, function (error) {
            //     console.error(error.message);
            // });
            // this.setState({ stepIndex: stepIndex - 1 });
        }
    }
    handelOnConfirmHandlePrev=(stepIndex)=>{
        this.setState({ stepIndex: stepIndex - 1 });
    }
    sfDialogConfirnHandlePrev = (stepIndex) => {
        this.setState({ stepIndex: stepIndex - 1 });
    }

    handleClose = () => {
        this.props.getAppCustomDrawer(false, null, null, null, null, null, null);
    }

    removeBeeEditorIfExist = () => {
        try {
            if (getObjectParam(this.state.template).editor_type === constants.EDITOR_BEE) {
                let beeDiv = document.getElementById("bee-plugin-container");
                if (beeDiv) {
                    while (beeDiv.firstChild) {
                        beeDiv.removeChild(beeDiv.firstChild);
                    }
                }
            }
        } catch (error) {
            console.error("Error in 'AutomationMosaicoTemplate.js -> removeBeeEditorIfExist()':" + error);
        }
    }

    render() {
        let wrapperStyle = {};
        let themeColor = this.props.app.me.background;
        let contentHeight = window.innerHeight - 200;
        let top = (contentHeight - 10) / 2;
        
        if (this.state.stepIndex === 0) {
            wrapperStyle = { width: '75%', margin: 'auto' }
            this.removeBeeEditorIfExist();
        }

        if (!this.state.mounted) {
            return (
                <div style={{ width: '100%', height: contentHeight }} >
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }} >
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div>
                <div style={{ width: '30%', margin: 'auto', padding: 24 }}>
                    <>
                        <Stepper nonLinear activeStep={this.state.stepIndex} style={{backgroundColor:'#f5f5f5'}} >
                            <Step>
                                <StepLabel onClick={this.handlePrev} style={{ cursor: 'pointer', color: this.state.stepIndex === 1 ? '#717171' : themeColor, fontWeight: 'bold' }}>Template Info</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={this.handleNext}>Design</StepLabel>
                            </Step>
                        </Stepper>
                    </>
                </div>
                <div style={wrapperStyle}>
                    <div style={{ padding: 20, paddingTop: 0, paddingBottom: 0, marginTop: 0 }}>
                        {this.getStepContent(this.state.stepIndex)}
                    </div>
                    {this.state.stepIndex === 0 &&
                        <div style={{ padding: 20, float: 'right' }}>
                            <Button
                                id="next"
                                primary={true}
                                onClick={() => {
                                    this.handleNext();
                                }}
                                style={styles.primaryButton}
                            >{getLocalizedStrings().label.FILTER.NEXT_BUTTON_TEXT}</Button>
                            <Button

                                style={styles.secondaryButton}
                                onClick={this.changeTemplate}
                            >{getLocalizedStrings().label.CAMPAIGNS.CHANGE_TEMPLATE}</Button>
                            <Button
                                id="cancel"

                                primary={false}
                                onClick={() => {
                                    this.handleClose();
                                }}
                                style={styles.secondaryButton}
                            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const MosaicoFrame = ({ postMessageData, iframeSrc, callbackAutomation, callbackSaveSuccess, nodeArray }) => {
    const attributes = {
        src: iframeSrc,
        width: "100%",
        height: (window.innerHeight - 180) + 'px',
        frameBorder: 0,
    };

    const onReceiveMessage = (nodeObject) => {
        let nodeObjectData = nodeObject.data;
        if (isValidParam(nodeObjectData)) {
            if (nodeObjectData.hasOwnProperty('is_new')) {
                delete nodeObjectData['is_new'];
            }
            if (nodeObjectData.hasOwnProperty('callbackAction') && isValidParam(nodeObjectData['callbackAction'])) {
                if (nodeObjectData['callbackAction'] === "saved_success") {
                    callbackSaveSuccess(nodeObjectData);
                    delete nodeObjectData['callbackAction'];
                }
            }
            let arrayNodes = nodeArray;
            let nodeIndex = 0;
            for (let i = 0; i < arrayNodes.length; i++) {
                let objNode = arrayNodes[i];
                if (objNode.childId === nodeObjectData.childId) {
                    nodeIndex = i;
                    break;
                }
            }
            arrayNodes[nodeIndex] = nodeObjectData;
            callbackAutomation(arrayNodes, nodeObjectData);
        }
        // console.log("onReceiveMessage");
    };

    const onReady = () => {
        console.log("onReady");
    };

    return (
        <IframeComm
            attributes={attributes}
            postMessageData={postMessageData}
            handleReady={onReady}
            handleReceiveMessage={onReceiveMessage}
            targetOrigin={constants.nodeServerURL}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        app: state.app,
        automationCampaign: state.automationCampaign,
        margefields: state.sfForm.data.contacts.fields,
        coupons: state.coupons
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addNode: (arrayNodes) => {
            dispatch(addNode(arrayNodes));
        },
        getAppCustomDrawer: (isOpen, dialogName, actionType, style, data, headerProps, minimizable) => {
            dispatch(getAppCustomDrawer(isOpen, dialogName, actionType, style, data, headerProps, minimizable));
        },
        getAppDialog: (isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom) => {
            dispatch(getAppDialog(isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom));
        },
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
        getCouponsAction: (data) => {
            dispatch(getCouponsAction(data));
        }
    }
}

AutomationMosaicoTemplate = connect(mapStateToProps, mapDispatchToProps)(AutomationMosaicoTemplate);

export { MosaicoFrame };
export default AutomationMosaicoTemplate;