import React from 'react';
import { styles } from '../../../services/constants/styles';
import {Card, CardHeader, CardMedia, CardContent, Typography } from '@mui/material';
import { connect } from 'react-redux';


const mapStateToProps = (state) => {
    return {
        app: state.app,

     };
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

 class QuoteTemplate extends React.Component {
        constructor(props) {
            super(props);
            this.state = { 
                logo : null,
            }
        }

        componentDidMount(){
            this.state.logo = this.props.app.me.logo+'.png';
            this.setState({});
        }
        

    render(){
        let containerStyle ={
            backgroundColor : '#fffff',
        }
        return(
            <div style= {styles.sfFormContainer.containerStyle}>
           
                  {/* Company Information part  */}
                <div style={{width: '200px', height: '100px',}}>
                    <Card>
                        <CardHeader title={'soffront'} />
                        <CardContent>
                        <Typography paragraph>
                             {'Adress1'}
                            </Typography >
                            <Typography paragraph>
                             {'Adress2'}
                            </Typography>
                            <Typography paragraph>
                             {'email '}
                            </Typography>
                            <p style={{ lineHeight: '20px', marginTop: -4, textAlign: 'justify' }}></p>
                       </CardContent>
                    </Card>
                </div>
                
                {/* Logo  Part  */}
                <div>
                    <Card>
                        <CardMedia
                            // className={classes.media}
                            image={this.state.logo}
                            title="Icon"
                        />
                    </Card>
                </div>

                {/* Bill Details  */}
                <div>
                    <Card style={{ marginTop: '100px' }}>
                        <CardContent expandable={false} style={{ paddingTop: 10, paddingBottom: 0 }}>
                        <Typography paragraph>
                              {'Invoice'}
                            </Typography>
                            <Typography paragraph>
                              {'Bill To '} 
                           </Typography >
                           <Typography paragraph>
                              {'Adress2'}
                            </Typography>
                            <Typography paragraph>
                              {'email '}
                            </Typography>
                            <p style={{ lineHeight: '20px', marginTop: -4, textAlign: 'justify' }}></p>
                        </CardContent>
                     </Card>
                </div>

                {/*Invoice  Numbers and Quote Numbers  */}
                <div>

                </div>

            </div>
        )
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteTemplate);

