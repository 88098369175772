import { actions } from '..//constants/actions';

const operationsReportsInitialState = {
    operationsReportData: {},
    dueByData:{},
}
const operationsReportReducer = (state = operationsReportsInitialState, action) => {
    switch (action.type) {
        case actions.OPERATIONS_REPORT_DATA_UPDATE:
            return {
                ...state,
                operationsReportData: action.payload
            }
        case actions.UPDATE_OPERATIONS_DUE_BY_DATA:
            return {
                ...state,
                dueByData : action.payload
            }
        default:
            return state;
    }
}
export default operationsReportReducer;