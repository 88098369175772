import { actions } from '..//constants/actions';

const rroyaltyReportInitialState = {
    royaltyReportData: {},
    royaltyReportV2Data: {},
}
const royaltyReportReducer = (state = rroyaltyReportInitialState, action) => {
    switch (action.type) {
        case actions.ROYALTY_REPORT_DATA_UPDATE:
            return {
                ...state,
                royaltyReportData : action.payload,
            }
        case actions.ROYALTY_REPORT_V2_DATA_UPDATE:
            return {
                ...state,
                royaltyReportV2Data : action.payload,
            }
        default:
            return state;
    }
}
export default royaltyReportReducer;