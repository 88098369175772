import React from 'react';
import PropTypes from 'prop-types';
import { styles } from '../../../services/constants/styles';
import { isValidParam, getStringParam, getArrayParam, getObjectParam, getBooleanParam, getIntParam, isInteger } from '../../../services/helper/parameterVerifier';
import * as sfDialogs from '../components/sfDialogs';
import { Icon } from '@mui/material';
import ShowCircularProgress from '../components/circularProgress';
import { getOutBoundPhoneList, verifyOutboundPhone, cancelVerficationCall, deleteOutboundPhone, checkDuplicatePhoneNumber, getVerficationStatus, postProcessVerification, enableOrDisable } from '../../../services/actions/callAction';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Button from '@mui/material/Button';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants } from '../../../services/constants/constants';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const CallSetup = () => {
    const [setupList, setSetupList] = useState([]);
    const [isEnable, setIsEnable] = useState(false);
    const [isPhoneNumberSaved, setIsPhoneNumberSaved] = useState(false);

    const app = useSelector((state) => state.app);
    const sfFormFields = useSelector((state) => state.sfForm.data);

    useEffect(() => {
        let onLoadStateData = getOnLoad();
        let _defaultSelectedObj = constants.ACCOUNTS_OBJECT;
        getFormFields(_defaultSelectedObj);
        if (isValidParam(onLoadStateData)) {
            onLoadStateData = onLoadStateData;
        }
    }, []);

    const getOnLoad = () => {
        let countryDetails = {};
        try {
            getOutBoundPhoneListData();
            let countryISO2Arr = JSON.parse(JSON.stringify(getArrayParam(app.countryISO2)));
            const _tenantCountry = getStringParam(app.me.tenant_country);
            countryISO2Arr = countryISO2Arr.filter(f => { return f.t_country == _tenantCountry });
            const _countryISO2 = countryISO2Arr.length > 0 ? countryISO2Arr[0].t_country_iso2 : '';
            const _countries = [_countryISO2];
            countryDetails.countryISO2 = _countryISO2;
            countryDetails.countries = _countries;
        } catch (error) {
            console.error("Error in 'callSetup.js -> getTenantCountry()':" + error);
        }
        return countryDetails;
    }

    const getOutBoundPhoneListData = () => {
        try {
            let promise = Promise.resolve(getOutBoundPhoneList());
            let _isEnable = isEnable;
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        response = getObjectParam(response);
                        let outboundPhones = getArrayParam(response.outbound_phones);
                        setSetupList([...outboundPhones]);
                        _isEnable = outboundPhones.length > 0 && isValidParam(outboundPhones[0]) ? getBooleanParam(outboundPhones[0].is_enable) : false;
                        if (outboundPhones.length > 0) {
                            setIsPhoneNumberSaved(true);
                            setIsEnable(_isEnable);
                        } else {
                            onToggle(true);
                        }
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'callSetup.js -> getOutBoundPhoneList()':" + error);
        }
    }


    const updatePhoneDetails = (index, phoneDetails) => {
        try {
            let _setupList = getArrayParam(setupList);
            _setupList.splice(index, 1, phoneDetails);
            setSetupList([..._setupList]);
        } catch (error) {
            console.error("Error in 'callSetup.js -> updatePhoneDetails()':" + error);
        }
    }

    const deleteOutboundPhoneData = (index) => {
        let params = null;
        try {
            let _setupList = getArrayParam(setupList);
            if (isValidParam(index) && index >= 0 && index < _setupList.length) {
                let isDeleteFromTwilio = true;
                let details = getObjectParam(_setupList[index]);
                let callerIdSid = getStringParam(details.callerid_sid).trim();
                let callStartTime = '';
                if (callerIdSid == '') {
                    callStartTime = getStringParam(details.call_start_time);
                    isDeleteFromTwilio = false;
                }
                params = {};
                params.callerid_sid = callerIdSid;
                params.call_start_time = callStartTime;
                params.is_delete_from_twilio = isDeleteFromTwilio;
            }
            if (isValidParam(params)) {
                let message = getLocalizedStrings().message.TWILIO.DELETE_PHONE_NUMBER;
                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, message, () => deleteOutboundPhoneOnConfirm(params), null);
            }
        } catch (error) {
            console.error("Error in 'callSetup.js -> deleteOutboundPhone()':" + error);
        }
    }

    const deleteOutboundPhoneOnConfirm = (params) => {
        let deletePromose = Promise.resolve(deleteOutboundPhone(params));
        if (isValidParam(deletePromose)) {
            deletePromose.then(response => {
                if (isValidParam(response) && getBooleanParam(response)) {
                    getOutBoundPhoneListData();
                    setIsEnable(true);
                }
            });
        }
    }

    const getPhoneNosElement = () => {
        let _setupList = [];
        let elements = [];
        try {
            let countryISO2Arr = JSON.parse(JSON.stringify(getArrayParam(app.countryISO2)));
            const _tenantCountry = getStringParam(app.me.tenant_country);
            countryISO2Arr = countryISO2Arr.filter(f => { return f.t_country == _tenantCountry });
            const _countryISO2 = countryISO2Arr.length > 0 ? countryISO2Arr[0].t_country_iso2 : '';
            const _countries = [_countryISO2];

            _setupList = getArrayParam(setupList);
            if (_setupList.length === 0) {
                const _phone = {};
                _phone.id = -999;
                _phone.phone_number = '';
                _phone.extension = '';
                _phone.is_enable = true;
                _phone.status = '';
                _phone.callerid_sid = '';
                _phone.call_start_time = '';
                _phone.is_call_back_flag = false;
                _phone.is_saved = false;
                _setupList.push(_phone);
                setSetupList([..._setupList]);
            }
            _setupList.forEach((phDtls, index) => {
                if (isValidParam(phDtls) && getBooleanParam(phDtls.is_enable)) {
                    elements.push(<PhoneNoDetails key={phDtls.id} index={index} countries={_countries} countryISO2={_countryISO2} details={phDtls} updatePhoneDetails={(index, phoneDetails) => updatePhoneDetails(index, phoneDetails)} deleteOutboundPhone={() => deleteOutboundPhoneData(index)} />)
                }
            });
        } catch (error) {
            console.error("Error in 'callSetup.js -> getPhoneNosElement()':" + error);
        }
        return elements;
    }

    const addNewPhone = () => {
        try {
            let _setupList = getArrayParam(setupList);
            let tempArr = _setupList.filter(f => { return f.status != constants.ACTIVE });
            let isAllVerified = tempArr.length > 0 ? false : true;
            if (!isAllVerified) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.TWILIO.VERIFY_PHONE_NUMBER, null);
            }
            else {
                const _phone = {};
                _phone.id = -999;
                _phone.phone_number = '';
                _phone.extension = '';
                _phone.is_enable = true;
                _phone.status = '';
                _phone.callerid_sid = '';
                _phone.call_start_time = '';
                _phone.is_call_back_flag = false;
                _phone.is_saved = false;
                _setupList.push(_phone);
                setSetupList([..._setupList]);
            }
        } catch (error) {
            console.error("Error in 'callSetup.js -> addNewPhone()':" + error);
        }
    }

    const onToggle = (isInputChecked) => {
        let _setupList = getArrayParam(setupList);
        setIsEnable(isInputChecked);
        if (isInputChecked && _setupList.length === 0) {
            addNewPhone();
        } else {
            return;
        }
        if (getBooleanParam(isPhoneNumberSaved)) {
            let params = {};
            params.is_enable = isInputChecked;
            let promise = Promise.resolve(enableOrDisable(params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        getOutBoundPhoneListData();
                    }
                });
            }
        }
    }

    const getFormFields = (object) => {
        let _formFileds = populateFields(object);
        generateFormFieldList(_formFileds);
    }
    const generateFormFieldList = (_formFileds) => {
        var options = [];
        let arrayFormFieldList = _formFileds;
        if (isValidParam(arrayFormFieldList) && arrayFormFieldList.length > 0) {
            arrayFormFieldList.map((fields, i) => {
                let fieldObj = {};
                fieldObj.value = fields.name;
                fieldObj.name = fields.name;
                fieldObj.label = fields.label;
                fieldObj.title = fields.label;
                options.push(fieldObj);
            }
            );
        }
        return options;
    }
    const populateFields = (object) => {
        let formFields = sfFormFields[object].fields;
        formFields = formFields.filter(f => {
            return (f.field_type !== "header" && f.field_type !== "" && f.field_type !== 'integer'
                && !f.is_hidden_field && !f.is_readonly && f.name !== "workflow_name" && f.name !== "opportunity_amount" &&
                f.name !== "sales_rep" && f.name !== "t_type" && f.name !== "lead_score" && f.field_type !== "datetime" && f.field_type !== "memo" && f.name !== "visible_to" && f.name !== "main_contact_id");
        });
        sortArrayObjectByProperty(formFields, 'label');
        return formFields;
    }
    const getElements = () => {
        let elements = [];
        try {
            elements.push(<div><span style={{ fontWeight: 'bold', fontSize: '15px' }}>{getLocalizedStrings().label.TWILIO.ADD_NUMBERS}</span></div>);
            let tempIsEnable = getBooleanParam(isEnable);
            if (tempIsEnable) {
                let phoneNoElements = getPhoneNosElement();
                elements.push(<div key='out-bound-label' style={{ marginTop: '10px' }}><span>{getLocalizedStrings().label.TWILIO.OUTBOUND_PHONE_NUMBERS}</span></div>);
                elements.push.apply(elements, phoneNoElements);
                elements.push(<div key='add-new' style={{ margin: '10px 0px 15px 0px' }}>
                    <a href='javascript:void(0);' onClick={() => addNewPhone()}>{getLocalizedStrings().label.TWILIO.ADD_ANOTHER_NUMBER}</a>
                </div>);
            }
        } catch (error) {
            console.error("Error in 'callSetup.js -> getElements()':" + error);
        }
        return elements;
    }

    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight - 10) / 2;
    let elements = getElements();
    if (elements.length > 0) {
        return <div>{elements}</div>;
    } else {
        return <div style={{ width: '100%', height: pageHeight }}>
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
        </div>
    }
}

const PhoneNoDetails = ({ index, countries, countryISO2, details, updatePhoneDetails, deleteOutboundPhone }) => {
    const [phoneDetails, setPhoneDetails] = useState(null);
    const [countryDetails, setCountryDetails] = useState(null);
    const [numberIsVerifying, setNumberIsVerifying] = useState(false);
    const [allowDelete, setAllowDelete] = useState(false);
    const app = useSelector((state) => state.app);

    useEffect(() => {
        let _details = phoneDetails;
        _details = getObjectParam(details);
        setPhoneDetails(_details);
    }, []);

    useEffect(() => {
        if (phoneDetails != null && phoneDetails != undefined) {
            setPhoneDetails(phoneDetails);
        }
    }, [phoneDetails]);


    const onPhoneNumberChange = (value, countryDetails, number, id) => {
        try {
            let _countryDetails = getObjectParam(countryDetails);
            value = getStringParam(value);
            if (value !== '' || isValidPhoneNo(value)) {
                let _details = getObjectParam(phoneDetails);
                let phone_number = null;
                let countryCode = _countryDetails.dialCode;
                phone_number = value.split(countryCode)[1];
                phone_number = phone_number.trim();
                _details.phone_number = countryCode + phone_number;
                setPhoneDetails(_details);
                setCountryDetails(_countryDetails);
            }
        } catch (error) {
            console.error("Error in 'callSetup.js -> onPhoneNumberChange()':" + error);
        }
    };

    const prepareSelectedNumbers = (value) => {
        let processedNo = null
        if (value.status == constants.ACTIVE || value.status == "Pending"
            || value.status == constants.INACTIVE) {
            let _countryDialCode = getTenantCountryDialCode();
            processedNo = _countryDialCode + value.phone_number;
        }
        else {
            processedNo = value.phone_number
        }
        return processedNo;
    }

    const getTenantCountryDialCode = () => {
        let country = getStringParam(app.me.tenant_country);
        let dialCode = "";
        if (country === "USA" || country === "Canada") {
            dialCode = "1";
        } else if (country === "India") {
            dialCode = "91";
        } else if (country === "United Kingdom") {
            dialCode = "44";
        } else if (country === "Germany") {
            dialCode = "49";
        } else if (country === "France") {
            dialCode = "33";
        } else if (country === "Spain") {
            dialCode = "34";
        } else if (country === "Netherlands") {
            dialCode = "31";
        } else if (country === "Australia") {
            dialCode = "61";
        }
        return dialCode;
    }

    const isValidPhoneNo = (phoneNo) => {
        let isValid = false;
        let match = /^[0-9]+$/;
        phoneNo = getStringParam(phoneNo);
        if (phoneNo.match(match)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid;
    }

    const onChangeExt = (event) => {
        let extension = event.target.value;
        let _details = getObjectParam(phoneDetails);
        if (extension === '' || (extension.length <= 6 && isInteger(extension))) {
            _details.extension = extension;
        } else {
            _details.extension = getStringParam(_details.extension);
        }
        setPhoneDetails({ ..._details });
    }
    const cancelVerifying = () => {
        setAllowDelete(true);
        setNumberIsVerifying(false);
    }

    const checkDuplicatePhoneNumberData = () => {
        try {
            const _phoneDetails = getObjectParam(phoneDetails);
            const _isSaved = getBooleanParam(_phoneDetails.is_saved);
            const _countryDetails = getObjectParam(countryDetails);
            let _countryCode = getStringParam(_countryDetails.dialCode);
            if (_isSaved == false) {
                let _phoneNumber = getStringParam(_phoneDetails.phone_number).trim();
                if (_phoneNumber != '') {
                    _countryCode = isInteger(_countryCode) ? parseInt(_countryCode) : 0;
                    const _phoneNumberList = _phoneDetails.phone_number.split(_countryCode);
                    const __phoneNumbers = [];
                    _phoneNumberList.map((e, i) => {
                        if (i > 0) {
                            __phoneNumbers.push(e);
                        }
                    })
                    _phoneNumber = __phoneNumbers.join(_countryCode).trim();
                    const params = {};
                    params.country_code = _countryCode;
                    params.phone_number = getStringParam(_phoneNumber).trim();
                    params.extension = getStringParam(_phoneDetails.extension).trim();

                    let promise = Promise.resolve(checkDuplicatePhoneNumber(params));
                    if (isValidParam(promise)) {
                        promise.then(response => {
                            if (isValidParam(response) && response.hasOwnProperty('is_duplicate')) {
                                const _isDuplicate = getBooleanParam(response.is_duplicate);
                                const data = {};
                                data.status = _isDuplicate;
                                processCheck(data);
                            }
                        });
                    }
                } else {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.TWILIO.PHONE_BLANK_MESSAGE, null);
                }
            } else {
                const data = {};
                data.status = false;
                processCheck(data);
            }
        } catch (error) {
            console.error("Error in 'callSetup.js -> checkDuplicatePhoneNumberData()':" + error);
        }

    }

    const processCheck = (data) => {
        const _data = getObjectParam(data);
        const _status = _data.status;
        if (_status === true) {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.TWILIO.PHONE_ALREADY_CONFIGURE, null);
        } else {
            openVerificationPopUp();
        }
    };

    const openVerificationPopUp = () => {
        setNumberIsVerifying(true);
    }

    if (!isValidParam(phoneDetails)) {
        return null;
    }

    let status = getStringParam(phoneDetails.status);
    return <div>
        <div style={{ display: 'table', width: '100%' }} >
            <div style={{ display: 'table-cell', width: '30%' }}>
                <PhoneInput
                    country={countryISO2}
                    countryCodeEditable={false}
                    value={prepareSelectedNumbers(phoneDetails)}
                    onChange={(value, countryDetails, number, id) =>
                        onPhoneNumberChange(value, countryDetails, number, id)}
                    onlyCountries={getArrayParam(countries)}
                    preferredCountries={[]}
                    inputStyle={{ height: '41px', width: '224px' }}
                    disabled={status == constants.ACTIVE ? true : false}
                />
                {numberIsVerifying && <VerifyingPopup index={index} phoneDetails={phoneDetails} cancelVerifying={() => cancelVerifying()} countryDetails={countryDetails} updatePhoneDetails={(index, phoneDetails) => updatePhoneDetails(index, phoneDetails)} getTenantCountryDialCode={() => getTenantCountryDialCode()} />}
            </div>
            <div style={{ display: "inline-grid", width: "100%", padding: '0px 10px' }}>
                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                    <TextField key={'input-' + phoneDetails.phone_number}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        placeholder='Ext'
                        value={phoneDetails.extension}
                        onChange={(event) => onChangeExt(event)}
                        disabled={status === constants.ACTIVE ? true : false}
                        autoComplete="new-password"
                        className={"sf-fields-bg"}
                    />
                </FormControl>
            </div>

            <div style={{ display: 'table-cell', width: '18%' }}>
                {status === constants.ACTIVE && <span style={{ color: 'green' }}>{getLocalizedStrings().label.TWILIO.VERIFIED}</span>}
                {status !== constants.ACTIVE && <Button
                    disabled={getBooleanParam(numberIsVerifying)}
                    onClick={() => checkDuplicatePhoneNumberData()}
                    style={styles.primaryButton} > {getLocalizedStrings().label.TWILIO.VERIFY}</Button>}
            </div>

            <div style={{ display: 'table-cell' }}>
                {(status === constants.ACTIVE || status === constants.INACTIVE || allowDelete) && <span id={'icon-' + phoneDetails.phone_number} style={{ display: 'block', }}><Icon onClick={() => deleteOutboundPhone(index)} style={{ cursor: 'pointer', verticalAlign: 'middle', marginRight: '73px' }} >delete</Icon></span>}
            </div>
        </div>
    </div>;
}
PhoneNoDetails.propTypes = {
    key: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    countries: PropTypes.array.isRequired,
    countryISO2: PropTypes.string.isRequired,
    details: PropTypes.object.isRequired,
    updatePhoneDetails: PropTypes.func.isRequired,
    deleteOutboundPhone: PropTypes.func.isRequired,
}

const VerifyingPopup = ({ index, phoneDetails, cancelVerifying, countryDetails, getTenantCountryDialCode, updatePhoneDetails }) => {
    const [isCalling, setIsCalling] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [callStartTime, setCallStartTime] = useState(null);
    const popStyle = {
        marginTop: '4px',
        borderRadius: "5px",
        width: "258px",
        display: "grid",
        background: "rgb(255, 255, 255)",
        height: "100px",
        border: "1px solid rgb(180 179 179)",
        padding: "5px",
        boxShadow: "rgb(222, 222, 222) 0px 0px 8px",
        position: "fixed",
        zIndex: 1000,
        justifyContent: "space-around",
        alignItems: "center",
        alignContent: "space-between"
    }
    const verifyOutboundPhoneData = () => {
        try {
            if (!isCalling) {
                const _phoneDetails = getObjectParam(phoneDetails);
                const _countryCode = getStringParam(getTenantCountryDialCode());
                const _extension = getStringParam(_phoneDetails.extension).trim();
                const isSaved = getBooleanParam(_phoneDetails.is_saved);
                let _phoneNumber = getStringParam(_phoneDetails.phone_number).trim();
                _phoneNumber = _phoneNumber.trim();
                if (_phoneNumber.startsWith(_countryCode)) {
                    _phoneNumber = _phoneNumber.substring(_countryCode.length);
                }
                if (_countryCode !== '' && _phoneNumber !== '') {
                    let callStartTime = new Date().getTime().toString();
                    setCallStartTime(callStartTime);
                    setIsCalling(true);
                    const params = {};
                    params.country_code = _countryCode;
                    params.phone_number = _phoneNumber;
                    params.extension = _extension;
                    params.is_saved = isSaved;
                    params.call_start_time = callStartTime;
                    const promise = Promise.resolve(verifyOutboundPhone(params));
                    if (isValidParam(promise)) {
                        promise.then(response => {
                            if (isValidParam(response)) {
                                showVerifyCode(response);
                            }
                        });
                    }
                } else {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.TWILIO.PHONE_BLANK_MESSAGE, null);
                }
            }

        } catch (error) {
            console.error("Error in 'callSetup.js -> verifyOutboundPhoneData()':" + error);
        }
    }

    const showVerifyCode = (data) => {
        try {
            let _countryDetails = getObjectParam(countryDetails);
            let _phoneDetails = getObjectParam(phoneDetails);
            let _phoneNumber = getStringParam(_phoneDetails.phone_number);
            let countryCode = getStringParam(_countryDetails.dialCode);
            _phoneNumber = _phoneDetails.phone_number.split(countryCode)[1];
            data = getObjectParam(data);
            const _verificationCode = getIntParam(data.verification_code);
            let calleridSid = getStringParam(data.callerid_sid).trim();
            if (_verificationCode != '' && calleridSid != '') {
                _phoneDetails.callerid_sid = calleridSid;
                updatePhoneDetails(index, _phoneDetails);
                setVerificationCode(_verificationCode);
            }
            if (_phoneNumber != '') {
                getVerficationStatusData(_phoneNumber);
            }
        } catch (error) {
            console.error("Error in 'callSetup.js -> showVerifyCode()':" + error);
        }
    }
    function getVerficationStatusData(_phoneNumber) {
        // Wait for 40 seconds before starting
        setTimeout(() => {
          // Initialize the number of attempts
          let attempts = 0;
          // Create an interval to call the backend function every 5 seconds
          const intervalId = setInterval(() => {
            if (attempts < 5) {
                const params = {};
                    params.phone_number = _phoneNumber;
                    params.call_start_time = getStringParam(callStartTime);
                    const promise = Promise.resolve(getVerficationStatus(params));
                    if (isValidParam(promise)) {
                        promise.then(response => {
                            if (isValidParam(response)) {
                                const arr = getArrayParam(response);
                                const obj = arr.length > 0 ? arr[0] : null;
                                if (isValidParam(obj) && obj.hasOwnProperty('verification_status')) {
                                    const callBackFlag = getIntParam(obj.callback_flag);
                                    if (callBackFlag === 1) {
                                        clearInterval(intervalId);
                                        postProcessVerificationDetails(_phoneNumber);
                                    }
                                }
                            }
                        });
                    }
              attempts++;
            } else {
              // Stop the interval after 10 attempts
              setIsCalling(false);
                    cancelVerifying();
              clearInterval(intervalId);
            }
          }, 50000); // 10 seconds interval
      
        }, 20000); // Wait for 40 seconds
      }

    const postProcessVerificationDetails = (_phoneNumber) => {
        try {
            setIsCalling(false);
            const params = {};
            params.phone_number = _phoneNumber;
            const promise = Promise.resolve(postProcessVerification(params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        const _phoneDetails = getObjectParam(phoneDetails);
                        _phoneDetails.callerid_sid = response.callerid_sid;
                        _phoneDetails.status = constants.ACTIVE;
                        _phoneDetails.is_saved = true;
                        updatePhoneDetails(index, _phoneDetails);
                        cancelVerifying();
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'callSetup.js -> postProcessVerification()':" + error);
        }
    }


    const cancelVerficationCallData = () => {
        try {
            const _phoneDetails = getObjectParam(phoneDetails);
            const _calleridSid = getStringParam(_phoneDetails.callerid_sid);
            const params = {};
            params.callerid_sid = _calleridSid;
            const promise = Promise.resolve(cancelVerficationCall(params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response) && getBooleanParam(response)) {
                        setIsCalling(false);
                        _phoneDetails.call_start_time = callStartTime;
                        _phoneDetails.is_saved = true;
                        updatePhoneDetails(index, _phoneDetails);
                        cancelVerifying();
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'callSetup.js -> cancelVerficationCallData()':" + error);
        }
    }

    const getView = () => {
        try {
            let element = null;
            let message = '';
            const _isCalling = getBooleanParam(isCalling);
            if (_isCalling) {
                message = getLocalizedStrings().message.TWILIO.VERIFICATION_CODE;
            } else {
                const _phoneDetails = getObjectParam(phoneDetails);
                const _countryDialCode = getTenantCountryDialCode()
                let _phoneNumber = _phoneDetails.phone_number;
                if (_phoneNumber.startsWith(_countryDialCode)) {
                    _phoneNumber = _phoneNumber.substring(_countryDialCode.length);
                }
                _phoneNumber = getStringParam(_countryDialCode + _phoneNumber).trim();
                const _extension = getStringParam(_phoneDetails.extension).trim();
                _phoneNumber = '+' + _phoneNumber;
                if (_extension === '') {
                    message = getLocalizedStrings().message.TWILIO.VERIFICATION_MESSAGE_1 + ' ' + _phoneNumber + ' ' + getLocalizedStrings().message.TWILIO.VERIFICATION_MESSAGE_2;
                } else {
                    message = getLocalizedStrings().message.TWILIO.VERIFICATION_MESSAGE_1 + ' ' + _phoneNumber + ' ext ' + _extension + ' ' + getLocalizedStrings().message.TWILIO.VERIFICATION_MESSAGE_2;
                }
            }
            element = [<div><span>{message}</span></div>,
            <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}><span>{getStringParam(verificationCode)}</span></div>];
            return element;
        } catch (error) {
            console.error("Error in 'callSetup.js -> cancelVerficationCallData()':" + error);
        }
    }
    return <div style={{ ...popStyle }}>
        <div style={{ textAlign: "center" }}>
            {getView()}
        </div>
        <div style={{ textAlign: 'center' }}>
            {!isCalling &&
                <div style={{ width: '68%', marginLeft: '15%', fontSize: "13px" }}>
                    <a href='/#' onClick={() => verifyOutboundPhoneData()} style={{ cursor: 'pointer' }}><Icon style={{ float: 'left', fontSize: '13px', marginTop: '4px' }}>call</Icon><div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={getLocalizedStrings().label.TWILIO.CLICK_TO_CALL}>{getLocalizedStrings().label.TWILIO.CLICK_TO_CALL}</div></a>
                </div>}
            {isCalling && <span style={{ width: '68%' }}>{getLocalizedStrings().label.TWILIO.CALLING}<img src='/asset/images/processing.gif' style={{ height: '10px', width: '50px' }} /></span>}
            {isCalling &&
                <a href='/#' onClick={() => cancelVerficationCallData()} style={{ marginLeft: '5%' }}>{getLocalizedStrings().label.COMMON.CANCEL}</a>}
        </div>
    </div>;
}

export default CallSetup;
VerifyingPopup.propTypes = {
    index: PropTypes.number.isRequired,
    phoneDetails: PropTypes.object.isRequired,
    cancelVerifying: PropTypes.func.isRequired,
    countryDetails: PropTypes.object.isRequired,
    updatePhoneDetails: PropTypes.func.isRequired
}
