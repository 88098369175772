import React from "react";
import { connect } from "react-redux";
import $ from "jquery";
import {
	getDashboard,
	getDashboardURL,
	setDashBoardRedirection,
	customDrillDown,
	getDashboardWidgets,
} from "../../../services/actions/dashboardAcions";
import { Chart } from "../components/chart";
import { Query } from "../components/query";
import { styles } from "../../../services/constants/styles";
import { constants } from "../../../services/constants/constants";
import { endPoints } from "../../../services/constants/endPoints";
import ShowCircularProgress from "../components/circularProgress";
import { setHeader } from "../../../services/actions/headerActions";
import { Navigate } from "react-router-dom";
import map from "lodash/map";
import * as HTTPClient from "../../../services/helper/httpClient";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { CustomReport } from "../components/customReport";
import {
	isValidParam,
	getBooleanParam,
	getStringParam,
	getArrayParam,
} from "../../../services/helper/parameterVerifier";
import {
	getActiveTab,
	getActiveTabInfo,
} from "../../../services/helper/sfTabManager";
import Select from "react-select";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import {
	DashboardLayoutComponent,
	PanelsDirective,
	PanelDirective,
} from "@syncfusion/ej2-react-layouts";
import { Responsive, WidthProvider } from "react-grid-layout";
import {  Icon } from '@mui/material';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
const ResponsiveReactGridLayout = WidthProvider(Responsive);

// const ResponsiveReactGridLayout = 0;

let options = [
	{ value: "td", label: getLocalizedStrings().label.DASHBOARD.TODAY },
	{ value: "yd", label: getLocalizedStrings().label.DASHBOARD.YESTERDAY },
	{ value: "tw", label: getLocalizedStrings().label.DASHBOARD.THIS_WEEK },
	{ value: "tm", label: getLocalizedStrings().label.DASHBOARD.THIS_MONTH },
	{ value: "tq", label: getLocalizedStrings().label.DASHBOARD.THIS_QUARTER },
	{ value: "ty", label: getLocalizedStrings().label.DASHBOARD.THIS_YEAR },
	{ value: "lw", label: getLocalizedStrings().label.DASHBOARD.LAST_WEEK },
	{ value: "lm", label: getLocalizedStrings().label.DASHBOARD.LAST_MONTH },
	{ value: "lq", label: getLocalizedStrings().label.DASHBOARD.LAST_QUARTER },
	{ value: "ly", label: getLocalizedStrings().label.DASHBOARD.LAST_YEAR },
];

let fields = { text: "label", value: "value" };
const exportingDataConstant = (reportName) => {
	return {
		exporting: {
			buttons: {
				contextButton: {
					_titleKey: "mytoolTip",
					menuItems: [
						{
							text: "Print Report",
							onclick: function () {
								this.print();
							},
						},
						{
							separator: true,
						},
						{
							text: "Download PNG Image",
							onclick: function () {
								this.exportChart({ type: "image/png", filename: reportName });
							},
						},
						{
							text: "Download JPEG Image",
							onclick: function () {
								this.exportChart({ type: "image/jpeg", filename: reportName });
							},
						},
						{
							text: "Download PDF Image",
							onclick: function () {
								this.exportChart({
									type: "application/pdf",
									filename: reportName,
								});
							},
						},
						{
							text: "Download SVG Image",
							onclick: function () {
								this.exportChart({
									type: "image/svg+xml",
									filename: reportName,
								});
							},
						},
					],
				},
			},
		},
		lang: {
			mytoolTip: "Print/Download Report",
		},
	};
};

class Dashboard extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isFranchisor: false,
			hasFranchiseList: false,
			mounted: false,
			franchise: "all",
			franchiseList: [],
			time_line: "tm",
			isQuickSight: false,
			isEditMode: false,
			newWidgetsToAdd: null,
			numberOfColumns: 1,
		};
		this.isAdmin = true;
		this.dashboardObj = null;
	}
	defineGadgetPosition = (dashboardData) => {
		let h = 8;
		//let w = 12;
		let x = 0;
		let y = 0;
		let c = 0;
		let k = 0;
		let r = 1;
		if (dashboardData !== undefined && dashboardData !== null) {
			dashboardData.sort(function (a, b) {
				return a.i - b.i;
			});
			if (this.props.app.selectedModuleName === constants.MODULE_SALES) {
				let colSize = 0;
				let isMoreCol = false;
				dashboardData.forEach(function (l, i) {
					let dataList = l.details.data;
					if (l.details.column !== null && l.details.column !== undefined) {
						colSize = JSON.stringify(l.details.column.length);
						if (!isMoreCol && colSize > 3) {
							isMoreCol = true;
						}
					}
					try {
						if (dataList !== null && dataList !== undefined) {
							dataList.sort(function (a, b) {
								if (a.Users.toLowerCase() < b.Users.toLowerCase()) {
									return -1;
								}
								if (a.Users.toLowerCase() > b.Users.toLowerCase()) {
									return 1;
								}
								return 0;
							});
						}
					} catch (error) {
						console.log("Error on Dashboad.js -> sort function" + error);
					}
					dataList = "";
				});
				// w = isMoreCol ? 12 : 4;
			}

			let isInxpress = getBooleanParam(this.props.app.me.is_inxpress);
			if (isInxpress) {
				dashboardData.forEach(function (l, i) {
					k = "widget_" + (k + 1);
					l.x = x;
					l.y = y;
					l.h = h;
					l.w = 4;
					l.isDraggable = false;
					try {
						if (l.details.chart.type === "pie") {
							l.details.plotOptions.pie = { size: 200 };
							l.details.chart.height = "250px";
						}
						if (l.details.chart.type === "column") {
							l.details.chart.height = "260px";
						}
						l.details.title.text = "";
					} catch (ex) {}

					if (c >= 2) {
						y = y + 8;
						x = 0;
						c = 0;
					} else {
						x = x + 4;
						c = c + 1;
					}
				});
			} else if (
				this.props.app.selectedModuleName === constants.MODULE_MARKETING
			) {
				c = 1;
				dashboardData.forEach(function (l, i) {
					k = "widget_" + (k + 1);
					l.x = x;
					l.y = y;
					l.h = h;
					l.w = 6;
					l.isDraggable = false;
					try {
						if (l.details.chart.type === "pie") {
							l.details.plotOptions.pie = { size: 200 };
							l.details.chart.height = "250px";
						}
						if (l.details.chart.type === "column") {
							l.details.chart.height = "260px";
						}
						l.details.title.text = "";
					} catch (ex) {}
					if (c % 2 === 0) {
						y = y + 8;
						x = 0;
						c = 1;
					} else {
						x = x + 6;
						c = c + 1;
					}
				});
			} else if (this.props.app.selectedModuleName === constants.MODULE_SALES) {
				c = 1;
				dashboardData.forEach(function (l, i) {
				  k = "widget_" + (k + 1);
				  l.x = x;
				  l.y = y;
				  l.h = h;
			  
				  l.isDraggable = false;
				  try {
					if (l.details.chart.type === "pie") {
					  l.details.plotOptions.pie = { size: 200 };
					  l.details.chart.height = "250px";
					}
					if (l.details.chart.type === "column") {
					  l.details.chart.height = "260px";
					}
					if (!l.headerAssigned) { 
						l.header = l.details.title.text;
						l.headerAssigned = true; 
					  }
					  
					  l.details.title.text = ""; 
				  } catch (ex) {}
				  if (r <= 2) {
					if (c % 2 === 0) {
					  y = y + 8;
					  x = 0;
					  c = 1;
					  r = r + 1;
					} else {
					  x = x + 6;
					  c = c + 1;
					}
					l.w = 6;
				  } else {
					l.w = 12;
					r = r + 1;
				  }
				});
			  } else {
				dashboardData.forEach(function (l, i) {
				  k = "widget_" + (k + 1);
				  l.x = x;
				  l.y = y;
				  l.h = h;
				  l.isDraggable = false;
				  try {
					if (l.details.chart.type === "pie") {
					  l.details.plotOptions.pie = { size: 200 };
					  l.details.chart.height = "250px";
					}
					if (l.details.chart.type === "column") {
					  l.details.chart.height = "260px";
					}
					l.details.title.text = "";
				  } catch (ex) {}
			  
				  c = c + 1;
			  
				  if (c === 2 || c === 3) {
					x = x + 6;
					l.w = 6;
				  } else {
					l.w = 12;
				  }
				});
			  }
			  return dashboardData;
			}
	};

	handleViewModeChange = () => {
		const mode = this.state.isEditMode;
		let dataToAPi = [];
		this.dashboardObj?.serialize().forEach((item, index) => {
			this.props.dashboard.data.widgets.forEach((secondItr, i) => {
				if (secondItr.i.toString() === item.id.split("_")[1]) {
					dataToAPi.push({
						...secondItr,
						x: item?.row,
						y: item?.col,
						h: item?.sizeY,
						w: item?.sizeX,
						status: "Active",
					});
				}
			});
		});
		if (this.state.newWidgetsToAdd) {
			dataToAPi.push({
				...this.state.newWidgetsToAdd,
				x: 0,
				y: 0,
				w: this.state.numberOfColumns,
				status: "Active",
			});
		}
		let apiToObj = {
			widgetInfo: dataToAPi,
		};
		if (mode) {
			const promise = HTTPClient.post(
				endPoints.DASHBOARD.DASHBOARD_CHANGE_LAYOUT,
				apiToObj
			);
			promise.then((res) => {
				this.props.showCustomSnackBar('Widgets Saved Sucessfully.', styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
				let timeLine = "";
				let type = "";
				this.setState({
					numberOfColumns: 1,
				});
				let isInxpress = getBooleanParam(this.props.app.me.is_inxpress);
				const moduleType = constants.MODULE_ID_SALES;

				timeLine = localStorage.getItem("salesDashboardTimeline");
				if (timeLine === null || timeLine === "") {
					timeLine = "tw";
				}
				if (isInxpress) {
					let industryType = getStringParam(this.props.app.me.industry_type);
					if (industryType === constants.INDUSTRY_TYPE_INXPRESS_COUNTRY) {
						type = constants.TYPE_DASHBOARD_INXPRESS_COUNTRY;
					} else if (
						industryType === constants.INDUSTRY_TYPE_INXPRESS_FRANCHISE ||
						industryType === constants.INDUSTRY_TYPE_INXPRESS_US_FRANCHISE
					) {
						type = constants.TYPE_DASHBOARD_INXPRESS_FRANCHISE;
					}
				} else {
					if (
						this.props.app.me.projectId ===
						constants.INDUSTRY_TYPE_INXPRESS_GLOBAL
					) {
						type = constants.TYPE_DASHBOARD_INXPRESS_COUNTRY;
					} else {
						type = this.props.app.selectedModuleName;
					}
				}
				this.props.getDashboard(
					this.props.app.me.projectId,
					this.props.app.me.id,
					this.props.dashboard.dashboardId,
					timeLine,
					type,
					moduleType
				);
			});
		}
		if(!mode){
			this.getIntialData()
		  }
		this.setState({ isEditMode: !mode });
	};

	generateHeadersArray = (dashboardData) => {
		if (dashboardData && Object.keys(dashboardData).length) {
			let widgets = dashboardData?.data?.widgets;
			if (widgets) {
				widgets.sort(function (a, b) {
					return a.i.toString() - b.i.toString();
				});
				let headers = widgets.map((w, i) => {
					return w.header;
				});
				return headers;
			}
		}
	};

	generateDOM = () => {
		let _this = this;
		let dashboardData = this.defineGadgetPosition(
			this.props.dashboard.layouts.lg
		);
		let timeLine = "";
		if (this.props.app.selectedModuleName === constants.MODULE_MARKETING) {
			timeLine = localStorage.getItem("marketingDashboardTimeline");
		} else if (this.props.app.selectedModuleName === constants.MODULE_SALES) {
			timeLine = localStorage.getItem("salesDashboardTimeline");
		}
		if (timeLine === null || timeLine === "") {
			timeLine = "tw";
		}
		return map(dashboardData, function (l, i) {
			if (l !== null) {
				let reportContent;

				if (l.format === "report") {
					if (
						l.hasOwnProperty("widgetName") &&
						(l.widgetName === "CRM Account Summary" ||
							l.widgetName === "Sales Cycle" ||
							l.widgetName === "Top Industries" ||
							l.widgetName === "Top Sources" ||
							l.widgetName === "Leads By Source" ||
							l.widgetName === "Leads By Lead Source")
					) {
						if (
							l.hasOwnProperty("details") &&
							l.details.hasOwnProperty("plotOptions")
						) {
							let plotOptions = l.details.plotOptions;
							plotOptions = {
								series: {
									cursor: "pointer",
									events: { click: customDrillDown.bind(_this, l) },
								},
							};
							l.details.plotOptions = plotOptions;
							l.timeLine = timeLine;
						}
						reportContent = (
							<Chart
								config={{ ...l.details, ...exportingDataConstant(l.header) }}
								widgetId={l.i}
							/>
						);
					} else if (
						l.hasOwnProperty("widgetName") &&
						(l.widgetName === "Accounts Matrix" ||
							l.widgetName === "Activities" ||
							l.widgetName === "Opportunities Matrix")
					) {
						reportContent = (
							<Query data={{ ...l.details, header: l.header }} widgetId={l.i} />
						);
					} else {
						reportContent = (
							<Chart
								config={{ ...l.details, ...exportingDataConstant(l.header) }}
								widgetId={l.i}
							/>
						);
					}
				} else if (l.format === "query") {
					reportContent = (
						<Query data={{ ...l.details, header: l.header }} widgetId={l.i} />
					);
				} else if (l.format === "custom") {
					if (
						l.hasOwnProperty("widgetName") &&
						(l.widgetName === "Activity Summary" ||
							l.widgetName === "Activities by Users" ||
							l.widgetName === "Call Activity" ||
							l.widgetName === "Activity Summary By SalesRep" ||
							l.widgetName === "Call Activity By SalesRep")
					) {
						if (
							l.hasOwnProperty("details") &&
							l.details.hasOwnProperty("plotOptions")
						) {
							let plotOptions = l.details.plotOptions;
							plotOptions = {
								series: {
									cursor: "pointer",
									events: { click: customDrillDown.bind(_this, l) },
								},
							};
							l.details.plotOptions = plotOptions;
							l.timeLine = timeLine;
						}

						reportContent = (
							<Chart
								config={{ ...l.details, ...exportingDataConstant(l.header) }}
								widgetId={l.i}
							/>
						);
					} else {
						reportContent = <CustomReport data={l.details} widgetId={l.i} />;
					}
				} else {
					reportContent = <div />;
				}

				let headerTitle = getLocalizedStrings().label.DASHBOARD.hasOwnProperty(
					l.header
				)
					? getLocalizedStrings().label.DASHBOARD[l.header]
					: l.header;
				return (
					<div key={l.i} style={{ width: "100%" }}>
						<div title={headerTitle}>
							<span style={{ color: "#000000" }}>
								<h3
									style={{
										margin: "0px",
										overflow: "auto",
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
										width: "98%",
										fontSize:"15px",
										color: '#000000'
									}}
								>
									{headerTitle}
								</h3>
							</span>
						</div>
						<div style={styles.bodyStyle} className="dragCancel">
							<div style={styles.contentDivStyle}>{reportContent}</div>
						</div>
					</div>
				);
			}
		});
	};

	generateSyncFusionDOM = () => {
		let _this = this;
		let reportContent = <div></div>;
		const isSales =
			this.props.app.selectedModuleName === constants.MODULE_SALES;
		let dashboardData = this.defineGadgetPosition(
			this.props.dashboard.layouts.lg
		);
		if (isSales) {
			let tempDashboardObj=[]
			dashboardData?.forEach((d,i)=>{
			  this.props?.dashboard?.data?.widgets.forEach((w,index)=>{
				if(d.i.toString()===w.i.toString()){
				  tempDashboardObj.push({...w,header:d.header})
				}
			  })
			})
			dashboardData = tempDashboardObj
		}
		let timeLine = "";
		if (this.props.app.selectedModuleName === constants.MODULE_MARKETING) {
			timeLine = localStorage.getItem("marketingDashboardTimeline");
		} else if (this.props.app.selectedModuleName === constants.MODULE_SALES) {
			timeLine = localStorage.getItem("salesDashboardTimeline");
		}
		if (timeLine === null || timeLine === "") {
			timeLine = "tw";
		}
		return map(dashboardData, function (l, i) {
			if (l !== null) {
				//let sync = false;
				if (l.format === "report") {
					if (
						l.hasOwnProperty("widgetName") &&
						(l.widgetName === "CRM Account Summary" ||
							l.widgetName === "Sales Cycle" ||
							l.widgetName === "Top Industries" ||
							l.widgetName === "Top Sources" ||
							l.widgetName === "Leads By Source" ||
							l.widgetName === "Leads By Lead Source")
					) {
						if (
							l.hasOwnProperty("details") &&
							l.details.hasOwnProperty("plotOptions")
						) {
							let plotOptions = l.details.plotOptions;
							plotOptions = {
								series: {
									cursor: "pointer",
									events: { click: customDrillDown.bind(_this, l) },
								},
							};
							l.details.plotOptions = plotOptions;
							l.timeLine = timeLine;
						}
						//sync = true;
						reportContent = (
							<Chart
								config={{
									...l.details,
									...exportingDataConstant(l.header),
								}}
								widgetId={l.i}
							/>
						);
					} else if (
						l.hasOwnProperty("widgetName") &&
						(l.widgetName === "Accounts Matrix" ||
							l.widgetName === "Activities" ||
							l.widgetName === "Opportunities Matrix")
					) {
						reportContent = (
							<Query data={{ ...l.details, header: l.header }} widgetId={l.i} />
						);
					} else {
						//sync = true;
						reportContent = (
							<Chart
								config={{
									...l.details,
									...exportingDataConstant(l.header),
								}}
								widgetId={l.i}
							/>
						);
					}
				} else if (l.format === "query") {
					reportContent = (
						<Query data={{ ...l.details, header: l.header }} widgetId={l.i} />
					);
				} else if (l.format === "custom") {
					if (
						l.hasOwnProperty("widgetName") &&
						(l.widgetName === "Activity Summary" ||
							l.widgetName === "Activities by Users" ||
							l.widgetName === "Call Activity" ||
							l.widgetName === "Activity Summary By SalesRep" ||
							l.widgetName === "Call Activity By SalesRep")
					) {
						if (
							l.hasOwnProperty("details") &&
							l.details.hasOwnProperty("plotOptions")
						) {
							let plotOptions = l.details.plotOptions;
							plotOptions = {
								series: {
									cursor: "pointer",
									events: { click: customDrillDown.bind(_this, l) },
								},
							};
							l.details.plotOptions = plotOptions;
							l.timeLine = timeLine;
						}
						// sync = true;

						reportContent = (
							<Chart
								config={{
									...l.details,
									...exportingDataConstant(l.header),
								}}
								widgetId={l.i}
							/>
						);
					} else {
						reportContent = <CustomReport data={l.details} widgetId={l.i} />;
					}
				} else {
					reportContent = <div />;
				}
				let headerTitle = getLocalizedStrings().label.DASHBOARD.hasOwnProperty(
					l.header
				)
					? getLocalizedStrings().label.DASHBOARD[l.header]
					: l.header;
				return (
					<PanelDirective
						id={`panel_${l.i}`}
						sizeX={l.w}
						sizeY={l.h}
						row={l.x}
						col={l.y}
						content={_this.contentfunction(reportContent)}
						header={` <div key={l.i} style={{ width: '100%' }}>
          <div title="${headerTitle}">
              <span style={{ color: '#000000' }}><h3 style={{ margin: '0px', overflow: 'auto', color: '#000000',textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '98%',fontSize:"15px"}}>${headerTitle}</h3></span>
          </div>
      </div>`}
					></PanelDirective>
				);
			}
		});
	};
	contentfunction = (content) => {
		return () => {
			return content;
		};
	};
	componentDidMount() {
      this.getIntialData()
	}

	getIntialData=()=>{
		let isInxpress = getBooleanParam(this.props.app.me.is_inxpress);
		let isFranchisor = false;
		let isQuickSight = false;
		if (getArrayParam(this.props.app?.dashboardInfo)) {
			this.props.app.dashboardInfo?.forEach((obj) => {
				if (
					obj.module_type.toLowerCase() ===
						this.props.app.selectedModuleName.toLowerCase() &&
					obj.info.length > 0
				) {
					isQuickSight = true;
					obj.info.forEach((obj1) => {
						if (this.props.id === obj1.id) {
							isFranchisor = obj1.is_franchisor;
						}
					});
				}
			});
		}

		if (isQuickSight === true) {
			let timeLine = this.state.time_line;
			let selectedModuleName = getStringParam(
				this.props.app.selectedModuleName
			).trim();
			let tab = getActiveTab();
			let label = getStringParam(tab.label);
			label =
				label.indexOf(selectedModuleName) >= 0
					? label
					: selectedModuleName + " " + label;
			if (this.props.app.selectedModuleName === constants.MODULE_SALES) {
				timeLine = "ty";
				this.props.getDashboardURL(
					this.props.app.me.projectId,
					this.props.id,
					isFranchisor,
					this.state.franchise,
					timeLine
				);
			} else
				this.props.getDashboardURL(
					this.props.app.me.projectId,
					this.props.id,
					isFranchisor,
					this.state.franchise,
					this.state.time_line
				);

			this.setState({ isQuickSight: true, time_line: timeLine });
			this.props.setHeader(this.props.object, label, null, false, true);
		} else {
			let timeLine = "";
			let label = "";
			let type = "";
			let moduleType = "";
			if (this.props.app.selectedModuleName === constants.MODULE_MARKETING) {
				timeLine = localStorage.getItem("marketingDashboardTimeline");
				label = getLocalizedStrings().label.DASHBOARD.MARKETING_DASHBOARD;
			} else if (this.props.app.selectedModuleName === constants.MODULE_SALES) {
				timeLine = localStorage.getItem("salesDashboardTimeline");
				label = getLocalizedStrings().label.DASHBOARD.SALES_DASHBOARD;
			}
			if (timeLine === null || timeLine === "") {
				timeLine = "tw";
			}
			if (isInxpress) {
				let industryType = getStringParam(this.props.app.me.industry_type);
				if (industryType === constants.INDUSTRY_TYPE_INXPRESS_COUNTRY) {
					type = constants.TYPE_DASHBOARD_INXPRESS_COUNTRY;
				} else if (
					industryType === constants.INDUSTRY_TYPE_INXPRESS_FRANCHISE ||
					industryType === constants.INDUSTRY_TYPE_INXPRESS_US_FRANCHISE
				) {
					type = constants.TYPE_DASHBOARD_INXPRESS_FRANCHISE;
				}
			} else {
				if (
					this.props.app.me.projectId ===
					constants.INDUSTRY_TYPE_INXPRESS_GLOBAL
				) {
					type = constants.TYPE_DASHBOARD_INXPRESS_COUNTRY;
				} else {
					type = this.props.app.selectedModuleName;
				}
			}

			if (this.props.app.selectedModuleName === constants.MODULE_SALES) {
				moduleType = constants.MODULE_ID_SALES;
			} else if (
				this.props.app.selectedModuleName === constants.MODULE_MARKETING
			) {
				moduleType = constants.MODULE_ID_MARKETING;
			}
			this.props.getDashboard(
				this.props.app.me.projectId,
				this.props.app.me.id,
				this.props.dashboard.dashboardId,
				timeLine,
				type,
				moduleType
			  );

			let kanbanObject = {};
			kanbanObject.currentView = null;
			kanbanObject.show = false;

			this.props.setHeader(this.props.object, label, kanbanObject, false, true);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.object !== this.props.object) {
			let tabInfo = getActiveTabInfo();
			this.props.setHeader(
				this.props.object,
				getStringParam(tabInfo.label),
				null,
				false,
				true
			);
		}
	}

	onLayoutChange = (layout, layouts) => {
		this.props.dashboard.config.onLayoutChange(layout, layouts);
		let widgets = [];
		map(layout, function (lay) {
			widgets.push({
				i: lay.i,
				x: lay.x,
				y: lay.y,
				w: 2,
				h: 12,
			});
		});
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.state.isQuickSight === true) {
			if (
				this.props.dashboard.dashboardURL !== "" &&
				this.props.dashboard.dashboardURL !== undefined &&
				this.props.dashboard.dashboardURL !== null
			) {
				//var embedURL = this.props.dashboard.dashboardURL;
				$("#dashboardContainer").html("");
				//var containerDiv = document.getElementById("dashboardContainer");
				// var params = {
				//   url: embedURL,
				//   container: containerDiv,
				//   height: "AutoFit",
				//   loadingHeight: "850px",
				// };
				//var dashboard = QuickSightEmbedding.embedDashboard(params);
				//dashboard.on("error", function () {});

				setTimeout(function () {
					$("iframe").css({ border: "none" });

					$("iframe")
						.contents()
						.find(".exploration-top-panel-header")
						.each(function () {
							$(this).remove();
						});

					$("iframe")
						.contents()
						.find(".embed-attribution-container")
						.each(function () {
							$(this).remove();
						});
				}, 500);

				setTimeout(function () {
					$("iframe").css({ border: "none" });

					$("iframe")
						.contents()
						.find(".exploration-top-panel-header")
						.each(function () {
							$(this).remove();
						});

					$("iframe")
						.contents()
						.find(".embed-attribution-container")
						.each(function () {
							$(this).remove();
						});
				}, 5000);

				setTimeout(function () {
					$("iframe").css({ border: "none" });

					$("iframe")
						.contents()
						.find(".exploration-top-panel-header")
						.each(function () {
							$(this).remove();
						});

					$("iframe")
						.contents()
						.find(".embed-attribution-container")
						.each(function () {
							$(this).remove();
						});
				}, 10000);
			}
		}
	}

	changeTimeline = (values) => {
		this.setState({ time_line: values.value }, function () {
			let isFranchisor = false;
			if (getArrayParam(this.props.app.dashboardInfo)) {
				this.props.app.dashboardInfo[0].info.forEach((obj) => {
					if (this.props.id === obj.id) {
						isFranchisor = obj.is_franchisor;
					}
				});
			}
			this.props.getDashboardURL(
				this.props.app.me.projectId,
				this.props.id,
				isFranchisor,
				this.state.franchise,
				this.state.time_line
			);
		});
	};

	loadFranchiseList = () => {
		let params = {};
		let promise = HTTPClient.get(endPoints.DASHBOARD.FRANCHISE_LIST, params);
		if (isValidParam(promise)) {
			promise.then((response) => {
				let franchiseList = [
					{
						label: "All",
						value: "all",
					},
				];
				if (isValidParam(response.franchise_list)) {
					let list = getArrayParam(response.franchise_list);
					list.forEach(function (element) {
						franchiseList.push({
							label: element.franchise_name,
							value: element.myclient_id,
						});
					});
					this.setState({
						franchiseList: franchiseList,
						mounted: true,
						hasFranchiseList: list.length > 0 ? true : false,
					});
				}
			});
		}
	};

	handleChangeFranchise = (value) => {
		this.setState({ franchise: value });
		let isFranchisor = false;
		if (getArrayParam(this.props.app.dashboardInfo)) {
			this.props.app.dashboardInfo[0].info.forEach((obj) => {
				if (this.props.id === obj.id) {
					isFranchisor = obj.is_franchisor;
				}
			});
		}
		this.props.getDashboardURL(
			this.props.app.me.projectId,
			this.props.id,
			isFranchisor,
			this.state.franchise,
			this.state.time_line
		);
	};

	generateNewPanelContent = (dataRecieved) => {
		let proxy = this;
		const timeLine = localStorage.getItem("marketingDashboardTimeline");
		let reportContent;
		//let sync = false;
		let l = this.state.newWidgetsToAdd;
		if (l) {
			if (l?.details?.title?.text) {
				l.details.title.text = "";
			}
			if (l?.details?.chart?.type === "pie") {
				l.details.plotOptions.pie = { size: 200 };
				l.details.chart.height = "250px";
			}
			if (l?.details?.chart?.type === "column") {
				l.details.chart.height = "260px";
			}
			if (l.format === "report") {
				if (
					l.hasOwnProperty("widgetName") &&
					(l.widgetName === "CRM Account Summary" ||
						l.widgetName === "Sales Cycle" ||
						l.widgetName === "Top Industries" ||
						l.widgetName === "Top Sources" ||
						l.widgetName === "Leads By Source" ||
						l.widgetName === "Leads By Lead Source")
				) {
					if (
						l.hasOwnProperty("details") &&
						l.details.hasOwnProperty("plotOptions")
					) {
						let plotOptions = l.details.plotOptions;
						plotOptions = {
							series: {
								cursor: "pointer",
								events: { click: customDrillDown.bind(proxy, l) },
							},
						};
						l.details.plotOptions = plotOptions;
						l.timeLine = timeLine;
					}
					//sync = true;
					reportContent = (
						<Chart
							config={{
								...l.details,
								...exportingDataConstant(l.header),
							}}
							widgetId={l.i}
						/>
					);
				} else if (
					l.hasOwnProperty("widgetName") &&
					(l.widgetName === "Accounts Matrix" ||
						l.widgetName === "Activities" ||
						l.widgetName === "Opportunities Matrix")
				) {
					reportContent = (
						<Query data={{ ...l.details, header: l.header }} widgetId={l.i} />
					);
				} else {
					//sync = true;
					reportContent = (
						<Chart
							config={{
								...l.details,
								...exportingDataConstant(l.header),
							}}
							widgetId={l.i}
						/>
					);
				}
			} else if (l.format === "query") {
				reportContent = (
					<Query data={{ ...l.details, header: l.header }} widgetId={l.i} />
				);
			} else if (l.format === "custom") {
				if (
					l.hasOwnProperty("widgetName") &&
					(l.widgetName === "Activity Summary" ||
						l.widgetName === "Activities by Users" ||
						l.widgetName === "Call Activity" ||
						l.widgetName === "Activity Summary By SalesRep" ||
						l.widgetName === "Call Activity By SalesRep")
				) {
					if (
						l.hasOwnProperty("details") &&
						l.details.hasOwnProperty("plotOptions")
					) {
						let plotOptions = l.details.plotOptions;
						plotOptions = {
							series: {
								cursor: "pointer",
								events: { click: customDrillDown.bind(proxy, l) },
							},
						};
						l.details.plotOptions = plotOptions;
						l.timeLine = timeLine;
					}
					//sync = true;

					reportContent = (
						<Chart
							config={{
								...l.details,
								...exportingDataConstant(l.header),
							}}
							widgetId={l.i}
						/>
					);
				} else {
					reportContent = <CustomReport data={l.details} widgetId={l.i} />;
				}
			} else {
				reportContent = <div />;
			}
			let headerTitle = getLocalizedStrings().label.DASHBOARD.hasOwnProperty(
				l.header
			)
				? getLocalizedStrings().label.DASHBOARD[l.header]
				: l.header;
			let panel = [
				{
					'id': 'panel_' + `${l.i}`, 'sizeX': this.state.numberOfColumns , 'sizeY': l.h, 'row': 0, 'col': 0, header:` <span >
      <h4
      >
        ${headerTitle || ""}
      </h4>
    </span>`,
					cellAspectRatio: 2.5,
					content: this.contentfunction(reportContent),
				},
			];
			this.dashboardObj.addPanel(panel[0]);
		}
	};
	handleAddWidget = (value) => {
		let chartToBeAdded = this.props.dashboard.inactiveWidgets.filter(
			({ wId }) => wId === value.value
		);
		this.setState({
			newWidgetsToAdd: chartToBeAdded[0],
		});
	};

	handleColumnChange = (value) => {
		if (
			this.state.newWidgetsToAdd &&
			(this.state.newWidgetsToAdd.header.toLowerCase() === "accounts matrix" ||
				this.state.newWidgetsToAdd.header.toLowerCase() ===
					"opportunities matrix")
		) {
			this.setState({ numberOfColumns: 2 });
		} else {
			this.setState({ numberOfColumns: value.value });
		}
	};

	handleProceedAddWidget = () => {
		this.generateNewPanelContent();
	};
	render(){
		if (
			this.props.dashboard.isRedirect == true &&
			this.props.dashboard.redirectUrl !== ""
		) {
			this.props.setDashBoardRedirection();
			return <Navigate push to={this.props.dashboard.redirectUrl} />;
		}
		if (this.state.isQuickSight === false) {
			this.cellSpacing = [20, 20];
			if (this.props.dashboard.mounted) {
				return (
					<div style={{ margin: "20px",overflow:'hidden' }}>
						<DashboardHeader
							data={this.props.dashboard.data}
							handleViewModeChange={this.handleViewModeChange}
							isEditMode={this.state.isEditMode}
							handleAddWidget={this.handleAddWidget}
							handleColumnChange={this.handleColumnChange}
							handleProceedAddWidget={this.handleProceedAddWidget}
						/>
						 {this.props.app.selectedModuleName == constants.MODULE_MARKETING &&
								<ResponsiveReactGridLayout
								{...this.props.dashboard.config}
								layouts={this.props.dashboard.layouts}
								//onLayoutChange={this.onLayoutChange}
								measureBeforeMount={false}
								useCSSTransforms={this.props.dashboard.mounted}
							>
								{this.generateDOM()}
							</ResponsiveReactGridLayout> 
							}

						{this.props.app.selectedModuleName === constants.MODULE_SALES && (
							<DashboardLayoutComponent
								id="edit_dashboard"
								columns={2}
								cellAspectRatio={2.5}
								cellSpacing={this.cellSpacing}
								// resizeStop={this.onPanelResize.bind(this)}
								ref={(scope) => {
									this.dashboardObj = scope;
								}}
								allowResizing={false}
								allowDragging={this.state.isEditMode}
							>
								<PanelsDirective>
									{this.generateSyncFusionDOM()}
								</PanelsDirective>
							</DashboardLayoutComponent>
						)}
					</div>
				);
			} else {
				let contentHeight = window.innerHeight - 240;
				let top = (contentHeight - 10) / 2;
				
				return (
				  <div style={{ width: "100%", height: contentHeight }}>
					<div 
					className="asset-loaderh" 
					style={{ paddingTop: top, paddingLeft: "45%" }}>
					  <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
						<ShowCircularProgress size={30} />
					  </div>
					</div>
				  </div>
				);
				}
		} else {
			if (
				this.props.dashboard.dashboardURL !== "" &&
				this.props.dashboard.dashboardURL !== undefined &&
				this.props.dashboard.dashboardURL !== null
			) {
				let selectedVal = options.filter(
					(e) => e.value === this.state.time_line
				);
				return (
					<div style={{ padding: "0px 20px 20px 20px", width: "100%"}}>
						<div style={{ ...styles.headerdropstyle, marginBottom: 10 }}>
							<Select
								id="date"
								name="dueBy"
								value={selectedVal}
								placeholder={""}
								options={options}
								className="basic-multi-select"
								classNamePrefix="select"
								onChange={this.changeTimeline.bind(this)}
							/>
						</div>
						<div id="dashboardContainer" style={{ border: "none" }}></div>
					</div>
				);
			}
		}
	}
}


class DashboardHeader extends React.Component {
	constructor(props) {
		super(props);
		this.changeDashboardTimeline = this.changeDashboardTimeline.bind(this);
		this.state = {
			showWidgetModal: false,
			isSalesModule:
				this.props.app.selectedModuleName === constants.MODULE_SALES,
				dropDownSelectedData:null,
				selectedRange:null
		};
		this.animationSettings = { effect: "None" };
		this.items = [
			{
				text: "Line Chart",
			},
			{
				text: "Pie Chart",
			},
			{
				text: "Spline Chart",
			},
		];
		this.fields = { text: "header", value: "wId" };
		this.columnDropdown = [{ label: "1 Column" , value: 1 },{ label : "2 Columns", value : 2}]
		this.columnDropdownFields = { text: "text", value: "value" };
	}
	componentDidMount = () => {
		this.handleClick("modeChange");
	};
	changeDashboardTimeline = (values) => {
		let isInxpress = getBooleanParam(this.props.app.me.is_inxpress);
		let type = "";
		let moduleType = "";
		let timeLine = values.value;
		this.setState({selectedRange:values})
		if (this.props.app.selectedModuleName === constants.MODULE_MARKETING) {
			localStorage.setItem("marketingDashboardTimeline", timeLine);
		} else if (this.props.app.selectedModuleName === constants.MODULE_SALES) {
			localStorage.setItem("salesDashboardTimeline", timeLine);
		}
		if (isInxpress) {
			let industryType = getStringParam(this.props.app.me.industry_type);
			if (industryType === constants.INDUSTRY_TYPE_INXPRESS_COUNTRY) {
				type = constants.TYPE_DASHBOARD_INXPRESS_COUNTRY;
			} else if (
				industryType === constants.INDUSTRY_TYPE_INXPRESS_FRANCHISE ||
				industryType === constants.INDUSTRY_TYPE_INXPRESS_US_FRANCHISE
			) {
				type = constants.TYPE_DASHBOARD_INXPRESS_FRANCHISE;
			}
		} else {
			if (
				this.props.app.me.projectId === constants.INDUSTRY_TYPE_INXPRESS_GLOBAL
			) {
				type = constants.TYPE_DASHBOARD_INXPRESS_COUNTRY;
			} else {
				type = getStringParam(this.props.app.selectedModuleName);
			}
		}

		if (this.props.app.selectedModuleName === constants.MODULE_SALES) {
			moduleType = constants.MODULE_ID_SALES;
		} else if (
			this.props.app.selectedModuleName === constants.MODULE_MARKETING
		) {
			moduleType = constants.MODULE_ID_MARKETING;
		}

		this.setState({
			dropDownSelectedData:{
				projectId:this.props.app.me.projectId,
				id:this.props.app.me.id,
				dashboardId:this.props.dashboard.dashboardId,
				timeLine,
				type,
				moduleType
			}
		  })
			};

	handleGoClick=() => {
		if(this.state.dropDownSelectedData){
		  this.props.getDashboard(
			this.state.dropDownSelectedData.projectId,
			this.state.dropDownSelectedData.id,
			this.state.dropDownSelectedData.dashboardId,
			this.state.dropDownSelectedData.timeLine,
			this.state.dropDownSelectedData.type,
			this.state.dropDownSelectedData.moduleType
			);
		}
	  }

	handleClick = (callType = "") => {
		let selectedVal = options.filter(
			(e) => e.value === this.props.data.time_line
		);
		let isInxpress = getBooleanParam(this.props.app.me.is_inxpress);
		let type = "";
		if (isInxpress) {
			let industryType = getStringParam(this.props.app.me.industry_type);
			if (industryType === constants.INDUSTRY_TYPE_INXPRESS_COUNTRY) {
				type = constants.TYPE_DASHBOARD_INXPRESS_COUNTRY;
			} else if (
				industryType === constants.INDUSTRY_TYPE_INXPRESS_FRANCHISE ||
				industryType === constants.INDUSTRY_TYPE_INXPRESS_US_FRANCHISE
			) {
				type = constants.TYPE_DASHBOARD_INXPRESS_FRANCHISE;
			}
		} else {
			if (
				this.props.app.me.projectId === constants.INDUSTRY_TYPE_INXPRESS_GLOBAL
			) {
				type = constants.TYPE_DASHBOARD_INXPRESS_COUNTRY;
			} else {
				type = this.props.app.selectedModuleName;
			}
		}
		this.props.getDashboardWidgets(
			this.props.dashboard.dashboardId,
			selectedVal[0]?.value,
			type,
			constants.MODULE_ID_SALES,
			"Inactive"
		);
		if (callType === "") {
			this.setState({ showWidgetModal: true });
		}
		this.setState({
			selectedRange:selectedVal[0]
		  })
	};
	render() {
		let selectedVal = options.filter(
			(e) => e.value === this.props.data.time_line
		);
		let dropDownValue = this.props?.dashboard?.inactiveWidgets;
		dropDownValue?.sort(function (a, b) {
			return a.header - b.header;
		});
        dropDownValue = dropDownValue.map((item)=>{
			return {
			  value:item.wId,
			  label:item.header
			}
		  })
		return (
			<div id="target">
				<div style={{...styles.row, padding: 0, justifyContent: 'space-between'}}>
					<div  style={{...styles.row}}>
						<div style={{...styles.headerdropstyle, width: '60%'}}>
						{/* <DropDownListComponent
							id="date"
							dataSource={options}
							fields={fields}
							name="dueBy"
							className="basic-multi-select"
							onChange={this.changeDashboardTimeline.bind(this)}
							value={selectedVal[0]?.value}
							cssClass="drop-down-syncfusion"
							/> */}
							<Select
								id="date"
								name="dueBy"
								value={this.state.selectedRange ? this.state.selectedRange : selectedVal[0]}
                                placeholder={""}
								options={options}
								className="basic-multi-select "
								classNamePrefix="select"
								styles={{
									menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
									menu: (provided) => ({ ...provided, zIndex: 9999 }),
								}}
								onChange={this.changeDashboardTimeline.bind(this)}
							/>
						</div>
						<div style={{margin: '10px 20px'}}>
							<ButtonComponent
								onClick={()=>{
								this.handleGoClick()
								}}
								cssClass="e-info e-outline"
								style={{padding:'9.5px'}}
							>
								Go
							</ButtonComponent>
						</div>
					</div>
					<div style={{ float: "right", textAlign: "right", marginTop:"5px"}}>
						{this.props.app.me.t_name==="Admin" && this.state.isSalesModule && 
							<ButtonComponent
								cssClass="e-info e-outline"
								onClick={()=>{
								this.props.handleViewModeChange()}}
							>
								{this.props.isEditMode ? "Save" : "Edit"}
								{this.props.isEditMode?<Icon style={{ 'fontSize': '15px',position: "relative",top: "2.3px",left:"3px" }} title={getLocalizedStrings().label.COMMON.EDIT}>save</Icon>:<Icon style={{ 'fontSize': '15px','paddingTop':"2px" }} title={getLocalizedStrings().label.COMMON.EDIT}>edit</Icon>}
							</ButtonComponent>
						}
					</div>
				</div>
				<div className="row">
					<div className="col-12" style={{ margin: "0 15px" }}>
						{this.props.isEditMode &&
						this.state.isSalesModule &&
						dropDownValue.length ? (
							<ButtonComponent
								onClick={() => this.handleClick("")}
								style={{
									width: "100%",
									padding: "10px",
									backgroundColor: "grey",
									color: "white",
									marginBottom: "20px",
								}}
							>
								ADD NEW WIDGET
							</ButtonComponent>
						) : null}
					</div>
				</div>
				<DialogComponent
					width="450px"
					height="230px"
					isModal={true}
					visible={this.state.showWidgetModal}
					close={() => {
						this.setState({ showWidgetModal: false });
					}}
					showCloseIcon={true}
					header="Add a Widget"
					closeOnEscape={false}
				>
					<Select
                     options={dropDownValue || []}
						placeholder="Add a New Widget"
						fields={this.fields}
						onChange={(e) => {
							this.props.handleAddWidget(e);
						}}
						className="mySelect-dashboard"
					/>
					<Select 
						id="ddlelement"
						options={this.columnDropdown} 
						placeholder="Select columns"
						// fields={this.columnDropdownFields}
						onChange={(e) => this.props.handleColumnChange(e)}
						className="mySelect-dashboard"
					/>
					<ButtonComponent
						onClick={() => {
							this.setState({ showWidgetModal: false });
							this.props.handleProceedAddWidget();
						}}
						style={{
							width: "100%",
							padding: "10px",
							backgroundColor: "grey",
							color: "white",
							marginBottom: "20px",
						}}
					>
						Proceed
					</ButtonComponent>
				</DialogComponent>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		app: state.app,
		dashboard: state.dashboard,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getDashboard: (
			projectId,
			userId,
			dashboardId,
			timeline,
			type,
			moduleType
		) => {
			dispatch(
				getDashboard(projectId, userId, dashboardId, timeline, type, moduleType)
			);
		},
		setHeader: (
			selectedTabObject,
			selectedHeader,
			kanban,
			showAddIcon,
			showHeader
		) => {
			dispatch(
				setHeader(
					selectedTabObject,
					selectedHeader,
					kanban,
					showAddIcon,
					showHeader
				)
			);
		},
		setDashBoardRedirection: () => {
			dispatch(setDashBoardRedirection());
		},
		getDashboardURL: (
			projectId,
			dashboardId,
			isFranchisor,
			franchiseId,
			timeLine
		) => {
			dispatch(
				getDashboardURL(
					projectId,
					dashboardId,
					isFranchisor,
					franchiseId,
					timeLine
				)
			);
		},
		getDashboardWidgets: (dashboardId, timeLine, type, moduleType, callFor) => {
			dispatch(
				getDashboardWidgets(dashboardId, timeLine, type, moduleType, callFor)
			);
		},
		showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
	};
};

Dashboard = connect(mapStateToProps, mapDispatchToProps)(Dashboard);
DashboardHeader = connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
export { Dashboard,DashboardHeader };
