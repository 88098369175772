import React from 'react';
import { connect } from 'react-redux';
import { getWrkflowList, getWrkflowStatusList, convertToAccount } from '../../../services/actions/convertToAccountActions';
import { isValidParam, getArrayParam, getIntParam, getObjectParam, getBooleanParam, getStringParam } from '../../../services/helper/parameterVerifier';
import Button from '@mui/material/Button';
import { styles } from '../../../services/constants/styles';
import { getObjectLabelByObject } from '../../../services/helper/common';
import Autocomplete from '@mui/material/Autocomplete';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { getMyGroups, setGroups } from '../../../services/actions/filterMenuAction';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { isAssignRuleExists } from '../../../services/actions/assignmentRuleActions';
import { addTab, TYPE_LIST_VIEW } from '../../../services/helper/sfTabManager';
import ShowCircularProgress from '../components/circularProgress';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';

/**
 * 
 * @Author  : Pradyut.s 
 * @Date:   : 2017-10-09
 */
const mapStateToProps = (state) => {
    return {
        appContainer: state.appContainer,
        userlist: state.userlist,
        app: state.app,
        groups: state.filters.groups
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setGroups(groups) {
            dispatch(setGroups(groups));
        },
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        }
    }
};

class ConvertToAccount extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            workflowMenuItems: [],
            workflowId: 0,
            workflowName: '',
            workflowList: null,
            isWorkflowShow: false,
            statusId: 0,
            status: '',
            statusMenuItems: [],
            statusList: null,
            salesRep: '',
            salesRepId: 0,
            groupName: '',
            groups: [],
            groupMenuItems: null,
            groupId: 0,
            note: null,
            isNotifySalesRep: true,
            isTextNotifySalesRep: true,
            flyingNoteText: '',
            isAssignRuleExists: false,
            isSaveClicked: false,
            user: '',
            workflow_error: false,
            selectedWorkflow:null,
            selecetdWorkflowInput : null,
            searchedWorkflow: null,

        }
    }

    componentDidMount() {
        this.getWFList();
        this.getGroups();
        this.isAssignmentRuleExists();
    }

    isAssignmentRuleExists = () => {
        let paramObj = { table_id: OBJECT_TABLEID_MAP[constants.ACCOUNTS_OBJECT] };
        var response = Promise.resolve(isAssignRuleExists(paramObj));
        response.then((response) => {
            if (response.hasOwnProperty('isExists')) {
                if (getBooleanParam(response.isExists)) {
                    this.setState({ isAssignRuleExists: true });
                }
            }
        });
    }

    getWFList = () => {
        let promise = Promise.resolve(getWrkflowList());
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    let wfList = getArrayParam(response);
                    let moduleAccess = getObjectParam(this.props.app.me.module_access);
                    let isOperations = moduleAccess[constants.MODULE_OPERATIONS];
                    if (isOperations === 0) {
                        wfList = wfList.filter(f => { return f.name !== constants.ONBOARDING_WORKFLOW });
                    }
                    let workflowId = wfList.length > 0 ? wfList[0].id : 0;
                    this.state.workflowId = wfList.length > 0 ? wfList[0].id : 0;
                    this.state.workflowName = wfList.length > 0 ? wfList[0].name : '';

                    this.getStatusList(this.state.workflowId);

                    this.state.workflowList = wfList;
                    this.state.isWorkflowShow = wfList.length > 1 ? true : false;
                    let workflowMenuItems = [];
                    wfList.map(wf => {
                        let tempObj = {};
                        tempObj.value = wf.name;
                        tempObj.label = wf.name;
                        tempObj.id = wf.id;
                        workflowMenuItems.push(tempObj);
                    });
                    this.state.workflowMenuItems = workflowMenuItems;
                    this.setState(this.state);
                }
            });

        }
    }

    getStatusList = (workflowId) => {
        workflowId = getIntParam(workflowId);
        if (workflowId > 0) {
            let params = {};
            params.workflow_id = workflowId;
            let promise = Promise.resolve(getWrkflowStatusList(params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        let statusList = getArrayParam(response.stages);
                        this.state.statusId = statusList.length > 0 ? statusList[0].id : 0;
                        this.state.status = statusList.length > 0 ? statusList[0].name : '';
                        this.state.statusList = statusList;
                        let tempMenueList = [];
                        statusList.map(status => {
                            let tempObj = {};
                            tempObj.key = status.name;
                            tempObj.value = status.name;
                            tempObj.label = status.name;
                            tempObj.primaryText = status.name;
                            tempObj.id = status.name;
                            tempMenueList.push(tempObj);
                        });
                        this.state.statusMenuItems = tempMenueList;

                        this.setState(this.state);
                    }
                });
            }
        }
    }

    handleClose = () => {
        this.props.closeDrawer();
    }

    getActions = () => {
        const actions = [
            <Button
                primary={true}
                onClick={this.convert}
                style={styles.primaryButton}
            >{getLocalizedStrings().label.COMMON.SAVE}</Button>,
            <Button
                primary={true}
                onClick={this.handleClose}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CLOSE}</Button>,

        ];
        return actions;
    }




    setFieldValue = (fieldName, event, value) => {
        let _state = this.state;
        _state.workflow_error = false;
        if (fieldName === 'workflowName') {
            let workflowMenuItems = getArrayParam(this.state.workflowMenuItems);
            workflowMenuItems = workflowMenuItems.filter(f => { return f.value === value.value });
            if (event !== undefined) {
                if (isValidParam(value) && value.value !== -99999) {
                     value = value.value;
                    _state.selectedWorkflow = value       
                    _state.selecetdWorkflowInput = value
                } else if (isValidParam(value) && value === -99999) {
                    let data = {};
                    data.workflowList = this.state.workflowList;
                    data.category = this.state.searchedWorkflow;
                    data.workflowId = value.workflowId;
                    data.orderId = value.orderId;
                    data.object = constants.CONVERT_TO_ACCOUNTS;
                    let label = getLocalizedStrings().label.AUDIT_QUESTIONS.ADD_NEW_CATEGORY;
                    this.props.getAppDialog(true, constants.AUDIT_CATEGORY_TYPE_ADD_DIALOG, label, null, data, null);
                }
            }
            let workflowList = this.state.workflowList;
             let wfId = workflowMenuItems.length > 0 ? getIntParam(workflowMenuItems[0].id) : 0;
            _state.workflowId = wfId;
            this.getStatusList(wfId);
            let tempWorkflowArr = [];
            workflowList.map(f => {
                tempWorkflowArr.push(this.getAddOptonObject(f.id, f.name));
            });
            let optionName = 'Add a New Category';
            tempWorkflowArr = tempWorkflowArr.filter(v => v.categoryName !== optionName);
            sortArrayObjectByProperty(tempWorkflowArr, 'categoryName');
            tempWorkflowArr.push(this.getAddOptonObject(optionName, -99999));
            _state.categoryListOptions = tempWorkflowArr;
            this.setState(_state)

        } else if (fieldName === 'groupName') {
            let grplist = getArrayParam(this.state.groups);
            grplist = grplist.filter(f => { return f.name === value });
            let grpId = grplist.length > 0 ? getIntParam(grplist[0].id) : 0;
            this.state.groupId = grpId;
        } else if (fieldName === 'salesRep') {
            if (value === 'Setup Assignment Rule') {
                this.props.closeDrawer();
                this.openAssignmentRuleDrawer()
            }
            this.state.isNotifySalesRep = this.props.app.me.name !== value ? true : false;
            this.state.isTextNotifySalesRep = this.props.app.me.name !== value ? true : false;
        }
        this.setState({ [fieldName]: value });
    }

    populateSalesRepList = () => {
        let userlist = this.props.userlist.data;
        let salesrepList = [];
        userlist.map(user => {
            if (this.state.salesRep === '') {
                this.state.salesRep = this.props.app.me.name;
            }
            let tempMenuObj = {};
            tempMenuObj.key = user.value;
            tempMenuObj.value = user.value;
            tempMenuObj.id = user.value;
            tempMenuObj.primaryText = user.value;
            salesrepList.push(tempMenuObj);
        });

        if (getStringParam(this.props.app.me.t_name) === 'Admin' ||
            getBooleanParam(this.props.app.me.is_tenant)) {
            let primaryText = "Setup Assignment Rule";
            let tempMenuObj = {};
            tempMenuObj.key = primaryText;
            tempMenuObj.value = primaryText;
            tempMenuObj.id = primaryText;
            tempMenuObj.primaryText = primaryText;
            salesrepList.push(tempMenuObj);
        }

        if (this.state.isAssignRuleExists) {
            let primaryText = "Use Assignment Rule";
            let tempMenuObj = {};
            tempMenuObj.key = primaryText;
            tempMenuObj.value = primaryText;
            tempMenuObj.id = primaryText;
            tempMenuObj.primaryText = primaryText;
            salesrepList.unshift(tempMenuObj);
        }

        return salesrepList;
    }

    getGroups = () => {
        try {
            let groups = isValidParam(this.props.groups) ? Object.assign([], this.props.groups) : [];
            if (groups.length === 0) {
                let promise = Promise.resolve(getMyGroups(constants.ACCOUNTS_OBJECT));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            this.props.setGroups(response);
                            let tempGroups = response.groups.filter(f => { return f.status === 'Active' });
                            tempGroups = tempGroups.map(m => {
                                return { ...m, label: m.name }
                            });
                            this.setState({
                                groups: tempGroups
                            });
                        }
                    });
                }
            } else {
                groups = groups.filter(f => { return f.status === 'Active' });
                let tempGroups = groups.map(m => {
                    return { ...m, label: m.name }
                });
                this.setState({
                    groups: tempGroups
                });
            }
        } catch (error) {
            console.error("Error in 'convertToAccounts.js -> getGroups()':" + error);
        }
    }


    onCheckedSalesRep = (isNotifySalesRep) => {
        this.setState({ isNotifySalesRep: isNotifySalesRep });
    }

    onCheckedTextNotifySalesRep = (isTextNotifySalesRepValue) => {
        this.setState({ isTextNotifySalesRep: isTextNotifySalesRepValue });
    }

    setFlyingNoteText = (value) => {
        this.setState({ flyingNoteText: value });
    }

    openAssignmentRuleDrawer = () => {
        let tab = {
            item: constants.CONTACTS_OBJECT,
            label: getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULES,
            object: 'assignmentrules',
            imgName: 'fas fa-cog',
            url: '/setting/module',
            type: TYPE_LIST_VIEW,
        };
        tab.info = { filter: { id: 0, name: 'Assignment Rule', type: '' } };
        tab.info.selectedItem = { id: 0, name: 'assignmentrules', label: getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULES, type: "menu-item", object: 'assignmentrules' };
        addTab(tab, true);
    }
    isSelectedFieldBlank = () =>{
        let _state = this.state;
        let workflow =this.state.workflowName;
        let status = this.state.status;
        let sales_rep = this.state.salesRep;
        if(workflow === ''){
            this.props.showCustomSnackBar(getLocalizedStrings().message.WORKFLOWS.WORKFLOW_BLANK_ALERT, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            _state.workflow_error = true;
            this.setState(_state);
        }
        else if(status === ''){
            this.props.showCustomSnackBar(getLocalizedStrings().message.WORKFLOWS.STATUS_BLANK_ALERT, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
        }
        else if(sales_rep === ''){
            this.props.showCustomSnackBar(getLocalizedStrings().message.WORKFLOWS.SALES_REP_BLANK_ALERT, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
        }else{
            return true;
        }
    }

    convert = () => {
        if(this.isSelectedFieldBlank()){
            let data = getObjectParam(this.props.data);
            let ids = getArrayParam(data.selectedRecords);
            let params = {};
            params.ids = ids;
            params.workflow_id = this.state.workflowId;
            params.workflow_name = this.state.workflowName;
            params.status = this.state.status;
            if (this.state.groupId !== null && this.state.groupId > 0) {
                params.groups = [this.state.groupId];
            }
            params.salesRep = this.state.salesRep;
            params.note = this.state.flyingNoteText;
            params.isNotifySalesRep = this.state.isNotifySalesRep;
            params.is_text_notify_sales_rep = this.state.isTextNotifySalesRep;
            let msg = getLocalizedStrings().message.BATCH_ACTION.CONVERT_SUCCESSFULL;
            let objectLabel = getObjectLabelByObject(constants.CONTACTS_OBJECT);
            let objectLabelAccount = getObjectLabelByObject(constants.ACCOUNTS_OBJECT);

            this.setState({ isSaveClicked: true });

            let promise = Promise.resolve(convertToAccount(params));
            this.props.closeDrawer();
            this.props.showCustomSnackBar("Contacts conversion to Accounts is going on.Once done You will get a mail.", styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
        }

        /* if (ids.length >= 100) {
             let promise = Promise.resolve(convertToAccount(params));
             this.props.closeDrawer();
             this.props.showCustomSnackBar("Contacts conversion to Accounts is going on.Once done You will get a mail.", styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
         } else {
 
             let promise = Promise.resolve(convertToAccount(params));
             if (isValidParam(promise)) {
                 promise.then(response => {
                     if (isValidParam(response)) {
                         if (response.status === 0 && getBooleanParam(response.data.isSuccess)) {
                             let convertCount = getIntParam(response.data.recordCount);
 
                             if (convertCount === 0) {
                                 this.props.showCustomSnackBar("All the selected " + objectLabel + " have been already linked with " + objectLabelAccount+".", styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                                 this.props.closeDrawer();
                             } else {
                                 if (convertCount === 1) {
                                     msg = getLocalizedStrings().message.BATCH_ACTION.SINGLE_CONVERT_SUCCESSFULL;
                                 }
                                 this.props.showCustomSnackBar(getIntParam(response.data.recordCount) + " " + objectLabel + msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                                 this.props.closeDrawer();
                             }
                         } else {
                             this.props.showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION.CONVERT_UNSUCCESSFULL, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                         }
 
                     }
                 });
             }
         }*/
    }
    getAddOptonObject = (Id , value) =>{
        let temObj = {};
        temObj.id = Id;
        temObj.value = value;
        return temObj;
    }
     SearchForObjectsWithName = (arrayOfVariable, sortValue) => {
        let ansArray = [];
        arrayOfVariable.map((data) => {
            let toAdd = false
            Object.values(data).forEach((value) => {
                if (value !== null) {
                    let mainString = value.toString().replace(/\s/g, '').toLowerCase()
                    let subString = sortValue.replace(/\s/g, '').toLowerCase()
                    if (mainString.includes(subString)) {
                        toAdd = true
                    }
                }
            })
            if (toAdd) {
                ansArray.push(data)
            }
        })
        return (ansArray);
    }

    setWorkflowChange = (data) => {
            let _state = this.state;
            _state.workflow_error = false;
            let workflowListdata = this.state.workflowList;
            let tempWorflowList = [];
            workflowListdata.map(f => {
                tempWorflowList.push(this.getAddOptonObject(f.id, f.name));
            });
            let workflowName = data;
            let tempWorkflowArr = [];
            tempWorflowList.map(f => {
                tempWorkflowArr.push(this.getAddOptonObject(f.id, f.value));
            });
            if (data !== undefined) {
                let searchString = data;
                if (searchString.replace(/\s/g, '') !== '') {
                    tempWorkflowArr = this.SearchForObjectsWithName(tempWorkflowArr, searchString)
                }
            }
            _state.workflowName = workflowName;
            _state.searchedWorkflow = data;
            _state.selecetdWorkflowInput = data;
            _state.workflowMenuItems = tempWorkflowArr;
            this.setState(_state)
    }

    workFlowOnFocus = () => {
        let _state = this.state;
        _state.workflowShrink = true;
        this.setState(_state)
    }
    handleWorkflowOnBlur = () => {
        let _state = this.state;
        _state.selecetdWorkflowInput = this.state.selectedWorkflow;
        if (_state.selecetdWorkflowInput === null) {
            _state.selecetdWorkflowInput = '';
            _state.searchedWorkflow = null;
        }
        let workflowListdata = this.state.workflowList;
        let tempWorkflowList = [];
        workflowListdata.map(f => {
            tempWorkflowList.push(this.getAddOptonObject(f.id, f.name));
        });
        _state.workflowMenuItems = tempWorkflowList;
        _state.workflowShrink = false;
        _state.workflowName = _state.selecetdWorkflowInput;
        this.setState(_state)
    }


    render() {
        let shrink = this.state.selectedWorkflow !== null ? true : this.state.workflowShrink;

        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        let workflow_label = getLocalizedStrings().label.COMMON.WORKFLOW_NAME + (getLocalizedStrings().label.COMMON.REQUIRED);
        let status_label = getLocalizedStrings().label.COMMON.STATUS + (getLocalizedStrings().label.COMMON.REQUIRED);
        let salesrepList = this.populateSalesRepList();
        let sfFormFieldTextAreaStyle = { marginTop: '10px', height: '90%' };
        let sfFormFieldTextAreaInputSyle = { marginTop: '10px', overflowY: 'auto' };
        let sfFormFieldStyle = { width: '100%', backgroundColor: '#fff', border: '1px solid #dddddd', marginBottom: '12px' };
        return <div style={styles.sfFormContainer}>
            {this.state.isSaveClicked ?
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div> :
                <div>
                    <div style={{ display: this.state.isWorkflowShow ? 'block' : 'none' , marginTop:'3px'}}>
                        <div style={{ ...styles.sf_12 }}>
                            <FormControl style={{ width: '100%', height:'25px',marginBottom:'25px'}}>
                            <Autocomplete
                                id={"workflow_name_" + this.state.workflowName}
                                disableClearable
                                filterOptions={(x) => x}
                                options={isValidParam(this.state.workflowMenuItems) ? this.state.workflowMenuItems : []}
                                onChange = {(e, newValue) => this.setFieldValue('workflowName', e, newValue)}
                                getOptionLabel={(option) => option.value ? option.value : ''}
                                value={this.state.workflowMenuItems.find(v => v.value === this.state.workflowName)}
                                inputValue={this.state.workflowName}
                                renderInput={(params) => <TextField  {...params}
                                InputLabelProps={{
                                    shrink: shrink,
                                }}
                                autoFocus={this.state.workflow_error} error={this.state.workflow_error} onChange={(e) => this.setWorkflowChange(e.target.value)} label={workflow_label} variant="outlined" margin="dense" size='small' />}
                                onFocus={() => this.workFlowOnFocus()}
                                onBlur={() => this.handleWorkflowOnBlur()}
                            />
                            </FormControl>
                        </div>
                    </div>

                    <div style={{ display: this.state.isWorkflowShow ? 'block' : 'none', marginTop:'3px' }}>
                        <div style={{ ...styles.sf_12 }}>
                            <FormControl style={{ width: '100%', height:'25px', marginBottom:'25px'}}>
                                <Autocomplete
                                    id={"status_" + this.state.status}
                                    disableClearable
                                    filterOptions={(x) => x}
                                    options={isValidParam(this.state.statusMenuItems) ? this.state.statusMenuItems : []}
                                    getOptionLabel={(option) => option.primaryText ? option.primaryText : ''}
                                    value={this.state.statusMenuItems.find(v => v.value === this.state.status)}
                                    inputValue={this.state.status}
                                    renderInput={(params) => <TextField {...params} label={status_label} variant="outlined" margin='dense' size='small'/>}
                                    onChange={(event, value) => this.setFieldValue('status', event, value.value)}
                                />
                            </FormControl>

                        </div>
                    </div>


                    <div style={{ display: this.state.isWorkflowShow ? 'block' : 'none' , marginTop:'3px'}}>
                        <div style={{ ...styles.sf_12 }}>
                            <Autocomplete
                                disableClearable
                                options={isValidParam(salesrepList) ? salesrepList : []}
                                value={salesrepList.find(v => v.value === this.state.salesRep)}
                                id={'sales_rep_' + this.state.salesRep}
                                getOptionLabel={(option) => option.primaryText}
                                onChange={(event, value) => this.setFieldValue('salesRep', event, value.value)}
                                renderInput={(params) => <TextField {...params} label={getLocalizedStrings().label.COMMON.SALESREP} variant="outlined" margin='dense'  size='small'/>}
                            />
                        </div>
                    </div>

                    <div style={{ display: this.state.isWorkflowShow ? 'block' : 'none' , marginTop:'3px' }}>
                        <div style={{ ...styles.sf_12 }}>
                            <Autocomplete
                                disableClearable
                                options={isValidParam(this.state.groups) ? this.state.groups : []}
                                value={this.state.groups.find(v => v.value === this.state.groupName)}
                                id={'groups_' + this.state.groupName}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, value) => this.setFieldValue('groupName', event, value.name)}
                                renderInput={(params) => <TextField {...params} label={getLocalizedStrings().label.COMMON.ADD_TO_GROUP} variant="outlined" margin='dense' size='small' />}
                            />
                        </div>
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <div>
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    key='flyingNoteText'
                                    type='textarea'
                                    multiline={true}
                                    rows={3}
                                    label={getLocalizedStrings().label.COMMON.NOTE}
                                    textareastyle={sfFormFieldTextAreaStyle}
                                    onChange={(e) => this.setFlyingNoteText(e.target.value)}
                                    style={sfFormFieldStyle}
                                    value={this.state.flyingNoteText}
                                    autoComplete="new-password"
                                    className={"sf-fields-bg"}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div style={{marginTop: '-8px'}}>
                        <div >
                            <FormControlLabel
                                control={<Checkbox
                                    color="default"
                                    key='notify_sales_rep'
                                    name='notify_sales_rep'
                                    checked={this.state.isNotifySalesRep}
                                    value={this.state.isNotifySalesRep}
                                    onChange={(e) => this.onCheckedSalesRep(e.target.checked)}
                                    defaultChecked={true} />}
                                label={getLocalizedStrings().label.COMMON.EMAIL + " " + getLocalizedStrings().label.COMMON.SALESREP}
                                labelposition="right"
                                labelstyle={{ ...styles.text, maxWidth: '90%', lineHeight: '24px' }}
                                style={{ height: 'auto' }}

                            />

                        </div>
                        {this.props.app.me.phone_text_provider && this.props.app.me.phone_text_provider!= constants.NONE && this.props.app.me.notify_text_salesrep && <div>
								<FormControlLabel
									control={
										<Checkbox
											color="default"
											key="notify_text_sales_rep"
											name="notify_text_sales_rep"
											checked={this.state.isTextNotifySalesRep}
											value={this.state.isTextNotifySalesRep}
                                            onChange={(e) => this.onCheckedTextNotifySalesRep(e.target.checked)}
										/>
									}
									label={getLocalizedStrings().label.COMMON.TEXT + " " + getLocalizedStrings().label.COMMON.SALESREP}
									labelposition="right"
									labelstyle={{
										color: "#717171",
										padding: "0px",
										width: "auto",
										fontWeight: "normal",
									}}
								/>
							</div>}
                    </div>
                    <div className='row'>
                        <div className='col-sm-12' style={{ textAlign: 'right' }}>
                            {this.getActions()}
                        </div>
                    </div>
                </div>

            }

        </div>


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConvertToAccount);
