import React, { useState } from "react";
import GroupDetailViewMenu from "../components/GroupDetailViewMenu";
import DetailViewFields from "../components/detailViewFields";
import GroupCampaignDetails from "../components/GroupCampaignDetails";
import ListView from "./ListView";
import { constants } from "../../../services/constants/constants";
import PropTypes from "prop-types";
import {
	getActiveTab,
	getActiveTabInfo,
} from "../../../services/helper/sfTabManager";
import {
	isValidParam,
	getObjectParam,
	getIntParam,
} from "../../../services/helper/parameterVerifier";
import { useSelector } from "react-redux";

const GroupDetailView = ({ id, fieldsRecords, object }) => {
	const listView = useSelector((state) => state.childListView);
	const [selectedGroupChildObject, setSelectedGroupChildObject] =
		useState("Details");
	const mounted = useState(true);

	const listviewCallBack = (selectedChildObj) => {
		let info = getObjectParam(getActiveTabInfo());
		info.groupChildObj = selectedChildObj;
		setSelectedGroupChildObject(selectedChildObj);
	};

	const populateFiltersFromActiveTab = () => {
		let params = null;
		try {
			let tab = getActiveTab();
			if (
				isValidParam(tab) &&
				isValidParam(tab.info) &&
				tab.info.hasOwnProperty("filter")
			) {
				if (isValidParam(tab.info.filter)) {
					let filter = getObjectParam(tab.info.filter);
					let filterId = getIntParam(filter.id);
					params = {
						query_id: filterId,
						query_type: filter.type,
						query_name: filter.name,
					};
				}
			}
		} catch (error) {
			console.error(
				"Error in 'GroupDetailView.js -> populateFiltersFromActiveTab()':" +
					error
			);
		}
		return params;
	};

	let tab = (getObjectParam(getActiveTab()));
	let childObj =  tab.info.groupChildObj ;
	if(childObj=== null || childObj === undefined){
		childObj =selectedGroupChildObject;
	}
	
	let childQueryParams = new Object();
	if(object == constants.GROUP_OBJECT && childObj != null &&  childObj != 'Details') {
		childQueryParams = populateFiltersFromActiveTab();
	}
	

	return (
		<>
			{mounted && tab.object === constants.GROUP_OBJECT ? (
				<div style={{flexDirection: 'row',display: 'flex'}}>
					<div
						className="col-sm-3"
						style={{ borderRight: "1px solid #eee", width: "13%" }}
					>
						<GroupDetailViewMenu
							id={id}
							listviewCallBack={listviewCallBack}
							selectedMenu={childObj}
							isDetailviewRefresh={listView.isRefreshDetailViewListView}
						/>
					</div>
					{childObj !== null && childObj === "Details" && (
						<div
							className="col-sm-3"
							style={{ borderRight: "1px solid #eee", width: "20%" }}
						>
							<DetailViewFields fieldsRecords={fieldsRecords} object={object} />
						</div>
					)}
					{childObj !== null && childObj !== undefined && childObj === "Details" && (
						<div
							className="col-sm-6"
							style={{ borderRight: "1px solid #eee", width: "67%" }}
						>
							<GroupCampaignDetails id={id} />
						</div>
					)}
					{object === constants.GROUP_OBJECT &&
						childObj !== null && childObj !== undefined &&
						childObj !== "Details" && (
							<div
								className="col-sm-9"
								style={{
									borderRight: "1px solid #eee",
									width: '87%',
									paddingTop: "15px",
									paddingBottom: "15px",
								}}
							>
								<ListView
									object={childObj}
									parentObject={object}
									parentRecordId={id}
									isDetailView={true}
									childQueryParams={childQueryParams}
								/>
							</div>
						)}
				</div>
			) : (
				<div></div>
			)}
		</>
	);
};

GroupDetailView.propTypes = {
	id: PropTypes.number,
	fieldsRecords: PropTypes.object,
	object: PropTypes.string,
};

export default GroupDetailView;
