import React from 'react';
import TextField from '@mui/material/TextField';
import {FormControl,Chip} from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { isInteger } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const ReminderBlock =({onUpdate,reminders})=> {
   
    const dispatch = useDispatch();
    const [rowseData, setRows] = useState(reminders);

    const updateValue = (obj, idx) => {
        let rows = rowseData;
        rows[idx] = obj.value;
        setRows([...rows]);
        let data = [];
        rows.forEach(function (row) {
            data.push(row);
        });
        onUpdate(data);
    }
    useEffect(() => {
        setRows(reminders);
    },[reminders,rowseData]);

    
    const addRow = () => {

        let rows = rowseData;
        let temoRows =   {
            t_reminder_type: "Email",
            t_time_value: "10",
            t_time_type: "minutes",
        };
      
        rows = [...rows, temoRows];
        setRows(rows);
        onUpdate(rows);

    }

    const deleteRow = (idx) => {
        let rows = rowseData;
        rows.splice(idx, 1);

        setRows([...rows]);
        onUpdate(rows);
    }

        if (rowseData === null || rowseData === 'undefined') {
            setRows([]);
        }

        return (
            <div style={{ paddingRight: '10px' }}>
                {rowseData.map(
                    (row, index) => {
                        return (
                            <Row
                                value={row}
                                updateRow={ (obj, index) =>updateValue(obj, index) }
                                deleteRow={ (index)=>deleteRow(index) }
                                index = {index}
                            />
                        )
                    }
                )}

                {rowseData.length < 3 &&
                    <div style={{ paddingBottom: '10px', paddingLeft: '5px', paddingTop: '3px', color: '#6495ED' }}>
                        <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={()=>addRow()}><a>{getLocalizedStrings().label.APPOINTMENTS_TASKS.ADD_REMINDER}</a></span>
                    </div>
                }

            </div>
        )
    
}


const Row =({value,updateRow,deleteRow,index}) => {
    

    const [t_reminder_type, setTreminderType] = useState(value.t_reminder_type);
    const [t_time_value, setTimeValue] = useState(value.t_time_value);
    const [t_time_type, setTimeType] = useState(value.t_time_type);

    let reminder_type_options = [
        {
            label: 'Email',
            value: 'Email'
        },
        {
            label: 'Pop-up',
            value: 'Pop-up'
        },
    ];
    
    let time_type_options = [
        {
            label: 'minutes',
            value: 'minutes'
        },
        {
            label: 'hours',
            value: 'hours'
        },
        {
            label: 'days',
            value: 'days'
        },
        {
            label: 'weeks',
            value: 'weeks'
        },
    ];

    useEffect(() => {
        setTreminderType(value.t_reminder_type);
        setTimeValue(value.t_time_value);
        setTimeType(value.t_time_type);
    },[value]);

    const handleReminderTypeChange = (event,value) => {

        setTreminderType(value.value);
        updateRow({ value: {t_reminder_type:value.value,t_time_value:t_time_value,t_time_type:t_time_type} },index);
    }

    const handleTimeTypeChange = (event,value) => {
        setTimeType(value.value);
        updateRow({ value: {t_reminder_type:t_reminder_type,t_time_value:t_time_value,t_time_type:value.value} },index);
    }

    const handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        if (value === '' || isInteger(value)) {
            const name = target.name;
            if (name === 't_reminder_type') {
                setTreminderType(value);
            } else if (name === 't_time_value') {
                setTimeValue(value);
            } else {
                setTimeType(value);
            }
            updateRow({ value: { t_reminder_type: t_reminder_type, t_time_value: value, t_time_type: t_time_type } },index)
        }
    };

  
        let time_options = [];
        let reminder_options = [];

        time_type_options.forEach((option) => {
            let op = {};
            op.label = getLocalizedStrings().label.APPOINTMENTS_TASKS[option.label];
            op.value = option.value;
            time_options.push(op);
        });

        reminder_type_options.forEach((option) => {
            let op = {};
            op.label = getLocalizedStrings().label.APPOINTMENTS_TASKS[option.label];
            op.value = option.value;
            reminder_options.push(op);
        });
        if (time_options.length > 0 && reminder_options.length > 0) {
            return (
                <div style={{display:'flex' , width: '100%'}}>
                    <div style={{...styles.sf_5 , paddingRight: '0px', marginRight: '0px', height: '48px' }}>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off" 
                               >
                            <Autocomplete
                                key={'reminder_type'}
                                id={'reminder_type'}
                                name="t_reminder_type"
                                value={reminder_options.find(v => v.label === t_reminder_type)}
                                options={reminder_options}
                                onChange={(event, newValue) => handleReminderTypeChange(event,newValue)}
                                disableClearable={true}
                                size='small'
                                renderInput={(params) => <TextField {...params}  variant="outlined" margin="dense" size='small' className={"sf-fields-bg"}/>}
                            />

                        </FormControl>
                    </div>
                    <div style={{...styles.sf_2 , margin: '0px',  overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                           
                            <input
                                autoComplete="new-password"
                                type='text'
                                name="t_time_value"
                                style={{ paddingTop: "19px",outline:'none', border: "0px", background: "transparent", color: "black", textAlign: "center", width: "100%", fontSize:15}}                                maxlength="3"
                                onChange={(event)=>handleChange(event)} value={t_time_value}></input>
                        
                    </div>
                    <div style={{...styles.sf_4 , padding: '0px', margin: '0px' }}>
                        <FormControl style={{ width: '110%' }} className="test" noValidate autoComplete="off"
                              >
                          { time_options.length >0 &&
                           <Autocomplete
                                key={'time_type'}
                                id={'time_type'}
                                name="t_time_type"
                                value={time_options.find(v => v.label === t_time_type)}
                                options={time_options}
                                onChange={(event, newValue) => handleTimeTypeChange(event,newValue)}
                                size='small'
                                disableClearable={true}    
                                renderInput={(params) => <TextField {...params}  variant="outlined" margin="dense" size='small'  className={"sf-fields-bg"} />}
                            />
                          }
                        </FormControl>
                    </div>
                    <div  style={{width:'30px' , padding: '0px', margin: '0px'}} >
                        <div onClick={()=>deleteRow(index)} style={{ cursor: 'pointer', marginTop: '20px', marginLeft: '26px'}} title={getLocalizedStrings().label.APPOINTMENTS_TASKS.REMOVE_REMINDER}>
                            <i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }}></i>
                        </div>
                    </div> 
                </div>
            )
        } else {
            return (
                <div className="row clearfix">

                </div>
            )
        }
    
}

export default ReminderBlock;

