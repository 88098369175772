import { actions } from '..//constants/actions'

const usefulLinkState = {
    mounted: false,
    usefulLinks: [],   
}

const usefulLinkReducer = (state = usefulLinkState, action) => {
    switch (action.type) {
        case actions.USEFUL_LINKS_GET_FULFILLED:
            return {
                ...state,
                mounted: true,
                usefulLinks: formatData(action.payload),
            }
        default:
            return state;
    }
}

export default usefulLinkReducer;

const formatData = (response) => {
    let arrCategory = [];
    try {
        let arrData = JSON.parse(response);
        arrCategory = arrData.filter( f => {return f.t_type === 'category' });
        arrCategory.forEach( m => { 
            m.items = arrData.filter( f => { return f.t_type === 'link' && f.category_name === m.category_name});
        });
    } catch (error) {
        console.error("Error in 'usefulLinkReducer.js -->  formatData()' "+error);
    }
    return arrCategory;
}