
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { isValidParam, getStringParam, getObjectParam, getIntParam, getBooleanParam, getArrayParam, makeValidAppUrl, isInteger } from '../../../services/helper/parameterVerifier';
import { setHeader } from '../../../services/actions/headerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import { sortArrayObjectByProperty, sortArrayObjectByPropertyForCurrency } from '../../../services/helper/utils';
import { constants } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import { FormControl, Select, MenuItem, InputLabel, Icon } from '@mui/material';
import SFDataTable from '../components/sfDataTable';
import * as sfDialogs from '../components/sfDialogs';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { addTab, getActiveTab, TYPE_OTHER_VIEW, addNewFormTabs, getTabsByItem, updateTabByIndex, TYPE_DETAIL_VIEW } from '../../../services/helper/sfTabManager';
import ShowCircularProgress from '../components/circularProgress';
import { updateRoyaltyReportData } from '../../../services/actions/royaltyReportActions';
import { LinkToType } from '../../../services/constants/link';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from "react-redux";
import { json } from 'react-router';

const optionvalue = ["", "1st", "2nd", "3rd", "4th", "5th", "6th",
    "7th", "8th", "9th", "10th", "11th", "12th", "13th", "14th", "15th", "16th",
    "17th", "18th", "19th", "20th", "21st", "22nd", "23rd", "24th", "25th", "26th",
    "27th", "28th", "29th", "30th", "31st", "32nd", "33rd", "34th", "35th", "36th",
    "37th", "38th", "39th", "40th", "41st", "42nd", "43rd", "44th", "45th", "46th",
    "47th", "48th", "49th", "50th", "51st", "52nd", "53rd"
];

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const CustomDateInputStyle = () => {
    return <style> {
        ".form-control[readonly] {background-color: #fff !important;}"
    }</style>
}



const RoyaltyReport = ({ style }) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const report = useSelector((state) => state.report);
    const sfForm = useSelector((state) => state.sfForm);
    const royaltyReports = useSelector((state) => state.royaltyReport.royaltyReportData);

    const [dueBy, setDueBy] = useState(new Date().getFullYear());
    const [recent_record_id, setRecent_record_id] = useState(0);
    const [mounted, setMounted] = useState({ isMounted: false });

    //const [isMounted, setIsMounted] = useState(false);
    const [fields, setFields] = useState([]);
    const [tempRecords, setTempRecords] = useState({
        records: []
    })
    // const [records, setRecords] = useState([]);
    const [rowSort, setRowSort] = useState({
        sortFieldName: 'franchisee',
        isDescending: false,
        onSort: (sortFieldName, isDescending) => onSortFn(sortFieldName, isDescending)
    });
    const [royalty_percent, setRoyalty_percent] = useState(5);
    const [showRecord, setRecordShow] = useState({ show: "All" });
    const [royalty_cal, setRoyalty_cal] = useState('fixed');
    const [weekType, setWeekType] = useState('Mon - Fri');
    const [timeLine, setTimeLine] = useState(new Date().getMonth());
    const [reportParams, setReportParams] = useState({});
    const [marketingInfo, setMarketingInfo] = useState({
        marketingFeeLabel: getLocalizedStrings().label.ROYALTY_REPORT.MARKETING_FEE,
        marketingFeeType: "fixed",
        marketingFee: 0
    })

    const [techInfo, setTechInfo] = useState({
        techFeeLabel: getLocalizedStrings().label.ROYALTY_REPORT.TECH_FEE,
        techFeeType: "fixed",
        techFee: 0
    })
    const [changeLog, setchangeLog] = useState({calculate:''});

    useEffect(() => {
        try {

            let reportData = getObjectParam(royaltyReports);
            let getfromRedux = false;
            let loadRecords = false;
            let _dueBy = dueBy;
            let _timeLine = timeLine;
            let tempMarketinfinfo = marketingInfo;
            let temptechInfo = techInfo;
            if (reportData.hasOwnProperty('configParams')) {
                let configParams = getObjectParam(reportData.configParams);
                if (configParams.hasOwnProperty('due_by') && getStringParam(configParams.due_by) !== "") {
                    let tempDueBy = getStringParam(configParams.due_by).split("-", 2)
                    _dueBy = tempDueBy[0];
                    _timeLine = tempDueBy[1];
                    getfromRedux = true;

                } else if (getStringParam(configParams.calculation_type) === "Weekly") {
                    let currentDate = new Date();
                    let startDate = new Date(currentDate.getFullYear(), 0, 1);
                    let days = Math.floor((currentDate - startDate) /
                        (24 * 60 * 60 * 1000));
                    setTimeLine(Math.ceil(days / 7));

                    loadRecords = true;
                } else if (getStringParam(configParams.calculation_type) === "Monthly") {
                    loadRecords = true;
                }

                if (configParams.hasOwnProperty('royalty_percent')) {
                    let tempRoyalty_percent = configParams.royalty_percent;
                    setRoyalty_percent(tempRoyalty_percent);
                }
                if (configParams.hasOwnProperty('show')) {
                    let tempShow = getStringParam(configParams.show);
                    if (isValidParam(tempShow) && tempShow !== '') {
                        let temp = showRecord
                        temp.show = tempShow;
                        setRecordShow(temp)
                    }

                }
                if (configParams.hasOwnProperty('calculation_type')) {
                    let calculation_type = getStringParam(configParams.calculation_type);
                    if (isValidParam(calculation_type) && calculation_type !== '') {
                        let temp =changeLog;
                        temp.calculate=calculation_type;
                        setchangeLog({...temp});
                    }

                }
                if (configParams.hasOwnProperty('week_type')) {
                    let week_type = getStringParam(configParams.week_type);
                    if (isValidParam(week_type) && week_type !== '') {
                        setWeekType(week_type);
                    }

                }
                if (configParams.hasOwnProperty('marketing_fee_label')) {
                    tempMarketinfinfo.marketingFeeLabel = configParams.marketing_fee_label !== null && configParams.marketing_fee_label !== "" ? configParams.marketing_fee_label : getLocalizedStrings().label.ROYALTY_REPORT.MARKETING_FEE;
                    setMarketingInfo({ ...tempMarketinfinfo })
                }
                if (configParams.hasOwnProperty('marketing_fee_type')) {
                    tempMarketinfinfo.marketingFeeType = configParams.marketing_fee_type !== null && configParams.marketing_fee_type !== "" ? configParams.marketing_fee_type : "fixed";
                    setMarketingInfo({ ...tempMarketinfinfo })
                }
                if (configParams.hasOwnProperty('marketing_fee')) {
                    tempMarketinfinfo.marketingFee = configParams.marketing_fee !== null && configParams.marketing_fee !== "" ? configParams.marketing_fee : 0;
                    setMarketingInfo({ ...tempMarketinfinfo })
                }
                if (configParams.hasOwnProperty('tech_fee_label')) {
                    temptechInfo.techFeeLabel = configParams.tech_fee_label !== null && configParams.tech_fee_label !== "" ? configParams.tech_fee_label : getLocalizedStrings().label.ROYALTY_REPORT.TECH_FEE;
                    setTechInfo({ ...temptechInfo })
                }
                if (configParams.hasOwnProperty('tech_fee_type')) {
                    temptechInfo.techFeeType = configParams.tech_fee_type !== null && configParams.tech_fee_type !== "" ? configParams.tech_fee_type : "fixed";
                    setTechInfo({ ...temptechInfo })
                }
                if (configParams.hasOwnProperty('tech_fee')) {
                    temptechInfo.techFee = configParams.tech_fee !== null && configParams.tech_fee !== "" ? configParams.tech_fee : 0;
                    setTechInfo({ ...temptechInfo })
                }

            }

            if (getfromRedux) {

                if (reportData.hasOwnProperty('fields')) {
                    let tempFields = getArrayParam(reportData.fields);
                    setFields(tempFields);
                }

                if (reportData.hasOwnProperty('records')) {
                    let _records = getArrayParam(reportData.records);
                    let temp = tempRecords;
                    temp.records = _records;
                    setTempRecords(temp);
                }
                let tempRowSet = rowSort;
                let tempMount = mounted;
                tempMount.isMounted = true;
                tempRowSet.isDescending = cookieGetSortData();
                setRowSort({ ...tempRowSet });
                setDueBy(_dueBy);
                setTimeLine(_timeLine);
                setMounted(tempMount);

                setReportParams(reportData.configParams);
            } else if (loadRecords) {
                getReportData();
            } else {
                getReportConfig();
            }
            dispatch(setHeader(constants.ROYALTY_REPORT, getLocalizedStrings().label.MODULE_ITEMS.Royalties, null, false, true));
        } catch (error) {
            console.error("Error in 'royaltyReport.js -> componentDidMount()'" + error);
        }

    }, []);

    const getReportConfig = () => {
        let url = endPoints.ROYALTY_REPORT.GET_REPORT_CONFIG;
        let promise = Promise.resolve(HTTPClient.get(url));
        let tempMarketinfinfo = marketingInfo;
        let temptechInfo = techInfo;
        promise.then((response) => {
            if (isValidParam(response)) {
                let tempMount = mounted;
                tempMount.isMounted = true;
                setMounted(tempMount);
                let configObj = getObjectParam(response);
                if (configObj.hasOwnProperty('due_by') && getStringParam(configObj.due_by) !== "") {
                    let tempDueBy = getStringParam(configObj.due_by).split("-", 2)
                    if (isInteger(tempDueBy[0]) && isInteger(tempDueBy[1])) {
                        setDueBy(tempDueBy[0]);
                        setTimeLine(tempDueBy[1]);
                    }
                }
                if (configObj.hasOwnProperty('royalty_percent')) {
                    setRoyalty_percent(configObj.royalty_percent);
                }
                if (configObj.hasOwnProperty('show')) {
                    let temp = showRecord
                    temp.show = configObj.show;
                    setRecordShow(temp)
                }
                if (configObj.hasOwnProperty('royalty_cal')) {
                    setRoyalty_cal(configObj.royalty_cal);
                }
                if (configObj.hasOwnProperty('calculation_type')) {
                    let temp =changeLog;
                        temp.calculate=configObj.calculation_type;
                        setchangeLog({...temp});
                }
                if (configObj.hasOwnProperty('week_type')) {
                    let tempWeektype = weekType;
                    tempWeektype = configObj.week_type !== null && configObj.week_type !== "" ? configObj.week_type : weekType;
                    setWeekType(tempWeektype);
                }
                if (configObj.hasOwnProperty('marketing_fee_label')) {
                    tempMarketinfinfo.marketingFeeLabel = configObj.marketing_fee_label !== null && configObj.marketing_fee_label !== "" ? configObj.marketing_fee_label : getLocalizedStrings().label.ROYALTY_REPORT.MARKETING_FEE;
                    setMarketingInfo({ ...tempMarketinfinfo })
                }
                if (configObj.hasOwnProperty('marketing_fee_type')) {
                    tempMarketinfinfo.marketingFeeType = configObj.marketing_fee_type !== null && configObj.marketing_fee_type !== "" ? configObj.marketing_fee_type : "fixed";
                    setMarketingInfo({ ...tempMarketinfinfo })
                }
                if (configObj.hasOwnProperty('marketing_fee')) {
                    tempMarketinfinfo.marketingFee = configObj.marketing_fee !== null && configObj.marketing_fee !== "" ? configObj.marketing_fee : 0;
                    setMarketingInfo({ ...tempMarketinfinfo })
                }
                if (configObj.hasOwnProperty('tech_fee_label')) {
                    temptechInfo.techFeeLabel = configObj.tech_fee_label !== null && configObj.tech_fee_label !== "" ? configObj.tech_fee_label : getLocalizedStrings().label.ROYALTY_REPORT.TECH_FEE;
                    setTechInfo({ ...temptechInfo })
                }
                if (configObj.hasOwnProperty('tech_fee_type')) {
                    temptechInfo.techFeeType = configObj.tech_fee_type !== null && configObj.tech_fee_type !== "" ? configObj.tech_fee_type : "fixed";
                    setTechInfo({ ...temptechInfo })
                }
                if (configObj.hasOwnProperty('tech_fee')) {
                    temptechInfo.techFee = configObj.tech_fee !== null && configObj.tech_fee !== "" ? configObj.tech_fee : 0;
                    setTechInfo({ ...temptechInfo })
                }
            }
            getReportData();

        });

    }

    const getReportData = () => {
        let _records = [];
        let _fields = [];
        let _reportParams = {};
        let url = endPoints.ROYALTY_REPORT.GET_REPORT_DATA;
        _reportParams.due_by = dueBy + "-" + timeLine;
        _reportParams.royalty_percent = royalty_percent;
        _reportParams.show = showRecord.show;
        _reportParams.royalty_cal = royalty_cal;
        _reportParams.recent_record_id = getIntParam(recent_record_id);
        _reportParams.item_type = 'Royalties Report';
        _reportParams.parameter_id = 0;
        _reportParams.royalty_cal = royalty_cal;
        _reportParams.calculation_type = changeLog.calculate;
        _reportParams.week_type = weekType
        _reportParams.marketing_fee_label = marketingInfo.marketingFeeLabel;
        _reportParams.marketing_fee_type = marketingInfo.marketingFeeType;
        _reportParams.marketing_fee = marketingInfo.marketingFee;
        _reportParams.tech_fee_label = techInfo.techFeeLabel;
        _reportParams.tech_fee_type = techInfo.techFeeType;
        _reportParams.tech_fee = techInfo.techFee;
        let promise = Promise.resolve(HTTPClient.get(url, _reportParams));
        let tempMount = mounted;
        tempMount.isMounted = false;
        setMounted(tempMount);

        promise.then((response) => {
            if (isValidParam(response)) {

                _records = getArrayParam(response.records);
                _fields = getArrayParam(response.fields);
                let _rowSort = {
                    sortFieldName: 'franchisee',
                    isDescending: cookieGetSortData(),

                    onSort: (fieldName, isDescending) => onSortFn(fieldName, isDescending)
                }

                if (_records.length > 0) {
                    let totalRec = _records.filter(f => {
                        if (f.franchisee === 'Total') {
                            return f;
                        }
                    });
                    let tempRecordArr = _records.filter(f => {
                        if (f.franchisee !== 'Total') {
                            return f;
                        }
                    });


                    if (getBooleanParam(_rowSort.isDescending)) {
                        sortArrayObjectByPropertyDesc(tempRecordArr, _rowSort.sortFieldName);
                    } else {
                        sortArrayObjectByProperty(tempRecordArr, _rowSort.sortFieldName);
                    }


                    if (tempRecordArr.length > 0) {
                        if (totalRec.length > 0) {
                            tempRecordArr.push(totalRec[0]);
                        }
                        _records = tempRecordArr;
                    }

                }

                let objParams = { configParams: _reportParams, fields: _fields, records: _records };
                dispatch(updateRoyaltyReportData(objParams));
                let temp = tempRecords;
                temp.records = _records;
                let tempMount = mounted;
                tempMount.isMounted = true;
                setMounted(tempMount);
                setFields(_fields);
                setTempRecords(temp );
                setRowSort({ ..._rowSort });
                setReportParams(_reportParams);



            }

        });

    }


    const cookieSetSortData = (value) => {
        let userid = app.me.id;
        let cVarName = "isDescending_" + userid;
        if (value) {
            document.cookie = cVarName + "=yes";
        } else {
            document.cookie = cVarName + "=no";
        }
    }

    const cookieGetSortData = () => {
        let userid = app.me.id;
        let cVarName = "isDescending_" + userid + "=";
        let cookieVar = decodeURIComponent(document.cookie).split(';')
        for (var i = 0; i < cookieVar.length; i++) {
            var c = cookieVar[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(cVarName) === 0) {
                var value = c.substring(cVarName.length, c.length);
                if (value === "yes") {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return "";
    }

    const reloadData = (obj) => {
        try {
            let tempRoyalty_percent = royalty_percent;
            tempRoyalty_percent(obj.royaltyPercent);
            setRoyalty_percent(tempRoyalty_percent);
            let tempShow = showRecord.show;
            tempShow(obj.show);
            let temp = showRecord
            temp.show = tempShow;
            setRecordShow(temp)
            getReportData();
        } catch (error) {
            console.error("Error in 'royaltyReport.js -> reloadData()':" + error);
        }
    }



    const handleDropDownChange = (name, event) => {

        try {
            if (name === "due_by") {
                setDueBy(event);
            }
            if (name === "timeline") {
                setTimeLine(event);
            }
        } catch (error) {
            console.error("Error in 'royaltyReport.js -> handleDropDownChange()");
        }

    }
    
    const generateDueBySelectionField = () => {
        let menuArray = [];
        try {
            for (var i = 0; i < 5; i++) {
                menuArray.push(<MenuItem key={i} value={new Date().getFullYear() - i} style={styles.popoverMenuItem} >{new Date().getFullYear() - i}</MenuItem>)
            }
        }
        catch (error) {
            console.error("Error in 'RoyaltyReport.js -> generateDueBySelectionField()':" + error);
        }
        return menuArray;
    }

    const generateTimelineSelectionField = () => {
        let menuArray = [];
        try {
            if (changeLog.calculate === "Monthly") {
                let months = monthNames;
                for (var i = 0; i < months.length; i++) {
                    menuArray.push(<MenuItem key={months[i]} value={i} style={styles.popoverMenuItem} >{months[i]}</MenuItem>)
                }
            } else {
                let weekNo = weeksInYear(parseInt(dueBy));
                for (var i = 1; i <= weekNo; i++) {
                    menuArray.push(<MenuItem key={i} value={i} style={styles.popoverMenuItem} >{i}</MenuItem>)
                }
            }
        }
        catch (error) {
            console.error("Error in 'RoyaltyReport.js -> generateTimelineSelectionField()':" + error);
        }
        return menuArray;
    }

    const weeksInYear = (year) => {
        var month = 11,
            day = 31,
            week;

        // Find week that 31 Dec is in. If is first week, reduce date until
        // get previous week.
        do {
            let d = new Date(year, month, day--);
            week = getWeekNumber(d)[1];
        } while (week === 1);

        return week;
    }

    const getWeekNumber = (d) => {
        // Copy date so don't modify original
        d = new Date(+d);
        d.setHours(0, 0, 0, 0);
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setDate(d.getDate() + 4 - (d.getDay() || 7));
        // Get first day of year
        var yearStart = new Date(d.getFullYear(), 0, 1);
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
        // Return array of year and week number
        return [d.getFullYear(), weekNo];
    }

    const onSortFn = (fieldName, isDescending) => {
        let _rowSort = rowSort;
        let _records;
            _records = getArrayParam(tempRecords.records);
        let sortFieldName = getStringParam(fieldName);
        try {
            let totalRec = _records.filter(f => {
                if (f.franchisee === 'Total') {
                    return f;
                }
            });
            let tempRecordArr = _records.filter(f => {
                if (f.franchisee !== 'Total') {
                    return f;
                }
            });

            if (fieldName === 'total_invoices' || fieldName === 'royalties_owed') {
                if (getBooleanParam(_rowSort.isDescending)) {
                    sortArrayObjectByPropertyForCurrency(tempRecordArr, sortFieldName, app.me.currency_symbol);
                    _rowSort.isDescending = false;
                }
                else {
                    sortArrayObjectByPropertyDescForCurrency(tempRecordArr, sortFieldName, app.me.currency_symbol);
                    _rowSort.isDescending = true;
                }
            } else {
                if (getBooleanParam(_rowSort.isDescending)) {
                    sortArrayObjectByProperty(tempRecordArr, sortFieldName);
                    _rowSort.isDescending = false;
                }
                else {
                    sortArrayObjectByPropertyDesc(tempRecordArr, sortFieldName);
                    _rowSort.isDescending = true;
                }
            }

            if (tempRecordArr.length > 0) {
                if (totalRec.length > 0) {
                    tempRecordArr.push(totalRec[0]);
                }
                _records = tempRecordArr;
            }
            cookieSetSortData(_rowSort.isDescending);
            _rowSort.sortFieldName = sortFieldName;
            // _rowSort = rowSort;
            setRowSort({ ..._rowSort })
            let temp = tempRecords;
            temp.records = _records;
            setTempRecords(temp);

        }
        catch (error) {
            console.error("Error in 'RoyaltyReport.js -> onSort()':" + error);
        }
    }

    const sortArrayObjectByPropertyDesc = (array, property) => {
        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];

                    val1 = typeof val1 === "string" ? val1.toLowerCase() : val1;
                    val2 = typeof val2 === "string" ? val2.toLowerCase() : val2;

                    if (val1 > val2) {
                        return -1;
                    } else if (val1 < val2) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
        }
    }

    const sortArrayObjectByPropertyDescForCurrency = (array, property, currency) => {

        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];

                    val1 = typeof val1 === "string" ? val1.toLowerCase() : val1;
                    val2 = typeof val2 === "string" ? val2.toLowerCase() : val2;

                    val1 = val1.replace(currency, '');
                    val1 = val1.replace(',', '');
                    val1 = val1.replace('.', '');
                    val1 = val1.trim();

                    val2 = val2.replace(currency, '');
                    val2 = val2.replace(',', '');
                    val2 = val2.replace('.', '');
                    val2 = val2.trim();

                    val1 = getIntParam(val1);
                    val2 = getIntParam(val2);

                    if (val1 > val2) {
                        return -1;
                    } else if (val1 < val2) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
        }
    }

    const replaceCurrencySymbols = (htmldata) => {
        htmldata = htmldata.replace(/€/gi, '&#x20AC;');
        htmldata = htmldata.replace(/￦/gi, '&#x20A9;');
        htmldata = htmldata.replace(/£/gi, '&#xa3;');
        htmldata = htmldata.replace(/₺/gi, '&#x20BA;');
        htmldata = htmldata.replace(/￥/gi, '&#xa5;');
        htmldata = htmldata.replace(/руб./gi, '&#x440;' + '&#x443;' + '&#x0431;' + ".");
        return htmldata;
    }

    const downloadExcel = () => {
        let rows = getArrayParam(tempRecords.records);
        if (rows.length > 0) {
            let beforeHeaderComponent = excelGenerateBeforeHeaders();
            let headerComponents = excelGenerateHeaders();
            let rowComponents = excelGenerateRows();
            let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '</tbody></table>';
            let htmlData = htmlTable;
            if (isValidParam(htmlData) && htmlData !== '') {
                htmlData = htmlData.replace("%22", "\"");
                htmlData = replaceCurrencySymbols(htmlData);
                var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
                var downloadUrl = URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = downloadUrl;
                a.download = 'royaltyResultSet_' + new Date().getTime() + '.xls';
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        } else {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.NO_RECORD_EXPORT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
    }

    const excelGenerateBeforeHeaders = () => {
        let cells = '';
        let tempDueBy = '';
        let reportName = getStringParam(getLocalizedStrings().label.ROYALTY_REPORT.ROYALTY_REPORT_LABEL);
        let columns = fields.filter(f => {
            if (!f.is_hidden) { return f; }
        });
        let columnsLength = getArrayParam(columns).length;

        cells = cells + '<tr><td  colspan="' + (columnsLength - 1) + '"> ' + reportName + ' </td></tr>';
        if (reportName.length > 0) {
            let tempTimeLine = changeLog.calculate === "Monthly" ? monthNames[timeLine] + ", " : optionvalue[timeLine] + " Week of ";
            tempDueBy = getStringParam(tempTimeLine + dueBy);
            cells = cells + '<tr><td>' + getLocalizedStrings().label.CALENDAR.DATE + ':</td>  <td colspan="' + (columnsLength - 2) + '"> ' + tempDueBy + ' </td></tr>';
        }
        return cells;
    }

    const excelGenerateHeaders = () => {
        let cells = '';
        let reportName = getStringParam(getLocalizedStrings().label.ROYALTY_REPORT.ROYALTY_REPORT_LABEL);
        let columns = fields.filter(f => {
            if (!f.is_hidden && f.name !== 'invoice') { return f; }
        });
        columns.map(f => {
            cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    const excelGenerateRows = () => {
        let rows = tempRecords.records;
        let columns = getArrayParam(fields);
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            let colVal = '';
            rows.map((rowObject, rowIndex) => {
                let innerCells = '';
                columns.map((columnObject, colIndex) => {
                    if (!columnObject.is_hidden && columnObject.name !== 'invoice') {
                        colVal = isValidParam(rowObject[columnObject.name]) ? rowObject[columnObject.name] : '';

                        if (rowIndex === (rows.length - 1)) {
                            innerCells = innerCells + '<td  bgcolor="gray"><b>' + colVal + '</b></td>';
                        } else {

                            if (rowIndex % 2 === 0) {
                                innerCells = innerCells + '<td >' + colVal + ' </td>';
                            } else {
                                innerCells = innerCells + '<td  bgcolor="Lavender">' + colVal + ' </td>';
                            }
                        }
                    }
                })
                if (rowIndex % 2 === 0) {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                } else {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                }

            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
        }
        return rowData;

    }

    const getReportFilter = () => {
        let div = <div className="row" style={{ padding: '0px', display: "flex", marginTop: '5px'}}>
            <CustomDateInputStyle />
            <iframe id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute', display: 'none' }}></iframe>
            <div className="col-md-1" style={{ paddingLeft: '0px', width: '13%' }} >
                <FormControl variant="outlined" style={{ width: '100%' }} className='appontmentdd-FormControl'>
                    <InputLabel id="sf-royaltyreport-simple-select-outlined-label" className="sf-royaltyreport">{getLocalizedStrings().label.ROYALTY_REPORT.YEAR}</InputLabel>
                    <Select
                        key="due_by"
                        name="due_by"
                        labelId='noteTypeList-label'
                        id='noteTypeList'
                        value={dueBy}
                        size="small"
                        className={"sf-select-height"}
                        label={getLocalizedStrings().label.ROYALTY_REPORT.YEAR}
                        onChange={(event) => handleDropDownChange('due_by', event.target.value)}
                    >
                        {generateDueBySelectionField()}
                    </Select>
                </FormControl>
            </div>
            <div className="col-md-1" style={{ paddingLeft: '0px', width: '13%' }} >
                <FormControl variant="outlined" style={{ width: '100%' }} className='appontmentdd-FormControl'>
                    <InputLabel id="sf-royaltyreport-simple-select-timeline-outlined-label" className="sf-royaltyreport">{changeLog.calculate === "Monthly" ? getLocalizedStrings().label.CALENDAR.MONTH : getLocalizedStrings().label.CALENDAR.WEEK}</InputLabel>
                    <Select
                        key="timeline"
                        name="timeline"
                        labelId='timeline-label'
                        id='noteTypeList'
                        value={timeLine}
                        size="small"
                        className={"sf-select-height"}
                        label={changeLog.calculate === "Monthly" ? getLocalizedStrings().label.CALENDAR.MONTH : getLocalizedStrings().label.CALENDAR.WEEK}
                        onChange={(event) => handleDropDownChange('timeline', event.target.value)}

                    >
                        {generateTimelineSelectionField()}
                    </Select>
                </FormControl>
            </div>
            <div className="col-md-1" style={{ paddingLeft: '0px', display: 'block', width: '15%', marginLeft: '-6px', paddingTop: '9px' }}>{/*paddingRight: '39px', */}
                {/* <Icon onClick={this.getReportData} title={getLocalizedStrings().label.REPORT.REFRESH} style={{ cursor: 'pointer', color: '#717171' }}>autorenew</Icon> */}
                <Button
                    onClick={() => getReportData()}
                    style={{
                        ...styles.primaryButton,
                        minWidth: '56px', width: '19%',
                        marginTop: 8
                    }}
                    title={getLocalizedStrings().label.REPORT.GO}
                > {getLocalizedStrings().label.REPORT.GO}</Button>
            </div>
            <div className="col-md-1" style={{ paddingLeft: '0px', width: '55%'}} >

                <div>
                    <div style={{ float: 'right', display: 'inline-block'}}>
                        <div style={{ float: 'left', marginTop: '1px' }}>
                            <Icon onClick={() => {
                                var content = document.getElementById("divcontents");
                                var ifrmae = document.getElementById("ifmcontentstoprint");
                                ifrmae.style.display = 'block'
                                var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                                ifrmae.style.display = 'block'
                                pri.document.open();
                                pri.document.write(content.innerHTML);
                                pri.document.close();
                                pri.document.title = getLocalizedStrings().label.ROYALTY_REPORT.ROYALTY_REPORT_LABEL
                                pri.focus();
                                pri.print();
                                ifrmae.style.display = 'none'
                            }} title={getLocalizedStrings().label.REPORT.PRINT} style={{ cursor: 'pointer', color: '#717171' }}>print</Icon>
                        </div>
                        <div style={{ float: 'left' , paddingLeft : '10px'  }}>
                            <i onClick={() => { downloadExcel(); }} className="far fa-file-excel" title="Excel" style={{ fontSize: '20px', color: 'green', cursor: 'pointer', paddingTop: '3px' }} />
                        </div>
                    </div>
                </div>
            </div>
        </div >

        return div;
    }


    const getNoRecordContent = () => {
        return (<table className="layout_maintable" style={{ width: '100%' }}><tr><td><span style={{ color: 'red' }}>{getLocalizedStrings().label.INXPRESS_REPORT.NO_RECORDS_FOUND}</span></td></tr></table>);
    }

    const customEvent = (eventType, column, row, selectedValue, event) => {

        try {
            var royaltyRecordId = 0;
            var accountRecordId = 0;
            var clientId = 0;
            row = getObjectParam(row);
            if (column.name === 'invoice' || column.name === 'royalties_owed' || column.name === 'franchisee') {
                royaltyRecordId = getArrayParam(row['royalty_recordid']);
                accountRecordId = getIntParam(row['account_record_id']);
                clientId = getIntParam(row['project_id']);
            }

            if (accountRecordId > 0) {
                // let row = getObjectParam(row); 

                if (royaltyRecordId.length > 0 && column.name === 'invoice') {
                    let info = { id: row.hasOwnProperty("qTpk") ? row.qTpk : 0, title: row.hasOwnProperty("qTpk") ? row.invoice_no : "Add Quotes & Invoices", type: row.hasOwnProperty("qTpk") ? LinkToType.TYPE_EDIT : "New", object: "quotations", parentObject: "accounts", parentRecordId: accountRecordId, royalty_record_id: royaltyRecordId, tmpCid: clientId, callFor: constants.ROYALTY_OBJECT, marketingFeeLabel: marketingInfo.marketingFeeLabel, techFeeLabel: techInfo.techFeeLabel, row: row, calculateType: changeLog.calculate, selectedValue: selectedValue }
                    if (row.hasOwnProperty("qTpk")) {
                        info.quotationNo = row.invoice_no;
                    }
                    addNewFormTabs(info);
                } else if (royaltyRecordId.length > 0 && column.name === 'royalties_owed') {

                    openRoyaltyListView(row);

                } else if (column.name === 'franchisee') {
                    openUnitDetailView(accountRecordId, getStringParam(row['franchisee']));
                }
            }
        }
        catch (error) {
            console.error("Error in 'RoyaltyReport.js -> customEvent()':" + error);
        }
    }

    const openRoyaltyListView = (row) => {
        try {
            let reportLabel = "";
            let tempTimeLine = changeLog.calculate === "Monthly" ? monthNames[timeLine] + ", " : optionvalue[timeLine] + " Week of ";
            reportLabel = row.franchisee + " (" + getStringParam(tempTimeLine + dueBy) + ")";

            let tabParams = {
                "imgName": "fa fa-users",
                "index": 0,
                item: getActiveTab().item,
                "info": {
                    ...reportParams,
                    show: showRecord.show,
                    isReport: true,
                    reportFieldValue: "All",
                    reportLabel: reportLabel,
                    parentId: row['account_record_id'],
                    total_invoiced: row['total_invoices'],
                    client_id: row['project_id'],
                    royalties_owed: row['royalties_owed'],
                    royalty_percent: row['royalty_percent'],
                    royalty_recordid: row['royalty_recordid'],
                },
                "isActive": false,
                "label": reportLabel,
                "object": constants.DRILL_DOWN_OBJECT,
                "type": 'TYPE_REPORT',
                "url": '/' + makeValidAppUrl(constants.DRILL_DOWN_OBJECT) + '/' + LinkToType.TYPE_REPORT,

            };

            addTab(tabParams, true);
        } catch (error) {
            console.error("Error in 'RoyaltyReport.js -> openRoyaltyListView ()':" + error);
        }
    }

    const openUnitDetailView = (accountRecordId, unit) => {
        try {

            let linkUrl = '/' + constants.ACCOUNTS_OBJECT + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + accountRecordId;
            let activeTab = getObjectParam(getActiveTab());
            let activeTabIndex = getIntParam(activeTab.index);
            let info = getObjectParam(activeTab.info);

            let tab = { label: unit, object: constants.ACCOUNTS_OBJECT, type: TYPE_DETAIL_VIEW, imgName: null, url: linkUrl, isActive: true, parentTabIndex: activeTabIndex };
            addTab(tab, true);

        } catch (error) {
            console.error("Error in 'RoyaltyReport.js -> openUnitDetailView()':" + error);
        }
    }


    let headerComponent = null;
    let tempStyle = { marginLeft: 42, width: '96%', paddingTop: 0, paddingLeft: 11, };
    tempStyle = { ...tempStyle, ...getObjectParam(style) };
    let noRecordsTitle = getNoRecordContent();
    if (mounted.isMounted) {
        headerComponent = getReportFilter();
    }
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2;

    return (
        <div id="royaltyReportDiv" style={tempStyle}>
            {headerComponent}
            {mounted.isMounted ?
                <div id="divcontents" key={"divcontents" + rowSort.isDescending + "-" + rowSort.sortFieldName} style={{ marginLeft: '-17px', marginTop: '10px' }}>
                    {tempRecords.records.length > 0 ?
                        <div>
                            <SFDataTable royalityReport={true} columns={fields} rowSort={rowSort} rows={tempRecords.records} customEvent={(eventType, column, row, selectedValue, event) => customEvent(eventType, column, row, selectedValue, event)}
                                calculateType={changeLog.calculate}
                            />
                        </div>
                        :
                        noRecordsTitle
                    }
                </div>
                :
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            }
        </div>);


}
export default RoyaltyReport;
