import * as HTTPClient from '..//helper/httpClient';
import { actions } from '..//constants/actions';
import { endPoints } from '..//constants/endPoints';

function getAllCampaignTypes(condition) {
    return {
        type: actions.CAMAPIGN_TYPE_GET,
        payload: HTTPClient.get(endPoints.COMMON.GET_CAMPAIGN_TYPES, null)
    }
}
function setCampaignType(campaignTypes){
    return {
        type: actions.CAMAPIGN_TYPE_SET,
        payload: campaignTypes
    }
}

export {
    getAllCampaignTypes,
    setCampaignType
}
