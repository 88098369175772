import * as HTTPClient from '..//helper/httpClient';
import { actions } from '..//constants/actions';
import { endPoints } from '..//constants/endPoints';
import * as ParameterVerifier from '..//helper/parameterVerifier'
import store from '../../../src/services/store';

function getUserList() {
    return {
        type: actions.USERLIST_GET,
        payload: HTTPClient.get(endPoints.USERS.USERLIST_GET)
    }
}

function getUserListPromise() {
    let url = null;
    var promise = null;
    try {
            url = endPoints.USERS.USERLIST_GET;
            promise = Promise.resolve(HTTPClient.get(url, null));
    } catch (error) {
        console.error("Error in 'UserListActions.js -> getUserDetailsPromise()':" + error);
    }
    return promise;
}

function getUserDetailsPromise(param) {
    let url = null;
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(param)) {
            url = endPoints.USERS.PROFILE_DETAILS_GET + '/' + param;
            promise = Promise.resolve(HTTPClient.get(url, null));
        }
    } catch (error) {
        console.error("Error in 'UserListActions.js -> getUserDetailsPromise()':" + error);
    }
    return promise;
}
function saveUserDetailsPromise(param) {
    let url = null;
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(param)) {
            url = endPoints.USERS.PROFILE_DETAILS_SAVE;
            promise = Promise.resolve(HTTPClient.post(url, param));
        }
    } catch (error) {
        console.error("Error in 'UserListActions.js -> getUserDetailsPromise()':" + error);
    }
    return promise;
}
function uploadProfilePhoto(param){
    let url = null;
    var promise = null;

    try {
        if (ParameterVerifier.isValidParam(param)) {
            url = endPoints.USERS.ADD_PHOTO;
            promise = Promise.resolve(HTTPClient.postFormData(url, param));
        }
    } catch (error) {
        console.error("Error in 'UserListActions.js -> getUserDetailsPromise()':" + error);
    }
    return promise;
}

function getEmailConfiguration() {
    let url = null;
    var promise = null;
    try {
            url = endPoints.EMAIL_INTEGRATION.CONFIGURATION_GET;
            promise = Promise.resolve(HTTPClient.get(url, null));
    } catch (error) {
        console.error("Error in 'UserListActions.js -> getEmailConfiguration()':" + error);
    }
    return promise;
}

function getEmailConfigDetails(param) {
    let url = null;
    var promise = null;
    try {
            url = endPoints.EMAIL_INTEGRATION.CONFIG_DATA_GET;
            promise = Promise.resolve(HTTPClient.get(url, param));
    } catch (error) {
        console.error("Error in 'UserListActions.js -> getEmailConfigDetails()':" + error);
    }
    return promise;
}

function getAurinkoConfigDetails(param) {
    let url = null;
    var promise = null;
    try {
            url = endPoints.AURINKO_INTEGRATION.CONFIG_DATA_GET;
            promise = Promise.resolve(HTTPClient.get(url, param));
    } catch (error) {
        console.error("Error in 'UserListActions.js -> getAurinkoConfigDetails()':" + error);
    }
    return promise;
}

function setNylasConfigDetails(data) {
        return function (dispatch) {
            try {
            dispatch({
                type: actions.SET_NYLAS_CONFIG_DETAILS,
                payload: data
            }) } catch (error) {
                console.error("Error in 'UserListActions.js -> setNylasConfigDetails()':" + error);
            }
        }
   
}
function setUserListData(data){
    try {
        store.dispatch({type: actions.SETUSERLIST_DATA, payload:data});
    }
     catch (e) { console.error("Error in 'callAction.js -> POWERCALL_NOTE_DATA_UPDATE':" + e); }
   
}

function disconnectNylas(param) {
    let url = null;
    var promise = null;
    try {
            url = endPoints.EMAIL_INTEGRATION.DISCONNECT_NYLAS_API;
            promise = Promise.resolve(HTTPClient.get(url, param));
    } catch (error) {
        console.error("Error in 'UserListActions.js -> disconnectNylas()':" + error);
    }
    return promise;
}

function saveCalendarForSync(param) {
    let url = null;
    var promise = null;
    try {
            url = endPoints.EMAIL_INTEGRATION.SAVE_CALENDAR;
            promise = Promise.resolve(HTTPClient.post(url, param));
    } catch (error) {
        console.error("Error in 'UserListActions.js -> saveCalendarForSync()':" + error);
    }
    return promise;
}

function saveAurinkoSetupForSync(param) {
    let url = null;
    var promise = null;
    try {
            url = endPoints.AURINKO_INTEGRATION.SAVE;
            promise = Promise.resolve(HTTPClient.post(url, param));
    } catch (error) {
        console.error("Error in 'UserListActions.js -> saveAurinkoSetupForSync()':" + error);
    }
    return promise;
}

function checkIfContactGroupExists(param) {
    let url = null;
    var promise = null;
    try {
            url = endPoints.EMAIL_INTEGRATION.CONTACT_GROUP_EXISTS;
            promise = Promise.resolve(HTTPClient.get(url, param));
    } catch (error) {
        console.error("Error in 'UserListActions.js -> checkIfContactGroupExists()':" + error);
    }
    return promise;
}

function getCalendarList(param) {
    let url = null;
    var promise = null;
    try {
            url = endPoints.EMAIL_INTEGRATION.CALENDAR_LIST;
            promise = Promise.resolve(HTTPClient.get(url, param));
    } catch (error) {
        console.error("Error in 'UserListActions.js -> getCalendarList()':" + error);
    }
    return promise;
}

/*Aurinko Calendar*/
function getAllCalendarList(param) {
    let url = null;
    var promise = null;
    try {
            url = endPoints.AURINKO_INTEGRATION.CALENDAR_LIST;
            promise = Promise.resolve(HTTPClient.get(url, param));
    } catch (error) {
        console.error("Error in 'UserListActions.js -> getCalendarList()':" + error);
    }
    return promise;
}

/*Aurinko Disconnect Account*/
function disconnectAurinko(param) {
    let url = null;
    var promise = null;
    try {
            url = endPoints.AURINKO_INTEGRATION.DISCONNECT;
            promise = Promise.resolve(HTTPClient.get(url, param));
    } catch (error) {
        console.error("Error in 'UserListActions.js -> disconnectNylas()':" + error);
    }
    return promise;
}


function getContactFilters(param) {
    let url = null;
    var promise = null;
    try {
            url = endPoints.EMAIL_INTEGRATION.CONTACT_FILTERS;
            promise = Promise.resolve(HTTPClient.get(url, param));
    } catch (error) {
        console.error("Error in 'UserListActions.js -> getContactFilters()':" + error);
    }
    return promise;
}

function saveRingCentralSetUp(param) {
    let url = null;
    var promise = null;
    try {
            url = endPoints.COMMON.SAVE_RINGCENTRAL_SETUP;
            promise = Promise.resolve(HTTPClient.post(url, param));
    } catch (error) {
        console.error("Error in 'UserListActions.js -> saveRingCentralSetUp()':" + error);
    }
    return promise;
}

function getPhoneIntegrationSetup() {
    let url = null;
    var promise = null;
    try {
            url = endPoints.COMMON.GET_PHONE_INTEGRATION_SETUP;
            promise = Promise.resolve(HTTPClient.get(url, null));
    } catch (error) {
        console.error("Error in 'UserListActions.js -> getPhoneIntegrationSetup()':" + error);
    }
    return promise;
}

function uploadLogo(formData){
    let url = null;
    let promise = null;
    try {
        url = endPoints.USERS.SAVE_LOGO;
        promise =Promise.resolve(HTTPClient.postFormData(url, formData)); 
    } catch (error) {
        console.error("Error in 'UserListActions.js -> uploadLogo()':" + error);
    }
    return promise;
}

function saveCompanyProfile(formData){
    let url = null;
    let promise = null;
    try {
        url = endPoints.USERS.SAVE_COMPANY_PROFILE;
        promise = Promise.resolve(HTTPClient.postFormData(url, formData)); 
    } catch (error) {
        console.error("Error in 'UserListActions.js -> saveCompanyProfile()':" + error);
    }
    return promise;
}

function getCompanyProfile(){
    let url = null;
    let promise = null;
    try {
        url = endPoints.USERS.GET_COMPANY_PROFILE;
        promise = Promise.resolve(HTTPClient.get(url)); 
    } catch (error) {
        console.error("Error in 'UserListActions.js -> getCompanyProfile()':" + error);
    }
    return promise;
}

function pullQBItems(){
    let url = null;
    let promise = null;
    try {
        url = endPoints.PRODUCTS.PULL_QB_ITEMS;
        promise = Promise.resolve(HTTPClient.get(url)); 
    } catch (error) {
        console.error("Error in 'UserListActions.js -> pullQBItems()':" + error);
    }
    return promise;
}

function syncCalendarNow() {
    let url = null;
    var promise = null;

    try {
            url = endPoints.APPOINTMENT.CALENDAR_SYNC;
            promise = Promise.resolve(HTTPClient.post(url));
    } catch (error) {
        console.error("Error in 'UserListActions.js -> syncCalendarNow()':" + error);
    }
    return promise;
}

function getPhoneTextIntegration(param) {
    let url = null;
    var promise = null;
    try {
            url = endPoints.PHONE_AND_TEXT_INTEGRATION.GET;
            promise = Promise.resolve(HTTPClient.get(url, param));
    } catch (error) {
        console.error("Error in 'UserListActions.js -> getAurinkoConfigDetails()':" + error);
    }
    return promise;
}

function savePhoneTextIntegration(param) {
    let url = null;
    var promise = null;
    try {
            url = endPoints.PHONE_AND_TEXT_INTEGRATION.SAVE;
            promise = Promise.resolve(HTTPClient.post(url, param));
    } catch (error) {
        console.error("Error in 'UserListActions.js -> saveAurinkoSetupForSync()':" + error);
    }
    return promise;
}


export {
    getUserList,
    getUserDetailsPromise,
    saveUserDetailsPromise,
    uploadProfilePhoto,
    getEmailConfiguration,
    getEmailConfigDetails,
    getAurinkoConfigDetails,
    disconnectNylas,
    disconnectAurinko,
    saveCalendarForSync,
    saveAurinkoSetupForSync,
    checkIfContactGroupExists,
    getCalendarList,
    getAllCalendarList,
    getContactFilters,
    saveRingCentralSetUp,
    getPhoneIntegrationSetup,
    uploadLogo,
    saveCompanyProfile,
    getCompanyProfile, 
    pullQBItems,
    syncCalendarNow,
    setNylasConfigDetails,
    setUserListData,
    getUserListPromise,
    getPhoneTextIntegration,
    savePhoneTextIntegration   
    
}