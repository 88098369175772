import React from 'react';
import { styles } from '../../../services/constants/styles';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { isValidParam, isUrl, getArrayParam, getIntParam, getObjectParam, getStringParam } from '../../../services/helper/parameterVerifier';
import Button from '@mui/material/Button';
import {getAppDrawer} from '../../../services/actions/appContainerActions';
import { connect } from 'react-redux';
import { saveLinkCategory, getUsefulLinks} from '../../../services/actions/usefulLinksAction';
import {Select, MenuItem,InputLabel } from '@mui/material';
import {showCustomSnackBar} from '../../../services/actions/snackBarAction';
import { constants } from '../../../services/constants/constants';
import { hasAccessPermission } from '../../../services/helper/common';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShowCircularProgress from '../components/circularProgress';

const CreateUsefulLink= ({object,data}) => {
    const dispatch = useDispatch();
    const [categoryList, setCategoryList] = useState([]);
    const [category, setCategory] = useState({ id: '', categoryName: '' });
    const [link, setLink] = useState({ categoryName: '', id: '', link: '', title: '' });
    const [isSaving, setIsSaving] = useState(false);
    const [new_category, setNew_category] = useState('');
    const [isAddToList, setIsAddToList] = useState(false);
    const [dumyData, setDumyData] = useState(false);
    const [isMounted, setisMounted] = useState(false);
    const [categotyTitle,setcategotyTitle] = useState('');
    const [show,setShow] = useState(true);

    const selectedModuleName = useSelector((state) => state.app.selectedModuleName);
    const usefulLink = useSelector((state) => state.usefulLink);

    useEffect(() => {
        let id = getStringParam(data.t_pk);
        if(id !== ''){
            getEditValues();
            setcategotyTitle(data?.category_name);
        }
        getCategoriesList();
      }, []);

    const getCategoriesList = () => {
        try {
            let usefulLinks  = getArrayParam(usefulLink.usefulLinks);
            let arrCategory = usefulLinks.filter( f => {return f.t_type === 'category'});
            arrCategory = arrCategory.map( m => {return {name: m.category_name, title: m.category_name} });
            setCategoryList(arrCategory)
            
        } catch (error) {
            console.error("Error in 'createUsefulLink.js --->  getCategoriesList()' "+error);
        }
    }

    const getEditValues = ()=>{
            let tempData = getObjectParam(data);
            let type = getStringParam(tempData.t_type).toLowerCase();
            let tempLink = link;
            tempLink.categoryName = getStringParam(tempData.category_name);
            tempLink.id = getIntParam(tempData.t_pk);
            tempLink.link = getStringParam(tempData.link);
            tempLink.title = getStringParam(tempData.title);
            setLink({...tempLink});
    }
    
    const getUsefulLinksData = () => {
        let tempSelectedModuleName = getStringParam(selectedModuleName);
        let params = {};
        if (tempSelectedModuleName === constants.MODULE_HUB) {
          params.is_franchise = true;
        }
        getUsefulLinks(params);
      }

    const handleChange = (event)=> {
        try {
            const target = event.target;
            let name = target.name;     
            let value = getStringParam(target.value);       
            if(name === 'new_category'){
                setNew_category(value);
                setDumyData(true);
            }else{
                value = name === 'link' ? value.replace(/\s/g,''):value;
                let tempLink = link;
                tempLink[name] = value;
                setLink({...tempLink});
            }            
        } catch (error) {
        console.error("Error in 'createUsefulLink.js ->  handleChange()' "+error);   
        }
    }
    
    const handleChangeCategory = (event) =>{
        let value = event.target.value;
        setcategotyTitle(value);
    }

    const restrictKey = (event) => {
        try {
            if(event.key ==' '){
                return false;
            }else{
                return true;
            }                
        } catch (error) {
            console.error("Error in 'createUsefulLink.js ->  restrictKey()' "+error);   
        }
    }

    const handleDropdownChange = (event)=>{
        let value = event.target.value;
        let primaryText = '---- ' + getLocalizedStrings().label.COMMON.ADD_TO_THIS_LIST + ' ----';
            let val = getStringParam(value) 
            if(val === primaryText){
                setNew_category('');
                setIsAddToList(true);
                setDumyData(true);
            }else{
                let tempLink = link;
                tempLink.categoryName = val;
                setLink({...tempLink});
                setDumyData(true);
            }
    }

    const validateData = ()=>{
        let errorMsg ='';
            setIsSaving(true);
            if(data?.t_type !== "category"){
                let tempLink = getStringParam(link.link).trim();
                let title = getStringParam(link.title).trim();
                let categoryName = getStringParam(link.categoryName).trim();
                if(tempLink === '' ){
                    errorMsg = getLocalizedStrings().message.USEFUL_LINKS.LINK +" "+ getLocalizedStrings().message.COMMON.VALIDATE_BLANK;
                }else if((tempLink != '' && !isUrl(tempLink)) || (isUrl(tempLink) && tempLink.startsWith("(") || tempLink.endsWith(")"))
                || (isUrl(tempLink) && tempLink.startsWith("{") || tempLink.endsWith("}"))|| (isUrl(tempLink) && tempLink.startsWith("[") || tempLink.endsWith("]"))){
                        errorMsg = getLocalizedStrings().message.USEFUL_LINKS.VALID_URL;
                }
                else if(title === ''){
                    errorMsg = getLocalizedStrings().message.USEFUL_LINKS.TITLE +" "+ getLocalizedStrings().message.COMMON.VALIDATE_BLANK;
                }else if(categoryName === '' ){
                    errorMsg = getLocalizedStrings().message.USEFUL_LINKS.VALID_CATEGORY;
                }
                if(errorMsg !==''){
                    dispatch(showCustomSnackBar(errorMsg,styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    setIsSaving(false);
                    return false;
                }else{
                    save();
                }
            }else{
                let _title = getStringParam(categotyTitle).trim();
                if(_title === ''){
                    errorMsg = getLocalizedStrings().message.USEFUL_LINKS.TITLE +" "+ getLocalizedStrings().message.COMMON.VALIDATE_BLANK;
                }else{
                    save();
                }

            }
    }

    const addCategory= ()=>{
        let params = Object();
            let newCategory = getStringParam(new_category).trim();
            if(newCategory !==''){
                let  tempList = categoryList.filter((f) =>{return f.name == newCategory })    
                if(isValidParam(tempList) &&  tempList.length > 0){
                    setTimeout(() => {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.USEFUL_LINKS.DUPLICATE_CATEGORY_MSG, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        setShow(false)
                      }, 100)
                }else{
                    params.t_pk = 0;
                    params.t_type ='category';
                    params.category_name = newCategory;
                    let promise = saveLinkCategory(params);
                    promise.then((response)=>{
                        if(isValidParam(response) && response.status === 0){                                    
                            getUsefulLinksData();
                            setIsAddToList(false);
                            setNew_category(newCategory);
                            let tempLink = link;
                            tempLink.categoryName= newCategory;
                            setLink(tempLink);
                            setDumyData(true);
                            let tempCartegoryList =  categoryList;
                            let newList = {};
                            newList.name = newCategory;
                            newList.title = newCategory;
                            tempCartegoryList.push(newList);
                            setCategoryList(tempCartegoryList); 
                            setDumyData(true);
                        }
                    })
                }
            } else {
                setIsAddToList(false);
            }
         
    }

    const isTitleDuplicateInCategory = (data, categoryName, title) => {
        // Find the category with the given name
        const category = data.find(cat => cat.category_name === categoryName);
    
        // If the category is not found, return false
        if (!category) return false;
    
        // Count occurrences of the title within this category
        const titleCount = category.items.filter(item => item.title === title).length;
    
        // Return true if more than zero occurrence is found
        return titleCount >= 1;
    };

    const save =()=>{
        let tempUsefullLinks  = usefulLink?.usefulLinks;
        let isDuplicate = false; 
        let tempSelectedModuleName = getStringParam(selectedModuleName);
        if(data?.t_type !== "category"){
            let categoryNameToCheck = getStringParam(link.categoryName).trim();
            let titleToCheck = getStringParam(link.title).trim();
            if((!data?.elementId) || (data?.elementId && data.title !== getStringParam(link.title).trim())){
                isDuplicate = isTitleDuplicateInCategory(tempUsefullLinks, categoryNameToCheck, titleToCheck)
            }
            if(!isDuplicate){
                let param = {};
                let successMessage ='';
                let tempData = getObjectParam(data);
                let type = isValidParam(tempData.t_type)? getStringParam(tempData.t_type).toLowerCase():getStringParam(tempData.type).toLowerCase();
                param.t_type = 'link';
                param.link = getStringParam(link.link).trim();
                param.title = getStringParam(link.title).trim();
                param.category_name = getStringParam(link.categoryName).trim();
    
                let id = getIntParam(tempData.t_pk);
                if(id !== 0){
                    param.t_pk = id;
                    param.t_projectid = getIntParam(tempData.t_projectid);
                }
                
                let promise = saveLinkCategory(param);
                promise.then((response)=>{
                    if(isValidParam(response) && response.status === 0){
                        successMessage = getLocalizedStrings().message.COMMON.SAVE;
                        dispatch(showCustomSnackBar(successMessage, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        setIsSaving(false);
                        closeDrawer();
                        let params = {};
                        if (tempSelectedModuleName === constants.MODULE_HUB) {
                            params.is_franchise = true;
                        }
                        getUsefulLinks(params);
                    }
                }); 
            }else{
                dispatch(showCustomSnackBar(getLocalizedStrings().message.USEFUL_LINKS.DUPLICATE_TITLE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                setIsSaving(false);
            }
        }else{
            let _categoryTitle = categotyTitle;
            let isFound;
            if(data.category_name !== getStringParam(_categoryTitle).trim()){
                isFound =  tempUsefullLinks.find((ele) => ele.category_name === _categoryTitle);
            }
            if(!isFound){
                isDuplicate = false;
            } else {
                isDuplicate = true;
            }
            if(!isDuplicate){
                let params = Object();
                params.t_pk = data?.t_pk;
                params.t_type ='category';
                params.t_projectid = data?.t_projectid;
                params.category_name = getStringParam(_categoryTitle).trim();
                let promise = saveLinkCategory(params);
                promise.then((response)=>{
                    if (isValidParam(response) && response.status === 0) {
                        let successMessage = getLocalizedStrings().message.COMMON.SAVE;
                        dispatch(showCustomSnackBar(successMessage, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        setIsSaving(false);
                        closeDrawer();
                        let params = {};
                        if (tempSelectedModuleName === constants.MODULE_HUB) {
                            params.is_franchise = true;
                        }
                        getUsefulLinks(params);
                    }
                })
            }else{
                dispatch(showCustomSnackBar(getLocalizedStrings().message.USEFUL_LINKS.DUPLICATE_CATEGORY_MSG, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                setIsSaving(false);
            }
        }
    }

    const getCategoryList = ()=>{
        let categoryItms = null;
        let tempCategoryList = getArrayParam(categoryList);
        let primaryText = '---- ' + getLocalizedStrings().label.COMMON.ADD_TO_THIS_LIST + ' ----';
        let hasPermission = false;
         hasPermission = hasAccessPermission("", constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_ADD_TO_LIST);  
        categoryItms = tempCategoryList.map((item, index) => {
            return <MenuItem 
            key={item.name} 
            value={item.name} 
            style={styles.popoverMenuItem}
            title ={item.title }>{item.title} </MenuItem>;
        });
        if(hasPermission){
        categoryItms.push(
            <MenuItem
                key={'addto_category'}
                id={'addto_category'}
                value={primaryText}
                style={{ ...styles.popoverMenuItem }}
                title={primaryText} >{primaryText}</MenuItem>
        );
        }
        categoryItms.unshift(<MenuItem key={0} value={''} style={styles.popoverMenuItem} > </MenuItem>);
        return categoryItms;
    }



    const closeDrawer =()=>{
        dispatch(getAppDrawer(false, null, null, null, null));
    }

    const getLinkElements=()=>{
        return (
        <div>
            {data?.t_type !== "category" &&<div>
                {isAddToList ?
                    <div className="row clearfix">
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                key={"-catgory"}
                                name={"new_category"}
                                disabled={false}
                                label={getLocalizedStrings().label.USEFUL_LINKS.CATEGORY + " " + getLocalizedStrings().label.COMMON.REQUIRED}
                                fullWidth={true}
                                style={{ ...styles.sfFormFieldContainer }}
                                inputprops={{ maxlength: '256' }}
                                value={new_category}
                                onChange={(event) => handleChange(event)}
                                autoFocus={true}
                                onBlur={() => addCategory()}
                                autoComplete="new-password"
                                margin='dense'
                                size='small'
                                className={"sf-fields-bg"}
                                id={'sf_select_fields'}
                            />
                        </FormControl>
                    </div> :
                    <div className="row clearfix">
                        <FormControl style={{ width: '100%' }} variant="outlined">
                            <InputLabel id="sf-create-useful-link-from-createusefullink-simple-select-outlined-label" className="sf-push-units-from-sfforms">{getLocalizedStrings().label.USEFUL_LINKS.CATEGORY + " " + getLocalizedStrings().label.COMMON.REQUIRED}</InputLabel>
                            <Select
                                labelId='sf-label-link'
                                id='sf-link'
                                label={getLocalizedStrings().label.USEFUL_LINKS.CATEGORY + " " + getLocalizedStrings().label.COMMON.REQUIRED}
                                value={link.categoryName}
                                style={{ marginTop: '7px' }}
                                onChange={(event) => handleDropdownChange(event)}
                                className={"sf-fields-bg"}
                                size="small"
                            >
                                {getCategoryList()}
                            </Select>
                        </FormControl>
                    </div>
                }


                <div className="row clearfix">
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            key={"-link"}
                            name={"link"}
                            disabled={false}
                            label={getLocalizedStrings().label.USEFUL_LINKS.LINK + " " + getLocalizedStrings().label.COMMON.REQUIRED}
                            fullWidth={true}
                            // style={{ ...styles.sfFormFieldContainer }}
                            value={link.link}
                            onChange={(event) => handleChange(event)}
                            autoFocus={false}
                            onKeyPress={(event) => restrictKey(event)}
                            autoComplete='off'
                            margin='dense'
                            size='small'
                            className={"sf-fields-bg"}
                        />
                    </FormControl>
                </div>
                <div className="row clearfix">
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            key={"-title"}
                            name={"title"}
                            disabled={false}
                            label={getLocalizedStrings().label.USEFUL_LINKS.TITLE + " " + getLocalizedStrings().label.COMMON.REQUIRED}
                            fullWidth={true}
                            // style={{ ...styles.sfFormFieldContainer }}
                            value={link.title}
                            onChange={(event) => handleChange(event)}
                            autoComplete="off"
                            margin='dense'
                            size='small'
                            className={"sf-fields-bg"}
                        />
                    </FormControl>
                </div>
            </div>}
                {
                    data?.t_type === "category" && <div className="row clearfix">
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                key={"-title"}
                                name={"title"}
                                disabled={false}
                                label={getLocalizedStrings().label.USEFUL_LINKS.TITLE + " " + getLocalizedStrings().label.COMMON.REQUIRED}
                                fullWidth={true}
                                // style={{ ...styles.sfFormFieldContainer }}
                                value={categotyTitle}
                                onChange={(event) => handleChangeCategory(event)}
                                autoComplete="off"
                                margin='dense'
                                size='small'
                                className={"sf-fields-bg"}
                            />
                        </FormControl>
                    </div>
                }
        </div>
        )  
    }
    const getActionsButtons = ()=>{
        let buttons = [];
        buttons.push(
            <Button
                key= 'save'
                onClick= {()=>validateData()}
                
                style= {{ ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top' }}
                disabled= {isSaving}
          > {getLocalizedStrings().label.COMMON.SAVE}</Button>
        );
        buttons.push(
            <Button
                key ='cancel'
                onClick={() => closeDrawer()}
              
                style={{ ...styles.secondaryButton, marginLeft: '6px', marginRight: '0px' }}
          > <span> {getLocalizedStrings().label.COMMON.CANCEL}</span></Button>
        );
        return buttons;
    }
        let type = isValidParam(data.t_type)? getStringParam(data.t_type).toLowerCase():getStringParam(data.type).toLowerCase();
        let elemensts = null;
        elemensts = getLinkElements();
        if(!isMounted)
        {
            return( 
                <div style={{ paddingLeft: '25px', paddingRight: '25px', marginTop: '10px' }}>
                    {elemensts}
                    <div style={{ float: 'right', marginTop: '8px' }}>
                        {getActionsButtons()}
                    </div>
                </div>
            )
        }else{
            let contentHeight = window.innerHeight - 240;
            let top = (contentHeight - 10) / 2;
            let loader = null;
            if (isMounted) {
                loader = <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
            }
            return loader;
        }
}

export default CreateUsefulLink;


