
import React from 'react';
import { SFTable } from './table';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';

export const CustomReport = (props) => {
        if (props.data !== null && props.data !== undefined) {
            let cols = props.data.column,
                data = props.data.data;
            return <div><SFTable cols={cols} data={data} /></div>
        } else {
            return <div>{getLocalizedStrings().message.COMMON.NO_RECORD}</div>
        }
}