import { actions } from '..//constants/actions';
import { constants } from '../constants/constants';
import { getUniqueId } from '../helper/utils';

const userListInitialState = {
    mounted: false,
    data: {},
    selectedStage: {},
    taskList: [],
    accountMergeFields: [],
    emailTemplateMergeFields: [],
    customDateFormFId: [],
    error: null,
    isEditTask:false,
    isAddNewTask:false,
    isaddNewTextMessage:false,
    isAddAppointment:false,
    isOpenEmailTask:false,
    showRequiredField:false,
    isShowWebhook:false,
    isEditWebhook:false,
    isEditTextmessage:false,
    isEditApppointment:false,
    isEditEmailTask:false,
    isFocusOnEmailTask:false,
    isFocusOnMessageTask:false,
    isFocusOnWebhook:false,
    taskListOrderErrorId:0,
    isTaskOrderError:false,
    isFocusOnappointmentTask:false,
    action:'',
    isEditStage : false,
    showActionButtons: true,
    editIndex : -1,
    selectedTemplateInfo : {},
    isDndCardDroped: false,
    twilioPhoneNumbers:[],
}

const workFlowSetupReducer = (state = userListInitialState, action) => {
    switch (action.type) {
        case actions.GET_WORKFLOW_SETUP_DATA:
            return {
                ...state,
                data: action.payload.data,
                selectedStage: action.payload.selectedStage,
                taskList: action.payload.taskList,
                accountMergeFields: action.payload.accountMergeFields,
                emailTemplateMergeFields: action.payload.emailTemplateMergeFields,
                customDateFormFId: action.payload.customDateFormFId,
                stagesLength : action.payload.stagesLength,
                twilioPhoneNumbers: action.payload.twilioPhoneNumbers.outbound_phonenumbers
            }
        case actions.GET_WORKFLOW_EMAIL_TEMPLATE:
            let _emailTemplates = action?.payload?.emailTemplates;
            let _emailTemplateList = [];
            _emailTemplateList = [..._emailTemplateList,_emailTemplates]
            return {
                ...state,
                emailTemplateList : _emailTemplateList,
            }

        case actions.SET_WORKFLOW_SETUP_STAGE:
            let stage = state.data?.nodes.filter((stage) => stage.id === action.payload.selectedStage.id)
            return {
                ...state,
                selectedStage: stage[0]
            }
        case actions.DELETE_WORKFLOW_STAGE:
            let _filteredNodes = action.payload.stage;
            let isAvailable = _filteredNodes.find((ele)=> ele.id == state.selectedStage.id)
            return {
                ...state,
                data: {
                    ...state.data,
                    nodes: action.payload.stage
                },
                selectedStage:  isAvailable?.id ? state.selectedStage :  action.payload.stage[0],  
            }

        case actions.ADD_NEW_WORKFLOW_STAGE:
            return {
                ...state,
                data: {
                    ...state.data,
                    nodes: action.payload.stage
                },
                selectedStage: state.selectedStage,  
            }
        case actions.UPDATE_WORKFLOW_STAGE:
            let selectedWfStage = action.payload.stage;
            let allStage = state.data.nodes;
            allStage?.map((ele)=>{
                if(ele.id === selectedWfStage.id){
                    ele = selectedWfStage;
                }
              })
            return{
                ...state,
                data: {
                    ...state.data,
                    nodes: allStage
                },
                selectedStage: state.selectedStage,  
            }

        case actions.ADD_VALUE_IN_TASK_LIST:
            let list =  action.payload.list;
            return{
                ...state,
                taskList: list
            }

        case actions.SET_SELECTED_REQUIRED_FIELDS:
            let _selectedStage = state.selectedStage;
            let _nodes = state.data.nodes;
            _nodes?.map((stage)=>{
                if(stage.id === _selectedStage.id){
                    stage.details.selected_form_fields = action.payload.selectedFormfield;
                }
            })
            return{
                ...state,
                data:{
                    ...state.data,
                    nodes : _nodes,
                },
                selectedStage: {
                    ...state.selectedStage,
                    details: {
                        ...state.selectedStage.details,
                        selected_form_fields: action.payload.selectedFormfield,
                    }
                }
            }

        case actions.ADD_NEW_TASKNAME_IN_TASKLIST:
            return{
                ...state,
                taskList : action.payload.newTaskList,
            }

        case actions.SET_IS_OPEN_EDIT_TASK:
            return {
                ...state,
                isEditTask: action.payload.data,
            } 
        case actions.SET_IS_FOCUS_ON_EMAIL_TASK:
            return {
                ...state,
                isFocusOnEmailTask: action.payload.data,
            } 
        case actions.SET_IS_FOCUS_ON_MESSAGE_TASK:
            return {
                ...state,
                isFocusOnMessageTask : action.payload.data,
            }
        case actions.SET_IS_FOCUS_ON_WEBHOOK:
            return {
                ...state,
                isFocusOnWebhook : action.payload.data,
            }
        case actions.SET_TASKLIST_ORDER_ERROR_ID:
            return {
                ...state,
                taskListOrderErrorId : action.payload.Errortaskid,
                isTaskOrderError : action.payload.isTaskerror,
            }
        case actions.SET_IS_FOCUS_ON_APPOINTMENT_TASK: 
        return {
            ...state,
            isFocusOnappointmentTask : action.payload.data,
        }
        case actions.SET_IS_DND_CARD_DROPPED: 
            return {
                ...state,
                isDndCardDroped: action.payload.data
            }
        case actions.SET_ACTIONS:
            return {
                ...state,
                action: action.payload.data,
            } 
        case actions.SET_IS_ADD_NEW_TASK:
            return {
                ...state,
                isAddNewTask: action.payload.data,
            } 
        case actions.SET_IS_ADD_NEW_TEXT_MESSAGE:
            return {
                ...state,
                isaddNewTextMessage: action.payload.data,
            } 
        case actions.SET_IS_ADD_APPOINTMENT_TASK:
            return {
                ...state,
                isAddAppointment: action.payload.data,
            } 
        case actions.SET_IS_OPEN_EMAIL_TASK:
            return {
                ...state,
                isOpenEmailTask: action.payload.data,
            } 
        case actions.SET_IS_SHOW_REQUIRED_FIELD:
            return {
                ...state,
                showRequiredField: action.payload.data,
            } 
        case actions.SET_IS_SHOW_WEBHOOK:
            return {
                ...state,
                isShowWebhook: action.payload.data,
            } 
        case actions.SET_IS_OPEN_EDIT_WEBHOOK:
            return {
                ...state,
                isEditWebhook: action.payload.data,
            } 
        case actions.SET_IS_EDIT_TEXT_MESSAGE:
            return {
                ...state,
                isEditTextmessage: action.payload.data,
            } 
        case actions.SET_IS_EDIT_APPOINMENT:
            return {
                ...state,
                isEditApppointment: action.payload.data,
            } 
        case actions.SET_IS_EDIT_EMAIL_TASK:
            return {
                ...state,
                isEditEmailTask: action.payload.data,
            } 
        case actions.SET_IS_EDIT_STAGES:
            return {
                ...state,
                isEditStage: action.payload.isEdit,
            } 
        case actions.SET_IS_SHOW_ACTIONS_BUTTONS:
            return {
                ...state,
                showActionButtons: action.payload.data,
            } 
        case actions.SET_EDIT_INDEX:
            return {
                ...state,
                editIndex: action.payload.data,
            } 
        case actions.CLOSE_DRAWER:
            return {
                ...state,
                isShowWebhook: false,
                showRequiredField: false,
                isOpenEmailTask: false,
                isAddAppointment: false,
                isaddNewTextMessage: false,
                isEditTask: false,
                isAddNewTask : false,
                isEditWebhook:false,
                isEditTextmessage : false,
                isEditApppointment: false,
                isEditEmailTask :false,
                data: {},
            } 

            
        case actions.UPDATED_WORKFLOW_TASK_ACTION:
            let updatedActionTask = action.payload.updatedActionTask;
            let stages = state.data.nodes;
            let filterStage = stages.filter((stage)=>stage.id === updatedActionTask.stage_id)
            let UpdatedTasksData = filterStage[0].tasks.map((task)=>{
                if(task.id === updatedActionTask.id){
                    return updatedActionTask;
                }
                return task
            })
            

            let updatedStage = {
                ...filterStage[0],
                tasks: UpdatedTasksData
            }
            let stageData = stages.map((stage)=>{
                if(stage.id === updatedStage.id){
                    return updatedStage;
                }
                return stage
            })

            return {
                ...state,
                data : {
                    ...state.data,
                    nodes: [...stageData]
                
                },
                selectedStage:updatedStage
            }

        case actions.WORKFLOW_ADD_NEW_TASK_ACTION:
            let newActionTask = action.payload.newActionTask;
            let tType = action.payload.type;
            let _stages = state.data.nodes;
            let _filterStage = _stages.filter((stage)=>stage.id === newActionTask.stage_id);
            let _updatedStage;
            let _taskList = state.taskList;
            if(tType === constants.COPY) {
                if(newActionTask.type === "Task"){
                    let taskName= "Copy of ".concat(...newActionTask.details.t_name);
                    let newTaskObj = {};
                    newTaskObj.description = taskName;
                    newTaskObj.taskname = taskName;
                    _taskList = _taskList.filter((ele)=> ele.taskname !== "--Add--");
                    _taskList = [..._taskList,newTaskObj];
                    _updatedStage = {
                        ..._filterStage[0],
                        tasks: [..._filterStage[0]?.tasks,{...newActionTask, id: getUniqueId(), details: {...newActionTask?.details, t_name: "Copy of ".concat(newActionTask?.details.t_name)} }]
                    }
                }else if(newActionTask.type === "New_Appointment"){
                    _updatedStage = {
                        ..._filterStage[0],
                        tasks: [..._filterStage[0]?.tasks,{...newActionTask, id: getUniqueId(),t_subject : "Copy of ".concat(newActionTask?.details.t_subject), details: {...newActionTask?.details, t_subject: "Copy of ".concat(newActionTask?.details.t_subject)} }]
                    }
                }else if(newActionTask.type === "Text_Message"){
                    _updatedStage = {
                        ..._filterStage[0],
                        tasks: [..._filterStage[0]?.tasks,{...newActionTask, id: getUniqueId(), details: {...newActionTask?.details, t_message: "Copy of ".concat(newActionTask?.details.t_message)} }]
                    }
                }else if(newActionTask.type === "Auto_Mail"){
                    _updatedStage = {
                        ..._filterStage[0],
                        tasks: [..._filterStage[0]?.tasks,{...newActionTask, id: getUniqueId(), details: {...newActionTask?.details , template:{...newActionTask?.details.template ,subject: "Copy of ".concat(newActionTask?.details?.template?.subject)}} }]
                    }
                }
            } else {
                _updatedStage = {
                    ..._filterStage[0],
                    tasks: [..._filterStage[0]?.tasks,{...newActionTask}]
                }
            }
            let _stageData = _stages?.map((stage)=>{
                if(stage.id === _updatedStage.id){
                    return _updatedStage;
                }
                return stage;
            })
            return {
                ...state,
                    taskList : _taskList,
                data : {
                    ...state.data,
                    nodes: [..._stageData]
                
                },
                selectedStage: _updatedStage
            }

        case actions.UPDATE_DND_CARD:
            let getDndRowCards = action.payload.updateCards;
            let type = action.payload.type;
            let dndStages = state.data.nodes;
            let dndFilterStage = dndStages.filter((stage)=>stage.id === getDndRowCards[0].stage_id);
            let updatedSelectedDndStage;
            if(type === 'tasks') {
                updatedSelectedDndStage = {
                    ...dndFilterStage[0],
                    tasks: getDndRowCards
                }
            } else {
                updatedSelectedDndStage = {
                    ...dndFilterStage[0],
                    webhooks: getDndRowCards
                }
            }
            let stageDndData = dndStages.map((stage)=>{
                if(stage.id === updatedSelectedDndStage.id){
                    return updatedSelectedDndStage;
                }
                return stage;
            })
            return {
                ...state,
                data : {
                    ...state.data,
                    nodes: [...stageDndData]
                
                },
                selectedStage: updatedSelectedDndStage
            }

        case actions.UPDATE_WORKFLOW_STAGE_DND_CARD:
            let _getDndCards = action.payload.updateCards;
            return {
                ...state,
                data : {
                    ...state.data,
                    nodes: [..._getDndCards]
                
                }
            }

        case actions.UPDATE_WEBHOOK:
            let updateWebhook = action.payload.updateWebhook;
            let webHookstages = state.data.nodes;
            
            // filter selected stage data
            let filterUpdatedWebhookStage = webHookstages.filter((stage)=>stage.id === updateWebhook.stage_id)
            
            // map all the stage with updated webhooks
            let UpdatedWebhooksData = filterUpdatedWebhookStage[0].webhooks.map((webhook)=>{
                if(webhook.id === updateWebhook.id){
                    return updateWebhook;
                }
                return webhook;
            })
            
            //  update the stage data with updated webhooks - selectedStage
            let updatedWebhookStage = {
                ...filterUpdatedWebhookStage[0],
                webhooks: UpdatedWebhooksData
            }

            //  get all the stages with updated webhook stage - nodes
            let webhookUpdatedStageData = webHookstages.map((stage)=>{
                if(stage.id === updatedWebhookStage.id){
                    return updatedWebhookStage;
                }
                return stage
            })

            return {
                ...state,
                data : {
                    ...state.data,
                    nodes: [...webhookUpdatedStageData]
                
                },
                selectedStage: updatedWebhookStage
            }

        case actions.WORKFLOW_ADD_NEW_WEBHOOK:
            let newWebhook = action.payload.newWebhook;
            let stagesWebhook = state.data.nodes;
            let filterwebhookStage = stagesWebhook.filter((stage)=>stage.id === newWebhook.stage_id);
            // update the selectedStage - updatedwebhookStage
            let updatedwebhookStage = {
                ...filterwebhookStage[0],
                webhooks: [...filterwebhookStage[0].webhooks,{...newWebhook}]
            }
            // update the nodes - updatedStageWebhookData
            let updatedStageWebhookData = stagesWebhook.map((stage)=>{
                if(stage.id === updatedwebhookStage.id){
                    return updatedwebhookStage;
                }
                return stage;
            })

            return {
                ...state,
                data : {
                    ...state.data,
                    nodes: [...updatedStageWebhookData]
                
                },
                selectedStage: updatedwebhookStage
            }

        case actions.DElETE_DND_ROW_CARD:
            let dndCard = action.payload.deletedRowCard;
            let dnDType = action.payload.type;
            let dndRowsStage = state.data.nodes;
            let dndCardsFilterStage = dndRowsStage.filter((stage)=> stage.id === dndCard.stage_id);
            let filteredRowsCard;
            let updatedDeletedDndRowsStage;

            // update the selectedStage - updatedwebhookStage
            if(dnDType === 'tasks') {
                filteredRowsCard = dndCardsFilterStage[0]?.tasks?.filter((item) => item.id !== dndCard.id);
                updatedDeletedDndRowsStage = {
                    ...dndCardsFilterStage[0],
                    tasks: filteredRowsCard
                }
            } else {
                filteredRowsCard = dndCardsFilterStage[0]?.webhooks?.filter((item) => item.id !== dndCard.id);
                updatedDeletedDndRowsStage = {
                    ...dndCardsFilterStage[0],
                    webhooks: filteredRowsCard
                }
            }

            // update the nodes - updatedStageWebhookData
            let updatedStageDndRowsData = dndRowsStage.map((stage)=>{
                if(stage.id === updatedDeletedDndRowsStage.id){
                    return updatedDeletedDndRowsStage;
                }
                return stage;
            })

            return {
                ...state,
                data : {
                    ...state.data,
                    nodes: [...updatedStageDndRowsData]
                
                },
                selectedStage: updatedDeletedDndRowsStage
            }
        default:
            return state;
    }
}

export default workFlowSetupReducer;