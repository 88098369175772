import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { isValidParam, getArrayParam, getStringParam, getBooleanParam, getIntParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { constants, OBJECT_TABLEID_MAP} from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getObjectLabelByObject } from '../../../services/helper/common';
import { styles } from '../../../services/constants/styles';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { getEndPointObject, getRecordPromise,saveRecordPromise } from '../../../services/actions/sfFormActions';
import DetailViewChildNote from '../components/detailViewChildNote';
import { LinkToType } from '../../../services/constants/link';
import { addTab, TYPE_DETAIL_VIEW, getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { getAppCustomDrawer, getAppDialog } from '../../../services/actions/appContainerActions';
import * as sfDialogs from '../components/sfDialogs';
import {  Menu, MenuItem, Icon } from '@mui/material';
import moment from 'moment';
import { makeTiteleCase } from '../../../services/helper/utils';
import { refreshQueueDetailViewData } from '../../../services/actions/detailViewActions';
import { getCallDrawer } from '../../../services/actions/callAction';
import ShowCircularProgress from '../components/circularProgress';
import { useDispatch, useSelector } from "react-redux";

    const AppTaskQueueChild = ({linkRecord, object, id,updateLinkInfo}) => {
        const dispatch = useDispatch();
        const app = useSelector((state) => state.app);
        const sfForm = useSelector((state) => state.sfForm);
        const detailView = useSelector((state) => state.detailView);
        const userlist = useSelector((state) => state.userlist);
        const appTasks = useSelector((state) => state.appTasks);
        const [statelinkRecord, setstateLinkRecord] = useState({});
        const [linkObject, setLinkObject] = useState(['company', 'fullname', 'opportunity_name']);
        const [mounted, setMounted] = useState(false);
        const [linkInfoData, setLinkInfoData] = useState('');
        const [linkInfoArr, setLinkInfoArr] = useState([]);
        const [childView, setChildView] = useState(null);
        const [isTwilioPopOverOpen, setIsTwilioPopOverOpen] = useState(false);
        const [anchorEl, setAnchorEl] = useState(null);
        const [phoneFields, setPhoneFields] = useState(null);
        const [fieldProps, setFieldProps] = useState(null);
        const [formFields, setFormFields] = useState([]);
        const [twilioDialogData, setTwilioDialogData] = useState({});
        const [pos, setPos] = useState({});
        const [locationFldData, setLocationFldData] = useState('');
        const [changeLog, setChangeLog] = useState({linkInfoData:""});

        useEffect(() => {
            let propsLinkRecord = JSON.parse(linkRecord);
            let tempRecords = {};
            linkObject.forEach(f => {
                if (propsLinkRecord.hasOwnProperty(f)) {
                    tempRecords[f] = propsLinkRecord[f];
                }
            });
            let linkInfo = propsLinkRecord['link_info'];
            let _linkInfoData = changeLog.linkInfoData;
            let _linkInfoArr = [];
            if (isValidParam(linkInfo) && linkInfo.hasOwnProperty('value')) {
                _linkInfoData = linkInfo.value;
                if (isValidParam(_linkInfoData) && _linkInfoData !== "") {
                    _linkInfoArr = _linkInfoData.split(',');
                }
    
            }
            let tempformFields = [];
            let fldKey = Object.keys(propsLinkRecord);
            fldKey.forEach(key => {
                let field = propsLinkRecord[key];
                tempformFields.push(field);
            });
            setFormFields(tempformFields);
            setLinkInfoArr(_linkInfoArr);
            if(_linkInfoData != null){
                setLinkInfoData(_linkInfoData);
                let log  = changeLog;
                log.linkInfoData = _linkInfoData;
                setChangeLog({...log});
            }
            
           
            setFieldProps(propsLinkRecord);
            
           setChildView(generateChildView(object, id));
            getRecord();
            }, [id]);
    


  const  generateChildView = (object, recordId) => {
        return <DetailViewChildNote parentObject={object} object={constants.NOTES} isDetailView={true} isQueueView={true} parentRecordId={recordId} linkInfo={getStringParam(changeLog.linkInfoData)} />
    }

   const getRecord = () => {
        try {
            let params = {
                id: id,
                linkInfoData: changeLog.linkInfoData,
            }
            let linkObject = getEndPointObject(object);
            let url = linkObject.CHILD_DETAILS_GET;
            var promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let record = response.result[0];
                    let phoneFields = response.phoneSetup;
                    var value = "";
                    var linkInfo = "";
                    for (var linkInfoKey in record) {
                        value = record[linkInfoKey];
                        if (isValidParam(value)) {
                            if (linkInfo === "") {
                                linkInfo = linkInfoKey + "_" + value.id;
                            } else {
                                linkInfo += "," + linkInfoKey + "_" + value.id;
                            }
                        }
                        value = null;
                    }
                    updateLinkInfo(linkInfo);
                    setstateLinkRecord(record)
                    setPhoneFields(phoneFields);
                    setMounted(true);
                }
            });
        } catch (error) {
            console.error("Error in 'AppTaskQueueChild.js -> getRecord()':" + error);
        }
    }

   const getLinkedObject = () => {
        let element = [];
        let linkObjArr = [constants.CONTACTS_OBJECT, constants.ACCOUNTS_OBJECT, constants.OPPORTUNITIES_OBJECT];
        try {
            linkObjArr.forEach(obj => {
                let ele = <div>
                    <div>{getObjectHeader(obj)}</div>
                    <div style={{ marginBottom: 10 }}>{getObjectRecord(obj)}</div>
                </div>
                element.push(ele);
            })
        } catch (error) {
            console.error("Error in 'AppTaskQueueChild.js -> getLinkedObject()':" + error);
        }
        return element;
    }

   const getObjectRecord = (linkObject) => {
        let recEle = null;
        try {
            let record = null;
            let objectId = OBJECT_TABLEID_MAP[linkObject];
            if (statelinkRecord.hasOwnProperty(objectId)) {
                record = statelinkRecord[objectId];
            }
            let objectLabel = getObjectLabelByObject(linkObject);
            objectLabel = getLocalizedStrings().label.NO_RECORD.hasOwnProperty(objectLabel) ? getLocalizedStrings().label.NO_RECORD[objectLabel] : objectLabel;
            objectLabel = objectLabel.toLowerCase();
            let label = getLocalizedStrings().label.COMMON.NO + ' ' + makeTiteleCase(objectLabel) + ' ' + getLocalizedStrings().label.COMMON.FOUND + getLocalizedStrings().label.COMMON.DOT;
            if (record !== null) {
                let customFields = [];
                if (linkObject === constants.ACCOUNTS_OBJECT) {
                    customFields.push('company');
                } else if (linkObject === constants.CONTACTS_OBJECT) {
                    customFields.push('full_name');
                    customFields.push('email');
                    customFields.push('phone');
                    customFields.push('mobile');
                } else if (linkObject === constants.OPPORTUNITIES_OBJECT) {
                    customFields.push('name');
                    customFields.push('opportunity_amount');
                }
                recEle = <div style={{ paddingBottom: 10 }}>{generateElement(linkObject, record, customFields)}</div>
            } else {
                recEle = <div style={{ color: '#B5B5B5', fontSize: '13px', paddingBottom: 10 }}>
                    {label}
                </div>
            }
        } catch (error) {
            console.error("Error in 'AppTaskQueueChild.js -> getObjectRecord()':" + error);
        }
        return recEle;
    }

   const generateElement = (linkObject, record, customFields) => {
        let recordElement = [];
        let formData = sfForm.data;
        let objectFields = formData[linkObject];
        let fields = getArrayParam(objectFields.fields);
            customFields.forEach(name => {
                let fld = fields.filter(f => { return f.name === name });
                let ele = null;
                if (isValidParam(fld)) {
                    if (name === 'company' || name === 'full_name' || name === 'name') {
                        let label = isValidParam(record[name]) ? record[name] : 'Empty';
                        let icon = name === 'full_name' ? <i id={name + fld.id} key={name + fld.id} className='fa fa-user' style={{ color: '#929292', marginRight: '4px', padding: '2px', fontSize: 15 }} /> : null;
                        ele = <div>
                            {icon}
                            <u
                                style={{ cursor: 'pointer', display: 'inline', color: 'rgb(3, 155, 229)' }}
                                onClick={()=>openRecord(linkObject, fld, label, record['id'])}
                            >
                                {label}
                            </u>
                        </div>
                    } else if (name === 'email' || name === 'phone' || name === 'mobile') {
                        let icon = null;
                        let isLink = true;
                        let isPopover =  phoneFields !== null && ((name === 'mobile') && phoneFields.is_click_to_call && phoneFields.is_twilio_sms && (phoneFields.is_twilio || phoneFields.is_ring_central)) ? true : false;
                        let label = (isValidParam(record[name]) && record[name] !== '') ? record[name] : 'Empty';
                        if (name === 'email') {
                            icon = <i id={name + fld.id} key={name + fld.id} className='fa fa-envelope' style={{ color: '#929292', marginRight: '3px', padding: '2px', fontSize: 13 }} />;
                        } else if (name === 'phone') {
                            icon = <i id={name + fld.id} key={name + fld.id} className='fa fa-phone' style={{ color: '#929292', marginRight: '4px', padding: '2px', fontSize: 15 }} />;
                            if ( phoneFields !== null && !getBooleanParam(phoneFields.is_twilio)) {
                                isLink = false;
                            }
                        } else {
                            icon = <i id={name + fld.id} key={name + fld.id} className='fa fa-mobile' style={{ color: '#929292', marginRight: '7px', padding: '2px', fontSize: 17 }} />;
                        }
                        ele = <div>
                            {icon}
                            {isLink ?
                                <span>
                                    {(label !== 'Empty') ?
                                        <u
                                            style={{ cursor: 'pointer', display: 'inline', color: 'rgb(3, 155, 229)' }}
                                            onClick={()=> customEvent(linkObject, fld, name, record[name], record['id'], record['full_name'])}
                                        >
                                            {label}
                                        </u> : <span style={{ color: '#929292' }}>{label}</span>}
                                </span> : <span style={{ color: '#929292' }}>{label}</span>}
                            {isPopover && getTwilioPopOver(linkObject, fld, name, label, record['id'], record['full_name'])}
                        </div>
                    } else if (name === 'opportunity_amount') {
                        ele = <div>{app.me.currency_symbol}{record[name]}</div>
                    }

                    recordElement.push(ele);
                }
            });
       

        return recordElement;
    }

   const customEvent = (objectName, field, fieldName, value, parentId, fullName, event) => {
        try {
            if (fieldName === 'email' || fieldName === 'phone') {
                handleDetailsLinkClicked(objectName, field, fieldName, value, parentId, fullName);
            } else {
                if (phoneFields.is_twilio_sms && (phoneFields.is_twilio || phoneFields.is_ring_central)) {
                    openTwilioPopOver(event, fieldName);
                } else {
                    handleDetailsLinkClicked(objectName, field, fieldName, value, parentId, fullName);
                }
            }
        } catch (error) {
            console.error("Error in 'AppTaskQueueChild.js -> customEvent()':" + error);
        }
    }

   const handleDetailsLinkClicked = (objectName, field, fieldName, value, parentId, fullName) => {
        let tabInfo = getActiveTabInfo();
        let isInXpress = getBooleanParam(tabInfo.isInXpress);
        if (!isInXpress) {
            if (fieldName === 'email') {
                sendMail(value, constants.TYPE_EMAIL, objectName, parentId, fullName);
            } else {
                if (getBooleanParam(phoneFields.is_click_to_call)) {
                    let recordId = parentId;
                    let phoneField = true;
                    let selectedPhoneNumber = value;
                    if (getBooleanParam(phoneFields.is_ring_central)) {
                        // Code for Ring Central
                        openRingCentralWebWidget();
                    } else {
                        if (getBooleanParam(phoneFields.is_twilio)) {
                            getCapabilityTokenForCall(objectName, recordId, phoneField, fieldName, selectedPhoneNumber);
                        } else if (fieldName === "mobile" && getBooleanParam(phoneFields.is_twilio_sms)) {
                            getRecordDetailsForSMS(objectName, recordId, fieldName);
                        }
                    }
                } else {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You have not configured call setup.',null);
                }
            }
        } else {
            let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage,null);
        }
    }

   const getCapabilityTokenForCall = (object, recordId, phoneField, fieldName, selectedPhoneNumber) => {
        var promise = Promise.resolve(HTTPClient.get(endPoints.CALLING_INTEGRATION.GENERATE_CAPABILITY_TOKEN, null));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    let capabilityToken = getStringParam(response.token);
                    if (capabilityToken !== null && capabilityToken !== "") {
                        getCapabilityToken(capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber);
                    }
                }
            });
        }
    }

   const getCapabilityToken = (capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber) => {
        let endPoint = "";
        let params = {};
        if (object === constants.ACCOUNTS_OBJECT) {
            endPoint = endPoints.ACCOUNTS.MAP_DATA;
            params = { "id": recordId, "fields": ["company", "first_name", "last_name", "phone", "mobile", "workflow_id", "country"] };
        } else if (object === constants.CONTACTS_OBJECT) {
            endPoint = endPoints.CONTACTS.MAP_DATA;
            params.id = recordId;
            params = { "id": recordId, "fields": ["company", "first_name", "last_name", "phone", "mobile", "workflow_id", "country"] };
        }

        var promise = Promise.resolve(HTTPClient.get(endPoint, params));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    let records = response.records;
                processInfoAndOpenCallDialog(records, capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber);
                }
            });
        }
    }

   const processInfoAndOpenCallDialog = (records, capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber) => {
        let firstName = "";
        let lastName = "";
        let fullName = "";
        let workflowId = "";
        let company = "";
        let title = "";
        let country = "";
        if (records) {
            if (records.hasOwnProperty('first_name')) {
                firstName = records.first_name;
            }

            if (records.hasOwnProperty('last_name')) {
                lastName = records.last_name;
            }

            if (records.hasOwnProperty('workflow_id')) {
                workflowId = records.workflow_id;
            }

            if (records.hasOwnProperty('company')) {
                company = records.company;
            }

            if (records.hasOwnProperty('country')) {
                country = records.country;
            }
        }

        if (firstName !== null && firstName !== "" && firstName !== undefined && lastName !== null && lastName !== "" && lastName !== undefined) {
            fullName = firstName + " " + lastName;
        }
        if (firstName !== null && firstName !== "" && firstName !== undefined && (lastName === null || lastName === "" || lastName === undefined)) {
            fullName = firstName;
        }

        if ((firstName === null || firstName === "" || firstName === undefined) && lastName !== null && lastName !== "" && lastName !== undefined) {
            fullName = lastName;
        }

        if (fullName === null || fullName === "" || fullName === undefined) {
            fullName = company;
        }
        if (fullName === null || fullName === "" || fullName === undefined) {
            fullName = selectedPhoneNumber;
        }
        if (fullName !== null && fullName !== "" && fullName !== undefined) {
            title = "Call " + fullName + "";
        }

        let label = title;
        let type = LinkToType.TYPE_CLICK_TO_CALL;
        let data = {};
        data.id = recordId;
        data.object = object;
        //data.type = dataType;
        data.token = capabilityToken;
        data.selectedPhoneNumber = selectedPhoneNumber;
        data.fieldName = fieldName;
        data.workflowId = workflowId;
        data.country = country;
        data.fullName = fullName;
        data.isDetailView = true;
        data.call_from = constants.COLUMN_VIEW;
        let selectedRecord = [{ id: recordId, title: fullName }];
        data.selectedRecords = selectedRecord;
        let style = {};
        style.width = '40%';
        dispatch(getCallDrawer(true, label, type, style, data, null));
    }

   const getRecordDetailsForSMS = (objectName, recordId, fieldName) => {
        let params = null;
        var response = null;
        let endPoint = null;
        let recordIdArr = [];
        recordIdArr.push(recordId);
        params = {
            recordIds: recordIdArr,
            type: 'Texts',
            tableId: OBJECT_TABLEID_MAP[objectName.toLowerCase()]
        }
        endPoint = endPoints.CONTACTS.GET_SMS_UNSUBSCRIBE_LIST;
        response = Promise.resolve(HTTPClient.get(endPoint, params));
        response.then((response) => {
            if (isValidParam(response) && getArrayParam(response).length === 1) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SMS_INTEGRATION.RECORD_UNSUBSCRIBE_ALERT,null);
            } else {
                let endPoint = endPoints[objectName.toUpperCase()].MAP_DATA;
                let params = { "id": recordId, "fields": ["company", "first_name", "last_name", "phone", "mobile", "country"] };

                var promise = Promise.resolve(HTTPClient.get(endPoint, params));
                if (isValidParam(promise)) {
                    promise.then(response => {
                        if (isValidParam(response)) {
                            let records = response.records;
                            processInfoAndOpenSMSDialog(objectName, records, recordId, fieldName);
                        }
                    });
                }
            }
        });
    }

   const processInfoAndOpenSMSDialog = (objectName, records, recordId, fieldName) => {
        let firstName = "";
        let lastName = "";
        let fullName = "";
        let company = "";
        let title = "";
        let country = "";
        let mobile = "";
        if (isValidParam(records)) {
            firstName = getStringParam(records.first_name);
            lastName = getStringParam(records.last_name);
            company = getStringParam(records.company);
            country = getStringParam(records.country);
            mobile = getStringParam(records.mobile);
        }
        country = app.me.tenant_country;
        fullName = (firstName + ' ' + lastName).trim();
        fullName = fullName === '' ? company : fullName;
        fullName = fullName === '' ? getStringParam(records.mobile) : fullName;
        title = fullName !== '' ? 'To ' + fullName : title;

        let label = title;
        let type = LinkToType.TYPE_CLICK_TO_SMS;
        let countryCode = "";
        let toNumber = getStringParam(mobile).trim();
        if (country === "USA" || country === "Canada") {
            countryCode = "+1";
        } else if (country === "India") {
            countryCode = "+91";
        } else if (country === "United Kingdom") {
            countryCode = "+44";
        } else if (country === "Germany") {
            countryCode = "+49";
        } else if (country === "France") {
            countryCode = "+33";
        } else if (country === "Spain") {
            countryCode = "+34";
        } else if (country === "Netherlands") {
            countryCode = "+31";
        } else if (country === "Australia") {
            countryCode = "+61";
        }

        if (toNumber.indexOf(countryCode) < 0) {
            toNumber = countryCode + toNumber;
        }

        let data = {};
        data.id = recordId;
        data.object = objectName.toLowerCase();
        data.toNumber = toNumber;
        data.fieldName = fieldName;
        data.country = country;
        data.fullName = fullName;
        data.isDetailView = true;
        let style = {};
        style.width = '40%';
        dispatch(getAppCustomDrawer(true, label, type, style, data, null, false));
    }

    const openTwilioPopOver = (event, fieldName) => {
        event.preventDefault();
        // let rect = event.currentTarget.getBoundingClientRect();
        // let x = rect.x - 616;
        // let y = rect.y - 362;
        // let pos = {};
        // pos.top = y;
        // pos.left = x;
        // this.state.pos = pos;
        setAnchorEl(event.currentTarget);
        setIsTwilioPopOverOpen(true);
    }

   const closeTwilioPopOver = (fieldName) => {
    setIsTwilioPopOverOpen(false);
    }

   const getTwilioPopOver = (objectName, field, fieldName, fieldValue, parentId, fullName) => {
        let popover = null;
        try {
            popover =  <Menu
            open={isTwilioPopOverOpen}
            onClose={closeTwilioPopOver}
            menuItemStyle={{ width: 168 }} 
            style={{width: 241 }}
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
            <MenuItem key='call' id='call' value='call'
                style={{ ...styles.popoverMenuItem,paddingLeft: 3 }}
                onClick ={(e)=> openTwilioDialog(e, field, objectName, parentId, fieldValue, fieldName,'call')}
            >
                <span >
                    <Icon style={{ height: 14, width: 14, margin: 9, fontSize: 14 }}>call</Icon>
                </span>
                <span style={{ marginLeft: '4px' }}>{getLocalizedStrings().label.TWILIO.CALL}</span> </MenuItem>
            <MenuItem key='sms' id='sms' value='sms'
                style={{ ...styles.popoverMenuItem,paddingLeft: 3}}
                onClick={(e)=> openTwilioDialog(e, field, objectName, parentId, fieldValue, fieldName,'sms')}
            >
                <span >
                    <Icon style={{ height: 16, width: 16, margin: 9, fontSize: 16 }}>textsms</Icon>
                </span>
                <span style={{ marginLeft: '4px' }}>{getLocalizedStrings().label.TWILIO_SMS.SEND_SMS}</span>
            </MenuItem>
            </Menu>
        } catch (error) {
            console.error("Error in 'AppTaskQueueChild.js -> getTwilioPopOver()':" + error);
        }
        return popover;
    }

   const openTwilioDialog = (event,field, object, recordId, fieldValue, fieldName, value) => {
    setIsTwilioPopOverOpen(false);
        if (value === 'call') {
            if (getBooleanParam(phoneFields.is_ring_central)) {
                openRingCentralWebWidget();
            } else {
                if (getBooleanParam(phoneFields.is_twilio)) {
                    getCapabilityTokenForCall(object, recordId, true, fieldName, fieldValue);
                }
            }
        } else if (value === 'sms') {
            getRecordDetailsForSMS(object, recordId, fieldName);
        }
    }


   const sendMail = (value, dataType, objectName, objectRecordId, fullName) => {
        let labelName = getLocalizedStrings().label.COMMON.EMAIL_TO;
        if (fullName !== null && fullName !== "" && fullName !== undefined) {
            labelName = labelName + " " + fullName + "";
        } else {
            labelName = labelName + " " + value + "";
        }
        openSendMailDialog(value, dataType, objectName, labelName, objectRecordId);
    }

  const  openSendMailDialog = (email, dataType, objectName, labelName, objectRecordId) => {
        let type = LinkToType.TYPE_EMAIL;
        try {
            let data = {};
            data.email = email;
            data.object = objectName;
            data.type = dataType;
            data.id = objectRecordId;
            dispatch(getAppCustomDrawer(true, labelName, type, { width: '90%' }, data, null, true));
        } catch (error) {

        }
    }

   const openRecord = (object, field, label, id, event) => {
        try {
            let linkUrl = '#';
            linkUrl = '/' + object + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + id;
            label = getStringParam(label);
            openTab(label, object, id, null, linkUrl);
        } catch (error) {
            console.error("Error in 'AppTaskQueueChild.js -> openRecord()':" + error);
        }
    }

   const openTab = (label, object, recordId, imgName, url) => {
        let tab = { label: label, object: object, type: TYPE_DETAIL_VIEW, imgName: imgName, url: url, isActive: true };
        addTab(tab, true);
    }

   const getObjectHeader = (linkObject) => {
        let actionIcon = getObjectHeaderIcon(linkObject);
        let objectLabel = getObjectLabelByObject(linkObject);
        objectLabel = getLocalizedStrings().label.NO_RECORD.hasOwnProperty(objectLabel) ? getLocalizedStrings().label.NO_RECORD[objectLabel] : objectLabel;
        let element = <div style={{ marginBottom: '10px' }}>
            <div style={{ padding: '8px', backgroundColor: '#e3e6e8', fontWeight: 'bold' }}>{objectLabel} {actionIcon}</div>
        </div>

        return element;
    }

   const getObjectHeaderIcon = (linkObject) => {
        let object_header_icon = null;
        try {
            let objectLabel = getObjectLabelByObject(linkObject);
            objectLabel = getLocalizedStrings().label.NO_RECORD.hasOwnProperty(objectLabel) ? getLocalizedStrings().label.NO_RECORD[objectLabel] : objectLabel;

            object_header_icon = <span style={{ float: 'right', paddingTop: '3px', color: '#B5B5B5', cursor: 'pointer' }}>
                <i
                    className="fa fa-pencil"
                    aria-hidden="true"
                    title={getLocalizedStrings().label.COMMON.EDIT + ' ' + objectLabel}
                    style={{ cursor: 'pointer', paddingRight: '3px' }}
                    onClick={()=> editChildRecord(linkObject)}
                />
            </span>;
        } catch (error) {
            console.error("Error in 'AppTaskQueueChild.js -> getObjectHeaderIcon()':" + error);
        }

        return object_header_icon;
    }

   const editChildRecord = (lookupObject) => {
        try {
            let fieldName = '';
            let lookupFieldName = '';
            if (lookupObject === constants.ACCOUNTS_OBJECT) {
                fieldName = 'company';
                lookupFieldName = 'company';
            } else if (lookupObject === constants.CONTACTS_OBJECT) {
                fieldName = 'fullname';
                lookupFieldName = 'full_name';
            } else if (lookupObject === constants.OPPORTUNITIES_OBJECT) {
                fieldName = 'opportunity_name';
                lookupFieldName = 'name';
            }

            let data = {};
            data.fieldName = getStringParam(fieldName);
            data.searchText = '';
            data.isLookupField = true;
            data.lookupFieldName = getStringParam(lookupFieldName);
            data.lookupObject = getStringParam(lookupObject);
            dispatch(getAppDialog(true, constants.LOOKUP_DIALOG, null, getLookupSelectedRecord, data, null));
        } catch (error) {
            console.error("Error in 'AppTaskQueueChild.js -> editChildRecord()':" + error);
        }
    }

   const getLookupSelectedRecord = (lookupInfo) => {
        try {
            if (isValidParam(lookupInfo)) {
                let lookupObject = getStringParam(lookupInfo.lookup_object_name).toLowerCase();
                let lookupObjectId = getIntParam(OBJECT_TABLEID_MAP[lookupObject]);
                let lookupRecordId = getIntParam(lookupInfo.record_id);
                let linkInfoArr = [];
                let newInfo = null;
                let isLookupExist = false;
                if (isValidParam(changeLog.linkInfoData) && lookupObject !== constants.ACCOUNTS_OBJECT) {
                    linkInfoArr = changeLog.linkInfoData.split(',');
                    linkInfoArr.map((data, i) => {
                        let rec = data.split('_');
                        if (isValidParam(rec)) {
                            let objectId = rec[0];
                            let recId = rec[1];
                            if (!isLookupExist && objectId === lookupObjectId) {
                                isLookupExist = true;
                            }
                            let tempInfo = '';
                            if (lookupObjectId === getIntParam(objectId)) {
                                tempInfo = lookupObjectId + '_' + lookupRecordId;
                            } else {
                                tempInfo = data;
                            }
                            if (newInfo !== null) {
                                newInfo = newInfo + ',' + tempInfo;
                            } else {
                                newInfo = tempInfo;
                            }
                        }
                    });
                }
                if (!isLookupExist) {
                    let tmpInfo = lookupObjectId + '_' + lookupRecordId;
                    if (newInfo !== null && newInfo !== "") {
                        newInfo = newInfo + ',' + tmpInfo;
                    } else {
                        newInfo = tmpInfo;
                    }
                }
                 setLinkInfoData(newInfo);
                 let log  = changeLog;
                 log.linkInfoData = newInfo;
                 setChangeLog({...log});
                 setMounted(false);
                let recPromise = getRecordPromise(lookupObject, lookupRecordId, null);
                recPromise.then((response) => {
                    if (isValidParam(response)) {
                        let lookupRecord = getObjectParam(response.records);
                        let contactEmail = null;
                        let title = '';
                        if (isValidParam(locationFldData) && locationFldData.hasOwnProperty('value')) {
                            contactEmail = locationFldData.value;
                        }

                        if (lookupObject === constants.CONTACTS_OBJECT) {
                            contactEmail = getStringParam(lookupRecord.email);
                            title = getStringParam(lookupRecord.full_name);
                        } else if (lookupObject === constants.ACCOUNTS_OBJECT) {
                            title = getStringParam(lookupRecord.company);
                        } else if (lookupObject === constants.OPPORTUNITIES_OBJECT) {
                            title = getStringParam(lookupRecord.name);
                        }
                        
                        saveLinkInfo(lookupObject, newInfo, contactEmail, title, lookupObject, lookupRecordId);
                    }
                });

            }
        } catch (error) {
            console.error("Error in 'AppTaskQueueChild.js -> getLookupSelectedRecord()':" + error);
        }
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

   const saveLinkInfo = (tempobject, newInfo, contactEmail, title, lookupObject, lookupRecordId) => {
        try {
            let params = {
                id: id,
                link_info: newInfo,
            }
            if (contactEmail !== null && contactEmail !== undefined) {
                params.location = getStringParam(contactEmail);
            }
            let paramFld = [];
            formFields.map((fld, i) => {
                let fldObj = {};
                fldObj.id = fld.id;
                fldObj.name = fld.name;
                if (fld.field_type === constants.FIELD_TYPE_DATE_TIME) {
                    let defaultDate = isValidParam(fld.value) ? getStringParam(fld.value).trim() : '';
                    defaultDate = defaultDate !== '' ? defaultDate : fld.default_value;

                    if (isValidParam(defaultDate) && defaultDate.trim() !== '') {
                        let momentObj = moment(defaultDate);
                        defaultDate = momentObj.format("YYYY-MM-DD HH:mm:ss");
                        fldObj.value = defaultDate === 'Invalid date' ? '' : defaultDate
                    }
                } else if (fld.name === 'link_info') {
                    fldObj.value = newInfo;
                } else {
                    if (tempobject === constants.CONTACTS_OBJECT && fld.name === 'fullname') {
                        fldObj.value = title;
                    } else if (tempobject === constants.ACCOUNTS_OBJECT && fld.name === 'company') {
                        fldObj.value = title;
                    } else if (tempobject === constants.OPPORTUNITIES_OBJECT && fld.name === 'opportunity_name') {
                        fldObj.value = title;
                    } else {
                        fldObj.value = fld.value;
                    }
                }

                paramFld.push(fldObj);
            });

            params.fields = paramFld;
            params.isQueueView = true;
            params.lookupObject = lookupObject;
            params.lookupRecordId = lookupRecordId;
            params.reminder = sfForm.reminder;
            var promise = saveRecordPromise(object, params);
            promise.then((response) => {
                if (isValidParam(response)) {
                    getRecord();
                    dispatch(refreshQueueDetailViewData(true));
                }
            });
        } catch (error) {
            console.error("Error in 'AppTaskQueueChild.js -> saveLinkInfo()':" + error);
        }
    }

    
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div>
                {(mounted) ?
                    <div>
                        <div style={{ height: '285px' }}>
                            {childView}
                        </div>
                        <div style={{ paddingTop: 20, paddingBottom: 10 }}>{getLinkedObject()}</div>
                    </div>
                    :
                    <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>}
            </div>
        );
    
}

function openRingCentralWebWidget() {
    var rcs = document.createElement("script");
    rcs.src = "https://ringcentral.github.io/ringcentral-embeddable-voice/adapter.js";
    rcs.id = "ring-central_widget_adapter";
    var rcs0 = document.getElementsByTagName("script")[0];
    if (rcs0.id !== rcs.id) {
        rcs0.parentNode.insertBefore(rcs, rcs0);
    }
    if (window.RCAdapter) {
        window.RCAdapter.setMinimized(false);
    }
}

export default AppTaskQueueChild;
