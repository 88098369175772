
import React, {useEffect, useState} from 'react';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import Button from '@mui/material/Button';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { isValidParam, isInvalidParam } from '../../../services/helper/parameterVerifier';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { addNode } from '../../../services/actions/automationCampaignActions';
import { removeError } from '../../../services/helper/automationUtils';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import {useDispatch, useSelector} from 'react-redux';

const AutomationWebForm = ({data,closeDrawer}) => {
    const dispatch = useDispatch();
    const automationCampaign = useSelector((state) => state.automationCampaign);
    const [selectedWebform,setSelectedWebform] = useState(0);
    const [accountWfList,setAccountWfList] = useState(null);
    const [contactWfList,setContactWfList] = useState(null);
    const [objectName,setObjectName] = useState(null);
    const [webformName,setWebformName] = useState(null);
    const [tableId,setTableId] = useState(0);
    const [accountsObjectName,setAccountsObjectName] = useState(null);

    
    useEffect(() => {
        let nodeObject = data;
        if (isValidParam(nodeObject)) {
            let detailsArray = null;
            let selectedWfId = 0;
            if (nodeObject.hasOwnProperty('details')) {
                detailsArray = nodeObject.details[0];
            }
            if (isValidParam(detailsArray)) {
                selectedWfId = detailsArray.webform_id;
                generateWebformList(detailsArray);
            } else {
                selectedWfId = 0;
                generateWebformList();
            }
        }
    }, []);

    const getWebformList = () => {
        try {
            let webformId = selectedWebform;
            let params = {
                id: webformId
            }
            let response = Promise.resolve(HTTPClient.get(endPoints.AUTOMATION_DESIGNER.GET_ACTIVE_WEBFORM, params));
            response.then((response) => {
                if (isValidParam(response)) {
                    if (response.hasOwnProperty('webform_list')) {
                        let webformListTemp = response.webform_list;
                        let accountWfList = [];
                        let contactWfList = [];

                        webformListTemp.map((objWebform, i) => {
                            if (objWebform.tableId === 10) {
                                accountWfList.push(
                                    <MenuItem
                                        value={objWebform.id}

                                        style={{ paddingLeft: '12px' }}
                                        onClick={() => selectWebForm(objWebform)}
                                        className="menuItemWebform"
                                        title={objWebform.name} >{objWebform.name}</MenuItem>
                                );
                            }
                        });

                        webformListTemp.map((objWebform, i) => {
                            if (objWebform.tableId === 9) {
                                contactWfList.push(
                                    <MenuItem
                                        value={objWebform.id}

                                        style={{ paddingLeft: '12px' }}
                                        onClick={() => selectWebForm(objWebform)}
                                        className="menuItemWebform"
                                        title={objWebform.name} >{objWebform.name}</MenuItem>
                                );
                            }
                        });
                        setAccountWfList(accountWfList);
                        setContactWfList(contactWfList);
                        
                    }
                }
            });
        } catch (error) {
            console.log("Error in 'AutomationWebForm.js -> getWebformList()':" + error);
        }
    }

    const generateWebformList = (detailsArray) => {
        let params = null;
        let webformId = 0;
        let objectName = "";
        let webformName = "";
        if (isValidParam(detailsArray)) {
            webformId = detailsArray.webform_id;
            objectName = detailsArray.objectName;
            webformName = detailsArray.object_indentifierText;
        } else {
            webformId = 0;
        }
        params = {
            id: webformId
        }
        let response = Promise.resolve(HTTPClient.get(endPoints.AUTOMATION_DESIGNER.GET_ACTIVE_WEBFORM, params));
        response.then((response) => {
            if (isValidParam(response)) {
                if (response.hasOwnProperty('webform_list')) {
                    let webformList = response.webform_list;
                    let _accountWfList = accountWfList;
                    let _contactWfList = contactWfList;
                    if (isValidParam(webformList)) {
                        if (_accountWfList === null) {
                            _accountWfList = [];
                        }
                        webformList.map((objWebform, i) => {
                            if (objWebform.tableId === 10) {
                                _accountWfList.push(
                                    <MenuItem
                                        value={objWebform.id}
                                        style={{ paddingLeft: '12px' }}
                                        onClick={() => selectWebForm(objWebform)}
                                        className="menuItemWebform"
                                        title={objWebform.name} >{objWebform.name}</MenuItem>
                                );
                            }

                            if (i === 1) {
                                setAccountsObjectName(objWebform.objectName);
                            }
                        });

                        if (_contactWfList === null) {
                            _contactWfList = [];
                        }
                        webformList.map((objWebform, i) => {
                            if (objWebform.tableId === 9) {
                                _contactWfList.push(
                                    <MenuItem
                                        value={objWebform.id}
                                        style={{ paddingLeft: '12px' }}
                                        onClick={() => selectWebForm(objWebform)}
                                        className="menuItemWebform"
                                        title={objWebform.name} >{objWebform.name}</MenuItem>
                                );
                            }
                        });
                        
                        let tableId = 0;
                        if(webformId > 0){
                            let selectedWebform = webformList.filter(f => f.id === webformId);
                            if(isValidParam(selectedWebform)){
                                tableId = selectedWebform[0].tableId;
                            }
                        }
                        
                        setSelectedWebform(webformId);
                        setAccountWfList(_accountWfList);
                        setContactWfList(_contactWfList);
                        setObjectName(objectName);
                        setWebformName(webformName);
                        setTableId(tableId);
                        
                    }
                }
            }
        });
    }

    const selectWebForm = (objWebform) => {
        if (isValidParam(objWebform)) {
            setSelectedWebform(objWebform.id);
            setObjectName(objWebform.objectName);
            setWebformName(objWebform.name);
            setTableId(objWebform.tableId);
            
        }
    }

    const openDialog = (dialogData) => {
        dispatch(getAppDialog(true, constants.ALERT_DIALOG, getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, null, dialogData, constants.AUTOMATION_DESIGNER_OBJECT));
    }

    const saveWebform = () => {
        let nodeObject = data;
        let webformId = selectedWebform;
        let dialogData = null;
        if (isInvalidParam(webformId) || isInvalidParam(objectName) || isInvalidParam(webformName)) {
            dialogData = {
                message: getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_WEB_FORM,
            }
            openDialog(dialogData);
        } else {
            let detailsArray = null;
            if (nodeObject.hasOwnProperty('details')) {
                detailsArray = nodeObject.details[0];
            }
            if (isValidParam(detailsArray)) {
                detailsArray.objectName = objectName;
                detailsArray.object_indentifierText = webformName;
                detailsArray.webform_id = webformId;
            } else {
                let array = [];
                detailsArray = {
                    objectName: objectName,
                    object_indentifierText: webformName,
                    webform_id: webformId
                }
                array.push(detailsArray);
                nodeObject.details = array;
            }
            nodeObject = removeError(nodeObject);
            dispatch(addNode(automationCampaign.data));
            closeDrawer();
        }
    }

    const addNewWebform = () => {
        try {
            let tableId = 10;
            let token = localStorage.getItem('token');
            let redirectUrl = constants.snapshotUrl;
            redirectUrl += '&token=' + token;
            redirectUrl += '&callingfor=' + "webform";
            redirectUrl += '&type=CUSTOM_VIEW';
            redirectUrl += '&label=' + constants.WEB_FORM_CREATE_TAB_LABEL;
            redirectUrl += '&tableId=' + tableId;
            window.open(redirectUrl);
        } catch (error) {
            console.log("Error in 'AutomationWebForm.js -> addNewWebform()':" + error);
        }
    }

    const editWebform = () => { 
        try {
            let token = localStorage.getItem('token');
            let redirectUrl = constants.snapshotUrl;
            redirectUrl += '&token=' + token;
            redirectUrl += '&callingfor=' + "webform";
            redirectUrl += '&recordId=' + selectedWebform;
            redirectUrl += '&tableId=' + tableId;
            redirectUrl += '&type=' + constants.EDIT.toLowerCase();
            redirectUrl += '&label=Edit ' + webformName;
            window.open(redirectUrl);
        } catch (error) {
            console.log("Error in 'AutomationWebForm.js -> editWebform()':" + error);
        }
    }
    let campaignStatus = automationCampaign.data.status;
    return (
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

            <div style={
                {
                    padding: '10px',
                    marginLeft:'8px'
                }
            }>
                <FormControl variant="outlined" style={{ width: '240px', marginTop: '3px' }}>
                    <InputLabel id="sf-automationweb-form-from-automationwebform-simple-select-outlined-label" className="sf-automationweb-form-from-automationwebform">{getLocalizedStrings().label.WEB_FORM.SELECT_FORM}</InputLabel>
                    <Select
                        value={selectedWebform}
                        label={getLocalizedStrings().label.WEB_FORM.SELECT_FORM}
                        labelId='sf-label-webform'
                        id='sf-webform'
                        className={"sf-fields-bg"}
                        style={{height:40}}
                    >
                        {
                            (accountWfList !== null && accountWfList.length > 0) &&
                            <MenuItem className="menuItemWebform" value={accountsObjectName} disabled={true} style={{ fontWeight: 'bold', fontSize: '17px' }} >{accountsObjectName}</MenuItem>
                        }
                        {accountWfList}

                        {
                            (contactWfList !== null && contactWfList.length > 0) &&
                            <MenuItem className="menuItemWebform" value={"Contacts"} disabled={true} style={{ fontWeight: 'bold', fontSize: '17px' }}>Contacts</MenuItem>
                        }
                        {contactWfList}
                    </Select>
                </FormControl>


                {
                    (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                    <div style={
                        {
                          marginTop:'15px'
                        }
                      
                    }>
                        <Button
                            style={styles.listViewPrimaryButton}
                            onClick={()=>saveWebform()}
                        >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                    </div>
                }

            </div>

            <div style={
                {
                    display: 'flex',
                    padding: '10px',
                    marginTop:campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING?'-42px':'0px',
                }
                // { display: 'inline-block', verticalAlign: 'top', paddingTop: '13px', paddingRight: '15px', float: 'right' }
            }>
                <span onClick={() => getWebformList()}>
                    <i className="fa fa-refresh" aria-hidden="true" style={{ cursor: 'pointer' }} title={getLocalizedStrings().label.COMMON.REFRESH}></i>
                </span>
                {
                    (selectedWebform > 0) &&
                    <span style={{ paddingLeft: '12px' }}>
                        <i onClick={() => editWebform()} className="fa fa-pencil" aria-hidden="true" style={{ cursor: 'pointer' }} title={getLocalizedStrings().label.COMMON.EDIT}></i>
                    </span>
                }
                <span style={{ paddingLeft: '12px' }}>
                    <i onClick={() => addNewWebform()} className="fa fa-plus" aria-hidden="true" style={{ cursor: 'pointer' }} title={getLocalizedStrings().label.COMMON.ADD}></i>
                </span>
            </div>
            {/* <div className="clearfix"></div> */}
        </div>
    );
}


export default AutomationWebForm;

