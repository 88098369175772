import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { constants } from '../../../services/constants/constants';
import { Menu, Select, MenuItem, InputLabel, Button, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styles } from '../../../services/constants/styles';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { isValidParam, getIntParam, getStringParam, getArrayParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { alert } from '../components/sfDialogs';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import concat from 'lodash/concat';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { getWorkflowStageById } from '../../../services/actions/importAction';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction'
import ShowCircularProgress from '../components/circularProgress';
import { useDispatch, useSelector } from "react-redux";

const CompanySchedulerFields = () => {

    const appContainer = useSelector((state) => state.appContainer);
    const sfForm = useSelector((state) => state.sfForm);
    const app = useSelector((state) => state.app);
    const schedulerDetails = useSelector((state) => state.schedulerDetails);
    const dispatch = useDispatch();

    const [fieldList, setFieldList] = useState([]);
    const [defaultFormFiels, setDefaultFormFiels] = useState([]);
    const [customFormFiels, setCustomFormFiels] = useState([]);
    const [availabeFields, setAvailabeFields] = useState([]);
    const [bookingButtonLabel, setBookingButtonLabel] = useState('Schedule My Meeting');
    const [onChangeAppointmentType, setOnChangeAppointmentType] = useState('');
    const [thankYouMsg, setThankYouMsg] = useState('Your meeting is confirmed. I look forward to speaking with you.');
    const [formFieldsInfo, setFormFieldsInfo] = useState({});
    const [changeLog, setChangeLog] = useState({ formFieldsInfo: {} , selectedWorkflow: 0 });
    const [workflowList, setWorkflowList] = useState([]);
    const [selectedWorkflow, setSelectedWorkflow] = useState(0);
    const [workflowStatusList, setWorkflowStatusList] = useState(null);
    const [defaultSelectedStatus, setDefaultSelectedStatus] = useState('');
    const [isSavedClicked, setIsSavedClicked] = useState(false);
    const [mounted, setMounted] = useState(false);


    useEffect(() => {
        populateFields();
        getIntakeFields();
    }, [])


    const getIntakeFields = () => {
        var promise = Promise.resolve(HTTPClient.get(endPoints.COMPANY_SCHEDULER.GET_COMPANY_SCHEDULER_FIELDS, null));

        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.hasOwnProperty('intakeFormInfo')) {
                        let temp = changeLog;
                        temp.formFieldsInfo = Object.assign({}, {...response.intakeFormInfo});
                        setChangeLog({ ...temp });
                        if (Object.keys(response.intakeFormInfo).length > 0) {
                            let _temp = changeLog;
                            _temp.selectedWorkflow = response.intakeFormInfo.workflow_id;
                            setChangeLog({...temp})
                            setDefaultSelectedStatus(response.intakeFormInfo.status_name);
                        }
                        getWorkflowListByObject(constants.ACCOUNTS_OBJECT);
                    }
                }
            });
        }
    }
    const saveFieldsInfo = () => {
        try {
            let param = {};
            let intakeFormInfo = getObjectParam(changeLog.formFieldsInfo);
            let intakeFormSelectedFields = [];
            let fieldsList = sfForm.data[constants.ACCOUNTS_OBJECT];
            let wffields = fieldsList.fields;
            wffields = wffields.filter(f => { return f.name == "workflow_id" || f.name == "t_status" });

            let wfObj = new Object();
            let statusObj = new Object();
            wffields.map((f, i) => {
                if (f.name == "t_status") {
                    statusObj.id = f.id;
                    statusObj.name = f.name;
                    statusObj.label = f.label;
                    statusObj.type = f.field_type_id;
                    statusObj.isRequire = true;
                    statusObj.defaultValue = defaultSelectedStatus.toString();
                    statusObj.isDelete = false;
                }
                if (f.name == "workflow_id") {
                    wfObj.id = f.id;
                    wfObj.name = f.name;
                    wfObj.label = f.label;
                    wfObj.type = f.field_type_id;
                    wfObj.isRequire = true;
                    wfObj.defaultValue = changeLog.selectedWorkflow.toString();
                    wfObj.isDelete = false;
                }
            });
            if(intakeFormInfo.hasOwnProperty('selectedFields')){
                intakeFormSelectedFields = intakeFormInfo.selectedFields.filter(f => { return f.name != "workflow_id" && f.name != "t_status" })
            }
      
            intakeFormSelectedFields.push(wfObj);
            intakeFormSelectedFields.push(statusObj);
            let Fields = [];
            intakeFormSelectedFields.map((f, i) => {
                let tempObj = f;
                tempObj.booking_btn_label = intakeFormInfo.bookingButtonLabel != undefined ? intakeFormInfo.bookingButtonLabel :bookingButtonLabel ;
                tempObj.thank_u_msg = intakeFormInfo.thankYouMsg != undefined ? intakeFormInfo.thankYouMsg : thankYouMsg ;
                tempObj.defaultValue = tempObj.defaultValue;

                Fields.push(tempObj);

            });
            intakeFormInfo.selectedFields = Fields;
            param.intakeFormInfo = intakeFormInfo;
            setIsSavedClicked(true);
            var promise = Promise.resolve(HTTPClient.post(endPoints.COMPANY_SCHEDULER.SAVE_COMPANY_SCHEDULER_FIELDS, param));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        setIsSavedClicked(false);

                        if (response.status == 0) {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.SCHEDULER.SAVE_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        } else {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.SCHEDULER.SAVE_FAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        }
                    }
                });
            }
        } catch (error) {
            console.error("error in scheduler.js -> saveSchedulingSetup() ::" + error);
        }
    }
  const populateFields = () => {
        try {
            let fieldsList = sfForm.data[constants.ACCOUNTS_OBJECT];
            let fields = fieldsList.fields;
            fields = fields.filter(f => {
                return (f.field_type != "header" && f.field_type != "" && f.field_type != 'integer'
                    && !f.is_hidden_field && !f.is_readonly && f.name != "workflow_name" && f.name != "opportunity_amount" &&
                    f.name != "sales_rep" && f.name != "t_type" && f.name != "lead_score");
            });
           

            let tempFields = [];
            let availabeFields = fields;
            if (fields.length > 0) {
                let fNameObj = new Object();
                let lNameObj = new Object();
                let emailObj = new Object();
                fields.map((f, i) => {
                    if (f.name == 'first_name') {
                        fNameObj.id = f.id;
                        fNameObj.name = f.name;
                        fNameObj.label = f.label;
                        fNameObj.type = f.field_type_id;
                        fNameObj.isRequire = true;
                        fNameObj.defaultValue = "";
                        fNameObj.isDelete = false;
                        availabeFields = availabeFields.filter(field => { return f.id != field.id });
                    }
                    if (f.name == 'last_name') {
                        lNameObj.id = f.id;
                        lNameObj.name = f.name;
                        lNameObj.label = f.label;
                        lNameObj.type = f.field_type_id;
                        lNameObj.isRequire = true;
                        lNameObj.defaultValue = "";
                        lNameObj.isDelete = false;
                        availabeFields = availabeFields.filter(field => { return f.id != field.id });
                    }
                    if (f.name == 'email') {
                        emailObj.id = f.id;
                        emailObj.name = f.name;
                        emailObj.label = f.label;
                        emailObj.type = f.field_type_id;
                        emailObj.isRequire = true;
                        emailObj.defaultValue = "";
                        emailObj.isDelete = false;
                        availabeFields = availabeFields.filter(field => { return f.id != field.id });
                    }
                   
                });
                tempFields.push(fNameObj);
                tempFields.push(lNameObj);
                tempFields.push(emailObj);
                sortArrayObjectByProperty(availabeFields, 'label');
            }

            let _formFieldsInfo = {};

            let fieldInfo = getObjectParam(changeLog.formFieldsInfo);
            if (Object.keys(fieldInfo).length > 0) {
                _formFieldsInfo = fieldInfo;
            } else {
                _formFieldsInfo = {
                    selectedFields: tempFields,
                    bookingButtonLabel: bookingButtonLabel,
                    thankYouMsg: thankYouMsg,
                    workflow_id: changeLog.selectedWorkflow,
                    status_name: defaultSelectedStatus,
                };
            }

            let customFields = customFormFiels;
            if (_formFieldsInfo.hasOwnProperty('selectedFields')) {
                let selectedFields = getArrayParam(_formFieldsInfo.selectedFields);
                let tempCustomFields = selectedFields.filter(f => {
                    return (f.name != 'first_name' &&
                        f.name != 'last_name' &&
                        f.name != 'email')
                });
                tempCustomFields.map((f, i) => {
                    let tempObj = new Object();
                    availabeFields.map((fld, index) => {
                        if (f.name == fld.name) {
                            tempObj.id = fld.id;
                            tempObj.name = f.name;
                            tempObj.label = fld.label;
                            tempObj.type = fld.field_type_id;
                            tempObj.isRequire = f.isRequire;
                            tempObj.defaultValue = "";
                        }
                    });
                    customFields.push(tempObj);
                });
                setCustomFormFiels(customFields);
                setBookingButtonLabel(_formFieldsInfo.bookingButtonLabel);
                setThankYouMsg(_formFieldsInfo.thankYouMsg);
                 setDefaultSelectedStatus(_formFieldsInfo.status_name);
                 let _temp = changeLog;
                 _temp.selectedWorkflow = _formFieldsInfo.workflow_id;
                 setChangeLog({..._temp});
              
            }

            
            setDefaultFormFiels(tempFields);
            setFieldList(fields);
            setAvailabeFields(availabeFields);
            let temp = changeLog;
            temp.formFieldsInfo = _formFieldsInfo;
            setChangeLog({ ...temp });
        } catch (error) {
            console.error('Error on SchedulerIntakeForm -> populateFields() ' + error);
        }
    }


    const getWorkflowListByObject = (object) => {
        let _selectedWorkflow;
        let url = endPoints.WORKFLOWS.LIST_GET + "/" + object;
        try {
            let promise = Promise.resolve(HTTPClient.get(url, null));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let records = getArrayParam(response);
                    if (records.length > 0) {
                        let workflowListObj = new Object();
                        workflowListObj[object] = records;
                        if (changeLog.selectedWorkflow > 0) {
                            _selectedWorkflow = changeLog.selectedWorkflow;
                        } else {
                            _selectedWorkflow = records[0].id;
                        }
                        let _formFieldsInfo = getObjectParam(changeLog.formFieldsInfo);
                        if (_selectedWorkflow > 0) {
                            _formFieldsInfo.workflow_id = _selectedWorkflow;
                        }
                        setWorkflowList(records);
                        let temp = changeLog;
                        temp.formFieldsInfo = _formFieldsInfo;
                        temp.selectedWorkflow= _selectedWorkflow;
                        setChangeLog({ ...temp });
                        getWorkStageByWorkflowId(_selectedWorkflow, 'onload');

                    }

                }

            });


        } catch (error) {
            console.log("Error in 'SchedulerIntakeForm.js -> getWorkflowListByObject()':" + error)
        }
    }

    const generateWorkflowList = () => {
        let _workflowList = null;
        let arrayWorkflowList = workflowList;
        try {
            if (isValidParam(arrayWorkflowList)) {
                _workflowList = arrayWorkflowList.map((objWorkflow, i) => {
                    return (
                        <MenuItem key={i} id={i} value={objWorkflow.id}  >{objWorkflow.name}</MenuItem>
                    );
                });
            }
        } catch (error) {
            console.log("Error in 'SchedulerIntakeForm.js -> generateWorkflowList()':" + error)
        }
        return _workflowList;
    }

    const changeWorkflow = (value) => {
        let _formFieldsInfo = getObjectParam(changeLog.formFieldsInfo);
        _formFieldsInfo.workflow_id = value;
        let temp = changeLog;
        temp.formFieldsInfo = _formFieldsInfo;
        temp.selectedWorkflow =value ;
        setChangeLog({ ...temp });
        getWorkStageByWorkflowId(value, 'onchange');

    }

    const getWorkStageByWorkflowId = (workflowId, type) => {
        try {
            let params = {
                workflow_id: workflowId
            }
            let promise = Promise.resolve(getWorkflowStageById(params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        let _formFieldsInfo = getObjectParam(changeLog.formFieldsInfo);
                        let _selectedStatus = response.stages[0].name;
                        let tempStatus = response.stages.filter(f => { return f.name == _formFieldsInfo.status_name });
                        if (tempStatus.length > 0) {
                            _selectedStatus = _formFieldsInfo.status_name;
                        }
                        if (changeLog.selectedWorkflow > 0) {
                            _formFieldsInfo.status_name = _selectedStatus;
                        }
                        setWorkflowStatusList(response.stages);
                        setMounted(true);
                        setDefaultSelectedStatus(_selectedStatus);
                        let temp = changeLog;
                        temp.formFieldsInfo = _formFieldsInfo;
                        temp.selectedWorkflow = workflowId;
                        setChangeLog({ ...temp });
                        if (type == 'onload') {
                            populateFields();
                        }

                    }
                });
            }
        } catch (error) {
            console.log("Error in 'SchedulerIntakeForm.js -> getWorkStageByWorkflowId()':" + error)
        }
    }

    const generateStatusList = () => {
        let statusList = null;
        let arrayStatusList = workflowStatusList;
        try {
            if (isValidParam(arrayStatusList)) {
                statusList = arrayStatusList.map((objStatus, i) => {
                    return (
                        <MenuItem key={i} id={i} value={objStatus.name}  >{objStatus.name}</MenuItem>
                    );
                });
            }
        } catch (error) {
            console.log("Error in 'SchedulerIntakeForm.js -> generateStatusList()':" + error)
        }
        return statusList;
    }

    const changeStatus = (value) => {
        let _formFieldsInfo = getObjectParam(changeLog.formFieldsInfo);
        _formFieldsInfo.status_name = value;
        setDefaultSelectedStatus(value);
        let temp = changeLog;
        temp.formFieldsInfo = _formFieldsInfo;
        setChangeLog({ ...temp });
    }

    const setDefaultFields = () => {
        let _defaultFormFiels = defaultFormFiels;
        let element = null;
        _defaultFormFiels.map((f, i) => {
            let tempElement = (<div>
                <div className="row" style={{ marginBottom: '5px' }}>
                    <div className="col-sm-3">
                        <span style={{ width: '100%', display: 'inline-block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={f.label}>{f.label}</span>
                    </div>
                    <div className="col-sm-4">
                        <div className="repeatChkBox" style={{ width: '8%', float: 'left', marginTop: '-8px' }}>
                            <FormControlLabel
                                control={<Checkbox
                                    color="primary"
                                    value={f.isRequire}
                                    disabled
                                    checked
                                />}
                                labelposition="left"
                                labelStyle={{ color: '#717171', padding: '0px', width: 'auto', fontWeight: 'normal' }}
                                style={{ height: 'auto' }}
                                title={getLocalizedStrings().label.SCHEDULER.REQUIRED} />

                        </div>
                        <div style={{ paddingLeft: '30px', marginTop: '0px' }}>{getLocalizedStrings().label.SCHEDULER.REQUIRED}</div>
                    </div>
                </div>
            </div>);
            element = <div>{element} {tempElement}</div>
        });
        return element;
    }

    const changeTextFieldValue = (event) => {
        let _formFieldInfo = changeLog.formFieldsInfo;
        if (event.target.name == 'buttonlabel') {
            if (_formFieldInfo.hasOwnProperty('error')) {
                delete _formFieldInfo.error;
            }
            _formFieldInfo.bookingButtonLabel = event.target.value;
            setBookingButtonLabel(event.target.value);
            let temp = changeLog;
            temp.formFieldsInfo = _formFieldInfo;
            setChangeLog({ ...temp });
        } else if (event.target.name == 'thankyoumsg') {
            _formFieldInfo.thankYouMsg = event.target.value;
            setThankYouMsg(event.target.value);
            let temp = changeLog;
            temp.formFieldsInfo = _formFieldInfo;
            setChangeLog({ ...temp });
        }

    }

    const addFormField = () => {
        try {
            let _customFormFiels = customFormFiels;
            let _availabeFields = availabeFields;
            let isSelected = false;
            let tempField = null;
            if (_customFormFiels.length == 0) {
                tempField = _availabeFields[0];
            } else {
                let tempList = _availabeFields.filter(f => {
                    let ele = _customFormFiels.find(e => {
                        return e.id == f.id;
                    });
                    if (isValidParam(ele)) {
                        isSelected = true;
                    } else {
                        isSelected = false;
                    }
                    if (!isSelected) return f;
                });
                tempField = tempList[0];
            }
            if (isValidParam(tempField)) {
                let tempObj = new Object();
                tempObj.id = tempField.id;
                tempObj.name = tempField.name;
                tempObj.label = tempField.label;
                tempObj.type = tempField.field_type_id;
                tempObj.isRequire = false;
                tempObj.isDelete = true;
                tempObj.defaultValue = "";
                _customFormFiels.push(tempObj);
            }
            let formFieldInfo = changeLog.formFieldsInfo;
            formFieldInfo.selectedFields = concat(defaultFormFiels, _customFormFiels);
            setCustomFormFiels([..._customFormFiels]);
            let temp = changeLog;
            temp.formFieldsInfo = formFieldInfo;
            setChangeLog({ ...temp });
        } catch (error) {
            console.error('Error on schedulerIntakeForm => addFormField() ' + error);
        }
    }

    const changeField = (key, fldId) => {
        try {
            key = getIntParam(key);
            let _customFormFiels = customFormFiels;
            let _availabeFields = availabeFields;
            let selectedField = _availabeFields.filter(f => { return f.id == fldId });
            let customSelectc = _customFormFiels.filter(f => { return f.id == fldId });
            let isSelected = false;
            if (customSelectc.length > 0) {
                isSelected = true;
            }
            let tempArray = [];
            if (!isSelected) {
                _customFormFiels.map((fld, i) => {
                    let tempObj = new Object();
                    tempObj = fld;
                    if (i == key) {
                        tempObj = selectedField[0];
                        tempObj.id = selectedField[0].id;
                        tempObj.name = selectedField[0].name;
                        tempObj.label = selectedField[0].label;
                        tempObj.type = selectedField[0].field_type_id;
                        tempObj.isRequire = fld.isRequire;
                        tempObj.defaultValue = "";
                        tempObj.isDelete = fld.isDelete;
                    }
                    tempArray.push(tempObj);
                });
                let _formFieldInfo = changeLog.formFieldsInfo;
                _formFieldInfo.selectedFields = concat(defaultFormFiels, tempArray);
                setCustomFormFiels(tempArray);
                let temp = changeLog;
                temp.formFieldsInfo = _formFieldInfo;
                setChangeLog({ ...temp });

            } else {
                alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.MULTIPLE_TIME_SELECTED_MSG_1 + ' ' + selectedField[0].label + '  multiple times. Please remove the duplicate rows. ');
                return false;
            }
        } catch (error) {
            console.error('Error on schedulerIntakeForm => changeField() ' + error);
        }
    }

    const setAdditionalFields = () => {
        try {
            let _customFormFiels = customFormFiels;
            let _availabeFields = availabeFields;
            let customFieldRows = new Array;
            let keyCount = 0;
            if (_customFormFiels.length > 0) {
                _customFormFiels.map((fld, key) => {
                    ++keyCount;
                    let listComponent = new Array();
                    _availabeFields.map((fld, i) => {
                        let menuList = <MenuItem
                            onClick={() => changeField(key, fld.id)}
                            value={fld.id}
                            title={fld.label}
                            style={{ ...styles.popoverMenuItem }}
                            className='menuItemIntakeFields'
                        >{fld.label}</MenuItem>
                        listComponent.push(menuList);
                    });
                    customFieldRows.push(
                        <div key={"1_" + key} className='row'>
                            <div key={"2_" + key} className='col-sm-3'>
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <InputLabel id="sf-schedulerintakeform-simple-select-outlined-label" className="sf-schedulerintakeform">{null}</InputLabel>
                                    <Select
                                        key={"3_" + key}
                                        value={fld.id}
                                        title={fld.label}
                                        style={{ height: '40px', marginBottom: '10px' }}
                                        className={"sf-fields-bg"}>
                                        {listComponent}
                                    </Select>
                                </FormControl>
                                <input key={'input_hidden_' + keyCount} type="hidden" id={"field_name_" + keyCount} value={key} />
                            </div>
                            <div className="col-sm-2">
                                <div className="repeatChkBox" style={{ width: '8%', float: 'left' }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            color="default"
                                            checked={fld.isRequire}
                                            value={fld.isRequire}
                                            onChange={(e) => handleRepeatCheckBox(key, e)}
                                        />}
                                        labelposition="left"
                                        labelStyle={{ color: '#717171', padding: '0px', width: 'auto', fontWeight: 'normal' }}
                                        style={{ height: 'auto' }}
                                        title={getLocalizedStrings().label.SCHEDULER.REQUIRED} />

                                </div>
                                <div style={{ paddingLeft: '30px', marginTop: '9px' }}>{getLocalizedStrings().label.SCHEDULER.REQUIRED}</div>
                            </div>

                            <div className='col-sm-1' key={'cross_icon_div_' + keyCount} style={{ paddingTop: '14px' }}>
                                <i id={key}
                                    key={'cross_icon_' + keyCount}
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                    style={{ cursor: 'pointer', fontSize: '14px' }}
                                    onClick={() => removeField(key)}
                                    title={getLocalizedStrings().label.COMMON.DELETE}
                                ></i>
                            </div>
                        </div>
                    );
                })
            }
            return customFieldRows;
        } catch (error) {
            console.error('Error on schedulerIntakeForm => setAdditionalFields() ' + error);
        }
    }

    const removeField = (key) => {
        key = getIntParam(key);
        let _customFormFiels = customFormFiels;
        let removeField = _customFormFiels[key];
        _customFormFiels = _customFormFiels.filter(f => {
            return f.id != removeField.id;
        });
        let formFieldInfo = changeLog.formFieldsInfo;
        formFieldInfo.selectedFields = concat(defaultFormFiels, _customFormFiels)
        setCustomFormFiels(_customFormFiels);
        let temp = changeLog;
        temp.formFieldsInfo = formFieldInfo;
        setChangeLog({ ...temp });
    }

    const handleRepeatCheckBox = (key, event) => {
        key = getIntParam(key);
        let _customFormFiels = customFormFiels;
        let tempArray = [];
        _customFormFiels.map((fld, i) => {
            let tempObj = new Object();
            tempObj = fld;
            if (i == key) {
                tempObj.isRequire = event.target.checked;
            }
            tempArray.push(tempObj);
        });
        let formFieldInfo = changeLog.formFieldsInfo;
        formFieldInfo.selectedFields = concat(defaultFormFiels, tempArray)
        setCustomFormFiels(tempArray);
        let temp = changeLog;
        temp.formFieldsInfo = formFieldInfo;
        setChangeLog({ ...temp });
    }

    let defaultFields = setDefaultFields();
    let additionalFields = setAdditionalFields()
    let textFldStyle = { backgroundColor: '#fff', height: '42px', paddingLeft: '7px', marginBottom: '20px', width: '100%' };
    let formTexFldStyle = {};
    if (changeLog.formFieldsInfo.hasOwnProperty('error') && changeLog.formFieldsInfo.error == 'submit_button_error') {
        formTexFldStyle = { ...textFldStyle, border: '1px solid #f75723' };
    } else {
        formTexFldStyle = { ...textFldStyle, border: '1px solid rgb(221, 221, 221)' };
    }

    if (mounted) {

        return (
            <div style={{ paddingLeft: '50px', width: '90%' }}>
                <div className="row">
                    <div id="header" style={{ paddingLeft: '9px', width: '100%', fontSize: '26px', paddingBottom: '12px' }}>{getLocalizedStrings().label.SCHEDULER.INTAKE_FORM}</div>
                    <div className="row" style={{ paddingTop: '5px'}}>
                        <div id="fieldheader" style={{ paddingLeft: '12px', width: '100%', fontSize: '17px', fontWeight: 'bold', paddingBottom: '12px' }}>{getLocalizedStrings().label.SCHEDULER.DEFINE_YOUR_INTAKE_FORM}</div>
                        <div  style={{}}>
                            {defaultFields}
                        </div>
                        <div className="col-sm-12">
                            {additionalFields}
                            {availabeFields.length > customFormFiels.length &&
                                <div>
                                    <span style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} onClick={() => addFormField()}>
                                        {getLocalizedStrings().label.SCHEDULER.ADD_ANOTHER_FIELD}
                                    </span>
                                </div>}
                        </div>
                    </div>
                </div>
                <div className="row" style={{ paddingTop: '15px' }}>
                    <div className="col-sm-8">
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                // key={field.id}
                                name='buttonlabel'
                                type='text'
                                multiline={false}
                                label={getLocalizedStrings().label.SCHEDULER.SUBMIT_BUTTON_LABEL}
                                disabled={false}
                                onChange={(e) => changeTextFieldValue(e)}
                                value={bookingButtonLabel}
                                inputprops={{ maxLength: 32 }}
                                className={"sf-fields-bg"}
                                autoComplete="new-password"
                                margin='dense'
                                size='small'
                            />
                        </FormControl>
                    </div>
                </div>
                {workflowList.length > 1 &&
                    <div>
                        <div style={{ paddingLeft: '15px', width: '100%', fontSize: '17px', fontWeight: 'bold', marginTop: '15px', paddingBottom: '18px' }}>{getLocalizedStrings().label.SCHEDULER.CHOOSE_A_STATUS_FOR_LEAD}</div>
                        <div className="row">
                            <div className="col-sm-4">
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <InputLabel id="sf-schedulerintakeform-from-schedulerintakeform-simple-select-outlined-label" className="sf-schedulerintakeform-from-schedulerintakeform">{getLocalizedStrings().label.IMPORT.WORKFLOW}</InputLabel>
                                    <Select
                                        value={changeLog.selectedWorkflow}
                                        onChange={(e) => changeWorkflow(e.target.value)}
                                        label={getLocalizedStrings().label.IMPORT.WORKFLOW}
                                        style={{ height: '40px' }}
                                        className={"sf-fields-bg"}>
                                        {generateWorkflowList()}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-sm-4">
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <InputLabel id="sf-scheduler-intakeform-from-schedulerintakeform-simple-select-outlined-label" className="sf-scheduler-intakeform-from-schedulerintakeform">{getLocalizedStrings().label.IMPORT.STATUS}</InputLabel>
                                    <Select
                                        value={defaultSelectedStatus}
                                        onChange={(e) => changeStatus(e.target.value)}
                                        label={getLocalizedStrings().label.IMPORT.STATUS}
                                        style={{ height: '40px' }}
                                        className={"sf-fields-bg"}>
                                        {generateStatusList()}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>}
                <div className="row" style={{ paddingTop: '15px' }}>
                    <div className="col-sm-8">
                        <div id="fieldheader" style={{ paddingLeft: '0px', width: '100%', fontSize: '17px', fontWeight: 'bold', paddingBottom: '12px' }}>{getLocalizedStrings().label.SCHEDULER.DEFINE_YOUR_THANK_YOU_MESSAGE}</div>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">

                            <TextField
                                variant="outlined"
                                // key={field.id}
                                name='thankyoumsg'
                                type='textarea'
                                multiline={false}
                                label={getLocalizedStrings().label.SCHEDULER.THANK_YOU_MESSAGE}
                                textareastyle={{ marginTop: '13px', height: '120px', resize: 'none' }}
                                disabled={false}
                                onChange={(e) => changeTextFieldValue(e)}
                                inputprops={{ maxLength: 32 }}
                                value={thankYouMsg}
                                autoComplete="new-password"
                                className={"sf-fields-bg"}
                                margin='dense'
                                size='small'
                            /></FormControl>
                    </div>
                </div>
                <div className="row" style={{ paddingTop: '20px',float: 'right', width: '41%' }}>
                    <div style={{  paddingBottom: '30px' }}>
                        <Button
                            id="btn"
                            primary={true}
                            onClick={() => saveFieldsInfo()}
                            style={styles.primaryButton}
                            disabled={isSavedClicked}
                        >  {getLocalizedStrings().label.COMMON.SAVE}</Button>
                    </div>
                </div>
                <div>


                </div>
            </div>
        )
    }
    else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }

}
export default CompanySchedulerFields;