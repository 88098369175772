import React, { useState, useEffect } from 'react';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { styles } from '../../../services/constants/styles';
import { isValidParam, getArrayParam, isUrl, getStringParam, getObjectParam, getIntParam } from '../../../services/helper/parameterVerifier';
import ShowCircularProgress from '../components/circularProgress';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { useDispatch } from "react-redux";


const hubListItem = [
    { id: 0, name: "useful_links", label: getLocalizedStrings().label.HUB_SETUP.USEFUL_LINK, isLinkField: false },
    { id: 1, name: "cases", label: getLocalizedStrings().label.HUB_SETUP.CASES, isLinkField: false },
    { id: 2, name: "news", label: getLocalizedStrings().label.HUB_SETUP.NEWS, isLinkField: false },
    { id: 3, name: "library", label: getLocalizedStrings().label.HUB_SETUP.LIBRARY, isLinkField: false },
    { id: 4, name: "community", label: getLocalizedStrings().label.HUB_SETUP.COMMUNITY, isLinkField: true },
    { id: 5, name: "training", label: getLocalizedStrings().label.HUB_SETUP.TRAINING, isLinkField: true }
    //{ id: 6, name: "manual", label: getLocalizedStrings().label.HUB_SETUP.MANNUAL, isLinkField: true }
]

const HUBSettings = () => {

    const dispatch = useDispatch();

    const [mounted,setMounted] = useState(true);
    const [items, setItems] = useState({
        id: 0,
        hub_name: 'Hub',
        useful_links: 0,
        cases: 0,
        news: 0,
        library: 0,
        community: 0,
        training: 0,
        manual: 0,
        community_link: '',
        training_link: '',
        manual_link: '',
    });


    useEffect(() => {
        getHubSetUp();
    }, []);


    const getHubSetUp = () => {
        try {
            let url = endPoints.HUB_SETUP.GET;
            let objectList = {};
            let param = {}
            let promise = Promise.resolve(HTTPClient.get(url, param));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let arrObject = getArrayParam(response.data);
                    if (arrObject.length > 0) {
                        objectList = arrObject[0];
                        objectList.id = objectList.t_pk;
                        setItems(objectList);
                    }
                }
            });
        } catch (error) {
            console.error("Error in hubsettings.js -> 'setHubSetUp()'" + error);
        }
    }

    const handleChange = (itemName, event) => {
            let value = getStringParam(event.target.value);
            if (itemName === 'community_link' || itemName === 'training_link' || itemName === 'manual_link') {
                value = value.replace(/\s/g, '');
            }
            // setItems({ ...items, itemName: value });
            let tempItems = items;
            tempItems[itemName] = value;
            setItems({...tempItems});
    }

    const handleCheckChanges = (itemName, event) => {
        const tempItems = { ...items }
        try {
            if (itemName === 'community' || itemName === 'training' || itemName === 'manual') {
                tempItems[itemName + "_link"] = '';
            }
            tempItems[itemName] = event.target.checked ? 1 : 0;
            setItems(tempItems);
        } catch (error) {
            console.error("Error in hubsettings.js -> 'handleCheckChanges()'" + error);
        }
    }
    const restrictKey = (event) => {
        try {
            if (event.key === ' ') {
                event.preventDefault();
                return false;
            } else {
                return true;
            }
        } catch (error) {
            console.error("Error in 'hubsettings.js ->  restrictKey()' " + error);
        }
    }
    const saveHubSetUp = () => {
        try {
            let url = endPoints.HUB_SETUP.SAVE;
            let params = getObjectParam(items);
            let promise = Promise.resolve(HTTPClient.post(url, params));
            promise.then((response) => {
                if (isValidParam(response) && response.status === 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    getHubSetUp();
                }
            })
        } catch (error) {
            console.error("Error in hubsettings.js -> 'saveHubSetUp()'" + error);
        }
    }

    const validateData = () => {
        try {
            let hubName = getStringParam(items['hub_name']).trim();
            let community = getIntParam(items['community']);
            let training = getIntParam(items['training']);
            let manual = getIntParam(items['manual']);
            let trainingLink = getStringParam(items['training_link']).trim();
            let communityLink = getStringParam(items['community_link']).trim();
            let manualLink = getStringParam(items['manual_link']).trim();
            let errorMessage = "";
            const letters = /^[A-Za-z0-9_ ]+$/;

            if (!String(hubName).match(letters)) {
                errorMessage = getLocalizedStrings().message.HUB_SETUP.INVALID_HUB_NAME;
            }
             else if (community === 1 && !isValidUrl(communityLink)) {
                errorMessage = getLocalizedStrings().message.HUB_SETUP.VALID_URL + " " + getLocalizedStrings().message.HUB_SETUP.COMMUNITY;
            } 
            else if (training === 1 && !isValidUrl(trainingLink)) {
                errorMessage = getLocalizedStrings().message.HUB_SETUP.VALID_URL + " " + getLocalizedStrings().message.HUB_SETUP.TRAINING;
            } else if (manual === 1 && !isValidUrl(manualLink)) {
                errorMessage = getLocalizedStrings().message.HUB_SETUP.VALID_URL + " " + getLocalizedStrings().message.HUB_SETUP.MANUAL;
            }
            if (errorMessage !== "") {
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            } else {
                saveHubSetUp();
            }
        } catch (error) {
            console.error("Error in hubsettings.js ---> 'validateData()'" + error);
        }
    }
    const isValidUrl = (url) => {
        let isValid = true;
        if (!isUrl(url) || (isUrl(url) && (url.startsWith("(") || url.endsWith(")")))
            || (isUrl(url) && (url.startsWith("{") || url.endsWith("}")))
            || (isUrl(url) && (url.startsWith("[") || url.endsWith("]")))) {
            isValid = false;
        }
        return isValid;
    }

    const getHubCheckListItem = () => {
        let elements = [];
        try {
            hubListItem.forEach((hubItem) => {
                let isChecked = items[hubItem.name] === 1;
                let panelSize = hubItem.isLinkField ? 3 : 12;
                elements.push(
                    <div className="container" style={{ width: '100%' }}>
                        <div className="row">
                            <div className={'col-sm-' + panelSize}>
                            <FormControlLabel
                                            control={<Checkbox
                                                color="default"
                                                checked={isValidParam(items[hubItem.name]) ? items[hubItem.name] : false}
                                                value={isValidParam(items[hubItem.name]) ? items[hubItem.name] : false}
                                                onChange={(e) => handleCheckChanges(hubItem.name, e)}
                                                />}    
                                                label={hubItem.label}
                                                labelposition="right"
                                                style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginBottom: '-2px',opacity: '0.8' }}
                                 />
                              
                            </div>
                            {hubItem.isLinkField &&
                                <div className='col-sm-9'>
                                <FormControl style={{ width: '100%'}} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        id={hubItem.name}
                                        label={getLocalizedStrings().label.HUB_SETUP.SET + ' ' + getStringParam(hubItem.label).toLowerCase() + ' ' + getLocalizedStrings().label.HUB_SETUP.HYPER_LINK}
                                        fullWidth={true}
                                        style={{ marginBottom: "12px" }}
                                        onChange={(e) => handleChange(hubItem.name + "_link", e)}
                                        value={isValidParam(items[hubItem.name + "_link"]) ? items[hubItem.name + "_link"] : ""}
                                        disabled={!isChecked}
                                        onKeyPress={restrictKey}
                                        inputprops={{ maxLength: '256' }}
                                        autoComplete="new-password"
                                        className={"sf-fields-bg"}
                                        margin='dense'
                                        size='small'
                                    />
                                </FormControl>
                                </div>
                            }
                        </div>
                    </div>
                )
            });
        } catch (error) {
            console.error("Error in hubsettings.js -> 'getHubCheckListItem()'" + error);
        }
        return elements;
    }

    const getHubSettingsFields = () => {
        try {
            return (
                <div style={{ paddingBottom: '10px' }}>
                    
                      <FormControl style={{width:'100%' ,opacity:'0.8' }} className="test" noValidate autoComplete="off">
                      
                    <TextField
                        variant="outlined"
                        id={"hub-settings-textField"}
                        disabled={false}
                        label={getLocalizedStrings().label.HUB_SETUP.SETUP_FIELD_NAME}
                        fullWidth={true}
                        onChange={(e) => handleChange('hub_name', e)}
                        value={isValidParam(items) ? items['hub_name'] : ''}
                        inputprops={{maxLength:'128'}}
                        autoComplete="new-password"
                        className={"sf-fields-bg"}
                        margin='dense'
                        size='small'
                    />
                    </FormControl>
                  
                    <div>
                        {getHubCheckListItem()}
                    </div>
                </div>
            );
        } catch (error) {
            console.error("Error in hubsettings.js -> 'getHubSettingsFields()'" + error);
        }
    }


    let height = window.innerHeight - 150;
    let top = (height-10) / 2;
    if (mounted) {
        return (
            <div style={{ paddingLeft: '20px', paddingRight: '10px', marginTop: '10px', width: '60%' }}>
                <div >
                    <span style={{ display: 'inline-block', color: '#585757', marginLeft: '5px', paddingBottom: '10px', fontWeight: 'bold', fontSize: '14px' }} >{getLocalizedStrings().label.HUB_SETUP.SETUP_NAME}</span>
                    {getHubSettingsFields()}
                    <div style={{ float: 'right', paddingTop: '10px' ,marginRight:'15px'}}>
                        <Button
                            key='save'
                            onClick={() => validateData()}
                            style={{ ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top' }}
                        >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                    </div>
                </div>
            </div>
        );
    } else {
        return (<div style={{ width: '100%', height: height }} >
        {
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
        }
        </div>);
    }
}


export default HUBSettings;
