import React, { useState, useEffect } from 'react';
import { isValidParam, getStringParam, getIntParam } from '../../../services/helper/parameterVerifier';
import { setHeader } from '../../../services/actions/headerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import * as sfDialogs from './sfDialogs';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { addNewFormTabs } from '../../../services/helper/sfTabManager';
import { useDispatch, useSelector } from "react-redux";
import PopOver from './PopOver';
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';
import { fetchLinkRecords } from '../../../services/actions/listViewAcions';
import { updateRoyaltyReportV2Data } from '../../../services/actions/royaltyReportActions';
import { dateFormatFlatPicker,dateFormat} from '../../../services/helper/utils';


import {
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    TextField,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    tableCellClasses,
} from '@mui/material';
import styled from '@emotion/styled';
import AutocompleteMultiSelect from './AutoCompleteMultiselect';
import ShowCircularProgress from './circularProgress';


const PERIOD_ITEMS = ["Previous", "This month", "This Quarter", "This Year", "Last month", "Last Quarter", "Last Year", "Custom"];

const CustomDateInputStyle = () => {
    return <style> {
        ".form-control[readonly] {background-color: #fff !important;}"
    }</style>
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        border: '1px solid #D3D3D3',
        textAlign: 'left',
        padding: '2px 10px 2px 3px'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
        color: '#000000',
        padding: '3px 10px 3px 3px'

    },
    [`&.${tableCellClasses.footer}`]: {
        backgroundColor: '#F5F5F5',
        color: '#000000',
        fontSize: 14,
      },

  }));


const RoyaltyReportV2 = () => {
    const dispatch = useDispatch();

    const royaltyReport = useSelector((state) => state.royaltyReport.royaltyReportV2Data);
    const appState = useSelector((state) => state.app);
    const isfranchisor = appState.me.is_franchisor;

    const [period, setPeriod] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [units, setUnits] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);
    const [plan, setPlan] = useState(null);
    const [groupBy, setGroupBy] = useState(isfranchisor ?  'Unit' : 'Period');
    const [sortBy, setSortBy] = useState('Unit Name');
    const [planOptions, setPlanOptions] = useState([]);
    const [reportData, setReportData] = useState({});
    const [showReport, setShowReport] = useState(false);
    const [isPlanChange, setIsPlanChange] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let shouldFetchPlans = true;
        if (royaltyReport) {
            if (royaltyReport.hasOwnProperty('availableParams')) {
                const availableParams = royaltyReport.availableParams;
                if (availableParams && availableParams.hasOwnProperty('planOptions')) {
                    shouldFetchPlans = false;
                    setPlanOptions(availableParams.planOptions);
                }
                if (availableParams && availableParams.hasOwnProperty('unitOptions')) {
                    setUnitOptions(availableParams.unitOptions);
                }
            }
            if (royaltyReport.hasOwnProperty('selectedParams')) {
                const selectedParams = royaltyReport.selectedParams;
                if (selectedParams) {
                    if (selectedParams.hasOwnProperty('plan')) {
                        setPlan(selectedParams.plan);
                    }
                    if (selectedParams.hasOwnProperty('period')) {
                        setPeriod(selectedParams.period);
                    }
                    if (selectedParams.hasOwnProperty('startDate')) {
                        setStartDate(selectedParams.startDate);
                    }
                    if (selectedParams.hasOwnProperty('endDate')) {
                        setEndDate(selectedParams.endDate);
                    }
                    if (selectedParams.hasOwnProperty('units')) {
                        setUnits(selectedParams.units);
                    }
                    if (selectedParams.hasOwnProperty('groupBy')) {
                        setGroupBy(selectedParams.groupBy);
                    }
                    if (selectedParams.hasOwnProperty('sortBy')) {
                        setSortBy(selectedParams.sortBy);
                    }
                }
            }

            if (royaltyReport.hasOwnProperty('reportData')) {
                setReportData(royaltyReport.reportData);
                setShowReport(true);
            }
        }
        if (shouldFetchPlans) {
            fetchRoyaltyPlans();
        }
        dispatch(setHeader(constants.ROYALTY_REPORT, getLocalizedStrings().label.MODULE_ITEMS.ROYALTIES_V2, null, false, true));
    }, []);

    function handlePeriodChange(tempPeriod, autoSelectPlanName) {
        if (tempPeriod && tempPeriod !== "") {
            let m = moment();
            let startDate = '';
            let endDate = '';
            const planOption = plan ? plan : autoSelectPlanName
            switch (tempPeriod.toLocaleLowerCase()) {
                case 'custom':                    
                    break;
                case 'previous':
                    if (planOption && planOption.name )
                        fetchLastRoyaltyRun(planOption.name, autoSelectPlanName);
                    break;
                case 'this month':
                    startDate = m.startOf('month').format(dateFormat[appState.me.date_format]);
                    endDate = m.endOf('month').format(dateFormat[appState.me.date_format]);                    
                    break;
                case 'this quarter':
                    startDate = m.startOf('quarter').format(dateFormat[appState.me.date_format]);
                    endDate = m.endOf('quarter').format(dateFormat[appState.me.date_format]);                    
                    break;
                case 'this year':
                    startDate = m.startOf('year').format(dateFormat[appState.me.date_format]);
                    endDate = m.endOf('year').format(dateFormat[appState.me.date_format]);                    
                    break;
                case 'last month':
                    m.subtract(1, 'months');
                    startDate = m.startOf('month').format(dateFormat[appState.me.date_format]);
                    endDate = m.endOf('month').format(dateFormat[appState.me.date_format]);                    
                    break;

                case 'last year':
                    m.subtract(1, 'years');                    
                    startDate = m.startOf('year').format(dateFormat[appState.me.date_format]);
                    endDate = m.endOf('year').format(dateFormat[appState.me.date_format]);                    
                    break;

                case 'last quarter':
                    m.subtract(1, 'quarters');
                    startDate = m.startOf('quarter').format(dateFormat[appState.me.date_format]);
                    endDate = m.endOf('quarter').format(dateFormat[appState.me.date_format]);                    
                    break;

                default: break;
            }
            if(tempPeriod.toLocaleLowerCase()!='previous'){
                setStartDate(startDate);
                setEndDate(endDate);
            }
        }
        setPeriod(tempPeriod);
    };

    function fetchRoyaltyPlans() {
        const promise = Promise.resolve(HTTPClient.get(endPoints.ROYALTY_PLANS.GET_PLANS, {}));
        if (promise) {
            promise.then(response => {
                if (response && response.length > 0) {
                    setPlanOptions(response);
                    getUnitPlanOptions(response)
                    setPlan(response[0]);
                    handlePeriodChange("This month", response[0])
                }
            });
        }
    }

    const getUnitPlanOptions = (option) => {
        let tempPlanName = option[0].name;
        const params = {
            planName: tempPlanName
        };
        const promise = HTTPClient.get(endPoints.ROYALTY_REPORT_V2.GET_UNITS, params);
        if (promise) {
            promise.then(response => {
                if (response) {
                    let tempUnits = [];
                    for (let key of Object.keys(response)) {
                        tempUnits.push({
                            id: key,
                            label: response[key]
                        });
                    }
                    setUnits(tempUnits)
                    let data = {
                        availableParams: { ...royaltyReport.availableParams, unitOptions: tempUnits },
                    };
                    setUnitOptions(tempUnits);
                    dispatch(updateRoyaltyReportV2Data(data));
                }
            });
        }
    }

    function handlePlanChange(e, option) {
        if (option) {
            let tempPlanName = option.name;
            const params = {
                planName: tempPlanName
            };
            const promise = HTTPClient.get(endPoints.ROYALTY_REPORT_V2.GET_UNITS, params);
            if (promise) {
                promise.then(response => {
                    if (response) {
                        let tempUnits = [];
                        for (let key of Object.keys(response)) {
                            tempUnits.push({
                                id: key,
                                label: response[key]
                            });
                        }
                        let data = {
                            availableParams: { ...royaltyReport.availableParams, unitOptions: tempUnits },
                        };
                        setIsPlanChange(true)
                        setUnitOptions(tempUnits);
                        dispatch(updateRoyaltyReportV2Data(data));
                    }
                });
            }
            if (period && period.toLocaleLowerCase() === 'previous') {
                fetchLastRoyaltyRun(tempPlanName);
            }
        }
        if(isfranchisor) {
            setUnits([]);
        }
        setPlan(option);
    }

    function getReportData() {
        const params = {};
        let valid = true;
        if (plan && plan.name && plan.name.trim() !== "") {
            params.planName = plan.name;
        } else {
            valid = false
        }
        if (startDate) {
            params.startDate = moment(startDate).format("YYYY-MM-DD");
        } else {
            valid = false
        }
        if (endDate) {
            params.endDate = moment(endDate).format("YYYY-MM-DD");
        } else {
            valid = false
        }
        if (units && units.length > 0) {
            params.units = units.map(u => u.label);
        } else {
            valid = false
        }
        if (groupBy && groupBy.trim() !== "") {
            params.groupBy = groupBy;
        } else {
            valid = false
        }
        if (sortBy && sortBy.trim() !== "") {
            params.sortBy = sortBy;
        } else {
            valid = false
        }

        if (!valid) {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_REPORT_V2.ALL_OPTIONS_REQUIRED, null);
            return;
        }
        setIsLoading(true);
        const promise = HTTPClient.get(endPoints.ROYALTY_REPORT_V2.GET, params);
        if (promise) {
            promise.then(res => {
                const headerPrefix = groupBy === 'Period'
                setReportData(res);
                setShowReport(true);
                let data = {
                    availableParams: { planOptions, unitOptions },
                    selectedParams: { plan, startDate, endDate, units, groupBy, sortBy, period },
                    reportData: res,
                    headerPrefix: headerPrefix
                };
                dispatch(updateRoyaltyReportV2Data(data));
                setIsLoading(false);
            })
        }
    }



    function fetchLastRoyaltyRun(planName, autoSelectPlanName) {
        const params = {};
        if (planName && planName.trim() !== "") {
            params.planName = planName;
            const promise = HTTPClient.get(endPoints.ROYALTY_REPORT_V2.LAST_ROYALTY_RUN, params);
            if (promise) {
                promise.then(res => {
                    if (res && res.startDate && res.endDate) {
                        let m = moment(res.startDate);
                        setStartDate(m.format(dateFormat[appState.me.date_format]));
                        m = moment(res.endDate);
                        setEndDate(m.format(dateFormat[appState.me.date_format]));
                    } else {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_REPORT_V2.NO_LAST_RUN_RECORD_FOUND, null);
                        setStartDate('');
                        setEndDate('');
                    }
                })
            }
        }
    }

    const generateHeaders = (column) => {
        let cells = [];
        cells = column.map((columnObject, index) => {
            if(!columnObject.is_hidden) {
                return (
                    <StyledTableCell key={index}> 
                        <span style={{fontSize: '15px'}}>
                            {columnObject.label}
                        </span>
                    </StyledTableCell>
                );
            }
            });
        return (
            <TableRow> 
                {cells} 
            </TableRow>
        );
    }

    const generateRows = (columns, rows) => {
        return rows.map((rowObject, rowIndex) => {
            let cells = columns.map((columnObject, colIndex) => {
                if(!columnObject.is_hidden) {
                    return (
                        <StyledTableCell key={rowIndex + ',' + colIndex}> 
                            <div onClick={columnObject.is_detail_link ? () => openQuotationTab (rowObject) : null} style={{float: columnObject.is_currency || columnObject.name === 'quoteno' ? 'right' : 'left', cursor: columnObject.is_detail_link ? 'pointer' : 'default'}}>
                                <span style={{fontWeight: rowObject.isTextBold ? 'bold' : '400', color: columnObject.is_detail_link ? '#337ab7' : '#000000', fontSize: '15px'}}>{rowObject[columnObject.name]}</span>
                            </div>
                        </StyledTableCell>
                    )
                }
            })
            return (
                <TableRow> 
                    {cells} 
                </TableRow>
            );
        })
    }

    function getReportView() {
        let view = [];
        let i = 1;
        let isFields = true;
        if ((!reportData.records || Object.keys(reportData.records).length === 0) && Object.keys(reportData.fields).length === 0) {
            isFields = false;
            return getNoRoyaltyReportFieldSetup();
        }
        if (isFields && (!reportData.records || Object.keys(reportData.records).length === 0)) {
            return getNoRecordContent();
        }
        for (let key in reportData.records) {
            let records = reportData.records[key];
            let headerComponents = generateHeaders(reportData.fields);
            let rowComponents = generateRows(reportData.fields, records);
            view.push(
                <div style={{...styles.sf_12, display: 'flex', flexDirection: 'column'}}>
                    <span style={{fontWeight: 'bold', fontSize: '16px', marginBlock: '20px'}}>{royaltyReport.headerPrefix ? 'PERIOD ' + i : ""} {key}</span>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                {headerComponents}
                            </TableHead>
                            <TableBody>
                                {rowComponents}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

            )
            i++;
        }
        return view;
    }

    const openQuotationTab = (rowdata) => {
        try {
            let recordId = rowdata.id;
            let royaltyArr = [];
            royaltyArr.push(getIntParam(recordId));
            let objectId = getIntParam(OBJECT_TABLEID_MAP[constants.REPORTED_SALES_OBJECT]);
            let params = {};
            params.parent_object_id = 280;
            params.child_record_id = getIntParam(recordId);
            params.object_id = objectId;
            let promise = Promise.resolve(fetchLinkRecords(params));
            promise.then((response) => {
                if (isValidParam(response) && getIntParam(response.record_id) > 0) {
                    let quoteId = response.record_id;
                    let info = { id: quoteId, title: "Edit Quotes & Invoices", type: "Edit", object: "quotations", royalty_record_id: royaltyArr, callFor: constants.ROYALTY_OBJECT }
                    addNewFormTabs(info);
                }
            });
        } catch (error) {
            console.error("Error in 'ListViewHeader.js ---> pushToQuickBooks() '" + error);
        }
    }
    function handleFlatpkrDateChange(date, field) {
        try {
            if (isValidParam(date)) {
                let selectedDate = new Date(date);
                let dateValue = new Date(
                    selectedDate.getFullYear(),
                    selectedDate.getMonth(),
                    selectedDate.getDate()
                );
                const momentDate = moment(dateValue);
                if (field === 'start_date') {
                    setStartDate(momentDate.format(dateFormat[appState.me.date_format]));
                }
                if (field === 'end_date') {
                    setEndDate(momentDate.format(dateFormat[appState.me.date_format]));
                }
            } else {
                if (field === 'start_date') {
                    setStartDate("");
                }
                if (field === 'end_date') {
                    setEndDate("");
                }
            }
        } catch (error) {
            console.error(
                "Error in 'RoyaltyReportV2.js -> handleFlatpkrDateChange()':" + error
            );
        }
    }

    function replaceCurrencySymbols(htmldata) {
        htmldata = htmldata.replace(/€/gi, '&#x20AC;');
        htmldata = htmldata.replace(/￦/gi, '&#x20A9;');
        htmldata = htmldata.replace(/£/gi, '&#xa3;');
        htmldata = htmldata.replace(/₺/gi, '&#x20BA;');
        htmldata = htmldata.replace(/￥/gi, '&#xa5;');
        htmldata = htmldata.replace(/руб./gi, '&#x440;' + '&#x443;' + '&#x0431;' + ".");
        return htmldata;
    }

    function downloadExcel() {
        let columns = reportData.fields.filter(f => !f.is_hidden);
        let columnsLength = columns.length;
        if (Object.keys(reportData.records).length > 0) {
            let beforeHeaderComponent = excelGenerateBeforeHeaders(columnsLength);
            let htmlContent = '<table border="1">' + beforeHeaderComponent;
            let i = 1;
            for (let key in reportData.records) {
                const label = ((groupBy && groupBy.toLocaleLowerCase() === 'unit') ? 'Unit - ' : 'PERIOD ' + i + ' ') + key;
                htmlContent += '<tr>' + getBlankTDs(columnsLength) + '</tr><tr><td  colspan="' + (columnsLength) + '"> ' + label + ' </td></tr>'
                let headerComponents = excelGenerateHeaders(columns);
                let rowComponent = excelGenerateRows(columns, reportData.records[key]);
                htmlContent += '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponent + '</tbody>';
                i++;
            }
            htmlContent += '</table>';
            let htmlData = htmlContent;
            if (isValidParam(htmlData) && htmlData !== '') {
                htmlData = htmlData.replace("%22", "\"");
                htmlData = replaceCurrencySymbols(htmlData);
                var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
                var downloadUrl = URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = downloadUrl;
                a.download = 'royaltyResultSet_' + new Date().getTime() + '.xls';
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        } else {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.NO_RECORD_EXPORT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
    }

    function getBlankTDs(num) {
        let p = '';
        while (num > 0) {
            p += '<td></td>';
            num--;
        }
        return p;
    }

    function excelGenerateBeforeHeaders(columnsLength) {
        let cells = '';
        let reportName = getStringParam(getLocalizedStrings().label.ROYALTY_REPORT.ROYALTY_REPORT_LABEL);

        cells = cells + '<tr><td  colspan="' + (columnsLength) + '"> ' + reportName + ' </td></tr>';
        if (reportName.length > 0) {
            const date = moment().format("DD/MM/YYYY");
            cells = cells + '<tr><td>' + getLocalizedStrings().label.CALENDAR.DATE + ':</td>  <td>' + date + ' </td>' + getBlankTDs(columnsLength - 2) + '</tr>';
        }
        return cells;
    }

    function excelGenerateHeaders(columns) {
        let cells = '';
        columns.map(f => {
            if (f.is_currency) {
                cells = cells + '<th bgcolor="6A8CCB" style="text-align: right"> ' + f.label + ' </th>';
            } else {
                cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
            }
            
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    function excelGenerateRows(columns, rows) {
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            let colVal = '';
            rows.map((rowObject, rowIndex) => {
                let innerCells = '';
                columns.map((columnObject, colIndex) => {
                    colVal = isValidParam(rowObject[columnObject.name]) ? rowObject[columnObject.name] : '';
                    let align = 'left'
                    if (columnObject.is_currency) {
                        align = "right"
                    }

                    // if (columnObject.name === "quoteno" && rowIndex !== (rows.length - 1) && colVal && colVal != "") {
                    //     colVal = "Invoice No: " + colVal.toString();
                    // } else {
                        // colVal = colVal.toString();
                    // }
                    colVal = colVal.toString();

                    if (rowIndex === (rows.length - 1)) {
                        innerCells = innerCells + '<td  bgcolor="gray" style="text-align:' + align +'"><b>' + colVal + '</b></td>';
                    } else {
                        if (rowIndex % 2 === 0) {
                            innerCells = innerCells + '<td style="text-align:' + align +'">' + colVal + ' </td>';
                        } else {
                            innerCells = innerCells + '<td bgcolor="Lavender" style="text-align:' + align +'">' + colVal + ' </td>';
                        }
                    }
                })
                if (rowIndex % 2 === 0) {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                } else {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                }

            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
        }
        return rowData;

    }

    function getNoRecordContent() {
        return (
            <div className="row" style={{ padding: '0px', display: "flex", marginLeft: '-14px' }}>
                <h5 style={{ color: 'red' }}>{getLocalizedStrings().message.ROYALTY_REPORT_V2.NO_RECORDS}</h5>
            </div>
        )
    }

    function getNoRoyaltyReportFieldSetup() {
        return (
            <div className="row" style={{ padding: '0px', display: "flex", marginLeft: '-14px' }}>
                <h5 style={{ color: 'red' }}>{isfranchisor ? getLocalizedStrings().message.ROYALTY_REPORT_V2.NO_ROYALTY_FIELD_SETUP_DONE : getLocalizedStrings().message.ROYALTY_REPORT_V2.NO_FE_ROYALTY_FIELD_SETUP_DONE}</h5>
            </div>
        )
    }

    const handleGOAction = () => {
        return(
            <div className="col-md-1" style={{ paddingLeft: '0px', display: 'flex', width: '15%', height: '47px', marginLeft: '-6px', alignItems: 'center' }}>
                        <Button
                            onClick={() => getReportData()}
                            style={{
                                ...styles.primaryButton,
                                minWidth: '56px',
                                width: '19%'
                            }}
                            title={getLocalizedStrings().label.REPORT.GO}
                        > {getLocalizedStrings().label.REPORT.GO}</Button>
                        {
                            showReport && (
                                <div style={{ width: '10%', marginRight: '25%' }}>
                                    <i onClick={() => { downloadExcel(); }} className="far fa-file-excel" title="Excel" style={{ fontSize: '20px', color: 'green', cursor: 'pointer', paddingTop: '3px' }} />
                                </div>
                            )
                        }
                    </div> 
        )
    }

    function getReportFilter() {
        let datePickerOptions = {
            enableTime: false,
            dateFormat: dateFormatFlatPicker[appState.me.date_format],
            minuteIncrement: 1,
            static: true
        }
        let div = (
            <>
                <div className="row" style={{ padding: '0px', display: "flex", marginLeft: '-14px', marginTop: '5px' }}>
                    <CustomDateInputStyle />
                    <iframe id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute', display: 'none' }}></iframe>
                    <div className="col-md-4" style={{ paddingLeft: '0px' }} >
                        <Autocomplete
                            id={`field-ref-plan-name`}
                            name={`select-field-plan-name`}
                            key={`select-field-plan-name`}
                            className={'sf-atc-autoComplete royalty-reportv2'}
                            defaultValue={plan}
                            options={planOptions}
                            value={plan}
                            onChange={(e, option) => handlePlanChange(e, option)}
                            getOptionLabel={(option) => option.name || ''}
                            disableClearable={false}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={getLocalizedStrings().label.ROYALTY_REPORT_V2.PLAN_NAME}
                                    sx={{ '.MuiAutocomplete-inputRoot': { padding: '9px' },
                                            '.MuiFormLabel-root[data-shrink=false]': { top: '4px'} 
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    size='small'
                                />
                            )}
                        />
                    </div>
                    <div className="col-md-2" style={{ paddingLeft: '0px' }}>
                        <FormControl variant="outlined" style={{ width: '100%' }} className='rrfc-select'>
                            <InputLabel id="sf-royaltyreport-simple-select-timeline-outlined-label" className="sf-royaltyreport">{getLocalizedStrings().label.ROYALTY_REPORT_V2.PERIOD}</InputLabel>
                            <Select
                                key="timeline"
                                name="timeline"
                                labelId='timeline-label'
                                id='noteTypeList'
                                value={period}
                                size="small"
                                className={"sf-select-height"}
                                label={getLocalizedStrings().label.ROYALTY_REPORT_V2.PERIOD}
                                onChange={(event) => handlePeriodChange(event.target.value)}
                            >
                                {
                                    PERIOD_ITEMS.map(item => <MenuItem key={item} value={item} style={styles.popoverMenuItem} >{item}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-2" style={{ paddingLeft: '0px' }} >
                        <div
                            style={{ margin: "0", width: "100%" }}
                            className={"sf-form-field dateTimeDiv-start_date"}
                            id="dateholder"
                        >
                            <PopOver
                                id={'field-ref-start_date'}
                                key={"start_date"}
                                name={"start_date"}
                                btnType={"date"}
                                buttonStyle={{ fontSize: "16px" }}
                                containerStyle={{ lineHeight: 1 }}
                                buttonLabel={getLocalizedStrings().label.ROYALTY_REPORT_V2.START_DATE}
                                onChange={(date) => handleFlatpkrDateChange(date, "start_date")}
                                innerLabelStyle={{
                                    padding: "14 5 2 5",
                                    fontSize: "15px",
                                }}
                                value={startDate}
                                height={47}
                                options={datePickerOptions}
                                disable={!period || period === '' || period.toLocaleLowerCase() !== 'custom'}
                            />
                        </div>
                    </div>
                    <div className="col-md-2" style={{ paddingLeft: '0px' }} >
                        <div
                            style={{ margin: "0", width: "100%" }}
                            className={"sf-form-field dateTimeDiv-end_date"}
                            id="dateholder"
                        >
                            <PopOver
                                id={'field-ref-end_date'}
                                key={"end_date"}
                                name={"end_date"}
                                btnType={"date"}
                                buttonStyle={{ fontSize: "16px" }}
                                containerStyle={{ lineHeight: 1 }}
                                buttonLabel={getLocalizedStrings().label.ROYALTY_REPORT_V2.END_DATE}
                                onChange={(date) => handleFlatpkrDateChange(date, "end_date")}
                                innerLabelStyle={{
                                    padding: "14 5 2 5",
                                    fontSize: "15px",
                                }}
                                height={47}
                                options={datePickerOptions}
                                value={endDate}
                                disable={!period || period === '' || period.toLocaleLowerCase() !== 'custom'}
                            />
                        </div>
                    </div>
                   {!isfranchisor && handleGOAction()}
                </div>
               {isfranchisor && <div className="row" style={{ padding: '1rem 0 0', display: "flex", marginLeft: '-14px' }}>
                        <div className="col-md-6" style={{ paddingLeft: '0px' }} >
                            <AutocompleteMultiSelect
                                value={units}
                                items={royaltyReport?.availableParams?.unitOptions}
                                // getOptionDisabled={getOptionDisabled}
                                placeholderText={getLocalizedStrings().label.ROYALTY_REPORT_V2.UNIT}
                                selectAllLabel='All'
                                onChange={setUnits}
                                isPlanChange={isPlanChange}
                                setIsPlanChange={setIsPlanChange}
                            />
                        </div>

                        <div className="col-md-2" style={{ paddingLeft: '0px' }}>
                            <FormControl variant="outlined" style={{ width: '100%' }} className='rrfc-select'>
                                <InputLabel id="sf-royaltyreport-simple-select-timeline-outlined-label" className="sf-royaltyreport">{getLocalizedStrings().label.ROYALTY_REPORT_V2.GROUP_BY}</InputLabel>
                                <Select
                                    key="group-by"
                                    name="group-by"
                                    labelId='group-by-label'
                                    id='groupByList'
                                    value={groupBy}
                                    size="small"
                                    className={"sf-select-height"}
                                    label={getLocalizedStrings().label.ROYALTY_REPORT_V2.GROUP_BY}
                                    onChange={(event) => setGroupBy(event.target.value)}
                                >
                                    <MenuItem key="unit" value="Unit" style={styles.popoverMenuItem} >Unit</MenuItem>
                                    <MenuItem key="period" value="Period" style={styles.popoverMenuItem} >Period</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    {/* <div className="col-md-2" style={{ paddingLeft: '0px' }}>
                        <FormControl variant="outlined" style={{ width: '100%' }} className='rrfc-select'>
                            <InputLabel id="sf-royaltyreport-simple-select-timeline-outlined-label" className="sf-royaltyreport">{getLocalizedStrings().label.ROYALTY_REPORT_V2.SORT_BY}</InputLabel>
                            <Select
                                key="sort-by"
                                name="sort-by"
                                labelId='sort-by-label'
                                id='sortByList'
                                value={sortBy}
                                size="small"
                                className={"sf-select-height"}
                                label={getLocalizedStrings().label.ROYALTY_REPORT_V2.SORT_BY}
                                onChange={(event) => setSortBy(event.target.value)}
                            >
                                <MenuItem key="unit_name" value="Unit Name" style={styles.popoverMenuItem} >Unit Name</MenuItem>
                            </Select>
                        </FormControl>
                    </div> */}
                    {handleGOAction()}
                </div> }
            </>
        )
        return div;
    }

    let tempStyle = { marginLeft: 42, width: '96%', paddingTop: 0 };
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 3
    return (
        <div style={tempStyle}>
            {getReportFilter()}
            <div style={{ marginBottom: '1.5rem' }}>
                {
                    showReport && (
                       !isLoading ? getReportView() : ( 
                            <div style={{ width: '100%', height: contentHeight }}>
                                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }
            </div>
        </div>
    );
}
export default RoyaltyReportV2;
