
import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Select from 'react-select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox'; 
import map from 'lodash/map';

import { constants } from '../../../services/constants/constants';
import { styles } from '../../../services/constants/styles';
import { getUserList } from '../../../services/actions/UserListAction';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { addNode } from '../../../services/actions/automationCampaignActions';
import { removeError, getParentDecision } from '../../../services/helper/automationUtils';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import {useDispatch, useSelector} from 'react-redux';

const AutomationNotify = ({data,closeDrawer}) => {
    const dispatch = useDispatch();
    const automationCampaign = useSelector((state) => state.automationCampaign);
    const userlist = useSelector((state) => state.userlist);
    const [isCheckSalsRep,setIsCheckSalsRep] = useState(false);
    const [isCheckUsers,setIsCheckUsers] = useState(false);
    const [users,setUsers] = useState([]);
    const [restofUsers,setRestofUsers] = useState([]);    
    const [isGenerateUserList,setIsGenerateUserList] = useState(true);
    const [isCheckCampSender,setIsCheckCampSender] = useState(false);
    const [isShowCampSender,setIsShowCampSender] = useState(false);
    const [parentActionName,setparentActionName] = useState(null);
    const [parentId,setParentId] = useState(0);


    useEffect(() => {
        getUserList();
        let nodeObject = data;
        let _isShowCampSender = isShowCampSender;
        if (isValidParam(nodeObject)) {
            let _parentId = parentId;
            if (nodeObject.hasOwnProperty('parentId')) {
                _parentId = nodeObject.parentId;
            }
            let actionName = null;
            let detailsArray = nodeObject.details;
            if (isValidParam(detailsArray)) {
                let selectedUsers = users;
                let _isCheckSalsRep = isCheckSalsRep;
                let _isCheckCampSender = isCheckCampSender;
                let _isCheckUsers = isCheckUsers;
                if (nodeObject.hasOwnProperty("parentId") && nodeObject.hasOwnProperty("childId")) {
                    if (nodeObject.parentId > 0) {
                        let parentnodeDetails = getParentDecision(automationCampaign.data.nodes, nodeObject.childId);
                        if (parentnodeDetails.hasOwnProperty("actionName")) {
                            actionName = parentnodeDetails.actionName;
                            if (actionName === constants.AUTOMATION_DESIGNER_WEB_FORM_SUBMIT || actionName === constants.AUTOMATION_DESIGNER_WEB_FORM || actionName === constants.AUTOMATION_DESIGNER_WEBSITE_VISIT
                                 || actionName === constants.AUTOMATION_DESIGNER_DECISION_LANDING_PAGE_SUBMITTED || actionName === constants.AUTOMATION_DESIGNER_LANDING_PAGE ) {
                                _isShowCampSender = false;
                            } else {
                                _isShowCampSender = true;
                            }
                        }
                    }
                }
                if (detailsArray.length > 0) {
                    let isHideSelectedUsers = false;
                    selectedUsers = detailsArray.map((detailObject, i) => {
                        if (detailObject.qualifier_1 === "notify_salesrep") {
                            _isCheckSalsRep = true;
                        } else if (detailObject.qualifier_1 === 'notify_both') {
                            _isCheckSalsRep = true;
                            _isCheckCampSender = true;
                            if (_parentId <= 0) {
                                _isShowCampSender = false;
                            } else {
                                _isShowCampSender = true;
                            }
                        } else if (detailObject.qualifier_1 === 'notify_sender') {
                            _isCheckCampSender = true;
                            if (_parentId <= 0) {
                                _isShowCampSender = false;
                            } else {
                                _isShowCampSender = true;
                            }
                        } else {
                            if (detailObject.object_indentifierText !== null && detailObject.object_indentifierText !== undefined &&
                                detailObject.object_indentifierText !== "") {
                                _isCheckUsers = true;
                                return {
                                    value: detailObject.object_indentifier,
                                    label: detailObject.object_indentifierText,
                                    title: detailObject.object_indentifierText,
                                }
                            }
                        }
                    });
                }
                
                setUsers(selectedUsers);
                setIsCheckSalsRep(_isCheckSalsRep);
                setIsCheckUsers(_isCheckUsers);
                setIsCheckCampSender(_isCheckCampSender);
                setIsShowCampSender(_isShowCampSender);
                setparentActionName(actionName);
            }
        }
    }, []);

    
    const toggleSalesRep = () => {
        setIsCheckSalsRep(!isCheckSalsRep);
    }

    const toggleUsers = () => {
        setIsCheckUsers(!isCheckUsers);
    }

    const changeUsersList = (options) => {
        if (isValidParam(options) && options.length > 0) {
            options.map((objOption, i) => {
                objOption.title = objOption.label;
            })
        }
        let isCheckUsers = true;
        if (options.length <= 0) {
            isCheckUsers = false;
        }
        setUsers(options);
        setIsCheckUsers(isCheckUsers);        
    }

    const generateUsersList = () => {
        let listOfUsers = userlist.data;
        let usersList = null;
        if (isValidParam(listOfUsers)) {
            usersList = map(listOfUsers, function (objUsers, i) {
                return {
                    value: objUsers.id,
                    label: objUsers.text
                }
            });
        }
        setIsGenerateUserList(false);
        setRestofUsers(usersList);
        
    }

    const saveNotify = () => {
        let nodeObject = data;
        let dialogData = null;
        let canSave = true;
        let csender = null;
        let notifyArr = [];

        if (parentActionName === "" || parentActionName === constants.AUTOMATION_DESIGNER_WEB_FORM_SUBMIT ||
            parentActionName === constants.AUTOMATION_DESIGNER_WEB_FORM ||
            parentActionName === constants.AUTOMATION_DESIGNER_WEBSITE_VISIT) {
            if (!isCheckSalsRep && !isCheckUsers) {
                canSave = false;
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_ONE_RECIPIENT
                }
                openDialog(dialogData);
                return false;
            } else {
                if (isCheckSalsRep) {
                    csender = constants.ACTION_NOTIFY_SALESREP;
                }
            }
        } else {
            if (!isCheckCampSender && !isCheckSalsRep && !isCheckUsers) {
                canSave = false;
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_ONE_RECIPIENT
                }
                openDialog(dialogData);
                return false;
            } else {
                if (isCheckCampSender) {
                    csender = constants.ACTION_NOTIFY_SENDER;
                }
                if (isCheckSalsRep) {
                    csender = constants.ACTION_NOTIFY_SALESREP;
                }
                if (isCheckCampSender && isCheckSalsRep) {
                    csender = constants.ACTION_NOTIFY_BOTH;
                }
            }
        }

        if (canSave) {
            if (isCheckCampSender || isCheckSalsRep) {
                let notifyJObject = {};
                notifyJObject.action_type = 'notify';
                notifyJObject.qualifier_1 = csender;
                notifyJObject.object_indentifier = "0";
                notifyArr.push(notifyJObject);
            }

            if (isCheckUsers) {
                if (users.length > 0) {
                    if (parentActionName === constants.AUTOMATION_DESIGNER_WEB_FORM_SUBMIT ||
                        parentActionName === constants.AUTOMATION_DESIGNER_WEB_FORM ||
                        parentActionName === constants.AUTOMATION_DESIGNER_WEBSITE_VISIT) {
                        csender = constants.ACTION_NOTIFY_USERS;
                    } else {
                        csender = constants.ACTION_NOTIFY_USERS;
                    }
                    users.map((userObject, i) => {
                        if (isValidParam(userObject)) {
                            let notifyJObject = {};
                            notifyJObject.action_type = 'notify';
                            notifyJObject.qualifier_1 = csender;
                            notifyJObject.object_indentifier = userObject.value;
                            notifyJObject.object_indentifierText = userObject.label;
                            notifyArr.push(notifyJObject);
                        }
                    });
                } else {
                    dialogData = {
                        message: getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_USER
                    }
                    openDialog(dialogData);
                    return false;
                }
            }
        }
        nodeObject.details = notifyArr;
        nodeObject = removeError(nodeObject);
        dispatch(addNode(automationCampaign.data));
        closeDrawer();
    }

    const openDialog = (dialogData) => {
        dispatch(getAppDialog(true, constants.ALERT_DIALOG, getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, null, dialogData, constants.AUTOMATION_DESIGNER_OBJECT));
    }

    const toggleCampSender = () => {
        setIsCheckCampSender(!isCheckCampSender);
    }

        let campaignStatus = automationCampaign.data.status;
        if (userlist.mounted && isGenerateUserList) {
            generateUsersList();
        }

    let drHeight = 416;

    return (
            <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px', minHeight: drHeight }}>
                <div style={{ paddingBottom: '12px' }}>
                    {
                        (isShowCampSender) &&
                        <div style={{ display: 'inline-block', width: '34%' }}>
                             <FormControlLabel 
                                control={<Checkbox color="default" value={isCheckCampSender} checked={isCheckCampSender} onChange={toggleCampSender} style={{marginRight: '10px'}} />}  
                                label={<div style={{ color: '#717171', marginLeft: '-10px', fontWeight: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.CAMPAIGN_SENDER}</div>} 
                                labelposition="right" 
                                title={getLocalizedStrings().label.AUTOMATION_DESIGNER.CAMPAIGN_SENDER}  style={{width: '20px', marginRight: '10px' }}
                            />
                        </div>
                    }
                    <div style={{ display: 'inline-block', width: '35%' }}>
                    <FormControlLabel 
                        control={<Checkbox  color="default" value={isCheckSalsRep} checked={isCheckSalsRep} onChange={toggleSalesRep} style={{marginRight: '10px'}} />} 
                        label={<div style={{ color: '#717171', marginLeft: '-10px', fontWeight: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >{getLocalizedStrings().label.AUTOMATION_DESIGNER.SALES_REP}</div>} labelposition="right" 
                        style={{width: '20px', marginRight: '10px' }}
                        title={getLocalizedStrings().label.AUTOMATION_DESIGNER.SALES_REP}
                    />
          
                    </div>
                    <div style={{ display: 'inline-block', width: '30%' }}>
                    <FormControlLabel 
                        control={<Checkbox color="default" value={isCheckUsers} checked={isCheckUsers} onChange={toggleUsers} style={{marginRight: '10px'}}/>} 
                        label={<div style={{ color: '#717171', marginLeft: '-10px', fontWeight: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.USERS}</div>}labelposition="right" 
                        style={{width: '20px', marginRight: '10px' }}
                        title={getLocalizedStrings().label.AUTOMATION_DESIGNER.USERS}
                    />
                    
                    </div>
                </div>

                <div style={{ paddingBottom: '12px' }}>
                    <Select
                        loadingPlaceholder="Loading..."
                        isMulti
                        value={users}
                        options={restofUsers}
                        placeholder={getLocalizedStrings().label.AUTOMATION_DESIGNER.CLICK_TO_SELECT_USERS}
                        onChange={(e)=>changeUsersList(e)}
                        className={"sf-fields-bg"}
                        style={{ border: '1px solid rgb(221, 221, 221)', backgroundColor: '#fff', borderRadius: '0px', height: '42px', paddingTop: '4px', paddingLeft: '2px' }}
                    />
                </div>

                {
                    (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                    <div>
                        <Button
                            style={styles.listViewPrimaryButton}
                            onClick={()=>saveNotify()}
                        >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                    </div>
                }
            </div>
        );
    }


export default AutomationNotify;

