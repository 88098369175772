import React from 'react';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import * as HTTPClient from '../../../services/helper/httpClient';
import { constants } from '../../../services/constants/constants';
import { isValidParam } from '../../../services/helper/parameterVerifier';

const mapStateToProps = (state) => {
    return {
        app: state.app,
        appContainer: state.appContainer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAppDialogActions: (actions) => {
            dispatch(setAppDialogActions(actions));
        },
        getAppDialog: (isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom) => {
            dispatch(getAppDialog(isOpenDialog, dialogType, dialogTitle, eventName, data,callFrom));
        }
    }
};

class VideoInsertDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            video_link: '',
            video_link_err: ''
        }
    }

    handleClose = () => {
        this.props.getAppDialog(false, null, null, null, null, null);
    }

    insertYouTubeVideo = () => {
        let video_link = this.state.video_link.trim();
        if (video_link === '') {
            this.setState({video_link_err: getLocalizedStrings().message.YOUTUBE_VIDEO.EMPTY_VIDEO_LINK});
            return false;
        }

        let videoArr = video_link.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
        if (videoArr && videoArr.length > 1) {
            let videoId = videoArr[1];
            let appContainer = this.props.appContainer;
            let params = {}
            let url = constants.YOUTUBE_API_URL + videoId;
            var promise = Promise.resolve(HTTPClient.getVideo(url, params));
            promise.then((data) => { 
                data = data || {};
                let items = data.items;
                if (items && items.length > 0) {
                    var item = items[0];
                    if (item) {
                        var snippet = item.snippet;
                        if (snippet) {
                            var title = snippet.title;
                            var thumbnails = snippet.thumbnails;
                            var thumbnail = {};
                            if (thumbnails) {
                                if (thumbnails.hasOwnProperty("high")) {
                                    thumbnail = thumbnails.high;
                                } else if (thumbnails.hasOwnProperty("standard")) {
                                    thumbnail = thumbnails.standard;
                                } else if (thumbnails.hasOwnProperty("medium")) {
                                    thumbnail = thumbnails.medium;
                                }
                            }
                            let obj = { thumbnail: thumbnail, title: title, video_link: video_link, editor: appContainer.dialogProps.data.editor };
                            appContainer.dialogProps.eventName(obj);
                        }
                    }
                } else {
                    this.setState({video_link_err: getLocalizedStrings().message.YOUTUBE_VIDEO.INVALID_VIDEO_LINK});
                }
                
            });
        } else {
            this.setState({video_link_err: getLocalizedStrings().message.YOUTUBE_VIDEO.INVALID_VIDEO_LINK});
        }       

    }

    validUrl = (url) => {
        var p = /https?:\/\/(.+)?(wistia\.com|wi\.st)\/.*/;
        return ( url.match( p ) ) ? RegExp.$1 : false;
    }

    insertWistiaVideo = () => {
        let video_link = this.state.video_link.trim();
        if (video_link === '') {
            this.setState({video_link_err: getLocalizedStrings().message.WISTIA_VIDEO.EMPTY_VIDEO_LINK});
            return false;
        }

        if (!this.validUrl(video_link)) {
            this.setState({video_link_err: getLocalizedStrings().message.WISTIA_VIDEO.INVALID_VIDEO_LINK});
            return false;
        } else {            
            let appContainer = this.props.appContainer;
            let params = {}
            var protocol = window.location.protocol; 
            
            let url = protocol+ constants.WISTIA_API_URL + video_link;
            var promise = Promise.resolve(HTTPClient.getVideo(url, params));
            promise.then((data) => { 
                data = data || {};
                let thumbnail = {
                    width: data.thumbnail_width,
                    height: data.thumbnail_height,
                    url: data.thumbnail_url
                }                
                let obj = { thumbnail: thumbnail, title: data.title, video_link: video_link, editor: appContainer.dialogProps.data.editor };
                appContainer.dialogProps.eventName(obj);                
            }); 
        }
    }

    handleInsertVideo = () => {
        let videoType = this.props.appContainer.dialogProps.data.videoType;
        if (videoType === constants.VIDEO_TYPE_YOUTUBE) {
            this.insertYouTubeVideo();

        } else if (videoType === constants.VIDEO_TYPE_WISTIA) {
            this.insertWistiaVideo();
        }       
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const _state = this.state;
        _state[name] = value; 
        _state['video_link_err'] = '';       
        this.setState(_state);
    };
    

    componentDidMount() {
        this.props.setAppDialogActions(this.getActions());
    }

    getActions = () => {
        const actions = [
            <Button
               
                primary={true}
                onClick={this.handleInsertVideo}
                style={styles.primaryButton}
            > {getLocalizedStrings().label.COMMON.INSERT}</Button>,
            <Button
               
                primary={true}
                onClick={this.handleClose}
                style={styles.secondaryButton}
            > {getLocalizedStrings().label.COMMON.CANCEL}</Button>,

        ];
        return actions;
    }

    render() {      
        let video_link_placeholder= null;
        
        if (this.props.appContainer.dialogProps.data !== null && isValidParam(this.props.appContainer.dialogProps.data.videoType)) { 
            let videoType = this.props.appContainer.dialogProps.data.videoType;
            if (videoType === constants.VIDEO_TYPE_YOUTUBE) {
                video_link_placeholder = getLocalizedStrings().message.YOUTUBE_VIDEO.VIDEO_LINK_PLACEHOLDER;

            } else if (videoType === constants.VIDEO_TYPE_WISTIA) {
                video_link_placeholder = getLocalizedStrings().message.WISTIA_VIDEO.VIDEO_LINK_PLACEHOLDER;
            }  
        }
        return (
            <div>
                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        label={video_link_placeholder}
                        InputLabelProps={{
                            style: {
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                width: '96%',
                            }
                        }}
                        fullWidth={true}
                        name="video_link"
                        value={this.state.video_link}
                        onChange={this.handleChange} 
                        autoFocus={true}
                        autoComplete="new-password"
                        margin='dense'
                        size='small'
                        className={"sf-fields-bg"}
                    /></FormControl>
                    {this.state.video_link_err !== '' && 
                        <div style={{fontSize: 12,color: '#f44336', marginLeft: 8}}>{this.state.video_link_err}</div>
                    }   
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoInsertDialog);