import * as React from 'react';
import { connect } from 'react-redux';
import { constants } from '../../../services/constants/constants';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getHome, setSelectedModuleItems, getObjectList } from '../../../services/actions/appActions';
import MenuConfiguration from '../components/MenuConfiguration';
import MobileMenuConfiguration from '../components/MobileMenuConfiguration';


const mapStateToProps = (state) => {
    return {
        app: state.app,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
        getHome: () => {
            dispatch(getHome());
        },
        setSelectedModuleItems: (selectedModule) => {
            dispatch(setSelectedModuleItems(selectedModule));
        },
        getObjectList: () => {
            dispatch(getObjectList());
        },
    }
};

connect(mapStateToProps, mapDispatchToProps)
export default class MenuConfigurationContainers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    UNSAFE_componentWillMount() {
    }

    componentDidMount() {
    }

    render() {
        if (this.props.object ===  constants.MENU_CONFIGURATION_OBJECT) {
            return (
                <MenuConfiguration object={this.props.object} />
            );
        } else {
            return (
                <MobileMenuConfiguration object={this.props.object} />
            );
        }
    }
}

