import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setHeader } from '../../../services/actions/headerActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants } from '../../../services/constants/constants';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Highcharts from 'highcharts'
import { styles } from '../../../services/constants/styles';
import { HighchartsReact } from 'highcharts-react-official';
import funnel from "highcharts/modules/funnel.js";
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
funnel(Highcharts);
NoDataToDisplay(Highcharts);

Highcharts.setOptions({
    lang: {
        thousandsSep: ',',
        noData: "No data to display"
    }
});
const chartInitialData = {
    "chart": {
        "type": "column"
    },
    "xAxis": {
        "categories": []
    },
    "title": {
        "text": ""
    },
    "yAxis": {
        "min": 0,
        "title": {
            "text": ""
        },
        "stackLabels": {
            "enabled": true,
            "style": {
                "fontWeight": "bold",
                "color": "gray"
            }
        }
    },
    "series": [
        {
            "name": "Count",
            "data": [],
            "dataLabels": {
                "enabled": true
            },
            "cursor": "pointer",
            "showInLegend": true,
            "point": {}
        }
    ],
    "subtitle": {
        "text": ""
    },
    "credits": {
        "enabled": false
    },
    "exporting": {
        "buttons": {
            "contextButton": {
                "_titleKey": "mytoolTip",
                "menuItems": [
                    {
                        "text": "Print Report"
                    },
                    {
                        "separator": true
                    },
                    {
                        "text": "Download PNG Image"
                    },
                    {
                        "text": "Download JPEG Image"
                    },
                    {
                        "text": "Download PDF Image"
                    },
                    {
                        "text": "Download SVG Image"
                    }
                ]
            }
        }
    },
    "lang": {
        "mytoolTip": "Print/Download Report"
    },
    "plotOptions": {
        "series": {
            "cursor": "pointer",
            "borderWidth": 0,
            "showInLegend": true,
            "point": {
                "events": {}
            },
            "dataLabels": {
                "enabled": true,
                "color": "#000000",
                "shadow": false,
                "style": {
                    "fontSize": "10px",
                    "textShadow": "0px"
                }
            }
        }
    },
    "tooltip": {
        "headerFormat": "<b>{point.x}</b><br/>",
        "pointFormat": "{series.name}: {point.y}"
    }
};
const initialChartTypes = [
    { id: 'column', label: 'Column' },
    { id: 'pie', label: 'Pie' },
    { id: 'funnel', label: 'Funnel' },
    { id: 'line', label: 'Line' },
    { id: 'bar', label: 'Bar' }
]
const ListViewDashBoard = () => {
    const dispatch = useDispatch();
    const groupByValues = useSelector((state) => state.listView?.groupByInfo?.group_by)
    const tabs = useSelector((state)=>state.tab?.tabs)
    const [selectedChartType, setSelectedChartType] = useState('');
    const [loadding, setLoadding] = useState(false);
    const [options, setOptions] = useState({ ...chartInitialData });
    const [chartType, setChartType] = useState([]);
    const chartComponentRef = useRef(null);
    let activeTab = tabs?.filter(f => { return f.isActive });
    let CurrentTab = tabs?.filter((ele)=> { return ele.object === constants.REPORT_DASHBOARD});

    useEffect(() => {
        dispatch(setHeader(constants.REPORT_DASHBOARD,(activeTab[0]?.label), null, false, true));
        if(CurrentTab[0]?.info?.currentView === constants.KANBAN){
            let _options = options;
            _options.series[0].name = 'Total Forecast($)';
        }else{
            let _options = options;
            _options.series[0].name = 'Count';
        }
        setChartType(initialChartTypes);
        setSelectedChartType(initialChartTypes[0].id);
         let list =CurrentTab[0]?.info?.list
        let allEqual = false

        if(list != undefined ){
            allEqual= list?.every(v => v?.record_count == 0);
        }
             if(allEqual == true ){
                CurrentTab[0].info.list = [];
                loadData(initialChartTypes[0].id);
             }else{
                loadData(initialChartTypes[0].id);
            }
    }, [groupByValues])

    const getCategories = () => {
        let _arr = [];
        CurrentTab[0]?.info?.list?.forEach((f, i) => {
            _arr.push(f.label);
        });
        return _arr;

    }
    const getReportData = () => {
        let _arr = CurrentTab[0]?.info?.list?.map((f, i) => {
            let typeObj = {};
            typeObj.name = f.label;
            typeObj.y = f.record_count;
            return typeObj;
        });
        return _arr;
    }

    const handleChange = (event) => {
        let value = event.target.value;
        setSelectedChartType(value);
    }
    const getChartTypeList = () => {
        let List = chartType;
        if (List !== undefined) {
            return List.map((item, i) => {
                return <MenuItem value={item.id}
                    style={styles.popoverMenuItem} >{item.label}</MenuItem>;
            })
        }
    }

    const loadData = (chartType) => {
        setLoadding(true);
        let _options = { ...options };
        _options.chart.type = chartType?.length > 0 ? chartType :selectedChartType;
        _options.xAxis.categories = [...getCategories()];
        _options.title.text = `${activeTab[0]?.label}`;
        _options.series[0].data = [...getReportData()];
        setOptions({ ..._options });
        setLoadding(false);

    }
    const getSelectFieldFordashBoard = () => {
        let element = <div style={{ width: '100%',display: 'flex', marginBottom: '15px',marginTop:'10px' }}>
            <FormControl style={{ width: '300px', marginRight: '20px' }}>
                <InputLabel id="demo-simple-select-label" style={{
                    marginTop: selectedChartType ? '0px' : '-6px'
                }}>{getLocalizedStrings().label.REPORT.CHART_TYPE}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedChartType}
                    label="Chart Type"
                    style={{ width: '100%' }}
                    onChange={handleChange}
                    size='small'
                >
                    {getChartTypeList()}
                </Select>
            </FormControl>
            <Button
                onClick={() => loadData()}
                style={{
                    ...styles.primaryButton,
                    minWidth: '56px', width: '65px',
                    marginTop: 8
                }}
                title={getLocalizedStrings().label.REPORT.GO}
            > {getLocalizedStrings().label.REPORT.GO}</Button>
        </div>
        return element;
    }


    let drWidth = window.innerWidth - 60;
    let contentHeight = window.innerHeight-200;
    return (
        <div style={{ width: '99%' ,marginLeft:'1%',height:contentHeight,overflowY:'scroll'}}>
            <div>
                {getSelectFieldFordashBoard()}
            </div>
            <div style={{ width: drWidth }}>
                {options?.series[0]?.data.length > 0 || !loadding ?
                    <HighchartsReact options={options} highcharts={Highcharts} ref={chartComponentRef} />
                    :
                    <div className="data_less">{getLocalizedStrings().message.CHARTS.NOTHING_TO_SHOW}</div>
                }
            </div>
        </div>
    )
}
export default ListViewDashBoard;

