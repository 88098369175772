import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getCookie, setCookie } from '../../../services/helper/sfCookies';
import { setHeader } from '../../../services/actions/headerActions';
import { isValidParam, getObjectParam, getStringParam, getArrayParam, getIntParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { getActiveTab, updateActiveTabProps, getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { hasAccessPermission } from '../../../services/helper/common';
import * as sfDialogs from '../components/sfDialogs';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import ListView from '../containers/ListView';
import DatabaseContainer from '../containers/DatabaseContainer';
import { getSetupPageInfo } from '../../../services/actions/appActions';
import ProfileDetails from '../components/profileDetails';
import NotificationReminders from '../components/notificationReminders';
import HUBSettings from '../components/hubsettings';
import SourceForm from '../components/sourceForm';
import ChangeLogo from '../components/changeLogo';
import AutoFillValues from '../components/autoFillValues';
import FindDuplicate from '../components/findDuplicate';
import Scheduler from '../components/scheduler';
import LeadScoreSetup from '../components/LeadScoreSetup';
import ExportSettings from '../components/exportSettings';
import CompanyProfile from '../components/companyProfile';
import WhiteLabel from '../components/WhiteLabel';
import GenerateAPIkey from '../components/GenerateAPIkey';
import PortalMenuConfiguration from '../components/portalMenuConfiguration';
import DuplicateRules from '../components/duplicateRules';
import RoyaltySetup from '../components/royaltySetup';
import UnitCrm from '../components/UnitCrm';
import ReviewWebsite from '../components/ReviewWebsite';
import MenuConfigurationContainers from '../containers/MenuConfigurationContainers';
import { useDispatch, useSelector } from "react-redux";
import ComplianceSetup from '../components/ComplianceSetup';
import LmsSetup from '../components/LmsSetup';
import CompanySchedulerAvailability from '../components/CompanySchedulerAvailability';
import CompanySchedulerAvppointentType from '../components/CompanySchedulerAvppointentType';
import CompanySchedulerLink from '../components/CompanySchedulerLink';
import CompanySchedulerFields from '../components/CompanySchedulerFields';
import ReportedSalesSetup from '../components/reportedSalesSetup';
import RoyaltySetupSetting from './RoyaltySetupSetting';
import EmailToCaseSetup from '../components/emailToCaseSetup';

const ModuleSetting = ({ object }) => {
    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const tab = useSelector((state) => state.tab);

    const [mounted, setMounted] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isShowUpgrade, setIsShowUpgrade] = useState(false);
    const [settingViewControl, setSettingViewControl] = useState({ left: '20%', isMinimize: false });
    const [checkAdminPermission, setCheckAdminPermission] = useState(false);
    const [containerelement, setContainerelement] = useState(null);

    useEffect(() => {
        let defaultMenuItem = { id: 0, name: constants.PROFILE_DETAILS, label: getLocalizedStrings().label.PROFILE_DETAILS.PROFILE_DETAILS_LABEL, type: "menu-item", object: constants.PROFILE_DETAILS };

        let activeTabInfo = getObjectParam(getActiveTabInfo());
        if (isValidParam(activeTabInfo.selectedItem)) {
            selectDefaultMenuItem(activeTabInfo.selectedItem);
        } else {
            selectDefaultMenuItem(defaultMenuItem);
        }
        fnGetSetupPageInfo();
        getAdminSecurityRolePermission();
        fnSettingViewControl();

    }, []);

    useEffect(() => {
        let defaultMenuItem = { id: 0, name: constants.PROFILE_DETAILS, label: getLocalizedStrings().label.PROFILE_DETAILS.PROFILE_DETAILS_LABEL, type: "menu-item", object: constants.PROFILE_DETAILS };

        if (getArrayParam(tab.tabs).length > 0) {
            let tabData = tab.tabs[0];
            let activeTabInfo = getObjectParam(getActiveTabInfo());
            if ((tabData.item === constants.SETTING) && tabData.item === constants.SETTING && !isValidParam(activeTabInfo.selectedItem)) {
                selectDefaultMenuItem(defaultMenuItem);
            }
        }
    }, [tab.tabs]);


    const fnSettingViewControl = () => {
        let userId = (app.me.id * 19000) + 7;
        let projectId = (app.me.projectId * 19000) + 7;
        let cookieName = "setting-my-setup" + projectId + '-' + userId;
        let view = JSON.parse(getCookie(cookieName));
        if (view !== null) {
            let tempSettingViewControl = settingViewControl
            tempSettingViewControl.left = view ? 20 : '20%';
            tempSettingViewControl.isMinimize = view;
            setSettingViewControl(tempSettingViewControl);

        }
    }


    const minimizeSettingsMenu = () => {
        let tempObj = settingViewControl;
        tempObj.left = !settingViewControl.isMinimize ? 20 : '20%';
        tempObj.isMinimize = !settingViewControl.isMinimize;
        setSettingViewControl({ ...tempObj })
        let userId = (app.me.id * 19000) + 7;
        let projectId = (app.me.projectId * 19000) + 7;
        let cookieName = "setting-my-setup" + projectId + '-' + userId;
        setCookie(cookieName, settingViewControl.isMinimize);
    }

    const selectDefaultMenuItem = (_selectedItem) => {
        if (_selectedItem.label === "Royalty Report Setup") {
            let _defaultMenuItem = { id: 0, name: 'royalty_report_setup', label: getLocalizedStrings().label.COMMON.ROYALTY_REPORT_SETUP, type: "menu-item", object: constants.REPORTED_SALES_OBJECT };
            setSelectedItem(_defaultMenuItem)
            setContainerelement(getComponent(_defaultMenuItem));
        } else if (_selectedItem.label === "Royalty Setup") {
            let _defaultMenuItem = { id: 0, name: 'royalty_setup', label: getLocalizedStrings().label.COMMON.ROYALTY_REPORT_SETUP, type: "menu-item", object: constants.ROYALTY_SETUP_OBJECT };
            setSelectedItem(_defaultMenuItem)
            setContainerelement(getComponent(_defaultMenuItem));
        }
        else if (_selectedItem.label === "Assignment Rules") {
            let _defaultMenuItem = { id: 0, name: 'assignmentrules', label: getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULES, type: "menu-item", object: constants.ASSIGNMENT_RULES_OBJECT };
            setSelectedItem(_defaultMenuItem)
            setContainerelement(getComponent(_defaultMenuItem));
        }
        else if (_selectedItem.label === "Recycle Bin") {
            let _defaultMenuItem = { id: 0, name: "recyclebin", label: getLocalizedStrings().label.SETTINGS.RECYCLEBIN, type: "menu-item", object: constants.RECYCLE_BIN_OBJECT };
            setSelectedItem(_defaultMenuItem)
            setContainerelement(getComponent(_defaultMenuItem));
        }
        else if(_selectedItem.label === "Find Duplicates"){
            let _defaultMenuItem ={id: 0, name: constants.FIND_DUPLICATE_OBJECT, label: getLocalizedStrings().label.FIND_DUPLICATES.FIND_DUPLICATES, type: "menu-item", object: constants.FIND_DUPLICATE_OBJECT }
            setSelectedItem(_defaultMenuItem)
            setContainerelement(getComponent(_defaultMenuItem));
        }
        else {
            const defaultMenuItem = { id: 0, name: constants.PROFILE_DETAILS, label: getLocalizedStrings().label.PROFILE_DETAILS.PROFILE_DETAILS_LABEL, type: "menu-item", object: constants.PROFILE_DETAILS };
            setSelectedItem(defaultMenuItem)
            setContainerelement(getComponent(defaultMenuItem));
        }

    }

    const fnGetSetupPageInfo = () => {
        let promise = getSetupPageInfo();
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    setIsShowUpgrade(getBooleanParam(response.is_show_upgrade));
                }
            });
        }
    }

    const getAdminSecurityRolePermission = () => {
        let params = {
            "security_level_name": "Admin",
            "template_id": app.me.t_id
        }
        if (app.me.t_id !== 2 && !app.me.is_tenant_admin) {
            var response = Promise.resolve(HTTPClient.get(endPoints.COMMON.HAS_SECURITY_ROLE_PERMISSION, params));
            response.then((response) => {
                if (response.status === 0 && getBooleanParam(response.hasSecurityRolePermission)) {
                    setCheckAdminPermission(getBooleanParam(response.hasSecurityRolePermission));
                }
            });
        }
    }

    const getPermission = (item) => {
        let hasPermission = false;
        if (item.name === 'purchase-products' || item.name === 'purchase-professional'
            || item.name === 'billing-invoice' || item.name === 'cancel-subscription'
            || item.name === 'upgrade-subscription' || item.name === 'subscription-details'
            || item.name === 'purchase-email-credits' || item.name === 'purchase-phone-credits'
            || item.name === 'purchase-sms-credits' || item.name === 'purchase-card-scanning-credits'
            || item.name === 'purchase-contacts' || item.name === 'purchase-training'
            || item.name === 'purchase-marketing' || item.name === 'scheduler' || item.name === 'royalty_setup' || item.name === 'royalty_report_setup' || item.name === 'review-website' || item.name === 'unit_crm') {
            hasPermission = true;
        } else {
            if (item.permissionType > 0) {
                let checkValue = '';
                let securityLevelType = '';
                if (item.permissionName === 'DesignForms') {
                    checkValue = constants.PERMISSION_NAME_DESIGN_FORMS;
                } else if (item.permissionName === 'DesignWorkFlows') {
                    checkValue = constants.PERMISSION_NAME_DESIGN_WORKFLOWS;
                } else if (item.permissionName === 'Users') {
                    checkValue = constants.PERMISSION_NAME_MANAGE_USERS;
                } else if (item.permissionName === 'SecurityLevels') {
                    checkValue = constants.PERMISSION_NAME_MANAGE_SECURITY_LEVELS;
                } else if (item.permissionName === 'SetupLeadScores') {
                    checkValue = constants.PERMISSION_NAME_SETUP_LEAD_SCORE;
                } else if (item.permissionName === 'ExportRecords') {
                    checkValue = constants.PERMISSION_NAME_EXPORT_RECORDS;
                } else if (item.permissionName === 'ChangeLogo') {
                    checkValue = constants.PERMISSION_NAME_UPLOAD_LOGO;
                }

                securityLevelType = constants.SECURITY_LEVEL_TYPE_PERMISSION;
                hasPermission = hasAccessPermission('', securityLevelType, checkValue);
            } else {
                let isTenantAdmin = app.me.is_tenant_admin;
                let userTemplateId = app.me.t_id;
                let securityLevelName = getStringParam(app.me.t_name);

                if (item.name === constants.NOTE_TYPES_OBJECT || item.name === constants.CAMPAIGN_TYPES_OBJECT || item.name === 'appointmenttypes' || item.name === constants.ROLL_UP_OBJECT) {
                    if (isTenantAdmin || userTemplateId === 2 || checkAdminPermission) {
                        hasPermission = true;
                    }
                } else if ((item.name === constants.RELATIONS_OBJECT || item.name === constants.CORPORATE_CURRENCY_OBJECT
                    || item.name === 'auto-fill-values' || item.name === 'company-profile'
                    || item.name === 'whitelabellink' ||  item.name === constants.EMAIL_TO_CASE_SETUP || item.name === constants.PHONE_NUMBERS_OBJECT) && isTenantAdmin) {
                    hasPermission = true;
                } else if (item.name === constants.PROFILE_DETAILS || item.name === constants.NOTIFICATION_REMINDERS
                    || item.name === 'email-integration' || item.name === constants.FIND_DUPLICATE_OBJECT || item.name === constants.RECYCLE_BIN_OBJECT || item.name === constants.ASSIGNMENT_RULES_OBJECT
                    || item.name === 'library' || item.name === 'hubsettings' || item.name === constants.EMAIL_INTEGRATION_OBJECT || item.name === constants.GENERATE_API_KEY_OBJECT || item.name === constants.MENU_CONFIGURATION_OBJECT) {
                    hasPermission = true;
                } else if (item.name === constants.MOBILE_MENU_CONFIGURATION_OBJECT && (isTenantAdmin || securityLevelName === 'Admin')) {
                    hasPermission = true;
                } else if (item.name === constants.PORTAL_MENU_CONFIGURATION_OBJECT && (isTenantAdmin || securityLevelName === 'Admin')) {
                    hasPermission = true;
                } else if (item.name === constants.DUPLICATE_RULE_OBJECT && (isTenantAdmin || securityLevelName === 'Admin')) {
                    hasPermission = true;
                } else if (item.object === constants.AUDIT_QUESTIONS_OBJECT && (isTenantAdmin || securityLevelName === 'Admin')) {
                    hasPermission = true
                }

                else if (item.object == constants.COMPLIANCE_SETUP) {
                    hasPermission = true
                }
                else if (item.object == constants.LMS_SETUP) {
                    hasPermission = true
                }
                else if (item.object == constants.REPORTED_SALES_SETUP) {
                    hasPermission = true
                }
                else if (item.object == constants.SCHEDULER_AVAILABILITY) {
                    hasPermission = true
                }
                else if (item.object == constants.SCHEDULER_APPOINTMENT_TYPES) {
                    hasPermission = true
                }
                else if (item.object == constants.SCHEDULER_INTAKE_FORM) {
                    hasPermission = true
                }
                else if (item.object == constants.SCHEDULER_LINK) {
                    hasPermission = true
                }
            }
        }
        return hasPermission;
    }

    const onMenuChange = (selectedItem) => {
        let hasPermission = getPermission(selectedItem);
        if (hasPermission) {
            let info = getObjectParam(selectedItem);
            let object = getStringParam(info.object);

            if (object === constants.USERS_OBJECT) {
                let token = localStorage.getItem('token');
                let redirectUrl = constants.snapshotUrl;
                redirectUrl += '&token=' + token;
                redirectUrl += '&callingfor=' + "users";
                redirectUrl += '&type=UM';
                redirectUrl += '&label=User Manager';
                window.open(redirectUrl);
            } else if (object === 'upgrade-subscription') {
                let token = localStorage.getItem('token');
                let redirectUrl = constants.snapshotUrl;
                redirectUrl += '&token=' + token;
                redirectUrl += '&callingfor=' + "subscription";
                redirectUrl += '&type=upgrade-subscription';
                window.open(redirectUrl);
            } else if (object === 'purchase-products') {
                let tenantType = app.me.customerType;
                let token = localStorage.getItem('token');
                let redirectUrl = constants.snapshotUrl;
                redirectUrl += '&token=' + token;
                redirectUrl += '&callingfor=' + "subscription";
                if (tenantType != null && tenantType == "BrandWide") {
                    redirectUrl += '&type=purchase-products-BrandWide';
                } else {
                    redirectUrl += '&type=purchase-products';
                }
                window.open(redirectUrl);
            } else if (object === 'purchase-professional') {
                let token = localStorage.getItem('token');
                let redirectUrl = constants.snapshotUrl;
                redirectUrl += '&token=' + token;
                redirectUrl += '&callingfor=' + "subscription";
                redirectUrl += '&type=purchase-professional';
                window.open(redirectUrl);
            } else if (object === 'billing-invoice') {
                redirectToReculy();
            } else if (object === 'cancel-subscription') {
                let token = localStorage.getItem('token');
                let redirectUrl = constants.snapshotUrl;
                redirectUrl += '&token=' + token;
                redirectUrl += '&callingfor=' + "subscription";
                redirectUrl += '&type=cancel-subscription';
                window.open(redirectUrl);
            } else if (object === 'subscription-details') {  // InXpress
                let token = localStorage.getItem('token');
                let redirectUrl = constants.snapshotUrl;
                redirectUrl += '&token=' + token;
                redirectUrl += '&callingfor=' + "subscription";
                redirectUrl += '&type=subscription-details';
                window.open(redirectUrl);
            } else if (object === 'purchase-email-credits') {  // InXpress
                let token = localStorage.getItem('token');
                let redirectUrl = constants.snapshotUrl;
                redirectUrl += '&token=' + token;
                redirectUrl += '&callingfor=' + "subscription";
                redirectUrl += '&type=purchase-email-credits';
                window.open(redirectUrl);
            } else if (object === 'purchase-phone-credits') {  // InXpress
                let token = localStorage.getItem('token');
                let redirectUrl = constants.snapshotUrl;
                redirectUrl += '&token=' + token;
                redirectUrl += '&callingfor=' + "subscription";
                redirectUrl += '&type=purchase-phone-credits';
                window.open(redirectUrl);
            } else if (object === 'purchase-sms-credits') {  // InXpress
                let token = localStorage.getItem('token');
                let redirectUrl = constants.snapshotUrl;
                redirectUrl += '&token=' + token;
                redirectUrl += '&callingfor=' + "subscription";
                redirectUrl += '&type=purchase-sms-credits';
                window.open(redirectUrl);
            } else if (object === 'purchase-card-scanning-credits') {  // InXpress
                let token = localStorage.getItem('token');
                let redirectUrl = constants.snapshotUrl;
                redirectUrl += '&token=' + token;
                redirectUrl += '&callingfor=' + "subscription";
                redirectUrl += '&type=purchase-card-scanning-credits';
                window.open(redirectUrl);
            } else if (object === 'purchase-contacts') {  // InXpress
                let token = localStorage.getItem('token');
                let redirectUrl = constants.snapshotUrl;
                redirectUrl += '&token=' + token;
                redirectUrl += '&callingfor=' + "subscription";
                redirectUrl += '&type=purchase-contacts';
                window.open(redirectUrl);
            } else if (object === 'purchase-training') {  // InXpress
                let token = localStorage.getItem('token');
                let redirectUrl = constants.snapshotUrl;
                redirectUrl += '&token=' + token;
                redirectUrl += '&callingfor=' + "subscription";
                redirectUrl += '&type=purchase-training';
                window.open(redirectUrl);
            } else if (object === 'purchase-marketing') {  // InXpress
                let token = localStorage.getItem('token');
                let redirectUrl = constants.snapshotUrl;
                redirectUrl += '&token=' + token;
                redirectUrl += '&callingfor=' + "subscription";
                redirectUrl += '&type=purchase-marketing';
                window.open(redirectUrl);
            } else if (object === 'library') {
                let token = localStorage.getItem('token');
                let redirectUrl = constants.snapshotUrl;
                redirectUrl += '&token=' + token;
                redirectUrl += '&callingfor=' + "library";
                redirectUrl += '&type=IMG_GALLERY';
                redirectUrl += '&label=Library';
                window.open(redirectUrl);
            } else {
                let element = getComponent(selectedItem);
                setSelectedItem(selectedItem);
                setContainerelement(element);
            }
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
        }
    }

    const redirectToReculy = () => {
        let url = endPoints.FIND_DUPLICATE.GET_RECURLY_TOKEN;
        let promise = Promise.resolve(HTTPClient.get(url));
        promise.then((response) => {
            if (isValidParam(response)) {
                let status = response.status;
                if (status != constants.STATUS_FAILURE) {
                    let rcToken = response.recurly_token;
                    let recurlyAPI = constants.RECURLY_APP_URL + '/account/' + rcToken;
                    window.open(recurlyAPI);
                } else {
                    let message = "Your earlier payment information is not available with us. Please contact smbsupport@soffront.com for further action.";
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, message, null);
                }
            }
        })
    }

    const getComponent = (selectedItem) => {
        let element = null;
        let info = getObjectParam(selectedItem);
        let object = getStringParam(info.object);
        let activeTab = getActiveTab();
        let isDraggable = false;
        let input_param = null;
        let duplicateField = null;
        let duplicate_object_id = 0;
        let query_id = null;
        let duplicate_object = null;
        if (isValidParam(activeTab?.info?.input_param) && object === constants.FIND_DUPLICATE_OBJECT) {
            input_param = activeTab.info.input_param;
            duplicateField = input_param.duplicate_field;
            duplicate_object_id = input_param.table_id;
            query_id = input_param.query_id;
            duplicate_object = input_param.object;
        }

        activeTab.type = 'TYPE_OTHER_VIEW';
        activeTab.object = 'settings';
        activeTab.info = {};
        if (object === constants.SOURCE_OBJECT || object === constants.LOCATION_OBJECT || object === constants.TASKSLIST_OBJECT || object === constants.WORKFLOW_OBJECT || object === constants.NOTE_TYPES_OBJECT || object === constants.APPOINTMENT_TYPES_OBJECT
            || object === constants.RELATIONS_OBJECT || object === constants.CAMPAIGN_TYPES_OBJECT || object === constants.RECYCLE_BIN_OBJECT || object === constants.ASSIGNMENT_RULES_OBJECT
            || object === constants.EMAIL_INTEGRATION_OBJECT || object === constants.SECURITY_LEVEL_OBJECT || object === constants.ROLL_UP_OBJECT
            || object === constants.PHONE_NUMBERS_OBJECT || object === constants.AUDIT_QUESTIONS_OBJECT
            || object === constants.LMS_USERS_OBJECT) {

            let prevSelectedObject = isValidParam(selectedItem) ? getStringParam(selectedItem.object) : '';
            if (prevSelectedObject !== object) {
                setMounted(false);
            }

            activeTab.type = 'TYPE_LIST_VIEW';
            let filter = { id: 0, name: object === constants.NOTE_TYPES_OBJECT ? "Note Types" : info.label, type: '' };
            if (object === constants.RECYCLE_BIN_OBJECT) {
                filter.name = 'All deleted records';
            }
            if (object === constants.SECURITY_LEVEL_OBJECT) {
                filter.name = constants.SECURITY_LEVEL_OBJECT;
            }
            if (object === constants.APPOINTMENT_TYPES_OBJECT) {
                filter.name = "Appointment Types";
            }
            activeTab.info = { filter: filter };
            activeTab.object = object;
            activeTab.refreshFromSettings = true;
            if (object === constants.WORKFLOW_OBJECT) {
                let selectedGroupBy = getObjectParam(JSON.parse(getCookie('groupBy_' + object + '_t_tableid_' + app.me.projectId)));
                let accountGroupCond = getArrayParam(app.objectList).filter(f => f.name === constants.ACCOUNTS_OBJECT)[0].label;
                activeTab.info = { ...activeTab.info, groupByFieldName: 't_tableid', groupByCondition: isValidParam(selectedGroupBy.groupByCondition) ? selectedGroupBy.groupByCondition : accountGroupCond };
            }
            if (object === constants.SECURITY_LEVEL_OBJECT) {
                activeTab.info = { ...activeTab.info, groupByFieldName: 't_name', groupByCondition: 'All' };
            }
            if (object === constants.AUDIT_QUESTIONS_OBJECT) {
                isDraggable = true;
                activeTab.object = constants.AUDIT_QUESTIONS_OBJECT;
                activeTab.info = { ...activeTab.info, isShowGroupBy: true };
                input_param = { "query_name": "All Audit Questions", "query_type": "query" };
            }
            element = <ListView object={object} isDraggable={isDraggable} fromSettings={true} />;
        } else if (object === constants.DATABASE) {
            element = <DatabaseContainer key={object} object={object} />;
        } else if (object === constants.PROFILE_DETAILS) {
            element = <ProfileDetails object={object} />;
        } else if (object === constants.NOTIFICATION_REMINDERS) {
            dispatch(setHeader(object, getLocalizedStrings().label.NOTIFICATION_REMINDERS.NOTIFICATION_REMINDERS, null, false, true));
            element = <NotificationReminders object={object} />;
        }
        else if (object === constants.CORPORATE_CURRENCY_OBJECT) {
            dispatch(setHeader(object, getLocalizedStrings().label.CUSTOMIZATION_SETTING.CORPORATE_CURRENCY, null, false, true));
            let currencyWidth = app.me.language === 'fr-FR' ? '56%' : '50%';
            element = <div style={{ height: '50%', width: currencyWidth }}><SourceForm object={object} /></div>;
        } else if (object === 'hubsettings') {
            dispatch(setHeader(object, getLocalizedStrings().label.CUSTOMIZATION_SETTING.HUB_SETTING, null, false, true));
            element = <HUBSettings object={object} />;
        } else if (object === 'change-logo') {
            element = <ChangeLogo object={object} />
        }
        else if (object === constants.LEAD_SCORES) {
            element = <LeadScoreSetup object={object} scroll={(data) => animationTop(data)} />
        }
        else if (object === 'export') {
            element = <ExportSettings object={object} />
        } else if (object === 'whitelabellink') {
            element = <WhiteLabel object={object} />
        } else if (object === constants.GENERATE_API_KEY_OBJECT) {
            element = <GenerateAPIkey object={object} />
        } else if (object === 'auto-fill-values') {
            element = <AutoFillValues object={object} />
        } else if (object === constants.FIND_DUPLICATE_OBJECT) {
            element = <FindDuplicate object={object} duplicateField={duplicateField} duplicate_object_id={duplicate_object_id} query_id={query_id} duplicate_object={duplicate_object} />
        } else if (object === constants.SCHEDULER) {
            element = <Scheduler object={object} />
        } else if (object === 'company-profile') {
            element = <CompanyProfile object={object} />
        }
        else if (object === 'review-website') {
            dispatch(setHeader(object, getLocalizedStrings().label.COMMON.WEBSITE_REVIEW, null, false, true));
            element = <ReviewWebsite object={object} />
        }
        else if (object === constants.MENU_CONFIGURATION_OBJECT || object === constants.MOBILE_MENU_CONFIGURATION_OBJECT) {
            dispatch(setHeader(object, object === constants.MENU_CONFIGURATION_OBJECT ? getLocalizedStrings().label.CUSTOMIZATION_SETTING.DESKTOP_MENU : getLocalizedStrings().label.CUSTOMIZATION_SETTING.MOBILE_MENU, null, false, true));
            element = <MenuConfigurationContainers object={object} />
        } else if (object === constants.PORTAL_MENU_CONFIGURATION_OBJECT) {
            dispatch(setHeader(object, getLocalizedStrings().label.COMMON.PORTAL_MENU, null, false, true));
            element = <PortalMenuConfiguration />
        } else if (object === constants.DUPLICATE_RULE_OBJECT) {
            element = <DuplicateRules key={object} object={object} />;
        } else if (object === constants.ROYALTY_SETUP_OBJECT) {
            dispatch(setHeader(object, getLocalizedStrings().label.COMMON.ROYALTY_SETUP, null, false, true));
            // element = <RoyaltySetup /> 
            element = <RoyaltySetupSetting object={object} />
        } 
        // else if (object === constants.ROYALTY_REPORT_SETUP_OBJECT) {
        //     dispatch(setHeader(object, getLocalizedStrings().label.COMMON.ROYALTY_REPORT_SETUP, null, false, true));
        //     element = <RoyaltySetupSetting object={object} />
        // }
        else if (object === constants.UNIT_CRM_OBJECT) {
            dispatch(setHeader(object, getLocalizedStrings().label.COMMON.UNIT_CRM, null, false, true));
            element = <UnitCrm />

        } else if (object === constants.COMPLIANCE_SETUP) {
            dispatch(setHeader(object, 'Notification Setup', null, false, true));
            element = <ComplianceSetup />
        } else if(object === constants.LMS_SETUP){
            dispatch(setHeader(object, 'LMS Setup', null, false, true));
            element = <LmsSetup />
        }
        else if (object === constants.REPORTED_SALES_SETUP) {
            dispatch(setHeader(object, 'Reported Sales Setup', null, false, true));
            element = <ReportedSalesSetup />
        }
        else if (object == constants.SCHEDULER_AVAILABILITY) {
            dispatch(setHeader(object, "Availability", null, false, true));
            element = <CompanySchedulerAvailability />
        }
        else if (object == constants.SCHEDULER_APPOINTMENT_TYPES) {
            dispatch(setHeader(object, "Appointments Types", null, false, true));
            element = <CompanySchedulerAvppointentType />
        }
        else if (object == constants.SCHEDULER_INTAKE_FORM) {
            dispatch(setHeader(object, "Intake form", null, false, true));
            element = <CompanySchedulerFields />
        }
        else if (object == constants.SCHEDULER_LINK) {
            dispatch(setHeader(object, "Scheduler Link", null, false, true));
            element = <CompanySchedulerLink />
        } else if (object === constants.EMAIL_TO_CASE_SETUP) {
            dispatch(setHeader(object, "Email to Case Rules", null, false, true));
            element = <EmailToCaseSetup object={object} />
        }
        else {
            element = containerelement;
        }
        activeTab.info.selectedItem = info;
        if (isValidParam(input_param) && input_param !== null) {
            activeTab.info.input_param = input_param;
        }
        updateActiveTabProps(activeTab);
        return element;
    }

    const getFilterNameCountsElement = (filterName, groupByCondition) => {
        let elements = [];
        filterName = getStringParam(filterName);
        if (filterName === constants.AUDIT_QUESTIONS_OBJECT) {
            filterName = getLocalizedStrings().label.AUDIT_QUESTIONS.AUDIT_QUESTIONS
        }
        groupByCondition = getStringParam(groupByCondition);
        if (filterName !== '') {
            elements.push(<div title={filterName} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 930, float: 'left' }}>{filterName}</div>);

            elements.push(<div title={groupByCondition} style={{ float: 'left' }}>&nbsp;{"- " + groupByCondition}</div>);
        } else {
            elements.push(<div title={groupByCondition} style={{ float: 'left' }}>{groupByCondition}</div>);
        }
        return elements;
    }

    /*componentDidUpdate() {
        let info = getObjectParam(selectedItem);
        let object = getStringParam(info.object);
        if (object !== constants.SOURCE_OBJECT && object !== constants.WORKFLOW_OBJECT && object !== constants.NOTE_TYPES_OBJECT && object !== constants.APPOINTMENT_TYPES_OBJECT
            && object !== constants.RELATIONS_OBJECT && object !== constants.CAMPAIGN_TYPES_OBJECT && object !== constants.RECYCLE_BIN_OBJECT && object !== constants.ROLL_UP_OBJECT) {
            document.documentElement.style.overflow = 'auto';
        }
    }*/

    const animationTop = (data) => {
        if (data !== undefined) {
            $("#scroll").animate({ scrollTop: (30 * data) + 325 + 420 });
        }
    }

    let contentHeight = document.body.clientHeight - 202;
    return (<div style={{ marginLeft: 10 }}>
        <div className="container" style={{ width: '100%' }}>
            <div style={styles.row}>
                <div style={{ width: settingViewControl.left }}>
                    {settingViewControl.isMinimize && <div style={{ width: "35px", top: "53px", marginLeft: "-7px", position: "absolute", background: "#e3e6e8", borderRadius: "50%", height: "35px" }}>
                        <button onClick={() => { minimizeSettingsMenu() }} style={{ border: "none", background: "transparent", padding: "7px 13px" }} title={settingViewControl.isMinimize ? "Expand" : "Collapse"}>
                            <i style={{ fontSize: "20px" }} className="fas fa-angle-right"></i>
                        </button>
                    </div>}
                    {!settingViewControl.isMinimize && <SettingsMenu onMenuChange={onMenuChange} selectedItem={selectedItem}
                        me={app.me}
                        isShowUpgrade={isShowUpgrade}
                        contentHeight={contentHeight}
                        checkAdminPermission={checkAdminPermission}
                        onMinimizeSettingsMenu={() => minimizeSettingsMenu()}
                    />}
                </div>

                {containerelement &&
                    <div id="scroll" style={{ width: '100%', paddingLeft: 0, maxHeight: contentHeight, overflowY: "auto" }}>
                        {containerelement}
                    </div>
                }
            </div>
        </div>
    </div>
    );
}


const SettingsMenu = ({ onMenuChange, selectedItem, me, isShowUpgrade, contentHeight, checkAdminPermission, onMinimizeSettingsMenu }) => {

    const getLink = (props) => {
        let element = null;
        let actionElement = null;
        let rootStyle = { maxHeight: 20, display: 'flex', alignItems: 'center', padding: '0px 10px' };
        let style = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' };
        let linkStyle = { fontSize: '14px', lineHeight: '32px', color: '#585757' };

        props = getObjectParam(props);

        let tempSelectedItem = getObjectParam(selectedItem);
        if (props.name === tempSelectedItem.name) {
            linkStyle.color = '#32a9e9';
        }

        let type = getStringParam(props.type);
        if (type === 'menu') {
            rootStyle.maxHeight = 30;
            rootStyle.backgroundColor = '#e3e6e8';
            element = <span style={{ ...linkStyle, fontWeight: 'bold' }} title={props.label}>{props.label}</span>;
            if (props.name === "my-setup") {
                actionElement =
                    <div style={{ width: "35px", top: "53px", marginLeft: "-15px", position: "absolute", background: "rgb(227, 230, 232)", borderRadius: "50%", height: "35px" }}>
                        <button onClick={() => { onMinimizeSettingsMenu() }} style={{ border: "none", background: "transparent", padding: "8px 10px" }} title={"Collapse"}>
                            <i style={{ fontSize: "20px" }} className="fas fa-angle-left"></i>
                        </button>
                    </div>
            }
        } else if (type === 'menu-item') {
            let linkProps = { key: props.name, to: '#', style: { cursor: 'pointer' } };
            if (isValidParam(onMenuChange)) {
                linkProps.onClick = () => onMenuChange(props);
            }
            element = <Link {...linkProps}>
                <span style={linkStyle} title={props.label}>{props.label}</span>
            </Link>;
        } else {
            rootStyle.height = 10;
        }
        element = <div style={style}> {element} </div>;
        element = <div style={rootStyle}> {element}<span>{actionElement}</span></div>;
        return element;
    }

    const getMenu = (menuItems) => {
        let menuElements = [];
        menuItems.forEach((menu, index) => {
            menuElements.push(getLink({ ...menu, id: 'menu-' + index }));
            menuElements.push(getLink());

            let items = getArrayParam(menu.items);
            items.forEach((item, itemIndex) => {
                menuElements.push(getLink({ ...item, id: 'menu-item-' + itemIndex }));
            });

            if (items.length > 0) {
                menuElements.push(getLink());
            }
        });
        return menuElements;
    }

    const getMenuItems = () => {
        let menuItems = [];
        let is_showmenu = me.is_showmenu;
        let tempIsShowUpgrade = getBooleanParam(isShowUpgrade);

        menuItems = [
            {
                id: 0,
                name: "my-setup",
                label: getLocalizedStrings().label.SETTINGS.SETUP,
                type: "menu",
                object: "my-setup",
                items: [
                    {
                        id: 0,
                        name: constants.PROFILE_DETAILS,
                        label: getLocalizedStrings().label.PROFILE_DETAILS.PROFILE_DETAILS_LABEL,
                        type: "menu-item",
                        object: constants.PROFILE_DETAILS
                    }
                    ,
                    {
                        id: 0,
                        name: constants.EMAIL_INTEGRATION_OBJECT,
                        label: getLocalizedStrings().label.SETTINGS.EMAILINTEGRATION,
                        type: "menu-item",
                        object: constants.EMAIL_INTEGRATION_OBJECT,
                    },
                    {
                        id: 0,
                        name: constants.NOTIFICATION_REMINDERS,
                        label: getLocalizedStrings().label.NOTIFICATION_REMINDERS.NOTIFICATION_REMINDERS,
                        type: "menu-item",
                        object: constants.NOTIFICATION_REMINDERS,
                    },
                    {
                        id: 0,
                        name: constants.SCHEDULER,
                        label: getLocalizedStrings().label.SCHEDULER.APPOINTMENT_SCHEDULER,
                        type: "menu-item",
                        object: constants.SCHEDULER,
                    }

                ]
            }
        ];

        let isTenant = getBooleanParam(me.is_tenant_admin);
        let isclientConnect = getBooleanParam(me.is_client_connect);
        let isCancellSubscription = getBooleanParam(me.is_cancell_subscription);
        let planCode = getStringParam(me.plan_code);
        let isInxpress = getBooleanParam(me.is_inxpress);
        let moduleAccess = getObjectParam(me.module_access);
        let CAS_plan = 3000;
        let securityLevelName = getStringParam(me.t_name);
        let is_franchisor = me.is_franchisor;
        let is_franchisee = me.is_franchisee;
        let industry_type = me.industry_type
        let phoneTextProvider = me.phone_text_provider ?? constants.NONE;
        let isLmsSetup = me.is_lms_setup;

        // COMPANY
        let company = {};
        company.id = 1;
        company.name = "company";
        company.label = getLocalizedStrings().label.SETTING_MENU.COMPANY;
        company.type = "menu";
        company.object = "company";
        company.items = [];
        menuItems.push(company);

        if (isTenant) {
            company.items.push({ id: 0, name: 'company-profile', label: getLocalizedStrings().label.CUSTOMIZATION_SETTING.COMPANY_PROFILE, type: "menu-item", object: 'company-profile' });
        }
        if(phoneTextProvider!== constants.NONE) {
            company.items.push({ id: 0, name: constants.PHONE_NUMBERS_OBJECT, label: getLocalizedStrings().label.CUSTOMIZATION_SETTING.PHONE_NUMBERS, type: "menu-item", object: constants.PHONE_NUMBERS_OBJECT });
        }
        if (isTenant || securityLevelName === 'Admin') {
            company.items.push({ id: 0, name: 'review-website', label: getLocalizedStrings().label.CUSTOMIZATION_SETTING.REVIEW_WEBSITE, type: "menu-item", object: 'review-website' });
        }

        // Users & Security
        let userSecurity = {};
        userSecurity.id = 2;
        userSecurity.name = "user-security";
        userSecurity.label = getLocalizedStrings().label.SETTING_MENU.SECURITY;
        userSecurity.type = "menu";
        userSecurity.object = "settings-security";
        userSecurity.items = [];
        menuItems.push(userSecurity);

        userSecurity.items.push({
            id: 0, name: 'users', label: getLocalizedStrings().label.SETTINGS.USERS, type: "menu-item", object: 'users',
            permissionName: 'Users', permissionType: constants.PERMISSION_TYPE_ADMINISTRATION
        });

        if(isLmsSetup){
            userSecurity.items.push({
                id: 0, name: 'lmsusers', label: getLocalizedStrings().label.SETTINGS.LMS_USERS, type: "menu-item", object: 'lmsusers',
                permissionName: 'Users', permissionType: constants.PERMISSION_TYPE_ADMINISTRATION
            });
        }

        userSecurity.items.push({
            id: 0, name: 'securityLevels', label: getLocalizedStrings().label.SECURITY_SETTING.SECURITY_LEVELS, type: "menu-item", object: 'securitylevel',
            permissionName: 'SecurityLevels', permissionType: constants.PERMISSION_TYPE_ADMINISTRATION
        });

        //Workflows & Rules
        let workflowRules = {};
        workflowRules.id = 3;
        workflowRules.name = "workflow-rules";
        workflowRules.label = getLocalizedStrings().label.SETTING_MENU.WORKFLOW_RULES;
        workflowRules.type = "menu";
        workflowRules.object = "workflow-rules";
        workflowRules.items = [];
        menuItems.push(workflowRules);

        workflowRules.items.push({
            id: 0, name: constants.WORKFLOW_OBJECT, label: getLocalizedStrings().label.CUSTOMIZATION_SETTING.WORKFLOWS, type: "menu-item", object: constants.WORKFLOW_OBJECT,
            permissionName: 'DesignWorkFlows', permissionType: constants.PERMISSION_TYPE_ADMINISTRATION
        });

        if (isTenant || getBooleanParam(checkAdminPermission)) {
            workflowRules.items.push({ id: 0, name: 'assignmentrules', label: getLocalizedStrings().label.SETTINGS.ASSIGNMENTRULES, type: "menu-item", object: 'assignmentrules' });
        }

        if (isTenant || securityLevelName === 'Admin') {
            workflowRules.items.push({ id: 0, name: constants.DUPLICATE_RULE_OBJECT, label: getLocalizedStrings().label.DUPLICATE_RULE.DUPLICATE_RULE, type: "menu-item", object: constants.DUPLICATE_RULE_OBJECT });
        }

        workflowRules.items.push({
            id: 0, name: 'leadscores', label: getLocalizedStrings().label.MARKETING_SETTING.LEAD_SCORE, type: "menu-item", object: 'leadscores',
            permissionName: 'SetupLeadScores', permissionType: constants.PERMISSION_TYPE_ADMINISTRATION
        });

        workflowRules.items.push({
            id: 0, name: constants.ROLL_UP_OBJECT, label: getLocalizedStrings().label.SETTINGS.ROLLUP, type: "menu-item", object: constants.ROLL_UP_OBJECT,
        });

        if (isTenant) {
            workflowRules.items.push({ id: 0, name: 'auto-fill-values', label: getLocalizedStrings().label.CUSTOMIZATION_SETTING.AUTO_FILL_VALUES, type: "menu-item", object: 'auto-fill-values' });
        }

        if ((!is_franchisee || (is_franchisor == true && is_franchisee == true)) && isTenant) {
            workflowRules.items.push({ id: 0, name: constants.EMAIL_TO_CASE_SETUP, label: "Email to Case Rules", type: "menu-item", object: constants.EMAIL_TO_CASE_SETUP });
        }

        // Customization
        let customization = {};
        customization.id = 4;
        customization.name = "customization";
        customization.label = getLocalizedStrings().label.SETTING_MENU.CUSTOMIZATION;
        customization.type = "menu";
        customization.object = "customization";
        customization.items = [];
        menuItems.push(customization);

        customization.items.push({
            id: 0, name: constants.DATABASE, label: getLocalizedStrings().label.CUSTOMIZATION_SETTING.DATA_BASE, type: "menu-item", object: constants.DATABASE,
            permissionName: 'DesignForms', permissionType: constants.PERMISSION_TYPE_ADMINISTRATION
        });

        customization.items.push({ id: 0, name: 'menu-configuration', label: getLocalizedStrings().label.CUSTOMIZATION_SETTING.DESKTOP_MENU, type: "menu-item", object: constants.MENU_CONFIGURATION_OBJECT });

        if (isTenant || securityLevelName === 'Admin') {
            customization.items.push({ id: 0, name: 'mobile_menu-configuration', label: getLocalizedStrings().label.CUSTOMIZATION_SETTING.MOBILE_MENU, type: "menu-item", object: constants.MOBILE_MENU_CONFIGURATION_OBJECT });
        }

        if (isTenant) {
            customization.items.push({ id: 0, name: 'whitelabellink', label: getLocalizedStrings().label.CUSTOMIZATION_SETTING.WHITE_LABEL, type: "menu-item", object: 'whitelabellink' });

        }

        
        // Lists
        let listMenu = {};
        listMenu.id = 5;
        listMenu.name = "lists";
        listMenu.label = getLocalizedStrings().label.SETTING_MENU.LISTS;
        listMenu.type = "menu";
        listMenu.object = "lists";
        listMenu.items = [];
        menuItems.push(listMenu);

        if (isTenant) {
            listMenu.items.push({ id: 0, name: 'relations', label: getLocalizedStrings().label.CUSTOMIZATION_SETTING.RELATIONS, type: "menu-item", object: 'relations' });
        }

        listMenu.items.push({
            id: 0, name: 'source', label: getLocalizedStrings().label.MARKETING_SETTING.SOURCE, type: "menu-item", object: 'source',
            permissionName: 'DesignForms', permissionType: constants.PERMISSION_TYPE_ADMINISTRATION
        });

        listMenu.items.push({ id: 0, name: 'notetypes', label: getLocalizedStrings().label.CUSTOMIZATION_SETTING.NOTE_TYPES, type: "menu-item", object: 'notetypes' });
        listMenu.items.push({ id: 0, name: 'appointmenttypes', label: getLocalizedStrings().label.SETTINGS.APPOINTMENTTYPES, type: "menu-item", object: 'appointmenttypes' });
        listMenu.items.push({ id: 0, name: 'campaigntypes', label: getLocalizedStrings().label.CUSTOMIZATION_SETTING.CAMPAIGN_TYPES, type: "menu-item", object: 'campaigntypes' });
        listMenu.items.push({
            id: 0, name: 'location', label: getLocalizedStrings().label.SCHEDULER.LOCATION, type: "menu-item", object: 'location',
            permissionName: 'DesignForms', permissionType: constants.PERMISSION_TYPE_ADMINISTRATION
        })
        listMenu.items.push({
            id: 0, name: 'taskinfo', label: getLocalizedStrings().label.SCHEDULER.TASKS, type: "menu-item", object: constants.TASKSLIST_OBJECT,
            permissionName: 'DesignForms', permissionType: constants.PERMISSION_TYPE_ADMINISTRATION
        })
        // Data
        let dataMenu = {};
        dataMenu.id = 6;
        dataMenu.name = "data";
        dataMenu.label = getLocalizedStrings().label.SETTING_MENU.DATA;
        dataMenu.type = "menu";
        dataMenu.object = "data";
        dataMenu.items = [];
        menuItems.push(dataMenu);

        dataMenu.items.push({
            id: 0, name: 'export', label: getLocalizedStrings().label.EXPORT_SETTINGS.EXPORT_HEADING, type: "menu-item", object: 'export',
            permissionName: 'ExportRecords', permissionType: constants.PERMISSION_TYPE_OPERATIONS
        });

        if (isTenant) {
            dataMenu.items.push({ id: 0, name: constants.RECYCLE_BIN_OBJECT, label: getLocalizedStrings().label.SETTINGS.RECYCLEBIN, type: "menu-item", object: constants.RECYCLE_BIN_OBJECT });
        }
        dataMenu.items.push({ id: 0, name: constants.FIND_DUPLICATE_OBJECT, label: getLocalizedStrings().label.FIND_DUPLICATES.FIND_DUPLICATES, type: "menu-item", object: constants.FIND_DUPLICATE_OBJECT });
        
        //Company Scheduler
        let SchedulerMenu = new Object();
        SchedulerMenu.id = 6;
        SchedulerMenu.name = "company-scheduler";
        SchedulerMenu.label = getLocalizedStrings().label.SETTING_MENU.COMPANY_SCHEDULER;
        SchedulerMenu.type = "menu";
        SchedulerMenu.object = "company-scheduler";
        SchedulerMenu.items = [];
        menuItems.push(SchedulerMenu);

        SchedulerMenu.items.push({ id: 0, name: constants.SCHEDULER_AVAILABILITY, label: getLocalizedStrings().label.SCHEDULER.AVAILABILITY, type: "menu-item", object: constants.SCHEDULER_AVAILABILITY });
        SchedulerMenu.items.push({ id: 0, name: constants.SCHEDULER_APPOINTMENT_TYPES, label: getLocalizedStrings().label.SCHEDULER.APPOINTMENT_TYPES, type: "menu-item", object: constants.SCHEDULER_APPOINTMENT_TYPES });
        SchedulerMenu.items.push({ id: 0, name: constants.SCHEDULER_INTAKE_FORM, label: getLocalizedStrings().label.SCHEDULER.INTAKE_FORM, type: "menu-item", object: constants.SCHEDULER_INTAKE_FORM });
        SchedulerMenu.items.push({ id: 0, name: constants.SCHEDULER_LINK, label: getLocalizedStrings().label.SCHEDULER.SCHEDULER_LINK, type: "menu-item", object: constants.SCHEDULER_LINK });

        // Franchise
        if (is_franchisor === true || is_franchisee === true) {
            let franchise = {};
            franchise.id = 7;
            franchise.name = "franchise";
            franchise.label = getLocalizedStrings().label.SETTING_MENU.FRANCHISE;
            franchise.type = "menu";
            franchise.object = "franchise";
            franchise.items = [];
            menuItems.push(franchise);

            if ((industry_type === "Franchisor" || industry_type === "Unit Base" || industry_type === "Technology") && is_franchisor ==  true) {
                franchise.items.push({ id: 0, name: 'royalty_setup', label: getLocalizedStrings().label.COMMON.ROYALTY_SETUP, type: "menu-item", object: constants.ROYALTY_SETUP_OBJECT });
            }
            if(is_franchisor === true){
                franchise.items.push({ id: 0, name: 'unit_crm', label: getLocalizedStrings().label.COMMON.UNIT_CRM, type: "menu-item", object: constants.UNIT_CRM_OBJECT });
            }
            if (securityLevelName === 'Admin' && is_franchisor === true) {
                franchise.items.push({ id: 2, name: 'audit_questions', label: getLocalizedStrings().label.AUDIT_QUESTIONS.AUDIT_QUESTIONS, type: "menu-item", object: constants.AUDIT_QUESTIONS_OBJECT });
            }
            if (is_franchisor === true) {
                /*let isOperations = moduleAccess[constants.MODULE_OPERATIONS];
                if (isTenant && isOperations === 1) {
                    franchise.items.push({ id: 0, name: 'hubsettings', label: getLocalizedStrings().label.CUSTOMIZATION_SETTING.HUB_SETTING, type: "menu-item", object: 'hubsettings' });
                }*/

                franchise.items.push({ id: 0, name: 'compliance', label: getLocalizedStrings().label.SCHEDULER.NOTIFICATION_SETUP, type: "menu-item", object: constants.COMPLIANCE_SETUP });

                franchise.items.push({ id: 0, name: 'lms', label: getLocalizedStrings().label.COMMON.LMS_SETUP, type: "menu-item", object: constants.LMS_SETUP});

            }

            if (is_franchisee === true) {
                franchise.items.push({ id: 0, name: 'reported_sales_setup', label: 'Reported Sales', type: "menu-item", object: constants.REPORTED_SALES_SETUP });
            }


        }

        // Integration
        let integration = {};
        integration.id = 8;
        integration.name = "integration";
        integration.label = getLocalizedStrings().label.COMMON.INTEGRATION_TITLE;
        integration.type = "menu";
        integration.object = "integration";
        integration.items = [];
        menuItems.push(integration);

        integration.items.push({ id: 0, name: constants.GENERATE_API_KEY_OBJECT, label: getLocalizedStrings().label.CUSTOMIZATION_SETTING.API_KEY, type: "menu-item", object: constants.GENERATE_API_KEY_OBJECT });

        if (isTenant && !isCancellSubscription) {
            let subscription = {};
            subscription.id = 0;
            subscription.name = "subscription";
            subscription.label = getLocalizedStrings().label.SETTINGS.SUBSCRIPTION;
            subscription.type = "menu";
            subscription.object = "subscription";
            subscription.items = [];
            menuItems.push(subscription);

            let is_recurly = true;
            if (isInxpress) {
                is_recurly = false;
            } else if (planCode === CAS_plan) {
                is_recurly = false;
            }

            if (is_recurly) {
                if (tempIsShowUpgrade && planCode === constants.RECURLY_PLAN_CODE) {
                    subscription.items.push({ id: 0, name: "upgrade-subscription", label: getLocalizedStrings().label.SETTINGS.UPGRADE, type: "menu-item", object: "upgrade-subscription" });
                }

                if (planCode !== constants.RECURLY_PLAN_CODE) {
                    subscription.items.push({ id: 0, name: "purchase-products", label: getLocalizedStrings().label.SETTINGS.BUYPRODUCT, type: "menu-item", object: "purchase-products" });
                    subscription.items.push({ id: 0, name: "purchase-professional", label: getLocalizedStrings().label.SETTINGS.BUYPROSERVICE, type: "menu-item", object: "purchase-professional" });
                    subscription.items.push({ id: 0, name: "billing-invoice", label: getLocalizedStrings().label.SETTINGS.BILLING, type: "menu-item", object: "billing-invoice" });
                    //subscription.items.push({ id: 0, name: "cancel-subscription", label: getLocalizedStrings().label.SETTINGS.CANCELSUB, type: "menu-item", object: "cancel-subscription" });
                }
            } else {
                if (planCode !== constants.RECURLY_PLAN_CODE) {
                    //subscription.items.push({ id: 0, name: "cancel-subscription", label: getLocalizedStrings().label.SETTINGS.CANCELSUB, type: "menu-item", object: "cancel-subscription" });
                    //subscription.items.push({ id: 0, name: "subscription-details", label: getLocalizedStrings().label.SETTINGS.SUBDETAIL, type: "menu-item", object: "subscription-details" });
                    subscription.items.push({ id: 0, name: "purchase-email-credits", label: getLocalizedStrings().label.SETTINGS.PURCHESEMAILCREDIT, type: "menu-item", object: "purchase-email-credits" });
                    subscription.items.push({ id: 0, name: "purchase-phone-credits", label: getLocalizedStrings().label.SETTINGS.PURCHESPHONECREDIT, type: "menu-item", object: "purchase-phone-credits" });
                    subscription.items.push({ id: 0, name: "purchase-sms-credits", label: getLocalizedStrings().label.SETTINGS.PURCHESSMSCREDIT, type: "menu-item", object: "purchase-sms-credits" });
                    subscription.items.push({ id: 0, name: "purchase-card-scanning-credits", label: getLocalizedStrings().label.SETTINGS.PURCHESCARDCREDIT, type: "menu-item", object: "purchase-card-scanning-credits" });
                    subscription.items.push({ id: 0, name: "purchase-contacts", label: getLocalizedStrings().label.SETTINGS.PURCHESCONTACTCREDIT, type: "menu-item", object: "purchase-contacts" });
                    subscription.items.push({ id: 0, name: "purchase-training", label: getLocalizedStrings().label.SETTINGS.PURCHESTRANINGCREDIT, type: "menu-item", object: "purchase-training" });
                    subscription.items.push({ id: 0, name: "purchase-marketing", label: getLocalizedStrings().label.SETTINGS.PURCHESMERKETINGSERVICE, type: "menu-item", object: "purchase-marketing" });
                    subscription.items.push({ id: 0, name: "purchase-professional", label: getLocalizedStrings().label.SETTINGS.PURCHESPROFESSONALSERVICE, type: "menu-item", object: "purchase-professional" });
                }
            }
        }
        return menuItems;
    }

    const showDivScroll = (id) => {
        document.getElementById(id).style.overflowY = 'auto';
    }

    const hideDivScroll = (id) => {
        document.getElementById(id).style.overflowY = 'hidden';
    }

    let _contentHeight = document.body.clientHeight - 202;

    return (<div
        id="settings-menu"
        onMouseOver={() => { showDivScroll('settings-menu') }}
        onMouseOut={() => { hideDivScroll('settings-menu') }}
        style={{ paddingRight: 0, maxHeight: _contentHeight, overflowY: 'hidden' }}>
        {getMenu(getMenuItems())}
    </div>
    );
}

export default ModuleSetting;

