let themeColor = localStorage.getItem("background");
let emailbtnbackground =
	themeColor + " url(/asset/images/button_seperator.jpg)";

export let styles = {
	row: {
		display: "flex",
		padding: "1 10 1 10",
	},
	sfrow: {
		marginRight: "-15px",
		marginLeft: "-15px",
		display: "table",
		content: " ",
		width: "100%",
	},
	sf_clm_manage: {
		position: "relative",
		// paddingLeft: 15,
		// paddingRight: 15,
	},
	sf_1: {
		width: "10%",
	},
	sf_1o5: {
		width: "15%",
	},
	sf_2: {
		width: "20%",
	},
	sf_3: {
		width: "25%",
	},
	sf_4: {
		width: "33.33%",
	},
	sf_5: {
		width: "40%",
	},
	sf_6: {
		width: "50%",
	},
	sf_7: {
		width: "60%",
	},
	sf_8: {
		width: "66.66%",
	},
	sf_9: {
		width: "73%",
		marginLeft: "51px",
	},
	sf_10: {
		width: "80%",
	},
	sf_11: {
		width: "90%",
	},
	sf_12: {
		width: "100%",
	},
	sf_p30: {
		width: "30%",
	},
	sf_p70: {
		width: "70%",
	},
	title: {
		cursor: "pointer",
		paddingLeft: "10px",
		color: "#fff",
	},
	appBar: {
		flexWrap: "wrap",
		color: "#191919",
	},
	bg_white: {
		background: "#fff",
	},
	tabs: {
		width: "100%",
		paddingTop: "10px",
		backgroundColor: "#ffffff",
		display: "inline-block",
	},
	tabitem: {
		marginTop: "0px",
		paddingLeft: "30px",
		paddingRight: "30px",
		backgroundColor: "#ffffff",
		color: "#191919",
	},
	header: {
		height: "60px",
	},
	logo: {
		float: "left",
		padding: "20px",
	},
	search: {},
	profile: {
		float: "right",
	},
	sfAlertAction: {
		float: "right",
		padding:'20px 0px 0px 0px',
		marginBottom:'-15px'
	},

	dashboardheaderstyle: {
		margin: "0 0px 0 0px",
	},

	headerdropstyle: {
		margin: "0 20px 0 20px",
		padding: '10px 0px 10px 0px',
		width: "15%",
	},

	removeStyle: {
		position: "absolute",
		right: "2px",
		top: 0,
		cursor: "pointer",
		padding: "10px",
	},

	headerStyle: {
		padding: "25px 10px 12px 20px",
		fontWeight: "bold",
		cursor: "move",
	},

	bodyStyle: {
		padding: "10px 25px 0 25px",
		margin: "15px 0px 0px 0px",
		overflowY: "auto",
		height: "80%",
		width: "100%",
	},

	contentDivStyle: {
		padding: '30px 0 0 0'
	},

	timelineDivStyle: {
		padding: "0px 0 10px 0",
		// width: '30%'
	},

	container: {
		margin: "20px",
	},

	processDiv: {
		position: "fixed",
		top: "120px",
	},

	loaderStyle: {
		marginTop: "22px",
	},

	loaderText: {
		fontWeight: "bold",
		fontSize: "12px",
		textAlign: "center",
	},

	loaderContainer: {
		textAlign: "center",
	},
	assetLoaderContainer: {
		height: "100px",
		width: "100px",
		boxShadow: "0px 0px 15px #ddd",
		background: "#ffff",
		borderRadius: "50%",
		padding: "25px",
		color: "rgb(107 106 106)",
	},
	styleThead: {
		paddingLeft: "9px",
	},

	styleTd: {
		maxWidth: "100px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},

	loginCopyRight: {
		paddingTop: "30px",
		font: "11px arial",
		color: "#878787",
		align: "center",
	},

	listViewHeaderContainer: {
		width: "100%",
		marginTop: "16px",
		paddingBottom: "7px",
	},

	loginError: {
		paddingLeft: "20px",
		font: "16px arial",
		color: "#FF0000",
	},

	listViewSearch: {
		paddingTop: "10px",
		align: "right",
	},
	listViewHeaderFilter: {
		height: "40px",
		textAlign: "left",
		color: "#717171",
		paddingTop: "4px",
		paddingLeft: "15px",
		fontSize: "24px",
	},
	listViewFilterBox: {
		textAlign: "left",
		paddingLeft: "20px",
		whiteSpace: "nowrap",
		cursor: "pointer",
	},
	autocompleteChip: {
		color: "rgba(0, 0, 0, 0.87)",
		border: "none",
		cursor: "default",
		height: "26px",
		display: "inline-flex",
		outline: "0",
		padding: "0",
		fontSize: "1.1818181818181819rem",
		boxSizing: "border-box",
		transition:
			"background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		alignItems: "center",
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		whiteSpace: "nowrap",
		borderRadius: "6px",
		verticalAlign: "middle",
		justifyContent: "center",
		textDecoration: "none",
		backgroundColor: "#e0e0e0",
		//   width: "93px"
	},

	customTimeHolder: {
		display: "flex",
		width: " 100%",
		marginBottom: "10px",
	},
	customTimeInput: {
		width: "45%",
		height: 40,
		display: "inline-block",
		position: "relative",
		backgroundColor: "rgb(255, 255, 255)",
		fontFamily: "Arial",
		border: "1px solid rgb(221, 221, 221)",
		fontSize: "16px",
		textAlign: "center",
	},
	customTimeInputLebel: {
		// position: 'absolute',
		color: "rgb(113, 113, 113)",
		fontWeight: "normal",
		fontSize: "16px",
		whiteSpace: "nowrap",
	},

	customInputHolder: {
		width: "100%",
		height: 35,
		display: "inline-block",
		position: "relative",
		backgroundColor: "rgb(255, 255, 255)",
		fontFamily: "Arial",
		border: "1px solid rgb(221, 221, 221)",
		paddingLeft: 7,
		marginBottom: 12,
		borderRadius: "4px",
	},
	customInput: {
		width: "100%",
		border: "none",
		outline: "none",
		backgroundColor: "rgba(0, 0, 0, 0)",
		color: "rgb(85, 85, 85)",
		cursor: "inherit",
		fontStyle: "inherit",
		fontVariant: "inherit",
		fontWeight: "normal",
		fontStretch: "inherit",
		fontSize: 14,
		lineHeight: "inherit",
		fontFamily: "inherit",
		paddingTop:'9px'
	},
	inputLabel: {
		color: "rgb(153, 153, 153)",
		fontWeight: "normal",
		fontSize: 12,
		lineHeight: 0.1,
		background: "inherit",
		marginTop: "-1px",
		padding: "0px 7px 0px 5px",
	},
	primaryButton: {
		backgroundColor: themeColor,
		color: "#ffffff",
		border: "solid 1px #d3d6d6",
		marginRight: "10px",
		zIndex: "0",
		height: "28px",
		//width:'20px',
		lineHeight: "28px",
	},
	primaryButton1:{
		backgroundColor: themeColor,
		color: "#ffffff",
		border: "solid 1px #d3d6d6",
		marginRight: "10px",
		zIndex: "0",
		height: "28px",
		lineHeight: "28px",
	},
	primaryButton2:{
		color: "#ffffff",
		border: "solid 1px #d3d6d6",
		marginRight: "10px",
		zIndex: "0",
		height: "28px",
		lineHeight: "28px",
	},
	disabled: {
		backgroundColor: "#ccc",
		color: "#ffffff",
		border: "solid 1px #ffffff",
		marginRight: "10px",
		zIndex: "0",
		height: "28px",
		lineHeight: "28px",
	},
	disabledButton: {
		backgroundColor: "#ccc",
		color: "#ffffff",
		border: "solid 1px #ffffff",
		marginRight: "10px",
		zIndex: "0",
		height: "40px",
		lineHeight: "40px",
	},
	successButton: {
		backgroundColor: "#399613",
		color: "#ffffff",
		border: "solid 1px #d3d6d6",
		marginRight: "10px",
		zIndex: "0",
		height: "28px",
		lineHeight: "28px",
	},
	primaryDisabledButton: {
		backgroundColor: themeColor,
		color: "#ffffff",
		border: "solid 1px #d3d6d6",
		marginRight: "10px",
		zIndex: "0",
		height: "28px",
		lineHeight: "28px",
	},
	secondaryButton: {
		backgroundColor: "#ffffff",
		color: "#717171",
		border: "solid 1px #e0e0e0",
		marginRight: "5px",
		zIndex: "0",
		height: "28px",
		width:'auto',
		lineHeight: "28px",
	},
	secondaryButton1: {
		backgroundColor: "#ffffff",
		color: "#717171",
		border: "solid 1px #e0e0e0",
		marginRight: "5px",
		zIndex: "0",
		height: "28px",
		width:'148px',
		lineHeight: "28px",
	},
	secondaryButton2: {
		backgroundColor: "#ffffff",
		color: "#717171",
		border: "solid 1px #e0e0e0",
		zIndex: "0",
		height: "28px",
		width:'auto',
		lineHeight: "28px",
	},
	changeBtn:{
		backgroundColor: "#ffffff",
		color: "#717171",
		border: "solid 1px #e0e0e0",
		marginRight: "5px",
		zIndex: "0",
		height: "28px",
		width:'90px',
		lineHeight: "28px",
	},
	iconStyle: {
		backgroundColor: "#ffffff",
		color: "#717171",
		border: "solid 1px #e0e0e0",
		marginRight: "5px",
		zIndex: "0",
		fontSize: "19px",
		padding: "8px",
	},
	togButton: {
		float: "left",
		marginRight: "4px",
		cursor: "pointer",
		borderRadius: "4px",
	},
	listViewPrimaryButton: {
		backgroundColor: themeColor,
		color: "#ffffff",
		marginRight: "5px",
		borderRadius: "4px",
		height: "28px",
		lineHeight: "28px",
		marginTop: "2px",
		padding: "6px 14px",
	},
	textField: {
		underlineFocus: {
			borderBottomColor: "#717171",
		},
		floatingLabel: {
			color: "#999999",
			fontWeight: "normal",
			fontSize: "16px",
		},
		floatingLabelShrink: {
			color: "#999999",
			fontWeight: "bold",
			fontSize: "16px",
		},
		floatingLabelFocus: {
			color: "#999999",
			fontWeight: "normal",
			fontSize: "16px",
		},
		input: {
			color: "#555555",
			fontWeight: "normal",
			fontSize: "14px",
			marginTop: 4,
		},
	},
	popoverMenuItem: {
		fontSize: "14px",
		paddingLeft: "24px",
		color: "#666666",
	},
	checkbox: {
		color: "#717171",
		fontWeight: "normal",
		fontSize: "12px",
	},
	text: {
		color: "#717171",
		fontWeight: "normal",
		fontSize: "12px",
		width: "150px",
		marginLeft: "-8px",
		wordBreak: "break-all",
	},
	text1: {
		color: "#717171",
		fontWeight: "normal",
		fontSize: "12px",
		marginLeft: "-8px",
		wordBreak: "break-all",
	},
	searchbox: {
		backgroundColor: "#fff",
		border: "1px solid #dddddd",
		paddingLeft: "7px",
		marginBottom: "12px",
		height: "42px",
		width: "100%",
		cursor: "pointer",
		color: "#717171",
		position: "relative",
	},
	datePicker: {
		color: "#717171",
		fontWeight: "normal",
		fontSize: "14px",
		height: "72px",
		width: "100%",
	},
	selectField: {
		color: "#717171",
		fontWeight: "normal",
		fontSize: "14px",
		width: "100%",
	},
	filterMenuHeaderStyle: {
		paddingBottom: "22px",
		fontSize: "20px",
		color: "#555",
		marginLeft: "10px",
	},
	secondaryTextStyle: {
		color: "#717171",
		paddingLeft: "0px",
		fontWeight: "normal",
	},
	radioBtnLabel: {
		color: "#717171",
		fontSize: "14px;",
		fontWeight: "normal",
	},
	addNew: {
		fontSize: "12px",
		fontWeight: "bold",
		marginLeft: "10px",
		color: "#039be5",
	},
	addFilterIcon: {
		float: "right",
		paddingRight: "26px",
		paddingTop: "3px",
		cursor: "pointer",
	},
	addGroupIcon: {
		float: "right",
		paddingRight: "22px",
		paddingTop: "3px",
		cursor: "pointer",
	},
	searchFilterIcon: {
		paddingRight: "13px",
		paddingTop: "3px",
		paddingLeft: "50px",
		float: "right",
	},
	searchGroupIcon: {
		paddingRight: "13px",
		paddingTop: "3px",
		paddingLeft: "38px",
		float: "right",
	},
	workAreaContainer: {
		width: "100%",
		paddingBottom: "7px",
		//overflow: 'hidden'
	},

	attachmentHeader: {
		margin: "0px",
		padding: "5px 15px 5px",
		color: "#f5f5f5",
		//fontSize: '14px',
		//  lineHeight: '14px',
		fontWeight: "bold",
	},

	uploadInput: {
		//cursor: 'pointer',
		position: "absolute",
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		width: "100%",
		opacity: 0,
	},

	taskAppHeadingStyle: {
		color: "#717171",
		fontSize: "17px",
		borderBottom: "1px solid #ccc",
		marginBottom: "10px",
	},

	detailViewAddTaskAppHeadingStyle: {
		color: "#717171",
		lineHeight: "35px",
	},

	detailViewTaskAppIconStyle: {
		fontSize: "20px",
		paddingRight: "6px",
	},

	detailViewTaskAppIconListIconStyle: {
		color: "#717171",
		fontSize: "20px",
	},

	detailViewTaskAppDateStyle: {
		paddingLeft: "6px",
		cursor: "pointer",
		fontStyle: "italic",
		color: "#717171",
	},

	detailViewTaskAppNameStyle: {
		color: "#717171",
		width: "96%", //250px
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		lineHeight: "19px",
	},

	detailViewTaskAppLinkStyle: {
		color: "#b5b5b5",
		width: "250px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		lineHeight: "19px",
	},

	detailViewTaskAppDetailStyle: {
		color: "#b5b5b5",
		lineHeight: "26px",
		width: "215px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},

	detailViewTaskAppCompleteScheduleIconStyle: {
		paddingRight: "9px",
		cursor: "pointer",
	},

	childTabsStyles: {
		color: "#717171",
		lineHeight: "32px",
		borderBottom: "2px solid #479CCF",
		width: "11%",
		display: "inline",
	},

	iconMarginForOpenMenu: {
		marginLeft: "14px",
		marginTop: "-3px",
		fontSize: "1.5em",
		color: "#E0E0E0",
	},

	iconMarginForCloseMenu: {
		paddingLeft: "2px",
		paddingBottom: "30px",
		marginLeft: "14px",
		marginTop: "5px",
		fontSize: "1.5em",
		color: "#E0E0E0",
		top: "5px",
	},

	detailViewFieldsRecordsWithLabel: {
		fieldsLabel: {
			color: "#b5b5b5",
			// paddingBottom: '4px',
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			overflow: "hidden",
			fontSize: "13px",
			height:'20px'
		},

		fieldsRecord: {
			color: "#626464",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			overflow: "hidden",
			width: "190px",
		},

		parentDivStyle: {
			paddingBottom: "12px",
		},
		noFieldsLabel: {
			color: "#b5b5b5",
			// paddingBottom: '4px',
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			overflow: "hidden",
			fontSize: "13px",
		},
	},

	detailViewFieldsRecordsWithoutLabel: {
		fieldsLabel: {
			color: "#b5b5b5",
			lineHeight: "38px",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			overflow: "hidden",
			width: "140px",
		},

		fieldsRecord: {
			color: "#626464",
			lineHeight: "38px",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			overflow: "hidden",
			width: "140px",
		},
	},

	customizedFieldsHeaderDiv: {
		color: "#717171",
		fontSize: "17px",
	},

	customizedFieldsHeader: {
		float: "left",
	},

	customizedFieldsHeaderIcon: {
		float: "right",
		cursor: "pointer",
	},

	customizedFieldsContainerDiv: {
		padding: "10px 10px 10px 10px",
	},

	customizedFieldsNotify: {
		paddingTop: "30px",
		color: "#B5B5B5",
		fontSize: "12px",
		paddingBottom: "10px",
	},

	columnViewSetupInfo: {
		paddingTop: "10px",
		color: "#717171",
		fontSize: "12px",
	},

	listViewHeaderButtonStyle: {
		float: "left",
	},

	inlineRefreshDivContainer: {
		position: "relative",
		minHeight: "300px",
	},

	inlineRefresh: {
		display: "block",
		position: "absolute",
		left: "50%",
		top: "50%",
	},

	underlinestyle: {
		borderColor: "#b5b5b5",
	},

	hintTextStyle: {
		color: "#626464",
	},

	automationIcon: {
		fontSize: "1.21em",
		color: "#FFFFFF",
		cursor: "pointer",
	},

	homePageSettingIcon: {
		// padding: '15px 10px',
		color: "#8c8c8c",
		cursor: "pointer",
		display:'grid',
		height:50
	},
	homePageHelpIcon: {
		// padding: '15px 10px',
		color: "#717171",
		cursor: "pointer",
		display:'grid',
		height:50
	},

	noRecordOuterDiv: {
		borderRadius: "10px",
		color: "#F37736",
		padding: "18px",
		margin: "10px auto 0",
		width: "50%",
		minHeight: "300px",
	},

	noRecordDiv: {
		backgroundColor: "#f1f1f1",
		border: "1px solid #95c2ef",
		boxShadow: "2px 2px 2px #aaaaaa",
		borderRadius: "10px",
		padding: "20px",
		margin: "5 % auto 0",
		width: "55 %",
		font: "normal 12px Arial, Helvetica, sans - serif",
		color: "#454545",
	},

	automationActionsHeadings: {
		fontSize: "16px",
		fontWeight: "bold",
		color: "#717171",
		paddingBottom: "7px",
		position: "relative",
	},

	automationDiagramStyle: {
		position: "relative",
		verticalAlign: "top",
		paddingLeft: "10px",
		paddingTop: "10px",
		overflow: "auto",
		width: "calc(100% - 10px)",
		height: "400px",
		display: 'inline-block',
		marginLeft: '10px',
		marginTop: '10px'
	},
	automationDiagramContainerStyle: {
		position: "relative",
		display: "inline-block",
		verticalAlign: "top",
		marginLeft: "10px",
		marginTop: "10px",
		marginRight: '15px',
		width: "auto",
		height: "80%",
		overflow: "auto",
		backgroundImage:
			"url(http://srv1.snapshotcrm.com:80/theme/blue/images/MarketingBuilder/background.png)",
	},
	automationSidebarActionsStyle: {
		display: "inline-block",
		paddingRight: "10px",
		paddingTop: "10px",
	},

	automationDesignerDecisionNo: {
		float: "left",
		color: "#ee5a00",
		cursor: "pointer",
		paddingLeft: "7px",
		paddingTop: "2px",
		fontWeight: "bold",
	},

	automationDesignerDecisionYes: {
		float: "right",
		color: "#028744",
		cursor: "pointer",
		paddingRight: "7px",
		paddingTop: "2px",
		fontWeight: "bold",
	},
	/********* START:   SF-FORM  *************************/

	sfFormContainer: {
		width: "89%",
		marginTop: "20px",
		marginLeft: "23px",
		paddingRight: "0",
		color: "#717171",
		height: "100%",
	},

	requiredFormDialog: {
		width: "89%",
		marginTop: "20px",
		marginLeft: "23px",
		paddingRight: "0",
		color: "#717171",
		height: "auto",
	},
	/********* END:   SF-FORM  *************************/

	/********* START: automation info tooltip style *********/
	automationTooltipStyle: {
		paddingLeft: "10px",
		paddingRight: "10px",
	},
	/********* END: automation info tooltip style *********/

	radioButtonLabelStyle: {
		marginLeft: "5px",
		color: "#717171",
		fontSize: "14px",
		fontWeight: "normal",
		width: "auto",
		height: "auto",
	},

	snackbarBodyStyleSuccess: {
		backgroundColor: "#32a84f",
		opacity: 1,
		color: "white",
	},
	snackbarBodyStyleError: {
		backgroundColor: "#d32f2f",
		color: "white",
	},
	snackBarStyleTop: {
		top: 0,
		transform: "translate3d(0px, 98px, 0px)",
		buttom: "auto",
		left: (window.innerWidth - 288) / 2,
		animationName: "slidedown",
		animationDuration: "0.5s",
	},
	snackBarStyleLongMsg: {
		top: 0,
		transform: "translate3d(0px, 98px, 0px)",
		buttom: "auto",
		left: (window.innerWidth - 588) / 2,
		animationName: "slidedown",
		animationDuration: "0.5s",
	},
	imageInput: {
		cursor: "pointer",
		position: "absolute",
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		width: "100%",
		opacity: 0,
	},
	emailSendBtn1: {
		color: "#fff",
		padding: "5px 10px",
		marginRight: "4px",
		cursor: "pointer",
		height: "32px",
		width: "182px",
		border: "1px solid rgb(224, 224, 224)",
		background: emailbtnbackground,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "right:40px",
		backgroundPositionX: 151,
		textTransform: "uppercase",
	},
	emailSendBtn: {
		color: "rgb(255, 255, 255)",
		marginRight: 4,
		cursor: "pointer",
		height: 32,
		border: "1px solid rgb(224, 224, 224)",
		background: themeColor,
		textTransform: "uppercase",
		display: 'flex'
	},
	chevron: {
		cursor: "pointer",
		height: "40",
		color: "#b5b5b5",
		fontSize: "16px",
		padding: "10px 5px",
	},
	formFldLabel: {
		// margin: '5px 0px',
		// padding: '12px 0px',
		// textAlign: 'right',
		fontSize: "15px",
		fontFamily: "Roboto",
		placeSelf: "center right",
		paddingRight: "15px",
	},
	formHeaderLabel: {
		padding: "5px",
		fontSize: "24px",
		color: "#555",
	},
	popperStyle : {
		boxShadow: '0 3px 12px rgb(149 147 147 / 61%)',
		borderRadius: 2,
		zIndex: 1300,
		fontSize: 13,
		color: '#586069',
		backgroundColor: '#fff',
		bottom: 'auto',
		width: '50%'
	},
	downloadableLink: {
		cursor: 'pointer',
		color: '#0356b3'
	}
};
