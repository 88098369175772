import { actions } from '..//constants/actions';
import { getArrayParam} from '..//helper/parameterVerifier';

function setTabs(tabs, activeTabIndex) {
    return function (dispatch) {
        dispatch({
            type: actions.APP_SET_TAB,
            payload: {
                tabs: tabs,
                activeTabIndex: activeTabIndex
            }
        })
    }
}

function setSFTabs(tabs) {
    tabs = getArrayParam(tabs);
    return function (dispatch) {
        dispatch({
            type: actions.APP_SET_TAB,
            payload: {
                tabs: tabs
            }
        })
    }
}

function setSelectedTab(tabIndex) {
    return function (dispatch) {
        dispatch({
            type: actions.APP_SET_SELECTED_TAB,
            payload: tabIndex
        })
    }
}

function setTabDataBackFRLogin(data) {
    return function (dispatch) {
        dispatch({
            type: actions.APP_SET_BACK_FR_LOGIN_TAB,
            payload: {
                tabs: data.tabs,
                rememberTab: data.rememberTab,
            }
        })
    }
}

export {
    setTabs,
    setSelectedTab,
    setSFTabs,
    setTabDataBackFRLogin
}
