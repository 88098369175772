import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import $ from 'jquery';
import Popover from '@mui/material/Popover';
import { Icon, Menu, MenuItem } from '@mui/material';
import * as sfDialogs from '../components/sfDialogs';
import { constants, OBJECT_TABLEID_MAP, TABLEID_OBJECT_MAP } from '../../../services/constants/constants';
import { styles } from '../../../services/constants/styles';
import * as ParameterVerifier from '../../../services/helper/parameterVerifier';
import { getArrayParam, getBooleanParam, getFloatParam, getIntParam, getObjectParam, getStringParam, isInvalidParam, isValidParam, makeValidAppUrl } from '../../../services/helper/parameterVerifier';
import { getAppCustomDrawer, getAppDrawer } from '../../../services/actions/appContainerActions';
import { deleteAction, reschedule } from '../../../services/actions/batchActions';
import { dateFormat, generateLeadScoreIcon, sortArrayObjectByProperty } from '../../../services/helper/utils';
import { getLookupRecordInfo, isLinkedRecord, isWorkflowExists, setListViewMounted, unsubscribeContacts } from '../../../services/actions/listViewAcions';
import { Link } from 'react-router-dom';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { addNewFormTabs, addTab, getActiveTab, getActiveTabInfo, TYPE_DETAIL_VIEW } from '../../../services/helper/sfTabManager';
import { getCampaignTypeNames, getObjectLabelByObject, getSelectedObjectLabel, hasAccessPermission, isObjectChildExists } from '../../../services/helper/common';
import { getForeCastReportData, getLists, getTaskLists, refreshColumnView } from '../../../services/actions/listsActions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import moment from 'moment';
import { FORM_FIELD_TYPE } from '../../../services/helper/formValidator';
import { LinkToType } from '../../../services/constants/link';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getTasksRefresh } from '../../../services/actions/taskActions';
import * as SFFormActions from '../../../services/actions/sfFormActions';
import { getCallDrawer } from '../../../services/actions/callAction';
import { NavigateBeforeRounded } from '@mui/icons-material';
import ColumnViewTaskApps from "../components/columnViewTaskApps";

const propTypes = {
	item: PropTypes.object.isRequired,
	style: PropTypes.object
};


const Card = ({ item, cardType, object, parentIndex, updateCards, totalCardsCount, status, fields, style }) => {
	const inputRef = useRef(null);
	const dispatch = useDispatch();

	const [openActionList, setOpenActionList] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [editLinkUrl, setEditLinkUrl] = useState(null);
	const [recordId, setRecordId] = useState(null);
	const [parentIndx, setParentIndx] = useState(null);
	const [isTwilioPopOverOpen, setIsTwilioPopOverOpen] = useState(false);
	const [isTaskDetails, setIsTaskDetails] = useState(false);
	const [close, setClose] = useState(0);
	const [open, setOpen] = useState(0);
	const [taskRecords, setTaskRecords] = useState([]);
	const [countTask, setCountTask] = useState(0);
	const [clickOnStatus, setClickOnStatus] = useState('');
	const [redirectUrl, setRedirectUrl] = useState('');
	const [redirect, setRedirect] = useState(false);
	const [actionsList, setAactionsList] = useState([]);
	let actions = useSelector((state) => state.lists.actions);
	let tab = useSelector((state) => state.tab);
	let totalLeadScore = useSelector((state) => state.lists.totalLeadScore);
	let lists = useSelector((state) => object === constants.TASKS ? state.lists.taskLists : state.lists.lists);
	let app = useSelector((state) => state.app);
	let userlist = useSelector((state) => state.userlist);
	let task = useSelector((state) => state.task);
	let appointment = useSelector((state) => state.appointment);
	let taskAppData = useSelector((state) => state.detailViewTaskAppintmentReducer.taskAppointment);
	let communicationFields = useSelector((state) => state.lists.communicationFields);
	let appContainer = useSelector((state) => state.appContainer);
	let convertDrawer = useSelector((state) => state.appContainer.customDrawerProps);
	const header = useSelector((state) => state.header);

	const generateActionsList = () => {
		let actionItems = [];
		if (object === constants.OPPORTUNITIES_OBJECT && (cardType === null || cardType === undefined)) {
			let tenpActionsList = [{ action_name: 'Convert' }, { action_name: 'Edit' }, { action_name: 'Delete' }];
			tenpActionsList.forEach((actionItem, i) => {
				let actionLabel = actionItem.action_name;
				actionLabel = isValidParam(getLocalizedStrings().label.KANBAN_REPORT[actionLabel]) ? getLocalizedStrings().label.KANBAN_REPORT[actionLabel] : actionLabel;
				actionItems.push(
					<MenuItem
						key={actionItem.action_name}
						value={actionItem.action_name}
						style={styles.popoverMenuItem}
						fullWidth={true}
						onClick={(e) => handleAction({ 'id': item.id, 'title': item.columnName, 'object': object, 'parentIndex': parentIndex }, actionItem.action_name, e)}
						labelstyle={{ color: '#479ccf' }}
						underlinestyle={{ display: 'none' }}
						autowidth={"true"}
					>{actionLabel}</MenuItem>);
			});

		} else if (object === constants.TASKS) {
			let tenpActionsList = [{ action_name: 'complete' }, { action_name: 'nextday' }, { action_name: 'nextweek' }, { action_name: 'nextmonth' }, { action_name: 'delete' }];
			if (item.type === constants.SMS_OBJECT || item.type === constants.EMAIL_OBJECT) {
				tenpActionsList = [];
				if (item.columnName !== constants.TASKS_OVERDUE) {
					if ((item.taskType === 'DirectMail') && isValidParam(item.emailIdList) && getStringParam(item.emailIdList) !== '') {
						tenpActionsList.push({ action_name: 'sendnow' });
					} else {
						tenpActionsList.push({ action_name: 'sendnow' }, { action_name: 'edit' });
					}
				}
				tenpActionsList.push({ action_name: 'delete' });
			}
			tenpActionsList.forEach((actionItem, i) => {
				let actionLabel = actionItem.action_name;
				if (actionLabel === 'complete') {
					actionLabel = getLocalizedStrings().label.APPOINTMENTS_TASKS.COMPLETE;
				} else if (actionLabel === 'Edit1') {
					actionLabel = "Edit";
				} else if (actionLabel === 'nextday') {
					actionLabel = getLocalizedStrings().label.APPOINTMENTS_TASKS.NEXT_DAY;
				} else if (actionLabel === 'nextweek') {
					actionLabel = getLocalizedStrings().label.APPOINTMENTS_TASKS.NEXT_WEEK;
				} else if (actionLabel === 'nextmonth') {
					actionLabel = getLocalizedStrings().label.APPOINTMENTS_TASKS.NEXT_MONTH;
				} else if (actionLabel === 'delete') {
					actionLabel = getLocalizedStrings().label.APPOINTMENTS_TASKS.DELETE;
				} else if (actionLabel === 'edit') {
					actionLabel = getLocalizedStrings().label.APPOINTMENTS_TASKS.EDIT;
				} else if (actionLabel === 'sendnow') {
					actionLabel = getLocalizedStrings().label.APPOINTMENTS_TASKS.SENDNOW;
				}
				actionItems.push(
					<MenuItem
						key={actionItem.action_name}
						value={actionItem.action_name}
						style={styles.popoverMenuItem}
						fullWidth={true}
						onClick={(e) => handleAction({ 'id': item.id, 'title': item.columnName, 'object': object, 'parentIndex': parentIndex, 'tType': item.tType, 'taskName': item.taskName }, actionItem.action_name, e)}
						labelstyle={{ color: '#479ccf' }}
						underlinestyle={{ display: 'none' }}
						autowidth={"true"}
					>{actionLabel}</MenuItem>);
			});
		} else {
			let tenpActionsList = actions !== null ? actions : null;
			tenpActionsList = tenpActionsList?.filter(f => {
				return f.action_name !== 'Add Task'
			});
			tenpActionsList = tenpActionsList?.filter(f => {
				return f.action_name !== constants.UNLINK
			});
			if (tenpActionsList !== null && tenpActionsList !== undefined) {
				let isNoteChild = false;
				isNoteChild = isObjectChildExists(object, constants.NOTES);
				if (!isNoteChild) {
					tenpActionsList = tenpActionsList?.filter(f => {
						return f.action_name !== constants.ADD_NOTES
					});
				}
				tenpActionsList = tenpActionsList?.filter(f => {
					return (f.action_name !== "Send Email" && f.action_name !== "Send DocuSign")
				});
				sortArrayObjectByProperty(tenpActionsList, 'action_name');
				tenpActionsList.forEach((actionItem, i) => {
					if (actionItem.action_name !== constants.COPY
						&& actionItem.action_name !== constants.MERGE
						&& actionItem.action_name !== constants.TO_EXCEL && actionItem.action_name !== constants.SEND_TEXT) {
						let actionLabel = actionItem.action_name;
						let dataObj = { 'id': item.id, 'title': item.columnName, 'object': object, 'parentIndex': parentIndex };
						if (actionItem.action_name === constants.CONVERT) {
							dataObj.myClientId = item.myclient_id;
						}
						actionLabel = isValidParam(getLocalizedStrings().label.COMMON[actionLabel.toUpperCase().trim().split(' ').join('_')]) ? getLocalizedStrings().label.COMMON[actionLabel.toUpperCase().trim().split(' ').join('_')] : actionLabel;
						actionItems.push(
							<MenuItem
								key={actionItem.action_name}
								value={actionItem.action_name}
								style={styles.popoverMenuItem}
								fullWidth={true}
								onClick={() => handleAction(dataObj, actionItem.action_name)}
								labelstyle={{ color: '#479ccf' }}
								underlinestyle={{ display: 'none' }}
								autowidth={"true"}
							>{actionLabel}</MenuItem>);
					}
				});
			}
		}
		return actionItems;
	};
	const closeListviewActionList = () => {
		setOpenActionList(false);
	};

	function openListviewActionList(event) {
		event.preventDefault();
		setOpenActionList(true);
		setAnchorEl(event.currentTarget);
		let tempActionsList = generateActionsList();
		if((item.t_unit_owner === 1 ||item.t_unit_owner === "1") && item.tType === "Corrective Action" ){
			tempActionsList = tempActionsList.filter(item => item.props.children !== "Delete");
		}
		setAactionsList(tempActionsList);
	};
	const openMiniDrawer = (label, object, id, url, isRedirect, field,recordData) => {
		if (field === 'itemName' || field === 'dateField') {
			let data = {};
			data.id = id;
			data.object = object;
			data.labelName = label;
			if(recordData.t_unit_owner){
				data.t_unit_owner = recordData.t_unit_owner;
			}
			if(recordData.tType){
				data.tType = recordData.tType;
			}
			data.isDetailView = false;
			let hasPermission = hasAccessPermission(constants.TASKS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
			if (hasPermission) {
				dispatch(getAppDrawer(true, label, constants.MINI_EDIT, data, constants.OTHER));
			} else {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
			}
		} else {
			if (url !== undefined && url !== null) {
				openTab(label, object, id, url, isRedirect, field);
			}

		}
	}
	const openTab = (label, object, id, url, isRedirect, field) => {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		try {
			if (object === constants.OPPORTUNITIES_OBJECT && object === constants.ACCOUNTS_OBJECT && id === 0) {
				let msg = getLocalizedStrings().message.COMMON.COULDNOT_LOCATE + label + getLocalizedStrings().message.COMMON.DELETED_OR_ACCESS_DENIED;
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
			} else if (object === constants.TASKS && field !== 'company') {
				let hasPermission = hasAccessPermission(constants.TASKS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
				if (hasPermission && !isInXpress) {
					openTaskDetailView(object, id, label);
				} else {
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
				}
			} else {
				let activeTab = getObjectParam(getActiveTab());
				let activeTabIndex = getIntParam(activeTab.index);

				let info = getObjectParam(getActiveTabInfo());
				info.actionType = "detail";
				let name = '';
				let elId = '';
				let deskDiv = '';
				let scrollInfoObj = getObjectParam(info.scrollInfo);
				$(".desk-items").each(function () {
					elId = $(this).attr("id");
					name = elId.split("desk-items-")[1];
					deskDiv = document.getElementById("desk-items-" + name);
					scrollInfoObj[name] = deskDiv.scrollTop;
				});
				info.scrollInfo = scrollInfoObj;

				let tab = { label: label, object: object, type: TYPE_DETAIL_VIEW, imgName: null, url: url, isActive: true, parentTabIndex: activeTabIndex };
				addTab(tab, isRedirect);
			}
		} catch (error) {
			console.error("Error in 'Card.js -> openTab()':" + error);
		}
	}
	const openTaskDetailView = (object, id, label) => {
		let linkTo = 'detailview/' + id;
		let linkToUrl = '/' + object + "/" + linkTo;
		linkToUrl = makeValidAppUrl(linkToUrl);

		let tab = {
			label: label,
			object: object,
			type: TYPE_DETAIL_VIEW,
			url: '/' + object + "/" + linkTo,
			info: {}
		};
		addTab(tab);
		setRedirect(true);
		setRedirectUrl(linkToUrl);
	}
	const handleAction = (dataObj, actionName, event) => {
		 
		if (actionName === constants.ADD_NOTES) {
			addNotes(dataObj);
		} else if (actionName === constants.CONVERT) {
			if (object === constants.CONTACTS_OBJECT) {
				dataObj.columnView = true;
				convert(dataObj);
			} else {
				convert(dataObj);
			}
		} else if (actionName === constants.ADD_TO_GROUP) {
			addToGroup(dataObj);
		} else if (actionName === constants.SHARE) {
			share(dataObj);
		} else if (actionName === constants.ASSIGN) {
			assign(dataObj);
		} else if (actionName === constants.PUSH) {
			push(dataObj);
		} else if (actionName === constants.DELETE) {
			if (object == 'accounts' || object == 'units') {
				let _params = {
					object: constants.ACCOUNTS_OBJECT,
					id: dataObj.id,
					callFrom: constants.UNITS_OBJECT,
				}
				let _myClientId = '';
				const response = Promise.resolve(HTTPClient.get(endPoints.COMMON.GET_MYCLIENTID, _params));
				response.then((response) => {
					if (response.status == 0) {
						_myClientId = response.myClientId;
						if (_myClientId > 0) {
							sfDialogs.alert(
								getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
								"Sorry, you do not have the permission to perform delete operation on a Unit Account record. Please contact Brandwide Support to delete the Unit Account record.",
								null
							);
						} else {
							handleDelete(dataObj);

						}
					}
				})
			}
			else {
				handleDelete(dataObj);
			}

		} else if (actionName === constants.MAP) {
			handleMap(dataObj);
		} else if (actionName === constants.EDIT) {
			edit(dataObj);
		} else if (actionName === constants.UNSUBSCRIBE) {
			unsubscribe(dataObj);
		} else if (actionName === constants.SHARE_WITH_UNITS) {
			
			let msg =`1 record will be shared with units. Are you sure ?`
			sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => shareWithUnitsAction(dataObj.id), null);
		}else if (actionName === constants.SEND_EMAIL) {
			sendEmail(dataObj);
		} else if (dataObj.object === constants.TASKS && actionName === 'complete') {
			handelCompleteTask(dataObj.object, dataObj.id, dataObj.tType);
		} else if (dataObj.object === constants.TASKS && (actionName === 'nextday' || actionName === 'nextweek' || actionName === 'nextmonth')) {
			handleTaskReschedule(dataObj.id, actionName, dataObj.object);
		} else if (dataObj.object === constants.TASKS && (actionName === 'Edit1')) {
			openTaskDetailView(dataObj.object, dataObj.id, dataObj.taskName);
		} else if (dataObj.object === constants.TASKS && actionName === constants.DELETE.toLowerCase()) {
			if (item.type === constants.EMAIL_OBJECT || item.type === constants.SMS_OBJECT) {
				handelSMSEmailDelete();
			} else {
				handelTaskDelete(dataObj.object, dataObj.id);
			}
		} else if (dataObj.object === constants.TASKS && actionName === constants.EDIT.toLowerCase()) {
			handleTaskEvent();
		} else if (dataObj.object === constants.TASKS && actionName === 'sendnow') {
			handleSendNow();
		} else if (dataObj.object === constants.CONTACTS_OBJECT && actionName === constants.CONVERT_TO_ACCOUNTS) {
			handleConvertToAccounts(dataObj);
		}
		setOpenActionList(false);
	}
	const addNotes = (dataObj) => {
		try {
			let labelName = getLocalizedStrings().label.COMMON.ADD_NOTES;
			let value = constants.NEW;
			let data = {
				object: constants.NOTE_OBJECT,
				parent_object: dataObj.object,
				parent_record_id: dataObj.id,
				mode: constants.SF_FORM_OPEN_MODE_QUICK
			}
			let hasPermission = null;
			let checkValue = constants.ACCESS_TYPE_CREATE;
			let securityLevelType = constants.SECURITY_LEVEL_TYPE_ACCESS;
			hasPermission = hasAccessPermission(constants.NOTES, securityLevelType, checkValue);
			if (isValidParam(appContainer.customDrawerProps.minimizeList.new) && appContainer.customDrawerProps.minimizeList.new) {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'Note drawer is already opened.', null)

			} else {
				if (hasPermission) {
					if (!appContainer.customDrawerProps.isOpen && !appContainer.customDrawerProps.isMinimize) {
						dispatch(getAppCustomDrawer(true, labelName, value, { width: '83%' }, data, null, true));
					 } else {
						dispatch(getAppDrawer(true, labelName, value, data, constants.KANBAN));
					 }
				} else {
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
				}
			}
			
		} catch (error) {
			console.error("Error in 'Card.js -> addNotes()':" + error);
		}
	}
	const convert = (dataObj) => {
		let minimizeList = convertDrawer.minimizeList;
		if (!minimizeList.hasOwnProperty(constants.CONVERT) || (minimizeList.hasOwnProperty(constants.CONVERT) && !minimizeList.Convert)) {
			try {
				let labelName = getLocalizedStrings().label.KANBAN_REPORT[constants.CONVERT];
				let value = constants.CONVERT;
				setRecordId(dataObj.id);
				setParentIndx(dataObj.parentIndex);
				let selectedRecord = [];
				if (object === constants.ACCOUNTS_OBJECT) {
					selectedRecord.push({ id: dataObj.id, title: dataObj.title, myClientId: dataObj.myClientId });
				} else {
					selectedRecord.push({ id: dataObj.id, title: dataObj.title });
				}
				let data = {
					selectedRecords: selectedRecord,
					callBack: getConvertCallBack,
					isRequiredListViewRefresh: totalCardsCount === 1,
					object: object
				}
				let style = {};
				style.width = '40%';
				dispatch(getAppCustomDrawer(true, labelName, value, style, data, null, true, constants.COLUMN_VIEW));

			} catch (error) {
				console.error("Error in 'Card.js -> convert()':" + error);
			}
		} else {
			dispatch(showCustomSnackBar(getLocalizedStrings().message.ACCOUNTS.ALERT_MULTI_DRAWER_OPEN, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
		}
	}
	const addToGroup = (dataObj) => {
		try {
			let labelName = getLocalizedStrings().label.COMMON.ADD_TO_GROUP;
			let value = constants.ADD_TO_GROUP;
			let selectedRecord = [];
			selectedRecord.push({ id: dataObj.id, title: dataObj.title });
			let data = {
				selectedRecords: selectedRecord,
				object: object
			}
			dispatch(getAppDrawer(true, labelName, value, data, constants.KANBAN));
		} catch (error) {
			console.error("Error in 'Card.js -> addToGroup()':" + error);
		}
	}
	const share = (dataObj) => {
		try {
			let hasSecurityRolePermission = false;
			let labelName = getLocalizedStrings().label.COMMON.SHARE + ' ' + getLocalizedStrings().label.COMMON.WITH;
			let value = constants.SHARE;
			let params = {
				"security_level_name": "Admin",
				"template_id": app.me.t_id
			}
			if (app.me.t_id !== 2 && !app.me.is_tenant) {
				const response = Promise.resolve(HTTPClient.get(endPoints.COMMON.HAS_SECURITY_ROLE_PERMISSION, params));
				response.then((response) => {
					if (response.status === 0) {
						hasSecurityRolePermission = response.hasSecurityRolePermission;
					} else {
						hasSecurityRolePermission = false;
					}
				});
			}
			if (app.me.is_tenant || app.me.t_id === 2 || hasSecurityRolePermission) {
				let selectedRecord = [];
				selectedRecord.push({ id: dataObj.id, title: dataObj.title });
				let data = {
					selectedRecords: selectedRecord,
					object: object
				}
				dispatch(getAppDrawer(true, labelName, value, data, constants.KANBAN));
			} else {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.LIST_VIEW.SHARE_PERMISSION, null);
			}
		} catch (error) {
			console.error("Error in 'Card.js -> share()':" + error);
		}
	}
	const assign = (dataObj) => {
		try {
			let hasPermission = false;
			let labelName = getLocalizedStrings().label.COMMON.ASSIGN;
			let value = constants.ASSIGN;
			let selectedRecord = [];
			selectedRecord.push({ id: dataObj.id, title: dataObj.title });
			let data = {
				selectedRecords: selectedRecord,
				object: object
			}
			let checkValue = constants.ACCESS_TYPE_EDIT;
			let securityLevelType = constants.SECURITY_LEVEL_TYPE_ACCESS;
			hasPermission = hasAccessPermission(object, securityLevelType, checkValue);
			if (hasPermission) {
				dispatch(getAppDrawer(true, labelName, value, data, constants.KANBAN));
			} else {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
			}
		} catch (error) {
			console.log("Error in 'Card.js -> assign()':" + error);
		}
	}
	const push = (dataObj) => {
		try {
			let labelName = getLocalizedStrings().label.COMMON.PUSH + " to";
			let value = constants.PUSH;
			let selectedRecord = [];
			selectedRecord.push({ id: dataObj.id, title: dataObj.title });
			let data = {
				selectedRecords: selectedRecord,
				object: object
			}
			dispatch(getAppDrawer(true, labelName, value, data, constants.COLUMN_VIEW));
		} catch (error) {
			console.log("Error in 'Card.js -> assign()':" + error);
		}
	}
	const handleDelete = (dataObj) => {
		try {
			let msg = "";
			let checkValue = constants.ACCESS_TYPE_DELETE;
			let hasPermission = hasAccessPermission(object, constants.SECURITY_LEVEL_TYPE_ACCESS, checkValue);
			if (hasPermission) {
				if (dataObj.id > 0) {
					msg = getLocalizedStrings().message.COMMON.CONFIRM_DELETE_RECORD;
				}
				sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, (e) => onconfirmHandleDelete(e, dataObj), null, null);
			} else {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
			}
		} catch (error) {
			console.log("Error in 'Card.js -> handleDelete()':" + error);
		}
	}
	const onconfirmHandleDelete = (event, dataObj) => {
		const recordIdList = [];
		recordIdList.push(dataObj.id);

		let params = {
			"id": recordIdList
		}
		const response = deleteAction(object, params);
		response.then((response) => {
			if (response.status === 0) {
				let info = getObjectParam(getActiveTabInfo());
				let params = {};
				if (cardType !== null && (cardType === 'column_view' || cardType === 'columnview')) {
					//console.log("need to be implemented");
					refreshColumnView(object);
				} else {
					params.workflowId = info.workflowId;
					params.salesRep = info.salesRep;
					params.duration = info.duration;
					params.reportBy = info.reportBy;
					params.sortBy = info.sortBy;
					dispatch(getForeCastReportData(object, params));
					setListViewMounted(false, object);
				}
				dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
			}
		});
	}
	const handelCompleteTask = (type, id, taskType) => {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		let hasPermission = hasAccessPermission(constants.TASKS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
		if (hasPermission && !isInXpress) {
			const tempid = parseInt(id, 10);
			let data = {
				id: tempid,
				object: type,
				taskType: taskType !== null && taskType !== undefined && taskType !== "" ? taskType : "Task",
			}
			let labelName = getLocalizedStrings().label.APPOINTMENTS_TASKS.COMPLETE_THIS_TASK;
			dispatch(getAppDrawer(true, labelName, constants.COMPLETE, data, constants.OTHER));
		} else {
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
		}
	}
	const handleTaskReschedule = (id, duration, object) => {

		let hasPermission = null;
		hasPermission = hasAccessPermission(object, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
		if (hasPermission) {
			let recordIdArr = [];
			let msg = (object === constants.TASKS ? getLocalizedStrings().message.APPOINTMENTS_TASKS.RESCHEDULE_TASK_SUCCESS : getLocalizedStrings().message.APPOINTMENTS_TASKS.RESCHEDULE_APPOINTMENT_SUCCESS);
			recordIdArr.push(id);

			let params = {
				"id": recordIdArr,
				"type": duration
			}
			const response = reschedule(object, params);
			response.then((response) => {
				if (response.status === 0) {
					let info = getActiveTabInfo();
					info.getTaskPageData(object);
					if(object== constants.TASKS){
						dispatch(showCustomSnackBar("Task is rescheduled successfully", styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
					} else if(object == constants.APPOINTMENTS ){
					dispatch(showCustomSnackBar("Appointment is rescheduled successfully", styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));

					}
					dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
				}
			});
		} else {
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
		}
	}
	const handelSMSEmailDelete = () => {
		let recordData = item;
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		const recordIds = [];
		let type = recordData.taskType;
		const id = parseInt(recordData.id, 10);
		let typeMsg = "";
		try {
			if (type === constants.WORKFLOW_SMS || type === constants.SCHEDULE_SMS) {
				recordIds.push(id);
				typeMsg = 'SMS';
			} else if (type === 'AutoMail' || type === 'DirectMail') {
				recordIds.push(id);
				typeMsg = 'Email';
				// objectName = constants.EMAIL_OBJECT;
			}
			let msg = "";
			if (id > 0) {
				if (type === 'AutoMail' || type === 'DirectMail' || type === constants.WORKFLOW_SMS || type === constants.SCHEDULE_SMS) {
					msg = typeMsg + getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;
				} else {
					msg = typeMsg.charAt(0).toUpperCase() + typeMsg.slice(1, type.length - 1) + getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;
				}

			}
			let hasPermission = hasAccessPermission(constants.TASKS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_DELETE);
			if (hasPermission && !isInXpress) {
				sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, (e) => handeelSMSEmailDeleteOnConfirm(e, recordIds, type, recordData));
			} else {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
			}
		} catch (error) {
			console.error("error in 'Card.js'-->'handelSMSEmailDelete()'" + error);
		}
	}
	const handeelSMSEmailDeleteOnConfirm = (event, recordIds, type, recordData) => {
		let msg = '';
		let typeMsg = '';
		let param = {
			"id": recordIds
		}
		if (type === 'AutoMail' || type === 'DirectMail') {
			param.taskType = type;
			if (type === 'AutoMail') {
				param.isDelete = false;
			} else {
				param.parent_table_id = getIntParam(recordData.tableId);
				param.parent_record_id = getIntParam(recordData.recordId);
			}
			msg = typeMsg + getLocalizedStrings().message.COMMON.DELETE_SUCCESS;
		} else if (type === constants.WORKFLOW_SMS || type === constants.SCHEDULE_SMS) {
			param.taskType = type;
			param.isDetailView = true;
			if (type === 'Workflow SMS') {
				param.isDelete = false;
			}
			msg = typeMsg + getLocalizedStrings().message.COMMON.DELETE_SUCCESS;
		} else {
			msg = type.charAt(0).toUpperCase() + type.slice(1, type.length - 1) + getLocalizedStrings().message.COMMON.DELETE_SUCCESS;
		}
		const response = deleteAction(object, param);
		response.then((response) => {
			if (response.status === 0) {
				if (type === 'AutoMail' || type === constants.WORKFLOW_SMS || type === constants.SCHEDULE_SMS) {
					let data = response.data;
					if (data.isDependent !== undefined && data.isDependent) {
						let title = 'This scheduled Email/SMS has dependent tasks and those dependent tasks will not be created.';
						sfDialogs.confirm(title, null, () => handeelSMSEmailDeleteActionOnConfirm(recordIds, type), null);
					} else {
						refreshTaskPage();
						dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
					}
				} else {
					refreshTaskPage();
					dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
				}
			}
		});
	}
	const handeelSMSEmailDeleteActionOnConfirm = (recordIds, type) => {
		let msg = getLocalizedStrings().message.COMMON.DELETE;
		let params = {
			"id": recordIds,
			'taskType': type,
			'isDelete': true,
		}
		const response = deleteAction(object, params);
		response.then((response) => {
			if (response.status === 0) {
				refreshTaskPage();
				dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
			}
		});
	}
	const handelTaskDelete = (object, id) => {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		const recordIds = [id];
		let typeMsg = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS;
		let msg = typeMsg.charAt(0).toUpperCase() + typeMsg.slice(1, object.length - 1) + getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;
		let hasPermission = hasAccessPermission(object, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_DELETE);
		if (hasPermission && !isInXpress) {
			sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, (e) => onConfirmHandleTaskDelete(e, recordIds, object), null);
		} else {
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
		}
	}
	const onConfirmHandleTaskDelete = (event, recordIds, object) => {
		let param = { "id": recordIds };
		let msg = object.charAt(0).toUpperCase() + object.slice(1, object.length - 1) + getLocalizedStrings().message.COMMON.DELETE_SUCCESS;
		const response = deleteAction(object, param);
		response.then((response) => {
			if (response.status === 0) {
				dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
				refreshTaskPage();
			}
		});
	}
	const handleMap = (dataObj) => {
		// need to check this functionality
		const recordIdArr = [];
		recordIdArr.push(dataObj.id);
		try {
			//let labelName = constants.MAP;
			let labelName = getLocalizedStrings().label.COMMON.MAP;
			let value = constants.MAP;
			let data = {
				id: recordIdArr,
				object: object
			}
			dispatch(getAppDrawer(true, labelName, value, data, constants.KANBAN));
		} catch (error) {
			console.log("Error in 'Card.js -> handleMap()':" + error);
		}
	}

	const edit = (dataObj) => {
		try {
			let objectName = object;
			if (objectName === constants.JOBS_OBJECT) {
				let title = getStringParam(item.job_name);
				let info = { id: dataObj.id, title: title, type: 'Edit', object: objectName, callFrom: constants.COLUMN_VIEW };
				addNewFormTabs(info);
			} else {
				let label = getSelectedObjectLabel();
				label = getStringParam(label);
				let tab = getActiveTab()
				if (label.toLowerCase() === constants.ONBOARDING_OBJECT && tab.object !== constants.ONBOARDING_OBJECT) {
					label = getObjectLabelByObject(tab.object);
				}
				let labelName = constants.EDIT + ' ' + label;
				if (objectName === constants.OPPORTUNITIES_OBJECT && cardType === null) {
					labelName = getLocalizedStrings().label.KANBAN_REPORT[constants.EDIT] + ' ' + getLocalizedStrings().label.KANBAN_REPORT[objectName];
				}
				let value = constants.EDIT;
				let hasPermission = false;
				let checkValue = constants.ACCESS_TYPE_EDIT;
				hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_ACCESS, checkValue);
				if (hasPermission) {
					let data = {
						object: objectName,
						id: dataObj.id,
						mode: constants.SF_FORM_OPEN_MODE_EXPANDED
					}
					dispatch(getAppDrawer(true, labelName, value, data, constants.KANBAN));
				} else {
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
				}
			}
		} catch (error) {
			console.log("Error in 'Card.js -> map()':" + error);
		}
	}
	const shareWithUnitsAction =(id)=>{
        let dataParam = {
            id:[id]
        };
        let promise = Promise.resolve(HTTPClient.post(endPoints.CONTACTS.SHARE_WITH_UNITS, dataParam));
        promise.then((response) => {
            if (isValidParam(response)) {
                if (response.status === 0) {
                    let msg = `1 record(s) shared successfully with the units.`
                    dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                } 
            }})
    }


	const unsubscribe = (dataObj) => {
		const recordIdArr = [];
		recordIdArr.push(dataObj.id);
		let data = {
			recordIds: recordIdArr,
			isDetailView: false
		}
		dispatch(getAppDrawer(true, getLocalizedStrings().label.COMMON.UNSUBSCRIBE, constants.UNSUBSCRIBE, data, constants.LIST_VIEW));
	}
	
	const sendEmail = (dataObj) => {
		let labelName = null;
		let isEmail = true;
		let endPoint = "";
		let params = {};
		let recordId = dataObj.id;
		if (object === constants.ACCOUNTS_OBJECT) {
			endPoint = endPoints.ACCOUNTS.MAP_DATA;
			params = { "id": recordId, "fields": ["company", "first_name", "last_name", "email"] };
		} else if (object === constants.CONTACTS_OBJECT) {
			endPoint = endPoints.CONTACTS.MAP_DATA;
			params.id = recordId;
			params = { "id": recordId, "fields": ["company", "first_name", "last_name", "email"] };
		}

		const promise = Promise.resolve(HTTPClient.get(endPoint, params));
		if (isValidParam(promise)) {
			promise.then(response => {
				if (isValidParam(response)) {
					let records = response.records;
					let firstName = "";
					let lastName = "";
					let fullName = "";
					let company = "";
					let email = "";
					let isFullName = false;
					if (records) {
						if (records.hasOwnProperty('first_name')) {
							firstName = records.first_name;
						}
						if (records.hasOwnProperty('last_name')) {
							lastName = records.last_name;
						}
						if (records.hasOwnProperty('company')) {
							company = records.company;
						}
						if (records.hasOwnProperty('email')) {
							email = records.email;
						}
					}

					if (firstName !== null && firstName !== "" && firstName !== undefined && lastName !== null && lastName !== "" && lastName !== undefined) {
						fullName = firstName + " " + lastName;
					}
					if (firstName !== null && firstName !== "" && firstName !== undefined && (lastName === null || lastName === "" || lastName === undefined)) {
						fullName = firstName;
					}

					if ((firstName === null || firstName === "" || firstName === undefined) && lastName !== null && lastName !== "" && lastName !== undefined) {
						fullName = lastName;
					}

					if (fullName === null || fullName === "" || fullName === undefined) {
						fullName = company;
					}

					if (fullName === null || fullName === "" || fullName === undefined) {
						fullName = email;
					}

					if (fullName === null || fullName === "" || fullName === undefined) {
						fullName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
						isEmail = false;
					}

					if (fullName !== null && fullName !== "" && fullName !== undefined) {
						if (isEmail) {
							labelName = getLocalizedStrings().label.COMMON.EMAIL_TO + " " + fullName + "";
						} else if (!isEmail && !isFullName) {
							labelName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
						}
					}
					openSendEmailDialog(object, dataObj, labelName);
				}
			});
		}
	}
	const openSendEmailDialog = (object, dataObj, label) => {
		let type = LinkToType.TYPE_EMAIL;
		let batchMailObjlist = [];
		let batchMailObj = {};
		batchMailObj.id = dataObj.id;
		batchMailObj.email = item.email;
		batchMailObjlist.push(batchMailObj);
		batchMailObj = null;

		let data = {};
		data.email_id_list = batchMailObjlist
		data.object = object;
		data.batch_mail = false;
		dispatch(getAppCustomDrawer(true, label, type, { width: '90%' }, data, null, true));
	}
	const getConvertCallBack = (stageName) => {
		let info = getObjectParam(getActiveTabInfo());
		let params = {};
		if (cardType !== null && (cardType === 'column_view' || cardType === 'columnview')) {
			//console.log("need to be implemented");
			refreshColumnView(object);
		} else {
			params.workflowId = info.workflowId;
			params.salesRep = info.salesRep;
			params.duration = info.duration;
			params.reportBy = info.reportBy;
			params.sortBy = info.sortBy;
			dispatch(getForeCastReportData(object, params));
			setListViewMounted(false, object);
		}
	}
	const getFielValue = (selectedFields, recordData) => {
		let result = {};
		try {
			selectedFields = getArrayParam(selectedFields);
			recordData = getObjectParam(recordData);

			if (selectedFields.length > 0 && Object.keys(recordData).length > 0) {
				if (selectedFields[0] === 'lead_score' && selectedFields.length > 1) {
					selectedFields[0] = selectedFields[1];
					selectedFields[1] = 'lead_score';
				}
				let hasName = selectedFields.includes('first_name') && selectedFields.includes('last_name') ? true : false;
				selectedFields.forEach((f, i) => {
					if ((f === 'first_name' || f === 'last_name') && hasName) {
						if (!result.hasOwnProperty('name')) {
							result.name = (getStringParam(recordData.first_name).trim() + ' ' + getStringParam(recordData.last_name).trim()).trim();
						}
					} else {
						result[f] = recordData[f];
					}
				});
			}
		} catch (error) {
			console.error("Error in 'card.js -> getFielValue()':" + error);
		}
		if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.OPPORTUNITIES_OBJECT || object === constants.TASKS) {
			if (object !== constants.TASKS && recordData.hasOwnProperty('task_appointment')) {
				result['task_appointment'] = recordData['task_appointment'];
			}
			if (recordData.hasOwnProperty('communication_info')) {
				result['communication_info'] = recordData['communication_info'];
			}
		}
		let hiddenFields = recordData.fields.filter(f => f.is_email_field === true || f.is_phone_field);
		if (hiddenFields.length > 0) {
			hiddenFields.forEach(f => {
				if (recordData.hasOwnProperty(f.name)) {
					result[f.name] = recordData[f.name];
				}
			});
		}
		return result;
	}
	const getLeadScoreElement = (totalLeadScore, value) => {
		let element = null;
		try {
			totalLeadScore = getIntParam(totalLeadScore);
			value = getIntParam(value);
			let scoreIcon = generateLeadScoreIcon(totalLeadScore, value);
			element =
				<div style={{ marginLeft: '4px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'left' }}
					 title={getLocalizedStrings().label.AUDIT_LEADSCORE.SCORE + " : " + value}>
					{scoreIcon}
					<span style={{ cursor: 'pointer', paddingLeft: '5px', color: '#717171', textDecoration: 'underline' }} onClick={() => {
						getLeadScoreHistory(value)
					}}>
					  {value}
					</span>
				</div>;
		} catch (error) {
			console.error("Error in 'card.js -> getLeadScoreElement()':" + error);
		}
		return element;
	}
	const getElementToShow = (field, fieldValue) => {
		let element = null;
		let fieldLabel = getStringParam(field.label);
		let color = '#717171';
		let title = null;
		let isValueExit = false;
		let isPopover = false;
		try {
			if (isValidParam(field) && isValidParam(fieldValue)) {
				fieldLabel = getStringParam(field.label);
				title = null;
				isValueExit = false;
				isPopover = false;
				if (object === constants.TASKS) {
					if ((item.type === constants.SMS_OBJECT || item.type === constants.EMAIL_OBJECT) && field.name === 'company' && (!isValidParam(fieldValue) || fieldValue === '')) {
						let objectList = getArrayParam(app.objectList).filter(f => f.name === TABLEID_OBJECT_MAP[item.tableId]);
						fieldValue = objectList.length > 0 ? objectList[0].label : '';
					}
				}
				if (fieldValue === null || fieldValue === "") {
					fieldValue = fieldLabel;
					color = '#B5B5B5';
					title = "";
					if (object === constants.TASKS && field.name === 'location') {
						fieldValue = 'Email';
					}
				} else {
					title = fieldLabel + ": " + fieldValue;
					isValueExit = true;

					isPopover = !!((field.name === 'mobile') && field.is_click_to_call && field.is_twilio_sms && (field.is_twilio || field.is_ring_central));
				}
				element =
					<div style={{ marginLeft: '4px', marginRight: '4px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'left', color: color }} title={title}>
						{getIconElement(field, fieldValue, isValueExit)}
						{
							<span style={{ fontSize: 12, lineHeight: '18px' }} onClick={() => openMiniDrawer(item.taskName, object, item.id, null, true, field.name,item)}>{getValueElement(field, fieldValue, isValueExit)}
          					</span>
						}
						{isPopover && getTwilioPopOver(field, fieldValue)}
					</div>
				if (field.name === 'workflow_id') {
					element = null;
				}
				if (object === constants.TASKS && field.name === 'location') {
					element = null;
				}

			}
		} catch (error) {
			console.error("Error in 'card.js -> getElementToShow()':" + error);
		}
		return element;
	}
	const generateCard = (fields, recordData, recordId) => {
		let elements = [];
		let element = null;
		try {
			recordData = getObjectParam(recordData);

			let field = null;
			let fieldValue = null;
			let tempFields = null;
			Object.keys(recordData).forEach((fieldName, i) => {
				fieldValue = recordData[fieldName];
				tempFields = fields.filter(f => f.name === fieldName && f.is_detail_link === false);
				field = tempFields.length > 0 ? tempFields[0] : null;
				if (isValidParam(field)) {
					if (fieldName === 'lead_score') {
						element = getLeadScoreElement(totalLeadScore, fieldValue);
					} else if (fieldName === 'location') {
						element = null;
					} else if (fieldName === 'campaign_type') {
						fieldValue = getStringParam(fieldValue).trim();
						fieldValue = getCampaignTypeNames(fieldValue);
						element = getElementToShow(field, fieldValue);
					} else {
						element = getElementToShow(field, fieldValue);
					}
				} else if (fieldName === 'name') {
					fieldValue = getStringParam(fieldValue).trim();
					element = getElementToShow({ name: 'name', label: 'Name' }, fieldValue);
				} else if (fieldName === 'address') {
					fieldValue = getStringParam(fieldValue).trim();
					element = getElementToShow({ name: 'address', label: 'Address' }, fieldValue);
				}

				if (isValidParam(element)) {
					elements.push(element);
				}
				tempFields = null;
				field = null;
				fieldValue = null;
				element = null;
			});

			if (recordData.hasOwnProperty('communication_info')) {
				let cFields = communicationFields[recordData.communication_info.objectName.toLowerCase()];
				let cInfo = recordData.communication_info;
				if (cInfo.hasOwnProperty('mobile') && isValidParam(cInfo.mobile) && cInfo.mobile !== '') {
					let mobile_field = cFields.filter(f => f.name === 'mobile' && f.is_phone_field);
					let element = getTwilioPopOver(mobile_field[0], cInfo.mobile);
					elements.push(element);
				}
			}


		} catch (error) {
			console.error("Error in 'card.js -> generateCard()':" + error);
		}
		return elements;
	}
	const getIconElement = (field, value, isValueExit) => {
		let props = {};
		let className = null;
		let iconElement = null;
		try {
			if  (isValueExit && value !== '' && (field.name === 'phone' || field.name === 'mobile' || field.name.indexOf('custom_field') > -1)) {
				props.style = { float: 'left', paddingTop: '1px', cursor: 'pointer', paddingRight: '3px' };
				props['aria-hidden'] = true;
				// if (field.name === 'phone' || (field.name.indexOf('custom_field') > -1 && field.is_click_to_call)) {
				// 	className = 'fa fa-phone';
				// } else if (field.name === 'mobile') {
				// 	className = 'fa fa-mobile';
				// }
				if (className !== null) {
					props.className = className;
					iconElement =
						<i {...props} />;
				}
			}
		} catch (error) {
			console.error("Error in 'card.js -> getIconElement()':" + error);
		}
		return iconElement;
	}
	const changeLinkObject = (event) => {
		if (event.currentTarget.style.textDecoration === 'underline') {
			event.currentTarget.style.textDecoration = 'none';
		} else {
			event.currentTarget.style.textDecoration = 'underline';
		}
	}
	const getValueElement = (field, value, isValueExit) => {
		let element = null;
		let isUnderLine = false;
		try {
			if (field.field_type === FORM_FIELD_TYPE.URL || field.is_lookup_field) {
				isUnderLine = true;
				if (object === constants.TASKS && (field.name === 'company' || field.name === 'fullname')) {
					if (item.t_unit_owner.toString() === "1") {
						isUnderLine = false;
					}
				}
			} else if (object === constants.TASKS && (field.name === 'taskName' || field.name === 'dateField')) {
				isUnderLine = true;
				if ((item.type === constants.EMAIL_OBJECT || item.type === constants.SMS_OBJECT) && ((item.columnName === constants.TASKS_OVERDUE || (item.taskType === 'DirectMail')) && isValidParam(item.emailIdList) && getStringParam(item.emailIdList) !== '')) {
					isUnderLine = false;
				}
			}

			let props = {};
			if (isValueExit && isUnderLine) {
				if (field.is_lookup_field) {
					props.onClick = () => openRecord(field, value, item.id);
				} else {
					props.onClick = (e) => customEvent(field, value, e);
				}
				props.style = { cursor: 'pointer', display: 'inline' };
				props.style = { ...props.style, textDecoration: 'none', color: '#717171' };
				props.onMouseOver = (e) => changeLinkObject(e);
				props.onMouseLeave = (e) => changeLinkObject(e);
				if (field.name === 'mobile') {
					props.style = { ...props.style, paddingLeft: '5px' };
				}
				element =
					<u {...props}>{value}</u>
			} else {
				element = value;
			}
		} catch (error) {
			console.error("Error in 'card.js -> getValueElement()':" + error);
		}
		return element;
	}
	const handleTaskEvent = () => {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		try {
			let hasPermission = hasAccessPermission(constants.TASKS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
			if (hasPermission && !isInXpress) {
				if (item.type === constants.EMAIL_OBJECT || item.type === constants.SMS_OBJECT) {
					let data = {};
					data.id = item.id;
					data.object = TABLEID_OBJECT_MAP[item.tableId];
					if (item.type === constants.SMS_OBJECT) {
						data.objectName = TABLEID_OBJECT_MAP[item.tableId];
						data.actionType = 'edit';
						data.smsType = item.taskType;
						dispatch(getAppDrawer(true, 'Edit SMS', constants.SEND_SMS, data, constants.LIST_VIEW));
					} else if (item.type === constants.EMAIL_OBJECT) {
						let labelName = item.taskName;
						data.type = constants.TYPE_EMAIL;
						data.emailType = item.taskType;
						let style = {};
						style.width = '90%';
						dispatch(getAppCustomDrawer(true, labelName, LinkToType.TYPE_EMAIL, style, data, null, true));
					}
				} else {
					dispatch(getAppDrawer(true, item.taskName, LinkToType.TYPE_EDIT, { id: getIntParam(item.id), object: object }, constants.OTHER));
				}
			} else {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
			}
		} catch (error) {
			console.error("Error in 'card.js -> handleTaskEvent()':" + error);
		}
	}
	const customEvent = (field, value, event) => {
		try {
			if (field.name === 'sales_rep' || field.name === 'email' || field.name === 'location' ||
				field.name === 'email2' || field.is_email_field || field.field_type === FORM_FIELD_TYPE.URL ||
				field.name === 'phone' || field.name.indexOf('custom_field') > -1) {
				handleDetailsLinkClicked(event, field.name, value);
			} else if (field.name === 'mobile') {
				if (field.is_twilio_sms && (field.is_twilio || field.is_ring_central)) {
					openTwilioPopOver(event, field.name);
				} else {
					handleDetailsLinkClicked(field.name, value);
				}
			} else if (object === constants.TASKS && field.name === 'taskName') {
				handleTaskEvent();
			}
		} catch (error) {
			console.error("Error in 'card.js -> customEvent()':" + error);
		}
	}
	const handleDetailsLinkClicked = (fieldName, value) => {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		if (!isInXpress) {
			let sortedfield = [];
			let objectName = object;
			if (item.hasOwnProperty('communication_info')) {
				objectName = item.communication_info.objectName.toLowerCase();
				sortedfield = communicationFields[objectName];
			}
			if (fieldName === 'sales_rep' || fieldName === 'website') {
				sortedfield = fields;
			}
			sortedfield = sortedfield.filter(f => {
				return f.name === fieldName
			});
			if ((fieldName === "email" || fieldName === "email" || fieldName === "location" || sortedfield[0].is_email_field) && value !== "") {
				sendMail(value, constants.TYPE_EMAIL, objectName, fieldName);
			} else if (sortedfield[0].field_type === FORM_FIELD_TYPE.URL && value !== "") {
				let websiteAddress = value.toLowerCase();
				if ((websiteAddress.indexOf("http:") !== -1) || (websiteAddress.indexOf("https:") !== -1)) {
					window.open(value.toLowerCase());
				} else {
					window.open('http://' + value.toLowerCase());
				}

			} else if (fieldName === "sales_rep" && value !== "") {
				let email = '';
				let userArray = getArrayParam(userlist.data);
				let tempUsers = userArray.filter(f => {
					return f.value === value
				});
				if (sortedfield.value !== 'Unassigned') {
					if (tempUsers.length > 0 && isValidParam(tempUsers[0]) && getStringParam(tempUsers[0].email) !== '') {
						email = getStringParam(tempUsers[0].email);
					} else {
						email = getStringParam(app.me.email);
					}
					if (email !== '') {
						sendMail(email, constants.TYPE_NOTIFY, objectName);
					}
				} else {
					let msg = "This record is not assigned to any sales rep.";
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
				}

			} else if (sortedfield[0].is_click_to_call) {
				let recordId = item.communication_info.id;
				let phoneField = true;
				let fieldName = sortedfield[0].name;
				if (sortedfield[0].is_ring_central) {
					// Code for Ring Central
					openRingCentralWebWidget();
				} else {
					if (sortedfield[0].is_twilio) {
						getCapabilityTokenForCall(objectName, recordId, phoneField, fieldName, value);
					} else if (sortedfield[0].name === "mobile" && sortedfield[0].is_twilio_sms) {
						getRecordDetailsForSMS(recordId, fieldName);
					}
				}
			}
		} else {
			let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
		}
	}
	const sendMail = (email, dataType, objectName, fieldName) => {
		let object = '';
		let recordId = 0;
		if (item.hasOwnProperty('communication_info')) {
			object = item.communication_info.objectName.toLowerCase();
			recordId = item.communication_info.id;
		}
		let labelName = null;
		let isEmail = true;
		let endPoint = "";
		let params = {};
		let isFetch = false;
		if (object === constants.OPPORTUNITIES_OBJECT) {
			objectName = object;
			recordId = item.id;
		}
		if (dataType === constants.TYPE_EMAIL) {
			if (object === constants.ACCOUNTS_OBJECT) {
				endPoint = endPoints.ACCOUNTS.MAP_DATA;
				params = { "id": recordId, "fields": ["company", "first_name", "last_name", "email"] };
				// isFetch = true;
				isFetch = false;
			} else if (object === constants.CONTACTS_OBJECT) {
				endPoint = endPoints.CONTACTS.MAP_DATA;
				params.id = recordId;
				params = { "id": recordId, "fields": ["company", "first_name", "last_name", "email"] };
				//  isFetch = true;
				isFetch = false;
			}
			if (isFetch) {
				const promise = Promise.resolve(HTTPClient.get(endPoint, params));
				if (isValidParam(promise)) {
					promise.then(response => {
						if (isValidParam(response)) {
							let records = response.records;
							let firstName = "";
							let lastName = "";
							let fullName = "";
							let company = "";
							let email = "";
							let isFullName = false;
							if (records) {
								if (records.hasOwnProperty('first_name')) {
									firstName = records.first_name;
								}
								if (records.hasOwnProperty('last_name')) {
									lastName = records.last_name;
								}
								if (records.hasOwnProperty('company')) {
									company = records.company;
								}
								if (records.hasOwnProperty('email')) {
									email = records.email;
								}
							}

							if (firstName !== null && firstName !== "" && firstName !== undefined && lastName !== null && lastName !== "" && lastName !== undefined) {
								fullName = firstName + " " + lastName;
							}
							if (firstName !== null && firstName !== "" && firstName !== undefined && (lastName === null || lastName === "" || lastName === undefined)) {
								fullName = firstName;
							}

							if ((firstName === null || firstName === "" || firstName === undefined) && lastName !== null && lastName !== "" && lastName !== undefined) {
								fullName = lastName;
							}

							if (fullName === null || fullName === "" || fullName === undefined) {
								fullName = company;
							}

							if (fullName === null || fullName === "" || fullName === undefined) {
								fullName = email;
							}

							if (fullName === null || fullName === "" || fullName === undefined) {
								fullName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
								isEmail = false;
							}

							if (fullName !== null && fullName !== "" && fullName !== undefined) {
								if (isEmail) {
									labelName = getLocalizedStrings().label.COMMON.EMAIL_TO + " " + fullName + "";
								} else if (!isEmail && !isFullName) {
									labelName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
								}
							}
							openSendMailDialog(email, dataType, objectName, labelName, firstName, fieldName);
						}
					});
				}
			} else {
				labelName = getLocalizedStrings().label.COMMON.EMAIL_TO + " " + email + "";
				openSendMailDialog(email, dataType, objectName, labelName);
			}
		} else if (dataType === constants.TYPE_NOTIFY) {
			let userArray = getArrayParam(userlist.data);
			let tempUsers = userArray.filter(f => {
				return f.email === email
			});
			if (tempUsers.length > 0 && isValidParam(tempUsers[0]) && getStringParam(tempUsers[0].value) !== '') {
				labelName = getStringParam(tempUsers[0].value);
			}
			labelName = getLocalizedStrings().label.COMMON.EMAIL_TO + " " + labelName + "";
			openSendMailDialog(email, dataType, objectName, labelName);
		}
	}
	const openSendMailDialog = (email, dataType, objectName, label, firstName, fieldName) => {
		let type = LinkToType.TYPE_EMAIL;
		let data = {};
		data.email = email;
		data.object = objectName;
		data.type = dataType;
		data.firstName = firstName;
		data.fieldName = fieldName;
		if (item.hasOwnProperty('communication_info')) {
			data.id = item.communication_info.id;
			data.object = item.communication_info.objectName.toLowerCase();
		}
		if (objectName === constants.TASKS) {
			data.taskId = getIntParam(item.communication_info.id);
			let linkInfo = item.linkInfo;
			if (isValidParam(linkInfo)) {
				let linkData = getStringParam(linkInfo.split(",")[0]).split("_");
				if (linkData.length > 0 && linkData.length === 2) {
					data.object = TABLEID_OBJECT_MAP[getIntParam(linkData[0])];
					data.id = linkData[1];
				}
			}
		}
		dispatch(getAppCustomDrawer(true, label, type, { width: '90%' }, data, null, true));
	}
	const getCapabilityTokenForCall = (object, recordId, phoneField, fieldName, selectedPhoneNumber) => {
		const promise = Promise.resolve(HTTPClient.get(endPoints.CALLING_INTEGRATION.GENERATE_CAPABILITY_TOKEN, null));
		if (isValidParam(promise)) {
			promise.then(response => {
				if (isValidParam(response)) {
					let capabilityToken = getStringParam(response.token);
					if (capabilityToken !== null && capabilityToken !== "") {
						getCapabilityToken(capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber);
					}
				}
			});
		}
	}
	const getCapabilityToken = (capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber) => {
		let endPoint = "";
		let params = {};
		if (object === constants.ACCOUNTS_OBJECT) {
			endPoint = endPoints.ACCOUNTS.MAP_DATA;
			params = { "id": recordId, "fields": ["company", "first_name", "last_name", "phone", "mobile", "workflow_id", "country"] };
		} else if (object === constants.CONTACTS_OBJECT) {
			endPoint = endPoints.CONTACTS.MAP_DATA;
			params.id = recordId;
			params = { "id": recordId, "fields": ["company", "first_name", "last_name", "phone", "mobile", "workflow_id", "country"] };
		}

		const promise = Promise.resolve(HTTPClient.get(endPoint, params));
		if (isValidParam(promise)) {
			promise.then(response => {
				if (isValidParam(response)) {
					let records = response.records;
					processInfoAndOpenCallDialog(records, capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber);
				}
			});
		}
	}
	const processInfoAndOpenCallDialog = (records, capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber) => {
		let firstName = "";
		let lastName = "";
		let fullName = "";
		let workflowId = "";
		let company = "";
		let title = "";
		let country = "";
		if (records) {
			if (records.hasOwnProperty('first_name')) {
				firstName = records.first_name;
			}

			if (records.hasOwnProperty('last_name')) {
				lastName = records.last_name;
			}

			if (records.hasOwnProperty('workflow_id')) {
				workflowId = records.workflow_id;
			}

			if (records.hasOwnProperty('company')) {
				company = records.company;
			}

			if (records.hasOwnProperty('country')) {
				country = records.country;
			}
		}

		if (firstName !== null && firstName !== "" && firstName !== undefined && lastName !== null && lastName !== "" && lastName !== undefined) {
			fullName = firstName + " " + lastName;
		}
		if (firstName !== null && firstName !== "" && firstName !== undefined && (lastName === null || lastName === "" || lastName === undefined)) {
			fullName = firstName;
		}

		if ((firstName === null || firstName === "" || firstName === undefined) && lastName !== null && lastName !== "" && lastName !== undefined) {
			fullName = lastName;
		}

		if (fullName === null || fullName === "" || fullName === undefined) {
			fullName = company;
		}
		if (fullName === null || fullName === "" || fullName === undefined) {
			fullName = selectedPhoneNumber;
		}
		if (fullName !== null && fullName !== "" && fullName !== undefined) {
			title = "Call " + fullName + "";
		}

		let label = title;
		let type = LinkToType.TYPE_CLICK_TO_CALL;
		let data = {};
		data.id = recordId;
		data.object = object;
		//data.type = dataType;
		data.token = capabilityToken;
		data.selectedPhoneNumber = selectedPhoneNumber;
		data.fieldName = fieldName;
		data.workflowId = workflowId;
		data.country = country;
		data.fullName = fullName;
		data.isDetailView = true;
		data.call_from = constants.COLUMN_VIEW;
		data.selectedRecords = [{ id: item.id, title: item.t_status }];
		let style = {};
		style.width = '40%';
		dispatch(getCallDrawer(true, label, type, style, data, null));
	}
	const getRecordDetailsForSMS = (recordId, fieldName) => {
		let params = null;
		let response = null;
		let endPoint = null;
		let recordIdArr = [];
		recordIdArr.push(recordId);
		params = {
			recordIds: recordIdArr,
			type: 'Texts',
			tableId: OBJECT_TABLEID_MAP[item.communication_info.objectName.toLowerCase()]
		}
		endPoint = endPoints.CONTACTS.GET_SMS_UNSUBSCRIBE_LIST;
		response = Promise.resolve(HTTPClient.get(endPoint, params));
		response.then((response) => {
			if (isValidParam(response) && getArrayParam(response).length === 1) {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SMS_INTEGRATION.RECORD_UNSUBSCRIBE_ALERT, null);
			} else {
				let endPoint = endPoints[item.communication_info.objectName.toUpperCase()].MAP_DATA;
				let params = { "id": recordId, "fields": ["company", "first_name", "last_name", "phone", "mobile", "country"] };

				const promise = Promise.resolve(HTTPClient.get(endPoint, params));
				if (isValidParam(promise)) {
					promise.then(response => {
						if (isValidParam(response)) {
							let records = response.records;
							processInfoAndOpenSMSDialog(records, recordId, fieldName);
						}
					});
				}
			}
		});
	}
	const processInfoAndOpenSMSDialog = (records, recordId, fieldName) => {
		let firstName = "";
		let lastName = "";
		let fullName = "";
		let company = "";
		let title = "";
		let country = "";
		let mobile = "";
		if (isValidParam(records)) {
			firstName = getStringParam(records.first_name);
			lastName = getStringParam(records.last_name);
			company = getStringParam(records.company);
			country = getStringParam(records.country);
			mobile = getStringParam(records.mobile);
		}

		country = app.me.tenant_country;

		fullName = (firstName + ' ' + lastName).trim();
		fullName = fullName === '' ? company : fullName;
		fullName = fullName === '' ? getStringParam(records.mobile) : fullName;
		title = fullName !== '' ? 'To ' + fullName : title;

		let label = title;
		let type = LinkToType.TYPE_CLICK_TO_SMS;
		let countryCode = "";
		let toNumber = getStringParam(mobile).trim();
		if (country === "USA" || country === "Canada") {
			countryCode = "+1";
		} else if (country === "India") {
			countryCode = "+91";
		} else if (country === "United Kingdom") {
			countryCode = "+44";
		} else if (country === "Germany") {
			countryCode = "+49";
		} else if (country === "France") {
			countryCode = "+33";
		} else if (country === "Spain") {
			countryCode = "+34";
		} else if (country === "Netherlands") {
			countryCode = "+31";
		} else if (country === "Australia") {
			countryCode = "+61";
		}

		if (toNumber.indexOf(countryCode) < 0) {
			toNumber = countryCode + toNumber;
		}

		let data = {};
		data.id = recordId;
		data.object = item.communication_info.objectName.toLowerCase();
		data.toNumber = toNumber;
		data.fieldName = fieldName;
		data.country = country;
		data.fullName = fullName;
		data.isDetailView = true;
		let style = {};
		style.width = '40%';
		dispatch(getAppCustomDrawer(true, label, type, style, data, null, false));
	}
	const getTwilioPopOver = (field, value) => {
		let popover = null;
		try {
			popover =
				<Menu
					open={isTwilioPopOverOpen}
					anchorEl={anchorEl}
					keepMounted id='modules'
					onClose={() => closeTwilioPopOver()}
				>
					<MenuItem key='call' id='call' value='call'
							  onClick={() => openTwilioDialog(field, 'call')}

					>{
						<Icon style={{ height: 14, width: 14, margin: 9, fontSize: 14 }}>call</Icon>} {getLocalizedStrings().label.TWILIO.CALL}</MenuItem>
					<MenuItem key='sms' id='sms' value='sms'
							  onClick={() => openTwilioDialog(field, 'sms')}
					> {
						<Icon style={{ height: 14, width: 14, margin: 9, fontSize: 14 }}>textsms</Icon>} {getLocalizedStrings().label.TWILIO_SMS.SEND_SMS}</MenuItem>
				</Menu>

		} catch (error) {
			console.error("Error in 'Card.js -> getPopOverElement()':" + error);
		}
		return popover;
	}
	const openTwilioPopOver = (event, fieldName) => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
		setIsTwilioPopOverOpen(true);
	}
	const closeTwilioPopOver = (fieldName) => {
		setIsTwilioPopOverOpen(false);
	};
	const openTwilioDialog = (field, value) => {
		setIsTwilioPopOverOpen(false);
		let fieldName = field.name;
		let recordId = item.communication_info.id;
		let object = item.communication_info.objectName.toLowerCase();
		if (value === 'call') {
			if (field.is_ring_central) {
				openRingCentralWebWidget();
			} else {
				if (field.is_twilio) {
					let value = getStringParam(item.communication_info[fieldName]);
					getCapabilityTokenForCall(object, recordId, true, fieldName, value);
				}
			}
		} else if (value === 'sms') {
			getRecordDetailsForSMS(recordId, fieldName);
		}
	}
	const openDetailView = (field) => {
		try {
			if (isValidParam(item.recordId) && isValidParam(item.tableId)) {
				let recordId = getStringParam(item.recordId).trim();
				let objectName = TABLEID_OBJECT_MAP[getStringParam(item.tableId)];
				if (field.name === 'fullname' && item.contactId) {
					objectName = constants.CONTACTS_OBJECT;
					recordId = item.contactId;
				}
				let linkTo = 'detailview/' + recordId;
				let linkToUrl = '/' + objectName + "/" + linkTo;
				linkToUrl = makeValidAppUrl(linkToUrl);

				let companyName = item.company;
				if (item.tableId === OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT] || (field.name === 'fullname' && item.contactId)) {
					companyName = item.fullname;
				}
				if (!isValidParam(companyName) || companyName === '') {
					let objectList = getArrayParam(app.objectList).filter(f => f.name === TABLEID_OBJECT_MAP[item.tableId]);
					companyName = objectList.length > 0 ? objectList[0].label : '';
				}
				let tab = {
					label: companyName,
					object: objectName,
					type: TYPE_DETAIL_VIEW,
					url: '/' + objectName + "/" + linkTo,
					info: {}
				};
				addTab(tab);
				setRedirect(true);
				setRedirectUrl(linkToUrl);
			}
		} catch (error) {
			console.error("Error in 'Card.js -> openDetailView()':" + error);
		}
	}
	const openRecord = (field, label, recordId) => {
		let isLookup = getBooleanParam(field.is_lookup_field);
		let linkUrl = '#';
		try {
			if (item.type === constants.SMS_OBJECT || item.type === constants.EMAIL_OBJECT) {
				isLookup = false;
				openDetailView(field);
			}
			if (isLookup) {
				label = getStringParam(label);

				let params = {};
				params.parent_object = getStringParam(object);
				params.parent_record_id = recordId;
				params.lookup_table_id = getIntParam(field.lookup_table_id);
				params.lookup_field_id = getIntParam(field.lookup_field_id);
				params.field_value = label;

				let promise = Promise.resolve(getLookupRecordInfo(params));
				if (isValidParam(promise)) {
					promise.then(response => {
						let lookupInfo = response;
						let lookupObject = getStringParam(lookupInfo.object).trim();
						let lookupRecordId = getIntParam(lookupInfo.record_id);
						if (lookupObject !== '' && lookupRecordId > 0) {
							linkUrl = getCustomLink({ object: lookupObject, field: field }, lookupRecordId);
							openTab(label, lookupObject, lookupRecordId, linkUrl, true, field);
						} else {
							let msg = getLocalizedStrings().message.COMMON.COULDNOT_LOCATE + label + "." + getLocalizedStrings().message.COMMON.DELETED_OR_ACCESS_DENIED;
							sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
						}
					});
				}
			}
		} catch (error) {
			console.error("Error in 'Card.js -> openRecord()':" + error);
		}
	}
	const isDetailLinkField = (field, fieldName) => {
		field = field.filter(f => f.name === fieldName && f.is_detail_link);
		if (field !== null && field.length > 0) {
			return true;
		} else {
			return false;
		}
	}
	const getLeadScoreHistory = (leadScore) => {
		let data = {};
		data.parent_object = object;
		data.parent_record_id = item.id;
		data.lead_score = leadScore;
		dispatch(getAppDrawer(true, getLocalizedStrings().label.AUDIT_LEADSCORE.LEAD_SCORE_HISTORY, constants.LEAD_SCORE_HISTORY, data, constants.DETAIL_VIEW));
	}
	const getTitleFieldValue = (fields, recordData) => {
		let fieldName = null;
		let fieldType = null;
		let value = '';
		let field = null;
		try {
			field = getArrayParam(fields);
			if (field.length > 0 && isValidParam(recordData)) {
				let titleField = field.filter(f => f.is_detail_link === true);
				if (object === constants.TASKS) {
					titleField = field.filter(f => f.name === 'taskName');
				}
				titleField = titleField.length > 0 ? titleField[0] : {};
				fieldName = getStringParam(titleField.name);
				fieldType = getStringParam(titleField.field_type);

				if (Object.keys(recordData).length > 0 && fieldName !== '') {
					if ((fieldName === 'first_name' || fieldName === 'last_name') && recordData.hasOwnProperty('name')) {
						value = getStringParam(recordData.name).trim();
						value = value === '' ? 'Empty' : value;
						delete recordData.name;
					} else if ((fieldName === 'city' || fieldName === 'state' || fieldName === 'country') && recordData.hasOwnProperty('address')) {
						value = getStringParam(recordData.address).trim();
						value = value === '' ? 'Empty' : value;
						delete recordData.address;
					} else if (fieldType === constants.FIELD_TYPE_FLOAT) {
						value = getStringParam(recordData[fieldName]) === '' ? 'Empty' : recordData[fieldName];
						if (value !== 'Empty' && fieldName !== "opportunity_amount") {
							value = getFloatParam(value);
						}
						delete recordData[fieldName];
					} else if (fieldType === constants.FIELD_TYPE_INTEGER) {
						value = getStringParam(recordData[fieldName]) === '' ? 'Empty' : recordData[fieldName];
						if (value !== 'Empty') {
							value = getIntParam(value);
						}
						delete recordData[fieldName];
					} else {
						value = getStringParam(recordData[fieldName]).trim();
						value = value === '' ? 'Empty' : value;
						delete recordData[fieldName];
					}
				}
			}

		} catch (error) {
			console.error("Error in 'Card.js -> getTitleFieldValue()':" + error);
		}
		return value
	}
	const handleSendNow = () => {
		let recordData = item;
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		try {
			let hasPermission = hasAccessPermission(constants.TASKS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
			if (hasPermission && !isInXpress) {
				let params = {};
				params.id = recordData.id;
				params.type = recordData.type;
				params.taskType = recordData.taskType;
				params.recordId = recordData.recordId;

				let url = endPoints.TASK.SEND_NOW;
				const promise = Promise.resolve(HTTPClient.get(url, params));
				promise.then((response) => {
					if (response.status === -1) {
						if (recordData.type === constants.SMS_OBJECT) {
							dispatch(showCustomSnackBar(getLocalizedStrings().message.SMS_INTEGRATION[response.data.toUpperCase()], styles.snackbarBodyStyleError, styles.snackBarStyleTop));
						}
					} else {
						if (recordData.type === constants.EMAIL_OBJECT) {
							dispatch(showCustomSnackBar(getLocalizedStrings().message.APPOINTMENTS_TASKS.SEND_EMAIL_PROGRESS_MSG, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
						} else if (recordData.type === constants.SMS_OBJECT) {
							dispatch(showCustomSnackBar(getLocalizedStrings().message.SMS_INTEGRATION.BATCH_SMS_SENT, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
						}
					}
					refreshTaskPage();
				});
			} else {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
			}
		} catch (error) {
			console.error("error in 'Card.js -> handleSendNow()':" + error);
		}
	}
	const refreshTaskPage = () => {
		try {
			let info = getObjectParam(getActiveTabInfo());
			let tasksParams = {};
			tasksParams.sales_rep = getStringParam(info.userName);
			tasksParams.status = getArrayParam(info.status).join();
			
			if (info.hasOwnProperty('workflowId')) {
				if (info.workflowId === -9999) {
					tasksParams.is_call_for_all = true;
				}
			}
			if(object === constants.TASKS) {
				dispatch(getTaskLists(object, tasksParams));
			} else {
				dispatch(getLists(object, tasksParams));
			}
		} catch (error) {
			console.error("error in 'Card.js -> refreshTaskPage()':" + error);
		}
	}
	const generatePickAction = (titleFieldValue, titleColor) => {
		let datePickElement = [];
		let isEdit = true;
		let recordData = item;
		let object = getStringParam(recordData.object);
		let linkUrl = object === constants.TASKS ? '#' : getLinkUrl(object, recordData.id);
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		try {

			if (recordData.type === constants.SMS_OBJECT || recordData.type === constants.EMAIL_OBJECT) {
				if ((recordData.columnName === constants.TASKS_OVERDUE || (recordData.taskType === 'DirectMail')) && isValidParam(recordData.emailIdList) && getStringParam(recordData.emailIdList) !== '') {
					isEdit = false;
				}
				let hasPermission = hasAccessPermission(constants.TASKS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
				if (!hasPermission || isInXpress) {
					isEdit = false;
				}
				if (!isEdit) {
					datePickElement.push(
						<span title={titleFieldValue} style={{ color: titleColor, float: 'left', width: '82%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'left', paddingLeft: '5px' }}>{titleFieldValue}</span>);
				} else {

					datePickElement.push(
						<div key={'datepicker-main' + recordData['parentId']}>
							<div className=" dpvalue" title={titleFieldValue} style={{ color: titleColor, paddingLeft: 5, paddingBottom: 5, marginTop: -1, width: '85%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{titleFieldValue}</div>
							<div className="rech_date dpinvisible">

							</div>
						</div>
					);
				}
			} else {
				datePickElement.push(
					<span title={titleFieldValue} style={{ float: 'left', width: '100%',fontSize: 14, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'left' }}>&nbsp;&nbsp;
						<Link title={titleFieldValue} to={linkUrl} onClick={object === constants.TASKS ? () => openMiniDrawer(titleFieldValue, object, recordData.id, linkUrl, true, 'itemName',recordData) : () => openTab(titleFieldValue, object, recordData.id, linkUrl, true, null)} style={{ color: titleColor }}>{titleFieldValue}</Link></span>);

			}

		} catch (error) {
			console.error("Error in 'Card.js -> generatePickAction()':" + error);
		}
		return datePickElement;
	}
	const populateArr = (selectedFields, fildName) => {
		let newArr = [];
		let indexO = selectedFields.indexOf(fildName);
		for (let i = 0; i < selectedFields.length; i++) {
			if (i !== indexO) {
				newArr.push(selectedFields[i])
			}
		}
		selectedFields = newArr;
		return selectedFields;
	}
	const getColumnViewElement = () => {
		let element = null;
		try {
			let selectedFields = getArrayParam(item.selectedFields);
			let tempFields = getArrayParam(item.fields);
			if (object === constants.TASKS) {
				let taskInfo = [];
				if (app.me.calendar_setup.length > 0 && app.me.calendar_setup[0].taskInfo !== null && app.me.calendar_setup[0].taskInfo !== undefined) {
					taskInfo = app.me.calendar_setup[0].taskInfo;
				}
				let isAccount = 1;
				let isContact = 0;
				let isOpportunity = 0;
				if (taskInfo.length > 0) {
					let checkInfoArr = taskInfo.split(',');


					checkInfoArr.forEach((item, i) => {
						let ObjRecordArr = item.split('_');
						let tableId = getIntParam(ObjRecordArr[0].trim());
						if (tableId === OBJECT_TABLEID_MAP.accounts) {
							isAccount = getIntParam(ObjRecordArr[1]);
						} else if (tableId === OBJECT_TABLEID_MAP.contacts) {
							isContact = getIntParam(ObjRecordArr[1]);
						} else if (tableId === OBJECT_TABLEID_MAP.opportunities) {
							isOpportunity = getIntParam(ObjRecordArr[1]);
						}
					});
				}

				let newArr = [];

				if (isAccount === 0 && selectedFields.indexOf("company") !== -1) {
					newArr = populateArr(selectedFields, "company");
					selectedFields = newArr;
				}
				if (isOpportunity === 0 && selectedFields.indexOf("opportunity_name") !== -1) {
					newArr = populateArr(selectedFields, "opportunity_name");
					selectedFields = newArr;
				}
				if (isContact === 0 && selectedFields.indexOf("fullname") !== -1) {
					newArr = populateArr(selectedFields, "fullname");
					selectedFields = newArr;
				}

			}
			if (object === constants.TASKS && item.type=== 'tasks') {
				selectedFields = selectedFields.length > 6 ? selectedFields.slice(0, 6) : selectedFields;
			}else{
			selectedFields = selectedFields.length > 5 ? selectedFields.slice(0, 5) : selectedFields;
			}

			let hasName = !!(selectedFields.includes("first_name") && selectedFields.includes("last_name"));

			let recordData = getFielValue(selectedFields, item);

			let titleFieldValue = getTitleFieldValue(tempFields, recordData);
			let height = hasName || object === constants.TASKS ? 100 : 120;
			if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.OPPORTUNITIES_OBJECT || object === constants.TASKS) {
				height = 'auto';
			}
			let titleColor = '';
			if (object === constants.TASKS) {
				if (item.columnName === constants.TASKS_TODAY) {
					titleColor = '#717171';
				} else if (item.columnName === constants.TASKS_TOMORROW || item.columnName === constants.TASKS_FUTURE) {
					titleColor = '#228B22';
				} else if (item.columnName === constants.TASKS_OVERDUE) {
					titleColor = '#ff0000';
				}
			}
			let iconName = 'fa fa-clock-o';
			let iconText = "";
			let iconTitle = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS;
			if (isValidParam(item.type)) {
				if (item.type === constants.EMAIL_OBJECT) {
					iconName = 'fa fa-paper-plane';
					iconTitle = "Email";
				} else if (item.type === constants.SMS_OBJECT) {
					iconName = 'material-icons';
					iconText = "textsms";
					iconTitle = "SMS";
				}
			}
			let isTask = false;
			if (item.type === 'email' || item.type === 'sms' || item.type === 'tasks') {
				isTask = true;
			}
			let borderLeftStyle = '';
			let titleName = '';
			if (!isTask && item.hasOwnProperty('updated_on') && item.object !== constants.ISSUES_OBJECT && false) {
				let borderProps = getCardColorByUpdatedDate(item);
				borderLeftStyle = borderProps.leftBorderStyle;
				titleName = borderProps.titleName;
			}

			let itemContentStyle = {};
			if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.OPPORTUNITIES_OBJECT || object === constants.TASKS) {
				itemContentStyle = { height: 'auto' }
			}

			// working here style issue

			element =
				<div style={{ ...style, height: height, marginTop: '0px', paddingRight: 5, borderLeft: borderLeftStyle, width: '100%' }} title={titleName} className="item" id={style ? item.id : null}>
					<div className="item-container" style={{ height: 'auto' }}>
						<div className='item-content' style={{ ...itemContentStyle }}>
							<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'left', display: 'flex' }}>
								{isTask &&
									<span style={{ color: titleColor, fontSize: '14px', paddingLeft: '5px', width:'27px' }} title={iconTitle}>
                <i className={iconName} aria-hidden="true" style={{ marginTop: '3px', fontSize: '15px', color: titleColor/*'#929292'*/ }}>{iconText}</i>
              </span>}
								{generatePickAction(titleFieldValue, titleColor)}
								<span title={getLocalizedStrings().label.COMMON.MORE} style={{ width: '20px' }}>
									<a href="#" onClick={openListviewActionList}><Icon style={{ fontSize: '17px', color: 'black' }}>more_vert</Icon></a>
									<Menu
										anchorEl={anchorEl}
										anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
										targetOrigin={{ horizontal: 'left', vertical: 'top' }}
										onClose={() => closeListviewActionList()}
										open={openActionList}
									>
										{actionsList}
									</Menu>
             					 </span>
							</div>
							{generateCard(tempFields, recordData, item.id)}
						</div>
						{(object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.OPPORTUNITIES_OBJECT || (object === constants.TASKS && item.type === constants.TASKS)) &&
							<div>
								<div style={{ paddingLeft: '3px', paddingRight: 3, marginTop: 10, marginBottom: 10 }}>
									<ItemAdditionalDetails
										customEvent={customEvent}
										fields={item.fields}
										itemid={item.id}
										record={recordData}
										object={object}
										app={app}
										userlist={userlist}
										getAppDrawer={dispatch(getAppDrawer)}
										status={item.columnName}
										task={task}
										appointment={appointment}
										showCustomSnackBar={dispatch(showCustomSnackBar)}
										item={item}
										getTasksRefresh={dispatch(getTasksRefresh)}
										lists={lists}
										updateCards={updateCards}
										communicationFields={communicationFields}
									/>
								</div>
							</div>
						}
						{object === constants.TASKS && item.type !== constants.TASKS &&
							<div style={{ height: '10px' }}></div>
						}
					</div>
				</div>
		} catch (error) {
			console.error("Error in 'Card.js -> getColumnViewElement()':" + error);
		}
		return element;
	}
	const getCardColorByUpdatedDate = (item) => {
		let leftBorderStyle = '';
		let titleName = '';
		let borderProps = {};
		try {
			let date1 = new Date().getTime();
			let date2 = new Date(item.updated_on).getTime();
			let days = Math.floor((date1 - date2) / (1000 * 3600 * 24));
			if (days <= 30) {
				leftBorderStyle = '4px solid #aad356';
				titleName = getLocalizedStrings().message.LIST_VIEW.CHECKBOX_COLOR_GREEN_TITLE;
			} else if (days > 30 && days <= 60) {
				leftBorderStyle = '4px solid #f9c908';
				titleName = getLocalizedStrings().message.LIST_VIEW.CHECKBOX_COLOR_YELLOW_TITLE;
			} else {
				leftBorderStyle = '4px solid #f35844';
				titleName = getLocalizedStrings().message.LIST_VIEW.CHECKBOX_COLOR_RED_TITLE;
			}
			borderProps = {
				leftBorderStyle: leftBorderStyle,
				titleName: titleName
			}
		} catch (error) {
			console.error("Error in 'Card.js -> getCardColorByUpdatedDate()':" + error);
		}
		return borderProps;
	}
	const handleConvertToAccounts = (dataObj) => {
		try {
			let hasPermission = hasAccessPermission(constants.ACCOUNTS_OBJECT, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_CREATE);
			if (hasPermission) {
				let labelName = "";
				let params = {};
				params.is_linked_contact = true;
				params.parent_object = constants.ACCOUNTS_OBJECT;
				params.object = constants.CONTACTS_OBJECT;
				params.record_id = dataObj.id;
				let promise = Promise.resolve(isLinkedRecord(params));
				if (isValidParam(promise)) {
					promise.then((response) => {
						if (isValidParam(response) && response.is_linked) {
							sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.LIST_VIEW.CONVERT_CONTACT_TO_ACCOUNT_CONFIRM, () => onConfirmAddTab(response), null);
						} else {
							let data = {
								object: constants.ACCOUNTS_OBJECT,
								parent_object: dataObj.object,
								parent_record_id: dataObj.id,
								is_convert: true,
								mode: constants.SF_FORM_OPEN_MODE_QUICK
							};

							labelName = getLocalizedStrings().label.COMMON.CONVERT_TO_ACCOUNTS;
							dispatch(getAppDrawer(true, labelName, constants.CONVERT_TO_ACCOUNTS, data, constants.LIST_VIEW));
						}
					});
				}
			} else {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
			}
		} catch (error) {
			console.error("Error in 'Card.js -> handleConvertToAccounts()':" + error);
		}

	}
	const onConfirmAddTab = (response) => {
		let linkDetails = response.link_details;
		let label = isInvalidParam(linkDetails.title) ? 'Empty' : linkDetails.title;

		let tab = {
			item: constants.CONTACTS_OBJECT,
			label: label,
			object: constants.ACCOUNTS_OBJECT,
			imgName: null,
			url: '/accounts/detailview/' + linkDetails.id,
			type: TYPE_DETAIL_VIEW,
			info: {}
		};
		addTab(tab, true);
	}
	const validateConvert = (dataObj) => {
		let params = {};
		params.record_ids = [];
		params.record_ids.push(dataObj.id);
		let promise = Promise.resolve(isWorkflowExists(params));
		if (isValidParam(promise)) {
			promise.then((response) => {
				if (isValidParam(response) && response.is_workflow_exists) {
					convert(dataObj);
				} else {
					let alertDialogMessage = getLocalizedStrings().message.BATCH_ACTION.RECORD_NOT_IN_WORKFLOW;
					sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertDialogMessage, null)
				}

			});

		}

	}
	const getTwilloPopover = (recordData) => {
		let element = null;
		if (recordData.hasOwnProperty('communication_info')) {
			let cFields = communicationFields[recordData.communication_info.objectName.toLowerCase()];
			let cInfo = recordData.communication_info;
			if (cInfo.hasOwnProperty('mobile') && isValidParam(cInfo.mobile) && cInfo.mobile !== '') {
				let mobile_field = cFields.filter(f => f.name === 'mobile' && f.is_phone_field);
				element = getTwilioPopOver(mobile_field[0], cInfo.mobile);
			}
		}
		return element;
	}

	const getLinkUrl = (object, recordId) => {
		let linkUrl = '#';
		if (recordId > 0) {
			if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.OPPORTUNITIES_OBJECT
				|| object === constants.CASES_OBJECT || object === constants.PROJECTS_OBJECT || object === constants.ISSUES_OBJECT
				|| object === constants.SOLUTIONS_OBJECT || object === constants.CUSTOM_TABLE1_OBJECT || object === constants.CUSTOM_TABLE2_OBJECT
				|| object === constants.CUSTOM_TABLE3_OBJECT || object === constants.CUSTOM_TABLE4_OBJECT || object === constants.JOBS_OBJECT) {
				linkUrl = '/' + object + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + recordId;
			}
		}
		return linkUrl;
	}
	if (redirect) {
		return <NavigateBeforeRounded push to={redirectUrl}/>;
	}
	if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.CASES_OBJECT
		|| object === constants.PROJECTS_OBJECT || object === constants.ISSUES_OBJECT || object === constants.CUSTOM_TABLE1_OBJECT
		|| object === constants.CUSTOM_TABLE2_OBJECT || object === constants.CUSTOM_TABLE3_OBJECT || object === constants.CUSTOM_TABLE4_OBJECT
		|| object === constants.TASKS || object === constants.JOBS_OBJECT || (object === constants.OPPORTUNITIES_OBJECT && cardType === 'columnview')) {

		return getColumnViewElement();
	} else if (object === constants.OPPORTUNITIES_OBJECT) {
		let linkUrl = getLinkUrl(object, item.id);
		let closeDate = new Date(item.closeDate);
		let momentDate = moment(closeDate);
		let close_date = momentDate.format(dateFormat[app.me.date_format]);

		let twilloPopover = getTwilloPopover(item);
		let salesRepmail = '';
		let userList = userlist.data;
		let salesRepArr = userList.filter(f => {
			return f.value === item.salesRep
		});
		if (salesRepArr.length > 0) {
			salesRepmail = salesRepArr[0].email;
		}
		return (
			<div style={{ ...style, height: 'auto', marginTop: '0px', width: '100%' }} className="item" id={style ? item.id : null}>
				<div className="item-container" style={{ height: 113 }}>
					<div className="item-content" style={{ height: 'auto' }}>
						<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'left' }}>

							<span title={"Name: " + item.name} style={{ float: 'left', width: '82%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'left', fontSize: 14 }}>&nbsp;&nbsp;
								<Link title={"Name: " + item.name} to={linkUrl} onClick={() => openTab(item.name, object, item.id, linkUrl, true, null)} /*style={{ color: '#585858' }}*/>{item.name}</Link></span>

							<span title={getLocalizedStrings().label.COMMON.MORE} style={{ float: 'right', marginRight: '4px' }}><a href="#" onClick={openListviewActionList}><Icon style={{ fontSize: '17px', color: 'black' }}>more_vert</Icon></a>
								<Menu
									anchorEl={anchorEl}
									anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
									targetOrigin={{ horizontal: 'left', vertical: 'top' }}
									onClose={() => closeListviewActionList()}
									open={openActionList}
								>
									{actionsList}
								</Menu>
							</span>
						</div>
						<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'left', fontSize: 12, color: '#717171' }} title={"Forecast$: " + item.foreCastAmount + "," + " Probability%: " + item.probability + "%"}>
							<span>&nbsp;&nbsp;{`${item.foreCastAmount}`}&nbsp;({`${item.probability}`}%)</span>
						</div>
						<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'left', color: '#717171', fontSize: 12 }} title={"Sales Rep: " + item.salesRep}>
							<span>&nbsp;&nbsp;
							{header?.selectedHeader == "Forecast" ? <span>{item.salesRep}</span>:
							<Link style={{ color: '#717171' }} title={"Sales Rep: " + item.salesRep} to={'#'} onClick={() => sendMail(salesRepmail, constants.TYPE_EMAIL, item.object.toLowerCase())}>{item.salesRep}</Link>
							}
							</span>
						</div>
						<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'left', fontSize: 12, color: '#717171' }} title={"Close Date: " + close_date}>
							{/* &nbsp;&nbsp;<Icon style={{ fontSize: '1em' }}>date_range</Icon> */}
							<span style={{ color: '#717171' }}>&nbsp;&nbsp;{`${close_date}`}</span>
						</div>
					</div>
					<div>
						<div style={{ paddingLeft: '3px', paddingRight: 3, marginTop: 10, marginBottom: 10 }}>
							{twilloPopover}
							<ItemAdditionalDetails
								customEvent={customEvent}
								fields={item.fields}
								itemid={item.id}
								record={item}
								object={object}
								app={app}
								userlist={userlist}
								getAppDrawer={dispatch(getAppDrawer)}
								status={item.columnName}
								task={task}
								appointment={appointment}
								showCustomSnackBar={dispatch(showCustomSnackBar)}
								item={item}
								getTasksRefresh={dispatch(getTasksRefresh)}
								lists={lists}
								updateCards={updateCards}
								communicationFields={communicationFields}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

}


function openRingCentralWebWidget() {
	const rcs = document.createElement("script");
	rcs.src = "https://ringcentral.github.io/ringcentral-embeddable-voice/adapter.js";
	rcs.id = "ring-central_widget_adapter";
	const rcs0 = document.getElementsByTagName("script")[0];
	if (rcs0.id !== rcs.id) {
		rcs0.parentNode.insertBefore(rcs, rcs0);
	}
	if (window.RCAdapter) {
		window.RCAdapter.setMinimized(false);
	}
}

function getCustomLink(props, recordId) {
	let linkUrl = '#';
	try {
		if (isValidParam(props)) {
			let object = props.object;
			let field = props.field;
			let isLookup = getBooleanParam(field.is_lookup_field);
			if (isValidParam(field)) {
				if (isLookup) {
					if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.OPPORTUNITIES_OBJECT
						|| object === constants.CASES_OBJECT || object === constants.PROJECTS_OBJECT || object === constants.ISSUES_OBJECT
						|| object === constants.SOLUTIONS_OBJECT || object === constants.CUSTOM_TABLE1_OBJECT || object === constants.CUSTOM_TABLE2_OBJECT
						|| object === constants.CUSTOM_TABLE3_OBJECT || object === constants.CUSTOM_TABLE4_OBJECT || object === constants.JOBS_OBJECT) {
						linkUrl = '/' + object + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + recordId;
					}
				}
			}
		}
	} catch (error) {
		console.error("Error in 'detailViewFields.js -> getCustomLink()':" + error);
	}
	return linkUrl;
}

const ItemAdditionalDetails = ({ lists, status, item, object, record, communicationFields, updateCards, itemid, app, userlist, task, appointment, customEvent }) => {
	const [isPopOverOpen, setIsPopOverOpen] = useState(false);
	const [fieldName, setFieldName] = useState('');
	const [fieldValue, setFieldValue] = useState('');
	const [optionsArray, setOptionsArray] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);
	const [popOverMode, setPopOverMode] = useState(null);
	const [hoverApiCall, setHoverApiCall] = useState(false);
	const [tooltip, setTooltip] = useState({
		email: '',
		phone: '',
		mobile: ''
	});
	const [error, setError] = useState({
		email: '',
		phone: '',
		mobile: ''
	});
	const [anchorEl, setAnchorEl] = useState(null);
	const [disableIconClick, setDisableIconClick] = useState(false);
	const [clickInfo, setClickInfo] = useState({
		trigger: false,
		id: null,
	});

	useEffect(() => {
		setToolTipValue();
	}, []);

	const getRecord = () => {
		let listIndex = lists.findIndex(f => f.columnName === status);
		let cardIndex = lists[listIndex].cards.findIndex(f => f.id === item.id);
		let cardRecord = null;
		lists.forEach((item, i) => {
			if (listIndex === i) {
				item.cards.forEach((record, index) => {
					if (cardIndex === index) {
						cardRecord = record;
					}

				});
			}
		});
		return cardRecord;

	}

	const setToolTipValue = (msg = false) => {
		if (msg) {
			if (object === constants.OPPORTUNITIES_OBJECT || object === constants.TASKS) {
				let tempTooltip = {
					email: 'No email found',
					phone: 'No phone found',
					mobile: 'No mobile found',
				}
				setTooltip(tempTooltip);
			}
			return;
		}

		let emailTooltip = 'No email found';
		let phoneTooltip = 'No phone found';
		let mobileTooltip = 'No mobile found';
		if (object === constants.OPPORTUNITIES_OBJECT || object === constants.TASKS) {
			emailTooltip = '';
			phoneTooltip = '';
			mobileTooltip = '';
		}
		if (hoverApiCall) {
			emailTooltip = 'No email found';
			phoneTooltip = 'No phone found';
			mobileTooltip = 'No mobile found';
		}

		let cardRecord = record
		if (cardRecord !== null && cardRecord.hasOwnProperty('communication_info')) {
			let communication_fields = communicationFields[cardRecord.communication_info.objectName.toLowerCase()];
			if (Array.isArray(communication_fields) && communication_fields.length > 0) {
				let email_fields = communication_fields.filter(f => f.is_email_field === true && f.name !== 'mobile');
				let phone_fields = communication_fields.filter(f => f.is_phone_field === true && f.name !== 'mobile');
				let mobile_fields = communication_fields.filter(f => f.name === 'mobile');


				let emailArray = [];
				let phoneArray = [];
				let mobileArray = [];
				if (Array.isArray(email_fields) && email_fields.length > 0) {
					email_fields.forEach((field) => {
						let value = cardRecord.communication_info[field.name];
						if (value !== undefined && value !== '') {
							emailArray.push(value);
						}
					});
				}
				if (Array.isArray(phone_fields) && phone_fields.length > 0) {
					phone_fields.forEach((field) => {
						let value = cardRecord.communication_info[field.name];
						if (value !== undefined && value !== '') {
							phoneArray.push(value);
						}
					});
				}
				if (Array.isArray(mobile_fields) && mobile_fields.length > 0) {
					mobile_fields.forEach((field) => {
						let value = cardRecord.communication_info[field.name];
						if (value !== undefined && value !== '') {
							mobileArray.push(value);
						}
					});
				}

				if (emailArray.length > 0) {
					emailTooltip = emailArray.join('\n');
				}
				if (phoneArray.length > 0) {
					phoneTooltip = phoneArray.join('\n');
				}
				if (mobileArray.length > 0) {
					mobileTooltip = mobileArray.join('\n');
				}
			}

		}
		let tempTooltip = {
			email: emailTooltip,
			phone: phoneTooltip,
			mobile: mobileTooltip
		}
		setTooltip(tempTooltip);
	}

	const setTextValue = (fieldNameValue, event) => {
		setFieldName(fieldNameValue);
		setFieldValue(event.target.value);
	}

	const validData = () => {
		let field_name = fieldName;
		let value = fieldValue.trim();
		if (field_name === 'phone' || field_name === 'mobile') {
			if (value === '') {
				let tempError = error;
				tempError[field_name] = 'Please enter value for this field';
				setError(tempError);
				return false;
			}
		} else if (field_name === 'email') {
			let tempError = error;
			if (value === '') {
				tempError.email = 'Please enter value for this field';
				setError(tempError);
				return false;
			} else if (!ParameterVerifier.isEmail(value)) {
				tempError.email = 'Invalid Email';
				setError(tempError);
				return false;
			}
		}
		return true;
	}

	const updateRecord = () => {
		if (!validData()) {
			return false;
		}

		let cardRecord = record;

		if (cardRecord !== null && cardRecord.hasOwnProperty('communication_info')) {
			let communication_fields = communicationFields[cardRecord.communication_info.objectName.toLowerCase()];
			let fields = communication_fields.filter(f => f.name === fieldName);
			let params = {};
			params.id = item.communication_info.id;
			params.fields = [];
			params.auditrail_action_type = constants.INLINE_UPDATE_AUDIT_TRAIL_RECORD;
			let tempField = {};
			tempField.id = fields[0].id;
			tempField.name = fields[0].name;
			tempField.value = fieldValue.trim();

			params.fields.push(tempField);

			let object = item.communication_info.objectName.toLowerCase();

			let promise = SFFormActions.saveRecordPromise(object, params);
			promise.showCustomSnackBar = showCustomSnackBar;
			promise.then((response) => {
				if (ParameterVerifier.isValidParam(response) && response.hasOwnProperty('data') && response.data.hasOwnProperty('id')) {
					let id = ParameterVerifier.isValidParam(response.data.id) && Array.isArray(response.data.id) && response.data.id.length > 0 ? response.data.id[0] : 0;
					if (id > 0) {
						promise.showCustomSnackBar(getLocalizedStrings().message.COMMON.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
						let listIndex = lists.findIndex(f => f.columnName === status);
						let cardIndex = lists[listIndex].cards.findIndex(f => f.id === item.id);
						lists[listIndex]['cards'][cardIndex]['communication_info'][fieldName] = fieldValue.trim();
						let updatedList = lists;
						updateCards(updatedList);
						setToolTipValue();
					} else {
						setToolTipValue(true);
					}
				}
			});
			closePopOver();
		}

	}

	const openPopoverforAdd = (name, event) => {
		event.preventDefault();
		setFieldName(name);
		setIsPopOverOpen(true);
		setAnchorEl(event.currentTarget);
		setPopOverMode(1);
	}

	const handleMouseHover = (icon, event) => {
		if ((object === constants.OPPORTUNITIES_OBJECT || object === constants.TASKS) && !hoverApiCall) {
			setHoverApiCall(true);
			let url = endPoints.COLUMN_VIEW.ADDITIONAL_FIELDS_GET;
			url = url.replace('{object}', object);
			let endPoint = url;
			let communicationFields = communicationFields;
			let inputFields = {};
			let objects = Object.keys(communicationFields);
			objects.forEach(function (object) {
				let fields = communicationFields[object];
				let fieldName = [];
				fields.forEach((fld, i) => {
					fieldName.push(fld.name);
				});
				inputFields[object] = fieldName;
			});

			let params = {
				recordId: item.id,
				object: object,
				call_from: constants.COLUMN_VIEW,
				is_column_view: true,
				fieldsList: inputFields,
			};
			let listIndex = lists.findIndex(f => f.columnName === status);
			let cardIndex = lists[listIndex].cards.findIndex(f => f.id === item.id);
			const promise = Promise.resolve(HTTPClient.post(endPoint, params));
			setDisableIconClick(true);
			if (isValidParam(promise)) {
				promise.then(response => {
					if (isValidParam(response)) {
						if (response.data.isRecords) {
							lists.forEach((item, i) => {
								if (listIndex === i) {
									item.cards.forEach((record, index) => {
										if (index === cardIndex) {
											record.communication_info = response.data;
										}
									});
								}
							});
							updateCards(lists);
						}
						setToolTipValue();
						setDisableIconClick(false);
						if (clickInfo.trigger === true) {
							let tempClickInfo = clickInfo;
							tempClickInfo.trigger = false;
							setClickInfo(tempClickInfo);
							if (tempClickInfo.id !== null) {
								document.getElementById(clickInfo.id).click();
							}
						}
						;
					}
				});
			}
		}
	}

	const isValid = (icon) => {
		let reponse = true;
		let cardRecord = record;
		let objectName = null;
		if (object === constants.OPPORTUNITIES_OBJECT) {
			objectName = 'opportunity';
		} else if (object === constants.TASKS) {
			objectName = 'task';
		} else if (object === constants.ACCOUNTS) {
			objectName = 'account';
		}
		let communication_fields = communicationFields[cardRecord.communication_info.objectName.toLowerCase()];
		if (icon === 'email' || icon === 'phone' || icon === 'mobile') {
			if (!cardRecord.communication_info.isRecords) {
				let msg = getLocalizedStrings().message.COMMON.DOESNT_HAVE_ASSOCIATED_1 + objectName + getLocalizedStrings().message.COMMON.DOESNT_HAVE_ASSOCIATED_2;
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
				return false;
			}
		}

		if (!isValidParam(cardRecord.communication_info[icon]) || cardRecord.communication_info[icon] === '') {
			return true;
		}
		if (icon === 'phone') {
			let fields = communication_fields.filter(f => f.is_phone_field === true && f.name !== 'mobile');
			if (!fields[0].is_click_to_call) {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.PHONE_CALL_NOT_ENABLED, null);
				return false;
			} else if (!fields[0].is_twilio) {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.NOT_CONFG_CALL_SETUP, null);
				return false;
			}
		} else if (icon === 'mobile') {
			let fields = communication_fields.filter(f => f.name === 'mobile');
			if (!fields[0].is_twilio) {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.NOT_CONFG_CALL_SMS_SETUP, null);
				return false;
			}
		}
		return reponse;
	}


	const handleIconClickEvent = (icon, event) => {
		let cardRecord = record;
		if (disableIconClick) {
			let tempClickInfo = clickInfo;
			tempClickInfo.trigger = true;
			tempClickInfo.id = event.target.id;
			event.stopPropagation();
			event.preventDefault()
			setClickInfo(tempClickInfo)
			return false;
		}
		let objectName = null;
		if (object === constants.OPPORTUNITIES_OBJECT) {
			objectName = 'opportunity';
		} else if (object === constants.TASKS) {
			objectName = 'task';
		} else if (object === constants.ACCOUNTS) {
			objectName = 'account';
		}

		if (cardRecord !== null) {
			if (!cardRecord.hasOwnProperty('communication_info') && icon !== 'clock') {
				let msg = getLocalizedStrings().message.COMMON.DOESNT_HAVE_ASSOCIATED_1 + objectName + getLocalizedStrings().message.COMMON.DOESNT_HAVE_ASSOCIATED_2;
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
				return;
			}
			if (icon !== 'clock' && !isValid(icon)) {
				return false;
			}
			if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.OPPORTUNITIES_OBJECT || object === constants.TASKS) {
				if ((icon === 'email' || icon === 'phone')) {
					let searchField = null;
					if (icon === 'email') {
						searchField = 'is_email_field';
					} else if (icon === 'phone') {
						searchField = 'is_phone_field';
					}
					let communication_fields = communicationFields[cardRecord.communication_info.objectName.toLowerCase()];

					let fields = communication_fields.filter(f => f[searchField] === true && f.name !== 'mobile');
					if (Array.isArray(fields) && fields.length > 0) {
						let options = [];
						fields.forEach((field) => {
							let value = cardRecord.communication_info[field.name];
							if (isValidParam(value) && value !== '') {
								options.push(
									{
										id: field.id,
										'value': value,
										fieldName: field.name
									}
								);
							}
						});
						if (options.length === 0 && cardRecord.communication_info.isRecords) {
							openPopoverforAdd(icon, event);
						} else if (options.length === 1) {
							customEvent(fields[0], options[0].value, event);
						} else {
							event.preventDefault();
							setOptionsArray(options);
							if (options.length > 0) {
								setSelectedOption(options[0]);
							}
							setPopOverMode(2);
							setFieldName(icon);
							setIsPopOverOpen(true);
							setAnchorEl(event.currentTarget);
						}

					} else {
						if (cardRecord.communication_info.isRecords) {
							openPopoverforAdd(icon, event);
						}
					}
				} else if (icon === 'mobile') {
					let communication_fields = communicationFields[cardRecord.communication_info.objectName.toLowerCase()];
					let fields = communication_fields.filter(f => f.name === 'mobile');
					if (Array.isArray(fields) && fields.length === 1) {
						let value = cardRecord.communication_info['mobile'];
						if (isValidParam(value) && value !== '') {
							customEvent(fields[0], value, event);
						} else {
							openPopoverforAdd(icon, event);
						}

					} else if (Array.isArray(fields) && fields.length === 0) {
						openPopoverforAdd(icon, event);
					}
				} else if (icon === 'clock') {
					setPopOverMode(3);
					setFieldName(icon);
					setIsPopOverOpen(true);
					setAnchorEl(event.currentTarget);
				}
			}
		}

	}

	const closePopOver = () => {
		setIsPopOverOpen(false);
	}

	const getOptionHtml = () => {
		return optionsArray.map((option) => {
				return <FormControlLabel value={option.id} control={
					<Radio key={option.id} color="default"/>} label={
					<span className="radio-label" title={option.value}>{option.value}</span>}/>
			}
		);
	}

	const doAction = (event) => {
		let cardRecord = record;
		if (cardRecord !== null && cardRecord.hasOwnProperty('communication_info')) {
			let communication_fields = communicationFields[cardRecord.communication_info.objectName.toLowerCase()];
			let fields = communication_fields.filter(f => f.name === selectedOption.fieldName);
			if (Array.isArray(fields) && fields.length === 1) {
				let value = selectedOption.value;
				if (value !== undefined && value !== '') {
					closePopOver();
					customEvent(fields[0], value, event);
				}
			}
		}
	}

	const changeRadioButton = (event, value) => {
		let option = optionsArray.filter(f => f.id === value);
		if (Array.isArray(option) && option.length === 1) {
			setSelectedOption(option[0]);
		}
	}

	const getClockColor = () => {
		let color = "#929292";
		if (object !== constants.TASKS) {
			if (record.hasOwnProperty('task_appointment')) {
				let appTask = record["task_appointment"];
				if (appTask && appTask.hasOwnProperty('status')) {
					let status = record["task_appointment"]['status'];
					if (status === 'red') {
						color = "#FF0000";
					} else if (status === 'grey') {
						color = "#929292";
					} else if (status === 'green') {
						color = "#228B22";
					}
				}
			}
		}
		return color;
	}

	let colockColor = getClockColor();
	let textLabel = '';
	if (popOverMode !== null && popOverMode === 1) {
		if (fieldName === 'email') {
			textLabel = 'Enter Email';
		} else if (fieldName === 'phone') {
			textLabel = 'Enter Phone';
		}
		if (fieldName === 'mobile') {
			textLabel = 'Enter Mobile';
		}
	}
	let getOptions = '';
	let optionLabel = '';
	if (popOverMode !== null && popOverMode === 2) {
		getOptions = getOptionHtml();
		if (fieldName === 'email') {
			optionLabel = 'Please select an email';
		} else if (fieldName === 'phone') {
			optionLabel = 'Please select a phone';
		}
	}
	return (
		<div key={"wrapper-div" + itemid}>
			<div key={"inner-div1" + itemid} style={{ display: 'inline-block', width: '70%' }}>
			</div>
			{object !== constants.TASKS &&
				<div key={"inner-div2" + itemid} style={{ display: 'inline-block', width: '30%', textAlign: 'right' }}>
					<i key={"clock" + itemid} title={'Tasks and Appointments'} className='fa fa-clock' onClick={(e) => handleIconClickEvent('clock', e)} style={{ color: colockColor, fontSize: 16, cursor: 'pointer', padding: '2px', }}/>
				</div>}
			<Popover
				open={isPopOverOpen}
				anchorEl={anchorEl}
				anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
				targetOrigin={{ horizontal: 'left', vertical: 'top' }}
				onClose={closePopOver}
				key={"email-popover" + itemid}
			>
				{popOverMode !== null && popOverMode === 1 &&
					<div style={{ width: 200, maxWidth: 130, height: 120, padding: 10, paddingTop: 15 }}>
						<TextField
							variant="standard"
							key={"email-text" + itemid}
							fullWidth={false}
							onChange={(e) => setTextValue(fieldName, e)}
							style={{ width: 180, height: 40, lineHeight: 40 }}
							autoFocus={true}
							label={textLabel}
							InputLabelProps={{
								style: {
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									width: '96%',
								}
							}}
							inputStyle={styles.textField.input}
							error={error[fieldName] !== ''}
							helperText={error[fieldName]}
							autoComplete="new-password"
						/>
						<div style={{ textAlign: 'right', padding: 0, margin: 0, marginTop: 20, marginBottom: 10 }}>
							<Button

								style={{ ...styles.primaryButton, padding: 0, marginRight: '0px !important', lineHeight: '25px', height: 25, minWidth: '20px', width: '36%', marginTop: '7px' }}
								labelstyle={{ fontWeight: 'normal' }}
								onClick={updateRecord}

							>{getLocalizedStrings().label.COMMON.SAVE}</Button>
						</div>
					</div>
				}
				{popOverMode !== null && popOverMode === 2 && optionsArray.length > 0 &&
					<div style={{ width: 'auto', maxWidth: '350px', height: 'auto', padding: '20px', paddingTop: '20px' }}>
						<Menu
							open={isPopOverOpen}
							anchorEl={anchorEl}
							onClose={() => closePopOver()}
							keepMounted>
							<div>{optionLabel}</div>
							<div style={{ height: 'auto', maxHeight: 160, minHeight: 80, overflow: 'auto' }}>
								<FormControl component="fieldset">
									<RadioGroup onChange={changeRadioButton} defaultValue={optionsArray[0].id} style={{ marginTop: '10px', fontSize: '15px' }}>
										{getOptions}
									</RadioGroup>
								</FormControl>
							</div>
							<div style={{ textAlign: 'right', padding: 0, margin: 0, marginTop: 10 }}>
								<Button
									style={{ ...styles.primaryButton, padding: 0, marginRight: '0px !important', lineHeight: '25px', height: 25, minWidth: '20px' }}
									labelstyle={{ fontWeight: 'normal' }}
									onClick={doAction}
								> Go </Button>
							</div>
						</Menu>
					</div>
				}
				{popOverMode !== null && popOverMode === 3 &&
					<div style={{ width: 'auto', maxWidth: 400, height: 'auto', padding: 20, marginTop: 0, paddingTop: 0 }}>
						<Menu
							open={isPopOverOpen}
							anchorEl={anchorEl}
							onClose={() => closePopOver()}
							keepMounted>
							<ColumnViewTaskApps
								app={app}
								userlist={userlist}
								itemid={itemid}
								object={object}
								status={status}
								closeParentPopOver={closePopOver}
								task={task}
								appointment={appointment}
								showCustomSnackBar={showCustomSnackBar}
								item={item}
								getTasksRefresh={getTasksRefresh}
								lists={lists}
								record={record}
								updateCards={updateCards}
							/>
						</Menu>
					</div>
				}
			</Popover>
		</div>
	)
}
export default Card;