import React from 'react';
import { Box, MenuItem, TextField, Grid } from '@mui/material';
import { INTAKE_FORM } from '../../../../../services/constants/constants';
import moment from 'moment';
import PopOver from '../../PopOver';
import { dateFormatFlatPicker } from '../../../../../services/helper/utils';
import { useSelector } from 'react-redux';

const OtherInfoCustomForm = ({ jsonData, formData, setFormData }) => {
    const app = useSelector((state)=> state.app);

    const handleDropDownChange = (event, dropDownName) => {
        setFormData({
            ...formData,
            [dropDownName]: event.target.value,
        });
    };

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const handleDateChange = (name, date) => {
        const formattedDate = moment(date).valueOf();
        setFormData({
            ...formData,
            [name]: formattedDate,
        });
    };

    const generateDatePickerField = (field) => {
        const {input3Placeholder, input3Name } = field;

       let datePickerOptions = {
            enableTime: false,
            dateFormat: dateFormatFlatPicker[app.me.date_format],
            static: true,
        };

        let date = formData[input3Name] ? moment(formData[input3Name]).format('YYYY-MM-DD') : '';

        return (
            <div style={{ paddingLeft: '0px' }} >
                <div
                    className={"sf-form-field dateTimeDiv-end_date"}
                    id="dateholder"
                >
                    <PopOver
                        id={'sf-date-time-picker2'}
                        className={'date-time anval'}
                        btnType={'date'}
                        buttonStyle={{ fontSize: '16px' }}
                        containerStyle={{ lineHeight: 1 }}
                        buttonLabel={input3Placeholder}
                        onChange={(date) => handleDateChange(field.input3Name, date)}
                        value={date}
                        innerLabelStyle={{ padding: "12 5 2 5", fontSize: '15px' }}
                        height={41}
                        options={{ ...datePickerOptions }}
                        
                    />
                </div>
            </div>
        );
    };

    const generateInputField = (field) => {
        const { id, name, type, title, required, multiline, max_lines, inputPlaceholder } = field;

        return (
            <TextField
                size={'small'} 
                key={id}
                id={id}
                label={title}
                placeholder={inputPlaceholder}
                name={name}
                value={formData[name] || ''}
                onChange={handleChange}
                type={type}
                required={required}
                multiline={multiline}
                maxRows={max_lines}
                fullWidth
                rows={max_lines}
            />
        );
    };

    const generateDropdownField = (field) => {
        const { id, dropDownName, title, required, options } = field;
        return (
            <TextField
                size={'small'} 
                key={id}
                select
                style={{ width: '100%' }}
                label={title}
                title={title}
                name={dropDownName}
                value={formData[dropDownName] || ''}
                onChange={(event) => handleDropDownChange(event, dropDownName)}
                required={required}>
                {options.map((option, i) => (
                    <MenuItem key={i} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        );
    };

    const generateDropDownWithInput = (field) => {
        const { title, required, options, dropDownName, input1Name, input1Placeholder, inputType } = field;

        return (
            <Grid container spacing={2} key={field.id}>
                <Grid item xs={3.5}>
                    <TextField
                        size={'small'}
                        select
                        style={{ width: '100%' }}
                        label={title}
                        title={title}
                        name={dropDownName}
                        value={formData[dropDownName] || ''}
                        className='dropdownwithinput'
                        onChange={handleChange}
                        required={required}
                    >
                        {options.map((option, i) => (
                            <MenuItem key={i} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={8.5}>
                    <TextField
                        size={'small'}
                        name={input1Name}
                        label={input1Placeholder}
                        value={formData[input1Name] || ''}
                        onChange={handleChange}
                        type={inputType}
                        fullWidth
                    />
                </Grid>
            </Grid>
        );
    };

    const generateDropDownWithThreeInput = (field) => {
        const {
            title,
            required,
            options,
            dropDownName,
            input1Name,
            input1Placeholder,
            input1Type,
            input2Name,
            input2Placeholder,
            input2Type,
            input3Name,
            input3Placeholder,
            input3Type,
        } = field;

        return (
            <Grid container spacing={2} key={field.id}>
                <Grid item xs={3}>
                    <TextField
                        size={'small'}
                        select
                        title={title}
                        style={{ width: '100%' }}
                        label={title}
                        name={dropDownName}
                        value={formData[dropDownName] || ''}
                        className='dropdownwiththreeinput'
                        onChange={handleChange}
                        required={required}>
                        {options.map((option, i) => (
                            <MenuItem key={i} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        size={'small'}
                        name={input1Name}
                        label={input1Placeholder}
                        value={formData[input1Name] || ''}
                        onChange={handleChange}
                        type={input1Type}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        size={'small'}
                        name={input2Name}
                        label={input2Placeholder}
                        value={formData[input2Name] || ''}
                        onChange={handleChange}
                        type={input2Type}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    {generateDatePickerField(field)}
                </Grid>
            </Grid>
        );
    };

    const generateDropDownWithFiveInput = (field) => {
        const {
            title,
            required,
            options,
            dropDownName,
            input1Name,
            input1Placeholder,
            input1Type,
            input2Name,
            input2Placeholder,
            input2Type,
            input3Name,
            input3Placeholder,
            input3Type,
            input4Name,
            input4Placeholder,
            input4Type,
            input5Name,
            input5Placeholder,
            input5Type,
        } = field;

        return (
            <Grid container spacing={2} key={field.id}>
                <Grid item xs={3}>
                    <TextField
                        size={'small'}
                        select
                        title={title}
                        style={{ width: '100%' }}
                        label={title}
                        name={dropDownName}
                        value={formData[dropDownName] || ''}
                        className='dropdownwithinput'
                        onChange={handleChange}
                        required={required}>
                        {options.map((option, i) => (
                            <MenuItem key={i} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        size={'small'}
                        name={input1Name}
                        label={input1Placeholder}
                        value={formData[input1Name] || ''}
                        onChange={handleChange}
                        type={input1Type}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        size={'small'}
                        name={input2Name}
                        label={input2Placeholder}
                        value={formData[input2Name] || ''}
                        onChange={handleChange}
                        type={input2Type}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        size={'small'}
                        name={input3Name}
                        label={input3Placeholder}
                        value={formData[input3Name] || ''}
                        onChange={handleChange}
                        type={input3Type}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        size={'small'}
                        name={input4Name}
                        label={input4Placeholder}
                        value={formData[input4Name] || ''}
                        onChange={handleChange}
                        type={input4Type}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        size={'small'}
                        name={input5Name}
                        label={input5Placeholder}
                        value={formData[input5Name] || ''}
                        onChange={handleChange}
                        type={input5Type}
                        fullWidth
                    />
                </Grid>
            </Grid>
        );
    };

    const generateDropDownWithSixInput = (field) => {
        const {
            title,
            required,
            options,
            dropDownName,
            input1Name,
            input1Placeholder,
            input1Type,
            input2Name,
            input2Placeholder,
            input2Type,
            input3Name,
            input3Placeholder,
            input3Type,
            input4Name,
            input4Placeholder,
            input4Type,
            input5Name,
            input5Placeholder,
            input5Type,
            input6Name,
            input6Placeholder,
            input6Type,
        } = field;

        return (
            <Grid container spacing={2} key={field.id}>
                <Grid item xs={3}>
                    <TextField
                        size={'small'}
                        select
                        style={{ width: '100%' }}
                        label={title}
                        title={title}
                        name={dropDownName}
                        value={formData[dropDownName] || ''}
                        className='dropdownwithinput'
                        onChange={handleChange}
                        required={required}>
                        {options.map((option, i) => (
                            <MenuItem key={i} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        size={'small'}
                        name={input1Name}
                        label={input1Placeholder}
                        value={formData[input1Name] || ''}
                        onChange={handleChange}
                        type={input1Type}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        size={'small'}
                        name={input2Name}
                        label={input2Placeholder}
                        value={formData[input2Name] || ''}
                        onChange={handleChange}
                        type={input2Type}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        size={'small'}
                        name={input3Name}
                        label={input3Placeholder}
                        value={formData[input3Name] || ''}
                        onChange={handleChange}
                        type={input3Type}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        size={'small'}
                        name={input4Name}
                        label={input4Placeholder}
                        value={formData[input4Name] || ''}
                        onChange={handleChange}
                        type={input4Type}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        size={'small'}
                        name={input5Name}
                        label={input5Placeholder}
                        value={formData[input5Name] || ''}
                        onChange={handleChange}
                        type={input5Type}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        size={'small'}
                        name={input6Name}
                        label={input6Placeholder}
                        value={formData[input6Name] || ''}
                        onChange={handleChange}
                        type={input6Type}
                        fullWidth
                    />
                </Grid>
            </Grid>
        );
    };

    const generateDropDownWithSevenInput = (field) => {
        const {
            title,
            required,
            options,
            dropDownName,
            input1Name,
            input1Placeholder,
            input1Type,
            input2Name,
            input2Placeholder,
            input2Type,
            input3Name,
            input3Placeholder,
            input3Type,
            input4Name,
            input4Placeholder,
            input4Type,
            input5Name,
            input5Placeholder,
            input5Type,
            input6Name,
            input6Placeholder,
            input6Type,
            input7Name,
            input7Placeholder,
            input7Type,
        } = field;

        return (
            <Grid container spacing={2} key={field.id}>
                <Grid item xs={3}>
                    <TextField
                        size={'small'}
                        select
                        style={{ width: '100%' }}
                        label={title}
                        title={title}
                        name={dropDownName}
                        value={formData[dropDownName] || ''}
                        className='dropdownwithinput'
                        onChange={handleChange}
                        required={required}>
                        {options.map((option, i) => (
                            <MenuItem key={i} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        size={'small'}
                        name={input1Name}
                        label={input1Placeholder}
                        value={formData[input1Name] || ''}
                        onChange={handleChange}
                        type={input1Type}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        size={'small'}
                        name={input2Name}
                        label={input2Placeholder}
                        value={formData[input2Name] || ''}
                        onChange={handleChange}
                        type={input2Type}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        size={'small'}
                        name={input3Name}
                        label={input3Placeholder}
                        value={formData[input3Name] || ''}
                        onChange={handleChange}
                        type={input3Type}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        size={'small'}
                        name={input4Name}
                        label={input4Placeholder}
                        value={formData[input4Name] || ''}
                        onChange={handleChange}
                        type={input4Type}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        size={'small'}
                        name={input5Name}
                        label={input5Placeholder}
                        value={formData[input5Name] || ''}
                        onChange={handleChange}
                        type={input5Type}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        size={'small'}
                        name={input6Name}
                        label={input6Placeholder}
                        value={formData[input6Name] || ''}
                        onChange={handleChange}
                        type={input6Type}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        size={'small'}
                        name={input7Name}
                        label={input7Placeholder}
                        value={formData[input7Name] || ''}
                        onChange={handleChange}
                        type={input7Type}
                        fullWidth
                    />
                </Grid>
            </Grid>
        );
    };

    const renderField = (field) => {
        const { dataType } = field;

        switch (dataType) {
            case INTAKE_FORM.INPUT:
                return generateInputField(field);
            case INTAKE_FORM.DROPDOWN:
                return generateDropdownField(field);
            case INTAKE_FORM.DROPDOWNWITHINPUT:
                return generateDropDownWithInput(field);
            case INTAKE_FORM.DROPDOWNTHREEINPUT:
                return generateDropDownWithThreeInput(field);
            case INTAKE_FORM.DROPDOWNWITHFIVEINPUT:
                return generateDropDownWithFiveInput(field);
            case INTAKE_FORM.DROPDOWNWITHSIXINPUT:
                return generateDropDownWithSixInput(field);
            case INTAKE_FORM.DROPDOWNWITHSEVENINPUT:
                return generateDropDownWithSevenInput(field);
            case INTAKE_FORM.DATE_PICKER:
                return generateDatePickerField(field);
            default:
                return null;
        }
    };

    return (
        <form>
            <Box component="form" noValidate autoComplete="off">
                <Grid container spacing={2}>
                    {jsonData.map((field, index) => (
                        <Grid item xs={field.section === 111 || field.section === 4 ? 6 : 12} key={index}>
                            {renderField(field)}
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </form>
    );
};

export default OtherInfoCustomForm;
