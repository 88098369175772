import map from 'lodash/map';
import range from 'lodash/range';
import { constants } from '..//constants/constants';
import { actions } from '..//constants/actions';
import store from '../../../src/services/store';
import { addTab } from '..//helper/sfTabManager';
import { getAppDrawer } from '..//actions/appContainerActions';
import { getLocalizedStrings } from '..//constants/MultiLingual';

const dashboardInitialSate = {
    dashboardId: 0,
    mounted: false,
    config: {
        className: "layout",
        autoSize: true,
        verticalCompact: true,
        rowHeight: 30,
        onLayoutChange: function () { },
        // breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
        // cols: { lg: 4, md: 4, sm: 4, xs: 2, xxs: 1 },
        breakpoints:{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0},
        cols:{lg: 12, md: 12, sm: 12, xs: 3, xxs: 1},        
        margin: [20, 20],
        containerPadding: [20, 20],
        useCSSTransforms: false
    },
    currentBreakpoint: 'lg',
    layouts: [],
    data: [],
    error: null,
    isRedirect: false,
    redirectUrl: '',
    dashboardURL: '',
    inactiveWidgets:[]
}

function generateLayout(dashboardData) {
    return map(range(0, dashboardData.length), function (item, i) {
        return {
            i: dashboardData[i].i.toString(),
            x: dashboardData[i].x,
            y: dashboardData[i].y,
            w: dashboardData[i].w,
            h: dashboardData[i].h,
            header: dashboardData[i].header,
            autoSize: true,
            type: dashboardData[i].type,
            format: dashboardData[i].format,
            isResizable: false, //dashboardData[i].isResizable,
            isDraggable: false, //dashboardData[i].isDraggable,
            details: dashboardData[i].details,
            margin: [10, 10],
            containerPadding: [10, 10],
            //draggableCancel: '.dragCancel',
            //draggableHandle: '.headerStyle',
            widgetName: dashboardData[i].widgetName
        };
    });
}

function drillDown(dashboardData, widgetId, _state) {
    let selectWidgetData = {};
    let query_id = null;
    var timeLine = '';//localStorage.getItem("dashboardTimeline") === null ? 'ty' : localStorage.getItem("dashboardTimeline");
    if(_state.app.selectedModuleName === constants.MODULE_MARKETING) {
        timeLine = localStorage.getItem('marketingDashboardTimeline') === null ? 'ty' : localStorage.getItem("marketingDashboardTimeline");
    } else if(_state.app.selectedModuleName === constants.MODULE_SALES){
        timeLine = localStorage.getItem('salesDashboardTimeline') === null ? 'ty' : localStorage.getItem("salesDashboardTimeline");
    }
    _state.dashboard.data.widgets?.forEach((dashboardData) => {
        if (dashboardData.i === widgetId) {
            selectWidgetData = dashboardData;
        }
    });
    query_id = selectWidgetData.details.query_id;



    if (selectWidgetData.header === "Top Sources By Lead Volume") {

        let object_name = "accounts";
        let customInputParamObject = {
            "group_by_field_name": "source",
            "group_by_condition": dashboardData.category,

            "report_type": "11",
            "report_search_condition": " j_leads.t_status <> 'Customer'",
            "click_form": "DASHBOARD"
        }
        addRedirectToTab(object_name, query_id, timeLine, customInputParamObject, selectWidgetData.header, dashboardData.category, _state);
    } else if (selectWidgetData.header === "Top Sources By Lead Conversion") {


        //  searchValue = "t_status = 'Customer'";
        let object_name = "accounts";
        let customInputParamObject = {
            "group_by_field_name": "source",
            "group_by_condition": dashboardData.category,
            "search_field_name": "t_status",
            "search_text": 'Customer',
            "report_type": "11"
        }
        addRedirectToTab(object_name, query_id, timeLine, customInputParamObject, selectWidgetData.header, dashboardData.category, _state);
    } else if (selectWidgetData.header  === 'Marketing Email Summary') {
       
        let data = {};
        data.event= dashboardData.category;
        data.timeLine = timeLine;//localStorage.getItem('dashboardTimeline');
        let eventLabel = dashboardData.category;
        eventLabel = getLocalizedStrings().label.CAMPAIGNS.hasOwnProperty(eventLabel.toUpperCase()) ? getLocalizedStrings().label.CAMPAIGNS[eventLabel.toUpperCase()] : eventLabel;
        
        store.dispatch(
            // getAppDrawer(true,   data.event+' '+selectWidgetData.header , constants.DASHBOARD_EMAIL_SUMMERY_DATA, data, null)
            getAppDrawer(true,   eventLabel+' '+getLocalizedStrings().label.DASHBOARD[selectWidgetData.header] , constants.DASHBOARD_EMAIL_SUMMERY_DATA, data, null)
        )
    }




}


function addRedirectToTab(object_name, query_id, dueBy, customInputParamObject, reportName,clickFieldName, state) {

    // let object_name = "accounts";
    //  let query_id = 716;
    /* let _state = store.getState();
     let tab = _state.tab;
     tab.tabs.push(
         {
             "label": "All",
             "linkTo": object_name,
             "type": "Queries",
             "value": 1,
             "active": true,
             "imgName": "fa fa-users",
             "filter": {
                 "id": query_id,
                 "name": "",
                 "type": ""
             },
             "input_param": {
                 //   "search_field_name": "author", "search_text": "Buddhasis Mitra"
                 "report_due_by": dueBy,
                 "report_user_names": "all",
                 // "report_from_date": reportParams.from_date,
                 //  "report_to_date": reportParams.to_date,
                 //"group_by_field_name": "",
                 //"group_by_condition": "",
                 ...customInputParamObject
             },
             "url": '/' + object_name + '/queries'
         }
     );
     tab.activeTabIndex = tab.activeTabIndex + 1;
     store.dispatch({
         type: actions.APP_SET_TAB,
         payload: {
             tabs: tab.tabs,
             activeTabIndex: tab.activeTabIndex
         }
     });
 
 
     // Redirect after Tab open
     store.dispatch({
         type: actions.DASHBOARD_REDUCER_REDIRECTION,
         payload: {
             isRedirect: true,
             redirectUrl: '/' + object_name + '/queries'
         }
     });*/

    let groupByObject = {};
    if (customInputParamObject.hasOwnProperty('group_by_field_name')) {
        groupByObject.group_by_field_name = customInputParamObject.group_by_field_name
        //  delete customInputParamObject.group_by_field_name;
    }

    if (customInputParamObject.hasOwnProperty('group_by_condition')) {
        groupByObject.group_by_condition = customInputParamObject.group_by_condition
        //delete customInputParamObject.group_by_condition;
    }

    let query_type = 11;

    let tabParams = {
        "imgName": "fa fa-users",
        "index": 0,
        item: 'dashboard',

        "info": {
            "filter": {
                "id": query_id,
                "name": "",
                //  "type": query_type
                "type": query_type
            },
            "input_param": {
                //   "search_field_name": "author", "search_text": "Buddhasis Mitra"
                "report_due_by": dueBy,
                "report_user_names": "all",
                ...customInputParamObject
            },
            // "groupByFieldName": x_axis_name,
            // "groupByCondition": reportData.name
            isReport:true,
            reportFieldValue: clickFieldName,
            reportLabel: reportName
        },
        "isActive": false,
        "label": reportName,
        "object": object_name,
        "type": 'TYPE_LIST_VIEW',
        "url": '/' + object_name + '/queries',
        ...groupByObject
    };

    addTab(tabParams, true,state);

}

function getPlotOptions(widgetId,state) {
    let plotOption = {
        series: {
            cursor: 'pointer',
            showInLegend: false,
            point: {
                events: {
                    click: function () {
                        drillDown(this, widgetId,state);
                    }
                }
            }
        }
    }
    return plotOption;
}

const dashboardReducer = (state = dashboardInitialSate, action) => {
    switch (action.type) {
        case actions.DASHBOARD_URL_GET_FULFILLED:
            return {
                ...state,
                mounted: true,
                dashboardURL: action.payload //'https://us-east-1.quicksight.aws.amazon.com/embed/121150c7676f4a809a68e73e1038c276/dashboards/92e703b5-2d6e-4cab-b23a-c3fb6f07532d?isauthcode=true&identityprovider=quicksight&code=AYABeKEEU982yF-cQNl23zdmnZsAAAABAAdhd3Mta21zAEthcm46YXdzOmttczp1cy1lYXN0LTE6MjU5NDgwNDYyMTMyOmtleS81NGYwMjdiYy03MDJhLTQxY2YtYmViNS0xNDViOTExNzFkYzMAuAECAQB4l6pD2xhUY2WZ3LzF9ADzT04TvWztj3rAluPGmvaui90B3LSKcUMDbdi_KvzUqVDsZAAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDJIY6XGeoMQt1BMBLgIBEIA7IHGlxuBT-JzsfYQ9QYogYDd6PnJha9wl-dB1npRLFuuhKEYPF_A5AzFqCdzhww3YTIAUbRojm1WlOtcCAAAAAAwAABAAAAAAAAAAAAAAAAAANsI7qtIt_Nl_Ge19qATQrf____8AAAABAAAAAAAAAAAAAAABAAAAmyPLSHgnYm0i59MeJV0s3O1CPgwdNd4-pNVV6NA1yY4LX5rfDUPai_SCFdrhnlBFwWQwcUIpPvqRgWrKPAzAs6B00DAG5SDVFHF2eMJfDtLcgrCG-XDO1ZQDR5e07STpdhResZLJwPnW4-dDYs1FJV7mOkHc_dGMNile7-SZl7dnSgdqpj19WxS7KkfRoveo_mupjOP21xf5XE6183dRI52soSqMxn9ZOTBn4g%3D%3D' //action.payload.dashboardURL
            }        
        case actions.DASHBOARD_GET_FULFILLED:
            const {widgets, id} = action.payload
            for (let i = 0; i < widgets.length; i++) {
                if (widgets[i].format === "report") {
                    widgets[i].details.plotOptions = getPlotOptions(widgets[i].i, action.state);
                }
            }

            return {
                ...state,
                mounted: true,
                layouts: { lg: generateLayout(widgets) },
                data: action.payload,
                dashboardId: id
            }
        case actions.DASHBOARD_ERROR:
            return {
                ...state,
                mounted: false,
                error: action.payload
            }

        case actions.DASHBOARD_CHANGE_LAYOUT:
            return {
                ...state,
                currentBreakpoint: action.payload
            }
        case actions.DASHBOARD_REDUCER_REDIRECTION:

            return {
                ...state,
                isRedirect: action.payload.isRedirect,
                redirectUrl: action.payload.redirectUrl
            } 
        case actions.DASHBOARD_INACTIVE_WIDGET_FULFILLED:
            return {
                ...state,
                inactiveWidgets:action.payload.widgets
            }    
        default:
            return state;
    }
}



export default dashboardReducer;