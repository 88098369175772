import React from 'react';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { styles } from '../../../services/constants/styles';
import Button from '@mui/material/Button';
import { getObjectParam, getBooleanParam, getIntParam, isValidParam} from '../../../services/helper/parameterVerifier';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { openWindow } from '../../../services/helper/utils';
import * as HttpClient from '../../../services/helper/httpClient';
import { isUndefined, isNull } from 'lodash';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";



const PaymentIntegration =({})=> {
    let payemtMethodListArr = [
        {
            value: 'Credit Card',
            label: 'Credit Card',
        },
        {
            value: 'ACH',
            label: 'ACH'
        }
    ];
    const dispatch = useDispatch();
    const [isConnected, setIsConnected] = useState(false); 
    const [isConnecting, setIsConnecting] = useState(false);
    const [isShowConnectButton, setIsShowConnectButton] = useState(false);
    const [attempts, setAttempts] = useState(0);
    const [details, setDetails] = useState(null);
    const [connectedEmail, setConnectedEmail] = useState(null);
    const [payemtMethodList, setPayemtMethodList] = useState(payemtMethodListArr);
    const [defaultpaymentmethod, setDefaultpaymentmethod] = useState("Credit Card");

    useEffect(() => {
        checkIsConnected()
    },[]);



    const checkIsConnected = () => {
        try {
            const url = endPoints.PAYMENT_INTEGRATION.CHECK_CONNECT
            setIsConnecting(true);
            
            HttpClient.post(url, {})
                .then(res => {
                    console.log(`ConnectedAPIPayment`)
                    console.log(res)
                    setIsConnecting(false);
                    if (res.data.flag) {
                        setIsConnected(true);
                        setConnectedEmail(res.dataStr);
                        setDefaultpaymentmethod(res.data.paymentMethod);
                       
                    } else {
                        setIsConnected(false);
                    }
                })

        } catch (error) {
            setIsConnecting(false);
            console.error("Error in 'quickBooksIntegration.js -> checkIsConnected()':" + error);
        }
    }

    const connect = (e) => {
        // e.preventDefault()
        try {
            const url = endPoints.PAYMENT_INTEGRATION.ONBOARD
            console.log(url)
            let openQBWindow = null
            console.log(HttpClient)
            HttpClient.post(url, {})
                .then(res => {
                    if (!isUndefined(res) && res.status !== -1) {
                        if (!isNull(res.data.url)) {
                            openQBWindow = openWindow({ location: res.data.url, spaces: 'scrollbars=1, location=no', width: 850, height: 650 });
                        }
                    }
                })
                setIsConnecting(true);
            let intervalVar = setInterval(() => {
                tryAndCheckIsConnected(openQBWindow, intervalVar)
            }, 3000);
        } catch (error) {
            console.error("Error in 'paymentIntegration.js -> connect()':" + error);
        } <div style={{ borderBottom: '2.0px solid #afafaf5c' }}><span style={{ fontWeight: 'bold', fontSize: '20px' }}>{getLocalizedStrings().label.MY_INTEGRATION.PAYMENT_INTEGRATION}</span></div>
    }

   const disconnectOnboard = () => {
        try {
            const url = endPoints.PAYMENT_INTEGRATION.DISCONNECT_ONBOARD
            HttpClient.post(url, {})
                .then(res => {
                    console.log(`DisconnectOnboard`)
                    console.log(res)
                    res = getObjectParam(res)
                    let flag = getIntParam(res.status)
                    if (flag === 0 && isConnected === true) {
                        setIsConnected(false);
                    }
                })
        } catch (e) {
            console.error(`Problem in disconnect method of paymentIntegration.js -> disconnectOnboard`)
            console.error(e)
        }
    }


    const tryAndCheckIsConnected = (openQBWindow, intervalVar) => {
        console.log(`PopUp`)
       setIsConnecting(true);
        if (openQBWindow !== null && !getBooleanParam(openQBWindow.closed)) {
            try {
                const url = endPoints.PAYMENT_INTEGRATION.CHECK_CONNECT
                HttpClient.post(url, {})
                    .then(res => {
                        console.log(`ConnectedAPIPayment`)
                        console.log(res)
                        if (res.data.flag === true) {
                           
                            if (openQBWindow !== null)
                                openQBWindow.close();
                            clearInterval(intervalVar);
                            if (isConnecting) {
                                setAttempts(0);
                                setDetails(null);
                                setIsConnecting(false);
                                setIsConnected(true);
                                setConnectedEmail(res.dataStr);
                                setDefaultpaymentmethod(res.data.paymentMethod);
                            }
                        } else {
                            
                        }
                    })
            } catch (e) {
                console.error(`Error in paymentIntegration.js-> tryAndCheckIsConnected`)
                console.error(e)
            }
        } else if (openQBWindow !== null && getBooleanParam(openQBWindow.closed)) {
            clearInterval(intervalVar)
            setIsConnecting(false);
        }
    }

    const changePaymentMethod = (event,value) =>{
         value = event.target.value;
        try {
            setDefaultpaymentmethod(value);
        } catch (error) {
            console.error("Error in 'news.js -> changePaymentMethod()': " + error);
        }
    }
    const generatePayementMethodList = () => {
        let listItems = payemtMethodList.map((type, i) => {
            return (
                <MenuItem
                    key={i}
                    id={type.value}
                    value={type.value}
                    style={{ ...styles.popoverMenuItem }}
                    title={type.label} > {type.label}</MenuItem>
            );
        });

        return listItems;
    }
    const savePaymentMethod = () =>{
        let params = {};
        try {
                params.default_payment_method = defaultpaymentmethod;
                var promise = Promise.resolve(HttpClient.post(endPoints.PAYMENT_INTEGRATION.SAVE_PAYEMENT_METHOD, params));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            dispatch (showCustomSnackBar(getLocalizedStrings().message.SECURITY_LEVEL.SUCCESS_MSG_1, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop))
                        }
                    });

                }
            
        } catch (error) {
            console.error("Error in 'payementIntegration.js -> PaymentMethod()':" + error);
        }
    }
   
        let payementMethodList = generatePayementMethodList();
        return <div style={{ marginLeft: '3%' }}>


            <div style={{ borderBottom: '2.0px solid #afafaf5c' }}><span style={{ fontWeight: 'bold', fontSize: '20px' }}>{getLocalizedStrings().label.MY_INTEGRATION.PAYMENT_INTEGRATION}</span></div>


            {true && <div style={{ marginTop: '10px' }}>
                <span ><img style={{
                    width: 100,
                    height: 70
                }} src='/asset/images/stripe_dark.png' /></span>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <div style={{ marginBottom: '0', marginLeft: '10' }}>
                        {
                            <div>
                                {
                                    (isConnected && !isConnecting) && <span style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                        {`Connected as ${connectedEmail}`}
                                    </span>

                                }
                                {
                                    (!isConnected && !isConnecting) && <span style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                        {getLocalizedStrings().label.PAYMENT.LABEL}
                                    </span>
                                }
                            </div>
                        }

                    </div>
                    {
                        !isConnecting &&
                        <div>
                            {
                                !isConnected ? <Button style={styles.primaryButton} onClick={()=>connect()} >{getLocalizedStrings().label.PAYMENT.CONNECT_BUTTON}</Button> :
                                    <Button style={styles.primaryButton} onClick={()=> disconnectOnboard()} >{getLocalizedStrings().label.PAYMENT.DISCONNECT_BUTTON}</Button>
                            }
                        </div>
                    }
                   
                </div>
                {isConnected && 
                        <div style={{marginTop:'20px' }}>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                            <InputLabel id="sf-whitelabel-simple-select-outlined-label" className="sf-paymenttype">{"Default Payment Method"}</InputLabel>
                            <Select
                                onChange={(event,value)=>changePaymentMethod(event,value)}
                                value={defaultpaymentmethod}
                                style={{ height: '42px' }}
                                label={"Default Payment Method"}
                                labelId="user"
                                className={"sf-fields-bg"}
                            >
                                {payementMethodList}
                            </Select>
                            </FormControl>

                            <div style={{ paddingBottom: '30px', overflow: 'hidden', float: 'right',marginTop:'15px' }}>
                                <Button
                                    key='save'
                                    onClick={()=>savePaymentMethod()}
                                    style={{ ...styles.primaryButton, verticalAlign: 'top', marginRight: '-3px' }}
                                >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                            </div>
                        </div>
                       

                    }
            </div>}
            {isConnecting && <span style={{ width: '68%' }}>Connecting<img src='/asset/images/processing.gif' style={{ height: '10px', width: '50px' }} /></span>}
        </div>;
    
}
export default PaymentIntegration