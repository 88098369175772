import { actions } from '..//constants/actions';
import concat from 'lodash/concat';

const taskInitialSate = {
    mounted: false,
    taskDetailViewMounted: false,
    taskHomeMounted: false,
    taskPortalMounted: false,
    task: { data: null },
    data: [],
    error: null,
    isTaskListView: false,
    taskPortalCount: 0,
    isrefresh: false,
    communicationFields: null,
    phoneSetup: null,
    isUnitTaskRefresh: false 
}

const taskReducer = (state = taskInitialSate, action) => {
    switch (action.type) {
        case actions.TASK_GET_FULFILLED:
            return {
                ...state,
                mounted: true,
                data: action.payload,
                isTaskListView: false,
                isrefresh: false
            }
        case actions.TASK_DETAILVIEW_GET_FULFILLED:
            return {
                ...state,
                taskDetailViewMounted: true,
                taskHomeMounted: false,
                taskPortalMounted: false,
                data: action.payload,
                isrefresh: false
            }
        case actions.TASK_HOME_GET_FULFILLED:
            return {
                ...state,
                taskDetailViewMounted: false,
                taskHomeMounted: true,
                taskPortalMounted: false,
                data: action.payload,
                isrefresh: false
            }
        case actions.TASK_PORTAL_FULFILLED:
            return {
                ...state,
                taskDetailViewMounted: false,
                taskHomeMounted: false,
                taskPortalMounted: true,
                data: action.payload.taskPortal,
                taskPortalCount: action.payload.recordCount,
                isrefresh: false,
                phoneSetup: action.payload.phoneSetup
            }
        case actions.TASK_PORTAL_SCROLL_FULFILLED:
            return {
                ...state,
                taskDetailViewMounted: false,
                taskHomeMounted: false,
                taskPortalMounted: true,
                data: concat(state.data, action.payload.taskPortal),
                isrefresh: false
            }
        case actions.TASK_ERROR:
            return {
                ...state,
                mounted: false,
                error: action.payload,
                isrefresh: false
            }

        case actions.TASK_PANE_REFRESH:
            return {
                ...state,
                isrefresh: action.payload
            }

        case actions.CLEAR_TASK_REDUCER:
            return taskInitialSate
        case actions.UNIT_TASK_PANE_REFRESH:
            return {
                ...state,
                isUnitTaskRefresh: action.payload
            }

        default:
            return state;
    }
}

export default taskReducer;