import React from 'react';
import { render } from 'react-dom';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { styles } from '../../../services/constants/styles';
import * as ParameterVerifier from '../../../services/helper/parameterVerifier';
import { constants } from '../../../services/constants/constants';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { uploadPhoto } from '../../../services/actions/batchActions';
import { isValidParam, getObjectParam, getStringParam } from '../../../services/helper/parameterVerifier';
import ShowCircularProgress from '../components/circularProgress';
import { showCustomSnackBar, hideSnackBar } from '../../../services/actions/snackBarAction';
import { refreshDetailViewData } from '../../../services/actions/detailViewActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { uploadProfilePhoto } from '../../../services/actions/UserListAction';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const ProfileImageUpload = () => {
    const dispatch = useDispatch();
    const [imageUrl, setImageUrl] = useState('');
    const [inProgress, setinProgress] = useState(false);
    const [files, setFiles] = useState([]);
    const [id, setId] = useState(null);
    const appContainer = useSelector((state) => state.appContainer);

    useEffect(() => {
        dispatch(setAppDialogActions(getActions()));
    }, [files]);

    const getActions = () => {
        const actions = [
            <Button

                primary={true}
                onClick={() => {
                    onFormSubmit();
                }}
                style={styles.primaryButton}
            >{getLocalizedStrings().label.ATTACHMENT.UPLOAD}</Button>,
            <Button

                primary={true}
                onClick={() => handleClose()}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CLOSE}</Button>

        ];
        return actions;
    }

    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const fnSetImageUrl = () => {
        let data = appContainer.dialogProps.data;

        data.setProfilePicture(imageUrl);
        handleClose();
        setinProgress(false)
    }


    const onChange = (e) => {
        let file = {};
        file.fileName = e.target?.files[0]?.name;
        file.file = e.target?.files[0];

        if (file.file.size === 0) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_BLANK_FILE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            e.target.value = null;
            setFiles([]);
            return false;
        } else {
            let fileName = file.fileName;
            fileName = fileName.replace(/'/g, "");
            let fileext = fileName.substring(fileName.lastIndexOf('.') + 1);
            if (fileext.toLowerCase() !== 'jpg' && fileext.toLowerCase() !== 'jpeg' && fileext.toLowerCase() !== 'gif' && fileext.toLowerCase() !== 'png') {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_LOGO_EXTENSION, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                e.target.value = null;

                setFiles([]);
                return false;
            } else {
                let tempFile = [];
                tempFile[0] = file;
                setFiles(tempFile);
            }
        }
        setinProgress(false);
    }



    const onFormSubmit = (e) => {
        try {
            let tempfiles = files;
            if (!isValidParam(tempfiles) || tempfiles.length === 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.PROFILE_DETAILS.VALIDATE_PROFILE_IMAGE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }

            for (let i = 0; i < tempfiles.length; i++) {
                let fileSize = tempfiles[i].file.size;
                if (fileSize > 0 && fileSize >= constants.MAX_IMAGE_FILE_SIZE_FOR_PROFILE) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.PROFILE_DETAILS.VALIDATE_PROFILE_IMAGE_SIZE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                }
            }
            setinProgress(true);
            const formData = new FormData();
            for (let i = 0; i < tempfiles.length; i++) {
                formData.append('file', tempfiles[i].file);
            }

            let url = null;
            let promise = uploadProfilePhoto(formData);
            promise.then((response) => {
                if (response.data.status === 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.PROFILE_DETAILS.PROFILE_IMAGE_UPLOAD, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    let dialogProps = appContainer.dialogProps;
                    let dialogType = getStringParam(dialogProps.dialogType);
                    if (dialogType === constants.PROFILE_DETAILS_LOGO_UPLOAD_DIALOG) {
                        let _imageUrl = getStringParam(response.data.data.profileImageUrl);
                        if (_imageUrl.length > 0) {
                            setImageUrl(_imageUrl);
                            fnSetImageUrl();
                        }
                    }
                } else {
                    setinProgress(false);
                    handleClose();
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.PROFILE_DETAILS.PROFILE_IMAGE_UPLOAD_FAILURE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
            });
        }
        catch (error) {
            console.error("Error in 'profileImageUpload.js -> onFormSubmit()':" + error);
        }
    }
    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight - 10) / 2;
    if (id === null) {
        let id = appContainer.dialogProps.data.id;
        if (isValidParam(id)) {
            setId(id);
        }
    }

    if (inProgress === false) {
        return (
            <div style={{ marginBottom: '10px' }}>
                <form method="post" id="photoUploadForm" enctype="multipart/form-data" action="uploadPhoto.page" name="uploadPhoto" id="uploadPhoto">
                    <div style={{ border: '1px solid rgb(221, 221, 221)', padding: '20px 20px 17px' }}>
                        <div className="row clearfix">
                            <label className="col-sm-3">{getLocalizedStrings().message.ATTACHMENT.UPLOAD_IMAGE}</label>
                            <input className="col-sm-9" type="file" name="img_name" id="img_to_upload" onChange={(e) => onChange(e)} />
                        </div>
                    </div>
                </form>
                <div className='dialogtxt'>
                    {getLocalizedStrings().message.PROFILE_DETAILS.PROFILE_IMAGE_ATTACHMENT_LOGO_TEXT}
                </div>
                <div className='dialogtxt' style={{ marginBottom: '0px' }}>
                    {getLocalizedStrings().message.PROFILE_DETAILS.PROFILE_IMAGE_SIZE_TEXT}
                </div>
            </div>
        );
    } else {

        return (
            <div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }
}
export default ProfileImageUpload;