import React from 'react';
import { useEffect, useState,useRef  } from "react";
import { useDispatch, useSelector } from "react-redux";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControl, Popover } from '@mui/material';
import { setSearchCriteria, openCloseSearchPopover } from '../../../services/actions/appActions';
import { constants } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { addTab, getActiveTab, TYPE_LIST_VIEW,TYPE_HOME} from '../../../services/helper/sfTabManager';
import { isValidParam, getArrayParam, getBooleanParam, getStringParam, getObjectParam, getSetParam } from '../../../services/helper/parameterVerifier';
import { Link } from 'react-router-dom';
import SFAdvanceSearchForm from '../components/sfAdvanceSearchForm';
import { getImageNameByObject, getObjectLabelByObject, setSelectedModuleInfo, getObjectIdListByModule } from '../../../services/helper/common';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { removeScrollPosition } from '../../../services/actions/listViewAcions';
import { removeColumnViewScrollPosition, removeColumnViewHorizontalScrollPosition } from '../../../services/actions/listsActions';
import RecentSearch from '../components/recentSearch';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { styles } from '../../../services/constants/styles';

const Search =({closeSubMenu})=>{
    const inputRef = useRef(null);
    let url = '/search-results/search';
    const dispatch = useDispatch();
    const [showSearchModueleDiv,setShowSearchModueleDiv] = useState(false);
    const [checkBoxList,setCheckBoxList] = useState([]);
    const [selectedCheckBoxes,setSelectedCheckBoxes] = useState(new Set());
    const [searchCriteriaErr,setSearchCriteriaErr] = useState('');
    const [isOpenAdvanceSearchForm,setIsOpenAdvanceSearchForm] = useState(false);
    const [advanceSearchObject,setAdvanceSearchObject] = useState(null);
    const [isDefaultSelectObject,setIsDefaultSelectObject] = useState(true);
    const [isSearchExist,setIsSearchExist] = useState(false);
    const [searchText,setSearchText] = useState('');
    const [seachAanchorEl,setSeachAanchorEl] = useState(null);
    let app= useSelector((state) => state.app);
    let filters= useSelector((state) => state.filters);

    useEffect(() => {    
        inputRef.current?.focus();
        let funcType=localStorage.getItem("type_"+app.me.projectId+"_"+app.me.id);
        let shouldReturnToInitialPosition = JSON.parse(localStorage.getItem('shouldReturnToInitialPos_'+app.me.projectId+"_"+app.me.id));
        if(funcType == constants.DETAIL || funcType == constants.LIST){
            if(shouldReturnToInitialPosition == true){
                localStorage.removeItem("selectedSearchItemObject_"+app.me.projectId+"_"+app.me.id);
                localStorage.removeItem("searchCriteria_"+app.me.projectId+"_"+app.me.id);
            }
        }
        return () => document.removeEventListener("mousedown",()=>{})
    }, []);
    
    useEffect(() => {    
        let isOpen = false;
        let tempObjName='';
        let activeTab = getObjectParam(getActiveTab());
        let activeTabInfo = getObjectParam(activeTab.info);
        if (activeTab.object === constants.SEARCH_RESULTS) {
            let search = getObjectParam(activeTabInfo.search);
            tempObjName = getStringParam(search.object);
            isOpen = showSearchModueleDiv
            if (app.openSearchPopover.global !== isOpen) {
              setShowSearchModueleDiv(app.openSearchPopover.global);
                    setAdvanceSearchObject(tempObjName);
            }
        } else if (activeTab.object === constants.ADVANCE_SEARCH_RESULTS) {
            let advanceSearch = getObjectParam(activeTabInfo.advanceSearch);
             tempObjName = getStringParam(advanceSearch.object);
            isOpen = isOpenAdvanceSearchForm;
            if (app.openSearchPopover.advance !== isOpen) {
                setIsOpenAdvanceSearchForm(app.openSearchPopover.advance);
                setAdvanceSearchObject(tempObjName);
            }
        }

    }, [app]);
    
    const handleFocus = (event) => {
        closeSubMenu()
        setShowSearchModueleDiv(true);
        setIsSearchExist(true);
        setSeachAanchorEl(event.currentTarget);
        app.openSearchPopover.global = true;
        if (isOpenAdvanceSearchForm) {
            console.log(event);
            setIsOpenAdvanceSearchForm(false);
            setShowSearchModueleDiv(true);
            let openSearchPopover = {};
            openSearchPopover.advance = false;
            openSearchPopover.global = true;
            dispatch(openCloseSearchPopover(openSearchPopover));
        }

        if (isValidParam(app.selectedSearchObject) && isValidParam(app.selectedSearchObject[app.selectedModuleName])) {
            setSelectedCheckBoxes(app.selectedSearchObject[app.selectedModuleName]);
        } else {
            setSelectedCheckBoxes(new Set());
        }
        setSearchObjectList();
    }

    const closeDropDown = () => {
        setShowSearchModueleDiv(false);
        let openSearchPopover = {};
        openSearchPopover.advance = isOpenAdvanceSearchForm;
        openSearchPopover.global = false;
        dispatch(openCloseSearchPopover(openSearchPopover));
    }

    const handleKeyUp = (event) => {
        let search_text = event.target.value.trim();
        if (search_text.toString().length > 0) {
            setSearchCriteriaErr('');
            setSearchText(search_text );
        } else {
            setSearchText(search_text);
        }

        if (event.keyCode === 13) {
            handleSearch(search_text, event);
        }
    }

    const handleSearch = (search_text, event) => {
        if (search_text.trim() === '' && selectedCheckBoxes.size === 0) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.EMPTY_SEARCH_CRITERIA, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }

        if (search_text.trim() === '*' && selectedCheckBoxes.size > 1) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.VALIDATE_MORE_CHECKBOX_SELECTION, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }

        if (search_text.trim() === '*' && selectedCheckBoxes.size === 0) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.VALIDATE_NO_CHECKBOX_SELECTION, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }

        if (search_text.trim() === '') {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.EMPTY_SEARCH_CRITERIA, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }

        if (search_text.trim() !== '' && selectedCheckBoxes.size === 0 ) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SEARCH_NO_CHECKBOX_SELECTION, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }
        let labelName = search_text; 
        let selectedObject = [...selectedCheckBoxes][0];
        let objIndex = checkBoxList.findIndex(v => v.value === selectedObject);
        let searchObject = objIndex > -1 ? selectedObject : checkBoxList[0].value;
     
        if(objIndex === -1){
            handleRadioButton(checkBoxList[0].value);
        }
        let isUnitsListView = false;
        if (searchObject === 'Unit’s Accounts') {
            isUnitsListView = true;
            searchObject = constants.ACCOUNTS_OBJECT;

        }
        let searchObjectLabel = getObjectLabelByObject(searchObject);
        let searchCriteria = {};
        searchCriteria.searchText = search_text;
        searchCriteria.object = searchObject;

        dispatch(setSearchCriteria(searchCriteria));

        if (labelName === "*") {
            labelName = getLocalizedStrings().label.COMMON.RESULTS_FOR + " " + searchObjectLabel + "=*";
        } else {
            labelName = getLocalizedStrings().label.COMMON.RESULTS_FOR + " " + searchObjectLabel + "=" + labelName;
        }

        let iconName = getImageNameByObject(searchObject);
        iconName = getStringParam(iconName);
        iconName = iconName === '' ? getImageNameByObject(constants.SEARCH_RESULTS) : iconName;

        let tempTab = { item: searchObject, label: labelName, object: constants.SEARCH_RESULTS, type: TYPE_LIST_VIEW, imgName: iconName, info: { search: searchCriteria }, url:url,refreshFromSettings :true  };
        if (isUnitsListView) {
            tempTab.info = { ...tempTab.info, isUnitsListView: isUnitsListView, isShow: true };
        }
        if (searchObject === constants.ACCOUNTS_OBJECT && !isUnitsListView) {
            tempTab.info = { ...tempTab.info, globalAccount: true };
        }

        dispatch(removeScrollPosition(searchObject));
        dispatch(removeColumnViewScrollPosition(searchObject));
        dispatch(removeColumnViewHorizontalScrollPosition(searchObject));

        let isCreateHomeTab = searchObject === constants.SOLUTIONS_OBJECT ? false : true;
        if (isCreateHomeTab) {
            let homeTab = { item: searchObject, label: searchObjectLabel + " Home", object: searchObject, type: TYPE_HOME, imgName: 'fa fa-home', url: "/" + searchObject + "/filters", info: {}, isActive: true };
            addTab(tempTab, true, homeTab, isCreateHomeTab);
        } else {
            addTab(tempTab, true);
        }

        setIsDefaultSelectObject(true);
        setShowSearchModueleDiv(false);
        let openSearchPopover = {};
        openSearchPopover.advance = isOpenAdvanceSearchForm;
        openSearchPopover.global = false;
        dispatch(openCloseSearchPopover(openSearchPopover));
        if ([...selectedCheckBoxes][0] === 'Unit’s Accounts') {
            searchObject = 'Unit’s Accounts';
            // localStorage.setItem('searchCriteria', JSON.stringify(searchCriteria));
            localStorage.setItem("searchCriteria_"+app.me.projectId+"_"+app.me.id, JSON.stringify(searchCriteria));

        }
        setSelectedModuleInfo(searchObject);
        if (searchObject !== 'Unit’s Accounts') {
           saveRecentSearchText(searchCriteria);
        }
       localStorageUpdate();
    }

    const localStorageUpdate=()=>{
        let selectedModuleName = app.selectedModuleName;
        let selectedObjectSet = app.selectedSearchObject[selectedModuleName];
        // localStorage.setItem("selectedSearchItemObject", [...selectedObjectSet][0]);
        localStorage.setItem("selectedSearchItemObject_"+app.me.projectId+"_"+app.me.id, [...selectedObjectSet][0]);

    }

    const handleRadioButton = (value) => {
        let tempSelectedCheckBoxes = selectedCheckBoxes;
        tempSelectedCheckBoxes = new Set();
        tempSelectedCheckBoxes.add(value);
        setSelectedCheckBoxes(tempSelectedCheckBoxes);
        let objects = new Set();
        objects.add(value);
        app.selectedSearchObject = getObjectParam(app.selectedSearchObject);
        app.selectedSearchObject[app.selectedModuleName] = objects;
        setSearchCriteriaErr('');
        setAdvanceSearchObject(value);
        document.getElementById("search_text").focus();
    }

    const addOrRemoveSelectedSearchObject = (object, isAdd) => {
        let selectedSearchObject = getObjectParam(app.selectedSearchObject);
        app.selectedSearchObject = selectedSearchObject;
        let objects = selectedSearchObject[app.selectedModuleName];
        objects = getSetParam(objects);
        isAdd = getBooleanParam(isAdd);
        if (isAdd) {
            objects.add(object);
        } else {
            objects.delete(object);
        }
        app.selectedSearchObject[app.selectedModuleName] = objects;
    }

    const setSearchObjectList =()=> {
        let objects = null;
        try {
            let selectedModuleName = getStringParam(app.selectedModuleName);
            if (isValidParam(app.home) && selectedModuleName !== '') {
                let home = getArrayParam(app.home.modules).find(f => { return f.module === selectedModuleName });
                let homeItems = isValidParam(home) ? getArrayParam(home.module_items) : [];
                homeItems = homeItems.filter(f => { return f.is_system_object });
                objects = homeItems.map(m => { return { id: m.object_id, name: m.object, label: m.label } });
                let isfranchisor = app.me.is_franchisor;
                if (isfranchisor) {
                    objects = objects.filter(f => (f.name !== constants.COMPLIANCE_OBJECT && f.name !== constants.REPORTED_SALES_OBJECT));
                }
            }

            if (isValidParam(objects)) {
                let tempCheckBoxList = objects.map(m => { return { value: m.name, label: m.label } });
                if (app.me.is_enableTenantSearch) {
                    let accountExist = tempCheckBoxList.filter(f => { return f.value === constants.ACCOUNTS_OBJECT });
                    if (accountExist.length > 0 && app.me.is_enableTenantSearch) {
                        let index = tempCheckBoxList.findIndex((obj => obj.value === constants.ACCOUNTS_OBJECT));
                        let label = accountExist[0].label;
                        tempCheckBoxList[index].label = "My " + label;
                        let unitOPtionlabel = 'Unit’s ' + label;
                        let newObj = { value: 'Unit’s Accounts', label: unitOPtionlabel };
                        tempCheckBoxList.splice(index + 1, 0, newObj);
                    }
                }
                setCheckBoxList(tempCheckBoxList);
            }
        } catch (error) {
            console.error("Error in 'search.js -> setSearchObjectList()':" + error);
        }
    }

    const openAdvanceSearchForm = (obj) => {
        if (selectedCheckBoxes.size === 1) {
            setShowSearchModueleDiv(false);
            setIsOpenAdvanceSearchForm(true);
            setAdvanceSearchObject(selectedCheckBoxes.values().next().value);
            let openSearchPopover = {};
            openSearchPopover.advance = true;
            openSearchPopover.global = false;
            dispatch(openCloseSearchPopover(openSearchPopover));
        }
    }

    const closeAdvanceSearchForm = (callType) => {
        let openSearchPopover = {};
        addOrRemoveSelectedSearchObject(advanceSearchObject, true);
        handleRadioButton([...app.selectedSearchObject[app.selectedModuleName]][0]);
        if (callType === "onBlur") {
            setShowSearchModueleDiv(false);
            openSearchPopover.global =  false;
        } else {
            setShowSearchModueleDiv( true);
            openSearchPopover.global = true;
        }
        setIsOpenAdvanceSearchForm(false);
        openSearchPopover.advance = false;
        dispatch(openCloseSearchPopover(openSearchPopover));
    }

    const getSearchResult = (obj) => {
        setShowSearchModueleDiv(false);
        setIsOpenAdvanceSearchForm (false);
        let openSearchPopover = {};
        openSearchPopover.advance = false;
        openSearchPopover.global = false;
        dispatch(openCloseSearchPopover(openSearchPopover));
        let isRedirect = getBooleanParam(obj.isRedirect);
        let advanceSearch = getObjectParam(obj.advanceSearch);
        let isHomeTabRequired = advanceSearch.object === constants.SOLUTIONS_OBJECT ? false : true;
        advanceSearch = { advanceSearch: advanceSearch };
        if (isRedirect) {
            dispatch(removeScrollPosition(obj.object));
            dispatch(removeColumnViewScrollPosition(obj.object));
            dispatch(removeColumnViewHorizontalScrollPosition(obj.object));
            let selectedObject = [...selectedCheckBoxes][0];
            if(checkBoxList.length > 0) {
                let isSelectedObjectExistInGlobalSearch = checkBoxList.find((item)=>item.value === selectedObject)
                selectedObject = isSelectedObjectExistInGlobalSearch ? selectedObject : checkBoxList[0]?.value
            }
            setAdvanceSearchObject(selectedObject);
            setSelectedModuleInfo(selectedObject);
            let labelName = getObjectLabelByObject(selectedObject) + ' ' + getLocalizedStrings().label.COMMON.ADVANCED_SEARCH;
            let iconName = getImageNameByObject(constants.ADVANCE_SEARCH_RESULTS);
            let tempTab = { item: selectedObject, label: labelName, object: constants.ADVANCE_SEARCH_RESULTS, type: TYPE_LIST_VIEW, imgName: iconName, info: advanceSearch, url: obj.redirectUrl,refreshFromSettings :true };
            localStorageUpdate();
            if (isHomeTabRequired) {
                let homeTab = { item: selectedObject, label: getObjectLabelByObject(selectedObject) + " Home", object: selectedObject, type: TYPE_HOME, imgName: 'fa fa-home', url: "/" + advanceSearchObject + "/filters", info: {}, isActive: true };
                addTab(tempTab, true, homeTab, true);
            } else {
                addTab(tempTab, true);
            }
        }
    }

    const focusOutSearchField = () => {
        document.addEventListener("mousedown", (e) => {
            let concernedElement = document.querySelector(".advanced-search");
            let concernedSearchElement = document.querySelector(".search");
            if (concernedElement !== null && !concernedElement.contains(e.target)) {
                if (concernedSearchElement !== null && !concernedSearchElement.contains(e.target)) {
                    if (showSearchModueleDiv) {
                        setShowSearchModueleDiv(false);
                        let openSearchPopover = {};
                        openSearchPopover.advance = isOpenAdvanceSearchForm;
                        openSearchPopover.global = false;
                        dispatch(openCloseSearchPopover(openSearchPopover));
                    }
                }
            }
        });
    }

    const handleChange = (event) => {
        try {
            let search_text = event.target.value.trim();
            setSearchText (search_text);
        } catch (error) {
            console.log("Error in 'Search.js -> handleChange()':" + error);
        }
    }

    const saveRecentSearchText = (searchCriteria) => {
        try {
            let url = endPoints.HOME.SAVE_RECENT_SEARCH_TEXT;
            let params = {};
            let chkbox = checkBoxList;
            let objectTableIds = getObjectIdListByModule(chkbox);
            if (isValidParam(searchCriteria)) {
                params.search_text = searchCriteria.searchText;
                params.object = searchCriteria.object;
                params.table_ids = objectTableIds;
                let promise = Promise.resolve(HTTPClient.post(url, params))
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (response.status === 0) {
                            filters.recentSearchesText[app.selectedModuleName] = response.data;
                        }
                    }
                });
            }
        } catch (error) {
            console.log("Error in 'Search.js -> saveRecentSearchText()':" + error);
        }
    }

    // render
    
    let contentHeight = window.innerHeight - 190;
    let defaultSearchObject = null;
    let selectedCheckboxInSearch = 0
    if (app.selectedModuleInfo !== null) {
        let selectedSubModule = app.selectedModuleInfo[app.selectedModuleName]?.item ? app.selectedModuleInfo[app.selectedModuleName].item.object : ''
        selectedCheckboxInSearch = checkBoxList.findIndex(f => f.value === selectedSubModule);
    }
    if (selectedCheckboxInSearch === undefined || selectedCheckboxInSearch === null || selectedCheckboxInSearch < 0) {
        selectedCheckboxInSearch = 0
    }
    if (selectedCheckBoxes.size > 0) {
        defaultSearchObject = selectedCheckBoxes.values().next().value;
        if (checkBoxList.findIndex(o => o.value === defaultSearchObject) === -1) {
            defaultSearchObject = checkBoxList[0]?.value ? checkBoxList[0].value : null
        }

        let obj = checkBoxList.find(f => f.value === defaultSearchObject);
        if (isValidParam(obj)) {
            let selectedRadioObject = new Set();
            if (checkBoxList.length > 0) {
                selectedRadioObject.add(defaultSearchObject);
            }
        } else {
            defaultSearchObject = checkBoxList[selectedCheckboxInSearch]?.value;
            let objects = new Set();
            objects.add(defaultSearchObject);
            app.selectedSearchObject = getObjectParam(app.selectedSearchObject);
            app.selectedSearchObject[app.selectedModuleName] = objects;
        }
    } else if (checkBoxList.length > 0) {
        defaultSearchObject = checkBoxList[selectedCheckboxInSearch].value;
        selectedCheckBoxes.add(defaultSearchObject);

        let objects = new Set();
        objects.add(defaultSearchObject);
        app.selectedSearchObject = getObjectParam(app.selectedSearchObject);
        app.selectedSearchObject[app.selectedModuleName] = objects;
    }

    let advSearchTagStyle = selectedCheckBoxes.size === 1 ? { color: '#337ab7', cursor: 'pointer', fontWeight: 800 } : { color: '#00000061', cursor: 'default', fontWeight: 800 };
    let searchTextPlaceholder = getLocalizedStrings().label.COMMON.SEARCH + "...";
    let searchFieldStyle = {
        backgroundColor: '#fff', paddingRight: '33px'
    }

    if (isSearchExist) {
        searchTextPlaceholder = getLocalizedStrings().label.COMMON.SEARCH_PLACEHOLDER;
    }
    let tempAdvanceSearchObject = advanceSearchObject;
    if (selectedCheckBoxes.size === 1) {
        selectedCheckBoxes.forEach(function (value) {
            tempAdvanceSearchObject = value ? value.trim() : tempAdvanceSearchObject;
        });
    }
    
    let objIndex = checkBoxList.findIndex(v => v.value === tempAdvanceSearchObject);
    let newAdvanceSearchObject = isValidParam(getObjectLabelByObject(tempAdvanceSearchObject)) && objIndex > -1 ? tempAdvanceSearchObject : defaultSearchObject;
    if (isOpenAdvanceSearchForm && newAdvanceSearchObject === 'Unit’s Accounts' ) {
        newAdvanceSearchObject = app.advanceSearch.object;
    }
    return (
        <div>
            <div id='search' className='Search' style={{ width: '100%', padding: '6' }}>
                <input type='text'
                    onClick={(e)=>handleFocus(e)}
                    onKeyUp={(e)=>handleKeyUp(e)}
                    placeholder={searchTextPlaceholder}
                    onBlur={()=>focusOutSearchField()}
                    style={searchFieldStyle}
                    id="search_text"
                    inputprops={{ maxlength: '255' }}
                    onChange={(e)=>handleChange(e)}
                    ref={inputRef}
                    autoComplete="off"
                />
                <i className="fa fa-search" onClick={(e)=>handleSearch(searchText,e)} title={getLocalizedStrings().label.COMMON.CLICK_TO_SEARCH} style={{ height: '20px', cursor: 'pointer' }} />
            </div>
            {showSearchModueleDiv &&
                checkBoxList.length > 0 &&
                <div className="advanced-search-container">
                    <div className="advanced-search" style={{overflow: 'auto', height: contentHeight }}>
                        <div style={{ padding: "20px" }}>
                            <div>
                                <FormControl >
                                    <RadioGroup className="sf-data-fieldset" name="shipSpeed" row
                                        defaultValue={defaultSearchObject}
                                        onChange={(event)=>handleRadioButton(event.target.value)}
                                    >
                                        {checkBoxList.map((obj, index) => {
                                            let objLabel = getLocalizedStrings().label.HEADER.hasOwnProperty(obj.label) ? getLocalizedStrings().label.HEADER[obj.label] : obj.label;
                                            return (
                                                <FormControlLabel value={obj.value} control={<Radio key={index} color="default" />} className="searchCheckBoxList" label={objLabel} />
                                            );
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className='adv-search-tag' style={{ overflow: "hidden", width: '100%', clear: 'both', marginTop: '12px' }}>
                                <span style={{ padding: '10px 39%', fontSize: '17px', }}>
                                    {isValidParam(newAdvanceSearchObject) && defaultSearchObject !== "Unit’s Accounts" && <Link to={'#'} id='advSearchTagId' onClick={() => openAdvanceSearchForm(newAdvanceSearchObject)} style={advSearchTagStyle}>{getLocalizedStrings().label.COMMON.ADVANCED}</Link>}
                                    {!isValidParam(newAdvanceSearchObject) && <span style={advSearchTagStyle}>{getLocalizedStrings().label.COMMON.ADVANCED}</span>}
                                </span>
                            </div>

                            {(checkBoxList.length > 0 && defaultSearchObject !== "Unit’s Accounts") &&
                                <RecentSearch objects={checkBoxList} closeDropDown={()=>closeDropDown()} />
                            }
                        </div>
                    </div>
                </div>}

            <Popover
                open={isOpenAdvanceSearchForm}
                onClose={() => closeAdvanceSearchForm("onBlur")}
                anchorEl={seachAanchorEl}
                style={{ marginTop: 8 }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                targetOrigin={{ horizontal: 'left', vertical: 'top' }}

            >
                <div style={{ padding: '20px', width: 434 }} >
                    <SFAdvanceSearchForm object={newAdvanceSearchObject} style={{ marginTop: '20px' }} closeForm={() => closeAdvanceSearchForm("form")} getSearchResult={(data) => getSearchResult({ ...data, object: advanceSearchObject })} />
                </div>
            </Popover>


        </div>
    );
    
}


export default Search;