import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'
import logger from 'redux-logger';
import { createPromise } from 'redux-promise-middleware'
import appReducer from './reducers/appReducer';
import auditReducer from './reducers/auditReducer';
import salesAndRefundReducer from './reducers/SalesAndRefundReducer';
import tabReducer from './reducers/tabReducer';
import headerReducer from './reducers/headerReducer';
import dashboardReducer from './reducers/dashboardReducer';
import calendarReducer from './reducers/calendarReducer';
import taskReducer from './reducers/taskReducer';
import listViewReducer from './reducers/listViewReducer';
import childListViewReducer from './reducers/childListViewReducer';
import otherListViewReducer from './reducers/otherListViewReducer';
import detailViewTaskListReducer from './reducers/detailViewTaskListReducer';
import salesReportReducer from './reducers/salesReportReducer';
import userListReducer from './reducers/userListReducer';
import salesTemplateReducer from './reducers/salesTemplateReducer';
import appContainerReducer from './reducers/appContainerReducer';
import snackBarReducer from './reducers/snackBarReducer';
import filterMenuReducer from './reducers/filterMenuReducer';
import lists from './reducers/listsReducer';
import detailViewReducer from './reducers/detailViewReducer';
import noteReducer from './reducers/noteReducer';
import dataReducer from './reducers/dataReducer';
import appointmentReducer from './reducers/appointmentReducer';
import reportReducer from './reducers/reportReducer';
import automationCampaignReducer from './reducers/automationCampaignReducer';
import automationCampaignTemplateReducer from './reducers/automationCampaignTemplateReducer';
import fieldsReducer from './reducers/fieldsReducer';
import sfFormReducer from './reducers/sfFormReducer';
import attachmentUploadReducer from './reducers/attachmentUploadReducer';
import sendMailReducer from './reducers/sendMailReducer';
import marketingReportReducer from './reducers/marketingReportReducer';
import detailViewTaskAppintmentReducer from './reducers/detailViewTaskAppointmentReducer';
import notificationReducer from './reducers/notificationReducer';
import campaignTypeReducer from './reducers/campaignTypeReducer';
import operationsReportReducer from './reducers/operationsReportReducer';
import tasksReportReducer from './reducers/tasksReportReducer';
import documentLibraryReducer from './reducers/documentLibraryReducer';
import emailReportReducer from './reducers/emailReportReducer';
import assignListViewReducer from './reducers/assignListViewReducer';
import pipelineReportReducer from './reducers/pipelineReportReducer';
import usefulLinkReducer from './reducers/usefulLinkReducer';
import timelineViewReducer from './reducers/timelineViewReducer';
import duplicateListViewReducer from './reducers/duplicateListViewReducer';
import schedulerReducer from './reducers/schedulerReducer';
import referralReportReducer from './reducers/referralReportReducer';
import appTaskReducer from './reducers/appTaskReducer';
import queueReducer from './reducers/queueReducer';
import quotationReducer from './reducers/quotationReducer';
import treeViewReducer from './reducers/treeViewReducer';
import royaltyReportReducer from './reducers/royaltyReportReducer';
import operationDashboardReducer from './reducers/operationDashboardReducer';
import purchaseOrderReducer from './reducers/purchaseOrderReducer';
import powerCallingReducer from './reducers/powerCallingReducer';
import smsReducer from './reducers/smsReducer';
import couponReducer from './reducers/couponReducer';
import complianceReportReducer from './reducers/complianceReportReducer';
import convertReducer from './reducers/convertReducer';
import templateReducer from './reducers/templateReducer';
import topUpDialogReducer from './reducers/topUpDialogReducer'; 
import workFlowSetupReducer from './reducers/workFlowSetupReducer';
import intakeFormReducer from './reducers/intakeFormReducer';
import smsVerificationReducer from './reducers/smsVerificationReducer';
import salesPipelineReportReducer from './reducers/salesPipelineReportReducer';
import { template } from 'lodash';
import auditPerformanceReportReducer from './reducers/auditPerformanceReportReducer';



const sendStoreVal = store => next => action => {
    action = { ...action, state: store.getState() }
    next(action)
}

export default createStore(
    combineReducers({
        app: appReducer,
        auditDetails: auditReducer,
        tab: tabReducer,
        header: headerReducer,
        dashboard: dashboardReducer,
        calendar: calendarReducer,
        task: taskReducer,
        listView: listViewReducer,
        childListView: childListViewReducer,
        otherListView: otherListViewReducer,
        detailViewTaskList: detailViewTaskListReducer,
        assignListView: assignListViewReducer,
        salesReport: salesReportReducer,
        userlist: userListReducer,
        salesTemplate: salesTemplateReducer,
        appContainer: appContainerReducer,
        snackBar: snackBarReducer,
        filters: filterMenuReducer,
        lists: lists,
        detailView: detailViewReducer,
        detailViewTaskAppintmentReducer:detailViewTaskAppintmentReducer,
        note: noteReducer,
        appointment: appointmentReducer,
        data: dataReducer,
        report:reportReducer,
        marketingReportResults: marketingReportReducer,
        automationCampaign: automationCampaignReducer,
        automationCampaignTemplate: automationCampaignTemplateReducer,
        fields: fieldsReducer,
        sfForm: sfFormReducer,
        attachmentUpload: attachmentUploadReducer,
        sendMail: sendMailReducer,
        powerCalling: powerCallingReducer,
        sms:smsReducer,
        notification:notificationReducer,
        campaignTypes:campaignTypeReducer,
        operationsReport:operationsReportReducer,
        tasksReport:tasksReportReducer,
        documentLibrary: documentLibraryReducer,
        emailReport:emailReportReducer,
        pipelineReport:pipelineReportReducer,
        usefulLink: usefulLinkReducer,
        timelineView: timelineViewReducer,
        duplicateListView: duplicateListViewReducer,
        schedulerDetails: schedulerReducer,
        referralReport: referralReportReducer,
        appTasks: appTaskReducer,
        queueList: queueReducer,
        quotation: quotationReducer,
        treeView: treeViewReducer,
        royaltyReport: royaltyReportReducer,
        complianceReport: complianceReportReducer,
        operationDashboard: operationDashboardReducer,
        purchaseOrder: purchaseOrderReducer,
        coupons: couponReducer,
        convert : convertReducer,
        template : templateReducer,
        topUpDialog:topUpDialogReducer,
        salesAndRefundReport:salesAndRefundReducer,
        workFlowSetup: workFlowSetupReducer,
        intakeForm: intakeFormReducer,
        smsVerfication: smsVerificationReducer,
        salesPipelineReport: salesPipelineReportReducer,
        auditPerformanceReport: auditPerformanceReportReducer
    }),
    applyMiddleware(thunk, createPromise(), sendStoreVal, logger)
);
