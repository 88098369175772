import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import ShowCircularProgress from '../components/circularProgress';
import { pushcompanylist } from '../../../services/actions/batchActions';
import { Box, Tab, List, ListItem, ListItemText } from '@mui/material';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import * as sfDialogs from '../components/sfDialogs';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getIntParam, getStringParam, getBooleanParam, isValidParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { sortArrayObjectByProperty} from '../../../services/helper/utils';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { updateDocumentLibrary, updateisAssetsUpdated } from '../../../services/actions/documentLibraryActions';
import { useDispatch, useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const DocumentShare = ({ object }) => {
    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);
    const documentLibrary = useSelector((state) => state.documentLibrary);

    const [companyList, setCompanyList] = useState([]);
    const [corporateUsersList, setCorporateUsersList] = useState([]);
    const [userType, setUserType] = React.useState('unit');
    const [mounted, setMounted] = useState(false);
    const [sharedDetails, setSharedDetails] = useState({ 'unit': {}, 'corporate': {} });
    const [isDuplicate, setIsDuplicate] = useState(false);
    const [snackMsg, setSnackMsg] = useState("");
    const [hasPush, setHasPush] = useState(true);
    const [sharedData, setSharedData] = useState([]);
    const [shareChildId, setShareChildId] = useState(0);
    const [sharedId, setSharedId] = useState(0);


    useEffect(() => {
        getCompany();
    }, []);


    useEffect(() => {
        if (sharedData != null && sharedData != undefined) {
            setSharedData(sharedData);
        }
    }, [sharedData]);

    const getCorporateUsers = () => {
        let params = { "query_id": 0, "query_type": "", "query_name": constants.SECURITY_LEVEL_OBJECT, "group_by_field_name": "t_name", "group_by_condition": "All" }
        let endPoint = endPoints.SECURITY_LEVEL.LIST_VIEW;
        var response = Promise.resolve(HTTPClient.get(endPoint, params));
        response.then((response) => {
            if (response.records !== null) {
                console.log(response)
                if (response.records.length > 0) {
                    setCorporateUsersList(response.records);
                    getShareDetails(response.records, 'corporate');
                } else {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SHARE.NO_CONNECTED_CLIENT, null);
                    handleChange('alert-call', 'unit');
                }
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SHARE.NO_CONNECTED_CLIENT, null);
                handleChange('alert-call', 'unit');
            }
        });
    }

    const getCompany = () => {
        let params = {}
        var response = pushcompanylist(object, params);
        response.then((response) => {
            if (response.records !== null) {
                if (response.records.length > 0) {
                    setCompanyList(response.records);
                    getShareDetails(response.records, 'unit');
                } else {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SHARE.NO_CONNECTED_CLIENT, null);
                    handleChange('alert-call', 'corporate');
                }
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SHARE.NO_CONNECTED_CLIENT, null);
                handleChange('alert-call', 'corporate');
            }
        });
    }

    const getShareDetails = (_list, _userType) => {
        try {
            let rootShareId = getIntParam(appContainer.dialogProps.data.rootShareId);
            let sharedId = getIntParam(appContainer.dialogProps.data.sharedId);
            let shareChildId = getIntParam(appContainer.dialogProps.data.shareChildId);
            let selectedLibrary = getStringParam(appContainer.dialogProps.data.selectedLibrary);
            let _sharedDetails = { 'unit': {}, 'corporate': {} };
            if (rootShareId > 0) {
                let params = {
                    shareId: rootShareId,
                    shareChildId: shareChildId,
                    selectedLibrary: selectedLibrary,
                    isCorporateUser: getBooleanParam(_userType == 'corporate')
                }

                let promise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.GET_SHARE_DETAILS, params));
                promise.then((response) => {
                    if (response !== null) {
                        let data = getObjectParam(response.data);
                        if (data) {
                            if (_userType == 'unit') {
                                _list.map((item) => {
                                    _sharedDetails[_userType][item.myclientId] = data[item.myclientId] ?? null;
                                });
                            } else {
                                _list.map((item) => {
                                    _sharedDetails[_userType][item.id] = data[item.id] ?? 'Write';
                                });
                            }
                        }
                        setMounted(true);
                        setSharedData(data);
                        setShareChildId(shareChildId);
                        setSharedId(sharedId);
                        setSharedDetails({ ..._sharedDetails });
                    } else {
                        setShareChildId(shareChildId);
                        setSharedId(sharedId);
                        setSharedDetails({ ..._sharedDetails });
                    }
                });
            }
        } catch (error) {

        }
    }


    const doShare = (_userArray, _userType) => {
        try {
            var recordIds = getStringParam(appContainer.dialogProps.data.documentID);
            let folderName = getStringParam(appContainer.dialogProps.data.folderName);
            let folderType = getBooleanParam(appContainer.dialogProps.data.folderType);
            let rootShareId = getIntParam(appContainer.dialogProps.data.rootShareId);
            let shareChildId = getIntParam(appContainer.dialogProps.data.shareChildId);
            let selectedLibrary = getStringParam(appContainer.dialogProps.data.selectedLibrary);
            let sharedId = getIntParam(appContainer.dialogProps.data.sharedId);
            let shareChildByRecord = appContainer.dialogProps.data.shareChildByRecord;
            var recordsLen = 0;
            let msg = null;
            let idList = "";
            let updatedSharedId = '';
            let isShareValid = false;
            let unshareClientId = '';
            let hasShared = false;
            let blockCall = false;
            let alertMsg = ''
            alertMsg = getLocalizedStrings().message.SHARE[userType == 'corporate' ? 'SHARE_SELECT_CORPORATE_DOCUMENT' : 'SHARE_SELECT_UNIT_DOCUMENT'];
            if (selectedLibrary === 'images') {
                alertMsg = getLocalizedStrings().message.SHARE[userType == 'corporate' ? 'SHARE_SELECT_CORPORATE_IMAGE' : 'SHARE_SELECT_UNIT_IMAGE'];
            }
            recordIds = recordIds.split(',');
            recordIds = recordIds.map(m => {
                return getIntParam(m);
            });
            Object.keys(_userArray[_userType]).forEach(function (key) {
                if (key != 0) {
                    let alreadyShared = false;
                    if (Object.keys(sharedData).length > 0) {
                        Object.keys(sharedData).map((client) => {
                            if (getIntParam(client) === getIntParam(key)) {
                                alreadyShared = true;
                            }
                        })
                    }
                    if (_userArray[_userType][key] && !alreadyShared) {
                        if (idList === "") {
                            idList = key;
                        } else {
                            idList = idList + "," + key;
                        }
                        isShareValid = true;
                    } else if (!_userArray[_userType][key] && alreadyShared) {
                        if (unshareClientId === '') {
                            unshareClientId = key;
                        } else {
                            unshareClientId = unshareClientId + "," + key;
                        }
                        isShareValid = true;
                    } else if (_userArray[_userType][key] != sharedData[key] && alreadyShared) {
                        if (updatedSharedId === "") {
                            updatedSharedId = key;
                        } else {
                            updatedSharedId = updatedSharedId + "," + key;
                        }
                        isShareValid = true;
                    }
                    else if (alreadyShared) {
                        hasShared = true;
                    }   
                }
            });
            if (hasShared) {
                if (updatedSharedId === '' && unshareClientId === '' && idList === '') {
                    isShareValid = true;
                    blockCall = true;
                }
            }
            recordsLen = recordIds.length;
            if (isShareValid) {
                if (!folderType) {
                    let docType = 'document';
                    if (selectedLibrary === 'images') {
                        docType = 'image';
                    }
                }
                msg = getLocalizedStrings().message.SHARE[userType == 'unit' ? 'SHARE_SUCCESS_MSG_1' : 'SHARE_SUCCESS_MSG_SL'];
                if (blockCall) {
                    if (idList.split(',').length === 1) {
                        if (userType == 'unit') {
                            msg = msg + ' ' + getLocalizedStrings().message.SHARE.SHARE_SUCCESS_MSG_3;
                        }
                    }
                    else {
                        if (userType == 'unit') {
                            msg = msg + ' ' + getLocalizedStrings().message.SHARE.SHARE_SUCCESS_MSG_2;
                        }
                    }
                    dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    dispatch(getAppDialog(false, null, null, null, null, null));
                } else {
                    let shareChildIdArr = [];
                    let param = {};
                    param.recordIds = recordIds;
                    param.type = selectedLibrary;
                    param.selectionType = 'option2';
                    param.idList = idList.toString();
                    param.updatedClientIdList = updatedSharedId.toString();
                    param.accessTypes = _userArray[_userType];
                    param.rootShareId = rootShareId;
                    if (sharedId > 0) {
                        shareChildIdArr.push(sharedId);
                    }
                    shareChildIdArr.push(shareChildId);
                    param.shareChildIdArr = shareChildIdArr;
                    param.shareChildId = shareChildId;
                    param.docType = folderType ? 'Folder' : 'File';
                    param.docName = folderName;
                    param.unshareClientIds = unshareClientId.toString();
                    param.hasShared = hasShared;
                    param.shareChildByRecord = shareChildByRecord;
                    param.isCorporateUser = getBooleanParam(userType == 'corporate');
                    var promise = Promise.resolve(HTTPClient.post(endPoints.DOCUMENT_LIBRARY.SHARE_WITH, param));
                    setMounted(false);
                    promise.then((response) => {
                        if (response !== null && response.status === 0) {
                            let selectedKye = 0;
                            let _maindata = [...documentLibrary.mainData];
                            _maindata.map((item) => {
                                if (item.key === rootShareId) {
                                    selectedKye = item.key;
                                }
                                return item;
                            });
                            _maindata.map((item) => {
                                if (item.key === selectedKye) {
                                        item.onlyCorpAccess = response.data.onlyCorpAccess ;
                                        item.isShared = false;
                                        item.shareId = response.data.shareId;
                                }
                                return item;
                            });
                            if (_maindata[0]?.library_type === "images" && response.status === 0) {
                                _maindata.map((child, index) => {
                                    if (appContainer.dialogProps.data.documentID === child.id) {
                                        child.shareId = appContainer.dialogProps.data.shareChildId
                                    }
                                });
                            }
                            setMounted(true);
                            dispatch(updateDocumentLibrary([..._maindata]));
                            dispatch(updateisAssetsUpdated(true));
                            if (response.status === 0) {
                                if (idList.split(',').length === 1) {
                                    if (userType == 'unit') {
                                        msg = msg + ' ' + getLocalizedStrings().message.SHARE.SHARE_SUCCESS_MSG_3;
                                    }
                                    dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleLongMsg));
                                }
                                else {
                                    if (userType == 'unit') {
                                        msg = msg + ' ' + getLocalizedStrings().message.SHARE.SHARE_SUCCESS_MSG_2;
                                    }
                                    dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                }
                                dispatch(getAppDialog(false, null, null, null, null, null));
                            } else if (response.status === -1) {
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.SHARE.SHARE_FAILED_MSG, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                appContainer.dialogProps.eventName('documents');
                            }
                        }
                    });
                }
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMsg, null);
            }
        } catch (error) {
            console.error('Error on documentShare.js -> doShare() : ' + error)
        }
    }

    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const handleOk = () => {
        if (hasPush) {
            dispatch(showCustomSnackBar(snackMsg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
        }
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const handleToggle = (checked, _sharedDetails, id) => {
        if (id == 0) {
            let _idList = companyList;
            if (userType == 'corporate') {
                _idList = corporateUsersList;
            }
            _idList.map((_data) => {
                let ele = document.getElementById('checkedUser-' + id);
                let disabled = ele?.disabled || false;
                if (!disabled) {
                    let _id = 0;
                    if (userType == 'unit') {
                        _id = _data.myclientId;
                    } else {
                        _id = _data.id
                    }

                    _sharedDetails[userType][_id] = checked ? 'Write' : null;
                }
            });
        } else {
            _sharedDetails[userType][id] = checked ? 'Write' : null;
        }
        setSharedDetails({ ..._sharedDetails });
    }

    const handleAccessChange = (_sharedDetails, value, id) => {
        if (id == 0) {
            let _idList = companyList;
            if (userType == 'corporate') {
                _idList = corporateUsersList;
            }
            _idList.map((_data) => {
                let ele = document.getElementById('checkedUser-' + id);
                let disabled = ele?.disabled || false;
                if (!disabled) {
                    let _id = 0;
                    if (userType == 'unit') {
                        _id = _data.myclientId;
                    } else {
                        _id = _data.id
                    }

                    _sharedDetails[userType][_id] = value;
                }
            });
        } else {
            _sharedDetails[userType][id] = value;
        }
        setSharedDetails({ ..._sharedDetails });
    }

    const handleChange = (event, newValue) => {
        if (event) {
            if (newValue == 'corporate') {
                setMounted(false);
                getCorporateUsers();
            } else {
                setMounted(false);
                getCompany();
            }
            setUserType(newValue);
        }
    };

    const getLoader = () => {
        return (
            <div>
                <div style={{ width: '100%', height: contentHeight }} >
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }} >
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    let _companyList = companyList;
    let _corporateUsersList = corporateUsersList;
    sortArrayObjectByProperty(_corporateUsersList, 't_name');
    let Write = 0, Read = 0, None = 0, total = 0, _selectedAll = false, allAccess = null;
    if (userType == 'corporate') {
        total = _corporateUsersList.length;
    } else {
        _companyList = _companyList.filter((v, i, a) => a.findIndex(v2 => (v2.myclientId === v.myclientId)) === i)
        total = _companyList.length;
    }
    Object.keys(sharedDetails[userType]).forEach(function (key, index) {
        if (key != 0) {
            if (sharedDetails[userType][key] == 'Write') {
                Write = Write + 1;
            }
            if (sharedDetails[userType][key] == 'Read') {
                Read = Read + 1;
            }
            if (sharedDetails[userType][key] == 'None') {
                None = None + 1;
            }
        }
    })
    if (total == Read) {
        allAccess = 'Read'
    }
    if (total == Write) {
        allAccess = 'Write'
    }
    if (total == None) {
        allAccess = 'None'
    }
    if (total == (Read + Write + None)) {
        _selectedAll = true;
    }
    let contentHeight = window.innerHeight - 300;
    let top = (contentHeight - 50) / 2;
    var isShare = true;
    if (appContainer.dialogProps.hasOwnProperty('data') && appContainer.dialogProps.data !== null) {
        isShare = getBooleanParam(appContainer.dialogProps.data.isShare);
    }



    let folderType = getBooleanParam(appContainer.dialogProps.data.folderType);
    return (

        <div>
            <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={userType}>
                    {app.me.t_name == "Admin" && <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Units" value="unit" />
                            <Tab label="Corporate Users" value="corporate" />
                        </TabList>
                    </Box>}
                    <TabPanel value="unit">
                        {userType == 'unit' && mounted &&
                            <div style={{ height: contentHeight - 50, width: '100%', overflow: 'auto' }} >
                                <List>
                                    <ListItem
                                        key={'all-0'}
                                        disablePadding
                                    >
                                        <ListItemIcon onClick={(e) => handleToggle(e.target.checked, sharedDetails, 0)}>
                                            <Checkbox
                                                edge="start"
                                                id={'checkedUser-0'}
                                                checked={_selectedAll}
                                                tabIndex={-1}
                                                color="default"
                                                inputProps={{ 'aria-labelledby': 'checkedUser-0' }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={0} primary={'All'} />
                                        {folderType && <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={allAccess}
                                                onChange={(e) => handleAccessChange(sharedDetails, e.target.value, 0)}
                                            >
                                                <MenuItem value={'Read'}>Read</MenuItem>
                                                <MenuItem value={'Write'}>Write</MenuItem>
                                            </Select>
                                        </FormControl>}
                                    </ListItem>

                                    {_companyList.map((comp, i) => {
                                        let cmpName = comp.company;
                                        let cmpNameEle = null;
                                        let alreadyShared = false;
                                        let disable = false;
                                        Object.keys(sharedData).map((clintId, i) => {
                                            if (clintId == comp.myclientId) {
                                                alreadyShared = true;
                                                if (shareChildId !== sharedId) {
                                                    disable = true;
                                                }
                                            }
                                        });
                                        if (alreadyShared) {
                                            cmpNameEle = <span>
                                                {cmpName}
                                                <img src={'/asset/images/externalPage/smallIcons/checked.png'} alt="xx" width="15" height="15" className='dlimg-tick' style={{ margin: '0 10 -3' }} />
                                            </span>
                                        } else {
                                            cmpNameEle = cmpName;
                                        }
                                        return (

                                            <ListItem
                                                key={i + comp.myclientId}
                                                disablePadding
                                            >
                                                <ListItemIcon onClick={(e) => handleToggle(e.target.checked, sharedDetails, comp.myclientId)}>
                                                    <Checkbox
                                                        edge="start"
                                                        id={'checkedUser-' + comp.myclientId}
                                                        checked={sharedDetails[userType][comp.myclientId]}
                                                        tabIndex={-1}
                                                        color="default"
                                                        disabled={disable}
                                                        inputProps={{ 'aria-labelledby': 'checkedUser-' + comp.myclientId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={comp.myclientId} primary={cmpNameEle} />
                                                {folderType && <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                                    <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        value={sharedDetails[userType][comp.myclientId]}
                                                        onChange={(e) => handleAccessChange(sharedDetails, e.target.value, comp.myclientId)}
                                                    >
                                                        <MenuItem value={'Read'}>Read</MenuItem>
                                                        <MenuItem value={'Write'}>Write</MenuItem>
                                                    </Select>
                                                </FormControl>}
                                            </ListItem>
                                        )
                                    }
                                    )}
                                </List>
                            </div>}{userType == 'unit' && !mounted &&
                                getLoader()
                        }
                    </TabPanel>
                    <TabPanel value="corporate">
                        {userType == 'corporate' && mounted &&
                            <div style={{ height: contentHeight - 50, width: '100%', overflow: 'auto' }} >
                                <List>
                                    <ListItem
                                        key={'all-0'}
                                        disablePadding
                                    >
                                        <ListItemIcon onClick={(e) => handleToggle(e.target.checked, sharedDetails, 0)}>
                                            <Checkbox
                                                edge="start"
                                                id={'checkedUser-0'}
                                                checked={_selectedAll}
                                                tabIndex={-1}
                                                color="default"
                                                inputProps={{ 'aria-labelledby': 'checkedUser-0' }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={0} primary={'All'} />
                                        {folderType && <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={allAccess}
                                                onChange={(e) => handleAccessChange(sharedDetails, e.target.value, 0)}
                                            >
                                                <MenuItem value={'Read'}>Read</MenuItem>
                                                <MenuItem value={'Write'}>Write</MenuItem>
                                            </Select>
                                        </FormControl>}
                                    </ListItem>
                                    {_corporateUsersList.map((corpUsr, i) => {

                                        return (

                                            <ListItem
                                                key={i + corpUsr.id}
                                                disablePadding
                                            >
                                                <ListItemIcon onClick={(e) => handleToggle(e.target.checked, sharedDetails, corpUsr.id)}>
                                                    <Checkbox
                                                        edge="start"
                                                        id={'checkedUser-' + corpUsr.id}
                                                        checked={sharedDetails[userType][corpUsr.id]}
                                                        tabIndex={-1}
                                                        color="default"
                                                        inputProps={{ 'aria-labelledby': 'checkedUser-' + corpUsr.id }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={corpUsr.id} primary={corpUsr.t_name} />
                                                {folderType && <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                                    <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        value={sharedDetails[userType][corpUsr.id]}
                                                        onChange={(e) => handleAccessChange(sharedDetails, e.target.value, corpUsr.id)}
                                                    >
                                                        <MenuItem value={'Read'}>Read</MenuItem>
                                                        <MenuItem value={'Write'}>Write</MenuItem>
                                                        <MenuItem value={'None'}>None</MenuItem>
                                                    </Select>
                                                </FormControl>}
                                            </ListItem>
                                        )
                                    }
                                    )}
                                </List>
                            </div>}{userType == 'corporate' && !mounted &&
                                getLoader()
                        }
                    </TabPanel>
                </TabContext>
            </Box>

            <div style={{ height: "50px", float: "right", padding: "20px 0px" }}>
                {isDuplicate ? (
                    <Button
                        key={'ok'}
                        primary={'true'}
                        onClick={() => handleOk()}
                        style={styles.primaryButton}
                    >{getLocalizedStrings().label.COMMON.OK}</Button>) : <>
                    <Button
                        key={'do-share'}
                        primary={'true'}
                        onClick={() => doShare(sharedDetails, userType)}
                        style={styles.primaryButton}
                    >{getLocalizedStrings().label.COMMON.DONE}</Button>
                    <Button
                        key={'close'}
                        primary={'true'}
                        onClick={() => handleClose()}
                        style={styles.secondaryButton}
                    >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                </>}
            </div>
        </div>
    );
};

export default DocumentShare;