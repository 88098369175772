import React, {useEffect, useState} from 'react';
import { isValidParam , getArrayParam , getBooleanParam} from '../../../services/helper/parameterVerifier';
import RecentRecordsForObjects from '../components/recentRecordsForObjects';
import RecentSearchText from '../components/recentSearchText';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { getObjectIdListByModule } from '../../../services/helper/common';
import {useDispatch, useSelector} from 'react-redux';

const RecentSearch = ({objects,closeDropDown}) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const filters = useSelector((state)=> state.filters);
    const [objectList,setObjectList] = useState([]);
    const [showRecentSearchDiv,setShowRecentSearchDiv] = useState(false);

    useEffect(() => {
        getObjectLists();
        getIfRecentDataExists();
    }, []);

    const getObjectLists = () => {
        if (getArrayParam(objects).length>0) {
            setObjectList(objects);
        }
    }

    const handleCloseDropdown = () => {
        closeDropDown();
    }


   const checkIfSearchTextRecordExists = (objects) => {
        let isExists = false;
        let url = endPoints.HOME.GET_RECENT_DATA_EXISTS;
        let params = {};
        let tableIds = getObjectIdListByModule(objectList);;
        try {
            
            
            params.table_ids = tableIds;
            let selectedModule = app.selectedModuleName;
            let reduxSearchText = filters.recentSearchesText[selectedModule];
            let reduxSearchRecord = filters.recentSearchRecords[selectedModule];
            if(((!isValidParam(reduxSearchText) || reduxSearchText.length === 0) || (!isValidParam(reduxSearchRecord) || reduxSearchRecord.length === 0))){
                let promise = Promise.resolve(HTTPClient.get(url, params));
                promise.then((response) => {
                    if (isValidParam(response)) {
                        isExists=getBooleanParam(response); 
                        setShowRecentSearchDiv(isExists);

                    }
                });
            }else{
                setShowRecentSearchDiv(true);

            }
        } catch (error) {
            console.error("Error in 'recentSearch.js -> checkIfSearchTextRecordExists()':" + error);
        }
    }

   const getIfRecentDataExists = () => {
        let objects =  getArrayParam(objectList);
        checkIfSearchTextRecordExists(objects);
    }
    
        let hasObjects = getArrayParam(objectList).length>0 ? true :false;
        if (showRecentSearchDiv) {
            return (
                <div>
                    <hr style={{ width: '96%', margin: '10px 0px 0px 0' }} />
                    <div className="row">
                        <div className="col-sm-4" style={{ width: '50%' }}>
                            <div style={{ width: '95%', display: 'inline-block',marginTop:'15px'}}>
                                <span style={{ fontWeight: 'bold' }}>{getLocalizedStrings().label.COMMON.RECENT_SEARCHES}</span>
                                {hasObjects && <RecentSearchText objects={objectList} handleCloseDropdown={()=>handleCloseDropdown()} />}
                            </div>
                        </div>
                        <div className="col-sm-4" style={{ width: '50%' }}>
                            <div style={{ width: '95%', display: 'inline-block',marginTop:'15px' }}>
                                <span style={{ fontWeight: 'bold' }}>{getLocalizedStrings().label.COMMON.RECENT_RECORDS}</span>
                                {hasObjects && <RecentRecordsForObjects objects={objectList} handleCloseDropdown={()=>handleCloseDropdown()} />}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
export default RecentSearch;