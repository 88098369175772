import React, { useState, useEffect } from 'react';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { getArrayParam, getObjectParam, isValidParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import ShowCircularProgress from '../components/circularProgress';
import { Select, Button, TextField, FormControl, MenuItem, InputLabel } from '@mui/material';
import { dateFormat, dateFormatFlatPicker } from '../../../services/helper/utils';
import { useDispatch, useSelector } from "react-redux";
import * as SFFormActions from '../../../services/actions/sfFormActions';
import PopOver from './PopOver';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import moment from 'moment';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import * as sfDialogs from '../components/sfDialogs';
import { getStringParam } from '../../../services/helper/parameterVerifier';


const AddExceptions = ({ data, closeDrawer }) => {
    const app = useSelector((state) => state.app);
    const sfForm = useSelector((state) => state.sfForm);
    const dispatch = useDispatch()
    const [excpetionData, setExcpetionData] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [noPlanAvailable, setNoPlanAvailable] = useState();
    const [isDisabled, setIsDisabled] = useState(false);
    const [addRowException, setAddRowException] = useState(false);
    const [exceptionFieldList, setExceptionFieldList] = useState([])
    const [isDelete, setIsDelete] = useState()
    const [planNameList, setPlanNameList] = useState([])
    const [planName, setPlanName] = useState('')

    const exceptionTypeList = [
        { id: 1237, name: 'Flat', label: 'Flat' },
    ]

    // useEffect(() => {
    //     let reportedSalesData = Object.assign({}, sfForm.data[constants.REPORTED_SALES_OBJECT]);
    //     if (Object.keys(reportedSalesData).length === 0) {
    //         getFormData(constants.REPORTED_SALES_OBJECT);
    //     } else if (Object.keys(reportedSalesData).length > 0) {
    //         setExceptionFormFields(reportedSalesData)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    useEffect(() => {
        let params = {}
        params.myclient_id = data?.myClientId;
        const promise = Promise.resolve(HTTPClient.get(endPoints.EXCEPTION.PLAN_NAME, params))

        promise.then((response) => {
            if (isValidParam(response)) {
                if (response.length > 0) {
                    setNoPlanAvailable(false)
                    setPlanNameList(response);
                    let firstPlanName = response[0]?.name;
                    let reportedSalesData = Object.assign({}, sfForm.data[constants.REPORTED_SALES_OBJECT]);
                    if (Object.keys(reportedSalesData).length === 0) {
                        getFormData(constants.REPORTED_SALES_OBJECT,firstPlanName);
                    } else if (Object.keys(reportedSalesData).length > 0) {
                        setExceptionFormFields(reportedSalesData,firstPlanName)
                    }

                }
                else {
                    setNoPlanAvailable(true)
                }

            }
        })
    }, []);

    const getRecordInformation = (exceptionFields, planname) => {
        let params = {}
        params.myclient_id = data?.myClientId;
        params.plan_name = planname !== undefined ? planname : planName;
        setisLoading(true)
        if (isValidParam(params)) {
            const promise = Promise.resolve(HTTPClient.get(endPoints.EXCEPTION.GET, params));
            promise.then((response) => {
                if (response?.length > 0) {
                    const fieldMap = new Map(exceptionFields?.map(field => [field.name, field.label]))
                    const filterResponse = response.map((item) => ({ ...item, to_date: moment(item.to_date).format(dateFormat[app.me.date_format]), from_date: moment(item.from_date).format(dateFormat[app.me.date_format]), 'field_label': fieldMap.get(item.field_name) }))
                    let filterFields = []
                    let responseFieldNames = response.map(item => item.field_name);
                    filterFields = exceptionFields?.filter((field) => !responseFieldNames.includes(field.name))
                    setExceptionFieldList(filterFields)
                    setExcpetionData(filterResponse);
                    setIsDelete(true)
                } else {
                    setExcpetionData([])
                    setIsDelete(false)
                }
                setisLoading(false)
            }).catch((err) => console.log('exception get err:', err));


        }
    }

    const getFormData = (object, planname) => {
        setisLoading(true)
        try {
            var promise = SFFormActions.getSFFormFieldsPromise(object);
            promise.then((response) => {
                if (isValidParam(response)) {
                    sfForm.data[object] = JSON.parse(JSON.stringify(getObjectParam(response)));
                    let reportedSalesData = Object.assign({}, sfForm.data[constants.REPORTED_SALES_OBJECT]);
                    if (Object.keys(reportedSalesData).length > 0) {
                        setExceptionFormFields(reportedSalesData, planname)
                    }
                }
            });
        } catch (error) {
            console.error("Error in 'AddException.js -> getFormData()':" + error);
        }
    }

    const setExceptionFormFields = (reportedSalesData, planname) => {
        const floatFields = reportedSalesData?.fields?.filter((item) => (item.field_type_id === constants.FLOAT_FORM_FIELD) && (item.name?.startsWith("royalty_") || item.name?.startsWith("marketing_fee") || item.name?.startsWith("tech_fee") || item.name?.startsWith("other_fee")))
        if (floatFields?.length > 0) {
            let tempFields = floatFields.map(m => { return { name: m.name, id: m.id, label: m.label, t_tablefldid: m.t_tablefldid, field_type_id: m.field_type_id, field_type: m.field_type } });
            let exceptionFields = tempFields.sort((obj1, obj2) => { return (obj1.label > obj2.label) ? 1 : -1 });
            setExceptionFieldList(exceptionFields)
            getRecordInformation(exceptionFields, planname);
        }
    }

    const datePickerOptions = {
        enableTime: false,
        dateFormat: dateFormatFlatPicker[app.me.date_format],
        minuteIncrement: 1,
        static: true
    }


    const handleExceptionDataChange = (field, value, index) => {
        let _excpetionData = getArrayParam(excpetionData);
        if (field === 'field_value') {
            let regExp = /^[0-9]*$/g;
           let isUpdate = regExp.test(value);
           if(isUpdate && value != ""){
            _excpetionData[index][field] = parseInt(value);
           }else if(isUpdate && value == ""){
            _excpetionData[index][field] = undefined;
           }
        } else {
            _excpetionData[index][field] = value;
        }
      
      let fromdate =_excpetionData[index].from_date
      let todate = _excpetionData[index].to_date
    let date1 =  moment(fromdate).format("YYYY-MM-DD")
    let date2 =  moment(todate).format("YYYY-MM-DD")
      date1 = new Date(date1)
      date2 = new Date(date2)
        if( todate !=="" && date1 > date2 ){
            dispatch(showCustomSnackBar("Please provide To Date greater than From Date", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            _excpetionData[index].to_date = '';
        }

        setExcpetionData([..._excpetionData]);
      
    }
  
    const getPlanData = (item, i) => {
        let elem = []
        elem.push(
            <div style= {{display:'flex', width:'100%'}}>
            <div style={{ display: 'grid',marginLeft:'5px', gridColumnGap: '5px', gridTemplateColumns: 'repeat(5, 1fr)', marginTop: '10px',width:'100%' }}>
                <div style={{ justifySelf: 'left' }}>
                    <h1 style={{ width: '150px', overflow: 'hidden', fontSize: '16px', fontWeight: 'bold' }}>{item.field_label}</h1>
                </div>
                <div style={{ justifySelf: 'left' }}>
                    <FormControl letiant="outlined">
                        <Select
                            key="sort-by"
                            name="remindertype"
                            labelId='sort-by-label'
                            id='datetime'
                            defaultValue={item.type == '' ?"Flat":item.type }
                            sx={{ width: '150px' }}
                            size="small"
                            className={"sf-select"}
                            onChange={(event) => handleExceptionDataChange('type', event.target.value, i)}
                        >
                            {getExceptionTypeList()}
                        </Select>
                    </FormControl>
                </div>
                <div style={{ justifySelf: 'left' }}>
                    <TextField
                        id="outlined-basic"
                        label="Value"
                        variant="outlined"
                        value={item.field_value == undefined ? '':item.field_value}
                        onChange={(event) => handleExceptionDataChange('field_value', event.target.value, i)}
                        size='small'
                        style={{ width: '150px' }}
                    />
                </div>
                <div style={{ justifySelf: 'left' }}>
                    <PopOver
                        id="UnitNameDatePicker"
                        key={"start_date"}
                        name={"start_date"}
                        btnType={"date"}
                        buttonStyle={{ fontSize: "16px" }}
                        containerStyle={{ lineHeight: 1, width: '150px' }}
                        buttonLabel="From Date"
                        onChange={(date) => handleExceptionDataChange("from_date", date, i)}
                        innerLabelStyle={{
                            padding: "10 5 2 5",
                            fontSize: "15px",
                        }}
                        value={item.from_date}
                        height={40}
                        options={datePickerOptions}
                    />
                </div>
                <div style={{ justifySelf: 'left' }}>
                    <PopOver
                        id="UnitNameDatePicker"
                        key={"to_date"}
                        name={"to_date"}
                        btnType={"date"}
                        buttonStyle={{ fontSize: "16px" }}
                        containerStyle={{ lineHeight: 1, width: '150px' }}
                        buttonLabel="To Date"
                        onChange={(date) => handleExceptionDataChange("to_date", date, i)}
                        innerLabelStyle={{
                            padding: "10 5 2 5",
                            fontSize: "15px",
                        }}
                        value={item.to_date}
                        height={40}
                        options={datePickerOptions}
                    />
                </div>
                
            </div>
            <div style={{ marginTop:'20px' }}>
              
            <span style={{ fontSize: 14,   cursor: "pointer", paddingTop: '12px' }}> <i className="fa fa-times" onClick={()=>deleteExceptionRow(item, i)} title={getLocalizedStrings().label.COMMON.DELETE} ></i></span>
       
        </div>
        </div>
        );
        return elem;
    }

    const deleteExceptionRow = (item, index) => {
        let excpetionDataArr = excpetionData;
        excpetionDataArr.splice(index, 1);
        setExcpetionData([...excpetionDataArr])
        let reportedSalesData = Object.assign({}, sfForm.data[constants.REPORTED_SALES_OBJECT]);
        const floatFields = reportedSalesData?.fields?.filter((f) => (f.label === item.field_label))
        let temp = exceptionFieldList;
        temp.push((floatFields[0]))
        setExceptionFieldList([...temp])
    }

    const getValidExceptionStatus = () => {
        const newErrors = [];

        excpetionData.forEach((item) => {
          let fields = Object.keys(item);
          fields.forEach((field)=>{
             if((field != 'field_value' && !item[field]) ){
                let undefinedKey = field === 'field_value' ? 'value' : field === 'from_date' ? 'From date' : field === 'to_date' ? 'To date' : field?.charAt(0).toUpperCase() + field?.slice(1)
                newErrors.push({ field_label: item.field_label,  field_name :undefinedKey});

             }else if(field == "field_value" && item.field_value == undefined  ){
                let undefinedKey = field === 'field_value' ? 'value' : field === 'from_date' ? 'From date' : field === 'to_date' ? 'To date' : field?.charAt(0).toUpperCase() + field?.slice(1)
                newErrors.push({ field_label: item.field_label, field_name : undefinedKey });

             }
          })})
         

        if (newErrors.length > 0) {
            dispatch(showCustomSnackBar(`Please provide ${newErrors[0].field_name} for ${newErrors[0].field_label}`, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return;
        }

        return true;
    };

    const handleSave = () => {
        // validation needs to be done
        const modifiedExcpetionData = excpetionData.map((item) => {
            const { field_label, ...rest } = item
            return rest;
        })
        let params = {}
        params.myclient_id = data?.myClientId;;
        params.exception_details = modifiedExcpetionData
        params.is_delete = isDelete;
        params.plan_name = planName == '' ? planNameList[0]?.name :planName;

        let isValid = getValidExceptionStatus();
        if (isValid) {
            setisLoading(true);
            if (isValidParam(params)) {
                const promise = Promise.resolve(HTTPClient.post(endPoints.EXCEPTION.SAVE, params));
                promise.then((response) => {
                    if (response.status !== -1) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.EXCEPTION.EXCEPTION_SAVE_SUCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        closeDrawer();
                    } 
                    setisLoading(false);
                }).catch((err) => {
                    console.log('exception save err:', err)
                    setisLoading(false);
                })
            }
        }
    }

    const handleActions = (name) => {
        try {
            if (name === 'save') {
                handleSave();
            } else if (name === 'cancel') {
                closeDrawer();
            }
        } catch (error) {
            console.error("Error in 'AddException.js -> handleActions()':" + error);
        }
    }

    const generateHeaderbuttons = () => {
        let element = [];
        try {
            let list = [{ name: 'save', label: getLocalizedStrings().label.COMMON.SAVE }, { name: 'cancel', label: getLocalizedStrings().label.COMMON.CANCEL }];
            element = list.map(item => {
                let buttonStyle = item.name === 'cancel' ? { ...styles.secondaryButton, verticalAlign: 'top' }
                    : isDisabled === false ? { ...styles.primaryButton, width: '20px', verticalAlign: 'top' }
                        : { ...styles.primaryButton, width: '20px', verticalAlign: 'top', opacity: '0.3' };
                let disableValue = item.name === 'cancel' ? isLoading : isDisabled === false ? isLoading : true;
                return (
                    <Button
                        key={`key-${item.name}`}
                        onClick={() => handleActions(item.name)}
                        style={{ ...styles.primaryButton, verticalAlign: 'top', ...buttonStyle }}
                        labelstyle={{ fontWeight: 'normal' }}
                        disabled={disableValue}
                    >{item.label}</Button>
                );
            });
        } catch (error) {
            console.error("Error in 'AddException.js -> generateHeaderbuttons()':" + error);
        }
        return element;
    }

    const addRow = () => {
        setAddRowException(true)
    }

    const onChangeExceptionTypesFields = (data) => {
        let dataRow = getArrayParam(excpetionData);
        let filterData = exceptionFieldList.filter((f) => f.label !== data.label)
        let rowObj = {
            'field_name': data.name,
            'field_label': data.label,
            'type': 'Flat',
            'field_value': undefined,
            'from_date': '',
            'to_date': '',
        }
        dataRow.push(rowObj);
        setExcpetionData([...dataRow]);
        setExceptionFieldList(filterData)
        setAddRowException(false)
    }

    const getExceptionFieldList = () => {
        let List = exceptionFieldList;
        if (List !== undefined) {
            return List.map((item, i) => {
                return <MenuItem value={item} style={styles.popoverMenuItem}>{item.label}</MenuItem>;
            })
        }
    }

    const getPlannameList = () => {
        let menuItems = [];
        let planList = getArrayParam(planNameList);
        if (planList !== null && planList.length > 0) {
            planList.map((fieldOption, i) => {
                menuItems.push(
                    <MenuItem value={fieldOption.id} id={fieldOption.id} title={fieldOption.name} key={fieldOption.id} style={styles.popoverMenuItem} >{fieldOption.name} </MenuItem>);
            });
        }
        return menuItems;
    }

    const onChangePlanName = (event) => {
        let value = event.target.value;
        setPlanName(value);
        let reportedSalesData = Object.assign({}, sfForm.data[constants.REPORTED_SALES_OBJECT]);
        if (Object.keys(reportedSalesData).length === 0) {
            getFormData(constants.REPORTED_SALES_OBJECT, value);
        } else if (Object.keys(reportedSalesData).length > 0) {
            setExceptionFormFields(reportedSalesData, value)
        }

    }

    const getExceptionTypeList = () => {
        let List = exceptionTypeList;
        if (List !== undefined) {
            return List.map((item, i) => {
                return <MenuItem value={item.name} style={styles.popoverMenuItem}> {item.label}</MenuItem>;
            })
        }
    }
    let _planNameList = getPlannameList();
    if (excpetionData && !isLoading && noPlanAvailable == false) {

        return (
            <div style={{ marginTop: '20px', marginBottom: '10px', marginLeft: '20px', width: '95%' }}>
                <div style={{ marginTop: '10px' , display:'flex', width:'100%'}}>
                    <FormControl letiant="outlined" style={{ width: '30%' }} className='plan-select'>
                        <InputLabel id="sf-add-units-from-simple-select-outlined-label" className="sf-add-units-plan-name" style={{ marginTop: '-7px' }}>Select a plan</InputLabel>

                        <Select
                            key="sort-by"
                            name="remindertype"
                            labelId='sort-by-label'
                            id='datetime'
                            label='Select a plan'
                            style={{ width: '100%' }}
                            size="small"
                            defaultValue={planName == '' && planNameList.length>0 ? planNameList[0]?.name:planName}
                            className={"sf-select"}
                            onChange={(event) => onChangePlanName(event)}
                        >
                            {_planNameList}
                        </Select>
                    </FormControl>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 0', marginLeft:'auto' }}>
                    {generateHeaderbuttons()}
                </div>
                </div>
                {/* exception */}
                {excpetionData.length > 0 && <div>
                    {/* header label row */}
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', marginRight: '5px' , marginTop: '20px'}}>
                        <div style={{ padding: '5px', textAlign: 'left', width: '150px' }}><span style={{ fontSize: '17px', fontWeight: 'bolder', fontFamily: 'Lato' }}>Field</span></div>
                        <div style={{ padding: '5px', textAlign: 'left', width: '150px' }}><span style={{ fontSize: '17px', fontWeight: 'bolder', fontFamily: 'Lato' }}>Type</span></div>
                        <div style={{ padding: '5px', textAlign: 'left', width: '150px' }}><span style={{ fontSize: '17px', fontWeight: 'bolder', fontFamily: 'Lato' }}>Value</span></div>
                        <div style={{ padding: '5px', textAlign: 'left', width: '150px' }}><span style={{ fontSize: '17px', fontWeight: 'bolder', fontFamily: 'Lato' }}>From Date</span></div>
                        <div style={{ padding: '5px', textAlign: 'left', width: '150px' }}><span style={{ fontSize: '17px', fontWeight: 'bolder', fontFamily: 'Lato' }}>To Date</span></div>
                    </div>

                    {/* exception row data */}
                    <div style={{ width: '100%', marginTop: '5px' }}>
                        {excpetionData.map((item, index) => (getPlanData(item, index)))}
                    </div>
                </div>
                }

                {/* add Exception Drop down */}
                {addRowException && <div style={{ marginTop: '10px', display: 'flex' }}>
                    <FormControl letiant="outlined" style={{ width: '30%' }} className='audit-select'>
                    <InputLabel id="sf-add-units-from-simple-select-outlined-label" className="sf-add-units-plan-name" style={{ marginTop: '-5px' }}>Select a field</InputLabel>

                        <Select
                            key="sort-by"
                            name="remindertype"
                            labelId='sort-by-label'
                            id='datetime'
                            label="Select a field"
                            style={{ width: '100%' }}
                            size="small"
                            className={"sf-select"}
                            onChange={(event) => onChangeExceptionTypesFields(event.target.value)}
                        >
                            {getExceptionFieldList()}
                        </Select>
                    </FormControl>
                </div>}

                {/* add exception Link */}

                <div style={{ marginTop: '20px' }}>
                    <div style={{ width: '100%', fontWeight: 'normal', fontSize: "16px" }} name='add_exception_label' id="add_exception_label">
                        <span><a href='/#' onClick={(event) => addRow(event)} >{'Add Exceptions'}</a></span>
                    </div>
                </div>

                {/* save and cancel button */}
                {/* {excpetionData.length > 0 && <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 0' }}>
                    {generateHeaderbuttons()}
                </div>} */}
            </div>
        );

    } else if (noPlanAvailable == true) {
        return (
            <div style={{ marginLeft: '30px' }}>
                <span style={{fontSize: 18}}> There is no plan enrolled for this unit.</span>
            </div>
        )
    }
    else {
        let contentHeight = window.innerHeight - 250;
        let top = (contentHeight - 10) / 2;
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    };
};

export default AddExceptions;
