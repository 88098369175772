import React, { useState, useEffect,useRef ,useCallback} from 'react';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants, OBJECT_TABLEID_MAP, WORKFLOW_SETUP, TABLEID_OBJECT_MAP} from '../../../services/constants/constants';
import * as sfDialogs from './sfDialogs';
import { useDispatch, useSelector } from "react-redux";
import { getAppDialog } from '../../../services/actions/appContainerActions';
import {showCustomSnackBar} from '../../../services/actions/snackBarAction';
import {
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    TextField,
    tableBodyClasses,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import WorkflowSetupCard from './WorkflowSetupCard';
import WorkflowSetupMessage from './WorkflowSetupMessage';
import WorkflowExternalwebhook from './WorkflowExternalwebhook';
import WorkflowSetupEmail from './WorkflowSetupEmail';
import WorkflowSetupStages from './WorkFlowSetupStages';
import LinkIcon from '@mui/icons-material/Link';
import ClearIcon from '@mui/icons-material/Clear';
import AttachmentIcon from '@mui/icons-material/Attachment';
import Box from '@mui/material/Box';
import { getStringParam, getArrayParam ,isValidParam} from '../../../services/helper/parameterVerifier';
import {setActions,addValueInTaskList, setisOpenEditTask,setSelectedRequiredFields,setTaskListkOrderErrorId,setisAddNewTask, setIsFocusOnAppointmentTask,setisaddNewTextMessage,setisEditEmailTask,setisEditTextMessage,setisAddAppointmentTask,setisEditAppoinmentTask,setisOpenEmailTask,setisFocusOnEmailTask,setIsFocusOnMessageTask,setShowRequiredField,setisShowWebhook, closeWorkflowSetupDrawer,updateActionTaskInStage,updateWorkflowSetupAddNewTask, addNewTaskNameInTaskList,getWorkflowEmailTemplates, updateDndCard, setIsDndCardDroped} from '../../../services/actions/workflowSetupAction';
import { getOrderId, getUniqueId } from '../../../services/helper/utils';
import ShowCircularProgress from './circularProgress';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

const WorkflowSetup = ({object,closeDrawer}) => {
    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const scrollref = useRef(null);
    const { action, isEditTask, isAddNewTask, isaddNewTextMessage, isEditTextmessage, isAddAppointment,isFocusOnappointmentTask, isEditApppointment, isOpenEmailTask, isEditWebhook, isEditEmailTask, showRequiredField, isShowWebhook, selectedStage, data, taskList, customDateFormFId, stagesLength, isDndCardDroped ,error} = useSelector((state) => state.workFlowSetup);
    const { form_fields, nodes } = data;
    const { tasks } = selectedStage;
    const userlist = useSelector((state)=>state.userlist.data)
    const { customDrawerProps } = useSelector((state) => state.appContainer)
    const positionRef = React.useRef({
        x: 0,
        y: 0,
      });
    const popperRef = React.useRef(null);
    const inputRef =  useRef(null);
    const areaRef = React.useRef(null);
    const [dummyData, setDummyData] = useState(0);
    const [element,setElement] = useState(null);
    const [newTask,setnewTask] = useState('');
    const [value, setValue] = React.useState('automatically');
    const [addActionList,setAddActionList] = useState([
        {name :'--Add--' , label:'--Add--'},
        {name : 'New task', label :'New task'},
        {name : 'New email', label :'New email'},
        {name : 'New text message', label :'New text message'},
        {name : 'New Appointment' , label : 'New Appointment'}
    ])
    const [dayTimeList,setdayTimeList] = useState([
        {name:'days',label:'days'},
        {name:'hours',label:'hours'}
    ])
    const [dueByConditionList,setdueByConditionList] = useState([
        {name:'Before',label:'Before'},
        {name:'After',label:'After'}
    ])
    const [taskName,settaskName] = useState('');
    const [owner,setOwner] = useState('');
    const [dueBy,setDueBy] = useState(null);
    const [dueByTime,setdueByTime] = useState('');
    const [dueByCondition,setdueByCondition] = useState('');
    const [dueByFields,setdueByFields]  = useState('');
    const [attachFiles, setAttachFiles] = useState([]);
    const [rowReminders,setrowReminders] = useState([]);
    const [isTaskFocus,setIsTaskFocus] = useState(false);
    const [reminderTypeList,setreminderTypeList] = useState([
        {id: 1233,name:'',label:''},
        {id:1234 , name:'Email',label:'Email'},
        {id:1235, name:'Pop-up',label:'Pop-up'}
    ])
    
    const [reminderTimeList,setreminderTimeList] = useState([
        {id: 1222,name:'minutes',label:'minutes'},
        {id:1223 , name:'hours',label:'hours'},
        {id:1224, name:'days',label:'days'},
        {id:1225,name:'weeks',label:'weeks'}
    ])

    const [priorityList,setPriorityList] = useState([
        {id:4444,name:'',label:''},
        {id:5555,name:'1 - High',label:'1 - High'},
        {id:6666,name:'2 - Moderate',label:'2 - Moderate'},
        {id:7777,name:'3 - Low',label:'3 - Low'},
    ])
    const [linkList,setlinkList] = useState([])
    const [isScrollToAppointment,setIsScrollToAppointment] = useState(false);
    const [isFocusOnTaskname,setIsFocusOnTaskname] = useState(false);
    const [priority,setPriority] = useState('');
    const [notifyUnit,setnotifyUnit] = useState(false);
    const [scheduleOnWeekend,setscheduleOnWeekend] = useState(false);
    const [link,setLink] = useState('');
    const [description,setDescription] = useState('');
    const [subject,setSubject] = useState('');
    const [requireFieldData,setrequireFieldData] = useState([]);
    const [table,setTable] = useState('');
    const [workflowName,setworkflowName] = useState('');
    const [status,setStatus] = useState('');
    const [tableList,setTableList] = useState([
        {id:4465,name:'accounts',label:'Accounts'},
        {id:5565,name:'contacts',label:'Contacts'},
        {id:6665,name:'helpdesk',label:'Helpdesk'},
        {id:7765,name:'projects',label:'Projects'},
        {id:6547,name:'cases',label:'Cases'},
        {id:6583,name:'projects - transaction',label:'Projects - Transaction'},
        {id: 5674 ,name:'issues',label:'Issues'},
        {id: 6754,name:'mlm',label:'MLM'},
        {id:7685,name:'meetings',label:'Meetings'},
    ])
    const [statusList,setStatusList] = useState([
        {id:4455,name:'Active',label:'Active'},
        {id:5566,name:'inactive',label:'inactive'}
    ])

    useEffect(()=>{
        let obj = {
            "taskname": "--Add--",
            "description": "--Add--"
        }
        let isIncluded = taskList.includes(obj.taskname);
        if(!isIncluded){
            let _taskList = taskList;
            _taskList.push(obj);
            dispatch(addValueInTaskList(_taskList))
        }
    },[taskList])

    useEffect(()=>{
        if(!action){
            dispatch(setActions(addActionList[0]?.name));
        }
        if(Object.keys(data).length !== 0){
            if (data.hasOwnProperty('status') && !status) {
                setStatus(data.status);
            }
            if(data.hasOwnProperty('t_workflowName') && !workflowName){
                setworkflowName(data.t_workflowName);
            }
            if(data.hasOwnProperty('t_tableid') && !table){
                let t_tableid = data.t_tableid;
                let _selectedTable = TABLEID_OBJECT_MAP[getStringParam(t_tableid)]
                setTable(_selectedTable);

            }
            dispatch(setShowRequiredField(true));
        }
    },[data])

    useEffect(()=>{
        if(isAddNewTask){
            if(!taskName){
                settaskName(taskList? taskList[0]?.taskname:'');
            }
            if(!owner){
                setOwner(userlist? userlist[0].value : '');
            }
            if(!dueByTime){
                setdueByTime('days');
            }
            if(!dueByCondition){
                setdueByCondition('Before')
            }
            if(!description){
                setDescription('Understandings of all the equipments');
            }
            if(!scheduleOnWeekend){
                setscheduleOnWeekend(true);
            }
            if(!dueByFields){
                let _customDateFormFId = [];
                customDateFormFId?.forEach((item, index) => {
                        if (isValidParam(item)) {
                            if (isValidParam(Object.values(item)[0]) && isValidParam(Object.keys(item)[0])) {
                                let data = {};
                                data.label = Object.values(item)[0];
                                data.value = Object.keys(item)[0];
                                _customDateFormFId.push(data);
                            }
                        }
                    });
                setdueByFields(customDateFormFId ? _customDateFormFId[0].value : '');
            }
            if(rowReminders.length  == 0){
                let obj = {id:7655675,name:'',t_remindertype:'',t_reminder:10,t_remindertime:'minutes'}
                let _rowReminders = rowReminders;
                _rowReminders.push(obj);
                setrowReminders([..._rowReminders]);
            }
        }
        if(isEditApppointment && !subject){
            let _subject  = element.details.t_subject;
            setSubject(_subject);
        }
    },[isAddNewTask])

    const stageValidation = useCallback(() => {
        const updatedTasks = tasks?.map((task, index) => {
            const precedingTask = tasks[index - 1];
            if (task.type == WORKFLOW_SETUP.TYPE_TASK && task.details.t_days_after == 'Previous_Mail_Is_Sent') {
                if (!precedingTask || (precedingTask && precedingTask.type != WORKFLOW_SETUP.TYPE_AUTO_MAIL)) {
                    // Add an error message to the task
                    return {
                        ...task,
                        errorMessage:
                            'This task is dependant on a preceding email. It needs to be placed after an email. Please drag and position this task after an email.',
                        // isValid: false
                    };
                } else {
                    delete task.errorMessage;
                    return {
                        ...task,
                        // errorMessage: '',
                        // isValid: false
                    };
                }
            } else if (
                task.type == WORKFLOW_SETUP.TYPE_TASK &&
                (task.details.t_days_after == 'Previous Task' || task.details.t_days_after == 'Previous_Task_Completed')
            ) {
                if (!precedingTask || (precedingTask && precedingTask.type != WORKFLOW_SETUP.TYPE_TASK)) {
                    if (task.details.t_days_after == 'Previous Task') {
                        // Add an error message to the task
                        return {
                            ...task,
                            errorMessage:
                                'Error: This task is dependant on a preceding task. It needs to be placed after a task. Please drag and position it after another task.',
                            // isValid: false
                        };
                    } else if (task.details.t_days_after == 'Previous_Task_Completed') {
                        return {
                            ...task,
                            errorMessage:
                                'Error: This task is dependant on a preceding task. It needs to be placed after a task. Please drag and position it after another task.',
                            // isValid: false
                        };
                    }
                } else {
                    delete task.errorMessage;
                    return {
                        ...task,
                        // isValid: false
                    };
                }
            } else if (task.type == WORKFLOW_SETUP.TYPE_TASK && task.details.t_days_after == 'Previous_Text_Message_Is_Sent') {
                if (!precedingTask || (precedingTask && precedingTask.type != WORKFLOW_SETUP.TYPE_TEXT_MESSAGE)) {
                    // Add an error message to the task
                    return {
                        ...task,
                        errorMessage:
                            'Error: This task is dependant on a preceding text message. It needs to be placed after a text message. Please drag and position this task after a text message.',
                        // isValid: false
                    };
                } else {
                    delete task.errorMessage;
                    return {
                        ...task,
                        // isValid: false
                    };
                }
            } else if (task.type == WORKFLOW_SETUP.TYPE_AUTO_MAIL && task.details.t_intervalDateFieldId == '-7777') {
                if (!precedingTask || (precedingTask && precedingTask.type != WORKFLOW_SETUP.TYPE_TASK)) {
                    // Add an error message to the task
                    return {
                        ...task,
                        errorMessage:
                            "Error: You have scheduled this email to go out after a task is completed. However, it doesn't have any preceding task. Please correct the order.",
                        // isValid: false
                    };
                } else {
                    delete task.errorMessage;
                    return {
                        ...task,
                        // isValid: false
                    };
                }
            } else if (task.type == WORKFLOW_SETUP.TYPE_AUTO_MAIL && task.details.t_intervalDateFieldId == '-5555') {
                if (!precedingTask || (precedingTask && precedingTask.type != WORKFLOW_SETUP.TYPE_TEXT_MESSAGE)) {
                    // Add an error message to the task
                    return {
                        ...task,
                        errorMessage:
                            "Error: You have scheduled this email to go out after a text message is sent. However, it doesn't have any preceding text message. Please correct the order.",
                        // isValid: false
                    };
                } else {
                    delete task.errorMessage;
                    return {
                        ...task,
                        // isValid: false
                    };
                }
            } else if (task.type == WORKFLOW_SETUP.TYPE_TEXT_MESSAGE && task.details.t_intervalDateFieldId == '-7777') {
                if (!precedingTask || (precedingTask && precedingTask.type != WORKFLOW_SETUP.TYPE_TASK)) {
                    // Add an error message to the task
                    return {
                        ...task,
                        errorMessage:
                            'Error: This message is dependent on a preceding task. It needs to be placed after a task. Please drag and position it after another task.',
                        // isValid: false
                    };
                } else {
                    delete task.errorMessage;
                    return {
                        ...task,
                        // isValid: false
                    };
                }
            } else if (task.type == WORKFLOW_SETUP.TYPE_TEXT_MESSAGE && task.details.t_intervalDateFieldId == '-5555') {
                if (!precedingTask || (precedingTask && precedingTask.type != WORKFLOW_SETUP.TYPE_AUTO_MAIL)) {
                    // Add an error message to the task
                    return {
                        ...task,
                        errorMessage:
                            'Error: This message is dependant on a preceding email. It needs to be placed after an email. Please drag and position this task after an email.',
                        // isValid: false
                    };
                } else {
                    delete task.errorMessage;
                    return {
                        ...task,
                        // isValid: false
                    };
                }
            } else if (task.type == WORKFLOW_SETUP.TYPE_ESCALATION) {
                //    if(node.hasOwnProperty("details") && task.details.length > 0){
                // 	   let nodeArr = $.workflow.isDuplicateStageNode(stageId, TYPE_ESCALATION, task.details);
                // 	   if($.checkParam(nodeArr) && nodeArr.length > 0 && nodeArr[0].id != node.id){
                // 		   let details = task.details;
                // 		   let msg = "";
                // 		   if($.trim($.workflow.getUserNamesByUserId(nodeArr[0].details.t_notifyuserList)) != "" &&
                // 					$.trim($.workflow.getUserNamesByUserId(nodeArr[0].details.t_notifyuserList)) == $.trim($.workflow.getUserNamesByUserId(details.t_notifyuserList)) &&
                // 					$.trim($.workflow.getUserNamesByUserId(nodeArr[0].details.t_assignuserList)) != "" &&
                // 					$.trim($.workflow.getUserNamesByUserId(nodeArr[0].details.t_assignuserList)) == $.trim($.workflow.getUserNamesByUserId(details.t_assignuserList))){
                // 				msg = "Notify and Reassign user already used in another escalation!";
                // 			}else if($.trim($.workflow.getUserNamesByUserId(nodeArr[0].details.t_notifyuserList)) != "" && $.trim($.workflow.getUserNamesByUserId(nodeArr[0].details.t_notifyuserList)) == $.trim($.workflow.getUserNamesByUserId(details.t_notifyuserList))){
                // 				msg = "Notify user already used in another escalation!";
                // 			}else if($.trim($.workflow.getUserNamesByUserId(nodeArr[0].details.t_assignuserList)) !=  "" && $.trim($.workflow.getUserNamesByUserId(nodeArr[0].details.t_assignuserList)) == $.trim($.workflow.getUserNamesByUserId(details.t_assignuserList))){
                // 				msg = "Reassign user already used in another escalation!";
                // 			}
                // 		   msg = "You have another escalation using the same criteria. Please change the criteria of the escalation and try again.";
                // 		   if(msg != ""){
                // 			   $nod.addClass("workflow_error_node");
                // 			   $nod.attr("title",msg);
                // 			   $nod.attr("error",true);
                // 			   isValid = false;
                // 		   }
                // 	   }else{
                // 		   $nod.removeClass("workflow_error_node");
                // 		   $nod.attr("title","");
                // 		   $nod.attr("error",false);
                // 	   }
                //    }
            }
            return task;
        });

        // Use updatedTasks in your state or wherever needed
        return updatedTasks;
    }, [tasks]);

    useEffect(() => {
        if (isDndCardDroped) {
            const stageData = stageValidation();
            dispatch(updateDndCard(stageData, 'tasks'));
            dispatch(setIsDndCardDroped(false));
        }
    }, [dispatch, stageValidation, isDndCardDroped]);

    const getActionList = () => {
        let List = addActionList;
        if (List !== undefined) {
            return List.map((item, i) => {
                return (
                    <MenuItem value={item.name} style={styles.popoverMenuItem}>
                        {item.label}
                    </MenuItem>
                );
            });
        }
    };

    const onChangeActions = (value) => {
        let errorMessage;
        if (
            isShowWebhook ||
            isEditTask ||
            isAddNewTask ||
            isaddNewTextMessage ||
            isAddAppointment ||
            isOpenEmailTask ||
            isEditWebhook ||
            isEditTextmessage ||
            isEditApppointment ||
            isEditEmailTask
        ) {
            if (isShowWebhook) {
                errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.WEBHOOK_OPEN;
            } else if (isEditTask) {
                errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EDIT_ERROR;
            } else if (isAddNewTask) {
                errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EDIT_ERROR;
            } else if (isaddNewTextMessage) {
                errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.TEXT_MESSAGE_ERROR;
            } else if (isAddAppointment) {
                errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.APPOINMENT_ERROR;
            } else if (isOpenEmailTask) {
                errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.CHECK_EMAIL_TASK_OPEN;
            } else if (isEditWebhook) {
                errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.WEBHOOK_OPEN;
            } else {
                errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EDIT_ERROR;
            }
            dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else {
            dispatch(setActions(value));
            if (value === 'New text message') {
                dispatch(setisaddNewTextMessage(true));
                dispatch(setIsFocusOnMessageTask(true));
            } else if (value === 'New task') {
                dispatch(setisAddNewTask(true));
                setIsTaskFocus(true);
            } else if (value === 'New Appointment') {
                var list = selectedStage?.tasks?.filter((f) => f.type == 'New_Appointment'); // checking for selected stage tasks must have only one appoinment
                if (list.length == 0) {
                    dispatch(setisAddAppointmentTask(true));
                    dispatch(setIsFocusOnAppointmentTask(true));
                    setIsScrollToAppointment(true);
                } else {
                    errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.MULTIPLE_APPOINTMENT_AVAILABILITY_ERROR;
                    dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    dispatch(setActions('--Add--'));
                }
            } else if (value === 'New email') {
                let _tableId = OBJECT_TABLEID_MAP[table];
                dispatch(getWorkflowEmailTemplates(_tableId));
                dispatch(setisOpenEmailTask(true));
                dispatch(setisFocusOnEmailTask(true));
            }
        }
    };

    useEffect(() => {
        if (isScrollToAppointment) {
            scrollref.current?.scrollIntoView({ behavior: 'smooth' });
            setIsScrollToAppointment(false);
        }
    }, [isScrollToAppointment]);

    const flattenNodes = () => {
        const nodeArray = nodes;
        let result = [];

        function traverse(node) {
            if (Array.isArray(node)) {
                node.forEach(traverse);
            } else if (typeof node === 'object' && node !== null) {
                if (node.type === WORKFLOW_SETUP.TYPE_STAGE) {
                    let stage = {};
                    stage.details = node.details;
                    stage.id = node.id;
                    stage.order_id = node.order_id;
                    stage.status = node.status;
                    stage.type = node.type;
                    stage.workflow_id = node.workflow_id;
                    result.push(stage);
                } else if (
                    node.type === WORKFLOW_SETUP.TYPE_TASK ||
                    node.type === WORKFLOW_SETUP.TYPE_APPOINTMENT_SUB ||
                    node.type === WORKFLOW_SETUP.TYPE_ESCALATION ||
                    node.type === WORKFLOW_SETUP.TYPE_AUTO_MAIL ||
                    node.type === WORKFLOW_SETUP.TYPE_TEXT_MESSAGE ||
                    node.type === WORKFLOW_SETUP.TYPE_WEBHOOK
                ) {
                    result.push(node);
                }
                Object.values(node).forEach(traverse);
            }
        }

        traverse(nodeArray);
        return result;
    };

    const saveWorkflow = () => {
        let task;
        task = tasks?.find((element) => element?.errorMessage?.length > 0);
        if(!workflowName){
            let errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EMPTY_WORKFLOW_NAME;
            dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            inputRef.current?.focus();
        }else if (task?.errorMessage) {
            let Errortaskid = task.id;
            let isTaskerror = true;
            dispatch(setTaskListkOrderErrorId(Errortaskid, isTaskerror));
        } else {
            let wfObject = {};
            wfObject.form_fields = form_fields; // take the selected required form fields from redux
            wfObject.nodes = flattenNodes(); // flattened nodes - stage - task - webhook
            wfObject.edit_stages = data.edit_stages;
            wfObject.valuelist = data.valuelist;
            wfObject.edit_tasks = data.edit_tasks;
            wfObject.t_workflowName = data.t_workflowName;
            wfObject.id = data.id;
            wfObject.t_tableid = data.t_tableid;
            wfObject.status = data.status;

            let params = {};
            params.myClientId = customDrawerProps.data?.myClientId;
            params.wfObject = wfObject;
            // console.log('saveWorkflow-params==> ', params);
            // const promise = Promise.resolve(HTTPClient.get(endPoints.WORKFLOW_SETUP.SAVE, params));
            // promise.then((response)=>{
            //     console.log('saveWorkflow-response:', response)
            // }).catch((error)=>{
            //     console.log('error-saveWorkflow:', error)
            // })
        }
    };

    const editTask = (task) =>{
        if(isShowWebhook || isEditTask || isAddNewTask || isaddNewTextMessage || isAddAppointment || isOpenEmailTask){
            let errorMessage;
            if(isShowWebhook){
              errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.WEBHOOK_OPEN;
            }else if(isEditTask){
              errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EDIT_ERROR;
            }else if(isAddNewTask){
              errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EDIT_ERROR;
            }else if(isaddNewTextMessage){
              errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.TEXT_MESSAGE_ERROR;
            }else if(isAddAppointment){
              errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.APPOINMENT_ERROR;
            }else if(isOpenEmailTask){
              errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.CHECK_EMAIL_TASK_OPEN;
            }
            dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
          }else if(data.edit_tasks !== 1){
            let errorMessage;
            errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EDIT_NOT_ALLOWED_MSG;
            errorMessage =  errorMessage + " " + task?.type;
            dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
          }else{
            setElement(task);
            setIsTaskFocus(true);
            if(task.type === "Task"){
                const {t_name,t_owner,t_interval,t_interval_mode,t_taskCreated,t_days_after,t_priority,t_description,t_notify_salesrep,t_weekdays} = task.details;
                let _taskList = taskList;
                let isAvailable = false;
                _taskList?.map((task)=>{if(task.taskname === t_name){isAvailable = true;}});
                if(!isAvailable){
                    let tempObj = {};
                    tempObj.description = t_name;
                    tempObj.taskname = t_name;
                    _taskList = _taskList.filter((ele)=> ele.taskname !== "--Add--");
                    _taskList = [..._taskList,tempObj];
                    dispatch(addNewTaskNameInTaskList(_taskList));
                }
                settaskName(t_name? t_name : '');
                setOwner(t_owner);
                setDueBy(t_interval);
                setdueByTime(t_interval_mode);
                setdueByCondition(t_taskCreated);
                setdueByFields(t_days_after);
                if(task.details?.t_reminderDetails){
                    let _rowReminders = rowReminders;
                    if(task.details.t_reminderDetails.length == 0){
                        let obj = {id:765567,name:'',t_remindertype:'',t_reminder:10,t_remindertime:'minutes'}
                        _rowReminders.push(obj);
                        setrowReminders([..._rowReminders]);
                    }else{
                        setrowReminders(task.details.t_reminderDetails);
                    }
                }
                setPriority(t_priority);
                setDescription(t_description);
                let isNotify = t_notify_salesrep === 0 ? false : true;
                setnotifyUnit(isNotify);
                let _weekdays = t_weekdays === 0 ? false : true;
                setscheduleOnWeekend(_weekdays);
                let _attachment  = task?.attachment?.attached_files;
                setAttachFiles(_attachment);
                let url = task?.attachment?.attached_urls;
                setlinkList(url);
                dispatch(setisOpenEditTask(true));
            }
            else if(task.type === "New_Appointment"){
                setSubject(task.t_subject);
                dispatch(setisEditAppoinmentTask(true));
                setIsScrollToAppointment(true);
                dispatch(setIsFocusOnAppointmentTask(true));
            }else if(task.type == "Text_Message"){
                dispatch(setisEditTextMessage(true));
                dispatch(setIsFocusOnMessageTask(true));
            }else if(task.type == "Auto_Mail"){
                dispatch(setisEditEmailTask(true));
                dispatch(setisFocusOnEmailTask(true));
            }
        }
    }

    const onChangeTaskName = (value) =>{
        settaskName(value);
        setnewTask('');
        if(value === '--Add--'){
            setIsFocusOnTaskname(true);
        }
    }

    const onChangeAddTaskName = (value) =>{
        setnewTask(value);
    }

    const getTaskNameList = () =>{
        let List = taskList;
        if (List !== undefined) {
            return List.map((item, i) => {
                return <MenuItem value={item.taskname} style={styles.popoverMenuItem} >{item.taskname}</MenuItem>;
            })
        }
    }

    const onChangeOwnerFunc = (value) =>{
        setOwner(value);
    }

    const getOwnerNameList = () =>{
        let List = userlist;
        let isUnitOwner = false;
    	//    if(operationModule == 1 && $.workflow.getWorkflow().t_tableid == PROJECT_TABLE_ID){
		// 	   isUnitOwner = true;
		//    }
    	  
    	   if(app.me.is_franchisor && data.t_tableid === OBJECT_TABLEID_MAP.accounts ){
    			isUnitOwner = true;
    	   }

    	   if(isUnitOwner){
    		    let user = {}
                user.value = "Unit Owner"
                user.text = "Unit Owner"
                user.id = getUniqueId();
                List = [...userlist,{...user}]
    	   }
        if (List?.length > 0) {
            return List.map((item, i) => {
                return <MenuItem value={item.value} style={styles.popoverMenuItem} >{item.value}</MenuItem>;
            })
        }
    }

    const onChangeDueBy = (event) =>{
        let value = event.target.value;
        setDueBy(value);
    }

    const getDayTimeList = () =>{
        let List = dayTimeList;
        if (List !== undefined) {
            return List.map((item, i) => {
                return <MenuItem value={item.name} style={styles.popoverMenuItem} >{item.label}</MenuItem>;
            })
        }
    }

    const onChangeDueByTime = (value) =>{
        setdueByTime(value);
    }

    const getdueByConditionList = () =>{
        let List = dueByConditionList;
        if (List !== undefined) {
            return List.map((item, i) => {
                return <MenuItem value={item.name} style={styles.popoverMenuItem} >{item.label}</MenuItem>;
            })
        }
    }

    const onChangeDueByCondition = (value) =>{
        setdueByCondition(value)
    }

    const onChangeDueByFields = (value) =>{
        setdueByFields(value);
    }
    
    // getTaskDaysAfterData(details.t_days_after, details.t_taskCreated, details.t_interval_mode)
    const getTaskDaysAfterData = (key, type, interval_mode) => {
        let daysAfter = [];
        let _customDateFormFId = [];
        customDateFormFId.forEach((item, index) => {
                if (isValidParam(item)) {
                    if (isValidParam(Object.values(item)[0]) && isValidParam(Object.keys(item)[0])) {
                        let data = {};
                        data.key = Object.keys(item)[0];
                        data.value = Object.values(item)[0];
                        _customDateFormFId.push(data);
                    }
                }
            });

        if(type == 'After'){
            daysAfter.push({"key":"Create Date", "value":"Created Date"});
            daysAfter.push({"key":"Current_Date", "value":"Current Date"});
            if (_customDateFormFId && _customDateFormFId.length > 0){
                _customDateFormFId.forEach(( item, index )=>{
                     let dateType =  item?.value?.substring(item?.value?.indexOf("_") + 1);
                    let subValue = item?.value?.substring(0,item?.value?.indexOf("_"));
                    if(interval_mode == "days" && (dateType == "204" || dateType == "224")){
                        daysAfter.push({"key":item.key, "value":subValue});
                    } 
                    if(interval_mode == "hours" && dateType == "224") {
                        daysAfter.push({"key":item.key, "value":subValue});
                    }
                })
            }
            daysAfter.push({"key":"Previous Task", "value":"The preceding task is created"});
            daysAfter.push({"key":"Previous_Task_Completed", "value":"The preceding task is completed"});
            if(OBJECT_TABLEID_MAP[data.t_tableid] == OBJECT_TABLEID_MAP.accounts){
                daysAfter.push({"key":"Previous_Mail_Is_Sent", "value":"The preceding email is sent"});
                daysAfter.push({"key":"Previous_Text_Message_Is_Sent", "value":"The preceding text message is sent"});
            }
        } else {
            if (_customDateFormFId && _customDateFormFId.length > 0){
                _customDateFormFId.forEach((item, index )=>{
                    let dateType =  item?.value?.substring(item?.value?.indexOf("_") + 1);
                    let subValue = item?.value?.substring(0,item?.value?.indexOf("_"));
                    if(interval_mode == "days" && (dateType == "204" || dateType == "224")){
                        daysAfter.push({"key":item.key, "value":subValue});
                    }
                    if(interval_mode == "hours"  && dateType == "224") {
                        daysAfter.push({"key":item.key, "value":subValue});
                    }
                })
            }
        }
        
        if(daysAfter.length == 0) {
            daysAfter.push({"key":"-999", "value":"No date fields found"});
        }
        
        if(key){
            let findVal = daysAfter.filter((obj)=> obj.key == key)
            if(findVal.length > 0){
                return findVal[0].value;
            }else{
                return "";
            }
        } else {
            return daysAfter;
        }   

    }
    
    const getdueByFieldList = () =>{
        let customDateFormFId = getTaskDaysAfterData('',dueByCondition, dueByTime);
            if (customDateFormFId?.length > 0) {
                return customDateFormFId.map((item, i) => {
                    return <MenuItem value={item.value} style={styles.popoverMenuItem} >{item.value}</MenuItem>;
                })
            }
    }

    const deletesAttachALink = (url,idx) =>{
        let _linkList = linkList;
        let  filtered_linklist;
        filtered_linklist  = _linkList.filter((url,index)=> index !== idx);
        setlinkList([...filtered_linklist]);
    }

    const setTextInputdueByRef = (element) =>{
        inputRef.current = element;
    }
    const setNewTaskInputRef = (element) =>{
        inputRef.current = element;
    }

    const getEditTaskElement = () =>{
        let div = (
            <>
                <div style={{width:'100%',height:'fit-content',display:'flex',paddingLeft:'4%'}}>
                    <div style={{width:'58%',display:'inline',height:'fit-content',background:'#E5E5E5',marginLeft:'-3.5%'}}>
                        <div style={{width:'92%',marginLeft:'1rem',paddingTop:'20px'}}>
                            {taskName !== '--Add--' && <FormControl letiant="outlined" style={{ width: '100%' }} className='audit-select'>
                                <InputLabel id="sf-advanced-search-form-simple-select-outlined-label" className="sf-advanced-search-forms-fromadvancesearch" style={{marginTop:taskName?.length > 0 ?'5px' :'-6px'}}>{getLocalizedStrings().label.WORKFLOW_SETUP.TASK_NAME}</InputLabel>
                                <Select
                                    key="sort-by"
                                    name="sort-by"
                                    labelId='sort-by-label'
                                    id='TaskName'
                                    autoFocus={isTaskFocus}
                                    label="Task Name"
                                    value={taskName}
                                    style={{width:'100%'}}
                                    size="small"
                                    className={"sf-select"}
                                    onChange={(event) => onChangeTaskName(event.target.value)}
                                >
                                    {getTaskNameList()}
                                </Select>
                            </FormControl>
                            }
                            {taskName === '--Add--' && 
                                <FormControl style={{ width: '100%' ,marginTop:'2px'}} className="test" noValidate autoComplete="off">
                                    <TextField
                                        letiant="outlined"
                                        name="due_by"
                                        id='TaskName'
                                        label="Task Name"
                                        fullWidth={true}
                                        autoFocus={isFocusOnTaskname}
                                        inputRef={(taskName === '--Add--' && newTask === '')?setNewTaskInputRef:''}
                                        value={newTask}
                                        style={{width:'100%'}}
                                        onChange={(event) => onChangeAddTaskName(event.target.value)}
                                        inputprops={{ maxlength: '256' }}
                                        autoComplete="new-password"
                                        margin='dense'
                                        className={"sf-fields-bg"}
                                        size="small"
                                    />
                                </FormControl>
                            }
                        </div>
                        <div style={{width:'92%',marginLeft:'1rem',display:'flex'}}>
                            <FormControl letiant="outlined" style={{ width: '24%',marginTop:'10px',marginRight:'10px' }} className='audit-select'>
                                <InputLabel id="sf-advanced-search-form-simple-select-outlined-label" className="sf-advanced-search-forms-fromadvancesearch" style={{marginTop:owner?.length > 0 ?'5px' :'-6px'}}>{getLocalizedStrings().label.WORKFLOW_SETUP.OWNER}</InputLabel>
                                <Select
                                    key="sort-by"
                                    name="sort-by"
                                    labelId='sort-by-label'
                                    id='OwnerName'
                                    label="Owner"
                                    value={owner}
                                    style={{width:'100%'}}
                                    size="small"
                                    className={"sf-select"}
                                    onChange={(event) => onChangeOwnerFunc(event.target.value)}
                                >
                                    {getOwnerNameList()}
                                </Select>
                            </FormControl>

                            <FormControl style={{ width: '15%' ,marginTop:'2px'}} className="test" noValidate autoComplete="off">
                                <TextField
                                    letiant="outlined"
                                    name="due_by"
                                    id="due_by"
                                    label="Due by"
                                    fullWidth={true}
                                    value={dueBy}
                                    inputRef={!dueBy ? setTextInputdueByRef : ''}
                                    style={{width:'100%'}}
                                    onChange={(e) =>onChangeDueBy(e)}
                                    inputprops={{ maxlength: '256' }}
                                    autoComplete="new-password"
                                    margin='dense'
                                    className={"sf-fields-bg"}
                                    size="small"
                                />
                            </FormControl>

                            <FormControl letiant="outlined" style={{ width: '15%',marginLeft:'10px',marginTop:'10px' }} className='audit-select'>
                                <Select
                                    key="sort-by"
                                    name="sort-by"
                                    labelId='sort-by-label'
                                    id='datetime'
                                    value={dueByTime}
                                    style={{width:'100%'}}
                                    size="small"
                                    className={"sf-select"}
                                    onChange={(event) => onChangeDueByTime(event.target.value)}
                                >
                                    {getDayTimeList()}
                                </Select>
                            </FormControl>

                            <FormControl letiant="outlined" style={{ width: '18%',marginLeft:'10px',marginTop:'10px' }} className='audit-select'>
                                <Select
                                    key="sort-by"
                                    name="dueByCondition"
                                    labelId='sort-by-label'
                                    id='datetime'
                                    value={dueByCondition}
                                    style={{width:'100%'}}
                                    size="small"
                                    className={"sf-select"}
                                    onChange={(event) => onChangeDueByCondition(event.target.value)}
                                >
                                    {getdueByConditionList()}
                                </Select>
                            </FormControl>

                            <FormControl letiant="outlined" style={{ width: '31%',marginLeft:'10px',marginTop:'10px' }} className='audit-select'>
                                <Select
                                    key="sort-by"
                                    name="dueByFields"
                                    labelId='sort-by-label'
                                    id='datetime'
                                    value={dueByFields}
                                    style={{width:'100%'}}
                                    size="small"
                                    className={"sf-select"}
                                    onChange={(event) => onChangeDueByFields(event.target.value)}
                                >
                                    {getdueByFieldList()}
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{width:'92%',marginLeft:'1rem',marginTop:'0.5rem'}}>
                            <span style={{fontWeight:'bolder'}}>{getLocalizedStrings().label.WORKFLOW_SETUP.REMINDER}</span>
                        </div>
                        <div>
                            {rowReminders?.map((reminder)=>{
                                return <div style={{width:'92%',marginLeft:'1rem',display:'flex'}}>
                                    <FormControl letiant="outlined" style={{ width: (reminder.t_remindertype).length > 0 ? '33.5%':'25%',marginTop:'10px' }} className='audit-select'>
                                        <Select
                                            key="sort-by"
                                            name="remindertype"
                                            labelId='sort-by-label'
                                            id='datetime'
                                            value={reminder.t_remindertype}
                                            style={{width:'100%'}}
                                            size="small"
                                            className={"sf-select"}
                                            onChange={(event) => onChangeReminderTypesFields(event.target.value,reminder)}
                                        >
                                            {getreminderTypeFieldList()}
                                        </Select>
                                    </FormControl>

                                    {reminder?.t_remindertype.length > 0 &&<div style={{width:'100%'}}>
        
                                        <FormControl style={{ width: '15%' ,marginTop:'2px',marginLeft:'10px'}} className="test" noValidate autoComplete="off">
                                            <TextField
                                                letiant="outlined"
                                                name="due_by"
                                                id="due_by"
                                                label="Due by"
                                                fullWidth={true}
                                                value={reminder.t_reminder}
                                                style={{width:'100%'}}
                                                onChange={(event) =>onChangeReminderCount(event.target.value,reminder)}
                                                inputprops={{ maxlength: '256' }}
                                                autoComplete="new-password"
                                                margin='dense'
                                                className={"sf-fields-bg"}
                                                size="small"
                                            />
                                        </FormControl>
            
            
                                        <FormControl letiant="outlined" style={{ width: '27%',marginTop:'10px' ,marginLeft:'15px'}} className='audit-select'>
                                            <Select
                                                key="sort-by"
                                                name="reminderTimefield"
                                                labelId='sort-by-label'
                                                id='datetime'
                                                value={reminder.t_remindertime}
                                                style={{width:'100%'}}
                                                size="small"
                                                className={"sf-select"}
                                                onChange={(event) => onChangeReminderTimeField(event.target.value,reminder)}
                                            >
                                                {getreminderTimeFieldList()}
                                            </Select>
                                        </FormControl>
                                    </div>
                                }
                                </div>
                            })}
                        </div>
                        <div  onClick={()=>addReminder()} style={{width:'fit-content',marginLeft:'1rem',marginTop:'1rem', cursor:'pointer', color:'blue'}}>
                            {getLocalizedStrings().label.WORKFLOW_SETUP.ADD_ANOTHER_REMINDER}  
                        </div>
                        <div  style={{width:'92%',marginLeft:'1rem',marginTop:'0.3rem',display:'flex'}}>
                            <FormControl letiant="outlined" style={{ width: '30%',marginTop:'10px',marginRight:'10px' }} className='audit-select'>
                                <InputLabel id="sf-advanced-search-form-simple-select-outlined-label" className="sf-advanced-search-forms-fromadvancesearch" style={{marginTop:priority?.length > 0 ?'5px' :'-6px'}}>Priority</InputLabel>
                                <Select
                                    key="sort-by"
                                    name="sort-by"
                                    labelId='sort-by-label'
                                    id='Priority'
                                    label="Owner"
                                    value={priority}
                                    style={{width:'100%'}}
                                    size="small"
                                    className={"sf-select"}
                                    onChange={(event) => onChangePriorityFunc(event.target.value)}
                                >
                                    {getPriorityList()}
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{width:'92%',marginLeft:'1rem',marginTop:'1rem',display:'flex'}}>
                            <FormControlLabel control={<Checkbox defaultChecked={true} onClick={(event)=>onchangeNotifyUnitOwnerFunc(event.target.checked)} checked={notifyUnit}/>} label={getLocalizedStrings().label.WORKFLOW_SETUP.NOTIFY_UNIT_OWNER}/>
                            <FormControlLabel control={<Checkbox  defaultChecked={true} onClick={(event)=>onchangeScheduleOnWeekendFunc(event.target.checked)} checked={scheduleOnWeekend}/>} label={getLocalizedStrings().label.WORKFLOW_SETUP.SCHEDULE_ON_WEEKEND} />
                        </div>
                        <div style={{width:'92%',marginLeft:'1rem',marginTop:'1rem',display:'flex'}}>
                            <span style={{fontSize:'16px',fontWeight:'bold'}}>{getLocalizedStrings().label.WORKFLOW_SETUP.ATTACH_DOCUMENT}</span>
                        </div>
                        <div style={{width:'92%',marginLeft:'1rem',marginTop:'0.5rem',display:'flex',background:'white',marginBottom:'0.5rem'}}>
                            <input type="submit" id="attachphoto" size = "57" value = "Choose File" onClick={()=>openAttachment()}/>
                            <span style={{marginLeft:'3px'}}>{getLocalizedStrings().label.WORKFLOW_SETUP.NO_FILE_CHOOSEN}</span>
                        </div>
                        <div style={{width:'92%',marginLeft:'0.6rem',display:'inline',marginBottom:'10px'}}>
                            {getAttachmetFiles()}
                        </div>
                        <div style={{width:'92%',marginLeft:'1rem',display:'flex',marginTop:'10px'}}>
                            <FormControl style={{ width: '78%' ,marginTop:'2px',marginRight:'10px'}} className="test" noValidate autoComplete="off">
                                <TextField
                                    letiant="outlined"
                                    name="link"
                                    id="lilnk"
                                    label="Attach a Link"
                                    fullWidth={true}
                                    value={link}
                                    style={{width:'100%'}}
                                    onChange={(e) =>onChangLinkFunc(e)}
                                    inputprops={{ maxlength: '256' }}
                                    autoComplete="new-password"
                                    margin='dense'
                                    className={"sf-fields-bg"}
                                    size="small"
                                />
                            </FormControl>
                            <Button
                                onClick={() => addaLinkFunc()}
                                style={{
                                    ...styles.primaryButton,
                                    minWidth: '20px',
                                    width: '10%',
                                    marginTop:'20px'

                                }}
                                title={getLocalizedStrings().label.COMMON.ADD}
                            > {getLocalizedStrings().label.COMMON.ADD}</Button>
                        </div>
                        <div style={{width:'92%',marginLeft:'1rem'}}>
                             {
                                linkList?.map((url,idx)=>{
                                    return <div style={{display:'flex'}}>
                                        <LinkIcon />
                                        <span style={{overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', whiteSpace: 'nowrap', width: '300px'}}>{ url }</span>
                                        <ClearIcon  
                                            onClick={() => deletesAttachALink(url,idx)}
                                            style={{cursor:'pointer' ,marginLeft:'5px',fontSize:'20px'}}
                                        />
                                    </div>
                                })
                             }   
                        </div>
                        <div style={{width:'92%',marginLeft:'1rem',paddingBottom:'13px'}}>
                        <Button
                            onClick={() => updateTaskFunc()}
                            style={{
                                ...styles.primaryButton,
                                width: 'fit-content',
                                marginTop:'20px',
                            }}
                            title={getLocalizedStrings().label.WORKFLOW_SETUP.UPDATE_TASK}
                        > {!isAddNewTask ? getLocalizedStrings().label.WORKFLOW_SETUP.UPDATE_TASK : getLocalizedStrings().label.WORKFLOW_SETUP.SAVE_TASK}</Button>
                        <Button
                            onClick={() => closetaskWoekflow()}
                            style={{
                                ...styles.primaryButton,
                                minWidth: '20px',
                                width: '11%',
                                marginTop:'20px',
                            }}
                            title={getLocalizedStrings().label.COMMON.CANCEL}
                        > {getLocalizedStrings().label.COMMON.CANCEL}</Button>

                        </div>
                    </div>
                    <div style={{width:'32%',display:'inline-grid',height:'60%',paddingTop:'2%',background:'#E5E5E5',paddingBottom:'3px'}}>
                            <span style={{marginBottom:'5px',fontWeight:'bolder'}}>{getLocalizedStrings().label.WORKFLOW_SETUP.DESCRIPTION}</span>
                            <FormControl style={{ width: '131%',marginTop:'0rem', backgroundColor: '#fff',height:'fit-content' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    letiant="outlined"
                                    name="description"
                                    multiline={true}
                                    type='textarea'
                                    rows={20}
                                    id='description'
                                    onChange={(e) => onChangDescriptionFunc(e.target.value)}
                                    fullWidth={true}
                                    textareastyle={{ marginTop: '5px', resize: 'none', marginBottom: '5px' }}
                                    inputStyle={{ marginTop: '10px', paddingBottam: '10px', }}
                                    value={description}
                                    className={"sf-fields-bg"}
                                />
                            </FormControl>
                    </div>
                </div>
            </>
        )
        return div;
    }

    const onChangDescriptionFunc = (value) =>{
        setDescription(value);
    }


    const updateTaskFunc = () =>{
        if(!dueBy){
            let errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.DUE_BY_EMPTY;
            dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            inputRef.current?.focus();
        }else if(taskName === '--Add--' && newTask === ''){
            let errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.TASK_NAME_EMPTY;
            dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            inputRef.current?.focus();
        }else{
            const orderId = getOrderId([{...selectedStage}])
            let obj= {};
            obj.workflow_id = selectedStage.workflow_id;
            if(taskName === '--Add--'){
                let tempObj = {};
                tempObj.description = newTask;
                tempObj.taskname = newTask;
                let _taskList = taskList;
                _taskList = _taskList.filter((ele)=> ele.taskname !== "--Add--");
                _taskList = [..._taskList,tempObj];
                dispatch(addNewTaskNameInTaskList(_taskList));
            }
            let details = {
                    "t_remindertype": "",
                    "t_days_after": dueByFields,
                    "t_tablefldId": 168,
                    "t_reminderDetails": rowReminders,
                    "t_notify_salesrep": notifyUnit == true ? 1 : 0,
                    "t_owner": owner,
                    "t_weekdays": scheduleOnWeekend == true ? 1 : 0,
                    "t_description": description,
                    "t_interval_mode": dueByTime,
                    "t_name": taskName !== '--Add--' ? taskName : newTask,
                    "t_remindertime": "",
                    "t_taskCreated": dueByCondition,
                    "t_priority": priority,
                    "t_interval": dueBy,
                    "t_reminder": -1
            }
            obj.details = details;
            obj.type = "Task";
            obj.status = status;
            let attachment = {
                attached_urls: linkList,  // linkList
                attached_files: [ // attachFiles
                {
                    uid: "P0JSQ9JF6DBJEKX0VBV2UTPQFYQ1CVGS",
                    name: "2023-06-16_17-46-41.png",
                    urlS3: "https://s3.amazonaws.com/snapshotcrm.test/task/k_69651701341392627.png"
                }
                ]
            }
            obj.attachment = attachment;
            if(isAddNewTask){
                obj.id = Math.floor(Math.random() * Date.now());
                obj.stage_id = selectedStage.id;
                obj.order_id = orderId;
                dispatch(updateWorkflowSetupAddNewTask(obj));
            }else if(isEditTask){
                obj.id = element.id;
                obj.stage_id = element.stage_id;
                obj.order_id = selectedStage.order_id;
                dispatch(updateActionTaskInStage(obj));
            }
            dispatch(setisOpenEditTask(false));
            dispatch(setisAddNewTask(false));
            updateStateValue();
            dispatch(setActions('--Add--'));
            setnewTask('');
            setIsFocusOnTaskname(false);
        }
    }

    const updateStateValue = () =>{
        settaskName('');
        setOwner('');
        setDueBy(0);
        setdueByCondition('');
        setdueByFields('');
        setdueByTime('');
        setPriority('');
        setnotifyUnit(false);
        setscheduleOnWeekend(false);
        setAttachFiles([]);
        setLink('');
    }

    const closetaskWoekflow=() =>{
        dispatch(setisAddNewTask(false));
        dispatch(setisOpenEditTask(false));
        updateStateValue();
        dispatch(setActions('--Add--'));
    }

    const openAttachment = () =>{
        let data = {
            contentStyle: { width: '80%', maxWidth: 'none' },
            type: "link",
            fullWidth: true,
            maxWidth: 'md',
        }
        data.callFrom = constants.SEND_SMS;
        data.files = getArrayParam(attachFiles);
        dispatch(getAppDialog(true, constants.LIBRARY_DIALOG, getLocalizedStrings().message.COMMON.ASSETMENU_DIALOG_TITLE, (sendData) => {

            let attachFile = {};
            attachFile.uid = getUniqueId();
            attachFile.name = sendData.name;
            attachFile.urlS3 = sendData.src;
            let tempattachFiles = [...attachFiles, {...attachFile}];
            dispatch(getAppDialog(false, null, null, null, null, null));
            setAttachFiles(tempattachFiles);
            setDummyData(true)
        }, data, constants.SEND_SMS));
    }

    const getAttachmetFiles= () =>{
        let files = getArrayParam(attachFiles);
        let ul = null;
            let li = files.map((f, index) => {
                return (
                <div key={f.name + index} style={{display:'flex',marginLeft:'16px'}}>
                    <AttachmentIcon  stye={{}}/>
                    <span style={{  overflow: 'hidden',
                        textOverflow: 'ellipsis', display: 'block', whiteSpace: 'nowrap', width: '150px'}} 
                        title={f.fileName}> 
                        {f.name} 
                    </span>
                    <ClearIcon
                    style={{ paddingRight: '5px', cursor: 'pointer' }}
                        onClick={() => {
                            let tempAttachFiles = attachFiles;
                            tempAttachFiles.splice(index, 1);
                            dispatch(getAppDialog(false, null, null, null, null, null));
                            setAttachFiles(tempAttachFiles);
                        }}
                    />
                    
                </div>)
            });
            if (li != null && li.length > 0) {
                return li;
            }

    }


    const addReminder = () =>{
        let row={};
        row.id= Math.floor(Math.random() * Date.now());
        row.name='';
        row.t_remindertype ='';
        row.t_reminder = 10;
        row.t_remindertime = 'minutes';
        let _rowReminders = rowReminders;
        _rowReminders.push(row);
        setrowReminders([..._rowReminders]);
    }

    const onchangeNotifyUnitOwnerFunc=(value)=>{
        setnotifyUnit(value);
    }
    const onchangeScheduleOnWeekendFunc = (value) =>{
        setscheduleOnWeekend(value);
    }
    
    const getreminderTypeFieldList = () =>{
        let List = reminderTypeList;
        if (List !== undefined) {
            return List.map((item, i) => {
                return <MenuItem value={item.name} style={styles.popoverMenuItem} >{item.label}</MenuItem>;
            })
        }
    }

    const onChangeReminderTypesFields = (value,ele) =>{
        let _rowReminders = getArrayParam(rowReminders);
        _rowReminders.forEach(row => {
            if (row.id == ele.id) {
                row.t_remindertype = value;
            }
        });
        setrowReminders([..._rowReminders]);
    }

    const onChangeReminderCount = (value,ele) =>{
        let _rowReminders = getArrayParam(rowReminders);
        _rowReminders.forEach(row => {
            if (row.id === ele.id) {
                row.t_reminder = value;
            }
        });
        setrowReminders([..._rowReminders]);
    }

    const getreminderTimeFieldList = () =>{
        let List = reminderTimeList;
        if (List !== undefined) {
            return List.map((item, i) => {
                return <MenuItem value={item.name} style={styles.popoverMenuItem} >{item.label}</MenuItem>;
            })
        }
    }

    const onChangeReminderTimeField=(value,ele)=>{
        let _rowReminders = getArrayParam(rowReminders);
        _rowReminders.forEach(row => {
            if (row.id === ele.id) {
                row.t_remindertime = value;
            }
        });
        setrowReminders([..._rowReminders]);
    }
    const getPriorityList= () =>{
        let List = priorityList;
        if (List !== undefined) {
            return List.map((item, i) => {
                return <MenuItem value={item.name} style={styles.popoverMenuItem} >{item.label}</MenuItem>;
            })
        }
    }

    const onChangePriorityFunc = (value) =>{
        setPriority(value);
    }

    const onChangLinkFunc=(event)=>{
        let value = event.target.value;
        setLink(value);
    }

    const addaLinkFunc=()=>{
        if(linkList.length < 10){
            var urlName = link;
            if(urlName != "" && urlName != null && urlName != undefined){
                urlName = urlName.trim();
                const regex = /((ht|f)tp(s?)\:\/\/|~\/|\/)?(\w+:\w+@)?(([-\w]+\.)+(com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum|travel|[a-z]{2}))(:[\d]{1,5})?(((\/([-\w~!$+|.,=]|%[a-f\d]{2})+)+|\/)+|\?|#)?((\?([-\w~!$+|.,*:]|%[a-f\d{2}])+=([-\w~!$+|.,*:=]|%[a-f\d]{2})*)(&([-\w~!$+|.,*:]|%[a-f\d{2}])+=([-\w~!$+|.,*:=]|%[a-f\d]{2})*)*)*(#([-\w~!$+|.,*:=]|%[a-f\d]{2})*)?/;
                if(regex.test(urlName)){
                    let _linkList = linkList;
                    _linkList.push(urlName);
                    setlinkList([..._linkList]);
                }else{
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.WORKFLOW_SETUP.INVALID_URL, null);
                }
            }else{
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.WORKFLOW_SETUP.INVALID_URL, null);
            }
        }else{
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.WORKFLOW_SETUP.NO_OF_URL_ALLOWED, null);
        }

    }

    const updateAddNewAppointment = () =>{
        const orderId = getOrderId([{...selectedStage}])
        if(subject)
        {
            if(isAddAppointment){
                let obj = {};
                obj.id =Math.floor(Math.random() * Date.now());
                obj.t_subject = subject;
                obj.workflow_id = selectedStage.workflow_id;
                obj.stage_id = selectedStage.id;
                obj.order_id = orderId;
                obj.type = "New_Appointment";
                obj.status = "Active";
                obj.details = {
                        "t_subject": subject,
                        "detailStatusFlg": 1
                    };
                dispatch(updateWorkflowSetupAddNewTask(obj));
            }else if(isEditApppointment){
                let task = element;
                let obj = {};
                obj.workflow_id = task.workflow_id;
                obj.t_subject = subject;
                obj.stage_id = task.stage_id;
                obj.details =  {
                      "t_subject": subject,
                      "detailStatusFlg": 2
                    };
                obj.id = task.id;
                obj.type = task.type;
                obj.order_id = task.order_id;
                dispatch(updateActionTaskInStage(obj));
            }
            setSubject('');
            dispatch(setisAddAppointmentTask(false));
            dispatch(setisEditAppoinmentTask(false));
            dispatch(setActions('--Add--'));
        }else{
            let errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.APPOINTMENT_ERROR;
            dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            inputRef.current?.focus();

        }

    }
    const closeAddNewApointmentfunc = () =>{
        setSubject('');
        dispatch(setisAddAppointmentTask(false));
        dispatch(setisEditAppoinmentTask(false));
        dispatch(setActions('--Add--'));
    }

    const onChangeSubjectFunc = (value) =>{
        setSubject(value);
    }

    const onChangeNameFunc = (value) =>{
        setworkflowName(value);
    }

    const handleChangeStatusFunc = (event) =>{
        setStatus(event.target.value);
    }

    const getStatusList = () =>{
        let List = statusList;
        if (List !== undefined) {
            return List.map((item, i) => {
                return <MenuItem value={item.name} style={styles.popoverMenuItem} >{item.label}</MenuItem>;
            })
        }
    }

    const getTableList = () => {
        let List = tableList;
        if (List !== undefined) {
            return List.map((item, i) => {
                return <MenuItem value={item.name} style={styles.popoverMenuItem} >{item.label}</MenuItem>;
            })
        }
    }

    const focusOntextfield = () =>{

    }

    const handleChangeTableFunc = (event) =>{
        setTable(event.target.value);
    }
    const setTextInputworkflownameRef = (element) =>{
        inputRef.current = element;
    }

    const getTopNavWorkflow = () =>{
        let element = <div style={{width:'100%',marginLeft:'3.5rem',display:'flex'}}>
            <Box sx={{ width: '25%' }}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" style={{marginTop:table?.length > 0 ?'' : '-6px'}}>{getLocalizedStrings().label.WORKFLOW_SETUP.TABLE}</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={table}
                        size='small'
                        label="Table"
                        disabled
                        onChange={handleChangeTableFunc}
                        >
                        {getTableList()}
                    </Select>
                </FormControl>
            </Box>
            <FormControl style={{ width: '40%' ,marginLeft:'10px',marginTop:'-9px'}} className="test" noValidate autoComplete="off">
                <TextField
                    letiant="outlined"
                    name="due_by"
                    id='subject'
                    label="Name"
                    fullWidth={true}
                    value={workflowName}
                    inputRef={!workflowName ? setTextInputworkflownameRef : ''}
                    style={{width:'100%'}}
                    onChange={(event) => onChangeNameFunc(event.target.value)}
                    inputprops={{ maxlength: '256' }}
                    autoComplete="new-password"
                    margin='dense'
                    className={"sf-fields-bg"}
                    size="small"
                />
            </FormControl>
            <Box sx={{ width: '22%' ,marginLeft:'10px'}}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" style={{marginTop:status?.length > 0 ?'' : '-6px'}}>{getLocalizedStrings().label.WORKFLOW_SETUP.STATUS}</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        size='small'
                        label="Table"
                        onChange={handleChangeStatusFunc}
                        >
                        {getStatusList()}
                    </Select>
                </FormControl>
            </Box>
        </div>
        return element;
    }

    const setTextInputRef = (element) =>{
        inputRef.current = element;
    }

    const getAddAppointmentSection = () =>{
        let element = <div  style={{width:'100%',height:'fit-content'}}>
            <div style={{display:'flex',marginLeft:'1.5rem'}} >
                <Button
                        onClick={() => updateAddNewAppointment()}
                        style={{
                            ...styles.primaryButton,
                            minWidth: '20px',
                            width: isEditApppointment? '7%': '5%',
                            marginTop:'20px',
                        }}
                        title={ !isAddAppointment ? getLocalizedStrings().label.COMMON.UPDATE : getLocalizedStrings().label.COMMON.ADD}
                    > { !isAddAppointment ? getLocalizedStrings().label.COMMON.UPDATE : getLocalizedStrings().label.COMMON.ADD}</Button>
                <Button
                    onClick={() => closeAddNewApointmentfunc()}
                    style={{
                        ...styles.primaryButton,
                        minWidth: '20px',
                        width: '7%',
                        marginTop:'20px',
                    }}
                    title={getLocalizedStrings().label.COMMON.CANCEL}
                > {getLocalizedStrings().label.COMMON.CANCEL}</Button>

                </div>
            <div>
                <FormControl style={{ width: '70%' ,marginTop:'10px',marginLeft:'1.5rem'}} className="test" noValidate autoComplete="off">
                    <TextField
                        letiant="outlined"
                        name="due_by"
                        id='subject'
                        label="Subject"
                        fullWidth={true}
                        autoFocus
                        inputRef={!subject ? setTextInputRef: ''}
                        value={subject}
                        style={{width:'100%'}}
                        onChange={(event) => onChangeSubjectFunc(event.target.value)}
                        inputprops={{ maxlength: '256' ,autoFocus: true}}
                        autoComplete="new-password"
                        margin='dense'
                        className={"sf-fields-bg"}
                        size="small"
                    />
                </FormControl>
            </div>
        </div>
        return element;
    }
    const handleChangeRadioBtns = (event) =>{
        setValue(event.target.value);
    }

    const getNextStageFunc = () =>{
        const handleMouseMove = (event) => {
            positionRef.current = { x: event.clientX, y: event.clientY };
        
            if (popperRef.current != null) {
              popperRef.current.update();
            }
          };
        let element = <div style={{width:'100%',height:'100%'}}>
            <div style={{paddingTop:'16px',display:'flex'}}>
                <span style={{marginLeft:'10px', font:'bolder 14px Arial, Helvetica, sans-serif'}}>{getLocalizedStrings().label.WORKFLOW_SETUP.CONVERT_TO_NEXT_STAGE}</span>
                <Tooltip
                    title={getLocalizedStrings().message.WORKFLOW_SETUP.CONVERT_NEXT_STAGE_INFO_MESSAGE}
                    placement="top"
                    arrow
                    componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: '#e8eff7',
                            color:'#454545',
                            paddingTop:'6px',
                            paddingBottom:'5px',
                            '& .MuiTooltip-arrow': {
                              color: '#f2f2f3',
                            },
                          },
                        },
                      }}
                    PopperProps={{
                        popperRef,
                        anchorEl: {
                        getBoundingClientRect: () => {
                            return new DOMRect(
                            positionRef.current.x,
                            areaRef.current.getBoundingClientRect().y,
                            0,
                            0,
                            );
                        },
                        },
                    }}
                    >
                    <Box
                        sx={{
                            color: 'primary.contrastText',
                            p: 2,
                            height:'5px',
                            width:'5px',
                            padding:'0px',
                            marginLeft:'5px'
                        }}
                    >
                    <InfoIcon fontSize='small' color="disabled" cursor='pointer' ref={areaRef} onMouseMove={handleMouseMove}/>
                </Box>
            </Tooltip>
            </div>
            <div style={{width:'100%',marginTop:'10px',paddingLeft:'8px'}}>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="Automatically when all the required tasks are done"
                        name="radio-buttons-group"
                        value={value}
                        onChange={handleChangeRadioBtns}
                    >
                        <FormControlLabel value="manually" control={<Radio />} label={getLocalizedStrings().label.WORKFLOW_SETUP.MANUALLY} style={{marginLeft:'10px',fontSize:'15px'}} />
                        <FormControlLabel value="automatically"  control={<Radio />} label={getLocalizedStrings().label.WORKFLOW_SETUP.AUTOMATICALLY} style={{marginLeft:'10px',fontSize:'15px',marginTop:'-13px'}}/>
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
        return element;
    }

    const handleSelectChangeField = (event,value) =>{
        dispatch(setSelectedRequiredFields(value));
    }

    const renderCard = (card, index) => {
        return (
            <WorkflowSetupCard
                key={card.id}
                index={index}
                id={card.id}
                task={card}
                editTask={editTask}
            />
        )
    }

    const addRequiredField = () =>{
        dispatch(setShowRequiredField(true));
    }
    
    const closeWfSetupDrawer = () =>{
        dispatch(closeWorkflowSetupDrawer());
        setIsFocusOnTaskname(false);
        closeDrawer();
    }

    if(data?.nodes){
        let selectedForm = selectedStage?.details.selected_form_fields;
        let formFields = form_fields.filter(({ id }) => !selectedForm.some((e) => e.id === id))
        
        let selectedStageindex = 0;
        nodes?.map((ele,idx)=>{
            if(ele.id === selectedStage.id){
                selectedStageindex = idx;
            }
        })
        return(
            <>    
                <div style={{marginTop: '10px', float:'right',alignItems:'right',justifyContent:'right',display:'flex',marginBottom:'35px'}}>
                    <Button
                        onClick={()=>closeWfSetupDrawer()}
                        style={{ ...styles.secondaryButton, height: 32, paddingLeft: '17px', paddingRight: '17px' }}
                    >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                    <Button
                        onClick={() => saveWorkflow()}
                        style={{
                            ...styles.primaryButton,
                            minWidth: '56px',
                            width: '96%',
                            height: "32px",
                        }}
                        title='Save Workflow'
                    >{getLocalizedStrings().label.WORKFLOW_SETUP.SAVE_WORKFLOW}</Button>
                </div>
                <div style={{width:'100%',overflowX:'hidden'}}>
                    {/* get TopNav section */}
                    <div style={{width:'100%',height:'3rem',marginTop:'7px'}}>
                        {getTopNavWorkflow()}
                    </div>
                    {/* Workflow Setup Stages */}
                    <div style={{marginTop:'30px',marginLeft:'3.5rem'}}>
                        <WorkflowSetupStages />
                    </div>
                    <div style={{paddingTop:'10px',width:'88.5%',height:'fit-content',marginLeft:'3.5rem',marginTop:'20px'}}>
                        <div style={{width:'12%',background:'#E5E5E5',height:'30px',paddingTop:'10px'}}>
                            <span style={{font:'bolder 15px Arial, Helvetica, sans-serif',marginRight:'10px',marginTop:'10px',background:'#E5E5E5',marginLeft:'9px'}}>{getLocalizedStrings().label.WORKFLOW_SETUP.REQUIRED_FIELDS}</span>
                        </div>
                        <div style={{width:'100%',background:'#E5E5E5',paddingTop:'10px',paddingBottom:'20px'}}>
                            {!showRequiredField && <div  onClick={()=>addRequiredField()} style={{width:'fit-content',font:'bold 15px Arial, Helvetica, sans-serif',marginLeft:'1rem',marginTop:'1rem', cursor:'pointer', color:'blue'}}>
                                {getLocalizedStrings().label.WORKFLOW_SETUP.ADD_A_REQUIRED_FIELD}
                            </div>}
                            {showRequiredField &&<Stack spacing={3} sx={{ width: '40rem' ,marginLeft:'1rem'}}>
                                <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    options={formFields}
                                    value={selectedStage?.details.selected_form_fields}
                                    onChange={(event, value) => handleSelectChangeField(event, value)}
                                    getOptionLabel={(option) => option.label}
                                    defaultValue={[form_fields[1]]}
                                    filterSelectedOptions
                                    size='small'
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder={getLocalizedStrings().label.WORKFLOW_SETUP.MORE_REQUIRED_FIELD}
                                    />
                                    )}
                                />
                            </Stack>}
                        </div>
                    </div>
                    <div style={{display:'flex',marginTop:'25px',marginLeft:'3.5rem'}}>
                        <div style={{width:'7%',background:'#E5E5E5',paddingTop:'10px'}}>
                            <span style={{font:'bolder 15px Arial, Helvetica, sans-serif',marginRight:'10px',marginTop:'10px',background:'#E5E5E5',marginLeft:'9px'}}>{getLocalizedStrings().label.COMMON.ACTIONS}</span>
                        </div>
                        <div>
                            <FormControl letiant="outlined" style={{ width: '100%',marginBottom:'3px',marginLeft:'3px' }} className='audit-select'>
                                <Select
                                    key="sort-by"
                                    name="sort-by"
                                    labelId='sort-by-label'
                                    id='AddActions'
                                    value={action}
                                    style={{width:'13rem'}}
                                    size="small"
                                    onChange={(event) => onChangeActions(event.target.value)}
                                >
                                    {getActionList()}
                                </Select>
                            </FormControl>

                        </div>
                    </div>

                    {/* Actions */}
                    <div style={{background:'#E5E5E5',marginLeft: '3.5rem' ,paddingBottom:'20px',marginRight:'7%',height:'fit-content',paddingTop:'20px'}}>
                        <div style={{display:'inline'}}>
                            {selectedStage?.tasks?.length !== 0 ? 
                                <div style={{width:'100%'}}>{selectedStage?.tasks?.map((card, i) => renderCard(card, i))}</div> 
                                :
                                <div>
                                {(!isAddNewTask && !isOpenEmailTask && !isAddAppointment && !isaddNewTextMessage)&&
                                     <div style={{marginLeft:'1rem'}}><span style={{fontSize:'14px',fontWeight:'bold'}}>{getLocalizedStrings().label.WORKFLOW_SETUP.NO_TASK_FOUND} </span></div>
                                }
                                </div>
                            }
                        </div>
                        {
                            isEditTask&& <div>{getEditTaskElement()}</div>
                        }
                        {
                            isAddNewTask && <div>{getEditTaskElement()}</div>
                        }
                        {
                            isaddNewTextMessage && <div style={{width:'100%'}}>
                                <WorkflowSetupMessage task ={element}/>
                            </div>
                        }
                        {
                            isEditTextmessage && <div style={{width:'100%'}}>
                                <WorkflowSetupMessage task ={element}/>
                            </div>
                        }
                        {
                            isAddAppointment && <div ref={scrollref}>{getAddAppointmentSection()}</div>
                        }
                        {
                            isEditApppointment && <div ref={scrollref}>{getAddAppointmentSection()}</div>
                        }
                        {/* workflow Email setup */}
                        {
                            isOpenEmailTask && <div>
                                <WorkflowSetupEmail />
                            </div>
                        }
                        {
                            isEditEmailTask && <div>
                                <WorkflowSetupEmail  task ={element}/>
                            </div>
                        }
                    </div>
                    {/* external Webhooks */}
                    <div style={{paddingBottom:'20px'}}>
                            <WorkflowExternalwebhook  />
                    </div>
                    {/* Next stage section */}
                    {(selectedStageindex+1 < stagesLength) && <div style={{width:'89%',height:'7rem',marginLeft:'3.5rem',background:'#E5E5E5',marginRight:'7%',marginBottom:'30px'}}>
                         {getNextStageFunc()}
                    </div> }
                </div>
            </>
        )
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 3
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        )
    }

}
export default WorkflowSetup;