import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { uploadPhoto } from '../../../services/actions/batchActions';
import { isValidParam, getObjectParam, getStringParam } from '../../../services/helper/parameterVerifier';
import ShowCircularProgress from '../components/circularProgress';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useDispatch, useSelector } from "react-redux";
import { refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';


const ListViewLogoUpload = ({ data }) => {
    const dispatch = useDispatch();

    const appContainer = useSelector((state) => state.appContainer);

    const [imageUrl, setImageUrl] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const [files, setFiles] = useState([]);


    // useEffect(() => {
    //     dispatch(setAppDialogActions(getActions()));
    // }, [files]);

    // const getActions = () => {
    //     const actions = [
           

    //     ];
    //     return actions;
    // }

    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const fnSetImageUrl = (imageUrl) => {
        data.setLogoOnRecord(imageUrl, data.id, data.fieldName);
        handleClose();
    }

    const onChange = (e) => {

        let file = {};
        file.fileName = e.target.files[0].name;
        file.file = e.target.files[0];

        if (file.file.size === 0) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_BLANK_FILE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            e.target.value = null;
            setFiles([]);
            return false;
        } else {
            let fileName = file.fileName;
            fileName = fileName.replace(/'/g, "");
            let fileext = fileName.substring(fileName.lastIndexOf('.') + 1);
            if (fileext.toLowerCase() !== 'jpg' && fileext.toLowerCase() !== 'jpeg' && fileext.toLowerCase() !== 'gif' && fileext.toLowerCase() !== 'png') {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_LOGO_EXTENSION, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                e.target.value = null;

                setFiles([]);
                return false;
            } else {
                let tempFile = [];
                tempFile[0] = file;
                setFiles(tempFile);
            }
        }
        setInProgress(false);
    }

    const onFormSubmit = (files) => {
        if (!isValidParam(files) || files.length === 0) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_ATTACHMENT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }

        for (let i = 0; i < files.length; i++) {
            let fileSize = files[i].file.size;
            if (fileSize > 0 && fileSize >= constants.MAX_IMAGE_FILE_SIZE_FOR_LOGO) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_LOGO_SIZE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }
        }

        setInProgress(true);
        const formData = new FormData();
        let arrFiles = [];
        let tempFiles = files;
        for (let i = 0; i < tempFiles.length; i++) {
            formData.append('file', tempFiles[i].file);
        }
        let url = null;
        let tempData = getObjectParam(data);
        formData.append("input_param", JSON.stringify({ "id": 0, ...tempData }));
        let promise = uploadPhoto(tempData.object, formData);
        promise.then((response) => {
            if (response.data.status === 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.ATTACHMENT_UPLOAD_IMAGE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));

                let dialogProps = appContainer.dialogProps;
                let dialogType = getStringParam(dialogProps.dialogType);
                if (dialogType === constants.LIST_VIEW_LOGO_UPLOAD_DIALOG) {
                    setImageUrl(getStringParam(response.data.data.url));
                    fnSetImageUrl(response.data.data.url);
                } else if (dialogType === constants.DETAIL_VIEW_LOGO_UPLOAD_DIALOG && isValidParam(dialogProps.eventName)) {
                    let setLogoURL = dialogProps.eventName;
                    setLogoURL(response.data.data.url);
                    handleClose();
                }
                dispatch(refreshDetailViewListViewData(true));
            } else {
                setInProgress(false);
                handleClose();
                dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.ATTACHMENT_UPLOAD_FAILURE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        });
    }


    let contentHeight = window.innerHeight - 500;
    let top = (contentHeight - 10) / 2;
    if (inProgress === false) {
        return (
            <div>
                <form method="post" id="photoUploadForm" encType="multipart/form-data" action="uploadPhoto.page" name="uploadPhoto">
                    <div style={{ border: '1px solid rgb(221, 221, 221)', padding: '20px 20px 17px' }}>
                        <div className="row clearfix">
                            <label className="col-sm-3">{getLocalizedStrings().message.ATTACHMENT.UPLOAD_IMAGE}</label>
                            <input className="col-sm-9" type="file" name="img_name" id="img_to_upload" onChange={(e) => onChange(e)} />
                        </div>
                    </div>
                </form>
                <div className='dialogtxt'>
                    {getLocalizedStrings().message.ATTACHMENT.ATTACHMENT_LOGO_TEXT}
                </div>
                <div className='dialogtxt' style={{ marginBottom: '0px' }}>
                    {getLocalizedStrings().message.ATTACHMENT.ATTACHMENT_LOGO_SIZE_TEXT}
                </div>
                <div style={{ width: 'auto' }}>
                    <div style={{ textAlign: 'right', marginTop: '10px'}}>
                        <Button
                            key={'upload'}
                            primary={"true"}
                            onClick={() => onFormSubmit(files)}
                            style={styles.primaryButton}
                        > 
                            {getLocalizedStrings().label.ATTACHMENT.UPLOAD}
                        </Button>
                        <Button
                            key={'close'}
                            primary={"true"}
                            onClick={() => handleClose()}
                            style={styles.secondaryButton}
                        >
                            {getLocalizedStrings().label.COMMON.CLOSE}
                        </Button>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
                <div style={{ width: '100%', height: contentHeight }}>
                        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }} >
                            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                            </div>
                        </div>
                </div>
        );
    }
};

export default ListViewLogoUpload;


