import React from 'react';
import { PivotFieldListComponent } from '@syncfusion/ej2-react-pivotview';
import { enableRipple } from '@syncfusion/ej2-base';
import { constants, USER_REPORT_TYPE } from '../../../services/constants/constants';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import { getSFFormFieldsPromise } from '../../../services/actions/sfFormActions';
import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {forwardRef, useImperativeHandle } from 'react';

enableRipple(true);

let CSS_FOR_PVOT = `
    .e-pivotview {
        width: 58%;
        height: auto;
        float: left;
    }
    .e-pivotfieldlist {
        width: 100%;
        float: right;
    }
    .e-pivotfieldlist .e-static {
        width: 100% !important;
    }
    .e-field-list-filters {
        display: none !important;
    }
    .e-field-list-container {
        height: auto !important;
    }
`;

let CSS_FOR_SUMMARY = `
    .e-pivotview {
        width: 58%;
        height: 60%;
        float: left;
    }
    .e-pivotfieldlist {
        width: 100%;
        height: 90%;
        float: right;
    }
    .e-pivotfieldlist .e-static {
        width: 100% !important;
    }
    .e-field-list-filters {
        display: none !important;
    }
    .e-field-list-columns {
        display: none !important;
    }
    .e-field-list-values {
        margin-top: 0px !important;
    }
    .control-pane {
        height: 500px;
    }
    .control-section {
        height: 500px;
    }
`;

let chart_types = [
    { 'value': '', 'label': '' },
    { 'value': 'Column', 'label': 'Column' },
    { 'value': 'Bar', 'label': 'Bar' },
    { 'value': 'Line', 'label': 'Line' },
    { 'value': 'Spline', 'label': 'Spline' },
    { 'value': 'Area', 'label': 'Area' },
    { 'value': 'SplineArea', 'label': 'SplineArea' },
    { 'value': 'StepLine', 'label': 'StepLine' },
    { 'value': 'StepArea', 'label': 'StepArea' },
    { 'value': 'StackingColumn', 'label': 'StackingColumn' },
    { 'value': 'StackingBar', 'label': 'StackingBar' },
    { 'value': 'StackingArea', 'label': 'StackingArea' },
    { 'value': 'StackingColumn100', 'label': 'StackingColumn100' },
    { 'value': 'StackingBar100', 'label': 'StackingBar100' },
    { 'value': 'StackingArea100', 'label': 'StackingArea100' },
    { 'value': 'Scatter', 'label': 'Scatter' },
    { 'value': 'Bubble', 'label': 'Bubble' },
    { 'value': 'Polar', 'label': 'Polar' },
    { 'value': 'Radar', 'label': 'Radar' },
    { 'value': 'Pareto', 'label': 'Pareto' },
];

const UserReportPivot = forwardRef(({ object, data, reportType }, ref) => {
    const [dataSourceSettings, setDataSourceSettings] = useState({
            dataSource: [],
            columns: [],
            rows: [],
            values: [],
            filters: [],
            enableSorting: true
        },);
    const [mounted, setMounted] = useState(false);
    const [fields, setFields] = useState(data);
    const [field_ids_map, setField_ids_map] = useState({});
    const [fields_name_map, setFields_name_map] = useState({});
    const [fields_currency_map, setFields_currency_map] = useState({});
    const [fieldids_currency_map, setFieldids_currency_map] = useState({});

    const sfForm = useSelector((state) => state.sfForm);

    useEffect(() => {
        getFields();
    }, []);
    useImperativeHandle(ref, () => {
        return {
            fields
        };
    }, [fields]);

    const getFields = () => {
        let tempObject = object;
        let data = Object.assign({}, sfForm.data[tempObject]);
        if (Object.keys(data).length === 0) {
            const promise = getSFFormFieldsPromise(tempObject);
            promise.then((response) => {
                if (isValidParam(response)) {
                    sfForm.data[tempObject] = Object.assign({}, response);
                    processFields(sfForm.data[tempObject]);
                }
            });
        } else {
            processFields(sfForm.data[tempObject]);
        }
    }

    const processFields = (data) => {
        let _fields = {};
        let fields_map = {};
        let id_fields_map = {};
        let fields_name_map = {};
        let fields_currency_map = {};
        let fieldids_currency_map = {};
        let _dataSourceSettings = dataSourceSettings;

        let all_fields = data.fields.filter((el) => {
            return el.field_type !== 'header' && el.label !== '' && el.label != 'Workflow Id';
        });
        if (object == constants.ACCOUNTS_OBJECT) {
            all_fields = all_fields.filter((el) => {
                return el.name !== 'franchise_id' && el.name !== 'lead_score';
            });
        } else if (object === constants.CONTACTS_OBJECT) {
            all_fields = all_fields.filter((el) => {
                return el.name != 'lead_score';
            });
        } else if (object === constants.TASKS) {
            all_fields = all_fields.filter((el) => {
                return el.name !== 'parent_recordid' && el.name !== 'billable' && el.name !== 'start_date' && el.name !== 'end_date' && el.name !== 'item' && el.name !== 'link_info' && el.name !== 't_prevtask' && el.name !== 't_workflow_task';
            });
        }

        all_fields.sort(function (f1, f2) {
            return f1.label.toLowerCase() > f2.label.toLowerCase() ? -1 : 1
        });

        all_fields.forEach((el) => {
            let fieldValue = "";
            if (el.field_type === 'float' || el.field_type === 'integer') {
                fieldValue = 0;
            }
            _fields[el.label] = fieldValue;
            fields_map[el.label] = el.t_tablefldid;
            id_fields_map[el.t_tablefldid] = el.label;
            fields_name_map[el.label] = el.name;
            fields_currency_map[el.label] = el.is_currency_field;
            fieldids_currency_map[el.t_tablefldid] = el.is_currency_field;
        });
        _dataSourceSettings.dataSource = [_fields];
        setField_ids_map(fields_map);
        setFields_name_map(fields_name_map);
        setFields_currency_map(fields_currency_map);
        setFieldids_currency_map(fieldids_currency_map);
        setMounted(true);
        setDataSourceSettings({..._dataSourceSettings});
        loadData(id_fields_map);
    }

    const loadData = (id_fields_map) => {
        let selectedColumns = [];
        let selectedRows = [];
        let selectedFilters = [];
        let selectedValues = [];
        let tempDataSourceSettings = dataSourceSettings;
        let tempFields = fields;
        if (fields !== null) {
            fields.columns.forEach((f) => {
                selectedColumns.push({ name: id_fields_map[f.field_id] });
            });
            fields.rows.forEach((f) => {
                selectedRows.push({ name: id_fields_map[f.field_id] });
            });
            fields.values.forEach((f) => {
                selectedValues.push({ name: id_fields_map[f.field_id], type: f.type, currency: fieldids_currency_map[f.field_id] });
            });
            tempDataSourceSettings.columns = selectedColumns;
            tempDataSourceSettings.rows = selectedRows;
            tempDataSourceSettings.filters = selectedFilters;
            tempDataSourceSettings.values = selectedValues;
            tempFields.rows = fields.rows;
            tempFields.columns = fields.columns;
            tempFields.values = fields.values;
            tempFields.showGrandTotal = fields.showGrandTotal === 1 ? true : false;
            tempFields.showSubTotal = fields.showSubTotal === 1 ? true : false;
            tempFields.chart_type = fields.chart_type;
            tempFields.chart_type_Obj = { value: data.chart_type, label: data.chart_type };
            setDataSourceSettings({ ...tempDataSourceSettings });
            setFields({ ...tempFields });
           }

    }


    const afterPopulate = (data) => {
        if (isValidParam(data.dataSourceSettings)) {
            let _columns = data.dataSourceSettings.columns;
            let _values = data.dataSourceSettings.values;
            let _rows = data.dataSourceSettings.rows;
            let _filters = data.dataSourceSettings.filters;
            _columns = _columns.map((el) => {
                return { field_id: field_ids_map[el.name], order: 'asc', field_label: el.name, field_name: fields_name_map[el.name] };
            });
            _rows = _rows.map((el) => {
                return { field_id: field_ids_map[el.name], order: 'asc', field_label: el.name, field_name: fields_name_map[el.name] };
            });
            _filters = _filters.map((el) => {
                return field_ids_map[el.name];
            });
            _values = _values.map((el) => {
                return { field_id: field_ids_map[el.name], type: el.type, field_label: el.name, field_name: fields_name_map[el.name], currency: fields_currency_map[el.name] };
            });
            let tempFields = fields;
            tempFields.rows = _rows;
            tempFields.columns = _columns;
            tempFields.filters = _filters;
            tempFields.values = _values;
            setFields({ ...tempFields });
          }
    };

    const ondataBound = () => {

    }

    const onLoad = () => {

    }

    const onFieldDropped = (data) => {
        if (data.droppedAxis !== '') {
            fields[data.droppedAxis].push(data.droppedField);
            setFields({ ...fields });
        }
    }

    const handleGrandTotalCheckBox = (event, isChecked) => {
        let tempFields = fields;
        tempFields.showGrandTotal = isChecked;
        setFields({ ...tempFields });
    }

    const handleSubTotalCheckBox = (event, isChecked) => {
        let tempFields = fields;
        tempFields.showSubTotal = isChecked;
        setFields({ ...tempFields });
    }

    const chartTypeMenuChange = (event, value) => {
        let tempFields = { ...fields };
        if (value !== null) {
            tempFields.chart_type = value;
            tempFields.chart_type_Obj = value;
        }
        setFields({ ...tempFields });

    }
    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight - 10) / 2;
    if (!mounted) {
        return (<div style={{ width: '100%', height: pageHeight }}>
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
        </div>);
    }
    let sfFormFieldContainerStyle = { ...styles.sfFormFieldContainer };

    return (
        <div>
            <div className="control-pane" style={{ paddingLeft: '11px' }}>
                <style>
                    {reportType === USER_REPORT_TYPE.PIVOT ? CSS_FOR_PVOT : CSS_FOR_SUMMARY}
                </style>
                     <div className="control-section" >
                         <PivotFieldListComponent
                             id='PivotFieldList'
                             enginePopulated={afterPopulate.bind(this)}
                             dataSourceSettings={dataSourceSettings}
                             renderMode={"Fixed"}
                             load={onLoad.bind(this)}
                             dataBound={ondataBound.bind(this)}
                             onFieldDropped={onFieldDropped.bind(this)}
                             showFieldList={true}
                         >
                         </PivotFieldListComponent>
                     </div>
            </div>

            <div
                style={{ ...sfFormFieldContainerStyle, float: 'left', 'width': '100%', cursor: "pointer", fontSize: '14px', color: '#717171', height: '47px', marginTop: 20, marginLeft: '10px' }}
                id='due_by'
                key='due_by'
            >
                <Autocomplete
                    id={'chartType'}
                    key={'chartType'}
                    name={'chartType'}
                    className={'sf-atc-autoComplete'}
                    style={{ width: '170px' }}
                     // value={fields.chart_type_Obj}
                    getOptionDisabled={(option) => option.disabled === true}
                    options={chart_types}
                    onChange={(event, value) => chartTypeMenuChange(event, value)}
                    getOptionLabel={(option) => option.label}
                    disableClearable={false}
                    label='Show Graphically'
                    size="small"
                    renderInput={(params) => <TextField {...params} label={'Show Graphically'}
                        variant="outlined" margin="dense" />
                    }
                />
            </div>
            <div style={{ marginLeft: '10px' }}>
                <FormControlLabel
                    control={<Checkbox
                        color="default"
                        defaultChecked={true}
                        value={fields.showGrandTotal}
                        onChange={(event, isChecked) => handleGrandTotalCheckBox(event, isChecked)}
                    />}
                    label={<div style={{ color: '#717171', maxWidth: '160px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{'Show Grand Total'}</div>}
                    labelposition="right"
                    labelstyle={{ color: '#717171', padding: '0px', fontWeight: 'bold' }}
                    style={{ height: 'auto' }}
                    title={'Show Grand Total'} />
            </div>
            <div style={{ marginLeft: '10px' }}>
                <FormControlLabel
                    control={<Checkbox
                        color="default"
                        value={fields.showSubTotal}
                        defaultChecked={true}
                        onChange={(event, isChecked) => handleSubTotalCheckBox(event, isChecked)}
                    />}
                    label={<div style={{ color: '#717171', maxWidth: '160px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{'Show Sub Total'}</div>}
                    labelposition="right"
                    labelstyle={{ color: '#717171', padding: '0px', fontWeight: 'bold' }}
                    style={{ height: 'auto' }}
                    title={'Show Sub Total'} />

            </div>
        </div>

    );
})

export default UserReportPivot;
