import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styles } from '../../../services/constants/styles';
import Button from '@mui/material/Button';
import { constants } from '../../../services/constants/constants';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { removeLeadAndContactsFromGroup, deleteAction } from '../../../services/actions/batchActions';
import { OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';
import * as sfDialogs from '../components/sfDialogs';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {updateTabInfo } from '../../../services/helper/sfTabManager';
import { getActiveTabInfo} from '../../../services/helper/sfTabManager';


const DeleteRecordDialog =({data,call_from})=> {
 
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState("1");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const detailViewDataRecord = useSelector((state) => state.detailView.data !== null ? state.detailView.data.record : null);
    useEffect(() => {
        if (data.isGroup) {

        } else {
            setSelectedOption("2");
        }
    },[]);

    const getSelectedOption = ( value) => {
        setSelectedOption(value);
    }

    const deleteRecord = () => {
        let isDetailView = false;
        if (call_from === constants.DETAIL_VIEW) {
            isDetailView = true;
        }
        if (selectedOption === "1") {
            let params = {};
            params.tableId = OBJECT_TABLEID_MAP[data.object];
            params.parent_record_id = data.parent_record_id;
            params.recordIds = data.id;
            setIsButtonDisabled(true);
            let promise = removeLeadAndContactsFromGroup(params);
            promise.then((response) => {
                if (isValidParam(response) && response.data.unLinked) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.REMOVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    dispatch(getAppDrawer(false, null, null, null, null));
                    if (isDetailView) {
                        dispatch(refreshDetailViewListViewData(true, data.parent_record_id));
                    }
                }
            });
        } else if (selectedOption === "2" || selectedOption === "3") {

            let snackMsg = getLocalizedStrings().message.COMMON.DELETE
            let msg = getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;
            let params = {
                "id": data.id
            }
            if (selectedOption === "2") {
                msg = getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;
                if (data.id.length === 1) {
                    msg = data.id.length + ' ' + getLocalizedStrings().message.BATCH_ACTION.RECORD + msg;
                } else if (data.id.length > 1) {
                    msg = data.id.length + ' ' + getLocalizedStrings().message.BATCH_ACTION.RECORDS + msg;
                }
            } else if (selectedOption === "3") {
                msg = getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_UNLINK_MSG;
                if (data.id.length === 1) {
                    msg = data.id.length + ' ' + getLocalizedStrings().message.BATCH_ACTION.RECORD + msg;
                } else if (data.id.length > 1) {
                    msg = data.id.length + ' ' + getLocalizedStrings().message.BATCH_ACTION.RECORDS + msg;
                }
            }

            params["parent_record_id"] = data.parent_record_id;
            params["parent_object"] = data.parent_object;
            if (selectedOption === "3") {
                params['isUnlink'] = true
                snackMsg = getLocalizedStrings().message.COMMON.UNLINK
            }


            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, (isConfirmed) => {
                if(data.parent_object === constants.OPPORTUNITIES_OBJECT && data.object === constants.PRODUCTS_OBJECT){
                    params.selectedProductNames = data.selectedProductNames;
                }
                setIsButtonDisabled(true);
                var promise = deleteAction(data.object, params);
                promise.then((response) => {
                    if (response.status === 0) {
                        dispatch(showCustomSnackBar(snackMsg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        dispatch(getAppDrawer(false, null, null, null, null));
                        if (isDetailView) {
                            dispatch(refreshDetailViewListViewData(true, data.parent_record_id));
                        }
                        let _info = getActiveTabInfo();
                        if (_info.selectedPageNo > 1) {
                            _info.selectedPageNo = 1;
                            updateTabInfo(_info);
                        }
                    }
                });
            }, () => console.log(`deny`))
        }
    }

    const closeDrawer = () => {
        dispatch(getAppDrawer(false, null, null, null, null));
    }
        let dataProps = data;
        let parentLabel = '', parentObj = '';
        if (dataProps !== null) {
            if (call_from === constants.DETAIL_VIEW) {
                parentLabel = detailViewDataRecord !== null ? detailViewDataRecord.name : '';
            } else if (call_from === constants.LIST_VIEW) {
                parentLabel = dataProps.parent_record_label;
            }

            parentObj = dataProps.parent_object === constants.GROUP_OBJECT ? 'group' + " " + parentLabel : dataProps.parent_object + " " + parentLabel;
        }

        return (
            <div>
                <div style={{ marginLeft: '10%', marginTop: '7%' }}>
                    <FormControl component="fieldset">
                          {
                            dataProps.isGroup ? <RadioGroup name="delete" onChange={(event) => getSelectedOption(event.target.value)}  defaultValue="1"  >
                                <FormControlLabel value="1" control={<Radio color="default" />} label={getLocalizedStrings().label.GROUP.REMOVE_FROM + " " + parentObj} />
                                <FormControlLabel value="2" control={<Radio color="default" />} label={getLocalizedStrings().label.COMMON.DELETE} />
                            </RadioGroup> : <RadioGroup name="delete" onChange={(event) => getSelectedOption(event.target.value)} defaultValue="2"  >
                                <FormControlLabel value="3" control={<Radio color="default" />} label={getLocalizedStrings().label.COMMON.UNLINK} />
                                <FormControlLabel value="2" control={<Radio color="default" />} label={getLocalizedStrings().label.COMMON.DELETE} />
                            </RadioGroup>
                        }
                    </FormControl>

                </div>
                <div style={{ textAlign: 'right', marginTop: '3%' }}>

                    <div style={{ float: 'right' }}>
                        <Button
                            primary={true}
                            style={styles.secondaryButton}
                            onClick={()=>closeDrawer()}
                        >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <Button
                            primary={true}
                            onClick={()=>deleteRecord()}
                            style={styles.primaryButton}
                            disabled={isButtonDisabled}

                        >{getLocalizedStrings().label.COMMON.OK}</Button>
                    </div>

                </div>
            </div>);
    }

export default DeleteRecordDialog ;