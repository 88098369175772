import * as HTTPClient from '..//helper/httpClient';
import { actions } from '..//constants/actions';
import { endPoints } from '..//constants/endPoints';

function getPendingAppTask(params){
    return {
        type: actions.TASK_APP_GET,
        payload: HTTPClient.get(endPoints.COMMON.GET_PENDING_TASK_APP, params)
    }
}

function updateAppTasks(data) {
    return {
        type: actions.TASK_APP_UPDATE,
        payload: data,
    }
}
function getAppTaskRefresh(refresh) {
    return {
        type: actions.APP_TASK_REFRESH_REFRESH,
        payload: refresh,
    }
}

export {
    getPendingAppTask,
    updateAppTasks,
    getAppTaskRefresh,
}