import { actions } from '..//constants/actions';

const pipelineReportInitialState = {
    pipelineReportData: {},
    pipelineReportWorkflows: {},
    pipelineReportSalesRep: [],
    reportParams:{}
}
const pipelineReportReducer = (state = pipelineReportInitialState, action) => {
    switch (action.type) {
        case actions.PIPELINE_REPORT_DATA_UPDATE:
            return {
                ...state,
                pipelineReportData: action.payload.pipelineReportData,
                reportParams: action.payload.reportParams
            }
        case actions.PIPELINE_REPORT_SALESREP_UPDATE:
            return {
                ...state,
                pipelineReportSalesRep: action.payload
        }
        case actions.PIPELINE_REPORT_WORKFLOW_LIST_UPDATE:
            return {
                ...state,
                pipelineReportWorkflows: action.payload
        }
        default:
            return state;
    }
}
export default pipelineReportReducer;