import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { styles } from '../../../services/constants/styles';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { isValidParam, getStringParam } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { refreshListView, unsubscribeContacts } from '../../../services/actions/listViewAcions';
import * as sfDialogs from '../components/sfDialogs';
import { useDispatch } from "react-redux";
import { isNullishCoalesce } from 'typescript';
import { refreshDetailViewData, refreshDetailViewListViewData, isDetailViewSet ,detailViewIsMounted} from '../../../services/actions/detailViewActions';


const Unsubscribe = ({ object, data,call_from }) => {

    const dispatch = useDispatch();

    const [selected, setSelected] = useState('Emails');
    const [isSavedDisabled, setIsSavedDisabled] = useState(false);

    const closeDrawer = () => {
        dispatch(getAppDrawer(false, null, null, null, null));
    }
    const unsubscribe = () => {
        let isDetailView = data.isDetailView;
        let selectedIds = data.recordIds;
        let msg = getLocalizedStrings().message.UNSUBSCRIBE[getStringParam(selected).toUpperCase() + '_UNSUBSCRIBE'];
        try {
            if (isDetailView) {
                if (getStringParam(selected).toUpperCase() === 'BOTHS') {
                    msg = getLocalizedStrings().message.UNSUBSCRIBE.SELECTED_BOTH_RECORD;
                } else {
                    msg = getLocalizedStrings().message.UNSUBSCRIBE.SELECTED_RECORD + ' ' + getLocalizedStrings().label.UNSUBSCRIBE[getStringParam(selected).toUpperCase()] + '' + getLocalizedStrings().message.UNSUBSCRIBE.ARE_YOU_SURE;
                }
            } else {
                if (selectedIds.length === 1) {
                    msg = selectedIds.length + ' ' + getLocalizedStrings().message.BATCH_ACTION.RECORD + msg;
                } else if (selectedIds.length > 1) {
                    msg = selectedIds.length + ' ' + getLocalizedStrings().message.BATCH_ACTION.RECORDS + msg;
                }
            }

            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => doUnsubscribe(selectedIds, selected), null);


        } catch (error) {
            console.error("Error in 'Unsubscribe.js -> unsubscribe()':" + error);
        }
    }

    const doUnsubscribe = (selectedIds, selected) => {
        let objectName = object;
        let isDetailView = data.isDetailView;
        setIsSavedDisabled(true);
        let params = {};
        params.ids = selectedIds;
        params.type = selected;
        let promise = Promise.resolve(unsubscribeContacts(params));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    if (response.status === 0) {
                        params = {}
                        if (isDetailView) {
                            params.isDetailView = true;
                        }
                        setIsSavedDisabled(false);
                        refreshListView(objectName, params);
                        if(call_from === "Listview" && data.isDetailView === true ){
                            dispatch(detailViewIsMounted(false));
							dispatch(isDetailViewSet(false));
							dispatch(refreshDetailViewData(true));
                        }
                        closeDrawer();
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION.UNSUBSCRIBE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    }
                }
            });
        }
    }

    const changeRadioButton = (value) => {
        setSelected(value);
    }

    return (
        <div style={{ paddingLeft: '30px', paddingRight: '30px', marginTop: '10px' }}>
            <div style={{ width: '100%' }}>
                <FormControl component="fieldset">
                    <RadioGroup name="selectAction" onChange={(e) => changeRadioButton(e.target.value)} defaultValue={selected} style={{ marginTop: '10px', fontSize: '15px' }} >
                        <FormControlLabel value="Emails" control={<Radio id="current" color="default" />} label={getLocalizedStrings().label.UNSUBSCRIBE.EMAILS} />
                        <FormControlLabel value="Texts" control={<Radio id="legacy" color="default" />} label={getLocalizedStrings().label.UNSUBSCRIBE.TEXTS} />
                        <FormControlLabel value="Boths" control={<Radio id="legacy" color="default" />} label={getLocalizedStrings().label.UNSUBSCRIBE.BOTHS} />

                    </RadioGroup>
                </FormControl>

            </div>
            <Button
                key='save'
                onClick={() => unsubscribe()}

                style={{ ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top', float: 'Right' }}
                disabled={isSavedDisabled}
            >{getLocalizedStrings().label.COMMON.UNSUBSCRIBE}</Button>
        </div>
    );
};

export default Unsubscribe;