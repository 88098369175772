import React from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { isValidParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import ShowCircularProgress from '../components/circularProgress';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import Select from 'react-select';

class WorkflowDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mounted: false,
            workflowList: [],
            currentWorkflowId: null,
            workflowName: '',
            stageName: '',
            workflow_err: '',
            nextStage: this.props.appContainer.dialogProps.data.params.next_stage
        }
    }
    componentDidMount() {
        this.getWFList();
    }

    getActions = () => {
        let actions = [];
        if (this.state.workflowList.length > 0) {
            actions = [
                <Button
                   
                    primary={true}
                    onClick={() => {
                        this.onMoveSubmit();
                    }}
                    style={styles.primaryButton}
                > Convert </Button>
            ];
        } else {
            actions = [
                <Button
                   
                    primary={true}
                    onClick={() => {
                        this.props.getAppDialog(false, constants.WORKFLOW_DIALOG, null, null, null, null);
                    }}
                    style={styles.primaryButton}
                > OK </Button>
            ];
        }

        return actions;
    }

    getWFList = () => {
        try {
            let data = this.props.appContainer.dialogProps.data;
            if (isValidParam(data)) {
                let wfList = getArrayParam(data.wfList);
                wfList.forEach((item, i) => {
                    let obj = {};
                    obj.id = item.id;
                    obj.value = item.id;
                    obj.label = item.name;
                    obj.title = item.name;
                    this.state.workflowList.push(obj);
                    if (data.params.workflow_id !== null && data.params.workflow_id === item.id) {
                        this.setState({ currentWorkflowId: item.id });
                    }
                });
                this.props.setAppDialogActions(this.getActions());
                this.setState({ mounted: true });
            }

            // let url = endPoints.ACCOUNTS.WORKFLOW_LIST_BY_STAGE;
            // url = url.replace('{object}', data.params.object);
            // let params = {
            //     object: data.params.object,
            //     stage_name: data.params.next_stage
            // }
            // var response = Promise.resolve(HTTPClient.get(url, params));
            // response.then((response) => {
            //     if (isValidParam(getArrayParam(response)) && getArrayParam(response).length > 0) {
            //         response.map((item, i) => {
            //             let obj = {};
            //             obj.id = item.id;
            //             obj.value = item.id;
            //             obj.label = item.name;
            //             obj.title = item.name;
            //             this.state.workflowList.push(obj);
            //             if (data.params.workflow_id !== null && data.params.workflow_id === item.id) {
            //                 this.state.currentWorkflowId = item.id;
            //             }
            //         })
            //     }
            //     this.props.setAppDialogActions(this.getActions());
            //     this.setState({ mounted: true });
            // });

        } catch (error) {
            console.error("Error in 'workflowDialog.js -> getWFList()':" + error);
        }
    }

    handleClose = () => {
        this.props.getAppDialog(false, null, null, null, null, null);
    }

    onMoveSubmit = () => {
        try {
            if (this.state.currentWorkflowId === null) {
                this.setState({ workflow_err: 'Please select a workflow from list.' });
                return false;
            }

            let workflowId = this.state.currentWorkflowId;
            let data = this.props.appContainer.dialogProps.data;
            let prevWorkflowId = data.params.workflow_id;
            data.params.workflow_id = workflowId;
            data.params.prevWorkflowId = prevWorkflowId;
            this.props.appContainer.dialogProps.eventName(data);
        } catch (error) {
            console.error("Error in 'workflowDialog.js -> onMoveSubmit()':" + error);
        }
    }

    handleWorkflowChange = (workFlowId) => {
        try {
            this.setState({ currentWorkflowId: workFlowId, workflow_err: '' });
        } catch (error) {
            console.error("Error in 'workflowDialog.js -> handleWorkflowChange()':" + error);
        }
    }

    render() {
        let pageHeight = window.innerHeight - 247;
        let top = (pageHeight-10) / 2;
        if (this.state.mounted === true) {
            let workflowMessage = 'The ' + this.state.nextStage + ' status appears in more than one workflow. Please choose a workflow for the record (required):'
            return (
                this.state.workflowList.length > 0 ? <div>
                    <div style={{ fontSize: 14, paddingBottom: 5, paddingLeft: 1 }}>{workflowMessage}</div>
                    <Select simpleValue value={this.state.currentWorkflowId} placeholder={getLocalizedStrings().label.COMMON.WORKFLOW_NAME} options={this.state.workflowList} clearable={false} onChange={this.handleWorkflowChange} />
                    {this.state.workflow_err !== '' &&
                        <span style={{ color: '#F4483B', fontSize: 12 }}>{this.state.workflow_err}</span>
                    }
                </div>
                    : <div>You can't move!! No workflow found.</div>
            );
        } else {
            return (<div style={{ width: '100%', height: pageHeight }}>
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
       </div>);
        }
    }
}
const mapStateToProps = (state) => {
    return {
        appContainer: state.appContainer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAppDialogActions: (actions) => {
            dispatch(setAppDialogActions(actions));
        },
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
        getAppDialog: (isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom) => {
            dispatch(getAppDialog(isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom));
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDialog);