 const message = {
    COMMON: {
        NO_ACTIONS_AVAILABLE: 'No hay ningún objeto disponible para la acción de adición rápida',
        NOTES_REMOVE_MESSAGE:'Las notas seleccionadas se eliminarán. ¿Está seguro?',
        SELECTED_NOTE_MESSAGE:'Se debe seleccionar al menos una nota para realizar esta acción.',
        BR_WINDOW_OPEN_CONFORMATION: 'No admitimos la función de arrastrar y soltar para campañas. ¿Te gustaría abrir la campaña?',
        CONFIRM_DIALOG_TITLE: 'Diálogo de confirmación',
        ALERT_DIALOG_TITLE: 'Diálogo de alerta',
        XMS_SUCCESS_ALERT_DIALOG_TITLE: 'Éxito',
        XMS_ERROR_ALERT_DIALOG_TITLE: 'Error',
        SAVE: 'Guardado exitosamente.',
        MERGE: 'Fusionado exitosamente.',
        UPDATE: 'Actualizado con éxito',
        DELETE: 'Borrado exitosamente.',
        REMOVE: 'Eliminado con éxito',
        UNLINK: 'Desvinculado exitosamente',
        COPY_EMAIL:'ID de correo electrónico copiado al portapapeles',
        COPY_MOBILE:'El número de móvil ha sido copiado a tu portapapeles',
        COPY_PHONE:'El número de teléfono ha sido copiado a su portapapeles',
        EMAIL_VALIDATION:'Por favor introduzca una dirección de correo electrónico válida.',
        EMPTY_EMAIL:'No hay ningún correo electrónico para copiar',
        EMPTY_MOBILE:'No hay ningún móvil presente para copiar',
        EMPTY_PHONE:'No hay ningún teléfono presente para copiar',
        ERROR: 'Sistema no responde',
        ACCESS_DENIED: 'Lo sentimos, no tienes el permiso para realizar esta operación. Por favor, póngase en contacto con su administrador',
        NOTIFICATION_INFO_EMPTY: 'No hay notificaciones disponibles',
        /** START:  Validation message */
        BLANK: 'En blanco',
        EMAIL: 'Es una dirección de correo electrónico no válida.',
        VALID_EMAIL: 'La dirección de correo electrónico que ingresó no parece ser una dirección válida. Inténtalo de nuevo. Sugerencia: asegúrese de que la dirección de correo electrónico tenga el formato de correo electrónico \\ @ dominio.com.',
        URL: 'Es una url no válida',
        VALID_URL: 'Por favor introduzca un URL válido.',
        INTEGER: 'No es un número entero',
        VALID_INTEGER: 'Por favor ingrese un entero válido.',
        FLOAT: 'No es flotador',
        VALID_FLOAT: 'Por favor ingrese un flotador válido.',
        VALID_PHONE: 'Los números de teléfono deben ser numéricos',
        VALID_LIST: 'Por favor seleccione un valor.',
        TOO_BIG_SIZE: 'El valor que ha ingresado es demasiado grande para este campo. Ingrese un valor más bajo.',
        NO_RECORD: 'Ningún record fue encontrado.',
        VALIDATE_BLANK: 'No puede estar en blanco.',
        VALIDATE_INTEGER: 'Tiene que ser un número entero.',
        VALIDATE_DECIMAL: 'Tiene que estar en decimal',
        VALIDATE_POSITIVE_DECIMAL: 'tiene que ser mayor que 0 y en decimal.',
        VALIDATE_URL: 'Formato de URL no válido. Ejemplo - www.soffront.com',
        VALIDATE_EMAIL: 'Formato de correo inválido.',
        VALUE: 'Valor',
        LIBRARY_DIALOG_TITLE: 'Biblioteca en línea de Soffront',
        BLANK_EMAIL: 'El correo electrónico está en blanco',
        INVALID_EMAIL: 'Por favor, proporciona un correo electrónico válido',
        BLANK_NUMBER : 'El número está en blanco',
        INVALID_NUMBER :'Proporcione un número válido',
        EMPTY_SEARCH_CRITERIA: 'Por favor ingrese los criterios de búsqueda',
        VALIDATE_MORE_CHECKBOX_SELECTION: 'No puede realizar búsquedas con <*> cuando se selecciona más de una casilla de verificación',
        VALIDATE_NO_CHECKBOX_SELECTION: 'No puede realizar búsquedas con <*> cuando se selecciona más de una casilla de verificación',
        NO_DATA: 'Datos no encontrados',
        DISCONNECT_ALERT_MSG: '¿Estás seguro de desconectar este cliente?',
        DISCONNECT_SUCCESS: 'Has desconectado exitosamente a tu cliente.',
        CONFIRM_DELETE_RECORD: 'El registro será eliminado. ¿Estás seguro?',
        DELETE_SUCCESS: ' Ha sido eliminado con éxito',
        COULDNOT_LOCATE: "No pudimos localizar",
        DELETED_OR_ACCESS_DENIED: " Puede haber sido eliminado o puede que no tenga acceso o ya lo haya abierto.",
        CLICK: 'Hacer clic',
        HERE: 'Aquí',
        MERGE_ALERT_1: '¿Seguro que te fusionarás?',
        WITH: 'Con',
        MERGE_ALERT_2: 'Y eliminar',
        DATA_ACCESS_REQUEST_SEND_SUCCESS: 'Solicitud de acceso a datos enviada a',
        DATA_ACCESS_REQUEST_SEND_FAILURE: 'La solicitud de acceso a datos no puede enviarse a "',
        REQUEST_DATA_ACCESS: 'Solicitar acceso a datos desde',
        WE_WILL_EMAIL: 'Le enviaremos un correo electrónico',
        AT: 'A',
        TO_APPROVE_REQUEST: 'Para aprobar su solicitud',
        HAVE_BEEN: 'Ha estado',
        REMINDER_VALUE: 'Acordarse que el valor no se puede dejar en blanco',
        DUPLICATE_EMAIL: 'Usted ya tiene el registro(s) con el mismo correo electrónico id, haga click "OK" para continuar ó haga click "cancel" para cambiar el correo electrónico id.',
        DUPLICATE_CONTACT_EMAIL: 'Ya tiene registro (s) con la misma ID de correo electrónico. Por favor ingrese una identificación de correo electrónico diferente.',
        CLIENT_CONNECT_MESSAGE: 'Este cliente no tiene una cuenta con este correo electrónico en Soffront Online. Primero, solicite al cliente que cree una cuenta con el mismo correo electrónico.',
        BLANK_CAMPAIGN_TYPE: 'El tipo de campaña no puede estar en blanco.',
        DUPLICATE_CAMPAIGN_TYPE: 'El tipo de campaña ya existe.',
        CAMPAIGN_TYPE_SAVE_SUCCESS: 'Tipo de campaña guardado correctamente.',
        /** END:  Validation message */
        STEPPER_BACK_MESSAGE: '¿Te gusta ir a la página de información de plantillas? Cualquier cambio no guardado se perdería.',
        CENCEL_MOSAICO_MESSAGE: '¿Está seguro de que desea cancelar sin guardar?',
        NO_RESULTS_FOUND: 'No se han encontrado resultados',
        FOR: 'para',
        ENABLE_MAILING_SUCCESS: 'El envío de correo electrónico habilitado con éxito',
        DISABLE_MAILING_SUCCESS: 'Desactivado con éxito el envío de correo electrónico',
        MAILING_FAILURE: 'Esta cuenta no es un inquilino de CRM',
        TEST: 'Prueba',
        SELECT_RECORD_FOR_DELETE: 'Please select a record to delete.',
        SELECT_RECORD_FOR_EDIT_ONE_RECORD: 'Por favor, seleccione un registro para editar.',
        SELECT_RECORD_FOR_DELETE_ONE_RECORD: 'Por favor, seleccione un registro para eliminar.',
        ASSETMENU_DIALOG_TITLE: 'Seleccione un activo',
        UNIT_SELECT_ALERT_MESSAGE: 'Puede seleccionar una unidad a la vez.',
        LOOKUP_MULTI_SELECT: 'Por favor seleccione solo un registro.',
        LOOKUP_SELECT_A_RECORD: 'Por favor seleccione un registro.',
        BOTH_COMPANY_AND_WORKFLOW_SELECTED: 'Ha seleccionado tanto la empresa como el flujo de trabajo.',
        VALIDATE_ZERO: 'no puede ser cero.',
        GREENLIGHT_EXPORT_NO_RECORD: 'No hay cuentas en el flujo de trabajo de Greenlight para este rango de fechas.',
        WITHOUT_SAVE_MESSAGE: '¿Quieres continuar sin guardar los cambios?',
        DELETE_API_KEY: 'Esto eliminará la clave de API en CRM. ¿Le gustaría continuar?',
        UNIT_ALERT: 'Seleccione un registro para iniciar sesión.',
        THIS_FIELD_CANNOT_BLANK: 'Este campo no puede estar vacío.',
        CANNOT_DELETE_ALL_ITEM: 'Debe tener al menos un elemento en menú móvil.',
        DUPLICATE_MSG_1: 'Ya tiene registro (s) con la misma ',
        DUPLICATE_MSG_2: '. Haga clic en OK para continuar o en Cancelar para cambiar el ',
        DUPLICATE_MSG_3: ' y ',
        DUPLICATE_CONTACT_EMAIL_1: 'Ya tiene registro (s) con la misma ',
        DUPLICATE_CONTACT_EMAIL_2: '. Por favor, introduzca diferente ',
        INVALID_TIME_ERROR_MSG: 'Por favor, proporcione un intervalo de tiempo válido.',
        DOESNT_HAVE_ASSOCIATED_1:'Esto ',
        DOESNT_HAVE_ASSOCIATED_2: " no tiene un contacto asociado.", 
        PHONE_CALL_NOT_ENABLED: 'La llamada telefónica no está habilitada.',
        NOT_CONFG_CALL_SETUP : 'No ha configurado la configuración de la llamada.',
        NOT_CONFG_CALL_SMS_SETUP:'No ha configurado la configuración de llamada / sms.',
        SEARCH_NO_CHECKBOX_SELECTION: '¡No puede realizar una búsqueda si no se selecciona ninguna casilla de verificación!',
        VALID_SOURCE_LIST: 'Seleccione el campo de origen.',
        LMS_USER_NOT_AVAILABLE: 'LMS aún no está activado. Por favor contacte a su administrador.',   
        ADD_BRANCH_BILLINGMASSAGE:'Actualmente no tienes licencias. Usted comprará una nueva licencia para esta unidad a $40.00/mes. Además, se cobrará una tarifa de instalación de $100,00 para configurar y capacitar al nuevo inquilino.'        
    },
    SALES_TEMPLATE: {
        NAME_BLANK: 'Por favor ingrese un nombre para identificar esta plantilla.',
        // SUBJECT_BLANK: 'Por favor ingrese un nombre para identificar esta plantilla.',
        SUBJECT_BLANK: 'Por favor ingrese un tema para esta plantilla',
        DUPLICATE_NAME: 'Ya tienes una plantilla llamada %%. No admitimos plantillas duplicadas. Por favor escriba un nombre diferente y vuelva a intentarlo.',
        DELETE: 'La plantilla será eliminada. ¿Estás seguro?',
        TEMPLATE_DELETED: 'Plantilla borrada con éxito',
        TEMPLATE_NAME_ERROR: 'Ya tienes una plantilla con este nombre. Por favor ingrese un nombre diferente e intente nuevamente.',
    },
    AGREEMENT_TEMPLATE: {
        NAME_BLANK: 'Introduzca un nombre para identificar esta plantilla.',
        // SUBJECT_BLANK: 'Please enter a subject for this template.',
        DUPLICATE_NAME: 'Ya tiene una plantilla denominada %% . No admitimos plantillas duplicadas. Escriba un nombre diferente e inténtelo de nuevo.',
        DELETE: 'La plantilla será eliminada. ¿Estás seguro?',
        TEMPLATE_DELETED: 'Plantilla borrada con éxito',
        TEMPLATE_NAME_ERROR: 'Ya tienes una plantilla con este nombre. Por favor ingrese un nombre diferente e intente nuevamente.',
        DUPLICATE_TEMPLAE_EXIST: 'Este nome de modelo já existe.',
        RECORD_NOT_EXIST: 'Este recorde não existe.',
        SAVE_TO_DOWNLOAD: 'Ainda não guardou o modelo do acordo. Por favor, guarde o modelo do Contrato primeiro para o descarregar.',
        TEMPLATE_CAN_NOT_EMPTY: 'O nome do modelo do acordo não pode ser vazio.',
        SELECT_TEMPLATE: 'Por favor, selecione um modelo.',
        SENT_OR_SIGNED: 'Ya has enviado este acuerdo una vez. No puedes volver a enviarlo.',
        SIGNATURE_TAGS_NOT_SEQUENCED_1: 'Agregue las etiquetas de firma en orden secuencial.',
        SIGNATURE_TAGS_NOT_SEQUENCED_2: 'Parece que se saltó el orden secuencial al agregar la etiqueta de firma.',
        SIGNATURE_TAGS_MISSING: 'No hay ninguna etiqueta de firma disponible en el cuerpo del Acuerdo. El cuerpo del acuerdo debe tener al menos una etiqueta de firma.',
        SIGNATURE_TAGS_CONTACT_FIELDS_COMBO_MISSING: 'Parece que ha utilizado campos de combinación para una firma sin utilizar la etiqueta de firma. Agregue la etiqueta de firma para continuar.',
        AGREEMENT_EDIT: 'Puede realizar edición solo en registros de acuerdos, no en otros registros adjuntos o registros de acuerdos ya enviados.',
        AGREEMENT_EXPIRED_1: 'Este acuerdo ha expirado el ',
        AGREEMENT_EXPIRED_2: 'Por favor extienda la fecha de vencimiento del acuerdo antes de enviarlo.',
    },
    SMS_TEMPLATE: {
        BLANK_MESSAGE: 'Introduzca un mensaje para guardar esta plantilla.',
        SAVE_MESSAGE: 'SMS guardado con éxito.',
    },
    MARKETING_TEMPLATE: {
        NAME_BLANK: 'Por favor ingrese un nombre para identificar esta plantilla.',
        SUBJECT_BLANK: 'Por favor ingrese un tema para esta plantilla.',
        DUPLICATE_NAME: 'Ya tienes una plantilla llamada %%. No admitimos plantillas duplicadas. Por favor escriba un nombre diferente y vuelva a intentarlo',
        DELETE: 'La plantilla será eliminada. ¿Estás seguro?',
        BACK: 'Si retrocedes perderás tus cambios no guardados. ¿Desea continuar?',
        SELECT_DESIGN: 'Seleccionar de un diseño',
        TEMPLATE_UPDATE: 'Plantilla(s) de marketing actualizados',
        CONFIRM1: 'De',
        CONFIRM2: 'Registro(s) son actualizados a',
        CONFIRM3: 'Estás seguro(a)?',
        ALERT1: 'Fuera de',
        FROM_NAME_BLANK: 'Por favor, introduzca un nombre de tipo.',
        FROM_EMAIL_BLANK: 'Por favor, elija un de correo electrónico.',
        DUPLICATE_TEMPLATE_NAME: 'El nombre de la plantilla ya existe.'
    },
    BROADCAST_CAMPAIGNS: {
        NAME_BLANK: 'Por favor ingrese un Nombre para identificar esta Campaña de Transmisión.',
        GROUP_FILTER_BLANK_COMMON_MSG: 'Por favor selecciona',
        FILTER_TYPE_GROUP: 'Un grupo',
        FILTER_TYPE_CONTACT: 'Un filtro de contacto',
        FILTER_TYPE_ACCOUNT: 'Un filtro de cuenta',
        SUBJECT_BLANK: 'Por favor ingrese un Asunto',
        FROM_NAME_BLANK: 'Por favor ingrese un nombre de From',
        FROM_EMAIL_BLANK: 'Seleccione un correo electrónico',
        GROUP_NAME_BLANK: 'El nombre del grupo no puede estar en blanco',
        SAVE: 'Ha creado con éxito esta campaña.',
        UPDATE: 'Has actualizado exitosamente esta campaña.',
        GROUP_NAME_SIZE_LIMIT: 'El nombre del grupo no puede tener más de 128 caracteres.',
        EXECUTION_CANNOT_CANCEL_1: 'La ejecución no puede cancelarse ahora.',
        EXECUTION_CANCEL_MSG: 'La aplicación intentará cancelar la ejecución de inmediato.',
        CONFIRM: 'Por favor confirmar.',
        EXECUTION_CANNOT_CANCEL_2: 'Una campaña que ya está en proceso no se puede cancelar.',
        PURCHASE_MORE: 'Para comprar más',
        CREDITS_REMAINING: 'Crédito por correo electrónico restante.',
        VERIFY_BLANK_EMAIL: 'Usted debe entrar en una dirección de correo electrónico para verificar.',
        VERIFY_INVALID_EMAIL: 'es una dirección de correo electrónico inválida.',
        SEND_VERIFICATION_EMAIL_SUCCESS: 'Nosotros enviamos un correo electrónico de verificación. Por favor chequiar su bandeja de entrada "inbox" y hagalé click para verificar esta dirección de correo electrónico. Después vuelva y pulse el icono refrescar para ver su nueva dirección de correo electrónico.',
        EMAIL_INTEGRATION_NEW: 'Esta dirección de correo electrónico ya ha sido sometida para la verificación! Por favor compruebe su bandeja de entrada"inbox" y hagalé click en verificar esta dirección de correo electrónico. Después vuelva y pulse el icono refrescar para ver su nueva dirección de correo electrónico.',
        EMAIL_INTEGRATION_VERIFIED: 'Esta dirección de correo electrónico ya ha sido verificada',
        BD_NAME_COPY_ERROR: 'Tienes una campaña existente con este nombre. Por favor ingrese un nombre diferente para esta campaña',
        STEPPER_BACK_MESSAGE: '¿Te gusta ir a la página de información de transmisión? Cualquier cambio no guardado se perdería.'
    },
    SPACE: ' ',
    LIST_VIEW: {
        SELECT_RECORD: 'Seleccione un registro para realizar más acciones.',
        SELECT_RECORD_FOR_CONVERT: 'Seleccione un registro para convertir.',
        SELECT_RECORD_FOR_EDIT: 'Seleccione un registro para editar.',
        SELECT_RECORD_FOR_COPY: 'Seleccione un registro para copiar.',
        CANNOT_COPY_APPOINTMENT_WORKFLOW: 'No puede copiar el flujo de trabajo de citas.',
        SELECT_RECORD_FOR_SEND_EMAIL: 'Seleccione un registro para Enviar correo electrónico.',
        MULTIPLE_RECORD_FOR_EDIT: 'Seleccione un registro para editar.',
        MULTIPLE_RECORD_FOR_COPY: 'Seleccione un registro para copiar.',
        MULTIPLE_RECORD_FOR_MORE: 'Seleccione un registro para MÁS.',
        SELECT_RECORD_FOR_COPY_ONE_RECORD: 'Seleccione un registro para copiar.',
        SELECT_MAXIMUM_FIELD_FOR_SETUP: 'Alcanzaste el límite máximo de un total de 15 campos seleccionados.',
        CONVERT_CONTACT_TO_ACCOUNT_CONFIRM: 'Este contacto ya está asociado a una cuenta. Haga clic en Aceptar para ver los detalles de la cuenta.',
        SHARE_PERMISSION: 'No tienes el permiso para compartir este registro.',
        NOT_ADDED: 'Usted no ha agregado',
        FIELDS_TO_VIEW: 'campos para ver.',
        SETUP_MINIMUM_FIELD_ALERT: 'Por favor agregue otro campo. Requerimos un mínimo de dos campos para la vista.',
        SELECT_ANOTHER_TABLE: 'Seleccione otra tabla y vuelva a intentarlo.',
        WEB_FORM_ALERT1: 'Fuera de',
        WEB_FORM_ALERT2: 'El webform(s) selcionados no son borrados ya que son usados en el diseñador de automatización.',
        SETUP_WORKFLOWSTAGE_MINIMUM_FIELD_ALERT: 'Agregue un campo para la columna. Necesitamos un mínimo de 1 campo para la vista.',
        SELECT_RECORD_FOR_SEND_SMS: 'Seleccione un registro para enviar mensaje de texto.',
        SELECT_RECORD_FOR_CALL: 'Seleccione un registro para realizar una llamada.',
        SELECT_MORE_RECORD_ACTION_ALERT: 'Seleccione un registro para realizar más acciones.',
        SELECT_RECORD_FOR_DOWNLOAD: 'Seleccione un registro en Descargar cuentas.',
        CHECKBOX_COLOR_GREEN_TITLE: 'Última actualización dentro de los 30 días',
        CHECKBOX_COLOR_YELLOW_TITLE: 'Última actualización en 30-60 días.',
        CHECKBOX_COLOR_RED_TITLE: 'Última actualización antes de 60 días.',
        STATUS_FIELD_NOT_ADDED_FOR_VIEW: 'No ha agregado ningún campo de estado para verlo.',
        NO_EMAIL_TO_SEND_MAIL: 'No hay ninguna dirección de correo electrónico disponible para enviar un correo electrónico.',
        REQUIRED_FIELD_CONVERT_FAILED: 'Error al convertir. Faltan uno o más campos obligatorios.',
        SELECT_RECORD_FOR_To_EXCEL: 'Por favor, seleccione un registro para sobresalir.',
        SELECT_RECORD_FOR_ADD_TASK: 'Por favor Ase seleccione un registro para agregar tarea',
        UNIT_TASK_MESSAGE: 'Puede agregar tareas solo a las unidades que están conectadas directamente a usted.',
        UNIT_SEND_EMAIL: 'Puede abrir y enviar correo electrónico solo a las unidades que están conectadas directamente con usted',
        UNIT_PHONE_NUMBER: 'Puede abrir una llamada telefónica solo a las unidades que están conectadas directamente a usted',
        FDD_AND_FA_AGREEMENT_CANNOT_BE_DELETED: 'No se le permite eliminar el registro adjunto de tipo FDD o FA.',
        MULTIPLE_SELECT_FDD_FA_MSG_1: 'Fuera de ',
        MULTIPLE_SELECT_FDD_FA_MSG_2: ' registros adjuntos seleccionados ',
        MULTIPLE_SELECT_FDD_FA_MSG_3: ' Los registros adjuntos no se eliminarán ya que son registros adjuntos de tipo FDD o FA. ¿Está seguro?',
    },

    AB_CAMPAIGNS: {
        NAME_BLANK: 'Ingrese un nombre para la campaña.',
        GROUP_FILTER_BLANK_COMMON_MSG: 'Por favor selecciona',
        FILTER_TYPE_GROUP: 'a Group',
        FILTER_TYPE_CONTACT: 'Un filtro de contacto',
        FILTER_TYPE_ACCOUNT: 'an Account Filter',
        GROUP_FILTER_BLANK: 'Seleccione un grupo',
        CONTACT_FILTER_BLANK: 'Por favor seleccione un filtro de contacto.',
        ACCOUNT_FILTER_BLANK: 'Seleccione un filtro de cuenta.',
        SUBJECT_BLANK: 'Ingrese un asunto para este mensaje.',
        FROM_NAME_BLANK: 'Ingrese el nombre del remitente.',
        FROM_EMAIL_BLANK: 'Ingrese el correo electrónico del remitente.',
        GROUP_NAME_BLANK: 'El nombre del grupo no puede estar en blanco',
        AB_DISTRIBUTION: 'Ingrese la distribución a/b.',
        INVALID_AB_DISTRIBUTION: 'Distribución a/b inválida.',
        INVALID_WINNING_MARGIN: 'Margin for Winner no es válido.',
        ZERO_WINNING_MARGIN: 'Entrar el margen para ganador.',
        MESSAGE_B_NOT_FILLED: 'Por favor ingrese los detalles del mensaje B',
        AB_DISTRIBUTION_OUT_OF_RANGE: 'Ingrese un valor entre 1 y 99 para la prueba ',
        DAYS_BLANK: 'Por favor Ingrese cuántos días le gustaría esperar antes de enviar el mensaje ganador a la lista restante.',
        INVALID_DAYS: 'Entrada invalida. Ingrese cuántos días le gustaría esperar antes de enviar el mensaje ganador a la lista restante.',
        SAVE: 'Usted a creado con éxito esta campaña.',
        UPDATE: 'Usted ha actualizado con éxito esta campaña.',
        EXECUTION_CANNOT_CANCEL_1: 'La ejecución no puede cancelarse ahora.',
        EXECUTION_CANCEL_MSG: 'La aplicación intentará cancelar la ejecución de inmediato.',
        CONFIRM: 'Por favor confirmar.',
        EXECUTION_CANNOT_CANCEL_2: 'Una campaña que ya está en proceso no se puede cancelar.',
        CAMPAIGN_EXECUTE_IN_60_MIN: 'Esta campaña se ejecutará en 60 minutos.',
        OK_TO_CONFIRM: 'Haga clic en Aceptar para confirmar',
        ERROR_MSG_1: 'Por favor seleccione la plantilla.',
    },
    REPEAT_DIALOG: {
        END_DATE_BLANK: 'La fecha de finalización no puede estar en blanco..',
        INVALID_END_DATE: 'La fecha de inicio no puede ser mayor o igual que la fecha de finalización.',
        DAY_CHECKBOX_BLANK: 'Al menos comprobar un día.',
        DELETE_RECUR_TITLE: 'Eliminar recurrente',
        EDIT_RECUR_TITLE: 'Editar recurrente',
        REPEAT: 'Repetir'
    },
    BATCH_ACTION: {
        CONVERT_SUCCESSFULL: ' Han sido convertidos exitosamente',
        SINGLE_CONVERT_SUCCESSFULL: ' Ha sido convertido con éxito',
        CONVERT_UNSUCCESSFULL: 'Acción incapaz de realizar',
        CONVERT_SAME_STAGE: ' El registro ya está en ',
        MULTI_SHARE_SUCCESSFULL: ' Han sido compartidos exitosamente',
        SINGLE_SHARE_SUCCESSFULL: ' Ha sido compartido exitosamente',
        SHARE_UNSUCCESSFULL: 'Acción incapaz de realizar',
        MULTI_ASSIGN_SUCCESSFULLY: ' Se han asignado con éxito.',
        SINGLE_ASSIGN_SUCCESSFULLY: ' se ha asignado con éxito.',
        ASSIGN_UNSUCCESSFULL: 'Acción incapaz de realizar',
        COMPLETE_SUCCESSFULL: ' Ha sido completado exitosamente',
        BATCH_COMPLETE_SUCCESSFULL: ' Se han completado con éxito.',
        COMPLETE_UNSUCCESSFULL: 'Acción incapaz de realizar',
        GROUP_ADDED_SUCCESSFULLY: 'Agregado con éxito al grupo (s)',
        GROUP_REMOVED_SUCCESSFULLY: 'Eliminado con éxito de todos los grupos.',
        GROUP_CLICK_TO_CONFIRM: ' Se agregará a los grupos seleccionados Haga clic en "Aceptar" para confirmar.',
        GROUP_BLANK_ADDED_BATCH: 'Por favor seleccione al menos un grupo.',
        NOTE_TXT_CANNOT_BE_BLANK: 'El texto no puede estar en blanco.',
        WORKFLOW_CONFIRMATION: 'El flujo de trabajo se cambiará para este registro. Haga clic en Ok para confirmar.',
        BATCH_WORKFLOW_CONFIRMATION: 'El flujo de trabajo se cambiará para estos registros. Haga clic en Ok para confirmar.',
        WORKFLOW_APPLIED: 'Flujo de trabajo aplicado con éxito.',
        RECORDS_WITH_DIFFERENT_WORKFLOW: 'Ha seleccionado registros con diferentes flujos de trabajo. Haga clic en Aceptar para continuar y realizar la operación de aplicar flujo de trabajo o haga clic en Cancelar para volver a seleccionar los registros.',
        RECORDS_WITH_DIFFERENT_WORKFLOW_STATUS: 'Ha seleccionado registros con diferente estado. La operación \nBatch Convert sólo se puede realizar en registros con el mismo estado.',
        ACTIVE_SUCCESSFULL: 'Su estado ha sido cambiado a Activo',
        INACTIVE_SUCCESSFULL: 'Su estado ha sido cambiado a Inactivo.',
        BATCH_EDIT_BLANK: 'El campo de valor no puede estar en blanco!',
        INVALID_WORKFLOW_STAGE: 'No puede convertir el registro. El estado del registro seleccionado no está disponible en el flujo de trabajo actual. Presiona Ok para cambiar el estado o flujo de trabajo',
        CONFIRMATION_DELETE_MSG: ' Será borrado. ¿Estás seguro?',
        CONFIRMATION_REMOVE_MSG: ' Será eliminado. ¿Estás seguro?',
        CONFIRMATION_UNLINK_MSG: ' Será desvinculado ¿Estás seguro?',
        CONVERT_ALERT_MSG: ' Se convertirá en ',
        CLICK_TO_CONFIRM: ' Haga clic en Ok para confirmar.',
        ASSIGN_ALERT_MSG: ' Registro (s) será asignado a ',
        ROUND_ROBIN_BASIS: ' En una base de turno rotativo. ¿Estás seguro?',
        CONFIRM_MSG: '¿Estás seguro?',
        RECORDS: 'Archivos',
        RECORD: 'Grabar',
        ALL_RECORDS: 'Todos los registros',
        RECORD_WILL_BE_UPDATED: 'Los registros se actualizarán a',
        CONFIRM_UNSUBSCRIBE_RECORD: ' will be unsubscribed. ¿Estás seguro?',
        UNSUBSCRIBE: 'Usted ha cancelado/anulado la subscripción correctamente  de los contactos seleccionados.',
        USE_ASSIGN_RULE_ALERT_MSG: ' registro (s) se asignarán a los representantes de ventas según ',
        EMPTY_RECYCLE_BIN: 'Todos los registros se eliminarán permanentemente de la Papelera de reciclaje. ¿Estás seguro?',
        PERMANENT_DELETE_MSG: ' será eliminado permanentemente. ¿Estás seguro?',
        INVALID_STATUS_FOR_WORKFLOW_STAGE: 'El estado del registro seleccionado no está disponible en el flujo de trabajo actual. Presione Ok para cambiar el estado del registro.',
        CONTACT_STATUS_UPDATE_SUCCESS: ' el estado se ha actualizado correctamente.',
        PUBLISH_SUCCESSFULL: 'El estado ha sido cambiado a Publicar.',
        UNPUBLISH_SUCCESSFULL: 'El estado ha sido cambiado a No publicado.',
        RECORDS_NOT_IN_WORKFLOW: 'Todos los registros seleccionados no están en el flujo de trabajo de contactos.',
        RECORD_NOT_IN_WORKFLOW: 'No se puede convertir, ya que el registro seleccionado no está en el flujo de trabajo de contactos.'
    },
    BATCH_ACTION_VALIDATION: {
        ADDTOGROUP_BLANK: 'El registro seleccionado se eliminará de todos los grupos. Haga clic en "Aceptar" para confirmar.',
        ADDTOGROUP_NAMEBLANK: 'El nombre del grupo no puede estar en blanco.',
        SHARE_WITH_BLANKUSER: 'Seleccione al menos un usuario.',
        ASSIGN_BLANKUSER: 'Por favor seleccione un usuario',
        CONVERT_SELECT_STATUS: 'Por favor seleccione un estado para ',
        MAP_INSUFFICIENT_INFO: 'Insuficiente información para mostrar el mapa',
        GROUP_ALREADY_EXIST: '¡El nombre del grupo ya existe!',
        ALREADY_ACTIVE: 'Su registro ya esta en estado activo.',
        ALREADY_INACTIVE: 'Su registro ya esta en estado inactivo.',
        ALREADY_PUBLISH: 'Grabar ya en estado de publicación.',
        ALREADY_UNPUBLISH: 'Grabar ya en estado no publicado.',
    },
    ATTACHMENT: {
        ATTACHMENT_UPLOAD: ' Archivo adjunto cargado con éxito',
        ATTACHMENT_LINK_ADDED: '¡El enlace del documento se ha agregado exitosamente!',
        ATTACHMENT_UPLOAD_IMAGE: 'La imagen cargo con éxito.',
        ATTACHMENTS_UPLOAD: 'Archivos adjuntos cargados con éxito',
        VALIDATE_ATTACHMENT: 'Seleccione un archivo para adjuntar  ',
        ATTACHMENT_UPLOAD_FAILURE: ' Este archivo ya existe',
        VALIDATE_EMPTY_FILE: ' El tamaño del archivo de carga no puede ser 0 ',
        VALIDATE_FILE_SIZE: 'El tamaño del archivo de carga no puede superar los 5 mb',
        VALIDATE_LOGO_SIZE: 'La foto que ha seleccionado es demasiado grande para usar. El tamaño máximo de archivo permitido es 500 KB',
        VALIDATE_BLANK_FILE: 'No puedes subir un archivo en blanco',
        VALIDATE_LOGO_EXTENSION: 'Por favor, seleccione un archivo jpg gif o png!',
        ATTACHMENT_LOGO_TEXT: 'Puede cargar un archivo jpg, gif o png.',
        ATTACHMENT_LOGO_SIZE_TEXT: ' El tamaño máximo de archivo permitido es 500 KB.',
        UPLOAD_IMAGE: 'Subir una imagen',
        UPLOAD_FILE: 'Seleccionar archivos para cargar (hasta cinco archivos a la vez)',
        ADD_LINK: 'Añadir un enlace externo',
        VALIDATE_MAX_ATTACHMENTS: 'No puedes subir más de cinco archivos',
        SELECT_IMAGE_TO_UPLOAD: 'Selecciona una imagen para subir',
        MAX_SIZE_2_MB_ALERT: 'El tamaño del Archivo de carga no puede ser más de 2 mb',
        VALID_FILE_UPLOAD: 'Puede cargar un archivo jpg o png desde la computadora.',
        MAX_SIZE_2_MB: 'El tamaño máximo de archivo permitido es de 2 MB.',
        CANNOT_UPLOAD_EXECUTABLE_FILE: 'No puedes subir archivos ejecutables',
        THE_ATTACHMENT: 'El adjunto',
        DOCUMENT_LINK: 'Enlace al documento', 
        FOLDER_NAME: 'Nombre de carpeta',
        FILE_NAME:'Nombre del archivo',
        ALREADY_EXIST: 'ya existe.',
        CANNOT_BE_DELETED: 'no se puede eliminar.',
        CLICK_TO_DELETE: 'Haga clic en Aceptar para eliminar ',
        EMPTY_FOLDER_ERROR_DOWNLOAD: 'No se puede descargar ya que la carpeta está vacía.',
        EMPTY_FOLDER_ERROR_PUSH: 'No se puede presionar cuando la carpeta está vacía.',
        RENAME_SUCCESSFULLY: 'Carpeta renombrada con éxito.',
        DELETE_SUCCESSFULLY: 'Carpeta eliminada correctamente.',
        ADDED_SUCCESSFULLY: 'agregó correctamente.',
        DOCUMENT_LINK_ADDED_SUCCESSFULLY: 'El enlace del documento se agregó correctamente.',
        DOCUMENT_LINK_UPDATE_SUCCESSFULLY: 'Enlace del documento actualizado correctamente.',
        DOCUMENT_LINK_DELETE_SUCCESSFULLY: 'El enlace del documento se eliminó correctamente.',
        FILE_ADDED_SUCCESSFULLY: 'Archivo agregado exitosamente.',
        FILE_RENAME_SUCCESSFULLY: 'Archivo eliminado con éxito.',
        FILE_DELETE_SUCCESSFULLY: 'Archivo renombrado con éxito.',
        IMAGE_RENAME_SUCCESSFULLY: 'Imagen renombrada correctamente.',
        IMAGE_NAME: 'Nombre de la imagen',
        MAX_SIZE_50_MB: 'El tamaño máximo de archivo permitido es 50 MB.',
    },
    AUTOMATION_DESIGNER: {
        STEPPER_BACK_MESSAGE: '¿Te gusta ir a la página de información de plantillas? Cualquier cambio no guardado se perdería.',
        MAX_CONNECTION: "Máximo no. de conexiones ha sido alcanzado.",
        TOUCH_CAMPAIGN_NAME: "Seleccione un nombre para esta campaña táctil",
        SELECT_FILTERS: "Por favor seleccione un",
        SELECT_FILTERS_A: "Por favor seleccione un",
        START_DATE: "Seleccione una fecha de inicio para",
        EMPTY_WEBSITE_LINK: "No puede agregar un enlace de sitio web vacío.",
        CANNOT_START_WITH_DECISSION: 'No puedes comenzar con una decisión. Comience con cualquiera de las opciones en el menú Comenzar con a la izquierda.',
        CANNOT_START_WITH_ACTION: 'No puedes comenzar con una acción. Comience con cualquiera de las opciones en el menú Comenzar con a la izquierda.',
        CONNECT_WITH_PROPER_NODE: 'Conéctate con el nodo apropiado',
        FROM_NAME: 'Por favor, introduzca un nombre de From para',
        FROM_EMAIL: 'Por favor, seleccione un correo electrónico de',
        RESEND_AFTER: 'Ingrese Reenviar después en día (s)',
        SELECT_WEB_FORM: 'Por favor seleccione un formulario web',
        SELECT_GROUP: 'Por favor seleccione un grupo',
        SELECT_GROUP_OPERATION: 'Por favor, elija una operación de grupo.',
        ENTER_NOTE: 'Por favor ingrese su nota.',
        SELECT_ONE_RECIPIENT: 'Seleccione al menos un destinatario',
        SELECT_USER: 'Por favor seleccione un usuario',
        ENTER_TASK_NAME: 'Por favor ingrese un nombre de tarea',
        PROVIDE_DUEBY_FIELD: 'Por favor, proporcione un valor en el campo Vencer por!',
        ENTER_LINK: 'Por favor ingrese un enlace.',
        PROVIDE_VALID_URL: 'Por favor, proporcione un URL del sitio web válido!',
        CATCH_ALL_LINK_CONNECTION: 'Ya tiene una condición de clic para capturar todos los enlaces',
        ENTER_SCORE: 'Por favor ingrese un puntaje',
        INVALID_SCORE: 'Has ingresado un puntaje no válido',
        ENTER_SUBJECT: 'Por favor ingrese un asunto',
        ENTER_BODY: 'Por favor ingrese el contenido del cuerpo',
        ENTER_INTERVAL_VALUE: 'Por favor ingrese un valor para el intervalo',
        SELECT_ONE_HOUR: 'Seleccione 1 hora o más para el intervalo de una hora.',
        BLANK_CAMPAIGN_NAME: 'El nombre de la campaña no puede estar en blanco.',
        CAMPAIGN_SAVED_SUCCESSFULLY: 'Esta campaña se ha guardado con éxito',
        BLANK_CANVAS: 'No puedes guardar un lienzo en blanco.',
        NOT_ROOT_NODE: 'no puede ser un nodo raíz',
        START_DATE_MUST_BE_GREATER: 'La fecha de inicio debe ser mayor que la campaña táctil anterior.',
        EDIT_AND_COMPLETE_DETAILS: 'Por favor edite y complete los detalles del',
        PROVIDE_WAIT_INTERVAL: 'Por favor proporcione un intervalo de espera más que cero.',
        CHECK_INTERVAL_WITH_PREVIOUS_TOUCH_CAMPAIGN: 'Por favor, compruebe el intervalo con la campaña táctil anterior, vuelva a enviar día (s).',
        CHECK_START_DATE_OR_WAIT_BEFORE_SAVE_TC: 'Verifique la fecha de inicio o espere antes de guardar la campaña táctil.',
        PROVIDE_START_DATE: 'Por favor proporcione una fecha de inicio.',
        INTERVAL_EXPEXT_ZERO: 'Por favor, proporcione un intervalo excepto cero.',
        EMPTY_LINK_NODE: 'No puede agregar un nodo de enlace vacío.',
        DELETE_NODE_CONFIRMATION: 'El nodo será eliminado. ¿Quieres continuar?',
        MSG_CANT_BE_DELETED: 'Este mensaje ha sido enviado y no puede ser eliminado.',
        FIRST_MSG_CANT_BE_DELETED: 'No puede eliminar el primer mensaje de una campaña táctil',
        FIRST_WAIT_CANT_BE_DELETED: 'No puedes borrar la primera espera de una campaña táctil',
        BLANK_INTERVAL: 'El intervalo no puede estar en blanco',
        PROVIDE_POSITIVE_INTERVAL: 'Por favor, proporcione un intervalo mayor que 0',
        WONT_BE_ABLE_TO_DELETE: 'No podrás eliminarlo.',
        URL_NOT_EXIST: 'La URL que se proporciona no está disponible en el cuerpo del correo electrónico.',
        GROUP_NAME_BLANK: '¡El nombre del grupo no puede estar en blanco!',
        SELECT_LANDING_PAGE: 'Seleccione una página de destino',
        RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD: 'Ya tiene un Diseñador de Automatización abierto, por favor cierre o guárdelo para abrir un nuevo Diseñador de Automatización.',
        SAVE_DIAGRAM_BEFORE_COPY: 'Guarde el diagrama antes de realizar la operación de copiado.',
        NO_TOUCH_FOUND: 'No se ha encontrado una campaña táctil.',
        ADD_ONE_FIELD: 'Por favor agrega al menos un campo',
        SELECT_FIELD_VALUE: 'Por favor seleccione un valor de campo',
        INVALID_URL: 'Es una url no válida',
        INVALID_INTEGER: 'No es un número entero',
        INVALID_EMAIL: 'es una dirección de correo electrónico inválida.',
        ALLOWED_UPTO_FOUR_FIELDS: 'La actualización está permitida hasta en 4 campos. No puede agregar otra fila de pares de valores de campo.',
        WILL_BE_UPDATED: 'Será actualizado. ¿Estás seguro?',
        DUPLICATE_TOUCH_NAME: 'Ya tiene una campaña Send Touch llamada',
        TOOLTIP_MESSAGE_SEND_MAIL: 'Comience con una campaña táctil que envíe una serie de correos electrónicos. Para usar esto, arrastre y suéltelo en el lienzo a la derecha.',
        TOOLTIP_MESSAGE_WEB_FORM: 'Comience por definir qué sucede después de enviar un formulario web. Para usar esto, arrastre y suéltelo en el lienzo a la derecha.',
        TOOLTIP_MESSAGE_LANDING_PAGE: 'Comience por definir qué sucede después de enviar una página de destino. Para usar esto, arrastre y suéltelo en el lienzo a la derecha.',
        TOOLTIP_MESSAGE_WEBSITE_VISIT: 'Comience por definir qué sucede después de que un contacto visite una página específica en su sitio web. Para usar esto, arrastre y suéltelo en el lienzo a la derecha.',
        TOOLTIP_MESSAGE_LINK_CLICK: 'Defina lo que sucede después de que un contacto hace clic en un enlace en su correo electrónico. Para usar esto, arrástrelo y suéltelo en el lienzo a la derecha y conéctelo con un mensaje táctil.',
        TOOLTIP_MESSAGE_EMAIL_OPEN: 'Define lo que sucede después de que un contacto abre un mensaje táctil. Para usar esto, arrástrelo y suéltelo en el lienzo a la derecha y conéctelo con un mensaje táctil.',
        TOOLTIP_MESSAGE_UNSUBSCRIBE: 'Define lo que sucede después de que un contacto anula la suscripción de un mensaje táctil. Para usar esto, arrástrelo y suéltelo en el lienzo a la derecha y conéctelo con un mensaje táctil.',
        TOOLTIP_MESSAGE_SCORE_REACHED: 'Defina lo que sucede después de que un contacto alcanza un umbral de puntuación. Para usar esto, arrastre y suéltelo en el lienzo a la derecha.',
        TOOLTIP_MESSAGE_AUTO_MAIL: 'El correo electrónico de envío se utilizará para enviar correos electrónicos únicos y táctiles. Esta acción se puede agregar después de una campaña táctil o cualquier decisión o acción.',
        TOOLTIP_MESSAGE_CHANGE_GROUP: 'Agrega o elimina cuentas y contactos de grupos. Para usar esto, arrastre y suéltelo en el lienzo a la derecha.',
        TOOLTIP_MESSAGE_ADD_NOTE: 'Agregue una nota a la cuenta. Para usar esto, arrastre y suéltelo en el lienzo a la derecha.',
        TOOLTIP_MESSAGE_NOTIFY: 'Notificar a un usuario de CRM. Para usar esto, arrastre y suéltelo en el lienzo a la derecha.',
        TOOLTIP_MESSAGE_ADD_TASK: 'Asignar una tarea a otro usuario. Para usar esto, arrastre y suéltelo en el lienzo a la derecha.',
        TOOLTIP_MESSAGE_UPDATE_FIELD: 'Actualice el valor de un campo para cuenta o contacto. Para usar esto, arrastre y suéltelo en el lienzo a la derecha.',
        TOOLTIP_MESSAGE_ASSIGN: 'Reasigna la cuenta a otro usuario. Para usar esto, arrastre y suéltelo en el lienzo a la derecha.',
        TOOLTIP_MESSAGE_CONVERT: 'Convierte la cuenta a un nuevo estado. Para usar esto, arrastre y suéltelo en el lienzo a la derecha.',
        TOOLTIP_MESSAGE_SEND_SMS: 'Send SMS to a group, account filter or contact filter. To use this, drag and drop it on the canvas to the right.',
        TOUCH_CAMPAIGN_DUPLICATE_NAME_TEXT_1: 'Ya tiene una campaña táctil llamada',
        TOUCH_CAMPAIGN_DUPLICATE_NAME_TEXT_2: 'Por favor ingrese un nombre diferente. ',
        INTERVAL_MODE_ADD_MSG_1: 'Por favor proporcione un',
        INTERVAL_MODE_ADD_MSG_2: 'Después del valor anterior excepto cero',
        AUTOMATED_CAMPAIGN_TITLE: 'Campañas automatizadas',
        CANNOT_CONNECT_MULTIPLE: 'No se puede conectar múltiples ',
        CONNECTION_EXIST: 'Ya tienes una conexión activa desde ',
        REMOVE_CONNECTION_ALERT: 'Si elimina la conexión, perderá todos los pasos siguientes',
        CONTINUE_ALERT: '¿Quieres continuar?',
        CANNOT_ADD: 'No puedes agregar',
        DIRECT_UNDER: 'directamente debajo de un',
        DIRECT_UNDER_AN: 'directamente debajo de un',
        MULTIPLE_TIME_SELECTED_MSG_1: 'Usted ha seleccionado el',
        MULTIPLE_TIME_SELECTED_MSG_2: 'campo (s) múltiples veces para la actualización. Por favor, elimine las filas duplicadas.',
        CANNOT_BE_LEAF_NODE: 'No puede ser un nodo de hoja.',
        CLICK: 'Hacer clic',
        HERE: 'Aquí',
        PURCHASE_MORE: 'Para comprar más',
        CREDITS_REMAINING: 'Crédito por correo electrónico restante.',
        STOP_CAMPAIGN: 'Detenga la campaña para hacer cambios.',
        SELECT_EMAIL_TEMPLATE: 'Seleccione una plantilla de correo electrónico',
        NO_INFO_EXISTS: 'No existe información',
        SELECT_RECORD_FOR_COPY_ONE_RECORD: 'Seleccione un registro para copiar.',
        SELECT_RECORD_FOR_EDIT_ONE_RECORD: 'Seleccione un registro para editar.',
        CANNOT_SAVE_CAMPAIGN: "No se puede guardar esta campaña. Consulte los bloques rojos en la campaña para ver los errores.",
        ADD_GROUP_CLICK: 'Hacer clic',
        ADD_GROUP_CLICK_HERE: 'Aquí',
        CLICK_TO_ADD_NEW_GROUP: 'Agregar un nuevo grupo',
        MESSAGE_SAVED_SUCCESSFULLY: 'Usted ha guardado este mensaje con éxito',
        MESSAGE_NOT_SAVED_SUCCESSFULLY: 'El mensaje no se ha guardado correctamente',
        QUERY_FILTER_BLANK: 'Por favor seleccione una consulta',
        CONTACT_FILTER_BLANK: 'Por favor seleccione un filtro de contacto.',
        ACCOUNT_FILTER_BLANK: 'Seleccione un filtro de cuenta.',
        SAVE_AUTOMATION_FOR_TEST: 'Por favor guarde la campaña de automatización.',
        BIRTHDAY_CAMPAIGN_NAME: 'Por favor, seleccione un nombre para esta campaña de cumpleaños',
        BIRTHDAY_CAMPAIGN_DUPLICATE_NAME_TEXT_1: 'Ya tienes una campaña de cumpleaños llamada',
        BIRTHDAY_CAMPAIGN_DUPLICATE_NAME_TEXT_2: 'Por favor, introduzca un nombre diferente. ',
        BIRTHDAY_FIRST_MSG_CANT_BE_DELETED: 'No puede eliminar el primer mensaje de una campaña de cumpleaños.',
        MIGRATION: 'Esta campaña automatizada se está actualizando al nuevo creador de campañas. Gracias por su paciencia.',
        MIGRATION_DIALOG_TITLE: 'Actualización en progreso ...',
        MIGRATION_NOTE: 'Nota: Después de la actualización, la campaña de automatización comenzará a ejecutarse el día en que realice la operación Iniciar.',
        BIRTHDAY_CAMPAIGN_NO_REPORT: 'Campaña de cumpleaños aún por enviar a los destinatarios.',
        RUNNING_CAMPAIGN_CANNOT_CANCEL: 'Una campaña en ejecución no se puede cancelar.',
        CONFIRM_CANCEL_SCHEDULE: '¿Desea cancelar la campaña programada?',
        CAMPAIGN_SCHEDULE_SUCCESSFULLY: 'Esta campaña ha sido programada con éxito.',
        SCHEDULED_CAMPAIGN_CANCEL_SUCCESSFULLY: 'Esta campaña ha sido cancelada con éxito.',
        CAMPAIGN_START_IMMEDIATELY: 'Esta campaña se ha configurado correctamente para comenzar de inmediato.',
        ENTER_FROM_NAME: 'Por favor, introduzca el nombre.',
        ENTER_FROM_EMAIL: 'Por favor, introduzca frm email.'
    },
    WEBSITE_TRACKING: {
        DOMAIN_NAME_BLANK: 'Proporcione un nombre de dominio para generar el código de seguimiento.',
        DOMAIN_NAME_INVALID: 'Por favor proporcione un nombre de dominio válido.',
        TRACKING_CODE_BLANK: 'No hay un código de seguimiento para copiar.',
        TRACKING_CODE_ALREADY_GENERATED: 'El código de seguimiento ya está generado.',
        DONE: 'Su nombre de dominio ha sido registrado.',
        SET_UP_INFO: 'Copie y pegue este código en cada página de su sitio justo antes de la etiqueta del cuerpo final.Una vez que haya terminado, vuelva a esta página y haga clic en el botón Listo.'

    },
    MAIL: {
        SUCCESS: 'Tu correo ha sido enviado.',
        BLANK_FROM_EMAIL: 'Del correo electrónico está en blanco.',
        INVALID_FROM_EMAIL: 'Tiene un inválido de la dirección de correo electrónico en el campo De.',
        INVALID_CC_EMAIL: 'Tiene una dirección de correo electrónico CC no válida en el campo CC.',
        INVALID_BCC_EMAIL: 'Tiene una dirección de correo electrónico BCC no válida en el campo BCC.',
        BLANK_SUBJECT: 'El sujeto está en blanco.',
        BLANK_EMAIL_BODY: 'El cuerpo del correo electrónico está en blanco.',
        BATCH_SEND_MAIL_MSG_1: 'Fuera de',
        BATCH_SEND_MAIL_MSG_2: 'Has seleccionado solo',
        BATCH_SEND_MAIL_MSG_3: 'Ha optado por recibir sus correos electrónicos. Enviaríamos el correo electrónico solo a estos',
        BATCH_SEND_MAIL_MSG_4: 'Ninguno de los',
        BATCH_SEND_MAIL_MSG_5: 'Ha optado por recibir sus correos electrónicos. No enviaremos este correo electrónico. Por favor cierra esta pestaña',
        NO_RECIPIENT: 'No ha ingresado ningún destinatario para este correo electrónico',
        NO_RECIPIENT_FOUND: 'Ningún destinatario fue encontrado para este correo electrónico.',
        BLANK_TO_EMAIL: 'No hay dirección de correo electrónico en el campo Para.',
        INVALID_TO_EMAIL: 'Dirección de correo electrónico no válida en el campo Para.',
        SCHEDULE_SUCCESS: 'Su correo electrónico ha sido programado con éxito.',
        RESCHEDULE_SUCCESS: 'El correo electrónico se ha reprogramado con éxito',
        TEMPLATE_NOT_SELECTED: 'Please select a template',
        NO_ACTIVE_AGREEMENT_TEMPLATE_MSG: 'No tiene ninguna plantilla de acuerdo activa.',
        SELECT_DIFFERENT_SIGNER: "Please select a different signer.",
        SELECT_ALL_SIGNERS: 'Please select all the signer(s) to send the agreement email.',
    },
    APPOINTMENTS_TASKS: {
        DELETE: 'Este registro será eliminado. ¿Estás seguro?',
        INVALID_START_END_DATE: 'La fecha de inicio no puede ser mayor o igual que la fecha de finalización.',
        RECURR_DELETE_DIALOG_ONLY_BTN_INFO: 'Todos los demás registros de la serie se mantendrán.',
        RECURR_DELETE_DIALOG_FOLLOWING_BTN_INFO: 'Este y todos los siguientes registros serán eliminados.',
        RECURR_DELETE_DIALOG_ALL_BTN_INFO: 'Todos los registros en la serie serán eliminados.',
        RECURR_EDIT_DIALOG_ONLY_BTN_INFO_1: 'Todos los demás',
        RECURR_EDIT_DIALOG_ONLY_BTN_INFO_2: 'En la serie seguirá siendo el mismo.',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_1: 'Esto y todo lo siguiente',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_2: 'Sera cambiado.',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_3: 'Cualquier cambio en el futuro',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_4: 'Se perderá.',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_1: 'Todas',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_2: 'En la serie será cambiado.',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_3: 'Cualquier cambio hecho a otro',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_4: 'Será guardado.',
        RESCHEDULE_TASK_SUCCESS: 'La tarea ha sido reprogramada exitosamente',
        RESCHEDULE_APPOINTMENT_SUCCESS: 'La cita ha sido reprogramada con éxito',
        NO_TASK_FOUND: 'No se encontraron tareas',
        NO_APPOINTMENT_FOR_TODAY: 'No hay citas programadas disponibles hoy',
        NO_PENDING_TASK_FOR_TODAY: 'No hay tareas pendientes disponibles hoy',
        NO_TASK_APPOINTMENT_FOUND: 'Nada pendiente.',
        NO_TASK_APPOINTMENT_FOUND_FOR_COMPLETED: 'Nada completado.',
        BLANK_START_END_DATE: 'La fecha de inicio y finalización no puede estar en blanco.',
        BLANK_START_DATE: 'La fecha de inicio no puede estar en blanco.',
        BLANK_END_DATE: 'La fecha de finalización no puede estar en blanco.',
        BLANK_NO_OF_DAYS: "No. De días puede estar en blanco.",
        ZERO_NO_OF_DAYS: "No. De días puede estar en cero.",
        BLANK_SPECIFIC_DATE: "La fecha no puede estar en blanco.",
        INVALID_DUEBY_END_DATE: 'La fecha de vencimiento no puede ser mayor o igual que la fecha de finalización de repetición.',
        SEND_EMAIL_PROGRESS_MSG: 'El envío de correo electrónico está en progreso, tomará un momento.',
        INVALID_DUEBY_START_DATE: 'El vencimiento de la fecha no puede ser mayor o igual que la fecha de inicio.',
        SYNC_STARTED_MSG: 'Se ha iniciado la sincronización. Por favor, actualice el calendario para ver nuevas citas.',
        SYNC_STARTED_MSG_FOR_SETUP: 'Se ha iniciado la sincronización. Su calendario se actualizará pronto.',
        ENABLE_SYNC_CAL: 'Habilite la opción de calendario de sincronización en la configuración de integración antes de sincronizar.',
        PRIVATE_APPOINTMENT_ALERT:"Esta es una cita privada; no puedes abrir esto.",
        START_END_DATE_VALIDATE:'no puede ser mayor o igual que',
        CONVERT_CONFIRMATION_MESSAGE: 'Una o más tareas no están activadas. ¿Desea continuar con la operación Convertir sin comprobar esas tareas?'
    },
    TASKS:{
        DELETE_CANCELLED_STATAUS_RESTRICT_MSG:'No está permitido cancelar ninguna tarea de acciones correctivas.',
    },
    HOME: {
        FILTER_ADD: 'Filtro agregado con éxito',
        FILTER_NOT_FOUND: 'No has configurado ningún filtro',
        FILTER_DELETE_RECORD: 'El filtro será eliminado. ¿Estás seguro?',
        FILTER_DELETE: 'Filtro eliminado con éxito',
        FILTER_ON_MAX_FIELD: 'Has alcanzado la condición máxima de campo. Permitimos hasta 10 campos.',
        ASSIGNMENT_RULES_ON_MAX_FIELD: 'Ha alcanzado la condición de campo máxima. Permitimos hasta 20 campos.',
        // not found in doc
        FILTER_NAME_BLANK: 'Por favor proporcione un nombre de filtro.',
        FILTER_NAME_MAX_LENGTH: 'El nombre del filtro no puede tener más de 64 caracteres.',
        FILTER_CRITERIA_EMPTY: 'Por favor proporcione al menos un criterio.',
        FILTER_SQL_ERROR_NO_VALUE: ' no puede estar en blanco.',
        FILTER_SQL_ERROR_INTEGER: ' não é um inteiro. ',
        FILTER_SQL_ERROR_FLOAT: ' não é um flutuador.  ',
        FILTER_SQL_ERROE_ALREADY_EXISTS: '¡ el nombre del filtro ya existe!',
        FILTER_DELETE_MSG_TC: 'las siguientes campañas Touch (s) ',
        FILTER_DELETE_MSG_BC: ' la siguiente campaña (s) de difusión ',
        FILTER_DELETE_MSG_AB: ' las siguientes campañas A/B ',
        FILTER_DELETE_MSG_AND: ' y ',
        FILTER_DELETE_MSG_BEING_USED: ' no se puede eliminar ya que se está utilizando en ',
        ADD_GROUP_SUCCESS: 'Grupo agregado correctamente.'

    },
    DOCUMENT_LIBRARY: {
        INVALID_UPLOAD_ERROR: 'Seleccione un archivo de imagen!',
        CONFIRM_DELETE_MSG: '¿Seguro que quieres eliminar esta imagen?',
        OVER_MAX_SIZE: 'El archivo que ha seleccionado es demasiado grande para usarlo. El tamaño máximo de archivo permitido es de 50 MB.',
        OVER_MAX_SIZE_IMAGE: 'El archivo que ha seleccionado es demasiado grande para usar. El tamaño máximo de archivo permitido es de 500 kb.',
        INVALID_DOCUMENT_UPLOAD_ERROR: '¡Los documentos exe bat sh no se pueden cargar!',
        DOCUMENT_FILE_EMPTY_ERROR: 'Por favor seleccione un documento!',
        ASSETS_ALREADY_OPEN_ALERT: 'Ya tiene la pestaña Biblioteca abierta en el módulo Operaciones.',
        DUPLICATE_IMAGE_NAME: 'La imagen ya existe con este nombre. Por favor, seleccione una imagen diferente!',
        DUPLICATE_DOCUMENT_NAME: 'El documento ya existe con este nombre. Por favor, seleccione un documento diferente!',
        UPLOAD_IMAGE: 'Seleccione imágenes para cargar (hasta cinco archivos a la vez).',
        ASSETS_ALREADY_OPEN_ALERT_HUB: 'Ya tiene la pestaña Biblioteca abierta en el módulo Hub.',
        BLANK_TITLE_ERROR : 'El título no puede estar en blanco',
        BLANK_URL_ERROR : 'El enlace no puede estar en blanco.',
        INVALID_URL_ERROR : 'Por favor proporcione una URL válida',
        CONFIRM_FILE_TYPE_CHANGE: {
            LN1: '¿Quieres salir de la sección actual? Cualquier cambio no guardado se perderá.',
            LN2: 'Haga clic en Aceptar para confirmar y salir.',
            LN3: 'Haga clic en Cancelar para permanecer en la sección actual.',
        },
    },
    MERGE: {
        NOT_URL: "Es una url no válida",
        NOT_FLOAT: "No es un flotante",
        NOT_INTEGER: "No es un número entero",
        NOT_EMAIL: "es una dirección de correo electrónico inválida.",
        MERGE_TENANT_EMAIL_ALERT_MESSAGE: 'No puede fusionar estos registros. ¡Como ha elegido una cuenta paga para eliminar!',
    },
    SCHEDULE: {
        EMPTY: 'Por favor, seleccione una fecha de programación',
        CAMPAIGN_SCHEDULE: 'Esta campaña se ejecutará',
        CONFIRM: 'Haga clic en Ok para confirmar.',
        SMS_SCHEDULE: 'Este mensaje de texto se enviará el'
    },
    RELATIONSHIP: {
        BLANK_RECORD: "Por favor seleccione la relación para",
        BLANK_PARENT_RELATION: "Seleccione al menos una relación principal.",
        BLANK_CHILD_RELATION: "Seleccione al menos una relación de niño",
        NO_PARENT_RELATION: "No tienes ninguna relación. Cree una relación en la personalización desde Todos los ajustes",
        NO_PARENT_RELATION_FOR_USER: "Lo sentimos, no hay relación disponible. Por favor contacte a su administrador",
        NO_RELATIONSHIP_SETUP: "Aún no ha configurado ningún tipo de relación",
        PLACEHOLDERTEXT_COMPANY: "Escriba un nombre de compañía y presione enter",
        PLACEHOLDERTEXT_NAME: "Escriba un nombre completo y presione enter",
        PLACEHOLDERTEXT_EMAIL: "Escriba un correo electrónico y presione enter",
    },
    GROUP: {
        NO_TOUCH_CAMPAIGN: "No se usa en ninguna campaña de Touch.",
        NO_BROADCAST_CAMPAIGN: "No se usa en ninguna campaña de difusión.",
        NO_AB_CAMPAIGN: "No se usa en ninguna Campaña A / B.",
        NO_WEBFORM: "No se usa en ningún formulario web.",
        NO_LANDINGPAGE: "No se usa en ninguna página de aterrizaje.",
        DELETE_MSG: 'El (los) siguiente (s) grupo (s) no pueden ser eliminados',
        USED_IN_TOUCH_CAMPAIGN: 'Se usa en la siguiente Campaña Touch (s)',
        USED_IN_BROADCAST_CAMPAIGN: 'Se usa en la siguiente campaña de difusión',
        USED_IN_AB_CAMPAIGN: 'Se usa en la siguiente Campaña A / B',
        USED_IN_WEBFORM: ' Se utiliza en los siguientes formularios web',
        USED_IN_LANDINGPAGE: 'Se usa en la (s) siguiente (s) página (s) de aterrizaje',
        SELECT_GROUP: 'Seleccione uno o más grupos',
        NO_GROUP_AVAILABLE: 'No hay grupo disponible Hacer clic',
        SELECT_RECORDS: 'Registros seleccionados',
        ALL_RECORDS: 'Todos los registros',
        SELECT_OPTIONS: 'Seleccione opciones',
        ADD_NEW_GROUP: 'Agregar un nuevo grupo',
        GROUP_DELETE_RECORD: 'El grupo será eliminado. ¿Estás seguro?',
        COMMON_DELETE_MSG:" no se puede eliminar ya que se está utilizando en las siguientes campañas:",
    },
    LOGIN: {
        VALIDATE_EMPTY_USER_NAME: 'Por favor, introduzca su correo electrónico y contraseña',
        VALIDATE_EMPTY_PASSWORD: 'Por favor, introduzca su contraseña',
        VALIDATE_USERNAME_PASSWORD: 'Has ingresado un correo electrónico o contraseña incorrectos',
        INVALID_RESPONSE: 'Respuesta invalida',
        LOGIN_COPYRIGHTS_TEXT_1: 'Derechos de autor',
        LOGIN_COPYRIGHTS_TEXT_2: 'Soffront Software, Inc. Todos los derechos reservados.',
        BEST_VIEWED: 'Se ve mejor en Google Chrome, Mozilla Firefox y Safari.'
    },
    AUDIT_LEADSCORE: {
        NO_AUDIT_DATA: 'No se encontró registro de seguimiento de auditoría',
        NO_SCORE_HISTORY: 'Sin historial para mostrar.'
    },
    DETAIL_VIEW: {
        CHANGE_PRIMARY_CONTACT_ALERT_1: 'Usted seleccionó',
        CHANGE_PRIMARY_CONTACT_ALERT_2: 'Como el contacto principal para',
        CLICK_TO_CONFIRM: 'Haga clic en Aceptar para confirmar.',
        MAX_SELECTED_FIELD_LIMIT: 'Alcanzaste el límite máximo de 50 campos seleccionados',
        SELECT_ATLEAST_ONE_FIELD: 'Seleccione al menos un campo ',
        SELECT_ATLEAST_ONE_CHILD_OBJECT: 'Seleccione al menos un objeto hijo.',
        MAX_SELECTED_CHILD_OBJECT_LIMIT: 'Alcanzaste el límite máximo de 10 objetos seleccionados.',
        NO_ASSOCIATED_GROUPS_FOUND: 'No hay grupos asociados disponibles',
        CONFIRM_UNSUBSCRIBE_RECORD: 'El registro será cancelado. ¿Estás seguro?',
        BLANK_SPECIFIC_DATE: 'La fecha no puede estar en blanco.',
        CONFIGURE_CHILD_SETUP: 'Configura la configuración secundaria.',
        CHILD_SETUP_SELECT_ATLEAST_ONE_FIELD: 'Seleccione al menos un campo para la configuración secundaria.',
        EMAIL_FORMAT_MSG: "- Formato de correo inválido.",
        URL_FORMAT_MSG: "- Formato de URL no válido.",
        FLOAT_FORMAT_MSG: "- Formato flotante no válido.",
        INTEGER_FORMAT_MSG: "- Formato de entero no válido.",
        ERROR_MSG: "¿Quieres irte sin guardar los cambios?",
    },
    CHARTS: {
        NOTHING_TO_SHOW: 'Nada que mostrar para este marco de tiempo'
    },
    ADVANCE_SEARCH: {
        SEARCH_FIELDS_NOT_FOUND: 'Nada para buscar. Porfavor introduzca un valor.',
        MAX_ADD_FIELD_ALERT:'No se puede buscar con más de 10 campos',
        MIN_ADD_FIELD_ALERT:'Se requiere al menos un campo para buscar.',
        SELECT_FIELD_ALERT:'Seleccione el campo antes de ingresar el valor.',
        ADVANCE_SEARCH: 'Búsqueda Avanzada',
        EMPTY_DATE: 'Por favor ingrese Hasta la fecha.',
        INVALID_DATE_RANGE: 'Desde la fecha no puede ser mayor que Hasta la fecha',
        EMPTY_FROM_TO_DATE: 'Por favor ingrese Desde fecha y Hasta la fecha.',
        EMPTY_FROM_DATE: 'Por favor ingrese desde la fecha.',
        EMPTY_TO_DATE: 'Por favor ingrese Hasta la fecha.'
    },
    PUSH: {
        PUSH_SUCCESS_MSG_1: 'Has empujado con éxito',
        PUSH_SUCCESS_MSG_2: 'A las empresas seleccionadas.',
        PUSH_SUCCESS_MSG_2_1: 'será empujado',
        PUSH_SUCCESS_MSG_3: 'Se ha enviado una notificación por correo electrónico a las compañías seleccionadas.',
        PUSH_SUCCESS_MSG_4: 'Has seleccionado más de 20 registros. Puede presionar un máximo de 20 cuentas a la vez.',
        PUSH_SUCCESS_MSG_4_1: 'a la vez.',
        PUSH_SUCCESS_MSG_5: 'Seleccione una empresa para empujar',
        PUSH_SUCCESS_MSG_6: 'No hay clientes conectados disponibles para enviar cuentas. ',
        PUSH_SUCCESS_MSG_7: 'Empujado con éxito',
        PUSH_ALERT_MSG_8: 'Seleccione al menos una empresa para enviar plantillas de correo electrónico',
        PUSH_ALERT_MSG_9: 'Seleccione al menos una empresa para impulsar las campañas de automatización',
        PUSH_ALERT_MSG_10: 'Por favor, seleccione al menos una empresa para impulsar campañas de difusión',
        PUSH_SUCCESS_MSG_11: 'a la empresa seleccionada.',
        //Added by Anindya
        PUSH_SUCCESS_MSG_11_2: 'Estás seguro?',
        //End
        PUSH_SUCCESS_MSG_12: 'Se ha enviado una notificación por correo electrónico a la empresa seleccionada.',
        PUSH_SUCCESS_MSG_13: 'Por favor, seleccione al menos una unidad para impulsar las oportunidades de ventas',
        PUSH_SUCCESS_MSG_14: 'Seleccione al menos una unidad para impulsar los flujos de trabajo',
        PUSH_FOLDER: 'Carpeta',
        FAIL_USE_ASSIGNMENT_RULE_FOR_SALES_LEADS: 'No se cumple ninguna regla de asignación para impulsar los leads de ventas',
        PUSH_ALERT_MSG_12: 'Seleccione al menos una unidad para enviar plantillas de acuerdo.',
        LANDING_PAGES: 'páginas de destino',
    },
    PROFILE_DETAILS: {
        UNMATCH_NEW_CONFIRM_PASSWORD: 'La contraseña confirmada no coincide con la nueva contraseña.',
        NO_NEW_PASSWORD: 'Ingrese una contraseña válida y confírmela.',
        NO_CONFIRM_PASSWORD: 'Confirma la nueva contraseña',
        VALIDATE_PROFILE_IMAGE: 'Seleccione un archivo de imagen',
        PROFILE_IMAGE_UPLOAD: 'Imagen de perfil guardada con éxito.',
        PROFILE_IMAGE_UPLOAD_FAILURE: 'La imagen del perfil no se pudo guardar.',
        PROFILE_UPDATE: 'Perfil actualizado con éxito.',
        VALIDATE_PROFILE_IMAGE_SIZE: 'La foto que ha seleccionado es demasiado grande para usar. El tamaño máximo de archivo permitido es 500 KB',
        PROFILE_IMAGE_ATTACHMENT_LOGO_TEXT: 'Puede cargar un archivo jpg, gif o png.',
        PROFILE_IMAGE_SIZE_TEXT: ' El tamaño máximo de archivo permitido es 500 KB.',
        PROFILE_SIGNATURE_AUTOSAVED: 'Firma guardada con éxito.'
    },
    CUSTOMIZATION: {
        LOCATION_VAL_NOT_MATCH: 'El valor de ubicación no coincide.',
        LOCATION_VAL_BLANK: 'No hay ningún valor disponible en el campo de ubicación.'
    },
    IMPORT: {
        SELECT_FILE: 'Por favor seleccione un archivo',
        SELECT_GROUP: 'Por favor seleccione un grupo',
        SELECT_WORKFLOW: 'Seleccione un flujo de trabajo',
        SELECT_STATUS: 'Por favor seleccione un estado',
        SELECT_SOURCE: 'Seleccione una fuente',
        SOURCR_NAME_EXISTS: 'El valor ya existe en la lista',
        SOURCR_NAME_BLANK: 'El nombre de la fuente no puede estar en blanco',
        IMPORT_FILE_EXT: 'Seleccione el archivo con extensión .csv o .xls / xlsx',
        NO_RECORD_TO_IMPORT: '¡Sin registro para importar!',
        NO_MAPPING: 'No hay mapeo para ',
        NOT_ALL_MAPPED: 'Una o más columnas no están asignadas a CRM. ¿Le gustaría continuar sin importar esas columnas?',
        IMPORT_CONFIRM_MSG: 'los registros serán importados a',
        IMPORT_CONFIRM_MSG_1: 'El registro será importado en',
        CONFIRM: 'Por favor confirmar.',
        SELECT_LINK_USING: 'Por favor seleccione una opción para "Enlace usando"',
        SELECT_LINK_WITH: 'Por favor seleccione una opción para "Enlace con"',
        SELECT_DUPLICATE_FIELD: 'Seleccione al menos una opción para el campo duplicado',
        SOURCE_ALERT_MSG_1: 'Correlacione el campo "Fuente" de la cuenta con una columna que contenga sus fuentes de marketing.',
        SOURCE_ALERT_MSG_2: 'Usted ha elegido Fuente de la lista desplegable, así como de la sección de mapeo. Puede elegir el valor del campo Fuente en un lugar, no en dos lugares.',
        NO_MAPPING_AVAILABLE: 'No hay mapas disponibles.',
        BLANK_MAP_NAME: 'Por favor, proporcione un nombre de mapa!',
        VALID_MAP_NAME_LENGTH: 'El nombre del mapa no debe tener más de 32 caracteres',
        DUPLICATE_MAP_NAME: 'El nombre del mapa ya existe',
        MAP_SAVE_SUCCESS: 'Tu mapeo de campo se ha guardado con éxito',
        NO_PREVIOUS_MAPPING: 'Ningún mapa previo presente',
        MAP_NAME: 'Nombre del mapa',
        DELETE_MAP_ALERT: 'Estas seguro que quieres borrarlo',
        DELETE_MAP_SUCCESS: 'Mapeo borrado exitosamente',
        IMPORT_SUCCESS: 'Exitosamente importado',
        WARNING: '¡Advertencia!',
        WARNING_TEXT: 'No cierre esta página hasta que finalice la importación.',
        IMPORTING_RECORDS: 'Importación de su registro(s)',
        SUCCESS_IMPORT_COUNT: 'Registro(s) fueron importados',
        FAIL_IMPORT_COUNT: 'Registro(s) no se importaron',
        FOUND: 'Encontramos',
        DUPLICATES: 'Duplicado(s)',
        APPLY_WORKFLOW_TEXT: 'A los registros importados',
        APPLYING_WORKFLOW: 'Aplicando flujo de trabajo',
        APPLIED_WORKFLOW: 'Completado aplicando flujo de trabajo',
        APPLIED_WORKFLOW_TO: 'Flujo de trabajo aplicado a',
        MAX_IMPORT_RECORDS_MSG_1: 'Existen',
        MAX_IMPORT_RECORDS_MSG_2: 'Registros en el archivo. ¡Presentemente puede importar 10000 registros a la vez',

        // not found in doc
        VERIFY_CONTACTS_MSG_1: 'Verifique que estos contactos le hayan proporcionado su',
        VERIFY_CONTACTS_MSG_2: 'permiso para enviarlos por correo electrónico. No permitimos el alquiler ni ',
        VERIFY_CONTACTS_MSG_3: 'listas compradas Estos contactos deben haber pedido',
        VERIFY_CONTACTS_MSG_4: 'para recibir correos electrónicos de usted o tener una relación cercana con ',
        VERIFY_CONTACTS_MSG_5: 'tu organización.',

        FILE_TYPE_MSG: 'La primera fila del archivo CSV o XLS / XLSX debe contener los nombres de los campos.',
        DATE_FORMAT_MSG: 'La fecha debe estar en formato MM / DD / AAAA ',
        ACCOUNTS_AND_CONTACTS: 'Cuentas y contactos',
        IMPORT_CSV_FILE: 'Seleccione el archivo con la extensión .csv.',
        RECORDS_IMPORT_SUCCESS: 'Los registros se han importado correctamente.',
        GREEN_LIGHT_STATUS_ERROR: 'Ya hay una importación en curso, por lo que puede iniciar una nueva importación solo después de completar el proceso de importación actual.',
        GREEN_LIGHT_STATUS_SUCCESS: 'Tu importación actual está en curso. Le enviaremos un correo electrónico de confirmación al correo electrónico registrado de los franquiciados en cuestión una vez que haya terminado.',
    },
    YOUTUBE_VIDEO: {
        EMPTY_VIDEO_LINK: 'Por favor, pegue una URL de video para YouTube',
        INVALID_VIDEO_LINK: 'La URL que ingresaste no es válida',
        INSERT_TITLE: 'Insertar video de YouTube',
        VIDEO_LINK_PLACEHOLDER: 'Pegue una URL de video de YouTube'
    },
    WISTIA_VIDEO: {
        EMPTY_VIDEO_LINK: 'Por favor, pegue una URL de video para Wistia',
        INVALID_VIDEO_LINK: 'La URL que ingresaste no es válida',
        INSERT_TITLE: 'Insertar video desde Wistia',
        VIDEO_LINK_PLACEHOLDER: 'Pegue una URL de video de Wistia'
    },
    INXPRESS_REPORT: {
        VALIDATE_EMPTY_FROM_DATE: 'Seleccione de la fecha.',
        VALIDATE_EMPTY_TO_DATE: 'Seleccione hasta la fecha.',
        VALIDATE_EMPTY_COUNTRY: 'Seleccione el pais.',
        VALIDATE_EMPTY_FRANCHISE: 'Por favor seleccione franquicia.',
        VALIDATE_EMPTY_WORKFLOW: 'Por favor, seleccione flujo de trabajo.',
        VALIDATE_EMPTY_SALES_REP: 'Seleccione Representante de ventas.',
        VALIDATE_EMPTY_OBJECT_NAME: 'Seleccione Informe en.',
        NO_RECORD_EXPORT: 'No hay registros disponibles para exportar.',
        REPORT_NOT_READY_FOR_EXPORT: 'Espere mientras se carga el informe, no puede exportar ahora.',
        BLANK_FROM_TO_DATE: 'La fecha no puede estar en blanco',
        VALIDATE_EMPTY_COUNTRY_INXPRESS_REPORT16: 'Por favor seleccione un país para continuar.',
        VALIDATE_EMPTY_WORKFLOW_INXPRESS_REPORT: 'Seleccione un flujo de trabajo para continuar.',
        VALIDATE_EMPTY_REGION_INXPRESS_REPORT: 'Seleccione una región para continuar.',
        VALIDATE_EMPTY_DATE_RANGE: 'Seleccione un rango de fechas para continuar.',
        VALIDATE_EMPTY_WORKFLOW_STAGES: 'Seleccione una etapa de flujo de trabajo para continuar.',
        VALIDATE_EMPTY_CHART_TYPE: 'Seleccione un tipo de gráfico para continuar.',
        VALIDATE_EMPTY_SHOW_TYPE: 'Seleccione un tipo de espectáculo para continuar.',
        VALIDATE_EMPTY_ACTIVITY_TYPE: 'Seleccione un tipo de actividad para continuar.',
    },

    OTHER_VIEW: {
        TENANT_MESSAGE: 'Su suscripción no incluye $$ @@ ## PRODUCT_NAME ## @@ $$',
        USER_MESSAGE: 'Por favor comunícate con tu administrador para agregar $$ @@ ## PRODUCT_NAME ## @@ $$ a tu suscripción.',
        AFTER_SUBSCRIPTION_VENDASTA_NOT_CONFIGURED: 'Aún no hemos configurado su cuenta para $$ @@ ## PRODUCT_NAME ## @@ $$. Le notificaremos tan pronto como esté disponible.'
    },

    MY_INTEGRATION: {
        SAVE_SUCCESS: 'Su configuración se guardó exitosamente.',
        APPEND_INFO: 'Adjunte información de la compañía y de contacto a las citas agregadas de Soffront',
        DISCONNECT_CONNECTION: 'La conexión será eliminada. ¿Estás seguro?',
        SAVE_FAIL: 'Su configuración no está guardada.',
        NO_CALENDAR: 'El calendario no está seleccionado.',
        NO_SYNC_CHECKED: 'No ha seleccionado ninguna de las opciones de sincronización. Puede optar por sincronizar sus correos electrónicos, calendario o ambos.',
        SYNC_EMAIL_INFO_1: 'Seleccione esta opción para guardar automáticamente los correos electrónicos de su buzón de G Suite / Office 365 en CRM.',
        SYNC_EMAIL_INFO_2: 'Los correos electrónicos se guardarán contra una cuenta coincidente o registro de contacto.',
        SYNC_EMAIL_INFO_3: 'Los correos electrónicos se sincronizarán cada 5 minutos.',
        SYNC_CONTACT_INFO_1: 'Seleccione esta opción para agregar automáticamente sus contactos de CRM a G Suite / Office 365.',
        SYNC_CONTACT_INFO_2: 'Cualquier cambio que realice en los contactos de CRM se actualizará automáticamente en G Suite / Office 365.',
        SYNC_CONTACT_INFO_3: 'Los contactos se sincronizarán cada 5 minutos.',
        SYNC_CALENDAR_INFO_1: 'Seleccione esta opción para sincronizar automáticamente las citas entre su calendario de Soffront con un calendario de su cuenta de G Suite / Office 365.',
        SYNC_CALENDAR_INFO_2: 'Si tiene varios calendarios de G Suite / Office 365, deberá elegir uno que desee sincronizar.',
        SYNC_CALENDAR_INFO_3: 'Los calendarios se sincronizarán cada 5 minutos.',
        NO_CALENDAR_MESSAGE: 'Estamos a la espera de conocer los detalles de su calendario. Cierre esta pantalla y vuelva en unos minutos. Si continúa viendo este mensaje, desconecte y vuelva a conectar su cuenta.',
        NO_PERMISSION: 'Mi integración no está disponible en su plan actual. Por favor, suscríbase a un plan apropiado o contacte a soporte en smbsupport@soffront.com.',
        SEND_SALES_EMAILS_VALIDATION1: 'Para poder enviar correos electrónicos utilizando',
        SEND_SALES_EMAILS_VALIDATION2: 'tendrás que conectarte usando',
        SEND_SALES_EMAILS_VALIDATION3: 'Desconecte y vuelva a conectar usando',
    },

    DOCUSIGN: {
        PROVIDE_USERNAME: 'Proporcione un nombre de usuario válido.',
        PROVIDE_PASSWORD: 'Por favor ingrese una contraseña válida.',
        PROVIDE_INTEGRATOR_KEY: 'Proporcione una clave de integrador válida.',
        SUCCESSFULLY_SAVED: 'Ha configurado correctamente su cuenta de DocuSign.',
        FAILED_TO_SAVED: 'No se puede configurar su cuenta de DocuSign.',
        DOCUSIGN_SETUP_TITLE_1: 'Seleccione un documento y envíelo ',
        DOCUSIGN_SETUP_TITLE_2: ' a',
        DOCUSIGN_SETUP_TITLE_3: ' Para firma',
        PROVIDE_RECIPIENT_NAME: 'Proporcione el nombre completo del destinatario',
        PROVIDE_RECIPIENT_EMAIL: 'Por favor, brinde el correo electrónico',
        PLEASE_SELECT_TEMPLATE: 'Por favor seleccione una plantilla.',
        NO_TEMPLATES_FOUND: 'No se encontraron plantillas.',
        UNABLE_TO_SEND: "No se puede enviar la plantilla debido a que su cuenta de DocuSign ha caducado.",
        TEMPLATE_NAME_LENTH: 'El nombre de la plantilla no debe exceder los 65 caracteres.',
        PLEASE_SELECT_CONTACT: 'Seleccione al menos un contacto.',
        DOCUSIGN_CONTACT_MESSAGE_1: 'No hay correo electrónico disponible para los siguientes contactos: ',
        DOCUSIGN_CONTACT_MESSAGE_2: 'No se enviará correo electrónico a estos contactos.',
        DOCUSIGN_CONNECTED_MESSAGE_1: 'Lo sentimos, no tiene el permiso para realizar esta operación. Por favor, póngase en contacto con Soffront.',
    },

    TWILIO: {
        CALL_OVER_SECURED_NETWORK: 'Puede hacer una llamada solo a través de una red segura',
        SAME_TO_AND_FROM_NUMBER: 'Al número y al número es el mismo. La llamada no se puede iniciar.',
        CALLING_SUPPORT: 'Actualmente, no se admiten llamadas en su país.',
        OUTBOUND_PHONE_NOT_VERIFIED: 'Su número de teléfono saliente no está verificado. Por favor verifíquelo antes de hacer una llamada.',
        CALL_RECORDING_SAVE: 'Guardado exitosamente. Por favor, cierre la sesión y vuelva a iniciar sesión para ver el cambio.',
        CALL_RECORDING_LAWS_AND_REGULATION_ALERT:'Asegúrese de que sus prácticas de grabación de llamadas cumplan con las leyes y regulaciones aplicables.',
        NOTE_SAVE: 'Nota guardada exitosamente',
        PHONE_LOW_BALANCE1: 'El saldo de su teléfono es ahora ',
        PHONE_LOW_BALANCE2: ' minuto(s).',
        PHONE_LOW_BALANCE3: 'Necesita más de 5 minutos de saldo para hacer una llamada.',

        PHONE_BLANK_MESSAGE: 'Por favor ingrese un número de teléfono.',
        PHONE_ALREADY_CONFIGURE: 'Este número de teléfono ya está configurado.',
        DELETE_PHONE_NUMBER: '¿Confirma que desea eliminar este número?',
        VERIFY_PHONE_NUMBER: 'Por favor verifique antes de agregar otro número.',
        PHONE_LIMIT: 'No puede agregar más de 3 números de teléfono.',
        VERIFICATION_CODE: 'En el prompt, por favor ingrese el codigo de verificacion.',
        VERIFICATION_MESSAGE_1: 'Vamos a llamar',
        VERIFICATION_MESSAGE_2: 'para verificar el número.',
        FIELDS_ADD_LIMIT:'No puede agregar más de 3 campos.',
        SETUP_UPDATED_SUCCESSFULLY:'Actualizado con éxito.',
        SETUP_UPDATED_FIELDS:'Actualización fallida.'
    },
    CALL_SCRIPT: {
        BLANK_NAME: 'Por favor ingrese un nombre para este script.',
        BLANK_DETAILS: 'Por favor ingrese los detalles del script.',
        SAVE_SUCCESS: 'Usted ha guardado satisfactoriamente este script.',
        UPDATE: 'Ha actualizado con éxito esta secuencia de comandos.',
        DELETE: 'Usted ha eliminado exitosamente este script.',
        NO_RECORD: 'No hay registro disponible',
        'DUPLICATE_SCRIPT_NAME': 'Este nombre de script ya existe. El nombre de secuencia de comandos duplicado no está permitido.',
        'EMPTY_SCRIPT_NAME_DETAILS': 'El nombre del script y los detalles del script no pueden estar vacíos.',
        'RECORD_NOT_FOUND_UPDATE.': 'Registro no encontrado para actualizar.',
        DELETE_CONFIRMATION: '¿Estás seguro de borrar este script?'
    },
    SMS_INTEGRATION: {
        PURCHASED_NUMBER: 'Usted ha comprado exitosamente el número',
        SELECT_USER: 'Seleccione al menos un representante de ventas para continuar.',
        ENTER_ZIP_OR_AREA: 'Por favor ingrese un código postal o código de área.',
        ENTER_AREA: 'Por favor, introduzca un código de campo.',
        ENTER_VALID_ZIP: 'Por favor ingrese un código postal válido.',
        ENTER_VALID_AREA_CODE: 'Por favor ingrese un código de área válido.',
        NO_AVAILABLE_PHONE_NUMBERS: 'No hay un número de teléfono disponible para el Código postal / de área ingresado.',
        NO_AVAILABLE_PHONE_NUMBERS_FOR_ZIP: 'No hay un número de teléfono disponible para el código de área ingresado.',
        REMOVE_NUMBER_CONFIRMATION: 'Estás seguro de eliminar este número?',
        SMS_SUPPORT: 'El envío de mensajes de texto no se admite en su país.',
        SELECT_NUMBER: 'Seleccione al menos un número para importar',
        SELECT_AT_LEAST_ONE_NUMBER:'Please select at least one number to import',
        TWILIO_NUMBER_IMPORTED_SUCCESS:'Su(s) número(s) de twilio se importaron exitosamente',
        TWILIO_NUMBER_IMPORTED_ERROR:'No se pudieron importar sus números de twilio.',
        PHONE_NUMBER_CANNOT_BE_PURCHASED:'El número de teléfono no se puede comprar. Comuníquese con el soporte de Soffront para obtener más detalles.',
        ENTER_SMS_TEXT: 'Por favor ingrese el texto de sms.',
        SMS_LOW_BALANCE_1: 'Su balance de sms es ahora ',
        SMS_LOW_BALANCE_2: 'Necesita comprar créditos de sms para enviar sms.',
        TWILIO_NUMBER_PURCHASE_MSG_1: 'Su cuenta será facturada',
        TWILIO_NUMBER_PURCHASE_MSG_2: 'mes.',
        TWILIO_NUMBER_PURCHASE_MSG_3: 'para este numero de telefono Puede eliminar el número de su cuenta en cualquier momento para detener la facturación.',
        SMS_TOOLTIP_MESSAGE: 'Buscar por código postal o área',
        SMS_TOOLTIP_MESSAGE_AREA_CODE: 'Buscar por código de área',
        INSERT_IMAGE_FILE: 'Por favor seleccione solo archivos jpg, jpeg, gif o png',
        BATCH_SEND_SMS_MESSAGE: 'Mensaje enviado con éxito',
        'INSUFFICIENT_BALANCE': 'Balance de SMS insuficiente',
        'BATCH_SMS_SENT': 'Mensaje enviado con éxito',
        'BATCH_SMS_FAILS': 'Error al enviar mensaje de texto',
        NO_FROM_NUMBER_MESSAGE: 'Deberá comprar un número de teléfono para enviar mensajes de texto. Compre el número de teléfono en el menú Integraciones.',
        MAX_ATTACHMENT_FILE_FOR_SMS: 'Máximo 10 archivos adjuntos permitidos.',
        'NO_INPUT_STREAM_FOUND': 'No se encontró flujo de entrada',
        'NO_ATTACHMENT_FOUND': 'Ningún archivo adjunto encontrado',
        'FILE_SIZE_ERROR': 'El tamaño del archivo debe ser inferior a 5 MB.',
        IMAGE_SELECT: 'Selecciona una imagen',
        MERGE_FIELD: 'Seleccionar campos de combinación',
        DUPLICATE_FILE_NAME: 'Este archivo ya existe. Por favor seleccione un archivo diferente.',
        RECORD_UNSUBSCRIBE_ALERT: 'El registro seleccionado se da de baja de los textos. No puede enviar mensajes de texto.',
        UNSUBSCRIBE_LIST_CONFIRM_MSG: 'fuera de los registros seleccionados se ha dado de baja de los textos. El mensaje de texto no se puede enviar a los registros no suscritos. Presione OK para continuar.',
        UNSUBSCRIBE_LIST_CONFIRM_MSG_1: 'fuera de los registros seleccionados se han dado de baja de los textos. El mensaje de texto no se puede enviar a los registros no suscritos. Presione OK para continuar.',
        'BATCH_SMS_SCHEDULE': 'El mensaje de texto ha sido programado exitosamente',
        'BATCH_SMS_SCHEDULE_FAIL': 'Error al programar mensaje de texto',
        BATCH_SEND_SMS_MSG_1: 'Fuera de la',
        BATCH_SEND_SMS_MSG_2: 'ha seleccionado, sólo',
        BATCH_SEND_SMS_MSG_3: 'han optado por recibir su mensaje de texto y tiene el número móvil. y pertenece a',
        BATCH_SEND_SMS_MSG_4: '. Enviaríamos el mensaje de texto sólo a estos',
        BATCH_SEND_SMS_MSG_5: 'Pulse OK para continuar.',
        SINGLE_SEND_SMS_MSG_1: 'Compruebe si está seleccionado',
        SINGLE_SEND_SMS_MSG_2: 'ha optado por recibir sus mensajes de texto o tiene el número de móvil. o pertenece a',
        RESCHEDULE_SUCCESS: 'SMS se ha reprogramado con éxito',
        AUTOMATION_SMS_ADD_MSG: 'Has guardado correctamente este mensaje de texto',
        WORKFLOW_STATUS_MSG: 'Tiene uno o más flujos de trabajo activos que utilizan este número para enviar mensajes de texto. Actualice esos flujos de trabajo para usar un número diferente antes de eliminar este número.',
        SCHEDULED_STATUS_MSG: 'Tiene uno o más sms preparados que utilizan este número. Haga clic en Aceptar para cancelar todos sus sms preparados y eliminar el número.',
        REMOVE_SUCCESS_MSG: 'El número se ha eliminado correctamente.',
        NUMBER_SAVED_SUCCESS_MSG: 'Número guardado correctamente.',
        NO_LINKED_MOBILE_NUMBER_MSG:'El registro asociado no tiene número de móvil. No puede enviar mensajes de texto.',
        NUMBER_INVALID_MSG:"¡El número de móvil del contacto es incorrecto!",
        CANNOT_DELETE_UNITS_NUMBER: 'No se le permite eliminar este número ya que este número ha sido asignado a una Unidad.',
    },
    EVENTBRITE: {
        NO_ORGANISATION_SELECTED: 'Vui lòng chọn ít nhất một tổ chức',
        NO_MAPPING_FOUND: 'No hay mapeo para CRM Contact y Eventbrite Fields.'
    },
    QUICKBOOKS: {
        NO_MAPPING_FOUND: 'No hay asignación para el campo Cliente de cuentas CRM y libros rápidos.'
    },
    XERO: {
        SELECT_ONE_INTEGRATION_ITEM_AND_TYPE: 'Seleccione un elemento y un tipo de integración para integrar.',
        SELECT_ONE_INTEGRATION_ITEM: 'Seleccione un elemento para integrar.',
        SELECT_INTEGRATION_TYPE: 'Seleccione el tipo de integración.',
    },
    REPORT_SCHEDULE_DIALOG: {
        START_DATE_BLANK: 'La fecha de inicio no puede estar en blanco.',
        INVALID_END_DATE: 'La fecha de inicio no puede ser mayor o igual que la fecha de finalización.',
        DAY_CHECKBOX_BLANK: 'Al menos revisa un día.',
        SEND_TO_BLANK: 'Al menos Seleccione un usuario.',
        SAVE_SUCCESS: 'Guardado correctamente.'
    },
    DOCUMENT_PUSH: {
        ALERT_MSG_1: 'Por favor, seleccione al menos una empresa para empujar documentos',
        DOCUMENTS: 'Documentos)',
        FAIL: 'Error al empujar'
    },
    OPERATIONS_REPORT: {
        NO_CUSTOM_REPORT_MESSAGE: 'No tienes ningún informe personalizado.'
    },
    PINNED_NOTES: {
        CONFIRM_MESSAGE: 'Esto reemplazará la nota fija existente. Haga clic en Aceptar para continuar o Cancelar para descartar.'
    },
    ASSIGNMENT_RULES: {
        NO_ASSIGNMENT_RULES: 'No hay reglas disponibles.',
        NO_NAME: 'Proporcione el nombre de la regla de asignación.',
        NAME_MAX_LENGTH: 'El nombre de la regla de asignación no puede tener más de 64 caracteres.',
        NO_CRITERIA: 'Por favor, proporcione al menos un criterio.',
        NO_USER: 'Por favor, proporcione al menos un usuario.',
        RULE_SAVE_SUCCESS: 'Regla de asignación guardada correctamente.',
        RULE_NAME_EXISTS: 'El nombre ya existe!',
        ACTIVE_RULE_EXCEEDS: 'Ya tiene 60 reglas de asignación activas.',
        SELECT_UNIT_MAXIMUM_FIELD: 'Puede seleccionar solo una unidad',
        SELECT_A_UNIT_WORKFLOW: 'Seleccione un flujo de trabajo de la unidad.',
        NO_UNIT_USER: 'proporcione al menos una unidad.'
    },
    SETTINGS: {
        EMPTY_NAME: 'Por favor escribe',
        DUPLICATES_SOURCE_NAME: '¡El nombre de la fuente ya existe!',
        CORPORATE_CURRENCY: 'La moneda seleccionada se ha actualizado con éxito.',
        INVALID_CURRENCY_NAME: 'Por favor, seleccione un nombre de moneda corporativa válida',
        SYSTEM_NOTE_TYPE_EDIT_ALERT: 'No puede editar este tipo de nota.',
        SYSTEM_NOTE_TYPE_DELETE_ALERT: 'No puede borrar este tipo de nota.',
        SYSTEM_CAMPAIGN_TYPE_DELETE_ALERT: 'No puede eliminar este tipo de campaña.',
        CORPORATE_CURRENCY_ALERT: 'Por favor seleccione una moneda corporativa.',
        CORPORATE_CURRENCY_CONFIRM: 'Se guardará la moneda seleccionada. ¿Estás seguro?',
        'CORPORATECURRENCY': 'Seleccione una moneda corporativa',
        'DUPLICATE_NOTE_TYPES': 'Este tipo de nota ya existe. No se permite la creación de tipos de notas duplicados.',
        'INCORRECT_RECORD_ID': 'Registro incorrecto Id. Actualización fallida.',
        'RECORD_DOES_NOT_EXISTS': 'El registro no existe. La operación de eliminación ha fallado.',
        'RECORD_CANNOT_DELETED': 'Los tipos de notas predeterminadas o del sistema no se pueden eliminar.',
        'DUPLICATE_CAMPAIGN_TYPES': 'Este tipo de campaña ya existe. No se permite la creación de tipos de campaña duplicados.',
        'DUPLICATE_RELATIONS': '¡El nombre de la relación ya existe!',
        RELATION_LINK_INFO_ALERT_MSG: 'Hay registros que siguen esta relación. Desvincula primero los registros respectivos que siguen a esta relación y luego podrás eliminar esta relación.'
    },
    RECYCLE_BIN: {
        SELECT_WORKFLOW: 'Por favor seleccione un flujo de trabajo',
        SELECT_STAGE: 'Por favor seleccione una etapa',
        NOTE: 'Nota: Los registros vinculados del registro original no se restaurarán.',
        RESTORE: 'Grabar restaurar con éxito'
    },
    UNSUBSCRIBE: {
        SELECTED_RECORD: 'Este registro se dará de baja de ',
        ARE_YOU_SURE: '. ¿Estás seguro?',
        SELECTED_BOTH_RECORD: 'Este registro se dará de baja de correos electrónicos y textos. ¿Estás seguro?',
        'EMAILS_UNSUBSCRIBE': ' será dado de baja de correos electrónicos. ¿Estás seguro?',
        'TEXTS_UNSUBSCRIBE': ' será dado de baja de los textos. ¿Estás seguro?',
        'BOTHS_UNSUBSCRIBE': ' será dado de baja de correos electrónicos y textos. ¿Estás seguro?',
    },
    WORKFLOWS: {
        STATUS_CHANGED: 'El estado ha cambiado a ',
        'SAME_STATUS': 'Grabar ya en',
        'FAILED_TO_CHANGE_WORKFLOW_STATUS': 'no se pudo cambiar el estado del flujo de trabajo.',
        ONLY_ONE_ACTIVE_WORKFLOW: 'No puede hacer que este flujo de trabajo esté inactivo. Este es el único flujo de trabajo activo disponible. Una tabla debe tener al menos un flujo de trabajo activo.',
        ONLY_ONE_ACTIVE_WORKFLOW_CANNOT_DELETE: 'No puede eliminar este flujo de trabajo. Este es el único flujo de trabajo activo disponible. Una tabla debe tener al menos un flujo de trabajo activo.',
        DELETED_WORKFLOW: 'El flujo de trabajo no existe. Seleccione Aceptar para elegir un nuevo flujo de trabajo.',
        STATUS_BLANK_ALERT:'el estado no puede estar en blanco',
        SALES_REP_BLANK_ALERT : 'Sales_rep no puede estar en blanco',
    },
    CHANGE_LOGO: {
        SELECT_IMAGE_TO_UPLOAD: 'Seleccione una imagen para cambiar logo',
        LOGO_IMAGE_UPLOAD_FAILURE: 'No se pudo guardar el logotipo de la empresa.',
        LOGO_IMAGE_UPLOAD: 'Logotipo de la empresa guardado con éxito.',
        VALIDATE_LOGO_IMAGE: 'Seleccione un archivo de imagen.',
        VALIDATE_LOGO_IMAGE_SIZE: 'La foto que has seleccionado es demasiado grande para usar. El tamaño máximo de archivo permitido es de 500 KB.',
        LOGO_IMAGE_ATTACHMENT_LOGO_TEXT: 'Puedes subir un archivo jpg, gif o png.',
        LOGO_IMAGE_SIZE_TEXT: ' El tamaño máximo de archivo permitido es de 500 KB.',
        WEBSITE_URL: 'Sitio web',
        VALIDATE_LOGO_WEBSITE_URL: '¿Te gustaría subir el logo sin ninguna URL válida?'
    },
    ACCOUNTS: {
        DUPLICATE_MAIL_DOMAIN: 'Hay una cuenta existente para esta empresa. ¿Desea agregar un contacto?'
    },
    COUPONS: {
        CREATE_COUPON_NAME: 'Crea un nombre interno para tu cupón',
        CREATE_DISCOUNT_TYPE: '¿Qué tipo de descuento te gustaría crear?',
        FIXED_AMOUNT: 'Cantidad fija',
        PERCENTAGE: 'Porcentaje',
        PREFIX_COUPON_CODE: 'Especifique un prefijo para el código de cupón',
        CAN_CLAIM_MORE_THAN_ONCE: '¿Se puede usar más de una vez por la misma cuenta?',
        CALIM_LIMIT_ONE: 'Sólo una vez',
        CLAIM_LIMIT_UNLIMITED: 'Ilimitado',
        WHYEN_CLAIM_CODE: 'Fecha de vencimiento del cupón',
        CLAIM_ANYTIME: 'Nunca expira',
        CLAIM_BEFORE_DATE: 'Fecha de caducidad',
        SAVE_SUCCESSFULLY: 'Cupón guardado con éxito',
        ACTIVE_SUCCESSFULL: 'El estado ha sido cambiado a activo.',
        INACTIVE_SUCCESSFULL: 'El estado ha sido cambiado a Inactivo.',
        DELETE_CONFIRMATION: 'Los cupones serán eliminados. ¿Estás seguro?',
        INVALID: 'es un inválido',
        DUPLICATE_COUPONS: 'El nombre del cupón ya existe.',
        NEGATIVE_COUPONS: 'No puede ser negativo.',
        SPECIFY_DATE: 'Por favor especifique una fecha.',
        PODUCT_SERVICE: 'Producto / Servicio',
    },
    NOTIFICATION_REMINDERS: {
        SAVE_SUCCESS: 'Recordatorio añadido con éxito',
        INVALID_DAY: 'Introduzca un día(s) válido(s)',
        DUPLICATE_REMINDER: 'Esto es recordatorio duplicado, por favor cambie',
        INVALID_DATE_FIELD: 'Seleccione un campo Fecha',
        DELETE_SUCCESS: 'Recordatorio eliminado correctamente',
        UPDATE_SUCCESS: 'Recordatorio actualizado con éxito',
    },
    EMAIL_INTEGRATION: {
        DUPLICATE_EMAIL: '¡Esta dirección de correo electrónico ya existe!',
        EMAIL_SAVE_SUCCESS: 'Dirección de correo electrónico guardada con éxito',
        SEND_VERIFICATION_EMAIL_SUCCESS_1: 'Hemos enviado el correo electrónico de verificación. Por favor, compruebe su bandeja de entrada y haga clic en ',
        SEND_VERIFICATION_EMAIL_SUCCESS_2: 'Verificar esta dirección de correo electrónico. ',
        SEND_VERIFICATION_EMAIL_SUCCESS_3: 'Luego vuelve y haz clic en el icono de actualización para ver el estado de tu dirección de correo electrónico.',
        EMAIL_INTEGRATION_VERIFIED: '¡Esta dirección de correo electrónico ya ha sido verificada!',
        CONFIRM_DELETE_EMAIL: 'Se eliminarán las direcciones de correo electrónico seleccionadas. ¿Está seguro?',
        INVALID_EMAIL: 'Dirección de correo electrónico no válida',
        EMAIL_CANT_BLANK: '¡La dirección de correo electrónico no puede estar en blanco!',
        EDIT_EMAIL_SAVE_SUCCESS: 'Correo electrónico guardado correctamente',

    },
    NEWS: {
        SAVE_ERROR: 'No se puede guardar.',
        VALIDATE_CATEGORY: 'La categoría no puede estar en blanco.',
        VALIDATE_STATUS: 'El estado no puede estar en blanco.',
        VALIDATE_TITLE: 'El título no puede estar en blanco.',
        VALIDATE_SUMMARY: 'El resumen no puede estar en blanco.',
        VALIDATE_EXTERNAL_LINK: 'El enlace externo no puede estar en blanco.',
        VALIDATE_DETAILS: 'Los detalles no pueden estar en blanco.',
        CATEGORY_NAME_EXISTS: 'El valor ya existe en la Lista.',
        DUPLICATE_TITLE: "¡Este título ya existe!",
    },
    USEFUL_LINKS: {
        CATEGORY: 'Categoría',
        LINK: 'Enlace',
        TITLE: 'Título',
        VALID_CATEGORY: 'Seleccione una categoría válida',
        DUPLICATE_CATEGORY: 'Esta es categoría duplicada, por favor cambie',
        DUPLICATE_TITLE: "Il s'agit d'un titre en double dans la même catégorie, veuillez le modifier.",
        DUPLICATE_CATEGORY_MSG:'La categoría ya existe con el mismo título. Cambie el título de la categoría.',
        CONFIRM_DELETE_CATEGORY: 'Se eliminará la categoría. ¿Estás seguro?',
        CONFIRM_DELETE_LINK: 'El enlace se eliminará. ¿Estás seguro?',
        VALID_URL: 'Introduce una URL válida.',
        EXISTING_LINKS_CATGORY: 'Tiene vínculos existentes en esta categoría. ',
        PLEASE_MOVE_LINKS: 'Por favor, mueva los enlaces a',
        CLICK_TO_CONFIRM: ' Haga clic en Aceptar para confirmar.',
    },
    CASE: {
        CASE_CLOSED_SUCCESS: 'Caso cerrado con éxito'
    },
    ORTHOTIC_REPORT: {
        VALIDATE_EMPTY_PRODUCT: 'Por favor seleccione un producto.'
    },
    USER_REPORT: {
        REPORT_DELETE_RECORD: 'El informe será eliminado. ¿Estás seguro?',
        REPORT_DELETE: 'Informe eliminado con éxito',
        INVALID_START_END_DATE: 'La fecha de inicio no puede ser mayor que la fecha de finalización.'
    },
    AUTO_FILL_VALUES: {
        CHNAGE_PARENT_OBJECT: '¿Realmente desea cambiar el objeto padre?',
        CHANGE_CHILD_OBJECT: '¿Realmente desea cambiar el objeto secundario?',
        SELECT_PARENT_OBJECT: 'Seleccione un objeto primario.',
        SELECT_CHILD_OBJECT: 'Seleccione un objeto secundario.',
        NO_MAPPING_FIELDS: 'No hay campos de mapeo disponibles entre ',
        NO_MAPPING_FIELDS_AND: 'Y',
        CHILD_FIELD_SIZESHOULD_GREATER: 'El tamaño del campo secundario debe ser mayor que el tamaño del campo primario.',
        MAPPING_FROM: 'Mapeo de',
        MAPPING_TO: ' Para ',
        NO_AVAILBLE_FIELDS_FOR_MAPPING: '. No hay ningún campo común disponible para la asignación predeterminada.',
        NO_AVAILABLE_PARENT_CHILD_MAPPING: 'No hay ninguna asignación de campos padre-hijo disponible.',
        FIELD_MAPPING_DONE_SUCCESS: 'Asignación de campos realizada correctamente.',
        MAP_FIELDS_TO_AUTOFILL: 'Asignar los campos para autocompletar ',
        MAP_FIELDS_TO_AUTOFILL_FROM: ' De ',
        SELECT_PARENT_FIELD: 'Seleccione un campo Padre.',
        SELECT_CHILD_FIELD: 'Seleccione un campo Secundario.',
        MAPPING_NOT_POSSIBLE: ' no es posible.'
    },
    LANDING_PAGE: {
        INVALID_NAME: 'Por favor, introduzca el nombre de la página de destino.',
        INVALID_URL_WARNING: 'Por favor, introduzca el nombre de la URL.',
        SAVE: 'La página de destino se guardó correctamente.',
        UPDATE: 'La página de destino se actualizó correctamente.',
        INVALID_FORM_BLOCK_EDIT_MSG: 'El bloque activo actual no tiene campos de formulario para editar, seleccione el bloque de campos de formulario para editar.',
        DUPLICATE_NAME: 'La página de destino ya existe con este nombre. Ingrese un nombre diferente e intente nuevamente.',
        DUPLICATE_URL: 'La página de destino ya existe con esta URL. Ingrese un nombre diferente e intente nuevamente.',
        INPUT_DATA_LOSS_MSG: 'Todos los datos ingresados ​​serán borrados. ¿Estás seguro?',
        EMAIL_REQUIRED_MSG: 'Correo electronico es requerido.',
        ATLEAST_ONE_BLOCK_ADD_MSG: 'Agregue al menos un bloque de diseño para guardar esta página.',
        PREVIEW_ALERT_MSG: 'Guarde antes de obtener una vista previa de la página de destino.',
        OPEN_URL_ALERT_MSG: 'Enlace de la página de destino copiado al portapapeles.',
        COPY_TO_CLIPBOARD: 'Enlace de la página de destino copiado al portapapeles.',
        FORM_ADDED_MSG: 'Formulario agregado con éxito.',
        FORM_UPDATED_MSG: 'Formulario actualizado con éxito.',
        INVALID_URL: 'URL invalida.',
        LABEL_FIELD_CANNOT_BLANK: 'el campo de la etiqueta no puede estar en blanco.',
        FIELD_IS_ALREADY_ADDED: 'El campo ya está agregado.',
        MINIMUM_ONE_FIELD: 'Se requiere un campo mínimo.',
        NO_MORE_FIELD_AVAILABEL: 'No hay más campos disponibles.'
    },
    DOWNLOAD_LEADS: {
        SELECT_CRITERIA: 'Seleccione al menos un criterio.',
        SELECT_WORKFLOW: 'Seleccione un flujo de trabajo.',
        SELECT_GROUP: 'Seleccione un grupo.',
        SELECT_STATUS: 'Seleccione el estado.',
        SELECT_SALESREP: 'Seleccione un representante de ventas.',
        LEAD_EXIST_MSG_1: 'Ya tiene estos clientes potenciales en su cuenta - ',
        LEAD_EXIST_MSG_2: '. Haga clic en Aceptar para detener la descarga y eliminar estos clientes potenciales de la selección.'

    },
    SCHEDULER: {
        BLANK_APPOINTMENT_NAME: 'Por favor, introduzca el nombre de la cita.',
        BLANK_LOCATION: 'Por favor, introduzca la ubicación.',
        SAVE_SUCCESS: 'La configuración del planificador se ha guardado correctamente.',
        SAVE_FAIL: 'No se pudo guardar la configuración del programador.',
        NO_SCHEDULER: 'Por favor, cree un programador de citas antes de usar el enlace del programador.',
        VALIDATE_TIME: 'La hora de inicio no puede ser mayor o igual que la hora de finalización.',
        NO_START_TIME: 'Seleccione la hora de inicio.',
        NO_END_TIME: 'Seleccione la hora de finalización.',
        SCHEDULER_LINK_COPIED_SUCCESS: 'Vínculo del programador copiado al portapapeles.',
        BLANK_SUBMIT_BUTTON: 'La etiqueta del botón no puede estar en blanco.',
        CALENDAR_SYNC_MSG: 'Tu calendario CRM no está conectado a tus calendae s.g.o Office 365.',
        CALENDAR_SYNC_LINK: 'Haga clic aquí para configurarlo.',
        SCHEDULER_UNSAVE_msg: '¿Quieres dejar este formulario? Los cambios realizados no se guardarán.',
    },
    RELATION_REPORT: {
        RANGE_FIELD_BLANK: 'Por favor seleccione un campo de fecha.',
        ROW_FIELD_BLANK: 'Por favor seleccione un campo de fila.',
        COL_FIELD_BLANK: 'Por favor seleccione un campo de columna.',
        REPORT_NAME_BLANK: 'Por favor, introduzca el nombre del informe.',
        REPORT_SAVE_SUCCESS: 'Informe guardado con éxito.',
        DUE_BY_BLANK: 'Por favor seleccione un rango de fechas.',
        NO_ROW_FIELD_VALUE: 'Seleccione un valor para la etiqueta de fila.',
        NO_COLUMN_VALUE: 'Seleccione un valor para el encabezado de columna.',
        SAME_ROW_COLUMN_FIELD: 'Los campos de fila y columna no pueden ser iguales.',
        INVALID_END_DATE: 'La fecha de inicio no puede ser mayor que la fecha de finalización.',
        'Report Name already exists': 'El nombre del informe ya existe.',
        VALID_REPORT_NAME: 'Ingrese un nombre de informe válido',
    },
    PAYMENTS: {
        NOT_ADDED_QB: '¡La cotización/factura aún no se ha añadido al Libro Rápido!',
        NO_LINKED_AVAILABLE: '¡No hay ninguna cotización/factura vinculada disponible!',
        PAYMENT_VALUE_ALERT_MSG: 'El valor de pago no puede ser cero.',
        RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD: 'Tiene una pestaña de cotización / factura abierta. Cierre esa pestaña antes de abrir o agregar una nueva cotización / factura.',
        AMOUNT_EXCEED_BALANCE_DUE: 'El pago que está introduciendo supera el saldo adeudado por esta factura. Haga clic en Aceptar para continuar con el pago. Haga clic en Cancelar para cambiar el importe del pago.'

    },
    PRODUCTS: {
        DUPLICATE_PRODUCT: 'El nombre del producto ya existe.',
        ADDED_TO_QUICKBOOK: 'Añadido correctamente a QuickBooks.',
        PULLED_FROM_QUICKBOOKS: 'Extraído con éxito de Quickbooks.',
        PRODUCT_PRICE_VALUE_ALERT_MSG: 'El valor del precio no puede ser cero.',
        PRODUCT_NAME_COULDNOT_CONTAIN_COLON_MSG: 'no puede contener dos puntos (:).',
        NEGATIVE_PRICE: 'El precio no puede ser negativo para el tipo de inventario.'
    },
    COMPANY_PROFILE: {
        BLANK_BUSINESS_NAME: 'Introduzca el nombre de la empresa.',
        BLANK_BUSINESS_ADDRESS: 'Introduzca la dirección comercial.',
        BLANK_BUSINESS_PHONE: 'Por favor, introduzca el teléfono de negocios.'
    },
    EXPORT_SETTINGS: {
        CONSIDERATION: 'Consideración:',
        CONSIDERATION_MSG1: 'Admitimos archivos con formato EXCEL y CSV.',
        CONSIDERATION_MSG2: 'Una celda en Excel puede contener un máximo de 32,767 caracteres, por lo tanto, en el archivo de Excel exportado, mostramos un máximo de 32,767 caracteres para una celda y excluimos el resto de los caracteres.',
        NO_RECORDS_FOUND: 'No se encontraron registros!',
        FILE_NAME_IS_EMPTY: 'El nombre del archivo está vacío.',
        PLEASE_SELECT_THE_FILE_TYPE: 'Seleccione el tipo de archivo.',
        PLEASE_SELECT_THE_PARENT_OBJECT: 'Seleccione el objeto principal.',
        PLEASE_SELECT_THE_QUERY_NAME: 'Por favor seleccione el nombre de la consulta.',
        PLEASE_SELECT_THE_PARENT_FIELDS: 'Seleccione los campos principales.'
    },
    DATA_BASE: {
        FAILURE_MSG: 'Error al guardar.',
        SUCCESS_MSG: 'Su configuración se ha guardado correctamente. Cierre la sesión y vuelva a iniciarla para ver los cambios.',
        VALIDATION_MSG: 'Seleccione al menos un objeto secundario para ',
        VALIDATION_MSG1: "Alcanzaste el límite máximo de 15 objetos seleccionados.",
        SUCCESS_MSG1: 'Su configuración se ha guardado correctamente.',
    },
    UNITS: {
        BLANK_FIRST_NAME: 'Ingrese el nombre.',
        BLANK_LAST_NAME: 'Ingrese el apellido.',
        BLANK_PASSWORD: 'Por favor, ingrese contraseña.',
        UNMATCH_RETYPE_PASSWORD: 'Vuelva a escribir la contraseña no coincide con la contraseña.',
        BLANK_RETYPE_PASSWORD: 'Ingrese nuevamente la contraseña.',
        BLANK_EMAIL: 'Ingrese el correo electrónico.',
        BLANK_PHONE: 'Ingrese el teléfono.',
        BLANK_COMPANY: 'Introduzca empresa.',
        UNIT_SAVED: 'Guardado exitosamente.',
        UNIT_NOT_SAVED: 'No se pudo guardar la unidad.',
        DUPLICATE_EMAIL: 'Ya tiene registro (s) con la misma identificación de correo electrónico.',
        DUPLICATE_COMPANY: 'El nombre de la empresa ya existe.',
        SUBSCRIPTION_NOT_VALID: 'La suscripción no es válida para la unidad seleccionada. Comuníquese con el soporte de Soffront para obtener más detalles.',
        NO_UNIT_TASKS: 'No hay ninguna tarea disponible para el propietario de la unidad.',
        NO_ACCOUNT_TASKS: 'No hay ninguna tarea disponible para la cuenta.',
        BLANK_FAMILY_INDUSTRY: 'Seleccione una industria familiar',
        NO_FAMILY_INDUSTRY: 'Necesitaría una plantilla base antes de poder agregar nuevas unidades. Comuníquese con el soporte de BrandWide para configurar una plantilla base.',
        BLANK_PLAN_NAME: 'Seleccione un nombre de plan.',
        NO_AVAILABLE_PLANS: 'No hay planes disponibles que puedan inscribirse para esta unidad.',
        NO_BRANCH_WORKFLOW: 'Es sind keine Filial-Workflows verfügbar.',
        PLEASE_SELECT_TYPE: 'Seleccione un tipo para continuar.',
        NO_BASE_LOGIN: '¡La base no existe o no está configurada! Solucione el problema y vuelva a intentarlo.',
    },
    SHARE: {
        SHARE_SUCCESS_MSG_1: 'La información de uso compartido se actualiza correctamente.',
        SHARE_SUCCESS_MSG_SL: 'La información compartida se actualiza correctamente para los niveles de seguridad seleccionados, si los hay.',
        SHARE_SUCCESS_MSG_2: 'La notificación por correo electrónico se enviará a las unidades recién seleccionadas si las hay.',
        SHARE_SUCCESS_MSG_3: 'La notificación por correo electrónico se enviará a la unidad recién seleccionada si la hay.',
        SHARE_SUCCESS_MSG_4: '¿Estás seguro?',
        SHARE_SUCCESS_MSG_5: 'se compartirán con la unidad seleccionada.',
        SHARE_SUCCESS_MSG_6: 'se compartirán con las Unidades seleccionadas.',
        SHARE_FAILED_MSG: 'No se pudo compartir.',
        SHARE_SELECT_CORPORATE_DOCUMENT: 'Seleccione al menos un usuario corporativo para compartir documentos.',
        SHARE_SELECT_CORPORATE_IMAGE: 'Seleccione al menos un usuario corporativo para compartir imagen.',
        SHARE_SELECT_UNIT_DOCUMENT: 'Seleccione al menos una unidad para compartir documentos.',
        SHARE_SELECT_UNIT_IMAGE: 'Seleccione al menos una unidad para compartir imagen.',
        NO_CONNECTED_CLIENT: 'No hay clientes conectados disponibles para compartir documentos.',
    },
    SECURITY_LEVEL: {
        ERROR_MSG_1: '¡Ups! Algo salió mal. Por favor intente mas tarde.',
        ERROR_MSG_2: 'La descripción no puede estar en blanco.',
        SUCCESS_MSG_1: 'Su configuración se ha guardado correctamente.',
    },
    ROYALTY_REPORT: {
        BLANK_ROYALTY_PERCENT: 'Por favor, introduzca el porcentaje de regalías.',
        REPORT_SAVE_SUCCESS: 'Informe guardado correctamente.',
        SETUP_SAVE_SUCCESS: 'La configuración de regalías se guardó correctamentey.'
    },
    MENU: {
        ATLEAST_ONE_MODULE_ACTIVE: 'Lo sentimos, al menos un módulo debe estar activo.',
        ATLEAST_ONE_MODULE_ITEM_ACTIVE: 'Lo sentimos, al menos un elemento del módulo debe estar activo.',
        MODULE_LABEl_CHAR_LIMIT_MSG: 'El nombre no puede superar los 32 caracteres.',
        DUPLICATE_NAME: 'El nombre del módulo ya existe.'
    },
    SALES_PURCHASE_ORDER: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_PURCHSE_RECORD: 'Tiene una pestaña de orden de compra abierta. Cierre esa pestaña antes de agregar una nueva orden de compra.',
        RESTRICT_TO_OPEN_TWO_OR_MORE_SALES_RECORD: 'Tiene una pestaña de orden de venta abierta. Cierre esa pestaña antes de agregar un nuevo pedido de venta.',
        SAVE_PURCHASE_SUCCESS: 'Orden de compra guardada correctamente.',
        SAVE_SALES_SUCCESS: 'Pedido de venta guardado correctamente.',
        SALES_REP_NAME_CANT_BLANK: 'El nombre del representante de ventas no puede dejarse en blanco.',
        SELECT_ONE_ITEM: 'Seleccione la información de un artículo.',
        DISCOUNT_AMOUNT_CANT_GREATER: "El monto del descuento no puede ser mayor que el subtotal.",
        SPECIFY_TRANSC_AMOUNT: 'Debe especificar un monto de transacción que sea 0 o mayor.',
        SETUP_COMPANY_PROFILE: 'Configure el perfil de su empresa antes de agregar cotizaciones. Comuníquese con su administrador de CRM para configurar el perfil.'

    },
    JOBS: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_JOBS_RECORD: 'Tiene una pestaña de trabajo abierta. Cierre esa pestaña antes de agregar un nuevo trabajo.',
        FIELDS_CANNOT_BLANK: ' el campo no puede estar en blanco.',
        PRODUCT_NAME_NOT_CONTAIN: 'El nombre del producto no puede contener dos puntos (:).',
        JOB_SAVE_SUCCESS: 'Trabajo guardado correctamente.',
        PRODUCT : 'Producto',
        ALREADY_EXIST: 'ya existe.',

    },
   POWER_CALLING :{
        ALERT: {
            WARNING: 'Are you sure',
        },
        YOU_DONT_HAVE_CALL_SCRIPT: "No tienes guiones de llamadas.",
        ALERT_MSG_1: 'Fuera de ',
        ALERT_MSG_2: ' has seleccionado, solo ',
        ALERT_MSG_3: ' tener números locales válidos. Podrás llamar a esos ',
        ALERT_MSG_4: 'Habló con ',
        ALERT_MSG_5: "No hay más",
        ALERT_MSG_6: "en la cola para realizar una llamada.",
        ALERT_MSG_7: 'No tienes ninguna conexión activa.',
        ALERT_MSG_8: 'que ha seleccionado, ninguno de ellos tiene número de teléfono o móvil.',
        ALERT_MSG_9: 'Ya tiene un Power Dialer abierto, ciérrelo para abrir un nuevo Power Dialer.',
        ALERT_MSG_10 :'Para cerrar la pestaña, haga clic en el icono de cerrar en la parte superior de la pestaña.'
    },
    LEAD_SCORE_SETUP: {
        ALREDY_DEFINED_SCORE: 'Ya ha definido una puntuación para esta acción.',
        SAVE_SUCCESSFULLY: 'La puntuación se ha guardado correctamente.',
        CAN_NOT_UPDATE: 'La configuración de la puntuación no se puede actualizar. Modifique la configuración de la puntuación correctamente. ',
        SELECT_ONE_ACTION: 'Seleccione al menos una acción',
        SELECT_ACTION: 'Seleccione una acción',
        SELECT_OBJECT: 'Seleccione un objeto',
        SELECT_WORKFLOW: 'Seleccione un flujo de trabajo',
        CONVERT_FROM_CANT_BLANK: 'Converted From no puede estar en blanco',
        CONVERT_TO_CANT_BLANK: 'Converted To no puede estar en blanco',
        CHOOSE_DIFF_STATUS: 'No puede convertir el mismo estado. Elija un estado diferente. ',
        SELECT_GROUP: 'Seleccione un grupo',
        SELECT_FIELD: 'Seleccione un campo',
        PROVIDE_VALUE_TO_UPDATE: 'Proporcione un valor que desee actualizar',
        ENTER_SCORE: 'Introduzca una puntuación entre 1 y 99',
        EXIST_IN_LIST: 'El valor ya existe en la lista',
        SCORE_DELETED: 'Esta puntuación será eliminada. ¿Está seguro?',
        REMOVE_MSG: 'eliminar con éxito',
        UPDATE_MSG: 'actualizar correctamente'

    },
    DUPLICATE_RULE: {
        ERROR_MSG_DEFAULT: 'No se puede desactivar la regla por defecto.',
        ERROR_MSG_Active: 'No se puede Desactivado una regla por defecto.',
        ERROR_MSG_FOR_FIELDS: 'Seleccione todos los campos de un bloque determinado para guardar la regla.',
        ERROR_MSG_1: 'Algo salió mal. Por favor, inténtelo de nuevo más tarde.',
        SUCCESS_MSG_1: 'Su regla duplicada (s) se ha guardado correctamente.',
        ERROR_MSG_SAME_FIELD: ' ya está seleccionado. Por favor seleccione otro campo.',
    },
    REQUIRED_FORM: {
        FIELD_FILL_MESG:'Estos campos deben completarse antes de esta ',
        CAN_BE_SUBMITTED:' se puede enviar.'
    },
    GREENLIGHT: {
        COMPANY_NAME_BLANK: 'Introduzca un nombre de empresa.',
        COMPANY_NAME_REQUIRED: 'El nombre de la empresa no puede tener más de 30 caracteres.',
        ADDRESS1_REQUIRED: 'Se requiere la dirección 1.',
        ADDRESS1_ADDRESS2_LIMIT: 'Dirección1 + Dirección2 combinados no pueden tener más de 30 caracteres.',
        PHONE_NUMBER_REQUIRED: 'Por favor ingrese un número de teléfono válido.',
        PHONE_NUMBER_LIMIT: 'Los números de teléfono no pueden tener más de 10 dígitos.',
        NET_ANNUAL_LIMIT: 'Net Anual no puede estar en blanco y no puede ser cero.',
        NET_ANNUAL_INVALID: 'Ingrese una cantidad válida en Net Anual.'
    },
    AUDIT_QUESTIONS: {
        QUESTION_REQUIRED: 'Se requiere la pregunta',
        ENTER_VALID_SCORE: 'Por favor ingrese una puntuación válida',
        ENTER_VALID_CHOICES: 'Por favor ingrese opciones válidas',
        SUCCESS: 'Éxito',
        ERROR: 'Error',
        NO_QUESTIONS: "No se han encontrado preguntas",
        THANK_YOU: "¡Gracias!",
        SOMETHING_WRONG: "¡Algo salió mal mientras envía tus comentarios, inténtalo de nuevo!",
        FEATURE_NOT_AVAILABLE: 'Esta característica no está disponible.',
        RECEIVED_FEEDBACK_1: "¡Bien hecho! Ha guardado correctamente las respuestas de la ",
        RECEIVED_FEEDBACK_2:"categoría.",
        RECEIVED_FEEDBACK_3:"Recuerde, siempre puede actualizar sus respuestas para esta categoría antes del envío final.",
        MAIL_SENT_MESSAGE:'Correo enviado.',
        SUBMIT_AUDIT_1:'Hay preguntas obligatorias sin respuesta en las siguientes categorías: ',
        SUBMIT_AUDIT_2:'Responda las preguntas requeridas y luego envíe la auditoría.',
        SUBMIT_AUDIT_3:'¡Ha enviado con éxito su auditoría!',
        SUBMIT_AUDIT_4:'Puede ver las respuestas de auditoría enviadas y sus puntuaciones en CRM en HUB > Auditorías.',
        SUBMIT_AUDIT_5:'Recibirá una notificación por correo electrónico si el franquiciador proporciona algún comentario sobre su auditoría.',
        SUBMIT_AUDIT_6:'También puede ver los comentarios proporcionados por el franquiciador y tomar acciones correctivas desde HUB > Auditorías.',
        SAVE_ALERT_1:'¡Atención! Estás a punto de dejar esto.',
        SAVE_ALERT_2:'categoría sin guardar los cambios.',
        SAVE_ALERT_3:'Haga clic en Aceptar para continuar después de guardar, o cierre esta alerta para permanecer aquí y continuar con esta categoría.',
        REQUEST_CORRECTIVE_SUCCESS:'¡Gracias! Sus comentarios se han enviado por correo electrónico correctamente.',
        SUBMIT_CORRECTIVE_ACTION:'¡Gracias! La información de su acción correctiva se envió por correo electrónico correctamente.',
        EMPTY_REQUEST_CORRECTIVE_MESSAGE:'No ha agregado sus comentarios para ninguna de las preguntas.'+'\n'+'Primero agregue los comentarios y luego haga clic en "Solicitar acciones correctivas".',
        EMPTY_SUBMIT_CORRECTIVE_MESSAGE:'No ha agregado sus acciones correctivas a ninguno de los comentarios.' + '\n' + 'Primero agregue la acción correctiva y luego haga clic en "Enviar acciones correctivas".',
        EMPTY_AUDIT_BY_NAME_AND_EMAIL:'Los campos de su nombre y dirección de correo electrónico están en blanco. Ingrese valores en esos campos.',
        EMPTY_AUDIT_BY_NAME:'您的姓名欄位為空。請在這些欄位中輸入值。',
        EMPTY_AUDIT_BY_EMAIL:'您的電子郵件地址欄位為空。請在這些欄位中輸入值。',
        AUDIT_TYPE_SELECT_VALIDATION:'請選擇一種審核類型以產生連結。',
        INVALID_AUDIT_EMAIL:'Por favor, introduzca un correo electrónico válido.',
        INVALID_AUDIT_NAME:'Por favor, introduzca un nombre válido.',
    },
    AUDIT_ANSWERS:{
        NO_RECORD_FOUND:'No se encontraron respuestas con preguntas correctivas',
    },
    AUDIT_QUESTION_TYPES:{
        MAX_EXCEED:'Máximo excedente',
    },
    AUDIT_CATEGORY:{
        CATEGORY_BALNK_MESSAGE:"La categoría no puede estar en blanco.",
        CATEGORY_CHANGES_UPDATED:'Category Changes Updated.',
        CATEGORY_ALREADY_EXIST:'Esta categoría ya existe. No se permite la creación de categorías duplicadas.',
        SAVED:'Categoría guardada',
        MAX_EXCEED:'Has alcanzado el límite máximo de categorías que puedes agregar. Permitimos hasta 50 categorías.',
    },
    COMPLIANCE: {
        SINGLE_RECORD_EDIT: 'Por favor, seleccione 1 Grabar para editar.',
    },
    SALES_AND_REFUND: {
        ALL_OPTIONS_REQUIRED: 'Seleccione todos los filtros disponibles e inténtelo de nuevo.',
    },
    INTAKE_FORM : {
        RESTRICT_TO_OPEN_TWO_OR_MORE_INTAKE_FORM:'You have an open Intake form tab. Please close that tab before opening or adding a new Intake form.',
        INTAKE_FORM_SUCCESSFULLY_SAVE: 'Saved Successfully.',
        THANK_YOU: "Thank You!",
    },
    EXCEPTION: {
        EXCEPTION_SAVE_SUCESS: 'Saved Successfully.',
    },
    PHONE_NUMBERS: {
        USE_FOR_NOTIFICATION_SAVE: 'Del Número Actualizado para Notificaciones.',
        USE_NOTIFICATION: 'Este número alquilado se utilizará para notificaciones. Por favor haga clic en Aceptar para confirmar.',
        REMOVE_NOTIFICATION: 'Este número alquilado se eliminará para recibir notificación. Por favor haga clic en Aceptar para confirmar.',
    },
    PHONE_AND_TEXT_INTEGRATION: {
        SAVE_SUCCESS: 'Su configuración se guardó correctamente.',
        SAVE_FAIL: 'Su configuración no se guarda.',
        NO_PROVIDER: 'Aún no se ha seleccionado ningún proveedor para la función de teléfono y mensajes de texto.\n Primero seleccione un proveedor para utilizar las funciones de teléfono y mensajes de texto.',
        NO_TWILIO_NUMBERS_FOUND: 'No hay ningún número de teléfono disponible en su cuenta de Twilio conectada.\n Primero agregue el número en su cuenta de Twilio y luego realice la operación de importación.',
        NONE_SELECT_CONFIRM: 'Esto deshabilitará la opción Teléfono y Texto en CRM desde todos los lugares.\n Si tiene algún flujo de trabajo o campaña de automatización donde el texto ya está configurado, cámbielos en consecuencia, ya que ya no funcionará.'
    }
}
export default message