import React, { Component, useRef } from "react";
import PropTypes from "prop-types";

import {
	constants,
	QUEUE_LIST,
	OBJECT_TABLEID_MAP,
} from "../../../services/constants/constants";
import { getActiveTab } from "../../../services/helper/sfTabManager";
import {
	isValidParam,
	getObjectParam,
	getArrayParam,
	getStringParam,
	getIntParam,
	getBooleanParam,
} from "../../../services/helper/parameterVerifier";
import { styles } from "../../../services/constants/styles";
import {
	loadQueueList,
	getQueueList,
	openDetailviewQueueTab,
} from "../../../services/actions/queueActions";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import Cards from "./Cards";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "../components/ItemTypes";
import { useDispatch } from "react-redux";
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';

const listSource = {
	beginDrag(props) {
		return {
			id: props.id,
			x: props.x
		};
	},
	endDrag(props) {
		props.stopScrolling();
	}
};

const listTarget = {
	canDrop() {
		return false;
	},
	hover(props, monitor) {
		if (!props.isScrolling) {
			if (window.innerWidth - monitor.getClientOffset().x < 200) {
				props.startScrolling('toRight');
			} else if (monitor.getClientOffset().x < 200) {
				props.startScrolling('toLeft');
			}
		} else {
			if (window.innerWidth - monitor.getClientOffset().x > 200 &&
				monitor.getClientOffset().x > 200
			) {
				props.stopScrolling();
			}
		}
		const { id: listId } = monitor.getItem();
		const { id: nextX } = props;
		if (listId !== nextX) {
			props.moveList(listId, props.x);
		}
	}
};

const CardsContainer = (props) => {
	let { id, canMove, selectedFields, cardType, selected_sort_option, selectedHeader, app, sales_rep, startScrolling, stopScrolling, isScrolling, object, reportBy, sortBy, sortType, fields, status, item, x, isDragging, browserName, queueList } = props
	const dispatch = useDispatch();
	const ref = useRef();
	const [, drag] = useDrag({
		type: ItemTypes.CARD
	});
	const [, drop] = useDrop({
		accept: ItemTypes.CARD,
		canDrop: () => {
			return false;
		},
		hover: (item, monitor) => {
			if (!isScrolling) {
				if (window.innerWidth - monitor.getClientOffset().x < 200) {
					startScrolling('toRight');
				} else if (monitor.getClientOffset().x < 200) {
					startScrolling('toLeft');
				}
			} else {
				if (window.innerWidth - monitor.getClientOffset().x > 200 &&
					monitor.getClientOffset().x > 200
				) {
					stopScrolling();
				}
			}
		}
	});

	const openQueue = (event, value, card_type, count) => {
		try {
			if (count > 0) {
				let activeTab = getObjectParam(getActiveTab());
				let info = getObjectParam(activeTab.info);
				let initilaParam = new Object();
				initilaParam.object = object;
				initilaParam.call_from_queue = true;
				initilaParam.is_column_view = true;

				let isForecast = isValidParam(info.isForecast) ? getBooleanParam(info.isForecast) : false;
				if (isForecast) {
					initilaParam.isForecast = isForecast;
				}

				if (object !== constants.TASKS) {
					let workflowId = getIntParam(props.workflowId);
					if (workflowId > 0) {
						initilaParam.input_param_sql = "workflow_id= " + workflowId;
						initilaParam.is_workflow = true;
					}
					let search = getObjectParam(activeTab.info);
					let filter = getObjectParam(activeTab.info.filter);
					let searchFieldName = getStringParam(activeTab.info.searchFieldName);
					if (getIntParam(filter.id) > 0 || getStringParam(filter.name) !== '') {
						initilaParam = {
							...initilaParam,
							query_id: getIntParam(filter.id),
							query_name: getStringParam(filter.name),
							query_type: getStringParam(filter.type),
							is_group_by_info: true,
							page_size: QUEUE_LIST.FETCH_SIZE,
							sort_field_name: getStringParam(sortBy),
							sort_type: getStringParam(sortType),
							group_by_condition: card_type,
							group_by_field_name: 't_status',
							call_from: constants.COLUMN_VIEW,
							search_text: getStringParam(search.searchText),
							search_type: constants.SEARCH_TYPE_ANYWHERE,
							search_field_name: searchFieldName,
						};
					}
				} else {
					let sort_option = getStringParam(selected_sort_option)
					let sort_field_name = sort_option === 'Date' ? 't_dueby' : 't_priority';
					initilaParam = {
						...initilaParam,
						module_id: card_type,
						fetch_size: QUEUE_LIST.FETCH_SIZE,
						sort_field_name: sort_field_name,
						sort_type: 'desc',
						start_index: 0,
						sales_rep: sales_rep,
						call_from: constants.TASKS,
						status: status.toString(),
						object: constants.TASKS,
						sort_order: sort_option,
						is_call_from_task_page: true,
					};
				}
				object = object.toLowerCase();
				let promise = Promise.resolve(getQueueList(object, initilaParam));
				promise.then((response) => {		
					if (isValidParam(response)) {
						let _queueList = response.records;
						let totalCount = getIntParam(response.total_count);
						if (isValidParam(_queueList) && _queueList.length > 0) {
							let recordIndex = 0;
							let recordId = _queueList[0].id;
							let label = _queueList[0].title
							response['openedRecordInQueueId'] = recordId;
							response['openedRecordInQueueIndex'] = recordIndex;
							response['openedRecordInQueuelabel'] = label;
							if(object== constants.ACCOUNTS_OBJECT && activeTab.type =="TYPE_COLUMN_VIEW"){
								response['total_count'] = count;
								}
							if (object === constants.TASKS) {

								if (queueList && queueList.hasOwnProperty('data') && queueList.data.hasOwnProperty('selecteddrpdwnObj') && queueList.data.selecteddrpdwnObj && queueList.data.selecteddrpdwnObj !== "") {
									response['selecteddrpdwnObj'] = queueList.data.selecteddrpdwnObj;
								} else {
									response['selecteddrpdwnObj'] = object;
								}

							} else {
								if (queueList && queueList.hasOwnProperty('data') && queueList.data.hasOwnProperty('selecteddrpdwnObj') && queueList.data.selecteddrpdwnObj && queueList.data.selecteddrpdwnObj !== "") {
									response['selecteddrpdwnObj'] = queueList.data.selecteddrpdwnObj;
								} else {
									response['selecteddrpdwnObj'] = constants.TASKS;
								}
							}
							if (object === constants.TASKS && queueList && queueList.hasOwnProperty('data') && queueList.data.hasOwnProperty('selecteddrpdwnObj') && queueList.data.selecteddrpdwnObj && queueList.data.selecteddrpdwnObj !== "") {
								let linkInfo;
								let islinkpresent = false;
								linkInfo = _queueList[0].linkInfo;
								if (linkInfo !== null && linkInfo !== "" && linkInfo !== undefined && queueList.data.selecteddrpdwnObj !== constants.TASKS) {
									let linkInfoArr = linkInfo.split(',');
									let selectedObjId = getIntParam(OBJECT_TABLEID_MAP[queueList.data.selecteddrpdwnObj]);
									linkInfoArr.forEach((item) => {
										let ObjRecordArr = item.split('_');
										let tableId = getIntParam(ObjRecordArr[0].trim());
										if (selectedObjId === tableId) {
											recordId = getIntParam(ObjRecordArr[1]);
											object = queueList.data.selecteddrpdwnObj;
											islinkpresent = true;
										}
									});

									openDetailviewQueueTab(object, label, recordId, recordIndex, totalCount);
								} else {
									openDetailviewQueueTab(constants.TASKS, label, recordId, recordIndex, totalCount);
								}
								if (!islinkpresent) {
									let parentItems = app.objectList;
									parentItems = parentItems.filter(f => f.name === queueList.data.selecteddrpdwnObj)
									if (parentItems.length > 0) {
										let msg = label + " doesn’t have any associated " + parentItems[0].label;
										dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
									}

								}
							} else {
								openDetailviewQueueTab(object, label, recordId, recordIndex, totalCount);
							}
							loadQueueList(getObjectParam(response));
						}
					}
				})
			}
		} catch (error) {
			console.error("error in CardsContainer.js -> openQueue" + error)
		}
	}

	let divDeskCss = {};
	const opacity = isDragging ? 0.5 : 1;
	let height = null;
	let width = '320px';
	if (object === constants.TASKS && browserName !== null) {
		if (browserName === 'Chrome') {
			height = 'calc(100% - 20px)';
			width = 'calc(100%/4)';
		} else if (browserName === 'Firefox') {
			height = 'calc(100% - 50px)';
			width = 'calc(100%/4)';
			divDeskCss.paddingBottom = '0';
		}
	}

	let total_count_opacity = 0.7;
	let isColumnView = false;
	if (item.cards !== null && item.cards.length === 0) {
		total_count_opacity = 0.3;
	}
	let count = item.cards.length;
	if (item.card_type !== null && item.card_type === 'columnview') {
		count = item.total_count;
		isColumnView = true;
	}

	let isSafari = false;
	if (browserName !== null && browserName === 'Safari') {
		count = item.total_count;
		isSafari = true;
	}


	let tab = getActiveTab();
	let workflowId = 0;
	if (isValidParam(tab)) {
		let info = getObjectParam(tab.info);
		workflowId = info.workflowId;  //-9999
	}

	let bottomPosition = isSafari ? '0px' : '4px';
	let divDeskHeadCss = {};
	let queueIconStyle = { fontSize: '13px', color: '#717171', cursor: 'pointer', paddingTop: '6px', paddingLeft: '2px', opacity: ' 0.8' }
	if (count === 0) {
		divDeskHeadCss.borderBottom = '1px solid lightgray'
		queueIconStyle = { fontSize: '13px', cursor: 'pointer', paddingTop: '6px', paddingLeft: '2px', color: 'grey', opacity: ' 0.6' }
	}

	let showOpportunityAmmount = false;
	if (object === constants.ACCOUNTS_OBJECT) {
		if (selectedFields.length > 0) {
			selectedFields = selectedFields.slice(0, 5);
			showOpportunityAmmount = selectedFields.includes('opportunity_amount') ? true : false;
		}
	}

	let addlStyle = {};
	if (object === constants.OPPORTUNITIES_OBJECT || (object === constants.ACCOUNTS_OBJECT && showOpportunityAmmount)) {
		addlStyle = {
			width: '60%',
			textAlign: 'left',
			display: 'inline-block'
		}
	}

	let showQueueButton = false;
	if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.OPPORTUNITIES_OBJECT
		|| object === constants.CASES_OBJECT || object === constants.PROJECTS_OBJECT || object === constants.ISSUES_OBJECT || object === constants.CUSTOM_TABLE1_OBJECT
		|| object === constants.CUSTOM_TABLE2_OBJECT || object === constants.CUSTOM_TABLE3_OBJECT || object === constants.CUSTOM_TABLE4_OBJECT || object === constants.TASKS) {
		if (object === constants.OPPORTUNITIES_OBJECT && isValidParam(selectedHeader) && getStringParam(selectedHeader) === constants.FORECAST) {
			showQueueButton = false;
		} else {
			showQueueButton = true;
		}
	}
	drag(drop(ref));
	if (cardType === constants.COLUMN_VIEW && workflowId !== -9999) {

		return (
			<div ref={ref} className="desk" style={{ ...divDeskCss, opacity }}>
				<div className="desk-head" style={divDeskHeadCss}>
					<div className="desk-name" title={item.columnName + " (" + count + ")"}>
						<div>
							<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'inline-block', maxWidth: '55%', fontSize: '17px' }}>{item.columnName}</div>
							&nbsp;
							<div style={{ display: 'inline-block', fontSize: '14px', position: 'relative', bottom: bottomPosition }}>({count})</div>
						</div>
					</div>
				</div>
				{!(isColumnView) &&
					<div style={{ borderBottom: '1px solid #dbdbdb', paddingBottom: '8px' }} title={item.total_count}>
						<div style={{ textAlign: 'center', fontSize: '25px', paddingTop: '7px', opacity: total_count_opacity, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.total_count}</div>
					</div>
				}
				<Cards
					x={x}
					id={item.id}
					columnName={item.columnName}
					cardLength={item.cards.length}
					cards={item.cards}
					startScrolling={startScrolling}
					stopScrolling={stopScrolling}
					isScrolling={isScrolling}
					object={object}
					reportBy={reportBy}
					sortBy={sortBy}
					sortType={sortType}
					fields={fields}
					selectedFields={selectedFields}
					cardType={item.card_type}
					totalCardsCount={item.total_count}
				/>
			</div>
		);
	} else {
		return (
			<div className="desk sf-desk" style={{ ...divDeskCss, opacity, height, width }}>
				<div className="desk-head" style={divDeskHeadCss}>
					<div className="desk-name">
						<div style={{ ...addlStyle }} title={item.columnName + " (" + count + ")"}>

							<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'inline-block', maxWidth: '70%', fontSize: '17px' }}>{item.columnName}</div>
							&nbsp;
							<div style={{ display: 'inline-block', fontSize: '14px', position: 'relative', bottom: bottomPosition }}>({count})</div>

							{(showQueueButton && ((object !== constants.OPPORTUNITIES_OBJECT && object === constants.ACCOUNTS_OBJECT && !showOpportunityAmmount) ||
									object !== constants.OPPORTUNITIES_OBJECT && (object !== constants.ACCOUNTS_OBJECT && !showOpportunityAmmount))) &&
								<div title={getLocalizedStrings().label.QUEUE.QUEUE}
									 style={{ ...styles.togButton, backgroundColor: '#ffffff', height: '27px', marginLeft: '2px', width: '26px', border: '1px solid rgb(224,224,224)', float: 'right' }}
									 onClick={() => openQueue(this, constants.QUEUE, item.columnName, count)}
								>
									<i className="fas fa-play" aria-hidden="true" style={queueIconStyle}></i>
								</div>
							}

						</div>

						{(object === constants.OPPORTUNITIES_OBJECT || (object === constants.ACCOUNTS_OBJECT && showOpportunityAmmount)) &&
							<div style={{ width:'25%', display: 'inline-block', marginLeft: 5, paddingLeft: 0, }} title={item.opportunityAmount}>
								<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'inline-block', width: '100%', textAlign: 'right', maxWidth: '100%', fontSize: '13px', paddingLeft: 0, marginLeft: 0, color: '#717171' }}>{item.opportunityAmount}</div>
							</div>
						}

						{(showQueueButton && (object === constants.OPPORTUNITIES_OBJECT || (object === constants.ACCOUNTS_OBJECT && showOpportunityAmmount))) &&
							<div title={getLocalizedStrings().label.QUEUE.QUEUE}
								 style={{ ...styles.togButton, backgroundColor: '#ffffff', height: '27px', marginLeft: '2px', width: '26px', border: '1px solid rgb(224,224,224)', float: 'right' }}
								 onClick={() => openQueue(this, constants.QUEUE, item.columnName, count)}
							>
								<i className="fas fa-play" aria-hidden="true" style={queueIconStyle}></i>
							</div>
						}
					</div>
				</div>

				<Cards
					x={x}
					id={item.id}
					columnName={item.columnName}
					cardLength={item.cards.length}
					cards={item.cards}
					startScrolling={startScrolling}
					stopScrolling={stopScrolling}
					isScrolling={isScrolling}
					object={object}
					reportBy={reportBy}
					sortBy={sortBy}
					sortType={sortType}
					fields={fields}
					selectedFields={selectedFields}
					cardType={item.card_type}
					totalCardsCount={item.total_count}
					canMove={canMove}
				/>

			</div>
		);
	}
}


export default CardsContainer;