import LocalizedStrings from "react-localization";
import store from "../../../src/services/store";

function getMessage(intstate) {
	let obj = {};
	try {
		let state = intstate || store.getState();
		let messageInstance = null,
			labelInstance = null;
		let lang = "en-US";
		if (state?.app?.me?.language) {
			lang = state.app.me.language;
		}
		messageInstance =
			require(`./localLanguage/message/message_${lang}`).default;
		labelInstance = require(`./localLanguage/label/label_${lang}`).default;

		obj[lang] = { message: messageInstance, label: labelInstance };
	} catch (error) {
		console.error("Error in 'MultiLingual.js -> getMessage()':" + error);
	}
	return obj;
}

export const getLocalizedStrings = (state) => {
	let localizedStrings = new LocalizedStrings({ ...getMessage(state) });

	return localizedStrings;
};
