import React, { useEffect, useState } from "react";
import { Select, Button, TextField, FormControl, MenuItem, InputLabel, Checkbox, ListItemText } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useSelector, useDispatch } from "react-redux";
import { getAppDialog } from '../../../services/actions/appContainerActions';

const AddWorkFlowSetupStages = () => {
    const [addNewWF, setAddNewWF] = useState(false);
    const appContainer = useSelector((state) => state.appContainer);
    const {valuelist,nodes} = useSelector((state) => state.workFlowSetup.data);
    const [wFStage, setWFStage] = useState(valuelist.length > 0 ? valuelist[0] :'');
    const dispatch = useDispatch();

    const handleChange = (event, val) => {
        let value = event.target.value;
        setWFStage(value);
        if(value == 'Add to This List'){
            setAddNewWF(true);
        }
    }

    const handleChangeNewWF =(event)=>{
        let value = event.target.value;
        setWFStage(value);
    }

    const saveWFName = () => {
        let wf = wFStage;
        console.log(">>>>>>>>",wFStage)

        console.log(">>>>>>>>",appContainer.dialogProps)
        appContainer.dialogProps.eventName(wf);
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const wfMenuList =()=>{
    let  _valuelist = valuelist;
    if(_valuelist.indexOf('Add to This List') === -1){
        _valuelist.push('Add to This List' );
    }
    nodes.map((ele)=>{
        _valuelist = _valuelist.filter((data)=> data !== ele.details.t_stages);
    })
    return  _valuelist.map((wf)=>{
        return <MenuItem key ={wf} id={wf} value={wf}>{wf}</MenuItem>
        })
    }

    let workflowMenuItems = wfMenuList()

    return (
        <>
            <div>
                <div style={{ width: '100%' }}>
                    <FormControl variant="outlined" style={{ width: '100%',marginTop:'5px' }} className='audit-select'>
                        <InputLabel id="sf-Auditreport-simple-select-timeline-outlined-label" className="sf-Auditreport"> {getLocalizedStrings().label.WORKFLOW_SETUP.ACCOUNT_WORKFLOW}</InputLabel>
                        <Select
                            key="sort-by"
                            name="sort-by"
                            labelId='sort-by-label'
                            id='sortByList'
                            value={wFStage}
                            size="small"
                            className={"sf-select-height"}
                            label="Account Workflow"
                            onChange={(event) => handleChange(event)}
                        >
                            {workflowMenuItems}
                        </Select>
                    </FormControl>
                </div>
                <div>
                    { addNewWF &&
                        <div>
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    label={getLocalizedStrings().label.WORKFLOW_SETUP.ADD_NEW}
                                    fullWidth={true}
                                    value={wFStage == 'Add to This List'?'':wFStage}
                                    onChange={(e) => handleChangeNewWF(e)}
                                    autoComplete="new-password"
                                    margin='dense'
                                    size='small'
                                    autoFocus={true}
                                    className={"sf-fields-bg"}
                                />
                            </FormControl>
                        </div>
                    }
                </div>
                <div style={{ float: 'right', paddingTop: '15px' }}>
                    <Button
                        key='save'
                        onClick={()=> saveWFName() }
                        style={{ ...styles.primaryButton }}
                    >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                </div>
            </div>

        </>
    )
}
export default AddWorkFlowSetupStages;