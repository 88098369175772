import React from 'react';
import { Menu, Select, MenuItem, InputLabel } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { constants,OBJECT_TABLEID_MAP} from '../../../services/constants/constants';
import { isValidParam, getIntParam, getStringParam, getBooleanParam, getArrayParam, getObjectParam,makeValidAppUrl } from '../../../services/helper/parameterVerifier';
import Button from '@mui/material/Button';
import { getActiveTab as tabManagerActiveTab, closeTab, addTab, getActiveTabInfo, updateActiveTab, TYPE_DETAIL_VIEW, addNewFormTabs, TYPE_LIST_VIEW, updateTabByTabIndex } from '../../../services/helper/sfTabManager';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import FormControl from '@mui/material/FormControl';
import { openDetailviewQueueTab, removeRecordFromRedux } from '../../../services/actions/queueActions';
import {  refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import * as sfDialogs from '../components/sfDialogs';
import { hasAccessPermission } from '../../../services/helper/common';
import { groupUsedInCampaigns } from '../../../services/actions/detailViewActions';
import { isLinkedRecord } from '../../../services/actions/listViewAcions';
import { deleteAction } from '../../../services/actions/batchActions';
import { refreshListView } from '../../../services/actions/listViewAcions';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const TaskQueueHeader =({headerProps,object,refreshView,is_callFrom_queue,isEdit})=> {

    const dispatch = useDispatch();
	const [openActionList, setopenActionList] = useState(false);
    const [mounted, setMounted] = useState(true);
	const [data, setData] = useState(null);
	const [fields, setFields] = useState(null);
	const [selectedObject, setselectedObject] = useState('tasks');
	const [title, setTitle] = useState(null);
    const [changeLog, setChangeLog] = useState({selectedObject:'tasks'});

    const appState = useSelector((state) => state.app);
	const tab = useSelector((state) => state.tab);
    const appContainer = useSelector((state) => state.appContainer);
	const childListView = useSelector((state) => state.childListView);
	const detailViewRecord = useSelector((state) => state.detailView.record);
	const detailView = useSelector((state) => state.detailView);
	const listView = useSelector((state) => state.listView);
    const queueList = useSelector((state) => state.queueList);
    const taskAppointment = useSelector((state) => state.detailViewTaskAppintmentReducer.taskAppointment);
    const calendar = useSelector((state) => state.calendar);

    useEffect(() => {
        getTitlele();
    }, []);
    const processQueue = (taskId, action) => {
        let prevObj = null;
        let nextRecordIndex = 0;
        let islinkpresent = false;
        try {
           
            let data = getObjectParam(queueList.data);
            let tempQueueList = getArrayParam(data.records);
            let recordId = taskId[0];
            let queueObj = tempQueueList.filter((obj) => { return obj.id === recordId });
            let index = isValidParam(queueObj) && queueObj.length > 0 ? queueObj[0].idx : -1;
            let objectName = getStringParam(object);
            if (index >= 0 && index < tempQueueList.length) {
                if (action === constants.PREVIOUS) {
                    nextRecordIndex = index - 1;
                } else if (action === constants.NEXT) {
                    nextRecordIndex = index + 1;
                }

                if (index === tempQueueList.length - 1) {
                    /**
                     *  get the data for for next limit ,   
                     * get the start_indext from redux and check  and then call api to get the data
                     */

                }

                if (nextRecordIndex === tempQueueList.length && data.call_from.toLowerCase() === constants.LIST_VIEW.toLocaleLowerCase) {
                    // Fetch the next page Records
                    // start_index":40,
                    nextRecordIndex = 0;
                }


                prevObj = tempQueueList[nextRecordIndex];
                let preId = isValidParam(prevObj) && prevObj !== null ? getIntParam(prevObj.id) : 0;
                if (preId > 0) {
                    let total_count = getIntParam(data.total_count);
                    let label = getStringParam(prevObj.title);
                    queueList.data['openedRecordInQueueId'] = preId;
                    queueList.data['openedRecordInQueueIndex'] = nextRecordIndex;
                    queueList.data['openedRecordInQueuelabel'] = label;
                    let linkInfo = prevObj.linkInfo;
                    getTitlele();
                    if (linkInfo !== null && linkInfo !== undefined ) {
                        let linkInfoArr = linkInfo.split(',');
                        let selectedObjId = getIntParam(OBJECT_TABLEID_MAP[changeLog.selectedObject]); 
                        linkInfoArr.map((item, i) => {
                            let ObjRecordArr = item.split('_');
                            let tableId = getIntParam(ObjRecordArr[0].trim());
                            if(selectedObjId === tableId ){
                                preId = getIntParam(ObjRecordArr[1]);
                                objectName = changeLog.selectedObject;
                                islinkpresent = true;
                            }
                            
                        });
                    }
                   if(!islinkpresent){
                        let parentItems = appState.objectList;
                        parentItems = parentItems.filter(f => { return f.name === changeLog.selectedObject })
                        if(parentItems.length > 0){
                            let msg =  label + " doesn’t have any associated " + parentItems[0].label ;
                            dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        }
                        
                   }
                    openDetailviewQueueTab(objectName, label, preId, nextRecordIndex, total_count);

                }
            }

        } catch (error) {
            console.error("Error in 'TaskQueueHeader.js -> processQueue()':" + error);
        }
    }
    const removeFromQueue = (taskId, action) => {
        let nextRecordIndex = 0;
        let newIdx = 0;
        try {
            if (taskId > 0) {
                let data = getObjectParam(queueList.data);
                let tempQueueList = getArrayParam(data.records);
                let recordId = taskId[0];
                let queueObj = tempQueueList.filter((obj) => { return obj.id === recordId });
                let index = isValidParam(queueObj) && queueObj.length > 0 ? queueObj[0].idx : -1;
                let objectName = getStringParam(object);

                if (action === constants.DELETE && tempQueueList.length <= 1) {
                    let activeTab = tabManagerActiveTab();
                    if (isValidParam(activeTab)) {
                        closeTab(activeTab.item, activeTab.index);
                    }
                } else {
                    if (index >= 0) {
                        if (index + 1 < tempQueueList.length) {
                            nextRecordIndex = index + 1;
                            newIdx = index;
                            // open next records for detailview
                        } else {
                            nextRecordIndex = index - 1;
                            newIdx = nextRecordIndex;
                        }

                        let prevObj = tempQueueList[nextRecordIndex];
                        let preId = isValidParam(prevObj) && prevObj !== null ? getIntParam(prevObj.id) : 0;
                        if (preId > 0) {
                            let total_count = getIntParam(data.total_count);
                            total_count = total_count - 1;
                            removeRecordFromRedux(objectName, recordId, total_count);

                            let label = getStringParam(prevObj.title);
                            if(data.records.length === 1 ){
                                nextRecordIndex = 0;
                            }
                            queueList.data['openedRecordInQueueId'] = preId;
                            queueList.data['openedRecordInQueueIndex'] = newIdx;
                            queueList.data['openedRecordInQueuelabel'] = label;
                            let linkInfo = prevObj.linkInfo;
                            if (linkInfo !== null && linkInfo !== undefined) {
                                let linkInfoArr = linkInfo.split(',');
                                let selectedObjId = getIntParam(OBJECT_TABLEID_MAP[changeLog.selectedObject]);
                                linkInfoArr.map((item, i) => {
                                    let ObjRecordArr = item.split('_');
                                    let tableId = getIntParam(ObjRecordArr[0].trim());
                                    if (selectedObjId === tableId) {
                                        preId = getIntParam(ObjRecordArr[1]);
                                        objectName = changeLog.selectedObject;
                                    }

                                });
                            }
                            openDetailviewQueueTab(objectName, label, preId, newIdx, total_count);
                            refreshDetailViewListViewData(true, recordId);
                        }
                    }
                }

            }
        } catch (error) {
            console.error("Error in 'TaskQueueHeader.js -> removeFromQueue()':" + error);
        }
    }

const buttonDelay =(value)=>{
   detailView.mounted = false;
    setTimeout(() => {
        triggerAction(value);
    }, 100);
}

    const handleAction = (value) => {
        if ((value === constants.PREVIOUS || value === constants.NEXT )){
            buttonDelay(value);
        }else{
            triggerAction(value);
        }
    }
    const triggerAction = (value) => {
        let labelName = null;
        let objectName = null;
        let linkTo = null;
        let linkToUrl = null;
        let isOpenDrawer = false;
        let isOpenDialog = false;
        let isRedirect = false;
        let isOpenTab = true;
        let data = null;
        let params = null;
        let permissionMessage = null;
        let tabInfo = null;
        let isInXpress = false;
        let isUnitsAccount = false;
        try {
            permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
            tabInfo = getActiveTabInfo();
            isInXpress = getBooleanParam(tabInfo.isInXpress);
            if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
                isUnitsAccount = tabInfo.isUnitsAccount;
            }
            if ((!isInXpress && !isUnitsAccount) || value === constants.PRINT) {

                var recordIds = [];
                objectName = object;
                recordIds[0] = queueList.data.openedRecordInQueueId;
                if (isValidParam(objectName) && isValidParam(value)) {                  
                    if (value === constants.DELETE && objectName !== constants.COUPONS_OBJECT) {
                        let hasPermission = false;
                        isOpenTab = false;
                        let checkValue = null;
                        let securityLevelType = null;
                        let msg = "";
                        let errorMessage = "";
                        hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_DELETE);
                        if (hasPermission) {
                            if (objectName === constants.GROUP_OBJECT) {
                                setopenActionList(false);
                                let objParam = {};
                                objParam.recordIds = recordIds;
                                var promise = groupUsedInCampaigns(objParam);
                                var groupAlert = "";
                                if (promise !== null) {
                                    promise.then((response) => {

                                        if (response !== null && response !== '') {
                                            groupAlert = processGroupData(response);
                                            let dialogData = {};
                                            dialogData.message = groupAlert;
                                            getAppDialog(true, constants.ALERT_DIALOG, getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, null, dialogData, constants.DETAIL_VIEW);
                                        } else {

                                            if (detailViewRecord.id > 0) {
                                                msg = getLocalizedStrings().message.COMMON.CONFIRM_DELETE_RECORD;
                                            }

                                            (sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, (event) => onConfirmDeleteAction(event,objectName, recordIds), null));

                                        }
                                    });

                                }

                            } else {
                                if (detailViewRecord.id > 0) {
                                    msg = getLocalizedStrings().message.COMMON.CONFIRM_DELETE_RECORD;
                                }
                                (sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, (event) => onConfirmDeleteAction(event, objectName, recordIds), null));
                                setopenActionList(false);
                            }
                        } else {
                            setopenActionList(false);
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
                        }
                    }
                 else if ((value === constants.PREVIOUS || value === constants.NEXT || value === constants.REMOVE || value === constants.CLOSE) && objectName !== constants.JOBS_OBJECT) {
                        queueActions(recordIds, value);
                    } 

                    if ((value === constants.EDIT || value === constants.COPY || value === constants.GENERATE_INVOICE) && objectName === constants.JOBS_OBJECT) {
                        isOpenDrawer = false;
                        labelName = value === constants.GENERATE_INVOICE ? getLocalizedStrings().label.COMMON['Generate Invoice'] : labelName;
                        let info = { id: recordIds[0], title: labelName, type: value, object: objectName };
                        addNewFormTabs(info);
                    }

                    if (value !== constants.PRINT && value !== constants.JOB_SCHEDULER) {

                        linkToUrl = '/' + objectName + "/" + linkTo;
                        linkToUrl = makeValidAppUrl(linkToUrl);
                    }
                    if (isOpenDrawer) {
                        if (value === constants.CONVERT_TO_ACCOUNTS) {
                            let hasPermission = false;
                            hasPermission = hasAccessPermission(constants.ACCOUNTS_OBJECT, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_CREATE);
                            if (hasPermission) {
                                let params = {};
                                params.is_linked_contact = true;
                                params.parent_object = 'accounts';
                                params.object = 'contacts';
                                params.record_id = recordIds[0];
                                let promise = Promise.resolve(isLinkedRecord(params));
                                if (isValidParam(promise)) {
                                    promise.then((response) => {
                                        if (isValidParam(response) && response.is_linked) {
                                            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.LIST_VIEW.CONVERT_CONTACT_TO_ACCOUNT_CONFIRM, null);
                                        } else {
                                            data = {
                                                object: constants.ACCOUNTS_OBJECT,
                                                parent_object: objectName,
                                                parent_record_id: recordIds[0],
                                                is_convert: true,
                                                mode: constants.SF_FORM_OPEN_MODE_QUICK
                                            };
                                            getAppDrawer(true, labelName, value, data, constants.LIST_VIEW);
                                        }
                                    });
                                }
                            } else {
                                setopenActionList(false);
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
                            }
                        } else if (value === constants.DOCUSIGN) {
                            //if (appState.me.is_free_crm === false) {
                                labelName = getLocalizedStrings().label.COMMON.DOCUSIGN_INTEGRATION;
                                data = {
                                    parent_object: object,
                                    parent_record_id: recordIds
                                }
                                getAppDrawer(true, labelName, value, data, constants.DETAIL_VIEW);
                                setopenActionList(false);
                            // } else {
                            //     sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.DOCUSIGN.DOCUSIGN_CONNECTED_MESSAGE_1, null);
                            // }
                        } else if (value === constants.PUSH) {
                            let pushIds = {};
                            let pushArr = [];
                            for (var i = 0; i < recordIds.length; i++) {
                                pushIds = {
                                    id: recordIds[i]
                                }
                                pushArr.push(pushIds);
                            }
                            data = {
                                selectedRecords: pushArr
                            }
                            getAppDrawer(true, labelName, value, data, constants.DETAIL_VIEW);
                            setopenActionList(false);
                        } else if (objectName === constants.CONTACTS_OBJECT && value === constants.EDIT) {
                            let params = {};
                            params.is_linked_contact = true;
                            params.parent_object = 'accounts';
                            params.object = 'contacts';
                            params.record_id = recordIds[0];
                            let promise = Promise.resolve(isLinkedRecord(params));
                            if (isValidParam(promise)) {
                                promise.then((response) => {
                                    if (isValidParam(response) && response.is_linked) {
                                        let linkDetails = response.link_details;
                                        data.parent_object = constants.ACCOUNTS_OBJECT;
                                        data.parent_record_id = linkDetails.id;

                                    }
                                    getAppDrawer(true, labelName, value, data, constants.DETAIL_VIEW);
                                });
                                setopenActionList(false);
                            }
                        } else {
                            getAppDrawer(true, labelName, value, data, constants.DETAIL_VIEW);
                            setopenActionList(false);
                        }
                    }
                }

               
            } else {
                setopenActionList(false);
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
            }
        } catch (error) {
            console.error("Error in 'TaskQueueHeader.js -> handleAction()':" + error);
        }
    };
    const queueActions = (recordId, value) => {
        let isAction = true;
        try {
            if ((object === constants.TASKS || object === constants.APPOINTMENTS) && getBooleanParam(isEdit)) {
                let msg = getLocalizedStrings().message.COMMON.WITHOUT_SAVE_MESSAGE;
                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, null);
            } else {
                if (value === constants.PREVIOUS || value === constants.NEXT) {
                    processQueue(recordId, value);
                } else if (value === constants.REMOVE) {
                    removeFromQueue(recordId, value);
                } 
                // else if (value === constants.CLOSE) {
                //     closeActiveTab();
                // }
            }
        } catch (error) {

        }
    }
    const onConfirmDeleteAction = (event, objectName, recordIds) => {
        var recordIdList = [];
        for (var i = 0; i < recordIds.length; i++) {
            recordIdList.push(recordIds[i]);
        }
        let param = {
            "id": recordIdList
        }
        var response = deleteAction(objectName, param);
        response.then((response) => {
            if (response.status === 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));

                let tabInfo = getActiveTabInfo();
                if (tabInfo.hasOwnProperty('is_callFrom_queue') && getBooleanParam(tabInfo.is_callFrom_queue)) {
                    removeFromQueue(recordIdList, constants.DELETE);
                } else {
                    let activeTab = tabManagerActiveTab();
                    if (isValidParam(activeTab)) {
                        closeTab(activeTab.item, activeTab.index);
                        let tabs = getArrayParam(tab.tabs);
                        let tempTab = tabs.find(tab => { return tab.type === TYPE_LIST_VIEW && tab.object === activeTab.object });
                        if (tempTab) {
                            refreshListView(tempTab.object);
                        }
                    }
                }
            }
        });
    }
    const getTitlele = () => {
        let title = null;
       let tempselectedObject = queueList.data.selecteddrpdwnObj;
        let url = endPoints.TASK.TASK_TITLE_GET;
        let params = {};
        params.id = getIntParam(queueList.data.openedRecordInQueueId);
        try {
            let promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                if (isValidParam(response) ) {
                    title = response;
                    setTitle(title);
                    setselectedObject(tempselectedObject);
                    let log = changeLog;
                    log.selectedObject = tempselectedObject;
                    setChangeLog({...log});
                }
            });
        }
        catch (error) {
            console.error("Error in 'TaskQueueHeader.js -> getTitlele()':" + error);
        }
    }
    
    const getObjects = () => {
        let convertObjectList = [];
        try {
            let parentItems = appState.objectList;
           
                parentItems = parentItems.filter(f => { return f.name === constants.ACCOUNTS_OBJECT || f.name === constants.OPPORTUNITIES_OBJECT ||f.name === constants.CONTACTS_OBJECT || f.name === constants.TASKS|| f.name === constants.PROJECTS_OBJECT|| f.name === constants.CASES_OBJECT })
            
                parentItems.unshift({label: 'Tasks', name: 'tasks', homeId: 0});
             
            if (parentItems.length > 0) {
                parentItems.map((items, Index) => {

                    let objectList = <MenuItem
                        value={items.name}
                        key={Index}
                    >{items.label}</MenuItem>;
                    convertObjectList.push(objectList);

                }
                )
            }
        } catch (error) {
            console.error("Error in 'LeadScoreSetup.js -> getObjects()':" + error);
        }
        return convertObjectList;
    }
    const changeObj = (event) => {
        let value = event.target.value;
        let preId = 0;
        let objectName = getStringParam(object);
        setselectedObject(value);
        let log = changeLog;
        log.selectedObject = value;
        setChangeLog({...log});
        queueList.data.selecteddrpdwnObj = value;
        let data = getObjectParam(queueList.data);
        let tempQueueList = getArrayParam(data.records);
        let selectedTaskId =  data.openedRecordInQueueId;
        let queueObj = tempQueueList.filter((obj) => { return obj.id === selectedTaskId });
        let linkInfo;
        let islinkpresent = false;
        if (queueObj.length > 0) {
            linkInfo =  queueObj[0].linkInfo ;
            preId = getIntParam(queueObj[0].id);
        }
        let label = getStringParam(queueObj[0].title);
        let nextRecordIndex = data.openedRecordInQueueIndex;
        let total_count = getIntParam(data.total_count);
        
        if (linkInfo !== null && linkInfo !== "" && linkInfo !== undefined ) {
            let linkInfoArr = linkInfo.split(',');
            let selectedObjId = getIntParam(OBJECT_TABLEID_MAP[value]); 
            linkInfoArr.map((item, i) => {
                let ObjRecordArr = item.split('_');
                let tableId = getIntParam(ObjRecordArr[0].trim());
                if(selectedObjId === tableId ){
                    preId = getIntParam(ObjRecordArr[1]);
                    objectName = value;
                    islinkpresent = true;
                }
                
            });
        }else{
            preId = selectedTaskId;
            objectName = constants.TASKS ;
        }
        if(!islinkpresent){
                let parentItems = appState.objectList;
                parentItems = parentItems.filter(f => { return f.name === value })
                if(parentItems.length > 0){
                    let msg =  label + " doesn’t have any associated " + parentItems[0].label ;
                    dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
                
        }
        openDetailviewQueueTab(objectName, label, preId, nextRecordIndex, total_count);
        refreshDetailViewListViewData(true, selectedTaskId);
    }

    let tempTitle = title;
    let isCallfromQueue = false;
    let themeColor = appState.me.background;
    let tabInfo = getActiveTabInfo();
    let isDisabledPrevious = false;
    let isDisabledNext = false;
    let isRemoveDisable = false;
    let total_count = 0 ;
    let previousButtonStyle = { ...styles.secondaryButton, width: '30px', minWidth: '27px', marginRight: '0px', backgroundColor: themeColor, height: '29px' };
    let nextButtonStyle = { ...styles.secondaryButton, width: '30px', minWidth: '27px', marginRight: '0px',  backgroundColor: themeColor, height: '29px' };
    let removeButtonStyle = { ...styles.secondaryButton, marginRight: '0px', width: '30px', minWidth: '27px', top: '1px', height: '28px' }
    let nextIconStyle = { fontSize: '25px', color: 'white', cursor: 'pointer', paddingTop: '1px', paddingLeft: '0px', };
    let prevIconStyle = { fontSize: '25px', color: 'white', cursor: 'pointer', paddingTop: '1px', paddingLeft: '0px', };
    let removeIconStyle = { fontSize: '20px', color: '#717171', cursor: 'pointer', paddingTop: '1px', paddingLeft: '0px', };
    let openedRecordInQueueNo = queueList.data.openedRecordInQueueIndex + 1 ;
    total_count = queueList.data.total_count;
    if (tabInfo.hasOwnProperty('is_callFrom_queue') && getBooleanParam(tabInfo.is_callFrom_queue)) {
        isCallfromQueue = true;
        let recordsIndex = tabInfo.hasOwnProperty('record_index') ? getIntParam(tabInfo.record_index) : 0;
        let total_count = tabInfo.hasOwnProperty('total_count') ? getIntParam(tabInfo.total_count) : 0;
        isDisabledNext = recordsIndex + 1 === total_count ? true : false;
        isDisabledPrevious = recordsIndex === 0 ? true : false;
        isRemoveDisable = recordsIndex === 0 && recordsIndex + 1 === total_count ? true : false;

        previousButtonStyle = isDisabledPrevious ? { ...styles.secondaryButton, backgroundColor: "white", marginRight: '0px', width: '30px', minWidth: '27px', height: '29px' } : previousButtonStyle;
        nextButtonStyle = isDisabledNext ? { ...styles.secondaryButton, backgroundColor: "white", width: '30px', minWidth: '27px', marginRight: '0px', height: '29px' } : nextButtonStyle;
        removeButtonStyle = isRemoveDisable ? { ...styles.secondaryButton, backgroundColor: "white", width: '30px', minWidth: '27px', marginRight: '0px', height: '29px' } : removeButtonStyle;

        nextIconStyle = isDisabledNext ? { fontSize: '25px', color: 'grey', cursor: '', paddingTop: '1px', paddingLeft: '0px', opacity: '0.6' } : nextIconStyle;
        prevIconStyle = isDisabledPrevious ? { fontSize: '25px', color: 'grey', cursor: '', paddingTop: '1px', paddingLeft: '0px', opacity: '0.6' } : prevIconStyle;
        removeIconStyle = isRemoveDisable ? { fontSize: '25px', color: 'grey', cursor: '', paddingTop: '1px', paddingLeft: '0px', opacity: '0.6' } : removeIconStyle;
    }

    return (
        <>
            {isCallfromQueue &&
                <div style={{ paddingLeft: '7px', height: '42px',width:'100%', backgroundColor: '#ffffff' }}>
                    <div style={{ paddingLeft: '0px', float: 'left',display:'flex',width:'100%' }}>
                        <span style={{
                            display: 'inline-block', paddingLeft: '13px', paddingTop: '5.5px',
                            paddingRight: '10px !important'
                        }}>
                            <i className='fa fa-clock-o' aria-hidden="true" style={{ color: '#F89764' }} ></i>
                        </span>
                        <span id="detailviewHeaderId" style={{
                            paddingLeft: '13px', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block', fontSize: '24px', whiteSpace: 'nowrap',
                            fontWeight: 'normal', color: '#555555', verticalAlign: 'middle', maxWidth:'31%',textOverflow: "ellipsis",
                        }} title={tempTitle} >{tempTitle}</span>
                        <div style ={{width: 'auto',position: 'absolute',right: '34%'}}>
                        <span style={{  fontSize: '14px', lineHeight:2 }}>Browse</span>
                        <FormControl variant="outlined" style={{  marginTop:'1px',marginLeft:'9px' }} noValidate autoComplete="off">
                            <Select
                                name="parentObject"
                                id="parentObject"
                                value={changeLog.selectedObject}
                                style={{ height: '29px',textOverflow: "ellipsis", }}
                                onChange={(event)=>changeObj(event)}
                                className={"sf-fields-bg"}
                            >
                                {getObjects()}
                            </Select>

                        </FormControl>

                        <div style={{ display: 'inline', marginTop: '1px', marginLeft: '15px' }}>
                            <div style={{ display: 'inline', paddingRight: '5px' }}>
                                <Button disabled={isDisabledPrevious} title={getLocalizedStrings().label.QUEUE.PREVIOUS} style={previousButtonStyle} onClick={() => handleAction(constants.PREVIOUS)} >
                                    <i className="fas fa-caret-left" aria-hidden="true" style={prevIconStyle}></i>
                                </Button>
                            </div>
                            <div style={{ display: 'inline', paddingRight: '5px' }}>
                                <Button disabled={isDisabledNext} title={getLocalizedStrings().label.QUEUE.NEXT} style={nextButtonStyle} onClick={() => handleAction(constants.NEXT)} >
                                    <i className="fas fa-caret-right" aria-hidden="true" style={nextIconStyle}></i>
                                </Button>

                            </div>
                            <div style={{ display: 'inline', paddingRight: '5px' }}>
                                <Button disabled={isRemoveDisable} title={getLocalizedStrings().label.QUEUE.REMOVE} style={removeButtonStyle} onClick={() => handleAction(constants.REMOVE)} >
                                    <i className="fas fa-minus" aria-hidden="true" style={removeIconStyle}></i>
                                </Button>
                            </div>
                            <div style={{ display: 'inline', paddingRight: '5px' }}>
                            <span> ({openedRecordInQueueNo} of {total_count})</span>
                            </div>
                        </div>
                        </div>
                    </div></div>}
        </>
    );
}

function processGroupData(response) {

    var tcAlert = [], tcMsgAlert = "";
    var bcAlert = [], bcMsgAlert = "";
    var abAlert = [], abMsgAlert = "";
    var webFormAlert = [], webFormMsgAlert = "";
    var landingPageAlert = [], landingPageMsgAlert = "";
    var message = [];
    var alertMsg = "";

    if (response !== null && response !== '') {
        if (response.touchmsg !== null) {
            tcMsgAlert = response.touchmsg;
        }
        if (response.broadcastmsg !== null) {
            bcMsgAlert = response.broadcastmsg;
        }
        if (response.abmsg !== null) {
            abMsgAlert = response.abmsg;
        }
        if (response.webFormMsg !== null) {
            webFormMsgAlert = response.webFormMsg;
        }
        if (response.landingPageMsg !== null) {
            landingPageMsgAlert = response.landingPageMsg;
        }

        if ((tcMsgAlert !== null && tcMsgAlert !== "") || (bcMsgAlert !== null && bcMsgAlert !== "") ||
            (abMsgAlert !== null && abMsgAlert !== "") || (webFormMsgAlert !== null && webFormMsgAlert !== "") ||
            (landingPageMsgAlert !== null && landingPageMsgAlert !== "")) {

            if (tcMsgAlert !== null && tcMsgAlert !== "") {
                for (let i = 0; i < tcMsgAlert.length; i++) {

                    var touchArr = tcMsgAlert[i].hasOwnProperty("touch") ? tcMsgAlert[i]["touch"] : null;
                    var tcGroup = tcMsgAlert[i].hasOwnProperty("tcGroup") ? tcMsgAlert[i]["tcGroup"] : null;

                    if (touchArr !== null && touchArr.length > 0) {
                        var tcName = "";
                        var messageTC = "";

                        for (let j = 0; j < touchArr.length; j++) {
                            if (tcName === "") {
                                tcName = touchArr[j];
                            } else {
                                tcName = tcName + ", " + touchArr[j];
                            }
                        }
                        messageTC = <span><b><i>{tcGroup}</i></b>  {getLocalizedStrings().message.GROUP.USED_IN_TOUCH_CAMPAIGN} <br /><b>{tcName}<br /><br /></b></span>;
                        tcName = "";
                        tcAlert.push(messageTC);

                    }
                }

            }

            if (bcMsgAlert !== null && bcMsgAlert !== "") {
                for (let i = 0; i < bcMsgAlert.length; i++) {

                    var broadcastArr = bcMsgAlert[i].hasOwnProperty("broadcast") ? bcMsgAlert[i]["broadcast"] : null;
                    var broadcastGroup = bcMsgAlert[i].hasOwnProperty("bcGroup") ? bcMsgAlert[i]["bcGroup"] : null;

                    if (broadcastArr !== null && broadcastArr.length > 0) {
                        var bcName = "";
                        var messageBC = "";

                        for (let j = 0; j < broadcastArr.length; j++) {
                            if (bcName === "") {
                                bcName = broadcastArr[j];
                            } else {
                                bcName = bcName + ", " + broadcastArr[j];
                            }
                        }

                        messageBC = <span><b><i>{broadcastGroup}</i></b> {getLocalizedStrings().message.GROUP.USED_IN_BROADCAST_CAMPAIGN} <br /><b>{bcName}<br /><br /></b></span>;
                        bcName = "";

                        bcAlert.push(messageBC);

                    }
                }

            }

            if (abMsgAlert !== null && abMsgAlert !== "") {
                for (let i = 0; i < abMsgAlert.length; i++) {

                    var abArr = abMsgAlert[i].hasOwnProperty("ab") ? abMsgAlert[i]["ab"] : null;
                    var abGroup = abMsgAlert[i].hasOwnProperty("abGroup") ? abMsgAlert[i]["abGroup"] : null;

                    if (abArr !== null && abArr.length > 0) {
                        var abName = "";
                        var messageAB = "";

                        for (let j = 0; j < abArr.length; j++) {
                            if (abName === "") {
                                abName = abArr[j];
                            } else {
                                abName = abName + ", " + abArr[j];
                            }
                        }

                        messageAB = <span><b><i>{abGroup}</i></b> {getLocalizedStrings().message.GROUP.USED_IN_AB_CAMPAIGN}  <br /><b>{abName}<br /><br /></b></span>;
                        abName = "";

                        abAlert.push(messageAB);

                    }
                }

            }

            if (webFormMsgAlert !== null && webFormMsgAlert !== "") {
                for (let i = 0; i < webFormMsgAlert.length; i++) {

                    var webformArr = webFormMsgAlert[i].hasOwnProperty("webForm") ? webFormMsgAlert[i]["webForm"] : null;
                    var webformGroup = webFormMsgAlert[i].hasOwnProperty("webFormGroup") ? webFormMsgAlert[i]["webFormGroup"] : null;

                    if (webformArr !== null && webformArr.length > 0) {
                        var webformName = "";
                        var messageWebform = "";

                        for (let j = 0; j < webformArr.length; j++) {
                            if (webformName === "") {
                                webformName = webformArr[j];
                            } else {
                                webformName = webformName + ", " + webformArr[j];
                            }
                        }

                        messageWebform = <span><b><i>{webformGroup}</i></b> {getLocalizedStrings().message.GROUP.USED_IN_WEBFORM} <br /><b>{webformName}<br /><br /></b></span>;
                        webformName = "";

                        webFormAlert.push(messageWebform);

                    }
                }

            }

            if (landingPageMsgAlert !== null && landingPageMsgAlert !== "") {
                for (let i = 0; i < landingPageMsgAlert.length; i++) {

                    var landingpageArr = landingPageMsgAlert[i].hasOwnProperty("landingPage") ? landingPageMsgAlert[i]["landingPage"] : null;
                    var landingpageGroup = landingPageMsgAlert[i].hasOwnProperty("landingPageGroup") ? landingPageMsgAlert[i]["landingPageGroup"] : null;

                    if (landingpageArr !== null && landingpageArr.length > 0) {
                        var landingpageName = "";
                        var messageLandingpage = "";

                        for (let j = 0; j < landingpageArr.length; j++) {
                            if (landingpageName === "") {
                                landingpageName = landingpageArr[j];
                            } else {
                                landingpageName = landingpageName + ", " + landingpageArr[j];
                            }
                        }

                        messageLandingpage = <span><b><i>{landingpageGroup}</i></b> {getLocalizedStrings().message.GROUP.USED_IN_LANDINGPAGE} <br /><b>{landingpageName}<br /><br /></b></span>;
                        landingpageName = "";
                        landingPageAlert.push(messageLandingpage);

                    }
                }
            }
        }

        if (tcAlert !== null) {
            message.push(tcAlert);
        }

        if (bcAlert !== null) {
            message.push(bcAlert);
        }

        if (abAlert !== null) {
            message.push(abAlert);
        }
        if (webFormAlert !== null) {
            message.push(webFormAlert);
        }

        if (landingPageAlert !== null) {
            message.push(landingPageAlert);
        }

        alertMsg = <div style={{ overflow: 'auto', maxHeight: '400px', fontSize: '16px' }}><b>{getLocalizedStrings().message.GROUP.DELETE_MSG} :</b><br /><br />{message}</div>;
        return alertMsg
    } else {
        return alertMsg
    }

}
export default TaskQueueHeader;