 const label = {
    MODULE: {
        Sales: 'Bán hàng',
        Marketing: 'Tiếp thị',
        Service: 'Dịch vụ',
        Operations: 'Hoạt động',
        HUB: 'Trung tâm'
    },
    MODULE_ITEMS: {
        Reports: 'Báo cáo',
        Dashboard: 'bảng điều khiển',
        'Marketing Templates': 'Email tiếp thị mẫu',
        'Broadcast Campaigns': 'Chiến dịch phát sóng',
        'Automation Designer': 'Thiết kế tự động',
        'Email Marketing': 'Thư điện tử quảng cáo',
        'Lead Capture': 'Chụp chì',
        'Landing Pages': 'Trang đích',
        'Web Forms': 'Biểu mẫu web',
        'Automated Campaigns': 'Chiến dịch tự động',
        'Social Marketing': 'Tiếp thị xã hội',
        'Website Tracking': 'Theo dõi trang web',
        'Website Analytics Setup': 'Thiết lập Analytics Trang web',
        'Ab Campaigns': 'Ab Chiến dịch',
        'Opportunities': 'Cơ hội',
        'Marketing Reports': 'Báo cáo',
        'Touch Campaigns': 'Chiến dịch cảm ứng',
        'A/B Campaigns': 'Chiến dịch A / B',
        'Email Blast': 'Email vụ nổ',
        Activities: 'Hoạt động',
        Conversion: 'Chuyển đổi',
        Campaigns: 'Chiến dịch',
        Custom: 'Tập quán',
        Sales: 'Bán hàng',
        Marketing: 'Tiếp thị',
        Offers: 'Ưu đãi',
        Coupons: 'Phiếu giảm giá',
        'Coupons Claim Report': 'Báo cáo', // Report
        ASSETS_DISTRIBUTION: 'Phân phối tài sản',
        USEFUL_LINKS: 'Liên kết hữu ích',
        Royalties: 'Tiền bản quyền - Di sản',
        COMPLIANCE_REPORT: "Tuân thủ",
    },
    NOTE:{
        ALL_NOTES:'Tất cả ghi chú',
        ALL_TYPES:'Các loại',        
    },
    COMMON: {
        PUSH_NO_CONFIRM: 'Đẩy mà không cần xác nhận',
        UNIT_CRM_UPDATE_STAGES:'Cập nhật giai đoạn',
        UNIT_CRM_UPDATE_TASKS:'Cập nhật nhiệm vụ',
        FRENCHISEE_COMPANY_NAME:'Chọn tên công ty nhận quyền',
        SELECT_AN_ACCOUNT:'Chọn một đơn vị',
        ADD_TO_PLAN:'Thêm vào kế hoạch',
        LOGIN_TO_BASE:'Đăng nhập vào cơ sở',
        PLUS_BRANCH: '+Chi nhánh',
        ADD: 'Thêm',
        ADD_NEW_APPOINTMENT: 'Thêm Mới',
        SHOW: 'Hiển thị',
        HIDE: 'Ẩn giấu',
        REFRESH: 'Làm tươi',
        EDIT: 'Chỉnh sửa',
        SAVE: 'Lưu',
        CANCEL: 'Hủy bỏ',
        TEST: 'Bài kiểm tra',
        ENTER_TEXT: 'Nhập văn bản',
        APPLY: 'Áp dụng',
        AND: 'và',
        EMPTY: 'Rỗng',
        EXPAND: 'Mở rộng',
        DELETE: 'Xóa bỏ',
        REQUIRED: ' (cần thiết)',
        AUTO_SEARCH_TEXT: 'Cùng một văn bản giá trị khác nhau',
        NOTE: 'Lưu ý',
        SEARCH: 'Tìm kiếm',
        OK: 'Được',
        SEND_MAIL: 'Gửi email',
        ASSIGN: 'Gán',
        SELECT_A: 'Chọn một ',
        SELECT_MORE: 'Chọn một hoặc nhiều ',
        CLOSE: 'Đóng',
        CREATE: 'Tạo',
        WITH: 'Với',
        CONVERT: 'Đổi',
        SEARCH_PLACEHOLDER: 'Nhập và nhấn Enter để tìm kiếm',
        COPY: 'Sao chép',
        MORE: 'Thêm',
        CLICK_TO_SEARCH: 'Click vào đây để tìm kiếm',
        PREVIEW: 'Xem trước',
        BACK: 'Trở lại',
        CONFIRM_REQUEST: 'Xác nhận yêu cầu',
        SELECT: 'Chọn',
        ADVANCED: 'Nâng cao',
        ADVANCED_SEARCH: 'Tìm kiếm nâng cao',
        RESULTS_FOR: 'Kết quả cho',
        SETTINGS: 'Cài đặt',
        SIGN_OUT: 'Đăng xuất',
        BACK_TO_YOUR_ACCOUNT: 'Trở lại tài khoản của bạn',
        BACK_TO_LIST: 'Quay trở lại danh sách.',
        BACK_TO_COLUMN_VIEW: 'Quay lại chế độ xem cột.',
        MERGE: 'Hợp nhất',
        DISPLAY_DIFFERENT_FIELDS: '* chỉ hiển thị các trường khác nhau.',
        WORKFLOW: 'Quy trình làm việc',
        STATUS: 'Tình trạng',
        RECORD: 'Ghi lại',
        MERGED_RECORD: 'Bản ghi sáp nhập',
        EXPORT: 'Xuất khẩu',
        CTR_DETAILS_FOR: 'Chi tiết CTR cho',
        URL: 'URL',
        COUNT: 'Đếm',
        CLICK_FOR_KANBAN_VIEW: 'Bấm vào đây để xem Kanban',
        CLICK_FOR_LIST_VIEW: 'Bấm vào đây để xem danh sách',
        INSERT: 'Chèn',
        CATEGORIES: 'Loại',
        REMOVE: 'Loại bỏ',
        REPLY: 'Trả lời',
        REPLY_ALL: 'Trả lời tất cả',
        FORWARD: 'Về phía trước',
        WORKFLOW_NAME: 'Tên công việc',
        CONVERTING: 'Chuyển đổi',
        TO: 'đến',
        CURRENT_WORKFLOW: 'Công việc hiện tại',
        CHANGE_WORKFLOW: 'Thay đổi Workflow',
        UPDATE: 'Cập nhật',
        STATUS_TO_OPTIONAL: 'tình traïng (Tuøy YÙ)',
        MANAGE: 'Quản lý',
        ADD_PERSONAL_NOTE_OPTIONAL: 'Thêm ghi chú cá nhân vào yêu cầu của bạn (tùy chọn)',
        CONVERTED: 'Chuyển',
        SELECT_USER: 'Chọn người dùng',
        COMING_SOON: 'Đến sớm',
        IMPORT: 'Nhập khẩu',
        NO: 'Không',
        FOUND: 'Tìm thấy',
        NOTHING_FOUND: 'Không tìm thấy gì cho',
        DOT: '.',
        UNDER_PROCESSING: 'Đang chế biến, đến sớm ',
        FIND: 'Tìm thấy',
        LOADING: 'Tải',
        NAME: 'Tên',
        CLEAR_ALL: 'Làm sạch tất cả',
        CLEAR: 'Rõ ràng',
        SHARE: 'Chia sẻ',
        ADD_TO_GROUP: 'Thêm vào nhóm',
        MAP: 'Bản đồ',
        AUDIT: 'Thay đổi log.',
        OPEN_AUDIT_FORM: 'Kiểm toán',
        TRAIL: 'Đường mòn',
        ADD_NOTES: 'Thêm ghi chú',
        TO_EXCEL: 'Để Excel',
        PUSH: 'Đẩy',
        RESCHEDULE: 'Sắp xếp lại',
        PRINT: 'In',
        COMPLETE: 'Hoàn thành',
        COMPLETE_New: 'Hoàn thành & Mới',
        SEND_EMAIL: 'Gửi email',
        SEND_SMS: 'Gửi tin nhắn',
        CONVERT_TO_ACCOUNTS: 'Chuyển đổi sang tài khoản',
        CONNECT: 'Kết nối',
        DISCONNECT: 'Ngắt kết nối',
        DOWNLOAD: 'Tải về',
        BATCH_EDIT: 'Chỉnh sửa hàng loạt',
        BATCH_CONVERT: 'Batch chuyển đổi',
        INACTIVE: 'Không hoạt động',
        ACTIVE: 'Hoạt động',
        RESTORE: 'Khôi phục',
        PROFILE: 'Hồ sơ',
        TYPE_TO_SEARCH: 'Gõ vào đây để tìm kiếm',
        CONVERT_AND_ADD_OPP: 'Chuyển đổi & Add cơ hội',
        SEND_VERIFICATION_EMAIL: 'Gửi email xác minh',
        THESE: 'Những',
        ALL_APPOINTMENTS: 'Tất cả các cuộc hẹn',
        ALL_TASKS: 'Nhiệm vụ',
        CONTINUE: 'Tiếp tục',
        ALL_OPPORTUNITIES: 'Tất cả cơ hội',
        EDIT_NOTES: 'Sửa ghi chú',
        RUN: 'Chạy',
        INTEGRATION_TITLE: 'Hội nhập',
        UNSUBSCRIBE: 'Bỏ đăng ký',
        MY_INTEGRATIONS: 'Tích hợp',
        NOTIFY: 'Thông báo',
        HELP: 'Trợ giúp',
        PUBLISH: 'Công bố',
        UNPUBLISH: 'Hủy xuất bản',
        CALL_SCRIPT: 'Tập lệnh gọi',
        DOCUSIGN: 'Gửi tài liệu',
        DOCUSIGN_INTEGRATION: 'Gửi tài liệu',
        ALL: 'Tất cả các',
        USER: 'Người dùng',
        SYSTEM: 'Hệ thống',
        MY: 'Của tôi',
        TODAYS: "Hôm nay",
        YES: 'Vâng',
        DONE: 'Làm xong',
        OPEN: 'Mở',
        FUTURE: 'Tương lai',
        OVERDUE: 'Quá hạn',
        TOMORROWS: "Ngày mai",
        TOMORROW: "Ngày mai",
        TODAY: 'Hôm nay',
        CONNECTED_AS: 'Đã kết nối với tư cách',
        SALESREP: 'Đại diện bán hàng',
        EVERYONE: 'Tất cả mọi người',
        NOTE_TYPE: 'Loại lưu ý',
        TEXT: 'Bản văn',
        FOR: 'cho',
        MODULE_SETTING: 'Cài đặt',
        TEMPLATE: {
            LIBRARY_TEMPLATE: 'Mẫu thư viện',
            USER_TEMPLATE: 'Mẫu người dùng',
        },
        OF: 'của',
        'System Fields': 'Trường hệ thống',
        'Custom Fields': 'Trường tùy chỉnh',
        'Additional Information': 'thông tin thêm',
        'Contact Details': 'Chi tiết liên lạc',
        'Phone and Email Information': 'Thông tin điện thoại và email',
        'Sales rep': 'Đại diện bán hàng',
        IMPORT_CSV_SOURCE_FIELD_HEADER: 'Tôi sẽ ánh xạ các nguồn của tôi từ tệp CSV',
        ADD_NEW_SOURCE: 'Thêm nguồn mới',
        ADD_TO_THIS_LIST: 'Thêm vào Danh sách này',
        TYPE_SOMETHING: 'Nhập thứ gì đó',
        'Opportunity Details': 'Chi tiết cơ hội',
        'Company Details': 'Chi tiết công ty',
        'Contact Information': 'Thông tin liên lạc',
        'Case Information': 'Thông tin trường hợp',
        'Solution Details': 'Giải pháp chi tiết',
        'Project Details': 'Chi tiết dự án',
        'Issue Details': 'Vấn đề chi tiết',
        'Assigned to': 'Gán cho',
        'Sales person': 'Người bán hàng',
        EMAIL_TO: 'Gửi email đến',
        RECENT_SEARCHES: 'Tìm kiếm gần đây',
        RECENT_RECORDS: 'Hồ sơ gần đây',
        ASSETS: 'Tài sản',
        ENABLE_MAILING: 'Cho phép gửi email',
        DISABLE_MAILING: 'Vô hiệu hóa gửi email',
        CLICK_TO_REMOVE: 'Click vào đây để loại bỏ',
        PIN: 'Ghim',
        UNPIN: 'Bỏ ghim',
        RENAME: 'Đổi tên',
        DEACTIVATE: 'Vô hiệu hóa',
        ACTIVATE: 'Kích hoạt',
        COUPONS: 'Phiếu giảm giá',
        ADD_FOLDER: 'Thêm cặp',
        ADD_FILE: 'Thêm tệp',
        SELECT_CONTACTS: 'Chọn danh bạ',
        PREFERENCES: 'Sở thích',
        NOTIFICATION_REMINDERS: 'Thông báo & nhắc nhở',
        LOCK_STAGES: 'Khóa giai đoạn',
        LOCK_TASKS: 'Nhiệm vụ khóa',
        SEND_BATCH_SMS: 'Gửi tin nhắn văn bản hàng loạt',
        SEND_TEXT_MESSAGE: 'gửi tin nhắn văn bản',
        VERIFY_EMAIL: 'Xác minh email',
        SELECT_UNIT_NAME: 'Chọn tên đơn vị',
        NEWS: 'Tin tức',
        CLOSE_CASE: 'Đóng trường hợp',
        CUSTOMIZE: 'Tùy chỉnh',
        VIEW_MODE: 'XEM CHẾ ĐỘ',
        LIST: 'Danh sách',
        COLUMN: 'Cột',
        SETUP_SCHEDULING_PAGES: 'Thiết lập lập kế hoạch trang',
        SAVE_RUN: 'TIẾT KIỆM & RUN',
        BATCH_CONVERT_TO_ACCOUNTS: 'Chuyển đổi hàng loạt sang tài khoản',
        NEXT: 'Next',
        ADD_QUICKBOOKS: 'Add to Quickbooks',
        ITEM_FROM_QB: 'FROM Quickbooks',
        PUSH_TO_QB: 'Add to Quickbooks',
        ADD_NOTE: 'Thêm ghi chú',
        ADD_NOTE_CONVERT: 'Thêm ghi chú & chuyển đổi',
        OBJECT_NAME: 'Tên đối tượng',
        GREENLIGHT_EXPORT_DOWNLOAD: 'Xử lý & Tải xuống',
        PREVIOUS: 'Trước',
        VIEW: 'Xem',
        MENU: 'Thực đơn',
        NO_DUPLICATE: 'Không trùng lặp',
        ONGOING: 'Đang diễn ra',
        ONGOING_ONBOARDING: 'Giới thiệu đang diễn ra',
        SYNC_NOW: 'Sync Now',
        'Generate Invoice': 'Tạo hóa đơn',
        'Add Payment': 'Thêm thanh toán',
        'Print' : 'In',
        MOBILE_MENU: 'Mobile Menu',
        PORTAL_MENU: 'Portal Menu',
        TO_QUICKBOOKS:'Tới Quickbooks',
        CONVERT_INVOICE: 'Chuyển đổi sang hóa đơn',
        RECEIVE : 'Nhận được',
        ACCEPT : 'Chấp nhận',
        CONFIRM: 'Xác nhận',
        FULFILL: 'Thực hiện',
        SHIP: 'Tàu',
        ADD_JOBS : 'Thêm việc làm',
        ROYALTY_SETUP: 'Thiết lập bản quyền',
        SAVE_AND_SCHEDULE:'Lưu & Lên lịch',
        UNLINK: 'Hủy liên kết',
        TO_XMS: 'Tới XMS',
        SEQUENTIAL:"Tuần tự",
        STAR: 'Ngôi sao',
        PIN :'Ghim',
        CONVERT: 'Chuyển thành',
        SHARE_WITH : 'Chia sẽ với',
        LMS_SETUP:'Cài đặt LMS',
        LMS_LABEL:'Tài khoản LMS (Bắt buộc)',
        PLUS_BASE: '+Căn cứ',
        UNITBASE:'Căn cứ đơn vị'
    },
    HOME: {
        MY_FILTERS: 'Bộ lọc của tôi',
        GROUPS: 'Nhóm',
        ADD_NEW: 'Thêm mới',
        RECENT: 'Gần đây',
        HOME: 'Nhà',
        CLICK_TO_REFRESH: 'Refresh',
        RECENTLY_VIEWED: 'Đã xem gần đây',
        FILTERS: 'Bộ lọc',
        ADD_GROUP: 'Thêm nhóm',
        NOTIFICATIONS: 'Thông báo',
        WHATS_NEW: "Có gì mới không",
        COLLAPSE: 'Sự sụp đổ',
        EXPAND: 'Mở rộng',
        ADD_FILTER: 'Thêm bộ lọc',
        CLICK_TO_FIND_GROUP: 'Click vào đây để tìm một nhóm',
        CLICK_TO_FILTER_GROUP: 'Lọc các nhóm của bạn',
        CLICK_TO_FIND_FILTER: 'Click vào đây để tìm một bộ lọc',
        FORECASTS: 'Dự báo',

        //not found in doc
        ADD_FIELD: 'Bấm vào đây để thêm trường vào bộ lọc',
        FITER_SETUP: 'Thiết lập bộ lọc',
        ENTER_BY_HAND: 'Nhập bằng tay',
        IMPORT: 'Nhập khẩu',
        VIEW: 'Lượt xem',
        DELETE: 'Xóa bỏ',
        CLICK_TO_FIND_REPORT: 'Nhấp vào đây để tìm báo cáo',

    },
    CAMPAIGNS: {
        NAME_REQUIRED: 'Tên chiến dịch (bắt buộc)',
        GROUP: 'Nhóm',
        CONTACT_FILTER: 'Bộ lọc liên hệ',
        ACCOUNT_FILTER: 'Bộ lọc tài khoản',
        FROM_NAME_REQUIRED: 'Từ tên (bắt buộc)',
        VIEW_AS_WEB_PAGE: 'Xem dưới dạng trang web',
        SUBJECT_REQUIRED: 'Môn học (yêu cầu)',
        DAYS: 'Ngày (s)',
        EXECUTE_NOW: 'Thực hiện ngay bây giờ',
        TEST: 'Thử nghiệm',
        SCHEDULED_FOR: 'Lên kế hoạch cho',
        EXECUTED_ON: 'Thực hiện trên',
        EXECUTING: 'Thực hiện',
        ABORTED: 'Hủy bỏ',
        SCHEDULE: 'Lịch biểu',
        CLICK_URL: 'Nhấp vào URL',
        SELECT_GROUP: 'Chọn tối đa ba nhóm',
        SELECT_CONTACT_FILTER: 'Chọn bộ lọc từ số liên lạc',
        SELECT_ACCOUNT_FILTER: 'Chọn một bộ lọc từ tài khoản',
        CHANGE_TEMPLATE: 'Thay đổi tiêu bản',
        WITHOUT_AB_TEST: 'Nếu không có a/b thử nghiệm',
        WITH_AB_TEST: 'Với thử nghiệm a / b',
        WITH_AUTOMATION: 'Chiến dịch tự động',
        WITHOUT_AUTOMATION: 'Chạm vào Chiến dịch (Di sản)',
        SEND_ON_WEEK_DAY: 'Không gửi vào cuối tuần (Thứ Bảy, Chủ Nhật)',
        RESEND_AFTER: 'Gửi lại sau khi',
        RESEND_ALL: 'Gửi lại tất cả',
        FROM_EMAIL_REQUIRED: 'Từ email (bắt buộc)',
        CAMPAIGN_TYPE_OPTIONAL: 'Loại chiến dịch (tùy chọn)',
        CAMPAIGN_TYPE: 'Loại chiến dịch',
        START_DATE_REQUIRED: 'Ngày bắt đầu (bắt buộc)',
        SENT: 'Gởi',
        OPENED: 'Đã mở',
        UNOPENED: 'Chưa mở',
        CLICKED: 'Đã nhấp',
        BOUNCED: 'Bị trả',
        UNSUBSCRIBED: 'Đã hủy đăng ký',
        SPAM: 'Thư rác',
        DROPPED: 'Giảm',
        TYPE_HERE: 'Loại ở đây.',
        ADD_SIGNATURE: 'Thêm chữ ký',
        INSERT_IMAGE: 'Chèn hình ảnh',
        PERSONALIZE: 'Cá nhân hóa',
        TEMPLATE_DIALOG_TITLE: 'Chọn một mẫu',

        // Campaign Status
        'All': 'Tất cả các',
        'Active': 'Hoạt động',
        'Running': 'Đang chạy',
        'Stopped': 'Ngưng',

        'New': 'Mới',
        'Queued': 'Đã xếp hàng đợi',
        'Executed': 'Thực thi',
        OPEN: 'Mở',
        BOUNCE: 'Tung lên',
        SPAMREPORT: 'Spamreport',
        'Halted': 'Đã tạm dừng',
        'Auto Response': 'Trả lời tự động',
        'Marketing Template': 'Mẫu tiếp thị',
        'Newsletter': 'Bản tin',
        'Greetings': 'Lời chào hỏi',
        'Inactive': 'Không hoạt động',
        'Executed - No Winner': 'Đã thi hành - Không có người chiến thắng',
        'Sent to Winner': 'Đã gửi tới người chiến thắng',
        'Sending to Winner': 'Gửi đến người chiến thắng',
        ADD_CAMPAIGN_TYPE: 'Thêm loại chiến dịch mới',
        CAMPAIGN_NAME: 'Tên chiến dịch',
        SUBJECT: 'Môn học',
        MANAGE: 'Quản lý',
        FROM_EMAIL: 'Từ email',
        SELECT_A_FILTER:'Chọn một bộ lọc từ ',
        BETWEEN: 'Giữa',
        WEEKDAYS_ONLY: 'Chỉ ngày trong tuần'
    },
    BROADCAST_CAMPAIGNS: {
        PERSONALIZE_SUBJECT: 'Cá nhân hoá chủ đề',
        FROM_EMAIL_REQUIRED: 'Từ email (bắt buộc)',
        EMAIL_CAMPAIGN_REPORT: 'Email chiến dịch báo cáo',
        SCHEDULE_BROADCAST: 'Lịch phát sóng',
        RESCHEDULE_BROADCAST: 'Lên lịch phát lại',
        EXECUTE_BROADCAST: 'Thực hiện phát sóng',
        ADD_GROUP: 'Thêm nhóm',
        CATEGORY: 'Mục',
        TEMPLATE: 'Bản mẫu',
        PERFORM_AB_TESTING: 'Thực hiện thử nghiệm A/B',
        VERIFY_EMAIL: 'Xác minh địa chỉ email của bạn',
        VERIFY_EMAIL_ADDRESS: 'Nhập địa chỉ email của bạn ở đây',
        VERIFY_EMAIL_ADDRESS_OPTION: 'Xác minh một địa chỉ email khác',
        BROADCAST_CAMPAIGN_EDIT_TAB_LABEL: 'Chỉnh sửa chiến dịch phát sóng',
        BATCH_EDIT: 'Chỉnh sửa hàng loạt',
        BROADCAST_CAMPAIGN_COPY_TAB_LABEL: 'Sao chép chiến dịch phát sóng',
        BROADCAST_CAMPAIGN_CREATE_TAB_LABEL: 'Tạo chiến dịch phát sóng',
        All_BROADCASTS: 'Tất cả các chương trình phát sóng',
        BROADCASTS: 'Chương trình phát sóng',
        ADD_SIGNATURE: 'Thêm ngôn ngữ',
        BROADCAST_INFO: 'Thông tin phát sóng',
        DESIGN: 'Thiết kế',
        DRAG_AND_DROP: 'Kéo và thả',
        LEGACY: 'Di sản',
        EMAIL_BUILDER: 'Trình tạo email',
        QUALIFIED_CONTACTS: 'Xem danh sách người nhận',
    },
    AB_CAMPAIGNS: {
        TARGET_BY: 'Mục tiêu theo',
        AB_TEST_DISTRIBUTION: 'Phân phối thử nghiệm A / B',
        MIN_MARGIN_PERCENT: 'Tỷ lệ ký quỹ tối thiểu%',
        A_PERCENT: 'A%',
        B_PERCENT: 'B%',
        AB_CAMPAIGN_REPORT: 'Báo cáo chiến dịch A/B',
        //not found in doc
        AB_SEND_REST: 'Gửi phần còn lại bằng cách sử dụng người chiến thắng',
        AB_SELECT_WINNER_BY: 'Chọn người chiến thắng bằng',
        VERIFY_EMAIL_ADDRESS_OPTION: 'Xác minh một địa chỉ email khác',
        EMAIL_VIEWS: 'Lượt xem email',
        EMAIL_CLICKS: 'Nhấp chuột qua email',
        AB_CAMPAIGN_EDIT_TAB_LABEL: 'Chỉnh sửa Chiến dịch A/B',
        BATCH_EDIT: 'Chỉnh sửa hàng loạt',
        AB_CAMPAIGN_COPY_TAB_LABEL: 'Sao chép Chiến dịch A/B',
        AB_CAMPAIGN_CREATE_TAB_LABEL: 'Tạo Chiến dịch A/B',
        ALL_AB_CAMPAIGNS: 'Tất cả các chiến dịch AB',
        AB_TESTS: 'Thử nghiệm A/B',
        MESSAGE_A: 'Tin nhắn A',
        MESSAGE_B: 'Tin nhắn B',
        AFTER: 'sau',
        SCHEDULE_AB_CAMPAIGN: 'Lịch trình A/B chiến dịch',
        WINNER_BASEDON_A: 'Người chiến thắng (dựa trên tin nhắn A)',
        WINNER_BASEDON_B: 'Người chiến thắng (dựa trên Thông điệp B)'
    },
    CALENDAR: {
        CALENDAR_TASKS: 'Lịch/nhiệm vụ',
        CALENDAR_ONLY: 'Lịch',
        CALENDARS: 'Lịch cho',
        SHOW_OPTION_1: 'Đã hủy',
        SHOW_OPTION_2: 'Đã hoàn thành',
        SHOW_OPTION_3: 'Lên kế hoạch',
        TODAY: 'Hôm nay',
        BACK: 'Trở lại',
        NEXT: 'Tiếp theo',
        DAY: 'Ngày',
        WEEK: 'Tuần',
        MONTH: 'Tháng',
        AGENDA: 'Chương trình',
        DATE: 'Ngày',
        TIME: 'Thời gian',
        EVENT: 'Sự kiện',
        ALL_DAY: 'cả ngày',
        WORK_WEEK: 'tuần làm việc',
        YESTERDAY: 'ngày hôm qua',
        TOMMOROW: 'Ngày mai',
        SHOW_MORE: '',
        TYPE: 'Loại',
        MY_CALENDAR_SETTING: 'Cài đặt lịch của tôi',
        DISPLAY:'Trưng bày',
        ACCOUNT_NAME:'Tên tài khoản',
        JOB_NAME : 'Tên công việc',
        COLOR_CODE: 'Mã màu',

    },
    GROUP: {
        GROUP_NAME: 'Tên nhóm',
        DELETE_RECORD: 'Xóa hồ sơ',
        USED_IN: 'Được sử dụng trong',
        REMOVE_FROM: 'Loại bỏ từ',
        SELECT_RECORDS: 'Bản ghi đã chọn',
        ALL_RECORDS: 'Tất cả hồ sơ',
        SELECT_OPTIONS: 'Chọn tùy chọn',
        GROUP_FILTER: 'Chọn bộ lọc nhóm',
        ALL: 'Tất cả',
        ACTIVE: 'Hoạt động',
        INACTIVE: 'Không hoạt động',
        DETAILS: 'Chi tiết',
        'Active': 'Hoạt động',
        'Inactive': 'Không hoạt động',
        'New': 'Mới',
        'scheduled': 'lên kế hoạch',
        'Queued': 'Đã xếp hàng đợi',
        'Executed': 'Thực thi',
        'Running': 'Đang chạy',
        'Halted': 'Đã tạm dừng',
    },
    APPOINTMENTS_TASKS: {
        MY_TASKS: 'Nhiệm vụ',
        PERSONALIZE_OPTION_1: 'Quá hạn',
        PERSONALIZE_OPTION_2: 'Hôm nay',
        PERSONALIZE_OPTION_3: 'Tương lai',
        PERSONALIZE_OPTION_4: 'Ngày mai',
        PERSONALIZE_SORT_1: 'Ngày',
        PERSONALIZE_SORT_2: 'Sự ưu tiên',
        TASKS_FOR: 'Nhiệm vụ cho',
        SORT_BY: 'Sắp xếp theo',
        COMPLETE_THIS_APPOINTMENT: 'Hoàn tất cuộc hẹn này',
        COMPLETE_THIS_TASK: 'Hoàn thành tác vụ này',
        NEXT_DAY: 'Ngày tiếp theo',
        NEXT_WEEK: 'Tuần tới',
        COMPLETE: 'Hoàn thành',
        DELETE: 'Xóa bỏ',
        TASKS: 'Nhiệm vụ',
        APPOINTMENTS: 'Cuộc hẹn',
        ADD_NOTE: 'Thêm ghi chú (tùy chọn):',
        NEXT_MONTH: 'Tháng tới',
        DAYS: 'Ngày',
        SPECIFIC_DATE: 'Ngày cụ thể',
        RESCHEDULE: 'Sắp xếp lại',
        RESCHEDULE_FOR: 'Lên lịch lại cho:',
        REPEAT: 'Lặp lại',
        DAY_OF_THE_MONTH: 'ngày của tháng',
        DAY_OF_THE_WEEK: 'ngày trong tuần',
        REPEAT_TYPE: 'Loại lặp lại',
        REPEAT_ON: 'Lặp lại trên',
        REPEAT_BY: 'Lặp lại bởi',
        START_DATE: 'Ngày bắt đầu',
        END_DATE: 'Ngày kết thúc',
        REPEAT_EVERY: 'Lặp lại mọi',
        REMOVE_REMINDER: 'Loại bỏ nhắc nhở',
        ONLY_THIS: 'Chỉ này',
        FOLLOWING: 'Sau',
        ALL: 'Tất cả',
        THIS:'Điều này',
        THIS_AND_FOLLOWING:'Cái này và cái sau',
        ONLY_THIS_RECORD: 'Chỉ có hồ sơ này',
        FOLLOWING_RECORDS: 'Hồ sơ sau',
        ALL_RECORDS: 'Tất cả hồ sơ',
        ADD_A_NOTE: 'Thêm một lưu ý',
        COMPLETE_TASK_OPTIONAL: 'Hoàn thành nhiệm vụ đang chờ xử lý',
        COMPLETE_TASK_OPTIONAL_OBJ: 'Hoàn thành nhiệm vụ / email / tin nhắn văn bản đang chờ xử lý',
        CREATE_NEXT_TASKS: 'Tạo các nhiệm vụ tiếp theo',
        CREATE_NEXT_TASKS_OBJ: 'Tạo các tác vụ / email / tin nhắn văn bản tiếp theo',
        PENDING_TASKS: 'Các nhiệm vụ đang chờ giải quyết',
        SEARCH_BY_NAME: 'Tìm kiếm theo tên',
        INVITE_GUEST: 'Mời những vị khách',
        ATTENDEES: 'Người tham dự',
        SALES_REP: 'Đại diện bán hàng',

        //not found in doc
        ADD_REMINDER: 'Thêm lời nhắc',
        'Email': 'Thư điện tử',
        'Pop-up': 'Cửa sổ bật lên',
        'minutes': 'Phút',
        'hours': 'Giờ',
        'days': 'Ngày',
        'weeks': 'Tuần',
        DAILY: 'hằng ngày',
        WEEKLY: 'Hàng tuần',
        MONTHLY: 'Hàng tháng',
        YEARLY: 'Hàng năm',
        DAYS_S: 'Ngày',
        WEEKS: 'Tuần',
        MONTHS: 'Tháng',
        YEARS: 'năm',
        APPOINTMENT: 'Cuộc hẹn',
        TASK: 'Bài tập',
        SUBJECT: 'Môn học',
        ASSIGN_TO: 'Giao cho',
        COMPANY: 'Công ty',
        CONTACT_NAME: 'Tên Liên lạc',
        OUTLOOK_SYNC: 'Đồng bộ hóa Outlook',
        GOOGLE_SYNC: 'Google đồng bộ hóa',
        'Scheduled': 'Lên kế hoạch',
        'Completed': 'Đã hoàn thành',
        'Cancelled': 'Đã hủy',
        'Pending': 'Đang chờ xử lý',
        TASK_NAME: 'Tên nhiệm vụ',
        DUE_BY: 'Do bởi',
        DESCRIPTION: 'SỰ MIÊU TẢ',
        OWNER: 'Chủ nhân',
        FOLLOWUP: 'Theo dõi',
        'All': 'Tất cả',
        'Empty': 'Trống',
        'No': 'Không',
        'Yes': 'Vâng',
        'Everyone': 'Tất cả mọi người',
        'Just me': 'Chỉ mình tôi',
        ALL_TASK_COMPLETED: 'Những nhiệm vụ này đã được hoàn thành.',
        'duration': 'thời lượng',
        'days_after': 'nhiều ngày sau đó',
        DEP_TASK_NAME: 'Tên nhiệm vụ',
        TASK_COMPLETED: 'Nhiệm vụ hoàn thành(%)',
        APPOINTMENTS_AND_TASKS: 'Các cuộc hẹn và nhiệm vụ',
        APPOINTMENTS_ONLY: 'Chỉ cuộc hẹn',
        TASKS_ONLY: 'Chỉ nhiệm vụ',
        EDIT: 'Chỉnh sửa',
        SENDNOW: 'Gửi ngay bây giờ',
        OPPORTUNITY_NAME: 'Tên cơ hội',
        SELECT_USER: 'Chọn người dùng',
        SELECT_STATUS: 'Chọn trạng thái',
        VISITS: 'Lượt truy cập',
        UNIT_OWNER_TASKS: 'Nhiệm vụ của Đơn vị',
        COMPLETE_UNIT_TASK_OPTIONAL: 'Hoàn thành nhiệm vụ đang chờ chủ sở hữu đơn vị',
        UNIT_TASKS: 'Nhiệm vụ cho đơn vị',
        DOCUMENTS: 'Các tài liệu',
        LINKS: 'Liên kết',
        TASK_SETTINGS: 'Cài đặt tác vụ',
        APPOINTMENT_SETTINGS: 'Cài đặt cuộc hẹn',
    },
    ATTACHMENT: {
        UPLOAD: 'Tải lên',
        MY_COMPUTER: 'Máy tính của tôi',
        BROWSE: 'Trình duyệt',
        DOWNLOAD: 'Tải tệp xuống',
        ATTACH_FILE: 'Đính kèm tệp',
        UPLOADING: 'Đang tải lên',
        NO_IMAGE_FOUND: 'Không tìm thấy hình ảnh',
        DOWNLOAD_RECORDING: 'Tải về các ghi âm',
        ATTACHMENT: 'Tập tin đính kèm',
        ADD_AN_ATTACHMENT: 'Thêm phần đính kèm'

    },
    LIST_VIEW: {
        CLICK_TO_COLLAPSE: 'Click vào đây để Collapse',
        CLICK_TO_EXPAND: 'Click vào đây để mở rộng',
        SHOW_TOTAL: 'Hiện tổng số',
        SHOW_PICTURE: 'Buổi trình diển tranh ảnh',
        DESCENDING: 'Giảm dần',
        SORT_BY: 'Sắp xếp theo',
        SELECT_COLUMN_FOR_VIEW: 'Chọn các trường cho chế độ xem này',
        SELECT_COLUMN_FOR_FILTER_VIEW: 'Chọn cột cho kết quả lọc',
        ADD_AND_REORDER: 'Thêm và sắp xếp lại',
        FIELDS: 'Lĩnh vực',
        CLICK_TO_ADD_MORE_FIELDS: '(bấm vào hộp này để thêm các trường khác. Kéo và sắp xếp lại các trường để thay đổi vị trí của họ.)',
        SELECT_FIELDS: 'Chọn lĩnh vực',
        MODIFY_FIELD: 'Sửa đổi trường',
        EXPORT_SELECTED_RECORDS: 'Xuất bản ghi đã chọn',
        EXPORT_ALL_RECORDS: 'Xuất tất cả hồ sơ',
        LINK_EXISTING: 'Liên kết hiện tại',
        SET_UP: 'Thiết lập',
        GROUP_BY: 'Nhóm theo',
        FIND_BY_FIELD: 'Tìm bằng',
        FILTER_TYPE: {
            DEFAULT_FILTERS: 'Bộ lọc mặc định',
            SHARED_FILTERS: 'Bộ lọc chia sẻ'
        },
        COLUMN_VIEW_SETUP_INFO: 'Chế độ xem cột này sẽ chỉ hiển thị năm trường đầu tiên. Chế độ xem danh sách hiển thị tất cả các trường.',
    },
    DETAIL_VIEW: {
        SHOW_LABELS: 'Hiện nhãn',
        SELECT_OPTIONS: 'Chọn tùy chọn',
        CLICK_TO_ADD_MORE_FIELDS: 'Bấm vào hộp này để thêm các trường khác. Kéo và sắp xếp lại các trường để thay đổi thứ tự của họ. Bạn có thể chọn tối đa 50 lĩnh vực.',
        SETUP_FIELDS: 'Lĩnh vực thiết lập',
        ADD_OR_REMOVE_FROM_LIST: 'Thêm/loại bỏ các trường khỏi danh sách này',
        CLICK_TO_KNOW_MORE: 'Nhấn vào đây để biết thêm chi tiết',
        CLICK_TO_ADD_CHILD_OBJECTS: 'Bấm vào hộp này để thêm các đối tượng con khác. Kéo và sắp xếp lại các đối tượng để thay đổi vị trí của họ.',
        SETUP_CHILD_TABS: 'Thiết lập tab con',
        SHOW_LABELS_AGAINST_FIELD: 'Lựa chọn này để hiển thị nhãn chống lại các trường.',
        CUSTOMIZE_CHILD: 'tùy chỉnh Child',
        REFUND_CHILD_LABEL: 'Đền bù',
        //not found in doc 
        GROUPS: 'Nhóm',
        MANAGE: 'Quản lý',
        ADD_TO_GROUP: 'Thêm vào nhóm',
        DATA_TIP_1: 'Điều này sẽ tạo ra các nhiệm vụ cho ',
        DATA_TIP_2: 'Điều này sẽ tạo ra các tác vụ / email / tin nhắn văn bản cho ',
        DATA_TIP_3: ' sân khấu',

        HIGHLIGHT_LABEL_OPPORTUNITY: 'Cơ hội $',
        HIGHLIGHT_LABEL_FORECAST: 'Dự báo $',
        HIGHLIGHT_LABEL_CLOSING_ON: 'Đóng cửa',
        HIGHLIGHT_LABEL_OPPORTUNITY_AGE: 'Tuổi cơ hội',
        HIGHLIGHT_LABEL_DAYS: 'Ngày',

        CHILD_SETUP_SELECT_CHILD_LABEL: 'Nhấn vào đây để thêm một đối tượng con vào thiết lập.',
        NO_SETUP_OPTION: 'không ai'
    },
    RELATIONSHIP: {
        DEFINE_RELATIONSHIP: 'Xác định mối quan hệ',
        RELATE: 'Liên quan',
        COMPANY: 'Công ty',
        FULL_NAME: 'Tên đầy đủ',
        EMAIL: 'Thư điện tử'
    },
    AUTOMATION_DESIGNER: {
        TOUCH_CAMPAIGN_REPORT: 'Chạm vào Báo cáo chiến dịch',
        INTERVAL: 'Sau',
        NOTE_TYPE: 'Lưu ý loại',
        TEXT_REQUIRED: 'Văn bản Ghi chú (bắt buộc)',
        TASK_NAME_REQUIRED: 'Tên tác vụ (Cần thiết)',
        OWNER_REQUIRED: 'Chủ sở hữu (Cần thiết)',
        DUE_BY_REQUIRED: 'Do bởi (Cần thiết)',
        DAY_AFTER_REQUIRED: 'Days after (required)',
        EMAIL: 'Thư điện tử',
        POP_UP: 'Cửa sổ bật lên',
        MINUTES: 'Phút',
        HOURS: 'Giờ',
        DAYS: 'Ngày',
        WEEKS: 'Tuần',
        REMINDER: 'Nhắc nhở',
        GROUP_REQUIRED: 'Chọn Nhóm (bắt buộc',
        ADD_TO_GROUP: 'Thêm vào nhóm',
        MOVE_TO_GROUP: 'Chuyển đến nhóm',
        REMOVE_FROM_GROUP: 'Loại bỏ khỏi nhóm',
        STATUS_REQUIRED: 'Tình trạng (bắt buộc)',
        WORKFLOW_REQUIRED: 'Quy trình làm việc (bắt buộc)',
        CHOOSE_ANOTHER_FIELD: 'Chọn một trường',
        CHOOSE: 'Chọn',
        FIELD_FOR: 'một lĩnh vực cho',
        CAMPAIGN_NAME: 'Tên chiến dịch (bắt buộc)',
        START: 'Bắt đầu',
        STOP: 'Dừng',
        REPORT: 'Báo cáo',
        RESUME: 'Tiếp tục',
        SELECT_PAGE: 'Chọn một trang',
        ENTER_WEB_PAGE_LINK_OPTIONAL: 'Nhập URL của liên kết (tùy chọn).',
        ENTER_WEB_PAGE_LINK: 'Nhập liên kết trang web.',
        CLICK_TO_VIEW: 'Click vào đây để xem',
        CLICK_TO_EDIT: 'Click vào đây để chỉnh sửa',
        DRAG_TO_MOVE: 'Kéo để di chuyển',
        DRAG_TO_CONNECT: 'Kéo để kết nối',
        CLICK_TO_REMOVE: 'Click vào đây để loại bỏ',
        CLICK_TO_SEE_REPORT: 'Click vào đây để xem báo cáo',
        CLICK_TO_ADD_MSG: 'Bấm để thêm thư mới',
        NO: 'Không',
        YES: 'Vâng',
        CLICK_TO_SELECT_USERS: 'Bấm vào đây để chọn người dùng',
        SALES_REP: 'Đại diện bán hàng',
        USERS: 'Người dùng',
        CAMPAIGN_SENDER: 'Người gửi chiến dịch',
        SCORE: 'Điểm',
        START_WITH: 'Thêm',
        DECISIONS: 'Quyết định',
        ACTIONS: 'Hành động',
        LINK_CLICKED: 'Liên kết nhấp vào?',
        SCORE_REACHED: 'Điểm đạt được?',
        TOUCH_CAMPAIGN: 'Touch chiến dịch',
        WEB_FORM: 'Hình thức web',
        LANDING_PAGE: 'Trang đích',
        WEBSITE_VISITED: 'Trang web đã truy cập',
        CHANGE_GROUP: 'Đổi nhóm',
        ADD_NOTE: 'Thêm ghi chú',
        NOTIFY: 'Thông báo',
        ADD_TASK: 'Thêm tác vụ',
        FIELD_UPDATED: 'Cập nhật các lĩnh vực',
        CHOOSE_TEMPLATE: 'Chọn một mẫu',
        USE_THIS_TEMPLATE: 'Sử dụng mẫu này',
        WAIT: 'Đợi',
        NAME: "Tên",
        TARGET_GROUPS: 'Nhóm mục tiêu (s)',
        TARGET_FILTER: 'Mục tiêu lọc',
        RESEND_AFTER: 'Gửi lại sau khi',
        START_DATE: 'Ngày bắt đầu',
        SUBJECT: 'Chủ đề',
        TEXT: 'Bản văn',
        TASK_NAME: 'Tên tác vụ',
        ASSIGN_TO: 'Gán cho',
        FIELD_LABEL: 'Lĩnh vực Nhãn',
        FIELD_VALUE: 'Giá trị trường',
        LINK: 'Liên kết',
        SEND_ON_ANY_TIME: 'Gửi vào bất kỳ thời gian',
        SEND_ON_BUSINESS_DAY: 'Chỉ gửi vào ngày kinh doanh',
        CONVERT_TO: 'Chuyển đổi để',
        QUALIFIER: 'Vòng loại',
        OBJECT: 'Đối tượng',
        NOTIFY_SENDER: 'Thông báo cho người gửi',
        NOTIFY_SALES_REP: 'Thông báo cho đại diện bán hàng',
        NOTIFY_SENDER_SALESREP: 'Thông báo cho người gửi thông báo đại diện bán hàng',
        NOTIFY_USERS: 'Thông báo cho người dùng',
        TEST: 'Thử nghiệm',
        VIEW_AS_WEBPAGE_INFO: 'Tùy chọn này sẽ thêm một liên kết ở đầu email để mở email như một trang web',
        LINK_CLICK_INFORMATION: 'Nếu bạn để trống này, tất cả các liên kết trong email sẽ được theo dõi.',
        GROUP_NAME: 'Tên nhóm mới',
        SELECT_USER: 'Giao cho',
        REMIND_BEFORE: 'Nhắc nhở trước khi',
        INTERVAL_REQUIRED: 'Khoảng (yêu cầu)',
        //not found in doc
        VERIFY_EMAIL_ADDRESS_OPTION: 'Xác minh một địa chỉ email khác',
        'Touch Campaign': 'Touch chiến dịch',
        'Link Clicked': 'Liên kết nhấp vào',
        'Link Clicked?': 'Liên kết nhấp vào?',
        'Email Opened': 'Đã mở email',
        'Email Opened?': 'Đã mở email',
        'Unsubscribed': 'Đã hủy đăng ký',
        'Score Reached': 'Điểm đạt được',
        // 'Send One-off Email': 'Gửi email một-off',
        'Send Email': 'Gửi email',
        'Change Group': 'Đổi nhóm',
        'Add Note': 'Thêm ghi chú',
        'Notify': 'Thông báo',
        'Add Task': 'Thêm tác vụ',
        'Field Updated': 'Lĩnh vực Cập nhật',
        'Assign': 'Gán',
        'Converted': 'Chuyển',
        'Start': 'Khởi đầu',
        'Add Touch Message': 'Thêm tin nhắn cảm ứng',
        'Web Form': 'Hình thức web',
        'Website Visit': 'Lượt truy cập trang web',
        'Landing Page': 'Trang đích',
        'Wait': 'Chờ đợi',
        WAIT_FOR_DAYS: 'ngày',
        'Simple email nurturing': 'Email đơn giản nuôi dưỡng',
        'Capture leads using a web form and nurture': 'Thu hút khách hàng tiềm năng bằng cách sử dụng biểu mẫu web và nuôi dưỡng',
        'Track web page visit and nurture': 'Theo dõi lượt truy cập trang web và nuôi dưỡng',
        'Capture leads from a landing page and nurture': 'Thu hút khách hàng tiềm năng từ trang đích và nuôi dưỡng',
        'Start with touch campaign': 'Bắt đầu với chiến dịch chạm',
        'Start with web form': 'Bắt đầu với biểu mẫu web',
        'Start with tracking website visit': 'Bắt đầu với theo dõi lượt truy cập trang web',
        'Start with landing page': 'Bắt đầu với trang đích',
        COPY_OF: 'Bản sao của ',
        AUTOMATION_CAMPAIGN_CREATE_TAB_LABEL: 'Chiến dịch tự động mới',
        TARGET_BY: 'Nhắm mục tiêu theo',
        'Hours': 'Giờ',
        'Hour(s)': 'Giờ',
        'day(s)': 'ngày (s)',
        'hour(s)': 'giờ (s)',
        ALL_AUTOMATED_CAMPAIGNS: 'Tất cả các chiến dịch tự động',
        MESSAGE: 'Thông điệp',
        SELECT_A_CAMPAIGN: 'Vui lòng chọn một chiến dịch',
        ADD_SIGNATURE: 'Thêm ngôn ngữ',
        BIRTHDAY_CAMPAIGN: 'Sinh Nhật chiến dịch',
        'Add Birthday Message': 'Thêm thông điệp sinh nhật',
        'Start with Birthday Campaign': 'Bắt đầu với chiến dịch sinh nhật',
        'SMS Replied': 'SMS đã trả lời',
        'Send SMS': 'Gửi tin nhắn',

        'Touch Campaign Automation': 'Tự động hóa chiến dịch cảm ứng',
        'Birthday Campaign Automation': 'Tự động hóa chiến dịch sinh nhật',
        'Web Form Automation': 'Tự động hóa mẫu web',
        'Landing Page Automation': 'Tự động hóa trang đích',

        SEND_SMS: 'Gửi tin nhắn',
        SMS_REPLY: 'SMS trả lời?',
        BIRTHDAY_CAMPAIGN_REPORT: 'Báo cáo chiến dịch sinh nhật',
        START_IMMEDIATELY: 'Ngay',
        START_SCHEDULE: 'Lịch trình',
        SCHEDULE_ON_TEXT: 'Lên lịch để bắt đầu',
        SCHEDULE_DIALOG_TITLE: 'Chọn ngày giờ',
        FROM_NUMBER: 'Từ số',
        REPLY: 'Đáp lại',
        NOT_CONVERTED: 'Không được chuyển đổi'
    },
    TOUCH_CAMPAIGNS: {
        ALL_TOUCH_CAMPAIGNS: 'Tất cả chiến dịch cảm ứng',
    },
    MARKETING_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Cá nhân hoá chủ đề',
        NAME_REQUIRED: 'Tên (bắt buộc)',
        STATUS: 'Tình trạng',
        SUBJECT_REQUIRED: 'Tiêu đề (bắt buộc)',
        ACTIVE: 'Hoạt động',
        INACTIVE: 'Không hoạt động',
        TEST: 'Thử nghiệm',
        SELECT_TEMPLATE: 'Bắt đầu nhập để chọn mẫu',
        TYPE_HERE: 'Loại ở đây.',
        ADD_SIGNATURE: 'Thêm chữ ký',
        INSERT_IMAGE: 'Chèn hình ảnh',
        PERSONALIZE: 'Cá nhân hoá',
        SELECT_FROM_LIBRARY: 'Chọn từ thư viện',
        USE_THIS_DESIGN: 'Sử dụng thiết kế này',
        DEAR: 'Thân mến',
        EXPAND_EDITOR: 'Toàn màn hình',
        SHARE: 'Sử dụng cho cả hai tiếp thị và bán hàng email',
        MARKETING_TEMPLATE_CREATE_TAB_LABEL: 'Tạo email tiếp thị mẫu',
        COPY_OF: 'Sao chép_của',
        BATCH_EDIT: 'Chỉnh sửa hàng loạt',
        MARKETING_TEMPLATE_EDIT_TAB_LABEL: 'Chỉnh sửa mẫu email tiếp thị',
        MARKETING_TEMPLATE_COPY_TAB_LABEL: 'Copy Marketing mẫu email',
        CATEGORY: 'thể loại',
        LAYOUT: 'Bố trí',
        'Auto Response': 'Trả lời tự động',
        'Marketing Template': 'Mẫu tiếp thị',
        'Newsletter': 'Bản tin',
        'Greetings': 'Lời chào hỏi',
        CHOOSE_EMAIL_BUILDER: 'Chọn một builder email',
        DRAG_AND_DROP_BUILDER: 'Sử dụng trình xây dựng kéo và thả mới',
        LEGACY_BUILDER: 'Sử dụng Builder Legacy',
        TEMPLATE_INFO: 'Tiêu bản thông tin',
        DESIGN: 'Thiết kế',
    },
    SALES_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Cá nhân hoá chủ đề',
        NAME_REQUIRED: 'Tên (bắt buộc)',
        STATUS: 'Tình trạng',
        SUBJECT_REQUIRED: 'Tiêu đề (bắt buộc)',
        ACTIVE: 'Hoạt động',
        INACTIVE: 'Không hoạt động',
        TEST: 'Thử nghiệm',
        TYPE_HERE: 'Loại ở đây.',
        ADD_SIGNATURE: 'Thêm chữ ký',
        INSERT_IMAGE: 'Chèn hình ảnh',
        PERSONALIZE: 'Cá nhân hoá',
        DEAR: 'Thân mến',
        SALES_TEMPLATE_CREATE_TAB_LABEL: 'Tạo mẫu email bán hàng',
        SALES_TEMPLATE_EDIT_TAB_LABEL: 'Chỉnh sửa mẫu email bán hàng',
        SALES_TEMPLATE_COPY_TAB_LABEL: 'Bản sao bán hàng email mẫu',
        COPY_OF: 'Sao chép_của',
        SALES_TEMPLATES: 'Mẫu email bán hàng',
    },
    AGREEMENT_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Cá nhân hoá chủ đề',
        NAME_REQUIRED: 'Tên (bắt buộc)',
        STATUS: 'Tình trạng',
        SUBJECT_REQUIRED: 'Tiêu đề (bắt buộc)',
        ACTIVE: 'Hoạt động',
        INACTIVE: 'Không hoạt động',
        TEST: 'Thử nghiệm',
        TYPE_HERE: 'Loại ở đây.',
        ADD_SIGNATURE: 'Thêm chữ ký',
        INSERT_IMAGE: 'Chèn hình ảnh',
        PERSONALIZE: 'Cá nhân hoá',
        DEAR: 'Thân mến',
        AGREEMENT_TEMPLATE_CREATE_TAB_LABEL: 'Tạo mẫu thỏa thuận',
        AGREEMENT_TEMPLATE_EDIT_TAB_LABEL: 'Chỉnh sửa mẫu thỏa thuận',
        AGREEMENT_TEMPLATE_COPY_TAB_LABEL: 'Bản sao mẫu thỏa thuận',
        COPY_OF: 'Sao chép_của',
        AGREEMENT_TEMPLATES: 'Mẫu thỏa thuận',
        ADD_SIGNATURE_1: 'Chữ ký 1',
        ADD_SIGNATURE_2: 'Chữ ký 2',
        ADD_SIGNATURE_3: 'Chữ ký 3',
        ADD_SIGNATURE_4: 'Chữ ký 4',
        ADD_SIGNATURE_5: 'Chữ ký 5',
        COVER_LETTER: 'Thư xin việc',
    },
    SMS_TEMPLATE: {
        SMS_TEMPLATE_EDIT_TAB_LABEL: 'Chỉnh sửa mẫu SMS',
        SMS_TEMPLATE_COPY_TAB_LABEL: 'Sao chép mẫu tổng hợp',
    },
    WEBSITE_TRACKING: {
        DOMAIN_NAME: 'Nhập tên miền của bạn (loại trừ http hoặc www)',
        GENERATE_TRACKING_CODE: 'Tạo mã theo dõi',
        TRACKING_CODE: 'Mã theo dõi của bạn',
        COPY_TO_CLIPBOARD: 'Sao chép vào clipboard',
        DONE: 'Thực hiện'

    },
    WEB_FORM: {
        SELECT_FORM: 'Chọn mẫu đơn',
        WEB_FORM_CREATE_TAB_LABEL: 'Tạo biểu mẫu web',
    },
    MAIL: {
        EMAIL_FOR_TESTING: 'Nhập email để thử nghiệm',
        SEND_A_TEST_EMAIL: 'Gửi thư thử nghiệm',
        TYPE_HERE: 'Loại ở đây.',
        INSERT_IMAGE: 'Chèn hình ảnh',
        ADD_SIGNATURE: 'Thêm chữ ký',
        PERSONALIZE: 'Cá nhân',
        FROM: 'Từ',
        SEND_MAIL: 'Gửi email',
        PERSONALIZE_SUBJECT: 'Cá nhân hoá chủ đề',
        SUBJECT: 'Chủ đề',
        COMPUTER: 'Từ máy tính',
        LIBRARY: 'Từ thư viện',
        RESCHEDULE: 'Sắp xếp lại',
        TO: 'Đến',
        ATTACH: 'Đính kèm tệp',
        CC: 'CC',
        WHEN: 'Khi nào',
        SENDING_TEST_MAIL: 'Gửi email kiểm tra ...',
        SCHEDULE: 'Lịch trình',
        SCHEDULE_DIALOG_TITLE: 'Chọn ngày giờ',
        SEND_LATER: 'GỬI SAU',
        SEND: 'Gửi',
        RECIPIENTS: 'Người nhận',
        SENDING: 'Gửi...'
    },
    SMS: {
        TO: 'Đến',
        WHEN: 'Khi nào',
        FROM: 'Từ',
        FROM_NUMBER: 'Từ số',
        SEND_A_TEST_SMS: 'Gửi tin nhắn kiểm tra',
        TO_NUMBER: 'Đến số',
        STATUS: 'Trạng thái',
        ENTER_NUMBER :'Nhập số để kiểm tra'
    },
    AUDIT_LEADSCORE: {
        LEAD_SCORE_HISTORY: 'Dẫn điểm lịch sử ',
        SCORE: 'Điểm',
        DATE: 'Ngày',
        ACTION: 'Hành động',
        USER: 'Người dùng',
        DETAILS: 'Chi tiết',
        ACTION_NAME: 'Tên hành động',
        SCORE_DETAILS: 'Chi tiết điểm',
        BY: 'bởi',
        FROM: 'Từ',
        LINK: 'Liên kết',
        CLICKED: 'đã nhấp',
        EMAIL: 'E-mail',
        OPENED: 'Đã mở',
        UNSUBSCRIBED: 'Đã hủy đăng ký',
        CONVERTED_FROM: 'Chuyển đổi từ',
        TO: 'đến',
        IN_OPPORTUNITIES: 'trong cơ hội',
        ADDED_TO: 'Đã thêm vào',
        REMOVED_FROM: 'Gỡ bỏ từ',
        UPDATED_TO: 'Đã cập nhật lên',
        WEBFORM: 'hình thức web',
        SUBMITTED: 'đã gửi',
        VISITED: 'Đã truy cập',
        EXPIRED: 'Hết hạn',
        'Imported': 'Đã nhập',
        'Created': 'Tạo',
        'Converted to Account': 'Đã chuyển thành tài khoản',
        'Created from Web form': 'Được tạo từ biểu mẫu web',
        'Created from Landing page': 'Được tạo từ trang Đích',
        'Created from Zapier': 'Được tạo từ Zapier',
        'Created from Outlook': 'Được tạo từ Outlook',
        'Created from Outlook (Client)': 'Được tạo từ Outlook (Máy khách)',
        'Created from G Suite': 'Được tạo từ G Suite',
        'Created from Office 365': 'Được tạo từ Office 365',
        'Created from Facebook/twitter etc': 'Được tạo từ Facebook / twitter, v.v.',
        'Pushed': 'Đã đẩy',
        'Created from API': 'Được tạo từ API',
        'Inline/Quick updated': 'Nội tuyến / Cập nhật nhanh',
        'Updated': 'Đã cập nhật',
        'Batch Updated': 'Đã cập nhật hàng loạt',
        'Workflow Applied': 'Đã áp dụng quy trình làm việc',
        'Converted': 'Chuyển đổi',
        'Assigned': 'Giao',
        'Deleted': 'Đã xóa',
        'Restored': 'Đã khôi phục',
        'Merged': 'Hợp nhất',
        'Main Contact updated': 'Đã cập nhật liên hệ chính',
        'Shared': 'Đã chia sẻ',

        'Link Clicked': 'Đã nhấp vào liên kết',
        'Email Opened': 'Đã mở email',
        'Unsubscribed': 'Đã hủy đăng ký',
        'Web Form Submitted': 'Đã gửi biểu mẫu web',
        'Removed from Group': 'Đã xóa khỏi nhóm',
        'Field Updated': 'Đã cập nhật trường',
        'Added to Group': 'Đã thêm vào nhóm',
        'Website Visited': 'Trang web đã truy cập',
        'Has Business Email': 'Có email doanh nghiệp',
        'Clicked Link': 'Liên kết được nhấp',
        'Opened Email': 'Email đã mở',
        ACCOUNT_SCORE: 'điểm hoàn hảo cho tài khoản là',
        CONTACT_SCORE: 'điểm hoàn hảo cho hệ là'
    },
    DASHBOARD: {
        TODAY: 'Hôm nay',
        YESTERDAY: 'Hôm qua',
        THIS_WEEK: 'Tuần này',
        THIS_MONTH: 'Tháng này',
        THIS_QUARTER: 'Quý này',
        THIS_YEAR: 'Năm nay',
        LAST_WEEK: 'Tuần trước',
        LAST_MONTH: 'Tháng trước',
        LAST_QUARTER: 'Cuối quý',
        LAST_YEAR: 'Năm ngoái',
        CUSTOM: 'Tùy chỉnh',

        //Kanban View
        NEXT_MONTH: 'Tháng tiếp theo',
        NEXT_QUARTER: 'Quý tiếp theo',
        NEXT_THREE_MONTHS: 'Ba tháng tiếp theo',
        NEXT_TWO_MONTHS: 'Hai tháng tiếp theo',

        SALES_DASHBOARD: 'Bảng điều khiển bán hàng',
        MARKETING_DASHBOARD: 'Bảng điều khiển Marketing',
        // SALES_MARKETING_DASHBOARD: 'Bảng điều khiển bán hàng và tiếp thị',
        REPOSITION_GADGET: 'Nhấp và kéo để định vị lại tiện ích này',
        'Top Sources By Lead Volume': 'Nguồn hàng đầu theo số lượng khách hàng tiềm năng',
        'Top Sources By Lead Conversion': 'Nguồn hàng đầu theo chuyển đổi khách hàng tiềm năng',
        'Marketing Email Summary': 'Tóm tắt email tiếp thị',
        'Top Marketing Emails by CTR': 'Email tiếp thị hàng đầu theo CTR',
        'Top Web Pages': 'Các trang web hàng đầu',
        'Lead Pipeline': 'Đường ống dẫn',
        'Opportunity Pipeline': 'Đường ống cơ hội',
        'Sales Leaderboard': 'Bảng xếp hạng bán hàng',

        'Status': 'Tình trạng',
        'Count': 'Đếm',
        'Subject': 'Chủ đề',
        // 'Click-Through Rate(CTR)': '',
        'Website Name': 'Tên trang web',
        'Forecast': 'Thời tiết',
        'Sales person': 'Người bán hàng',
        'Closed': 'Đóng cửa',

        'Activities': 'Hoạt động',
        'Accounts': 'Ma trận tài khoản',
        'Opportunities': 'Ma trận cơ hội',
        'Users': 'Người dùng',
        'Appointment': 'Cuộc hẹn',
        'Dialed': 'Quay',
        'Email': 'Email',
        'LVM': 'LVM',
        'Note': 'Lưu ý',
        'Spoke': 'Nói',
        'Task': 'Nhiệm vụ',
        'Transferred': 'Chuyển',

        'Qualified': 'Đủ điều kiện',
        'Presentation': 'Trình bày',
        'Proposal': 'Đề nghị',
        'Negotiation': 'Đàm phán',
        'Close': 'Đóng',

        'Lead': 'Dẫn',
        'Prospect': 'Prospect',
        'Customer': 'Khách hàng',
    },
    LOGIN: {
        LOG_IN: 'Đăng nhập',
        EMAIL_ADDRESS: 'Địa chỉ email',
        PASSWORD: 'Mật khẩu',
        REMEMBER_ME: 'Ghi nhớ tôi '
    },
    SOCIAL_CAMPAIGN: {
        SAVE_AS_DRAFT: 'Tiết kiệm như dự thảo',
        POST_NOW: 'Đăng bây giờ',
        CONNECT: 'Kết nối'

    },
    DOCUMENT_LIBRARY: {
        COMPUTER: 'Máy tính',
        LIBRARY: 'Thư viện',
        IMAGES: 'Hình ảnh',
        STOCK_IMAGES: 'Hình ảnh chứng khoán',
        DOCUMENTS: 'Tài liệu',
        SEARCH: 'Tìm kiếm...',
        UPLOAD_DOCUMENT: 'Tải lên tài liệu',
        NAME: 'Tên',
        TITLE: 'Tiêu đề',
        TITLE_REQUIRED: 'Tiêu đề (bắt buộc)',
        LINK_REQUIRED: 'Liên kết (bắt buộc)',
        TYPE: 'Loại',
        OWNER: 'Chủ sở hữu',
        ALL: 'Tất cả',
        NO_IMAGE_FOUND: 'Không tìm thấy hình ảnh',
        UPLOAD_DIALOG_TITLE: 'Chọn một tài liệu để tải lên',
        SHARED_WITH: 'đã được chia sẻ với',
        UNITS: 'các đơn vị',
        UNIT: 'đơn vị',
        UNIT_PUSH: 'đơn vị:',
        RENAME_FOLDER: 'Đổi tên thư mục',
        CREATE_FOLDER: 'Tạo cặp',
        NEW_FOLDER: 'Thư mục mới',
        ASSETS_NOT_FOUND: 'Không tìm thấy thư mục & tệp.',
        'Folder': 'Thư mục',
        'File': 'Tập tin',
        IMAGE: 'Hình ảnh',
        DOCUMENT_AND_FILE: 'Tài liệu & Files',
    },
    HEADER: {
        SALES_TEMPLATE: 'Mẫu email bán hàng',
        CALENDAR_TASKS: 'Lịch/nhiệm vụ',
        ACCOUNT: 'Tài khoản',
        SALES_REPORT: 'Báo cáo bán hàng',
        ACCOUNTS: 'Tài khoản',
        ACCOUNTS_WITHOUT_GROUP: 'Tài khoản không có nhóm',
        MY_ACCOUNT: 'Tài khoản của tôi',
        TODAYS_ACCCOUNTS: 'Todays tài khoản',
        CONTACTS: 'Liên hệ',
        CONTACTS_WITHOUT_ACCOUNT: 'Liên hệ mà không có tài khoản',
        CONTACTS_WITHOUT_ACCOUNTS: 'Danh sách liên hệ không có tài khoản',
        CONTACTS_WITHOUT_GROUPS: 'Liên hệ mà không có nhóm',
        MY_CONTACTS: 'Liên hệ của tôi',
        TODAYS_CONTACTS: 'Todays Contacts',

        ALL_APPOINMENTS: 'Tất cả các cuộc hẹn',
        MY_APPOINMENTS: 'Cuộc hẹn của tôi',
        MY_COMPLETED_APPOINMENTS: 'Cuộc hẹn đã hoàn thành của tôi',
        MY_MISSED_APPOINMENTS: 'Cuộc hẹn bị lỡ của tôi',
        TODAYS_APPOINMENTS: 'Các cuộc hẹn vào Todays',
        ALL_TASKS: 'Tất cả công việc',
        MY_COMPLETED_TASKS: 'Công việc đã hoàn thành của tôi',
        MY_FUTURE_TASKS: 'Nhiệm vụ tương lai của tôi',
        MY_PENDING_TASKS: 'Tác vụ đang chờ xử lý của tôi',
        MY_TODAYS_TASKS: 'Công việc của tôi todays',
        ONBOARDING: 'Lên tàu',
        SALES_PIPELINE:'Báo cáo quy trình bán hàng',
        COUPONS_CLAIM_REPORTS: 'Báo cáo yêu cầu phiếu giảm giá',
        'Cases': 'Các trường hợp',
        'Projects': 'Dự án',
        'Issues': 'Vấn đề',
        'Solutions': 'Các giải pháp',
        'Contacts': 'Danh bạ',
        'Accounts': 'Tài khoản',
        'Opportunities': 'Cơ hội',
        'Appointments': 'cuộc hẹn',
        'Tasks': 'nhiệm vụ',
        'Onboarding': 'Lên tàu',
        'Units': 'Các đơn vị',
        'Assets': 'Tài sản',
        'Activities': 'Hoạt động',
        'Conversions': 'Chuyển đổi',
        'Campaigns': 'Chiến dịch',
        'Reports': 'Báo cáo',
        'Sales_templates ': 'Mẫu bán hàng',
        'Dashboard': 'bảng điều khiển',
        'Sales Leads': 'Dẫn bán hàng',
        'Recycle Bin': 'Tài khoản',
        REPORT: 'Báo cáo',
        TASKS_PAGE: 'Nhiệm vụ',
        'Products': 'Các sản phẩm',
    },
    ADVANCE_SEARCH: {
        ADVANCE_SEARCH: 'tìm kiếm nâng cao',
        ADD_FIELD:'Tìm kiếm trường khác'
    },
    IMPORT: {
        GROUP: 'Nhóm (bắt buộc)',
        WORKFLOW: 'Workflow (yêu cầu)',
        STATUS: 'Tình trạng (bắt buộc)',
        SOURCE: 'Nguồn (bắt buộc)',
        IMPORT: 'Nhập khẩu',
        LINK_USING: 'Liên kết sử dụng',
        LINK_WITH: 'Liên kết với',
        APPLY_WORKFLOW: 'Áp dụng Workflow',
        AUTO_MAP: 'Bản đồ tự động',
        USE_SAVED_MAPPING: 'Sử dụng bản đồ đã lưu',
        SAVE_MAPPING: 'Lưu ánh xạ',
        DELETE_MAPPING: 'Xóa ánh xạ',
        DONOT_CHECK_DUPLICATE: 'Không kiểm tra các tính trùng',
        CHECK_DUPLICATE: 'Kiểm tra trùng lặp',
        CHECK_DUPLICATE_BY: 'Kiểm tra trùng lặp theo',
        DONOT_IMPORT: 'Không nhập',
        MARK_DUPLICATE: 'Nhập và đánh dấu làm bản sao',
        UPDATE: 'Cập nhật',
        //not found in doc
        STEP_1: 'Chọn nguồn',
        STEP_2: 'Trường & Nhập bản đồ',
        FINISH: 'Kết thúc',
        NEXT: 'Tiếp theo',
        MAP_CSV_COLUMNS: 'Bản đồ CSV cột để CRM',
        MAP_XLS_COLUMNS: 'Bản đồ XLS cột để CRM',
        MAP_XLSX_COLUMNS: 'Map XLSX Columns to CRM',
        MAP_TO_CRM_FIELD: 'Bản đồ đến CRM Field',
        COLUMN: 'Cột',
        ADD_NEW_GROUP: 'Thêm nhóm mới',
        WORKFLOW_OPTIONAL: 'Dòng công việc ( Tùy chọn )',
        FILE_MUST_CSV: 'Tệp phải ở định dạng CSV.',
        SKIP: 'Nhảy',
        MERGE: 'Merge',
        CHECK_DUPLICATE_USING: 'Kiểm tra trùng lặp bằng',
    },
    REPORT: {
        START_DATE: 'Ngày bắt đầu',
        END_DATE: 'Ngày kết thúc',
        REPORT_ON: 'Báo cáo về',
        REFRESH: 'Làm tươi',
        PRINT: 'In',
        SALES_REP: 'Đại diện bán hàng',
        DATE_RANGE: 'Phạm vi ngày',
        SALES_REPORT: 'Báo cáo bán hàng',
        SERVICE_REPORT: 'Báo cáo dịch vụ',
        'Activity Report': 'Báo cáo hoạt động',
        'Conversion Report': 'Báo cáo chuyển đổi',
        'Call Report': 'Báo cáo cuộc gọi',
        'Pipeline Report': 'Báo cáo đường ống',
        'Customer Activation Report': 'Báo cáo kích hoạt khách hàng',
        'Customer Service Type Report': 'Báo cáo loại dịch vụ khách hàng',
        'Customer Assessment Report': 'Báo cáo đánh giá khách hàng',
        'Customer Leads Assessment Report': 'Khách hàng Leads báo cáo đánh giá',
        'Customer Status Report': 'Báo cáo tình trạng khách hàng',
        'Sales Pipeline Report': 'Báo cáo đường ống bán hàng',
        'Days in Franchise Workflow Report': 'Franchise Workflow báo cáo',
        'Franchise Workflow Report': 'Franchise Workflow báo cáo',
        'Franchise Sales Pipeline Report': 'Franchise bán hàng Pipeline báo cáo',
        'Franchise Employees Report': 'Báo cáo nhân viên nhượng quyền',
        'Franchise Last Contact Report': 'Nhượng quyền thương mại cuối báo cáo liên hệ',
        'InXpress User Baseline Report': 'Inxpress cơ sở người dùng báo cáo',
        'Franchise User Report': 'Báo cáo người dùng nhượng quyền',
        'Sales Summary Report': 'Báo cáo tóm tắt bán hàng',
        ACTIVITY_MATRIX: 'Hoạt động ma trận',
        'ACTIVITY_TYPE': 'Loại hoạt động',
        'Service Reports': 'Báo cáo dịch vụ',
        'Project Reports': 'Báo cáo dự án',
        'CRM Activity by Account': 'CRM Activity by Account',
        'Activity by Franchise': 'Hoạt động của Franchise',
        'Franchise Activity Report': 'Báo cáo hoạt động nhượng quyền thương mại',
        'Issue Reports': 'Báo cáo sự cố',
        'Customer Service and Sales Rep Listing': 'Danh sách đại diện bán hàng và dịch vụ khách hàng',
        GO: 'Đi',
        'CRM Account Details': 'Chi tiết tài khoản CRM',
        'Activity by Coach': 'Hoạt động của HLV',
        'Franchise Conversion Report': 'Báo cáo chuyển đổi nhượng quyền thương mại',
        'Tasks Report': 'Báo cáo nhiệm vụ',
        'Email Report': 'Báo cáo qua email',
        'SMS Report': 'Báo cáo SMS',
        'Orthotic Report': 'Báo cáo chỉnh hình',
        'Greenlight Export': 'Greenlight Export',
        REPORT_ON_OBJECTS: {
            ACCOUNTS: 'Tài khoản',
            OPPORTUNITIES: 'Cơ hội'
        },
        'Count': 'Đếm',
        'Grand Total': 'Tổng cộng',
        ACTIVITIES_BY_TYPE: 'Hoạt động theo loại',
        ACTIVITIES_BY_SALES_REP: 'Hoạt động theo đại diện bán hàng',
        ALL_CONVERSIONS_BY_STATUS: 'Tất cả chuyển đổi theo trạng thái',
        CUSTOMERS_BY_SOURCE: 'Khách hàng theo nguồn',
        DEALS_BY_SALES_PER: 'Đường ống của đại diện bán hàng',
        DEALS_BY_STAGES: 'Ưu đãi theo giai đoạn',
        TOTAL_CALLS_BY_SALES_REP: 'Tổng số cuộc gọi theo đại diện bán hàng',
        TOTAL_CALL_DURATION_BY_SALES_REP: 'Tổng thời lượng cuộc gọi theo đại diện bán hàng',
        EXCEL: 'Excel',
        MATRIX_BY_STATUS: 'Ma trận theo trạng thái',
        MATRIX_BY_SOURCE: 'Ma trận theo nguồn',
        'Status': 'Trạng thái',
        'Source': 'Nguồn',
        '5 Ways Report': 'Báo cáo 5 cách',
        'Forecast Report': 'Báo cáo dự báo',
        CASES_BY_STATUS: 'Các trường hợp theo trạng thái',
        CASES_BY_PRIORITY: 'Các trường hợp theo mức độ ưu tiên',
        PROJECTS_BY_STATUS: 'Dự án theo trạng thái',
        PROJECTS_BY_TYPE: 'Dự án theo loại',
        ISSUES_BY_STATUS: 'Các vấn đề theo trạng thái',
        ISSUES_BY_PRIORITY: 'Vấn đề theo mức độ ưu tiên',
        SUPPORT_MATRIX: 'Hỗ trợ ma trận',
        CUSTOMER_BY_CASES: 'Khách hàng theo trường hợp',
        PROJECT_MATRIX: 'Ma trận dự án',
        ISSUE_MATRIX: 'Ma trận phát hành',
        SUB_TOTAL: 'Tổng số phụ',
        SERVICE_MATRIX_HEADER: {
            'Assigned to': 'Phân công',
            'Total count': 'Tổng số',
            'Company': 'Công ty',
            'Assigned To': 'Phân công',
        },
        COUPONS_NAME: 'Chọn phiếu giảm giá',
        PRODUCT_SELECT: 'Chọn sản phẩm',
        AVAILABLE_REPORTS: 'Báo cáo có sẵn',
        WORKFLOW_STAGES: 'Giai đoạn',
        CHART_TYPE: 'Loại biểu đồ',
        SHOW: 'Trình diễn',
        VALUE: 'Giá trị',
        COUNT: 'Đếm',
        DEALS_BY_SALES_REP: 'Đường ống của đại diện bán hàng',
        PIPELINE:'Đường ống',
    },
    FILTER: {
        NAME_REQUIRED: 'Tên bộ lọc (bắt buộc)',
        REMOVE_CONDITION: 'Loại bỏ điều kiện',
        CRITERIA_FOR_ASSOCIATED_OBJECTS:'Tiêu chí cho các đối tượng liên kết',
        //not found in doc
        NEXT_BUTTON_TEXT: 'Tiếp theo',
        PREVIOUS_BUTTON_TEXT: 'Trước',
        AND: 'Và',
        OR: 'Hoặc',
        ADD_ANOTHER: 'Thêm người khác',
        ADD_CONDITION: 'Thêm điều kiện',
        COPY_FILTER: 'Bản sao của ',
        FROM_DATE: 'Từ ngày (From Date)',
        TO_DATE: 'Đến ngày (To Date)',

        CONTAINS: 'chứa đựng',
        DOES_NOT_CONTAINS: 'không chứa',
        IS_EQUAL_TO: 'là bằng với',
        IS_NOT_EQUAL_TO: 'là không bằng với',
        IS_EMPTY: 'là trống rỗng',

        IS_GREATER_THAN: 'là lớn hơn',
        IS_GREATER_THAN_OR_EQUAL_TO: 'là lớn hơn hoặc bằng',
        IS_LESS_THAN: 'là ít hơn',
        IS_LESS_THAN_OR_EQUAL_TO: 'nhỏ hơn hoặc bằng',
        'Contacts': 'Liên hệ',
        'Contacts without Groups': 'Liên hệ mà không có nhóm',
        'My Contacts': 'Liên hệ của tôi',
        'Contacts without Account': 'Liên hệ mà không có tài khoản',
        'Todays Contacts': 'Todays Contacts',
        'Contacts without Accounts': 'Danh sách liên hệ không có tài khoản',
        'All records': 'Tất cả hồ sơ',
        'Added this Month': 'Đã thêm tháng này',
        'Added this Week': 'Đã thêm tuần này',
        'My Opportunities': 'Cơ hội của tôi',
        'Opportunities': 'Cơ hội',
        'Accounts': 'Tài khoản',
        'Accounts without Contact': 'Tài khoản không có Liên hệ',
        'Accounts without Groups': 'Tài khoản không có nhóm',
        'My Accounts': 'Tài khoản của tôi',
        'Todays Accounts': 'Todays tài khoản',
        'Cases': 'Các trường hợp',
        'Projects': 'Dự án',
        'Issues': 'Vấn đề',
        'Accounts without Contacts': 'Tài khoản không có Danh bạ',
    },
    SOURCE: {
        SOURCE_NAME: 'Tên nguồn'
    },
    INXPRESS_REPORT: {
        COUNTRY_SELECT: 'Chọn quốc gia',
        FRANCHISE_SELECT: 'Chọn nhượng quyền',
        WORKFLOW_SELECT: 'Chọn Workflow',
        START_DATE: 'Ngày bắt đầu',
        NO_RECORDS_FOUND: 'Không có dữ liệu được tìm thấy.',
        'Account': 'Tài khoản',
        'Sales Rep': 'Đại diện bán hàng',
        'Workflow': 'Quy trình làm việc',
        'Status': 'Tình trạng',
        REGION_SELECT: 'Chọn địa bàn',
    },
    KANBAN_REPORT: {
        WORKFLOW: 'Quy trình làm việc',
        USER: 'Người dùng',
        DURATION: 'Thời lượng',
        SORT_BY: 'Sắp xếp theo',
        REPORT_BY: 'Báo cáo bởi',
        'Forecast amount': 'Số tiền dự báo',
        'Close date': 'Đóng ngày',
        'Sales rep': 'Đại diện bán hàng',
        'Status': 'Tình trạng',
        'Convert': 'Đổi',
        'Edit': 'Chỉnh sửa',
        'Delete': 'Xóa bỏ',
        'opportunities': 'Cơ hội',
        'Probability': 'Xác suất',
    },
    APP: {
        CURRENT: 'Tôi sẽ sử dụng phiên bản mới',
        LEGACY: 'Tôi sẽ sử dụng phiên bản Legacy',
        HEADING: 'Hãy chọn một phiên bản soffront',
        SUB_TITLE: 'Tổ chức của bạn có quyền truy cập vào phiên bản di sản của chúng tôi. Bạn có muốn dùng nó thay vì phiên bản mới không?'
    },
    OTHER_VIEW: {
        SUBSCRIBE_LABEL: 'Đăng ký'
    },
    MY_INTEGRATION: {
        CONNECTING: 'Kết nối',
        DISCONNECTING: 'Ngắt kết nối',
        SYNC_EMAIL: 'Đồng bộ hóa email',
        SYNC_CONTACT: 'Đồng bộ liên hệ',
        SYNC_CALENDAR: 'Đồng bộ lịch',
        APPEND_COMPANY: 'Thêm tên công ty',
        APPEND_CONTACT: 'Nối Tên người liên hệ',
        CALENDAR: 'Lịch',
        SERVICE: 'Dịch vụ',
        INTEGRATION_HEADER: 'Email và hội nhập lịch',
        PHONE_INTEGRATION: 'Tích hợp điện thoại',
        MY_INTEGRATIONS: 'Tích hợp',
        CONTACT_FILTER: 'Bộ lọc liên hệ',
        RINGCENTRAL_HEADER: 'Ring Central',
        SMS_INTEGRATION: 'Tích hợp SMS',
        QUICKBOOKS: 'QuickBooks',
        XERO:'Xero',
        INTEGRATE:'tích hợp',
        SELECT_INTEGRATION_ITEMS:'Chọn mục để tích hợp',
        INVOICES:'Hóa đơn',
        PAYMENTS:'Thanh toán',
        MANNUALLY:'Thủ công (CRM> Xero)',
        AUTOMATICALLY:'Tự động (Xero> CRM)',
        ACCOUNTING_INTEGRATION_LABEL: 'Tích hợp với',
        ACCOUNTING_INTEGRATION: 'Tích hợp kế toán',
        QUICKBOOKS_INTEGRATION: 'Tích hợp QuickBooks',
        EVENTBRITE_INTEGRATION: 'Tích hợp Eventbrite',
        SELECT_A: 'Chọn một',
        CRM_CONTACT_FILTER_SELECT: 'Chọn bộ lọc liên hệ CRM',
        CONTACT_GROUP_NOT_FIND: 'Không thể tìm thấy nhóm liên hệ. Hãy nhấp vào',
        HERE: 'Ở đây',
        TO_TRY_AGAIN: 'để thử lại.',
        CONTACT_FILTER_NOT_FIND: 'Không tìm thấy bất kỳ bộ lọc liên hệ nào.',
        SEND_SALES_EMAILS_USING: 'Gửi email bán hàng bằng',
    },
    PROFILE_DETAILS: {
        PROFILE_DETAILS_LABEL: 'Hồ sơ',
        PERSONAL_INFORMATION: 'Thông tin cá nhân',
        USERNAME_LABEL: 'Điều này sẽ được sử dụng như tên người dùng.',
        CHANGE_PASSWORD: 'Đổi mật khẩu',
        MANAGE_NOTIFICATIONS: 'Quản lý thông báo',
        EMAIL_SIGNATURE: 'Chữ ký email',
        TASKS: 'Nhiệm vụ',
        APPOINTMENTS: 'Cuộc hẹn',
        TASK_EMAIL: 'Email công việc',
        TASK_POPUP: 'Nhiệm vụ Pop lên',
        APPOINTMENT_EMAIL: 'Các cuộc hẹn email',
        APPOINTMENT_POPUP: 'Các cuộc hẹn bật lên',
        ON: 'Trên',
        OFF: 'Tắt',
        CHANGE_PHOTO: 'Thay đổi hình ảnh',
        NEW_PASSWORD: 'Mật khẩu mới',
        CONFIRM_PASSWORD: 'Xác nhận mật khẩu mới',
        NAME_REQUIRED: 'Tên (bắt buộc)',
        EMAIL_REQUIRED: 'Email (bắt buộc)',
        PHONE: 'Điện thoại',
        TIME_ZONE_REQUIRED: 'Múi giờ (bắt buộc)',
        DATE_FORMAT: 'Định dạng ngày tháng',
        LANGUAGE: 'Ngôn ngữ',
        USER_COLOR: 'Màu người dùng',
    },
    DETAIL_VIEW_CHILD_NOTES: {
        ALL: 'Tất cả',
        USER: 'Người dùng',
        SYSTEM: 'Hệ thống',
        MY: 'Của tôi',
        ATTACHMENT_INFO: 'Thông tin đính kèm',
    },
    DOCUSIGN: {
        CONNECT_BUTTON: 'Kết nối',
        SAVE_BUTTON: 'Lưu',
        CANCEL_BUTTON: 'Hủy bỏ',
        USERNAME: 'Tên người dùng (bắt buộc)',
        PASSWORD: 'Mật khẩu (bắt buộc)',
        INTREGATOR_KEY: 'Khóa tích hợp (bắt buộc)',
        SELECT_TEMPLATE: 'Chọn mẫu',
        SEND: 'Gửi',
        DISCONNECT_BUTTON: 'Ngắt kết nối',
        DOCUSIGN_SETUP_HEADER: 'Docusign hội nhập',
        DOCUSIGN_CONNECT_TITLE: 'Kết nối với tài khoản docusign của bạn',
        ADD_NOTE: 'Thêm nhận xét (tùy chọn).',
    },
    TWILIO: {
        CALL: 'Gọi',
        VERIFY: 'Kiểm chứng',
        DISCONNECT: 'Ngắt kết nối',
        SHOW_SCRIPTS: 'Hiện script',
        HIDE_SCRIPTS: 'Ẩn script',
        CALL_SETUP: 'Thiết lập cuộc gọi',
        CALL_SCRIPT: 'Tập lệnh gọi',
        CALL_SCRIPT_COPY_TAB_LABEL: 'Sao chép tập lệnh cuộc gọi',
        COPY_OF: 'Bản sao của',
        ADD_NUMBERS: 'Thêm số điện thoại đi của bạn',
        OUTBOUND_PHONE_NUMBERS: 'Số điện thoại Outbound',
        ADD_ANOTHER_NUMBER: 'Thêm một số khác',
        CALL_RECORDING: 'Thu âm cuộc gọi',
        PHONE_CREDITS: 'Tín dụng điện thoại',
        PURCHASE_CREDITS_YOU_HAVE: 'Bạn có',
        PURCHASE_CREDITS_MINUTES_LEFT: 'phút còn lại.',
        PURCHASE_CREDITS_MORE: 'Mua thêm các khoản tín dụng.',
        CALLING: 'Đang gọi',
        RECORD_ALL_CALL: 'Ghi lại tất cả cuộc gọi',
        VERIFIED: 'Xác minh',
        CLICK_TO_CALL: 'Tôi đã sẵn sàng để có cuộc gọi',
        ADDITIONAL_INFO: 'Hiển thị thông tin bổ sung',
        ADDITIONAL_INFO_TITLE: 'Thông tin thêm',
        SELECT_FIELD:'Chọn trường (Tối đa 3 trường)'
    },
    CALL_SCRIPT: {
        ADD_SCRIPT_NAME: 'Tên tập lệnh (bắt buộc)',
        ADD_DETAILS: 'Chi tiết tập lệnh (bắt buộc)',
        WORKFLOW_NAME: 'Tên công việc',
        CALL_SCRIPTS_EDIT_TAB_LABEL: 'Chỉnh sửa tập lệnh cuộc gọi',
    },
    TWILIO_SMS: {
        ZIP_CODE: 'Mã bưu điện',
        AREA_CODE: 'Mã vùng',
        ADD_TO_ACCOUNT: 'Thêm vào',
        SMS_CREDITS: 'Tín dụng SMS của bạn',
        SMS_CREDITS_LEFT: 'Tín dụng SMS còn lại.',
        SEND: 'Gửi',
        NO_NUMBER_PURCHASED: 'Bạn chưa có bất kỳ số điện thoại nào. Sử dụng tùy chọn tìm kiếm để mua số điện thoại. Sau khi mua, bạn sẽ có thể gửi tin nhắn bằng cách sử dụng các số.',
        FIND_PHONE_NUMBER: 'Tìm số điện thoại bằng mã zip hoặc mã vùng điện thoại',
        FIND_PHONE_NUMBER_BY_AREA_CODE: 'Tìm số điện thoại theo mã vùng',
        SEND_SMS: 'Gửi tin nhắn văn bản',
        YOUR_MESSAGE: 'Tin nhắn của bạn',
        OR: 'Hoặc',
        PHONE_NUMBER: 'Số điện thoại',
        PHONE: 'Điện thoại',
        AVAILABLE_TO: 'Sẵn sàng cho',
        USER_NAME: 'Tên người dùng',
        USED_BY: 'Được sử dụng bởi',
        UNIT_NAME: 'Tên bài',
    },
    QUICKBOOKS: {
        CONNECT_BUTTON: 'Kết nối',
        LABEL: 'Kết nối với tài khoản QuickBooks trực tuyến của bạn'
    },
    PREVIEW_EMAIL_TEMPLATE: {
        ALL_LAYOUT: 'Tất cả bố cục',
        LEFT_SIDEBAR_LAYOUT: 'Bố cục thanh bên trái',
        RIGHT_SIDEBAR_LAYOUT: 'Bố cục thanh bên phải',
        HORIZONTAL_LAYOUT: 'Bố cục ngang',
        MULTIPLE_COLUMN_LAYOUT: 'Bố cục nhiều cột'
    },
    EVENTBRITE: {
        CONNECT_BUTTON: 'Kết nối',
        SELECT_ORGANISATIONS: 'Đồng bộ hóa các sự kiện từ tổ chức này',
        EVENTBRITE_CONNECT_TITLE: 'Kết nối với tài khoản Eventbrite của bạn',
        SELECT: 'Lựa chọn',
        EVENTBRITE_TOGGLE_TITLE: 'Trong quá trình đồng bộ hóa, hãy thêm liên hệ mới nếu không tìm thấy trong CRM',
        EVENTBRITE_MAP_FIELDS: 'Trường bản đồ',
        CRM_FIELDS_TITLE: 'Các trường liên hệ CRM',
        EVENTBRITE_FIELDS_TITLE: 'Trường Eventbrite',
    },
    SIDE_NAV: {
        APPOINTMENTS: 'Lịch/nhiệm vụ',
        DASHBOARD: 'Bảng điều khiển',
        REPORTS: 'Báo cáo',
        SALES_TEMPLATES: 'Mẫu email bán hàng',
        SOCIAL_MARKETING: 'Tiếp thị xã hội',
        WEBSITE_TRACKING: 'Phân tích trang web',
        SOLUTIONS: 'Các giải pháp',
        CONTACTS: 'Danh bạ',
        ACCOUNTS: 'Tài khoản',
        OPPORTUNITIES: 'Cơ hội',
        CASES: 'Các trường hợp',
        PROJECTS: 'Dự án',
        ISSUES: 'Vấn đề',
        ONBOARDING: 'Lên tàu',
        UNITS: 'Các đơn vị',
        ASSESTS: 'Tài sản',
        OPERATIONS_ACTIVITIES: 'actividades',
        OPERATIONS_CONVERSIONS: 'Conversões',
        OPERATIONS_CAMPAIGNS: 'Campanhas',
        OPERATIONS_REPORTS: 'Relatórios',
        LEAD_CAPTURE: 'Chụp chì',
        EMAIL_MARKETING: 'Thư điện tử quảng cáo',
        'CALENDAR/TASKS': 'Lịch / Nhiệm vụ',
        'SALES REPORTS': 'Báo cáo',
        'SALES TEMPLATES': 'Mẫu bán hàng',
        'SOCIAL MARKETING': 'Tiếp thị xã hội',
        'ONLINE REPUTATION': 'Danh tiếng trực tuyến',
        DASHBOARDS: 'Bảng điều khiển',
        'SALES LEADS': 'Dẫn bán hàng',
        'USEFUL LINKS': 'Liên kết hữu ích',
        NEWS: 'Tin tức',
        LIBRARY: 'Thư viện',
        COMMUNITY: 'cộng đồng',
        TRAINING: 'Đào tạo',
        HYPER_LINK: 'siêu liên kết',
        HUB: 'HUB',
        SCROLL_TO_RIGHT: 'Di chuyển sang phải',
        SCROLL_TO_LEFT: 'Di chuyển sang trái',
        Royalties: 'Tiền bản quyền',
        INBOX: 'Hộp thư đến',
    },
    NO_RECORD: {
        'Solutions': 'Các giải pháp',
        'Appointments': 'Cuộc hẹn',
        'Tasks': 'Nhiệm vụ',
        'Groups': 'Các nhóm',
        'Notes': 'Ghi chú',
        'Attachments': 'Tệp đính kèm',
        'Marketing-templates': 'Mẫu tiếp thị',
        'Sales-templates': 'Mẫu bán hàng',
        'Web-forms': 'Biểu mẫu web',
        'Products': 'Các sản phẩm',
        'Broadcast-campaigns': 'Chiến dịch quảng bá',
        'Ab-campaigns': 'Ab-campaign',
        'Automation-designer': 'Tự động thiết kế',
        'Projects': 'Dự án',
        'Invoices': 'Hóa đơn',
        'Touch-campaigns': 'Chiến dịch cảm ứng',
        'Events': 'Sự kiện',
        'Sms-templates': 'SMS-mẫu',

        'Emails': 'Email',
        'Relationships': 'Các mối quan hệ',
        'Templates': 'Mẫu',

        'Cases': 'Các trường hợp',
        'Contacts': 'Danh bạ',
        'Accounts': 'Tài khoản',
        'Opportunities': 'Cơ hội',
        'Issues': 'Vấn đề',
        'Activities': 'Hoạt động',
        'Conversions': 'Chuyển đổi',
        'Salesleads': 'Dẫn bán hàng',
        'Source': 'Nguồn',
        'Users': 'Người dùng',
        'Securitylevel': 'Cấp độ bảo mật',
        'Workflows': 'Quy trình làm việc',
        'Relations': 'Quan hệ',
        'Notetypes': 'Các loại ghi chú',
        'Campaigntypes': 'Các loại chiến dịch',
        'Emailintegration': 'Bí danh email',
        'Library': 'Thư viện',
        'Recyclebin': 'Tài khoản',
        'Sms': 'Tin nhắn',
        'Assignmentrules': 'Quy tắc chuyển nhượng',
        'Units': 'Các đơn vị',
        'Orderitems': 'Đặt hàng',
        'Claims': 'Yêu cầu bồi thường',
        'Payments': 'Thanh toán',

    },
    REPORT_SCHEDULE_DIALOG: {
        REPORT_SCHEDULE_SETUP: 'Lịch báo cáo',
        DAY_OF_THE_MONTH: 'ngày của tháng',
        DAY_OF_THE_WEEK: 'ngày trong tuần',
        REPEAT_TYPE: 'Lặp lại loại (bắt buộc)',
        REPEAT_ON: 'Lặp lại trên',
        REPEAT_BY: 'Lặp lại bởi',
        START_DATE: 'Ngày bắt đầu (bắt buộc)',
        END_DATE: 'Ngày kết thúc',
        REPEAT_EVERY: 'Lặp lại mỗi (yêu cầu)',
        SEND_TO: 'Gửi đến (bắt buộc)',
        SCHEDULE: 'Lịch biểu',
    },
    LIST_VIEW_HEADER: {
        AUTOMATION_DESIGNER: {
            'Name': 'Tên',
            'Status': 'Trạng thái',
            'Created By': 'Được tạo bởi',
            'Created On': 'Được tạo ra',
            'Updated By': 'Cập nhật',
            'Updated On': 'Đã cập nhật vào',
        },
        APPOINTMENT: {
            'Subject': 'Môn học',
            'Company': 'Công ty',
            'Status': 'Trạng thái',
            'Contact': 'Tiếp xúc',
            'Contact Name': 'Tên Liên lạc',
            'Start date': 'Ngày bắt đầu',
            'End date': 'Ngày cuối',
            'Assign to': 'Giao cho',
            'Visible to': 'Hiển thị với',
            'Outlook Sync': 'Đồng bộ hóa Outlook',
            'Google Sync': 'Google đồng bộ hóa',
            'Text': 'Bản văn',
            'Creator': 'Người sáng tạo',
            'Created on': 'Được tạo ra',
            'Updated by': 'Cập nhật',
            'Updated on': 'Đã cập nhật vào',
        },
        TASKS: {
            'Task Name': 'Tên nhiệm vụ',
            'Company': 'Công ty',
            'Status': 'Trạng thái',
            'Previous Task': 'Tác vụ trước',
            'Complete Date': 'Ngày hoàn thành',
            'Owner': 'Chủ nhân',
            'Due By': 'Do bởi',
            'Contact Name': 'Tên Liên lạc',
            'Description': 'Sự miêu tả',
            'Updated on': 'Đã cập nhật vào',
            'Contact': 'Tiếp xúc',
            'Start Time': 'Thời gian bắt đầu',
            'End Time': 'Thời gian kết thúc',
            'Item': 'Mục',
            'Billable': 'Có thể thanh toán',
        },
        BROADCAST: {
            'Broadcast Name': 'Tên phát sóng',
            'Status': 'Tình trạng',
            'Run Date': 'Ngày chạy',
            'Owner': 'Chủ sở hữu',
            'Created On': 'Đã tạo trên',
            'Updated On': 'Đã cập nhật vào',
            'Target': 'Mục tiêu',
            'From email': 'Từ email',
            'From name': 'Từ tên',
            'Updated By': 'Cập nhật bởi',
            'Created By': 'Được tạo bởi'
        },
        AB_CAMPAIGN: {
            'Campaign Name': 'Tên chiến dịch',
            'Created By': 'Được tạo bởi',
            'Created On': 'Đã tạo trên',
            'Run Date': 'Ngày chạy',
            'Status': 'Tình trạng',
            'Target Filter': 'Bộ lọc mục tiêu',
            'Updated By': 'Cập nhật bởi',
            'Updated On': 'Đã cập nhật vào'
        },
        SALES_TEMPLATE: {
            'Name': 'Tên',
            'Category': 'Mục',
            'Subject': 'Chủ đề',
            'Status': 'Tình trạng',
            'Owner': 'Chủ sở hữu',
            'Created On': 'Lập ngày',
            'Updated By': 'Cập Nhật bởi',
            'Updated On': 'Cập Nhật trên',
        },
        MARKETING_TEMPLATE: {
            'Name': 'Tên',
            'Category': 'Mục',
            'Subject': 'Chủ đề',
            'Status': 'Tình trạng',
            'Owner': 'Chủ sở hữu',
            'Created On': 'Lập ngày',
            'Updated By': 'Cập Nhật bởi',
            'Updated On': 'Cập Nhật trên',
        },
        WEB_FORM: {
            'Name': 'Tên',
            'Table Name': 'Bảng tên',
            'Group': 'Nhóm',
            'Source': 'Nguồn',
            'Status': 'Tình trạng',
            'Assign To': 'Gán cho',
        },
        TOUCH_CAMPAIGNS: {
            'Campaign name': 'Tên chiến dịch',
            'Status': 'Tình trạng',
            'Owner': 'Chủ sở hữu',
            'Target': 'Mục tiêu',
            'From email': 'Từ email',
            'Created On': 'Lập ngày',
            'Created By': 'Tạo bởi',
            'From name': 'Từ tên',
            'Updated On': 'Cập Nhật trên',
            'Updated By': 'Cập Nhật bởi',
            'Resend All': 'Gửi lại tất cả',
            'Resend After': 'Gửi lại sau khi',
        },
        ACCOUNTS: {
            'Status': 'Trạng thái',
        },
        CONTACTS: {
            'Status': 'Trạng thái',
        },
        OPPORTUNITIES: {
            'Status': 'Trạng thái',
        },
        PROJECTS: {
            'Status': 'Trạng thái',
        },
        ISSUES: {
            'Status': 'Trạng thái',
        },
        CASES: {
            'Status': 'Trạng thái',
        },
        SOLUTIONS: {
            'Status': 'Trạng thái',
        },
        SALES_LEADS: {
            'Status': 'Trạng thái',
        },
    },
    DEFAULT_NOTE_TYPES: {
        'Appointment': 'Cuộc hẹn',
        'Dialed': 'Đã gọi',
        'Email': 'Email',
        'LVM': 'LVM',
        'Note': 'chú thích',
        'Spoke': 'Nói',
        'Task': 'Bài tập',
        'Transferred': 'Đã chuyển',
    },
    VIEW_TOGGLE: {
        LIST: 'Danh sách',
        COLUMN: 'Cột',
        LIST_TITLE: 'Chuyển sang Chế độ xem danh sách',
        COLUMN_TITLE: 'Chuyển sang Chế độ xem Cột',
        TIMELINE: 'Thời gian',
        TIMELINE_TITLE: 'Chuyển sang chế xem dòng thời gian',
        DETAILS: 'Chi tiết',
        DETAILS_TITLE: 'Chuyển sang chế xem chi tiết',
    },
    EMAIL_MARKRTING_REPORT: {
        REPORT_HEADER: 'Email tiếp thị báo cáo',
        MY_CAMPAIGNS: 'Chiến dịch của tôi',
        MY_LOCATION_CAMPAIGNS: 'Các chiến dịch của đơn vị',
        RECENT_CAMPAIGNS: 'Chiến dịch gần đây',
        BROADCAST_REPORT: 'Báo cáo chiến dịch phát sóng',
        TOUCH_REPORT: 'Liên lạc báo cáo chiến dịch'
    },
    DOCUMENT_PUSH: {
        DIALOG_TITLE: 'Đẩy đến',
        DOCUMENT_NOT_FOUND: 'Không tìm thấy tài liệu.'
    },
    OPERATIONS_REPORT: {
        REQUEST_NEW_REPORT: 'Yêu cầu báo cáo mới'
    },
    OPERATIONS_CAMPAIGNS: {
        REPORT_HEADER: 'Email chiến dịch',
        BROADCAST_REPORT: 'Chiến dịch phát sóng',
        TOUCH_REPORT: 'Touch chiến dịch'
    },
    TASKS_REPORT: {
        TASKS_REPORT_LABEL: 'Báo cáo nhiệm vụ'
    },
    EMAIL_REPORT: {
        EMAIL_REPORT_LABEL: 'Báo cáo qua email',
        ERROR_REASON: 'Lý do'
    },
    SMS_REPORT: {
        SMS_REPORT_LABEL: 'Báo cáo SMS'
    },
    BATCH_SMS: {
        MESSAGE: 'Tin nhắn của bạn',
        SEND: 'Gửi',
        CHARS: ' ký tự',
        SCHEDULE: 'Lịch trình',
        SCHEDULE_SMS: 'Lịch trình tin nhắn văn bản',
        SEND_LATER: 'Gửi sau'
    },
    SETTING_MENU: {
        MARKETING: 'Tiếp thị',
        SECURITY: 'Người dùng và bảo mật',
        DATA: 'Dữ liệu',
        CUSTOMIZATION: 'Tùy biến',
        INTEGRATION: 'Hội nhập',
        COMPANY: 'Công ty',
        WORKFLOW_RULES:'Quy trình & Quy tắc làm việc',
        LISTS: 'Danh sách',
        FRANCHISE: 'Nhượng quyền thương mại',
        COMPANY_SCHEDULER: 'Người lập kế hoạch công ty'
    },
    MARKETING_SETTING: {
        'SOURCE': 'Nguồn',
        'LIBRARY': 'Thư viện',
        'LEAD_SCORE': 'Điểm chì',
    },
    SECURITY_SETTING: {
        'USERS': 'Người dùng',
        'SECURITY_LEVELS': 'Cấp độ bảo mật'
    },
    DATA_SETTING: {
        'EXPORT': 'Xuất khẩu',
        'RECYCLE_BIN': 'Thùng rác',
        'FIND_DUPLICATES': 'Tìm bản sao'
    },
    CUSTOMIZATION_SETTING: {
        'CHANGE_LOGO': 'Thay đổi Logo',
        'DATA_BASE': 'Cơ sở dữ liệu',
        'WORKFLOWS': 'Quy trình làm việc',
        'RELATIONS': 'Quan hệ',
        'AUTO_FILL_VALUES': 'Giá trị tự động điền',
        'NOTE_TYPES': 'Các loại nhiệm vụ & ghi chú',
        'CAMPAIGN_TYPES': 'Các loại chiến dịch',
        'CORPORATE_CURRENCY': 'Tiền tệ của công ty',
        'HUB_SETTING': 'HUB',
        'COMPANY_PROFILE': 'Hồ sơ công ty',
        'WHITE_LABEL': 'Nhãn trắng',
        'MENU': 'Thực đơn',
        'DESKTOP_MENU': 'Menu Bàn làm việc',
        'MOBILE_MENU': 'Menu Di động',
        'PHONE_NUMBERS': 'Số điện thoại',
    },
    INTEGRATION_SETTING: {
        'MICROSOFT_OUTLOOK': 'Microsoft Outlook (Máy tính để bàn)',
        'GENERATE_API': 'Tạo API',
        'EMAIL_INTEGRATION': 'Tích hợp email'
    },
    SETTINGS: {
        ADDED: 'thêm',
        EDIT: 'cập nhật',
        SUCCESSFULL: 'Thành công.',
        'SOURCE': 'Nguồn',
        'NOTETYPES': 'Các loại nhiệm vụ & ghi chú',
        'CAMPAIGNTYPES': 'Loại chiến dịch',
        'CORPORATECURRENCY': 'Tiền tệ của công ty',
        'RELATIONS': 'Quan hệ',
        'LIBRARY': 'Thư viện',
        'USERS': 'Người dùng',
        'SECURITYLEVEL': 'Cấp độ bảo mật',
        'WORKFLOWS': 'Quy trình làm việc',
        'EMAILINTEGRATION': 'Bí danh email',
        'RECYCLEBIN': 'Thùng rác',
        'ASSIGNMENTRULES': 'quy định phân công',
        'APPOINTMENTTYPES': 'Loại hẹn',
        'ROLLUP': 'Quy tắc cuộn lên',
        'SUBSCRIPTION': 'Đăng ký',
        'UPGRADE': 'đăng ký nâng cấp',
        'BUYPRODUCT': 'Mua Sản phẩm & Đào tạo',
        'BUYPROSERVICE': 'Mua Dịch vụ chuyên nghiệp',
        'BILLING': 'Lập hoá đơn và chi tiết hóa đơn',
        'CANCELSUB': 'Hủy đăng ký',
        'SUBDETAIL': 'Chi tiết đăng ký',
        'PURCHESEMAILCREDIT': 'Mua Email Tín',
        'PURCHESPHONECREDIT': 'Mua điện thoại Tín',
        'PURCHESSMSCREDIT': 'Mua SMS Tín',
        'PURCHESCARDCREDIT': 'Mua thẻ quét Tín',
        'PURCHESCONTACTCREDIT': 'Mua More Liên hệ',
        'PURCHESTRANINGCREDIT': 'Đào tạo mua',
        'PURCHESMERKETINGSERVICE': 'Mua Tiếp thị Dịch vụ',
        'PURCHESPROFESSONALSERVICE': 'Mua Dịch vụ chuyên nghiệp',
        'SETUP': 'Cài đặt của tôi',
        SHOW_LOGO: 'Hiển thị biểu trưng',
        SHOW_SIGNOUT_LINK: 'Hiển thị liên kết Signout',
        THEME_COLOR:'Màu chủ đề',
        LOGO_COLOR :'Nền biểu trưng',
        AUDIT_QUESTIONS: 'Danh sách kiểm tra kiểm toán',
        LMS_USERS: 'Người dùng LMS',
    },
    CORPORATE_CURRENCY: {
        SELECT_CURRENCY_NAME: 'Chọn một loại tiền tệ của công ty'
    },
    UNSUBSCRIBE: {
        EMAILS: 'Email',
        TEXTS: 'Các văn bản',
        BOTHS: 'Cả hai'
    },
    HUB_SETUP: {
        SETUP_FIELD_NAME: 'Tên trung tâm',
        USEFUL_LINK: 'Liên kết hữu ích',
        CASES: 'Các trường hợp',
        SETUP_NAME: 'Cài đặt HUB',
        NEWS: 'Tin tức',
        LIBRARY: 'Thư viện',
        COMMUNITY: 'cộng đồng',
        TRAINING: 'Đào tạo',
        HYPER_LINK: 'siêu liên kết',
        SET: 'Bộ',
    },
    UNITS: {
        TASK_DETAILS: 'Chi tiết tác vụ',
        'Unit': 'đơn vị',
        'Contact Name': 'Tên liên hệ',
        'Done': 'Thực hiện',
        'Open': 'Mở',
        'Tasks Name': 'Tên tác vụ',
        'Assigned To': 'Phân công',
        'Due By': 'Do theo',
        'Status': 'Tình trạng',
        UNIT_DETAILS_LABEL: 'Nhập lại mật khẩu (bắt buộc)',
        FIRST_NAME: 'Tên đầu tiên (cần thiết)',
        LAST_NAME: 'Yêu cầu Tên)',
        PASSWORD: 'Mật khẩu  (cần thiết)',
        RETYPE_PASSWORD: 'Nhập lại mật khẩu (bắt buộc)',
        EMAIL: 'Email (bắt buộc)',
        PHONE: 'Điện thoại (bắt buộc)',
        COMPANY: 'Công ty (bắt buộc)',
        TIME_ZONE_SELECT: 'Múi giờ',
        COUNTRY_SELECT: 'Quốc gia',
        STATE_SELECT: 'Tiểu bang',
        NO_UNIT_TASKS: 'Không có tác vụ nào sẵn dùng cho Chủ sở hữu Đơn vị.',
        NO_ACCOUNT_TASKS: 'Không có tác vụ nào sẵn dùng cho Trương mục.'
    },
    ASSIGNMENT_RULES: {
        SETUP_FIRST_RULE: 'Thiết lập quy tắc đầu tiên của bạn',
        ASSIGNMENT_RULE: 'Quy tắc chuyển nhượng',
        ASSIGNMENT_RULE_CREATE_TAB_LABEL: 'Tạo quy tắc chuyển nhượng',
        ASSIGNMENT_RULE_EDIT_TAB_LABEL: 'Chỉnh sửa quy tắc gán',
        NAME_REQUIRED: 'Tên (bắt buộc)',
        ASSIGNMENT_RULES: 'Quy tắc chuyển nhượng',
        STATUS: 'Tình trạng',
        SETUP_RULE: 'Thiết lập gán quy tắc',
        USE_RULE: 'Sử dụng quy tắc gán',
        TABLE_NAME: 'Tên bảng',
        SELECT_UNIT_WORKFLOW: 'chọn quy trình công việc đơn vị'
    },
    COUPONS: {
        PREFIX: 'Tiếp đầu ngữ',
        'Coupon Name': 'Tên phiếu giảm giá',
        'Discount Value': 'Giá trị chiết khấu',
        EXPIRATION_DATE: 'Ngày hết hạn',
        PODUCT_SERVICE: 'Sản phẩm / Dịch vụ (tùy chọn)',

    },
    COUPONS_CLAIM_REPORTS: {
        COUPONS_CLAIM_REPORTS_LABEL: 'Báo cáo yêu cầu phiếu giảm giá',
        COUPONS_REPORT: 'báo cáo' // Report       
    },
    PIPELINE_REPORT: {
        PIPELINE_REPORT_LABEL: 'Báo cáo đường ống',
        SALES_SUMMARY_REPORT: 'Báo cáo tóm tắt bán hàng'
    },
    NOTIFICATION_REMINDERS: {
        NOTIFICATION_REMINDERS: 'Cảnh báo & Thông báo',
        NOTIFY_ME: 'Thông báo cho tôi khi...',
        REMIND_ME: 'Nhắc tôi trước khi...',
        NOTIFY_EMAIL_OPEN: 'Email của tôi được mở',
        NOTIFY_EMAIL_CLICK: 'Email của tôi được nhấp',
        NOTIFY_TEXT_REPLY: 'Văn bản của tôi được trả lời',
        REMIND_UPCOMING_APPOINTMENT: 'Các cuộc hẹn sắp tới',
        REMIND_UPCOMING_TASK: 'Nhiệm vụ sắp tới',
        ADD_NEW_REMINDER: 'Thêm Reminder mới',
        REMIND: 'Nhắc nhở',
        DAY_BEFORE: 'ngày trước khi',
        MY_WEB_FORM_SUBMITTTED: 'Biểu mẫu web của tôi được gửi',
        NO_DATE_FIELD: 'Không có trường ngày nào',

    },
    EMAIL_INTEGRATION: {
        EMAIL: 'Bí danh email',
    },
    NEWS: {
        HEADER: 'Tin tức',
        STATUS: {
            DRAFT: 'Bản nháp',
            PUBLISHED: 'Được phát hành'
        },
        EDIT_TAB_LABEL: 'Chỉnh sửa tin tức',
        ADD_TAB_LABEL: 'Thêm tin tức',
        CATEGORY_REQUIRED: 'Danh mục (bắt buộc)',
        STATUS_REQUIRED: 'Tình trạng (bắt buộc)',
        TITLE_REQUIRED: 'Tiêu đề (bắt buộc)',
        SUMMARY_REQUIRED: 'Tóm tắt (bắt buộc)',
        EXTERNAL_LINK_REQUIRED: 'Liên kết ngoài (bắt buộc)',
        UPLOAD_IMAGE: 'Tải lên một hình ảnh',
        EXTERNAL_LINK: 'Liên kết bên ngoài',
        ENTER_DETAILS: 'Nhập chi tiết'
    },
    USEFUL_LINKS: {
        USEFUL_LINKS: 'Liên kết hữu ích',
        CATEGORY: 'Mục',
        LINK: 'Liên kết',
        TITLE: 'Tiêu đề',
        NO_RECORD_FOUND: 'Không có liên kết hữu ích tìm thấy.',
    },
    ORTHOTIC_REPORT: {
        ORTHOTIC_REPORT_LABEL: 'Báo cáo chỉnh hình'
    },
    REPORTS: {
        NO_REPORTS_FOUND: 'Không tìm thấy báo cáo.',
    },

    AUTO_FILL_VALUES: {
        SELECT_PARENT: 'chọn một phụ huynh',
        SELECT_CHILD: 'chọn một con',
        PARENT_FIELDS: 'trường đối tượng phụ huynh',
        CHILD_FIELDS: 'trường đối tượng con',
    },
    LANDING_PAGE: {
        LANDING_PAGE: 'Trang đích',
        LANDING_PAGES: 'Trang đích',
        NAME_REQUIRED: 'Tên (bắt buộc)',
        URL_REQUIRED: 'URL (bắt buộc)',
        LANDING_PAGE_FORMS: 'Trình tạo Mẫu',
        EDIT_LANDING_PAGE: 'Chỉnh sửa trang đích',
        COPY_LANDING_PAGE: 'Sao chép trang đích'
    },
    FIND_DUPLICATES: {
        FIND_DUPLICATES: 'Tìm bản sao',
        SELECT_OBJECT: 'Chọn đối tượng',
        SELECT_FIELD: 'Chọn trường',

    },
    SCHEDULER: {
        APPOINTMENT_SCHEDULER: 'Lịch hẹn',
        APPOINTMENT_INFO: 'Thông tin cuộc hẹn',
        AVAILABILITY:'khả dụng',
        APPOINTMENT_TYPES:'Các loại cuộc hẹn',
        MY_AVAILABILITY: 'Tính khả dụng của tôi',
        SCHEDULER_LINK: 'Liên kết lập lịch',
        INTAKE_FORM: 'Mẫu tiêu thụ',
        APPOINTMENT_NAME: 'Tên cuộc hẹn (bắt buộc)',
        LOCATION: 'Vị trí',
        TASKS:'Nhiệm vụ',
        UNIT_CRM:'đơn vị crm',
        NOTIFICATION_SETUP:'Cài đặt thông báo',
        DURATION: 'Thời gian',
        MIN_ADVANCE_NOTICE: 'Thông báo trước tối thiểu ',
        DESCRIPTION: 'Mô tả',
        COPY_TO_CLIPBOARD: 'Sao chép vào clipboard của tôi',
        SCHEDULER_LINK_TITLE: 'Liên kết lịch hẹn của tôi',
        SCHEDULING_PAGE: 'Lên lịch hẹn',
        ADD_BUTTON: 'Thêm lịch biểu mới',
        MY_SCHEDULAR_LINK: 'Liên kết lên lịch của tôi',
        MY_APPOINTMENT_TYPE: 'Loại cuộc hẹn của tôi',
        DEFINE_YOUR_INTAKE_FORM: 'Xác định biểu mẫu tuyển sinh của bạn',
        REQUIRED: 'Cần thiết',
        ADD_ANOTHER_FIELD: 'Thêm một trường khác',
        SUBMIT_BUTTON_LABEL: 'Gửi nhãn nút',
        CHOOSE_A_STATUS_FOR_LEAD: 'Chọn trạng thái cho các khách hàng tiềm năng được thêm vào từ bộ lập lịch',
        DEFINE_YOUR_THANK_YOU_MESSAGE: 'Xác định thông điệp cảm ơn của bạn',
        THANK_YOU_MESSAGE: 'Tin nhắn cảm ơn',
    },
    RELATION_REPORT: {
        RELATION_REPORT_LABEL: 'Ma trận quan hệ',
        REPORT_SAVE_LABEL: 'Lưu tùy chỉnh',
        REPORT_NAME_LABEL: 'Tên báo cáo tùy chỉnh',
        REPORT_NAME_TEXTFIELD_LABEL: 'Tên báo cáo',
        ROW_FIELD_LABEL: 'Lĩnh vực hàng',
        COL_FIELD_LABEL: 'Trường cột',
        DATE_FIELD_LABEL: 'Trường ngày',
        PDF_LABEL_DURATION: 'Thời lượng',
        RELATION_FIELD_LABEL: 'Quan hệ',
        SAVE_CUSTOMIZATION_LABEL: 'Lưu tùy chỉnh',
        RUN_BUTTON_LABEL: 'Chạy'
    },
    COMPANY_PROFILE: {
        UPLOAD_LOGO: 'Tải lên logo của bạn',
        WEBSITE: 'Trang web',
        BUSINESS_NAME: 'Tên doanh nghiệp',
        BUSINESS_ADDRESS: 'Địa chỉ doanh nghiệp',
        BUSINESS_PHONE: 'Điện thoại doanh nghiệp',
        BUSINESS_EMAIL: 'Email doanh nghiệp',
        CORPORATE_CURRENCY: 'Tiền tệ công ty'
    },
    EXPORT_SETTINGS: {
        EXPORT_HEADING: 'Xuất khẩu',
        FILE_NAME_REQUIRED: 'Tên tệp (bắt buộc)',
        PARENT_OBJECT_REQUIRED: 'Tên đối tượng chính (bắt buộc)',
        FILE_TYPE_REQUIRED: 'Loại tệp (bắt buộc)',
        QUERY_NAME_REQUIRED: 'Tên truy vấn (bắt buộc)',
        SELECTED_FIELDS_FOR_PARENT: 'Các trường được chọn cho cha mẹ',
        AVAILABLE_FIELDS_FOR_PARENT: 'Các trường có sẵn cho cha mẹ',
        CHILD_OBJECT_NAME: 'Tên đối tượng con',
        AVAILABLE_FIELDS_FOR_CHILD: 'Các trường có sẵn cho trẻ em',
        SELECTED_FIELDS_FOR_CHILD: 'Các trường được chọn cho trẻ em',
        EXPORT_BUTTON: 'Xuất khẩu',
        DOWNLOAD_BUTTON: 'Tải tập tin',
        ADD: 'Thêm vào',
        ADD_ALL: 'Thêm tất cả',
        REMOVE: 'Tẩy',
        REMOVE_ALL: 'Xóa tất cả'
    },
    QUEUE: {
        QUEUE: 'Queue',
        PREVIOUS: 'Trước đó trong hàng đợi',
        NEXT: 'Tiếp theo trong hàng đợi',
        REMOVE: 'Loại bỏ khỏi hàng đợi',
        CLOSE: 'Thoát khỏi hàng đợi',
    },
    SECURITY_LEVEL: {
        ACCESS: 'Truy cập',
        DESCRIPTION: 'Sự miêu tả',
        VIEW: 'Lượt xem',
        PERMISSIONS: 'Quyền',
        OPERATIONS: 'Hoạt động',
        ADMINISTRATION: 'Hành chính',
    },
    ROLL_UP: {
        CHILD_OBJECT_NAME: 'Object con',
        PARENT_OBJECT_NAME: 'Object mẹ',
        CHILD_FIELD_NAME: 'Child Tên Field',
        PARENT_FIELD_NAME: 'Mẹ Tên Field',
    },
    ROYALTY_REPORT: {
        ROYALTY_REPORT_LABEL: 'Báo cáo tiền bản quyền',
        ROYALTY_PERCENT: 'Tiền bản quyền %',
        SHOW: 'Hiển thị',
        ALL_INVOICES: 'Tất cả hóa đơn',
        PAID_INVOICE_ONLY: 'Chỉ hóa đơn thanh toán',
        SETUP_TITLE: 'Thiết lập tiền bản quyền',
        PAID_INVOICES: 'Hóa đơn thanh toán',
        USE: 'Sử dụng',
        FIXED: 'Cố định %',
        VARIABLE: 'Biến %',
        TOTAL_INVOICES: 'Tổng hóa đơn',
        TOTAL_PAYMENTS: 'Tổng chi phí',
        CALCULATE: 'Tính toán',
        WEEKLY: 'Hàng tuần.',
        MONTHLY: 'Hàng tháng',
        WEEK_TYPE: 'TUẦN TYPE.',
        MON_TO_FRI: 'Thứ Hai - Thứ Sáu',
        MON_TO_SUN: 'Mon - Sun.',
        ADD_INVOICES: 'Thêm hóa đơn',
        TOTAL_Reported_Sales: 'Tổng doanh số báo cáo',
        YEAR: 'Năm',
        MARKETING_FEE: 'Phí tiếp thị',
        TECH_FEE: 'Phí công nghệ',
        PERCENT: 'Tỷ lệ phần trăm',
        FIXED_VALUE: 'đã sửa',
        WITH: 'Với phí',
        WITH_OUT: 'Không có phí',
        Reported_Sales_Franchisee: 'Doanh số được báo cáo - Bên nhận quyền',
        Reported_Sales_Franchisor: 'Doanh số được báo cáo - Bên nhượng quyền',
        Reported_Sales_ThirdParty: 'Bán hàng được báo cáo - Bên thứ ba',
    },
    POWER_CALLING: {
      NOTE_AND_CALL_SCRIPTS: 'Tập lệnh ghi chú và cuộc gọi',
      SEND: 'GỬI',
      LOG_CALL:'Ghi nhật ký cuộc gọi',
      CALLER_ID: 'Người gọi ID',
      END_CALL: 'Kết thúc cuộc gọi',
      MOBILE: 'Di động',
      PHONE: 'Điện thoại',
      HOME_PHONE:'Điện thoại nhà',
      NEXT: 'kế tiếp',
      COMPLETE: 'hoàn thành',
      OF: 'của',
      POWER_DIALER: 'Power Dialer'
    },
    LEAD_SCORE_SETUP:{ 
        SCORES: 'Pontuações',
        NO_SCORES_AVAILABLE: 'Não há ações disponíveis.',
        OBJECT: 'Objeto',
        GROUPS:'Grupos',
        WORKFLOW_NAME: 'Nome do Fluxo de Trabalho',
        CONVERTED_FROM:'Convertido de',
        CONVERTED_TO: 'Convertido para',
        FIELDS_NAME: 'Nome dos Campos',
        POINTS: 'Pontos',
        EXPIRES_BY: 'Expira em',
        APPLY_TO_EXISTING_SCORE: '* A data de expiração será aplicada retroativamente às suas pontuações existentes.',
        GROUP_ALREADY_REMOVE: 'Grupo já removido. Modifique antes de salvar.',
        FIELD_ALREADY_REMOVE: 'Campo já removido. Modifique antes de salvar.',
        FIELD_VALUE_ALREADY_REMOVE:'O valor do campo já foi removido. Modifique antes de salvar.',
        AND_ABOVE: 'e acima',
        LESS_THAN: 'Menor que',
    },
    DUPLICATE_RULE: {
        DUPLICATE_RULE: 'quy định trùng lặp',
        DUPLICATE_RULE_HEAD_LABEL: 'Kiểm tra trùng lặp bằng',
        SELECT_OBJECT_LABEL: 'Chọn Object',
        ACTIVE: 'tích cực',
        DEFAULT: 'Mặc định',
        ONE_FIELD: 'một Dòng',
        TWO_FIELD: 'hai Fields',
        THREE_FIELD: 'ba Fields',
        FIELD_ONE: 'Dòng 1',
        FIELD_TWO: 'Dòng 2',
        FIELD_THREE: 'Dòng 3',
        SELECT_FIELD_LABEL: 'Chọn Dòng',
        CHECK_DUPLICATES: 'Kiểm tra Duplicates',
        SELECT_RULES: 'Chọn một Rule',
    },
    JOBS:{
        RESCHEDULE: 'Sắp xếp lại',
        OMW:'omw',
        INVOICE:'Hóa đơn',
        PAY:'Trả'
    },
    TASK_REPORT:{
        TITLE:'Báo cáo nhiệm vụ',
        UNITS:'Các đơn vị',
        NO_TASK_FOUND:'Không tìm thấy nhiệm vụ nào',
    },
    AUDIT_TASK_REPORT_DETAILS:{
        TASK_REPORT_DETAILS:'Chi tiết báo cáo nhiệm vụ',
    },
    AUDIT_QUESTIONS: {
        MINIMUM_OPTIONS: 'Có ít nhất 2 lựa chọn phải có trong câu hỏi này',
        SINGLE_RECORD: 'Chỉ có 1 bản ghi được phép di chuyển cùng một lúc.',
        AUDIT_QUESTIONS: "Danh sách kiểm tra kiểm toán",
        GIVE_FEEDBACK:'Cung cấp thông tin phản hồi',
        NEW_QUESTION: 'Câu hỏi mới',
        TEXT: 'Văn bản ngắn',
        COMMENT: 'Văn bản dài',
        LIST: 'Lựa chọn duy nhất (thả xuống)',
        CHECKBOX: 'Nhiều lựa chọn (hộp kiểm)',
        QUESTION: 'Câu hỏi',
        ADD_NEW: 'Thêm mới',
        ADD_NEW_CATEGORY:'Add a New Category',
        SINGLE_RECORD_EDIT: 'Vui lòng chọn 1 bản ghi để chỉnh sửa.',
        MESSAGE_DELETE: 'Bạn có chắc chắn muốn xóa',
        SELECTED_RECORDS: 'Bản ghi đã chọn?',
        YOUR_ANSWER: "Câu trả lời của bạn",
        SUBMITTING: 'Đệ trình ...',
        SAVE_CATEGORY:'Lưu danh mục',
        SUBMIT_AUDIT:'Gửi kiểm tra',
        SUBMIT: 'Nộp',
        SCORE: 'Ghi bàn',
        REQUIRED: 'Yêu cầu',
        CHOICE: 'Sự lựa chọn',
        FIELD_REQUIRED: 'Trường này là bắt buộc *',
        NEW_CHOICE: 'Lựa chọn mới',
        FREE_FORM: 'Hình thức miễn phí',
        SCORE_HELP_TOOLTIP: "Gán điểm hoặc điểm số để trả lời. Điểm số sẽ cộng lại để tính tổng điểm cho kiểm toán.",
        SURVEY_LINK: "Liên kết kiểm toán / khảo sát",
        NEXT_CATEGORY:'Thể loại tiếp theo',
        GENERATE:'Phát ra',
        FIELD_AUDIT:'Kiểm toán thực địa (được kiểm toán bởi bên nhượng quyền)',
        SELF_AUDIT:'Tự kiểm toán (được kiểm toán bởi bên nhượng quyền)',
        GENERATE_AUDIT_LINK:'Tạo liên kết kiểm tra',
        REQUIRES_PHOTO: 'Yêu cầu ảnh',
        ENABLE_NOTE: 'Bật Ghi chú',
        ADD_PHOTO: 'Ảnh',
        UPDATE_PHOTO: 'Cập nhật',
        NOTE: 'Thêm một ghi chú',
        PHOTO_REQUIRED: 'Cần có ảnh *',
    },
    AUDIT_ANSWERS: {
        AUDIT_ANSWERS_LABEL: 'Kiểm toán trả lời',
        QUESTION:'Câu hỏi',
        SCORE: 'Ghi bàn',
        ANSWER: 'Bài giải',
        SUBMIT_CORRECTIVE_ACTIONS:'Gửi hành động khắc phục',
        REQUEST_CORRECTIVE_ACTIONS:'Yêu cầu hành động khắc phục',
        INSERT_PHOTO:'Chèn ảnh',
    },
    AUDIT_WEAKNESS:{
        CATEGORY:'Loại',
        MIN_FAILURE_COUNT:'Số lần thất bại tối thiểu',
    },
    AUDIT_CATEGORY:{
        ADD_NEW_CATEGORY:'Thêm một danh mục mới',
        MANAGE_CATEGORY: 'Quản lý danh mục',
        CATEGORIES:'Thể loại',
    },
    COMPLIANCE_REPORT:{
        COMPLIANCE_REPORT_LABEL: 'Báo cáo chuẩn',
    },
    SALES_AND_REFUND: {
        PERIOD:'Giai đoạn',
        START_DATE:'Ngày bắt đầu',
        END_DATE :'Ngày cuối',
        BASED_ON :'Dựa trên',
        SALES_AND_REFUND_TITLE:'Bán hàng và hoàn tiền',
        SALES_AND_REFUND_DETAIL:'Chi tiết bán hàng và hoàn tiền',
    },
    SALES_AND_REFUND_DETAILS_REPORT:{
        SALES_AND_REFUND_DETAILS :'Chi tiết báo cáo bán hàng và hoàn tiền',
        START_DATE:'Ngày bắt đầu',
        END_DATE:'Ngày cuối',
        BASED_ON:'Dựa trên',
        NO_RECORD_EXPORT: 'Không tìm thấy bản ghi nào'
    },
    TOTAL_REPORTED_SALES_DRILLDOWN:{
        TOTAL_REPORTED_SALES_DRILLDOWN:'Chi tiết về doanh số được báo cáo của tiền bản quyền'
    },
    OTHER_FORM_DATA: {
        CARETAKING:'Caretaking',
        EXPLANATION:'Explanation',
        EXPLANATION_HISTORY_TIMELINE:'Explanation of history/timeline (dates/places/etc)',
        PEOPLE_PLEASING:'People Pleasing',
        RELATIONSHIPS:'Relationships',
        PERFECTIONISM:'Perfectionism',
        CONTROL:'Control',
        WORKAHOLISM:'Workaholism',
        SHOPPING_SPENDING:'Shopping/Spending',
        RELIGION_OR_ABUSE:'Religion/Abuse',
        RISK_TAKING:'Risk Taking',
        EXCESSIVE_SCREEN_TIME:'Excessive Screen time',
        DEPRESSION:'Depression',
        ANXIETY:'Anxiety',
        ANGER_OR_RAGE:'Anger/Rage',
        SELF_EXTEEM:'Self Esteem',
        PAINFUL_OR_TROMATIC :'Describe any painful/traumatic/memorable experiences or events that have been impactful in your life (trauma)',
        MOTHER:'Mother',
        FATHER:'Father',
        SIBLINGS:'Siblings',
        CHILDREN:'Children',
        SIG_OTHER:'Sig. Other',
        OTHER:'Other',
        PRESENTING_PROBLEM:'Describe the main presenting problem or current crisis/goal for coming to TBR',
        ALCOHAL:'Alcohol',
        HOW_MUCH:'How Much & What type?',
        HOW_OFTEN:'How often, and for how long?',
        DATE_LAST_USED:'Date Last Used',
        COCAINE_OR_CRACK :'Cocaine/Crack',
        HEROINE:'Heroin',
        MARIJUANA:'Marijuana',
        METHADONE_OR_SUBOXONE :'Methadone/Suboxone',
        AMPHETAMINES :'Amphetamines & Stimulants (Meth, Adderall, Speed)',
        NARCOTICS_OR_OPIATES:'Narcotics/Opiates',
        BENZODIAZEPINES:'Benzodiazepines',
        TOBACCO:'Tobacco',
        OTHER_ETC:'Other (Hallucinogenic, OTC, Synthesis, etc)',
        WITHDRAWEL_SYMPTOMS :'Withdrawal Symptoms (shaking, sweating, tremors, seizures etc)',
        MEDICAL_DETOX:'Medical Detox',
        PRIMARY_TRATMENT_OR_IOP:'Primary Treatment/IOP',
        SUCIDE_THOUGHT:'Had suicidal Thoughts?',
        ENGAGED_IN_SELF_HARD:'Engaged in Self Hard',
        ATTEMPED_SUCIDE:'Attempted Suicide While under the influence?',
        ENGAGED_IN_CUTTING:'Engaged in "cutting" behaviors',
        ATTEMPED_SUCIDE_WHITE_SOBER:'Attempted suicide while Sober?',
        ENGEGED_IN_SCORINCATION :'Engaged in "scorincation"' ,
        HOSPITALIZED_FOR_A_SUICIDE_ATTEMPT:'Been hospitalized for a suicide attempt?',
        ENGEGED_IN_BODY_MUTATION:'Engaged in any other body mutation bx',
        HISTORY_EXPLANATION:'If history, explanation (use this space to provide further details, including dates, current ideation, thoughts or plans, etc.)',
        PHYCHIATRIC_DIANOSIS:'Psychiatric diagnosis',
        DATES_DIAGNOSIS_TREATMENT:'Date, diagnosis, treatment',
        MENTAL_BREAKDOWNS_OR_HOSPITALIZATIONS:'Mental Breakdowns/Hospitalizations' ,
        ANOREXIA:'Anorexia',
        TREATMENT_HISTORY:'Treatment History',
        PERIODS_OF_ABSTINENCE:'Periods of Abstinence',
        LAST_EPISODE:'Last Episode',
        LOWEST_WEIGHT:'Lowest Weight',
        HIGHEST_WEIGHT:'Highest Weight',
        CURRENT_WEIGHT_OR_HEIGHT: 'Current Weight/Height',
        HOW_LONG_AT_CURRENT_WEIGHT:'How Long at Current Weight',
        BULIMIA:'Bulimia',
        OVEREATING:'Overeating',
        EXCESSING_EXCERCISING:'Excessive Exercising',
        DETAILS_SOBRIETY_LENGTH_PATTERNS:'If history, explanation (use this space to provide further details, sobriety length, patterns etc.)',
        BETTING:'Betting',
        ONSET:'Onset',
        TREATMENT:'Treatment',
        AMOUNT_OF_DEBT_ACCUMULAATED:'Amount of Debt accumulated',
        TIME_PER_DAY_OR_WEEK_SPENT_GAMBLING:'Time per day/week spent gambling',
        GAMING_OR_CASINOS :'Gaming/Casinos',
        ONLINE_GAMES_OR_APPS:'Online Games/Apps' ,
        PORNOGRAPHY:'Pornography',
        FIRED:'Have you ever been fired or had job threatened because of behavior?',
        EXCESSIVE_MASTURBATION:'Excessive Masturbation',
        VOYEURISM_OR_EXHIBITIONIST:'Voyeurism/Exhibitionist',
        ANONYMOUS_SEX:'Anonymous Sex (casual sex, one-night stands, hookup apps/websites)',
        PROSTITUTION:'Prostitution',
        AFFAIRS:'Affairs (Physical/Emotional)',
        SERIAL_RELATIONSHIP:'Serial Relationships',
        INCLUDE_DATE_TEASON_OR_CHARGE:'Include Date, Reason/Charge (What was the charge/reason for arrest), Result (was there a conviction, jail time served, probation/parole, sex offender registration etc), Current Pending/Legal Obligations',
        CODEPENDENCY_AND_PROCESS_ADDITIONS_BEHAVIOURS:'Codependency and process addictions behaviors ("C" for Current, "D" Denies or "H" for History)',
        RELATIONSHIP_AND_FAMILY_HISTORY:'Relationship/family history (List current relationship status and family history of addiction/psych issues)',
        SUBSTANCE_HISTORY:'Substance history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use',
        SUBSTANCE_TREATMENT_HISTORY:'Substance treatment history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use',
        SUICIDIAL_IDEATION:'Suicidal ideation / self harm behaviors ("C" for Current, "D" Denies or "H" for History)',
        MENTAL_HEALTH_AND_PSHYATRATIC_DIAGNOSIS_HISTORY :'Mental health/ psychiatric diagnosis history- ("C" for Current, "D" Denies or "H" for History)',
        EATING_DISORDER_BEHAVIER:'Eating disorder behaviors - ("C" for Current, "D" Denies or "H" for History)',
        GAMBLIMG_BEHAVIER:'Gambling behaviors - ("C" for Current, "D" Denies or "H" for History)',
        SEXUAL_RELATIONSHIP_LOVE_BEHAVIOUR:'Sexual/relationship/love behaviors- ("C" for Current, "D" Denies or "H" for History)',
        ARRESTED_HISTORY:'Arrest history (including prior/current and pending)',
    },
    PHONE_NUMBERS: {
        USE_NOTIFICATION_TOOLTIP: 'Sử dụng để thông báo',
        REMOVE_NOTIFICATION_TOOLTIP: 'Xóa để thông báo',
    },
    PHONE_AND_TEXT_INTEGRATION: {
        MANAGE_UNIT_PHONE: 'Quản lý số điện thoại, tín dụng văn bản và số phút gọi của đơn vị',
    },
}
export default label