import { actions } from '..//constants/actions';

function setMarketingResults(marketingResults) {
    return function (dispatch) {
        dispatch({
            type: actions.MARKETING_REPORTS_REDUCER_REDIRECTION,
            payload: {
                marketingResults: marketingResults
            }
        })
    }
}

function setEmailMarketingReportDetails(marketingReportData, object) {
    return function (dispatch) {
        dispatch({
            type: actions.EMAIL_MARKETING_REPORTS_DETAILS,
            payload: { 
                marketingReportData: marketingReportData,
                object: object

            }
        })
    }
}


function setEmailMarketingReportDetailsScroll(marketingReportData) {
    return function (dispatch) {
        dispatch({
            type: actions.EMAIL_MARKETING_REPORTS_DETAILS_SCROLL,
            payload: { 
                marketingReportData: marketingReportData,

            }
        })
    }
}

function setEmailMarketingReportToggle(selectedCampaignReport, object) {
    return function (dispatch) {
        dispatch({
            type: actions.EMAIL_MARKETING_REPORTS_TOGGLE,
            payload: { 
                selectedCampaignReport: selectedCampaignReport,
                object: object
            }
        })
    }
}

export {
    setMarketingResults,
    setEmailMarketingReportDetails,
    setEmailMarketingReportDetailsScroll,
    setEmailMarketingReportToggle
}