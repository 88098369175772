import React from 'react';
import {showCustomSnackBar} from "../../../services/actions/snackBarAction";
import { Button, TextField, FormControl } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { isValidParam,isEmpty,getStringParam} from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { setLmsNode } from '../../../services/actions/appActions';


    const LmsSetup = () => {
        const [lmsAccountField , setLmsAccountField] = useState('');
        const dispatch = useDispatch();

      useEffect(() => {
        try {
            var promise = Promise.resolve(HTTPClient.get(endPoints.COMPLIANCE_SETUP.GET, null));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        setLmsAccountField(response.complinaceSetup.lms_account);
                     }
                });
            }
        } catch (error) {
            console.error("Error in 'LmsSetup.js -> componentDidMount()':" + error);
        }

    },[]);

    const onChangeTextFiled = (value, fieldName) => {
        if(fieldName == 'lmsAccount'){
            setLmsAccountField(value);
        } 
        
    }

   const saveData = () => {
       let params = {};
        params.lms_account = lmsAccountField;
    
        if (!isValidParam(lmsAccountField) || isEmpty(getStringParam(lmsAccountField))) {
            dispatch(showCustomSnackBar('Please provide a LMS Account.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }
        else {
            let promise = Promise.resolve(HTTPClient.post(endPoints.COMPLIANCE_SETUP.SAVE, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.status === 0) {
                    dispatch(setLmsNode(lmsAccountField));    
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    
                    }
                }
            });
        }

    }

        return (
            <div style={{ marginTop: '40px', marginLeft: '6%', width: '50%'}}>
                <span style={{ fontWeight:'bold',fontSize:'22px' }}>{getLocalizedStrings().label.COMMON.LMS_SETUP} </span>
                <div style={{ ...styles.sf_12 }}>
                    <FormControl style={{ width: '100%', marginBottom:'20px' , marginTop: '8px'}} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            name="bcc"
                            id="bcc"
                            value={lmsAccountField}
                            label= {getLocalizedStrings().label.COMMON.LMS_LABEL} 
                            onChange={(e) => onChangeTextFiled(e.target.value,'lmsAccount')}
                            autoComplete="new-password"
                            margin="dense"
                            size='small'
                            className={"sf-fields-bg"}

                        />
                    </FormControl>
                </div>
                <div style={{width:'100%'}}>
                <div style={{ float: 'right' }}>
                    <Button style={{ ...styles.primaryButton, fontSize: 13,marginRight:0,marginTop: "20px"}} size="small" onClick={() =>saveData()} >{getLocalizedStrings().label.COMMON.SAVE} </Button>
                </div>
                </div>
                
            </div>
        );
    }

export default LmsSetup;