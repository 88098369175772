import React from 'react';
import { connect } from 'react-redux';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants } from '../../../services/constants/constants';
import { isValidParam, getArrayParam, getIntParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { getObjectLabelByObject } from '../../../services/helper/common';
import DraggableSelect from '../../sfPlugins/draggableSelect.tsx';


const maxLimit = 50;
class DetailViewChildObjectFields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            childObjects: [],
            selectedChild: null,
            account_fields: [],
            selected_account_fields: [],
            contact_fields: [],
            selected_contact_fields: [],
            isMounted: false,
            selected_child_fields: {},
        }
    }

    componentDidMount() {
        this.populateFields();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate() {
    }

    populateFields = () => {
        try {
            let selected_child_fields = {};
            let accountFields = this.getFormFields(constants.ACCOUNTS_OBJECT);
            let contactFields = this.getFormFields(constants.CONTACTS_OBJECT);
            accountFields = this.getOptions(accountFields);
            contactFields = this.getOptions(contactFields);
            let accountSelectedFields = [];
            let constantSelectedFields = [];
            if (isValidParam(this.props.childDetails)) {
                let objects = Object.keys(this.props.childDetails);
                let childDetails = this.props.childDetails;
                objects.forEach(function (object) {
                    let details = childDetails[object];
                    let selectedArr = [];
                    if (details.hasOwnProperty('fields')) {
                        let selectedFields = details.fields;
                        selectedFields.map((fld, i) => {
                            let fldObj = {};
                            fldObj.value = fld.name;
                            fldObj.label = fld.label;
                            fldObj.title = fld.label;
                            selectedArr.push(fldObj);
                        });
                        if (object === constants.ACCOUNTS_OBJECT) {
                            accountSelectedFields = selectedArr;
                        } else {
                            constantSelectedFields = selectedArr;
                        }
                        selected_child_fields[object] = selectedArr;
                    }
                })
            }

            this.setState({
                selected_account_fields: accountSelectedFields, selected_contact_fields: constantSelectedFields,
                account_fields: accountFields, contact_fields: contactFields,
                selected_child_fields: selected_child_fields
            });
        } catch (error) {
            console.error("Error in 'DetailViewChildObjectFields.js -> populateFields()': " + error);
        }
    }

    getOptions = (fields) => {
        let optionList = null;
        try {
            optionList = fields.map(m => {
                {
                    return { value: m.name, label: m.label, title: m.label }
                }
            });
            sortArrayObjectByProperty(optionList, 'label');
        } catch (error) {
            console.error("Error in ' DetailViewChildObjectFields -> getOptions()'" + error);
        }
        return optionList;
    }

    removeFromgenerateFieds = (fields) => {
        let fieldsWithoutHeader = [];
        fields.map((fieldObject, i) => {
            if (fieldObject.field_type !== constants.FIELD_TYPE_HEADER && fieldObject.name !== "" && fieldObject.label !== "" && fieldObject.field_type !== constants.FIELD_TYPE_INTEGER) {
                fieldsWithoutHeader.push(fieldObject);
            }
        });
        return fieldsWithoutHeader;
    }

    getFormFields = (object) => {
        let fields = null;
        try {
            if (this.props.sfForm.data.hasOwnProperty(object)) {
                fields = getArrayParam(this.props.sfForm.data[object].fields);
                fields = this.removeFromgenerateFieds(fields);
            }
        } catch (error) {
            console.error("Error in 'DetailViewChildObjectFields.js -> getFormFields()': " + error);
        }
        return fields;
    }

    handleChildSetupSelect = (fieldName, options) => {
        let _state = this.state;
        let totalCount = 0;
        let oppSetupFields = getIntParam(this.props.setupFieldsCount());
        totalCount = oppSetupFields;
        if (fieldName === 'accountField') {
            totalCount = totalCount + getIntParam(_state.selected_contact_fields.length);
        } else {
            totalCount = totalCount + getIntParam(_state.selected_account_fields.length);
        }
        if ((options.length + totalCount) > maxLimit) {
            this.props.showCustomSnackBar(getLocalizedStrings().message.DETAIL_VIEW.MAX_SELECTED_FIELD_LIMIT, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            return;
        }
        try {
            let availableFields = [];
            let fields = [];
            let object = constants.ACCOUNTS_OBJECT;
            if (fieldName === 'accountField') {
                fields = _state.account_fields;
            } else if (fieldName === 'contactField') {
                fields = _state.contact_fields;
                object = constants.CONTACTS_OBJECT;
            }
            fields = this.removeFromgenerateFieds(fields);

            if (isValidParam(options)) {
                options.map((objOption, i) => {
                    objOption.title = objOption.label;
                });
                _state.selected_child_fields[object] = options;
                if (fieldName === 'accountField') {
                    _state.selected_account_fields = options;
                } else if (fieldName === 'contactField') {
                    _state.selected_contact_fields = options;
                }
            }
            this.setState({ _state });
        } catch (error) {
            console.error("Error in DetailViewChildObjectFields.js -> handleChildSetupSelect():'" + error);
        }
    }

    getChildFieldsCount = () => {
        let total = 0;
        try {
            let selected_account_fields = getArrayParam(this.state.selected_account_fields);
            let selected_contact_fields = getArrayParam(this.state.selected_contact_fields);

            total = total + getIntParam(selected_account_fields.length);
            total = total + getIntParam(selected_contact_fields.length);
        } catch (error) {
            console.error("Error in DetailViewChildObjectFields.js -> getChildFieldsCount():'" + error);
        }

        return total;
    }

    rearrangeSequenceAccounts = (options) => {
        let _state = this.state;
        if (options !== null && options !== undefined)
            _state.selected_account_fields = options;
        _state.selected_child_fields['accounts'] = options;
        this.setState(_state);
    }

    rearrangeSequenceContacts = (options) => {
        let _state = this.state;
        if (options !== null && options !== undefined)
            _state.selected_contact_fields = options;
        _state.selected_child_fields['contacts'] = options;
        this.setState(_state);
    }

    render() {
        let accountObjectLabel = getObjectLabelByObject(constants.ACCOUNTS_OBJECT);
        accountObjectLabel = getLocalizedStrings().label.NO_RECORD.hasOwnProperty(accountObjectLabel) ? getLocalizedStrings().label.NO_RECORD[accountObjectLabel] : accountObjectLabel;
        let contactObjectLabel = getObjectLabelByObject(constants.CONTACTS_OBJECT);
        contactObjectLabel = getLocalizedStrings().label.NO_RECORD.hasOwnProperty(contactObjectLabel) ? getLocalizedStrings().label.NO_RECORD[contactObjectLabel] : contactObjectLabel;

        return (
            <div>
                <div style={{ paddingTop: '15px', paddingBottom: '5px' }} >
                    {getLocalizedStrings().label.COMMON.SELECT + ' ' + accountObjectLabel + ' ' + getLocalizedStrings().label.LIST_VIEW.FIELDS}
                </div>
                <div id="accounts_fld_setupDiv">
                    <DraggableSelect
                        isClearable
                        loadingPlaceholder={getLocalizedStrings().label.COMMON.LOADING + "..."}
                        multi={true}
                        value={this.state.selected_account_fields}
                        options={this.state.account_fields}
                        selectLimit ={maxLimit}
                        placeholder={getLocalizedStrings().label.DETAIL_VIEW.SELECT_OPTIONS}
                        limitMessage ={getLocalizedStrings().message.DETAIL_VIEW.MAX_SELECTED_CHILD_OBJECT_LIMIT}
                        onChange={this.handleChildSetupSelect.bind(this, 'accountField')}
                        onSortEnd={this.rearrangeSequenceAccounts}
                        clearAllText={getLocalizedStrings().label.COMMON.CLEAR_ALL}
                    />
                </div>
                <div style={{ paddingTop: '15px', paddingBottom: '5px' }} >
                    {getLocalizedStrings().label.COMMON.SELECT + ' ' + contactObjectLabel + ' ' + getLocalizedStrings().label.LIST_VIEW.FIELDS}
                </div>
                <div id="contacts_fld_setupDiv">
                    <DraggableSelect
                        isClearable
                        loadingPlaceholder={getLocalizedStrings().label.COMMON.LOADING + "..."}
                        multi={true}
                        value={this.state.selected_contact_fields}
                        options={this.state.contact_fields}
                        selectLimit ={maxLimit}
                        placeholder={getLocalizedStrings().label.DETAIL_VIEW.SELECT_OPTIONS}
                        limitMessage ={getLocalizedStrings().message.DETAIL_VIEW.MAX_SELECTED_CHILD_OBJECT_LIMIT}
                        onChange={this.handleChildSetupSelect.bind(this, 'contactField')}
                        onSortEnd={this.rearrangeSequenceContacts}
                        clearAllText={getLocalizedStrings().label.COMMON.CLEAR_ALL}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        detailView: state.detailView,
        app: state.app,
        sfForm: state.sfForm
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        }
    }
};

DetailViewChildObjectFields = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DetailViewChildObjectFields);

export default DetailViewChildObjectFields;