import { actions } from '..//constants/actions';

const salesAndRefundInitialState = {
    salesAndRefundCategory : {},
    salesAndRefundDetailscategory: {},
}

const SalesAndRefundReducer = (state = salesAndRefundInitialState, action) => {
    switch (action.type) {
        case actions.UPDATE_SALES_AND_REFUND_DATA:
            return {
                ...state,
                salesAndRefundCategory: action.payload
            }
        case actions.UPDATE_SALES_AND_REFUND_DETAILS:
            return {
                ...state,
                salesAndRefundDetailscategory: action.payload
            }
        default:
            return state;
    }
}

export default SalesAndRefundReducer;