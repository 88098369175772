import React, { useEffect } from 'react';
import { getTemplateList } from '../../../services/actions/automationCampaignTemplateActions';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { setHeader } from '../../../services/actions/headerActions';
import AutomationTemplateCard from '../components/automationTemplateCard';
import { getActiveTab, updateActiveTab } from '../../../services/helper/sfTabManager';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useDispatch, useSelector } from "react-redux";


const AutomationTemplates = () => {

    const dispatch = useDispatch();

    const automationCampaignTemplate = useSelector((state) => state.automationCampaignTemplate);
    const app = useSelector((state) => state.app);

    useEffect(() => {
        let label = null;
        let kanbanObject = {};
        kanbanObject.currentView = null;
        kanbanObject.show = false;
        dispatch(setHeader("", label, kanbanObject, false, false));
        dispatch(getTemplateList());
    }, []);



    useEffect(() => {
        let activeTab = getActiveTab();
        if (activeTab.url === '/automation-designer/edit/0') {
            activeTab.url = '/automation-designer/new';
            updateActiveTab(activeTab);
        }
    });

    const generateTemplateList = () => {
        let templateList = null;
        let templateArr = automationCampaignTemplate.data.templates;
        let moduleAccessItem = app.me.module_access;
        let _templateArr = templateArr
        _templateArr = _templateArr.filter(f => f.templateName === "SMS Drip Campaign");
        templateArr = templateArr.filter(f => f.templateName !== "SMS Drip Campaign");

        if (!moduleAccessItem.hasOwnProperty('Pet') || moduleAccessItem.Pet === 0) {
            templateArr = templateArr.filter(f => f.templateName !== "Pet Birthday Campaign");
        }

        if (isValidParam(templateArr)) {
            templateArr.splice(1, 0, ..._templateArr);
            templateList = templateArr.map((objTemplate, i) => {
                if (objTemplate.hasOwnProperty('imgUrl')) {
                    let imageUrl = objTemplate['imgUrl'];
                    if (imageUrl.includes('touch-thumb.jpg')) {
                        imageUrl = imageUrl.replace("touch-thumb.jpg", "touch-thumb-react.jpg");
                    } else if (imageUrl.includes('touch-webform.jpg')) {
                        imageUrl = imageUrl.replace("touch-webform.jpg", "touch-webform-react.jpg");
                    } else if (imageUrl.includes('thumb-webvisited.jpg')) {
                        imageUrl = imageUrl.replace("thumb-webvisited.jpg", "thumb-webvisited-react.jpg");
                    } else if (imageUrl.includes('thumb-landingpage.jpg')) {
                        imageUrl = imageUrl.replace("thumb-landingpage.jpg", "thumb-landingpage-react.jpg");
                    } else if (imageUrl.includes('start-landingpage.jpg')) {
                        imageUrl = imageUrl.replace("start-landingpage.jpg", "start-landingpage-react.jpg");
                    } else if (imageUrl.includes('start-touch.jpg')) {
                        imageUrl = imageUrl.replace("start-touch.jpg", "start-touch-react.jpg");
                    } else if (imageUrl.includes('start-webform.jpg')) {
                        imageUrl = imageUrl.replace("start-webform.jpg", "start-webform-react.jpg");
                    } else if (imageUrl.includes('start-webvisited.jpg')) {
                        imageUrl = imageUrl.replace("start-webvisited.jpg", "start-webvisited-react.jpg");
                    } else if (imageUrl.includes('start-birthday.jpg')) {
                        imageUrl = imageUrl.replace("start-birthday.jpg", "start-birthday-react.jpg");
                    } else if (imageUrl.includes('start-sms.jpg')) {
                        imageUrl = imageUrl.replace("start-sms.jpg", "start-sms-react.png");
                    }
                    objTemplate.imgUrl = imageUrl;
                }
                return (
                    <div key={objTemplate.id} style={{ display: 'inline-block', paddingBottom: '15px', paddingRight: '15px' }}>
                        <AutomationTemplateCard objTemplate={objTemplate} />
                    </div>
                );
            });
        }
        return templateList
    }


    let themeColor = app.me.background;
    if (automationCampaignTemplate.mounted) {
        let templateList = generateTemplateList();
        return (
            <div>
                <div className='appheader' style={{ backgroundColor: '#fff', marginBottom: '20px', paddingBottom: '4px' }}>
                    <div style={styles.listViewHeaderFilter}>
                        <div style={styles.listViewFilterBox}>
                            <div className="headingApp" style={{ float: 'left', height: '40px', width: '100%', cursor: 'default' }}>
                                <div>
                                    <div style={{ paddingLeft: '0px', float: 'left' }}>
                                        <i className="fa fa-sitemap"
                                            style={{ float: 'left', marginTop: '3px', backgroundColor: themeColor, fontSize: '14px', marginLeft: '-6px', marginRight: '10px' }} />
                                        <span >{getLocalizedStrings().label.AUTOMATION_DESIGNER.CHOOSE_TEMPLATE}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ paddingTop: '13px', paddingLeft: '38px' }}>
                    {templateList}
                </div>
            </div>
        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }
};

export default AutomationTemplates;