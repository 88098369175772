
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { styles } from '../../../services/constants/styles';
import { Select, MenuItem, InputLabel } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import * as sfDialogs from '../components/sfDialogs';
import { saveImportRecords, getWorkflowStagesTask, sendMailForImportedRecords } from '../../../services/actions/importAction';
import { applyWorkFlow } from '../../../services/actions/batchActions';
import PopOver from '../components/PopOver';
import { isValidParam, getObjectParam, getArrayParam, getStringParam } from '../../../services/helper/parameterVerifier';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import ImportSaveMapping from '../components/importSaveMapping';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { getSFFormFieldsPromise } from '../../../services/actions/sfFormActions';
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import { getSavedMapping, saveMapping, getPreviousMappingFields, deleteMapping } from '../../../services/actions/importAction';


const mapList = [{
    value: "automap",
    label: getLocalizedStrings().label.IMPORT.AUTO_MAP
}, {
    value: "usesavedmap",
    label: getLocalizedStrings().label.IMPORT.USE_SAVED_MAPPING
},
{
    value: "savemap",
    label: getLocalizedStrings().label.IMPORT.SAVE_MAPPING
}, {
    value: "deletemap",
    label: getLocalizedStrings().label.IMPORT.DELETE_MAPPING
}
]

const ImportMappingFields = ({ object, importData, importInfo }) => {

    const app = useSelector((state) => state.app);
    const sfForm = useSelector((state) => state.sfForm);
    const linkWithFields = useSelector((state) => state.sfForm.data !== null && state.sfForm.data.accounts !== null ? state.sfForm.data.accounts.fields : null);

    const [isMounted, setisMounted] = useState(false);
    const [sourceFieldMap, setsourceFieldMap] = useState({});
    const [sourceDestFieldMap, setsourceDestFieldMap] = useState({});
    const [fileName, setfileName] = useState('');
    const [duplicateFieldMap, setduplicateFieldMap] = useState(null);
    const [noOfRecords, setnoOfRecords] = useState(0);
    const [noOfRows, setnoOfRows] = useState(0);
    const [tableLabel, settableLabel] = useState('');
    const [duplicate_info_obj, setduplicate_info_obj] = useState({
        selected_duplicate_field: [],
        is_duplicate_check: true,
        duplicate_check_opt: 'noAction'

    });
    const [openActionList, setopenActionList] = useState(false);
    const [isImportClicked, setisImportClicked] = useState(false);
    const [totalRecordCount, settotalRecordCount] = useState(0);
    const [import_response_obj, setimport_response_obj] = useState({
        importingRecordCount: 0,
        failCount: 0,
        duplicateCount: 0,
        isImportDone: false,
        applyWorkflow: false
    });
    const [recordArrForWorkFlow, setrecordArrForWorkFlow] = useState([]);
    const [workFlowApplied, setworkFlowApplied] = useState(false);
    const [link_obj_details, setlink_obj_details] = useState({
        linkTableId: 10,
        linkWithValue: -1,
        linkUsingValue: -1,
        tableName: -1,
    
    });
    const [linkWithValueArr, setlinkWithValueArr] = useState([]);
    const [destinationFieldArr, setdestinationFieldArr] = useState([]);
    const [openSaveMappingComponent, setopenSaveMappingComponent] = useState(false);
    const [tableFieldsColumn, settableFieldsColumn] = useState([]);
    const [mappingAction, setmappingAction] = useState('');
    const [totalApplyWFCount, settotalApplyWFCount] = useState(0);
    const [objectName, setobjectName] = useState(object);
    const [fieldDataArray, setfieldDataArray] = useState([]);
    const [defaultVal, setDefault] = useState('');
    const [changeLog, setChangeLog] = useState({totalApplyWFCount:0});

    useEffect(() => {
        if (importData !== null) {
            let data = importData;
            let sourceFieldsMap = getObjectParam(data.data.data.source_fields);
            let sourceFieldObject = {};
            let sourceDestFieldObject = {};
            let file_name = data.data.data.file_name;
            let no_of_recs = data.data.data.no_of_records;
            let no_of_rows = data.data.data.no_of_rows;
            Object.keys(sourceFieldsMap).map((key) => {
                sourceFieldObject[key] = sourceFieldsMap[key]
                sourceDestFieldObject[key] = "-1";
            });
            if (object !== null && (object !== constants.ACCOUNTS_OBJECT
                && object !== constants.CONTACTS_OBJECT)) {

                let fields = getArrayParam(linkWithFields);
                let linkObj = null;
                fields.forEach(f => {
                    if (f.field_type !== constants.FIELD_TYPE_HEADER && f.label !== '' && f.name !== 'workflow_id') {
                        linkObj = {};
                        linkObj.table_field_id = f.t_tablefldid;
                        linkObj.label = f.label;

                        linkWithValueArr.push(linkObj);

                    }
                });


                sortArrayObjectByProperty(linkWithValueArr, 'label');
            }

            if (no_of_recs > 10000) {
                let msg = getLocalizedStrings().message.IMPORT.MAX_IMPORT_RECORDS_MSG_1 + ' ' + no_of_recs + ' ' + getLocalizedStrings().message.IMPORT.MAX_IMPORT_RECORDS_MSG_2;
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
            }
            setisMounted(true);
            setsourceFieldMap(sourceFieldObject);
            setsourceDestFieldMap(sourceDestFieldObject);
            setfileName(file_name);
            setnoOfRecords(no_of_recs);
            setnoOfRows(no_of_rows);
            setlinkWithValueArr(linkWithValueArr)
            getFieldsByObject();
        }
    }, []);
    const getFieldsByObject = () => {
        let selectObjetFields = [];
        let optionsListItem = [];
            optionsListItem = sfForm.data[objectName]?.fields;
            if (!isValidParam(optionsListItem)) {
                let promise = getSFFormFieldsPromise(objectName);
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            sfForm.data[objectName] = response;
                            optionsListItem = response.fields;
                            if (optionsListItem.length > 0) {
                                optionsListItem.map((items, Index) => {
                                    if (items.t_tablefldid !== -1 && items.name !== "workflow_id" && items.is_hidden_field === false && items.field_type !== "memo") {
                                        let objectList = {
                                            label: items.label,
                                            value: items.name,
                                            title: items.label,
                                            name: items.name,
                                        };
                                        selectObjetFields.push(objectList);
                                    }
                                }
                                )
                            }
                            sortArrayObjectByProperty(selectObjetFields, 'label');
                            setfieldDataArray(selectObjetFields);

                        }
                    });
                }
            } else {
                if (optionsListItem.length > 0) {
                    optionsListItem.map((items, Index) => {
                        if (items.t_tablefldid !== -1 && items.name !== "workflow_id" && items.is_hidden_field === false && items.field_type !== "memo") {
                            let objectList = {
                                label: items.label,
                                value: items.name,
                                title: items.label,
                                name: items.name,
                            };
                            selectObjetFields.push(objectList);
                        }
                    }
                    )
                }
                sortArrayObjectByProperty(selectObjetFields, 'label');
                setfieldDataArray(selectObjetFields);
            }
    }

    let fieldNamesValidation = {
        tableName: link_obj_details.tableName,
        linkWithValue: link_obj_details.linkWithValue,
        linkUsingValue: link_obj_details.linkUsingValue
    };

    

    const changeMapField = (value, field) => {
        let temp = sourceDestFieldMap;
        temp[field] = value;
        setsourceDestFieldMap({...temp});
    }

    const changeLinkFieldValue = (event) => {
        const { name, value } = event.target;

        setlink_obj_details((prev) => {
            if (name === 'tableName') {
                return { ...prev, [name]: value, linkWithValue: -1 }
            } else {
                return { ...prev, [name]: value }
            }
        });
    }

    const getImportData = () => {
        let s = sourceDestFieldMap;
        let sfFormFieldContainerStyle = {  ...styles.sfFormFieldContainer  };
        let data = importData;
        let impDataList = data.data.data.importData;
        let object = {};
        let component = null;
        let componentArr = [];
        impDataList.map((importData, index) => {
            if (index === 0) {
                let sourceFieldsMap = sourceFieldMap;
                let destinationFieldsMap = getObjectParam(importData.destinationFields);

                let tableFieldsColumn = getObjectParam(importData.tableFieldsColumn);

                let destObj = null;
                if (destinationFieldArr !== null && destinationFieldArr.length === 0) {
                    Object.keys(destinationFieldsMap).map((dsKey) => {
                        destObj = {};
                        destObj.field_id = dsKey;
                        destObj.label = destinationFieldsMap[dsKey];
                        destObj.name = tableFieldsColumn[dsKey];
                        destinationFieldArr.push(destObj);
                    });
                    let noteObj = {};
                    noteObj.field_id = "-999";
                    noteObj.label = constants.NOTE_OBJECTS;
                    destinationFieldArr.push(noteObj);
                    sortArrayObjectByProperty(destinationFieldArr, 'label');
                }

                let row = 0;
                let style = '';
                Object.keys(sourceFieldsMap).map((sKey) => {
                    row++;
                    if (row % 2 === 0) {
                        style = { height: '30px', borderBottom: '1px solid #ddd', backgroundColor: '#fafafa', fontSize: '14px' };
                    } else {
                        style = { height: '30px', borderBottom: '1px solid #ddd', fontSize: '14px' };
                    }
                    componentArr.push(
                        <div className='mapbar'>
                            <table style={{ width: '100%', tableLayout: 'fixed' }}>
                                <tbody>
                                    <tr style={style}>
                                        <td style={{ width: '60%' }}>{getLocalizedStrings().label.IMPORT.COLUMN} {parseInt(sKey) + 1} : <b>{sourceFieldsMap[sKey]}</b></td>
                                        <td style={{ width: '20%', textAlign: 'right', paddingRight: '10px' }}>{getLocalizedStrings().label.IMPORT.MAP_TO_CRM_FIELD}: </td>
                                        <td style={{ width: '20%' }}>
                                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                                <InputLabel id="sf-importmappingfields-simple-select-outlined-label" className="sf-importmappingfields">{null}</InputLabel>
                                                <Select
                                                    key={"source" + sKey}
                                                     value={sourceDestFieldMap[sKey]}
                                                    onChange={(e) => changeMapField(e.target.value, sKey)}
                                                    labelId='sf-label-source'
                                                    id='sf-source'
                                                    style={{ height: '42px' }}
                                                    className={"sf-fields-bg"}
                                                >
                                                    <MenuItem value="-1"
                                                        id="-1"
                                                        key="-1"
                                                        insetChildren={true}
                                                        checked={sourceDestFieldMap[sKey] !== null && sourceDestFieldMap[sKey] === "-1" ? true : false}
                                                        style={styles.popoverMenuItem} >None</MenuItem>
                                                    {
                                                        getDestinationFieldArray(sKey)
                                                    }

                                                </Select>
                                            </FormControl>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div></div>
                        </div>
                    )
                });

            }
        });
        if (duplicateFieldMap == null) {
            getRuleData();
        }
        return componentArr;

    }

    const getRuleData = () => {
            if (objectName != '' && fieldDataArray.length > 0) {
                let tableId = OBJECT_TABLEID_MAP[objectName];
                let fldList = fieldDataArray;
                let defaultValue = '';
                let rulesField = false;
                if (tableId > 0) {
                    let params = { table_id: tableId };
                    let url = endPoints.DUPLICATE_RULE.GET;
                    let promise = Promise.resolve(HTTPClient.get(url, params));
                    promise.then((response) => {
                        if (isValidParam(response) && Array.isArray(fldList)) {
                            let duplicateRules = [];
                            response.map((items, Index) => {
                                if (items.is_active == 1) {
                                    let rule = {};
                                    let label = '';
                                    let valuesFld = [];
                                    if (items.field_names.length > 0) {
                                        for (var i = 0; i < items.field_names.length; i++) {
                                            let dataFld = fldList.find(e => e.name === items.field_names[i].field_name);
                                            if (!dataFld) {
                                                rulesField = false;
                                            } else {
                                                rulesField = true;
                                            }
                                            if (label === '') {
                                                label = items.rule_name + " (" + dataFld?.label;
                                            } else {
                                                label += ", " + dataFld.label;
                                            }
                                            valuesFld.push(items.field_names[i].field_name);
                                        }
                                    }
                                    label += ")";
                                    rule.label = rulesField ? label : "";
                                    rule.value = valuesFld;
                                    duplicateRules.push(rule);
                                    if (items.is_default == 1) {
                                        defaultValue = valuesFld;
                                    }
                                }
                            })
                            let _duplicate_info_obj = duplicate_info_obj;
                            _duplicate_info_obj.selected_duplicate_field = defaultValue;
                            setduplicateFieldMap(duplicateRules);
                            setduplicate_info_obj(_duplicate_info_obj);
                            
                        }
                    });
                }
            }
    }

    const getDestinationFieldArray = (sKey) => {
        let dest_field_arr = [];

        if (Array.isArray(destinationFieldArr)) {
            destinationFieldArr.forEach(f => {
                dest_field_arr.push(
                    <MenuItem value={f.field_id}
                        id={f.field_id + '_' + sKey}

                        key={f.field_id + '_' + sKey}
                        insetChildren={true}
                        checked={sourceDestFieldMap[sKey] !== null && sourceDestFieldMap[sKey] === f.field_id ? true : false}
                        style={styles.popoverMenuItem} >{f.label}</MenuItem>
                );
            })
        }

        return dest_field_arr;

    }

    const autoMapNames = () => {
        let data = importData;
        let sourceFieldsMap = data.data.data.source_fields;
        let impDataList = data.data.data.importData;
        let destinationFieldsMap = null;
        let _sourceDestFieldMap = {};
        impDataList.map((importData, index) => {
            if (index === 0) {
                destinationFieldsMap = importData.destinationFields !== null ? importData.destinationFields : null;
                var objKey = "-999";
                destinationFieldsMap[objKey] = constants.NOTE_OBJECTS;
            }
        });

        let destKey = '-1';
        if (sourceFieldsMap !== null) {
            Object.keys(sourceFieldsMap).map((sKey) => {
                destKey = '-1';
                let sourceFieldName = sourceFieldsMap[sKey] !== null ? sourceFieldsMap[sKey] : '';

                if (destinationFieldsMap !== null) {
                    Object.keys(destinationFieldsMap).map((dKey) => {
                        let destFieldName = destinationFieldsMap[dKey] !== null ? destinationFieldsMap[dKey] : '';
                        if (sourceFieldName.length > 0 && destFieldName.length > 0) {
                            if (sourceFieldName.toLowerCase() === destFieldName.toLowerCase()) {
                                destKey = dKey;
                            }
                        }

                    });
                }
                _sourceDestFieldMap[sKey] = destKey;
            });
            setsourceDestFieldMap({..._sourceDestFieldMap});
            setopenActionList(false);
        }

    }


    const getDuplicateCheckOption = (event, value) => {

        let obj = duplicate_info_obj;
        obj.duplicate_check_opt = value;

        setduplicate_info_obj(obj);

    }

    const doImport = () => {

        const { invalidFields, allFieldsValid } = Object.entries(fieldNamesValidation)
            .reduce((acc, [key, value]) => {
                const isInvalid = (typeof value === 'string' && value === '-1') ||
                    (typeof value === 'number' && value === -1);
                if (isInvalid) {
                    acc.invalidFields[key] = value;
                    acc.allFieldsValid = false;
                }
                return acc;
            }, { invalidFields: {}, allFieldsValid: true });

        const allFieldsDefault = Object.keys(invalidFields).length === Object.keys(fieldNamesValidation).length;

        if (importData !== null) {

            let data = importData;
            let file_name = data.data.data.file_name;
            let no_of_recs = data.data.data.no_of_records;
            let no_of_rows = data.data.data.no_of_rows;
            let impDataList = data.data.data.importData;
            let sourceFieldsMap = data.data.data.source_fields;
            let import_obj = {};

            impDataList.map((importData, index) => {
                if (index === 0) {
                    import_obj[object] = importData;
                } else {
                    import_obj[object][importData.tableName] = importData;
                }

            });

            if (no_of_recs === 0) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.NO_RECORD_TO_IMPORT, null);
                return false;

            } else {
                let import_flag = false;
                let noFieldMapped = true;
                let allFieldMapped = true;

                if (import_obj !== null && import_obj.hasOwnProperty(object)) {
                    let importObj = import_obj[object];
                    var objKey = "-999";
                    importObj.tableFieldsColumn[objKey] = constants.NOTES_NAME;
                    importObj.destinationFields[objKey] = constants.NOTE_OBJECTS;
                    let tableLabel = importObj.tableLabel;
                    let tableId = importObj.tableId;

                    let sourceDestinationFieldMap = getObjectParam(sourceDestFieldMap);
                    Object.keys(sourceDestinationFieldMap).map((key) => {
                        if (sourceDestFieldMap[key] !== "-1") {
                            noFieldMapped = false;
                            return;
                        }
                    });


                    if (Object.values(sourceDestinationFieldMap).indexOf("-1") > -1) {
                        allFieldMapped = false;
                    }

                    if (object === constants.ACCOUNTS_OBJECT) {
                        tableLabel = getLocalizedStrings().message.IMPORT.ACCOUNTS_AND_CONTACTS;
                    }

                    tableLabel = tableLabel;

                    if (noFieldMapped) {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.NO_MAPPING + tableLabel + getLocalizedStrings().label.COMMON.DOT, null);
                        return false;

                    } else if (!noFieldMapped && object.includes('custom') && !allFieldsDefault && !allFieldsValid) {
                        const invalidFieldLabels = Object.keys(invalidFields).map(key => {
                            switch(key) {
                                case 'tableName':
                                    return getLocalizedStrings().label.IMPORT.TABLE_NAME;
                                case 'linkWithValue':
                                    return getLocalizedStrings().label.IMPORT.LINK_WITH;
                                case 'linkUsingValue':
                                    return getLocalizedStrings().label.IMPORT.LINK_USING;
                                default:
                                    return key;
                            }
                        }).join(', ');
                        const message = getLocalizedStrings().message.IMPORT.REQUIRED_FIELDS(invalidFieldLabels);
                        sfDialogs.alert(
                            getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 
                           message, 
                            null
                        );
                        return;
                    } else {
                        import_flag = true;
                        let checkSource = checkSave(import_flag, importObj);
                        if (checkSource) {
                            if (allFieldMapped) {
                                saveImport(importObj, no_of_recs, sourceFieldsMap, tableId, file_name, import_flag, tableLabel, no_of_rows);
                            } else {
                                let msg = getLocalizedStrings().message.IMPORT.NOT_ALL_MAPPED;
                                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, ()=> saveImport(importObj, no_of_recs, sourceFieldsMap, tableId, file_name, import_flag, tableLabel, no_of_rows), null);

                            }
                        }
                    }
                }

            }
        }
    }



    const checkSave = (import_flag, importObj) => {
        if (import_flag && isSourceFieldSelected(importObj)) {
            return false;
        } else {
            return true;
        }
    }

    const isSourceFieldSelected = (importObj) => {
        let isSourceMapping = false;
        let source = importInfo.source;
        let isSourceSelected = false;
        let tableFieldsColumn = getObjectParam(importObj.tableFieldsColumn);

        let sourceDestFieldObj = getObjectParam(sourceDestFieldMap);

        Object.keys(sourceDestFieldObj).map((sKey) => {
            let dest_field_id = sourceDestFieldObj[sKey];

            if (dest_field_id !== "-1") {
                if (source !== "" && source !== "-1") {
                    let field_name = tableFieldsColumn[dest_field_id];

                    if (field_name === 'source') {
                        isSourceMapping = true;
                    }
                }
            }

        });

        if (source !== "" && source !== "-1" && source === "-111" && !isSourceMapping) {
            isSourceSelected = true;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.SOURCE_ALERT_MSG_1, null);
        } else if (source !== "-111" && isSourceMapping) {
            isSourceSelected = true;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.SOURCE_ALERT_MSG_2, null);
        }
        return isSourceSelected;
    }


    const saveImport = (importObj, no_of_recs, sourceFieldsMap, tableId, file_name, import_flag, tableLabel, no_of_rows) => {

        if (import_flag) {
            let msg = "";
            if (no_of_recs === 1) {
                msg = no_of_recs + ' ' + getLocalizedStrings().message.IMPORT.IMPORT_CONFIRM_MSG_1 + ' ' + tableLabel + '. ' + getLocalizedStrings().message.IMPORT.CONFIRM;
            } else {
                msg = no_of_recs + ' ' + getLocalizedStrings().message.IMPORT.IMPORT_CONFIRM_MSG + ' ' + tableLabel + '. ' + getLocalizedStrings().message.IMPORT.CONFIRM;
            }
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, ()=> handleSfDialogSaveImport(importObj, no_of_recs, sourceFieldsMap, tableId, file_name, import_flag, tableLabel, no_of_rows), null);
           

        } else {

        }

    }

    const handleSfDialogSaveImport = (importObj, no_of_recs, sourceFieldsMap, tableId, file_name, import_flag, tableLabel, no_of_rows) => {
        let mail_param = {};
        let import_status = {};
        let table_list = [];
        let params = {};
        params.total_records = no_of_recs;
        params.object_name = object;
        params.workflow_id = importInfo.workflowId;
        params.is_group_link = true;
        params.file_name = file_name;
        params.workflow_status = importInfo.status !== null ? importInfo.status : '';
        params.source = importInfo.source !== null ? importInfo.source : '';
        params.group_id = importInfo.groupId;
        params.audit_trail_table_ids = getStringParam(app.me.audit_trail_table_ids);
        params.assignRule = importInfo.assignRule !== null ? importInfo.assignRule : '0'
        if (object.includes('custom') && ![-1, '-1'].some(x => x === link_obj_details.linkUsingValue || x === link_obj_details.linkWithValue)) {
            const tableId = { accounts: 10, contacts: 9 };
            const { tableName, linkWithValue, linkUsingValue } = link_obj_details;
            params["linkWith"] = {
                linkTableId: tableId[tableName],
                linkTableFieldId: linkWithValue
            };
            params["linkUsing"] = linkUsingValue;
        };
        if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object.includes('custom')) {
            params.is_check_duplicate = duplicate_info_obj.is_duplicate_check;

            if (duplicate_info_obj.is_duplicate_check) {
                params.check_duplicate_action = duplicate_info_obj.duplicate_check_opt;
                let duplicateFldArr = [];

                if (duplicate_info_obj.selected_duplicate_field !== null && duplicate_info_obj.selected_duplicate_field.length > 0) {


                    let duplicateInfoObj = {};
                    duplicateInfoObj.check_duplicate_field_names = duplicate_info_obj.selected_duplicate_field;
                    params.duplicate_checking = duplicateInfoObj;
                    let sourceFields = sourceFieldMap;
                } else {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.SELECT_DUPLICATE_FIELD + getLocalizedStrings().label.COMMON.DOT, null);
                    return false;

                }
            }

        } else {
            let linkWith = {};
            linkWith.linkTableId = link_obj_details.linkTableId;
            linkWith.linkTableFieldId = link_obj_details.linkWithValue;

            params.linkWith = linkWith;
            params.linkUsing = link_obj_details.linkUsingValue;

            if (link_obj_details.linkWithValue > 0 && link_obj_details.linkUsingValue === -1) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.SELECT_LINK_USING + getLocalizedStrings().label.COMMON.DOT, null);
                return false;
            }

            if (link_obj_details.linkUsingValue > 0 && link_obj_details.linkWithValue === -1) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.SELECT_LINK_WITH + getLocalizedStrings().label.COMMON.DOT, null);
                return false;
            }

        }

        table_list.push(importObj.tableId);
        import_status.tableLabel = importObj.tableLabel;
        mail_param[importObj.tableId] = import_status;

        let destFieldMap = getObjectParam(importObj.destinationFields);
        let tableFieldsColumn = getObjectParam(importObj.tableFieldsColumn);
        let tableFieldsType = getObjectParam(importObj.tableFieldsType);
        let requiredFieldsColumn = getObjectParam(importObj.requiredFieldsColumn);

        let field_map_obj = null;
        let field_map_obj_arr = [];

        if (sourceDestFieldMap !== null && sourceDestFieldMap !== undefined) {

            Object.keys(sourceDestFieldMap).map((key) => {

                if (sourceDestFieldMap[key] !== "-1") {
                    field_map_obj = {};
                    let sKey = parseInt(key);
                    if (sourceDestFieldMap[key] === "-999") {
                        field_map_obj.source_field_index = sKey + 1;
                        field_map_obj.source_column_name = sourceFieldsMap[key];
                        field_map_obj.field_id = sourceDestFieldMap[key];
                        field_map_obj.field_name = tableFieldsColumn[sourceDestFieldMap[key]];
                        field_map_obj.field_label = destFieldMap[sourceDestFieldMap[key]];
                        field_map_obj.field_type = constants.NOTES_FIELD_TYPE;
                    } else {
                        field_map_obj.source_field_index = sKey + 1;
                        field_map_obj.source_column_name = sourceFieldsMap[key];
                        field_map_obj.field_id = sourceDestFieldMap[key];
                        field_map_obj.field_name = tableFieldsColumn[sourceDestFieldMap[key]];
                        field_map_obj.field_label = destFieldMap[sourceDestFieldMap[key]];
                        field_map_obj.field_type = tableFieldsType[sourceDestFieldMap[key]] + '';
                    }
                    field_map_obj_arr.push(field_map_obj);
                }


            });

            Object.keys(requiredFieldsColumn).map((key) => {

                field_map_obj = {};
                let sKey = parseInt(key);
                field_map_obj.source_field_index = -2;
                field_map_obj.source_column_name = requiredFieldsColumn[key];
                field_map_obj.field_id = key;
                field_map_obj.field_name = tableFieldsColumn[key];
                field_map_obj.field_label = requiredFieldsColumn[key];
                field_map_obj.field_type = tableFieldsType[key] + '';

                field_map_obj_arr.push(field_map_obj);


            });

            params[object + "_field_map"] = field_map_obj_arr;

            if (object === constants.ACCOUNTS_OBJECT && importObj.hasOwnProperty('j_contacts')) {
                import_status = {};

                table_list.push(importObj.j_contacts.tableId);
                import_status.tableLabel = importObj.j_contacts.tableLabel;
                mail_param[importObj.j_contacts.tableId] = import_status;

                let childDestFieldMap = getObjectParam(importObj.j_contacts.destinationFields);
                let childTableFieldsColumn = getObjectParam(importObj.j_contacts.tableFieldsColumn);
                let childTableFieldsType = getObjectParam(importObj.j_contacts.tableFieldsType);
                let childRequiredFieldsColumn = getObjectParam(importObj.j_contacts.requiredFieldsColumn);

                let field_map_childobj_arr = [];
                let leadContactMappingFields = importObj.j_contacts.leadContactMapping;
                let contactMappingFields = importObj.j_contacts.contactFieldMapping;
                Object.keys(sourceDestFieldMap).map((key) => {
                    if (sourceDestFieldMap[key] !== "-1") {
                        let contactFieldId = leadContactMappingFields[sourceDestFieldMap[key]];
                        if (contactFieldId !== null) {
                            field_map_obj = {};
                            let sKey = parseInt(key);
                            field_map_obj.source_field_index = sKey + 1;
                            field_map_obj.source_column_name = sourceFieldsMap[key];
                            field_map_obj.field_id = contactFieldId;
                            field_map_obj.field_name = childTableFieldsColumn[contactFieldId];
                            field_map_obj.field_label = childDestFieldMap[contactFieldId];
                            field_map_obj.field_type = childTableFieldsType[contactFieldId] + '';

                            field_map_childobj_arr.push(field_map_obj);
                        }
                    }

                });


                Object.keys(childRequiredFieldsColumn).map((key) => {

                    field_map_obj = {};
                    let sKey = parseInt(key);
                    field_map_obj.source_field_index = -2;
                    field_map_obj.source_column_name = childRequiredFieldsColumn[key];
                    field_map_obj.field_id = key;
                    field_map_obj.field_name = childTableFieldsColumn[key];
                    field_map_obj.field_label = childRequiredFieldsColumn[key];
                    field_map_obj.field_type = childTableFieldsType[key] + '';

                    field_map_childobj_arr.push(field_map_obj);


                });

                params["contacts_field_map"] = field_map_childobj_arr;
            }

        }

        let fetchSize = 10;
        let start = 1;
        let end = fetchSize;

        if (end >= no_of_recs) {
            end = no_of_recs;
        }
        end += start;
        params.start = start;
        params.end = end;

        setisImportClicked(true);
        settotalRecordCount(no_of_recs);
        saveImportRecord(params, no_of_rows, fetchSize, mail_param, table_list);
    }

    const saveImportRecord = (params, no_of_rows, fetchSize, mail_param, table_list) => {
        let promise = Promise.resolve(saveImportRecords(params));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.hasOwnProperty('data')) {
                        let importResponseInfo = {};
                        importResponseInfo.importingRecordCount = response.data.success;
                        importResponseInfo.failCount = response.data.fail;
                        importResponseInfo.duplicateCount = response.data.duplicate;
                        setisImportClicked(true);
                        setimport_response_obj(importResponseInfo);
                        params.success = response.data.success;
                        params.fail = response.data.fail;
                        params.duplicate = response.data.duplicate;
                        params.applyWorkflow = response.data.applyWorkflow;

                        let recordIds = [];
                        if (response.data.recordArr !== null && response.data.recordArr.length > 0) {
                            response.data.recordArr.forEach(function (id) {
                                recordIds.push(id);

                            });

                            recordArrForWorkFlow.push(recordIds);
                            let _totalApplyWFCount = changeLog.totalApplyWFCount + recordIds.length;
                            let temp = changeLog;
                            temp.totalApplyWFCount = _totalApplyWFCount;
                            setChangeLog({...temp})
                           // settotalApplyWFCount(_totalApplyWFCount);

                        }
                        let no_of_records = importData.data.data.no_of_records;
                        if ((params.end === no_of_records) || (params.end - 1 === no_of_records)) {
                            importResponseInfo.isImportDone = true;
                            if (response.data.applyWorkflow === 1) {
                                importResponseInfo.applyWorkflow = true;
                            }

                            let import_status = null;

                            for (var id = 0; id < table_list.length; id++) {

                                mail_param[table_list[id]].success = params.success;
                                mail_param[table_list[id]].fail = params.fail;

                                mail_param[table_list[id]].duplicate = params.duplicate;

                            }

                            mail_param.tableList = table_list;

                            sendMail(mail_param);
                            setisImportClicked(true);
                            setimport_response_obj(importResponseInfo);
                        } else {

                            let start = params.end + 1;
                            let end = params.end + fetchSize;

                            if (end >= no_of_records) {
                                end = no_of_records;
                            }

                            params.start = start;
                            params.end = end;

                            saveImportRecord(params, no_of_rows, fetchSize, mail_param, table_list);
                        }

                    }
                }
            });
        }
    }

    const sendMail = (params) => {
        let promise = Promise.resolve(sendMailForImportedRecords(params));
        if (isValidParam(promise)) {
            promise.then((response) => {

            });
        }

    }

    const openMapActionList = (event) => {
        setopenActionList(true);

        // setState({
        //     openActionList: true,
        //     anchorEl: event.currentTarget
        // });
    }

    const closeMapActionList = (event) => {
        setopenActionList(true);

        // setState({
        //     openActionList: false,
        //     anchorEl: event.currentTarget
        // });
    }

    const handleAction = (value) => {

        if (value === 'automap') {
            autoMapNames();
        } else {
            openSaveMappingArea(value);
        }
    }

    const applyWorkFlowFn = () => {
        var applyBtn = document.getElementById("applyWfButtnDiv");
        // if (applyBtn.style.display === "block") {
        //     applyBtn.style.display = "none";
        // }

        var applyingWFStatus = document.getElementById("applyingWorkflow");
        if (applyingWFStatus.style.display === "none") {
            applyingWFStatus.style.display = "block";
        }

        if (tableLabel === "Accounts and Contacts") {
            tableLabel = "Account";
        }

        let recordArr = [];
        let workflowId = 0;
        let workflowName = '';
        let statusName = '';
        let statusId = 0;

        if (
            importInfo !== null) {
            recordArr = recordArrForWorkFlow;
            workflowId =
                importInfo.workflowId;
            workflowName =
                importInfo.workflowName;
            statusName =
                importInfo.status;
            statusId =
                importInfo.statusId;

            let params = {};
            params.workflow_id = workflowId;
            params.stage_id = statusId;

            let promise = Promise.resolve(getWorkflowStagesTask(params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (response.hasOwnProperty('tasks')) {
                            let taskArr = response.tasks;
                            let taskIds = [];

                            for (let i = 0; i < taskArr.length; i++) {
                                var taskObj = taskArr[i];
                                taskIds.push(taskObj["id"]);
                            }

                            let workflowParam = {};
                            workflowParam.task_id = taskIds;
                            workflowParam.stage = statusName;
                            workflowParam.workflow_id = workflowId;
                            workflowParam.isImport = true;
                            workflowParam.is_create_next_task = true;
                            let recordArrLength = recordArr.length;

                            let index = 0;
                            if (recordArrLength > index) {
                                completeApplyWorkflow(workflowParam, recordArrLength, recordArr, index);
                            }
                        }
                    }
                });
            }
        }
    }

    const completeApplyWorkflow = (params, recordArrLength, recordArray, index) => {

        params.id = recordArray[index];
        params.current_workflow_name = "Empty";
        params.current_status = "Empty";
        params.changed_status =
            importInfo.status;
        params.change_workflow_name =
            importInfo.workflowName;
        let promise = Promise.resolve(applyWorkFlow(
            object, params));

        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.status === 0) {
                        index = index + 1;
                        if (recordArrLength > index) {
                            completeApplyWorkflow(params, recordArrLength, recordArray, index);
                        }

                        if (index === recordArrLength) {
                            setworkFlowApplied(true);
                        }

                    }
                }
            });
        }

    }

    const openSaveMappingArea = (callFrom) => {
        setopenActionList(false)

        if (checkFieldsMapOrNot() && callFrom === 'savemap') {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.NO_MAPPING_AVAILABLE, null);
            return false;
        } else {
            setopenSaveMappingComponent(true);
            setmappingAction(callFrom);

        }

    }

    const checkFieldsMapOrNot = () => {
        let sourceDestinationFieldMap = getObjectParam(sourceDestFieldMap);
        let noFieldMapped = true;
        Object.keys(sourceDestinationFieldMap).map((key) => {
            if (sourceDestFieldMap[key] !== "-1") {
                noFieldMapped = false;
                return noFieldMapped;
            }
        });
        return noFieldMapped;
    }

    const usePrevFieldMapping = (mapId) => {
      console.log("mappingFields",mapId)
      let mappingFieldsArr = [];
      let sourceDestFieldMapObj = {};
      let sourceFields = sourceFieldMap;
      let destFieldArr = getArrayParam(destinationFieldArr);
      let destKey = "-1";
      let params = {};
      params.id = mapId;

      let promise = Promise.resolve(getPreviousMappingFields(params));
      if (isValidParam(promise)) {
          promise.then((response) => {
              if (isValidParam(response)) {

                  if (response.hasOwnProperty('field_mapping_details')) {
                    mappingFieldsArr=response.field_mapping_details; 

                  }
                  if (mappingFieldsArr !== null && mappingFieldsArr.length > 0) {
                    let mappingSourceFieldName = '';
                    let mappingDestinationFieldId = '';
                    Object.keys(sourceFields).map((sKey) => {
                        destKey = "-1";
                        mappingFieldsArr.forEach(f => {
                            let sourceFieldName = sourceFields[sKey];
                            mappingSourceFieldName = f.t_source_field_name;
                            mappingDestinationFieldId = f.t_destination_field_id;
                            if (mappingDestinationFieldId === null || mappingDestinationFieldId === undefined || mappingDestinationFieldId === '') {
                                mappingDestinationFieldId = "-999"
                            }
                            if (sourceFieldName === mappingSourceFieldName) {
                                for (var i = 0; i < destFieldArr.length; i++) {
                                    let destFieldName = destFieldArr[i].label;
                                    let destFieldId = destFieldArr[i].field_id;
                                    if (destFieldId === mappingDestinationFieldId.toString()) {
                                        destKey = mappingDestinationFieldId.toString();
                                        break;
                                    }
                                }
                            }
                        });
                        sourceDestFieldMapObj[sKey] = destKey;
                    });
                    setsourceDestFieldMap({...sourceDestFieldMapObj});
                         hideImportMapping();

                }
          
              }

          });

      }

console.log("arraymappingFields", mappingFieldsArr)

   
       
      
      

    }

    const hideImportMapping = () => {
        setopenSaveMappingComponent(false);
    }



    const onChangeDuplicateField = (values) => {
        setDefault(values)
        let obj = duplicate_info_obj;
        obj.selected_duplicate_field = values;
        setduplicate_info_obj(obj);

    }


    if (isMounted) {
        let importDetails = getImportData();
        let mapFileColumnTxt = '', fileType = '';

        if (fileName !== null) {
            fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
            if (fileType === 'csv') {
                mapFileColumnTxt = getLocalizedStrings().label.IMPORT.MAP_CSV_COLUMNS;
            } else if (fileType === 'xls') {
                mapFileColumnTxt = getLocalizedStrings().label.IMPORT.MAP_XLS_COLUMNS;
            } else if (fileType === 'xlsx') {
                mapFileColumnTxt = getLocalizedStrings().label.IMPORT.MAP_XLSX_COLUMNS;
            }
        }

        var showDuplicateFieldOptions = [];
        if (duplicateFieldMap !== null) {
            duplicateFieldMap.map((item, Index) => {
                showDuplicateFieldOptions.push(<MenuItem value={item.value} key={Index} onClick={() => onChangeDuplicateField(item.value)} > {item.label}</MenuItem>);
            });
        }

        let disableDuplicateOpt = false;
        if (duplicate_info_obj.is_duplicate_check === false) {
            disableDuplicateOpt = true;
        }

        let importMappingProps = {
            sourceFieldMap: sourceFieldMap,
            sourceDestFieldMap: sourceDestFieldMap,
            destFieldArr: destinationFieldArr,
            tableFieldsColumn: tableFieldsColumn
        }

        let listViewHeaderButtonStyle = { ...styles.listViewHeaderButtonStyle };
        let sfFormFieldContainerStyle = { ...styles.sfFormFieldContainer };

        let link_with_arr = [];
        let link_using_arr = [];
        if (Array.isArray(linkWithValueArr)) {
            linkWithValueArr.forEach(f => {
                link_with_arr.push(
                    <MenuItem value={f.table_field_id}
                        id={'linkwith_' + f.table_field_id}

                        key={'linkwith_' + f.table_field_id}
                        insetChildren={true}
                        checked={link_obj_details.linkWithValue === f.table_field_id ? true : false}
                        style={styles.popoverMenuItem} > {f.label}</MenuItem>
                );
            })
        }

        if (Array.isArray(destinationFieldArr)) {
            destinationFieldArr.forEach(f => {
                link_using_arr.push(
                    <MenuItem value={f.field_id}
                        id={'linkusing_' + f.field_id}
                        key={'linkusing_' + f.field_id}
                        insetChildren={true}
                        checked={link_obj_details.linkUsingValue === f.field_id ? true : false}
                        style={styles.popoverMenuItem} > {f.label}</MenuItem>
                );
            })
        }

        return (
            <div style={{ paddingTop: '12px', paddingLeft: '20px', paddingRight: '20px' }}>

                {!isImportClicked &&
                    <div id="importMapDiv">
                        <div style={{ width: '100%', paddingBottom: '18px' }}>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr><td style={{ width: '80%' }}><div> <b>{mapFileColumnTxt}</b> </div> </td>
                                        <td style={{ textAlign: 'right' }}><div style={{ paddingRight: '5px', minWidth: '88px' }}>
                                            {!(noOfRecords > 10000) && <Button key='import' id='import' onClick={doImport} style={{ ...styles.primaryButton, marginLeft: '6px', marginRight: '0px' }} >{getLocalizedStrings().label.IMPORT.IMPORT}</Button>}</div></td>
                                        <td style={{ textAlign: 'right' }}>
                                            <div style={listViewHeaderButtonStyle}>
                                                <div onClick={openMapActionList} style={{ ...styles.togButton, backgroundColor: '#ffffff', height: '27px', width: '28px', marginRight: '-7px', border: '1px solid rgb(224,224,224)' }}>
                                                    <PopOver
                                                        id={'personalize'}
                                                        key={'personalize'}
                                                        name={'personalize'}
                                                        buttonEndIcon={"more_vert"}
                                                        buttonStyle={{ fontWeight: 'bold', paddingLeft: '0px', marginTop: '2px', marginLeft: '0px' }}
                                                        labelposition={"before"}
                                                        buttonLabel={getLocalizedStrings().label.COMMON.MORE}
                                                        options={mapList}
                                                        onclickAction={handleAction}
                                                        isShowIcon={true}
                                                        // anchorEl={anchorEl}
                                                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                                        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                                                        buttonLineOrigin={[18, 162]}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr><td colSpan="4">
                                        {openSaveMappingComponent &&
                                            <div style={{ marginBottom: "20" }}>
                                                <ImportSaveMapping object={object} importMappingProps={importMappingProps} callFrom={mappingAction} usePrevFieldMapping={usePrevFieldMapping} hideImportMapping={hideImportMapping} />
                                            </div>
                                        }</td></tr>
                                </tbody>
                            </table>

                        </div>
                        <div></div>
                        {importDetails}
                        <div></div>
                        {!(
                            object === constants.ACCOUNTS_OBJECT ||
                            object === constants.CONTACTS_OBJECT ||
                            object === constants.SALES_LEADS_OBJECT) &&
                            <div style={{ paddingTop: '3%' }}>
                                <table style={{ width: '52%' }}>
                                    <tbody>
                                        <tr><td style={{ width: '25%' }}><div>
                                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                                <InputLabel id="sf-import-mapping-field-simple-select-outlined-label" className="sf-import-mapping-field-simple">{getLocalizedStrings().label.IMPORT.TABLE_NAME}</InputLabel>
                                                <Select
                                                    key="tableName"
                                                    label={"Table"}
                                                    name='tableName'
                                                    value={link_obj_details.tableName}
                                                    onChange={changeLinkFieldValue}
                                                    style={{ height: '42px' }}
                                                    className={"sf-fields-bg"}
                                                >
                                                    <MenuItem value="-1"
                                                        id="-1"
                                                        key="-1"
                                                        insetChildren={true}
                                                        checked={link_obj_details.tableName === -1 ? true : false}
                                                        style={styles.popoverMenuItem} >{""}</MenuItem>
                                                    {
                                                        app.objectList.filter((object) => {
                                                            return object.name === constants.ACCOUNTS_OBJECT || object.name === constants.CONTACTS_OBJECT
                                                        }).map((object) => {
                                                            return <MenuItem value={object.name}
                                                                id={object.name}
                                                                key={object.name}
                                                                insetChildren={true}
                                                                checked={link_obj_details.tableName === object.name ? true : false}
                                                                style={styles.popoverMenuItem} >{object.label}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>

                                        </div></td>

                                        <td style={{ width: '25%' }}><div>
                                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                                <InputLabel id="sf-import-mapping-field-simple-select-outlined-label" className="sf-import-mapping-field-simple">{getLocalizedStrings().label.IMPORT.LINK_WITH}</InputLabel>
                                                <Select
                                                    key="linkWith"
                                                    label={getLocalizedStrings().label.IMPORT.LINK_WITH}
                                                    name='linkWithValue'
                                                    value={link_obj_details.linkWithValue}
                                                    onChange={changeLinkFieldValue}
                                                    style={{ height: '42px' }}
                                                    className={"sf-fields-bg"}
                                                >
                                                    <MenuItem value={'-1'}
                                                        id="-1"
                                                        key="-1"
                                                        insetChildren={true}
                                                        style={styles.popoverMenuItem} >{""}</MenuItem>

                                                    {
                                                        sfForm?.data[`${link_obj_details?.tableName}`]?.fields.sort((a,b)=>a.label.localeCompare(b.label)).filter(({label,t_tablefldid})=>Boolean(label.trim()) && t_tablefldid > 0 ).map(({ label,id,t_tablefldid}) => {
                                                            
                                                            return <MenuItem value={t_tablefldid}
                                                                id={id}
                                                                key={t_tablefldid}
                                                                insetChildren={true}
                                                                style={styles.popoverMenuItem} >{label}</MenuItem>
                                                            
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>

                                        </div></td>
                                            <td style={{ width: '2%' }}></td>
                                            <td style={{ width: '25%' }}><div>

                                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                                    <InputLabel id="sf-import-mapping-field-fromimportmappingfields-simple-select-outlined-label" className="sf-import-mapping-field-fromimportmappingfields">{getLocalizedStrings().label.IMPORT.LINK_USING}</InputLabel>
                                                    <Select
                                                        key="linkUsing"
                                                        name='linkUsingValue'
                                                        label={getLocalizedStrings().label.IMPORT.LINK_USING}
                                                        value={link_obj_details.linkUsingValue}
                                                        onChange={changeLinkFieldValue}
                                                        style={{ height: '42px' }}
                                                        className={"sf-fields-bg"}
                                                    >
                                                        <MenuItem
                                                            value="-1"
                                                            id="-1"
                                                            key="-1"
                                                            insetChildren={true}
                                                            checked={link_obj_details.linkUsingValue === -1 ? true : false}
                                                            style={styles.popoverMenuItem} >{""}</MenuItem>

                                                        {
                                                            link_using_arr
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div></td></tr>
                                    </tbody>
                                </table>

                            </div>
                        }
                        {(
                            object === constants.ACCOUNTS_OBJECT ||
                            object === constants.CONTACTS_OBJECT ||
                            object === constants.SALES_LEADS_OBJECT||
                            object.includes('custom')
                        ) &&
                            <div>
                                <div style={{ fontSize: '14px', lineHeight: '30px', marginTop: '2%' }}>
                                </div>
                                <div style={{ fontSize: '14px', lineHeight: '30px', marginTop: '2%' }}>
                                    <span className="import-gray-text">{getLocalizedStrings().label.IMPORT.CHECK_DUPLICATE_USING}:</span>
                                    <div style={{ width: '100%', marginTop: '5px' }} className='no-line'>


                                        {/* working here */}
                                        <FormControl variant="outlined" style={{ width: '100%' }}>
                                            <InputLabel id="sf-importmappingfields-from-importmappingfields-simple-select-outlined-label" className="sf-importmappingfields-from-importmappingfields">Duplicate Rules</InputLabel>
                                            <Select
                                                name='duplicateField'
                                                key='duplicateField'
                                                value={defaultVal == '' ? duplicate_info_obj.selected_duplicate_field : defaultVal}
                                                label={'Duplicate Rules'}
                                                labelId='sf-label-duplicateField'
                                                id='sf-duplicateField'
                                                style={{ height: '42px' }}
                                                className={"sf-fields-bg"}
                                            >
                                                {showDuplicateFieldOptions}
                                            </Select>
                                        </FormControl>

                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <FormControl component="fieldset">
                                            <RadioGroup name="duplicateRadioOption" onChange={getDuplicateCheckOption} disabled={disableDuplicateOpt} defaultValue="noAction" style={{ marginTop: '10px', fontSize: '15px' }} >
                                                <FormControlLabel value="noAction" control={<Radio color="default" disabled={disableDuplicateOpt} />} label={getLocalizedStrings().label.IMPORT.SKIP} />
                                                <FormControlLabel value="updateRecord" control={<Radio color="default" disabled={disableDuplicateOpt} />} label={getLocalizedStrings().label.IMPORT.MERGE} />
                                            </RadioGroup>
                                        </FormControl>

                                    </div>
                                </div>
                                {/* } */}
                            </div>}

                       

                        <div style={{ float: 'right', marginBottom: "10%" }}>
                            {!(noOfRecords > 10000) && <Button key='import' id='import' onClick={doImport} style={{ ...styles.primaryButton, marginLeft: '6px', marginRight: '0px' }}>{getLocalizedStrings().label.IMPORT.IMPORT}</Button>}
                        </div>

                    </div>

                }
                {isImportClicked &&
                    <div id="importedRecordDiv" >
                        <div id="inside_page_content">

                            {import_response_obj.isImportDone &&
                                <Alert  icon={false} severity="success">{getLocalizedStrings().message.IMPORT.IMPORT_SUCCESS}</Alert>
                            }
                            {!import_response_obj.isImportDone &&
                               <Alert icon={false} severity="warning">
                                    <strong>{getLocalizedStrings().message.IMPORT.WARNING}</strong> {getLocalizedStrings().message.IMPORT.WARNING_TEXT}
                                    </Alert>
                            }

                            <blockquote>
                                {!import_response_obj.isImportDone &&
                                    <p className="msg-import-progress">{getLocalizedStrings().message.IMPORT.IMPORTING_RECORDS} <img src="/asset/images/ajLoader2.gif" /></p>
                                }

                                <div><h5><span className="fa fa-check" style={{ color: 'green' }}></span>&nbsp;<span id="importingRecordCount">{import_response_obj.importingRecordCount}</span> of  <span id="totalRecordCount">{totalRecordCount}</span> {getLocalizedStrings().message.IMPORT.SUCCESS_IMPORT_COUNT}</h5></div>
                                <div><h5><span className="fa fa-times" style={{ color: 'red' }}></span>&nbsp;<span id="failCount">{import_response_obj.failCount}</span> {getLocalizedStrings().message.IMPORT.FAIL_IMPORT_COUNT}</h5></div>
                                <div><h5><span className="fa fa-exclamation-circle" style={{ color: 'orange' }}></span>&nbsp;{getLocalizedStrings().message.IMPORT.FOUND} <span id="duplicateCount">{import_response_obj.duplicateCount}</span> {getLocalizedStrings().message.IMPORT.DUPLICATES}</h5></div>
                            </blockquote>
                            {import_response_obj.applyWorkflow &&
                                importInfo.workflowId !== 0 &&
                                <div>
                                    {!workFlowApplied &&
                                        <div>
                                            <div id="applyWfButtnDiv" style={{ display: 'block' }}>
                                                <Button key='appyWorkflow' onClick={applyWorkFlowFn} style={{ ...styles.primaryButton,width:'', marginLeft: '6px', marginRight: '0px' }} >{getLocalizedStrings().label.IMPORT.APPLY_WORKFLOW}</Button> {getLocalizedStrings().message.IMPORT.APPLY_WORKFLOW_TEXT}
                                            </div>

                                            <blockquote id="applyingWorkflow" style={{ display: 'none' }}>
                                                <p className="msg-import-progress" id="applyingWorkflow" >{getLocalizedStrings().message.IMPORT.APPLYING_WORKFLOW} <img src="/asset/images/ajLoader2.gif" /></p>
                                            </blockquote>
                                        </div>
                                    }
                                    {workFlowApplied &&
                                        <div>
                                            <div id="applyingStatus" className="alert alert-success">{getLocalizedStrings().message.IMPORT.APPLIED_WORKFLOW}</div>
                                            <blockquote id="awBlockquote">
                                                <div id="applySuccess" ><h5><span className="glyphicon glyphicon-ok" style={{ color: 'green' }}></span>&nbsp; <span id="afterApplyStatus" >{getLocalizedStrings().message.IMPORT.APPLIED_WORKFLOW_TO}</span> <span id="applyingRecordCount"> {changeLog.totalApplyWFCount}  {tableLabel === 'Account' && tableLabel + 's'} {tableLabel !== 'Account' && tableLabel}</span> </h5></div>
                                            </blockquote>
                                        </div>
                                    }
                                </div>
                            }

                        </div>
                    </div>
                }
            </div>
        );
    } else {
        return null;
    }


}


ImportMappingFields.propTypes = {
    object: PropTypes.string,
    importInfo: PropTypes.object,
    importData: PropTypes.object
};

export default ImportMappingFields;