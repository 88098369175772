
import React, { useState, useEffect, useRef } from 'react';
import { isValidParam, getStringParam, getObjectParam, getIntParam, getBooleanParam, getArrayParam, getFloatParam } from '../../../services/helper/parameterVerifier';
import { setHeader } from '../../../services/actions/headerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import { constants } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import Button from '@mui/material/Button';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import ShowCircularProgress from '../components/circularProgress';
import { updateRoyaltyReportData } from '../../../services/actions/royaltyReportActions';
import { Menu, Select, MenuItem, InputLabel } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";


    const RoyaltySetup = ({linkRecord, object, id,updateLinkInfo}) => {
        const dispatch = useDispatch();
        const app = useSelector((state) => state.app);
        const sfForm = useSelector((state) => state.sfForm);
        const report = useSelector((state) => state.report);
        const royaltyReport = useSelector((state) => state.royaltyReport.royaltyReportData);

        
        const [isMounted, setIsMounted] = useState(false);
        const [royaltyCalOpt, setRoyaltyCalOpt] = useState('fixed');
        const [royaltyPercent, setRoyaltyPercent] = useState(5);
        const [use, setUse] = useState('All');
        const [calculate, setCalculate] = useState('Monthly');
        const [isShowWeekType, setIsShowWeekType] = useState(false);
        const [weekType, setWeekType] = useState('Mon - Sun');
        const [field, setfield] = useState(null);

        const [marketingFeeLabel, setMarketingFeeLabel] = useState(getLocalizedStrings().label.ROYALTY_REPORT.MARKETING_FEE);
        const [marketingFeeType, setMarketingFeeType] = useState('fixed');
        const [marketingFee, setMarketingFee] = useState(0);
        const [techFeeLabel, setTechFeeLabel] = useState(getLocalizedStrings().label.ROYALTY_REPORT.TECH_FEE);
        const [techFeeType, setTechFeeType] = useState('fixed');
        const [techFee, setTechFee] = useState(0);
        const [isShowMarketingTextBox, setIsShowMarketingTextBox] = useState(false);
        const [isShowTechTextBox, setisShowTechTextBox] = useState(false);
        const [setupId, setId] = useState(0);
        
        useEffect(() => {
           getReportConfig();
        }, []);
    
      
    
   const getReportConfig = () => {
        try {
            let tempisShowWeekType = isShowWeekType;
            let tempWeekType = weekType;  
            let tempmarketingFeeLabel=marketingFeeLabel;
            let tempmarketingFeeType= marketingFeeType;
            let tempmarketingFee= marketingFee;
            let temptechFeeLabel=techFeeLabel;
            let temptechFeeType= techFeeType;
            let temptechFee= techFee;
            let promise = Promise.resolve(HTTPClient.get(endPoints.ROYALTY_REPORT.GET_REPORT_CONFIG));
            promise.then((response) => {
                setIsMounted(true);
                if (isValidParam(response)) {
                    let configObj = getObjectParam(response);
                    setId(configObj.id || setupId);
                    setRoyaltyPercent(configObj.royalty_percent || royaltyPercent);
                    setUse(configObj.show || use);
                    setRoyaltyCalOpt(configObj.royalty_cal || royaltyCalOpt);
                    setCalculate(configObj.calculation_type);
                    tempisShowWeekType = configObj.calculation_type === 'Weekly' ? true : false;
                    tempWeekType = configObj.week_type !== null && configObj.week_type !== "" ? configObj.week_type : weekType;
                    tempmarketingFeeLabel = configObj.marketing_fee_label !== null && configObj.marketing_fee_label !== "" && configObj.marketing_fee_label!==undefined ? configObj.marketing_fee_label : getLocalizedStrings().label.ROYALTY_REPORT.MARKETING_FEE;
                    tempmarketingFeeType = configObj.marketing_fee_type !== null && configObj.marketing_fee_type !== "" ? configObj.marketing_fee_type : "fixed";
                    tempmarketingFee = configObj.marketing_fee !== null && configObj.marketing_fee !== "" ? configObj.marketing_fee : 0;
                    temptechFeeLabel = configObj.tech_fee_label !== null && configObj.tech_fee_label !== "" && configObj.tech_fee_label!==undefined ? configObj.tech_fee_label : getLocalizedStrings().label.ROYALTY_REPORT.TECH_FEE;
                    temptechFeeType = configObj.tech_fee_type !== null && configObj.tech_fee_type !== "" ? configObj.tech_fee_type : "fixed";
                    temptechFee = configObj.tech_fee !== null && configObj.tech_fee !== "" ? configObj.tech_fee : 0;
                    setMarketingFeeLabel(tempmarketingFeeLabel);
                    setMarketingFeeType(tempmarketingFeeType);
                    setMarketingFee(tempmarketingFee);
                    setTechFeeLabel(temptechFeeLabel);
                    setTechFeeType(temptechFeeType);
                    setTechFee(temptechFee);
                }

            });
        } catch (error) {
            console.error("Error in 'royaltySetup.js -> getReportConfig()'" + error);
        }
    }

   const onSave = () => {
        try {
            let rayaltyPer = royaltyPercent;

            let errMsg = "";
            if (rayaltyPer === '' || rayaltyPer === 0) {
                errMsg = getLocalizedStrings().message.ROYALTY_REPORT.BLANK_ROYALTY_PERCENT;
                dispatch(showCustomSnackBar(errMsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop))
            }
            else if (techFee === '' || marketingFee === '') {
                let label = "";
                if (techFee === '') {
                    label = techFeeLabel + " ";
                }
                if (marketingFee === '') {
                    label = marketingFeeLabel + " ";
                }
                errMsg = label + getLocalizedStrings().message.COMMON.VALIDATE_BLANK;
                dispatch(showCustomSnackBar(errMsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
            else {
                let reportParams = {};
                reportParams.royalty_cal = royaltyCalOpt;
                reportParams.royalty_percent = royaltyPercent;
                if (reportParams.royalty_cal === 'variable') {
                    reportParams.royalty_percent = 5;
                }
                reportParams.show = use;
                reportParams.item_type = 'Royalties Report';
                reportParams.parameter_id = -99;
                reportParams.calculation_type = calculate;
                reportParams.week_type = weekType;
                reportParams.marketing_fee_type =marketingFeeType;
                reportParams.marketing_fee =marketingFee;
                reportParams.tech_fee_label =techFeeLabel;
                reportParams.tech_fee_type =techFeeType;
                reportParams.tech_fee =techFee;
                reportParams.marketing_fee_label = marketingFeeLabel;
                let promise = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_REPORT.SAVE_REPORT_SETUP, reportParams));
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (response.status === 0) {
                            let objParams = getObjectParam(royaltyReport);
                            objParams.configParams = reportParams;
                            dispatch(updateRoyaltyReportData(objParams));
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.ROYALTY_REPORT.SETUP_SAVE_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop))

                        }
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'RoyaltyReportSetup.js -> saveReport()'" + error);
        }
    }

   const onChangeValue = (fieldVal, value) => {
    if(fieldVal === "use"){
        setUse(value)
    }
    else if(fieldVal === "calculate"){
        setCalculate(value)

    }
    else if(fieldVal === "weekType"){
        setWeekType(value)
    }
    else if(fieldVal ==='marketingFeeLabel' ){
        setMarketingFeeLabel(value)
    } 
    else if(fieldVal ==="techFee"){
        setTechFee(value)
    } 
    else if(fieldVal === "techFeeType"){
        setTechFeeType(value)
    } 
    else if(fieldVal === "techFeeLabel"){
        setTechFeeLabel(value)
    } 
    else if(fieldVal === "marketingFee"){
        setMarketingFee(value)
    }
    else if(fieldVal === "marketingFeeType"){
        setMarketingFeeType(value)
    }
        if (fieldVal === "calculate" && value === 'Weekly') {
        setIsShowWeekType(true);
        
        } 
            else if (field === "calculate" && value !== 'Weekly') {
            setIsShowWeekType(false);
        }
    }

   const onChangeTextField = (field, event, value) => {
        var re = /^\d*\.?\d*$/;
        if (isValidParam(value) && (value === '' || re.test(value))) {
            setfield(value);
        }
    }
   const onClickChangeValue = (field, value) => {
        try {
            if (marketingFeeLabel !== "" && techFeeLabel !== "") {
                if (field === "isShowMarketingTextBox") {
                    setIsShowMarketingTextBox(value);
                }
                if (field === "isShowTechTextBox") {
                    setisShowTechTextBox(value);
                }
            } else if (marketingFeeLabel === "" || techFeeLabel === "") {
                let errMsg = "";
                let label = "";
                if (marketingFeeLabel === '') {
                    label = getLocalizedStrings().label.ROYALTY_REPORT.MARKETING_FEE + " ";
                }
                if (techFeeLabel === '') {
                    label = getLocalizedStrings().label.ROYALTY_REPORT.TECH_FEE + " ";
                }
                errMsg = label + getLocalizedStrings().message.COMMON.VALIDATE_BLANK;
                dispatch(showCustomSnackBar(errMsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        } catch (error) {
            console.error("Error in 'RoyaltyReportSetup.js -> onClickChangeValue()'" + error);
        }
    }
    
        if (isMounted) {
            let royaltyCalMenuItems = [];
            royaltyCalMenuItems.push(<MenuItem key='fixed' value='fixed'>{getLocalizedStrings().label.ROYALTY_REPORT.FIXED}</MenuItem>);
            royaltyCalMenuItems.push(<MenuItem key='variable' value='variable'>{getLocalizedStrings().label.ROYALTY_REPORT.VARIABLE}</MenuItem>);

            let useMenuItems = [];
            useMenuItems.push(<MenuItem key='total-invoice' value='All'>{getLocalizedStrings().label.ROYALTY_REPORT.TOTAL_INVOICES}</MenuItem>);
            useMenuItems.push(<MenuItem key='total-payment' value='Paid'>{getLocalizedStrings().label.ROYALTY_REPORT.TOTAL_PAYMENTS} </MenuItem>);
            // useMenuItems.push(<MenuItem key='total-reported-sales' value='ReportedSales'>{getLocalizedStrings().label.ROYALTY_REPORT.TOTAL_Reported_Sales} </MenuItem>);
            useMenuItems.push(<MenuItem key='total-reported-sales-franchisee' value='ReportedSales-Franchisee'>{getLocalizedStrings().label.ROYALTY_REPORT.Reported_Sales_Franchisee} </MenuItem>);
            useMenuItems.push(<MenuItem key='total-reported-sales-franchisor' value='ReportedSales-Franchisor'>{getLocalizedStrings().label.ROYALTY_REPORT.Reported_Sales_Franchisor} </MenuItem>);
            useMenuItems.push(<MenuItem key='total-reported-sales-thirdparty' value='ReportedSales-ThirdParty'>{getLocalizedStrings().label.ROYALTY_REPORT.Reported_Sales_ThirdParty} </MenuItem>);

            let calculateMenuItems = [];
            calculateMenuItems.push(<MenuItem key='Monthly' value='Monthly'>{getLocalizedStrings().label.ROYALTY_REPORT.MONTHLY}</MenuItem>);
            calculateMenuItems.push(<MenuItem key='Weekly' value='Weekly'>{getLocalizedStrings().label.ROYALTY_REPORT.WEEKLY}</MenuItem>);
            let weekTypeMenuItems = [];
            weekTypeMenuItems.push(<MenuItem key='workingWeekDays' value='Mon - Fri'>{getLocalizedStrings().label.ROYALTY_REPORT.MON_TO_FRI}</MenuItem>);
            weekTypeMenuItems.push(<MenuItem key='allWeekDays' value='Mon - Sun'>{getLocalizedStrings().label.ROYALTY_REPORT.MON_TO_SUN}</MenuItem>);

            let feesMenuItems = [];
            feesMenuItems.push(<MenuItem key='fixed' value='fixed'>{getLocalizedStrings().label.ROYALTY_REPORT.FIXED_VALUE}</MenuItem>);
            feesMenuItems.push(<MenuItem key='percent' value='percent'>{getLocalizedStrings().label.ROYALTY_REPORT.PERCENT}</MenuItem>);
         
            return (
                <div style={{ paddingLeft: '26px' }}>
                    <div className='sf-RoyaltySetup-Container'>
                        <div className='sf-RoyaltySetup-Header' style={{ ...styles.row }}>
                            <div style={{ ...styles.sf_12 }}>
                                <div style={{ fontSize: '24px' }}> {getLocalizedStrings().label.COMMON.ROYALTY_SETUP} </div>
                            </div>
                        </div>
                        <div className='sf-RoyaltySetup-FieldArea' style={{ marginTop: '25px', height: isShowWeekType ? ' 50%' : '35%' }}>
                            <div style={{ ...styles.sf_12, paddingTop: '10px', paddingLeft: '12px' }}>
                                <div style={{ ...styles.sf_4 }}>
                                    <FormControl margin="dense" size='small' variant="outlined" style={{ width: '100%' }}>
                                        <InputLabel id="color-code-select-outlined-label"> {getLocalizedStrings().label.ROYALTY_REPORT.USE} </InputLabel>
                                        <Select
                                            key={'sf-auto-complete-status'}
                                            label={getLocalizedStrings().label.ROYALTY_REPORT.USE}
                                            onChange={(e)=>{onChangeValue( 'use',e.target.value)}}
                                            value={use}
                                            id={'use'}
                                            name='use'
                                            style={{ height: '41px' }}
                                            className={"sf-fields-bg"}
                                        >
                                            {useMenuItems}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div style={{ ...styles.sf_12, paddingTop: '10px', paddingLeft: '12px' }}>
                                <div style={{ ...styles.sf_4 }}>
                                    <FormControl margin="dense" size='small' variant="outlined" style={{ width: '100%' }}>
                                        <InputLabel id="calculate-select-outlined-label"> {getLocalizedStrings().label.ROYALTY_REPORT.CALCULATE} </InputLabel>
                                        <Select
                                            key={'sf-auto-complete-status'}
                                            label={getLocalizedStrings().label.ROYALTY_REPORT.CALCULATE}
                                            onChange={(event)=>onChangeValue('calculate',event.target.value)}
                                            value={calculate}
                                            id={'calculate'}
                                            name='calculate'
                                            style={{ height: '41px' }}
                                            className={"sf-fields-bg"}
                                            disabled={setupId > 0 ? true : false}
                                        >
                                            {calculateMenuItems}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {/* <div style={{ ...styles.sf_12, paddingTop: '10px', paddingLeft: '12px' }}>
                                <div style={{ ...styles.sf_4 }}>
                                    {isShowWeekType &&
                                        <FormControl margin="dense" size='small' variant="outlined" style={{ width: '100%' }}>
                                            <InputLabel id="weekType-select-outlined-label"> {getLocalizedStrings().label.ROYALTY_REPORT.WEEK_TYPE} </InputLabel>
                                            <Select
                                                key={'sf-auto-complete-status'}
                                                label={getLocalizedStrings().label.ROYALTY_REPORT.WEEK_TYPE}
                                                onChange={(e)=>onChangeValue('weekType',e.target.value)}
                                                value={weekType}
                                                id={'weekType'}
                                                name='weekType'
                                                style={{ height: '41px' }}
                                                className={"sf-fields-bg"}
                                            >
                                                {weekTypeMenuItems}
                                            </Select>
                                        </FormControl>
                                    }
                                </div>
                            </div> */}
                            <div style={{ ...styles.sf_12, paddingTop: '10px', paddingLeft: '12px' }}>
                                <div style={{ ...styles.sf_4 }}>
                                    {!isShowMarketingTextBox &&
                                        <FormControl margin="dense" size='small' variant="outlined" style={{ width: '100%' }}>
                                            <div>{marketingFeeLabel}
                                                <span style={{ marginLeft: "5%" }} onClick={(e) => { e.preventDefault(); onClickChangeValue('isShowMarketingTextBox', true); }}>
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                        </FormControl>
                                    }
                                    {isShowMarketingTextBox &&
                                        <TextField
                                            variant="outlined"
                                            name="marketingFeeLabel"
                                            id="marketingFeeLabel"
                                            value={marketingFeeLabel}
                                            disabled={false}
                                            autoFocus
                                            inputprops={{ maxLength: 128 }}
                                            className={"sf-fields-bg"}
                                            autoComplete="new-password"
                                            margin="dense"
                                            size='small'
                                            onChange={(e) => onChangeValue('marketingFeeLabel', e.target.value)}
                                            onBlur={(e) => { e.preventDefault(); onClickChangeValue('isShowMarketingTextBox', false); }}
                                            style={{ width: '100%' }}
                                        />
                                    }
                                </div>
                                <div style={{ ...styles.sf_4 }}>
                                    <FormControl margin="dense" size='small' variant="outlined" style={{ width: '45%' }}>
                                        {/* <InputLabel id="calculate-select-outlined-label"> {this.state.marketingFeeLabel} </InputLabel> */}
                                        <Select
                                            key={'sf-auto-complete-status'}
                                            // label={this.state.marketingFeeLabel}
                                            onChange={(e) => onChangeValue('marketingFeeType', e.target.value)}
                                            value={marketingFeeType}
                                            id={'marketingFeeType'}
                                            name='marketingFeeType'
                                            style={{ height: '41px' }}
                                            className={"sf-fields-bg"}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            {feesMenuItems}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        variant="outlined"
                                        name="marketingFee"
                                        id="marketingFee"
                                        value={marketingFee}
                                        disabled={false}
                                        autoFocus
                                        inputprops={{ maxLength: 128 }}
                                        className={"sf-fields-bg"}
                                        autoComplete="new-password"
                                        margin="dense"
                                        size='small'
                                        onChange={(e) => onChangeValue('marketingFee', e.target.value)}
                                        style={{ width: "45%", position: "relative", left: "35px" }}
                                    />
                                </div>
                            </div>
                            <div style={{ ...styles.sf_12, paddingTop: '10px', paddingLeft: '12px' }}>
                                <div style={{ ...styles.sf_4 }}>
                                    {!isShowTechTextBox &&
                                        <FormControl margin="dense" variant="outlined" style={{ width: '100%' }}>
                                            <div>{techFeeLabel}
                                                <span style={{ marginLeft: "5%" }} onClick={(e) => { e.preventDefault(); onClickChangeValue('isShowTechTextBox', true); }}>
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                        </FormControl>
                                    }
                            {isShowTechTextBox &&
                                        <TextField
                                            variant="outlined"
                                            name="techFeeLabel"
                                            id="techFeeLabel"
                                            value={techFeeLabel}
                                            disabled={false}
                                            autoFocus
                                            inputprops={{ maxLength: 128 }}
                                            className={"sf-fields-bg"}
                                            autoComplete="new-password"
                                            margin="dense"
                                            size='small'
                                            onChange={(e) =>onChangeValue('techFeeLabel', e.target.value)}
                                            onBlur={(e) => { e.preventDefault(); onClickChangeValue('isShowTechTextBox', false); }}
                                            style={{ width: '100%' }}
                                        />
                                    }
                                </div>
                                <div style={{ ...styles.sf_4 }}>
                                    <FormControl margin="dense" size='small' variant="outlined" style={{ width: '45%' }}>
                                        {/* <InputLabel id="calculate-select-outlined-label"> {this.state.techFeeLabel} </InputLabel> */}
                                        <Select
                                            key={'sf-auto-complete-status'}
                                            // label={this.state.techFeeLabel}
                                            onChange={(e) => onChangeValue('techFeeType', e.target.value)}
                                            value={techFeeType}
                                            id={'techFeeType'}
                                            name='techFeeType'
                                            style={{ height: '41px' }}
                                            className={"sf-fields-bg"}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            {feesMenuItems}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        variant="outlined"
                                        name="techFee"
                                        id="techFee"
                                        value={techFee}
                                        disabled={false}
                                        autoFocus
                                        inputprops={{ maxLength: 128 }}
                                        className={"sf-fields-bg"}
                                        autoComplete="new-password"
                                        margin="dense"
                                        size='small'
                                        onChange={(e) => onChangeValue('techFee', e.target.value)}
                                        style={{ width: "45%", position: "relative", left: "35px" }}
                                    />
                                </div>
                            </div>
                            <div className='sf-RoyaltySetup-Button' style={{ ...styles.row }}>
                                <div style={{ width: '34.6%', marginTop: '5%' }}>
                                    <div style={{ float: 'right' }}>
                                        <Button
                                            key='save'
                                            onClick={onSave}
                                            style={{ ...styles.primaryButton, verticalAlign: 'top' }}
                                        >
                                            {getLocalizedStrings().label.COMMON.SAVE}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )
        } else {
            let contentHeight = window.innerHeight - 240;
            let top = (contentHeight - 10) / 2
            return (
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            );
        }
    
}

export default RoyaltySetup