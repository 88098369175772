
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Step, Stepper, StepLabel } from '@mui/material';
import Button from '@mui/material/Button';
import { getArrayParam, isInteger, isFloat, getStringParam, getIntParam,isValidParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import Filter from '../components/filter';
import FilterSetup from '../components/filterSetup';
import { constants, OBJECT_TABLEID_MAP, TABLEID_OBJECT_MAP } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { styles } from '../../../services/constants/styles';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { makeTiteleCase, phoneFormat } from '../../../services/helper/utils';
import { addFilterToReduxStore } from '../../../services/helper/common';
import { getCacheFromFieldsWithPromise, getFieldListValuesPromise } from '../../../services/actions/sfFormActions';
import * as sfDialogs from '../components/sfDialogs';
import moment from 'moment';
import ShowCircularProgress from '../components/circularProgress';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { TYPE_LIST_VIEW, addTab, TYPE_COLUMN_VIEW } from '../../../services/helper/sfTabManager';
import { setMounted } from '../../../services/actions/listViewAcions';
import { saveRecentFilter, getImageNameByObject, getRecentFilters } from '../../../services/helper/common';
import { setHeader } from '../../../services/actions/headerActions';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { refreshColumnView } from '../../../services/actions/listsActions';
import { refreshListView } from "../../../services/actions/listViewAcions";
import _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";

const FilterStepper = ({ data, closeDrawer }) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const sfForm = useSelector((state) => state.sfForm);
    const filters = useSelector((state) => state.filters);
    const sfTab = useSelector((state) => state.tab);

   
    const filterRef = useRef();
    const filterset = useRef();

    const [objectName, setObjectName] = useState(null);
    const [steps, setSteps] = useState(null);
    const [stepIndex, setStepIndex] = useState(0);
    const [finished, setFinished] = useState(false);
    const [filter, setFilter] = useState({});
    const [filterSetup, setFilterSetup] = useState({});
    const [filterFieldData, setFilterFieldData] = useState({});
    const [fromFieldData, setFromFieldData] = useState({});
    const [isShowLoader, setIsShowLoader] = useState(false);
    const [openMode, setOpenMode] = useState('');
    const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(true);
    const [isSaved, setIsSaved] = useState(false);
    const [isDuplicateError, setIsDuplicateError] = useState(false);
    const [isAssociateObjExist, setIsAssociateObjExist] = useState(false);
    const [associateObjects, setAssociateObjects] = useState([]);
    const [associatedata, setAssociatedata] = useState(null);
    const [associateFieldData, setAssociateFieldData] = useState({});
    const [associateObj, setAssociateObj] = useState('');
    const [selectedassociateObj, setSelectedassociateObj] = useState({});
    const [associateObjId, setAssociateObjId] = useState(0);
    const [isGroupExits, setIsGroupExits] = useState(false);
    const [previousFilter, setpreviousFilter] = useState(null);
    const [previousFilterSetup, setPreviousFilterSetup] = useState(null);
    const [selectedAssoObjFromChild, setSelectedAssoObjFromChild] = useState("");
    const [defaultAssObj, setDefaultAssObj] = useState("");
    const [childRows, setChildRows]=useState([])

 useEffect(()=>{
    if(selectedAssoObjFromChild !==undefined && selectedAssoObjFromChild != ''){
        let linkChildArrList = getObjectParam(app.linkChildInfo);
        let tableId = getIntParam(OBJECT_TABLEID_MAP[data.table]);
        let arrChildObjectInfo = [];
        let associateObjects = [];
        if (isValidParam(linkChildArrList)) {
            arrChildObjectInfo = getArrayParam(linkChildArrList[tableId]);
            arrChildObjectInfo = arrChildObjectInfo.filter((f)=>{
                return f.objectName == "accounts" || f.objectName == "opportunities" || f.objectName == "cases" || f.objectName == "customobjectone" 
                || f.objectName == "customobjecttwo" || f.objectName == "customobjectthree" || f.objectName == "customobjectfour";
            })   
            arrChildObjectInfo.map(function (obj) {
                    associateObjects.push({
                        label: obj.title,
                        value: obj.objectName
                    });
            });
        let _data = {};
        _data.object = 'filter';
        _data.id = data.id;
        _data.table = selectedAssoObjFromChild;
        getAssociateFromFieldData(_data, associateObjects, "callFromAssociateObj");
    }}
 },[selectedAssoObjFromChild])
    
    const processConditionValue = (condition, fileldProps) => {
        let fieldType = fileldProps.field_type;
        let operator = condition.t_operator;
        if (fieldType === constants.FIELD_TYPE_DATE || fieldType === constants.FIELD_TYPE_DATE_TIME) {
            condition.t_value = condition.t_value.replace(/'/g, '');
        } else if (!(fieldType === constants.FIELD_TYPE_INTEGER || fieldType === constants.FIELD_TYPE_FLOAT || fieldType === constants.FIELD_TYPE_DATE_TIME || fieldType === constants.FIELD_TYPE_DATE)) {
            if (operator !== "IS_NULL" && operator !== "IS_NOT_NULL") {
                if (condition.t_value !== "NOT NULL" && condition.t_value !== "NULL") {
                    condition.t_value = condition.t_value.substr(1).slice(0, -1);
                }
            }
        } else if (condition.t_value.charAt(0) === "'" && condition.t_value.charAt(condition.t_value.length - 1) === "'") {
            condition.t_value = condition.t_value.substr(1).slice(0, -1);
        }

        if (operator === "LIKE" || operator === "NOT_LIKE") {
            if (condition.t_value.charAt(0) === "%" && condition.t_value.charAt(condition.t_value.length - 1) === "%") {
                condition.t_value = condition.t_value.substr(1).slice(0, -1);
            }
        }

        // Not logical operator 
        if (condition.t_logicalop.indexOf("NOT") > -1) {
            if (operator === "=") {
                condition.t_operator = "NOT_EQUAL";
            } else if (operator === "LIKE" || operator === "BETWEEN") {
                condition.t_operator = "NOT_LIKE";
            }
            condition.t_logicalop = condition.t_logicalop.replace("NOT", "").trim();
        } else if (operator === "BETWEEN") {
            condition.t_operator = "LIKE";
        }
        if (operator === "IS" && condition.t_value === "NULL") {
            condition.t_operator = "IS_NULL";
            condition.t_value = "";
            condition.conditionFieldProps.isShowConditionValue = false;
        }
        if (operator === "IS" && condition.t_value === "NOT NULL") {
            condition.t_operator = "IS_NOT_NULL";
            condition.t_value = "";
            condition.conditionFieldProps.isShowConditionValue = false;
        }

        return condition;
    }

    const loadAssociateFromFieldData = (associateObj, filterDetails, filterId, associateObjId,_fromFieldData) => {
        let _associatefromFieldData = sfForm.data[associateObj];
        if (isValidParam(_associatefromFieldData)) {
            _associatefromFieldData = JSON.parse(JSON.stringify(_associatefromFieldData));
            setAssociateFieldData({..._associatefromFieldData});
            processFilterData(filterDetails, filterId, associateObjId, associateObj,_fromFieldData,_associatefromFieldData);
        } else {
            var promise = getCacheFromFieldsWithPromise(associateObj);
            promise.then((response) => {

                loadAssociateFromFieldData(associateObj, filterDetails, filterId, associateObjId,_fromFieldData);
            });
        }
    }
    const loadFilterData = (filterId,_fromFieldData) => {
        var response = Promise.resolve(HTTPClient.get(endPoints.FILTER.EDIT + filterId, {}));
        response.then((response) => {
           let assoObj = TABLEID_OBJECT_MAP[response.t_associateobjectid];
            setDefaultAssObj(assoObj);
            
            let group = [];
            group = response.sql_array.filter((f) => { return f.t_fldid == -9999 });
            if (group.length > 0 && group[0].t_fldid == -9999) {
                setIsGroupExits(true);
            }
            else {
                setIsGroupExits(false);
            }
            let associateObj;
            let associateObjId = response.t_associateobjectid;
            if (associateObjId !== null && associateObjId > 0) {
                associateObj = TABLEID_OBJECT_MAP[associateObjId];
                loadAssociateFromFieldData(associateObj, response, filterId, associateObjId,_fromFieldData);
            } else {
                processFilterData(response, filterId, associateObjId, associateObj,_fromFieldData,{});
            }

        });

    }
    const processFilterData = (response, filterId, associateObjId, associateObj,_fromFieldData,_associatefromFieldData) => {
        let filterObject = {};
        filterObject.id = filterId;
        filterObject.t_name = response.t_name;
        filterObject.t_formid = response.t_formid;
        let sqlArray = response.sql_array;
        let fieldContainer = {};
        let filterIdArray = [];
        let i = 0;

        for (i = 0; i < sqlArray.length; i++) {
            let savedConditon = sqlArray[i];
            let tableFiledId = savedConditon.t_fldid;
            if (fieldContainer.hasOwnProperty(tableFiledId)) {
                let field = fieldContainer[tableFiledId];
                let condition = {};
                condition.t_logicalop = savedConditon.t_logicalop;
                condition.t_operator = savedConditon.t_operator;
                condition.t_value = savedConditon.t_value;
                condition.t_tableid = savedConditon.t_tableid;
                condition.conditionFieldProps = {
                    isOpenPopover: false,
                    popoverTarget: null,
                    isShowConditionValue: true
                }
                let conditionArray = field.conditions;
                let selectFieldProps = field.filedProps;
                condition = processConditionValue(condition, selectFieldProps);
                conditionArray.push(condition);
                fieldContainer[tableFiledId] = field;
            } else {
                let field = {};
                let selectFieldProps;
                let conditionObj = TABLEID_OBJECT_MAP[savedConditon.t_tableid];
                if (conditionObj === filterObject.t_formid.toLowerCase()) {
                    selectFieldProps = getFiledPropsByFieldId(tableFiledId, _fromFieldData);
                } else {
                    selectFieldProps = getFiledPropsByFieldId(tableFiledId, _associatefromFieldData);
                }

                field.t_fldname = selectFieldProps.label;
                field.t_fldid = selectFieldProps.t_tablefldid;
                field.t_tableid = savedConditon.t_tableid;
                let condition = {};
                condition.t_logicalop = savedConditon.t_logicalop;
                condition.t_operator = savedConditon.t_operator;
                condition.t_value = savedConditon.t_value;
                condition.t_tableid = savedConditon.t_tableid;
                condition.conditionFieldProps = {
                    isOpenPopover: false,
                    popoverTarget: null,
                    isShowConditionValue: true
                }
                condition = processConditionValue(condition, selectFieldProps);
                let conditionArray = [];
                conditionArray.push(condition);
                field.conditions = conditionArray;
                field.filedProps = selectFieldProps;
                fieldContainer[tableFiledId] = field;
                filterIdArray.push(tableFiledId);
                
            }
        }

        let filterSqlArray = [];
        for (let i = 0; i < filterIdArray.length; i++) {
            filterSqlArray.push(fieldContainer[filterIdArray[i]])
        }
        let firstFilterTableId = getIntParam(OBJECT_TABLEID_MAP[data.table]);
        let mainFilterSqlArray = filterSqlArray.filter(f => { return f.t_tableid === firstFilterTableId });
        let associateFilterSqlArray = filterSqlArray.filter(f => { return f.t_tableid !== firstFilterTableId });
        filterObject.sql_array = mainFilterSqlArray.length > 0 ? mainFilterSqlArray : [];
        filterObject.associateSql_array = associateFilterSqlArray.length > 0 ? associateFilterSqlArray : [];
        // let selectedassociateObj = associateFilterSqlArray
        if (data.mode === 'copy') {
            filterObject.t_name = getLocalizedStrings().label.FILTER.COPY_FILTER + filterObject.t_name;
        }
        setFilter({...filterObject});
        setAssociateObjId(associateObjId);
        setAssociateObj(associateObj);
        setpreviousFilter(JSON.parse(JSON.stringify(filterObject)));

        // for updating status field value

        for (let i = 0; i < filterSqlArray.length; i++) {
            if (filterSqlArray[i].filedProps.name === 't_status') {
                let params = {};
                let conditionObj = TABLEID_OBJECT_MAP[filterSqlArray[i].t_tableid];
                params.object = conditionObj;
                params.name = filterSqlArray[i].filedProps.name;
                params.list_type = constants.LIST_FIELD_TYPE_REGULAR;
                let promise = getFieldListValuesPromise(params);
                promise.then((response) => {
                    response = getArrayParam(response);
                    if (response.length > 0) {
                        sortArrayObjectByProperty(response, 'value');
                        filterSqlArray[i].filedProps.list_values = response;
                        setFilter({...filterObject});
                        setpreviousFilter(JSON.parse(JSON.stringify(filterObject)));
                    }
                });
            }
        }
    }
    const setEmptyFilter = () => {
        let filterVal = {
            id: 0,
            t_formid: makeTiteleCase(data.table),
            t_name: '',
            sql_array: [],
            associateSql_array: [],
        }
        setFilter(filterVal);
    }
    useEffect(() => {

        if (data.mode === 'edit') {
        }
        if (data.mode === 'copy') {
            setOpenMode('copy');
        } else {
            setEmptyFilter();
        }

        getFromFieldData();

    }, []);


    function handleDataFromChild(data) {
        setSelectedAssoObjFromChild(data);
      }    
      function getAssociateRows(row) {
        setChildRows(row);
      }    

    const getFiledPropsByFieldId = (fileldId, fromFieldData) => {

        let selectFieldProps = {};
        fromFieldData.fields.map((obj) => {
            if (obj.t_tablefldid === fileldId) {
                selectFieldProps = obj;
            }
        });
        return selectFieldProps;
    }




    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return (
                    <div>
                        {Object.keys(fromFieldData).length > 0 && !isAssociateObjExist &&
                            <Filter
                                ref={filterRef}
                                data={{ ...data }}
                                isAssociateObjExist={isAssociateObjExist}
                                object={data.table}
                                callFrom= {null}
                                setParentSteperFilterState={(val) => setFilterSteperState(val)}
                                parentFilter={filter}
                                fromFieldData={fromFieldData}
                                associateRow ={getAssociateRows}
                            />
                        }
                        {Object.keys(associateFieldData).length > 0 && isAssociateObjExist &&
                            <Filter
                                ref={filterRef}
                                isAssociateObjExist={isAssociateObjExist}
                                data={{ ...associatedata }}
                                callFrom={"associateObj"}
                                object={associateObj}
                                setParentSteperFilterState={(val) => setFilterSteperState(val)}
                                parentFilter={filter}
                                fromFieldData={associateFieldData}
                                assosiateObjects={associateObjects}
                                selectedassociateObj={selectedassociateObj}
                                sendDataToParent={handleDataFromChild}
                                associateRow ={getAssociateRows}
                            />
                        }
                    </div>

                );
            case 1:
                return (
                    <FilterSetup
                        ref={filterset}
                        object={data.table}
                        data={data}
                        filter={filter}
                        filterSetup={filterSetup}
                        filterFieldData={filterFieldData}
                        handleSaveOrRun={(isRun,id)=>handleSaveOrRun(isRun,id)}
                        setParentSteperFilterSetupState={(setup) => setFilterSetupSteperState(setup)}
                        setParentStateLoader={ ()=>setIsShowLoader(false)}
                        setSaveButton= {()=>setIsDisabledSaveButton(false)}

                    />
                );
            default:
                return 'Soffront Filter Module';
        }
    }
    const callForAssociateObj = (callFrom) => {
        let linkChildArrList = getObjectParam(app.linkChildInfo);
        let tableId = getIntParam(OBJECT_TABLEID_MAP[data.table]);
        let arrChildObjectInfo = [];
        let associateObjects = [];
        if (isValidParam(linkChildArrList)) {
            arrChildObjectInfo = getArrayParam(linkChildArrList[tableId]);
            arrChildObjectInfo = arrChildObjectInfo.filter((f)=>{
                return f.objectName == "accounts" || f.objectName == "opportunities" || f.objectName == "cases" || f.objectName == "customobjectone" 
                || f.objectName == "customobjecttwo" || f.objectName == "customobjectthree" || f.objectName == "customobjectfour";
            })    
            arrChildObjectInfo.map(function (obj) {
                    associateObjects.push({
                        label: obj.title,
                        value: obj.objectName
                    });
                

            });
            if(arrChildObjectInfo.length > 0){
                let isLinkObjPresent = false;
                arrChildObjectInfo.map((m)=>{
                    if(m.objectName == defaultAssObj){
                        isLinkObjPresent = true;
                    }
                })
                if (defaultAssObj == '' || defaultAssObj == undefined) {
                    isLinkObjPresent = true;
                }
                if(isLinkObjPresent == true ){
                    let data = {};
                    data.object = 'filter';
                    data.id = data.id;
                    data.table =defaultAssObj != '' && defaultAssObj != undefined ? defaultAssObj : arrChildObjectInfo[0].objectName;;
                   
                    getAssociateFromFieldData(data, associateObjects, callFrom);
             
                }else{
                    let data = {};
                    data.object = 'filter';
                    data.id = data.id;
                    data.table =arrChildObjectInfo[0].objectName;
                    let msg = 'Associated object used in this filter is no more associated with Contacts. Click Ok if you want to modify the criteria for the associated object or Click Cancel if you do not want to modify.'
                    sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, ()=>onConfirmAssoObj(data, associateObjects, callFrom), null);

                }
                  }else{
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'There are no eligible associated objects available.', null);

            }
           
        }
    }

    const onConfirmAssoObj = (data, associateObjects, callFrom)=>{
        let filterVal = filter;
        filter.associateSql_array =[]
        setFilter(filterVal);
        getAssociateFromFieldData(data, associateObjects, callFrom)
    }

    const getAssociateFromFieldData = (data, associateObjects, callFrom) => {
        let object =  data.table;
        let fromFieldData = sfForm.data[object];
      
        let selectedassociateObj = associateObjects.filter(f => { return f.value === object });

        if (isValidParam(fromFieldData)) {
            fromFieldData = JSON.parse(JSON.stringify(fromFieldData));
            setIsAssociateObjExist(true);
            setAssociateObjects(associateObjects);
            setAssociatedata(data);
            setAssociateFieldData(fromFieldData);
            setAssociateObj(object);
            setSelectedassociateObj(selectedassociateObj);

            getStepContent(stepIndex);

            if (data.mode === 'edit' || data.mode === 'copy') {
                loadFilterData(data.id);
            }
        } else {
            if(data?.table !== undefined){
                var promise = getCacheFromFieldsWithPromise(data?.table);
                promise.then((response) => {
                    getAssociateFromFieldData(data, associateObjects, callFrom);
                });
            }
          
        }
    }
    const setFilterSteperState = (filter) => {
        setFilter(filter);
        let group = [];
        group = filter.sql_array.filter((f) => { return f.t_fldname === 'Groups' });

        if (group.length > 0 && group[0].t_fldname === 'Groups') {
            setIsGroupExits(true);
            setIsAssociateObjExist(false);

        }
        else {
            setIsGroupExits(false);
        }
    }

    const setFilterSetupSteperState = (setup) => {
        if(setup.hasOwnProperty(previousFilterSetup)){
            setPreviousFilterSetup({...setup.previousFilterSetup});
        }else{
            setFilterSetup({...setup.filterSetup});
        }

    }
   

    const getSetupData = () => {
        try {
            let params = {};

            params.query_name = filter.t_name;
            params.query_id =filter.id;
            params.query_type = 'filter';
            params.object = data.table;

            var promise = Promise.resolve(HTTPClient.get(endPoints.FILTER.LIST_VIEW_SETUP_GET, params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    setFilterFieldData(response);
                    setIsShowLoader(false);
                });
            }
        } catch (e) { console.error("Error in 'filterSetup.js -> getListViewSetupData()':" + e); }
    }


    const handleNext = () => {
        if (stepIndex === 0) {
            getSetupData();
            setStepIndex(stepIndex + 1);
            setFinished(stepIndex >= 2);

        }
    };

    const handlePrev = () => {
        if (stepIndex > 0) {
            setStepIndex(stepIndex - 1);
        }
    };
    const handlePrevAssociateObj = () => {
        if (stepIndex > 0) {
            setStepIndex(stepIndex - 1);
        } else {
            setIsAssociateObjExist(false);
            getStepContent(stepIndex);
        }
    };

    const validateFilter = () => {
        if (isValidParam(filter.t_name) && filter.t_name.trim().length === 0) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.HOME.FILTER_NAME_BLANK, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else if (filter.t_name.length > 64) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.HOME.FILTER_NAME_MAX_LENGTH, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }
        if (!isValidParam(filter.sql_array) || filter.sql_array.length === 0) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.HOME.FILTER_CRITERIA_EMPTY, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }
        if ((!isValidParam(childRows) || childRows.length === 0) && isAssociateObjExist) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.HOME.FILTER_CRITERIA_EMPTY, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }
        let error = false;
        let errorType = '';
        let errorFieldName = '';
        let errorFieldValue = '';
        filter.sql_array.forEach((field) => {
            if (error) return;
            field.conditions.forEach((condition) => {
                let fieldName = field.t_fldname;
                let filedProps = field.filedProps;
                let operator = condition.t_operator;
                let value = condition.t_value;
                let fieldType = filedProps.field_type;

                if (operator !== "IS_NULL" && operator !== "IS_NOT_NULL") {
                    if (fieldType === constants.FIELD_TYPE_DATE_TIME || fieldType === constants.FIELD_TYPE_DATE) {
                        if (isValidParam(value) && value.length === 0) {
                            error = true;
                            errorType = "NO_VALUE";
                            errorFieldName = fieldName;
                            errorFieldValue = value;
                            return false;
                        } else if ((operator === "LIKE" || operator === "NOT_LIKE") && (value.indexOf("CURDATE") >= 0 || value.indexOf("CURWEEK") >= 0 || value.indexOf("CURMONTH") >= 0)) {
                            error = true;
                            errorType = "NO_VALUE";
                            errorFieldName = fieldName;
                            errorFieldValue = value;
                            return false;
                        }
                    } else if (isValidParam(value) && value.length === 0) {
                        error = true;
                        errorType = "NO_VALUE";
                        errorFieldName = fieldName;
                        errorFieldValue = value;
                        return false;
                    } if (fieldType === constants.FIELD_TYPE_INTEGER || fieldType === constants.FIELD_TYPE_FLOAT) {
                        if (fieldType === constants.FIELD_TYPE_INTEGER && !isInteger(value)) {
                            error = true;
                            errorType = "INT";
                            errorFieldName = fieldName;
                            errorFieldValue = value;
                            return false;
                        }
                        if (fieldType === constants.FIELD_TYPE_FLOAT && !isFloat(value)) {
                            error = true;
                            errorType = "FLOAT";
                            errorFieldName = fieldName;
                            errorFieldValue = value;
                            return false;
                        }
                    }
                }
            });
        });
        if (filter.associateSql_array.length > 0 && isAssociateObjExist) {
            filter.associateSql_array.forEach((field) => {
                if (error) return;
                field.conditions.forEach((condition) => {
                    let fieldName = field.t_fldname;
                    let filedProps = field.filedProps;
                    let operator = condition.t_operator;
                    let value = condition.t_value;
                    let fieldType = filedProps.field_type;
                    if (operator !== "IS_NULL" && operator !== "IS_NOT_NULL") {
                        if (fieldType === constants.FIELD_TYPE_DATE_TIME || fieldType === constants.FIELD_TYPE_DATE) {
                            if (isValidParam(value) && value.length === 0) {
                                error = true;
                                errorType = "NO_VALUE";
                                errorFieldName = fieldName;
                                errorFieldValue = value;
                                return false;
                            } else if ((operator === "LIKE" || operator === "NOT_LIKE") && (value.indexOf("CURDATE") >= 0 || value.indexOf("CURWEEK") >= 0 || value.indexOf("CURMONTH") >= 0)) {
                                error = true;
                                errorType = "NO_VALUE";
                                errorFieldName = fieldName;
                                errorFieldValue = value;
                                return false;
                            }
                        } else if (isValidParam(value) && value.length === 0) {
                            error = true;
                            errorType = "NO_VALUE";
                            errorFieldName = fieldName;
                            errorFieldValue = value;
                            return false;
                        } if (fieldType === constants.FIELD_TYPE_INTEGER || fieldType === constants.FIELD_TYPE_FLOAT) {
                            if (fieldType === constants.FIELD_TYPE_INTEGER && !isInteger(value)) {
                                error = true;
                                errorType = "INT";
                                errorFieldName = fieldName;
                                errorFieldValue = value;
                                return false;
                            }
                            if (fieldType === constants.FIELD_TYPE_FLOAT && !isFloat(value)) {
                                error = true;
                                errorType = "FLOAT";
                                errorFieldName = fieldName;
                                errorFieldValue = value;
                                return false;
                            }
                        }
                    }
                });
            });
        }

        if (error) {
            if (errorType === "NO_VALUE") {
                dispatch(showCustomSnackBar(errorFieldName + getLocalizedStrings().message.HOME.FILTER_SQL_ERROR_NO_VALUE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            } else if (errorType === "INT") {
                dispatch(showCustomSnackBar(errorFieldName + ' - ' + errorFieldValue + getLocalizedStrings().message.HOME.FILTER_SQL_ERROR_INTEGER, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            } else if (errorType === "FLOAT") {
                dispatch(showCustomSnackBar(errorFieldName + '  -  ' + errorFieldValue + getLocalizedStrings().message.HOME.FILTER_SQL_ERROR_FLOAT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }
            return false;
        } else {
            return true;
        }
    }

    const isValidToSave = () => {
        if (openMode !== 'copy') {
            if (isValidParam(filterset)) {
                if (isValidParam(filterset)) {
                    filterset.current.setFilterSetUpInParentState();
                }
            }
            if (_.isEqual(filter, previousFilter) && _.isEqual(filterSetup, previousFilterSetup) && !isDuplicateError) {
                return false;
            } else {
                return true;
            }
           
        }
    }

    const processToSaveFilter = () => {
        if (isValidParam(filter)) {
            saveFilter(false);
        }
    }

    const processToRunFilter = () => {
        if (isValidParam(filter)) {
            if (getObjectParam(isValidToSave())) {
                saveFilter(true);
            } else {
                if (isSaved) {
                    let tabs = getArrayParam(sfTab.tabs);
                    tabs = tabs.filter(f => {
                        if (data.callFromListView && f.type === TYPE_COLUMN_VIEW) {
                            return f;
                        }
                        else {
                            return f.type === TYPE_LIST_VIEW
                        }
                    });
                    let tempTab = tabs.length > 0 ? tabs[0] : null;
                    let filter = isValidParam(tempTab) && isValidParam(tempTab.info) ? tempTab.info.filter : null;
                    if (isValidParam(filter) && getIntParam(filter.id) > 0) {
                        if (data.callFromListView && tempTab.type === TYPE_LIST_VIEW) {
                            setMounted(false);
                            refreshListView(data.table);
                        } else if (tempTab.type === TYPE_COLUMN_VIEW) {
                            refreshColumnView(data.table);
                        }
                    }
                    runFilter();
                } else {
                    runFilter();
                }
            }
        }
    }

    const setFilterSetUpInParentState = () => {
        let isValidSetupView = false
        if (isValidParam(filterset)) {
            filterset.current.setFilterSetUpInParentState();
        }
    }

    const saveFilter = (isRun) => {
        let previousFilterVal = JSON.parse(JSON.stringify(filter));
        let finalFilterSqlArr = [];
        let sql_arr = [];
        let filed_cnt = 0;
        let associateObjId = 0;
        if (filter.associateSql_array.length > 0 && isAssociateObjExist) {
            finalFilterSqlArr = filter.sql_array.concat(filter.associateSql_array);
            associateObjId = filter.associateSql_array[0].t_tableid;
        } else {
            finalFilterSqlArr = filter.sql_array;
        }
        finalFilterSqlArr.forEach(function (field) {
            filed_cnt++;
            let condition_cnt = 0;
            field.conditions.forEach(function (condition) {
                let fieldName = field.t_fldname;
                let filedProps = field.filedProps;
                let fieldType = filedProps.field_type;
                let operator = condition.t_operator;

                let temp_condition = {};
                temp_condition.t_fldid = field.t_fldid;
                temp_condition.t_operator = condition.t_operator;
                temp_condition.t_value = condition.t_value;
                temp_condition.t_logicalop = condition.t_logicalop;
                temp_condition.t_tableid = condition.t_tableid;
                condition_cnt++;

                if (condition_cnt === 1 && field.conditions.length > 1) {
                    temp_condition.is_start = true;
                    temp_condition.is_end = false;
                } else if (condition_cnt === 1 && field.conditions.length === 1) {
                    temp_condition.is_start = true;
                    temp_condition.is_end = true;
                } else if (condition_cnt > 1 && condition_cnt !== field.conditions.length) {
                    temp_condition.is_start = false;
                    temp_condition.is_end = false;
                } else if (condition_cnt > 1 && condition_cnt === field.conditions.length) {
                    temp_condition.is_start = false;
                    temp_condition.is_end = true;
                }
                if (fieldType === constants.FIELD_TYPE_DATE_TIME || fieldType === constants.FIELD_TYPE_DATE) {
                    // Due need to write 
                    if (isValidParam(condition.t_value)) {
                        if (condition.t_operator === 'LIKE' || condition.t_operator === 'NOT_LIKE') {
                            let splitDate = condition.t_value.split('AND');

                            let fromDate = moment(splitDate[0].trim());
                            fromDate = isValidParam(fromDate) ? fromDate.format("YYYY-MM-DD HH:mm") : '';
                            let toDate = moment(splitDate[1]);
                            toDate = isValidParam(toDate) ? toDate.format("YYYY-MM-DD HH:mm") : '';

                            temp_condition.t_value = fromDate !== "" && toDate !== "" ? "'" + fromDate + "' AND '" + toDate + "'" : "";

                        } else if (condition.t_value === 'CURDATE()' || condition.t_value === 'CURWEEK()' || condition.t_value === 'CURMONTH()') {
                            //by pass
                        } else {
                            let dateFormat = 'YYYY-MM-DD';
                            if (condition.t_operator === '>' || condition.t_operator === '>=' || condition.t_operator === '<'|| condition.t_operator === '<=') {
                                dateFormat = 'YYYY-MM-DD HH:mm';
                            }
                            let dateValue = moment(condition.t_value);
                            if (isValidParam(dateValue)) {
                                temp_condition.t_value = "'" + dateValue.format(dateFormat) + "'";
                            } else {
                                temp_condition.t_value = '';
                            }
                        }
                    }
                } else {
                    let value = condition.t_value;
                    if (filedProps.is_phone_field) {
                        value = phoneFormat(value);
                    }
                    if (operator === "LIKE" || operator === "NOT_LIKE") {
                        temp_condition.t_value = '\'%' + value + '%\''
                    } else if (fieldType === constants.FIELD_TYPE_INTEGER || fieldType === constants.FIELD_TYPE_FLOAT) {
                        temp_condition.t_value = value;
                    } else {
                        temp_condition.t_value = '\'' + value + '\'';
                    }
                }

                // Not logical operator 
                if (operator === "NOT_EQUAL" || operator === "NOT_LIKE") {
                    if ((fieldType === constants.FIELD_TYPE_DATE || fieldType === constants.FIELD_TYPE_DATE_TIME) && operator === "NOT_LIKE") {
                        temp_condition.t_operator = "BETWEEN";
                    } else if (operator === "NOT_EQUAL") {
                        temp_condition.t_operator = "=";
                    } else if (operator === "NOT_LIKE") {
                        temp_condition.t_operator = "LIKE";
                    }
                    temp_condition.t_logicalop = condition.t_logicalop + ' ' + 'NOT'
                } else if (operator === "LIKE") {
                    if (fieldType === constants.FIELD_TYPE_DATE || fieldType === constants.FIELD_TYPE_DATE_TIME) {
                        temp_condition.t_operator = "BETWEEN";
                    }
                }

                if (operator === "IS_NULL") {
                    temp_condition.t_operator = "IS";
                    temp_condition.t_value = "NULL";
                }
                if (operator === "IS_NOT_NULL") {
                    temp_condition.t_operator = "IS";
                    temp_condition.t_value = "NOT NULL";
                }
                sql_arr.push(temp_condition)

            });
        });

        let filterSaveObject = {};
        if (openMode !== "copy") {
            filterSaveObject.id = filter.id;
        } else {
            filterSaveObject.id = 0;
            setOpenMode('edit')
        }

        filterSaveObject.t_formid = filter.t_formid;
        filterSaveObject.t_name = filter.t_name.trim();
        filterSaveObject.sql_array = sql_arr;
        filterSaveObject.t_associateobjectid = associateObjId;
        let filtersetRef = null;
        let isValidSetupViewTemp = false;
        if (isValidParam(filterset)) {
            filtersetRef = filterset.current;
            isValidSetupViewTemp = filterset.current.setupViewValidation();
        }
        if (isValidSetupViewTemp) {
            setIsShowLoader(true);
            var promise = Promise.resolve(HTTPClient.post(endPoints.FILTER.SAVE, filterSaveObject));
            promise.then((response) => {
                if (response.status === 0) {
                    setIsDuplicateError(false);
                    let tempfilter = filter;
                    tempfilter.id = response.data.id;
                    setFilter(tempfilter);
                    setIsSaved(true);
                    if (previousFilter !== undefined) {
                        let temppreviousFilter = getObjectParam(previousFilter);
                        temppreviousFilter.id = response.data.id;
                        setpreviousFilter(temppreviousFilter);
                    }

                    let tempReduxFilter = {};
                    tempReduxFilter.id = response.data.id;
                    tempReduxFilter.query_id = response.data.filter_id;
                    tempReduxFilter.name = filter.t_name;
                    tempReduxFilter.type = 'filter';

                    addFilterToReduxStore(tempReduxFilter, filter.t_formid.toLowerCase());
                    getRecentFilters(data.table);

                    filtersetRef.saveFilterSetup(isRun,response);
                    
                } else if (response.status === -1 && response.error.message !== "") {
                    setIsShowLoader(false);
                    if (response.error.message === 'Filter Name already exists!') {
                        setIsDuplicateError(true);
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.HOME.FILTER_SQL_ERROE_ALREADY_EXISTS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    } else {
                        dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                }
            });
        }
    }
 
    const handleSaveOrRun = (isRun,id)=>{
        if (isRun) {
            /** START: After saving listView refresh. */
            let tabs = getArrayParam(sfTab.tabs);
            tabs = tabs.filter(f => {
                if (data.callFromListView && f.type === TYPE_COLUMN_VIEW) {
                    return f;
                }
                else {
                    return f.type === TYPE_LIST_VIEW
                }
            });
            let tab = tabs.length > 0 ? tabs[0] : null;
            let _filter = isValidParam(tab) && isValidParam(tab.info) ? tab.info.filter : null;

            if (isValidParam(_filter) && getIntParam(_filter.id) > 0 && getIntParam(_filter.id) === getIntParam(id)) {
                if (data.callFromListView && tab.type === TYPE_LIST_VIEW) {
                    dispatch(setMounted(false));
                    refreshListView(data.table);
                } else if (tab.type === TYPE_COLUMN_VIEW) {
                    refreshColumnView(data.table);
                }
                dispatch(getAppDrawer(false, null, null, null, null));
           if(filter?.t_name !== _filter?.name){
            runFilter();
           }
            } else {
                runFilter();
            }
            /** END: After saving listView refresh. */
            setIsShowLoader(false);
       
    } else {
        getSetupData()
        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
    }
    }

    const getFromFieldData = () => {
        let object = data.table;
        let _fromFieldData = sfForm.data[object];

        if (isValidParam(_fromFieldData)) {
            _fromFieldData = JSON.parse(JSON.stringify(_fromFieldData));
            if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT) {
                let groups = [];
                if (isValidParam(filters.groups)) {
                    groups = Object.assign([], getArrayParam(filters.groups));
                    groups = groups.filter(f => { return f.status === 'Active' });
                }

                let groupsArray = [];
                groups.map((groupObject, index) => {
                    groupsArray.push({
                        "id": groupObject.name,
                        "value": groupObject.name

                    });
                });

                let fields = getArrayParam(_fromFieldData.fields);

                fields.map((field, index) => {
                    if (field.field_type === constants.FIELD_TYPE_LIST) {
                        let multiSelectList = [];
                        for (let x = 0; x < field.list_values.length; x++) {
                            if (field.list_values[x].value !== "") {
                                multiSelectList.push(field.list_values[x]);
                            }

                        }
                        field.list_values = multiSelectList;
                    }

                });

                _fromFieldData.fields = fields;
                _fromFieldData.fields.push({
                    id: -9999,
                    name: "Groups",
                    label: getLocalizedStrings().label.HOME.GROUPS,//"Groups",
                    field_type: "list",
                    field_length: 0,
                    default_value: "",
                    t_tablefldid: -9999,
                    list_type: "regular",
                    list_values: groupsArray
                });
            }

            setFromFieldData(_fromFieldData);
            if (data.mode === 'edit' || data.mode === 'copy') {
                loadFilterData(data.id,_fromFieldData);
            }
        } else {
            var promise = getCacheFromFieldsWithPromise(object);
            promise.then((response) => {
                getFromFieldData();
            });
        }
    }

    const runFilter = () => {
        if (isValidParam(filter)) {
            let _filter = null;
            let filterObj = {};
            _filter = getObjectParam(filter);
            filterObj.id = _filter.id;
            filterObj.name = _filter.t_name;
            filterObj.type = 'filter';
            dispatch(getAppDrawer(false, null, null, null, null));
            openTab(filterObj);
        }
    }

    const openTab = (filter) => {
        let object = getStringParam(data.table);
        dispatch(setHeader(object, null, null, true, true));
        let tab = {
            item: object,
            label: filter.name,
            object: object,
            type: TYPE_LIST_VIEW,
            imgName: getImageNameByObject(object),
            url: '/' + object + '/queries',
            info: { filter: { id: filter.id, name: filter.name, type: filter.type } }
        };
        addTab(tab, true);
        saveRecentFilter(object, filter);
    }

    let themeColor = app.me.background;
    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight - 10) / 2
    if (isShowLoader) {
        return (
            <div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>);
    }
    let buttonLabel = "Add";
    if (data.id > 0 && data.mode === 'edit' && associateObjId > 0) {
        buttonLabel = "Edit";
    }
    let filterId = isValidParam(filter) ? getIntParam(filter.id) : 0;
    return (
        <div>
            <div>
                <>
                    <Stepper nonLinear activeStep={stepIndex} sx={{padding: '24px', backgroundColor: '#f5f5f5'}}>
                        <Step>
                            <StepLabel
                                StepIconProps={{
                                    style: { color: stepIndex === 1 ? '#717171' : themeColor },
                                    fontWeight: 'bold'
                                }}>{getLocalizedStrings().label.HOME.ADD_FILTER}</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel
                                StepIconProps={{
                                    style: { color: stepIndex === 0 ? '#717171' : themeColor },
                                    fontWeight: 'bold'
                                }}>{getLocalizedStrings().label.HOME.FITER_SETUP}</StepLabel>
                        </Step>
                    </Stepper>
                </>
            </div>
            <div>{getStepContent(stepIndex)}</div>
            <div style={{ marginBottom: "10%" }}>
                {!finished &&
                    <div style={{ marginTop: 20, paddingLeft: 19 }}>
                        {stepIndex === 1 &&
                            <div>
                                <Button
                                    primary={true}
                                    onClick={() => processToRunFilter()}
                                    style={{...styles.primaryButton,height:30,fontSize:16}}
                                    disabled={isDisabledSaveButton}
                                > {getLocalizedStrings().label.COMMON.SAVE_RUN}</Button>
                                <Button
                                    onClick={() => { processToSaveFilter() }}
                            style={{...styles.secondaryButton,height:30,fontSize:16}}
                                > {getLocalizedStrings().label.COMMON.SAVE}</Button>
                                <Button
                                    disabled={stepIndex === 0}
                                    onClick={() => {
                                        setIsDisabledSaveButton(true);
                                        setFilterSetUpInParentState();
                                        handlePrev();
                                    }
                                    }
                                    style={styles.secondaryButton}
                                >  {getLocalizedStrings().label.FILTER.PREVIOUS_BUTTON_TEXT}</Button>
                                {
                                    filter.id > 0 && <Button
                                        onClick={() => {
                                            filter.id = 0;
                                            filter.t_name = getLocalizedStrings().label.FILTER.COPY_FILTER + filter.t_name;
                                            handlePrev();
                                        }}
                                        style={{ ...styles.primaryButton, display: 'none',height:30,fontSize:16 }}
                                    >Copy</Button>
                                }
                            </div>
                        }
                        {stepIndex === 0 &&
                            <div>
                                <Button
                                    id="next"
                                    primary={true}
                                    onClick={() => {
                                        if (!validateFilter()) {
                                            return false;
                                        }
                                        handleNext();
                                    }}
                                    style={{...styles.primaryButton,height:30,fontSize:16}}
                                >{getLocalizedStrings().label.FILTER.NEXT_BUTTON_TEXT}</Button>
                                {stepIndex === 0 && !isGroupExits
                                    && data.table === constants.CONTACTS_OBJECT && !isAssociateObjExist &&

                                    <Button
                                        id="Add Criteria for Assosiate Objects"
                                        primary={true}
                                        onClick={() => {
                                            if (!validateFilter()) {
                                                return false;
                                            }
                                            callForAssociateObj("callFromAssociateObj");
                                        }}
                                        style={{...styles.primaryButton,height:30,fontSize:16}}
                                    > {getLocalizedStrings().label.FILTER.CRITERIA_FOR_ASSOCIATED_OBJECTS}</Button>
                                }
                                {stepIndex === 0 && isAssociateObjExist &&

                                    <Button
                                        id="prev"
                                        primary={true}
                                        onClick={() => {
                                            handlePrevAssociateObj();
                                        }}
                                        style={styles.secondaryButton}
                                    >{getLocalizedStrings().label.FILTER.PREVIOUS_BUTTON_TEXT}</Button>
                                }

                            </div>
                        }


                    </div>
                }
            </div>
        </div>
    );

}


export default FilterStepper;
