import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';

const RecurringEditConfirmDailog = () => {
    const dispatch = useDispatch();

    const [actionType, setActionType] = useState('only');
    const [changelog, setChangeLog] = useState({actionType:'only'});

    const appContainer = useSelector((state) => state.appContainer);

    const handleClose = () => {

        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const handleEditRecurring = (val) => {
        appContainer.dialogProps.eventName(val);
    }

    const selectActionType = (value) => {
        let _changelog = changelog;
        _changelog.actionType = value;
        setChangeLog({..._changelog});
        setActionType(value);

    }
    const handleRedioOnChange = () => {
        let value = changelog.actionType;
        if (value != null) {
            handleEditRecurring(value);
        }
    }

    useEffect(() => {
        dispatch(setAppDialogActions(getActions()));
    }, []);

    const getActions = () => {
        const actions = [
            <Button

                primary={true}
                onClick={() => handleRedioOnChange()}
                style={styles.primaryButton}
            >{getLocalizedStrings().label.COMMON.OK}</Button>,
            <Button

                primary={true}
                onClick={() => handleClose()}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,

        ];

        return actions;
    }

    let objName = appContainer.dialogProps.callFrom;
    let objTxt = '';
    if (objName !== null) {
        if (objName === 'appointments') {
            objTxt = "Appointment";
        } else if (objName === 'tasks') {
            objTxt = "Task";
        }
    }

    let btnStyle = { ...styles.secondaryButton };
    return (
        <div>
            <div className="row">
                <div className="col-sm-5">
                    <div>
                        <FormControl component="fieldset">
                            <RadioGroup name="layout" onChange={(event) => selectActionType(event.target.value)} value={changelog.actionType} style={{ width: 'auto' }} >
                                <FormControlLabel value="only" control={<Radio color="default" />} label={getLocalizedStrings().label.APPOINTMENTS_TASKS.ONLY_THIS + " " + objTxt} />
                                <FormControlLabel value="following" control={<Radio color="default" />} label={getLocalizedStrings().label.APPOINTMENTS_TASKS.FOLLOWING + " " + objTxt + "s"} />
                                <FormControlLabel value="all" control={<Radio color="default" />} label={getLocalizedStrings().label.APPOINTMENTS_TASKS.ALL + " " + objTxt + "s"} />

                            </RadioGroup>
                        </FormControl>

                    </div>
                </div>
                <div className="col-sm-7">
                    {getLocalizedStrings().message.APPOINTMENTS_TASKS.RECURR_EDIT_DIALOG_ONLY_BTN_INFO_1} {objTxt}s  {getLocalizedStrings().message.APPOINTMENTS_TASKS.RECURR_EDIT_DIALOG_ONLY_BTN_INFO_2}
                </div>
            </div>

            <div className="row" style={{ marginTop: '-83px' }}>
                <div className="col-sm-5">
                </div>
                <div className="col-sm-7">
                    {getLocalizedStrings().message.APPOINTMENTS_TASKS.RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_1} {objTxt}s {getLocalizedStrings().message.APPOINTMENTS_TASKS.RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_2}
                    {getLocalizedStrings().message.APPOINTMENTS_TASKS.RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_3} {objTxt}s {getLocalizedStrings().message.APPOINTMENTS_TASKS.RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_4}
                </div>
            </div>

            <div className="row" style={{ }}>
                <div className="col-sm-5">

                </div>
                <div className="col-sm-7">
                    {getLocalizedStrings().message.APPOINTMENTS_TASKS.RECURR_EDIT_DIALOG_ALL_BTN_INFO_1} {objTxt}s  {getLocalizedStrings().message.APPOINTMENTS_TASKS.RECURR_EDIT_DIALOG_ALL_BTN_INFO_2}
                    {getLocalizedStrings().message.APPOINTMENTS_TASKS.RECURR_EDIT_DIALOG_ALL_BTN_INFO_3} {objTxt}s  {getLocalizedStrings().message.APPOINTMENTS_TASKS.RECURR_EDIT_DIALOG_ALL_BTN_INFO_4}
                </div>
            </div>
        </div>
    );
}

export default RecurringEditConfirmDailog;