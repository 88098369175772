import React, { useState, useEffect } from 'react';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { Button, Box, Grid, TextField } from '@mui/material';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { isValidParam, getStringParam, getIntParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import * as sfDialogs from './sfDialogs';
import { useDispatch, useSelector } from "react-redux";
import {tollFreeVerification} from "../../../services/actions/smsAction";
import Autocomplete from '@mui/material/Autocomplete';
const fields = [
    {
        name: 'business_name',
        label: 'Business Name',
        value: '',
        type: 'text',
        optionList:[],
        disabled: false,
        
    },
    {
        name: 'business_website',
        label: 'Business Website',
        value: '',
        type: 'text',
        optionList:[],
        disabled: false,
        
    },
    {
        name: 'notification_email',
        label: 'Notification Email',
        value: '',
        type: 'text',
        optionList:[],
        disabled: false,
        
    },
    {
        name: 'use_case_categories',
        label: 'Use Case Categories',
        value: '',
        optionList:[{value:'TWO_FACTOR_AUTHENTICATION',label:'Two Factor Authentication'},{value: 'ACCOUNT_NOTIFICATIONS',label: 'Account Notifications'},{value: 'CUSTOMER_CARE',label: 'Customer Care'},{ value: 'CHARITY_NONPROFIT', label: 'Charity Nonprofit'}, {value: 'DELIVERY_NOTIFICATIONS',label: 'Delivery Notifications'}, {value: 'FRAUD_ALERT_MESSAGING',label: 'Fraud Alert Messaging'},{value:'EVENTS',label: 'Events' },{value:'HIGHER_EDUCATION',label:'Higher Education'},{value: 'K12',label: 'K12'},{value:'POLLING_AND_VOTING_NON_POLITICAL',label: 'Polling And VotingNon Political'},{value:'MARKETING',label:'Marketing'},{value:'POLITICAL_ELECTION_CAMPAIGNS',label:'Political Election Campaigns'},{value:'PUBLIC_SERVICE_ANNOUNCEMENT ',label:'Public Service Announcement'},{value: 'SECURITY_ALERT',label: 'Security Alert'}],
        type: 'select',
        disabled: false,
    },
    {
        name: 'use_case_summary',
        label: 'Use Case Summary',
        value: '',
        type: 'text',
        optionList:[],
        disabled: false,
        
    },
    {
        name: 'production_message_sample',
        label: 'Production Message Sample',
        value: '',
        type: 'text',
        optionList:[],
        disabled: false,
        
    },
    {
        name: 'opt_in_image_urls',
        label: 'Opt in image urls',
        value: '',
        type: 'text',
        optionList:[],
        disabled:false,
    },
    {
        name: 'opt_in_type',
        label: 'Opt In Type',
        value: '',  
        optionList:[{value: "VERBAL" ,label:'Verbal'}
        // ,{value: "WEB_FORM", label: 'Web Form'},{value: "PAPER_FORM", label: 'Paper Form'},{value: "VIA_TEXT", label: 'Via Text'}, {value: "MOBILE_QR_CODE" ,label: 'Mobile Qr Code'}
        ],
        type: 'select',
        disabled:false,
    },
    {
        name: 'message_volume',
        label: 'Message Volume',
        value: '',
        type: 'select',
        optionList:[{value: "10", label:"10"},{value: "100", label: "100"},{value: "1,000", label:'1,000'},{value: "10,000", label: '10,000'},{value: "100,000" , label: '100,000'},{value: "250,000", label: '250,000'},{value: "500,000", label: '500,000'},{value: "750,000", label: '750,000'},{value: "1,000,000", label: '1,000,000'},{value: "5,000,000", label: '5,000,000'},{value: "10,000,000+", label: '10,000,000+'},],
        disabled:false
    },
    {
        name: 'business_street_address',
        label: 'Business Street Address',
        value: '',
        type: 'text',
        optionList:[],
        disabled: false
    },
    {
        name: 'business_street_address2',
        label: 'Business Street Address2',
        value: '',
        type: 'text',
        optionList:[],
        disabled: false
    },
    {
        name: 'business_city',
        label: 'Business city',
        value: '',
        type: 'text',
        optionList:[],
        disabled: false,
    },
    {
        name: 'business_state_province_region',
        label: 'Business State Province Region',
        value: '',
        type: 'text',
        optionList:[],
        disabled: false,
    },
    {
        name: 'business_postal_code',
        label: 'Business Postal Code',
        value: '',
        type: 'text',
        optionList:[],
        disabled: false,
    },
    {
        name: 'business_country',
        label: 'Business Country',
        value: '',
        type: 'text',
        optionList:[],
        disabled: false,
    },
    {
        name: 'business_contact_first_name',
        label: 'Business Contact Firstname',
        value: '',
        type: 'text',
        optionList:[],
        disabled: false,
    },
    {
        name: 'business_contact_last_name',
        label: 'Business Contact Lastname',
        value: '',
        type: 'text',
        optionList:[],
        disabled: false,
    },
    {
        name: 'business_contact_email',
        label: 'Business Contact Email',
        value: '',
        type: 'text',
        optionList:[],
        disabled: false,
    },
    {
        name: 'business_contact_phone',
        label: 'Business Contact Phone',
        value: '',
        type: 'text',
        optionList:[],
        disabled: false,
    }
]

const TwilioNumberVerificationForm = ({data}) => {

    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);

	const [isLoaded, setIsLoaded] = useState(false);
    const [formData, setFormData] = useState([...fields]);


    const handleChange = (value, index) => {
        const _formData = [...formData];
        const _dataObj = { ..._formData[index] };
        _dataObj.value = value;
        _formData[index] = _dataObj;
        setFormData([..._formData]);
        console.log(_formData);
    };

    function getParams(array) {
        let params = {};
        try {
            array.forEach((obj) => {
                let value = obj.value;;
                if (obj.type == "select") {
                    value = [];
                    value.push(obj.value.value);
                    value = value.join(" , ");
                }
                params = { ...params, [obj.name]: value };
            });
        } catch (error) {
            console.error("getParams()':" + error);
        }
        return params;
    }

    const verifyNumbers = () => {
		try {	
            let isValid =true;
            let message = '';
			if (isValid) {
				setIsLoaded(false);
				let params = getParams(formData);
                params.tollfree_phone_number_sid = data.twilio_sid;
                
				let promise = Promise.resolve(tollFreeVerification(params));
				if (isValidParam(promise)) {
					promise.then(response => {
						if (isValidParam(response)) {
							if (response.status == 0) {
								let twilioNumbersArr = getArrayParam(response.twilio_numbers);
								setIsLoaded(true);
							} else {
								setIsLoaded(true);
								message = getLocalizedStrings().message.SMS_INTEGRATION.NO_AVAILABLE_PHONE_NUMBERS_FOR_ZIP;
								sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, message, null);

							}

						}
					});
				}
			} else {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, message, null);
			}
		} catch (error) {
			console.error("Error in 'smsIntegration.js -> findNumbers()':" + error);
		}
	}

    const cancel = () => {
		try {	
            dispatch(getAppDrawer(true, 'Lease Number', constants.LEASE_NUMBER, null, constants.PHONE_NUMBERS_OBJECT));
		} catch (error) {
			console.error("Error in 'smsIntegration.js -> findNumbers()':" + error);
		}
	}

    return (
        <div style={{ padding: 20 }}>
            <div >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        {formData.map((data, index) => (<Grid item xs={6} key={`grid-${index}`}>
                        <Fields data={data} handleChange={(value) => handleChange(value, index)}/>
                        </Grid>))}
                    </Grid>
                </Box>
            </div>
            <div style={{ width: '100%' }}>
                <div style={{ float: 'right', margin: '12px 0' }}>
                    <Button onClick={() => verifyNumbers()} style={styles.primaryButton}>{getLocalizedStrings().label.COMMON.SAVE} </Button>
                    <Button onClick={() => cancel()} style={styles.secondaryButton}>{getLocalizedStrings().label.COMMON.CANCEL} </Button>
                </div>
            </div>
        </div>

    );
};

const Fields = ({
        data,
        handleChange,
      }) => {
        if (data.type == "text" || data.type == "url" || data.type == "number") {
          return (
            <TextField
              onChange={(e) => handleChange(e.target.value)}
              value={data.value}
              type={data.type}
              style={{ width: "100%" }}
              id={`${data.name}`}
              label={data.label}
              variant="outlined"
              size='small'
            />
          );
        }
        if (data.type == "textarea") {
          return (
            <TextField
              onChange={(e) => handleChange(e.target.value)}
              value={data.value}
              type={data.type}
              style={{ width: "100%" }}
              id={`${data.name}`}
              label={data.label}
              variant="outlined"
              multiline
              rows={4}
              size='small'
            />
          );
        }
      
        if (data.type == "select") {
          return (
            <Autocomplete
              disablePortal
              id={`${data.name}`}
              defaultValue={data.value}
              options={data.optionList}
              sx={{ width: "100%" }}
              onChange={(e, newValue) => handleChange(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={`${data.name}+${data.type}`}
                  label={data.label}
                  size='small'
                />
              )}
            />
          );
        }
}


export default TwilioNumberVerificationForm;