import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { connect } from 'react-redux';
import $ from 'jquery';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { getAppDrawer, setAppDrawerTitle } from '../../../services/actions/appContainerActions';
import { isValidParam, getArrayParam, getBooleanParam, getStringParam, getObjectParam, isUrl } from '../../../services/helper/parameterVerifier';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import ShowCircularProgress from './circularProgress';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { refreshListView } from '../../../services/actions/listViewAcions';
import * as sfDialogs from './sfDialogs';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { constants } from '../../../services/constants/constants';
import copy from 'copy-to-clipboard';
import { getDefaultBeeEditorData } from '../../../services/helper/common';
import { getBeeEditorFontList } from "../../../services/helper/utils";
import Bee from "@mailupinc/bee-plugin";
import { Data } from '@react-google-maps/api';


const LandigPage =(props)=> {
  const dispatch = useDispatch();  
  const appState = useSelector((state) => state.app);
  const sfForm = useSelector((state) => state.sfForm);
  const appContainer = useSelector((state) => state.appContainer);

	const [mouted, setMouted] = useState(false);
  const [fixedUrl, setFixedUrl] = useState(constants.LANDIND_PAGE_BASEPATH);
	const [postfixUrl, setPostfixUrl] = useState('');
	const [prefixUrl, setPrefixUrl] = useState('');
	const [template, setTemplate] = useState({});
	const [moreActionOpen, setmoreActionOpen] = useState(false);
	const [isEditPanel, setisEditPanel] = useState(false);
	const [formhandler, setformHandler] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isInputValueChanged, setisInputValueChanged] = useState(false);
  const [isCopy, setisCopy] = useState(false);
  const [bee, setBee] = useState(null);
  const [token, setToken] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [dummyData ,setDummyData] =  useState(false)

  const  beeTest = new Bee(); 

  useEffect(()=>{
    getpageDetails();
  },[])

  useEffect(()=>{
    if (!appContainer.dialogProps.isOpenDialog) {
      if (isValidParam(formhandler)) {
        formhandler.reject();
        setformHandler(null);
      }
    }
  },[appContainer.dialogProps])

  const getpageDetails = () => {
    let params = null;
    var promise = null;
      params = {
        id: props.id,
        call_from: props.type
      }
      promise = Promise.resolve(HTTPClient.get(endPoints.LANDING_PAGE.GET, params));
      if (isValidParam(promise)) {
        promise.then((response) => {
          if (isValidParam(response) && response.hasOwnProperty('template')) {
            let Name = response.template.name;
            let id = response.template.id;
            let status = response.template.status;
            let url = getStringParam(response.template.url);
            let category = response.template.category;
            let pageBody = response.template.pageBody;
            let pageJSON = response.template.pageJSON;
            let tempTemplate = template;
            tempTemplate.name = Name;
            tempTemplate.id = id;
            tempTemplate.status = status;
            tempTemplate.url = url;
            tempTemplate.category = category;
            tempTemplate.pageBody = pageBody;
            tempTemplate.pageJSON = pageJSON;
            let fixedUrlLength = fixedUrl.length;
            let index = fixedUrlLength + url.substring(fixedUrlLength).lastIndexOf('/');
            let prefixUrl = url.substring(0, index + 1);
            let postfixUrl = url.substring(index + 1);
            if (getStringParam(props.type).toLowerCase() == 'copy') {
              tempTemplate.id = 0;
              tempTemplate.name = Name;
              let tempValue = getStringParam(tempTemplate.name).toLowerCase();
              tempValue = tempValue.replace(/\s+/g, '-')
              tempValue = tempValue.replace(/[^-0-9A-Za-z]+/ig, '');
              postfixUrl = 'copy_of_' + tempValue;
              tempTemplate.name = 'Copy_of_' + tempTemplate.name;
              tempTemplate.url = prefixUrl + postfixUrl;
            }
            setTemplate(tempTemplate);
            setPrefixUrl(prefixUrl);
            setPostfixUrl(postfixUrl);
            registerPage(response);
            setMouted(true);
          }
        })
      }
  }

  const registerPage = async(response) => {
    let tempJson = response.template.pageJSON;
    let tempBody = response.template.pageBody;
    let tenantId = getStringParam(appState.me.projectId);
      var beeConfig = {
        uid: tenantId,
        container: 'bee-plugin-container',
        language: 'en-US',
        trackChanges: true,
        defaultForm: {},
        contentDefaults: {
          ...contentDefaultsStyle
        },
        editorFonts: {
          showDefaultFonts: false,
          customFonts: getBeeEditorFontList(),
        },
        onSave: (jsonFile, htmlFile) => {
          console.log('onSave');
          processSavePageTemplate(htmlFile, jsonFile);
        },
        onChange: (jsonFile, response) => {
          console.log('onChange')
        },
        contentDialog: {
          manageForm: {
            label: 'Edit form',
            handler: (resolve, reject, args) => {
              let data = null;
              setformHandler({reject : reject})
            
              if (isValidParam(args) && getArrayParam(args.layout).length > 0) {
                data = editFormFields(args);
              }
              dispatch(getAppDialog(true, constants.LANDING_PAGE_FORM_BUILDER_DIALOG, getLocalizedStrings().label.LANDING_PAGE.LANDING_PAGE_FORMS, (fields, isCancel) => {
                if(isCancel){
                  reject();
                }else{
                  resolve(getFormStructure(fields, 'add'))
                }
                setformHandler(null);
              }, data, null));
            }
          },
        }
      }
      var endpoint = constants.BEE_EDITOR_AUTH_API;
      var page;
      var payload = {
        client_id: constants.BEE_EDITOR_CLIENT_ID,
        client_secret: constants.BEE_EDITOR_CLIENT_SECRET_ID,
      };

      let templateObj = getStringParam(tempJson) != "" ? JSON.parse(tempJson) : getDefaultBeeEditorData();
      await beeTest.getToken(
        constants.BEE_EDITOR_CLIENT_ID,
        constants.BEE_EDITOR_CLIENT_SECRET_ID
        )
        .then((response)=>{
          setToken(response.access_token)
        })
        .then(() => {                 
            beeTest.start(beeConfig, templateObj)
              .then(instance =>{
                  setBee(instance)
              })
          }).catch((error) => console.error('error during iniziatialization --> ', error))
  }

  const editFormFields = (fieldStructure) => {
    let data = {};
    let selectedFormFields = [];
    let fieldItemList = [];
    let optInInfo = {};
    let formItems = {};
    let messageInfo = {};
    let redirectInfo = {};
    try {
      if (isValidParam(fieldStructure)) {
        let fields = fieldStructure.fields;
        getArrayParam(fieldStructure.layout).map(field => {
          if (fields[field].hasOwnProperty('attributes')) {
            let fieldAttributes = fields[field].attributes;
            if (fieldAttributes.class == 'sf-form-input') {
              let tiltle = getStringParam(fields[field].label).replace('*', '').trim();
              let obj = { name: fieldAttributes.name, label: tiltle, is_required_field: fieldAttributes.required };
              selectedFormFields.push(obj);
            } else if (fieldAttributes.class == 'sf-form-hidden-input' || fieldAttributes.class == 'sf-form-submit-button') {
              let obj = { name: fieldAttributes.name, label: fieldAttributes.title, value: fieldAttributes.value };
              fieldItemList.push(obj);
            } else if (fieldAttributes.class == 'sf-form-optin-input') {
              let label = getStringParam(fields[field].label).trim();
              optInInfo = { value: label, isChecked: fieldAttributes.checked };
            } else if (fieldAttributes.class == 'sf-form-hidden-message-input') {
              messageInfo = { ...messageInfo, [fieldAttributes.name]: fieldAttributes.value };
            } else if (fieldAttributes.class == 'sf-form-hidden-redirect-input') {
              redirectInfo = { ...redirectInfo, [fieldAttributes.name]: fieldAttributes.value };
            }
          }
        });
        formItems = { fieldItemList, optInInfo, messageInfo, redirectInfo };
        data = { selectedFormFields, formItems, type: constants.EDIT };
      }
    } catch (error) {
      console.error("Error in 'LandigPage.js -> editFormFields()':" + error);
    }
    return data;
  }

  const getFormStructure = (formFieldsData) => {
    let formStructure = {};
    let layout = [];
    let fields = getObjectParam(formFieldsData);
    let formFields = getArrayParam(fields.selectedFormFields);
    let formItems = getObjectParam(fields.formItems);
    let fieldItemList = getArrayParam(formItems.fieldItemList);
    let optInInfo = getObjectParam(formItems.optInInfo);
    let formTempFields = {};
    let hiddenFieldLabelObj = { workflow: 'Workflow', group: 'Group', source: 'Source', sales_rep: 'Sales_Rep', title: 'Thank_you_Message_Title', message: 'Thank_you_Message', button: 'Thank_you_Message_Button_Name', websiteValue: 'Website', externalWebsiteURL: 'External_Website_Url' };
    try {
      formFields.forEach(field => {
        let fieldType = 'text';
        let listValue = [];
        let fieldId = `sf-text-field-${field.name}`;
        if (field.field_type == 'memo') {
          fieldType = 'textarea';
        } else if (field.field_type == 'list') {
          fieldType = 'select';
          listValue = field.list_values.map(listValue => { return { "type": "option", "label": listValue.value, "value": listValue.value } });
          fieldId = `sf-list-field-${field.name}`;
        } else if (field.field_type == 'date' || field.field_type == 'datetime') {
          //fieldType = 'date';
          fieldId = `sf-${field.field_type}-field-${field.name}`;
        }
        let fieldObj = {
          "type": fieldType,
          "label": field.label + (field.is_required_field == true ? " *" : ""),
          "removeFromLayout": false,
          "canBeRemovedFromLayout": field.name == 'email' ? false : true,
          "attributes": {
            "required": field.is_required_field,
            "class": 'sf-form-input',
            "id": fieldId,
            "name": field.name,
            "title": field.label,
          }
        }
        if (field.field_type == 'list') {
          fieldObj.options = listValue;
        }
        formTempFields[field.name] = fieldObj;
        layout.push(new Array(field.name));
      });

      if (isValidParam(optInInfo) && optInInfo.isChecked) {
        let newslettersLabel = getStringParam(optInInfo.value) != '' ? getStringParam(optInInfo.value) : 'Subscribe to our newsletters';
        let fieldObj = {
          "type": "checkbox",
          "label": newslettersLabel,
          "canBeRemovedFromLayout": false,
          "attributes": {
            "class": 'sf-form-optin-input',
            "id": `sf-text-field-${optInInfo.name}`,
            "name": getStringParam(optInInfo.value),
            "checked": optInInfo.isChecked
          }
        }
        formTempFields['news_letter'] = fieldObj;
        layout.push(new Array('news_letter'));
      }

      let tempList = fieldItemList.filter(f => f.name == 'submit_button');
      let submitValue = 'Submit';
      if (tempList.length > 0) {
        submitValue = tempList[0].value;
      }
      formTempFields.submit_button = {
        "type": "submit",
        "label": "",
        "canBeRemovedFromLayout": false,
        "attributes": {
          "class": 'sf-form-submit-button',
          "value": tempList.length > 0 ? tempList[0].value : 'Submit',
          "name": "submit_button",
          "title": tempList.length > 0 ? tempList[0].label : '',
          "id": 'sf-form-submit',
        }
      }

      layout.push(new Array('submit_button'));

      fieldItemList.forEach(item => {
        if (item.name != 'submit_button') {
          let value = item.name == 'group' || item.name == 'workflow' ? item.value : item.label;
          let fieldObj = {
            "type": 'hidden',
            "label": '',
            "canBeRemovedFromLayout": false,
            "attributes": {
              "class": 'sf-form-hidden-input',
              "id": `sf-text-field-${item.name}`,
              "name": item.name,
              "title": item.label,
              "value": getStringParam(value)
            }
          }
          formTempFields[hiddenFieldLabelObj[item.name]] = fieldObj;
          layout.push(new Array(hiddenFieldLabelObj[item.name]));
        }
      });

      let messageInfo = getObjectParam(formItems.messageInfo);
      Object.keys(messageInfo).forEach(name => {
        let fieldObj = {
          "type": 'hidden',
          "label": '',
          "canBeRemovedFromLayout": false,
          "attributes": {
            "class": 'sf-form-hidden-message-input',
            "id": `sf-text-field-${name}`,
            "name": name,
            "value": getStringParam(messageInfo[name])
          }
        }
        formTempFields[hiddenFieldLabelObj[name]] = fieldObj;
        layout.push(new Array(hiddenFieldLabelObj[name]));
      });

      let redirectInfo = getObjectParam(formItems.redirectInfo);
      delete redirectInfo.URLList;
      Object.keys(redirectInfo).forEach(name => {
        let fieldObj = {
          "type": 'hidden',
          "label": "",
          "canBeRemovedFromLayout": false,
          "attributes": {
            "class": 'sf-form-hidden-redirect-input',
            "id": `sf-text-field-${name}`,
            "name": name,
            "value": getStringParam(redirectInfo[name])
          }
        }
        formTempFields[hiddenFieldLabelObj[name]] = fieldObj;
        layout.push(new Array(hiddenFieldLabelObj[name]));
      });

      let structure = {
        fields: formTempFields,
        layout,
        title: "Registration",
      }
      formStructure = { structure };
    } catch (error) {
      console.error("Error in 'LandigPage.js -> getFormStructure()':" + error);
    }
    return formStructure;
  }

  const savePageTemplate = () => {
    let params = {};
    var promise = null;
      let isValidate = validatePageForm();
      if (isValidate) {
        params = {
          url: template.url,
          name: template.name,
          id: template.id
        }
        promise = Promise.resolve(HTTPClient.get(endPoints.LANDING_PAGE.CHECK_DUPLICATE, params));
        if (isValidParam(promise)) {
          promise.then((response) => {
            if (isValidParam(response)) {
              if (response.isDuplicateName) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.LANDING_PAGE.DUPLICATE_NAME, null);
              } else if (response.isDuplicateURL) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.LANDING_PAGE.DUPLICATE_URL, null);
              } else {
                // processSavePageTemplate();
                bee.save();
              }
            }
          })
        }
      }
  }

  const processSavePageTemplate=(pageBody, pageJSON)=> {
    let params = {};
    var promise = null;
    try {
      setLoading(true);
      params = {
        ...template,
        pageBody: pageBody,
        pageJSON: pageJSON
      }
      var promise1 = Promise.resolve(HTTPClient.post(endPoints.LANDING_PAGE.SAVE, params));
      if (isValidParam(promise1)) {
        promise1.then((response) => {
          if (isValidParam(response)) {
            setLoading(false);
            let message = getLocalizedStrings().message.LANDING_PAGE.SAVE;
            setIsSaved(true);
            if (template.id > 0) {
              message = getLocalizedStrings().message.LANDING_PAGE.UPDATE;
            }
            dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
            refreshListView(props.object);
            if (template.id == 0) {
              let tempTemplate = template;
              tempTemplate.id = response.data;
              tempTemplate.status = 'Draft';
              setTemplate(tempTemplate);
            }
            setisInputValueChanged(false);

          }else{
            setIsSaved(false)
          }
        });
      }
    } catch (error) {
      console.error("Error in 'LandigPage.js -> processSavePageTemplate()':" + error);
    }
  }


  const validatePageForm = () => {
    let name = getStringParam(template.name).trim();
    let tempPostfixUrl = getStringParam(postfixUrl).trim();
    let url = getStringParam(template.url).trim();
    try {
      if (name == '') {
        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.LANDING_PAGE.INVALID_NAME, null);
        return false;
      } else if (tempPostfixUrl == '') {
        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.LANDING_PAGE.INVALID_URL, null);
        return false;
      }
      else {
        return true;
      }

    } catch (error) {
      console.error("Error in 'LandigPage.js -> savePageTemplate()':" + error);
    }
  }

  const closeDrawer = () => {
    dispatch(getAppDrawer(false, null, null, null, null));
  }

  const handlePreview = (name) => {
    try {
      if (isInputValueChanged) {
        dispatch(showCustomSnackBar(getLocalizedStrings().message.LANDING_PAGE.PREVIEW_ALERT_MSG, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        return false;
      }
      if (name == 'external') {
        window.open(template.url);
      } 
      else if (isValidParam(bee)) {
        bee.preview();
      }
    } catch (error) {
      console.error("Error in 'LandigPage.js -> handlePreview()':" + error);
    }
  }

  const handleMoreActionChange = (event, value) => {
    try {
      setmoreActionOpen(false);
      switch (value) {
        case 'Copy':
          let tempTemplate = template;
          tempTemplate.id = 0;
          tempTemplate.name = getLocalizedStrings().label.MARKETING_TEMPLATE.COPY_OF + '_' + tempTemplate.name;
          setTemplate(tempTemplate);
          setisCopy(true);
          dispatch(setAppDrawerTitle(getLocalizedStrings().label.LANDING_PAGE.COPY_LANDING_PAGE));
          break;
        case 'Preview':
          handlePreview();
          break;
        case constants.PUBLISHED:
          changeStatus(value);
          break;
        case constants.UNPUBLISHED:
          changeStatus(value);
          break;
        default:
      }
    } catch (error) {
      console.error("Error in 'LandigPage.js -> handleMoreActionChange()':" + error);
    }
  }

  const changeStatus = (value) => {
    let params = {};
    var promise = null;
    try {
      let status = value;
      params = {
        id: [template.id],
        status: status
      }
      promise = Promise.resolve(HTTPClient.post(endPoints.LANDING_PAGE.UPDATE_STATUS, params));
      if (isValidParam(promise)) {
        promise.then((response) => {
          if (isValidParam(response)) {
            let message = "";
            if (response.status == -1) {
              message = status == constants.PUBLISHED ? getLocalizedStrings().message.BATCH_ACTION_VALIDATION.ALREADY_PUBLISH : getLocalizedStrings().message.BATCH_ACTION_VALIDATION.ALREADY_UNPUBLISH;
              dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
              message = status == constants.PUBLISHED ? getLocalizedStrings().message.BATCH_ACTION.PUBLISH_SUCCESSFULL : getLocalizedStrings().message.BATCH_ACTION.UNPUBLISH_SUCCESSFULL;
              dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));

              dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
              refreshListView(props.object);
              let tempTemplate = template;
              tempTemplate.status = status;
              setTemplate({...tempTemplate});
              setDummyData(true);
            }
          }
        })
      }
    } catch (error) {
      console.error("Error in 'LandigPage.js -> changeStatus()':" + error);
    }
  }


  const handleChange = (event) => {
    try {
      const target = event.target;
      let value = getStringParam(target.value);
      let name = target.name;

      let tempTemplate = template;
      tempTemplate[name] = value;
      let tempValue = getStringParam(value).toLowerCase();
      tempValue = tempValue.replace(/\s+/g, '-')
      tempValue = tempValue.replace(/[^-0-9A-Za-z]+/ig, '')
      setPostfixUrl(tempValue);
      tempTemplate.url = prefixUrl + tempValue;
      setTemplate(tempTemplate);
      setisInputValueChanged(true);
    } catch (error) {
      console.error("Error in 'LandigPage.js -> handleChange()':" + error);
    }
  };

  const copyToClipboard = () => {
    try {
      if (isInputValueChanged) {
        dispatch(showCustomSnackBar(getLocalizedStrings().message.LANDING_PAGE.OPEN_URL_ALERT_MSG, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        return false;
      }
      copy(template.url);
      dispatch(showCustomSnackBar(getLocalizedStrings().message.LANDING_PAGE.COPY_TO_CLIPBOARD, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
    } catch (error) {
      console.error("Error in 'LandigPage.js -> copyToClipboard()':" + error);
    }

  }

  const openUrl = () => {
    try {
      if (isInputValueChanged) {
        dispatch(showCustomSnackBar(getLocalizedStrings().message.LANDING_PAGE.OPEN_URL_ALERT_MSG, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        return false;
      }
      window.open(template.url);
    } catch (error) {
      console.error("Error in 'LandigPage.js -> openUrl()':" + error);
    }
  }

  const handleActions = (name) => {
    try {
      if (name == 'save') {
        savePageTemplate();
      } else if (name == 'publish') {
        changeStatus(constants.PUBLISHED)
      } else if (name == 'unpublish') {
        changeStatus(constants.UNPUBLISHED)
      } else if (name == 'close') {
        closeDrawer();
      } else if (name == 'copy') {
        let tempTemplate = template;
        let name = tempTemplate.name;
        tempTemplate.id = 0;
        tempTemplate.name = getLocalizedStrings().label.MARKETING_TEMPLATE.COPY_OF + '_' + name;
        setisCopy(true);
        let tempValue = getStringParam(name).toLowerCase();
        tempValue = tempValue.replace(/\s+/g, '-')
        tempValue = tempValue.replace(/[^-0-9A-Za-z]+/ig, '');
        let postfixUrl = 'copy_of_' + tempValue;
        setPostfixUrl(postfixUrl)
        tempTemplate.url = prefixUrl + postfixUrl;
        setTemplate(tempTemplate);
        dispatch(setAppDrawerTitle(getLocalizedStrings().label.LANDING_PAGE.COPY_LANDING_PAGE));
      } else if (name == 'preview') {
        handlePreview();
      }
    } catch (error) {
      console.error("Error in 'LandigPage.js -> handleActions()':" + error);
    }
  }
  
  const generateActionButtons = () => {
    let elements = [];
    let _template = template;
    let items = [];
    try {
      items = [{ name: 'save', label: getLocalizedStrings().label.COMMON.SAVE, isPrimary: true }];
      if (_template.id > 0) {
        if (_template.status != constants.PUBLISHED) {
          items.push({ name: 'publish', label: getLocalizedStrings().label.COMMON.PUBLISH, isPrimary: true });
        } else {
          items.push({ name: 'unpublish', label: getLocalizedStrings().label.COMMON.UNPUBLISH, isPrimary: false });
        }
      }
      items.push({ name: 'close', label: getLocalizedStrings().label.COMMON.CLOSE, isPrimary: false });
      items.push({ name: 'copy', label: getLocalizedStrings().label.COMMON.COPY, isPrimary: false });
      items.push({ name: 'preview', label: getLocalizedStrings().label.COMMON.PREVIEW, isPrimary: false });
      elements = items.map(item => {
        let buttonStyle = item.isPrimary ? { ...styles.primaryButton, marginRight: '5px', verticalAlign: 'top' } : { ...styles.secondaryButton, verticalAlign: 'top' }
        return (
          <Button
            onClick={()=>handleActions(item.name)}

            style={buttonStyle}
            labelStyle={{ fontWeight: 'normal' }}
            disabled={loading}
          > {item.label}</Button>
        );
      });
    } catch (error) {
      console.error("Error in 'LandigPage.js -> generateActionButtons()':" + error);
    }
    return elements;
  }
    if (mouted) {
      let tempTemplate = template;
      return (
        <div style={{ padding: '0px 30px', marginBottom: '68px' }}>
          <div style={{ width: '100%' }}>
            <div style={{ height: '5px', paddingTop: '5px' }}>
              {loading &&
                <div style={{ textAlign: 'center' }}>
                  <span className="loader"><span className="loader-box"></span><span className="loader-box"></span><span className="loader-box"></span></span>
                </div>}
            </div>
            <div className="row" style={{ padding: '10px 0px' }}>
              <div className="col-sm-3">
                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                  <TextField
                    variant="outlined"
                    label={getLocalizedStrings().label.MARKETING_TEMPLATE.NAME_REQUIRED}
                    fullWidth={true}
                    autoFocus
                    name="name"
                    title={getStringParam(tempTemplate.name)}
                    value={getStringParam(tempTemplate.name)}
                    onChange={(event)=>handleChange(event)}
                    inputprops={{ maxlength: '64' }}
                    autoComplete="new-password"
                    margin='dense'
                    size='small'
                    className={"sf-fields-bg"}
                  />
                </FormControl>
              </div>
              <div className="col-sm-2" style={{ padding: '0px' }} >
                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                  <TextField
                    variant="outlined"
                    label={getLocalizedStrings().label.LANDING_PAGE.URL_REQUIRED}
                    fullWidth={true}
                    name="postfixUrl"
                    title={getStringParam(postfixUrl)}
                    value={getStringParam(postfixUrl)}
                    onChange={(event)=>handleChange(event)}
                    inputprops={{ maxlength: '64' }}
                    disabled={true}
                    autoComplete="new-password"
                    margin='dense'
                    size='small'
                    className={"sf-fields-bg"}
                  />
                </FormControl>
              </div>
              <div className="col-sm-1" style={{ padding: '0px' }}>
                {(tempTemplate.id > 0 && tempTemplate.status == constants.PUBLISHED) && <div style={{ display: 'flex', flexWrap: 'wrap', height: '42px',paddingTop:'7%' }}>
                  <span id={`external-url-id`} className='page-action' title={'Open published URL'} style={{ padding: '12px', cursor: 'pointer', borderRadius: '50%', margin: '0px 5px' }} onClick={()=>handlePreview('external')} ><i className='fas fa-external-link-alt' style={{ fontSize: '15px' }}></i></span>
                  <span id={`clipboard-id`} className='page-action' title={'Copy published URL to clipboard'} style={{ padding: '10px', cursor: 'pointer', borderRadius: '50%' }} onClick={()=>copyToClipboard(true, 'customerinfo')} ><i className="fas fa-clipboard-check" style={{ fontSize: '17px' }}></i></span>
                </div>}
              </div>
              <div className="col-sm-6" style={{ textAlign: 'right', marginTop: '5px' }}>
                <div>
                  {generateActionButtons()}
                </div>
              </div>
            </div>
            <div id='bee-plugin-container' style={{ border: '1px solid #cac7c7', margin: '10px 0px', height: '604px' }}></div>
          </div>
        </div>
      );
    } else {
      let contentHeight = window.innerHeight - 240;
      let top = (contentHeight - 10) / 2
      return (
          <div style={{ width: '100%', height: contentHeight }}>
              <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                  <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                      <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                  </div>
              </div>
          </div>
      );
    }
}

export default LandigPage;

var contentDefaultsStyle = {
  form: {
    structure: {},
    styles: {
      fontSize: "14px",
      //fontFamily: "Roboto",
    },
    labelsOptions: {
      color: "#000000",
      lineHeight: "200%",
      fontWeight: "",
      fontStyle: "",
      align: "left",
      position: "top"
    },
    fieldsOptions: {
      color: "#000000",
      backgroundColor: "#ffffff",
      outlineColor: "#3AAEE0",
      borderRadius: "4px",
      borderTop: "1px solid #3AAEE0",
      borderRight: "1px solid #3AAEE0",
      borderBottom: "1px solid #3AAEE0",
      borderLeft: "1px solid #3AAEE0",
      paddingBottom: "5px",
      paddingLeft: "5px",
      paddingRight: "5px",
      paddingTop: "5px",
    },
    buttonsOptions: {
      color: "#ffffff",
      backgroundColor: "#3AAEE0",
      borderRadius: "4px",
      borderTop: "0px solid transparent",
      borderRight: "0px solid transparent",
      borderBottom: "0px solid transparent",
      borderLeft: "0px solid transparent",
      lineHeight: "200%",
      align: "center",
      width: "100%",
      maxWidth: "100%",
      paddingBottom: "5px",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "5px",
      marginBottom: "0px",
      margingLeft: "0px",
      marginRight: "0px",
      marginTop: "15px",
    },
    blockOptions: {
      align: "center",
      paddingBottom: "10px",
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "10px",
      hideContentOnMobile: false,
      hideContentOnDesktop: false,
    }
  }
}


