import { actions } from '..//constants/actions';

function updateTasksReportData(tasksReportData) {
    return {
        type: actions.TASKS_REPORT_DATA_UPDATE,
        payload: tasksReportData
    }
}

function updateTasksReportWorkflows(taskReportWorkflows) {
    return {
        type: actions.TASKS_REPORT_WORKFLOW_LIST_UPDATE,
        payload: taskReportWorkflows
    }
}

export {
updateTasksReportData,
updateTasksReportWorkflows
}