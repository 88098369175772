import isNull from 'lodash/isNull';
import isNaN from 'lodash/isNaN';
import isUndefined from 'lodash/isUndefined';
import isBoolean from 'lodash/isBoolean';
import isArray from 'lodash/isArray';

/**
 * 
 * @Author  : Pradyut.s 
 * @Date:   : 2017-07-10
 */
export const EMPTY_STRING = '';
export const DEFAULT_INTEGER = 0;
export const DEFAULT_FLOAT = 0.00;
const DEFAULT_FLOAT_PRECISION = 2;

function isValidParam(param) {
    let isValid = false;
    try {
        if (!isNull(param) && !isNaN(param) && !isUndefined(param)) {
            isValid = true;
        }
    } catch (error) {
        console.error("Error in 'parameterVerifier.js -> isValidParam()':" + error);
    }
    return isValid;
}

function isInvalidParam(param) {
    let isInvalid = false;
    try {
        if (isNull(param) || isNaN(param) || isUndefined(param) || param === EMPTY_STRING) {
            isInvalid = true;
        }
    } catch (error) {
        console.error("Error in 'parameterVerifier.js -> isInvalidParam()':" + error);
    }
    return isInvalid;
}

function makeValidAppUrl(url) {
    try {
        if (isValidParam(url)) {
            url = url.toLowerCase();
            url = url.split('_').join('-');
        } else {
            url = '';
        }
    } catch (error) {
        url = '';
        console.error("Error in 'parameterVerifier.js -> makeValidAppUrl()':" + error);
    }
    return url;
}

/* eslint-disable no-useless-escape */
function isEmail(strEmail) {
    let isValid = true;
    try {
        if (isValidParam(strEmail) && strEmail.length > 0) {
            let arrEmail = strEmail.split(',');
            arrEmail.forEach( email => {
                email = email.trim()
                if(isValid && email !== ''){
                    var emailregstr = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,})+$/;
                    var result = -1;
                    result = email.search(emailregstr);
                    if (result === -1) {
                        isValid = false;
                        return;
                    }
                }                
            });       
        }
    } catch (error) {
        console.error("Error in 'parameterVerifier.js -> isEmail()':" + error);
    }
    return isValid;
}

 /*--check only one email [Rabi-(06-04-2020)]-- */
function isValidEmail(strEmail) {
    let isValid = true;
    try {
        if (isValidParam(strEmail)) {
            let email = strEmail.trim()
            if (email !== '') {
                var emailregstr = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,})+$/;
                var result = -1;
                result = email.search(emailregstr);
                if (result === -1) {
                    isValid = false;
                }
            }
        }
    } catch (error) {
        console.error("Error in 'parameterVerifier.js -> isValidEmail()':" + error);
    }
    return isValid;
}

function isUrl(url) {
    let isValid = false;
    try {
        url = url.toLowerCase();
        if (isValidParam(url)) {
            var urlregstr = /((ht|f)tp(s?)\:\/\/|~\/|\/)?(\w+:\w+@)?(([-\w]+\.)+(com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum|travel|[a-z]{2}))(:[\d]{1,5})?(((\/([-\w~!$+|.,=]|%[a-f\d]{2})+)+|\/)+|\?|#)?((\?([-\w~!$+|.,*:]|%[a-f\d{2}])+=([-\w~!$+|.,*:=]|%[a-f\d]{2})*)(&([-\w~!$+|.,*:]|%[a-f\d{2}])+=([-\w~!$+|.,*:=]|%[a-f\d]{2})*)*)*(#([-\w~!$+|.,*:=]|%[a-f\d]{2})*)?/;
            var result = -1;
            result = url.search(urlregstr);
            if (result !== -1) {
                isValid = true;
            }
        }
    } catch (error) {
        console.error("Error in 'parameterVerifier.js -> isUrl()':" + error);
    }
    return isValid;
}

function isFloat(value) {
    let isValid = false;
    try {
        if (isValidParam(value)) {
            var float = value.toString();
            var floatregstr = /^[0-9\.]+$/;
            floatregstr = /^\d{0,8}(\.\d{1,2})?$/;
            floatregstr = /^-?\d+(?:[.]\d*?)?$/;
            var result = -1;
            result = float.search(floatregstr);
            if (result !== -1 && float !== ".") {
                isValid = true;
            }
        }
    } catch (error) {
        console.error("Error in 'parameterVerifier.js -> isFloat()':" + error);
    }
    return isValid;
}

function isEmpty(value) {
    let isValid = false;
    try {
        if (isValidParam(value) && value === EMPTY_STRING) {
            isValid = true;
        }
    } catch (error) {
        console.error("Error in 'parameterVerifier.js -> isEmpty()':" + error);
    }
    return isValid;
}

function getStringParam(value) {
    let strValue = EMPTY_STRING;
    try {
        if (isValidParam(value)) {
            return String(value);
        }
    } catch (error) {
        console.error("Error in 'parameterVerifier.js -> getStringParam()':" + error);
    }
    return strValue;
}

function getIntParam(value) {
    let intValue = DEFAULT_INTEGER;
    try {
        if (isInteger(value)) {
            value = parseInt(value);
            return value;
        }
    } catch (error) {
        console.error("Error in 'parameterVerifier.js -> getIntParam()':" + error);
    }
    return intValue;
}

function getFloatParam(value, precision) {
    let floatValue = DEFAULT_FLOAT;
    let defaultPrecision = DEFAULT_FLOAT_PRECISION;
    try {
        if (isValidParam(precision) && isInteger(precision)) {
            defaultPrecision = precision;
        }
        if (isFloat(value)) {
            value = parseFloat(value);
            value = value.toFixed(defaultPrecision);
            return value;
        }
    } catch (error) {
        console.error("Error in 'parameterVerifier.js -> getFloatParam()':" + error);
    }
    return floatValue;
}

function getObjectParam(value) {
    let object = {};
    try {
        if (isValidParam(value)) {
            return value;
        }
    } catch (error) {
        console.error("Error in 'parameterVerifier.js -> getObjectParam()':" + error);
    }
    return object;
}

function getBooleanParam(value) {
    let booleanValue = false;
    try {
        if (isValidParam(value) && isBoolean(value)) {
            return value;
        }
    } catch (error) {
        console.error("Error in 'parameterVerifier.js -> getBooleanParam()':" + error);
    }
    return booleanValue;
}

function getArrayParam(value) {
    let object = [];
    try {
        if (isValidParam(value) && isArray(value)) {
            return value;
        }
    } catch (error) {
        console.error("Error in 'parameterVerifier.js -> getArrayParam()':" + error);
    }
    return object;
}

function isInteger(int) {
    var validInt = false;
    if (isValidParam(int)) {
        int = int.toString();
        var intregstr = /^[-]?[0-9]+$/;
        var result = -1;
        result = int.search(intregstr);
        if (int == '-1' || result != "-1") {
            validInt = true;
        }
    }
    return validInt;
};

function isPositiveInteger(int) {
    var validInt = false;
    if (isValidParam(int)) {
        int = int.toString();
        var intregstr =  /(^[\d+]$)|(^\d+,?\d{1,2}$)/;
        var result = -1;
        result = int.search(intregstr);
        if (int === '-1' || result !== "-1") {
            validInt = true;
        }
    }
    return validInt;
};

function getSetParam(value) {
    let object = new Set();
    try {
        if (isValidParam(value)) {
            return value;
        }
    } catch (error) {
        console.error("Error in 'parameterVerifier.js -> getSetParam()':" + error);
    }
    return object;
}

function isString(value) {
    return isValidParam(value) && (typeof value === 'string' || value instanceof String);
}

function makeValidObjectName(objectName) {
    try {
        objectName = getStringParam(objectName);
        objectName = objectName.toLowerCase();
        objectName = objectName.split('_').join('-');
    } catch (error) {
        console.error("Error in 'parameterVerifier.js -> makeValidObjectName()':" + error);
        objectName = '';
    }
    return objectName;
}

export {
    isValidParam,
    makeValidAppUrl,
    isEmail,
    isUrl,
    isInteger,
    isFloat,
    isEmpty,
    getStringParam,
    getIntParam,
    getFloatParam,
    getObjectParam,
    getBooleanParam,
    getArrayParam,
    isInvalidParam,
    getSetParam,
    isString,
    makeValidObjectName,
    isValidEmail,
    isPositiveInteger
}