import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField,Icon } from '@mui/material';
import {
    isValidParam,
    getStringParam,
    getArrayParam,
    getObjectParam,
    getBooleanParam,
    getIntParam
} from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getMultiLingualLabel } from '../../../services/helper/common';
/**
 * 
 * @Author  : Pradyut.s 
 * @Date:   : 2018-07-20
 */
export default class SFAutoComplete extends React.Component {

    constructor() {
        super();
        this.state = {
            isOpenPopover: false,
            value: '',
            menuItemsLength: 0,
            isValueUpdated: false,
            isArrowPress: false,
            isMouseHover: false,
            defaultSelectedIndex: -1,
            isUpArrowPress: false
        };
    }

    componentDidMount() {
        this.setDefaultSelectedIndex(this.props.value);
    }

    setDefaultSelectedIndex = (value) => {
        try {
            value = getStringParam(value).toLowerCase();
            let values = this.getValues();
            let index = values.findIndex((obj) => {
                return obj.value.toLowerCase().indexOf(value) > -1;
            });
            this.state.defaultSelectedIndex = index;
        } catch (error) {
            console.error("Error in 'sfAutoComplete.js -> setDefaultSelectedIndex()':" + error);
        }
    }

    openPopOver = (event) => {
        try {
            let disabled = getBooleanParam(this.props.disabled);
            if (!this.state.isOpenPopover && !disabled) {
                this.state.value = '';
                this.state.isOpenPopover = true;
                this.setDefaultSelectedIndex(this.props.value);

                let isAdvanceSearch = getBooleanParam(this.props.isAdvanceSearch);
                if (!isAdvanceSearch) {
                    this.setState(this.state);
                }
            }
        } catch (error) {
            console.error("Error in 'sfAutoComplete.js -> openPopOver()':" + error);
        }
    }

    closePopOver = () => {
        this.setState({ isOpenPopover: false, isUpArrowPress: false });
    }

    componentDidUpdate() {
        try {
            let containerelement = document.getElementById(this.props.id + this.props.name);
            let elementStyle = isValidParam(containerelement) ? containerelement.style : null;

            if (elementStyle !== null) {
                if (this.state.isOpenPopover && isValidParam(this.props.id)) {
                    this.focus();

                    elementStyle.display = 'block';
                    elementStyle.zIndex = '1000';
                    elementStyle.position = 'relative';

                    let element = document.getElementById(this.getValueBasedOnField());
                    if (element !== null) {
                        element.style.color = '#9e9e9e';
                    }
                } else if (!this.state.isOpenPopover && elementStyle.display === 'block') {
                    elementStyle.display = 'none';
                }
            }

        } catch (error) {
            console.error("Error in 'sfAutoComplete.js -> componentDidUpdate()':" + error);
        }
    }

    getValueBasedOnField = () => {
        let value = null;
        try {
            value = getStringParam(this.props.value);
            if (this.props.name === 'workflow_name') {
                let values = this.getValues();
                values = values.filter(f => { return f.value === value });
                if (values.length > 0) {
                    value = values[0].id;
                }
            }
        } catch (error) {
            console.error("Error in 'sfAutoComplete.js -> getValueBasedOnField()':" + error);
        }
        return value;
    }

    focus = () => {
        try {
            let vv = document.getElementsByName(this.props.name);
            vv[0].focus();
            this.state.isValueUpdated = false;
        } catch (error) {
            console.error("Error in 'sfAutoComplete.js -> focus()':" + error);
        }
    }

    focusOut = () => {
        try {
            let vv = document.getElementsByName(this.props.name);
            vv[0].blur();
        } catch (error) {
            console.error("Error in 'sfAutoComplete.js -> focusOut()':" + error);
        }
    }

    textFielOnBlur = (event, value) => {
        try {
            if (!this.state.isUpArrowPress) {
                if (this.state.isMouseHover) {
                    //by pass...
                } else if (this.state.isArrowPress) {
                    this.state.isArrowPress = false;
                } else if (!this.state.isValueUpdated) {
                    this.setState({ isOpenPopover: false });
                } else if (getStringParam(event.target.value) === '') {
                    this.focus();
                } else if (this.state.menuItemsLength === 0) {
                    this.setState({ isOpenPopover: false, isValueUpdated: false });
                } else if (event.target.value !== this.props.value) {
                    this.focus();
                } else if (this.props.hasOwnProperty('onBlur') && typeof this.props.onBlur === 'function') {
                    this.props.onBlur(event, value);
                }
            }

        } catch (error) {
            console.error("Error in 'sfAutoComplete.js -> textFielOnBlur()':" + error);
        }
    }

    onChange = (event) => {
        try {
            let target = event.target;
            let value = target.value;
            this.state.value = value;
            this.setDefaultSelectedIndex(value);
            this.setState({ isValueUpdated: true, isOpenPopover: true });
        } catch (error) {
            console.error("Error in 'sfAutoComplete.js -> onChange()':" + error);
        }
    }

    onChangeMenu = (event, value) => {
        if (event !== null && event !== undefined && value !== null && value !== undefined) {
            this.props.onChange(event, value);
        }
    }

    onKeyUp = (event) => {
        try {
            if (event.keyCode === '9') {
                let isAdvanceSearch = getBooleanParam(this.props.isAdvanceSearch);
                if (isAdvanceSearch) {
                    this.setState({});// advance search popover open
                }
            }
            if (event.keyCode === '13') {
                let isAdvanceSearch = getBooleanParam(this.props.isAdvanceSearch);
                if (isAdvanceSearch) {
                    this.props.onKeyUp(event);
                }
            }
        } catch (error) {
            console.error("Error in 'sfAutoComplete.js -> onKeyUp()':" + error);
        }
    }

    onKeyDown = (event) => {
        try {
            if (event.keyCode === '9') {
                //by pass...
            } else if (event.keyCode === '40') {//Down Arrow Key
                if (this.state.defaultSelectedIndex === -1) {
                    this.setDefaultSelectedIndex(this.props.value);
                }

                this.state.defaultSelectedIndex++;

                this.setState({ isOpenPopover: true, isArrowPress: true, isMouseHover: false });
            } else if (event.keyCode === '38') {//Upper Arrow Key
                if (this.state.defaultSelectedIndex === -1) {
                    this.setDefaultSelectedIndex(this.props.value);
                }

                if (this.state.defaultSelectedIndex > 0) {
                    this.state.defaultSelectedIndex--;
                } else {
                    let length = getIntParam(this.state.menuItemsLength);
                    this.state.defaultSelectedIndex = length - 1;
                }

                this.setState({ isOpenPopover: true, isArrowPress: true, isMouseHover: false });
            } else if (event.keyCode === '13') {//Enter Key
                let value = this.getItemValueByIndex();
                this.setState({ isOpenPopover: false, isArrowPress: false, isMouseHover: false });
                this.props.onChange(event, value);
            } else if (event.keyCode === '8') {//Backspace key
                //by pass...
            }
        } catch (error) {
            console.error("Error in 'sfAutoComplete.js -> onKeyDown()':" + error);
        }
    }

    getItemValueByIndex = (isMenu) => {
        let value = '';
        try {
            isMenu = getBooleanParam(isMenu);
            let isModifyList = getBooleanParam(this.props.isModifyList);
            let values = this.getValues();
            if (values.length > 0) {
                let length = getIntParam(this.state.menuItemsLength);
                let selectedIndex = parseInt(this.state.defaultSelectedIndex);
                selectedIndex = selectedIndex < length && selectedIndex >= 0 ? selectedIndex : -1;
                this.state.defaultSelectedIndex = selectedIndex >= 0 ? selectedIndex : 0;

                if ((isMenu && this.props.name === 'workflow_name') || (isModifyList && selectedIndex === values.length - 1)) {
                    value = selectedIndex >= 0 ? values[selectedIndex].id : values[0].id;
                } else {
                    value = selectedIndex >= 0 ? values[selectedIndex].value : values[0].value;
                }
            }
        } catch (error) {
            console.error("Error in 'sfAutoComplete.js -> getItemValueByIndex()':" + error);
        }
        return value;
    }

    getValues = () => {
        let values = [];
        try {
            let value = this.getValue();
            let menuItems = getArrayParam(this.props.menuItems);
            menuItems.forEach(e => {
                values.push({ id: e.props.id, value: e.props.value });
            });

            if (values.length === 0) {
                menuItems = getArrayParam(this.props.itemValues);
                menuItems.forEach(e => {
                    values.push({ id: e.label, value: e.label });
                });
            }

            if (value !== '') {
                let tempValue = value?.toLowerCase();
                values = values.filter(f => { return f.value.toLowerCase().indexOf(tempValue) === 0 });
            }
        } catch (error) {
            console.error("Error in 'sfAutoComplete.js -> getValues()':" + error);
        }
        return values;
    }

    getMenuItems = () => {
        let menuItems = [];
        try {
            let values = this.getValues();
            menuItems = values.map((e, i) => {
                let primaryTextVal = getStringParam(e.value);
                primaryTextVal = getMultiLingualLabel(primaryTextVal);
                if (primaryTextVal === '') {
                    primaryTextVal = isValidParam(getLocalizedStrings().label.DEFAULT_NOTE_TYPES[e.value]) ? getLocalizedStrings().label.DEFAULT_NOTE_TYPES[e.value] : e.value;
                }
                return { value: primaryTextVal, id: e.id }
            });
        } catch (error) {
            console.error("Error in 'sfAutoComplete.js -> getMenuItems()':" + error);
        }
        return menuItems
    }

    getValue = () => {
        if (this.state.isOpenPopover) {
            return this.state.value;
        }
        return this.props.value;
    }

    getPopoverClasses = () => {
        let className = 'form-dropdown-menu-container';
        try {
            let popoverClasses = getArrayParam(this.props.popoverClasses);
            popoverClasses.forEach(val => {
                val = getStringParam(val);
                className = (className + ' ' + val).trim();
            });
        } catch (error) {
            console.error("Error in 'sfAutoComplete.js -> getPopoverClasses()':" + error);
        }
        return className;
    }

    getNotchElement = () => {
        let element = null;
        let props = {};
        let elementVal = '';
        try {
            let disabled = getBooleanParam(this.props.disabled);
            let iconStyle = { color: '#717171', cursor: disabled ? 'not-allowed' : 'pointer', float: 'right', marginTop: '-33px', position: 'relative' };

            if (this.state.isOpenPopover) {
                elementVal = 'keyboard_arrow_up';
                props.id = 'icon-arrow-up-' + this.props.id;
                props.onMouseDown = () => {
                    this.state.isUpArrowPress = true;
                    setTimeout(() => {
                        this.closePopOver();
                    }, 500);
                }
            } else {
                elementVal = 'keyboard_arrow_down';
                props.id = 'icon-arrow-down-' + this.props.name;
                props.onClick = this.openPopOver;
            }
            element = <Icon style={iconStyle} {...props}>{elementVal}</Icon>;
        } catch (error) {
            console.error("Error in 'sfAutoComplete.js -> getNotchElement()':" + error);
        }
        return element;
    }

    render() {
        let isFullHeight = getBooleanParam(this.props.isFullHeight);
        let menuItems = this.getMenuItems();
        this.state.menuItemsLength = getArrayParam(menuItems).length;
        let disabled = getBooleanParam(this.props.disabled);

        let containerStyle = { ...getObjectParam(this.props.style) }
        delete containerStyle.border;
        let style = { ...getObjectParam(this.props.style), paddingRight: '22px' };
        delete style.marginBottom;
        if (isFullHeight) {
            delete containerStyle.height;
        }
        let isShowMarginBottom = this.props.hasOwnProperty("isShowMarginBottom") ? this.props.isShowMarginBottom : true;
        let marginBottom = '25px';
        if(!isShowMarginBottom){
            marginBottom = '0px';
        }

        return (
            <div id={'field-container-' + this.props.id} key={'field-container-' + this.props.id} style={containerStyle}>

                <Autocomplete
                    style={{ width: '100%', marginBottom: marginBottom }}
                    disableClearable
                    options={isValidParam(menuItems) ? menuItems.map((option) => option.value) : [].map((option) => option.value)}
                    onOpen={() => { this.setState({ isOpenPopover: true }) }}
                    onClose={() => { this.setState({ isOpenPopover: false }) }}
                    value={this.getValue()}
                    disabled={disabled}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={getStringParam(this.props.label)}
                            InputLabelProps={{
                                style: {
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '96%',
                                }
                            }}
                            variant="outlined"
                            inputprops={{ ...params.InputProps }}
                            disabled={disabled}
                            fullWidth={true}
                            margin='dense'
                            className={"sf-fields-bg"}
                            size="small"
                        />
                    )}
                    onInputChange={(e, value) => this.onChangeMenu(e, value)}
                />
            </div>
        );
    }
}

SFAutoComplete.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    isLookup: PropTypes.bool,
    maxLength: PropTypes.number,
    popoverClasses: PropTypes.array,
    isModifyList: PropTypes.bool,

    floatingLabelText: PropTypes.string,
    menuItems: PropTypes.array,
    disabled: PropTypes.bool,
    isFullHeight: PropTypes.bool,   //Text field with menu items.

    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,

    underlineFocusStyle: PropTypes.object,
    floatingLabelStyle: PropTypes.object,
    inputStyle: PropTypes.object,
    style: PropTypes.object,
};

