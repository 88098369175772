import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { getCookie, setCookie, deleteCookie } from '../../../services/helper/sfCookies';
import { getUserListPromise } from '../../../services/actions/UserListAction';
import { styles } from '../../../services/constants/styles';
import map from 'lodash/map';
import filter from 'lodash/filter';
import ShowCircularProgress from '../components/circularProgress';
import { setHeader } from '../../../services/actions/headerActions';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { getAppDrawer, getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import { isValidParam, getObjectParam, getStringParam, getArrayParam, getIntParam } from '../../../services/helper/parameterVerifier';
import { hasAccessPermission } from '../../../services/helper/common';
import * as sfDialogs from '../components/sfDialogs';
import { getSFFormFieldsPromise, saveRecordPromise, getEndPointObject } from '../../../services/actions/sfFormActions';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { updateAppointmentStoreByDragandDrop, getCalendarRefresh, setCalendarInfo, refreshCalendarForBroadcast, formatDate, getDateRange, selectedDateRangeUpdate, getAllAppointments, getAllBroadcasts, setSelectedDate } from '../../../services/actions/calendarActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { addTab, TYPE_DETAIL_VIEW, getActiveTab } from '../../../services/helper/sfTabManager';
import { dateTimeFormat, timeZone, dateFormat, timeFormat, sortArrayObjectByProperty } from '../../../services/helper/utils';
import { LinkToType } from '../../../services/constants/link';
import { getAllCampaignTypes } from '../../../services/actions/campaignTypeActions';
import { Day, Week, WorkWeek, Month, Agenda, ScheduleComponent, ViewsDirective, ViewDirective, Inject, Resize, DragAndDrop } from '@syncfusion/ej2-react-schedule';

import find from 'lodash/find';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { IconButton, ListItemIcon, Checkbox } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { getTasksRefresh, getUnitTasksRefresh, getTasksPortal } from "../../../services/actions/taskActions";
const Calendar = ({ type, object ,getAllTaskView}) => {

    let scheduleObj;
    const app = useSelector((state) => state.app);
    const calendar = useSelector((state) => state.calendar);
    const userlist = useSelector((state) => state.userlist);
    const sfForm = useSelector((state) => state.sfForm);
    const campaignTypes = useSelector((state) => state.campaignTypes);
    const tab = useSelector((state) => state.tab);
    const dispatch = useDispatch();
    const [calendarViewControl, setCalendarViewControl] = useState({ left: '50px', leftMin: true });
    const [selected_user, setSelected_user] = useState([]);
    const [selected_show, setSelected_show] = useState([{ value: 'Scheduled', label: getLocalizedStrings().label.CALENDAR.SHOW_OPTION_3 }]);
    const [getAllAppointmentsTypesCall, setGetAllAppointmentsTypesCall] = useState(false);
    const [updateAllAppointmentsCall, setUpdateAllAppointmentsCall] = useState(false);
    const [getAllAppointmentsfromNavigate, setGetAllAppointmentsfromNavigate] = useState(false);
    const [showOptions, setShowOptions] = useState([
        { value: 'Cancelled', label: getLocalizedStrings().label.CALENDAR.SHOW_OPTION_1 },
        { value: 'Completed', label: getLocalizedStrings().label.CALENDAR.SHOW_OPTION_2 },
        { value: 'Scheduled', label: getLocalizedStrings().label.CALENDAR.SHOW_OPTION_3 }
    ]);
    const [currentView, setCurrentView] = useState("");
    const [events, setevents] = useState([]);
    const [appTypes, setAppTypes] = useState([]);
    const [workDays, setWorkDays] = useState([1, 2, 3, 4, 5]);
    const [selectedDate, _setSelectedDate] = useState(calendar.selectedDate);
    const [userList, setUserList] = useState([]);
    const [superSelectUserlist, setSuperSelectUserlist] = useState([]);
    const [startWkHr, setStartWkHr] = useState('9:00');
    const [endWkHr, setEndWkHr] = useState('17:00');
    const [finalSelectedUserList, setFinalSelectedUserList] = useState([]);
    const [finalUserList, setFinalUserList] = useState([]);
    const [alreadyMounted, setAlreadyMounted] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [changelog, setChangeLog] = useState({ isRefresh: false, selectedDateRange: null });

    useEffect(() => {
        wrkTimeSet();
        userListUpdate();
        setCalendarViewMode();
        setHeader(type);
        if (!userList.length && !userlist.data.length) {
            let userArr = [];
            let promise = getUserListPromise()
            promise.then((response) => {
                map(response.users, function (userObject, i) {
                    let userObj = {};
                    userObj.value = userObject.name;
                    userObj.email = userObject.email;
                    userObj.phone = userObject.phone;
                    userObj.text = userObject.name;
                    userObj.id = userObject.id;
                    userObj.colorCode = userObject.colorCode;
                    userObj.profile_image_url = userObject.profile_image_url;
                    userArr.push(userObj);
                });
                userArr = userArr.sort(function (a, b) { return (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0); });
                setUserList(userArr)
                if (!alreadyMounted && !updateAllAppointmentsCall && !getAllAppointmentsTypesCall) {
                    setAlreadyMounted(true)
                    getAndUpdateCalendarData('DidMount', userArr)
                }
            })
        } else {
            if (!alreadyMounted && !updateAllAppointmentsCall && !getAllAppointmentsTypesCall) {
                setAlreadyMounted(true)
                getAndUpdateCalendarData('DidMount')
            }
        }

        if (app.selectedModuleName === constants.MODULE_MARKETING) {
            getAllCampaignTypesFn();
        }
    }, [calendarViewControl])

    useEffect(() => {
        let isRefresh = calendar.isrefresh;
        finalSelectedUserUpdate();
        setCalendarViewMode();
        if (!isRefresh && calendar.calendarInfo.appTypes) {
            if (calendar.calendarInfo.appTypes !== undefined) {
                setAppTypes(calendar.calendarInfo.appTypes);
            }
        }

        if (app.selectedModuleName !== app.lastSelectedModuleName) {
            isRefresh = true;
            calendar.isrefresh = true;
        }
        // if (alreadyMounted) {
        //     setIsRefresh(isRefresh);
        //     let log = changelog;
        //     log.isRefresh = isRefresh;
        //     setChangeLog({...log});
        // }
        if (getStringParam(app.me.language) !== getStringParam(app.me.language)) {
            let label = getLocalizedStrings().label.CALENDAR.CALENDAR_TASKS;
            let kanbanObject = {};
            kanbanObject.currentView = 'calendarOrTask';
            kanbanObject.show = false;
            dispatch(setHeader(object, label, kanbanObject, true, true));
        }
        if (type) {
            setHeaderfn(type);
        }
    }, [calendar.calendarInfo, type, app.selectedModuleName]);

    useEffect(() => {
        setCurrentView(calendar.calendarInfo.calendarView);
    }, [calendar.calendarInfo]);

    useEffect(() => {
        let data = app.me.calendar_setup;
        if (userList.length <= 0 && userlist.data.length > 0) {
            userListUpdate();
        }
        if (alreadyMounted && calendar.isrefresh ) {
            dispatch(getCalendarRefresh(false));
            getAndUpdateCalendarData('DidUpdate');
            setCalendarViewMode()
        }
        if (data.length > 0) {
            let endTime = data[0].endHour + ':00';
            let startTime = data[0].startHour + ':00';
            if (endWkHr !== endTime || startWkHr !== startTime) {
                wrkTimeSet();
            }

        }
        let isLogin = getStringParam(localStorage.getItem('isLogin'));
        
        if (getStringParam(localStorage.getItem('token')) !== '') {
            let tabs = getArrayParam(calendar.tabs);
            if (tabs.length > 0 && getArrayParam(app.objectList).length > 0) {
                let userId = (app.me.id * 19000) + 7;
                let projectId = (app.me.projectId * 19000) + 7;
                let cookieName = 'link_click_from_email-' + projectId + '-' + userId;
                let params = getCookie(cookieName);
                params = JSON.parse(params);
                if (params !== null && params.suffix === 'link-click') {
                    let label = null;
                    if (params.object === constants.APPOINTMENTS) {
                        label = getLocalizedStrings().label.COMMON.EDIT + " " + getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENT;
                        dispatch(getAppDrawer(true, label, LinkToType.TYPE_EDIT, { id: getIntParam(params.id), object: params.object }, null));
                    } else if (params.object === constants.TASKS) {
                        label = getLocalizedStrings().label.COMMON.EDIT + " " + getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK;
                        dispatch(getAppDrawer(true, label, LinkToType.TYPE_EDIT, { id: getIntParam(params.id), object: params.object }, null));
                    } else {
                        let tab = {
                            item: tabs[0].item,
                            object: params.object,
                            label: params.title,
                            type: TYPE_DETAIL_VIEW,
                            url: '/' + params.object + '/detailview/' + params.id
                        }
                        addTab(tab, true);
                    }
                    deleteCookie(cookieName);
                }
            }
        }
    }, [app.me, calendar])

    const finalSelectedUserUpdate = () => {
        let mode = app.selectedModuleName
        let finalSelectedUser = [];
        finalSelectedUser.push({ value: app.me.id, label: app.me.name, color: 'FE5722' });
        if (mode === 'Sales' || mode === 'Service') {

            if (calendar.calendarInfo !== null && calendar.calendarInfo !== undefined) {
                let selectedUser = filter(selected_user, function (userobj, i) {
                    return userobj.value !== -8888;
                });
                setSelected_user(selectedUser);
            }
        }
        if (mode === 'Marketing') {

            finalSelectedUser.push({ value: -8888, label: 'Campaigns' })
            setSelected_user(finalSelectedUser);
        }
    }

    const setCalendarViewMode = () => {
        let userId = (app.me.id * 19000) + 7;
        let projectId = (app.me.projectId * 19000) + 7;
        let cookieName = "calendarMin-left" + projectId + '-' + userId;
        let view = JSON.parse(getCookie(cookieName));
        if (view !== null) {
            let tempCalendarViewControl = calendarViewControl;
            tempCalendarViewControl.left = view ? 50 : '20%';
            tempCalendarViewControl.leftMin = view;
        }
    }

    const getAndUpdateCalendarData = (callFrom, userslst) => {
        let userslist = getArrayParam(userList.length > 0 ? userList : userlist.data.length > 0 ? userlist.data : userslst);
        let currentView = "week";
        let finalSelectedStatus = [{ value: 'Scheduled', label: getLocalizedStrings().label.CALENDAR.SHOW_OPTION_3 }];
        let finalSelectedUser = [];
        finalSelectedUser.push({ value: app.me.id, label: app.me.name, color: 'FE5722' });
        if (app.selectedModuleName === 'Marketing') {
            finalSelectedUser.push({ value: -8888, label: 'Campaigns' })
        }
        let calendarInfoFromRedux = getObjectParam(calendar.calendarInfo);
        if (calendarInfoFromRedux !== null && calendarInfoFromRedux !== '' && calendarInfoFromRedux.hasOwnProperty('salesAssign') && calendarInfoFromRedux.hasOwnProperty('status') && calendarInfoFromRedux.hasOwnProperty('calendarView')) {
            currentView = calendarInfoFromRedux.calendarView;
            finalSelectedUser = calendarInfoFromRedux.salesAssign;
            finalSelectedStatus = calendarInfoFromRedux.status;
            let appTypes = calendarInfoFromRedux.appTypes;
            setSelected_user(calendarInfoFromRedux.salesAssign);
            setSelected_show(calendarInfoFromRedux.status);
            setCurrentView(calendarInfoFromRedux.calendarView);
            setAppTypes(calendarInfoFromRedux.appTypes);
            prepareSearchArray(userslist, finalSelectedUser, appTypes, finalSelectedStatus, callFrom);
        } else {

            if (!getAllAppointmentsTypesCall) {
                setGetAllAppointmentsTypesCall(true);
                getAllAppointmentsTypes(finalSelectedUser, finalSelectedStatus, currentView, userslist);
            }

        }
        setSelected_user(finalSelectedUser);

    }

    const getAllAppointmentsTypes = (finalSelectedUser, finalSelectedStatus, currentView, userslst) => {
        let userId = (app.me.id * 19000) + 7;
        let projectId = (app.me.projectId * 19000) + 7;
        let userCookieName = "selected-user" + projectId + '-' + userId;
        let responseUserArr = [];
        let selectedUserArr = [];
        let calendarInfoFromRedux = getObjectParam(calendar.calendarInfo);
        if (!calendarInfoFromRedux.hasOwnProperty('appTypes') && appTypes.length === 0) {
            let param = {};
            param.object = constants.APPOINTMENTS;
            param.name = 'event_type';
            param.list_type = 'external';

            let promise = Promise.resolve(HTTPClient.get(endPoints.FORM.APPOINTMENT_TYPE_LIST, param));

            promise.then((response) => {
                if (isValidParam(response)) {
                    let type = getArrayParam(response.record);
                    let _appTypes = type.map((f, i) => {
                        let typeObj = {};
                        typeObj.value = f.value;
                        typeObj.label = f.value;
                        typeObj.color = f.color;
                        return typeObj;
                    })
                    sortArrayObjectByProperty(_appTypes, 'label');
                    setAppTypes(_appTypes);
                    let userslist = getArrayParam(userList.length > 0 ? userList : userlist.data.length > 0 ? userlist.data : userslst);
                    let cookieSelectedUser = JSON.parse(getCookie(userCookieName));
                    if (cookieSelectedUser !== undefined && cookieSelectedUser !== null && cookieSelectedUser.length > 0) {
                        finalSelectedUser = cookieSelectedUser;
                    }
                    if (app.me.calendar_setup.length === 0) {
                        responseUserArr.push({ value: app.me.id, label: app.me.name, color: 'FE5722', profileImage: app.me.profile_image })
                    }
                    if (app.me.calendar_setup.length > 0) {
                        let selectedStatusArr = [];
                        let responseStatusArr = [];
                        let selectedStatus = app.me.calendar_setup[0].selectedStatus;
                        if (selectedStatus !== null && selectedStatus !== undefined && selectedStatus !== "") {
                            selectedStatusArr = selectedStatus.split(',');
                            selectedStatusArr.forEach((item, i) => {
                                let selectedAppStatus = showOptions.filter(f => { return f.label === item });
                                if (selectedAppStatus.length > 0) {
                                    let statusObj = {};
                                    statusObj.value = selectedAppStatus[0].value;
                                    statusObj.label = selectedAppStatus[0].label;
                                    responseStatusArr.push(statusObj);
                                    finalSelectedStatus = responseStatusArr;
                                }
                            });

                        }

                        let selectedDbUser = app.me.calendar_setup[0].selectedUser;
                        if (selectedDbUser !== null && selectedDbUser !== undefined) {
                            selectedUserArr = selectedDbUser.split(',');

                            selectedUserArr.forEach((item, i) => {
                                let selectedCUser = userslst.filter(f => { return f.id === item });
                                if (selectedCUser.length > 0) {
                                    let userObj = {};

                                    userObj.value = selectedCUser[0].id;
                                    userObj.label = selectedCUser[0].value;
                                    userObj.color = selectedCUser[0].colorCode;
                                    userObj.profileImage = selectedCUser[0].profile_image_url;

                                    responseUserArr.push(userObj);
                                }
                            });
                        }
                    }


                    calendar.calendarInfo.salesAssign = finalSelectedUser;
                    calendar.calendarInfo.assign = responseUserArr;
                    calendar.calendarInfo.status = finalSelectedStatus;
                    calendar.calendarInfo.calendarView = currentView;
                    calendar.calendarInfo.schedulerView = 'Day';
                    calendar.calendarInfo.appTypes = _appTypes;
                    prepareSearchArray(userslist, finalSelectedUser, _appTypes, finalSelectedStatus, 'getAllAppointmentsTypes');
                }
            });
        }
    }

    const prepareSearchArray = (userListParam, finalSelectedUser, appTypes, finalSelectedStatus, callFrom) => {
        let searchObject = null;
        let searchArr = [];
        var userOptions = [];
        let isCampaignsSelected = false;
        let selectedModuleName = app.selectedModuleName;
        let newUserList=[...userListParam];

        if (appTypes !== null && appTypes.length > 0 && userListParam !== null && userListParam.length > 0 && finalSelectedUser !== null && finalSelectedUser.length > 0) {
            let isExist = userListParam.filter(f => { return f.value === 'Campaigns' });
            if (selectedModuleName === 'Marketing') {
                if (isExist.length === 0) {
                    // userList.unshift({ value: 'Campaigns', label: 'Campaigns', id: -8888 });
                    newUserList = [{ value: 'Campaigns', label: 'Campaigns', id: -8888 }, ...userListParam];
                }
            } else {
                if (isExist.length > 0) {
                    newUserList = userListParam.filter(f => { return f.value !== 'Campaigns' });;
                }
            }
            map(newUserList, function (userObject, i) {
                let color = '#' + userObject.colorCode;
                userOptions.push({ value: userObject.id, label: userObject.value, color: color });
            });
            map(finalSelectedUser, function (userObject, index) {
                if (userObject.label !== 'Campaigns') {
                    searchObject = {};
                    searchObject.assign = userObject.label;
                    searchArr.push(searchObject);
                } else if (userObject.label === 'Campaigns' && selectedModuleName === 'Marketing') {
                    isCampaignsSelected = true;
                }
            });
            map(appTypes, function (typeObject, i) {
                searchObject = {};
                searchObject.event_type = typeObject.value;
                searchArr.push(searchObject);
            });
            map(finalSelectedStatus, function (showObject, index) {
                searchObject = {};
                searchObject.t_status = showObject.value;
                searchArr.push(searchObject);
            });
            setFinalUserList(userOptions);
            setSelected_user(finalSelectedUser);
            if (callFrom != 'handleToggle') {
                selectedDataManipulation(finalSelectedUser, userOptions, 'prepareSearchArray');
            }
            setAllAppointments(searchArr, callFrom, isCampaignsSelected);
        }
    }

    const setAllAppointments = (searchArr, callFrom, isCampaignsSelected) => {
        if (searchArr.length > 0) {
            let selectedDateRange = null;
            if (calendar.selectedDateRange !== null) {
                selectedDateRange = calendar.selectedDateRange;
                if (callFrom === 'viewNavigate' || callFrom === 'dateNavigate') {
                    selectedDateRange = changelog.selectedDateRange;
                }
            } else {
                selectedDateRange = getDateRange(calendar.calendarInfo.calendarView);
                dispatch(selectedDateRangeUpdate(selectedDateRange));
            }
            let searchObject = {};
            searchObject.start_date = selectedDateRange.startDate + "," + selectedDateRange.endDate;
            searchArr.push(searchObject);
            if (callFrom === 'viewNavigate' || callFrom === 'dateNavigate') {
                if (!getAllAppointmentsfromNavigate) {
                    setGetAllAppointmentsTypesCall(true);
                    getAllBroadcast(isCampaignsSelected, selectedDateRange)
                    dispatch(getAllAppointments(searchArr));
                }
            } else if (callFrom === "DidUpdate") {
                if (alreadyMounted && calendar.isrefresh) {
                    getAllBroadcast(isCampaignsSelected, selectedDateRange)
                    dispatch(getAllAppointments(searchArr));
                }
            } else if (callFrom === "teantCheck" && alreadyMounted) {
                getAllBroadcast(isCampaignsSelected, selectedDateRange)
                dispatch(getAllAppointments(searchArr));
            } else if (callFrom === "handleToggle" && alreadyMounted) {
                getAllBroadcast(isCampaignsSelected, selectedDateRange)
                dispatch(getAllAppointments(searchArr));
            } else {
                if (!updateAllAppointmentsCall) {
                    setUpdateAllAppointmentsCall(true);
                    getAllBroadcast(isCampaignsSelected, selectedDateRange)
                    dispatch(getAllAppointments(searchArr));
                }
            }
        }
    }

    const getAllBroadcast = (isCampaignsSelected, selectedDateRange) => {
        if (isCampaignsSelected) {
            let params = {
                start_index: 0,
                page_size: -1,
                start_date: selectedDateRange.startDate + "," + selectedDateRange.endDate,
                click_form: "calendar_brData",
                fields: ["bd_name", "t_status", "t_owner", "run_date", "created_on", "t_editor_type"],
            }
            dispatch(getAllBroadcasts(params));
        } else if (!isCampaignsSelected) {
            calendar.brdata = null;
        }
    }

    const wrkTimeSet = () => {
        if (app.me.calendar_setup.length > 0) {

            let data = app.me.calendar_setup[0]
            setStartWkHr(data.startHour + ':00');
            setEndWkHr(data.endHour + ':00');

        } else {
            setStartWkHr('9:00');
            setEndWkHr('17:00');
        }
    }

    const setHeaderfn = (type) => {
        let label = type === 'calendar' ? getLocalizedStrings().label.CALENDAR.CALENDAR_TASKS : getLocalizedStrings().label.CALENDAR.CALENDAR_ONLY;
        let kanbanObject = {};
        kanbanObject.currentView = 'calendarOrTask';
        kanbanObject.show = true;
        kanbanObject.isOnlyCalendar = type === 'calendaronly' ? true : false;
        dispatch(setHeader(object, label, kanbanObject, true, true));
    }

    const userListUpdate = () => {
        if (userList.length <= 0 && userlist.data.length > 0) {
            let userList = getArrayParam(userlist.data);
            let _superSelectUserlist = [];
            let tmpUserlist = [];

            userList.forEach((usr, i) => {
                let obj = {};
                obj.value = usr.id;
                obj.label = usr.value;
                _superSelectUserlist.push(obj);
                tmpUserlist.push(usr);
            })
            setUserList(tmpUserlist);
            setSuperSelectUserlist(_superSelectUserlist)

        }
    }


    const getAllCampaignTypesFn = () => {
        try {
            let _campaignTypes = getObjectParam(campaignTypes.data);

            if (Object.keys(_campaignTypes).length === 0) {
                dispatch(getAllCampaignTypes());
            }
        } catch (error) {
            console.error("Error in 'calender.js -> getAllCampaignTypes()':" + error);
        }
    }

    const setAppoinmentData = () => {
        let modifiedEventArr = [];
        try {
            let mounted = calendar.mounted;
            let _userlist = getArrayParam(userlist.data);
            let calendarInfoFromRedux = getObjectParam(calendar.calendarInfo);
            var showType = calendarInfoFromRedux.appTypes;
            let loggedInUser = app.me.name;
            let colorCodeField = 'Users';
            let subject = '';
            let selectedSalesUser = selected_user.length > 0 ? selected_user : calendar.calendarInfo?.salesAssign;
            if (mounted) {
                let calendarUser = [];
                getArrayParam(selectedSalesUser).forEach((selectedUser, i) => {
                    let id = selectedUser.value;
                    map(_userlist, function (userObject, i) {
                        if (userObject.id === id) {
                            let obj = {};
                            let color = '#' + userObject.colorCode;
                            obj.Text = userObject.value;
                            obj.Id = id;
                            obj.Color = color; //'#FE5722';
                            obj.resourceId = id;
                            calendarUser.push(obj);
                        }
                    });
                });

                let eventArr = getArrayParam(calendar.data);
                modifiedEventArr = eventArr.map(function (item) {
                    let conId;
                    let color = '';
                    calendarUser.forEach((user, i) => {

                        if (item.assign_user === user.Text) {
                            conId = getIntParam(user.Id);
                            color = getStringParam(user.Color);
                        }
                        if (loggedInUser !== item.assign_user && item.visibility === 'Private') {
                            subject = 'Busy';
                        } else {
                            subject = item.subject;
                        }


                    });
                    let isexistType = showType.filter(f => { return (f.value === item.type) });
                    let emptyType = showType.filter(f => { return (f.value === "Empty") });
                    if (colorCodeField === 'Types') {
                        if (item.type === '' || isexistType.length === 0) {
                            color = '#' + getStringParam(emptyType[0].color);
                        } else {
                            map(showType, function (typeObject, i) {
                                if (typeObject.value === item.type) {
                                    color = '#' + getStringParam(typeObject.color);
                                }
                            });
                        }
                    }
                    // conId = getIntParam(conId[0]);
                    let parentRecordId = getIntParam(item.parent_recordid);
                    return {
                        Id: item.id,
                        Subject: subject,
                        StartTime: item.start,
                        EndTime: item.end,
                        IsAllDay: item.allDay,
                        ParentRecordId: parentRecordId,
                        Description: item.description,
                        ConferenceId: [conId],
                        Color: color,
                        type: item.type,
                        Company: item.company,
                        Contact: item.contact,
                        LinkInfo: item.linkInfo,
                        Location: item.location,
                        JobName: item.jobName,
                        OpprtunityName: item.opportunityName,
                        userName: item.assign_user,
                    }
                });
            }
            return modifiedEventArr;
        } catch (error) {
            console.error("Error in 'calender.js -> setAppoinmentData()':" + error);
        }
    }

    const getAppoinmentFormData = (data) => {
        try {
            if (isValidParam(sfForm.data['appointments'])) {
                let appFormdata = sfForm.data['appointments']
                processFormData(appFormdata, data);
            } else {
                var promise = getSFFormFieldsPromise(constants.APPOINTMENTS);
                promise.then((response) => {
                    if (isValidParam(response)) {
                        sfForm.data['appointments'] = Object.assign({}, response);
                        processFormData(response, data);
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'calender.js -> getAppoinmentFormData()':" + error);
        }
    }

    const processFormData = (formData, data) => {
        let fields = [];
        try {
            if (data.requestType === 'eventChanged') {
                updateAppointmentDnD(formData, data);
            } else {
                if (isValidParam(formData)) {

                    let customFields = JSON.parse(JSON.stringify(formData.custom_fields));
                    customFields = getArrayParam(customFields);

                    let tempFields = filter(formData.fields, function (field) {
                        return find(customFields, function (fld) {
                            return (fld === field.name && field.name !== 'location');
                        });
                    });

                    customFields.forEach(f => {
                        let searchFields = tempFields.filter(fld => { return fld.name === f; });
                        let searchField = searchFields.length > 0 ? searchFields[0] : null;
                        if (isValidParam(searchField)) {
                            fields.push(searchField);
                        }
                    });

                    let fieldValue = {};
                    let appId = 0;
                    if (data.requestType === 'eventChanged') {
                        fieldValue = data.changedRecords[0]
                        appId = fieldValue.Id
                    } else if (data.requestType === 'eventCreated') {
                        fieldValue = data.addedRecords[0]
                    }
                    let saveField = [];
                    for (let i = 0; i < tempFields.length; i++) {
                        let saveObj = {};
                        let field = tempFields[i];
                        let value = '';
                        if (field.name === 'subject') {
                            value = fieldValue.Subject;
                        } else if (field.name === 'assign') {
                            let assignName = '';
                            let assign_user = fieldValue.ConferenceId[0];
                            selected_user.forEach((user, i) => {
                                if (user.value === assign_user) {
                                    assignName = user.label;
                                }
                            });
                            value = assignName;
                        } else if (field.name === 'event_type') {
                            value = fieldValue.type;
                        } else if (field.name === 'company') {
                            value = fieldValue.Company;
                        } else if (field.name === 'fullname') {
                            value = fieldValue.Contact;
                        } else if (field.name === 'text') {
                            value = fieldValue.Description;
                        } else if (field.field_type === 'datetime') {
                            let dateValue = '';
                            if (field.name === 'start_date') {
                                dateValue = fieldValue.StartTime;
                            } else if (field.name === 'end_date') {
                                dateValue = fieldValue.EndTime;
                            }
                            value = moment(dateValue).format("YYYY-MM-DD HH:mm:ss");
                        } else {
                            value = field.default_value;
                        }
                        saveObj.id = field.id;
                        saveObj.name = field.name;
                        saveObj.value = value;
                        saveField.push(saveObj);
                    }

                    let params = {
                        id: appId,
                        fields: saveField,
                        is_repeat: false

                    }

                    let promise = saveRecordPromise(constants.APPOINTMENTS, params);
                    promise.then((response) => {

                    });
                }
            }
        } catch (error) {
            console.error("Error in 'calender.js -> processFormData()':" + error);
        }
    }

    const updateAppointmentDnD = (formData, data) => {
        try {
            let param = {};
            let eventObj = {};
            eventObj.id = data.data[0].Id;
            eventObj.start_date = data.data[0].StartTime;
            eventObj.end_date = data.data[0].EndTime;
            updateDnDdataInRedux(eventObj);
            let momentStartDate = moment(data.data[0].StartTime);
            let momentEndDate = moment(data.data[0].EndTime);
            let startDate = momentStartDate.format(constants.INPUT_DATE_TIME_FORMAT_24);
            let endDate = momentEndDate.format(constants.INPUT_DATE_TIME_FORMAT_24);
            param.id = data.data[0].Id;
            param.start_date = startDate;
            param.end_date = endDate;
            HTTPClient.post(endPoints.APPOINTMENT.APPOINTMENT_DRAG_AND_DROP, param)
                .then((response) => {
                    if (isValidParam(response) && response.status === 0) {
                        calendar.isrefresh = true;
                        dispatch(getCalendarRefresh(true));
                    }
                });
        } catch (error) {
            console.error("Error in 'calender.js -> updateAppointmentDnD()':" + error);
        }
    }

    const updateDnDdataInRedux = (param) => {
        try {
            let tempArr = [];
            let toModifyArr = [];
            let eventArr = getArrayParam(calendar.data);
            tempArr = eventArr.filter(events => { return events.id !== param.id; });
            toModifyArr = eventArr.filter(events => { return events.id === param.id; });
            if (toModifyArr.length > 0) {
                toModifyArr[0].start = param.start_date;
                toModifyArr[0].end = param.end_date;
                tempArr.push(toModifyArr[0]);
                dispatch(updateAppointmentStoreByDragandDrop(tempArr));
                setevents(tempArr);
            }


        } catch (error) {
            console.error("Error in 'calender.js -> updateDnDdataInRedux()':" + error);
        }

    }

    const addnewAppointment = (refData) => {
        let start = refData.startTime;
        let end = refData.endTime

        if (currentView === 'Month') {
            end = moment(start).add(constants.CALENDER_DEFAULT_INTERVAL, 'm').format(dateTimeFormat[app.me.date_format]);
        }
        let hasPermission = null;
        hasPermission = hasAccessPermission(object, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_CREATE);
        if (hasPermission) {
            let labelName = null;
            labelName = getLocalizedStrings().label.COMMON.ADD_NEW_APPOINTMENT + ' ' + getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENT;
            let data = {};
            let startDate = new Date(start);
            let startDateValue = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes());
            let endDate = new Date(end);
            let endDateValue = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours(), endDate.getMinutes());
            const momentStartDate = moment(startDateValue);
            const momentEndDate = moment(endDateValue);
            //data.timeSlot = { start_date: moment(start), end_date: moment(end) }
            data.timeSlot = { start_date: momentStartDate.format(dateTimeFormat[app.me.date_format]), end_date: momentEndDate.format(dateTimeFormat[app.me.date_format]) }


            if (refData.Subject !== null && refData.Subject !== undefined) {
                data.subject = refData.Subject;
            } else {
                data.subject = getLocalizedStrings().label.APPOINTMENTS_TASKS.FOLLOWUP;
            }
            let confData = isValidParam(refData.ConferenceId) ? refData.ConferenceId : [];
            if (confData.length > 0) {
                let assignTo = null;
                let userArray = getArrayParam(refData.ConferenceId)
                let userId = getIntParam(userArray[0]);
                selected_user.forEach((user, i) => {
                    if (userId === user.value) {
                        assignTo = getStringParam(user.label);
                    }
                });
                data.assign = assignTo;

            } else if (isValidParam(refData.groupIndex)) {
                let selectUser = selected_user[refData.groupIndex];
                data.assign = selectUser.label;
            } else {
                data.assign = app.me.name;
            }

            dispatch(getAppDrawer(true, labelName, constants.NEW, data, null));
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED)
        }
    }

    const editAppointment = (data) => {
        let events = data;
        let hasPermission = null;
        if (events.object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
            let labelName = events.Subject;
            if (events.editorType === constants.EDITOR_LEGACY) {
                let data = {
                    id: events.Id,
                    mode: constants.SF_FORM_OPEN_MODE_EXPANDED,
                    object: constants.BROADCAST_CAMPAIGNS_OBJECT,
                }
                dispatch(getAppDrawer(true, labelName, "Edit", data, constants.LIST_VIEW));
            } else {
                let data = {
                    id: events.Id,
                    object: constants.BROADCAST_CAMPAIGNS_OBJECT,
                    type: constants.EDITOR_NEW,
                }
                let style = {};
                style.width = '95%';
                dispatch(getAppCustomDrawer(true, labelName, "edit", style, data, null, false));
            }
        } else {
            hasPermission = hasAccessPermission(constants.APPOINTMENTS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
            if (hasPermission) {
                let recordId = events.Id;
                let labelName = null;
                labelName = getLocalizedStrings().label.COMMON.EDIT + ' ' + getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENTS;

                let data = {
                    id: recordId
                }
                dispatch(getAppDrawer(true, labelName, constants.EDIT, data, null));
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED);
            }
        }
    }


    const changeView = (view) => {
        calendar.calendarInfo.calendarView = view;
        let userId = (app.me.id * 19000) + 7;
        let projectId = (app.me.projectId * 19000) + 7;
        let taskcookieName = "task-view-mode" + projectId + '-' + userId;
        let _isAllTakView = JSON.parse(getCookie(taskcookieName));
        if(view != 'Day'){
            getAllTaskView('close-cal');
        }
    }

    const generateBroadcastTooltipInfo = () => {
        let broadcastArr = [];
        try {

            let dateFrmt = app.me.date_format;
            map(calendar.brdata, function (broadcastObj, i) {
                let broadcastObject = {};
                broadcastObject.Id = broadcastObj.id;
                broadcastObject.IsAllDay = false;
                broadcastObject.Status = broadcastObj.t_status;
                broadcastObject.linkInfo = null;

                let startDate = new Date(broadcastObj.run_date);
                let endDate = new Date(broadcastObj.run_date);
                if (broadcastObj.t_status !== 'New') {
                    endDate = moment(startDate).add(constants.CALENDER_DEFAULT_INTERVAL, 'm').format(dateTimeFormat[dateFrmt]);
                }
                broadcastObject.StartTime = startDate;
                broadcastObject.EndTime = endDate;

                broadcastObject.Subject = broadcastObj.bd_name;
                broadcastObject.Description = broadcastObj.bd_name;
                broadcastObject.object = constants.BROADCAST_CAMPAIGNS_OBJECT;
                broadcastObject.editorType = broadcastObj['t_editor_type'];
                broadcastObject.ConferenceId = [-8888];
                broadcastObject.color = '#81b752'
                broadcastArr.push(broadcastObject);
            });
        } catch (error) {
            console.error("Error in 'calendars.js -> generateBroadcastTooltipInfo()':" + error);
        }
        return broadcastArr;
    }

    const onEventRendered = (args) => {
        if (scheduleObj !== null && scheduleObj !== undefined && scheduleObj.currentView !== 'Agenda') {
            if (scheduleObj.currentView !== 'Month' && args.data.object === undefined && args.data.object !== constants.BROADCAST_CAMPAIGNS_OBJECT) {
                let cellHeight = scheduleObj.element.querySelector('.e-work-cells').offsetHeight;
                let appHeight = (args.data.EndTime.getTime() - args.data.StartTime.getTime()) / (60 * 1000) * (cellHeight * scheduleObj.timeScale.slotCount) / scheduleObj.timeScale.interval;
                if (!args.data.IsAllDay) {
                    args.element.style.height = appHeight + 'px';
                }
            }


            args.element.style.backgroundColor = args.data.Color;
            let nowTime = moment().tz(timeZone[app.me.timezone]);
            var isafter = moment(args.data.EndTime).isAfter(nowTime);
            if (!isafter) {
                args.element.style.opacity = 0.5;
            }
            if (args.data.Subject === 'Busy') {
                args.element.innerHTML = "<span><div className=e-appointment-details ><div className=e-subject>Busy</div></div></span>";
            }
        }
    }

    const triggerAction = (args) => {
        try {

            let userList = getArrayParam(userlist.data);

            let requestType = getStringParam(args.requestType);
            if (requestType === 'eventCreated') {
                getAppoinmentFormData(args);
            } else if (requestType === 'eventChanged') {
                let records = args.changedRecords[0];
                let selectedUser = userList.filter(f => { return f.value === records.userName });
                if (records.object !== undefined && records.object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
                    args.cancel = true;
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.APPOINTMENTS_TASKS.CAMPAIGN_DRAGE_ALERT, () => dispatch(getCalendarRefresh(true)));
                    return false;
                } else {
                    if (records.Subject === 'Busy') {
                        args.cancel = true;
                        let eventArr = calendar.data;
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.APPOINTMENTS_TASKS.PRIVATE_APPOINTMENT_DRAGE_ALERT, () => dispatch(updateAppointmentStoreByDragandDrop(eventArr)));
                        return false;

                    } else if (selectedUser[0].id !== records.ConferenceId[0]) {
                        args.cancel = true;
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "You cannot drag events in this view ");
                        return false;
                    } else {
                        let hasPermission = hasAccessPermission(constants.APPOINTMENTS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
                        if (hasPermission) {
                            getAppoinmentFormData(args);
                        } else {
                            args.cancel = true;
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
                            return false;
                        }
                    }
                }
            } else if (requestType === 'eventRemoved') {

            } else if (requestType === 'dateNavigate') {
                let view = 'Week';
                if (scheduleObj !== null && scheduleObj !== undefined) {
                    setGetAllAppointmentsTypesCall(false);
                    view = scheduleObj.currentView;
                    var dateRange = {};
                    let tempdates = scheduleObj.activeView.renderDates;
                    let tempdatesCounts = tempdates.length;
                    let tempstartDate = formatDate(tempdates[0], 'start');
                    let tempendDate = formatDate(tempdates[tempdatesCounts - 1], 'end');
                    dateRange.startDate = tempstartDate;
                    dateRange.endDate = tempendDate;
                    changeView(view);
                    dispatch(selectedDateRangeUpdate(dateRange));
                    let log = changelog;
                    log.selectedDateRange = dateRange;
                    setChangeLog({ ...log });
                    dispatch(setSelectedDate(scheduleObj.selectedDate));
                    _setSelectedDate(calendar.selectedDate)
                    getAndUpdateCalendarData('dateNavigate');

                }
            }
            else if (requestType === 'viewNavigate') {
                let _view = 'Week';
                if (scheduleObj !== null && scheduleObj !== undefined) {
                    setGetAllAppointmentsfromNavigate(false);
                    _view = scheduleObj.currentView;
                    dateRange = {};
                    let dates = scheduleObj.activeView.renderDates;
                    let datesCounts = dates.length;
                    let startDate = formatDate(dates[0], 'start');
                    let endDate = formatDate(dates[datesCounts - 1], 'end');
                    dateRange.startDate = startDate;
                    dateRange.endDate = endDate;
                    changeView(_view);
                    dispatch(selectedDateRangeUpdate(dateRange));
                    let _log = changelog;
                    _log.selectedDateRange = dateRange;
                    setChangeLog({ ..._log });
                    dispatch(setSelectedDate(scheduleObj.selectedDate));
                    getAndUpdateCalendarData('viewNavigate');
                }
            }
        } catch (error) {
            console.error("Error in 'calendars.js -> triggerAction()':" + error);
        }
    }

    const onPopupOpen = (args) => {
        let data = args.data;
        let hasPermission = false;
        if (args.type === 'Editor') {
            args.cancel = true;
            data.endTime = data.EndTime;
            data.startTime = data.StartTime;
            if (data.Id > 0) {
                editAppointment(data);
            } else {
                addnewAppointment(data);
            }

        } else if (args.type === 'QuickInfo') {
            // args.data.Subject = 'Followup';

            if (data.object !== undefined && data.object === constants.BROADCAST_CAMPAIGNS_OBJECT && data.Id > 0) {
                editAppointment(data);
            } if (data.Subject === 'Busy') {
                args.cancel = true;
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.APPOINTMENTS_TASKS.PRIVATE_APPOINTMENT_DRAGE_ALERT);


            } else if (data.Id === undefined) {
                args.cancel = true;
                addnewAppointment(data);
            }
        } else if (args.type === 'DeleteAlert') {
            hasPermission = hasAccessPermission(constants.APPOINTMENTS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_DELETE);
            args.cancel = true;
            if (hasPermission) {
                if (data.ParentRecordId > 0) {
                    setSelectedId(data.Id);
                    let _changelog = changelog;
                    _changelog.selectedId = data.Id;
                    setChangeLog(_changelog);
                    let dataValue = {
                        object: constants.APPOINTMENTS,
                    };
                    dispatch(getAppDialog(true, constants.RECURRING_DELETE_DIALOG, getLocalizedStrings().message.REPEAT_DIALOG.DELETE_RECUR_TITLE, getRecurringDeleteChoice, dataValue));
                } else {
                    sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.APPOINTMENTS_TASKS.DELETE, () => deleteRecord(data.Id, null), null);
                }
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
            }
        }
    }

    const getRecurringDeleteChoice = (val) => {
        dispatch(getAppDialog(false, constants.RECURRING_DELETE_DIALOG, null, null, null, null));
        deleteRecord(changelog.selectedId, val);

    }

    const deleteRecord = (id, delete_action_type) => {
        let params = {};
        params.id = [id];
        if (delete_action_type !== null) {
            params.delete_action_type = delete_action_type;
        }

        let linkObject = getEndPointObject(constants.APPOINTMENTS);
        let url = linkObject.DELETE;
        var promise = Promise.resolve(HTTPClient.post(url, params));
        promise.then((response) => {
            if (response.status === 0) {
                dispatch(getCalendarRefresh(true));
                dispatch(getAppDrawer(false, null, null, null, null));
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
            }
            else if (response.status === -1 && response.error.message !== "") {
                dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        });
        // }
    }

    const tooltipShowTemplate = (props) => {
        try {
            let momentStartDate = moment(props.StartTime);
            let momentEndDate = moment(props.EndTime);
            let eventDate = momentStartDate.format(dateFormat[app.me.date_format]);
            let eventStartTime = momentStartDate.format(timeFormat[app.me.date_format]);
            let eventEndTime = momentEndDate.format(timeFormat[app.me.date_format]);
            let appInfo = [];
            if (app.me.calendar_setup.length > 0 && app.me.calendar_setup[0].appInfo !== null && app.me.calendar_setup[0].appInfo !== undefined) {
                appInfo = app.me.calendar_setup[0].appInfo;
            }
            let isAccount = 1;
            let isContact = 0;
            let isOpportunity = 0;

            if (appInfo.length > 0) {
                let checkInfoArr = appInfo.split(',');

                checkInfoArr.forEach((item, i) => {
                    let ObjRecordArr = item.split('_');
                    let tableId = getIntParam(ObjRecordArr[0].trim());
                    if (tableId === OBJECT_TABLEID_MAP.accounts) {
                        isAccount = getIntParam(ObjRecordArr[1]);
                    } else if (tableId === OBJECT_TABLEID_MAP.contacts) {
                        isContact = getIntParam(ObjRecordArr[1]);
                    } else if (tableId === OBJECT_TABLEID_MAP.opportunities) {
                        isOpportunity = getIntParam(ObjRecordArr[1]);
                    } else if (tableId === OBJECT_TABLEID_MAP.jobs) {
                        // isJob = getIntParam(ObjRecordArr[1]);
                    }
                });
            }
            if (object !== undefined && object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
                return (
                    <div className="tooltip-wrap">
                        <div className="content-area">
                            <div className="name">{props.Subject}</div>
                            <div className="time">{props.Status}</div>
                        </div>
                    </div>
                );
            } else {
                if (props.Subject === 'Busy') {
                    return (
                        <div className="tooltip-wrap">
                            <div className="content-area">
                                <div className="name">{props.Subject}</div>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className="tooltip-wrap">
                            <div className="content-area">
                                <div className="name">{props.Subject}</div>
                                <div className="time">{eventDate}</div>
                                <div className="time">{eventStartTime} - {eventEndTime}</div>
                                {(props.Company !== null && props.Company !== undefined && isAccount > 0) && <div className="company">{props.Company}</div>}
                                {(props.Contact !== null && props.Contact !== undefined && isContact > 0) && <div className="contact">{props.Contact}</div>}
                                {(props.OpprtunityName !== null && props.OpprtunityName !== undefined && isOpportunity > 0) && <div className="contact">{props.OpprtunityName}</div>}
                                {/* {(props.JobName !== null && props.JobName !== undefined && isJob > 0) && <div className="JobName">{props.JobName}</div>} */}
                            </div>
                        </div>
                    );
                }
            }
        } catch (error) {
            console.error('Error in Calendar.js -> tooltipShowTemplate() : ' + error);
        }
    }

    const quickPopupContent = (props) => {
        try {
            let Appinfo = [];
            if (app.me.calendar_setup.length > 0 && app.me.calendar_setup[0].appInfo !== null && app.me.calendar_setup[0].appInfo !== undefined) {
                Appinfo = app.me.calendar_setup[0].appInfo;
            }
            let isAccount = 1;
            let isContact = 0;
            let isOpportunity = 0;

            let momentStartDate = moment(props.StartTime);
            let momentEndDate = moment(props.EndTime);
            let eventDate = momentStartDate.format(dateFormat[app.me.date_format]);
            let eventStartTime = momentStartDate.format(timeFormat[app.me.date_format]);
            let eventEndTime = momentEndDate.format(timeFormat[app.me.date_format]);
            let company = (props.Company !== undefined && props.Company !== '') ? props.Company : 'Empty';
            let ContactName = (props.Contact !== undefined && props.Contact !== '') ? props.Contact : 'Empty';
            let opportunityName = (props.OpprtunityName !== undefined && props.OpprtunityName !== '') ? props.OpprtunityName : 'Empty';
            let appTime = eventDate + ' (' + eventStartTime + ' - ' + eventEndTime + ' )';
            let emptyName = 'Empty';
            let openPopOver = true;
            let childRecordArr = [];
            if (props.LinkInfo !== undefined && props.LinkInfo !== '') {
                childRecordArr = props.LinkInfo.split(',');
            }
            if (props.Subject === "Busy") {
                openPopOver = false;
            }
            let leadId = 0;
            let contactId = 0;
            let opportunityId = 0;

            childRecordArr.forEach((item, i) => {
                let ObjRecordArr = item.split('_');
                let tableId = getIntParam(ObjRecordArr[0].trim());
                if (tableId === OBJECT_TABLEID_MAP.accounts) {
                    leadId = getIntParam(ObjRecordArr[1]);
                } else if (tableId === OBJECT_TABLEID_MAP.contacts) {
                    contactId = getIntParam(ObjRecordArr[1]);
                } else if (tableId === OBJECT_TABLEID_MAP.opportunities) {
                    opportunityId = getIntParam(ObjRecordArr[1]);
                }
            });
            if (Appinfo.length > 0) {
                let checkInfoArr = Appinfo.split(',');

                checkInfoArr.forEach((item, i) => {
                    let ObjRecordArr = item.split('_');
                    let tableId = getIntParam(ObjRecordArr[0].trim());
                    if (tableId === OBJECT_TABLEID_MAP.accounts) {
                        isAccount = getIntParam(ObjRecordArr[1]);
                    } else if (tableId === OBJECT_TABLEID_MAP.contacts) {
                        isContact = getIntParam(ObjRecordArr[1]);
                    } else if (tableId === OBJECT_TABLEID_MAP.opportunities) {
                        isOpportunity = getIntParam(ObjRecordArr[1]);
                    } else if (tableId === OBJECT_TABLEID_MAP.jobs) {
                        //isJobs = getIntParam(ObjRecordArr[1]);
                    }
                });
            }
            if (openPopOver) {
                return (<div>
                    {props.elementType === 'event' &&
                        <div className="e-event-content e-template">
                            <div className="e-subject-wrap">
                                <div className="subject" title={appTime} style={{ paddingTop: '16px', fontSize: '14px' }} >
                                    <i className='fa fa-calendar' />
                                    <span style={{ padding: '8px' }}>{appTime}</span>
                                </div>
                                {/* {(props.LinkInfo !== undefined && props.LinkInfo !== '') ?  */}



                                {(contactId > 0 && isContact > 0) &&
                                    <div className='appointment_contact' style={{
                                        paddingTop: '16px', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block',
                                        whiteSpace: 'nowrap', fontWeight: 'normal', maxWidth: '95%', fontSize: '14px'
                                    }} onClick={() => openLinkDetails(contactId, constants.CONTACTS_OBJECT, ContactName)} >
                                        <i className='fa fa-user' title={"Contact"} style={{ color: '#526066', paddingRight: '8px' }} />
                                        <span className='appointment_link' title={ContactName} >{ContactName}</span>
                                    </div>
                                }
                                {(contactId === 0 && isContact > 0) &&
                                    <div className='appointment_contact' style={{
                                        paddingTop: '16px', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block',
                                        whiteSpace: 'nowrap', fontWeight: 'normal', maxWidth: '95%', fontSize: '14px'
                                    }}>
                                        <i className='fa fa-user' title={"Contact"} style={{ color: '#526066', paddingRight: '11px' }} />
                                        <span style={{ color: '#b5b5b5' }} title={ContactName}>{ContactName}</span>
                                    </div>
                                }
                                <br />
                                {(leadId > 0 && isAccount > 0) &&
                                    <div className='appointment_company' style={{
                                        paddingTop: '16px', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block',
                                        whiteSpace: 'nowrap', fontWeight: 'normal', maxWidth: '95%', fontSize: '14px'
                                    }} onClick={() => openLinkDetails(leadId, constants.ACCOUNTS_OBJECT, company)} >
                                        <i className='fa fa-building' title={getLocalizedStrings().label.HEADER.ACCOUNT} style={{ color: '#526066', paddingRight: '8px' }} />
                                        <span className='appointment_link' title={company} >{company}</span>
                                    </div>
                                }
                                {(leadId === 0 && isAccount > 0) &&
                                    <div className='appointment_company' style={{
                                        paddingTop: '16px', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block',
                                        whiteSpace: 'nowrap', fontWeight: 'normal', maxWidth: '95%', fontSize: '14px'
                                    }}>
                                        <i className='fa fa-building' title={getLocalizedStrings().label.HEADER.ACCOUNT} style={{ color: '#526066', paddingRight: '8px' }} />
                                        <span style={{ color: '#b5b5b5' }} title={company}>{company}</span>
                                    </div>


                                }
                                {(opportunityId > 0 && isOpportunity > 0) &&
                                    <div className='appointment_opp' style={{
                                        paddingTop: '16px', textOverflow: 'ellipsis', overflow: 'hidden',
                                        whiteSpace: 'nowrap', fontWeight: 'normal', maxWidth: '95%', fontSize: '14px'
                                    }} onClick={() => openLinkDetails(opportunityId, constants.OPPORTUNITIES_OBJECT, opportunityName)} >
                                        <i className='fa fa-usd' title={'Opportunity'} style={{ color: '#526066', paddingRight: '8px' }} />
                                        <span className='appointment_link' title={opportunityName} >{opportunityName}</span>
                                    </div>
                                }
                                {(opportunityId === 0 && isOpportunity > 0) &&
                                    <div className='appointment_opp' style={{
                                        paddingTop: '16px', textOverflow: 'ellipsis', overflow: 'hidden',
                                        whiteSpace: 'nowrap', fontWeight: 'normal', maxWidth: '95%', fontSize: '14px'
                                    }}>
                                        <i className='fa fa-usd' title={'Opportunity'} style={{ color: '#526066', paddingRight: '11px' }} />
                                        <span style={{ color: '#b5b5b5' }} title={opportunityName} >{opportunityName}</span>
                                    </div>
                                }

                                <div className="appointment_location" style={{
                                    paddingTop: '16px', textOverflow: 'ellipsis', overflow: 'hidden',
                                    whiteSpace: 'nowrap', fontWeight: 'normal', maxWidth: '95%', fontSize: '14px'
                                }} >
                                    <i className='fa fa-compass' title={getLocalizedStrings().label.SCHEDULER.LOCATION} style={{ color: '#526066', paddingRight: '8px', fontSize: '15px' }} />
                                    {(props.Location !== undefined && props.Location !== '') ?
                                        <span title={props.Location} >{props.Location}</span>
                                        : <span style={{ color: '#b5b5b5' }} title={emptyName} >{emptyName}</span>}
                                </div>
                                <div className="appointment_description" style={{
                                    paddingTop: '16px', textOverflow: 'ellipsis', overflow: 'hidden',
                                    whiteSpace: 'nowrap', fontWeight: 'normal', maxWidth: '95%', fontSize: '14px'
                                }} >
                                    <i className='fa fa-info-circle' title={getLocalizedStrings().label.SCHEDULER.DESCRIPTION} style={{ color: '#526066', paddingRight: '8px' }} />
                                    {(props.Description !== undefined && props.Description !== '') ?
                                        <span title={props.Description} >{props.Description}</span>
                                        : <span style={{ color: '#b5b5b5' }} title={emptyName}>{emptyName}</span>}
                                </div>
                            </div>
                        </div>}
                </div>);
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.APPOINTMENTS_TASKS.PRIVATE_APPOINTMENT_ALERT, null);
                return false;

            }
        } catch (error) {
            console.error('Error in Calendar.js -> quickPopupContent() : ' + error);
        }
    }

    const openLinkDetails = (recordId, object, labelName) => {
        try {
            let url = '/' + object + '/detailview/' + recordId;
            let tabParams = {
                label: labelName,
                object: object,
                type: TYPE_DETAIL_VIEW,
                url: url,
            }
            addTab(tabParams, true);
        } catch (error) {
            console.error('Error in Calendar.js -> openLinkDetails() : ' + error);
        }
    }

    const minimizeLeftRightCard = (from) => {
        if (from === 'left') {
            let temp = calendarViewControl;
            temp.left = !temp.leftMin ? 50 : '20%';
            temp.leftMin = !temp.leftMin;
            setCalendarViewControl({ ...temp });
        }
        let userId = (app.me.id * 19000) + 7;
        let projectId = (app.me.projectId * 19000) + 7;
        let cookieName = "calendarMin-left" + projectId + '-' + userId;
        setCookie(cookieName, calendarViewControl.leftMin);
        let taskcookieName = "task-view-mode" + projectId + '-' + userId;
        let _isAllTakView = JSON.parse(getCookie(taskcookieName));
        if (_isAllTakView) {
            setCookie(taskcookieName, false);
            calendar.calendarInfo.calendarView = 'Week';
            updateCalenderDateTimeLine();
            dispatch(getCalendarRefresh(true));
        }
        if(calendarViewControl.leftMin== false){
            getAllTaskView('close-cal');
            }
    }
    const updateCalenderDateTimeLine = () => {
        var sObj = document.querySelector(".e-schedule").ej2_instances[0];
        var currentDates = sObj.getCurrentViewDates();
        var currentView = sObj.viewIndex;
        var startDate = currentDates[0];
        if (currentView === 3) {
            startDate = currentDates[15];
        }
        if (startDate !== null && startDate !== undefined) {
            dispatch(setSelectedDate(startDate));
        }
    };
    const selectedDataManipulation = (selectedArr, options, callFrom) => {
        let tempOption = [];

        options.forEach((option, index) => {
            let tempOptionObj = {};
            tempOptionObj.label = option.label;
            tempOptionObj.value = option.value;
            tempOptionObj.color = option.color;
            tempOptionObj.selected = false;
            if (selectedArr.length > 0) {
                for (let i = 0; i < selectedArr.length; i++) {
                    if (selectedArr[i].value === option.value) {
                        tempOptionObj.selected = true;
                    }
                }
            }
            tempOption.push(tempOptionObj);
        })
        setFinalSelectedUserList(tempOption)
        let userId = (app.me.id * 19000) + 7;
        let projectId = (app.me.projectId * 19000) + 7;
        let cookieName = "selected-user" + projectId + '-' + userId;
        setCookie(cookieName, JSON.stringify(selectedArr));
        calendar.calendarInfo.salesAssign = selectedArr;
        if (callFrom === 'handleToggle') {
            dispatch(getCalendarRefresh(true));
            getAndUpdateCalendarData('handleToggle');
        }
    }

    const handleToggle = (val) => {
        let data = calendar.calendarInfo.salesAssign;
        let isTenatCall = false;
        let isExists = data.filter(f => { return f.value === val.value });
        if (isExists.length > 0) {
            data = data.filter(f => { return f.value !== val.value });
            if (val.value === app.me.id) {
                isTenatCall = true;
            }
        } else {
            data.push(val);
        }
        let iscampaignExist = data.filter(f => { return f.value === -8888 });
        if (iscampaignExist.length === 0 && app.selectedModuleName === 'Marketing') {
            calendar.brdata = null;
        }
        if (data.length === 1 && app.selectedModuleName === 'Marketing') {
            if (iscampaignExist.length === 1) {
                data.push({ value: app.me.id, label: app.me.name, color: 'FE5722' })
            }
        } else if (data.length === 0) {
            data.push({ value: app.me.id, label: app.me.name, color: 'FE5722' })
        } else if (data.length === 1 && app.selectedModuleName !== 'Marketing' && iscampaignExist.length > 0) {
            data.push({ value: app.me.id, label: app.me.name, color: 'FE5722' })
        }
        calendar.calendarInfo.salesAssign = data;
        setSelected_user(data);
        if (!isTenatCall) {
            selectedDataManipulation(data, finalUserList, 'handleToggle');
        } else {
            getAndUpdateCalendarData('teantCheck')
        }
    }

    let calendarHeight = window.innerHeight - 247;
    let calendarWidth = window.innerWidth;
    let titleWidth = (calendarWidth * 0.2) - 153;
    let top = (calendarHeight - 10) / 2;

    if (calendar.mounted && finalSelectedUserList !== undefined && finalSelectedUserList.length > 0) {
        let updatedEventArr = [];
        updatedEventArr = setAppoinmentData();
        if (app.selectedModuleName === constants.MODULE_MARKETING) {
            let broadcastArr = [];
            broadcastArr = generateBroadcastTooltipInfo();
            updatedEventArr = updatedEventArr.concat(broadcastArr);
        }
        let userId = (app.me.id * 19000) + 7;
        let projectId = (app.me.projectId * 19000) + 7;
        let taskcookieName = "task-view-mode" + projectId + '-' + userId;
        let _isAllTakView = JSON.parse(getCookie(taskcookieName));
      
        let defaultView = calendar.calendarInfo.calendarView ? calendar.calendarInfo.calendarView : 'Week';
       
        if(_isAllTakView || (app.me?.calendar_setup.length == 0 && _isAllTakView == null)){
            defaultView = 'Day'
        }
        if(_isAllTakView == null && (app.me?.calendar_setup[0]?.defaultView == undefined || app.me?.calendar_setup[0]?.defaultView == "" ||app.me?.calendar_setup[0]?.defaultView == "Expanded" ) && (app.me?.calendar_setup.length == 1 || app.me?.calendar_setup.length == 2) ){
            defaultView = 'Day'
        }
        defaultView = defaultView.charAt(0).toUpperCase() + defaultView.slice(1, defaultView.length);

        return (
            <div style={styles.workAreaContainer} >
                {calendar.mounted &&
                    <div style={{ paddingLeft: '10', width: '100%', display: 'flex' }}>
                        <div style={{ width: calendarViewControl.left }} >
                            <div className="card" style={{ width: '100%' }} >
                                <div className='inner-card-body' >
                                    <div>
                                        <div style={{ width: '100%', display: 'flex', padding: '7px', height: '42px' }}>
                                            {!calendarViewControl.leftMin && <div style={{ width: '100%' }}>
                                                <Typography variant="h6">
                                                    Calendars
                                                </Typography>
                                            </div>}
                                            <div style={{ width: '50px', marginTop: '-7px' }}>
                                                <IconButton onClick={() => { minimizeLeftRightCard("left") }} >
                                                    {calendarViewControl.leftMin ? <i className="fas fa-angle-right"></i> : <i className="fas fa-angle-left"></i>}
                                                </IconButton>
                                            </div>
                                        </div>
                                        <Divider light />
                                        <div style={{ overflowY: 'scroll', height: calendarHeight - 45 }}>
                                            {!calendarViewControl.leftMin && <List >
                                                {finalSelectedUserList.map(option => (
                                                    <ListItem key={option.value} role={undefined} dense button onClick={() => handleToggle(option)}>
                                                        <Checkbox
                                                            edge="start"
                                                            size='small'
                                                            color="default"
                                                            checked={option.selected}
                                                            inputprops={{ 'aria-labelledby': option.label }}
                                                        />
                                                        <span style={{ backgroundColor: option.color }} className='user-color-box' />
                                                        <ListItemText primary={<div title={option.label} style={{ width: titleWidth, display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: '#000000' }}>{option.label}</div>} />
                                                    </ListItem>
                                                ))}
                                            </List>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: !calendarViewControl.leftMin ? '80%' : '100%' }} >
                            <div style={{ height: '100%' }}>
                                <ScheduleComponent
                                    showQuickInfo={true}
                                    currentView={defaultView}
                                    selectedDate={new Date(calendar.selectedDate)}
                                    height={calendarHeight}
                                    ref={schedule => scheduleObj = schedule}
                                    allowResizing={false}
                                    eventSettings={{
                                        dataSource: updatedEventArr,
                                        fields: {
                                            id: 'Id',
                                            subject: { name: 'Subject', title: 'Event Name', value: 'Follow' },
                                            location: { name: 'Company', title: 'Company' },
                                            description: { name: 'Description', title: 'Event Description' },
                                            startTime: { name: 'StartTime', title: 'Start Duration' },
                                            endTime: { name: 'EndTime', title: 'End Duration' },
                                            attendees: { name: 'Attendees', title: 'Assign To' }
                                        },
                                        enableTooltip: true,
                                        tooltipTemplate: tooltipShowTemplate.bind(this)
                                    }}
                                    workHours={{
                                        start: startWkHr,
                                        end: endWkHr
                                    }}
                                    quickInfoTemplates={{ content: quickPopupContent.bind(this) }}
                                    eventRendered={onEventRendered.bind(this)}
                                    cellDoubleClick={addnewAppointment.bind(this)}
                                    actionComplete={triggerAction.bind(this)}
                                    popupOpen={onPopupOpen.bind(this)}
                                >
                                    <ViewsDirective>
                                        <ViewDirective option='Day' />
                                        <ViewDirective option='Week' />
                                        <ViewDirective option='WorkWeek' workDays={workDays} />
                                        <ViewDirective option='Month' />
                                        <ViewDirective option='Agenda' />
                                        <ViewDirective option= ' ' />
                                    </ViewsDirective>
                                    <Inject services={[Day, Week, WorkWeek, Month, Agenda, Resize, DragAndDrop]} />
                                </ScheduleComponent>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    } else {
        return (
            <div style={{ width: '100%', height: calendarHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 55, width: 55, padding: 6 }}>
                        <ShowCircularProgress size={35} style={{ marginTop: '4', marginLeft: '4' }} />
                    </div>
                </div>
            </div>
        );
    }

}

export default Calendar;
