
import React from 'react';

const SFCheckbox = ({ checked, onChange,label,size = 30,style={} }) => {
    return (
      <label className="sf-checkbox" style={{...style}}>
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="sf-checkmark" style={{width:size,height:size}}>
            <span className="sf-checkmark-inner" style={{width:size/2.7,height:size/2.7}}></span>
        </span>
        <span className="sf-checkbox-label" style={{marginLeft:size+5,lineHeight:`${size}px`,marginRight:5}}>{label??""}</span> 
      </label>
    );
  };
  
export default SFCheckbox;