import React, { useState, useEffect }  from 'react';
import Button from '@mui/material/Button';
import { styles } from '../../../services/constants/styles';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { getStringParam, getObjectParam, getArrayParam, getBooleanParam, isValidParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants } from '../../../services/constants/constants';
import { dateTimeFormat, dateFormat, dateTimeFormatFlatPicker, dateFormatFlatPicker } from '../../../services/helper/utils';
import moment from 'moment';
import PopOver from './PopOver';
import { useDispatch, useSelector } from "react-redux";
import changeLogo from './changeLogo';

const ListViewDatePicker = ({ data }) => {

    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    
    const [value, setValue] = useState(data.row[data.column.key] == "Empty" && data.callFrom == "onBoarding" ? '' : data.row[data.column.key]);
    const[changeLog,setchangeLog] = useState({value: data.row[data.column.key] == "Empty" && data.callFrom == "onBoarding" ? '' : data.row[data.column.key]})

    const getColumnInfo = () => {
        let tempData = getObjectParam(data);
        let column = getObjectParam(tempData.column);
        return column;
    }

    const getOptions = (column) => {
        let dateTimeFormatValue = null;
        if (column.field_type === constants.FIELD_TYPE_DATE_TIME) {
            dateTimeFormatValue = dateTimeFormatFlatPicker[app.me.date_format];
        } else if (column.field_type === constants.FIELD_TYPE_DATE) {
            dateTimeFormatValue = dateFormatFlatPicker[app.me.date_format];
        }
        let HHFormat = new RegExp("HH");
        let datePickerOptions = {
            enableTime: column.field_type === constants.FIELD_TYPE_DATE_TIME ? true : false,
            noCalendar: column.is_readonly ? true : false,
            dateFormat: dateTimeFormatValue,
            minuteIncrement: 1,
            time_24hr: HHFormat.test(app.me.date_format) ? true : false
        };
        return datePickerOptions;
    }

    const fnSetValue = (date) => {
        let fieldValue = null;
        let column = getColumnInfo();
        date = getStringParam(date);
        if (date !== '') {
            let selectedDate = new Date(date);
            let dateValue = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), selectedDate.getHours(), selectedDate.getMinutes())
            const momentDate = moment(dateValue);

            if (column.field_type === constants.FIELD_TYPE_DATE) {
                fieldValue = momentDate.format(dateFormat[app.me.date_format]);
                setValue(fieldValue);
                let log = changeLog;
                log.value = fieldValue;
                setchangeLog({...log});
            } else if (column.field_type === constants.FIELD_TYPE_DATE_TIME) {
                fieldValue = momentDate.format(dateTimeFormat[app.me.date_format]);
                setValue(fieldValue);
                let _log = changeLog;
                _log.value = fieldValue;
                setchangeLog({..._log});
            }
            
        } else {
            setValue('');
        }
    }

    const updateDataGrid = (Nvalue) => {
       
        let tempData = getObjectParam(data);
        let column = getObjectParam(tempData.column);
        let callBackToUpdate = tempData.callBackToUpdate;
        let colName = getStringParam(column.key);
        let isRequired = getBooleanParam(column.is_required_field);
        let callFrom = getStringParam(tempData.callFrom);
        let rows = getArrayParam(tempData.rows);
        let row = getObjectParam(tempData.row);
        let field = {};
        field.column = column

        let defaultDate = '';
        let tempValToCheckLength = Nvalue;
            if (column.field_type == constants.FIELD_TYPE_DATE && Nvalue != '') {
                tempValToCheckLength = moment(Nvalue);
                tempValToCheckLength = tempValToCheckLength.format(dateFormat[app.me.date_format]);
            } else if (column.field_type == constants.FIELD_TYPE_DATE_TIME && Nvalue != '') {
                tempValToCheckLength = moment(Nvalue);
                tempValToCheckLength = tempValToCheckLength.format(dateTimeFormat[app.me.date_format]);
            } 
             
        if (callFrom === "onBoarding" && ((isRequired && isValidParam(tempValToCheckLength) && tempValToCheckLength !== '') || !isRequired)) {
            let tempRdata = {};
            tempRdata.column = column;
            tempRdata.row = tempData.row;
            tempRdata.rowId = tempData.rowId;
            tempRdata.value = tempValToCheckLength ;
            callBackToUpdate({...tempRdata,value :tempValToCheckLength});
        }
        if (callFrom !== "onBoarding" && ((isRequired && tempValToCheckLength) || !isRequired)) {
            rows = rows.map(item=>{
                if(item.id === row.id) {
                    return {...item, [colName]: tempValToCheckLength}
                }
                return item;
            })
            callBackToUpdate(rows, field)
        }
        dispatch(getAppDialog(false, null, null, null, null, null));
    }


    let column = getColumnInfo();
    let datePickerOptions = getOptions(column);

    return (
        <div className='row'>
            <div className="col-sm-12 col-two">
                <div className={'sf-form-field dateTimeDiv-' + column.key} id="dateholder" >
                    <label id={column.name + '-datelbl'} className="date-label">{column.label}</label>
                    {changeLog.value !== null &&
                        <PopOver
                            id={'listview_Flat_picker'}
                            key={'listview_Flat_picker'}
                            name={'listview_Flat_picker'}
                            btnType={column.field_type === constants.FIELD_TYPE_DATE_TIME ? 'dateTime' : 'date'}
                            buttonStyle={{ fontSize: '16px' }}
                            containerStyle={{ lineHeight: 1 }}
                            buttonLabel={""}
                            onChange={(date) => fnSetValue(date)}
                            value={changeLog.value}
                            innerLabelStyle={{ padding: "12 5 2 5", fontSize: '15px' }}
                            height={45}
                            options={datePickerOptions}
                        />
                    }
                </div>
                <div style={{ width: 'auto' }}>
                    <div style={{ textAlign: 'right', marginTop: '15px'}}>
                        <Button
                            key={'update'}
                            primary={"true"}
                            onClick={() => updateDataGrid(changeLog.value)}
                            style={styles.primaryButton}
                        >
                            {getLocalizedStrings().label.COMMON.SAVE}
                        </Button>
                        <Button
                            key={'cancel'}
                            primary={"true"}
                            onClick={() => dispatch(getAppDialog(false, null, null, null, null, null))}
                            style={styles.secondaryButton}
                        >
                            {getLocalizedStrings().label.COMMON.CLOSE}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ListViewDatePicker

