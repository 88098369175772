import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import  { useEffect, useState } from 'react';
import { isValidParam, getObjectParam, getIntParam, getArrayParam, getBooleanParam, getStringParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getEmailPromise } from '../../../services/actions/emailActions';
import { constants, TABLEID_OBJECT_MAP } from '../../../services/constants/constants';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import Icon from '@mui/material/Icon';

const EmailReportListView = ({ info, closePanel }) => { 

const dispatch = useDispatch();

const [mounted, setMounted] = useState(false);
const [records, setRecords] = useState(null);
const [data, setData] = useState(null);

    
        useEffect(() => {
        let tempInfo = info;
        if (isValidParam(tempInfo)) {
            let params = tempInfo.input_param;
            if (isValidParam(params)) {
                getDrillDownDetails(params);
            }
        }
        },[]);


    const getDrillDownDetails = (params) => {
        let records = null;
        params = getObjectParam(params);
        let promise = Promise.resolve(HTTPClient.get(endPoints.EMAIL_REPORT.DRILLDOWN_GET, params));
        promise.then((response) => {
            if (isValidParam(response)) {
                records = getArrayParam(response);
                setRecords(records);
                setData(params);
                setMounted(true);

            }
        });
    }

    const getHeader = (record) => {
        let header = null;
        let style = { lineHieght: '30px', backgroundColor: '#fafafa', color: '#535353', paddingTop: '10px', paddingLeft: '7px' };
        try {
            if (isValidParam(record)) {
                let headerText = getHeaderText(record);
                header = <div style={style}>
                    <div style={{ paddingLeft: '10px', fontWeight: 'bold', color: '#070100' }}>
                        {headerText}
                    </div>
                </div>;
            }
        } catch (error) {
            console.error("Error in 'emailReportListView.js -> getHeader()':" + error);
        }
        return header;
    }

    const getHeaderText = (object) => {
        let headerText = "";
        let record = getObjectParam(object);
        try {
            let author = getStringParam(record.t_creater).trim();
            let toEmail = getStringParam(record.t_email).trim();
            /* let toName = getStringParam(record.t_name).trim(); */
            let date = getStringParam(record.created_on).trim();
            if (author !== "") {
                headerText = author;
            }
            if (date !== "") {
                headerText += headerText !== "" ? ", " + date : date;
            }
            if(getStringParam(toEmail).length > 0){
                headerText =  headerText !== "" ? headerText.concat(", "+getStringParam(toEmail)) : headerText.concat(getStringParam(toEmail));
            } 

        } catch (error) {
            console.error("Error in 'emailReportListView.js -> getHeaderText()':" + error);
        }
        return headerText;
    }

    const getNoteSubText = (object) => {
        let subjectContent = null;
        let subject = "";
        let record = getObjectParam(object);
        let style = { backgroundColor: '#ffffff', paddingLeft: "17px", paddingRight: 17 };
        let subjectText = null;
        let column_name = null;
        try {
            if (isValidParam(object)) {
                let emailObj = getObjectParam(object);
                let col = getStringParam(data.column_name);
                if (col === 'bounce') {
                    subjectText = getStringParam(record.t_subject).trim() +', '+ getStringParam(record.t_email).trim() + ", " + getLocalizedStrings().label.EMAIL_REPORT.ERROR_REASON + " :" + getStringParam(record.error_reason).trim();
                } else {
                    subjectText = getStringParam(record.t_subject).trim();
                }
                if (subjectText !== "") {
                    subject = subjectText;
                    subjectContent = <div style={style}>
                        <div style={{ whiteSpace: "pre-wrap", wordBreak: "break-all", lineHeight: "30px" }}>{subject}
                            {col === 'bounce' ? null : <span style={{ paddingLeft: '10px' }}>
                                <a href="javascript:void(0)" onClick={() => openEmail(emailObj)}>
                                    {getLocalizedStrings().label.COMMON.MORE + "..."}
                                </a>
                            </span>
                            }
                        </div>
                    </div>

                }
            }
        } catch (error) {
            console.error("Error in 'emailReportListView.js -> getNoteSubText()':" + error);
        }
        return subjectContent;
    }

    const generateContent = () => {
        let _records = records;
        let mainContent = [];
        let content = null;
        if (getArrayParam(_records).length > 0) {
            _records.map((_records, index) => {
                let header = getHeader(_records);
                let subText = getNoteSubText(_records);
                content = <div>{header}{subText}</div>
                mainContent.push(content);
                content = null;
            });
        }
        return mainContent;
    }

    const openEmail = (recordObj) => {
        try {
            if (isValidParam(recordObj)) {
                let record = getObjectParam(recordObj);
                if (isValidParam(record)) {
                    let data = {};
                    let object = TABLEID_OBJECT_MAP[getIntParam(record.table_id)];
                    data.parentObject = getStringParam(object);
                    data.parentRecordId = getIntParam(record.record_id);
                    data.record = record;
                    let subject = getStringParam(record.t_subject);
                    let label = subject.length > 55 ? subject.substring(0, 55) + '...' : subject;
                    let type = constants.EMAIL_DETAILS;
                    let callFrom = constants.NOTES;
                    dispatch(getAppDrawer(true, label, type, data, callFrom));
                }
            }

        } catch (error) {
            console.log("Error in 'emailReportListView.js -> openEmail()':" + error);
        }
    }
    
        let pageHeight = window.innerHeight - 247;
        let top = (pageHeight-10) / 2
        if (mounted) {
            let heading = data.reportLabel;
            let mainContent = generateContent(records);
            return (
                <div className="container" id="email-list-view-container" style={{ width: '100%' }}>
                    <div className="row">
                        <div style={{ backgroundColor: '#ffffff', paddingTop: "1px", paddingBottom: "20px" }}>
                            <div style={{ marginTop: '0px' }}>
                                <div className="text" style={{ fontSize: '22px', float: 'left', textAlign: 'center', width: '90%', padding: '0px 10px'}}>
                                    <h3 style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 10, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {heading}
                                    </h3>
                                </div>
                                <div className="text" style={{ fontSize: '22px', float: 'right', textAlign: 'center', width: '10%', padding: '2.8% 2%'}}>
                                    <Icon style={{cursor: 'pointer'}} title="Close" onClick={()=>closePanel()}>close</Icon>
                                </div>
                            </div>
                            {mainContent}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{ width: '100%', height: pageHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>);
        }
    }


export default EmailReportListView;