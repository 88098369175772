import { actions } from "../constants/actions";

function updateAgreementComponentData(data){
return function (dispatch){
    try {
dispatch({
    type: actions.UPDATE_AGREEMENT_COMPONENT_DATA,
    payload : {
        agreementComponentData: data,
    }
})
} catch (e) { console.error("Error in 'templateAction.js -> templateAction()':" + e); }
}
}

function updateAgreementTemplateData(data){
    return function (dispatch){
        try {
    dispatch({
        type: actions.UPDATE_AGREEMENT_TEMPLATE_DATA,
        payload : {
            agreementTemplateData: data,
        }
    })
    } catch (e) { console.error("Error in 'agreementtemplateAction.js -> updateAgreementTemplateData()':" + e); }
    }
    }
export { updateAgreementComponentData,updateAgreementTemplateData};