import { actions } from "../constants/actions";

let intialState = {
    agreementComponentData : null,
    agreementTemplateData : null
}

const templateReducer = (state = intialState , action )=>{
    switch(action.type){
        case actions.UPDATE_AGREEMENT_COMPONENT_DATA:
            return {
                agreementComponentData:  action.payload.agreementComponentData,
            }
            case actions.UPDATE_AGREEMENT_TEMPLATE_DATA:
                return{
                    agreementTemplateData : action.payload.agreementTemplateData
                }
            default:
                return state;
    
    }

}
export default templateReducer;
