import React from 'react';
import { Button, Chip, TextField, FormControl } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { isValidParam, getStringParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants } from '../../../services/constants/constants';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import * as sfDialogs from '../components/sfDialogs';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';
import NoRecord from '../components/noRecord';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import map from 'lodash/map';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const Docusign = ({ closeDrawer, parentObject, childRecordId }) => {
    const dispatch = useDispatch();
    const [isDocuSignConnected, setIsDocuSignConnected] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const [menuItemTemplates, setMenuItemTemplates] = useState([]);
    const [selectedOption, setSelectedOption] = useState({});
    const [isNoTemplateFound, setisNoTemplateFound] = useState(false);
    const [predefinedNoteText, setPredefinedNoteText] = useState(null);
    const [selectedContact, setSelectedContact] = useState([]);
    const [availableContacts, setAvailableContacts] = useState([]);

    const app = useSelector((state) => state.app);
    const detailView = useSelector((state) => state.detailView);

    useEffect(() => {
        fnGetDocusignEnvelopeList();
        processContacts();
    }, []);

    const fnGetDocusignEnvelopeList = () => {
        try {
            let promise = Promise.resolve(HTTPClient.get(endPoints.DOCUSIGN.ENVELOP_LIST_GET, null));
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.status === -1) {
                        let errorMsg = response.error.message;
                        if (isValidParam(errorMsg) && errorMsg === "Authentication failed") {
                        }
                        setIsMounted(true);
                        setIsDocuSignConnected(false);
                    } else {
                        if (response.hasOwnProperty('template_list')) {
                            let templateList = response['template_list'];
                            if (isValidParam(templateList) && templateList !== null && templateList.length > 0) {
                                let menuItemTemplates = null;
                                menuItemTemplates = templateList.map((templateObject, i) => {
                                    return {
                                        value: templateObject.template_id,
                                        label: templateObject.template_name,
                                        title: templateObject.template_name,
                                    }
                                });
                                let predefinedNoteText = "Hi,\n" +
                                    "Please sign this document. Thank you." + "\n" +
                                    "" + app.me.name;
                                setIsMounted(true);
                                setIsDocuSignConnected(true);
                                setMenuItemTemplates(menuItemTemplates);
                                setSelectedOption(menuItemTemplates[0]);
                                setPredefinedNoteText(predefinedNoteText);
                            } else {
                                setIsMounted(true);
                                setisNoTemplateFound(true);
                            }
                        }
                    }
                }
            })
        } catch (error) {
            console.log("Error in 'Docusign.js -> fnGetDocusignEnvelopeList()':" + error);
        }
    }

    const openConfiguration = (boolParam) => {
        try {
            setIsMounted(false);
            let token = localStorage.getItem('token');
            let url = constants.DOCUSIGN_AUTH_API + 'response_type=code' +
                '&state=' + token + '&scope=signature&prompt=login&client_id=fc9c09eb-7135-4d76-9826-fa86d5196b92&redirect_uri=' + constants.DOCUSIGN_REDIRECT_URI;
            var myWindow = window.open("", "", 'scrollbars=1,width=850,height=550,location=no');
            myWindow.location = url;
            setTimeout(function () {
                getEnvelopelist();
            }, 30000);
        } catch (error) {
            console.log("Error in 'Docusign.js -> openConfiguration()':" + error);
        }
    }

    const getEnvelopelist = () => {
        let promise = Promise.resolve(HTTPClient.get(endPoints.DOCUSIGN.ENVELOP_LIST_GET, null));
        promise.then((response) => {
            if (isValidParam(response)) {
                if (response.status === -1) {
                    let errorMsg = response.error.message;
                    if (isValidParam(errorMsg) && errorMsg === "Authentication failed") {
                    }
                    setIsMounted(true);
                    setIsDocuSignConnected(false);
                } else {
                    if (response.hasOwnProperty('template_list')) {
                        let templateList = response['template_list'];
                        if (isValidParam(templateList) && templateList !== null && templateList.length > 0) {
                            let menuItemTemplates = null;
                            menuItemTemplates = templateList.map((templateObject, i) => {
                                return {
                                    value: templateObject.template_id,
                                    label: templateObject.template_name,
                                    title: templateObject.template_name,
                                }
                            });
                            let predefinedNoteText = "Hi,\n" +
                                "Please sign this document. Thank you." + "\n" +
                                "" + app.me.name;
                            setIsMounted(true);
                            setIsDocuSignConnected(true);
                            setMenuItemTemplates(menuItemTemplates);
                            setSelectedOption(menuItemTemplates[0]);
                            setPredefinedNoteText(predefinedNoteText);
                        } else {
                            setIsMounted(true);
                            setisNoTemplateFound(true);
                        }
                    }
                }
            }
        });
    }

    const changeTeplateList = (event, options) => {
        setSelectedOption(options != null ? options : {});
    }

    const sendTemplateWithValidate = () => {
        try {
            var selectedContacts = getArrayParam(selectedContact);
            let mailNotFndContacts = '';

            if (selectedContacts.length > 0) {
                let emptyEmailList = selectedContacts.filter(f => { return getStringParam(f.recipient_email).trim() === '' });
                let validEmailList = selectedContacts.filter(f => { return getStringParam(f.recipient_email).trim() !== '' });
                emptyEmailList.forEach(item => {
                    mailNotFndContacts = mailNotFndContacts === '' ? item.recipient_name : mailNotFndContacts + ', ' + item.recipient_name;
                });

                if (validEmailList.length === 0) {
                    let msg = <span><span>{getLocalizedStrings().message.DOCUSIGN.DOCUSIGN_CONTACT_MESSAGE_1}</span><span style={{ fontWeight: 'bold' }}>{mailNotFndContacts}</span>.<br /><span>{getLocalizedStrings().message.DOCUSIGN.DOCUSIGN_CONTACT_MESSAGE_2}</span></span>
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                } else if (emptyEmailList.length > 0) {
                    let confirmMsg = <span><span>{getLocalizedStrings().message.DOCUSIGN.DOCUSIGN_CONTACT_MESSAGE_1}</span><span style={{ fontWeight: 'bold' }}>{mailNotFndContacts}</span>.<br /><span>{getLocalizedStrings().message.DOCUSIGN.DOCUSIGN_CONTACT_MESSAGE_1}</span></span>
                    sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, confirmMsg, () => sendTemplate(), null);
                } else {
                    sendTemplate();
                }
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.DOCUSIGN.PLEASE_SELECT_CONTACT, null);
            }

        } catch (error) {
            console.log("Error in 'Docusign.js -> sendTemplateWithValidate()':" + error);
        }
    }

    const sendTemplate = () => {
        try {
            let notetext = predefinedNoteText;
            notetext = (notetext !== null && notetext !== undefined && notetext.trim() !== "") ? notetext.trim() : "";
            let templateId = (isValidParam(selectedOption) && isValidParam(selectedOption.value)) ? selectedOption.value : null;
            let templateName = (isValidParam(selectedOption) && isValidParam(selectedOption.label)) ? selectedOption.label : null;
            let contactLength = getArrayParam(selectedContact).length;
            if (templateId !== null && templateId !== undefined && templateId !== "" && templateName !== null && templateName !== undefined && templateName !== "" && contactLength > 0) {
                if (templateName.trim().length > 65) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.DOCUSIGN.TEMPLATE_NAME_LENTH, null);
                } else {
                    setIsMounted(false);
                    let params = {
                        recipient: selectedContact,
                        template_id: templateId,
                        template_name: templateName,
                        parent_record_id: detailView.data.record.id,
                        custom_note: notetext,
                        parent_object: parentObject,
                    }
                    let promise = Promise.resolve(HTTPClient.post(endPoints.DOCUSIGN.ENVELOP_SEND, params));
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            let status = response.status;
                            if (status === -1) {
                                //let msg = getLocalizedStrings().message.DOCUSIGN.UNABLE_TO_SEND;
                                dispatch(refreshDetailViewListViewData(true));
                                dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                closeDrawer();
                            } else {
                                let data = isValidParam(response.data.data) ? response.data.data : null;
                                if (data !== null) {
                                    dispatch(refreshDetailViewListViewData(true));
                                    dispatch(showCustomSnackBar(data, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                    closeDrawer();
                                }
                            }
                        }
                    });
                }
            } else {
                if (contactLength > 0) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.DOCUSIGN.PLEASE_SELECT_TEMPLATE, null);
                } else {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.DOCUSIGN.PLEASE_SELECT_CONTACT, null);
                }
            }
        } catch (error) {
            console.log("Error in 'Docusign.js -> sendTemplate()':" + error);
        }
    }

    const changeNoteText = (event) => {
        setPredefinedNoteText(event.target.value);
    }

    const selectContacts = (event, options) => {
         setSelectedContact(options != null ? options : []);

    }

    const processContacts = () => {
        var contactlist = detailView.data.avaliableContacts;
        let userObj = null;
        var options = [];
        map(contactlist, function (userObject, i) {
            if (getStringParam(userObject.email).trim() != '') {
                userObj = {};
                userObj.value = userObject.id.toString();
                userObj.id = userObject.id.toString();
                userObj.recipient_email = userObject.email;
                if (userObject.full_name === '' || userObject.full_name === null || (userObject.first_name === '' && userObject.last_name === '')) {
                    userObj.label = 'Empty';
                    userObj.title = 'Empty';
                    userObj.recipient_name = 'Empty';
                } else {
                    userObj.label = userObject.full_name;
                    userObj.title = userObject.full_name;
                    userObj.recipient_name = userObject.full_name;
                }
                options.push(userObj);
            }
        });
        sortArrayObjectByProperty(options, 'label');
        if (childRecordId !== undefined && childRecordId !== null && childRecordId !== '' && childRecordId.length !== 0) {
            var selectOptions = childRecordId;
            var tempOptions = [];
            map(options, function (userObject, i) {
                selectOptions.map((key, index) => {
                    let userObj = null;
                    if (key == userObject.value) {
                        userObj = {};
                        userObj = userObject;
                        tempOptions.push(userObj);
                    }
                })
            });
           
            setAvailableContacts(options);
                    setSelectedContact(tempOptions);
        } else {
           
            setAvailableContacts(options);
        }
    }

    let title = "";
    title = getLocalizedStrings().message.DOCUSIGN.DOCUSIGN_SETUP_TITLE_1;
    title += getLocalizedStrings().message.DOCUSIGN.DOCUSIGN_SETUP_TITLE_3;
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2


    return (
        <div style={{ marginLeft: '42px' }}>
            {isMounted && <div>  

            {isDocuSignConnected &&
                <div id="header" style={{ marginLeft: '3%', fontSize: '14px' }}>
                    {title}
                </div>
            }
            {selectedOption != null &&
                <div>
                    {
                        (!isDocuSignConnected && !isNoTemplateFound) &&
                        <div style={{ paddingLeft: '12px', paddingTop: '5px' }}>
                            <div style={{ textAlign: 'left', fontSize: '14px' }}>{getLocalizedStrings().label.DOCUSIGN.DOCUSIGN_CONNECT_TITLE}</div>
                            <div style={{ textAlign: 'left', paddingTop: '12px' }}>
                                <Button
                                    style={styles.primaryButton}
                                    onClick={() => openConfiguration(true)}
                                >{getLocalizedStrings().label.DOCUSIGN.CONNECT_BUTTON}</Button>
                            </div>
                        </div>
                    }
                    {
                        (isNoTemplateFound) &&
                        <div>
                            <NoRecord object={constants.DOCUSIGN} parentObject={constants.ACCOUNTS_OBJECT} />
                        </div>
                    }
                    {   (isDocuSignConnected) &&
                        <div className="col-sm-11" style={{ display: 'inline-block', paddingTop: '14px', float: 'left', paddingBottom: '20px' }}>
                            <FormControl style={{ width: '100%', height: '42px' }} className="test" noValidate autoComplete="off">
                                        <Autocomplete
                                            className="tempList"
                                            value={selectedOption}
                                            options={menuItemTemplates}
                                            onChange={(event, options) => changeTeplateList(event, options)}
                                            getOptionLabel={(option) => option.label}
                                            disableClearable={false}
                                            renderInput={(params) => <TextField {...params}
                                                label={getLocalizedStrings().label.DOCUSIGN.SELECT_TEMPLATE}
                                                variant="outlined" size='small'  margin="dense" className={"sf-fields-bg"} />
                                            }
                                        />
                                    </FormControl>
                        </div>
                    }

                    {/* {(isDocuSignConnected) &&
                         <div>
                        
                        {selectedContact.length > 0 &&
                        <div className="col-sm-11" style={{ display: 'inline-block', paddingTop: '14px', float: 'left', paddingBottom: '20px' }}>
                             <Autocomplete
                                id={"contactList"}
                                name={"contactList"}
                                key={"contactList"}
                                multiple
                                className="contactList"
                                value={selectedContact}
                                options={availableContacts.filter(x => !selectedContact.filter(y => y.recipient_email === x.recipient_email).length)}
                                onChange={(event,options) => selectContacts(event,options)}
                                getOptionLabel={(option) => option.label}
                                disableClearable={false}
                                renderInput={(params) => <TextField {...params}
                                    label={getLocalizedStrings().label.COMMON.SELECT_CONTACTS}
                                    variant="outlined" size='small' margin="dense" className={"sf-fields-bg"} />
                                }
                            /> 
                
                            
                    </div>
                        }
                  
                   
                    { 
                    selectedContact.length == 0 &&
                    <Autocomplete
                            id={"contactList"}
                            name={"contactList"}
                            key={"contactList"}
                            multiple
                            className="contactList"
                            value={selectedContact}
                            options={availableContacts.filter(x => !selectedContact.filter(y => y.recipient_email === x.recipient_email).length)}
                            onChange={(event, options) => selectContacts(event, options)}
                            getOptionLabel={(option) => option.label}
                            disableClearable={false}
                            style={{width:'87%', marginLeft:'14px'}}
                            renderInput={(params) => <TextField {...params}
                                label={getLocalizedStrings().label.COMMON.SELECT_CONTACTS}
                                variant="outlined" size='small' margin="dense" className={"sf-fields-bg"} />
                            }
                        />
                    }
                      </div>
                    } */}


                    {isDocuSignConnected && (
                        <div>
                            <Autocomplete
                                id="contactList"
                                name="contactList"
                                multiple
                                className="contactList"
                                value={selectedContact}
                                options={availableContacts.filter( (x) => !selectedContact.filter((y) => y.recipient_email === x.recipient_email).length )}
                                onChange={(event, options) => selectContacts(event, options)}
                                getOptionLabel={(option) => option.label}
                                disableClearable={false}
                                style={{ width: '85%', marginLeft: '18px' }}
                                renderInput={(params) => ( <TextField  {...params}
                                label={getLocalizedStrings().label.COMMON.SELECT_CONTACTS}
                                variant="outlined" size="small" margin="dense" className="sf-fields-bg"
                                />
                                )}
                            />
                        </div>
                    )}

                      {
                        (isDocuSignConnected) &&
                        <div>
                            <div className="col-sm-11" style={{ paddingRight: '14px', marginTop: '15px' }}>
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        value={predefinedNoteText}
                                        onChange={(event) => changeNoteText(event)}
                                        type='textarea'
                                        multiline={true}
                                        rows={12}
                                        fullWidth={true}
                                        id="notetext"
                                        textareastyle={{ marginTop: '10px', height: '90%' }}
                                        inputStyle={{ overflowY: 'auto' }}
                                        label={getLocalizedStrings().label.DOCUSIGN.ADD_NOTE}
                                        autoComplete="new-password"
                                        className={"sf-fields-bg"}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    }
                    {
                        (isDocuSignConnected) &&
                        <div className="col-sm-4" style={{ float: 'right', paddingTop: '14px', paddingBottom: '14px' }}>
                            <Button

                                style={styles.primaryButton}
                                onClick={() => {
                                    sendTemplateWithValidate()
                                }}
                            >{getLocalizedStrings().label.DOCUSIGN.SEND}</Button>
                        </div>
                    }
                </div>
            }
            </div> }
            {!isMounted &&
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            }
            
        </div>
    );

}


export default Docusign;