import { getObjectParam } from '..//helper/parameterVerifier';
import { getActiveTabInfo } from '..//helper/sfTabManager';
import { actions } from '..//constants/actions';

/* eslint-disable new-cap */
const initialState = {
  isFetching: false,
  taskLists: [],
  lists: [],
  isDragging: false,
  workflowName: '',
  fields: [],
  queryName: '',
  queryType: '',
  groupByFieldName: '',
  totalCount: 0,
  mounted: false,
  object: '',
  view: '',
  isFilterChanged: false,
  sort_field_name: '',
  sort_type: '',
  scrollPosition: {},
  HorizontalscrollPosition: {},
  workFlowId: -9999,
  taskFields:[]
};
/* eslint-enable new-cap */


export default function lists(state = initialState, action) {
  switch (action.type) {
    case actions.GET_LISTS_START:
      return {
        ...state,
        isFetching: action.isFetching,
        mounted: action.mounted,
      }
    case actions.GET_LISTS:
      return {
        ...state,
        lists: action.payload.lists,
        sort_field_name: action.payload.sort_field_name,
        sort_type: action.payload.sort_type,
        isFetching: action.payload.isFetching,
        workflowName: action.payload.workflowName,
        totalForecast: getTotalForecastAmount(action.payload.lists, action.state),
        fields: action.payload.fields,
        communicationFields: action.payload.communicationFields,
        selectedFields: action.payload.selectedFields,
        queryName: action.payload.queryName,
        queryType: action.payload.queryType,
        groupByFieldName: action.payload.groupByFieldName,
        totalCount: action.payload.totalCount,
        mounted: action.payload.mounted,
        salesRep: action.payload.sales_rep,
        object: action.payload.object,
        view: action.payload.view,
        isFilterChanged: action.payload.isFilterChanged,
        actions: action.payload.actions,
        totalLeadScore: action.payload.totalLeadScore,
        queryInfo: action.payload.queryInfo,
        workFlowId: action.payload.workFlowId,
      }
    case actions.MOVE_CARD: {
      let newLists = [...state.lists];
      let { lastX, lastY, nextX, nextY } = action;
      if (lastX === nextX) {
        newLists[lastX].cards.splice(nextY, 0, newLists[lastX].cards.splice(lastY, 1)[0]);
      } else {

        //element to new place
        let moveCard = newLists[lastX].cards[lastY];
        if (moveCard.hasOwnProperty('t_status')) {
          moveCard.t_status = newLists[nextX].columnName;
        }

        newLists[nextX].cards.splice(nextY, 0, moveCard);
        newLists[nextX].total_count += 1;
        // delete element from old place
        newLists[lastX].cards.splice(lastY, 1);
        newLists[lastX].total_count -= 1;
      }
      return {
        ...state,
        lists: newLists
      }
    }
    case actions.MOVE_LIST: {
      const newLists = [...state.lists];
      const { lastX, nextX } = action;
      const t = newLists.splice(lastX, 1)[0];

      newLists.splice(nextX, 0, t);
      return {
        ...state,
        lists: newLists
      }
    }
    case actions.COLUMN_VIEW_OPPORTUNITIES_UPDATE: {
      let _lists = [...state.lists];
      let { lastX, lastY, nextX, nextY } = action.payload.moveProps;
      let params = action.payload.params;
      let id = params.id;
      let _selectedList = { ..._lists[lastX] };
      if (_selectedList && _selectedList.hasOwnProperty('cards')) {
        let updatedCards = _selectedList.cards.map((f) => {
          if (f.id == id) {
            f.closeDate = params.close_date;
          }
          return f;
        });
        _selectedList.cards = updatedCards;
        _lists[lastX] = _selectedList;
      }

      if (lastX === nextX) {
        _lists[lastX].cards.splice(nextY, 0, _lists[lastX].cards.splice(lastY, 1)[0]);
      } else {
        let moveCard = _lists[lastX].cards[lastY];
        if (moveCard.hasOwnProperty('t_status')) {
          moveCard.t_status = _lists[nextX].columnName;
        }
        _lists[nextX].cards.splice(nextY, 0, moveCard);
        _lists[nextX].total_count += 1;
        if (moveCard.hasOwnProperty('foreCastAmount')) {
          let cardOppAmount = currencyToFloatValue(moveCard.foreCastAmount, action.state);
          cardOppAmount = isNaN(cardOppAmount) ? 0 : cardOppAmount;
          let lastColumnOppAmount = currencyToFloatValue(_lists[lastX].opportunityAmount, action.state);
          lastColumnOppAmount = isNaN(lastColumnOppAmount) ? 0 : lastColumnOppAmount;
          let nextColumnOppAmount = currencyToFloatValue(_lists[nextX].opportunityAmount, action.state);
          nextColumnOppAmount = isNaN(nextColumnOppAmount) ? 0 : nextColumnOppAmount;
          lastColumnOppAmount = lastColumnOppAmount - cardOppAmount;
          nextColumnOppAmount = nextColumnOppAmount + cardOppAmount;
          _lists[lastX].opportunityAmount = currencyFormat(lastColumnOppAmount, action.state);
          _lists[nextX].opportunityAmount = currencyFormat(nextColumnOppAmount, action.state);
        }
        _lists[lastX].cards.splice(lastY, 1);
        _lists[lastX].total_count -= 1;
      }
      return {
        ...state,
        lists: _lists
      }
    }
    case actions.COLUMN_VIEW_TASK_OPPORTUNITIES_UPDATE: {
      let _lists = [...state.taskLists];
      let { lastX, lastY, nextX, nextY } = action.payload.moveProps;
      let params = action.payload.params;
      let id = params.id;
      let _selectedList = { ..._lists[lastX] };
      if (_selectedList && _selectedList.hasOwnProperty('cards')) {
        let updatedCards = _selectedList.cards.map((f) => {
          if (f.id == id) {
            f.closeDate = params.close_date;
          }
          return f;
        });
        _selectedList.cards = updatedCards;
        _lists[lastX] = _selectedList;
      }

      if (lastX === nextX) {
        _lists[lastX].cards.splice(nextY, 0, _lists[lastX].cards.splice(lastY, 1)[0]);
      } else {
        let moveCard = _lists[lastX].cards[lastY];
        if (moveCard.hasOwnProperty('t_status')) {
          moveCard.t_status = _lists[nextX].columnName;
        }
        _lists[nextX].cards.splice(nextY, 0, moveCard);
        _lists[nextX].total_count += 1;
        if (moveCard.hasOwnProperty('foreCastAmount')) {
          let cardOppAmount = currencyToFloatValue(moveCard.foreCastAmount, action.state);
          cardOppAmount = isNaN(cardOppAmount) ? 0 : cardOppAmount;
          let lastColumnOppAmount = currencyToFloatValue(_lists[lastX].opportunityAmount, action.state);
          lastColumnOppAmount = isNaN(lastColumnOppAmount) ? 0 : lastColumnOppAmount;
          let nextColumnOppAmount = currencyToFloatValue(_lists[nextX].opportunityAmount, action.state);
          nextColumnOppAmount = isNaN(nextColumnOppAmount) ? 0 : nextColumnOppAmount;
          lastColumnOppAmount = lastColumnOppAmount - cardOppAmount;
          nextColumnOppAmount = nextColumnOppAmount + cardOppAmount;
          _lists[lastX].opportunityAmount = currencyFormat(lastColumnOppAmount, action.state);
          _lists[nextX].opportunityAmount = currencyFormat(nextColumnOppAmount, action.state);
        }
        _lists[lastX].cards.splice(lastY, 1);
        _lists[lastX].total_count -= 1;
      }
      return {
        ...state,
        taskLists: _lists
      }
    }
    case actions.TOGGLE_DRAGGING: {
      return {
        ...state,
        isDragging: action.isDragging
      }
    }
    case actions.USER_LIST: {
      return {
        ...state,
        userList: action.userList
      }
    }
    case actions.WORKFLOW_LIST: {
      return {
        ...state,
        wokflowList: action.workflowList
      }
    }
    case actions.MOUNTED: {
      return {
        ...state,
        mounted: action.payload.mounted
      }
    }
    case actions.COLUMNVIEW_FILTER_CHANGE: {
      return {
        ...state,
        isFilterChanged: action.payload.isFilterChanged
      }
    }
    case actions.FETCHED: {
      return {
        ...state,
        isFetching: action.payload.isFetching,
      }
    }
    case actions.COLUMN_VIEW_TASK_UPDATE: {
      return {
        ...state,
        lists: action.list,
      }
    }
    case actions.COLUMN_VIEW_TASK_LIST_UPDATE: {
      return {
        ...state,
        taskLists: action.tasklist,
      }
    }
    case actions.COLUMN_VIEW_CONTACT_UPDATE: {
      return {
        ...state,
        lists: action.list,
      }
    }
    case actions.UPDATE_COLUMN_VIEW_SCROLL_POSITION: {
      return {
        ...state,
        scrollPosition: action.payload.pos
      }
    }
    case actions.REMOVE_COLUMN_VIEW_SCROLL_POSITION: {
      delete state.scrollPosition[action.payload.obj]
      return {
        ...state,
      }
    }
    case actions.UPDATE_COLUMN_VIEW_HORIZONTAL_SCROLL_POSITION: {
      return {
        ...state,
        HorizontalscrollPosition: action.payload.pos
      }
    }
    case actions.REMOVE_COLUMN_VIEW_HORIZONTAL_SCROLL_POSITION: {
      delete state.HorizontalscrollPosition[action.payload.obj]
      return {
        ...state,
      }
    }
    case actions.GET_TASK_LISTS:
      return {
        ...state,
        taskLists: action.payload.taskLists,
        sort_field_name: action.payload.sort_field_name,
        sort_type: action.payload.sort_type,
        isFetching: action.payload.isFetching,
        workflowName: action.payload.workflowName,
        totalForecast: getTotalForecastAmount(action.payload.lists, action.state),
        taskFields: action.payload.taskFields,
        communicationFields: action.payload.communicationFields,
        selectedFields: action.payload.selectedFields,
        queryName: action.payload.queryName,
        queryType: action.payload.queryType,
        groupByFieldName: action.payload.groupByFieldName,
        totalCount: action.payload.totalCount,
        mounted: action.payload.mounted,
        salesRep: action.payload.sales_rep,
        object: action.payload.object,
        view: action.payload.view,
        isFilterChanged: action.payload.isFilterChanged,
        actions: action.payload.actions,
        totalLeadScore: action.payload.totalLeadScore,
        queryInfo: action.payload.queryInfo,
        workFlowId: action.payload.workFlowId,
    }
    case actions.SET_LIST_OBJECT:
      return {
        ...state,
        object: action.payload.object,
    }
    case actions.GET_TASK_LISTS_START:
      return {
        ...state,
        isFetching: action.isFetching,
        lists: []
    }
    case actions.MOVE_TASK_CARD: {
      let newLists = [...state.taskLists];
      let { lastX, lastY, nextX, nextY } = action;
      if (lastX === nextX) {
        newLists[lastX].cards.splice(nextY, 0, newLists[lastX].cards.splice(lastY, 1)[0]);
      } else {

        //element to new place
        let moveCard = newLists[lastX].cards[lastY];
        if (moveCard.hasOwnProperty('t_status')) {
          moveCard.t_status = newLists[nextX].columnName;
        }

        newLists[nextX].cards.splice(nextY, 0, moveCard);
        newLists[nextX].total_count += 1;
        // delete element from old place
        newLists[lastX].cards.splice(lastY, 1);
        newLists[lastX].total_count -= 1;
      }
      return {
        ...state,
        taskLists: newLists
      }
    }
    case actions.MOVE_TASK_LIST: {
      const newLists = [...state.taskLists];
      const { lastX, nextX } = action;
      const t = newLists.splice(lastX, 1)[0];

      newLists.splice(nextX, 0, t);
      return {
        ...state,
        taskLists: newLists
      }
    }
    default:
      return state;
  }
}

function getTotalForecastAmount(lists, state) {
  let info = getObjectParam(getActiveTabInfo(state));
  let total_forecast_amount = 0;
  if (!!lists && lists.length > 0) {
    if (info.reportBy === 'sales_rep') {
      total_forecast_amount = lists[0].grand_total;
    } else {
      let lastIndex = lists.length - 1;
      total_forecast_amount = lists[lastIndex].grand_total;
    }
  }
  return total_forecast_amount;
}

function currencyFormat(num, state) {
  let app = state.app;
  let currency = app.me.currency_symbol;
  return currency + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function currencyToFloatValue(currencyVal, state) {
  currencyVal = currencyVal + "";
  if (currencyVal === '') {
    return parseFloat('0');

  }
  let app = state.app;
  let currency = app.me.currency_symbol;
  currencyVal = currencyVal.replace(currency, '');
  currencyVal = currencyVal.replace(/,/g, '');
  const floatCurrency = parseFloat(currencyVal);
  return floatCurrency;
}