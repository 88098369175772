import { actions } from '../constants/actions';
import { isValidParam } from '../helper/parameterVerifier';
import * as HTTPClient from '..//helper/httpClient';
import { endPoints } from '../constants/endPoints';

const getLinkIntakeformData = (encodedParams) => {
    if (encodedParams) {
        return (dispatch) => {
            const promise = Promise.resolve(
                HTTPClient.getWithoutToken(endPoints.INTAKE_FORM.GET, {
                    encodedParams: encodedParams,
                }),
            );
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        dispatch({
                            type: actions.SET_LINK_INTAKE_FORM_DATA,
                            payload: {
                                linkIntakeFormData: response,
                                isLoading: false
                            }
                        });
                    }
                });
            }
        };
    } 
};

const getIntakeformData = (intakeId, otherId, reduxDetailViewID) => {
    const params = { intake: intakeId ? intakeId : 0, other: otherId ? otherId : 0};
    const autoFillValuesParams = {object:"customobjectone", parent_object:"accounts", parent_record_id: reduxDetailViewID} 
        return (dispatch) => {
            const promise = Promise.resolve(HTTPClient.get(endPoints.INTAKE_FORM.GET, params));
            const autoFillPromise = Promise.resolve(HTTPClient.get(endPoints.INTAKE_FORM.GET_CONTACT_AUTOFILL_VALUES, autoFillValuesParams));

            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        dispatch({
                            type: actions.SET_INTAKE_FORM_DATA,
                            payload: {
                                intakeForm: response,
                                isLoading: false
                            },
                        });
                    }
                });
            }

            if (isValidParam(autoFillPromise)) {
                autoFillPromise.then((response) => {
                    if (isValidParam(response)) {
                        dispatch({
                            type: actions.SET_CUSTOM_TABLE_ONE_AUTOFILL_DATA,
                            payload: {
                                customAutofillData: response.records,
                            },
                        });
                    }
                });
            }
        };
}


const setSelectedIntakeForm = (form) => {
    return (dispatch) => {
        dispatch({
            type: actions.SET_SELECTED_INTAKE_FORM,
            payload: {
                selectedForm: form,
            },
        });
    };
};

const DeleteIntakeFormData = (data) =>{
    return (dispatch) => {
        dispatch({
            type: actions.DELETE_INTAKE_FORM_DATA,
            payload: {
                IntakeData: data,
            },
        });
    };
}

const setIsErrorIntakeForm = () =>{
    return (dispatch) =>{
        dispatch({
            type: actions.SET_IS_ERROR_INTAKE_FORM,
            payload:{
                isError: {},
            }
        })
    }
}

const saveIntakeFormId = (savedIntakeFormId, savedOtherFormId) => {
    return (dispatch) => {
        dispatch({
            type: actions.SAVE_INTAKE_FORM_ID,
            payload: {
                intakeFormId: savedIntakeFormId,
                otherFormId: savedOtherFormId,
                isLoading: false
            }
        })
    }
}

const setIsLoading = (value) => {
    return (dispatch) => {
        dispatch({
            type: actions.SET_IS_LOADING,
            payload: {
                isLoading: value
            }
        })
    }
}

export { setSelectedIntakeForm,DeleteIntakeFormData, getLinkIntakeformData, saveIntakeFormId, getIntakeformData, setIsLoading ,setIsErrorIntakeForm};
