
import React from 'react';
import { setHeader } from '../../../services/actions/headerActions';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { isValidParam, getIntParam } from '../../../services/helper/parameterVerifier';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
 
const PreviewNews =(object,type,Id)=>{
    const dispatch = useDispatch();
    const [newsTitle, setNewsTitle] = useState("");
    const [newsDetails, setNewsDetails] = useState("");
    const [mounted, setMounted] = useState(false);
    const appState = useSelector((state) => state.app);


    useEffect(() => {
        let user_type = null;
            let id = getIntParam(Id);
            if(id > 0) {
                user_type = appState.me.module_access.HUB === 1 ? 'franchise' : 'franchisor';
                let promise = Promise.resolve(HTTPClient.get(endPoints.NEWS.GET + '/'+user_type+"/" + id));
                if(isValidParam(promise)) {
                    promise.then((response) => {
                        setNewsTitle(response.title);
                        setNewsDetails(response.news_details);
                        setMounted(true)
                        const title = <span title={response.title} style = {{textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block',
                        whiteSpace: 'nowrap',width: '94%', position: 'absolute'}}>{response.title}</span>;
                        dispatch(setHeader(object, title , null, false, true));
                    });
                } 
            }       
      }, []);
        let pageHeight = window.innerHeight - 247;
        let top = (pageHeight-10) / 2;
        if (!mounted) {
            return (
            <div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
           </div>
            )
        } else {
            return (
                <div id="preview" style={{ backgroundColor: '#F5F5F5', padding: '15px', paddingLeft: 21 , paddingTop: 5 }}>
                    <table width="100%" border="0" cellSpacing="0" cellPadding="0" align="center">
                        <tr>
                            <td>
                                <div className="preview_content_box">
                                    <div id="main">
                                        <div id="msgBody" className="divBody">
                                            <p dangerouslySetInnerHTML=
                                                {{ __html: (newsDetails) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>

            );
        }

}

export default PreviewNews;

