import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { Menu, Select, MenuItem, InputLabel } from '@mui/material';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { styles } from '../../../services/constants/styles';
import { isValidParam, getIntParam, isInvalidParam, getObjectParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { setCalendarSetup, saveCalendarSetup } from '../../../services/actions/appActions';
import { connect } from 'react-redux';
import ShowCircularProgress from '../components/circularProgress';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { getCalendarRefresh, setCalendarInfo, getJobCalendarRefresh,setSelectedDate } from '../../../services/actions/calendarActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { getTasksRefresh, getTasksPortal } from '../../../services/actions/taskActions';
import { showTimeZoneMap } from '../../../services/helper/utils';
import SuperSelectField from '../components/sfSuperSelect';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { useDispatch, useSelector } from "react-redux";
import { refreshColumnView } from "../../../services/actions/listsActions";
import { getCookie, setCookie } from '../../../services/helper/sfCookies';

const CalenderSettings = (props) => {
    const duration = [{ key: '1 AM', label: '1 AM' },
    { key: '2 AM', label: '2 AM' },
    { key: '3 AM', label: '3 AM' },
    { key: '4 AM', label: '4 AM' },
    { key: '5 AM', label: '5 AM' },
    { key: '6 AM', label: '6 AM' },
    { key: '7 AM', label: '7 AM' },
    { key: '8 AM', label: '8 AM' },
    { key: '9 AM', label: '9 AM' },
    { key: '10 AM', label: '10 AM' },
    { key: '11 AM', label: '11 AM' },
    { key: '12 PM', label: '12 PM' },
    { key: '13 PM', label: '1 PM' },
    { key: '14 PM', label: '2 PM' },
    { key: '15 PM', label: '3 PM' },
    { key: '16 PM', label: '4 PM' },
    { key: '17 PM', label: '5 PM' },
    { key: '18 PM', label: '6 PM' },
    { key: '19 PM', label: '7 PM' },
    { key: '20 PM', label: '8 PM' },
    { key: '21 PM', label: '9 PM' },
    { key: '22 PM', label: '10 PM' },
    { key: '23 PM', label: '11 PM' },
    { key: '0 AM', label: '12 AM' },

    ];

    const defaultSetupData = [{
        description: null,
        endHour: 17,
        endHourMeridiem: "PM",
        id: 0,
        location: null,
        startHour: 9,
        startHourMeridiem: "AM",
        sortOrder:'Date',
        defaultView:'Expanded',
    }];
    let colorCodeList = [
        {
            value: 'Users',
            label: 'Users',
        },
        {
            value: 'Types',
            label: 'Types'
        }
    ];
    let groupByList = [
        {
            value: 'None',
            label: 'None',
        },
        {
            value: 'Users',
            label: 'Users'
        },
        {
            value: 'Types',
            label: 'Types'
        }
    ];
    let sortList = [
        { id: 'Date', label: getLocalizedStrings().label.APPOINTMENTS_TASKS.PERSONALIZE_SORT_1 }, 
        { id: 'Priority', label: getLocalizedStrings().label.APPOINTMENTS_TASKS.PERSONALIZE_SORT_2 }
    ];

    let defaltViewList = [
        { id: 'Expanded', label: 'Expanded'}, 
        { id: 'Collapsed', label: 'Collapsed' }
    ];
    const dispatch = useDispatch();
    const userlist = useSelector((state) => state.userlist);
    const app = useSelector((state) => state.app);
    const calendar = useSelector((state) => state.calendar);
    const appContainer = useSelector((state) => state.appContainer);

    const [start_time, setstart_time] = useState(null);
    const [end_time, setend_time] = useState(null);
    const [calenderSetup, setcalenderSetup] = useState(defaultSetupData);
    const [setupId, setsetupId] = useState(0);
    const [isloading, setisloading] = useState(true);
    const [isAccount_Name, setisAccount_Name] = useState(0);
    const [isContact_Name, setisContact_Name] = useState(0);
    const [isOpportunity_Name, setisOpportunity_Name] = useState(0);
    const [isJob_Name, setisJob_Name] = useState(0);
    const [taskArr, settaskArr] = useState([]);
    const [appArr, setappArr] = useState([]);
    const [jobAppArr, setjobAppArr] = useState([]);
    const [selected_user_final, setselected_user_final] = useState([
        { value: app.me.id, label: app.me.name },
        { value: -8888, label: 'Campaigns' }
    ]);
    const [selected_user, setselected_user] = useState([
        { value: app.me.id, label: app.me.name },
        { value: -8888, label: 'Campaigns' }
    ]);
    const [appTypes, setappTypes] = useState([]);
    const [stateuserList, setstateuserList] = useState([]);
    const [colorCode, setcolorCode] = useState(null);
    const [superSelectUserlist, setsuperSelectUserlist] = useState([]);
    const [selected_show, setselected_show] = useState([{ value: 'Scheduled', label: getLocalizedStrings().label.CALENDAR.SHOW_OPTION_3 }]);
    const [showOptions, setshowOptions] = useState([
        { value: 'Cancelled', label: getLocalizedStrings().label.CALENDAR.SHOW_OPTION_1 },
        { value: 'Completed', label: getLocalizedStrings().label.CALENDAR.SHOW_OPTION_2 },
        { value: 'Scheduled', label: getLocalizedStrings().label.CALENDAR.SHOW_OPTION_3 }
    ]);
    const [isAllTakView, setIsAllTakView] = useState(false)

    useEffect(() => {
        let userOptions = [];
        console.log("props.data.propsTaskApp>>>>>>>>>>>",props.data.propsTaskApp)
        userListUpdate();
        if (app.me.calendar_setup.length > 0) {
            setDefaultData();
        }
        else {
            let type = props.data.type;
            let userArr = [];
            let typeArr = [];
            let appTypes = [];
            let showArr = [];

            let tempuserlist = stateuserList.length > 0 ? stateuserList : userlist.data;
            let calendarInfoFromRedux = getObjectParam(calendar.calendarInfo);
            if (calendarInfoFromRedux !== null && calendarInfoFromRedux !== '' &&
                calendarInfoFromRedux.hasOwnProperty('assign') && calendarInfoFromRedux.hasOwnProperty('status')
            ) {
                map(tempuserlist, function (userObject, i) {
                    let obj = {};
                    obj.label = userObject.value;
                    obj.value = userObject.id;
                    userOptions.push(obj);

                });
                if (app.selectedModuleName === 'Marketing') {
                    userOptions.push(
                        { value: -8888, label: 'Campaigns' }
                    );
                }
                userArr = userOptions;
                showArr = calendarInfoFromRedux.status;
                appTypes = calendarInfoFromRedux.appTypes;
            }
            let newArrA = OBJECT_TABLEID_MAP[constants.ACCOUNTS_OBJECT] + '_1' + "," + OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT] + '_0,' + OBJECT_TABLEID_MAP[constants.OPPORTUNITIES_OBJECT] + '_0,' + OBJECT_TABLEID_MAP[constants.JOBS_OBJECT] + '_0';
            let newArrT = OBJECT_TABLEID_MAP[constants.ACCOUNTS_OBJECT] + '_1' + "," + OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT] + '_0,' + OBJECT_TABLEID_MAP[constants.OPPORTUNITIES_OBJECT] + '_0,' + OBJECT_TABLEID_MAP[constants.JOBS_OBJECT] + '_0';
            let newArrJ = OBJECT_TABLEID_MAP[constants.ACCOUNTS_OBJECT] + '_1' + "," + OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT] + '_0,' + OBJECT_TABLEID_MAP[constants.OPPORTUNITIES_OBJECT] + '_0,' + OBJECT_TABLEID_MAP[constants.JOBS_OBJECT] + '_1';
            let tempCalenderSetup = [{
                description: null,
                endHour: 17,
                endHourMeridiem: "PM",
                id: 0,
                location: null,
                startHour: 9,
                startHourMeridiem: "AM",
            }];
            if (type === constants.JOB_SCHEDULER) {
                setisJob_Name(1);
            } else {
                setisJob_Name(0);
            }
            setisAccount_Name(1);
            setisContact_Name(0);
            setisOpportunity_Name(0);
            setCalendarSetup(tempCalenderSetup);
            setisloading(false);
            setstart_time(9 + " " + "AM");
            setend_time(17 + " " + "PM");
            settaskArr(newArrT);
            setappArr(newArrA);
            setjobAppArr(newArrJ);
            setselected_user_final(userArr);
            setselected_user(userArr);
            setselected_show(showArr);
            setappTypes(appTypes);
        }
    }, []);

    const userListUpdate = () => {
        if (stateuserList.length <= 0 && userlist.data.length > 0) {
            let userList = getArrayParam(userlist.data);
            let superSelectUserlist = [];
            let tmpUserlist = [];
            superSelectUserlist.push({ value: -5555, label: 'All' });
            tmpUserlist.push({ value: 'All', label: 'All', id: -5555 });
            userList.map((usr, i) => {
                let obj = {};
                obj.value = usr.id;
                obj.label = usr.value;
                superSelectUserlist.push(obj);
                tmpUserlist.push(usr);
            })
            setstateuserList(tmpUserlist);
            setsuperSelectUserlist(superSelectUserlist);
        }
    }

    const setDefaultData = () => {
        let type = props.data.type;
        let url = endPoints.APPOINTMENT.GET__CALENDAR_SET_UP;
        let promise = Promise.resolve(HTTPClient.get(url, null));
        let userArr = [];
        let typeArr = [];
        let showArr = [];
        let responseUserArr = [];
        let responseTypeArr = [];
        let responseStatusArr = [];
        let count = 0;
        let appTypes = [];
        let calendarInfoFromRedux = getObjectParam(calendar.calendarInfo);
        if (calendarInfoFromRedux !== null && calendarInfoFromRedux !== '' &&
            calendarInfoFromRedux.hasOwnProperty('assign') && calendarInfoFromRedux.hasOwnProperty('status')) {
            userArr = calendarInfoFromRedux.assign;
            showArr = calendarInfoFromRedux.status;
            appTypes = calendarInfoFromRedux.appTypes;
            if (userArr !== null && userArr.length > 0) {

                if (appTypes !== null && appTypes.length > 0) {
                    let isAllType = false;
                    map(appTypes, function (typeObject, i) {
                        if (typeObject.value === -9999) {
                            isAllType = true;
                        }
                    });
                    if (isAllType) {
                        appTypes = appTypes.filter(function (el) {
                            return el.value !== -9999;
                        });
                    }
                }

                setselected_user_final(userArr);
                setselected_user(userArr);
                setappTypes(appTypes);
                setselected_show(showArr);
            }

        }
        let checkInfoArr = [];
        let selectedUserArr = [];
        let selectedTypeArr = [];
        let selectedStatusArr = [];
        let isAccount = 1;

        let isContact = 0;
        let isJobs = 0;
        let isOpportunity = 0;
        if (type === constants.JOB_SCHEDULER) {
            isJobs = 1;
        } else {
            isJobs = 0;
        }



        let newArrA = OBJECT_TABLEID_MAP[constants.ACCOUNTS_OBJECT] + '_1' + "," + OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT] + '_0,' + OBJECT_TABLEID_MAP[constants.OPPORTUNITIES_OBJECT] + '_0,' + OBJECT_TABLEID_MAP[constants.JOBS_OBJECT] + '_0';
        let newArrT = OBJECT_TABLEID_MAP[constants.ACCOUNTS_OBJECT] + '_1' + "," + OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT] + '_0,' + OBJECT_TABLEID_MAP[constants.OPPORTUNITIES_OBJECT] + '_0,' + OBJECT_TABLEID_MAP[constants.JOBS_OBJECT] + '_0';
        let newArrJ = OBJECT_TABLEID_MAP[constants.ACCOUNTS_OBJECT] + '_1' + "," + OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT] + '_0,' + OBJECT_TABLEID_MAP[constants.OPPORTUNITIES_OBJECT] + '_0,' + OBJECT_TABLEID_MAP[constants.JOBS_OBJECT] + '_1';
        let temptaskArr = taskArr;
        let tempappArr = appArr;
        let tempjobAppArr = jobAppArr;

        promise.then(response => {
            if (isValidParam(response)) {
                if (response.length > 0) {
                    let userList = stateuserList.length > 0 ? stateuserList : userlist.data;
                    let start_time = response[0].startHour + ' ' + response[0].startHourMeridiem;
                    let end_time = response[0].endHour + ' ' + response[0].endHourMeridiem;
                    temptaskArr = response[0].taskInfo;
                    tempappArr = response[0].appInfo;
                    tempjobAppArr = response[0].jobAppInfo;

                    if (tempjobAppArr === null && tempjobAppArr === undefined) {
                        tempjobAppArr = newArrJ;
                    }

                    if (
                        app.selectedModuleName === 'Marketing') {
                        let tempObj = userList.find(e => e = -8888);
                        if (tempObj != null && tempObj.id !== -8888) {
                            userList.unshift({ value: -8888, label: 'Campaigns', id: -8888 });
                        }

                    }
                    if (response[0].selectedUser !== null && response[0].selectedUser !== undefined) {
                        selectedUserArr = response[0].selectedUser.split(',');

                        count = selectedUserArr.find(e => e = -8888);
                        if (app.selectedModuleName !== 'Marketing' && count === -8888) {
                            selectedUserArr = selectedUserArr.filter(f => { return f !== -8888 });
                        }
                        
                        selectedUserArr.map((item, i) => {
                            let selectedUser = userList.filter(user => user.id == item ); // eslint-disable-line
                            if (selectedUser.length > 0) {
                                let userObj = {};

                                userObj.value = selectedUser[0].id;

                                if (item === -8888) {
                                    userObj.label = selectedUser[0].label;
                                } else {
                                    userObj.label = selectedUser[0].value;
                                }
                                responseUserArr.push(userObj);
                            }
                        });

                        if (app.selectedModuleName === 'Marketing') {
                            let tempObj = responseUserArr.find(e => e = -8888);
                            if (tempObj != null && tempObj.value === -8888) {
                                if (responseUserArr.length === (userList.length - 1)) {
                                    responseUserArr.unshift({ value: -5555, label: 'All' });
                                }
                            } else {
                                if (responseUserArr.length === (userList.length - 2)) {
                                    responseUserArr.unshift({ value: -5555, label: 'All' });
                                }
                            }

                        } else {
                            if (responseUserArr.length === (userList.length - 1)) {
                                responseUserArr.unshift({ value: -5555, label: 'All' });
                            }

                        }
                    } else {
                        responseUserArr = selected_user;
                    }

                    if (response[0].selectedStatus !== null && response[0].selectedStatus !== undefined) {
                        selectedStatusArr = response[0].selectedStatus.split(',');
                        selectedStatusArr.map((item, i) => {
                            let selectedAppStatus = showOptions.filter(f => { return f.label === item });
                            if (selectedAppStatus.length > 0) {
                                let statusObj = {};
                                statusObj.value = selectedAppStatus[0].value;
                                statusObj.label = selectedAppStatus[0].label;
                                responseStatusArr.push(statusObj);
                            }
                        });

                    } else {
                        responseStatusArr = selected_show;
                    }

                    if (taskArr != undefined && taskArr != null && appArr != undefined && appArr != null && jobAppArr != undefined && jobAppArr != null) {
                        if (type === constants.APPOINTMENTS) {
                            checkInfoArr = response[0].appInfo.split(',');
                        } else if (type === constants.JOB_SCHEDULER) {
                            checkInfoArr = tempjobAppArr.split(',');
                        } else {
                            checkInfoArr = response[0].taskInfo.split(',');
                        }

                        checkInfoArr.map((item, i) => {
                            let ObjRecordArr = item.split('_');
                            let tableId = getIntParam(ObjRecordArr[0].trim());
                            if (tableId === OBJECT_TABLEID_MAP.accounts) {
                                isAccount = getIntParam(ObjRecordArr[1]);
                            } else if (tableId === OBJECT_TABLEID_MAP.contacts) {
                                isContact = getIntParam(ObjRecordArr[1]);
                            } else if (tableId === OBJECT_TABLEID_MAP.opportunities) {
                                isOpportunity = getIntParam(ObjRecordArr[1]);
                            } else if (tableId === OBJECT_TABLEID_MAP.jobs) {
                                isJobs = getIntParam(ObjRecordArr[1]);
                            }
                        });
                    }
                    setstart_time(start_time);
                    setend_time(end_time);
                    setcalenderSetup(response);
                    setsetupId(response[0].id);
                    setisAccount_Name(isAccount);
                    setisContact_Name(isContact);
                    setisOpportunity_Name(isOpportunity);
                    setisJob_Name(isJobs);
                    settaskArr(temptaskArr);
                    setappArr(tempappArr);
                    setjobAppArr(tempjobAppArr);
                    setselected_user(responseUserArr);
                    setselected_show(responseStatusArr);
                    setappTypes(appTypes);
                } else {
                    let isAccount_Name = 0;
                    if (type === constants.TASKS) {
                        isAccount_Name = 1;
                    } else {
                        isAccount_Name = 1;
                    }
                    setCalendarSetup({...defaultSetupData});
                    setstart_time(defaultSetupData[0].startHour + " " + defaultSetupData[0].startHourMeridiem)
                    setend_time(defaultSetupData[0].endHour + " " + defaultSetupData[0].endHourMeridiem)
                    setisAccount_Name(isAccount_Name)
                    setisContact_Name(0);
                    setisOpportunity_Name(0);
                    setisJob_Name(0);
                    settaskArr(newArrT);
                    setappArr(newArrA);
                    setjobAppArr(newArrJ);
                }
                setisloading(false);
            }
        });
    }

    const onChangeValue = (field, e) => {
        let value = e.target.value;
        if (field === 'start_time') {
            let startHour = null;
            if (value.split(' ')[1] === 'PM') {
                startHour = parseInt(value.split(' ')[0]);
                if (startHour === 24) {
                    startHour = 12;
                }
                let temp = calenderSetup[0];
                temp.startHour = startHour;
                setcalenderSetup([{...calenderSetup[0], startHour: startHour}])
            } else {
                startHour = parseInt(value.split(' ')[0])
                if (startHour === 12) {
                    startHour = 0;
                }
                let temp = calenderSetup[0];
                temp.startHour = startHour;
                setcalenderSetup([{...calenderSetup[0], startHour: startHour}])
            }
           
            setcalenderSetup([{...calenderSetup[0], startHourMeridiem: value.split(' ')[1]}])
            setstart_time(startHour + ' ' + value.split(' ')[1]);
        } else if (field === 'end_time') {
            let endHour = null;
            if (value.split(' ')[1] === 'PM') {
                endHour = parseInt(value.split(' ')[0]) ;
                if (endHour === 24) {
                    endHour = 12;
                }
                let temp = calenderSetup[0];
                temp.endHour = endHour;
                setcalenderSetup([{...calenderSetup[0], endHour: endHour}])                
            } else {
                endHour = parseInt(value.split(' ')[0]);
                if (endHour === 12) {
                    endHour = 0;
                }
                let temp = calenderSetup[0];
                temp.endHour = endHour;
                setcalenderSetup([{...calenderSetup[0], endHour: endHour}])                
            }
            setcalenderSetup([{...calenderSetup[0], endHourMeridiem: value.split(' ')[1]}])
            setend_time(endHour + ' ' + value.split(' ')[1]);
        } else if (field === 'location') {
            setcalenderSetup([{...calenderSetup[0], location: value}])
        } else if (field === 'description') {
            setcalenderSetup([{...calenderSetup[0], description: value}])
        }

    }


    // }
    
    // }
    const closeDrawer = () => {
        if (app.me.calendar_setup.length == 0) {
            let tempCalenderSetup = [{
                description: null,
                endHour: 17,
                endHourMeridiem: "PM",
                id: 0,
                location: null,
                startHour: 9,
                startHourMeridiem: "AM",
            }];
            setCalendarSetup();
            setisAccount_Name(0);
            setisContact_Name(0);
            setisOpportunity_Name(0);
            setisJob_Name(0);
            setcolorCode = ('Users');
        }
        dispatch(getAppCustomDrawer(false, null, null, null, null, null, null));
    }

    const handleSave = () => {
        let isValied = true
        let type = props.data.type;
        let isAccount = isAccount_Name;
        let isContact = isContact_Name;
        let isOpportunity = isOpportunity_Name;
        let isJob = isJob_Name;

        let selectedUser = selected_user;
        let selectedStatus = selected_show;
        let checkInfo = "";
        let _appArr = "";
        let _taskArr = "";
        let _jobAppArr = "";
        let userData = '';
        let statusData ='';
        let selectedUserArr = [];
        let responseUserArr = [];
        let selectedStatusArr = [];
        let responseStatusArr = [];
        let count = 0;
        let calendarInfoFromRedux = getObjectParam(calendar.calendarInfo);
        let isAllUser = selectedUser.filter(f => { return f.value === -5555 });

        if (isAllUser.length === 1) {
            selectedUser = selectedUser.filter(f => { return f.value !== -5555 });
        }

        if (app.selectedModuleName === 'Marketing' && selectedUser.length === 1) {
            let tempobj = selectedUser.find(e => e = -8888);
            if (tempobj.value === -8888) {
                selectedUser.unshift({ value: app.me.id, label: app.me.name });
            }
        }
        if (selectedUser.length === 0 && app.selectedModuleName !== 'Marketing') {
            selectedUser.push({ value: app.me.id, label: app.me.name });
        }
        
        if (selectedUser) {
            selectedUser.map((item)=>{
                if(item.value !== -5555) {
                    if(!userData)  {
                        userData = item.value
                    } else {
                        userData += "," + item.value
                    }
                }
            })
        }

        if (selectedStatus) {
            selectedStatus.map((item)=>{
                if(!statusData)  {
                    statusData = item.value
                } else {
                    statusData += "," + item.value
                }
            })
        }
        if (isAccount === 1) {
            checkInfo = OBJECT_TABLEID_MAP[constants.ACCOUNTS_OBJECT] + "_" + 1;
        } else {
            checkInfo = OBJECT_TABLEID_MAP[constants.ACCOUNTS_OBJECT] + "_" + 0;
        }
        if (isContact === 1) {
            checkInfo += "," + OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT] + "_" + 1;
        } else {
            checkInfo += "," + OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT] + "_" + 0;
        }
        if (isOpportunity === 1) {
            checkInfo += "," + OBJECT_TABLEID_MAP[constants.OPPORTUNITIES_OBJECT] + "_" + 1;
        } else {
            checkInfo += "," + OBJECT_TABLEID_MAP[constants.OPPORTUNITIES_OBJECT] + "_" + 0;
        }
        if (isJob === 1) {
            checkInfo += "," + OBJECT_TABLEID_MAP[constants.JOBS_OBJECT] + "_" + 1;
        } else {
            checkInfo += "," + OBJECT_TABLEID_MAP[constants.JOBS_OBJECT] + "_" + 0;
        }

        if (type === constants.APPOINTMENTS) {
            _appArr = checkInfo;
            _taskArr = taskArr;
            _jobAppArr = jobAppArr;
        } else if (type === constants.TASKS) {
            _appArr = appArr;
            _taskArr = checkInfo;
            _jobAppArr = jobAppArr;

        } else if (type === constants.JOB_SCHEDULER) {
            _jobAppArr = checkInfo;
            _appArr =appArr;
            _taskArr = taskArr;
        }
        let params = {
            "t_pk": setupId,
            "startHour": calenderSetup[0].startHour,
            "startMeridiem": calenderSetup[0].startHourMeridiem,
            "endHour": calenderSetup[0].endHour,
            "endMeridiem": calenderSetup[0].endHourMeridiem,
            "location": calenderSetup[0].location,
            "description": calenderSetup[0].description,
            "appArr": _appArr,
            "taskArr": _taskArr,
            "jobAppArr": _jobAppArr,
            "userData": userData,
            "statusData": statusData,
            "sortOrder": calenderSetup[0].sortOrder,
            "defaultView": calenderSetup[0].defaultView,
        }
        console.log("calenderSetup[0]",calenderSetup[0])
        if ((calenderSetup[0].startHour > calenderSetup[0].endHour)) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.SCHEDULER.VALIDATE_TIME, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            isValied = false;
        } else if ((calenderSetup[0].startHour === calenderSetup[0].endHour)) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.SCHEDULER.VALIDATE_TIME, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            isValied = false;
        }
        if (isValied) {

            let url = endPoints.APPOINTMENT.SAVE_CALENDAR_SET_UP;
            let promise = Promise.resolve(HTTPClient.post(url, params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        calenderSetup.id = response.data.id[0];
                        setsetupId(response.data.id[0]);
                        if (app.selectedModuleName === 'Marketing') {
                            let tempObj = stateuserList.find(e => e = -8888);
                            if (tempObj != null && tempObj.id !== -8888) {
                                stateuserList.unshift({ value: -8888, label: 'Campaigns', id: -8888 });
                            }

                        }
                        if (type === constants.APPOINTMENTS || type === constants.JOB_SCHEDULER) {

                            if (userData !== null && userData !== undefined) {
                                userData = userData.toString();
                                if (userData.indexOf(',') > -1) {
                                    selectedUserArr = userData.split(',');
                                } else {
                                    let tempArr = [];
                                    tempArr.push(userData);
                                    selectedUserArr = tempArr;
                                }
                                count = selectedUserArr.find(e => e = -8888);
                                if (app.selectedModuleName !== 'Marketing' && count === -8888) {
                                    selectedUserArr = selectedUserArr.filter(f => { return f !== -8888 });
                                }
                                selectedUserArr.map((item, i) => {
                                    let selectedUser = stateuserList.filter(f => { return f.id.toString() === item });
                                    let userObj = {};
                                    if (selectedUser.length > 0) {
                                        userObj.value = selectedUser[0].id;

                                        if (item === -8888) {
                                            userObj.label = selectedUser[0].label;
                                        } else {
                                            userObj.label = selectedUser[0].value;
                                            userObj.color = selectedUser[0].colorCode;
                                            userObj.profileImage = selectedUser[0].profile_image_url
                                        }
                                        responseUserArr.push(userObj);
                                    }
                                });

                                if (app.selectedModuleName === 'Marketing') {
                                    if (responseUserArr.length === (stateuserList.length - 1)) {
                                        responseUserArr.unshift({ value: -5555, label: 'All' });
                                    }
                                } else {
                                    if (responseUserArr.length === (stateuserList.length)) {
                                        responseUserArr.unshift({ value: -5555, label: 'All' });
                                    }

                                }
                            }

                            if (statusData !== null && statusData !== undefined && statusData !== "") {
                                selectedStatusArr = statusData.split(',');
                                selectedStatusArr.map((item, i) => {
                                    let selectedAppStatus = showOptions.filter(f => { return f.label === item });
                                    if (selectedAppStatus.length > 0) {
                                        let statusObj = {};
                                        statusObj.value = selectedAppStatus[0].value;
                                        statusObj.label = selectedAppStatus[0].label;
                                        responseStatusArr.push(statusObj);
                                    }
                                });
                            }
                            if (type === constants.APPOINTMENTS) {
                                calenderSetup[0].appInfo = checkInfo;
                                calenderSetup[0].taskInfo = _taskArr;
                                calenderSetup[0].jobAppInfo = _jobAppArr;
                            } 
                            else if (type === constants.TASKS) {
                                calenderSetup[0].appInfo = _appArr;
                                calenderSetup[0].taskInfo = checkInfo;
                                calenderSetup[0].jobAppInfo = _jobAppArr;
                            }
                            else {
                                calenderSetup[0].appInfo = _appArr;
                                calenderSetup[0].taskInfo = _taskArr;
                                calenderSetup[0].jobAppInfo = checkInfo;
                            }

                            calenderSetup[0].selectedUser = userData;
                            calenderSetup[0].selectedStatus = statusData;
                            setselected_user(responseUserArr);
                            setselected_show(responseStatusArr);
                            let salesAssign = [];
                            let calendarInfoFromRedux = getObjectParam(calendar.calendarInfo);
                            if (calendarInfoFromRedux !== null && calendarInfoFromRedux !== '' &&
                                calendarInfoFromRedux.hasOwnProperty('salesAssign')) {
                                salesAssign = calendar.calendarInfo.salesAssign;
                            } else {
                                salesAssign.push({ value: app.me.id, label: app.me.name, color: 'FE5722' });
                                if (app.selectedModuleName === 'Marketing') {
                                    salesAssign.push({ value: -8888, label: 'Campaigns' })
                                }

                            }

                            calendar.calendarInfo.assign = responseUserArr;
                            calendar.calendarInfo.salesAssign = salesAssign;
                            calendar.calendarInfo.appTypes = appTypes;
                            calendar.calendarInfo.status = responseStatusArr;
                            if (type === constants.JOB_SCHEDULER) {
                                calendar.calendarInfo.schedulerView = 'Day';
                                calendar.calendarInfo.calendarView = 'Week';
                            } else {
                                calendar.calendarInfo.schedulerView = 'Day';
                                calendar.calendarInfo.calendarView = 'Week';
                            }

                        } 
                        else {
                            if (type === constants.APPOINTMENTS) {
                                calenderSetup[0].taskInfo = _taskArr;
                                calenderSetup[0].appInfo = checkInfo;
                                calenderSetup[0].jobAppInfo = _jobAppArr;
                                calenderSetup[0].selectedUser = userData;
                                calenderSetup[0].selectedStatus = statusData;
                            } 
                            else if (type === constants.TASKS) {
                                calenderSetup[0].taskInfo = checkInfo;
                                calenderSetup[0].appInfo = _appArr;
                                calenderSetup[0].jobAppInfo = _jobAppArr;
                                calenderSetup[0].selectedUser = userData;
                                calenderSetup[0].selectedStatus = statusData;
                            }
                            else{
                                calenderSetup[0].taskInfo = checkInfo;
                                calenderSetup[0].appInfo = checkInfo;
                                calenderSetup[0].jobAppInfo = _jobAppArr;
                                calenderSetup[0].selectedUser = userData;
                                calenderSetup[0].selectedStatus = statusData;
                            }
                           
                        }
                        if (type === constants.TASKS  && calenderSetup[0].defaultView == "Collapsed") {
                            calendar.calendarInfo.calendarView = 'Week';
                        }
                        setCalendarSetup(calenderSetup);

                        dispatch(getCalendarRefresh(true));
                        dispatch(getTasksRefresh(true));
                        refreshColumnView(constants.TASKS);
                        ///
						
						if(props.data.hasOwnProperty("propsTaskApp") && props.data.propsTaskApp != null){
                            let tempProps =  props.data.propsTaskApp;
                            console.log("tempProps>>>>>>>>>",tempProps)
                            tempProps.sortOrder = calenderSetup[0].sortOrder;
                            dispatch(getTasksPortal(tempProps));
                            refreshColumnView(constants.TASKS);
                        }
                        if (type === constants.JOB_SCHEDULER) {
                            dispatch(getJobCalendarRefresh(true));
                        }
                        refreshColumnView('tasks');
                        closeDrawer();
                    }
                });
            }
        }
    }

    const populateTimeListS = () => {
        let timeList = '';
        let timeArr = duration;
        if (timeArr !== null && timeArr !== '' && timeArr !== undefined) {
            return timeArr.map((timeObj, i) => {
                return <MenuItem key={timeObj.key} value={timeObj.key} style={styles.popoverMenuItem}>{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={timeObj.label}>{timeObj.label}</div>}</MenuItem>
                    
            }
            )
        }
        return timeList;

    }

    const handleCheckChanges = (name, event) => {
        try {
            if (name === 'Account Name') {
                let isAccount_Name = event.target.checked ? 1 : 0;
                setisAccount_Name(isAccount_Name)
            } else if (name === 'Contact Name') {
                let isContact_Name = event.target.checked ? 1 : 0;
                setisContact_Name(isContact_Name)
            } else if (name === 'Opportunity Name') {
                let isOpportunity_Name = event.target.checked ? 1 : 0;
                setisOpportunity_Name(isOpportunity_Name)
            } else if (name === 'Job Name') {
                let isJob_Name = event.target.checked ? 1 : 0;
                setisJob_Name(isJob_Name)
            }
        } catch (error) {
            console.error("Error in powerCalling.js -> 'handleCheckChanges()'" + error);
        }
    }

   
    const selectCheckUser = (data, name, values) => {
        let finalSelected = data;
        let allUser = superSelectUserlist;
        let finalAllSelect = values.label;
        let allSelected = values.selected;
        let hasAll = data.filter(f => { return f.value === -5555 });
        let campaignExist = data.filter(f => { return f.value === -8888 });

        if ((allUser.length - data.length) === 1) {
            if (finalAllSelect !== null && finalAllSelect === 'All' && allSelected === false) {
                finalSelected = allUser;
            } else if (finalAllSelect !== null && finalAllSelect === 'All' && allSelected) {
                finalSelected = [];
            } else if (finalAllSelect !== null && finalAllSelect !== 'All' && hasAll.length === 0) {
                finalSelected = allUser;
            } else {
                finalSelected = data.filter(f => { return f.value !== -5555 });
            }
        } else if (finalAllSelect !== null && finalAllSelect === 'All' && allSelected === false) {
            finalSelected = allUser;
        } else if ((allUser.length === data.length) && finalAllSelect !== null && finalAllSelect !== 'All' && campaignExist.length > 0 && hasAll.length === 0) {
            finalSelected = allUser;
        } else if ((allUser.length === data.length) && finalAllSelect !== null && finalAllSelect !== 'All' && campaignExist.length > 0 && hasAll.length > 0) {
            finalSelected = data.filter(f => { return f.value !== -5555 });
        } else if ((allUser.length === data.length) && finalAllSelect !== null && finalAllSelect !== 'All' && campaignExist.length === 0 && hasAll.length > 0) {
            finalSelected = data.filter(f => { return f.value !== -5555 });
        }

        setselected_user(finalSelected);
    }
    const onChange = (value, fieldName) => {
		 if (fieldName === 'sortOrder') {
            setcalenderSetup([{...calenderSetup[0], sortOrder: value}]);
		} if (fieldName === 'defaultView') { 
            setcalenderSetup([{...calenderSetup[0], defaultView: value}]);  
        }
	}
    const changeUserCalendar = (values, name) => {
        setselected_user_final(values);
    }

    const changeShow = (values, name) => {
        setselected_show(values);
    }

    if (!isloading) {
        let timeList = populateTimeListS();
        let type = props.data.type;
        let marginTopDesign = '5px';
        if (type === constants.APPOINTMENTS) {
            marginTopDesign = '13px';
        }
        let timeZone = app.me.timezone;

        let count = 0;
        let startData = start_time;
        let endData = end_time;
        let tZ = app.me.timezone;
        /////////
        let userlist = stateuserList;
        let userOptions = [];
        let tempselected_user = selected_user.filter(f => { return f.value !== -8888 });
        let userkeDropdown = 'sales-services';
        if (app.selectedModuleName !== 'Marketing') {
            if (userlist.length > 0) {
                count = userlist.find(e => e = -8888);

                if (count.id === -8888) {
                    userlist = userlist.filter(f => { return f.id !== -8888 });
                }
            }
        }
        if (app.selectedModuleName === 'Marketing') {
            userkeDropdown = 'marketing';
            tempselected_user.unshift({ value: -8888, label: 'Campaigns' });
            count = userlist.find(e => e = -8888);

            if (count !== -8888) {
                userlist = userlist.filter(f => { return f.id !== -8888 });
            }
            userOptions.push(
                { value: -8888, label: 'Campaigns' }
            );
        }
        map(userlist, function (userObject, i) {
            let obj = {};
            obj.label = userObject.value;
            obj.value = userObject.id;
            userOptions.push(obj);

        });

        let showType = getArrayParam(appTypes);
        let showTypeOptions = [];

        map(showType, function (typeObject, i) {
            let obj = {};
            obj.label = typeObject.label;
            obj.value = typeObject.value;
            showTypeOptions.push(obj);
        });
        console.log("calenderSetup[0]?.defaultView",calenderSetup[0]?.defaultView)

       return (<div style={{ paddingLeft: '10px', paddingRight: '10px', marginTop: '20px', height: '100%' }}>
            <div>
                {(type === constants.APPOINTMENTS || type === constants.JOB_SCHEDULER) &&
                    <div>
                        <div className="color" id="noHolder" style={{ marginLeft: '15px' }}>

                            <div>
                                <label className='time-input' style={{ ...styles.customTimeInputLebel, marginTop: '7px' }}>
                                    {getLocalizedStrings().label.CALENDAR.WORKING_HOUERS}
                                </label>
                            </div>
                            <div className="working-hour" style={styles.customTimeHolder}>
                                <div>
                                    <div style={{ float: 'left' }}>
                                        <FormControl margin="dense" size='small' variant="outlined" style={{ width: '100%' }}>
                                            <Select
                                                key={"start_time"}
                                                labelId='sf-label-start_time'
                                                id={"start_time"}
                                                value={startData}
                                                onChange={(e) => onChangeValue("start_time", e)}
                                                name={"start_time"}
                                                margin="dense"
                                                size='small'
                                                className={"sf-fields-bg"}
                                            >
                                                {timeList}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <span style={{ float: 'left', paddingLeft: '5px', paddingRight: '5px', paddingTop: '17px' }}>to</span>
                                    <div style={{ float: 'left' }}>
                                        <FormControl margin="dense" size='small' variant="outlined" style={{ width: '100%' }}>
                                            <Select
                                                key={"end_time"}
                                                labelId='sf-label-end_time'
                                                id={"end_time"}
                                                value={endData}
                                                onChange={(e) => onChangeValue("end_time", e)}
                                                name={"end_time"}
                                                margin="dense"
                                                size='small'
                                                className={"sf-fields-bg"}
                                            >
                                                {timeList}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <span style={{ float: 'left', paddingLeft: '5px', paddingRight: '5px', paddingTop: '10px' }}> {showTimeZoneMap[tZ]}</span>

                                </div>

                            </div>
                        </div>
                        {(type === constants.JOB_SCHEDULER) &&
                            <div id={'calendarsetupcheck'} style={{width: '94%', margin: '15px 0px 10px 15px' }}>
                                <SuperSelectField
                                    key={userkeDropdown}
                                    name='userCalendar'
                                    multiple
                                    colorBox={true}
                                    defaultLabel={getLocalizedStrings().label.CALENDAR.CALENDARS}
                                    // ref="superSlctUser"
                                    options={userOptions}
                                    hintTextAutocomplete={getLocalizedStrings().label.COMMON.TYPE_SOMETHING}
                                    checkPosition='left'
                                    onChange={changeUserCalendar}
                                    // onClose={onClose}
                                    value={selected_user}
                                    onSelect={selectCheckUser}
                                    floatingLabelStyle={{ display: 'none' }}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                    buttonStyle={{ backgroundColor: '#fff', border: '1px solid #e0e0e0', padding: '1px 8px', borderRadius: '3px', color: '#616161', lineHeight: 2.5 }}
                                    downArrowStyle={{ marginTop: '7px' }}
                                    width={"96.8%"}
                                    height={45}
                                    buttonOuterLabel={"Default Calendars"}
                                    innerDivStyle={{ fontSize: '14px' }}
                                    underlineStyle={{ border: '1px solid red' }}
                                    showAutocompleteThreshold={3}
                                />
                            </div>
                        }
                        <div style={{ width: '94%', margin: '15px 0px 10px 15px' }} >
                            <SuperSelectField
                                key={userkeDropdown}
                                name='showCalendar'
                                multiple
                                colorBox={false}
                                id={'calendar'}
                                defaultLabel={getLocalizedStrings().label.COMMON.SHOW}
                                options={showOptions}
                                hintTextAutocomplete={getLocalizedStrings().label.COMMON.TYPE_SOMETHING}
                                checkPosition='left'
                                onChange={changeShow}
                                // onClose={onClose}
                                value={selected_show}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                buttonStyle={{ backgroundColor: '#fff', border: '1px solid #e0e0e0', padding: '1px 8px', borderRadius: '3px', marginTop: '5px', color: '#616161', lineHeight: 2.5 }}
                                downArrowStyle={{ marginTop: '7px' }}
                                width={"96.8%"}
                                height={45}
                                buttonOuterLabel={"Default Status"}
                                showAutocompleteThreshold={3}
                            />
                        </div>

                        <div className="col-12">
                            <FormControl style={{ width: '97%' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    id="default_location"
                                    name="default_location"
                                    value={calenderSetup[0].location}
                                    onChange={(e) => onChangeValue("location", e)}
                                    label={getLocalizedStrings().label.CALENDAR.DEFAULT_lOCATION}
                                    inputprops={{ maxlength: '64' }}
                                    autoComplete="new-password"
                                    autoFocus={true}
                                    margin='dense'
                                    size='small'
                                    className={"sf-fields-bg"}
                                />
                            </FormControl>
                        </div> 

                        <div className="col-12">
                            <FormControl style={{ width: '97%', marginTop: '6px' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    type='textarea'
                                    multiline={true}
                                    rows={4}
                                    fullWidth={true}
                                    id='default_description'
                                    value={calenderSetup[0].description}
                                    label={getLocalizedStrings().label.CALENDAR.DEFAULT_DESCRIPTION}
                                    autoComplete="new-password"
                                    onChange={(e) => onChangeValue("description", e)}
                                    margin='dense'
                                    size='small'
                                    className={"sf-fields-bg"}
                                />
                            </FormControl>
                        </div>
                    </div>
                }
                {type === constants.TASKS &&
                    <FormControl variant="outlined" style ={{width:'50%',marginLeft:'14px'}}>
                        <InputLabel htmlFor="task-sort_by-native-simple">{getLocalizedStrings().label.APPOINTMENTS_TASKS.SORT_BY}</InputLabel>
                        <Select
                            value={calenderSetup[0].sortOrder}
                            onChange={(e) => onChange(e.target.value, 'sortOrder')}
                            style={{ height: '44px',}}
                            className={"sf-fields-bg"}
                            label={getLocalizedStrings().label.APPOINTMENTS_TASKS.SORT_BY}
                        >
                            {sortList.map((l) => (
                                <MenuItem key={l.id} value={l.id}>{l.id}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }
                <div style={{marginTop: '18px', marginBottom: '-12px'}}>
                    <label className='time-input' style={{ ...styles.customTimeInputLebel, top: marginTopDesign, marginLeft: '15px' }}>
                        {getLocalizedStrings().label.CALENDAR.DISPLAY}
                    </label>
                </div>

                <div className="col-12">
                    <FormControlLabel control={<Checkbox color="default" checked={(isContact_Name == 1) ? true : false} value={(isContact_Name == 1) ? true : false}
                        onChange={(e) => handleCheckChanges('Contact Name', e)} defaultChecked={false} />} label={getLocalizedStrings().label.APPOINTMENTS_TASKS.CONTACT_NAME} labelPosition="right" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginTop: '19px', marginBottom: '-16px' }}
                        labelstyle={{ color: '#717171', padding: '0px', width: 'auto', fontWeight: 'normal' }} />

                </div>
                <div className="col-12">
                    <FormControlLabel control={<Checkbox color="default" checked={(isAccount_Name === 1) ? true : false} value={(isAccount_Name === 1) ? true : false}
                        onChange={(e) => handleCheckChanges('Account Name', e)} defaultChecked={false} />} label={getLocalizedStrings().label.CALENDAR.ACCOUNT_NAME} labelPosition="right" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginTop: '19px', marginBottom: '-16px' }}
                        labelstyle={{ color: '#717171', padding: '0px', width: 'auto', fontWeight: 'normal' }} />

                </div>

                <div className="col-12">
                    <FormControlLabel control={<Checkbox color="default" checked={(isOpportunity_Name == 1) ? true : false} value={(isOpportunity_Name === 1) ? true : false}
                        onChange={(e) => handleCheckChanges('Opportunity Name', e)} defaultChecked={false} />} label={getLocalizedStrings().label.APPOINTMENTS_TASKS.OPPORTUNITY_NAME} labelPosition="right" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginTop: '19px', marginBottom: '-16px' }}
                        labelstyle={{ color: '#717171', padding: '0px', width: 'auto', fontWeight: 'normal' }} />

                </div>

                {type === constants.TASKS &&
                    <FormControl variant="outlined" style ={{width:'50%',marginLeft:'14px',marginTop:'31px'}}>
                        <InputLabel htmlFor="task-default_view-native-simple">Default View</InputLabel>
                        <Select
                            defaultValue={calenderSetup[0]?.defaultView == null ? "Expanded":calenderSetup[0]?.defaultView}
                            onChange={(e) => onChange(e.target.value, 'defaultView')}
                            style={{ height: '44px',}}
                            className={"sf-fields-bg"}
                            label='Default View'
                        >
                            {defaltViewList.map((l) => (
                                <MenuItem key={l.id} value={l.id}>{l.id}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }

                {(type === constants.JOB_SCHEDULER) &&
                    <div className="col-12">
                        <FormControlLabel control={<Checkbox color="default" checked={(isJob_Name == 1) ? true : false} value={(isJob_Name === 1) ? true : false}
                            onChange={handleCheckChanges.bind(this, 'Job Name')} defaultChecked={false} />} label={getLocalizedStrings().label.CALENDAR.JOB_NAME} labelPosition="right" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginTop: '19px', marginBottom: '-16px' }}
                            labelstyle={{ color: '#717171', padding: '0px', width: 'auto', fontWeight: 'normal' }} />

                    </div>
                }

                <div style={{ float: 'right', marginRight: '15px' }}>
                    <div className="col-12" style={{ marginTop: '90px' }} >
                        <Button
                            key='save'
                            onClick={() => handleSave()}
                            style={{ ...styles.primaryButton, marginRight: '5px', verticalAlign: 'top' }}>
                            {getLocalizedStrings().label.COMMON.SAVE}
                        </Button>
                        <Button
                            key='cancel'
                            onClick={closeDrawer}
                            style={{ ...styles.secondaryButton, marginLeft: '6px', marginRight: '0px', marginBottom: 14 }}>
                            {getLocalizedStrings().label.COMMON.CANCEL}
                        </Button>
                    </div>
                </div>


            </div>

        </div>);
    } else {

        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }

}

export default CalenderSettings;