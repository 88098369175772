import { actions } from '..//constants/actions';
import { getObjectParam} from '..//helper/parameterVerifier';

const operationDashboardInitialState = {     
    mounted : false,
    data: null,
    error: null,
    reportInfo: {
        mounted: false,
    },
    reportData: {
        mounted: false,
    }
}

const operationDashboardReducer = (state = operationDashboardInitialState, action) => {
    switch (action.type) {
        case actions.DASHBOARD_REPORT_INFO_GET_PENDING:
            return {
                ...state,
                reportInfo: {
                    mounted: false,
                }
            }
        case actions.DASHBOARD_REPORT_INFO_GET_FULFILLED:
            return {
                ...state,
                reportInfo: {
                    mounted: true,
                    ...getObjectParam(action.payload)
                }
            }
            case actions.DASHBOARD_REPORT_DATA_GET_PENDING:
            return {
                ...state,
                reportData: {
                    mounted: false,
                }
            }
        case actions.DASHBOARD_REPORT_DATA_GET_FULFILLED:
            return {
                ...state,
                reportData: {
                    mounted: true,
                    ...getObjectParam(action.payload)
                }
            }
        default:
            return state;
    }
}

export default operationDashboardReducer;


