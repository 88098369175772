import React from 'react';
import TotalRecordCount from '../components/TotalRecordCount';
import { GroupDataTable } from '../components/GroupDataTable';
import { GroupPagination } from '../components/GroupPagination';
import { getGroupCampaignDetails } from '../../../services/actions/detailViewActions';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const TouchCampaignsForGroup =({recordId,defaultPageSize})=> {
    const [campaignDetails, setCampaignDetails] = useState(null);
    const [mounted, setMounted] = useState(false);
    const[changeLog,setchangeLog]=useState({campaignDetails:null})

    useEffect(() => {
        var objParam = {};
        objParam.recordId = recordId.id;
        objParam.type = "touchCampaigns";
        objParam.fetchFrom = 0;
        objParam.fetchCount = defaultPageSize;
        objParam.isRecordCount = true;

        getCampaignDetails(objParam);
      }, [recordId.id]);
   

    const getCampaignDetails = (objParam) => {
        setMounted(false);
        let promise = Promise.resolve(getGroupCampaignDetails(objParam));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    setMounted(true);
                    setCampaignDetails(response);
                    let log = changeLog;
                    log.campaignDetails = response;
                    setchangeLog({...log});

                }
            });
        }

    }

    if (mounted) {
        let objDetails = changeLog.campaignDetails;
        let pgDetails = {};
        pgDetails.campType = 'touchCampaigns';
        pgDetails.totalRecordCount = objDetails.touchCampaignsCount;
        pgDetails.startNo = 1;
        pgDetails.defaultPageSize = defaultPageSize;
        pgDetails.recordId = recordId.id;

        return (
            <div>
                <div className="group_campaign_title">
                    <TotalRecordCount totalCount={objDetails.touchCampaignsCount} />
                    <span style={{ paddingTop: "2px" }}>{getLocalizedStrings().label.MODULE_ITEMS[objDetails.touchCampaignsLabel]}</span>
                </div>
                <div>
                    {objDetails.touchCampaigns !== undefined && objDetails.touchCampaigns !== null && objDetails.touchCampaigns.length > 0 &&
                        <GroupDataTable data={objDetails.touchCampaigns} />
                    }
                </div>
                {!( objDetails.touchCampaigns !== undefined && objDetails.touchCampaigns !== null && objDetails.touchCampaigns.length > 0) &&
                    <div className="group_campaign_norecord">{getLocalizedStrings().message.GROUP.NO_TOUCH_CAMPAIGN}</div>
                }
                <div>
                    {objDetails.touchCampaigns !== undefined && objDetails.touchCampaigns !== null && objDetails.touchCampaigns.length > 0 &&
                        <GroupPagination pgDetails={pgDetails} getGroupCampaignDetails={getCampaignDetails} />
                    }
                </div>
            </div>
        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (<div style={{ width: '100%', height: contentHeight }}>
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
        </div>);
    }
}
export default TouchCampaignsForGroup;

TouchCampaignsForGroup.propTypes = {
    recordId: PropTypes.number,
    defaultPageSize: PropTypes.number
};