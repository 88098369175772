import React, {useState,useEffect, useRef } from "react";
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    FormControl,
    TextField,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import {showCustomSnackBar} from '../../../services/actions/snackBarAction';
import WorkflowExternalWebhookCard from "./WorkflowExternalWebhookCard";
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import { setisShowWebhook ,closeWorkflowSetupDrawer, addNewWebhook,updateWebhook,setisOpenEditWebhook,setIsFocusOnWebhook } from "../../../services/actions/workflowSetupAction";
import { getOrderId } from "../../../services/helper/utils";

const WorkflowExternalwebhook = () =>{
    const dispatch = useDispatch();
    const webhookScrollRef =  useRef(null);
    const inputRef = useRef(null);    
    let tempInputRef;
    const [name,setName] = useState('');
    const [active,setActive]  = useState(true);
    const [endpointUrl,setendpointUrl] = useState('');
    const [data,setData] = useState([]);
    const [failureNotification,setfailureNotification] = useState('');
    const [selectedWebhook,setSelectedWebhook] = useState(null);
    const [isScrollToWebHookField,setIsScrollToWebHookField] = useState(false);
    const {isShowWebhook,isEditTask,isEditWebhook,isAddNewTask,isEditTextmessage,isFocusOnWebhook,isaddNewTextMessage,isEditApppointment,isEditEmailTask,isAddAppointment,isOpenEmailTask,selectedStage} = useSelector((state) => state.workFlowSetup);
    const { webhooks } =  selectedStage;
    const {form_fields} = useSelector((state) => state.workFlowSetup.data);

    useEffect(()=>{
      if(isScrollToWebHookField){
        webhookScrollRef.current?.scrollIntoView({behavior: 'smooth'});
        setIsScrollToWebHookField(false);
      }
  },[isScrollToWebHookField])
   
    const onChangeNameFunc = (value) =>{
        setName(value);
    }
    const onchangeActiveFunc = (value) =>{
        setActive(value);
    }
    const onChangeSetendPointUrlFunc =(value) =>{
        setendpointUrl(value);
    }

    const onChangeFailureNotificationFunc = (value) =>{
        setfailureNotification(value)
    }

    const closeWorkflowExternalWebhook = () =>{
      setData([]);
      setfailureNotification('');
      setName('');
      setendpointUrl('');
      setActive(true);
      dispatch(setisShowWebhook(false));
      dispatch(setisOpenEditWebhook(false));    
    }

    const saveWorkflowExternalWebhook = () =>{
        let errorMessage;
        if(!name){
          errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.WEBHOOK_NAME_BLANK;
          inputRef.current?.focus();
        }else if(!endpointUrl){
          errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.WEBHOOK_URL_BLANK;
          inputRef.current?.focus();
        }else if(data.length == 0){
          errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.WEBHOOK_DATA_BLANK;
          tempInputRef.focus();
        }
        if(errorMessage){
            dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }else{
            const _selectedStage = [{...selectedStage}]
            const highestOrderId = getOrderId(_selectedStage)
            let obj = {};
            obj.workflow_id =  selectedStage.workflow_id;
            obj.stage_id = selectedStage.id;
            let selectedDataFields = [];
            data.map((ele,idx)=>{
              selectedDataFields.push(ele.name);
            })
            let tempdata = "";
            tempdata = selectedDataFields.join(",");
            
            let details = {
              "failure_notify_mail": failureNotification,
              "fields_info": tempdata,
              "name": name,
              "webhook_url": endpointUrl,
              "status": active ? 1 : 0
            };
            obj.details = details;
            if(isShowWebhook){
              obj.id = Math.floor(Math.random() * Date.now());
              obj.order_id = highestOrderId + 1;
            }else{
              obj.id = selectedWebhook.id;
            }
            obj.type = "webHook";
            if(isShowWebhook){
              dispatch(addNewWebhook(obj));
            }
            else{
              dispatch(updateWebhook(obj));
            }
            dispatch(setisShowWebhook(false));
            dispatch(setisOpenEditWebhook(false));
            setData([]);
            setfailureNotification('');
            setName('');
            setendpointUrl('');
            setActive(true);
            dispatch(setisShowWebhook(false));
            dispatch(setisOpenEditWebhook(false)); 
        }
    }
    const handleSelectChangeData=(event,value)=>{
        setData(value);

    }

    const setTextInputRef = (element) =>{
      inputRef.current = element;
  }

    const getExternalWebhookFun = () =>{
        let element = <div style={{width:'100%',height:'100%'}}>
            <div className="name_field" style={{width:'100%',height:'fit-content',display:'inline-grid'}}>
                <FormControl style={{ width: '60rem',marginRight:'10px',marginTop:'10px'}} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        name="name"
                        id="name"
                        label="name"
                        fullWidth={true}
                        value={name}
                        inputRef={!name ? setTextInputRef :''}
                        style={{width:'60rem',height:'fit-content',display:'inline-block'}}
                        onChange={(event) =>onChangeNameFunc(event.target.value)}
                        inputprops={{ maxlength: '256' }}
                        autoComplete="new-password"
                        margin='dense'
                        className={"sf-fields-bg"}
                        size="small"
                    />
                </FormControl>
            </div>

            <div className="checkBox_field" style={{width:'100%',height:'fit-content',display:'inline-grid'}}>
                <FormControlLabel control={<Checkbox defaultChecked onClick={(event)=>onchangeActiveFunc(event.target.checked)} value={active}/>} label="Active" />
            </div>
            <div className="name_field" style={{width:'100%',height:'fit-content',display:'inline-grid'}}>
                <FormControl style={{ width: '60rem',marginRight:'10px'}} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        name="Endpoint URL"
                        label="Endpoint URL"
                        id="url"
                        fullWidth={true}
                        inputRef={!endpointUrl ? setTextInputRef :''}
                        value={endpointUrl}
                        style={{width:'60rem',height:'fit-content',display:'inline-block'}}
                        onChange={(event) =>onChangeSetendPointUrlFunc(event.target.value)}
                        inputprops={{ maxlength: '256' }}
                        autoComplete="new-password"
                        margin='dense'
                        className={"sf-fields-bg"}
                        size="small"
                    />
                </FormControl>
            </div>
            <div className="name_field" style={{width:'100%',height:'fit-content',display:'inline-grid'}}>
                <div className="col-md-4" style={{ paddingLeft: '0px' ,width:'100%',marginRight:'10px' ,marginTop:'10px'}} >
                    <Stack spacing={3} sx={{ width: '60rem' }}>
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            label="Data"
                            options={form_fields}
                            autoFocus ={isFocusOnWebhook}
                            value={data}
                            onChange={(event, value) => handleSelectChangeData(event, value)}
                            getOptionLabel={(option) => option.label}
                            defaultValue={[form_fields[1]]}
                            size="small"
                            filterSelectedOptions
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                inputRef={input => {
                                  tempInputRef = input;
                                }}
                                label="Data"
                                placeholder="Click here to select one or more required fields(s)"
                            />
                            )}
                        />
                    </Stack>

                </div>
            </div>

            <div className="name_field" style={{width:'100%',height:'fit-content',display:'inline-grid'}}>
                <FormControl style={{ width: '60rem',marginRight:'10px',marginTop:'5px'}} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        name="Failure Notification Recipient"
                        label="Failure Notification Recipient"
                        id="url"
                        fullWidth={true}
                        value={failureNotification}
                        style={{width:'60rem',height:'fit-content',display:'inline-block'}}
                        onChange={(event) =>onChangeFailureNotificationFunc(event.target.value)}
                        inputprops={{ maxlength: '256' }}
                        autoComplete="new-password"
                        margin='dense'
                        className={"sf-fields-bg"}
                        size="small"
                    />
                </FormControl>
            </div>
            <div style={{display:'flex',marginTop:'15px'}}>
                <Button
                    onClick={() => saveWorkflowExternalWebhook()}
                    style={{
                        ...styles.primaryButton,
                        height: "32px",
                        width:'7.5%'
                    }}
                    title='Save'
                > {isShowWebhook ? getLocalizedStrings().label.COMMON.SAVE : getLocalizedStrings().label.COMMON.UPDATE}</Button>
                <Button
                    onClick={()=>closeWorkflowExternalWebhook()}
                    style={{ ...styles.secondaryButton, height: 32, paddingLeft: '17px', paddingRight: '17px' }}
                >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
            </div>
        </div>
        return element;
    }
    const addNewWebhoook = () =>{
        if(isShowWebhook||isEditTask||isEditWebhook||isAddNewTask||isaddNewTextMessage||isAddAppointment||isOpenEmailTask){
          let errorMessage;
          if(isShowWebhook){
            errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.WEBHOOK_OPEN;
          }else if(isEditWebhook){
            errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.WEBHOOK_OPEN;
          } else if(isEditTask){
            errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EDIT_ERROR;
          }else if(isAddNewTask){
            errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EDIT_ERROR;
          }else if(isaddNewTextMessage){
            errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.TEXT_MESSAGE_ERROR;
          }else if(isAddAppointment){
            errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.APPOINMENT_ERROR;
          }else if(isOpenEmailTask){
            errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.CHECK_EMAIL_TASK_OPEN;
          }
          dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }else{
          dispatch(setisShowWebhook(true));
        }
    }

    const editWebhookTask = (ele) =>{
      if(isShowWebhook || isEditTask || isAddNewTask || isaddNewTextMessage || isAddAppointment || isOpenEmailTask ||isEditWebhook || isEditTextmessage || isEditApppointment || isEditEmailTask){
        let errorMessage;
        if(isShowWebhook){
          errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.WEBHOOK_OPEN;
        }else if(isEditTask){
          errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EDIT_ERROR;
        }else if(isAddNewTask){
          errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EDIT_ERROR;
        }else if(isaddNewTextMessage){
          errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.TEXT_MESSAGE_ERROR;
        }else if(isAddAppointment){
          errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.APPOINMENT_ERROR;
        }else if(isOpenEmailTask){
          errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.CHECK_EMAIL_TASK_OPEN;
        }else if(isEditWebhook){
            errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.WEBHOOK_OPEN;
        }else{
            errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EDIT_ERROR;
          }
        dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
      }else{
          let weBhookDetails = ele?.details;
          let isActive = weBhookDetails.status == 1 ? true : false;
          let _tempFieldInfo = weBhookDetails?.fields_info;
          let separatedArray = _tempFieldInfo.split(',');
          let webhookData = [];
          form_fields?.map((ele)=>{
            separatedArray?.map((item,idx)=>{
              if(ele.name === item){
                webhookData.push(ele);
              }
            })
          })
          setActive(isActive);
          setData(webhookData);
          setendpointUrl(weBhookDetails?.webhook_url);
          setfailureNotification(weBhookDetails?.failure_notify_mail);
          setName(weBhookDetails.name);
          setSelectedWebhook(ele);
          dispatch(setisOpenEditWebhook(true));
          dispatch(setIsFocusOnWebhook(true));
          setIsScrollToWebHookField(true);
        }
    }
    

    const renderCard = (card, index) => {
        return (
          <WorkflowExternalWebhookCard
            key={card.id}
            index={index}
            id={card.id}
            webhook={card}
            editWebhookTask={editWebhookTask}
          />
        )
      }
    

    return (
        <div>
            <div style={{height:'30px',width:'fit-content',marginLeft:'3.5rem',marginTop:'1rem',background:'#E5E5E5',paddingTop:'1px',paddingLeft:'10px',paddingRight:'10px'}}>
                <h1 style={{font:'bold 15px Arial, Helvetica, sans-serif',color:'#231F20',fontWeight:'bolder',paddingRight:'10px'}}>External Webhooks</h1>
            </div>
            <div style={{width:'89%',height:'fit-content',background:'#E5E5E5',marginLeft:'3.5rem',paddingLeft:'20px',paddingBottom:'20px',paddingTop:'20px'}}>
                <div style={{display:'inline'}}>
                    <div style={{width:'100%',marginBottom:webhooks?.length > 0 ? '20px' : ''}}>{webhooks?.map((card, i) => renderCard(card, i))}</div>
                </div>
                <div  onClick={()=>addNewWebhoook()} style={{font:'bold 14px Arial, Helvetica, sans-serif',width:'fit-content',cursor:'pointer', color:'rgb(5, 138, 229)',marginLeft:'0px'}}>
                        Add new Webhook
                </div>
                
                <div ref={webhookScrollRef}>{isShowWebhook && getExternalWebhookFun()}</div>
                <div ref={webhookScrollRef}>{isEditWebhook && getExternalWebhookFun()}</div>
            </div>
        </div>
    )
}
export default WorkflowExternalwebhook;