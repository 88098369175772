import { actions } from '..//constants/actions';

const couponInitialState = {
    couponMounted: false,
    coupons: {}
}

const couponReducer = (state = couponInitialState, action) => {
    switch (action.type) {
        case actions.COUPONS_GET:
            return {
                ...state,
                couponMounted: true,
                coupons: action.payload
            }
        default:
            return state;
    }
}

export default couponReducer;