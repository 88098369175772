import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormControl,
    Select as SelectField,
    MenuItem,
    InputLabel,
    Icon,
    Radio,
    RadioGroup, Chip
} from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { isValidParam, isInvalidParam, isInteger, getArrayParam, getIntParam, getObjectParam, getStringParam } from '../../../services/helper/parameterVerifier';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { addNode } from '../../../services/actions/automationCampaignActions';
import { removeError, getNodesByActionName } from '../../../services/helper/automationUtils';
import { sortArrayObjectByProperty, isEmailExistsInList } from '../../../services/helper/utils';
import { addCampaignTypeIntoReduxStore, getObjectLabelByObject } from '../../../services/helper/common';
import { addTab, TYPE_LIST_VIEW, getActiveTab } from '../../../services/helper/sfTabManager';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from "react-redux";
import { getAllCampaignTypes } from "../../../services/actions/campaignTypeActions";
import ShowCircularProgress from '../components/circularProgress';

const AutomationSMSCampaign = ({ data, closeDrawer }) => {

        const dispatch = useDispatch();

        const app = useSelector((state) => state.app);
        const campaignTypes = useSelector((state) => state.campaignTypes);
        const automationCampaign = useSelector((state) => state.automationCampaign);

        const [stateFilterType, setFilterType] = useState('group');
        const [filter_type, setFilter_Type] = useState(0);
        const [stateFilters, setStateFilters] = useState([]);
        const [stateRestofFilters, setRestofFilters] = useState([]);
        const [filters_loading, setFilters_Loading] = useState(false);
        const [isFocusOnCampaignName, setIsFocusOnCampaignName] = useState(true);
        const [stateIsMulti, setIsMulti] = useState(false);
        const [placeholder, setPlaceholder] = useState('');
        const [stateCampaignType, setCampaignType] = useState(null);
        const [campTypelistArr, setCampTypelistArr] = useState(null);
        const [campTypeMenuItems, setCampTypeMenuItems] = useState(null);
        const [changeLog, setchangeLog] = useState({ stateFilters: [] });
        const [mounted, setMounted] = useState(false);
        const [campaignId, setCampaignId] = useState(0);
        const [status, setStatus] = useState("");

        useEffect(()=>{
            if (campaignTypes.hasOwnProperty('data') && campaignTypes.data == null ) {
                dispatch(getAllCampaignTypes());
            }else{
                    getCampaignTypeList();
            }
        },[campaignTypes,mounted])

        useEffect(() => {
            populateTouchCampaignFields()
        }, []);

        useEffect(() => {
            if (isFocusOnCampaignName) {
                let touchName = document.getElementById('campaignName');
                if (touchName !== null && touchName !== undefined) {
                    setTimeout(function () { touchName.focus(); }, 100);
                    setIsFocusOnCampaignName(false);
                }
            }
        }, [isFocusOnCampaignName]);

        const populateTouchCampaignFields = () => {
            try {
                if (isValidParam(data) && data.hasOwnProperty('details')) {
                    let campaign = "";
                    let groups = "";
                    let filter_type = 0;
                    let query_id = 0;
                    let groupNames = "";
                    let filters = changeLog.stateFilters;
                    let isMulti = stateIsMulti;
                    let filterType = stateFilterType;

                    let detailsArray = data.details[0];
                    let status = "";
                    let id = campaignId;
                    let campaignType = stateCampaignType;
    
                    if (isValidParam(detailsArray)) {

                        if (detailsArray.hasOwnProperty('campaign')) {
                            campaign = detailsArray.campaign;
                        }
                        if (detailsArray.hasOwnProperty('groups')) {
                            groups = detailsArray.groups;
                        }
                        if (detailsArray.hasOwnProperty('filter_type')) {
                            filter_type = detailsArray.filter_type;
                        }
                        if (detailsArray.hasOwnProperty('query_id')) {
                            query_id = detailsArray.query_id;
                        }
                        
                        if (detailsArray.hasOwnProperty('campaign_type')) {
                            campaignType = detailsArray.campaign_type === 0 ? '' : detailsArray.campaign_type;
                        }
    
                        if (filter_type === 0) {
                            groupNames = campaign.split(",");
                            if (isInteger(groups)) {
                                groups = groups.toString();
                            }
                            groups = groups.split(",");
                            filters = groupNames.map((objGroupname, i) => {
                                return {
                                    value: parseInt(groups[i]),
                                    label: objGroupname,
                                    title: objGroupname
                                }
                            });
                            isMulti = true;
                            filterType = 'group';
                        } else {
                            let objFilter = {
                                value: parseInt(query_id),
                                label: campaign
                            }
                            if (filter_type === constants.CAMPAIGN_QUERY_TYPE) {
                                objFilter.type = filter_type;
                            }
                            filters.push(objFilter);
                            isMulti = false;
                            if (filter_type === 9 || filter_type === constants.CAMPAIGN_QUERY_TYPE) {
                                filterType = 'contact';
                            } else if (filter_type === 10) {
                                filterType = 'account'
                            }
                            filters = filters[0];
                        }
                        if (detailsArray.hasOwnProperty('t_status')) {
                            status = detailsArray.t_status;
                        }
    
                        if (detailsArray.hasOwnProperty('id')) {
                            id = detailsArray.id;
                        }

                        loadFilterOptions(filter_type, filters);
                        setStateFilters(filters);
                        let log = changeLog;
                        log.stateFilters = filters;
                        setchangeLog({ ...log });
                        setIsMulti(isMulti);
                        setFilterType(filterType);
                        setFilter_Type(filter_type);
                        setCampaignId(id);
                        setStatus(status);
                        setCampaignType(campaignType);
                    } else {
                        loadFilterOptions(0);
                        setStatus(constants.AUTOMATION_DESIGNER_STATUS_ACTIVE);
                    }
                } else {
                    loadFilterOptions(0);
                    setStatus(constants.AUTOMATION_DESIGNER_STATUS_ACTIVE);

                }
                setFilterPlaceholder(filter_type);
            } catch (error) {
                console.log("Error in populateTouchCampaignFields() in automationSMSCampaign.js : " + error);
            }
        }

        const getCampaignTypeList = () => {
            try {
                let campaign_types = getObjectParam(campaignTypes.data);
                let campTypelistArr = [];
                let objCamp = null;
                if (Object.keys(campaign_types).length > 0) {
                    Object.keys(campaign_types).map((itemKey) => {
                        objCamp = {};
                        objCamp.id = parseInt(itemKey)
                        objCamp.name = campaign_types[itemKey];

                        campTypelistArr.push(objCamp);

                        objCamp = null;

                    });
                }

                sortArrayObjectByProperty(campTypelistArr, "name");
                let campTypeMenuItems = getCampaignTypeMenuItems(campTypelistArr);
                setCampTypelistArr(campTypelistArr);
                setCampTypeMenuItems(campTypeMenuItems);
                setMounted(true);
            } catch (error) {
                console.log("Error in 'AutomationSmsCampaign.js -> getCampaignTypeList()': " + error);
            }
        }

        const setFilterPlaceholder = (filtertype) => {
            try {
                let placeHolder = '';
                if (filtertype === 0) {
                    placeHolder = getLocalizedStrings().label.CAMPAIGNS.SELECT_GROUP;
                } else if (filtertype === 9) {
                    placeHolder = getLocalizedStrings().label.CAMPAIGNS.SELECT_A_FILTER + getObjectLabelByObject(constants.CONTACTS_OBJECT);
                } else if (filtertype === 10) {
                    placeHolder = getLocalizedStrings().label.CAMPAIGNS.SELECT_A_FILTER + getObjectLabelByObject(constants.ACCOUNTS_OBJECT);
                }
                setPlaceholder(placeHolder);
            } catch (error) {
                console.log("Error in setFilterPlaceholder() in automationSmsCampaign.js : " + error);
            }
        }

        const changeFilterType = (value) => {
            try {
                if (isValidParam(value)) {
                    let filtertype = "";
                    if (value === 'group') {
                        filtertype = 0;
                    } else if (value === 'contact') {
                        filtertype = 9;
                    } else if (value === 'account') {
                        filtertype = 10;
                    }
                    setFilterType(value);
                    setFilterPlaceholder(filtertype);
                    let filterType = null;
                    let nodeObject = data;
                    if (isValidParam(nodeObject) && nodeObject.hasOwnProperty('details') && isValidParam(nodeObject['details'][0]) &&
                        nodeObject['details'][0].hasOwnProperty('filter_type')) {
                        filterType = nodeObject['details'][0]['filter_type'];
                        if (filterType === filtertype) {
                            let groupNames = "";
                            let campaign = "";
                            let groups = "";
                            let queryId = null;
                            let filters = [];
                            if (nodeObject['details'][0].hasOwnProperty('campaign')) {
                                campaign = nodeObject['details'][0]['campaign'];
                            }
                            if (nodeObject['details'][0].hasOwnProperty('groups')) {
                                groups = nodeObject['details'][0]['groups'];
                            }
                            if (nodeObject['details'][0].hasOwnProperty('query_id')) {
                                queryId = nodeObject['details'][0]['query_id'];
                            }
                            if (filtertype === 0) {
                                groupNames = campaign.split(",");
                                if (isInteger(groups)) {
                                    groups = groups.toString();
                                }
                                groups = groups.split(",");
                                filters = groupNames.map((objGroupname, i) => {
                                    return {
                                        value: groups[i],
                                        label: objGroupname
                                    }
                                });
                                setStateFilters(filters);
                                let log = changeLog;
                                log.stateFilters = filters;
                                setchangeLog({ ...log });
                            } else {
                                let objFilter = {
                                    value: queryId,
                                    label: campaign
                                }
                                filters.push(objFilter);
                                filters = filters[0];
                                setStateFilters(filters);
                                let log = changeLog;
                                log.stateFilters = filters;
                                setchangeLog({ ...log });
                            }
                        } else {
                            setStateFilters([]);
                            let log = changeLog;
                            log.stateFilters = [];
                            setchangeLog({ ...log });
                        }
                    } else {
                        setStateFilters([]);
                        let log = changeLog;
                        log.stateFilters = [];
                        setchangeLog({ ...log });
                    }
                    loadFilterOptions(filtertype);
                }
            } catch (error) {
                console.log("Error in changeFilterType() in automationSmsCampaign.js : " + error);
            }
        }

        const loadFilterOptions = (filter_type, filters) => {
            try {
                if (isValidParam(filter_type)) {
                    setRestofFilters([]);
                    setFilters_Loading(true);

                    let endpoints, params, isMulti;
                    if (filter_type === 0) {
                        isMulti = true;
                        endpoints = endPoints.GROUP.LIST;
                        params = {
                            search_field_name: "t_status",
                            search_text: "Active",
                            fields: ['name', 'id', 't_status']
                        };
                    } else if (filter_type === 9 || filter_type === constants.CAMPAIGN_QUERY_TYPE) {
                        isMulti = false;
                        endpoints = endPoints.CONTACTS.CAMPAIGN_FILTER;
                        params = null;
                    } else if (filter_type === 10) {
                        isMulti = false;
                        endpoints = endPoints.ACCOUNTS.CAMPAIGN_FILTER;
                        params = null;
                    }

                    let response = Promise.resolve(HTTPClient.get(endpoints, params));
                    response.then((response) => {
                        let arrayRecords = null;
                        if (filter_type === 0) {
                            arrayRecords = response.records;
                            if (isValidParam(arrayRecords)) {
                                arrayRecords = arrayRecords.filter((el) => {
                                    if (el.hasOwnProperty('t_status') && isValidParam(el.t_status)) {
                                        return el.t_status !== constants.INACTIVE;
                                    }
                                })
                            }
                        } else {
                            arrayRecords = response.queries;
                        }
                        let filtersList = [];
                        let _filters = !!filters ? filters : [];
                        if (arrayRecords !== null) {
                            filtersList = arrayRecords.map((objRecord, i) => {
                                if (objRecord.hasOwnProperty('type')) {
                                    return {
                                        value: objRecord.id,
                                        label: objRecord.name,
                                        title: objRecord.name,
                                        type: objRecord.type,
                                        isDisabled: false
                                    }
                                } else {
                                    return {
                                        value: objRecord.id,
                                        label: objRecord.name,
                                        title: objRecord.name,
                                        isDisabled: false
                                    }
                                }
                            });
                        }
                        let tempFilters = [];
                        if (filter_type === 0 && isValidParam(_filters)) {
                            _filters = _filters.map((objFilter, i) => {
                                filtersList.map((objFilterList, i) => {
                                    if (objFilterList.value === objFilter.value) {
                                        tempFilters.push(objFilter);
                                    }
                                });
                            });
                        } else {
                            tempFilters = _filters;
                        }
                        setStateFilters(tempFilters);
                        let log = changeLog;
                        log.stateFilters = tempFilters;
                        setchangeLog({ ...log });
                        setRestofFilters(filtersList);
                        setIsMulti(isMulti);
                        setFilters_Loading(false);
                        setFilter_Type(filter_type);
                        selectFilter(tempFilters);
                    });
                }
            } catch (error) {
                console.log("Error in loadFilterOptions() in automationSmsCampaign.js : " + error);
            }
        }

        const selectFilter = (event, options) => {
            if (isValidParam(options)) {
                if (options.hasOwnProperty('type')) {
                    setFilter_Type(options.type);
                } else {
                    if (stateFilterType === 'contact') {
                        setFilter_Type(9);
                    }
                }
                setStateFilters(options);
                let log = changeLog;
                log.stateFilters = options;
                setchangeLog({ ...log });
            }
        }

        const openDialog = (dialogData) => {
            dispatch(getAppDialog(true, constants.ALERT_DIALOG, 'Alert Dialog', null, dialogData, constants.AUTOMATION_DESIGNER_OBJECT));
        }

        const validation = ()=>{
            let dialogData = null;
            let filters = changeLog.stateFilters;
            let selectedFilter = null;
            let filterAlertMsg = null;
            if (filter_type === 0) {
                selectedFilter = getLocalizedStrings().label.CAMPAIGNS.GROUP;//"Group";
            } else if (filter_type === 9 || filter_type === constants.CAMPAIGN_QUERY_TYPE) {
                selectedFilter = getLocalizedStrings().label.CAMPAIGNS.CONTACT_FILTER;//"Contact Filter";
            } else if (filter_type === 10) {
                selectedFilter = getLocalizedStrings().label.CAMPAIGNS.ACCOUNT_FILTER;//"Account Filter";
            }
          
            if (filter_type === 0) {
                filterAlertMsg = getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_FILTERS_A
            } else if (filter_type === 9) {
                filterAlertMsg = getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_FILTERS_A
            } else if (filter_type === 10) {
                filterAlertMsg = getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_FILTERS

            }
        
            if (isInvalidParam(filters) || filters.length === 0) {
                dialogData = {
                    message: filterAlertMsg + " " +
                        selectedFilter,
                }
                openDialog(dialogData);
                return false;
            }
            return true;
        }

        const saveSmsCampaignData = () => {
           let isValid = validation()
           let filters = changeLog.stateFilters;
           let tempCampaignType = 0;
            if(isValid){           
                if (isValidParam(stateCampaignType) && stateCampaignType !== '') {
                    tempCampaignType = stateCampaignType;
                }

                let arrNodes = null;
                let nodeDetailStrObject = data;
                if (nodeDetailStrObject !== null && nodeDetailStrObject.hasOwnProperty('actionName')) {
                    let actionName = nodeDetailStrObject.actionName;
                    let arrNodesArr = getNodesByActionName(actionName);
                    arrNodes = arrNodesArr.filter(function (el) {
                        return el.childId !== nodeDetailStrObject.childId && el.nodeStatusFlg !== 3;
                    });
                }
                
                let nodeObject = data;
                let flag = true;
                    if (isValidParam(nodeObject)) {
                        if (flag) {
                            let detailsArray = null;
                            if (nodeObject.hasOwnProperty('details')) {
                                detailsArray = nodeObject.details[0];
                            }
                            let campaign = "";
                            let groups = "";
                            let query_id = 0;
                            if (filter_type === 0) {
                                filters.map((objFilter, i) => {
                                    if (campaign === "") {
                                        campaign = objFilter.label;
                                        groups = objFilter.value;
                                    } else {
                                        campaign = campaign + "," + objFilter.label;
                                        groups = groups + "," + objFilter.value;
                                    }
                                });
                            } else {
                                campaign = filters.label;
                                query_id = filters.value;
                            }
        
                            if (isValidParam(detailsArray)) {
                                detailsArray.campaign = campaign;
                                detailsArray.groups = groups;
                                detailsArray.filter_type = filter_type;
                                detailsArray.query_id = query_id;
                                if (detailsArray.t_status === null || detailsArray.t_status === undefined ||
                                    detailsArray.t_status === "" || detailsArray.id <= 0) {
                                    detailsArray.t_status = 'New';
                                }
                                detailsArray.campaign_type = tempCampaignType;
                            } else {
                                nodeObject.details = [];
                                detailsArray = {
                                    campaign: campaign,
                                    groups: groups,
                                    filter_type: filter_type,
                                    query_id: query_id,
                                    t_show: 0,
                                    t_status: 'New',
                                    id: 0,
                                    campaign_type: tempCampaignType
                                }
                                nodeObject.detailStatusFlg = 2;
                                nodeObject.details.push(detailsArray);
                            }
                            nodeObject = removeError(nodeObject);
                            dispatch(addNode(automationCampaign.data));
                            closeDrawer();
                        }
                }
            }
        }

        

        function addCampaignType(obj) {
            try {
                let camObj = {
                    id: obj.id,
                    name: obj.name
                }

                let camTyps = getArrayParam(campTypelistArr);
                camTyps.push(camObj);
                addCampaignTypeIntoReduxStore(camObj);
                sortArrayObjectByProperty(camTyps, 'name');
                let campTypeMenuItems = getCampaignTypeMenuItems(camTyps);
                setCampTypelistArr(camTyps);
                setCampTypeMenuItems(campTypeMenuItems);
            } catch (error) {
                console.log("Error in 'automationSmsCampaign.js -> addCampaignType()': " + error);
            }
        }

        const changeCampaignType = (event) => {
            let value = event.target.value;
            try {
                if (isValidParam(value)) {
                    if (value !== 'Manage') {
                        setCampaignType(value);
                    } else {
                        let data = {};
                        data.object = constants.AUTOMATION_DESIGNER_OBJECT;
                        dispatch(getAppDialog(true, constants.ADD_CAMPAIGN_TYPE_DIALOG, getLocalizedStrings().label.CAMPAIGNS.ADD_CAMPAIGN_TYPE, addCampaignType, null, null));
                    }
                }
            } catch (error) {
                console.log("Error in 'automationSmsCampaign.js -> changeCampaignType()': " + error);
            }
        }

        const getCampaignTypeMenuItems = (campTypelistArr) => {
            try {
                let campaignTypes = getArrayParam(campTypelistArr);
                let campTypeItems = null;
                if (campaignTypes !== null && campaignTypes.length > 0) {
                    campTypeItems = campaignTypes.map((camTy, index) => {
                        return <MenuItem key={camTy.id} value={camTy.id} style={styles.popoverMenuItem} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '142px' }} title={camTy.name}>{camTy.name}</div>} </MenuItem>
                    });
                }
                return campTypeItems;
            } catch (error) {
                console.log("Error in 'AutomationSmsCampaign.js -> getCampaignTypeMenuItems()': " + error);
            }
        }


        const handleQualifiedContacts = () => {
            try {
                if (validateFilter()) {
                    const label = getLocalizedStrings().label.BROADCAST_CAMPAIGNS.QUALIFIED_CONTACTS;
                    const url = `/${constants.CONTACTS_OBJECT}/queries`;
                    openTab(label, url);
                }
            } catch (error) {
                console.error("Error in 'automationSmsCampaign.js -> handleQualifiedContacts()':" + error);
            }
        }

        const openTab = (label, url) => {
            try {
                let activeTab = getObjectParam(getActiveTab());
                let activeTabIndex = getIntParam(activeTab.index);
                let filter = { id: 542, name: 'All records', type: constants.SEARCH_QUERY_TYPE_ALL }
                let tab = { label: getStringParam(label), object: constants.CONTACTS_OBJECT, type: TYPE_LIST_VIEW, imgName: null, url: url, isActive: true, parentTabIndex: activeTabIndex };
                let info = {
                    isReport: true,
                    reportLabel: label,
                    filter: filter,
                    input_param: {
                        call_from: constants.CAMPAIGN_QUALIFIED_CONTACTS,
                        filter_options: changeLog.stateFilters,
                        filter_type: getStringParam(stateFilterType)
                    }
                };
                tab.info = info;
                addTab(tab, true);
            } catch (error) {
                console.error("Error in 'automationSmsCampaign.js -> openTab()':" + error);
            }
        }

        const validateFilter = () => {
            let isValid = true;
            let dialogData = null;
            let selectedFilter = null;
            let filterAlertMsg = null;
            try {
                let campName = campName;
                let filters = changeLog.stateFilters;
                if (filter_type === 0) {
                    selectedFilter = getLocalizedStrings().label.CAMPAIGNS.GROUP;//"Group";
                    filterAlertMsg = getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_FILTERS_A
                } else if (filter_type === 9 || filter_type === constants.CAMPAIGN_QUERY_TYPE) {
                    selectedFilter = getLocalizedStrings().label.CAMPAIGNS.CONTACT_FILTER;//"Contact Filter";
                    filterAlertMsg = getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_FILTERS_A;
                } else if (filter_type === 10) {
                    selectedFilter = getLocalizedStrings().label.CAMPAIGNS.ACCOUNT_FILTER;//"Account Filter";
                    filterAlertMsg = getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_FILTERS;
                }
                if (isInvalidParam(filters) || filters.length === 0) {
                    isValid = false;
                    dialogData = {
                        message: filterAlertMsg + " " + selectedFilter,
                    }
                    openDialog(dialogData);
                } 
            } catch (error) {
                console.error("Error in 'automationSmsCampaign.js -> validateFilter()':" + error);
            }
            return isValid;
        }


    
        let campaignStatus = automationCampaign.data.status;

        let selectedFilter = "";
        if (filter_type === 0) {
            selectedFilter = "group";
        } else if (filter_type === 9 || filter_type === constants.CAMPAIGN_QUERY_TYPE) {
            selectedFilter = "contact";
        } else if (filter_type === 10) {
            selectedFilter = "account";
        }
        selectedFilter = selectedFilter.toString();

        let selectedFilters = changeLog.stateFilters;
        let restofFilters = [];
        let filters = [];
        if (stateFilterType === 'group') {
            filters = stateRestofFilters.filter(function (el) { return selectedFilters.indexOf(el) < 0; });

            if (changeLog.stateFilters.length >= 3) {
                filters.map((filter, index) => {
                    filter.isDisabled = true;
                    restofFilters.push(filter);
                })
            } else {
                filters.map((filter, index) => {
                    filter.isDisabled = false;
                    restofFilters.push(filter);
                })
            }
        } else {
            filters = stateRestofFilters;
            filters.map((filter, index) => {
                filter.isDisabled = false;
                restofFilters.push(filter);
            })
        }
        sortArrayObjectByProperty(stateRestofFilters, 'label');

        let contentHeight = window.innerHeight - 200;
        let top = (contentHeight - 10) / 2;
        if (mounted) {
            return (
                <div id="tcMainDiv" style={{ paddingTop: '40px', paddingLeft: '20px', paddingRight: '20px' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '44%', minWidth: '44%' }}>
                            <div style={{ ...styles.customInputHolder, borderRadius: '4px', height: '44' }}>
                                <label style={{ background: 'inherit', marginTop: '-1px', fontWeight: 'normal', fontSize: 12, lineHeight: 0.1, padding: '0px 5px 0px 5px', background: "#fafafa" }}>
                                    {getLocalizedStrings().label.AB_CAMPAIGNS.TARGET_BY}
                                </label>
                                <RadioGroup className="sf-data-fieldset" name="layout" style={{ width: '100%', marginTop: '-2px' }} onChange={(e) => changeFilterType(e.target.value)} value={stateFilterType} row>
                                    <FormControlLabel value={"group"} control={<Radio color="default" />} label={getLocalizedStrings().label.CAMPAIGNS.GROUP} />
                                    <FormControlLabel value={"contact"} control={<Radio color="default" />} label={getObjectLabelByObject(constants.CONTACTS_OBJECT)} />
                                    <FormControlLabel value={"account"} control={<Radio color="default" />} label={getObjectLabelByObject(constants.ACCOUNTS_OBJECT)} />
                                </RadioGroup>
                            </div>
                        </div>
                        <div style={{ ...styles.sf_7, paddingLeft: '0px', maxWidth: '55.5%' }} >
                            <div style={{ display: 'flex', width: '94%', marginLeft: '17px', paddingBottom: '10px' }}>
                                <div style={{ width: '90%' }}>
                                    {stateFilterType === 'group' &&
                                        <Autocomplete
                                            multiple
                                            id={"autocomplete_group"}
                                            name={"autocomplete_group"}
                                            key={"autocomplete_group"}
                                            className={'sf-atc-autoComplete'}
                                            value={changeLog.stateFilters}
                                            getOptionDisabled={(option) => option.isDisabled === true || changeLog.stateFilters.length >= 3}
                                            options={stateRestofFilters}
                                            onChange={(event, option) => selectFilter(event, option)}
                                            getOptionLabel={(option) => !!option && option.label}
                                            disableClearable={false}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        style={{ ...styles.autocompleteChip, width: '26.5%', height: '24px' }}
                                                        label={option.label}
                                                        title={option.label}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    placeholder={changeLog.stateFilters && changeLog.stateFilters.length === 0 ? placeholder : ''}
                                                    className={'sf-atc-autoComplete-text-field'}
                                                    variant="outlined" margin="dense" size='small'
                                                />
                                            )}
                                        />

                                    }
                                    {stateFilterType !== 'group' &&
                                        <Autocomplete
                                            id={"autocomplete_group"}
                                            name={"autocomplete_group"}
                                            key={"autocomplete_group"}
                                            className={'sf-atc-autoComplete'}
                                            value={changeLog.stateFilters}
                                            getOptionDisabled={(option) => option.isDisabled === true}
                                            options={restofFilters}
                                            onChange={(e, options) => selectFilter(e, options)}
                                            disableClearable={false}
                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    placeholder={placeholder}
                                                    className={'sf-atc-autoComplete-text-field'}
                                                    variant="outlined" margin="dense" size='small'
                                                />
                                            )}
                                        />
                                    }
                                </div>
                                <div style={{ width: '10%' }}>
                                    <i className="fa fa-eye" name={'qualifiedcontacts'} onClick={() => handleQualifiedContacts()}
                                        title={getLocalizedStrings().label.BROADCAST_CAMPAIGNS.QUALIFIED_CONTACTS} style={{ fontSize: '28px', color: '#a8a0a0', cursor: 'pointer', padding: '6px 0px 4px 10px' }}>
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row" style={{ paddingTop: '12px' }}>
                        {
                            (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                            <div style={{ ...styles.sf_5}}>
                                <Button
                                    style={styles.listViewPrimaryButton}
                                    onClick={() => saveSmsCampaignData()}
                                >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                            </div>
                        }
                    </div>
                </div>

            );
    }else {
        return (<div style={{ width: '100%', height: contentHeight }} >
            {<div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }} >
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            }
        </div>);
    }
};

export default AutomationSMSCampaign;
