import React, { useState, useEffect, forwardRef, useImperativeHandle, useMemo} from 'react';
import { styles } from '../../../services/constants/styles';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import { Select as SelectField, MenuItem, InputLabel, Checkbox, ListItemText } from '@mui/material';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { isValidParam, getArrayParam, getStringParam, getBooleanParam, getIntParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { getFieldListValuesPromise } from '../../../services/actions/sfFormActions';
import 'flatpickr/dist/flatpickr.min.css';
import { dateFormat, dateFormatFlatPicker, sortArrayObjectByProperty } from '../../../services/helper/utils';
import moment from 'moment';
import * as sfDialogs from '../components/sfDialogs';
import replace from 'lodash/replace';
import SFAutoComplete from '../components/sfAutoComplete';
import PopOver from './PopOver';
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from "@mui/material/Autocomplete";
import * as SFFormActions from "../../../services/actions/sfFormActions";

const Filter = forwardRef(({ data, isAssociateObjExist, object, setParentSteperFilterState, parentFilter, fromFieldData, selectedassociateObj, callFrom, assosiateObjects,sendDataToParent ,associateRow }, ref) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const [filter, setFilter] = useState({
        id: 0,
        t_formid: data.table.charAt(0).toUpperCase() + data.table.slice(1),
        t_name: '',
        sql_array: [],
        associateSql_array: [],
    });
    const [error, setError] = useState({
        name: ''
    });
    const [selectedassociate, setSelectedassociate] = useState(selectedassociateObj);
const [childRows, setChildRows]=useState([])
    useEffect(() => {
        if (parentFilter !== undefined && Object.keys(parentFilter).length > 0) {
            setFilter({...parentFilter})
        }
    }, [parentFilter]);

    useEffect(() => {
        associateRow(childRows)
    }, [childRows]);

    const handleChangeFilterTextField = (event) => {
        let target = event.target;
        let value = target.value;
        let name = target.name;
        let tempFilter = filter;
        tempFilter.t_name = value;
        setFilter({...tempFilter});
        if (value.trim() !== '') {
            let tempError = error;
            tempError.name = "";
            setError(tempError);
        }

        if (setParentSteperFilterState !== undefined) {
            setParentSteperFilterState(filter)
        }
    }

    const updateFields = (fields) => {
        let tempFilter = filter;
        if (isAssociateObjExist) {
            tempFilter.associateSql_array = fields;
        } else {
            tempFilter.sql_array = fields;
        }
        setFilter({...tempFilter});
        if (setParentSteperFilterState !== undefined) {
            setParentSteperFilterState(filter)
        }
    }
    useImperativeHandle(ref, () => ({

    }));

    const showCustomSnackBar = (message, bodyStyle, style) => {
        dispatch(showCustomSnackBar(message, bodyStyle, style));
    }
    const onchangeAssociateObj = (value) => {
        if(isAssociateObjExist && childRows.length > 0){
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, 'If you change the table name , the existing criteria will be removed. Are you sure to continue ?', () => OnConfirmAssociatObj(value), null);
        }else{
            setSelectedassociate(value);
            sendDataToParent(value.value)
        }
    }
const handleRowsFromChild =(tempRow)=>{
    setChildRows(tempRow)
}

 const  OnConfirmAssociatObj =(value)=>{
let tempFilter = filter;
if (isAssociateObjExist) {
    tempFilter.associateSql_array = [];
    setFilter({...tempFilter})
    setSelectedassociate(value);
    sendDataToParent(value.value)
 }}
    let objectList = app.objectList;
    let filterObj = objectList.filter(option => option.name === data.table);
    return (
        <div style={{ paddingLeft: '19px', paddingRight: '26px', paddingBottom: '10px' }}>
            <div style={{ paddingTop: '5px' }}>
                <div className="row">
                    <div className="col-sm-4 p-0">
                        {callFrom === null && !isAssociateObjExist &&
                            <label style={{
                                display: 'inline-block',
                                maxWidth: '100%',
                                marginBottom: '5px',
                                fontWeight: 700
                            }}>{filterObj[0]?.label} Based Filter</label>
                        }
                        {callFrom != null && isAssociateObjExist && assosiateObjects !== [] &&
                            <label style={{
                                display: 'inline-block',
                                maxWidth: '100%',
                                marginBottom: '5px',
                                fontWeight: 700
                            }}>Use Associated Object Properties</label>
                        }
                    </div>
                </div>

                <div className="row">
                    {callFrom === null && !isAssociateObjExist &&
                        <div className="col-sm-12 p-0">

                            <FormControl style={{ width: '100%', marginBottom: '14px' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    label={getLocalizedStrings().label.FILTER.NAME_REQUIRED}
                                    fullWidth={true}
                                    name="t_name"
                                    value={filter.t_name}
                                    onChange={handleChangeFilterTextField}
                                    error={error.name !== ''}
                                    helperText={error.name}
                                    autoFocus={true}
                                    autoComplete="new-password"
                                    margin="dense"
                                    size='small'
                                    className={"sf-fields-bg"}
                                />
                            </FormControl>
                        </div>
                    }

                    {callFrom !=null && isAssociateObjExist && assosiateObjects !== [] &&
                        <div style={{...styles.sf_6}}>
                            <FormControl style={{ width: '82%', marginBottom: '10px' }} className="test" noValidate autoComplete="off">
                                {assosiateObjects.length > 0 &&
                                    <Select
                                        name="field"
                                        value={selectedassociate}
                                        options={[...assosiateObjects]}
                                        onChange={(v)=>onchangeAssociateObj(v)}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder={"Add" + selectedassociate.label + "Properties"}
                                    />
                                }
                            </FormControl>
                        </div>
                    }
                    <div className="col-sm-2">
                    </div>
                </div>
                <div>
                    <Fields
                        object={data.table}
                        fields={isAssociateObjExist ? filter.associateSql_array : filter.sql_array}
                        updateFields={(fields) => { updateFields(fields) }}
                        showCustomSnackBar={showCustomSnackBar}
                        isAssociateObjExist={isAssociateObjExist}
                        fromFieldData={fromFieldData}
                        _selectedassociate ={selectedassociate}
                        rowsFromChild ={handleRowsFromChild}
                    />
                </div>

            </div>

        </div>
    );

});


const Fields = ({ fields, object, fromFieldData, isAssociateObjExist, updateFields,_selectedassociate ,rowsFromChild}) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const [rows, setRows] = useState(fields);
    const [field_options, setField_options] = useState([]);
    const [field, setField] = useState({
        t_fldid: '',
        t_logicalop: '',
        t_operator: '',
        t_value: '',
        is_start: false,
        is_end: false,
        t_tableid: '',
    });
    const [selected_field, setSelected_field] = useState('');

    useEffect(() => {
        setRows(fields);
        generateFieldOptions(fields);
        rowsFromChild(rows)
    }, [fields,rows ,fromFieldData,_selectedassociate]);


    const addField = (fieldValue) => {
        if (rows.length >= 10) {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.HOME.FILTER_ON_MAX_FIELD, null);
        } else {
            if (fieldValue === '') {
                dispatch(showCustomSnackBar('Please select a Field  Name.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return;
            }

            let t_tableid = getIntParam(OBJECT_TABLEID_MAP[object]);
            let condition = {
                t_logicalop: '',
                t_operator: '=',
                t_value: '',
                t_tableid: t_tableid,
                conditionFieldProps: {
                    isOpenPopover: false,
                    popoverTarget: null,
                    isShowConditionValue: true
                }
            };

            if (rows.length !== 0) {
                condition.t_logicalop = 'AND';
            }

            if (rows.length === 0 && isAssociateObjExist) {
                condition.t_logicalop = 'AND';
            }
            
            let newField = { t_fldid: fieldValue.value, t_fldname: fieldValue.label, t_tableid: t_tableid, conditions: [condition] };
            let fieldProps = getFiledPropsByFieldId(fieldValue.value);
            newField = { ...newField, ...fieldProps };
            newField = getObjectParam(newField);
            let fieldName = isValidParam(newField.filedProps) ? getStringParam(newField.filedProps.name) : '';

            if (fieldName === 't_status') {
                let params = {};
                params.object = object;
                params.name = fieldName;
                params.list_type = constants.LIST_FIELD_TYPE_REGULAR;

                let promise = getFieldListValuesPromise(params);
                promise.then((response) => {
                    response = getArrayParam(response);
                    if (response.length > 0) {
                        sortArrayObjectByProperty(response, 'value');
                        newField.filedProps.list_values = response;
                        let row = [...rows, newField];
                        row = JSON.parse(JSON.stringify(row));
                        setRows(row);
                        removeFieldFromFieldList(fieldValue);
                        updateFields(row);
                    }
                });
            } else {
                let row = [...rows, newField];
                row = JSON.parse(JSON.stringify(row));
                removeFieldFromFieldList(fieldValue);
                setRows(row);
                updateFields(row);
            }
        }
    }

    const getFiledPropsByFieldId = (fileldId) => {
        let selectFieldProps = {};
        fromFieldData.fields.map((obj) => {
            if (obj.t_tablefldid === fileldId) {
                selectFieldProps['filedProps'] = obj;
            }
        });
        return selectFieldProps;
    }

    const deleteField = (idx) => {
        let field = {
            label: rows[idx].t_fldname,
            value: rows[idx].t_fldid
        };
        if (rows.length > 1 && idx === 0) {
            let row1 = rows[idx];
            let row2 = rows[idx + 1];
            row2.conditions[0].t_logicalop = '';
            rows.splice(idx, 1);
        } else {
            rows.splice(idx, 1);
        }
        setRows(rows);
        updateFields(rows);
        addFieldToFieldList(field);
    }

    const addFieldToFieldList = (field) => {
        let tempFieldOptuons = field_options;
        tempFieldOptuons.push(field);
        setField_options(tempFieldOptuons);
        setSelected_field('');
        sortFieldOptionsList(tempFieldOptuons);
    }

    const removeFieldFromFieldList = () => {
        let value = selected_field.value;
        let options = field_options.filter(option => option.value !== value);
        setField_options(options);
        setSelected_field('');
    }

    const generateFieldOptions = (tempFields) => {
        
            let options = [];
            if (isValidParam(fromFieldData)) {
                let fromField =  fromFieldData;
                let fields = fromField.fields;
                if (fromField.object === constants.QUOTATION_OBJECT) {
                    let filteredOptions = fields.filter((option) => {
                        return option.name === 'BillTo' || option.name === 'Company' || option.name === 'CreateDate' ||
                            option.name === 'Discount' || option.name === 'DueDate' || option.name === 'Email' || option.name === 'ExpDate' ||
                            option.name === 'GrandTtl' || option.name === 'OrdType'
                    });
    
                    fields = filteredOptions;
                }
                if (fromField.object === constants.OPPORTUNITIES_OBJECT) {
                    let Options = fields.filter((option) => {
                        return option.name !== 'product_id' && option.name !== 'link_info' && option.name !== 'forecast_amount'
    
                    });
                    fields = Options;
                }
    
                fields.map(function (obj) {
                    if (obj.field_type !== 'header' && obj.label !== '' && obj.name !== 'workflow_id' && obj.name !== 'main_contact_id') {
                        options.push({
                            label: obj.label,
                            value: obj.t_tablefldid
                        });
                    }
    
                });
    
                let withOutSelectOption = [];
                options.map((option) => {
                    let containOption = false;
                    tempFields?.map((row) => {
                        if (option['value'] === row.t_fldid) {
                            containOption = true;
                        }
                    });
                    if (!containOption) {
                        withOutSelectOption.push(option);
                    }
                });
                options = withOutSelectOption;
                sortFieldOptionsList(options);
                setField_options(options);
               
            }
        
       
    }

    const sortFieldOptionsList = (options) => {
        options.sort(function (option1, option2) {
            let opt1 = option1.label.toLowerCase();
            let opt2 = option2.label.toLowerCase();
            if (opt1 < opt2)
                return -1;
            if (opt1 > opt2)
                return 1;
            return 0;
        });
    }

    const handleFieldSelect = (value) => {
        if (value === undefined || value === null) {
            value = '';

            setSelected_field(value);
        } else {
            setSelected_field(value);
           addField(value);
        }
    }

    const setFieldFunction = (conditions, i) => {
        let tempRow = rows;
        tempRow[i].conditions = conditions;
        setRows(tempRow);
        updateFields(tempRow)
    }

    const generateFieldSData = () => {
        if(rows?.length > 0){
            return rows?.map((row, i) => {
                return (
                    <Field
                        field={row}
                        deleteField={() => { deleteField(i) }}
                        index={i}
                        object={object}
                        updateField={(conditions, i) => { setFieldFunction(conditions, i) }}
                    />
                )
            }
            )
        }  
    }

    if (rows === null || rows === 'undefined') {
        rows = [];
    }
    let objectList = app.objectList;
    let filterObj = objectList.filter(option => option.name === object);
    let fieldsData = generateFieldSData();

let criteriaObj = filterObj[0]?.label;
if(criteriaObj == undefined && isAssociateObjExist){
    if(_selectedassociate!= undefined &&  _selectedassociate?.length == 1){
        criteriaObj =_selectedassociate[0].value
    }else if(_selectedassociate!= undefined && _selectedassociate?.value !=undefined )
    {
        criteriaObj = _selectedassociate?.value;
    }
}

    return (
        <div>
            {fieldsData}
            <div className="row">
                <div className="col-sm-10 p-0">
                    <div className="row">
                        <div className="col-sm-4 p-0">
                            <label style={{
                                display: 'inline-block',
                                maxWidth: '100%',
                                marginBottom: '5px',
                                fontWeight: 700
                            }}>Add criteria to the filter</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 p-0">
                            <FormControl style={{ width: '98%' }} className="test" noValidate autoComplete="off">
                                {field_options.length > 0 &&
                                    <Select
                                        name="field"
                                        value={selected_field}
                                        options={[...field_options]}
                                        onChange={(value)=>handleFieldSelect(value)}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                placeholder={"Add " + criteriaObj + " Properties"}
                                    />
                                }
                            </FormControl>
                        </div>
                        <div className="col-sm-6" style={{ padding: 0, float: 'right' }}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}


const Field = ({ index, field, updateField, object, deleteField }) => {
    const app = useSelector((state) => state.app);

    const [rows, setRows] = useState([]);
    const [filedProps, setFiledProps] = useState({});

    useEffect(() => {
        setRows(field.conditions);
        setFiledProps(field.filedProps);
        
    }, [rows,field]);

    const addCondition = () => {
        let tempRows = [
            ...rows,
            {
                t_value: '',
                t_tableid: getIntParam(OBJECT_TABLEID_MAP[object]),
                t_operator: '=',
                t_logicalop: 'OR',
                conditionFieldProps: {
                    isOpenPopover: false,
                    popoverTarget: null,
                    isShowConditionValue: true
                }
            }
        ];
        setRows(tempRows);
        updateField(tempRows, index)
    }

    const deleteCondition = (fieldIndex, idx) => {
        if (rows.length === 2 && idx === 0) {
            let cond1 = rows[idx];
            let cond2 = rows[idx + 1];
            let logicalOperator = cond1.t_logicalop;
            cond2.t_logicalop = logicalOperator;
            rows.splice(idx, 1);

        } else {
            rows.splice(idx, 1);
        }

        rows.forEach((obj, index) => {
            if (index === 0 && fieldIndex === 0) {
                obj.t_logicalop = '';
            }
        });
        setRows(rows);
        updateField(rows, index);
        if (rows.length === 0) {
            deleteField();
        }
    }


    const setCondition = (condition, i) => {
        let tempRow = rows;
        tempRow[i] = condition;
        setRows(tempRow);
        updateField(rows, index);
    }

    

   

    const getConditionsData = () => {
        let fieldIndex = getIntParam(index);
        return rows.map((row, index) => {
            return (
                <Condition
                condition={row}
                deleteConditionRow={() => { deleteCondition(fieldIndex, index) }}
                index={index}
                field_name={field.t_fldname}
                updateCondition={(condition, index) => { setCondition(condition, index) }}
                filedProps={filedProps}
            />
            )
        }
        )
       
    }

    const changeRowCondition = (value) => {
        let condition = rows[0];
        condition.t_logicalop = value;
        setCondition(condition, 0);
    }
    let conditions = getConditionsData();
    conditions = getArrayParam(conditions);
    let activeStyle = { backgroundColor: '#e0e0e0',padding:'0px 15px',display: 'inline-block',position: 'relative',overflow: 'hidden',whiteSpace:'nowrap'} 
    let inactiveStyle={ backgroundColor: 'white',padding:'0px 15px',display: 'inline-block',position: 'relative',overflow: 'hidden',whiteSpace:'nowrap'} ;
    return (
        <div>
            {(index > 0 && rows.length >= 1) && <div style={{ marginBottom: 20 }}>
                <div style ={{ borderRadius: '3px',cursor: 'pointer',textAlign: 'center',overflow: 'hidden',position: 'relative',width: '112px',whiteSpace:'nowrap'}}>
                    <div style ={rows[0].t_logicalop.trim() === 'AND' ? activeStyle : inactiveStyle} onClick={() => { changeRowCondition('AND') }}>
                        <span>{getLocalizedStrings().label.FILTER.AND}</span>
                    </div>
                    <div style ={rows[0].t_logicalop.trim() === 'OR' ? activeStyle : inactiveStyle} onClick={() => { changeRowCondition('OR') }}>
                        <span>{getLocalizedStrings().label.FILTER.OR}</span>
                    </div>
                </div>

            </div>}
            <div style = {{ border: '5px solid #e0e0e0',marginBottom: '20px',background: '#fff'}}>
                <div style= {{ backgroundColor:'#e0e0e0',padding:'6px 8px',fontSize: '14px',fontWeight: 'bold',marginBottom:'20px'}}>
                    {field.t_fldname}
                </div>
                <div style = {{padding:'0 15px'}}>
                    {conditions}
                    {conditions.length < 10 &&
                        <div style={{ paddingBottom: '10px', paddingLeft: '5px', paddingTop: '3px', color: '#6495ED' }}>
                            <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={addCondition} title={getLocalizedStrings().label.FILTER.ADD_CONDITION}><a>{getLocalizedStrings().label.FILTER.ADD_ANOTHER}</a></span>
                        </div>}
                </div>
            </div>
        </div>
    );

}

const Condition = ({  updateCondition, condition, filedProps, index, field_name, deleteConditionRow }) => {
    const app = useSelector((state) => state.app);
    const [error, setError] = useState({
        field_value: ''
    });
    const [stateCondition, setStateCondition] = useState(
        { ...condition, selectedListIds: '', selectedListValues: getStringParam(condition.t_value), selectedListValuesArray: filedProps?.field_type === constants.FIELD_TYPE_LIST && filedProps.list_type === constants.LIST_FIELD_TYPE_MULTI_SELECT ? condition.t_value === "" ? [] : condition.t_value.split(",") : [], t_operatorObj: {}, }
    );
    const [isShowDate, setisShowDate] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const [operator_options_string, setOperator_options_string] = useState([
        { label: getLocalizedStrings().label.FILTER.CONTAINS, value: 'LIKE' },
        { label: getLocalizedStrings().label.FILTER.DOES_NOT_CONTAINS, value: 'NOT_LIKE' },
        { label: getLocalizedStrings().label.FILTER.IS_EQUAL_TO, value: '=' },
        { label: getLocalizedStrings().label.FILTER.IS_NOT_EQUAL_TO, value: 'NOT_EQUAL' },
        { label: getLocalizedStrings().label.FILTER.IS_EMPTY, value: 'IS_NULL' },
        { label: getLocalizedStrings().label.FILTER.IS_NOT_EMPTY, value: 'IS_NOT_NULL' },
    ]);
    const [operator_options_number, setOperator_options_number] = useState([
        { label: getLocalizedStrings().label.FILTER.IS_GREATER_THAN, value: '>' },
        { label: getLocalizedStrings().label.FILTER.IS_GREATER_THAN_OR_EQUAL_TO, value: '>=' },
        { label: getLocalizedStrings().label.FILTER.IS_LESS_THAN, value: '<' },
        { label: getLocalizedStrings().label.FILTER.IS_LESS_THAN_OR_EQUAL_TO, value: '<=' },
    ]);

    useEffect(()=> {
        let value = stateCondition.t_value;
        if (value === 'CURDATE()' || value === 'CURWEEK()' || value === 'CURMONTH()') {
        setisShowDate(false);
        } else {
           setisShowDate(true);
        }
    },[stateCondition.t_value])

    useEffect(() => {
        if (condition.t_operator !== null && condition.t_operator !== "") {
            let selectedOperatorObj = operator_options_string.filter(e => e.value === condition.t_operator);
            if (selectedOperatorObj.length === 0) {
                selectedOperatorObj = operator_options_number.filter(e => e.value === condition.t_operator);
            }
            let temp = stateCondition;
            setStateCondition({...temp,t_operatorObj:selectedOperatorObj[0]});
        }

    }, [condition]);

useEffect(()=>{
    let isPickDate= false;
    let tempfromDate = null;
    let temptoDate = null;
    let value = stateCondition.t_value;
        if (stateCondition.t_operator === 'LIKE' || stateCondition.t_operator === 'NOT_LIKE') {
            let tempValue = getStringParam(value);
            tempValue = tempValue.split('AND');
            if (tempValue.length === 2) {
                 tempfromDate = tempValue[0].trim();
                 temptoDate = tempValue[1].trim();

                tempfromDate = tempfromDate.replace('00:00', '');
                temptoDate = temptoDate.replace('23:59', '');

                tempfromDate = tempfromDate.trim();
                temptoDate = temptoDate.trim();
                setFromDate(tempfromDate);
                setToDate(temptoDate);

               
            }
        } 
      
    
},[toDate,fromDate])

    const handleConditionOperatorChange = (value) => {
        let temp = condition;
        temp.t_operator = value.value;
        temp.t_operatorObj = value;
        if (value.value === "IS_NULL" || value.value === "IS_NOT_NULL") {
            temp.conditionFieldProps.isShowConditionValue = false;
        } else {
            temp.conditionFieldProps.isShowConditionValue = true;
        }
        if(value.value == ">=" ||value.value == "<=" ||value.value == ">" ||value.value == "<" ||value.value == "=" ||value.value == "NOT_EQUAL"){
            condition.t_value = "";
        }
        setStateCondition({...temp});
        updateCondition(temp, index);
    }

    const handleChangeConditionTextField = (event) => {
        let target = event.target;
        let value = target.value;
        let temp = stateCondition;
        temp.t_value = value;
        setStateCondition({...temp});
        updateCondition(stateCondition, index);
    }

    const setSelectFieldValue = (fieldName, fieldType, event, value) => {

        let temp = stateCondition;
        temp.t_value = value;
        temp.conditionFieldProps.isOpenPopover = false;
        temp.conditionFieldProps.popoverTarget = null;
        if (fieldType === constants.FIELD_TYPE_DATE || fieldType === constants.FIELD_TYPE_DATE_TIME) {
            if (value === 'Pick a Date.') {
                setisShowDate(true);
            } else {
                setisShowDate(false);
            }
        }
        setStateCondition({...temp});
        updateCondition(stateCondition, index);
    }

    const handleDateChange = (date, fieldName) => {
        let tempCondition = stateCondition;
        let tempDateValue;
        let tempTodate;
        let tempfromDate;
        if (isValidParam(date)) {
            let selectedDate = new Date(date);
            let dateValue = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), selectedDate.getHours(), selectedDate.getMinutes())
            const momentDate = moment(dateValue);
            if (fieldName === 'fromDate' || fieldName === 'toDate') {
                if(fieldName === 'fromDate'){
                    tempTodate = toDate;
                    tempfromDate = momentDate.format(dateFormat[app.me.date_format]);
                    setFromDate(tempfromDate );
                    tempCondition.t_value = tempfromDate;

                }else{
                    tempfromDate = fromDate;
                    tempTodate = momentDate.format(dateFormat[app.me.date_format]);
                    setToDate(tempTodate);
                    tempCondition.t_value = tempTodate;

                }
                if (isValidParam(tempfromDate) && isValidParam(tempTodate)) {
                    tempDateValue = tempfromDate + ' 00:00 AND ' + tempTodate + ' 23:59';
                    tempCondition.t_value = tempDateValue;
                    
                }
            } else {
                tempDateValue = momentDate.format(dateFormat[app.me.date_format]);
                let operator = getStringParam(tempCondition.t_operator);
                if (isValidParam(operator) && (operator === '>' || operator === '<=')) {
                    let time=" 23:59";
                    tempDateValue =  tempDateValue.concat(time);

                  
                }
                tempCondition.t_value = tempDateValue;
            }
        } else {

            tempCondition.t_value = '';
        }
        setStateCondition({...tempCondition});
        updateCondition(stateCondition, index);
    }


    const generateFieldOperatorOptions = (field) => {
        if (field.field_type === constants.FIELD_TYPE_INTEGER
            || field.field_type === constants.FIELD_TYPE_FLOAT
            || field.field_type === constants.FIELD_TYPE_DATE
            || field.field_type === constants.FIELD_TYPE_DATE_TIME
        ) {
            return [...operator_options_number, ...operator_options_string];
        } else {
            return operator_options_string;
        }
    }

    const generateMenuItemList = (itemValues) => {
        let listItems = itemValues.map((item, i) => {
            return (
                <MenuItem
                    key={i}
                    id={item.id}
                    label={item.value}
                    value={item.value}
                    style={{ ...styles.popoverMenuItem }}
                    title={item.value} >{item.value}</MenuItem>
            );
        });

        return listItems;
    }

    const generateTypeWiseTextFields = (field) => {
        let isPickDate= false;
        let tempfromDate = null;
        let temptoDate = null;
        let value = condition.t_value;
        let HHFormat = new RegExp("HH");
        let datePickerOptions = {
            enableTime: false,
            dateFormat: dateFormatFlatPicker[app.me.date_format],
            time_24hr: HHFormat.test(app.me.date_format) ? true : false
        };
        field = getObjectParam(field);
        field = { ...field };
        if (field.name === 'sales_rep' && field.field_type === constants.FIELD_TYPE_LIST) {
            let values = getArrayParam(field.list_values);
            if (values.filter(e => e.value === 'me()').length === 0) {
                values.push({ id: 'me()', value: 'me()' });
            }
            field.list_values = values;
        } else if (field.name === 'state' && field.field_type === constants.FIELD_TYPE_LIST) {
            field.list_values = [];
            field.field_type = constants.FIELD_TYPE_TEXT;
        } 
        else if (field.field_type === constants.FIELD_TYPE_DATE || field.field_type === constants.FIELD_TYPE_DATE_TIME) {
            if (condition.t_operator === 'LIKE' || condition.t_operator === 'NOT_LIKE') {
                let tempValue = getStringParam(value);
                tempValue = tempValue.split('AND');
                if (tempValue.length === 2) {
                     tempfromDate = tempValue[0].trim();
                     temptoDate = tempValue[1].trim();

                    tempfromDate = tempfromDate.replace('00:00', '');
                    temptoDate = temptoDate.replace('23:59', '');

                    tempfromDate = tempfromDate.trim();
                    temptoDate = temptoDate.trim();
                    // setFromDate(tempfromDate);
                    //setToDate(temptoDate);

                   
                }
            } else {
                field.list_values = [];
                field.list_values.push({ id: 'CURDATE()', value: 'CURDATE()' });
                field.list_values.push({ id: 'CURWEEK()', value: 'CURWEEK()' });
                field.list_values.push({ id: 'CURMONTH()', value: 'CURMONTH()' });
                field.list_values.push({ id: 'Pick a Date.', value: 'Pick a Date.' });
                if (value === 'CURDATE()' || value === 'CURWEEK()' || value === 'CURMONTH()') {
                    isPickDate = false;
                    //setisShowDate(false);
                } else {
                    isPickDate = true;
                   // setisShowDate(true);

                    value = null;
                    value = 'Pick a Date.';
                }
            }
        }
      let _filterOptions = createFilterOptions({
        ignoreCase: true,
      });
        let component = null;

        if (condition.conditionFieldProps !== null && condition.conditionFieldProps !== undefined && condition.conditionFieldProps.isShowConditionValue === false) {
            return null;
        }

        if (field.field_type === constants.FIELD_TYPE_TEXT
            || field.field_type === constants.FIELD_TYPE_INTEGER
            || field.field_type === constants.FIELD_TYPE_FLOAT
            || field.field_type === constants.FIELD_TYPE_URL
            || field.field_type === constants.FIELD_TYPE_MEMO
            || field.name === 'workflow_name'
        ) {
            component =
                <FormControl style={{ width: '100%', paddingLeft:'15px' }} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        label={field_name}
                        InputLabelProps={{
                            style: {
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                width: '96%',
                            }
                        }}
                        fullWidth={true}
                        name={field.name}
                        value={value}
                        onChange={(e)=>handleChangeConditionTextField(e)}
                        error={error.field_value !== ''}
                        helperText={error.field_value}
                        autoComplete="new-password"
                        margin='dense'
                        size='small'
                        className={"sf-testbox-height"}
                    />
                </FormControl>;
        } else if (field.field_type === constants.FIELD_TYPE_LIST
            || ((field.field_type === constants.FIELD_TYPE_DATE || field.field_type === constants.FIELD_TYPE_DATE_TIME)
                && condition.t_operator !== 'LIKE' && condition.t_operator !== 'NOT_LIKE')) {
            if (field.list_type === constants.LIST_FIELD_TYPE_REGULAR
                || field.list_type === constants.LIST_FIELD_TYPE_USER
                || field.list_type === constants.LIST_FIELD_TYPE_EXTERNAL
                || field.list_type === constants.LIST_FIELD_TYPE_MULTI_SELECT
                || field.field_type === constants.FIELD_TYPE_DATE
                || field.field_type === constants.FIELD_TYPE_DATE_TIME
            ) {
                component = [];
                if (field.list_type === constants.LIST_FIELD_TYPE_MULTI_SELECT) {
                    if (condition.selectedListValuesArray !== '' && !Array.isArray(condition.selectedListValuesArray)) {
                        condition.selectedListValuesArray = condition?.selectedListValuesArray?.split(',');
                    }
                    component.push(
                        <div style={{paddingLeft:'15px',  width: '100%'}}>
                            <FormControl sx={{ m: 0.8, minWidth: '100%' }} variant="outlined" >
                                <InputLabel id="demo-simple-select-helper-label"  >{field.label}</InputLabel>
                                <SelectField
                                    id="demo-simple-select-helper"
                                    labelId="demo-simple-select-helper-label"
                                    key={field.id}
                                    multiple={true}
                                    label={field.label}
                                    value={condition.selectedListValuesArray}
                                    onChange={(e,value)=>setMultiSelectValue(e,value)}
                                    autowidth={"true"}
                                    style={{ height: '40px' }}
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    {
                                        field.list_values.map((listValue, listValueIndex) => {
                                            return <MenuItem
                                                value={listValue.value}
                                                id={listValue.id}
                                                title={listValue.value}
                                                key={listValue.id}
                                                insetChildren={true}
                                                style={styles.popoverMenuItem}>
                                                <Checkbox checked={stateCondition.selectedListValues && stateCondition.selectedListValues.indexOf(listValue.value) > -1 ? true : false} />
                                                <ListItemText primary={listValue.value} /></MenuItem>
                                        })
                                    }
                                </SelectField>
                            </FormControl>
                        </div>
                    );
                } else if (condition.conditionFieldProps.isShowConditionValue) {
                 let tempMenuItems = generateMenuItemList(field.list_values);
                    let containerStyle = { width: '85%', float: 'left' };
                    if (isPickDate) {
                        containerStyle.width = '42.5%';
                    }
                    let autoCompleteStyle = { ...styles.sfFormFieldContainer };
                    delete autoCompleteStyle.paddingLeft;

                    let uniqueId = field.id + '-' + field.name + '-' + index;
if(field.field_type === constants.FIELD_TYPE_DATE || field.field_type === constants.FIELD_TYPE_DATE_TIME){
    component.push(
        <div style={{containerStyle, paddingLeft: '15px', width:'100%'}} id={uniqueId} key={uniqueId}>

         <SFAutoComplete
                key={'sf-auto-complete-' + uniqueId}
                id={'sf-auto-complete-' + uniqueId}
                name={'sf-auto-complete-' + uniqueId + field.name}
                label={field.label}
                menuItems= {tempMenuItems}
                onChange={(event, value)=>setSelectFieldValue( field.name, field.field_type,event, value)}
                value={value}
                inputprops={{ maxLength: '20' }}
                underlineFocusStyle={styles.textField.underlineFocus}
                floatingLabelStyle={{ ...styles.textField.floatingLabel, top: '10px', left: '7px' }}
                inputStyle={{ ...styles.textField.input, paddingLeft: 7 }}
                menuItemStyle={styles.popoverMenuItem}
                style={autoCompleteStyle}
            /> 
        </div>);

}else {
    component.push(
        <div style={{containerStyle, paddingLeft: '15px', width:'100%'}} id={uniqueId} key={uniqueId}>
         
            <Autocomplete
                filterOptions={_filterOptions}
                value={value}
                onChange={(event, value) => setSelectFieldValue(field.name, field.field_type, event, value)}

                // filterOptions={(options, params) => {
                //     const { inputValue } = params;
                //     let filtered = options;
                //     if (inputValue !== '') {
                //         filtered = options.filter(function (el) {
                //             return el.value.includes(inputValue);
                //         });
                //     }else{
                //         filtered = filterOptions
                //     }

                //     return filtered;
                // }}
                onBlur={(event, newValue) =>
                    setFieldValueOnBlur("t_name", event, newValue)
                }
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={field.list_values}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.value;
                }}

                style={{ width: "100%" }}
                className="sendMail"
                //  disabled={isTemplateListDisabled}
                disableClearable={false}
                renderOption={(props, option) => <li {...props}>{option.value}</li>}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={field.label}
                        inputprops={{
                            maxlength: field.field_length,
                            ...params.InputProps,
                        }}
                       // disabled={field.is_readonly}
                        fullWidth={true}
                        margin="dense"
                        size="small"
                        className={"sf-fields-bg"}
                        InputLabelProps={{
                            style: {
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "90%",
                            },
                        }}
                    />
                )} />

        </div>);

}

                    if (isPickDate) {
                        component.push(
                            <PopOver
                                id={field.name}
                                key={field.name}
                                name={field.name}
                                btnType={field.field_type === constants.FIELD_TYPE_DATE_TIME ? 'dateTime' : 'date'}
                                buttonStyle={{ fontSize: '16px' }}
                                placeholder={field.label}
                                containerStyle={{ lineHeight: 1, marginBottom: '20px', width: '50%', padding: '6px' }}
                                onChange={(date, fieldName) => handleDateChange(date, fieldName)}
                                value={condition.t_value!== ''&& condition.t_value.includes(" 23:59") && condition.t_value.includes("AND")==false ?
                                moment(getStringParam(condition.t_value)).format(dateFormat[app.me.date_format]) + " 23:59":
                                condition.t_value == '' ? condition.t_value :
                                condition.t_value.includes(" 23:59")==true && condition.t_value.includes("AND")==true
                                ?condition.t_value:
                                condition.t_value == 'Pick a Date.'?
                                condition.t_value
                                :
                                moment(getStringParam(condition.t_value)).format(dateFormat[app.me.date_format])}
                                innerLabelStyle={{ padding: "8 5 2 5", fontSize: '15px' }}
                                height={40}
                                options={datePickerOptions}
                            />);
                    }
                }
            }
        } else if ((field.field_type === constants.FIELD_TYPE_DATE || field.field_type === constants.FIELD_TYPE_DATE_TIME)
            && (condition.t_operator === 'LIKE' || condition.t_operator === 'NOT_LIKE')) {
            component = [];
            component.push(
                <PopOver
                    id={'fromDate'}
                    key={'fromDate'}
                    name={'fromDate'}
                    btnType={field.field_type === constants.FIELD_TYPE_DATE_TIME ? 'dateTime' : 'date'}
                    buttonStyle={{ fontSize: '16px' }}
                    containerStyle={{ lineHeight: 1, marginBottom: '20px', width: '50%', padding: '6px' }}
                    placeholder={getLocalizedStrings().label.FILTER.FROM_DATE}
                    onChange={(date, fieldName) => handleDateChange(date, fieldName)}
                    value={tempfromDate != null ? moment(getStringParam(tempfromDate)).format(dateFormat[app.me.date_format]): fromDate }
                    innerLabelStyle={{ padding: "8 5 2 5", fontSize: '15px' }}
                    height={40}
                    options={datePickerOptions}
                />
            );
            component.push(
                <PopOver
                    id={'toDate'}
                    key={'toDate'}
                    name={'toDate'}
                    btnType={field.field_type === constants.FIELD_TYPE_DATE_TIME ? 'dateTime' : 'date'}
                    buttonStyle={{ fontSize: '16px' }}
                    containerStyle={{ lineHeight: 1, marginBottom: '20px', width: '50%', padding: '6px' }}
                    placeholder={getLocalizedStrings().label.FILTER.TO_DATE}
                    onChange={(date, fieldName) => handleDateChange(date, fieldName)}
                    value={temptoDate != null ? moment(getStringParam(temptoDate)).format(dateFormat[app.me.date_format]): toDate }
                    innerLabelStyle={{ padding: "8 5 2 5", fontSize: '15px' }}
                    height={40}
                    options={datePickerOptions}
                />);
        }
        return component;
    }

    const setFieldValueOnBlur = (fieldName, event, value) => {
		let val = event.target.value;
        let temp = stateCondition;
        temp.t_value = val;
        temp.selectedListValues =val;
        setStateCondition({...temp});
        updateCondition(stateCondition, index);

	}


    const switchLogicalOperator = (val) => {
        let temp = stateCondition;
        temp = {...temp,t_logicalop:val};
        setStateCondition({...temp});
        updateCondition(temp, index);
    }

   
    const setMultiSelectValue = (event,value) => {
        let tempCondition = stateCondition;
        tempCondition.t_value =  getArrayParam(event.target.value).join();
        tempCondition.selectedListIds = getStringParam(stateCondition.selectedListIds);
        if (stateCondition.selectedListIds.indexOf(value.props.id) > -1) {
            tempCondition.selectedListIds = replace(stateCondition.selectedListIds, value.props.id + ',', '');
        } else {
            tempCondition.selectedListIds = stateCondition.selectedListValues + value.props.id + ',';
        }
        tempCondition.selectedListValues = stateCondition.selectedListIds;
        tempCondition.selectedListValuesArray = event.target.value;
        setStateCondition({...tempCondition});
        updateCondition(stateCondition, index);
    }
    

    let typeWiseField = generateTypeWiseTextFields(filedProps);
    let tempisShowDate = getBooleanParam(isShowDate);

    let operator_options =  generateFieldOperatorOptions(filedProps);
    let activeStyle = { backgroundColor: '#e0e0e0',padding:'0px 15px',display: 'inline-block',position: 'relative',overflow: 'hidden',whiteSpace:'nowrap'} 
    let inactiveStyle={ backgroundColor: 'white',padding:'0px 15px',display: 'inline-block',position: 'relative',overflow: 'hidden',whiteSpace:'nowrap'} ;
    return (
        <div className="row clearfix">
            {index > 0 ?
                <div style={{ marginRight: '0px', height: '48px',
                flex:"0 0 auto",position:"relative",minHeight:"1px",paddingLeft:"15px",width:"50%"}
                 }>
                    <div className="row">
                        <div className="col-sm-4 sm-col-4">
                            <div style={{ borderRadius: '3px',cursor: 'pointer',textAlign: 'center',overflow: 'hidden',position: 'relative',width: '112px',whiteSpace:'nowrap',marginLeft:'-6px'
                        }}>
                                <div style={condition.t_logicalop.trim() === 'AND' ? activeStyle : inactiveStyle } onClick={() => { switchLogicalOperator('AND') }}>
                                    <span>{getLocalizedStrings().label.FILTER.AND}</span>
                                </div>
                                <div style={condition.t_logicalop.trim() === 'OR' ? activeStyle : inactiveStyle} onClick={() => { switchLogicalOperator('OR') }}>
                                    <span>{getLocalizedStrings().label.FILTER.OR}</span>
                                </div>
                            </div>
                        </div>
                        <div style={{ flex:"0 0 auto",position:"relative",minHeight:"1px",paddingLeft:"15px",width:"66.66666667%"}}>
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <Select
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    name="operator"
                                    value={stateCondition.t_operatorObj}
                                    options={operator_options}
                                    onChange={(value)=>handleConditionOperatorChange(value)}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </FormControl>
                        </div>
                    </div>
                </div>
                :
                <div className={"col-sm-6 p-0"} style={{ marginRight: '0px', height: '48px', marginTop: '1%' }}>
                    <div className="row">
                        <div className="col-sm-12 main_form_div p-0">
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <Select
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    name="operator"
                                    value={stateCondition.t_operatorObj}
                                    options={operator_options}
                                    onChange={(value)=>handleConditionOperatorChange(value)}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </FormControl>
                        </div>
                    </div>
                </div>
            }
            <div className={"col-sm-5 p-0"} style={{ padding: '0px', margin: '0px', display: 'flex', marginTop: '0px', width:  '41%' }}>
                {typeWiseField}
            </div>
            <div className={"col-sm-1 p-0"} onClick={()=>deleteConditionRow()} style={{ cursor: 'pointer', marginTop: '10px',  float: 'left', paddingBottom: '5px' }} title={getLocalizedStrings().label.FILTER.REMOVE_CONDITION}>
                <i
                    className="fa fa-minus"
                    aria-hidden="true"
                    style={{
                        cursor: 'pointer',
                        marginLeft: '20%',
                        marginTop: '11%',
                        border: '1px solid #bbb',
                        borderRadius: '50%',
                        width: '22px',
                        height: '22px',
                        display: 'inline-block',
                        padding: '4px',
                        lineHeight: 1
                }}></i>
            </div>
        </div>
    )

}

export default Filter;

