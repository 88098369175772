import React, { useState, useRef } from 'react';
import { Card, CardActions, CardHeader, CardMedia, CardActionArea } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import * as HTTPClient from '../../../services/helper/httpClient';
import { constants } from '../../../services/constants/constants';
import { getActiveTab, updateActiveTab } from '../../../services/helper/sfTabManager';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useDispatch, useSelector } from "react-redux";
import { appRedirect } from '../../../services/actions/appActions';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { endPoints } from '../../../services/constants/endPoints';
import * as sfDialogs from '../components/sfDialogs';

const style = {
    cardWrapper: {
        display: 'inline-block',
        position: 'relative',
        cursor: ' pointer',
        width: '100%',

    },
    layer: {
        position: 'absolute',
        backgroundColor: 'rgba(56,56,56,0.54)',
        color: '#fff',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        display: 'none',
        width: '240px',
        height: '320px',
        margin: '20px',
    }
}

const AutomationTemplateCard = ({ objTemplate }) => {
    const layerStyle = useRef();
    const dispatch = useDispatch()
    const automationCampaignTemplate = useSelector((state) => state.automationCampaignTemplate);
    const [shadow, setShadow] = useState(1);
    let imageUrl = objTemplate.imgUrl;
    
    const onMouseOver = () => {
        if (layerStyle.current) {
            layerStyle.current['style'].display = "block";
            setShadow(3);
        }
    }

    const onMouseOut = () => {
        if (layerStyle.current) {
            layerStyle.current['style'].display = "none";
            setShadow(1);
        }
    }

    const selectTemplate = () => {
        try {
            let campaignData = objTemplate;
            if (campaignData.templateName === constants.SMS_DRIP_CAMPAIGN) {
                const promise = Promise.resolve(HTTPClient.get(endPoints.AUTOMATION_DESIGNER.CHECK_CAMPAIGN_NUMBER_PRESENT));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        let isNumberPresent = response.is_campaign_number;
                        if (isNumberPresent) {
                            openTemplate();
                        } else {
                            let message = "Please purchase a campaign number to design an SMS Drip Campaign."
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, message, null);
                        }
                    });
                }
            } else {
                openTemplate();
            }
        } catch (error) {
            console.error("Error in 'AutomationTemplateCard.js -> selectTemplate()': " + error);
        }
    }

    const openTemplate = () =>{
         try{
                automationCampaignTemplate.data.templates = [];
                let campaignData = objTemplate;
                if (campaignData.hasOwnProperty("id")) {
                    campaignData.id = 0;
                }
                if (campaignData.hasOwnProperty('imgUrl')) {
                    delete campaignData['imgUrl'];
                }
                if (campaignData.hasOwnProperty('isTemplate')) {
                    campaignData.isTemplate = 2;
                }
                if (campaignData.hasOwnProperty('name')) {
                    campaignData.template_name = campaignData.name
                    campaignData.name = "";
                }

                if (campaignData.hasOwnProperty('status')) {
                    campaignData.status = constants.AUTOMATION_DESIGNER_STATUS_ACTIVE;
                }
                if (campaignData.hasOwnProperty('nodes')) {
                    let nodesArray = campaignData.nodes;
                    nodesArray.forEach((nodeObject, i) => {
                        if (nodeObject.hasOwnProperty('id')) {
                            nodeObject.id = "";
                        }
                        if (nodeObject.hasOwnProperty('status')) {
                            nodeObject.status = "";
                        }
                        if (nodeObject.hasOwnProperty('processId')) {
                            nodeObject.processId = "";
                        }
                        if (nodeObject.hasOwnProperty('nodeStatusFlg')) {
                            nodeObject.nodeStatusFlg = 1;
                        }
                        if (nodeObject.hasOwnProperty('detailStatusFlg')) {
                            nodeObject.detailStatusFlg = 1;
                        }
                    });
                    campaignData.nodes = nodesArray;
                }
                automationCampaignTemplate.data.templates.push(campaignData);
                let tabObj = getActiveTab();
                let linkToUrl = "/" + constants.AUTOMATION_DESIGNER_OBJECT + "/" + "edit/0";
                if (tabObj.url !== linkToUrl) {
                    tabObj.url = linkToUrl;
                    updateActiveTab(tabObj);
                    dispatch(appRedirect(true,linkToUrl))
                }
         }catch (error) {
            console.error("Error in 'AutomationTemplateCard.js -> OpenTemplate()': " + error);
        }
    }

    return (
        <Box boxShadow={3} m={1}>
            <Card>
                <CardActionArea
                    onMouseOver={() => onMouseOver()}
                    onMouseOut={() => onMouseOut()}
                    zdepth={shadow}
                    className="templateCard"
                    style={{ width: '280px' }}>

                    <CardHeader
                        title={<div
                            title={
                                getLocalizedStrings().label.AUTOMATION_DESIGNER[objTemplate.name]
                            }
                            style={{
                                textOverflow: 'ellipsis',
                                width: '300px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                paddingLeft: '0px',
                                paddingRight: '22px',
                                color: '#717171',
                                fontSize: '14px',
                                textAlign: 'center',
                                marginBottom: '-20px'
                            }}>
                            {getLocalizedStrings().label.AUTOMATION_DESIGNER[objTemplate.name]}
                        </div>}
                        titlestyle={{
                            textOverflow: 'ellipsis',
                            width: '250px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                            fontWeight: 'normal',
                            color: '#717171',

                        }}
                        style={{ padding: '0px', paddingTop: '10px', paddingBottom: '10px' }}
                    />
                    <div style={{ ...style.cardWrapper }}>
                        <CardMedia

                            component="img"
                            image={imageUrl}
                            style={{ width: "240px", height: "320px", margin: '20px' }}
                        >
                        </CardMedia>
                        <CardActions>
                            <div style={{ ...style.layer }} ref={layerStyle}>
                                <div style={{ paddingTop: '110px', textAlign: 'center' }}>
                                    <Link style={{ textDecoration: 'none' }}>
                                        <Button variant="contained"
                                            style={{ backgroundColor: "#484848", color: '#fff' }}
                                            onClick={() => selectTemplate()}
                                        >{getLocalizedStrings().label.AUTOMATION_DESIGNER.USE_THIS_TEMPLATE}</Button>
                                    </Link>
                                </div>
                            </div>
                        </CardActions>
                    </div>

                </CardActionArea>
            </Card>
        </Box>
    );
};

export default AutomationTemplateCard;