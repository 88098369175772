import React, { useState, useEffect } from 'react';
import TotalRecordCount from '../components/TotalRecordCount';
import { GroupDataTable } from '../components/GroupDataTable';
import { GroupPagination } from '../components/GroupPagination';
import { getGroupCampaignDetails } from '../../../services/actions/detailViewActions';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import PropTypes from 'prop-types';

const BroadcastCampaignsForGroup = ({ recordId, defaultPageSize }) => {

    const [broadcastCampaignDetails, setBroadcastCampaignDetails] = useState(null);
    const [mounted, setMounted] = useState(false);

    const[changeLog,setchangeLog]=useState({broadcastCampaignDetails:null})

    useEffect(() => {
        var objParam = {};
        objParam.recordId = recordId.id;
        objParam.type = "broadcastCampaigns";
        objParam.fetchFrom = 0;
        objParam.fetchCount = defaultPageSize;
        objParam.isRecordCount = true;
        getCampaignDetails(objParam);
    }, [recordId.id]);


   

    const getCampaignDetails = (objParam) => {
        setMounted(false);
        let promise = Promise.resolve(getGroupCampaignDetails(objParam));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    setMounted(true);
                    setBroadcastCampaignDetails(response);
                    let log = changeLog;
                    log.broadcastCampaignDetails = response;
                    setchangeLog({...log});
                }
            });
        }
    }


    if (mounted) {
        let objDetails = changeLog.broadcastCampaignDetails;
        let pgDetails = {};
        pgDetails.campType = 'broadcastCampaigns';
        pgDetails.totalRecordCount = objDetails.broadcastCampaignsCount;
        pgDetails.startNo = 1;
        pgDetails.defaultPageSize = defaultPageSize;
        pgDetails.recordId = recordId;

        return (
            <div>
                <div className="group_campaign_title">
                    <TotalRecordCount totalCount={objDetails.broadcastCampaignsCount} />
                    <span style={{ paddingTop: "2px" }}>{getLocalizedStrings().label.MODULE_ITEMS[objDetails.broadcastCampaignsLabel]}</span>
                </div>
                <div>
                    {objDetails.broadcastCampaigns !== undefined &&  objDetails.broadcastCampaigns !== null && objDetails.broadcastCampaigns.length > 0 &&
                        <GroupDataTable data={objDetails.broadcastCampaigns} />
                    }
                </div>
                {!(objDetails.broadcastCampaigns !== undefined &&  objDetails.broadcastCampaigns !== null && objDetails.broadcastCampaigns.length > 0) &&
                    <div className="group_campaign_norecord">{getLocalizedStrings().message.GROUP.NO_BROADCAST_CAMPAIGN}</div>
                }
                <div>
                    {objDetails.broadcastCampaigns !== undefined &&  objDetails.broadcastCampaigns !== null && objDetails.broadcastCampaigns.length > 0 &&
                        <GroupPagination pgDetails={pgDetails} getGroupCampaignDetails={() => getCampaignDetails()} />
                    }
                </div>
            </div>
        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>);
    }
};

export default BroadcastCampaignsForGroup;

BroadcastCampaignsForGroup.propTypes = {
    recordId: PropTypes.number,
    defaultPageSize: PropTypes.number
};
