import store from '../../../src/services/store';
import { constants } from '..//constants/constants';
import { endPoints } from '..//constants/endPoints';
import { actions } from '..//constants/actions';
import * as HTTPClient from '..//helper/httpClient';
import filter from 'lodash/filter';
import { isValidParam, getObjectParam, getBooleanParam, getStringParam, getIntParam, getArrayParam } from '..//helper/parameterVerifier';
import { getActiveTab, getActiveTabInfo, TYPE_LIST_VIEW, TYPE_DETAIL_VIEW, updateTabInfo, addTab } from '..//helper/sfTabManager';
import { getObjectNameByLabel } from '..//helper/common';
import { getQueryDetails } from '..//actions/appActions';

function getListViewData(object, params, isLookup) {
    let url = null;
    let isGroupByInfo = false;
    try {
        if (object === constants.MARKETING_TEMPLATE_OBJECT) {
            url = endPoints.MARKETING_TEMPLATE.LIST_VIEW;
        } else if (object === constants.SALES_TEMPLATE_OBJECT) {
            url = endPoints.SALES_TEMPLATE.LIST_VIEW;
        }else if (object === constants.AGREEMENT_TEMPLATE_OBJECT) {
            url = endPoints.AGREEMENT_TEMPLATE.LIST_VIEW;
        } else if (object === constants.ACCOUNTS_OBJECT || object === constants.UNITS_OBJECT) {
            url = endPoints.ACCOUNTS.LIST_VIEW;
            if(object === constants.UNITS_OBJECT){
                params.call_object = object;
            }
        } else if (object === constants.CONTACTS_OBJECT) {
            url = endPoints.CONTACTS.LIST_VIEW;
        } else if (object === constants.OPPORTUNITIES_OBJECT) {
            url = endPoints.OPPORTUNITIES.LIST_VIEW;
        } else if (object === constants.PRODUCTS_OBJECT) {
            url = endPoints.PRODUCTS.LIST_VIEW;
        } else if (object === constants.CASES_OBJECT) {
            url = endPoints.CASES.LIST_VIEW;
        } else if (object === constants.SOLUTIONS_OBJECT) {
            url = endPoints.SOLUTIONS.LIST_VIEW;
        } else if (object === constants.ISSUES_OBJECT) {
            url = endPoints.ISSUES.LIST_VIEW;
        } else if (object === constants.PROJECTS_OBJECT) {
            url = endPoints.PROJECTS.LIST_VIEW;
        } else if (object === constants.AUTOMATION_DESIGNER_OBJECT) {
            url = endPoints.AUTOMATION_DESIGNER.LIST_VIEW;
            params = getObjectParam(params);
            params.query_type = 'query';
        } else if (object === constants.TOUCH_CAMPAIGNS_OBJECT) {
            url = endPoints.TOUCH_CAMPAIGNS.LIST_VIEW;
            params = getObjectParam(params);
            params.query_type = 'query';
        } else if (object === constants.AB_CAMPAIGNS_OBJECT) {
            url = endPoints.AB_CAMPAIGNS.LIST_VIEW;
            params = getObjectParam(params);
            params.query_type = 'all';
        } else if (object === constants.SOCIAL_CAMPAIGNS_OBJECT) {
            url = endPoints.SOCIAL_CAMPAIGNS.LIST_VIEW;
        } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
            url = endPoints.CUSTOM_TABLE1.LIST_VIEW;
        } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
            url = endPoints.CUSTOM_TABLE2.LIST_VIEW;
        } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
            url = endPoints.CUSTOM_TABLE3.LIST_VIEW;
        } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
            url = endPoints.CUSTOM_TABLE4.LIST_VIEW;
        } else if (object === constants.APPOINTMENTS) {
            url = endPoints.APPOINTMENT.LIST_VIEW;
        } else if (object === constants.TASKS) {
            url = endPoints.TASK.LIST_VIEW;
        } else if (object === constants.WEB_FORMS) {
            url = endPoints.WEB_FORMS.LIST_VIEW;
        } else if (object === constants.NOTES) {
            if(params.isReport && params.type === 'DASHBOARD'){
                url = endPoints.NOTES.LIST_GET;
                params = getObjectParam(params);
                params.fields = ['author', 'note_date', 'note_text'];
            }else{
                url = endPoints.NOTES.LIST_VIEW;
            }
        } else if (object === constants.ATTACHMENT_OBJECT) {
            url = endPoints.ATTACHMENT.LIST_VIEW;
        } else if (object === constants.GROUP_OBJECT) {
            url = endPoints.GROUP.LIST_VIEW;
        } else if (object === constants.PRODUCTS_OBJECT) {
            url = endPoints.PRODUCTS.LIST_VIEW;
        } else if (object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
            url = endPoints.BROADCAST_CAMPAIGNS.LIST_VIEW;
            params = getObjectParam(params);
            params.query_type = 'query';
        } else if (object === constants.INVOICES_OBJECT) {
            url = endPoints.INVOICES.LIST_VIEW;
        } else if (object === constants.EMAIL_OBJECT) {
            url = endPoints.EMAIL.LIST_VIEW;
        } else if(object === constants.EVENTS_OBJECT){
            url = endPoints.EVENTBRITE_INTEGRATION.LIST_VIEW;
        } else if (object === constants.COUPONS) {
            url = endPoints.COUPONS.LIST_VIEW;
        } else if (object === constants.CLAIMS) {
            url = endPoints.CLAIMS.LIST_VIEW;
        }else if(object === constants.SOURCE_OBJECT){
            url = endPoints.SOURCE.LIST_VIEW;
        }else if(object === constants.LIBRARY_OBJECT){
            url = endPoints.ATTACHMENT.LIBRARY_LIST_VIEW;
        }
        else if(object === constants.LOCATION_OBJECT){
            url = endPoints.LOCATION.LIST_VIEW;
        }
        else if(object === constants.TASKSLIST_OBJECT){
             url = endPoints.TASKS_INFO.LIST_VIEW;
         }
        else if(object === constants.NOTE_TYPES_OBJECT){
            url = endPoints.NOTE_TYPE.LIST_VIEW;
        }else if(object === constants.APPOINTMENT_TYPES_OBJECT){
            url =  endPoints.NOTE_TYPE.LIST_VIEW;
        }else if(object === constants.CAMPAIGN_TYPES_OBJECT){
            url = endPoints.CAMPAIGN_TYPES.LIST_VIEW;
        }else if(object === constants.USERS_OBJECT){
            url = endPoints.USERS.LIST_VIEW;
        }else if(object === constants.SECURITY_LEVEL_OBJECT){
            url = endPoints.SECURITY_LEVEL.LIST_VIEW;
        }else if(object === constants.SALES_LEADS_OBJECT){
            url = endPoints.SALES_LEADS.LIST_VIEW;
        }else if (object === constants.RELATIONS_OBJECT) {
            url = endPoints.RELATIONS.LIST_VIEW;
        }else if (object === constants.WORKFLOW_OBJECT) {
            url = endPoints.WORKFLOWS.LIST_VIEW;
        }else if (object === constants.ROLL_UP_OBJECT) {
            url = endPoints.USERS.GET_ROLLUP_LIST_VIEW;
        }else if (object === constants.RECYCLE_BIN_OBJECT) {
            url = endPoints.ACCOUNTS.RECYCLE_BIN_LIST_VIEW;
        }else if (object === constants.EMAIL_INTEGRATION_OBJECT) {
            url = endPoints.EMAIL_INTEGRATION.LIST_VIEW;
        }else if (object === constants.ASSIGNMENT_RULES_OBJECT) {
            url = endPoints.ASSIGNMENT_RULE.LIST_VIEW;
        }else if (object === constants.NEWS_OBJECT) {
            url = endPoints.NEWS.LIST_VIEW;
        } else if (object === constants.SMS_TEMPLATE_OBJECT) {
            url = endPoints.SMS_TEMPLATE.LIST_VIEW;
        } else if (object === constants.PETS_OBJECT) {
            url = endPoints.PETS.LIST_VIEW;
        } else if (object === constants.ORDERS_OBJECTS) {
            url = endPoints.ORDERS.LIST_VIEW;
        } else if (object === constants.ORDER_ITEMS_OBJECTS) {
            url = endPoints.ORDER_ITEMS.LIST_VIEW;
        }else if(object === constants.LANDING_PAGES_FROALA_OBJECT){
            url = endPoints.LANDING_PAGE.LIST_VIEW;
        }else if (object === constants.FIND_DUPLICATE_OBJECT) {
            url = endPoints.FIND_DUPLICATE.LIST_VIEW;
        } else if (object === constants.DOWNLOAD_LEADS_OBJECT) {
            url = endPoints.ACCOUNTS.DOWNLOAD_LEADS_LIST_VIEW;
        } else if (object === constants.CALL_SCRIPT_OBJECT) {
            url = endPoints.CALL_SCRIPT.GET_CALL_SCRIPT_LIST;
        } else if (object === constants.QUOTATION_OBJECT) {
            url = endPoints.QUOTATIONS.LIST_VIEW;
        } else if (object === constants.PAYMENTS_OBJECT) {
            url = endPoints.PAYMENTS.LIST_VIEW;
        }


        let info = getActiveTabInfo();
        if (isValidParam(info) && info.isInXpress) {
            params.is_inxpress = info.isInXpress;
            params.inxpress_project_id = info.inXpressProjectId;
        }
        if (isValidParam(info) && info.hasOwnProperty("isUnitsAccount") && info.hasOwnProperty("UnitsAccountProjectId") && info.isUnitsAccount) {
            params.isUnitsAccount = info.isUnitsAccount;
            params.UnitsAccountProjectId = info.UnitsAccountProjectId;
        }
        if(info.hasOwnProperty('query_link_id')){
            params.query_link_id = getIntParam(info.query_link_id);
        }

        let isDetailView = getBooleanParam(params.isDetailView);
        let isReport = getBooleanParam(params.isReport);
        if (isReport || getBooleanParam(info.isReport)) {
            params.is_report = true;

            let state = store.getState();
            let listView = getObjectParam(state.otherListView.data);
            if(listView.hasOwnProperty('statKey') && getStringParam(listView.statKey) !== '') {
                params.statKey = listView.statKey;
            }
        }

        if(object === constants.CASES_OBJECT || object === constants.NOTES || object === constants.ATTACHMENT_OBJECT) {
            let state = store.getState();
            let parentObject = getStringParam(params.parent_object);
            if(state.app.selectedModuleName === constants.MODULE_HUB 
                && (object === constants.CASES_OBJECT || parentObject === constants.CASES_OBJECT)) {
                params.call_from = constants.MODULE_HUB;
            }
        }
        let isDuplicate = false;
        if(object === constants.FIND_DUPLICATE_OBJECT ){
            isDuplicate = true;
        }

        if (isDetailView) {
            delete params.isDetailView;
            return {
                type: actions.CHILD_LISTVIEW_GET,
                payload: HTTPClient.get(url, params)
            }
        } else if (isReport) {
            delete params.isReport;
            return {
                type: actions.OTHER_LISTVIEW_GET,
                payload: HTTPClient.get(url, params)
            }
        } else if(getBooleanParam(info.isAssignRule)){
            if (isValidParam(params)) {
                params = getObjectParam(params);
                isGroupByInfo = getBooleanParam(params.is_group_by_info);
            }
            if (isGroupByInfo) {
                return {
                    type: actions.ASSIGN_LISTVIEW_GROUPBY_INFO_GET,
                    payload: HTTPClient.get(url, params)
                }
            } else{
                return {
                    type: actions.ASSIGN_LISTVIEW_GET,
                    payload: HTTPClient.get(url, params)
                }
            }
        }else if(isDuplicate){
            if (getBooleanParam(params.is_group_by_info)) {
                return {
                    type: actions.DUPLICATE_LISTVIEW_GROUPBY_INFO_GET,
                    payload: HTTPClient.get(url, params)
                }
            } else{
                return {
                    type: actions.DUPLICATE_LISTVIEW_GET,
                    payload: HTTPClient.get(url, params)
                }
            }

        }  else if(object === constants.DOWNLOAD_LEADS_OBJECT){
            let searchFields = false;
            if (isValidParam(params)) {
                params = getObjectParam(params);
                searchFields = getBooleanParam(params.is_search_field_info);
            }
                 if(searchFields){
                return {
                    type: actions.DOWNLOAD_LEADS_SEARCH_INFO,
                    payload: HTTPClient.get(url, params)
                }
                 }else {
                return {
                    type: actions.LISTVIEW_GET,
                    payload: HTTPClient.get(url, params)
                }
            }
        }
        else {
            if (isValidParam(params)) {
                params = getObjectParam(params);
                isGroupByInfo = getBooleanParam(params.is_group_by_info);
            }
            if (isLookup) {
                return HTTPClient.get(url, params);
            } else if (isGroupByInfo) {
                //return HTTPClient.get(url, params);
                if(params.hasOwnProperty('call_object') && params.call_object === constants.UNITS_OBJECT) {
                    url = '/v3/accounts/get/unit/groupbyinfo';
                    return {
                        type: actions.TREEVIEW_INFO_GET,
                        payload: HTTPClient.get(url, params)
                    }
                }
                return {
                    type: actions.LISTVIEW_GROUPBY_INFO_GET,
                    payload: HTTPClient.get(url, params)
                }
            } else {
                return {
                    type: actions.LISTVIEW_GET,
                    payload: HTTPClient.get(url, params)
                }
            }
        }
    } catch (e) { console.error("Error in 'listViewActions.js -> getListViewData()':" + e); }
}

function saveListViewLayoutRowWidth(object, params) {

    let url = null;
    try {
        if (object === constants.MARKETING_TEMPLATE_OBJECT) {
            url = endPoints.MARKETING_TEMPLATE.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.SALES_TEMPLATE_OBJECT) {
            url = endPoints.SALES_TEMPLATE.LIST_VIEW_LAYOUT_CHANGE;
        }else if (object === constants.AGREEMENT_TEMPLATE_OBJECT) {
            url = endPoints.AGREEMENT_TEMPLATE.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.ACCOUNTS_OBJECT) {
            url = endPoints.ACCOUNTS.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.CONTACTS_OBJECT) {
            url = endPoints.CONTACTS.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.OPPORTUNITIES_OBJECT) {
            url = endPoints.OPPORTUNITIES.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.PRODUCTS_OBJECT) {
            url = endPoints.PRODUCTS.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.NOTES) {
            url = endPoints.NOTES.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.CASES_OBJECT) {
            url = endPoints.CASES.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.SOLUTIONS_OBJECT) {
            url = endPoints.SOLUTIONS.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.ISSUES_OBJECT) {
            url = endPoints.ISSUES.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.PROJECTS_OBJECT) {
            url = endPoints.PROJECTS.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.AUTOMATION_DESIGNER_OBJECT) {
            url = endPoints.AUTOMATION_DESIGNER.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
            url = endPoints.BROADCAST_CAMPAIGNS.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.TOUCH_CAMPAIGNS_OBJECT) {
            url = endPoints.TOUCH_CAMPAIGNS.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.AB_CAMPAIGNS_OBJECT) {
            url = endPoints.AB_CAMPAIGNS.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.SOCIAL_CAMPAIGNS_OBJECT) {
            url = endPoints.SOCIAL_CAMPAIGNS.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
            url = endPoints.CUSTOM_TABLE1.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
            url = endPoints.CUSTOM_TABLE2.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
            url = endPoints.CUSTOM_TABLE3.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
            url = endPoints.CUSTOM_TABLE4.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.APPOINTMENTS) {
            url = endPoints.APPOINTMENT.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.TASKS) {
            url = endPoints.TASK.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.WEB_FORMS) {
            url = endPoints.WEB_FORMS.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.INVOICES_OBJECT) {
            url = endPoints.INVOICES.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.COUPONS_OBJECT) {
            url = endPoints.COUPONS.LIST_VIEW_LAYOUT_CHANGE;
        }else if (object === constants.CLAIMS_OBJECT) {
            url = endPoints.CLAIMS.LIST_VIEW_LAYOUT_CHANGE;
        }else if (object === constants.SOURCE_OBJECT){
            url = endPoints.SOURCE.LIST_VIEW_LAYOUT_CHANGE;
        }else if (object === constants.NOTE_TYPES_OBJECT){
            url = endPoints.NOTE_TYPE.LIST_VIEW_LAYOUT_CHANGE;
        }
        else if (object === constants.LOCATION_OBJECT){
            url = endPoints.LOCATION.LIST_VIEW_LAYOUT_CHANGE;
        }
        else if (object === constants.TASKSLIST_OBJECT){
              url = endPoints.TASKS_INFO.LIST_VIEW_LAYOUT_CHANGE;
          }
        else if (object === constants.APPOINTMENT_TYPES_OBJECT){
            url =endPoints.NOTE_TYPE.LIST_VIEW_LAYOUT_CHANGE;
        }else if (object === constants.ROLL_UP_OBJECT){
            url =endPoints.USERS.LIST_VIEW_LAYOUT_CHANGE;
        }else if(object === constants.CAMPAIGN_TYPES_OBJECT){
            url = endPoints.CAMPAIGN_TYPES.LIST_VIEW_LAYOUT_CHANGE;
        }else if(object === constants.SALES_LEADS_OBJECT){
            url = endPoints.SALES_LEADS.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.RELATIONS_OBJECT) {
            url = endPoints.RELATIONS.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.USERS_OBJECT) {
            url = endPoints.USERS.USER_LIST_VIEW_LAYOUT_CHANGE;
        }else if (object === constants.SECURITY_LEVEL_OBJECT) {
            url = endPoints.SECURITY_LEVEL.LIST_VIEW_LAYOUT_CHANGE;
        }else if (object === constants.EMAIL_INTEGRATION_OBJECT) {
            url = endPoints.EMAIL_INTEGRATION.LIST_VIEW_LAYOUT_CHANGE;
        }else if (object === constants.LIBRARY_OBJECT) {
            url = endPoints.ATTACHMENT.LIBRARY_LIST_VIEW_LAYOUT_CHANGE;
        }else if (object === constants.WORKFLOW_OBJECT) {
            url = endPoints.WORKFLOWS.LIST_VIEW_LAYOUT_CHANGE;
        }else if (object === constants.RECYCLE_BIN_OBJECT) {
            url = endPoints.ACCOUNTS.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.ASSIGNMENT_RULES_OBJECT) {
            url = endPoints.ASSIGNMENT_RULE.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.NEWS_OBJECT) {
            url = endPoints.NEWS.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.ATTACHMENT_OBJECT) {
            url = endPoints.ATTACHMENT.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.SMS_TEMPLATE_OBJECT) {
            url = endPoints.SMS_TEMPLATE.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.ORDERS_OBJECTS) {
            url = endPoints.ORDERS.LIST_VIEW_LAYOUT_CHANGE;
        } else if (object === constants.ORDER_ITEMS_OBJECTS) {
            url = endPoints.ORDER_ITEMS.LIST_VIEW_LAYOUT_CHANGE;
        } else if(object === constants.LANDING_PAGES_FROALA_OBJECT){
            url = endPoints.LANDING_PAGE.LIST_VIEW_LAYOUT_CHANGE;
        } else if(object === constants.QUOTATION_OBJECT){
            url = endPoints.QUOTATIONS.LIST_VIEW_LAYOUT_CHANGE;
        }
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'listViewActions.js -> saveListViewLayoutRowWidth()':" + e); }
}

function getListViewSetupData(object, params) {
    let url = null;
    try {
        if (object === constants.ACCOUNTS_OBJECT) {
            url = endPoints.ACCOUNTS.LIST_VIEW_SETUP_GET;
        } else if (object === constants.CONTACTS_OBJECT) {
            url = endPoints.CONTACTS.LIST_VIEW_SETUP_GET;
        } else if (object === constants.OPPORTUNITIES_OBJECT) {
            url = endPoints.OPPORTUNITIES.LIST_VIEW_SETUP_GET;
        } else if (object === constants.PRODUCTS_OBJECT) {
            url = endPoints.PRODUCTS.LIST_VIEW_SETUP_GET;
        } else if (object === constants.CASES_OBJECT) {
            url = endPoints.CASES.LIST_VIEW_SETUP_GET;
        } else if (object === constants.SOLUTIONS_OBJECT) {
            url = endPoints.SOLUTIONS.LIST_VIEW_SETUP_GET;
        } else if (object === constants.ISSUES_OBJECT) {
            url = endPoints.ISSUES.LIST_VIEW_SETUP_GET;
        } else if (object === constants.PROJECTS_OBJECT) {
            url = endPoints.PROJECTS.LIST_VIEW_SETUP_GET;
        } else if (object === constants.AUTOMATION_DESIGNER_OBJECT) {
            url = endPoints.AUTOMATION_DESIGNER.LIST_VIEW_SETUP_GET;
        } else if (object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
            url = endPoints.BROADCAST_CAMPAIGNS.LIST_VIEW_SETUP_GET;
        } else if (object === constants.TOUCH_CAMPAIGNS_OBJECT) {
            url = endPoints.TOUCH_CAMPAIGNS.LIST_VIEW_SETUP_GET;
        } else if (object === constants.AB_CAMPAIGNS_OBJECT) {
            url = endPoints.AB_CAMPAIGNS.LIST_VIEW_SETUP_GET;
        } else if (object === constants.SOCIAL_CAMPAIGNS_OBJECT) {
            url = endPoints.SOCIAL_CAMPAIGNS.LIST_VIEW_SETUP_GET;
        } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
            url = endPoints.CUSTOM_TABLE1.LIST_VIEW_SETUP_GET;
        } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
            url = endPoints.CUSTOM_TABLE2.LIST_VIEW_SETUP_GET;
        } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
            url = endPoints.CUSTOM_TABLE3.LIST_VIEW_SETUP_GET;
        } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
            url = endPoints.CUSTOM_TABLE4.LIST_VIEW_SETUP_GET;
        } else if (object === constants.APPOINTMENTS) {
            url = endPoints.APPOINTMENT.LIST_VIEW_SETUP_GET;
        } else if (object === constants.TASKS) {
            url = endPoints.TASK.LIST_VIEW_SETUP_GET;
        } else if (object === constants.NOTES) {
            url = endPoints.NOTES.LIST_VIEW_SETUP_GET;
        } else if (object === constants.GROUP_OBJECT) {
            url = endPoints.GROUP.LIST_VIEW_SETUP_GET;
        } else if (object === constants.ATTACHMENT_OBJECT) {
            url = endPoints.ATTACHMENT.LIST_VIEW_SETUP_GET;
        } else if (object === constants.COUPONS_OBJECT) {
            url = endPoints.COUPONS.LIST_VIEW_SETUP_GET;
        }else if (object === constants.SALES_LEADS_OBJECT) {
            url = endPoints.SALES_LEADS.LIST_VIEW_SETUP_GET;
        }else if (object === constants.RECYCLE_BIN_OBJECT) {
            url = endPoints.ACCOUNTS.LIST_VIEW_SETUP_GET;
        } else if (object === constants.NEWS_OBJECT) {
            url = endPoints.NEWS.LIST_VIEW_SETUP_GET;
        } else if (object === constants.ATTACHMENT_OBJECT) {
            url = endPoints.ATTACHMENT.LIST_VIEW_SETUP_GET;
        } else if (object === constants.PETS_OBJECT) {
            url = endPoints.PETS.LIST_VIEW_SETUP_GET;
        } else if (object === constants.ORDERS_OBJECTS) {
            url = endPoints.ORDERS.LIST_VIEW_SETUP_GET;
        } else if (object === constants.ORDER_ITEMS_OBJECTS) {
            url = endPoints.ORDER_ITEMS.LIST_VIEW_SETUP_GET;
        } else if (object === constants.QUOTATION_OBJECT) {
            url = endPoints.QUOTATIONS.LIST_VIEW_SETUP_GET;
        } else if (object === constants.PAYMENTS_OBJECT) {
            url = endPoints.PAYMENTS.LIST_VIEW_SETUP_GET;
        }

        let info = getActiveTabInfo();
        if(info.hasOwnProperty('query_link_id')){
            params.query_link_id = getIntParam(info.query_link_id);
        }
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'listViewActions.js -> getListViewSetupData()':" + e); }
}

function saveListViewSetupData(object, params) {
    let url = null;
    try {
        if (object === constants.ACCOUNTS_OBJECT) {
            url = endPoints.ACCOUNTS.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.CONTACTS_OBJECT) {
            url = endPoints.CONTACTS.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.OPPORTUNITIES_OBJECT) {
            url = endPoints.OPPORTUNITIES.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.PRODUCTS_OBJECT) {
            url = endPoints.PRODUCTS.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.CASES_OBJECT) {
            url = endPoints.CASES.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.SOLUTIONS_OBJECT) {
            url = endPoints.SOLUTIONS.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.ISSUES_OBJECT) {
            url = endPoints.ISSUES.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.PROJECTS_OBJECT) {
            url = endPoints.PROJECTS.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.AUTOMATION_DESIGNER_OBJECT) {
            url = endPoints.AUTOMATION_DESIGNER.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
            url = endPoints.BROADCAST_CAMPAIGNS.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.TOUCH_CAMPAIGNS_OBJECT) {
            url = endPoints.TOUCH_CAMPAIGNS.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.AB_CAMPAIGNS_OBJECT) {
            url = endPoints.AB_CAMPAIGNS.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.SOCIAL_CAMPAIGNS_OBJECT) {
            url = endPoints.SOCIAL_CAMPAIGNS.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
            url = endPoints.CUSTOM_TABLE1.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
            url = endPoints.CUSTOM_TABLE2.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
            url = endPoints.CUSTOM_TABLE3.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
            url = endPoints.CUSTOM_TABLE4.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.APPOINTMENTS) {
            url = endPoints.APPOINTMENT.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.TASKS) {
            url = endPoints.TASK.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.GROUP_OBJECT) {
            url = endPoints.GROUP.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.NOTES) {
            url = endPoints.NOTES.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.ATTACHMENT_OBJECT) {
            url = endPoints.ATTACHMENT.LIST_VIEW_SETUP_SAVE;
        }else if (object === constants.COUPONS_OBJECT) {
            url = endPoints.COUPONS.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.SALES_LEADS_OBJECT) {
            url = endPoints.SALES_LEADS.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.RECYCLE_BIN_OBJECT) {
            url = endPoints.ACCOUNTS.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.NEWS_OBJECT) {
            url = endPoints.NEWS.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.PETS_OBJECT) {
            url = endPoints.PETS.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.ORDERS_OBJECTS) {
            url = endPoints.ORDERS.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.ORDER_ITEMS_OBJECTS) {
            url = endPoints.ORDER_ITEMS.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.QUOTATION_OBJECT) {
            url = endPoints.QUOTATIONS.LIST_VIEW_SETUP_SAVE;
        } else if (object === constants.PAYMENTS_OBJECT) {
            url = endPoints.PAYMENTS.LIST_VIEW_SETUP_SAVE;
        }

        let info = getActiveTabInfo();
        if(info.hasOwnProperty('query_link_id')){
            params.query_link_id = getIntParam(info.query_link_id);
        }
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'listViewActions.js -> saveListViewSetupData()':" + e); }
}

function setTreeViewSelectedRecord(selectedRows, isDetailView) {
    try {
     
        return {
            type: actions.TREEVIEW_SET_SELECTED_RECORD,
            payload: selectedRows
        }
    } catch (e) { console.error("Error in 'listViewActions.js -> getListViewSelectedRecord()':" + e); }
}


function setListViewData(data) {
    return {
        type: actions.LISTVIEW_GET_FULFILLED,
        payload: data
    };
}

function setMounted(isMounted, isDetailView, isReport, isAssignRule, isDuplicate) {
    isDetailView = getBooleanParam(isDetailView);
    isReport = getBooleanParam(isReport);
    isAssignRule = getBooleanParam(isAssignRule);
    isDuplicate = getBooleanParam(isDuplicate);

    let type = '';
    if (isDetailView) {
        type = actions.CHILD_LISTVIEW_SET_MOUNTED;
    } else if (isReport) {
        type = actions.OTHER_LISTVIEW_SET_MOUNTED;
    } else if (isAssignRule) {
        type = actions.ASSIGN_LISTVIEW_SET_MOUNTED;
    }else if(isDuplicate){
        type = actions.DUPLICATE_LISTVIEW_SET_MOUNTED;
    } else {
        type = actions.LISTVIEW_SET_MOUNTED;
    }
    return {
        type: type,
        payload: isMounted
    };
}
// opeationType -> ADD , UPDATE , DELETE 
// recordId -> Id of Selected Row
// modifedRowData is [{"fieldName":"","fieldValue":""  }]

let inaitailModifedRowData = { "fieldName": "", "fieldValue": "" };

function manipulateListViewRowData(opeationType, recordId, modifedRowData = inaitailModifedRowData) {

    let _state = store.getState();
    let listView = _state.listView;


    if (opeationType === 'update') {
        let selectedRecord = filter(listView.data.records, (item, index) => {
            return (item.id === recordId)
        });

        selectedRecord = selectedRecord.length > 0 ? selectedRecord[0] : null;

        if (selectedRecord !== null && modifedRowData !== null) {
            for (let fieldName in modifedRowData) {
                if (selectedRecord.hasOwnProperty(fieldName)) {
                    selectedRecord[fieldName] = modifedRowData[fieldName];
                }
            }

            store.dispatch({
                type: actions.LISTVIEW_MODIFY,
                payload: listView
            });
        }
    } else if (opeationType === 'delete') {
        let allRecordExpectDeteted = filter(listView.data.records, (item, index) => {
            return (item.id !== recordId)
        });

        listView.data.records = allRecordExpectDeteted;

        store.dispatch({
            type: actions.LISTVIEW_MODIFY,
            payload: listView
        });

    }

}

function donloadAttachmentData(object, recordId) {
    object = getStringParam(object);
    if (object !== '' && recordId !== '') {
        let url = '';
        let _state = store.getState();
        let listView = null;
        let tabInfo = getActiveTabInfo();
        let isReport = getBooleanParam(tabInfo.isReport);
        if (getBooleanParam(_state.detailView.isDetailView)) {
            listView = _state.childListView;
        } else if (getBooleanParam(isReport)) {
            listView = _state.otherListView;
        } else {
            listView = _state.listView;
        }


        //  let listView = _state.listView;

        let selectedRecords = filter(listView.data.records, (item, index) => {
            return (item.id === recordId)
        });

        var formData = new FormData();
        let documentName = null;
        let documentType = '';
        if (object === constants.INVOICES_OBJECT && selectedRecords.length > 0) {
            url = endPoints.INVOICES.DOWNLOAD;
            let invoiceNo = getStringParam(selectedRecords[0].invoice_no);
            documentName = invoiceNo + '.pdf';
            formData.append('input_param', JSON.stringify({ invoice_id: invoiceNo, file_name: documentName }));
            documentName = getStringParam(documentName);
            if (documentName !== '') {
                HTTPClient.postFormDataDownloadFile(url, formData, documentName);
            }
        } else {
            url = endPoints.ATTACHMENT.DOWNLOAD_ATTACHMENT;
            documentName = selectedRecords[0].document_name;
            if (isValidParam(selectedRecords) && getStringParam(selectedRecords[0].attachment_type) === 'Agreement') {
                documentName = documentName + '.pdf';
            }
            formData.append('input_param', JSON.stringify({ 'id': recordId }));
            documentName = getStringParam(documentName);
            documentType = documentName.split('.').pop();
            if (documentName !== '') {
                if (documentType === "pdf") {
                    let params = { 'id': recordId };
                    var promise = Promise.resolve(HTTPClient.post(endPoints.ATTACHMENT.GET_ATTACHMENT_URL, params));
                    promise.then((response) => {
                        window.open(response.attachmentUrl);
                    });
                } else {
                    HTTPClient.postFormDataDownloadFile(url, formData, documentName);
                }
            }
        }

    }

    return;
}

function updateListViewRowOnRedux(recordId, modifedRowData) {
    let _state = store.getState();
    let listView = null;
    if(isValidParam(_state.tab.tabs[0].info.is_duplicate_object) && getBooleanParam(_state.tab.tabs[0].info.is_duplicate_object)){
        listView = _state.duplicateListView;
    }else{
        listView = _state.listView;
    }

    let selectedRecord = filter(listView.data.records, (item, index) => {
        return (item.id === recordId)
    });

    selectedRecord = selectedRecord.length > 0 ? selectedRecord[0] : null;

    if (selectedRecord !== null && modifedRowData !== null) {
        for (let fieldName in modifedRowData) {
            if (selectedRecord.hasOwnProperty(fieldName)) {
                selectedRecord[fieldName] = modifedRowData[fieldName];
            }
        }
    }
}

function getListData(object, params) {

    let url = null;
    try {
        if (object === constants.ACCOUNTS_OBJECT) {
            url = endPoints.ACCOUNTS.LIST_GET;
        } else if (object === constants.CONTACTS_OBJECT) {
            url = endPoints.CONTACTS.LIST_GET;
        } else if (object === constants.OPPORTUNITIES_OBJECT) {
            url = endPoints.OPPORTUNITIES.LIST_GET;
        } else if (object === constants.PRODUCTS_OBJECT) {
            url = endPoints.PRODUCTS.LIST_GET;
        } else if (object === constants.CASES_OBJECT) {
            url = endPoints.CASES.LIST_GET;
        } else if (object === constants.SOLUTIONS_OBJECT) {
            url = endPoints.SOLUTIONS.LIST_GET;
        } else if (object === constants.ISSUES_OBJECT) {
            url = endPoints.ISSUES.LIST_GET;
        } else if (object === constants.PROJECTS_OBJECT) {
            url = endPoints.PROJECTS.LIST_GET;
        } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
            url = endPoints.CUSTOM_TABLE1.LIST_GET;
        } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
            url = endPoints.CUSTOM_TABLE2.LIST_GET;
        } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
            url = endPoints.CUSTOM_TABLE3.LIST_GET;
        } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
            url = endPoints.CUSTOM_TABLE4.LIST_GET;
        } else if (object === constants.APPOINTMENTS) {
            url = endPoints.APPOINTMENT.LIST_GET;
        } else if (object === constants.TASKS) {
            url = endPoints.TASK.LIST_GET;
        } else if (object === constants.GROUP_OBJECT) {
            url = endPoints.GROUP.LIST_GET;
        } else if (object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
            url = endPoints.BROADCAST_CAMPAIGNS.LIST_GET;
        } else if (object === constants.AB_CAMPAIGNS_OBJECT) {
            url = endPoints.AB_CAMPAIGNS.LIST_GET;
        } else if (object === constants.AUTOMATION_DESIGNER_OBJECT) {
            url = endPoints.AUTOMATION_DESIGNER.LIST_GET;
        } else if (object === constants.NOTES) {
            url = endPoints.NOTES.LIST_GET;
        } else if (object === constants.PAYMENTS_OBJECT) {
            url = endPoints.PAYMENTS.LIST_GET;
        } else if (object === constants.QUOTATION_OBJECT) {
            url = endPoints.QUOTATIONS.LIST_GET;
        }

        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'listViewActions.js -> getListData()':" + e); }
}

function isLinkedRecord(params) {
    let url = null;
    try {
        url = endPoints.COMMON.IS_LINKED_RECORD;
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'listViewActions.js -> isLinkedRecord()':" + e); }
}

function getLookupRecordInfo(params) {
    let url = null;
    try {
        if (params !== null && params !== undefined && params !== '') {
            url = endPoints.COMMON.LOOKUP_RECORD_INFO_GET;
            return HTTPClient.get(url, params);
        }

    } catch (e) { console.error("Error in 'listViewActions.js -> getLookupRecordInfo()':" + e); }
}

function groupByShowHide(flag) {
    let info = getActiveTabInfo();
    let isAssignRule = getBooleanParam(info.isAssignRule);
    let type = '';
    if(isAssignRule){
        type = actions.ASSIGN_LISTVIEW_GROUPBY_SHOW_HIDE;
    } else {
        type = actions.LISTVIEW_GROUPBY_SHOW_HIDE;
    }
    return {
        type: type
    }
}

function leadStoreSearchFldShowHide(flag) {
    let type = actions.LISTVIEW_LEADSTORE_SEARCH_SHOW_HIDE;
    return {
        type: type
    }
}

function refreshListView(object, params, isLookup) {
    try {
        object = getStringParam(object);
        let state = store.getState();
        let activeTab = getActiveTab();
        activeTab = getObjectParam(activeTab);
        let tabType = getStringParam(activeTab.type);
        let inputParam = getObjectParam(activeTab.info.input_param);

        params = getObjectParam(params);
        if (activeTab.object === constants.SEARCH_RESULTS || activeTab.object === constants.ADVANCE_SEARCH_RESULTS) {
            let info = getObjectParam(activeTab.info);
            params = getObjectParam(params);
            params.query_name = constants.SEARCH_QUERY_NAME;
            if (activeTab.object === constants.SEARCH_RESULTS) {
                let search = getObjectParam(info.search);
                params.query_type = constants.SEARCH_QUERY_TYPE_GLOBAL;
                params.search_text = getStringParam(search.searchText);
            } else if (activeTab.object === constants.ADVANCE_SEARCH_RESULTS) {
                params.query_type = constants.SEARCH_QUERY_TYPE_ADVANCE;
                params.search_type = constants.SEARCH_TYPE_START_WITH;
                let advanceSearch = getObjectParam(info.advanceSearch);
                let searchParams = getObjectParam(advanceSearch.searchParams);
                params.search = getArrayParam(searchParams.search);
            }
        } else if (TYPE_LIST_VIEW === tabType && object !== constants.ASSIGNMENT_RULES) {
            let filter = getObjectParam(activeTab.info.filter);
            if(getIntParam(filter.id) > 0 || getStringParam(filter.name) !== '') {
                params = {
                    ...params,
                    query_id: getIntParam(filter.id),
                    query_name: getStringParam(filter.name),
                    query_type: getStringParam(filter.type)
                };
            } else if (isValidParam(activeTab) && isValidParam(activeTab.info) && activeTab.info.hasOwnProperty('query_link_id')) {
                params = {
                    ...params,
                    query_link_id: getIntParam(activeTab.info.query_link_id)
                };
            }

        } else if (TYPE_DETAIL_VIEW === tabType) {
            let listView = getObjectParam(state.childListView.data);

            let listViewObject = getStringParam(listView.object);
            listViewObject = getObjectNameByLabel(listViewObject).toLowerCase();
            object = object !== listViewObject ? '' : object;

            let parentObject = getStringParam(listView.parent_object);
            
            params = {
                ...params,
                parent_object: getStringParam(listView.parent_object),
                parent_record_id: getIntParam(listView.parent_record_id),
                query_id: getIntParam(listView.query_id),
                query_name: getStringParam(listView.query_name),
                query_type: getStringParam(listView.query_type)
            };
            if (object === constants.ACCOUNTS_OBJECT && parentObject === constants.ACCOUNTS_OBJECT) {
                params.relationTableId = 10;
            }
        }

        params = {
            ...params,
            ...inputParam,
        };

        if(object === constants.CASES_OBJECT || object === constants.NOTE_OBJECT || object === constants.ATTACHMENT_OBJECT) {
            let parentObject = getStringParam(params.parent_object);
            if(state.app.selectedModuleName === constants.MODULE_HUB 
                && (object === constants.CASES_OBJECT || parentObject === constants.CASES_OBJECT)) {
                params.call_from = constants.MODULE_HUB;
            }
        }   

        if (object !== '') {
            let info = getObjectParam(activeTab.info);
            let isReport = getBooleanParam(info.isReport);
            let isDetailView = getBooleanParam(params.isDetailView);

            if (info.hasOwnProperty('groupByFieldName') || info.hasOwnProperty('groupByCondition') || info.hasOwnProperty('searchFieldName')
                || info.hasOwnProperty('searchText') || info.hasOwnProperty('selectedPageNo')
                || info.hasOwnProperty('startIndex') || info.hasOwnProperty('startPageNo')) {

                if (info.hasOwnProperty('groupByFieldName')) {
                    params.group_by_field_name = getStringParam(info.groupByFieldName);
                }

                if (info.hasOwnProperty('groupByCondition')) {
                    params.group_by_condition = getStringParam(info.groupByCondition);
                }

                if (info.hasOwnProperty('searchFieldName')) {
                    params.search_field_name = getStringParam(info.searchFieldName);
                }

                if (activeTab.object !== constants.SEARCH_RESULTS && activeTab.object !== constants.ADVANCE_SEARCH_RESULTS) {
                    if (params.hasOwnProperty('search_text') && getStringParam(params.search_text) === '') {
                        delete info.searchText;
                    } else {
                        params.search_text = getStringParam(info.searchText);
                    }
                }

                if (TYPE_LIST_VIEW === tabType && !params.hasOwnProperty('start_index') && !params.hasOwnProperty('page_size')) {
                    let listViewObject = getStringParam(state.listView.data.object);
                        if(isReport && getBooleanParam(state.otherListView.mounted) && isValidParam(state.otherListView.data)) {
                        listViewObject = getStringParam(state.otherListView.data.object);
                        } else if(getBooleanParam(state.listView.mounted) && isValidParam(state.listView.data)) {
                        listViewObject = getStringParam(state.listView.data.object);
                    }
                    listViewObject = getObjectNameByLabel(listViewObject);
                    if (object === listViewObject) {
                        delete info.selectedPageNo;
                        delete info.startIndex;
                        delete info.startPageNo;
                        delete info.pageSize;
                    }
                }

                else if (TYPE_DETAIL_VIEW === tabType && !params.hasOwnProperty('start_index') && !params.hasOwnProperty('page_size')) {
                    let listView = getObjectParam(state.childListView.data);
                    let listViewObject = getStringParam(listView.object);
                    listViewObject = getObjectNameByLabel(listViewObject);
                    if (object === listViewObject) {
                        delete info.selectedPageNo;
                        delete info.startIndex;
                        delete info.startPageNo;
                        delete info.pageSize;
                    }
                }
            }

            if(object === constants.WORKFLOW_OBJECT && info.hasOwnProperty('statusfieldName')){
                params.status_field_name = getStringParam(info.statusfieldName);
            }

            if (isReport) {
                params.isReport = true;

                let listView = getObjectParam(state.otherListView.data);
                if(listView.hasOwnProperty('statKey')) {
                    delete listView.statKey;
                }
            }

            updateTabInfo(info);

            if (object !== '') {
                object = object.toLowerCase();
                store.dispatch(getListViewData(object, params, isLookup));

                if (!isReport && !isDetailView) {
                    store.dispatch(getListViewData(object, { ...params, is_group_by_info: true }, isLookup));
                }
            }
        }

    } catch (error) {
        console.error("Error in 'listViewActions.js -> refreshListView()':" + error);
    }
}

function getLinkRecordsForLookup(params) {
    let url = null;
    try {
        if (params !== null && params !== undefined && params !== '') {
            url = endPoints.COMMON.LOOKUP_LINK_RECORDS;
            return HTTPClient.post(url, params);
        }

    } catch (e) { console.error("Error in 'listViewActions.js -> getLinkRecordsForLookup()':" + e); }

}

function setListViewMounted(isMounted, object) {
    try {
        if (isValidParam(isMounted)) {
            isMounted = getBooleanParam(isMounted);
            object = getStringParam(object).toLowerCase();
            let state = store.getState();
            if (object !== '') {
                let listViewObject = getStringParam(state.listView.data.object);
                listViewObject = getObjectNameByLabel(listViewObject);
                if (object === listViewObject || object === constants.RECYCLE_BIN_OBJECT) {
                    state.listView.mounted = isMounted;
                    state.listView.isGroupByMounted = isMounted;
                }
            } else {
                let activeTab = getActiveTab();
                if (activeTab.object !== constants.SEARCH_RESULTS && activeTab.object !== constants.ADVANCE_SEARCH_RESULTS) {
                    state.listView.mounted = isMounted;
                    state.listView.isGroupByMounted = isMounted;
                }

            }
        }
    } catch (error) {
        console.error("Error in 'listViewActions.js -> setListViewMounted()':" + error);
    }
}

function openListView(object, queryName) {
    try {
        let state = store.getState();
        let url = '/' + object + '/queries';
        queryName = getStringParam(queryName);
        let tab = { item: constants.CALENDAR_TASK, label: queryName, object: object, type: TYPE_LIST_VIEW, url: url, isActive: true };
        let queryInfo = getObjectParam(state.app.queryInfo);
        if (Object.keys(queryInfo).length === 0 || !queryInfo.hasOwnProperty(queryName)) {
            let params = {};
            params.queries = [];
            params.queries.push({ object: object, query_name: queryName });
            let promise = getQueryDetails(params);
            if (isValidParam(promise)) {
                promise.then((response) => {
                    response = getObjectParam(response);
                    for (let qName in response) {
                        queryInfo[qName] = response[qName];
                    }
                    if (isValidParam(queryInfo[queryName])) {
                        let queryDtls = queryInfo[queryName];
                        let filter = { id: queryDtls.query_id, name: queryDtls.query_name, type: queryDtls.query_type };
                        tab.info = { filter: filter };
                        addTab(tab, true);
                    }
                });
            }
        } else if (isValidParam(queryInfo[queryName])) {
            let queryDtls = queryInfo[queryName];
            let filter = { id: queryDtls.query_id, name: queryDtls.query_name, type: queryDtls.query_type };
            tab.info = { filter: filter };
            addTab(tab, true);
        }
    } catch (e) { console.error("Error in 'listViewActions.js -> openListView()':" + e); }
}

function unsubscribeContacts(params) {
    try {
        let url = endPoints.CONTACTS.UNSUBSCRIBE;
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'listViewActions.js -> saveListViewSetupData()':" + e); }
}

function getParentAccountContacts(contactId, params) {
    try {
        contactId = getIntParam(contactId);
        if (contactId > 0) {
            let url = endPoints.CONTACTS.PARENT_ACCOUNT_CONTACTS_LIST_GET + '/' + contactId;
            return HTTPClient.get(url, params);
        }
    } catch (e) { console.error("Error in 'listViewActions.js -> getParentAccountContacts()':" + e); }
}

function populateFiltersFromActiveTab() {
    let params = null;
    let input_param = null;
    try {
        let tab = getActiveTab();
        if (isValidParam(tab) && isValidParam(tab.info) && tab.info.hasOwnProperty('filter')) {
            if (isValidParam(tab.info.filter)) {
                let filter = getObjectParam(tab.info.filter);
                let filterId = getIntParam(filter.id);
                params = {
                    query_id: filterId,
                    query_type: filter.type,
                    query_name: filter.name
                };
            }
        }

        if (isValidParam(tab) && isValidParam(tab.info) && tab.info.hasOwnProperty('input_param')) {
            input_param = getObjectParam(tab.info.input_param);
            params = { ...params, ...input_param };
        }
    } catch (error) {
        console.error("Error in 'listViewActions.js -> populateFiltersFromActiveTab()':" + error);
    }
    return params;
}

function getSetupViewFormFields(object) {
    let url = null;
    var promise = null;
    try {
        url = endPoints.COMMON.SETUP_VIEW_FIELDS_GET;
        if (isValidParam(url)) {
            promise = Promise.resolve(HTTPClient.get(url, {object: object}));
        }
    } catch (error) {
        console.error("Error in 'listViewActions.js -> getSetupViewFormFields()':" + error);
    }
    return promise;
}

function downloadLeads(object, params) {
    let url = null;
    try {
        if (object === constants.DOWNLOAD_LEADS_OBJECT) {
            url = endPoints.ACCOUNTS.DOWNLOAD_LEADS;
        }

        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'listViewActions.js -> downloadLeads()':" + e); }
}

function isWorkflowExists( params) {
    try {

          let  url = endPoints.CONTACTS.WORKFLOW_EXISTS;
        return HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'listViewActions.js -> isWorkflowExists()':" + e); }
}

function refreshColumnView(params) {
    try {
        let url = endPoints.COLUMN_VIEW.APP_TASK_PORTAL;
        url = url.replace('{object}', params.object);
        let inputParams = {
            recordId: params.record_id,
            object: params.object,
            call_from: constants.COLUMN_VIEW,
            is_column_view: true
        }

        if (isValidParam(url)) {
            let promise = Promise.resolve(HTTPClient.get(url, inputParams));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    let state = store.getState();
                    let columnViewData = getObjectParam(state.lists);
                    let lists = columnViewData.lists;
                    lists[params.listIndex]['cards'][params.cardIndex]['task_appointment'] = response.task_appointment;
                    let updatedList = lists;
                    params.updateCards(updatedList);
                    
                });
            }
        }

    } catch (error) {
        console.error("Error in 'listViewActions.js -> refreshColumnView()':" + error);
    }
}

function getLinkRecords(params) {
    try {
        let url = endPoints.COMMON.GET_LINKED_RECORD;
        return HTTPClient.get(url, params);
    } catch (e) {
        console.error("Error in 'listViewActions.js -> getLinkRecords()':" + e);
    }
}


function checkSyncedProducts(params) {
    try {
        let url = endPoints.PRODUCTS.SYNCED_PRODUCTS;
        return HTTPClient.get(url, params);
    } catch (e) {
        console.error("Error in 'listViewActions.js -> checkSyncedProducts()':" + e);
    }
}

function getAttachmentAgreementData(params){
    try {
        let url = endPoints.ATTACHMENT.GET_ATTACHMENT_AGREEMENT;
        return HTTPClient.get(url, params);
    } catch (e) {
        console.error("Error in 'listViewActions.js -> getAttachmentAgreementData()':" + e);
    }
}

function getAttachmentSignStatus(params){
    try {
        let url = endPoints.ATTACHMENT.GET_ATTACHMENT_SIGN_STATUS;
        return HTTPClient.get(url, params);
    } catch (e) {
        console.error("Error in 'listViewActions.js -> getAttachmentSignStatus()':" + e);
    }
}

function getAttachmentType(params){
    try {
        let url = endPoints.ATTACHMENT.GET_ATTACHMENT_TYPE;
        return HTTPClient.get(url, params);
    } catch (e) {
        console.error("Error in 'listViewActions.js -> getAttachmentType()':" + e);
    }
}

function refreshTreeView(refresh) {
    store.dispatch({
        type: actions.TREEVIEW_REFRESH,
        payload: refresh
    });
}

function saveUnitPlanNames(params) {
	try {
		let url = endPoints.UNITS.SAVE_PLAN_NAMES;
		return HTTPClient.post(url, params);
	} catch (e) {
		console.error(
			"Error in 'treeViewActions.js -> saveUnitPlanNames()':" + e
		);
	}
}

export {
    getListViewData,
    saveListViewLayoutRowWidth,
    getListViewSetupData,
    saveListViewSetupData,
    setListViewData,
    manipulateListViewRowData,
    setMounted,
    getListData,
    isLinkedRecord,
    updateListViewRowOnRedux,
    getLookupRecordInfo,
    groupByShowHide,
    refreshListView,
    donloadAttachmentData,
    getLinkRecordsForLookup,
    setListViewMounted,
    openListView,
    unsubscribeContacts,
    getParentAccountContacts,
    populateFiltersFromActiveTab,
    getSetupViewFormFields,
    leadStoreSearchFldShowHide,
    downloadLeads,
    isWorkflowExists,
    refreshColumnView,
    getLinkRecords,
    checkSyncedProducts,
    getAttachmentAgreementData,
    getAttachmentSignStatus,
    getAttachmentType,
    refreshTreeView,
    setTreeViewSelectedRecord,
    saveUnitPlanNames
}
