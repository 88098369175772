import React from 'react';
import IntakeInfoForm from './IntakeInfoForm';

const IntakeForm = ({id}) => {
    const urlParams = new URLSearchParams(window.location.hash.split('intake-form')[1]);
    let encodedParams = urlParams.get('p');
    let contentHeight;
    if(encodedParams === null){
        contentHeight = window.innerHeight - 150;
    }else{
        contentHeight = 'auto';
    }
    return (
        <div style={{width:'100%',height:contentHeight,overflowY:'scroll',margin:'0px'}}>
            <IntakeInfoForm id={id}/>
        </div>
    );
};

export default IntakeForm;
