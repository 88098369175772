import React, {useEffect, useState} from 'react';
import {styles} from '../../../services/constants/styles';
import * as HTTPClient from '../../../services/helper/httpClient';
import {endPoints} from '../../../services/constants/endPoints';
import {constants, OBJECT_TABLEID_MAP} from '../../../services/constants/constants';
import {getAuditTrail} from '../../../services/actions/batchActions';
import ShowCircularProgress from '../components/circularProgress';
import {dateTimeFormat} from '../../../services/helper/utils';
import moment from 'moment';
import {getArrayParam, isValidParam} from '../../../services/helper/parameterVerifier';
import {getLocalizedStrings} from '../../../services/constants/MultiLingual';
import {useSelector} from "react-redux";

const AuditTrailAndLeadScore = ({ object, data }) => {

    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);

    const [mounted, setMounted] = useState(false);
    const [dataArray, setDataArray] = useState([]);

    useEffect(() => {
        let actionType = appContainer.drawerProps.actionType;
        if (actionType === constants.AUDIT) {
            getAuidtrailData();
        }
        else if (actionType === constants.LEAD_SCORE_HISTORY) {
            getLeadScoreHistory();
        }
    });

    const getAuidtrailData = () => {
        let params = '';
        let response = getAuditTrail(object, params, data.parent_record_id);
        response.then((response) => {
            setMounted(true);
            setDataArray(response.audit_trails)
        });
    }

    const populateAuditTrailHeader = () => {
        let header = '';
        // let fieldArray = constants.AUDIT_TRAIL_HEADER;
        let fieldArray = [getLocalizedStrings().label.AUDIT_LEADSCORE.DATE,
        getLocalizedStrings().label.AUDIT_LEADSCORE.ACTION,
        getLocalizedStrings().label.AUDIT_LEADSCORE.USER,
        getLocalizedStrings().label.AUDIT_LEADSCORE.DETAILS];

        if (fieldArray.length > 0) {

            return fieldArray.map((item, i) => {
                if (i !== 3) {
                    return <div style={{ display: 'inline-block', width: '20%', borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd', backgroundColor: 'black', paddingTop: '5px', paddingBottom: '5px' }}><span style={{ paddingLeft: '5px', fontWeight: 'bold', color: '#FFFFFF' }}> {item} </span></div>;
                } else {
                    return <div style={{ display: 'inline-block', width: '40%', borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd', backgroundColor: 'black', paddingTop: '5px', paddingBottom: '5px' }}><span style={{ paddingLeft: '5px', fontWeight: 'bold', color: '#FFFFFF' }}> {item} </span></div>;
                }
            }
            )
        }
        return header;
    }

    const populateAuditTrailBody = () => {
        // let body = null;
        let dataArrayCopy = [...dataArray];
        if (dataArrayCopy.length > 0) {
            dataArrayCopy.reverse();
            return dataArrayCopy.map((item, i) => {
                // let detailvalue = '';
                let detailvalueHtml = null;
                let dueByDateObj = moment(item.date);
                dueByDateObj = dueByDateObj.format(dateTimeFormat[app.me.date_format]);
                if (item.field_name) {
                    // if (item.action === 'Updated') {
                    //     detailvalue = item.field_name + " ( Updated to " + item.new_value + ")";
                    // } else if (item.action === 'Assigned') {
                    //     detailvalue = item.field_name + " ( Assigned to " + item.new_value + ")";
                    // } else {
                    //     detailvalue = item.field_name + "( " + getLocalizedStrings().label.AUDIT_LEADSCORE.FROM + " " + (item.old_value === "" ? getLocalizedStrings().label.COMMON.EMPTY : item.old_value) + " " + getLocalizedStrings().label.AUDIT_LEADSCORE.TO + " " + (item.new_value === "" ? getLocalizedStrings().label.COMMON.EMPTY : item.new_value) + ")";
                    // }
                    detailvalueHtml = <div><span style={{ fontWeight: 'bold' }}>{item.field_name}</span>
                        <span>{" ( " + getLocalizedStrings().label.AUDIT_LEADSCORE.FROM + " "}</span>
                        <span style={{ fontWeight: 'bold' }}>{(item.old_value === "" ? getLocalizedStrings().label.COMMON.EMPTY : item.old_value)}</span>
                        <span>{" " + getLocalizedStrings().label.AUDIT_LEADSCORE.TO + " "}</span>
                        <span style={{ fontWeight: 'bold' }}>{(item.new_value === "" ? getLocalizedStrings().label.AUDIT_LEADSCORE.EMPTY : item.new_value)}</span>
                        <span>{")"}</span>
                    </div>;
                }
                if (i % 2 !== 0) {
                    return <div style={{ backgroundColor: '#FAFAFA' }}>
                        <div style={{ display: 'inline-block', width: '20%', borderTop: '1px solid #ddd', verticalAlign: 'top', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px' }}><span> {dueByDateObj} </span></div>
                        <div style={{ display: 'inline-block', width: '20%', borderTop: '1px solid #ddd', verticalAlign: 'top', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px' }}>
                            <span>
                                {
                                    isValidParam(getLocalizedStrings().label.AUDIT_LEADSCORE[item.action]) ?
                                        getLocalizedStrings().label.AUDIT_LEADSCORE[item.action] :
                                        item.action
                                }
                            </span>
                        </div>
                        <div style={{ display: 'inline-block', width: '20%', borderTop: '1px solid #ddd', verticalAlign: 'top', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px' }}><span> {item.user} </span></div>
                        <div style={{ display: 'inline-block', width: '40%', borderTop: '1px solid #ddd', verticalAlign: 'top', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px', paddingRight: '10px' }}> {detailvalueHtml}</div>
                    </div>;
                } else {
                    return <div>
                        <div style={{ display: 'inline-block', width: '20%', borderTop: '1px solid #ddd', verticalAlign: 'top', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px' }}><span style={{fontSize: '15px'}}> {dueByDateObj} </span></div>
                        <div style={{ display: 'inline-block', width: '20%', borderTop: '1px solid #ddd', verticalAlign: 'top', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px' }}>
                            <span >
                                {
                                    isValidParam(getLocalizedStrings().label.AUDIT_LEADSCORE[item.action]) ?
                                        getLocalizedStrings().label.AUDIT_LEADSCORE[item.action] :
                                        item.action
                                }
                            </span>
                        </div>
                        <div style={{ display: 'inline-block', width: '20%', borderTop: '1px solid #ddd', verticalAlign: 'top', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px' }}><span> {item.user} </span></div>
                        <div style={{ display: 'inline-block', width: '40%', borderTop: '1px solid #ddd', verticalAlign: 'top', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px', paddingRight: '10px' , fontSize:'16px'}}> {detailvalueHtml} </div>
                    </div>;
                }
            }
            )
        } else {
            return <div style={{ display: 'inline-block', width: '100%', paddingTop: '10px', borderTop: '1px solid #ddd', verticalAlign: 'top', textAlign: 'center' }}><span >{getLocalizedStrings().message.AUDIT_LEADSCORE.NO_AUDIT_DATA}</span></div>
        }
        //  return body;
    }

    const getLeadScoreHistory = () => {
        let params = {};
        params.tableId = OBJECT_TABLEID_MAP[data.parent_object];
        params.recordId = data.parent_record_id;
        params.projectId = app.me.projectId;
        params.isInXpress = false;
        let endPoint = '';
        if (data.parent_object === constants.ACCOUNTS_OBJECT) {
            endPoint = endPoints.ACCOUNTS.LEAD_SCORE_HISTORY
        }
        if (data.parent_object === constants.CONTACTS_OBJECT) {
            endPoint = endPoints.CONTACTS.LEAD_SCORE_HISTORY
        }
        const response = Promise.resolve(HTTPClient.get(endPoint, params));
        response.then((response) => {
            setMounted(true);
            setDataArray(response)
        });
    }

    const populateLeadScoreHistoryHeader = () => {
        let header = '';
        //let fieldArray = constants.LEAD_SCORE_HISTORY_HEADER;
        let fieldArray = [getLocalizedStrings().label.AUDIT_LEADSCORE.DATE,
        getLocalizedStrings().label.AUDIT_LEADSCORE.ACTION_NAME,
        getLocalizedStrings().label.AUDIT_LEADSCORE.SCORE,
        getLocalizedStrings().label.AUDIT_LEADSCORE.SCORE_DETAILS];

        if (fieldArray.length > 0) {
            return fieldArray.map((item, i) => {
                if (i !== 3) {
                    return <div style={{ display: 'inline-block', width: '20%', borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd', backgroundColor: 'black', paddingTop: '5px', paddingBottom: '5px' }}><span style={{ paddingLeft: '5px', fontWeight: 'bold', color: '#FFFFFF' }}> {item} </span></div>;
                } else {
                    return <div style={{ display: 'inline-block', width: '40%', borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd', backgroundColor: 'black', paddingTop: '5px', paddingBottom: '5px' }}><span style={{ paddingLeft: '5px', fontWeight: 'bold', color: '#FFFFFF' }}> {item} </span></div>;
                }
            }
            )
        }
        return header;
    }

    const populateLeadScoreHistoryBody = () => {
        // let body = null;
        let dataObj = dataArray;
        let scoreDetailsArr = dataObj.ARR_SCORE_DETAILS;

        if (scoreDetailsArr !== null && scoreDetailsArr.length > 0) {
            return scoreDetailsArr.map((item, i) => {
                let postText = '', msgName = '';

                let createDateObj = moment(item.createDate);
                createDateObj = createDateObj.format(dateTimeFormat[app.me.date_format]);

                if (item.leadsOrCustomerName !== null && item.leadsOrCustomerName !== "undefined" && item.leadsOrCustomerName.trim().length > 0) {
                    postText = getLocalizedStrings().label.AUDIT_LEADSCORE.BY + " " + item.leadsOrCustomerName.trim();
                }

                if (item.actionId === dataObj.SCORE_ACTION_CLICKED_LINK) {
                    let visitedUrl = "";
                    if (item.websiteVisitUrl !== null && item.websiteVisitUrl !== 'undefined' && item.websiteVisitUrl !== "" && item.websiteVisitUrl.trim().length > 0) {
                        visitedUrl = " (" + item.websiteVisitUrl + ")";
                    }
                    item.description = getLocalizedStrings().label.AUDIT_LEADSCORE.LINK + visitedUrl + " " + getLocalizedStrings().label.AUDIT_LEADSCORE.CLICKED + " " + postText;
                }

                else if (item.actionId === dataObj.SCORE_ACTION_OPENED_EMAIL) {
                    if (item.messageName !== null && item.messageName !== 'undefined' && item.messageName !== "" && item.messageName.trim().length > 0) {
                        msgName = " (" + item.messageName + ")";
                    }
                    item.description = getLocalizedStrings().label.AUDIT_LEADSCORE.EMAIL + msgName + " " + getLocalizedStrings().label.AUDIT_LEADSCORE.OPENED + " " + postText;
                } else if (item.actionId === dataObj.SCORE_ACTION_UNSUBSCRIBED) {
                    item.description = getLocalizedStrings().label.AUDIT_LEADSCORE.UNSUBSCRIBED + " " + postText;
                } else if (item.actionId === dataObj.SCORE_ACTION_STATUS_CONVERTED) {
                    item.description = getLocalizedStrings().label.AUDIT_LEADSCORE.CONVERTED_FROM + " " + item.convertedFrom + " " + getLocalizedStrings().label.AUDIT_LEADSCORE.TO + " " + item.convertedTo;
                    if (item.childObjectId > 0 && item.childRecordId > 0) {
                        item.description = item.description + " " + getLocalizedStrings().label.AUDIT_LEADSCORE.IN_OPPORTUNITIES;
                    }
                } else if (item.actionId === dataObj.SCORE_ACTION_ADDED_TO_GROUP) {
                    item.description = getLocalizedStrings().label.AUDIT_LEADSCORE.ADDED_TO + " " + item.description;
                } else if (item.actionId === dataObj.SCORE_ACTION_REMOVED_FROM_GROUP) {
                    item.description = getLocalizedStrings().label.AUDIT_LEADSCORE.REMOVED_FROM + " " + item.description;
                } else if (item.actionId === dataObj.SCORE_ACTION_UPDATE_FIELD) {
                    item.description = item.updateLabel + " " + getLocalizedStrings().label.AUDIT_LEADSCORE.UPDATED_TO + " " + item.updatedValue;
                } else if (item.actionId === dataObj.SCORE_ACTION_SUBMITTED_WEBFORM) {
                    if (item.messageName !== null && item.messageName !== 'undefined' && item.messageName !== "" && item.messageName.trim().length > 0) {
                        msgName = " (" + item.messageName + ")";
                    }
                    item.description = getLocalizedStrings().label.AUDIT_LEADSCORE.WEBFORM + msgName + " " + getLocalizedStrings().label.AUDIT_LEADSCORE.SUBMITTED + " " + postText;
                } else if (item.actionId === dataObj.SCORE_ACTION_WEBSITE_VISITED) {
                    item.description = getLocalizedStrings().label.AUDIT_LEADSCORE.VISITED + " " + item.websiteVisitUrl;
                } else if (item.actionId === dataObj.SCORE_ACTION_HAS_BUSINESS_EMAIL) {
                    item.description = item.messageName;
                }

                if (i % 2 !== 0) {
                    return <div style={{ backgroundColor: '#FAFAFA' }}>
                        <div style={{ display: 'inline-block', width: '20%', borderTop: '1px solid #ddd', verticalAlign: 'top', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px' }}><span > {createDateObj} </span></div>
                        <div style={{ display: 'inline-block', width: '20%', borderTop: '1px solid #ddd', verticalAlign: 'top', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px' }}>
                            <span>
                                {
                                    isValidParam(getLocalizedStrings().label.AUDIT_LEADSCORE[item.actionLabel]) ?
                                        getLocalizedStrings().label.AUDIT_LEADSCORE[item.actionLabel] :
                                        item.actionLabel
                                }
                            </span>
                        </div>
                        <div style={{ display: 'inline-block', width: '20%', borderTop: '1px solid #ddd', verticalAlign: 'top', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px' }}><span > {item.operator + item.score} </span></div>
                        <div style={{ display: 'inline-block', width: '40%', borderTop: '1px solid #ddd', verticalAlign: 'top', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px' }}>
                            {item.reverse !== null && item.reverse !== undefined && item.reverse === 1 &&
                                <span style={{ color: 'red' }}>({getLocalizedStrings().label.AUDIT_LEADSCORE.EXPIRED})</span>
                            }
                            <span >{item.description} </span></div>

                    </div>;
                } else {
                    return <div >
                        <div style={{ display: 'inline-block', width: '20%', borderTop: '1px solid #ddd', verticalAlign: 'top', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px' }}><span > {createDateObj} </span></div>
                        <div style={{ display: 'inline-block', width: '20%', borderTop: '1px solid #ddd', verticalAlign: 'top', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px' }}>
                            <span >
                                {
                                    isValidParam(getLocalizedStrings().label.AUDIT_LEADSCORE[item.actionLabel]) ?
                                        getLocalizedStrings().label.AUDIT_LEADSCORE[item.actionLabel] :
                                        item.actionLabel
                                }
                            </span>
                        </div>
                        <div style={{ display: 'inline-block', width: '20%', borderTop: '1px solid #ddd', verticalAlign: 'top', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px' }}><span > {item.operator + item.score} </span></div>
                        <div style={{ display: 'inline-block', width: '40%', borderTop: '1px solid #ddd', verticalAlign: 'top', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px' }}>
                            {item.reverse !== null && item.reverse !== undefined && item.reverse === 1 &&
                                <span style={{ color: 'red' }}>({getLocalizedStrings().label.AUDIT_LEADSCORE.EXPIRED})</span>
                            }
                            <span > {item.description} </span></div>

                    </div>;
                }
            }
            )
        } else {
            return <div style={{ display: 'inline-block', width: '100%', paddingTop: '10px', borderTop: '1px solid #ddd', verticalAlign: 'top', textAlign: 'center' }}><span >{getLocalizedStrings().message.AUDIT_LEADSCORE.NO_SCORE_HISTORY}</span></div>
        }
    }

    if (mounted) {
        let header = "";
        let body = "";
        let actionType = appContainer.drawerProps.actionType;
        let style = { height: actionType === constants.AUDIT ?'' : '80%'};
        if (actionType === constants.AUDIT) {
            header = populateAuditTrailHeader();
            body = populateAuditTrailBody();
        }
        else if (actionType === constants.LEAD_SCORE_HISTORY) {
            let scoreDetailsArr = getArrayParam(dataArray.ARR_SCORE_DETAILS);
            if ((data.lead_score !== 0 || data.lead_score === 0) && scoreDetailsArr.length > 0) {
                header = populateLeadScoreHistoryHeader();
                body = populateLeadScoreHistoryBody();
            } else {
                body = getLocalizedStrings().message.AUDIT_LEADSCORE.NO_SCORE_HISTORY;
                style = { overflowY: 'auto', overflowX: 'hidden', textAlign: 'center' };
            }
        }
        return (

            <div style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px', paddingTop: '20px' }} >
                {body && <div style={{overflow: 'hidden', height: actionType === constants.AUDIT ?'' : '80%'}}>  <div>{header}</div>
                    <div style={style}>{body}</div></div>}
            </div>

        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }
}

export default AuditTrailAndLeadScore;
