import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {styles} from '../../../services/constants/styles';
import {constants} from '../../../services/constants/constants';
import {getBooleanParam, isValidParam} from '../../../services/helper/parameterVerifier';
import AssignmentRuleSetup from '../components/assignmentRuleSetup';
import AssignmentRuleList from '../components/assignmentRuleList';
import ShowCircularProgress from '../components/circularProgress';

const AssignmentRules = ({data}) => {

    const dispatch = useDispatch();

    const [stateData, setStateData] = useState(null);
    const [isMounted, setIsMounted] = useState(false);
    const [refreshListData, setRefreshListData] = useState(false);
    const [refreshSetup, setRefreshSetup] = useState(false);
    const [showSetup, setShowSetup] = useState(false);
    const [hideList, setHideList] = useState(false);

    useEffect(() => {
        setStateData(data);
        setIsMounted(true);
    }, [data]);

    const loadAssignmentRule = (id) => {
        try {
            if (isValidParam(stateData)) {
                let tempData = {...stateData};

                tempData.id = id;
                tempData.mode = 'edit';

                setRefreshSetup(!refreshSetup);
                setStateData(tempData);
                setShowSetup(true);

            }
        } catch (error) {
            console.error("Error in 'AssignmentRuleList.js -> loadAssignmentRule()':" + error);
        }

    }

    const refreshDataTable = () => {
        setRefreshListData(!refreshListData);
    }

    const addRule = (action) => {
        try {
            if (isValidParam(stateData)) {
                let tempRefreshSetup = refreshSetup;
                let tempData = stateData;
                let tempShowSetup = showSetup;
                let tempHideList = hideList;

                tempRefreshSetup = !refreshSetup;
                tempData.id = null;
                tempData.mode = constants.NEW;
                if (action !== 'delete') {
                    tempShowSetup = true;
                }

                if (action === 'delete-no-records') {
                    tempShowSetup = false;
                }

                if (action === 'add-no-records') {
                    tempHideList = true;
                } else {
                    tempHideList = false;
                }
                setRefreshSetup(tempRefreshSetup);
                setStateData(tempData);
                setHideList(tempHideList);
                setShowSetup(tempShowSetup);
            }
        } catch (error) {
            console.error("Error in 'AssignmentRuleList.js -> addRule()':" + error);
        }

    }

    const closeSetUP = () => {
        setShowSetup(false);
        setHideList(false);
    }

    let show_setup = getBooleanParam(showSetup);
    let hide_list = getBooleanParam(hideList);
    let class_name_left = 'col-sm-12';
    let class_name_right = 'col-sm-7';
    if (show_setup) {
        class_name_left = 'col-sm-5';
    }

    if (hide_list) {
        class_name_right = 'col-sm-12';
    }

    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2;

    return (

        isMounted ?
            <div style={{paddingLeft: '26px', paddingRight: '0px', marginTop: '0px', width: '98%'}}>
                <div className="row">
                    {!hideList &&
                        <div className={class_name_left}>
                            <AssignmentRuleList
                                data={stateData}
                                loadAssignmentRule={(id) => loadAssignmentRule(id)}
                                refreshListData={refreshListData}
                                addRule={(action) => addRule(action)}
                            />
                        </div>
                    }
                    {show_setup &&
                        <div className={class_name_right}>
                            <AssignmentRuleSetup
                                data={stateData}
                                refreshSetup={refreshSetup}
                                refreshDataTable={() => refreshDataTable()}
                                addRule={(action) => addRule(action)}
                                closeSetup={() => closeSetUP()}/>
                        </div>
                    }
                </div>
            </div>
            :
            <div style={{width: '100%', height: contentHeight}}>
                <div className="asset-loaderh" style={{paddingTop: top, paddingLeft: '48%'}}>
                    <div style={{...styles.assetLoaderContainer, height: 50, width: 50, padding: 7}}>
                        <ShowCircularProgress size={30} style={{marginTop: '3', marginLeft: '3'}}/>
                    </div>
                </div>
            </div>
    );

}

export default AssignmentRules;

