import React from 'react';
import { isValidParam, getStringParam, getObjectParam, getBooleanParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import Button from '@mui/material/Button';
import SFDataTable from '../components/sfDataTable';
import ShowCircularProgress from '../components/circularProgress';
import { constants } from '../../../services/constants/constants';
import { sortArrayObjectByProperty, dateFormatFlatPicker, dateFormat } from '../../../services/helper/utils';
import moment from 'moment';
import SMSReportListView from '../components/SMSReportListView';
import { getActiveTab, updateTabInfo } from '../../../services/helper/sfTabManager';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { setHeader } from '../../../services/actions/headerActions';
import PopOver from '../components/PopOver';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const optionvalue = {
    td: 'Today',
    yd: 'Yesterday',
    tw: 'This Week',
    tm: 'This Month',
    tq: 'This Quarter',
    ty: 'This Year',
    lw: 'Last Week',
    lm: 'Last Month',
    lq: 'Last Quarter',
    ly: 'Last Year',
    cust: 'Custom'
};

function formatDate(date) {
    if (isValidParam(date)) {
        let d = date instanceof Date ? date : new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }
    return null;
}



const SMSReport = ({ key, object }) => {
    const dispatch = useDispatch();
    const [dueBy, setDueBy] = useState("tm");
    const [user_names, setUser_names] = useState('');
    const [user_id, setUser_id] = useState(-99);
    const [from_date, setFrom_date] = useState(null);
    const [to_date, setTo_date] = useState(null);
    const [reportParams, setReportParams] = useState({
        due_by: 'tm',
        from_date: null,
        to_date: null,
        user_names: 'All',
        user_id: -99
    },);
    const [isMounted, setIsMounted] = useState(false);
    const [fields, setFields] = useState([]);
    const [records, setRecords] = useState([]);
    const [rowSort, setRowSort] = useState({
        sortFieldName: 'sales_rep',
        isDescending: false,
        onSort: (fieldName, isDescending, rows) => onSort(fieldName, isDescending, rows)
    },);
    const [customTimeLineDisplay, setCustomTimeLineDisplay] = useState('none');
    const [isPanelVisible, setIsPanelVisible] = useState(false);
    const [tab, setTab] = useState({});
    const [isFieldName, setIsFieldName] = useState(false);
    const [anchorEl, setAnchorEl] = useState('');
    const [tempname, setTempname] = useState('');


    const appState = useSelector((state) => state.app);
    const report = useSelector((state) => state.report);
    const userlist = useSelector((state) => state.userlist);


    useEffect(() => {
        report.showProgressBar = false;
        reloadReport();
        dispatch(setHeader(object, getLocalizedStrings().label.REPORT['SMS Report'], null, false, true));

    }, []);

    const onSort = (fieldName, isDescending, records) => {
        let tempRowSort = rowSort;
        let tempRecords = getArrayParam(records);
        let sortFieldName = getStringParam(rowSort.sortFieldName);
        let activeTab = getActiveTab();
        let info = getObjectParam(activeTab.info);
        try {
            if (!getBooleanParam(tempRowSort.isDescending)) {
                sortArrayObjectByPropertyDesc(tempRecords, sortFieldName);
                tempRowSort.isDescending = isDescending;//true;
            }
            else {
                sortArrayObjectByProperty(tempRecords, sortFieldName);
                tempRowSort.isDescending = isDescending;//false;
            }
        }
        catch (error) {
            console.error("Error in 'SMSReport.js -> onSort()':" + error);
        }
        info.isDescending = tempRowSort.isDescending;
        updateTabInfo(info);
        setRowSort(tempRowSort);
    }

    const getMultilingualOptionsMap = () => {
        let optionsMultilingual = {
            'Today': getLocalizedStrings().label.DASHBOARD.TODAY,
            'Yesterday': getLocalizedStrings().label.DASHBOARD.YESTERDAY,
            'This Week': getLocalizedStrings().label.DASHBOARD.THIS_WEEK,
            'This Month': getLocalizedStrings().label.DASHBOARD.THIS_MONTH,
            'This Quarter': getLocalizedStrings().label.DASHBOARD.THIS_QUARTER,
            'This Year': getLocalizedStrings().label.DASHBOARD.THIS_YEAR,
            'Last Week': getLocalizedStrings().label.DASHBOARD.LAST_WEEK,
            'Last Month': getLocalizedStrings().label.DASHBOARD.LAST_MONTH,
            'Last Quarter': getLocalizedStrings().label.DASHBOARD.LAST_QUARTER,
            'Last Year': getLocalizedStrings().label.DASHBOARD.LAST_YEAR,
            'Custom': getLocalizedStrings().label.DASHBOARD.CUSTOM
        };
        return optionsMultilingual;
    }

    const setReportParamsData = () => {
        let tempdueBy = getStringParam(dueBy);
        let fromDate = from_date;
        let toDate = to_date;
        let tempUser_id = user_id;
        let tempUser_names = user_names;
        let params = {
            due_by: tempdueBy,
            from_date: formatDate(fromDate),
            to_date: formatDate(toDate),
            user_names: tempUser_names,
            user_id: tempUser_id,
            t_ype: constants.SMS
        };
        setReportParams({ ...params });
        return params;
    }

    const sortArrayObjectByPropertyDesc = (array, property) => {
        let temp;
        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];
                    val1 = val1 instanceof String ? val1.toLowerCase() : val1;
                    val2 = val2 instanceof String ? val2.toLowerCase() : val2;
                    if (val1 > val2) {
                        temp = -1;
                    } else if (val1 < val2) {
                        temp = 1;
                    } else {
                        temp = 0;
                    }
                }
                return temp;
            });
        }
    }


    const getFieldList = () => {
        let columnHeaderList = [
            { field_type: "integer", is_date: false, is_hidden: true, is_detail_link: false, is_sql_col: false, label: "userid", name: "user_id", style: { textAlign: 'center' } },
            { field_type: "text", is_date: false, is_hidden: false, is_detail_link: false, is_sql_col: false, label: "User", name: "sales_rep" },
            { field_type: "integer", is_date: false, is_hidden: false, is_detail_link: true, is_sql_col: false, label: "Sent", name: "sent", style: { textAlign: 'center' } },
            { field_type: "integer", is_date: false, is_hidden: false, is_detail_link: true, is_sql_col: false, label: "Delivered", name: "delivered", style: { textAlign: 'center' } },
            { field_type: "integer", is_date: false, is_hidden: false, is_detail_link: true, is_sql_col: false, label: "Replied", name: "reply", style: { textAlign: 'center' } },
            { field_type: "integer", is_date: false, is_hidden: false, is_detail_link: true, is_sql_col: false, label: "Failed", name: "fail", style: { textAlign: 'center' } },
        ]
        return columnHeaderList;
    }

    const reloadReport = () => {
        let params = setReportParamsData();
        let temprowSort = getObjectParam(rowSort);
        let records = null;
        let fields = getFieldList();
        let url = endPoints.SMS_REPORT.SMS_REPORT_GET;
        try {
            setIsMounted(false);
            temprowSort.isDescending = false;
            let promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    records = getArrayParam(response);
                    sortArrayObjectByProperty(records, "sales_rep");
                    setIsMounted(true);
                    setFields(fields);
                    setRecords(records);
                    setRowSort({ ...temprowSort });
                    setIsPanelVisible(false);
                }
            });
        } catch (error) {
            console.log("Error in 'SMSReport.js -> reloadReport()':" + error)
        }
    }

    const excelGenerateRows = () => {
        let rows = formatData(records);
        let columns = getArrayParam(fields);
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            rows.forEach((rowObject, rowIndex) => {
                let innerCells = '';
                columns.forEach((columnObject, colIndex) => {
                    if (!columnObject.is_hidden) {
                        innerCells = innerCells + '<td >' + rowObject[columnObject.name] + ' </td>';
                    }
                })
                if (rowIndex % 2 === 0) {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                } else {
                    cells = cells + ('<tr  bgcolor="Lavender">' + innerCells + ' </tr>');
                }
            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
        }
        return rowData;

    }

    const excelGenerateHeaders = () => {
        let cells = '';
        let columns = fields.filter(f => {
            let tempData;
            if (!f.is_hidden) {
                // return f;
                tempData = f;
            }
            return tempData;
        });
        columns.forEach(f => {
            cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    const excelGenerateBeforeHeaders = () => {
        let optionsMultilingual = getMultilingualOptionsMap();
        let tempreportParams = getObjectParam(reportParams);
        let cells = '';
        let dueBy = '';
        let reportName = getStringParam(getLocalizedStrings().label.SMS_REPORT.SMS_REPORT_LABEL);
        let columns = fields.filter(f => {
            let temp;
            if (!f.is_hidden) {
                temp = f;
            }
            return temp;
        });
        let columnsLength = getArrayParam(columns).length;

        cells = cells + '<tr><td  colspan="' + (columnsLength) + '"> ' + reportName + ' </td></tr>';
        if (reportName.length > 0) {
            dueBy = getStringParam(optionsMultilingual[optionvalue[tempreportParams.due_by]]);
            cells = cells + '<tr><td> Date :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + dueBy + ' </td></tr>';
        }
        return cells;
    }

    const downloadExcel = () => {

        let rows = getArrayParam(records);
        if (rows.length > 0) {
            let beforeHeaderComponent = excelGenerateBeforeHeaders();
            let headerComponents = excelGenerateHeaders();
            let rowComponents = excelGenerateRows();
            let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '</tbody></table>';
            let htmlData = htmlTable;
            if (isValidParam(htmlData) && htmlData !== '') {
                htmlData = htmlData.replace("%22", "\"");
                var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
                var downloadUrl = URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = downloadUrl;
                a.download = 'SMSReportResultSet_' + new Date().getTime() + '.xls';
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        } else {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.NO_RECORD_EXPORT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }

    }

    const getButtonSet = () => {
        return (
            <div>
                <iframe id="ifmcontentstoprint" title="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute', display: 'none' }}></iframe>
                <Button
                    onClick={() => reloadReport()}


                    style={{
                        ...styles.primaryButton,
                        minWidth: '40px',
                        paddingLeft: '17px',
                        paddingRight: '17px'
                    }}
                    title={getLocalizedStrings().label.REPORT.GO}
                > {getLocalizedStrings().label.REPORT.GO}</Button>
                <Button


                    onClick={() => {
                        var content = document.getElementById("divcontents");
                        var ifrmae = document.getElementById("ifmcontentstoprint");
                        ifrmae.style.display = 'block'
                        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                        ifrmae.style.display = 'block'

                        pri.document.open();
                        pri.document.write(

                            content.innerHTML);
                        pri.document.close();
                        pri.focus();
                        pri.print();
                        ifrmae.style.display = 'none'
                    }}

                    style={{
                        ...styles.secondaryButton,
                        minWidth: '40px'
                    }}
                    title={getLocalizedStrings().label.REPORT.PRINT}
                > {getLocalizedStrings().label.REPORT.PRINT}</Button>
                <Button
                    startIcon={<i className="far fa-file-excel" style={{ fontSize: '20px', color: 'green' }} />}
                    onClick={() => { downloadExcel(); }}

                    style={{
                        ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                        color: '#717171',
                        border: '0px',
                        minWidth: '30px'
                    }}
                    title="Excel"
                ></Button>
            </div>
        );
    }

    const handlePopover = (fieldName, event) => {
        try {
            setIsFieldName(true);
            setAnchorEl(event.currentTarget);
        }
        catch (error) {
            console.error("Error in 'SMSReport.js -> handlePopover()':" + error);
        }
    }

    const handleRequestClose = (fieldName) => {
        try {

            setIsFieldName(false);
        }
        catch (error) {
            console.error("Error in 'SMSReport.js -> handleRequestClose()':" + error);
        }
    };

    const handleDropDownChange = (name, event, value) => {
        try {
            value = value.props.value;
            if (name === "due_by") {
                setDueBy(value);
                if (value === "cust") {
                    let tempcustomTimeLineDisplay = 'block';
                    setCustomTimeLineDisplay(tempcustomTimeLineDisplay);
                    let tempDate = moment(String(new Date())).format(dateFormat[appState.me.date_format]);
                    setFrom_date(tempDate);
                    setTo_date(tempDate);
                    return;
                } else {
                    setCustomTimeLineDisplay('none');
                    setFrom_date(null);
                    setTo_date(null);
                    handleRequestClose(name + "_popup");
                }
            }
            else if (name === "user_names") {

                if (value === -99) {
                    setUser_id(value);
                    setUser_names("All");
                } else {
                    let user = userlist.data.filter((f) => {
                        return f.id === value;
                    });
                    let tempUser_id = user[0].id;
                    setUser_id(tempUser_id);
                    let tempUser_names = user[0].value;
                    setUser_names(tempUser_names);
                }
                handleRequestClose(name + "_popup");
            }
            else {
                handleRequestClose(name + "_popup");
            }
        }
        catch (error) {
            console.error("Error in 'SMSReport.js -> handleDropDownChange()':" + error);
        }
    }

    const generateDateSelectionField = () => {
        let optionsMultilingual = getMultilingualOptionsMap();
        try {
            return Object.keys(optionvalue).map((itemKey) => {
                return <MenuItem key={itemKey} value={itemKey} style={styles.popoverMenuItem}>{optionsMultilingual[optionvalue[itemKey]]}</MenuItem>;
            });
        }
        catch (error) {
            console.error("Error in 'SMSReport.js -> generateDateSelectionField()':" + error);
        }
    }

    const handleDateChange = (date, name) => {
        setTempname(date);
    }

    const generateUserSelectionFieldList = () => {
        let usernamesList = [];
        try {
            let tempuserlist = userlist.data;
            let isContain = tempuserlist.filter((e => e.label === "All")).length > 0 ? true : false;
            if (isContain) {
                tempuserlist.pop();
            }
            usernamesList = userlist.data.map((user, index) => {
                return <MenuItem value={user.id} style={styles.popoverMenuItem}>{user.value}</MenuItem>;
            });
            usernamesList.unshift(<MenuItem value={-99} style={styles.popoverMenuItem}>All</MenuItem>);
        }
        catch (error) {
            console.error("Error in 'SMSReport.js -> generateUserSelectionFieldList()':" + error);
        }
        return usernamesList;
    }

    const formatData = (_records) => {
        let newRecordSet = [];
        try {
            _records = getArrayParam(records);
            _records.forEach(r => {

                _records = { ...r, delivered_value: r.delivered, reply_value: r.reply, sent_value: r.sent, fail_value: r.fail };
                newRecordSet.push(_records);
            });
        } catch (error) {
            console.error("Error in 'SMSReport.js -> formatData()':" + error);
        }
        return newRecordSet;
    }

    const getNoRecordContent = () => {
        return (
            <div style={{ display: 'flex', backgroundColor: 'white', borderBottom: '1px solid #D3D3D3' }}>
                <span style={{ color: 'red', margin: '5px 0 5px 8px', fontSize: '14px' }}>{getLocalizedStrings().label.INXPRESS_REPORT.NO_RECORDS_FOUND}</span>
            </div>
        );
    }

    const getReportFilter = () => {
        let buttonSet = getButtonSet();

        let HHFormat = new RegExp("HH");
        let datePickerOptions = {
            enableTime: false,
            dateFormat: dateFormatFlatPicker[appState.me.date_format],
            minuteIncrement: 1,
            static: true,
            time_24hr: HHFormat.test(appState.me.date_format) ? true : false
        };
        return (
            <div className="row" style={{ padding: '0px' }} >
                <div >
                    <Grid container spacing={0}>
                        <Grid item xs={12} style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', marginLeft: "17px" }}>
                                <div style={{ padding: 0 }} >
                                    <div
                                        style={{ width: '100%', fontSize: '14px', color: '#717171', height: '47px' }}
                                        id='due_by'
                                        key='due_by'
                                    >
                                        <FormControl variant="outlined" size="small">
                                            <InputLabel id="sf-sms-report-sffromfields-simple-select-outlined-label" className="sf-sms-report-result">{getLocalizedStrings().label.REPORT.DATE_RANGE}</InputLabel>
                                            <Select
                                                labelId="sf-sms-report-sffromfields-simple-select-outlined-label"
                                                id="sf-sms-report-simple-select-outlined"
                                                className="sf-sms-report-from-fields"
                                                value={dueBy}
                                                onChange={(event, value) => handleDropDownChange('due_by', event, value)}
                                                label={getLocalizedStrings().label.REPORT.DATE_RANGE}
                                                style={{ minWidth: 250, maxWidth: 250 }}
                                            >
                                                {generateDateSelectionField()}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div style={{ display: customTimeLineDisplay, }}>

                                    <div style={{ marginRight: '7px', marginLeft: '7px' }}>
                                        <PopOver
                                            id={'from_date'}
                                            key={'from_date'}
                                            name={'from_date'}
                                            btnType={'date'}
                                            buttonStyle={{ fontSize: '16px' }}
                                            containerStyle={{ lineHeight: 1, marginBottom: '20px' }}
                                            buttonLabel={getLocalizedStrings().label.REPORT.START_DATE}
                                            onChange={(e) => setFrom_date(e)}
                                            value={from_date}
                                            innerLabelStyle={{ padding: "8 5 2 5", fontSize: '15px' }}
                                            height={40}
                                            options={datePickerOptions}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: customTimeLineDisplay }}>

                                    <div style={{ marginRight: '7px', marginLeft: '7px' }}>
                                        <PopOver
                                            id={'to_date'}
                                            key={'to_date'}
                                            name={'to_date'}
                                            btnType={'date'}
                                            buttonStyle={{ fontSize: '16px' }}
                                            containerStyle={{ lineHeight: 1, marginBottom: '20px' }}
                                            buttonLabel={getLocalizedStrings().label.REPORT.END_DATE}
                                            onChange={(e) => setTo_date(e)}
                                            value={to_date}
                                            innerLabelStyle={{ padding: "8 5 2 5", fontSize: '15px' }}
                                            height={40}
                                            options={{ ...datePickerOptions, minDate: from_date }}
                                        />
                                    </div>
                                </div>
                                {/* username dropdown */}
                                <div style={{ paddingLeft: 0 }} >
                                    <div
                                        style={{ width: '100%', fontSize: '14px', color: '#717171', height: '47px', marginLeft: "7px" }}
                                        onClick={(event) => handlePopover(event, 'user_names_popup')}
                                        id='user_names'
                                        key='user_names'
                                    >
                                        <FormControl variant="outlined" size="small">
                                            <InputLabel id="sf-sms-report-from-smsreport-simple-select-outlined-labedeml" className="sf-sms-report-from-smsreport">{getLocalizedStrings().label.REPORT.SALES_REP}</InputLabel>
                                            <Select
                                                labelId="sf-sms-report-from-smsreport-simple-select-outlined-label"
                                                id="sf-smsreport-simple-select-outlined"
                                                value={user_id}
                                                onChange={(event, value) => handleDropDownChange('user_names', event, value)}
                                                label={getLocalizedStrings().label.REPORT.SALES_REP}
                                                className={"sf-fields-bg"}
                                                style={{ minWidth: 250, maxWidth: 250 }}
                                            >
                                                {generateUserSelectionFieldList()}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: '8px', marginLeft: '7px' }}>{buttonSet}</div>

                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    const getReportHeaderName = () => {
        let component = null;
        component = (
            <div className="col-md-12">
                <div className="row" style={{ paddingLeft: "2px" }}>
                    <span className="text" style={{ margin: '0px', fontSize: '22px' }}><h3 style={{ fontWeight: 'bold' }}>{getLocalizedStrings().label.SMS_REPORT.SMS_REPORT_LABEL}</h3></span>
                </div>
            </div>)
        return component;
    }

    const customEvent = (eventType, column, row, event) => {
        try {
            if (row[column.name + '_value'] > 0) {
                let label = getStringParam(getLocalizedStrings().label.SMS_REPORT.SMS_REPORT_LABEL);
                let object = constants.SMS_OBJECT;
                let reportLabel = getStringParam(row.sales_rep) + ' - ' + getStringParam(column.label);
                let fromDate = from_date;
                let toDate = to_date;
                let temptab = getObjectParam(tab);
                temptab.label = label;
                temptab.object = object;
                temptab.isReport = true;
                temptab.reportLabel = reportLabel;
                temptab.input_param = {};
                temptab.input_param.user_id = row.user_id;
                temptab.input_param.due_by = reportParams.due_by;
                temptab.input_param.from_date = formatDate(fromDate);
                temptab.input_param.to_date = formatDate(toDate);
                temptab.input_param.column_name = column.name;
                temptab.input_param.status = reportLabel;
                temptab.input_param.reportLabel = reportLabel;
                if (temptab.input_param.report_due_by === 'cust') {
                    temptab.input_param.report_from_date = getStringParam(reportParams.from_date);
                    temptab.input_param.report_to_date = getStringParam(reportParams.to_date);
                }
                setIsPanelVisible(true);
                setTab({ ...temptab });
            } else {
                setIsPanelVisible(false);
                setTab({});
            }

        } catch (error) {
            console.error("Error in 'SmsReport.js -> customEvent()':" + error);
        }
    }

    const closePanel = () => {
        setIsPanelVisible(false);
        setTab({});
    }

    const generateSfDataTable = (records) => {
        let html = "";
        if (fields.length > 0 && records.length > 0 && rowSort !== null) {
            html = (
                <SFDataTable columns={fields} rowSort={rowSort} rows={records} customEvent={(eventType, column, row, event) => customEvent(eventType, column, row, event)} />
            );
        }
        return html;
    }
    let customReportStyle = null;
    let style = { margin: 10, width: '98%', paddingTop: 0, paddingLeft: 11 };
    style = { ...style, ...getObjectParam(style) };
    let temprecords = formatData(records);
    let noRecordsTitle = getNoRecordContent()
    let headerComponent = getReportFilter();
    let reportHeaderName = getReportHeaderName();
    let reportListViewData = getObjectParam(tab);
    let customPanelStyle = null;
    let tempIsPanelVisible = getBooleanParam(isPanelVisible);
    if (tempIsPanelVisible) {
        customReportStyle = {};
        customPanelStyle = {};
        customReportStyle.width = '56%';
        customReportStyle.float = 'left';
        customPanelStyle.width = '40%';
        customPanelStyle.float = 'right';
        customPanelStyle.marginLeft = '2%';
        customPanelStyle.marginRight = '2%';
        customPanelStyle.marginTop = '-52px';
    }
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2
    return (
        <div style={style}>
            <div>
                {headerComponent}
                {reportHeaderName}
            </div>
            {isMounted ?
                <div id="divcontents" style={{ marginLeft: '15px' }}>
                    {temprecords.length > 0 ?
                        <div>
                            <div style={customReportStyle}>
                                {generateSfDataTable(temprecords)}
                            </div>
                            {tempIsPanelVisible ?
                                <div key={tab.reportLabel} style={customPanelStyle}>
                                    <SMSReportListView info={reportListViewData} closePanel={() => closePanel()} />
                                </div> :
                                null}
                        </div> :
                        noRecordsTitle
                    }
                </div>
                :
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default SMSReport;
