import { actions } from '..//constants/actions'
const salesTemplate = {   
    data: null
}

const salesTemplateReducer = (state = salesTemplate, action ) => {
    
    switch (action.type) {
        case actions.SALES_TEMPLATE_GET_FULFILLED:
            return {
                ...state,
                data: action.payload
            }
        default:
            return state;
    }
}

export default salesTemplateReducer;