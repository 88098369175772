import React from 'react';
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import * as ParameterVerifier from '../../../services/helper/parameterVerifier';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';

const styles = {

    labelFocused: {},
    root: {
        minWidth: 275,
    },
    button: {
        backgroundColor: '#34a853',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#34a853',
            color: '#fff',
        },
    },
    title: {
        fontSize: 14,
    },
};


const ThumbSurvey = ({ match }) => {
    let params = useParams();
    const location = useLocation();
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    document.title = 'Thumb Survey'
    const [isThumbsurvey, setIsThumbsurvey] = useState(false);
    const [comment, setComment] = useState("");
    const [companyLogo, setCompanyLogo] = useState(null);
    const [isSendDisabled, setIsSendDisabled] = useState(false);
    const [reviewWebsiteUrl, setReviewWebsiteUrl] = useState(null);
    const [reviewWebsiteName, setReviewWebsiteName] = useState(null);
    const [mounted, setMounted] = useState(false);
    const [thumbsUpTitle, setThumbsUpTitle] = useState(null);
    const [thumbsUpDesc, setThumbsUpDesc] = useState(null);
    const [thumbsUpYesBtn, setThumbsUpYesBtn] = useState(null);
    const [thumbsUpNoBtn, setThumbsUpNoBtn] = useState(null);

    useEffect(() => {
        let _reviewWebsiteName = null;
        let _reviewWebsiteUrl = null;
        let _thumbsUpTitle = null;
        let _thumbsUpDesc = null;
        let _thumbsUpYesBtn = null;
        let _thumbsUpNoBtn = null;
        let ipParams = { "encodedParams": params.eKey }
        var promise = Promise.resolve(HTTPClient.getFullResponseWithoutToken(endPoints.SURVEY_RESPONSE.COMPANY_LOGO, ipParams));
        promise.then((response) => {
            if (ParameterVerifier.isValidParam(response)) {
                if (response.status === 0) {
                    let _companyLogo = response.data.logoUrl;
                    if (response.data.hasOwnProperty("reviewWebsite")) {
                        _reviewWebsiteName = response.data.reviewWebsite;
                    }
                    if (response.data.hasOwnProperty("reviewWebsiteUrl")) {
                        _reviewWebsiteUrl = response.data.reviewWebsiteUrl;
                    }
                    if (response.data.hasOwnProperty("thumbsUpTitle")) {
                        _thumbsUpTitle = response.data.thumbsUpTitle;
                    }
                    if (response.data.hasOwnProperty("thumbsUpDesc")) {
                        _thumbsUpDesc = response.data.thumbsUpDesc;
                    }
                    if (response.data.hasOwnProperty("thumbsUpYesBtn")) {
                        _thumbsUpYesBtn = response.data.thumbsUpYesBtn;
                    }
                    if (response.data.hasOwnProperty("thumbsUpNoBtn")) {
                        _thumbsUpNoBtn = response.data.thumbsUpNoBtn;
                    }

                    if (_companyLogo === null || _companyLogo === '') {
                        _companyLogo = "/asset/images/soffront_logo.png";
                    }

                    if (params.tType === "up" && _reviewWebsiteName === null && _reviewWebsiteUrl === null && _thumbsUpTitle === null && _thumbsUpDesc === null && _thumbsUpYesBtn === null && _thumbsUpNoBtn === null) {
                        send();
                    }

                    setCompanyLogo(_companyLogo);
                    setReviewWebsiteName(_reviewWebsiteName);
                    setReviewWebsiteUrl(_reviewWebsiteUrl);
                    setThumbsUpTitle(_thumbsUpTitle);
                    setThumbsUpDesc(_thumbsUpDesc);
                    setThumbsUpYesBtn(_thumbsUpYesBtn);
                    setThumbsUpNoBtn(_thumbsUpNoBtn);
                    setMounted(true);

                }
            }
        })
    }, []);

    const send = () => {
        if (!isSendDisabled) {
            setIsSendDisabled(true);
            let surveyResponse = params.tType;
            surveyResponse = surveyResponse === "up" ? "Thumbs Up" : "Thumbs Down";
            let ipParams = { "encodedParams": params.eKey, 'response': surveyResponse, 'comment': comment }
            var promise = Promise.resolve(HTTPClient.postWithoutToken(endPoints.SURVEY_RESPONSE.THUMB_SURVEY, ipParams));
            promise.then((response) => {
                if (ParameterVerifier.isValidParam(response)) {
                    if (response.status === 0) {
                        if (params.tType === "up" && reviewWebsiteName === null && reviewWebsiteUrl === null && thumbsUpTitle === null && thumbsUpDesc === null && thumbsUpYesBtn === null && thumbsUpNoBtn === null) {

                        } else {
                            setIsThumbsurvey(true);
                        }

                    }
                }
            })

        }
    }
    const redirectUrl = () => {
        let _reviewWebsiteUrl = reviewWebsiteUrl;
        saveResponse();
        if (_reviewWebsiteUrl.indexOf("https://") === 0 || _reviewWebsiteUrl.indexOf("http://") === 0) {
            window.open(_reviewWebsiteUrl, "_blank");
        } else {
            _reviewWebsiteUrl = "https://" + _reviewWebsiteUrl;
            window.open(_reviewWebsiteUrl, "_blank");
        }
    }
    const saveResponse = (callfrom) => {
        let surveyResponse = params.tType;
        surveyResponse = surveyResponse === "up" ? "Thumbs Up" : "Thumbs Down";
        let ipParams = { "encodedParams": params.eKey, 'response': surveyResponse, 'comment': comment }
        var promise = Promise.resolve(HTTPClient.postWithoutToken(endPoints.SURVEY_RESPONSE.THUMB_SURVEY, ipParams));
        promise.then((response) => {
            if (ParameterVerifier.isValidParam(response)) {
                if (response.status === 0) {
      if(callfrom !== undefined && callfrom == 'noBtn'){
        window.open(detectBrowser, "_self");
        window.close();
      }
                }
            }
        })
    }
    const windowClose = (callfrom) => {
        saveResponse(callfrom);
    }
    const detectBrowser = () => {
        if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
            return 'https://www.opera.com/';
        } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
            return 'https://google.com';
        } else if (navigator.userAgent.indexOf("Safari") !== -1) {
            return 'https://www.safari.com/';
        } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
            return 'https://www.mozilla.org/en-US/firefox/';
        } else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) {
            return 'IE';//crap
        } else {
            return 'Unknown';
        }
    }

    let height = window.innerHeight - 350;
	let top = (height - 10) / 2;
    return (
        <div>
            {mounted &&
                <div className='abc' style={{ width: '450px', marginTop: top, marginLeft: '32%' }}>

                    {(params.tType === "up" && reviewWebsiteUrl !== null && reviewWebsiteName !== null && thumbsUpTitle === null && thumbsUpDesc === null && thumbsUpYesBtn === null && thumbsUpNoBtn === null && !isThumbsurvey) ?
                        <Box boxShadow={3} className='sf-login-box'>
                            <Card className={'c'} style={{ height: '350px' }}>
                                <div style={{ marginTop: '10%', marginLeft: '10px' }}>
                                    <Typography className='sf-login-Typography' color="textSecondary" align="center" style={{ fontSize: 30 }}>
                                        < img src={companyLogo} alt='' style={{ maxWidth: '130px', maxHeight: '40px' }} />  </Typography> </div>

                                <CardContent style={{ marginTop: '-10px', marginLeft: '10px' }}>
                                    <Typography className='sf-login-Typography' color="textSecondary" align="center" style={{ fontSize: 30 }}>
                                        Thank You!                                               </Typography>
                                    <Typography className='sf-login-Typography' color="textSecondary" align="center" style={{ fontSize: 16 }}>
                                        We appreciate your feedback.
                                    </Typography>
                                    <Typography className='sf-login-Typography' color="textSecondary" gutterBottom align="center" style={{ fontSize: 16, marginTop: '-31px' }}>

                                        If you have a minute, please review us on {reviewWebsiteName}.                                               </Typography>
                                </CardContent>
                                <div style={{ textAlign: 'center', marginLeft: '15px' }}>
                                    <Button variant="contained" align="center" style={{ ...styles.primaryButton, fontSize: 14, marginRight: '15px' }}

                                        color="primary" size="large" onClick={()=>redirectUrl()} ><span style={{ fontSize: 16 }}>Review us on {reviewWebsiteName}</span></Button>
                                </div>
                            </Card>
                        </Box>
                        :

                        ((params.tType === "down" && !isThumbsurvey)) ?
                            <Box boxShadow={3} className='sf-login-box'>
                                <Card className={'c'} style={{ height: '385px' }}>
                                    <div style={{ marginTop: '10%', marginLeft: '10px' }}>
                                        <Typography className='sf-login-Typography' color="textSecondary" align="center" style={{ fontSize: 30 }}>
                                            < img src={companyLogo} alt='' style={{ maxWidth: '130px', maxHeight: '40px' }} />  </Typography> </div>

                                    <CardContent style={{ marginTop: '-10px' }}>
                                        {params.tType === "down" ?
                                            <Typography className='sf-login-Typography' color="textSecondary" align="center" style={{}}>
                                                How Can We Improve?                                               </Typography>
                                            :
                                            <Typography className='sf-login-Typography' color="textSecondary" align="center" style={{ fontSize: 20 }}>
                                                Would you like to leave a comment?      </Typography>
                                        }

                                        <FormControl style={{ width: '85%', marginLeft: '30px' }} className="test" noValidate autoComplete="off">
                                            <TextField
                                                type='textarea'
                                                variant="outlined"
                                                multiline={true}
                                                rows={4}
                                                autoFocus={true}
                                                fullWidth={true}
                                                id="notetext"
                                                textareastyle={{ marginTop: '8px', height: '90%' }}
                                                inputStyle={{ overflowY: 'auto' }}
                                                onChange={(e) => setComment(e.target.value.trim())}
                                                autoComplete="new-password"
                                                className={"sf-fields-bg"}
                                            />
                                        </FormControl>
                                    </CardContent>
                                    <div style={{ textAlign: 'center' }}>
                                        <Button variant="contained" align="center" style={{ ...styles.primaryButton, fontSize: 14 }}
                                            onClick={() => send()}
                                            color="primary" size="large"  ><span style={{ fontSize: 16 }}>Send</span></Button>
                                    </div>
                                </Card>
                            </Box>
                            :
                            (params.tType === "up" && reviewWebsiteName !== null && reviewWebsiteUrl !== null && thumbsUpTitle !== null && thumbsUpDesc !== null && thumbsUpYesBtn !== null && thumbsUpNoBtn !== null) ?
                                <Box boxShadow={3} className='sf-login-box'>
                                    <Card className={'c'} style={{ height: '360px' }}>
                                        <div style={{ marginTop: '10%', marginLeft: '10px' }}>
                                            <Typography className='sf-login-Typography' color="textSecondary" align="center" style={{ fontSize: 30 }}>
                                                < img src={companyLogo} alt='' style={{ maxWidth: '130px', maxHeight: '40px' }} />  </Typography> </div>

                                        <CardContent style={{ marginTop: '-10px', marginLeft: '10px' }}>
                                            <Typography className='sf-login-Typography' color="textSecondary" align="center" style={{ fontSize: 30 }}>
                                                {thumbsUpTitle}                                               </Typography>
                                            <Typography className='sf-login-Typography' color="textSecondary" align="center" style={{ fontSize: 16 }}>
                                                {thumbsUpDesc}
                                            </Typography>

                                        </CardContent>

                                        <div style={{ textAlign: 'center' }}>
                                            <Button className={'c'} variant="contained" style={{ width: '42%', fontSize: 13, marginRight: '15px' }} size="small" onClick={()=>redirectUrl()} title={thumbsUpYesBtn} ><span style={{ fontSize: 16, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textTransform: 'none' }}> {thumbsUpYesBtn}</span></Button>
                                            <Button className={"btn"} variant="contained" style={{ backgroundColor: '#ffffff', fontSize: 13, width: '42%' }}
                                                size="small" onClick={()=>windowClose("noBtn")} title={thumbsUpNoBtn} ><span style={{ fontSize: 16, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',color:'black' }}>{thumbsUpNoBtn}</span></Button>
                                        </div>

                                    </Card>
                                </Box>
                                :
                                (params.tType === "up" && reviewWebsiteName === null && reviewWebsiteUrl === null && reviewWebsiteName === null && reviewWebsiteUrl === null && thumbsUpTitle === null && thumbsUpDesc === null && thumbsUpYesBtn === null && thumbsUpNoBtn === null && !isThumbsurvey) ?
                                    <Box boxShadow={3} className='sf-login-box'>
                                        <Card className={'c'} style={{ height: '250px' }}>
                                            <div style={{ marginTop: '10%', marginLeft: '10px' }}>
                                                <Typography className='sf-login-Typography' color="textSecondary" align="center" style={{ fontSize: 30 }}>
                                                    < img src={companyLogo} alt='' style={{ maxWidth: '130px', maxHeight: '40px' }} />  </Typography> </div>

                                            <div style={{ marginTop: '20px', marginLeft: '65px' }}>
                                                <Typography className='sf-login-Typography' color="textSecondary" gutterBottom align="center" style={{ fontSize: 20, marginLeft: '-14%' }}>
                                                    Thank You for your feedback.
                                                </Typography>
                                            </div>
                                        </Card>
                                    </Box>

                                    :
                                    <Box boxShadow={3} className='sf-login-box' style={{ marginTop: '43%' }}>
                                        <Card className={'c'} style={{ height: '250px' }}>
                                            <div style={{ marginTop: '10%', marginLeft: '10px' }}>
                                                <Typography className='sf-login-Typography' color="textSecondary" align="center" style={{ fontSize: 30 }}>
                                                    < img src={companyLogo} alt='' style={{ maxWidth: '130px', maxHeight: '40px' }} />  </Typography> </div>

                                            <div style={{ marginTop: '20px', marginLeft: '65px' }}>
                                                <Typography className='sf-login-Typography' color="textSecondary" gutterBottom align="center" style={{ fontSize: 20, marginLeft: '-14%' }}>
                                                    Your survey response has been submitted successfully.
                                                </Typography>
                                            </div>
                                        </Card>
                                    </Box>
                        // <Typography className='sf-login-Typography' color="textSecondary" gutterBottom align="center" style={{ fontSize: 16, marginTop: 290, marginLeft: '-14%' }}>
                        //     Your survey response has been submitted successfully.
                        // </Typography>

                    }
                </div>
            }
        </div>
    );
}
export default ThumbSurvey;