import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { isValidParam, getStringParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import RequiredForms from '../components/requiredForms';
import { useSelector } from "react-redux";
import {detailViewIsMounted} from "../../../services/actions/detailViewActions";
import {refreshDetailViewData} from "../../../services/actions/detailViewActions";
import {useDispatch} from "react-redux";

const GreenlightConvert = ({ object, data, closeDrawer, onFieldConvert }) => {
    const app = useSelector((state) => state.app);
	const dispatch = useDispatch();

    const [requiredFields, setRequiredFields] = useState([]);
    const [recordId, setRecordId] = useState(null);
    const [isMount, setIsMount] = useState(false);
    const requiredFormsRefs = useRef(null);


    useEffect(() => {
        setRecordId(data.recordId);
        setRequiredFields(data.requiredFields);
        setIsMount(true);
    }, []);

    const doConvert = () => {
        try {
            if (isValidParam(requiredFormsRefs.current)) {
                let requriredFormInstance = requiredFormsRefs;
                if (isValidParam(requriredFormInstance.current)) {
                    let isvalid = requriredFormInstance.current.saveSFForm(null);
                    if (isvalid) {
                        setIsMount(false);
                        closeDrawer()
                        dispatch(detailViewIsMounted(false));
						dispatch(refreshDetailViewData(true));
                    }
                }
            }
        } catch (error) {
            console.error("error in 'convert.js'-->'doConvert()'" + error);
        }
    }


    if (isMount && requiredFields.length > 0) {
        let parentDetails = {};
        let objectLabel = object;
        let objectList = getArrayParam(app.objectList);
        objectList = objectList.filter(f => f.name === object);
        if (objectList.length > 0) {
            objectLabel = getStringParam(objectList[0].label).toLowerCase();;
        }
        console.log("requiredFields",requiredFields)
        return (
            <>
                <div style={{ height: '100%' }}>
                    <div>
                        <div style={{ padding: '10px 25px 0px 25px', marginTop: '5px', marginBottom: '-10px' }}> {requiredFields.length===1 ? 'This': 'These'} {requiredFields.length===1 ? 'field': 'fields'} {requiredFields.length===1 ? 'needs': 'need'} to be filled in before this {object == constants.ACCOUNTS_OBJECT ? 'account' : objectLabel} can be converted</div>
                        <div style={{marginLeft:'-15px'}}>
                            <RequiredForms
                                ref={requiredFormsRefs}
                                object={object}
                                openMode={constants.SF_FORM_OPEN_MODE_QUICK}
                                recordId={recordId}
                                requiredFields={requiredFields}
                                callFrom={constants.GREENLIGHT}
                                isDetailView={true}
                                parentDetails={parentDetails}
                                onFieldConvert={onFieldConvert}
                            />
                        </div>

                    </div>

                    <div style={{ float: 'right', paddingRight: '50px', paddingBottom: '30px', paddingTop: '15px' }}>
                        <Button
                            key={'convert'}
                            onClick={() => doConvert()}
                            style={styles.primaryButton}
                            title={getLocalizedStrings().label.COMMON.CONVERT}
                        > {getLocalizedStrings().label.COMMON.CONVERT}</Button>
                        <Button
                            key={'convert'}
                            onClick={() => closeDrawer()}
                            style={styles.secondaryButton}
                            title={getLocalizedStrings().label.COMMON.CANCEL}
                        > {getLocalizedStrings().label.COMMON.CANCEL}</Button>
                    </div>
                </div>
            </>
        );
    }
    else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }
};

export default GreenlightConvert;
