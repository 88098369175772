import { actions } from '..//constants/actions'

const reportInitialState = {
    isRedirect: false,
    redirectUrl: '',
    recentReportResponseData: {},
    recentReportParams: {
        recent_record_id: 0, // recentreport Id 
        parameter_id: 0, // Report Id
        item_type: "",
        due_by: "ty",
        user_names: "All",
        from_date: null,
        to_date: null,
        type: [],
        object_name: '',
        selectedIndex: 0

    },
    reportFilterData:{},

    inExpressReportData:{},
    selectIndex: 0,
    reportHeader: [],
    reportObject: '',
    showProgressBar: true,
    selectedReport:null,

    reportSortConfList: [
        {
            report_name: 'Support Matrix',
            sort_field_name: 'Assigned to',
            sort_order: 'asc',
            // label:'Assigned to'
        },
        {
            report_name: 'Customers by Cases',
            sort_field_name: 'Company',
            sort_order: 'asc',
            //  label:'Company'
        },
        {
            report_name: 'Issue Matrix',
            sort_field_name: 'Assigned to',
            sort_order: 'asc',
            //  label:'Assigned To'
        },
        {
            report_name: 'Project Matrix',
            sort_field_name: 'Assigned to',
            sort_order: 'asc',
            //  label:'Assigned To'
        }

    ],
    isLoadReportData: false,
    isShowParentLoading: false


}

const reportReducer = (state = reportInitialState, action) => {
    switch (action.type) {
        case actions.REPORTS_REDUCER_REDIRECTION:
            return {
                ...state,
                isRedirect: action.payload.isRedirect,
                redirectUrl: action.payload.redirectUrl
            }
        case actions.RECENT_REPORT_RESPONSE_DATA:

            return {
                ...state,
                recentReportResponseData: action.payload.reportData,
                recentReportParams: action.payload.reportParams,
                reportHeader: action.payload.reportHeaders,
                selectIndex: action.payload.reportIndex,
                reportObject: action.payload.reportObject,
                showProgressBar: false
            }
        case actions.SET_REPORT_HEADER_DATA:

            return {
                ...state,
                reportHeader: action.payload.reportHeaders,
                reportObject: action.payload.reportObject,
            }

        case actions.SET_REPORT_PARAM_DATA:


            return {
                ...state,
                //  reportParams: action.payload.reportParams,

            }

        case actions.SET_INXPRESS_REPORT_FRANCHISE_LIST:
            return {
                ...state,
                franchiseList: action.payload
            }

        case actions.SET_INXPRESS_REPORT_WORKFLOW_LIST:
            return {
                ...state,
                workflowList: action.payload

            }
        case actions.SET_REPORT_FILTER_DATA:
            return {
                ...state,
                reportFilterData:action.payload,
            }

        case actions.SET_INXPRESS_REPORT_DATA:
            return {
                ...state,
                        inExpressReportData: action.payload,
                       

            }
        default:
            return state;
    }
}

export default reportReducer;