import { actions } from '..//constants/actions'


const listViewInitialSate = {
    mounted: false,
    data: {
        object: ''
    },
    error: null,
    actionsOnSelectedRows: [],
    selectedRows: null,
    queryName: '',
    isSetHeader: false,
    isRefreshDetailViewListView: false,
}

const otherListViewReducer = (state = listViewInitialSate, action) => {
    switch (action.type) {
        case actions.OTHER_LISTVIEW_GET_PENDING:
            return {
                ...state,
                ...listViewInitialSate
            }
        case actions.OTHER_LISTVIEW_GET_FULFILLED:
            return {
                ...state,
                mounted: true,
                data: action.payload,
                actionsOnSelectedRows: [],
                selectedRows: [],
                queryName: action.payload !== null ? action.payload.query_name : '',
                isSetHeader: true,
            }
        case actions.OTHER_LISTVIEW_GET_SELECTED_RECORD:
            return {
                ...state,
                selectedRows: action.payload
            }
        case actions.OTHER_LISTVIEW_SET_MOUNTED:
            return {
                ...state,
                mounted: action.payload
            }
        default:
            return state;
    }
}

export default otherListViewReducer;