import update from "immutability-helper";
import * as React from 'react';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popper from '@mui/material/Popper';
import { showCustomSnackBar } from '../../services/actions/snackBarAction';
import { styles } from '../../services/constants/styles';
import { useDispatch } from "react-redux";
import { Dispatch } from 'redux'
import type { FC } from "react";
import _ from 'lodash';
import {getArrayParam} from '../../services/helper/parameterVerifier';


import { useDrag, useDrop } from "react-dnd";
const ItemTypes = {
  CARD: "card"
};



export interface CardProps {
  id: string;
  label: string;
  value: any;
  moveCard: (id: string, to: number) => void;
  findCard: (id: string) => { index: number };
  delCard: (id: string) => void;
}
export interface CardType {
  id: number;
  label: string;
  value: any;
}
interface Item {
  id: string;
  originalIndex: number;
}
export interface ContainerState {
  cards: object[];
}
export const DraggablChip: FC<CardProps> = React.memo(function DraggablChip({
  id,
  label,
  value,
  moveCard,
  findCard,
  delCard
}) {
  const originalIndex = findCard(id).index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
          const didDrop = monitor.didDrop();
          if (!didDrop) {
            moveCard(droppedId, originalIndex);
          }
      }
    }),
    [id, originalIndex, moveCard]
  );

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      hover({ id: draggedId }: Item) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      }
    }),
    [findCard, moveCard]
  );
  const handleDelete = () => {
    delCard(id);
  };


  const opacity = isDragging ? 0 : 1;
  return (
    <span ref={(node) => drag(drop(node))} style={{ opacity }}>
      <Chip style={{ cursor: "move", margin: 2 }} label={label} onDelete={handleDelete} size="small" />
    </span>
  );
});

const DraggableSelect: FC<{ selectId: string, variant: string, options: any[], selectLimit: any, limitMessage: string, isClearable: any, onSortEnd: any, closeMenuOnSelect: any, value: any[], placeholder: any, onChange: any }> = React.memo(function DraggableSelect({ selectId, variant, options, selectLimit, limitMessage, isClearable, onSortEnd, closeMenuOnSelect, value, placeholder, onChange }) {
  const dispatch: Dispatch<any> = useDispatch();
  const [cards, setCards] = React.useState<CardType[]>([]);
  const [optionList, setOptionList] = React.useState<object[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const [searchString, setSearchString] = React.useState('');
  const inputRef = React.useRef<any>(null);
  const open = Boolean(anchorEl);
  const cell = React.useRef<HTMLDivElement>(null)
  const scrollableDivRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    const firstArray = options,
      secondArray = getArrayParam(value),
      result = firstArray?.filter((item: any) => secondArray?.some(({ value }) => item?.value === value));
    let _selectedValue = getArreyWithId(result);
    let _setOptionList = getArreyWithId(options);
    setCards(_selectedValue);
    setOptionList(_setOptionList);
  }, [options, value]);

  React.useEffect(() => {
    setCards(getArreyWithId(getArrayParam(value)));
  }, [value]);
  const getArreyWithId = (arr: any[]) => {
    let _arr = arr.map((el: any) => {
      el.id = el.id || el.value;
      return el;
    });
    return _arr;
  }

  const handleMenuItemClick = (
    item: any
  ) => {
    try {
      let _limit = selectLimit || optionList.length;
      let tempCard = [...cards, item];
      setInputValue('');
      setSearchString('');
      if (_limit >= tempCard.length) {
        setCards(tempCard);
        onChange(tempCard);
        if (closeMenuOnSelect) {
          handleClose();
        }
      } else {
        const result = tempCard.slice(0, _limit);
        setCards(result);
        onChange(result);
        let _message = limitMessage || 'Max limit exceeded';
        dispatch(showCustomSnackBar(_message, styles.snackbarBodyStyleError, styles.snackBarStyleTop, 2000));
        handleClose();
      }
    } catch (error) {
      console.error("Error on dragableSelect.tsx >> handleMenuItemClick(): " + error);
    }
  };
  const delCard = (id: any) => {
    try {
      let tempCard = cards;
      const result = tempCard.filter((obj: any) => {
        return obj.id != id;
      });
      setCards(result);
      onChange(result);
      setInputValue('');
      setSearchString('');
    } catch (error) {
      console.error("Error on dragableSelect.tsx >> delCard(): " + error);
    }
  }
  const clearCard = () => {
    try {
      setCards([]);
      onChange([]);
      setInputValue('');
      setSearchString('');
    } catch (error) {
      console.error("Error on dragableSelect.tsx >> clearCard(): " + error);
    }
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  const findCard = React.useCallback(
    (id: string) => {
      const _filtredCard = cards.filter((c: any) => `${c.id}` === `${id}`)
      if (_filtredCard.length) {
        const card = _filtredCard[0] as {
          id: number;
          label: string;
          value: any;
        };
        return {
          card,
          index: cards.indexOf(card)
        };
      } else {
        return {
          card: {
            id: -191,
            label: '',
            value: ''
          },
          index: -1
        };
      }

    },
    [cards]
  );

  const moveCard = React.useCallback(
    (id: string, atIndex: number) => {
      const { card, index } = findCard(id);
      if(card.id != -191 || index != -1){
        console.log(card, index);
        setCards(
          update(cards, {
            $splice: [
              [index, 1],
              [atIndex, 0, card]
            ]
          })
        );
        onSortEnd(update(cards, {
          $splice: [
            [index, 1],
            [atIndex, 0, card]
          ]
        }));
      }
      
    },
    [findCard, cards, onSortEnd, setCards]
  );
  const setSearchValue = (e: any) => {
    try {
      setSearchString(e.target.value);
      setAnchorEl(e.currentTarget);
    } catch (error) {
      console.error("Error on dragableSelect.tsx >> setSearchValue(): " + error);
    }
  }

  const handleFocus = (event: any, callFrom: string) => {
    if (callFrom === 'icon') {
      setAnchorEl(anchorEl == null ? event.currentTarget : null);
    } else {
      setAnchorEl(event.currentTarget);
    }
    inputRef.current?.focus();
  }
  const focusOutSearchField = () => {
    try {
      document.addEventListener("mousedown", (e: any) => {
        let concernedElement = document.querySelector(".draggable-select");
        let concernedSearchElement = document.querySelector(".draggable-search-area");
        if (concernedElement !== null && !concernedElement.contains(e.target)) {
          if (concernedSearchElement !== null && !concernedSearchElement.contains(e.target)) {
            if (open) {
              setAnchorEl(null);
            }
          }
        }
      });
    } catch (error) {
      console.error("Error on dragableSelect.tsx >> focusOutSearchField(): " + error);
    }
  }

  const SearchForObjectsWithName = (arrayOfVariable: any[], sortValue: any) => {
    try {
      let ansArray: any[] = [];
      arrayOfVariable.forEach((students) => {
        let toAdd = false
        Object.values(students).forEach((value: any) => {
          if (value !== null) {
            let mainString = value?.toString().replace(/\s/g, '').toLowerCase()
            let subString = sortValue.replace(/\s/g, '').toLowerCase()
            if (mainString?.includes(subString)) {
              toAdd = true
            }
          }
        })
        if (toAdd) {
          ansArray.push(students)
        }
      })
      return ansArray;
    } catch (error) {
      console.error("Error on dragableSelect.tsx >> SearchForObjectsWithName(): " + error);
    }
  }

  const setInputValue = (value: string) => {
    try {
      inputRef.current.value = value;
      setInputFocused();
    } catch (error) {
      console.error("Error on dragableSelect.tsx >> setInputValue(): " + error);
    }
  }
  const setInputFocused = () => {
    try {
      inputRef.current?.focus();
    } catch (error) {
      console.error("Error on dragableSelect.tsx >> setInputFocused(): " + error);
    }
  }

  const isIncludes = (arr: any[], obj: any) => {
    try {
      let includes = false;
      let index = arr.findIndex(x => x.value === obj.value);
      if (index > -1) {
        includes = true;
      }
      return includes;
    } catch (error) {
      console.error("Error on dragableSelect.tsx >> isIncludes(): " + error);
    }
  }

  const handleKeyDown = (e: any) => {
    try {
      let _index = selectedIndex;
      var dataSearchList: any = [...optionList.filter((olo: any) => !isIncludes(cards, olo))];
      let _searchString = searchString
      if (_searchString.replace(/\s/g, '') !== '') {
        dataSearchList = SearchForObjectsWithName(dataSearchList, _searchString)
      }
      let _dataSearchList = dataSearchList;
      _dataSearchList = _dataSearchList?.filter((obj: any, index: any) => {
        return index === _dataSearchList?.findIndex((o: any) => obj.value === o.value);
      });
      let height = 40;
      if (e.keyCode === 38) {
        _index = _index - 1;
        if (_index < 0) {
          _index = _dataSearchList.length;
        }
        setSelectedIndex(_index);
        height = _index * height;

        if (scrollableDivRef.current) {
          scrollableDivRef.current.scrollTop = height;
        }
      } else if (e.keyCode === 40) {
        _index = _index + 1;
        if (_index > _dataSearchList.length) {
          _index = 0;
        }
        setSelectedIndex(_index);
        height = _index * height;
        if (scrollableDivRef.current) {
          scrollableDivRef.current.scrollTop = height;
        }
      } else if (e.keyCode === 13) {
        if (_dataSearchList.length > 0) {
          let _card = _dataSearchList[selectedIndex];
          if (_card) {
            handleMenuItemClick(_card);
          }
        }
      } else if (e.keyCode === 8) {
        if (_searchString.length == 0) {
          let _cards = cards;
          _cards = cards?.filter((obj: any, index: any) => {
            return index === cards?.findIndex((o: any) => obj?.label == o?.label && obj?.value === o?.value);
          });
          if (_cards.length > 0) {
            let newSearchString: string = _cards[_cards.length - 1]?.label;
            _cards.splice((_cards.length - 1), 1);
            setSearchString(newSearchString);
            setCards([..._cards]);
          }
        }
      }
    } catch (error) {
      console.error("Error on dragableSelect.tsx >> handleKeyDown(): " + error);
    }
  };

  const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));

  let width: any = 0;
  let height: any = 0;
  if (document.getElementById(selectId || "draggable-search") != null) {
    width = document.getElementById(selectId || "draggable-search")?.offsetWidth;
  };
  height = window.innerHeight;
  const tempElement = document.getElementById(selectId || "draggable-search");
  let pos = tempElement?.getBoundingClientRect();
  let eliminator = (pos?.y || 0) + (pos?.height || 0);
  let heightB = height - eliminator - 20;
  let heightT = (pos?.y || 0) - 100;
  variant = variant || "standard";
  let charWidth = searchString.length * 8;
  charWidth = charWidth > 0 ? charWidth + 10 : 2;
  var dataSearchList: any = [...optionList.filter((olo: any) => !isIncludes(cards, olo))];
  let _searchString = searchString
  if (_searchString.replace(/\s/g, '') !== '') {
    dataSearchList = SearchForObjectsWithName(dataSearchList, _searchString)
  }

  if (dataSearchList?.length == 0) {
    dataSearchList = [{ id: -90001, label: 'No Options', value: 'no_options' }]

  }
  let _cards = cards;
  _cards = cards?.filter((obj: any, index: any) => {
    return index === cards?.findIndex((o: any) => obj?.label == o?.label && obj?.value === o?.value);
  });
  let _dataSearchList = dataSearchList;
  _dataSearchList = _dataSearchList?.filter((obj: any, index: any) => {
    return index === _dataSearchList?.findIndex((o: any) => obj.value === o.value);
  });
  const getDraggablChip = () => {
    let _draggablChips: any[] = [];
    _cards?.forEach((item: any) => {
      if (item) {
        _draggablChips.push(
          <DraggablChip
            key={item.id}
            id={`${item.id ?? -191}`}
            label={item.label ?? ''}
            value={item.value ?? ''}
            moveCard={moveCard}
            findCard={findCard}
            delCard={delCard}
          />
        )
      }
    })
    return _draggablChips
  }
  return (
    <div className='draggable-search-root' ref={cell} style={{ borderRadius: '4px' }}>
      <div className='draggable-search-area' style={{ width: '100%', border: '1px solid #aeaeae', borderRadius: '4px' }} >
        <div>
          <div id={selectId || "draggable-search"} style={{ padding: '3px', display: 'flex' }}>
            <span style={{ width: '100%' }}
              onClick={(e) => handleFocus(e, 'input')}>
              <span ref={drop}>
                {getDraggablChip()}

              </span>
              <span style={{ display: 'inline-flex', marginTop: '2px' }}>
                <input autoCapitalize="none" autoComplete="off" autoCorrect="off" id="react-select-2-input" spellCheck="false" tabIndex={0} type="text" aria-autocomplete="list"
                  ref={inputRef}
                  className='draggable-search-input'
                  onBlur={() => focusOutSearchField()}
                  onChange={(event) => setSearchValue(event)}
                  onKeyDown={handleKeyDown}
                  value={searchString}
                  style={{ boxSizing: 'content-box', width: charWidth, maxWidth: width - 50, background: '0px center', border: 0, fontSize: 'inherit', opacity: 1, outline: 0, padding: 0, color: 'inherit' }} />
                {cards.length == 0 && searchString.length == 0 && <span style={{ color: 'rgb(128, 128, 128)', alignItems: 'center', display: 'flex' }}>
                  {placeholder}</span>}
              </span>
            </span>
            <span style={{ width: '50px', display: 'flex' }}>
              {isClearable && <span onClick={clearCard} style={{ cursor: 'pointer', display: 'flex', marginTop: '-5px', alignItems: "center" }}>
                x
              </span>}
              <span style={{ cursor: 'pointer', display: 'flex', alignItems: "center" }}>
                <ArrowDropDownIcon onClick={(e) => handleFocus(e, 'icon')} />
              </span>
            </span>
          </div>
          <Popper
            open={open}
            anchorEl={cell.current}
            placement="bottom"
            sx={{ ...styles.popperStyle, width: width, borderRadius: '4px', }} >
            <div id="draggable-select-container-root" className="draggable-select-container" style={{ margin: '0' }}>
              <div className="draggable-select" style={{ width: width, background: '#fff', overflow: 'auto', maxHeight: heightB, borderRadius: '4px', }}>
                <div style={{ width: width - 8 }}>
                  <div id="draggable-select-items" ref={scrollableDivRef}>
                    {_dataSearchList?.map((item: any, index: number) => (
                      <MenuItem
                        key={item.label}
                        disabled={item.id == -90001}
                        selected={index == selectedIndex}
                        onClick={() => handleMenuItemClick(item)}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Popper>
        </div>
      </div>
    </div>


  );
});

export default DraggableSelect