
import { actions } from '..//constants/actions';

function updateConvertData(convert) {
    return function (dispatch) {
        try {
            dispatch({
                type: actions.UPDATE_CONVERT_DATA,
                payload: {
                    convert: convert,
                }
            })
        } catch (e) { console.error("Error in 'convertAction.js -> updateConvertData()':" + e); }
    }
}

function updateConvertDataByKey(key, data) {
    return function (dispatch) {
        try {
            dispatch({
                type: actions.UPDATE_CONVERT_DATA_BY_KEY,
                payload: { key, data }
            })
        } catch (e) { console.error("Error in 'convertAction.js -> updateConvertDataByKey()':" + e); }
    }
}



function updateBatchConvertData(batchConvert) {
    return function (dispatch) {
        try {
            dispatch({
                type: actions.UPDATE_BATCH_CONVERT_DATA,
                payload: {
                    batchConvert: batchConvert,
                }
            })
        } catch (e) { console.error("Error in 'convertAction.js -> updateBatchConvertData()':" + e); }
    }
}

export { updateConvertData, updateBatchConvertData, updateConvertDataByKey };