import { actions } from '..//constants/actions';

const listViewInitialSate = {
    mounted: false,
    data: {
        object: ''
    },
    error: null,
    actionsOnSelectedRows: [],
    selectedRows: null,
    queryName: '',
    isSetHeader: false,
    isShowGroupBy: false,
    groupByInfo: {
        object: ''
    },
    isGroupByMounted: false
}

const duplicateListViewReducer = (state = listViewInitialSate, action) => {
    switch (action.type) { 
        case actions.DUPLICATE_LISTVIEW_GET_FULFILLED:
            return {
                ...state,
                mounted: true,
                data: action.payload,
                actionsOnSelectedRows: [],
                selectedRows: [],
                queryName: action.payload !== null ? action.payload.query_name : '',
                isSetHeader: true,
                isShowGroupBy: false
            }
        case actions.DUPLICATE_LISTVIEW_GROUPBY_INFO_GET_FULFILLED:
            return {
                    ...state,
                    isGroupByMounted: true,
                    groupByInfo: action.payload,
            } 
        case actions.DUPLICATE_LISTVIEW_GET_SELECTED_RECORD:
            return {
                ...state,
                selectedRows: action.payload
            }
        case actions.DUPLICATE_LISTVIEW_SET_MOUNTED:
            return {
                ...state,
                mounted: action.payload,
                isGroupByMounted: action.payload,
            }
        default:
            return state;
    }
}

export default duplicateListViewReducer;

