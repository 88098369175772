import React, { useState, useEffect ,useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import FroalaEditor from "react-froala-wysiwyg";
import Froalaeditor from 'froala-editor';
import $ from "jquery";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { Menu, MenuItem, Icon } from "@mui/material";
import ShowCircularProgress from "../components/circularProgress";
import * as HTTPClient from "../../../services/helper/httpClient";
import { endPoints } from "../../../services/constants/endPoints";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PopOver from "../components/PopOver";
import { constants } from "../../../services/constants/constants";
import { styles } from "../../../services/constants/styles";
import { Navigate } from "react-router-dom";
import { getAppDialog } from "../../../services/actions/appContainerActions";
import { getAppDrawer } from "../../../services/actions/appContainerActions";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { refreshListView } from "../../../services/actions/listViewAcions";
import {
	getIntParam,
	getStringParam,
	isValidParam,
	getArrayParam,
	getObjectParam,
} from "../../../services/helper/parameterVerifier";
import {
	getCoupons,
	hasAccessPermission,
} from "../../../services/helper/common";
import { getCouponsAction } from "../../../services/actions/couponActions";
import * as sfDialogs from "../components/sfDialogs";
import SFAutoComplete from "../components/sfAutoComplete";
import { storeTemplateListInRedux } from "../../../services/actions/sendMailActions";
import { sortArrayObjectByProperty } from "../../../services/helper/utils";

require("froala-editor/js/froala_editor.pkgd.min.js");
require("froala-editor/css/froala_editor.pkgd.min.css");
require("froala-editor/js/plugins/font_family.min.js");
require("froala-editor/css/froala_style.css");

const optionvalue = {
	Copy: "Copy",
	Preview: "Preview",
};

const MarketingTemplate = ({ id, type,closeDrawer, object,html }) => {
	const dispatch = useDispatch();
	const fieldRefStatus = useRef()

	const sendMail = useSelector((state) => state.sendMail);
	const app = useSelector((state) => state.app);

	const [template, setTemplate] = useState({
		name: "",
		subject: "",
		email_message: "",
		id: id | 0,
		status: "Active",
		t_library: 1,
		category: "",
	});

	const [mounted, setMounted] = useState(false);
	const [statusOpen, setStatusOpen] = useState(false);
	const [personilizeOpen, setPersonilizeOpen] = useState(false);
	const [moreActionOpen, setMoreActionOpen] = useState(false);
	const [error, setError] = useState({
		name: "",
		subject: "",
	});
	const [buttonVisible, setButtonVisible] = useState("inline-block");
	const [redirect, setRedirect] = useState(false);
	const [redirectUrl, setRedirectUrl] = useState("");
	const [personalize_fields, setPersonalize_fields] = useState({
		"j_contacts.company": "Company",
		"j_contacts.first_name": "First name",
		"j_contacts.last_name": "Last name",
	});
	const [stickyStyle, setStickyStyle] = useState({ width: "100%" });
	const [cursorposition, setCursorposition] = useState(0);
	const [shareMarketingTemplate, setShareMarketingTemplate] = useState(false);
	const [isFullScreenEditor, setIsFullScreenEditor] = useState(false);
	const [interval, setInterval] = useState(0);
	const [templateName, setTemplateName] = useState("");
	const [isCopy, setIsCopy] = useState(false);
	const [coupons, setCoupons] = useState({});
	const [editorLoader, setEditorLoader] = useState(true);
	const [statusArr, setStatusArr] = useState([
		<MenuItem
			key={"Active"}
			value={"Active"}
			id={"Active"}
			primaryText={getLocalizedStrings().label.MARKETING_TEMPLATE.ACTIVE}
			style={styles.popoverMenuItem}
		/>,
		<MenuItem
			key={"Inactive"}
			value={"Inactive"}
			id={"Inactive"}
			primaryText={getLocalizedStrings().label.MARKETING_TEMPLATE.INACTIVE}
			style={styles.popoverMenuItem}
		/>,
	]);
	const [isOpenNoteTypeList, setIsOpenNoteTypeList] = useState(false);
	const [anchorELNoteTypeList, setAnchorELNoteTypeList] = useState(null);
	const [merge_fields, setMerge_fields] = useState({});
	const [anchorMoreActionEl, setAnchorMoreActionEl] = useState(false);
	const [menuList, setMenuList] = useState([]);


	useEffect(()=>{
		getMoreMenuListData();
		loadMarketingTemplateData(id);

	},[])

	const getMoreMenuListData=()=>{
		let menuList = [];
		let tempMenuList = getMoreMenuList();
		Object.keys(optionvalue).forEach((itemKey) => {
			let data = {};
			data.label = tempMenuList[optionvalue[itemKey]];
			data.value = itemKey;
			menuList.push(data);
		});
		setMenuList(menuList);
	}
	const getMoreMenuList = () => {
		let moreMenuList = {
			Copy: getLocalizedStrings().label.COMMON.COPY,
			Preview: getLocalizedStrings().label.COMMON.PREVIEW,
		};
		return moreMenuList;
	};

	const loadMarketingTemplateData = (id) => {
		loadCoupons();
		if (id !== undefined && id !== null && id !== 0) {
			var response = Promise.resolve(
				HTTPClient.get(endPoints.MARKETING_TEMPLATE.EDIT + "/" + id, null)
			);
			response.then((response) => {
				let tempTemplate = template;
				let tempMerge_fields = merge_fields;
				let tempCursorposition = cursorposition;
				let tempShareMarketingTemplate = shareMarketingTemplate;
				let tempPersonalize_fields = personalize_fields;

				const template_details = response.template_details[0];
				if (template_details.t_library === 0) {
					tempTemplate["name"] = template_details.t_name;
					setTemplate(tempTemplate);
					setButtonVisible("inline-block");
				} else {
					setButtonVisible("none");
				}
				tempTemplate["subject"] = template_details.t_subject;
				tempTemplate["email_message"] = template_details.t_msg;
				tempTemplate["id"] = template_details.t_pk;
				tempTemplate["status"] = template_details.t_status;
				tempTemplate["t_library"] = template_details.t_library;
				tempTemplate["category"] = template_details.t_catagory;
				tempMerge_fields["merge_fields"] = response.available_mail_merge_fields.merge_fields;
				setTemplate(tempTemplate);
				setMerge_fields(tempMerge_fields);
				tempCursorposition = template_details.t_subject.length;
				setCursorposition(tempCursorposition);
				tempShareMarketingTemplate =
					template_details.share === 1 ? true : false;
				setShareMarketingTemplate(tempShareMarketingTemplate);
				setMounted(true);
				if (type === "copy") {
					tempTemplate.id = 0;
					tempTemplate.name =
						getLocalizedStrings().label.MARKETING_TEMPLATE.COPY_OF +
						"_" +
						tempTemplate.name;
					setButtonVisible("none");
				}
				let personalizeArray = {};
				let i;
				let j;
				let mergeFields = response.available_mail_merge_fields;
				for (i in mergeFields) {
					for (j in mergeFields[i]) {
						// if (mergeFields[i].hasOwnProperty('j_contacts.first_name') || mergeFields[i].hasOwnProperty('j_contacts.last_name') || mergeFields[i].hasOwnProperty('j_contacts.company')) {
						personalizeArray[j] = mergeFields[i][j];
					}
				}
				tempPersonalize_fields = personalizeArray;
				setPersonalize_fields(tempPersonalize_fields);

				if (getStringParam(tempTemplate["name"].trim()).length === 0) {
					autoSetMarketingData();
				} else if (type === "copy") {
					autoSetMarketingData();
				}
				startAutoSaveInterval();
			});
		} else {
			response = Promise.resolve(
				HTTPClient.get(endPoints.MARKETING_TEMPLATE.NEW, null)
			);
			response.then((response) => {
				let tempTemplate = template;
				tempTemplate["name"] = "";
				tempTemplate["subject"] = "";
				tempTemplate["id"] = 0;
				tempTemplate["email_message"] = "";

				setCursorposition(0);
				setMounted(true);
				setButtonVisible("none");
				tempTemplate["email_message"] =
					getLocalizedStrings().label.MARKETING_TEMPLATE.DEAR +
					" ${j_contacts.first_name},<br/> " +
					getLocalizedStrings().label.MARKETING_TEMPLATE.TYPE_HERE;
				setTemplate(tempTemplate);
				setShareMarketingTemplate(false);
				let personalizeArray = {};
				let i;
				let j;
				let mergeFields = response.available_mail_merge_fields;
				for (i in mergeFields) {
					for (j in mergeFields[i]) {
						personalizeArray[j] = mergeFields[i][j];
					}
				}

				setPersonalize_fields(personalizeArray);

				autoSetMarketingData();
				startAutoSaveInterval();
			});
		}
	};

	const loadCoupons = () => {
			let coupons = {};

			if (!coupons?.couponMounted) {
				let promise = getCoupons();
				promise.then((response) => {
					if (isValidParam(response.records)) {
						getCouponsAction(response.records);
						let records = getArrayParam(response.records);
						records.forEach((record) => {
							let discount =
								record.discount_type.toLowerCase() === "percentage"
									? record.discount + "%"
									: "$" + record.discount;
							coupons[" " + record.name] = `${record.name} (${discount})`;
						});

						setCoupons(coupons);
						setEditorLoader(false);
					}
				});
			} else {
				if (isValidParam(coupons.coupons)) {
					let records = getArrayParam(coupons.coupons);
					records.forEach((record) => {
						let discount =
							record.discount_type.toLowerCase() === "percentage"
								? record.discount + "%"
								: "$" + record.discount;
						coupons[" " + record.name] = `${record.name} (${discount})`;
					});
				}

				setCoupons(coupons);
				setEditorLoader(false);
			}
	};

	

	const handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		let tempTemplate = template;
		let tempError = error;

		setCursorposition(event.target.selectionStart);
		tempTemplate[name] = value;
		if (value.trim() !== "") {
			tempError[name] = "";
		}

		setTemplate(tempTemplate);
		setError(tempError);
	};

	const focusSubject = (event) => {
			setCursorposition(event.target.selectionStart);
	};

	const handleStatusChange = (fieldName, value) => {
		let tempTemplate = template;
		tempTemplate[fieldName] = value;
		setTemplate(tempTemplate);
		setStatusOpen(false);
	};

	const handleMoreActionChange = (value) => {
		let hasPermission = hasAccessPermission(
			object,
			constants.SECURITY_LEVEL_TYPE_PERMISSION,
			constants.PERMISSION_NAME_MANAGE_TEMPLATES
		);

		setMoreActionOpen(false);
		switch (value) {
			case "Copy":
				if (hasPermission) {
					let tempTemplate = template;
					let tempError = error;
					tempTemplate.id = 0;
					tempTemplate.name =
						getLocalizedStrings().label.MARKETING_TEMPLATE.COPY_OF +
						"_" +
						tempTemplate.name;
					setTemplate(tempTemplate);
					setButtonVisible("none");
					tempError = { name: "", subject: "" };
					setError(tempError);
					setIsCopy(true);
				} else {
					sfDialogs.alert(
						getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
						getLocalizedStrings().message.COMMON.ACCESS_DENIED,
						null
					);
				}
				break;
			case "Preview":
				window.open("/#previewemailtemplate/" + template.id);
				break;
			default:
				return false;
		}
		autoSetMarketingData();
		startAutoSaveInterval();
	};

	const handleSelectPersonilizeChange = (fieldName, value) => {
		let tempTemplate = template;
		tempTemplate[fieldName] = value;
		let tempCursorPosition = cursorposition;
		setTemplate(tempTemplate);
		setPersonilizeOpen(false);
		let front = tempTemplate["subject"].substring(0, cursorposition);
		let back = tempTemplate["subject"].substring(
			cursorposition,
			tempTemplate["subject"].length
		);
		tempTemplate["subject"] = front + " ${" + value + "}" + back;
		tempCursorPosition = tempTemplate["subject"].length;
		setCursorposition(tempCursorPosition);
		setIsOpenNoteTypeList(false);
	};

	const handleModelChange = (event) => {
		let tempTemplate = template;
		tempTemplate["email_message"] = event;
		setTemplate(tempTemplate);
	};

	const handleBack = () => {
		if (
			sfDialogs.confirm(getLocalizedStrings().message.MARKETING_TEMPLATE.BACK)
		) {
			// handleBackComponetFromMarketingTemplate();
		}
	};

	const saveTemplate = () => {
		if (!validateForm()) {
			return false;
		}
		let tempTemplate = template;

		let params = {
			id: tempTemplate.t_library === 0 ? tempTemplate.id : 0,
			name: tempTemplate.name,
			subject: tempTemplate.subject,
			email_message: tempTemplate.email_message,
			status: tempTemplate.status,
			category: tempTemplate.category,
			share: shareMarketingTemplate === true ? 1 : 0,
		};

		var response = Promise.resolve(
			HTTPClient.post(endPoints.MARKETING_TEMPLATE.SAVE, params)
		);
		setMounted(false);
		response.then((response) => {
			this.setState({ templateName: getStringParam(tempTemplate.name) });
			if (response.status === 0) {
				if (params.id === 0) {
					tempTemplate.id = response.data.t_pk;
					setTemplate(tempTemplate);
					setButtonVisible("none");
				}
				updateTemplateList(params);

				dispatch(
					showCustomSnackBar(
						getLocalizedStrings().message.COMMON.SAVE,
						styles.snackbarBodyStyleSuccess,
						styles.snackBarStyleTop
					)
				);
				tempTemplate.t_library = 0;
				setMounted(true);
				setButtonVisible("inline-block");
				if (type !== "edit") {
					// this.replaceExistingTabAndRedirect(this.state.template.id, "marketing-templates", "Edit Marketing Template");
				}
				refreshListView(constants.MARKETING_TEMPLATE_OBJECT);
			} else if (response.status === -1 && response.error.message !== "") {
				dispatch(
					showCustomSnackBar(
						response.error.message,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleTop
					)
				);

				setMounted(true);
				setButtonVisible("none");
			}
		});
	};

	const updateTemplateList = (params) => {
		let templateList = getArrayParam(sendMail.templateList);
		if (templateList.length > 0) {
			let templateListObj = getObjectParam(templateList[0]);
			let mrktTempList = getArrayParam(templateListObj[template.category]);

			let templateObj = {
				email_status: template.status,
				execution_time: null,
				id: template.id,
				name: template.name,
				preview_image_url: "",
			};

			if (params.id === 0) {
				if (shareMarketingTemplate && template.status === constants.ACTIVE) {
					mrktTempList.push(templateObj);
				}
			} else {
				let mrktTemplateObj = mrktTempList.filter((f) => f.id === params.id);
				if (mrktTemplateObj.length > 0) {
					if (shareMarketingTemplate && template.status === constants.ACTIVE) {
						mrktTemplateObj[0].name = templateObj.name;
						mrktTemplateObj[0].email_status = templateObj.email_status;
					} else {
						mrktTempList = mrktTempList.filter((f) => f.id !== params.id);
					}
				} else {
					if (shareMarketingTemplate && template.status === constants.ACTIVE) {
						mrktTempList.push(templateObj);
					}
				}
			}

			sortArrayObjectByProperty(mrktTempList, "name");
			templateListObj[template.category] = mrktTempList;
			templateList = new Array(templateListObj);

			dispatch(storeTemplateListInRedux(templateList));
		}
	};

	const renderPersonalizeField = () => {
		let personalizeObject = personalize_fields;
		return Object.keys(personalizeObject).map((itemKey, index) => {
			return (
				<MenuItem
					key={itemKey + index}
					value={itemKey}
					style={styles.popoverMenuItem}
					fullWidth={true}
					labelStyle={{ color: "#479ccf" }}
					underlinestyle={{ display: "none" }}
					autowidth={"true"}
					onClick={() =>
						handleSelectPersonilizeChange("personalize", itemKey)
					}
				>
					{personalizeObject[itemKey]}
				</MenuItem>
			);
		});
	};

	const validateForm = () => {
		let tempError = error;
		let isValid = true;
		if (
			template.name.trim() === "" ||
			(template.name.indexOf("Untitled") !== -1 &&
				template.name.startsWith("Untitled"))
		) {
			isValid = false;
			error["name"] =
				getLocalizedStrings().message.MARKETING_TEMPLATE.NAME_BLANK;

			setError(error);
		}
		if (template.subject.trim() === "") {
			isValid = false;
			tempError["subject"] =
				getLocalizedStrings().message.MARKETING_TEMPLATE.SUBJECT_BLANK;
			setError(tempError);
		}
		return isValid;
	};

	const testMail = () => {
		let params = {};
		params.email = "";
		params.frommail = app.me.email;
		params.fromname = app.me.name;
		params.subject = template.subject;
		params.templateBody = template.email_message;
		params.testMailType = 'marketing';
		dispatch(
			getAppDialog(true, constants.TEST_MAIL_DIALOG, null, null, params, null)
		);
	};

	const getLibrary = (editor, type) => {
		editor.events.disableBlur();
		let data = {
			editor: editor,
			contentStyle: { width: "80%", maxWidth: "none" },
			type: type,
			fullWidth: true,
			maxWidth: "md",
		};
		dispatch(
			getAppDialog(
				true,
				constants.LIBRARY_DIALOG,
				getLocalizedStrings().message.COMMON.LIBRARY_DIALOG_TITLE,
				handleLibraryResponse,
				data,
				null
			)
		);
	};

	const getVideoInsertDialog = (editor, videoType) => {
		editor.events.disableBlur();
		let data = {
			editor: editor,
			contentStyle: { width: "80%", maxWidth: "none" },
			videoType: videoType,
		};
		let title = null;
		if (videoType === constants.VIDEO_TYPE_YOUTUBE) {
			title = getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE;
		} else if (videoType === constants.VIDEO_TYPE_WISTIA) {
			title = getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE;
		}
		dispatch(
			getAppDialog(
				true,
				constants.VIDEO_INSERT_DIALOG,
				title,
				(event)=>handleVideoInsertResponse(event),
				data,
				null
			)
		);
	};

	const handleVideoInsertResponse = (obj) => {
		obj.editor.events.focus();
		dispatch(
			getAppDialog(false, constants.VIDEO_INSERT_DIALOG, null, null, null, null)
		);
		obj.editor.html.insert(
			'<a href="' +
				obj.video_link +
				'" target="_blank" className="editor_video-selector"><img   height="220" width="380" "src="' +
				obj.thumbnail.url +
				'" title="' +
				obj.title +
				'" /></a>'
		);
		obj.editor.events.focus();
	};

	const closeDrawerfunc = () => {
		closeDrawer();
	};

	const handleLibraryResponse = (obj) => {
		obj.editor.events.enableBlur();
		if (obj.type === "link" && obj.src !== "") {
			document.getElementsByName("href")[0].value = obj.src;
		} else if (obj.type === "image" && obj.src !== "") {
			obj.editor.image.insert(obj.src);
		}
		dispatch(
			getAppDialog(false, constants.LIBRARY_DIALOG, null, null, null, null)
		);
	};

	const checkBoxShare = (isInputChecked) => {
		setShareMarketingTemplate(isInputChecked);
	};

	const expandEditor = () => {
		try {
			setIsFullScreenEditor(!isFullScreenEditor);
			if (isFullScreenEditor) {
				$("[id^=expand_editor]")
					.find($(".fa"))
					.removeClass("fa fa-expand")
					.addClass("fa fa-compress");
				setTimeout(function () {
					$("[id^=expand_editor]").addClass("fr-active");
				}, 500);
			} else
				$("[id^=expand_editor]")
					.find($(".fa"))
					.removeClass("fa fa-compress")
					.addClass("fa fa-expand");
		} catch (error) {
			console.error(
				"Error in 'MarketingTemplate.js -> expandEditor()':" + error
			);
		}
	};

	const templateNameSet = () => {
		let tname = getStringParam(template.name);
		if (tname === "" && getStringParam(templateName) !== "") {
			template.name = templateName;
			setTemplate(template);
		}
	};

	const startAutoSaveInterval = () => {
			if (interval === 0) {
				let interval = setInterval(autoSetMarketingData, 1000 * 60 * 2);
				setInterval(interval);
			}
	};

	const autoSetMarketingData = () => {
			let hasPermission = hasAccessPermission(
				object,
				constants.SECURITY_LEVEL_TYPE_PERMISSION,
				constants.PERMISSION_NAME_MANAGE_TEMPLATES
			);
			if (hasPermission) {
				if (isValidParam(template)) {
					let templateName = getStringParam(template.name);
					if (templateName.length === 0) {
						var promise = Promise.resolve(
							HTTPClient.get(
								endPoints.MARKETING_TEMPLATE.GET_DEFAULT_TEMPLATE_NAME,
								{}
							)
						);
						promise.then((response) => {
							if (
								response !== null &&
								response.hasOwnProperty("templateName")
							) {
								template.name = getStringParam(response.templateName);
								autoSaveTemplate();
							}
						});
					} else {
						autoSaveTemplate();
					}
				}
			}
	};

	const autoSaveTemplate = () => {
		let tempTemplate = template;
			if (isValidParam(template)) {
				tempTemplate.name = getStringParam(tempTemplate.name);
			}
			let params = {
				id: tempTemplate.t_library === 0 ? tempTemplate.id : 0,
				name: tempTemplate.name,
				subject: tempTemplate.subject,
				email_message: tempTemplate.email_message,
				status: tempTemplate.status,
				category: tempTemplate.category,
				share: shareMarketingTemplate === true ? 1 : 0,
			};
			var response = Promise.resolve(
				HTTPClient.post(endPoints.MARKETING_TEMPLATE.SAVE, params)
			);
			response.then((response) => {
				if (response.status === 0) {
					let tempTemplateName = templateName;
					tempTemplateName = getStringParam(tempTemplate.name);
					setTemplateName(tempTemplateName);
					if (params.id === 0) {
						tempTemplate.id = response.data.t_pk;
						setButtonVisible("none");
					}

					updateTemplateList(params);

					if (type === "edit" || type === "copy") {
						if (params.id === 0) {
							// this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
						} else if (params.id > 0) {
							dispatch(
								showCustomSnackBar(
									getLocalizedStrings().message.COMMON.UPDATE,
									styles.snackbarBodyStyleSuccess,
									styles.snackBarStyleTop
								)
							);
						}
					} else {
						if (params === 0) {
						} else if (params.id > 0 && !isCopy) {
							dispatch(
								showCustomSnackBar(
									getLocalizedStrings().message.COMMON.SAVE,
									styles.snackbarBodyStyleSuccess,
									styles.snackBarStyleTop
								)
							);
						}
						if (isCopy) {
							setIsCopy(false);
						}
					}
					tempTemplate.t_library = 0;

					setMounted(true);
					setButtonVisible("inline-block");
					if (type !== "edit") {
						// this.replaceExistingTabAndRedirect(this.state.template.id, "marketing-templates", "Edit Marketing Template");
					}
					refreshListView(constants.MARKETING_TEMPLATE_OBJECT);
				} else if (response.status === -1 && response.error.message !== "") {
					dispatch(
						showCustomSnackBar(
							response.error.message,
							styles.snackbarBodyStyleError,
							styles.snackBarStyleTop
						)
					);

					setMounted(true);
					setButtonVisible("none");
				}
			});
	};

	const getSchedulerLink = (event) => {
			let slug = app.appointment_scheduler_slug;
			if (slug === null) {
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					getLocalizedStrings().message.SCHEDULER.NO_SCHEDULER,
					null
				);
			} 
			// else {
			// 	event.html.insert(
			// 		" [Start Scheduler Link] Book an Appointment [End Scheduler Link] "
			// 	);
			// }
	};



	const openNoteTypeList = (event) => {
		setIsOpenNoteTypeList(true);
		setAnchorELNoteTypeList(event.currentTarget);
	};

	let height = window.innerHeight - 150;
	let top = (height - 10) / 2;
	if (!mounted) {
		return (
			<div style={{ width: "100%", height: height }}>
				<div
					className="asset-loaderh"
					style={{ paddingTop: top, paddingLeft: "50%" }}
				>
					<div
						style={{
							...styles.assetLoaderContainer,
							height: 55,
							width: 55,
							padding: 6,
						}}
					>
						<ShowCircularProgress
							size={35}
							style={{ marginTop: "4", marginLeft: "4" }}
						/>
					</div>
				</div>
			</div>
		);
	}

	if (redirect) {
		setRedirect(false);
		return <Navigate push to={redirectUrl} />;
	}

	// $.FroalaEditor.DefineIcon('personalize_dropdown', {NAME: 'Personalize', template: 'text'});
	Froalaeditor.DefineIcon("personalize_dropdown", { NAME: "fa-duotone fa-gear" , template: "font_awesome"});
	Froalaeditor.RegisterCommand("personalize_dropdown", {
		title: getLocalizedStrings().label.MARKETING_TEMPLATE.PERSONALIZE,
		type: "dropdown",
		focus: false,
		undo: true,
		refreshAfterCallback: true,

		options: {
			"[Start Opt-in Link] Enter your display text [End Opt-in Link]":
				"Opt-in Link",
			"[Start Opt-out Link] Enter your display text [End Opt-out Link]":
				"Opt-out Link",
			...personalize_fields,
		},
		callback: function (cmd, val) {
			if (
				val.indexOf("[Start Opt-in Link]") > -1 ||
				val.indexOf("[Start Opt-out Link]") > -1
			) {
				this.html.insert(val);
			} else {
				this.html.insert(" ${" + val + "} ");
			}
		},
		// Callback on refresh.
		refresh: function ($btn) {},
		// Callback on dropdown show.
		refreshOnShow: function ($btn, $dropdown) {},
	});

	Froalaeditor.DefineIcon("coupon", { NAME: "ticket",template: "font_awesome" });
	Froalaeditor.RegisterCommand("coupon", {
		title: getLocalizedStrings().label.COMMON.COUPONS,
		type: "dropdown",
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		options: coupons,
		callback: function (cmd, val) {
			val = val.trim();
			this.html.insert(" ${coupon_code." + val + "} ");
		},
		refresh: function ($btn) {},
		refreshOnShow: function ($btn, $dropdown) {},
	});

	// let tempGetVideoInsertDialog = getVideoInsertDialog();
	// let tempExpandEditor = expandEditor();

	// $.FroalaEditor.DefineIcon('library', { SRC: '/asset/images/file-archieve.png', ALT: 'Insert from Document Library', template: 'image' });
	Froalaeditor.DefineIcon("library", { NAME: "image",template: "font_awesome" });
	Froalaeditor.RegisterCommand("library", {
		title: getLocalizedStrings().label.MARKETING_TEMPLATE.INSERT_IMAGE,
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			getLibrary(this, "image");
		},
	});

	Froalaeditor.DefineIcon("insertYouTubeVideo", {
		NAME: constants.ICON_YOUTUBE,template: "font_awesome",
	});
	Froalaeditor.RegisterCommand("insertYouTubeVideo", {
		title: getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE,
		focus: true,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			getVideoInsertDialog(this, constants.VIDEO_TYPE_YOUTUBE);
		},
	});

	Froalaeditor.DefineIcon("insertWistiaVideo", {
		SRC: "/asset/images/wistia.png",
		ALT: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
		template: "image",
	});
	Froalaeditor.RegisterCommand("insertWistiaVideo", {
		title: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
		focus: true,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			getVideoInsertDialog(this, constants.VIDEO_TYPE_WISTIA);
		},
	});

	/*Froalaeditor.DefineIcon("link_library", { NAME: "file-archive-o",template: "font_awesome" });
	Froalaeditor.RegisterCommand("link_library", {
		title: getLocalizedStrings().label.MARKETING_TEMPLATE.SELECT_FROM_LIBRARY,
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			getLibrary(this, "link");
		},
	});*/

	Froalaeditor.DefineIcon("expand_editor", { NAME: "expand",template: "font_awesome" });
	Froalaeditor.RegisterCommand("expand_editor", {
		title: getLocalizedStrings().label.MARKETING_TEMPLATE.EXPAND_EDITOR,
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			expandEditor(this, "link");
		},
	});

	Froalaeditor.DefineIcon("expand_editor", { NAME: "expand" ,template: "font_awesome"});
	Froalaeditor.RegisterCommand("expand_editor", {
		title: getLocalizedStrings().label.MARKETING_TEMPLATE.EXPAND_EDITOR,
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			expandEditor(this, "link");
		},
	});

	//add signature
	Froalaeditor.DefineIcon("signature", {
		SRC: "/asset/images/sign_icon.png",
		ALT: "Image button",
		template: "font_awesome"
	});
	Froalaeditor.RegisterCommand("signature", {
		title: getLocalizedStrings().label.MARKETING_TEMPLATE.ADD_SIGNATURE,
		focus: false,
		undo: true,
		icon: "signature",
		refreshAfterCallback: true,
		callback: function () {
			this.html.insert(" ${Email_Signature} ");
		},
	});
	//

	// $('div#froala-editor').froalaEditor({
	//     height: 300,
	//     iframe: true
	// })

	// let tempGetSchedulerLink = getSchedulerLink();
	Froalaeditor.DefineIcon("scheduler-editor", {
		NAME: "fas fa-calendar-minus",
		template: "font_awesome",
	});
	Froalaeditor.RegisterCommand("scheduler-editor", {
		title: getLocalizedStrings().label.COMMON.SCHEDULER_LINK,
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			getSchedulerLink(this);
		},
	});

	// let personalizeFieldList = renderPersonalizeField();
	let FroalaConfig = {
		key: constants.FROALA_EDITOR_KEY,
		placeholderText: getLocalizedStrings().label.MARKETING_TEMPLATE.TYPE_HERE,
		height: document.body.clientHeight - 200,
		toolbarButtons: constants.MARKETING_TEMPLATE_EDITOR_TOOLBAR_CONFIGURATION,
		toolbarButtonsMD: constants.MARKETING_TEMPLATE_EDITOR_TOOLBAR_CONFIGURATION,
		toolbarButtonsSM: constants.MARKETING_TEMPLATE_EDITOR_TOOLBAR_CONFIGURATION,
		toolbarButtonsXS: constants.MARKETING_TEMPLATE_EDITOR_TOOLBAR_CONFIGURATION,
		colorsBackground: constants.EDITOR_TOOLBAR_BACKGROUND_COLOR_PALETTE,
		colorsText: constants.EDITOR_TOOLBAR_TEXT_COLOR_PALETTE,
		toolbarSticky: false,
		enter: FroalaEditor.ENTER_BR,
		iframe: true,
		htmlUntouched: true,
		attribution: false,
		inlineMode: false,
		linkEditButtons: ["linkOpen", "linkEdit", "linkRemove"],
		linkInsertButtons: ["linkEdit", "linkRemove", "link_library"],
		imageEditButtons: [
			"imageAlign",
			"imageRemove",
			"imageLink",
			"imageDisplay",
			"imageAlt",
			"imageSize",
			"linkOpen",
			"linkEdit",
			"linkRemove",
		],
		tableEditButtons: [
			"tableHeader",
			"tableRemove",
			"|",
			"tableRows",
			"tableColumns",
			"-",
			"tableCells",
			"tableCellBackground",
			"tableCellVerticalAlign",
			"tableCellHorizontalAlign",
		],
		quickInsertButtons: [],
		imageUpload: false,
		imagePaste: false,
		fileUpload: false,
		fontSize: constants.EDITOR_FONT_SIZE,
		fontFamily: {
			"Adobe Garamond Pro": "Adobe Garamond Pro",
			"Arial,Helvetica,sans-serif": "Arial",
			"Futura Std": "Futura Std",
			"Georgia,serif": "Georgia",
			"Impact,Charcoal,sans-serif": "Impact",
			RobotoSegoe: "Roboto",
			"Tahoma,Geneva,sans-serif": "Tahoma",
			"'Times New Roman',Times,serif": "Times New Roman",
			"Verdana,Geneva,sans-serif": "Verdana",
		},
		fontFamilySelection: true,
		fontFamilyDefaultSelection: "Roboto",
		events: {
			"froalaEditor.initialized": function (e, editor) {
				$(".fr-command").on("click", function () {
					if ($(this).data("cmd") === "insertLink") {
						$('input[name="target"]').prop("checked", true);
					}
				});
			},
		},
		events: {
			'initialized': function() {
				window.WEBSPELLCHECKER.init({
					container: this.$iframe ? this.$iframe[0] : this.el
				});
			 }
		 }
	};

	const setIsOpenNoteTypeListValue=()=>{
		setIsOpenNoteTypeList(false);
	}

	let backBtnVisible = "none";
	if (template.t_library === 1) {
		backBtnVisible = "inline-block";
	}
	
	

	let hasPermission = hasAccessPermission(
		object,
		constants.SECURITY_LEVEL_TYPE_PERMISSION,
		constants.PERMISSION_NAME_MANAGE_TEMPLATES
	);
	let saveBtnStyle = hasPermission
		? { ...styles.primaryButton, verticalAlign: "top" }
		: {
				...styles.primaryButton,
				verticalAlign: "top",
				backgroundColor: "#ffc5b3",
				cursor: "not-allowed",
		  };
	return (
		<div style={{ paddingLeft: "26px", paddingRight: "26px" }}>
			{!isFullScreenEditor && (
				<div>
					<div style={{ marginBottom: "20px", marginTop: "20px" }}>
						<>
							<div style={stickyStyle}>
								<Button
									onClick={() => saveTemplate()}
									style={saveBtnStyle}
									labelStyle={{ fontWeight: "normal" }}
									disabled={hasPermission ? false : true}
								>
									{" "}
									{getLocalizedStrings().label.COMMON.SAVE}
								</Button>
								<Button
									onClick={() => handleBack()}
									style={{
										...styles.secondaryButton,
										display: backBtnVisible,
										verticalAlign: "top",
									}}
									labelStyle={{ fontWeight: "normal" }}
								>
									{" "}
									{getLocalizedStrings().label.COMMON.BACK}
								</Button>
								<Button
									style={{
										...styles.secondaryButton,
										display: buttonVisible,
										verticalAlign: "top",
									}}
									onClick={() => testMail()}
									labelStyle={{ fontWeight: "normal" }}
								>
									{getLocalizedStrings().label.MARKETING_TEMPLATE.TEST}
								</Button>
								<Button
									style={{ ...styles.secondaryButton, verticalAlign: "top" }}
									onClick={() => closeDrawerfunc()}
									labelStyle={{ fontWeight: "normal" }}
								>
									{getLocalizedStrings().label.COMMON.CANCEL}
								</Button>

								<div
									style={{
										paddingRight: "20px",
										paddingTop: "0px",
										display: buttonVisible,
									}}
								>
									<div
										style={{
											...styles.togButton,
											backgroundColor: "#ffffff",
											height: "27px",
											width: "28px",
											border: "1px solid rgb(224,224,224)",
										}}
									>
										<PopOver
											id={"marketing-template-more"}
											key={"marketing-template-more"}
											name={"marketing-template-more"}
											buttonEndIcon={"more_vert"}
											buttonStyle={{
												fontSize: "20px",
												color: "#717171",
												cursor: "pointer",
												paddingTop: "3px",
												paddingLeft: "3px",
											}}
											labelposition={"before"}
											buttonLabel={""}
											options={menuList}
											onclickAction={(value) => handleMoreActionChange(value)}
											isShowIcon={true}
											title={getLocalizedStrings().label.COMMON.MORE}
											anchorEl={anchorMoreActionEl}
											anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
											targetOrigin={{ horizontal: "left", vertical: "top" }}
										/>
									</div>
								</div>
							</div>
						</>
					</div>
					<div>
						<div className="row">
							<div className="col-sm-8">
								<FormControl
									style={{ width: "100%" }}
									className="test"
									noValidate
									autoComplete="off"
								>
									<TextField
										variant="outlined"
										label={
											getLocalizedStrings().label.MARKETING_TEMPLATE
												.NAME_REQUIRED
										}
										fullWidth={true}
										autoFocus
										name="name"
										value={template.name}
										onChange={(event) => handleChange(event)}
										error={error.name !== ""}
										helperText={error.name}
										onBlur={() => templateNameSet()}
										autoComplete="new-password"
										margin="dense"
										className={"sf-fields-bg"}
										size="small"
									/>
								</FormControl>
							</div>

							<div className="col-sm-4 col-two">
								<SFAutoComplete
									key={"sf-auto-complete-status"}
									id={"status"}
									name={"status"}
									label={getLocalizedStrings().label.SALES_TEMPLATE.STATUS}
									menuItems={statusArr}
									disabled={false}
									onChange={() => handleStatusChange(this, "status")}
									value={template.status}
									underlineFocusStyle={styles.textField.underlineFocus}
									floatingLabelStyle={{
										...styles.textField.floatingLabel,
										top: "10px",
										left: "7px",
									}}
									inputStyle={styles.textField.input}
									menuItemStyle={styles.popoverMenuItem}
									style={styles.sfFormFieldContainer}
									ref={fieldRefStatus}
									isFullHeight={false}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-8">
								<FormControl
									style={{ width: "100%" }}
									className="test"
									noValidate
									autoComplete="off"
								>
									<TextField
										variant="outlined"
										label={
											getLocalizedStrings().label.MARKETING_TEMPLATE
												.SUBJECT_REQUIRED
										}
										fullWidth={true}
										name="subject"
										value={template.subject}
										onChange={(event) => handleChange(event)}
										onClick={(e) => focusSubject(e)}
										error={error.subject !== ""}
										helperText={error.subject}
										inputprops={{ maxlength: "255" }}
										autoComplete="new-password"
										id="sf-marketingtemplate-inputwidth"
										margin="dense"
										className={"sf-fields-bg "}
										size="small"
									/>
									<div
										title={getLocalizedStrings().label.CAMPAIGNS.PERSONALIZE}
										style={{
											width: "10%",
											marginTop: "-3%",
											zIndex: 1,
											marginLeft: "96%",
										}}
									>
										<div
											style={{ marginTop: "-5%" }}
											onClick={(e) => openNoteTypeList(e)}
										>
											<Icon
												style={{
													fontSize: "17px",
													color: "black",
													cursor: "pointer",
													marginTop: "1px",
													marginLeft: "-7px",
												}}
											>
												settings
											</Icon>
											<Icon
												style={{
													fontSize: "17px",
													color: "black",
													cursor: "pointer",
													marginTop: "1px",
												}}
											>
												keyboard_arrow_down
											</Icon>
										</div>
										<Menu
											name="noteTypeList"
											key={"sf-auto-complete-noteTypeList"}
											id={"noteTypeList"}
											// className='appontmentdd ntl'
											style={{ height: "50%" }}
											anchorEl={anchorELNoteTypeList}
											anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
											targetOrigin={{ horizontal: "left", vertical: "top" }}
											open={isOpenNoteTypeList}
											// onClose={() => { this.setState({ isOpenNoteTypeList: false }); }}
											onClose={() => setIsOpenNoteTypeListValue()}
										>
											{renderPersonalizeField()}
										</Menu>
									</div>
								</FormControl>
							</div>

							<div className="col-sm-4" style={{ marginTop: "10px" }}>
								<FormControlLabel
									control={
										<Checkbox
											color="default"
											checked={shareMarketingTemplate}
											value={shareMarketingTemplate}
											onChange={(isInputChecked) =>
												checkBoxShare(isInputChecked)
											}
											defaultChecked={false}
										/>
									}
									label={getLocalizedStrings().label.MARKETING_TEMPLATE.SHARE}
									labelposition="right"
									style={{ left: "6", height: "auto", marginLeft: "-13px" }}
									//    labelStyle={{ color: '#717171', padding: '0px', width: 'auto', fontWeight: 'normal' }}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
			<div style={{ marginTop: "10px", marginBottom: "70px" }}>
				{editorLoader ? (
					<div style={styles.loaderContainer}>
						<div
							style={{
								...styles.assetLoaderContainer,
								height: 50,
								width: 50,
								padding: 7,
							}}
						>
							<ShowCircularProgress
								size={30}
								style={{ marginTop: "3", marginLeft: "3" }}
							/>
						</div>
					</div>
				) : (
					<FroalaEditor
						tag="textarea"
						model={template.email_message}
						onModelChange={(e) => handleModelChange(e)}
						config={FroalaConfig}
					/>
				)}
			</div>
		</div>
	);
};
export default MarketingTemplate;
