import React, { useState, useEffect } from "react";
import { constants } from "../../../services/constants/constants";
import DetailViewChildNote from "../components/detailViewChildNote";
import {getObjectParam, isValidParam} from "../../../services/helper/parameterVerifier";
import ListView from "./ListView";
import { getListViewData } from "../../../services/actions/listViewAcions";
import { refreshDetailViewListViewData } from "../../../services/actions/detailViewActions";
import Cookies from "universal-cookie";
import { getActiveTabInfo } from "../../../services/helper/sfTabManager";
import filter from "lodash/filter";
import EmailContainer from "../containers/emailContainer";
import Pets from "../components/petsComponent";
import { useDispatch, useSelector } from "react-redux";

const DetailViewChildContainer = ({ childProps, childTabs }) => {
	const dispatch = useDispatch();

	const note = useSelector((state) => state.note);
	const detailView = useSelector((state) => state.detailView);
	const listView = useSelector((state) => state.childListView);
	const app = useSelector((state) => state.app);
	const userlist = useSelector((state) => state.userlist);
	const cookies = new Cookies();

	useEffect(() => {
		if (listView.isRefreshDetailViewListView === true) {
			dispatch(refreshDetailViewListViewData(false, 0));
			refreshListView();
		}
	}, [listView]);

	const refreshListView = () => {
		let isRemembSearch = false;
		let filterObj = {};
		filterObj.isDetailView = true;
		filterObj.parent_object = listView.data.parent_object;
		filterObj.parent_record_id = detailView.data.record.id;

		filterObj.query_id = listView.data.query_id;
		filterObj.query_name = listView.data.query_name;
		filterObj.query_type = listView.data.query_type;
		filterObj.start_index = listView.data.start_index;
		filterObj.page_size = listView.data.page_size;

		if (
			childProps.childObject === constants.ACCOUNTS_OBJECT &&
			listView.data.parent_object === constants.ACCOUNTS_OBJECT
		) {
			filterObj.relationTableId = 10;
		}

		let searchParam = null;
		if (childProps.childObject === constants.NOTES) {
			let selectedNotesTypes = [];
			note.selectedNoteTypes.forEach((item, i) => {
				if (item === "All Types") {
					item = "All";
				}
				selectedNotesTypes.push(item);
			});
			if (selectedNotesTypes.length === 0) {
				selectedNotesTypes.push("All");
			}
			filterObj.sort_field_name = "note_date";
			filterObj.note_types = selectedNotesTypes;
			filterObj.sort_type = "desc";
			let cookiesName = app.me.id + "_note";
			let selectedNotetype = cookies.get(cookiesName) || "All";
			searchParam = {};

			if (isValidParam(listView.editFromRecordId)) {
				if (listView.editFromRecordId > 0) {
					isRemembSearch = true;
				}
			}

			if (selectedNotetype === "All") {
			} else if (selectedNotetype === "User") {
				let searchParamList = [];
				let userList = userlist.data;
				for (let i = 0; i < userList.length; i++) {
					let searchParam = { author: userList[i].text };
					searchParamList.push(searchParam);
				}
				searchParam["search"] = searchParamList;
			} else if (selectedNotetype === "System") {
				searchParam = { search_field_name: "author", search_text: "System" };
			} else if (selectedNotetype === "My") {
				searchParam = {
					search_field_name: "author",
					search_text: app.me.name,
				};
			} else if (selectedNotetype === "Starred") {
				searchParam = {
					search_field_name: "t_important_note",
					search_text: "Yes",
				};
			} else if (selectedNotetype === "Archived") {
				searchParam = {
					is_archive: "true",
				};
			}

			filterObj = { ...filterObj, ...searchParam };
		}
		if (isRemembSearch) {
			try {
				let searchArray = [];
				let tabInfo = getActiveTabInfo();
				let searchFieldName = null;
				if (
					isValidParam(tabInfo) &&
					tabInfo.hasOwnProperty("note_search") &&
					isValidParam(tabInfo.note_search)
				) {
					let searchText = tabInfo.note_search;
					if (searchText !== "") {
						if (searchText.includes(":")) {
							let arrSearchText = searchText.split(":");
							if (
								arrSearchText[0].trim().toLowerCase() === "type" ||
								arrSearchText[0].trim().toLowerCase() === "text" ||
								arrSearchText[0].trim().toLowerCase() === "author"
							) {
								searchFieldName = arrSearchText[0].trim().toLowerCase();
								searchText = arrSearchText[1].trim();

								if (
									(arrSearchText[0].trim().toLowerCase() === "type" ||
										arrSearchText[0].trim().toLowerCase() === "author") &&
									searchText.includes(",")
								) {
									let arraySearchText = searchText.split(",");
									for (let i = 0; i < arraySearchText.length; i++) {
										if (searchFieldName === "type") {
											searchFieldName = "note_type";
										}
										if (searchFieldName === "text") {
											searchFieldName = "note_text";
										}
										let searchObject = {};
										searchObject[searchFieldName] = arraySearchText[i].trim();
										searchArray.push(searchObject);
									}
								} else {
									if (searchFieldName === "type") {
										searchFieldName = "note_type";
									}
									if (searchFieldName === "text") {
										searchFieldName = "note_text";
									}
									let searchObject = {};
									searchObject[searchFieldName] = searchText;
									searchArray.push(searchObject);
								}
							} else {
								searchArray = populateSearchArray(searchText);
							}
						} else {
							searchArray = populateSearchArray(searchText);
						}
					}
					searchParam = getObjectParam(searchParam);
					if (isValidParam(searchParam)) {
						if (
							searchParam.hasOwnProperty("search_field_name") &&
							searchParam.hasOwnProperty("search_text")
						) {
							let noteParamSearchFieldName = searchParam.search_field_name;
							let noteParamSearchText = searchParam.search_text;
							let filterSearchArray = [];
							let filterSearchObject = {};
							filterSearchObject[noteParamSearchFieldName] =
								noteParamSearchText;
							filterSearchArray.push(filterSearchObject);

							searchParam["filter_search"] = filterSearchArray;
							searchParam.search = searchArray;
							delete searchParam.search_field_name;
							delete searchParam.search_text;
						} else if (searchParam.hasOwnProperty("search")) {
							if (searchArray.length > 0) {
								searchParam["filter_search"] = searchParam["search"];
								searchParam.search = searchArray;
							}
						} else {
							searchParam.search = searchArray;
						}
					}
					searchParam.search_type = constants.SEARCH_TYPE_ANYWHERE;
					filterObj = { ...filterObj, ...searchParam };
					dispatch(getListViewData(constants.NOTES, { ...filterObj }));
				} else {
					dispatch(getListViewData(childProps.childObject, filterObj));
				}
			} catch (error) {
				console.log(
					"Error in 'DetailViewChildContainer.js -> refreshListView() -> isRemembSearch':" +
						error
				);
			}
		} else {
			dispatch(getListViewData(childProps.childObject, filterObj));
		}
	};

	const populateSearchArray = (search_text) => {
		let searchArray = [];
		try {
			let searchObject = {};
			searchObject["note_type"] = search_text.trim();
			searchArray.push(searchObject);

			searchObject = null;
			searchObject = {};
			searchObject["note_text"] = search_text.trim();
			searchArray.push(searchObject);

			searchObject = null;
			searchObject = {};
			searchObject["author"] = search_text.trim();
			searchArray.push(searchObject);
		} catch (error) {
			console.log(
				"Error in 'DetailViewChildContainer.js -> changeSearchText()':" + error
			);
		}
		return searchArray;
	};

	const generateChildView = (parentObject, childObject, childObjectArr) => {
		try {
			if (isValidParam(childObject) && isValidParam(parentObject)) {
				let childObjectInfo = null;
				let childQueryParams = {};
				if (childObjectArr !== null && childObjectArr.length > 0) {
					childObjectInfo = filter(childObjectArr, function (obj) {
						return childObject === obj.child_object;
					});
					if (
						childObjectInfo !== null &&
						childObject !== constants.DETAILS_OBJECT
					) {
						childQueryParams = {
							query_id: childObjectInfo[0].query_id,
							query_name: childObjectInfo[0].query_name,
							query_type: childObject === constants.INVOICES_OBJECT ? childObjectInfo[0].query_type : "-1",
						};
					}
				}
				parentObject = parentObject.replace("_", "-");
				childObject = childObject.replace("_", "-");
				if (
					childObjectInfo !== null &&
					childObjectInfo.length > 0 &&
					childObjectInfo[0].child_object === constants.AUDIT_ANSWERS_OBJECT
				) {
					childObject = childObjectInfo[0].child_object;
				}

				if (childObject === constants.NOTES) {
					return (
						<div style={{ marginTop: "5px" }}>
							<DetailViewChildNote
								parentObject={parentObject}
								object={childObject}
								isDetailView={true}
								parentRecordId={childProps.parent_record_id}
							/>
						</div>
					);
				} else if (childObject === constants.EMAIL_OBJECT) {
					return (
						<div style={{ marginTop: "5px" }}>
							<EmailContainer
								isDetailView={true}
								parentObject={parentObject}
								parentRecordId={childProps.parent_record_id}
								object={childObject}
								childQueryParams={childQueryParams}
							/>
						</div>
					);
				} else if (childObject === constants.PETS_OBJECT) {
					return (
						<div style={{ marginTop: "5px" }}>
							<Pets
								isDetailView={true}
								parentObject={parentObject}
								parentRecordId={childProps.parent_record_id}
								object={childObject}
								childQueryParams={childQueryParams}
							/>
						</div>
					);
				} else {
					return (
						<div style={{ marginTop: "15px" }}>
							<ListView
								object={childObject}
								parentObject={parentObject}
								isDetailView={true}
								childQueryParams={childQueryParams}
								parentRecordId={childProps.parent_record_id}
								isChildIdListView={true}
							/>
						</div>
					);
				}
			}
		} catch (error) {
			console.log("Error in 'DetailViewChildContainer.js -> generateChildView()': " + error);
		}
	};
	return (
		<div>
			{ childTabs.length > 0 &&
			generateChildView(
				childProps.parentObject,
				childProps.childObject,
				detailView.childObjects
			)}
		</div>
	);
};

export default DetailViewChildContainer;
