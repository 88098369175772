import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import { constants } from '../../../services/constants/constants';
import { styles } from '../../../services/constants/styles';
import { Popover, MenuItem, Button, Stack, TextField, FormControl } from '@mui/material';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import FroalaEditor from "react-froala-wysiwyg";
import Froalaeditor from 'froala-editor'
import ShowCircularProgress from '../components/circularProgress';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { isValidParam, getIntParam, getStringParam } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getTemplate } from '../../../services/actions/sendMailActions';
import { OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';
import * as sfDialogs from '../components/sfDialogs';
import Autocomplete from '@mui/material/Autocomplete';
import PopOver from './PopOver';
import store from "../../../services/store";
import { useDispatch, useSelector } from "react-redux";
import { detailViewIsMounted, refreshDetailViewData } from "../../../services/actions/detailViewActions";
import { getTasksRefresh } from "../../../services/actions/taskActions";
import { updateAgreementComponentData } from '../../../services/actions/templateAction';

let popOverList = [
    {
        value: 'Delete',
        label: getLocalizedStrings().label.COMMON.DELETE,
    },
    {
        value: 'Download',
        label: getLocalizedStrings().label.COMMON.DOWNLOAD,
    }
];
let SignatureList = {
    Signature: "Signature 1",
    Signature2: "Signature 2",
    Signature3: "Signature 3",
    Signature4: "Signature 4",
    Signature5: "Signature 5",
    Signature6: "Signature 6",
    Signature7: "Signature 7",
    Signature8: "Signature 8",
    Signature9: "Signature 9",
    Signature10: "Signature 10"
}
const currentDateList = {
	"date": "Current Date",
	"date+30": "Current Date + 30 days",
	"date+60": "Current Date + 60 days",
	"date+90": "Current Date + 90 days",
}
const AgreementComponent = ({ data, closeDrawer }) => {
    const dispatch = useDispatch();
    const detailView = useSelector((state) => state.detailView);

    const [id, setId] = useState(0);
    const [template, setTemplate] = useState({
        name: null,
        templateBody: null,
        id: 0,
    });
    const topUpDialog = useSelector((state) => state.topUpDialog);
    const [editorLoader, setEditorLoader] = useState(true);
    const [options, setOptions] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [isFullScreenEditor, setIsFullScreenEditor] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [fullName, setFullName] = useState('');
    const [isTemplateListDisabled, setIsTemplateListDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [templateList, setTemplateList] = useState([]);
    const [agreementType, setAgreementType] = useState('');
    const [changeLog, setChangeLog] = useState({});
    const [attachFiles, setAttachFiles] = useState([]);
    const [editor, setEditor] = useState(null);
    const [selectedSigner, setSelectedSigner] = useState('Signature');
    const [selectedmergeFields, setSelectedmergeFields] = useState([]);
    const [isOpenSignatureTag, setIsOpenSignatureTag] = useState(false);
    const [personalize_fields, setPersonalize_fields] = useState({});
    const [error, setError] = useState({
        name: "",
    });
    const [filteredPopOverList, setFilteredPopOverList] = useState(popOverList);
    let customDrawer = useSelector((state) => state.appContainer.customDrawerProps);
    let agreementComponentRedux = useSelector((state) => state.template);

    let froalaRef;
    const inputRef = useRef(null)



    useEffect(() => {
        let minimizeList = customDrawer.minimizeList;
        if (minimizeList.hasOwnProperty('agreement') && minimizeList.agreement) {
            let _data = agreementComponentRedux.agreementComponentData;
            if (_data !== null) {
                if (_data.hasOwnProperty("agreementType")) {
                    setAgreementType(_data?.agreementType)
                  
                    if (_data?.agreementType === 'FDD' || _data?.agreementType === 'Franchise Agreement') {
                        setFilteredPopOverList(popOverList.filter(option => option.value !== 'Delete'));
                    }
                }
                if (_data.hasOwnProperty("template")) {
                    setTemplate(_data?.template);
                }
                if (_data.hasOwnProperty("selectedTemplate")) {
                    setSelectedTemplate(_data?.selectedTemplate);
                }
                if (_data.hasOwnProperty("attachFiles")) {
                    setAttachFiles(_data?.attachFiles);
                }
                if (_data.hasOwnProperty("id")) {
                    setId(_data?.id);
                }
            }
        }
    }, []);

    useEffect(()=>{
    let agreementObj = {
        selectedTemplate: selectedTemplate,
        agreementType: agreementType,
        attachFiles: attachFiles,
        template : template,
        id: id

    }
    if (JSON.stringify(agreementComponentRedux.agreementComponentData) !== JSON.stringify(agreementObj)) {
        dispatch(updateAgreementComponentData(agreementObj));
    }
    }, [template, selectedTemplate, agreementType, attachFiles, setTemplate, error, id])

    useEffect(() => {
        getMailMergeFields();
        getAttachmentData();
        //getContcatName();
        getCompanyName();
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);
    const handleEditorFocus = () => {
        handlePopUpClose();
    };
    const disableEditorBlurEffect = () => {
        const divElement = document.querySelector('.fr-popup');
        if (divElement) {
            divElement.classList.add('fr-block');
        }
    }
    const handleDocumentClick = (event) => {
        if (popUpClose(event.target)) {
            handlePopUpClose()

        }
    };
    const handlePopUpClose = () => {
        let _store = store.getState()
        const divElement = document.querySelector('.fr-popup');
        if (divElement && !_store.appContainer.dialogProps.isOpenDialog && !_store.topUpDialog.isOpenDialog) {
            divElement.classList.remove('fr-block');
            if (froalaRef && froalaRef?.current) {
                froalaRef.current.events.enableBlur();
            }
        }
    };
    const popUpClose = (element) => {
        let _popUp = false;
        const popUpElemrnt = document.querySelector('.fr-popup');
        const dialogElement = document.querySelector('.MuiDialog-paper');
        const insertElement = document.querySelector('.btn-insert');
        if (!insertElement && !dialogElement && !popUpElemrnt?.contains(element)) {
            _popUp = true;
        } else {
            _popUp = false;
        }
        if (dialogElement && !dialogElement.contains(element)) {
            if (popUpElemrnt && !popUpElemrnt.contains(element)) {
                if (insertElement && !insertElement.contains(element)) {
                    _popUp = false;
                }
            }
        }
        return _popUp;
    }

    /*const getContcatName = () => {
        let url = null;
        let contactId = 0;
        let contcatList = detailView.avaliableContacts;
        let param = null;
        let contactObj = null;
        let company = '';
        let recordId = detailView.data.record.id;
        try {
            param = { record_id: recordId }
            let object = detailView.data.object.toLowerCase();
            if (object === constants.ACCOUNTS_OBJECT) {
                url = endPoints.ATTACHMENT.GET_ACCOUNT_CONTACT_ID;
                let promise = Promise.resolve(HTTPClient.get(url, param));
                promise.then((response) => {
                    if (isValidParam(response)) {
                        contactId = response;
                        contactObj = contcatList.filter((obj) => { return obj.id === contactId })
                        company = contactObj[0]?.company;
                        setFullName(company);
                    }
                })
            } else if (object === constants.CONTACTS_OBJECT) {
                contactObj = contcatList.filter((obj) => { return obj.id === recordId })
                company = contactObj[0]?.company;
                setFullName(company);
            }

        } catch (error) {
            console.error("Error in 'agreementComponent.js -> getContcatName()':" + error);

        }
    }*/

    const getCompanyName = () => {
        try {
            let _company = "";
            let object = detailView.data.object.toLowerCase();
            let recordId = detailView.data.record.id;
            let contcatList = detailView.avaliableContacts;
            let contactObj = null;
            if (object === constants.ACCOUNTS_OBJECT) {
                _company = detailView.data?.company;
            } else if (object === constants.CONTACTS_OBJECT) {
                contactObj = contcatList.filter((obj) => { return obj.id === recordId });
                if (contactObj != null && contactObj != "") {
                    _company = contactObj[0]?.company;
                }
            }
            setFullName(_company);
        } catch (error) {
            console.error("Error in 'agreementComponent.js -> getCompanyName()':" + error);
        }
    }

    const getMailMergeFields = () => {
        try {
            var response = Promise.resolve(
                HTTPClient.get(endPoints.AGREEMENT_TEMPLATE.NEW, null)
            );
            let personalizeArray = {};
            let i;
            let j;
            response.then((response) => {
                let mergeFields = response?.mmFields?.available_mail_merge_fields;
                for (i in mergeFields) {
                    for (j in mergeFields[i]) {
                        personalizeArray[j] = mergeFields[i][j];
                    }
                }
                let tempPersonalize_fields = personalizeArray;
                setPersonalize_fields(tempPersonalize_fields);
            })
        } catch (error) {
            console.error("Error in 'agreementComponent.js -> getMailMergeFields()':" + error);

        }
    };
    const getAttachmentData = () => {
        let url = null;
        let templateList = null;
        let type = '';
        try {
            url = endPoints.ATTACHMENT.GET_TEMPLATE;
            if (isValidParam(data)) {
                if (getStringParam(data.mode) === 'edit') {
                    type = 'edit';
                }
            } else {
                type = 'new';
            }

            let params = {};
            params.type = type;
            params.parent_record_id = detailView.data.record.id;
            params.parent_table_id = OBJECT_TABLEID_MAP[detailView.data.object.toLowerCase()];
            let promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    templateList = response;
                    if (Array.isArray(templateList)) {
                        let options = templateList.map((obj) => { return { id: obj.t_pk, label: obj.t_name, value: obj.t_name, type: obj.agreement_type } });
                        setTemplateList(templateList);
                        setOptions(options);
                        setEditorLoader(false);
                        setIsLoading(false);

                        if (isValidParam(data) && getStringParam(data.mode) === 'edit') {
                            let agreementObj = {}
                            let param = { attchment_id: data.id };
                            setId(data.id);
                            let url = endPoints.ATTACHMENT.GET_ATTACHMENT_AGREEMENT;
                            let promise = Promise.resolve(HTTPClient.get(url, param));
                            promise.then((response) => {
                                if (isValidParam(response)) {
                                    let templateId = response.template_id;
                                    let selectedTemplate = options.filter((obj) => { return obj.id === templateId });
                                    let tempTemplate = {};
                                    tempTemplate.name = response.document_name;
                                    tempTemplate.id = templateId;
                                    tempTemplate.templateBody = response.template_content;

                                    let attachedFDDPdf = response?.fdd_info

                                    if (isValidParam(attachedFDDPdf) && attachedFDDPdf != undefined) {
                                        let _attachFiles = [];
                                        let attachObj = {};
                                        attachObj.name = attachedFDDPdf.fdd_doc_name;
                                        attachObj.src = attachedFDDPdf.fdd_file_url;
                                        attachObj.fdd_file_id = attachedFDDPdf.fdd_file_id;
                                        _attachFiles.push(attachObj);
                                        setAttachFiles([..._attachFiles]);
                                    }
                                    let minimizeList = customDrawer.minimizeList;
                                    if (minimizeList.hasOwnProperty('agreement') && minimizeList.agreement) {
                                        //bypass
                                    }else{
                                    setTemplate(tempTemplate);
                                    }
                                    setIsTemplateListDisabled(true);
                                    setEditorLoader(false);
                                    setIsLoading(false);
                                    setSelectedTemplate(selectedTemplate[0]?.value);
                                 const selectedType = selectedTemplate[0]?.type;
                                    if (selectedType === 'FDD' || selectedType === 'Franchise Agreement') {
                                        setFilteredPopOverList(popOverList.filter(option => option.value !== 'Delete'));
                                    } else {
                                        setFilteredPopOverList(popOverList);
                                    }
                                }
                            })

                        }

                    } else {
                        dispatch(showCustomSnackBar("No Agreement Templates are there.", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        closeDrawer();
                    }
                }
            })

        } catch (error) {
            console.error("Error in 'agreementComponent.js -> getTemplateData()':" + error);
        }
    }

    const validateForm = () => {
        let tempTemplate = template;
        let tempError = error;
        let isValid = true;
        if (tempTemplate.name == null || tempTemplate.name === "") {
            isValid = false;
            tempError["name"] =
                getLocalizedStrings().message.AGREEMENT_TEMPLATE.NAME_BLANK;
            setError({ ...tempError });
        }
        if (tempTemplate.templateBody !== null && tempTemplate.templateBody.trim() !== "") {
            const tagCount = 10;
            let signatureList = [];
            for (let i = 0; i < tagCount; i++) {
                signatureList.push(i == 0 ? 'Signature' : `Signature${i + 1}`);
            }
            let templateBody = tempTemplate.templateBody;
            let isSequenced = true;
            let isMessageValid = true;
            let isTagPresent = true;
            let count = 0;

            signatureList.forEach(e => {
                if (isMessageValid) {
                    let sIdx = '${' + e + '.';
                    let sTag = '${' + e + '}';
                    if (templateBody.includes(sIdx) || templateBody.includes(sTag)) {
                        count++;
                        if (!isSequenced) {
                            isMessageValid = false;
                        }
                        if (templateBody.includes(sIdx) && !templateBody.includes(sTag)) {
                            isTagPresent = false;
                        }
                        if (!templateBody.includes(sTag)) {
                            isMessageValid = false;
                        }
                    } else {
                        isSequenced = false;
                    }
                }
            })

            if (!isMessageValid) {
                isValid = false;
                let msg = '';
                msg += "<span>" + getLocalizedStrings().message.AGREEMENT_TEMPLATE.SIGNATURE_TAGS_NOT_SEQUENCED_1 + "<br /></span>";
                msg += "<span>" + getLocalizedStrings().message.AGREEMENT_TEMPLATE.SIGNATURE_TAGS_NOT_SEQUENCED_2 + "<br /></span>";
                sfDialogs.alert(
                    getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                    msg,
                    null
                );
            }
            if (!count) {
                isValid = false;
                sfDialogs.alert(
                    getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                    getLocalizedStrings().message.AGREEMENT_TEMPLATE.SIGNATURE_TAGS_MISSING,
                    null
                );
            }

            if (!isTagPresent) {
                isValid = false;
                sfDialogs.alert(
                    getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                    getLocalizedStrings().message.AGREEMENT_TEMPLATE.SIGNATURE_TAGS_CONTACT_FIELDS_COMBO_MISSING,
                    null
                );
            }
        }

        return isValid;
    };

    const saveTemplate = () => {
        let url = null;
        let params = {};
        if (!validateForm()) {
            return false;
        }
        try {
            if (!isSaving) {
                setIsSaving(true);
                let validationObj = validateAgreement();
                if (validationObj.isValid) {
                    params.name = template.name;
                    let content = template.templateBody.replace(/(<img("[^"]*"|[^\/">])*)>/gi, "$1/>");
                    content = content.replace(/(<video("[^"]*"|[^\/">])*)>/gi, "$1/>");
                    params.content = content;
                    params.parent_recordid = detailView.data.record.id;
                    params.parent_object = detailView.data.object.toLowerCase();
                    params.id = getIntParam(id);
                    let templateName = selectedTemplate;
                    let templateObj = options.filter((obj) => { return obj.value === templateName });

                    params.template_id = templateObj[0].id;
                    params.agreement_type = agreementType;
                    if (attachFiles.length > 0 && isValidParam(attachFiles[0].fdd_file_id)) {
                        params.fdd_file_id = attachFiles[0].fdd_file_id !== undefined && attachFiles[0].fdd_file_id !== null ? parseInt(attachFiles[0].fdd_file_id) : 0;
                    }

                    url = endPoints.ATTACHMENT.SAVE_TEMPLATE;
                    let promise = Promise.resolve(HTTPClient.post(url, params));
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            if (response.status === 0) {
                                let data = getStringParam(response.data);
                                if (data !== 'duplicate') {
                                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                    dispatch(refreshDetailViewListViewData(true));
                                    setId(response.data);
                                    let selectedTemplate = options.filter((obj) => { return obj.id === templateObj[0].id });
                                    const selectedType = selectedTemplate[0]?.type;
                                    if (agreementType === 'FDD' || agreementType === 'Franchise Agreement' || selectedType === 'FDD' || selectedType === 'Franchise Agreement') {
                                        setFilteredPopOverList(popOverList.filter(option => option.value !== 'Delete'));
                                    } else {
                                        setFilteredPopOverList(popOverList);
                                    }
                                    setIsSaving(false);
                                } else {
                                    let tempError = error;
                                    tempError["name"] = getLocalizedStrings().message.AGREEMENT_TEMPLATE.DUPLICATE_TEMPLAE_EXIST;
                                    setError({ ...tempError });
                                    dispatch(showCustomSnackBar(getLocalizedStrings().message.AGREEMENT_TEMPLATE.DUPLICATE_TEMPLAE_EXIST, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                    setIsSaving(false);
                                }
                            }
                        }
                    })
                } else {
                    setIsSaving(false);
                    dispatch(showCustomSnackBar(getStringParam(validationObj.errorMesg), styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
            }
        } catch (error) {
            console.error("Error in 'agreementComponent.js -> saveTemplate()':" + error);
        }
    }

    const deleteAgreementTemplate = () => {
        let url = null;
        let tempId = getIntParam(id);
        try {
            if (tempId > 0) {
                url = endPoints.ATTACHMENT.DELETE_TEMPLATE;
                let params = {};
                params.id = tempId;
                params.parent_recordid = detailView.data.record.id;
                params.parent_object = detailView.data.object.toLowerCase();

                let promise = Promise.resolve(HTTPClient.post(url, params));
                promise.then((response) => {
                    if (isValidParam(response) && response.status === 0) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        closeDrawer();
                        setId(0);

                    }
                })
            } else {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.AGREEMENT_TEMPLATE.RECORD_NOT_EXIST, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        } catch (error) {
            console.error("Error in 'agreementComponent.js -> deleteAgreementTemplate()':" + error);
        }
    }

    const downloadAgreement = () => {
        let url = null;
        let tempId = getIntParam(id);
        try {
            if (tempId > 0) {
                url = endPoints.ATTACHMENT.DOWNLOAD_ATTACHMENT;
                let params = { id: tempId };
                let templateName = template.name;

                var formData = new FormData();
                formData.append('input_param', JSON.stringify(params));
                HTTPClient.postFormDataDownloadFile(url, formData, templateName + '.pdf');
                return;

            } else {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.AGREEMENT_TEMPLATE.SAVE_TO_DOWNLOAD, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        } catch (error) {
            console.error("Error in 'agreementComponent.js -> downloadAgreement()':" + error);
        }
    }

    const validateAgreement = () => {
        try {
            let templateName = getStringParam(template.name);
            let templateId = getStringParam(selectedTemplate);
            let validationObj = {};
            if (templateName === '') {
                validationObj.errorMesg = getLocalizedStrings().message.AGREEMENT_TEMPLATE.TEMPLATE_CAN_NOT_EMPTY;
                validationObj.isValid = false;
                return validationObj;
            } else if (templateId === '') {
                validationObj.errorMesg = getLocalizedStrings().message.AGREEMENT_TEMPLATE.SELECT_TEMPLATE;
                validationObj.isValid = false;
                return validationObj;
            } else {
                validationObj.errorMesg = '';
                validationObj.isValid = true;
                return validationObj;
            }
        } catch (error) {
            console.error("Error in 'agreementComponent.js -> validateAgrrement()':" + error);
        }
    }

    const handleMoreActionChange = (value) => {
        try {
            if (value === 'Delete') {
                deleteAgreementTemplate();
            } else if (value === 'Download') {
                downloadAgreement();
            }
        } catch (error) {
            console.error("Error in 'agreementComponent.js -> handleMoreActionChange()':" + error);

        }
    }


    const onTemplateNameChange = (event) => {
        try {
            let target = event.target;
            let tempTemplate = template;
            tempTemplate.name = getStringParam(target.value);
            setTemplate({ ...tempTemplate });

            let tempError = error;
            tempError["name"] = null;
            setError({ ...tempError });
        } catch (error) {
            console.error("Error in 'agreementComponent.js -> onTemplateNameChange()':" + error);
        }
    }

    const handleTemplateChange = (event, option) => {
        try {
            let templateName = option.value;
            let agreementObj = {};
            agreementObj.selectedTemplate = templateName;
            setSelectedTemplate(templateName);
            let templateBody = templateList.filter((obj) => { return obj.t_name === option.value });
            let tempTemplate = template;
            tempTemplate.templateBody = templateBody[0].t_msg;;
            setTemplate({ ...tempTemplate });

            let tempError = error;
            tempError["name"] = null;
            setError({ ...tempError });
            let param = {};
            param.template_id = option.id;
            param.parent_record_id = detailView.data.record.id;
            param.parent_table_id = OBJECT_TABLEID_MAP[detailView.data.object.toLowerCase()];
            param.call_from = "add_agreement";

            let promise = Promise.resolve(getTemplate(param));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let mailMsg = response.mail_body;

                    let tempTemplate = template;
                    tempTemplate.templateBody = mailMsg;

                    let _fullName = fullName;
                    if (_fullName != null && _fullName != "") {
                        tempTemplate.name = fullName + ' ' + templateName;
                    } else {
                        tempTemplate.name = templateName;
                    }
                    agreementObj.template = tempTemplate;
                    agreementObj.agreementType = option.type;
                    setTemplate({ ...tempTemplate });

                    setAgreementType(option.type);
                    let attachedFDDPdf = response?.attachment_info
                    if (isValidParam(attachedFDDPdf) && attachedFDDPdf != undefined) {
                        let _attachFiles = [];
                        let attachObj = {};
                        attachObj.name = attachedFDDPdf.name;
                        attachObj.src = attachedFDDPdf.url;
                        attachObj.fdd_file_id = attachedFDDPdf.id;
                        _attachFiles.push(attachObj);
                        setAttachFiles([..._attachFiles]);
                        agreementObj.attachFiles = _attachFiles
                    }
                    dispatch(updateAgreementComponentData(agreementObj));

                    if (response.alert) {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, response.alert, null);
                    }
                }
            });
        } catch (error) {
            console.error("Error in 'agreementComponent.js -> handleTemplateChange()':" + error);
        }
    }

    const onCancel = () => {
        try {
            closeDrawer();
        } catch (error) {
            console.error("Error in 'agreementComponent.js -> onCancel()':" + error);
        }
    }

    const getLibrary = (editor, type) => {
        editor.events.disableBlur();
        disableEditorBlurEffect();
        let data = {
            editor: editor,
            contentStyle: { width: '80%', maxWidth: 'none' },
            type: type,
            fullWidth: true,
            maxWidth: 'md',
        }
        dispatch(getAppDialog(true, constants.LIBRARY_DIALOG, getLocalizedStrings().message.COMMON.LIBRARY_DIALOG_TITLE, (event) => handleLibraryResponse(event), data, null));

    }
    const handleLibraryResponse = (obj) => {
        obj.editor.events.enableBlur();
        if (obj.type === 'link' && obj.src !== '') {
            document.getElementsByName('href')[0].value = obj.src;
        } else if (obj.type === 'image' && obj.src !== '') {
            obj.editor.image.insert(obj.src)
        }
        dispatch(getAppDialog(false, constants.LIBRARY_DIALOG, null, null, null, null));
    }
    const getVideoInsertDialog = (editor, videoType) => {
        editor.events.disableBlur();
        let data = {
            editor: editor,
            contentStyle: { width: '80%', maxWidth: 'none' },
            videoType: videoType
        }
        let title = null;
        if (videoType === constants.VIDEO_TYPE_YOUTUBE) {
            title = getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE;
        } else if (videoType === constants.VIDEO_TYPE_WISTIA) {
            title = getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE;
        }
        dispatch(getAppDialog(true, constants.VIDEO_INSERT_DIALOG, title, (event) => handleVideoInsertResponse(event), data, null));

    }

    const handleVideoInsertResponse = (obj) => {
        obj.editor.events.focus();
        dispatch(getAppDialog(false, constants.VIDEO_INSERT_DIALOG, null, null, null, null));
        obj.editor.html.insert('<a href="' + obj.video_link + '" target="_blank" class="editor_video-selector"><img  height="220" width="380" "src="' + obj.thumbnail.url + '" title="' + obj.title + '" /></a>');
        obj.editor.events.focus();
    }
    const expandEditor = () => {
        try {
            setIsFullScreenEditor(!isFullScreenEditor)
            if (isFullScreenEditor) {
                $('[id^=expand_editor]').find($(".fa")).removeClass('fa fa-expand').addClass('fa fa-compress');
                setTimeout(function () { $('[id^=expand_editor]').addClass('fr-active'); }, 500);
            } else
                $('[id^=expand_editor]').find($(".fa")).removeClass('fa fa-compress').addClass('fa fa-expand');
        } catch (error) {
            console.error("Error in 'agreementComponent.js -> expandEditor()':" + error);
        }
    }
    const handleModelChange = (event) => {
        let tempTemplate = template;
        tempTemplate.templateBody = event;
        setTemplate({ ...tempTemplate });

    };



    if ((!editorLoader) && (!isFullScreenEditor)) {
        Froalaeditor.DefineIcon("signature_tag", { NAME: "fa-regular fa-file-signature", template: "font_awesome" });
        Froalaeditor.RegisterCommand("signature_tag", {
            title: getLocalizedStrings().label.BROADCAST_CAMPAIGNS.ADD_SIGNATURE,
            focus: false,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                setEditor(this);
                setIsOpenSignatureTag(true);
            },
        });

        Froalaeditor.DefineIcon("current_date_tag", { NAME: "fa-regular fa fa-calendar", template: "font_awesome" });
        Froalaeditor.RegisterCommand("current_date_tag", {
            title: "Insert Date",
            type: 'dropdown',
            focus: false,
            undo: true,
            refreshAfterCallback: true,
            options: currentDateList,
            callback: function (cmd, val) {
                this.html.insert(" ${" + val + "} ");
            },
            // Callback on refresh.
            refresh: function ($btn) {
            },
            // Callback on dropdown show.
            refreshOnShow: function ($btn, $dropdown) {
            }
        });

        Froalaeditor.DefineIcon('library', { NAME: 'image', template: "font_awesome" });
        Froalaeditor.RegisterCommand('library', {
            title: getLocalizedStrings().label.MAIL.INSERT_IMAGE,
            focus: false,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                getLibrary(this, 'image')
            }
        });
        Froalaeditor.DefineIcon('link_library', { NAME: 'file-archive-o', template: "font_awesome" });
        Froalaeditor.RegisterCommand('link_library', {
            title: getLocalizedStrings().label.MARKETING_TEMPLATE.SELECT_FROM_LIBRARY,
            focus: false,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                getLibrary(this, 'link')
            }
        });

        Froalaeditor.DefineIcon("insertYouTubeVideo", {
            NAME: "fa-brands fa-youtube", template: "font_awesome"
        });
        Froalaeditor.RegisterCommand('insertYouTubeVideo', {
            title: getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE,
            focus: true,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                getVideoInsertDialog(this, constants.VIDEO_TYPE_YOUTUBE)
            }
        });
        Froalaeditor.DefineIcon("insertWistiaVideo", {
            SRC: "/asset/images/wistia.png",
            ALT: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
            template: "image",
        });
        Froalaeditor.RegisterCommand('insertWistiaVideo', {
            title: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
            focus: true,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                getVideoInsertDialog(this, constants.VIDEO_TYPE_WISTIA)
            }
        });

        const handelSignerSection = (value) => {
            setSelectedSigner(value);
            setSelectedmergeFields([]);
        }
        const handelFieldsSection = (value) => {
            let _fieldList = selectedmergeFields;
            const fi = _fieldList.findIndex((i) => i === value);
            if (fi > -1) {
                _fieldList.splice(fi, 1);
            } else {
                _fieldList.push(value);
            }
            setSelectedmergeFields([..._fieldList]);
        }
        const handelCloseSigner = () => {
            setEditor(null);
            setSelectedSigner('Signature');
            setSelectedmergeFields([]);
            setIsOpenSignatureTag(false);
        }
        const handelSignerSet = () => {
            let _tag = "";
            if (selectedmergeFields.length) {
                selectedmergeFields.forEach(field => { _tag += "${" + selectedSigner + "." + field + "}" });
            } else {
                _tag = " ${" + selectedSigner + "} ";
            }
            setSelectedSigner('Signature');
            setSelectedmergeFields([]);
            setIsOpenSignatureTag(false);
            editor.html.insert(_tag);
            handleModelChange(editor.html.get());
        }
        let FroalaConfig = {
            key: constants.FROALA_EDITOR_KEY,
            placeholderText: getLocalizedStrings().label.AGREEMENT_TEMPLATE.TYPE_HERE,
            height: document.body.clientHeight - 200,
            toolbarButtons: constants.AGREEMENT_EDITOR_TOOLBAR_CONFIGURATION,
            toolbarButtonsMD: constants.AGREEMENT_EDITOR_TOOLBAR_CONFIGURATION,
            toolbarButtonsSM: constants.AGREEMENT_EDITOR_TOOLBAR_CONFIGURATION,
            toolbarButtonsXS: constants.AGREEMENT_EDITOR_TOOLBAR_CONFIGURATION,
            colorsBackground: constants.EDITOR_TOOLBAR_BACKGROUND_COLOR_PALETTE,
            colorsText: constants.EDITOR_TOOLBAR_TEXT_COLOR_PALETTE,
            toolbarSticky: false,
            enter: FroalaEditor.ENTER_BR,
            iframe: true,
            htmlUntouched: true,
            attribution: false,
            inlineMode: false,
            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
            linkInsertButtons: ['linkEdit', 'linkRemove', 'link_library'],
            imageEditButtons: ['imageAlign', 'imageRemove', 'imageLink', 'imageDisplay', 'imageAlt', 'imageSize', 'linkOpen', 'linkEdit', 'linkRemove'],
            tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', '-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign'],
            quickInsertButtons: [],
            imageUpload: false,
            imagePaste: false,
            fileUpload: false,
            // autofocus: autoFocus,
            fontSize: constants.EDITOR_FONT_SIZE,
            fontFamily: {
                'Adobe Garamond Pro': 'Adobe Garamond Pro',
                'Arial,Helvetica,sans-serif': 'Arial',
                'Futura Std': 'Futura Std',
                'Georgia,serif': 'Georgia',
                'Impact,Charcoal,sans-serif': 'Impact',
                "RobotoSegoe": 'Roboto',
                'Tahoma,Geneva,sans-serif': 'Tahoma',
                "'Times New Roman',Times,serif": 'Times New Roman',
                'Verdana,Geneva,sans-serif': 'Verdana',
            },
            fontFamilySelection: true,
            events: {
                'froalaEditor.initialized': function (e, editor) {
                    $('.fr-command').on('click', function () {
                        if ($(this).data('cmd') === 'insertLink') {
                            $('input[name="target"]').prop('checked', true);
                        }
                    });
                }
            },
            events: {
                'initialized': function () {
                    window.WEBSPELLCHECKER.init({
                        container: this.$iframe ? this.$iframe[0] : this.el
                    });
                }
            },
            events: {
                focus: handleEditorFocus,
            },
            zIndex: 2501
        }

        let uKey = Math.random();

        const focusOntextfield = (element) => {
            inputRef.current = element;
            inputRef.current?.focus();
        }
        return (
            <div style={styles.workAreaContainer} >
                <div style={{ paddingLeft: '26px', paddingRight: '26px' }}>

                    <div>
                        < >
                            <div style={{ paddingTop: '8px', marginLeft: '15px' }}>
                                <Button
                                    key='save'
                                    onClick={() => saveTemplate()}
                                    style={{ ...styles.primaryButton, verticalAlign: 'top' }}
                                    disabled={isSaving}
                                >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                                <Button
                                    key='cancel'
                                    onClick={() => onCancel()}
                                    style={{ ...styles.secondaryButton, verticalAlign: 'top' }}
                                >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                                {
                                    id > 0 &&
                                    <div style={{ backgroundColor: '#ffffff', height: '27px', width: '28px', border: '1px solid rgb(224,224,224)', display: 'inline-block' }}>
                                        <PopOver
                                            id={"more"}
                                            key={"more"}
                                            name={"more"}
                                            buttonEndIcon={"more_vert"}
                                            buttonStyle={{ fontSize: '20px', color: '#717171', cursor: 'pointer', paddingTop: '3px', paddingLeft: '3px' }}
                                            labelposition={"before"}
                                            buttonLabel={""}
                                            options={filteredPopOverList}
                                            onclickAction={(value) => handleMoreActionChange(value)}
                                            isShowIcon={true}
                                            buttonLineOrigin={[22, 20]}
                                        />
                                    </div>

                                }

                            </div>
                        </ >

                        <div className='row' style={{ marginTop: '10px', marginLeft: '0px' }}>
                            <div className='col-sm-7'>
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        key='name'
                                        onChange={(e) => onTemplateNameChange(e)}
                                        value={template.name ? template.name : ''}
                                        fullWidth={true}
                                        label={'Name (required)'}
                                        inputprops={{ maxlength: '255' }}
                                        autoComplete="new-password"
                                        margin='dense'
                                        size='small'
                                        error={Boolean(error.name)}
                                        helperText={error.name}
                                        inputRef={Boolean(error.name) ? focusOntextfield : ''}
                                        className={"sf-fields-bg"}
                                    />
                                </FormControl>
                            </div>
                            <div className='col-sm-5'>
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <Autocomplete
                                        key={"template" + "_" + uKey}
                                        id={"template"}
                                        name="template"
                                        value={options.find(v => v.value === selectedTemplate)}
                                        options={options}
                                        onChange={(event, newValue) => handleTemplateChange(event, newValue)}
                                        getOptionLabel={(option) => option.label}
                                        style={{ ...styles.sfFormFieldContainer, paddingLeft: 0, width: 300 }}
                                        className="sendMail"
                                        disabled={isTemplateListDisabled}
                                        disableClearable={false}
                                        renderInput={(params) => <TextField {...params} placeholder={getLocalizedStrings().label.MARKETING_TEMPLATE.SELECT_TEMPLATE} margin='dense' size='small' className={"sf-fields-bg"} />}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: '10px', marginLeft: '0px', marginBottom: '20px' }}>
                        <div className="col-sm-12">
                            <FroalaEditor tag='textarea' model={template.templateBody} onModelChange={(e) => handleModelChange(e)} config={FroalaConfig} ref={(ref) => froalaRef = ref} />
                            <Popover
                                name="noteTypeList"
                                key={"sf-auto-complete-noteTypeList"}
                                id={"noteTypeList"}
                                anchorEl={document.querySelector('[id*="signature_tag"]')}
                                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                                targetOrigin={{ horizontal: "left", vertical: "top" }}
                                open={isOpenSignatureTag}
                                onClose={() => handelCloseSigner()}
                            >
                                <div style={{ width: 300, padding: 5 }}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ height: 300, overflow: 'auto', borderRight: '1px solid', borderColor: '#aeaeae' }}>
                                            {Object.keys(SignatureList).map((key) => {
                                                return <MenuItem value={key} style={{ backgroundColor: selectedSigner === key ? '#00000042' : '', ...styles.popoverMenuItem }} onClick={() => handelSignerSection(key)}>
                                                    <span>
                                                        {SignatureList[key]}
                                                    </span>
                                                </MenuItem>
                                            })}
                                        </div>
                                        <div style={{ height: 300, overflow: 'auto' }}>
                                            {Object.keys(personalize_fields).map((key) => {
                                                return <MenuItem value={key} style={{ backgroundColor: selectedmergeFields.find((i) => i === key) ? '#00000042' : '', ...styles.popoverMenuItem }} onClick={() => handelFieldsSection(key)}>
                                                    <span>
                                                        {personalize_fields[key]}
                                                    </span>
                                                </MenuItem>
                                            })}
                                        </div>
                                    </div>
                                    <div style={{ padding: 5, borderTop: '1px solid', borderColor: '#aeaeae', width: '100%' }}>
                                        <Stack spacing={2} direction="row" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button variant="outlined" size="small" style={styles.secondaryButton} onClick={() => handelCloseSigner()}>cancel</Button>
                                            <Button variant="contained" size="small" style={styles.primaryButton} onClick={() => handelSignerSet()}>Ok</Button>
                                        </Stack>
                                    </div>
                                </div>
                            </Popover >
                        </div>
                    </div>
                    <div style={{ ...styles.row, marginTop: '10px' }}>
                        {attachFiles.length > 0 &&
                            <AttachmentFiles files={attachFiles} />}

                    </div>
                </div>
            </div>
        )
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }
};
const AttachmentFiles = ({ files }) => {
    const attachmentList = files.map((f, index) => (
        <div style={{ display: 'flex' }}>
            <i
                className="fa fa-paperclip"
                aria-hidden="true"
                style={{ paddingRight: "5px", cursor: "pointer" }}
            />
            <li key={f.name + index}>
                <span
                    style={{
                        float: 'left',
                        textAlign: 'left',
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: 'block',
                        whiteSpace: 'nowrap',
                        // width: '150px',
                        // maxWidth: '50ch'
                    }}
                    title={f.fileName}
                >
                    {f.name.length > 50 ? f.name.substring(0, 50) + "..." : f.name}

                </span>

            </li>
        </div>
    ));

    if (attachmentList.length > 0) {
        return <ul>{attachmentList}</ul>;
    } else {
        return null;
    }
};
export default AgreementComponent