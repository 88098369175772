import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAppDrawer, getAppCustomDrawer, minimizeAppCustomDrawer } from '../../../services/actions/appContainerActions';
import { constants, TABLEID_OBJECT_MAP, OBJECT_TABLEID_MAP, WORKFLOW_SETUP, INTAKE_FORM } from '../../../services/constants/constants';
import {
    isValidParam,
    getStringParam,
    getBooleanParam,
    getIntParam,
    getArrayParam,
    getObjectParam,
    makeValidAppUrl
} from '../../../services/helper/parameterVerifier';
import { franchiseLogin } from "../../../services/actions/unitsAction.js";
import { LinkToType } from '../../../services/constants/link';
import * as sfDialogs from '../components/sfDialogs';
import { Navigate } from 'react-router-dom';
import { getLookupRecordInfo, donloadAttachmentData, getAttachmentAgreementData, setMounted, updateScrollPosition } from '../../../services/actions/listViewAcions';
import { dateTimeFormat, dateFormat, timeFormat, timeZone } from '../../../services/helper/utils';
import { escapeHtml, hasAccessPermission } from '../../../services/helper/common';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { selectTabByIndex, getActiveTab, TYPE_DETAIL_VIEW, TYPE_OTHER_VIEW, addTab,closeTab, updateTabByTabIndex, getActiveTabInfo } from '../../../services/helper/sfTabManager';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { getRecordPromise } from '../../../services/actions/sfFormActions';
import { manipulateListViewRowData } from '../../../services/actions/listViewAcions';
import { getCallDrawer } from '../../../services/actions/callAction';
import { Menu, Select, MenuItem, InputLabel, Icon } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { getObjectSpecificEndpoint } from '../../../services/helper/utils';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import '../../../asset/styles/css/react-data-grid.css';
import {
    REPORTED_SALES_ACTIONS,
} from '../../../services/constants/constants';
import { getWorkflowSetupData } from '../../../services/actions/workflowSetupAction';
import { constant, isArray } from 'lodash';
import { updateTabInfo } from '../../../services/helper/sfTabManager';

const cellExpandStyle = {
    //float: 'right',
    float: 'inline-start',
    display: 'table',
    blockSize: '100%',
}
const cellSpanExpandStyle = {
    display: 'table-cell',
    verticalAlign: 'middle',
    cursor: 'pointer',

}

const moment = require('moment');

const getListViewInstanceFromRedux = (state, props) => {
    let tabInfo = getActiveTabInfo();
    let isReport = getBooleanParam(tabInfo.isReport);
    let isAssignRule = getBooleanParam(tabInfo.isAssignRule);
    if (getBooleanParam(props.isDetailView)) {
        return state.childListView;
    } else if (getBooleanParam(isReport)) {
        return state.otherListView;
    } else if (getBooleanParam(isAssignRule)) {
        return state.assignListView;
    } else if (getBooleanParam(props.isDuplicate)) {
        return state.duplicateListView;
    } else if (getBooleanParam(props.isExpandedTaskList)) {
        return state.detailViewTaskList;
    } else {
        return state.listView;
    }
};
const mapStateToProps = (state, props) => {
    return {
        tab: state.tab,
        app: state.app,
        // parentObject: isValidParam(state.listView.data) ? getStringParam(state.listView.data.parent_object) : '',
        // parentRecordId: isValidParam(state.listView.data) ? getIntParam(state.listView.data.parent_record_id) : 0,
        userList: getArrayParam(state.userlist.data),
        detailViewRecord: state.detailView.record,
        detailView: state.detailView.data,
        customDrawer: state.appContainer.customDrawerProps,
        listView: getListViewInstanceFromRedux(state, props),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAppDrawer: (isOpen, actionDialogName, actionType, data, callFrom) => {
            dispatch(getAppDrawer(isOpen, actionDialogName, actionType, data, callFrom));
        },
        getAppCustomDrawer: (isOpen, dialogName, actionType, style, data, headerProps, minimizable) => {
            dispatch(getAppCustomDrawer(isOpen, dialogName, actionType, style, data, headerProps, minimizable));
        },
        setMounted: (isMounted, isDetailView, isReport, isAssignRule, isDuplicate) => {
            dispatch(setMounted(isMounted, isDetailView, isReport, isAssignRule, isDuplicate));
        },
        minimizeAppCustomDrawer: (isMinimize, dialogName, style, data, headerProps, minimizeList) => {
            dispatch(minimizeAppCustomDrawer(isMinimize, dialogName, style, data, headerProps, minimizeList));
        },
        getCallDrawer: (isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom) => {
            dispatch(getCallDrawer(isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom));
        },
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
        updateScrollPosition: (pos, obj) => {
            dispatch(updateScrollPosition(pos, obj))
        },
        getSetupWorkflowData: (params) => {
            dispatch(getWorkflowSetupData(params))
        }
    };
};

class DetailLink extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isRedirect: false,
            redirectUrl: null,
            isPopOverOpen: false,
            isUnitLoginDropdownShow: true,
        };
    }

    /* link child add */
    openChildRecord = (label) => {
        let rowData = this.props.rowData;
        let recordProjectID;
        if (this.props.app.me.projectId !== recordProjectID) {
            recordProjectID = rowData.t_projectid;
        }
        if (rowData.hasOwnProperty('child_objects')) {
            for (let j = 0; j < rowData.child_objects.length; j++) {
                let childObject = rowData.child_objects[j];
                let childObjectName = childObject.object;
                let childRecords = childObject.records;
                if (this.props.object === childObjectName) {
                    let childRecord = childRecords[0];
                    let recordId = childRecord.id;
                    if (recordId && this.props.listView.data?.records?.length > 0) {
                        let rowIdx = this.props.listView.data.records.findIndex((row) => row.id === recordId)
                        if (rowIdx) {
                            this.props.updateScrollPosition({
                                pos: rowIdx,
                                object: this.props.object
                            }, this.props.object)
                        }
                    }
                    let linkUrl = getCustomLink(this.props, recordId);
                    this.props.openTab(recordProjectID, label, this.props.object, TYPE_DETAIL_VIEW, linkUrl, true);
                }
            }
        }
    };

    editReportedSales(selectedIds) {
        let data = {
            object: constants.REPORTED_SALES_OBJECT,
            id: selectedIds,
            mode: constants.SF_FORM_OPEN_MODE_QUICK
        }
        data['parent_object'] = "";
        data['recordId'] = [selectedIds];
        data['parent_record_id'] = 0;
        data['isDetailView'] = false;
        data['type'] = 'edit';
        let labelName = getLocalizedStrings().label.REPORTED_SALES.EDIT_TAB_LABEL;
        this.props.getAppDrawer(true, labelName, constants.EDIT, data, constants.LIST_VIEW)
    }

    openTotalSalesOfReportedSales = (row, column) => {
        let tabParams = {
            "imgName": "fa fa-newspaper",
            "index": 0,
            "info": {
                isReport: true,
                row: row,
                column: column,
                id: row.id,
            },
            "isActive": false,
            "label": constants.ROYALTY_REPORTED_SALES_DRILLDOWN,
            "object": constants.ROYALTY_REPORTED_SALES_DRILLDOWN,
            "type": constants.ROYALTY_REPORTED_SALES_DRILLDOWN,
            "url": '/' + makeValidAppUrl(this.props.object) + '/' + constants.ROYALTY_REPORTED_SALES_DRILLDOWN,
        };
        let tabs = this.props.tab?.tabs;
        let automationTab = tabs.find((tab) => {
            return tab.type === constants.ROYALTY_REPORTED_SALES_DRILLDOWN && tab.object === constants.ROYALTY_REPORTED_SALES_DRILLDOWN;
        });
        if (!isValidParam(automationTab)) {
            addTab(tabParams, true);
        } else {
            let tabParams = {
                "imgName": "fa fa-newspaper",
                "index": automationTab.index,
                "info": {
                    isReport: true,
                    row: row,
                    column: column,
                    id: row.id,
                },
                "isActive": false,
                "label": automationTab.label,
                "object": automationTab.object,
                "type": automationTab.type,
                "url": automationTab.url,
            };
            addTab(tabParams, true);
        }
    }

    /* link child add */

    openRecord = (column, isTabOpen, label, recordId, linkUrl, editorType, recordProjectID) => {
        let rowIdx = this.props.listView.data.records.findIndex((row) => row.id === recordId);
        let firstColumn = false;
        if (this.props.listView.data?.fields[0]?.id === column.id) {
            firstColumn = true;
        }
        let statusValue;
        let sourceValue;
        if (rowIdx !== -1) {
            statusValue = this.props.listView.data.records[rowIdx].t_status;
            sourceValue = this.props.listView.data.records[rowIdx].source;
        }
        if (recordId && this.props.listView.data?.records?.length > 0) {
            let rowIdx = this.props.listView.data.records.findIndex((row) => row.id === recordId)
            if (rowIdx) {
                this.props.updateScrollPosition({
                    pos: rowIdx,
                    object: this.props.object
                }, this.props.object)
            }
        }

        let isLookup = getBooleanParam(column.is_lookup_field);
        let attachmenType = '';
        let hasPermission = false;
        if (column.field_type === 'url') {
            let websiteAddress = label.toLowerCase();
            if (websiteAddress.indexOf('http:') !== -1 || websiteAddress.indexOf('https:') !== -1) {
                window.open(label.toLowerCase());
            } else {
                window.open('http://' + label.toLowerCase());
            }
        } else if (isTabOpen) {
            if (this.props.object === constants.AUTOMATION_DESIGNER_OBJECT) {
                let tabs = this.props.tab.tabs;
                let automationTab = tabs.find((tab) => {
                    return tab.type === TYPE_DETAIL_VIEW && tab.object === constants.AUTOMATION_DESIGNER_OBJECT;
                });
                if (!isValidParam(automationTab)) {
                    linkUrl = getCustomLink(this.props, recordId);
                    this.props.openTab(recordProjectID, label, this.props.object, TYPE_DETAIL_VIEW, linkUrl, true);
                } else {
                    let url = automationTab.url;
                    if (url.includes(recordId)) {
                        if (url.includes('copy')) {
                            sfDialogs.alert(
                                getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                                getLocalizedStrings().message.AUTOMATION_DESIGNER.RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD,
                                () => this.alertConfirm(automationTab),
                            );
                        } else {
                            linkUrl = getCustomLink(this.props, recordId);
                            this.props.openTab(recordProjectID, label, this.props.object, TYPE_DETAIL_VIEW, linkUrl, true);
                        }
                    } else {
                        sfDialogs.alert(
                            getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                            getLocalizedStrings().message.AUTOMATION_DESIGNER.RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD,
                            () => this.alertConfirm(automationTab),
                        );

                        // sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD);
                    }
                }
            } else if (this.props.object === constants.WORKFLOW_OBJECT) {
                let tabs = this.props.tab.tabs;
                let automationTab = tabs.find((tab) => {
                    return tab.type === TYPE_DETAIL_VIEW && tab.object === constants.WORKFLOW_OBJECT;
                });
                if (!isValidParam(automationTab)) {
                    this.props.openTab(recordProjectID, label, this.props.object, TYPE_DETAIL_VIEW, linkUrl, true);
                } else {
                    sfDialogs.alert('You already have one open Workflow Designer, please close or save it to open a new Workflow Designer.', () =>
                        this.alertConfirm(automationTab),
                    );
                }
            } else if (
                isLookup &&
                (this.props.isDetailView === false ||
                    (column.name == 'unit' && this.props.object === constants.ROYALTY_OBJECT && this.props.isDetailView === true))
            ) {
                label = getStringParam(this.props.value);
                let lookupTableId = getIntParam(this.props.column.lookup_table_id);
                let lookupFieldId = getIntParam(this.props.column.lookup_field_id);

                let activeTab = getObjectParam(getActiveTab());
                if (activeTab.type === TYPE_DETAIL_VIEW && activeTab.object === TABLEID_OBJECT_MAP[lookupTableId]) {
                    return;
                }

                let params = {};
                params.parent_object = getStringParam(this.props.object);
                params.parent_record_id = recordId;
                params.lookup_table_id = lookupTableId;
                params.lookup_field_id = lookupFieldId;
                params.field_value = label;
                let promise = Promise.resolve(getLookupRecordInfo(params));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        let lookupInfo = response;
                        let lookupObject = getStringParam(lookupInfo.object).trim();
                        let lookupRecordId = getIntParam(lookupInfo.record_id);
                        if (lookupObject !== '' && lookupRecordId > 0) {
                            linkUrl = getCustomLink({ object: lookupObject, column: this.props.column }, lookupRecordId);
                            this.setState({ isRedirect: true, redirectUrl: linkUrl });
                            label = label === '' ? 'Empty' : label;
                            this.props.openTab(recordProjectID, label, lookupObject, TYPE_DETAIL_VIEW, linkUrl, true);
                        } else {
                            let msg =
                                getLocalizedStrings().message.COMMON.COULDNOT_LOCATE +
                                label +
                                ' .' +
                                getLocalizedStrings().message.COMMON.DELETED_OR_ACCESS_DENIED;
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                        }
                    });
                }
            }
            else if (
                isLookup && (column.name == "company" && this.props.object === constants.CASES_OBJECT)
            ) {
                label = getStringParam(this.props.value);
                let lookupTableId = getIntParam(this.props.column.lookup_table_id);
                let lookupFieldId = getIntParam(this.props.column.lookup_field_id);

                let activeTab = getObjectParam(getActiveTab());
                if (activeTab.type === TYPE_DETAIL_VIEW && activeTab.object === TABLEID_OBJECT_MAP[lookupTableId]) {
                    return;
                }

                let params = {};
                params.parent_object = getStringParam(this.props.object);
                params.parent_record_id = recordId;
                params.lookup_table_id = lookupTableId;
                params.lookup_field_id = lookupFieldId;
                params.field_value = label;
                let promise = Promise.resolve(getLookupRecordInfo(params));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        let lookupInfo = response;
                        let lookupObject = getStringParam(lookupInfo.object).trim();
                        let lookupRecordId = getIntParam(lookupInfo.record_id);
                        if (lookupObject !== '' && lookupRecordId > 0) {
                            linkUrl = getCustomLink({ object: lookupObject, column: this.props.column }, lookupRecordId);
                            this.setState({ isRedirect: true, redirectUrl: linkUrl });
                            label = label === '' ? 'Empty' : label;
                            this.props.openTab(recordProjectID, label, lookupObject, TYPE_DETAIL_VIEW, linkUrl, true);
                        } else {
                            let msg =
                                getLocalizedStrings().message.COMMON.COULDNOT_LOCATE +
                                label +
                                ' .' +
                                getLocalizedStrings().message.COMMON.DELETED_OR_ACCESS_DENIED;
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                        }
                    });
                }
            }
            else if (this.props.object === constants.GROUP_OBJECT) {
                let filter = {};
                if (column.key === 'name') {
                    filter = { id: recordId, name: label, type: 'group' };
                    this.props.openTab(recordProjectID, label, this.props.object, TYPE_DETAIL_VIEW, linkUrl, true, filter);
                } else {
                    let promise = getRecordPromise(this.props.object, recordId, null);
                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            if (isValidParam(response) && response.hasOwnProperty('records')) {
                                filter = {
                                    id: recordId,
                                    name: response.records.name,
                                    type: 'group',
                                };
                                this.props.openTab(recordProjectID, label, this.props.object, TYPE_DETAIL_VIEW, linkUrl, true, filter);
                            }
                        });
                    }
                }

            } else if (column.name == 'quoteno' && this.props.object === constants.REPORTED_SALES_OBJECT && column.name !== "total_sale") {
                this.props.openQuotationTab(recordId);
            } else if (this.props.object === constants.REPORTED_SALES_OBJECT && this.props.app.me.is_franchisee && !(this.props.app.me.is_franchisor) && column.name !== "total_sale") {
                if (statusValue !== 'Submitted') {
                    this.editReportedSales(recordId);
                } else {
                    let msg = 'This royalty has already been submitted.';
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                }
            } else if (this.props.object === constants.REPORTED_SALES_OBJECT && column.name === "total_sale") {
                let rowData = this.props.rowData;
                this.openTotalSalesOfReportedSales(rowData, column);
            } else if (this.props.object === constants.REPORTED_SALES_OBJECT &&
                !getBooleanParam(this.props.detailView?.is_submitted) && sourceValue !== 'BW' && column.name !== "total_sale") {
                this.editReportedSales(recordId);
            } else if (this.props.object === constants.ROYALTY_OBJECT) {
                if (!getBooleanParam(this.props.isDetailView)) {
                    this.props.openTab(recordProjectID, label, constants.ACCOUNTS_OBJECT, TYPE_DETAIL_VIEW, linkUrl, true);
                }
            } else if (this.props.isDetailView === true && this.props.object === constants.REPORTED_SALES_OBJECT) {
                if (this.props.detailView.is_submitted && column.name != 'quoteno' && column.name !== "total_sale") {
                    let msg = 'This royalty has already been submitted.';
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                } else if (this.props.object === constants.REPORTED_SALES_OBJECT && this.props.app.me.is_franchisor && statusValue !== 'Invoiced' && column.name !== "total_sale") {
                    this.getReportedBY(recordId);
                }
            }
            else if (this.props.object === constants.TASKS) {
                linkUrl = getCustomLink(this.props, recordId);
                let _object = this.props.object;
                if (this.props.column.name == "company" && this.props.column.label == "Account Name") {
                    _object = constants.ACCOUNTS_OBJECT;
                } else if (this.props.column.name == "fullname" && this.props.column.label == "Contact Name") {
                    _object = constants.CONTACTS_OBJECT;
                }
                this.props.openTab(recordProjectID, label, _object, TYPE_DETAIL_VIEW, linkUrl, true);
            }
            else if (this.props.object === constants.APPOINTMENTS) {
                linkUrl = getCustomLink(this.props, recordId);
                let filter = {};
                filter = { columnName: column.name };

                this.props.openTab(recordProjectID, label, this.props.object, TYPE_DETAIL_VIEW, linkUrl, true, filter);
            }
            else if (this.props.object === constants.UNITS_OBJECT) {
                let activeTab = getObjectParam(getActiveTab());
                let activeTabIndex = getIntParam(activeTab.index);
                let info = getObjectParam(activeTab.info);
                let isInXpress = getBooleanParam(info.isInXpress);
                let tab = { label: label, object: this.props.object, type: TYPE_DETAIL_VIEW, imgName: null, url: linkUrl, isActive: true, parentTabIndex: activeTabIndex };
                if (isInXpress) {
                    tab.info = { isInXpress: isInXpress, inXpressProjectId: getIntParam(info.inXpressProjectId) };
                }
                addTab(tab, true);
            }
            else if (this.props.object === constants.CUSTOM_TABLE1_OBJECT && INTAKE_FORM.TENENT_PROJECTS_ID.includes(this.props.app.me.projectId)) {
                let info = {};
                let objectName = this.props.object;
                let labelName = getLocalizedStrings().label.COMMON.EDIT + ' ' + 'Intake Info';
                let activeTab = getObjectParam(getActiveTab());
                let activeTabIndex = getIntParam(activeTab.index);
                let newTab = {
                    object: constants.INTAKE_FORM,
                    url: `/${constants.INTAKE_FORM}/${constants.SPREADSHEET}/${recordId}`,
                    type: TYPE_OTHER_VIEW,
                    label: labelName,
                    info: info,
                    isActive: true,
                    parentTabIndex: activeTabIndex,
                    recordId: recordId,
                    imgName: null,
                };

                let tabs = this.props.tab.tabs;
                let intakeTab = tabs.find((tab) => {
                    return tab.type === TYPE_OTHER_VIEW && tab.object === constants.INTAKE_FORM;
                });

                if (!isValidParam(intakeTab)) {
                    addTab(newTab);
                    return false;
                } else {
                    let url = intakeTab?.url;
                    if (url.includes(label)) {
                        addTab(newTab);
                        return false;
                    } else {
                        let message = '';
                        if (objectName === constants.CUSTOM_TABLE1_OBJECT) {
                            message = getLocalizedStrings().message.INTAKE_FORM.RESTRICT_TO_OPEN_TWO_OR_MORE_INTAKE_FORM;
                        }
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, message, () => this.alertConfirm(intakeTab));
                    }
                }
            }
            else {
                this.props.openTab(recordProjectID, label, this.props.object, TYPE_DETAIL_VIEW, linkUrl, true);
            }
        } else {
            let value = getStringParam(this.props.value);
            let type = null;

            if (column.is_detail_link &&
                (this.props.object === constants.ATTACHMENT_OBJECT || this.props.object === constants.INVOICES_OBJECT
                    || this.props.object === constants.TASKS || this.props.object === constants.APPOINTMENTS)) {
                if (this.props.object === constants.ATTACHMENT_OBJECT && isValidParam(this.props.row)) {
                    attachmenType = getStringParam(this.props.row.attachment_type);
                }
                if (
                    column.is_detail_link &&
                    attachmenType !== 'Agreement' &&
                    (this.props.object === constants.ATTACHMENT_OBJECT || this.props.object === constants.INVOICES_OBJECT)
                ) {
                    donloadAttachmentData(this.props.object, recordId);
                    return false;
                } else if (column.is_detail_link && attachmenType === 'Agreement'
                    && this.props.object === constants.ATTACHMENT_OBJECT) {
                    // check status
                    let url = endPoints.ATTACHMENT.GET_ATTACHMENT_SIGN_STATUS;
                    let param = { attchment_id: recordId };
                    let promise = Promise.resolve(HTTPClient.get(url, param));
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            let sign_status = getStringParam(response.signature_status);
                            if (sign_status === 'Sent' || sign_status === 'Signed') {
                                donloadAttachmentData(this.props.object, recordId);
                                return false;
                            } else {
                                label = getLocalizedStrings().label.COMMON.EDIT + ' ' + label;
                                data.mode = 'edit';
                                data.object = this.props.parentObject;
                                type = constants.AGREEMENT_OBJECT;

                                this.props.getAppCustomDrawer(true, label, type, { width: '83%' }, data, null, true);
                                // this.props.getAppCustomDrawer(true, 'New Agreement', constants.AGREEMENT_OBJECT, { width: '83%' }, data, null, false);
                            }
                        }
                    });
                }

                type = LinkToType.TYPE_EDIT;
            } else if (column.is_email_field || column.is_sales_rep) {
                type = LinkToType.TYPE_EMAIL;
            } else if (column.is_click_to_call) {
                let phoneTextProvider = this.props.app.me.phone_text_provider ?? constants.NONE;
                if (phoneTextProvider !== constants.NONE) {
                    type = LinkToType.TYPE_CLICK_TO_CALL;
                    let phoneField = true;
                    let fieldName = column.name;
                    let selectedPhoneNumber = label;
                    if (column.is_ring_central) {
                        // Code for Ring Central
                        openRingCentralWebWidget();
                    } else {
                        if (column.is_twilio) {
                            const unitHLabel = this.props.rowData?.hLabel || 0;
                            if (unitHLabel && this.props.object === constants.UNITS_OBJECT) {
                                this.props.showCustomSnackBar(getLocalizedStrings().message.LIST_VIEW.UNIT_PHONE_NUMBER, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                            } else {
                                this.getCapabilityTokenForCall(this.props.object, recordId, phoneField, fieldName, selectedPhoneNumber);
                            }
                        } else if (fieldName === 'mobile' && column.is_twilio_sms) {
                            this.getRecordDetailsForSMS(recordId, fieldName);
                        }
                    }
                } else {
                    sfDialogs.alert(
                        getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                        getLocalizedStrings().message.PHONE_AND_TEXT_INTEGRATION.NO_PROVIDER,
                        null,
                    );
                }
                return false;
            } else if (column.is_relationship_field) {
                type = LinkToType.TYPE_RELATIONSHIP;
                label = getLocalizedStrings().label.RELATIONSHIP.DEFINE_RELATIONSHIP;
            }
            let data = {};
            data.id = recordId;
            if (type === LinkToType.TYPE_EMAIL && !firstColumn) {
                let minimizeList = this.props.customDrawer.minimizeList;
                if (!minimizeList.hasOwnProperty('email') || (minimizeList.hasOwnProperty('email') && !minimizeList.email)) {
                    const unitHLabel = this.props.rowData?.hLabel || 0;
                    if (unitHLabel && this.props.object === constants.UNITS_OBJECT) {
                        this.props.showCustomSnackBar(getLocalizedStrings().message.LIST_VIEW.UNIT_SEND_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                    } else {
                        let isAurinkoReAuthRequired = this.props.app.me.is_aurinko_reauth_required;
                        let isSendMailUsingAurinko = this.props.app.me.is_send_mail_using_aurinko;
                        if (isSendMailUsingAurinko && isAurinkoReAuthRequired) {
                            let msg = getLocalizedStrings().message.MY_INTEGRATION.RE_AUTHENTICATE_AURINKO;
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                        } else {
                            this.sendEmail(this.props, recordId, value, column);
                        }
                    }
                } else {
                    this.props.showCustomSnackBar(
                        'Send mail popup is already opened for different record',
                        styles.snackbarBodyStyleError,
                        styles.snackBarStyleTop,
                    );
                }

                /*if (column.is_sales_rep) {
                    let userList = this.props.userList;
                    let tempUser = userList.filter(f => f.text === value);
                    if (tempUser !== null && tempUser.length > 0) {
                        data.email = tempUser[0].email;
                        data.type = constants.TYPE_NOTIFY;
                    }
                } else {
                    data.email = value;
                    data.type = constants.TYPE_EMAIL;
                }*/
            } else {
                let objectName = this.props.object;
                if (objectName !== constants.QUOTATION_OBJECT && objectName !== constants.TASKS
                    && objectName !== constants.APPOINTMENTS && objectName !== constants.PAYMENTS_OBJECT
                    && objectName !== constants.SALES_ORDER_OBJECT && objectName !== constants.PURCHASE_ORDER_OBJECT
                    && objectName !== 'workflows'
                    && objectName !== constants.AUDIT_ANSWERS_OBJECT
                    && objectName !== constants.TOUCH_CAMPAIGNS_OBJECT
                    && objectName !== constants.MARKETING_TEMPLATE_OBJECT
                    && objectName !== constants.BROADCAST_CAMPAIGNS_OBJECT
                    && objectName !== constants.WEB_FORMS
                    && objectName !== constants.EMAIL_INTEGRATION_OBJECT
                    && objectName !== constants.LANDING_PAGES_FROALA_OBJECT
                    && objectName !== constants.ASSIGNMENT_RULES_OBJECT
                    && objectName !== constants.LANDING_PAGES_FROALA_OBJECT
                    && objectName !== constants.SALES_TEMPLATE_OBJECT
                    && objectName !== constants.AGREEMENT_TEMPLATE_OBJECT
                    && objectName !== constants.NEWS_OBJECT
                    && objectName !== constants.AB_CAMPAIGNS_OBJECT
                    && objectName !== constants.ROYALTY_PLANS_OBJECT
                    && objectName !== constants.SMS_TEMPLATE_OBJECT
                    && objectName !== constants.SOURCE_OBJECT
                    && objectName !== constants.LOCATION_OBJECT
                    && objectName !== constants.TASKSLIST_OBJECT
                    && objectName !== constants.RELATIONS_OBJECT
                    && objectName !== constants.CAMPAIGN_TYPES_OBJECT
                    && objectName !== constants.APPOINTMENT_TYPES_OBJECT
                    && objectName !== constants.SECURITY_LEVEL_OBJECT
                    && objectName !== constants.CALL_SCRIPT_OBJECT
                ) {
                    this.props.openTab(recordProjectID, label, this.props.object, TYPE_DETAIL_VIEW, linkUrl, true);
                } else {
                    type = LinkToType.TYPE_EDIT;
                }
            }
            data.object = this.props.object;
            if (this.props.column.is_detail_link && this.props.object === constants.APPOINTMENTS) {
                // label = "Edit Appointment";
                label = getLocalizedStrings().label.COMMON.EDIT + ' ' + getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENT;
                data.isDetailView = getBooleanParam(this.props.isDetailView);
                data.parent_object = this.props.parentObject;
                data.parent_record_id = this.props.parentRecordId;
            }
            if (this.props.column.is_detail_link && this.props.object === constants.TASKS) {
                // label = "Edit Task";
                label = getLocalizedStrings().label.COMMON.EDIT + ' ' + getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK;
                data.isDetailView = getBooleanParam(this.props.isDetailView);
                data.parent_object = this.props.parentObject;
                data.parent_record_id = this.props.parentRecordId;
            }

            if (this.props.object === constants.TASKS && column.name == "t_dueby") {
                // label = "Edit Task";
                label = getLocalizedStrings().label.COMMON.EDIT + ' ' + getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK;
                data.isDetailView = getBooleanParam(this.props.isDetailView);
                data.parent_object = this.props.parentObject;
                data.parent_record_id = this.props.parentRecordId;
            }
            if (this.props.column.is_detail_link && this.props.object === constants.AUDIT_ANSWERS_OBJECT) {
                type = this.props.object;
                label = getLocalizedStrings().label.AUDIT_ANSWERS.AUDIT_ANSWERS_LABEL;
                data.isDetailView = getBooleanParam(this.props.isDetailView);
                data.parent_object = this.props.parentObject;
                data.parent_record_id = this.props.parentRecordId;
                data.data = this.props.rowData;
            }
            if (column.is_relationship_field) {
                let obj = {};
                data.id = recordId;
                obj.title = this.props.rowData['company'];
                data.object = obj;
            }
            if (this.props.object === constants.TOUCH_CAMPAIGNS_OBJECT) {
                //let tempLabel = label;
                //tempLabel = tempLabel.replace("#", constants.REPLACE_DELIMITER);
                let token = localStorage.getItem('token');
                let redirectUrl = constants.snapshotUrl;
                redirectUrl += '&token=' + token;
                redirectUrl += '&callingfor=' + 'touchcampaign';
                redirectUrl += '&recordId=' + recordId;
                //redirectUrl += '&label=' + escapeHtml(tempLabel);
                redirectUrl += '&type=edit';
                window.open(redirectUrl);
            } else if (this.props.object === constants.WEB_FORMS) {
                let recordsArr = this.props.listView.data.records;
                recordsArr = recordsArr.filter(function (el) {
                    return el.id === recordId;
                });
                recordsArr = recordsArr[0];
                let tableId = recordsArr['t_temp_tableid'];
                let token = localStorage.getItem('token');
                let redirectUrl = constants.snapshotUrl;
                redirectUrl += '&token=' + token;
                redirectUrl += '&callingfor=' + 'webform';
                redirectUrl += '&recordId=' + recordId;
                redirectUrl += '&type=edit';
                redirectUrl += '&label=Edit ' + label;
                redirectUrl += '&tableId=' + tableId;
                window.open(redirectUrl);
            } else if (this.props.object === 'workflows') {
                let recordsArr = this.props.listView.data.records;
                recordsArr = recordsArr.filter(function (el) {
                    return el.id === recordId;
                });

                let isShared = false;
                let sharedWf = getObjectParam(this.props.listView.data.shared_workflowid);
                let sharedWfIds = Object.keys(sharedWf);
                if (sharedWfIds.length > 0) {
                    if (sharedWfIds.indexOf(recordId + '') > -1) {
                        isShared = true;
                    }
                }

                let myClientId = 0;

                if (isShared && sharedWf.hasOwnProperty(recordId)) {
                    myClientId = sharedWf[recordId];
                }
                recordsArr = recordsArr[0];
                let tableId = recordsArr['t_temp_tableid'];
                let token = localStorage.getItem('token');
                let redirectUrl = constants.snapshotUrl;
                redirectUrl += '&token=' + token;
                redirectUrl += '&callingfor=' + 'workflows';
                redirectUrl += '&recordId=' + recordId;
                redirectUrl += '&type=edit';
                //redirectUrl += '&label=' + label;
                redirectUrl += '&tableId=' + tableId;

                if (isShared) {
                    redirectUrl += '&isShared=' + isShared;
                    redirectUrl += '&myClientId=' + myClientId;
                }

                if (this.props.app.me.is_customize_workflow) {

                    window.open(redirectUrl);
                    // let params = {id:recordId, tableId:tableId, myClientId: myClientId}
                    // this.props.getSetupWorkflowData(params)
                    // let data = {};
                    // data.recordsArr = recordsArr;
                    // let style = {};
                    // style.width = '100%';
                    // let type = WORKFLOW_SETUP.SETUP
                    // let label = recordsArr.t_name;
                    // this.props.getAppCustomDrawer(true, label, type, style, data, null, false);
                } else {
                    let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
                }
            } else if (this.props.object === constants.MARKETING_TEMPLATE_OBJECT || this.props.object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
                // if (this.props.object === constants.BROADCAST_CAMPAIGNS_OBJECT && !isValidParam(editorType)) {
                let params = { id: recordId };
                let url = '';
                if (this.props.object === constants.MARKETING_TEMPLATE_OBJECT) {
                    url = endPoints.MARKETING_TEMPLATE.GET_EDITOR_TYPE;
                } else if (this.props.object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
                    url = endPoints.BROADCAST_CAMPAIGNS.GET_EDITOR_TYPE;
                }
                var promise = Promise.resolve(HTTPClient.get(url, params));
                promise.then((response) => {
                    editorType = response;
                    data.type = editorType;
                    data.object = this.props.object;
                    if (editorType === constants.EDITOR_NEW || editorType === constants.EDITOR_BEE) {
                        let style = {};
                        style.width = '100%';
                        this.props.getAppCustomDrawer(true, label, type, style, data, null, false);
                    } else {
                        // this.props.getAppDrawer(true, label, type, data, null);
                        let style = {};
                        style.width = '100%';
                        this.props.getAppCustomDrawer(true, label, type, style, data, null, false);
                    }
                });
                // } else {
                //     data.type = editorType;
                //     if (editorType === constants.EDITOR_NEW || editorType === constants.EDITOR_BEE) {
                //         let style = {};
                //         style.width = '100%';
                //         this.props.getAppCustomDrawer(true, label, type, style, data, null);
                //     } else {
                //         if (type !== LinkToType.TYPE_EMAIL) {
                //             this.props.getAppDrawer(true, label, type, data, null);
                //         }
                //     }
                // }
            } else if (
                this.props.object === constants.SOURCE_OBJECT ||
                this.props.object == constants.LOCATION_OBJECT ||
                this.props.object == constants.TASKSLIST_OBJECT ||
                this.props.object === constants.RELATIONS_OBJECT ||
                this.props.object === constants.CAMPAIGN_TYPES_OBJECT ||
                this.props.object === constants.APPOINTMENT_TYPES_OBJECT
            ) {
                label = getLocalizedStrings().label.COMMON.EDIT + ' ' + getLocalizedStrings().label.SETTINGS[this.props.object.toUpperCase()];
                this.props.getAppDrawer(true, label, type, data, null);
            } else if (this.props.object === constants.TASKS) {
                hasPermission = hasAccessPermission(this.props.object, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
                if (hasPermission) {
                    let data = {};
                    let recordsArr = this.props.listView.data.records;
                    let recordsObj = recordsArr.find(function (f) {
                        return f.id === recordId;
                    });
                    if (this.props.listView.data.query_name === 'Unit Tasks' || recordsObj.t_unit_owner === 1 ) {
                        if(this.props.detailView?.record?.id){
                            let propsTaskApp = {
                                object: this.props.object,
                                recordId: this.props.detailView.record.id,
                                workflowId: !this.props.detailView?.workflow ? 0 : this.props.detailView?.workflow?.id,
                                isDetailView: true,
                                callFrom: 'DetailView',
                                isAppointmentInChild: true,
                                currentStatus: this.props.detailView.workflow?.current_stage
                            }
                            data.UnitsAccountProjectId = this.props.detailViewRecord.myclient_Id;
                            data.callFrom = propsTaskApp.callFrom;
                            data.id = recordsObj.id;
                            data.isDetailView = true;
                            data.isFranchiseRecordVal = true;
                            data.isUnitownerTasksVal = true;
                            data.isUnitsAccount = true;
                            data.labelName = recordsObj.t_name;
                            data.object = this.props.object;
                            data.parent_object = propsTaskApp.object;
                            data.task_type = recordsObj?.task_type;
                            data.parent_record_id = propsTaskApp.recordId;
                            data.propsTaskApp = propsTaskApp;
                            label = recordsObj.t_name;
                        }else{
                            data.labelName = recordsObj.t_name;
                            data.id = recordId;
                            data.object = constants.TASKS;
                            data.id = recordsObj.id;
                            data.isDetailView = true;
                            data.isFranchiseRecordVal = true;
                            data.isUnitownerTasksVal = true;
                            data.isUnitsAccount = true;
                            data.labelName = recordsObj.t_name;
                            data.object = this.props.object;
                            data.task_type = recordsObj?.task_type;
                            label = recordsObj.t_name;
                        }
                    } else {
                        data.labelName = recordsObj.t_name;
                        data.id = recordId;
                        data.object = constants.TASKS;
                    }
                    this.props.getAppDrawer(true, label, constants.MINI_EDIT, data, constants.OTHER);
                } else {
                    sfDialogs.alert(
                        getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                        getLocalizedStrings().message.COMMON.ACCESS_DENIED,
                        null,
                    );
                }
            } else if (this.props.object === constants.EMAIL_INTEGRATION_OBJECT) {
                label = getLocalizedStrings().label.COMMON.EDIT + ' ' + getLocalizedStrings().label.EMAIL_INTEGRATION.EMAIL;
                this.props.getAppDrawer(true, label, type, data, null);
            } else if (this.props.object === constants.ASSIGNMENT_RULES_OBJECT) {
                label = getLocalizedStrings().label.COMMON.EDIT + ' ' + getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULE;
                let tableName =
                    TABLEID_OBJECT_MAP[this.props?.rowData?.t_temp_tableid ? this.props?.rowData?.t_temp_tableid : constants.ACCOUNTS_OBJECT];
                data.table = tableName;
                data.mode = 'edit';
                this.props.getAppDrawer(true, label, type, data, null);
            } else if (this.props.object === constants.LANDING_PAGES_FROALA_OBJECT) {
                //label = getLocalizedStrings().label.COMMON.EDIT + " " +'Landing Page';
                data.mode = 'edit';
                this.props.getAppDrawer(true, label, type, data, null);
            } else if (
                this.props.object === constants.QUOTATION_OBJECT ||
                this.props.object === constants.PAYMENTS_OBJECT ||
                this.props.object === constants.PURCHASE_ORDER_OBJECT ||
                this.props.object === constants.SALES_ORDER_OBJECT
            ) {
                let nextTabObject = '';
                let info = {};
                let objectName = this.props.object;
                if (objectName === constants.QUOTATION_OBJECT || objectName === constants.PAYMENTS_OBJECT) {
                    nextTabObject = constants.QUOTATION_OBJECT;
                    info = {
                        type: LinkToType.TYPE_EDIT,
                        id: recordId,
                        parentRecordId: this.props.parentRecordId,
                        parentObject: this.props.parentObject,
                        quotationNo: label,
                        object: objectName,
                        parentObject: this.props.parentObject,
                    };
                } else {
                    nextTabObject = objectName;
                    info = {
                        type: LinkToType.TYPE_DETAIL_VIEW,
                        id: recordId,
                        parentRecordId: this.props.parentRecordId,
                        parentObject: this.props.parentObject,
                        orderno: label,
                        object: objectName,
                        parentObject: this.props.parentObject,
                    };
                }
                let newTab = {
                    object: objectName,
                    url: `/${nextTabObject}/${constants.SPREADSHEET}/${recordId}`,
                    type: TYPE_OTHER_VIEW,
                    label: label,
                    info: info,
                };

                let tabs = this.props.tab.tabs;
                let quoteTab = tabs.find((tab) => {
                    return tab.type === TYPE_OTHER_VIEW && tab.object === objectName;
                });
                if (!isValidParam(quoteTab)) {
                    let _info = getActiveTabInfo();
                    if (_info.selectedPageNo > 1) {
                        _info.selectedPageNo = 1;
                        updateTabInfo(_info);
                    }
                    addTab(newTab);
                    return false;
                } else {
                    let url = quoteTab.url;
                    if (url.includes(label)) {
                        addTab(newTab);
                        return false;
                    } else {
                        let message = '';
                        if (objectName === constants.QUOTATION_OBJECT || objectName === constants.PAYMENTS_OBJECT) {
                            const crIndex = tabs.findIndex((tab) => {
                                return tab.type === TYPE_OTHER_VIEW && tab.object === objectName;
                            });
                            console.log(crIndex);
                            closeTab(objectName,crIndex);
                            addTab(newTab);
                            // updateTabByTabIndex(newTab)
                            // message = getLocalizedStrings().message.PAYMENTS.RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD;
                        } else {
                            if (objectName === constants.PURCHASE_ORDER_OBJECT) {
                                message = getLocalizedStrings().message.SALES_PURCHASE_ORDER.RESTRICT_TO_OPEN_TWO_OR_MORE_PURCHSE_RECORD;
                            } else if (objectName === constants.SALES_ORDER_OBJECT) {
                                message = getLocalizedStrings().message.SALES_PURCHASE_ORDER.RESTRICT_TO_OPEN_TWO_OR_MORE_SALES_RECORD;
                            }
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, message, () => this.alertConfirm(quoteTab));
                        }
                    }
                }
            } else {
                if (type !== LinkToType.TYPE_EMAIL && attachmenType !== 'Agreement') {
                    if (this.props.object === constants.SECURITY_LEVEL_OBJECT) {
                        let recordDetails = this.props.rowData;
                        data.description = recordDetails['t_description'];
                        data.name = label;
                        this.props.getAppDrawer(true, label, type, data, null);
                    } else if (this.props.object === constants.SALES_TEMPLATE_OBJECT) {
                        this.props.getAppCustomDrawer(true, label, type, { width: '83%' }, data, null, false);
                    } else if (this.props.object === constants.AUDIT_ANSWERS_OBJECT) {
                        this.props.getAppCustomDrawer(true, label, type, { width: '45%' }, data, null, false);
                    } else if (this.props.object === constants.AGREEMENT_TEMPLATE_OBJECT) {
                        this.props.getAppCustomDrawer(true, label, type, { width: '83%' }, data, null, true);
                    } else if (this.props.object === constants.NEWS_OBJECT) {
                        this.props.getAppCustomDrawer(true, label, type, { width: '60%' }, data, null, false);
                    } else if (this.props.object === constants.AB_CAMPAIGNS_OBJECT) {
                        this.props.getAppCustomDrawer(true, label, type, { width: '83%' }, data, null, false);
                    } else if (this.props.object === constants.ROYALTY_PLANS_OBJECT) {
                        this.props.getAppCustomDrawer(true, label, type, { width: '60%' }, data, null, false);
                    } else {
                        if (this.props.hasPermission) {
                            if (data.object === constants.REPORTED_SALES_OBJECT) {
                                data.mode = constants.SF_FORM_OPEN_MODE_QUICK;
                            }
                            if (data.object === 'refund' && this.props.parentObject === 'accounts' && this.props.isDetailView === true) {
                                type = LinkToType.TYPE_EDIT;
                            }
                            this.props.getAppDrawer(true, label, type, data, null);
                        } else {
                            sfDialogs.alert(
                                getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                                getLocalizedStrings().message.COMMON.ACCESS_DENIED,
                                null,
                            );
                            return false;
                        }
                    }
                }
            }
        }
    };
    alertConfirm = (automationTab) => {
        let tabIndex = automationTab.index;
        selectTabByIndex(tabIndex);
    };
    getReportedBY = (recordId) => {
        try {
            let params = {};
            params.recordId = recordId;
            const url = endPoints.REPORTED_SALES.GET_REPORTED_BY;
            HTTPClient.post(url, params).then((res) => {
                if (res && res.data && res.data === constants.REPORTED_BY_FR) {
                    let _recordArr = [];
                    _recordArr.push(recordId)
                    let data = {
                        isYesButton: true,
                        isCancelButton: false,
                        newRecordDetails: res,
                        selectedRecordId: _recordArr
                    }
                    this.props.getAppDrawer(true, "Reported Sales Update", REPORTED_SALES_ACTIONS.UPDATE, data, constants.OTHER);
                }
            });
        } catch (error) {
            console.error(
                "Error in 'quickBooksIntegration.js -> checkIsConnected()':" + error
            );
        }
    };
    getCapabilityTokenForCall = (object, recordId, phoneField, fieldName, selectedPhoneNumber) => {
        var promise = Promise.resolve(HTTPClient.get(endPoints.CALLING_INTEGRATION.GENERATE_CAPABILITY_TOKEN, null));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    let capabilityToken = getStringParam(response.token);
                    if (capabilityToken !== null && capabilityToken !== '') {
                        this.getCapabilityToken(capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber);
                    }
                }
            });
        }
    };

    getCapabilityToken = (capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber) => {
        let params = {
            id: recordId,
            parent_table_id: OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT],
        };
        let endPoint = getObjectSpecificEndpoint(object, endPoints.RECORDS.RECORD_LINKED_MAP_DATA);
        if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT) {
            endPoint = getObjectSpecificEndpoint(object, endPoints.RECORDS.MAP_DATA);
            params = {
                id: recordId,
                fields: ['company', 'first_name', 'last_name', 'phone', 'mobile', 'workflow_id', 'country'],
            };
        }
        var promise = Promise.resolve(HTTPClient.get(endPoint, params));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    let records = response.records;
                    this.processInfoAndOpenCallDialog(records, capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber);
                }
            });
        }
    };

    processInfoAndOpenCallDialog = (records, capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber) => {
        let firstName = '';
        let lastName = '';
        let fullName = '';
        let workflowId = '';
        let company = '';
        let title = '';
        let country = '';
        if (records) {
            if (records.hasOwnProperty('first_name')) {
                firstName = records.first_name;
            }

            if (records.hasOwnProperty('last_name')) {
                lastName = records.last_name;
            }

            if (records.hasOwnProperty('workflow_id')) {
                workflowId = records.workflow_id;
            }

            if (records.hasOwnProperty('company')) {
                company = records.company;
            }

            if (records.hasOwnProperty('country')) {
                country = records.country;
            }
        }

        if (firstName !== null && firstName !== '' && firstName !== undefined && lastName !== null && lastName !== '' && lastName !== undefined) {
            fullName = firstName + ' ' + lastName;
        }
        if (firstName !== null && firstName !== '' && firstName !== undefined && (lastName === null || lastName === '' || lastName === undefined)) {
            fullName = firstName;
        }

        if ((firstName === null || firstName === '' || firstName === undefined) && lastName !== null && lastName !== '' && lastName !== undefined) {
            fullName = lastName;
        }

        if (fullName === null || fullName === '' || fullName === undefined) {
            fullName = company;
        }
        if (fullName === null || fullName === '' || fullName === undefined) {
            fullName = selectedPhoneNumber;
        }
        if (fullName !== null && fullName !== '' && fullName !== undefined) {
            title = 'Call ' + fullName + '';
        }

        let label = title;
        let type = LinkToType.TYPE_CLICK_TO_CALL;
        let data = {};
        data.id = recordId;
        data.object = object;
        //data.type = dataType;
        data.token = capabilityToken;
        data.selectedPhoneNumber = selectedPhoneNumber;
        data.fieldName = fieldName;
        data.workflowId = workflowId;
        data.country = country;
        data.fullName = fullName;
        data.isDetailView = this.props.isDetailView;
        let style = {};
        style.width = '40%';

        let selectedRecord = [
            {
                rowIdx: this.props.rowIdx,
                id: this.props.rowData.id,
                title: this.props.rowData.company,
            },
        ];
        data.selectedRecords = selectedRecord;
        data.call_from = constants.LIST_VIEW;
        this.props.getCallDrawer(true, label, type, style, data, null);
    };

    getPopOverElememnt = (from, record) => {
        let popover = null;
        let label = record?.hLabel || 0;
        let itemArr = [];
        if (from == 'contact') {
            itemArr = ['call', 'sms'];
        } else {
            itemArr = ['login'];
            if (label == 0) {
                itemArr.unshift('details');
            }
        }

        try {
            popover = (
                <Menu
                    open={this.state.isPopOverOpen}
                    onClose={this.closePopOver}
                    menuItemStyle={{ width: 168 }}
                    style={{ width: 241 }}
                    anchorEl={this.state.target}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    targetOrigin={{ horizontal: 'left', vertical: 'top' }}>
                    {this.getMenuItem(itemArr)}
                </Menu>
            );
            // </Popover>
        } catch (error) {
            console.error("Error in 'detailLink.js -> getPopOverElememnt()':" + error);
        }
        return popover;
    };

    getMenuItem = (options) => {
        let _items = [];
        options.map((item, i) => {
            _items.push(<MenuItem
                key={item + i}
                id={item}
                value={item}
                style={{ ...styles.popoverMenuItem, paddingLeft: 3 }}
                onClick={() => this.onClickMenuItemAction(item)}>
                {this.getIconLabel(item)}
            </MenuItem>)

        })
        return _items;
    };
    getIconLabel = (item) => {
        const icons = { call: 'call', sms: 'textsms', details: 'link', login: 'login' }
        const labels = {
            call: getLocalizedStrings().label.TWILIO.CALL,
            sms: getLocalizedStrings().label.TWILIO_SMS.SEND_SMS,
            details: 'Unit details',
            login: 'Login to unit'
        }
        return (
            <><span>
                <Icon style={{ height: 14, width: 14, margin: 9, fontSize: 14 }}>{icons[item]}</Icon>
            </span>
                <span style={{ marginLeft: '4px' }}>{labels[item]}</span>{' '}</>)

    }
    onClickMenuItemAction = (callType) => {
        if (callType === 'call' || callType === 'sms') {
            this.openTwiloDialog(callType);
        } else if (callType === 'login') {
            this.login();
        } else {
            let column = this.props.column;
            let record = this.props.rowData;
            let label = record.company;
            if (label === '') {
                label = getStringParam(record.first_name) + ' ' + getStringParam(record.last_name);
            }
            if (label.trim() === '') {
                label = 'EMPTY'
            }
            let id = this.props.rowData['id']
            let linkUrl = '/accounts' + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + id;
            let recordProjectID = 0;
            if (this.props.app.me.projectId !== record.t_projectid) {
                recordProjectID = record.t_projectid;
            }
            this.openRecord(column, true, label, id, linkUrl, null, recordProjectID);
        }
    };
    closePopOver = () => {
        this.setState({ isPopOverOpen: false });
    };

    openPopOver = (event) => {
        event.preventDefault();
        this.setState({ target: event.currentTarget, isPopOverOpen: true });
    };

    openTwiloDialog = (value) => {
        this.setState({ isPopOverOpen: false });
        let recordId = this.props.rowData['id'];
        let column = this.props.column;
        if (value === 'call') {
            if (column.is_ring_central) {
                // Code for Ring Central
                openRingCentralWebWidget();
            } else {
                if (column.is_twilio) {
                    this.getCapabilityTokenForCall(this.props.object, recordId, true, column.key, this.props.value);
                }
            }
        } else if (value === 'sms') {
            this.getRecordDetailsForSMS(recordId, column.key);
        }
    };

    getRecordDetailsForSMS = (recordId, fieldName) => {
        let params = null;
        var response = null;
        let endPoint = null;
        let recordIdArr = [];
        recordIdArr.push(recordId);
        params = {
            recordIds: recordIdArr,
            type: 'Texts',
            tableId: OBJECT_TABLEID_MAP[getStringParam(this.props.object)],
        };
        endPoint = endPoints.CONTACTS.GET_SMS_UNSUBSCRIBE_LIST;
        response = Promise.resolve(HTTPClient.get(endPoint, params));
        response.then((response) => {
            if (isValidParam(response) && getArrayParam(response).length === 1) {
                sfDialogs.alert(
                    getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                    getLocalizedStrings().message.SMS_INTEGRATION.RECORD_UNSUBSCRIBE_ALERT,
                    null,
                );
            } else {
                let params = {
                    id: recordId,
                    parent_table_id: OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT],
                };
                let endPoint = getObjectSpecificEndpoint(this.props.object, endPoints.RECORDS.RECORD_LINKED_MAP_DATA);
                if (this.props.object === constants.ACCOUNTS_OBJECT || this.props.object === constants.UNITS_OBJECT || this.props.object === constants.CONTACTS_OBJECT) {
                    let _object = this.props.object
                    if (this.props.object === constants.UNITS_OBJECT) {
                        _object = constants.ACCOUNTS_OBJECT;
                    }
                    endPoint = getObjectSpecificEndpoint(_object, endPoints.RECORDS.MAP_DATA);
                    params = {
                        id: recordId,
                        fields: ['company', 'first_name', 'last_name', 'phone', 'mobile', 'country'],
                    };
                }
                var promise = Promise.resolve(HTTPClient.get(endPoint, params));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            let records = response.records;
                            this.processInfoAndOpenSMSDialog(records, recordId, fieldName);
                        }
                    });
                }
            }
        });
    };

    processInfoAndOpenSMSDialog = (records, recordId, fieldName) => {
        let firstName = '';
        let lastName = '';
        let fullName = '';
        let company = '';
        let title = '';
        let country = '';
        if (isValidParam(records)) {
            firstName = getStringParam(records.first_name);
            lastName = getStringParam(records.last_name);
            company = getStringParam(records.company);
            country = getStringParam(records.country);
        }
        if (this.props.object === constants.JOBS_OBJECT) {
            company = getStringParam(records.job_name);
        }

        fullName = (firstName + ' ' + lastName).trim();
        fullName = fullName === '' ? company : fullName;
        fullName = fullName === '' ? getStringParam(records?.mobile) : fullName;
        title = fullName !== '' ? 'To ' + fullName : title;

        country = this.props.app.me.tenant_country;

        let label = title;
        let type = LinkToType.TYPE_CLICK_TO_SMS;
        let countryCode = '';
        let toNumber = getStringParam(this.props.value).trim();
        if (country === 'USA' || country === 'Canada') {
            countryCode = '+1';
        } else if (country === 'India') {
            countryCode = '+91';
        } else if (country === 'United Kingdom') {
            countryCode = '+44';
        } else if (country === 'Germany') {
            countryCode = '+49';
        } else if (country === 'France') {
            countryCode = '+33';
        } else if (country === 'Spain') {
            countryCode = '+34';
        } else if (country === 'Netherlands') {
            countryCode = '+31';
        } else if (country === 'Australia') {
            countryCode = '+61';
        }
        if (toNumber.indexOf('+') < 0) {
            toNumber = countryCode + toNumber;
        }

        let data = {};
        data.id = recordId;
        data.object = this.props.object;
        data.toNumber = toNumber;
        data.fieldName = fieldName;
        data.country = country;
        data.fullName = fullName;
        data.isDetailView = false;
        let style = {};
        style.width = '40%';
        this.props.getAppCustomDrawer(true, label, type, style, data, null, false);
    };

    sendEmail = (props, recordId, value, column) => {
        let object = this.props.object;
        let labelName = null;
        let endPoint = '';
        let params = {};
        let isEmail = true;
        let isFetch = false;
        if (column.is_sales_rep) {
            let userList = props.userList;
            let tempValue = getStringParam(value).toLowerCase();
            let tempUser = userList.filter((f) => getStringParam(f.text).toLowerCase() === tempValue);
            if (tempUser !== null && tempUser.length > 0) {
                labelName = tempUser[0].value;
                labelName = getLocalizedStrings().label.COMMON.EMAIL_TO + ' ' + labelName + '';
                this.openSendEmailDialog(props, recordId, value, labelName, column);
            } else {
                let msg = 'This record is not assigned to any sales rep.';
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
            }
        } else {
            if (object === constants.ACCOUNTS_OBJECT) {
                endPoint = endPoints.ACCOUNTS.MAP_DATA;
                params = {
                    id: recordId,
                    fields: ['company', 'first_name', 'last_name', 'email'],
                };
                isFetch = true;
            } else if (object === constants.CONTACTS_OBJECT) {
                endPoint = endPoints.CONTACTS.MAP_DATA;
                params.id = recordId;
                params = {
                    id: recordId,
                    fields: ['company', 'first_name', 'last_name', 'email'],
                };
                isFetch = true;
            }

            if (isFetch) {
                var promise = Promise.resolve(HTTPClient.get(endPoint, params));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            let records = response.records;
                            let firstName = '';
                            let lastName = '';
                            let fullName = '';
                            let company = '';
                            let email = '';
                            let isFullName = false;
                            if (records) {
                                if (records.hasOwnProperty('first_name')) {
                                    firstName = records.first_name;
                                }
                                if (records.hasOwnProperty('last_name')) {
                                    lastName = records.last_name;
                                }
                                if (records.hasOwnProperty('company')) {
                                    company = records.company;
                                }
                                if (records.hasOwnProperty('email')) {
                                    email = records.email;
                                }
                            }

                            if (
                                firstName !== null &&
                                firstName !== '' &&
                                firstName !== undefined &&
                                lastName !== null &&
                                lastName !== '' &&
                                lastName !== undefined
                            ) {
                                fullName = firstName + ' ' + lastName;
                            }
                            if (
                                firstName !== null &&
                                firstName !== '' &&
                                firstName !== undefined &&
                                (lastName === null || lastName === '' || lastName === undefined)
                            ) {
                                fullName = firstName;
                            }

                            if (
                                (firstName === null || firstName === '' || firstName === undefined) &&
                                lastName !== null &&
                                lastName !== '' &&
                                lastName !== undefined
                            ) {
                                fullName = lastName;
                            }

                            if (fullName === null || fullName === '' || fullName === undefined) {
                                fullName = company;
                            }

                            if (fullName === null || fullName === '' || fullName === undefined) {
                                fullName = email;
                            }

                            if (fullName === null || fullName === '' || fullName === undefined) {
                                fullName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
                                isEmail = false;
                            }

                            if (fullName !== null && fullName !== '' && fullName !== undefined) {
                                if (isEmail) {
                                    labelName = getLocalizedStrings().label.COMMON.EMAIL_TO + ' ' + fullName + '';
                                } else if (!isEmail && !isFullName) {
                                    labelName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
                                }
                            }
                            this.openSendEmailDialog(props, recordId, value, labelName, column, firstName);
                        }
                    });
                }
            } else {
                labelName = getLocalizedStrings().label.COMMON.EMAIL_TO + ' ' + value + '';
                this.openSendEmailDialog(props, recordId, value, labelName, column);
            }
        }
    };

    openSendEmailDialog = (props, recordId, value, labelName, column, firstName) => {
        let data = {};
        data.id = recordId;
        data.object = props.object;
        data.parentObject = props.parentObject;
        data.parentRecordId = props.parentRecordId;
        if (column.is_sales_rep) {
            let userList = props.userList;
            let tempVal = getStringParam(value).toLowerCase();
            let tempUser = userList.filter((f) => getStringParam(f.text).toLowerCase() === tempVal);
            if (tempUser !== null && tempUser.length > 0) {
                data.email = tempUser[0].email;
                data.type = constants.TYPE_NOTIFY;
            }
        } else {
            data.email = value;
            data.type = constants.TYPE_EMAIL;
            data.firstName = firstName;
            data.fieldName = column.key;
        }
        //this.props.getAppDrawer(true, labelName, LinkToType.TYPE_EMAIL, data, null);

        let style = {};
        style.width = '90%';
        this.props.getAppCustomDrawer(true, labelName, LinkToType.TYPE_EMAIL, style, data, null, true);
    };

    login = () => {
        let projectId = this.props.rowData.myclient_id;
        let promise = franchiseLogin(projectId);
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    if (getBooleanParam(response.is_franchise_login)) {
                        let previousFRLoginDetails = localStorage.getItem('franchiseAppLoginDetails') // get local storage - franchiseAppLoginDetails - JSON parse
                        previousFRLoginDetails = previousFRLoginDetails ? JSON.parse(previousFRLoginDetails) : [];
                        const { me: { id, background, industry_id, industry_type, is_franchisor, is_franchisee, email, projectId }, selectedModuleItems, selectedModuleName, selectedModuleInfo } = this.props.app
                        const { rememberTab, tabs } = this.props.tab;
                        const currentUrl = window.location.href;
                        const franchiseAppLoginDetails = {}
                        franchiseAppLoginDetails.appData = { id: id, background: background, industryId: industry_id, industryType: industry_type, isFranchisor: is_franchisor, is_franchisee: is_franchisee, email: email, projectId: projectId }
                        franchiseAppLoginDetails.selectionDetails = { selectedModuleItems: selectedModuleItems, selectedModuleName: selectedModuleName }
                        if (isValidParam(selectedModuleInfo)) {
                            franchiseAppLoginDetails.selectionDetails = { ...franchiseAppLoginDetails.selectionDetails, selectedModuleInfo: selectedModuleInfo }
                        }
                        franchiseAppLoginDetails.tabDetails = { listrememberedTab: rememberTab, listselectedTab: tabs }
                        franchiseAppLoginDetails.returnUrl = currentUrl
                        franchiseAppLoginDetails.token = response.token
                        franchiseAppLoginDetails.callFrom = constants.LIST
                        franchiseAppLoginDetails.shouldReturnToInitialPos_ = `shouldReturnToInitialPos_${this.props.app.me.projectId}_${this.props.app.me.id}`
                        if (isArray(previousFRLoginDetails)) {
                            previousFRLoginDetails.push(franchiseAppLoginDetails)
                        }
                        localStorage.setItem('token', response.token);
                        localStorage.setItem('franchiseAppLoginDetails', JSON.stringify(previousFRLoginDetails))
                        localStorage.setItem(`shouldReturnToInitialPos_${this.props.app.me.projectId}_${this.props.app.me.id}`, true)
                        localStorage.setItem('is_franchisor_token', true);
                        if (constants.APP_URL === "") {
                            window.location.replace("/");
                        } else {
                            window.location.replace(constants.APP_URL);
                        }
                    } else {
                        if (response.data.hasOwnProperty('is_subscription_overdue') && response.data.is_subscription_overdue === true) {
                            localStorage.removeItem(`shouldReturnToInitialPos_${this.props.app.me.projectId}_${this.props.app.me.id}`);
                            let alertDialogMessage = getLocalizedStrings().message.UNITS.SUBSCRIPTION_NOT_VALID;
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertDialogMessage, null)
                        }
                    }
                }
            });
        }
    }


    handlecellExpand = () => {
        const id = this.props.rowData.id;
        const label = this.props.rowData?.hLabel || 0;
        const dir = this.props.rowData?.dir || `l${id}e`;
        const rowIndex = this.props?.tableSmmary?.rows.findIndex((r) => r.id === id);

        let isExpanded = getBooleanParam(this.props?.tableSmmary?.expanded[dir]);
        let children = getArrayParam(this.props?.tableSmmary?.childData[dir]);

        let newRows = [...this.props?.tableSmmary?.rows];
        if (children.length == 0) {
            let endPoint = endPoints.ACCOUNTS.LIST_VIEW;
            let params = {};
            params.call_object = this.props.object;
            params.parentItem = this.props.rowData.myclient_id;

            let promise = Promise.resolve(HTTPClient.get(endPoint, params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    let _childData = getArrayParam(response.records);
                    const cDir = `${dir}-l${id}e`;
                    _childData.forEach((element) => {

                        element.hLabel = label + 1;
                        element.dir = cDir;
                        children.push(element)
                    });
                    this.updateTableSmmary(dir, id, rowIndex, newRows, children, isExpanded);
                });
            } else {

                this.updateTableSmmary(dir, id, rowIndex, newRows, children, isExpanded)
            }
        } else {
            this.updateTableSmmary(dir, id, rowIndex, newRows, children, isExpanded)

        }


    }
    updateTableSmmary = (dir, id, rowIndex, newRows, children, isExpanded) => {
        const cDir = `${dir}-l${id}e`
        if (isExpanded) {
            const filteredArray = this.filterArrayWithSubstring(newRows, cDir);
            console.log(filteredArray);
            newRows.splice(rowIndex + 1, filteredArray.length);
        } else {
            newRows.splice(rowIndex + 1, 0, ...children);
        }
        let __tableSmmary = this.props?.tableSmmary;
        __tableSmmary.rows = newRows;
        __tableSmmary.expanded = { ...__tableSmmary.expanded, [dir]: !isExpanded };
        if (isExpanded) {
            __tableSmmary.expanded = this.deleteKeysContainingString(__tableSmmary.expanded, cDir);
        }
        __tableSmmary.childData = { ...__tableSmmary.childData, [dir]: children };
        manipulateListViewRowData('data-update', 0, { records: newRows, ...__tableSmmary });
        this.props.setTableSmmary(__tableSmmary);
    }
    filterArrayWithSubstring = (arr, substring) => {
        return arr.filter(item => item?.dir?.includes(substring));
    }
    deleteKeysContainingString(obj, dir) {
        Object.keys(obj).forEach(key => {
            if (key.includes(dir)) {
                delete obj[key];
            }
        });
        return obj;
    }
    render() {
        let label = this.props.rowData?.hLabel || 0;
        const id = this.props.rowData.id;
        const dir = this.props.rowData?.dir || `l${id}e`;
        let isExpanded = getBooleanParam(this.props?.tableSmmary?.expanded[dir]);
        let column = this.props.column;
        let elelentProps = {};
        let clickableColumn = false;
        let fieldIdx = this.props.listView?.data?.fields?.findIndex((row) => row.id === column?.id);
        if (fieldIdx >= 0) {
            if (this.props.listView.data?.fields[fieldIdx]?.id === column?.id &&
                this.props.object !== constants.APPOINTMENTS && this.props.object !== constants.ROYALTY_PLANS_OBJECT && this.props.object !== constants.TASKS) {
                clickableColumn = true;
            }
        }
        if (this.state.isRedirect && this.state.redirectUrl !== null && this.state.redirectUrl !== '') {
            return <Navigate push to={this.state.redirectUrl} />;
        } else if (this.props.isLinkChild === true) {
            /* link child add */
            let value = getStringParam(this.props.value);

            if (value === '') {
                return null;
            } else {
                return (
                    <Link title={value} to={'#'} onClick={() => this.openChildRecord(value)}>
                        {value}
                    </Link>
                );
            }
        } else {
            /* link child add */
            let record = this.props.rowData;
            let recordProjectID = 0;
            if (this.props.app.me.projectId !== record.t_projectid) {
                recordProjectID = record.t_projectid;
            }
            let id = record['id'];
            if (id < 0) {
                if (this.props.value === undefined || this.props.value === '') {
                    return null;
                } else {
                    return <div> {this.props.value}</div>;
                }
            }
            let isTabOpen = false;
            let linkUrl = '#';
            let isLookup = getBooleanParam(column.is_lookup_field);
            let recordId = this.props.rowData['id'];
            let value = formatValue(this.props);
            let tabLabel = null;
            if (column.field_type === 'url') {
                tabLabel = value;
            } else {
                value = column.is_detail_link && value === '' ? 'Empty' : value;
                //tabLabel = column.is_email_field ? getLocalizedStrings().label.COMMON.SEND_EMAIL : value; //constants.SEND_EMAIL
                tabLabel = value;
                if (((column?.is_email_field && !column?.is_email_field) || clickableColumn) && this.props.object !== constants.APPOINTMENTS && this.props.object !== constants.ROYALTY_PLANS_OBJECT && this.props.object !== constants.TASKS) {
                    linkUrl = getCustomLink(this.props, recordId);
                }
                isTabOpen = linkUrl !== '#' ? true : false;
                if (
                    isLookup &&
                    (this.props.object === constants.APPOINTMENTS ||
                        this.props.object === constants.TASKS ||
                        (this.props.object === constants.QUOTATION_OBJECT && column.name === 'Company'))
                ) {
                    isTabOpen = true;
                }

                if ((isLookup || this.props.object === constants.AUTOMATION_DESIGNER_OBJECT) && this.props.isDetailView == false) {
                    linkUrl = '#';
                }
            }

            elelentProps.title = value;
            elelentProps.to = linkUrl;

            if (column.name === 'mobile') {
                if (column.is_twilio_sms && (column.is_twilio || column.is_ring_central)) {
                    let phoneTextProvider = this.props.app.me.phone_text_provider ?? constants.SOFFRONT;
                    if (phoneTextProvider !== constants.NONE) {
                        elelentProps.onClick = this.openPopOver.bind(this);
                    } else {
                        elelentProps.onClick = () => { sfDialogs.alert(
                            getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                            getLocalizedStrings().message.PHONE_AND_TEXT_INTEGRATION.NO_PROVIDER,
                            null,
                        ); };    
                    }        
                } else {
                    elelentProps.onClick = () => { this.openRecord(column, isTabOpen, tabLabel, recordId, linkUrl, null, recordProjectID) };
                }
            } else if (this.props.object === constants.UNITS_OBJECT && column.is_detail_link) {
                elelentProps.onClick = this.openPopOver.bind(this);
            }
            else {
                let editorType = record.t_editor_type;

                if (this.props.object === constants.MARKETING_TEMPLATE_OBJECT && isValidParam(editorType)) {
                    elelentProps.onClick = () => { this.openRecord(column, isTabOpen, tabLabel, recordId, linkUrl, editorType, recordProjectID) };
                } else if (this.props.object === constants.BROADCAST_CAMPAIGNS_OBJECT && isValidParam(editorType)) {
                    elelentProps.onClick = () => { this.openRecord(column, isTabOpen, tabLabel, recordId, linkUrl, editorType, recordProjectID) };
                } else {
                    elelentProps.onClick = () => { this.openRecord(column, isTabOpen, tabLabel, recordId, linkUrl, null, recordProjectID) };
                }
            }
            let arrElement;
            if (this.props.object === constants.NOTE_TYPES_OBJECT) {
                arrElement = value;
            } else if (this.props.object === constants.UNITS_OBJECT && column.is_detail_link) {
                if (this.props.object === constants.UNITS_OBJECT && column.is_detail_link && !this.state.isUnitLoginDropdownShow) {
                    let label = record.company;
                    if (label === '') {
                        label = getStringParam(record.first_name) + ' ' + getStringParam(record.last_name);
                    }
                    if (label.trim() === '') {
                        label = 'EMPTY'
                    }
                    linkUrl = '/accounts' + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + id;

                    elelentProps.onClick = () => { this.openRecord(column, true, label, id, linkUrl, null, recordProjectID) };

                }
                arrElement = [
                    <div style={{ marginLeft: label * 12 }}>
                        {this.props.rowData.isParent && this.props.app.me.is_unit_tree_view && <CellExpanderFormatter
                            tabIndex={this.props.rowIdx}
                            expanded={isExpanded}
                            onCellExpand={() => this.handlecellExpand()}
                        />}
                        <div className="rdg-cell-value">
                            <div style={{ marginLeft: this.props.app.me.is_unit_tree_view ? 20 : 0, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                <Link {...elelentProps}>{value}</Link></div>
                        </div>
                    </div>
                ];
            } else {
                arrElement = [<Link {...elelentProps}>{value}</Link>];
            }
            if (column.name === 'mobile' && column.is_click_to_call && column.is_twilio_sms && (column.is_twilio || column.is_ring_central)) {
                arrElement.push(this.getPopOverElememnt('contact', record));
            }
            if (this.props.object === constants.UNITS_OBJECT && column.is_detail_link && this.state.isUnitLoginDropdownShow) {
                arrElement.push(this.getPopOverElememnt('unit', record));
            }

            return <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{arrElement}</div>;
        }
    }
}
export function CellExpanderFormatter({
    tabIndex,
    expanded,
    onCellExpand
}) {
    function handleKeyDown(e) {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            onCellExpand();
        }
    }

    return (
        <div style={cellExpandStyle}>
            <span onClick={onCellExpand} onKeyDown={handleKeyDown} style={cellSpanExpandStyle}>
                <span tabIndex={tabIndex} style={{ color: '#969393' }}>{expanded ? '\u25BC' : '\u25B6'}</span>
            </span>
        </div>
    );
}
function getCustomLink(props, recordId) {
    let linkUrl = '#';
    try {
        if (isValidParam(props)) {
            let object = props.object;
            let column = props.column;
            let isLookup = getBooleanParam(column.is_lookup_field);
            if (isValidParam(column)) {
                if (column.is_detail_link || isLookup || props.isDetailLink == true) {
                    if (
                        object === constants.ACCOUNTS_OBJECT ||
                        object === constants.CONTACTS_OBJECT ||
                        object === constants.OPPORTUNITIES_OBJECT ||
                        object === constants.PRODUCTS_OBJECT ||
                        object === constants.CASES_OBJECT ||
                        object === constants.PROJECTS_OBJECT ||
                        object === constants.ISSUES_OBJECT ||
                        object === constants.SOLUTIONS_OBJECT ||
                        object === constants.CUSTOM_TABLE1_OBJECT ||
                        object === constants.CUSTOM_TABLE2_OBJECT ||
                        object === constants.CUSTOM_TABLE3_OBJECT ||
                        object === constants.CUSTOM_TABLE4_OBJECT ||
                        object === constants.GROUP_OBJECT ||
                        object === constants.SALES_LEADS_OBJECT ||
                        object === constants.COUPONS_OBJECT ||
                        object === constants.CLAIMS_OBJECT ||
                        object === constants.RECYCLE_BIN_OBJECT ||
                        object === constants.PETS_OBJECT ||
                        object === constants.ORDERS_OBJECTS ||
                        object === constants.ORDER_ITEMS_OBJECTS ||
                        object === constants.INVENTORY_OBJECT ||
                        object === constants.JOBS_OBJECT ||
                        object === constants.ROYALTY_OBJECT ||
                        object === constants.AUDITS_OBJECT ||
                        object === constants.ROYALTY_RUN_OBJECT ||
                        object === constants.REPORTED_SALES_OBJECT ||
                        object === constants.COMPLIANCE_OBJECT
                    ) {
                        if (object === constants.ROYALTY_OBJECT) {
                            let tabInfo = getObjectParam(getActiveTabInfo());
                            if (getBooleanParam(tabInfo.isReport)) {
                                if (tabInfo.hasOwnProperty('parentId')) {
                                    recordId = getIntParam(tabInfo.parentId);
                                }
                            } else {
                                recordId = getIntParam(props.detailViewRecord.id);
                            }
                            object = constants.ACCOUNTS_OBJECT;
                        }

                        linkUrl = '/' + object + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + recordId;
                    } else if (object === constants.TASKS) {
                        let record = props.rowData;
                        let linkInfo = record.link_info;
                        let accId = 0;
                        let contId = 0;
                        let oppId = 0;
                        let linkInfoArr = linkInfo.split(',');
                        linkInfoArr.map((item, i) => {
                            let ObjRecordArr = item.split('_');
                            let tableId = getIntParam(ObjRecordArr[0].trim());
                            if (tableId === getIntParam(OBJECT_TABLEID_MAP[constants.ACCOUNTS_OBJECT])) {
                                accId = getIntParam(ObjRecordArr[1]);
                            } else if (tableId === getIntParam(OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT])) {
                                contId = getIntParam(ObjRecordArr[1]);
                            } else if (tableId === getIntParam(OBJECT_TABLEID_MAP[constants.OPPORTUNITIES_OBJECT])) {
                                oppId = getIntParam(ObjRecordArr[1]);
                            }
                        });
                        if (column.name === 'company') {
                            linkUrl = '/' + constants.ACCOUNTS_OBJECT + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + accId;
                        } else if (column.name === 'opportunity_name') {
                            linkUrl = '/' + constants.OPPORTUNITIES_OBJECT + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + oppId;
                        } else {
                            linkUrl = '/' + constants.CONTACTS_OBJECT + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + contId;
                        }
                    } else if (object === constants.APPOINTMENTS) {
                        let _record = props.rowData;
                        let linkInfo = _record.link_info;
                        let accId = 0;
                        let contId = 0;
                        let oppId = 0;
                        let jobId = 0;
                        let linkInfoArr = linkInfo.split(',');
                        linkInfoArr.map((item, i) => {
                            let ObjRecordArr = item.split('_');
                            let tableId = getIntParam(ObjRecordArr[0].trim());
                            if (tableId === getIntParam(OBJECT_TABLEID_MAP[constants.ACCOUNTS_OBJECT])) {
                                accId = getIntParam(ObjRecordArr[1]);
                            } else if (tableId === getIntParam(OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT])) {
                                contId = getIntParam(ObjRecordArr[1]);
                            } else if (tableId === getIntParam(OBJECT_TABLEID_MAP[constants.OPPORTUNITIES_OBJECT])) {
                                oppId = getIntParam(ObjRecordArr[1]);
                            } else if (tableId === getIntParam(OBJECT_TABLEID_MAP[constants.JOBS_OBJECT])) {
                                jobId = getIntParam(ObjRecordArr[1]);
                            }
                        });
                        if (column.name === 'company') {
                            linkUrl = '/' + constants.ACCOUNTS_OBJECT + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + accId;
                        } else if (column.name === 'opportunity_name') {
                            linkUrl = '/' + constants.OPPORTUNITIES_OBJECT + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + oppId;
                        } else if (column.name === 'job_name') {
                            linkUrl = '/' + constants.CONTACTS_OBJECT + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + contId;
                        }
                        else {
                            linkUrl = '/' + constants.CONTACTS_OBJECT + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + contId;
                        }
                    }

                    else if (object === constants.AUTOMATION_DESIGNER_OBJECT) {
                        linkUrl = '/' + object + '/' + LinkToType.TYPE_EDIT + '/' + recordId;
                    }
                }
            }
        }
    } catch (error) {
        console.error("Error in 'detailLink.js -> getCustomLink()':" + error);
    }
    return linkUrl;
}

function formatValue(props) {
    let value = '';
    try {
        let fieldType = isValidParam(props.column) ? getStringParam(props.column.field_type) : '';
        let format = getStringParam(props.format).trim();
        value = getStringParam(props.value).toString().trim();

        if (value !== '' && (fieldType === constants.FIELD_TYPE_DATE || fieldType === constants.FIELD_TYPE_DATE_TIME)) {
            let dateMoment = moment(value);
            if (fieldType === constants.FIELD_TYPE_DATE) {
                value = dateMoment.format(dateFormat[format]);
            } else if (fieldType === constants.FIELD_TYPE_DATE_TIME) {
                value = dateMoment.format(dateTimeFormat[format]);
            }
        }
    } catch (error) {
        console.error("Error in 'detailLink.js -> formatValue()':" + error);
    }
    return value;
}

function openRingCentralWebWidget() {
    var rcs = document.createElement('script');
    //rcs.src =  rcs.src = "https://ringcentral.github.io/ringcentral-embeddable-voice/adapter.js?appKey=MLf6gyKLTrygTsae59AZyw&appSecret=EHB8hNgDTs-kCmS5LvX1IwVNfmBHwPS-2CAlyWs0SQHw&appServer=https://platform.devtest.ringcentral.com&redirectUri=https://ringcentral.github.io/ringcentral-embeddable-voice/redirect.html";

    rcs.src = 'https://ringcentral.github.io/ringcentral-embeddable-voice/adapter.js';
    rcs.id = 'ring-central_widget_adapter';
    var rcs0 = document.getElementsByTagName('script')[0];
    if (rcs0.id !== rcs.id) {
        rcs0.parentNode.insertBefore(rcs, rcs0);
    }
    if (window.RCAdapter) {
        window.RCAdapter.setMinimized(false);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailLink);
