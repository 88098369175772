
import React from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { constants } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const Note = ({parentObject,onChange}) => {

    useEffect(() => {
       
    },[]);

    const dispatch = useDispatch();
    const [noteData, setNoteData] = useState({ note_text: '', note_type: 'Appointment' });

    

    const handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const nName = target.name;

        let tempNoteData = noteData;
        tempNoteData[nName] = value;
        
        let noteType = null;
        if(parentObject === constants.APPOINTMENTS ){
            noteType = 'Appointment';
        }else if(parentObject === constants.TASKS){
            noteType = 'Task';
        }
        tempNoteData['note_type']=noteType;
        setNoteData(tempNoteData);
        onChange(noteData);
    }


            return (
                <div style={{ width: '100%' }} >
                    <div className="row">
                        <div className="col-sm-12 groupFrm">
                            <FormControl style={{ width: '360px',marginLeft:'-15px'}} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    name="note_text"
                                    onChange={(event)=>handleChange(event)}
                                    multiline={true}
                                    type='textarea'
                                    rows={6}
                                    fullWidth={true}
                                    label={getLocalizedStrings().label.APPOINTMENTS_TASKS.ADD_A_NOTE}
                                    textareastyle={{ marginTop: '10px', height: '200px', resize: 'none' }}
                                    autoComplete="new-password"
                                    margin='dense'
                                    size='small'
                                    className={"sf-fields-bg"}
                                />
                            </FormControl></div>
                    </div>
                </div>
            );
    
}





export default  (Note) ;