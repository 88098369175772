import React, { useState, useEffect } from 'react';
import { Button, TextField, RadioGroup, Chip, FormControlLabel, Radio, FormControl } from '@mui/material';
import { getGrouplist, addToGroupAction, saveGroup } from '../../../services/actions/batchActions';
import ShowCircularProgress from '../components/circularProgress';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { isValidParam, getArrayParam, getObjectParam, getStringParam, getBooleanParam, getIntParam } from '../../../services/helper/parameterVerifier';
import { addGroupIntoReduxStore, hasAccessPermission } from '../../../services/helper/common';
import * as sfDialogs from '../components/sfDialogs';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { getActiveTab } from '../../../services/helper/sfTabManager';
import { refreshDetailViewListViewData, refreshDetailViewGroup, refreshDetailViewData } from '../../../services/actions/detailViewActions';
import { populateFiltersFromActiveTab, refreshListView } from '../../../services/actions/listViewAcions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import store from '../../../../src/services/store';
import Autocomplete from '@mui/material/Autocomplete'
import { getMyGroups, setGroups } from '../../../services/actions/filterMenuAction';
import map from 'lodash/map';
import { detailViewIsMounted } from "../../../services/actions/detailViewActions";
import { useDispatch, useSelector } from "react-redux";
import {updateActiveTab} from '../../../services/helper/sfTabManager';

const AddToGroup = ({ object, data, selectedRecords, callFrom, closeDrawer }) => {
    const dispatch = useDispatch();
    const detailViewRecord = useSelector((state) => state.detailView.record);
    const listView = useSelector((state) => state.listView);
    const filters = useSelector((state) => state.filters);
    const [mounted, setmounted] = useState(true);
    const [selectedOptions, setselectedOptions] = useState([]);
    const [newgroup, setnewgroup] = useState(false);
    const [groupList, setgroupList] = useState([]);
    const [hasGroupList, sethasGroupList] = useState(true);
    const [hasGroupListError, sethasGroupListError] = useState(false);
    const [addGroupRadio, setaddGroupRadio] = useState('selected');

    useEffect(() => {
        var isdetailviewchild = false;
        let objectName = null;
        if (object === constants.SEARCH_RESULTS) {
            let activeTab = getObjectParam(getActiveTab());
            let activeTabInfo = getObjectParam(activeTab.info);
            let search = getObjectParam(activeTabInfo.search);
            objectName = getStringParam(search.object);
        }
        else if (object === constants.ADVANCE_SEARCH_RESULTS) {
            let activeTab = getObjectParam(getActiveTab());
            let advanceSearch = getObjectParam(activeTab.info.advanceSearch);
            objectName = getStringParam(advanceSearch.object);
        }
        if (data !== null && data.hasOwnProperty('isDetailviewChild')) {
            isdetailviewchild = data.isDetailviewChild;
        }
        if (isValidParam(selectedRecords) || isValidParam(detailViewRecord)) {
            callGroupList();
          
        }
    }, []);



    const handleSelectChange = (event, value) => {
        if (event) {
            sethasGroupListError(false);
            setselectedOptions(value);
        }
    }
    const changeRadioButton = (event, value) => {
        setaddGroupRadio(value);
    }

    const calladdedGroupList = (groupListArr) => {
        object = object.charAt(0).toUpperCase() + object.slice(1);
        let recordId = 0;
        if ((callFrom === constants.POWER_CALLING) || (isValidParam(selectedRecords) && (selectedRecords.length === 1) || isValidParam(detailViewRecord))) {
            if (callFrom === constants.LIST_VIEW || callFrom === constants.KANBAN) {
                recordId = selectedRecords[0].id
            } else if (callFrom === constants.POWER_CALLING) {
                recordId = data.id;
            } else {
                recordId = detailViewRecord.id;
            }

            let params = {
                "parent_object": object,
                "parent_record_id": recordId,
                "fields": ["id"]
            }
            var response = getGrouplist(object, params);
            setmounted(false);
            response.then((response) => {
                setmounted(true);

                var options = getgroupListOptionArr(groupListArr);
                var list = [];
                var _groupList = response.records;
                if (_groupList.length > 0) {
                    map(_groupList, function (groupObject, i) {
                        let _opt = options.find((element) => element.value.toString() === groupObject.id.toString());
                        if(_opt){
                            list.push(_opt);
                        }
                    });
                    setselectedOptions(list);

                } 
            });
        } else {
            setselectedOptions([]);
        }
    }


    const callGroupList = () => {
        let objectName = null;
        if (object === constants.SEARCH_RESULTS) {
            let activeTab = getObjectParam(getActiveTab());
            let activeTabInfo = getObjectParam(activeTab.info);
            let search = getObjectParam(activeTabInfo.search);
            objectName = getStringParam(search.object);
            object = objectName;
        } else if (object === constants.ADVANCE_SEARCH_RESULTS) {
            let activeTab = getObjectParam(getActiveTab());
            let advanceSearch = getObjectParam(activeTab.info.advanceSearch);
            objectName = getStringParam(advanceSearch.object);
            object = objectName;
        }
        let params = {
            "fields": ["name", "id"],
            "search_field_name": 't_status',
            "search_text": 'Active',
            "search_type": 'exact'

        }
        var response = getGrouplist(object, params);
        setmounted(false);
        response.then((response) => {
            if (filters.groups.length === 0) {
                getGroupsToStoreInRedux();
            }
            var _groupList = response.records;
            if (_groupList.length > 0) {
                sortArrayObjectByProperty(_groupList, 'name');
                setmounted(true);
                sethasGroupList(true);
                setgroupList(_groupList);
                if (((callFrom === constants.LIST_VIEW || callFrom === constants.KANBAN) && selectedRecords.length === 1) || (callFrom === constants.DETAIL_VIEW && isdetailviewchild && selectedRecords.length === 1) || (callFrom === constants.DETAIL_VIEW && !isdetailviewchild) || (callFrom === constants.POWER_CALLING)) {
                    calladdedGroupList(_groupList);
                }
            } else {
                setmounted(true);
                sethasGroupList(false);
            }
        });

    }

    const getGroupsToStoreInRedux = () => {
        let promise = Promise.resolve(getMyGroups(object));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    dispatch(setGroups(response));
                }
            });
        }
    }

    const addGroupShowHide = () => {
        let hasPermission = null;
        hasPermission = hasAccessPermission(constants.GROUP_OBJECT, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_CREATE);
        if (hasPermission) {
            var tempboolean = newgroup;
            setnewgroup(!tempboolean)
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
        }
    }

    const saveGroupName = () => {
        var groupName = document.getElementById('groupName').value;
        groupName = groupName.trim();
        let params = {
            "id": 0,
            "name": groupName,
            "description": groupName,
            "t_status": "Active"
        }
        let selectedAddGroupRadio = addGroupRadio;
        if (groupName !== '') {
            if (groupName.trim().length <= 128) {
                var response = saveGroup(object, params);
                response.then((response) => {
                    if (response.status === 0) {
                        if (response.data.id !== -1) {
                          //  callGroupList();
                            addGroupShowHide();
                            setselectedOptions([...selectedOptions, {value: response.data.id.toString() , label: groupName, title: groupName}]);
                            addGroupIntoReduxStore(response.data.id, groupName, "Active");
                            setaddGroupRadio(selectedAddGroupRadio);
                            sethasGroupList(true);
                        } else {
                            if (response.data.id === -1) {
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.BATCH_ACTION_VALIDATION.GROUP_ALREADY_EXIST, null);
                                return 0;
                            }
                        }
                    }
                });
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.BROADCAST_CAMPAIGNS.GROUP_NAME_SIZE_LIMIT, null);
            }
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.BATCH_ACTION_VALIDATION.ADDTOGROUP_NAMEBLANK, null);
        }
    }

    const addToGroup = () => {
        var records = [];
        let groupvalsArray = [];
        var recordIds = [];
        let _state = store.getState();
        let isDetailView = _state.detailView.isDetailView;
        if (callFrom === constants.LIST_VIEW || callFrom === constants.KANBAN) {
            records = selectedRecords;
            for (var i = 0; i < records.length; i++) {
                recordIds.push(records[i].id);
            }
        } else if (callFrom === constants.DETAIL_VIEW) {
            recordIds[0] = detailViewRecord.id;
        } else if (callFrom === constants.POWER_CALLING) {
            recordIds[0] = data.id;
        }
        let isAllRecords = false;
        let tempaddGroupRadio = addGroupRadio;
        if (tempaddGroupRadio === 'all') {
            isAllRecords = true;
        }
        selectedOptions.map(function (item) {
            if (item !== null) {
                groupvalsArray.push(parseInt(item.value, 10));
            }
        });

        var params = {
            "is_all_records": isAllRecords,
            "id": groupvalsArray,
            "record_id": recordIds

        }
        if (callFrom === constants.LIST_VIEW) {
            let tempdata = listView.data;
            let sort_field_name = tempdata.sort_field_name;
            let sort_type = tempdata.sort_type;
            params = { ...params, sort_field_name: sort_field_name, sort_type: sort_type };
        }

        if (groupvalsArray.length == 0 && callFrom !== constants.DETAIL_VIEW) {
            sethasGroupListError(true);
            dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION.GROUP_BLANK_ADDED_BATCH, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else {
            if (groupvalsArray.length < 1) {
                let objectName = null;
                if (object === constants.SEARCH_RESULTS) {
                    let activeTab = getObjectParam(getActiveTab());
                    let activeTabInfo = getObjectParam(activeTab.info);
                    let search = getObjectParam(activeTabInfo.search);
                    objectName = getStringParam(search.object);
                    object = objectName;
                } else if (object === constants.ADVANCE_SEARCH_RESULTS) {
                    let activeTab = getObjectParam(getActiveTab());
                    let advanceSearch = getObjectParam(activeTab.info.advanceSearch);
                    objectName = getStringParam(advanceSearch.object);
                    object = objectName;
                }

                var response = addToGroupAction(object, params);
                setmounted(false);
                response.then((response) => {
                    if (response.status === 0) {

                        if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT) {
                            dispatch(refreshDetailViewGroup(true));
					        dispatch(detailViewIsMounted(false));
                            dispatch(refreshDetailViewData(true));                        }

                        closeDrawer();
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION.GROUP_REMOVED_SUCCESSFULLY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        if (isDetailView) {
                            dispatch(refreshDetailViewListViewData(true));
                        } else {
                            refreshListView(object);
                        }
                    }
                });
            } else {
                if (isAllRecords) {
                    params = getObjectParam(params);
                    params = { ...params, ...getQueryParams() };
                }
                let objectName = null;
                if (object === constants.SEARCH_RESULTS) {
                    let activeTab = getObjectParam(getActiveTab());
                    let activeTabInfo = getObjectParam(activeTab.info);
                    let search = getObjectParam(activeTabInfo.search);
                    objectName = getStringParam(search.object);
                    object = objectName;
                } else if (object === constants.ADVANCE_SEARCH_RESULTS) {
                    let activeTab = getObjectParam(getActiveTab());
                    let advanceSearch = getObjectParam(activeTab.info.advanceSearch);
                    objectName = getStringParam(advanceSearch.object);
                    object = objectName;
                }

                var response = addToGroupAction(object, params);
                setmounted(false);

                response.then((response) => {
                    if (response.status === 0) {
                        closeDrawer();
                        if (object == constants.ACCOUNTS_OBJECT) {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION.GROUP_ADDED_SUCCESSFULLY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        }
                        if (data != null && data.hasOwnProperty('isPowerCalling') && data.isPowerCalling == true) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.BATCH_ACTION.GROUP_ADDED_SUCCESSFULLY, null);
                        } else {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION.GROUP_ADDED_SUCCESSFULLY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        }
                        if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT) {
                            dispatch(refreshDetailViewGroup(true));
                            dispatch(detailViewIsMounted(false));
                            dispatch(refreshDetailViewData(true));
                        }
                        if (isDetailView) {
                            dispatch(refreshDetailViewListViewData(true));
                        } else {
                            let activeTab = getActiveTab();
                            activeTab.info = { ...activeTab.info, rememberSelection: { ...activeTab.info.rememberSelection ?? {}, [object]: new Set()} };
                            updateActiveTab(activeTab);
                            refreshListView(object);
                        }
                    } else {
                        closeDrawer();
                    }
                });
            }
        }
    }

    const getQueryParams = () => {
        let params = populateFiltersFromActiveTab();
        let activeTab = getObjectParam(getActiveTab());
        let info = getObjectParam(activeTab.info);
        let tabObject = getStringParam(activeTab.object);
        let groupByFieldName = getStringParam(info.groupByFieldName);
        let groupByCondition = getStringParam(info.groupByCondition);
        let searchText = getStringParam(info.searchText);
        let searchFieldName = getStringParam(info.searchFieldName);
        let inputParam = getObjectParam(activeTab.info.input_param);
        let isReport = getBooleanParam(activeTab.info.isReport);
        let isSearchWithinList = getBooleanParam(activeTab.info.isSearchWithinList)

        params = getObjectParam(params);

        if (groupByFieldName !== '') {
            params.group_by_field_name = groupByFieldName;
        }

        if (groupByCondition !== '') {
            params.group_by_condition = groupByCondition;
        }

        if (searchFieldName !== '') {
            params.search_field_name = searchFieldName;
            params.search_type = constants.SEARCH_TYPE_ANYWHERE;
        }

        if (searchText !== '') {
            params.search_text = searchText;
        }

        if (tabObject === constants.SEARCH_RESULTS || tabObject === constants.ADVANCE_SEARCH_RESULTS) {
            params.query_id = 0;
            params.query_name = constants.SEARCH_QUERY_NAME;
            if (tabObject === constants.SEARCH_RESULTS) {
                params.query_type = constants.SEARCH_QUERY_TYPE_GLOBAL;
                let search = getObjectParam(info.search);
                params.search_text = getStringParam(search.searchText);
            } else if (tabObject === constants.ADVANCE_SEARCH_RESULTS) {
                params.query_type = constants.SEARCH_QUERY_TYPE_ADVANCE;
                params.search_type = constants.SEARCH_TYPE_START_WITH;
                let advanceSearch = getObjectParam(info.advanceSearch);
                let searchParams = getObjectParam(advanceSearch.searchParams);
                params.search = getArrayParam(searchParams.search);
            }
        } else if (isReport) {
            let filterObj = getObjectParam(activeTab.info.filter);
            let queryId = getIntParam(filterObj.id);
            let queryName = getStringParam(filterObj.name);
            let queryType = getStringParam(filterObj.type);

            params.query_id = queryId;
            params.query_name = queryName;
            params.query_type = queryType;
        } else {
            let filterObj = getObjectParam(activeTab.info.filter);
            let queryId = getIntParam(filterObj.id);
            let queryName = getStringParam(filterObj.name);
            let queryType = getStringParam(filterObj.type);

            if (!params.hasOwnProperty('query_id')) {
                params.query_id = queryId;
            }

            if (!params.hasOwnProperty('query_name')) {
                params.query_name = queryName;
            }

            if (!params.hasOwnProperty('query_type')) {
                params.query_type = queryType;
            }
        }

        params = { ...params, ...inputParam };
        return params;
    }
    const getgroupListOptionArr = (groupListArr) => {
        var options = [];
        var tempGroupList = groupListArr;
        map(tempGroupList, function (groupObject, i) {
            let groupObj = {};
            if (groupObject.id.toString() !== null && groupObject.id.toString() !== "" && groupObject.name !== null && groupObject.name !== "") {
                groupObj.value = groupObject.id.toString();
                groupObj.label = groupObject.name;
                groupObj.title = groupObject.name;
                options.push(groupObj);
            }
        });
        return options;
    }

    var options = getgroupListOptionArr(groupList);
    let withOutSelectOption = [];
    options.map((option) => {
        let containOption = false;
        selectedOptions.map((row) => {
            if (option.value == row.value) {
                containOption = true;
            }
        });
        if (!containOption) {
            withOutSelectOption.push(option);
        }
    });
    var groupmsg = "";
    let data1 = getObjectParam(data);
    let isdetailviewchild = getBooleanParam(data1.isDetailviewChild);
    let isDetailView = getBooleanParam(data1.isDetailView);
    if (hasGroupList) {
        groupmsg = getLocalizedStrings().message.COMMON.CLICK + ' ';
    } else {
        groupmsg = getLocalizedStrings().message.GROUP.NO_GROUP_AVAILABLE + ' ';
    }

    if (mounted) {
        let drHeight = window.innerHeight - 85;
        return (
            <div style={{ marginLeft: '36px', marginTop: '24px', marginRight: '36px', height: drHeight }}>
                {(isValidParam(selectedRecords) && selectedRecords.length > 1 && hasGroupList === true && (isdetailviewchild === true || callFrom === constants.LIST_VIEW || callFrom === constants.KANBAN) && !isDetailView) &&
                    <div>

                        <FormControl component="fieldset">
                            <RadioGroup name="addGroupRadio" onChange={changeRadioButton} defaultValue={addGroupRadio} horizontal>
                                <FormControlLabel value="selected" control={<Radio id="isallrecords" />} label={getLocalizedStrings().label.GROUP.SELECT_RECORDS} />
                                <FormControlLabel value="all" control={<Radio />} label={getLocalizedStrings().label.GROUP.ALL_RECORDS} />
                            </RadioGroup>
                        </FormControl>

                    </div>
                }
                {(hasGroupList === true) && <div style={{ marginTop: '15px' }}>
                    <div style={{ width: '100%' }}>
                        <Autocomplete
                            multiple
                            size="small"
                            id={"autocomplete_group"}
                            name={"autocomplete_group"}
                            key={"autocomplete_group"}
                            className={"sf-atc-autoComplete"}
                            value={selectedOptions}
                            options={[...withOutSelectOption]}
                            onChange={(event, optionsValue) =>
                                handleSelectChange(event, optionsValue)
                            }
                            style={{
                                width: "100%"
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={getLocalizedStrings().message.GROUP.SELECT_GROUP}
                                    //variant="outlined"
                                    placeholder={selectedOptions.length ==0 ? getLocalizedStrings().label.GROUP.SELECT_OPTIONS : ''}
                                    margin="dense"
                                    size='small'
                                    error={hasGroupListError}
                                    className={"sf-fields-bg"}                                />
                            )}
                        />
                    </div>
                </div>}

                <div style={{ marginTop: '10px' }}>
                    {(!newgroup) && <div style={{ marginTop: '13px', display: 'inline-block' }}><span >{groupmsg}</span><a onClick={addGroupShowHide} style={{ cursor: 'pointer' }}>{getLocalizedStrings().message.COMMON.HERE}</a><span > {getLocalizedStrings().message.GROUP.ADD_NEW_GROUP}.</span></div>}
                </div>

                <div style={{ marginTop: '15px' }}>
                    {(!newgroup && hasGroupList === true) && <div> <div style={{ marginTop: '5px', float: 'right', display: 'inline-block' }}>
                        <Button onClick={() => addToGroup()} style={styles.primaryButton} >{getLocalizedStrings().label.COMMON.APPLY}</Button>
                        <Button onClick={() => closeDrawer()} style={styles.secondaryButton} >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                    </div>
                    </div>}
                    {(newgroup) && <div style={{ marginTop: '15px' }}>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                label={getLocalizedStrings().label.GROUP.GROUP_NAME}
                                id="groupName"
                                name="groupName"
                                autoFocus={true}
                                autoComplete="new-password"
                                style={{ zIndex: 0 }}
                                margin="dense"
                                size='small'
                                className={"sf-fields-bg"} />
                        </FormControl>
                        <div style={{ float: 'right', marginTop: '10px', paddingBottom: '5em' }}>
                            <Button onClick={saveGroupName} style={styles.primaryButton} >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                            <Button onClick={addGroupShowHide} style={styles.secondaryButton} >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                        </div>
                    </div>}
                </div>

            </div>

        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '40%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }

};

export default AddToGroup;