
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as HTTPClient from '../../../services/helper/httpClient';
import { getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { getObjectParam, isValidParam, isUrl} from '../../../services/helper/parameterVerifier';
import { endPoints } from '../../../services/constants/endPoints';
import { setMarketingResults } from '../../../services/actions/marketingReportAction';
import { setHeader } from '../../../services/actions/headerActions';
import { getListViewData } from '../../../services/actions/listViewAcions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';

function mapStateToProps(state) {
    return {
        tab: state.tab,
        marketingReportResults: state.marketingReportResults,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setMarketingResults: (marketingResults) => {
            dispatch(setMarketingResults(marketingResults));
        },
        setHeader: (selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader) => {
            dispatch(setHeader(selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader));
        },
        getListViewData: (object, params) => {
            dispatch(getListViewData(object, params));
        },
    }
}
  class DashBoardLinkClickInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            arrReportDetail: [],
            gblStatIdSubArr: [],
            campaignId: 0,
            messageName: "",
            linkClickInfoArray: null,
            statId: 0,
            linkClickInfo: null,
            isListviewMounted: false,
            clickedUrl: null,
        };
    }

    componentDidMount() {

        this.generateLinkClickInfoDetails(this.props);
    }

    componentDidUpdate() {
        if (isValidParam(this.state.clickedUrl)) {
            document.getElementById(this.state.clickedUrl + "_listview_section").style.display = 'block';
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.generateLinkClickInfoDetails(nextProps);
    }

    generateLinkClickInfoDetails = (propsValue) => {
        try {
            let tabInfo = getObjectParam(getActiveTabInfo());
            if (isValidParam(tabInfo) && tabInfo.hasOwnProperty('input_param')) {
                let inputParam = getObjectParam(tabInfo.input_param);
                this.fetchLinkClickDetailInfo(inputParam, propsValue);
            }
        } catch (error) {
            console.error("Error in 'LinkClickInfo.js -> generateLinkClickInfoDetails()':" + error);
        }
    }

    fetchLinkClickDetailInfo = (inputParam, propsValue) => {
        try {
            let params = {
                stat_id: inputParam.stat_id,
                url: "",
            }
            var promise = Promise.resolve(HTTPClient.get(endPoints.REPORTS.LINK_CLICK_INFO, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.hasOwnProperty('link_click_info') && isValidParam(response['link_click_info']) &&
                        response['link_click_info'].length > 0) {
                        let linkClickInfoArray = response['link_click_info'];
                        params = {
                            stat_id: inputParam.stat_id,
                            url: "all",
                        }
                        var promise = Promise.resolve(HTTPClient.get(endPoints.REPORTS.LINK_CLICK_INFO, params));
                        promise.then((response) => {
                            if (response.hasOwnProperty('link_click_info') && isValidParam(response['link_click_info']) &&
                                response['link_click_info'].length > 0) {
                                let arrLinkClickInfo = response['link_click_info'];
                                let totalCountValue = arrLinkClickInfo[0].count;
                                let percentageValue = 0;
                                linkClickInfoArray.forEach((linkClickArrObj, i) => {
                                    percentageValue = Math.round((linkClickArrObj.count / totalCountValue) * 100);
                                    linkClickArrObj.percentage = percentageValue;
                                })
                                linkClickInfoArray = linkClickInfoArray.concat(arrLinkClickInfo);
                                this.setState({
                                    linkClickInfoArray: linkClickInfoArray,
                                    statId: inputParam.stat_id,
                                    messageName: propsValue.selectedMsgName,
                                })
                            }
                        })
                    }
                }
            });
        } catch (error) {
            console.error("Error in 'LinkClickInfo.js -> fetchLinkClickDetailInfo()':" + error);
        }
    }

    openUrl = (url) => {
        try {
            if (isValidParam(url) && isUrl(url)) {
                window.open(url);
            }
        } catch (error) {
            console.error("Error in 'LinkClickInfo.js -> openUrl()':" + error);
        }
    }

    drilldownMarketingReport = (clickedUrl) => {

        try {
            clickedUrl = clickedUrl.toLowerCase()
            // call parent function to update parent state 
            this.props.setClickUrl(clickedUrl);
            this.setState({
                isListviewMounted: true,
                clickedUrl: clickedUrl,
            });

        } catch (error) {
            console.error("Error in 'LinkClickInfo.js -> drilldownMarketingReport()':" + error);
        }
    }

    populateLinkClickInfo = () => {
        let linkClickInfo = [];
        try {
            let linkClickInfoArray = this.state.linkClickInfoArray;
            if (isValidParam(linkClickInfoArray) && linkClickInfoArray.length > 0) {
                let totalCount = 0;

                linkClickInfoArray.forEach((objLinkclickInfo, i) => {
                    totalCount = totalCount + objLinkclickInfo.count;
                    linkClickInfo.push(
                        <div>
                            <div style={{ paddingBottom: '80px' }}>
                                <div align="left" className="report_box" style={{ width: '64%', padding: '10px 20px', minHeight: '69px', wordBreak: 'break-all' }}>
                                    {
                                        (objLinkclickInfo.url !== 'All') &&
                                        <Link to="#" onClick={() => this.openUrl(objLinkclickInfo.url)} style={{ float: 'left', color: '#717171', textDecoration: 'none' }}>
                                            {objLinkclickInfo.url}
                                        </Link>
                                    }
                                    {
                                        (objLinkclickInfo.url === 'All') &&
                                        <span style={{ float: 'left', color: '#717171' }}>{objLinkclickInfo.url}</span>
                                    }
                                </div>
                                <div className="report_gap"></div>
                                <div align="center" className="report_box" style={{ width: '10%', padding: '10px 20px', minHeight: '69px' }}>
                                    {/* <Link to="#" onClick={() => this.drilldownMarketingReport(objLinkclickInfo.url)}> */}
                                    <span style={{ fontSize: 18, color: '#F04B24', cursor: 'pointer' }} onClick={() => this.drilldownMarketingReport(objLinkclickInfo.url)}  >{objLinkclickInfo.count}</span>
                                    {/* </Link> */}
                                    <br />
                                    {
                                        (objLinkclickInfo.hasOwnProperty('percentage')) &&
                                        <span>{objLinkclickInfo['percentage'] + '%'}</span>
                                    }
                                </div>
                            </div>

                        </div>
                    );
                });
            }
        } catch (error) {
            console.error("Error in 'LinkClickInfo.js -> populateLinkClickInfo()':" + error);
        }
        return linkClickInfo;
    }

    render() {
        let linkClickInfo = this.populateLinkClickInfo();
        return (
            <div style={{ paddingLeft: '41px', color: '#717171' }} >
                <div><h4>{getLocalizedStrings().label.COMMON.CTR_DETAILS_FOR} {this.state.messageName}</h4></div>
                <div style={{ paddingBottom: '14px', fontSize: '1.1em' }}>
                    <div align="left" style={{ width: '64%', display: 'inline-block' }}>{getLocalizedStrings().label.COMMON.URL}</div>
                    <div align="center" style={{ width: '10%', display: 'inline-block', paddingLeft: '24px' }}>{getLocalizedStrings().label.COMMON.COUNT}</div>
                </div>
                <div>{linkClickInfo}</div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardLinkClickInfo);


