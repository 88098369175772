import { actions } from '..//constants/actions';
const schedulerInitialState = {
    data: {},
    workflowList: {},
    linkDetails: [],
}

const schedulerReducer = (state = schedulerInitialState, action) => {
    switch (action.type) {
        case actions.SCHEDULER_DETAILS:
        return {
            ...state,
            data: action.payload
        }
        case actions.SCHEDULER_WORKFLOW_LIST_GET:
            return {
                ...state,
                workflowList: action.payload
        }
        case actions.SCHEDULER_LINK_DETAILS:
            return {
                ...state,
                linkDetails: action.payload
            }
        default:
            return state;
    }
    
}

export default schedulerReducer;