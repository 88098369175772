import React, { useCallback, useEffect, useState , memo } from 'react';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { constants } from '../../../services/constants/constants';
import * as sfDialogs from '../components/sfDialogs';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { GoogleMap, Marker, Circle, useJsApiLoader,InfoWindow} from "@react-google-maps/api";


const containerStyle = {
    width: '100%',
    height: '600px'
};

const infoWindowStyles = {
    boldText: {
        fontWeight: 'bold',
        margin: '0',
        textAlign: 'center',
        fontSize: '11px'
    },
    regularText: {
        margin: '0',
        textAlign: 'center',
        fontSize: '11px'
    }
};

const Map = ({ object, data, closeDrawer }) => {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: constants.GOOGLE_MAP_API_KEY
    })

    // const [ismounted, setIsMounted] = useState(false);
    // const [zoom, setZoom] = useState(13);
    // const [coords, setCoords] = useState({
    //     lat: 51.5258541, lng: -0.08040660000006028,
    // });
    // const [map, setMap] = React.useState(null);
    // const [fullMapData, setFullMapData] = useState([]);
    // const [mapData, setMapData] = useState([]);
    // const [infoWindowOpen, setInfoWindowOpen] = useState({});

    const [newData ,setNewData] = useState({ismounted: false, map: null, mapData: [], infoWindowOpen: {}});

    const onLoad = useCallback((map) => {
        map.setOptions({
            disableDefaultUI: true
        });
    }, []);

    const onDragEnd = (e) => {
    };

    const onClick = (e) => {
    };

    const onUnmount = useCallback(function callback(map) {
        setNewData((prev) => ({ ...prev, map: null }));
    }, [])

    useEffect(() => {
        let geocoder = new window.google.maps.Geocoder();
        let endPoint = "";
        let params = { "id": 0, "fields": ["company", "address1", "address2", "city", "state", "country", "zip_code"] };
        const info = data?.id?.map(item => ({...params, id:item}));

        if (object === "accounts") {
            endPoint = endPoints.ACCOUNTS.MAP_DATA;
            params.id = data.id[0];
        } else if (object === "contacts") {
            endPoint = endPoints.CONTACTS.MAP_DATA;
            params.id = data.id[0];
        }

        info.forEach((item) => (Promise.resolve(HTTPClient.get(endPoint, item))).then((response) => {
            new Promise((resolve, reject) => {
                const requiredParamForMapData = Object.values(response.records).join(', ');
                const requireCountryCheck = response.records.hasOwnProperty('country');
                if ((requiredParamForMapData.trim() && !requireCountryCheck) || (!requiredParamForMapData.trim())) {
                    setNewData((prev) => ({ ...prev, ismounted: false }));
                    closeDrawer();
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.BATCH_ACTION_VALIDATION.MAP_INSUFFICIENT_INFO, null);
                } else {
                    geocoder.geocode({ 'address': requiredParamForMapData }, (results, status) => {
                        if (status === window.google.maps.GeocoderStatus.OK) {
                            resolve({ 'lat': results[0].geometry.location.lat(), 'lng': results[0].geometry.location.lng() });
                        }
                    })
                }
            }).then((cords) => {
                const checkingCompanyName = response.records.hasOwnProperty('company');
                const newCords = { ...cords, address: checkingCompanyName ? Object.values(response.records) : ['', ...Object.values(response.records)] };
                setNewData((prev)=>({...prev,ismounted: true,mapData: [...prev.mapData, newCords]}));
            })
        }));


        //old method(back up)

        
        //     info.forEach((item) => (Promise.resolve(HTTPClient.get(endPoint, item))).then((response)=>{
        //     let finalAddress = "";
        //     let addressArray = [];
        //     let zoom = 13;
        //     let name='';
        //     if (response.hasOwnProperty('records')) {
        //         if (response.records.hasOwnProperty('address1')) {
        //             addressArray.push({ 'name': 'address1', 'value': response.records["address1"], 'zoom': 15 });
        //         }
        //         if(response.records.hasOwnProperty('company')){
        //             name = response.records["company"];
        //         }
        //         if (response.records.hasOwnProperty('address2')) {
        //             addressArray.push({ 'name': 'address2', 'value': response.records["address2"], 'zoom': 15 });
        //         }
        //         if (response.records.hasOwnProperty('city')) {
        //             addressArray.push({ 'name': 'city', 'value': response.records["city"], 'zoom': 11 });
        //         }
        //         if (response.records.hasOwnProperty('state')) {
        //             addressArray.push({ 'name': 'state', 'value': response.records["state"], 'zoom': 9 });
        //         }
        //         if (response.records.hasOwnProperty('country')) {
        //             addressArray.push({ 'name': 'country', 'value': response.records["country"], 'zoom': 4 });
        //         }
        //         if (response.records.hasOwnProperty('zip_code')) {
        //             addressArray.push({ 'name': 'zip_code', 'value': response.records["zip_code"], 'zoom': 15 });
        //         }
        //     }


        //     for (let i in addressArray) {
        //         let value = addressArray[i]["value"];
        //         if (value && value !== "") {
        //             if (finalAddress === "") {
        //                 finalAddress = value;
        //                 zoom = addressArray[i]["zoom"];
        //             } else {
        //                 finalAddress = (finalAddress + ',' + value);
        //             }
        //         }
        //     }

        //     if (finalAddress && finalAddress !== "") {
        //         let innerResponse = new Promise((resolve) => {
        //             geocoder.geocode({ 'address': finalAddress }, (results, status) => {
        //                 if (status === window.google.maps.GeocoderStatus.OK) {
        //                     let coords = {};
        //                     coords.lat = results[0].geometry.location.lat();
        //                     coords.lng = results[0].geometry.location.lng();
        //                     resolve(coords);

        //                 }
        //             });
        //         });
        //         innerResponse.then((coords) => {
        //             obj={...coords,zoom,fullAddress:[name,...addressArray.map(a=>a.value)]};
        //             setFullMapData((prev)=>[...prev,obj]);
        //             setIsMounted(true);
        //             setZoom(zoom);
        //         },
        //             function (error) {
        //             });

        //     } else {
        //         closeDrawer();
        //         sfDialogs.alert(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.BATCH_ACTION_VALIDATION.MAP_INSUFFICIENT_INFO, null);
        //     }
        // }));

    }, []);

    useEffect(() => {
        setNewData((prev) => ({ ...prev, infoWindowOpen: Array(newData.mapData.length).fill(true) }));
    }, [newData.mapData]);


    if (newData.ismounted) {
        return (
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={{
                    lat: newData.mapData[0].lat,
                    lng: newData.mapData[0].lng
                }}
                loadingMessage={'Loading ....'}
                zoom={data.id.length > 1 ? 2 : 13}
                onUnmount={onUnmount}
                onLoad={onLoad}
            >
                {newData.mapData.map((coords, index) => (
                    <React.Fragment key={index}>
                    <Marker
                        position={{
                            lat: coords.lat,
                            lng: coords.lng
                        }}
                            onClick={() => setNewData((prev) => ({ ...prev, infoWindowOpen: { ...prev.infoWindowOpen, [index]: true } }))}
                    />

                      {newData.infoWindowOpen[index] &&  (<InfoWindow
                            position={{ lat: coords.lat, lng: coords.lng }}
                            onCloseClick={() =>
                                setNewData((prev) => ({ ...prev, infoWindowOpen: { ...prev.infoWindowOpen, [index]: false } }))
                              }
                            options={{
                                headerDisabled: false,
                                pixelOffset: new window.google.maps.Size(0, -30),
                            }}
                        >
                            <div style={{
                                backgroundColor: 'white',
                                padding: '10px',
                                borderRadius: '5px',
                                fontSize: '14px',
                            }}>
                                {coords.address?.map((item, index) => (
                                    <p key={index} style={index === 0 ? infoWindowStyles.boldText : infoWindowStyles.regularText}>{item}</p>
                                ))}
                            </div>
                        </InfoWindow>)}

                    </React.Fragment>
                ))}
                {/* <Circle
                    center={{ lat: coords.lat, lng: coords.lng }}
                    radius={500}
                    onClick={onClick} /> */}
            </GoogleMap>
        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }

}
export default memo(Map);