import React from 'react';
import { connect } from 'react-redux';
import { isValidParam, getStringParam, getArrayParam, getObjectParam, getBooleanParam, getIntParam } from '../../../services/helper/parameterVerifier';
import { dateFormat } from '../../../services/helper/utils';
import moment from 'moment';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SmsFailedIcon from '@mui/icons-material/SmsFailed';

const SMSBody = ({ key, details }) => {
    const [arrSMSDetails, setArrSMSDetails] = useState(null);
    const appState = useSelector((state) => state.app);
    const userListData = useSelector((state) => state.userlist);
    const isMounted = useSelector((state) => state.userlist.mounted);

    const scollToRef = useRef();

    useEffect(() => {
        scollToRef.current.scrollIntoView();
    }, [appState])

    useEffect(() => {
        setArrSMSDetails(details);
    }, [details]);


    const getStyle = () => {
        let style = {}
        let isSent = getBooleanParam(details.is_sent);
        let isReceived = getBooleanParam(details.is_received);


        style.borderRadius = 4;
        style.width = 'auto';
        if (isSent && !isReceived) {
            style.float = 'right';
            style.background = '#666666';
            style.color = '#fff';
            style.padding = 10;
            style.marginRight = 8;
        } else if (!isSent && isReceived) {
            style.float = 'left';
            style.background = '#0384d8';
            style.color = '#fff';
            style.padding = 10;
            style.marginLeft = 12;
        }
        return style;
    }


    const generateImageView = (userName) => {
        let arrElements = [];
        let tempdetails = details;
        let isSend = getBooleanParam(tempdetails.is_sent);
        let format = dateFormat[appState.me.date_format];
        let momentDate = isValidParam(tempdetails.date_time) ? new moment(tempdetails.date_time) : new moment();
        let dateAfterFormat = momentDate.format(format);
        let imageUrls = getArrayParam(tempdetails.t_attachment);
        let isSent = getBooleanParam(details.is_sent);
        let isReceived = getBooleanParam(details.is_received);
        let stylelist = {};
        stylelist.borderRadius = 4;
        stylelist.width = 'auto';
        if (isSent && !isReceived) {
            stylelist.background = '#666666';
            stylelist.color = '#fff';
            stylelist.padding = 10;
            stylelist.marginRight = 8;
        } else if (!isSent && isReceived) {
            stylelist.background = '#0384d8';
            stylelist.color = '#fff';
            stylelist.padding = 10;
            stylelist.marginLeft = 12;
        }
        let floatDirection = '';
        if (isSent && !isReceived) {
            floatDirection = 'right';
        }
        else if (!isSent && isReceived) {
            floatDirection = 'left';
        }


        imageUrls.forEach((imageUrl, index) => {
            if (isValidParam(imageUrl)) {
                arrElements.push(
                    <div key={'view_sms' + tempdetails.id} className='row' style={{ minHeight: 20 }}>
                        <div>
                            <div style={{ ...stylelist, float: floatDirection == 'right' ? 'right' : 'left', left: floatDirection == 'right' ? 0 : '', alignItems: floatDirection == 'right' ? 'right' : 'left' }}>

                                <span>
                                    <div style={{ maxHeight: 100, maxWidth: 100 }}>
                                        <a href={imageUrl} target="_blank"><img style={{ maxHeight: 100, maxWidth: 100 }} src={imageUrl} /></a>
                                    </div>
                                    <ul style={{ float: 'right', margin: "0", lineHeight: "1.2", listStyleType: "none" }}>
                                        <li style={{ fontSize: '10px', fontWeight: 300, margin: '0px' }}>{isSend ? userName : tempdetails.contact_name}</li>
                                        <li style={{ fontSize: '10px', fontWeight: 300, margin: '0px' }}>{dateAfterFormat}</li>

                                    </ul>
                                </span>
                            </div>
                        </div>
                    </div>
                );
            }
        })
        return arrElements;
    }
    let pageHeight = window.innerHeight - 200;
    let top = (pageHeight - 10) / 2
    let smsInboxHeight = (pageHeight);

    let tempDetails = details;
    let imageUrl = tempDetails.hasOwnProperty('t_attachment') ? getArrayParam(tempDetails.t_attachment) : null;
    let attachmentPreview = null;
    let userName = appState.me.name;
    if (isMounted) {
        let tempuserList = getArrayParam(userListData.data).filter(f => f.id === tempDetails.user_id);
        if (tempuserList.length > 0) {
            userName = tempuserList[0].text;
        }
        if (isValidParam(imageUrl)) {
            attachmentPreview = generateImageView(userName);
        }
        let isSend = getBooleanParam(tempDetails.is_sent);
        let status = getStringParam(tempDetails.status);
        let format = dateFormat[appState.me.date_format];
        let momentDate = isValidParam(tempDetails.date_time) ? new moment(tempDetails.date_time) : new moment();
        let dateAfterFormat = momentDate.format(format);
        let isSent = getBooleanParam(details.is_sent);
        let isReceived = getBooleanParam(details.is_received);
        let stylelist = {};
        stylelist.borderRadius = 4;
        stylelist.width = 'auto';
        stylelist.height = 'fit-content'
        if (isSent && !isReceived) {
            stylelist.background = '#666666';
            stylelist.color = '#fff';
            stylelist.paddingLeft = 10;
            stylelist.paddingRight = 10;
            stylelist.marginRight = 8;
        } else if (!isSent && isReceived) {
            stylelist.background = '#0384d8';
            stylelist.color = '#fff';
            stylelist.paddingLeft = 10;
            stylelist.paddingRight = 10;
            stylelist.marginLeft = 12;
        }
        let floatDirection = '';
        if (isSent && !isReceived) {
            floatDirection = 'right';
        }
        else if (!isSent && isReceived) {
            floatDirection = 'left';
        }

        return (
            <div key={'view_sms' + tempDetails.id}>
                <div className='row' ref={scollToRef} style={{ margin: '10 0', minHeight: 20 }}>
                    <div className='row' style={{ minHeight: 20 }}>
                        <div>
                            <div style={{ float: floatDirection == 'right' ? 'right' : 'left', alignItems: floatDirection == 'right' ? 'right' : 'left' }}>
                                <span style={{display:'flex'}}>
                                    <div style={{ ...stylelist }}>
                                        <p style={{ fontSize: '13px', fontWeight: 400 }} title={tempDetails.sms_text}>{tempDetails.sms_text}</p>
                                         <ul style={{ float: 'right', marginTop: '-6px', lineHeight: "1.2", listStyleType: "none" }}>
                                            <li style={{ fontSize: '10px', fontWeight: 300, margin: '0px' }}>{isSend ? userName : tempDetails.contact_name}</li>
                                            <li style={{ fontSize: '10px', fontWeight: 300, margin: '0px' }}>{dateAfterFormat}</li>
                                        </ul>
                                    </div>
                                    <div style={{display:'flex',alignItems: 'flex-end'}}>
                                        {(status == "failed" || status == "undelivered") && <SmsFailedIcon fontSize="small" sx={{ color: '#db0b0b', fontSize: 15 }} />}
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {isValidParam(attachmentPreview) && <div>{attachmentPreview}</div>}
            </div>
        );
    } else {
        return (<div className="loaderh">
            {<div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>}
        </div>);
    }
}
export default SMSBody;