import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { styles } from '../../../services/constants/styles';
import { endPoints } from '../../../services/constants/endPoints';
import ShowCircularProgress from '../components/circularProgress';
import * as HTTPClient from '../../../services/helper/httpClient';
import { setHeader } from '../../../services/actions/headerActions';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { timeZoneWithGMT } from '../../../services/helper/utils';
import { getActiveTab } from '../../../services/helper/sfTabManager';
import { GanttComponent, Inject, Filter, Sort, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-gantt';

const GanttChart = ({ object }) => {
    const app = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const [mounted, setMounted] = React.useState(false);
    const [taskList, setTaskList] = React.useState([]);
    const [projectResources, setProjectResources] = React.useState([]);
    const taskFields = {
        id: 'id',
        name: 'task_name',
        startDate: 'start_date',
        endDate: 'end_date',
        duration: 'duration',
        child: 'subtasks',
        status: 'status',
        resourceInfo: 'resources'
    };
    const labelSettings = {
        rightLabel: 'resources'
    };
    const editSettings = {
        allowEditing: true,
        editMode: 'Auto',
        allowTaskbarEditing: true
    };
    const resourceFields = {
        id: 'name',
        name: 'name',
    };
    const  workWeek = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];

    const dateFormat = 'MMM dd, yyyy hh:mm:ss';

    React.useEffect(() => {
        let tab = getActiveTab();
        let _info = tab.info;
        dispatch(setHeader(object.object, _info.label ? _info.label : 'Plan Chart', null, false, true));
        let _filterText = _info?.search_text || '';
        // let _taskType = 'All';
        let _workflowName = '';
        let _taskStatus = 'Pending'
        if (_filterText.includes(",")) {
            _taskStatus = _filterText.split(",")[0];
            _workflowName = _filterText.split(",")[1];
        };
        getTaskList({ id: _info.id, query_name: _info.query_name, workflow_name: _workflowName, task_status: _taskStatus });
    }, []);
    const getTaskList = (param) => {
        var promise = Promise.resolve(HTTPClient.get(endPoints.TASK.GANTT_CHART, param));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    console.log(response);
                    let _resources = [];
                    response.owner.map((resource, index) => {
                        _resources.push({ 'name': resource });
                    });
                    setTaskList(response.task_list);
                    setProjectResources(_resources);
                    setMounted(true);
                }
            }
            );
        }
    }
    function getStatusColor(status) {
        const task = {
            "Pending": "#FB773C",
            "In-progress": "#FB773C",
            "Not Started": "#C7C8CC",
            "Completed": "#88D66C",
            "Overdue": "#FF6969"
        };
        const progress = {
            "Pending": "#FF8225",
            "In-progress": "#FF8225",
            "Not Started": "#5F6F65",
            "Completed": "#059212",
            "Overdue": "#FF0000"
        }
        return {task: task[status] || "#7C00FE" , progress : progress[status] || "#180161"} ;
    }
    function queryTaskbarInfo(args) {
        let colorObj = getStatusColor(args.data.taskData.status);
        // if ( !args.data.hasChildRecords) {
            args.taskbarBgColor = colorObj.task;
            args.progressBarBgColor = colorObj.progress;
        //   }
      }

    let pageHeight = window.innerHeight - 210;
    let top = (pageHeight - 10) / 2
    const timezone = app.me.timezone;
    const _timeZoneWithGMT = timeZoneWithGMT[timezone];
    if (mounted) {
        return (
            <div >
                <div className=" row ">
                    <div className="col-lg-12">
                        <GanttComponent  queryTaskbarInfo={queryTaskbarInfo}  dataSource={taskList} allowFiltering={true} allowSorting={true} taskFields={taskFields} editSettings={editSettings} labelSettings={labelSettings} resourceFields={resourceFields} resources={projectResources} height={pageHeight}
                        workWeek={workWeek} includeWeekend={true} dateFormat={dateFormat}>
                            <ColumnsDirective>
                                <ColumnDirective field='task_name' headerText='Task Name'></ColumnDirective>
                                <ColumnDirective field='duration'  headerText="Duration"></ColumnDirective>
                                <ColumnDirective field='start_date' headerText="Start Date"></ColumnDirective>
                                <ColumnDirective field='end_date' headerText="End Date"></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Filter, Sort]} />
                        </GanttComponent>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (<div className="loaderh">
            {<div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>}
        </div>)
    }

}
export default GanttChart;