import React, { useState } from 'react';
import { LinkToType } from '../../../services/constants/link';
import { styles } from '../../../services/constants/styles';
import { getStringParam, isValidParam } from '../../../services/helper/parameterVerifier';
import { getAppCustomDrawer, getAppDialog, getAppDrawer } from '../../../services/actions/appContainerActions';
import { constants } from '../../../services/constants/constants';
import { addNode } from '../../../services/actions/automationCampaignActions';
import AutomationAction from './automationAction';
import { getActionByName, getMaxId, } from '../../../services/helper/automationUtils';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useDispatch, useSelector } from "react-redux";

const AutomationSidebar = () => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const automationCampaign = useSelector((state) => state.automationCampaign);
    const [isOpenSideMenu, setIsOpenSideMenu] = useState(true);

    const generateNodeObject = (objActions, posObject) => {
        let arrayNodes = automationCampaign.data.nodes;
        let maxId = 0;
        maxId = getMaxId(arrayNodes);
        let childId = maxId + 1;
        let posX = 40;
        let posY = 40;
        if (isValidParam(posObject) && posObject.hasOwnProperty('posX') && posObject.hasOwnProperty('posY')) {
            posX = posObject['posX'];
            posY = posObject['posY'];
        }
        let processId = 0;
        if (objActions.actionName === constants.AUTOMATION_DESIGNER_SCORE_REACHED ||
            objActions.actionName === constants.AUTOMATION_DESIGNER_NOTIFY ||
            objActions.actionName === constants.AUTOMATION_DESIGNER_ADD_NOTE ||
            objActions.actionName === constants.AUTOMATION_DESIGNER_CHANGE_GROUP ||
            objActions.actionName === constants.AUTOMATION_DESIGNER_CONVERT ||
            objActions.actionName === constants.AUTOMATION_DESIGNER_ASSIGN ||
            objActions.actionName === constants.AUTOMATION_DESIGNER_ADD_TASK ||
            objActions.actionName === constants.AUTOMATION_DESIGNER_UPDATE_FIELD ||
            objActions.actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK ||
            objActions.actionName === constants.AUTOMATION_DESIGNER_SMS_REPLY) {
            processId = "";
        }
        return {
            id: 0,
            parentId: 0,
            childId: childId,
            actionId: objActions.actionId,
            actionName: objActions.actionName,
            actionLabel: objActions.actionLabel,
            nodeStatusFlg: 1,
            detailStatusFlg: 1,
            status: "",
            processId: processId,
            secProcessId: 0,
            type: objActions.actionType,
            decisionFlg: 0,
            posX: posX,
            posY: posY,
            details: [],
            referenceId: 0
        };
    }

    const addNodeToCanvas = (objActions, posObject) => {
        let actionsArray = automationCampaign.data.actions;
        let nodeObject = generateNodeObject(objActions, posObject);
        let nodesArray = automationCampaign.data;
        nodesArray.nodes.push(nodeObject);
        if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL) {
            let objWaitAction = getActionByName(actionsArray, constants.AUTOMATION_DESIGNER_WAIT);
            let waitNode = generateNodeObject(objWaitAction, null);
            waitNode.details[0] = {
                interval: 0,
                interval_mode: 'days',
            }
            waitNode.parentId = nodeObject.childId;
            waitNode.posX = nodeObject.posX;
            waitNode.posY = nodeObject.posY + 120;
            waitNode.decisionFlg = 0;
            nodesArray.nodes.push(waitNode);

            let objAction = getActionByName(actionsArray, constants.AUTOMATION_DESIGNER_ADD_MESSAGE);
            let addMessageNode = generateNodeObject(objAction, null);
            addMessageNode.parentId = waitNode.childId;
            addMessageNode.posX = waitNode.posX;
            addMessageNode.posY = waitNode.posY + 120;
            nodesArray.nodes.push(addMessageNode);
        }
        dispatch(addNode(nodesArray));
        return nodeObject;
    }

    const openDrawer = (objActions, posObject) => {

        let actionName = objActions.actionName;
        if (automationCampaign.data.status !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) {
            let nodeObject = null;
            if (actionName !== constants.AUTOMATION_DESIGNER_ADD_MESSAGE && actionName !== constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                nodeObject = addNodeToCanvas(objActions, posObject);
            }
            let dialogName = "";
            let isOpenDrawer = false;

            if (actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK) {
                dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.LINK_CLICKED;
                isOpenDrawer = true;
            }
            if (actionName === constants.AUTOMATION_DESIGNER_SCORE_REACHED) {
                dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.SCORE_REACHED;
                isOpenDrawer = true;
            }
            if (actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL) {
                dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.TOUCH_CAMPAIGN;
                isOpenDrawer = true;
            }
            if (actionName === constants.AUTOMATION_DESIGNER_WEB_FORM) {
                dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.WEB_FORM;
                isOpenDrawer = true;
            }
            if (actionName === constants.AUTOMATION_DESIGNER_LANDING_PAGE) {
                dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.LANDING_PAGE;
                isOpenDrawer = true;
            }
            if (actionName === constants.AUTOMATION_DESIGNER_WEBSITE_VISIT) {
                dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.WEBSITE_VISITED;
                isOpenDrawer = true;
            }
            if (actionName === constants.AUTOMATION_DESIGNER_SMS_REPLY) {
                isOpenDrawer = false;
            }
            if (actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE ||
                actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                let nodeObject = generateNodeObject(objActions, posObject);
                if (actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                    let automationData = automationCampaign.data;
                    let nodesArray = automationData.nodes;
                    nodesArray.push(nodeObject);
                    dispatch(addNode(automationData));

                    nodeObject.details = [];
                    let callbackData = {
                        labelName: getLocalizedStrings().label.AUTOMATION_DESIGNER['Add Touch Message'],
                        value: "New",
                        data: {},
                        nodeobject: nodeObject,
                    }
                    dispatch(getAppDialog(true, constants.EDITOR_TEMPLATE_DIALOG,
                        getLocalizedStrings().label.CAMPAIGNS.TEMPLATE_DIALOG_TITLE,
                        null, callbackData, constants.LIST_VIEW));
                }
            }
            if (actionName === constants.AUTOMATION_DESIGNER_CHANGE_GROUP) {
                dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.CHANGE_GROUP;
                isOpenDrawer = true;
            }
            if (actionName === constants.AUTOMATION_DESIGNER_ADD_NOTE) {
                dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.ADD_NOTE;
                isOpenDrawer = true;
            }
            if (actionName === constants.AUTOMATION_DESIGNER_NOTIFY) {
                dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.NOTIFY;
                isOpenDrawer = true;
            }
            if (actionName === constants.AUTOMATION_DESIGNER_ADD_TASK) {
                dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.ADD_TASK;
                isOpenDrawer = true;
            }
            if (actionName === constants.AUTOMATION_DESIGNER_UPDATE_FIELD) {
                dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.FIELD_UPDATED;
                isOpenDrawer = true;
            }
            if (actionName === constants.AUTOMATION_DESIGNER_ASSIGN) {
                dialogName = getLocalizedStrings().label.COMMON.ASSIGN;
                isOpenDrawer = true;
            }
            if (actionName === constants.AUTOMATION_DESIGNER_CONVERT) {
                dialogName = getLocalizedStrings().label.COMMON.CONVERTED;
                isOpenDrawer = true;
            }
            if (actionName === constants.AUTOMATION_DESIGNER_SEND_SMS) {
                dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.SEND_SMS;
                isOpenDrawer = true;
            }
            if (actionName === constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN) {
                dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.AUTOMATION_DESIGNER_SMS_CAMPAIGN;
                isOpenDrawer = true;
            }
            if (actionName === constants.AUTOMATION_DESIGNER_ADD_SMS) {
                dialogName = getLocalizedStrings().label.AUTOMATION_DESIGNER.ADD_SMS;
                isOpenDrawer = true;
            }
            if (isOpenDrawer) {
                dispatch(getAppDrawer(true, dialogName, actionName, nodeObject, constants.AUTOMATION_DESIGNER));
            }
        }
    }

    const callbackEditorSelection = (callbackData) => {
        try {
            if (callbackData.object === constants.AUTOMATION_DESIGNER_OBJECT) {
                dispatch(getAppDialog(false, constants.EDITOR_SELECTION_DIALOG, null, null, null, null));
                let nodeObject = callbackData['nodeObject'];
                let data = callbackData['data'];
                if (data['type'] === constants.EDITOR_NEW || data['type'] === constants.EDITOR_BEE) {
                    automationCampaign.data['editorType'] = data['type'];
                    let data = {
                        type: "New",
                        'node_object': nodeObject,
                    }
                    let style = {};
                    style.width = '95%';
                    dispatch(getAppCustomDrawer(true, getLocalizedStrings().label.AUTOMATION_DESIGNER['Send Email']),
                        LinkToType.TYPE_NEW, style, data, null, false);
                } else if (data['type'] === constants.EDITOR_LEGACY) {
                    automationCampaign.data['editorType'] = constants.EDITOR_LEGACY;
                    dispatch(getAppDialog(true, constants.TEMPLATE_DIALOG,
                        getLocalizedStrings().label.CAMPAIGNS.TEMPLATE_DIALOG_TITLE,
                        '', nodeObject, constants.AUTOMATION_DESIGNER_OBJECT));
                }
            }
        } catch (error) {
            console.error("Error in 'automationNode.js -> callbackEditorSelection()': " + error);
        }
    }

    const generateStartWith = () => {
        let startWithActions = null;
        let actionsArray = automationCampaign.data.actions;
        if (isValidParam(actionsArray)) {
            const exceptions = [constants.AUTOMATION_DESIGNER_WEB_FORM, constants.AUTOMATION_DESIGNER_LANDING_PAGE, constants.AUTOMATION_DESIGNER_WEBSITE_VISIT,
            constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN];
            startWithActions = actionsArray
                .filter(objActions => objActions.actionType === 3 && !exceptions.includes(objActions.actionName))
                .map(objActions => {
                    let tooltip = generateTooltipInfo(objActions.actionName);
                    return (
                        <AutomationAction
                            key={objActions.actionId}
                            isOpen={isOpenSideMenu}
                            id={objActions.actionId}
                            label={getLocalizedStrings().label.AUTOMATION_DESIGNER[objActions.actionLabel]}
                            icon={objActions.iconName}
                            tooltip={tooltip}
                            openDrawer={openDrawer}
                            objActions={objActions}
                        />
                    )
                });
        }
        return startWithActions;
    }


    const generateDecisions = () => {
        let decisions = null;
        let actionsArray = automationCampaign.data.actions;
        if (isValidParam(actionsArray)) {
            if (automationCampaign.data.templateName !== constants.SMS_DRIP_CAMPAIGN) {
                actionsArray = actionsArray.filter(objActions => {
                    return (objActions.actionType === 1
                        && (objActions.actionName !== constants.AUTOMATION_DESIGNER_SMS_REPLY)
                        && (objActions.actionName !== "SMS_REPLY_OLD"))
                });
            }
            if (automationCampaign.data.templateName === constants.BIRTHDAY_CAMPAIGN_NAME ||
                automationCampaign.data.templateName === constants.PET_BIRTHDAY_CAMPAIGN_NAME) {
                actionsArray = actionsArray.filter(objActions => {
                    return !(objActions.actionType === 1
                        && (objActions.actionName === constants.AUTOMATION_DESIGNER_UNSUBSCRIBE || 
                            objActions.actionName === constants.AUTOMATION_DESIGNER_SCORE_REACHED ||
                            objActions.actionName === "SMS_REPLY_OLD"))
                });
            }

            if (automationCampaign.data.templateName === constants.SMS_DRIP_CAMPAIGN) {
                actionsArray = actionsArray.filter(objActions => {
                    return !(objActions.actionType === 1
                        && (objActions.actionName === constants.AUTOMATION_DESIGNER_UNSUBSCRIBE ||
                            objActions.actionName === constants.AUTOMATION_DESIGNER_SCORE_REACHED ||
                            objActions.actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK ||
                            objActions.actionName === constants.AUTOMATION_DESIGNER_EMAIL_OPEN ||
                            objActions.actionName === "SMS_REPLY_OLD" ||
                            objActions.actionName === constants.AUTOMATION_DESIGNER_SMS_REPLY))
                });
            }

            decisions = actionsArray.filter(objActions => (
                objActions.actionType === 1 &&
                objActions.actionName !== constants.AUTOMATION_DESIGNER_WEB_FORM_SUBMIT
            )).map((objActions, i) => {
                let tooltip = generateTooltipInfo(objActions.actionName);
                return <AutomationAction
                    key={objActions.actionId}
                    isOpen={isOpenSideMenu}
                    id={objActions.actionId}
                    label={getLocalizedStrings().label.AUTOMATION_DESIGNER[objActions.actionLabel]}
                    icon={objActions.iconName}
                    tooltip={tooltip}
                    openDrawer={openDrawer}
                    objActions={objActions} />
            });
        }
        return decisions;
    }

    const generateActions = () => {
        let actions = null;
        let actionsArray = automationCampaign.data.actions;
        let phone_text_provider = app.me.phone_text_provider ?? constants.SOFFRONT;
        if (isValidParam(actionsArray)) {
            if (automationCampaign.data.templateName === constants.SMS_DRIP_CAMPAIGN) {
                actionsArray = actionsArray.filter(objActions => {
                    return !(objActions.actionType === 2
                        && (objActions.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL ||
                            objActions.actionName === constants.AUTOMATION_DESIGNER_CHANGE_GROUP ||
                            objActions.actionName === constants.AUTOMATION_DESIGNER_ADD_NOTE ||
                            objActions.actionName === constants.AUTOMATION_DESIGNER_NOTIFY ||
                            objActions.actionName === constants.AUTOMATION_DESIGNER_ADD_TASK ||
                            objActions.actionName === constants.AUTOMATION_DESIGNER_UPDATE_FIELD ||
                            objActions.actionName === constants.AUTOMATION_DESIGNER_ASSIGN ||
                            objActions.actionName === constants.AUTOMATION_DESIGNER_CONVERT
                        ))
                });
            }
            let _tempActionArray = actionsArray;
            if (phone_text_provider == constants.NONE) {
                _tempActionArray = actionsArray.filter(objActions => objActions.actionType === 2 && objActions.actionName !== constants.AUTOMATION_DESIGNER_SEND_SMS)
            }
            actions = _tempActionArray.filter(objActions => objActions.actionType === 2
                && objActions.actionName !== constants.AUTOMATION_DESIGNER_ADD_MESSAGE && objActions.actionName !== constants.AUTOMATION_DESIGNER_ADD_SMS)
                .map((objActions, i) => {
                    let tooltip = generateTooltipInfo(objActions.actionName);
                    return <AutomationAction
                        key={objActions.actionId}
                        isOpen={isOpenSideMenu}
                        id={objActions.actionId}
                        label={getLocalizedStrings().label.AUTOMATION_DESIGNER[objActions.actionLabel]}
                        icon={objActions.iconName}
                        tooltip={tooltip}
                        openDrawer={openDrawer}
                        objActions={objActions} />
                });
        }
        return actions;
    }

    const generateTooltipInfo = (actionName) => {
        let tooltipMessage = "";
        if (actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL) {
            tooltipMessage = getLocalizedStrings().message.AUTOMATION_DESIGNER.TOOLTIP_MESSAGE_SEND_MAIL;
        }
        if (actionName === constants.AUTOMATION_DESIGNER_WEB_FORM) {
            tooltipMessage = getLocalizedStrings().message.AUTOMATION_DESIGNER.TOOLTIP_MESSAGE_WEB_FORM;
        }
        if (actionName === constants.AUTOMATION_DESIGNER_LANDING_PAGE) {
            tooltipMessage = getLocalizedStrings().message.AUTOMATION_DESIGNER.TOOLTIP_MESSAGE_LANDING_PAGE;
        }
        if (actionName === constants.AUTOMATION_DESIGNER_WEBSITE_VISIT) {
            tooltipMessage = getLocalizedStrings().message.AUTOMATION_DESIGNER.TOOLTIP_MESSAGE_WEBSITE_VISIT;
        }
        if (actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK) {
            tooltipMessage = getLocalizedStrings().message.AUTOMATION_DESIGNER.TOOLTIP_MESSAGE_LINK_CLICK;
        }
        if (actionName === constants.AUTOMATION_DESIGNER_EMAIL_OPEN) {
            tooltipMessage = getLocalizedStrings().message.AUTOMATION_DESIGNER.TOOLTIP_MESSAGE_EMAIL_OPEN;
        }
        if (actionName === constants.AUTOMATION_DESIGNER_UNSUBSCRIBE) {
            tooltipMessage = getLocalizedStrings().message.AUTOMATION_DESIGNER.TOOLTIP_MESSAGE_UNSUBSCRIBE;
        }
        if (actionName === constants.AUTOMATION_DESIGNER_SCORE_REACHED) {
            tooltipMessage = getLocalizedStrings().message.AUTOMATION_DESIGNER.TOOLTIP_MESSAGE_SCORE_REACHED;
        }
        if (actionName === constants.AUTOMATION_DESIGNER_SMS_REPLY) {
            tooltipMessage = getLocalizedStrings().message.AUTOMATION_DESIGNER.TOOLTIP_MESSAGE_SMS_REPLY;
        }
        if (actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
            tooltipMessage = getLocalizedStrings().message.AUTOMATION_DESIGNER.TOOLTIP_MESSAGE_AUTO_MAIL;
        }
        if (actionName === constants.AUTOMATION_DESIGNER_CHANGE_GROUP) {
            tooltipMessage = getLocalizedStrings().message.AUTOMATION_DESIGNER.TOOLTIP_MESSAGE_CHANGE_GROUP;
        }
        if (actionName === constants.AUTOMATION_DESIGNER_ADD_NOTE) {
            tooltipMessage = getLocalizedStrings().message.AUTOMATION_DESIGNER.TOOLTIP_MESSAGE_ADD_NOTE;
        }
        if (actionName === constants.AUTOMATION_DESIGNER_NOTIFY) {
            tooltipMessage = getLocalizedStrings().message.AUTOMATION_DESIGNER.TOOLTIP_MESSAGE_NOTIFY;
        }
        if (actionName === constants.AUTOMATION_DESIGNER_ADD_TASK) {
            tooltipMessage = getLocalizedStrings().message.AUTOMATION_DESIGNER.TOOLTIP_MESSAGE_ADD_TASK;
        }
        if (actionName === constants.AUTOMATION_DESIGNER_UPDATE_FIELD) {
            tooltipMessage = getLocalizedStrings().message.AUTOMATION_DESIGNER.TOOLTIP_MESSAGE_UPDATE_FIELD;
        }
        if (actionName === constants.AUTOMATION_DESIGNER_ASSIGN) {
            tooltipMessage = getLocalizedStrings().message.AUTOMATION_DESIGNER.TOOLTIP_MESSAGE_ASSIGN;
        }
        if (actionName === constants.AUTOMATION_DESIGNER_CONVERT) {
            tooltipMessage = getLocalizedStrings().message.AUTOMATION_DESIGNER.TOOLTIP_MESSAGE_CONVERT;
        }
        if (actionName === constants.AUTOMATION_DESIGNER_SEND_SMS) {
            tooltipMessage = getLocalizedStrings().message.AUTOMATION_DESIGNER.TOOLTIP_MESSAGE_SEND_SMS;
        }
        return tooltipMessage;
    }

    const closeSideMenu = () => {
        setIsOpenSideMenu(false);
    }

    const openSideMenu = () => {
        setIsOpenSideMenu(true);
    }

    const onMouseOver = () => {
        document.getElementById('openedSidebar').style.overflow = "auto";
    }

    const onMouseOut = () => {
        document.getElementById('openedSidebar').style.overflow = "hidden";
    }

    let templateName = automationCampaign.data.template_name;
    templateName = getStringParam(templateName);
    let startWith = null;
    if (templateName !== constants.BIRTHDAY_CAMPAIGN_TEMPLATE_NAME &&
        templateName !== constants.PET_BIRTHDAY_CAMPAIGN_TEMPLATE_NAME &&
        automationCampaign.data.templateName !== "Touch Campaign" &&
        automationCampaign.data.templateName !== constants.SMS_DRIP_CAMPAIGN) {
        startWith = generateStartWith();
    }

    let decisions = generateDecisions();
    let actions = generateActions();

    let navigationMenuStyle = styles.automationActionsHeadings;
    if (!isOpenSideMenu) {
        navigationMenuStyle.paddingBottom = '15px';
    }
    let contentHeight = document.body.clientHeight - 215;
    return (
        <div>
            {
                (isOpenSideMenu) &&
                <div>
                    <div
                        className=''
                        style={{ overflow: 'hidden', height: contentHeight, width: '170px' }}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                        id="openedSidebar">
                        {templateName !== constants.BIRTHDAY_CAMPAIGN_TEMPLATE_NAME
                            && templateName !== constants.PET_BIRTHDAY_CAMPAIGN_TEMPLATE_NAME
                            && automationCampaign.data.templateName !== "Touch Campaign"
                            && automationCampaign.data.templateName !== constants.SMS_DRIP_CAMPAIGN &&
                            <div style={{ paddingBottom: '15px' }} id="divToolbarStart">
                                <div style={styles.automationActionsHeadings}>
                                    <div style={{ display: 'inline' }}>
                                        {getLocalizedStrings().label.AUTOMATION_DESIGNER.START_WITH}
                                    </div>

                                </div>
                                {startWith}
                            </div>
                        }

                        {automationCampaign.data.templateName !== constants.SMS_DRIP_CAMPAIGN && (
                        <div style={{ paddingBottom: '15px' }} id="divToolbarDecision">
                            <div style={styles.automationActionsHeadings}>
                                {getLocalizedStrings().label.AUTOMATION_DESIGNER.DECISIONS}
                            </div>
                            {decisions}
                        </div>)}

                        <div id="divToolbarTask">
                            <div style={styles.automationActionsHeadings}>
                                {getLocalizedStrings().label.AUTOMATION_DESIGNER.ACTIONS}
                            </div>
                            {actions}
                        </div>
                    </div>
                </div>
            }

            {
                (!isOpenSideMenu) &&
                <div>
                    <div className=''
                        style={{ overflow: 'hidden', height: contentHeight, width: '44px' }}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                        id="openedSidebar">
                        <div>
                            <div style={navigationMenuStyle}>
                                {/*<div style={{ display: 'inline', cursor: 'pointer' }} onClick={() => openSideMenu()} title="Click here to expand">
                                        <i className="fa fa-bars" aria-hidden="true"></i>
                                    </div>*/}
                            </div>
                            {startWith}
                        </div>

                        <div>
                            {decisions}
                        </div>

                        <div>
                            {actions}
                        </div>
                    </div>
                </div>
            }
        </div>
    );

}

export default AutomationSidebar;