import React, { useState, useEffect } from "react";
import ShowCircularProgress from './circularProgress';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { styles } from '../../../services/constants/styles';
import { useSelector } from "react-redux";


const PreviewNewsTemplate = () => {

    const app = useSelector((state) => state.app);
    const [isMounted, setIsMounted] = useState(false);
    const [emailBody, setEmailBody] = useState("");
    const [emailsubject, setEmailsubject] = useState("");

    let location = window.location.href;
    let currentLocation = location.split('previewNewsTemplate/');
    let urlId = currentLocation[1];

    useEffect(() => {
        let promise = Promise.resolve(HTTPClient.get(endPoints.NEWS.EDIT + '/' + urlId, null));
        promise.then((response) => {
            let _emailBody = response.records.news_details;
            let _emailSubject = response.records.title;
            setEmailBody(_emailBody);
            setEmailsubject(_emailSubject);
            setIsMounted(true);
        })
    }, [])

    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight - 10) / 2
    if (isMounted) {
        return (
            <div id="preview" style={{ backgroundColor: '#eeeeee', padding: '15px' }}>
                <table width="100%" border="0" cellSpacing="0" cellPadding="0" align="center">
                    <tr>
                        <td>
                            <div className="preview_title_box" style={{ height: '43px', marginBottom: '10px', backgroundColor: app.me.background, color: '#ffffff', borderRadius: '10px', boxShadow: '0 -2px 4px #909090', marginBottom: '10px', font: 'bold 18px Arial, Helvetica, sans-serif', paddingTop: '10px',paddingLeft:'15px' }}>{emailsubject}</div>
                            <div className="preview_content_box" style={{ background: '#ffffff', borderRadius: '10px', boxShadow: '0 -2px 4px #909090', padding: '20px 15px', font: '14px Arial, Helvetica, sans-serif' }}>
                                <div id="main">
                                    <div id="msgBody" className="divBody">
                                        <p style={{ margin: '0 0 10px', fontSize: '15px', fontWeight: '300', lineHeight: '1.6' }} dangerouslySetInnerHTML=
                                            {{ __html: (emailBody) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        )
    } else {
        return (
            <div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        )
    }

}

export default PreviewNewsTemplate;