import { actions } from '..//constants/actions';

const quotatiopurchaseOrderInitialState = {
    isRefresh: false,
}

const purchaseOrderReducer =(state = quotatiopurchaseOrderInitialState, action )=>{
    switch (action.type) {
         case actions.PURCHASE_ORDER_IS_REFRESH:
            return {
                ...state,
                isRefresh: action.payload,
            }
            
        default:
            return state;
    }

}
export default purchaseOrderReducer;