import React, { useState, useEffect, useRef } from 'react';
import * as HTTPClient from '../../../services/helper/httpClient';
import { connect } from 'react-redux';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { setTabs } from '../../../services/actions/tabActions';
import { setData } from '../../../services/actions/dataActions';
import Autocomplete from '@mui/material/Autocomplete';
import {Button, Chip, Checkbox, FormControl, FormControlLabel, TextField} from '@mui/material';
import { endPoints } from '../../../services/constants/endPoints';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { addNode } from '../../../services/actions/automationCampaignActions';
import { getAppDrawer, getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { retriveEscapeHtml, sortArrayObjectByProperty } from '../../../services/helper/utils';
import ShowCircularProgress from '../components/circularProgress';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { useDispatch, useSelector } from "react-redux";


function TemplateDialog ({object,setTemplateData}) {
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const catagoryinputRef = useRef(null);
    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);
    const tab = useSelector((state) => state.tab);
    const automationCampaign = useSelector((state) => state.automationCampaign);
    const header = useSelector((state) => state.header);
    const [categories, setCategories] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [templateOptions, setTemplateOptions] = useState([]);
    const [selected_category, setSelected_category] = useState('');
    const [selected_template, setSelected_template] = useState("");
    const [is_loading, setIs_loading] = useState(true);
    const [callFrom, setCallFrom] = useState(null);
    const [addLinkUrl, setAddLinkUrl] = useState("#");
    const [disabled, setDisabled] = useState(true);
    const [ab_test, setAb_test] = useState((header.selectedTabObject === constants.AB_CAMPAIGNS_OBJECT) ? true : false);
    const [changeLog, setchangeLog] = useState({});
    const [stateEditorType, setStateEditorType] = useState();
    const [selected_template_type, setSelected_template_type] = useState(null);
    const [dummyData,setDummyData] = useState(false);

    const constref = useRef(null);
    

    const handleClose = () => {
        dispatch(getAppDialog(false, constants.TEMPLATE_DIALOG, null, null, null, null));
    }
    const loadData = () => {
        let params = {
            fields: ['t_m_categories', 't_pk'],
            type: 'froala',
        };
        let data = appContainer.dialogProps.data;

        var promise = Promise.resolve(HTTPClient.get(endPoints.MARKETING_TEMPLATE.MARKETINGCATEGORYLISTBYTYPE, params
        ));
        promise.then((response) => {
            let _categories = [];
            let default_category = null;

            if (Array.isArray(response.records)) {
                response.records.forEach(function (record) {
                    if (data !== null && data.selectedCategory !== undefined) {
                        if (record.t_pk === data.selectedCategory) {
                            default_category = {
                                label: record.t_m_categories,
                                value: record.t_pk
                            };
                        }
                    } else if (record.t_m_categories === 'Marketing Template') {
                        default_category = {
                            label: record.t_m_categories,
                            value: record.t_pk
                        };
                    }
                    _categories.push({
                        label: record.t_m_categories,
                        value: record.t_pk
                    });
                });
            }

            if (_categories.length) {

                if (default_category !== null) {
                    let log = changeLog;
                    log.categories = _categories;
                    log.selected_category = default_category.value;
                    setchangeLog({...log});
                    setCategories(_categories);
                    setSelected_category(default_category.value);
                    loadTemplateByCategory(default_category.label);
                } else {
                    let _log = changeLog;
                    _log.categories = _categories;
                    _log.selected_category = _categories[0].value;
                    setchangeLog({..._log});
                    setCategories(_categories);
                    setSelected_category(_categories[0].value);
                    loadTemplateByCategory(_categories[0].label);
                }
            }
        });
    }

    const loadTemplateByCategory = (category) => {
        let params = {
            category: [category]
        }

        setDisabled(true);
        setIs_loading(true);
        dispatch(setAppDialogActions(getActions()));

        let _templates = [];
        let _templateOptions = [];
        let library_templates = { label: getLocalizedStrings().label.COMMON.TEMPLATE.LIBRARY_TEMPLATE, options: [] };
        let user_templates = { label: getLocalizedStrings().label.COMMON.TEMPLATE.USER_TEMPLATE, options: [] };
        let data = appContainer.dialogProps.data;

        var promise1 = Promise.resolve(HTTPClient.get(endPoints.MARKETING_TEMPLATE.LIBRARY_TEMPLATE, params));
        var promise2 = Promise.resolve(HTTPClient.get(endPoints.MARKETING_TEMPLATE.USER_TEMPLATE, params));
        Promise.all([promise1, promise2]).then(response => {
            if (Array.isArray(response[1].user_templates)) {
                response[1].user_templates.forEach(function (record) {
                    user_templates.options.push({
                        label: retriveEscapeHtml(record.t_name),
                        t_catagory: getLocalizedStrings().label.COMMON.TEMPLATE.USER_TEMPLATE,
                        value: record.t_pk,
                        type: 'user',
                        editorType: constants.EDITOR_BEE
                    });
                });
            }

            user_templates.options.sort(function (option1, option2) {
                let opt1 = option1.label.toLowerCase();
                let opt2 = option2.label.toLowerCase();
                if (opt1 < opt2)
                    return -1;
                if (opt1 > opt2)
                    return 1;
                return 0;
            });

            _templates.push(user_templates);
            let optionsArr = user_templates.options;
            if (Array.isArray(optionsArr)) {
                optionsArr.forEach(function (record) {
                    _templateOptions.push({
                        label: retriveEscapeHtml(record.label),
                        t_catagory: getLocalizedStrings().label.COMMON.TEMPLATE.USER_TEMPLATE,
                        value: record.value,
                        type: 'library',
                        editorType: constants.EDITOR_BEE
                    });
                });
            }

            if (Array.isArray(response[0].library_templates)) {
                response[0].library_templates.forEach(function (record) {
                    library_templates.options.push({
                        label: retriveEscapeHtml(record.t_name),
                        t_catagory: getLocalizedStrings().label.COMMON.TEMPLATE.LIBRARY_TEMPLATE,
                        value: record.t_pk,
                        type: 'library',
                        editorType: constants.EDITOR_BEE
                    });
                });
            }

            library_templates.options.sort(function (option1, option2) {
                let opt1 = option1.label.toLowerCase();
                let opt2 = option2.label.toLowerCase();
                if (opt1 < opt2)
                    return -1;
                if (opt1 > opt2)
                    return 1;
                return 0;
            });
            _templates.push(library_templates);
            optionsArr = library_templates.options;
            if (Array.isArray(optionsArr)) {
                optionsArr.forEach(function (record) {
                    _templateOptions.push({
                        label: retriveEscapeHtml(record.label),
                        t_catagory: getLocalizedStrings().label.COMMON.TEMPLATE.LIBRARY_TEMPLATE,
                        value: record.value,
                        type: 'library',
                        editorType: constants.EDITOR_BEE
                    });
                });
            }


            let _selected_template = '';
            if (data !== null && data.selectedTemplate !== undefined && data.selectedTemplate !== null) {
                _selected_template = data.selectedTemplate;
            } else if (user_templates.options.length > 0) {
                _selected_template = user_templates.options[0].value;
            } else if (library_templates.options.length > 0) {
                _selected_template = library_templates.options[0].value;
            }
            setTemplates(_templates);
            setTemplateOptions(_templateOptions);
            setSelected_template(_selected_template);
            setIs_loading(false);
            setDisabled(false);
            setStateEditorType(constants.EDITOR_BEE);
            let log = changeLog;
            log.selected_template = _selected_template;
            log.templateOptions = _templateOptions;
            log.stateEditorType = constants.EDITOR_BEE;
            setchangeLog({ ...log });
            dispatch(setAppDialogActions(getActions()));

        }).catch(e => {
            console.log(e)
        });
    }

    
    const handleCategoryChange = (event, option) => {
        setSelected_category(option.value);
        setTemplateOptions([]);
        setTemplates([]);
        setIs_loading(false);
        setDisabled(false);
        loadTemplateByCategory(option.label);
    }
    const handleTemplateChange = (event, option) => {
        let val;

        if (templates.length > 0) {
            let tempArr = templates.filter(v => v.label === option.t_catagory);
            val = tempArr[0].options.filter(v => v.value === option.value);
        }
        if (val.length > 0) {
            let log = changeLog;
            log.selected_template = val[0].value;
            log.selected_template_type = val[0].type;
            log.stateEditorType = val[0].editorType;
            setchangeLog({...log});
            setSelected_template(val[0].value);
            setSelected_template_type(val[0].type);
            setStateEditorType(val[0].editorType);


        }
    }

    const handleSelect = () => {
        let labelName = null;
        let log = changeLog;
        let templateValue = inputRef.current.getElementsByTagName("input")['template'].value;
        let templateId = log.templateOptions.filter(v => v.label === templateValue).length > 0 ? log.templateOptions.filter(v => v.label === templateValue)[0].value : 0;

        let catagoyValue = catagoryinputRef.current.getElementsByTagName("input")['catagory'].value;
        let catagoryId = log.categories.filter(v => v.label === catagoyValue).length > 0 ? log.categories.filter(v => v.label === catagoyValue)[0].value : 0;

        let obj = {
            category_id: catagoryId,
            template_id: templateId,
            template_type: log.selected_template_type,
            type: log.stateEditorType
        };
        
        if (appContainer.dialogProps.callFrom === constants.LIST_VIEW) {
            const collection = constref.current.getElementsByTagName("input")[0].value;
                if (collection != "false") {
                    if (obj.template_id !== null && obj.template_id !== undefined && obj.template_id !== "" && obj.template_id > 0) {
                        labelName = getLocalizedStrings().label.AB_CAMPAIGNS.AB_CAMPAIGN_CREATE_TAB_LABEL;
                        dispatch(setData(obj));
                        dispatch(getAppDialog(false, constants.TEMPLATE_DIALOG, null, null, null, null));

                        let data = {};
                        data.object = constants.AB_CAMPAIGNS_OBJECT;
                        let style = {};
                        style.width = '83%';
                        dispatch(getAppCustomDrawer(true, labelName, 'new', style, data, null, false));
                        setAddLinkUrl(null);
                    } else {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.AB_CAMPAIGNS.ERROR_MSG_1, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                } else {
                    if (obj.template_id !== null && obj.template_id !== undefined && obj.template_id !== "") {
                        labelName = getLocalizedStrings().label.BROADCAST_CAMPAIGNS.BROADCAST_CAMPAIGN_CREATE_TAB_LABEL;
                        dispatch(setData(obj));
                        dispatch(getAppDialog(false, constants.TEMPLATE_DIALOG, null, null, null, null));
                        let data = {};
                        data.object = constants.BROADCAST_CAMPAIGNS_OBJECT;
                        let style = {};
                        style.width = '83%';
                        dispatch(getAppCustomDrawer(true, labelName, 'new', style, data, null, false));
                        setAddLinkUrl(null);
                    } else {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.AB_CAMPAIGNS.ERROR_MSG_1, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                }
            } else if (callFrom === constants.AUTOMATION_DESIGNER_OBJECT) {
                let nodeObject = appContainer.dialogProps.data;
                if (isValidParam(nodeObject)) {
                    let params = {
                        template_id: obj.template_id
                    }
                    var promise = Promise.resolve(HTTPClient.get(endPoints.MARKETING_TEMPLATE.TEMPLATE_GET, params));

                    promise.then((response) => {
                        if (isValidParam(response)) {
                            let emailBody = response.mail_body;
                            let subject = response.subject;
                            let detailsArray = nodeObject.details[0];
                            let detailObject = null;
                            let templateObject = {
                                emailBody: emailBody,
                                subject: subject,
                                template_id: 0
                            }
                            if (nodeObject.hasOwnProperty('actionName')) {
                                let actionName = nodeObject.actionName;
                                if (actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                                    if (isValidParam(detailsArray)) {
                                        setTemplateData(templateObject);
                                    } else {
                                        detailsArray = {
                                            interval: 0,
                                            interval_mode: 'days',
                                            id: 0,
                                            t_status: 'New',
                                            view_as_web: 'No',
                                            template: templateObject
                                        }
                                        nodeObject.is_new = true;
                                        nodeObject.details.push(detailsArray);
                                        dispatch(getAppDrawer(true, getLocalizedStrings().label.COMMON.SEND_MAIL, actionName, nodeObject, constants.AUTOMATION_DESIGNER));
                                    }
                                } else if (constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
                                    if (isValidParam(detailsArray)) {
                                        setTemplateData(templateObject);
                                    } else {
                                        detailObject = {
                                            sequence: 1,
                                            dbp: 0,
                                            message_name: "Message",
                                            message_id: 0,
                                            interval_mode: "days",
                                            view_as_web: "No",
                                            startDate: "",
                                            hDate: "",
                                            template: templateObject
                                        }
                                        nodeObject.is_new = true;
                                        nodeObject.details.push(detailObject);
                                        dispatch(getAppDrawer(true, getLocalizedStrings().label.COMMON.SEND_MAIL, actionName, nodeObject, constants.AUTOMATION_DESIGNER));
                                    }
                                }
                                dispatch(getAppDialog(false, constants.TEMPLATE_DIALOG, null, null, null, null));
                            }
                        }
                    });
                }
            } else if (callFrom === constants.TOUCH_CAMPAIGNS_OBJECT) {
                let categoriesArray = categories;
                categoriesArray = categoriesArray.filter(function (el) {
                    return obj.category_id === el.value;
                })
                categoriesArray = categoriesArray[0];
                setTemplateData({ category_name: categoriesArray.label, template_id: obj.template_id });
                dispatch(getAppDialog(false, constants.TEMPLATE_DIALOG, null, null, null, null));
            }
            else {
                dispatch(setData(obj));
                appContainer.dialogProps.eventName(obj);
            }
    }

   
    

    const handleCheckBox = (event, isInputChecked) => {
        let _isInputChecked = isInputChecked;
        setAb_test(_isInputChecked);
        setDummyData(true);
        if (isInputChecked) {
            setAddLinkUrl('/ab-campaigns/new');
        } else {
            setAddLinkUrl('/broadcast-campaigns/new');
        }
       
        dispatch(setAppDialogActions(getActions()));
    }

    useEffect(() => {
        setCallFrom(appContainer.dialogProps.callFrom);
        if (callFrom === constants.LIST_VIEW) {
            setAddLinkUrl("/" + 'broadcast-campaigns' + '/new') ;
        }
        loadData();
        dispatch(setAppDialogActions(getActions()));
    }, []);


    const getActions = () => {
        let className = '';
        if (disabled) {
            className = "btn-disabled";
        }
        const actions = [
            <Button
                primary={true}
                onClick={()=>handleSelect()}
                style={{ ...styles.primaryButton, paddingTop: '6px' }}
                // disabled={disabled}
                className={className}
            >{getLocalizedStrings().label.COMMON.SELECT}</Button>,
            <Button
                primary={true}
                onClick={()=>handleClose()}
                style={{ ...styles.secondaryButton, marginRight: '18px' }}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,

        ];
        return actions;
    }

 
        let pageHeight = window.innerHeight - 550;
        let top = (pageHeight - 10) / 2;
        if (categories.length > 0) {
            return (
                <div>
                    <div className="row" style={{ paddingBottom: '10px' }}>
                        <div className="col-sm-2" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap',fontSize:'14px',paddingTop:'17px' }} title={getLocalizedStrings().label.BROADCAST_CAMPAIGNS.CATEGORY} >
                            {getLocalizedStrings().label.BROADCAST_CAMPAIGNS.CATEGORY}
                        </div>
                        <div className="col-sm-10">
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                {categories.length > 0 && <Autocomplete
                                    name="catagory"
                                    defaultValue={categories.find(v => v.value === selected_category)}
                                    options={categories}
                                    onChange={(event, newValue) =>handleCategoryChange(event, newValue) }
                                    style={{ height: '40px' }}
                                    disableClearable={true}
                                    renderInput={(params) => <TextField {...params} name='catagory' ref={catagoryinputRef} label={getLocalizedStrings().label.COMMON.SELECT + "..."} variant="outlined" margin="dense" className={"sf-fields-bg"} size="small"/>}
                                />
                                }
                            </FormControl>
                        </div>
                    </div>
                    {templateOptions.length > 0 && changeLog.selected_template !== "" &&
                        <div className="row" style={{ paddingBottom: '10px' }}>
                            <div className="col-sm-2" style={{fontSize:'14px',textOverflow: 'ellipsis', whiteSpace: 'nowrap',paddingTop:'17px'  }} title={getLocalizedStrings().label.BROADCAST_CAMPAIGNS.TEMPLATE} >
                                {getLocalizedStrings().label.BROADCAST_CAMPAIGNS.TEMPLATE}
                            </div>
                            <div className="col-sm-10">
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <Autocomplete
                                    name="template"
                                    value={templateOptions.filter(v => v.value === changeLog.selected_template).length > 0 ? templateOptions.filter(v => v.value === changeLog.selected_template)[0] : ""}
                                    options={templateOptions}
                                    groupBy={(option) => option.t_catagory}
                                    onChange={(event, newValue) => handleTemplateChange(event, newValue)}
                                    style={{ height: '40px' }}
                                    disableClearable={true}
                                    renderInput={(params) => <TextField {...params} name='template' ref={inputRef} label={getLocalizedStrings().label.COMMON.SELECT + "..."} variant="outlined" margin="dense" className={"sf-fields-bg"} size="small" />}
                                />
                                </FormControl>

                            </div>
                        </div>
                    }
                    {templateOptions.length === 0 &&
                        <div className="row" style={{ paddingBottom: '10px' }}>
                            <div className="col-sm-2" style={{fontSize:'14px', textOverflow: 'ellipsis', whiteSpace: 'nowrap',paddingTop:'17px' }} title={getLocalizedStrings().label.BROADCAST_CAMPAIGNS.TEMPLATE} >
                                {getLocalizedStrings().label.BROADCAST_CAMPAIGNS.TEMPLATE}
                            </div>
                            <div className="col-sm-10">
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <Autocomplete
                                    name="template"
                                    value={templateOptions.filter(v => v.value === changeLog.selected_template).length > 0 ? templateOptions.filter(v => v.value === changeLog.selected_template)[0] : ""}
                                    options={templateOptions}
                                    groupBy={(option) => option.t_catagory}
                                    onChange={(event, newValue) => handleTemplateChange(event, newValue)}
                                    style={{ height: '40px' }}
                                    disableClearable={true}
                                    renderInput={(params) => <TextField {...params} name='template' ref={inputRef} label={getLocalizedStrings().label.COMMON.SELECT + "..."} variant="outlined" margin="dense" className={"sf-fields-bg"} size="small"/>}
                                />
                                </FormControl>

                            </div>
                        </div>
                    }
                    {callFrom === constants.LIST_VIEW &&
                        <div className="row">
                            <div className="col-sm-2" style={{ textAlign: 'right' }}>
                                &nbsp;
                            </div>
                            <div className="col-sm-10">
                                <FormControlLabel
                                    control={<Checkbox
                                        color="default"
                                        ref={constref}
                                        checked={ab_test}
                                        value={ab_test}
                                        onChange={(event, isInputChecked) =>
                                            handleCheckBox(event, isInputChecked)
                                        }
                                    />}
                                    label={getLocalizedStrings().label.BROADCAST_CAMPAIGNS.PERFORM_AB_TESTING}
                                    labelposition="right"
                                    labelstyle={{ color: '#717171', padding: '0px', width: 'auto', fontWeight: 'normal', fontSize: '14px' }}
                                    style={{ height: 'auto' }}
                                    className='abcheck'
                                    disabled={disabled}
                                />

                            </div>
                        </div>
                    }
                </div>
            );
        } else {
            return (
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' , paddingBottom: '45px '}}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
            );
        }
    
}

export default (TemplateDialog);