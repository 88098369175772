import React, { useEffect,useState,useRef } from 'react';
import $ from 'jquery';
import { Step, Stepper, StepLabel, Icon } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import Button from '@mui/material/Button';
import { constants } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { getIntParam, getStringParam, isValidParam, getArrayParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { Menu, MenuItem } from '@mui/material';
import ShowCircularProgress from '../components/circularProgress';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import IframeComm from "react-iframe-comm";
import { refreshListView } from '../../../services/actions/listViewAcions';
import * as sfDialogs from '../components/sfDialogs';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getCoupons, hasAccessPermission, getDefaultBeeEditorData } from '../../../services/helper/common';
import SFAutoComplete from '../components/sfAutoComplete';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { getBeeEditorFontList } from "../../../services/helper/utils";
import {  storeTemplateListInRedux } from '../../../services/actions/sendMailActions';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { getCouponsAction } from '../../../services/actions/couponActions';
import Bee from "@mailupinc/bee-plugin";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';

const StyledStep = styled('div')(({ theme, status }) => ({
	zIndex: 1,
	color: '#fff',
	backgroundColor:'#717171',
	width: 22,
	height: 22,
	display: 'flex',
	borderRadius: '50%',
    fontSize:12,
	justifyContent: 'center',
	alignItems: 'center',
	...(status.active && {
	  color:'white',
	  backgroundColor: localStorage.getItem('background'),
	}),
  }));

function ColoredStep(props) {
	const { active, completed, className } = props;
	return (
	  <StyledStep status={{ completed, active }} className={className}>
		{props.icon}
	  </StyledStep>
	);
  }


const MosaicoTemplate=({id,type,object})=> {
    const dispatch = useDispatch();
    let currentRef = useRef('field-ref-status');
    const app = useSelector((state) => state.app);
	const appContainer = useSelector((state) => state.appContainer);
	const data = useSelector((state) => state.data);
	const sendMail = useSelector((state) => state.sendMail);
	const coupons = useSelector((state) => state.coupons);

	const [steps, setSteps] = useState(null);
    const [stepIndex, setstepIndex] = useState(0);
    const [dummyData, setdummyData] = useState(false);
    const [finished, setFinished] = useState(false);
	const [template, setTemplate] = useState({
        name: '',
        subject: '',
        email_message: '',
        id: id | 0,
        status: 'Active',
        t_library: 1,
        category: '',
        shareMarketingTemplate: false,
        metadata: '',
        content: '',
        endPoint: constants.endPoint,
        personalize_fields: [],
        user_name: app.me.name,
        user_email: app.me.email,
        object: constants.MARKETING_TEMPLATE_OBJECT,
        token: localStorage.getItem('token'),
        isCopy: false,
        language: app.me.language,
        coupons: [],
        scheduler_slug: app.appointment_scheduler_slug,
        hasPermission: true,
    },);
	const [personalize, setPersonalize] = useState(-1);
    const [bee, setBee] = useState(null);
	const [mounted, setMounted] = useState(false);
	const [couponLoader, setCouponLoader] = useState(true);
	const [statusOpen, setStatusOpen] = useState(false);
	const [personilizeOpen, setpersonilizeOpen] = useState(false);
	const [error, setError] = useState({
        name: '',
        subject: ''
    });
    const [interval, setInterval] = useState(0);
	const [intervalo, setIntervalo] = useState(false);
	const [statusArr, setstatusArr] = useState([
        (<MenuItem key={'Active'} value={'Active'} id={'Active'} primaryText={getLocalizedStrings().label.MARKETING_TEMPLATE.ACTIVE} style={styles.popoverMenuItem} />),
        (<MenuItem key={'Inactive'} value={'Inactive'} id={'Inactive'} primaryText={getLocalizedStrings().label.MARKETING_TEMPLATE.INACTIVE} style={styles.popoverMenuItem} />)
    ]);
	const [isOpenNoteTypeList, setisOpenNoteTypeList] = useState(false);
    const [anchorELNoteTypeList, setanchorELNoteTypeList] = useState(null);
    const [merge_fields, setMerge_fields] = useState(null);
    const [changeLog, setChangeLog] = useState({merge_fields: []});

    const [cursorposition, setCursorposition] = useState(0);
    const [buttonVisible, setButtonVisible] = useState('');
    const [anchorPersonilizeEl, setAnchorPersonilizeEl] = useState('');
    const [beeEditorAction, setbeeEditorAction] = useState('');
    const [token, setToken] = useState(null);
    const [update, setUpdate]= useState(false);
    const [testTemplate, settestTemplate]= useState('')


    const  beeTest = new Bee(); 

    const setIntervaloData = () => {
        setIntervalo(true);
    }

    setTimeout(setIntervaloData, 1000)

    useEffect(()=>{
        if (id == 0 &&  data.obj.template_id ) {
            loadMarketingTemplateData(id, data.obj.template_id);
            loadCoupons();
        }
        loadMarketingTemplateData(id);
        loadCoupons();
        let tempInterval = getIntParam(interval);
        if (tempInterval > 0) {
            clearInterval(tempInterval);
            setInterval(0);
        }
    },[data])

    const validateForm=()=> {
        let tempTemplate = template;
        let tempError = error;
        let isValid = true;
        if (tempTemplate.name.trim() === '') {
            isValid = false;
            tempError['name'] = getLocalizedStrings().message.MARKETING_TEMPLATE.NAME_BLANK;
        }
        if (tempTemplate.subject.trim() === '') {
            isValid = false;
            tempError['subject'] = getLocalizedStrings().message.MARKETING_TEMPLATE.SUBJECT_BLANK;
        }
        setError({...tempError});
        setUpdate(false);
        return isValid;
    }
     const loadMarketingTemplateData = (id, templateId) => {
        var response = null;
        let tempTemplate = template;
        let hasPermission = hasAccessPermission(object,constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_MANAGE_TEMPLATES);
        if (id !== undefined && id != null && id !== 0) {
            response = Promise.resolve(HTTPClient.get(endPoints.MARKETING_TEMPLATE.EDIT + '/' + id, null));
            response.then((response) => {
                const template_details = response.template_details[0];
                if (template_details.t_library === 0) {
                    tempTemplate['name'] = template_details.t_name;
                }
                else {
                }
                tempTemplate['subject'] = template_details.t_subject;
                tempTemplate['email_message'] = template_details.t_msg;
                tempTemplate['id'] = template_details.t_pk;
                tempTemplate['status'] = template_details.t_status;
                tempTemplate['t_library'] = template_details.t_library;
                tempTemplate['category'] = template_details.t_catagory;
                tempTemplate['metadata'] = template_details.t_metadata;
                tempTemplate['content'] = template_details.t_content;
                tempTemplate['editor_type'] = template_details.t_editor_type;
                let tempMergeField = response.available_mail_merge_fields;
                let temp = changeLog;
                temp.merge_fields =tempMergeField;
                setChangeLog({...temp});
                setMerge_fields(tempMergeField);
                let tempCursorposition = template_details.t_subject.length;
                setCursorposition(tempCursorposition);
                tempTemplate.shareMarketingTemplate = template_details.share === 1 ? true : false;
                setMounted(true);
                let tempStepIndex=0;
                if (type === "copy" || type === "new") {
                    setstepIndex(0);
                    tempStepIndex = 0;
                    tempTemplate.id = 0;
                    if (type === "copy") {
                        tempTemplate.name = getLocalizedStrings().label.MARKETING_TEMPLATE.COPY_OF + '_' + template.name;
                        tempTemplate.isCopy = true;
                        setButtonVisible('none');
                    }
                } else {
                    setstepIndex(1);
                    tempStepIndex = 1;
                }
                let personalizeArray = {};
                let i;
                let j;
                let mergeFields = response.available_mail_merge_fields;
                for (i in mergeFields) {
                    for (j in mergeFields[i]) {
                        personalizeArray[j] = mergeFields[i][j];
                    }
                }
                tempTemplate.personalize_fields = personalizeArray;
                tempTemplate.hasPermission = hasPermission;
                if (tempStepIndex === 1 && template.editor_type === constants.EDITOR_BEE) {
                    registerBeeEditor();
                }
                autoSaveInterval();
            });

        } else 
        {
            let id = isValidParam(templateId) ? templateId : data.obj.template_id;
            response = Promise.resolve(HTTPClient.get(endPoints.MARKETING_TEMPLATE.NEW + '/' + id, null));
            response.then((response) => {
                if (isValidParam(response)) {
                    const template_details = response.template_details[0];
                    tempTemplate['editor_type'] = constants.EDITOR_BEE;
                    tempTemplate['name'] = template_details.t_name;
                    tempTemplate['subject'] = template_details.t_subject;
                    tempTemplate['email_message'] = template_details.t_msg;
                    setCursorposition(0);
                    setMounted(true);
                    setButtonVisible('none');
                    tempTemplate['email_message'] = getLocalizedStrings().label.MARKETING_TEMPLATE.DEAR + ' ${j_contacts.first_name},<br/> ' + getLocalizedStrings().label.MARKETING_TEMPLATE.TYPE_HERE;
                    tempTemplate.shareMarketingTemplate = false;
                    let personalizeArray = {};
                    let i;
                    let j;
                    let mergeFields = response.available_mail_merge_fields;
                    for (i in mergeFields) {
                        for (j in mergeFields[i]) {
                            personalizeArray[j] = mergeFields[i][j];
                        }
                    }
                    let tenantId = getStringParam(app.me.projectId);
                    let mergeTags = getMergeTags();
                    let specialLinks = getSpecialLinks();
                    var beeConfig = {
                        uid: tenantId,
                        container: 'bee-container',
                        language: 'en-US',
                        mergeTags: mergeTags,
                        specialLinks: specialLinks,
                        editorFonts: {
                            showDefaultFonts: false,
                            customFonts: getBeeEditorFontList()
                        },
                        titleDefaultConfig: {
                            bold: true
                        },
                        titleDefaultStyles: {
                            h1: {
                                color: '#555555',
                                'font-size': '34px',
                                'font-family': "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                                'link-color': '#E01253',
                                'line-height': '120%',
                                'text-align': 'center',
                                'direction': 'ltr',
                                'letter-spacing': 0,
                            },
                            h2: {
                                color: '#555555',
                                'font-size': '23px',
                                'font-family': "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                                'link-color': '#E01253',
                                'line-height': '120%',
                                'text-align': 'center',
                                'direction': 'ltr',
                                'letter-spacing': 0,
                            },
                            h3: {
                                color: '#555555',
                                'font-size': '18px',
                                'font-family': "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                                'link-color': '#E01253',
                                'line-height': '120%',
                                'text-align': 'center',
                                'direction': 'ltr',
                                'letter-spacing': 0,
                            },
                        },
                        contentDefaults: {
                            text: {
                                html: "I'm a new Text block ready for your content.",
                                styles: {
                                    color: "#555555",
                                    linkColor: "#0068A5",
                                    fontSize: '14px',
                                    lineHeight: "120%",
                                    fontFamily: "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                                },
                            },
                        },
                        onSaveAsTemplate: (jsonFile) => {
                            var response = Promise.resolve(HTTPClient.post(endPoints.MARKETING_TEMPLATE.GET_BEE_MESSAGE_HTML, { page: JSON.parse(jsonFile) }));
                            response.then((response) => {
                                if (isValidParam(response) && isValidParam(response.data) && response.data !== '') {
                                    let html = response.data;
                                    settestTemplate(response.data);
                                    if (beeEditorAction === 'test') {
                                        testMail(html);
                                    } else {
                                        tempTemplate.email_message = html;
                                        tempTemplate.content = jsonFile;
                                        setTemplate(tempTemplate);
                                        setdummyData(true);
                                        saveTemplate();
                                    }
                                }
                            });
                        }
                    }
                    tempTemplate.personalize_fields = personalizeArray;
                    tempTemplate.hasPermission = hasPermission;
                    if (type === 'edit' && isValidParam(templateId)) {
                        if (stepIndex === 1 && isValidParam(bee)) {
                            let templateObj = getStringParam(template_details.t_content) !== "" ? JSON.parse(template_details.t_content) : getDefaultBeeEditorData();
                            beeTest.getToken(
                                constants.BEE_EDITOR_CLIENT_ID,
                                constants.BEE_EDITOR_CLIENT_SECRET_ID
                                )
                                .then((response)=>{
                                    setToken(response.access_token)
                                })
                                .then(() => {                 
                                    beeTest.start(beeConfig, templateObj)
                                      .then(instance =>{
                                          setBee(instance)
                                      })
                                  }).catch((error) => console.error('error during iniziatialization --> ', error))
            
                        }
                    } else {
                        tempTemplate['id'] = 0;
                    }
                    autoSaveInterval();
                }
            });
        }
        setTemplate(tempTemplate);
    }


    const loadCoupons = () => {
            let tempCoupons = {};
            let tempTemplate = template;
            if (!coupons?.couponMounted) {
                let promise = getCoupons()
                promise.then((response) => {
                    if (isValidParam(response.records)) {
                        dispatch(getCouponsAction(response.records));
                        let records = getArrayParam(response.records);
                        records.forEach(record => {
                            let discount = record.discount_type.toLowerCase() === 'percentage' ? record.discount + '%' : '$' + record.discount;
                            tempCoupons[' ' + record.name] = `${record.name} (${discount})`;
                        });
                        tempTemplate.coupons = tempCoupons;
                        setCouponLoader(false);
                        setTemplate(tempTemplate);
                        setdummyData(true);
                    }
                })
            } else {
                if (isValidParam(coupons.coupons)) {
                    let records = getArrayParam(coupons.coupons);
                    records.forEach(record => {
                        let discount = record.discount_type.toLowerCase() === 'percentage' ? record.discount + '%' : '$' + record.discount;
                        tempCoupons[' ' + record.name] = `${record.name} (${discount})`;
                    });
                }
                tempTemplate.coupons = tempCoupons;
                setCouponLoader(false);
                setTemplate(tempTemplate);
                setdummyData(true);
            }
    }
    
    const autoSaveInterval = () => {
        let tempInterval = 0;
        if (template.editor_type === 'New') {
            tempInterval = setInterval(autoSaveTemplate, 1000 * 60 * 2);
        }else{
            tempInterval = interval;
        }
        setInterval(tempInterval);
    }
    const autoSaveTemplate = () => {
        let tempTemplate = template;
            if (stepIndex === 0 && (tempTemplate.id > 0 || tempTemplate.isCopy)) {
                saveTemplate();
            }
    }
    const saveTemplate=()=> {
            let tempTemplate = template;
            if (tempTemplate.hasPermission) {
                let params = {
                    "id": tempTemplate.id,
                    "name": tempTemplate.name,
                    "subject": tempTemplate.subject,
                    "email_message": tempTemplate.email_message,
                    "status": tempTemplate.status,
                    "category": tempTemplate.category,
                    "share": tempTemplate.shareMarketingTemplate === true ? 1 : 0,
                    "editor_type": tempTemplate.editor_type,
                    "metadata": tempTemplate.metadata,
                    "content": tempTemplate.content,
                };
                var response = Promise.resolve(HTTPClient.post(endPoints.MARKETING_TEMPLATE.SAVE, params));
                response.then((response) => {
                    if (response.status === 0) {
                        if (tempTemplate.id === 0) {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            updateReduxTemplateList(tempTemplate,response.data);
                        } else {
                           
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            updateReduxTemplateList(tempTemplate,response.data);
                        }
                                                
                        tempTemplate.id = response.data.t_pk;
                        setTemplate({...tempTemplate});
                        setdummyData(true);
                        refreshListView(constants.MARKETING_TEMPLATE_OBJECT);
                    }
                    else if (response.status === -1 && response.error.message !== "") {
                        let msg = getLocalizedStrings().message.MARKETING_TEMPLATE.DUPLICATE_NAME;
                        msg = msg.replace('%%', template.name);
                        dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, { ...styles.snackBarStyleTop, left: '340px' }));
                        setMounted(true);
                    }
                });
            }
    }
    const updateReduxTemplateList =(template,data)=>{
          let templateList = getArrayParam(sendMail.templateList);
          if (templateList.length > 0) {
              let templateListObj = getObjectParam(templateList[0]);

              let _marketingTempList = getArrayParam(templateListObj['Marketing Template']);
             
              let templateObj = {
                  email_status: template.status,
                  execution_time: null,
                  id: data.t_pk,
                  name: template.name,
                  preview_image_url: "",
                  editor_type:template.editor_type
              };
              if (template.id === 0) {
                  if (template.status === constants.ACTIVE) {
                    _marketingTempList.push(templateObj);
                     
                  }

              } else {
                  let marketingTempObj = _marketingTempList.filter(f => f.id === template.id);
                  if (marketingTempObj.length > 0) {
                      if (template.status === constants.ACTIVE) {
                          marketingTempObj[0].name = templateObj.name;
                          marketingTempObj[0].email_status = templateObj.email_status;
                      } else {
                        _marketingTempList = _marketingTempList.filter(f => f.id !== template.id);
                      }
                  } else if (template.status === constants.ACTIVE) {
                    _marketingTempList.push(templateObj);
                  }
              }
              dispatch(sortArrayObjectByProperty(_marketingTempList, 'name'));
              templateListObj['Marketing Template'] = _marketingTempList;
              templateList = new Array(templateListObj);

              dispatch(storeTemplateListInRedux(templateList))
        }
    }
    const handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let tempCursorposition = event.target.selectionStart;
        setCursorposition(tempCursorposition);
        let tempTemplate = template;
        tempTemplate[name] = value;
        setTemplate(tempTemplate);
        setdummyData(true);
        let tempError = error;
        if (value.trim() !== '') {
            tempError[name] = "";
        }
        setError({...tempError});
        setUpdate(null);
    };
    const checkBoxShare = (event, isInputChecked) => {

        let tempTemplate = template;
        tempTemplate['shareMarketingTemplate'] = isInputChecked;
        setTemplate(tempTemplate);
    }

    const handleStatusChange = (fieldName, event, value) => {
        let tempTemplate = template;
        tempTemplate[fieldName] = value;
        setTemplate(tempTemplate);
        setdummyData(true);
        setStatusOpen(false);
    };

    const handleStatusOpen = (event) => {
        setStatusOpen(true);
    }

    const handleStatusClose = () => {
        setStatusOpen(false);
    }

    const handleSelectPersonilizeChange = (fieldName, value, event) => {
        let tempTemplate = template;
        tempTemplate[fieldName] = value;
        setpersonilizeOpen(false);
        let front = (tempTemplate['subject']).substring(0, cursorposition);
        let back = (tempTemplate['subject']).substring(cursorposition, tempTemplate['subject'].length);
        tempTemplate['subject'] = front + ' \${' + value + '}' + back;
        let tempCursorposition = tempTemplate['subject'].length;
        setCursorposition(tempCursorposition);
        setisOpenNoteTypeList(false);
    };

    const handlePersonilizeClose = () => {
        setpersonilizeOpen(false);
    }

    const handlePersonilizeOpen = (event) => {
        setpersonilizeOpen(true);
        setAnchorPersonilizeEl(event.currentTarget);
    }

    const renderPersonalizeField = () => {
        let personalizeObject = template["personalize_fields"];
        return Object.keys(personalizeObject).map((itemKey, index) => {

            return <MenuItem
                key={itemKey + index}
                value={itemKey}
                style={styles.popoverMenuItem}
                fullWidth={true}
                labelstyle={{ color: '#479ccf' }}
                underlinestyle={{ display: 'none' }}
                autowidth={"true"}
                onClick={(event)=>handleSelectPersonilizeChange('personalize', itemKey,event)}>
                {personalizeObject[itemKey]}
            </MenuItem>;
        });
    }

    const handleChangeTemplate = () => {
        let tempData = {};
        if (isValidParam(data.obj)) {
            tempData = { selectedTemplate: data.obj.template_id, selectedCategory: data.obj.category_id, template_type: data.obj.template_type, callFrom: 'template change' };
        }
        dispatch(getAppDialog(true, constants.EDITOR_TEMPLATE_DIALOG, getLocalizedStrings().label.CAMPAIGNS.TEMPLATE_DIALOG_TITLE, null, tempData, constants.MARKETING_TEMPLATE_OBJECT));
    }
   
    const openNoteTypeList = (event) => {
        setisOpenNoteTypeList(true);
        setanchorELNoteTypeList(event.currentTarget);
    }
    const getStepContent=(stepIndex)=> {

        let height = window.innerHeight - 150;
        let top = (height-10) / 2
        switch (stepIndex) {
            case 0:
                let personalizeFieldList = renderPersonalizeField();
                return (
                    <div>
                        <div className="row">
                            <div className="col-sm-8">
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        label={getLocalizedStrings().label.MARKETING_TEMPLATE.NAME_REQUIRED}
                                        fullWidth={true}
                                        name="name"
                                        autoFocus
                                        value={template.name}
                                        onChange={(event)=>handleChange(event)}
                                        error={error.name !== ''}
                                        helperText={error.name}
                                        autoComplete="new-password"
                                        margin='dense'
                                        size='small'
                                        fontSize='12px'
                                        className={"sf-fields-bg"}
                                    /></FormControl>
                            </div>

                            <div className="col-sm-4 col-two">
                                <SFAutoComplete
                                    key={'sf-auto-complete-status'}
                                    id={'status'}
                                    name={'status'}
                                    floatingLabelText={getLocalizedStrings().label.SALES_TEMPLATE.STATUS}
                                    menuItems={statusArr}
                                    disabled={false}
                                    onChange={(event,value)=>handleStatusChange('status',event,value)}
                                    value={template.status}
                                    underlineFocusStyle={styles.textField.underlineFocus}
                                    floatingLabelStyle={{ ...styles.textField.floatingLabel, top: '10px', left: '7px' }}
                                    inputStyle={styles.textField.input}
                                    menuItemStyle={styles.popoverMenuItem}
                                    style={styles.sfFormFieldContainer}
                                    ref={currentRef}
                                    isFullHeight={false}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8">
                                <FormControl style={{ width: '152%' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        label={getLocalizedStrings().label.MARKETING_TEMPLATE.SUBJECT_REQUIRED}
                                        fullWidth={true} name="subject"
                                        value={template.subject}
                                        onChange={(event)=>handleChange(event)}
                                        error={error.subject !== ''}
                                        helperText={error.subject}
                                        autoComplete="new-password"
                                        margin='dense'
                                        size='small'
                                        className={"sf-fields-bg"}
                                    />
                                    <div title={getLocalizedStrings().label.CAMPAIGNS.PERSONALIZE} style={{ width: '10%', marginTop: '-3%', zIndex: 1, marginLeft: '96%' }}>
                                        <div style={{ marginTop: '-4%' }} onClick={(event)=>openNoteTypeList(event)}>
                                            <Icon style={{ fontSize: '17px', color: 'black', cursor: 'pointer', marginTop: "1px", marginLeft: '-2px' }}>settings</Icon>
                                            <Icon style={{ fontSize: '17px', color: 'black', cursor: 'pointer', marginTop: "1px" }}>keyboard_arrow_down</Icon>
                                        </div>
                                        <Menu
                                            name="personalizeFieldList"
                                            key={'sf-auto-complete-noteTypeList'}
                                            id={"personalizeFieldList"}
                                            style={{ height: '100%' }}
                                            anchorEl={anchorELNoteTypeList}
                                            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                                            open={isOpenNoteTypeList}
                                            onClose={() => { setisOpenNoteTypeList(false) }}
                                        >
                                            {personalizeFieldList}
                                        </Menu>


                                    </div>

                                </FormControl>


                            </div>
                        </div>
                    </div>

                );
            case 1:
                let iframeSrc = constants.nodeServerURL + "/index.html"; //"./mosaico/index.html";
                if (template.id > 0 || template.isCopy) {
                    iframeSrc = constants.nodeServerURL + "/editor.html";
                }
                let hasPermission = template.hasPermission;
                return (
                    <div>
                        <div className="row" style={{}}>
                            <div className="col-sm-12" style={{ paddingBottom: '20px' }}>
                                <div style={{ float: 'right' }}>
                                    {template.editor_type === constants.EDITOR_BEE &&
                                        <span>
                                            <Button

                                                style={styles.primaryButton}
                                                onClick={()=>handleAction('save')}
                                                disabled={hasPermission ? false : true}
                                            >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                                            {(template.id > 0) && <Button

                                                style={styles.secondaryButton}
                                                onClick={()=>testMail()}
                                                disabled={hasPermission ? false : true}
                                            >{getLocalizedStrings().label.CAMPAIGNS.TEST}</Button>}
                                        </span>
                                    }
                                    {(template.id > 0) &&
                                        <Button

                                            style={styles.secondaryButton}
                                            onClick={()=>handleCopy()}
                                            disabled={hasPermission ? false : true}
                                        >{getLocalizedStrings().label.COMMON.COPY}</Button>
                                    }
                                    {(template.id > 0) &&
                                        <Button
                                            style={styles.secondaryButton}
                                            onClick={()=>handlePreview()}
                                        >{getLocalizedStrings().label.COMMON.PREVIEW}</Button>
                                    }

                                    <Button
                                        style={styles.secondaryButton}
                                        onClick={()=>handleClose()}
                                    >{getLocalizedStrings().label.COMMON.CANCEL}</Button>

                                    <Button
                                        style={styles.secondaryButton1}
                                        onClick={()=>handleChangeTemplate()}
                                    >{getLocalizedStrings().label.CAMPAIGNS.CHANGE_TEMPLATE}</Button>

                                </div>
                            </div>
                        </div>
                        {template.editor_type === constants.EDITOR_BEE && <div id='bee-container' style={{ padding: '0px 10px', height: (window.innerHeight - 220) + 'px' }}></div>}
                        {template.editor_type !== constants.EDITOR_BEE && intervalo &&
                            <MosaicoFrame postMessageData={template} iframeSrc={iframeSrc} resetTemplateData={resetTemplateData} />
                        }
                        {!intervalo &&

                        <div style={{ width: '100%', height: height }} >
                            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '50%' }}>
                                <div style={{ ...styles.assetLoaderContainer, height: 55, width: 55, padding: 6 }}>
                                    <ShowCircularProgress size={35} style={{ marginTop: '4', marginLeft: '4' }} />
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                );

            default:
                return 'Soffront Template Module';
        }
    }

    const handleCopy = () => {
        let tempTemplate = template;
        let tempError = error;
        tempTemplate.id = 0;
        tempTemplate.name = getLocalizedStrings().label.MARKETING_TEMPLATE.COPY_OF + '_' + template.name;
        tempError.name='';
        tempError.subject = '';
        tempTemplate.isCopy = true;
        setTemplate(tempTemplate);
        setdummyData(true);
        setError(tempError);
        setstepIndex(0);
    }

    const handlePreview = () => {
        if (getObjectParam(template).editor_type === constants.EDITOR_BEE) {
            if (isValidParam(bee)) {
                bee.preview();
            }
        } else {
            window.open('/#previewemailtemplate/' + template.id);
        }
    }

    const resetTemplateData = (templateInfo) => {
        let tempTemplate = template;
        if (tempTemplate.id === 0) {
            tempTemplate.id = templateInfo.id;
            setButtonVisible('none');
        }
        tempTemplate['email_message'] = templateInfo.email_message;
        tempTemplate['metadata'] = templateInfo.metadata;
        tempTemplate['content'] = templateInfo.content;
        tempTemplate['t_library'] = templateInfo.t_library;
        tempTemplate['category'] = templateInfo.category;
        setTemplate(tempTemplate);
        setdummyData(true);
    }

    const handleNext = () => {
        if (!validateForm()) {
            return false;
        }
        let tempStepIndex = stepIndex;
        if (tempStepIndex === 0) {
            let params = {
                id: template.id,
                name: template.name
            }
            let promise = Promise.resolve(HTTPClient.getFullResponse(endPoints.MARKETING_TEMPLATE.CHECK_DUPLICATE_TEMPLATE, params));
            if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.status === -1) {
                        let msg = getLocalizedStrings().message.MARKETING_TEMPLATE.DUPLICATE_TEMPLATE_NAME;
                        dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    } else {
                        setstepIndex(stepIndex+1);
                        setFinished(stepIndex >= 2);
                        registerBeeEditor();
                    }
                }
            });
        }
        }
    };

    const getMergeTags = () => {
        let mergeTags = [];
        let mergeFields = changeLog.merge_fields;
            mergeTags = [
                 { name: 'Company Logo', value:"${company.logo}"},
                { name: 'Company Name', value:"${company.business_name}"},
                { name: 'Company Website', value:"${company.website}"},
                { name: 'Company Address', value:"${company.business_address}"},
                { name: 'Company Phone', value:"${company.business_phone}"},
                { name: 'Company Email', value:"${company.business_email}"},
                // { name: 'Company Country', value:"${company.country}"},
                { name: 'Opt-in Link', value: '[Start Opt-in Link] Enter your display text [End Opt-in Link]' },
                { name: 'Opt-out Link', value: '[Start Opt-out Link] Enter your display text [End Opt-out Link]' },
                { name: 'Signature', value: '${Email_Signature}' },
            ];
            let tempTags = mergeFields.map(mergeField => {
                let key = Object.keys(mergeField)[0];
                return { name: mergeField[key], value: '$' + `{${key}}` };
            });
            mergeTags= [...mergeTags, ...tempTags]
          //  mergeTags.push(...tempTags);
        return mergeTags;
    }

    const getSpecialLinks = () => {
        let specialLinks = [];
            let slug = app.appointment_scheduler_slug;
            if (isValidParam(slug) && slug !== '') {
                let link = { type: 'Appointment', label: 'Book an appointment', link: constants.APPOINTMENT_SCHEDULER_URL + slug }
                specialLinks.push(link);
            }
        return specialLinks;
    }

    const getCouponsTags = () => {
        let couponsTags = [];
        let couponsFields = getArrayParam(template.coupons);
        couponsTags = couponsFields.map(couponField => {
            return { name: couponField.text, value: `{coupon_code.${couponField.token}}` };
        });
        return couponsTags;
    }

    const handleAction = (type) => {
            if (getObjectParam(template).editor_type === constants.EDITOR_BEE) {
                if (isValidParam(bee)) {
                    setbeeEditorAction(type);
                    bee.saveAsTemplate();
                }
            }
    }

     const registerBeeEditor = async() => {
        let tempTemplate = getObjectParam(template);
            if (tempTemplate.editor_type === constants.EDITOR_BEE) {
                let tenantId = getStringParam(app.me.projectId);
                let mergeTags = getMergeTags();
                let specialLinks = getSpecialLinks();
                var beeConfig = {
                    uid: tenantId,
                    container: 'bee-container',
                    language: 'en-US',
                    mergeTags: mergeTags,
                    specialLinks: specialLinks,
                    editorFonts: {
                        showDefaultFonts: false,
                        customFonts: getBeeEditorFontList()
                    },
                    titleDefaultConfig: {
                        bold: true
                    },
                    titleDefaultStyles: {
                        h1: {
                            color: '#555555',
                            'font-size': '34px',
                            'font-family': "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            'link-color': '#E01253',
                            'line-height': '120%',
                            'text-align': 'center',
                            'direction': 'ltr',
                            'letter-spacing': 0,
                        },
                        h2: {
                            color: '#555555',
                            'font-size': '23px',
                            'font-family': "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            'link-color': '#E01253',
                            'line-height': '120%',
                            'text-align': 'center',
                            'direction': 'ltr',
                            'letter-spacing': 0,
                        },
                        h3: {
                            color: '#555555',
                            'font-size': '18px',
                            'font-family': "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            'link-color': '#E01253',
                            'line-height': '120%',
                            'text-align': 'center',
                            'direction': 'ltr',
                            'letter-spacing': 0,
                        },
                    },
                    contentDefaults: {
                        text: {
                            html: "I'm a new Text block ready for your content.",
                            styles: {
                                color: "#555555",
                                linkColor: "#0068A5",
                                fontSize: '14px',
                                lineHeight: "120%",
                                fontFamily: "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            },
                        },
                    },
                    onSaveAsTemplate: (jsonFile) => {
                        var response = Promise.resolve(HTTPClient.post(endPoints.MARKETING_TEMPLATE.GET_BEE_MESSAGE_HTML, { page: JSON.parse(jsonFile) }));
                        response.then((response) => {
                            if (isValidParam(response) && isValidParam(response.data) && response.data !== '') {
                                let html = response.data;
                                settestTemplate(response.data);
                                if (beeEditorAction === 'test') {
                                    testMail(html);
                                } else {
                                    tempTemplate.email_message = html;
                                    tempTemplate.content = jsonFile;
                                    setTemplate(tempTemplate);
                                    setdummyData(true);
                                    saveTemplate();
                                }
                            }
                        });
                    }
                }
                var endpoint = constants.BEE_EDITOR_AUTH_API;
                let templateObj = getStringParam(template.content) !== "" ? JSON.parse(template.content) : getDefaultBeeEditorData();

                await beeTest.getToken(
                    constants.BEE_EDITOR_CLIENT_ID,
                    constants.BEE_EDITOR_CLIENT_SECRET_ID
                    )
                    .then((response)=>{
                        setToken(response.access_token)
                    })
                    .then(() => {                 
                        beeTest.start(beeConfig, templateObj)
                          .then(instance =>{
                              setBee(instance)
                          })
                      }).catch((error) => console.error('error during iniziatialization --> ', error))
            }
    }

    const testMail = () => {
        let params = {};
        params.email = '';
        params.frommail = app.me.email;
        params.fromname = app.me.name;
        params.subject = template.subject;
        params.templateBody = template.email_message;
        params.testMailType = 'marketing';
        dispatch(getAppDialog(true, constants.TEST_MAIL_DIALOG, null, null, params, null));
    }

    const handlePrev = () => {
        let tempStepIndex = stepIndex;
        if (tempStepIndex > 0) {
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.COMMON.STEPPER_BACK_MESSAGE, (event) => sfDialogConfirnHandlePrev(event, stepIndex), null);

        }
    }

    const sfDialogConfirnHandlePrev = (event, stepIndex) => {
        setstepIndex(stepIndex - 1);
    }

    const handleClose = () => {
        if (template.id > 0) {
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.COMMON.CENCEL_MOSAICO_MESSAGE, closeDrawer , null);
        } else {
            dispatch(getAppCustomDrawer(false, null, null, null, null, null, null));
        }
    }
    const closeDrawer=()=>{
        dispatch(getAppCustomDrawer(false, null, null, null, null, null, null));
    }

    const removeBeeEditorIfExist = () => {
            if (getObjectParam(template).editor_type === constants.EDITOR_BEE) {
                var beeDiv = document.getElementById("bee-container");
                if (beeDiv) {
                    while (beeDiv.firstChild) {
                        beeDiv.removeChild(beeDiv.firstChild);
                    }
                }
            }
    }
        let wrapperStyle = {};
        let themeColor = app.me.background;
        let height = window.innerHeight - 150;
        let top = (height-10) / 2
        if (stepIndex === 0) {
            wrapperStyle = { width: '75%', margin: 'auto' };
            removeBeeEditorIfExist();
        }
        if (!mounted || couponLoader) {
            return (
                <div style={{ width: '100%', height: height }} >
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '50%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 55, width: 55, padding: 6 }}>
                            <ShowCircularProgress size={35} style={{ marginTop: '4', marginLeft: '4' }} />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div>
                <div style={{ width: '30%', margin: 'auto',paddingTop:'20px' }}>
                    <   >
                        <Stepper nonLinear activeStep={stepIndex} style={{ backgroundColor: '#f5f5f5' }}>
                            <Step>
                                <StepLabel StepIconComponent={ColoredStep} style={{ fontWeight: 'bold', color: stepIndex === 0 ? '#717171' : themeColor, cursor: 'pointer' }} onClick={()=>handlePrev()}>{getLocalizedStrings().label.MARKETING_TEMPLATE.TEMPLATE_INFO}</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel StepIconComponent={ColoredStep} style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={()=>handleNext()}>{getLocalizedStrings().label.MARKETING_TEMPLATE.DESIGN}</StepLabel>
                            </Step>
                        </Stepper>
                    </ >
                </div>
                <div style={wrapperStyle}>
                    <div style={{ paddingTop: '20px', paddingBottom: 0, marginTop: 0 }}>{getStepContent(stepIndex)}</div>
                    {stepIndex === 0 &&
                        <div style={{ padding: 20, float: 'right' }}>
                            <Button
                                id="next"

                                primary={true}
                                onClick={()=>handleNext()}
                                style={styles.primaryButton}
                            >{getLocalizedStrings().label.FILTER.NEXT_BUTTON_TEXT}</Button>
                            <Button
                                id="cancel"

                                primary={false}
                                onClick={() => {
                                    handleClose();
                                }}
                                style={styles.secondaryButton}
                            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                        </div>
                    }
                </div>
            </div >
        );
}

const MosaicoFrame = ({ postMessageData, iframeSrc, resetTemplateData }) => {
    const attributes = {
        id: "editorFrame",
        src: iframeSrc + '?background=' + localStorage.getItem('background'),
        width: "100%",
        height: (window.innerHeight - 220) + 'px',
        frameBorder: 0,
    };

    const onReceiveMessage = (e) => {
        let receiveData = e.data;
        if (receiveData.callbackAction === 'saved_success') {
            refreshListView(constants.MARKETING_TEMPLATE_OBJECT);
            resetTemplateData(receiveData.templateInfo);
        } else if (receiveData.callbackAction === 'upload_file') {
            const formData = new FormData();
            formData.append('file', receiveData.file);
            let url = endPoints.DOCUMENT_LIBRARY.UPLOAD;

            return HTTPClient.postFormData(url, formData);
        }
    };

    const onReady = () => {
    };

    return (
        <IframeComm
            attributes={attributes}
            postMessageData={postMessageData}
            handleReady={onReady}
            handleReceiveMessage={onReceiveMessage}
            targetOrigin={constants.nodeServerURL}
        />
    );
};

export { MosaicoFrame };
export default MosaicoTemplate;