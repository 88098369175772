import { endPoints } from '..//constants/endPoints';
import {getToken, forgotPassword} from '..//helper/httpClient';


function submitLoginCredential(email, password) {
    let url = null;
    var promise = null;
    try {
        url = endPoints.LOGIN.SUBMIT_LOGIN_CRED;
        promise = getToken(url, email, password);
    } catch (e) { console.error("Error in 'loginActions.js -> submitLoginCredential()':" + e); }
    return promise;
}

function submitForgotPassword(email) {
    let url = null;
    var promise = null;
    try {
        url = endPoints.LOGIN.FORGOT_PASSWORD;
        promise = forgotPassword(url, email);
    } catch (e) { console.error("Error in 'loginActions.js -> submitLoginCredential()':" + e); }
    return promise;
}

export {
  submitLoginCredential,
  submitForgotPassword
}
