import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';

function getWrkflowList(){
    let url = endPoints.WORKFLOWS.LIST_GET + '/accounts';
    return HTTPClient.get(url, null);
}

function getWrkflowStatusList(params){
    let url = endPoints.WORKFLOWS.LIST_STAGES_GET;
    return HTTPClient.get(url, params);
}

function convertToAccount(params){
    let url = endPoints.CONTACTS.CONVERT_TO_ACCOUNT;
    return HTTPClient.post(url, params);
}

export {
    getWrkflowList,
    getWrkflowStatusList,
    convertToAccount
}