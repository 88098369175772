import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { addTab, TYPE_HOME, TYPE_LIST_VIEW } from '../../../services/helper/sfTabManager';
import { isValidParam, getStringParam, getArrayParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { TABLEID_OBJECT_MAP } from '../../../services/constants/constants';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import map from 'lodash/map';
import { getImageNameByObject, getObjectLabelByObject, setSelectedModuleInfo, getObjectIdListByModule } from '../../../services/helper/common';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants } from '../../../services/constants/constants';
import { setSearchCriteria } from '../../../services/actions/appActions';
import {useDispatch, useSelector} from 'react-redux';

const RecentSearchText = ({objects,handleCloseDropdown}) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const filters = useSelector((state)=> state.filters);
    const [mounted,setMounted] = useState(false);
    const [recentSearchesText,setRecentSearchesText] = useState(null);

    useEffect(() => {
        if (getArrayParam(objects).length > 0) {
            getRecentSearchText(objects);
        }
        const franchiseAppLoginDetails = JSON.parse(localStorage.getItem('franchiseAppLoginDetails'))
        let shouldReturnToInitialPosition = JSON.parse(localStorage.getItem(`shouldReturnToInitialPos_${app.me.projectId}_${app.me.id}`));
		let callFrom;
		if(franchiseAppLoginDetails && franchiseAppLoginDetails.length >= 2) {
			const index = franchiseAppLoginDetails.length - 1;
			const previousUnitLoginDetails = franchiseAppLoginDetails[index]
			callFrom = previousUnitLoginDetails.callFrom
		} else if(franchiseAppLoginDetails && franchiseAppLoginDetails.length === 1) {
			const previousUnitLoginDetails = franchiseAppLoginDetails[0]
			callFrom = previousUnitLoginDetails.callFrom
		}

        if(callFrom === constants.DETAIL|| callFrom === constants.LIST){
            if(shouldReturnToInitialPosition){
                localStorage.removeItem('selectedSearchItemObject_'+app.me.projectId+"_"+app.me.id);
                localStorage.removeItem('searchCriteria_'+app.me.projectId+"_"+app.me.id);
            }
        }
        
    }, [recentSearchesText]);


   

   const getRecentSearchText = (objects) => {
        try {
            if(app.me.is_enableTenantSearch){
                objects = objects.filter(f => { return f.value !== 'Unit’s Accounts'});
            }
            let objList = getObjectIdListByModule(objects)
            let reduxSearchText = filters.recentSearchesText[app.selectedModuleName];
            if ((!isValidParam(reduxSearchText) || reduxSearchText.length === 0)) {
                let url = endPoints.HOME.GET_RECENT_SEARCH_TEXT;
                let params = {};
                params.table_ids = objList;

                let promise = Promise.resolve(HTTPClient.get(url, params));
                promise.then((response) => {
                    if (isValidParam(response)) {
                        var recentSearchesTextTemp = filters.recentSearchesText;
                        recentSearchesTextTemp[app.selectedModuleName] = response;
                        setRecentSearchesText(recentSearchesTextTemp);
                        setMounted(true);
                        generateRecentRecords();
                    }
                });
            } else {
                setMounted(true);
                setRecentSearchesText(reduxSearchText);
                generateRecentRecords();
            }
        } catch (error) {
            console.error("Error in 'recentSearchText.js -> getRecentSearchText()':" + error);
        }
    }

   const generateRecentRecords = () => {
        let recentSearches = recentSearchesText;
        let recordItems = null;
        if (isValidParam(recentSearches)) {
            if (recentSearches.length > 0) {
                recordItems = map(recentSearches, (recentItem, i) => {
                    let object = TABLEID_OBJECT_MAP[recentItem.objectId];
                    let searchText = getStringParam(recentItem.searchText);
                    let url = '/search-results/search';

                    let imagename = getImageNameByObject(object);
                    imagename = getStringParam(imagename);
                    imagename = imagename === '' ? getImageNameByObject(constants.SEARCH_RESULTS) : imagename;

                    let searchCriteria = {};
                    searchCriteria.searchText = searchText;
                    searchCriteria.object = object;
                    return (
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', lineHeight: '30px' }} key={'recent' + recentItem.id}>
                            <Link
                                to={url}
                                style={{ cursor: 'pointer' }}
                                onClick={() => openTab(searchCriteria, url)}>
                                <span style={{ color: '#ccc', fontSize: '14px', paddingLeft: '1px', paddingRight: '7px' }}><i className={imagename} aria-hidden="true" style={{ width: '12px' }}></i></span>
                                <span title={recentItem.searchText} style={{ fontSize: '14px', color: '#717171' }}>
                                    {recentItem.searchText}
                                </span>
                            </Link>
                        </div>
                    );
                });
                return recordItems;
            }
            else {
                return <div style={{ color: 'rgb(113, 113, 113)', lineHeight: '30px' }}>No recent searches</div>
            }
        }
    }

   const openTab = (searchCriteria, url) => {
        let object = null;
        let searchText = null;
        let selectedObjectLabel = null;
        let isHomeTabRequired = null;
        if (isValidParam(searchCriteria)) {
            object = getStringParam(searchCriteria.object);
            let selectedObjectSet,selectedSearchObject;
            let _selectedModuleName = app.selectedModuleName;
            if(app.selectedSearchObject === null){
                // let selectedSearchItemObject = localStorage.getItem('selectedSearchItemObject');
                let selectedSearchItemObject = localStorage.getItem('selectedSearchItemObject_'+app.me.projectId+"_"+app.me.id);

                let objects = new Set();
                objects.add(selectedSearchItemObject);
                selectedSearchObject = getObjectParam(app.selectedSearchObject);
                selectedSearchObject[_selectedModuleName] = objects;
                app.selectedSearchObject = selectedSearchObject;
                selectedObjectSet = objects;
            }else{
                selectedObjectSet = app.selectedSearchObject[_selectedModuleName];
                // localStorage.setItem("selectedSearchItemObject", [...selectedObjectSet][0]);
                localStorage.setItem("selectedSearchItemObject_"+app.me.projectId+"_"+app.me.id, [...selectedObjectSet][0]);

            }
            let selectedObject = [...selectedObjectSet][0];
            let isUnitsListView = false;
            if (selectedObject === 'Unit’s Accounts') {
                isUnitsListView = true;
                object = constants.ACCOUNTS_OBJECT;

            }
            selectedObjectLabel = getStringParam(getObjectLabelByObject(object));
            searchText = getStringParam(searchCriteria.searchText);
            isHomeTabRequired = object === constants.SOLUTIONS_OBJECT ? false : true;
            saveRecentSearchText(searchCriteria);
            dispatch(setSearchCriteria(searchCriteria));

            if (searchText === "*") {
                searchText = getLocalizedStrings().label.COMMON.RESULTS_FOR + " " + selectedObjectLabel + "=*";
            } else {
                searchText = getLocalizedStrings().label.COMMON.RESULTS_FOR + " " + selectedObjectLabel + "=" + searchText;
            }
            var iconName = getImageNameByObject(object);
            iconName = getStringParam(iconName);
            iconName = iconName === '' ? getImageNameByObject(constants.SEARCH_RESULTS) : iconName;

            let tab = {
                item: object,
                label: searchText,
                object: constants.SEARCH_RESULTS,
                type: TYPE_LIST_VIEW,
                imgName: iconName,
                info: { search: searchCriteria },
                url: getStringParam(url),
            };
            if(isUnitsListView){
                tab.info = {...tab.info,isUnitsListView:isUnitsListView,isShow:true};
            }
            if(selectedObject === constants.ACCOUNTS_OBJECT && !isUnitsListView){
                tab.info = {...tab.info,globalAccount:true};
            }
            
            if (isHomeTabRequired) {
                var homeTab = { item: object, label: selectedObjectLabel + " Home", object: object, type: TYPE_HOME, imgName: 'fa fa-home', url: "/" + object + "/filters", info: { search: searchCriteria }, isActive: true };
                addTab(tab, true, homeTab, true);
            } else {
                addTab(tab, true);
            }
            if(selectedObject === 'Unit’s Accounts'){
                object ='Unit’s Accounts';
                // localStorage.setItem('searchCriteria', JSON.stringify(searchCriteria));
                localStorage.setItem('searchCriteria_'+app.me.projectId+"_"+app.me.id, JSON.stringify(searchCriteria));

            }
            setSelectedModuleInfo(object);
        }
         handleCloseDropdown();
    }

    const saveRecentSearchText = (searchCriteria) => {
        try {
            let url = endPoints.HOME.SAVE_RECENT_SEARCH_TEXT;
            let params = {};
            let objectTableIds = getObjectIdListByModule(objects);

            if (isValidParam(searchCriteria)) {
                params.search_text = searchCriteria.searchText;
                params.object = searchCriteria.object;
                params.table_ids = objectTableIds;
                let promise = Promise.resolve(HTTPClient.post(url, params))
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (response.status === 0) {
                            filters.recentSearchesText[app.selectedModuleName] = response.data;
                        }
                    }
                });
            }
        } catch (error) {
            console.log("Error in 'recentSearchText.js -> saveRecentSearchText()':" + error);
        }
    }

    
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        return (
            <div>
                {mounted ?
                    <div style={{ marginLeft: '5px', marginTop: '13px' }}>
                        {generateRecentRecords()}
                    </div>
                    :
                    <div style={{ width: '100%', height: contentHeight }}>
                        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                            </div>
                        </div>
                    </div>}
            </div>
        );
    }
export default RecentSearchText;