import React from 'react'
import Button from '@mui/material/Button';
import { setHeader } from '../../../services/actions/headerActions';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import TextField from '@mui/material/TextField';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { isValidParam, getStringParam, isUrl, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { constants } from '../../../services/constants/constants';
import { TwitterPicker } from 'react-color';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Select, MenuItem, InputLabel, FormControl, Grid, Typography } from '@mui/material';
import { setUserContextData } from '../../../services/actions/appActions';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';

const ToggleSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#424242",
        '&:hover': {
            backgroundColor: '#2c2c2c1f',
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#424242",
    },
}));

const myColors = ['#FF6900', '#00D084', '#0693E3', '#EB144C', '#596ac6']

const WhiteLabel = ({ object }) => {
    let tempLogoBackgroundColorList = [
        {
            value: 'Light',
            label: 'Light',
        },
        {
            value: 'Dark',
            label: 'Dark'
        }
    ];

    const dispatch = useDispatch();
    const [mounted, setMounted] = useState(false);
    const [pickerOpen, setPickerOpen] = useState(false);
    const [logoBackgroundColorList, setLogoBackgroundColorList] = useState(tempLogoBackgroundColorList);
    const [whiteLabel, setWhiteLabel] = useState({
        help_url: constants.HELP,
        login_url: '',
        whiteLabelId: 0,
        logo: true,
        sign_out: true,
        background: '#FF6900',
        logoColorCode: "Light",
    });
    const [attachment, setAttachment] = useState(null);
    const [inProgress, setInProgress] = useState(false);
    const [sfFavicon, setSfFavicon] = useState(null);
    const appState = useSelector((state) => state.app);



    useEffect(() => {
        dispatch(setHeader(object, getLocalizedStrings().label.CUSTOMIZATION_SETTING.WHITE_LABEL, null, false, true));
        getUrl();
    }, []);


    const generateColorTypeList = () => {
        let listItems = logoBackgroundColorList.map((color, i) => {
            return (
                <MenuItem
                    key={i}
                    id={color.value}
                    value={color.value}
                    style={{ ...styles.popoverMenuItem }}
                    title={color.label} > {color.label}</MenuItem>
            );
        });

        return listItems;
    }

    const handleChange = (event) => {
        const target = event.target;
        let value = getStringParam(target.value);
        let tempWhiteLabel = { ...whiteLabel };
        tempWhiteLabel.help_url = value;
        setWhiteLabel(tempWhiteLabel);
    }
    const handleLoginUrlChange = (event) => {
        const target = event.target;
        let value = getStringParam(target.value);
        let _whiteLabel = { ...whiteLabel };
        _whiteLabel.login_url = value;
        setWhiteLabel({ ..._whiteLabel });
    }
    const restrictKey = (event) => {
        try {
            if (event.key === ' ') {
                return false;
            } else {
                return true;
            }
        } catch (error) {
            console.error("Error in 'WhiteLabel.js ->  restrictKey()' " + error);
        }
    }
    const isValidUrl = (url, attachedObj, val) => {
        let isValid = false;
        let is_url = isUrl(url);
        if (url !== '' && (!is_url || (is_url && url.startsWith("(") || url.endsWith(")"))
            || (is_url && url.startsWith("{") || url.endsWith("}"))
            || (is_url && url.startsWith("[") || url.endsWith("]")))) {
            if (val == 'loginurl') {
                dispatch(showCustomSnackBar(whiteLabel.login_url + " " + getLocalizedStrings().message.WHITE_LABEL.NOT_VALID_URL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));

            } else {
                dispatch(showCustomSnackBar(whiteLabel.help_url + " " + getLocalizedStrings().message.WHITE_LABEL.NOT_VALID_URL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));

            }

            isValid = false;
        } else {
            isValid = true;
        }
        return isValid;
    }

    const getUrl = () => {
        try {
            let _loginUrl = `${window.location.protocol}//${window.location.hostname + (window.location.port ? ':' + window.location.port : '')}`;

            var promise = Promise.resolve(HTTPClient.get(endPoints.WHITE_LABEL.GET_HELP_URL, null));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response) && response.hasOwnProperty('help_url')) {
                        console.log("response", response)
                        let _whiteLabel = { ...whiteLabel, help_url: response.help_url, login_url: response.login_url || _loginUrl, background: response.background, logoColorCode: response.logoColorCode, sign_out: response.sign_out === 'NO' ? false : true, logo: response.logo === 'NO' ? false : true }
                        setWhiteLabel({ ..._whiteLabel });
                        setMounted(true);
                    } else {

                        let tempWhiteLabel = { ...whiteLabel };
                        tempWhiteLabel.login_url = _loginUrl;
                        tempWhiteLabel.help_url = constants.HELP;
                        setWhiteLabel({ ...tempWhiteLabel });
                        appState.me.is_help_url = constants.HELP;
                        setMounted(true);

                    }
                    if (response.hasOwnProperty('favicon')) {
                        setSfFavicon(response.favicon);
                    } else {
                        let sfFavicon = "https://s3.amazonaws.com/app.soffront.com/favicon.ico";
                        setSfFavicon(sfFavicon);
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'WhiteLabel.js -> getUrl()':" + error);
        }
    }
    const saveHelpUrl = () => {
        let params = {};
        let helpLink = whiteLabel['help_url'];
        let loginUrl = whiteLabel['login_url']
        let background = whiteLabel['background'];
        let logoColorCode = whiteLabel['logoColorCode']
        let prevStyle = "float: left; margin-top: 3px; font-size: 14px; margin-left: -6px; margin-right: 10px;"
        let iconBtnBg = prevStyle + " background-color:" + background + ' !important;';
        try {
            let isValid = isValidUrl(helpLink, attachment, "helpurl");
            let isLoginValid = isValidUrl(loginUrl, attachment, "loginurl");
            if (loginUrl.trim() !== '') {
                if (isValid && isLoginValid) {
                    const formData = new FormData();
                    params.help_url = helpLink;
                    params.login_url = loginUrl;
                    params.background = background;
                    params.logo = getBooleanParam(whiteLabel['logo']) ? 'YES' : 'NO';
                    params.sign_out = getBooleanParam(whiteLabel['sign_out']) ? 'YES' : 'NO';
                    params.logoColorCode = logoColorCode;
                    let attachedObj = attachment;
                    if (isValidParam(attachedObj)) {
                        params.file_name = attachedObj.fileName;
                    }

                    formData.append('input_param', JSON.stringify(params));

                    if (isValidParam(attachedObj)) {
                        formData.append('attached_file', attachedObj.file);
                    }

                    var promise = Promise.resolve(HTTPClient.postFormData(endPoints.WHITE_LABEL.SAVE_HELP_URL, formData));
                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            if (isValidParam(response)) {
                                appState.me.is_help_url = helpLink;
                                appState.me.is_sign_out = whiteLabel['sign_out'];
                                appState.me.is_logo = whiteLabel['logo'];
                                appState.me.background = whiteLabel['background'];
                                appState.me.logoColorCode = whiteLabel['logoColorCode'];

                                dispatch(showCustomSnackBar(getLocalizedStrings().message.SECURITY_LEVEL.SUCCESS_MSG_1, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                let ids = response.data.id;
                                let token = localStorage.getItem('token');
                                if (token !== null) {
                                    dispatch(setUserContextData(token));
                                }
                                let sfFavicon = document.getElementById('sf-favicon');
                                if (sfFavicon !== null) {
                                    sfFavicon.href = response.data.data;
                                }
                                localStorage.setItem('background', whiteLabel['background']);
                                styles.primaryButton = { ...styles.primaryButton, backgroundColor: whiteLabel['background'] };
                                styles.listViewPrimaryButton = { ...styles.listViewPrimaryButton, backgroundColor: whiteLabel['background'] };
                                styles.emailSendBtn = { ...styles.emailSendBtn, background: whiteLabel['background'] };
                                styles.emailSendBtn1 = { ...styles.emailSendBtn1, background: whiteLabel['background'] + ' url(/asset/images/button_seperator.jpg)' };

                                var sheet = document.body.style;
                                sheet.setProperty("--background-color", whiteLabel['background'], 'important');
                                setPickerOpen(false);
                                setSfFavicon(response.data.data);
                                document.getElementById("instantColorChange").style = iconBtnBg;

                            }
                        });

                    }



                }
            }
            else {
                dispatch(showCustomSnackBar("Please enter valid login URL", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        } catch (error) {
            console.error("Error in 'WhiteLabel.js -> saveHelpUrl()':" + error);
        }
    }

    const handleToggle = (name, isInputChecked) => {
        try {
            let tempWhiteLabel = whiteLabel;
            tempWhiteLabel[name] = isInputChecked;
            setWhiteLabel({ ...tempWhiteLabel });

        } catch (error) {
            console.error("Error in 'WhiteLabel.js --> handleToggle()':" + error);

        }
    }

    const handelColorPicker = () => {
        setPickerOpen(!pickerOpen);
    };

    const handleChangeComplete = (color) => {
        let tempWhiteLabel = whiteLabel;
        tempWhiteLabel.background = color.hex;
        setWhiteLabel({ ...tempWhiteLabel });
    };
    const handleClose = () => {
        setPickerOpen(false);
    };
    const changeStatus = (event) => {
        let value = event.target.value;
        try {
            let tempWhiteLabel = whiteLabel;
            tempWhiteLabel.logoColorCode = value;
            setWhiteLabel({ ...tempWhiteLabel });
        } catch (error) {
            console.error("Error in 'news.js -> changeStatus()': " + error);
        }
    }
    const onChange = (e) => {
        let file = {};
        try {
            file.fileName = e.target.files[0].name;
            file.file = e.target.files[0];
            var img = new Image;
            img.src = URL.createObjectURL(e.target.files[0]);
            let fileName = file.fileName;
            fileName = fileName.replace(/'/g, "");
            let fileext = fileName.substring(fileName.lastIndexOf('.') + 1);
            if (fileext.toLowerCase() !== 'ico' && fileext.toLowerCase() !== 'gif' && fileext.toLowerCase() !== 'png') {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_FAVICON_EXTENSION, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                e.target.value = null;
                return false;
            } else {
                img.onload = function () {
                    var _height = this.height;
                    var _width = this.width;
                    if (_height <= 16 && _width <= 16) {
                        if (file.file.size === 0) {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_BLANK_FILE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            e.target.value = null;
                            return false;
                        } else {
                            let fileName = file.fileName;
                            fileName = fileName.replace(/'/g, "");
                            let fileext = fileName.substring(fileName.lastIndexOf('.') + 1);
                            if (fileext.toLowerCase() !== 'ico' && fileext.toLowerCase() !== 'gif' && fileext.toLowerCase() !== 'png') {
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_FAVICON_EXTENSION, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                e.target.value = null;
                                return false;
                            }
                        }
                        setInProgress(false);
                        setAttachment(file);
                    } else {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.CHANGE_LOGO.VALIDATE_FAVICON_IMAGE_SIZE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        document.getElementById("file").value = null;
                        setAttachment(null);
                        return false;
                    }

                }
            }

        }
        catch (error) {
            console.error("Error in 'CompanyProfile.js -> onChange()':" + error);
        }
    }

    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }
    let color = whiteLabel['background'];
    let open = pickerOpen;
    console.log("whitelable", whiteLabel.login_url)
    if (mounted) {
        return (
            <div style={{ paddingLeft: '3%', width: '97%' }}>
                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center" style={{ paddingTop: '10px', paddingBottom: '2px' }}>
                    <Grid item xs={2}>
                        <Typography >
                            Upload your favicon
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <input type="file" name="img_name" id="file" onChange={(e) => onChange(e)} />
                    </Grid>
                </Grid>
                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid item xs={12}>
                        <div className='dialogtxt'>
                            {"You can upload an ico, gif, or png file. The favicon must be 16x16 pixels."}
                        </div>
                    </Grid>
                </Grid>

                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center" style={{ paddingBottom: '10px' }}>
                    <Grid item xs={2}>
                        <Typography >
                            Login URL
                        </Typography>
                    </Grid>
                    <Grid item xs={6}><FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            name="login_url"
                            id="login_url"
                            label={"Login URL"}
                            value={whiteLabel['login_url']}
                            onChange={(event) => handleLoginUrlChange(event)}
                            margin='dense'
                            size='small'
                        />
                    </FormControl>
                    </Grid>
                </Grid>
                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center" style={{ paddingBottom: '10px' }}>



                    <Grid item xs={2}>
                        <Typography >
                            Help URL
                        </Typography>
                    </Grid>
                    <Grid item xs={6}><FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            name="help_url"
                            id="help_url"
                            label={"Help URL"}
                            defaultValue={whiteLabel['help_url']}
                            onChange={(event) => handleChange(event)}
                            onKeyPress={(event) => restrictKey(event)}
                            margin='dense'
                            size='small'
                            className={"sf-fields-bg"}
                        />
                    </FormControl>
                    </Grid>
                </Grid>

                

                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid item xs={2}>
                        <Typography >{getLocalizedStrings().label.SETTINGS.SHOW_SIGNOUT_LINK}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <FormGroup>
                            <FormControlLabel
                                control={<ToggleSwitch checked={whiteLabel['sign_out']} onChange={(event) => handleToggle('sign_out', event.target.checked)} color="default" name="sign_out" />}
                                label=''
                                labelPlacement="end"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center" style={{ paddingBottom: '10px' }}>
                    <Grid item xs={2}>
                        <Typography >{getLocalizedStrings().label.SETTINGS.SHOW_LOGO}</Typography></Grid>
                    <Grid item xs={6}>
                        <FormGroup>
                            <FormControlLabel
                                control={<ToggleSwitch checked={whiteLabel['logo']} onChange={(event) => handleToggle('logo', event.target.checked)} color="default" name="logo" />}
                                label=''

                                labelPlacement="end"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid item xs={2}><Typography >{getLocalizedStrings().label.SETTINGS.LOGO_COLOR}</Typography></Grid>
                    <Grid item xs={6}> <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                        <InputLabel id="sf-whitelabel-simple-select-outlined-label" className="sf-whitelabel">{getLocalizedStrings().label.SETTINGS.LOGO_COLOR}</InputLabel>
                        <Select
                            onChange={(event) => changeStatus(event)}
                            defaultValue={whiteLabel['logoColorCode']}
                            style={{ height: '42px' }}
                            label={getLocalizedStrings().label.SETTINGS.LOGO_COLOR}
                            labelId="user"
                            className={"sf-fields-bg"}
                        >
                            {generateColorTypeList()}
                        </Select>
                    </FormControl></Grid>
                </Grid>
                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid item xs={2}>
                        <Typography >{getLocalizedStrings().label.SETTINGS.THEME_COLOR}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Button label="" key="colorchange" style={{ ...{ borderRadius: '0px', border: '0px', height: '20px', backgroundColor: color, width: 20, minWidth: 20, marginTop: 10 } }} onClick={() => handelColorPicker()} />

                    </Grid>
                </Grid>
                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={6}>
                        {open &&
                            <div style={{}}>
                                <div style={cover} onClick={() => handleClose()} />
                                <TwitterPicker
                                    color={whiteLabel.background}
                                    onChangeComplete={(color) => handleChangeComplete(color)}
                                    width='204px'
                                    colors={myColors}
                                /></div>
                        }</Grid>
                </Grid>
                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid item xs={8}>
                        <div style={{ paddingBottom: '30px', overflow: 'hidden', float: 'right' }}>
                            <Button
                                key='save'
                                onClick={() => saveHelpUrl()}
                                style={{ ...styles.primaryButton, verticalAlign: 'top', marginRight: '-3px' }}
                            >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                        </div>
                    </Grid>
                </Grid>
            </div>



        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        return (<div style={{ width: '100%', height: contentHeight }}>
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
        </div>);
    }
}

export default WhiteLabel;

