import { actions } from '..//constants/actions';

const auditInitialState = {
    category: null,
    selecteCategory:null,
    auditCategory : {},
    answers:{},
    photos:{},
    AuditAnswerIds:[],
    AuditId:0,
    AuditWeakness:{},
    AuditTask:{},
}

const auditReducer = (state = auditInitialState, action) => {
    switch (action.type) {
        case actions.AUDIT_CATEGORY_GET_FULFILLED:
            return {
                ...state,
                category: action.payload
            }
        case actions.AUDIT_CATEGORY_UPDATE:
            return {
                ...state,
                category: action.payload
            }
        case actions.SET_AUDIT_ID:
            return {
                ...state,
                AuditId: action.payload
            }
        case actions.UPDATE_AUDIT_ANSWER_IDS:
            return {
                ...state,
                AuditAnswerIds: action.payload
            }
        case actions.UPDATE_AUDIT_ANSWERS:
            return {
                ...state,
                answers: action.payload
            }
        case actions.UPDATE_AUDIT_PHOTOS:
            return {
                ...state,
                photos: action.payload
            }
        case actions.AUDIT_CATEGORY_SELECTED_VALUE_UPDATE:
            return {
                ...state,
                selecteCategory: action.payload
            }


        case actions.UPDATE_AUDIT_CATEGORY_DATA:
            return {
                ...state,
                auditCategory : action.payload
            }

        case actions.UPDATE_AUDIT_WEAKNESS_DATA:
            return {
                ...state,
                AuditWeakness : action.payload
            }
        case actions.UPDATE_AUDIT_TASK:
            return {
                ...state,
                AuditTask : action.payload,
            }
        default:
            return state;
    }
}

export default auditReducer;