import * as React from 'react';
import Button from '@mui/material/Button';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { useDispatch, useSelector } from "react-redux";
import { styles } from '../../../services/constants/styles';

export default function SFDialogContainer({ callFrom, data }) {
    const dispatch = useDispatch();
    const handleClick = (from) => {
        dispatch(getAppDialog(false, null, null, null, null, null));
        if (from === 'deny') {
            if (data.deny !== undefined && data.deny !== null ) {
                data.deny();
            }
        } else {
            if (data.accept !== undefined && data.accept !== null) {
                data.accept();
            }
        }
    };

    return (
        <div>
            <div>
                <div style={{color:'rgb(0 0 0 / 60%)' , marginLeft:'2.5%'}} dangerouslySetInnerHTML={{ __html: data.message }}/>
            </div>
            <div style={styles.sfAlertAction}>
                {callFrom === 'confirm' && <Button style={{...styles.secondaryButton, marginRight:'20px'}} onClick={() => handleClick('deny')}> {getLocalizedStrings().label.COMMON.CANCEL} </Button>}
                <Button style={styles.primaryButton} onClick={() => handleClick('accept')}> {getLocalizedStrings().label.COMMON.OK} </Button>
            </div>
        </div>
    );
}
