import React, { useState, useEffect,useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setReportRedirection, setRecentReportResponseData, drillDownMatrixReport, downloadPdf, processChartData, getInXpressReports, setInXpressReportData } from '../../../services/actions/reportActions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { addTab, TYPE_DETAIL_VIEW, TYPE_LIST_VIEW, getActiveTab, getActiveTabInfo, updateTabInfo } from '../../../services/helper/sfTabManager';
import { isValidParam, getStringParam, getIntParam, getArrayParam, getBooleanParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import * as HTTPClient from '../../../services/helper/httpClient';
import { getInXpressReportId } from '../../../services/helper/utils';
import { constants } from '../../../services/constants/constants';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { INXPRESS_REPORT_MAP, INXPRESS_REPORT } from '../../../services/constants/constants';
import { LinkToType } from '../../../services/constants/link';
import { endPoints } from '../../../services/constants/endPoints';
import ShowCircularProgress from '../components/circularProgress';
import { connect, Provider } from 'react-redux';
import Button from '@mui/material/Button';

class InexpressReportTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            /** inexpress  report table*/
            data: null,
            reportResponseData: {},
            reportColumns: null,
            reportData: null,
            startIndex: INXPRESS_REPORT.FETCH_COUNT_START,
            fetchSize: INXPRESS_REPORT.FETCH_SIZE,
            isScroll: false,
            showProgressBar: true,
            isShowMore: true
        }
    }

    componentDidMount() {
        this.loadReport(this.props);
     }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let selectedReport = this.getSelectedReport();

        let reportParams = isValidParam(this.props.data) ? getObjectParam(this.props.data.reportParams) : {};
        let nextReportParams = isValidParam(nextProps.data) ? getObjectParam(nextProps.data.reportParams) : {};

        if (getStringParam(reportParams.parameterId) != getStringParam(reportParams.parameterId)) {
            this.state.showProgressBar = true;
            this.setState({});
            this.loadReport(nextProps);
        } else if (this.props.startIndex == nextProps.startIndex) {
            if (nextProps.isReload) {
                this.state.showProgressBar = true;
                this.setState({});
                this.loadReport(nextProps);
            }
        }
        else if (nextProps.startIndex > 0 && this.props.startIndex != nextProps.startIndex && (nextProps.data.reportParams.reportType == "InXpress Report 16" || nextProps.data.reportParams.reportType == "InXpress Report 20" || nextProps.data.reportParams.reportType == "InXpress Report 21")) {
            if (nextProps.isReload) {
                this.setState({});
                this.loadReport(nextProps);
            }
        } else if (nextProps.startIndex == 0 && this.props.startIndex != nextProps.startIndex && (nextProps.data.reportParams.reportType == "InXpress Report 20" || nextProps.data.reportParams.reportType == "InXpress Report 21")) {
            if (nextProps.isReload) {
                this.setState({});
                this.loadReport(nextProps);
            }
        }
    }

    componentDidUpdate() {
        if (this.props.startIndex > 0) {
            let element = document.getElementById("row_" + this.state.startIndex);
            if (isValidParam(element)) {
                element.scrollIntoView();
            }
        }
    }

    getSelectedReport = () => {
        let info = getActiveTabInfo();
        return info.selectedReport;
    }

    loadReport = (props) => {
        this.setReportInformation(props);
        this.loadReportByIndex();
        props.resetReload();
    }

    setReportInformation = (props) => {
        try {
            let state = this.state;
            state.data = props.data;
            state.isScroll = props.isScroll;
            state.startIndex = props.data.reportParams.startIndex;
        } catch (error) {
            console.log("Error in 'inexpressReportTable.js -> setReportInformation()':" + error)
        }
    }


    stopScrolling = () => {
        this.state.startIndex = this.state.startIndex - this.state.fetchSize;
        this.state.isScroll = false;
        this.props.stopScrolling();
    }


    loadReportByIndex = () => {
        let errorMessage = null;
        let _state = this.state;
        let reportObj = new Object();
        let parameterId = null;
        let reportType = null;
        let dueBy = null;
        let country = null;
        let reportId = null;
        let selectedCountryId = null;
        let franchiseId = null;
        let franProjectId = null;
        let selectedWorkflowId = null;
        let selectedWorkflow = null;
        let workflowList = null;
        let fromDate = null;
        let toDate = null;
        let isValid = false;
        let wfIdAll = '';
        try {
            let selectedReport = this.getSelectedReport();
            if (isValidParam(selectedReport)) {
                let reportParams = this.state.data.reportParams;
                if (isValidParam(reportParams)) {
                    parameterId = reportParams.parameterId;
                    reportType = getStringParam(reportParams.reportType);
                    reportId = getIntParam(reportParams.reportId);
                    dueBy = reportParams.dueBy;
                    country = reportParams.country;
                    selectedCountryId = reportParams.selectedCountryId;

                    if (reportType.length > 0 && reportType != 'InXpress Report 12') {

                        franchiseId = reportParams.franchiseId;
                        franProjectId = isValidParam(reportParams.franProjectId) ? reportParams.franProjectId.toString() : '';
                        selectedWorkflowId = reportParams.selectedWorkflowId;
                        selectedWorkflow = reportParams.selectedWorkflow;
                        workflowList = reportParams.workflowList;
                    }

                    fromDate = reportParams.fromDate;
                    toDate = reportParams.toDate;
                }

                if (isValidParam(selectedWorkflowId) && selectedWorkflowId == -99) {

                    /*   let wfList = workflowList;
                      if (getArrayParam(wfList).length > 0) {
                          let lastIndex = wfList.length - 1;
                          for (let i = 0; i < wfList.length; i++) {
                              let obj = wfList[i];
                              if (i == lastIndex) {
                                  wfIdAll += obj.ID;
                              } else {
                                  wfIdAll += obj.ID + '##';
                              }
                          }
                      }
                      selectedWorkflowId = wfIdAll; */
                }

                /* validate date */
                if (dueBy == 'cust') {
                    if (!isValidParam(fromDate)) {
                        errorMessage = getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_FROM_DATE;
                    } else if (!isValidParam(toDate)) {
                        errorMessage = getLocalizedStrings().message.INXPRESS_REPORT.VALIDATE_EMPTY_TO_DATE;
                    } else if (isValidParam(fromDate) && isValidParam(toDate)) {
                        isValid = true;
                    }
                } else {
                    fromDate = "";
                    toDate = "";
                    isValid = true;
                }

                let fetchSize = this.state.fetchSize;
                if (isValid && !isValidParam(errorMessage)) {
                    reportObj.parameter_id = isValidParam(parameterId) ? parameterId : '';
                    if (isValidParam(reportId)) {
                        reportObj.report_id = reportId;
                        reportObj.report_type_id = reportId;
                    }
                    reportObj.due_by = getStringParam(dueBy);
                    reportObj.hdn_from_date = getStringParam(fromDate);
                    reportObj.hdn_to_date = getStringParam(toDate);
                    reportObj.country = getStringParam(country);
                    reportObj.my_client_id = getIntParam(selectedCountryId);
                    if (isValidParam(franchiseId)) {
                        franchiseId = franchiseId.toString();
                    }
                    if (isValidParam(selectedWorkflowId)) {
                        selectedWorkflowId = selectedWorkflowId.toString()
                    }
                    reportObj.franchise_id = getStringParam(franchiseId);
                    reportObj.workflow_id = getStringParam(selectedWorkflowId);
                    reportObj.workflow_name = getStringParam(selectedWorkflow);
                    reportObj.drill_down = '1';
                    reportObj.franProjectId = getStringParam(franProjectId);
                    reportObj.start_index = this.state.startIndex;
                    reportObj.page_size = fetchSize;

                    if (reportType == 'InXpress Report 16' && reportParams.isGlobal) {
                        reportObj.isGlobal = true;
                    }
                    if (reportType == 'InXpress Report 16') {
                        reportObj.page_size = INXPRESS_REPORT.ACTIVITY_FETCH_SIZE;
                        fetchSize = INXPRESS_REPORT.ACTIVITY_FETCH_SIZE;
                    }

                    if (reportType == 'InXpress Report 15' || reportType == 'InXpress Report 16' || reportType == 'InXpress Report 17') {
                        reportObj.user_names = reportParams['userNames'];
                    }

                    if (reportType == 'InXpress Report 20' || reportType == 'InXpress Report 21') {
                        reportObj.selectedRegion = reportParams.selectedRegion;
                        reportObj.page_size = INXPRESS_REPORT.ACTIVITY_FETCH_SIZE;
                        fetchSize = INXPRESS_REPORT.ACTIVITY_FETCH_SIZE;
                    }

                    let promise = getInXpressReports(reportObj);
                     if (isValidParam(promise)) {
                        promise.then((response) => {
                            if (isValidParam(response)) {
                                
                               this.props.setInXpressReportData(response);
                               let state = this.state;
                                state.reportData = state.startIndex <= 0 ? [] : state.reportData;
                                this.state.showProgressBar = false;
                                if (reportId == INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_SIX) {
                                    state.reportResponseData = response;
                                } else {
                                    let columns = getArrayParam(response.fields);
                                    let records = getArrayParam(response.records);
                                    let reportData = getArrayParam(state.reportData);
                                    let isScroll = getBooleanParam(state.isScroll);
                                    if (columns.length > 0) {
                                        state.reportColumns = columns;
                                    } if (isScroll) {
                                        state.reportData = reportData.concat(records);
                                    } else {
                                        state.reportData = records;
                                    }

                                    if (reportId == INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_THIRTEEN && Array.isArray(state.reportData)) {
                                        state.reportData = state.reportData.sort((a, b) => {
                                            return a.franchiseCode - b.franchiseCode;
                                        });
                                    }

                                    if (getArrayParam(records).length == 0) {
                                        this.stopScrolling();
                                    }

                                    if (getArrayParam(records).length < fetchSize) {
                                        this.state.isShowMore = false;
                                    } else {
                                        this.state.isShowMore = true;
                                    }
                                }
                                this.setState(state);
                            }
                        });
                    }
                } else {
                    this.props.showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                    this.state.showProgressBar = false;
                }
            }
        } catch (error) {
            console.error("Error in 'inexpressReport.js -> loadReportByIndex()':" + error);
        }
    }


    generateReportTable = () => {
        let reportTypeId = null;
        let component = null;
        let selectedReport = this.getSelectedReport();
        try {
            if (isValidParam(selectedReport)) {
                reportTypeId = getInXpressReportId(getStringParam(selectedReport.item_type));
                if (reportTypeId == INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_SIX) {
                    component = this.processInXpressReportSix(reportTypeId);
                }
                else if (reportTypeId == INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_TWELVE) {
                    component = this.processInXpressReportSummary(reportTypeId);
                }
                else {
                    component = this.processInXpressReports(reportTypeId);
                }
            }
        }
        catch (error) {
            console.error("Error in 'inexpressReportTable.js -> generateReportTable()':" + error);
        }
        return component;
    }

    processInXpressReports = (reportTypeId) => {
        let reportData = null;
        let reportColumns = null;
        let reportId = null;
        let START = 0;
        let reportComponent = null;
        try {
            reportData = getArrayParam(this.state.reportData);
            reportColumns = getArrayParam(this.state.reportColumns);
            reportId = getIntParam(reportTypeId);

            let columnHeader = new Array();
            let record = null;
            let rowArray = new Array();
            let columnArray = null;

            let reportTableWidth = 98;
            if (reportId == INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_EIGHT) {
                reportTableWidth = 27;

            }
            let textAlign = 'center';
            if(this.props.data.reportParams.reportType === "InXpress Report 20"){
                textAlign = 'left';
            }
            if (reportId == INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_FOURTEEN) {
                this.state.isShowMore = false;
            }


            let tableStyle = { cellspacing: '0', cellpadding: '0', border: '0', width: reportTableWidth + '%', id: reportId };
            let colValueStyle = null;
            if (reportColumns.length > 0 && reportData.length > 0) {
                reportColumns.map((col, Index) => {
                    if (!getBooleanParam(col.is_hidden)) {
                        if (col.hasOwnProperty('label')) {
                            let columnLabel = col.label;
                            columnLabel = getLocalizedStrings().label.INXPRESS_REPORT.hasOwnProperty(columnLabel) ? getLocalizedStrings().label.INXPRESS_REPORT[columnLabel] : columnLabel;
                            if(this.props.data.reportParams.reportType === "InXpress Report 21" && Index == 1){
                                columnHeader.push(<th style={{ overflow: 'hidden', whiteSpace: 'nowrap', textAlign: 'left' }}>{/*col.label*/}{columnLabel}</th>);
                            } else {
                                columnHeader.push(<th style={{ overflow: 'hidden', whiteSpace: 'nowrap', textAlign: textAlign }}>{/*col.label*/}{columnLabel}</th>);
                            }
                        }
                    }
                });

                reportData.forEach((rowData, index) => {
                    if (isValidParam(rowData)) {
                        columnArray = new Array();
                        reportColumns.forEach(col => {
                            if (isValidParam(col) && col.hasOwnProperty('name') && !getBooleanParam(col.is_hidden)) {
                                colValueStyle = new Object();
                                let value = isValidParam(rowData[col.name]) ? rowData[col.name] : '';
                                if (getBooleanParam(col.is_link)) {
                                    let label = getStringParam(value);
                                    let id = getIntParam(rowData.id);
                                    let projectId = getIntParam(rowData.t_projectid);
                                    let workflowId = getIntParam(rowData.workflow_id);
                                    let status = getStringParam(rowData.t_status);
                                    let object = constants.ACCOUNTS_OBJECT;
                                    if (reportId == INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_FIVE || reportId == INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_ELEVEN) {
                                        id = rowData["j_leads.id"];
                                        projectId = rowData["j_leads.t_projectid"];
                                    }

                                    if (reportId == INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_SIXTEEN) {
                                        projectId = rowData["myclient_id"];
                                        if (col.name == "new_leads") {
                                            object = constants.ACCOUNTS_OBJECT;
                                            label = "New Accounts";
                                        } else if (col.name == "new_customers") {
                                            label = "New Customers";
                                        }
                                        else if (col.name == "new_contacts") {
                                            object = constants.CONTACTS_OBJECT;
                                            label = "New Contacts";
                                        }
                                    }

                                    if (reportId == INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_FIFTEEN) {
                                        id = rowData['record_id'];
                                        projectId = rowData['project_id'];
                                        workflowId = rowData['workflow'];
                                        status = rowData['status'];
                                    }

                                    if (reportId == INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_NINE) {
                                        status = getStringParam(rowData.name);
                                    }
                                    let params = { id: id, projectId: projectId, workflowId: workflowId, status: status };
                                    let linkComponent = <Link title={value} to={'#'} onClick={() => this.openTab(label, object, reportId, params)}>{value}</Link>;

                                    if (col.field_type == 'integer') {
                                        colValueStyle.textAlign = 'center';
                                    }
                                    colValueStyle.verticalAlign = 'top';
                                    columnArray.push(<td title={isValidParam(value) ? value : ''} style={colValueStyle}>{linkComponent}</td>);
                                } else {

                                    if (col.field_type == 'integer') {
                                        colValueStyle.textAlign = 'center';
                                    }
                                    if (col.name == 'note_text' || col.name == 'j_notes.note_text') {
                                        colValueStyle.overflow = 'hidden';
                                        colValueStyle.textOverflow = 'ellipsis';
                                        colValueStyle.whiteSpace = 'nowrap';
                                        colValueStyle.maxWidth = '0';
                                        colValueStyle.minWidth = '20px';
                                        colValueStyle.width = '40%';
                                    }
                                    if(this.props.data.reportParams.reportType === "InXpress Report 20"){
                                        colValueStyle.textAlign = 'left';
                                    }
                                    if(col.name !="workFlow_stages" && col.field_type == 'text' && this.props.data.reportParams.reportType === "InXpress Report 21"){
                                        colValueStyle.textAlign = 'center';
                                    }
                                    colValueStyle.verticalAlign = 'top';
                                    columnArray.push(<td title={isValidParam(value) ? value : ''} style={colValueStyle}>{value}</td>);
                                }
                            }
                        });
                        rowArray.push(<tr id={'row_' + index}>{columnArray}</tr>);
                    }
                });

                if (getBooleanParam(this.state.isShowMore)) {
                    let colLength = reportColumns.filter(f => { return !f.is_hidden }).length;
                    let moreButtonRow = <tr id={'row_more'}><td colSpan={colLength}>
                         <Button>
                        <label style={{ background: '#f75723', width: '70px', fontStyle: 'normal', marginTop: 3, cursor: 'pointer', color: '#ffffff', fontWeight: 400, textAlign: 'center' }} onClick={this.props.handleMore}>More</label></Button>
                    </td></tr>;
                    rowArray.push(moreButtonRow);
                }

                reportComponent = (
                    <table id='inxpress' className="layout_maintable" style={{ width: '100%' }}>
                        <thead> {columnHeader} </thead>
                        <tbody> {rowArray} </tbody>
                    </table>
                );
            } else {
                rowArray = [<tr><td><span style={{ color: 'red' }}>{getLocalizedStrings().label.INXPRESS_REPORT.NO_RECORDS_FOUND}</span></td></tr>];
            }
            reportComponent = (
                <table className="layout_maintable" style={{ width: '100%' }}>
                    <thead> {columnHeader} </thead>
                    <tbody> {rowArray} </tbody>
                </table>
            );

        } catch (error) {
            console.error("Error in 'inexpressReportTable.js -> processInXpressReports()':" + error);
        }
        return reportComponent;
    }

    processInXpressReportSix = (reportTypeId) => {
        let reportResponseData = {};
        reportResponseData = this.state.reportResponseData;
        let maxLength = getIntParam(reportResponseData.maxLength);
        let columnNames = getArrayParam(reportResponseData.columnsArr);
        let reportId = getIntParam(reportTypeId);
        let reportComponent = null;
        let columnHeader = new Array();
        let rowArray = new Array();
        try {
            if (maxLength > 0) {
                columnNames.forEach(stage => {
                    columnHeader.push(<th>{stage}</th>);
                    columnHeader.push(<th>Days</th>);
                });

                for (let rowIndex = 0; rowIndex < maxLength; rowIndex++) {
                    let colArr = new Array();
                    columnNames.forEach((stage, colIndex) => {
                        if (reportResponseData.hasOwnProperty(stage)) {
                            let stageArr = reportResponseData[stage];
                            if (stageArr.length >= rowIndex + 1) {
                                let stageObj = stageArr[rowIndex];
                                let params = { id: stageObj.id, projectId: stageObj.t_projectid };
                                colArr.push(<td><Link title={stageObj.companyName} to={'#'} onClick={() => this.openTab(stageObj.companyName, constants.ACCOUNTS_OBJECT, reportId, params)}>{stageObj.companyName}</Link></td>);
                                colArr.push(<td title={isValidParam(stageObj.diffdate) ? stageObj.diffdate : ''}>{stageObj.diffdate}</td>);
                            }
                        }
                    });
                    /** priyanka for adding empty cells table */
                    if (colArr.length < columnHeader.length) {
                        let lastIndex = colArr.length - 1;

                        for (let i = lastIndex; i < columnHeader.length; i++) {
                            colArr.push(<td></td>);
                        }
                    }
                    /** */
                    rowArray.push(<tr id={'row_' + rowIndex}>{colArr}</tr>);
                }
            } else {
                rowArray = [<tr><td><span style={{ color: 'red' }}>{getLocalizedStrings().label.INXPRESS_REPORT.NO_RECORDS_FOUND}</span></td></tr>];
            }

            reportComponent = (
                <table id='inxpress' className="layout_maintable" style={{ width: '100%' }}>
                    {columnHeader.length > 0 && <thead> {columnHeader} </thead>}
                    <tbody> {rowArray} </tbody>
                </table>
            );
        } catch (error) {
            console.error("Error in 'inexpressReport.js -> processInXpressReportSix()':" + error);
        }
        return reportComponent;
    }


    processInXpressReportSummary = (reportTypeId) => {
        let reportData = null;
        let reportColumns = null;
        let reportId = null;
        let START = 0;
        let reportComponent = null;
        let colValueStyle = null;
        try {
            reportData = getArrayParam(this.state.reportData);
            reportColumns = getArrayParam(this.state.reportColumns);
            reportId = getIntParam(reportTypeId);

            let columnHeader = new Array();
            let rowArray = new Array();
            let columnArray = null;
            let record = null;

            var reportTableWidth = 98;
            let tableStyle = { cellspacing: '0', cellpadding: '0', border: '0', width: reportTableWidth + '%', id: reportId };
            if (reportColumns.length > 0 && reportData.length > 0) {
                reportColumns.forEach(col => {
                    if (!getBooleanParam(col.is_hidden)) {
                        if (col.hasOwnProperty('label')) {
                            columnHeader.push(<th style={{ overflow: 'hidden', whiteSpace: 'nowrap', textAlign: 'center' }}>{col.label}</th>);
                        }
                    }
                });

                reportData.forEach((rowData, index) => {
                    if (isValidParam(rowData)) {
                        columnArray = new Array();
                        reportColumns.forEach(col => {
                            if (isValidParam(col) && col.hasOwnProperty('name') && !getBooleanParam(col.is_hidden)) {
                                colValueStyle = new Object();
                                let value = isValidParam(rowData[col.name]) ? rowData[col.name] : '';
                                if (col.field_type == 'integer') {
                                    colValueStyle.textAlign = 'center';
                                }
                                columnArray.push(<td title={isValidParam(value) ? value : ''} style={colValueStyle}>{value}</td>);
                            }
                        });
                        rowArray.push(<tr id={'row_' + index}>{columnArray}</tr>);
                    }
                });
            }
            else {
                rowArray = [<tr><td><span style={{ color: 'red' }}>{getLocalizedStrings().label.INXPRESS_REPORT.NO_RECORDS_FOUND}</span></td></tr>];
            }
            reportComponent = (
                <table id='inxpress' className="layout_maintable" style={{ width: '100%' }}>
                    <thead> {columnHeader} </thead>
                    <tbody> {rowArray} </tbody>
                </table>
            );


        } catch (error) {
            console.error("Error in 'inexpressReportTable.js -> processInXpressReportSummary()':" + error);
        }
        return reportComponent;
    }

    openTab = (label, object, reportId, params) => {
        let isInXpress = true;
        let projectId = 0;
        let recordId = 0;
        let url = '';
        let isListView = false;
        let tabInfo = new Object();
        try {
            reportId = getIntParam(reportId);
            label = getStringParam(label);
            object = getStringParam(object);

            if (isValidParam(params)) {
                recordId = getIntParam(params.id);
                recordId = parseInt(recordId);
                projectId = getIntParam(params.projectId);
            }

            /** ---added by priyanka for drill down----- */
            let data = getObjectParam(this.state.data);
            let reportParams = getObjectParam(data.reportParams);
            let isFranchise = getBooleanParam(reportParams.isFranchise);
            let isCountry = getBooleanParam(reportParams.isCountry);

            if (reportId == INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_EIGHT || reportId == INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_NINE
                || reportId == INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_SIXTEEN) {
                isListView = true;
            }
            tabInfo = { isInXpress: isInXpress, inXpressProjectId: projectId };

            let activeTab = getObjectParam(getActiveTab());
            let activeTabIndex = getIntParam(activeTab.index);

            let tab = { label: label, object: object, type: TYPE_DETAIL_VIEW };
            if (isListView) {
                tab.type = TYPE_LIST_VIEW;
                url = '/' + object + '/queries';
                tabInfo.filter = { id: 542, name: 'All records', type: constants.SEARCH_QUERY_TYPE_ALL };
                tabInfo.isReport = true;

                if (reportId == INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_SIXTEEN) {
                    if (object == constants.CONTACTS_OBJECT) {
                        tabInfo.filter.id = 551;
                    }
                    if (reportParams.reportName == 'Activity by Franchise' && getStringParam(label) == "New Customers") {
                        tabInfo.input_param = {
                            "query_id": 716,
                            "report_to_date": "",
                            "query_name": "",
                            "report_due_by": getStringParam(reportParams.dueBy),
                            "group_by_field_name": "t_status",
                            "group_by_condition": "Customer",
                            "query_type": "11",
                            //"report_user_names":"Amitava Neogi",
                            "report_from_date": "",
                            "report_type": "11",
                            "is_report": true,
                            "report_action_coach_type": "102"
                        }
                    }
                    else {
                        tabInfo.input_param = {
                            report_due_by: getStringParam(reportParams.dueBy),
                            report_type: getIntParam(reportParams.reportId)
                        }
                    }
                } else {
                    tabInfo.input_param = {
                        search_type: constants.SEARCH_TYPE_EXACT,
                        search: [{ workflow_id: getIntParam(params.workflowId) }, { t_status: getStringParam(params.status) }]
                    };
                }
                tabInfo.reportLabel = label;
            } else if (recordId > 0) {
                url = '/' + object + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + recordId;

                if (isFranchise) {
                    tabInfo.isInXpress = false;
                }
            }
            tab.url = url;
            tab.info = tabInfo;
            addTab(tab, true);
        } catch (error) {
            console.error("Error in 'inexpressReportTable.js -> openTab()':" + error);
        }
    }

    /** excel */
    downloadExcelReport = () => {
        let beforeHeaderComponent = this.excelGenerateBeforeHeaders();
        let headerComponents = this.excelGenerateHeaders();
        let rowComponents = this.excelGenerateRows();

        let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '  </tbody> </table>';
        let htmlData = htmlTable;
        if (isValidParam(htmlData) && htmlData != '') {
            htmlData = htmlData.replace("%22", "\"");
            var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
            var downloadUrl = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = downloadUrl;
            a.download = 'ResultSet_' + new Date().getTime() + '.xls';
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    }

    excelGenerateBeforeHeaders = () => {
        let reportParams = getObjectParam(this.state.data.reportParams);
        let cells = '';
        let dueBy = '';
        let franchise = '';
        let workflow = '';
        let user = '';
        let region = '';
        let country = '';

        let reportName = getStringParam(reportParams.reportName);
        let reportType = getStringParam(reportParams.reportType);
        let columns = this.state.reportColumns.filter(f => {
            if (!f.is_hidden) { return f; }
        });
        let columnsLength = getArrayParam(columns).length;

        cells = cells + '<tr><td  colspan="' + (columnsLength) + '"> ' + reportName + ' </td></tr>';
        if (reportType.length > 0) {

            if (reportType == 'InXpress Report 15' || reportType == 'InXpress Report 17') {
                user = getStringParam(reportParams.userNames);
                cells = cells + '<tr><td> User Name :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + user + ' </td></tr>';
            }
            if (reportType == 'InXpress Report 20' || reportType == 'InXpress Report 21') {
                region = getStringParam(reportParams.selectedRegion);
                country = getStringParam(reportParams.country);
                cells = cells + '<tr><td> Region :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + region + ' </td></tr>';
                cells = cells + '<tr><td> Country :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + country + ' </td></tr>';
            }
            if (reportType != 'InXpress Report 13' && reportType != 'InXpress Report 14' && reportType != 'InXpress Report 20' && reportType != 'InXpress Report 21') {
                dueBy = getStringParam(reportParams.dueByName);
                cells = cells + '<tr><td> Date :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + dueBy + ' </td></tr>';
            }
            if (reportType != 'InXpress Report 15' && reportType != 'InXpress Report 16' && reportType != 'InXpress Report 17' && reportType != 'InXpress Report 20' && reportType != 'InXpress Report 21') {
                if (reportParams.isCountry) {
                    franchise = getStringParam(reportParams.franchiseId);
                    cells = cells + '<tr><td> Franchise :</td>  <td  align=\'left\'  colspan="' + (columnsLength - 1) + '"> ' + ' ' + franchise + ' </td></tr>';
                }
            }
            if (reportType != 'InXpress Report 13' && reportType != 'InXpress Report 14' && reportType != 'InXpress Report 16' && reportType != 'InXpress Report 15') {
                workflow = getStringParam(reportParams.selectedWorkflow);
                cells = cells + '<tr><td> Workflow :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + workflow + ' </td></tr>';
            }
        }
        return cells;
    }

    excelGenerateHeaders = () => {
        let columns = getArrayParam(this.state.reportColumns);
        let cells = '';
        columns.map((columnObject, index) => {
            if (!columnObject.is_hidden) {
                cells = cells + '<th  bgcolor="6A8CCB"> ' + columnObject.label + ' </th>';
            }
        })
        return ('<tr>' + cells + '</tr>');
    }

    excelGenerateRows = () => {
        let rows = getArrayParam(this.state.reportData);
        let columns = getArrayParam(this.state.reportColumns);
        let rowData = '';
        if (rows.length != 0) {
            let cells = '';
            rows.map((rowObject, rowIndex) => {
                let innerCells = '';
                columns.map((columnObject, colIndex) => {
                    if (!columnObject.is_hidden) {
                        innerCells = innerCells + '<td >' + rowObject[columnObject.name] + ' </td>';
                    }
                })
                if (rowIndex % 2 == 0) {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                } else {
                    cells = cells + ('<tr  bgcolor="Lavender">' + innerCells + ' </tr>');
                }

            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
        }
        return rowData;
    }
    render() {
        let reportTableComponent = null;
        let pageHeight = window.innerHeight - 247;
        let top = (pageHeight - 10) / 2
        if (this.state.showProgressBar) {
            return (
                <div style={{ width: '100%', height: pageHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            )
        }
        else {
            reportTableComponent = this.generateReportTable();
            return <div>{reportTableComponent}</div>;
        }
    }
}


const mapStateToProps = (state, props) => {
    return {
        app: state.app,
        report: state.report,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
        setInXpressReportData: (reportData) => {
            dispatch(setInXpressReportData(reportData));
        },
    }
}

/** excel */
InexpressReportTable = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(InexpressReportTable);

export default InexpressReportTable;