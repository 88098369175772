
import React from 'react';
import  TotalRecordCount from '../components/TotalRecordCount';
import { GroupDataTable } from '../components/GroupDataTable';
import { GroupPagination } from '../components/GroupPagination';
import { getGroupCampaignDetails } from '../../../services/actions/detailViewActions';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import ShowCircularProgress from '../components/circularProgress';
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const WebFormsForGroup =({recordId,defaultPageSize})=> {
    const [campaignDetails, setCampaignDetails] = useState(null);
    const [mounted, setMounted] = useState(false);
    const[changeLog,setchangeLog]=useState({campaignDetails:null})
    useEffect(() => {
        var objParam = {};
            objParam.recordId = recordId.id;
            objParam.type = "webForms";
            objParam.fetchFrom = 0;
            objParam.fetchCount = defaultPageSize;
            objParam.isRecordCount = true;
    
            getCampaignDetails(objParam);
      }, [recordId.id]);
   


    const getCampaignDetails = (objParam) => {
        setMounted(false);
        let promise = Promise.resolve(getGroupCampaignDetails(objParam));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    setMounted(true);
                    setCampaignDetails(response);
                    let log = changeLog;
                    log.campaignDetails = response;
                    setchangeLog({...log});
                }
            });
        }

    }

    if (mounted) {
        let objDetails = changeLog.campaignDetails;
        let pgDetails = {};
        pgDetails.campType = 'webForms';
        pgDetails.totalRecordCount = objDetails.webFormsCount;
        pgDetails.startNo = 1;
        pgDetails.defaultPageSize = defaultPageSize;
        pgDetails.recordId = recordId.id;
        let webFormsLabel = getLocalizedStrings().label.MODULE_ITEMS[objDetails.webFormsLabel];
        return (
            <div>
                <div className="group_campaign_title">
                    <TotalRecordCount totalCount={objDetails.webFormsCount} />
                    <span style={{ paddingTop: "2px" }}>{webFormsLabel}</span>
                </div>
                <div>
                    { objDetails.webForms !== undefined  && objDetails.webForms !== null && objDetails.webForms.length > 0 &&
                        <GroupDataTable data={objDetails.webForms} />
                    }
                </div>
                {!( objDetails.webForms !== undefined  && objDetails.webForms !== null && objDetails.webForms.length > 0) &&
                    <div className="group_campaign_norecord">{getLocalizedStrings().message.GROUP.NO_WEBFORM}</div>
                }
                <div>
                    { objDetails.webForms !== undefined  && objDetails.webForms !== null && objDetails.webForms.length > 0 &&
                        <GroupPagination pgDetails={pgDetails} getGroupCampaignDetails={getCampaignDetails} />
                    }
                </div>
            </div>
        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        return (<div style={{ width: '100%', height: contentHeight }}>
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
        </div>);
    }
}
export default WebFormsForGroup;

WebFormsForGroup.propTypes = {
    recordId: PropTypes.number,
    defaultPageSize: PropTypes.number
};