import React, { useState, useEffect }  from 'react';
import { generateLeadScoreIcon } from '../../../services/helper/utils';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { constants } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useDispatch} from "react-redux";
import { CollectionsOutlined } from '@mui/icons-material';

const ShowLeadScore = ({key,rowIdx, row, value, totalScore,object, score}) =>  {

    const dispatch = useDispatch();

    const [stateScore, setStateScore] = useState(0);
    const [scoreIconarray, setScoreIconarray] = useState(null);

    useEffect(() => {
        try {
            let tempScore = score!== undefined ? score : value;
            let scoreIconarray = generateLeadScoreIcon(totalScore, tempScore);
            setStateScore(tempScore);
            setScoreIconarray(scoreIconarray,scoreIconarray);  
            console.log(tempScore,)          
        } catch (error) {
            console.error("Error in 'showLeadScore.js -> updateState()':" + error);
        }
    }, []);

    const getLeadScoreHistory = () => {
        let data = {};
        data.parent_object =  object;
        data.parent_record_id = row.id;
        data.lead_score = stateScore;
        dispatch(getAppDrawer(true, getLocalizedStrings().label.AUDIT_LEADSCORE.LEAD_SCORE_HISTORY, constants.LEAD_SCORE_HISTORY, data, constants.LIST_VIEW));
    }

    if(typeof(value) === 'number') {
        return (
            <div
                style={{ display: 'inline', fontSize: '16px', cursor: 'pointer' }}
                title={getLocalizedStrings().label.AUDIT_LEADSCORE.SCORE + ": " + stateScore}>
                {scoreIconarray}
                <span style={{ paddingLeft: '5px', color: '#717171', textDecoration:'underline' }} onClick={()=>getLeadScoreHistory()}>
                    {stateScore}
                </span>
            </div>
        )
    } else if(typeof(value) === 'object') {
        return (
            {...value}
        )
    } else if (typeof (value) === 'string' && Boolean(value.trim ())) {
        return <span>{value}</span>;
    }
    else {
        return <></>;
    }
    
};

export default ShowLeadScore;
