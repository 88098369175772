import React from 'react';
import { getObjectParam } from '../../../services/helper/parameterVerifier';

export default class SFLoader extends React.Component {
    constructor() {
        super();
        this.state = {
            style: { textAlign: 'center', padding: '5px', borderRadius: '50%', background: 'white', margin: '35px auto', boxShadow: '0.1px 0.1px 12px 0.1px rgba(0,0,0,0.75)', color: '#78717e' }
        }
    }

    getStyle = () => {
        let style = getObjectParam(this.state.style);
        let propsStyle = getObjectParam(this.props.style);
        style = { ...style, ...propsStyle };
        return style;
    }

    render() {
        return (
            <div style={this.getStyle()}>
                <i className="fa fa-circle-o-notch fa-spin"></i>
            </div>
        );
    }
}