
import React from 'react';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import Button from '@mui/material/Button';
import { FormControl, FormControlLabel, MenuItem, RadioGroup, Radio, Typography } from '@mui/material';
import { constants } from '../../../services/constants/constants';
import { isValidParam, getStringParam, getObjectParam, getBooleanParam, getIntParam } from '../../../services/helper/parameterVerifier';
import { getQuickBooksInfo, disconnect } from '../../../services/actions/quickBooksAction';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { openWindow } from '../../../services/helper/utils';
import { updateUserQbSetup } from '../../../services/actions/appActions';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as HTTPClient from '../../../services/helper/httpClient';

const QuickBooksIntegration = ({ details, connectionHandle, disconnectHandle }) => {
    let _attempts = 0;
    const dispatch = useDispatch();
    const [isShowConnectButton, setIsShowConnectButton] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [isConnecting, setIsConnecting] = useState(false);
    const [QBDetails, setQBDetails] = useState({});

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        try {
            if (details !== null && details !== undefined && details.is_connected) {
                setQBDetails({...details});
                setIsConnected(true);
                setIsShowConnectButton(false);
            } else {
                setQBDetails({});
                setIsConnected(false);
                setIsShowConnectButton(true);
            }
        } catch (error) {
            console.error("Error in 'quickBooksIntegration.js -> loadData()':" + error);
        }
    }

    
    const connectQB = () => {
        try {
            let url = constants.endPoint + endPoints.QUICKBOOKS_INTEGRATION.CONNECT + '?token=' + localStorage.getItem('token');
            let openQBWindow = openWindow({ location: url, spaces: 'scrollbars=1, location=no', width: 850, height: 650 });
            setIsConnecting(true);
            _attempts = 10;
            setTimeout(() => {
                checkIsConnected(openQBWindow);// This method will call after 20000 millisecond or 5 second
            }, 20000);//20000 millisecond = 5 second
        } catch (error) {
            console.error("Error in 'quickBooksIntegration.js -> connectQB()':" + error);
        }
    }

    const checkIsConnected = (openQBWindow) => {
        try {
            let refreshIntervalId = window.setInterval(() => {
                if (_attempts > 0) {
                    let promise = Promise.resolve(getQuickBooksInfo());
                    if (isValidParam(promise)) {
                        promise.then(response => {
                            _attempts--;
                            if (isValidParam(response) && getBooleanParam(response.is_connected)) {
                                clearInterval(refreshIntervalId);
                                dispatch(updateUserQbSetup(true));
                                connectionHandle();
                                _attempts = 0;
                                setQBDetails({...response});
                                setIsConnected(true);
                                setIsConnecting(false);
                                setIsShowConnectButton(false);
                            }
                        });
                    }
                } else if (_attempts === 0) {
                    openQBWindow.close();
                    clearInterval(refreshIntervalId);
                    if (isConnecting) {
                        _attempts = 0;
                        setQBDetails(null);
                        setIsConnecting(false);
                    }
                }
            }, 20000);// Above code will execute after every 20000 millisecond or 5 second 
        } catch (error) {
            console.error("Error in 'quickBooksIntegration.js -> checkIsConnected()':" + error);
        }
    }

    const disconnectQB = () => {
        try {
            let promise = Promise.resolve(disconnect());
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response) && response.status === 0 && getBooleanParam(response.data)) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DISCONNECT_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        dispatch(updateUserQbSetup(false));
                        disconnectHandle();
                        setQBDetails(null);
                        setIsConnected(false);
                        setIsShowConnectButton(true);
                    }
                })
            }
        } catch (error) {
            console.error("Error in 'quickBooksIntegration.js -> disconnectQB()':" + error);
        }
    }


    return <div>
        {/* <div style={{ borderBottom: '2.0px solid #afafaf5c' }}><span style={{ fontWeight: 'bold', fontSize: '20px' }}>{getLocalizedStrings().label.MY_INTEGRATION.QUICKBOOKS_INTEGRATION}</span></div> */}
        {isShowConnectButton && <div style={{ marginTop: '10px' }}>
            <div style={{ marginBottom: '10px' }}><span style={{ fontWeight: 'normal', fontSize: '14px' }}>{'To setup the integration, connect to your QuickBooks Online account'}</span></div>
            <Button style={styles.primaryButton} onClick={() => connectQB()} >{getLocalizedStrings().label.QUICKBOOKS.CONNECT_BUTTON}</Button>
        </div>}
        {isConnecting && <span style={{ width: '68%' }}>Connecting<img src='/asset/images/processing.gif' style={{ height: '10px', width: '50px' }} /></span>}
        {isConnected && <ConnectedView QBDetails={QBDetails} isConnected={isConnected} disconnectQB={() => disconnectQB()} />}
    </div>;

}
export default (QuickBooksIntegration);



const ConnectedView = ({ isConnected, QBDetails, disconnectQB }) => {

    const dispatch = useDispatch();
    const [isLoaded, setIsLoaded] = useState(true);
    const [PushCustomers, setIsPushCustomers] = useState(false);
    const [isPullInvoices, setIsPullInvoices] = useState(false);
    const [objects, setObjects] = useState({ accounts: 'Accounts', products: 'Products' });
    const [selectedObject, setSelectedObject] = useState('accounts');
    const [integrationType, setIntegrationType] = useState(null);
    const [integrationItem, setIntegrationItem] = useState(null);
    const [isInvoices, setIsInvoices] = useState(false);
    const [isPayments, setIsPayments] = useState(false);

    useEffect(() => {
        let tempIsPushCustomers = getBooleanParam(QBDetails.is_push_customers);
        let tempIsPullInvoices = getBooleanParam(QBDetails.is_pull_invoices);
        let tempIntegrationType = getStringParam(QBDetails.integration_type);
        let tempIsInvoices = getBooleanParam(QBDetails.is_invoices);
        let tempIsPayments = getBooleanParam(QBDetails.is_payments);
        let integration_item = null;
        if (isConnected) {
            setIsPushCustomers(tempIsPushCustomers);
            setIsPullInvoices(tempIsPullInvoices);
            setIntegrationType(tempIntegrationType);
            setIsInvoices(tempIsInvoices);
            setIsPayments(tempIsPayments);
            if (tempIsInvoices) {
                integration_item = "INVOICES";
            }
            if (tempIsPayments) {
                integration_item = "PAYMENTS";
            }
            setIntegrationItem(integration_item);
        }
    }, []);

   const  handleChange = (selectedOption) => {
        setIntegrationType(selectedOption);
    }

    const handleItemIntegration = ( value) => {
        if (value == "INVOICES") {
            setIsInvoices(true);
            setIsPayments(false);
        } else {
            setIsInvoices(false);
            setIsPayments(true);
        }
        setIntegrationItem(value);
    }


    const quickBooksIntegrationSave = () => {
        let data = new Object;
        //data.is_chart_of_accounts = this.state.isChartOfAccounts;
       // data.selected_chart_of_accounts = this.state.groupedSelectedChartData;
        data.integration_type = integrationType;
        data.is_invoices = isInvoices;
        data.is_payments = isPayments;
        let url = endPoints.QUICKBOOKS_INTEGRATION.UPDATE_DETAILS;
        if (integrationType === null) {
            dispatch(showCustomSnackBar("Please select one synchronize type.", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else if (integrationType!= null && integrationType!== "MANUALLY" && isInvoices === false &&  isPayments === false ) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.XERO.SELECT_ONE_INTEGRATION_ITEM, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else {
            let promise = Promise.resolve(HTTPClient.post(url, data));
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.status == 0) {
                        let details = new Object;
                        //details.is_chart_of_accounts = this.state.isChartOfAccounts;
                        //details.selectedChartOfAccounts = this.state.selectedChartOfAccounts;
                        details.integration_type = integrationType;
                        details.is_invoices= isInvoices;
                        details.is_payments= isPayments;
                        dispatch(updateUserQbSetup(false));
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    }
                }
            });
        }
    }

    /*const syncNow = () => {
        let data = new Object;
        data.integration_type = integrationType;
        let url = endPoints.QUICKBOOKS_INTEGRATION.SYNC_NOW;
        if (integrationType=== '') {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.XERO.SELECT_ONE_INTEGRATION_ITEM, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else {
            let promise = Promise.resolve(HTTPClient.post(url, data));
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.status == 0) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SYNC_DONE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    }
                }
            });
        }
    }*/


    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight - 10) / 2;
    let objectsMenuItems = null;
    objectsMenuItems = Object.keys(objects).map(key => {
        return <MenuItem key={key} value={key} style={styles.popoverMenuItem} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '162px' }} title={objects[key]}>{objects[key]}</div>}</MenuItem>
    });

    let details = getObjectParam(QBDetails);
    return <div style={{ marginTop: '10px' }}>
        {!isLoaded && <div style={{ width: '100%', height: pageHeight }}>
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
        </div>}
        {isLoaded && <div>
            <div>
                <div style={{ padding: '8px', fontSize: '15px', height: '40px', background: 'rgb(227, 230, 232)', marginRight: '-4%' }}>
                    You have connected as {getStringParam(details.email)}

                    <Button
                        style={{ ...styles.secondaryButton, float: 'right' }}
                        onClick={() => disconnectQB()}
                    >{getLocalizedStrings().label.DOCUSIGN.DISCONNECT_BUTTON}</Button></div>
            </div>

            <div style={{ paddingTop: '15px' }}>
                    <Typography variant="subtitle1">{getLocalizedStrings().label.MY_INTEGRATION.SYNCHRONIZE}</Typography>
                    <FormControl component="fieldset" >
                        <RadioGroup aria-label={getLocalizedStrings().label.MY_INTEGRATION.SYNCHRONIZE} name="integration_type" value={integrationType} onChange={(e) => handleChange(e.target.value)}>
                            <FormControlLabel value="MANUALLY" control={<Radio color='default' />} label={getLocalizedStrings().label.MY_INTEGRATION.QB_MANNUALLY} />
                            <FormControlLabel value="AUTOMATICALLY" control={<Radio color='default' />} label={getLocalizedStrings().label.MY_INTEGRATION.QB_AUTOMATICALLY} />
                        </RadioGroup>
                    </FormControl>
                    {integrationType === "AUTOMATICALLY" && <div style={{ paddingLeft: '25px' }}>
                        <FormControl component="fieldset" >
                            <RadioGroup aria-label={getLocalizedStrings().label.MY_INTEGRATION.INTEGRATE} name="integration_item" value={integrationItem} onChange={(e) => handleItemIntegration(e.target.value)}>
                                <FormControlLabel value="INVOICES" control={<Radio color='default' />} label={getLocalizedStrings().label.MY_INTEGRATION.INVOICES + ' ' + getLocalizedStrings().label.COMMON.AMPERSAND + ' ' + getLocalizedStrings().label.MY_INTEGRATION.REFUNDS} />
                                <FormControlLabel value="PAYMENTS" control={<Radio color='default' />} label={getLocalizedStrings().label.MY_INTEGRATION.PAYMENTS + ' ' + getLocalizedStrings().label.COMMON.AMPERSAND + ' ' + getLocalizedStrings().label.MY_INTEGRATION.REFUNDS} />
                            </RadioGroup>
                        </FormControl>
                    </div>}
                </div>
                
                <div style={{ textAlign: 'right', display: 'table', width: '100%' }}>
                    <div style={{ display: 'inline-block',  paddingTop: '15px' }} >
                        <Button
                            style={{ ...styles.primaryButton, float: 'right' }}
                            onClick={() => quickBooksIntegrationSave() }
                        >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                    </div>
                    {/* <div style={{ display: 'inline-block', paddingTop: '15px' }}>
                        <Button
                            style={{ ...styles.primaryButton, float: 'right' }}
                            onClick={() => { this.syncNow(); }}
                        >{getLocalizedStrings().label.COMMON.SYNC_NOW}</Button>
                    </div> */}
                </div>
        </div>}

    </div>

}


