import React from 'react';
import { connect } from 'react-redux';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styles } from '../../../services/constants/styles';
import PopOver from '../components/PopOver';
import {Menu,  Select, MenuItem,InputLabel,Icon,FormControl} from '@mui/material';
import Button from '@mui/material/Button';
import { isValidParam, getBooleanParam, getArrayParam, getIntParam, getObjectParam, isInteger, getStringParam } from '../../../services/helper/parameterVerifier';
import { OBJECT_TABLEID_MAP, constants } from '../../../services/constants/constants';
import * as SFFormActions from '../../../services/actions/sfFormActions';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getUniqueId } from '../../../services/helper/utils';
import { setHeader } from '../../../services/actions/headerActions';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { identity } from 'lodash';
import { alpha, styled } from '@mui/material/styles';


const ToggleSwitch  = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#424242",
      '&:hover': {
        backgroundColor: '#2c2c2c1f',
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#424242",
    },
  }));

const NotificationReminders=(object)=>{

    const dispatch = useDispatch();
    const [toggleValue, setToggleValue] = useState(false);

    const [isEmailOpen, setIsEmailOpen] = useState(null);
    const [isEmailClick, setIsEmailClick] = useState(null);
    const [isTextReply, setIsTextReply] = useState(null);
    const [isGreenlightEmail, setIsGreenlightEmail] = useState(null);
    const [isWebFormSubmitted, setIsWebFormSubmitted] = useState(null);
    const [isUpcomingAppointment, setIsUpcomingAppointment] = useState(null);
    const [isUpcomingTask, setIsUpcomingTask] = useState(null);
    const [customReminder, setCustomReminder] = useState([]);
    const [reminders, setReminders] = useState([]);
    const [objectList, setObjectList] = useState([]);
    const [object_names, setObject_names] = useState('');
    const [object_id, setObject_id] = useState(0);
    const [remind_on, setRemind_on] = useState('');
    const [remind_on_id, setRemind_on_id] = useState('');
    const [remind_on_popup, setRemind_on_popup] = useState(false);
    const [object_names_popup, setObject_names_popup] = useState(false);
    const [objectMenuList, setObjectMenuList] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [anchorEl, setAnchorEl] = useState('');
    const [shouldRestrict, setShouldRestrict] = useState(false);
    const [changeLog, setChangeLog] = useState({name:null,value:null});
    const [tempReminderData, setTempReminderData] = useState({});
    const [dummyData,setDummyData] = useState(false);
    const [rowData, setRowData]= useState(null);

    const appState = useSelector((state) => state.app);
    const sfForm = useSelector((state) => state.sfForm);

    useEffect(() => {
        dispatch(setHeader(object, getLocalizedStrings().label.NOTIFICATION_REMINDERS.NOTIFICATION_REMINDERS, null, false, true));
        getNotificationsReminders();
        let objectList = getArrayParam(appState.objectList).filter((obj) => { return obj.homeId > 0 });

        let isServiceEnable = getBooleanParam(appState.me.is_service);
        let options = objectList.map(m => { return { id: OBJECT_TABLEID_MAP[m.name], name: m.name, label: m.label } });
        options = options.filter(obj => {
            return obj.id === OBJECT_TABLEID_MAP[constants.ACCOUNTS_OBJECT] ||
                obj.id === OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT] ||
                obj.id === OBJECT_TABLEID_MAP[constants.OPPORTUNITIES_OBJECT] ||
                obj.id === OBJECT_TABLEID_MAP[constants.CASES_OBJECT] ||
                obj.id === OBJECT_TABLEID_MAP[constants.PROJECTS_OBJECT] ||
                obj.id === OBJECT_TABLEID_MAP[constants.CUSTOM_TABLE1_OBJECT] ||
                obj.id === OBJECT_TABLEID_MAP[constants.CUSTOM_TABLE2_OBJECT] ||
                obj.id === OBJECT_TABLEID_MAP[constants.CUSTOM_TABLE3_OBJECT] ||
                obj.id === OBJECT_TABLEID_MAP[constants.CUSTOM_TABLE4_OBJECT]
        });
        if (!isServiceEnable) {
            options = options.filter((obj) => {
                return obj.id !== OBJECT_TABLEID_MAP[constants.CASES_OBJECT] &&
                    obj.id !== OBJECT_TABLEID_MAP[constants.PROJECTS_OBJECT]
            });
        }
        let objectMenuList = generateObjectSelectionField(options);
        setObjectList(options);
        setObjectMenuList(objectMenuList);
    }, [object]);

    useEffect(()=>{
        let addRowElement = rowElement.map((row, index) => { return getAddRemidersRows(row, index) });
        setRowData(addRowElement);
    },[rowData])

    const getNotificationsReminders = () => {
            let url = endPoints.NOTIFICATIONS_REMINDER.GET;
            let promise = Promise.resolve(HTTPClient.get(url, null));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let sysReminder = getObjectParam(response.sys_remind);
                    let isEmailOpen = getBooleanParam(sysReminder.is_email_open);
                    let isEmailClick = getBooleanParam(sysReminder.is_email_click);
                    let isTextReply = getBooleanParam(sysReminder.is_text_replied);
                    let isGreenlightEmail = getBooleanParam(sysReminder.is_greenlight_email);
                    let isWebFormSubmitted = getBooleanParam(sysReminder.is_form_submitted);
                    let appointment = getBooleanParam(sysReminder.is_appointments);
                    let isTask = getBooleanParam(sysReminder.is_tasks);
                    let customReminderTemp = getArrayParam(response.custom_remind);
                    let reminders = setObjectName(customReminderTemp);
                    setIsEmailOpen(isEmailOpen);
                    setIsEmailClick(isEmailClick);
                    setIsTextReply(isTextReply);
                    setIsWebFormSubmitted(isWebFormSubmitted);
                    setIsUpcomingAppointment(appointment);
                    setIsUpcomingTask(isTask);
                    setReminders(reminders);
                    setIsSaving(false);
                    setIsGreenlightEmail(isGreenlightEmail);
                }
            });
    }
    const setObjectName = (customReminder) => {
            let objectList = getArrayParam(appState.objectList);
            let options = {};
            objectList.map(m => {
                options[OBJECT_TABLEID_MAP[m.name]] = { id: OBJECT_TABLEID_MAP[m.name], name: m.name, label: m.label };
            });

            customReminder.map((row, index) => {
                let tempObject = options[row.object_id];
                customReminder[index].object_name = tempObject.name;
                customReminder[index].object_label = tempObject.label;
                customReminder[index].object_id = tempObject.id;
            })
        return customReminder;
    }
    const generateObjectSelectionField=(options)=> {
        let objectListObj;
            objectListObj = options.map((item, index) => {
                return <MenuItem key={item.id} value={item.id}  style={styles.popoverMenuItem} >{item.label}</MenuItem>;
            });
        return objectListObj;
    }
    const handleToggle = (isInputChecked, name) => {
            if(name ==='isEmailOpen'){
                setIsEmailOpen(isInputChecked);
            } else if(name ==='isEmailClick'){
                setIsEmailClick(isInputChecked);
            } else if(name ==='isTextReply'){
                setIsTextReply(isInputChecked);   
            }else if(name === 'isUpcomingAppointment'){
                setIsUpcomingAppointment(isInputChecked);
            } else if(name === 'isUpcomingTask'){
                setIsUpcomingTask(isInputChecked);
            } else if(name === 'isGreenlightEmail'){
                setIsGreenlightEmail(isInputChecked);
            }
        }

    const handleCustomRemiderToggle = (event,rowId) => {
        let actiontype = event.target.checked;
        let tempReminders = getArrayParam(reminders);
        tempReminders.map((row)=>{
            if(row.status !== 'new' && row.id === rowId){
                row.status = actiontype ? 'Active' : 'Inactive';
            }
        })
        if(tempReminders > 0){
            setShouldRestrict(true);
        }
        setReminders(tempReminders);
        setDummyData(true);
        setChangeLog({...changeLog,name:rowId,value:actiontype})
        
    }

    const getcustomremiderLabel = (day, obj, remindOn) => {

        let labelVal = getLocalizedStrings().label.NOTIFICATION_REMINDERS.REMIND + " " + day
            + " " + getLocalizedStrings().label.NOTIFICATION_REMINDERS.DAY_BEFORE + " " + obj + " " + remindOn;
        return labelVal;
    }
    const getCustomRemindersElements = (row, index) => {
        let elementName = "custom_remind_" + index;
        let defaultToggled = false;
        if (row.status == 'Active' || row.status == 'active') {
            defaultToggled = true;
        }
        let rowElement =
            <div className="row" name={"custom_remind-div" + index}  style={{marginLeft: '-40px',display:'flex'}} key={"custom-remind-div" + index} id={"custom-remind-div" + index} >
                <div className='col-sm-6'>
                    <FormGroup>
                        <FormControlLabel
                            control={<ToggleSwitch checked={defaultToggled} value={defaultToggled} onChange={(event)=>handleCustomRemiderToggle(event,row.id)} color="default" />}
                            label={getcustomremiderLabel(row.remind_day, row.object_label, row.field_label)} style={{ paddingLeft: '10px' }}
                        />
                    </FormGroup>
                </div>
                <div style={{width : '20px',marginTop:'10px'}}>
                    <span style={{ fontSize: 14,   cursor: "pointer", paddingTop: '12px' }}> <i className="fa fa-times" name={elementName} onClick={()=>deleteCustomReminders(row.id)} title={getLocalizedStrings().label.COMMON.DELETE} ></i></span>
               </div>
            </div>;
        return rowElement;
    }
    const deleteRow = (event,id) => {
            let tempReminders = getArrayParam(reminders);
            tempReminders = tempReminders.filter((obj, index) => { return obj.id !== id });
            setReminders(tempReminders);
        }
   
   const deleteCustomReminders = (id) => {
       
            let _reminders = getArrayParam(reminders);
            _reminders.forEach((row, index) => {
                if (row.status !== 'new' && row.id === id) {
                    row.status = 'Deleted';
                }
            });
            setReminders([..._reminders]);
        
    }
    const getShowAddReminder = () => {
        let rows = getArrayParam(reminders);
        let remindersRows = rows.filter((row, index) => { return row.status !== 'Deleted'; });
        return remindersRows.length < 5 ? true : false;
    }
    const addRow = (event) => {
            let tempObjectList = getArrayParam(objectList);
            let rowId = 'new-row-' + getUniqueId();
            let objectName = tempObjectList[0].name;
            let dataRow = getArrayParam(reminders);
            let length = dataRow.filter((row, index) => { return row.status !== 'Deleted'; }).length;

            if (length < 5) {
                let rowObj = {
                    id: rowId,
                    remind_day: 1,
                    object_id: tempObjectList[0].id,
                    object_name: objectName,
                    object_label: tempObjectList[0].label,
                    field_id: 0,
                    field_name: '',
                    field_label: '',
                    field_type_id: 0,
                    status: 'new',
                    isOpenObjectPopover: false,
                    isOpenReminderPopover: false,
                    reminderFields: null,

                }
                dataRow.push(rowObj);
                setReminders({...dataRow});
                setTempReminderData(dataRow);
                onObjectFieldChange(rowId, objectName);

            }
    }
    const validateData = () => {
        let isError = false;
        let errorMessage = '';
        let isValid = true;
            let tempReminders = getArrayParam(reminders);
            let rowElement = tempReminders.filter((row) => { return row.status === 'new'; });
            rowElement.some(row => {
                let dayVal = row.remind_day;
                let field_name = row.field_name
                if ((dayVal === 'undefined' && !isInteger(dayVal)) || getIntParam(dayVal) <= 0) {
                    errorMessage = getLocalizedStrings().message.NOTIFICATION_REMINDERS.INVALID_DAY;
                    isError = true;
                    return true;
                }
                if (field_name === 'undefined' || getStringParam(field_name) === '') {
                    errorMessage = getLocalizedStrings().message.NOTIFICATION_REMINDERS.INVALID_DATE_FIELD;
                    isError = true;
                    return true;
                }
                isValid = isValidReminder(row, true);
                if (!isValid) {
                    return true;
                }
            });

            if (!isValid) {
                isError = true;
                errorMessage = getLocalizedStrings().message.NOTIFICATION_REMINDERS.DUPLICATE_REMINDER;
            }

            if (isError) {
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }

        return isError
    }
    const handleDropDownChange = (event,name, rowId) => {
        let isValid = true;
        let id = event.target.value;
            let tempReminders = getArrayParam(reminders);
            let options = getArrayParam(objectList);
            tempReminders.forEach(row => {
                if (row.id === rowId && name === "object_names") {
                    let object = options.filter((obj) => { return obj.id === id });
                    row.object_label = object[0].label;
                    row.object_name = object[0].name;
                    row.object_id = object[0].id;
                    row.isOpenObjectPopover = false;

                    row.isOpenReminderPopover = false;

                    onObjectFieldChange(rowId, object[0].name);
                } else if (row.id === rowId && name === "remind_on") {
                    let tempReminders = row.reminderFields;
                    let fields = tempReminders.filter((obj) => { return obj.id === id });
                    let temRow = { ...row }
                    temRow.field_id = fields[0].id;
                    temRow.field_name = fields[0].name;
                    temRow.field_label = fields[0].label;
                    temRow.field_type_id = fields[0].field_type_id;
                    isValid = isValidReminder(temRow);
                    if (isValid) {
                        row.field_id = fields[0].id;
                        row.field_name = fields[0].name;
                        row.field_label = fields[0].label;
                        row.field_type_id = fields[0].field_type_id;
                    }
                    row.isOpenReminderPopover = false;
                }
            });
            setReminders([...tempReminders]);
            setTempReminderData(null);

            if (!isValid) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.NOTIFICATION_REMINDERS.DUPLICATE_REMINDER, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
    }
    const isValidReminder = (row, isOnBlur) => {
        let isValid = true;
            if (isValidParam) {
                isOnBlur = getBooleanParam(isOnBlur);
                let tempReminders = getArrayParam(reminders);
                tempReminders = tempReminders.filter((row, index) => { return row.status !== 'Deleted'; });

                let reminderDay = getIntParam(row.remind_day);
                let objectId = getIntParam(row.object_id);
                let fieldName = getStringParam(row.field_name);

                let arr = tempReminders.filter(f => { return f.field_name === fieldName && f.object_id === objectId && row.status !== 'Deleted' });

                if (isOnBlur) {
                    if (arr.length > 1) {
                        isValid = false;
                    }
                } else if (arr.length > 0) {
                    isValid = false;
                }
            }
        return isValid;
    }
    const handleDayChange = (rowId, event) => {
            let tempReminders = getArrayParam(reminders);
            tempReminders.forEach(row => {
                if (row.status === 'new' && row.id === rowId) {
                    row.remind_day = event.target.value;
                }
            });
            setReminders(tempReminders);
    }
    const onObjectFieldChange = (rowId, object) => {
            let data = Object.assign({}, sfForm.data[object]);
            if (Object.keys(data).length === 0) {
                getFormData(rowId, object);
            } else {
                data = getObjectParam(data);
                data.fields = getArrayParam(data.fields);
                populateReminderFiels(rowId, data);
            }
    }
    const getFormData = (rowId, object) => {
            var promise = SFFormActions.getSFFormFieldsPromise(object);
            promise.then((response) => {
                if (isValidParam(response)) {
                    sfForm.data[object] = Object.assign({}, response);
                    populateReminderFiels(rowId, response);
                }
            });
    }
    const populateReminderFiels = (rowId, data) => {
        let fields = getArrayParam(data.fields);
        let tempReminders = getArrayParam(reminders);
        fields = fields.filter(f => {
            return (f.field_type === constants.FIELD_TYPE_DATE
                || f.field_type === constants.FIELD_TYPE_DATE_TIME)
                && f.name !== 'created_on' && f.name !== 'updated_on' && f.name !== 'permission_date'
        }).map(m => { return { name: m.name, id: m.id, label: m.label, field_type_id: m.field_type_id } });

        let sortFields = fields.sort((obj1, obj2) => { return (obj1.label > obj2.label) ? 1 : -1 });

        tempReminders.map(row => {
            if (row.id === rowId) {
                if (sortFields.length > 0) {
                    row.reminderFields = sortFields;
                    row.field_id = getIntParam(sortFields[0].id);
                    row.field_name = getStringParam(sortFields[0].name);
                    row.field_label = getStringParam(sortFields[0].label);
                    row.field_type_id = getIntParam(sortFields[0].field_type_id);
                } else {
                    row.reminderFields = null;
                    row.field_id = 0;
                    row.field_name = '';
                    row.field_label = getLocalizedStrings().label.NOTIFICATION_REMINDERS.NO_DATE_FIELD;
                    row.field_type_id = 0;
                }
            }
        });
        
        setReminders(tempReminders);
        setTempReminderData(tempReminders);
    }
    const getReminderItems =(reminderFields)=> {
        let tempFieldElements;
        tempFieldElements = getArrayParam(reminderFields);
        tempFieldElements = tempFieldElements.map((item, index) => {
                return <MenuItem key={item.id} value={item.id}  style={styles.popoverMenuItem} >{item.label}</MenuItem>;
            });
        return tempFieldElements;
    }
    const save = () => {
        let param = {};
        let url = endPoints.NOTIFICATIONS_REMINDER.SAVE;
        let isError = false;
        let successMsg = '';
        isError = validateData();
            if (!isError) {
                setIsSaving(true);
                let tempReminders = getArrayParam(reminders);
                let deleteReminders = tempReminders.filter(f => { return f.status === 'Deleted' });
                let newReminders = tempReminders.filter(f => { return f.status === 'new' });
                if (newReminders.length > 0) {
                    successMsg = getLocalizedStrings().message.NOTIFICATION_REMINDERS.SAVE_SUCCESS;
                } else if (newReminders.length === 0 && deleteReminders.length > 0) {
                    successMsg = getLocalizedStrings().message.NOTIFICATION_REMINDERS.DELETE_SUCCESS;
                } else {
                    successMsg = getLocalizedStrings().message.NOTIFICATION_REMINDERS.UPDATE_SUCCESS;
                }

                let remindersData = tempReminders.map(r => {
                    if (r.status === 'new') {
                        r.id = 0;
                    }
                    delete r.isOpenObjectPopover;
                    delete r.isOpenReminderPopover;
                    delete r.object_name;
                    return { ...r };
                });

                param.is_email_open = getBooleanParam(isEmailOpen);
                param.is_email_click = getBooleanParam(isEmailClick);
                param.is_text_reply = getBooleanParam(isTextReply);
                param.is_greenlight_email = getBooleanParam(isGreenlightEmail);
                param.is_webfrom_submitted = getBooleanParam(isWebFormSubmitted);
                param.is_appointment = getBooleanParam(isUpcomingAppointment);
                param.is_task = getBooleanParam(isUpcomingTask);
                param.reminders = remindersData;

                let promise = Promise.resolve(HTTPClient.post(url, param));
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (response.status === 0) {
                            dispatch(showCustomSnackBar(successMsg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            getNotificationsReminders();

                        }
                    }
                });
            }
    }
    const setDayValue = (rowId, event) => {
        let tempReminders = getArrayParam(reminders);
        tempReminders.forEach(row => {
            if (row.status === 'new' && row.id === rowId) {
                row.remind_day = getIntParam(event.target.value);
            }
        });
        setReminders(tempReminders);
        setTempReminderData(null);
    }
    const getAddRemidersRows = (row, index) => {
        let sfFormFieldContainerStyle = { ...styles.sfFormFieldContainer };
        let id = index;
        let elementName = "add_row_" + id;
        let inputTextStyle = { width: '40px', height: '35px', marginLeft: '10px', float: 'left', textAlign: 'center' };
        let fieldcontainerStyle = { width: '100%', cursor: "pointer", fontSize: '14px', color: '#717171', height: '35px', padding: 5, }
        let newElement =
            <div name={elementName} className="row" key={"add-row-div-conatiner-" + index} id={elementName} >
                <div style={{ width: '100%', marginTop: '20px', fontWeight: 'normal' }} name='add_reminder' id="add_reminder01">
                    <span style={{ float: 'left', paddingTop: 6 }}>{getLocalizedStrings().label.NOTIFICATION_REMINDERS.REMIND}</span>
                    <input
                        type='text'
                        style={inputTextStyle}
                        defaultValue={row.remind_day}
                        onChange={(event)=>handleDayChange(row.id,event)}
                        onBlur={(event)=>setDayValue(row.id,event)}
                        inputprops={{maxLength:3}}
                    />
                    <span style={{ marginLeft: '10px', float: 'left', paddingTop: 6 }}>{getLocalizedStrings().label.NOTIFICATION_REMINDERS.DAY_BEFORE}</span>
                    <div style={{ width: '20%', float: 'left', marginLeft: '10px', marginRight: '15px' ,marginTop:'-9px'}} >
                        <div
                            style={{ ...sfFormFieldContainerStyle, ...fieldcontainerStyle }}
                            id={'object_names-div' + id}
                            key={'object_names-div' + id}
                        >

                            <FormControl variant="outlined">
                                <Select
                                    labelId="sf-notificationreminders-simple-select-outlined-label"
                                    id="sf-notificationreminders-result-simple-select-outlined"
                                    defaultValue={row.object_id}
                                    style={{width:'184px', height:'42px'}}
                                    className={"sf-fields-bg"}
                                    onChange={(event)=>handleDropDownChange(event,"object_names", row.id)}
                                >
                                    {objectMenuList}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div style={{ width: '20%', float: 'left', marginRight: '5px',marginTop:'-9px' }} >
                        <div
                            style={{ ...sfFormFieldContainerStyle, width: '100%', cursor: "pointer", fontSize: 14, color: '#717171', height: '35px', padding: 5, }}

                            key={"remind_on-div" + id}
                            name={"remind_on-div" + id}
                        >
                            <FormControl variant="outlined">
                                <Select
                                    labelId="sf-notificationreminders-simple-select-outlined-label"
                                    id="sf-notificationreminders-result-simple-select-outlined"
                                    value={(row.reminderFields !== null)?row.field_id: getLocalizedStrings().label.NOTIFICATION_REMINDERS.NO_DATE_FIELD}
                                    style={{width:'195px', height:'42px'}}
                                    className={"sf-fields-bg"}
                                    disabled={row.reminderFields === null}
                                    onChange={(event)=>handleDropDownChange(event,"remind_on", row.id)}
                                >
                                    {row.reminderFields!=null  && getReminderItems(row.reminderFields)}
                                    
                                    {row.reminderFields==null && <MenuItem key={row.id} value={getLocalizedStrings().label.NOTIFICATION_REMINDERS.NO_DATE_FIELD}  style={styles.popoverMenuItem} >{getLocalizedStrings().label.NOTIFICATION_REMINDERS.NO_DATE_FIELD}</MenuItem>}

                                </Select>
                            </FormControl>

                        </div>
                    </div>
                    <div style={{ fontSize: '20px', float: 'left', cursor: "pointer", paddingTop: '5px' }}> <i className="fa fa-times" name={elementName} onClick={(event)=>deleteRow(event,row.id)} title={getLocalizedStrings().label.COMMON.DELETE} ></i></div>
                </div>
            </div>
        return newElement;
    }
    const getActionButtons = () => {
        let buttons = [];
        buttons.push(
            <Button
                key='save'
                onClick={()=>save()}
               
                style={{ ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top' }}
                disabled={isSaving}
            >{getLocalizedStrings().label.COMMON.SAVE}</Button>
        );
        return buttons;
    }
    let togglleLabelStyle = { float: 'left', paddingLeft: '10px', };
    let columStyle = { paddingLeft: 20 };
    let rowStyle = { paddingLeft: '-10px' }
    let tempReminders = getArrayParam(reminders);
    let rowElement = tempReminders.filter((row) => { return row.status === 'new'; });
    let customRemider = tempReminders.filter((row) => { return row.status === 'Active' || row.status === 'Inactive'; });
    let isClickAddReminder = getShowAddReminder();

    // let addRowElement = rowElement.map((row, index) => { return getAddRemidersRows(row, index) });
    let addRowElement = rowData;
    let labelObj = customRemider.map((row, index) => { return getCustomRemindersElements(row, index); });
   
    return(
        <div style={{ paddingLeft: '5%', width: '95%' }}>
                {shouldRestrict ? handleCustomRemiderToggle() : '' }
            <div className="row" style={{ paddingBottom: '14px' }}>
                <div id="header" style={{ width: '100%', fontSize: '24px', paddingBottom: '12px' }}>{getLocalizedStrings().label.NOTIFICATION_REMINDERS.NOTIFY_ME}</div>
            </div>
            <div className="row" style={{ marginBottom: '10', marginLeft: '-25px' }}>
                <div className="col-sm-4" style={{ paddingLeft: '0px' }} >
                <FormGroup>
                    <FormControlLabel
                        control={<ToggleSwitch checked={isEmailOpen} value={isEmailOpen} onChange={(e)=>handleToggle(e.target.checked, 'isEmailOpen')} color="default" name="isEmailOpen" />}
                        label={getLocalizedStrings().label.NOTIFICATION_REMINDERS.NOTIFY_EMAIL_OPEN}
                        style={togglleLabelStyle}
                    />
                </FormGroup>
                </div>
                <div className="col-sm-4" style={columStyle} >
                <FormGroup>
                <FormControlLabel
                        control={<ToggleSwitch checked={isEmailClick} value={isEmailClick} onChange={(e)=>handleToggle(e.target.checked, 'isEmailClick')} color="default" name="isEmailClick" />}
                        label={getLocalizedStrings().label.NOTIFICATION_REMINDERS.NOTIFY_EMAIL_CLICK}
                        style={togglleLabelStyle}
                />
                </FormGroup>
                </div>
                <div className="col-sm-4" style={columStyle} >
                <FormGroup>
                <FormControlLabel
                        control={<ToggleSwitch checked={isTextReply} value={isTextReply}onChange={(e)=>handleToggle(e.target.checked, 'isTextReply')} color="default"name="isTextReply" />}
                        label={getLocalizedStrings().label.NOTIFICATION_REMINDERS.NOTIFY_TEXT_REPLY}
                        style={togglleLabelStyle}
                    />
                </FormGroup>
                </div>
                </div>
                { appState.me.industry_type =="InXpressFranchise" && 
                <div className="row" style={{ marginBottom: '20px', marginLeft: '-45px' }}>
                    <div className="col-sm-4" style={columStyle} >
                    <FormGroup>
                    <FormControlLabel
                        control={<ToggleSwitch checked={isGreenlightEmail} value={isGreenlightEmail}onChange={(e)=>handleToggle(e.target.checked, 'isGreenlightEmail')} color="default" name="isGreenlightEmail" />}
                        label={'Greenlight Email'}
                        style={togglleLabelStyle}
                    />
                    </FormGroup> 
                    </div>
                </div>
                }
            

            <div className="row" style={{ paddingBottom: '14px' }} >
                <div id="header" style={{ width: '100%', fontSize: '24px', paddingBottom: '12px' }}>{getLocalizedStrings().label.NOTIFICATION_REMINDERS.REMIND_ME}</div>
            </div>

            <div className="row" style={{ marginTop: '-13px', marginLeft: '-25px' }}>
                <div className="col-sm-4" style={{ paddingLeft: '0px' }} >
                <FormGroup>
                <FormControlLabel
                        control={<ToggleSwitch checked={isUpcomingAppointment} value={isUpcomingAppointment} onChange={(e)=>handleToggle(e.target.checked, 'isUpcomingAppointment')} color="default" name="isUpcomingAppointment" />}
                        label={getLocalizedStrings().label.NOTIFICATION_REMINDERS.REMIND_UPCOMING_APPOINTMENT}
                        style={togglleLabelStyle}
                    />
                </FormGroup>
                </div>
                <div className="col-sm-4" style={columStyle} >
                <FormGroup>
                <FormControlLabel
                        control={<ToggleSwitch checked={isUpcomingTask} value={isUpcomingTask} onChange={(e)=>handleToggle(e.target.checked, 'isUpcomingTask')} color="default"  name="isUpcomingTask" />}
                        label={getLocalizedStrings().label.NOTIFICATION_REMINDERS.REMIND_UPCOMING_TASK}
                        style={togglleLabelStyle}
                    />
                </FormGroup>
                </div>
            </div>
            <div>{labelObj}</div>     
            <div>{addRowElement}</div>

            {isClickAddReminder &&
                <div className="row" >
                    <div style={{ width: '100%', marginTop: '20px', fontWeight: 'normal',fontSize:"14px" }} name='add_reminder_label' id="add_reminder_label">
                        <span><a href='/#' onClick={(event)=>addRow(event)} >{getLocalizedStrings().label.NOTIFICATION_REMINDERS.ADD_NEW_REMINDER}</a></span>
                    </div>
                </div>
            }
            <div className="row" style={{ marginBottom: '25px', marginTop: '25px' }}  >
                <div className="col-sm-12" style={{ textAlign: 'right' }}>
                    {getActionButtons()}
                </div>
            </div>
        </div>
    )

}
export default  NotificationReminders;
