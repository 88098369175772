import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNode, get, setJSPlumbInst, setMounted, setTemplate, } from "../../../services/actions/automationCampaignActions";
import { styles } from "../../../services/constants/styles";
import ShowCircularProgress from "../components/circularProgress";
import AutomationHeader from "../components/automationHeader";
import AutomationSidebar from "../components/automationSidebar";
import AutomationBuilder from "../components/automationBuilder";
import { setHeader } from "../../../services/actions/headerActions";
import { isValidParam } from "../../../services/helper/parameterVerifier";
import { Navigate } from "react-router-dom";
import { LinkToType } from "../../../services/constants/link";
import { constants } from "../../../services/constants/constants";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { jsPlumb } from "jsplumb";

const settings = {
	endpointType: "Dot",
	endpointRadius: 4,
	endpointFillStyle: "#bdbdbd",
	hoverPaintStrokeStyle: "#bdbdbd",
	hoverPaintLineWidth: 4,
	paintStrokeStyle: "#bdbdbd",
	paintLineWidth: 2,
	connectorType: "StateMachine",
	connectorMargin: 0,
	connectorCurviness: 1,
	connectorGap: 0,
	connectorCornerRadius: 5,
	connectorStrokeStyle: "#bdbdbd",
	connectorLineWidth: 1,
	connectorOutlineColor: "transparent",
	connectorOutlineWidth: 0,
	connectionOverlaysType: "Arrow",
	connectionOverlaysLocation: 0.97,
	connectionOverlaysId: "arrow",
	connectionOverlaysLength: 10,
	connectionOverlaysFoldback: 0.7,
	anchorsTopCenter: "TopCenter",
	anchorsBottomCenter: "BottomCenter",
};

const AutomationDesigner = (props) => {
	const { id, action } = props;
	const dispatch = useDispatch()
	const automationCampaign = useSelector((state) => state.automationCampaign)
	const automationCampaignTemplate = useSelector((state) => state.automationCampaignTemplate)
	const [btnAction, setBtnAction] = useState(null);
	const [redirectUrl, setRedirectUrl] = useState('');
	const [isRedirect, setIsRedirect] = useState(false);
	const container = useRef(null)

	const importJsplumbDefaults = {
		Container: container.current,
		Anchors: [settings.anchorsTopCenter, settings.anchorsBottomCenter],
		ConnectionsDetachable: false,
		Endpoint: [
			settings.endpointType,
			{
				radius: settings.endpointRadius,
			},
		],
		EndpointStyle: {
			fill: settings.endpointFillStyle,
		},
		HoverPaintStyle: {
			stroke: settings.hoverPaintStrokeStyle
		},
		PaintStyle: {
			stroke: settings.paintStrokeStyle,
			strokeWidth: 2
		},
		Connector: [
			settings.connectorType,
			{
				margin: settings.connectorMargin,
				curviness: settings.connectorCurviness,
				gap: settings.connectorGap,
				cornerRadius: settings.connectorCornerRadius,
			},
		],
		ConnectionOverlays: [
			[
				settings.connectionOverlaysType,
				{
					location: settings.connectionOverlaysLocation,
					id: settings.connectionOverlaysId,
					length: settings.connectionOverlaysLength,
					foldback: settings.connectionOverlaysFoldback,
				},
			],
		],
	}

	useEffect(() => {
		if (automationCampaign.jsPlumbInst === null) {
			const jsPlumbInstance = jsPlumb.getInstance(importJsplumbDefaults);
			dispatch(setJSPlumbInst(jsPlumbInstance));
		}
		if (id > 0) {
			dispatch(setMounted(false));
			dispatch(get(id));
			setBtnAction(action)
		} else {
			if (isValidParam(automationCampaignTemplate.data.templates)) {
				let templateData = automationCampaignTemplate.data.templates[0];
				dispatch(setTemplate(templateData));
			} else if (!isValidParam(id)) {
				setRedirectUrl("/automation-designer/new")
				setIsRedirect(true)
			}
		}

		let label = null;
		let kanbanObject = {};
		kanbanObject.currentView = null;
		kanbanObject.show = false;
		dispatch(setHeader("", label, kanbanObject, false, false));
		
		return () => {
			dispatch(setJSPlumbInst(null));
		}
	}, []);


	
	useEffect(() => {
		if (automationCampaign.mounted) {
			let data = automationCampaign.data;
			if (btnAction !== null && btnAction === LinkToType.TYPE_COPY) {
				if (data.hasOwnProperty("status")) {
					data.status = constants.AUTOMATION_DESIGNER_STATUS_ACTIVE;
				}
				if (data.hasOwnProperty("id") && !data.hasOwnProperty("newId")) {
					data.id = 0;
				}
				if (data.hasOwnProperty("name") && !data.name.startsWith(getLocalizedStrings().label.AUTOMATION_DESIGNER.COPY_OF)) {
					data.name = getLocalizedStrings().label.AUTOMATION_DESIGNER.COPY_OF + " " + data.name;
				}
				if (data.hasOwnProperty("status")) {
					data.status = constants.AUTOMATION_DESIGNER_STATUS_ACTIVE;
				}
				if (data.hasOwnProperty("id") && !data.hasOwnProperty("newId")) {
					data.id = 0;
				}
				if (data.hasOwnProperty("name") && !data.name.startsWith(getLocalizedStrings().label.AUTOMATION_DESIGNER.COPY_OF) && !data.hasOwnProperty("newId")) {
					data.name = getLocalizedStrings().label.AUTOMATION_DESIGNER.COPY_OF + " " + data.name;
				}

				data.nodes.forEach((copyObject, i) => {
					copyObject.id = 0;
					copyObject.processId = "";
					if (copyObject.actionName !== constants.AUTOMATION_DESIGNER_SCORE_REACHED) {
						copyObject.secProcessId = "";
					}
					copyObject.status = "";
					if (copyObject.hasOwnProperty("details")) {
						if (copyObject.details[0] !== null && copyObject.details[0] !== undefined) {
							copyObject.details[0].t_status = "";
						}
					}
					copyObject.nodeStatusFlg = 1;
					copyObject.detailStatusFlg = 1;
					if (copyObject.hasOwnProperty("details") && copyObject.details !== null && copyObject.details !== undefined) {
						let detailsArray = copyObject.details;
						detailsArray.forEach(objDetail => {
							if (objDetail.hasOwnProperty("t_status")) {
								objDetail.t_status = "";
							}
							if (
								copyObject.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL ||
								copyObject.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL
							) {
								objDetail.autoresponder_id = 0;
								objDetail.name = getLocalizedStrings().label.AUTOMATION_DESIGNER.COPY_OF + " " + objDetail.name;
								objDetail.startDate = "";
							} else if (
								copyObject.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE ||
								copyObject.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE
							) {
								objDetail.message_id = 0;
								objDetail.template.template_id = 0;
								if (objDetail.hasOwnProperty("startDate")) {
									objDetail.startDate = "";
								}
							} else if (copyObject.actionName === constants.AUTOMATION_DESIGNER_WEB_FORM) {
								copyObject.details = null;
								copyObject.details = [];
							} else if (copyObject.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
								objDetail.id = 0;
								objDetail.template.template_id = 0;
								objDetail.t_status = "New";
							} else if (copyObject.actionName === constants.AUTOMATION_DESIGNER_LANDING_PAGE) {
								copyObject.details = null;
								copyObject.details = [];
							} else {
								objDetail.id = 0;
							}
						});
					}
				});
			}
			dispatch(addNode(data));
		}
	}, [automationCampaign.mounted])


	let pageHeight = window.innerHeight - 247;
	let top = (pageHeight - 10) / 2;

	document.documentElement.style.overflow = "hidden";

	if (isRedirect) {
		return <Navigate to={redirectUrl}/>;
	} else {
		if (automationCampaign.mounted) {
			let nodes = automationCampaign.data.nodes;
			let contentHeight = document.body.clientHeight - 230;
			return (
				<DndProvider backend={HTML5Backend}>
					<div style={{ paddingLeft: "33px" }}>
						<div style={{ paddingTop: "15px" }}>
							<AutomationHeader/>
						</div>
						<div style={{ display: "flex" }}>
							<div
								style={{
									paddingRight: "0px",
									paddingTop: "10px",
									float: "left",
								}}
							>
								<AutomationSidebar/>
							</div>
							<div style={styles.automationDiagramContainerStyle}>
								<div
									ref={container}
									id="divContainer"
									style={{
										...styles.automationDiagramStyle,
										height: contentHeight,
									}}
								>
									<AutomationBuilder propsNodes={nodes}/>
								</div>
							</div>
						</div>
					</div>
				</DndProvider>
			);
		} else {
			return (
				<div style={{ width: "100%", height: pageHeight }}>
					<div
						className="asset-loaderh"
						style={{ paddingTop: top, paddingLeft: "45%" }}
					>
						<div
							style={{
								...styles.assetLoaderContainer,
								height: 50,
								width: 50,
								padding: 7,
							}}
						>
							<ShowCircularProgress
								size={30}
								style={{ marginTop: "3", marginLeft: "3" }}
							/>
						</div>
					</div>
				</div>
			);
		}
	}
}

export default AutomationDesigner;
