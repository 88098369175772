
import React from 'react';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { styles } from '../../../services/constants/styles';
import * as sfDialogs from '../components/sfDialogs';
import { isValidParam, getStringParam, getArrayParam, getIntParam, getObjectParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { getAllocatePhoneNumbers, getMergeField, getRemainingSMSCount } from '../../../services/actions/smsAction';
import ShowCircularProgress from '../components/circularProgress';
import { refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';
import { Button, Chip, FormControl, Grid, Menu, MenuItem, TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { constants, OBJECT_TABLEID_MAP, COUNTRY_LIST_FOR_PHONE_SMS } from '../../../services/constants/constants';
import { getAppDialog, getAppDrawer } from '../../../services/actions/appContainerActions';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { refreshListView, getAttachmentType } from '../../../services/actions/listViewAcions';
import { dateTimeFormat } from '../../../services/helper/utils';
import { getTasksRefresh } from '../../../services/actions/taskActions';
import { getActiveTab } from '../../../services/helper/sfTabManager';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SetMealSharp } from '@mui/icons-material';
import PopOver from '../components/PopOver';
import {
    refreshDetailViewData, detailViewIsMounted
} from '../../../services/actions/detailViewActions';

const textOverflow = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };

const SendBatchSMS =({object,data,call_from})=> {
    const dispatch = useDispatch();
    const [ismobileNumberLoaded, setIsmobileNumberLoaded] = useState(false);
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [arrSMS, setArrSMS] = useState([]);
    const [fromNumber, setFromNumber] = useState(null);
    const [smsFromNumber, setSmsFromNumber] = useState(null);
    const [smsText, setSmsText] = useState(null);
    const [phoneNumberList, setPhoneNumberList] = useState([]);
    const [smslength, setSmslength] = useState(0);
    const [isOpenAttachmentPopover, setIsOpenAttachmentPopover] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(false);
    const [ismergeFieldLoaded, setIsmergeFieldLoaded] = useState(false);
    const [mergeFields, setMergeFields] = useState(null);
    const [attachFiles, setAttachFiles] = useState([]);
    const [mergeFieldName, setMergeFieldName] = useState('');
    const [isMounted, setIsMounted] = useState(true);
    const [noFromNumber, setNoFromNumber] = useState(false);
    const [fromNumberIndex, setFromNumberIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTemplate, setSelectedTemplate] = useState(0);
    const [templatesList, setTemplatesList] = useState([]);
    const [noteId, setNoteId] = useState(0);
    const [isDraftAgreement, setIsDraftAgreement] = useState(false);
    const [isSMSSupport, setIsSMSSupport] = useState(true);
    const [smsShrink, setSmsShrink] = useState(false);
    const [schedule_date, setSchedule_date] = useState(null);
    const [changeLog, setchangeLog] = useState({});
    const [moreActionOpen, setMoreActionOpen] = useState(false);
    const [anchorMoreActionEl, setAnchorMoreActionEl] = useState(false);
    const [dummyData, setDummyData] = useState(false);
    const [stateObject, setStateObject] = useState(null);

    const appState = useSelector((state) => state.app);

    useEffect(() => {
        setObject();
    }, []);

    useEffect(() => {
        if (stateObject) {
            getSmsData();
        }
    }, [stateObject]);

    const setObject = () => {
        let tempObject;
		if (object === constants.SEARCH_RESULTS) {
			let activeTab = getObjectParam(getActiveTab());
			let activeTabInfo = getObjectParam(activeTab.info);
			let search = getObjectParam(activeTabInfo.search);
			tempObject = getStringParam(search.object);
		} else if (object === constants.ADVANCE_SEARCH_RESULTS) {
			let activeTab = getObjectParam(getActiveTab());
			let advanceSearch = getObjectParam(activeTab.info.advanceSearch);
			tempObject = getStringParam(advanceSearch.object);
		} else {
			tempObject = object;
		}
		setStateObject(tempObject);
    }

    const getSmsData = () => {
        let tenantCountry = getStringParam(appState.me.tenant_country);
        try {
            if (COUNTRY_LIST_FOR_PHONE_SMS.indexOf(tenantCountry) < 0) {
                setIsSMSSupport(false);
                setIsLoading(false);
            } else {
                let actionType = data.actionType !== undefined ? getStringParam(data.actionType) : null;
                getFromPhoneNumbers();
                getMergeFieldObject();
                if (actionType === 'edit') {
                    loadEditSMSData();
                } else {
                    loadTemplateList();
                    let objName = getStringParam(data.childObject);
                    if (objName === constants.ATTACHMENT_OBJECT) {
                        getAttachmentType();
                    }
                }
            }
        } catch (error) {
            console.error("Error in 'SendBatchSMS.js -> getSmsData()':" + error);
        }
    }

    const getAttachmentType = () => {
        let recordId = getIntParam(data.attachmentId);
        if (recordId > 0) {
            let promise = getAttachmentType({ attchment_id: recordId });
            promise.then((response) => {
                if (isValidParam(response)) {
                    let type = getStringParam(response.type);
                    let attachment_type = getStringParam(response.attachment_type);
                    let name = getStringParam(response.name);
                    let isDraftAgreement = false;
                    let msg = "";
                    if (attachment_type === 'Agreement' && type === 'draft') {
                        msg = 'Please sign ' + name + '.\n<Agreement Link>';
                        isDraftAgreement = true;
                    } else {
                        msg = 'I am sending ' + name + '.\n<Attachment Link>';
                        let src = getStringParam(response.src);
                        let obj = { name: name, src: src };

                        if (attachFiles !== null) {
                            setAttachFiles(obj);
                        }
                    }

                    let smsSize = msg.length;
                    setSmsText(msg);
                    setSmslength(smsSize);
                    setIsDraftAgreement(isDraftAgreement);
                }
            });
        }
    }


    const loadEditSMSData = () => {
            let id = getIntParam(data.id);
            let params = {
                'id': id,
                'smsType': getStringParam(data.smsType),
            }

            let promise = Promise.resolve(HTTPClient.get(endPoints.SMS_TEMPLATE.GET_SCHEDULE_SMS, params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        if (response.status === 0) {
                            let data = response.data;
                            data.isLoadSMSData = true;
                            setTemplateMsgData(data);
                        }
                    }
                });
            }
    }

    const getFromPhoneNumbers = () => {
            let tempPhoneNumbersArr = null;
            let phoneNumber = [];
            let params = {};
            params.call_from = 'sendSMS';
            let promise = Promise.resolve(getAllocatePhoneNumbers(params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        let twilioNumbersArr = getArrayParam(response.twilio_numbers);
                        if (twilioNumbersArr !== null && twilioNumbersArr.length > 0) {
                            tempPhoneNumbersArr = twilioNumbersArr.filter(f => {
                                let availableToArr = f.available_to.split(',');
                                if (availableToArr.indexOf(String(-9999)) > -1) {
                                    return true;
                                } else if (availableToArr.indexOf(String(appState.me.id)) > -1) {
                                    return true;
                                }
                            })
                            let cookieSmsNo = cookieGetSmsFromPhoneNo();
                            if (arrSMS.length > 0) {
                                let tempsmsFromNumber = smsFromNumber;
                                setFromNumber(tempsmsFromNumber);
                            } else {
                                if (tempPhoneNumbersArr.length > 0) {
                                    if (cookieSmsNo === null || cookieSmsNo === "") {
                                        let tempfromNumberIndex = tempPhoneNumbersArr[0].id;
                                        setFromNumberIndex(tempfromNumberIndex);
                                        let tempfromNumber = tempPhoneNumbersArr[0].phone_number;
                                        setFromNumber(tempfromNumber);
                                    } else {
                                        tempPhoneNumbersArr.map((items, Index) => {
                                            if (cookieSmsNo === items.phone_number) {
                                                let tempfromNumberIndex = items.id;
                                                setFromNumberIndex(tempfromNumberIndex)
                                                let tempfromNumber = items.phone_number;
                                                setFromNumber(tempfromNumber);
                                            }
                                        });
                                    }
                                }
                            }
                            tempPhoneNumbersArr.map((listValue, listValueIndex) => {
                                let obj = {};
                                obj.value = listValue.id;
                                obj.label = listValue.phone_number;
                                obj.title = listValue.phone_number;
                                phoneNumber.push(obj);
                            })
                            setPhoneNumberList(phoneNumber);
                            setIsmobileNumberLoaded(true);
                        } else {
                            setNoFromNumber(true);
                        }
                    }
                });
            }
    }
    const getMergeFieldObject = () => {
        try {
            let promise = Promise.resolve(getMergeField(constants.CONTACTS_OBJECT, {}));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        setMergeFields(response);
                        setIsmergeFieldLoaded(true);

                    }
                });
            }
        } catch (error) {
            console.error("Error in 'SendBatchSMS.js -> getMergeFieldObject()':" + error);
        }

    }
    const setSelectedFromNumber = ( value) => {
        try {
            if (isValidParam(value)) {
                setFromNumberIndex(value.value);
                setFromNumber(value.title);
            }
        } catch (error) {
            console.error("Error in 'AutomationSendSMS.js -> setSelectedFromNumber()':" + error);
        }
    }
    const setSMSBody = (event, value, callFrom) => {
            let smsSize = null;
            if (callFrom === constants.MERGE_FIELD) {
                var eventSelection = document.getElementById('send-batchsms-text-box');
                let start = eventSelection.selectionStart,
                    end = eventSelection.selectionEnd;
                    if(!end ){
                        value = getStringParam(smsText).concat(' ${' + getStringParam(value) + '} ');
                    }else{
                        value = getStringParam(smsText).substring(0, start) + ' ${' + getStringParam(value) + '} ' + getStringParam(smsText).substring(end);
                    }
            } else {
                value = getStringParam(value);
            }
            smsSize = value.length;
            setSmsText(value);
            setSmslength(smsSize);
    }

    const setLoading = (mountedObject) => {
        setIsMounted(mountedObject);
        
    }

    const validateSMSForm = () => {
        let tempfromNumber = getStringParam(fromNumber).trim();
        let tempsmsText = getStringParam(smsText).trim();
            if (tempfromNumber === '') {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SMS_INTEGRATION.SELECT_NUMBER,null);
                return false;
            } else if (tempsmsText === '') {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SMS_INTEGRATION.ENTER_SMS_TEXT,null);
                return false;
            } else {
                return true;
            }
    }

    const cookieSetSmsFromPhoneNo=(value)=> {
        let userid = appState.me.id;
        let cVarName = "smsFromPhoneNo_" + userid
        document.cookie = cVarName + "=" + value;
    }



    const checkPhoneTextProvider = () => {
        let phone_text_provider = appState.me.phone_text_provider ?? constants.NONE;
        if (phone_text_provider == constants.SOFFRONT) {
            saveSMSAfterRemainingCountCheck();
        } else if (phone_text_provider == constants.TWILIO) {
            sendMessage();
        }
    }

    const cookieGetSmsFromPhoneNo=()=> {
        let userid = appState.me.id;
        let cVarName = "smsFromPhoneNo_" + userid + "=";
        let cookieVar = decodeURIComponent(document.cookie).split(';')
        for (var i = 0; i < cookieVar.length; i++) {
            var c = cookieVar[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(cVarName) === 0) {
                return c.substring(cVarName.length, c.length);
            }
        }
        return "";
    }

    const saveSMSAfterRemainingCountCheck = () => {
           try {
                if (validateSMSForm()) {
                    let smsCountPromise = Promise.resolve(getRemainingSMSCount());
                    if (isValidParam(smsCountPromise)) {
                        smsCountPromise.then((response) => {
                            if (isValidParam(response)) {
                                let remainingSMSCount = response.remaining_sms_count;
                                if (remainingSMSCount > 0){
                                    sendMessage();
                                } else {
                                    let msg = getLocalizedStrings().message.SMS_INTEGRATION.SMS_LOW_BALANCE_1 + remainingSMSCount + '.\n' + getLocalizedStrings().message.SMS_INTEGRATION.SMS_LOW_BALANCE_2
                                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                                }
                            }
                        });
                    }
                }
            } catch (error) {
                console.error("Error in 'sendSMS.js -> saveSMSAfterRemainingCountCheck()':" + error);
            }
    }

    const sendMessage = () => {
        let msg = getLocalizedStrings().message.SMS_INTEGRATION['BATCH_SMS_SENT'];
        try {
            let tempfromNumber = getStringParam(fromNumber).trim();
            let tempsmsText = getStringParam(smsText).trim();
            let tempAttachFiles = getArrayParam(attachFiles);
            let tempIsDraftAgreement = getBooleanParam(isDraftAgreement);
            cookieSetSmsFromPhoneNo(tempfromNumber);
            let url = endPoints.SMS_INTEGRATION.BATCH_SMS;
            var promise = null;
            let recordIds = getArrayParam(data.recordIds);

            let object = getStringParam(data.objectName);
            if (object === constants.UNITS_OBJECT) {
                object = constants.ACCOUNTS_OBJECT;
            }
            let params = {
                'from_number': tempfromNumber,
                'sms_text': tempsmsText,
                'record_ids': recordIds,
                'table_id': OBJECT_TABLEID_MAP[getStringParam(object)],
                'type': 'Send SMS',
                'template_id': selectedTemplate
            }

            let objName = getStringParam(data.childObject);
            let isDetailView = getBooleanParam(data.isDetailView);

            if (objName === constants.ATTACHMENT_OBJECT) {
                params.is_draft_agreement = tempIsDraftAgreement;
                params.attachment_id = getIntParam(data.attachmentId);
            }
            if (tempAttachFiles.length > 0) {
                params.image_object = tempAttachFiles;
            }
            if (isValidParam(changeLog.schedule_date)) {
                params.schedule_date = changeLog.schedule_date;
                params.type = 'Schedule SMS';
                msg = getLocalizedStrings().message.SMS_INTEGRATION.BATCH_SMS_SCHEDULE;
            }
            let _isMounted = false;
            setLoading(_isMounted);

            promise = Promise.resolve(HTTPClient.post(url, params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    _isMounted = true;
                    setLoading(_isMounted);
                    if (isValidParam(response.status) && isValidParam(response.data)) {
                        if (response.status === 0) {
                            if (isValidParam(getLocalizedStrings().message.SMS_INTEGRATION[response.data.toUpperCase()])) {
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.SMS_INTEGRATION[response.data.toUpperCase()], styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            } else {
                                dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            }
                            closeDrawer();

                            if (isDetailView) {
                                refreshDetailViewListViewData(true);
                                let activeTab = getActiveTab();
                                activeTab = getObjectParam(activeTab);
                                let info = getObjectParam(activeTab.info);
                                info.selectedPageNo = 1;
                            } else {
                                refreshListView(getStringParam(data.objectName));
                            }
                        } else {
                            if(recordIds.length > 1){
                                if (isValidParam(getLocalizedStrings().message.SMS_INTEGRATION[response.data.toUpperCase()])) {
                                    dispatch(showCustomSnackBar(getLocalizedStrings().message.SMS_INTEGRATION[response.data.toUpperCase()], styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                }
                            }else if(recordIds.length == 1){
                                    dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            }
                            closeDrawer();
                            if (isDetailView) {
                                refreshDetailViewListViewData(true);
                                let activeTab = getActiveTab();
                                activeTab = getObjectParam(activeTab);
                                let info = getObjectParam(activeTab.info);
                                info.selectedPageNo = 1;
                            } else {
                                refreshListView(getStringParam(data.objectName));
                            }
                        }
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'sendSMS.js -> sendMessage()':" + error);
        }
    }

  

    const closeDrawer = () => {
            dispatch(getAppDrawer(false, null, null, null, null));
    }

    const openAttachment = () => {
            setIsOpenAttachmentPopover(false);
            let data = {
                contentStyle: { width: '80%', maxWidth: 'none' },
            }
            data.callFrom = constants.SEND_SMS;
            data.files = getArrayParam(attachFiles);
            data.fullWidth = true;
            data.maxWidth = 'md';
            dispatch(getAppDialog(true, constants.LIBRARY_DIALOG, getLocalizedStrings().message.COMMON.ASSETMENU_DIALOG_TITLE, (sendData) => {
                // Check for duplicates before adding
                const isDuplicate = attachFiles.some(file => file.id == sendData.id);

                if (isDuplicate) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,"Duplicate files are not allowed.", null);
                } else {
                    let attachFile = {
                        name: sendData.name,
                        src: sendData.src,
                        id: sendData.id,
                    };
                    setAttachFiles([...attachFiles, attachFile]);
                    dispatch(getAppDialog(false, null, null, null, null, null));
                    setDummyData(true);
                }
            }, data, constants.SEND_SMS));
    }

    const handleClick = (event) => {
        setIsOpen(true);
        setAnchorEl(event.currentTarget);
    }

    const handleMoreActionOpen = (event) => {
        // event.stopPropagation();
        setMoreActionOpen(true);
        setAnchorMoreActionEl(event.currentTarget);
    }

    const handleMoreActionClose = () => {
        setMoreActionOpen(false);
        setAnchorMoreActionEl(null);
    }

    const confirmSchedule = (obj) => {
            let tempschedule_date = obj.schedule_date;
            setSchedule_date(tempschedule_date);
            let log = changeLog;
            log.schedule_date = obj.schedule_date;
            setchangeLog({ ...log});
            dispatch(getAppDialog(false, constants.SMS_CONFIRM_SCHEDULE_DIALOG, null, null, null, null));
            checkPhoneTextProvider();
    }

    const schedule = (obj) => {
            let momentObj = obj.date;
            let db_date = momentObj.format('YYYY-MM-DD HH:mm:ss');
            let user_date = momentObj.format(dateTimeFormat[appState.me.date_format]);
            let data = { user_date: user_date, db_date: db_date };
            dispatch(getAppDialog(false, constants.SMS_SCHEDULE_DIALOG, null, null, null, null));
            dispatch(getAppDialog(true, constants.SMS_CONFIRM_SCHEDULE_DIALOG, getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, (event)=>confirmSchedule(event), data, constants.SMS_OBJECT));

    }

    const handleSchedule = () => {
            setMoreActionOpen(false);
            setAnchorMoreActionEl(null);
            if (validateSMSForm()) {
                dispatch(getAppDialog(true, constants.SMS_SCHEDULE_DIALOG, getLocalizedStrings().label.BATCH_SMS.SCHEDULE_SMS, (event)=>schedule(event), null, null));
            }
    }

    const menuOnChange = (value,event) => {
        try {
            let callFrom = constants.MERGE_FIELD;
            setSMSBody(event, value, callFrom);
            setIsOpen(false);
            setMergeFieldName(value);
            setSmsShrink(true);
        } catch (error) {
            console.error("Error in 'SendBatchSMS.js ->  menuOnChange()':" + error);
        }
    }

    const openfields = () => {
        let element = null;
        let fieldItems = mergeFields;
        try {
            let popOverList = [];
            fieldItems.forEach((item, index) => {
                if (isValidParam(item)) {
                    if (isValidParam(Object.values(item)[0]) && isValidParam(Object.keys(item)[0])) {
                        let data = {};
                        data.label = Object.values(item)[0];
                        data.value = Object.keys(item)[0];
                        popOverList.push(data);
                    }
                }
            });
            element = <span title={getLocalizedStrings().message.SMS_INTEGRATION.MERGE_FIELD}>
                <span  style={{ marginRight: 5, maxWidth: 80, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }}>
                    <PopOver
                        id={'phoneTemplate'}
                        key={'phoneTemplate'}
                        name={'phoneTemplate'}
                        title={getLocalizedStrings().message.SMS_INTEGRATION.MERGE_FIELD}
                        buttonEndIcon={"settings,keyboard_arrow_down"}
                        buttonStyle={{ color: '#7b7272', marginTop: '0px', fontSize:18 }}
                        labelposition={"before"}
                        buttonLabel={""}
                        options={popOverList}
                        onclickAction={(value,event)=>menuOnChange(value,event)}
                        isShowIcon={true}
                        buttonLineOrigin={[28, 145]}
                    />
                </span>
            </span>
        } catch (error) {
            console.error("Error in 'SmsTemplate.js -> openfields()':" + error);
        }

        return element;
    }

    const getAttachmetFiles = () => {
        try{
            let files = getArrayParam(attachFiles);
            let ul = null;
            let li = files.map((f, index) => {
                return <li key={f.name + index}><span title={f.name} style={{
                    float: 'left', textAlign: 'left', overflow: 'hidden',
                    textOverflow: 'ellipsis', display: 'block', whiteSpace: 'nowrap', width: '150px'
                }}> {f.name} </span>
                    &nbsp;  <i className="fa fa-times" title={getLocalizedStrings().label.COMMON.DELETE} aria-hidden="true" style={{ paddingRight: '5px', cursor: 'pointer' }} onClick={() => {

                        let tempAttachFiles = attachFiles;
                        tempAttachFiles.splice(index, 1);
                        dispatch(getAppDialog(false, null, null, null, null, null));
                        setAttachFiles(tempAttachFiles);
                        setDummyData(true);
                    }} ></i>
                </li>
            });
            if (li !== null && li.length > 0) {
                ul = <ul>{li}</ul>;
            }
        return ul;
        }catch (error) {
            console.error("Error in 'SendBatchSMS.js -> getSmsData()':" + error);
        }
    }

    const loadTemplateList = () => {
            let promise = Promise.resolve(HTTPClient.get(endPoints.SMS_TEMPLATE.GET_TEMPLATE, null));
            promise.then((response) => {
                if (isValidParam(response) && response.status === 0) {
                    let responseData = getArrayParam(response.data);
                    let templateList = [];
                    responseData.forEach(template => {
                        templateList.push({ label: template.name, value: template.id, title: template.name, sms_message: template.sms_message, image_object: template.image_object });
                    });
                    setIsLoading(false);
                    templateList = templateList.filter((obj, index) => {
                        return index === templateList.findIndex(o => obj.value.toString() === o.value.toString());
                    });
                    setTemplatesList(templateList);
                    if(templateList.length > 0){
                        setSelectedTemplate(templateList[0].value);
                        handleTemplateChange(null,templateList[0]);
                    }

                    if (response.alert) {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, response,null);
                    }
                }
            });
    }

    const handleTemplateChange = (event, option) => {
            setIsDraftAgreement(false);
            let tempdata = getObjectParam(data);
            let recordIds = getArrayParam(tempdata.recordIds);
            if (recordIds.length > 1) {
                setTemplateMsgData(option);
            } else {
                let params = {};
                params.record_id = getIntParam(recordIds[0]);
                params.table_id = OBJECT_TABLEID_MAP[getStringParam(stateObject)];
                params.object = getIntParam(tempdata.objectName);
                params.templateId = getIntParam(option.value);
                let promise = Promise.resolve(HTTPClient.get(endPoints.SMS_TEMPLATE.GET_TEMPLATE_DATA, params));
                promise.then((response) => {
                    if (isValidParam(response) && response.status === 0) {
                        setTemplateMsgData(response.data);
                    }
                });
            }
    }

    const setTemplateMsgData = (dataValue) => {
            let actionType = data.actionType !== undefined ? getStringParam(data.actionType) : null;
            if (isValidParam(dataValue)) {
                let sms_message = getStringParam(dataValue.sms_message);
                let smslength = sms_message.length;
                let selectedId = getIntParam(dataValue.value);
                let noteId = getIntParam(dataValue.note_id);
                let tempAttach = [];
                if (dataValue.image_object !== null && dataValue.image_object !== "" && actionType !== 'edit') {
                    let attach = [];
                    attach = dataValue.image_object.split('###');
                    if (attach.length > 0) {
                        attach.forEach(element => {
                            let obj = {};
                            let elm = element.split('@#@');
                            obj.name = elm[0];
                            obj.src = elm[1];
                            obj.id = elm[2];
                            tempAttach.push(obj);
                        });
                    }
                }
                if (isValidParam(dataValue.image_object) && dataValue.isLoadSMSData && dataValue.image_object.startsWith("[{")) {
                    tempAttach = JSON.parse(dataValue.image_object);
                }
                setSmsText(sms_message);
                setSelectedTemplate(selectedId);
                setSmslength(smslength);
                setAttachFiles(tempAttach);
                setNoteId(noteId);
                setSmsShrink(true);
            }
    }

    const saveSMS = () => {
            let tempAttachFiles = getArrayParam(attachFiles);
            let tempSmsText = getStringParam(smsText).trim();
            if (validateSMSForm()) {
                let url = endPoints.SMS_TEMPLATE.SAVE_SCHEDULE_SMS;
                let smsId = getIntParam(data.id);
                let smsType = getStringParam(data.smsType);

                let params = {
                    'sms_text': tempSmsText,
                    'id': smsId,
                    'smsType': smsType,
                    'noteId': noteId,
                }
                if (tempAttachFiles.length > 0) {
                    params.image_object = tempAttachFiles;
                }
                let _Mounted = false;
                setLoading(_Mounted);
                var promise = Promise.resolve(HTTPClient.post(url, params));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (response.status === 0) {
                            closeDrawer();
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.SMS_TEMPLATE.SAVE_MESSAGE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            getTasksRefresh(true);
                            let activeTab = getObjectParam(getActiveTab());
                            if (activeTab.object === constants.TASKS && isValidParam(activeTab.info.getTaskPageData)) {
                                activeTab.info.getTaskPageData(constants.TASKS);
                            }
                            dispatch(detailViewIsMounted(false));
						    dispatch(getTasksRefresh(true));
						    dispatch(refreshDetailViewListViewData(true));
						    dispatch(refreshDetailViewData(true));
                        }
                    });
                }
            }
    }
    const onBlurShrink = () => {
        if (smsText === null || smsText=='') {
            setSmsShrink(false);
        }
    }
    const generateMSGForm = () => {
        let actionType = data.actionType !== undefined ? getStringParam(data.actionType) : null;
        return (
            <div style={{paddingBottom:'10px'}}>
                {actionType !== 'edit' &&
                    <Grid container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center" spacing={2}>

                        <Grid item xs={6}>
                            <FormControl style={{ width: '100%',height:'41px' }} className="test" noValidate autoComplete="off">
                                <Autocomplete
                                style={{ ...styles.sfFormFieldContainer, paddingLeft: 0 }}
                                key={"number"}
                                size="small"
                                id="combo-box-sf-sendbatchsms"
                                name="number"
                                value={phoneNumberList.find(v => v.value == fromNumberIndex)}
                                options={phoneNumberList}
                                onChange={(event,newValue)=>setSelectedFromNumber(newValue)}
                                getOptionLabel={(option) => option.label}
                                
                                    disableClearable={false}
                                    renderInput={(params) => <TextField {...params}  placeholder={getLocalizedStrings().message.SMS_INTEGRATION.SELECT_NUMBER} />}
                                />

                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl style={{ width: '100%',height:'41px'}} className="test" noValidate autoComplete="off">
                                {templatesList.length > 0 &&<Autocomplete
                                style={{ ...styles.sfFormFieldContainer, paddingLeft: 0 }}
                                key={"template"}
                                id="combo-box-sfsendbatchsms"
                                name="template"
                                size="small"
                                defaultValue={templatesList.find(v => v.value === selectedTemplate)}
                                options={templatesList}
                                onChange={(event,option)=>handleTemplateChange(event,option)}
                                getOptionLabel={(option) => option.label}
                                    disableClearable={false}
                                    renderInput={(params) => <TextField {...params}  placeholder={getLocalizedStrings().label.MARKETING_TEMPLATE.SELECT_TEMPLATE} />}
                                />}
                            </FormControl>

                        </Grid>

                        <Grid item xs={12} >
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    id='send-batchsms-text-box'
                                    type='textarea'
                                    multiline={true}
                                    rows={4}
                                    fullWidth={true}
                                    style={{ width: '100%', backgroundColor: '#fff'}}
                                    label={getLocalizedStrings().label.BATCH_SMS.MESSAGE}
                                    value={smsText}
                                    onChange={(e) => setSMSBody(this, e.target.value, null)}
                                    onFocus={() => setSmsShrink(true)}
                                    onBlur={()=>onBlurShrink()}
                                    InputLabelProps={{
                                        shrink: smsShrink,
                                    }}
                                    className={"sf-fields-bg"}
                                /></FormControl>
                        </Grid>
                    </Grid>
                }
                {actionType === 'edit' &&
                    <Grid container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center">
                    <Grid item xs={12}>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                id='send-batchsms-text-box'
                                type='textarea'
                                multiline={true}
                                rows={5}
                                fullWidth={true}
                                style={{ width: '100%', backgroundColor: '#fff', }}
                                label={getLocalizedStrings().label.BATCH_SMS.MESSAGE}
                                value={smsText}
                                onChange={(e) => setSMSBody(this, e.target.value, null)}
                                onFocus={() => setSmsShrink(true)}
                                onBlur={()=>onBlurShrink()}
                                InputLabelProps={{
                                    shrink: smsShrink,
                                }}
                                className={"sf-fields-bg"}
                            /></FormControl>
                    </Grid>
                    </Grid>
                }
                <div style={{ marginTop:13}}>
                    <span>{smslength}{getLocalizedStrings().label.BATCH_SMS.CHARS}</span>
                    <div style={{ marginTop: '2px', float: "right", height: 'auto',display:'flex'}}>
                        {(appState.me.tenant_country === 'USA' || appState.me.tenant_country === 'Canada') &&
                            <span onClick={()=>openAttachment()} style={{ cursor: 'pointer', paddingRight: '10px' }} title={getLocalizedStrings().message.SMS_INTEGRATION.IMAGE_SELECT}><i className="fa fa-file-image" style={{ fontSize: '20px' }} ></i></span>}
                        {openfields()}
                    </div>
                </div>
            </div>
                            
        );
    }

    const getActionButton = () => {
        let button;
            let actionType = data.actionType !== undefined ? getStringParam(data.actionType) : null;
            button = <div>
                {actionType !== 'edit' ?
                    <div  >
                        <div style={{ ...styles.emailSendBtn, width: '6.5rem', marginLeft:'-43px',backgroundColor : appState.me.background  }}>
                            <div style={{ float: 'left', width: '67rem', margin: 5, textAlign: 'center'}} onClick={()=>checkPhoneTextProvider()}>{getLocalizedStrings().label.BATCH_SMS.SEND}</div>
                            <div style={{ float: 'right', width: '25%', borderLeft: '1px solid rgba(159, 46, 10, 0.6)' }} onClick={(event)=>handleMoreActionOpen(event)}>
                                <i className="fa fa-angle-down" aria-hidden="true" style={{ margin: 5, fontSize: 20 }}></i>
                            </div>
                        </div>
                        <Menu
                            open={moreActionOpen}
                            anchorEl={anchorMoreActionEl}
                            // getContentAnchorEl={null}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            onClose={()=>handleMoreActionClose()}
                        >
                            <MenuItem value={'Schedule'} style={styles.popoverMenuItem} onClick={()=>handleSchedule()} >{getLocalizedStrings().label.BATCH_SMS.SEND_LATER}</MenuItem>
                        </Menu>
                    </div>
                    :
                    <Button
                        key='save'
                        onClick={()=>saveSMS()}
                        style={{ ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top' }}
                    > {getLocalizedStrings().label.COMMON.SAVE}</Button>
                }

            </div>
        return button;

    }
    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight-10) / 2;
    let mounted = false;
    let tempAttachFiles = getArrayParam(attachFiles);
    if (ismergeFieldLoaded && ismobileNumberLoaded && isMounted) {
        mounted = true;
    }
    if (mounted) {
        return (
            <div style={{ paddingLeft: '30px', paddingRight: '30px', marginTop: '10px' }}>
                <div>
                    {/* Message Form */}
                    <div>{generateMSGForm()}</div>
    
                    {/* Attachments and Action Buttons */}
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '10px' }}>
                            
                            {/* Attachments */}
                            {tempAttachFiles.length > 0 && (
                                <div className="row" style={{ marginBottom: '5px', textAlign: 'right' }}>
                                    {getAttachmetFiles()}
                                </div>
                            )}
    
                            {/* Action Buttons */}
                            <div style={{ display: 'flex', gap: '5px' }}>
                                {/* Send Button */}
                                {getActionButton()}
    
                                {/* Cancel Button */}
                                <Button
                                    onClick={() => closeDrawer()}
                                    style={{ ...styles.secondaryButton, height: '32px' }}
                                >
                                    {getLocalizedStrings().label.COMMON.CANCEL}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (!isSMSSupport) {
        return (<div style={{ margin: '10px 15px' }}> {getLocalizedStrings().message.SMS_INTEGRATION.SMS_SUPPORT}</div>);
    } else if (noFromNumber) {
        return (<div style={{ margin: '10px' }}>
            {getLocalizedStrings().message.SMS_INTEGRATION.NO_FROM_NUMBER_MESSAGE}
        </div>
        );
    } else {
        return (<div style={{ width: '100%', height: pageHeight }}>
        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
            </div>
        </div>
    </div>);
    }
}

export default SendBatchSMS;