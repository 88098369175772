import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { dateTimeFormat, dateTimeFormatFlatPicker } from '../../../services/helper/utils';
import moment from 'moment';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import PopOver from './PopOver';
import { getStringParam } from '../../../services/helper/parameterVerifier';
import { useDispatch, useSelector } from "react-redux";

const ScheduleDialog = ({ }) => {

    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);

    const [schedule_date, setScheduleDate] = useState(moment(getStringParam(new Date())).format(dateTimeFormat[app.me.date_format]));
    const [schedule_date_error, setScheduleDateError] = useState('');


    useEffect(() => {
        dispatch(setAppDialogActions(getActions()));
    }, [schedule_date]);

    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const handleSchedule = () => {
        if (schedule_date.length === 0) {
            setScheduleDateError(getLocalizedStrings().message.SCHEDULE.EMPTY);
            return false;
        }
        let obj = { date: moment(schedule_date) };
        appContainer.dialogProps.eventName(obj);
    }

    const handleChange = (date) => {
        setScheduleDate(date);
        if (date.length > 0) {
            setScheduleDateError('');
        }
    }

    const getActions = () => {
        const actions = [
            <Button
                key={'schedule'}
                primary={'true'}
                onClick={() => handleSchedule()}
                style={{...styles.primaryButton, fontSize:16}}
            > {getLocalizedStrings().label.CAMPAIGNS.SCHEDULE}</Button>,
            <Button
                key={'cancel'}
                primary={'true'}
                onClick={() => handleClose()}
            style={{...styles.secondaryButton, fontSize:16}}
            > {getLocalizedStrings().label.COMMON.CANCEL}</Button>,

        ];
        return actions;
    }


    let HHFormat = new RegExp("HH");
    let datePickerOptions = {
        enableTime: true,
        dateFormat: dateTimeFormatFlatPicker[app.me.date_format],
        minuteIncrement: 1,
        time_24hr: HHFormat.test(app.me.date_format) ? true : false
    };

    return (
        <div style={{ paddingRight: 100 , marginLeft: '-13px'}}>
            <div className="row">
                <div className="col-sm-8">
                    <PopOver
                        id={'dateTime'}
                        key={'dateTime'}
                        btnType={'dateTime'}
                        buttonStyle={{ fontSize: '16px' }}
                        containerStyle={{  }}
                        onChange={(date) => handleChange(date)}
                        value={schedule_date}
                        height={38}
                        options={datePickerOptions}
                        buttonOuterLabelStyle={{border: '#616061'}}
                        innerLabelStyle={{ paddingTop: "8px"}}


                    />
                    <span style={{ fontSize: '12px', color: '#FF0000', marginTop: 5, display: 'block' }}>{schedule_date_error}</span>
                </div>
                <div className="col-sm-8">

                </div>
            </div>
        </div>
    );
};

export default ScheduleDialog;