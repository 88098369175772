import React from 'react';
import { isValidParam, getObjectParam, getStringParam, getIntParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { LinkToType } from '../../../services/constants/link';
import { constants, TABLEID_OBJECT_MAP, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import { Link } from 'react-router-dom';
import { addTab, TYPE_DETAIL_VIEW } from '../../../services/helper/sfTabManager';
import { getObjectSpecificEndpoint } from '../../../services/helper/utils';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retriveEscapeHtml, } from "../../../services/helper/common";
import { Icon } from "@mui/material";
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';

const Email = ({ parentObject, parentRecordId, data, callFrom, getListViewEmailChildData}) => {
    const dispatch = useDispatch();
    const [mounted, setMounted] = useState(false);
    const [emailReportInfo, setEmailReportInfo] = useState(null);
    const appContainerData = useSelector((state) => state.appContainer);
    const drawerProps = appContainerData.drawerProps.data ?? {};
    const { noteData,getListView, selectedNoteAuthor, isPinned } = drawerProps;
    useEffect(() => {
        let _callFrom = getStringParam(callFrom);
        if (_callFrom === constants.NOTES) {
            getContactByEmail();
        } else {
            setMounted(true);
        }
    }, [callFrom]);

    const getContactByEmail = () => {
        try {
            let tempdata = getObjectParam(data);
            let parentObject = getStringParam(TABLEID_OBJECT_MAP[getIntParam(tempdata.table_id)]).trim();
            let parentRecordId = getIntParam(tempdata.record_id);
            if (parentObject !== '' && parentRecordId > 0) {
                let params = { "id": parentRecordId, parent_table_id: OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT] };
                let endPoint = getObjectSpecificEndpoint(parentObject, endPoints.RECORDS.RECORD_LINKED_MAP_DATA);
                if (parentObject === constants.ACCOUNTS_OBJECT || parentObject === constants.CONTACTS_OBJECT) {
                    endPoint = getObjectSpecificEndpoint(parentObject, endPoints.RECORDS.MAP_DATA);
                    params = { "id": parentRecordId, "fields": ['company', 'first_name', 'last_name', 'email'] };
                }
                var promise = Promise.resolve(HTTPClient.get(endPoint, params));
                if (isValidParam(promise)) {
                    promise.then(response => {
                        if (isValidParam(response)) {
                            setMounted(true);
                            setEmailReportInfo(response);
                        }
                    });
                }
            } else {
                setMounted(true);
            }
        } catch (error) {
            console.error("Error in 'email.js -> getContactByEmail()':" + error);
        }
    }

    const sendEmail = (actionType) => {
        let label = getLocalizedStrings().label.COMMON.SEND_MAIL;;
        let type = LinkToType.TYPE_EMAIL;
        let _data = {};
        _data.parentObject = getStringParam(parentObject);
        _data.parentRecordId = getIntParam(parentRecordId);
        _data.type = constants.EMAIL_OBJECT;
        _data.actionType = getStringParam(actionType);
        _data.record = data;
        _data.object = getStringParam(parentObject);
        _data.id = getIntParam(parentRecordId);
        let tempcallFrom = getStringParam(callFrom) === constants.NOTES ? constants.NOTES : null;
        let minimizeList = appContainerData.customDrawerProps.minimizeList
             if ( (actionType == 'REPLY' && actionType == "REPLY-ALL") && minimizeList.hasOwnProperty('email') || (minimizeList.hasOwnProperty('email') && minimizeList.email)) {
                dispatch(showCustomSnackBar("Send mail popup is already opened for different record", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
    
            }else{
        dispatch(getAppDrawer(true, label, type, _data, tempcallFrom));
            }
    }

    const getNoteHeader = () => {
        let noteHeader = null;
        let elements = [];
        let title = '';
        try {
            let tempcallFrom = getStringParam(callFrom);
            let tempdata = getObjectParam(data);
            let subject = getStringParam(tempdata.t_subject);
            let emailDate = getStringParam(tempdata.email_date).trim();
            let cc = getStringParam(tempdata.t_cc).trim();
            let subjectToShow = subject.length > 55 ? subject.substring(0, 55) + '...' : subject;
            if (tempcallFrom === constants.NOTES && isValidParam(emailReportInfo)) {
                let contact = getObjectParam(emailReportInfo.records);
                if (isValidParam(contact) && Object.keys(contact).length > 0) {
                    let fullName = getStringParam(contact.first_name).trim() + ' ' + getStringParam(contact.last_name).trim();
                    fullName = fullName.trim() === '' ? 'Empty' : fullName;
                    elements.push(<div style={{ lineHeight: 1.3 }} title={getLocalizedStrings().label.MAIL.TO + ': ' + fullName}>
                        <span style={{ fontWeight: 'bold', color: '#070100' }}>{getLocalizedStrings().label.MAIL.TO}: </span>
                        {<Link to="#" onClick={(info) => openAsDetailView(info)}>{fullName}</Link>}
                    </div>);
                }
                if (cc !== '') {
                    elements.push(<div style={{ lineHeight: 1.3 }} title={getLocalizedStrings().label.MAIL.CC + ': ' + cc}>
                        <span style={{ fontWeight: 'bold', color: '#070100' }}>{getLocalizedStrings().label.MAIL.CC}:</span> {cc}</div>);
                }
                elements.push(<div style={{ lineHeight: 1.3 }} title={getLocalizedStrings().label.MAIL.WHEN + ': ' + emailDate}>
                    <span style={{ fontWeight: 'bold', color: '#070100' }}>{getLocalizedStrings().label.MAIL.WHEN}:</span> {emailDate}</div>);
                elements.push(<div style={{ lineHeight: 1.3 }} title={getLocalizedStrings().label.MAIL.SUBJECT + ': ' + subjectToShow}>
                    <span style={{ fontWeight: 'bold', color: '#070100' }}>{getLocalizedStrings().label.MAIL.SUBJECT}: </span>{subjectToShow}</div>);
                noteHeader = <div title={title} style={{ marginTop: 10 }}>{elements}</div>;
            } else {
                title = subjectToShow;
                elements.push(<span>{subjectToShow}</span>);
                elements.push(<span>,&nbsp;{emailDate}</span>);
                title = title + ', ' + emailDate;
                noteHeader = <span title={title}>{elements}</span>;
            }
        } catch (error) {
            console.error("Error in 'email.js -> getNoteHeader()':" + error);
        }
        return noteHeader;
    }

    const getNoteBody = () => {
        let noteBody = null;
        try {
            let noteText = getNoteText();
            noteBody = <div style={{ width: '100%' }}>
                <div dangerouslySetInnerHTML=
                    {{ __html: retriveEscapeHtml(noteText) }} />
            </div>;

        } catch (error) {
            console.error("Error in 'noteBody.js -> getNoteBody()':" + error);
        }
        return noteBody;
    }

    const getNoteText = () => {
        let { t_msg } = getObjectParam(data);
        let noteText = getStringParam(t_msg);
        try {
            noteText = noteText.split("Attachment Info:")[0];
            noteText = noteText.split("Call Recording:")[0];
        } catch (error) {
            noteText = "";
            console.error("Error in 'noteBody.js -> getNoteText()':" + error);
        }
        return noteText;
    }

    const hasAttachment = () => {
        let { t_msg } = getObjectParam(data);
        try {
            let noteText = getStringParam(t_msg).trim();
            return noteText.indexOf("Attachment Info:") > -1 ? true : false;
        } catch (error) {
            console.error("Error in 'noteBody.js -> getAttachmentIcon()':" + error);
        }
    }

    const getAllNoteAttachments = () => {
        let arrAttachment = new Array();
        let { t_msg } = getObjectParam(data);
        try {
            let noteText = getStringParam(t_msg).trim();
            if (hasAttachment()) {
                let tempArr = noteText.split("Attachment Info:");
                tempArr = tempArr.length > 1 ? tempArr.slice(1) : [];
                arrAttachment = tempArr.map((sliceSplitAttachmentObject, j) => {
                    let strAttachment = sliceSplitAttachmentObject.trim();
                    let attachmentInfoArray = [];
                    while (strAttachment != '' && strAttachment.indexOf('<a') >= 0 && strAttachment.indexOf('a>') > 0 && strAttachment.indexOf('http') >= 0) {
                        let startIndex = strAttachment.indexOf('<a');
                        let endIndex = strAttachment.indexOf('a>');
                        let lenkTag = startIndex >= 0 && endIndex > 0 ? strAttachment.slice(startIndex, endIndex + 2) : '';
                        strAttachment = strAttachment.replace(lenkTag, '').trim();
                        lenkTag = lenkTag.slice(lenkTag.indexOf('http'));
                        let httpUrl = lenkTag.slice(0, lenkTag.indexOf('\''));
                        let docName = lenkTag.slice(lenkTag.indexOf('>') + 1, lenkTag.indexOf('<'));
                        attachmentInfoArray.push({ name: docName, url: httpUrl, isMailAttach: httpUrl.indexOf('/mail/') });
                    }

                    let newAttachmentHtml = attachmentInfoArray.map((attachInfoObject, k) => {
                        return <NoteAttachment key={"attachment-" + k} data={attachInfoObject} />;
                    });
                    return (<div style={{ color: '#717171', paddingLeft: '10px', lineHeight: '25px' }}>
                        {getLocalizedStrings().label.DETAIL_VIEW_CHILD_NOTES.ATTACHMENT_INFO}:<br />
                        {newAttachmentHtml}
                    </div>);
                });
            }
        } catch (error) {
            console.error("Error in 'noteBody.js -> getAllNoteAttachments()':" + error);
        }
        return arrAttachment;
    }

    const openAsDetailView = (info) => {
        try {
            let tempemailReportInfo = getObjectParam(emailReportInfo);
            let objectName = getStringParam(parentObject);
            let id = getIntParam(parentRecordId);
            if (objectName !== constants.ACCOUNTS_OBJECT && objectName !== constants.CONTACTS_OBJECT) {
                objectName = getStringParam(TABLEID_OBJECT_MAP[tempemailReportInfo.parentTableId]);
                id = getIntParam(tempemailReportInfo.parentRecordId)
            }
            let linkToUrl = '/' + objectName + '/detailview/' + id;
            let labelName = getStringParam(tempemailReportInfo.records.company);
            labelName = labelName.trim() === '' ? 'Empty' : labelName;

            let tab = { label: labelName, object: objectName, type: TYPE_DETAIL_VIEW, url: linkToUrl };
            addTab(tab, true);
        } catch (error) {
            console.error("Error in 'email.js -> openAsDetailView()':" + error);
        }
    }

    if (!isValidParam(data)) {
        return <div />;
    }

    let rootDivStyle = { lineHeight: '30px', paddingTop: '7px', paddingBottom: '10px', maxHeight: '90%' };
    let headerContainerStyle = { backgroundColor: '#fafafa', color: '#535353', padding: '5px 10px', width: '100%', display: 'table' };
    let headerLeftContainerStyle = { float: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '88%' };
    let headerRightContainerStyle = { float: 'right' };
    let tempcallFrom = getStringParam(callFrom);
    if (tempcallFrom === constants.NOTES) {
        rootDivStyle = { ...rootDivStyle, margin: '0px 15px' };
        headerContainerStyle.padding = 10;
    } else {
        headerLeftContainerStyle.fontWeight = "bold";
        headerLeftContainerStyle.color = "#070100";
    }
    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight - 10) / 2
    if (!mounted) {
        return (
            <div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }

    const handleArchive = () => {
        let params = {
            id: [noteData ? noteData.id : data.id],
            note_type: noteData ? noteData.note_type : "Email",
            email_id: data.id,
            is_archive: selectedNoteAuthor === 'Archived' ? false : true,
            is_email : getListViewEmailChildData ? true : false
        }
        const promise = Promise.resolve(HTTPClient.post(endPoints.NOTES.ARCHIVE, params));
        if (promise) {
            promise.then(res => {
                if (selectedNoteAuthor === 'Archived') {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.UNARCHIVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                } else {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.ARCHIVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                }
                if (getListView) {
                    getListView();
                }

                if(getListViewEmailChildData){
                    getListViewEmailChildData()
                }
                dispatch(getAppDrawer(null, null, null, null, null));
            }
            )
        }
    }

    let tempdata = getObjectParam(data);
    let iconClass = getStringParam(tempdata.t_email_type) === 'in-bound' ? 'fa fa-inbox' : 'fa fa-paper-plane';
    let noteHeader = getNoteHeader();
    let noteBody = getNoteBody();
    let allNoteAttachments = getAllNoteAttachments();
    let iconTitle = getStringParam(tempdata.t_email_type) === 'in-bound' ? 'Received' : 'Sent';
    let _height = window.innerHeight - 170;

    return <div className='row' style={rootDivStyle}>
        <div style={headerContainerStyle}>
            <div style={headerLeftContainerStyle}>
                <div style={{ lineHeight: 1 }}>
                    <i className={iconClass} title={iconTitle} aria-hidden="true" style={{ fontSize: '16px', color: 'rgb(113, 113, 113)', marginRight: '5px' }}></i>
                    {noteHeader}
                </div>
            </div>
            <div style={headerRightContainerStyle}>
                <div style={{ float: 'right' }}>
                    <span title={getLocalizedStrings().label.COMMON.REPLY}><i className="fa fa-reply" aria-hidden="true" style={{ paddingRight: '5px', cursor: 'pointer' }} onClick={() => sendEmail('REPLY')}  ></i></span>
                    <span title={getLocalizedStrings().label.COMMON.REPLY_ALL}><i className="fa fa-reply-all" aria-hidden="true" style={{ paddingRight: '5px', cursor: 'pointer' }} onClick={() => sendEmail('REPLY-ALL')}  ></i></span>
                    <span title={getLocalizedStrings().label.COMMON.FORWARD}><i className="fa fa-share" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => sendEmail('FORWARD')}  ></i></span>
                    {(!isPinned) &&
                        <span
                            title={selectedNoteAuthor === 'Archived' ? getLocalizedStrings().label.COMMON.UNARCHIVE : getLocalizedStrings().label.COMMON.ARCHIVE}
                            key="archive"
                            onClick={() => handleArchive()}
                        >
                            <Icon style={{
                                fontSize: '18px',
                                cursor: 'pointer',
                                paddingTop: '3px',
                                paddingLeft: '1.5px',
                                opacity: 1,
                                width: '25px'
                            }}>{selectedNoteAuthor === 'Archived' ? 'unarchive' : 'archive'}</Icon>
                        </span>}
                </div>
            </div>
        </div>
        <div style={{ color: '#717171', paddingLeft: '10px', paddingTop: '6px' }}>
            <div style={{ whiteSpace: 'normal', wordBreak: 'break-word', overflow: 'auto', height: _height }}>
                <div>
                    {noteBody}
                    {allNoteAttachments}
                </div>
            </div>
        </div>
    </div>;
}

const NoteAttachment = ({ data }) => {
    let attachment = getObjectParam(data);

    const dowload = (attachment) => {
        let fileparams = {}
        var formData = new FormData();
        let url = endPoints.ATTACHMENT.DOWNLOAD_ATTACHMENT;
        let documentName = getStringParam(attachment.name);
        fileparams = { 'url': attachment.url, 'name': documentName };
        formData.append('input_param', JSON.stringify(fileparams));
        if (documentName != '') {
            HTTPClient.postFormDataDownloadFile(url, formData, documentName);
        }
    }
    return (
        <div style={{ width: "100%" }}>
            <i
                className="fa fa-paperclip"
                aria-hidden="true"
                style={{ paddingRight: "5px", cursor: "pointer" }}
            />
            <a href='javascript:void(0);' onClick={() => dowload(attachment)} >{attachment.name}</a>
        </div>
    );
}

export default Email;

