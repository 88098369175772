
import React from 'react';
import PropTypes from 'prop-types';
import { render } from 'react-dom';
import { connect } from 'react-redux';
import {getStringParam} from '../../../services/helper/parameterVerifier';

export class Logo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imgPath: null,
            website: null
        };
    }
    onClick =(website)=>{

        if ((website.toLowerCase().indexOf("http:") !== -1) || (website.toLowerCase().indexOf("https:") !== -1)) {
          window.open(website);
        } else {
          window.open('http://' + website);
        }
    }

    render() {
        let imgPath = null;
        this.state.imgPath = this.props.app.me.logo;
        if (this.state.imgPath === null) {
            this.state.imgPath = "/asset/images/soffront_logo.png";
        }

        let website = getStringParam(this.props.app.me.website);
        if (website === null || website === '') {
            website = "https://www.soffront.com";
        }

        return (
            <div>
                <img src={this.state.imgPath} border='0' style={{ paddingLeft: '0px', float: 'left', maxWidth: '130px', maxHeight: '40px' }} onClick={this.onClick.bind(this,website)} />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        app: state.app,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

Logo = connect(mapStateToProps, mapDispatchToProps)(Logo);