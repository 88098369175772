import React, { useState, useEffect } from "react";
import { styles } from "../../../services/constants/styles";
import { constants } from "../../../services/constants/constants";
import { setHeader } from "../../../services/actions/headerActions";
import { getAppDialog } from "../../../services/actions/appContainerActions";
import ColumnFilter from "../components/columnFilter";
import ColumnGroup from "../components/columnGroup";
import ColumnRecent from "../components/columnRecent";
import { saveGroup } from "../../../services/actions/batchActions";
import {
  addGroupIntoReduxStore,
  hasAccessPermission,
  getSelectedObjectLabel,
  getObjectLabelByObject,
  getSelectedModuleInfo,
} from "../../../services/helper/common";
import { saveUserRecentGroupStatus } from "../../../services/actions/filterMenuAction";
import {
  isValidParam,
  getArrayParam,
  getObjectParam,
  getStringParam,
  getBooleanParam,
} from "../../../services/helper/parameterVerifier";
import PopOver from "../components/PopOver";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import * as sfDialogs from "../components/sfDialogs";
import {
  getActiveTab,
  getActiveTabInfo,
} from "../../../services/helper/sfTabManager";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { getAppDrawer } from "../../../services/actions/appContainerActions";
import ColumnForecasts from "../components/columnForecasts";
import ColumnReports from "../components/columnReports";
import { getCookie, setCookie } from "../../../services/helper/sfCookies";
import { useDispatch, useSelector } from "react-redux";

const FilterMenu = ({ object }) => {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const filters = useSelector((state) => state.filters);
  let selectedModuleInfo = getSelectedModuleInfo();
  const [isSavingGroup, setIsSavingGroup] = useState(false);
  const [groupStatus, setGroupStatus] = useState("");
  const [searchTextMap, setSearchTextMap] = useState({
    accountsFilter: "",
    accountsGroup: "",
    contactsFilter: "",
    contactsGroup: "",
    report: "",
  });
  const [menuItems, setMenuItems] = useState([]);
  const [objectLabel, setObjectLabel] = useState(
    getStringParam(selectedModuleInfo.item.label)
  );
  const isShowGroup =
    object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT
      ? true
      : false;
  let isShowForecasts = false;
  if (object === constants.OPPORTUNITIES_OBJECT) isShowForecasts = false;
  const isShowReportNames = object === constants.SALES_OBJECT ? true : false;

  const searchToolTip =
    object === constants.SALES_OBJECT
      ? getLocalizedStrings().label.HOME.CLICK_TO_FIND_REPORT
      : getLocalizedStrings().label.HOME.CLICK_TO_FIND_FILTER;

  useEffect(() => {
    if (objectLabel?.toLowerCase() === constants.ONBOARDING_OBJECT) {
      let tab = getActiveTab();
      setObjectLabel(tab.object);

      if (
        isValidParam(
          getLocalizedStrings().label.SIDE_NAV[tab.object.toUpperCase()]
        )
      ) {
        setObjectLabel(
          getLocalizedStrings().label.SIDE_NAV[tab.object.toUpperCase()]
        );
      }
    }
  }, [objectLabel]);

  useEffect(() => {
    if (selectedModuleInfo.item) {
      setObjectLabel(getStringParam(selectedModuleInfo.item.label));
    }
  }, [selectedModuleInfo]);

  useEffect(() => {
    setListViewHeader(object);
    setGroupStatus(getGroupSavedStatus(object));
  }, [object]);

  /**
   * Check menu item var on update. Work like componentDidUpdate
   */
  useEffect(() => {
    if (menuItems.length === 0) {
      openGroupStatus();
    }
  }, [menuItems]);

  const getGroupSavedStatus = (val) => {
    let objectVal = getStringParam(val);
    let groupSavedStatus = getObjectParam(filters.user_group_status);
    let status = getStringParam(groupSavedStatus[objectVal]);
    status = status === "" ? "All" : status;
    return status;
  };

  const updateGroupStatus = () => {
    let objectVal = getStringParam(object);
    let groupStatus = getGroupSavedStatus(objectVal);
    setGroupStatus(groupStatus);
  };

  const setParentSearchResult = (result, columnName) => {
    if (isValidParam(result) && isValidParam(columnName)) {
      let searchResultArray = getArrayParam(result);
    }
  };

  const setListViewHeader = (object) => {
    let activeTab = getObjectParam(getActiveTab());
    let label = getStringParam(activeTab.label);
    let kanbanObject = {};
    kanbanObject.currentView = null;
    kanbanObject.show = false;
    dispatch(setHeader(object, label, kanbanObject, true, true));
  };

  const handleAddGroup = () => {
    let hasPermission = null;
    hasPermission = hasAccessPermission(
      constants.GROUP_OBJECT,
      constants.SECURITY_LEVEL_TYPE_ACCESS,
      constants.ACCESS_TYPE_CREATE
    );
    if (hasPermission) {
      dispatch(
        getAppDialog(
          true,
          constants.ADD_GROUP_DIALOG,
          getLocalizedStrings().label.HOME.ADD_GROUP,
          addGroup,
          null,
          null
        )
      );
    } else {
      sfDialogs.alert(
        getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
        getLocalizedStrings().message.COMMON.ACCESS_DENIED,
        null
      );
    }
  };

  const handleAddFilter = () => {
    let hasPermission = null;
    hasPermission = hasAccessPermission(
      null,
      constants.SECURITY_LEVEL_TYPE_PERMISSION,
      constants.PERMISSION_NAME_MANAGE_FILTERS
    );

    if (hasPermission) {
      let label = getStringParam(getSelectedObjectLabel());
      if (label.toLowerCase() === constants.ONBOARDING_OBJECT) {
        let tab = getActiveTab();
        label = getObjectLabelByObject(tab.object);
      }
      let data = {};
      data.object = "filter";
      data.id = null;
      data.table = object;
      if (isValidParam(getLocalizedStrings().label.HEADER[label])) {
        label = getLocalizedStrings().label.HEADER[label];
      }
      let title =
        getLocalizedStrings().label.HOME.ADD_FILTER +
        " " +
        getLocalizedStrings().label.PROFILE_DETAILS.ON +
        " " +
        label;

      dispatch(getAppDrawer(true, title, constants.FILTER, data, null));
    } else {
      sfDialogs.alert(
        getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
        getLocalizedStrings().message.COMMON.ACCESS_DENIED,
        null
      );
    }
  };

  const addGroup = (obj) => {
    if (!isSavingGroup) {
      setIsSavingGroup(true);
      var groupname = obj.group_name;
      groupname = groupname.trim();
      let params = {
        id: 0,
        name: groupname,
        description: groupname,
        t_status: "Active",
      };
      var response = saveGroup(object, params);
      response.then((response) => {
        if (response.status === 0) {
          if (response.data.id !== -1) {
            dispatch(
              getAppDialog(
                false,
                constants.ADD_GROUP_DIALOG,
                null,
                null,
                null,
                null
              )
            );
            addGroupIntoReduxStore(
              response.data.id,
              obj.group_name,
              params.t_status
            );
            dispatch(
              showCustomSnackBar(
                getLocalizedStrings().message.HOME.ADD_GROUP_SUCCESS,
                styles.snackbarBodyStyleSuccess,
                styles.snackBarStyleTop
              )
            );
          } else {
            sfDialogs.alert(
              getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
              getLocalizedStrings().message.BATCH_ACTION_VALIDATION
                .GROUP_ALREADY_EXIST,
              null
            );
          }
        }
        setIsSavingGroup(false);
      });
    }
  };

  const openGroupStatus = () => {
    let themeColor = app.me.background;
    let arrStatus = [
      { label: getLocalizedStrings().label.GROUP.ALL, value: "All" },
      { label: getLocalizedStrings().label.GROUP.ACTIVE, value: "Active" },
      { label: getLocalizedStrings().label.GROUP.INACTIVE, value: "Inactive" },
    ];

    let groupStatusMenuItems = [];
    arrStatus.forEach((status, index) => {
      let labelStyle = { color: "#666666", fontSize: "12px" };
      if (groupStatus === status.value) {
        labelStyle.color = themeColor;
        labelStyle.fontWeight = "bold";
      }
      let data = {};
      data.label = status.label;
      data.value = status.value;
      data.labelStyle = labelStyle;
      groupStatusMenuItems.push(data);
    });
    setMenuItems(groupStatusMenuItems);
  };

  const onChangeGroupStatus = (value) => {
    let promise = Promise.resolve(
      saveUserRecentGroupStatus(object, {
        type: 110,
        group_status: value,
      })
    );
    if (promise !== null) {
      promise.then((response) => {
        if (isValidParam(response) && response.data) {
          let objectVal = getStringParam(object);
          let groupSavedStatus = getObjectParam(filters.user_group_status);
          groupSavedStatus[objectVal] = value;
          filters.user_group_status = groupSavedStatus;
        }

        setGroupStatus(value);
        setMenuItems([]);
      });
    }
  };

  const getGroupStatusMenu = () => {
    let setupIconStyle = {
      cursor: "pointer",
      marginLeft: "3px",
      fontSize: "17px",
      color: "#ccc ",
    };
    let groupStatusString = getStringParam(groupStatus);
    if (groupStatusString !== "" && groupStatusString !== "All") {
      setupIconStyle.color = "#259be7";
    }
    return (
      <span
        key="group-filter-icon"
        onClick={openGroupStatus}
        style={{
          marginTop: "5px",
          width: "10%",
          marginRight: "4px",
          marginLeft: "3px",
        }}
      >
        <PopOver
          id={"setting"}
          key={"setting"}
          name={"setting"}
          title={"Filter your groups"}
          buttonEndIcon={"settings"}
          buttonStyle={setupIconStyle}
          labelPosition={"before"}
          buttonLabel={""}
          options={menuItems}
          onclickAction={onChangeGroupStatus}
          isShowIcon={true}
        />
      </span>
    );
  };

  const onChangeSearchText = (value, name) => {
    let key = object + name;
    let _searchTextMap = searchTextMap;
    if (isValidParam(key)) {
      if (key == "accountsFilter" || key == "contactsFilter") {
        _searchTextMap[key] = value;
        setSearchTextMap({ ..._searchTextMap });
      } else {
        setSearchTextMap({ ...searchTextMap, [key]: value });
      }
    }
  };

  let _getGroupStatusMenu = getGroupStatusMenu();

  return (
    <div
      style={{
        paddingTop: "0px",
        paddingLeft: "11px",
        height: "80%",
        overflow: "auto",
      }}
    >
      <div className="container" style={{ width: "99%" }}>
        <div style={{ ...styles.row }}>
          {!isShowReportNames && (
            <div
              style={
                !isShowGroup && !isShowForecasts && !isShowReportNames
                  ? {
                      ...styles.sf_8,
                      ...styles.sf_clm_manage,
                    }
                  : {
                      ...styles.sf_4,
                      ...styles.sf_clm_manage,
                    }
              }
            >
              <FilterHeader
                key="filter-header"
                label={getLocalizedStrings().label.HOME.FILTERS}
                links={[
                  {
                    label: getLocalizedStrings().label.HOME.ADD_NEW,
                    event: handleAddFilter,
                  },
                ]}
                onChangeSearchText={(val) => {
                  onChangeSearchText(val, "Filter");
                }}
                searchToolTip={searchToolTip}
                object={object}
                columnName="filter"
                app={app}
              />
              <ColumnFilter
                object={object}
                searchText={searchTextMap[object + "Filter"]}
                setParentSearchResult={setParentSearchResult}
              />
            </div>
          )}

          {isShowGroup && (
            <div
              style={
                !isShowGroup && !isShowForecasts && !isShowReportNames
                  ? { ...styles.sf_8, ...styles.sf_clm_manage }
                  : {
                      ...styles.sf_4,
                      ...styles.sf_clm_manage,
                      width: isShowReportNames ? "70%" : "35%",
                    }
              }
            >
              <FilterHeader
                key="group-header"
                label={getLocalizedStrings().label.HOME.GROUPS}
                links={[
                  {
                    label: getLocalizedStrings().label.HOME.ADD_NEW,
                    event: handleAddGroup,
                  },
                ]}
                onChangeSearchText={(val) => {
                  onChangeSearchText(val, "Group");
                }}
                rightItems={_getGroupStatusMenu}
                searchToolTip={searchToolTip}
                object={object}
                columnName="group"
                app={app}
              />
              <ColumnGroup
                object={object}
                status={groupStatus}
                searchText={searchTextMap[object + "Group"]}
                setParentSearchResult={setParentSearchResult}
                updateGroupStatus={updateGroupStatus}
              />
            </div>
          )}
          {isShowForecasts && (
            <div
              style={
                !isShowGroup && !isShowForecasts && !isShowReportNames
                  ? {
                      ...styles.sf_8,
                      ...styles.sf_clm_manage,
                    }
                  : {
                      ...styles.sf_4,
                      ...styles.sf_clm_manage,
                      width: isShowReportNames ? "70%" : "",
                    }
              }
            >
              <FilterHeader
                key="forecasts-header"
                label={getLocalizedStrings().label.HOME.FORECASTS}
                searchToolTip={searchToolTip}
                object={object}
              />
              <ColumnForecasts object={object} />
            </div>
          )}
          {isShowReportNames && (
            <div
              style={
                !isShowGroup && !isShowForecasts && !isShowReportNames
                  ? {
                      ...styles.sf_8,
                      ...styles.sf_clm_manage,
                    }
                  : {
                      ...styles.sf_4,
                      ...styles.sf_clm_manage,
                      width: isShowReportNames ? "70%" : "",
                    }
              }
            >
              <FilterHeader
                key="reports-header"
                label={getLocalizedStrings().label.REPORT.AVAILABLE_REPORTS}
                onChangeSearchText={(e) => {
                  onChangeSearchText(e, "report");
                }}
                searchToolTip={searchToolTip}
                object={object}
                columnName="report"
                app={app}
              />
              <ColumnReports
                object={object}
                searchText={searchTextMap[object + "report"]}
              />
            </div>
          )}
          <div
            style={
              !isShowGroup && !isShowForecasts && !isShowReportNames
                ? {
                    ...styles.sf_8,
                    ...styles.sf_clm_manage,
                  }
                : {
                    ...styles.sf_4,
                    ...styles.sf_clm_manage,
                  }
            }
          >
            <FilterHeader
              key="column-recent-header"
              s
              label={
                getLocalizedStrings().label.HOME.RECENTLY_VIEWED +
                " " +
                objectLabel
              }
              searchToolTip={searchToolTip}
              object={object}
            />
            <ColumnRecent object={object} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterMenu;

const FilterHeader = ({
  label,
  links,
  rightItems,
  searchToolTip,
  onChangeSearchText,
  object,
  columnName,
  app,
}) => {
  const [isShowSearchElement, setIsShowSearchElement] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [styleLink, setStyleLink] = useState({
    fontSize: 20,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  });
  const [styleInput, setStyleInput] = useState({
    float: "right",
    display: "flex",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
  });

  useEffect(() => {
    if (searchText) {
      setStyleInput({ ...styleInput, border: "2px solid #346ea9" });
    } else {
      setStyleInput({ ...styleInput, border: "2px solid #E0E0E0" });
    }
  }, [searchText]);

  useEffect(() => {
    updateSearchText(onChangeSearchText, object, columnName, app);
  }, []);

  useEffect(() => {
    setIsShowSearchElement(false);
    setSearchText("");
    updateSearchText(onChangeSearchText, object, columnName, app);
  }, [object]);

  const updateSearchText = (onChangeSearchText, object, columnName, app) => {
    let style = "1px solid #E0E0E0";
    let searchTextVal = "";
    if (isValidParam(columnName)) {
      let cookieName =
        "homepage_search_" + object + "_" + columnName + "_" + app.me.projectId;
      let cookieValue = getCookie(cookieName);
      if (cookieValue === null) {
        cookieValue = "";
      }
      let searchElement = document.getElementById(cookieName);
      if (isValidParam(cookieValue) && cookieValue !== "") {
        searchTextVal = cookieValue;
        if (isValidParam(searchElement) && searchElement !== null) {
          style = "2px solid #346ea9";
        }
      }
      if (isValidParam(onChangeSearchText) && searchTextVal) {
        onChangeSearchText(searchTextVal);
      }
      setSearchText(searchTextVal);
      setIsShowSearchElement(true);
    }
  };

  const getLinks = () => {
    let linksParams = getArrayParam(links);
    let props = null;
    return linksParams.map((m) => {
      props = {};
      if (isValidParam(m.label)) {
        props.children = m.label;
      }
      if (isValidParam(m.event)) {
        props.onClick = () => m.event();
      }
      return <a href="javascript:void(0);" style={styles.addNew} {...props} />;
    });
  };

  const handleSearchElement = () => {
    setIsShowSearchElement(true);
  };

  const handleFocusModule = (e, callFrom) => {
    let id =
      "homepage_search_" + object + "_" + columnName + "_" + app.me.projectId;
    let searchElement = document.getElementById(id);
    if (isValidParam(searchElement) && searchElement !== null) {
      if (callFrom === "focus") {
        searchElement.style.border = "2px solid #346ea9";
      } else if (callFrom === "blur") {
        searchElement.style.border =
          searchText !== null && searchText !== ""
            ? "2px solid #346ea9"
            : "1px solid #E0E0E0";
      }
    }
  };

  const onChangeSearchTextFnc = (value) => {
    let id =
      "homepage_search_" + object + "_" + columnName + "_" + app.me.projectId;
    setCookie(id, value);
    setSearchText(value);

    if (isValidParam(onChangeSearchText)) {
      onChangeSearchText(value);
    }
  };

  const getSearchElement = () => {
    let btnWidth = "130px";
    let language = getLocalizedStrings()._language;

    if (
      language === "de-DE" ||
      language === "fr-FR" ||
      language === "pt-PT" ||
      language === "es-ES" ||
      language === "vi-VN"
    ) {
      btnWidth = "150px";
    }
    if (isValidParam(onChangeSearchText)) {
      if (isShowSearchElement) {
        return (
          <div
            id="sf_filter_search"
            style={{ borderRadius: "4px", width: btnWidth }}
          >
            <input
              className="listview-search-input"
              onFocus={(e) => {
                handleFocusModule(e, "focus");
              }}
              onBlur={(e) => {
                handleFocusModule(e, "blur");
              }}
              type="text"
              id="searchElement"
              value={searchText}
              onChange={(e) => {
                onChangeSearchTextFnc(e.target.value);
              }}
              placeholder={getLocalizedStrings().label.COMMON.TYPE_TO_SEARCH}
              style={{
                outline: "0 none",
                border: "none",
                paddingTop: "0px",
                paddingLeft: "9px",
                width: "100%",
                height: "26px",
                fontSize: "12px",
              }}
            />
          </div>
        );
      } else {
        return (
          <i
            className="fa fa-search"
            style={{ color: "#ccc", marginTop: "12px", cursor: "pointer" }}
            onClick={handleSearchElement}
            title={searchToolTip}
          ></i>
        );
      }
    }
  };

  return (
    <div
      style={{
        height: 39,
        borderBottom: "1px solid #ccc",
        padding: "2px 8px 8px",
      }}
    >
      <div style={{ float: "left" }}>
        <div title={getStringParam(label)} style={styleLink}>
          {getStringParam(label)}
          {getLinks()}
        </div>
      </div>
      <div
        id={
          app !== undefined
            ? "homepage_search_" +
              object +
              "_" +
              columnName +
              "_" +
              app.me.projectId
            : "homepage_search_" + object + "_" + columnName
        }
        style={styleInput}
      >
        <div style={{ display: "flex" }}>
          {getSearchElement()}
          {rightItems}
        </div>
      </div>
    </div>
  );
};
