import { actions } from '..//constants/actions';

const listViewInitialSate = {
    mounted: false,
    data: {
        object: '',
    },
    selectedFilter:'',
    selectedTaskType:'',
    selectedTask:'',
    selectedTaskFilter:'In-progress',
    selectedAuditchildTaskStatus:'Pending',
    error: null,
    actionsOnSelectedRows: [],
    selectedRows: null,
    queryName: '',
    isSetHeader: false,
    isRefreshDetailViewListView: false,
    editFromRecordId: 0,
    isShowGroupBy: false,
    groupByInfo: {
        object: '',
    },
    isGroupByMounted: false,
    selectedTaskType: '',
    selectedTask: '',
    workflowList: []
};

const childListViewReducer = (state = listViewInitialSate, action) => {
    switch (action.type) {
        case actions.CHILD_LISTVIEW_GET_FULFILLED:
            return {
                ...state,
                mounted: true,
                data: action.payload,
                actionsOnSelectedRows: [],
                selectedRows: [],
                queryName: action.payload !== null ? action.payload.query_name : '',
                isSetHeader: true,
                isShowGroupBy: false,
            };
        case actions.CHILD_LISTVIEW_GET_SELECTED_RECORD:
            return {
                ...state,
                selectedRows: action.payload,
            };
        case actions.CHILD_LISTVIEW_SET_MOUNTED:
            return {
                ...state,
                mounted: action.payload,
            };
        case actions.DETAILVIEWLISTVIEW_IS_REFERSH:
            return {
                ...state,
                isRefreshDetailViewListView: action.payload.isRefresh,
                editFromRecordId: action.payload.id,
            };
        case actions.CHILD_LISTVIEW_GROUPBY_INFO_GET_FULFILLED:
            return {
                ...state,
                isGroupByMounted: true,
                groupByInfo: action.payload,
            };
        case actions.UPDATE_FILTERED_RECORDS_FOR_AUDIT:
            return {
                ...state,
                data: {
                    ...state.data,
                    records: action.payload,
                }
            }
        case actions.SET_SELCTED_AUDIT_FILTER:
            return {
                ...state,
                selectedFilter: action.payload,
            }
        case actions.SET_SELECTED_TASK_TYPE:
            return {
                ...state,
                selectedTask: action.payload,
            }
        case actions.SET_SELECTED_AUDIT_TASK_TYPE:
            return {
                ...state,
                selectedTaskType: action.payload,
            }
        case actions.CHILD_LISTVIEW_TASK_WORKFLOW_LIST_UPDATE:
            return {
                ...state,
                workflowList: action.payload,
            }
        case actions.SET_SELECTED_TASK_TYPE:
            return {
                ...state,
                selectedTask:action.payload,
            }
        case actions.SET_SELECTED_AUDIT_TASK_TYPE:
            return {
                ...state,
                selectedTaskType : action.payload,
            }
        case actions.SET_SELECTED_TASK_FILTER:
            return {
                ...state,
                selectedTaskFilter : action.payload,
            }
        case actions.SET_AUDIT_CHILD_TASK_FILTER:
            return {
                ...state,
                selectedAuditchildTaskStatus : action.payload,
            }
        default:
            return state;
    }
};

export default childListViewReducer;
