import { actions } from '..//constants/actions'

const appInitialState = {
    snackbar: {
        open: false,
        message: '',
        duration: 400,
        bodyStyle: null,
        style: '',
    }
}

const snackBarReducer = (state = appInitialState, action) => {
    switch (action.type) {
        case actions.SNACKBAR_MESSAGE_VIEW:
           
            return {
                ...state,
                snackbar: action.payload
            }
        case actions.SNACKBAR_CUSTOM_VIEW:
           
            return {
                ...state,
                snackbar: action.payload
            }

        default:
            return state;
    }
}

export default snackBarReducer;