
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { getStringParam, getIntParam } from '../../services/helper/parameterVerifier';

function SFSlidingPopup({ onClose, children }) {

  useEffect(() => {
        setTimeout(() => {
          onClose();
        },2500);
  }, []);
  return (
    <div className={`sliding-popup open`}>
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>
          x
        </button>
        <div className='popup-content-body'>
          {children}
        </div>
      </div>
    </div>
  );
}

function SFSlidingPopupContainer() {
  const app = useSelector((state) => state.app);
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    let _cards = [...app.notification];
    let _cardData = [...cardData]
   if(_cards.length>0){
      _cards.map((item, i) => {
        _cardData.push({...item})
      });
      setCardData([..._cardData]);
    }

  }, [app.notification]);

  useEffect(() => {
    let _cardData = [...cardData]
   if(_cardData.length==1){
    setTimeout(() => {
      setCardData([]);
    },2500);
    }
  }, [cardData]);

  const removeElement = (idx) => {
    let _arr = [...cardData];
    _arr.splice(idx, 1);
    setCardData([ ..._arr ])
  }
  if (cardData.length == 0) {
    return <></>
  } else {
    return (<>
      {cardData.length > 0 && <div className='popup-container'>
        <div>
          {cardData.map((item, i) => {
            return (
              <SFSlidingPopup
                onClose={() => removeElement(i)}
              >
                {item.type == 'appointments' &&
                  <div>
                    <div>{"Appointment: " + getStringParam(item.subject) + ", " + getStringParam(item.assign)}</div>
                    <div>{"Start Date: " + getStringParam(item.start_date)}</div>
                    <div>{"End Date: " + getStringParam(item.end_date)}</div>
                  </div>}
                {item.type == 'tasks' &&
                  <div>
                    <div>{"Name: " + getStringParam(item.task_name) + ", " + getStringParam(item.company)}</div>
                    <div>{"Due by: " + getStringParam(item.when)}</div>
                  </div>
                }
                {item.type == 'reminder' &&
                  <div>
                    <span>{'Reminder'}</span>
                    <div>{'The ' + getStringParam(item.field_label) + ' for' + getStringParam(item.value) + ' is coming up in ' + getIntParam(item.reminder_day) + 'day(s).'}</div>
                  </div>
                }
              </SFSlidingPopup>
            );
          })}
        </div>
      </div>}
    </>);
  }
}

export default SFSlidingPopupContainer;