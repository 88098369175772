import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { reschedule } from '../../../services/actions/batchActions';
import { styles } from '../../../services/constants/styles';
import { Select, MenuItem, InputLabel } from '@mui/material';
import { dateTimeFormatFlatPicker } from '../../../services/helper/utils';
import moment from 'moment';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { refreshListView } from '../../../services/actions/listViewAcions';
import { isValidParam, isInteger, getBooleanParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getActiveTabInfo } from '../../../services/helper/sfTabManager';
import PopOver from './PopOver';
import { constants } from '../../../services/constants/constants';
import { useParams } from 'react-router-dom'

const getListViewselectedRecordsFromRedux = (state, isDetailView) => {
    let tabInfo = getActiveTabInfo();
    let isReport = getBooleanParam(tabInfo.isReport);
    if (getBooleanParam(isDetailView)) {
        return state.childListView.selectedRecords;
    } else if (getBooleanParam(isReport)) {
        return state.otherListView.selectedRecords;
    } else {
        return state.listView.selectedRecords;
    }
}

const Reschedule = ({ object, closeDrawer, isDetailView, handleSubmit,selectedRecords }) => {

    const dispatch = useDispatch();

    const { suffix } = useParams();
    const [selectedValue, setSelectedValue] = useState('nextday');
    const [mounted, setMounted] = useState(true);
    const [showDayTextField, setShowDayTextField] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [scheduleDate, setScheduleDate] = useState(null);
    const [no_of_days, setNo_of_days] = useState('');

    const app = useSelector((state) => state.app);
    const selectedRecordsId = useSelector((state) => getListViewselectedRecordsFromRedux(state, isDetailView));

   

    const changeReschedule = (value) => {
        if (value === "days") {
            setShowDayTextField(true);
            setShowDatePicker(false);
        } else if (value === "date") {
            setShowDayTextField(false);
            setShowDatePicker(true);
        } else {
            setShowDayTextField(false);
            setShowDatePicker(false);
        }

        setSelectedValue(value);

    }

    useEffect(() => {
        // handleSubmit();
    }, [selectedValue]);

    const doReschedule = () => {
        let tempNno_of_day = no_of_days;
        let selectedDate = "";
        let selectedOptions = selectedValue;
        let recordIds = getArrayParam(selectedRecords);
        recordIds = recordIds.length === 0 ? getArrayParam(selectedRecordsId) : recordIds;

        let validForm = true;

        if (selectedOptions === 'days') {


            if (!isValidParam(tempNno_of_day) || tempNno_of_day === "") {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.APPOINTMENTS_TASKS.BLANK_NO_OF_DAYS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                validForm = false;
            } else if (tempNno_of_day === 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.APPOINTMENTS_TASKS.ZERO_NO_OF_DAYS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                validForm = false;
            } else if (!isInteger(tempNno_of_day)) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.VALID_INTEGER, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                validForm = false;
            }
        } else if (selectedOptions === 'date') {
            tempNno_of_day = 0;
            selectedDate = scheduleDate;
            if (!isValidParam(selectedDate) || selectedDate === "Invalid date" || selectedDate === "") {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.APPOINTMENTS_TASKS.BLANK_SPECIFIC_DATE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                validForm = false;
            } else {
                let date = new Date(selectedDate);
                let dateValue = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes())
                const momentDate = moment(dateValue);
                selectedDate = momentDate.format("YYYY-MM-DD HH:mm:ss");

            }
        } else {
            tempNno_of_day = 0;
        }

        if (validForm) {
            var recordIdArr = [];
            for (var i = 0; i < recordIds.length; i++) {
                recordIdArr.push(recordIds[i].id);
            }

            let params = {
                "id": recordIdArr,
                "type": selectedOptions,
                "no_of_days": tempNno_of_day,
                "date": selectedDate
            }

            var response = reschedule(object, params);
            setMounted(false);
            response.then((response) => {

                if (response.status === 0) {
                    closeDrawer();
                    refreshListView(object, { isDetailView: false },null,suffix)
                    console.log("object",object)
                    if(object== constants.TASKS){
                        dispatch(showCustomSnackBar("Task is rescheduled successfully", styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    } else if(object == constants.APPOINTMENTS ){
                    dispatch(showCustomSnackBar("Appointment is rescheduled successfully", styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));

                    }

                } else {

                }
            });
        }
    }

    const handleDateChange = (date) => {
        setScheduleDate(date);
    }

    let HHFormat = new RegExp("HH");
    let datePickerOptions = {
        enableTime: true,
        dateFormat: dateTimeFormatFlatPicker[app.me.date_format],
        minuteIncrement: 1,
        time_24hr: HHFormat.test(app.me.date_format) ? true : false
    };

    return (
        <div style={{ width: "84%", marginLeft: '42px', marginTop: '20px' }}>
            {/*<span >Reschedule for: </span>*/}

            <div style={{ marginRight: '10px', marginLeft: '-22px' }}>

                <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel id="sf-reschedule-simple-select-outlined-label" className="sf-reschedule">{getLocalizedStrings().label.APPOINTMENTS_TASKS.RESCHEDULE_FOR}</InputLabel>
                    <Select
                        id='daySelect'
                        name='daySelect'
                        value={selectedValue}
                        onChange={(e) => changeReschedule(e.target.value)}
                        style={{ height: 42 }}
                        className={"sf-fields-bg"}
                        label={getLocalizedStrings().label.APPOINTMENTS_TASKS.RESCHEDULE_FOR}
                        fullWidth={true}
                    >
                        <MenuItem value={'nextday'}  >{getLocalizedStrings().label.APPOINTMENTS_TASKS.NEXT_DAY}</MenuItem>
                        <MenuItem value={'nextweek'} >{getLocalizedStrings().label.APPOINTMENTS_TASKS.NEXT_WEEK}</MenuItem>
                        <MenuItem value={'nextmonth'} >{getLocalizedStrings().label.APPOINTMENTS_TASKS.NEXT_MONTH}</MenuItem>
                        <MenuItem value={'days'} >{getLocalizedStrings().label.APPOINTMENTS_TASKS.DAYS}</MenuItem>
                        <MenuItem value={'date'} >{getLocalizedStrings().label.APPOINTMENTS_TASKS.SPECIFIC_DATE}</MenuItem>
                    </Select>
                </FormControl>
            </div>

            <div style={{ marginTop: '20px', marginLeft: '-21px', width: '104%' }}>
                {showDayTextField &&
                    <div>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField type='text' placeholder={getLocalizedStrings().label.APPOINTMENTS_TASKS.DAYS}
                                id='days'
                                name='days'
                                // ref={this.days}
                                inputprops={{ maxlength: '3' }}
                                onChange={(e) => setNo_of_days(e.target.value)}
                                style={{ width: "250px" }}
                                autoComplete="new-password" className={"sf-fields-bg"} /></FormControl>
                    </div>
                }
                {showDatePicker &&
                    <div >
                        <PopOver
                            id={'task_start_date'}
                            key={'task_start_date'}
                            name={'task_start_date'}
                            btnType={'dateTime'}
                            buttonStyle={{ fontSize: '16px' }}
                            containerStyle={{ lineHeight: 1, marginBottom: '20px' }}
                            buttonLabel={null}
                            onChange={(date) => handleDateChange(date)}
                            value={scheduleDate}
                            innerLabelStyle={{ padding: "8 5 2 5", fontSize: '15px' }}
                            height={40}
                            options={datePickerOptions}
                        />
                    </div>
                }
            </div>
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <Button
                    onClick={() => doReschedule()}
                    style={styles.primaryButton}
                >{getLocalizedStrings().label.APPOINTMENTS_TASKS.RESCHEDULE}</Button>
            </div>
        </div>
    );

}
export default Reschedule;