import React, {useState, useEffect, useRef, useCallback} from 'react';
import * as HTTPClient from '../../../services/helper/httpClient';
import { connect } from 'react-redux';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { setTabs } from '../../../services/actions/tabActions';
import { setData } from '../../../services/actions/dataActions';
import { endPoints } from '../../../services/constants/endPoints';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { isValidParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { addNode } from '../../../services/actions/automationCampaignActions';
import { getAppDrawer, getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { retriveEscapeHtml, sortArrayObjectByProperty } from '../../../services/helper/utils';
import { LinkToType } from '../../../services/constants/link';
import Autocomplete from '@mui/material/Autocomplete';
import { Button, Chip, FormControl, TextField } from '@mui/material';
import { getNodeByChildId } from '../../../services/helper/automationUtils';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import ShowCircularProgress from '../components/circularProgress';
import { useDispatch, useSelector } from "react-redux";


function EditorTemplateDialog  ({ object, setTemplateData }) {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);
    const tab = useSelector((state) => state.tab);
    const automationCampaign = useSelector((state) => state.automationCampaign);
    const header = useSelector((state) => state.header);
    const data = useSelector((state) => state.data);

    const [selected, setSelected] = useState(constants.EDITOR_BEE);
    const [editorType, setEditorType] = useState(constants.EDITOR_BEE);
    const [isTemplateChange, setIsTemplateChange] = useState(false);


   
    useEffect(() => {
        if (object === constants.BROADCAST_CAMPAIGNS_OBJECT || object === constants.MARKETING_TEMPLATE_OBJECT) {
            if (appContainer.dialogProps.callFrom === constants.BROADCAST_CAMPAIGNS_OBJECT || appContainer.dialogProps.callFrom === constants.MARKETING_TEMPLATE_OBJECT) {
                let _editorType = appContainer.dialogProps.data.type;
                _editorType = isValidParam(_editorType) ? _editorType : constants.EDITOR_BEE;
                appContainer.dialogProps.data = { ...appContainer.dialogProps.data, type: constants.EDITOR_BEE }
                setSelected(constants.EDITOR_BEE);
                setIsTemplateChange(false);
                setEditorType(_editorType);
            } else {
                appContainer.dialogProps.data.data = { ...appContainer.dialogProps.data.data, type: selected }
            }
        } else if (object === constants.AUTOMATION_DESIGNER_OBJECT) {
            let tempEditorType = automationCampaign.data.editorType;
            let _editorType = appContainer.dialogProps.data.type;
            _editorType = isValidParam(_editorType) ? _editorType : constants.EDITOR_BEE
            appContainer.dialogProps.data.data = {
                ...appContainer.dialogProps.data.data,
                type: isValidParam(tempEditorType) ? tempEditorType : constants.EDITOR_BEE
            }
            if (isValidParam(tempEditorType)) {
                setSelected(constants.EDITOR_BEE);
                setIsTemplateChange(true);
                setEditorType(_editorType)
            }
        }
        dispatch(setAppDialogActions());
    }, []);

    // useEffect(() => {
    //     if (object == constants.BROADCAST_CAMPAIGNS_OBJECT || object == constants.MARKETING_TEMPLATE_OBJECT) {
    //        appContainer.dialogProps.data.data = { ...appContainer.dialogProps.data.data, type: selected };
    //     }
    // }, [object]);

    return (
        <div>
            <div>
                {((selected === constants.EDITOR_LEGACY || selected === constants.EDITOR_BEE) && !isTemplateChange) &&
                    <FroalaTemplateSilector
                        appContainer={appContainer}
                        object={object}
                        automationCampaign={automationCampaign}
                        editorType={editorType}
                        setTemplateData={setTemplateData}
                        data={data}
                    />
                }
                {
                    ((selected === constants.EDITOR_LEGACY || selected === constants.EDITOR_BEE) &&
                        object === constants.AUTOMATION_DESIGNER_OBJECT &&
                        isTemplateChange) &&
                    <FroalaTemplateSilector
                        appContainer={appContainer}
                        object={object}
                        automationCampaign={automationCampaign}
                        editorType={editorType}
                        setTemplateData={setTemplateData}
                        data={data}
                    />
                }
                {(selected === constants.EDITOR_NEW) &&
                    <MosaicoTemplateSelector
                        appContainer={appContainer}
                        object={object}
                        automationCampaign={automationCampaign}
                        editorType={selected}
                        setTemplateData={setTemplateData}
                    />
                }
            </div>
        </div>
    );

}
export default EditorTemplateDialog;

const MosaicoTemplateSelector = ({ editorType, object,appContainer,automationCampaign,setTemplateData,data }) => {
    const dispatch = useDispatch();

    const [templates, setTemplates] = useState([]);
    const [selected_template, setSelected_template] = useState("");
    const [is_loading, setIs_loading] = useState(true);
    const [callFrom, setCallFrom] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [templateType, setTemplateType] = useState('');

    useEffect(() => {

        callFrom = appContainer.dialogProps.callFrom;
        loadTemplate();
        dispatch(setAppDialogActions(getActions()));
    }, []);



    const loadTemplate = () => {
        let type = 'library';
        let params = {
            category: ['Newsletter'],
            type: editorType
        }

        try {
            is_loading = true;
            dispatch(setAppDialogActions(getActions()));

            let templateNames = [];
            let library_templates = { label: getLocalizedStrings().label.COMMON.TEMPLATE.LIBRARY_TEMPLATE, options: [] };
            let user_templates = { label: getLocalizedStrings().label.COMMON.TEMPLATE.USER_TEMPLATE, options: [] };
            let data = appContainer.dialogProps.data;
            var promise = Promise.resolve(HTTPClient.get(endPoints.MARKETING_TEMPLATE.ALL_TEMPLATE, params));
            promise.then(response => {
                if (Array.isArray(response.user_templates)) {
                    response.user_templates.forEach(function (record) {
                        user_templates.options.push({
                            label: retriveEscapeHtml(record.t_name),
                            value: record.t_pk,
                            type: 'user',
                        });
                    });
                }
                getArrayParam(response.library_templates).forEach(function (record) {
                    library_templates.options.push({
                        label: retriveEscapeHtml(record.t_name),
                        value: record.t_pk,
                        type: 'library',
                    });
                });

                sortArrayObjectByProperty(user_templates.options, 'label');
                sortArrayObjectByProperty(library_templates.options, 'label');

                templateNames.push(user_templates);
                templateNames.push(library_templates);

                if (editorType === constants.EDITOR_NEW) {

                    library_templates.options.push({
                        label: 'Default',
                        value: 'versafix-1',
                        type: type,
                    })
                    library_templates.options.push({
                        label: 'Promotional Email',
                        value: 'promotional',
                        type: type,
                    })
                    library_templates.options.push({
                        label: 'Email Newsletter I',
                        value: 'newsletter',
                        type: type,
                    })
                    library_templates.options.push({
                        label: 'Email Newsletter II',
                        value: 'newsletters-popup',
                        type: type,
                    })
                    library_templates.options.push({
                        label: 'E-Commerce Email I',
                        value: 'ecommerce-cutout',
                        type: type,
                    })
                    library_templates.options.push({
                        label: 'E-Commerce Email II',
                        value: 'ecommerce-flyer',
                        type: type,
                    })
                    library_templates.options.push({
                        label: 'E-Commerce Email III',
                        value: 'ecommerce-hero',
                        type: type,
                    })
                    library_templates.options.push({
                        label: 'Events Invitation',
                        value: 'events-invitation',
                        type: type,
                    })
                    library_templates.options.push({
                        label: 'Letter',
                        value: 'letter',
                        type: type,
                    })
                    library_templates.options.sort(function (option1, option2) {
                        let opt1 = option1.label.toLowerCase();
                        let opt2 = option2.label.toLowerCase();
                        if (opt1 < opt2)
                            return -1;
                        if (opt1 > opt2)
                            return 1;
                        return 0;
                    });
                    templateNames.push(library_templates);
                }

                let selected_template_name = '';
                let selected_template_type = '';
                if (data !== null && data.selectedTemplate !== undefined) {
                    selected_template_name = data.selectedTemplate;
                    selected_template_type = data.type;
                } else if (user_templates.options.length > 0) {
                    selected_template_name = user_templates.options[0].value;
                    selected_template_type = user_templates.options[0].type
                } else if (library_templates.options.length > 0) {
                    selected_template_name = library_templates.options[0].value;
                    selected_template_type = library_templates.options[0].type
                }
                setTemplates(templateNames);
                setSelected_template(selected_template_name);
                setDisabled(false);
                setIs_loading(false);
                setTemplateType(selected_template_type);
                dispatch(setAppDialogActions(getActions()));
            });
        } catch (error) {
            console.error("Error in 'editorTemplateDialog.js -> loadTemplate()':" + error);
        }
    }

    const handleTemplateChange = (event, option) => {
        setSelected_template(option.value);
        setTemplateType(option.type);

    }

    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const handleSelect = () => {
        if (object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
            let labelName = null;
            let obj = {
                template_id: selected_template,
                template_type: templateType,
            };

            if (callFrom === constants.LIST_VIEW) {
                labelName = getLocalizedStrings().label.BROADCAST_CAMPAIGNS.BROADCAST_CAMPAIGN_CREATE_TAB_LABEL;
                dispatch(setData(obj));
                dispatch(getAppDialog(false, null, null, null, null, null));
                let data = {};
                data.object = object;//constants.BROADCAST_CAMPAIGNS_OBJECT;
                data.type = appContainer.dialogProps.data.data.type;
                // data.template_type = this.state.templateType;
                if (data.type === constants.EDITOR_NEW || data.type === constants.EDITOR_BEE) {
                    let style = {};
                    style.width = '95%';
                    dispatch(getAppCustomDrawer(true, labelName, 'new', style, data, null, false));
                } else {
                    dispatch(getAppDrawer(true, labelName, 'new', data, null, false));
                }
            } else {
                appContainer.dialogProps.eventName(obj);
            }
        } else if (object === constants.AUTOMATION_DESIGNER_OBJECT) {
            try {
                let dialogPropsData = appContainer.dialogProps.data;
                let labelName = null;
                let obj = {
                    template_id: selected_template,
                    template_type: templateType,
                }
                if (obj.template_type === 'library') {
                    dispatch(setData(obj));
                    dispatch(getAppDialog(false, constants.EDITOR_TEMPLATE_DIALOG, null, null, null, null));
                    let nodeObject = dialogPropsData.nodeobject;
                    if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE || nodeObject.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
                        if (isValidParam(nodeObject['details'][0])) {
                            nodeObject.template_type = obj.template_type;
                            nodeObject.template_name = selected_template;
                            setTemplateData(nodeObject, true);
                        } else {
                            let detailObject = {
                                sequence: 1,
                                dbp: 0,
                                message_name: "Message",
                                message_id: 0,
                                interval_mode: "days",
                                view_as_web: "No",
                                startDate: "",
                                hDate: "",
                                template: {
                                    emailBody: '',
                                    subject: '',
                                    template_id: 0,
                                    editorType: constants.EDITOR_NEW,
                                }
                            }
                            nodeObject.details[0] = detailObject;
                            nodeObject.is_new = true;
                            nodeObject.template_type = obj.template_type;
                            nodeObject.template_name = selected_template;
                            let data = {
                                type: editorType, // New
                                'node_object': nodeObject,
                                object: constants.AUTOMATION_DESIGNER_OBJECT
                            }
                            let style = {};
                            style.width = '95%';
                            let drawerHeaderName = getLocalizedStrings().label.AUTOMATION_DESIGNER['Add Touch Message'];
                            if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
                                drawerHeaderName = getLocalizedStrings().label.AUTOMATION_DESIGNER['Add Birthday Message'];
                            }
                            automationCampaign.data['editorType'] = constants.EDITOR_NEW;
                            dispatch(getAppDialog(false, constants.EDITOR_TEMPLATE_DIALOG, null, null, null, null));
                            dispatch(getAppCustomDrawer(true,
                                drawerHeaderName,
                                LinkToType.TYPE_NEW, style, data, null, false));

                        }
                    } else if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                        if (isValidParam(nodeObject['details'][0])) {
                            nodeObject.template_type = obj.template_type;
                            nodeObject.template_name = selected_template;
                            setTemplateData(nodeObject, true);
                        } else {
                            let detailObject = {
                                interval: 0,
                                interval_mode: 'days',
                                id: 0,
                                t_status: 'New',
                                view_as_web: 'No',
                                template: {
                                    emailBody: '',
                                    subject: '',
                                    template_id: 0,
                                    editorType: editorType,
                                }
                            }
                            nodeObject.details[0] = detailObject;
                            nodeObject.is_new = true;
                            nodeObject.template_type = obj.template_type;
                            nodeObject.template_name = selected_template;
                            let data = {
                                type: editorType, //"New",
                                'node_object': nodeObject,
                                object: constants.AUTOMATION_DESIGNER_OBJECT
                            }
                            let style = {};
                            style.width = '95%';
                            automationCampaign.data['editorType'] = editorType;
                            dispatch(getAppDialog(false, constants.EDITOR_TEMPLATE_DIALOG, null, null, null, null));
                            dispatch(getAppCustomDrawer(true,
                                getLocalizedStrings().label.COMMON.SEND_EMAIL,
                                LinkToType.TYPE_NEW, style, data, null, false));
                        }
                    }
                } else {
                    var promise = Promise.resolve(HTTPClient.get(endPoints.MARKETING_TEMPLATE.TEMPLATE_GET, obj));
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            let nodeObject = dialogPropsData.nodeobject;
                            let detailsArray = nodeObject['details'][0];
                            let emailBody = response.mail_body;
                            let subject = response.subject;
                            let editorType = response.editorType;
                            let templateObject = {
                                emailBody: emailBody,
                                subject: subject,
                                template_id: 0,
                                editorType: editorType
                            }
                            if (dialogPropsData.data.type === constants.EDITOR_NEW || dialogPropsData.data.type === constants.EDITOR_BEE) {
                                templateObject.metadata = response.metadata;
                                templateObject.content = response.content;
                                if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE || nodeObject.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
                                    if (isValidParam(detailsArray)) {
                                        setTemplateData(templateObject, false);
                                    } else {
                                        let dbp = 0;
                                        let interval_mode = 'days';
                                        let arrayNodes = automationCampaign.data.nodes;
                                        if (isValidParam(nodeObject) && nodeObject.hasOwnProperty('actionName')) {
                                            let immediateprevId = 0;
                                            let immediateprevnodeDetails = null;
                                            if (isValidParam(nodeObject.parentId) && nodeObject.parentId > 0) {
                                                immediateprevId = nodeObject.parentId;
                                                immediateprevnodeDetails = getNodeByChildId(arrayNodes, immediateprevId);
                                                if (isValidParam(immediateprevnodeDetails)) {
                                                    let prevNodeActionName = immediateprevnodeDetails.hasOwnProperty('actionName') ? immediateprevnodeDetails.actionName : "";
                                                    if (prevNodeActionName === constants.AUTOMATION_DESIGNER_WAIT) {
                                                        if (immediateprevnodeDetails.hasOwnProperty('details')) {
                                                            let preveNodeDetailsArray = immediateprevnodeDetails.details[0];
                                                            if (isValidParam(preveNodeDetailsArray)) {
                                                                if (preveNodeDetailsArray.interval_mode === 'days') {
                                                                    dbp = preveNodeDetailsArray.interval / 24;
                                                                    interval_mode = 'days';
                                                                } else {
                                                                    dbp = preveNodeDetailsArray.interval;
                                                                    interval_mode = 'hours'
                                                                }

                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        let detailObject = {
                                            sequence: 1,
                                            dbp: dbp,
                                            message_name: "Message",
                                            message_id: 0,
                                            interval_mode: interval_mode,
                                            view_as_web: "No",
                                            startDate: "",
                                            hDate: "",
                                            template: templateObject
                                        }
                                        nodeObject.details[0] = detailObject;
                                        nodeObject.is_new = true;
                                        let data = {
                                            type: editorType, // New
                                            'node_object': nodeObject,
                                            object: constants.AUTOMATION_DESIGNER_OBJECT
                                        }
                                        let style = {};
                                        style.width = '100%';
                                        let drawerHeaderName = getLocalizedStrings().label.AUTOMATION_DESIGNER['Add Touch Message'];
                                        if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
                                            drawerHeaderName = getLocalizedStrings().label.AUTOMATION_DESIGNER['Add Birthday Message'];
                                        }
                                        automationCampaign.data['editorType'] = editorType;
                                        dispatch(getAppDialog(false, constants.EDITOR_TEMPLATE_DIALOG, null, null, null, null));
                                        dispatch(getAppCustomDrawer(true,
                                            drawerHeaderName,
                                            LinkToType.TYPE_NEW, style, data, null, false));
                                    }
                                } else if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                                    if (isValidParam(detailsArray)) {
                                        setTemplateData(templateObject, false);
                                    } else {
                                        let detailObject = {
                                            interval: 0,
                                            interval_mode: 'days',
                                            id: 0,
                                            t_status: 'New',
                                            view_as_web: 'No',
                                            template: templateObject
                                        }
                                        nodeObject.details[0] = detailObject;
                                        nodeObject.is_new = true;
                                        let data = {
                                            type: editorType, // New
                                            'node_object': nodeObject,
                                            object: constants.AUTOMATION_DESIGNER_OBJECT
                                        }
                                        let style = {};
                                        style.width = '95%';
                                        automationCampaign.data['editorType'] = editorType;
                                        dispatch(getAppDialog(false, constants.EDITOR_TEMPLATE_DIALOG, null, null, null, null));
                                        dispatch(getAppCustomDrawer(true,
                                            getLocalizedStrings().label.COMMON.SEND_EMAIL,
                                            LinkToType.TYPE_NEW, style, data, null, false));
                                    }
                                }
                            } else if (dialogPropsData.data.type === constants.EDITOR_LEGACY) {
                                if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
                                    //
                                } else if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                                    //
                                }
                            }
                        }
                    });
                }
            } catch (error) {
                console.log('Error in editorTemplateDialog.js : ' + error);
            }
        }
    }

    const getActions = () => {
        let className = '';
        if (disabled) {
            className = "btn-disabled";
        }
        const actions = [
            <Button
                primary={true}
                onClick={()=>handleSelect()}
                style={{ ...styles.primaryButton, display: 'inline-grid' }}
                // disabled={disabled}
                className={className}
            > {getLocalizedStrings().label.COMMON.OK}</Button>,
            <Button
                primary={true}
                onClick={()=>handleClose()}
                style={{ ...styles.secondaryButton, marginRight: '18px' }}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,

        ];
        return actions;
    }


    let contentHeight = window.innerHeight - 480;
    let top = (contentHeight - 10) / 2;
    return (
        <div>
            {templates.length > 0 && selected_template !== "" ?
                <div className="row" style={{ paddingBottom: '10px' }}>
                    <div className="col-sm-2" style={{ overflow: 'hidden', fontSize:'14px',fontWeight:400,lineHeight:'20px', whiteSpace: 'nowrap' }} title={getLocalizedStrings().label.BROADCAST_CAMPAIGNS.TEMPLATE}>
                        {getLocalizedStrings().label.BROADCAST_CAMPAIGNS.TEMPLATE}
                    </div>
                    <div className="col-sm-10">
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            {templates.length > 0 && <Autocomplete
                                name="template"
                                value={templates.filter(v => v.value === selected_template).length > 0 ? templates.filter(v => v.value === selected_template)[0] : ""}
                                options={templates}
                                onChange={(event, newValue) => handleTemplateChange(event, newValue)}
                                style={{ height: '40px' }}
                                disableClearable={true}
                                renderInput={(params) => <TextField {...params} label={getLocalizedStrings().label.COMMON.SELECT + "..."} variant="outlined" margin="dense" className={"sf-fields-bg"} size="small" />}
                            />
                            }
                        </FormControl>
                    </div>
                </div>
                :
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }} >
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>

            }
        </div>
    );

}

function FroalaTemplateSilector ({ editorType, object, setTemplateData,appContainer,automationCampaign,data }){
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const catagoryinputRef = useRef(null);
    const [categories, setCategories] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [selected_category, setSelected_category] = useState('');
    const [selected_template, setSelected_template] = useState('');
    const [is_loading, setIs_loading] = useState(true);
    const [callFrom, setCallFrom] = useState(null);
    const [addLinkUrl, setAddLinkUrl] = useState("#");
    const [disabled, setDisabled] = useState(true);
    const [stateEditorType, setStateEditorType] = useState(editorType);
    const [templateOptions, setTemplateOptions] = useState([]);
    const [selected_template_type, setSelected_template_type] = useState(null);
    const [ab_test, setAb_test] = useState(null);
    const [changeLog, setchangeLog] = useState({});

    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }
    

    useEffect(() => {
        dispatch(setAppDialogActions(getActions()));
    }, []);

    const loadData = () => {
        let params = {
            fields: ['t_m_categories', 't_pk'],
            type: constants.EDITOR_BEE,
            object: object,
        };
        let data = appContainer.dialogProps.data;
        const promise = Promise.resolve(HTTPClient.get(endPoints.MARKETING_TEMPLATE.MARKETINGCATEGORYLISTBYTYPE, params));
        promise.then((response) => {
            let categories = [];
            let default_category = null;

            if (Array.isArray(response.records)) {
                response.records.forEach(function (record, index) {
                    if (index === 0) {
                        default_category = {
                            label: record.t_m_categories,
                            value: record.t_pk
                        };
                    }
                    if (data !== null && data.selectedCategory !== undefined) {
                        if (record.t_pk === data.selectedCategory) {
                            default_category = {
                                label: record.t_m_categories,
                                value: record.t_pk
                            };
                        }
                    }
                    if (stateEditorType === constants.EDITOR_BEE) {
                        categories.push({
                            label: record.t_m_categories,
                            value: record.t_pk
                        });
                    }
                });

                if (appContainer.dialogProps.callFrom === constants.LIST_VIEW || stateEditorType !== constants.EDITOR_BEE) {
                    if (data.selectedCategory === -111 || appContainer.dialogProps.callFrom !== constants.LIST_VIEW) {
                        default_category = { label: 'Legacy Template', value: -111 };
                    }
                    categories.push({ label: 'Legacy Template', value: -111 });
                }
            }

            if (categories.length) {
                if (default_category !== null) {
                    let log = changeLog;
                    log.categories = categories;
                    log.selected_category = default_category.value;
                    setchangeLog({...log});
                    setCategories(categories);
                    setSelected_category(default_category.value);
                    loadTemplateByCategory(default_category.label);
                } else {
                    let log = changeLog;
                    log.categories = categories;
                    log.selected_category = categories[0].value;
                    setchangeLog({...log});
                    setCategories(categories);
                    setSelected_category(categories[0].value);
                    loadTemplateByCategory(categories[0].label);
                }
            }

        });

    }

    const loadTemplateByCategory = (category) => {
        setDisabled(true);
        setIs_loading(true);
        dispatch(setAppDialogActions(getActions()));
        let _templates = [];
        let _templateOptions = [];
        let library_templates = { label: getLocalizedStrings().label.COMMON.TEMPLATE.LIBRARY_TEMPLATE, options: [] };
        let user_templates = { label: getLocalizedStrings().label.COMMON.TEMPLATE.USER_TEMPLATE, options: [] };
        let data = appContainer.dialogProps.data;
        let editorType = constants.EDITOR_BEE;
        if (category === 'Legacy Template') {
            var promise = Promise.resolve(HTTPClient.get(endPoints.MARKETING_TEMPLATE.LEGACY_TEMPLATE, null));
            promise.then(response => {
                response.user_templates.forEach(record => {
                    user_templates.options.push({
                        label: retriveEscapeHtml(record.t_name),
                        t_catagory: getLocalizedStrings().label.COMMON.TEMPLATE.USER_TEMPLATE,
                        value: record.t_pk,
                        type: 'user',
                        editorType: record.t_editor_type
                    });
                });
                _templates.push(user_templates);
                let optionsArr = user_templates.options;
                if (Array.isArray(optionsArr)) {
                    optionsArr.forEach(function (record) {
                        _templateOptions.push({
                            label: retriveEscapeHtml(record.label),
                            t_catagory: getLocalizedStrings().label.COMMON.TEMPLATE.USER_TEMPLATE,
                            value: record.value,
                            type: 'library',
                            editorType: constants.EDITOR_BEE
                        });
                    });
                }
                let _selected_template = '';
                if (data !== null && data.selectedTemplate !== undefined) {
                    _selected_template = data.selectedTemplate;
                    editorType = data.obj.type;
                } else if (user_templates.options.length > 0) {
                    _selected_template = user_templates.options[0].value;
                    editorType = user_templates.options[0].editorType;
                }

                if (appContainer.dialogProps.callFrom !== constants.LIST_VIEW && editorType === constants.EDITOR_NEW && _templates.length > 0) {
                    let tempOpt = _templates[0].options;
                    _templates = [];
                    user_templates.options = [];
                    tempOpt.forEach(m => {
                        if (m.editorType === constants.EDITOR_NEW) {
                            user_templates.options.push(m);
                        }
                    });
                    _templates.push(user_templates);
                }
                setTemplates(_templates);
                setTemplateOptions(_templateOptions);
                setSelected_template(_selected_template);
                let log = changeLog;
                log.selected_template = _selected_template;
                log.templateOptions = _templateOptions;
                log.stateEditorType = editorType;
                setchangeLog({...log});
                setIs_loading(false);
                setDisabled(false);
                setStateEditorType(editorType);
                dispatch(setAppDialogActions(getActions()));
            });
        } else {
            let params = {
                category: [category],
                type: constants.EDITOR_BEE
            }
            var promise1 = Promise.resolve(HTTPClient.get(endPoints.MARKETING_TEMPLATE.LIBRARY_TEMPLATE, params));
            var promise2 = Promise.resolve(HTTPClient.get(endPoints.MARKETING_TEMPLATE.USER_TEMPLATE, params));
            Promise.all([promise1, promise2]).then(response => {
                if (Array.isArray(response[1].user_templates)) {
                    response[1].user_templates.forEach(function (record) {
                        user_templates.options.push({
                            label: retriveEscapeHtml(record.t_name),
                            t_catagory: getLocalizedStrings().label.COMMON.TEMPLATE.USER_TEMPLATE,
                            value: record.t_pk,
                            type: 'user',
                            editorType: constants.EDITOR_BEE
                        });
                    });
                }

                user_templates.options.sort(function (option1, option2) {
                    let opt1 = option1.label.toLowerCase();
                    let opt2 = option2.label.toLowerCase();
                    if (opt1 < opt2)
                        return -1;
                    if (opt1 > opt2)
                        return 1;
                    return 0;
                });

                _templates.push(user_templates);
                let optionsArr = user_templates.options;
                if (Array.isArray(optionsArr)) {
                    optionsArr.forEach(function (record) {
                        _templateOptions.push({
                            label: retriveEscapeHtml(record.label),
                            t_catagory: getLocalizedStrings().label.COMMON.TEMPLATE.USER_TEMPLATE,
                            value: record.value,
                            type: 'library',
                            editorType: constants.EDITOR_BEE
                        });
                    });
                }
                if (Array.isArray(response[0].library_templates)) {
                    response[0].library_templates.forEach(function (record) {
                        library_templates.options.push({
                            label: retriveEscapeHtml(record.t_name),
                            t_catagory: getLocalizedStrings().label.COMMON.TEMPLATE.LIBRARY_TEMPLATE,
                            value: record.t_pk,
                            type: 'library',
                            editorType: constants.EDITOR_BEE
                        });
                    });
                }

                library_templates.options.sort(function (option1, option2) {
                    let opt1 = option1.label.toLowerCase();
                    let opt2 = option2.label.toLowerCase();
                    if (opt1 < opt2)
                        return -1;
                    if (opt1 > opt2)
                        return 1;
                    return 0;
                });
                _templates.push(library_templates);
                optionsArr = library_templates.options;
                if (Array.isArray(optionsArr)) {
                    optionsArr.forEach(function (record) {
                        _templateOptions.push({
                            label: retriveEscapeHtml(record.label),
                            t_catagory: getLocalizedStrings().label.COMMON.TEMPLATE.LIBRARY_TEMPLATE,
                            value: record.value,
                            type: 'library',
                            editorType: constants.EDITOR_BEE
                        });
                    });
                }

                let _selected_template = '';
                if (data !== null && data.selectedTemplate !== undefined && data.selectedTemplate !== null) {
                    _selected_template = data.selectedTemplate;
                } else if (user_templates.options.length > 0) {
                    _selected_template = user_templates.options[0].value;
                } else if (library_templates.options.length > 0) {
                    _selected_template = library_templates.options[0].value;
                }
                setTemplates(_templates);
                setTemplateOptions(_templateOptions);
                setSelected_template(_selected_template);
                setIs_loading(false);
                setDisabled(false);
                setStateEditorType(constants.EDITOR_BEE);
                let log = changeLog;
                log.selected_template = _selected_template;
                log.templateOptions = _templateOptions;
                log.stateEditorType = constants.EDITOR_BEE;
                setchangeLog({...log});
                dispatch(setAppDialogActions(getActions()));
            });
        }
    }

    const handleCategoryChange = (event, option) => {
        setSelected_category(option.value);
        setTemplates([]);
        loadTemplateByCategory(option.label);
    }

    const handleTemplateChange = (event, option) => {
        let val;

        if (templates.length > 0) {
            let tempArr = templates.filter(v => v.label === option.t_catagory);
            val = tempArr[0].options.filter(v => v.value === option.value);
        }
        if (val.length > 0) {
            let log = changeLog;
            log.selected_template = val[0].value;
            log.selected_template_type = val[0].type;
            log.stateEditorType = val[0].editorType;
            setchangeLog({...log});
            setSelected_template(val[0].value);
            setSelected_template_type(val[0].type);
            setStateEditorType(val[0].editorType);
        }
    }

    const handleSelect = () => {
        let labelName = null;
        let log = changeLog;

        let templateValue = inputRef.current.getElementsByTagName("input")['template'].value;
        let templateId = log.templateOptions.filter(v => v.label === templateValue).length > 0 ? log.templateOptions.filter(v => v.label === templateValue)[0].value : 0;

        let catagoyValue = catagoryinputRef.current.getElementsByTagName("input")['catagory'].value;
        let catagoryId = log.categories.filter(v => v.label === catagoyValue).length > 0 ? log.categories.filter(v => v.label === catagoyValue)[0].value : 0;

        if (!isValidParam(templateId) && templateId === "") {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.TEMPLATE_NOT_SELECTED, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }
        let obj = {
            category_id: catagoryId,
            template_id: templateId,
            template_type: log.selected_template_type,
            type: log.stateEditorType
        };
        if (object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
            labelName = getLocalizedStrings().label.BROADCAST_CAMPAIGNS.BROADCAST_CAMPAIGN_CREATE_TAB_LABEL;
            dispatch(setData(obj));
            dispatch(getAppDialog(false, null, null, null, null, null));
            let data = {};
            data.object = constants.BROADCAST_CAMPAIGNS_OBJECT;
            data.type = log.stateEditorType;
            let type = 'new'
            if (appContainer.dialogProps.data.callFrom === 'template change') {
                type = 'edit'
            }
            if (log.stateEditorType === constants.EDITOR_LEGACY) {
                let style = {};
                style.width = '100%';
                dispatch(getAppCustomDrawer(true, labelName, type, style, data, null, false));
            } else {
                let style = {};
                style.width = '100%';
                dispatch(getAppCustomDrawer(true, labelName, type, style, data, null, false));
                setAddLinkUrl(null);
            }
        } else if (object === constants.MARKETING_TEMPLATE_OBJECT) {
            dispatch(setData(obj));
            dispatch(getAppDialog(false, constants.EDITOR_TEMPLATE_DIALOG, null, null, null, null));
            let data = {};
            data.object = constants.MARKETING_TEMPLATE_OBJECT;
            data.type = stateEditorType;
            let type = 'new'
            if (appContainer.dialogProps.data.callFrom === 'template change') {
                type = 'edit'
            }
            let style = {};
            style.width = '100%';
            dispatch(getAppCustomDrawer(true, getLocalizedStrings().label.MARKETING_TEMPLATE.MARKETING_TEMPLATE_CREATE_TAB_LABEL, type, style, data, null, false));
        } else if (object === constants.AUTOMATION_DESIGNER_OBJECT) {
            let nodeObject = appContainer.dialogProps.data.nodeobject;
            if (isValidParam(nodeObject)) {
                let params = {
                    template_id: obj.template_id
                }
                var promise = Promise.resolve(HTTPClient.get(endPoints.MARKETING_TEMPLATE.TEMPLATE_GET, params));

                promise.then((response) => {
                    if (isValidParam(response)) {
                        let emailBody = response.mail_body;
                        let subject = response.subject;
                        let detailsArray = nodeObject.details[0];
                        let detailObject = null;
                        let templateObject = {
                            emailBody: emailBody,
                            subject: subject,
                            template_id: 0,
                            editorType: stateEditorType,
                        }
                        if (stateEditorType === constants.EDITOR_BEE || stateEditorType === constants.EDITOR_NEW) {
                            templateObject.content = response.content;
                            templateObject.metadata = response.metadata;
                        }
                        if (nodeObject.hasOwnProperty('actionName')) {
                            let actionName = nodeObject.actionName;
                            let nodesArray = automationCampaign.data;
                            if (actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                                if (isValidParam(detailsArray)) {
                                    setTemplateData(templateObject);
                                } else {
                                    let interval = 0;
                                    let interval_mode = 'days';
                                    let arrayNodes = automationCampaign.data.nodes;
                                    if (isValidParam(nodeObject) && nodeObject.hasOwnProperty('actionName')) {
                                        let immediateprevId = 0;
                                        let immediateprevnodeDetails = null;
                                        if (isValidParam(nodeObject.parentId) && nodeObject.parentId > 0) {
                                            immediateprevId = nodeObject.parentId;
                                            immediateprevnodeDetails = getNodeByChildId(arrayNodes, immediateprevId);
                                            if (isValidParam(immediateprevnodeDetails)) {
                                                let prevNodeActionName = immediateprevnodeDetails.hasOwnProperty('actionName') ? immediateprevnodeDetails.actionName : "";
                                                if (prevNodeActionName === constants.AUTOMATION_DESIGNER_WAIT) {
                                                    if (immediateprevnodeDetails.hasOwnProperty('details')) {
                                                        let preveNodeDetailsArray = immediateprevnodeDetails.details[0];
                                                        if (isValidParam(preveNodeDetailsArray)) {
                                                            if (preveNodeDetailsArray.interval_mode === 'days') {
                                                                interval = preveNodeDetailsArray.interval / 24;
                                                                interval_mode = 'days';
                                                            } else {
                                                                interval = preveNodeDetailsArray.interval;
                                                                interval_mode = 'hours'
                                                            }

                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    detailsArray = {
                                        interval: interval,
                                        interval_mode: interval_mode,
                                        id: 0,
                                        t_status: 'New',
                                        view_as_web: 'No',
                                        template: templateObject
                                    }

                                    nodeObject.is_new = true;
                                    nodeObject.details.push(detailsArray);
                                    automationCampaign.data['editorType'] = stateEditorType;
                                    if (stateEditorType === constants.EDITOR_BEE || stateEditorType === constants.EDITOR_NEW) {
                                        let data = {
                                            type: stateEditorType,
                                            'node_object': nodeObject,
                                            object: constants.AUTOMATION_DESIGNER_OBJECT
                                        }
                                        let style = {};
                                        style.width = '100%';
                                        let drawerHeaderName = getLocalizedStrings().label.AUTOMATION_DESIGNER['Add Touch Message'];
                                        if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
                                            drawerHeaderName = getLocalizedStrings().label.AUTOMATION_DESIGNER['Add Birthday Message'];
                                        } else if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                                            drawerHeaderName = getLocalizedStrings().label.AUTOMATION_DESIGNER['Send Email'];
                                        }
                                        dispatch(getAppCustomDrawer(true, drawerHeaderName, LinkToType.TYPE_NEW, style, data, null, false));
                                    } else {
                                        let style = {};
                                        style.width = '83%';
                                        dispatch(getAppCustomDrawer(true, getLocalizedStrings().label.COMMON.SEND_MAIL, actionName, style, nodeObject, null, false));
                                       
                                    }
                                }
                            } else {
                                if (isValidParam(detailsArray)) {
                                    setTemplateData(templateObject);
                                } else {
                                    let dbp = 0;
                                    let interval_mode = 'days';
                                    let arrayNodes = automationCampaign.data.nodes;
                                    if (isValidParam(nodeObject) && nodeObject.hasOwnProperty('actionName')) {
                                        let immediateprevId = 0;
                                        let immediateprevnodeDetails = null;
                                        if (isValidParam(nodeObject.parentId) && nodeObject.parentId > 0) {
                                            immediateprevId = nodeObject.parentId;
                                            immediateprevnodeDetails = getNodeByChildId(arrayNodes, immediateprevId);
                                            if (isValidParam(immediateprevnodeDetails)) {
                                                let prevNodeActionName = immediateprevnodeDetails.hasOwnProperty('actionName') ? immediateprevnodeDetails.actionName : "";
                                                if (prevNodeActionName === constants.AUTOMATION_DESIGNER_WAIT) {
                                                    if (immediateprevnodeDetails.hasOwnProperty('details')) {
                                                        let preveNodeDetailsArray = immediateprevnodeDetails.details[0];
                                                        if (isValidParam(preveNodeDetailsArray)) {
                                                            if (preveNodeDetailsArray.interval_mode === 'days') {
                                                                dbp = preveNodeDetailsArray.interval / 24;
                                                                interval_mode = 'days';
                                                            } else {
                                                                dbp = preveNodeDetailsArray.interval;
                                                                interval_mode = 'hours'
                                                            }

                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    detailObject = {
                                        sequence: 1,
                                        dbp: dbp,
                                        message_name: "Message",
                                        message_id: 0,
                                        interval_mode: interval_mode,
                                        view_as_web: "No",
                                        startDate: "",
                                        hDate: "",
                                        template: templateObject
                                    }
                                    nodeObject.is_new = true;
                                    nodeObject.details.push(detailObject);

                                    nodeObject.template_type = obj.template_type;
                                    nodeObject.template_name = templateId;
                                    nodeObject.catagory_name = catagoryId;

                                    automationCampaign.data['editorType'] = stateEditorType;
                                    if (stateEditorType === constants.EDITOR_BEE || stateEditorType === constants.EDITOR_NEW) {
                                        let data = {
                                            type: stateEditorType,
                                            'node_object': nodeObject,
                                            object: constants.AUTOMATION_DESIGNER_OBJECT
                                        }
                                        let style = {};
                                        style.width = '100%';
                                        let drawerHeaderName = getLocalizedStrings().label.AUTOMATION_DESIGNER['Add Touch Message'];
                                        if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
                                            drawerHeaderName = getLocalizedStrings().label.AUTOMATION_DESIGNER['Add Birthday Message'];
                                        } else if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                                            drawerHeaderName = getLocalizedStrings().label.AUTOMATION_DESIGNER['Send Email'];
                                        }
                                        dispatch(getAppCustomDrawer(true, drawerHeaderName, LinkToType.TYPE_NEW, style, data, null, false));
                                    } else {
                                        let style = {};
                                        style.width = '83%';
                                        dispatch(getAppCustomDrawer(true, getLocalizedStrings().label.COMMON.SEND_MAIL, actionName, style, nodeObject, null, false));
                                        

                                    }
                                }
                            }
                            dispatch(getAppDialog(false, constants.TEMPLATE_DIALOG, null, null, null, null));
                        }
                    }
                });
            }
        } else if (callFrom === constants.TOUCH_CAMPAIGNS_OBJECT) {
            let categoriesArray = categories;
            categoriesArray = categoriesArray.filter(function (el) {
                return obj.category_id === el.value;
            })
            categoriesArray = categoriesArray[0];
            setTemplateData({ category_name: categoriesArray.label, template_id: obj.template_id });
            dispatch(getAppDialog(false, constants.TEMPLATE_DIALOG, null, null, null, null));
        }
        else {
            appContainer.dialogProps.eventName(obj);
        }
    }



    useEffect(() => {
        setCallFrom(appContainer.dialogProps.callFrom);
        if (callFrom === constants.LIST_VIEW) {
            setAddLinkUrl("/" + 'broadcast-campaigns' + '/new') ;
        }
        loadData();
        dispatch(setAppDialogActions(getActions()));
    }, []);

    const handleRef = useCallback((node) => {
        if (node) {
             
            inputRef.current = node;
            dispatch(setAppDialogActions(getActions()));
        }
    }, []);

    const getActions = () => {
        let className = '';
        if (disabled) {
            className = "btn-disabled";
        }
        const actions = [
            <Button
                primary={true}
                onClick={()=>handleSelect()}
                style={{ ...styles.primaryButton, paddingTop: '6px' }}
                // disabled={disabled}
                className={className}
            >{getLocalizedStrings().label.COMMON.SELECT}</Button>,
            <Button
                primary={true}
                onClick={()=>handleClose()}
                style={{ ...styles.secondaryButton, marginRight: '18px' }}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,

        ];
        return actions;
    }


    let contentHeight = window.innerHeight - 550;
    let top = (contentHeight - 10) / 2;
    return (
        <div>
            {categories.length > 0 ?
                <div>
                    <div className="row" style={{ paddingBottom: '10px' }}>
                        <div className="col-sm-2" style={{ overflow: 'hidden', fontSize:'14px',fontWeight:400,lineHeight:'20px', whiteSpace: 'nowrap', marginTop: '19px' }} title={getLocalizedStrings().label.BROADCAST_CAMPAIGNS.CATEGORY} >
                            {getLocalizedStrings().label.BROADCAST_CAMPAIGNS.CATEGORY}
                        </div>
                        <div className="col-sm-10" >
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                {categories.length > 0 && <Autocomplete
                                    name="catagory"
                                    defaultValue={categories.find(v => v.value === selected_category)}
                                    options={categories}
                                    onChange={(event, newValue) =>handleCategoryChange(event, newValue) }
                                    style={{ height: '40px' }}
                                    disableClearable={true}
                                    renderInput={(params) => <TextField {...params} name='catagory' ref={catagoryinputRef} label={getLocalizedStrings().label.COMMON.SELECT + "..."} variant="outlined" margin="dense" className={"sf-fields-bg"} size="small" />}
                                />
                                }
                            </FormControl>
                        </div>
                    </div>
                    
                    <div className="row" style={{ paddingBottom: '10px' }}>
                        <div className="col-sm-2" style={{ overflow: 'hidden', fontSize:'14px',fontWeight:400,lineHeight:'20px', whiteSpace: 'nowrap', marginTop: '19px' }} title={getLocalizedStrings().label.BROADCAST_CAMPAIGNS.TEMPLATE} >
                            {getLocalizedStrings().label.BROADCAST_CAMPAIGNS.TEMPLATE}
                        </div>
                        <div className="col-sm-10" >
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                              
                                <Autocomplete
                                    name="template"
                                    value={templateOptions.length>0 && selected_template != "" && templateOptions.filter(v => v.value === selected_template).length > 0 ? templateOptions.filter(v => v.value === selected_template)[0] : ""}
                                    options={templateOptions}
                                    groupBy={(option) => option.t_catagory}
                                    onChange={(event, newValue) => handleTemplateChange(event, newValue)}
                                    style={{ height: '40px' }}
                                    disableClearable={true}
                                    renderInput={(params) => <TextField {...params} name='template' ref={handleRef} label={getLocalizedStrings().label.COMMON.SELECT + "..."} variant="outlined" margin="dense" className={"sf-fields-bg"} size="small"/>}
                                />
                            </FormControl>
                        </div>
                    </div>
                </div>
                :
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%', paddingBottom: '45px ' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            }
        </div>
    );

}
