import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {getAppDialog, setAppDialogActions} from '../../../services/actions/appContainerActions';
import {styles} from '../../../services/constants/styles';
import {dateTimeFormat, dateTimeFormatFlatPicker, getDateObject, timeZone} from '../../../services/helper/utils';
import moment from 'moment';
import {getLocalizedStrings} from '../../../services/constants/MultiLingual';
import PopOver from './PopOver';
import {getStringParam, isValidParam} from '../../../services/helper/parameterVerifier';
import {useDispatch, useSelector} from "react-redux";

const AutomationScheduleDialog = () => {

    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);

    const [schedule_date, setScheduleDate] = useState(moment(getStringParam(new Date())).format(dateTimeFormat[app.me.date_format]));
    const [schedule_date_error, setScheduleDateError] = useState('');
    const [changeLog,setchangeLog] =useState({schedule_date:moment(getStringParam(new Date())).format(dateTimeFormat[app.me.date_format])});

    useEffect(() => {
        dispatch(setAppDialogActions(getActions()));
    }, []);

    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }


    const handleSchedule = () => {
        if (changeLog.schedule_date.length === 0) {
            setScheduleDateError(getLocalizedStrings().message.SCHEDULE.EMPTY);
            return false;
        }
        let obj = { date: changeLog.schedule_date };
        appContainer.dialogProps.eventName(obj);
    }


    const handleChange = (date) => {
        setScheduleDate(date);
        let log = changeLog;
        log.schedule_date = date;
        setchangeLog({...log});
        if (isValidParam(date) && date.length > 0) {
            setScheduleDateError('')
        }
    }

    const getActions = () => {
        return [
            <Button
                key={'schedule'}
                primary={'true'}
                onClick={() => handleSchedule()}
                style={styles.primaryButton}
            >{getLocalizedStrings().label.CAMPAIGNS.SCHEDULE}</Button>,
            <Button
                key={'cancel'}
                primary={'true'}
                onClick={() => handleClose()}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,
        ];
    }


    let currentTime = getDateObject(moment().tz(timeZone[app.me.timezone]).toObject());
    let HHFormat = new RegExp("HH");
    let datePickerOptions = {
        enableTime: true,
        dateFormat: dateTimeFormatFlatPicker[app.me.date_format],
        minuteIncrement: 1,
        time_24hr: HHFormat.test(app.me.date_format),
        minDate: currentTime
    };

    return (
        <div style={{ paddingRight: 100 }}>
            <div className="row">
                <div className="col-sm-6">
                    <PopOver
                        id={'dateTime'}
                        key={'dateTime'}
                        btnType={'dateTime'}
                        buttonStyle={{ fontSize: '16px' }}
                        containerStyle={{ lineHeight: 1 }}
                        onChange={(date) => handleChange(date)}
                        value={changeLog.schedule_date}
                        innerLabelStyle={{ padding: "10px 2px 2px 5px", fontSize: '15px' }}
                        height={38}
                        options={datePickerOptions}
                    />
                    <span style={{ fontSize: '12px', color: '#FF0000', marginTop: 5, display: 'block' }}>{schedule_date_error}</span>
                </div>
            </div>
        </div>
    );
};

export default AutomationScheduleDialog;