import { actions } from '..//constants/actions'

const dataInitialState = {
    obj: {
    }
}

const dataReducer = (state = dataInitialState, action) => {
    switch (action.type) {
        case actions.SET_DATA:
            return {
                ...state,
                obj: action.payload.obj
            }
        default:
            return state;
    }
}

export default dataReducer;