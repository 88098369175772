import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import $, { error } from 'jquery';
import { FormControl, Menu, MenuItem, IconButton, TextField, Typography } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import FroalaEditor from 'react-froala-wysiwyg';
import Froalaeditor from 'froala-editor';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import {
    sendMail,
    getTemplateList,
    getTemplate,
    getVerifiedEmais,
    getEmailSignature,
    storeTemplateListInRedux,
    storeTemplateCategoriesInRedux,
    storeSendMailDataInRedux,
} from '../../../services/actions/sendMailActions';
import {
    isValidParam,
    getStringParam,
    isEmail,
    getIntParam,
    getArrayParam,
    getObjectParam,
    getBooleanParam,
} from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { sortArrayObjectByProperty, StripHtmlToText } from '../../../services/helper/utils';
import * as sfDialogs from './sfDialogs';
import { getAppDialog, getAppDrawer, getAppCustomDrawer, minimizeAppCustomDrawer } from '../../../services/actions/appContainerActions';
import {
    getDetailviewData,
    refreshDetailViewData,
    refreshDetailViewListViewData,
    refreshPurchaseOrder,
} from '../../../services/actions/detailViewActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getCoupons } from '../../../services/helper/common';
import { getTasksRefresh } from '../../../services/actions/taskActions';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import ShowCircularProgress from './circularProgress';
import _ from 'lodash';
import PopOver from './PopOver';
import Autocomplete from '@mui/material/Autocomplete';
import { getCouponsAction } from '../../../services/actions/couponActions';
import SFLoader from './sfLoader';
import { initAppContextSendMailDataMobile } from '../../../services/actions/appActions';
import TagAutocomplete from '../../sfPlugins/tagAutocomplete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
require('froala-editor/js/froala_editor.pkgd.min.js');
require('froala-editor/css/froala_editor.pkgd.min.css');
require('froala-editor/js/plugins/font_family.min.js');
require('froala-editor/js/plugins/image.min.js');
require('froala-editor/css/plugins/image.min.css');

/**
 *
 * @Author  : Madhur Gangwani
 * @Date:   : 20-05-2023
 */

const ComposeMobileEmail = () => {
    const dispatch = useDispatch();
    // const cookies = new Cookies();
    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);
    const sendEMail = useSelector((state) => state.sendMail);
    const customDrawer = useSelector((state) => state.appContainer.customDrawerProps);
    const couponsFromRedux = useSelector((state) => state.coupons);
    const detailViewFromRedux = useSelector((state) => state.detailView);
    const reactTagsCc = useRef(null);
    const reactTagsBcc = useRef(null);
    let maildata = {
        fromEmail: app.me.email,
        toEmail: '',
        ccEmail: '',
        bccEmail: '',
        selectedTemplate: 0,
        selectedpersonalize: 0,
        emailSignature: '',
        emailSignatureTag: '',
        template: {
            id: 0,
            name: '',
            subject: '',
            email_message: '',
        },
        files: [],
        inProgress: false,
        isLoading: true,
        mounted: false,
        isOpenFromEmail: false,
        ccTab: false,
        bccTab: false,
        isMailSent: false,
        batchMail: false,
        attachFiles: [],
        isOpenAttachmentPopover: false,
        isDisabledToEmail: true,
        ccBccFocus: null,
        recordDetail: {},
        tags: {
            cc: [],
            bcc: [],
            to: [],
        },
        suggestions: [],
        toSuggestion: [],
        ccSuggestions: [],
        coupons: {},
        editorLoader: true,
        sending: false,
        scheduleDate: null,
        moreActionOpen: false,
        anchorMoreActionEl: null,
        actionType: '',
        isAgreementSelected: false,
        btnIndex: 0,
        attachLoader: false,
        isFromAttachment: false,
        email_id_list: [],
        anchorEl: null,
        editorType: null,
        emailContent: '',
        idToLoadEncodedParams: '',
        encodedParams: '',
        catagoryList: [{ key: 'Sales Template', value: 'Sales Template', id: 'Sales Template', label: 'Sales Templates' }],
        selectedCatagory: 'Sales Template',
        tempSalesTemplate: [],
        templateOptions: [],
        isAutoCompleteDisabled: false,
        isSendDisabled: false,
    };
    const [fromEmail, setFromEmail] = useState(app.me.email);
    const [toEmail, setToEmail] = useState('');
    const [ccEmail, setCCEmail] = useState('');
    const [bccEmail, setBCCEmail] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState(0);
    const [emailSignature, setEmailSignature] = useState('');
    const [emailSignatureTag, setEmailSignatureTag] = useState('');
    const [template, setTemplate] = useState({
        id: 0,
        name: '',
        subject: '',
        email_message: '',
    });
    const [ccTab, setCCTab] = useState(false);
    const [bccTab, setBCCTab] = useState(false);
    const [isDisabledToEmail, setIsDisabledToEmail] = useState(true);
    const [ccBccFocus, setCCBCCFocus] = useState(null);
    const [tags, setTags] = useState({
        cc: [],
        bcc: [],
        to: [],
    });
    const [suggestions, setSuggestions] = useState([]);
    const [toSuggestion, settoSuggestion] = useState([]);
    const [ccSuggestions, setccSuggestions] = useState([]);
    const [editorLoader, setEditorLoader] = useState(true);
    const [sending, setSending] = useState(false);
    const [moreActionOpen, setMoreActionOpen] = useState(false);
    const [anchorMoreActionEl, setAnchorMoreActionEl] = useState(null);
    const [actionType, setActionType] = useState('');
    const [btnIndex, setBtnIndex] = useState(0);
    const [attachLoader, setAttachLoader] = useState(false);
    const [isFromAttachment, setIsFromAttachment] = useState(false);
    const [emailIdList, setEmailIdList] = useState([]);
    const [editorType, setEditorType] = useState(null);
    const [emailContent, setEmailContent] = useState('');
    const [isLoading, setIsloading] = useState(true);
    const [catagoryList, setCatagoryList] = useState([]);
    const [attachFiles, setAttachFiles] = useState([]);
    const [selectedCatagory, setSelectedCatagory] = useState('Sales Template');
    const [selectedDataFetchType, setSelectedDataFetchType] = useState('Recent');
    const [templateOptions, setTemplateOptions] = useState([]);
    const [isSendDisabled, setIsSendDisabled] = useState(false);
    const [mergeFields, setMergeFields] = useState([]);
    const [recordDetail, setRecordDetail] = useState();
    const [mailUpdateData, setMailUpdateData] = useState({});
    const [isMailSent, setIsMailSent] = useState(false);
    const [encodedParams, setEncodedParams] = useState('');
    const [isOpenFromEmail, setIsOpenFromEmail] = useState();
    const [files, setFiles] = useState([]);
    const [addDocument, setAddDocument] = useState();
    const [inProgress, setInProgress] = useState();
    const [mounted, setMounted] = useState();
    const [error, setError] = useState({ upload: '' });
    const urlParams = new URLSearchParams(window.location.hash.split('compose-mail')[1]);

    useEffect(() => {
        let token = urlParams.get('t');
        localStorage.setItem('token', token);
        getSendMailDetail();
    }, []);

    // useEffect(()=>{
    //     document.addEventListener("message", function(data) {
    //         // call whatever function you need
    //         // console.log('data==>')
    //         // alert(JSON.stringify(data))
    //         // window?.ReactNativeWebView?.postMessage(JSON.stringify(data))
    //         sendMail(sendEMail)
    //     });
    // },[])

    const getSendMailDetail = () => {
        initAppContextSendMailDataMobile();
        // this.loadCoupons();
        if (sendEMail?.maildata?.merge_fields) {
            setMergeFields(sendEMail.maildata.merge_fields);
        } else {
            loadMergeFields();
        }
        try {
            if ((!sendEMail?.verifiedEmails || sendEMail.verifiedEmails.length === 0) && !sendEMail.isVerifyEmailCalled) {
                dispatch(getVerifiedEmais(false));
            }
            let recordId = urlParams.get('id');
            let object = urlParams.get('obj');
            let fromEmail = urlParams.get('f');

            let urlSuffix = '';
            let url = '';
            if (object === constants.ACCOUNTS_OBJECT) {
                urlSuffix = '/' + recordId + '/detailview';
                url = endPoints.ACCOUNTS.DETAIL_VIEW + urlSuffix;
            } else if (object === constants.CONTACTS_OBJECT) {
                urlSuffix = '/' + recordId + '/detailview';
                url = endPoints.CONTACTS.DETAIL_VIEW + urlSuffix;
            } else {
                urlSuffix = '/' + recordId + '/detailview';
                url = endPoints.JOBS.DETAIL_VIEW + urlSuffix;
            }

            let promise = Promise.resolve(HTTPClient.get(url, {}));
            promise.then((res) => {
                let record = res;
                setToEmail(res.record.email);
                setFromEmail(fromEmail);
                setRecordDetail(record);
                populateEmailContent(recordId);
                geTemplateListByCategory(selectedCatagory, 'All', record);
                maildata = { ...maildata, toEmail: res.record.email, fromEmail: app.me.email };
            });
            dispatch(storeSendMailDataInRedux(maildata));
        } catch (error) {
            setIsloading(false);
            console.error("Error in 'sendMail.js -> componentDidMount()':" + error);
        }
    };

    useEffect(() => {
        if (ccBccFocus == 'ccTab') {
            reactTagsCc.current?.input?.focus();
        }

        if (ccBccFocus == 'bccTab') {
            reactTagsBcc.current?.input?.focus();
        }
    }, [ccBccFocus]);

    const addTO = (newTag) => {
        let _tag = { ...tags, to: newTag };
        setCCEmail(toEmail);
        setTags(_tag);
        maildata = { ...sendEMail.maildata, toEmail: toEmail, tags: _tag };
        dispatch(storeSendMailDataInRedux(maildata));
        setSuggestions([]);
    };

    const removeTO = (allTag, removedTag) => {
        let tag = { ...tags, to: allTag };
        setToEmail(toEmail);
        setTags(tag);
        let _tagArr = allTag?.indexOf(',') > -1 ? allTag.split(',') : [];
        setEmailIdList(_tagArr);
        maildata = { ...sendEMail.maildata, toEmail: toEmail, tags: tag, email_id_list: _tagArr };
        dispatch(storeSendMailDataInRedux(maildata));
    };

    const addCC = (allTag, newTag) => {
        let _tag = { ...tags, cc: allTag };
        setCCEmail(ccEmail);
        setTags(_tag);
        maildata = { ...sendEMail.maildata, ccEmail: ccEmail, tags: _tag };
        dispatch(storeSendMailDataInRedux(maildata));
        setSuggestions([]);
    };

    const removeCC = (allTag, removedTag) => {
        let tag = { ...tags, cc: allTag };
        setCCEmail(ccEmail);
        setTags(tag);
        maildata = { ...sendEMail.maildata, ccEmail: ccEmail, tags: tag };
        dispatch(storeSendMailDataInRedux(maildata));
    };

    const addBCC = (allTag, newTag) => {
        let _tag = { ...tags, bcc: allTag };
        setBCCEmail(bccEmail);
        setTags(_tag);
        maildata = { ...sendEMail.maildata, bccEmail: bccEmail, tags: _tag };
        dispatch(storeSendMailDataInRedux(maildata));
        setSuggestions([]);
    };

    const removeBCC = (allTag, removedTag) => {
        let _tag = { ...tags, bcc: allTag };
        setBCCEmail(bccEmail);
        setTags(_tag);
        maildata = { ...sendEMail.maildata, bccEmail: bccEmail, tags: _tag };
        dispatch(storeSendMailDataInRedux(maildata));
    };

    const wait = (delay = 500) => {
        return new Promise((resolve) => setTimeout(resolve, delay));
    };

    const debounce = (fn, delay = 500) => {
        let timeoutID;
        return function (...args) {
            clearTimeout(timeoutID);
            timeoutID = setTimeout(() => fn(...args), delay);
        };
    };

    const handleInputChangeForBcc = useCallback(
        debounce(async (value) => {
            setCCBCCFocus('bccTab');
            if (value.length < 2) {
                setSuggestions([]);
                return;
            }
            try {
                await wait();
                let params = {
                    email_text: value,
                };
                let promise = Promise.resolve(HTTPClient.get(endPoints.SEND_MAIL.AUTOCOMPLETE_GET, params));
                promise.then((response) => {
                    let emailList = getArrayParam(response.emaillist);
                    let bccsuggestions = [];
                    emailList.forEach((email) => {
                        bccsuggestions.push({ label: email, value: email });
                    });
                    setSuggestions(bccsuggestions);
                });
            } catch (error) {
                console.error(`The Send mail bcc tag API returned a ${error}`);
                setSuggestions([]);
            }
        }),
        [suggestions],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleInputChangeForCC = useCallback(
        debounce(async (value) => {
            setCCBCCFocus('ccTab');
            if (value.length < 2) {
                setSuggestions([]);
                return;
            }
            try {
                await wait();
                let params = {
                    email_text: value,
                };
                let promise = Promise.resolve(HTTPClient.get(endPoints.SEND_MAIL.AUTOCOMPLETE_GET_CC, params));
                promise.then((response) => {
                    let emailList = getArrayParam(response.emaillist);
                    if (emailList.length === 0) return;
                    let ccSuggestions = [];
                    emailList.forEach((email) => {
                        ccSuggestions.push({ value: email, label: email });
                    });
                    setccSuggestions(ccSuggestions);
                });
            } catch (error) {
                console.error(`The Send mail cc tag API returned a ${error}`);
                setccSuggestions([]);
            }
        }),
        [ccSuggestions],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleInputChangeForTo = useCallback(
        debounce(async (value) => {
            setCCBCCFocus('toTab');
            if (value.length < 2) {
                setSuggestions([]);
                return;
            }
            try {
                await wait();
                let params = {
                    email_text: value,
                };
                let promise = Promise.resolve(HTTPClient.get(endPoints.SEND_MAIL.AUTOCOMPLETE_GET_TO, params));
                promise.then((response) => {
                    let emailList = getArrayParam(response.emaillist);
                    if (emailList.length === 0) return;
                    let toSuggestion = [];
                    emailList.forEach((email) => {
                        toSuggestion.push({ value: email.email, label: email.email });
                    });
                    settoSuggestion(toSuggestion);
                });
            } catch (error) {
                console.error(`The Send mail cc tag API returned a ${error}`);
                settoSuggestion([]);
            }
        }),
        [toSuggestion],
    );

    const inputRestriction = (event) => {
        if (event.key === ',' || event.key === ' ') {
            event.preventDefault();
            return false;
        } else {
            return true;
        }
    };

    const loadMergeFields = () => {
        let response = Promise.resolve(HTTPClient.get(endPoints.SALES_TEMPLATE.NEW, null));
        response.then((response) => {
            setMergeFields(response.available_mail_merge_fields);
        });
    };

    const handleModelChange = (event) => {
        setCCBCCFocus(null);
        let _template = { ...template, email_message: event };
        setTemplate(_template);
        maildata = { ...sendEMail.maildata, template: _template };
        setMailUpdateData({ ...maildata });
    };

    const setFieldValue = (event, fieldName) => {
        let val;
        val = event.target.value;

        if (fieldName === 'subject') {
            let _template = { ...template, subject: val };
            setTemplate({ ..._template });
            maildata = { ...sendEMail.maildata, template: _template };
        } else if (fieldName === 'toEmail') {
            setToEmail(val);
            maildata = { ...sendEMail.maildata, toEmail: val };
        } else if (fieldName === 'ccEmail') {
            setCCEmail(val);
            maildata = { ...sendEMail.maildata, ccEmail: val };
        } else if (fieldName === 'bccEmail') {
            setBCCEmail(val);
            maildata = { ...sendEMail.maildata, bccEmail: val };
        }
        dispatch(storeSendMailDataInRedux(maildata));
    };

    const _sendMail = () => {
        let objectId = 0;
        let data = recordDetail;
        let object = data?.object;

        if (data?.hasOwnProperty('isAttachment') && data.isAttachment === true) {
            if (object !== constants.ACCOUNTS_OBJECT && object !== constants.CONTACTS_OBJECT && object !== constants.JOBS_OBJECT) {
                objectId = getIntParam(OBJECT_TABLEID_MAP['contacts']).toString();
            } else {
                objectId = getIntParam(OBJECT_TABLEID_MAP[object]).toString();
            }
        } else {
            objectId = getIntParam(OBJECT_TABLEID_MAP[object]).toString();
        }

        let params = {};

        let recordIds = getStringParam(data.record.id);
        params.recipients = toEmail;
        params.frommail = fromEmail;
        params.fromname = app.me.name;
    
        params.cc = tags.cc.length > 0 ? tags.cc : '';
        params.bcc = tags.bcc.length > 0 ? tags.bcc : '';
        params.subject = template['subject'] !== undefined ? template['subject'] : '';
        params.template_id = template['id'];
        params.object_id = objectId;
        params.record_ids = recordIds.toString();
        params.note_subject = StripHtmlToText(template['subject']);
        params.isAgreementTemplate = false;
        params.template_name = template.name;
        params.note_body = template['email_message'].replace(/[\u200B-\u200D\uFEFF]/g, ''); //StripHtmlToText(template['email_message']);
        params.email_body = StripHtmlToText(template['email_message']);
        params.templateBody = template['email_message'];
        params.is_email_note = true;

        let strAtachmentInfo = '';
        let arrAttachment = getArrayParam(attachFiles);
        arrAttachment.forEach((f) => {
            if (strAtachmentInfo === '') {
                strAtachmentInfo = '##' + f.name + '##' + f.src;
            } else {
                strAtachmentInfo += ',@##' + f.name + '##' + f.src;
            }
        });
        params.attachment_info = strAtachmentInfo;
        params.editorType = editorType;

        if (isValid(params)) {
            setIsMailSent(true);
            const promise = sendMail(params);
            if (isValidParam(promise)) {
                setSending(true);
                promise.then((response) => {
                    if (isValidParam(response) && response?.hasOwnProperty('status')) {
                        if (response.status === 0) {
                            if (window?.ReactNativeWebView) {
                                window?.ReactNativeWebView?.postMessage('mail success');
                            }
                        } else if (response.status === 1) {
                            if (window?.ReactNativeWebView) {
                                window?.ReactNativeWebView?.postMessage('error');
                            }
                        } else if (response.status > 0) {
                            if (window?.ReactNativeWebView) {
                                window?.ReactNativeWebView?.postMessage('error');
                            }
                        } else {
                            if (window?.ReactNativeWebView) {
                                window?.ReactNativeWebView?.postMessage('error');
                            }
                        }
                        setSending(false);
                        setIsMailSent(false);
                        setIsSendDisabled(false);
                    }
                });
            } else {
                if (window?.ReactNativeWebView) {
                    window?.ReactNativeWebView?.postMessage('error');
                }
                setIsMailSent(false);
                setIsSendDisabled(false);
            }
        } else {
            setIsSendDisabled(false);
            setIsMailSent(false);
        }
    };

    const isValid = (params) => {
        let data = getObjectParam(recordDetail);
        if (isValidParam(params.frommail) && params.frommail === '') {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.BLANK_FROM_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else if (isValidParam(params.frommail) && !isEmail(params.frommail)) {
            dispatch(
                showCustomSnackBar(getLocalizedStrings().message.MAIL.INVALID_FROM_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop),
            );
            return false;
        } else if (
            !(data?.hasOwnProperty('batch_mail') && data.batch_mail === true) &&
            isValidParam(params.recipients) &&
            params.recipients === '' &&
            !getBooleanParam(data.isAttachment)
        ) {
            if (!((isValidParam(params.cc) && params.cc !== '') || (isValidParam(params.bcc) && params.bcc !== ''))) {
                dispatch(
                    showCustomSnackBar(getLocalizedStrings().message.MAIL.BLANK_TO_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop),
                );
                return false;
            } else if (isValidParam(params.cc) && !isEmail(params.cc)) {
                dispatch(
                    showCustomSnackBar(getLocalizedStrings().message.MAIL.INVALID_CC_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop),
                );
                return false;
            } else if (isValidParam(params.bcc) && !isEmail(params.bcc)) {
                dispatch(
                    showCustomSnackBar(getLocalizedStrings().message.MAIL.INVALID_BCC_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop),
                );
                return false;
            }
        } else if (isValidParam(params.recipients) && params.recipients === '' && emailIdList.length === 0 && getBooleanParam(data.isAttachment)) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.BLANK_TO_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else if (isValidParam(params.recipients) && !isEmail(params.recipients)) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.INVALID_TO_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else if (isValidParam(params.cc) && !isEmail(params.cc)) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.INVALID_CC_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else if (isValidParam(params.bcc) && !isEmail(params.bcc)) {
            dispatch(
                showCustomSnackBar(getLocalizedStrings().message.MAIL.INVALID_BCC_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop),
            );
            return false;
        } else if (isValidParam(params.subject) && params.subject === '') {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.BLANK_SUBJECT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else if (isValidParam(params.templateBody) && params.templateBody === '') {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.BLANK_EMAIL_BODY, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else if (data.batch_mail && isValidParam(params.email_id_list) && JSON.parse(params.email_id_list).length === 0) {
            dispatch(
                showCustomSnackBar(
                    getLocalizedStrings().message.MAIL.NO_RECIPIENT + getLocalizedStrings().label.COMMON.DOT,
                    styles.snackbarBodyStyleError,
                    styles.snackBarStyleTop,
                ),
            );
            return false;
        } else if (getBooleanParam(params.isAgreementTemplate) && params.template_id === 0) {
            dispatch(
                showCustomSnackBar(
                    getLocalizedStrings().message.MAIL.TEMPLATE_NOT_SELECTED + getLocalizedStrings().label.COMMON.DOT,
                    styles.snackbarBodyStyleError,
                    styles.snackBarStyleTop,
                ),
            );
            return false;
        }
        return true;
    };

    const geTemplateListByCategory = (category, selectedDataFetchType, record) => {
        let arrPromise = [];
        let templateListObj = null;
        if (category !== null) {
            if (sendEMail.templateList.length === 0) {
                arrPromise.push(Promise.resolve(getTemplateList(category, selectedDataFetchType, 'sendMail')));
                Promise.all(arrPromise).then((arrResponse) => {
                    let templateObj = getArrayParam(arrResponse);
                    if (templateObj.length > 0) {
                        if (selectedDataFetchType !== 'Recent') {
                            dispatch(storeTemplateListInRedux(templateObj));
                        }
                    }
                    processTemplateList(arrResponse, record);
                });
            } else if (sendEMail.templateList.length > 0) {
                let finalArr = [];
                templateListObj = sendEMail.templateList[0];
                let list = templateListObj[category];

                if (list === undefined && selectedDataFetchType !== 'Recent') {
                    arrPromise.push(Promise.resolve(getTemplateList(category, selectedDataFetchType, 'sendMail')));
                    Promise.all(arrPromise).then((arrResponse) => {
                        let templateObj = getArrayParam(arrResponse);
                        if (templateObj.length > 0) {
                            Object.assign(templateListObj, templateObj[0]);
                            finalArr.push(templateListObj);
                            if (selectedDataFetchType !== 'Recent') {
                                dispatch(storeTemplateListInRedux(finalArr));
                            }
                        }
                        processTemplateList(arrResponse, record);
                    });
                } else {
                    processTemplateList(sendEMail.templateList, record);
                }
            }
        }
    };

    const processTemplateList = (arrResponse, record) => {
        let templateObj = getArrayParam(arrResponse)[0];

        restOptionsAfterSavingLibraryTemplates(templateObj, 'sendMail', constants.EMAIL_OBJECT, record);
    };

    const restOptionsAfterSavingLibraryTemplates = (templateObj, callFrom, type, record) => {
        let tempCat = [];
        let templateOptions = [];
        let selectedDataFetchType = 'All';
        if (selectedCatagory === 'Sales Template' && selectedDataFetchType === 'All') {
            if (templateObj['My Sales Template'] !== null && templateObj['My Sales Template'].length > 0) {
                let mySalesTemplateList = getArrayParam(templateObj['My Sales Template']);
                mySalesTemplateList.forEach((template) => {
                    let id = getIntParam(template.id);
                    let name = getStringParam(template.name);
                    let emailStatus = getStringParam(template.email_status);
                    if (template.id > 0 && name !== '' && emailStatus === 'Active') {
                        tempCat.push({ label: template.name, title: template.name, value: template.id, editor_type: template.editor_type });
                    }
                });
            }
            if (templateObj['Sales Template'] !== null && templateObj['Sales Template'].length > 0) {
                let salesTemplateList = getArrayParam(templateObj['Sales Template']);
                salesTemplateList.forEach((template) => {
                    let id = getIntParam(template.id);
                    let name = getStringParam(template.name);
                    let emailStatus = getStringParam(template.email_status);
                    if (template.id > 0 && name !== '' && emailStatus === 'Active') {
                        let isShowTemplate = true;
                        let exist = tempCat.filter((e) => {
                            return e.value === template.id;
                        });
                        if (exist !== undefined && exist.length > 0) {
                            isShowTemplate = false;
                        }
                        if (isShowTemplate) {
                            tempCat.push({ label: template.name, title: template.name, value: template.id, editor_type: template.editor_type });
                        }
                    }
                });
            }
            templateOptions = tempCat;
            let _template = { ...template, id: 0, name: '', subject: '', email_message: '' };
            setSelectedTemplate(0);
            setEditorType('froala');
            setTemplate(_template);
            maildata = { ...maildata, selectedTemplate: 0, editorType: 'froala', template: _template };
        }
        if (templateOptions.length > 0) {
            sortArrayObjectByProperty(templateOptions, 'label');
        }
        if (selectedCatagory === 'Sales Template' && selectedDataFetchType === 'All') {
            templateOptions.unshift({ label: 'Select a Template', value: 6395 });
        }

        setSelectedTemplate(templateOptions[0].value);
        setTemplateOptions(templateOptions);
        maildata = { ...maildata, selectedTemplate: templateOptions[0].value, templateOptions: templateOptions };
        dispatch(storeSendMailDataInRedux(maildata));
        loadRecentTemplate(templateOptions, record);
    };

    const loadRecentTemplate = (recentTempCat, record) => {
        setFromEmail(app.me.emai);
        if (recentTempCat && recentTempCat.length > 0) {
            let option = recentTempCat[0];
            setSelectedTemplate(option.value);

            let data = record;
            let param = {};
            param.from_email = fromEmail;
            param.template_id = option.value;
            param.email_id = data.email;
            param.parent_record_id = data.record.id;
            param.parent_table_id = OBJECT_TABLEID_MAP[data.object];
            param.type = constants.TYPE_EMAIL;

            let promise = Promise.resolve(getTemplate(param));
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response !== null) {
                        let mailMsg = getStringParam(response.mail_body);
                        let mailContent = response.content;
                        if (mailMsg.indexOf(response.email_signature_tag) > 0) {
                            mailMsg = mailMsg.split(response.email_signature_tag).join(response.email_signature);
                        }
                        if (response.template_name === 'Blank Template') {
                            mailMsg = mailMsg + '<br><br>' + response.email_signature;
                        }
                        let subject = getStringParam(response.subject);

                        let templateName = getStringParam(response.template_name);

                        let selectedTemplateArr = recentTempCat.filter((f) => {
                            return f.value === option.value;
                        });
                        if (selectedTemplateArr.length > 0) {
                            let editorType = selectedTemplateArr[0].editor_type;
                            if (response.template_name === 'Blank Template') {
                                editorType = 'froala';
                            }
                            let _editorType = editorType;
                            let emailSignatureTag = getStringParam(response.email_signature_tag);
                            let emailSignature = getStringParam(response.email_signature);
                            let encodedParams = getStringParam(response?.encodedParams);
                            let _template = { ...template, subject: subject, email_message: mailMsg, id: option.value, name: templateName };
                            setTemplate(_template);
                            setEditorType(_editorType);
                            setEmailContent(mailContent);
                            setSelectedTemplate(option.value);
                            setEditorLoader(false);
                            setEmailSignatureTag(emailSignatureTag);
                            setEmailSignature(emailSignature);
                            setEncodedParams(encodedParams);
                            maildata = {
                                ...maildata,
                                template: _template,
                                editorType: _editorType,
                                emailContent: mailContent,
                                selectedTemplate: option.value,
                                editorLoader: false,
                                emailSignature: emailSignature,
                                emailSignatureTag: emailSignatureTag,
                                encodedParams: encodedParams,
                            };
                            dispatch(storeSendMailDataInRedux(maildata));
                        } else {
                            addEmailSignatureBlankTemplate(record);
                        }
                    } else {
                        addEmailSignatureBlankTemplate(record);
                    }

                    if (response.alert) {
                        if (window?.ReactNativeWebView) {
                            window?.ReactNativeWebView?.postMessage(`${response.alert}`);
                        }
                        // sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, response.alert, null);
                    }
                }
            });
        } else {
            addEmailSignatureBlankTemplate(record);
        }
    };

    const actionOnHandleTemplateChange = (event, option) => {
        try {
            if (isValidParam(option)) {
                let param = {};
                setSelectedTemplate(option.value);
                let data = recordDetail;
                param.template_id = option.value;
                param.email_id = data.email;
                param.parent_record_id = data.record.id;
                param.parent_table_id = OBJECT_TABLEID_MAP[data.object];
                param.type = data.type;
                param.from_email = fromEmail;

                const promise = Promise.resolve(getTemplate(param));
                promise.then((response) => {
                    if (isValidParam(response)) {
                        setEmailSignatureTag(response.email_signature_tag);
                        setEmailSignature(response.email_signature);
                        setEncodedParams(response?.encodedParams);
                        maildata = {
                            ...maildata,
                            emailSignatureTag: response.email_signature_tag,
                            emailSignature: response.email_signature,
                            encodedParams: response?.encodedParams,
                        };
                        let mailMsg = response.mail_body;
                        let mailContent = response.content;
                        if (mailMsg.indexOf(emailSignatureTag) > 0) {
                            mailMsg = mailMsg.split(emailSignatureTag).join(emailSignature);
                        }
                        if (option.label === 'Blank Template') {
                            mailMsg = mailMsg + '<br><br>' + emailSignature;
                        }
                        let subject = response.subject;
                        let selectedTemplateArr = templateOptions.filter((f) => {
                            return f.value === option.value;
                        });
                        let editorType = selectedTemplateArr[0].editor_type;
                        let _template = { ...template, subject: subject, name: option.label, id: maildata.selectedTemplate, email_message: mailMsg };
                        setEditorType(editorType);
                        setEmailContent(mailContent);
                        setSelectedTemplate(option.value);
                        setTemplate({ ..._template });
                        maildata = { ...maildata, editorType: editorType, mailContent: mailContent, selectedTemplate: option.value };
                        dispatch(storeSendMailDataInRedux(maildata));
                        if (response.alert) {
                            // sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, response.alert, null);
                            if (window?.ReactNativeWebView) {
                                window?.ReactNativeWebView?.postMessage(JSON.stringify(response.alert));
                            }
                        }
                    }
                });
            } else {
                setSelectedTemplate('');
                maildata = { ...maildata, selectedTemplate: '' };
                dispatch(storeSendMailDataInRedux(maildata));
            }
        } catch (error) {
            console.error("Error in 'sendMail.js -> actionOnHandleTemplateChange()':" + error);
        }
    };

    const addEmailSignatureBlankTemplate = (record) => {
        let param = {};
        param.template_id = 0;
        let data = record;
        let fromEmail = app.me.email;
        param.from_email = fromEmail;
        param.parent_table_id = OBJECT_TABLEID_MAP[data.object];
        param.email_id = getStringParam(data.email);
        param.parent_record_id = getStringParam(data.id).toString();
        let promise = Promise.resolve(getTemplate(param));
        promise.then((response) => {
            if (isValidParam(response)) {
                let mailMsg = response.mail_body;
                if (mailMsg.indexOf(response.email_signature_tag) > 0) {
                    mailMsg = mailMsg.split(response.email_signature_tag).join(response.email_signature);
                } else {
                    mailMsg = mailMsg + '<br><br>' + response.email_signature;
                }

                let _template = { ...template, subject: response.subject, email_message: mailMsg };
                setTemplate(_template);
                setEmailSignatureTag(response.email_signature_tag);
                setEmailSignature(response.email_signature);
                setEncodedParams(response?.encodedParams);
                maildata = {
                    ...maildata,
                    template: _template,
                    editorType: 'froala',
                    editorLoader: false,
                    emailSignatureTag: response.email_signature_tag,
                    emailSignature: response.email_signature,
                    encodedParams: response?.encodedParams,
                };
            }
        });
        setEditorType('froala');
        setEditorLoader(false);
        dispatch(storeSendMailDataInRedux(maildata));
    };

    const openAttachmentDialog = () => {
        let data = {};
        data.callFrom = constants.SEND_EMAIL;
        this.props.getAppDialog(
            true,
            constants.SEND_MAIL_ATTACHMENT_UPLOAD_DIALOG,
            getLocalizedStrings().message.ATTACHMENT.UPLOAD_FILE,
            handleSetAttachFiles,
            data,
            null,
        );
    };

    const handleSetAttachFiles = (files) => {
        files = getArrayParam(files);
        if (files.length > 0) {
            let attachfiles = [...attachFiles, ...files];
            setAttachFiles([...attachfiles]);
            maildata = { ...maildata, attachFiles: attachFiles };
            dispatch(storeSendMailDataInRedux({ ...maildata }));
        }
    };

    const getAttachmetFiles = () => {
        let files = getArrayParam(attachFiles);
        let ul = null;
        let li = files.map((f, index) => {
            return (
                <li key={f.name + index}>
                    <span
                        style={{
                            float: 'left',
                            textAlign: 'left',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'block',
                            whiteSpace: 'nowrap',
                            width: '150px',
                        }}
                        title={f.fileName}>
                        {' '}
                        {f.name}{' '}
                    </span>
                    &nbsp;{' '}
                    <i
                        className="fa fa-times"
                        aria-hidden="true"
                        style={{ paddingRight: '5px', cursor: 'pointer' }}
                        onClick={() => {
                            let attachFiles = attachFiles.splice(index, 1);
                            setAttachFiles(attachFiles);
                            maildata = { ...maildata, attachFiles: attachFiles };
                            dispatch(storeSendMailDataInRedux({ ...maildata }));
                        }}></i>
                </li>
            );
        });
        if (li !== null && li.length > 0) {
            ul = <ul>{li}</ul>;
        }
        return ul;
    };

    const handleSetFromEmail = (value) => {
        setFromEmail(value);
        setIsOpenFromEmail(false);
        setFromEmailSignature(value);
    };

    const setFromEmailSignature = (value) => {
        let emailMsg = template.email_message;
        let oldEmailSignature = emailSignature;
        let signature = '';
        let params = null;
        value = getStringParam(value);
        if (value.length > 0) {
            params = {};
            params.signature_email_id = value;
            let promise = Promise.resolve(HTTPClient.get(endPoints.SEND_MAIL.DIRECT_EMAIL_SIGNATURE, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    signature = getStringParam(response.t_signature);
                    if (emailMsg !== '' && emailMsg.indexOf(oldEmailSignature) > 0) {
                        emailMsg = emailMsg.split(oldEmailSignature).join(signature);
                    }
                    let _template = { ...template, email_message: emailMsg };
                    setTemplate(_template);
                    setEmailSignature(signature);
                    maildata = { ...maildata, template: _template, emailSignature: signature };
                }
            });
        }
        dispatch(storeSendMailDataInRedux(maildata));
    };

    const getVerifiedEmailList = () => {
        if (sendEMail.mounted) {
            let verifiedEmails = getArrayParam(sendEMail.verifiedEmails);
            let menuItems = [];
            let data1 = {};
            data1.label = app.me.email;
            data1.value = app.me.email;
            menuItems.push(data1);
            verifiedEmails.map((email) => {
                let data = {};
                data.label = email;
                data.value = email;
                menuItems.push(data);
            });
            return menuItems;
        }
    };

    const getSignature = (inst) => {
        let param = {};
        param.signature_email_id = fromEmail;
        let promise = Promise.resolve(getEmailSignature(param));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    let signature = getStringParam(response.t_signature).trim();
                    inst.html.insert(signature);
                    setEmailSignature(signature);
                    maildata = { ...maildata, emailSignature: signature };
                    dispatch(storeSendMailDataInRedux(maildata));
                }
            });
        }
    };

    const setCcBccEmailAreaFlag = useCallback(
        (event) => {
            let val = event.currentTarget.id;
            event.preventDefault();
            if (val === 'ccTab') {
                setCCTab(true);
            } else {
                setBCCTab(true);
            }
            setCCBCCFocus(val);
            // setAnchorEl(event.currentTarget)
            maildata = { ...maildata, ccBccFocus: event.currentTarget.id, anchorEl: event.currentTarget };
            dispatch(storeSendMailDataInRedux(maildata));
        },
        [reactTagsCc],
    );

    const openAttachmentPopover = (event) => {
        event.preventDefault();
        // setIsOpenAttachmentPopover(true)
        // setAnchorEl(event.currentTarget)
    };
    const closeAttachmentPopover = () => {
        // setIsOpenAttachmentPopover(false)
    };

    const handelSendAction = () => {
        if (!isSendDisabled) {
            setIsSendDisabled(true);
            _sendMail();
        }
    };

    const getActionButton = (props) => {
        try {
            return (
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            color: 'rgb(255, 255, 255)',
                            marginRight: 4,
                            height: 32,
                            border: '1px solid rgb(224, 224, 224)',
                            background: '#FE5722',
                            textTransform: 'uppercase',
                        }}>
                        <div
                            style={{ margin: 5, textAlign: 'center', padding: '0px 15px', pointerEvents: sending && 'none' }}
                            onClick={() => handelSendAction()}>
                            {!sending ? getLocalizedStrings().label.MAIL.SEND : getLocalizedStrings().label.MAIL.SENDING}
                        </div>
                    </div>
                </div>
            );
        } catch (error) {
            console.error("Error in 'sendMail.js -> getActionButton()':" + error);
        }
    };

    const getSchedulerLink = (event) => {
        try {
            let slug = app.appointment_scheduler_slug;
            if (slug === null) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SCHEDULER.NO_SCHEDULER, null);
            } else {
                var link = constants.APPOINTMENT_SCHEDULER_URL + slug;
                event.html.insert(' <a target="_blank" href="' + link + '">Book an appointment</a> ');
            }
        } catch (error) {
            console.error("Error in 'sendMail.js -> getSchedulerLink()':" + error);
        }
    };

    const getAuditSurveyLink = (event) => {
        event.html.insert(' ${audit_survey_link} ');
    };

    const populateEmailContent = (recordId) => {
        try {
            let params = {};
            params.id = recordId ? recordId : 0;
            let promise = Promise.resolve(HTTPClient.get(endPoints.WORKFLOWS.GET_DENIAL_INQUIRY_MAIL_CONTENT, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let templateSubject = getStringParam(response.subject);
                    let templateEmailMessage = getStringParam(response.content);
                    setEditorLoader(false);
                    setEditorType('froala');
                    setTemplate({ ...template, subject: templateSubject, email_message: templateEmailMessage });
                }
            });
        } catch (error) {
            console.error("Error in 'sendMail.js -> populateEmailContent()':" + error);
        }
    };

    const fileUpload = (files) => {
        const formData = new FormData();
        formData.append('file', files[0].file);
        let url = endPoints.SEND_MAIL.ATTACHMENT_UPLOAD;
        return HTTPClient.postFormData(url, formData);
    };

    const onClickUploadAttachment = (e) => {
        let filesArray = [];
        let filesName = null;
        var file = e.target.files[0];
        let isDuplicate = false;
        let uploadFileName = file.name;
        if (filesName === null) {
            filesName = uploadFileName;
        } else {
            filesName += ', ' + uploadFileName;
        }
        if (attachFiles.length > 0) {
            for (let i = 0; i < attachFiles.length; i++) {
                if (attachFiles[i].name === filesName) {
                    isDuplicate = true;
                    let errorMsg = filesName + ' ' + getLocalizedStrings().message.DOCUMENT_LIBRARY.DUPLICATE_DOCUMENT_NAME;
                    alert(errorMsg);
                }
            }
        }
        if (!isDuplicate) {
            let fileDetails = {};
            fileDetails.fileName = file.name;
            fileDetails.file = e.target.files[0];
            filesArray.push(fileDetails);
            if (fileDetails.file.size >= 1024) {
                let kb = (fileDetails.file.size / 1024).toFixed(2);
                if (kb > 1024 * 50) {
                    setFiles([]);
                    alert(getLocalizedStrings().message.ATTACHMENT.MAX_SIZE_50_MB);
                    return false;
                }
            }
            setInProgress(true);
            setAddDocument(true);
            let promise = Promise.resolve(fileUpload(filesArray));
            promise.then((response) => {
                if (response.data.status === 0) {
                    const files = [...files, ...response.data.data];
                    const attachFiles = [...attachFiles, ...response.data.data];
                    setMounted(false);
                    setAddDocument(false);
                    setInProgress(false);
                    setFiles(files);
                    setAttachFiles(attachFiles);
                    alert(getLocalizedStrings().message.ATTACHMENT.ATTACHMENT_UPLOAD);
                } else if (response.data.status === -1 && response.data.error.message !== '') {
                    let error = { ...error, upload: response.data.error.message };
                    setError(error);
                    setInProgress(false);
                    setFiles([]);
                }
            });
        }
        e.target.value = null;
    };

    const getEditor = (templateBodyMsg, FroalaConfig) => {
        let editor = null;
        let editorType = '';

        if (editorType !== null) {
            if (editorType === constants.EDITOR_BEE) {
                editor = <div id="bee-container" style={{ padding: '0px 10px', height: window.innerHeight - 220 + 'px' }}></div>;
            } else {
                editor = (
                    <FroalaEditor
                        tag="textarea"
                        model={templateBodyMsg}
                        config={FroalaConfig}
                        style={{ marginLeft: '10px' }}
                        onModelChange={handleModelChange}
                    />
                );
            }
        }
        return editor;
    };

    const removeCcBcc = (type) => {
        let _focusMode = null;
        if (type === 'CC') {
            setCCTab(false);
            if (reactTagsBcc?.current?.input) {
                _focusMode = 'bccTab';
            }
            setTags({ ...tags, cc: [] });
            setCCEmail('');
        } else {
            setBCCTab(false);
            if (reactTagsCc?.current?.input) {
                _focusMode = 'ccTab';
            }
            setTags({ ...tags, bcc: [] });
            setBCCEmail('');
        }
        setCCBCCFocus(_focusMode);
    };

    const focusHandle = () => {
        setCCBCCFocus(null);
    };

    // render() {
    let data = recordDetail;
    let showCcBcc = '1%';
    let type = 'email';
    let showBCCTab = true;
    let contentHeight = 250;
    let popOverEmailOptions = [];
    let toMailClass = 'col-sm-11';
    let ccMailClass = 'col-sm-12';
    let bccMailClass = 'col-sm-12';
    let top = (contentHeight - 10) / 2;
    let toEmailValue = toEmail;
    let templateBodyMsg = template.email_message;
    let batchMailFlag = data?.hasOwnProperty('batch_mail') && data.batch_mail === true ? true : false;
    let divStyle = {
        color: 'rgb(97, 97, 97)',
        height: 'auto',
        border: '1px solid #c4c4c4',
        borderRadius: '4px',
        background: '#fff',
        padding: '1px 8px',
        marginBottom: 10,
        width: '100%',
    };
    let buttonOuterLabelStyle = {
        backgroundColor: '#fff',
        lineHeight: '0.1',
        marginTop: '-2px',
        padding: '-1px',
        paddingLeft: 5,
        paddingRight: 4,
        fontSize: 11,
        fontWeight: 500,
        color: '#717171',
    };
    let autoFocus = actionType === 'REPLY' || actionType === 'REPLY-ALL' || actionType === 'FORWARD' ? true : false;

    // const Tag = (props) => {
    //     let btnClass = "react-tags__selected-tag";
    //     if (isValidParam(props.tag.name) && !isEmail(props.tag.name)) {
    //         btnClass = "react-tags__selected-tag invalid-tag";
    //     }
    //     return (
    //         <div className={btnClass}>
    //             <span className="react-tags__selected-tag-name">{props.tag.name}</span>
    //             {!batchMailFlag &&
    //                 <span title={getLocalizedStrings().label.COMMON.CLICK_TO_REMOVE} onClick={props.onDelete} className="close-tag"></span>
    //             }
    //             {batchMailFlag &&
    //                 <span className="disabled-tag"></span>
    //             }
    //         </div>

    //     )
    // }

    let FroalaConfig = {
        key: constants.FROALA_EDITOR_KEY,
        placeholderText: getLocalizedStrings().label.AGREEMENT_TEMPLATE.TYPE_HERE,
        height: document.body.clientHeight - 200,
        // toolbarButtons: constants.AGREEMENT_EDITOR_TOOLBAR_CONFIGURATION,
        // toolbarButtonsMD: constants.AGREEMENT_EDITOR_TOOLBAR_CONFIGURATION,
        // toolbarButtonsSM: constants.AGREEMENT_EDITOR_TOOLBAR_CONFIGURATION,
        // toolbarButtonsXS: constants.AGREEMENT_EDITOR_TOOLBAR_CONFIGURATION,
        colorsBackground: constants.EDITOR_TOOLBAR_BACKGROUND_COLOR_PALETTE,
        colorsText: constants.EDITOR_TOOLBAR_TEXT_COLOR_PALETTE,
        toolbarSticky: false,
        //scaytAutoload: true,
        //scaytCustomerId: constants.scaytCustomerId,
        enter: FroalaEditor.ENTER_BR,
        iframe: true,
        htmlUntouched: true,
        attribution: false,
        inlineMode: false,
        imageEditButtons: ['imageAlign', 'imageRemove', 'imageLink', 'imageDisplay', 'imageAlt', 'imageSize', 'linkOpen', 'linkEdit', 'linkRemove'],
        tableEditButtons: [
            'tableHeader',
            'tableRemove',
            '|',
            'tableRows',
            'tableColumns',
            '-',
            'tableCells',
            'tableCellBackground',
            'tableCellVerticalAlign',
            'tableCellHorizontalAlign',
        ],
        toolbarButtons: {
            moreText: {
                buttons: [
                    'bold',
                    'italic',
                    'underline',
                    'strikeThrough',
                    // "subscript",
                    // "superscript",
                    "fontFamily",
                    'fontSize',
                    'textColor',
                    'backgroundColor',
                    'inlineClass',
                    'inlineStyle',
                    'clearFormatting',
                ],
            },
            moreParagraph: {
                buttons: [
                    'alignLeft',
                    'alignCenter',
                    'formatOLSimple',
                    'alignRight',
                    'alignJustify',
                    'formatOL',
                    'formatUL',
                    // "paragraphFormat",
                    // "paragraphStyle",
                    'lineHeight',
                    'outdent',
                    'indent',
                ],
            },
            // moreRich: {
            //   buttons: [
            //     "signature",
            //     "personalize_dropdown",
            //     "insertLink",
            //     "insertTable",
            //     "emoticons",
            //     "fontAwesome",
            //     "specialCharacters",
            //     "embedly",
            //     // "insertFile",
            //     // "insertHR",
            //     // "insertImage",
            //     // "insertYouTubeVideo",
            //     // "insertWistiaVideo",
            //   ]
            // },
            // moreMisc: {
            //   buttons: [
            //     // "undo",
            //     // "redo",
            //     "fullscreen",
            //     //"print",
            //     //"getPDF",
            //     "spellChecker",
            //     "selectAll",
            //     // "html",
            //     //"help"
            //   ],
            //   align: "right",
            //   buttonsVisible: 2
            // }
        },
        quickInsertButtons: [],
        imageUpload: false,
        imagePaste: false,
        fileUpload: false,
        // fontSize: constants.EDITOR_FONT_SIZE,
        // fontFamily: {
        //     "Adobe Garamond Pro": "Adobe Garamond Pro",
        //     "Arial,Helvetica,sans-serif": "Arial",
        //     "Futura Std": "Futura Std",
        //     "Georgia,serif": "Georgia",
        //     "Impact,Charcoal,sans-serif": "Impact",
        //     RobotoSegoe: "Roboto",
        //     "Tahoma,Geneva,sans-serif": "Tahoma",
        //     "'Times New Roman',Times,serif": "Times New Roman",
        //     "Verdana,Geneva,sans-serif": "Verdana",
        // },
        // fontFamilySelection: true,
        events: {
            'froalaEditor.initialized': function (e, editor) {
                $('.fr-command').on('click', function (event) {
                    if ($(event).data('cmd') === 'insertLink') {
                        $('input[name="target"]').prop('checked', true);
                    }
                });
            },
        },
        events: {
            initialized: function () {
                window.WEBSPELLCHECKER.init({
                    container: this.$iframe ? this.$iframe[0] : this.el,
                });
            },
        },
    };

    if (data?.hasOwnProperty('selectedEmailLength') && getIntParam(data.selectedEmailLength) > 1) {
        showBCCTab = false;
    }

    if (sendEMail.mounted) {
        popOverEmailOptions = getVerifiedEmailList();
    }

    const centerAlign = {
        padding: 10,
        alignItems: 'center',
        display: 'flex',
    };

    return (
        <div>
            {/* header */}
            <div style={{ height: '55px', backgroundColor: '#FE5722', display: 'flex', flex: 1, width: '100%', position: 'fixed', top: 0, zIndex: 10 }}>
                <div className="alignleft" style={{ alignSelf: 'center' }}>
                    <IconButton onClick={() => window?.ReactNativeWebView?.postMessage('goback')}>
                        <ArrowBackIcon sx={{ fontSize: 25 }} style={{ fontWeight: 'normal', color: '#fff', margin: 5 }} />
                    </IconButton>
                </div>

                <div className="aligncenter" style={{ alignSelf: 'center' }}>
                    <span style={{ color: '#fff', margin: 10, fontSize: '20px', alignSelf: 'center' }}>Send Email</span>
                </div>

                <div className="alignright" style={{ alignSelf: 'center' }}>
                        <div style={{ color: '#fff', marginRight: '10px' }} onClick={() => handelSendAction()}>
                            <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                {!sending ? getLocalizedStrings().label.MAIL.SEND : getLocalizedStrings().label.MAIL.SENDING}
                            </span>
                        </div>
                </div>
            </div>

            {/* send mail screen */}
            <div style={{ height: '100%', opacity: isMailSent ? 0.5 : 1, marginTop: '4em' }}>
                <div style={{ width: '96%', margin: '2px 5px 15px 5px' }} className="mail-container">
                    {/* FromEmail */}
                    <div style={{ ...styles.row }}>
                        <div style={{ width: '100%' }}>
                            {/* <span>From</span>
                                <PopOver
                                    id={'fromEmail'}
                                    key={'fromEmail'}
                                    name={'fromEmail'}
                                    btnType={'div'}
                                    buttonEndIcon={"keyboard_arrow_down"}
                                    buttonStyle={{ fontSize: '16px' }}
                                    containerStyle={{ lineHeight: 1}}
                                    buttonLineOrigin={[46, 15]}
                                    // buttonLabel={"From"}
                                    isShowIcon={true}
                                    options={popOverEmailOptions}
                                    onclickAction={(value)=> handleSetFromEmail(value)}
                                    value={fromEmail ? fromEmail : popOverEmailOptions[0].value}
                                    innerIcon={'left'}
                                    innerLabelStyle={{ fontSize: '15px' }}
                                    height={38}
                                    downArrowStyle={{ marginTop: '-2px', padding: ' 0 5 0 5px' }}
                                /> */}
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <span>From</span>
                                <TextField
                                    variant="outlined"
                                    onChange={(e) => setFieldValue(e, 'toEmail')}
                                    value={fromEmail}
                                    fullWidth={true}
                                    // label={getLocalizedStrings().label.MAIL.TO}//'To'
                                    disabled={isDisabledToEmail}
                                    autoComplete="new-password"
                                    margin="dense"
                                    className={'sf-fields-bg'}
                                    size="small"
                                    onFocus={() => focusHandle('toEmail')}
                                />
                            </FormControl>
                        </div>
                    </div>

                    {/* toEmail */}
                    <div style={{ ...styles.row }}>
                        {!isFromAttachment ? (
                            <div style={{ width: '90%' }}>
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <span>{getLocalizedStrings().label.MAIL.TO}</span>
                                    <TextField
                                        variant="outlined"
                                        onChange={(e) => setFieldValue(e, 'toEmail')}
                                        value={toEmailValue}
                                        fullWidth={true}
                                        // label={getLocalizedStrings().label.MAIL.TO}//'To'
                                        disabled={isDisabledToEmail}
                                        autoComplete="new-password"
                                        margin="dense"
                                        className={'sf-fields-bg'}
                                        size="small"
                                        onFocus={() => focusHandle('toEmail')}
                                    />
                                </FormControl>
                            </div>
                        ) : (
                            <div style={{ ...styles.sf_12, paddingTop: '5px' }}>
                                <div onKeyDown={(event) => inputRestriction(event)}>
                                    <TagAutocomplete
                                        selected={tags.to}
                                        label={'To'}
                                        rule="email"
                                        suggestions={toSuggestion}
                                        onDelete={removeTO}
                                        onAdd={addTO}
                                        allowNew={true}
                                        onInput={handleInputChangeForTo}
                                        minSearchLength={2}
                                        placeholderText=""
                                        labelText=""
                                        tagListLabelText=""
                                        ariaAddedText=""
                                        mobileSreen={true}
                                    />
                                </div>
                            </div>
                        )}
                        {!ccTab && !bccTab && (
                            <div style={{ ...centerAlign, width: 80, marginTop: 20 }}>
                                {!ccTab && (
                                    <span
                                        className="mail"
                                        style={{ cursor: 'pointer', fontSize: '14px', marginRight: '8px' }}
                                        onClick={setCcBccEmailAreaFlag}
                                        id="ccTab">
                                        Cc
                                    </span>
                                )}
                                {!bccTab && (
                                    <span
                                        className="mail"
                                        style={{ cursor: 'pointer', fontSize: '14px' }}
                                        onClick={setCcBccEmailAreaFlag}
                                        id="bccTab">
                                        Bcc
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                    {ccTab && (
                        <div style={{ ...styles.row }}>
                            <div style={{ width: '100%' }} onKeyDown={(event) => inputRestriction(event)}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span>Cc</span>
                                    <div style={{ display: 'flex', paddingTop: '5px' }}>
                                        <TagAutocomplete
                                            id="cc_field"
                                            ref={reactTagsCc}
                                            // label={'Cc'}
                                            rule="email"
                                            selected={tags.cc}
                                            suggestions={ccSuggestions}
                                            onDelete={removeCC}
                                            onAdd={addCC}
                                            allowNew={true}
                                            onInput={handleInputChangeForCC}
                                            minSearchLength={2}
                                            placeholderText=""
                                            labelText=""
                                            tagListLabelText=""
                                            ariaAddedText=""
                                            inputFieldPosition="bottom"
                                            autocomplete
                                            mobileSreen={true}
                                        />
                                        <div>
                                            <IconButton style={{ ...centerAlign, cursor: 'pointer' }} onClick={() => removeCcBcc('CC')}>
                                                {' '}
                                                <i className="fa fa-times" title={getLocalizedStrings().label.COMMON.DELETE}></i>
                                            </IconButton>
                                        </div>
                                        {!bccTab && ccTab && (
                                            <div style={{ ...centerAlign, width: 40, height: 45 }}>
                                                <span
                                                    className="mail"
                                                    style={{ cursor: 'pointer', fontSize: '14px' }}
                                                    onClick={setCcBccEmailAreaFlag}
                                                    id="bccTab">
                                                    Bcc
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showBCCTab && bccTab && (
                        <div style={{ ...styles.row }}>
                            <div style={{ width: '100%' }} onkeyDown={(event) => inputRestriction(event)}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span>Bcc</span>
                                    <div style={{ display: 'flex', paddingTop: '5px' }}>
                                        <TagAutocomplete
                                            ref={reactTagsBcc}
                                            // label={'Bcc'}
                                            rule="email"
                                            selected={tags.bcc}
                                            suggestions={suggestions}
                                            onDelete={removeBCC}
                                            onAdd={addBCC}
                                            allowNew={true}
                                            onInput={handleInputChangeForBcc}
                                            minSearchLength={2}
                                            isDisabled={batchMailFlag}
                                            placeholderText=""
                                            labelText=""
                                            tagListLabelText=""
                                            ariaAddedText=""
                                            mobileSreen={true}
                                        />
                                        <div>
                                            <IconButton style={{ ...centerAlign, cursor: 'pointer' }} onClick={() => removeCcBcc('BCC')}>
                                                {' '}
                                                <i className="fa fa-times" title={getLocalizedStrings().label.COMMON.DELETE}></i>
                                            </IconButton>
                                        </div>
                                        {!ccTab && bccTab && !batchMailFlag && (
                                            <div style={{ ...centerAlign, width: 40, height: 45 }}>
                                                <span
                                                    className="mail"
                                                    style={{ cursor: 'pointer', fontSize: '14px' }}
                                                    onClick={setCcBccEmailAreaFlag}
                                                    id="ccTab">
                                                    Cc
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Sales Template */}
                    <div style={{ ...styles.row }}>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <span>Template</span>
                            <Autocomplete
                                id={'template'}
                                name="template"
                                value={
                                    templateOptions.filter((v) => v.value === selectedTemplate).length > 0
                                        ? templateOptions.filter((v) => v.value === selectedTemplate)[0]
                                        : {}
                                }
                                options={templateOptions}
                                onChange={(event, newValue) => actionOnHandleTemplateChange(event, newValue)}
                                getOptionLabel={(option) => (option.label ? option.label : '')}
                                style={{ ...styles.sfFormFieldContainer, paddingLeft: 0 }}
                                className="sendMail"
                                // disabled={isTemplateListDisabled}
                                disableClearable={true}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder={getLocalizedStrings().label.MARKETING_TEMPLATE.SELECT_TEMPLATE}
                                        title={template.name}
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        className={'sf-fields-bg'}
                                        onFocus={() => focusHandle('template')}
                                    />
                                )}
                            />
                        </FormControl>
                    </div>

                    {/* Subject */}
                    <div style={{ ...styles.row }}>
                        <div style={{ width: '100%' }}>
                            <FormControl style={{ width: '100%', marginBottom: '8px' }} className="test" noValidate autoComplete="off">
                                <span>{getLocalizedStrings().label.MAIL.SUBJECT}</span>
                                <TextField
                                    variant="outlined"
                                    onChange={(e) => setFieldValue(e, 'subject')}
                                    value={template['subject']}
                                    // label={getLocalizedStrings().label.MAIL.SUBJECT}
                                    fullWidth={true}
                                    style={styles.sfFormFieldContainer}
                                    className={'sf-fields-bg'}
                                    autoComplete="new-password"
                                    margin="dense"
                                    size="small"
                                    onFocus={() => focusHandle('subject')}
                                />
                            </FormControl>
                        </div>
                    </div>

                    {/* Froala Editor */}
                    {editorLoader || sending ? (
                        <div style={styles.loaderContainer}>
                            <div style={{ width: '100%', height: contentHeight }}>
                                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                padding: '2px 10px 10px 10px'
                            }}>
                            {getEditor(templateBodyMsg, FroalaConfig)}
                        </div>
                    )}
                    <br />

                    {/* Attach Files */}
                    {/* <div style={{...styles.row ,marginTop: '10px', height: '28px' }}>
                            <div className="col-sm-9" style={{ width: 'auto', fontSize: '14px' }}>
                                <a herf="" onClick={openAttachmentPopover} style={{ cursor: 'pointer' }}><i className="fa fa-paperclip" aria-hidden="true"></i><span> {getLocalizedStrings().label.MAIL.ATTACH}</span></a>
                                <i className="fa fa-angle-down" aria-hidden="true" onClick={openAttachmentPopover} style={{ paddingLeft: '7px', cursor: 'pointer' }}></i>
                                <input style={{cursor: 'pointer',position: 'absolute',top: 0,bottom: 0,right: 0,left: 0,width: '100%',opacity: 0}} type="file" onChange={(e) => onClickUploadAttachment(e)} multiple />    
                            </div>
                            <div className="col-sm-3" style={{ float: "right", marginRight: "-5px", width: 'auto', marginBottom: '33px' }}>
                                {getActionButton({ key: 'bottom' })}
                            </div>

                        </div>

                        <div className="row" style={{ marginTop: '10px', height: '28px' }}>
                            {(attachFiles.length > 0 && !getBooleanParam(this.state.attachLoader)) &&
                                <div>{getAttachmetFiles()}</div>}
                            {getBooleanParam(attachLoader) &&
                                <div style={{ width: '150px', height: '67px', padding: '0 60px' }}>
                                    <SFLoader style={{ fontSize: '20px' }} />
                                </div>
                            }
                        </div> */}
                </div>
            </div>
        </div>
    );
    // }
};

export default ComposeMobileEmail;
