import React, { useState, useEffect } from 'react';
import { isValidParam, getIntParam, getBooleanParam, getObjectParam, getStringParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { List, ListItem, ListItemText } from '@mui/material';
import NylasIntegration from '../components/nylasIntegration';
import AurinkoIntegration from '../components/aurinkoIntegration';
import PhoneTextIntegration from '../components/phoneTextIntegration';
import DocusignSetup from '../components/docusignSetup';
import IntegrationCointainer from '../components/integrationCointainer';
import PaymentIntegration from '../components/paymentIntegration';
import { constants } from '../../../services/constants/constants';
import EventbriteIntegration from '../components/eventbriteIntegration';
import { useSelector } from "react-redux";

const getStage = (stage) => {
    let defaultStage = { status: false, name: '', label: '', stage: 0 };
    stage = getObjectParam(stage);
    stage = { ...defaultStage, ...stage };
    return stage
}

const getSFInitTree = (meInfo) => {
    let sfIntTree = {};
    try {
        if (isValidParam(meInfo)) {
            let isTenant = getBooleanParam(meInfo.is_tenant);
            let name = getStringParam(meInfo.t_name);
            let emailAndCal = getStage({ name: constants.EMAIL_AND_CALENDER_INTEGRATION, label: 'Email and Calendar Integration', stage: 1 });
            sfIntTree[constants.EMAIL_AND_CALENDER_INTEGRATION] = emailAndCal;
            /*let soffront = getStage({ label: 'Soffront', stage: 2 });
            let callSetup = getStage({ label: 'Call Setup', stage: 3 });

            let callScripts = getStage({ label: 'Call Scripts', stage: 3 });
            soffront.child = { 'Call Setup': callSetup, 'Call Scripts': callScripts };*/
            let phoneandText = getStage({ name: constants.PHONE_AND_TEXT_INTEGRATION, label: 'Phone and Text Integration', stage: 1 });
            let _showPhoneAndText = true;
            if(meInfo.is_manage_unit_phone_by_fr == true){
                _showPhoneAndText = false;
            }
            if(_showPhoneAndText){
                sfIntTree[constants.PHONE_AND_TEXT_INTEGRATION] = phoneandText;
            }
            let docusign = getStage({ name: constants.DOCUSIGN_INTEGRATION, label: 'DocuSign Integration', stage: 1 });
            sfIntTree[constants.DOCUSIGN_INTEGRATION] = docusign;
            let quickBooks = getStage({ name: constants.ACCOUNTING_INTEGRATION, label: getLocalizedStrings().label.MY_INTEGRATION.ACCOUNTING_INTEGRATION, stage: 1 });
            if (isTenant || name === 'Admin') {
                sfIntTree[constants.ACCOUNTING_INTEGRATION] = quickBooks;
            }
            let payment = getStage({ name: constants.PAYMENT_INTEGRATION, label: 'Payment Integration', stage: 1 });
            if (isTenant) {
                sfIntTree[constants.PAYMENT_INTEGRATION] = payment;
            }
            let eventbrite = getStage({ name: constants.EVENTBRITE_INTEGRATION, label: 'Eventbrite Integration', stage: 1 });
            if (isTenant || name === 'Admin') {
                sfIntTree[constants.EVENTBRITE_INTEGRATION] = eventbrite;
            }
        }
    } catch (error) {
        console.error("Error in 'myIntegrations.js -> getSFInitTree()':" + error);
    }
    return sfIntTree;
}

const MyIntegrations = ({ callFrom }) => {

    const app = useSelector((state) => state.app);

    const [selectedStage, setSelectedStage] = useState(1);
    const [stateSfIntTree, setStateSfIntTree] = useState(null);
    const [selectedOption, setSelectedOption] = useState(constants.EMAIL_AND_CALENDER_INTEGRATION);


    useEffect(() => {
        let sfIntTree = getSFInitTree(app.me);
        let tempSelectedOption = selectedOption;
        if (callFrom === 'scheduler') {
            tempSelectedOption = constants.EMAIL_AND_CALENDER_INTEGRATION;
        } else if (callFrom === constants.PRODUCTS_OBJECT || callFrom === constants.QUOTATION_OBJECT) {
            tempSelectedOption = constants.ACCOUNTING_INTEGRATION;
        }
        setStateSfIntTree(JSON.parse(JSON.stringify(sfIntTree)));
        setSelectedOption(tempSelectedOption);

    }, []);

    const getCustomSetup = () => {
        let sfIntTree = getObjectParam(stateSfIntTree);
        let listItems = getListItems(sfIntTree);
        let component = <List style={{ padding: 0 }}>{listItems} </List>;
        return component;
    }

    const getListItems = (itemObj, pIndex) => {
        pIndex = getIntParam(pIndex);
        itemObj = getObjectParam(itemObj);
        let items = Object.keys(itemObj);
        let multilingualItemLabel = {
            'Email and Calendar Integration': getLocalizedStrings().label.MY_INTEGRATION.INTEGRATION_HEADER,
            'Phone and Text Integration': getLocalizedStrings().label.MY_INTEGRATION.PHONE_AND_TEXT_INTEGRATION,
            'DocuSign Integration': getLocalizedStrings().label.DOCUSIGN.DOCUSIGN_SETUP_HEADER,
            'Accounting Integration': getLocalizedStrings().label.MY_INTEGRATION.ACCOUNTING_INTEGRATION,
            'Payment Integration': getLocalizedStrings().label.MY_INTEGRATION.PAYMENT_INTEGRATION,
            'Eventbrite Integration': getLocalizedStrings().label.MY_INTEGRATION.EVENTBRITE_INTEGRATION,
        };
        let listItems = [];
        items.forEach((item, index) => {
            let props = {};
            props.key = pIndex + '-' + index;
            let primaryText = isValidParam(multilingualItemLabel[itemObj[item].label]) ? multilingualItemLabel[itemObj[item].label] : itemObj[item].label;
            props.nestedItems = getListItems(itemObj[item].child, index);
            props.className = 'call-setup-item-list';
            let divStyle = { padding: '15 4 15 10', fontSize: 15, color: '#616161' };
            let nestedDivStyle = { paddingTop: 5, paddingRight: 0, paddingBottom: 0, paddingLeft: 16, fontSize: 15, color: '#616161' };
            if ((itemObj[item].status && selectedStage !== itemObj[item].stage) ||
                selectedOption === itemObj[item].name) {
                divStyle = { ...divStyle, backgroundColor: '#e3e6e8' }
            }
            if (itemObj[item].stage === 1) {
                props.rightIcon = <div></div>;
            }
            props.onClick = () => toggleItem(itemObj, item, itemObj[item].hasOwnProperty('child') ? true : false);
            props.style = divStyle;
            props.nestedListStyle = nestedDivStyle;
            if (itemObj[item].hasOwnProperty('child')) {
                props.primaryTogglesNestedList = true;
                props.open = itemObj[item].status;
            }
            listItems.push(
                (<ListItem
                    button
                    {...props}
                >
                    <ListItemText className="sf-myintegration-color" primary={primaryText} />
                </ListItem>)
            );
        });
        return listItems;
    }

    const toggleItem = (itemObj, itemKey, isToggle) => {

        let item = itemObj[itemKey];

        let status = false;
        if (isToggle) {
            item.status = item.status ? false : true;
            status = item.status;

            if (!status) {
                let childObj = getObjectParam(item.child);
                let childItems = Object.keys(childObj);
                setItemStatus(childObj, childItems)
            }

            let sfIntTree = getObjectParam(itemObj);
            let items = Object.keys(sfIntTree);
            items = items.filter(f => {
                return (sfIntTree[f].label !== item.label);
            });

            setItemStatus(sfIntTree, items);

        } else {
            status = true;
        }

        if (isToggle && item.hasOwnProperty("child") && Object.keys(item.child).length === 0) {
            status = true;
        }

        let tempSelectedOption = status ? item.name : ''
        setSelectedOption(tempSelectedOption);
        setSelectedStage(item.stage);
    }

    const setItemStatus = (sfIntTree, items) => {
        items.forEach((item, index) => {

            if (sfIntTree.hasOwnProperty(item)) {
                sfIntTree[item].status = false;

                if (sfIntTree[item].hasOwnProperty("child")) {
                    setItemStatus(sfIntTree[item].child, Object.keys(sfIntTree[item].child));
                }
            }

        });

    }

    return (
        <div style={{ padding: '0 10px' }} key={selectedOption}>
            <div style={{ float: 'left', width: '32%', borderRight: '2.0px solid #afafaf5c', height: '90%' }}>
                {getCustomSetup()}
            </div>
            <div style={{ float: 'right', width: '68%' }}>
                {selectedOption === constants.EMAIL_AND_CALENDER_INTEGRATION &&
                     // <NylasIntegration />
                      <AurinkoIntegration />
                }
                {selectedOption === constants.PHONE_AND_TEXT_INTEGRATION &&
                      <PhoneTextIntegration />
                }
                {selectedOption === constants.DOCUSIGN_INTEGRATION &&
                    <DocusignSetup />
                }
                {selectedOption === constants.ACCOUNTING_INTEGRATION &&
                    <IntegrationCointainer />
                }
                {selectedOption === constants.PAYMENT_INTEGRATION &&
                    <PaymentIntegration />
                }
                {selectedOption === constants.EVENTBRITE_INTEGRATION &&
                    <EventbriteIntegration />
                }
            </div>
        </div>
    );
};

export default MyIntegrations;

