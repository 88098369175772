
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isValidParam, getStringParam, getObjectParam, getIntParam, getBooleanParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import PopOver from '../components/PopOver';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import Button from '@mui/material/Button';
import SFDataTable from '../components/sfDataTable';
import ShowCircularProgress from '../components/circularProgress';
import { constants, PIPELINE_REPORTS_MAP } from '../../../services/constants/constants';
import { dateFormat, dateFormatFlatPicker, sortArrayObjectByProperty } from '../../../services/helper/utils';
import moment from 'moment';
import { getActiveTab, updateTabInfo, addTab, getActiveTabInfo, TYPE_LIST_VIEW } from '../../../services/helper/sfTabManager';
import { updatePipelineReportData, updatePipelineReportSalesRep, updatePipelineReportWorkflows } from '../../../services/actions/pipelineReportActions';
import { getQueryDetails } from '../../../services/actions/appActions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { setHeader } from '../../../services/actions/headerActions';

const optionvalue = {
    td: 'Today',
    yd: 'Yesterday',
    tw: 'This Week',
    tm: 'This Month',
    tq: 'This Quarter',
    ty: 'This Year',
    lw: 'Last Week',
    lm: 'Last Month',
    lq: 'Last Quarter',
    ly: 'Last Year',
    cust: 'Custom'
};

const formatDate = (date) => {
    if (isValidParam(date)) {
        let d = date instanceof Date ? date : new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }
    return null;
}

const _toDate = (dateStr) => {
    var parts = dateStr.split("-");
    return new Date(parts[0], parts[1] - 1, parts[2]);
}

const PipelineReport = ((props) => {

    const dispatch = useDispatch()
    const app = useSelector((state) => state.app);
    const report = useSelector((state) => state.report);
    const pipelineReport = useSelector((state) => state.pipelineReport);
    
    const [dueBy, setDueBy] = useState('ty')
    const [userNames, setUserNames] = useState('All')
    const [userId, setUserId] = useState(-99)
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [objectName, setObjectName] = useState('accounts')
    const [userlist, setUserlist] = useState(null)
    const [workFlowList, setWorkFlowList] = useState(null)
    const [workflowNames, setWorkFlowNames] = useState(null)
    const [workflowId, setWorkFlowId] = useState(-99)
    const [recentRecordId, setRecentRecordId] = useState(0)
    const [parameterId, setParameterId] = useState(0)
    const [itemType, setItemType] = useState('Sales Summary Report')
    const [reportParams, setReportParams] = useState({
        due_by: 'tm',
        from_date: null,
        to_date: null,
        user_names: 'All',
        user_id: -99,
        recent_record_id: 0,
        parameter_id: 0,
        item_type: 'Sales Summary Report',
    })
    const [isMounted, setIsMounted] = useState(false)
    const [dueByPopup, setDueByPopup] = useState(false)
    const [userNamesPopup, setUserNamesPopup] = useState(false)
    const [objectNamePopup ,setObjectNamePopup] = useState(false)
    const [fields, setFields] = useState([])
    const [records, setRecords] = useState([])
    const [rowSort ,setRowSort] = useState({
        sortFieldName: 'sales_rep',
        isDescending: false,
        onSort: onSort
    })
    const [customTimeLineDisplay, setCustomTimeLineDisplay] = useState('none')
    const [objectValue, setObjectValue] = useState({
        accounts: getLocalizedStrings().label.REPORT.REPORT_ON_OBJECTS.ACCOUNTS,
        opportunities: getLocalizedStrings().label.REPORT.REPORT_ON_OBJECTS.OPPORTUNITIES
    })
    const [workFlowListByObj, setWorkFlowListByObj] = useState({
        accounts: [],
        opportunities: []
    })

    useEffect(()=>{
        report.showProgressBar(false)
        let objectList = getArrayParam(app.objectList);
        let tempObjList = null;
        let _objectvalue = getObjectParam(objectValue);
        if (objectList.length > 0) {
            tempObjList = objectList.filter(f => (f.name === constants.ACCOUNTS_OBJECT || f.name === constants.OPPORTUNITIES_OBJECT));
        }

        if (tempObjList.length > 0) {

            tempObjList.map((obj, index) => {
                if (obj.name === constants.ACCOUNTS_OBJECT) {
                    _objectvalue.accounts = getStringParam(obj.label);
                }
                if (obj.name === constants.OPPORTUNITIES_OBJECT) {
                    _objectvalue.opportunities = getStringParam(obj.label);
                }
            });

        }
        if (isValidParam(props)) {
            // let info = getActiveTabInfo();
            let params = getObjectParam(pipelineReport.reportParams);
            if (isValidParam(params) && Object.keys(params).length > 0 && params.hasOwnProperty("due_by")) {
                let cols = [];
                let rows = [];
                let workflowList = [];
                let _workflowId = 0;
                let _workflowName = ''
                let salesRepList = [];
                let pipelineReportWorkflows = {};
                setStateFromTabRedux();
                if (isValidParam(pipelineReport)) {
                    salesRepList = getArrayParam(pipelineReport.pipelineReportSalesRep);

                    pipelineReportWorkflows = getObjectParam(pipelineReport.pipelineReportWorkflows);

                    workflowList = getArrayParam(pipelineReportWorkflows[objectName]);
                    _workflowId = workflowId;
                    _workflowName = getStringParam(workflowNames);
                    if (workflowList.length > 0 && _workflowId === -99) {
                        _workflowId = getIntParam(workflowList[0].id);
                        _workflowName = getStringParam(workflowList[0].name);
                    }

                    let pipelineReportData = getObjectParam(pipelineReport.pipelineReportData);
                    if (pipelineReportData.hasOwnProperty('fields')) {
                        cols = pipelineReportData.fields;
                    }
                    if (pipelineReportData.hasOwnProperty('report_results')) {
                        rows = pipelineReportData.report_results;
                    }

                }
                let from_date = moment(getStringParam(new Date())).format(dateFormat[app.me.date_format]);
                let to_date = moment(getStringParam(new Date())).format(dateFormat[app.me.date_format]);
                setFromDate(from_date)
                setToDate(to_date)
                setIsMounted(true)
                setFields(cols)
                setRecords(rows)
                setRowSort(rowSort)
                setUserlist(salesRepList)
                setWorkFlowList(workflowList)
                setWorkFlowId(_workflowId)
                setWorkFlowNames(_workflowName)
                setWorkFlowListByObj(pipelineReportWorkflows)
            } else {
                getSalesRep();
            }
            dispatch(setHeader(constants.SALES_OBJECT, itemType, null, false, true));
        }
    },[])

    const loadRecentReport = () => {
        let objRecentReport = { item_type: 'Sales Summary Report', parameter_id: -99 };
        let promise = Promise.resolve(HTTPClient.get(endPoints.SALES_REPORT.RECENT_REPORT, objRecentReport));
        promise.then((response) => {
            if (Object.keys(response).length !== 0) {
                let wfId = getIntParam(response.workflow_id);
                let itemType = getStringParam(response.item_type);
                let dueby = getStringParam(response.due_by);
                let usrName = getStringParam(response.user_names);
                let objName = getStringParam(response.object_name);

                if (wfId > 0) {
                    setWorkFlowId(wfId)
                }

                if (isValidParam(itemType) && itemType.length > 0) {
                    setItemType(itemType)
                }

                if (isValidParam(dueby) && dueby.length > 0) {
                    setDueBy(dueBy)
                }
                setRecentRecordId(response.recent_record_id)
                setParameterId(response.parameter_id)
                if (dueBy === "cust") {
                    let from_date = moment(getStringParam(response.from_date)).format(dateFormat[app.me.date_format]);
                    let to_date = moment(getStringParam(response.to_date)).format(dateFormat[app.me.date_format]);
                    setFromDate(from_date)
                    setToDate(to_date)
                    setCustomTimeLineDisplay('block')
                } else {
                    setFromDate('')
                    setToDate('')
                    setCustomTimeLineDisplay('none')
                }

                if (isValidParam(usrName) && usrName.length > 0) {
                    setUserNames(usrName)
                }
                if (isValidParam(objName) && objName.length > 0) {
                    setObjectName(objName)
                }

                if (userNames !== "All") {
                    let user = userlist.filter((f) => {
                        let usrName = f.t_name === userNames
                        return usrName;
                    });
                    let user_id = user[0].t_userid;
                    setUserId(user_id)
                }

                let workflowListObj = getObjectParam(workFlowListByObj);
                if (workflowListObj.hasOwnProperty('accounts') && workflowListObj.hasOwnProperty('opportunities')) {
                    let accountWorkflowList = getArrayParam(workflowListObj.accounts);
                    let oppWorkflowList = getArrayParam(workflowListObj.opportunities);

                    let obj = null;
                    if (objectName === constants.ACCOUNTS_OBJECT) {

                        if (workflowId === -99) {
                            setWorkFlowNames(accountWorkflowList[0].name)
                            setWorkFlowId(accountWorkflowList[0].id)
                        } else {
                            obj = accountWorkflowList.filter((f) => {
                                let wrkFlowId = f.id === workflowId;
                                return wrkFlowId;
                            });

                            if(obj.length > 0){
                                setWorkFlowNames(obj[0].name)
                            } else {
                                setWorkFlowNames(accountWorkflowList[0].name)
                                setWorkFlowId(accountWorkflowList[0].id)
                            }
                            
                        }
                        setWorkFlowList(accountWorkflowList)
                    }
                    if (objectName === constants.OPPORTUNITIES_OBJECT) {
                        obj = oppWorkflowList.filter((f) => {
                            let wrkFlowId = f.id === workflowId
                            return wrkFlowId;
                        });
                        setWorkFlowNames(obj[0].name)
                        setWorkFlowList(oppWorkflowList)
                    }

                }
                setHandleReportParams();
                reloadReport();
            } else {
                let _workflowListObj = getObjectParam(workFlowListByObj);
                if (_workflowListObj.hasOwnProperty('accounts')) {
                    let accountWorkflowList = getArrayParam(_workflowListObj.accounts);
                    setWorkFlowNames(accountWorkflowList[0].name)
                    setWorkFlowId(accountWorkflowList[0].id)
                    setWorkFlowList(accountWorkflowList)
                }
                reloadReport();
            }

        });
    }

    const setStateFromTabRedux = () => {
        let params = getObjectParam(pipelineReport.reportParams);
        let info = getActiveTabInfo();
        info = getObjectParam(info);

        if (isValidParam(params) && Object.keys(params).length > 0 && params.hasOwnProperty("due_by")) {
            let _rowSort = getObjectParam(rowSort);
            setWorkFlowId(params.workflow_id)
            setWorkFlowNames(params.workflow_name)
            setUserId(params.user_id)
            setUserNames(params.user_name)
            setDueBy(params.due_by)
            setObjectName(params.report_on)
            if (info.hasOwnProperty("isDescending")) {
                rowSort.isDescending = info.isDescending;
            }
            if (params.due_by === 'cust') {
                let from_date = _toDate(info.from_date);
                let to_date = _toDate(info.to_date);
                setFromDate(from_date)
                setToDate(to_date)
                setCustomTimeLineDisplay('block')
            }
            setRowSort(_rowSort)
            setHandleReportParams();
        }
    }

    const getSalesRep = () => {
        let url = endPoints.SALES_REPORT.SALESREP_GET;
        try {
            let promise = Promise.resolve(HTTPClient.get(url, null));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let records = getArrayParam(response);
                    sortArrayObjectByProperty(records, "t_name");
                    dispatch(updatePipelineReportSalesRep(records));
                    getWorkflowList();
                    setUserlist(records)
                }
            });
        } catch (error) {
            console.log("Error in 'pipelineReport.js -> getSalesRep()':" + error)
        }

    }

    const getWorkflowList = () => {
        getWorkflowListByObject('accounts');
        getWorkflowListByObject('opportunities');
    }

    const getWorkflowListByObject = (object) => {
        let url = endPoints.WORKFLOWS.LIST_GET + "/" + object;
        let obj;
        try {
            let promise = Promise.resolve(HTTPClient.get(url, null));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let records = getArrayParam(response);
                    sortArrayObjectByProperty(records, "name");
                    if (records.length > 0) {
                        obj = workFlowListByObj[object]
                        setWorkFlowListByObj({...workFlowListByObj, obj: records})
                        let _workflowListObj = getObjectParam(workFlowListByObj);
                        if (_workflowListObj.hasOwnProperty('accounts') && _workflowListObj.hasOwnProperty('opportunities')) {
                            let accountWorkflowList = getArrayParam(_workflowListObj.accounts);
                            let oppWorkflowList = getArrayParam(_workflowListObj.opportunities);
                            if (accountWorkflowList.length > 0 && oppWorkflowList.length > 0) {
                                loadRecentReport();
                                dispatch(updatePipelineReportWorkflows(_workflowListObj));
                            }
                        }
                    }
                }
            });
        } catch (error) {
            console.log("Error in 'pipelineReport.js -> getWorkflowList()':" + error)
        }
    }

    const setHandleReportParams = () => {
        let from_Date = isValidParam(fromDate) ? moment(String(fromDate)).format(dateFormat[app.me.date_format]) : null;
        let to_Date = isValidParam(toDate) ? moment(String(toDate)).format(dateFormat[app.me.date_format]) : null;
        let params = {
            due_by: dueBy,
            from_date: formatDate(from_Date),
            to_date: formatDate(to_Date),
            user_names: userNames,
            user_id: userId,
            report_on: objectName,
            workflow_name: workflowNames,
            workflow_id: workflowId,
            recent_record_id: recentRecordId,
            parameter_id: parameterId
        };
        setReportParams(params)
        return params;
    }

    const getMultilingualOptionsMap = () => {
        let optionsMultilingual = {
            'Today': getLocalizedStrings().label.DASHBOARD.TODAY,
            'Yesterday': getLocalizedStrings().label.DASHBOARD.YESTERDAY,
            'This Week': getLocalizedStrings().label.DASHBOARD.THIS_WEEK,
            'This Month': getLocalizedStrings().label.DASHBOARD.THIS_MONTH,
            'This Quarter': getLocalizedStrings().label.DASHBOARD.THIS_QUARTER,
            'This Year': getLocalizedStrings().label.DASHBOARD.THIS_YEAR,
            'Last Week': getLocalizedStrings().label.DASHBOARD.LAST_WEEK,
            'Last Month': getLocalizedStrings().label.DASHBOARD.LAST_MONTH,
            'Last Quarter': getLocalizedStrings().label.DASHBOARD.LAST_QUARTER,
            'Last Year': getLocalizedStrings().label.DASHBOARD.LAST_YEAR,
            'Custom': getLocalizedStrings().label.DASHBOARD.CUSTOM
        };
        return optionsMultilingual;
    }

    const generateDateSelectionField = () => {
        let optionsMultilingual = getMultilingualOptionsMap();
        try {
            return Object.keys(optionvalue).map((itemKey) => {
                return <MenuItem key={itemKey} value={itemKey} style={styles.popoverMenuItem}>{optionsMultilingual[optionvalue[itemKey]]}</MenuItem>;
            });
        }
        catch (error) {
            console.error("Error in 'pipelineReport.js -> generateDateSelectionField()':" + error);
        }
    }

    const generateUserSelectionFieldList = () => {
        let usernamesList = [];
        try {
            let username_list = getArrayParam(userlist);
            if (username_list.length > 0) {
                usernamesList = username_list.map((user, index) => {
                    return <MenuItem value={user.t_userid} style={styles.popoverMenuItem} >{user.t_name}</MenuItem>;
                });
            }
            usernamesList.unshift(<MenuItem value={-99} style={styles.popoverMenuItem} >All</MenuItem>);
        }
        catch (error) {
            console.error("Error in 'pipelineReport.js -> generateUserSelectionFieldList()':" + error);
        }
        return usernamesList;
    }

    const generateWorkflowList = () => {
        let workflowList = [];
        try {
            workflowList = workFlowList.map((wf, index) => {
                return <MenuItem value={wf.id} style={styles.popoverMenuItem}>{wf.name}</MenuItem>;
            });
        }
        catch (error) {
            console.error("Error in 'pipelineReport.js -> generateWorkflowList()':" + error);
        }
        return workflowList;
    }

    const handleRequestClose = (fieldName) => {
        try {
            if(fieldName === 'due_by') {
                setDueByPopup(false)
            } else if(fieldName === 'user_names') {
                setUserNamesPopup(false)
            } else if(fieldName === 'object_name') {
                setObjectNamePopup(false)
            } else if(fieldName === 'workflow_names') {
                setWorkFlowNames(false)
            }
        }
        catch (error) {
            console.error("Error in 'pipelineReport.js -> handleRequestClose()':" + error);
        }
    };

    const handleDropDownChange = (name, event, value) => {
        try {
            value = value.props.value;
            if (name === "due_by") {
                setDueBy(value)
                if (value === "cust") {
                    let from_date = moment(getStringParam(new Date())).format(dateFormat[app.me.date_format]);
                    let to_date = moment(getStringParam(new Date())).format(dateFormat[app.me.date_format]);
                    setCustomTimeLineDisplay('block')
                    setFromDate(from_date)
                    setToDate(to_date)
                    return;
                } else {
                    setCustomTimeLineDisplay('none')
                    setFromDate(null)
                    setToDate(null)
                    handleRequestClose(name + "_popup");
                }
            }
            else if (name === "user_names") {

                if (value === -99) {
                    setUserId(value)
                    setUserNames('All')
                } else {
                    let user = userlist.filter((f) => {
                        return f.t_userid === value;
                    });
                    setUserId(user[0].t_userid)
                    setUserNames(user[0].t_name)
                }
                handleRequestClose(name + "_popup");
            }
            else if (name === "object_name") {
                let workflowlist = getArrayParam(workFlowListByObj[value]);
                setWorkFlowId(workflowlist[0].id)
                setWorkFlowNames(workflowlist[0].name)
                setWorkFlowList(workflowlist)
                setObjectName(value)
                handleRequestClose(name + "_popup");
            }
            else if (name === "workflow_names") {
                let wf = workFlowList.filter((f) => {
                    return f.id === value;
                });
                setWorkFlowId(wf[0].id)
                setWorkFlowNames(wf[0].name)
                handleRequestClose(name + "_popup");
            }
        }
        catch (error) {
            console.error("Error in 'pipelineReport.js -> handleDropDownChange()':" + error);
        }
    }

    const handleDateChange = (date, name) => {
        let field_name = name.replace('_', '-');
        let dateTimeDiv = document.getElementsByClassName("dateTimeDiv-" + field_name);
        if (date !== '') {
            if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
                dateTimeDiv[0].classList.add('label-top');
            }
            if(name === 'from_date'){
                setFromDate(date)
            } else {
                setToDate(date)
            }
        }
        else {
            if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
                dateTimeDiv[0].classList.remove('label-top');
            }
            if(name === 'from_date'){
                setFromDate('')
            } else {
                setToDate('')
            }
        }
    }

    const getButtonSet = () => {
        return (
            <div style={{ display: 'flex' }}>
                <iframe id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute', display: 'none' }}></iframe>
                <Button
                    onClick={() => { reloadReport() }}
                    style={{
                        ...styles.primaryButton,
                        minWidth: '40px', width: '19%'
                    }}
                    title={getLocalizedStrings().label.REPORT.GO}
                > {getLocalizedStrings().label.REPORT.GO}</Button>
                <Button
                    onClick={() => {
                        let content = document.getElementById("divcontents");
                        let ifrmae = document.getElementById("ifmcontentstoprint");
                        ifrmae.style.display = 'block'
                        let pri = document.getElementById("ifmcontentstoprint").contentWindow;
                        ifrmae.style.display = 'block'

                        pri.document.open();
                        pri.document.write(

                            content.innerHTML);
                        pri.document.close();
                        pri.focus();
                        pri.print();
                        ifrmae.style.display = 'none'
                    }}

                    style={{
                        ...styles.secondaryButton,
                        minWidth: '40px',

                    }}
                    title={getLocalizedStrings().label.REPORT.PRINT}
                >{getLocalizedStrings().label.REPORT.PRINT}</Button>
                <Button
                    startIcon={<i className="far fa-file-excel" style={{ fontSize: '20px', color: 'green' }} />}
                    onClick={() => { downloadExcel(); }}
                    style={{
                        ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                        color: '#717171',
                        border: '0px',
                        minWidth: '30px'
                    }}
                    title="Excel"
                ></Button>
            </div>
        );
    }
    
    const generateObjectNameValue = () => {
        return Object.keys(objectValue).map((itemKey) => {
            return <MenuItem key={itemKey} value={itemKey} style={styles.popoverMenuItem}>{objectValue[itemKey]}</MenuItem>;
        });
    }

    const getReportFilter = () => {
        let buttonSet = getButtonSet();
        let workflow_list = getArrayParam(workFlowList);
        let reportoncss = { marginLeft: '10px' };
        let workflowcss = { width: '17%' };
        let HHFormat = new RegExp("HH");
        let datePickerOptions = {
            enableTime: false,
            dateFormat: dateFormatFlatPicker[app.me.date_format],
            minuteIncrement: 1,
            static: true,
            time_24hr: HHFormat.test(app.me.date_format) ? true : false
        };

        //*
        let pprFields = '16.666667%';

        if(customTimeLineDisplay =='none'){
            pprFields = '25%';
        }
        return (
            <div className="row" style={{ padding: '0px' }} >
                <div>
                    <div style={{ padding: '10px 0' }}>
                        <div style={{ ...styles.row,width:'100%' }}>
                            <div style={{ ...styles.row,width:'100%' }}>
                                <div style={{width:pprFields }}>
                                    <div style={{ cursor: "pointer", fontSize: '14px', color: '#717171', height: '47px' }} id='due_by' key='due_by' >
                                        <FormControl variant="outlined" style={{width:'100%' }}>
                                            <InputLabel id="sf-pipelinereports-simple-select-outlined-label" className="sf-pipelinereports">{getLocalizedStrings().label.REPORT.DATE_RANGE}</InputLabel>
                                            <Select
                                                labelId="sf-pipelinereports-simple-select-outlined-label"
                                                id="sf-pipelinereports-simple-select-outlined"
                                                value={dueBy}
                                                onChange={handleDropDownChange('due_by')}
                                                label={getLocalizedStrings().label.REPORT.DATE_RANGE}
                                                className={"sf-fields-bg"}
                                                style={{ width:'100%', fontSize: '14px', color: '#717171', height: '47px', paddingLeft: 7, paddingRight: 7 }}
                                            >
                                                {generateDateSelectionField()}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div style={{ display: customTimeLineDisplay, width:pprFields}}>

                                    <div id="dateholder" style={{ display: 'grid', color: 'rgb(97, 97, 97)', borderRadius: '4px', background: '#fff', marginRight: '7px', marginLeft: '7px' }}>
                                        <PopOver
                                            id={'sf-date-time-picker1'}
                                            className={'date-time anval'}
                                            key={'fromDate'}
                                            name={'from_date'}
                                            btnType={'date'}
                                            buttonStyle={{ fontSize: '16px' }}
                                            containerStyle={{ lineHeight: 1 }}
                                            buttonLabel={getLocalizedStrings().label.REPORT.START_DATE}
                                            onChange={handleDateChange}
                                            value={fromDate}
                                            innerLabelStyle={{ padding: "8 5 2 5", fontSize: '15px' }}
                                            height={45}
                                            options={datePickerOptions}
                                        />
                                    </div>

                                </div>
                                <div style={{ display: customTimeLineDisplay, width:pprFields }}>
                                    <div id="dateholder" style={{ display: 'grid', color: 'rgb(97, 97, 97)', borderRadius: '4px', background: '#fff' }}>
                                        <PopOver
                                            id={'sf-date-time-picker2'}
                                            className={'date-time anval'}
                                            key={'toDate'}
                                            name={'to_date'}
                                            btnType={'date'}
                                            buttonStyle={{ fontSize: '16px' }}
                                            containerStyle={{ lineHeight: 1 }}
                                            buttonLabel={getLocalizedStrings().label.REPORT.END_DATE}
                                            onChange={handleDateChange}
                                            value={toDate}
                                            innerLabelStyle={{ padding: "8 5 2 5", fontSize: '15px' }}
                                            height={45}
                                            options={{ ...datePickerOptions, minDate: fromDate }}
                                        />
                                    </div>
                                </div>
                                <div style={{width:pprFields }}>
                                    <div
                                        style={{ paddingLeft: '10px' }}
                                        id='user_names'
                                        key='user_names'
                                    >
                                        <FormControl variant="outlined" style={{width:'100%' }}>
                                            <InputLabel id="sf-pipelinereport-simple-select-outlined-label">{getLocalizedStrings().label.REPORT.SALES_REP}</InputLabel>
                                            <Select
                                                labelId="sf-pipelinereport-simple-select-outlined-label"
                                                id="sf-pipeline-report-simple-select-outlined"
                                                value={userId}
                                                onChange={handleDropDownChange('user_names')}
                                                label={getLocalizedStrings().label.REPORT.SALES_REP}
                                                className={"sf-fields-bg"}
                                                style={{ width:'100%', cursor: "pointer", fontSize: '14px', color: '#717171', height: '47px', paddingLeft: 7, paddingRight: 7 }}
                                            >
                                                {generateUserSelectionFieldList()}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div style={{ ...reportoncss, width:pprFields }}>
                                    <div
                                        id='object_name'
                                        key='object_name'
                                    >
                                        <FormControl variant="outlined" style={{width:'100%' }}>
                                            <InputLabel id="sf-pipelinereport-from-pipelinereport-simple-select-outlined-label" className="sf-pipelinereport-from-pipelinereport">{getLocalizedStrings().label.REPORT.REPORT_ON}</InputLabel>
                                            <Select
                                                labelId="sf-pipelinereport-from-pipelinereport-simple-select-outlined-label"
                                                id="sf-pipelinereport-simple-select-outlined"
                                                value={objectName}
                                                onChange={handleDropDownChange('object_name')}
                                                label={getLocalizedStrings().label.REPORT.REPORT_ON}
                                                className={"sf-fields-bg"}
                                                style={{ width:'100%', cursor: "pointer", fontSize: '14px', color: '#717171', height: '47px', paddingLeft: 7, paddingRight: 7 }}
                                            >
                                                {generateObjectNameValue()}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                {workflow_list.length > 1 &&
                                    <div style={{ ...workflowcss, width:pprFields}}>
                                        <div
                                            style={{ paddingLeft: '10px' }}
                                            id='workflow_names'
                                            key='workflow_names'
                                        >
                                            <FormControl variant="outlined" style={{width:'100%' }}>
                                                <InputLabel id="sf-pipeline-report-from-pipelinereport-simple-select-outlined-label" className="sf-pipeline-report-from-pipelinereport">{getLocalizedStrings().label.REPORT.WORKFLOW_SELECT}</InputLabel>
                                                <Select
                                                    labelId="sf-pipeline-report-from-pipelinereport-simple-select-outlined-label"
                                                    id="sf-pipeline-report-simple-select-outlined"
                                                    value={workflowId}
                                                    onChange={handleDropDownChange('workflow_names')}
                                                    label={getLocalizedStrings().label.REPORT.WORKFLOW_SELECT}
                                                    className={"sf-fields-bg"}
                                                    style={{ width:'100%', cursor: "pointer", fontSize: '14px', color: '#717171', height: '47px', paddingLeft: 7, paddingRight: 7 }}
                                                >
                                                    {generateWorkflowList()}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div style={{ display: 'inline-block', paddingTop: '10px', paddingLeft: '10px' }}>{buttonSet}</div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    const getReportHeaderName = () => {
        let component = null;
        component = (
            <div className="col-md-12">
                <div className="row" style={{ paddingLeft: "2px", paddingBottom: "15px" }}>
                    <span className="text" style={{ margin: '0px', fontSize: '22px' }}><h3 style={{ fontWeight: 'bold' }}>{getLocalizedStrings().label.PIPELINE_REPORT.SALES_SUMMARY_REPORT}</h3></span>
                </div>
            </div>)
        return component;
    }

    const reloadReport = () => {
        let params = setHandleReportParams();
        let _rowSort = getObjectParam(rowSort);
        let records = null;
        let fields = null;
        let url = endPoints.PIPELINE_REPORT.PIPELINE_REPORT_GET;
        let activeTab = getActiveTab();
        let info = getObjectParam(activeTab.info);

        try {
            setIsMounted(false)
            _rowSort.isDescending = false;
            info.workflow_id = params.workflow_id;
            info.workflow_name = params.workflow_name;
            info.user_id = params.user_id;
            info.user_name = params.user_names;
            info.due_by = params.due_by;
            info.report_on = params.report_on;
            info.isDescending = getBooleanParam(rowSort.isDescending);
            if (params.due_by === 'cust') {
                info.from_date = getStringParam(params.from_date);
                info.to_date = getStringParam(params.to_date);
            }
            updateTabInfo(info);

            if (params.user_id === -99) {
                let user_names = "";
                let username_list = getArrayParam(userlist);
                if (username_list.length > 0) {

                    username_list.map((user, index) => {
                        user_names = user_names + user.t_name + ","
                    });
                }
                user_names = user_names.substring(0, user_names.length - 1);
                params.user_names = user_names;
            }

            let promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.hasOwnProperty('fields')) {
                        fields = getArrayParam(response.fields);
                    }
                    if (response.hasOwnProperty('report_results')) {
                        records = getArrayParam(response.report_results);
                    }

                    for (let field of fields) {
                        for (let record of records) {
                            if (!record.hasOwnProperty(field.name)) {
                                record[field.name] = 0;
                            }
                        }

                    }

                    if (params.user_id === -99) {
                        params.user_names = "All";
                    }

                    dispatch(updatePipelineReportData(response, params));
                    sortArrayObjectByProperty(records, "sales_rep");
                    setIsMounted(true)
                    setFields(fields)
                    setRecords(records)
                    setRowSort(_rowSort)
                }
            });
        } catch (error) {
            console.log("Error in 'pipelineReport.js -> reloadReport()':" + error)
        }
    }

    const getNoRecordContent = () => {
        return (<table className="layout_maintable" style={{ width: '100%' }}><tr><td><span style={{ color: 'red' }}>{getLocalizedStrings().label.INXPRESS_REPORT.NO_RECORDS_FOUND}</span></td></tr></table>);
    }

    const excelGenerateBeforeHeaders = () => {
        let optionsMultilingual = getMultilingualOptionsMap();
        let _reportParams = getObjectParam(reportParams);
        let cells = '';
        let dueBy = '';
        let reportName = getStringParam(getLocalizedStrings().label.PIPELINE_REPORT.SALES_SUMMARY_REPORT);
        let columns = fields.filter(f => {
            if (!f.is_hidden) { return f; }
        });
        let columnsLength = getArrayParam(columns).length;

        cells = cells + '<tr><td  colspan="' + (columnsLength) + '"> ' + reportName + ' </td></tr>';
        if (reportName.length > 0) {
            dueBy = getStringParam(optionsMultilingual[optionvalue[_reportParams.due_by]]);
            cells = cells + '<tr><td> Date :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + dueBy + ' </td></tr>';
        }
        return cells;
    }

    const excelGenerateHeaders = () => {
        let cells = '';
        let columns = fields.filter(f => {
            if (!f.is_hidden) { return f; }
        });
        columns.map(f => {
            cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    const excelGenerateRows = () => {
        let rows = records;
        let columns = getArrayParam(fields);
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            rows.map((rowObject, rowIndex) => {
                let innerCells = '';
                columns.map((columnObject, colIndex) => {
                    if (!columnObject.is_hidden) {
                        innerCells = innerCells + '<td >' + rowObject[columnObject.name] + ' </td>';
                    }
                })
                if (rowIndex % 2 === 0) {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                } else {
                    cells = cells + ('<tr  bgcolor="Lavender">' + innerCells + ' </tr>');
                }

            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
        }
        return rowData;
    }

    const downloadExcel = () => {
        let rows = getArrayParam(records);
        if (rows.length > 0) {
            let beforeHeaderComponent = excelGenerateBeforeHeaders();
            let headerComponents = excelGenerateHeaders();
            let rowComponents = excelGenerateRows();
            let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '</tbody></table>';
            let htmlData = htmlTable;
            if (isValidParam(htmlData) && htmlData !== '') {
                htmlData = htmlData.replace("%22", "\"");
                var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
                var downloadUrl = URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = downloadUrl;
                a.download = 'salesSummaryReportResultSet_' + new Date().getTime() + '.xls';
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        } else {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.NO_RECORD_EXPORT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
    }

    const customEvent = (eventType, column, row, event) => {
        try {
            let info = getActiveTabInfo();
            var value = 0;
            row = getObjectParam(row);
            value = getIntParam(row[column.name]);
            if (value > 0) {
                let object_name = objectName;
                let queryInfo = getObjectParam(app.queryInfo);
                //let queryName = 'All';
                let queryName = 'All records';
                let queryId = 0;
                let queryType = 'all';

                if (isValidParam(info) && info.hasOwnProperty(object_name)) {
                    let qryDetails = getObjectParam(info[object_name]);
                    queryId = qryDetails.qId;
                    queryName = qryDetails.qName;
                    queryType = qryDetails.qType;
                }

                if (queryId === 0) {
                    let params = {};
                    params.queries = [];
                    params.queries.push({ object: object_name, query_name: queryName });
                    let promise = getQueryDetails(params);

                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            response = getObjectParam(response);
                            for (let qName in response) {
                                queryInfo[qName] = response[qName];
                            }
                            if (isValidParam(queryInfo[queryName])) {
                                let queryDtls = queryInfo[queryName];
                                let filter = { id: queryDtls.query_id, name: queryDtls.query_name, type: queryDtls.query_type };
                                queryId = queryDtls.query_id;
                                queryName = queryDtls.query_name;
                                queryType = queryDtls.query_type;

                                openListView(info, object_name, queryId, queryName, queryType, column.name, row.sales_rep);

                            }
                        });
                    }
                } else {
                    openListView(info, object_name, queryId, queryName, queryType, column.name, row.sales_rep);
                }

            }

        } catch (error) {
            console.error("Error in 'pipelineReport.js -> customEvent()':" + error);
        }
    }

    const openListView = (tabinfo, object_name, queryId, queryName, queryType, colName, sales_rep) => {
        try {
            let _dueBy = getStringParam(dueBy);
            let user_name = sales_rep;

            tabinfo[object_name] = { qId: queryId, qName: queryName, qType: queryType };

            let filter = {
                id: queryId,
                name: queryName,
                type: queryType,
            }
            let url = "/" + object_name + "/" + 'queries';
            let label = getStringParam(getLocalizedStrings().label.PIPELINE_REPORT.SALES_SUMMARY_REPORT);
            let tab = {
                label: colName,
                object: object_name,
                type: TYPE_LIST_VIEW,
                imgName: "fa fa-bullhorn",
                info: {
                    filter: filter,
                    input_param: {
                        report_due_by: _dueBy,
                        report_user_names: user_name,
                        is_report: true,
                        report_type: PIPELINE_REPORTS_MAP.TYPE_PIPELINE_REPORT,
                        report_name: constants.SALES_SUMMARY_REPORT,
                        report_click_from: 'DRILLDOWN',
                        status: colName,
                        report_search_condition: "workflow_id = " + workflowId
                    },
                    isReport: true,
                    reportLabel: label,
                    groupByFieldName: "t_status",
                    groupByCondition: colName,
                    reportFieldValue: colName,
                },
                url: url,
                isActive: true
            };

            if (dueBy === 'cust') {
                tab.info.input_param.report_from_date = getStringParam(reportParams.from_date);
                tab.info.input_param.report_to_date = getStringParam(reportParams.to_date);
            }

            addTab(tab, true);
        } catch (error) {
            console.error("Error in 'pipelineReport.js -> openListView()':" + error);
        }

    }

    const sortArrayObjectByPropertyDesc = (array, property) => {
        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];

                    val1 = val1 instanceof String ? val1.toLowerCase() : val1;
                    val2 = val2 instanceof String ? val2.toLowerCase() : val2;

                    if (val1 > val2) {
                        return -1;
                    } else if (val1 < val2) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
        }
    }

    const onSort = (fieldName, isDescending) => {
        let _rowSort = rowSort;
        let _records = getArrayParam(records);
        let sortFieldName = getStringParam(rowSort.sortFieldName);
        let activeTab = getActiveTab();
        let info = getObjectParam(activeTab.info);
        try {
            if (!getBooleanParam(rowSort.isDescending)) {
                sortArrayObjectByPropertyDesc(_records, sortFieldName);
                _rowSort = {..._rowSort, isDescending: isDescending}//true;
            }
            else {
                sortArrayObjectByProperty(_records, sortFieldName);
                _rowSort = {..._rowSort, isDescending: isDescending}//false;;

            }
        }
        catch (error) {
            console.error("Error in 'pipelineReport.js -> onSort()':" + error);
        }
        info = {...info, isDescending: _rowSort.isDescending}
        updateTabInfo(info);
        setRowSort(_rowSort)
    }

    let style = { margin: 10, width: '98%', paddingTop: 0, paddingLeft: 11 };
    style = { ...style, ...getObjectParam(props.style) };
    let headerComponent = getReportFilter();
    let reportHeaderName = getReportHeaderName();
    let noRecordsTitle = getNoRecordContent();
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2
    return (
        <div style={style}>
            <div>{headerComponent} {reportHeaderName}</div>
            {isMounted ?
                <div id="divcontents">
                    {records.length > 0 ?
                        <div>
                            <div>
                                <SFDataTable columns={fields} rowSort={rowSort} rows={records} customEvent={customEvent} />
                            </div>
                        </div> :
                        noRecordsTitle
                    }
                </div>
                :
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
})

export default PipelineReport;
