import { actions } from '..//constants/actions';

const automationCampaignTemplateInitialSate = {
    mounted: false,
    data: [],
    error: null
}

const automationCampaignTemplateReducer = (state = automationCampaignTemplateInitialSate, action) => {
    switch (action.type) {
        case actions.AUTOMATION_CAMPAIGN_TEMPLATES_GET_FULFILLED:
            return {
                ...state,
                mounted: true,
                data: action.payload
            }
        default:
            return state;
    }
}

export default automationCampaignTemplateReducer