import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useDispatch, useSelector } from 'react-redux';
import { setHeader } from '../../../services/actions/headerActions';
import * as HTTPClient from '../../../services/helper/httpClient';
import { addTab, TYPE_DETAIL_VIEW } from '../../../services/helper/sfTabManager';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants } from '../../../services/constants/constants';
import { DURATION_ITEMS } from './AuditPerformanceReport';
import { endPoints } from '../../../services/constants/endPoints';

const AuditPerformanceReportChart = ({ object, id }) => {

  const dispatch = useDispatch();
  const { duration,reportData, averageScore, reportParams } = useSelector((state) => state.auditPerformanceReport);
  const [data, setData] = useState({ graphData: [],maxValue:false,tickPositions: [] });


  const dynamicInterval=(num)=>{
    const interval=Math.ceil(num/100)*100;
    return (interval/10);
  };
 
  const getGraphData = async () => {
    try {
      const response = await HTTPClient.get(endPoints.AUDIT_PERFORMANCE_REPORT.AUDIT_REPORT_DETAILS, JSON.stringify({ period: reportParams.period, questionId: reportParams.questionId, unitId: id }));
      const responseData = response?.data?.map((item) => {
        const date = new Date(item.Date);

        return { ...item, Date: formatDate(date), label: item?.Date };
      });
      const maxValue = response?.data?.every((v) => Number(v.TotalAuditanswerscore) < Number(averageScore));
      const requiredDynamicInterval = dynamicInterval(Math.max(...responseData.map((v)=>(Number(v.TotalAuditanswerscore)))));
      const tempTick = responseData.map((v) => {
        const remainder = v.TotalAuditanswerscore % requiredDynamicInterval;
        if (remainder !== 0) {
          return v.TotalAuditanswerscore + (requiredDynamicInterval - remainder);
        }
        return v.TotalAuditanswerscore;
      })
      const steps =Number(averageScore)<Math.max(...tempTick) ? Math.max(...tempTick) / requiredDynamicInterval : Math.ceil(averageScore /100) * 100 / requiredDynamicInterval;
      let ticks = [];
      for (let i = 0; i < steps + 2; i++) {
        ticks.push(i * requiredDynamicInterval);
      };
      ticks.push(averageScore);

      const sortedTicks = [...new Set(ticks.sort((a, b) => a - b))];
      const findIndex = sortedTicks.findIndex((tick) => tick % requiredDynamicInterval !== 0);
      
      if(findIndex){
      if (sortedTicks[findIndex] - sortedTicks[findIndex - 1] < (requiredDynamicInterval / 4) && sortedTicks[findIndex - 1] !== 0) {
        sortedTicks.splice(findIndex - 1, 1);
      } else if (sortedTicks[findIndex + 1] - sortedTicks[findIndex] < (requiredDynamicInterval / 4)) {
        sortedTicks.splice(findIndex + 1, 1);
      }else if (requiredDynamicInterval === 10) {
        const difference = sortedTicks[findIndex + 1] - sortedTicks[findIndex];
        if (difference > 6) {
          sortedTicks.splice(findIndex - 1, 1);
        } else if (difference < 4) {
          sortedTicks.splice(findIndex + 1, 1);
        }
      };
    }


      setData((prev) => ({ ...prev, graphData: { gpaphDetails: responseData, avg: response?.avg }, maxValue, tickPositions: sortedTicks }));
    } catch (error) {
      console.log(error);
    }
  };

  const unit = reportData.find((v) => v.unit_id === Number(id));
  const year = DURATION_ITEMS.find((item) => item.label === duration).label;
  
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}/${formattedMonth}/${year}`;
  }
  const options = {
    chart: {
      type: 'column',
      backgroundColor: '#F0F8FF',
      borderWidth: 1,
      borderColor: '#B0C4DE',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: `${unit?.unit}: ${year}`,
    },
    subtitle: {
      text: `<b>Category:</b> ${reportParams.printLabels.categories}. <b>Question:</b> ${reportParams.printLabels.questions}`
    },
    xAxis: {
      categories: data.graphData.gpaphDetails?.map((value) => value.Date),
    },
    yAxis: {
      title: {
        text: null,
      },
      min: 0,
      gridLineWidth: 0,
      minorGridLineWidth: 1,
      minorTickInterval: 'auto',
      tickPositions: data.tickPositions,
      tickmarkPlacement: 'on',
      labels: {
        formatter: function () {
          if (this.value === Number(averageScore)) {
                return `<b>Average: ${averageScore.toFixed(2)}</b>`;
            }
            return this.value;
        }
    },
      plotLines: [{
        color: 'gray',
        value: Number(averageScore),
        width: 0.7,
        zIndex: 1
      }],
      
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        return `
          <span style="color:${this.point.color}">●</span> ${this.x}<br/>
          <span style="color:${this.point.color}">●</span> <b>${this.series.name}:</b> ${this.y}<br/>
          <span style="color:gray">●</span> <b>${getLocalizedStrings().label.AUDIT_PERFORMANCE_REPORT.AUDIT_PERFORMANCE_REPORT_CHART_AVERAGE_SCORE_TOOLTIP}:</b> ${averageScore.toFixed(2)}
        `;
      }
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y;
          }
        },
        events: {
          click: function (event) {

            handleBarClick(event.point);

          }
        },
        zIndex: 4
      },
      series: {
        zones: [
          {
            value: Number(averageScore),
            color: '#FF0000',
          },
          {
            color: '#008000',
          },
        ],
        dataLabels: {
          enabled: true,
        },
      }
    },
    series: [{
      name:  reportParams.printLabels.categories,
      data: data.graphData.gpaphDetails?.map((value) => {
        const score = Number(value.TotalAuditanswerscore);
        const color = score < Number(averageScore) ? '#FF0000' : '#008000';
        const dataLabels = score < Number(averageScore) ? { align: 'center', verticalAlign: 'bottom' } : {};
        return { y: score, color, yAxis: 0, xAxis: 0, dataLabels, customData: { id: value?.Audit_id, date: value?.label } };
      })
    }]
  };


  const handleBarClick = (point) => {
    addTab({
      imgName: null,
      info: {
        isAuditAnswerChild: true,
        groupByCondition: reportParams.printLabels.categories,
      },
      isActive: true,
      label: point?.customData?.date,
      object: constants.AUDITS_OBJECT,
      parentTabIndex: 1,
      type: TYPE_DETAIL_VIEW,
      url: `/audits/detailview/${point?.customData?.id}`
    }, true);
  }

  useEffect(() => {
    dispatch(setHeader(constants.AUDIT_PERFORMANCE_REPORT, getLocalizedStrings().label.AUDIT_PERFORMANCE_REPORT.AUDIT_PERFORMANCE_REPORT_CHART_TITLE, null, false, true));
  }, []);

  useEffect(() => {

    getGraphData();
  }, [id]);



  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{ style: { height: '80vh', width: '90vw' } }}
      />
    </div>
  )
}

export default AuditPerformanceReportChart; 