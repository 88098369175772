import React from 'react';
import { constants } from '../../../services/constants/constants';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { LinkToType } from '../../../services/constants/link';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useDispatch, useSelector } from "react-redux";
import {getArrayParam} from "../../../services/helper/parameterVerifier";
export const GroupDataTable =({data})=> {
    const dispatch = useDispatch();
    const appState = useSelector((state) => state.app);

    const openRecord = (event,itemObj) => {
        let data = {};
        data.id = itemObj.id;
        let isOpenDrawer = false;

        if (itemObj.isAutomatedCamp) {
            data.object = constants.TOUCH_CAMPAIGNS_OBJECT;
            isOpenDrawer = false;
            openTouchCampaigns(itemObj);
        } else if (itemObj.isBroadcastCamp) {
            data.object = constants.BROADCAST_CAMPAIGNS_OBJECT;
            isOpenDrawer = true;
        } else if (itemObj.isAbCamp) {
            data.object = constants.AB_CAMPAIGNS_OBJECT;
            isOpenDrawer = true;
        } else if (itemObj.isTouchCamp) {
            data.object = constants.TOUCH_CAMPAIGNS_OBJECT;
            isOpenDrawer = false;
            openTouchCampaigns(itemObj);
        } else if (itemObj.isWebForms) {
            data.object = constants.WEB_FORMS;
            isOpenDrawer = false;
            openWebForms(itemObj);
        } else if (itemObj.isLandingPages) {
            data.object = '';
            isOpenDrawer = false;
            openLandingPages();
        }

        if (isOpenDrawer) {
            dispatch(getAppDrawer(true, itemObj.name, LinkToType.TYPE_EDIT, data, null));
        }
    }

    const openLandingPages = () => {
        if (document.getElementById("login") === null) {
            let form = document.createElement("form");
            form.setAttribute("method", "post");
            form.setAttribute("action", constants.LANDING_PAGE_LOGIN_URL);
            form.setAttribute("target", "_blank");
            form.setAttribute("id", "login");
            form.setAttribute("style", "display:none");

            let emailField = document.createElement("input");
            emailField.setAttribute("name", "email");
            emailField.setAttribute("id", "email");
            emailField.setAttribute("type", "hidden");
            emailField.setAttribute("value", appState.me.email);
            form.appendChild(emailField);

            let passwordField = document.createElement("input");
            passwordField.setAttribute("name", "password");
            passwordField.setAttribute("id", "password");
            passwordField.setAttribute("type", "hidden");
            passwordField.setAttribute("value", appState.me.projectId + '_' + appState.me.email);
            form.appendChild(passwordField);

            document.body.appendChild(form);
            form.submit();
        }
        else {
            document.getElementById("login").submit();
        }
    }

    const openWebForms = (webFormObj) => {
        let label = webFormObj.name;
        let tableId = webFormObj.tableId;
        let token = localStorage.getItem('token');
        let redirectUrl = constants.snapshotUrl;
        redirectUrl += '&token=' + token;
        redirectUrl += '&callingfor=webform';
        redirectUrl += '&recordId=' + webFormObj.id;
        redirectUrl += '&type=edit';
        redirectUrl += '&label=Edit ' + label;
        redirectUrl += '&tableId=' + tableId;
        window.open(redirectUrl);
    }

    const openTouchCampaigns = (touchCampaignObj) => {
        let label = touchCampaignObj.name;
        let token = localStorage.getItem('token');
        let redirectUrl = constants.snapshotUrl;
        redirectUrl += '&token=' + token;
        redirectUrl += '&callingfor=touchcampaign';
        redirectUrl += '&recordId=' + touchCampaignObj.id;
        redirectUrl += '&label=' + label;
        redirectUrl += '&type=edit';
        window.open(redirectUrl);
    }
        let dataArr = (data);
        let arr = [];
        let style = {};
        dataArr.map((item, i) => {
            if (i % 2 === 0) {
                style = { height: '30px', borderBottom: '1px solid #ddd', backgroundColor: '#fafafa' };
            } else {
                style = { height: '30px', borderBottom: '1px solid #ddd' };
            }
            let status = getLocalizedStrings().label.GROUP.hasOwnProperty(item.status) ? getLocalizedStrings().label.GROUP[item.status] : item.status;
            arr.push(<tr style={style}><td><div className='group_listview_precord_cdiv' title={item.name} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={(event) => openRecord(event,item)}>{item.name}</div></td>
            <td><div className='group_listview_precord_cdiv' title={status}>{status}</div></td></tr>);
        });
        return (
            <div>
                <div style={{ minHeight: '50px', maxHeight: '200px', overflowY: 'auto' }}>
                    {<table className='group_listview_table' style={{ borderStyle: 'none' }}><tbody>{arr}</tbody></table>}
                </div>
            </div>
        );
}

GroupDataTable.propTypes = {
    data: PropTypes.array
};