import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const GroupPagination =({pgDetails,getGroupCampaignDetails})=>
{
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(5);
    const [startNo, setStartNo] = useState(0);
    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [defaultPageSize, setDefaultPageSize] = useState(20);
    const [mounted, setMounted] = useState(false);
    const [selectedPageNo, setSelectedPageNo] = useState(1);

    useEffect(() => {
        let paginateObjDetails = pgDetails;
        let temptotalRecordCount = paginateObjDetails.totalRecordCount;
        setTotalRecordCount(temptotalRecordCount);
        let temppageCount = (paginateObjDetails.totalRecordCount - ( (paginateObjDetails.startNo-1) * paginateObjDetails.defaultPageSize)) / paginateObjDetails.defaultPageSize;
        setPageCount(temppageCount);
        let tempstartNo = paginateObjDetails.startNo;
        setStartNo(tempstartNo);
        let tempdefaultPageSize = paginateObjDetails.defaultPageSize;
        setDefaultPageSize(tempdefaultPageSize);
        setMounted(true);
    }, [pgDetails]);

    const getRecords = (objParam,curPage) => {
        objParam.fetchFrom = objParam.maxRecordSize * (curPage - 1);
        setSelectedPageNo(curPage);
        dispatch(getGroupCampaignDetails(objParam));
    }

    const goToNextPage = () => {
        let tempstartNo = startNo + 1; 
        setStartNo(tempstartNo)   
    }

    const goToPrevPage = () => {
        let tempstartNo = startNo - 1;
        setStartNo(tempstartNo)   

       
    }

      if(mounted){
            let page_size = pageSize;
        
            let pageNumbers = [];
            let mod = 0;
            let lastPageNo = 0;
        
            if(page_size >= pageCount){
                page_size = parseInt(pageCount);

                mod = totalRecordCount %  defaultPageSize;
                
                if(mod > 0 && mod < defaultPageSize){
                    page_size = page_size + 1;
                }
            }

            let  startingPageNo = startNo;
            let endPageNo = startingPageNo + page_size;
            let currPageNo = selectedPageNo;
            var objParam = {};
            objParam.recordId = pgDetails.recordId;
            objParam.type = pgDetails.campType;
            objParam.fetchCount = defaultPageSize;
            objParam.isRecordCount = true;
            objParam.maxRecordSize = defaultPageSize;
            
            if(page_size > 1 ){
                if((totalRecordCount / defaultPageSize) > page_size){
                    if(startingPageNo === 1){
                        pageNumbers.push(<li className='disabled'><a href="javascript:void(0)">&lt;</a></li>);
                    }else {
                        pageNumbers.push(<li><a href="javascript:void(0)" onClick={() => goToPrevPage()}>&lt;</a></li>);
                    }
                }

                for(let i=startingPageNo;i<endPageNo;i++){
                    if(i === currPageNo) {
                        pageNumbers.push(<li className='active'><a href="javascript:void(0)">{i}</a></li>);       
                    }else{
                        pageNumbers.push(<li><a href="javascript:void(0)"  onClick={() =>getRecords(objParam,i)}>{i}</a></li>);       
                }
                    lastPageNo = i;
                }
        
                if((totalRecordCount / defaultPageSize) > page_size){
                    if( totalRecordCount <= (lastPageNo * defaultPageSize) ){	
                        pageNumbers.push(<li className='disabled'><a href="javascript:void(0)">&gt;</a></li>);
                    }else{
                        pageNumbers.push(<li><a href="javascript:void(0)" onClick={() => goToNextPage()}>&gt;</a></li>);
                    }
                }
            }   

            return(<div style={{textAlign: 'center', width: '100%'}}>
                    <ul className="pagination">
                        {pageNumbers}
                    </ul>
                </div>
                );
        }else {
            return null;
        }
}


