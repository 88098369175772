import React, { useState, useEffect } from 'react';
import { isValidParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import * as sfDialogs from '../components/sfDialogs';
import ShowCircularProgress from '../components/circularProgress';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import MenuContainer from './MenuContainer';


let allMenuFRItems = [
    { key: 'calendar', name: 'Calendar' },
    { key: 'accounts', name: 'Accounts' },
    { key: 'contacts', name: 'Contacts' },
    { key: 'jobs', name: 'Jobs' },
    { key: 'inbox', name: 'Inbox' },
    { key: 'card_scan', name: 'Card Scanner' },
    { key: 'library', name: 'Library' },
    { key: 'news', name: 'News' },
];

let allMenuFEItems = [
    ...allMenuFRItems,
    { key: 'audits', name: 'Audits' }
];

const MobileMenuConfiguration = () => {
    const dispatch = useDispatch();
    const app = useSelector((state)=> state.app);
    const [mounted, setMounted] = useState(false);
    const [availableMenuItems, setAvailableMenuItems] = useState(app.me.is_franchisor ? getArrayParam(allMenuFRItems) : getArrayParam(allMenuFEItems));
    const [mobileMenuItems, setMobileMenuItems] = useState([]);
    const [mobileMenuItemsFix, setMobileMenuItemsFix] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [prevMobileItems, setPrevMobileItems] = useState([]);
    const [contentHeight] = useState(window.innerHeight - 240);
    const [top] = useState((contentHeight - 10) / 2);
    const [sections, setSections] = useState([]);

    const handleRemoveForcefix = (item) => {

        let itemNew = mobileMenuItems.filter(it => it.key === item.key)[0];

        try {
            if (mobileMenuItems.length === 1) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.CANNOT_DELETE_ALL_ITEM, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            } else {
                let msg = item.name + getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_REMOVE_MSG;
                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => removeMenuOnConfirmed(item), null);
            }
        } catch (error) {
            console.error("error in MobileMenuConfiguration.js -> handleRemoveForcefix" + error)
        }
    }

    const removeMenuOnConfirmed = (item) => {
        let params = {};
        try {
            params.key = item.key;
            params.name = item.name;
            params.is_remove = true;
            updateModuleItem(params);
        } catch (error) {
            console.error("error in MobileMenuConfiguration.js -> removeMenuOnConfirmed" + error)
        }
    }

    const handleRemove = (item) => {

        try {
            let mobileMenuItemsNew = getArrayParam(mobileMenuItems);
            if (mobileMenuItems.length === 1) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.CANNOT_DELETE_ALL_ITEM, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            } else {
                let param = { item_name: item.key };
                let promise = Promise.resolve(HTTPClient.post(endPoints.HOME.DELETE_MOBILE_MENU, param));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.REMOVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        let mobileMenuItemsNewVal = mobileMenuItemsNew.filter(m => m.key !== item.key);
                        let availableItems = getArrayParam(availableMenuItems);
                        let itemObj = { key: item.key, name: item.name };
                        availableItems.push(itemObj);
                        sortArrayObjectByProperty(mobileMenuItemsNewVal, 'orderId');
                        sortArrayObjectByProperty(availableItems, 'name');
                        setSections([{ key: '1', label: 'Available Menu Items', items: availableItems }, { key: '2', label: 'Mobile Menu', items: mobileMenuItemsNewVal }]);
                        setAvailableMenuItems(availableItems);
                        setMobileMenuItems(mobileMenuItemsNewVal);
                    });
                }
            }
        } catch (error) {
            console.error("Error in 'MobileMenuConfiguration.js -> handleRemove()':" + error);
        }
    }

    const updateIsEditProps = (isEdit) => {
        setIsEdit(isEdit);
    }

    const updateModuleItem = (params, isEdit) => {
        let mobileMenuItemsNew = getArrayParam(mobileMenuItems);
        if (isEdit) {
            let item = mobileMenuItemsNew.find(m => m.key === params.key);
            if (item) {
                item.name = params.name;
                item.key = params.key;
                handleSave(item);
            }
        } else {
            handleRemove(params);
        }
        setMobileMenuItems(mobileMenuItemsNew);
    }

    const moveCard = (columnId, columnIndex, menuId) => {
        if (columnId === '2') {
            let mobileMenuItemsNew = getArrayParam(mobileMenuItems);
            let availableMenuItemsNew = getArrayParam(availableMenuItems);

            let item = availableMenuItems.filter(m => m.key === menuId);
            let mobileItem = mobileMenuItemsNew.filter(m => m.key === menuId);

            if (mobileItem.length === 0) {
                mobileMenuItemsNew.push(item[0]);
            } else {

                if (columnIndex < mobileMenuItemsNew.length) {

                    mobileMenuItemsNew = mobileMenuItemsNew.filter(m => m.key !== mobileItem[0].key);

                    mobileMenuItemsNew = [
                        ...mobileMenuItemsNew.slice(0, columnIndex),
                        mobileItem[0],
                        ...mobileMenuItemsNew.slice(columnIndex)
                    ];

                }
            }

            mobileMenuItemsNew.map((elem, idx) => {
                mobileMenuItemsNew[idx].orderId = idx + 1
            });

            let availableMenuItemsNewVal = availableMenuItems.filter(m => m.key !== menuId);

            setSections([{ key: '1', label: 'Available Menu Items', items: availableMenuItemsNewVal }, { key: '2', label: 'Mobile Menu', items: mobileMenuItemsNew }]);
            setAvailableMenuItems(availableMenuItemsNewVal);
            setMobileMenuItemsFix(mobileMenuItemsNew);
        }
    };

    const handleSave = (item, flag = 0) => {

        try {
            let param = {};
            let mobileMenus = []
            if (flag === 1) {
                mobileMenus = getArrayParam(mobileMenuItemsFix);
            } else {
                mobileMenus = getArrayParam(mobileMenuItems);
            }

            let itemObj = mobileMenus.filter(m => m.key === item.key);
            if (itemObj.length > 0) {
                let prevMobileItemsNew = getArrayParam(prevMobileItems);

                let blankItems = mobileMenus.filter(f => f.name === "" || f.name === null || (f.name !== null && f.name.length === 0));

                blankItems.forEach(item => {
                    var prevItem = prevMobileItemsNew.filter(f => f.key === item.key);
                    item.name = prevItem[0].name;
                });

                param.mobileItems = mobileMenus;

                let promise = Promise.resolve(HTTPClient.post(endPoints.HOME.SAVE_MOBILE_MENU, param));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                         if (isValidParam(response) && response.status !== -1) {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        }
                        let allMenuItemList = getArrayParam(app.me.is_franchisor ? allMenuFRItems : allMenuFEItems);
                        let tempArr = getArrayParam(response.data).map(m => { return { key: m.name, name: m.label, canDelete: m.canDelete, orderId: m.orderId } });
                        let mobileMenuItemsData = tempArr;
                        setPrevMobileItems(_.cloneDeep(getArrayParam(mobileMenuItemsData)));
                        let availableItems = allMenuItemList.filter(ar => !mobileMenuItemsData.find(rm => (rm.key === ar.key)));
                        setAvailableMenuItems(availableItems);
                        setMobileMenuItems(mobileMenuItemsData);
                    });
                }
            }
        } catch (error) {
            console.error("Error in 'MobileMenuConfiguration.js -> handleSave()':" + error);
        }
    }

    const getDataContainer = () => {
        let element = [];
        let props = {};
        let height = window.innerHeight;
        let menuCardHeight = height - 210;
        try {
            props.moveCard = moveCard;
            props.handleSave = handleSave;
            props.handleRemoveForcefix = handleRemoveForcefix;
            props.isEdit = isEdit;
            element = sections.map((section, index) => {
                return (<MenuContainer
                    key={section.key + index}
                    {...props}
                    updateIsEditProps={updateIsEditProps}
                    columnId={section.key}
                    section={section}
                    col={sections.length}
                    updateModuleItem={updateModuleItem}
                    prevMobileItems={prevMobileItems}
                />);
            });

            element = <div id="module-config-id" className="kanbanBord "  style={{ width: '95%',height:menuCardHeight, padding: '10 0 0 25', display: 'inline-flex', position: 'relative' }}>{element}</div>
        } catch (error) {
            console.error("Error in 'MobileMenuConfiguration.js -> getDataContainer()':" + error);
        }
        return element;
    }

    const getMobileMenus = () => {
        try {
            let promise = Promise.resolve(HTTPClient.get(endPoints.HOME.GET_MOBILE_MENU, {}));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    let arr = getArrayParam(response);
                    if (isValidParam(arr) && arr.length > 0) {
                        let marr = arr.filter(ar => ar.t_status === 1);
                        let tempArr = marr.map(m => { return { key: m.name, name: m.label, canDelete: m.canDelete, orderId: m.orderId } });
                        let allMenuItemList = getArrayParam(app.me.is_franchisor ? allMenuFRItems : allMenuFEItems);
                        let availableItems = allMenuItemList.filter(ar => !tempArr.find(rm => (rm.key === ar.key)))

                        // New added
                        sortArrayObjectByProperty(tempArr, 'orderId');
                        sortArrayObjectByProperty(availableItems, 'name');
                        let section1 = { key: '1', label: 'Available Menu Items', items: availableItems }
                        let section2 = { key: '2', label: 'Mobile Menu', items: tempArr }
                        let sectionsData = [];
                        sectionsData.push(section1);
                        sectionsData.push(section2);
                        setSections(sectionsData);

                        setMobileMenuItems(tempArr);
                        setAvailableMenuItems(availableItems);
                        setPrevMobileItems(_.cloneDeep(getArrayParam(tempArr)));

                    }
                    setMounted(true);
                });
            }
        } catch (error) {
            console.error("Error in 'MobileMenuConfiguration.js -> getMobileMenus()':" + error);
        }
    }

    useEffect(() => {
        getMobileMenus();
    }, []);

    return (
        <div>
            {mounted ?
                <>
                    <div>{getDataContainer()}</div>
                </>
                :
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>}
        </div>
    );
}
export default MobileMenuConfiguration;