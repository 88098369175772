import { actions } from '..//constants/actions';

const taskAppInitialSate = {
    mounted: false,
    data: [],
    isrefresh: false,
}

const appTaskReducer = (state = taskAppInitialSate, action) => {
    switch (action.type) {
        case actions.TASK_APP_GET_FULFILLED:
            return {
                ...state,
                mounted: true,
                data: action.payload,
                isrefresh: false,
            }
        case actions.TASK_APP_UPDATE:
            return {
                ...state,
                mounted: action.payload.mounted,
                data: action.payload.data,
                isrefresh: false,
            }
        case actions.APP_TASK_REFRESH_REFRESH:
            return {
                ...state,
                // mounted: true,
                isrefresh: action.payload,
            }
        default:
            return state;
    }
}

export default appTaskReducer;