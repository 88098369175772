import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import ShowCircularProgress from '../components/circularProgress';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import * as HTTPClient from '../../../services/helper/httpClient';
import { Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Select, TextField } from '@mui/material';
import { detailViewIsMounted} from "../../../services/actions/detailViewActions";
import { getTasksRefresh } from '../../../services/actions/taskActions';
import {
    groupUsedInCampaigns,
    refreshDetailViewData,
    refreshDetailViewListViewData
} from '../../../services/actions/detailViewActions';
const RoyaltyRejectionReasonDialog = () => {

    const [reason, setReason] = useState("");
    const [isLoadded, setIsLoadded] = useState(true);
    const [data, setData] = useState("");

    const appContainer = useSelector((state) => state.appContainer);

    const dispatch = useDispatch();

    const getActionButtons = () => {
        let buttons = [];
        buttons.push(
            <Button
                primary={true}
                onClick={() => sendReason()}
                style={{ ...styles.primaryButton }}
            > Reject</Button>
        );

        buttons.push(
            <Button
                primary={true}
                onClick={() => handleClose()}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
        );
        return buttons;
    }
    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }
    const sendReason = () => {
        let _id = [];
        let id = appContainer.dialogProps.data.selectedRowId
        _id.push(id);
        let params = {
            action: 'Rejected',
            id: _id,
            rejectionReason: reason
        }
        console.log("reson", reason)
        let endPoint = 'v3/reportedsales/action/Rejected';
        //  endPoint = getObjectSpecificEndpoint(_actionname, endPoint);
        if (reason == '') {
            dispatch(showCustomSnackBar('Please enter Rejection reason.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else {
            let promise = Promise.resolve(HTTPClient.post(endPoint, params));
            promise.then((response) => {
                if (response.status === 0) {
                    let msg = "You have Rejected the Reported Sales record successfully.  " + "  " + "An email notification has been sent to the unit."
                    dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    dispatch(getAppDialog(false, null, null, null, null, null));
                    dispatch(detailViewIsMounted(false));
                    dispatch(getTasksRefresh(false));
                    dispatch(refreshDetailViewListViewData(true));
                    dispatch(refreshDetailViewData(true));
                }
            });
        }


    }

    const handleReasonChange = (event) => {
        let value = event.target.value;
        setReason(value);
    }

    let contentHeight = window.innerHeight - 300;
    let top = (contentHeight - 10) / 2;
    if (isLoadded) {
        return (
            <div>
                <div style={{ color: '#717171', marginTop: '10px' }}>
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            type='textarea'
                            multiline={true}
                            rows={3}
                            label="Rejection Reason"
                            value={reason}
                            InputLabelProps={{
                                style: {
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '96%',
                                }
                            }}
                            onChange={e => handleReasonChange(e)}
                            autoComplete="new-password"
                            margin='dense'
                            size='small'
                            className={"sf-fields-bg"}
                            autoFocus={true}
                        />
                    </FormControl>
                </div>
                <div style={{ float: 'right', marginTop: '10px' }}>
                    {getActionButtons()}
                </div>
            </div>
        )
    }
    else {
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        )
    }
}
export default RoyaltyRejectionReasonDialog;