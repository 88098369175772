
import React from 'react';
import PropTypes, { array } from 'prop-types';
import map from 'lodash/map'
import { connect } from 'react-redux';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { Button, Menu, Checkbox, Select, TextField, MenuItem, FormControlLabel, FormControl, Icon, Chip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import PopOver from '../components/PopOver';
import * as HTTPClient from '../../../services/helper/httpClient';
import { setHeader } from '../../../services/actions/headerActions';
import { endPoints } from '../../../services/constants/endPoints';
import { styles } from '../../../services/constants/styles';
import { LinkToType } from '../../../services/constants/link';
import { getImageNameByObject, getObjectLabelByObject } from '../../../services/helper/common';
import { getStringParam, isValidParam, getObjectParam, getBooleanParam, getIntParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import ShowCircularProgress from '../components/circularProgress';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import * as sfDialogs from '../components/sfDialogs';
import { getRemainingCallMinutes, getCallScriptList, getPowerCallingSkipedData, getPowerCallingData, getCallScriptDetails, saveNoteAfterCall, setPowerCallingFromNoUpdate, setPowerCallingSkipedIdsUpdate, setPowerCallingStatusUpdate, setPowerCallingIsCallingUpdate, setPowerCallingMaxCountUpdate, setPowerCallingNoteDataUpdate, setPowerCallingScriptUpdate, setPowerCallingScriptDataUpdate, setPowerCallingNoteButtonVisibilityUpdate } from '../../../services/actions/callAction';
import { getNoteType } from '../../../services/actions/batchActions';
import { getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import { OBJECT_TABLEID_MAP, COUNTRY_LIST_FOR_PHONE_SMS, COUNTRY_CODE_MAP, constants } from '../../../services/constants/constants';
import { getActiveTab, getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { setCookie, getCookie } from '../../../services/helper/sfCookies';
import '../../../asset/styles/css/power-dialer.css';
import '../../../asset/styles/css/dailpad.css';
import { Device } from 'twilio-client';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
let gblCountryArr = [];
const states = {
    CONNECTING: "Connecting",
    READY: "Ready",
    INCOMING: "Incoming",
    ON_CALL: "On call",
    OFFLINE: "Offline"
};
const PowerCalling = (object) => {
    const appState = useSelector((state) => state.app);
    const powerCalling = useSelector((state) => state.powerCalling);
    const sfForm = useSelector((state) => state.sfForm);



    const dispatch = useDispatch();
    const [queue, setQueue] = useState(false);
    const [openChangeList, setOpenChangeList] = useState(false);
    const [showButton, setShowButton] = useState({
        phone: true,
        mobile: true,
        hphone: true
    },);
    const [fieldsLabels, setFieldsLabels] = useState({
        phone: getLocalizedStrings().label.POWER_CALLING.PHONE,
        mobile: getLocalizedStrings().label.POWER_CALLING.MOBILE,
        hphone: getLocalizedStrings().label.POWER_CALLING.HOME_PHONE
    },);
    const [callingMode, setCallingMode] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isCallingSupport, setIsCallingSupport] = useState(true);
    const [isOutBoundPhoneNumberVerfied, setIsOutBoundPhoneNumberVerfied] = useState(true);
    const [outboundPhoneNumberArr, setOutboundPhoneNumberArr] = useState([]);
    const [defaultFromNumber, setDefaultFromNumber] = useState(powerCalling.defaultFromNumber);
    const [fromNumberCountryCode, setFromNumberCountryCode] = useState(powerCalling.fromNumberCountryCode);
    const [fromNumberExtension, setFromNumberExtension] = useState(powerCalling.fromNumberExtension);
    const [mounted, setMounted] = useState(false);
    const [toNumber, setToNumber] = useState(null);
    const [toPhone, setToPhone] = useState(null);
    const [toMobile, setToMobile] = useState(null);
    const [toHomePhone, setToHomePhone] = useState(null);
    const [callingTo, setCallingTo] = useState("phone");
    const [toNumberWithoutCountryCode, setToNumberWithoutCountryCode] = useState(null);
    const [formatedToNumber, setFormatedToNumber] = useState(null);
    const [dialCode, setDialCode] = useState(null);
    const [toExtension, setToExtension] = useState("");
    const [callScriptListArr, setCallScriptListArr] = useState([]);
    const [callingNoteObject, setCallingNoteObject] = useState({ primaryKey: "-999", scriptName: "Note", workflowId: "-9999" });
    const [selectedCallScript, setSelectedCallScript] = useState(powerCalling.selectedCallScript);
    const [callScriptDetails, setCallScriptDetails] = useState(null);
    const [noteTypeList, setNoteTypeList] = useState(null);
    const [selectedNoteType, setSelectedNoteType] = useState('Dialed');
    const [noteText, setNoteText] = useState(null);
    const [showCallSection, setShowCallSection] = useState(false);
    const [showCallScriptLink, setShowCallScriptLink] = useState(false);
    const [showCallScriptSection, setshowCallScriptSection] = useState(false);
    const [showNoteSection, setShowNoteSection] = useState(true);
    const [showMessageSection, setShowMessageSection] = useState(false);
    const [isMinimize, setIsMinimize] = useState(false);
    const [dialogTitle, setDialogTitle] = useState(null);
    const [isCalling, setIsCalling] = useState(false);
    const [isCallRecording, setIsCallRecording] = useState(false);
    const [selectedValue, setSelectedValue] = useState('scripts');
    const [cookieFromNumber, setCookieFromNumber] = useState(null);
    const [callCount, setCallCount] = useState(powerCalling.callCount);
    const [nextCallButton, setNextCallButton] = useState(false);
    const [callStarted, setCallStarted] = useState(powerCalling.callStarted);
    const [token, setToken] = useState("");
    const [selectedIds, setSelectedIds] = useState({});
    const [callData, setCallData] = useState(null);
    const [noteData, setNoteData] = useState(powerCalling.noteData);
    const [fieldValueForCallScriptDetails, setFieldValueForCallScriptDetails] = useState(powerCalling.fieldValueForCallScriptDetails);
    const [noteButtonVisibility, setNoteButtonVisibility] = useState(false);
    const [openAddList, setOpenAddList] = useState(false);
    const [label, setLabel] = useState("");
    const [isShowAddIcon, setIsShowAddIcon] = useState(false);
    const [openSendList, setOpenSendList] = useState(false);
    const [objectIconToolTip, setObjectIconToolTip] = useState('');
    const [isCallFieldFocused, setIsCallFieldFocused] = useState(true);
    const [maxCount, setMaxCount] = useState(powerCalling.maxCount);
    const [tenantCountryCode, setTenantCountryCode] = useState("");
    const [tenantCountryDialCode, setTenantCountryDialCode] = useState(false);
    const [skipedIds, setSkipedIds] = useState(powerCalling.skipedIds);
    const [savedCallingNote, setSavedCallingNote] = useState([]);
    const [addInfo, setAddInfo] = useState(null);
    const [gblCallStartTime, setGblCallStartTime] = useState(new Date().getTime());
    const [isbtnDisable, setIsbtnDisable] = useState(false);
    const [noteSavedAfterCalling, setNoteSavedAfterCalling] = useState(false);
    const [selectedNoteTypeList, setSelectedNoteTypeList] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [device, setDevice] = useState(null);
    const [conn, setConn] = useState(null);
    const [state, setState] = useState(states.CONNECTING);

    useEffect(() => {
        getTenantCountryDialCode();
        setCallingModeFromUser();
        getCapabilityTokenForCall();
        getOutBoundPhoneNumbers();
        getCallScriptListData();
        dispatch(setHeader(object.object, getLocalizedStrings().label.POWER_CALLING.POWER_DIALER, null, false, true));
        let tab = getActiveTab();
        let data = tab.data;
        let isCallRecording = powerCalling.isCallRecording;
        let noteButtonVisibility = powerCalling.noteButtonVisibility;
        let is_call_record = appState.me.is_call_record;
        if (isValidParam(is_call_record)) {
            isCallRecording = is_call_record;
        }
        setSelectedIds(data);
        setNoteButtonVisibility(noteButtonVisibility);
        setIsCallRecording(isCallRecording);

    }, [selectedCallScript]);

    const powerCallingDataInformationAlert = () => {
        let totalRecords = getArrayParam(powerCalling.callingInfo.ids).length;
        let returnRecords = getArrayParam(powerCalling.data.records).length;
        let callableRcords = totalRecords - returnRecords;
        let alertMessage = getLocalizedStrings().message.POWER_CALLING.ALERT_MSG_1 + totalRecords + ' ' + object.object + getLocalizedStrings().message.POWER_CALLING.ALERT_MSG_2 + returnRecords + getLocalizedStrings().message.POWER_CALLING.ALERT_MSG_3 + returnRecords + ' ' + object.object + '.';
        if (callableRcords > 0 && callCount === 0) {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMessage, null);
        }
    }

    useEffect(() => {
        let element = document.getElementsByClassName('form-control-phone');
        if (element !== null && element.length > 0 && isCallFieldFocused) {
            setFocusToTextBox(callingTo);
        }
        if (powerCalling.data !== null && powerCalling.data !== undefined && powerCalling.data.hasOwnProperty("records") && powerCalling.data.records.length > 0 && isLoading) {
            let newCount = powerCalling.callCount;
            setDataForPowerCall(newCount);
            powerCallingDataInformationAlert();
        }
    }, []);

    const setCallingModeFromUser = () => {
        setCallingMode("both");
        setQueue(true);

    }

    const setDataForPowerCall = (newCount) => {
        if (powerCalling.data !== null && powerCalling.data !== undefined && powerCalling.data.hasOwnProperty("records") && powerCalling.data.records.length > 0) {
            let powerCallingData = {};
            if (callData !== null) {
                powerCallingData = callData;
            } else {
                powerCallingData = powerCalling;
            }
            var i = newCount;
            let tempToMobile = powerCallingData.data.records[i].mobile;
            let tempToPhone = powerCallingData.data.records[i].phone;
            let tempToHomePhone = powerCallingData.data.records[i].hphone;
            let tempDialogTitle = powerCallingData.data.records[i].first_name + " " + powerCallingData.data.records[i].first_name;

            setToMobile(tempToMobile);
            setToPhone(tempToPhone);
            setToHomePhone(tempToHomePhone);
            setDialogTitle(tempDialogTitle);
            setShowCallScriptLink(true);

            getAddInfoFields(i, powerCallingData)
            let tempNoteText = getLocalizedStrings().message.POWER_CALLING.ALERT_MSG_4 + powerCallingData.data.records[i].first_name;
            setNoteText(tempNoteText);
            if (dialogTitle !== null) {
                getAddInfoFields(i, powerCallingData)
            }
            getTenantCountry(powerCallingData)
            if (isLoading) {
                let dataLength = powerCallingData.data.records.length
                if (dataLength === 1) {
                    let isMaxCount = true;
                    setPowerCallingMaxCountUpdate(isMaxCount);
                    setMaxCount(true);
                    setIsLoading(false);
                    setCallData(powerCallingData);

                } else {
                    setIsLoading(false);
                    setCallData(powerCallingData);
                }
            }
        }
    }

    const getAddInfoFields = (count, powerCallingData) => {
        let elements = [];
        let fields = [];
        let fieldLabel = null
        let tempField = null;
        let data = appState.me.power_calling_setup
        if (data !== null && data !== undefined && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].object === powerCallingData.callingInfo.parentObject) {
                    tempField = data[i].object_fields;
                }
            }
        }
        if (tempField !== null) {
            fields = tempField.split(',')
        }

        let length = fields.length;
        let fieldListData = getObjectParam(sfForm.data[powerCallingData.callingInfo.parentObject]);
        let propsField = getArrayParam(fieldListData.fields);
        if (propsField !== null && propsField !== undefined) {
            propsField = propsField.filter((f) => {
                if (f.field_type !== constants.FIELD_TYPE_HEADER) {
                    return f;
                }
            });
        }
        let fieldsData = null;
        let id = "filelds"
        for (let j = 0; j < length; j++) {
            let val = powerCallingData.data.records[count][fields[j]]
            let fieldLabelarry = propsField.filter((f) => {
                if (f.name === fields[j]) {
                    return f;
                }
            });
            if (fieldLabelarry !== null && fieldLabelarry.length > 0 && fieldLabelarry !== undefined) {
                fieldLabel = fieldLabelarry[0].label;
                if (j !== (length - 1)) {
                    if (val !== null && val !== "" && val !== undefined) {
                        fieldsData = val + ", ";
                        id = "filelds";
                    } else {

                        fieldsData = fieldLabel + ', ';
                        id = "gray_filelds";
                    }
                } else {
                    if (val !== null && val !== "" && val !== undefined) {
                        fieldsData = val;
                        id = "filelds"
                    } else {
                        fieldsData = fieldLabel;
                        id = "gray_filelds";
                    }
                }
                elements.push(<span id={id}>{fieldsData}</span>);
            }
        }
        setAddInfo(elements);
    }

    const maxCountValidation = (newCount) => {
        let isMaxCount = false
        let dataLength = callData.data.records.length - 1;
        if (newCount === dataLength) {
            isMaxCount = true
            setPowerCallingMaxCountUpdate(isMaxCount);
        } else if (dataLength > newCount) {
            isMaxCount = false
        }
        setMaxCount(isMaxCount);
    }
    const startNewCall = () => {
        setPowerCallingNoteDataUpdate(" ");
        getCallScriptListData();
        var newCount = (callCount + 1);
        setPowerCallingStatusUpdate(newCount);
        maxCountValidation(newCount);
        setPowerCallingNoteButtonVisibilityUpdate(false);
        setNoteData(" ");
        setCallCount(newCount);
        setCallStarted(false);
        setIsCallFieldFocused(true);
        setIsCalling(false);
        setNoteSavedAfterCalling(false);
        setNextCallButton(false);
        setNoteButtonVisibility(false);
        let tempShowButton = showButton;
        tempShowButton.phone = true;
        tempShowButton.mobile = true;
        tempShowButton.hphone = true;
        setShowButton(tempShowButton);
        setDataForPowerCall(newCount);
    }
    const skipedIdsHandle = () => {
        let powerCallingData = {};
        if (callData !== null) {
            powerCallingData = callData;
        } else {
            powerCallingData = powerCalling;
        }
        if (!callStarted) {
            let i = callCount
            let id = powerCallingData.data.records[i].id
            let tempSkipedIds = skipedIds;
            tempSkipedIds.push(id);
            setSkipedIds(tempSkipedIds);
            startNewCall()
            setPowerCallingSkipedIdsUpdate(skipedIds);
        } else {
            // this.saveNote();
            startNewCall()
        }
    }
    // const skiphandleforPowerCalling = () => {
    //     if (!powerCalling.callCompleted) {
    //         powerCalling.callCompleted = true;
    //         // this.saveNote();
    //     }
    //     dispatch(showCustomSnackBar(getLocalizedStrings().message.POWER_CALLING.ALERT_MSG_10, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
    // }

    const getCapabilityTokenForCall = () => {
        var promise = Promise.resolve(HTTPClient.get(endPoints.CALLING_INTEGRATION.GENERATE_CAPABILITY_TOKEN, null));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    let capabilityToken = getStringParam(response.token);
                    if (capabilityToken !== null && capabilityToken !== "") {
                        setToken(capabilityToken);
                        setupTwilio(response.token);
                    }
                }
            });
        }
    }
    const getTenantCountryDialCode = () => {
        let country = getStringParam(appState.me.tenant_country);
        let countryCode = "";
        let dialCode = "";
        if (country === "USA" || country === "Canada") {
            dialCode = "1";
        } else if (country === "India") {
            dialCode = "91";
        } else if (country === "United Kingdom") {
            dialCode = "44";
        } else if (country === "Germany") {
            dialCode = "49";
        } else if (country === "France") {
            dialCode = "33";
        } else if (country === "Spain") {
            dialCode = "34";
        } else if (country === "Netherlands") {
            dialCode = "31";
        } else if (country === "Australia") {
            dialCode = "61";
        }
        setTenantCountryCode("+" + dialCode);
        setTenantCountryDialCode(dialCode);
    }


    const getPowerCallingData = () => {
        setIsLoading(true);
        getPowerCallingSkipedData(skipedIds);
        if (skipedIds.length > 0) {
            let childObject = constants.CONTACTS_OBJECT;
            let _object = object.object;
            if (_object === constants.CONTACTS_OBJECT) {
                childObject = constants.ACCOUNTS_OBJECT
            }

            let params = { "ids": skipedIds, "callingMode": "both", "fields": ["company", "first_name", "last_name", "phone", "mobile", "hphone", "email", "workflow_id"], "parentObject": _object, "childObject": childObject }
            let url = '';
            let responseCallData = {};
            // getPowerCallingData(params);
            url = endPoints.POWER_CALLING.GET_POWER_CALLING_RECORD_DETAIL;
            let promise = Promise.resolve(HTTPClient.get(url, params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        getPowerCallingData(params, response);
                        setCallData(response);
                        setIsLoading(true);
                        setIsCalling(false);
                        setNoteSavedAfterCalling(false);
                        setCallCount(0);
                        setCallStarted(false);
                        setCallData(null);
                        setSkipedIds(powerCalling.skipedIds);
                    }
                });
            }
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "There is no data in skiped list", null);
        }
    }
    const getTenantCountry = (powerCallingData) => {

        let tenantCountry = getStringParam(appState.me.tenant_country);
        let countryRecord = getStringParam(powerCallingData.data.records[callCount].country);
        let gblCountry = null;

        let countryISO2CodeArr = appState.countryISO2;
        if (countryRecord === '') {
            gblCountry = tenantCountry;
            setIsCallingSupport(true);
        } else {
            if (COUNTRY_LIST_FOR_PHONE_SMS.indexOf(tenantCountry) > -1) {
                gblCountry = tenantCountry;
                setIsCallingSupport(true);
            } else {
                setIsCallingSupport(false);
                setShowCallSection(false);
                setShowCallScriptLink(false);
                setMounted(false);
            }
        }

        if (gblCountry !== null && gblCountry !== '' && isCallingSupport) {
            var countryISO2Arr = countryISO2CodeArr.filter(f => {
                return f.t_country === gblCountry;
            });
            if (countryISO2Arr !== null && countryISO2Arr.length > 0) {
                var countryISO2 = countryISO2Arr[0].t_country_iso2;
                gblCountryArr.push(countryISO2);
            }
        }
    }


    const getOutBoundPhoneNumbers = () => {
        let params = null;
        let cookieFromNo = cookieGetCallFromPhoneNo();
        let promise = Promise.resolve(HTTPClient.get(endPoints.CALLING_INTEGRATION.OUTBOUND_PHONE_NUMBERS_GET, params));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    let outboundPhoneNumbers = getArrayParam(response.outbound_phonenumbers);
                    if (outboundPhoneNumbers.length > 0) {
                        setOutboundPhoneNumberArr(outboundPhoneNumbers);
                        setShowCallSection(true);
                        setMounted(true);
                        if (cookieFromNo === null || cookieFromNo === "") {
                            setDefaultFromNumber(outboundPhoneNumbers[0].phoneNumber);
                            setFromNumberCountryCode(outboundPhoneNumbers[0].countryCode)
                            setFromNumberExtension(outboundPhoneNumbers[0].extension);
                        } else {
                            outboundPhoneNumbers.map((items, Index) => {
                                if (cookieFromNo === items.phoneNumber) {
                                    setDefaultFromNumber(items.phoneNumber);
                                    setFromNumberCountryCode(items.countryCode);
                                    setFromNumberExtension(items.extension);
                                }
                            })
                        }
                    } else {
                        setIsOutBoundPhoneNumberVerfied(false);
                        setMounted(true);
                    }
                    setPowerCallingFromNoUpdate(defaultFromNumber, fromNumberCountryCode, fromNumberExtension);
                }
            });
        }
    }

    const cookieSetCallFromPhoneNo = (value) => {
        let userid = appState.me.id;
        let cVarName = "callFromPhoneNo_" + userid
        document.cookie = cVarName + "=" + value;
    }

    const cookieGetCallFromPhoneNo = () => {
        let userid = appState.me.id;
        let cVarName = "callFromPhoneNo_" + userid + "=";
        let cookieVar = decodeURIComponent(document.cookie).split(';')
        for (var i = 0; i < cookieVar.length; i++) {
            var c = cookieVar[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(cVarName) === 0) {
                return c.substring(cVarName.length, c.length);
            }
        }
        return "";
    }

    const populateFromPhoneList = () => {
        let outboundPhoneList = [];
        let outboundPhoneListArr = outboundPhoneNumberArr;
        if (outboundPhoneListArr !== null && outboundPhoneListArr !== '' && outboundPhoneListArr !== undefined) {
            outboundPhoneListArr.map((outboundPhoneObj, i) => {
                let tempObj = {};
                tempObj.value = outboundPhoneObj.phoneNumber;
                tempObj.label = outboundPhoneObj.phoneNumber;
                outboundPhoneList.push(tempObj);
            }
            )
        }
        return outboundPhoneList;
    }

    const changeFromNumber = (data) => {
        let value = data.value
        let phoneArr = [];
        let fromNumberArr = outboundPhoneNumberArr;
        phoneArr = fromNumberArr.filter(f => { return f.phoneNumber === value });
        if (phoneArr !== null && phoneArr.length > 0 && phoneArr[0].hasOwnProperty('phoneNumber')) {
            setDefaultFromNumber(phoneArr[0].phoneNumber);
            setFromNumberCountryCode(phoneArr[0].countryCode);
            setFromNumberExtension(phoneArr[0].extension);
            setPowerCallingFromNoUpdate(defaultFromNumber, fromNumberCountryCode, fromNumberExtension);
        }

    }
    const setupTwilio = (token) => {
        const device = new Device()
        try {
            const device = new Device();
            device.setup(token, { debug: true });
            device.on("ready", () => {
                setDevice(device);
                setState(states.READY);
            });
            device.on("connect", connection => {
                console.log("Connect event");
                setConn(connection);
                setState(states.ON_CALL);
            });
            device.on("disconnect", () => {
                setState(states.READY);
                setConn(null);
                setPowerCallingNoteButtonVisibilityUpdate(true);
                setMounted(true);
                setIsCalling(false);
                setNoteSavedAfterCalling(true);
                setNoteButtonVisibility(true);
                let tempShowButton = showButton;
                tempShowButton.phone = true;
                tempShowButton.mobile = true;
                tempShowButton.hphone = true;
                setShowButton(tempShowButton);
            });
            device.on("incoming", connection => {
                setState(states.INCOMING);
                setConn(connection);
                connection.on("reject", () => {
                    setState(states.READY);
                    setConn(null);
                });
            });
            device.on("cancel", () => {
                setState(states.READY);
                setConn(null);
            });
            device.on("reject", () => {
                setState(states.READY);
                setConn(null);
            });
        } catch (error) {
            console.error("Error in 'powerCalling.js --> setupTwilio()':" + error);
        }
    }

    // End SETUP TWILIO //


    const focusNumFieldBeforecall = (val, toData) => {
        setIsCallFieldFocused(true);
        setCallingTo(val);
        var number = "";
        var ext = "";
        let value = toData;
        if (value.includes("ext.") || value.includes("x")) {
            let tempNumber = "";
            if (value.includes("ext.")) {
                tempNumber = value.split("ext.");
            } else {
                tempNumber = value.split("x");
            }
            if (tempNumber !== null && tempNumber !== "") {
                number = tempNumber[0].trimEnd();
                ext = tempNumber[1].trimStart();
            }
        } else {
            number = value;
        }
        let newFormatedToNumber = tenantCountryDialCode + number;

        setToNumberWithoutCountryCode(number);
        setFormatedToNumber(newFormatedToNumber);
        setToExtension(ext);

        checkPhoneTextProvider(val, newFormatedToNumber, ext);
    }


    const checkPhoneTextProvider = (val, newFormatedToNumber, ext) => {
        let phone_text_provider = appState.me.phone_text_provider ?? constants.NONE;
        if (phone_text_provider == constants.SOFFRONT) {
            callAfterRemainingCountCheck(val, newFormatedToNumber, ext);
        } else {
            call(val, newFormatedToNumber, ext);
        }
    }

    const callAfterRemainingCountCheck = (val, newFormatedToNumber, ext) => {
        let promise = Promise.resolve(getRemainingCallMinutes());
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    let remainingCallMins = response.remaining_call_mins;
                    if (remainingCallMins > 5) {
                        call(val, newFormatedToNumber, ext);
                    } else {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.TWILIO.PHONE_LOW_BALANCE1 + remainingCallMins + getLocalizedStrings().message.TWILIO.PHONE_LOW_BALANCE2 + "\n" + getLocalizedStrings().message.TWILIO.PHONE_LOW_BALANCE3, null);
                    }
                }
            });
        }
    }

    const call = (val, formatedToCallNumber, cExtension) => {

        if (mounted && device != null) {
            var makeCall = true;
            var remainingCallMins = 0;
            var protocol = window.location.protocol;
            var protocolStr = "https:"


            if (protocol !== null && protocol !== protocolStr) {
                //makeCall = false;  // for local enviroment comment out this line.
            }
            if (makeCall) {

                let fromNumber = "";
                let tempfromNumberCountryCode = fromNumberCountryCode;
                let fromPhone = defaultFromNumber;
                fromNumber = "+" + tempfromNumberCountryCode + "" + fromPhone;
                let gblToPhoneNumber = toNumberWithoutCountryCode;
                let temptoExtension = toExtension;

                if (gblToPhoneNumber === fromPhone && (temptoExtension === "" || temptoExtension.length === 0)) {
                    makeCall = false;
                }

                if (makeCall) {
                    let isStrated = true;
                    setPowerCallingIsCallingUpdate(isStrated);
                    let tempShowButton = getObjectParam(showButton);
                    for (var key in tempShowButton) {
                        if (tempShowButton.hasOwnProperty(key) && key === val) {
                            tempShowButton[key] = true;
                        } else {
                            tempShowButton[key] = false;
                        }
                    }
                    setPowerCallingNoteButtonVisibilityUpdate(false);
                    setShowButton(tempShowButton);
                    setNoteButtonVisibility(false);
                    setIsCalling(true);
                    setCallStarted(true);
                    cookieSetCallFromPhoneNo(defaultFromNumber);
                    let gblCallStartTime = new Date().getTime();
                    setGblCallStartTime(gblCallStartTime);

                    var params = {
                        "toNumber": formatedToCallNumber,
                        "toExtension": cExtension,
                        "fromNumber": fromNumber,
                        "fromExtension": fromNumberExtension,
                        "record": isCallRecording,
                        "projectId": appState.me.projectId,
                        "userId": appState.me.id,
                        "callStartTime": gblCallStartTime
                    };
                    console.log(params)
                    device.connect(params);
                } else {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.TWILIO.SAME_TO_AND_FROM_NUMBER, null);
                }
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.TWILIO.CALL_OVER_SECURED_NETWORK, null);
            }
        }
    }



    const getCallScriptListData = () => {
        let params = {}
        let promise = Promise.resolve(getCallScriptList(params));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {

                    let callScriptList = [];
                    callScriptList.push(callingNoteObject);
                    let newCallScriptList = [];
                    newCallScriptList = callScriptList.concat(getArrayParam(response.callScriptList));

                    let tempcallScriptListArr = newCallScriptList;
                    setCallScriptListArr(tempcallScriptListArr);
                    if (newCallScriptList !== null && newCallScriptList.length > 0) {
                        let callScriptValue = newCallScriptList.length > 0 ? newCallScriptList[0].primaryKey : newCallScriptList[0].primaryKey;
                        let cookieCallScript = getCoockieData(callScriptValue);
                        setSelectedCallScript(callScriptValue);

                        let callscriptObj = newCallScriptList.filter((obj) => { return obj.primaryKey === cookieCallScript });
                        let tempSelectedCallScript = callscriptObj.length > 0 ? callscriptObj[0].primaryKey : newCallScriptList[0].primaryKey;
                        setSelectedCallScript(tempSelectedCallScript);
                        setShowCallScriptLink(true);
                        if (selectedCallScript !== "-999") {
                            SelectedCallScriptFunc();
                        }
                    } else {
                        setShowCallScriptLink(false);
                    }
                }
            });
        }
    }

    const populateCallScriptList = () => {
        let tempArr = [];
        let tempCallScriptListArr = callScriptListArr;
        if (tempCallScriptListArr !== null && tempCallScriptListArr !== '' && tempCallScriptListArr !== undefined) {
            tempCallScriptListArr.map((callScriptListObj, i) => {
                let tempObj = {};
                tempObj.value = callScriptListObj.primaryKey;
                tempObj.label = callScriptListObj.scriptName;
                tempArr.push(tempObj);
            }
            )
        }
        return tempArr;
    }

    const changeCallScript = (event, value) => {
        let _value = value;
        let callScriptArr = [];
        let csArr = callScriptListArr;
        callScriptArr = csArr.filter(f => { return f.primaryKey == _value.value });
        if (callScriptArr !== null && callScriptArr.length > 0 && callScriptArr[0].hasOwnProperty('scriptName')) {
            let _selectedCallScript = callScriptArr[0].primaryKey;
            setSelectedCallScript(_selectedCallScript);

            SelectedCallScriptFunc();

            setPowerCallingScriptUpdate(_selectedCallScript);
            setCookiesData(_selectedCallScript);
        }
    }

    const SelectedCallScriptFunc = () => {
        let powerCallingData = {};
        if (callData !== null) {
            powerCallingData = callData;
        } else {
            powerCallingData = powerCalling;
        }
        getCallScriptDetailsFunc(powerCallingData);
    }

    const getCallScriptDetailsFunc = (powerCallingData) => {
        if (selectedCallScript !== "-999") {
            let params = {
                "call_script_id": selectedCallScript,
                "record_id": powerCallingData.data.records[callCount].id,
                "object_name": object.object
            }
            let promise = Promise.resolve(getCallScriptDetails(params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        let callScriptDetails = getStringParam(response.callScriptDetails);
                        setCallScriptDetails(callScriptDetails);
                        if (noteData !== "" || noteData !== " ") {
                            let tempfieldValueForCallScriptDetails = noteData + "\n" + callScriptDetails;
                            setFieldValueForCallScriptDetails(tempfieldValueForCallScriptDetails);
                        } else {
                            let tempfieldValueForCallScriptDetails = callScriptDetails;
                            setFieldValueForCallScriptDetails(tempfieldValueForCallScriptDetails);
                        }
                        setPowerCallingScriptDataUpdate(fieldValueForCallScriptDetails);
                    }
                });
            }
        } else {
            setFieldValueForCallScriptDetails(noteData);
            setPowerCallingScriptDataUpdate(fieldValueForCallScriptDetails);
        }
    }

    const populateNoteTypeList = () => {
        let tempnoteTypeList = '';
        let noteTypeArr = noteTypeList;
        if (noteTypeArr !== null && noteTypeArr !== '' && noteTypeArr !== undefined) {
            return noteTypeArr.sort().map((item, i) => {
                if (i === 0 && selectedNoteTypeList === null) {
                    setSelectedNoteTypeList(item);
                }
                return <MenuItem value={item} style={styles.popoverMenuItem} >{item}  </MenuItem>;
            }
            )
        }
        return tempnoteTypeList;
    }

    const saveNote = () => {
        var newNoteData = document.getElementById('noteTextArea').value;
        setIsbtnDisable(true);
        newNoteData = getStringParam(newNoteData);
        let i = callCount;
        let tableId = null;
        let tempObject = object.object;
        tableId = OBJECT_TABLEID_MAP[tempObject];
        let params = {
            "to_number": toNumberWithoutCountryCode,
            "table_id": tableId,
            "record_id": powerCalling.data.records[i].id,
            "note_type": 'Dialed',
            "note_text": newNoteData.trim(),
            "call_start_time": gblCallStartTime.toString(),
            "call_script_id": selectedCallScript,
        }
        let promise = Promise.resolve(saveNoteAfterCall(params));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    setNoteSavedAfterCalling(true);
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.TWILIO.NOTE_SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    setIsbtnDisable(false);
                    skipedIdsHandle();
                }
            });
        }
    }
    const saveNoteForLastRecord = () => {
        var newNoteData = document.getElementById('noteTextArea').value;
        setIsbtnDisable(true);
        newNoteData = getStringParam(newNoteData);
        let i = callCount;
        let tableId = null;
        let tempObject = object.object;
        tableId = OBJECT_TABLEID_MAP[tempObject];
        let params = {
            "to_number": toNumberWithoutCountryCode,
            "table_id": tableId,
            "record_id": powerCalling.data.records[i].id,
            "note_type": 'Dialed',
            "note_text": newNoteData.trim(),
            "call_start_time": gblCallStartTime.toString(),
            "call_script_id": selectedCallScript,
        }
        let promise = Promise.resolve(saveNoteAfterCall(params));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    setNoteSavedAfterCalling(true);
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.TWILIO.NOTE_SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    setIsbtnDisable(false);
                }
            });
        }
    }

    const setPowerCallingNoteDataValueUpdate = (noteScriptData) => {
        if (selectedCallScript === "-999") {
            setPowerCallingNoteDataUpdate(noteScriptData)
        }
    }

    const hangup = () => {
        device.disconnectAll();
    }

    const disconnectCall = () => {
        hangup();
    }

    const closePowerCall = () => {
        if (!powerCalling.callCompleted) {
            powerCalling.callCompleted = true;
            // this.saveNote();
        }
        let tempObject = object.object;
        let alert = getLocalizedStrings().message.POWER_CALLING.ALERT_MSG_5 + " " + tempObject + " " + getLocalizedStrings().message.POWER_CALLING.ALERT_MSG_6;

        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alert, null);
    }

    const updateNoteText = (event, value) => {
        setNoteText(value);
    }

    const setCookiesData = (scriptId) => {
        try {
            let projectId = appState.me.projectId;
            let userId = appState.me.id;
            let cookieName = 'callscript_' + projectId + '_' + userId;
            if (isValidParam(scriptId)) {
                setCookie(cookieName, scriptId, constants.DEFAULT_COOKIE_EXPIRES);
            }
        } catch (error) {
            console.error("Error in 'callDialog.js --> setCookiesData()':" + error);
        }
    }

    const getCoockieData = (scriptId) => {
        let selectionField = null;
        try {
            let projectId = appState.me.projectId;
            let userId = appState.me.id;
            let cookieName = 'callscript_' + projectId + '_' + userId;
            let cookieValue = getCookie(cookieName);
            if (!isValidParam(cookieValue) || (isValidParam(cookieValue) && getStringParam(cookieValue) === '')) {
                selectionField = scriptId;
            } else {
                selectionField = cookieValue;
            }
        } catch (error) {
            console.error("Error in 'callDialog.js --> getCoockieData()':" + error);
        }
        return selectionField;
    }

    const getTabButtonCss = (selectedTabVal) => {
        let css = {};
        try {
            css = { float: 'left', width: '100px', padding: 5, borderBottom: '2.0px solid #afafaf5c', borderRight: '1.0px solid #afafaf5c', borderRadius: '0px 0px 5px', cursor: 'pointer' };
            selectedTabVal = getStringParam(selectedTabVal);
            if (selectedValue === selectedTabVal) {
                css.background = '#00000014';
            }
        } catch (error) {
            console.error("Error in 'callDialog.js -> getTabButtonCss()':" + error);
        }
        return css;
    }

    const getDailPad = () => {
        let daiPadArr = [{ num: '1', text: '' }, { num: '2', text: 'abc' }, { num: '3', text: 'def' }, { num: '4', text: 'ghi' }, { num: '5', text: 'jkl' },
        { num: '6', text: 'mno' }, { num: '7', text: 'pqrs' }, { num: '8', text: 'tuv' }, { num: '9', text: 'wxyz' }, { num: '*', text: '' },
        { num: '0', text: '+' }, { num: '#', text: '' }];
        let padElements = [];
        try {
            daiPadArr.forEach((obj) => {
                let text = getStringParam(obj.text);
                let num = getStringParam(obj.num);

                let customizeStyle = { marginTop: '-7px' };
                let customizeStyle2 = { paddingTop: '12px' };
                let element = <li className="digits" key={'digits' + num} onClick={() => clickDailPad(num)} ><p key={'p-' + num}><div style={customizeStyle2}><strong>{num}</strong></div><div className='divStyle' style={customizeStyle}>{text}</div></p></li>;

                if (text === "") {
                    element = <li className="digits" key={'digits-' + num} onClick={() => clickDailPad(num)} ><p key={'p-' + num}><div style={customizeStyle2}><strong>{num}</strong></div></p></li>;
                }

                if (num === '*') {
                    element = <li className="digits" key={'digits-' + num} onClick={() => clickDailPad(num)} ><p key={'p-' + num}><div style={customizeStyle2} ><strong style={{ fontSize: '30px', marginTop: '14px' }}>{num}</strong></div></p></li>;

                }
                padElements.push(element)

            })

        } catch (error) {
            console.error("Error in 'callDialog.js -> getDailPad()':" + error);
        }
        return padElements;
    }

    const clickDailPad = (num) => {
        try {
            var conn = device.activeConnection();
            if (num && num != null && conn !== null && conn !== undefined && conn._status === 'open') {
                conn.sendDigits(num.trim());
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.POWER_CALLING.ALERT_MSG_7, null);
            }
        } catch (error) {
            console.error("Error in 'callDialog.js  -> clickDailPad()':" + error);
        }
    }

    const openAddScriptDrawer = () => {
        try {
            let data = {};
            data.id = 0;
            data.object = constants.CALL_SCRIPT_OBJECT;
            data.mode = constants.SF_FORM_OPEN_MODE_QUICK;
            data.parent_object = object;
            dispatch(getAppCustomDrawer(false, null, null, null, null, null, null));
            let objectLabel = getObjectLabelByObject(constants.CALL_SCRIPT_OBJECT);
            let labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + objectLabel;
            dispatch(getAppDrawer(true, labelName, 'New', data, 'list'));
        } catch (error) {
            console.error("Error in 'callDialog.js  -> clickDailPad()':" + error);
        }
    }

    const getActiveContactName = () => {
        let userData = callData.data.records[callCount]
        if (userData.first_name !== null || userData.last_name !== null) {
            let callingData = <div>

                <span style={{ fontSize: '25px', fontWeight: '700' }}> {userData.first_name} {userData.last_name}</span>

            </div>

            return callingData;
        }
        else {
            let callingData = <div>

                <span style={{ fontSize: '25px', fontWeight: '700' }}> {userData.company}</span>

            </div>

            return callingData;
        }
    }


    const setTempNoteData = () => {
        var newNoteData = document.getElementById('noteTextArea').value;
        var newData = noteData;

        var noteScriptData = "";
        if (selectedCallScript === "-999") {
            newData = newNoteData;
            noteScriptData = newData;
        } else {
            noteScriptData = newNoteData;
        }
        setNoteData(newData);
        setFieldValueForCallScriptDetails(noteScriptData);
        setIsCallFieldFocused(false);
        setPowerCallingScriptDataUpdate(noteScriptData);
        setPowerCallingNoteDataValueUpdate(noteScriptData);
    }

    const openAdd = (event) => {
        setOpenAddList(true);
        setAnchorEl(event.currentTarget);
    }

    const addAction = (value) => {
        setOpenAddList(false);
        if (value === "Appointments") {
            add(constants.APPOINTMENTS);
        } else if (value === "Tasks") {
            add(constants.TASKS);
        }
    }

    const generateAddActions = () => {
        let actions = null;
        let arrActions = [];
        arrActions.push(constants.APPOINTMENTS.charAt(0).toUpperCase() + constants.APPOINTMENTS.slice(1));
        arrActions.push(constants.TASKS.charAt(0).toUpperCase() + constants.TASKS.slice(1));

        let actionNameMap = {};
        actionNameMap[constants.APPOINTMENTS.charAt(0).toUpperCase() + constants.APPOINTMENTS.slice(1)] = getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENTS;
        actionNameMap[constants.TASKS.charAt(0).toUpperCase() + constants.TASKS.slice(1)] = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS;

        if (isValidParam(arrActions)) {
            actions = map(arrActions, function (actionName, i) {
                if (actionName !== 'Edit') {
                    return <MenuItem
                        key={actionName}
                        value={actionName}
                        style={{ ...styles.popoverMenuItem, margin: '4px', paddingLeft: '40px' }}
                        fullWidth={true}
                        labelstyle={{ color: '#479ccf' }}
                        underlinestyle={{ display: 'none' }}
                        autowidth={"true"}
                    >{<div style={{ width: '15px', marginRight: '0px' }} ><i className={getImageNameByObject(actionName.toLowerCase())} /></div>}{actionNameMap[actionName]}</MenuItem>
                }
            });
        }
        return actions;
    }

    const add = (objectName) => {
        let data = {
            id: 0,
            object: objectName,
            parent_object: object.object,
            parent_record_id: selectedIds[callCount],
            callType: 'TYPE_LIST_VIEW',
            isDetailView: true,
            isPowerCalling: true,
        }

        let labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + objectName.charAt(0).toUpperCase() + objectName.slice(1);
        dispatch(getAppDrawer(true, labelName, constants.NEW, data, constants.OTHER));
    }


    const openSend = (event) => {
        setOpenSendList(true);
        setAnchorEl(event.currentTarget);
    }

    const closeSend = () => {
        setOpenSendList(false);
    }

    const sendAction = (value) => {
        let i = callCount;
        let data = callData.data.records[i];
        setOpenSendList(false);
        if (value === "SMS") {
            processInfoAndOpenSMSDialog(data, data.id, 'send sms');
        } else if (value === "Email") {
            openSendMailDialog(data.email, constants.TYPE_EMAIL, object.object, getLocalizedStrings().label.COMMON.SEND_EMAIL, data.first_name, 'email')
        }
    }
    const generateSendActions = () => {
        let iconStyle = {
            fontSize: '17px', color: '#717171', cursor: 'pointer'
        }
        let phoneSetupData = powerCalling.data;
        let actions = null;
        let arrActions = [];
        if (phoneSetupData !== null && phoneSetupData !== undefined
            && phoneSetupData.hasOwnProperty("phoneSetup")
            && phoneSetupData.phoneSetup.is_twilio_sms) {
            arrActions.push(constants.SMS.charAt(0).toUpperCase() + constants.SMS.slice(1));
        }
        arrActions.push(constants.EMAIL.charAt(0).toUpperCase() + constants.EMAIL.slice(1));

        let actionNameMap = {};
        actionNameMap[constants.SMS.charAt(0).toUpperCase() + constants.SMS.slice(1)] = "SMS";
        actionNameMap[constants.EMAIL.charAt(0).toUpperCase() + constants.EMAIL.slice(1)] = "EMAIL";

        if (isValidParam(arrActions)) {
            actions = map(arrActions, function (actionName, i) {
                if (actionName !== 'Edit') {
                    return <MenuItem
                        key={actionName}
                        value={actionName}
                        style={{ ...styles.popoverMenuItem, margin: '4px', paddingLeft: '40px' }}
                        fullWidth={true}
                        labelstyle={{ color: '#479ccf' }}
                        underlinestyle={{ display: 'none' }}
                        autowidth={"true"}
                    >{<div style={{ width: '15px', marginRight: '0px' }} > {actionName === 'SMS' ? <Icon style={iconStyle} >textsms</Icon> : <i className="fa fa-paper-plane" aria-hidden="true" style={iconStyle}></i>}</div>}{actionNameMap[actionName]}</MenuItem>
                }
            });
        }
        return actions;
    }

    const viewFullText = (id) => {
        if (document.getElementById('f' + id).hasAttribute('hidden')) {
            document.getElementById('s' + id).setAttribute("hidden", "true")
            document.getElementById('f' + id).removeAttribute("hidden")
        } else {
            document.getElementById('f' + id).setAttribute("hidden", "true")
            document.getElementById('s' + id).removeAttribute("hidden")
        }

    }
    const getLastSavedNote = () => {
        let style = {
            noteHeading: {
                background: '#9c9a9a36',
                display: 'flex',
                padding: '5px',
                justifyContent: 'space-between',
                alignItems: 'center',
                alignContent: 'center',
                flexWrap: 'nowrap',
                flexDirection: 'row',
                marginBottom: '10px',
                marginTop: '5px'
            },
            noteText: {
                padding: '5px 0px 5px 0px',
                justifyContent: 'space-between',
                alignItems: 'center',
                alignContent: 'center',
                flexWrap: 'nowrap',
                flexDirection: 'row',
            },
            link: {
                color: 'blue'
            }
        }

        let data = savedCallingNote;
        let val = null
        var str = null
        if (data.length > 0) {
            val = data.map(nd => (
                <div>
                    <span style={style.noteHeading}>
                        <strong>{nd.name},&nbsp;{nd.cotactNumber},&nbsp;{nd.callinDate},&nbsp;,{nd.callStatus} </strong>
                    </span>
                    <span style={style.noteText} id={'s' + nd.id}>
                        {nd.noteData.length > 100 ? nd.noteData.substring(0, 100) : nd.noteData}{nd.noteData.length > 100 ? <span style={style.link}><a onClick={() => viewFullText(nd.id)} >&nbsp;...view more </a></span> : <span />}
                    </span>
                    <span style={style.noteText} hidden id={'f' + nd.id}>
                        {nd.noteData} <a onClick={() => viewFullText(nd.id)} >&nbsp;...view less </a>
                    </span>
                </div>
            ));
        }
        return val
    }

    const processInfoAndOpenSMSDialog = (records, recordId, fieldName) => {
        let firstName = "";
        let lastName = "";
        let fullName = "";
        let company = "";
        let title = "";
        let country = "";
        if (isValidParam(records)) {
            firstName = getStringParam(records.first_name);
            lastName = getStringParam(records.last_name);
            company = getStringParam(records.company);
            country = getStringParam(records.country);
        }

        country = appState.me.tenant_country;

        fullName = (firstName + ' ' + lastName).trim();
        fullName = fullName === '' ? company : fullName;
        fullName = fullName === '' ? getStringParam(records.mobile) : fullName;
        title = fullName !== '' ? 'To ' + fullName : title;

        let label = title;
        let type = LinkToType.TYPE_CLICK_TO_SMS;
        let countryCode = tenantCountryCode;
        let TempformatedToNumber = getStringParam(formatedToNumber);
        let toNumber = TempformatedToNumber.startsWith('+') ? TempformatedToNumber : '+' + TempformatedToNumber;
        let data = {};
        data.id = recordId;
        data.object = object.object;
        data.toNumber = toNumber;
        data.fieldName = fieldName;
        data.country = country;
        data.fullName = fullName;
        data.isDetailView = true;
        let style = {};
        style.width = '40%';
        dispatch(getAppCustomDrawer(true, label, type, style, data, null, false));
    }


    const openSendMailDialog = (email, dataType, objectName, label, firstName, fieldName) => {
        let i = callCount;
        let type = LinkToType.TYPE_EMAIL;
        let data = {};
        data.id = callData.data.records[i].id;
        data.email = email;
        data.object = objectName;
        data.type = dataType;
        data.firstName = firstName;
        data.fieldName = fieldName;
        dispatch(getAppCustomDrawer(true, label, type, { width: '90%' }, data, null, true));
    }

    const setFocusToTextBox = (val) => {
        let id = 'to' + val + 'no';
        var textbox = document.getElementById(id);
        textbox.focus();
    }

    const getgroupChangeDrawer = () => {
        setOpenChangeList(false);
        let tabInfo = getActiveTabInfo();
        let isInXpress = getBooleanParam(tabInfo.isInXpress);
        let i = callCount;
        let linkType = constants.ADD_TO_GROUP;
        let label = getLocalizedStrings().label.DETAIL_VIEW.ADD_TO_GROUP;
        let isOpenDrawer = true;
        let data = {};
        data.id = callData.data.records[i].id;
        if (!isInXpress) {
            dispatch(getAppDrawer(isOpenDrawer, label, linkType, data, constants.POWER_CALLING));
        } else {
            let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
        }

    }
    const getMenuItems = () => {
        let itemsArr = [];
        let items = [];
        try {
            itemsArr.push({ value: 'Appointments', text: getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENTS, rightIconClassName: 'fa fa-calendar' });
            itemsArr.push({ value: 'Tasks', text: getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS, rightIconClassName: 'fa fa-clock-o' });

            itemsArr.map((item, i) => {
                let data = {};
                data.label = item.text;
                data.value = item.value;
                data.leftIcon = <i className={item.rightIconClassName} />;
                items.push(data);
            });
        } catch (error) {
            console.error("Error in 'assetsHome.js -> getMenuItems()':" + error);
        }
        return items;
    }
    const getSendMenuItems = () => {
        let itemsArr = [];
        let items = [];
        try {
            itemsArr.push({ value: "SMS", label: "SMS", leftIcon: <Icon>textsms</Icon> });
            itemsArr.push({ value: 'Email', label: 'Email', leftIcon: <i className='fa fa-paper-plane' /> });
        } catch (error) {
            console.error("Error in 'assetsHome.js -> getMenuItems()':" + error);
        }
        return items;
    }
    const handleCallRecording = (event, isInputChecked) => {
        if (event !== undefined && isInputChecked) {
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.TWILIO.CALL_RECORDING_LAWS_AND_REGULATION_ALERT, () => handleSfDialogConfirm(isInputChecked), null);
        } else {
            handleSfDialogConfirm(isInputChecked);
        }
    }

    const handleSfDialogConfirm = (isInputChecked) => {
        setIsCallRecording(isInputChecked);
        powerCalling.isCallRecording = isInputChecked;

    }
    const prepareCallSection = (callObject) => {
        let fields = [];
        for (let i = 0; i < callObject.length; i++) {
            let key = callObject[i];
            let identifiers = 'to' + key.type + 'no';
            let callSection = <div />;
            let viewButton = showButton[key.type];
            if (key.number !== undefined && key.number !== null && key.number !== "") {
                callSection = <div style={{ display: 'flex', marginBottom: '0px' }}>
                    <div style={{ float: 'left', marginRight: '10px', width: '60%' }}>

                        <div className="" id="noHolder">
                            <div className style={styles.customInputHolder}>
                                <label htmlFor={identifiers} style={styles.inputLabel}>
                                    {key.label}
                                </label>
                                <input
                                    type="text"
                                    name={identifiers}
                                    value={tenantCountryCode + " " + key.number}
                                    autoComplete="new-password"
                                    id={identifiers}
                                    style={styles.customInput}
                                />
                            </div>
                        </div>

                    </div>

                    <div>
                        {viewButton && isCalling && <Button
                            onMouseEnter={() => { setFocusToTextBox(key.type) }}
                            key={key.type + 'Disconnect'}
                            onClick={() => { disconnectCall() }}
                            startIcon={<i className="fa fa-phone" style={{ fontWeight: 'normal', fontSize: '1.4em', rotate: '135deg' }}></i>}

                            style={{ ...styles.primaryButton1, height: "35" }}
                        >{getLocalizedStrings().label.POWER_CALLING.END_CALL}</Button>}
                        {viewButton && !isCalling && <Button
                            onMouseEnter={() => { setFocusToTextBox(key.type) }}
                            key={key.type + 'Connect'}
                            onClick={() => { focusNumFieldBeforecall(key.type, key.number) }}
                            startIcon={<i className="fa fa-phone" style={{ fontWeight: 'normal', fontSize: '1.4em' }}></i>}

                            style={{ ...styles.successButton, height: "35" }}
                        >{getLocalizedStrings().label.TWILIO.CALL}</Button>}
                        {!viewButton && <Button
                            key={key.type + 'DisabledConnect'}
                            startIcon={<i className="fa fa-phone" style={{ fontWeight: 'normal', fontSize: '1.4em' }}></i>}
                            style={styles.disabledButton}
                        >{getLocalizedStrings().label.TWILIO.CALL}</Button>}
                    </div>
                </div>
            }
            fields.push(callSection);
        }
        return fields;
    }

    const openHelpUrl = () => {
        let url = 'http://support.soffront.com/knowledge-base/phone-recording-compliance/';
        window.open(url, '_blank');
    }
    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight - 10) / 2
    let selectMenuItem = getMenuItems();
    let sendList = getSendMenuItems();
    let iconStyle = {
        fontSize: '17px', color: '#717171', cursor: 'pointer', marginRight: '10px', marginLeft: '9px'
    }
    if (isMinimize) {
        return (

            <div />
        );
    }
    else if (mounted && token !== '' && !isLoading && callingMode !== null && queue && device !== null) {
        if (callData.data !== null && callData.data !== undefined && callData.data.hasOwnProperty("records") && callData.data.records.length > 0) {
            let powerCallingData = {};
            if (callData !== null) {
                powerCallingData = callData;
            } else {
                powerCallingData = powerCalling;
            }
            let tenantCountry = getStringParam(appState.me.tenant_country);
            let userData = powerCallingData.data.records[callCount];
            let countryCode = COUNTRY_CODE_MAP[tenantCountry];
            countryCode = countryCode.split("+")[1];
            if (userData.mobile !== null && userData.mobile !== undefined && userData.mobile.indexOf("+", 0) === 0) {
                userData.mobile = userData.mobile.split("+")[1];
            }
            if (userData.mobile !== null && userData.mobile !== undefined && userData.mobile.indexOf(countryCode, 0) === 0) {
                userData.mobile = userData.mobile.replace(countryCode, '');
            }
            if (userData.phone !== null && userData.phone !== undefined && userData.phone.indexOf("+", 0) === 0) {
                userData.phone = userData.phone.split("+")[1];
            }
            if (userData.phone !== null && userData.phone !== undefined && userData.phone.indexOf(countryCode, 0) === 0) {
                userData.phone = userData.phone.replace(countryCode, '');
            }
            if (userData.hphone !== null && userData.hphone !== undefined && userData.hphone.indexOf("+", 0) === 0) {
                userData.hphone = userData.hphone.split("+")[1];
            }
            if (userData.hphone !== null && userData.hphone !== undefined && userData.hphone.indexOf(countryCode, 0) === 0) {
                userData.hphone = userData.hphone.replace(countryCode, '');
            }
            let callObject = [];
            let tempShowButton = getObjectParam(showButton);
            for (var key in tempShowButton) {
                let tempObj = {};
                tempObj.type = key;
                tempObj.number = userData[key];
                tempObj.label = fieldsLabels[key];
                callObject.push(tempObj);
            }
            let fromPhoneList = populateFromPhoneList();
            let callScriptList = populateCallScriptList();
            let noteTypeList = populateNoteTypeList();
            let dataTipLabel = 'info';

            return (
                <div >
                    <div className=" row ">

                        <div className="col-lg-12">
                            <div className="row">

                                <div className="col-lg-12" style={{ display: 'flex' }}>
                                    <div>
                                        <span style={{ display: 'flex' }}>
                                            <div>{getActiveContactName()}</div>
                                            <p style={{ paddingLeft: '5px', marginTop: '6px' }}>
                                                ( {callCount + 1} {getLocalizedStrings().label.POWER_CALLING.OF} {callData.data.records.length} )
                                            </p>
                                        </span>
                                        <span style={{ fontSize: '16px' }}>
                                            {addInfo}
                                        </span>
                                    </div>
                                    <div style={{ marginLeft: "20px", marginTop: '6px' }}>
                                        {isCalling &&
                                            <Button
                                                key='nextconnect'
                                                style={styles.disabled} > {getLocalizedStrings().label.POWER_CALLING.NEXT}</Button>}
                                        {maxCount && callStarted && !isCalling &&
                                            <Button
                                                key='closeconnect'
                                                onClick={() => closePowerCall()}
                                                style={styles.primaryButton1} >{getLocalizedStrings().label.POWER_CALLING.COMPLETE}</Button>}
                                        {maxCount && !callStarted &&
                                            <Button
                                                key='nextconnect'

                                                style={styles.disabled} > {getLocalizedStrings().label.POWER_CALLING.NEXT}</Button>}
                                        {!isCalling && !maxCount &&
                                            <Button
                                                key='nextconnect'
                                                onClick={() => skipedIdsHandle()}

                                                style={styles.primaryButton} >{getLocalizedStrings().label.POWER_CALLING.NEXT}</Button>}
                                    </div>
                                </div>

                            </div>


                            <div className="row" style={{ marginTop: '25px' }}>
                                <div className="col-lg-4" style={{ paddingRight: '0px' }}>

                                    <div>
                                        {prepareCallSection(callObject)}
                                    </div>


                                    <div>

                                        {!showCallScriptLink &&
                                            <div style={{ borderLeft: '1px solid', height: '270px', padding: '40px', marginLeft: 20, paddingRight: 0, marginTop: 25, paddingTop: 100, paddingLeft: 25 }}>
                                                <div>
                                                    <span>{getLocalizedStrings().message.POWER_CALLING.YOU_DONT_HAVE_CALL_SCRIPT}</span>
                                                </div>
                                            </div>
                                        }
                                        {!isCallingSupport && !isLoading && callingMode !== null &&

                                            <div>  {getLocalizedStrings().message.TWILIO.CALLING_SUPPORT}</div>}
                                    </div>
                                    <div className="dialPad compact" key='dailPad' style={{ maxWidth: '260px', paddingLeft: '0%' }}>
                                        <div className="dials" key='dails-1' >
                                            <ol> {getDailPad()} </ol>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8" style={{ paddingTop: '1px', paddingLeft: '0px' }}>
                                    <div className="" >

                                        <div style={{ display: 'flex', paddingLeft: '15px' }}>
                                            {fromPhoneList.length > 0 && <Autocomplete
                                                style={{ width: '256px', marginBottom: '10px' }}
                                                disableClearable
                                                options={fromPhoneList}
                                                value={fromPhoneList.find(i => i.value === defaultFromNumber)}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(v) => changeFromNumber(v)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={getLocalizedStrings().label.POWER_CALLING.CALLER_ID}
                                                        variant="outlined"
                                                        fullWidth
                                                        margin='dense'
                                                        size='small'
                                                        className={"sf-fields-bg"}
                                                    />

                                                )}

                                            />}
                                            {!isOutBoundPhoneNumberVerfied && !isLoading && callingMode !== null &&
                                                <div style={{ paddingRight: '15px', marginTop: '10px' }}>

                                                    {getLocalizedStrings().message.TWILIO.OUTBOUND_PHONE_NOT_VERIFIED}
                                                </div>}
                                            <div style={{ paddingLeft: '10px', paddingTop: '6px' }}>
                                                <FormControlLabel control={<Checkbox color="default" key='callRecording'
                                                    name='callRecording'
                                                    checked={isCallRecording}
                                                    value={isCallRecording}
                                                    onChange={(e) => handleCallRecording(e, e.target.checked)} />} label='Record this call' labelposition="right"
                                                    labelstyle={{ color: '#717171', fontWeight: 'normal', marginLeft: '-6px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                                    style={{ height: 'auto', width: '100%' }} title='Record this call' />
                                            </div>
                                            <div style={{ paddingLeft: '5px' }}>
                                                <div >
                                                    <Icon
                                                        style={{ cursor: 'pointer', color: '#717171', fontSize: '24px', marginTop: '13px' }}
                                                        title={getLocalizedStrings().label.DETAIL_VIEW.CLICK_TO_KNOW_MORE}
                                                        onClick={() => openHelpUrl()}>
                                                        info_outline</Icon>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {noteButtonVisibility && callStarted &&
                                        <div style={{ marginBottom: '5px', display: 'flex', marginLeft: '67%' }}>

                                            <span style={{ paddingLeft: '5px' }}>
                                                <PopOver
                                                    id={"PopOver"}
                                                    key={"PopOver"}
                                                    buttonEndIcon={"keyboard_arrow_down"}
                                                    buttonStyle={styles.secondaryButton}
                                                    labelposition={"before"}
                                                    buttonLabel={getLocalizedStrings().label.COMMON.ADD}
                                                    options={selectMenuItem}
                                                    onclickAction={(value) => addAction(value)}
                                                />
                                            </span>
                                            <span style={{ paddingLeft: '10px' }}>
                                                <Button
                                                    endIcon={<Icon style={{ fontWeight: 'bold' }} >keyboard_arrow_down</Icon>}
                                                    onClick={(e) => openSend(e)}


                                                    style={styles.secondaryButton}
                                                > {getLocalizedStrings().label.POWER_CALLING.SEND}</Button>
                                                <Menu
                                                    open={openSendList}
                                                    anchorEl={anchorEl}
                                                    keepMounted id='modules'
                                                    onClose={() => closeSend()}
                                                    style={{ marginTop: '0px' }}
                                                >
                                                    <MenuItem key='Email' id='Email' value='Email'
                                                        onClick={() => sendAction('Email')}

                                                    >{<i className="fa fa-paper-plane" aria-hidden="true" style={iconStyle}></i>} {getLocalizedStrings().label.MAIL.SEND_MAIL}</MenuItem>
                                                    <MenuItem key='SMS' id='SMS' value='SMS'
                                                        onClick={() => sendAction('SMS')}
                                                    > {<Icon style={{ height: 14, width: 14, margin: 9, fontSize: 14, color: '#717171' }}>textsms</Icon>} {getLocalizedStrings().label.TWILIO_SMS.SEND_SMS}</MenuItem>
                                                </Menu>
                                            </span>
                                            <span style={{ paddingLeft: '5px' }}>

                                                <PopOver
                                                    id={"PopOver"}
                                                    key={"PopOver"}
                                                    buttonEndIcon={"keyboard_arrow_down"}
                                                    buttonStyle={styles.changeBtn}
                                                    labelposition={"before"}
                                                    buttonLabel={getLocalizedStrings().label.POWER_CALLING.CHANGE_GROUP}
                                                    options={[{ value: 'group', label: 'Group' }]}
                                                    onclickAction={() => getgroupChangeDrawer()}
                                                />
                                            </span>

                                        </div>
                                    }

                                    <div>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div>
                                                    {callScriptList.length > 1 && <Autocomplete
                                                        style={{ width: '100%', marginBottom: '10px' }}
                                                        disableClearable
                                                        options={callScriptList}
                                                        value={callScriptList.find(i => i.value == selectedCallScript)}
                                                        getOptionLabel={(option) => option.label}
                                                        onChange={(event, value) => changeCallScript(event, value)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={getLocalizedStrings().label.POWER_CALLING.NOTE_AND_CALL_SCRIPTS}
                                                                variant="outlined"
                                                                fullWidth
                                                                margin='dense'
                                                                size='small'
                                                                className={"sf-fields-bg"}
                                                            />
                                                        )}
                                                    />
                                                    }
                                                </div>

                                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                                    <TextField
                                                        variant="outlined"
                                                        name="call_script_details"
                                                        multiline={true}
                                                        type='textarea'
                                                        rows={6}
                                                        id='noteTextArea'
                                                        onChange={() => setTempNoteData()}
                                                        fullWidth={true}
                                                        textareastyle={{ marginTop: '5px', resize: 'none', marginBottom: '5px' }}
                                                        style={{ width: '100%', maxHeight: '250px', height: '100%', backgroundColor: '#fff', marginBottom: '12px' }}
                                                        value={fieldValueForCallScriptDetails}
                                                        className={"sf-fields-bg"}
                                                    />
                                                </FormControl>

                                            </div>

                                            {noteButtonVisibility && callStarted &&

                                                <span style={{ marginLeft: '82%' }}>
                                                    {!maxCount &&
                                                        <Button
                                                            onClick={() => saveNote()}
                                                            style={styles.primaryButton}
                                                            disabled={isbtnDisable}
                                                        > {getLocalizedStrings().label.COMMON.SAVE}</Button>
                                                    }
                                                    {maxCount &&
                                                        <Button
                                                            onClick={() => saveNoteForLastRecord()}
                                                            style={{ ...styles.primaryButton, marginLeft: '60px' }}
                                                            disabled={isbtnDisable}
                                                        > {getLocalizedStrings().label.COMMON.SAVE}</Button>
                                                    }

                                                    {!maxCount &&
                                                        <Button
                                                            onClick={() => skipedIdsHandle()}
                                                            style={styles.primaryButton}
                                                        > {getLocalizedStrings().label.COMMON.CLOSE}</Button>}

                                                    {/* {maxCount &&
                                                        <Button
                                                            onClick={() => skiphandleforPowerCalling()}
                                                            style={styles.primaryButton}
                                                        > {getLocalizedStrings().label.COMMON.CLOSE}</Button>} */}

                                                </span>
                                            }
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    else {
        return (<div className="loaderh">
            {<div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>}
        </div>);
    }
}

export default PowerCalling;
