import { actions } from '..//constants/actions';
import store from '../../../src/services/store';

function setHeader(selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader) {
    return function (dispatch) {
        dispatch({
            type: actions.SET_HEADER,
            payload: {
                selectedTabObject: selectedTabObject,
                selectedHeader: selectedHeader,
                kanban: kanban,
                showAddIcon: showAddIcon,
                showHeader: showHeader
            }
        })
    }
}

function setHeaderByDispatch(selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader) {
    store.dispatch(setHeader(selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader));
}

function doRefreshAssets(refresh) {
    return {
            type: actions.REFRESH_ASSETS,
            payload: {
                refresh: refresh,
            }
       
    }
}

function doRefreshOnboardingUnits(isRefresh) {
    return {
        type: actions.REFRESH_ONBOARDING_UNITS,
        payload: {
            isRefresh: isRefresh,
        }
    }
}

export {
    setHeader,
    setHeaderByDispatch,
    doRefreshAssets,
    doRefreshOnboardingUnits
}
