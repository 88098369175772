import React, { useEffect, useState } from 'react';
import ListView from './ListView';
import { styles } from '../../../services/constants/styles';
import { setHeader } from '../../../services/actions/headerActions';
import { constants } from '../../../services/constants/constants';
import ShowCircularProgress from '../components/circularProgress';
import { isValidParam, getObjectParam, getStringParam } from '../../../services/helper/parameterVerifier';
import { getActiveTab, } from '../../../services/helper/sfTabManager';
import { useDispatch, useSelector } from 'react-redux';

const SearchResults = ({ object }) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const [searchCriteria, setSearchCriteria] = useState(null);
    const [selectedModule, setSelectedModule] = useState('');
    const [searchParams, setSearchParams] = useState({
        query_name: constants.SEARCH_QUERY_NAME,
        query_type: constants.SEARCH_QUERY_TYPE_GLOBAL,
        search_text: app.searchCriteria.searchText
    });
    const [isMounted, setMounted] = useState(false);

    useEffect(() => {
        let label = "Search Results";
        let kanbanObject = {};
        kanbanObject.currentView = '';
        kanbanObject.show = false;
        let tempSearchParams = searchParams;
        dispatch(setHeader(object, label, kanbanObject, false, true));
        setSearchCriteria(app.searchCriteria);
        if (!isValidParam(app.searchCriteria) || Object.keys(app.searchCriteria).length === 0) {
            let activeTab = getActiveTab();
            app.searchCriteria = getObjectParam(activeTab.info);
            setSelectedModule(getStringParam(app.searchCriteria.object));
            tempSearchParams.search_text = app.searchCriteria.searchText;
            setSearchParams({ ...tempSearchParams });
        } else {
            setSelectedModule(getStringParam(app.searchCriteria.object));
            tempSearchParams.search_text = app.searchCriteria.searchText;
            setSearchParams({ ...tempSearchParams });
            setMounted(true);
        }

    }, []);

    useEffect(() => {
        if (JSON.stringify(searchCriteria) !== JSON.stringify(app.searchCriteria)) {
            let tempSearchParams = searchParams;
            setSearchCriteria(app.searchCriteria);
            let tempSearchCriteria = getObjectParam(app.searchCriteria);
            setSelectedModule(getStringParam(tempSearchCriteria.object));
            tempSearchParams.search_text = getStringParam(tempSearchCriteria.searchText);
            setSearchParams({ ...tempSearchParams });
            setMounted(true);
        }

    }, [app]);

    let activeTab = getObjectParam(getActiveTab());
    let tabInfo = getObjectParam(activeTab.info);
    let paddingLeft = '0px';
    if (tabInfo.hasOwnProperty('selectedCheckBoxes') && tabInfo.selectedCheckBoxes.length !== 1) {
        paddingLeft = '26px';
    }
    let listViewWidth = '100%';
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2
    return (
        <>
            <div style={{ width: '98%', paddingLeft: paddingLeft }}>
                <div className="row">
                    <div className='col-md-11' style={{ width: listViewWidth }}>
                        <div>
                            {isMounted ?
                                <ListView object={selectedModule} isSearch={true} searchParams={searchParams} searchText={searchParams.search_text} />
                                : <div style={{ width: '100%', height: contentHeight }}>
                                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchResults;
