import React from 'react';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import { isValidParam, getObjectParam, getStringParam, getIntParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import {  getImageNameByObject} from '../../../services/helper/common';
import { Link } from 'react-router-dom';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { Card, CardActions, CardMedia,CardContent,Typography } from '@mui/material';
import { constants } from '../../../services/constants/constants';
import { TYPE_OTHER_VIEW } from '../../../services/helper/sfTabManager';
import { addTab, getActiveTab, TYPE_DETAIL_VIEW, getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { setHeader } from '../../../services/actions/headerActions';


const NewsContainer =({object , type})=>{
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const defaultCategory = { name: 'All', label: 'All' };
    const [isMounted, setIsMounted] = useState(null);
    const [allCategories, setAllCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [records, setRecords] = useState(null);

    useEffect(() => {
        setHeaderdata();
        getAllNews();
        let info = getActiveTabInfo();
        info.getAllNews = getAllNews();
      }, []);

    const setHeaderdata = () => {
        dispatch(setHeader(object, getLocalizedStrings().label.NEWS.HEADER, null, false, true));
    }

    const getAllNews = () => {
        try {
            let promise = Promise.resolve(HTTPClient.get(endPoints.NEWS.LIST_GET, { is_franchise: true }));
            promise.then(response => {
                let records = getArrayParam(response);
                let categorySet = records.length > 0 ? new Set(records.map(m => { return m.category; })) : new Set();
                let _allCategories = Array.from(categorySet).map(m => { return { name: m, label: m } });
                sortArrayObjectByProperty(_allCategories, 'name');
                setIsMounted(true);
                setRecords(records);
                setAllCategories([..._allCategories]);
                console.log("_allCategories1",_allCategories);
                setSelectedCategory(defaultCategory);
            });
        } catch (error) {
            console.error("Error in 'NewsContainer.js -> getAllNews()':" + error);
        }
    }

    const getLoader = () => {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        let loader = null;
        if (!isMounted) {
            loader = <div style={{ width: '100%', height: contentHeight }}>
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
        </div>
        }
        return loader;
    }

    const getMenu = (menuItems) => {
        let menuElements = [];
        try {

            if (isValidParam(selectedCategory)) {
                let allNews = getArrayParam(records);

                menuElements.push(getLink({ ...defaultCategory, id: 'menu-item-99999' }, selectedCategory.name, allNews.length));
                menuItems.forEach((menu, index) => {
                    let count = allNews.filter(f => { return f.category ===  menu.label }).length;
                    menuElements.push(getLink({ ...menu, id: 'menu-item-' + index }, selectedCategory.name, count));
                });
            }
        } catch (error) {
            console.error("Error in 'NewsContainer.js -> getMenu()':" + error);
        }
        return menuElements;
    }
    const getLink = (props, selectedItem, count) => {
    
        let element = null;
        try {
            let rootStyle = { maxHeight: 30,maxWidth:'100%', display: 'flex', alignItems: 'center', padding: '0px 10px', paddingRight: 20, color: '#585757' };
            let style = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '85%',marginRight:'3px' };
            let linkStyle = { fontSize: '14px', lineHeight: '32px', color: 'inherit' };
    
            let _props = getObjectParam(props);
            selectedItem = getStringParam(selectedItem);
            if (selectedItem !== '' && _props.name == selectedItem) {
                rootStyle.color =  app.me.background ;
                rootStyle.fontWeight = 'bold';
            }
    
            element = <Link  key = {_props.name} to= '#' style = {{ cursor: 'pointer', color: 'inherit' }} onClick={()=>onChangeCategory(_props)}>
                <span style={linkStyle} title={_props.label}>
                    {_props.label}
                </span>
            </Link>;
            element = <div style={style}> {element} </div>;
            element = <div style={rootStyle}> {element} {isValidParam(count) && <div style={{ float: 'right' }}>{" "+"(" + count + ")"}</div>}</div>;
        } catch (error) {
            console.error("Error in 'NewsContainer.js -> getLink()':" + error);
        }
        return element;
    }
    const getAllNewsComponent = (arrNews) => {
        let arrNewsElement = [];
        try {
            arrNews = getArrayParam(arrNews);
            if (isMounted) {
                arrNewsElement = arrNews.map((item, index) => {
                    return <News key={'news-' + index} id={'news-' + index} index={index + 1} {...item} openTab={()=>openTab()} />;
                });
            }
        } catch (error) {
            console.error("Error in 'NewsContainer.js -> getAllNewsComponent()':" + error);
        }
        return arrNewsElement;
    }

    const onChangeCategory = (props) => {
        try {
            setSelectedCategory(props);
        } catch (error) {
            console.error("Error in 'NewsContainer.js -> onChangeCategory()':" + error);
        }
    }

    const getCategoryNews = (_selectedCategory) => {
        let categoryNews = [];
        try {
            let allNews = getArrayParam(records);
            if ( isValidParam(_selectedCategory) && _selectedCategory.name !== 'All') {
                categoryNews = allNews.filter(f => { return f.category ===  _selectedCategory.label });
            } else {
                categoryNews = allNews;
            }
        } catch (error) {
            console.error("Error in 'NewsContainer.js -> getCategoryNews()':" + error);
        }
        return categoryNews;
    }

    const openTab = (label, object, type, url, isRedirect, filter) => {
        let activeTab = getObjectParam(getActiveTab());
        let activeTabIndex = getIntParam(activeTab.index);
        let tab = { label: label, object: object, type: TYPE_DETAIL_VIEW, imgName: null, url: url, isActive: true, parentTabIndex: activeTabIndex };
        addTab(tab, isRedirect);
    }
        let categoryNews =  getCategoryNews(selectedCategory);
        let _allCategories = allCategories;
        let className = _allCategories?.length > 1 ? "col-sm-10" : "col-sm-12";
        let imageName = getImageNameByObject(constants.NEWS_OBJECT);
        return <div style={{ marginLeft: 10, marginRight: 10, height: '76%' }}>

            <div className="container" style={{ width: '100%', height: '100%' }}>
                <div className="row" style={{ height: 'inherit',display:'flex' }}>
                    {_allCategories?.length > 0 && <div className="col-sm-2" style={{ height: 'inherit', paddingRight: 0,width:'15.5%' }}>
                        {getMenu(_allCategories)}
                    </div>}
                    <div className={className} style={{ paddingLeft: _allCategories?.length > 1 ? 0 : 15,width:'84%' }}>
                        {getLoader()}
                        {categoryNews?.length > 0 && <div style={{ display: "grid", gridTemplateColumns: "50% 50%", gridGap: 15 }}>
                            {getAllNewsComponent(categoryNews)}
                        </div>}
                        {getCategoryNews(selectedCategory).length ===  0 &&
                           <div style={{display:'flex',justifyContent:'center'}}>
                           <table className="table table-striped">
                               <tr>
                                   <td>
                                       <div style ={{ textAlign:'center',fontSize:'16px',color: '#868686',marginTop:'154px'}}>
                                           <span style={{ fontSize: '100px', opacity: '0.4' }}><i className={imageName}></i></span>
                                       </div>
                                   </td>
                               </tr>

                               <tr>
                                   <td>

                                       <div className="data_less_text" style={{ textAlign: 'center', marginTop: '12px', marginBottom: 14 }}>


                                           <span> Nothing found for News </span>

                                       </div>
                                   </td>
                               </tr>
                           </table>
                       </div>
                   }
                    </div>
                </div>
            </div>
        </div>
}

const News =(props)=>{
    const dispatch = useDispatch();

    const onChange = (propsData) => {
        let isExternalLink = propsData.is_external_link;
        try {
            if (isExternalLink ===  1) {
                let externalLink = propsData.external_link;
                if ((externalLink.indexOf("http:") !== -1) || (externalLink.indexOf("HTTP:") !== -1) || (externalLink.indexOf("HTTPS:") !== -1) || (externalLink.indexOf("https:") !== -1)) {
                    window.open(externalLink);
                } else {
                    window.open('http://' + externalLink);
                }
            } else {
                // dispatch(propsData.openTab(propsData.title, constants.NEWS_OBJECT, TYPE_OTHER_VIEW, '/news/previewnews/' + propsData.id, true));
                window.open("/#/previewNewsTemplate/" + propsData.id);
            }
        } catch (error) {
            console.error("Error in 'NewsContainer.js -> News.class -> onChange()':" + error);
        }
    }
        let attachment = getStringParam(props.attachment_urls);
        return <Card style={{ fontFamily: 'inherit' }}>
            <CardContent expandable={false} style={{ paddingTop: 10, paddingBottom: 0 }}>
                <p>
                <CardActions >
                    <Link to="#" style={{ cursor: 'pointer', fontWeight: props.index === 1 ? 'bold':'' ,marginLeft:'-7px'}} title={props.title} onClick={() =>onChange(props)}>{props.title}</Link><br />
                    </CardActions>
                    <Typography>
                    <strong style={{ fontWeight: 400, color: '#3333339c', fontSize: 14 }} title={props.published_on}>{props.published_on}</strong>
                    </Typography>
                </p>

                {attachment !== '' && 
                <div  style={{ width: '7%',height:'10%' }}>
                        <CardMedia
                            component="img"
                            height="50"
                            image={attachment}
                            style={{ marginRight: 15, paddingBottom: 15, float: 'left' }}
                        />
                </div>
               
                 }
                <Typography style={{paddingBottom: "15px"}}>
                <p style={{ lineHeight: '20px', marginTop: -4, textAlign: 'justify', overflowY: "scroll", height: "70px", paddingRight: "15px" }}>{props.summary}</p>
                </Typography>
            </CardContent>
        </Card>
}



export default NewsContainer;


