import React, { useState, useEffect }  from 'react';
import { List } from '@mui/material';
import map from 'lodash/map';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import { isValidParam, getStringParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { Link } from 'react-router-dom';
import { getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { constants } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useDispatch, useSelector } from "react-redux";

const GroupByValues = ({groupByCondition, groupByValues, object, callBack}) => {

    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);

    const [isMounted, setIsMounted] = useState(false);
    const [stateGroupByValues, setGroupByValues] = useState([]);
    const [stateGroupByCondition, setGroupByCondition] = useState(null);
    const [valueItems, setValueItems] = useState([]);

    useEffect(() => {
        try {
            let tempValueItems = getGroupByValueItems();
            setValueItems(tempValueItems);
        } catch (error) {
            console.error("Error in 'listViewFilterDropDown.js -> componentDidMount()':" + error);
        }
    }, [stateGroupByCondition]);


    useEffect(() => {
        try {
            if (valueItems != null) {
                setIsMounted(true);
            }
        } catch (error) {
            console.error("Error in 'listViewFilterDropDown.js -> componentDidMount()':" + error);
        }
    }, [valueItems]);



    const fnCallBack = (event, value) => {
        try {
            let label = value.label;
            let recordCount = value.record_count;
            callBack(label, recordCount)
        } catch (error) {
            console.error("error in GroupByValues -> fnCallBack" + error);
        }
    }
    const getGroupByValueItems = () => {
        let groupByValueItems = null;
        let themeColor = app.me.background;
        let tabInfo = getActiveTabInfo();
        let groupByCond = getStringParam(tabInfo.groupByCondition);
        let tempGroupByCondition = groupByCond === '' ? getStringParam(groupByCondition) : groupByCond;
        setGroupByCondition(tempGroupByCondition); 
        if (isValidParam(groupByValues)) {
            let values = groupByValues;
            groupByValueItems = map(values, (value, i) => {
                let label = null;
                let groupByLabel = null;
                if (object === constants.AUTOMATION_DESIGNER_OBJECT ||
                    object === constants.BROADCAST_CAMPAIGNS_OBJECT ||
                    object === constants.AB_CAMPAIGNS_OBJECT) {
                    label = getLocalizedStrings().label.CAMPAIGNS[value.label] + ' (' + value.record_count + ')';
                } else if (object === constants.MARKETING_TEMPLATE_OBJECT ||
                    object === constants.WEB_FORMS) {
                    if (object === constants.MARKETING_TEMPLATE_OBJECT) {
                        groupByLabel = getLocalizedStrings().label.CAMPAIGNS.hasOwnProperty(value.label) ? getLocalizedStrings().label.CAMPAIGNS[value.label] : value.label;
                        label = groupByLabel + ' (' + value.record_count + ')';
                    }
                    if (object === constants.WEB_FORMS) {
                        let objectList = app.objectList;
                        if (isValidParam(objectList) && getArrayParam(objectList).length > 0) {
                            objectList = getArrayParam(objectList).filter(f => {
                                if (f.label === value.label) {
                                    return true;
                                }
                            });
                            if (objectList.length > 0) {
                                label = objectList[0].label + ' (' + value.record_count + ')';
                                groupByLabel = objectList[0].label;
                            } else {
                                groupByLabel = value.label;
                            }
                        }
                    }
                } else if (object === constants.APPOINTMENTS || object === constants.TASKS) {
                    groupByLabel = getLocalizedStrings().label.APPOINTMENTS_TASKS.hasOwnProperty(value.label) ? getLocalizedStrings().label.APPOINTMENTS_TASKS[value.label] : value.label;
                    label = groupByLabel + ' (' + value.record_count + ')';
                } else if (object === constants.CUSTOM_TABLE1_OBJECT ||
                    object === constants.CUSTOM_TABLE2_OBJECT ||
                    object === constants.CUSTOM_TABLE3_OBJECT ||
                    object === constants.CUSTOM_TABLE4_OBJECT |
                    object === constants.SALES_TEMPLATE_OBJECT) {
                    groupByLabel = value.label;
                    if (groupByLabel === 'All') {
                        groupByLabel = getLocalizedStrings().label.COMMON.ALL;
                    }
                    if (groupByLabel === 'Active') {
                        groupByLabel = getLocalizedStrings().label.COMMON.ACTIVE;
                    }
                    if (groupByLabel === 'Inactive') {
                        groupByLabel = getLocalizedStrings().label.COMMON.INACTIVE;
                    }
                    if (groupByLabel === 'Sales Template') {
                        groupByLabel = getLocalizedStrings().label.HEADER.SALES_TEMPLATE;
                    }
                    label = groupByLabel + ' (' + value.record_count + ')';
                } else if (object === constants.CONTACTS_OBJECT ||
                    object === constants.ACCOUNTS_OBJECT) {
                    groupByLabel = value.label;
                    if (groupByLabel === 'All') {
                        groupByLabel = getLocalizedStrings().label.COMMON.ALL;
                    }
                    label = groupByLabel + ' (' + value.record_count + ')';
                } else if (object === constants.OPPORTUNITIES_OBJECT) {
                    groupByLabel = value.label;
                    if (groupByLabel === 'All') {
                        groupByLabel = getLocalizedStrings().label.COMMON.ALL;
                    }
                    label = groupByLabel + ' (' + value.record_count + ')';
                } else if (object === constants.SOLUTIONS_OBJECT ||
                    object === constants.CASES_OBJECT ||
                    object === constants.PROJECTS_OBJECT ||
                    object === constants.ISSUES_OBJECT) {
                    groupByLabel = value.label;
                    if (groupByLabel === 'All') {
                        groupByLabel = getLocalizedStrings().label.COMMON.ALL;
                    }
                    label = groupByLabel + ' (' + value.record_count + ')';
                } else if (object === constants.WORKFLOW_OBJECT || object === constants.ASSIGNMENT_RULES_OBJECT) {
                    let objectList = app.objectList;
                    if (isValidParam(objectList) && getArrayParam(objectList).length > 0) {
                        objectList = getArrayParam(objectList).filter(f => {
                            if (f.name === value.label.toLowerCase()) {
                                return true;
                            }
                        });
                        if (objectList.length > 0) {
                            label = objectList[0].label + ' (' + value.record_count + ')';
                            groupByLabel = objectList[0].label;
                        } else {
                            label = value.label + ' (' + value.record_count + ')';
                            groupByLabel = value.label;
                        }
                    }
                } else {
                    label = value.label + ' (' + value.record_count + ')';
                }
                let groupCondition = stateGroupByCondition;

                let style = value.label === groupCondition ? { color: themeColor, fontWeight: 'bold' } : { color: '#717171' };
                return <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} key={value.label + value.record_count + i}>
                    <Link
                        key={value.label + i}
                        to={'#'}
                        style={{ cursor: 'pointer', ...style }}
                        onClick={(e) => fnCallBack(e, value)}>
                        <div style={{ fontSize: '14px', lineHeight: '32px', }} title={label}>
                            {
                                (object === constants.AUTOMATION_DESIGNER_OBJECT ||
                                    object === constants.BROADCAST_CAMPAIGNS_OBJECT ||
                                    object === constants.AB_CAMPAIGNS_OBJECT ||
                                    object === constants.TOUCH_CAMPAIGNS_OBJECT) ?
                                    <div style={{
                                        maxWidth: '70%',
                                        float: 'left',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {getLocalizedStrings().label.CAMPAIGNS[value.label]}
                                    </div>
                                    :
                                    (object === constants.MARKETING_TEMPLATE_OBJECT ||
                                        object === constants.WEB_FORMS ||
                                        object === constants.APPOINTMENTS || object === constants.TASKS ||
                                        object === constants.CUSTOM_TABLE1_OBJECT ||
                                        object === constants.CUSTOM_TABLE2_OBJECT ||
                                        object === constants.CUSTOM_TABLE3_OBJECT ||
                                        object === constants.CUSTOM_TABLE4_OBJECT ||
                                        object === constants.CONTACTS_OBJECT || object === constants.ACCOUNTS_OBJECT ||
                                        object === constants.SALES_TEMPLATE_OBJECT ||
                                        object === constants.OPPORTUNITIES_OBJECT ||
                                        object === constants.SOLUTIONS_OBJECT ||
                                        object === constants.CASES_OBJECT ||
                                        object === constants.PROJECTS_OBJECT || object === constants.ASSIGNMENT_RULES_OBJECT ||
                                        object === constants.ISSUES_OBJECT || object === constants.WORKFLOW_OBJECT) ?
                                        <div style={{ maxWidth: '70%', float: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{groupByLabel}</div>
                                        :
                                        <div style={{ maxWidth: '70%', float: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{getStringParam(value.label)}</div>
                            }
                            <div style={{ width: '30%', float: 'left' }}>&nbsp;{'(' + getStringParam(value.record_count) + ')'}</div>
                        </div>
                    </Link>
                </div>;
            });
        }
        return groupByValueItems;
    }

        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        return (
            isValidParam(valueItems) && valueItems.length > 0 ?
                <div>
                    <List style={{ paddingTop: '0px', backgroundColor: 'transparent', marginTop: '-5px', marginLeft: '25px' }}>{valueItems}</List>
                </div>
                :
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
        );
};

export default GroupByValues;

