import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Checkbox, FormControl, Icon, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setHeader } from '../../../services/actions/headerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import SfDataTable from './sfDataTable';
import { getAuditCategory } from '../../../services/actions/auditActions';
import * as HTTPClient from '../../../services/helper/httpClient';
import { getMultiColumnTitles } from '../../../services/helper/common';
import { addTab, getActiveTab, TYPE_DETAIL_VIEW } from '../../../services/helper/sfTabManager';
import { getArrayParam, getIntParam, getObjectParam, isValidParam } from '../../../services/helper/parameterVerifier';
import { LinkToType } from '../../../services/constants/link';
import { constants } from '../../../services/constants/constants';
import { sortArrayObjectByPropertyOrderType } from '../../../services/helper/utils';
import ShowCircularProgress from './circularProgress';
import {
  setAuditPerformanceDuration,
  setAuditPerformanceCategoryData,
  setAuditPerformanceCategories,
  setAuditPerformanceQuestionsData,
  setAuditPerformanceQuestions,
  setAuditPerformanceReportData,
  setAuditPerformanceInitialLoad,
  setAuditPerformanceReportParams
} from '../../../services/actions/auditPerformanceReportActions';
import { endPoints } from '../../../services/constants/endPoints';

export const DURATION_ITEMS = [{ label: getLocalizedStrings().label.DASHBOARD.THIS_MONTH, value: 'tm' }, { label: getLocalizedStrings().label.DASHBOARD.THIS_QUARTER, value: 'tq' }, { label: getLocalizedStrings().label.DASHBOARD.THIS_YEAR, value: 'ty' }, { label: getLocalizedStrings().label.DASHBOARD.LAST_MONTH, value: 'lm' }, { label: getLocalizedStrings().label.DASHBOARD.LAST_QUARTER, value: 'lq' }, { label: getLocalizedStrings().label.DASHBOARD.LAST_YEAR, value: 'ly' }];

const AuditPerformanceReport = () => {
  const dispatch = useDispatch();
  const auditPerformance = useSelector((state) => state.auditPerformanceReport);
  const { duration, categoryData, categories, questionsData, questions, reportData, averageScore, initialLoad, reportParams } = auditPerformance;
  const [rowSort, setRowSort] = useState({
    enableAllColumn: true,
    sortFieldName: 'unit',
    isDescending: false,
    onSort: (sortFieldName, isDescending, records) => onSortFn(sortFieldName, isDescending, records)
  });
  const [loading, setLoading] = useState(false);
  const [headerName, setHeaderName] = useState(false);
  const tableRef = useRef(null);

  const getQestionsList = async () => {
    try {
      const allData = categoryData.map((v) => v.t_category_name);
      const response = await HTTPClient.get(endPoints.AUDIT_PERFORMANCE_REPORT.AUDIT_PERFORMANCE_QUESTIONS, JSON.stringify({ categories: categories === 'All' ? allData : [categories] }));
      if(Array.isArray(response)){
      dispatch(setAuditPerformanceQuestionsData(response?.sort((a, b) => a.question.localeCompare(b))));
    }

    } catch (error) {
      console.log(error);
    }
  }

  const auditCategory = async () => {
    setLoading(true);
    try {
      const response=await getAuditCategory();
      if(Array.isArray(response)){
      dispatch(setAuditPerformanceCategoryData(response?.sort((a, b) => a.t_category_name.localeCompare(b.t_category_name))));
      if(response.length > 0){
        setLoading(true);
      } else {
        setLoading(false);
        dispatch(setAuditPerformanceInitialLoad(false));
      }
    }
    } catch (error) {
      
    }
  }

  const handleRun = async () => {
    const periodParam = DURATION_ITEMS.find((item) => item.label === duration)?.value;
    const questionParam = questionsData.find((v) => (v.question === questions))?.id;
    const allQuestions = questionsData.map((v) => v.id).join(',');
    const categoryParam = categoryData.find((v) => (v.t_category_name === categories))?.id;
    const allCategories = categoryData.map((v) => v.id).join(',');
    setLoading(true);
    try {
      const response = await HTTPClient.get(endPoints.AUDIT_PERFORMANCE_REPORT.AUDIT_PERFORMANCE_RECORDS, JSON.stringify({ period: periodParam, questionId: questions === 'All' ? allQuestions : questionParam }));
      if(Array.isArray(response)){
        dispatch(setAuditPerformanceReportData(response.map((v) => {
          return { ...v,unit_avg_score:Number(v.unit_avg_score)};
        })));
        };
      dispatch(setAuditPerformanceReportParams(
        {
          period: periodParam,
          questionId: questions === 'All' ? allQuestions : questionParam,
          categoryId: categories === "All" ? allCategories : categoryParam,
          printLabels: {
            duration,
            questions,
            categories
          }
        }
      ));

      setLoading(false);

    } catch (error) {
      console.log(error);
    }
  }

  const openTab = (label, object, recordId) => {
    let activeTab = getObjectParam(getActiveTab());
    let activeTabIndex = getIntParam(activeTab.index);
    let url = '/' + object + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + recordId;
    let tab = { label: label, object: object, type: TYPE_DETAIL_VIEW, imgName: null, url: url, isActive: true, parentTabIndex: activeTabIndex };

    addTab(tab, true);
  }

  const handlePrint = () => {
    const table = tableRef.current;


    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = '0';

    document.body.appendChild(iframe);

    const doc = iframe.contentWindow.document;

    doc.open();
    doc.write(`
      <html>
        <head>
          <title>Audit Performance Report</title>
          <style>
            @media print {
              body {
                font-size: 10pt;
              }
              th, td {
                border: 1px solid #ddd;
                padding: 5px;
                text-align: left;
              }
                #reportName{
                  text-align: center;
                  font-weight: bold;
                }
                #reportTitle{
                  text-align: center;
                }
            }
                a{
                  text-decoration: none;
                  color: #000 !important;
                  }
          </style>
        </head>
        <body>
          <h4 id="reportTitle">Audit Performance Report</h4>
          <p id="reportName">Duration: ${reportParams?.printLabels?.duration}</p>
         <p id="reportName">Category Name: ${reportParams?.printLabels?.categories}</p>
          <p id="reportName">Question Name: ${reportParams?.printLabels?.questions}</p>
          <p id="reportName">Average Score: ${averageScore.toFixed(2)}</p>
          ${table.outerHTML}
        </body>
      </html>
    `);
    doc.close();


    iframe.contentWindow.focus();
    iframe.contentWindow.print();


    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 1000);
  };

  const downloadExcelReport = (e) => {
    if (e !== null && e !== undefined) {
      let beforeHeaderComponent = excelGenerateBeforeHeaders();
      let headerComponents = excelGenerateHeaders();
      let rowComponents = excelGenerateRows();

      let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '  </tbody> </table>';
      let htmlData = htmlTable;
      if (isValidParam(htmlData) && htmlData !== '') {
        htmlData = htmlData.replace("%22", "\"");
        var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
        var downloadUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = downloadUrl;
        a.download = 'ResultSet_' + new Date().getTime() + '.xls';
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    }
  }

  const excelGenerateBeforeHeaders = () => {
    let cells = '';
    let reportName = "Audit Performance Report";
    let duration = reportParams?.printLabels?.duration;
    let category = reportParams?.printLabels?.categories;
    let question = reportParams?.printLabels?.questions;
    let tempColumns = getArrayParam(columns);


    let columns1 = tempColumns.filter(f => !f.is_hidden);
    let columnsLength = getArrayParam(columns1).length;


    let combinedText = `${reportName}<br>Duration: ${duration}<br>Category Name: ${category}<br>Question Name: ${question}<br>Average Score: ${averageScore.toFixed(2)}`;


    cells = cells + `<tr><td colspan="${columnsLength}">${combinedText}</td></tr>`;

    return cells;
  }

  const excelGenerateHeaders = () => {
    let cells = '';
    let tempColumns = getArrayParam(columns);
    let columns1 = tempColumns.filter(f => {
      let temp;
      if (!f.is_hidden) { temp = f; }
      return temp;
    });
    columns1.forEach(f => {
      cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
    });
    cells = '<tr>' + cells + '</tr>';
    return cells;
  }

  const excelGenerateRows = () => {
    let rows = getArrayParam([...reportData]);
    let columns1 = getArrayParam(columns);
    let rowData = '';
    if (rows.length !== 0) {
      let cells = '';
      rows.forEach((rowObject, rowIndex) => {
        let innerCells = '';
        columns1.forEach((columnObject, colIndex) => {
          if (!columnObject.is_hidden) {
            let value = rowObject.hasOwnProperty(columnObject.name) ? rowObject[columnObject.name] : "";
            innerCells = innerCells + '<td >' + value + ' </td>';
          }
        })
        if (rowIndex % 2 === 0) {
          cells = cells + ('<tr>' + innerCells + ' </tr>');
        } else {
          cells = cells + ('<tr  bgcolor="Lavender">' + innerCells + ' </tr>');
        }

      });
      rowData = cells;
    } else {
      rowData = ('<tr> <td colspan="' + (columns1.length) + '"> No data Found  </td>  </tr>');
    }
    return rowData;

  }

  const customEvent = (eventType, column, row, event) => {
    if (eventType === 'is_detail_link' && column.name === 'unit') {
      openTab(getMultiColumnTitles(column.name, row), constants.ACCOUNTS_OBJECT, row.record_id);
    }
    if (eventType === 'is_detail_link' && column.name === 'unit_avg_score' && Number(row.unit_avg_score) !== 0) {
      addTab({
        imgName: null,
        isActive: true,
        label: getLocalizedStrings().label.AUDIT_PERFORMANCE_REPORT.AUDIT_PERFORMANCE_REPORT_CHART_TITLE,
        object:"audit-performance-report",
        parentTabIndex: 0,
        type: "TYPE_DETAIL_VIEW",
        url: `/audit-performance-report/detailview/${row?.unit_id}`
      }, true);
    }

  }

  const onSortFn = (fieldName, isDescending, recordsData) => {
    try {
      let tempRecords = recordsData;
      let orderType = isDescending ? 'desc' : 'asc';
      sortArrayObjectByPropertyOrderType(tempRecords, fieldName, orderType);

    }
    catch (error) {
      console.error("Error in 'operationsReports.js -> onSort()':" + error);
    }
  }

  const handleTrack = (text) => {
    setHeaderName(text);
  }


  useEffect(() => {
    dispatch(setHeader(constants.AUDIT_PERFORMANCE_REPORT, getLocalizedStrings().label.AUDIT_PERFORMANCE_REPORT.AUDIT_PERFORMANCE_REPORT_TITLE, null, false, true));
    if(initialLoad){
      auditCategory();
    }
  }, [])

  useEffect(() => {
    if (Boolean(categories)) {
      getQestionsList();
    }

  }, [categories, categoryData])


  useEffect(() => {
    if (initialLoad && questionsData.length > 0) {
      handleRun();
      dispatch(setAuditPerformanceInitialLoad(false));
    }
  }, [questionsData])

  useEffect(() => {
    const table = document.getElementById('sfCustomTable');
    const headers = table?.querySelectorAll('th');
    let averageScoreIndex = -1;

    headers?.forEach((item) => {
      if (item.innerText.includes('Unit Average Score')) {
        averageScoreIndex = item.cellIndex;
      }
    });

    const rows = table?.querySelectorAll('tr');
    const coloredLinks = [];

    if (averageScoreIndex !== -1) {
      rows?.forEach((row) => {
        const cells = row.querySelectorAll('td');
        if (cells.length > averageScoreIndex) {
          const cellValue = parseFloat(cells[averageScoreIndex].innerText);
          if (!isNaN(cellValue)) {
            const color = Number(cellValue) >= Number(averageScore) && Number(averageScore) !== 0  ? 'green' : 'red';
            const link = cells[averageScoreIndex].querySelector('div > div > a');
            if (link) {
              link.style.color = color;
              coloredLinks.push(link);
            }
          }
        }
      });
    }
    return () => {
      coloredLinks.forEach(link => {
        link.style.removeProperty('color');
      });
    };

  }, [reportData, averageScore, headerName]);

  useEffect(() => {
    const table = document.getElementById('sfCustomTable');
    const headers = table?.querySelectorAll('th');
    let timer;

    headers?.forEach((item) => {
      item.addEventListener('click', (e) => { timer = setTimeout(() => handleTrack(item.innerText), 0) });
    })

    return () => {
      headers?.forEach((item) => {
        item?.removeEventListener('click', (e) => handleTrack(item.innerText));
      });

      clearTimeout(timer);
    }
  }, [averageScore])

  return (
    <Box className="audit-performance-report" sx={{ width: '98%', margin: '0px auto' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>

          {/* Duration Selector */}
          <FormControl sx={{ m: 1, width: 200 }} size='small'>
            <InputLabel id="duration-label">Duration</InputLabel>
            <Select
              labelId="duration-label"
              id="select-duration"
              value={duration}
              label="Duration"
              onChange={(e) => { dispatch(setAuditPerformanceDuration(e.target.value)) }}
            >
              {DURATION_ITEMS?.map(({ label }) => (
                <MenuItem key={label} value={label}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Categories Selector */}
          <FormControl sx={{ m: 1, width: 200 }} size='small'>
            <InputLabel id="category-selector-label">Category</InputLabel>
            <Select
              labelId="category-selector-label"
              id="category-selector"
              name='category-selector'
              value={categories}
              onChange={(e) => {
                dispatch(setAuditPerformanceCategories(e.target.value));
                dispatch(setAuditPerformanceQuestions('All'));

              }}
              input={<OutlinedInput label='Category' />}

            >
              {categoryData.length > 0 ? <MenuItem value={'All'}>
                All
              </MenuItem> : <MenuItem disabled>No data available</MenuItem>}

              {categoryData?.map(({ t_category_name }) => (

                <MenuItem key={t_category_name} value={t_category_name}>
                  {t_category_name}
                </MenuItem>

              ))}
            </Select>
          </FormControl>

          {/* Questions Selector */}

          <FormControl sx={{ m: 1, width: 200 }} size='small'>
            <InputLabel id="question-selector-label">Questions</InputLabel>
            <Select
              labelId="question-selector-label"
              id="question-selector"
              name='question-selector'
              value={questions}
              onChange={(e) => { dispatch(setAuditPerformanceQuestions(e.target.value)) }}
              input={<OutlinedInput label='Questions' />}

            >
              {questionsData.length > 0 ? <MenuItem value={'All'}>
                All
              </MenuItem> : <MenuItem disabled>No data available</MenuItem>}

              {questionsData?.sort((a, b) => a.question.localeCompare(b.question)).map(({ question, id }) => (
                <MenuItem key={id} value={question}>
                  {question}
                </MenuItem>
              ))}
            </Select>

          </FormControl>


          <div className="col-md-1" style={{ paddingLeft: '0px', display: 'flex', width: '15%', height: '47px', alignItems: 'center' }}>
            <Button
              onClick={() => handleRun()}
              style={{
                ...styles.primaryButton,
                minWidth: '56px',
                width: '19%'
              }}
              title={'Run'}
            > Run</Button>
          </div>
        </Box>

        {reportData.some(Boolean) && <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ color: styles.primaryButton.backgroundColor, fontWeight: 'bold' }} title={'Average Score'}>Average Score : {averageScore.toFixed(2)}</Typography>
          <Button
            style={{
              minWidth: 'unset',
            }}
          >
            <Icon
              onClick={() => handlePrint()}
              title={getLocalizedStrings().label.REPORT.PRINT}
              style={{ cursor: 'pointer', color: '#717171' }}
            >
              print
            </Icon>
          </Button>

          <Button
            startIcon={<i className="fas fa-file-excel" style={{ fontSize: '20px', color: 'green' }} ></i>}
            onClick={(e) => downloadExcelReport(e)}
            style={{
              ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
              color: '#717171',
              border: '0px',
              minWidth: 'unset'

            }}
            title="Excel"
          />
        </Box>}
      </Box>
      {reportData.some(Boolean) ? <Box className='audit-performance-report-table' ><div ref={tableRef}>

        <SfDataTable columns={columns} rows={reportData} rowSort={rowSort} customEvent={(eventType, column, row, event) => customEvent(eventType, column, row, event)} />
      </div>
      </Box> : (loading  ? <div style={{ height: '50vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
      </div></div> : (!initialLoad ?<p style={{ color: 'red', fontWeight: '500', marginLeft: '1rem' }}> No data found</p> : null))}
    </Box>
  )
}

export default AuditPerformanceReport;


const columns = [
  { name: 'unit', label: 'Unit', width: 90, is_hidden: false, is_email_field: false, is_detail_link: true, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: {} },
  { name: 'no_of_audits', label: 'No. of Audits', width: 60, is_hidden: false, is_email_field: false, is_detail_link: false, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: {} },
  { name: 'total_score', label: 'Total Score', width: 60, is_hidden: false, is_email_field: false, is_detail_link: false, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: {} },
  { name: 'unit_avg_score', label: 'Unit Average Score', width: 60, is_hidden: false, is_email_field: false, is_detail_link: true, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: {} },
];

