import React, { useState } from "react";
import { Link } from "react-router-dom";
import map from "lodash/map";
import { isValidParam } from "../../../services/helper/parameterVerifier";
import { addTab, TYPE_REPORT } from "../../../services/helper/sfTabManager";
import { constants } from "../../../services/constants/constants";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";

const ColumnForecasts = ({ object }) => {
	const [durations, setDurations] = useState([
		{
			label: getLocalizedStrings().label.DASHBOARD.LAST_MONTH, //'Last Month',
			value: 3,
		},
		{
			label: getLocalizedStrings().label.DASHBOARD.LAST_QUARTER, //'Last Quarter',
			value: 12,
		},
		{
			label: getLocalizedStrings().label.DASHBOARD.LAST_YEAR, //'Last Year',
			value: 13,
		},
		{
			label: getLocalizedStrings().label.DASHBOARD.NEXT_MONTH, //'Next Month',
			value: 5,
		},
		{
			label: getLocalizedStrings().label.DASHBOARD.NEXT_QUARTER, //'Next Quarter',
			value: 10,
		},
		{
			label: getLocalizedStrings().label.DASHBOARD.NEXT_THREE_MONTHS, //'Next three Months',
			value: 7,
		},
		{
			label: getLocalizedStrings().label.DASHBOARD.NEXT_TWO_MONTHS, //'Next two Months',
			value: 6,
		},
		{
			label: getLocalizedStrings().label.DASHBOARD.THIS_MONTH, //'This Month',
			value: 4,
		},
		{
			label: getLocalizedStrings().label.DASHBOARD.THIS_QUARTER, //'This Quarter',
			value: 9,
		},
		{
			label: getLocalizedStrings().label.DASHBOARD.THIS_YEAR, //'This Year',
			value: 11,
		},
	]);

	const generateDurationsLink = () => {
		let durationList = null;

		if (isValidParam(durations)) {
			durationList = map(durations, (duration, i) => {
				let linkToUrl = "/" + object + "/kanban" + "/";
				return (
					<div
						style={{
							overflow: "hidden",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
						}}
						key={"duration_" + duration.label}
					>
						<Link
							to={linkToUrl}
							style={{ cursor: "pointer" }}
							onClick={() =>
								openTab(constants.FORECAST, object, linkToUrl, duration.value)
							}
						>
							<span
								style={{
									color: "#ccc",
									fontSize: "14px",
									paddingLeft: "1px",
									paddingRight: "7px",
								}}
							></span>
							<span
								title={duration.label}
								style={{
									fontSize: "14px",
									lineHeight: "32px",
									color: "#717171",
								}}
							>
								{duration.label}
							</span>
						</Link>
					</div>
				);
			});
			return durationList;
		}
	};

	const openTab = (label, object, url, selectedDuration) => {
		let info = { duration: selectedDuration };
		let tab = {
			item: object,
			label: label,
			object: object,
			type: TYPE_REPORT,
			url: url,
			info: info,
		};

		addTab(tab);
	};

	return <div style={{ marginLeft: "10px" }}>{generateDurationsLink()}</div>;
};

export default ColumnForecasts;
