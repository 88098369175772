
import React, {useEffect, useRef, useState} from 'react';
import {MenuItem} from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styles } from '../../../services/constants/styles';
import { isValidParam, isInvalidParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { constants } from '../../../services/constants/constants';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { addNode } from '../../../services/actions/automationCampaignActions';
import { removeError } from '../../../services/helper/automationUtils';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { hasAccessPermission, addGroupIntoReduxStore } from '../../../services/helper/common';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getMyGroups, setGroups } from '../../../services/actions/filterMenuAction';
import SFAutoComplete from '../components/sfAutoComplete';
import {useDispatch, useSelector} from 'react-redux';
import { saveGroup } from '../../../services/actions/batchActions';

const AutomationChangeGroup = ({data,object,closeDrawer}) => {
    const dispatch = useDispatch();
    const filters = useSelector((state) => state.filters);
    const automationCampaign = useSelector((state) => state.automationCampaign);
	const fieldRefStatus = useRef('field-ref-status');

    const [value,setValue] = useState('');
    const [actionType,setActionType] = useState('');
    const [groupList,setGroupList] = useState([]);
    const [tempGroupList,setTempGroupList] = useState([]);
    const [groupName,setGroupName] = useState('');


    useEffect(() => {
        let nodeObject = data;
        let groupName = '';
        let value = '';
        if (isValidParam(nodeObject)) {
            let detailsArray = null;
            if (nodeObject.hasOwnProperty('details')) {
                detailsArray = nodeObject.details[0];
                if (isValidParam(detailsArray)) {
                    let action_type = actionType;
                    let objectIndentifier = value;
                    let object_indentifierText = groupName;
                    if (detailsArray.hasOwnProperty('action_type')) {
                        action_type = detailsArray.action_type;
                    }
                    if (detailsArray.hasOwnProperty('object_indentifier')) {
                        objectIndentifier = detailsArray.object_indentifier;
                    }
                    if (detailsArray.hasOwnProperty('object_indentifierText')) {
                        object_indentifierText = detailsArray.object_indentifierText;
                    }
                    groupName = object_indentifierText;
                    value = objectIndentifier;
                    setValue(objectIndentifier);
                    setActionType(action_type);
                    setGroupName(object_indentifierText);
                  
                }
            }
        }

        let groups = filters.groups;
        if (!isValidParam(groups) || groups.length === 0) {
            let promise = Promise.resolve(getMyGroups(constants.CONTACTS_OBJECT));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        let _value = value;
                        let _groupName = groupName;
                        let arrayGroups = response.groups;
                        arrayGroups = arrayGroups.filter(f => f.status === 'Active');
                        sortArrayObjectByProperty(arrayGroups, 'name');
                        let filteredArrGrps = arrayGroups.filter(function (el) {
                            return el.id === _value;
                        });
                        if (filteredArrGrps.length === 0) {
                            _value = '';
                            _groupName = '';
                        }
                        if (arrayGroups.length > 0) {
                            // setGroupList(arrayGroups);
                            setTempGroupList(arrayGroups)
                            generateGroupsList(arrayGroups)
                            setValue(_value);
                            setGroupName(_groupName);
                        }
                        dispatch(setGroups(response));
                    }
                });
            }
        } else {
            let _value = value;
            let _groupName = groupName;
            let arrayGroups = [...groups];
            arrayGroups = arrayGroups.filter(f => f.status === 'Active');
            sortArrayObjectByProperty(arrayGroups, 'name');
            let filteredArrGrps = arrayGroups.filter(function (el) {
                return el.id === value;
            });
            if (filteredArrGrps.length === 0) {
                _value = '';
                _groupName = '';
            }
            if (arrayGroups.length > 0) {
                // setGroupList(arrayGroups);
                setTempGroupList(arrayGroups)
                generateGroupsList(arrayGroups)
                setValue(_value);
                setGroupName(_groupName);
            }
        }
    }, []);
   
    const selectGroup = (fieldName, event, value) => {
        if(event !== undefined ){
        let arrGroup = tempGroupList;
            if(arrGroup.length >0){
                let groupItem = arrGroup.filter(f => { return f.name === value });
                let item= groupItem[0];
                let groupId = item.id;
                setValue(groupId);
                setGroupName(value);
            }
        }
    }

    const generateGroupsList = (arrayGroups) => {
        let groupsList = null;
        let arrGroup = arrayGroups;
        if (isValidParam(arrGroup) && arrGroup.length > 0) {
            groupsList = arrGroup.map((objGroup, i) => {
                return (
                    <MenuItem key={objGroup.name} value={objGroup.name} id={objGroup.name} style={styles.popoverMenuItem} >{objGroup.name}</MenuItem>
                   
                );
            });
        }
        setGroupList(groupsList)
    }

    const addGroup = () => {
        let hasPermission = getBooleanParam(hasAccessPermission(constants.GROUP_OBJECT, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_CREATE));
        if (hasPermission) {
            document.getElementById('addGroupElem').style.display = 'block';
            document.getElementById('iconAddGroup').style.display = 'none';
            document.getElementById('btnSave').style.display = 'none';
        } else {
            let dialogData = {
                message: getLocalizedStrings().message.COMMON.ACCESS_DENIED,
            }
            openDialog(dialogData);
        }
    }

    const addGroupShowHide = () => {
        document.getElementById('addGroupElem').style.display = 'none';
        document.getElementById('iconAddGroup').style.display = 'block';
        document.getElementById('btnSave').style.display = 'block';
    }

    const _saveGroup = () => {
        let groupName = document.getElementById('groupName').value;
        document.getElementById('groupName').value = "";
        groupName = groupName.trim();
        let dialogData = null;
        if (groupName === null || groupName === undefined || groupName === "") {
            dialogData = {
                message: getLocalizedStrings().message.AUTOMATION_DESIGNER.GROUP_NAME_BLANK,
                fieldId: 'groupName'
            }
            openDialog(dialogData);
        } else {
            // document.getElementById('addGroupElem').style.display = 'none';
            document.getElementById('iconAddGroup').style.display = 'block';
            // document.getElementById('btnSave').style.display = 'block';
            let params = {
                "id": 0,
                "name": groupName,
                "description": groupName,
                "t_status": "Active"
            }
            let response = saveGroup(object, params);
            response.then((response) => {
                if (response.data.id === -1) {
                    let errMsg = getLocalizedStrings().message.BATCH_ACTION_VALIDATION.GROUP_ALREADY_EXIST;
                    dialogData = {
                        message: errMsg,
                        fieldId: 'groupName'
                    }
                    openDialog(dialogData);
                } else {
                    document.getElementById('addGroupElem').style.display = 'none';
                    document.getElementById('iconAddGroup').style.display = 'block';
                    document.getElementById('btnSave').style.display = 'block';
                    let grId = response.data.id;
                    let grName = groupName;
                    let objGroup = {
                        id: grId,
                        name: grName,
                        status: params.t_status
                    }
                    let arrGroups = [...groupList];
                    arrGroups.push(objGroup);
                    sortArrayObjectByProperty(arrGroups, 'name');
                    addGroupIntoReduxStore(response.data.id, groupName, params.t_status);
                    
                    setGroupList(arrGroups);
                    setValue(grId);
                    setGroupName(grName);
                }
            });
        }
    }

    const openDialog = (dialogData) => {
        dispatch(getAppDialog(true, constants.ALERT_DIALOG, getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, null, dialogData, constants.AUTOMATION_DESIGNER_OBJECT));
    }

    const saveGroupDetail = () => {
        let nodeObject = data;
        let action_type = actionType;
        let object_indentifier = value;
        let object_indentifierText = groupName;
        let dialogData = null;

        if (isInvalidParam(action_type)) {
            dialogData = {
                message: getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_GROUP_OPERATION,
            }
            openDialog(dialogData);
        } else if (isInvalidParam(object_indentifier) || isInvalidParam(object_indentifierText)) {
            dialogData = {
                message: getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_GROUP,
            }
            openDialog(dialogData);
        } else {
            if (isValidParam(nodeObject)) {
                if (nodeObject.hasOwnProperty('details')) {
                    let detailsArray = nodeObject.details[0];
                    if (isValidParam(detailsArray)) {
                        detailsArray.action_type = action_type;
                        detailsArray.object_indentifier = object_indentifier;
                        detailsArray.object_indentifierText = object_indentifierText;
                    } else {
                        detailsArray = {
                            action_type: action_type,
                            object_indentifier: object_indentifier,
                            object_indentifierText: object_indentifierText,
                        }
                        nodeObject.details.push(detailsArray);
                    }
                    nodeObject = removeError(nodeObject);
                    dispatch(addNode(automationCampaign.data));
                    closeDrawer();
                }
            }
        }
    }

    const selectActionType = (event, value) => {
        if (isValidParam(value)) {
            setActionType(value);            
        }
    }
    
    let campaignStatus = automationCampaign.data.status;
    return (
        <div style={{ paddingTop: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
            <div>
                <FormControl component="fieldset">
                    <RadioGroup
                        name="layout"
                        style={{ width: 'auto' }}
                        value={actionType}
                        onChange={(event)=>selectActionType(event,event.target.value)}
                    >
                        <FormControlLabel  value="add_to_group"  control={<Radio    color="default" />}   style={{marginBottom:'-8px'}} label={getLocalizedStrings().label.AUTOMATION_DESIGNER.ADD_TO_GROUP} />
                        <FormControlLabel   value="move_to_group"  control={<Radio    color="default" />}   style={{marginBottom:'-8px'}}    label={getLocalizedStrings().label.AUTOMATION_DESIGNER.MOVE_TO_GROUP} />
                        <FormControlLabel  value="remove_from_group" control={<Radio    color="default" />}   label={getLocalizedStrings().label.AUTOMATION_DESIGNER.REMOVE_FROM_GROUP} />
                    </RadioGroup>
                </FormControl>
            </div>

            <div>
                <div style={{ display: 'inline-block',width: ' 420px', paddingTop: '5px' }}>
                    <SFAutoComplete
                        key={'sf-auto-complete-group'}
                        id='groups'
                        name='groups'
                        label={getLocalizedStrings().label.AUTOMATION_DESIGNER.GROUP_REQUIRED}
                        menuItems={groupList}
                        disabled={false}
                        onChange={(event, value) => selectGroup('groups', event, value)}
                        value={groupName}
                        underlineFocusStyle={styles.textField.underlineFocus}                           
                        inputStyle={{...styles.textField.input, paddingLeft: '6px'}}
                        menuItemStyle={styles.popoverMenuItem}
                        style={{...styles.sfFormFieldContainer, paddingLeft: '0px'}}
                        ref={fieldRefStatus}
                        isFullHeight={false}                                                    
                    />
                </div>

                {
                    (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                    <div style={{  }} id="iconAddGroup">
                        <span>
                            {getLocalizedStrings().message.AUTOMATION_DESIGNER.ADD_GROUP_CLICK}
                        </span>
                        &nbsp;
                        <a style={{ cursor: 'pointer' }} onClick={() => addGroup()}>
                            {getLocalizedStrings().message.AUTOMATION_DESIGNER.ADD_GROUP_CLICK_HERE}
                        </a>
                        &nbsp;
                        <span>
                            {getLocalizedStrings().message.AUTOMATION_DESIGNER.CLICK_TO_ADD_NEW_GROUP}
                        </span>
                        {/*<i
                            className="fa fa-plus"
                            aria-hidden="true"
                            style={{ cursor: 'pointer' }}
                            title={getLocalizedStrings().label.COMMON.ADD}
                            onClick={() => this.addGroup()}
                        ></i>*/}
                    </div>
                }
            </div>

            <div id="addGroupElem" style={{ display: 'none' }}>
                <div style={{ display: 'inline-block' }}>
                <FormControl style={{width:'100%'}} className="test" noValidate autoComplete="off">
                    <TextField
                     variant="outlined"
                        type='textarea'
                        id='groupName'
                        label={getLocalizedStrings().label.AUTOMATION_DESIGNER.GROUP_NAME}
                        autoComplete="new-password"
                        margin='dense'
                        size='small'
                        className={"sf-fields-bg"}
                    />
                    </FormControl>
                    
                </div>
                <div style={{ display: 'inline-block', verticalAlign: 'top', paddingTop: '13px',marginLeft:'14px' }}>
                    <Button onClick={()=> _saveGroup()}  style={styles.primaryButton}>{getLocalizedStrings().label.COMMON.ADD}</Button>
                </div>
                <div style={{ display: 'inline-block', verticalAlign: 'top', paddingTop: '13px' }}>
                    <Button onClick={() => addGroupShowHide()} style={styles.secondaryButton}> {getLocalizedStrings().label.COMMON.CANCEL}</Button>
                </div>
            </div>

            {
                (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                <div style={{ paddingTop: '10px' }} id="btnSave">
                    <Button
                        style={styles.listViewPrimaryButton}
                        onClick={()=>saveGroupDetail()}
                   >
                   {getLocalizedStrings().label.COMMON.SAVE}</Button>
                </div>
            }
        </div>
    );
}

export default AutomationChangeGroup;
