import React from 'react';
import { connect } from 'react-redux';
import { isValidParam, getStringParam, getObjectParam, getIntParam, getBooleanParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import PopOver from '../components/PopOver';
import { Select, MenuItem,InputLabel,FormControl, Button} from '@mui/material';
import SFDataTable from '../components/sfDataTable';
import ShowCircularProgress from '../components/circularProgress';
import { constants } from '../../../services/constants/constants';
import { dateFormat, dateFormatFlatPicker } from '../../../services/helper/utils';
import { getMultiColumnTitles } from '../../../services/helper/common';
import { LinkToType } from '../../../services/constants/link';
import moment from 'moment';
import { setHeader } from '../../../services/actions/headerActions';
import { addTab, getActiveTab, TYPE_DETAIL_VIEW, getActiveTabInfo, updateTabInfo } from '../../../services/helper/sfTabManager';
import Grid from '@mui/material/Grid'; 
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const optionvalue = {
    td: 'Today',
    yd: 'Yesterday',
    tw: 'This Week',
    tm: 'This Month',
    tq: 'This Quarter',
    ty: 'This Year',
    lw: 'Last Week',
    lm: 'Last Month',
    lq: 'Last Quarter',
    ly: 'Last Year',
    cust: 'Custom'
};
function formatDate(date) {
    if (isValidParam(date)) {
        let d = date instanceof Date ? date : new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }
    return null;
}

function parseDate(date) {
    if (isValidParam(date) && date.split('-').length === 3) {
        let parseDate = Date.parse(date);
        date = new Date(parseDate);
    }
    return date;
}
const CouponClaimReport =({object,type})=>{
    const dispatch = useDispatch();
    const [dueBy, setDueBy] = useState("tm");
    const [coupons_names, setCoupons_names] = useState('All Coupons');
    const [coupons_id, setCoupons_id] = useState(-97);
    const [from_date, setFrom_date] = useState(null);
    const [to_date, setTo_date] = useState(null);
    const [couponsList, setCouponsList] = useState(null);
    const [reportParams, setReportParams] = useState({
                    due_by: 'tm',
                    from_date: null,
                    to_date: null,
                    coupons_names: 'All Coupons',
                    coupons_id: -97
                },);
    const [isMounted, setIsMounted] = useState(false);
    const [due_by_popup, setDue_by_popup] = useState(false);
    const [coupons_names_popup, setCoupons_names_popup] = useState(false);
    const [fields, setFields] = useState([]);
    const [records, setRecords] = useState([]);
    const [rowSort, setRowSort] = useState({
                    onSort: (fieldName, isDescending) => onSort(fieldName, isDescending)
                },);
    const [customTimeLineDisplay, setCustomTimeLineDisplay] = useState('none');
    const [tempFieldName, setTempFieldName] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [name, setName] = useState('');



    const appState = useSelector((state) => state.app);
    const report = useSelector((state) => state.report);
    const couponsReport = useSelector((state) => state.couponsReport);
    // const couponsList = useSelector((state) => state.couponsList);


    useEffect(() => {
        report.showProgressBar = false;
        getCouponsHeadersFileds();
        reloadReport();

        let label = getLocalizedStrings().label.HEADER.COUPONS_CLAIM_REPORTS;
        setHeader(constants.COUPONS_CLAIM_REPORTS, label, null, false, true);
    }, [report]);

    const handlePopover = (fieldName, event) => {
        try {
            setTempFieldName(true);
            setAnchorEl(event.currentTarget);
        }
        catch (error) {
            console.error("Error in 'couponClaimReport.js -> handlePopover()':" + error);
        }
    }

    const handleRequestClose = (fieldName) => {
        try {
            setTempFieldName(false);
        }
        catch (error) {
            console.error("Error in 'couponClaimReport.js -> handleRequestClose()':" + error);
        }
    };

    const handleDropDownChange = (name, event,value) => {
        let tempRowSort;
        try {
            if (name === "due_by") {
                setDueBy(value);
                if (value === "cust") {
                    setCustomTimeLineDisplay('block');
                    let tempDate = moment(String(new Date())).format(dateFormat[appState.me.date_format]);
                    setFrom_date(tempDate);
                    setTo_date(tempDate);
                    return;
                } else {
                    setCustomTimeLineDisplay('none');
                    setFrom_date(null);
                    setTo_date(null);
                    tempRowSort = rowSort;
                    tempRowSort.isDescending = false;
                    setRowSort(tempRowSort);
                }
            }
            else if (name === "coupons_names") {

                if (value === -97) {
                    setCoupons_id(value);
                    setCoupons_names("All Coupons ");
                } else {
                    let tempCouponList = couponsList.filter((f) => {
                        return f.id === value;
                    });
                    setCoupons_id(tempCouponList[0].id);
                    setCoupons_names(tempCouponList[0].name);
                }
                tempRowSort.isDescending = false;
                setRowSort(tempRowSort);
            }
        }
        catch (error) {
            console.error("Error in 'couponClaimReport.js -> handleDropDownChange()':" + error);
        }
    }

    const getMultilingualOptionsMap = () => {
        let optionsMultilingual = {
            'Today': getLocalizedStrings().label.DASHBOARD.TODAY,
            'Yesterday': getLocalizedStrings().label.DASHBOARD.YESTERDAY,
            'This Week': getLocalizedStrings().label.DASHBOARD.THIS_WEEK,
            'This Month': getLocalizedStrings().label.DASHBOARD.THIS_MONTH,
            'This Quarter': getLocalizedStrings().label.DASHBOARD.THIS_QUARTER,
            'This Year': getLocalizedStrings().label.DASHBOARD.THIS_YEAR,
            'Last Week': getLocalizedStrings().label.DASHBOARD.LAST_WEEK,
            'Last Month': getLocalizedStrings().label.DASHBOARD.LAST_MONTH,
            'Last Quarter': getLocalizedStrings().label.DASHBOARD.LAST_QUARTER,
            'Last Year': getLocalizedStrings().label.DASHBOARD.LAST_YEAR,
            'Custom': getLocalizedStrings().label.DASHBOARD.CUSTOM
        };
        return optionsMultilingual;
    }

    const generateDateSelectionField=()=> {
        let optionsMultilingual = getMultilingualOptionsMap();
        try {
            return Object.keys(optionvalue).map((itemKey) => {
                return <MenuItem key={itemKey} value={itemKey} style={styles.popoverMenuItem}>{optionsMultilingual[optionvalue[itemKey]]} </MenuItem>;
            });
        }
        catch (error) {
            console.error("Error in 'couponClaimReport.js -> generateDateSelectionField()':" + error);
        }
    }

    const getCouponsHeadersFileds = () => {
        let url = endPoints.COUPONS_REPORT.HEADERS_GET;
        let couponsList = {};
        let fields = null;
        try {

            let promise = Promise.resolve(HTTPClient.get(url, null));
            promise.then((response) => {
                if (isValidParam(response)) {
                    couponsList = getArrayParam(response.coupons_list);
                    fields = getArrayParam(response.fields);
                    fields = fields.map(field => {
                        if (field.is_center) {
                            field.style = { textAlign: 'center' };
                        }
                        return field;
                    });
                    setFields(fields);
                    setCouponsList(couponsList);
                }
            });

        } catch (error) {
            console.log("Error in 'couponClaimReport.js -> getCouponsHeadersFileds()':" + error)
        }
    }


    const reloadReport = (params) => {

        params = getObjectParam(params);
        let isNewFilter = getBooleanParam(params.isNewFilter);
        params = { ...getReportParams(isNewFilter), ...params };

        let tempRowSort = getObjectParam(rowSort);
        let records = [];
        let url = endPoints.COUPONS_REPORT.COUPONS_REPORT_GET;

        if (!isValidParam(params.sort_field_name)) {
            params.sort_field_name = 'claim_date';
        }
        if (!isValidParam(params.sort_type)) {
            params.sort_type = 'asc';
        }


        try {
            setIsMounted(false);
            tempRowSort.isDescending = false;
            let promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    records = getArrayParam(response.coupons_report);
                    tempRowSort['sortFieldName'] = params.sort_field_name;
                    setIsMounted(true);
                    setRecords(records);
                    setRowSort(tempRowSort);
                }
            });
            setRowSort(tempRowSort);

        } catch (error) {
            console.log("Error in 'couponClaimReport.js -> reloadReport()':" + error)
        }
    }

    const getReportParams = (isNewFilter) => {
        let params = null;
        isNewFilter = getBooleanParam(isNewFilter);
        let info = getActiveTabInfo();

        if (!isNewFilter && isValidParam(info) && Object.keys(info).length > 0) {

            params = info;
            let dueBy = getStringParam(params.due_by);
            setDueBy(dueBy);
            let fromDate = getStringParam(params.from_date);
            let toDate = getStringParam(params.to_date);
            let tempCoupons_id = getIntParam(params.coupons_id);
            let tempCoupons_names = getStringParam(params.coupons_names);
            let tempFrom_date = parseDate(fromDate);
            let tempTo_date = parseDate(toDate);
            setCoupons_id(tempCoupons_id);
            setCoupons_names(tempCoupons_names);
            setFrom_date(tempFrom_date);
            setTo_date(tempTo_date);

            if (dueBy === 'cust') {
                setCustomTimeLineDisplay('block');
                let dateTimeDiv1 = document.getElementsByClassName("dateTimeDiv-from-date");
                if (isValidParam(dateTimeDiv1) && dateTimeDiv1.length > 0) {
                    dateTimeDiv1[0].classList.add('label-top');
                }
                let dateTimeDiv2 = document.getElementsByClassName("dateTimeDiv-to-date");
                if (isValidParam(dateTimeDiv2) && dateTimeDiv2.length > 0) {
                    dateTimeDiv2[0].classList.add('label-top');
                }
            }

        } else {
            let tempDueBy = getStringParam(dueBy);
            let fromDate =from_date;
            let toDate = to_date;
            let tempCoupons_id = coupons_id;
            let tempCoupons_names = coupons_names;
            params = {
                due_by: tempDueBy,
                from_date: formatDate(fromDate),
                to_date: formatDate(toDate),
                coupons_names: tempCoupons_names,
                coupons_id: tempCoupons_id
            };
            updateTabInfo(params);
        }
        return params;
    }

    const handleDateChange = (date) => {
        setName(date);
        
    }

    const handleDateFocus = (fieldName, event) => {
        let dateTimeDiv = document.getElementsByClassName("dateTimeDiv-" + fieldName);
        if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
            dateTimeDiv[0].classList.add('label-top');
        }
    }

    const handleDateBlur = (fieldName, event) => {
        let field_name = fieldName.replace('-', '_');
        let value = getStringParam(field_name);
        if (value === '') {
            let dateTimeDiv = document.getElementsByClassName("dateTimeDiv-" + fieldName);
            if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
                dateTimeDiv[0].classList.remove('label-top');
            }
        }
    }

    const onSort = (fieldName, isDescending) => {

        let temp_rowSort = rowSort;
        let activeTab = getActiveTab();
        try {
            reloadReport({ sort_field_name: fieldName, sort_type: isDescending ? 'desc' : 'asc' });
            if (!getBooleanParam(temp_rowSort.isDescending)) {
                temp_rowSort.isDescending = isDescending;
            }
            else {
                temp_rowSort.isDescending = isDescending;
            }
            setRowSort(temp_rowSort);
        }
        catch (error) {
            console.error("Error in 'couponClaimReport.js -> onSort()':" + error);
        }
    }


    const excelGenerateBeforeHeaders = () => {
        let optionsMultilingual = getMultilingualOptionsMap();
        let tempdueBy = '';
        let cells = '';
        let reportName = getStringParam(getLocalizedStrings().label.COUPONS_CLAIM_REPORTS.COUPONS_CLAIM_REPORTS_LABEL);
        let columns = fields.filter(f => {
            if (!f.is_hidden) { return f; }
        });
        let columnsLength = getArrayParam(columns).length;

        cells = cells + '<tr><td  colspan="' + (columnsLength) + '"> ' + reportName + ' </td></tr>';
        if (reportName.length > 0) {
            tempdueBy = getStringParam(optionsMultilingual[optionvalue[getStringParam(dueBy)]]);
            cells = cells + '<tr><td> Date :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + tempdueBy + ' </td></tr>';
        }
        return cells;
    }

    const excelGenerateHeaders = () => {
        let cells = '';
        let columns = fields.filter(f => {
            if (!f.is_hidden) { return f; }
        });
        columns.map(f => {
            cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    const excelGenerateRows = () => {
        let rows = getArrayParam(records);
        let columns = getArrayParam(fields);
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            rows.map((rowObject, rowIndex) => {
                let innerCells = '';
                columns.map((columnObject, colIndex) => {
                    if (!columnObject.is_hidden) {
                        innerCells = innerCells + '<td >' + rowObject[columnObject.name] + ' </td>';
                    }
                })
                if (rowIndex % 2 === 0) {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                } else {
                    cells = cells + ('<tr  bgcolor="Lavender">' + innerCells + ' </tr>');
                }

            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
        }
        return rowData;

    }

    const downloadExcel = () => {
        let beforeHeaderComponent = excelGenerateBeforeHeaders();
        let headerComponents = excelGenerateHeaders();
        let rowComponents = excelGenerateRows();
        let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '</tbody></table>';
        let htmlData = htmlTable;
        if (isValidParam(htmlData) && htmlData !== '') {
            htmlData = htmlData.replace("%22", "\"");
            var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
            var downloadUrl = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = downloadUrl;
            a.download = 'CouponClaimsReportResultSet_' + new Date().getTime() + '.xls';
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    }

    const customEvent = (eventType, column, row, event) => {
        try {
            eventType = getStringParam(eventType);
            column = getObjectParam(column);
            row = getObjectParam(row);
            let columnName = column.name;
            let labelName = column.label;

            if (eventType === 'is_detail_link' && columnName === 'company_name' && labelName === 'Account') {
                openTab(getMultiColumnTitles(columnName, row), constants.ACCOUNTS_OBJECT, row.account_id);
            } else if (eventType === 'is_detail_link' && columnName === 'coupon_name' && labelName === 'Coupon') {
                openTab(getMultiColumnTitles(columnName, row), constants.COUPONS_OBJECT, row.id);
            }
        } catch (error) {
            console.error("Error in 'couponClaimReport.js -> customEvent()':" + error);
        }
    }

    const openTab = (label, object, recordId) => {
        let activeTab = getObjectParam(getActiveTab());
        let activeTabIndex = getIntParam(activeTab.index);
        let url = '/' + object + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + recordId;
        let tab = { label: label, object: object, type: TYPE_DETAIL_VIEW, imgName: null, url: url, isActive: true, parentTabIndex: activeTabIndex };

        addTab(tab, true);
    }

    const generateCouponsSelectionField=()=> {
        let tempCouponsList = getArrayParam(couponsList);
        let coubonListObj;
        try {
            coubonListObj = tempCouponsList.map((item,index) => {
                return <MenuItem  key={'key-iem-'+item.id} value={item.id} style={styles.popoverMenuItem}>{item.name} </MenuItem>;
            });
            coubonListObj.unshift(<MenuItem   key={-97} value = {-97} style={styles.popoverMenuItem} >All Coupons</MenuItem>);     
        }
        catch (error) {
            console.error("Error in 'couponClaimReport.js -> generateCouponsSelectionField()':" + error);
        }
        return coubonListObj;
    }

    const getNoRecordContent = () => {
        return (<table className="layout_maintable" style={{ width: '100%' }}><tr><td><span style={{ color: 'red' }}>{getLocalizedStrings().message.COMMON.NO_DATA}</span></td></tr></table>);
    }

    const getReportFilter = () => {
        let buttonSet = getButtonSet();
        let sfFormFieldContainerStyle = { ...styles.sfFormFieldContainer };
        let HHFormat = new RegExp("HH");
        let datePickerOptions = {
            enableTime: false,
            dateFormat: dateFormatFlatPicker[appState.me.date_format],
            minuteIncrement: 1,
            static: true,
            time_24hr: HHFormat.test(appState.me.date_format) ? true : false
        };
        return (
            <div className="row" style={{ padding: '0 0 10 0' }} >
                <div >
                    <Grid container spacing={0} >
                        <Grid item xs={12} style={{ display: 'flex', marginLeft: "17px" }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ padding: 0 }} >
                                    <div
                                        style={{ ...sfFormFieldContainerStyle, width: '100%', cursor: "pointer", fontSize: '14px', color: '#717171', height: '47px' }}
                                        id='due_by'
                                        key='due_by'
                                    >
                                        <FormControl variant="outlined">
                                            <InputLabel id="sf-couponreport-simple-select-outlined-label" className="sf-taskreport">{getLocalizedStrings().label.REPORT.DATE_RANGE}</InputLabel>
                                            <Select
                                                labelId="sf-couponreport-simple-select-outlined-label"
                                                id="sf-couponreport-simple-select-outlined"
                                                value={dueBy}
                                                className={"sf-fields-bg"}
                                                onChange={(e)=>handleDropDownChange('due_by', e, e.target.value)}
                                                label={getLocalizedStrings().label.REPORT.DATE_RANGE}
                                                style={{  minWidth: 250, maxWidth: 250, cursor: "pointer", fontSize: '14px', color: '#717171', height: '45px', paddingLeft: 7}}
                                            >
                                                {generateDateSelectionField()}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div style={{ paddingLeft: '7px' }} >
                                    <div
                                        style={{ ...sfFormFieldContainerStyle, width: '100%', cursor: "pointer", fontSize: '14px', color: '#717171', height: '47px', marginRight: "7px" }}
                                        id='coupons_names'
                                        key='coupons_names'
                                    >
                                        <FormControl variant="outlined">
                                            <InputLabel id="sf-coupon-report-fromcouponreport-simple-select-outlined-label" className="sf-task-report-fromtaskreport">{getLocalizedStrings().label.REPORT.COUPONS_NAME}</InputLabel>
                                            <Select
                                                labelId="sf-coupon-report-fromcouponreport-simple-select-outlined-label"
                                                id="sf-coupon-report-fromcouponreport-simple-select-outlined"
                                                value={coupons_id}
                                                onChange={(e)=>handleDropDownChange('coupons_names', e, e.target.value)}
                                                label={getLocalizedStrings().label.REPORT.COUPONS_NAME}
                                                style={{  minWidth: 250, maxWidth: 250, cursor: "pointer", fontSize: '14px', color: '#717171', height: '45px', paddingLeft: 7, width: '100%' }}
                                                >
                                                {generateCouponsSelectionField()}
                                            </Select>
                                        </FormControl>

                                    </div>
                                </div>
                                <div style={{ display: customTimeLineDisplay }}>
                                    <div id="dateholder" style={{ display: 'grid', color: 'rgb(97, 97, 97)', borderRadius: '4px', background: '#fff', marginRight: '7px' }}>
                                        <PopOver
                                            id={'from_date'}
                                            key={'from_date'}
                                            name={'from_date'}
                                            btnType={'date'}
                                            buttonStyle={{ fontSize: '16px' }}
                                            containerStyle={{ lineHeight: 1 }}
                                            buttonLabel={getLocalizedStrings().label.REPORT.START_DATE}
                                            onChange={(date)=>handleDateChange(date)}
                                            value={from_date}
                                            innerLabelStyle={{ padding: "8 5 2 5", fontSize: '15px' }}
                                            height={45}
                                            options={datePickerOptions}
                                        />
                                    </div>

                                </div>
                                <div style={{ display: customTimeLineDisplay }}>
                                    <div id="dateholder" style={{ display: 'grid', color: 'rgb(97, 97, 97)', borderRadius: '4px', background: '#fff', marginRight: '7px' }}>
                                        <PopOver
                                            id={'to_date'}
                                            key={'to_date'}
                                            name={'to_date'}
                                            btnType={'date'}
                                            buttonStyle={{ fontSize: '16px' }}
                                            containerStyle={{ lineHeight: 1 }}
                                            buttonLabel={getLocalizedStrings().label.REPORT.END_DATE}
                                            onChange={(date)=>handleDateChange(date)}
                                            value={to_date}
                                            innerLabelStyle={{ padding: "8 5 2 5", fontSize: '15px' }}
                                            height={45}
                                            options={{ ...datePickerOptions, minDate: from_date }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: '10px' }}>{buttonSet}</div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    const getButtonSet = () => {
        return (
            <div>
                <iframe id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute', display: 'none' }}></iframe>
                <Button
                    onClick={() => { reloadReport({ isNewFilter: true }) }}
                    style={{
                        ...styles.primaryButton,
                        minWidth: '40px'
                    }}
                    title={getLocalizedStrings().label.REPORT.GO}
                >{getLocalizedStrings().label.REPORT.GO}</Button>
                <Button
                    onClick={() => {
                        var content = document.getElementById("divcontents");
                        var ifrmae = document.getElementById("ifmcontentstoprint");
                        ifrmae.style.display = 'block'
                        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                        ifrmae.style.display = 'block'

                        pri.document.open();
                        pri.document.write(

                            content.innerHTML);
                        pri.document.close();
                        pri.focus();
                        pri.print();
                        ifrmae.style.display = 'none'
                    }}

                    style={{
                        ...styles.secondaryButton,
                        minWidth: '40px'
                    }}
                    title={getLocalizedStrings().label.REPORT.PRINT}
                >{getLocalizedStrings().label.REPORT.PRINT}</Button>
                <Button
                    endIcon={<i className="fa fa-file-excel-o" style={{ fontSize: '20px', color: 'green' }} ></i>}
                    onClick={() => { downloadExcel(); }}
                    style={{
                        ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                        color: '#717171',
                        border: '0px',
                        minWidth: '30px'
                    }}
                    title="Excel"
                ></Button>
            </div>
        );
    }

        let style = { margin: 10, width: '98%', paddingTop: 0, paddingLeft: 11 };
        style = { ...style, ...getObjectParam(style) };
        let headerComponent = getReportFilter();
        let noRecordsTitle = getNoRecordContent()
        let tempRecords = getArrayParam(records);
        let pageHeight = window.innerHeight - 247;
        let top = (pageHeight-10) / 2
        return (
            <div style={style}>
                <div>{headerComponent} </div>
                {isMounted ?
                    <div id="divcontents-coupon-claim-report">
                        {tempRecords.length > 0 ?
                            <SFDataTable columns={fields} rowSort={rowSort} rows={records} customEvent={customEvent} />
                            :
                            noRecordsTitle
                        }
                    </div>
                    :
                    <div style={{ width: '100%', height: pageHeight }}>
                        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        )

}

export default CouponClaimReport;

