import React, { useEffect, useState } from 'react';
import { getBooleanParam, getStringParam } from '../../../services/helper/parameterVerifier';
import { phoneFormat, removeCurrency } from '../../../services/helper/utils';
import { TextField } from '@mui/material';

const GridTextField = (props) => {
    const { column, rowData, p } = props;
    const [value, setValue] = useState(rowData[column.key]);

    useEffect(() => {
        let isCurrencyField = getBooleanParam(column.is_currency_field);
        setValue(isCurrencyField ? removeCurrency(props.value) : props.value);
    }, []);

    const onValueChanged = (event) => {
        let value = getStringParam(event.target.value);
        setValue(value);
    };

    if (rowData.id < 0) {
        return null;
    }
    return (
        <div>
            <TextField
                id="text"
                type="text"
                autoFocus={true}
                size="small"
                onBlur={() => {
                    if(value === undefined) return;
                    p.onRowChange({ ...p.row, [column.key]: phoneFormat(value) }, true)
                }}
                defaultValue={value}
                value={value}
                onChange={(e) => onValueChanged(e)}
                style={{ width: '100%' }}
            />
        </div>
    );
};

export default GridTextField;
