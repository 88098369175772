
import React from 'react';
import { isValidParam, getStringParam, getObjectParam, getIntParam, getBooleanParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import SFDataTable from '../components/sfDataTable';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import * as HTTPClient from '../../../services/helper/httpClient';
import { connect } from 'react-redux';

/**
 * Author   : Pradyut
 * Date:    : 2019-03-27
 */

class AWSReportContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gadget: '',
            projectIds: [],
            object: '',
            fieldName: '',
            fieldValue: '',
            dateRange: '',
            fields: [],
            records: [],
            isMounted: false
        };
    }

    componentDidMount() {
        this.setData();
    }

    setData = () => {
        try {
            let params = getObjectParam(this.props.match.params);
            if (Object.keys(params).length > 0) {
                let _state = this.state;
                _state.gadget = getStringParam(params.gadget);
                let strIds = getStringParam(params.projectId);
                let arrIds = strIds.split("&p.projectId=");
                arrIds = arrIds.map(id => { return getIntParam(id) });
                _state.projectIds = arrIds;
                _state.object = decodeURIComponent(decodeURI(getStringParam(params.object)).replace(/\+/g, '%20'));
                _state.fieldName = decodeURIComponent(decodeURI(getStringParam(params.fieldName)).replace(/\+/g, '%20'));
                _state.fieldValue = decodeURIComponent(decodeURI(getStringParam(params.fieldValue)).replace(/\+/g, '%20'));
                _state.dateRange = decodeURIComponent(decodeURI(getStringParam(params.dateRange)).replace(/\+/g, '%20'));
                this.setState(_state);

                let input_params = {
                    projectIds: this.state.projectIds.join(),
                    fieldName: this.state.fieldName,
                    fieldValue: this.state.fieldValue,
                    dateRange: this.state.dateRange
                };

                if (this.state.fieldName === 't_companyname') {
                    input_params.fieldName = null;
                    input_params.fieldValue = null;
                    input_params.tenantName = this.state.fieldValue
                }

                let promise = HTTPClient.get('v3/common/' + this.state.object + '/getFranchisorData', input_params);
                if (isValidParam(promise)) {
                    promise.then(response => {
                        if (isValidParam(response)) {
                            if(isValidParam(response.fields)) {
                                this.setFields(getArrayParam(response.fields));
                                this.setState({records: getArrayParam(response.records), isMounted: true});
                            }
                        }
                    });
                }
            }
        } catch (error) {
            console.error("Error in 'AWSReportContainer.js -> setData()':" + error);
        }
    }

    setFields = (fields) => {
        let headerRow = fields.map( field => {
            return { name: field.name, label: field.label, width: 160, is_hidden: false, is_email_field: false, is_detail_link: false, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: false, style: {} }
        });
        this.setState({fields: headerRow});
    }

    render() {        
        let isMounted = getBooleanParam(this.state.isMounted);
        let records = getArrayParam(this.state.records);
        
        return <div style={{padding: 20}}>
            {/* <div style={{  marginBottom: '15px', width: '514px'}}>
                <a href="http://www.soffront.com/" target="_blank" ><img style={{ height: '5%', minHeight: '40px' }} src={'/asset/images/soffront_logo.png'} alt="Soffront CRM" ></img></a>
            </div> */}
            <div style={{marginTop: -10}}>
                <Logo logo={this.props.app.me.logo} website={this.props.app.me.website} />
            </div>
            <div style={{height: '2px', backgroundColor: '#dddddd', marginTop: 60}} />
            {isMounted &&
                <p>
                <div className="hdrfiltr" style={{ fontSize: '24px', marginTop: '0px', height: 31 }}>
                    {this.state.gadget} - {this.state.fieldValue}
                    <div style={{ fontSize: '14px', display: 'inline-block', marginLeft: '2px', marginTop: 10 }}>{' (' + this.state.records.length + ')'}</div>

                </div>
                </p>
            }

             {isMounted && records.length > 0 && 
                <SFDataTable
                columns={this.state.fields}
                rows={this.state.records}
                style={{height: 'calc(100% -  150px)'}}
                />}
            {isMounted && records.length === 0 && 
                <div key={this.props.object} style={{ ...styles.workAreaContainer, marginTop: '0px' }}>
                    <table className="table table-striped">
                        <tr>
                            <td>
                                <div className="data_less">
                                    <span>No records found</span>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            }
            {!isMounted && <div style={{...styles.loaderContainer, marginTop: 200}}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>} 
        </div>
    }
}

function Logo(props) {
    if(props.logo !== null ) {
        return (
            <div style={{ marginTop: '12px' }}>
                <a href={props.website} target="_blank" rel="noreferrer" ><img alt='' src={props.logo} border='0' style={{ paddingLeft: '0px', float: 'left', maxWidth: '130px', maxHeight: '40px' }} /></a>
            </div>
        );
    } else {
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        app: state.app,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default AWSReportContainer = connect(mapStateToProps, mapDispatchToProps)(AWSReportContainer);