import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { constants } from '../../../services/constants/constants';

const PageTemplatePreview = ({ match }) => {

    const [mounted, setMounted] = useState(false);
    const [body, setBody] = useState("");
    const [projectId, setprojectId] = useState(0);
    const [userId, setUserId] = useState(0);
    const [isValidURL, setIsValidURL] = useState(true);

    const app = useSelector((state) => state.app);

    useEffect(() => {
        let params = {};
        var endpointUrl = '';
        let object = '';
        if (match.url.includes('previewpagetemplate')) {
            params.id = match.params.id;
            endpointUrl = endPoints.LANDING_PAGE.GET;
            object = constants.LANDING_PAGES_FROALA_OBJECT;
        }
        var promise = Promise.resolve(HTTPClient.get(endpointUrl, params));
        promise.then((response) => {
            if (response.status === -1) {
                setMounted(true);
                setIsValidURL(false);
            } else {
                if (object === constants.LANDING_PAGES_FROALA_OBJECT) {
                    setBody(response.template.pageBody);
                    setMounted(true);
                    setprojectId(app.me.projectId);
                    setUserId(app.me.id);
                }
            }
        });
    }, [])


    if (mounted) {
        if (isValidURL) {
            return (
                <div id="body" className="divBody">
                    <p dangerouslySetInnerHTML={{ __html: (body) }} />
                    <div><input type="hidden" id="projectId" name="projectId" value={projectId} /></div>
                    <div><input type="hidden" id="userId" name="userId" value={userId} /></div>
                </div>
            );
        } else {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div style={{ padding: '40px 15px', textAlign: 'center' }}>
                                <h1>
                                    Oops!</h1>
                                <h2>
                                    404 Not Found</h2>
                                <div className="error-details">
                                    Sorry, an error has occured, Requested page not found!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className="row h-100">
                <div className="col-sm-12 align-self-center" style={{ textAlign: "center" }}>
                    <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-secondary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-success" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        )
    }

}

export default PageTemplatePreview;