import React from 'react';
import { isValidParam, getStringParam, getArrayParam, getObjectParam, getBooleanParam, getIntParam } from '../../../services/helper/parameterVerifier';
import SFDataTable from '../components/sfDataTable';
import { constants } from '../../../services/constants/constants'
import { addTab, TYPE_DETAIL_VIEW, getActiveTab } from '../../../services/helper/sfTabManager';
import { LinkToType } from '../../../services/constants/link';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { setHeader } from '../../../services/actions/headerActions';
import { getMultiColumnTitles } from '../../../services/helper/common';
import { styles } from '../../../services/constants/styles';
import NoRecords from '../components/noRecord';
import ShowCircularProgress from '../components/circularProgress';
import { doRefreshOnboardingUnits } from '../../../services/actions/headerActions';
import Button from '@mui/material/Button';
import { OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import * as SFFormActions from '../../../services/actions/sfFormActions'
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { sortArrayObjectByPropertyOrderType } from '../../../services/helper/utils';
import isArray from 'lodash/isArray';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOnboardingUnits } from '../../../services/actions/unitsAction';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { getCookie, setCookie } from '../../../services/helper/sfCookies';
const moment = require('moment');

const OnboardingUnits = ({ object, isOnboarding, tempStyle }) => {

    const [isMounted, setisMounted] = useState(false);
    const [fields, setFields] = useState([]);
    const [data, setData] = useState({});
    const [records, setRecords] = useState([]);
    const [rowSort, setrowSort] = useState({
        sortFieldName: '',
        isDescending: false,
        onSort: (fieldName, isDescending,records) => onSortFn(fieldName, isDescending,records)
    });
    const [workflowId, setworkflowId] = useState(false);
    const refreshOnboardingUnits = useSelector((state) => state.header.refreshOnboardingUnits);
    const app = useSelector((state) => state.app);
    const dispatch = useDispatch();

    useEffect(() => {
        if (refreshOnboardingUnits) {
            getOnboardingUnitsData();
        } else {
            let sortFieldName = 'custom_field_date9';
            setData({});
            setRecords([]);
            getOnboardingUnitsData();
            let tab = getObjectParam(getActiveTab());
            let label = tab.label;
            if (label === 'Onboarding') {
                label = getLocalizedStrings().label.HEADER['Onboarding'];
            }
            dispatch(setHeader(constants.ONBOARDING_OBJECT, label, null, false, true));
        }
    }, [refreshOnboardingUnits]);



    const getOnboardingUnitsData = (params) => {
        try {
            setisMounted(false);
            params = getObjectParam(params);
            if (!isValidParam(params.sort_field_name)) {
                params.sort_field_name = 'custom_field_date9';
            }
            if (!isValidParam(params.sort_type)) {
                params.sort_type = 'asc';
            }
            let promise = getOnboardingUnits(params);
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        let data = { ...response };
                        let recordsData = getArrayParam(response.records)
                        let fields = getArrayParam(response.fields);
                        let workflowId = getIntParam(response.workflowId);
                        recordsData = recordsData.map(record => {
                            fields.forEach(field => {
                                if (field.is_WorkflowStage && record[field.name] !== 'Completed' && isValidParam(record[field.name])) {
                                }

                            });
                            return record;
                        });
                        delete response.records
                        fields = fields.map(field => {
                            if (field.is_WorkflowStage) {
                                field.style = { textAlign: 'center' };
                            }
                            return field;
                        });
                        let _rowSort = rowSort;
                        let sortInfo = getOrderInfoData();
                        if (sortInfo !== null && Object.keys(sortInfo).length > 0) {
                            let fieldName = sortInfo.fieldName;
                            let orderType = sortInfo.orderType;
                            sortArrayObjectByPropertyOrderType(recordsData, fieldName, orderType);
                            _rowSort = {
                                sortFieldName: fieldName,
                                isDescending: orderType === 'asc' ? false : true,
                                onSort: (fieldName, isDescending) => onSortFn(fieldName, isDescending, recordsData)
                            }
                        } else {
                            sortArrayObjectByPropertyOrderType(recordsData, fields[0].name, 'desc');
                            _rowSort = {
                                sortFieldName: fields[0].name,
                                isDescending: true,
                                onSort: (fieldName, isDescending) => onSortFn(fieldName, isDescending, recordsData)
                            }
                        }
                        
                        if (refreshOnboardingUnits) {
                            dispatch(doRefreshOnboardingUnits(false));
                        }
                        setrowSort({..._rowSort});
                        setisMounted(true);
                        setFields([...fields]);
                        setData(data);
                        setRecords([...recordsData]);
                        setworkflowId(workflowId);
                     }
                });
            }
        } catch (error) {
            console.error("Error in 'OnboardingUnits.js -> getOnboardingUnits()':" + error);
        }
    }

    const onSortFn = (fieldName, isDescending,recordsData) => {
        try {
            let tempRecords = recordsData;
            let orderType = isDescending ? 'desc' : 'asc';
            sortArrayObjectByPropertyOrderType(tempRecords, fieldName, orderType);
            setOrderInfoData(fieldName, orderType);
            setRecords(tempRecords);
        }
        catch (error) {
            console.error("Error in 'OnboardingUnits.js -> onSort()':" + error);
        }
    }

     const getOrderInfoData = () => {
        try {
            let orderInfo = {};
            let cookieName = 'onboarding_reports_order' + object + '_' + app.me.projectId + '_' + app.me.id;
            let cookieValue = getObjectParam(JSON.parse(getCookie(cookieName)));
            if (Object.keys(cookieValue).length === 0) {
                return orderInfo;
            } else {
                let fieldName = cookieValue['fieldName'];
                let orderType = cookieValue['orderType'];
                orderInfo.fieldName = fieldName;
                orderInfo.orderType = orderType;
                return orderInfo;
            }
        } catch (error) {
            console.error("Error in 'OnboardingUnits.js -> getOrderInfoData()':" + error);
        }
    }

    const setOrderInfoData = (fieldName, orderType) => {
        try {
            let orderInfo = {}
            if (fieldName !== null && getStringParam(fieldName) !== '' && orderType !== null && getStringParam(orderType) !== '') {
                let cookieName = 'onboarding_reports_order' + object + '_' + app.me.projectId + '_' + app.me.id;
                orderInfo.fieldName = fieldName;
                orderInfo.orderType = orderType;
                setCookie(cookieName, JSON.stringify(orderInfo), constants.DEFAULT_COOKIE_EXPIRES);
            }

        } catch (error) {
            console.error("Error in 'OnboardingUnits.js -> setOrderInfoData()':" + error);
        }
    }
    const customEvent = (eventType, column, row, event) => {
        try {
            eventType = getStringParam(eventType);
            column = getObjectParam(column);
            row = getObjectParam(row);

            if (eventType === 'is_detail_link' && column.name !== "custom_field_date9") {
                openTab(getMultiColumnTitles(column.name, row), constants.ACCOUNTS_OBJECT, row.id);
            } else if (eventType === 'is_detail_link' && column.name === "custom_field_date9") {
                let obj = {};
                obj.column = {};
                obj.column.field_type = column.field_type;
                obj.column.is_detail_link = column.is_detail_link;
                obj.column.is_hidden = column.is_hidden;
                obj.column.is_readonly = column.is_readonly;
                obj.column.is_sortable = column.is_sortable;
                obj.column.name = column.name;
                obj.column.width = column.width;
                obj.column.key = column.name;
                obj.column.id = column.id;
                obj.object = constants.ACCOUNTS_OBJECT;
                let rows = [];
                rows.push(row);
                obj.row = row;
                obj.callBackToUpdate = (tempRdata)=>handleDateChange(tempRdata);
                obj.callFrom = "onBoarding";
                obj.rowIdx = 0;
                obj.rowId = row.id;
                dispatch(getAppDialog(true, constants.LIST_VIEW_DATE_PICKER, column.label, null, obj, null));

            }
        } catch (error) {
            console.error("Error in 'OnboardingUnits.js -> customEvent()':" + error);
        }
    }

    const handleDateChange = (tempRdata) => {
        try {
            let tempRecords = records;
            let object = constants.ACCOUNTS_OBJECT;
            let tempValToCheckLength = tempRdata?.value;
            if (tempRdata.column.field_type === constants.FIELD_TYPE_DATE && tempRdata.value !== '') {
                tempValToCheckLength = moment(tempRdata?.value);
                tempValToCheckLength = tempValToCheckLength.format("YYYY-MM-DD");
            } else if (tempRdata.column.field_type === constants.FIELD_TYPE_DATE_TIME && tempRdata.value !== '') {
                tempValToCheckLength = moment(tempRdata?.value);
                tempValToCheckLength = tempValToCheckLength.format("YYYY-MM-DD HH:mm:ss");
            }
            let params = {};
            params.id = tempRdata.rowId;
            params.fields = [];
            let tempField = {};
            tempField.id = tempRdata.column.id;
            tempField.name = tempRdata.column.name;
            tempField.value = tempValToCheckLength;
            params.fields.push(tempField);
            params.auditrail_action_type = constants.INLINE_UPDATE_AUDIT_TRAIL_RECORD;
            let promise = SFFormActions.saveRecordPromise(object, params);
            promise.showCustomSnackBar = dispatch(showCustomSnackBar);
            promise.then((response) => {
                if (isValidParam(response) && response.hasOwnProperty('data') && response.data.hasOwnProperty('id')) {
                    let id = isValidParam(response.data.id) && isArray(response.data.id) && response.data.id.length > 0 ? response.data.id[0] : 0;
                    if (id > 0) {
                        tempRecords.forEach((record, index) => {
                            if (record.id === tempRdata.rowId) {
                                record.custom_field_date9 = tempRdata.value;

                            }
                            tempRecords.push(record);
                        })
                        if (rowSort.sortFieldName === "custom_field_date9") {
                            if (!rowSort.isDescending) {
                                tempRecords.sort(function (a, b) {
                                    var key1 = a.custom_field_date9 !== "Empty" ? new Date(a.custom_field_date9) : null;
                                    var key2 = b.custom_field_date9 !== "Empty" ? new Date(b.custom_field_date9) : null;

                                    if (key1 < key2) {
                                        return -1;
                                    } else if (key1 === key2) {
                                        return 0;
                                    } else {
                                        return 1;
                                    }
                                });
                            } else {
                                tempRecords.sort(function (a, b) {
                                    var key1 = a.custom_field_date9 !== "Empty" ? new Date(a.custom_field_date9) : null;
                                    var key2 = b.custom_field_date9 !== "Empty" ? new Date(b.custom_field_date9) : null;

                                    if (key1 > key2) {
                                        return -1;
                                    } else if (key1 === key2) {
                                        return 0;
                                    } else {
                                        return 1;
                                    }
                                });
                            }
                        } else {
                            sortArrayObjectByPropertyOrderType(records, rowSort.sortFieldName, rowSort.isDescending ? 'desc' : 'asc');
                        }
                        setRecords(tempRecords);
                        dispatch(doRefreshOnboardingUnits(true));
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                       // getOnboardingUnitsData();
                    }
                }
            });
        } catch (error) {
            console.error("Error in 'OnboardingUnits.js -> handleDateChange()':" + error);
        }
    }

    const openTab = (label, object, recordId) => {
        let activeTab = getObjectParam(getActiveTab());
        let activeTabIndex = getIntParam(activeTab.index);
        let url = '/' + object + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + recordId;
        let tab = { label: label, object: object, type: TYPE_DETAIL_VIEW, imgName: null, url: url, isActive: true, parentTabIndex: activeTabIndex };

        addTab(tab, true);
    }

    const openCustomize = (event, value) => {
        try {
            let token = localStorage.getItem('token');
            let redirectUrl = constants.snapshotUrl;
            redirectUrl += '&token=' + token;
            redirectUrl += '&callingfor=workflows';
            redirectUrl += '&recordId=' + workflowId;
            let tableId = OBJECT_TABLEID_MAP['accounts'];
            redirectUrl += '&type=edit';
            redirectUrl += '&tableId=' + tableId;
            window.open(redirectUrl);
        } catch (error) {
            console.error("Error in 'OnboardingUnits.js -> openCustomize()':" + error);
        }
    }

    const downloadExcelReport = (e) => {
        if (e !== null && e !== undefined) {
            let beforeHeaderComponent = excelGenerateBeforeHeaders();
            let headerComponents = excelGenerateHeaders();
            let rowComponents = excelGenerateRows();

            let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '  </tbody> </table>';
            let htmlData = htmlTable;
            if (isValidParam(htmlData) && htmlData !== '') {
                htmlData = htmlData.replace("%22", "\"");
                var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
                var downloadUrl = URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = downloadUrl;
                a.download = 'ResultSet_' + new Date().getTime() + '.xls';
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        }
    }

    const excelGenerateBeforeHeaders = () => {
        let cells = '';
        let reportName = getStringParam(getLocalizedStrings().label.HEADER.ONBOARDING);
        let tempColumns = getArrayParam(fields);
        let columns = tempColumns.filter(f => {
            let temp;
            if (!f.is_hidden) {
                temp = f;
            }
            return temp;
        });
        let columnsLength = getArrayParam(columns).length;

        cells = cells + '<tr><td  colspan="' + (columnsLength) + '"> ' + reportName + ' </td></tr>';

        return cells;
    }

    const excelGenerateHeaders = () => {
        let cells = '';
        let tempColumns = getArrayParam(fields);
        let columns = tempColumns.filter(f => {
            let temp;
            if (!f.is_hidden) { temp = f; }
            return temp;
        });
        columns.forEach(f => {
            cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    const excelGenerateRows = () => {
        let rows = getArrayParam(records);
        let columns = getArrayParam(fields);
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            rows.forEach((rowObject, rowIndex) => {
                let innerCells = '';
                columns.forEach((columnObject, colIndex) => {
                    if (!columnObject.is_hidden) {
                        let value = rowObject.hasOwnProperty(columnObject.name) ? rowObject[columnObject.name] : "";
                        innerCells = innerCells + '<td >' + value + ' </td>';
                    }
                })
                if (rowIndex % 2 === 0) {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                } else {
                    cells = cells + ('<tr  bgcolor="Lavender">' + innerCells + ' </tr>');
                }

            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
        }
        return rowData;

    }
    let style = { margin: 10, paddingTop: 0 ,height:'66%',overflowY:'scroll'};
    style = { ...style, ...getObjectParam(tempStyle) };

    let contentHeight = document.body.clientHeight - 250;
    let top = (contentHeight - 10) / 2;
    return (
        <div style={style}>
            {isMounted && records.length > 0 &&
                <div style={{ float: 'right' }}>
                    <Button
                        startIcon={<i className="far fa-file-excel" style={{ fontSize: '20px', color: 'green' }} />}
                        onClick={(e) => downloadExcelReport(e)}
                        style={{ ...styles.listViewPrimaryButton, backgroundColor: 'inherit', color: '#717171', border: '0px', minWidth: '40px', marginTop: 4, padding: "6px 5px" }}
                        title={getLocalizedStrings().label.REPORT.EXCEL}
                    ></Button>
                    <Button
                        startIcon={<i className="material-icons" style={{ fontSize: '20px', color: '#717171' }} >settings</i>}
                        onClick={(event) => openCustomize(event, constants.EDIT)}
                        style={{ ...styles.listViewPrimaryButton, backgroundColor: 'inherit', color: '#717171', border: '0px', minWidth: '40px', marginTop: 4, padding: "6px 5px" }}
                        title={getLocalizedStrings().label.COMMON.CUSTOMIZE}
                    ></Button>
                </div>
            }
            {isMounted && records.length > 0 &&
                <div style={{ paddingTop: '40px' }}>
                    <SFDataTable
                        columns={fields}
                        rowSort={rowSort}
                        rows={records}
                        customEvent={(eventType, column, row, event) => customEvent(eventType, column, row, event)}
                    />
                </div>
            }
            {isMounted && records.length === 0 &&
                <NoRecords
                    key={'no-records'}
                    object={constants.ONBOARDING_OBJECT}
                />
            }
            {!isMounted &&
                <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default OnboardingUnits;

