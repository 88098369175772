import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import FroalaEditor from "react-froala-wysiwyg";
import Froalaeditor from 'froala-editor'
import $ from "jquery";
import store from "../../../services/store";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import {
	Button,
	FormControl,
	Icon,
	Menu,
	MenuItem,
	TextField,
} from "@mui/material";
import ShowCircularProgress from "../components/circularProgress";
import * as HTTPClient from "../../../services/helper/httpClient";
import { endPoints } from "../../../services/constants/endPoints";
import PopOver from "../components/PopOver";
import { constants } from "../../../services/constants/constants";
import { styles } from "../../../services/constants/styles";
import { Navigate } from "react-router-dom";
import * as sfDialogs from "../components/sfDialogs";
import { getAppDialog } from "../../../services/actions/appContainerActions";
import { getAppDrawer } from "../../../services/actions/appContainerActions";
import { refreshListView } from "../../../services/actions/listViewAcions";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import {
	getIntParam,
	getStringParam,
	getArrayParam,
	getObjectParam,
} from "../../../services/helper/parameterVerifier";
import SFAutoComplete from "../components/sfAutoComplete";
import { hasAccessPermission } from "../../../services/helper/common";
import { storeTemplateListInRedux } from "../../../services/actions/sendMailActions";
import { sortArrayObjectByProperty } from "../../../services/helper/utils";
import { Select, InputLabel } from '@mui/material';
import { OBJECT_TABLEID_MAP, TABLEID_OBJECT_MAP } from '../../../services/constants/constants';

require("froala-editor/js/froala_editor.pkgd.min.js");
require("froala-editor/css/froala_editor.pkgd.min.css");
require("froala-editor/js/plugins/font_family.min.js");
require("froala-editor/css/froala_style.css");

const optionvalue = {
	Copy: "Copy",
	Delete: "Delete",
	Preview: "Preview",
};

const SalesTemplate = ({ id, object, type, closeDrawer, html }) => {
	const dispatch = useDispatch();
	const fieldRefStatus = useRef()

	const app = useSelector((state) => state.app);
	const sendMail = useSelector((state) => state.sendMail);
	const [template, setTemplate] = useState({
		name: "",
		subject: "",
		email_message: "",
		id: id || 0,
		status: "Active",
		agreementType: "",
	});
	const topUpDialog = useSelector((state) => state.topUpDialog);
	const [mounted, setMounted] = useState(false);
	const [personilizeOpen, setPersonilizeOpen] = useState(false);
	const [moreActionOpen, setMoreActionOpen] = useState(false);
	const [error, setError] = useState({
		name: "",
		subject: "",
	});
	const [buttonVisible, setButtonVisible] = useState("inline-block");
	const [redirect, setRedirect] = useState(false);
	const [redirectUrl] = useState("");
	const [personalize_fields, setPersonalize_fields] = useState({});
	const [stickyStyle] = useState({ width: "100%" });
	const [cursorposition, setCursorposition] = useState(0);
	const [isFullScreenEditor, setIsFullScreenEditor] = useState(false);
	const [merge_fields, setMerge_fields] = useState([]);
	const [editor_type, setEditor_type] = useState(false);
	const [moreMenuList, setMoreMenuList] = useState([])
	const [statusArr] = useState([
		<MenuItem
			key={"Active"}
			value={"Active"}
			id={"Active"}
			primaryText={getLocalizedStrings().label.MARKETING_TEMPLATE.ACTIVE}
			style={styles.popoverMenuItem}
		/>,
		<MenuItem
			key={"Inactive"}
			value={"Inactive"}
			id={"Inactive"}
			primaryText={getLocalizedStrings().label.MARKETING_TEMPLATE.INACTIVE}
			style={styles.popoverMenuItem}
		/>,
	]);
	const [mainObjectArr] = useState([
		{ label: 'Accounts', value: constants.ACCOUNTS_OBJECT },
		{ label: 'Contacts', value: constants.CONTACTS_OBJECT },
		{ label: 'Opportunities', value: constants.OPPORTUNITIES_OBJECT },
		{ label: 'Cases', value: constants.CASES_OBJECT },
	]);
	const [selectedBatchObjName, setSelectedBatchObjName] = useState({
		object: "Contacts",
	});
	const [fieldListResp, setFieldListResp] = useState({
		fieldList: [],
		fieldListAtEdit: []
	});
	const [objArr, setObjArr] = useState(["Contacts"]);
	const [isOpenNoteTypeList, setIsOpenNoteTypeList] = useState(false);
	const [isOpenObjectTypeList, setIsOpenObjectTypeList] = useState(false);
	const [isOpenObjectDropDown, setIsOpenObjectDropDown] = useState(false);
	const [isOpenPersonalize, setIsOpenPersonalize] = useState(false);

	const [anchorELNoteTypeList, setAnchorELNoteTypeList] = useState(null);
	const [froalaObject, setFroalaObject] = useState('');
	const [froalaObjInBody, setFroalaObjInBody] = useState('');
	const [subChangeValue, setSubChangeValue] = useState('');
	const [tableName, setTableName] = useState('');

	let froalaRef;

	useEffect(() => {
		loadSalesTemplateData(id);
	}, [id]);

	useEffect(() => {
		getMoreMenuList()
	}, []);

	useEffect(() => {
		document.addEventListener('click', handleDocumentClick);
		return () => {
			document.removeEventListener('click', handleDocumentClick);
		};
	}, []);

	useEffect(() => {
		setTimeout(() => {
			showHideObjectIcon();
		}, [1000])
	}, [selectedBatchObjName, tableName])

	const handleEditorFocus = () => {
		handlePopUpClose();
	};
	const disableEditorBlurEffect = () => {
		const divElement = document.querySelector('.fr-popup');
		if (divElement) {
			divElement.classList.add('fr-block');
		}
	}
	const handleDocumentClick = (event) => {
		if (popUpClose(event.target)) {
			handlePopUpClose()

		}
	};
	const handlePopUpClose = () => {
		let _store = store.getState()
		const divElement = document.querySelector('.fr-popup');
		if (divElement && !_store.appContainer.dialogProps.isOpenDialog && !_store.topUpDialog.isOpenDialog) {
			divElement.classList.remove('fr-block');
			if (froalaRef && froalaRef?.current) {
				froalaRef.current.events.enableBlur();
			}
		}
	};
	const popUpClose = (element) => {
		let _popUp = false;
		const popUpElemrnt = document.querySelector('.fr-popup');
		const dialogElement = document.querySelector('.MuiDialog-paper');
		const insertElement = document.querySelector('.btn-insert');
		if (!insertElement && !dialogElement && !popUpElemrnt?.contains(element)) {
			_popUp = true;
		} else {
			_popUp = false;
		}
		if (dialogElement && !dialogElement.contains(element)) {
			if (popUpElemrnt && !popUpElemrnt.contains(element)) {
				if (insertElement && !insertElement.contains(element)) {
					_popUp = false;
				}
			}
		}
		return _popUp;
	}
	const loadSalesTemplateData = (id) => {
		if (id !== null && id !== undefined && id !== 0) {
			let response = Promise.resolve(
				HTTPClient.get(endPoints.SALES_TEMPLATE.EDIT + "/" + id, null)
			);
			response.then((response) => {
				let tempTemplate = template;
				let tempMerge_fields = merge_fields;
				let tempError = error;
				let tempCursorposition = cursorposition;
				let btnVisible = '';
				tempTemplate["name"] = response.template_details.name;
				tempTemplate["status"] = response.template_details.status;
				tempTemplate["subject"] = response.template_details.subject;
				tempTemplate["email_message"] = response.template_details.email_message;
				tempTemplate["agreementType"] = response.template_details.hasOwnProperty('agreementType') ? response.template_details.agreementType : null; 
				tempTemplate["id"] = response.template_details.id;
				tempTemplate["editor_type"] = response.template_details.t_editor_type;
				tempMerge_fields["merge_fields"] = response?.contact_mail_merge_fields;
				tempError["name"] = "";
				tempError["status"] = "";
				tempCursorposition = response.template_details.subject.length;
				btnVisible = "inline-block"

				if (type === "copy") {
					tempTemplate.id = 0;
					tempTemplate.name =
						getLocalizedStrings().label.SALES_TEMPLATE.COPY_OF +
						"_" +
						template.name;
					tempTemplate.agreementType = template.agreementType
					btnVisible = "none"
				}
				let personalizeArray = {};
				let i;
				let j;
				let tableId = response.template_details.tableId;
				let _tableName = TABLEID_OBJECT_MAP[tableId];
				_tableName = _tableName == "contacts" ? "Contacts" : _tableName == "accounts" ? "Accounts" : _tableName == "opportunities" ? "Opportunities" : _tableName == "cases" ? "Cases" : tableName;
				setTableName(_tableName);
				let temp = fieldListResp;
				temp.fieldListAtEdit = response;
				setFieldListResp({ ...temp });
				let mergeFields;
				if (_tableName == "Contacts") {
					mergeFields = response?.contact_mail_merge_fields;
				} else if (_tableName == "Accounts") {
					mergeFields = response?.company_mail_merge_fields;
				} else if (_tableName == "Opportunities") {
					mergeFields = response?.opportunity_mail_merge_fields;
				} else if (_tableName == "Cases") {
					mergeFields = response?.case_mail_merge_fields;
				}
				let _objArr = objArr;
				if (_tableName == "Accounts") {
					_objArr = ["Accounts", "Contacts"]
				} else if (_tableName == "Opportunities") {
					_objArr = ["Opportunities", "Contacts"]

				} else if (_tableName == "Cases") {
					_objArr = ["Cases", "Contacts"]

				} else if (_tableName == "Contacts") {
					_objArr = ["Contacts"]

				}
				setObjArr(_objArr)

				for (i in mergeFields) {
					for (j in mergeFields[i]) {
						personalizeArray[j] = mergeFields[i][j];
					}
				}
				let tempPersonalizeFields = {
					'Agreement_Name': "Agreement Name",
					'Agreement_Link': "Agreement Link",
					...personalizeArray,
				};
				setMounted(true);
				setButtonVisible(btnVisible);
				setTemplate(tempTemplate);
				setMerge_fields(tempMerge_fields);
				setEditor_type(editor_type);
				setError(tempError);
				setCursorposition(tempCursorposition);
				setPersonalize_fields(tempPersonalizeFields);

			});


		} else {
			let _object = constants.CONTACTS_OBJECT
			let objectTableId = OBJECT_TABLEID_MAP[getStringParam(_object)];
			let params = {}
			params.tableId = objectTableId
			let response = Promise.resolve(
				HTTPClient.get(endPoints.SALES_TEMPLATE.NEWTEMPLATE, params)
			);
			response.then((response) => {
				let tempTemplate = {};
				tempTemplate["name"] = "";
				tempTemplate["subject"] = "";
				tempTemplate["id"] = 0;
				tempTemplate["email_message"] = "";
				tempTemplate["editor_type"] = constants.LEGACY;
				tempTemplate["email_message"] = getLocalizedStrings().label.SALES_TEMPLATE.DEAR + '  ${j_contacts.first_name},<br/>  ' + getLocalizedStrings().label.SALES_TEMPLATE.TYPE_HERE
				let personalizeArray = {};
				let i;
				let j;
				let mergeFields = response?.contact_mail_merge_fields;
				for (i in mergeFields) {
					for (j in mergeFields[i]) {
						personalizeArray[j] = mergeFields[i][j];
					}
				}
				let tempPersonalizeFields = {
					'Agreement_Name': "Agreement Name",
					'Agreement_Link': "Agreement Link",
					...personalizeArray,
				};
				setTemplate(tempTemplate);
				setMounted(true);
				setButtonVisible("none");
				setPersonalize_fields(tempPersonalizeFields);
			});
		}
	};

	const handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		let tempTemplate = template;
		let tempError = error;
		tempTemplate[name] = value;
		setTemplate({...tempTemplate});

		if (value.trim() !== "") {
			tempError[name] = "";
			setError(tempError);
		}
	};

	const focusSubject = (event) => {
		try {
			setCursorposition(event.target.selectionStart);
		} catch (error) {
			console.error("Error in focusSubject() in salseTemplate.js : " + error);
		}
	};

	const handleStatusChange = (event, fieldName, value) => {
		let tempTemplate = template;
		setTemplate({ ...tempTemplate, [fieldName]: value });
	};

	const handleObjectChange = (event, fieldName, value) => {
		let sub = template.subject;
		let _email_message = template.email_message;
		let msg = "You have added mail merge fields. If you change the table name , the existing mail merge fields will not be resolved. Are you sure to continue ?"
		if (sub.includes("j_opportunities") == true || _email_message.includes("j_opportunities") == true ) {
			sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => onConfirmObjectChange(value), null);

		} else if (sub.includes("j_cases") == true || _email_message.includes("j_cases") == true) {
			sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => onConfirmObjectChange(value), null);

		} else if (sub.includes("j_leads") == true || _email_message.includes("j_leads") == true ) {
			sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => onConfirmObjectChange(value), null);

		} else {
			let _objArr = objArr;
			let temp = selectedBatchObjName;
			temp.object = value;
			setSelectedBatchObjName({ ...temp });
			let _object;
			setFroalaObject('');
			setSubChangeValue("");
			if (value == "Accounts") {
				_objArr = ["Accounts", "Contacts"]
				_object = "accounts";
			} else if (value == "Opportunities") {
				_objArr = ["Opportunities", "Contacts"]
				_object = "opportunities";

			} else if (value == "Cases") {
				_objArr = ["Cases", "Contacts"]
				_object = "cases";

			} else if (value == "Contacts") {
				_objArr = ["Contacts"]
				_object = "contacts";

			}
			setObjArr(_objArr)
			let objectTableId = OBJECT_TABLEID_MAP[getStringParam(_object)];
			let params = {}
			params.tableId = objectTableId
			let response = Promise.resolve(
				HTTPClient.get(endPoints.SALES_TEMPLATE.NEWTEMPLATE, params)
			);
			response.then((response) => {
				let temp = fieldListResp;
				temp.fieldList = response;
				setFieldListResp({ ...temp });
				let personalizeArray = {};
				let i;
				let j;
				let mergeFields;

				if (value == "Accounts") {
					mergeFields = response?.company_mail_merge_fields;
				} else if (value == "Contacts") {
					mergeFields = response?.contact_mail_merge_fields;
				} else if (value == "Opportunities") {
					mergeFields = response?.opportunity_mail_merge_fields;
				} else if (value == "Cases") {
					mergeFields = response?.case_mail_merge_fields;
				}
				for (i in mergeFields) {
					for (j in mergeFields[i]) {
						personalizeArray[j] = mergeFields[i][j];
					}
				}
				let tempPersonalizeFields = {
					'Agreement_Name': "Agreement Name",
					'Agreement_Link': "Agreement Link",
					...personalizeArray,
				};
				setMounted(true);
				setButtonVisible("none");
				setPersonalize_fields(tempPersonalizeFields);
			});
			let _element = document.querySelector('[id*="object_dropdown"]')

			if (_element != null) {
				if (selectedBatchObjName.object == "Contacts") {
					_element.style.display = 'none';
				} else {
					_element.style.display = 'block';

				}
			}
		}


	};

	const onConfirmObjectChange = (value) => {
		let _objArr = objArr;
		let temp = selectedBatchObjName;
		temp.object = value;
		setSelectedBatchObjName({ ...temp });
		let _object;
		setFroalaObject('');
		setSubChangeValue("");
		if (value == "Accounts") {
			_objArr = ["Accounts", "Contacts"]
			_object = "accounts";
		} else if (value == "Opportunities") {
			_objArr = ["Opportunities", "Contacts"]
			_object = "opportunities";

		} else if (value == "Cases") {
			_objArr = ["Cases", "Contacts"]
			_object = "cases";

		} else if (value == "Contacts") {
			_objArr = ["Contacts"]
			_object = "contacts";

		}
		setObjArr(_objArr)
		let objectTableId = OBJECT_TABLEID_MAP[getStringParam(_object)];
		let params = {}
		params.tableId = objectTableId
		let response = Promise.resolve(
			HTTPClient.get(endPoints.SALES_TEMPLATE.NEWTEMPLATE, params)
		);
		response.then((response) => {
			let temp = fieldListResp;
			temp.fieldList = response;
			setFieldListResp({ ...temp });
			let personalizeArray = {};
			let i;
			let j;
			let mergeFields;

			if (value == "Accounts") {
				mergeFields = response?.company_mail_merge_fields;
			} else if (value == "Contacts") {
				mergeFields = response?.contact_mail_merge_fields;
			} else if (value == "Opportunities") {
				mergeFields = response?.opportunity_mail_merge_fields;
			} else if (value == "Cases") {
				mergeFields = response?.case_mail_merge_fields;
			}
			for (i in mergeFields) {
				for (j in mergeFields[i]) {
					personalizeArray[j] = mergeFields[i][j];
				}
			}
			let tempPersonalizeFields = {
				'Agreement_Name': "Agreement Name",
				'Agreement_Link': "Agreement Link",
				...personalizeArray,
			};
			//setTemplate(tempTemplate);
			setMounted(true);
			setButtonVisible("none");
			setPersonalize_fields(tempPersonalizeFields);
		});
		let _element = document.querySelector('[id*="object_dropdown"]')

		if (_element != null) {
			if (selectedBatchObjName.object == "Contacts") {
				_element.style.display = 'none';
			} else {
				_element.style.display = 'block';

			}
		}
	}

	const showHideObjectIcon = () => {
		let _element = document.querySelector('[id*="object_dropdown"]')
		let tableIdOnEdit = fieldListResp?.fieldListAtEdit?.template_details?.tableId
		if (_element != null) {
			if ((id > 0 && tableIdOnEdit == 9) || (id == 0 && selectedBatchObjName.object == "Contacts")) {
				_element.style.display = 'none';
			}
			else {
				_element.style.display = 'block';

			}

		}
	}

	const openNoteTypeList = (event) => {
		event.preventDefault();
		setIsOpenNoteTypeList(true);
		setAnchorELNoteTypeList(event.currentTarget);
	};
	const openObjectTypeList = (event) => {
		event.preventDefault();
		setIsOpenObjectTypeList(true)
		setAnchorELNoteTypeList(event.currentTarget);
	};
	const handleMoreActionChange = (value) => {
		let hasPermission = hasAccessPermission(
			object,
			constants.SECURITY_LEVEL_TYPE_PERMISSION,
			constants.PERMISSION_NAME_MANAGE_TEMPLATES
		);

		setMoreActionOpen(false);
		switch (value) {
			case "Copy":
				if (hasPermission) {
					let tempTemplate = template;
					tempTemplate.id = 0;
					tempTemplate.name =
						getLocalizedStrings().label.SALES_TEMPLATE.COPY_OF +
						"_" +
						template.name;
					tempTemplate.agreementType = template.agreementType;
					setTemplate(tempTemplate);
					setButtonVisible("none");
				} else {
					sfDialogs.alert(
						getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
						getLocalizedStrings().message.COMMON.ACCESS_DENIED,
						null
					);
				}
				break;
			case "Delete":
				if (hasPermission) {
					sfDialogs.confirm(
						getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
						getLocalizedStrings().message.SALES_TEMPLATE.DELETE,
						deleteTemplate.bind(),
						null
					);
				} else {
					sfDialogs.alert(
						getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
						getLocalizedStrings().message.COMMON.ACCESS_DENIED,
						null
					);
				}
				break;
			case "Preview":
				window.open("/#previewemailtemplate/" + template.id);
				break;
			default:
				return null;
		}
	};

	const handleSelectPersonilizeChange = (fieldName, value) => {
		let tempTemplate = template;
		tempTemplate[fieldName] = value;
		let front = tempTemplate["subject"].substring(0, cursorposition);
		let back = tempTemplate["subject"].substring(
			cursorposition,
			tempTemplate["subject"].length
		);
		template["subject"] = front + " ${" + value + "}" + back;
		let tempCursorPosition = tempTemplate["subject"].length;
		setTemplate({...tempTemplate});
		setPersonilizeOpen(false);
		setCursorposition(tempCursorPosition);
		setIsOpenNoteTypeList(false);
	};

	const handleModelChange = (event) => {
		console.log("handleModelChange",event)
		let tempTemplate = template;
		tempTemplate["email_message"] = event;
		setTemplate(tempTemplate);
	};

	const saveTemplate = () => {
		if (!validateForm()) {
			return false;
		}

		let _object = selectedBatchObjName.object;
		_object = _object.toLowerCase();
		let _tableName = tableName.toLowerCase();
		let objectTableId = id == 0 ? OBJECT_TABLEID_MAP[getStringParam(_object)] : OBJECT_TABLEID_MAP[getStringParam(_tableName)];
		let params = {
			id: template.id,
			name: template.name,
			subject: template.subject,
			email_message: template.email_message,
			status: template?.status ? template.status : 'Active',
			tableId: objectTableId
		};

		if(template?.agreementType == 'Agreement Cover Letter') {
			params = {...params, agreement_type: template.agreementType};
		}

		let response = Promise.resolve(
			HTTPClient.post(endPoints.SALES_TEMPLATE.SAVE, params)
		);
		setMounted(false);
		response.then((response) => {
			if (response.status === 0) {
				if (params.id === 0) {
					let tempTemplate = template;
					tempTemplate.id = response.data.id[0];

					setButtonVisible("none");
					setTemplate(template);
				}
				// --------------store template in redux-------------------------
				let templateList = getArrayParam(sendMail.templateList);
				if (templateList.length > 0) {
					let templateListObj = getObjectParam(templateList[0]);

					let salesTempList = getArrayParam(templateListObj["Sales Template"]);
					let mySalesTempList = getArrayParam(
						templateListObj["My Sales Template"]
					);
					let templateObj = {
						email_status: template.status,
						execution_time: null,
						id: template.id,
						name: template.name,
						preview_image_url: "",
						editor_type: template.editor_type,
					};

					if (params.id === 0) {
						if (template.status === constants.ACTIVE) {
							salesTempList.push(templateObj);
							mySalesTempList.push(templateObj);
						}
					} else {
						let salesTemplateObj = salesTempList.filter(
							(f) => f.id === params.id
						);
						if (salesTemplateObj.length > 0) {
							if (template.status === constants.ACTIVE) {
								salesTemplateObj[0].name = templateObj.name;
								salesTemplateObj[0].email_status = templateObj.email_status;
							} else {
								salesTempList = salesTempList.filter((f) => f.id !== params.id);
							}
						} else if (template.status === constants.ACTIVE) {
							salesTempList.push(templateObj);
						}

						let mySalesTemplateObj = mySalesTempList.filter(
							(f) => f.id === params.id
						);
						if (mySalesTemplateObj.length > 0) {
							if (template.status === constants.ACTIVE) {
								mySalesTemplateObj[0].name = templateObj.name;
								mySalesTemplateObj[0].email_status = templateObj.email_status;
							} else {
								mySalesTempList = mySalesTempList.filter(
									(f) => f.id !== params.id
								);
							}
						} else if (template.status === constants.ACTIVE) {
							mySalesTempList.push(templateObj);
						}
					}

					sortArrayObjectByProperty(salesTempList, "name");
					templateListObj["Sales Template"] = salesTempList;

					sortArrayObjectByProperty(mySalesTempList, "name");
					templateListObj["My Sales Template"] = mySalesTempList;

					templateList = new Array(templateListObj);

					dispatch(storeTemplateListInRedux(templateList));
				}

				// ------End template store in redux--------------

				dispatch(
					showCustomSnackBar(
						getLocalizedStrings().message.COMMON.SAVE,
						styles.snackbarBodyStyleSuccess,
						styles.snackBarStyleTop
					)
				);

				setMounted(true);
				setButtonVisible("inline-block");
				refreshListView(constants.SALES_TEMPLATE_OBJECT);
			} else if (response.status === -1 && response.error.message !== "") {
				dispatch(
					showCustomSnackBar(
						getLocalizedStrings().message.SALES_TEMPLATE.TEMPLATE_NAME_ERROR,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleLongMsg
					)
				);

				setMounted(true);
				setButtonVisible("none");
			}
		});
	};

	const deleteTemplate = () => {
		let params = {
			id: [template.id],
		};
		let response = Promise.resolve(
			HTTPClient.post(endPoints.SALES_TEMPLATE.DELETE, params)
		);

		setMounted(false);
		response.then((response) => {
			dispatch(
				showCustomSnackBar(
					getLocalizedStrings().message.SALES_TEMPLATE.TEMPLATE_DELETED,
					styles.snackbarBodyStyleSuccess,
					styles.snackBarStyleTop
				)
			);
			dispatch(getAppDrawer(false, null, null, null, null));
			setMounted(true);

			refreshListView(constants.SALES_TEMPLATE_OBJECT);
		});
	};

	const renderPersonalizeField = () => {
		return Object.keys(personalize_fields).map((itemKey, index) => {
			return (
				<MenuItem
					key={itemKey + index}
					value={itemKey}
					style={styles.popoverMenuItem}
					fullWidth={true}
					labelstyle={{ color: "#479ccf" }}
					underlinestyle={{ display: "none" }}
					autowidth={"true"}
					onClick={(fieldName, value) =>
						handleSelectPersonilizeChange("personalize", itemKey)
					}
				>
					{personalize_fields[itemKey]}
				</MenuItem>
			);
		});
	};

	const validateForm = () => {
		let isValid = true;
		if (template.name.trim() === "" && template.subject.trim() === "") {
			isValid = false;
			setError({ ...error, name: getLocalizedStrings().message.SALES_TEMPLATE.NAME_BLANK, subject: getLocalizedStrings().message.SALES_TEMPLATE.SUBJECT_BLANK });
		} else if (template.name.trim() === "") {
			isValid = false;
			setError({ ...error, name: getLocalizedStrings().message.SALES_TEMPLATE.NAME_BLANK });
		} else if (template.subject.trim() === "") {
			isValid = false;
			setError({ ...error, subject: getLocalizedStrings().message.SALES_TEMPLATE.SUBJECT_BLANK });
		}
		return isValid;
	};

	const testMail = () => {
		let params = {};
		params.email = "";
		params.frommail = app.me.email;
		params.fromname = app.me.name;
		params.subject = template.subject;
		params.templateBody = template.email_message;
		params.frompage = "sales_template";
		params.testMailType = 'sales';
		dispatch(
			getAppDialog(true, constants.TEST_MAIL_DIALOG, null, null, params, null)
		);
	};

	const getLibrary = (editor, type) => {
		if (type != "image") {
			editor.events.disableBlur();
			disableEditorBlurEffect();
		}

		let data = {
			editor: editor,
			contentStyle: { width: "80%", maxWidth: "none" },
			type: type,
			fullWidth: true,
			maxWidth: "md",
			is_library: true,
			is_attachment: false,
		};
		dispatch(
			getAppDialog(
				true,
				constants.LIBRARY_DIALOG,
				getLocalizedStrings().message.COMMON.LIBRARY_DIALOG_TITLE,
				handleLibraryResponse,
				data,
				null
			)
		);
	};

	const handleLibraryResponse = (obj) => {
		obj.editor.events.enableBlur();
		if (obj.type === "link" && obj.src !== "") {
			document.getElementsByName("href")[0].value = obj.src;
		} else if (obj.type === "image" && obj.src !== "") {
			obj.editor.image.insert(obj.src);
		}
		dispatch(
			getAppDialog(false, constants.LIBRARY_DIALOG, null, null, null, null)
		);
	};

	const getVideoInsertDialog = (editor, videoType) => {
		editor.events.disableBlur();
		let data = {
			editor: editor,
			contentStyle: { width: "80%", maxWidth: "none" },
			videoType: videoType,
		};
		let title = null;
		if (videoType === constants.VIDEO_TYPE_YOUTUBE) {
			title = getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE;
		} else if (videoType === constants.VIDEO_TYPE_WISTIA) {
			title = getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE;
		}
		dispatch(
			getAppDialog(
				true,
				constants.VIDEO_INSERT_DIALOG,
				title,
				(event) => handleVideoInsertResponse(event),
				data,
				null
			)
		);
	};

	const handleVideoInsertResponse = (obj) => {
		obj.editor.events.focus();
		dispatch(
			getAppDialog(false, constants.VIDEO_INSERT_DIALOG, null, null, null, null)
		);
		obj.editor.html.insert(
			'<a href="' +
			obj.video_link +
			'" target="_blank" class="editor_video-selector"><img  height="220" width="380" src="' +
			obj.thumbnail.url +
			'" title="' +
			obj.title +
			'" /></a>'
		);
		obj.editor.events.focus();
	};

	const expandEditor = () => {
		try {
			setIsFullScreenEditor(!isFullScreenEditor);
			if (isFullScreenEditor) {
				$("[id^=expand_editor]")
					.find($(".fa"))
					.removeClass("fa fa-expand")
					.addClass("fa fa-compress");
				setTimeout(function () {
					$("[id^=expand_editor]").addClass("fr-active");
				}, 500);
			} else
				$("[id^=expand_editor]")
					.find($(".fa"))
					.removeClass("fa fa-compress")
					.addClass("fa fa-expand");
		} catch (error) {
			console.error(
				"Error in 'MarketingTemplate.js -> expandEditor()':" + error
			);
		}
	};

	const getSchedulerLink = (event) => {
		try {
			let slug = app.appointment_scheduler_slug;
			if (slug === null) {
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					getLocalizedStrings().message.SCHEDULER.NO_SCHEDULER,
					null
				);
			} else {
				event.html.insert(
					" [Start Scheduler Link] Book an Appointment [End Scheduler Link] "
				);
			}
		} catch (error) {
			console.error(
				"Error in 'SalesTemplate.js -> getSchedulerLink()':" + error
			);
		}
	};

	const getMoreMenuList = () => {
		let moreMenuList = {
			Copy: getLocalizedStrings().label.COMMON.COPY,
			Delete: getLocalizedStrings().label.COMMON.DELETE,
			Preview: getLocalizedStrings().label.COMMON.PREVIEW,
		};
		let menuList = []
		Object.keys(optionvalue).map((itemKey) => {
			let data = {};
			data.label = moreMenuList[optionvalue[itemKey]];
			data.value = itemKey;
			menuList.push(data);
			return 0;
		});
		setMoreMenuList(menuList)
	};

	const getAuditSurveyLink = (event) => {
		event.html.insert("${audit_survey_link}");
	};
	const getSurveyLink = (event) => {
		event.html.insert("${Thumbs_Survey}");
	};

	const froalaDropDownArrList = () => {
		let arr = []

		let defaultOptions = [{ value: "[Start Opt-in Link] Enter your display text [End Opt-in Link]", label: "Opt-in Link" },
		{ value: "[Start Opt-out Link] Enter your display text [End Opt-out Link]", label: "Opt-out Link" }]

		let personalizeArray = {};
		let i;
		let j;
		let mergeFields;
		let _fieldList = id > 0 ? fieldListResp.fieldListAtEdit : fieldListResp.fieldList;
		if (froalaObject == "Accounts") {
			mergeFields = _fieldList?.company_mail_merge_fields;
		} else if (froalaObject == "Contacts") {
			mergeFields = _fieldList?.contact_mail_merge_fields;
		} else if (froalaObject == "Opportunities") {
			mergeFields = _fieldList?.opportunity_mail_merge_fields;
		} else if (froalaObject == "Cases") {
			mergeFields = _fieldList?.case_mail_merge_fields;
		}


		for (i in mergeFields) {
			for (j in mergeFields[i]) {
				personalizeArray[j] = mergeFields[i][j];
			}
		}
		let _personalizeFields = [];
		if (froalaObject == '' && subChangeValue !== "Contacts") {
			Object.keys(personalize_fields).forEach(function (key, index) {
				_personalizeFields.push({ value: key, label: personalize_fields[key] })
			})
		}
		if (id == 0 && froalaObject == '' && subChangeValue == "Contacts") {
			if (selectedBatchObjName.object == "Accounts") {
				mergeFields = _fieldList?.company_mail_merge_fields;
			} else if (selectedBatchObjName.object == "Contacts") {
				mergeFields = _fieldList?.contact_mail_merge_fields;
			}
			else if (selectedBatchObjName.object == "Opportunities") {
				mergeFields = _fieldList?.opportunity_mail_merge_fields;
			} else if (selectedBatchObjName.object == "Cases") {
				mergeFields = _fieldList?.case_mail_merge_fields;
			}

			for (i in mergeFields) {
				for (j in mergeFields[i]) {
					personalizeArray[j] = mergeFields[i][j];
				}
			}
			Object.keys(personalizeArray).forEach(function (key, index) {
				_personalizeFields.push({ value: key, label: personalizeArray[key] })
			})
		} else if (id > 0 && froalaObject == '' && subChangeValue == "Contacts") {
			if (tableName == "Accounts") {
				mergeFields = _fieldList?.company_mail_merge_fields;
			} else if (tableName == "Contacts") {
				mergeFields = _fieldList?.contact_mail_merge_fields;
			}
			else if (tableName == "Opportunities") {
				mergeFields = _fieldList?.opportunity_mail_merge_fields;
			} else if (tableName == "Cases") {
				mergeFields = _fieldList?.case_mail_merge_fields;
			}

			for (i in mergeFields) {
				for (j in mergeFields[i]) {
					personalizeArray[j] = mergeFields[i][j];
				}
			}
			Object.keys(personalizeArray).forEach(function (key, index) {
				_personalizeFields.push({ value: key, label: personalizeArray[key] })
			})
		}
		else {
			Object.keys(personalizeArray).forEach(function (key, index) {
				_personalizeFields.push({ value: key, label: personalizeArray[key] })
			})
		}

		arr = [...defaultOptions, ..._personalizeFields]

		return arr;
	}
	const changeSubjectListAtEdit = ((event, value) => {
		setIsOpenObjectTypeList(false)
		setSubChangeValue(value)
		let personalizeArray = {};
		let i;
		let j;
		let mergeFields;
		let _fieldList = fieldListResp.fieldListAtEdit;
		if (value == "Accounts") {
			mergeFields = _fieldList?.company_mail_merge_fields;
		} else if (value == "Contacts") {
			mergeFields = _fieldList?.contact_mail_merge_fields;
		} else if (value == "Opportunities") {
			mergeFields = _fieldList?.opportunity_mail_merge_fields;
		} else if (value == "Cases") {
			mergeFields = _fieldList?.case_mail_merge_fields;
		}
		for (i in mergeFields) {
			for (j in mergeFields[i]) {
				personalizeArray[j] = mergeFields[i][j];
			}
		}
		let tempPersonalizeFields = {
			'Agreement_Name': "Agreement Name",
			'Agreement_Link': "Agreement Link",
			...personalizeArray,
		};
		setMounted(true);
		setButtonVisible("none");
		setPersonalize_fields(tempPersonalizeFields);
	})
	const changeSubjectList = (event, value) => {
		setIsOpenObjectTypeList(false);
		setSubChangeValue(value)
		let personalizeArray = {};
		let i;
		let j;
		let mergeFields;
		let _fieldList = fieldListResp.fieldList;
		if (value == "Accounts") {
			mergeFields = _fieldList?.company_mail_merge_fields;
		} else if (value == "Contacts") {
			mergeFields = _fieldList?.contact_mail_merge_fields;
		} else if (value == "Opportunities") {
			mergeFields = _fieldList?.opportunity_mail_merge_fields;
		} else if (value == "Cases") {
			mergeFields = _fieldList?.case_mail_merge_fields;
		}
		for (i in mergeFields) {
			for (j in mergeFields[i]) {
				personalizeArray[j] = mergeFields[i][j];
			}
		}
		let tempPersonalizeFields = {
			'Agreement_Name': "Agreement Name",
			'Agreement_Link': "Agreement Link",
			...personalizeArray,
		};
		setMounted(true);
		setButtonVisible("none");
		setPersonalize_fields(tempPersonalizeFields);
	}

	const froalaDropdown = (event, obj) => {
		setFroalaObject(obj);
		setIsOpenObjectDropDown(false)
	}
	const froalaPersonliseDropdown = (event, obj) => {
		let val = obj.value;
		if (
			val.indexOf("[Start Opt-in Link]") > -1 ||
			val.indexOf("[Start Opt-out Link]") > -1
		) {
			froalaObjInBody.html.insert(val)
			let _htmlModule = froalaObjInBody.html.get();
			setTemplate({...template ,email_message: _htmlModule});
		} else {
			froalaObjInBody.html.insert(" ${" + val + "} ")
			let _htmlModule = froalaObjInBody.html.get();
			setTemplate({...template ,email_message: _htmlModule});
			}
	
		setIsOpenPersonalize(false)
	}

	let height = window.innerHeight - 150;
	let top = (height - 10) / 2;
	if (!mounted) {
		return (
			<div style={{ width: "100%", height: height }}>
				<div
					className="asset-loaderh"
					style={{ paddingTop: top, paddingLeft: "45%" }}
				>
					<div
						style={{
							...styles.assetLoaderContainer,
							height: 55,
							width: 55,
							padding: 6,
						}}
					>
						<ShowCircularProgress
							size={35}
							style={{ marginTop: "4", marginLeft: "4" }}
						/>
					</div>
				</div>
			</div>
		);
	}

	if (redirect) {
		setRedirect(false);
		return <Navigate push to={redirectUrl} />;
	}

	Froalaeditor.DefineIcon("object_dropdown", { NAME: "fa-duotone fa-table", template: "font_awesome" });
	Froalaeditor.RegisterCommand("object_dropdown", {
		title: "Table Name",
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			setIsOpenObjectDropDown(true);
		},
	});

	Froalaeditor.DefineIcon("personalize_dropdown", { NAME: "fa-duotone fa-gear", template: "font_awesome" });
	Froalaeditor.RegisterCommand("personalize_dropdown", {
		title: getLocalizedStrings().label.SALES_TEMPLATE.PERSONALIZE,
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		callback: function (cmd, val) {
			setIsOpenPersonalize(true);
			setFroalaObjInBody(this)
				
		},
	
	});
		
	// Froalaeditor.DefineIcon("personalize_dropdown", { NAME: "fa-duotone fa-gear", template: "font_awesome" });
	// Froalaeditor.RegisterCommand("personalize_dropdown", {
	// 	title: getLocalizedStrings().label.SALES_TEMPLATE.PERSONALIZE,
	// 	type: "dropdown",
	// 	focus: false,
	// 	undo: true,
	// 	refreshAfterCallback: true,

	// 	options: {
	// 		"[Start Opt-in Link] Enter your display text [End Opt-in Link]":
	// 			"Opt-in Link",
	// 		"[Start Opt-out Link] Enter your display text [End Opt-out Link]":
	// 			"Opt-out Link",...personalize_fields,
	// 	},
	// 	callback: function (cmd, val) {
	// 		console.log("val",val)
	// 		 val = val.replace('j_contacts.','.');
	// 		console.log("val",val)

	// 		if(val != null && val!= undefined && val !== ''){
	// 			if (
	// 				val.indexOf("[Start Opt-in Link]") > -1 ||
	// 				val.indexOf("[Start Opt-out Link]") > -1
	// 			) {
	// 				this.html.insert(val);
	// 			} else {
	// 				this.html.insert(" ${" + personalizeFiledname +"_"+ val + "} ");
	// 			}
	// 		}
			
	// 	},
	// 	// Callback on refresh.
	// 	refresh: function ($btn) { },
	// 	// Callback on dropdown show.
	// 	refreshOnShow: function ($btn, $dropdown) { },
	// });

	Froalaeditor.DefineIcon('coupon', { NAME: 'ticket', template: "font_awesome" });
	Froalaeditor.RegisterCommand('coupon', {
		title: getLocalizedStrings().label.COMMON.COUPONS,
		type: 'dropdown',
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		options: [],
		callback: function (cmd, val) {
			val = val.trim();
			this.html.insert(' ${coupon_code.' + val + '} ');
		},
		// Callback on refresh.
		refresh: function ($btn) {
		},
		// Callback on dropdown show.
		refreshOnShow: function ($btn, $dropdown) {
		}
	});
	Froalaeditor.DefineIcon('library', { NAME: 'image', template: "font_awesome" });
	Froalaeditor.RegisterCommand("library", {
		title: getLocalizedStrings().label.SALES_TEMPLATE.INSERT_IMAGE,
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			getLibrary(this, "image");
		},
	});

	Froalaeditor.DefineIcon('link_library', { NAME: 'file-archive-o', template: "font_awesome" });
	Froalaeditor.RegisterCommand("link_library", {
		title: getLocalizedStrings().label.MARKETING_TEMPLATE.SELECT_FROM_LIBRARY,
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			getLibrary(this, "link");
		},
	});

	Froalaeditor.DefineIcon("insertYouTubeVideo", {
		NAME: "fa-brands fa-youtube", template: "font_awesome"
	});
	Froalaeditor.RegisterCommand("insertYouTubeVideo", {
		title: getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE,
		focus: true,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			getVideoInsertDialog(this, constants.VIDEO_TYPE_YOUTUBE);
		},
	});

	Froalaeditor.DefineIcon("insertWistiaVideo", {
		SRC: "/asset/images/wistia.png",
		ALT: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
		template: "image",
	});
	Froalaeditor.RegisterCommand("insertWistiaVideo", {
		title: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
		focus: true,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			getVideoInsertDialog(this, constants.VIDEO_TYPE_WISTIA);
		},
	});

	Froalaeditor.DefineIcon('signature', { NAME: "fa-regular fa-file-signature", template: "font_awesome" });
	Froalaeditor.RegisterCommand("signature", {
		title: getLocalizedStrings().label.SALES_TEMPLATE.ADD_SIGNATURE,
		focus: false,
		undo: true,
		icon: "signature",
		refreshAfterCallback: true,
		callback: function () {
			this.html.insert(" ${Email_Signature} ");
		},
	});

	Froalaeditor.DefineIcon("expand_editor", { NAME: "expand", template: "font_awesome" });
	Froalaeditor.RegisterCommand("expand_editor", {
		title: getLocalizedStrings().label.MARKETING_TEMPLATE.EXPAND_EDITOR,
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			expandEditor(this, "link");
		},
	});
	Froalaeditor.DefineIcon("scheduler-editor", {
		NAME: "fas fa-calendar-minus",
		template: "font_awesome"
	});
	Froalaeditor.RegisterCommand("scheduler-editor", {
		title: getLocalizedStrings().label.COMMON.SCHEDULER_LINK,
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			getSchedulerLink(this);
		},
	});

	Froalaeditor.DefineIcon("audit_link", { NAME: "fas fa-wpforms", template: "font_awesome" });
	Froalaeditor.RegisterCommand("audit_link", {
		title: getLocalizedStrings().label.AUDIT_QUESTIONS.SURVEY_LINK,
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			getAuditSurveyLink(this);
		},
	});
	Froalaeditor.DefineIcon("survay_link", { NAME: "fas fa-link", template: "font_awesome" });
	Froalaeditor.RegisterCommand("survay_link", {
		title: "Insert Thumbs Up/Down Survey ",
		focus: false,
		undo: true,
		refreshAfterCallback: true,
		callback: function () {
			getSurveyLink(this);
		},
	});
	let FroalaConfig = {
		key: constants.FROALA_EDITOR_KEY,
		placeholderText: getLocalizedStrings().label.SALES_TEMPLATE.TYPE_HERE,
		height: document.body.clientHeight - 200,
		toolbarButtons: constants.EDITOR_TOOLBAR_CONFIGURATION,
		toolbarButtonsMD: constants.EDITOR_TOOLBAR_CONFIGURATION,
		toolbarButtonsSM: constants.EDITOR_TOOLBAR_CONFIGURATION,
		toolbarButtonsXS: constants.EDITOR_TOOLBAR_CONFIGURATION,
		colorsBackground: constants.EDITOR_TOOLBAR_BACKGROUND_COLOR_PALETTE,
		colorsText: constants.EDITOR_TOOLBAR_TEXT_COLOR_PALETTE,
		toolbarSticky: false,
		linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
		linkInsertButtons: ['linkEdit', 'linkRemove', 'link_library'],
		enter: FroalaEditor.ENTER_BR,
		iframe: true,
		htmlUntouched: true,
		attribution: false,
		inlineMode: false,
		imageEditButtons: [
			"imageAlign",
			"imageRemove",
			"imageLink",
			"imageDisplay",
			"imageAlt",
			"imageSize",
			"linkOpen",
			"linkEdit",
			"linkRemove",
		],
		tableEditButtons: [
			"tableHeader",
			"tableRemove",
			"|",
			"tableRows",
			"tableColumns",
			"-",
			"tableCells",
			"tableCellBackground",
			"tableCellVerticalAlign",
			"tableCellHorizontalAlign",
		],
		quickInsertButtons: [],
		imageUpload: false,
		imagePaste: false,
		fileUpload: false,
		fontSize: constants.EDITOR_FONT_SIZE,
		fontFamily: {
			"Adobe Garamond Pro": "Adobe Garamond Pro",
			"Arial,Helvetica,sans-serif": "Arial",
			"Futura Std": "Futura Std",
			"Georgia,serif": "Georgia",
			"Impact,Charcoal,sans-serif": "Impact",
			RobotoSegoe: "Roboto",
			"Tahoma,Geneva,sans-serif": "Tahoma",
			"'Times New Roman',Times,serif": "Times New Roman",
			"Verdana,Geneva,sans-serif": "Verdana",
		},
		fontFamilyDefaultSelection: "Roboto",
		fontFamilySelection: true,
		events: {
			"froalaEditor.initialized": function (e, editor) {
				$(".fr-command").on("click", function () {
					if ($(this).data("cmd") === "insertLink") {
						$('input[name="target"]').prop("checked", true);
					}
				});
			},
		},
		events: {
			'initialized': function () {
				showHideObjectIcon();
				window.WEBSPELLCHECKER.init({
					container: this.$iframe ? this.$iframe[0] : this.el
				});
			}
		},
		events: {
			focus: handleEditorFocus,
		},
		zIndex: 2501
	};


	let hasPermission = hasAccessPermission(
		object,
		constants.SECURITY_LEVEL_TYPE_PERMISSION,
		constants.PERMISSION_NAME_MANAGE_TEMPLATES
	);
	let saveBtnStyle = hasPermission
		? { ...styles.primaryButton, verticalAlign: "top" }
		: {
			...styles.primaryButton,
			verticalAlign: "top",
			backgroundColor: "#ffc5b3",
			cursor: "not-allowed",
		};
	const _froalaDropDownArrList = froalaDropDownArrList();
	let _objectArr = mainObjectArr.map((r, index) => {
		let label = r.label;
		return <MenuItem key={r.value} value={r.label} style={styles.popoverMenuItem} >{<div title={label} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }}>{label}</div>}</MenuItem>
	});
	return (
		<div style={styles.workAreaContainer}>
			<div style={{ paddingLeft: "26px", paddingRight: "26px" }}>
				{!isFullScreenEditor && (
					<div>
						<>
							<div style={{ ...stickyStyle, paddingTop: "25px", paddingLeft: '10px' }}>
								<Button
									onClick={() => saveTemplate()}
									style={saveBtnStyle}
									labelstyle={{ fontWeight: "normal" }}
									disabled={hasPermission ? false : true}
								>
									{getLocalizedStrings().label.COMMON.SAVE}
								</Button>
								<Button
									style={{
										...styles.secondaryButton,
										verticalAlign: "top",
									}}
									onClick={() => testMail()}
									labelstyle={{ fontWeight: "normal" }}
								>
									{getLocalizedStrings().label.SALES_TEMPLATE.TEST}
								</Button>
								<Button
									onClick={(e) => closeDrawer(e)}
									style={{ ...styles.secondaryButton, verticalAlign: "top", marginLeft: '3px' }}
									labelstyle={{ fontWeight: "normal" }}
								>
									{getLocalizedStrings().label.COMMON.CANCEL}
								</Button>

								<div
									style={{
										paddingRight: "20px",
										paddingTop: "0px",
										display: buttonVisible,
									}}
								>
									<div
										style={{
											...styles.togButton,
											backgroundColor: "#ffffff",
											height: "27px",
											width: "28px",
											border: "1px solid rgb(224,224,224)",
											marginLeft: "-46px",
										}}
									>
										<PopOver
											id={"sales-template-more"}
											key={"sales-template-more"}
											name={"sales-template-more"}
											buttonEndIcon={"more_vert"}
											buttonStyle={{
												fontSize: "20px",
												color: "#717171",
												cursor: "pointer",
												paddingTop: "3px",
												paddingLeft: "3px",
											}}
											labelposition={"before"}
											buttonLabel={""}
											options={moreMenuList}
											onclickAction={(value) => handleMoreActionChange(value)}
											isShowIcon={true}
											title={getLocalizedStrings().label.COMMON.MORE}
											buttonLineOrigin={[22, 20]}
										/>
									</div>
								</div>
							</div>
						</>
						<div>
							<div style={{ ...styles.row, marginTop: '10px', height: '50px' }}>
								<div style={{ width: '100%', marginRight: '10px' }}>
									<FormControl
										style={{ width: "100%" }}
										className="test"
										noValidate
										autoComplete="off"
									>
										<TextField
											variant="outlined"
											label={
												getLocalizedStrings().label.SALES_TEMPLATE.NAME_REQUIRED
											}
											fullWidth={true}
											name="name"
											className={"sf-fields-bg"}
											value={template.name}
											disabled = {id && (template.name === "Request Corrective Action" || template.name === "Submit Corrective Action")}
											onChange={(e) => handleChange(e)}
											error={error.name !== ""}
											helperText={error.name}
											autoFocus
											autoComplete="new-password"
											margin="dense"
											size="small"
										/>
									</FormControl>
								</div>
								<div style={styles.sf_8}>
									<SFAutoComplete
										key={"sf-auto-complete-object"}
										id={"object"}
										name={"object"}
										label="Table Name"
										menuItems={_objectArr}
										disabled={id == 0 ? false : true}
										onChange={(eventData, value) =>
											handleObjectChange(eventData, 'object', value,)
										}
										value={id == 0 && selectedBatchObjName?.object ? selectedBatchObjName?.object : id !== 0 ? tableName : 'Contacts'}
										underlineFocusStyle={styles.textField.underlineFocus}
										floatingLabelStyle={{
											...styles.textField.floatingLabel,
											top: "10px",
											left: "7px",
										}}
										inputStyle={styles.textField.input}
										menuItemStyle={styles.popoverMenuItem}
										style={styles.sfFormFieldContainer}
										//ref={fieldRefStatus}
										isFullHeight={false}
									/>
								</div>
								<div style={{ ...styles.sf_8, marginLeft: '10px' }}>

									<SFAutoComplete
										key={"sf-auto-complete-status"}
										id={"status"}
										name={"status"}
										label={getLocalizedStrings().label.SALES_TEMPLATE.STATUS}
										menuItems={statusArr}
										disabled={false}
										onChange={(eventData, value) =>
											handleStatusChange(eventData, 'status', value,)
										}
										value={template?.status ? template.status : 'Active'}
										underlineFocusStyle={styles.textField.underlineFocus}
										floatingLabelStyle={{
											...styles.textField.floatingLabel,
											top: "10px",
											left: "7px",
										}}
										inputStyle={styles.textField.input}
										menuItemStyle={styles.popoverMenuItem}
										style={styles.sfFormFieldContainer}
										ref={fieldRefStatus}
										isFullHeight={false}
									/>
								</div>

							</div>
							<div style={{ display: 'flex', height: '62px', marginTop: '17px', marginLeft: '10px' }}>
								<div style={{ width: '130%' }}>
									<FormControl
										style={{ marginBottom: "2%", width: '100%' }}
										className="test"
										noValidate
										autoComplete="off"
									>
										<TextField
											variant="outlined"
											label={
												getLocalizedStrings().label.SALES_TEMPLATE
													.SUBJECT_REQUIRED
											}
											fullWidth={true}
											name="subject"
											value={template.subject}
											className={"sf-fields-bg"}
											onClick={(e) => focusSubject(e)}
											onChange={(e) => handleChange(e)}
											error={error.subject !== ""}
											helperText={error.subject}
											autoComplete="new-password"
											margin="dense"
											size="small"
										/>
									</FormControl>
								</div>

								<div
									title={getLocalizedStrings().label.CAMPAIGNS.PERSONALIZE}
									style={{ width: "5%", marginTop: "15px", float: "right", zIndex: 1, marginLeft: '-35px' }} >
									<div
										style={{ marginTop: "10%" }}
										onClick={(e) => openNoteTypeList(e)} >
										<Icon
											style={{
												fontSize: "17px",
												color: "black",
												cursor: "pointer",
												marginTop: "1px",
												marginLeft: "-2px",
											}}>
											settings
										</Icon>
										<Icon
											style={{
												fontSize: "17px",
												color: "black",
												cursor: "pointer",
												marginTop: "1px",
											}}>
											keyboard_arrow_down
										</Icon>
									</div>
									<Menu
										name="noteTypeList"
										key={"sf-auto-complete-noteTypeList"}
										id={"noteTypeList"}
										style={{ height: "50%" }}
										anchorEl={anchorELNoteTypeList}
										anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
										targetOrigin={{ horizontal: "left", vertical: "top" }}
										open={isOpenNoteTypeList}
										onClose={() => setIsOpenNoteTypeList(false)}
									>
										{renderPersonalizeField()}
									</Menu>
								</div>
								{id == 0 && selectedBatchObjName.object != "Contacts" &&
									<div
										style={{}}
										onClick={(e) => openObjectTypeList(e)} >

										<Icon
											style={{

												color: "black",
												cursor: "pointer",
												marginTop: "15px",
											}}>
											keyboard_arrow_down
										</Icon>
									</div>
								}
								{id == 0 && selectedBatchObjName.object != "Contacts" && <Menu
									name="noteTypeList"
									key={"sf-auto-complete-noteTypeList"}
									id={"noteTypeList"}
									style={{ height: "50%" }}
									anchorEl={anchorELNoteTypeList}
									anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
									targetOrigin={{ horizontal: "left", vertical: "top" }}
									open={isOpenObjectTypeList}
									onClose={() => setIsOpenObjectTypeList(false)}

								>
									{objArr.map((obj) => {
										return <MenuItem value={obj} style={styles.popoverMenuItem} onClick={(e) => changeSubjectList(e, obj)}>
											<span style={{ color: subChangeValue !== '' && obj == subChangeValue ? app.me.background : subChangeValue == '' && selectedBatchObjName.object !== '' && obj == selectedBatchObjName.object ? app.me.background : '' }}>
												{obj}
											</span>
										</MenuItem>
									})}
								</Menu>}
								{id > 0 && tableName != "Contacts" &&
									<div
										style={{}}
										onClick={(e) => openObjectTypeList(e)} >

										<Icon
											style={{

												color: "black",
												cursor: "pointer",
												marginTop: "15px",
											}}>
											keyboard_arrow_down
										</Icon>
									</div>
								}
								{id > 0 && tableName.object != "Contacts" && <Menu
									name="noteTypeList"
									key={"sf-auto-complete-noteTypeList"}
									id={"noteTypeList"}
									style={{ height: "50%" }}
									anchorEl={anchorELNoteTypeList}
									anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
									targetOrigin={{ horizontal: "left", vertical: "top" }}
									open={isOpenObjectTypeList}
									onClose={() => setIsOpenObjectTypeList(false)}

								>
									{objArr.map((obj) => {
										return <MenuItem value={obj} style={styles.popoverMenuItem} onClick={(e) => changeSubjectListAtEdit(e, obj)}>
											<span style={{ color: subChangeValue !== '' && obj == subChangeValue ? app.me.background : subChangeValue == '' && tableName !== '' && obj == tableName ? app.me.background : '' }}>
												{obj}
											</span>
										</MenuItem>
									})}
								</Menu>}
							</div>
						</div>
					</div>

				)}
				<div style={{ marginTop: "11px", marginBottom: "50px", marginLeft: '10px', marginRight: '10px' }}>
					<FroalaEditor
						tag="textarea"
						model={template.email_message}
						onModelChange={(e) => handleModelChange(e)}
						config={FroalaConfig}
						ref={(ref) => froalaRef = ref}
					/>

					<Menu
						name="noteTypeList"
						key={"sf-auto-complete-noteTypeList"}
						id={"noteTypeList"}
						style={{ height: "50%" }}
						anchorEl={document.querySelector('[id*="object_dropdown"]')}
						anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
						targetOrigin={{ horizontal: "left", vertical: "top" }}
						open={isOpenObjectDropDown}
						onClose={() => setIsOpenObjectDropDown(false)}
					>
						{objArr.map((obj) => {
							return <MenuItem value={obj} style={styles.popoverMenuItem} onClick={(e) => froalaDropdown(e, obj)} >
								<span style={{
									color: froalaObject !== '' && obj == froalaObject ? app.me.background : id == 0 && froalaObject == '' && selectedBatchObjName.object !== '' && obj == selectedBatchObjName.object ? app.me.background :
										id > 0 && froalaObject == '' && tableName !== '' && obj == tableName ? app.me.background :
											''
								}}>
									{obj}
								</span>
							</MenuItem>
						})}
					</Menu>
					<Menu
						name="noteTypeList"
						key={"sf-auto-complete-noteTypeList"}
						id={"noteTypeList"}
						style={{ height: "50%" }}
						anchorEl={document.querySelector('[id*="personalize_dropdown"]')}
						anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
						targetOrigin={{ horizontal: "left", vertical: "top" }}
						open={isOpenPersonalize}
						onClose={() => setIsOpenPersonalize(false)}
					>
						{_froalaDropDownArrList.map((obj) => {
							return <MenuItem value={obj} style={styles.popoverMenuItem} onClick={(e) => froalaPersonliseDropdown(e, obj)}> {obj.label} </MenuItem>
						})}
					</Menu>
				</div>
			</div>
		</div>
	);
};

export default SalesTemplate;