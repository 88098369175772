
import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { isValidParam, isInvalidParam, isUrl } from '../../../services/helper/parameterVerifier';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { addNode } from '../../../services/actions/automationCampaignActions';
import { removeError } from '../../../services/helper/automationUtils';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import {useDispatch, useSelector} from 'react-redux';


const AutomationLinkClick = ({data,closeDrawer}) => {
    const dispatch = useDispatch();
    const automationCampaign = useSelector((state) => state.automationCampaign);
    const [websitevisitUrl,setWebsitevisitUrl] = useState('');


    useEffect(() => {
        if (isValidParam(data)) {
            let detailsArray = null;
            if (data.hasOwnProperty('details')) {
                detailsArray = data.details[0];
                if (isValidParam(detailsArray)) {
                    if (detailsArray.hasOwnProperty('websiteUrl')) {
                        setWebsitevisitUrl(detailsArray.websiteUrl);
                        
                    }
                }
            }
        }
    }, []);

    const changeWebsiteUrl = (event, value) => {
        if (isValidParam(value)) {
            setWebsitevisitUrl(value);           
        }
    }

    const saveUrl = () => {
        let nodeObject = data;
        let websiteUrl = websitevisitUrl;
        let dialogData = null;
        if (isInvalidParam(websiteUrl) && nodeObject.hasOwnProperty('actionName') &&
            nodeObject.actionName === constants.AUTOMATION_DESIGNER_WEBSITE_VISIT) {
            dialogData = {
                message: getLocalizedStrings().message.AUTOMATION_DESIGNER.ENTER_LINK,
                fieldId: 'websiteVisitUrl',
            }
            openDialog(dialogData);
        } else if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_WEBSITE_VISIT && !isUrl(websiteUrl)) {
            dialogData = {
                message: getLocalizedStrings().message.AUTOMATION_DESIGNER.PROVIDE_VALID_URL,
                fieldId: 'websiteVisitUrl',
            }
            openDialog(dialogData);
        } else if (nodeObject.actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK && websiteUrl !== "" &&
            isValidParam(websiteUrl) && !isUrl(websiteUrl)) {
            dialogData = {
                message: getLocalizedStrings().message.AUTOMATION_DESIGNER.PROVIDE_VALID_URL,
                fieldId: 'websiteVisitUrl',
            }
            openDialog(dialogData);
        } else {
            if (isValidParam(nodeObject)) {
                let detailsArray = null;
                if (nodeObject.hasOwnProperty('details')) {
                    if (websiteUrl !== "" && isValidParam(websiteUrl)) {
                        if (websiteUrl.indexOf('http://') < 0 && websiteUrl.indexOf('https://') < 0) {
                            websiteUrl = "http://" + websiteUrl;
                        }
                    }
                    detailsArray = nodeObject.details[0];
                    if (isValidParam(detailsArray) && detailsArray.hasOwnProperty('id')) {
                        detailsArray.websiteUrl = websiteUrl
                    } else {
                        detailsArray = {
                            id: 0,
                            websiteUrl: websiteUrl
                        }
                        nodeObject.details.push(detailsArray);
                    }
                    nodeObject = removeError(nodeObject);
                    dispatch(addNode(automationCampaign.data));
                    closeDrawer();
                } else {
                    if (websiteUrl !== null && websiteUrl !== undefined && websiteUrl !== "") {
                        if (websiteUrl.indexOf('http://') < 0 && websiteUrl.indexOf('https://') < 0) {
                            websiteUrl = "http://" + websiteUrl;
                        }
                        detailsArray = [];
                        let detailObj = {
                            id: 0,
                            websiteUrl: websiteUrl
                        }
                        detailsArray.push(detailObj);
                        nodeObject.details = detailsArray;
                    }
                    nodeObject = removeError(nodeObject);
                    dispatch(addNode(automationCampaign.data));
                    closeDrawer();
                }
            }
        }
    }

    const openDialog = (dialogData) => {
        dispatch(getAppDialog(true, constants.ALERT_DIALOG, getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, null, dialogData, constants.AUTOMATION_DESIGNER_OBJECT));
    }

    
        let campaignStatus = automationCampaign.data.status;
        let nodeObject = data;
        let floatingLabelText = getLocalizedStrings().label.AUTOMATION_DESIGNER.ENTER_WEB_PAGE_LINK_OPTIONAL;
        let actionName = null;
        if (isValidParam(nodeObject)) {
            if (nodeObject.hasOwnProperty('actionName')) {
                actionName = nodeObject.actionName;
                if (actionName === constants.AUTOMATION_DESIGNER_WEBSITE_VISIT) {
                    floatingLabelText = getLocalizedStrings().label.AUTOMATION_DESIGNER.ENTER_WEB_PAGE_LINK;
                }
            }
        }

        let infoTextForLinkclick = null;
        if (actionName !== null && actionName !== undefined && actionName !== '') {
            if (actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK) {
                infoTextForLinkclick = getLocalizedStrings().label.AUTOMATION_DESIGNER.LINK_CLICK_INFORMATION;
            } else if (actionName === constants.AUTOMATION_DESIGNER_WEBSITE_VISIT) {
                infoTextForLinkclick = null;
            }
        }
        return (
            <div style={{ paddingTop: '5px', paddingLeft: '20px', paddingRight: '20px' }}>

                <div style={{ paddingBottom: '6px' }}>
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            id="websiteVisitUrl"
                            value={websitevisitUrl}
                            inputprops={{maxlength: '256' }}
                            onChange={(e)=>changeWebsiteUrl(e,e.target.value)}
                            label={floatingLabelText}
                            InputLabelProps={{
                                style: {
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '96%',
                                }
                            }}
                            autoComplete="new-password"
                            margin='dense'
                            size='small'
                            autoFocus
                            className={"sf-fields-bg"}
                        />
                    </FormControl>
                </div>
                <div style={{ fontSize: '12px' }}>
                    {infoTextForLinkclick}
                </div>
                {
                    (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                    <div style={{ paddingTop: '10px' }}>
                        <Button
                            style={styles.listViewPrimaryButton}
                            onClick={()=>saveUrl()}
                        >{getLocalizedStrings().label.COMMON.SAVE}</Button>

                    </div>
                }
            </div>
        );
    }

export default AutomationLinkClick;
