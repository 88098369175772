import { actions } from '..//constants/actions';


function UpdateSalesAndRefundData(data){
    return {
        type: actions.UPDATE_SALES_AND_REFUND_DATA,
        payload: data
    }
}
function UpdateSalesAndRefundDetails(data){
    return {
        type: actions.UPDATE_SALES_AND_REFUND_DETAILS,
        payload: data
    }
}
export {UpdateSalesAndRefundData,UpdateSalesAndRefundDetails}