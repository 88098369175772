import React, { useState, useEffect } from 'react';
import { isValidParam, getStringParam, getObjectParam, getBooleanParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { setHeader } from '../../../services/actions/headerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import SFDataTable from './sfDataTable';
import * as sfDialogs from './sfDialogs';
import { useDispatch, useSelector } from "react-redux";
import PopOver from './PopOver';
import moment from 'moment';
import { updateAuditCategoryData } from '../../../services/actions/auditActions';

import {getUnitsWithMyClientId} from "../../../services/actions/unitsAction.js";
import { getAppCustomDrawer, getAppDialog } from '../../../services/actions/appContainerActions';
import { getActiveTab, updateTabInfo } from '../../../services/helper/sfTabManager';
import { sortArrayObjectByProperty, dateFormatFlatPicker, dateFormat } from '../../../services/helper/utils';
import ShowCircularProgress from '../components/circularProgress';
import AutocompleteMultiSelect from './AutoCompleteMultiselect';


import {
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Chip,
    TextField
} from '@mui/material';
import { CloseFullscreen } from '@mui/icons-material';


const PERIOD_ITEMS = ["Previous", "Last month", "Last Quarter", "Last Year", "Custom"];

const CustomDateInputStyle = () => {
    return <style> {
        ".form-control[readonly] {background-color: #fff !important;}"
    }</style>
}



const AuditReport = ({object,type}) => {
    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);

    const [units, setUnits] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);
    const [from_date, setFrom_date] = useState(null);
    const [to_date, setTo_date] = useState(null);
    const [sortBy, setSortBy] = useState('');
    const [sortByList,setSortByList] = useState([
        {name :'audit_date' , label:'Audit Date'},
        {name : 'unit', label :'Unit'},
        {name : 'total_score' , label : 'Total Score'}
    ])
    const [isMounted, setIsMounted] = useState(false);

    const [plan, setPlan] = useState({
        name :'Reports'
    });
    const [groupBy, setGroupBy] = useState('Unit');
    const [planOptions, setPlanOptions] = useState([]);
    const [reportData, setReportData] = useState({});
    const [showReport, setShowReport] = useState(false);
    const [loading, setLoading] = useState(false);
    const [headerPrefix, setHeaderPrefix] = useState(false);
    const [rowSort, setRowSort] = useState({
        isDescending: false,
        onSort: (fieldName, isDescending, rows)=> onSortFn (fieldName, isDescending, rows)
    });

    const auditCategory = useSelector((state) => state.auditDetails.auditCategory);

    useEffect(() => {
        let shouldFetchUnits = true;
        if(Object.keys(auditCategory).length !== 0){
            if (auditCategory.hasOwnProperty('availableParams')) {
                const availableParams = auditCategory.availableParams;
                if (availableParams && availableParams.hasOwnProperty('unitOptions')) {
                    setUnitOptions(availableParams.unitOptions);
                }
            }
            if (auditCategory.hasOwnProperty('selectedParams')) {
                const selectedParams = auditCategory.selectedParams;
                if (selectedParams) {
                    if (selectedParams.hasOwnProperty('from_date')) {
                        let _currDate = selectedParams.from_date;
                        setFrom_date(_currDate);
                    }
                    if (selectedParams.hasOwnProperty('to_date')) {
                        let _toDate = selectedParams.to_date;
                        setTo_date(_toDate);
                    }
                    if (selectedParams.hasOwnProperty('units')) {
                        setUnits(selectedParams.units);
                    }
                    if (selectedParams.hasOwnProperty('sortBy')) {
                        let _sortBy = selectedParams.sortBy;
                        if(!_sortBy){
                            setSortBy('unit');
                        }else{
                            setSortBy(selectedParams.sortBy);
                        }
                    }
                }
            }
            if (auditCategory.hasOwnProperty('reportData')) {
                setReportData(auditCategory.reportData);
                setShowReport(true);
            }
        }else{
            let currentDate = new Date();
            currentDate = moment(currentDate)
            currentDate = currentDate.format(dateFormat[app.me.date_format])
            setFrom_date(currentDate);
            setTo_date(currentDate);
        }
        if (shouldFetchUnits) {
            fetchUnits();
        }
        dispatch(setHeader(constants.AUDIT_REPORT, getLocalizedStrings().label.MODULE_ITEMS.AUDIT_SUMMARY_REPORT, null, false, true));
    }, []);

    useEffect(()=>{
        if(Object.keys(auditCategory).length === 0){
            if(!sortBy){
                setSortBy('unit');
            }
        }
    },[])


    function fetchUnits() {
        let promise = getUnitsWithMyClientId({});
        if (promise) {
            promise.then(response => {
                if (response) {
                    let tempUnits = [];
                    response.map((items)=>{
                        let data ={};
                        data.id = items.id;
                        data.label = items.company;
                        tempUnits.push(data)
                    })
                    tempUnits.sort(function(a, b){
                        if(a.label < b.label) { return -1; }
                        if(a.label > b.label) { return 1; }
                        return 0;
                    })
                    setUnitOptions(tempUnits);
                }
            });
        }
    }

    const sortArrayObjectByPropertyDesc = (array, property) => {
        let temp;
        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];
                    val1 = val1 instanceof String ? val1.toLowerCase() : val1;
                    val2 = val2 instanceof String ? val2.toLowerCase() : val2;
                    if (val1 > val2) {
                        temp= -1;
                    } else if (val1 < val2) {
                        temp= 1;
                    } else {
                        temp= 0;
                    }
                }
                return temp;
            });
        }
    }


    
    const onSortFn = (fieldName, isDescending, records) => {
        let tempRowSort = rowSort;
        let tempRecords = getArrayParam(records);
        let sortFieldName = getStringParam(fieldName);
        let activeTab = getActiveTab();
        let info = getObjectParam(activeTab.info);
       
        if(sortFieldName !== 'audit_date'){
            try {
                if (!getBooleanParam(isDescending)) {
                    sortArrayObjectByProperty(tempRecords, sortFieldName);
                    tempRowSort.isDescending = isDescending;//true;
                }
                else {
                    sortArrayObjectByPropertyDesc(tempRecords, sortFieldName);
                    tempRowSort.isDescending = isDescending;//false;
                }
            }
            catch (error) {
                console.error("Error in 'SMSReport.js -> onSort()':" + error);
            }
            info.isDescending = tempRowSort.isDescending;
            updateTabInfo(info);
            setRowSort(tempRowSort);
        }else{
            tempRecords.sort(function(a, b) {
                var c = new Date(a.audit_date);
                var d = new Date(b.audit_date);
                return c-d;
            });
            try {
                if(!getBooleanParam(isDescending)){
                    tempRowSort.isDescending = isDescending;
                }else{
                    tempRowSort.isDescending = isDescending;
                    tempRecords = tempRecords.reverse();
                }
            }
            catch (error) {
                console.error("Error in 'SMSReport.js -> onSort()':" + error);
            }
            info.isDescending = tempRowSort.isDescending;
            updateTabInfo(info);
            setRowSort(tempRowSort);
        }
    }

    function getReportData() {
        const params = {};
        let valid = true;
        if (units && units.length > 0) {
            params.units = units.map(u => u.label);
        } else {
            valid = false
        }
        if (from_date) {
            let currentDate = moment(from_date)
            currentDate = currentDate.format('YYYY-MM-DD')
            params.fromDate = currentDate;
        } else {
            valid = false
        }
        if (to_date) {
            let toDate = moment(to_date)
            toDate = toDate.format('YYYY-MM-DD')
            params.toDate = toDate;
        } 
        else {
            valid = false
        }
        if (sortBy && sortBy.trim() !== "") {
            params.sortBy = sortBy;
        } else {
            valid = false
        }

        if (!valid) {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_REPORT_V2.ALL_OPTIONS_REQUIRED, null);
            return;
        }
        setIsMounted(true);
        const promise = Promise.resolve(HTTPClient.post(endPoints.AUDIT_REPORT.GENERATE, params));
        if (promise) {
            promise.then(res => {
                setHeaderPrefix(groupBy === 'Period');
                setReportData(res);
                setShowReport(true);
                let data = {
                    availableParams: {unitOptions},
                    selectedParams: { plan,from_date,to_date, units, groupBy, sortBy},
                    reportData: res
                };
                dispatch(updateAuditCategoryData(data));
                setIsMounted(false);
            })
        }
    }

    const customEvent = (eventType, column, row, event) => {
        let data = {};
        data.eventType = eventType;
        data.column = column;
        data.row = row;
        let style = {};
        style.width = '80%';
        let type = constants.AUDIT_REPORT_DEATILS;
        let label = getLocalizedStrings().label.AUDIT_REPORT.AUDIT_SCORE_DETAILS;
        dispatch(getAppCustomDrawer(true, label, type, style, data, null, false));

    }

    function getReportView() {
        let view = [];
        let i = 1;
        if (!reportData.data.records || Object.keys(reportData.data.records).length === 0) {
            return getNoRecordContent();
        }else{
            let RecordArr = [],colmsFields ;
            let _records = reportData.data.records;
            _records.map((item,index)=>{
                let data = {}
                if(item.id){
                    data.id = item.id;
                }
                if(item.audit_date) {
                    data.audit_date = item.audit_date
                }
                if(item.audited_by){
                    data.audited_by = item.audited_by
                }
                if(item.company) {
                    data.unit = item.company
                }
                if(item.total_score){
                    data.total_score = item.total_score
                }
                RecordArr.push(data)
            })
            colmsFields = reportData.data.fields;
            colmsFields.map((item,index)=>{
                if(item.name === 'company'){
                    item.name = 'unit';
                    item.is_sortable = true;
                }
                if(item.name == "id"){
                    item.is_hidden = true;
                }
                if(item.name == "total_score"){
                    item.is_detail_link = true;
                    item.is_sortable = true;
                }
                if(item.name == "audit_date"){
                    item.is_sortable = true;
                }
                if(item.name == "audited_by"){
                    item.is_sortable = true;
                }
                return item;
                
            })
            view.push(<div style={{height:'21rem',overflowY:'scroll'}}>
                    <SFDataTable columns={colmsFields} rowSort={rowSort} rows={RecordArr} customEvent={(eventType, column, row, event) => customEvent(eventType, column, row, event)} />
                </div>)

            return view;
        }
        
    }

    function getNoRecordContent() {
        return (
            <div className="row" style={{ padding: '0px', display: "flex",marginLeft:'10px'}}>
                <h5 style={{ color: 'red' }}>{getLocalizedStrings().label.INXPRESS_REPORT.NO_RECORDS_FOUND}</h5>
            </div>
        )
    }
    const getSBList = () => {
        let List = sortByList;
        if (List !== undefined) {
            return List.map((item, i) => {
                return <MenuItem value={item.name} style={styles.popoverMenuItem} >{item.label}</MenuItem>;
            })
        }
    }
    const onChangeShortBy=(data)=>{
        setSortBy(data);
    }
    function getReportFilter() {
        let HHFormat = new RegExp("HH");
        let datePickerOptions = {
            enableTime: false,
            dateFormat: dateFormatFlatPicker[app.me.date_format],
            minuteIncrement: 1,
            static: true,
            time_24hr: HHFormat.test(app.me.date_format) ? true : false
        };

        let div = (
            <>
                <div className="row" style={{ padding: '0px', display: "flex", marginLeft: '-14px' }}>
                    <CustomDateInputStyle />
                    <div className="col-md-4" style={{ paddingLeft: '0px' ,width:'400px'}} >
                        <AutocompleteMultiSelect
                            value={units}
                            items={Object.keys(auditCategory).length !== 0 ? auditCategory?.availableParams?.unitOptions : unitOptions}
                            // getOptionDisabled={getOptionDisabled}
                            placeholderText={getLocalizedStrings().label.ROYALTY_REPORT_V2.UNIT}
                            selectAllLabel='All'
                            onChange={setUnits}
                        />
                    </div>
                    <div className="col-md-2" style={{ paddingLeft: '0px' }} >
                        <div
                            style={{ margin: "0", width: "100%" }}
                            className={"sf-form-field dateTimeDiv-start_date"}
                            id="dateholder"
                        >
                            <PopOver
                                id={'sf-date-time-picker1'}
                                className={'date-time anval'}
                                key={'fromDate'}
                                name={'from_date'}
                                btnType={'date'}
                                buttonStyle={{ fontSize: '16px' }}
                                containerStyle={{ lineHeight: 1 }}
                                buttonLabel={getLocalizedStrings().label.REPORT.START_DATE}
                                onChange={(e) => setFrom_date(e)}
                                value={from_date}
                                innerLabelStyle={{ padding: "14px 5px 2px 5px", fontSize: '15px' }}
                                height={47}
                                options={datePickerOptions}
                            />
                        </div>
                    </div>
                    <div className="col-md-2" style={{ paddingLeft: '0px' }} >
                        <div
                            style={{ margin: "0", width: "100%" }}
                            className={"sf-form-field dateTimeDiv-end_date"}
                            id="dateholder"
                        >
                            <PopOver
                                id={'sf-date-time-picker2'}
                                className={'date-time anval'}
                                key={'toDate'}
                                name={'to_date'}
                                btnType={'date'}
                                buttonStyle={{ fontSize: '16px' }}
                                containerStyle={{ lineHeight: 1 }}
                                buttonLabel={getLocalizedStrings().label.REPORT.END_DATE}
                                onChange={(e) => setTo_date(e)}
                                value={to_date}
                                innerLabelStyle={{ padding: "14 5 2 5", fontSize: '15px' }}
                                height={47}
                                options={{ ...datePickerOptions, minDate: from_date }}
                            />
                        </div>
                    </div>
                    <div className="col-md-2" style={{ paddingLeft: '0px' }}>
                        <FormControl variant="outlined" style={{ width: '100%' }} className='audit-select'>
                            <InputLabel id="sf-Auditreport-simple-select-timeline-outlined-label" className="sf-Auditreport">{getLocalizedStrings().label.ROYALTY_REPORT_V2.SORT_BY}</InputLabel>
                            <Select
                                key="sort-by"
                                name="sort-by"
                                labelId='sort-by-label'
                                id='sortByList'
                                value={sortBy}
                                size="small"
                                className={"sf-select-height"}
                                label={getLocalizedStrings().label.ROYALTY_REPORT_V2.SORT_BY}
                                onChange={(event) => onChangeShortBy(event.target.value)}
                            >
                                {getSBList()}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-1" style={{ paddingLeft: '0px', display: 'flex', width: '15%', height: '47px', marginLeft: '0px', alignItems: 'center',paddingLeft:'0rem' }}>
                        <Button
                            onClick={() => getReportData()}
                            style={{
                                ...styles.primaryButton,
                                minWidth: '56px',
                                width: '19%'
                            }}
                            title={getLocalizedStrings().label.COMMON.RUN}
                        > {getLocalizedStrings().label.COMMON.RUN}</Button>
                    </div>
                </div>
            </>
        )
        return div;
    }

    let tempStyle = { marginLeft: 42, width: '96%', paddingTop: 5, paddingLeft: 11};
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2
    return (
        <div style={tempStyle}>
            {getReportFilter()}
            {!isMounted ?
                <div style={{ marginTop:'30px',marginLeft:'-2%' }}>
                    {
                        showReport && (
                            getReportView()
                        )
                    }
                </div>
                :
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
export default AuditReport;
