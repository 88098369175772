import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';


function getUnits(params) {
    let url = null;
    var promise = null;
    try {
        url = endPoints.ACCOUNTS.UNITS_GET;
        promise = HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'unitsActions.js -> getUnits()':" + e); }
    return promise;
}

function franchiseLogin(projectId) {
    let url = null;
    var promise = null;
    try {
        url = endPoints.LOGIN.FRANCHISE_LOGIN_CRED;
        promise = HTTPClient.get(url, {franchise_tenant_id: projectId});
    } catch (e) { console.error("Error in 'unitsActions.js -> submitLoginCredential()':" + e); }
    return promise;
}

function getOnboardingUnits(params) {
    let url = null;
    var promise = null;
    try {
        url = endPoints.ACCOUNTS.ONBOARDING_UNITS_GET;
        promise = HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'unitsActions.js -> getOnboardingUnits()':" + e); }
    return promise;
}

function getUnitsWithMyClientId(params) {
    let url = null;
    let promise = null;
    try {
        url = endPoints.ACCOUNTS.UNITS_WITH_MYCLIENT_ID_GET;
        promise = HTTPClient.get(url, params);
    } catch (e) { console.error("Error in 'unitsActions.js -> getUnits()':" + e); }
    return promise;
}

export {
    getUnits,
    franchiseLogin,
    getOnboardingUnits,
    getUnitsWithMyClientId
}