import React, { useLayoutEffect } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { styles } from '../../../services/constants/styles';
import { USER_REPORT_TYPE } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import ShowCircularProgress from '../components/circularProgress';
import { isValidParam, getArrayParam, getStringParam } from '../../../services/helper/parameterVerifier';
import {dateFormat, dateFormatFlatPicker, timeZone, getDateObject } from '../../../services/helper/utils';
import moment from 'moment';
import 'flatpickr/dist/flatpickr.min.css';
import Flatpickr from 'react-flatpickr';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getSFFormFieldsPromise } from '../../../services/actions/sfFormActions';
import { getObjectLabelByObject } from '../../../services/helper/common';
import SFAutoComplete from '../components/sfAutoComplete';
import { useEffect, useState } from "react";
import { useSelector ,useDispatch} from "react-redux";
import { getFilters } from "../../../services/actions/filterMenuAction";
import { useRef,forwardRef,useImperativeHandle } from 'react';

let types = [
    {
        label: 'Tabular',
        id: USER_REPORT_TYPE.TABULAR
    },
    {
        label: 'Pivot',
        id: USER_REPORT_TYPE.PIVOT
    },
];

const UserReportStepOne = forwardRef(({setParentState,object,data, setErrorMessage },ref) => {
    let localizedStrings = getLocalizedStrings();
    const dispatch = useDispatch();
    const [mounted, setMounted] = useState(false);
    const [queries, setQueries] = useState(null);
    const [report, setReport] = useState(data);
    const [fields_options, setFields_options] = useState([]);
    const [fieldsLoader, setFieldsLoader] = useState(false);
    const [filterLoader, setFilterLoader] = useState(false);
    const [error, setError] = useState({
        name: ''
    });
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const appState = useSelector((state) => state.app);
    const filters = useSelector((state) => state.filters);
    const sfForm = useSelector((state) => state.sfForm);

    useEffect(() => {
        if(!mounted){
            getFiltersData(object);
            getFields();
        }
        setMounted(true)
      }, []);

    useImperativeHandle(ref, () => ({
        report
            }));


    const setData = (data) => {
        let _filters = getFilterOptions();
        let _report = report;
        _report.id = data.id;
        _report.reportName = data.reportName;
        _report.filterId = data.filterId;
        _report.filterType = data.filterType;
        _report.dateRange = data.dateRange;
        let dateRangeOptions = getDateRangeOptions();
        let range = dateRangeOptions.find(f => { return f.value === data.dateRange });
        _report.dateRangeLabel = isValidParam(range) ? range.label : '';
        _report.reportType = data.reportType;
        let type = types.find(f => { return f.id === data.reportType });
        _report.reportTypeLabel = isValidParam(type) ? type.label : '';
        let filter = _filters.find(f => { return f.id === data.filterId });
        _report.filterName = isValidParam(filter) ? filter.label : '';
        _report.rangeField = data.rangeField;
        let rangeField = fields_options.find(f => { return f.value === data.rangeField });
        _report.rangeFieldLabel = isValidParam(rangeField) ? rangeField.label : '';
        setMounted(true);
        if (data.dateRange == 'cust') {
            if (getStringParam(data.fromDate) !== "" && getStringParam(data.toDate) !== "") {
                let fromDate = moment(data.fromDate);
                fromDate = [getDateObject(fromDate.tz(timeZone[appState.me.timezone]).toObject())];
                let toDate = moment(data.toDate);
                toDate = [getDateObject(toDate.tz(timeZone[appState.me.timezone]).toObject())];
                _report.fromDate = fromDate[0];
                _report.toDate = toDate[0];
                setReport({..._report});
                } else {
                _report.fromDate = data.fromDate;
                _report.toDate = data.toDate;
                setReport({..._report});
            }
        }
        updateParentState();
        setReport({..._report});
    }

 const getFiltersData = (object) => {
        let _queries = filters.queries[object];
        if (!isValidParam(queries)) {
            let promise = Promise.resolve(getFilters(object));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        filters.queries[object] = response.queries;
                        setFilterLoader(true);
                        setQueries(response.queries);
                        setFilterLoader(true);
                        loadData();
                    } else {
                        setMounted(true);
                    }
                });
            }
        } else {
            setQueries(_queries);
            setFilterLoader(true);
            loadData();
            setMounted(true);    
        }
    }

    const loadData = () => {
        if (fieldsLoader && filterLoader) {
            setMounted(true);
            if (isValidParam(report)) {
                setData(report);
               }
        }
    }

    const handleChangeFilterTextField = (event) => {
        
        let _report = report;
        let tempError = error;
        if(event._reactName == "onChange")
        {
            let value = event.target.value;
            _report.reportName = value;
            if (value.trim() !== '') {
                tempError.name = "";
            }
           
            setReport({..._report});
            setError({...tempError});
            updateParentState();
           
            
        }
    }

    const getFilterOptions = () => {
        let tempQueries = getArrayParam(queries);
        let filters = tempQueries.map((el) => { return { label: el.name, id: el.id, value: el.id, type: el.type } });
        return filters;
       
    }

    const updateParentState = () => {
        if (setParentState !== undefined) {
            setParentState(report);    
        }
    }

    const setReportType = (event, value) => {
        if(event._reactName == "onClick")
        {
            let reportType = types.filter(f => f.label === value);
            let _report = report;
            _report.reportType = reportType[0].id;
            _report.reportTypeLabel = value;
             setReport({..._report});
              updateParentState();
        }
    }

    const handleFieldSelect = (event, value) => {
        if(event._reactName == "onClick")
        {
            let rangeField = fields_options.filter(f => f.label === value);
            let _report = report;
            _report.rangeField = rangeField[0].value;
            _report.rangeFieldLabel = value;
            setReport({..._report});
            updateParentState();
         }
    }

    const setFilterValue = (event, value) => {
          let tempFilters = getFilterOptions();
          let _report = report;
            let reportType = tempFilters.filter(f => f.label == value);
            _report.filterId = reportType[0].id;
            _report.filterName = value;
            _report.filterType = reportType[0].type;
            setReport({..._report});
            updateParentState();
         }

    const handleDateRangeSelect = (event, value) => {
       if(event._reactName == "onClick")
        {
            let _report = report;
            _report.dateRangeLabel = value;
            let dateRangeOptions = getDateRangeOptions()
            let dateRange = dateRangeOptions.filter(f => f.label === value);
            _report.dateRange = dateRange[0].value;
            if (dateRange[0].value === 'cust') {
                let today = [getDateObject(moment().tz(timeZone[appState.me.timezone]).toObject())];
                _report.fromDate = today;
                _report.toDate = today;
            }
            updateParentState();
             setReport({..._report});
          }
    }

    const handleDateChange = (name, momentVal) => {
        let _report = report;
        // date updating for tabular report
        if (name === 'fromDate') {
            setFromDate(momentVal[0]);
            let _fromDate = moment(momentVal[0]);
            _fromDate = _fromDate.format('YYYY-MM-DD')
            _report.fromDate = _fromDate;
        } else {
            setToDate(momentVal[0]);
            let _toDate = moment(momentVal[0]);
            _toDate = _toDate.format('YYYY-MM-DD')
            _report.toDate = _toDate;
        }
        setReport({ ..._report });
        validateDateRange(name === 'fromDate' ? momentVal[0] : fromDate, name === 'toDate' ? momentVal[0] : toDate);
    }

    const validateDateRange = (start, end) => {
        let _report = { ...report };
        const startDateObj = moment(start, 'YYYY-MM-DD');
        const endDateObj = moment(end, 'YYYY-MM-DD');
        let today = [getDateObject(moment().tz(timeZone[appState.me.timezone]).toObject())];

        if (endDateObj.valueOf() < startDateObj.valueOf()) {
            dispatch(showCustomSnackBar(localizedStrings.message.USER_REPORT.INVALID_START_END_DATE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            setErrorMessage(localizedStrings.message.USER_REPORT.INVALID_START_END_DATE)
            _report.name = today;
        } else {
            setErrorMessage(undefined)
            _report.name = '';
        }
        setReport({..._report});
        updateParentState();
    };

    const getFields = () => {
        let tempObject = isValidParam(object) ? object : [];
        
        let data = Object.assign({}, sfForm.data[tempObject]);
        if (Object.keys(data).length === 0) {
            const promise = getSFFormFieldsPromise(tempObject);
            if(promise) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        sfForm.data[tempObject] = Object.assign({}, response);
                        processFields(sfForm.data[tempObject]);
                    }
                });
            }
        } else {
            setMounted(true)
            processFields(sfForm.data[tempObject]);
        }
    }

    const processFields = (data) => {
        let fields = getArrayParam(data.fields);
        let fields_options = [{ label: '', value: '' }];
        fields.forEach((field) => {
            if (field.field_type === 'date' || field.field_type === 'datetime') {
                fields_options.push({ label: field.label, value: field.name });
            }
        });
        
        setFields_options(fields_options);
        setFieldsLoader(true);
        loadData();
    }

    const getDateRangeOptions = () => {
        return [{ "value": "", "label": '' },
        { "value": "td", "label": localizedStrings.label.DASHBOARD.TODAY },
        { "value": "yd", "label": localizedStrings.label.DASHBOARD.YESTERDAY },
        { "value": "tw", "label": localizedStrings.label.DASHBOARD.THIS_WEEK },
        { "value": "tm", "label": localizedStrings.label.DASHBOARD.THIS_MONTH },
        { "value": "tq", "label": localizedStrings.label.DASHBOARD.THIS_QUARTER },
        { "value": "ty", "label": localizedStrings.label.DASHBOARD.THIS_YEAR },
        { "value": "lw", "label": localizedStrings.label.DASHBOARD.LAST_WEEK },
        { "value": "lm", "label": localizedStrings.label.DASHBOARD.LAST_MONTH },
        { "value": "lq", "label": localizedStrings.label.DASHBOARD.LAST_QUARTER },
        { "value": "ly", "label": localizedStrings.label.DASHBOARD.LAST_YEAR },
        { "value": "cust", "label": localizedStrings.label.DASHBOARD.CUSTOM }];
    }

    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight-10) / 2;
    let tempFilters = mounted ? getFilterOptions() : false;
    let dateRangeOptions = getDateRangeOptions();
    let datePickerOptions = {
        enableTime: false,
            dateFormat: dateFormatFlatPicker[appState.me.date_format],
            
        };
        
    let dateRange = isValidParam(report.dateRange) ? report.dateRange : null;
    let daterangeClassName = isValidParam(report.dateRange) && report.dateRange === 'cust' ? 'col-sm-2 col-two' : 'col-sm-6 col-two';
    return (
        <div>
            {!mounted && <div style={{ width: '100%', height: pageHeight }}>
                        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                            </div>
                        </div>
                </div>}
            {mounted && <div>
                <div className="row">
                    <div className="col-sm-12">
                        <FormControl style={{ width: '100%',marginTop: "20px" }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                label={'Report Name (Required)'}
                                fullWidth={true}
                                name="name"
                                value = {report.reportName}
                                onChange={(event)=>handleChangeFilterTextField(event)}
                                error={error.name !== ''}
                                helperText={error.name}
                                autoFocus={true}
                                inputprops={{ maxLength: 64 }}
                                autoComplete="new-password"
                                margin='dense'
                                size='small'
                                className={"sf-fields-bg"}
                            /></FormControl>
                    </div>
                </div>
                <div className="row" style={{marginTop:'6px'}}>
                    <div className="col-sm-6 col-two">
                        <SFAutoComplete
                            key={'sf-auto-complete-report-type'}
                            id='sf-auto-complete-report-type'
                            name='sf-auto-complete-report-type'
                            disabled={data.id > 0 ? true : false}
                            label='Report Type (Required)'
                            itemValues={types.map(m => { return { id: m.value, label: m.label } })}
                            onChange={(event,value)=>setReportType(event,value)}
                            value = {types.find(v => v.id === report.reportType)}
                            inputprops={{ maxLength: 20 }}
                            underlineFocusStyle={styles.textField.underlineFocus}
                            floatingLabelStyle={{ ...styles.textField.floatingLabel, top: '9px', left: '7px' }}
                            inputStyle={styles.textField.input}
                            menuItemStyle={styles.popoverMenuItem}
                            style={{ ...styles.sfFormFieldContainer }}
                            isFullHeight={false} />
                    </div>
                    <div className="col-sm-6  col-two" >
                    <SFAutoComplete
                            key={'sf-auto-complete-filter'}
                            id='sf-auto-complete-filter'
                            name='sf-auto-complete-filter'
                            label={getObjectLabelByObject(object) + ' Filter (Required)'}
                            itemValues={tempFilters.map(m => { return { id: m.value, label: m.label } })}
                            onChange={(event,value)=>setFilterValue(event,value)}
                            value={tempFilters.find(v => v.id === report.filterId) || null}
                            inputprops={{ maxLength: 20 }}
                            underlineFocusStyle={styles.textField.underlineFocus}
                            floatingLabelStyle={{ ...styles.textField.floatingLabel, top: '10px', left: '7px' }}
                            inputStyle={styles.textField.input}
                            menuItemStyle={styles.popoverMenuItem}
                            style={{ ...styles.sfFormFieldContainer }}
                            isFullHeight={false} />
                    </div>
                </div>

                <div className="row" style={{ marginTop: '11px' }}>
                    <div className="col-sm-6 col-two">
                        <SFAutoComplete
                            key={'sf-auto-complete-date-field'}
                            id='sf-auto-complete-date-field'
                            name='sf-auto-complete-date-field'
                            label={'Report Date Field (Optional)'}
                            itemValues={fields_options.map(m => { return { id: m.value, label: m.label } })}
                            onChange={(event,value)=>handleFieldSelect(event,value)}
                            value={fields_options.find(v => v.value === report.rangeField)}
                            inputprops={{ maxLength: 20 }}
                            underlineFocusStyle={styles.textField.underlineFocus}
                            floatingLabelStyle={{ ...styles.textField.floatingLabel, top: '10px', left: '7px' }}
                            inputStyle={styles.textField.input}
                            menuItemStyle={styles.popoverMenuItem}
                            style={{ ...styles.sfFormFieldContainer }}
                            isFullHeight={false} />
                    </div>
                    <div className={daterangeClassName}>
                        <SFAutoComplete
                            key={'sf-auto-complete-date-range'}
                            id='sf-auto-complete-date-range'
                            name='sf-auto-complete-date-range'
                            className="sf-userreportone-range"
                            label={'Date Range '}
                            itemValues={dateRangeOptions.map(m => { return { id: m.value, label: m.label } })}
                            onChange={(event,value)=>handleDateRangeSelect(event,value)}
                            value={dateRangeOptions.find(v => v.value === report.dateRange)}
                            inputprops={{ maxLength: 20 }}
                            inputStyle={styles.textField.input}
                            menuItemStyle={styles.popoverMenuItem}
                            style={{ ...styles.sfFormFieldContainer }}
                            isFullHeight={false} />
                    </div>
                    {dateRange !== null && dateRange === 'cust' && <div className="col-sm-2" >
                        <div id="dateholder" style={{display:'grid', color: 'rgb(97, 97, 97)',  border: '1px solid #c4c4c4', borderRadius: '4px', background: '#fff',marginRight: '7px',marginTop: '8px'}}>  
                            <span style={{paddingLeft: "5px",height: "21px"}}>{localizedStrings.label.REPORT.START_DATE}</span>
                            <Flatpickr 
                                style={{ width: '100%', border: 'none',borderRadius: '4px' }}
                                onChange={(value)=>handleDateChange('fromDate',value)}
                                value={fromDate}
                                options={datePickerOptions}
                                className='flatpickr'
                            />
                        </div>
                    </div>
                    }
                    {dateRange !== null && dateRange === 'cust' && <div className="col-sm-2" >
                        <div id="dateholder" style={{display:'grid', color: 'rgb(97, 97, 97)',  border: '1px solid #c4c4c4', borderRadius: '4px', background: '#fff',marginRight: '7px',marginTop: '8px'}}>  
                            <span style={{paddingLeft: "5px",height: "21px"}}>{localizedStrings.label.REPORT.END_DATE}</span>
                            <Flatpickr 
                                style={{ width: '100%', border: 'none',borderRadius: '4px' }}
                                onChange={(value)=>handleDateChange('toDate',value)}
                                value={toDate}
                                options={datePickerOptions}
                                className='flatpickr'
                            />
                        </div>
                    </div>
                    }
                </div>

            </div>}
        </div>
    );
});

export default UserReportStepOne;  
