import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';
import { actions } from '..//constants/actions';
import { isValidParam } from '../helper/parameterVerifier';
import { WORKFLOW_SETUP } from '../constants/constants';

const getWorkflowSetupData = (params) => {
  return dispatch => {
    const promise = Promise.resolve(HTTPClient.get(endPoints.WORKFLOW_SETUP.GET_WORKFLOW, params));
    if (isValidParam(promise)) {
      promise.then((response) => {
        let wfSetupParams = { objectid: params.tableId}
        
        if(Object.keys(response).length > 0) {
          let stages = response.nodes.filter((node)=> node.details.detailStatusFlg !== WORKFLOW_SETUP.DELETED);
          const sortedNodes = stages.sort((a, b) => a.order_id - b.order_id);
          const data = {...response, nodes: sortedNodes};
          const p_emailMerge = HTTPClient.get(endPoints.WORKFLOW_SETUP.WORKFLOW_EMAIL_TEMPLATE_MERGE_FIELD, wfSetupParams);
          const p_accountMerge = HTTPClient.get(endPoints.WORKFLOW_SETUP.ACCOUNT_WORKFLOW_MERGE_FIELD, wfSetupParams);
          const p_customDate = HTTPClient.get(endPoints.WORKFLOW_SETUP.WORKFLOW_CUSTOM_DATE_FORM_ID, wfSetupParams);
          const p_allTaskList = HTTPClient.get(endPoints.WORKFLOW_SETUP.WORKFLOW_ALL_TASK_LIST, {});
          const p_twilioPhoneNumber = Promise.resolve(HTTPClient.get(endPoints.CALLING_INTEGRATION.OUTBOUND_PHONE_NUMBERS_GET, null));
          let promises = [p_emailMerge, p_accountMerge, p_customDate, p_allTaskList, p_twilioPhoneNumber];
          Promise.allSettled(promises).then((result) => {
            if(result?.length > 0) {
              dispatch({ 
                type: actions.GET_WORKFLOW_SETUP_DATA,
                payload: {
                  data: data,
                  selectedStage: data.nodes[0],
                  emailTemplateMergeFields: result[0].value,
                  accountMergeFields: result[1].value,
                  customDateFormFId: result[2].value,
                  taskList: result[3].value,
                  twilioPhoneNumbers: result[4].value,
                  stagesLength : (data.nodes).length,
                }
              });
            }
          })
          .catch(err => console.log('error getWorkflowSetupData:-', err));
        }
      });
    }
  };
}

const getWorkflowEmailTemplates = (tableId) =>{
  return dispatch =>{
    let params = {};
    params.category_name = "Sales Template";
    params.type_id = 5;
    params.is_status_check = true;
    params.tableId = tableId;
    const promise = Promise.resolve(HTTPClient.get(endPoints.WORKFLOW_SETUP.GET_WORKFLOW_EMAIL_TEMPLATES, params));
      if (isValidParam(promise)) {
        promise.then((response) => {
            dispatch({
              type: actions.GET_WORKFLOW_EMAIL_TEMPLATE,
              payload: {
                emailTemplates: response,
              }
            });
        }).catch(err => console.log('error getWorkflowEmailTemplates:-', err));
      }
  }
        
 
}

const setisOpenEditTask = (value) =>{
  return dispatch =>{
    dispatch({
      type: actions.SET_IS_OPEN_EDIT_TASK,
      payload: {
        data: value,
      }
    });
  }
}

const setisAddNewTask = (value) =>{
  return dispatch =>{
    dispatch({
      type: actions.SET_IS_ADD_NEW_TASK,
      payload: {
        data: value,
      }
    });
  }
}

const setisaddNewTextMessage = (value) =>{
  return dispatch =>{
    dispatch({
      type: actions.SET_IS_ADD_NEW_TEXT_MESSAGE,
      payload: {
        data: value,
      }
    });
  }
}

const setisFocusOnEmailTask = (value) =>{
  return dispatch =>{
    dispatch({
      type: actions.SET_IS_FOCUS_ON_EMAIL_TASK,
      payload: {
        data: value,
      }
    });
  }
}
const setIsFocusOnMessageTask = (value) =>{
  return dispatch =>{
    dispatch({
      type: actions.SET_IS_FOCUS_ON_MESSAGE_TASK,
      payload: {
        data :  value,
      }
    })
  }
}

const setIsFocusOnAppointmentTask = (value) =>{
  return dispatch =>{
    dispatch({
      type : actions.SET_IS_FOCUS_ON_APPOINTMENT_TASK,
      payload : {
        data : value,
      }
    })
  }
}

const setIsFocusOnWebhook = (value) =>{
  return dispatch =>{
    dispatch({
      type : actions.SET_IS_FOCUS_ON_WEBHOOK,
      payload : {
        data : value,
      }
    })
  }
}

const setTaskListkOrderErrorId = (Errortaskid,isTaskerror) =>{
  return dispatch =>{
    dispatch({
      type : actions.SET_TASKLIST_ORDER_ERROR_ID,
      payload:{
        Errortaskid : Errortaskid,
        isTaskerror : isTaskerror
      }
    })
  }
}

const setisAddAppointmentTask = (value) =>{
  return dispatch =>{
    dispatch({
      type: actions.SET_IS_ADD_APPOINTMENT_TASK,
      payload: {
        data: value,
      }
    });
  }
}

const setisOpenEmailTask = (value) =>{
  return dispatch =>{
    dispatch({
      type: actions.SET_IS_OPEN_EMAIL_TASK,
      payload: {
        data: value,
      }
    });
  }
}

const setisEditEmailTask = (value) =>{
  return dispatch =>{
    dispatch({
      type: actions.SET_IS_EDIT_EMAIL_TASK,
      payload: {
        data: value,
      }
    });
  }
}

const setIsDndCardDroped = (value) =>{
  return dispatch =>{
    dispatch({
      type: actions.SET_IS_DND_CARD_DROPPED,
      payload: {
        data: value,
      }
    });
  }
}

const setShowRequiredField = (value) =>{
  return dispatch =>{
    dispatch({
      type: actions.SET_IS_SHOW_REQUIRED_FIELD,
      payload: {
        data: value,
      }
    });
  }
}

const setisShowWebhook = (value) =>{
  return dispatch =>{
    dispatch({
      type: actions.SET_IS_SHOW_WEBHOOK,
      payload: {
        data: value,
      }
    });
  }
}

const setisOpenEditWebhook = (value) =>{
  return dispatch =>{
    dispatch({
      type: actions.SET_IS_OPEN_EDIT_WEBHOOK,
      payload: {
        data: value,
      }
    });
  }
}

const setisEditTextMessage = (value) =>{
  return dispatch =>{
    dispatch({
      type: actions.SET_IS_EDIT_TEXT_MESSAGE,
      payload: {
        data: value,
      }
    });
  }
}

const setisEditAppoinmentTask = (value) =>{
  return dispatch =>{
    dispatch({
      type: actions.SET_IS_EDIT_APPOINMENT,
      payload: {
        data: value,
      }
    });
  }
}

const setisEditStages = (value) =>{
  return dispatch =>{
    dispatch({
      type: actions.SET_IS_EDIT_STAGES,
      payload: {
        isEdit: value,
      }
    });
  }
}

const setisShowActionsButtons = (value) =>{
  return dispatch =>{
    dispatch({
      type: actions.SET_IS_SHOW_ACTIONS_BUTTONS,
      payload: {
        data: value,
      }
    });
  }
}

const setEditIndex = (value) =>{
  return dispatch =>{
    dispatch({
      type: actions.SET_EDIT_INDEX,
      payload: {
        data: value,
      }
    });
  }
}

const setActions = (value) =>{
  return dispatch =>{
    dispatch({
      type: actions.SET_ACTIONS,
      payload: {
        data: value,
      }
    });
  }
}

const setSelectedWorkflowStage = (stage) => {
  return dispatch => {
    dispatch({
          type: actions.SET_WORKFLOW_SETUP_STAGE,
          payload: {
            selectedStage: stage
          }
        });
  }
}

const updateWorkflowStage = (stage) =>{
  return dispatch =>{
    dispatch({
      type: actions.UPDATE_WORKFLOW_STAGE,
      payload: {
        stage: stage,
      }
    });
  }
}

const closeWorkflowSetupDrawer = () => {
  return dispatch => {
    dispatch({
          type: actions.CLOSE_DRAWER,
        });
  }
}

const updateActionTaskInStage = (stage) => {
  return dispatch => {
    dispatch({
          type: actions.UPDATED_WORKFLOW_TASK_ACTION,
          payload: {
            updatedActionTask: stage
          }
        });
  }
}

const updateWorkflowSetupAddNewTask = (task, type) => {
  return dispatch => {
    dispatch({
          type: actions.WORKFLOW_ADD_NEW_TASK_ACTION,
          payload: {
            newActionTask: task,
            type: type
          }
        });
  }
}

const addNewTaskNameInTaskList = (taskList) =>{
  return dispatch => {
    dispatch({
          type: actions.ADD_NEW_TASKNAME_IN_TASKLIST,
          payload: {
            newTaskList: taskList,
          }
        });
  }
}

const copyNewTaskAction = (stage) => {
  return dispatch => {
    dispatch({
          type: actions.WORKFLOW_COPY_TASK_ACTION,
          payload: {
            newCopyActionTask: stage,
          }
        });
  }
}

const updateDndCard = (stage, type) =>{
  return dispatch => {
    dispatch({
          type: actions.UPDATE_DND_CARD,
          payload: {
            updateCards: stage,
            type: type
          }
        });
  }
}

const updateWorkflowStageDndCard = (stage) =>{
  return dispatch => {
    dispatch({
          type: actions.UPDATE_WORKFLOW_STAGE_DND_CARD,
          payload: {
            updateCards: stage,
          }
        });
  }
}

const updateWebhook = (webhook) =>{
  return dispatch => {
    dispatch({
          type: actions.UPDATE_WEBHOOK,
          payload: {
            updateWebhook: webhook
          }
        });
  }
}

const addNewWebhook = (webhook) =>{
  return dispatch => {
    dispatch({
          type: actions.WORKFLOW_ADD_NEW_WEBHOOK,
          payload: {
            newWebhook: webhook
          }
        });
  }
}

const deleteDndRowCards = (deletedRowCard, type) =>{
  return dispatch => {
    dispatch({
          type: actions.DElETE_DND_ROW_CARD,
          payload: {
            deletedRowCard: deletedRowCard,
            type: type
          }
        });
  }
}

const addValueInTaskList = (list) =>{
  return dispatch => {
    dispatch({
          type: actions.ADD_VALUE_IN_TASK_LIST,
          payload: {
            list: list,
          }
        });
  }
}

const setSelectedRequiredFields = (selectedFormfield) =>{
  return dispatch => {
    dispatch({
          type: actions.SET_SELECTED_REQUIRED_FIELDS,
          payload: {
            selectedFormfield: selectedFormfield,
          }
        });
  }
}

const removeWorkflowStage = (stage) =>{
    return dispatch =>{
      dispatch({
        type: actions.DELETE_WORKFLOW_STAGE,
        payload :{
          stage:stage,
        }
      })
    }
}

const AddNewWorkflowStage = (stage) =>{
  return dispatch =>{
    dispatch({
      type: actions.ADD_NEW_WORKFLOW_STAGE,
      payload :{
        stage:stage,
      }
    })
  }
}

export {
    getWorkflowSetupData,
    getWorkflowEmailTemplates,
    setSelectedWorkflowStage,
    updateWorkflowStage,
    setSelectedRequiredFields,
    setisOpenEditTask,
    setisAddNewTask,
    setisaddNewTextMessage,
    setisFocusOnEmailTask,
    setIsFocusOnMessageTask,
    setIsFocusOnAppointmentTask,
    setIsFocusOnWebhook,
    setTaskListkOrderErrorId,
    setisAddAppointmentTask,
    setisOpenEmailTask,
    setisEditEmailTask,
    setShowRequiredField,
    setisShowWebhook,
    closeWorkflowSetupDrawer,
    updateActionTaskInStage,
    updateWorkflowSetupAddNewTask,
    addNewTaskNameInTaskList,
    updateDndCard,
    addNewWebhook,
    updateWebhook,
    deleteDndRowCards,
    setisOpenEditWebhook,
    copyNewTaskAction,
    setisEditTextMessage,
    setisEditAppoinmentTask,
    setActions,
    setisEditStages,
    setisShowActionsButtons,
    setEditIndex,
    removeWorkflowStage,
    AddNewWorkflowStage,
    updateWorkflowStageDndCard,
    addValueInTaskList,
    setIsDndCardDroped
}