import React, { useRef, useCallback } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../services/constants/actions";
import { styles } from '../../../services/constants/styles';
import { setSelectedWorkflowStage,updateWorkflowStage } from '../../../services/actions/workflowSetupAction';
import { Icon } from '@mui/material';
import {setisEditStages,setisShowActionsButtons,setEditIndex,removeWorkflowStage,updateWorkflowStageDndCard} from '../../../services/actions/workflowSetupAction';
import update from 'immutability-helper'
import * as sfDialogs from '../components/sfDialogs';
import { updateOrderIds, updateStageOrderIds } from "../../../services/helper/utils";
import {showCustomSnackBar} from '../../../services/actions/snackBarAction';

const WorkflowStagesCard = ({key,index,id,wf}) =>{
    const dispatch = useDispatch();
    const ref = useRef(null)
    const { isEditStage, showActionButtons,isShowWebhook, editIndex,isEditTask,isAddNewTask,isaddNewTextMessage,isEditTextmessage,isAddAppointment,isEditApppointment,isOpenEmailTask,isEditWebhook,isEditEmailTask, data, selectedStage } = useSelector((state) => state.workFlowSetup);
    const { nodes } = data;

    const onChangeModuleItem = (event, wf) => {
        let value = event.target.value;
        wf.details.t_stages = value;
        dispatch(updateWorkflowStage(wf));
    }

    const saveWFItem = (wf, index) => {
        dispatch(setisEditStages(false));
        dispatch(setEditIndex(-1));
    }
    
    const handleEditAction = (_isEdit, ind, wf) => {
        dispatch(setisEditStages(_isEdit));
        dispatch(setEditIndex(ind));
        if (editIndex == ind) {
            dispatch(setisShowActionsButtons(false));
        }
    }

    const handleRemove = (wf) => {
      let confirmMsg = getLocalizedStrings().message.WORKFLOW_SETUP.DELETE_STAGE;
      sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, confirmMsg, () => onConfirmDelete(wf), null);
    }

    const onConfirmDelete = (wf) =>{
      let _wfArr = data.nodes.filter((f) => {
        return f.id != wf.id
      })
      dispatch(removeWorkflowStage(_wfArr));
    }
    const onChangeWorkflowStage = (wf) =>{
      if(isShowWebhook || isEditTask || isAddNewTask || isaddNewTextMessage || isAddAppointment || isOpenEmailTask ||isEditWebhook || isEditTextmessage || isEditApppointment || isEditEmailTask){
        let errorMessage;
        if(isShowWebhook){
          errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.WEBHOOK_OPEN;
        }else if(isEditTask){
          errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EDIT_ERROR;
        }else if(isAddNewTask){
          errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EDIT_ERROR;
        }else if(isaddNewTextMessage){
          errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.TEXT_MESSAGE_ERROR;
        }else if(isAddAppointment){
          errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.APPOINMENT_ERROR;
        }else if(isOpenEmailTask){
          errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.CHECK_EMAIL_TASK_OPEN;
        }else if(isEditWebhook){
            errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.WEBHOOK_OPEN;
        }else{
            errorMessage = getLocalizedStrings().message.WORKFLOW_SETUP.EDIT_ERROR;
          }
        dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));   
      }else{
        dispatch(setSelectedWorkflowStage(wf));
      }
  }


    const [, drop] = useDrop({
    accept: actions.WORKFLOW_STAGES_CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get horizontal middle
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      const dragRight = dragIndex === (hoverIndex - 1);
      const dragLeft = dragIndex === (hoverIndex + 1);
      if (dragRight && dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      if (dragLeft && dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = nodes[dragIndex]
      let rearrangeRows = update(nodes, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        });
        let updatedRows = updateStageOrderIds(rearrangeRows)
        dispatch(updateWorkflowStageDndCard(updatedRows));
      },[dispatch, nodes]
  )

  const [{ isDragging }, drag] = useDrag({
    type: actions.WORKFLOW_STAGES_CARD,
    item: {  id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  drag(drop(ref));

    let wfName = wf.details.t_stages;
    return (
        <>
        <div style={{ display: 'flex', marginTop: '5px',cursor:'grab' }} ref={ref}>
                    <div style={{ display: 'flex' }}>
                        {
                            isEditStage && editIndex == index ?
                                <input
                                    autoFocus={true}
                                    type="text"
                                    value={wfName}
                                    title={wfName}
                                    style={{ border: "1px solid #000", backgroundColor: "#fff", padding: "3px" }}
                                    onChange={(event) => onChangeModuleItem(event, wf)}
                                    onBlur={() => saveWFItem( wfName, index)}
                                />
                                :
                                showActionButtons && <div class={selectedStage.id === wf.id ? "selected_workflow_stage" : "workflow_stage_hover"} style={{border: '1px solid #000', borderEndStartRadius:'10px', cursor: 'grab'}}>
                                    <span style={{ padding: '5px' }} >
                                        <span style={{ padding: '5px' }}>
                                        <Icon key={`module-item-edit-$`} style={{ 'fontSize': '13px', cursor: 'pointer', color: '#717171' }} title={getLocalizedStrings().label.COMMON.EDIT} onClick={() => handleEditAction(true, index, wfName)}>edit</Icon>
                                    </span>
                                     
                                    <span onClick={()=>onChangeWorkflowStage(wf)} style={{ marginLeft: 'px', fontWeight: 'bold', fontSize: '16px'  }}>
                                        {wfName}
                                    </span>
                                        <Icon key={`module-item-delete-$`} style={{ 'fontSize': '13px', fontWeight: 'normal', color: '#717171', cursor: 'pointer', marginLeft: '5px' }} title={getLocalizedStrings().label.COMMON.DELETE}
                                            onClick={() => handleRemove(wf)}
                                        >
                                            close
                                        </Icon>
                                    </span>
                                </div>
                        }
                    </div>
        </div>
        </>
    )
}


export default WorkflowStagesCard;