import { isValidParam, getStringParam, getObjectParam, getIntParam, getBooleanParam, getArrayParam, getFloatParam } from '../../../services/helper/parameterVerifier';
import { setHeader } from '../../../services/actions/headerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import { FormControl, Icon, TextField } from '@mui/material';
import SFDataTable from '../components/sfDataTable';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { addTab, getActiveTab, TYPE_DETAIL_VIEW } from '../../../services/helper/sfTabManager';
import ShowCircularProgress from '../components/circularProgress';
import { updateComplianceReportData } from '../../../services/actions/complianceReportActions';
import { LinkToType } from '../../../services/constants/link';
import Pagination from '../components/pagination';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sortArrayObjectByPropertyOrderType } from '../../../services/helper/utils'


const ComplianceReport = ({ object }) => {

    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState("");
    const [isMounted, setIsMounted] = useState(false);
    const [fields, setFields] = useState([]);
    const [records, setRecords] = useState([]);
    const [rowSort, setrowSort] = useState({
        sortFieldName: 'franchisee',
        isDescending: false,
        onSort: (fieldName, isDescending,records) => onSortFn(fieldName, isDescending,records)
    });
    const [startIndex, setstartIndex] = useState(0);
    //const [pageSize, setpageSize] = useState(50);
    const [totalCount, setTotalCount] = useState(0);
    const [changeSize, setChangeSize] = useState({ pageSize: 50 });
    const [changeLog, setchangeLog] = useState({startIndex:0});
    const app = useSelector((state) => state.app);
    const report = useSelector((state) => state.report);
    const sfForm = useSelector((state) => state.sfForm);
    const complianceReport = useSelector((state) => state.complianceReport.complianceReportData);

    useEffect(() => {
        try {
            let reportData = getObjectParam(complianceReport);
            let getfromRedux = false;
            let tempRowSort = rowSort;
            if (reportData.hasOwnProperty('configParams')) {
                let configParams = getObjectParam(reportData.configParams);
                if (configParams.hasOwnProperty('search_text')) {
                    let tempSearchText = getStringParam(configParams.search_text);
                    if (isValidParam(tempSearchText) && tempSearchText !== '') {
                        getfromRedux = true;
                        setSearchText(tempSearchText);
                    }
                }
                if (configParams.hasOwnProperty('start_index')) {
                    let tempStartIndex = getIntParam(configParams.start_index);
                    if (isValidParam(tempStartIndex)) {
                        setstartIndex(tempStartIndex);
                        let log = changeLog;
                        log.startIndex = tempStartIndex;
                        setchangeLog({...log});
                    }
                }
                if (configParams.hasOwnProperty('page_size')) {
                    let tempPageSize = getIntParam(configParams.page_size);
                    if (isValidParam(tempPageSize)) {
                        let temp = changeSize;
                        temp.pageSize = tempPageSize;
                        setChangeSize(temp)
                    }
                }
            }

            if (getfromRedux) {
                if (reportData.hasOwnProperty('fields')) {
                    let tempFields = getArrayParam(reportData.fields);
                    setFields(tempFields);
                }
                if (reportData.hasOwnProperty('records')) {
                    let tempRecords = getArrayParam([...reportData.records]);
                    setRecords(tempRecords);
                }
                if (reportData.hasOwnProperty('total_count')) {
                    let tempTotalCount = getIntParam(reportData.total_count);
                    setTotalCount(tempTotalCount);
                }
                tempRowSort.isDescending = cookieGetSortData();
                setrowSort(tempRowSort);
                setIsMounted(true);
            } else {
                getReportData();
            }
            let activeTab = getObjectParam(getActiveTab());
            let label = activeTab.label;
            dispatch(setHeader(constants.COMPLIANCE_REPORT, label, null, false, true));
        } catch (error) {
            console.error("Error in 'complianceReport.js -> componentDidMount()'" + error);
        }

    }, []);

    const onSortFn = (fieldName, isDescending, _records) => {
        let tempRowSort = rowSort;
        let sortFieldName = getStringParam(fieldName);
        try {
            let tempRecordArr = _records;
            if (getBooleanParam(tempRowSort.isDescending)) {
                sortArrayObjectByProperty(_records, sortFieldName);

                tempRowSort.isDescending = false;
            } else {
                sortArrayObjectByPropertyDesc(_records, sortFieldName);
                tempRowSort.isDescending = true;
            }
            if (_records.length > 0) {
                _records = tempRecordArr;
            }
            cookieSetSortData(tempRowSort.isDescending);
            tempRowSort.sortFieldName = sortFieldName;
            setrowSort({...tempRowSort});
            setRecords(_records);
        }
        catch (error) {
            console.error("Error in complianceReport.js -> onSort()':" + error);
        }
    }

    const getReportData = () => {
        let records = [];
        let fields = [];
        let totalCount = 0;
        let reportParams = {};
        let url = endPoints.COMPLIANCE_REPORT.GET_REPORT_DATA;
        reportParams.search_text = searchText;
        reportParams.start_index = changeLog.startIndex;
        reportParams.page_size = changeSize.pageSize;
        let promise = Promise.resolve(HTTPClient.get(url, reportParams));
        setIsMounted(false);
        promise.then((response) => {
            if (isValidParam(response)) {
                records = getArrayParam(response.records);
                fields = getArrayParam(response.fields);
                totalCount = getIntParam(response.total_count);

                let rowSort = {
                    sortFieldName: 'franchisee',
                    isDescending: cookieGetSortData(),
                    onSort: (fieldName, isDescending) => onSortFn(fieldName, isDescending, records)
                }

                if (records.length > 0) {
                    let tempRecordArr = records;
                    if (getBooleanParam(rowSort.isDescending)) {
                        sortArrayObjectByPropertyDesc(tempRecordArr, rowSort.sortFieldName);
                    } else {
                        sortArrayObjectByProperty(tempRecordArr, rowSort.sortFieldName);
                    }
                    if (tempRecordArr.length > 0) {
                        records = tempRecordArr;
                    }
                }
                let objParams = { configParams: reportParams, fields: fields, records: records };
                dispatch(updateComplianceReportData(objParams));

                setTotalCount(totalCount);
                setIsMounted(true);
                setFields([...fields]);
                setRecords([...records]);
                setrowSort(rowSort);

            }

        });

    }

    const cookieSetSortData = (value) => {
        let userid = app.me.id;
        let cVarName = "isDescending_" + userid
        if (value) {
            document.cookie = cVarName + "=yes";
        } else {
            document.cookie = cVarName + "=no";
        }
    }

    const cookieGetSortData = () => {
        let userid = app.me.id;
        let cVarName = "isDescending_" + userid + "=";
        let cookieVar = decodeURIComponent(document.cookie).split(';')
        for (var i = 0; i < cookieVar.length; i++) {
            var c = cookieVar[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(cVarName) === 0) {
                var value = c.substring(cVarName.length, c.length);
                if (value === "yes") {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return "";
    }



    const sortArrayObjectByPropertyDesc = (array, property) => {
        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];

                    val1 = typeof val1 === "string" ? val1.toLowerCase() : val1;
                    val2 = typeof val2 === "string" ? val2.toLowerCase() : val2;

                    if (val1 > val2) {
                        return -1;
                    } else if (val1 < val2) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
        }
    }

    const downloadExcel = () => {
        let rows = getArrayParam(records);
        if (rows.length > 0) {
            let beforeHeaderComponent = excelGenerateBeforeHeaders();
            let headerComponents = excelGenerateHeaders();
            let rowComponents = excelGenerateRows();
            let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '</tbody></table>';
            let htmlData = htmlTable;
            if (isValidParam(htmlData) && htmlData !== '') {
                htmlData = htmlData.replace("%22", "\"");
                var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
                var downloadUrl = URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = downloadUrl;
                a.download = 'complianceResultSet_' + new Date().getTime() + '.xls';
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        } else {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.INXPRESS_REPORT.NO_RECORD_EXPORT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
    }

    const excelGenerateBeforeHeaders = () => {
        let cells = '';
        let reportName = getStringParam(getLocalizedStrings().label.COMPLIANCE_REPORT.COMPLIANCE_REPORT_LABEL);
        let columns = fields.filter(f => {
            if (!f.is_hidden) { return f; }
        });
        let columnsLength = getArrayParam(columns).length;

        cells = cells + '<tr><td  colspan="' + (columnsLength) + '"> ' + reportName + ' </td></tr>';
        return cells;
    }

    const excelGenerateHeaders = () => {
        let cells = '';
        let columns = fields.filter(f => {
            if (!f.is_hidden) { return f; }
        });
        columns.map(f => {
            cells = cells + '<th bgcolor="c2d7fc"> ' + f.label + ' </th>';
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    const excelGenerateRows = () => {
        let rows = records;
        let columns = getArrayParam(fields);
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            let colVal = '';
            rows.map((rowObject, rowIndex) => {
                let innerCells = '';
                columns.map((columnObject, colIndex) => {
                    if (!columnObject.is_hidden) {
                        colVal = isValidParam(rowObject[columnObject.name]) ? rowObject[columnObject.name] : '';
                        innerCells = innerCells + '<td >' + colVal + ' </td>';
                    }
                })
                if (rowIndex % 2 === 0) {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                } else {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                }

            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
        }
        return rowData;
    }

    const setSearchTextData = (event) => {
        let value = event.target.value
        try {
            value = getStringParam(value);
            setSearchText(value);
        } catch (error) {
            console.error("Error in 'complianceReport.js -> setSearchTextData()':" + error);
        }
    }

    const reloadDataBySearchTextKeyPress = (event) => {
        try {
            if (event.key === 'Enter') {
                getReportData();
            }
        } catch (error) {
            console.error("Error in 'complianceReport.js -> setSearchTextKeyPress()':" + error);
        }
    }

    const getReportFilter = () => {
        let data = {};
        data.object = object;
        data.total_count = totalCount;
        data.page_size = changeSize.pageSize;
        let listviewData = {};
        listviewData.data = data;
        let div = <div className="row" style={{ padding: '0px', display: 'flex', marginLeft: '-17px' }}>
            <div className="col-md-1" style={{ paddingLeft: '0px', width: '22%' }} >
                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                    <TextField
                        size='small'
                        variant="outlined"
                        key='search_text'
                        label={getLocalizedStrings().label.COMMON.SEARCH_PLACEHOLDER}
                        name="search_text"
                        className={"sf-fields-bg"}
                        style={{ width: '100%' }}
                        value={searchText}
                        onChange={(e) => setSearchTextData(e)}
                        onKeyPress={(e) => reloadDataBySearchTextKeyPress(e)}
                    />
                </FormControl>
            </div>
            <div className="col-md-1" style={{ paddingLeft: '0px', display: 'block', paddingTop: '5px' }}>
                <Icon onClick={() => { getReportData()}} title={getLocalizedStrings().label.REPORT.REFRESH} style={{ cursor: 'pointer', color: '#717171', paddingTop: '3px', fontSize: 21 }}>autorenew</Icon>
                <i onClick={() => downloadExcel()} className="far fa-file-excel" title="Excel" style={{ fontSize: '20px', color: 'green', cursor: 'pointer', paddingLeft: '5px' }} />
            </div>




            <div className='col-md-8' style={{ paddingLeft: '0px', display: 'block', marginLeft: '442px',marginTop:'-31px' }}>
                <div style={{ float: 'left', width: '80%', display: 'inline-block' }}></div>
                <div style={{ float: 'right', width: '20%', display: 'inline-block' }}>

                    <Pagination
                        totalRecordCount={totalCount}
                        object={object}
                        listView={listviewData}
                        isLookup={false}
                        listViewCallBack={(object, params) => getPaginationData(object, params)}
                    />


                </div>
            </div>

        </div >

        return div;
    }

    const getPaginationData = (object, params) => {
        try {
            if (isValidParam(params) !== null) {
                let _startIndex = params.hasOwnProperty("start_index") ? getIntParam(params.start_index) : 0;
                let _pageSize = params.hasOwnProperty("page_size") ? getIntParam(params.page_size) : 50;
                setstartIndex(_startIndex);
                let log = changeLog;
                log.startIndex = _startIndex;
                setchangeLog({ ...log });
                let temp = changeSize;
                temp.pageSize = _pageSize;
                setChangeSize(temp)
                getReportData();
            }
        }
        catch (error) {
            console.error("Error in 'complianceReport.js -> getPaginationData()':" + error);
        }
    }


    const getNoRecordContent = () => {
        return (<table className="layout_maintable" style={{ width: '100%' }}><tr><td><span style={{ color: 'red' }}>{getLocalizedStrings().label.INXPRESS_REPORT.NO_RECORDS_FOUND}</span></td></tr></table>);
    }

    const customEvent = (eventType, column, row, event) => {
        try {
            var accountRecordId = 0;
            var clientId = 0;
            row = getObjectParam(row);
            if ((column.name === 'active' && row.active !== 0) || (column.name === 'over_due' && row.over_due !== 0) || (column.name === 'cancelled' && row.cancelled !== 0) || column.name === 'franchisee') {
                accountRecordId = getIntParam(row['account_record_id']);
                clientId = getIntParam(row['project_id']);
            }

            if (accountRecordId > 0) {
                if (column.name === 'active' || column.name === 'over_due' || column.name === 'cancelled') {
                    openComplianceListView(column.name, accountRecordId, row.franchisee, row.project_id);
                } else if (column.name === 'franchisee') {
                    openUnitDetailView(accountRecordId, getStringParam(row['franchisee']));
                }
            }
        }
        catch (error) {
            console.error("Error in 'complianceReport.js -> customEvent()':" + error);
        }
    }

    const openComplianceListView = (columnName, accountId, franchisee, projectId) => {
        try {
            let cond = "";

            if (columnName === 'active') {
                cond = "( t_status = 'Active' AND next_renewal_date > NOW() AND t_projectid = " + projectId + ")";
            } else if (columnName === 'over_due') {
                cond = "( t_status = 'Active' AND next_renewal_date < NOW() AND t_projectid = " + projectId + ")";
            } else if (columnName === 'cancelled') {
                cond = "( t_status = 'Cancelled' AND t_projectid = " + projectId + ")";
            }

            let params = {
                name: 'All records',
                tableId: OBJECT_TABLEID_MAP[constants.COMPLIANCE_OBJECT]
            }
            var promise = Promise.resolve(HTTPClient.get(endPoints.REPORTS.QUERY_DETAIL, params));
            promise.then((response) => {
                let tabParams = {
                    "imgName": "fa fa-users",
                    "index": 0,
                    item: getActiveTab().item,
                    "info": {
                        "filter": {
                            "id": response.query_id,
                            "name": "All records",
                            "type": "all",
                        },
                        "input_param_sql": cond,
                        isReport: true,
                        reportFieldValue: "All",
                        reportLabel: 'Compliance',
                        parentId: accountId,
                        franchisee: franchisee,
                        isUnitsAccount: true,
                        UnitsAccountProjectId: projectId,
                    },
                    "isActive": false,
                    "label": 'Compliance',
                    "object": constants.COMPLIANCE_OBJECT,
                    "type": 'TYPE_LIST_VIEW',
                    "url": '/' + constants.COMPLIANCE_OBJECT + '/queries',

                };

                addTab(tabParams, true);
            });
        } catch (error) {
            console.error("Error in 'complianceReport.js -> openComplianceListView ()':" + error);
        }
    }

    const openUnitDetailView = (accountRecordId, unit) => {
        try {

            let linkUrl = '/' + constants.ACCOUNTS_OBJECT + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + accountRecordId;
            let activeTab = getObjectParam(getActiveTab());
            let activeTabIndex = getIntParam(activeTab.index);
            let info = getObjectParam(activeTab.info);

            let tab = { label: unit, object: constants.ACCOUNTS_OBJECT, type: TYPE_DETAIL_VIEW, imgName: null, url: linkUrl, isActive: true, parentTabIndex: activeTabIndex };
            addTab(tab, true);

        } catch (error) {
            console.error("Error in 'complianceReport.js -> openUnitDetailView()':" + error);
        }
    }
    let tempRecords = null;
    let style = { marginLeft: 42, width: '96%', paddingTop: 0, paddingLeft: 11 };
    style = { ...style, ...getObjectParam(style) };
    let noRecordsTitle = getNoRecordContent();
    tempRecords = getArrayParam(records);
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2;
    return (
        <div id="complianceReportDiv" style={style}>
            {isMounted ?
                <div>
                    <div>{getReportFilter()}</div>
                    <div id="divcontents" key={"divcontents" + rowSort.isDescending + "-" + rowSort.sortFieldName} style={{ marginLeft: '-17px', marginTop: '10px' }}>
                        {tempRecords.length > 0 ?
                            <div>
                                <SFDataTable columns={fields} rowSort={rowSort} rows={records} customEvent={(eventType, column, row, event) => customEvent(eventType, column, row, event)} />
                            </div>
                            :
                            noRecordsTitle
                        }
                    </div>

                </div>
                : <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
export default ComplianceReport;