import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants } from '../../../services/constants/constants';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TagAutocomplete from '../../sfPlugins/tagAutocomplete';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { isValidParam, getStringParam, isEmail, getIntParam, getArrayParam, getObjectParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import ShowCircularProgress from '../components/circularProgress';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { getSFFormFieldsPromise } from '../../../services/actions/sfFormActions';


const MarkPaidAllActionDailog = ({ object }) => {
    const dispatch = useDispatch();
    const [paymentList, setPaymentList] = useState([]);
    const [changeLog, setChangeLog] = useState({
        markAllStatus: "",
        markAllPaymentType: ''
    });
    const [isMounted, setIsMounted] = useState(false);

    const appContainer = useSelector((state) => state.appContainer);
    const detailView = useSelector((state) => state.detailView);

    useEffect(() => {
        try {
            if (isValidParam(object)) {
                let promise = getSFFormFieldsPromise(constants.PAYMENTS_OBJECT);
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            let paymentTypeField = response?.fields.find(field => field.name === 'payment_type');
                            let _paymentList = paymentTypeField?.list_values
                            setPaymentList(_paymentList)
                            getReportConfig()
                        }
                    });
                }
            }
        } catch (error) {
            console.error('Error in exportSettings.js -> updateParentObjectFields() : ' + error);
        }
    }, []);

    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const getReportConfig = () => {
        try {
            let promise = Promise.resolve(HTTPClient.get(endPoints.ROYALTY_REPORT.GET_REPORT_CONFIG));
            promise.then((response) => {
                if (response.status == -1) {
                    setIsMounted(true);
                }
               else if (isValidParam(response)) {
                        let configObj = getObjectParam(response);
                        let newStringMarkPaid;
                        if (typeof configObj.markpaid_setup == 'string' && configObj.invoice_setup !== ''){
                            newStringMarkPaid = JSON.parse(configObj?.markpaid_setup)
                           }else{
                            newStringMarkPaid = JSON.parse(JSON.stringify(configObj?.markpaid_setup));
    
                           }
                           let temp = changeLog;
                           temp.markAllPaymentType = newStringMarkPaid.paymentType;
                           temp.markAllStatus = newStringMarkPaid.status;
                           setChangeLog({ ...temp })
                           setIsMounted(true);
                }
             
            });
        } catch (error) {
            console.error("Error in 'royaltySetup.js -> getReportConfig()'" + error);
        }
    }

    const handleStatusPaymentTypeChange = (event, field) => {
        let val = event.target.value;
        let temp = changeLog;
        if (field == 'status') {
            temp.markAllStatus = val;
        } else {
            temp.markAllPaymentType = val;
        }
        setChangeLog({ ...temp })
    }
    const handleSave = () => {
        let params = {};
        let run_record_id = 0;
        if (appContainer.dialogProps?.data?.callFrom == "reportedSalesListView"){
            run_record_id = appContainer.dialogProps.data?.selectedIds[0];
        }
           
        params = {
            id :  appContainer.dialogProps?.data?.id ,
            paymentStatus: changeLog.markAllStatus,
            paymentType : changeLog.markAllPaymentType,
            rs_record_id: run_record_id
        }
        
         console.log("dcjndsk",appContainer)
         console.log("params",params)

         let promise = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_SETUP.MARKPAID_ALL_SAVE , params))
         promise.then((response)=>{
            if(isValidParam(response)){
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    handleClose();
                
            }
         })

    }

    const getActions = () => {
        const actions = [
            <Button

                primary={true}
                onClick={() => handleSave()}
                style={{ ...styles.primaryButton, float: 'right', marginRight: '' }}
            >{getLocalizedStrings().label.COMMON.SAVE}</Button>,
            <Button

                primary={true}
                onClick={() => handleClose()}
                style={{ ...styles.secondaryButton, float: 'right', }}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,

        ];

        return actions;
    }

    let paymentArr = paymentList?.map((r, index) => {
        let label = r.value;
        return <MenuItem key={r.value} value={r.value} style={styles.popoverMenuItem} >{<div title={label} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }}>{label}</div>}</MenuItem>
    });
console.log("dsldksl", isMounted)
    if (isMounted) {
        return (
            <div>

                <div style={{ width: '100%', paddingLeft: '12px', marginTop: '10px' }}>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel id="sf-leadscoresetup-simple-select-outlined-label"
                            className="sf-add-units-plan-name" style={{ marginTop: '-7px' }}>{getLocalizedStrings().label.COMMON.STATUS}</InputLabel>
                        <Select
                            key="status"
                            label={getLocalizedStrings().label.COMMON.STATUS}
                            // labelId='sf-leadscore-status'
                            id='status'
                            value={changeLog.markAllStatus}
                            onChange={(e) => handleStatusPaymentTypeChange(e, "status")}
                            name='status'
                            style={{ height: "42px" }}
                            className={"sf-fields-bg"}
                        >
                            <MenuItem value={'Full'}>Full</MenuItem>
                            <MenuItem value={'Partial'}>Partial</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{ width: '100%', paddingLeft: '12px', marginTop: '10px' }}>

                    <FormControl variant="outlined" style={{ width: '100%', marginTop: '15px' }}>
                        <InputLabel id="sf-leadscoresetup-simple-select-outlined-label"
                            className="sf-add-units-plan-name" style={{ marginTop: '-7px' }} >Payment Type</InputLabel>
                        <Select
                            key="payment type"
                            label='Payment Type'
                            // labelId='sf-leadscore-status'
                            id='payment'
                            value={changeLog.markAllPaymentType}
                            onChange={(e) => handleStatusPaymentTypeChange(e, "paymentType")}
                            name='status'
                            style={{ height: "42px" }}
                            className={"sf-fields-bg"}
                        >
                            {paymentArr}
                        </Select>
                    </FormControl>

                </div>
                <div style= {{marginTop: '10px'}}>
                    {getActions()}
                </div>
            </div>
        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }
}


export default MarkPaidAllActionDailog;