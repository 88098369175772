import React from 'react';
import PropTypes from 'prop-types';

import {Menu, Popover, Select, Typography,MenuItem,Badge,InputLabel,IconButton} from '@mui/material';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { connect } from 'react-redux';
import { isValidParam, getArrayParam, getObjectParam, getIntParam } from '../../../services/helper/parameterVerifier';
import { updateNotificationAccess, updateNotificationInfo, getNotificationDetails } from '../../../services/actions/notificationActions';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const NotificationComponent=(props)=>{
    const dispatch = useDispatch();
    const [showNotification, setShowNotification] = useState(true);
    const [badgePopOver, setBadgePopOver] = useState(false);
    const [notificationMenuItems, setNotificationMenuItems] = useState([]);
    const [count, setCount] = useState(0);
    const [interval, setInterval] = useState(0);
    const [anchorEl,setAnchorEl] = useState('');

    const notification = useSelector((state) => state.notification.notificationInfo);

    const openBadgePopover = (event) => {
        try {
            let notificationMenuItems = null;
            notificationMenuItems = getNotificationMenu();
            let notificationInfo = notification;
            let notificationDisplayCount = getArrayParam(notificationInfo.remindersList).length;
            notificationInfo['count'] = 0;
            notificationInfo['isViewed'] = true;
            notificationInfo['notificationDisplayCount'] = notificationDisplayCount;
            updateNotificationInfo(notificationInfo);
            setBadgePopOver(true);
            setAnchorEl(event.currentTarget);
            setNotificationMenuItems(notificationMenuItems);
        }
        catch (error) {
            console.error("Error in 'notificationComponent.js -> openBadgePopover():'" + error);
        }
    }
    const closeBadgePopover = () => {
        setBadgePopOver(false);
    }
    useEffect(() => {
        let notificationAccess = props.accessConfig;
        updateNotificationAccess(notificationAccess);

        let nextAccess = notification;
        let currAccess = props.accessConfig;
  
        if (nextAccess.is_appointment_popup !== currAccess.is_appointment_popup || nextAccess.is_task_popup !== currAccess.is_task_popup) {
          if (nextAccess.is_appointment_popup || nextAccess.is_task_popup) {
          }
          else {
            clearInterval(interval);
            setInterval(0);
          }
        }
        
    }, [props]);
    
    const handleTaskItemClick = (task) => {
        let recordid = 0;
        let labelName = '';
        let data = {};
        try {
          if (isValidParam(task)) {
            recordid = task.id;
            labelName = task.task_name;
          }
          data = { id: recordid, object: constants.TASKS }
          closeBadgePopover();
          dispatch(getAppDrawer(true, labelName, constants.EDIT, data, null));
        }
        catch (error) {
          console.error("Error in 'notificationComponent.js -> handleTaskItemClick():'" + error);
        }
    }
    const handleAppointmentItemClick = (appntmt) => {
        let recordid = 0;
        let labelName = '';
        let data = {};
        try {
          if (isValidParam(appntmt)) {
            recordid = appntmt.id;
            labelName = getLocalizedStrings().label.COMMON.EDIT + ' ' + getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENTS;
          }
          data = { id: recordid, object: constants.APPOINTMENTS }
          closeBadgePopover();
          dispatch(getAppDrawer(true, labelName, constants.EDIT, data, null));
        }
        catch (error) {
          console.error("Error in 'notificationComponent.js -> handleAppointmentItemClick():'" + error);
        }
    };
    const getNotificationMenu = () => {
        try {
    
          let notificationInfo = getObjectParam(notification);
          let menuItems = [];
          let remindersArray = [];
          if (getArrayParam(notificationInfo.remindersList).length > 0) {
            remindersArray = notificationInfo.remindersList;
            remindersArray.forEach((obj, index) => {
              if (obj.type === constants.TASKS) {
                menuItems.push((<MenuItem style={{ ...styles.popoverMenuItem }} key={index} value={obj.id} id={index}  autowidth={false} onClick={() => handleTaskItemClick(obj)} title={obj.task_name + ' due by ' + obj.when} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '110px' }}>{'Task: ' + obj.task_name}</div>}</MenuItem>));
              }
              else if (obj.type === constants.APPOINTMENTS) {
                menuItems.push((<MenuItem style={{ ...styles.popoverMenuItem }} key={index} value={obj.id} id={index}  autowidth={false} onClick={() => handleAppointmentItemClick(obj)} title={obj.subject + ' due by ' + obj.start_date} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '110px' }}>{'Appointment: ' + obj.subject}</div>}</MenuItem>));
              }
            });
          }
          else if (getArrayParam(notificationInfo.remindersList).length === 0) {
            menuItems.push((<div style={{ padding: '5px' }}>{getLocalizedStrings().message.COMMON.NOTIFICATION_INFO_EMPTY}</div>));
          }
          return menuItems;
        }
        catch (error) {
          console.error("Error in 'notificationComponent.js -> getNotificationMenu():'" + error);
        }
    }
    const displayNotificationComponent = (notificationCount) => {
        notificationCount = getIntParam(notificationCount);
        let component = null;
        if (notificationCount > 0) {
          component = <Badge badgeContent={notificationCount || 0} secondary={true} style={{ padding: '0px' }}
            badgeStyle={{
              top: '6px', right: '2px',
              height: '19px', width: '19px', fontSize: '10px', backgroundColor: '#f75528'
            }}>
            <IconButton onClick={(e)=>openBadgePopover(e)} title={getLocalizedStrings().label.HOME.NOTIFICATIONS}>
              <i className="far fa-bell" style={{color: '#cccccc', width: '22px', height: '22px',fontSize:'21px' }}></i>
            </IconButton>
          </Badge>
        }
        else {
          component  =  <IconButton onClick={(e)=>openBadgePopover(e)}  title={getLocalizedStrings().label.HOME.NOTIFICATIONS}>
          <i className="far fa-bell" style={{color: '#cccccc',fontSize:'21px' }}></i>
        </IconButton>
        }
        return component;
    }
    const openWhatsNew = () => {
        closeBadgePopover();
        window.open(constants.WSTNEW);
    }
    let notificationCount = getIntParam(notification.count);
    let notificationComp = displayNotificationComponent(notificationCount);
    return(
        <div style={ { display: 'grid',  height: '50px'  }}>
            {(showNotification) &&
            notificationComp}
        
            <Menu 
            open={badgePopOver}
            anchorEl={anchorEl} 
            onClose={()=>closeBadgePopover()}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: 13,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0
                }
              }
            }}   
            >
              
                <MenuItem title={getLocalizedStrings().label.HOME.WHATS_NEW}  onClick={()=>openWhatsNew()} style={styles.popoverMenuItem} >
                <Typography>{getLocalizedStrings().label.HOME.WHATS_NEW}</Typography>
                </MenuItem>
            </Menu>
        </div>
    );
}
export default NotificationComponent;