import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {Select, MenuItem,InputLabel,FormControl} from '@mui/material';
import * as HTTPClient from '../../../services/helper/httpClient';

import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { endPoints } from '../../../services/constants/endPoints';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { getWorkFlowStage } from '../../../services/actions/batchActions';
import { addNode } from '../../../services/actions/automationCampaignActions';
import { removeError } from '../../../services/helper/automationUtils';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import {useDispatch, useSelector} from 'react-redux';

const AutomationConvert = ({data,closeDrawer}) => {
    const dispatch = useDispatch();
    const automationCampaign = useSelector((state) => state.automationCampaign);
    const [valueWorkflow,setValueWorkflow] = useState(0);
    const [valueStage,setValueStage] = useState("");
    const [workflowList,setWorkflowList] = useState(null);
    const [stagesList,setStagesList] = useState(null);


   const getWorkflowList = () => {
        let nodeObject = data;
        let workflowId = 0;
        let stageValue = "";
        if (isValidParam(nodeObject)) {
            let detailsArray = null;
            if (nodeObject.hasOwnProperty('details')) {
                detailsArray = nodeObject.details[0];
                if (isValidParam(detailsArray)) {
                    if (detailsArray.hasOwnProperty('object_indentifier')) {
                        workflowId = detailsArray.object_indentifier;
                    }
                    if (detailsArray.hasOwnProperty('param_value')) {
                        stageValue = detailsArray.param_value;
                    }
                }
            }
        }
        let url = endPoints.WORKFLOWS.LIST_GET + "/" + constants.ACCOUNTS_OBJECT;
        var promise = Promise.resolve(HTTPClient.get(url, null));
        promise.then((response) => {
            let worlflowListArray = response;
            let wfList = workflowList;
            let _valueWorkflow = valueWorkflow;
            if (isValidParam(worlflowListArray)) {
                if (workflowId === 0) {
                    _valueWorkflow = worlflowListArray[0].id;
                } else {
                    _valueWorkflow = workflowId;
                }
                wfList = worlflowListArray.map((objWorkflow, i) => {
                    return (
                        <MenuItem
                            value={objWorkflow.id}
                        >{objWorkflow.name}</MenuItem>
                    );
                });
            }
            setWorkflowList(wfList);
            setValueWorkflow(_valueWorkflow);
            
            let params = {
                workflow_id: _valueWorkflow
            }

            populateWorkflowStages(params, stageValue);
        });
    }

    useEffect(() => {
        getWorkflowList();
    }, []);

    const populateWorkflowStages = (params, stageValue) => {
        var response = getWorkFlowStage(constants.ACCOUNTS_OBJECT, params);
        response.then((response) => {
            let stageListArray = null;
            let _valueStage = valueStage;
            let _stagesList = stagesList;
            if (isValidParam(response)) {
                if (response.hasOwnProperty('stages')) {
                    stageListArray = response.stages;
                    if (isValidParam(stageListArray)) {
                        if (stageValue === "" || stageValue === null) {
                            _valueStage = stageListArray[0].name;
                        } else {
                            _valueStage = stageValue;
                        }
                        _stagesList = stageListArray.map((objStage, i) => {
                            return (
                                <MenuItem
                                    value={objStage.name}
                                >{objStage.name}</MenuItem>
                            )
                        });
                    }
                    setStagesList(_stagesList);
                    setValueStage(_valueStage);
                }
            }
        });
    }

    const changeWorkflow = (event) => {
        let value = event.target.value;
        if (isValidParam(value)) {
            let params = {
                workflow_id: value
            }
            populateWorkflowStages(params, "");
            setValueWorkflow(value);            
        }
    }

   const changeWorkflowStage = (event) => {
        let value = event.target.value;
        if (isValidParam(value)) {
            setValueStage(value);
        }
    }

   const saveConvertDetails = () => {
        let object_indentifier = valueWorkflow;
        let param_value = valueStage;
        let nodeObject = data;
        let detailsArray = null;
        if (isValidParam(nodeObject)) {
            if (nodeObject.hasOwnProperty('details')) {
                detailsArray = nodeObject.details[0];
                if (isValidParam(detailsArray)) {
                    detailsArray.object_indentifier = object_indentifier;
                    detailsArray.param_value = param_value;
                } else {
                    detailsArray = {
                        object_indentifier: object_indentifier,
                        param_value: param_value,
                        action_type: "convert",
                    }
                    nodeObject.details.push(detailsArray);
                }
            } else {
                detailsArray.object_indentifier = object_indentifier;
                detailsArray.param_value = param_value;
                detailsArray.action_type = "convert";
                nodeObject.details.push(detailsArray);
            }
            nodeObject = removeError(nodeObject);
            dispatch(addNode(automationCampaign.data));
            closeDrawer();
        }
    }

        let campaignStatus = automationCampaign.data.status;
        return (
            <div style={{ paddingTop: '10px', paddingLeft: '20px', paddingRight: '30px' }}>
                <div style={{ marginBottom: '20px' }}>
                    <div>
                        <FormControl variant="outlined" style={{ width: '100%' }}>
                            <InputLabel id="sf-automation-converts-from-automation-results-simple-select-outlined-label" className="sf-automation-converts-from-automation-results">{getLocalizedStrings().label.AUTOMATION_DESIGNER.WORKFLOW_REQUIRED}</InputLabel>
                            <Select
                                value={valueWorkflow}
                                onChange={(event)=>changeWorkflow(event)}
                                labelId='sf-label-workflow'
                                id='sf-workflow'
                                style={{height:'42px'}}
                                className={"sf-fields-bg"}
                                label={getLocalizedStrings().label.AUTOMATION_DESIGNER.WORKFLOW_REQUIRED}
                            >
                                {workflowList}
                            </Select>
                        </FormControl>
                    </div>
                </div>

              
                    <div>
                       {valueStage !== null &&  <FormControl variant="outlined" style={{ width: '100%' }}>
                       <InputLabel id="sf-importfiledetails-from-importfiledetails-simple-select-outlined-label" className="sf-importfiledetails-from-importfiledetails">{getLocalizedStrings().label.AUTOMATION_DESIGNER.STATUS_REQUIRED}</InputLabel>
                            <Select
                                value={valueStage}
                                onChange={(event)=>changeWorkflowStage(event)}
                                style={{ height: '42px' }}
                                labelId='sf-label-status'
                                id='sf-status'
                                label={getLocalizedStrings().label.AUTOMATION_DESIGNER.STATUS_REQUIRED}
                                fullWidth={true}
                                className={"sf-fields-bg"}
                            >
                                {stagesList}
                            </Select>
                        </FormControl> }
                    </div>
               

                {
                    (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                    <div style={{ float: 'left', paddingTop: '15px' }}>
                        <Button
                            style={styles.listViewPrimaryButton}
                            onClick={()=>saveConvertDetails()}
                        >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                    </div>
                }
            </div>
        );
    }


export default AutomationConvert;

