import React from 'react';
import { connect } from 'react-redux';
import {styles} from '../../../services/constants/styles';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { setHeader } from '../../../services/actions/headerActions';
import ShowCircularProgress from '../components/circularProgress';
import { Link } from 'react-router-dom';
import { setRecentReportResponseData, setReportHeaderData, isInexpressReport, isTasksReport, isEmailReport, isSalesSummaryReport, isSMSReport, isOrthoticReport } from '../../../services/actions/reportActions';
import { openLeftSideMenu } from '../../../services/actions/appActions';
import { getObjectParam, getArrayParam, getBooleanParam, getStringParam } from '../../../services/helper/parameterVerifier';
import { updateTabInfo } from '../../../services/helper/sfTabManager';
import ServiceReportView from '../components/serviceReport';
import InexpressReport from '../components/inexpressReport';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import TasksReport from '../components/tasksReport';
import EmailReport from '../components/emailReport';
import SMSReport from '../components/smsReport'
import PipelineReport from '../components/pipelineReport';
import { constants } from '../../../services/constants/constants';
import OrthoticReport from '../components/orthoticReport';

class ServiceReportContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //value: 'ty',
            selectedIndex: 0,
            reportHeaderNames: [],
            showProgressBar: true,
            userType: {}
        }
    }



    UNSAFE_componentWillReceiveProps(nextProps) {
        let label = ""
        if (nextProps.object === "sales") {
            // label = "Sales Reports";
            label = getLocalizedStrings().label.REPORT.SALES_REPORT;
        } else if (nextProps.object === "services") {
            // label = "Service Reports";
            label = getLocalizedStrings().label.REPORT.SERVICE_REPORT;
        }

        let kanbanObject = {};
        kanbanObject.currentView = null;
        kanbanObject.show = false;

        if (nextProps.object !== this.props.object) {
            this.loadReportHeader(nextProps);
            this.props.setHeader(nextProps.object, label, kanbanObject, false, true);
        }

    }



    componentDidMount() {
        let label = ""
        if (this.props.object === "sales") {
            // label = "Sales Reports";
            label = getLocalizedStrings().label.REPORT.SALES_REPORT;
        } else if (this.props.object === "services") {
            // label = "Service Reports";
            label = getLocalizedStrings().label.REPORT.SERVICE_REPORT;
        }

        let kanbanObject = {};
        kanbanObject.currentView = null;
        kanbanObject.show = false;
        this.props.report.showProgressBar = true;

        this.props.setHeader(this.props.object, label, kanbanObject, false, true);
        this.loadReportHeader(this.props);
        if (this.props.report.selectIndex > 0) {
            this.state.selectedIndex = this.props.report.selectIndex;
        }
    }

    componentWillUnmount() {
        //this.props.openLeftSideMenu(true);
        this.props.report.showProgressBar = true;

    }

    loadReportHeader = (props) => {
        var promise1 = null;
        if (props.object === "sales") {
            promise1 = Promise.resolve(HTTPClient.get(endPoints.SALES_REPORT.REPORT_HEADER, null));
        } else if (props.object === "services") {
            promise1 = Promise.resolve(HTTPClient.get(endPoints.SERVICE_REPORT.REPORT_HEADER, null));
        }

        Promise.all([promise1]).then((response) => {
            let filteredHeaderNames = this.filterHeaderNames(response[0].report_names, response[0].user_type);
            // replaced this.setState({reportHeaderNames:response[0].report_names, userType: response[0].user_type /*userList: response[1].users */, showProgressBar: false }); 
            this.setState({ reportHeaderNames: filteredHeaderNames, userType: response[0].user_type /*userList: response[1].users */, showProgressBar: false });
            this.props.setReportHeaderData(response[0].report_names, props.object);
        });
    }

    handlButtonNavigationChange = (index, e) => {
        this.props.report.showProgressBar = true;
        this.props.report.selectIndex = index;
        this.props.report.isLoadReportData = true;
        this.state.selectedIndex = index;
        updateTabInfo({});
        this.setState({});
    }

    changeParentState = () => {
        this.setState({});
    }




    generateHeaderMenuNames(selectIndex) {
        let headerNames = this.state.reportHeaderNames.slice(0) === undefined ? [] : this.state.reportHeaderNames.slice(0);

        let isGlobal = getObjectParam(this.state.userType.isGlobal);
        let isCountry = getObjectParam(this.state.userType.isCountry);
        let isFranchise = getObjectParam(this.state.userType.isFranchise);
        return headerNames.map((headerName, index) => {
            // if (isGlobal || isCountry || isFranchise) {
            if (headerName.item_name !== "Forecast Report" && headerName.item_name !== "5 Ways Report") {
                if (index === selectIndex) {
                    return (
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            <Link
                                key={headerName.item_name}
                                to={'#'}
                                style={{ cursor: 'pointer' }}
                                onClick={() => { }}>
                                <span style={{ fontSize: '14px', lineHeight: '32px', color: 'rgb(3, 155, 229)' }} title={getLocalizedStrings().label.REPORT[headerName.item_name]}>{getLocalizedStrings().label.REPORT[headerName.item_name]}</span>
                            </Link>
                        </div>)
                } else {

                    return (
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            <Link
                                key={headerName.item_name}
                                to={'#'}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    if (this.props.report.showProgressBar === false) {
                                        this.handlButtonNavigationChange(index /*headerName.parameter_id*/, this);
                                    }

                                }}>
                                <span style={{ fontSize: '14px', lineHeight: '32px', color: '#717171' }} title={getLocalizedStrings().label.REPORT[headerName.item_name]}>{getLocalizedStrings().label.REPORT[headerName.item_name]}</span>
                            </Link>
                        </div>)
                }
            }

            // }

        });
    }

    isAdminOrManager = (roleName) => {
        let isValidRole = false;
        let role = getStringParam(roleName);
        if (role === constants.SECURITY_ROLE_ADMIN || role === constants.SECURITY_ROLE_MANAGER) {
            isValidRole = true;
        }
        return isValidRole;
    }

    filterHeaderNames = (headerNames, userType) => {
        let headerNamesList = getArrayParam(headerNames);
        let isCountry = getObjectParam(userType).hasOwnProperty("isCountry") ? getBooleanParam(userType.isCountry) : false;
        let isAdminOrMngr = this.isAdminOrManager(this.props.app.me.t_name);

        let names = headerNamesList.filter((f) => {
            if (f.item_type === constants.TASKS_REPORT && isAdminOrMngr) {
                return f;
            }
            if(f.item_type === constants.ORTHOTIC_REPORT){
                return f;
            }
            else {
                if (isCountry) {
                    if (f.item_type === 'InXpress Report 2' || f.item_type === 'InXpress Report 3' || f.item_type === 'InXpress Report 5' || f.item_type === 'InXpress Report 13'
                        || f.item_type === 'InXpress Report 14' || f.item_type === 'InXpress Report 16' || f.item_type === 'InXpress Report 17' || f.item_type === 'InXpress Report 18'
                        || (f.item_type.indexOf('InXpress Report') < 0) && f.item_type !== constants.TASKS_REPORT) {
                        return f;
                    }
                }
                else {
                    if (f.item_type === 'InXpress Report 5' || f.item_type === 'InXpress Report 13' || f.item_type === 'InXpress Report 14' || f.item_type === 'InXpress Report 15' ||
                        f.item_type === 'InXpress Report 16' || ((f.item_type.indexOf('InXpress Report') < 0) && f.item_type !== constants.TASKS_REPORT)) {
                        return f;
                    }
                }
            }

        });
        return names;
    }

    render() {
        let pageHeight = window.innerHeight - 247;
        let top = (pageHeight-10) / 2;
        if (this.state.showProgressBar === true) {
            return (<div style={{ width: '100%', height: pageHeight }}>
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
       </div>)}
        let headerNameMenuList = this.generateHeaderMenuNames(this.props.report.selectIndex); //this.props.report.selectIndex || 

        return (
            <   >
                <div className='SalesReport' style={{ paddingLeft: '30px', paddingRight: '20px' }} >
                    <div>
                        <div className="row" style={{ borderTop: 'solid 0px #d9d9d9' }}>
                            <div className="col-md-2">
                                <div style={{ marginBottom: 0, marginRight: 0, maxWidth: 200, width: '100%', overflow: 'auto' }}>
                                    <div style={{ overflow: 'hidden' }}>
                                        {headerNameMenuList}

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-10" style={{ borderLeft: 'solid 0px #d9d9d9' }}>
                                {
                                    // isInexpressReport(this.props.object, this.state.reportHeaderNames[this.props.report.selectIndex].item_name)
                                    isInexpressReport(this.props.object, this.state.reportHeaderNames[this.state.selectedIndex].item_name) ?
                                        <InexpressReport reportHeaderNames={this.state.reportHeaderNames} selectedIndex={this.state.selectedIndex} object={this.props.object} reportResponseData={this.state.reportResponseData} /> :

                                        !isTasksReport(this.props.object, this.state.reportHeaderNames[this.state.selectedIndex].item_name) && !isEmailReport(this.props.object, this.state.reportHeaderNames[this.state.selectedIndex].item_name) && !isSalesSummaryReport(this.props.object, this.state.reportHeaderNames[this.state.selectedIndex].item_name) 
                                          && !isSMSReport(this.props.object, this.state.reportHeaderNames[this.state.selectedIndex].item_name) && !isOrthoticReport(this.props.object, this.state.reportHeaderNames[this.state.selectedIndex].item_name) ?
                                            <ServiceReportView key={'SalesServiceReportView_' + this.props.object + '_' + this.props.report.selectIndex} reportHeaderNames={this.state.reportHeaderNames} selectedIndex={this.props.report.selectIndex} object={this.props.object} reportResponseData={this.state.reportResponseData} />
                                            :
                                            isTasksReport(this.props.object, this.state.reportHeaderNames[this.state.selectedIndex].item_name) ?
                                                <TasksReport reportHeaderNames={this.state.reportHeaderNames} selectedIndex={this.props.report.selectIndex} object={this.props.object} reportResponseData={this.state.reportResponseData} />
                                                :
                                                isSalesSummaryReport(this.props.object, this.state.reportHeaderNames[this.state.selectedIndex].item_name) ?
                                                    <PipelineReport reportHeaderNames={this.state.reportHeaderNames} selectedIndex={this.props.report.selectIndex} object={this.props.object} reportResponseData={this.state.reportResponseData} />
                                                    :
                                                    isSMSReport(this.props.object, this.state.reportHeaderNames[this.state.selectedIndex].item_name) ?
                                                    <SMSReport reportHeaderNames={this.state.reportHeaderNames} selectedIndex={this.props.report.selectIndex} object={this.props.object} reportResponseData={this.state.reportResponseData} />
                                                    :
                                                    isOrthoticReport(this.props.object, this.state.reportHeaderNames[this.state.selectedIndex].item_name) ?
                                                    <OrthoticReport reportHeaderNames={this.state.reportHeaderNames} selectedIndex={this.props.report.selectIndex} object={this.props.object} reportResponseData={this.state.reportResponseData} />
                                                    :
                                                    //Email Report
                                                    <EmailReport reportHeaderNames={this.state.reportHeaderNames} selectedIndex={this.props.report.selectIndex} object={this.props.object} reportResponseData={this.state.reportResponseData} />

                                }
                                {/* 
                                {
                                   !isInexpressReport(this.props.object, this.state.reportHeaderNames[this.state.selectedIndex].item_name) 
                                   && <ServiceReportView key={'SalesServiceReportView_' + this.props.object+'_'+this.props.report.selectIndex} reportHeaderNames={this.state.reportHeaderNames} selectedIndex={this.props.report.selectIndex} object={this.props.object} reportResponseData={this.state.reportResponseData} />
                                    
                                }
 */}
                            </div>
                        </div>
                    </div>
                </div>
            </  >
        );
    }
}



const mapStateToProps = (state) => {
    return {
        report: state.report,
        app: state.app
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setHeader: (selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader) => {
            dispatch(setHeader(selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader));
        },

        setRecentReportResponseData: (reportData, reportParams, selectIndex, selectedHeader, reportObject) => {
            dispatch(setRecentReportResponseData(reportData, reportParams, selectIndex, selectedHeader, reportObject));
        },
        openLeftSideMenu: (isOpenMenu) => {
            dispatch(openLeftSideMenu(isOpenMenu));
        },
        setReportHeaderData: (reportHeaders, reportObject) => {
            dispatch(setReportHeaderData(reportHeaders, reportObject));
        }
    }
}


ServiceReportContainer = connect(mapStateToProps, mapDispatchToProps)(ServiceReportContainer);

ServiceReportContainer.defaultProps = {
    object: "sales",
    //contentProp:"Content from props..."
}
export default ServiceReportContainer;