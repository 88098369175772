import React, { useState, useEffect } from 'react';
import { isValidParam, getStringParam, getIntParam,getArrayParam,getObjectParam ,getBooleanParam} from '../../../services/helper/parameterVerifier.js';
import { setHeader } from '../../../services/actions/headerActions.js';
import { styles } from '../../../services/constants/styles.js';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual.js';
import { endPoints } from '../../../services/constants/endPoints.js';
import { constants } from '../../../services/constants/constants.js';
import * as HTTPClient from '../../../services/helper/httpClient.js';
import SFDataTable from './sfDataTable.js';
import * as sfDialogs from './sfDialogs.js';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction.js';
import { useDispatch, useSelector } from "react-redux";
import PopOver from './PopOver.js';
import moment from 'moment';
import { dateFormatFlatPicker,dateFormat,sortArrayObjectByProperty} from '../../../services/helper/utils.js';
import { LinkToType } from '../../../services/constants/link.js';
import { getAuditTaskType } from '../../../services/actions/auditActions.js';
import { getAppCustomDrawer } from '../../../services/actions/appContainerActions.js';
import {getUnitsWithMyClientId} from "../../../services/actions/unitsAction.js";
import {
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Icon
} from '@mui/material';
import AutocompleteMultiSelect from './AutoCompleteMultiselect.js';
import ShowCircularProgress from './circularProgress.js';
import { addTab, TYPE_DETAIL_VIEW ,getActiveTab,updateTabInfo} from '../../../services/helper/sfTabManager.js';
import { updateAuditTask } from '../../../services/actions/auditActions.js';


const optionvalue = {
    td: 'Today',
    yd: 'Yesterday',
    tw: 'This Week',
    tm: 'This Month',
    tq: 'This Quarter',
    ty: 'This Year',
    lw: 'Last Week',
    lm: 'Last Month',
    lq: 'Last Quarter',
    ly: 'Last Year',
    cust: 'Custom'
};

const CustomDateInputStyle = () => {
    return <style> {
        ".form-control[readonly] {background-color: #fff !important;}"
    }</style>
}

const CorrectiveActionReport = (object) =>{
    const dispatch = useDispatch();
    const appState = useSelector((state) => state.app);
    const AuditTask = useSelector((state)=> state.auditDetails.AuditTask);

    const [isLoading,setIsLoading] = useState(false);
    const [showReport,setshowReport] = useState(false);
    const [units,setUnits] = useState([])
    const [unitOptions,setunitOptions] = useState([]);
    const [period, setPeriod] = useState("tm");
    const [from_date, setFrom_date] = useState(null);
    const [to_date, setTo_date] = useState(null)
    const [customTimeLineDisplay, setCustomTimeLineDisplay] = useState('none');
    const [taskTypeList,setTaskTypeList] = useState([]);
    const [reportData,setReportData] = useState({});
    const [unitList,setUnitList] = useState([]);

    const [rowSort, setRowSort] = useState({
        isDescending: false,
        onSort: (fieldName, isDescending, rows)=> onSortFn (fieldName, isDescending, rows)
    });

    useEffect(()=>{
        getUnitOptions();
        if(Object.keys(AuditTask).length !== 0){
            setIsLoading(false);
            if (AuditTask.hasOwnProperty('availableParams')) {
                const availableParams = AuditTask.availableParams;
                if (availableParams && availableParams.hasOwnProperty('unitOptions')) {
                    setunitOptions(availableParams.unitOptions);
                }
            }
            if (AuditTask.hasOwnProperty('selectedParams')) {
                const selectedParams = AuditTask.selectedParams;
                if (selectedParams) {
                    if(selectedParams.hasOwnProperty('period')){
                        if(selectedParams.period === 'cust'){
                            if (selectedParams.hasOwnProperty('from_date')) {
                                let _currDate = selectedParams.from_date;
                                setFrom_date(_currDate);
                            }
                            if (selectedParams.hasOwnProperty('to_date')) {
                                let _toDate = selectedParams.to_date;
                                setTo_date(_toDate);
                            }
                            setCustomTimeLineDisplay('block');
                        }
                        setPeriod(selectedParams.period);
                    }
                    if (selectedParams.hasOwnProperty('units')) {
                        setUnits(selectedParams.units);
                    }
                }
            }
            if (AuditTask.hasOwnProperty('reportData')) {
                setReportData(AuditTask.reportData);
                setIsLoading(true);
                setshowReport(true);
            }
        }
        dispatch(setHeader(constants.TASK_REPORT, getLocalizedStrings().label.TASK_REPORT.TITLE, null, false, true));
    },[])

    const getUnitOptions = () =>{
        setIsLoading(true);
        let promise = Promise.resolve(getUnitsWithMyClientId());
        if (isValidParam(promise)) {
            promise.then((response) => {
                setUnitList(response);
                let tempUnits = [];
                response.map((items)=>{
                    let data ={};
                    data.id = items.myclient_id;
                    data.label = items.company;
                    tempUnits.push(data)
                })
                tempUnits.sort(function(a, b){
                    if(a.label < b.label) { return -1; }
                    if(a.label > b.label) { return 1; }
                    return 0;
                })
                setunitOptions([...tempUnits]);
                let unitOptions = tempUnits;
                let data = {
                    availableParams: {unitOptions},
                    selectedParams: {units,period,from_date,to_date},
                };
                dispatch(updateAuditTask(data));
                setIsLoading(false);
            })
        }
    }

    const getMultilingualOptionsMap = () => {
        let optionsMultilingual = {
            'Today': getLocalizedStrings().label.DASHBOARD.TODAY,
            'Yesterday': getLocalizedStrings().label.DASHBOARD.YESTERDAY,
            'This Week': getLocalizedStrings().label.DASHBOARD.THIS_WEEK,
            'This Month': getLocalizedStrings().label.DASHBOARD.THIS_MONTH,
            'This Quarter': getLocalizedStrings().label.DASHBOARD.THIS_QUARTER,
            'This Year': getLocalizedStrings().label.DASHBOARD.THIS_YEAR,
            'Last Week': getLocalizedStrings().label.DASHBOARD.LAST_WEEK,
            'Last Month': getLocalizedStrings().label.DASHBOARD.LAST_MONTH,
            'Last Quarter': getLocalizedStrings().label.DASHBOARD.LAST_QUARTER,
            'Last Year': getLocalizedStrings().label.DASHBOARD.LAST_YEAR,
            'Custom': getLocalizedStrings().label.DASHBOARD.CUSTOM
        };
        return optionsMultilingual;
    }

    const sortArrayObjectByPropertyDesc = (array, property) => {
        let temp;
        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];
                    val1 = val1 instanceof String ? val1.toLowerCase() : val1;
                    val2 = val2 instanceof String ? val2.toLowerCase() : val2;
                    if (val1 > val2) {
                        temp= -1;
                    } else if (val1 < val2) {
                        temp= 1;
                    } else {
                        temp= 0;
                    }
                }
                return temp;
            });
        }
    }

    const onSortFn = (fieldName, isDescending, records) => {
        let tempRowSort = rowSort;
        let tempRecords = getArrayParam(records);
        let sortFieldName = getStringParam(fieldName);
        let activeTab = getActiveTab();
        let info = getObjectParam(activeTab.info);
        if(fieldName !== "unit"){
            try {
                if (!getBooleanParam(isDescending)) {
                    sortArrayObjectByProperty(tempRecords, sortFieldName);
                    tempRowSort.isDescending = isDescending;//true;
                }
                else {
                    sortArrayObjectByPropertyDesc(tempRecords, sortFieldName);
                    tempRowSort.isDescending = isDescending;//false;
                }
            }
            catch (error) {
                console.error("Error in 'SMSReport.js -> onSort()':" + error);
            }
        }else{
            if (!getBooleanParam(isDescending)) {
                tempRecords.sort((a, b) => a.unit?.toLowerCase() > b.unit?.toLowerCase() ? 1 : -1);
                tempRowSort.isDescending = isDescending;//true;
            }
            else {
                tempRecords= tempRecords.reverse();
                tempRowSort.isDescending = isDescending;//false;
            }
        }
        info.isDescending = tempRowSort.isDescending;
        updateTabInfo(info);
        setRowSort(tempRowSort);
    }
    
    const generateDateSelectionField = () => {
        let optionsMultilingual = getMultilingualOptionsMap();
        try {
            return Object.keys(optionvalue).map((itemKey) => {
                return <MenuItem key={itemKey} value={itemKey} style={styles.popoverMenuItem}>{optionsMultilingual[optionvalue[itemKey]]}</MenuItem>;
            });
        }
        catch (error) {
            console.error("Error in 'pipelineReport.js -> generateDateSelectionField()':" + error);
        }
    }


    // const handleOnChangeReportType = (value) =>{
    //     setReport_type(value);
    // }

    const handleDropDownChange = (event, value) => {
        value = value.props.value;
        setPeriod(value);
        if (value === "cust") {
            setCustomTimeLineDisplay('block');
            let tempDate = moment(String(new Date())).format(dateFormat[appState.me.date_format]);
            setFrom_date(tempDate);
            setTo_date(tempDate);
        } else {
            setCustomTimeLineDisplay('none');
            setFrom_date(null);
            setTo_date(null);
        }
    }

    const getTaskReport = () =>{
        let params = {};
        let valid = true;
        if(units && units.length > 0){
            params.unitid = units.map(u => u.id);
        }else{
            valid = false;
        }
        if(period){
            if(period === 'cust'){
                if(from_date){
                    let fromDate = moment(from_date)
                    fromDate =  fromDate.format('YYYY-MM-DD');
                    params.from_date = fromDate;
                }else{
                    valid = false;
                }
                if(to_date){
                    let toDate = moment(to_date);
                    toDate = toDate.format('YYYY-MM-DD');
                    params.to_date = toDate;
                }else{
                    valid = false;
                }
            }
            params.period = period;
        }else{
            valid = false;
        }
        if (!valid) {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_REPORT_V2.ALL_OPTIONS_REQUIRED, null);
            return;
        }
        setIsLoading(true);
        const promise = Promise.resolve(HTTPClient.get(endPoints.AUDIT_TASK_REPORT.GET_AUDIT_TASK, params));
        if (promise) {
            promise.then(res => {
                setReportData(res);
                let data = {
                    availableParams: {unitOptions},
                    selectedParams: {units,period,from_date,to_date},
                    reportData: res,
                };
                dispatch(updateAuditTask(data));
                setIsLoading(false);
                setshowReport(true)
            })
        }
    }

    const generateHeadersToPrint = (records,fields) => {
        let cells = '', temp;
        let tempColumns = getArrayParam(fields);
        let columns = tempColumns.filter(f => {
            if (!f.is_hidden) {
                temp = f;
            }
            return temp;
        });
        columns.forEach(f => {
            cells = cells + '<th style="border:1px solid black; text-align: center; padding-left:10px; padding-right:10px"> ' + f.label + ' </th>';
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    const generateRowsToPrint = (records,fields) => {
        let rows = getArrayParam(records);
        let columns = getArrayParam(fields);
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            rows.forEach((rowObject, rowIndex) => {
                let innerCells = '';
                columns.forEach((columnObject, colIndex) => {
                    if (!columnObject.is_hidden) {
                        if(rowObject[columnObject.name] !== undefined)
                        {
                            innerCells = innerCells + '<td style="border:1px solid black; text-align: center;padding-left: 3px; padding-right:3px;">' + rowObject[columnObject.name] + ' </td>';
                        }else if(rowObject[columnObject.name] == undefined){
                            innerCells = innerCells + '<td style="border:1px solid black; text-align: center;">' + ' ' + ' </td>';
                        }
                    }
                })
                cells = cells + ('<tr>' + innerCells + ' </tr>');
            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '" style="border:1px solid black;"> No data Found  </td>  </tr>');
        }
        return rowData;

    }

    const generateDateComponentForPrint = () =>{
        let period, start_date,end_date = '';
        const selectedParams = AuditTask?.selectedParams;

        if (selectedParams) {
            if(selectedParams.hasOwnProperty('period')){
                if(selectedParams.period === 'cust'){
                    if (selectedParams.hasOwnProperty('from_date')) {
                        start_date = selectedParams.from_date;
                    }
                    if (selectedParams.hasOwnProperty('to_date')) {
                        end_date = selectedParams.to_date;
                    }
                }
                period = selectedParams.period;
            }
        }
        let cells = '';
        if(period === 'cust'){
            cells = cells + '<th style="border:1px solid black; text-align: center;" colspan="2"> '+ getLocalizedStrings().label.KANBAN_REPORT.DURATION + '</th>' + '<th style="border:1px solid black; text-align: center;" colspan="7"> ' + optionvalue[period] + "  " + '(' + start_date + " - " + end_date + ')' + ' </th>';
        }else{
            cells = cells +'<th style="border:1px solid black; text-align: center; " colspan="2"> ' + getLocalizedStrings().label.KANBAN_REPORT.DURATION + ' </th>'+ '<th style="border:1px solid black; text-align: center;" colspan="7"> ' + optionvalue[period] + ' </th>';
        }
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    function printPdf() {
        let fields = reportData.fields;
        let records = reportData.records;
        if (getArrayParam(records).length > 0 && getArrayParam(fields).length > 0) {
            let dateComponent = generateDateComponentForPrint();
            let headerComponents = generateHeadersToPrint(records, fields);
            let rowComponents = generateRowsToPrint(records, fields);
            let htmlTable = '<table style="border:1px solid black;border-collapse:collapse;"> <thead>' + dateComponent +'</thead><thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '  </tbody> </table>';
            let htmlData = htmlTable;

            if (isValidParam(htmlData) && htmlData !== '') {
                var ifrmae = document.getElementById("ifmcontentstoprint");
                ifrmae.style.display = 'block'
                var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                ifrmae.style.display = 'block'

                pri.document.open();
                pri.document.write(htmlData);
                pri.document.close();
                pri.focus();
                pri.print();
                ifrmae.style.display = 'none';
            }
        }
    }

    const excelGenerateBeforeHeaders = () => {
        let cells = '';
        let reportName = getLocalizedStrings().label.TASK_REPORT.TITLE;
        let columns = reportData.fields;
        let period, from_date,to_date = '';
        const selectedParams = AuditTask?.selectedParams;

        if (selectedParams) {
            if(selectedParams.hasOwnProperty('period')){
                if(selectedParams.period === 'cust'){
                    if (selectedParams.hasOwnProperty('from_date')) {
                        from_date = selectedParams.from_date;
                    }
                    if (selectedParams.hasOwnProperty('to_date')) {
                        to_date = selectedParams.to_date;
                    }
                }
                period = selectedParams.period;
            }
        }
        let tempColumns = getArrayParam(columns);
    
    
        let columns1 = tempColumns.filter(f => !f.is_hidden);
        let columnsLength = getArrayParam(columns1).length;
    
    
        let combinedText = '';
        if(period === 'cust'){
            combinedText = `${reportName}<br>Duration: ${optionvalue[period]}<br> Start Date : ${from_date} <br> End Date : ${to_date}`;
        }else{
            combinedText = `${reportName}<br>Duration: ${optionvalue[period]}<br>`;
        }
    
    
        cells = cells + `<tr><td colspan="${columnsLength}">${combinedText}</td></tr>`;
    
        return cells;
    }

    const excelGenerateHeaders = () => {
        let cells = '';
        let tempColumns = getArrayParam(reportData.fields);
        let columns1 = tempColumns.filter(f => {
            let temp;
            if (!f.is_hidden) { temp = f; }
            return temp;
        });
        columns1.forEach(f => {
            cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    const excelGenerateRows = () => {
        let rows = reportData.records;
        let columns1 = getArrayParam(reportData.fields);
        let rowData = '';
        if (rows.length !== 0) {
          let cells = '';
          rows.forEach((rowObject, rowIndex) => {
            let innerCells = '';
            columns1.forEach((columnObject, colIndex) => {
              if (!columnObject.is_hidden) {
                let value = rowObject.hasOwnProperty(columnObject.name) ? rowObject[columnObject.name] : "";
                innerCells = innerCells + '<td >' + value + ' </td>';
              }
            })
            if (rowIndex % 2 === 0) {
              cells = cells + ('<tr>' + innerCells + ' </tr>');
            } else {
              cells = cells + ('<tr  bgcolor="Lavender">' + innerCells + ' </tr>');
            }
    
          });
          rowData = cells;
        } else {
          rowData = ('<tr> <td colspan="' + (columns1.length) + '"> No data Found  </td>  </tr>');
        }
        return rowData;
    
      }

    function downloadExcel(e) {
        if (e !== null && e !== undefined) {
            let beforeHeaderComponent = excelGenerateBeforeHeaders();
            let headerComponents = excelGenerateHeaders();
            let rowComponents = excelGenerateRows();
      
            let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '  </tbody> </table>';
            let htmlData = htmlTable;
            if (isValidParam(htmlData) && htmlData !== '') {
              htmlData = htmlData.replace("%22", "\"");
              var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
              var downloadUrl = URL.createObjectURL(blob);
              var a = document.createElement("a");
              a.href = downloadUrl;
              a.download =  getLocalizedStrings().label.TASK_REPORT.TITLE + new Date().getTime() + '.xls';
              document.body.appendChild(a);
              a.click();
              a.remove();
            }
          }
    }

    const customEvent = (eventType, column, row, event) =>{
        if(column.name === "unit"){
            openUnitDetailView(column,row);
        }else {
            let data = {};
            data.eventType = eventType;
            data.column = column;
            data.object = 'correactive_action_details'
            data.row = row;
            let style = {};
            style.width = '80%';
            let type = constants.CORRECTIVE_ACTIONS_REPORT_DETAILS;
            let label = getLocalizedStrings().label.TASK_REPORT.CORRECTIVE_ACTION_REPORT_DETAILS;
            dispatch(getAppCustomDrawer(true, label, type, style, data, null, false));
        }
    }

    const openUnitDetailView = (columnObject,rowdata)=>{
        let selectedUnit = unitList.filter((ele)=> {return ele.myclient_id === rowdata.id})
        let accountRecordId = selectedUnit[0] ? selectedUnit[0].id : 0;
        try {
            let linkUrl = '/' + constants.ACCOUNTS_OBJECT + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + accountRecordId;
            let activeTab = getObjectParam(getActiveTab());
            let activeTabIndex = getIntParam(activeTab.index);
            let info = getObjectParam(activeTab.info);
            let tab = { label: rowdata.unit, object: constants.ACCOUNTS_OBJECT, type: TYPE_DETAIL_VIEW, imgName: null, url: linkUrl, isActive: true, parentTabIndex: activeTabIndex };
            addTab(tab, true);
        } catch (error) {
            console.error("Error in 'complianceReport.js -> openUnitDetailView()':" + error);
        }
    }

    const getReportView = () =>{
        let view = [];
        if (!reportData.records || Object.keys(reportData.records).length === 0) {
            return getNoRecordContent();
        }else{
            view.push(<div style={{height:'21rem',overflowY:'scroll'}}>
                    <SFDataTable rowSort={rowSort} report_type="corrective_action_report" columns={reportData.fields}  rows={reportData.records} customEvent={(eventType, column, row, event) => customEvent(eventType, column, row, event)} />
                </div>)

            return view;
        }
    }

    function getNoRecordContent() {
        return (
            <div className="row" style={{ padding: '0px', display: "flex",marginLeft:'10px'}}>
                <h5 style={{ color: 'red' }}>{getLocalizedStrings().label.INXPRESS_REPORT.NO_RECORDS_FOUND}</h5>
            </div>
        )
    }

    const getReportFilter = () => {
        let datePickerOptions = {
            enableTime: false,
            dateFormat: dateFormatFlatPicker[appState.me.date_format],
            minuteIncrement: 1,
            static: true
        }

        let element = (
            <>
                <div className='Task_report' style={{ width: '100%', height: 'fit-content', display: 'flex', paddingLeft: '2rem',paddingTop:'1rem' }}>
                    {/* units */}
                    <CustomDateInputStyle />
                    <iframe id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute', display: 'none' }}></iframe>

                    <div className="col-md-4" style={{ paddingLeft: '0px', minWidth: '15rem',maxWidth:'30rem'}} >
                        <AutocompleteMultiSelect
                            value={units}
                            items={AuditTask?.availableParams?.unitOptions}
                            placeholderText={getLocalizedStrings().label.TASK_REPORT.UNITS}
                            selectAllLabel='All'
                            onChange={setUnits}
                        />
                    </div>

                    {/* date_range */}
                    <div style={{ paddingLeft: '0px' }} >
                        <div
                            style={{ width: '100%', fontSize: '14px', color: '#717171', height: '47px', marginRight: "7px" }}
                            id='due_by'
                            key='due_by'
                        >
                            <FormControl variant="outlined">
                                <InputLabel id="sf-task-report-fromtaskreport-simple-select-outlined-label" className="sf-task-report-fromtaskreport">{getLocalizedStrings().label.REPORT.DATE_RANGE}</InputLabel>
                                <Select
                                    labelId="sf-task-report-fromtaskreport-simple-select-outlined-label"
                                    id="sf-task-report-fromtaskreport-simple-select-outlined"
                                    value={period}
                                    className={"sf-fields-bg"}
                                    onChange={(event, value) => handleDropDownChange(event, value)}
                                    label={getLocalizedStrings().label.REPORT.DATE_RANGE}
                                    style={{ minWidth: 200, maxWidth: 200, height: '47px' }}
                                >
                                    {generateDateSelectionField()}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    {/* date_picker */}
                    <div style={{ display: customTimeLineDisplay }}>
                        <div id="dateholder" style={{ marginLeft:'10px',width:'8rem'}}>
                            <PopOver
                                id={'from_date'}
                                key={'from_date'}
                                name={'from_date'}
                                btnType={'date'}
                                buttonStyle={{ fontSize: '16px' }}
                                containerStyle={{ lineHeight: 1 }}
                                buttonLabel={getLocalizedStrings().label.REPORT.START_DATE}
                                onChange={(date) => setFrom_date(date)}
                                value={from_date}
                                innerLabelStyle={{ paddingTop:'15px', fontSize: '15px',paddingLeft:'3px' }}
                                height={47}
                                options={datePickerOptions}
                            />
                        </div>

                    </div>
                    <div style={{ display: customTimeLineDisplay }}>
                        <div id="dateholder" style={{ marginLeft:'10px',width:'8rem' }}>
                            <PopOver
                                id={'to_date'}
                                key={'to_date'}
                                name={'to_date'}
                                btnType={'date'}
                                buttonStyle={{ fontSize: '16px' }}
                                containerStyle={{ lineHeight: 1 }}
                                buttonLabel={getLocalizedStrings().label.REPORT.END_DATE}
                                onChange={(date) => setTo_date(date)}
                                value={to_date}
                                innerLabelStyle={{ paddingTop:'15px', fontSize: '15px',paddingLeft:'3px' }}
                                height={47}
                                options={{ ...datePickerOptions, minDate: from_date }}
                            />
                        </div>
                    </div>
                    <div style={{width:'5rem',paddingLeft:period === 'cust' ? '17px' : '10px'}}>
                        <Button
                            onClick={() => getTaskReport()}
                            style={{
                                ...styles.primaryButton,
                                minWidth: '56px',
                                width: '2rwm',
                                marginTop:'10px',
                            }}
                            title={getLocalizedStrings().label.REPORT.GO}
                        > {getLocalizedStrings().label.REPORT.GO}</Button>
                    </div>
                    {/* page_print */}
                    <div class="print_section" style={{width:'5rem',height:'46px',paddingTop:'10px'}}>
                        {
                            showReport && (
                                <div style={{ display: 'flex' }}>
                                    <Icon onClick={() => { printPdf(); }} title={getLocalizedStrings().label.REPORT.PRINT} style={{ fontSize: '23px', cursor: 'pointer', color: '#717171', paddingTop: '3px', marginLeft: '5px' }}>print</Icon>
                                    <i  onClick={(e) => downloadExcel(e)} className="far fa-file-excel" title="Excel" style={{ fontSize: '21px', color: 'green', cursor: 'pointer', paddingTop: '3px', marginLeft: '10px' }} />
                                </div>
                            )
                        }
                    </div>
                </div>
            </>
        )
        return element;
    }

    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 3
    return(
        <div>
            {getReportFilter()}
            <div style={{ marginTop:'15px',marginLeft:'1.3rem'}}>
                {showReport && ( !isLoading ? getReportView() : ( 
                            <div style={{ width: '100%', height: contentHeight }}>
                                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }
            </div>
        </div>
    )
}

export default CorrectiveActionReport;