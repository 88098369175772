import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Note from '../components/note';
import { constants } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getContactRecordsInfo } from '../../../services/actions/sfFormActions';
import { isValidParam, getArrayParam, getStringParam } from '../../../services/helper/parameterVerifier';
import * as SFFormActions from '../../../services/actions/sfFormActions';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { Icon } from '@mui/material';
import AsyncSelect from 'react-select/async';
import Axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { MenuItem, Menu, IconButton } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import * as sfDialogs from '../components/sfDialogs';
import { getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { constant } from 'lodash';


const CustomForm = ({ object, attendeeRecordId, id, isUnitOwner, parentDetails, callFrom, updateNoteData, updateAttendee, attachedFiles, tasksObjParam, showAttachment }) => {
    const app = useSelector((state) => state.app);
    const dispatch = useDispatch();

    const [attendeeSearchName, setAttendeeSearchName] = useState(null);
    const [isSearchAttendee, setIsSearchAttendee] = useState(false);
    const [stateObject] = useState(object);
    const [attendees, setAttendees] = useState([]);
    const [note_data, setNoteData] = useState({});
    const [selectedAttendees, setSelectedAttendees] = useState([]);
    const [options, setOptions] = useState([]);
    const [attendeeObj, setattendeeObj] = useState(null);
    const [parentRecordId, setParentRecordId] = useState(0);
    const [isWorkflowTask, setIsWorkflowTask] = useState(false);
    const [taskCompleted, setTaskCompleted] = useState(0);
    const [isOpenNote, setIsOpenNote] = useState(false);
    const [attachmentDetails, setAttachmentDetails] = useState(null);
    const [isOpenAttachmentPopover, setIsOpenAttachmentPopover] = useState(false);
    const [stateCallFrom, setCallFrom] = useState(callFrom);
    const [attachFiles, setAttachFiles] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [existingFiles, setExistingFiles] = useState([]);
    const [duplicateFiles, setDuplicateFiles] = useState([]);
	const appContainer = useSelector((state) => state.appContainer);


    useEffect(() => {
        if (attendeeRecordId > 0 && object === constants.APPOINTMENTS) {
            setParentRecordId(attendeeRecordId);
        }
        if (id > 0 && object === constants.APPOINTMENTS) {
            getInviteeList();
        }
        if (id > 0 && object === constants.TASKS) {
            getTaskDetails();
        }
    }, [attendees]);

    useEffect(() => {
        let _files = attachFiles;
        if (isValidParam(attachmentDetails)) {
            if (isValidParam(attachmentDetails.attached_files) && getArrayParam(attachmentDetails.attached_files).length > 0) {
                var uniqueResultArray = attachmentDetails.attached_files.filter(function (objOne) {
                    return !_files.some(function (objTwo) {
                        return objOne.name == objTwo.name;
                    });
                });
                _files = [..._files, ...uniqueResultArray];
            }

        }
        if (isValidParam(_files)) {
            attachedFiles(_files);

        }
    }, [attachmentDetails, attachFiles])

    useEffect(() => {
        if(appContainer.drawerProps.data?.parentRecordDetails !== undefined && object == 'tasks'){
            let recordDetails = appContainer.drawerProps.data?.parentRecordDetails;
            let attachesInfo =recordDetails?.t_attachment;
            if(attachesInfo !== undefined && attachesInfo !== '' && attachmentDetails == null){
                setAttachmentDetails(JSON.parse(attachesInfo));
            }
        }
 
    }, []);

    const onChange = (obj) => {
        let name = '';
        if (obj !== null && obj.label !== null && obj.label.length > 0) {
            name = $.trim(obj.label.substring(0, obj.label.lastIndexOf(" (")));
        }
        var attObj = {};
        attObj.id = 0;
        attObj.guest_name = name;
        attObj.guest_email = obj.email;
        attObj.t_status = constants.CALENDER_INVITE_STATUS_REQUESTED;
        attObj.owner = constants.CALENDER_INVITE_OWNER_NO;
        attObj.idx = selectedAttendees.length;

        let selectedAttendeesArr = selectedAttendees;
        selectedAttendeesArr.push(attObj);
        setSelectedAttendees(selectedAttendeesArr);

        let attendeeName = attendees;
        attendeeName.push(name);
        setAttendees(attendeeName);
        setAttendeeSearchName(name);
        setOptions([]);
        updateAttendee(selectedAttendees);
    }

    const removeAttendee = (idx) => {
        let attendees = selectedAttendees;
        attendees = attendees.filter(f => {
            return f.idx !== idx;
        });
        updateAttendee(attendees);
        setSelectedAttendees(attendees);
    }



    const noData = () => {
        return [];
    }

    const getOptions = (input) => {

        if (!input || (input && input.length < 3)) {
            return new Promise(resolve => {
                resolve(noData())
            });
        }

        try {
            let params = {};
            params.attendeeText = input;
            params.attendeesInfo = selectedAttendees;

            return HTTPClient.get(endPoints.APPOINTMENT.LIST_ATTENDEE_GET, params)
                .then((response) => {
                    let options = [];
                    response.forEach(function (data) {
                        options.push(
                            {
                                label: data.value,
                                email: data.id
                            }
                        );

                    });
                    return options;

                });


        } catch (error) {
            console.error("error in getOptions:::" + error)
        }
    };

    const downloadAs = (item) => {
        if (item.uid > 0 || (item.urlS3 != null && item.urlS3 != undefined)) {
            let tabInfo = getActiveTabInfo();
            let isUnitsAccount = false;
            let UnitsAccountProjectId = 0;
            if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
                isUnitsAccount = tabInfo.isUnitsAccount;
                UnitsAccountProjectId = tabInfo.UnitsAccountProjectId;
            }
            let url = '';
            var formData = new FormData();
            let documentName = null;
            let documentType = '';
            let fileparams = {}
            url = endPoints.ATTACHMENT.DOWNLOAD_ATTACHMENT;
            documentName = item.name;

            if (UnitsAccountProjectId > 0) {
                fileparams = { 'id': item.uid, 'url': item.hasOwnProperty('urlS3') && item.urlS3 != null ? item.urlS3 : "", 'isUnitsAccount': isUnitsAccount, 'UnitsAccountProjectId': UnitsAccountProjectId };
            } else {
                fileparams = { 'id': item.uid, 'url': item.hasOwnProperty('urlS3') && item.urlS3 != null ? item.urlS3 : "" };
            }

            formData.append('input_param', JSON.stringify(fileparams));
            documentName = getStringParam(documentName);
            documentType = documentName.split('.').pop();
            if (documentName != '') {
                HTTPClient.postFormDataDownloadFile(url, formData, documentName);
            }
        } else {
            Axios.get(item.urlS3, {
                headers: {
                    "Content-Type": "application/octet-stream"
                },
                responseType: "blob"
            })
                .then(response => {

                    const a = document.createElement("a");
                    const url = window.URL.createObjectURL(response.data);
                    a.href = url;
                    a.download = item.name;
                    a.click();
                })
                .catch(err => {
                    console.log("error", err);
                });
        }

    };

    const handleFocus = (event) => {

        if (selectedAttendees !== null && selectedAttendees.length === 0) {
            if (isSearchAttendee === false) {
                setIsSearchAttendee(true);
                let attendeeArr = [];
                let attendeeOwner = app.me.name + ' ( ' + getLocalizedStrings().label.APPOINTMENTS_TASKS.OWNER + ' )';
                attendeeArr.push(attendeeOwner);
                setAttendees(attendeeArr);
                var attObj = {};
                attObj.id = 0;
                attObj.guest_name = app.me.name;
                attObj.guest_email = app.me.email;
                attObj.t_status = constants.CALENDER_INVITE_STATUS_ACCEPTED;
                attObj.owner = constants.CALENDER_INVITE_OWNER_YES;

                let _selectedAttendeesArr = [];
                _selectedAttendeesArr.push(attObj);
                setSelectedAttendees(_selectedAttendeesArr);

                if (attendeeObj !== null) {
                    let _attendeeObj = attendeeObj;
                    _attendeeObj.idx = selectedAttendees.length;
                    setattendeeObj(_attendeeObj);
                    _selectedAttendeesArr = [];
                    _selectedAttendeesArr.push(attendeeObj);
                    setSelectedAttendees(_selectedAttendeesArr);
                }


                if (parentRecordId > 0) {

                    let params = {};
                    params.parent_table_id = 10;
                    params.child_table_id = 9;
                    params.parent_record_ids = parentRecordId;
                    params.child_fields = 'id,email,first_name,last_name';
                    let promise = getContactRecordsInfo(params);

                    if (promise !== null) {
                        promise.then((response) => {
                            if (response.linkRecords !== null && response.linkRecords.length > 0) {
                                response.linkRecords.forEach(function (data) {

                                    if (data.email !== null && data.email !== '') {
                                        attObj = {};
                                        attObj.id = 0;
                                        attObj.t_status = constants.CALENDER_INVITE_STATUS_REQUESTED;
                                        attObj.owner = constants.CALENDER_INVITE_OWNER_NO;
                                        let contactName = '';
                                        if (data.first_name !== null & data.first_name !== '') {
                                            contactName = data.first_name;
                                            if (data.last_name !== null & data.last_name !== '') {
                                                contactName += " " + data.last_name;
                                            }

                                        } else if (data.last_name !== null & data.last_name !== '') {
                                            contactName = data.last_name;
                                        } else {
                                            contactName = data.email;
                                        }
                                        attObj.guest_name = contactName;
                                        attObj.guest_email = data.email;
                                        attObj.idx = _selectedAttendeesArr.length;

                                        _selectedAttendeesArr.push(attObj);
                                        setSelectedAttendees(_selectedAttendeesArr);
                                    }
                                });
                            }
                            updateAttendee(_selectedAttendeesArr);
                        });
                    }
                } else {
                    updateAttendee(_selectedAttendeesArr);
                }
            }
        }
    }

    const getNoteData = (obj) => {
        updateNoteData(obj);
        setNoteData({ note_data: obj });
    }

    const getInviteeList = () => {
        let params = {};
        params.event_id = id;
        let obj = null;
        let inviteesArr = [];
        HTTPClient.get(endPoints.APPOINTMENT.LIST_INVITEE_GET, params)
            .then((response) => {
                response.forEach(function (data, index) {
                    obj = {};
                    obj.id = data.id;
                    obj.guest_name = data.guest_name;
                    obj.guest_email = data.guest_email;
                    obj.t_status = data.t_status;
                    obj.owner = data.owner;
                    obj.idx = index;
                    inviteesArr.push(obj);

                });
                updateAttendee(inviteesArr);
                setSelectedAttendees(inviteesArr);
            });
    }


    const getTaskDetails = () => {
        let params = {};
        try {
            
            params.object = object;
            params.isUnitOwnerTasks = tasksObjParam?.isUnitOwnerTasks ? true : false;
            params.parent_object = parentDetails.parent_object;
            params.parent_record_id = parentDetails.parent_record_id;
            let promise = SFFormActions.getRecordPromise(object, id, params);
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response) && isValidParam(response.records)) {
                        if (response.records.hasOwnProperty('t_attachment') && isValidParam(response.records.t_attachment)) {
                            setAttachmentDetails(JSON.parse(response.records.t_attachment));
                        }
                        if (response.records.hasOwnProperty('t_workflow_task') && isValidParam(response.records.t_workflow_task)) {
                            if (response.records.t_workflow_task === 1 || response.records.t_workflow_task === -1) {
                                setIsWorkflowTask(true);
                            }
                        }
                        if (isValidParam(response.records.task_completed)) {
                            setTaskCompleted(response.records.task_completed);
                        }
                    }
                });
            }
        } catch (error) {
            console.error("error in CustomForm.js --> 'getTaskDetails'" + error);
        }
    }

    const createAttachmentInfo = () => {
        let element = [];
        try {
            let _attachmentDetails = isValidParam(attachmentDetails) ? getArrayParam(attachmentDetails.attached_files) : [];
            let attachmentUrls = isValidParam(attachmentDetails) ? getArrayParam(attachmentDetails.attached_urls) : [];
            let currentfiles = attachFiles;
            var uniqueResultArray = _attachmentDetails.filter(function (objOne) {
                return !currentfiles.some(function (objTwo) {
                    return objOne.name == objTwo.name;
                });
            });
            let allUniqueFiles = [...uniqueResultArray];
            if (isValidParam(allUniqueFiles)) {
                if (isValidParam(allUniqueFiles) && getArrayParam(allUniqueFiles).length > 0) {

                    getArrayParam(allUniqueFiles).forEach(item => {
                        element.push(
                            <div style={{ height: '30px' }}>
                                <button style={{
                                    background: 'none!important',
                                    border: 'none',
                                    padding: '0!important',
                                    /*optional*/
                                    fontFamily: 'arial, sans-serif',
                                    /*input has OS specific font-family*/
                                    color: '#069',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    maxWidth: '200px',
                                    // border: '1px solid red',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden'
                                }} onClick={() => downloadAs(item)}>{item.name}</button>
                                {/* <a href={item.urlS3} title={item.name} download={item.name}
                                    // target="_blank"
                                    style={{ paddingLeft: '5px', maxWidth: '300px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block' }}>
                                    {item.name}
                                </a> */}
                            </div>
                        );
                    });
                }
                if (isValidParam(attachmentUrls) && getArrayParam(attachmentUrls).length > 0) {
                    element.push(
                        <div style={{ padding: '10 4 10 10', fontSize: 15, color: '#616161', backgroundColor: '#e3e6e8', marginTop: '11px' }}>
                            {getLocalizedStrings().label.APPOINTMENTS_TASKS.LINKS}
                        </div>
                    );
                    getArrayParam(attachmentUrls).forEach(item => {
                        let hrefName = '';
                        if ((item.indexOf("http:") != -1) || (item.indexOf("https:") != -1)) {
                            hrefName = item;
                        } else {
                            hrefName = 'http://' + item;
                        }
                        element.push(
                            <div style={{ height: '30px', paddingTop: '5px' }}>

                                <a href={hrefName} title={item}
                                    download={item}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ paddingLeft: '5px', maxWidth: '348px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block' }}>
                                    {item}
                                </a>
                            </div>
                        );
                    });
                }
            }
        } catch (error) {
            console.error("error in CustomForm.js --> 'createAttachmentInfo'" + error);
        }
        return element;
    }

    const openNote = () => {
        setIsOpenNote(true);
    }

    const openAttachmentPopover = (event) => {
        event.preventDefault();
        setIsOpenAttachmentPopover(true);
        setAnchorEl(event.currentTarget);
    }

    const closeAttachmentPopover = () => {
        setIsOpenAttachmentPopover(false);
    };

    const onClickUploadAttachment = (value, event) => {
        let _existingFiles = [];
        let _files = getArrayParam(attachFiles);

        if (attachmentDetails != null) {
            _existingFiles = [..._files, ...attachmentDetails.attached_files]
        } else if (existingFiles.length == 0) {
            _existingFiles = [..._files]
        }
        else {
            _existingFiles = [...existingFiles]
        }
        if (value == "Computer") {
            setIsOpenAttachmentPopover(false);
            let data = new Object();
            data.fullWidth = true;
            data.maxWidth = 'md';
            data.callFrom = 'TaskForm';
            data.existingFiles = _existingFiles;
            data.duplicateFiles = duplicateFiles;
            data.object = constants.TASKS;
            dispatch(getAppDialog(true, constants.SEND_MAIL_ATTACHMENT_UPLOAD_DIALOG, getLocalizedStrings().message.ATTACHMENT.UPLOAD_FILE, setAttachFilesFn, data, null));

        }

    }

    const openAttachmentDialog = () => {
        let data = new Object();
        data.callFrom = constants.SEND_EMAIL;
        dispatch(getAppDialog(true, constants.SEND_MAIL_ATTACHMENT_UPLOAD_DIALOG, getLocalizedStrings().message.ATTACHMENT.UPLOAD_FILE, setAttachFilesFn, data, null));
    }

    const setAttachFilesFn = (files, duplicateFileName, duplicateFileExsts) => {
        if (object === constants.TASKS && duplicateFileExsts === true) {
            var uniqueResultArray;
            if (isValidParam(attachFiles)) {
                uniqueResultArray = attachFiles.filter(function (objOne) {
                    return !files.some(function (objTwo) {
                        return objOne.name == objTwo.name;
                    });
                });
            }

            files = getArrayParam(files);
            if (files.length > 0) {
                setAttachFiles([...uniqueResultArray, ...files]);

            }
        }
        else {
            files = getArrayParam(files);
            if (files.length > 0) {
                let temp = [...attachFiles, ...files];
                setAttachFiles(temp);

            }
        }

    }


    const getAttachmetFiles = () => {
        let _files = getArrayParam(attachFiles);
        let ul = null;
        let li = _files.map((f, index) => {
            return <li key={f.name + index}><span style={{
                float: 'left', textAlign: 'left', overflow: 'hidden',
                textOverflow: 'ellipsis', display: 'block', whiteSpace: 'nowrap', width: '150px'
            }} title={f.fileName}> {f.name} </span>
                &nbsp;
                <i className="fa fa-times" aria-hidden="true" style={{ paddingRight: '5px', cursor: 'pointer' }} onClick={() => onclickRemoveFile(index)} ></i>
            </li>


        });
        if (li != null && li.length > 0) {
            ul = <ul>{li}</ul>;
            //setExistingFiles([..._files]);

        }
        return ul;
    }
    const onclickRemoveFile = (index) => {
        let removeItem = attachFiles[index];
        let file = attachFiles.filter(f => {
            return f.name !== removeItem.name;
        });
        setAttachFiles(file)
    }
    const sfFormAttachmentFn = () => {
        let _files = attachFiles;

        if (isValidParam(attachmentDetails)) {
            if (isValidParam(attachmentDetails.attached_files) && getArrayParam(attachmentDetails.attached_files).length > 0) {
                var uniqueResultArray = attachmentDetails.attached_files.filter(function (objOne) {
                    return !_files.some(function (objTwo) {
                        return objOne.name == objTwo.name;
                    });
                });
                _files = [..._files, ...uniqueResultArray];
            }

        }
        attachedFiles(_files);

    }

    let showCustomFormRightDiv = 'none';
    let showCompleteField = false;

    if (stateObject === constants.TASKS || stateObject === constants.APPOINTMENTS) {
        showCustomFormRightDiv = 'block';
    }
    if (stateObject === constants.TASKS && isValidParam(parentDetails) && !isWorkflowTask && parentDetails.parent_object === constants.PROJECTS_OBJECT) {
        if (id > 0) {
            showCompleteField = true;
        }
    }




    return (
        <div>
            <div>

                <div className="col-sm-12" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: '12px' , paddingBottom : callFrom == 'queueView' && stateObject === constants.APPOINTMENTS ? '20px' : ''  }}>
                    {(stateObject === constants.APPOINTMENTS || stateObject === constants.TASKS) && callFrom !== 'queueView' ?
                        <div style={{ paddingBottom: 20 }}>
                            {!isOpenNote &&
                                <div style={{ paddingLeft: '5px', color: '#6495ED' }}>
                                    <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => openNote()}>{getLocalizedStrings().label.APPOINTMENTS_TASKS.ADD_A_NOTE}</span>
                                </div>}
                            {isOpenNote &&
                                <Note onChange={(obj) => getNoteData(obj)} parentObject={stateObject} />}
                        </div>
                        : null}
                    {object == constants.TASKS && showAttachment &&
                        <>
                            <div>
                                <div style={{ width: 'auto', fontSize: '15px' }}>
                                    <a herf="" onClick={(event) => openAttachmentPopover(event)} style={{ cursor: 'pointer' }}><i className="fa fa-paperclip" aria-hidden="true"></i><span> {getLocalizedStrings().label.MAIL.ATTACH}</span></a>
                                    <i className="fa fa-angle-down" aria-hidden="true" onClick={(event) => openAttachmentPopover(event)} style={{ paddingLeft: '7px', cursor: 'pointer' }}></i>
                                    <Menu
                                        open={isOpenAttachmentPopover}
                                        anchorEl={anchorEl}
                                        onClose={() => closeAttachmentPopover()}
                                        style={{ width: '100%' }}
                                    >
                                        <MenuItem onClick={() => onClickUploadAttachment('Computer')} value='Computer' style={styles.popoverMenuItem}>{getLocalizedStrings().label.MAIL.COMPUTER}</MenuItem>
                                    </Menu>


                                </div>
                            </div>
                            <div>

                                <div style={{ padding: '10 4 10 10', fontSize: 15, color: '#616161', backgroundColor: '#e3e6e8', marginTop: '20px' }} >
                                    Attachments
                                </div>
                                <div className="row" style={{ marginTop: '10px' }}>
                                    {(attachFiles.length > 0) &&
                                        <div style={{ marginLeft: '-20px' }}>{getAttachmetFiles()}</div>}

                                </div>
                            </div>

                        </>

                    }
                    {stateObject === constants.TASKS && createAttachmentInfo()}
                    {stateObject === constants.APPOINTMENTS ? <div className="kd" style={{ border: '1px solid rgb(221, 221, 221)', backgroundColor: '#fff' }}>
                        <div style={{ padding: '4px 7px', fontWeight: 'bold', borderBottom: '1px solid #ddd', }}>{getLocalizedStrings().label.APPOINTMENTS_TASKS.INVITE_GUEST} </div>
                        <div style={{}}>

                            <AsyncSelect
                                name="search_by_name"
                                value={attendeeSearchName}
                                getOptionLabel={e => e.label}
                                getOptionValue={e => e.email}
                                loadOptions={getOptions}
                                onChange={onChange}
                                onFocus={(e) => handleFocus(e)}
                                backspaceRemoves={true}
                                clearable={false}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                searchPromptText={getLocalizedStrings().label.APPOINTMENTS_TASKS.SEARCH_BY_NAME}
                                placeholder={getLocalizedStrings().label.APPOINTMENTS_TASKS.SEARCH_BY_NAME}
                            />
                        </div>

                    </div> : null}
                    {isSearchAttendee || id > 0 ?
                        <div style={{ paddingTop: '20px' }}>
                            <Attendees attendees={selectedAttendees} removeAttendee={removeAttendee} recordId={id} />
                        </div>
                        : null}
                </div>
            </div>

            {showCompleteField &&
                <div className="colTwo" style={{ display: showCustomFormRightDiv, marginTop: '20px', height: '55px', width: '45%', float: 'left', padding: '0px' }}>
                    <div className="col-sm-12 col-two" style={{ padding: '0px', paddingLeft: '5px' }}>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                key={"progress-key"}
                                label={getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK_COMPLETED}
                                fullWidth={true}
                                name={getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK_COMPLETED}
                                value={taskCompleted}
                                title={getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK_COMPLETED}
                                disabled={true}
                                autoComplete="new-password"
                                margin='dense'
                                size="small"
                                className={"sf-fields-bg"}
                            /></FormControl>
                    </div>
                </div>
            }
        </div>
    )
};

const Attendees = ({ attendees, removeAttendee, recordId }) => {

    const [attendeesArr, setAttendeesArr] = useState([]);

    useEffect(() => {
        setAttendeesArr(attendees);
    }, [attendees]);

    if (attendeesArr !== null && attendeesArr.length > 0) {
        return (
            <div>
                <div style={{ borderBottom: 'solid 1px #B0B0B0', marginTop: '10px', paddingBottom: '3px', fontWeight: 'bold', paddingLeft: '7px' }}>{getLocalizedStrings().label.APPOINTMENTS_TASKS.ATTENDEES} </div>
                {attendeesArr.map((attendeeObj, index) => {
                    return <div key={index} style={{ marginTop: '3px', paddingLeft: '7px' }} title={attendeeObj.guest_email}>
                        {attendeeObj.owner === constants.CALENDER_INVITE_OWNER_YES ? attendeeObj.guest_name + ' (' + getLocalizedStrings().label.APPOINTMENTS_TASKS.OWNER + ')' : attendeeObj.guest_name}

                        {attendeeObj.t_status === constants.CALENDER_INVITE_STATUS_REQUESTED &&
                            <span style={{ right: '0px', cursor: 'pointer', position: 'absolute' }} onClick={() => removeAttendee(attendeeObj.idx)}>
                                <Icon style={{ 'fontSize': '1.3em' }} title="Remove attendee">close</Icon>
                            </span>
                        }
                        {attendeeObj.t_status === constants.CALENDER_INVITE_STATUS_ACCEPTED && attendeeObj.owner === constants.CALENDER_INVITE_OWNER_NO &&
                            <span style={{ right: '0px', cursor: 'pointer', position: 'absolute' }}>
                                <Icon style={{ 'fontSize': '1.3em' }} title={attendeeObj.t_status}>done</Icon>
                            </span>
                        }
                        {attendeeObj.t_status === constants.CALENDER_INVITE_STATUS_DECLINED &&
                            <span style={{ right: '0px', cursor: 'pointer', position: 'absolute' }}>
                                <Icon style={{ 'fontSize': '1.3em' }} title={attendeeObj.t_status}>close</Icon>
                            </span>
                        }
                        {attendeeObj.t_status === constants.CALENDER_INVITE_STATUS_TENTATIVELY_ACCEPTED &&
                            <span style={{ right: '0px', cursor: 'pointer', position: 'absolute' }} >
                                <Icon style={{ 'fontSize': '1.3em' }} title={attendeeObj.t_status}>help</Icon>
                            </span>
                        }
                    </div>
                })}
            </div>
        )
    } else { return null }
};

export default CustomForm;
