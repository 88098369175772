import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { setHeader } from '../../../services/actions/headerActions';
import { styles } from '../../../services/constants/styles';
import { importGreenFieldData, getStatusOfLastImport } from '../../../services/actions/importAction';
import { isValidParam, getIntParam, getStringParam } from '../../../services/helper/parameterVerifier'
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import ShowCircularProgress from '../components/circularProgress';
import { Button, Chip, FormControl, TextField } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

const GreenImportReport = ({ object }) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const [countryId, setCountryId] = useState('US');
    const [file, setFile] = useState({});
    const [isMounted, setisMounted] = useState(false);
    const [resultType, setResultType] = useState(null);

    const countryList = [
        {
            value: 'US',
            label: 'US',
            key: 1,
        },
    
    ]
    const resultTypeList=[
        {
            value: 'Bad Address',
            label : 'Bad Address',
            key : 1,
        },
        {
            value: 'Denied',
            label : 'Denied',
            key : 2,
        },
        {
            value: 'Approved',
            label : 'Approved',
            key : 3,
        },
        {
            value: 'Overturn',
            label : 'Overturn',
            key : 4,
        }
    ]
    useEffect(() => {
        dispatch(setHeader(object, 'Greenlight Import', null, false, true));
        setisMounted(true);
    }, []);

    const handleAction = (fieldName, event, valueObject) => {
        if (fieldName === 'country') {
            setCountryId(getStringParam(valueObject?.value));
        } else if (fieldName === 'resultType') {
            setResultType(getStringParam(valueObject?.value));
        }
    }

    const onChangeFile = (e) => {
            let tempFile = file;
            tempFile.fileName = e.target.files[0].name;
            tempFile.file = e.target.files[0];
            setFile({...tempFile});
        
    }

    const validateImport = () => {
        try {
             if( file.fileName !== undefined && file.fileName == ''){
                return false;
            }
           else if (file !== null && file !== '') {
                let fileName = file.fileName;
                if (fileName.toLowerCase().indexOf('.csv') > 0) {
                    return true;
                } 
                else {
                    return false;
                }
            } else {
                return false;
            }
        } catch (error) {
            console.error("Error in 'greenImportReport.js --> validateImport()':" + error);
        }

    }
    const startImport = () => {
        try {
            setisMounted(false);
            if (validateImport()) {
                setisMounted(true);
                const formData = new FormData();
                formData.append("file", file.file);
                let params = {};
                params.object_name = object;
                params.resultType = resultType;
                formData.append("input_param", JSON.stringify(params));
                let promise = Promise.resolve(importGreenFieldData(formData));
                let snackBarStyle = styles.snackBarStyleLongMsg;
                snackBarStyle.left = '245px';
                let snackbarBodyStyleSuccess = styles.snackbarBodyStyleSuccess;
                snackbarBodyStyleSuccess.maxWidth = '920px';
                dispatch(showCustomSnackBar(getLocalizedStrings().message.IMPORT.GREEN_LIGHT_STATUS_SUCCESS, snackbarBodyStyleSuccess, snackBarStyle));
                const _file = document.querySelector('.file');
                     _file.value = '';
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        let data = response.data.data;
                        let temp = file;
                        temp.fileName = '';
                        setFile({ ...temp });
                    })
                }

            } else {
                setisMounted(true);
                dispatch(showCustomSnackBar(getLocalizedStrings().message.IMPORT.IMPORT_CSV_FILE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }


        } catch (error) {
            console.error("Error in 'greenImportReport.js --> startImport()':" + error);
        }
    }


    let style = { margin: 20, width: '98%', paddingTop: 0, paddingLeft: 11 };
    let height = window.innerHeight - 150;
    let top = (height - 10) / 2;
   

    return (
        <div style={style}>
            {
                isMounted ?
                    <div>
                        <div className='row-clearfix'>
                            <div style={{ margin: '12px 0px', width: '250px' }}>

                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <Autocomplete
                                        style={{ ...styles.sfFormFieldContainer, paddingLeft: 0 }}
                                        key={"number"}
                                        id="combo-box-sf-greenimportreport"
                                        name="resultType"
                                        defaultValue={countryList?.find(v => v.value === countryId)}
                                        options={countryList}
                                        onChange={handleAction.bind(this, 'country')}
                                        getOptionLabel={(option) => option.label}
                                  
                                        disableClearable={false}
                                        renderInput={(params) => <TextField {...params} placeholder={"Select country"} variant="outlined" margin="dense" size='small' className={"sf-fields-bg"} />}
                                    />

                                </FormControl>
                            </div>
                        </div>
                        <div className='row-clearfix'>
                            <div style={{ margin: '12px 0px', width: '250px' }}>

                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <Autocomplete
                                        style={{ ...styles.sfFormFieldContainer, paddingLeft: 0 }}
                                        key={"number"}
                                        id="combo-box-sf-green-importreport"
                                        name="resultType"
                                       defaultValue={resultTypeList.find(v => v.value === resultType)}
                                        options={resultTypeList}
                                        onChange={handleAction.bind(this, 'resultType')}
                                        getOptionLabel={(option) => option.label}
                                        disableClearable={false}
                                        renderInput={(params) => <TextField {...params} placeholder={"Select type"} variant="outlined" margin="dense" size='small' className={"sf-fields-bg"} />}
                                    />

                                </FormControl>
                            </div>
                        </div>
                        <div className='row-clearfix'>
                            <input type="file" name="import_file" id="import_file"  className="file" text='Upload' onChange={(e) => onChangeFile(e)} />
                        </div>
                        <div className='row-clearfix' style={{ marginTop: '7px' }}>
                            <span>{getLocalizedStrings().label.IMPORT.FILE_MUST_CSV}  </span>
                        </div>

                        <div className='row-clearfix' style={{ marginTop: '35px', marginBottom: '12px', width: '250px' }} >
                            <Button
                                key='import'

                                onClick={()=>startImport()}
                                style={{ ...styles.primaryButton, }}
                            >Start Import</Button>
                        </div>
                    </div>
                    :
                    <div style={{ width: '90%', height: height }} >
                        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }} >
                            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                            </div>
                        </div>
                    </div>
            }
        </div>
    )

}

export default GreenImportReport;




