import { actions } from '..//constants/actions';
import map from 'lodash/map';
import concat from 'lodash/concat';

const calendarInitialSate = {
    mounted: false,
    calendar: { data: null },
    data: [],
    jobsAppData: [],
    error: null,
    isListView: false,
    isrefresh: false,
    isJobCalendarRefresh: false,
    calendarInfo: {},
    isRefreshForBroadcast: false,
    brdata: null,
    selectedDate: new Date(),
    selectedDateRange: null,
    unscheduledJobs: [],
    userTimeAvailability: null
}

const calendarReducer = (state = calendarInitialSate, action) => {
    switch (action.type) {
        case actions.APPOINTMENT_GET_PENDING:
            return {
                ...state,
                mounted: false,
                data: null,
                isListView: false
            }
        case actions.JOB_APPOINTMENT_GET_FULFILLED:
            let jobsAppData = action.payload.records;
            let _state = state;
            if (jobsAppData !== null && jobsAppData !== undefined) {
                var jobEventArr = [];
                let eventObj = null;
                var userColorArray = {};
                map(_state.userlist && _state.userlist.jobsAppData, function (user, index) {
                    userColorArray[user.value] = user.colorCode;
                });

                map(jobsAppData, function (eventObject, index) {
                    eventObj = {};
                    eventObj.id = eventObject.id;
                    eventObj.subject = eventObject.subject;
                    eventObj.description = eventObject.text;
                    eventObj.company = (eventObject.company !== '' || eventObject.link_info === '') ? eventObject.company : 'Empty';
                    eventObj.linkInfo = eventObject.link_info;
                    eventObj.colorCode = userColorArray[eventObject.assign];
                    eventObj.assign_user = eventObject.assign;
                    eventObj.parent_recordid = eventObject.parent_recordid;
                    eventObj.type = eventObject.event_type;
                    eventObj.company = eventObject.company;
                    eventObj.contact = eventObject.fullname;
                    let startDate = new Date(eventObject.start_date);
                    let endDate = new Date(eventObject.end_date);
                    let duration = (endDate - startDate) / (1000 * 60 * 60);
                    if (duration >= 24) {
                        eventObj.allDay = true;
                    } else {
                        eventObj.allDay = false;
                    }
                    if (startDate !== null && startDate !== undefined && endDate !== null && endDate !== undefined) {
                        eventObj.start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes(), startDate.getSeconds());
                        eventObj.end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours(), endDate.getMinutes(), endDate.getSeconds());
                        jobEventArr.push(eventObj);
                    }
                    eventObj.location = eventObject.location;
                    eventObj.visibility = eventObject.t_visibility;
                    eventObj.opportunityName = eventObject.opportunity_name;
                    eventObj.jobName = eventObject.project_name;
                });
            }

            return {
                ...state,
                mounted: true,
                jobsAppData: jobEventArr,
                isListView: false,
                isJobCalendarRefresh: false
            }
        case actions.APPOINTMENT_GET_FULFILLED:
            let data = action.payload.records;
            let _tempState = state;
            if (data !== null && data !== undefined) {
                var eventArr = [];
                let eventObj = null;
                userColorArray = {};
                map(_tempState.userlist && _tempState.userlist.data, function (user, index) {
                    userColorArray[user.value] = user.colorCode;
                });

                map(data, function (eventObject, index) {
                    eventObj = {};
                    eventObj.id = eventObject.id;
                    eventObj.subject = eventObject.subject;
                    eventObj.description = eventObject.text;
                    eventObj.company = (eventObject.company !== '' || eventObject.link_info === '') ? eventObject.company : 'Empty';
                    eventObj.linkInfo = eventObject.link_info;
                    eventObj.colorCode = userColorArray[eventObject.assign];
                    eventObj.assign_user = eventObject.assign;
                    eventObj.parent_recordid = eventObject.parent_recordid;
                    eventObj.type = eventObject.event_type;
                    eventObj.company = eventObject.company;
                    eventObj.contact = eventObject.fullname;
                    let startDate = new Date(eventObject.start_date);
                    let endDate = new Date(eventObject.end_date);
                    let duration = (endDate - startDate) / (1000 * 60 * 60);
                    if (duration >= 24) {
                        eventObj.allDay = true;
                    } else {
                        eventObj.allDay = false;
                    }
                    if (startDate !== null && startDate !== undefined && endDate !== null && endDate !== undefined) {
                        eventObj.start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes(), startDate.getSeconds());
                        eventObj.end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours(), endDate.getMinutes(), endDate.getSeconds());
                        eventArr.push(eventObj);
                    }
                    eventObj.location = eventObject.location;
                    eventObj.visibility = eventObject.t_visibility;
                    eventObj.opportunityName = eventObject.opportunity_name;
                    eventObj.jobName = eventObject.project_name;
                });
            }

            return {
                ...state,
                mounted: true,
                data: eventArr,
                isListView: false
            }
        case actions.BROADCAST_GET_FULFILLED:
            return {
                ...state,
                // mounted: true,
                brdata: action.payload.records,
                isListView: false
            }
        case actions.SELECTED_DATE_SET_FULFILLED:
            return {
                ...state,
                selectedDate: action.payload,
            }
        case actions.APPOINTMENT_UPDATE:
            return {
                ...state,
                // mounted: true,
                data: concat(state.data, action.payload),
            }
        case actions.SELECTED_DATE_RANGE_UPDATE:
            return {
                ...state,
                selectedDateRange: action.payload,
            }
        case actions.USER_TIME_AVAILABILITY_UPDATE:
            return {
                ...state,
                userTimeAvailability: action.payload,
            }
        case actions.APPOINTMENT_DELETE:
            let new_data = [];
            state.data.forEach(function (obj) {
                if (obj.id !== action.payload) {
                    new_data.push(obj);
                }
            });

            return {
                ...state,
                // mounted: true,
                data: new_data,
            }
        case actions.APPOINTMENT_ERROR:
            return {
                ...state,
                // mounted: false,
                error: action.payload
            }
        case actions.APPOINTMENT_UPDATE_DRAG_AND_DROP:
            return {
                ...state,
                // mounted: true,
                data: action.payload
            }
        case actions.CALENDAR_REFRESH:
            return {
                ...state,
                // mounted: true,
                isrefresh: action.payload
            }
        case actions.JOB_CALENDAR_REFRESH:
            return {
                ...state,
                isJobCalendarRefresh: action.payload
            }
        case actions.SET_CALENDAR_INFO:
            return {
                ...state,
                calendarInfo: action.payload
            }
        case actions.JOB_APPOINTMENT_UPDATE:
            return {
                ...state,
                calendarInfo: action.payload
            }
        case actions.CALENDAR_REFRESH_FOR_BROADCAST:
            return {
                ...state,
                isRefreshForBroadcast: action.payload,
            }
        default:
            return state;
    }
}

export default calendarReducer;
