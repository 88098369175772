import React from 'react';
import { connect } from 'react-redux';
import { constants } from '../../../services/constants/constants';

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

 class WebForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { url: null }
    }

    UNSAFE_componentWillMount() {
        this.setUrl();
    }
    setUrl = () => {
        let token = localStorage.getItem('token');
        let redirectUrl = constants.snapshotUrl;
        redirectUrl += '&token=' + token;
        redirectUrl += '&callingfor=webform';
        redirectUrl += '&recordId=' + this.props.id;
        redirectUrl += '&type=' + this.props.type;
        this.setState({ url: redirectUrl });
    }
    render() {
        return <div> <iframe title="webform" width="100%" height="100%" src={this.state.url}></iframe> </div>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebForm);
