import axios from 'axios';
import { constants } from '..//constants/constants';
import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient'
import { actions } from '..//constants/actions';
import store from '../../../src/services/store';
import { isValidParam, getObjectParam, getStringParam ,getIntParam } from '..//helper/parameterVerifier';
import { setRecentFilterReport, setRecentReport} from '..//actions/filterMenuAction';
import filter from 'lodash/filter';
import {getRecentRecord} from '..//helper/common';

export function getSalesReports() {
    let url = endPoints.SALES_REPORT.REPORT_HEADER;
    return { type: actions.SALES_REPORT_GET, payload: HTTPClient.get(url, null) };    
}

export function addReportInRedux(report) {
    let salesReports = null;
    if(isValidParam(report)) {
        let state = store.getState();
        let data = state.salesReport.data;
        let reportNames = isValidParam(data.report_names) ? data.report_names : [];
        let tempObject = filter(reportNames, (item) => {return item.parameter_id === report.parameter_id});
        if(tempObject.length === 0){
            reportNames.push(report);
            salesReports = reportNames; 
        }else{
            let tempReportNames = filter(reportNames, (item) => {return item.parameter_id !== report.parameter_id});
            tempReportNames.push(report);
            salesReports = tempReportNames;
        }
        store.dispatch({ type: actions.SALES_REPORT_ADD, payload: salesReports });
    }     
}

export function removeReportInRedux(id) {
    if(isValidParam(id)) {
        store.dispatch({ type: actions.SALES_REPORT_REMOVE, payload: id });
    }     
}

export function getRecentReport(reportData) {
    return function (dispatch) {
        var token = { "token": localStorage.getItem('token') };
        var config = {
            headers : {
                'dataType': 'jsonp',
                'contentType': "application/json; charset=utf-8",
                'Authentication': JSON.stringify(token)
            },
            params : {
                input_param : {
                    "item_type": reportData.item_type,
	                "parameter_id": reportData.parameter_id
                }
            }
        }
        axios.get(constants.endPoint + '/v3/reports/recentreports', config).then(response => {
            let recentReportData = response.data.data;
            if(recentReportData !== null && recentReportData !== undefined){
                dispatch({
                    type : "RECENT_REPORT_GET",
                    payload : recentReportData
                })
            }
        }).catch(error => {
            dispatch({
                type : "RECENT_REPORT_ERROR",
                payload : error
            })
        });
    }
}

export function addRecentFilterInRedux(reportData){
    if(isValidParam(reportData)){
        let object = constants.SALES_OBJECT;
        reportData = getObjectParam(reportData);
        let state = store.getState();
        let recentFilter = state.filters.recentFilters;
        let salesFilter = isValidParam(recentFilter[object]) ? recentFilter[object] : [];
        let selectObject = filter(salesFilter,(item)=>{
            return item.id === reportData.id;
        }) 
        if(selectObject.length ===0){
            salesFilter.unshift(reportData);
        }else{
            let tempObject = filter(salesFilter,(item)=>{ return item.id !== reportData.id})
            tempObject.unshift(reportData);
            salesFilter = tempObject;
        }
        if(salesFilter.length > 15){
            salesFilter.pop();
        }
            recentFilter[object] = salesFilter;
        store.dispatch(setRecentFilterReport(recentFilter)); 
    }
}
    
    export function addRecentRecordInRedux(reportData){
        if(isValidParam(reportData)){
            let object = constants.SALES_OBJECT;
            reportData = getObjectParam(reportData);
            let state = store.getState();
            let recentRecords = state.filters.recentRecords;
            let recentSalesRecords = isValidParam(recentRecords[object]) ? recentRecords[object]: [];
            let recentObject = filter(recentSalesRecords,(item)=>{
                return item.id === reportData.id;
            }) 
            if(recentObject.length ===0){
                recentSalesRecords.unshift(reportData);
            }else{
                let tempObject = filter(recentSalesRecords,(item)=>{ return item.id !== reportData.id})
                tempObject.unshift(reportData);
                recentSalesRecords = tempObject;    
            }
            if(recentSalesRecords.length > 25){
                recentSalesRecords.pop();
            }
            recentRecords[object] = recentSalesRecords;
            store.dispatch(setRecentReport(recentRecords)); 
        }
    }

    export function refreshSalesReports(object){
        store.dispatch(getSalesReports());
        getRecentRecord(object);
    }
   
export const getReportUrl = (object, reportType, id) => {
    let url = "#";
    try {
        object = getStringParam(object);
        reportType = getStringParam(reportType);
        id = getIntParam(id) > 0 ? id : "";
        if(reportType !== '' && object !== '') {
            let type = '';
            type = reportType;
            type = type.replace(/ /g, '-').toLowerCase();
            url = '/' + object + '/' + type + (id !== '' ? '/'+id : '') ;
        }        
    } catch (error) {
        console.error("Error in 'SalesReportAction.js ---> getReportUrl()':" + error);
    }
    return url;
}


export const getSalesReportInfo = (selectedReport) => {
    let promises = [];
    let workflowObjects = [];
    let workflowPromises = [];
    let objectWorkflow = null;
    try {
        if(isValidParam(selectedReport)) {
            
            let recentReportPromise = Promise.resolve(HTTPClient.get(endPoints.SALES_REPORT.RECENT_REPORT, selectedReport));
            promises.push(recentReportPromise);
            
            let state = store.getState();
            let reportInfo = state.salesReport.reportInfo;
            if((selectedReport.item_name === 'Pipeline Report' || selectedReport.item_name === constants.PIPELINE_REPORT || selectedReport.item_name === constants.DEALS_BY_SALES_REP || selectedReport.item_name === constants.SALES_SUMMARY_REPORT  || selectedReport.item_name === constants.CONVERSION_REPORT || selectedReport.item_name === constants.CONVERSION_BY_SALES_REP || selectedReport.item_name === constants.CONVERSION_MATRIX) && isValidParam(reportInfo)) {
                objectWorkflow = getObjectParam(reportInfo.objectWorkflow);
                if(Object.keys(objectWorkflow).length === 0) {
                    workflowObjects = [constants.ACCOUNTS_OBJECT, constants.OPPORTUNITIES_OBJECT];
                }
                workflowPromises = getWorkflows(workflowObjects);
                if(workflowPromises.length > 0) {
                    promises = promises.concat(workflowPromises);
                }
            }
            
            store.dispatch({type: actions.SALES_REPORT_INFO_GET_PENDING, payload: null});
            Promise.all(promises).then((responses) => {
                let reportInfo = {};
                if(responses.length > 0 && responses.length === promises.length) {
                    let responseIndex = 0;
                    reportInfo.recentReport = responses[responseIndex++];
                    reportInfo.objectWorkflow = objectWorkflow;
                    
                    /*** For Workflows */
                    if(workflowPromises.length > 0 && workflowObjects.length > 0 && workflowPromises.length === workflowObjects.length) {
                        reportInfo.objectWorkflow = {};
                        workflowObjects.forEach( object => {
                            reportInfo.objectWorkflow[object] = responses[responseIndex++];
                        });
                    }
                }
                store.dispatch({type: actions.SALES_REPORT_INFO_GET_FULFILLED, payload: reportInfo});
            });
        }
        
    } catch (error) {
        console.log("Error in 'SalesReportAction.js -> getSalesReportInfo()':" + error)
    }
}

const getWorkflows = (objects) => {
    let promises = [];
    try {
        objects.forEach( object => {
            let url = endPoints.WORKFLOWS.LIST_GET + "/" + object
            promises.push(Promise.resolve(HTTPClient.get(url, null)));
        });
    } catch (error) {
        console.log("Error in 'SalesReportAction.js -> getWorkflows()':" + error);
    }
    return promises;
}

export const getReportDataPromise = (selectedReport, params) => {
    let promise = null;
    try {
        if(isValidParam(selectedReport) && Object.keys(selectedReport).length > 0) {
            params = getObjectParam(params);

            let url = "";
            if (selectedReport.type === 'Activity Report Sales Rep') {
                url = endPoints.SALES_REPORT.ACTIVITY_BY_SALES_REP;
            } else  if (selectedReport.type === 'Activity Report Type') {
                url = endPoints.SALES_REPORT.ACTIVITY_BY_TYPE;
            } else  if (selectedReport.type === 'Activity Report Matrix') {
                url = endPoints.SALES_REPORT.ACTIVITY_MATRIX;
            }else  if (selectedReport.type === 'Conversion Report Status') {
                url = endPoints.SALES_REPORT.PIPELINE;
            }else  if (selectedReport.type === 'Conversion Report Source') {
                url = endPoints.SALES_REPORT.CONVERSION_BY_SOURCE;
            }else  if (selectedReport.item_name === constants.CONVERSION_MATRIX) {
                url = endPoints.SALES_REPORT.CONVERSION_MATRIX_STATUS;
            }else  if (selectedReport.type === 'Conversion Report Matrix Source') {
                url = endPoints.SALES_REPORT.CONVERSION_MATRIX_SOURCE;
            } else if (selectedReport.item_name === 'Franchise Conversion Report') {
                url = endPoints.SALES_REPORT.CONVERSION;
            } else if (selectedReport.item_name === 'Pipeline Report' || selectedReport.item_name === constants.DEALS_BY_SALES_REP ||  selectedReport.item_name === constants.CONVERSION_BY_SALES_REP ||  selectedReport.item_name === constants.PIPELINE_REPORT ) {
                url = endPoints.SALES_REPORT.PIPELINE;
            } else if (selectedReport.item_name === 'Call Report') {
                url = endPoints.SALES_REPORT.CALL;
            }  else if (selectedReport.item_name === 'Pipeline Summary') {
                url = endPoints.PIPELINE_REPORT.PIPELINE_REPORT_GET;
            }

            if(url !== '') {
                store.dispatch({type: actions.SALES_REPORT_DATA_GET_PENDING, payload: null});
                promise = Promise.resolve(HTTPClient.get(url, params));
                promise.then( response => {
                    if(isValidParam(response)) {
                        if (selectedReport.item_type === 'Activities By Sales Rep' || selectedReport.item_type === 'Activity Matrix') {
                            let state = store.getState();
                            let report = state.salesReport.reportData.report_values !== null && state.salesReport.reportData.report_values !== undefined ? state.salesReport.reportData : {report_values:[]};
                            if (report.report_values !== undefined && report.report_values.length > 0){
                                report.report_values.push(response.report_values[0]);
                                delete report.mounted;
                            } else {
                                report = response;
                            }
                            if (report.report_values.length === 1 && selectedReport.item_type === 'Activities By Sales Rep') {
                                let tempArr = [];
                                tempArr.push(report.report_values.filter(e => e.name === "activity_by_salesrep")[0]);
                                 report.report_values = tempArr;
                                report.recent_report_params = params;
                                store.dispatch({type: actions.SALES_REPORT_DATA_GET_FULFILLED, payload: report});
                            } else if (report.report_values.length === 1 && selectedReport.item_type === 'Activity Matrix') {
                                let tempArr = [];
                                tempArr.push(report.report_values.filter(e => e.name === "Activity Matrix")[0]);
                                report.report_values = tempArr;
                                report.recent_report_params = params;
                                store.dispatch({type: actions.SALES_REPORT_DATA_GET_FULFILLED, payload: report});
                            } else{
                                report.recent_report_params = params;
                                store.dispatch({type: actions.SALES_REPORT_DATA_GET_PARTIAL_FULFILLED, payload: report});
                            }
                        } else if (selectedReport.item_type === 'Conversion Report') {
                            let state = store.getState();
                            let report = state.salesReport.reportData.report_values !== null && state.salesReport.reportData.report_values !== undefined ? state.salesReport.reportData : {report_values:[]};
                            if (report.report_values !== undefined && report.report_values.length > 0){
                                report.report_values.push(response.report_values[0]);
                                delete report.mounted;
                            } else {
                                report = response;
                            }
                           
                                let tempArr = [];
                                tempArr.push(report.report_values.filter(e => e.name === "conversion_by_status")[0]);
                                report.report_values = tempArr;
                                report.recent_report_params = params;
                                store.dispatch({type: actions.SALES_REPORT_DATA_GET_FULFILLED, payload: report});
                            
                        } else {
                            let report = typeof response === 'string' ? {} : response;
                            report.recent_report_params = params;
                            store.dispatch({type: actions.SALES_REPORT_DATA_GET_FULFILLED, payload: report});
                        }
                    }
                });
            }            
        }
    } catch (error) {
        console.log("Error in 'SalesReportAction.js -> getReportData()':" + error);
    }
}

export const getSalesRepListPromise = (projectId) => {
    let promise = null;
    if (isValidParam(projectId)) {
        let params = {};
        params.project_id = projectId;
        promise = Promise.resolve(HTTPClient.get(endPoints.SALES_REPORT.SALES_REP_FRANCHISE, params));        
    }
    return promise;
}

export const getTabularReportPromise = (params) => {
    let promise = null;
    if (isValidParam(params)) {
        promise = Promise.resolve(HTTPClient.get(endPoints.USERREPORT.GET_TABULAR_REPORT, params));        
    }
    return promise;
}
