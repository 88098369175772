
import React from 'react';
import PropTypes from 'prop-types';
import { SFTable } from './table';

export const Query = (props) => {
    return <div><SFTable cols={props.data.column} data={props.data.data} widgetId={props.widgetId}  tableHeader={props.data.header} /></div>
   
}

Query.propTypes = {
    cols: PropTypes.object,
    data: PropTypes.object,
    widgetId: PropTypes.string
};