import React from "react";
import FroalaEditor from "react-froala-wysiwyg";
import Froalaeditor from 'froala-editor'
import $ from "jquery";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	Menu,
	MenuItem,
	Radio,
	RadioGroup,
	Select as SelectField,
	TextField,
	InputLabel,
	Icon,
	IconButton,
	Chip,
} from "@mui/material";
import ShowCircularProgress from "../components/circularProgress";
import * as HTTPClient from "../../../services/helper/httpClient";
import { endPoints } from "../../../services/constants/endPoints";
import {
	constants,
	EVENT_TYPE_KEYS,
} from "../../../services/constants/constants";
import { styles } from "../../../services/constants/styles";
import { Navigate } from "react-router-dom";
import {
	getAppDialog,
	getAppDrawer,
} from "../../../services/actions/appContainerActions";
import { Link } from "react-router-dom";
import { setHeader } from "../../../services/actions/headerActions";
import { saveGroup } from "../../../services/actions/batchActions";
import {
	dateTimeFormat,
	timeZone,
	isEmailExistsInList,
	sortArrayObjectByProperty,
} from "../../../services/helper/utils";
import moment from "moment";
import {
	hasAccessPermission,
	addCampaignTypeIntoReduxStore,getObjectLabelByObject
} from "../../../services/helper/common";
import * as sfDialogs from "../components/sfDialogs";
import {
	addTab,
	TYPE_LIST_VIEW,
	getActiveTab,
} from "../../../services/helper/sfTabManager";
import {
	isValidParam,
	getIntParam,
	getStringParam,
	getArrayParam,
	getObjectParam,
} from "../../../services/helper/parameterVerifier";
import { setMarketingResults } from "../../../services/actions/marketingReportAction";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { refreshListView } from "../../../services/actions/listViewAcions";
import { refreshCalendarForBroadcast } from "../../../services/actions/calendarActions";
import { getCoupons } from "../../../services/helper/common";
import Autocomplete from "@mui/material/Autocomplete";
import { getAppCustomDrawer } from "../../../services/actions/appContainerActions";
import { getCouponsAction } from "../../../services/actions/couponActions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

require("froala-editor/js/froala_editor.pkgd.min.js");
require("froala-editor/css/froala_editor.pkgd.min.css");
require("froala-editor/js/plugins/font_family.min.js");
require("froala-editor/css/froala_style.css");

const BroadcastCampaign = (id) => {
	const dispatch = useDispatch();
	const [campaign, setCampaign] = useState({
		bd_name: "",
		sender_name: "",
		subject: "",
		email_message: "",
		id: id.id | 0,
		sender_email: "",
		campaign_targets: [],
		view_as_web_page: false,
		t_status: null,
		campaign_type: "",
	});
	const [personalize, setPersonalize] = useState(-1);
	const [mounted, setMounted] = useState(false);
	const [senderEmailOpen, setSenderEmailOpen] = useState(false);
	const [personilizeOpen, setPersonilizeOpen] = useState(false);
	const [moreActionOpen, setMoreActionOpen] = useState(false);
	const [error, setError] = useState({
		bd_name: "",
		filter_options: "",
		subject: "",
		sender_name: "",
		sender_email: "",
	});
	const [open, setOpen] = useState(false);
	const [buttonVisible, setButtonVisible] = useState("none");
	const [redirect, setRedirect] = useState(false);
	const [redirectUrl, setRedirectUrl] = useState("");
	const [personalize_fields, setPersonalize_fields] = useState({
		"j_contacts.company": "Company",
		"j_contacts.first_name": "First name",
		"j_contacts.last_name": "Last name",
	});
	const [verifid_email_ids, setVerifid_email_ids] = useState([]);
	const [disabled, setDisabled] = useState(false);
	const [filter_options, setFilter_options] = useState([]);
	const [selected_filter_options, setSelected_filter_options] = useState([]);
	const [filter_type, setFilter_type] = useState("group");
	const [loaded_filter_options, setLoaded_filter_options] = useState({
		group: { loaded: false, options: [] },
		contact: { loaded: false, options: [] },
		account: { loaded: false, options: [] },
	});
	const [options_loading, setOptions_loading] = useState(true);
	const [multi, setMulti] = useState(true);
	
	const [email_credit_balance, setEmail_credit_balance] = useState(null);
	const [scheduled, setScheduled] = useState(false);
	const [scheduled_on, setScheduled_on] = useState(null);
	const [stickyStyle, setStickyStyle] = useState({});
	const [anchorMoreActionEl, setAnchorMoreActionEl] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorPersonilizeEl, setAnchorPersonilizeEl] = useState(null);
	const [result, setResult] = useState(false);
	const [resultContent, setResultContent] = useState(null);
	const [resultExecuteStart, setResultExecuteStart] = useState(false);
	const [editorInstance, seteditorInstance] = useState(null);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [placeholder, setPlaceholder] = useState(null);
	const [cursorposition, setCursorposition] = useState(0);
	const [isFullScreenEditor, setIsFullScreenEditor] = useState(false);
	const [savedSenderEmail, setSavedSenderEmail] = useState(null);
	const [bdName, setBdName] = useState("");
	const [isCopy, setIsCopy] = useState(false);
	const [campTypelistArr, setCampTypelistArr] = useState(null);
	const [campTypeMenuItems, setCampTypeMenuItems] = useState(null);
	const [disabledFrmEmail, setDisabledFrmEmail] = useState(false);
	const [couponsData, setCouponsData] = useState({});
	const [couponLoader, setCouponLoader] = useState(true);

	const [isOpenNoteTypeList, setIsOpenNoteTypeList] = useState(false);
	const [anchorELNoteTypeList, setAnchorELNoteTypeList] = useState(null);
	const [isDisableRefresh, setIsDisableRefresh] = useState(false);
	const [merge_fields, setMerge_fields] = useState(null);

	const appState = useSelector((state) => state.app);
	const margefields = useSelector((state) => state.sfForm.data.contacts.fields);
	const data = useSelector((state) => state.data);
	const campaignTypes = useSelector((state) => state.campaignTypes);
	const coupons = useSelector((state) => state.coupons);

	const loadBroadcastCampaignData = (bId) => {
		let fields = margefields;
		fields = fields.filter((f) => f.name !== "header" && f.name !== "");
		let mergeFields = {};
		sortArrayObjectByProperty(fields, "label");
		for (let i = 0; i < fields.length; i++) {
			let fieldsName = "j_contacts." + fields[i].name;
			mergeFields[fieldsName] = fields[i].label;
		}

		if (bId !== undefined && bId !== null && bId !== 0) {
			var response = Promise.resolve(
				HTTPClient.get(endPoints.BROADCAST_CAMPAIGNS.EDIT + "/" + bId, null)
			);
			response.then((response) => {
				setPersonalize_fields(mergeFields);
				const tempcampaign_details = response.campaign_details;
				setBdName(tempcampaign_details.bd_name);
				let tempCampaign = campaign;
				tempCampaign.bd_name = tempcampaign_details.bd_name;
				tempCampaign.sender_name = tempcampaign_details.from_name;
				tempCampaign.id = id.id;
				tempCampaign.sender_email = tempcampaign_details.sender_email;
				setCampaign(tempCampaign);

				if (
					tempcampaign_details.from_name !== null &&
					tempcampaign_details.from_name.trim() === constants.RECORD_OWNER
				) {
					setDisabledFrmEmail(true);
					setIsDisableRefresh(true);
					tempCampaign.sender_email = "";
					setCampaign(tempCampaign);
				}
				tempCampaign.view_as_web_page = tempcampaign_details.view_as_web_page;
				tempCampaign.t_status = tempcampaign_details.t_status;
				tempCampaign.subject = response.template_details.subject;
				tempCampaign.email_message = response.template_details.email_message;
				tempCampaign.campaign_type =
					tempcampaign_details.campaign_type === 0
						? ""
						: tempcampaign_details.campaign_type;
				setCampaign(tempCampaign);
				setMerge_fields(response.available_mail_merge_fields);
				setButtonVisible("");
				setFilter_type(tempcampaign_details.target_type);

				if (tempcampaign_details.target_type === "666") {
					setFilter_type("contact");
				}
				let tempverifid_email_ids = generateFromEmailOptions(
					response.verifid_email_ids
				);
				setVerifid_email_ids(tempverifid_email_ids);

				if (
					campaign["sender_email"] === null ||
					campaign["sender_email"] === ""
				) {
					tempCampaign.sender_email = "";
					setCampaign(tempCampaign);
				} else if (
					!isEmailExistsInList(verifid_email_ids, campaign["sender_email"]) &&
					(campaign["sender_email"] === null )
				) {
					tempCampaign.sender_email = appState.me.email;
					setCampaign(tempCampaign);
				}
				let tempsavedSenderEmail = campaign["sender_email"];
				setSavedSenderEmail(tempsavedSenderEmail);
				setEmail_credit_balance(response.email_credit_balance);
				let tempError = error;
				tempError["bd_name"] = "";
				tempError["filter_options"] = "";
				tempError["subject"] = "";
				tempError["sender_name"] = "";
				tempError["sender_email"] = "";
				setError({...tempError});

				if (
					tempcampaign_details.t_status === "Queued" ||
					tempcampaign_details.t_status === "Executed" ||
					tempcampaign_details.t_status === "Halted" ||
					tempcampaign_details.t_status === "Running"
				) {
					setDisabled(true);
					setScheduled(true);
					setScheduled_on(tempcampaign_details.run_date);
				}

				if (tempcampaign_details.t_status === "Running") {
					setDisabled(true);
				}

				if (tempcampaign_details.campaign_targets) {
					if (getArrayParam(tempcampaign_details.campaign_targets).length > 0) {
						let tempselected_filter_options =
							tempcampaign_details.campaign_targets.map(function (
								target,
								index
							) {
								if (tempcampaign_details.target_type === "group") {
									return {
										value: target.id,
										label: target.name,
										title: target.name,
									};
								} else if (tempcampaign_details.target_type === "666") {
									return {
										value: target.id,
										label: target.name,
										type: tempcampaign_details.target_type,
									};
								} else {
									return {
										value: target.id,
										label: target.name,
									};
								}
							});

						setSelected_filter_options(tempselected_filter_options);

						let tempcampaign_targets =
							tempcampaign_details.campaign_targets.map(function (
								target,
								index
							) {
								return {
									id: target.id,
									name: target.name,
									type: tempcampaign_details.target_type,
								};
							});
							let setOptions = tempcampaign_details.campaign_targets.map(function (
								target,
								index
							) {
								return {
									value: target.id,
									label: target.name,
								};
							});
						tempCampaign.campaign_targets = tempcampaign_targets;
						setCampaign(tempCampaign);

						if (tempcampaign_details.target_type === "contact" || tempcampaign_details.target_type === "account" || tempcampaign_details.target_type === "666") {
							setSelected_filter_options(setOptions[0]);
						}
					}
				} else {
					setSelected_filter_options([]);
					tempCampaign.campaign_targets = [];
					setCampaign(tempCampaign);
				}
				setCursorposition(response.template_details.subject.length);

				if (tempcampaign_details.target_type === "group") {
					setPlaceholder(getLocalizedStrings().label.CAMPAIGNS.SELECT_GROUP);
				} else if (tempcampaign_details.target_type === "contact") {
					setPlaceholder(
						getLocalizedStrings().label.CAMPAIGNS.SELECT_CONTACT_FILTER
					);
				} else if (tempcampaign_details.target_type === "account") {
					setPlaceholder(
						getLocalizedStrings().label.CAMPAIGNS.SELECT_ACCOUNT_FILTER
					);
				}
				setMounted(true);

				loadFilterOptions(tempcampaign_details.target_type);

				if (
					id.type !== "copy" &&
					(tempcampaign_details.t_status === "Executed" ||
						tempcampaign_details.t_status === "Running")
				) {
					loadResults();
				}
				if (id.type === "copy") {
					handleCopy();
				}
				//this.startAutoSaveInterval();
			});
		} else {
			let params = {
				template_id: data.obj.template_id,
			};

			response = Promise.resolve(
				HTTPClient.get(endPoints.BROADCAST_CAMPAIGNS.NEW, params)
			);
			response.then((response) => {
				let tempCampaign = campaign;
				tempCampaign['bd_name'] = "";
				tempCampaign['sender_name'] = appState.me.name;
				tempCampaign['sender_email'] = appState.me.email;
				tempCampaign['id'] = 0;
				tempCampaign['subject'] = response.template_details.subject;
				tempCampaign['email_message'] = response.template_details.email_message;
				setCampaign(tempCampaign);
				setMerge_fields(response.available_mail_merge_fields);
				setEmail_credit_balance(response.email_credit_balance);
				setPlaceholder(getLocalizedStrings().label.CAMPAIGNS.SELECT_GROUP);
				let tempError = error;
				tempError["bd_name"] = "";
				tempError["filter_options"] = "";
				tempError["subject"] = "";
				tempError["sender_name"] = "";
				tempError["sender_email"] = "";
				setError({...tempError});
				setMounted(true);
				let temp = generateFromEmailOptions(response.verifid_email_ids);
				setVerifid_email_ids(temp);
				setSelectedTemplate(data.obj.template_id);
				setSelectedCategory(data.obj.category_id);
				setCursorposition(0);

			});
			loadFilterOptions("group");
		}
		getCampaignTypeList();
	};

	const generateFromEmailOptions = (verifid_email_ids) => {
		let verifid_email_options = [];
		try {
			if (
				verifid_email_ids !== undefined &&
				Array.isArray(verifid_email_ids) &&
				verifid_email_ids.length > 0
			) {
				verifid_email_ids.forEach(function (value) {
					if (!isEmailExistsInList(verifid_email_options, value)) {
						verifid_email_options.push({
							value: value,
							label: value,
						});
					}
				});
			}
			if (!isEmailExistsInList(verifid_email_options, appState.me.email)) {
				verifid_email_options.unshift({
					value: appState.me.email,
					label: appState.me.email,
				});
			}
			verifid_email_options.push({
				value: "-1",
				label:
					"---" +
					getLocalizedStrings().label.BROADCAST_CAMPAIGNS
						.VERIFY_EMAIL_ADDRESS_OPTION +
					"---",
			});
		} catch (error) {
			console.error(
				"Error in 'BroadcastCampaign.js -> generateFromEmailOptions()':" + error
			);
		}

		return verifid_email_options;
	};
	const focusSubject = (event) => {
		try {
			setCursorposition(event.target.selectionStart);
		} catch (error) {
			console.log("Error in focusSubject() in salseTemplate.js : " + error);
		}
	};

	const loadResults = () => {
		setResultExecuteStart(true);
		setResult(false);
		setResultContent(null);
		let params = {
			campaign_id: campaign.id,
			report_type: "Broadcast Report",
		};
		var promise = Promise.resolve(
			HTTPClient.get(endPoints.BROADCAST_CAMPAIGNS.RESULTS, params)
		);

		promise.then((response) => {
			if (
				Array.isArray(response.marketing_results) &&
				response.marketing_results.length > 0
			) {
				setResult(true);
				setResultContent(response);
				setResultExecuteStart(false);
			} else {
				setResult(true);
				setResultContent("");
				setResultExecuteStart(false);
			}
		});
	};

	const checkAndResetSelectedFilterOptions = (_filter_type) => {
		if (_filter_type === "group") {
			let temp_selected_filter_options = [];
			let campaign_targets = [];
			if (getArrayParam(selected_filter_options).length > 0) {
				if (getStringParam(campaign.t_status) !== "Executed") {
					selected_filter_options.forEach(function (selectedObj) {
						filter_options.forEach(function (listObj) {
							if (selectedObj.value === listObj.value) {
								temp_selected_filter_options.push(selectedObj);
								campaign_targets.push({
									id: selectedObj.value,
									name: selectedObj.label,
									type: "group",
								});
							}
						});
					});
					let selected_filter_options_data =
						temp_selected_filter_options.length > 0
							? temp_selected_filter_options
							: selected_filter_options;
					setSelected_filter_options(selected_filter_options_data);
					let tempCampaign = campaign;
					tempCampaign.campaign_targets = campaign_targets;
					setCampaign(tempCampaign);
					if (
						disabled &&
						getStringParam(campaign.t_status) !== "Queued" &&
						(id.type === "copy" || id.type === "edit")
					) {
						setDisabled(false);
					}

					if (
						scheduled &&
						getStringParam(scheduled_on) !== "" &&
						id.type === "copy"
					) {
						setScheduled(false);
						setScheduled_on(null);
					}
				}
			}
		}
	};

	const loadFilterOptions = (_filter_type) => {
		if (_filter_type === "666") {
			_filter_type = "contact";
		}
		setOptions_loading(true);
		if (loaded_filter_options[_filter_type].loaded) {
			let multi = _filter_type === "group" ? true : false;
			setFilter_options(loaded_filter_options[_filter_type].options);
			setFilter_type(_filter_type);
			setOptions_loading(false);
			setMulti(multi);
			checkAndResetSelectedFilterOptions(_filter_type);

			return;
		}

		let end_points, params;
		if (_filter_type === "group") {
			end_points = endPoints.GROUP.LIST;
			params = {
				fields: ["name", "id"],
				search_field_name: "t_status",
				search_text: "Active",
				search_type: constants.SEARCH_TYPE_EXACT,
			};
			setMulti(true);
		} else if (_filter_type === "contact") {
			end_points = endPoints.CONTACTS.CAMPAIGN_FILTER;
			params = null;
			setMulti(false);
		} else if (_filter_type === "account") {
			end_points = endPoints.ACCOUNTS.CAMPAIGN_FILTER;
			params = null;
			setMulti(false);
		}

		var response = Promise.resolve(HTTPClient.get(end_points, params));

		response.then((response) => {
			let options = [];
			let records;
			if (_filter_type === "contact" || _filter_type === "account") {
				records = response.queries;
			} else {
				records = response.records;
			}
			records.forEach(function (data) {
				let filterObject = {
					value: data.id.toString(),
					label: data.name,
				};
				if (data.hasOwnProperty("type")) {
					filterObject.type = data.type;
				}
				if (_filter_type === "group") {
					filterObject.title = data.name;
				}
				options.push(filterObject);
			});

			options.sort(function (option1, option2) {
				let opt1 = option1.label.toLowerCase();
				let opt2 = option2.label.toLowerCase();
				if (opt1 < opt2) return -1;
				if (opt1 > opt2) return 1;
				return 0;
			});

			if (_filter_type === "contact" || _filter_type === "account") {
				if (
					getStringParam(selected_filter_options).length === 0 ||
					(Array.isArray(selected_filter_options) &&
						getArrayParam(selected_filter_options).length === 0)
				) {
					setSelected_filter_options("");
				}
			}
			setFilter_options(options);
			let temploaded_filter_options = loaded_filter_options;
			temploaded_filter_options[_filter_type].loaded = true;
			temploaded_filter_options[_filter_type].options = options;
			setLoaded_filter_options(temploaded_filter_options);
			setFilter_type(_filter_type);
			setOptions_loading(false);
			checkAndResetSelectedFilterOptions(_filter_type);
			if (
				Array.isArray(selected_filter_options) &&
				selected_filter_options.length > 0
			) {
				reGenerateFilterOptions(selected_filter_options);
			}
		});
	};

	const validateForm = (callFrom = "default") => {
		const tempCampaign = campaign;
		let tempError = error;
		let isValid = true;

		let addl_msg = "";
		if (tempCampaign.bd_name.trim() !== "") {
			addl_msg =
				" " +
				getLocalizedStrings().message.COMMON.FOR +
				" " +
				tempCampaign.bd_name +
				" ";
		}
		if (tempCampaign.bd_name.trim() === "") {
			isValid = false;
			tempError["bd_name"] =
				getLocalizedStrings().message.BROADCAST_CAMPAIGNS.NAME_BLANK;
			setError({...tempError});
		}

		if (tempCampaign.sender_name.trim() === "") {
			isValid = false;
			tempError["sender_name"] =
				getLocalizedStrings().message.BROADCAST_CAMPAIGNS.FROM_NAME_BLANK +
				addl_msg;
			setError({...tempError});
		}

		if (tempCampaign.subject.trim() === "") {
			isValid = false;
			tempError["subject"] =
				getLocalizedStrings().message.BROADCAST_CAMPAIGNS.SUBJECT_BLANK +
				addl_msg;
			setError({...tempError});
		}

		if (
			!disabledFrmEmail &&
			(tempCampaign.sender_email === "-1" ||
				tempCampaign.sender_email === "" ||
				tempCampaign.sender_email === undefined)
		) {
			isValid = false;
			tempError["sender_email"] =
				getLocalizedStrings().message.BROADCAST_CAMPAIGNS.FROM_EMAIL_BLANK +
				addl_msg;
				setError({...tempError});
		}
		if (
			callFrom !== "testMail" &&
			(selected_filter_options === "" ||
				(Array.isArray(selected_filter_options) &&
					selected_filter_options.length === 0))
		) {
			isValid = false;

			let filter_type_msg = "";
			if (filter_type === "group") {
				filter_type_msg =
					getLocalizedStrings().message.BROADCAST_CAMPAIGNS.FILTER_TYPE_GROUP;
			} else if (filter_type === "contact") {
				filter_type_msg =
					getLocalizedStrings().message.BROADCAST_CAMPAIGNS.FILTER_TYPE_CONTACT;
			} else if (filter_type === "account") {
				filter_type_msg =
					getLocalizedStrings().message.BROADCAST_CAMPAIGNS.FILTER_TYPE_ACCOUNT;
			}

			tempError["filter_options"] =
				getLocalizedStrings().message.BROADCAST_CAMPAIGNS
					.GROUP_FILTER_BLANK_COMMON_MSG +
				" " +
				filter_type_msg +
				addl_msg;
				setError({...tempError});
		} else if (
			typeof selected_filter_options === "object" &&
			selected_filter_options.value === -1 &&
			callFrom !== "testMail"
		) {
			let filter_type_msg = "";
			if (filter_type === "contact") {
				filter_type_msg =
					getLocalizedStrings().message.BROADCAST_CAMPAIGNS.FILTER_TYPE_CONTACT;
			} else if (filter_type === "account") {
				filter_type_msg =
					getLocalizedStrings().message.BROADCAST_CAMPAIGNS.FILTER_TYPE_ACCOUNT;
			}

			tempError["filter_options"] =
				getLocalizedStrings().message.BROADCAST_CAMPAIGNS
					.GROUP_FILTER_BLANK_COMMON_MSG +
				" " +
				filter_type_msg +
				addl_msg;
			setError({...tempError});
		}

		return isValid;
	};

	const saveCampaign = () => {
		if (!validateForm()) {
			return false;
		}
		let hasPermission = false;
		let tempCampaign = campaign;
		if (tempCampaign.id > 0) {
			hasPermission = hasAccessPermission(
				constants.BROADCAST_CAMPAIGNS_OBJECT,
				constants.SECURITY_LEVEL_TYPE_ACCESS,
				constants.ACCESS_TYPE_EDIT
			);
		} else {
			hasPermission = true;
		}

		if (hasPermission) {
			let campType = 0;
			if (
				isValidParam(tempCampaign.campaign_type) &&
				tempCampaign.campaign_type !== ""
			) {
				campType = tempCampaign.campaign_type;
			}
			let campaignName = tempCampaign.bd_name.trim();
			tempCampaign.bd_name = campaignName;
			setCampaign(tempCampaign);
			let params = {
				id: tempCampaign.id,
				bd_name: campaignName,
				campaign_targets: campaign.campaign_targets,
				sender_name: tempCampaign.sender_name,
				sender_email: tempCampaign.sender_email,
				subject: tempCampaign.subject,
				email_message: tempCampaign.email_message,
				view_as_web_page: tempCampaign.view_as_web_page,
				campaign_type: campType,
			};

			var response = Promise.resolve(
				HTTPClient.post(endPoints.BROADCAST_CAMPAIGNS.SAVE, params)
			);
			setMounted(false);
			let tempCampaign_id = campaign.id;
			response.then((response) => {
				if (response.status === 0) {
					setBdName(campaignName);
					if (params.id === 0) {
						tempCampaign.id = response.data.id[0];
						tempCampaign.t_status = "New";
						setCampaign(tempCampaign);
						setButtonVisible("none");
					}
					if (tempCampaign_id === 0) {
						dispatch(
							showCustomSnackBar(
								getLocalizedStrings().message.BROADCAST_CAMPAIGNS.SAVE,
								styles.snackbarBodyStyleSuccess,
								styles.snackBarStyleTop
							)
						);
					} else if (tempCampaign_id > 0) {
						dispatch(
							showCustomSnackBar(
								getLocalizedStrings().message.BROADCAST_CAMPAIGNS.UPDATE,
								styles.snackbarBodyStyleSuccess,
								styles.snackBarStyleTop
							)
						);
					}

					setMounted(true);
					setButtonVisible("inline-block");
					refreshListView(constants.BROADCAST_CAMPAIGNS_OBJECT);
					dispatch(refreshCalendarForBroadcast(true));
				} else if (response.status === -1 && response.error.message !== "") {
					dispatch(
						showCustomSnackBar(
							response.error.message,
							styles.snackbarBodyStyleError,
							styles.snackBarStyleLongMsg
						)
					);
					setMounted(true);
					setButtonVisible("none");
				}
			});
		} else {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				getLocalizedStrings().message.COMMON.ACCESS_DENIED,
				null
			);
		}
	};

	const reGenerateFilterOptions = (options) => {
		let new_options = loaded_filter_options["group"].options;
		new_options = new_options.filter(
			(el) => !options.filter((els) => els.value === el.value).length
		);
		if (options.length === 3) {
			new_options = new_options.map(function (option, index) {
				return { ...option, isDisabled: true, title: option.label };
			});
		} else {
			new_options = new_options.map(function (option, index) {
				return {
					label: option.label,
					value: option.value,
					title: option.label,
				};
			});
		}

		new_options.sort(function (option1, option2) {
			let opt1 = option1.label.toLowerCase();
			let opt2 = option2.label.toLowerCase();
			if (opt1 < opt2) return -1;
			if (opt1 > opt2) return 1;
			return 0;
		});
		setFilter_options(new_options);
	};

	const handleSelectChange = (event, options) => {
		let campaign_targets = [];

		let temp_filter_type = filter_type;
		if (options.hasOwnProperty("type")) {
			temp_filter_type = options.type;
			temp_filter_type = temp_filter_type.toString();
		}
		if (Array.isArray(options)) {
			options.forEach(function (data) {
				campaign_targets.push({
					id: data.value,
					name: data.label,
					type: temp_filter_type,
				});
			});
			reGenerateFilterOptions(options);
		} else {
			campaign_targets.push({
				id: options.value,
				name: options.label,
				type: temp_filter_type,
			});
		}

		let tempCampaign = campaign;
		tempCampaign.campaign_targets = campaign_targets;
		setCampaign(tempCampaign);
		setSelected_filter_options(options);
		setPlaceholder(null);
		let tempError = error;
		tempError.filter_options = "";
		setError({...tempError});
	};

	useEffect(() => {
		loadBroadcastCampaignData(id.id);
		loadCoupons();
	}, []);

	const openNoteTypeList = (event) => {
		setIsOpenNoteTypeList(true);
		setAnchorELNoteTypeList(event.currentTarget);
	};
	const loadCoupons = () => {
		try {
			let tempcoupons = {};
			if (!coupons?.couponMounted) {
				let promise = getCoupons();
				promise.then((response) => {
					if (isValidParam(response.records)) {
						dispatch(getCouponsAction(response.records));
						let records = getArrayParam(response.records);
						records.forEach((record) => {
							let discount =
								record.discount_type.toLowerCase() === "percentage"
									? record.discount + "%"
									: "$" + record.discount;
							tempcoupons[" " + record.name] = `${record.name} (${discount})`;
						});
						setCouponsData(tempcoupons);
						setCouponLoader(false);
					}
				});
			} else {
				if (isValidParam(coupons.coupons)) {
					let records = getArrayParam(coupons.coupons);
					records.forEach((record) => {
						let discount =
							record.discount_type.toLowerCase() === "percentage"
								? record.discount + "%"
								: "$" + record.discount;
						tempcoupons[" " + record.name] = `${record.name} (${discount})`;
					});
				}
				setCouponsData(tempcoupons);
				setCouponLoader(false);
			}
		} catch (error) {
			console.error("Error in 'sendMail.js -> BroadcastCampaign()':" + error);
		}
	};

	const handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		let tempCampaign = campaign;
		let tempError = error;
		let tempDisabledFrmEmail = disabledFrmEmail;
		let tempIsDisableRefresh = isDisableRefresh;

		tempCampaign[name] = value;
		if (value.trim() !== "") {
			tempError[name] = "";
		}

		setCursorposition(event.target.selectionStart);

		if (name === "sender_name") {
			if (
				value.trim().replace(/ /g, "").toLowerCase() ===
				constants.RECORD_OWNER.replace(/ /g, "").toLowerCase()
			) {
				tempDisabledFrmEmail = true;
				tempCampaign["sender_name"] = constants.RECORD_OWNER;
				tempCampaign["sender_email"] = "";
				tempIsDisableRefresh = true;
			} else {
				tempDisabledFrmEmail = false;
				tempIsDisableRefresh = false;

				if (campaign.sender_email === "") {
					tempCampaign["sender_email"] = appState.me.email;
				}
			}
		}

		setDisabledFrmEmail(tempDisabledFrmEmail);
		setIsDisableRefresh(tempIsDisableRefresh);
		setCampaign(tempCampaign);
	}


	const handleCopy = () => {
		let tempCampaign = campaign;
		let hasPermission = false;
		hasPermission = hasAccessPermission(
			constants.BROADCAST_CAMPAIGNS_OBJECT,
			constants.SECURITY_LEVEL_TYPE_ACCESS,
			constants.ACCESS_TYPE_CREATE
		);
		if (hasPermission) {
			let params = {
				id: campaign.id,
				bd_name: campaign.bd_name,
			};
			var response = Promise.resolve(
				HTTPClient.get(endPoints.BROADCAST_CAMPAIGNS.COPY_OF_NAME_GET, params)
			);
			response.then((response) => {
				if (isValidParam(response)) {
					tempCampaign.id = 0;
					response = response
						.replace("copy", getLocalizedStrings().label.COMMON.COPY)
						.replace("of", getLocalizedStrings().label.COMMON.OF);
					tempCampaign.bd_name = response;
					tempCampaign.t_status = null;
					setCampaign(tempCampaign);

					setBdName(response);
					setButtonVisible("none");
					setDisabled(false);
					setScheduled(false);
					setScheduled_on(null);
					setResult(false);
					setResultExecuteStart(false);
					setIsCopy(true);

					let verifiedEmail = savedSenderEmail;
					if (isValidParam(verifiedEmail) && verifiedEmail.length > 0) {
						let emailObj = { label: verifiedEmail, value: verifiedEmail };
						handleSenderEmailChange({},emailObj);
					}
					if (
						selected_filter_options.length > 0 &&
						(id.type === "copy" || id.type === "edit")
					) {
						if (filter_options.length > 0 && filter_type === "group") {
							checkAndResetSelectedFilterOptions(filter_type);
						}
					}

					if (editorInstance !== null) {
						editorInstance.edit.on();
					}
				}
			});
		} else {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				getLocalizedStrings().message.COMMON.ACCESS_DENIED,
				null
			);
		}
	};


	const handleSelectPersonilizeChange = (event,value) => {
		setPersonalize(value);
		setPersonilizeOpen(false);
		let front = campaign["subject"].substring(0, cursorposition);
		let back = campaign["subject"].substring(
			cursorposition,
			campaign["subject"].length
		);
		let tempCampaign = campaign;
		setCampaign({...tempCampaign,subject: front + ' \${' + value + '}' + back})
		setCursorposition(campaign["subject"].length);
		setIsOpenNoteTypeList(false);
	};

	const handleModelChange = (event) => {
		let tempCampaign = campaign;
		tempCampaign.email_message = event;
		setCampaign(tempCampaign);
	};

	const handleCheckBox = (event, isInputChecked) => {
		let tempCampaign = campaign;
		tempCampaign.view_as_web_page = event;
		setCampaign(tempCampaign);
	};

	const changeRadioButton = (value) => {
		if (value === "group") {
			setSelected_filter_options([]);
		} else {
			setSelected_filter_options("");
		}
		setFilter_options([]);
		let tempError = error;
		tempError.filter_options = "";
		setError(error);
		setFilter_type(value);
		loadFilterOptions(value);
		if (value === "group") {
			setPlaceholder(getLocalizedStrings().label.CAMPAIGNS.SELECT_GROUP);
		} else if (value === "contact") {
			setPlaceholder(getLocalizedStrings().label.CAMPAIGNS.SELECT_A_FILTER +
				getObjectLabelByObject(constants.CONTACTS_OBJECT));

		} else if (value === "account") {
			setPlaceholder(getLocalizedStrings().label.CAMPAIGNS.SELECT_A_FILTER +
				getObjectLabelByObject(constants.ACCOUNTS_OBJECT));
		}
	};

	const handleSenderEmailChange = (event,value) => {
		let tempCampaign = campaign;
		let tempError = error;
		value = value.value;
		if (value === "-1") {
			openVerifyEmailPopup();
			tempCampaign["sender_email"] = value;
		} else {
			tempCampaign["sender_email"] = value;
		}
		tempError["sender_email"] = "";

		setCampaign(tempCampaign);
		setError({...tempError});
	};

	const renderPersonalizeField = () => {
		let personalizeObject = personalize_fields;
		return Object.keys(personalizeObject).map((itemKey, index) => {
			return (
				<MenuItem
					key={itemKey + index}
					value={itemKey}
					style={styles.popoverMenuItem}
					fullWidth={true}
					labelstyle={{ color: "#479ccf" }}
					underlinestyle={{ display: "none" }}
					autowidth={"true"}
					onClick={(event) =>
						handleSelectPersonilizeChange( event,itemKey)
					}
				>
					{personalizeObject[itemKey]}
				</MenuItem>
			);
		});
	};

	const setTemplateData = (obj) => {
		let tempCampaign = campaign;
		let params = {
			template_id: obj.template_id,
		};
		setSelectedTemplate(obj.template_id);
		setSelectedCategory(obj.category_id);
		var promise = Promise.resolve(
			HTTPClient.get(endPoints.MARKETING_TEMPLATE.TEMPLATE_GET, params)
		);

		promise.then((response) => {
			tempCampaign["subject"] = response.subject;
			tempCampaign["email_message"] = response.mail_body;
			setCampaign(tempCampaign);
			dispatch(getAppDialog(false, constants.TEMPLATE_DIALOG, null, null, null, null));
		});
	};

	const confirmSchedule = (obj) => {
		let params = campaign;
		params.scheduled_on = obj.schedule_date;
		params.t_status = "scheduled";
		let campType = 0;
		if (isValidParam(params.campaign_type) && params.campaign_type !== "") {
			campType = params.campaign_type;
		}
		params.campaign_type = campType;
		var promise = Promise.resolve(
			HTTPClient.post(endPoints.BROADCAST_CAMPAIGNS.SCHEDULE, params)
		);
		promise.then((response) => {
			if (response.status === 0) {
				dispatch(
					getAppDialog(
						false,
						constants.CONFIRM_SCHEDULE_DIALOG,
						null,
						null,
						null,
						null
					)
				);
				setDisabled(true);
				setScheduled(true);
				setScheduled_on(obj.schedule_date);
				setScheduled(true);
				params["t_status"] = response.data.t_status;
				params["campaign_type"] =
					campaign["campaign_type"] === 0 ? "" : campaign["campaign_type"];
				setCampaign(params);
				if (editorInstance !== null) {
					editorInstance.edit.off();
				}
				dispatch(refreshCalendarForBroadcast(true));
			} else if (response.status === -1 && response.error.message !== "") {
				dispatch(
					showCustomSnackBar(
						response.error.message,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleTop
					)
				);
			}
		});
	};

	const executeNow = () => {
		let params = campaign;
		params.scheduled_on = "";
		params.t_status = "scheduled";
		let campType = 0;
		if (isValidParam(params.campaign_type) && params.campaign_type !== "") {
			campType = params.campaign_type;
		}
		params.campaign_type = campType;
		var promise = Promise.resolve(
			HTTPClient.post(endPoints.BROADCAST_CAMPAIGNS.EXECUTE, params)
		);
		promise.then((response) => {
			if (response.status === 0) {
				params["t_status"] = response.data.t_status;
				params["campaign_type"] =
					campaign["campaign_type"] === 0 ? "" : campaign["campaign_type"];
				setCampaign(params);
				setScheduled(true);
				setScheduled_on(moment().tz(timeZone[appState.me.timezone]));
				setDisabled(true);
				if (editorInstance !== null) {
					editorInstance.edit.off();
				}
				dispatch(refreshCalendarForBroadcast(true));
				dispatch(
					getAppDialog(false, constants.EXECUTE_DIALOG, null, null, null, null)
				);

			} else if (response.status === -1 && response.error.message !== "") {
				dispatch(
					showCustomSnackBar(
						response.error.message,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleTop
					)
				);
			}
		});
	};

	

	const schedule = (obj) => {
		
		let momentObj = obj.date;
		let db_date = momentObj.format("YYYY-MM-DD HH:mm:ss");
		let user_date = momentObj.format(dateTimeFormat[appState.me.date_format]);
		let data = { user_date: user_date, db_date: db_date };
		dispatch(
			getAppDialog(false, constants.SCHEDULE_DIALOG, null, null, null, null)
		);
		dispatch(
			getAppDialog(
				true,
				constants.CONFIRM_SCHEDULE_DIALOG,
				getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
				confirmSchedule,
				data
			)
		);
	};
	const handlePreview = () => {
		window.open("/#previewbroadcasttemplate/" + campaign.id);
	};
	
	const handleChangeTemplate = () => {
		let data = null;
		if (selectedTemplate !== null && selectedCategory !== null) {
			data = {
				selectedTemplate: selectedTemplate,
				selectedCategory: selectedCategory,
			};
		}

		dispatch(
			getAppDialog(
				true,
				constants.TEMPLATE_DIALOG,
				getLocalizedStrings().label.CAMPAIGNS.TEMPLATE_DIALOG_TITLE,
				setTemplateData,
				data,
				constants.BROADCAST_CAMPAIGNS_OBJECT
			)
		);
	};
	const handleSchedule = () => {
		if (validateForm()) {
			dispatch(
				getAppDialog(
					true,
					constants.SCHEDULE_DIALOG,
					getLocalizedStrings().label.BROADCAST_CAMPAIGNS.SCHEDULE_BROADCAST,
					schedule,
					null,
					null
				)
			);
		}
	};
	

	const handleExecute = () => {
		if (validateForm()) {
			dispatch(
				getAppDialog(
					true,
					constants.EXECUTE_DIALOG,
					getLocalizedStrings().label.BROADCAST_CAMPAIGNS.EXECUTE_BROADCAST,
					executeNow,
					null,
					null
				)
			);
		}
	};

	

	const handleCancelSchedule = () => {
		const msg = (
			<div style={{ paddingLeft: "20px" }}>
				<div style={{ ...styles.row }}>
					{
						getLocalizedStrings().message.BROADCAST_CAMPAIGNS
							.EXECUTION_CANCEL_MSG
					}
				</div>
				<div style={{ ...styles.row, paddingBottom: 20, fontWeight: "bold" }}>
					{getLocalizedStrings().message.BROADCAST_CAMPAIGNS.CONFIRM}
				</div>
				<div style={{ ...styles.row }}>
					{
						getLocalizedStrings().message.BROADCAST_CAMPAIGNS
							.EXECUTION_CANNOT_CANCEL_2
					}
				</div>
			</div>
		);

		sfDialogs.confirm(
			getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
			msg,
			handleCancelScheduleOnConfirm,
			null
		);
	};

	const handleCancelScheduleOnConfirm = () => {
		let params = {
			id: campaign.id,
		};
		var promise2 = Promise.resolve(
			HTTPClient.get(endPoints.BROADCAST_CAMPAIGNS.CANCEL_QUEUED, params)
		);
		promise2.then((response) => {
			if (response.is_cancel === true) {
				setDisabled(false);
				let tempCampaign = campaign;
				tempCampaign.t_status = "New";
				setCampaign(tempCampaign);
				setScheduled(false);
			} else {
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					getLocalizedStrings().message.BROADCAST_CAMPAIGNS
						.EXECUTION_CANNOT_CANCEL_1,
					null
				);
			}
		});
	};

	const testMail = () => {
		if (validateForm("testMail")) {
			let params = {};
			params.email = "";
			params.frommail = campaign.sender_email;
			params.fromname = campaign.sender_name;
			params.subject = campaign.subject;
			params.templateBody = campaign.email_message;
			if (campaign["view_as_web_page"] === true) {
				params.isVawp = "1";
			} else {
				params.isVawp = "0";
			}
			params.testMailType = 'marketing';
			dispatch(
				getAppDialog(true, constants.TEST_MAIL_DIALOG, null, null, params, null)
			);
		}
	};

	const getLibrary = (editor, type) => {
		editor.events.disableBlur();
		let data = {
			editor: editor,
			contentStyle: { width: "80%", maxWidth: "none" },
			type: type,
			fullWidth: true,
			maxWidth: "md",
		};
		dispatch(
			getAppDialog(
				true,
				constants.LIBRARY_DIALOG,
				getLocalizedStrings().message.COMMON.LIBRARY_DIALOG_TITLE,
				handleLibraryResponse,
				data,
				null
			)
		);
	};

	const handleLibraryResponse = (obj) => {
		obj.editor.events.enableBlur();
		if (obj.type === "link" && obj.src !== "") {
			document.getElementsByName("href")[0].value = obj.src;
		} else if (obj.type === "image" && obj.src !== "") {
			obj.editor.image.insert(obj.src);
		}
		dispatch(
			getAppDialog(false, constants.LIBRARY_DIALOG, null, null, null, null)
		);
	};

	const getVideoInsertDialog = (editor, videoType) => {
		editor.events.disableBlur();
		let data = {
			editor: editor,
			contentStyle: { width: "80%", maxWidth: "none" },
			videoType: videoType,
		};
		let title = null;
		if (videoType === constants.VIDEO_TYPE_YOUTUBE) {
			title = getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE;
		} else if (videoType === constants.VIDEO_TYPE_WISTIA) {
			title = getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE;
		}
		dispatch(
			getAppDialog(
				true,
				constants.VIDEO_INSERT_DIALOG,
				title,
				(event)=>handleVideoInsertResponse(event),
				data,
				null
			)
		);
	};

	const handleVideoInsertResponse = (obj) => {
		obj.editor.events.focus();
		dispatch(
			getAppDialog(false, constants.VIDEO_INSERT_DIALOG, null, null, null, null)
		);
		obj.editor.html.insert(
			'<a href="' +
				obj.video_link +
				'" target="_blank" className="editor_video-selector"><img  height="220" width="380" "src="' +
				obj.thumbnail.url +
				'" title="' +
				obj.title +
				'" /></a>'
		);
		obj.editor.events.focus();
	};

	const setEditorInstance = (editor) => {
		setEditorInstance(editor);
	};

	const expandEditor = () => {
		try {
			setIsFullScreenEditor(!isFullScreenEditor);
			if (isFullScreenEditor) {
				$("[id^=expand_editor]")
					.find($(".fa"))
					.removeClass("fa fa-expand")
					.addClass("fa fa-compress");
				setTimeout(function () {
					$("[id^=expand_editor]").addClass("fr-active");
				}, 500);
			} else
				$("[id^=expand_editor]")
					.find($(".fa"))
					.removeClass("fa fa-compress")
					.addClass("fa fa-expand");
		} catch (error) {
			console.error(
				"Error in 'MarketingTemplate.js -> expandEditor()':" + error
			);
		}
	};

	const openVerifyEmailPopup = () => {
		try {
			let data = {
				fromName: appState.me.name,
				verifiedEmailOptions: verifid_email_ids,
			};
			dispatch(
				getAppDialog(
					true,
					constants.VERIFY_EMAIL_DIALOG,
					getLocalizedStrings().label.BROADCAST_CAMPAIGNS.VERIFY_EMAIL,
					null,
					data,
					constants.BROADCAST_CAMPAIGNS_OBJECT
				)
			);
		} catch (error) {
			console.error(
				"Error in 'BroadcastCampaign.js -> openVerifyEmailPopup():'" + error
			);
		}
	};

	const loadVerifiedEmailIds = (event) => {
		let verifid_email_ids = [];
		let isReloaded = false;
		try {
			let tempCampaign = campaign;
			let url = endPoints.BROADCAST_CAMPAIGNS.GET_VERIFIED_EMAIL_IDS;
			let params = {};
			params.getUserMailIds = false;
			let promise = Promise.resolve(HTTPClient.get(url, params));
			promise.then((response) => {
				if (isValidParam(response)) {
					isReloaded = true;
					verifid_email_ids = generateFromEmailOptions(
						response.verified_email_ids
					);
					if (isReloaded) {
						let emailObj = {
							label: verifid_email_ids[0].value,
							value: verifid_email_ids[0].value,
						};
						handleSenderEmailChange({}, emailObj);
					}
					setVerifid_email_ids(verifid_email_ids);
					setCampaign(tempCampaign);
				}
			});
		} catch (error) {
			console.error(
				"Error in 'BroadcastCampaign.js -> loadVerifiedEmailIds():'" + error
			);
		}
	};


	const changeCampaignType = (event, index) => {
		try {
			let value = event.target.value;
			if (isValidParam(value)) {
				if (value !== "Manage") {
					let tempCampaign = campaign;
					tempCampaign.campaign_type = value;
					setCampaign(tempCampaign);
				} else {
					let data = {};
					data.object = constants.AUTOMATION_DESIGNER_OBJECT;
					dispatch(
						getAppDialog(
							true,
							constants.ADD_CAMPAIGN_TYPE_DIALOG,
							getLocalizedStrings().label.CAMPAIGNS.ADD_CAMPAIGN_TYPE,
							addCampaignType,
							null,
							null
						)
					);
				}
			}
		} catch (error) {
			console.log(
				"Error in 'BroadcastCampaign.js -> changeCampaignType()': " + error
			);
		}
	};

	const addCampaignType = (obj) => {
		try {
			let camObj = {
				id: obj.id,
				name: obj.name,
			};

			let camTyps = getArrayParam(campTypelistArr);
			camTyps.push(camObj);
			addCampaignTypeIntoReduxStore(camObj);
			sortArrayObjectByProperty(camTyps, "name");
			let campTypeMenuItems = getCampaignTypeMenuItems(camTyps);
			setCampTypelistArr(camTyps);
			setCampTypeMenuItems(campTypeMenuItems);
		} catch (error) {
			console.log(
				"Error in 'BroadcastCampaign.js -> addCampaignType()': " + error
			);
		}
	};

	const getCampaignTypeList = () => {
		try {
			let campaign_types = getObjectParam(campaignTypes.data);
			let campTypelistArr = [];
			let objCamp = null;
			if (Object.keys(campaign_types).length > 0) {
				Object.keys(campaign_types).forEach((itemKey) => {
					objCamp = {};
					objCamp.id = parseInt(itemKey);
					objCamp.name = campaign_types[itemKey];

					campTypelistArr.push(objCamp);

					objCamp = null;
				});
			}

			sortArrayObjectByProperty(campTypelistArr, "name");
			let campTypeMenuItems = getCampaignTypeMenuItems(campTypelistArr);
			setCampTypelistArr(campTypelistArr);
			setCampTypeMenuItems(campTypeMenuItems);
		} catch (error) {
			console.log(
				"Error in 'BroadcastCampaign.js -> getCampaignTypeList()': " + error
			);
		}
	};

	const getCampaignTypeMenuItems = (campTypelistArr) => {
		try {
			let campaignTypes = getArrayParam(campTypelistArr);
			let campTypeItems = null;
			if (campaignTypes !== null && campaignTypes.length > 0) {
				campTypeItems = campaignTypes.map((camTy, index) => {
					return (
						<MenuItem
							key={camTy.id}
							value={camTy.id}
							style={styles.popoverMenuItem}
						>
							{
								<div
									style={{
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
										overflow: "hidden",
										width: "142px",
									}}
									title={camTy.name}
								>
									{camTy.name}
								</div>
							}
						</MenuItem>
					);
				});
			}
			return campTypeItems;
		} catch (error) {
			console.log(
				"Error in 'BroadcastCampaign.js -> getCampaignTypeMenuItems()': " +
					error
			);
		}
	};

	const getSchedulerLink = (event) => {
		try {
			let slug = appState.appointment_scheduler_slug;
			if (slug === null) {
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					getLocalizedStrings().message.SCHEDULER.NO_SCHEDULER
				);
			} else {
				event.html.insert(
					" [Start Scheduler Link] Book an Appointment [End Scheduler Link] "
				);
			}
		} catch (error) {
			console.error(
				"Error in 'BroadcastCampaign.js -> getSchedulerLink()':" + error
			);
		}
	};

	//priyanka 6.5.20

	const validateFilter = (callFrom = "default") => {
		let tempError = error;
		let isValid = true;
		let addl_msg = "";
		try {
			if (
				callFrom !== "testMail" &&
				(selected_filter_options === "" ||
					(Array.isArray(selected_filter_options) &&
						selected_filter_options.length === 0))
			) {
				isValid = false;

				let filter_type_msg = "";
				if (filter_type === "group") {
					filter_type_msg =
						getLocalizedStrings().message.BROADCAST_CAMPAIGNS.FILTER_TYPE_GROUP;
				} else if (filter_type === "contact") {
					filter_type_msg =
						getLocalizedStrings().message.BROADCAST_CAMPAIGNS
							.FILTER_TYPE_CONTACT;
				} else if (filter_type === "account") {
					filter_type_msg =
						getLocalizedStrings().message.BROADCAST_CAMPAIGNS
							.FILTER_TYPE_ACCOUNT;
				}

				tempError["filter_options"] =
					getLocalizedStrings().message.BROADCAST_CAMPAIGNS
						.GROUP_FILTER_BLANK_COMMON_MSG +
					" " +
					filter_type_msg +
					addl_msg;
				setError({...tempError});
			} else if (
				typeof selected_filter_options === "object" &&
				selected_filter_options.value === -1 &&
				callFrom !== "testMail"
			) {
				let filter_type_msg = "";
				if (filter_type === "contact") {
					filter_type_msg =
						getLocalizedStrings().message.BROADCAST_CAMPAIGNS
							.FILTER_TYPE_CONTACT;
				} else if (filter_type === "account") {
					filter_type_msg =
						getLocalizedStrings().message.BROADCAST_CAMPAIGNS
							.FILTER_TYPE_ACCOUNT;
				}

				tempError["filter_options"] =
					getLocalizedStrings().message.BROADCAST_CAMPAIGNS
						.GROUP_FILTER_BLANK_COMMON_MSG +
					" " +
					filter_type_msg +
					addl_msg;
				setError({...tempError});
			}
		} catch (error) {
			console.error(
				"Error in 'BroadcastCampaign.js -> validateFilter()':" + error
			);
		}
		return isValid;
	};

	const handleQualifiedContacts = () => {
		let label =
			getLocalizedStrings().label.BROADCAST_CAMPAIGNS.QUALIFIED_CONTACTS;
		try {
			if (validateFilter()) {
				let url = "/" + constants.CONTACTS_OBJECT + "/queries";
				openQualifiedContactsTab(label, url);
			}
		} catch (error) {
			console.error(
				"Error in 'BroadcastCampaign.js -> handleQualifiedContacts()':" + error
			);
		}
	};
	const getAuditSurveyLink = (event) => {
        event.html.insert(' ${audit_survey_link} ');
    }
	const openQualifiedContactsTab = (label, url) => {
		try {
			let activeTab = getObjectParam(getActiveTab());
			let activeTabIndex = getIntParam(activeTab.index);
			let filter = {
				id: 542,
				name: "All records",
				type: constants.SEARCH_QUERY_TYPE_ALL,
			};
			var tab = {
				label: getStringParam(label),
				object: constants.CONTACTS_OBJECT,
				type: TYPE_LIST_VIEW,
				imgName: null,
				url: url,
				isActive: true,
				parentTabIndex: activeTabIndex,
			};
			let info = {
				isReport: true,
				reportLabel: label,
				filter: filter,
				input_param: {
					call_from: constants.CAMPAIGN_QUALIFIED_CONTACTS,
					filter_options: selected_filter_options,
					filter_type: getStringParam(filter_type),
				},
			};
			tab.info = info;
			addTab(tab, true);
			dispatch(getAppDrawer(false, null, null, null, null));
		} catch (error) {
			console.error(
				"Error in 'BroadcastCampaign.js -> openQualifiedContactsTab()':" + error
			);
		}
	};


	let pageHeight = window.innerHeight - 247;
	let top = (pageHeight - 10) / 2;
	if (!mounted || couponLoader) {
		return (
			<div style={{ width: "100%", height: pageHeight }}>
				<div
					className="asset-loaderh"
					style={{ paddingTop: top, paddingLeft: "45%" }}
				>
					<div
						style={{
							...styles.assetLoaderContainer,
							height: 50,
							width: 50,
							padding: 7,
						}}
					>
						<ShowCircularProgress
							size={30}
							style={{ marginTop: "3", marginLeft: "3" }}
						/>
					</div>
				</div>
			</div>
		);
	}
	if (redirect) {
		return <Navigate push to={redirectUrl} />;
	}

	let campaignName = getStringParam(campaign.bd_name);
	let campaignStatus = campaign.t_status;
	
        Froalaeditor.DefineIcon("personalize_dropdown", { NAME: "fa-duotone fa-gear", template: "font_awesome" });
        Froalaeditor.RegisterCommand('personalize_dropdown', {
            title: getLocalizedStrings().label.MAIL.PERSONALIZE,
            type: 'dropdown',
            focus: false,
            undo: true,
            refreshAfterCallback: true,
            options: personalize_fields,
            callback: function (cmd, val) {
                this.html.insert(' ${' + val + '} ');
            },
            // Callback on refresh.
            refresh: function ($btn) {
            },
            // Callback on dropdown show.
            refreshOnShow: function ($btn, $dropdown) {
            }
        });
    
    Froalaeditor.DefineIcon('signature', { NAME: "fa-regular fa-file-signature", template: "font_awesome" });
    
    Froalaeditor.DefineIcon('library', { NAME: 'image', template: "font_awesome" });
    Froalaeditor.RegisterCommand('library', {
        title: getLocalizedStrings().label.MAIL.INSERT_IMAGE,
        focus: false,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            getLibrary(this, 'image')
        }
    });
    Froalaeditor.DefineIcon("insertYouTubeVideo", {
        NAME: "fa-brands fa-youtube", template: "font_awesome"
    });
    Froalaeditor.RegisterCommand('insertYouTubeVideo', {
        title: getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            getVideoInsertDialog(this, constants.VIDEO_TYPE_YOUTUBE)
        }
    });
    Froalaeditor.DefineIcon("insertWistiaVideo", {
        SRC: "/asset/images/wistia.png",
        ALT: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
        template: "image",
    });
    Froalaeditor.RegisterCommand('insertWistiaVideo', {
        title: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            getVideoInsertDialog(this, constants.VIDEO_TYPE_WISTIA)
        }
    });
    Froalaeditor.DefineIcon('link_library', { NAME: 'file-archive-o', template: "font_awesome" });
    Froalaeditor.RegisterCommand('link_library', {
        title: getLocalizedStrings().label.MARKETING_TEMPLATE.SELECT_FROM_LIBRARY,
        focus: false,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            getLibrary(this, 'link')
        }
    });
    Froalaeditor.DefineIcon('coupon', { NAME: 'ticket', template: "font_awesome" });
    Froalaeditor.RegisterCommand('coupon', {
        title: getLocalizedStrings().label.COMMON.COUPONS,
        type: 'dropdown',
        focus: false,
        undo: true,
        refreshAfterCallback: true,
        options: coupons,
        callback: function (cmd, val) {
            val = val.trim();
            this.html.insert(' ${coupon_code.' + val + '} ');
        },
        // Callback on refresh.
        refresh: function ($btn) {
        },
        // Callback on dropdown show.
        refreshOnShow: function ($btn, $dropdown) {
        }
    });
    Froalaeditor.DefineIcon('scheduler-editor', { NAME: 'fas fa-calendar-minus', template: "font_awesome" });
    Froalaeditor.RegisterCommand('scheduler-editor', {
        title: getLocalizedStrings().label.COMMON.SCHEDULER_LINK,
        focus: false,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            getSchedulerLink(this);
        }
    });
    Froalaeditor.DefineIcon('audit_link', { NAME: 'fas fa-wpforms', template: "font_awesome" });
    Froalaeditor.RegisterCommand('audit_link', {
        title: getLocalizedStrings().label.AUDIT_QUESTIONS.SURVEY_LINK,
        focus: false,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            getAuditSurveyLink(this);
        }
    });
   // let autoFocus = (actionType === 'REPLY' || actionType === 'REPLY-ALL' || actionType === 'FORWARD') ? true : false;
    let FroalaConfig = {
        key: constants.FROALA_EDITOR_KEY,
        placeholderText: getLocalizedStrings().label.MAIL.TYPE_HERE,
        height: document.body.clientHeight - 200,
        toolbarButtons: constants.SEND_MAIL_EDITOR_TOOLBAR_CONFIGURATION,
        toolbarButtonsMD: constants.SEND_MAIL_EDITOR_TOOLBAR_CONFIGURATION,
        toolbarButtonsSM: constants.SEND_MAIL_EDITOR_TOOLBAR_CONFIGURATION,
        toolbarButtonsXS: constants.SEND_MAIL_EDITOR_TOOLBAR_CONFIGURATION,
        colorsBackground: constants.EDITOR_TOOLBAR_BACKGROUND_COLOR_PALETTE,
        colorsText: constants.EDITOR_TOOLBAR_TEXT_COLOR_PALETTE,
        toolbarSticky: false,
        enter: FroalaEditor.ENTER_BR,
        iframe: true,
        htmlUntouched: true,
        attribution: false,
        inlineMode: false,
        linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
        linkInsertButtons: ['linkEdit', 'linkRemove', 'link_library'],
        imageEditButtons: ['imageAlign', 'imageRemove', 'imageLink', 'imageDisplay', 'imageAlt', 'imageSize', 'linkOpen', 'linkEdit', 'linkRemove'],
        tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', '-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign'],
        quickInsertButtons: [],
        imageUpload: false,
        imagePaste: false,
        fileUpload: false,
       // autofocus: autoFocus,
        fontSize: constants.EDITOR_FONT_SIZE,
        fontFamily: {
            'Adobe Garamond Pro': 'Adobe Garamond Pro',
            'Arial,Helvetica,sans-serif': 'Arial',
            'Futura Std': 'Futura Std',
            'Georgia,serif': 'Georgia',
            'Impact,Charcoal,sans-serif': 'Impact',
            "RobotoSegoe": 'Roboto',
            'Tahoma,Geneva,sans-serif': 'Tahoma',
            "'Times New Roman',Times,serif": 'Times New Roman',
            'Verdana,Geneva,sans-serif': 'Verdana',
        },
        fontFamilySelection: true,
        events: {
            'froalaEditor.initialized': function (e, editor) {
                $('.fr-command').on('click', function () {
                    if ($(this).data('cmd') === 'insertLink') {
                        $('input[name="target"]').prop('checked', true);
                    }
                });
            }
        },
        events: {
            'initialized': function () {
                window.WEBSPELLCHECKER.init({
                    container: this.$iframe ? this.$iframe[0] : this.el
                });
            }
        },
       
        zIndex: 2501
    }



	let personalizeFieldList = renderPersonalizeField();


	

	let mainContentTopMargin = "-12px";
	if (result) {
		mainContentTopMargin = "30px";
	} else if (resultExecuteStart) {
		mainContentTopMargin = "80px";
	}
	return (
		<div style={{ paddingLeft: "26px", paddingRight: "26px" }}>
			{!isFullScreenEditor && (
				<div>
					<>
						<div>
							<div
								style={{
									...styles.sf_12,
									fontSize: "14px",
									textAlign: "left",
									color: "#717171",
									paddingLeft: "10px",
									paddingBottom: "5px",
								}}
							>
								<span>
									{new Intl.NumberFormat().format(email_credit_balance)}{" "}
									{
										getLocalizedStrings().message.BROADCAST_CAMPAIGNS
											.CREDITS_REMAINING
									}{" "}
								</span>
							</div>

							<div style={{ ...styles.row }}>
								<div
									style={{
										...styles.sf_12,
										...stickyStyle,
										paddingBottom: "20px",
									}}
								>
									<div style={{ float: "right" }}>
										{campaign.t_status !== "Queued" &&
											campaign.t_status !== "Executed" &&
											campaign.t_status !== "Running" &&
											campaign.t_status !== "Halted" && (
												<Button
													onClick={() => saveCampaign()}
													style={styles.primaryButton}
													labelstyle={{ fontWeight: "normal" }}
												>
													{getLocalizedStrings().label.COMMON.SAVE}
												</Button>
											)}
										{campaign.t_status === "Queued" && (
											<Button
												style={styles.primaryButton}
												onClick={() => handleCancelSchedule()}
												labelstyle={{ fontWeight: "normal" }}
											>
												{getLocalizedStrings().label.COMMON.CANCEL}
											</Button>
										)}

										{campaign.t_status !== null &&
											campaign.t_status !== "Queued" &&
											campaign.t_status !== "Executed" &&
											campaign.t_status !== "Running" &&
											campaign.t_status !== "Halted" && (
												<Button
													onClick={() => handleSchedule()}
													style={styles.secondaryButton}
													labelstyle={{ fontWeight: "normal" }}
												>
													{" "}
													{getLocalizedStrings().label.CAMPAIGNS.SCHEDULE}
												</Button>
											)}

										{campaign.t_status !== null &&
											campaign.t_status !== "Queued" &&
											campaign.t_status !== "Executed" &&
											campaign.t_status !== "Running" &&
											campaign.t_status !== "Halted" && (
												<Button
													onClick={() => handleExecute()}
													style={styles.secondaryButton}
													labelstyle={{ fontWeight: "normal" }}
												>
													{getLocalizedStrings().label.CAMPAIGNS.EXECUTE_NOW}
												</Button>
											)}
										{campaign.t_status === "Executed" ||
										campaign.t_status === "Running" ||
										campaign.t_status === "Halted" ? (
											<Button
												onClick={() => handleCopy()}
												style={{
													...styles.primaryButton,
													display: buttonVisible,
													lineHeight:'20px'
												}}
												labelstyle={{ fontWeight: "normal" }}
											>
												{getLocalizedStrings().label.COMMON.COPY}
											</Button>
										) : (
											<Button
												onClick={() => handleCopy()}
												style={{
													...styles.secondaryButton,
													display: buttonVisible,
													lineHeight:'20px'
												}}
												labelstyle={{ fontWeight: "normal" }}
											>
												{getLocalizedStrings().label.COMMON.COPY}
											</Button>
										)}
										<Button
											onClick={() => testMail()}
											style={{
												...styles.secondaryButton,
												display: buttonVisible,
											}}
											labelstyle={{ fontWeight: "normal" }}
										>
											{getLocalizedStrings().label.CAMPAIGNS.TEST}
										</Button>

										{campaign.t_status !== "Executed" &&
											campaign.t_status !== "Queued" &&
											campaign.t_status !== "Running" && (
												<Button
													style={styles.secondaryButton}
													onClick={() => handleChangeTemplate()}
													disabled={disabled}
													labelstyle={{ fontWeight: "normal" }}
												>
													{" "}
													{
														getLocalizedStrings().label.CAMPAIGNS
															.CHANGE_TEMPLATE
													}
												</Button>
											)}

										<Button
											onClick={() => handlePreview()}
											style={{
												...styles.secondaryButton,
												display: buttonVisible,
											}}
											labelstyle={{ fontWeight: "normal" }}
										>
											{getLocalizedStrings().label.COMMON.PREVIEW}
										</Button>
									</div>
									<div>
										{scheduled &&
											campaign.t_status !== "Executed" &&
											campaign.t_status !== "Halted" &&
											campaign.t_status !== "Running" && (
												<span
													style={{
														fontSize: "12px",
														textAlign: "left",
														color: "#228B22",
														fontWeight: "bold",
														paddingBttom: "5px",
													}}
												>
													{getLocalizedStrings().label.CAMPAIGNS.SCHEDULED_FOR}
													&nbsp;
													{moment(scheduled_on).format(
														dateTimeFormat[appState.me.date_format]
													)}
												</span>
											)}
										{campaign.t_status === "Executed" && (
											<span
												style={{
													fontSize: "14px",
													textAlign: "left",
													color: "#228B22",
													fontWeight: "bold",
													paddingBttom: "5px",
												}}
											>
												{getLocalizedStrings().label.CAMPAIGNS.EXECUTED_ON}
												&nbsp;
												{moment(scheduled_on).format(
													dateTimeFormat[appState.me.date_format]
												)}
											</span>
										)}

										{campaign.t_status === "Running" && (
											<span
												style={{
													fontSize: "12px",
													textAlign: "left",
													color: "#228B22",
													fontWeight: "bold",
													paddingBttom: "5px",
												}}
											>
												{getLocalizedStrings().label.CAMPAIGNS.EXECUTING}
											</span>
										)}
										{campaign.t_status === "Halted" && (
											<span
												style={{
													fontSize: "12px",
													textAlign: "left",
													color: "#228B22",
													fontWeight: "bold",
													paddingBttom: "5px",
												}}
											>
												{getLocalizedStrings().label.CAMPAIGNS.ABORTED}
											</span>
										)}
									</div>
								</div>
							</div>

							{campaign.id > 0 && (result || resultExecuteStart) && (
								<div style={{ ...styles.row }} className="bcresult">
									<div style={{ ...styles.sf_12, padding: "0px" }}>
										<Results
											campaignId={campaign.id}
											campaignName={campaignName}
											setMarketingResults={setMarketingResults}
											subject={campaign.subject}
											resultContent={resultContent}
											mounted={result}
										/>
									</div>
								</div>
							)}
						</div>
					</>

					<div>
						<div style={{ ...styles.row, marginTop: mainContentTopMargin }}>
							<div style={{ ...styles.sf_12 }}>
								<FormControl
									style={{ width: "100%", marginBottom: "16px" }}
									className="test"
									noValidate
									autoComplete="off"
								>
									<TextField
										variant="outlined"
										label={getLocalizedStrings().label.CAMPAIGNS.NAME_REQUIRED}
										fullWidth={true}
										name="bd_name"
										size = 'small'
										value={campaign.bd_name}
										onChange={(event) => handleChange(event)}
										error={error.bd_name !== ""}
										helperText={error.bd_name}
										disabled={disabled}
										autoFocus={campaign.id === 0 ? true : false}
										autoComplete="new-password"
										margin="dense"
										className={"sf-fields-bg"}
									/>
								</FormControl>
							</div>
						</div>

						<div style={{ display:'flex',paddingLeft:'10px'}}>
							<div style={{ ...styles.sf_3, width: "47%" }}>
								<FormControl variant="outlined" style={{ width: "100%" }}>
									<InputLabel
										id="sf-broadcastcampaign-simple-select-outlined-label"
										className="sf-broadcastcampaign"
										style={{ paddingBottom: "12px", marginTop: "-5px" }}
									>
										{
											getLocalizedStrings().label.CAMPAIGNS
												.CAMPAIGN_TYPE_OPTIONAL
										}
									</InputLabel>
									<SelectField
										id="campaignType"
										value={campaign.campaign_type}
										onChange={(event, index) =>
											changeCampaignType(event, index)
										}
										className="asf"
										style={{ height: "44px" }}
										label={
											getLocalizedStrings().label.CAMPAIGNS
												.CAMPAIGN_TYPE_OPTIONAL
										}
										disabled={disabled}
									>
										<MenuItem value="" title="" style={styles.popoverMenuItem}>
											{""}
										</MenuItem>
										{campTypeMenuItems}
										<MenuItem
											value="Manage"
											title={getLocalizedStrings().label.CAMPAIGNS.MANAGE}
											style={styles.popoverMenuItem}
										>
											{"---" +
												getLocalizedStrings().label.CAMPAIGNS.MANAGE +
												"---"}
										</MenuItem>
									</SelectField>
								</FormControl>
							</div>

							<div style={{ width: "50%" }}>
								<div>
									<div
										className
										style={{
											...styles.customInputHolder,
											height: "44px",
											marginLeft: "12px",
										}}
									>
										<label htmlFor="tophoneno" style={styles.inputLabel}>
											{getLocalizedStrings().label.AB_CAMPAIGNS.TARGET_BY}
										</label>
										<div>
											<RadioGroup row className="sf-data-fieldset" name="layout" onChange={(event) => changeRadioButton(event.target.value)} defaultValue={filter_type} style={{ marginTop: "-5px" }}>
												<FormControlLabel value="group" control={<Radio disabled={disabled} color="default" />} label={getLocalizedStrings().label.CAMPAIGNS.GROUP} title={getLocalizedStrings().label.CAMPAIGNS.GROUP} />
												<FormControlLabel value="contact" control={<Radio disabled={disabled} color="default" />} label={getObjectLabelByObject(constants.CONTACTS_OBJECT)} title={getObjectLabelByObject(constants.CONTACTS_OBJECT)} />
												<FormControlLabel value="account" control={<Radio disabled={disabled} color="default" />} label={getObjectLabelByObject(constants.ACCOUNTS_OBJECT)} title={getObjectLabelByObject(constants.ACCOUNTS_OBJECT)} />
											</RadioGroup>
										</div>
									</div>
								</div>
							</div>

							<div style={{ width: "45%"}}>
								<div style={{ display: "flex" }}>
									<Autocomplete
										multiple={filter_type === "group"}
										id={"autocomplete_group"}
										name={"autocomplete_group"}
										key={"autocomplete_group"}
										className={"sf-atc-autoComplete"}
										defaultValue ={selected_filter_options}
										getOptionDisabled={(option) => option.isDisabled === true}
										options={filter_options}
										onChange={(event, options) =>
											handleSelectChange(event, options)
										}
										
										disableClearable={true}
										style={{
											width: "100%",
											marginLeft: "25px",
											height: "44px",
										}}
										renderTags={(value, getTagProps) =>
											value.map((option, index) => (
												<Chip 
													style={{...styles.autocompleteChip, width: '26.5%'}}
													label={option.label}
													title={option.label}
													{...getTagProps({ index })}
												/>
											))
										}
										renderInput={(params) => (
											<TextField
												{...params}
												placeholder={placeholder}
												variant="outlined"
												margin="dense"
												size = 'small'
												className={"sf-atc-autoComplete-text-field"}
											/>
										)}
									/>
									<span style={{ fontSize: "12px", color: "#FF0000" }}>
										{error.filter_options}
									</span>

									<div style={{ width: "70px" }}>
										<i
											className="fa fa-eye"
											name={"qualifiedcontacts"}
											onClick={() => handleQualifiedContacts()}
											title={
												getLocalizedStrings().label.BROADCAST_CAMPAIGNS
													.QUALIFIED_CONTACTS
											}
											style={{
												marginTop: "6px",
												marginLeft: "18px",
												fontSize: "30px",
												color: "#a8a0a0",
												cursor: "pointer",
											}}
										></i>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div
								style={{ ...styles.sf_4, float: "left", paddingLeft: "10px" }}
							>
								<FormControl
									style={{ width: "100%" }}
									className="test"
									noValidate
									autoComplete="off"
								>
									<TextField
										variant="outlined"
										label={
											getLocalizedStrings().label.CAMPAIGNS.FROM_NAME_REQUIRED
										}
										fullWidth={true}
										name="sender_name"
										value={campaign.sender_name}
										onChange={(event) => handleChange(event)}
										error={error.sender_name !== ""}
										helperText={error.sender_name}
										disabled={disabled}
										inputprops={{ maxlength: "64" }}
										autoComplete="new-password"
										margin="dense"
										size = 'small'
										className={"sf-fields-bg"}
									/>
								</FormControl>
							</div>
							<div
								style={{ ...styles.sf_4, float: "left", paddingLeft: "15px" }}
							>
								<div style={{ float: "left", width: "100%" }}>
									<div
										style={{
											color: "#999999",
											position: "absolute",
											top: "2px",
											zIndex: "1",
											left: "25px",
											fontSize: "11px",
										}}
									>
									</div>
									<FormControl
										style={{ width: "100%" }}
										className="test"
										noValidate
										autoComplete="off"
									>
										{verifid_email_ids.length > 0 && (
											<Autocomplete
												name="sender_email"
												defaultValue={verifid_email_ids.find(
													(v) => v.value === campaign.sender_email
												)}
												options={verifid_email_ids}
												onChange={(event, value) =>
													handleSenderEmailChange(event, value)
												}
												getOptionLabel={(option) => option.label}
												disableClearable={true}
												disabled={disabled || disabledFrmEmail ? true : false}
												className="broadFromEmail"
												style={{ marginBottom: "0px" }}
												renderInput={(params) => (
													<TextField
														{...params}
														variant="outlined"
														label={
															getLocalizedStrings().label.BROADCAST_CAMPAIGNS
																.FROM_EMAIL_REQUIRED
														}
														margin="dense"
														size='small'
														className={"sf-fields-bg"}
													/>
												)}
											/>
										)}
									</FormControl>

									<span
										style={{
											fontSize: "12px",
											color: "#f44336",
											marginTop: "10px",
											marginBottom: "5px",
										}}
									>
										{error.sender_email}
									</span>
								</div>
							</div>
							<div>
								<div style={{ float: "right",marginRight:'29%',marginTop:'-45px' }}>
									<IconButton
										onClick={(e) => loadVerifiedEmailIds(e)}
										style={{ color: "#949494", fontSize: "16px" }}
									>
										<Icon
											style={{ fontSize: "25px", color: "#666161" }}
											title={getLocalizedStrings().label.REPORT.REFRESH}
										>
											autorenew
										</Icon>
									</IconButton>
								</div>
							</div>

							<div
								style={{
									...styles.sf_3,
									float: "left",
									paddingLeft: "2px",
									marginLeft: "72%",
									marginTop:'-31px'
								}}
							>
								<div>
									<FormControlLabel
										control={
											<Checkbox
												color="default"
												value={campaign.view_as_web_page}
												onChange={(event, isInputChecked) =>
													handleCheckBox(event, isInputChecked)
												}
												Checked={campaign.view_as_web_page}
											/>
										}
										label={
											getLocalizedStrings().label.CAMPAIGNS.VIEW_AS_WEB_PAGE
										}
										labelposition="right"
										style={{ marginRight: "10px", height: "12px" }}
										inputStyle={{ padding: "0px", width: "auto" }}
										labelstyle={{
											color: "#717171",
											padding: "0px",
											width: "auto",
											fontWeight: "normal",
										}}
										disabled={disabled}
									/>
								</div>
							</div>
						</div>

						<div style={{ padding: "1 10 1 10", marginTop: "7px" }}>
							<div>
								<FormControl
									style={{ width: "100%" }}
									className="test"
									noValidate
									autoComplete="off"
								>
									<TextField
										variant="outlined"
										label={
											getLocalizedStrings().label.MARKETING_TEMPLATE
												.SUBJECT_REQUIRED
										}
										fullWidth={true}
										name="subject"
										size = 'small'
										value={campaign.subject}
										onChange={(event) => handleChange(event)}
										onClick={(event) => focusSubject(event)}
										error={error.subject !== ""}
										helperText={error.subject}
										inputprops={{ maxlength: "255" }}
										autoComplete="new-password"
										margin="dense"
										className={"sf-fields-bg"}
									/>
									<div
										title={getLocalizedStrings().label.CAMPAIGNS.PERSONALIZE}
										style={{ marginTop: "-34px", zIndex: 1, marginLeft: "96%" }}
									>
										<div
											style={{ marginTop: "2px" }}
											onClick={(event) => openNoteTypeList(event)}
										>
											<Icon
												style={{
													fontSize: "17px",
													color: "black",
													cursor: "pointer",
													marginTop: "1px",
													marginLeft: "-2px",
												}}
											>
												settings
											</Icon>
											<Icon
												style={{
													fontSize: "17px",
													color: "black",
													cursor: "pointer",
													marginTop: "1px",
												}}
											>
												keyboard_arrow_down
											</Icon>
										</div>
										<Menu
											name="noteTypeList"
											key={"sf-auto-complete-noteTypeList"}
											id={"noteTypeList"}
											style={{ height: "50%" }}
											anchorEl={anchorELNoteTypeList}
											anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
											targetOrigin={{ horizontal: "left", vertical: "top" }}
											open={isOpenNoteTypeList}
											onClose={() => setIsOpenNoteTypeList(false)}
										>
											{personalizeFieldList}
										</Menu>
									</div>
								</FormControl>
							</div>
						</div>
					</div>
				</div>
			)}
			<div
				style={{
					marginBottom: "55px",
					marginTop: "30px",
					width: "98.5% ",
					marginLeft: "10px",
				}}
			>
				<FroalaEditor
					tag="textarea"
					model={campaign.email_message}
					onModelChange={(event) => handleModelChange(event)}
					config={FroalaConfig}
				/>
			</div>
		</div>
	);
};

const Results = ({campaignId,campaignName,setMarketingResults,subject,resultContent,mounted}) => {
	const dispatch = useDispatch();
	const getPercentage = (value) => {
		let percentage_value = "";
		let sent_value = resultContent.marketing_results[0].sent;
		if (sent_value !== 0) {
			percentage_value = (value / sent_value) * 100;
			if (Math.round(percentage_value) !== percentage_value) {
				percentage_value = percentage_value.toFixed(2);
			}
			return percentage_value + "%";
		}
	};

	const drilldownMarketingReport = (statsId, eventType, reportType) => {
		try {
			let params = {
				name: "All records",
				tableId: 9,
			};
			var promise = Promise.resolve(
				HTTPClient.get(endPoints.REPORTS.QUERY_DETAIL, params)
			);
			promise.then((response) => {
				if (isValidParam(response)) {
					let label = getStringParam(campaignName);
					if (eventType === "click") {
						let resultObject = resultContent.marketing_results[0];
						let reportName = resultObject["subject"];
						let eventTypeText = getLocalizedStrings().label.CAMPAIGNS.CLICK_URL;
						label = eventTypeText + " " + reportName;
					} else {
						let eventTypeText =
							eventType.charAt(0).toUpperCase() + eventType.slice(1);
						if (reportType === constants.BROADCAST_REPORT_TYPE) {
							let eventTypeTextUpper = eventTypeText.toUpperCase();
							eventTypeText =
								getLocalizedStrings().label.CAMPAIGNS.hasOwnProperty(
									eventTypeTextUpper
								)
									? getLocalizedStrings().label.CAMPAIGNS[eventTypeTextUpper]
									: eventTypeText;
							label =
								eventTypeText +
								" " +
								getLocalizedStrings().label.BROADCAST_CAMPAIGNS
									.EMAIL_CAMPAIGN_REPORT;
						} else if (reportType === constants.AUTO_RESPONDER_REPORT_TYPE) {
							label =
								eventTypeText +
								" " +
								getLocalizedStrings().label.AUTOMATION_DESIGNER
									.TOUCH_CAMPAIGN_REPORT;
						} else if (reportType === constants.AB_REPORT_TYPE) {
							label =
								eventTypeText +
								" " +
								getLocalizedStrings().label.AB_CAMPAIGNS.AB_CAMPAIGN_REPORT;
						}
					}
					let url =
						"/" +
						constants.CONTACTS_OBJECT +
						"/" +
						constants.BROADCAST_REPORT_TYPE.toLocaleLowerCase();
					openTab(
						label,
						constants.CONTACTS_OBJECT,
						response.query_id,
						"fa fa-bullhorn",
						url,
						statsId,
						eventType
					);
					dispatch(getAppCustomDrawer(false, null, null, null, null, null, null));
				}
			});
		} catch (error) {
			console.log(
				"Error in 'BroadcastCampaign.js -> drilldownMarketingReport()':" + error
			);
		}
	};

	const openTab = (
		label,
		component,
		queryId,
		imgName,
		url,
		statsId,
		eventType
	) => {
		let filter = {
			id: queryId,
			name: "",
			type: constants.TYPE_AUTORESPONDER_REPORT,
		};
		let tabType = TYPE_LIST_VIEW;
		let arrMarketingResults = resultContent.marketing_results;
		arrMarketingResults = arrMarketingResults.filter(function (el) {
			return el.id === statsId;
		});
		arrMarketingResults = arrMarketingResults[0];
		let tab = {
			label: label,
			object: component,
			type: tabType,
			imgName: imgName,
			info: {
				filter: filter,
				input_param: {
					stat_id: statsId,
					event_type: eventType,
				},
				isReport: true,
				reportType: constants.BROADCAST_REPORT_TYPE,
				event_type_key: EVENT_TYPE_KEYS[eventType],
				campaignId: campaignId,
				reportFieldValue: getStringParam(eventType),
				reportLabel: getStringParam(campaignName),
				recordCount: arrMarketingResults[EVENT_TYPE_KEYS[eventType]],
			},
			url: url,
			isActive: true,
		};
		setMarketingResults(resultContent.marketing_results);
		addTab(tab, true);
		dispatch(getAppCustomDrawer(false, null, null, null, null, null, null));
	};

	

	let pageHeight = window.innerHeight - 247;
	let top = (pageHeight - 10) / 2;
	if (!mounted) {
		return (
			<div style={{ width: "100%", height: pageHeight }}>
				<div
					className="asset-loaderh"
					style={{ paddingTop: top, paddingLeft: "45%" }}
				>
					<div
						style={{
							...styles.assetLoaderContainer,
							height: 50,
							width: 50,
							padding: 7,
						}}
					>
						<ShowCircularProgress
							size={30}
							style={{ marginTop: "3", marginLeft: "3" }}
						/>
					</div>
				</div>
			</div>
		);
	} else if (typeof resultContent === "object") {
		let resultObject = resultContent.marketing_results[0];
		let tempid = resultObject.id;

		let sent = 0;
		let opened = 0;
		let unopened = 0;
		let clicked = 0;
		let bounced = 0;
		let unsubscribed = 0;
		let spam = 0;
		let dropped = 0;

		if (resultObject.hasOwnProperty("sent")) {
			sent = resultObject.sent;
		}
		if (resultObject.hasOwnProperty("opened")) {
			opened = resultObject.opened;
		}
		if (resultObject.hasOwnProperty("unopened")) {
			unopened = resultObject.unopened;
		}
		if (resultObject.hasOwnProperty("clicked")) {
			clicked = resultObject.clicked;
		}
		if (resultObject.hasOwnProperty("bounced")) {
			bounced = resultObject.bounced;
		}
		if (resultObject.hasOwnProperty("unsubscribed")) {
			unsubscribed = resultObject.unsubscribed;
		}
		if (resultObject.hasOwnProperty("spam")) {
			spam = resultObject.spam;
		}
		if (resultObject.hasOwnProperty("dropped")) {
			dropped = resultObject.dropped;
		}

		return (
			<div
				style={{
					width: "100%",
					paddingBottom: "20px",
					backgroundColor: "#919191",
					paddingTop: "20px",
					marginBottom: "23px",
					paddingLeft: "30px",
					minHeight: "120px",
				}}
			>
				<div className="report_box_area">
					<div className="report_box"  style={{
								height:'78px'
							}}>
						<div
							style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
							title={getLocalizedStrings().label.CAMPAIGNS.SENT}
						>
							{getLocalizedStrings().label.CAMPAIGNS.SENT}
						</div>
						{sent !== 0 ? (
							<Link to={"#"}>
								<span
									style={{ fontSize: 18, color: "#F04B24" }}
									onClick={() =>
										drilldownMarketingReport(
											tempid,
											"Sent",
											constants.BROADCAST_REPORT_TYPE
										)
									}
								>
									{sent}
								</span>
							</Link>
						) : (
							<span style={{ fontSize: 18, color: "#F04B24" }}>{sent}</span>
						)}

						<br />
						{sent !== 0 && <span>&nbsp;</span>}
					</div>
					<div className="report_gap"></div>
					<div className="report_box">
						<div
							style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
							title={getLocalizedStrings().label.CAMPAIGNS.OPENED}
						>
							{getLocalizedStrings().label.CAMPAIGNS.OPENED}
						</div>
						{opened !== 0 ? (
							<Link to={"#"}>
								<span
									style={{ fontSize: 18, color: "#F04B24" }}
									onClick={() =>
										drilldownMarketingReport(
											tempid,
											"Open",
											constants.BROADCAST_REPORT_TYPE
										)
									}
								>
									{opened}
								</span>
							</Link>
						) : (
							<span style={{ fontSize: 18, color: "#F04B24" }}>{opened}</span>
						)}
						<br />
						{getPercentage(opened)}
					</div>
					<div className="report_gap"></div>
					<div className="report_box">
						<div
							style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
							title={getLocalizedStrings().label.CAMPAIGNS.UNOPENED}
						>
							{getLocalizedStrings().label.CAMPAIGNS.UNOPENED}
						</div>
						{unopened !== 0 ? (
							<Link to={"#"}>
								<span
									style={{ fontSize: 18, color: "#F04B24" }}
									onClick={() =>
										drilldownMarketingReport(
											tempid,
											"Unopened",
											constants.BROADCAST_REPORT_TYPE
										)
									}
								>
									{unopened}
								</span>
							</Link>
						) : (
							<span style={{ fontSize: 18, color: "#F04B24" }}>{unopened}</span>
						)}
						<br />
						{getPercentage(unopened)}
					</div>
					<div className="report_gap"></div>

					<div className="report_box">
						<div
							style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
							title={getLocalizedStrings().label.CAMPAIGNS.CLICKED}
						>
							{getLocalizedStrings().label.CAMPAIGNS.CLICKED}
						</div>
						{clicked !== 0 ? (
							<Link to={"#"}>
								<span
									style={{ fontSize: 18, color: "#F04B24" }}
									onClick={() =>
										drilldownMarketingReport(
											tempid,
											"click",
											constants.BROADCAST_REPORT_TYPE
										)
									}
								>
									{clicked}
								</span>
							</Link>
						) : (
							<span style={{ fontSize: 18, color: "#F04B24" }}>{clicked}</span>
						)}
						<br />
						{getPercentage(clicked)}
					</div>
					<div className="report_gap"></div>

					<div className="report_box">
						<div
							style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
							title={getLocalizedStrings().label.CAMPAIGNS.BOUNCED}
						>
							{getLocalizedStrings().label.CAMPAIGNS.BOUNCED}
						</div>
						{bounced !== 0 ? (
							<Link to={"#"}>
								<span
									style={{ fontSize: 18, color: "#F04B24" }}
									onClick={() =>
										drilldownMarketingReport(
											tempid,
											"Bounce",
											constants.BROADCAST_REPORT_TYPE
										)
									}
								>
									{bounced}
								</span>
							</Link>
						) : (
							<span style={{ fontSize: 18, color: "#F04B24" }}>{bounced}</span>
						)}
						<br />
						{getPercentage(bounced)}
					</div>
					<div className="report_gap"></div>

					<div className="report_box">
						<div
							style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
							title={getLocalizedStrings().label.CAMPAIGNS.UNSUBSCRIBED}
						>
							{getLocalizedStrings().label.CAMPAIGNS.UNSUBSCRIBED}
						</div>
						{unsubscribed !== 0 ? (
							<Link to={"#"}>
								<span
									style={{ fontSize: 18, color: "#F04B24" }}
									onClick={() =>
										drilldownMarketingReport(
											tempid,
											"Unsubscribed",
											constants.BROADCAST_REPORT_TYPE
										)
									}
								>
									{unsubscribed}
								</span>
							</Link>
						) : (
							<span style={{ fontSize: 18, color: "#F04B24" }}>
								{unsubscribed}
							</span>
						)}
						<br />
						{getPercentage(unsubscribed)}
					</div>
					<div className="report_gap"></div>

					<div className="report_box">
						<div
							style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
							title={getLocalizedStrings().label.CAMPAIGNS.SPAM}
						>
							{getLocalizedStrings().label.CAMPAIGNS.SPAM}
						</div>
						{spam !== 0 ? (
							<Link to={"#"}>
								<span
									style={{ fontSize: 18, color: "#F04B24" }}
									onClick={() =>
										drilldownMarketingReport(
											tempid,
											"spamreport",
											constants.BROADCAST_REPORT_TYPE
										)
									}
								>
									{spam}
								</span>
							</Link>
						) : (
							<span style={{ fontSize: 18, color: "#F04B24" }}>{spam}</span>
						)}
						<br />
						{getPercentage(spam)}
					</div>
					<div className="report_gap"></div>
					<div className="report_box">
						<div
							style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
							title={getLocalizedStrings().label.CAMPAIGNS.DROPPED}
						>
							{getLocalizedStrings().label.CAMPAIGNS.DROPPED}
						</div>
						{dropped !== 0 ? (
							<Link to={"#"}>
								<span
									style={{ fontSize: 18, color: "#F04B24" }}
									onClick={() =>
										drilldownMarketingReport(
											tempid,
											"dropped",
											constants.BROADCAST_REPORT_TYPE
										)
									}
								>
									{dropped}
								</span>
							</Link>
						) : (
							<span style={{ fontSize: 18, color: "#F04B24" }}>{dropped}</span>
						)}
						<br />
						{getPercentage(dropped)}
					</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default BroadcastCampaign;
