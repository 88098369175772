import React, { useState, useEffect } from "react";
import {
	isValidParam,
	getStringParam,
	getObjectParam,
	getBooleanParam,
} from "../../../services/helper/parameterVerifier";
import AppContainer from "../containers/AppContainer";
import { constants } from "../../../services/constants/constants";
import Layout from "../containers/Layout";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import * as sfDialogs from "../components/sfDialogs";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";

const RedirectComponent = (props) => {
	let params = useParams();
	const app = useSelector((state) => state.app);
	const location = useLocation();
	const [view, setView] = useState(null);
	const [isMounted, setIsMounted] = useState(false);
	useEffect(() => {
		try {
			// console.log("RedirectComponent params", params);
			let moduleName = getStringParam(app.selectedModuleName);
			let url = isValidParam(location) ? getObjectParam(location.pathname) : "";
			let isMountedVal = getBooleanParam(isMounted);
			if (moduleName !== "" || url !== "" || !isMountedVal) {
				setIsMounted(true);
				updateView();
			}
		} catch (error) {
			console.error(
				"Error in 'redirectComponent.js -> componentDidUpdate()':" + error
			);
		}
	}, [app, props]);

	const updateView = () => {
		
		try {
			let url = props.url;
			if(!url){
				url = isValidParam(location) ? getObjectParam(location.pathname) : "";
			}
			let urlParams = {}
			if(url) {
				url = url.substring(1)
				url = url.split('/')
				if(url.length > 2) {
					urlParams.object = url[0]
					urlParams.suffix = url[1]
					urlParams.id = url[2]
				} else {
					urlParams.object = url[0]
					urlParams.suffix = url[1]
				}
			}
			
			let moduleName = getStringParam(app.selectedModuleName);
			let path = isValidParam(url) ? url : "";
			let userSecurityLevel = app.me.t_name;

			if(urlParams.object === constants.LANDING_PAGES_FROALA_OBJECT && (userSecurityLevel && userSecurityLevel !== "Admin")) {
				setView("");
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					getLocalizedStrings().message.COMMON.ACCESS_DENIED,
					null
				);
				return false;
			}
			let phone_text_provider = app.me.phone_text_provider ?? constants.NONE;
			if(urlParams.object === constants.INBOX_OBJECT && (phone_text_provider && phone_text_provider == constants.NONE)) {
				setView("");
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                	getLocalizedStrings().message.PHONE_AND_TEXT_INTEGRATION.NO_PROVIDER,
					null
				);
				return false;
			}

			if (moduleName !== "" && path !== "") {
				setView(
					<AppContainer
						id={"app-container"}
						moduleName={moduleName}
						location={url}
						match={{ params: urlParams ? urlParams : params }}
					/>
				);
			} else if (
				moduleName !== "" &&
				(moduleName === constants.MODULE_OPERATIONS ||
					moduleName === constants.MODULE_SALES ||
					moduleName === constants.MODULE_MARKETING ||
					moduleName === constants.MODULE_SERVICE)
			) {
				setView(<AppContainer id={"app-container"} />);
			} else {
				setView("");
			}
		} catch (error) {
			console.error("Error in 'redirectComponent.js -> updateView()':" + error);
		}
	};

	return <Layout params={params}>{view}</Layout>;
};
export default RedirectComponent;
