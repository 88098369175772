import { actions } from '..//constants/actions'

const detailViewTaskAppintmentInitialState = {

    taskAppointment: null,
    taskAppMounted: false,
    detailViewTaskAppintmentParam: {},
    isRefreshDetailviewGroup: false
    //isRefreshDetailViewListView: false
}

const detailViewTaskAppintmentReducer = (state = detailViewTaskAppintmentInitialState, action) => {
    switch (action.type) {

        case actions.DETAILVIEW_TASK_APP_GET_FULFILLED:
            return {
                ...state,
                taskAppointment: action.payload,
                taskAppMounted: true
            }

        case actions.SAVE_DETAILVIEW_TASK_APP_PARAM:
            return {
                ...state,
                detailViewTaskAppintmentParam: action.payload
            }

        case actions.DETAILVIEW_GROUP_IS_REFERSH:
            return {
                ...state,
                isRefreshDetailviewGroup: action.payload.isRefresh,
            }
        case actions.DETAILVIEW_UNIT_OWNER_TASKS_GET_FULFILLED:
            return {
                ...state,
                unitOwnerTasks: action.payload,
                taskAppMounted: true
            }
    

        default:
            return state;
    }
}

export default detailViewTaskAppintmentReducer;