import React from 'react';
import {connect} from 'react-redux';
import ReportFilter from '../components/reportFilter';
import { isValidParam, getStringParam, getArrayParam, getIntParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import {styles} from '../../../services/constants/styles';
import { ReactChart} from './reportMatrixTable.js';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { setHeaderByDispatch } from '../../../services/actions/headerActions';
import { getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { reportFormatDate, reportToDate, getDateOptions } from '../../../services/helper/common';
import {getOperationDashboardReportData, getOpertaionDashboardInfo} from '../../../services/actions/operationDashboardAction';
import { constants } from '../../../services/constants/constants';
import ShowCircularProgress from '../components/circularProgress';


const mapStateToProps = (state) => {
    return {
        header: state.header,
        app: state.app,
        userlist: state.userlist,
        reportData: state.operationDashboard.reportData,
        reportInfo: state.operationDashboard.reportInfo,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        }
    }
}


class OperationDashBoard  extends React.Component{
constructor(props){
    super(props);
    this.localizedStrings = getLocalizedStrings();
    this.selectedReport = this.getReportObject();
    this.state = {
        reportData: {},

        recentRecordId: null,
        parameterId: null,
        itemType: null,
        userName: null,
        objectName: null,
        dueBy: 'tw',            
        fromDate: null,
        toDate: null,        

        isGlobal: false,
        isCountry: false,
        isFranchise: false,
        selectedFrnProjectId: null,
        selectedFranchise: null,
        selectedFranchise: null,
        
        }
    }


componentDidMount(){
    getOpertaionDashboardInfo(this.selectedReport);
    this.setHeader();
}

setHeader = () => {
    setHeaderByDispatch(constants.OPERATIONS_DASHBOARD, 'Operation Dashboard', null, false, true);
}

UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.reportInfo.mounted !== nextProps.reportInfo.mounted && nextProps.reportInfo.mounted) {
        this.updateStateForRecentReport(nextProps);
        this.loadReport();
    }
}

getReportObject =()=>{
    return({item_name: 'Top 5 Units by Sales', item_type: 'Top5 UnitsbySales', parameter_id:0});
}

getReportParams = () => {
    let reportParams = {};
    try {
        let { recentRecordId, parameterId, itemType, dueBy, userName, objectName,  selectedFrnProjectId, selectedFranchise} = this.state;
        reportParams.recent_record_id = getIntParam(recentRecordId);
        reportParams.parameter_id = getIntParam(parameterId);
        reportParams.item_type = getStringParam(itemType);
        reportParams.due_by = getStringParam(dueBy);
        reportParams.user_names = getStringParam(userName);
        reportParams.object_name = getStringParam(objectName);
        reportParams.selectedFrnProjectId  = getStringParam(selectedFrnProjectId);
        reportParams.selectedFranchise  = getStringParam(selectedFranchise);

        if (this.state.dueBy === 'cust' && isValidParam(this.state.fromDate) && isValidParam(this.state.toDate)) {                   
            reportParams.from_date = reportFormatDate(this.state.fromDate);
            reportParams.to_date = reportFormatDate(this.state.toDate);
        }
        reportParams.type = ""; 

        // let tabInfo = getActiveTabInfo();
        // tabInfo.report_params = reportParams;
    } catch (error) {
        console.error("Error in 'operationDashboard.js -> getReportParams()':" + error);
    }
    return reportParams;
}

loadReport = () => { 
    let reportParams = this.getReportParams();    
    let isValidRequest = true;
    let errorMsg = "";
    try {
        if (getStringParam(reportParams.due_by) === 'cust' && (!isValidParam(reportParams.from_date) || !isValidParam(reportParams.to_date))) {                   
            isValidRequest = false;
            errorMsg = this.localizedStrings.message.INXPRESS_REPORT.BLANK_FROM_TO_DATE;
        }
        if (isValidRequest) {
            getOperationDashboardReportData(reportParams);

        }else {
            this.props.showCustomSnackBar(errorMsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
        }
    } catch(error) {
        console.log("Error in 'operationDashboard.js -> loadReport()':" + error);
    }
}

updateStateForRecentReport = (props) => {
    try {
        let _state = this.state;
        let data = props.reportInfo.recentReport;
        if(isValidParam(_state)) {
            /*************************** START: Default values  ***************************/
            let selectedReport = this.selectedReport;
            _state.parameterId = selectedReport.parameter_id;
            _state.itemType = selectedReport.item_type;
            _state.recentRecordId = 0;
            _state.objectName = constants.ACCOUNTS_OBJECT;
            /*************************** END: Default values    ***************************/

            if(isValidParam(data) && Object.keys(data).length > 0) {
                _state.recentRecordId = data.recent_record_id;
                
                _state.parameterId = data.parameter_id;
                _state.itemType = data.item_type;
                _state.userName = data.user_names;
                _state.objectName = getStringParam(data.object_name) !== '' ? getStringParam(data.object_name) : constants.ACCOUNTS_OBJECT;

                if(getStringParam(data.due_by) !== ''){
                    _state.dueBy = getStringParam(data.due_by);
                }
                if (data.due_by === "cust") {
                    _state.fromDate = reportToDate(data.from_date);
                    _state.toDate = reportToDate(data.to_date);
                } else {
                    _state.fromDate = null;
                    _state.toDate = null;
                }
                _state.workflowId = getIntParam(data.workflow_id);
            }
            this.loadWorkflowByObject(props, _state.objectName);
            // this.setReportParamsFromTab();
        }
    } catch (error) {
        console.error("Error in 'operationDashboard.js -> updateStateForRecentReport()':" + error);
    }
}

setReportParamsFromTab = () => {
    try {
        let tabInfo = getActiveTabInfo();
        let reportParams = getObjectParam(tabInfo.report_params);

        if(Object.keys(reportParams).length > 0) {
            let { recent_record_id, parameter_id, item_type, due_by, user_names, object_name, workflow_id, workflow_name, selectedFrnProjectId, selectedFranchise } = reportParams;

            let state = this.state;
            state.recentRecordId = getIntParam(recent_record_id);
            state.parameterId = getIntParam(parameter_id);
            state.itemType = getStringParam(item_type);
            state.dueBy = getStringParam(due_by);
            state.userName = getStringParam(user_names);
            state.objectName = getStringParam(object_name);
            state.workflowId = getIntParam(workflow_id);
            state.workflowName = getStringParam(workflow_name);
            state.selectedFrnProjectId = getStringParam(selectedFrnProjectId);
            state.selectedFranchise = getStringParam(selectedFranchise);
            if (state.dueBy === "cust" && isValidParam(reportParams.from_date) && isValidParam(reportParams.to_date)) {
                state.fromDate = reportToDate(reportParams.from_date);
                state.toDate = reportToDate(reportParams.to_date);
            } else {
                state.fromDate = null;
                state.toDate = null;
            }
        }            
    } catch (error) {
        console.error("Error in 'operationDashboard.js -> setReportParamsFromTab()':" + error);
    }
}

loadWorkflowByObject = (props, object) => {
    try {
        object = getStringParam(object);
        let objectWorkflow = isValidParam(props.reportInfo) && props.reportInfo.mounted ? getObjectParam(props.reportInfo.objectWorkflow) : null;
        if(object !== '' && isValidParam(objectWorkflow)) {
            let workflowList = getArrayParam(objectWorkflow[object]);
            workflowList = workflowList.map(function (wf, index) {
                return { id: wf.id, label: wf.name };
            });
            let { workflowId, workflowName } = this.state;
            if(workflowList.length > 0 && getIntParam(workflowId) === -99){
                workflowId  = getIntParam(workflowList[0].id);
                workflowName = getStringParam(workflowList[0].name);
            } else if(workflowList.length > 0 && workflowId > 0) {
                workflowList.map((f, i) => {
                    if(f.id === workflowId){
                        workflowName =  f.name;
                    }
                });
            }
            this.state = {...this.state, workflowList: workflowList, workflowId: workflowId, workflowName: workflowName};
        }
    } catch (error) {
        console.log("Error in 'operationDashboard.js -> loadWorkflowByObject()':" + error);
    }
}


getFilterProps = () => {
    let selectedReport = this.selectedReport;
    // let itemType = getStringParam(selectedReport.item_type);
    let fieldProps = [];

    fieldProps.push({
        date: {
            list: getDateOptions(['blank']),
            onChange: this.handleDropDownChange.bind(this, 'dueBy'),
            defaultValue: this.state.dueBy,
            fromDateOptions: {maxDate: new Date()},
            toDateOptions: {minDate: this.getMinDate()},
            profile: this.props.app.me,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate
        }
    });
    return fieldProps;
}

handleDropDownChange = (name, event, value) => {
    let selectedReport = this.selectedReport;
    let _state = this.state;
    _state[name] = value;
    if (name === "dueBy") {
        if(event === 'fromDate' || event === 'toDate') {
            _state[name] = 'cust';
            if(event === 'fromDate') {
                _state.fromDate = value;
            } else if(event === 'toDate') {
                _state.toDate = value;
            }                    
            this.setState(_state);
        } else if (value === "cust") {
            _state.fromDate = this.getDate();
            _state.toDate = this.getDate();
            this.setState(_state);
        } else {
            _state.fromDate = null;
            _state.toDate = null;
            this.setState(_state);
        }
    } 
}

getDate = (date) => {
    let dateValue = isValidParam(date) ? new Date(date) : new Date();
    dateValue = new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate());
    return dateValue;
}

getMinDate = () => {
    let minDateVal = null;
    if (isValidParam(this.state.fromDate)) {
        let fromDateVal = new Date(this.state.fromDate);
        minDateVal = new Date(fromDateVal.getFullYear(), fromDateVal.getMonth(), fromDateVal.getDate());
    }
    return minDateVal;
}


generateChart = (reportData) => {
// reportData = JsonReturnData.data;
    if (reportData !== undefined && Object.keys(reportData).length > 0 && getArrayParam(reportData.report_values).length > 0) {
        return reportData.report_values.map((reportDataMap, index) => {
            let reportName = reportData.report_values[index].name;

            if (reportName === 'Top 5 Units by Sales' || reportName === 'Bottom 5 Units by Sales') {
                let reportConfData = reportData.report_values[index].value;
                // let reportConfObject = processChartData(reportConfData, 'column', reportName);
               let drWidth= window.innerWidth-60;
                return (
                    <div className="col-md-12" style={{paddingLeft:'2px',fontSize:'20px', width: drWidth}}>
                        <ReactChart config={reportConfData} />
                    </div>
                );
            } 
        });
    }
}

generateReportTypeWiseDiv() {

        let reportTypeName = 'Top 5 Units by Sales'; 
        if (reportTypeName === 'Top 5 Units by Sales' || reportTypeName === 'Bottom 5 Units by Sales' ) {
            return this.props.reportData.mounted ? this.generateChart(this.props.reportData) : <Loader />;
        } 
    }

render(){
    if (!this.props.reportInfo.mounted) {
        return <Loader/>
    }
    let reportTypeWiseDiv = this.generateReportTypeWiseDiv();
    return <div style={{ width: '97%', marginLeft: '20px'}}>
        <ReportFilter fieldProps={this.getFilterProps()} reload={this.loadReport} printContentId="printable-contents"  object={this.props.object}/>
        <div style={{ width: '97%',display : 'flex'}}></div>
        <div id="printable-contents" style={{ width: '60%'}}>{reportTypeWiseDiv}</div>
    </div>
    }
}
OperationDashBoard = connect(mapStateToProps, mapDispatchToProps)(OperationDashBoard);
export default OperationDashBoard;
const Loader = () => {
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2;
    return (
        <div style={{ width: '100%', height: contentHeight }}>
        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
            </div>
        </div>
    </div>
    );
}

