import { actions } from '..//constants/actions';
import { isInvalidParam } from '..//helper/parameterVerifier';

function showSnackBar(message) {
    return function (dispatch) {
        dispatch({
            type: actions.SNACKBAR_MESSAGE_VIEW,
            payload: {
                open: true,
                message: message,
                duration: 400,
            }
        })
    }
}

function showCustomSnackBar(message, bodyStyle, style, autoDuration) {
    return function (dispatch) {
        dispatch({
            type: actions.SNACKBAR_CUSTOM_VIEW,
            payload: {
                open: true,
                message: message,
                duration: isInvalidParam(autoDuration) ? 2000 : autoDuration,
                bodyStyle: bodyStyle,
                style: style,
            }
        })
    }
}

function hideSnackBar() {
    return function (dispatch) {
        dispatch({
            type: actions.SNACKBAR_MESSAGE_VIEW,
            payload: {
                open: false,
                message: '',
                duration: 400,
            }
        })
    }
}

export {
    showSnackBar,
    hideSnackBar,
    showCustomSnackBar
}
