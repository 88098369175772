
import React from 'react';
import * as HTTPClient from '../../../services/helper/httpClient';
import { isValidParam, getStringParam, getObjectParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import SFCustomDataTable from '../components/sfCustomDataTable';
import { styles } from '../../../services/constants/styles';
import { DASHBOARD } from '../../../services/constants/constants';
import ShowCircularProgress from '../components/circularProgress';

export default class DashboardDrilldownTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            startIndex: 0,
            fetchSize: DASHBOARD.FETCH_SIZE,
            isShowMore: false,
            responseData: {
                columns: null,
                records: null
            }
        }
    }
    componentDidMount() {
        this.getDrilldownData(this.props.data);
    }

    getDrilldownData = (data) => {
        try {
            if (isValidParam(data) && getStringParam(data.url) !== '') {

                let url = getStringParam(data.url);

                let params = {};
                params.header = getStringParam(data.header);
                params.timeLine = getStringParam(data.timeLine);
                params.value = getStringParam(data.value);
                params.startIndex = this.state.startIndex;
                params.fetchSize = this.state.fetchSize;

                let promise = Promise.resolve(HTTPClient.get(url, params));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            let responseData = getObjectParam(this.state.responseData);
                            let records = getArrayParam(response.records);
                            let showMore = this.state.isShowMore;
                            responseData.columns = getArrayParam(response.columns);
                            
                            if (getArrayParam(this.state.responseData.records).length > 0) {
                              responseData.records = responseData.records.concat(records);
                            } else {
                              responseData.records = records;
                            }
                             responseData.records = responseData.records.map(record => {
                              let tempRecordData = { ...record };
                              Object.keys(tempRecordData).forEach(key => {
                                if (typeof tempRecordData[key] === 'string') {
                                  tempRecordData[key] = tempRecordData[key].replace(/(&nbsp;|\n\n|<([^>]+)>)/gi, " ");
                                }
                              });
                              return tempRecordData;
                            });
                          
                            if (records.length < this.state.fetchSize) {
                              showMore = false;
                            } else {
                              showMore = true;
                            }
                            
                            this.setState({ responseData: responseData, isShowMore: showMore });
                            console.log("responseData", responseData.records);
                          }
                          
                    });
                }
            }
        }
        catch (error) {
            console.log("Error in 'dashboardDrilldownTable.js -> getDrilldownData()':" + error);
        }
    }

    handleMore = () => {
        this.state.startIndex = this.state.startIndex + this.state.fetchSize;
        this.getDrilldownData(this.props.data);
        this.setState({});
    }

    downloadExcel = () => {
        try {
            let records = this.state.responseData.records;
            let columns = this.state.responseData.columns.filter(f => {
                if (!f.is_hidden) { return f; }
            });
            let beforeHeaderComponent = this.excelGenerateBeforeHeaders(columns);
            let headerComponents = this.excelGenerateHeaders(columns);
            let rowComponents = this.excelGenerateRows(columns, records);

            let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '  </tbody> </table>';
            let htmlData = htmlTable;
            if (isValidParam(htmlData) && htmlData !== '') {
                htmlData = htmlData.replace("%22", "\"");
                var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
                var downloadUrl = URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = downloadUrl;
                a.download = 'DashboardResultSet_' + new Date().getTime() + '.xls';
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        } catch (error) {
            console.error("Error in 'dashboardDrilldownTable.js -> downloadExcel()':" + error);
        }
    }

    excelGenerateBeforeHeaders = (columns) => {
        let cells = '';
        const optionvalue = {
            td: 'Today',
            yd: 'Yesterday',
            tw: 'This Week',
            tm: 'This Month',
            tq: 'This Quarter',
            ty: 'This Year',
            lw: 'Last Week',
            lm: 'Last Month',
            lq: 'Last Quarter',
            ly: 'Last Year'
        };
        try {
            let data = getObjectParam(this.props.data);
            let reportName = getStringParam(data.header);
            let timeLine = optionvalue[getStringParam(data.timeLine)];
            let option = getStringParam(data.value);

            let cols = getArrayParam(columns);
            let columnsLength = getArrayParam(cols).length;

            cells = cells + '<tr><td  colspan="' + (columnsLength) + '"> ' + reportName + ' </td></tr>';
            if (reportName.length > 0) {
                cells = cells + '<tr><td> Date :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + timeLine + ' </td></tr>';
                cells = cells + '<tr><td> Selection :</td>  <td  align=\'left\'  colspan="' + (columnsLength - 1) + '"> ' + ' ' + option + ' </td></tr>';
            }
        }
        catch (error) {
            console.error("Error in 'dashboardDrilldownTable.js -> excelGenerateBeforeHeaders()':" + error);
        }
        return cells;
    }

    excelGenerateHeaders = (columns) => {
        let cells = '';
        try {
            let cols = getArrayParam(columns);
            cols.map((columnObject, index) => {
                if (!columnObject.is_hidden) {
                    cells = cells + '<th  bgcolor="6A8CCB"> ' + columnObject.label + ' </th>';
                }
            })
        }
        catch (error) {
            console.error("Error in 'dashboardDrilldownTable.js -> excelGenerateHeaders()':" + error);
        }
        return ('<tr>' + cells + '</tr>');
    }

    excelGenerateRows = (columns,records) => {
        let rows = getArrayParam(records);
        let cols =  getArrayParam(columns);
        let rowData = '';
        try {
            if (rows.length !== 0) {
                let cells = '';
                rows.map((rowObject, rowIndex) => {
                    let innerCells = '';
                    cols.map((columnObject, colIndex) => {
                        if (!columnObject.is_hidden) {
                            innerCells = innerCells + '<td >' + rowObject[columnObject.name] + ' </td>';
                        }
                    })
                    if (rowIndex % 2 === 0) {
                        cells = cells + ('<tr>' + innerCells + ' </tr>');
                    } else {
                        cells = cells + ('<tr  bgcolor="Lavender">' + innerCells + ' </tr>');
                    }
                });
                rowData = cells;
            } else {
                rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
            }
    }
    catch(error){
        console.error("Error in 'dashboardDrilldownTable.js -> excelGenerateRows()':"+error);
    }
    return rowData; 
    }

    render() {
        let object = "dashboard";
        let data = getObjectParam(this.state.responseData);
        let pageHeight = window.innerHeight - 247;
        let top = (pageHeight-10) / 2

        if (data.columns === null || data.records === null) {
            return(
                <div style={{ width: '100%', height: pageHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
        );
        } else {
            return (
                <div>
                    <SFCustomDataTable object={object} data={data} style={{ height: 400 }} />
                    {this.state.isShowMore && <label style={{ background: '#f75723', width: 76, fontStyle: 'normal', marginLeft: 11, cursor: 'pointer', color: '#ffffff', fontWeight: 400, textAlign: 'center', display: 'inline-block',paddingBottom:'2px',fontSize:'14px',paddingTop:'2px' }} onClick={this.handleMore}>More</label>}
                    <label style={{ background: '#f75723', width: 76, fontStyle: 'normal', marginLeft: 11, cursor: 'pointer', color: '#ffffff', fontWeight: 400, textAlign: 'center', display: 'inline-block',paddingBottom:'2px',fontSize:'14px',paddingTop:'2px' }} onClick={this.downloadExcel}>Excel</label>
                    {/*   <Button endIcon={<i className="fa fa-file-excel-o" style={{ fontSize: '20px', color: 'green' }} />}
                                //onClick={() => { this.downloadExcel(this.props.config) }}
                                style={{
                                    ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                                    color: '#717171',
                                    border: '0px',
                                    minWidth: '30px'
                                }}
                                // title="Excel"
                               // title={getLocalizedStrings().label.REPORT.EXCEL}
                            ></Button> */}
                </div>
            );
        }
    }
}