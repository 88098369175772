import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import ShowCircularProgress from '../components/circularProgress';
import ListView from '../containers/ListView';
import * as HTTPClient from '../../../services/helper/httpClient';
import { getActiveTabInfo, getActiveTab } from '../../../services/helper/sfTabManager';
import { getObjectParam, isValidParam, isUrl, getStringParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { endPoints } from '../../../services/constants/endPoints';
import { constants, EVENT_TYPE_KEYS } from '../../../services/constants/constants';
import { setMarketingResults } from '../../../services/actions/marketingReportAction';
import { updateTabInfo } from '../../../services/helper/sfTabManager';
import { setHeader } from '../../../services/actions/headerActions';
import { styles } from '../../../services/constants/styles';
import { refreshListView } from '../../../services/actions/listViewAcions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';

const LinkClickInfo = ({ propsValue,selectedMsg }) => {

    const tab = useSelector((state) => state.tab);

    const [isMounted, setIsMounted] = useState(false);
    const [arrReportDetail, setArrReportDetail] = useState(new Array);
    const [gblStatIdSubArr, setGblStatIdSubArr] = useState(new Array);
    const [campaignId, setCampaignId] = useState(0);
    const [messageName, setMessageName] = useState([]);
    const [linkClickInfoArray, setLinkClickInfoArray] = useState(null);
    const [statId, setStatId] = useState(0);
    const [linkClickInfo, setLinkClickInfo] = useState(null);
    const [isListviewMounted, setIsListviewMounted] = useState(false);
    const [clickedUrl, setClickedUrl] = useState(null);
    const [selectedMsgName, setSelectedMsgName] = useState(null);


    useEffect(() => {
      generateLinkClickInfoDetails(propsValue);
    }, []);

    useEffect(() => {
            if (isValidParam(clickedUrl)) {
            if (clickedUrl === 'all') {
                document.getElementById("All_listview_section").style.display = 'block';
            } else {
                document.getElementById(clickedUrl + "_listview_section").style.display = 'block';
            }
        }
    }, [clickedUrl]);

    useEffect(() => {
        if (getStringParam(selectedMsgName) !== '' &&
            getStringParam(selectedMsgName) !== '' &&
            selectedMsgName !== selectedMsgName) {
            setIsMounted(false);
            generateLinkClickInfoDetails(propsValue);
        }
    }, [selectedMsgName,messageName]);

    const generateLinkClickInfoDetails = (propsValue) => {
        try {
            let tabInfo = getObjectParam(getActiveTabInfo());
            if (isValidParam(tabInfo) && tabInfo.hasOwnProperty('input_param')) {
                let inputParam = getObjectParam(tabInfo.input_param);
                fetchLinkClickDetailInfo(inputParam, propsValue);
            }
        } catch (error) {
            console.error("Error in 'LinkClickInfo.js -> generateLinkClickInfoDetails()':" + error);
        }
    }

    const fetchLinkClickDetailInfo = (inputParam, propsValue) => {
        try {
            let params = {
                stat_id: inputParam.stat_id,
                url: "",
            }
            var promise = Promise.resolve(HTTPClient.get(endPoints.REPORTS.LINK_CLICK_INFO, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.hasOwnProperty('link_click_info') && isValidParam(response['link_click_info']) &&
                        response['link_click_info'].length > 0) {
                        let linkClickInfoArray = response['link_click_info'];
                        params = {
                            stat_id: inputParam.stat_id,
                            url: "all",
                        }
                        var promise = Promise.resolve(HTTPClient.get(endPoints.REPORTS.LINK_CLICK_INFO, params));
                        promise.then((response) => {
                            if (response.hasOwnProperty('link_click_info') && isValidParam(response['link_click_info']) &&
                                response['link_click_info'].length > 0) {
                                let arrLinkClickInfo = response['link_click_info'];
                                let totalCountValue = arrLinkClickInfo[0].count;
                                let percentageValue = 0;
                                linkClickInfoArray.map((linkClickArrObj, i) => {
                                    percentageValue = Math.round((linkClickArrObj.count / totalCountValue) * 100);
                                    linkClickArrObj.percentage = percentageValue;
                                })
                                linkClickInfoArray = linkClickInfoArray.concat(arrLinkClickInfo);
                                setLinkClickInfoArray(linkClickInfoArray);
                                setStatId(inputParam.stat_id);
                                setMessageName(selectedMsg);
                                setIsMounted(true);
                            }
                        })
                    }
                }
            });
        } catch (error) {
            console.error("Error in 'LinkClickInfo.js -> fetchLinkClickDetailInfo()':" + error);
        }
    }

    const openUrl = (url) => {
        try {
            if (isValidParam(url) && isUrl(url)) {
                window.open(url);
            }
        } catch (error) {
            console.error("Error in 'LinkClickInfo.js -> openUrl()':" + error);
        }
    }

    const drilldownMarketingReport = (clickedUrl) => {
        try {
            let listViewDivElements = document.getElementsByClassName('listview_section');
            for (var i = 0; i < listViewDivElements.length; i++) {
                listViewDivElements[i].style['display'] = 'none';
            }
            if (document.getElementById(clickedUrl + "_listview_section").style.display !== 'block') {
                document.getElementById(clickedUrl + "_listview_section").style.display = 'block'

                if (clickedUrl === "All" || clickedUrl === 'all') {
                    clickedUrl = clickedUrl.toLowerCase();
                }

                let tab = getObjectParam(getActiveTab());
                let info = getObjectParam(tab.info);
                let isRefresh = info.input_param.hasOwnProperty('url');
                info.input_param.url = clickedUrl;
                updateTabInfo(info);
                if (isRefresh) {
                    refreshListView(constants.CONTACTS_OBJECT);
                }
                setIsListviewMounted(true);
                setClickedUrl(clickedUrl);
            }
        } catch (error) {
            console.error("Error in 'LinkClickInfo.js -> drilldownMarketingReport()':" + error);
        }
    }

    const populateLinkClickInfo = () => {
        let linkClickInfo = [];
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        try {
            let tempLinkClickInfoArray = linkClickInfoArray;
            if (isValidParam(tempLinkClickInfoArray) && tempLinkClickInfoArray.length > 0) {
                let totalCount = 0;

                tempLinkClickInfoArray.map((objLinkclickInfo, i) => {
                    totalCount = totalCount + objLinkclickInfo.count;
                    linkClickInfo.push(
                        <div>
                            <div style={{ display: 'flex' }}>
                                <div align="left" className="report_box" style={{ width: '64%', padding: '10px 20px', minHeight: '69px', wordBreak: 'break-all' }}>
                                    {
                                        (objLinkclickInfo.url !== 'All') &&
                                        <Link to="#" onClick={() => openUrl(objLinkclickInfo.url)} style={{ float: 'left', color: '#717171', textDecoration: 'none' }}>
                                            {objLinkclickInfo.url}
                                        </Link>
                                    }
                                    {
                                        (objLinkclickInfo.url === 'All') &&
                                        <span style={{ float: 'left', color: '#717171' }}>{objLinkclickInfo.url}</span>
                                    }
                                </div>
                                <div className="report_gap"></div>
                                <div align="center" className="report_box" style={{ width: '10%', padding: '10px 20px', minHeight: '69px' }}>
                                    <Link to="#" onClick={() => drilldownMarketingReport(objLinkclickInfo.url)}>
                                        <span style={{ fontSize: 18, color: '#F04B24' }}>{objLinkclickInfo.count}</span>
                                    </Link>
                                    <br />
                                    {
                                        (objLinkclickInfo.hasOwnProperty('percentage')) &&
                                        <span>{objLinkclickInfo['percentage'] + '%'}</span>
                                    }
                                </div>
                            </div>
                            <div id={objLinkclickInfo.url + "_listview_section"} className="listview_section" style={{ display: 'none', marginLeft: '-24px', paddingBottom: '13px' }}>
                                {
                                    isListviewMounted ?
                                        <ListView object={constants.CONTACTS_OBJECT} isReport={true} />
                                        :
                                        <div style={{ width: '100%', height: contentHeight }}>
                                            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                                                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    );
                });
            }
        } catch (error) {
            console.error("Error in 'LinkClickInfo.js -> populateLinkClickInfo()':" + error);
        }
        return linkClickInfo;
    }
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2;
    // let tempIsMounted = isMounted;
    let messageArr = [];
    messageArr=getArrayParam(selectedMsg) ;

    let tempLinkClickInfo = isMounted ? populateLinkClickInfo() : null;
    if (!isMounted) {
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div style={{ paddingLeft: '41px', color: '#717171' }} >
            <div><h4>{getLocalizedStrings().label.COMMON.CTR_DETAILS_FOR} { messageArr }</h4></div>
            <div style={{ paddingBottom: '14px', fontSize: '1.1em' }}>
                <div align="left" style={{ width: '64%', display: 'inline-block' }}>{getLocalizedStrings().label.COMMON.URL}</div>
                <div align="center" style={{ width: '10%', display: 'inline-block', paddingLeft: '24px' }}>{getLocalizedStrings().label.COMMON.COUNT}</div>
            </div>
            <div>{tempLinkClickInfo}</div>
        </div>
    );
}

export default LinkClickInfo;

