import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { constants } from '../../../services/constants/constants';
import { getDefaultBeeEditorData } from '../../../services/helper/common';
import { getStringParam } from '../../../services/helper/parameterVerifier';
import Bee from "@mailupinc/bee-plugin";

const templateDatTest = {
    page: {
        body: {
            container: { style: { "background-color": "#FFFFFF" } },
            content: {
                computedStyle: {
                    linkColor: "#7c4b96",
                    messageBackgroundColor: "transparent",
                    messageWidth: "500px"
                },
                style: {
                    color: "#000000",
                    "font-family": "Arial, Helvetica Neue, Helvetica, sans-serif"
                }
            },
            type: "mailup-bee-page-properties",
            webFonts: []
        },
        description: "",
        rows: [
            {
                columns: [
                    {
                        "grid-columns": 6,
                        modules: [
                            {
                                descriptor: {
                                    computedStyle: {
                                        class: "center fixedwidth",
                                        width: "187.5px"
                                    },
                                    image: {
                                        alt: "Image",
                                        href: "https://beefree.io",
                                        src:
                                            "https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/84613a3a-908f-4d45-b9e3-43f2455173e3/84613a3a-908f-4d45-b9e3-43f2455173e3/sacha-styles-0sPKk1YWmBc-unsplash.jpg",
                                        width: "1694px",
                                        height: "2117px"
                                    },
                                    style: {
                                        "padding-bottom": "10px",
                                        "padding-left": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        width: "100%"
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-image",
                                uuid: "9cebbdc5-30d7-4754-8ab0-b26513f32aea"
                            }
                        ],
                        style: {
                            "background-color": "transparent",
                            "border-bottom": "0px solid transparent",
                            "border-left": "0px solid transparent",
                            "border-right": "0px solid transparent",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "5px",
                            "padding-left": "0px",
                            "padding-right": "0px",
                            "padding-top": "5px"
                        },
                        uuid: "268f0566-9446-4097-a3fb-44f23d198e92"
                    },
                    {
                        "grid-columns": 6,
                        modules: [
                            {
                                descriptor: {
                                    computedStyle: { align: "center", hideContentOnMobile: true },
                                    divider: {
                                        style: {
                                            "border-top": "0px solid transparent",
                                            height: "36px",
                                            width: "100%"
                                        }
                                    },
                                    style: {
                                        "padding-bottom": "0px",
                                        "padding-left": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px"
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-divider",
                                uuid: "d484d332-9c2c-4adc-96ea-b7bfd518402d"
                            },
                            {
                                descriptor: {
                                    computedStyle: { hideContentOnMobile: false },
                                    style: {
                                        "padding-bottom": "20px",
                                        "padding-left": "5px",
                                        "padding-right": "5px",
                                        "padding-top": "0px"
                                    },
                                    text: {
                                        computedStyle: { linkColor: "#7c4b96" },
                                        html:
                                            '<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 18px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;" data-mce-style="font-size: 12px; line-height: 18px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;"><p style="font-size: 18px; line-height: 27px; text-align: center; word-break: break-word; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;" data-mce-style="font-size: 18px; line-height: 27px; text-align: center; word-break: break-word;"><span style="font-size: 15px; line-height: 22px;" data-mce-style="font-size: 15px; line-height: 22px;"><strong><span style="line-height: 22px; font-size: 15px;" data-mce-style="line-height: 22px; font-size: 15px;"><span style="line-height: 22px; font-size: 15px;" data-mce-style="line-height: 22px; font-size: 15px;">TEST email</span></span></strong></span></p></div>',
                                        style: {
                                            color: "#7c4b96",
                                            "font-family":
                                                "Arial, 'Helvetica Neue', Helvetica, sans-serif",
                                            "line-height": "150%"
                                        }
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-text",
                                uuid: "0c8dd836-f552-4574-83ba-c9374c51601d"
                            }
                        ],
                        style: {
                            "background-color": "transparent",
                            "border-bottom": "0px solid transparent",
                            "border-left": "0px solid transparent",
                            "border-right": "0px solid transparent",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "5px",
                            "padding-left": "0px",
                            "padding-right": "0px",
                            "padding-top": "5px"
                        },
                        uuid: "5b0e0f97-29d7-477e-b55c-be0ad826d2bf"
                    }
                ],
                container: {
                    style: {
                        "background-color": "#E5E5E5",
                        "background-image": "none",
                        "background-position": "top left",
                        "background-repeat": "no-repeat"
                    }
                },
                content: {
                    computedStyle: { rowColStackOnMobile: true },
                    style: {
                        "background-color": "transparent",
                        "background-image": "none",
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        color: "#333",
                        width: "500px"
                    }
                },
                locked: false,
                type: "two-columns-empty",
                uuid: "82bcb135-feac-4050-8b65-910420f4be64"
            },
            {
                columns: [
                    {
                        "grid-columns": 12,
                        modules: [
                            {
                                descriptor: {
                                    computedStyle: { align: "center", hideContentOnMobile: true },
                                    divider: {
                                        style: {
                                            "border-top": "0px solid transparent",
                                            height: "10px",
                                            width: "100%"
                                        }
                                    },
                                    style: {
                                        "padding-bottom": "10px",
                                        "padding-left": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px"
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-divider",
                                uuid: "bd31ede2-fe2c-4676-9d9d-312ab3b4ef2e"
                            },
                            {
                                descriptor: {
                                    computedStyle: {
                                        class: "center  autowidth  fullwidth",
                                        hideContentOnMobile: false,
                                        width: "500px"
                                    },
                                    image: { alt: "Image", href: "", src: "" },
                                    style: {
                                        "padding-bottom": "0px",
                                        "padding-left": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        width: "100%"
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-image",
                                uuid: "84299d0c-cc0b-4c3d-aa40-30c173d88d25"
                            },
                            {
                                descriptor: {
                                    computedStyle: { align: "center", hideContentOnMobile: true },
                                    divider: {
                                        style: {
                                            "border-top": "0px solid transparent",
                                            height: "10px",
                                            width: "100%"
                                        }
                                    },
                                    style: {
                                        "padding-bottom": "10px",
                                        "padding-left": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px"
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-divider",
                                uuid: "b71f67d4-e494-4bc2-a8b2-928dc4c782f7"
                            }
                        ],
                        style: {
                            "background-color": "transparent",
                            "border-bottom": "0px solid transparent",
                            "border-left": "0px solid transparent",
                            "border-right": "0px solid transparent",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "0px",
                            "padding-left": "0px",
                            "padding-right": "0px",
                            "padding-top": "0px"
                        },
                        uuid: "09b059ba-ecd2-4e28-8638-81d5901a3298"
                    }
                ],
                container: {
                    style: {
                        "background-color": "#f3f3f3",
                        "background-image": "none",
                        "background-position": "top left",
                        "background-repeat": "no-repeat"
                    }
                },
                content: {
                    computedStyle: { rowColStackOnMobile: true },
                    style: {
                        "background-color": "transparent",
                        "background-image": "none",
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        color: "#000000",
                        width: "500px"
                    }
                },
                locked: false,
                type: "one-column-empty",
                uuid: "1f9fb237-09fe-4278-8c1f-ea58311c1ed8"
            },
            {
                columns: [
                    {
                        "grid-columns": 12,
                        modules: [
                            {
                                descriptor: {
                                    computedStyle: { align: "center" },
                                    divider: {
                                        style: {
                                            "border-top": "10px solid transparent",
                                            height: "0px",
                                            width: "100%"
                                        }
                                    },
                                    style: {
                                        "padding-bottom": "10px",
                                        "padding-left": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px"
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-divider",
                                uuid: "5ff32059-f595-4cdf-8e0e-46cef8b8a734"
                            }
                        ],
                        style: {
                            "background-color": "transparent",
                            "border-bottom": "0px solid transparent",
                            "border-left": "0px solid transparent",
                            "border-right": "0px solid transparent",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "5px",
                            "padding-left": "0px",
                            "padding-right": "0px",
                            "padding-top": "5px"
                        },
                        uuid: "bf9cd543-a2e6-4963-981b-90d7aba6e48a"
                    }
                ],
                container: {
                    style: {
                        "background-color": "transparent",
                        "background-image": "none",
                        "background-position": "top left",
                        "background-repeat": "no-repeat"
                    }
                },
                content: {
                    computedStyle: { rowColStackOnMobile: true },
                    style: {
                        "background-color": "transparent",
                        "background-image": "none",
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        color: "#333",
                        width: "500px"
                    }
                },
                locked: false,
                type: "one-column-empty",
                uuid: "a950e78f-1d30-43ab-8a21-f8f3a6cc6e94"
            },
            {
                columns: [
                    {
                        "grid-columns": 6,
                        modules: [
                            {
                                descriptor: {
                                    computedStyle: {
                                        class: "center  autowidth ",
                                        width: "180px"
                                    },
                                    image: { alt: "Image", href: "https://beefree.io", src: "" },
                                    style: {
                                        "padding-bottom": "0px",
                                        "padding-left": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        width: "100%"
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-image",
                                uuid: "1a4d5d14-b36c-48da-98ba-8be5d82629fd"
                            },
                            {
                                descriptor: {
                                    computedStyle: { hideContentOnMobile: false },
                                    style: {
                                        "padding-bottom": "0px",
                                        "padding-left": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "15px"
                                    },
                                    text: {
                                        computedStyle: { linkColor: "#7c4b96" },
                                        html:
                                            '<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 14px;" data-mce-style="font-size: 12px; line-height: 14px;"><p style="font-size: 14px; line-height: 16px; text-align: center; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px; text-align: center;"><span style="font-size: 20px; line-height: 24px;" data-mce-style="font-size: 20px; line-height: 24px;"><strong><span style="line-height: 24px; font-size: 20px;" data-mce-style="line-height: 24px; font-size: 20px;">I\'m a very important title</span></strong></span></p></div>',
                                        style: {
                                            color: "#2C2C2C",
                                            "font-family": "inherit",
                                            "line-height": "120%"
                                        }
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-text",
                                uuid: "16e561c3-b90e-4038-bc66-9ecd0f3029fe"
                            },
                            {
                                descriptor: {
                                    computedStyle: { hideContentOnMobile: false },
                                    style: {
                                        "padding-bottom": "5px",
                                        "padding-left": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "5px"
                                    },
                                    text: {
                                        computedStyle: { linkColor: "#7c4b96" },
                                        html:
                                            '<div class="txtTinyMce-wrapper" data-mce-style="font-size: 12px; line-height: 14px;" style="font-size: 12px; line-height: 14px;"><p style="font-size: 14px; line-height: 16px; text-align: center; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px; text-align: center;">I\'m a block of text and I like latin.</p></div>',
                                        style: {
                                            color: "#aaaaaa",
                                            "font-family": "inherit",
                                            "line-height": "120%"
                                        }
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-text",
                                uuid: "40999cc0-4e59-4207-90cf-c8f144ac5619"
                            },
                            {
                                descriptor: {
                                    computedStyle: { hideContentOnMobile: false },
                                    style: {
                                        "padding-bottom": "10px",
                                        "padding-left": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "10px"
                                    },
                                    text: {
                                        computedStyle: { linkColor: "#7c4b96" },
                                        html:
                                            '<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 14px;" data-mce-style="font-size: 12px; line-height: 14px;"><p style="font-size: 12px; line-height: 14px; text-align: center; word-break: break-word;" data-mce-style="font-size: 12px; line-height: 14px; text-align: center;"><a href="https://beefree.io" target="_blank" rel="noopener"><strong><span style="font-size: 14px; line-height: 16px;" data-mce-style="font-size: 14px; line-height: 16px;"><span style="text-decoration: underline; font-size: 14px; line-height: 16px;" data-mce-style="text-decoration: underline; font-size: 14px; line-height: 16px;">More info</span></span></strong></a></p></div>',
                                        style: {
                                            color: "#888888",
                                            "font-family": "inherit",
                                            "line-height": "120%"
                                        }
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-text",
                                uuid: "4d088a43-620d-4f5f-9b0b-f0518d767fbd"
                            }
                        ],
                        style: {
                            "background-color": "transparent",
                            "border-bottom": "0px solid transparent",
                            "border-left": "0px solid transparent",
                            "border-right": "0px solid transparent",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "20px",
                            "padding-left": "0px",
                            "padding-right": "0px",
                            "padding-top": "20px"
                        },
                        uuid: "69a41944-c23c-4789-8629-e61738a3082f"
                    },
                    {
                        "grid-columns": 6,
                        modules: [
                            {
                                descriptor: {
                                    computedStyle: {
                                        class: "center  autowidth ",
                                        width: "180px"
                                    },
                                    image: { alt: "Image", href: "https://beefree.io", src: "" },
                                    style: {
                                        "padding-bottom": "0px",
                                        "padding-left": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        width: "100%"
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-image",
                                uuid: "81947c72-8a46-4c30-b172-51daa5e143b1"
                            },
                            {
                                descriptor: {
                                    computedStyle: { hideContentOnMobile: false },
                                    style: {
                                        "padding-bottom": "0px",
                                        "padding-left": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "15px"
                                    },
                                    text: {
                                        computedStyle: { linkColor: "#7c4b96" },
                                        html:
                                            '<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 14px;" data-mce-style="font-size: 12px; line-height: 14px;"><p style="font-size: 14px; line-height: 16px; text-align: center; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px; text-align: center;"><span style="font-size: 20px; line-height: 24px;" data-mce-style="font-size: 20px; line-height: 24px;"><strong><span style="line-height: 24px; font-size: 20px;" data-mce-style="line-height: 24px; font-size: 20px;">I\'m a very important title</span></strong></span></p></div>',
                                        style: {
                                            color: "#2C2C2C",
                                            "font-family": "inherit",
                                            "line-height": "120%"
                                        }
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-text",
                                uuid: "5feee558-f661-40bf-b07d-298ee0921bcd"
                            },
                            {
                                descriptor: {
                                    computedStyle: { hideContentOnMobile: false },
                                    style: {
                                        "padding-bottom": "5px",
                                        "padding-left": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "5px"
                                    },
                                    text: {
                                        computedStyle: { linkColor: "#7c4b96" },
                                        html:
                                            '<div class="txtTinyMce-wrapper" data-mce-style="font-size: 12px; line-height: 14px;" style="font-size: 12px; line-height: 14px;"><p style="font-size: 14px; line-height: 16px; text-align: center; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px; text-align: center;">I\'m a block of text and I like latin.</p></div>',
                                        style: {
                                            color: "#aaaaaa",
                                            "font-family": "inherit",
                                            "line-height": "120%"
                                        }
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-text",
                                uuid: "73033cd0-9eed-4c71-8bdb-4841246c21ae"
                            },
                            {
                                descriptor: {
                                    computedStyle: { hideContentOnMobile: false },
                                    style: {
                                        "padding-bottom": "10px",
                                        "padding-left": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "10px"
                                    },
                                    text: {
                                        computedStyle: { linkColor: "#7c4b96" },
                                        html:
                                            '<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 14px;" data-mce-style="font-size: 12px; line-height: 14px;"><p style="font-size: 12px; line-height: 14px; text-align: center; word-break: break-word;" data-mce-style="font-size: 12px; line-height: 14px; text-align: center;"><a href="https://beefree.io" target="_blank" rel="noopener"><strong><span style="font-size: 14px; line-height: 16px;" data-mce-style="font-size: 14px; line-height: 16px;"><span style="text-decoration: underline; font-size: 14px; line-height: 16px;" data-mce-style="text-decoration: underline; font-size: 14px; line-height: 16px;">More info</span></span></strong></a></p></div>',
                                        style: {
                                            color: "#888888",
                                            "font-family": "inherit",
                                            "line-height": "120%"
                                        }
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-text",
                                uuid: "5d297698-2df7-4954-b376-5a7d232895fc"
                            }
                        ],
                        style: {
                            "background-color": "transparent",
                            "border-bottom": "0px solid transparent",
                            "border-left": "0px solid transparent",
                            "border-right": "0px solid transparent",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "30px",
                            "padding-left": "10px",
                            "padding-right": "0px",
                            "padding-top": "20px"
                        },
                        uuid: "cdf5f907-133d-4873-8e19-9f9f137eecda"
                    }
                ],
                container: {
                    style: {
                        "background-color": "transparent",
                        "background-image": "none",
                        "background-position": "top left",
                        "background-repeat": "no-repeat"
                    }
                },
                content: {
                    computedStyle: { rowColStackOnMobile: true },
                    style: {
                        "background-color": "transparent",
                        "background-image": "none",
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        color: "#333",
                        width: "500px"
                    }
                },
                locked: false,
                type: "two-columns-empty",
                uuid: "8af76e96-d6d8-4de9-afa8-cc375e91174e"
            },
            {
                columns: [
                    {
                        "grid-columns": 12,
                        modules: [
                            {
                                descriptor: {
                                    computedStyle: { align: "center" },
                                    divider: {
                                        style: { "border-top": "1px solid #dddddd", width: "100%" }
                                    },
                                    style: {
                                        "padding-bottom": "20px",
                                        "padding-left": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px"
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-divider",
                                uuid: "5a1dcc0e-10ee-471f-b24c-5eeb4aba3f0c"
                            }
                        ],
                        style: {
                            "background-color": "transparent",
                            "border-bottom": "0px solid transparent",
                            "border-left": "0px solid transparent",
                            "border-right": "0px solid transparent",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "5px",
                            "padding-left": "0px",
                            "padding-right": "0px",
                            "padding-top": "5px"
                        },
                        uuid: "52445e37-1413-40cb-9873-12cc951805d0"
                    }
                ],
                container: {
                    style: {
                        "background-color": "transparent",
                        "background-image": "none",
                        "background-position": "top left",
                        "background-repeat": "no-repeat"
                    }
                },
                content: {
                    computedStyle: { rowColStackOnMobile: true },
                    style: {
                        "background-color": "transparent",
                        "background-image": "none",
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        color: "#000000",
                        width: "500px"
                    }
                },
                locked: false,
                type: "one-column-empty",
                uuid: "c6cb67b9-0a04-4478-9d6c-2750347185e3"
            },
            {
                columns: [
                    {
                        "grid-columns": 6,
                        modules: [
                            {
                                descriptor: {
                                    computedStyle: {
                                        class: "center  autowidth ",
                                        width: "180px"
                                    },
                                    image: { alt: "Image", href: "https://beefree.io", src: "" },
                                    style: {
                                        "padding-bottom": "0px",
                                        "padding-left": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        width: "100%"
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-image",
                                uuid: "06055e38-83b5-4e5e-b160-7f38275e3b07"
                            },
                            {
                                descriptor: {
                                    computedStyle: { hideContentOnMobile: false },
                                    style: {
                                        "padding-bottom": "0px",
                                        "padding-left": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "15px"
                                    },
                                    text: {
                                        computedStyle: { linkColor: "#7c4b96" },
                                        html:
                                            '<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 14px;" data-mce-style="font-size: 12px; line-height: 14px;"><p style="font-size: 14px; line-height: 16px; text-align: center; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px; text-align: center;"><span style="font-size: 20px; line-height: 24px;" data-mce-style="font-size: 20px; line-height: 24px;"><strong><span style="line-height: 24px; font-size: 20px;" data-mce-style="line-height: 24px; font-size: 20px;">I\'m a very important title</span></strong></span></p></div>',
                                        style: {
                                            color: "#2C2C2C",
                                            "font-family": "inherit",
                                            "line-height": "120%"
                                        }
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-text",
                                uuid: "4e567557-688a-477e-950d-0219a382016e"
                            },
                            {
                                descriptor: {
                                    computedStyle: { hideContentOnMobile: false },
                                    style: {
                                        "padding-bottom": "5px",
                                        "padding-left": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "5px"
                                    },
                                    text: {
                                        computedStyle: { linkColor: "#7c4b96" },
                                        html:
                                            '<div class="txtTinyMce-wrapper" data-mce-style="font-size: 12px; line-height: 14px;" style="font-size: 12px; line-height: 14px;"><p style="font-size: 14px; line-height: 16px; text-align: center; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px; text-align: center;">I\'m a block of text and I like latin.</p></div>',
                                        style: {
                                            color: "#aaaaaa",
                                            "font-family": "inherit",
                                            "line-height": "120%"
                                        }
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-text",
                                uuid: "b9f24602-6fef-44b1-a13b-23b1737af549"
                            },
                            {
                                descriptor: {
                                    computedStyle: { hideContentOnMobile: false },
                                    style: {
                                        "padding-bottom": "10px",
                                        "padding-left": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "10px"
                                    },
                                    text: {
                                        computedStyle: { linkColor: "#7c4b96" },
                                        html:
                                            '<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 14px;" data-mce-style="font-size: 12px; line-height: 14px;"><p style="font-size: 12px; line-height: 14px; text-align: center; word-break: break-word;" data-mce-style="font-size: 12px; line-height: 14px; text-align: center;"><a href="https://beefree.io" target="_blank" rel="noopener"><strong><span style="font-size: 14px; line-height: 16px;" data-mce-style="font-size: 14px; line-height: 16px;"><span style="text-decoration: underline; font-size: 14px; line-height: 16px;" data-mce-style="text-decoration: underline; font-size: 14px; line-height: 16px;">More info</span></span></strong></a></p></div>',
                                        style: {
                                            color: "#888888",
                                            "font-family": "inherit",
                                            "line-height": "120%"
                                        }
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-text",
                                uuid: "426567ed-7d4a-476b-8c54-7d938840602b"
                            }
                        ],
                        style: {
                            "background-color": "transparent",
                            "border-bottom": "0px solid transparent",
                            "border-left": "0px solid transparent",
                            "border-right": "0px solid transparent",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "20px",
                            "padding-left": "0px",
                            "padding-right": "0px",
                            "padding-top": "20px"
                        },
                        uuid: "b5b7e1e6-7f84-409a-ad6a-a0d7516cdd67"
                    },
                    {
                        "grid-columns": 6,
                        modules: [
                            {
                                descriptor: {
                                    computedStyle: {
                                        class: "center  autowidth ",
                                        width: "180px"
                                    },
                                    image: { alt: "Image", href: "https://beefree.io", src: "" },
                                    style: {
                                        "padding-bottom": "0px",
                                        "padding-left": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        width: "100%"
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-image",
                                uuid: "41d1f603-a608-481d-81d6-5a74ad2c049c"
                            },
                            {
                                descriptor: {
                                    computedStyle: { hideContentOnMobile: false },
                                    style: {
                                        "padding-bottom": "0px",
                                        "padding-left": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "15px"
                                    },
                                    text: {
                                        computedStyle: { linkColor: "#7c4b96" },
                                        html:
                                            '<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 14px;" data-mce-style="font-size: 12px; line-height: 14px;"><p style="font-size: 14px; line-height: 16px; text-align: center; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px; text-align: center;"><span style="font-size: 20px; line-height: 24px;" data-mce-style="font-size: 20px; line-height: 24px;"><strong><span style="line-height: 24px; font-size: 20px;" data-mce-style="line-height: 24px; font-size: 20px;">I\'m a very important title</span></strong></span></p></div>',
                                        style: {
                                            color: "#2C2C2C",
                                            "font-family": "inherit",
                                            "line-height": "120%"
                                        }
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-text",
                                uuid: "3ab88883-71d8-40d1-8bcb-e3cc5a60caf8"
                            },
                            {
                                descriptor: {
                                    computedStyle: { hideContentOnMobile: false },
                                    style: {
                                        "padding-bottom": "5px",
                                        "padding-left": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "5px"
                                    },
                                    text: {
                                        computedStyle: { linkColor: "#7c4b96" },
                                        html:
                                            '<div class="txtTinyMce-wrapper" data-mce-style="font-size: 12px; line-height: 14px;" style="font-size: 12px; line-height: 14px;"><p style="font-size: 14px; line-height: 16px; text-align: center; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px; text-align: center;">I\'m a block of text and I like latin.</p></div>',
                                        style: {
                                            color: "#aaaaaa",
                                            "font-family": "inherit",
                                            "line-height": "120%"
                                        }
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-text",
                                uuid: "67b19b8f-56ad-459f-b4bc-61d8b07e2f8b"
                            },
                            {
                                descriptor: {
                                    computedStyle: { hideContentOnMobile: false },
                                    style: {
                                        "padding-bottom": "10px",
                                        "padding-left": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "10px"
                                    },
                                    text: {
                                        computedStyle: { linkColor: "#7c4b96" },
                                        html:
                                            '<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 14px;" data-mce-style="font-size: 12px; line-height: 14px;"><p style="font-size: 12px; line-height: 14px; text-align: center; word-break: break-word;" data-mce-style="font-size: 12px; line-height: 14px; text-align: center;"><a href="https://beefree.io" target="_blank" rel="noopener"><strong><span style="font-size: 14px; line-height: 16px;" data-mce-style="font-size: 14px; line-height: 16px;"><span style="text-decoration: underline; font-size: 14px; line-height: 16px;" data-mce-style="text-decoration: underline; font-size: 14px; line-height: 16px;">More info</span></span></strong></a></p></div>',
                                        style: {
                                            color: "#888888",
                                            "font-family": "inherit",
                                            "line-height": "120%"
                                        }
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-text",
                                uuid: "5368e709-5d78-4f7a-a632-817b0d23a9e5"
                            }
                        ],
                        style: {
                            "background-color": "transparent",
                            "border-bottom": "0px solid transparent",
                            "border-left": "0px solid transparent",
                            "border-right": "0px solid transparent",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "30px",
                            "padding-left": "10px",
                            "padding-right": "0px",
                            "padding-top": "20px"
                        },
                        uuid: "85a93014-07f0-43c5-ab5d-a623f6831571"
                    }
                ],
                container: {
                    style: {
                        "background-color": "transparent",
                        "background-image": "none",
                        "background-position": "top left",
                        "background-repeat": "no-repeat"
                    }
                },
                content: {
                    computedStyle: { rowColStackOnMobile: true },
                    style: {
                        "background-color": "transparent",
                        "background-image": "none",
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        color: "#333",
                        width: "500px"
                    }
                },
                locked: false,
                type: "two-columns-empty",
                uuid: "86026e7f-453e-4813-90d4-b89ee24bfae8"
            },
            {
                columns: [
                    {
                        "grid-columns": 12,
                        modules: [
                            {
                                descriptor: {
                                    computedStyle: { align: "center" },
                                    divider: {
                                        style: {
                                            "border-top": "10px solid transparent",
                                            width: "100%"
                                        }
                                    },
                                    style: {
                                        "padding-bottom": "10px",
                                        "padding-left": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px"
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-divider",
                                uuid: "0e4442e5-28c8-4724-b6eb-9150182fb621"
                            }
                        ],
                        style: {
                            "background-color": "transparent",
                            "border-bottom": "0px solid transparent",
                            "border-left": "0px solid transparent",
                            "border-right": "0px solid transparent",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "5px",
                            "padding-left": "0px",
                            "padding-right": "0px",
                            "padding-top": "5px"
                        },
                        uuid: "79307bc6-6162-4890-a0e2-6070b57ebdbe"
                    }
                ],
                container: {
                    style: {
                        "background-color": "transparent",
                        "background-image": "none",
                        "background-position": "top left",
                        "background-repeat": "no-repeat"
                    }
                },
                content: {
                    computedStyle: { rowColStackOnMobile: true },
                    style: {
                        "background-color": "transparent",
                        "background-image": "none",
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        color: "#333",
                        width: "500px"
                    }
                },
                locked: false,
                type: "one-column-empty",
                uuid: "9623d815-dc8a-4138-b06a-8475516b8f6f"
            },
            {
                columns: [
                    {
                        "grid-columns": 12,
                        modules: [
                            {
                                descriptor: {
                                    computedStyle: {
                                        height: 52,
                                        iconsDefaultWidth: 32,
                                        padding: "0 5px 0 0",
                                        width: 151
                                    },
                                    iconsList: {
                                        icons: [
                                            {
                                                image: {
                                                    alt: "Facebook",
                                                    href: "https://www.facebook.com/",
                                                    prefix: "https://www.facebook.com/",
                                                    src:
                                                        "repository_path/img/t-only-logo-white/facebook.png",
                                                    title: "Facebook"
                                                },
                                                name: "facebook",
                                                text: "",
                                                type: "follow"
                                            },
                                            {
                                                image: {
                                                    alt: "Twitter",
                                                    href: "http://twitter.com/",
                                                    prefix: "http://twitter.com/",
                                                    src:
                                                        "repository_path/img/t-only-logo-white/twitter.png",
                                                    title: "Twitter"
                                                },
                                                name: "twitter",
                                                text: "",
                                                type: "follow"
                                            },
                                            {
                                                image: {
                                                    alt: "Instagram",
                                                    href: "https://instagram.com/",
                                                    prefix: "https://instagram.com/",
                                                    src:
                                                        "repository_path/img/t-only-logo-white/instagram@2x.png",
                                                    title: "Instagram"
                                                },
                                                name: "instagram",
                                                text: "",
                                                type: "follow"
                                            }
                                        ]
                                    },
                                    style: {
                                        "padding-bottom": "10px",
                                        "padding-left": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "text-align": "center"
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-social",
                                uuid: "c3e46ec7-1d2a-4999-b505-182bf22b0ad2"
                            },
                            {
                                descriptor: {
                                    computedStyle: { hideContentOnMobile: false },
                                    style: {
                                        "padding-bottom": "10px",
                                        "padding-left": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px"
                                    },
                                    text: {
                                        computedStyle: { linkColor: "#7c4b96" },
                                        html:
                                            '<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 14px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;" data-mce-style="font-size: 12px; line-height: 14px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;"><p style="font-size: 18px; line-height: 21px; text-align: center; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; word-break: break-word;" data-mce-style="font-size: 18px; line-height: 21px; text-align: center;"><span style="font-size: 13px; line-height: 15px;" data-mce-style="font-size: 13px; line-height: 15px;">This is a sample template from BEE free email editor</span><br><span style="font-size: 13px; line-height: 15px;" data-mce-style="font-size: 13px; line-height: 15px;">Visit <span style="text-decoration: underline; font-size: 13px; line-height: 15px;" data-mce-style="text-decoration: underline; font-size: 13px; line-height: 15px;"><span style="color: rgb(255, 255, 255); line-height: 15px; font-size: 13px; text-decoration: underline;" data-mce-style="color: rgb(255, 255, 255); line-height: 15px; font-size: 13px; text-decoration: underline;"><span style="line-height: 15px; font-size: 13px;" data-mce-style="line-height: 15px; font-size: 13px;"><a style="color: #ffffff; text-decoration: underline;" href="https://beefree.io" target="_blank" rel="noopener">beefree.io</a></span></span></span><span style="font-size: 13px; line-height: 15px;" data-mce-style="font-size: 13px; line-height: 15px;"><span style="color: rgb(255, 255, 255); line-height: 15px; font-size: 13px;" data-mce-style="color: rgb(255, 255, 255); line-height: 15px; font-size: 13px;">&nbsp;</span></span>to create beautiful and rich email messages at no cost.</span></p></div>',
                                        style: {
                                            color: "#bbbbbb",
                                            "font-family":
                                                "Arial, 'Helvetica Neue', Helvetica, sans-serif",
                                            "line-height": "120%"
                                        }
                                    }
                                },
                                locked: false,
                                type: "mailup-bee-newsletter-modules-text",
                                uuid: "0cecc52e-52e7-40d3-a23b-8f6554d27748"
                            }
                        ],
                        style: {
                            "background-color": "transparent",
                            "border-bottom": "0px solid transparent",
                            "border-left": "0px solid transparent",
                            "border-right": "0px solid transparent",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "25px",
                            "padding-left": "0px",
                            "padding-right": "0px",
                            "padding-top": "25px"
                        },
                        uuid: "fdbd8c1c-2644-4809-9d4f-c374695e0358"
                    }
                ],
                container: {
                    style: {
                        "background-color": "#2C2C2C",
                        "background-image": "none",
                        "background-position": "top left",
                        "background-repeat": "no-repeat"
                    }
                },
                content: {
                    computedStyle: { rowColStackOnMobile: true },
                    style: {
                        "background-color": "transparent",
                        "background-image": "none",
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        color: "#333",
                        width: "500px"
                    }
                },
                locked: false,
                type: "one-column-empty",
                uuid: "ac97c212-b4b4-4ef8-ba9e-35b475734aef"
            }
        ],
        template: { name: "template-base", type: "basic", version: "2.0.0" },
        title: ""
    },
    comments: {}
};

const PreviewTemplate = ({ match }) => {

    const [emailsubject, setEmailsubject] = useState("");
    const [emailBody, setEmailBody] = useState("");
    const [content, setContent] = useState("");
    const [editorType, setEditorType] = useState("");
    const [isMounted, setIsMounted] = useState(false);
    const [token, setToken] = useState(null);
    const [template, setTemplate] = useState(templateDatTest);

    useEffect(() => {

        const initEditor = async () => {
            const beeTest = new Bee();
            const { access_token } = await beeTest.getToken(
                "84613a3a-908f-4d45-b9e3-43f2455173e3",
                "ZZ8wqss60hqezYuNXiuzveXDNdnDBKJWFbEvWWjEOMnECCVBwmih"
            );
            console.log("token", access_token);
            setToken(access_token);

            const beeConfig = {
                uid: "84613a3a-908f-4d45-b9e3-43f2455173e3", //needed for identify resources of the that user and billing stuff
                container: "bee-container", //Identifies the id of div element that contains BEE Plugin
                customCss:
                    "https://drive.google.com/uc?export=view&id=11327esAFZO-GYuxgAN-e1_XguxwUDQ3U",
                language: "en-US",
                onSave: (jsonFile, htmlFile) => {
                    //console.log("onSave", { jsonFile, htmlFile });e
                    console.log("onSave", jsonFile);
                },
                onSaveAsTemplate: (jsonFile) => {
                    console.log("onSaveAsTemplate", jsonFile);
                },
                onSend: (htmlFile) => {
                    console.log("onSend", htmlFile);
                },
                onError: (errorMessage) => {
                    console.log("onError ", errorMessage);
                }
            };

            // const beeTest = new Bee(token);
            beeTest.start(beeConfig, template);
        };

        initEditor();

        setIsMounted(true);
    }, []);

    useEffect(() => {
        let params = {};
        params.template_id = match.params.id;
        params.call_from = 'preview_template';
        var promise = Promise.resolve(HTTPClient.get(endPoints.MARKETING_TEMPLATE.TEMPLATE_GET, params));
        promise.then((response) => {
            setIsMounted(true);
            setEmailsubject(response.template_details.subject);
            setEmailBody(response.template_details.email_message);
            setContent(response.template_details.email_message);
            setEditorType(response.template_details.editorType);
            // registerBeeEditor();

        });
    }, [])

    const registerBeeEditor = () => {

        try {
            if (editorType === constants.EDITOR_BEE) {
                var beeConfig = {
                    uid: '111',
                    container: 'bee-container',
                    language: 'en-US',
                    onLoad: (jsonFile) => {
                        bee.preview(jsonFile);
                    },
                }
                var endpoint = constants.BEE_EDITOR_AUTH_API;
                var bee;
                var payload = {
                    client_id: constants.BEE_EDITOR_CLIENT_ID,
                    client_secret: constants.BEE_EDITOR_CLIENT_SECRET_ID,
                };
                $.post(endpoint, payload)
                    .done(function (data) {
                        var token = data;
                        const BeePlugin = new Bee();
                        let templateObj = getStringParam(content) !== "" ? JSON.parse(content) : getDefaultBeeEditorData();
                        BeePlugin.start(beeConfig, templateObj);
                        /*BeePlugin.create(token, beeConfig, function (instance) {
                            bee = instance;
                            let templateObj = getStringParam(_this.state.content) !== "" ? JSON.parse(_this.state.content) : getDefaultBeeEditorData();
                            bee.start(templateObj);
                            _this.bee = bee;
                        });*/
                    }).fail(function () {
                        alert('Network connection error... Check your network settings and try again later');
                    });
            }
        } catch (error) {
            console.error("Error in 'PreviewTemplate.js -> registerBeeEditor()':" + error);
        }
    }

    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight - 10) / 2
    if (isMounted) {
        return (
            <div>
                {editorType === constants.EDITOR_BEE ?
                    // <div id='bee-container'></div> //Add id

                    <div>
                        {!token && <div>loading...</div>}
                        {token && <div className="App">
                            <h1>Bee editor:</h1>
                            <div id="bee-container"></div>
                        </div>}
                    </div>
                    :
                    <div id="preview" style={{ backgroundColor: '#eeeeee', padding: '15px' }}>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0" align="center">
                            <tr>
                                <td>
                                    <div className="preview_title_box">{emailsubject}</div>
                                    <div className="preview_content_box">
                                        <div id="main">
                                            <div id="msgBody" className="divBody">
                                                {/* <p className="text-font">Dear Pravat,<br />  Type here</p> */}
                                                <p dangerouslySetInnerHTML=
                                                    {{ __html: (emailBody) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                }
            </div>
        );
    } else {
        return (
            <div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        )
    }

}
export default PreviewTemplate;