import * as React from 'react';
import { constants, MODULES } from '../../../services/constants/constants';
import { isValidParam, getArrayParam, getBooleanParam, getStringParam, getIntParam, getObjectParam, makeValidObjectName } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getHome, updateHomeItems, setSelectedModuleItems, getObjectList , updateExternalMenuLink} from '../../../services/actions/appActions';
import * as sfDialogs from '../components/sfDialogs';
import ShowCircularProgress from "../components/circularProgress";
import { sortHomeItems } from '../../../services/helper/common';
import { Icon } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import _ from 'lodash';
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODULE_LIST } from './MenuItemType';
import { Menu } from '@mui/material';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { MenuItem } from '@mui/material';
import { useDrag, useDrop } from "react-dnd";

const inputStyle = { border: "1px solid rgb(153, 153, 153)", backgroundColor: "rgb(255 255 255)", borderRadius: "3px", width: "100%", padding: "3px" }

function getHomeId(name) {
    let obj = MODULES.find(f => f.name == name);
    let id = obj?.id || 0;
    return id;
}

function getParamArr(arr, key) {
    let tempArr = [];
    arr.forEach(e => {
        tempArr.push(e[key])
    });
    return tempArr;
}

const MenuConfiguration = () => {
    const [modulsData, setModulsData] = useState([]);
    const [modules, setModules] = useState([]);
    const [allModuleItems, setAllModuleItems] = useState([]);
    const [moduleItemIds, setModuleItemIds] = useState([]);
    const [availableModuleItems, setAvailableModuleItems] = useState([]);
    const [hoverIndx, setHoverIndx] = useState({ '': -1 });
    const app = useSelector((state) => state.app);
    const dispatch = useDispatch();
    useEffect(() => {
        generateData();

    }, [])
 
   

    useEffect(() => {
        if(app.externalMenuLink != null){
           let {status,menuItem} = app.externalMenuLink;
           let isHubPresent = modulsData.find(o => o.module === 'HUB');
                let colIndex ;
                if(isHubPresent){
                  let   index = modulsData.findIndex(x => x.module === menuItem.home_name );
                  colIndex = status== "updated"?index: 0;
                   }else{
                    colIndex = status== "updated"?menuItem.home_id: 0;
                    if(menuItem.home_name == "Operations" && menuItem.type== "externallink" && menuItem.home_id == 5){
                        let   index = modulsData.findIndex(x => x.module === menuItem.home_name );
                        colIndex = status== "updated"?index: 0;
                    }
                   }
                
                let _modulsData = [...modulsData];
                _modulsData = _modulsData.filter((f) => f?.is_show);

                    let _srcColData = { ..._modulsData[colIndex] };
                    let _moduleItems = _srcColData.module_items;
                    if(status=='added'){
                        _moduleItems?.push({...menuItem});
                    }else{
                        _moduleItems?.map((f)=>{
                            if(f.id == menuItem.id){
                                f.label = menuItem.label;
                                f.url = menuItem.url;
                            }
                    })
                    }
                    
                    _srcColData.module_items = _moduleItems; 
                    _modulsData[colIndex] = _srcColData;
                    updateModulsData([..._modulsData]);
                    dispatch(updateExternalMenuLink(null));
                
    }}, [modulsData,app,app.externalMenuLink])
    
    const generateData = () => {
        let availableModuleList = [];
        let _moduleItemIds = [];
        let _allModuleItems = allModuleItems;
        let promise = Promise.resolve(HTTPClient.get(endPoints.HOME.GET_AVAILABEL_MODULE_ITEMS, {}));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response) && response.status != -1) {
                    console.log(response)
                    let data = getArrayParam(response.modules).map(module => {
                        let obj = getArrayParam(response.available_modules).find(f => f.homeId == module.homeId);
                        if (isValidParam(obj)) {
                            module.module_items.push(...obj.module_items);
                            obj.module_items.forEach(m => {
                                if (!getArrayParam(module.objectList).includes(m.temp_object)) {
                                    module.objectList.push(m.temp_object);
                                }
                            });
                        }
                        return module;
                    });
                    let modulesArr = sortHomeItems({ modules: data, multiObjectList: response.multiObjectList });
                    getArrayParam(response.available_modules).forEach(e => {
                        let tempModule = getArrayParam(modulesArr.modules).find(f => f.homeId == e.homeId);
                        if (isValidParam(tempModule)) {
                            getArrayParam(e.module_items).forEach(item => {
                                let list = tempModule.module_items.filter(f => f.id == item?.id);
                                if (list.length > 0) {
                                    availableModuleList.push(...list);
                                    list.forEach(e => {
                                        let isPresent = _allModuleItems.some(s => s.id == e.id);
                                        if (isPresent) {
                                            _allModuleItems = _allModuleItems.map(m => {
                                                return m.id == e.id ? { ...m, is_update: true } : m;
                                            });
                                        } else {
                                            _allModuleItems.push({ ...e, is_update: true });
                                        }
                                    });
                                    let ids = list.map(m => m.id).filter((f, index, arr) => arr.indexOf(f) == index);
                                    moduleItemIds.push(...ids);
                                }
                            });
                        }
                    });
                    getArrayParam(response.available_modules).filter(f => f.homeId == 0 || f.homeId == 7).forEach(e => {
                        let list = getArrayParam(e.module_items);
                        if (list.length > 0) {
                            availableModuleList.push(...list);
                            list.forEach(e => {
                                let isPresent = _allModuleItems.some(s => s.id == e.id);
                                if (isPresent) {
                                    _allModuleItems = _allModuleItems.map(m => {
                                        return m.id == e.id ? { ...m, is_update: true } : m;
                                    });
                                } else {
                                    _allModuleItems.push({ ...e, is_update: true });
                                }
                            });
                            let ids = list.map(m => m.id).filter((f, index, arr) => arr.indexOf(f) == index);
                            _moduleItemIds.push(...ids);
                        }
                    });

                    let _modules = getObjectParam(modules).map(m => { return m.module == MODULE_LIST.AVAILABLE_MENU_ITEMS ? { ...m, _moduleItemIds } : m; });
                  
                    setAllModuleItems(_allModuleItems);
                    setModuleItemIds(_moduleItemIds);
                    setModules(_modules);
                    setAvailableModuleItems(availableModuleList);
                    processModeleData(availableModuleList);
                }
            })
        }
    }

    const processModeleData = (availableModuleList) => {
        let _modules = _.cloneDeep(getArrayParam(app.home.modules));
        _modules = _modules.filter(f => f.module != 'Sales & Marketing');
        let availabelModulesObj = {
            "module_label": "Available Menu Items",
            "module": constants.MODULE_AVAILABLE_MENU_ITEMS,
            "id": -111,
            "is_show": true,
            "objectList": [],
            "module_items": []
        }

        let _availableModuleList = availableModuleList;
        let isfranchisor = app.me.is_franchisor;
        let isfranchisee = app.me.is_franchisee; 
        let isIndustryFlag = app.me.industry_flag;
        let isLMSAccount = app.me.lms_account;

        if (isfranchisor && isIndustryFlag === false) {
            _availableModuleList = _availableModuleList.filter(f => (f.object !== constants.COMPLIANCE_OBJECT));
        }
        if(!isfranchisee){
            _availableModuleList = _availableModuleList.filter(f => (f.object !== constants.ROYALTY_REPORT_OBJECT_V2));
        }
        if(!isLMSAccount) {
            _availableModuleList = _availableModuleList.filter(f => (f.object !== constants.LMS_TRAINING_MENU_ITEM));
        }
        if (isfranchisee) {
            _availableModuleList = _availableModuleList.filter(f => (f.object !== constants.COMPLIANCE_REPORT_OBJECT));
        }

        availabelModulesObj.module_items = _availableModuleList;
        _modules.unshift(availabelModulesObj);
        setModulsData([..._modules]);
    }


    const updateModulsData = (data) => {
        setModulsData([...data]);
        data = data.filter((f) => f?.is_show);
        let _modules = [...data];
        _modules.splice(0, 1)
        let _home = { ..._.cloneDeep(app.home) };
        _home.modules = [..._modules];
        dispatch(updateHomeItems(_home));
        let moduleName = app.selectedModuleName;
        let _selectedModuleItems = { ..._modules.find(m => m.module == app.selectedModuleName) }
        if (!_selectedModuleItems.is_show) {
            _selectedModuleItems = { ..._modules.find(m => m.is_show) }
            moduleName = _selectedModuleItems.module;
        }
        let moduleItems = _selectedModuleItems.module_items;
        let lastSelectedModuleName = app.lastSelectedModuleName||app.selectedModuleName;
        dispatch(setSelectedModuleItems({ moduleName, moduleItems, lastSelectedModuleName }));

    }


    const isValidate = (val) => {
        let _isValidate = true;
        val = getStringParam(val).trim();
        if (val == '') {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.THIS_FIELD_CANNOT_BLANK, null);
            return false;
        } else if (val.length > 32) {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.MENU.MODULE_LABEl_CHAR_LIMIT_MSG, null);
            return false;
        } else {
            let modules = getArrayParam(app.home.modules);
            let moduleObj = modules.filter(f => f.module != module.module && getStringParam(f.module_label).toLowerCase() == val.toLowerCase());
            // if (moduleObj.length > 0) {
            //     sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, val + ' ' + getLocalizedStrings().message.MENU.DUPLICATE_NAME, null);
            //     return false;
            // }
        }

        return _isValidate;
    }

    const returnItemsForColumn = (columnData, colIndex) => {
        if (colIndex == 0 && columnData.module_items.length == 0) {
            return (
                <div style={{ height: '100%', textAlign: 'center', fontSize: '14px' }}>Empty </div>

            );
        } else {
            return columnData.module_items.map((item, index) => (
                <MovableItem
                    key={item?.id}
                    modulsData={modulsData}
                    moduleItem={item}
                    currentColumnName={columnData.module}
                    updateModulsData={updateModulsData}
                    index={index}
                    colIndex={colIndex}
                    borderTop={hoverIndx[columnData.module] == index ? 1 : 0}
                    isValidate={isValidate}
                    setHoverIndx={(data) => setHoverIndx(data)}
                />
            ));
        }

    };

    let height = window.innerHeight;
    let menuCardHeight = height - 240;
    let top = (menuCardHeight - 10) / 2;
    let persentile = 100 / modulsData.length
    let width = `calc(${persentile}% - 5px)`;
    return (<>
        {modulsData.length > 0 ? <div id="module-config-id" style={{ width: '100%', padding: '10 0 0 25', border: '0px solid red', display: 'inline-flex', overflow: 'auto', position: 'relative' }}>
            {modulsData.map((item, index) => (
                <Column isValidate={isValidate} width={width} colIndex={index} modulsData={modulsData} updateModulsData={updateModulsData} columnData={item}>
                    {returnItemsForColumn(item, index)}
                </Column>
            ))}
        </div> :
            <div style={{ width: '100%', height: menuCardHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>}
    </>);
};

const Column = ({ children, modulsData, colIndex, updateModulsData, columnData, width, isValidate }) => {

    const [isEdit, setIsEdit] = useState(false);
    const [openActionList, setOpenActionList] = useState(false);
    const [moduleLable, setModuleLable] = useState(columnData.module_label);
    const app = useSelector((state) => state.app);
    const anchorRef = useRef(null)
    const dispatch = useDispatch();
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: 'DESKTOP-MENU-CONFIGURATION',
        drop: () => ({ name: columnData.module }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        }),
        canDrop: (item) => {
            return (
                item?.currentColumnName == item?.currentColumnName
            );
        }
    });
    const handleFocusModule = (e) => {
    }
    const openExternalLinkDialog = () => {
        let externalLinkLabel = modulsData[0].module_items;
        let data = {
            externalLinkLabel: externalLinkLabel
        }
        dispatch(getAppDialog(true, constants.EXTERNAL_LISTS_DIALOG, "", externalLinkLabel, data, constants.OTHER));
        setOpenActionList(false);
    }
    const onChangeModule = (value) => {
        setModuleLable(value)
    }
    const handleKeyUpModule = (event) => {
        if (event.keyCode == 13) {
            saveModule();
        }
    }

    const saveModule = () => {
        let params = {};
        let _isValidate = isValidate(moduleLable);
        let _modulsData = [...modulsData]
        let _ColData = { ..._modulsData[colIndex] };
        let preModuleName =_ColData.module_label;
        if (_isValidate) {
            _ColData.module_label = moduleLable;;
            _modulsData[colIndex] = _ColData;
            updateModulsData([..._modulsData]);
            setIsEdit(false);
            params.id = getIntParam(_ColData.id);
            params.name = getStringParam(_ColData.module_label).trim();
            params.call_from = 'changeModuleLable';
            if(preModuleName !== moduleLable){
                updateModuldata(params)
            }
        } else {
            setIsEdit(false);
            setModuleLable(preModuleName)
        }
    }
    const updateModuldata = (params) => {
        let promise = Promise.resolve(HTTPClient.get(endPoints.HOME.UPDATE_MODULE, params));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response) && response.status != -1) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                }
            });
        }
    }
    const getBackgroundColor = () => {
        if (isOver) {
            if (canDrop) {
                return "#d7d5d517";
            } else if (!canDrop) {
                return "#e9888817";
            }
        } else {
            return "";
        }
    };
    const handleModuleHideAndShow = (value) => {
        let params = {};
        let _modulsData = [...modulsData];
        let module = getObjectParam({ ..._modulsData[colIndex] });
        let activeModules = getArrayParam(app.home.modules);
        activeModules = activeModules.filter(f => f.is_show == 1);
        if (activeModules.length == 1 && value == 0) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MENU.ATLEAST_ONE_MODULE_ACTIVE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }
        module.is_show = value;;
        _modulsData[colIndex] = module;
        updateModulsData([..._modulsData]);
        params.id = getIntParam(module.id);
        params.is_show = value;
        params.call_from = 'showHideModule';
        updateModulevisibility(params);


    }
    const updateModulevisibility = (params) => {
        let promise = Promise.resolve(HTTPClient.get(endPoints.HOME.UPDATE_MODULE, params));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response) && response.status != -1) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                }
            });
        }
    }

    let height = window.innerHeight;
    let menuCardHeight = height - 232;

    return (
        <div className="desk sf-desk" style={{ opacity: 1, width, height: menuCardHeight }}>
            <div className="desk-head" >
                <div className="desk-name" style={{ display: 'flex' }}>
                    {getBooleanParam(isEdit) ?
                        <input
                            autoFocus={true}
                            type="text"
                            id={`module-name-${columnData.module}`}
                            key={`module-field-${columnData.module}-key`}
                            value={moduleLable}
                            style={inputStyle}
                            onChange={(e) => onChangeModule(e.target.value)}
                            onKeyUp={(e) => handleKeyUpModule(e)}
                            onBlur={() => saveModule()}
                            onFocus={(e) => handleFocusModule(e)}
                            autoComplete="new-password"
                        />
                        : <span onDoubleClick={() => setIsEdit(getIntParam(columnData.id) > 0 && !getBooleanParam(isEdit))} title={moduleLable} style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '14px' }}>{moduleLable}</span>}
                    {
                        getIntParam(columnData.id) < 0 &&
                        <span style={{ marginTop: '5px' }} ref={anchorRef}>
                            <i onClick={() => setOpenActionList(true)} className="fa fa-plus" aria-hidden="true" style={{ cursor: 'pointer' }} title="Add new menu item"></i>
                        </span>
                    }
                    <div>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorRef.current}
                            keepMounted
                            open={openActionList}
                            onClose={() => setOpenActionList(false)}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                            transformOrigin={{ vertical: "top", horizontal: "left" }}
                        >
                            <MenuItem value={'External Link'} style={styles.popoverMenuItem} onClick={() => openExternalLinkDialog()}>
                                <span onClick={openExternalLinkDialog}> External Link</span>
                            </MenuItem>
                        </Menu>

                    </div>
                    {getIntParam(columnData.id) > 0 && !getBooleanParam(isEdit) &&
                        <span style={{ display: 'flex', cursor: 'pointer', color: '#717171' }}>
                            <span>
                                {columnData.is_show ? <i key={`module-edit-${columnData.module}`} style={{ padding: '5px' }} className="far fa-eye-slash" title={getLocalizedStrings().label.COMMON.HIDE} onClick={() => handleModuleHideAndShow(0)}></i>
                                    : <i key={`module-edit-${columnData.module}`} style={{ padding: '5px' }} className="far fa-eye" title={getLocalizedStrings().label.COMMON.SHOW} onClick={() => handleModuleHideAndShow(1)}></i>}
                            </span>
                            <span title={getLocalizedStrings().label.COMMON.EDIT}><EditIcon
                                key={`module-edit-${columnData.module}`}
                                style={{ 'fontSize': '25px', padding: '5px' }}
                                onClick={() => setIsEdit(true)}
                            /></span>
                        </span>}
                </div>
            </div>
            <div ref={drop} style={{ backgroundColor: getBackgroundColor(), height: menuCardHeight - 44, overflow: 'auto' }} key={"desk-items-" + columnData.module} id={"desk-items-" + columnData.module} >
                {children}
            </div>

        </div>

    );
};

const MovableItem = ({
    modulsData,
    moduleItem,
    index,
    colIndex,
    currentColumnName,
    updateModulsData,
    borderTop,
    isValidate,
    setHoverIndx
}) => {
    const [isEdit, setIsEdit] = useState(false);
    const [itemLable, setItemLable] = useState(null);
    const [data, setData] = useState(null);
    const [item, setItem] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        setData([...modulsData]);
        setItem({...moduleItem});
        setItemLable(moduleItem.label)
    }, [modulsData,moduleItem])
 
    const onChangeModuleItem = (value) => {
        setItemLable(value);
    }
    const handleKeyUp = (event) => {
        if (event.keyCode == 13) {
            saveModuleItem();
        }
    }
    const saveModuleItem = () => {
        let params = {};
        let _isValidate = isValidate(itemLable);
        let _modulsData = [...data]
        let _srcColData = { ..._modulsData[colIndex] };
        let _moduleItems = [..._srcColData.module_items];
        let preItemName = _moduleItems[index].label;
        if (_isValidate) {
            
            _moduleItems[index].label = itemLable;
            _modulsData[colIndex] = _srcColData;
            updateModulsData([..._modulsData]);
            setIsEdit(false);
            params.id = getIntParam(item?.id);
            params.name = getStringParam(itemLable).trim();
            params.object = getStringParam(item?.object).trim();
            if(preItemName !==itemLable){
                updateModuleItem(params);
            }
        } else {
            setIsEdit(false);
            setItemLable(preItemName)
        }

    }
    const updateModuleItem = (params) => {
        let promise = Promise.resolve(HTTPClient.get(endPoints.HOME.UPDATE_MODULE_ITEM, params));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response) && response.status != -1) {
                    let message = params.is_remove ? getLocalizedStrings().message.COMMON.REMOVE : getLocalizedStrings().message.COMMON.UPDATE;
                    dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                }
            });
        }
    }
    const handleFocus = (e) => {
    }

    const handleRemove = (item) => {
        let _modulsData = [...data];
        let srcColData = { ..._modulsData[colIndex] };
        let srcModuleItems = [...srcColData.module_items];
        if (srcModuleItems.length == 1) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.CANNOT_DELETE_ALL_ITEM, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else {
            let msg = item?.label + getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_REMOVE_MSG;
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => handleConfirmRemove(item), null);
        }
    }
    const handleConfirmRemove = (item) => {

        let _modulsData = [...data];
        let srcColData = { ..._modulsData[colIndex] };
        let srcModuleItems = [...srcColData.module_items];
        srcModuleItems.splice(index, 1);
        _modulsData[colIndex] = srcColData;
        let desColData = { ..._modulsData[0] };
        let desModuleItems = [...desColData.module_items]
        let _item = { ...item };
        // _item.label = item?.name;
        desModuleItems.push(_item);
        srcColData.module_items = srcModuleItems;
        desColData.module_items = desModuleItems;
        _modulsData[0] = desColData;
        let params = {};
        params.id = item?.id;
        params.is_remove = true;
        params.name = getStringParam(item?.label);
        params.object = getStringParam(item?.object);
        updateModuleItem(params);
        updateModulsData([..._modulsData]);
    }


    const handleDragAndDrop = (dragData, dropData) => {
        let _modulsData = [...data];
        const dragColIndex = _modulsData?.findIndex(m => m.module === dragData.currentColumnName);
        const dropColIndex = _modulsData?.findIndex(m => m.module === dropData.name);
        const dragIndex = index;
        const dropIndex = dragData?.index;
        if (dragColIndex == 0 && dropColIndex == 0) {
            setHoverIndx({ '': -1 });
        } else {
            let daragItem = { ...dragData.item }
            daragItem.is_update = true;
            // if (dropColIndex == 0) {
            //     daragItem.label = daragItem.name;
            // }
            if (dragColIndex == dropColIndex) {
                let desColData = { ..._modulsData[dropColIndex] };
                let desModuleItems = [...desColData.module_items]
                desModuleItems.splice(dropIndex, 0, desModuleItems.splice(dragIndex, 1)[0]);
                desColData.module_items = desModuleItems;
                _modulsData[dropColIndex] = desColData;
            } else {
                let srcColData = { ..._modulsData[dragColIndex] };
                let srcModuleItems = [...srcColData.module_items];
                if (dragColIndex != 0 && srcModuleItems.length == 1) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.CANNOT_DELETE_ALL_ITEM, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                } else {
                    srcModuleItems.splice(dragIndex, 1);
                    _modulsData[dragColIndex] = srcColData;
                    let desColData = { ..._modulsData[dropColIndex] };
                    let desModuleItems = [...desColData.module_items]
                    desModuleItems.splice(dropIndex, 0, daragItem);
                    srcColData.module_items = srcModuleItems;
                    desColData.module_items = desModuleItems;
                    _modulsData[dropColIndex] = desColData;
                }
            }
            setHoverIndx({ '': -1 });
            updateModulsData([..._modulsData]);
            if (dropColIndex == 0) {
                let params = {};
                params.id = daragItem.id;
                params.is_remove = true;
                params.name = getStringParam(daragItem.label);
                updateModuleItem(params);
            } else {
                saveFullModulsData([..._modulsData]);
            }
        }
    };
    const saveFullModulsData = (value) => {
        let modules = getArrayParam(getApiParams(value));

        let promise = Promise.resolve(HTTPClient.post(endPoints.HOME.SAVE_MODULE_ITEM, { modules }));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response) && response.status != -1) {
                   dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                }
            });
        }

    }

    const getApiParams = (localParam) => {
        let prams = [];
        localParam.map((m) => {
            m.module_items.map((item) => {
                if (item?.type == "externallink") {
                    item.home_id = m.homeId;
                    item.home_name = m.module;
                }

            })
        })
        localParam.map((m)=>{
            if(m.module == "HUB"){
                m.module_items.map((item)=>{
                    if(item.is_update !== undefined &&item.is_update == true ){
                        item.is_show = 1;
                    }
                })
            }
        })
        localParam.forEach(e => {
            let pramObj = {};
            pramObj.id = e.id;
            pramObj.homeId = getHomeId(e.module);
            pramObj.module = e.module;
            pramObj.module_label = e.module_label;
            pramObj.module_items = e.module_items;
            pramObj.moduleItemIds = getParamArr(e.module_items, 'id');
            pramObj.sortedObjectList = getParamArr(e.module_items, 'temp_object');
            if(e.id != -111){
                 pramObj.is_show= e.is_show;
            }
            prams.push(pramObj);
        });
        return prams;
    }

    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: 'DESKTOP-MENU-CONFIGURATION',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item?.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            item.index = hoverIndex;
            setHoverIndx({ [currentColumnName]: hoverIndex });
        }
    });

    const openExternalDialog = () => {
        let externalLinkLabel = "";
        let data = {
            externalLinkLabel: externalLinkLabel,
            callFrom: "update",
            moduleItem: item
        }
        dispatch(getAppDialog(true, constants.EXTERNAL_LISTS_DIALOG, "", externalLinkLabel, data, constants.OTHER));
    }
    const [{ isDragging }, drag] = useDrag({
        type: 'DESKTOP-MENU-CONFIGURATION',
        item: { index, item, currentColumnName },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();

            if (dropResult) {
                handleDragAndDrop(item, dropResult);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    const opacity = isDragging ? 0.4 : 1;

    drag(drop(ref));
    let showActionButtons = false;
    if (!getBooleanParam(isEdit) && getIntParam(item?.id) > 0 && colIndex > 0) {
        showActionButtons = true;
    }
    let isShowEditIcon = false;
    let object = makeValidObjectName(item?.object);
    if (getIntParam(item?.object_id) > 0 || item?.type == 'externallink' || object == constants.LEAD_CAPTURE || object == constants.EMAIL_MARKETING || object == constants.SOCIAL_MARKETING || object == constants.ONLINE_REPUTATION || object == constants.USEFUL_LINK_OBJECT || object == constants.ASSETS_OBJECT) {
        isShowEditIcon = true;
        if (object == constants.UNITS_OBJECT) {
            isShowEditIcon = false;
        }
    }

    let isShowDeleteIcon = getArrayParam(item?.moduleItemIds).length == 1 ? false : true;
    if (isShowDeleteIcon) {
        let _length = data!= null ? data[colIndex]?.module_items?.length : 0;
        isShowDeleteIcon =  _length > 1;
    }
    let label = itemLable;
    if (label === "Calendar/Tasks") {
        label = item?.label + " - " + item?.home_name
    }

    return (
        <div ref={ref} id={`module-item-${item?.id}`} style={{ opacity, borderTop: `${borderTop}px solid red`, height: '30px', marginTop: '0px', backgroundColor: isDragging ? '#efefef' : '', cursor: isDragging ? 'grabbing' : 'grab' }}>
            <div className="item-container" style={{ opacity, height: '100%' }}>
                <div className="item-content" style={{ padding: getBooleanParam(isEdit) ? '0px 10px' : '5px 10px' }}>
                    <div style={{ display: 'flex' }}>
                        {getBooleanParam(isEdit) ?
                            <input
                                autoFocus={true}
                                type="text"
                                id={`module-item-name-${item?.id}-id`}
                                className={`module-item-name-${item?.id}-class`}
                                value={label}
                                title={label}
                                style={inputStyle}
                                onChange={(e) => onChangeModuleItem(e.target.value)}
                                onKeyUp={(e) => handleKeyUp(e)}
                                onBlur={() => saveModuleItem()}
                                onFocus={(e) => handleFocus(e)}
                                autoComplete="new-password"
                            />
                            :
                            <div onDoubleClick={() => setIsEdit(isShowEditIcon)} title={label} style={{ width: '100%', float: 'left', color: '#717171', fontSize: '14px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{label}</div>
                        }

                        {showActionButtons && <div style={{ display: 'flex', alignItems: 'center' }}>
                            {isShowEditIcon && item?.type !== "externallink"  && <span style={{ padding: '5px' }}>
                                <Icon key={`module-item-edit-${item?.id}`} style={{ 'fontSize': '11px', cursor: 'pointer', color: '#717171' }} title={getLocalizedStrings().label.COMMON.EDIT} onClick={() => setIsEdit(true)}>edit</Icon>
                            </span>}
                            {isShowEditIcon && item?.type == "externallink"  && <span style={{ padding: '5px' }}>
                                <Icon key={`module-item-edit-${item?.id}`} style={{ 'fontSize': '11px', cursor: 'pointer', color: '#717171' }} title={getLocalizedStrings().label.COMMON.EDIT} onClick={() => openExternalDialog()}>edit</Icon>
                            </span>}
                            {isShowDeleteIcon && <span style={{ padding: '5px' }} >
                                <Icon key={`module-item-delete-${item?.id}`} style={{ 'fontSize': '12px', fontWeight: 'normal', color: '#717171', cursor: 'pointer' }} title={getLocalizedStrings().label.COMMON.DELETE} onClick={() => handleRemove(moduleItem)}>close</Icon>
                            </span>}
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MenuConfiguration;

