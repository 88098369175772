import React from "react";
import { styles } from "../../../services/constants/styles";
import {
	constants,
	OBJECT_TABLEID_MAP,
} from "../../../services/constants/constants";
import {
	getStringParam,
	getObjectParam,
	isValidParam,
	getIntParam,
	getBooleanParam,
	getArrayParam,
} from "../../../services/helper/parameterVerifier";
import { makeTiteleCase } from "../../../services/helper/utils";
import { refreshListView ,updateListviewFilter} from "../../../services/actions/listViewAcions";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { addTab } from "../../../services/helper/sfTabManager";
import Button from "@mui/material/Button";
import { LinkToType } from "../../../services/constants/link";
import { getAppDialog } from "../../../services/actions/appContainerActions";
import {
	getAppDrawer,
	getAppCustomDrawer,
} from "../../../services/actions/appContainerActions";
import * as sfDialogs from "../components/sfDialogs";
import { openCloseSearchPopover } from "../../../services/actions/appActions";
import {
	hasAccessPermission,
	getSelectedObjectLabel,
	getImageNameByObject,
	getObjectLabelByObject,
} from "../../../services/helper/common";
import {
	getActiveTab,
	getActiveTabInfo,
	updateTabInfo,
	TYPE_LIST_VIEW,
	TYPE_DETAIL_VIEW,
	TYPE_COLUMN_VIEW,
	TYPE_OTHER_VIEW,
	TYPE_HOME,
	selectTabByIndex,
	getCalenderPageType,
	addNewFormTabs,
} from "../../../services/helper/sfTabManager";
import ShowCircularProgress from "../components/circularProgress";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isDetailViewSet, detailViewIsMounted, refreshDetailViewData} from '../../../services/actions/detailViewActions';
import { franchiseLogin } from "../../../services/actions/unitsAction.js";
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { isArray } from "lodash";


const NoRecords = ({
	noRecordFilterText,
	object,
	totalRecordCount,
	parentObject,
	childObject,
	view,
	isDuplicate,
	suffix
}) => {
	const dispatch = useDispatch();
	const [stateView, setView] = useState(null);
	const [noRecordFoundText, setNoRecordFoundText] = useState("");
	const [mounted, setMounted] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const app = useSelector((state) => state.app);
	const appContainer = useSelector((state) => state.appContainer);
	const detailView = useSelector((state) => state.detailView);
	const tab = useSelector((state) => state.tab);
	const childListView = useSelector((state) => state.childListView);
	const listView = useSelector((state) => state.listView);
	const selectedFilter = useSelector((state)=>state.childListView.selectedFilter);

	useEffect(() => {
		let activeTabInfo = getObjectParam(getActiveTabInfo());
		let isLookUpField = appContainer.dialogProps.dialogType;
		let tempnoRecordFoundText = noRecordFoundText;
		if (activeTabInfo.hasOwnProperty("search")) {
			tempnoRecordFoundText = activeTabInfo.search.searchText;
		} else if (isLookUpField === "LookupDialog") {
			tempnoRecordFoundText = noRecordFilterText;
		} else {
			if (activeTabInfo.hasOwnProperty("searchText")) {
				tempnoRecordFoundText = activeTabInfo.searchText;
			} else if (
				activeTabInfo.hasOwnProperty("filter") &&
				activeTabInfo.is_callFrom_queue !== true
			) {
				tempnoRecordFoundText = activeTabInfo.filter.name;
			}
		}
		setNoRecordFoundText(tempnoRecordFoundText);
		setMounted(true);
		setView(view);
	}, [stateView]);

	const onClickRefreshListView = () => {
		let activeTabInfo = getObjectParam(getActiveTabInfo());
		if (activeTabInfo.hasOwnProperty("searchText")) {
			delete activeTabInfo.searchFieldName;
			delete activeTabInfo.searchText;
			if (activeTabInfo.hasOwnProperty("statusfieldName")) {
				delete activeTabInfo.statusfieldName;
			}
			updateTabInfo(activeTabInfo);
			setMounted(false);
		}
		let objectName = getStringParam(object);
		if(objectName === "reportedsales"){
			dispatch(detailViewIsMounted(false));
			dispatch(isDetailViewSet(false));
			dispatch(refreshDetailViewData(true));
		}
		if(objectName === constants.AUDIT_ANSWERS_OBJECT){
			dispatch(detailViewIsMounted(false));
			dispatch(isDetailViewSet(false));
			dispatch(refreshDetailViewData(true));
			delete activeTabInfo['startIndex'];
			updateTabInfo(activeTabInfo);
		}
		if (objectName === constants.WORKFLOW_OBJECT) {
			let { filterList } = listView.filterByInfo;
			updateListviewFilter(objectName, filterList, filterList[0].value);

		}
		refreshListView(object,null,false,suffix);
	};

	const modifySearch = () => {
		let openSearchPopover = {};
		openSearchPopover.advance = false;
		openSearchPopover.global = false;
		let activeTab = getObjectParam(getActiveTab());
		if (activeTab.object === constants.SEARCH_RESULTS) {
			openSearchPopover.global = true;
		} else if (activeTab.object === constants.ADVANCE_SEARCH_RESULTS) {
			openSearchPopover.advance = true;
		}
		dispatch(openCloseSearchPopover(openSearchPopover));
		setView(view);
	};
	const addNewRecord = (value) => {
		let data = {};
		let labelName = null;
		let objectName = null;
		let linkTo = null;
		let isOpenDrawer = true;
		let isCampaign = false;
		let hasPermission = false;
		let filter = null;
		let isMarketingTemplate = false;
		let isBroadcastCampaign = false;
		try {
			let tempObjName = object;
			if (object === constants.SEARCH_RESULTS) {
				let activeTab = getObjectParam(getActiveTab());
				let activeTabInfo = getObjectParam(activeTab?.info);
				let search = getObjectParam(activeTabInfo.search);
				tempObjName = getStringParam(search.object);
			} else if (object === constants.ADVANCE_SEARCH_RESULTS) {
				let activeTab = getObjectParam(getActiveTab());
				let activeTabInfo = getObjectParam(activeTab?.info);
				let advanceSearch = getObjectParam(activeTabInfo.advanceSearch);
				tempObjName = getStringParam(advanceSearch.object);
			} else if (object === constants.SCHEDULER) {
				tempObjName = constants.APPOINTMENTS;
			}
			objectName = tempObjName;
			value = getStringParam(value);
			if (objectName === constants.COUPONS_OBJECT) {
				hasPermission =
					app.me.t_name === "Admin" || app.me.t_name === "Marketing"
						? true
						: false;
			} else if (
				objectName === constants.SALES_TEMPLATE_OBJECT ||
				objectName === constants.MARKETING_TEMPLATE_OBJECT ||
				objectName === constants.SMS_TEMPLATE_OBJECT ||
				objectName === constants.CALL_SCRIPT_OBJECT ||
				objectName === constants.AGREEMENT_TEMPLATE_OBJECT
			) {
				hasPermission = hasAccessPermission(
					objectName,
					constants.SECURITY_LEVEL_TYPE_PERMISSION,
					constants.PERMISSION_NAME_MANAGE_TEMPLATES
				);
				if (
					objectName === constants.AGREEMENT_TEMPLATE_OBJECT &&
					hasPermission
				) {
					if (app.me.t_name !== "Admin") {
						hasPermission = false;
					}
				}
			} else if (objectName === constants.WEB_FORMS) {
				hasPermission = true;
			} else if (objectName === constants.UNITS_OBJECT) {
				if (
					getBooleanParam(app.me.franchisor_add_unit) &&
					(getBooleanParam(app.me.is_tenant_admin) ||
						constants.SECURITY_ROLE_ADMIN === getStringParam(app.me.t_name))
				) {
					hasPermission = true;
				}
			} else {
				hasPermission = hasAccessPermission(
					objectName,
					constants.SECURITY_LEVEL_TYPE_ACCESS,
					constants.ACCESS_TYPE_CREATE
				);
			}
			if (hasPermission) {
				if (value === constants.NEW) {
					if (objectName === constants.SALES_TEMPLATE_OBJECT) {
						labelName =
							getLocalizedStrings().label.SALES_TEMPLATE
								.SALES_TEMPLATE_CREATE_TAB_LABEL;
					} else if (objectName === constants.MARKETING_TEMPLATE_OBJECT) {
						labelName =
							getLocalizedStrings().label.MARKETING_TEMPLATE
								.MARKETING_TEMPLATE_CREATE_TAB_LABEL;
						isOpenDrawer = false;
						isMarketingTemplate = true;
					} else if (objectName === constants.AGREEMENT_TEMPLATE_OBJECT) {
						labelName =
							getLocalizedStrings().label.AGREEMENT_TEMPLATE
								.AGREEMENT_TEMPLATE_CREATE_TAB_LABEL;
					} else if (objectName === constants.BROADCAST_CAMPAIGNS_OBJECT) {
						labelName = constants.BROADCAST_CAMPAIGN_CREATE_TAB_LABEL;
						isCampaign = true;
						isOpenDrawer = false;
						isBroadcastCampaign = true;
					} else if (objectName === constants.AB_CAMPAIGNS_OBJECT) {
						labelName = constants.AB_CAMPAIGN_CREATE_TAB_LABEL;
						isCampaign = true;
						isOpenDrawer = false;
					} else if (objectName === constants.SOCIAL_CAMPAIGNS_OBJECT) {
						labelName = constants.SOCIAL_CAMPAIGN_CREATE_TAB_LABEL;
					} else if (objectName === constants.AUTOMATION_DESIGNER_OBJECT) {
						labelName =
							getLocalizedStrings().label.COMMON.CREATE +
							" " +
							objectName.charAt(0).toUpperCase() +
							objectName.slice(1);
					} else if (objectName === constants.WEB_FORMS) {
						labelName = constants.WEB_FORM_CREATE_TAB_LABEL;
						// labelName = getLocalizedStrings().label.WEB_FORM.WEB_FORM_CREATE_TAB_LABEL;
					} else if (objectName === constants.ASSIGNMENT_RULES_OBJECT) {
						labelName =
							getLocalizedStrings().label.ASSIGNMENT_RULES
								.ASSIGNMENT_RULE_CREATE_TAB_LABEL;
					} else if (
						objectName === constants.SOURCE_OBJECT ||
						objectName === constants.TASKSLIST_OBJECT ||
						objectName === constants.NOTE_TYPES_OBJECT ||
						objectName === constants.ROLL_UP_OBJECT ||
						objectName === constants.CAMPAIGN_TYPES_OBJECT ||
						objectName === constants.RELATIONS_OBJECT ||
						objectName === constants.LOCATION_OBJECT||
						objectName === constants.APPOINTMENT_TYPES_OBJECT
					) {
						labelName =
							getLocalizedStrings().label.COMMON.ADD +
							" " +
							getLocalizedStrings().label.SETTINGS[objectName.toUpperCase()];
					} else if (objectName === constants.NEWS_OBJECT) {
						labelName =
							getLocalizedStrings().label.COMMON.ADD +
							" " +
							getLocalizedStrings().label.COMMON.NEWS;
					} else if (objectName === constants.EMAIL_INTEGRATION_OBJECT) {
						labelName =
							getLocalizedStrings().label.COMMON.ADD +
							" " +
							getLocalizedStrings().label.EMAIL_INTEGRATION.EMAIL;
					} else if (objectName === constants.APPOINTMENTS) {
						labelName =
							getLocalizedStrings().label.COMMON.ADD_NEW_APPOINTMENT +
							" " +
							getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENT;
					} else if (objectName === constants.TASKS) {
						labelName =
							getLocalizedStrings().label.COMMON.ADD_NEW_APPOINTMENT +
							" " +
							getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK;
					} else if (objectName === constants.LANDING_PAGES_FROALA_OBJECT) {
						labelName =
							getLocalizedStrings().label.COMMON.ADD +
							" " +
							getLocalizedStrings().label.LANDING_PAGE.LANDING_PAGE;
					} else {
						let objectName = null;
						let itemLabel = null;
						let activeTab = getObjectParam(getActiveTab());
						let activeTabObject = getObjectParam(activeTab.object);
						if (activeTabObject === constants.SEARCH_RESULTS) {
							let activeTabInfo = getObjectParam(activeTab.info);
							let search = getObjectParam(activeTabInfo.search);
							objectName = getStringParam(search.object);
							itemLabel = getObjectLabelByObject(objectName);
						} else if (activeTabObject === constants.ADVANCE_SEARCH_RESULTS) {
							let activeTabInfo = getObjectParam(activeTab.info);
							let advanceSearch = getObjectParam(activeTabInfo.advanceSearch);
							objectName = getStringParam(advanceSearch.object);
							let objList = getArrayParam(app.objectList);
							if (objList.length > 0) {
								let arr = objList.filter((f) => f.name === objectName);
								if (arr.length > 0) {
									itemLabel = getStringParam(arr[0].label);
								}
							}
						} else {
							itemLabel = getObjectLabelByObject(activeTabObject);
						}
						if (itemLabel === null) {
							itemLabel = getSelectedObjectLabel();
						}
						labelName =
							getLocalizedStrings().label.COMMON.ADD + " " + itemLabel;
						if (object === constants.GROUP_OBJECT) {
							data.mode = constants.SF_FORM_OPEN_MODE_EXPANDED;
						} else if (object === constants.COMPLIANCE_OBJECT) {
							data.mode = constants.SF_FORM_OPEN_MODE_EXPANDED;
						} else {
							data.mode = constants.SF_FORM_OPEN_MODE_QUICK;
						}
						if (activeTabObject === constants.JOBS_OBJECT) {
							isOpenDrawer = false;
						}
					}
					linkTo = LinkToType.TYPE_NEW;
				}

				if (
					objectName === constants.AUTOMATION_DESIGNER_OBJECT ||
					objectName === constants.TOUCH_CAMPAIGNS_OBJECT
				) {
					if (objectName === constants.TOUCH_CAMPAIGNS_OBJECT) {
						objectName = constants.AUTOMATION_DESIGNER_OBJECT;
					}
					// labelName = constants.AUTOMATION_CAMPAIGN_CREATE_TAB_LABEL;
					labelName =
						getLocalizedStrings().label.AUTOMATION_DESIGNER
							.AUTOMATION_CAMPAIGN_CREATE_TAB_LABEL;
					let url = "/" + objectName + "/" + linkTo;
					let tabs = tab.tabs;
					let automationTab = tabs.find((tab) => {
						return (
							tab.type === TYPE_DETAIL_VIEW &&
							tab.object === constants.AUTOMATION_DESIGNER_OBJECT
						);
					});
					if (!isValidParam(automationTab)) {
						let tab = {
							item: objectName,
							label: labelName,
							object: objectName,
							type: TYPE_DETAIL_VIEW,
							url: url,
						};
						addTab(tab, true);
					} else {
						sfDialogs.alert(
							getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
							getLocalizedStrings().message.AUTOMATION_DESIGNER
								.RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD,
							() => sfAlertFunctionRecord(automationTab)
						);
					}
				} else if (objectName === constants.WEB_FORMS) {
					isOpenDrawer = false;
					let tableId = 10;
					let token = localStorage.getItem("token");
					let redirectUrl = constants.snapshotUrl;
					redirectUrl += "&token=" + token;
					redirectUrl += "&callingfor=" + "webform";
					redirectUrl += "&type=CUSTOM_VIEW";
					redirectUrl += "&label=" + labelName;
					redirectUrl += "&tableId=" + tableId;
					window.open(redirectUrl);
				} else if (object === "workflows") {
					let activeTab = getObjectParam(getActiveTab());
					let activeTabInfo = getObjectParam(activeTab.info);
					let objName = "";
					let tableId = 0;
					if (
						activeTabInfo.hasOwnProperty("groupByCondition") &&
						activeTabInfo.groupByCondition !== null &&
						activeTabInfo.groupByCondition.length > 0
					) {
						let objLabel = getStringParam(activeTabInfo.groupByCondition);
						let objList = getArrayParam(app.objectList);
						if (objList.length > 0) {
							let arr = objList.filter((f) => f.label === objLabel);
							if (arr.length > 0) {
								objName = getStringParam(arr[0].name);
								tableId = OBJECT_TABLEID_MAP[objName];
							}
						}
					}

					let label = "New Workflow";
					let token = localStorage.getItem("token");
					let redirectUrl = constants.snapshotUrl;
					redirectUrl += "&token=" + token;
					redirectUrl += "&callingfor=" + "workflows";
					redirectUrl += "&type=new";
					redirectUrl += "&label=" + label;
					redirectUrl += "&tableId=" + tableId;
					if (app.me.is_customize_workflow) {
						window.open(redirectUrl);
					} else {
						let permissionMessage =
							getLocalizedStrings().message.COMMON.ACCESS_DENIED;
						sfDialogs.alert(
							getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
							permissionMessage,
							null
						);
					}
				} else if (isOpenDrawer) {
					data.object = objectName;
					if (
						objectName === constants.ACCOUNTS_OBJECT ||
						objectName === constants.CONTACTS_OBJECT
					) {
						let activeTabInfo = getObjectParam(getActiveTabInfo());
						filter = getObjectParam(activeTabInfo.filter);
						let filterId = getIntParam(filter.id);
						let type = getStringParam(filter.type);
						if (type === "group") {
							data.parent_object = constants.GROUP_OBJECT;
							data.parent_record_id = filterId;
						}
					} else if (objectName === constants.USEFUL_LINK_OBJECT) {
						data = {};
						data.type = value;
						// if (value.toLowerCase() === 'category') {
						//     labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + getLocalizedStrings().label.USEFUL_LINKS.CATEGORY;
						// } else if (value.toLowerCase() === 'link') {
						labelName =
							getLocalizedStrings().label.COMMON.ADD +
							" " +
							getLocalizedStrings().label.USEFUL_LINKS.LINK;
						// }
						value = constants.USEFUL_LINK;
					}

					if (objectName === constants.ASSIGNMENT_RULES_OBJECT) {
						data.table = constants.ACCOUNTS_OBJECT;
						data.callFrom = "Assignment Rules";
						data.mode = "New";
					}

					if (objectName === constants.SALES_TEMPLATE_OBJECT) {
						let style = {};
						style.width = "83%";
						dispatch(
							getAppCustomDrawer(
								true,
								labelName,
								LinkToType.TYPE_NEW,
								style,
								data,
								null,
								false
							)
						);
					} else if (objectName === constants.AGREEMENT_TEMPLATE_OBJECT) {
						let style = {};
						style.width = "83%";
						dispatch(
							getAppCustomDrawer(
								true,
								labelName,
								value,
								style,
								data,
								null,
								true
							)
						);
					} else if (objectName === constants.NEWS_OBJECT) {
						let style = {};
						style.width = "60%";
						dispatch(
							getAppCustomDrawer(
								true,
								labelName,
								value,
								style,
								data,
								null,
								false
							)
						);
					} else {
						dispatch(getAppDrawer(true, labelName, value, data, null));
					}
				} else {
					if (isCampaign) {
						if (isBroadcastCampaign) {
							let callbackData = {
								labelName: labelName,
								value: value,
								data: data,
							};
							dispatch(
								getAppDialog(
									true,
									constants.EDITOR_TEMPLATE_DIALOG,
									getLocalizedStrings().label.CAMPAIGNS.TEMPLATE_DIALOG_TITLE,
									null,
									callbackData,
									constants.LIST_VIEW
								)
							);
						} else {
							dispatch(
								getAppDialog(
									true,
									constants.TEMPLATE_DIALOG,
									getLocalizedStrings().label.CAMPAIGNS.TEMPLATE_DIALOG_TITLE,
									null,
									null,
									constants.LIST_VIEW
								)
							);
						}
					} else if (isMarketingTemplate) {
						let data = {
							labelName: labelName,
							value: value,
							type: constants.EDITOR_BEE,
							object: objectName,
						};

						let style = {};
						style.width = "100%";
						dispatch(
							getAppCustomDrawer(
								true,
								labelName,
								value,
								style,
								data,
								null,
								false
							)
						);
					} else if (objectName === constants.JOBS_OBJECT) {
						let info = { id: 0, title: labelName, type: "New", object: object };
						addNewFormTabs(info);
					}
				}
			} else {
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					getLocalizedStrings().message.COMMON.ACCESS_DENIED,
					null
				);
			}
		} catch (error) {
			console.error("Error in 'noRecord.js -> addNewRecord()':" + error);
		}
	};

	const sfAlertFunctionRecord = (automationTab) => {
		let tabIndex = automationTab.index;
		selectTabByIndex(tabIndex);
	};

	const addBranchName = () => {
		let unitData = {}
		unitData.object = "Add Branch";
		unitData.dialogContentHeight = '116px';
		dispatch(getAppDialog(true, 'Add Branch', "Select/Create a Branch", null, unitData, constants.LIST_VIEW));
	}

	// const addUnitBase =()=>{
	// 	let unitData = {}
	// 	unitData.object = "Add Base";
	// 	unitData.dialogContentHeight ='116px';
	//    dispatch(getAppDialog(true, 'Add Base', "Select/Create a Base", null, unitData , constants.LIST_VIEW));
	
	// }

	const addUnitBase = () => {
		let labelName = "Add Base";
	    let data = {};
        data.object = constants.ADD_UNIT_BASE;
        data.mode = "QUICK";
        data.records = "";
        data.mainContactName = "";
        data.accountRecordId = 0;
        data.clientProjectId = 0;
        data.clientEmail = '';
        data.click_form = 'addunit';
        data.billingMessage = '';
        dispatch(getAppDrawer(true, labelName, 'New', data, null));
    }

	const loginToBase = () => {
        let baseTeantId = 0;
        let params = {
            franchise_industry_type: app.me.franchise_industry
        }
        var response = Promise.resolve(HTTPClient.get(endPoints.COMMON.GET_BASETENANT_ID, params));
        response.then((response) => {
            baseTeantId = response.baseTenantId;
            login(baseTeantId);

        });
    }

	const login = (projectId) => {
		let myclient_id = projectId
		let promise = franchiseLogin(myclient_id);
		if (isValidParam(promise)) {
			promise.then(response => {
				if (isValidParam(response)) {
					if (getBooleanParam(response.is_franchise_login)) {
						let previousFRLoginDetails = localStorage.getItem('franchiseAppLoginDetails')
						previousFRLoginDetails  = previousFRLoginDetails ? JSON.parse(previousFRLoginDetails) : [];
						const {me: { id, background, industry_id, industry_type, is_franchisor, is_franchisee, email, projectId }, selectedModuleItems, selectedModuleName, selectedModuleInfo } = app;
						const { rememberTab, tabs } = tab;
						const currentUrl = window.location.href;
						const franchiseAppLoginDetails = {}
						franchiseAppLoginDetails.appData = { id: id, background: background, industryId: industry_id, industryType: industry_type, isFranchisor: is_franchisor, is_franchisee: is_franchisee, email: email, projectId: projectId}
						franchiseAppLoginDetails.selectionDetails = { selectedModuleItems: selectedModuleItems, selectedModuleName: selectedModuleName }
						if (isValidParam(selectedModuleInfo)) {
							franchiseAppLoginDetails.selectionDetails = { ...franchiseAppLoginDetails.selectionDetails, selectedModuleInfo: selectedModuleInfo }
						}
						franchiseAppLoginDetails.tabDetails = { listrememberedTab: rememberTab, listselectedTab: tabs }
						franchiseAppLoginDetails.returnUrl = currentUrl
						franchiseAppLoginDetails.token = response.token
						franchiseAppLoginDetails.callFrom = constants.LIST
						if(isArray(previousFRLoginDetails)) {
							previousFRLoginDetails.push(franchiseAppLoginDetails)
						} 
						localStorage.setItem('token', response.token);
						localStorage.setItem('franchiseAppLoginDetails', JSON.stringify(previousFRLoginDetails))
						localStorage.setItem(`shouldReturnToInitialPos_${app.me.projectId}_${app.me.id}`, true)
						localStorage.setItem('is_franchisor_token', true);
						if (constants.APP_URL === "") {
							window.location.replace("/");
						} else {
							window.location.replace(constants.APP_URL);
						}
					} else {
						if (response.data?.is_subscription_overdue) {
							localStorage.removeItem(`shouldReturnToInitialPos_${app.me.projectId}_${app.me.id}`)
							let alertDialogMessage = getLocalizedStrings().message.UNITS.SUBSCRIPTION_NOT_VALID;
							sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertDialogMessage, null)
						}
					}
				}
			});
		}
	};

	let objectName = getStringParam(object);
	let _isDetailView = getBooleanParam(detailView.isDetailView);
	let tempchildListView = childListView ;

	let isLookUpField = appContainer.dialogProps.dialogType;
	let parentObjectName = getStringParam(parentObject);
	let activeTab = getActiveTab();
	let tabInfo = getObjectParam(activeTab?.info);
	let tabObject = getStringParam(activeTab?.object);
	objectName = objectName.replace("_", "-");

	if(isDuplicate){
		_isDetailView = false;
	}
	if (objectName === constants.RECYCLE_BIN_OBJECT) {
		objectName = constants.ACCOUNTS_OBJECT;
	}
	let labelName = null;
	if (objectName.length > 1) {
		if (
			objectName === constants.APPOINTMENTS ||
			objectName === constants.TASKS ||
			objectName === constants.GROUP_OBJECT ||
			objectName === constants.NOTES ||
			objectName === constants.ATTACHMENT_OBJECT ||
			objectName === constants.WEB_FORMS ||
			objectName === constants.BROADCAST_CAMPAIGNS_OBJECT ||
			objectName === constants.AB_CAMPAIGNS_OBJECT ||
			objectName === constants.AUTOMATION_DESIGNER_OBJECT ||
			objectName === constants.INVOICES_OBJECT ||
			objectName === constants.TOUCH_CAMPAIGNS_OBJECT ||
			objectName === constants.EVENTS_OBJECT ||
			objectName === constants.ACTIVITIES ||
			objectName === constants.CONVERSIONS ||
			objectName === constants.ONBOARDING_OBJECT ||
			objectName === constants.SOURCE_OBJECT ||
			objectName === constants.TASKSLIST_OBJECT ||
			objectName === constants.CLAIMS_OBJECT ||
			objectName === constants.USERS_OBJECT ||
			objectName === constants.SECURITY_LEVEL_OBJECT ||
			objectName === constants.RELATIONS_OBJECT ||
			objectName === constants.CAMPAIGN_TYPES_OBJECT ||
			objectName == constants.LOCATION_OBJECT||
			objectName === constants.NOTE_TYPES_OBJECT ||
			objectName === constants.APPOINTMENT_TYPES_OBJECT ||
			objectName === constants.WORKFLOW_OBJECT ||
			objectName === constants.ASSIGNMENT_RULES_OBJECT ||
			objectName === constants.ORDER_ITEMS_OBJECTS ||
			objectName === constants.LANDING_PAGES_FROALA_OBJECT ||
			objectName === constants.ROLL_UP_OBJECT ||
			objectName === constants.PAYMENTS_OBJECT ||
			objectName === constants.TIME_OBJECT ||
			objectName === constants.LINE_ITEMS_OBJECT ||
			objectName === constants.ROYALTY_OBJECT || 
			objectName === constants.REFUND_OBJECT
		) {
			labelName = makeTiteleCase(objectName);
			labelName = getLocalizedStrings().label.NO_RECORD.hasOwnProperty(
				labelName
			)
				? getLocalizedStrings().label.NO_RECORD[labelName]
				: labelName;
		} else if (
			objectName === constants.MARKETING_TEMPLATE_OBJECT ||
			objectName === constants.SALES_TEMPLATE_OBJECT ||
			objectName === constants.SMS_TEMPLATE_OBJECT ||
			objectName === constants.CALL_SCRIPT_OBJECT ||
			objectName === constants.AGREEMENT_TEMPLATE_OBJECT
		) {
			labelName = "Templates";
			labelName = getLocalizedStrings().label.NO_RECORD.hasOwnProperty(
				labelName
			)
				? getLocalizedStrings().label.NO_RECORD[labelName]
				: labelName;
		} else if (object === constants.EMAIL_OBJECT) {
			labelName = "Emails";
			labelName = getLocalizedStrings().label.NO_RECORD["Emails"];
		} else if (object === parentObject) {
			labelName = "Relationships";
		} else if (objectName === constants.DOCUSIGN) {
			labelName = "Templates";
		} else if (object === constants.EMAIL_INTEGRATION_OBJECT) {
			labelName = "Email";
		} else if (object === constants.AUDIT_QUESTIONS_OBJECT) {
			labelName = getLocalizedStrings().label.AUDIT_QUESTIONS.AUDIT_QUESTIONS;
		} else if (object === constants.AUDITS_OBJECT) {
			labelName = "Audits";
		}  else if (object === constants.AUDIT_ANSWERS_OBJECT) {
			labelName = "Answers with Corrective Actions";
		} else if (object === constants.SURVEY_RESPONSES) {
			labelName = "Survey Responses";
		} else if (object === constants.PETS_OBJECT) {
			labelName = "Pets";
		} else if (object === constants.CASES_OBJECT && app?.selectedModuleName === constants.MODULE_HUB) {
			let listData = getObjectParam(listView?.data);
			labelName = listData?.query_name ;
		} 
		else {
			labelName = getObjectLabelByObject(objectName);
			labelName = getLocalizedStrings().label.NO_RECORD.hasOwnProperty(
				labelName
			)
				? getLocalizedStrings().label.NO_RECORD[labelName]
				: labelName;
		}
	}
	let isListViewSearch = false;
	let activeTabInfo = getObjectParam(getActiveTabInfo());
	if (
		activeTabInfo.hasOwnProperty("searchFieldName") &&
		activeTabInfo.hasOwnProperty("searchText")
	) {
		isListViewSearch = true;
	}
	let label =
		getLocalizedStrings().label.COMMON.NOTHING_FOUND +
		" " +
		noRecordFoundText +
		" ";
	if (_isDetailView && noRecordFoundText === "") {

		label =
			getLocalizedStrings().label.COMMON.NO +
			" " +
			labelName +
			" " +
			getLocalizedStrings().label.COMMON.FOUND;
	}
	else if (_isDetailView && noRecordFoundText === "Accounts") {
		label =
			getLocalizedStrings().label.COMMON.NO +
			" " +
			labelName +
			" " +
			getLocalizedStrings().label.COMMON.FOUND;
	}
	else if (
		!_isDetailView &&
		noRecordFoundText === "" &&
		object !== constants.FIND_DUPLICATE_OBJECT
	) {
		label =
			getLocalizedStrings().label.COMMON.NOTHING_FOUND + " " + labelName + " ";
	} 
	if (_isDetailView && (tempchildListView.data.parent_object == constants.CONTACTS_OBJECT ||tempchildListView.data.parent_object == constants.ACCOUNTS_OBJECT || 
		tempchildListView.data.parent_object == constants.OPPORTUNITIES_OBJECT || tempchildListView.data.parent_object == constants.CASES_OBJECT || tempchildListView.data.parent_object == constants.PROJECTS_OBJECT ||
		tempchildListView.data.parent_object == constants.ISSUES_OBJECT || tempchildListView.data.parent_object == constants.CUSTOM_TABLE1_OBJECT ||
		 tempchildListView.data.parent_object == constants.CUSTOM_TABLE2_OBJECT || tempchildListView.data.parent_object == constants.CUSTOM_TABLE3_OBJECT 
		 || tempchildListView.data.parent_object == constants.CUSTOM_TABLE4_OBJECT 
	)) {
		label =
			getLocalizedStrings().label.COMMON.NO +
			" " +
			labelName +
			" " +
			getLocalizedStrings().label.COMMON.FOUND;
	} 
	else if (
		!_isDetailView &&
		noRecordFoundText === "" &&
		object === constants.FIND_DUPLICATE_OBJECT
	) {
		let customLabelNameData = app.objectList.filter(
			(object) => object.name === childObject
		);
		let customLabelName = customLabelNameData[0].label;
		label =
			getLocalizedStrings().label.COMMON.NO_DUPLICATE +
			" " +
			customLabelName +
			" " +
			getLocalizedStrings().label.COMMON.FOUND +
			".";
	}
	if (app.me.language === "zh-CN") {
		switch (objectName) {
			case "accounts":
				label = "未发现账户";
				break;
			case "contacts":
				label = "未发现联系人";
				break;
			case "opportunities":
				label = "未发现商机";
				break;
			case "web-forms":
				label = "未发现Web表单";
				break;
			case "sales-templates":
				label = "未发现销售模板";
				break;
			case "cases":
				label = "未发现案例";
				break;
			case "solutions":
				label = "未发现解决方案";
				break;
		}
	}
	let imageName = getImageNameByObject(objectName);
	if (objectName === "ab-campaigns") {
		imageName = "fa fa-bullhorn";
	}else if (objectName === constants.PETS_OBJECT) {
		imageName = "fas fa-paw";
	}
	let className =
		(_isDetailView && isLookUpField === null) ||
		(!_isDetailView && isLookUpField === "LookupDialog")
			? "data_less"
			: "data_less_text";
	let divStyle =
		(_isDetailView && isLookUpField === null) ||
		(!_isDetailView && isLookUpField === "LookupDialog")
			? {}
			: { marginTop: 17, marginBottom: 14 };
			
		let contentHeight = window.innerHeight - 240;
		let top = (contentHeight - 10) / 2;

	if (mounted) {
		return (
			<div
				key={object}
				style={{ ...styles.workAreaContainer,paddingTop: "20px", marginTop: "0px" }}
			>
				<table className="table table-striped" style={{ textAlign: "center",width:'100%' }}>
					{((!_isDetailView && isLookUpField === null) ||
						(isLookUpField !== "LookupDialog")) && (
						<tr>
							<td>
								<div className="data_less" style={{marginTop: tabObject == 'appointments' && object == 'notes' ? '60px': '100px'}} >
									<span style={{ fontSize: "100px", opacity: "0.4" }}>
										<i className={imageName}></i>
									</span>
								</div>
							</td>
						</tr>
					)}
					<tr>
						<td>
							{tabObject !== "search-results" &&
								tabObject !== "advance-search-results" && tabObject !== "intake-form" && (
									<div className={className} style={{divStyle, marginTop:'20px'}}>
										{tabObject !== constants.AUDITS_OBJECT &&
											objectName !== constants.AUDIT_ANSWER_OBJECT && selectedFilter !== "Answers with Corrective Actions" &&
											 <span>{label}</span>
										}
										{stateView !== constants.AUDIT_QUESTIONS_OBJECT &&
											stateView === constants.LIST_VIEW && 
											isListViewSearch && (
												<span>
													&nbsp;
													<a
														href="javascript:void(0);"
														onClick={() => onClickRefreshListView()}
													>
														{getLocalizedStrings().label.COMMON.BACK_TO_LIST}
													</a>
												</span>
											)}


										{stateView !== constants.AUDIT_QUESTIONS_OBJECT &&
											stateView === constants.LIST_VIEW &&
											tabObject === constants.AUDITS_OBJECT &&
											objectName === constants.AUDIT_ANSWER_OBJECT && selectedFilter === "Answers with Corrective Actions" && (
												<span>{getLocalizedStrings().message.AUDIT_ANSWERS.NO_RECORD_FOUND}</span>
											)}

										{stateView !== constants.AUDIT_QUESTIONS_OBJECT &&
											stateView === constants.LIST_VIEW && 
											  tabObject === constants.AUDITS_OBJECT &&
											  objectName === constants.AUDIT_ANSWER_OBJECT && selectedFilter === "Answers with Corrective Actions" && (
												<span>
													&nbsp;
													<a
														href="javascript:void(0);"
														onClick={() => onClickRefreshListView()}
													>
														{getLocalizedStrings().label.COMMON.BACK_TO_LIST}
													</a>
												</span>
											)}
									</div>
								)}
							{(tabObject === "search-results" || tabObject === "intake-form" ||
								tabObject === "advance-search-results") && (
								<div className={className} style={{divStyle,marginTop:'20px'}}>
									<span>{"No records found"}</span>
								</div>
							)}
						</td>
					</tr>
					{((stateView !== constants.DETAIL_VIEW && isLookUpField === null && !tempchildListView.mounted) ||
						(stateView !== constants.DETAIL_VIEW && isLookUpField !== "LookupDialog" && !tempchildListView.mounted) ||(stateView !== constants.DETAIL_VIEW && isLookUpField !== "LookupDialog" && listView.mounted && objectName === constants.UNITS_OBJECT)) && (
						<tr>
							<td>
								{tabObject !== "search-results" &&
								tabObject !== "intake-form" &&
									tabObject !== "advance-search-results" && (
										<div className="data_less_text">
											{((objectName === constants.ACCOUNTS_OBJECT &&
												!(object === constants.RECYCLE_BIN_OBJECT)) ||
												objectName === constants.CONTACTS_OBJECT ||
												objectName === constants.OPPORTUNITIES_OBJECT ||
												objectName === constants.CUSTOM_TABLE1_OBJECT ||
												objectName === constants.CUSTOM_TABLE2_OBJECT ||
												objectName === constants.CUSTOM_TABLE3_OBJECT ||
												objectName === constants.CUSTOM_TABLE4_OBJECT ||
												objectName === constants.SALES_LEADS_OBJECT) && (
												<div>
													<Button
														onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
														onClick={() => addNewRecord(constants.NEW)}
														title={getLocalizedStrings().label.COMMON.ADD}
														style={{...styles.listViewPrimaryButton, marginTop:'10px'}}
													>
														{" "}
														{getLocalizedStrings().label.COMMON.ADD +
															" " +
															labelName}
													</Button>
												</div>
											)}
											{!(object === constants.APPOINTMENTS) &&
												!(object === constants.ACCOUNTS_OBJECT) &&
												!(object === constants.CONTACTS_OBJECT) &&
												!objectName === constants.OPPORTUNITIES_OBJECT &&
												!(object === constants.CUSTOM_TABLE1_OBJECT) &&
												!(object === constants.CUSTOM_TABLE2_OBJECT) &&
												!(object === constants.CUSTOM_TABLE3_OBJECT) &&
												!(object === constants.CUSTOM_TABLE4_OBJECT) &&
												!(object === constants.SALES_LEADS_OBJECT) &&
												!(object === constants.RECYCLE_BIN_OBJECT) &&
												!(object === constants.SALES_OBJECT) &&
												!(object === constants.ORDERS_OBJECTS) &&
												!(object === constants.QUOTATION_OBJECT) &&
												!(object === constants.SECURITY_LEVEL_OBJECT) &&
												!(object === constants.ROLL_UP_OBJECT) &&
												!(object === constants.FIND_DUPLICATE_OBJECT) &&
												!(object === constants.SALES_ORDER_OBJECT) &&
												!(object === constants.PURCHASE_ORDER_OBJECT) &&
												!(objectName === constants.ACTIVITIES) &&
												!(objectName === constants.ONBOARDING_OBJECT) &&
												!(objectName === constants.CONVERSIONS) &&
												!(object === constants.RECYCLE_BIN_OBJECT) &&
												!(objectName === constants.PHONE_NUMBERS_OBJECT) &&
												object !== constants.AUDIT_QUESTIONS_OBJECT &&
												object !== constants.AUDIT_ANSWERS_OBJECT &&
												totalRecordCount !== 0 && (
													<Button
														// icon={<Icon style={{ fontWeight: 'bold' }}>add</Icon>}
														onClick={() => addNewRecord(constants.NEW)}
														style={{...styles.listViewPrimaryButton,  marginTop:'10px'}}
													>
														{getLocalizedStrings().label.COMMON.ADD +
															" " +
															labelName}
													</Button>
												)}
											{object === constants.APPOINTMENTS && (
												<div>
													<Button
														// icon={<Icon style={{ fontWeight: 'bold' }}>add</Icon>} //*
														onClick={() => addNewRecord(constants.NEW)}
														style={{...styles.listViewPrimaryButton,  marginTop:'10px'}}
													>
														{getLocalizedStrings().label.COMMON.ADD +
															" " +
															labelName}
													</Button>
												</div>
											)}
											{object === constants.ROLL_UP_OBJECT && (
												<div>
													<Button
														// icon={<Icon style={{ fontWeight: 'bold' }}>add</Icon>}
														onClick={() => addNewRecord(constants.NEW)}
														style={{...styles.listViewPrimaryButton,  marginTop:'10px'}} //*
													>
														{getLocalizedStrings().label.COMMON.ADD +
															" " +
															"Roll Up"}
													</Button>
												</div>
											)}
										{objectName === constants.UNITS_OBJECT && (

											<div>
												{app.me.franchise_industry != '' &&
													<Button
														onClick={() => loginToBase(constants.LOGIN_TO_BASE)}
														style={{...styles.listViewPrimaryButton,  marginTop:'10px'}}
													>
														{getLocalizedStrings().label.COMMON.LOGIN_TO_BASE}
													</Button>}

												{app.me.industry_flag == false && app.me.franchise_industry == '' && listView.isUnitBaseShow !== false &&

													<Button
														onClick={() => addUnitBase()}
														style={{...styles.listViewPrimaryButton,  marginTop:'10px'}}
													>
														{getLocalizedStrings().label.COMMON.PLUS_BASE}
													</Button>}
												
												<Button
													onClick={() => addBranchName()}
													style={{...styles.listViewPrimaryButton,  marginTop:'10px'}}
												>
													{getLocalizedStrings().label.COMMON.PLUS_BRANCH}
												</Button>
											</div>)}
										</div>
									)}
								{(tabObject === "search-results" ||
									tabObject === "advance-search-results") && (
									<div className="data_less_text">
										<div>
											<Button
												style={{...styles.listViewPrimaryButton,  marginTop:'10px'}}
												onClick={() => modifySearch()}
											>
												{" "}
												Modify Search
											</Button>
										</div>
									</div>
								)}
							</td>
						</tr>
					)}
				</table>
			</div>
		);
	} else {
		return (
			<div style={{ width: "100%", height: contentHeight }}>
				<div
					className="asset-loaderh"
					style={{ paddingTop: top, paddingLeft: "48%" }}
				>
					<div
						style={{
							...styles.assetLoaderContainer,
							height: 50,
							width: 50,
							padding: 7,
						}}
					>
						<ShowCircularProgress
							size={30}
							style={{ marginTop: "3", marginLeft: "3" }}
						/>
					</div>
				</div>
			</div>
		);
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		getAppDrawer: (isOpen, actionDialogName, actionType, data, callFrom) => {
			dispatch(
				getAppDrawer(isOpen, actionDialogName, actionType, data, callFrom)
			);
		},
	};
};
export default NoRecords;
