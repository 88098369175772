import { actions } from '..//constants/actions';

const emailReportInitialState = {
    emailReportData: {},
}
const emailReportReducer = (state = emailReportInitialState, action) => {
    switch (action.type) {
        case actions.EMAIL_REPORT_DATA_UPDATE:
            return {
                ...state,
                emailReportData: action.payload
            }
        default:
            return state;
    }
}
export default emailReportReducer;