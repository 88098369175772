import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import PopOver from '../components/PopOver';
import {styles} from '../../../services/constants/styles';
import {getAppDialog, setAppDialogActions} from '../../../services/actions/appContainerActions';
import {getIntParam, getStringParam, isEmail, isValidParam} from '../../../services/helper/parameterVerifier';
import {showCustomSnackBar} from '../../../services/actions/snackBarAction';
import {getLocalizedStrings} from '../../../services/constants/MultiLingual';
import {endPoints} from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import {useDispatch, useSelector} from 'react-redux';

const AutomationTestMail = ({data}) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const [email, setEmail] = useState(app.me.email || '');
    const [isCampListOpen, setIsCampListOpen] = useState(false);
    const [campaignId, setCampaignId] = useState(0);
    const [campaignName, setCampaignName] = useState('');
    const [touchNamesList, setTouchNamesList] = useState([]);
    const [sending, setSending] = useState(false);
    const [changeLog,setChangeLog] = useState({email:app.me.email || ''});

    useEffect(() => {
        dispatch(setAppDialogActions(getActions()));
        populateTouchNamesList();
    }, []);

    function getActions() {
        return [
            <Button
                primary={true}
                onClick={() => sendMail()}
                style={styles.primaryButton}
            >{getLocalizedStrings().label.MAIL.SEND_A_TEST_EMAIL}</Button>,
            <Button
                primary={true}
                onClick={() => dispatch(getAppDialog(false, null, null, null, null, null))}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CLOSE}</Button>,

        ];
    }

    function fnSetEmail(event) {
        setEmail(event.target.value);
        let log = changeLog;
        log.email = event.target.value;
        setChangeLog({...log});
        dispatch(setAppDialogActions(getActions()));
    }

    function sendMail() {
        let dialogData = data;
        let tempCampaignId = 0;
        if (isValidParam(dialogData)) {
            if (dialogData.length === 1) {
                tempCampaignId = dialogData[0].details[0]['autoresponder_id'];
            } else if (dialogData.length > 1) {
                tempCampaignId = campaignId;
            }
            let tempEmail = getStringParam(changeLog.email).trim();
            if (tempEmail.length === 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.BLANK_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else if (!isEmail(tempEmail)) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.INVALID_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
                let params = {
                    campaign_id: tempCampaignId,
                    email: tempEmail,
                    testMailType: 'marketing'
                }
                let promise = Promise.resolve(HTTPClient.post(endPoints.SEND_MAIL.TEST_AUTOMATION, params));
                setSending(true);
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response) && response.hasOwnProperty('status')) {
                            if (response.status === 0) {
                                setSending(false);
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                dispatch(getAppDialog(false, null, null, null, null, null));
                            } else if (response.status > 0) {
                                setSending(false);
                                dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            } else {
                                setSending(false);
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            }
                        }
                    })
                }
            }
        }
    }

    function populateTouchNamesList() {
        let touchNamesList = [];
        let dialogData = data;
        let campaignId = 0;
        let campaignName = '';
        try {
            if (dialogData && dialogData.length > 1) {
                dialogData.forEach((objDialogData, i) => {
                    if (isValidParam(objDialogData)) {
                        if (i === 0) {
                            campaignId = objDialogData.details[0]['autoresponder_id'];
                            campaignName = objDialogData.details[0]['name'];
                        }

                        let data = {};
                        data.label = objDialogData.details[0]['name'];
                        data.value = objDialogData.details[0]['autoresponder_id'];
                        touchNamesList.push(data);
                    }

                });
                setTouchNamesList(touchNamesList);
                setCampaignId(campaignId);
                setCampaignName(campaignName);
            }
        } catch (error) {
            console.error("Error in 'AutomationTestMail.js -> populateTouchNamesList()':" + error);
        }
    }

    function changeCampName(value) {
        try {
            if (isValidParam(value)) {
                let dialogData = data;
                if (isValidParam(dialogData) && dialogData.length > 1) {
                    let campaignObject = dialogData.filter(function (el) {
                        return el.details[0]['autoresponder_id'] === getIntParam(value)
                    });

                    setCampaignId(value);
                    setCampaignName(campaignObject[0].details[0]['name']);
                    setIsCampListOpen(false);
                }
            }
        } catch (error) {
            console.error("Error in 'AutomationTestMail.js -> changeCampName()':" + error);
        }
    }

    function handleCampListOpen(event) {
        event.preventDefault();
        //this.setState({ isCampListOpen: true, anchorEl: event.currentTarget });
        setIsCampListOpen(true);
    }


    let isShowCampaignList = false;
    let dialogData = data;
    if (isValidParam(dialogData) && dialogData.length > 1) {
        isShowCampaignList = true;
    }
    return (
        <div>
            {
                (isShowCampaignList) &&
                <div style={{paddingBottom: '15px'}}>
                    <div style={{
                        display: 'inline-block',
                        verticalAlign: 'top',
                        paddingTop: '10px',
                        paddingRight: '10px',
                    }}>
                        {getLocalizedStrings().label.AUTOMATION_DESIGNER.SELECT_A_CAMPAIGN}
                    </div>
                    <div
                        style={{
                            cursor: 'pointer',
                            fontSize: '20px',
                            width: '230px',
                            border: '1px solid #ccc',
                            backgroundColor: '#fff',
                            height: '38px',
                            paddingTop: '4px',
                            paddingLeft: '5px',
                            display: 'inline-block',
                        }}
                        onClick={handleCampListOpen}
                    >
                        <div
                            style={{
                                display: 'inline-block',
                                width: '197px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                fontSize: 18,
                                paddingTop: '3px',
                                paddingLeft: '3px'
                            }}
                            title={campaignName}>
                            {campaignName}
                        </div>

                    </div>

                    <PopOver
                        id={'modules'}
                        key={'modules'}
                        name={'modules'}
                        buttonEndIcon={"keyboard_arrow_down"}
                        buttonStyle={{fontWeight: 'bold', cursor: 'pointer', fontSize: '20px'}}
                        labelposition={"before"}
                        buttonLabel={""}
                        options={touchNamesList}
                        onclickAction={changeCampName}
                        isShowIcon={true}
                    />
                </div>
            }

            <div className='row'>
                <div className="col-sm-12 col-two">
                    <FormControl style={{width: '100%'}} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            key='email'
                            name='email'
                            value={changeLog.email}
                            onChange={(e) => fnSetEmail(e)}
                            label={getLocalizedStrings().label.MAIL.EMAIL_FOR_TESTING}
                            fullWidth={true}
                            className={"sf-fields-bg"}
                            autoComplete="new-password"
                            margin='dense'
                            size='small'
                        />
                    </FormControl>
                </div>
                <div className="col-sm-12 col-two">
                    {sending && <span style={{
                        display: 'inline-block',
                        fontSize: 15,
                        marginTop: '10px',
                        color: '#717171',
                        fontWeight: '600',
                        paddingLeft: 2
                    }}>{getLocalizedStrings().label.MAIL.SENDING_TEST_MAIL} </span>}
                </div>
            </div>
        </div>
    )
}

export default AutomationTestMail;
