import React from 'react';
import { styles } from '../../../services/constants/styles';
import { TextField, Select, FormControl, MenuItem, Button, InputLabel, Dialog } from '@mui/material';
import { isValidParam, getStringParam, getIntParam, getBooleanParam, getArrayParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { testSms } from '../../../services/actions/sendMailActions';
import {
	constants,
	COUNTRY_CODE_MAP,
} from "../../../services/constants/constants";


const TestSms =(data)=>{
    const dispatch = useDispatch();
	const [sms_message, setSms_message] = useState(data.data.sms_message);
    const [id,setId] = useState(data.data.id);
    const [name,setName] = useState(data.data.name);
    const [toNumber,setToNumber] = useState('');
    const [smsType, setSmsType] = useState(data.data?.sms_type);
    const [sending,setSending] = useState(false);
    const appState = useSelector((state) => state.app);

    const onChangeSetSmsText = (e) =>{
        let PhNumber = e.target.value;
        setToNumber(PhNumber);
    }

    const sendSms = () =>{
        let _toNumber = toNumber;
        let params={};
        let country_code = COUNTRY_CODE_MAP[appState.me.tenant_country];
        let firstIndexValue = _toNumber.charAt(0);
        if(firstIndexValue != '+' && _toNumber.length !== 0){
            _toNumber = country_code.concat(_toNumber);
        }
        if(_toNumber.length === 0){
            dispatch(showCustomSnackBar(getLocalizedStrings().message.AUTOMATION_DESIGNER.ENTER_MOBILE_NO, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
        else{
            params.to_number = _toNumber;
            if(smsType!= null && smsType == "campaignSMS"){
                params.sms_type = smsType;
                params.id = id;
            }else {
                params.sms_text = sms_message;
            }
            var promise = testSms(params);
            setSending(true);
            if (isValidParam(promise)) {
                promise.then((response)=>{
                    if(isValidParam(response)){
                        if(response.status == 0){
                            setSending(false);
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.SMS_TEMPLATE.SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            dispatch(getAppDialog(false, null, null, null, null, null));                        
                        }else if (response.status == -1) {
                            setSending(false);
                            dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        }else {
                            setSending(false);
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        }
                    }
                })
            }
        }

    }

    const getActionButtons = () =>{
        const actions = [
            <Button
                primary={true}
                onClick={() => sendSms()}
                style={styles.primaryButton}
            > {getLocalizedStrings().label.SMS.SEND_A_TEST_SMS}</Button>,
            <Button

                primary={true}
                onClick={() => dispatch(getAppDialog(false, null, null, null, null, null))}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CLOSE}</Button>,

        ];
        return actions;
    }


    return(
        <div style={{width:'100%',height:'100%'}}>
            <div style={{width:'100%',height:'100%',display:'inline'}}>
                <div className="col-sm-12 col-two">
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            key='sms'
                            name='sms'
                            // type='number'
                            value={toNumber}
                            onChange={(e) => onChangeSetSmsText(e)}
                            underlineFocusStyle={styles.textField.underlineFocus}
                            label={getLocalizedStrings().label.SMS.ENTER_NUMBER}
                            fullWidth={true}
                            className={"sf-fields-bg"}
                            size= 'small'
                            autoComplete="new-password"
                            margin='dense'
                        /></FormControl>
                </div>
                <div style={{float:'right',marginTop:'25px'}}>
                    {getActionButtons()}
                </div>
            </div>
        </div>
    )
} 

export default TestSms;