import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants } from '../../../services/constants/constants';
import TagAutocomplete from '../../sfPlugins/tagAutocomplete';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';

const InvoiceRecipientsDailog = ({ object }) => {
    const dispatch = useDispatch();
    const [tags, setTags] = useState('')
    const [contactEmail, setContactEmail] = useState('')

    const [changeLog, setChangeLog] = useState({
        clientId: "",
        tagEmails: '',
    })
    const appContainer = useSelector((state) => state.appContainer);
	const reduxListView = useSelector((state) => state.listView);

    useEffect(() => {
        let _myClientId = '';
        let selectedRowId = reduxListView.selectedRows[0].id;

        let _params = {
            object: constants.ACCOUNTS_OBJECT,
            id: selectedRowId,
            callFrom: constants.UNITS_OBJECT,
        }
        var response = Promise.resolve(HTTPClient.get(endPoints.COMMON.GET_MYCLIENTID, _params));
        response.then((response) => {
            if (response.status == 0) {
                _myClientId = response.myClientId;
                   let temp = changeLog;
                   temp.clientId = _myClientId
                   setChangeLog({...temp})
                   getInvoiceRecipients(_myClientId)
            }
        });
    }, []);

const handleInputChange =()=>{

}
    const getInvoiceRecipients = (_myClientId) => {
        let selectedRowId = reduxListView.selectedRows[0].id;
        let params = {
            unitId: _myClientId,
            recordId: selectedRowId
        };
        let promise = Promise.resolve(HTTPClient.get(endPoints.INVOICE_RECIPIENTS.GET, params));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if(response.status == 0){
                    if (response.emails.length ==0){
                        setTags('')
                        let contact_email = response.contactEmails.join(",") ;
                        setContactEmail(contact_email)
                    }else{
                        let _tag = response.emails.join(",");
                        setTags(_tag);
                           
                    }
                 
                }
               
              
            });
        }

    }

    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const addEmail = (allTag, newTag) => {
        let _tag = allTag
        setTags(_tag)

    }
 const onClick =()=>{
     if(tags.length == 0){
      setTags(contactEmail)
     }
 
 }

    const removeMail = (allTag, removedTag) => {
        let _tag = allTag
        setTags(_tag)
    }

    const getActions = () => {
        const actions = [
            <Button

                primary={true}
                onClick={() => handleSaveEmail()}
                style={{ ...styles.primaryButton, float : 'right', marginRight :''}}
            >{getLocalizedStrings().label.COMMON.SAVE}</Button>,
            <Button

                primary={true}
                onClick={() => handleClose()}
                style={{...styles.secondaryButton, float : 'right',}}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,

        ];

        return actions;
    }

    const handleSaveEmail = () => {
        let _allEmail = []
         _allEmail = tags?.split(',');
        let isDuplicateEmail = _allEmail.some((val, i) => _allEmail.indexOf(val) !== i);
        if(isDuplicateEmail == false){
            let params = {
                unitId: changeLog.clientId,
                emails: _allEmail
            };
            if(_allEmail.length == 1 && _allEmail[0]==''){
                dispatch(showCustomSnackBar("Please enter at least one recipient.", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
            else{
                let promise = Promise.resolve(HTTPClient.post(endPoints.INVOICE_RECIPIENTS.SAVE, params));
                if (isValidParam(promise)) {
                   promise.then((response) => {
                       dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                       dispatch(getAppDialog(false, null, null, null, null, null));
                   
                   });
               }
            }
        }
          else{
            dispatch(showCustomSnackBar("Please remove duplicate email id", styles.snackbarBodyStyleError, styles.snackBarStyleTop));

          }
                    
    }

    const inputRestriction = (event) => {
        if ((event.key == ',') || (event.key == ' ')) {
            event.preventDefault();
            return false;
        }
        else {
            return true;
        }
    }
    return (
        <div>
            <div className="row">
                <div>
                    <div style={{ width: '100%' }} onKeyDown={(event) => inputRestriction(event)}>
                        <div style={{ display: 'flex' }}>
                            <TagAutocomplete
                                //ref={reactTagsBcc}
                                //label={'Bcc'}
                                rule='email'
                                selected={tags}
                                suggestions={[]}
                                onDelete={removeMail}
                                onAdd={addEmail}
                                onInputClick={onClick}
                                autoFocus={false}
                                allowNew={true}
                                onInput={handleInputChange}
                                minSearchLength={2}
                                isDisabled={false}
                                labelText=""
                                tagListLabelText=""
                                ariaAddedText=""
                                placeholderText='Enter the email addresses to send Royalty Invoices'
                            />

                        </div>
                    </div>

                </div>
<div>					{getActions()}
</div>
            </div>
        </div>
    );

}


export default InvoiceRecipientsDailog;