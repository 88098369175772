import React from "react";
import {
	isValidParam,
	getStringParam,
	getIntParam,
} from "..//helper/parameterVerifier";
import {
	INXPRESS_REPORT_MAP,
	constants,
	OBJECT_TABLEID_MAP,
} from "..//constants/constants";
import { getLocalizedStrings } from "..//constants/MultiLingual";

function generateLeadScoreIcon(totalScore, score) {
	let scoreIconFillColor = (
		<span>
			<i
				className="fa fa-star"
				aria-hidden="true"
				style={{ color: "#f9e970" }}
			></i>
		</span>
	);
	let scoreIconBlankColor = (
		<span>
			<i
				className="fa fa-star-o"
				aria-hidden="true"
				style={{ color: "#b5b5b5" }}
			></i>
		</span>
	);
	let percentageValue = 0;
	let iconArr = [];
	try {
		percentageValue = (score / totalScore) * 100;
		if (percentageValue < 1) {
			iconArr.push(scoreIconBlankColor);
			iconArr.push(scoreIconBlankColor);
			iconArr.push(scoreIconBlankColor);
			iconArr.push(scoreIconBlankColor);
			iconArr.push(scoreIconBlankColor);
		} else if (percentageValue >= 1 && percentageValue < 21) {
			iconArr.push(scoreIconFillColor);

			iconArr.push(scoreIconBlankColor);
			iconArr.push(scoreIconBlankColor);
			iconArr.push(scoreIconBlankColor);
			iconArr.push(scoreIconBlankColor);
		} else if (percentageValue >= 21 && percentageValue < 41) {
			iconArr.push(scoreIconFillColor);
			iconArr.push(scoreIconFillColor);

			iconArr.push(scoreIconBlankColor);
			iconArr.push(scoreIconBlankColor);
			iconArr.push(scoreIconBlankColor);
		} else if (percentageValue >= 41 && percentageValue < 61) {
			iconArr.push(scoreIconFillColor);
			iconArr.push(scoreIconFillColor);
			iconArr.push(scoreIconFillColor);

			iconArr.push(scoreIconBlankColor);
			iconArr.push(scoreIconBlankColor);
		} else if (percentageValue >= 61 && percentageValue < 81) {
			iconArr.push(scoreIconFillColor);
			iconArr.push(scoreIconFillColor);
			iconArr.push(scoreIconFillColor);
			iconArr.push(scoreIconFillColor);

			iconArr.push(scoreIconBlankColor);
		} else if (percentageValue >= 81) {
			iconArr.push(scoreIconFillColor);
			iconArr.push(scoreIconFillColor);
			iconArr.push(scoreIconFillColor);
			iconArr.push(scoreIconFillColor);
			iconArr.push(scoreIconFillColor);
		} else {
			iconArr.push(scoreIconBlankColor);
			iconArr.push(scoreIconBlankColor);
			iconArr.push(scoreIconBlankColor);
			iconArr.push(scoreIconBlankColor);
			iconArr.push(scoreIconBlankColor);
		}
	} catch (error) {
		console.error("Error in 'utils.js -> generateLeadScoreIcon()': " + error);
	}
	return iconArr;
}

function fetchAutomationIcon(actionName, actionsArray) {
	let iconName = "fa fa-circle";
	try {
		if (isValidParam(actionName) && isValidParam(actionsArray)) {
			actionsArray.forEach((actionObject, i) => {
				if (actionObject.actionName === actionName) {
					iconName = actionObject.iconName;
				}
			});
		}
	} catch (error) {
		console.error("Error in 'utils.js -> fetchAutomationIcon()': " + error);
	}
	return iconName;
}

function removeCurrency(value) {
	try {
		if (isValidParam(value)) {
			value = value.toString();
			if (value.indexOf("€") >= 0) {
				value = value.replace("€", "");
				value = value.replace(/\./g, "");
				if (value.indexOf(",") >= 0) {
					value = value.replace(",", ".");
				}
			} else if (value.indexOf("R$") >= 0) {
				value = value.replace("R$", "");
				value = value.replace(/\./g, "");
				if (value.indexOf(",") >= 0) {
					value = value.replace(",", ".");
				}
			} else if (value.indexOf("HK$") >= 0) {
				value = value.replace("HK$", "");
				value = value.replace(/,/g, "");
			} else if (value.indexOf("NT$") >= 0) {
				value = value.replace("NT$", "");
				value = value.replace(/,/g, "");
			} else if (value.indexOf("$") >= 0) {
				value = value.replace("$", "");
				value = value.replace(/,/g, "");
			} else if (value.indexOf("￥") >= 0) {
				value = value.replace("￥", "");
				value = value.replace(/,/g, "");
			} else if (value.indexOf("kr") >= 0) {
				value = value.replace("kr", "");
				value = value.replace(/\s/g, "");
				value = value.replace(/\./g, "");
				if (value.indexOf(",") >= 0) {
					value = value.replace(",", ".");
				}
			} else if (value.indexOf("€") >= 0) {
				value = value.replace("€", "");
				value = value.replace(/\./g, "");
				if (value.indexOf(",") >= 0) {
					value = value.replace(",", ".");
				}
			} else if (value.indexOf("Ft") >= 0) {
				value = value.replace("Ft", "");
				value = value.replace(/\s/g, "");
				if (value.indexOf(",") >= 0) {
					value = value.replace(",", ".");
				}
			} else if (value.indexOf("Rs.") >= 0) {
				value = value.replace("Rs.", "");
				value = value.replace(/,/g, "");
			} else if (value.indexOf("MYR") >= 0) {
				value = value.replace("MYR", "");
				value = value.replace(/,/g, "");
			} else if (value.indexOf("zł") >= 0) {
				value = value.replace("zł", "");
				value = value.replace(/\s/g, "");
				if (value.indexOf(",") >= 0) {
					value = value.replace(",", ".");
				}
			} else if (value.indexOf("£") >= 0) {
				value = value.replace("£", "");
				value = value.replace(/,/g, "");
			} else if (value.indexOf("руб.") >= 0) {
				value = value.replace("руб.", "");
				value = value.replace(/\s/g, "");
				if (value.indexOf(",") >= 0) {
					value = value.replace(",", ".");
				}
			} else if (value.indexOf("R") >= 0) {
				value = value.replace("R", "");
				value = value.replace(/\s/g, "");
				value = value.replace(/,/g, "");
			} else if (value.indexOf("￦") >= 0) {
				value = value.replace("￦", "");
				value = value.replace(/\s/g, "");
				value = value.replace(/,/g, "");
			} else if (value.indexOf("SFr.") >= 0) {
				value = value.replace("SFr.", "");
				value = value.replace(/\s/g, "");
				value = value.replace(/[']/g, "");
			} else if (value.indexOf("TL") >= 0) {
				value = value.replace("TL", "");
				value = value.replace(/\s/g, "");
				value = value.replace(/\./g, "");
				if (value.indexOf(",") >= 0) {
					value = value.replace(",", ".");
				}
			}
		}
	} catch (e) {
		console.error(e);
	}
	return value;
}
function findNearest(array, target) {
    if (array.length === 0) return null;
    let nearest = array[0]; 
    let minDiff = Math.abs(target - nearest);
    array.forEach(num => {
        const diff = Math.abs(target - num);
        if (diff < minDiff) {
            minDiff = diff;
            nearest = num;
        }
    });

    return nearest;
}

const dateTimeFormat = {
	"MM/dd/yy hh:mm a": "MM/DD/YY hh:mm A",
	"MM/dd/yyyy hh:mm a": "MM/DD/YYYY hh:mm A",
	"MMM dd, yyyy HH:mm": "MMM DD, YYYY HH:mm",
	"MMM dd, yyyy hh:mm a": "MMM DD, YYYY hh:mm A",
	"dd MMM yyyy HH:mm": "DD MMM YYYY HH:mm",
	"dd/MM/yyyy HH:mm": "DD/MM/YYYY HH:mm",
	"dd MM yyyy hh:mm a": "DD MM YYYY hh:mm A",
	"dd MMM yyyy hh:mm a": "DD MMM YYYY hh:mm A",
};

const dateFormat = {
	"MM/dd/yy hh:mm a": "MM/DD/YY",
	"MM/dd/yyyy hh:mm a": "MM/DD/YYYY",
	"MMM dd, yyyy HH:mm": "MMM DD, YYYY",
	"MMM dd, yyyy hh:mm a": "MMM DD, YYYY",
	"dd MMM yyyy HH:mm": "DD MMM YYYY",
	"dd/MM/yyyy HH:mm": "DD/MM/YYYY",
	"dd MM yyyy hh:mm a": "DD MM YYYY",
	"dd MMM yyyy hh:mm a": "DD MMM YYYY",
};

const timeFormat = {
	"MM/dd/yy hh:mm a": "hh:mm A",
	"MM/dd/yyyy hh:mm a": "hh:mm A",
	"MMM dd, yyyy HH:mm": "HH:mm",
	"MMM dd, yyyy hh:mm a": "hh:mm A",
	"dd MMM yyyy HH:mm": "HH:mm",
	"dd/MM/yyyy HH:mm": "HH:mm",
	"dd MM yyyy hh:mm a": "hh:mm A",
	"dd MMM yyyy hh:mm a": "hh:mm A",
};

const dateTimeFormatFlatPicker = {
	"MM/dd/yy hh:mm a": "m/d/y h:i K",
	"MM/dd/yyyy hh:mm a": "m/d/Y h:i K",
	"MMM dd, yyyy HH:mm": "M d, Y H:i",
	"MMM dd, yyyy hh:mm a": "M d, Y h:i K",
	"dd MMM yyyy HH:mm": "d M Y H:i",
	"dd/MM/yyyy HH:mm": "d/m/Y H:i",
	"dd MM yyyy hh:mm a": "d m Y h:i K",
	"dd MMM yyyy hh:mm a": "d M Y h:i K",
};

const dateFormatFlatPicker = {
	"MM/dd/yy hh:mm a": "m/d/y",
	"MM/dd/yyyy hh:mm a": "m/d/Y",
	"MMM dd, yyyy HH:mm": "M d, Y",
	"MMM dd, yyyy hh:mm a": "M d, Y",
	"dd MMM yyyy HH:mm": "d M Y",
	"dd/MM/yyyy HH:mm": "d/m/Y",
	"dd MM yyyy hh:mm a": "d m Y",
	"dd MMM yyyy hh:mm a": "d M Y",
};

const timeFormatFlatPicker = {
	"MM/dd/yy hh:mm a": "h:i K",
	"MM/dd/yyyy hh:mm a": "h:i K",
	"MMM dd, yyyy HH:mm": "H:i",
	"MMM dd, yyyy hh:mm a": "h:i K",
	"dd MMM yyyy HH:mm": "H:i",
	"dd/MM/yyyy HH:mm": "H:i",
	"dd MM yyyy hh:mm a": "h:i K",
	"dd MMM yyyy hh:mm a": "h:i K",
};

const timeZoneWithGMT = {
	ART: "GMT+2:00",
	AST: "GMT-9:00",
	AGT: "GMT-3:00",
	ACT: "GMT+9:30",
	AET: "GMT+10:00",
	"Australia/Queensland": "GMT+10:00",
	"Australia/Perth": "GMT+8:00",
	BST: "GMT+6:00",
	BET: "GMT-3:00",
	CNT: "GMT-3:30",
	CAT: "GMT+2:00",
	CST: "GMT-6:00",
	CTT: "GMT+8:00",
	EAT: "GMT+3:00",
	EET: "GMT+2:00",
	EST: "GMT-5:00",
	"Europe/London": "GMT+1:00",
	ECT: "GMT+1:00",
	GMT: "GMT",
	HST: "GMT-10:00",
	IST: "GMT+5:30",
	IET: "GMT-5:00",
	JST: "GMT+9:00",
	MET: "GMT+3:30",
	MIT: "GMT-11:00",
	MST: "GMT-7:00",
	NET: "GMT+4:00",
	NST: "GMT+12:00",
	PST: "GMT-8:00",
	PLT: "GMT+5:00",
	PNT: "GMT-7:00",
	PRT: "GMT-4:00",
	SST: "GMT+11:00",
	UTC: "GMT",
	VST: "GMT+7:00",
	"Atlantic/Bermuda": "GMT-4:00",
};

const timeZone = {
	ART: "Africa/Cairo", // (Arabic) Egypt Standard Time (GMT+2:00)
	AST: "America/Nome", // Alaska Standard Time (GMT-9:00)
	AGT: "America/Argentina/Buenos_Aires", // Argentina Standard Time (GMT-3:00)
	ACT: "Australia/Adelaide", // Australia Central Time (GMT+9:30)
	AET: "Australia/Sydney", // Australia Eastern Time (GMT+10:00)
	"Australia/Queensland": "Australia/Brisbane", // Australia/Queensland (GMT+10:00)
	"Australia/Perth": "Australia/Perth", // Australia Western Time (GMT+8:00)
	BST: "Asia/Dhaka", // Bangladesh Standard Time (GMT+6:00)
	BET: "Brazil/East", // Brazil Eastern Time (GMT-3:00)
	CNT: "Canada/Newfoundland", // Canada Newfoundland Time (GMT-3:30)
	CAT: "Africa/Johannesburg", // Central African Time (GMT+2:00)
	CST: "US/Central", // Central Standard Time (GMT-6:00)  //
	CTT: "Asia/Macau", // China Taiwan Time (GMT+8:00)
	EAT: "Africa/Nairobi", // Eastern African Time (GMT+3:00)
	EET: "Europe/Athens", // Eastern European Time (GMT+2:00)
	EST: "America/New_York", // Eastern Standard Time (GMT-5:00)
	"Europe/London": "Europe/London", // Europe/London (GMT+1:00)
	ECT: "Europe/Paris", // European Central Time (GMT+1:00)
	GMT: "GMT", // Greenwich Mean Time(GMT)
	HST: "US/Hawaii", // Hawaii Standard Time(GMT-10:00)
	IST: "Asia/Kolkata", // India Standard Time (GMT+5:30)
	IET: "America/Indiana/Knox", // Indiana Eastern Standard Time (GMT-5:00)
	JST: "Asia/Tokyo", // Japan Standard Time (GMT+9:00)
	MET: "Asia/Bahrain", // Middle East Time (GMT+3:30)
	MIT: "Pacific/Midway", // Midway Islands Time (GMT-11:00)
	MST: "US/Mountain", // Mountain Standard Time (GMT-7:00)
	NET: "Asia/Dubai", // Near East Time (GMT+4:00)
	NST: "Pacific/Auckland", // New Zealand Standard Time (GMT+12:00)
	PST: "America/Los_Angeles", // Pacific Standard Time (GMT-8:00)
	PLT: "Asia/Karachi", // Pakistan Lahore Time (GMT+5:00)
	PNT: "America/Phoenix", // Phoenix Standard Time (GMT-7:00)
	PRT: "America/Puerto_Rico", // Puerto Rico and US Virgin Islands Time (GMT-4:00)
	SST: "Pacific/Noumea", // Solomon Standard Time (GMT+11:00)
	UTC: "UTC", // Universal Coordinated Time (GMT)
	VST: "Asia/Ho_Chi_Minh", // Vietnam Standard Time (GMT+7:00)
	"Atlantic/Bermuda": "Atlantic/Bermuda", // Atlantic Standard Time (GMT-4:00)
};

const timeZoneMap = {
	ART: "(Arabic) Egypt Time",
	AST: "Alaska Time",
	AGT: "Argentina Time",
	ACT: "Australia Central Time",
	AET: "Australia Eastern Time",
	"Australia/Queensland": "Australia/Queensland",
	"Australia/Perth": "Australia Western Time",
	BST: "Bangladesh Time",
	BET: "Brazil Eastern Time",
	CNT: "Canada Newfoundland Time",
	CAT: "Central African Time",
	CST: "Central Time",
	CTT: "China Taiwan Time",
	EAT: "Eastern African Time",
	EET: "Eastern European Time",
	EST: "Eastern Time",
	"Europe/London": "Europe/London",
	ECT: "European Central Time",
	GMT: "Greenwich Mean Time",
	HST: "Hawaii Time",
	IST: "India Time",
	IET: "Indiana Eastern Time",
	JST: "Japan Time",
	MET: "Middle East Time",
	MIT: "Midway Islands Time",
	MST: "Mountain Time",
	NET: "Near East Time",
	NST: "New Zealand Time",
	PST: "Pacific Time",
	PLT: "Pakistan Lahore Time",
	PNT: "Phoenix Time",
	PRT: "Puerto Rico and US Virgin Islands Time",
	SST: "Solomon Time",
	UTC: "Universal Coordinated Time",
	VST: "Vietnam Time",
	"Atlantic/Bermuda": "Atlantic Standard Time",
};

const showTimeZoneMap = {
	ACT: "Australia Central Time",
	AET: "Australia Eastern Time",
	AGT: "Argentina Standard Time",
	ART: "(Arabic) Egypt Standard Time",
	AST: "Alaska Standard Time",
	"Atlantic/Bermuda": "Atlantic Standard Time",
	"Australia/Perth": "Australia Western Time",
	"Australia/Queensland": "Australia/Queensland",
	BET: "Brazil Eastern Time",
	BST: "Bangladesh Standard Time",
	CAT: "Central African Time",
	CNT: "Canada Newfoundland Time",
	CST: "Central Standard Time",
	CTT: "China Standard Time",
	EAT: "Eastern African Time",
	ECT: "European Central Time",
	EET: "Eastern European Time",
	EST: "Eastern Standard Time",
	"Europe/London": "Europe/London",
	GMT: "Greenwich Mean Time(GMT)",
	HST: "Hawaii Standard Time",
	IET: "Indiana Eastern Standard Time",
	IST: "India Standard Time",
	JST: "Japan Standard Time",
	MET: "Middle East Time",
	MIT: "Midway Islands Time",
	MST: "Mountain Standard Time",
	NET: "Near East Time",
	NST: "New Zealand Standard Time",
	PLT: "Pakistan Lahore Time",
	PNT: "Phoenix Standard Time",
	PRT: "Puerto Rico and US Virgin Islands Time",
	PST: "Pacific Standard Time",
	SST: "Solomon Standard Time",
	UTC: "Universal Coordinated Time (GMT)",
	VST: "Vietnam Standard Time",
};

function groupByObjectArrayByProperty(objectArray, property) {
	return objectArray.reduce(function (acc, obj) {
		let key = obj[property];
		if (!acc[key]) {
			acc[key] = [];
		}
		acc[key].push(obj);
		return acc;
	}, {});
}

function sortArrayObjectByProperty(array, property) {
	if (Array.isArray(array)) {
		array.sort((obj1, obj2) => {
			let temp;
			if (
				isValidParam(obj1) &&
				obj1.hasOwnProperty(property) &&
				isValidParam(obj2) &&
				obj2.hasOwnProperty(property)
			) {
				let val1 = obj1[property];
				let val2 = obj2[property];

				val1 = typeof val1 === "string" ? val1.toLowerCase() : val1;
				val2 = typeof val2 === "string" ? val2.toLowerCase() : val2;

				if (val1 > val2) {
					temp = 1;
				} else if (val1 < val2) {
					temp = -1;
				} else {
					temp = 0;
				}
			}
			return temp;
		});
	}
}

function sortArrayObjectByPropertyForCurrency(array, property, currency) {
	let temp;
	if (Array.isArray(array)) {
		array.sort((obj1, obj2) => {
			if (
				isValidParam(obj1) &&
				obj1.hasOwnProperty(property) &&
				isValidParam(obj2) &&
				obj2.hasOwnProperty(property)
			) {
				let val1 = obj1[property];
				let val2 = obj2[property];

				val1 = typeof val1 === "string" ? val1.toLowerCase() : val1;
				val2 = typeof val2 === "string" ? val2.toLowerCase() : val2;

				val1 = val1.replace(currency, "");
				val1 = val1.replace(",", "");
				val1 = val1.replace(".", "");
				val1 = val1.trim();

				val2 = val2.replace(currency, "");
				val2 = val2.replace(",", "");
				val2 = val2.replace(".", "");
				val2 = val2.trim();
				val1 = getIntParam(val1);
				val2 = getIntParam(val2);

				if (val1 > val2) {
					temp = 1;
				} else if (val1 < val2) {
					temp = -1;
				} else {
					temp = 0;
				}
			}
			return temp;
		});
	}
}
function toCapitalize(word) {
	return word
		.split("")
		.map((letter, index) =>
			index ? letter.toLowerCase() : letter.toUpperCase()
		)
		.join("");
}

function makeTiteleCase(value) {
	let newValue = "";
	value = getStringParam(value);
	let arrValue = value.split(" ");
	arrValue.forEach((val) => {
		val = getStringParam(val).trim();
		val = val.length > 0 ? val.charAt(0).toUpperCase() + val.slice(1) : val;

		if (newValue === "") {
			newValue = val;
		} else {
			newValue += " " + val;
		}
	});
	return newValue;
}
/* eslint-disable no-control-regex */
function StripHtmlToText(result) {
	if (result !== null && result !== "") {
		try {
			var exp = new RegExp("(<( )*(/)( )*p( )*>)", "g");
			result = result.replace(exp, "%%%%");

			exp = new RegExp("(<( )*(/)( )*P( )*>)", "g");
			result = result.replace(exp, "%%%%");

			exp = new RegExp("(<( )*(/)( )*div( )*>)", "g");
			result = result.replace(exp, "%%%%");

			exp = new RegExp("(<( )*(/)( )*DIV( )*>)", "g");
			result = result.replace(exp, "%%%%");

			exp = new RegExp("(<( )*(/)( )*tr( )*>)", "g");
			result = result.replace(exp, "%%%%");

			exp = new RegExp("(<( )*(/)( )*TR( )*>)", "g");
			result = result.replace(exp, "%%%%");

			exp = new RegExp("(<( )*(/)( )*li( )*>)", "g");
			result = result.replace(exp, "%%%%");

			exp = new RegExp("(<( )*(/)( )*LI( )*>)", "g");
			result = result.replace(exp, "%%%%");

			exp = new RegExp("<( )*br( )*>", "g");
			result = result.replace(exp, "%%%%");

			exp = new RegExp("<( )*BR( )*>", "g");
			result = result.replace(exp, "%%%%");

			exp = new RegExp("<( )*br([^>])*>", "g");
			result = result.replace(exp, "%%%%");

			exp = new RegExp("<( )*BR([^>])*>", "g");
			result = result.replace(exp, "%%%%");

			exp = new RegExp("<.*?>", "g");
			result = result.replace(exp, "");

			result = result.replace(/<[^<|>]+?>/gi, "");

			result = result.replace(/\r/gm, "");
			result = result.replace(/\n/gm, "");

			exp = new RegExp("\t", "g");
			result = result.replace(exp, "");
			result = result.replace(/&nbsp;/g, " ");
			//result = format_content(result);
			result = result.replace(/%%%%/g, "\n");
		} catch (ex) {}
	}
	return result;
}

/* eslint-disable no-useless-escape */
function retriveEscapeHtml(unsafe) {
	if (typeof unsafe === "string") {
		return unsafe
			.replace(/&amp;/g, "&")
			.replace(/&lt;/g, "<")
			.replace(/&gt;/g, ">")
			.replace(/&quot;/g, '"')
			.replace(/&#039;/g, "'")
			.replace(/&#092;/g, "\\")
			.replace(/&#047;/g, "/");
	} else {
		return unsafe;
	}
}

function getSalesObjectListForHome(objectList, moduleItems) {
	let arrangedByObject = [
		"accounts",
		"contacts",
		"opportunities",
		"products",
		"orders",
		"customobjectone",
		"customobjecttwo",
		"customobjectthree",
		"customobjectfour",
		"salesleads",
	];
	let salesObjectName = [];
	let objects = null;
	moduleItems.forEach((obj) => {
		if (arrangedByObject.indexOf(obj.object) >= 0) {
			salesObjectName.push(obj.object);
		}
	});

	objects = objectList.filter((f) => {
		return salesObjectName.indexOf(f.name) >= 0;
	});
	return objects;
}

function phoneFormat(phval) {
	var enterphno = "";
	phval = getStringParam(phval);
	if (isValidParam(phval) && phval.length === 10) {
		var result = -1;
		var numbers = /^[0-9]+$/;
		result = phval.search(numbers);
		if (result !== -1 && phval.length === 10) {
			enterphno =
				phval.substring(0, 3) +
				"-" +
				phval.substring(3, 6) +
				"-" +
				phval.substring(6, 10);
		} else {
			enterphno = phval;
		}
	} else {
		enterphno = phval;
	}
	return enterphno;
}

function getDateObject(obj) {
	let dateObject = null;
	try {
		if (isValidParam(obj)) {
			let dateString =
				obj.years +
				"-" +
				(obj.months + 1) +
				"-" +
				obj.date +
				" " +
				obj.hours +
				":" +
				obj.minutes;
			dateObject = Date.parse(dateString);
		}
	} catch (error) {
		console.error("Error in 'utils.js -> getDateObject()': " + error);
	}

	return dateObject;
}

function isEmailExistsInList(array, email) {
	let response = false;
	array.forEach(function (obj) {
		if (obj.value === email) {
			response = true;
		}
	});
	return response;
}

function sortArrayOfString(array) {
	let temp;
	if (Array.isArray(array)) {
		array.sort((obj1, obj2) => {
			if (obj1.toLowerCase() > obj2.toLowerCase()) {
				temp = 1;
			} else if (obj1.toLowerCase() < obj2.toLowerCase()) {
				temp = -1;
			} else {
				temp = 0;
			}
			return temp;
		});
	}
}

function sortArrayObjectByIntegerProperty(array, property, orderType) {
	let temp;
	if (Array.isArray(array)) {
		array.sort((obj1, obj2) => {
			if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
				if(orderType === 'asc'){
					if (parseInt(obj1[property]) > parseInt(obj2[property])) {
						temp = 1;
					} else if (parseInt(obj1[property]) < parseInt(obj2[property])) {
						temp = -1;
					} else {
						temp = 0;
					}
				} else {
					if (parseInt(obj1[property]) < parseInt(obj2[property])) {
						temp = 1;
					} else if (parseInt(obj1[property]) > parseInt(obj2[property])) {
						temp = -1;
					} else {
						temp = 0;
					}
				}				
			}
			return temp;
		});
	}
}

function sortArrayObjectByDateProperty(array, property) {
	let temp;
	if (Array.isArray(array)) {
		array.sort((obj1, obj2) => {
			if (
				isValidParam(obj1) &&
				obj1.hasOwnProperty(property) &&
				isValidParam(obj2) &&
				obj2.hasOwnProperty(property)
			) {
				let date1 = new Date(obj1[property]);
				let date2 = new Date(obj2[property]);
				if (date1 > date2) {
					temp = 1;
				} else if (date1 < date2) {
					temp = -1;
				} else {
					temp = 0;
				}
			}
			return temp;
		});
	}
}

function sortArrayObjectByDatePropertyDescending(array, property) {
	let temp;
	if (Array.isArray(array)) {
		array.sort((obj1, obj2) => {
			if (
				isValidParam(obj1) &&
				obj1.hasOwnProperty(property) &&
				isValidParam(obj2) &&
				obj2.hasOwnProperty(property)
			) {
				let date1 = new Date(obj1[property]);
				let date2 = new Date(obj2[property]);
				if (date1 < date2) {
					temp = 1;
				} else if (date1 > date2) {
					temp = -1;
				} else {
					temp = 0;
				}
			}
			return temp;
		});
	}
}
function moveArrayItemToNewIndex(arr, old_index, new_index) {
	if (new_index >= arr.length) {
		var k = new_index - arr.length + 1;
		while (k--) {
			arr.push(undefined);
		}
	}
	arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
	return arr;
}
function getKey(obj, value) {
	let rtnKey = "";
	if (isValidParam(obj) && isValidParam(value)) {
		for (let key in obj) {
			if (obj[key] === value) {
				rtnKey = key;
			}
		}
	}
	return rtnKey;
}
/** added by Priyanka on 21.02.18 for inxpress reports */

function getInXpressReportId(reportTypeName) {
	let reportId = null;
	try {
		if (isValidParam(reportTypeName)) {
			if (reportTypeName === "InXpress Report 1") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_ONE;
			} else if (reportTypeName === "InXpress Report 2") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_TWO;
			} else if (reportTypeName === "InXpress Report 3") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_THREE;
			} else if (reportTypeName === "InXpress Report 4") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_FOUR;
			} else if (reportTypeName === "InXpress Report 5") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_FIVE;
			} else if (reportTypeName === "InXpress Report 6") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_SIX;
			} else if (reportTypeName === "InXpress Report 7") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_SEVEN;
			} else if (reportTypeName === "InXpress Report 8") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_EIGHT;
			} else if (reportTypeName === "InXpress Report 9") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_NINE;
			} else if (reportTypeName === "InXpress Report 10") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_TEN;
			} else if (reportTypeName === "InXpress Report 11") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_ELEVEN;
			} else if (reportTypeName === "InXpress Report 12") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_TWELVE;
			} else if (reportTypeName === "InXpress Report 13") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_THIRTEEN;
			} else if (reportTypeName === "InXpress Report 14") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_FOURTEEN;
			} else if (reportTypeName === "InXpress Report 15") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_FIFTEEN;
			} else if (reportTypeName === "InXpress Report 16") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_SIXTEEN;
			} else if (reportTypeName === "InXpress Report 17") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_SEVENTEEN;
			} else if (reportTypeName === "InXpress Report 18") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_EIGHTEEN;
			} else if (reportTypeName === "InXpress Report 19") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_NINETEEN;
			} else if (reportTypeName === "InXpress Report 20") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_TWENTY;
			} else if (reportTypeName === "InXpress Report 21") {
				reportId = INXPRESS_REPORT_MAP.TYPE_INXPRESS_REPORT_TWENTYONE;
			}
		}
	} catch (error) {
		console.error("Error in 'util.js -> getInXpressReportId()':" + error);
	}
	return reportId;
}

function escapeHtml(unsafe) {
	return unsafe
		.replace(/&/g, "&amp;")
		.replace(/</g, "&lt;")
		.replace(/>/g, "&gt;")
		.replace(/"/g, "&quot;")
		.replace(/'/g, "&#039;");
}

function sortArrayObjectByFloatProperty(array, property) {
	let temp;
	if (Array.isArray(array)) {
		array.sort((obj1, obj2) => {
			if (
				isValidParam(obj1) &&
				obj1.hasOwnProperty(property) &&
				isValidParam(obj2) &&
				obj2.hasOwnProperty(property)
			) {
				var regex = /\d+./g;
				let f1 = obj1[property].match(regex);
				f1 = f1 + "";
				f1 = f1.replace(/,/g, "");

				let f2 = obj2[property].match(regex);
				f2 = f2 + "";
				f2 = f2.replace(/,/g, "");

				if (parseFloat(f1) > parseFloat(f2)) {
					temp = 1;
				} else if (parseFloat(f1) < parseFloat(f2)) {
					temp = -1;
				} else {
					temp = 0;
				}
			}
			return temp;
		});
	}
}

/* function isNotificationExistsInList(array, notification) {
	let response = false;
	if (array.length > 0 && isValidParam(notification)) {
		array.forEach(function (obj) {
			if (obj.id === notification.id) {
				response = true;
			}
		});
	}
	return response;
} */

function openWindow(params) {
	let newWindow = null;
	if (isValidParam(params)) {
		let location = getStringParam(params.location);
		let name = getStringParam(params.name);
		let specs = getStringParam(params.specs);
		let width = getIntParam(params.width);
		let height = getIntParam(params.height);

		if (width > 0 && height > 0) {
			let dualScreenLeft =
				window.screenLeft !== undefined ? window.screenLeft : window.screenX;
			let dualScreenTop =
				window.screenTop !== undefined ? window.screenTop : window.screenY;

			let w = window.innerWidth
				? window.innerWidth
				: document.documentElement.clientWidth
				? document.documentElement.clientWidth
				: window.screen.width;
			let h = window.innerHeight
				? window.innerHeight
				: document.documentElement.clientHeight
				? document.documentElement.clientHeight
				: window.AbortSignalscreen.height;

			let left = w / 2 - width / 2 + dualScreenLeft;
			let top = h / 2 - height / 2 + dualScreenTop;
			specs +=
				"width=" +
				width +
				", height=" +
				height +
				", top=" +
				top +
				", left=" +
				left;
		}

		newWindow = window.open("", "", specs);
		newWindow.location = location;
		if (name !== "") {
			newWindow.name = name;
		}
	}
	return newWindow;
}

function getMultilingualLabelName(object, label) {
	let labelName = null;
	try {
		if (object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
			labelName = isValidParam(
				getLocalizedStrings().label.LIST_VIEW_HEADER.BROADCAST[label]
			)
				? getLocalizedStrings().label.LIST_VIEW_HEADER.BROADCAST[label]
				: label;
		} else if (object === constants.SALES_TEMPLATE_OBJECT) {
			labelName =
				getLocalizedStrings().label.LIST_VIEW_HEADER.SALES_TEMPLATE.hasOwnProperty(
					label
				)
					? getLocalizedStrings().label.LIST_VIEW_HEADER.SALES_TEMPLATE[label]
					: label;
		} else if (object === constants.MARKETING_TEMPLATE_OBJECT) {
			labelName =
				getLocalizedStrings().label.LIST_VIEW_HEADER.MARKETING_TEMPLATE.hasOwnProperty(
					label
				)
					? getLocalizedStrings().label.LIST_VIEW_HEADER.MARKETING_TEMPLATE[
							label
					  ]
					: label;
		} else if (object === constants.WEB_FORMS) {
			labelName =
				getLocalizedStrings().label.LIST_VIEW_HEADER.WEB_FORM.hasOwnProperty(
					label
				)
					? getLocalizedStrings().label.LIST_VIEW_HEADER.WEB_FORM[label]
					: label;
		} else if (object === constants.AUTOMATION_DESIGNER_OBJECT) {
			labelName = isValidParam(
				getLocalizedStrings().label.LIST_VIEW_HEADER.AUTOMATION_DESIGNER[label]
			)
				? getLocalizedStrings().label.LIST_VIEW_HEADER.AUTOMATION_DESIGNER[
						label
				  ]
				: label;
		} else if (object === constants.APPOINTMENTS) {
			labelName = isValidParam(
				getLocalizedStrings().label.LIST_VIEW_HEADER.APPOINTMENT[label]
			)
				? getLocalizedStrings().label.LIST_VIEW_HEADER.APPOINTMENT[label]
				: label;
		} else if (object === constants.TASKS) {
			labelName = isValidParam(
				getLocalizedStrings().label.LIST_VIEW_HEADER.TASKS[label]
			)
				? getLocalizedStrings().label.LIST_VIEW_HEADER.TASKS[label]
				: label;
		} else if (object === constants.TOUCH_CAMPAIGNS_OBJECT) {
			labelName =
				getLocalizedStrings().label.LIST_VIEW_HEADER.TOUCH_CAMPAIGNS.hasOwnProperty(
					label
				)
					? getLocalizedStrings().label.LIST_VIEW_HEADER.TOUCH_CAMPAIGNS[label]
					: label;
		} else if (object === constants.AB_CAMPAIGNS_OBJECT) {
			labelName = isValidParam(
				getLocalizedStrings().label.LIST_VIEW_HEADER.AB_CAMPAIGN[label]
			)
				? getLocalizedStrings().label.LIST_VIEW_HEADER.AB_CAMPAIGN[label]
				: label;
		} else if (object === constants.ACCOUNTS_OBJECT) {
			if (label === "Status") {
				labelName = isValidParam(
					getLocalizedStrings().label.LIST_VIEW_HEADER.ACCOUNTS[label]
				)
					? getLocalizedStrings().label.LIST_VIEW_HEADER.ACCOUNTS[label]
					: label;
			} else {
				labelName = label;
			}
		} else if (object === constants.CONTACTS_OBJECT) {
			if (label === "Status") {
				labelName = isValidParam(
					getLocalizedStrings().label.LIST_VIEW_HEADER.CONTACTS[label]
				)
					? getLocalizedStrings().label.LIST_VIEW_HEADER.CONTACTS[label]
					: label;
			} else {
				labelName = label;
			}
		} else if (object === constants.OPPORTUNITIES_OBJECT) {
			if (label === "Status") {
				labelName = isValidParam(
					getLocalizedStrings().label.LIST_VIEW_HEADER.OPPORTUNITIES[label]
				)
					? getLocalizedStrings().label.LIST_VIEW_HEADER.OPPORTUNITIES[label]
					: label;
			} else {
				labelName = label;
			}
		} else if (object === constants.PROJECTS_OBJECT) {
			if (label === "Status") {
				labelName = isValidParam(
					getLocalizedStrings().label.LIST_VIEW_HEADER.PROJECTS[label]
				)
					? getLocalizedStrings().label.LIST_VIEW_HEADER.PROJECTS[label]
					: label;
			} else {
				labelName = label;
			}
		} else if (object === constants.ISSUES_OBJECT) {
			if (label === "Status") {
				labelName = isValidParam(
					getLocalizedStrings().label.LIST_VIEW_HEADER.ISSUES[label]
				)
					? getLocalizedStrings().label.LIST_VIEW_HEADER.ISSUES[label]
					: label;
			} else {
				labelName = label;
			}
		} else if (object === constants.SOLUTIONS_OBJECT) {
			if (label === "Status") {
				labelName = isValidParam(
					getLocalizedStrings().label.LIST_VIEW_HEADER.SOLUTIONS[label]
				)
					? getLocalizedStrings().label.LIST_VIEW_HEADER.SOLUTIONS[label]
					: label;
			} else {
				labelName = label;
			}
		} else if (object === constants.CASES_OBJECT) {
			if (label === "Status") {
				labelName = isValidParam(
					getLocalizedStrings().label.LIST_VIEW_HEADER.CASES[label]
				)
					? getLocalizedStrings().label.LIST_VIEW_HEADER.CASES[label]
					: label;
			} else {
				labelName = label;
			}
		} else if (object === constants.SALES_LEADS_OBJECT) {
			if (label === "Status") {
				labelName = isValidParam(
					getLocalizedStrings().label.LIST_VIEW_HEADER.SALES_LEADS[label]
				)
					? getLocalizedStrings().label.LIST_VIEW_HEADER.SALES_LEADS[label]
					: label;
			} else {
				labelName = label;
			}
		} else {
			labelName = label;
		}
	} catch (error) {
		console.error("Error in 'utils.js -> getMultilingualLabelName()':" + error);
	}
	return labelName;
}

function getMultilingualNoteTypes(object, fieldName, listValues) {
	let arrayFieldValues = [];
	try {
		if (object === constants.NOTE_OBJECT && fieldName === "note_type") {
			listValues.forEach((objecValue, i) => {
				if (
					objecValue.value === "Appointment" ||
					objecValue.value === "Dialed" ||
					objecValue.value === "Email" ||
					objecValue.value === "LVM" ||
					objecValue.value === "Note" ||
					objecValue.value === "Spoke" ||
					objecValue.value === "Task" ||
					objecValue.value === "Transferred"
				) {
					objecValue.value =
						getLocalizedStrings().label.DEFAULT_NOTE_TYPES[objecValue.value];
				}
			});
		}
		arrayFieldValues = listValues;
	} catch (error) {
		console.error("Error in 'utils.js -> getMultilingualNoteTypes()':" + error);
	}
	return arrayFieldValues;
}

const getKeyByValue = (obj, value) =>
	Object.keys(obj).find((key) => obj[key] === value);

function getUniqueId() {
	let uId = null;
	try {
		uId = new IDGenerator().generate();
	} catch (error) {
		console.error("Error in 'utils.js -> getUniqueId()':" + error);
	}
	return uId;
}

function formatDate(date) {
	if (isValidParam(date)) {
		let d = date instanceof Date ? date : new Date(date);
		let month = "" + (d.getMonth() + 1);
		let day = "" + d.getDate();
		let year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("-");
	}
	return null;
}

function getChildObjectCustomTitle(parentObject, childObject, linkchildInfo) {
	parentObject = getStringParam(parentObject);
	childObject = getStringParam(childObject);
	let childTitle = "";
	if (parentObject !== "" && childObject !== "" && linkchildInfo !== null) {
		let parentTableId = OBJECT_TABLEID_MAP[parentObject];
		let linkchildInfoArray = linkchildInfo[parentTableId];
		let linkchildObj = linkchildInfoArray.filter((obj) => {
			return obj.objectName === childObject;
		});
		childTitle = getStringParam(linkchildObj[0].title);
	}
	return childTitle;
}

function reverseFormatNumber(val, locale) {
	val = val.toString();
	var group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
	var decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
	var reversedVal = val.indexOf(group)>-1 ? val.replace(new RegExp("\\" + group, "g"), ""):val;
	reversedVal = reversedVal.indexOf(decimal)>-1 ? reversedVal.replace(new RegExp("\\" + decimal, "g"), "."):reversedVal;
	return Number.isNaN(reversedVal) ? 0 : reversedVal;
}

function formatCurrency(price) {
	price = isValidParam(price) && price !== "undefined" ? price : 0;
	let formattedValue = null;
	try {
		const formatter = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 2,
		});
		formattedValue = formatter.format(price);
		formattedValue = formattedValue.replace("$", "");
	} catch (error) {
		console.error("Error in 'addQuotes.js --> formatCurrency()':" + error);
	}
	return formattedValue;
}

function getBeeEditorFontList() {
	let fontList = [];
	try {
		fontList = [
			{
				name: "Arial",
				fontFamily: "Arial, 'Helvetica Neue', Helvetica, sans-serif",
			},
			{
				name: "Bitter",
				fontFamily: "'Bitter', Georgia, Times, 'Times New Roman', serif",
				url: "https://fonts.googleapis.com/css?family=Bitter",
			},
			{
				name: "Courier",
				fontFamily:
					"'Courier New', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace",
			},
			{
				name: "Georgia",
				fontFamily: "Georgia, Times, 'Times New Roman', serif",
			},
			{
				name: "Helvetica",
				fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
			},
			{
				name: "Lucida Sans",
				fontFamily:
					"'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Geneva, Verdana, sans-serif",
			},
			{
				name: "Tahoma",
				fontFamily: "Tahoma, Verdana, Segoe, sans-serif",
			},
			{
				name: "Times New Roman",
				fontFamily:
					"TimesNewRoman, 'Times New Roman', Times, Beskerville, Georgia, serif",
			},
			{
				name: "Trebuchet MS",
				fontFamily:
					"'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif",
			},
			{
				name: "Verdana",
				fontFamily: "Verdana, Geneva, sans-serif",
			},
			{
				name: "Droid Serif",
				fontFamily: "'Droid Serif', Georgia, Times, 'Times New Roman', serif",
				url: "https://fonts.googleapis.com/css?family=Droid+Serif",
			},
			{
				name: "Lato",
				fontFamily: "'Lato', Tahoma, Verdana, Segoe, sans-serif",
				url: "https://fonts.googleapis.com/css?family=Lato",
			},
			{
				name: "Open Sans",
				fontFamily:
					"'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
				url: "https://fonts.googleapis.com/css?family=Open+Sans",
			},
			{
				name: "Roboto",
				fontFamily: "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
				url: "https://fonts.googleapis.com/css?family=Roboto",
			},
			{
				name: "Source Sans Pro",
				fontFamily: "'Source Sans Pro', Tahoma, Verdana, Segoe, sans-serif",
				url: "https://fonts.googleapis.com/css?family=Source+Sans+Pro",
			},
			{
				name: "Montserrat",
				fontFamily:
					"'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif",
				url: "https://fonts.googleapis.com/css?family=Montserrat",
			},
			{
				name: "Ubuntu",
				fontFamily: "'Ubuntu', Tahoma, Verdana, Segoe, sans-serif",
				url: "https://fonts.googleapis.com/css?family=Ubuntu",
			},
			{
				name: "Adobe Garamond Pro",
				fontFamily: "Adobe Garamond Pro",
			},
			{
				name: "Futura Std",
				fontFamily: "Futura Std",
			},
			{
				name: "Kalam",
				fontFamily: "Kalam",
				url: "https://fonts.googleapis.com/css?family=Kalam",
			},
		];
		sortArrayObjectByProperty(fontList, "name");
	} catch (error) {
		console.error("Error in 'utils.js -> getBeeEditorFontList()':" + error);
	}
	return fontList;
}
const alfaNumSort = (dataArr,property) => {
    var reA = /[^a-zA-Z]/g;
    var reN = /[^0-9]/g;
    dataArr.sort(sortAlphaNum);
    function sortAlphaNum(a, b) {
        var aName = a[property];
        var bName = b[property];
        var AInt = parseInt(aName, 10);
        var BInt = parseInt(bName, 10);

        if (isNaN(AInt) && isNaN(BInt)) {
            var aA = aName.replace(reA, "");
            var bA = bName.replace(reA, "");
            if (aA === bA) {
                var aN = parseInt(aName.replace(reN, ""), 10);
                var bN = parseInt(bName.replace(reN, ""), 10);
                return aN === bN ? 0 : aN > bN ? 1 : -1;
            } else {
                return aA > bA ? 1 : -1;
            }
        } else if (isNaN(AInt)) {//A is not an Int
            return 1;//to make alphanumeric sort first return -1 here
        } else if (isNaN(BInt)) {//B is not an Int
            return -1;//to make alphanumeric sort first return 1 here
        } else {
            return AInt > BInt ? 1 : -1;
        }
    }

    return dataArr;
}
function removeDuplicateByTags (listOfTags,keys){
	const filtered = listOfTags.filter(
        (s => o => 
            (k => !s.has(k) && s.add(k))
            (keys.map(k => o[k]).join('|'))
        )
        (new Set())
    );

return filtered;
}
function sortArrayObjectByPropertyOrderType(array, property, orderType) {
	let temp;
	if (Array.isArray(array)) {
		array.sort((obj1, obj2) => {
			if (
				isValidParam(obj1) &&
				obj1.hasOwnProperty(property) &&
				isValidParam(obj2) &&
				obj2.hasOwnProperty(property)
			) {
				let val1 = obj1[property];
				let val2 = obj2[property];

				val1 = typeof val1 === "string" ? val1.toLowerCase() : val1;
				val2 = typeof val2 === "string" ? val2.toLowerCase() : val2;
				if (property === "convert_percent") {
					var regex = /\d+./g;
					let f1 = obj1[property].match(regex);
					f1 = f1 + "";
					f1 = f1.replace(/,/g, "");

					let f2 = obj2[property].match(regex);
					f2 = f2 + "";
					f2 = f2.replace(/,/g, "");

					if (orderType === "asc") {
						if (parseFloat(f1) > parseFloat(f2)) {
							temp = 1;
						} else if (parseFloat(f1) < parseFloat(f2)) {
							temp = -1;
						} else {
							temp = 0;
						}
					} else {
						if (parseFloat(f1) < parseFloat(f2)) {
							temp = 1;
						} else if (parseFloat(f1) > parseFloat(f2)) {
							temp = -1;
						} else {
							temp = 0;
						}
					}
				} else {
					if (orderType === "asc") {
						if (val1 > val2) {
							temp = 1;
						} else if (val1 < val2) {
							temp = -1;
						} else {
							temp = 0;
						}
					} else {
						if (val1 < val2) {
							temp = 1;
						} else if (val1 > val2) {
							temp = -1;
						} else {
							temp = 0;
						}
					}
				}
			}
			return temp;
		});
	}
}

function getObjectSpecificEndpoint(object, endpoint) {
	return endpoint.replace("{object}", object);
}

function updateStageOrderIds(array) {
	// Variable to track the order ID for the main array and inner arrays
	let orderId = 1;
	let dataArray = array || [];
	// Iterate through each object in the array
	dataArray?.forEach((obj) => {
		// Update the order ID for the main object
		obj.order_id = orderId++;

		// Iterate through tasks array
		obj?.tasks?.sort((a, b) => a.orderid - b.orderid);
		obj?.tasks?.forEach((task) => {
		// Update the order ID for tasks
		task.order_id = orderId++;
		});

		// Iterate through webhooks array
		obj?.webhooks?.sort((a, b) => a.orderId - b.orderId);
		obj?.webhooks?.forEach((webhook) => {
		// Update the order ID for webhooks
		webhook.order_id = orderId++;
		});
	});

  return dataArray;
}

const getOrderId = (selectedStageTasksAndWebhooks) => {
	// Extract tasks and webhooks from the selectedStageTasksAndWebhooks original array
	let tasksAndWebhooksOrderIds = selectedStageTasksAndWebhooks?.flatMap((stage) => [
			...stage?.tasks?.map((task) => ({ type: "Task", order_id: task.order_id })),
			...stage?.webhooks?.map((webhook) => ({ type: "Webhook", order_id: webhook.order_id })),
		]);
    // Extract only the order_id values
	let orderIds = tasksAndWebhooksOrderIds?.map((item) => item.order_id);
    // get highest order_id value present orderIds
	let orderId = orderIds.length + 1;
	return orderId;
} 



export {
	generateLeadScoreIcon,
	fetchAutomationIcon,
	removeCurrency,
	findNearest,
	dateTimeFormat,
	dateFormat,
	timeFormat,
	timeZone,
	showTimeZoneMap,
	toCapitalize,
	groupByObjectArrayByProperty,
	sortArrayObjectByProperty,
	moveArrayItemToNewIndex,
	makeTiteleCase,
	StripHtmlToText,
	retriveEscapeHtml,
	getSalesObjectListForHome,
	phoneFormat,
	dateTimeFormatFlatPicker,
	dateFormatFlatPicker,
	timeFormatFlatPicker,
	getDateObject,
	isEmailExistsInList,
	sortArrayOfString,
	sortArrayObjectByIntegerProperty,
	sortArrayObjectByDateProperty,
	getInXpressReportId,
	escapeHtml,
	sortArrayObjectByFloatProperty,
	/* isNotificationExistsInList */
	openWindow,
	getMultilingualLabelName,
	getMultilingualNoteTypes,
	getUniqueId,
	getKey,
	getKeyByValue,
	timeZoneMap,
	timeZoneWithGMT,
	sortArrayObjectByDatePropertyDescending,
	formatDate,
	getChildObjectCustomTitle,
	formatCurrency,
	getBeeEditorFontList,
	sortArrayObjectByPropertyForCurrency,
	sortArrayObjectByPropertyOrderType,
	removeDuplicateByTags,
	alfaNumSort,
	getObjectSpecificEndpoint,
	reverseFormatNumber,
	updateStageOrderIds,
	getOrderId,
};

class IDGenerator {
	constructor() {
		this.length = 8;
		this.timestamp = +new Date();
	}

	getRandomInt = function (min, max) {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	};

	generate = function () {
		let ts = this.timestamp.toString();
		let parts = ts.split("").reverse();
		let id = "";

		for (let i = 0; i < this.length; ++i) {
			let index = this.getRandomInt(0, parts.length - 1);
			id += parts[index];
		}

		return id;
	};
}
