
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setHeader } from '../../../services/actions/headerActions';
import { getAppDrawer, getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import { ScheduleComponent, ResourcesDirective, ResourceDirective, ViewsDirective, ViewDirective, Inject, TimelineViews, Resize, DragAndDrop, TimelineMonth, Day, Week, WorkWeek, Month, Agenda } from '@syncfusion/ej2-react-schedule';
import { closest, remove, addClass } from '@syncfusion/ej2-base';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { Grid, Popper, InputBase, Button, Icon, Paper, Menu, Divider, List, ListItem, ListItemText, Select, MenuItem, InputLabel, FormControl, } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { getJobCalendarRefresh, setCalendarInfo, getAllJobAppointments, formatDate, getDateRange, selectedDateRangeUpdate, setSelectedDate, userTimeAvailabilityUpdate, prepareAllDateEvents, getDayAvailability } from '../../../services/actions/calendarActions';
import { isValidParam, getObjectParam, getStringParam, getArrayParam, getIntParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { dateTimeFormat, timeZone, dateFormat, timeFormat, sortArrayObjectByProperty } from '../../../services/helper/utils';
import * as HTTPClient from '../../../services/helper/httpClient';
import Avatar from '@mui/material/Avatar';
import { endPoints } from '../../../services/constants/endPoints';
import '../../../asset/styles/css/calendar.css';
import map from 'lodash/map';
import { addTab, TYPE_DETAIL_VIEW, getActiveTabInfo, TYPE_OTHER_VIEW } from '../../../services/helper/sfTabManager';
import Typography from '@mui/material/Typography';
import { hasAccessPermission } from '../../../services/helper/common';
import moment from 'moment';
import ShowCircularProgress from '../components/circularProgress';
import { getObjectSpecificEndpoint } from '../../../services/helper/utils';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import * as sfDialogs from '../components/sfDialogs';
import { getSFFormFieldsPromise, saveRecordPromise, getEndPointObject } from '../../../services/actions/sfFormActions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getAvailableLocationList } from '../../../services/actions/importAction';
import { getCookie, setCookie } from '../../../services/helper/sfCookies';
import _ from 'lodash';
import filter from 'lodash/filter';
import find from 'lodash/find';

const colorBoxStyle = {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2,
}
const days = [{ value: 1, label: 'Monday' },
{ value: 2, label: 'Tuesday' },
{ value: 3, label: 'Wednesday' },
{ value: 4, label: 'Thursday' },
{ value: 5, label: 'Friday' },
{ value: 6, label: 'Saturday' },
{ value: 7, label: 'Sunday' }];



const JobScheduler = ({ type, object }) => {
    const app = useSelector((state) => state.app);
    const tab = useSelector((state) => state.tab);
    const calendar = useSelector((state) => state.calendar);
    const calendarInfo = useSelector((state) => state.calendarInfo);
    const userlist = useSelector((state) => state.userlist);
    const sfForm = useSelector((state) => state.sfForm);
    const campaignTypes = useSelector((state) => state.campaignTypes);
    const detailViewRecord = useSelector((state) => state.detailViewRecord);
    const detailView = useSelector((state) => state.detailView);
    const dispatch = useDispatch();
    let getRecurringDeleteChoice;
    let scheduleObj;
    let treeObj;
    let isTreeItemDropped = false;
    let draggedItemId = '';
    let allowDragAndDrops = true;
    let calendarHeight = window.innerHeight - 205;
    let top = (calendarHeight - 10) / 2
    const [selectedJobSchdulerDate, setSelectedJobSchdulerDate] = useState(new Date);
    const [calendarViewControl, setCalendarViewControl] = useState({ left: '50px', leftMin: true, right: '25%', rightMin: false });
    const [workDays, setWorkDays] = useState([1, 2, 3, 4, 5]);
    const [menuAncher, setMenuAncher] = useState(null);
    const [selectedType, setSelectedType] = useState([]);
    const [selected_user, setSelected_user] = useState([
        { value: app.me.id, label: app.me.name, color: 'FE5722' },
    ]);
    const [selected_show, setSelected_show] = useState([{ value: 'Scheduled', label: getLocalizedStrings().label.CALENDAR.SHOW_OPTION_3 }]);
    const [pendingJob, setPendingJob] = useState([]);
    const [pendingJobRearrangeIdx, setPendingJobRearrangeIdx] = useState(0);
    const [selectedId, setSelectedId] = useState(0);
    const [isRefresh, setIsRefresh] = useState(false);
    const [alreadyMounted, setAlreadyMounted] = useState(false);
    const [getAllAppointmentsTypesCall, setGetAllAppointmentsTypesCall] = useState(false);
    const [setAllJobAppointmentsCall, setSetAllJobAppointmentsCall] = useState(false);
    const [appTypes, setAppTypes] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [finalUserList, setFinalUserList] = useState([]);
    const [currentView, setCurrentView] = useState("");
    const [userTimeAvailability, setUserTimeAvailability] = useState([]);
    const [showOptions, setShowOptions] = useState([
        { value: 'Cancelled', label: getLocalizedStrings().label.CALENDAR.SHOW_OPTION_1 },
        { value: 'Completed', label: getLocalizedStrings().label.CALENDAR.SHOW_OPTION_2 },
        { value: 'Scheduled', label: getLocalizedStrings().label.CALENDAR.SHOW_OPTION_3 }
    ]);

    const [startWkHr, setStartWkHr] = useState('9:00');
    const [endWkHr, setEndWkHr] = useState('17:00');

    const [locationList, setLocationList] = useState([]);
    const [zipTextValue, setZipTextValue] = useState('');
    const [selectedDays, setSelectedDays] = useState(1);
    const [availableUser, setAvailableUser] = useState([]);
    const [dataFetched, setDataFetched] = useState(true);
    const [changeLog, setChangeLog] = useState({ usersList: [], getAllJobAppointmentsfromNavigate: false, selectedDateRange: null, pendingJobRearrangeIdx: 0, pendingJob: [] })
    useEffect(() => {
        setCalendarViewMode();
        userListUpdate();
        if (!alreadyMounted && !setAllJobAppointmentsCall && !getAllAppointmentsTypesCall) {
            setAlreadyMounted(true)
            if (calendar.userTimeAvailability != null) {
                setUserTimeAvailability(calendar.userTimeAvailability);
            }
            getJobSchedulerDetils('OnMount');
        }
        let kanbanObject = {};
        kanbanObject.currentView = 'calendarOrTask';
        kanbanObject.show = false;
        dispatch(setHeader(object, 'Scheduler', kanbanObject, true, true));

    }, []);

    useEffect(() => {
        let _isRefresh = calendar.isJobCalendarRefresh;
        if (!_isRefresh && (calendar.calendarInfo.appTypes != calendar.calendarInfo.appTypes
        )) {
            if (calendar.calendarInfo.appTypes != undefined) {
                setAppTypes(calendar.calendarInfo.appTypes);
            }
        }
    }, [calendar.calendarInfo])

    // useEffect(() => {
    //     let isEqual = _.isEqual(changeLog.pendingJob, calendar.calendarInfo.unscheduledJobs);
    //     if (!isEqual) {
            
    //     }
    // }, [calendar.calendarInfo.unscheduledJobs])

    useEffect(() => {
        let _isRefresh = calendar.isJobCalendarRefresh;
        if (app.selectedModuleName !== app.lastSelectedModuleName) {
            _isRefresh = true;
            calendar.isJobCalendarRefresh = true;
        }
    }, [calendar.isJobCalendarRefresh, app.selectedModuleName])

    useEffect(() => {
        if (alreadyMounted) {
            setDataFetched(true);
            if (calendar.userTimeAvailability != null) {
                setUserTimeAvailability(calendar.userTimeAvailability);
            }
        }
    }, [calendar.userTimeAvailability, alreadyMounted])

    useEffect(() => {
       let data = app.me.calendar_setup;
       if (usersList.length <= 0 && userlist.data.length > 0) {
            userListUpdate();
        }
        if (alreadyMounted && calendar.isJobCalendarRefresh) {
            dispatch(getJobCalendarRefresh(false));
            getJobSchedulerDetils('OnUpdate');
        }
        if ( data.length > 0 ) {
            let endTime = data[0].endHour + ':00';
            let startTime = data[0].startHour + ':00';
            if (endWkHr != endTime || startWkHr != startTime) {
                wrkTimeSet();
            }

        }
        let _pendingJobRearrangeIdx = pendingJobRearrangeIdx
        if (alreadyMounted && getArrayParam(changeLog.pendingJob)) {
            if (type == 'jobdetailviewscheduler') {
                let info = getActiveTabInfo();
                let selectedId = info.detailView_recordid;
                let _jobData = changeLog.pendingJob
                let indx = _jobData?.findIndex((obj) => obj?.jobId == selectedId)
                if (_pendingJobRearrangeIdx != indx) {
                    _jobData = arrayMove(_jobData, indx, 0);
                    if(isValidParam(_jobData)){
                      //  getUnscheduledJob();

                    }
                    setPendingJobRearrangeIdx(indx);
                }
            }
        }
    }, [alreadyMounted, calendar.isJobCalendarRefresh, app.me, userlist.data, calendar,changeLog.pendingJob, usersList])

    const arrayMove = (array, from, to) => {
        if(array.length>0){
         array = array?.slice();
         array?.splice(to < 0 ? array.length + to : to, 0, array?.splice(from, 1)[0]);
        }
        return array;
    }

    const setCalendarViewMode = () => {
        let userId = (app.me.id * 19000) + 7;
        let projectId = (app.me.projectId * 19000) + 7;
        let cookieNameLeft = "jobCalendarMin-left" + projectId + '-' + userId;
        let cookieNameRight = "jobCalendarMin-right" + projectId + '-' + userId;
        let viewLeft = JSON.parse(getCookie(cookieNameLeft));
        let viewRight = JSON.parse(getCookie(cookieNameRight));
        let tempCalendarViewControl = calendarViewControl;
        if (viewLeft != null) {
            tempCalendarViewControl.left = viewLeft ? 50 : '13%';
            tempCalendarViewControl.leftMin = viewLeft;
        }
        if (viewRight != null) {
            tempCalendarViewControl.right = viewRight ? 50 : '25%';
            tempCalendarViewControl.rightMin = viewRight;
        }
        setCalendarViewControl(tempCalendarViewControl);
    }
    const getJobSchedulerDetils = (callFrom) => {
        wrkTimeSet();
        let _userlist = getArrayParam(usersList);
        let _currentView = "week";
        let finalSelectedStatus = [{ value: 'Scheduled', label: getLocalizedStrings().label.CALENDAR.SHOW_OPTION_3 }];
        let _finalSelectedUser = [];
        _finalSelectedUser.push({ value: app.me.id, label: app.me.name, color: 'FE5722', profileImage: app.me.profile_image });

        let _calendarInfo = getObjectParam(calendar.calendarInfo);
        if (_calendarInfo != null && _calendarInfo != '' && _calendarInfo.hasOwnProperty('assign') && _calendarInfo.hasOwnProperty('status') && _calendarInfo.hasOwnProperty('schedulerView')) {
            _currentView = _calendarInfo.schedulerView;
            finalSelectedStatus = _calendarInfo.status;
            if (_calendarInfo.hasOwnProperty('availableUser') && _calendarInfo.availableUser.length > 0 &&
                _calendarInfo.userTimeAvailability != null && _calendarInfo.userTimeAvailability.length > 0 && _calendarInfo.selectedLocation != null
                && _calendarInfo.selectedLocation != "" && _calendarInfo.selectedLocation != 'None') {
                if (callFrom == 'OnMount') {
                    dispatch(userTimeAvailabilityUpdate(_calendarInfo.userTimeAvailability));
                }
                _finalSelectedUser = _calendarInfo.availableUser;
                setSelected_user(_calendarInfo.availableUser);
                setZipTextValue(_calendarInfo.selectedLocation);
                setSelectedDays(_calendarInfo.selectedDays);
            } else {
                if (_calendarInfo.assign.length > 0) {
                    setSelected_user(_calendarInfo.assign);
                    _finalSelectedUser = _calendarInfo.assign;
                } else {
                    setSelected_user(_finalSelectedUser);
                }


            }
            setSelected_show(_calendarInfo.status);
            setCurrentView(_calendarInfo.schedulerView);
            setAppTypes(_calendarInfo.appTypes);
            if (callFrom == 'filter') {

            } else {
                getAvailableLocationListFnc(callFrom);
            }
            setSelected_user(_finalSelectedUser);
            prepareSearchArray(_userlist, _finalSelectedUser, _calendarInfo.appTypes, finalSelectedStatus, callFrom);
        } else {
            if (!getAllAppointmentsTypesCall) {
                setSelected_user(_finalSelectedUser);
                setGetAllAppointmentsTypesCall(true);
                getAllAppointmentsTypes(_finalSelectedUser, finalSelectedStatus, _currentView, callFrom);

            }
        }
    }
    const getAllAppointmentsTypes = (finalSelectedUser, finalSelectedStatus, currentView, callFrom) => {

        let userId = (app.me.id * 19000) + 7;
        let projectId = (app.me.projectId * 19000) + 7;
        let userCookieName = "selected-user" + projectId + '-' + userId;
        let _responseUserArr = [];
        let _selectedUserArr = [];
        let finalCookieUser = [];
        let _calendarInfo = getObjectParam(calendar.calendarInfo);
        if (!_calendarInfo.hasOwnProperty('appTypes') && appTypes.length == 0) {
            let param = {};
            param.object = constants.APPOINTMENTS;
            param.name = 'event_type';
            param.list_type = 'external';

            let promise = Promise.resolve(HTTPClient.get(endPoints.FORM.APPOINTMENT_TYPE_LIST, param));

            promise.then((response) => {
                if (isValidParam(response)) {
                    let type = getArrayParam(response.record);
                    let _appTypes = type.map((f, i) => {
                        let typeObj = {};
                        typeObj.value = f.value;
                        typeObj.label = f.value;
                        typeObj.color = f.color;
                        return typeObj;
                    })
                    sortArrayObjectByProperty(_appTypes, 'label');
                    setAppTypes(_appTypes);
                    let _userList = getArrayParam(usersList);
                    _userList = _userList.length > 0 ? _userList : userlist.data;
                    getAvailableLocationListFnc(callFrom);
                    if (app.me.calendar_setup.length > 0) {

                        let _selectedStatusArr = [];
                        let responseStatusArr = [];
                        let _selectedUser = app.me.calendar_setup[0].selectedUser;
                        let _selectedStatus = app.me.calendar_setup[0].selectedStatus;

                        if (_selectedUser != null && _selectedUser != undefined) {
                            _selectedUserArr = _selectedUser.split(',');

                            _selectedUserArr.map((item, i) => {
                                let selectedCUser = _userList.filter(f => { return f.id == item });
                                if (selectedCUser.length > 0) {
                                    let userObj = {};

                                    userObj.value = selectedCUser[0].id;
                                    userObj.label = selectedCUser[0].value;
                                    userObj.color = selectedCUser[0].colorCode;
                                    userObj.profileImage = selectedCUser[0].profile_image_url;
                                    _responseUserArr.push(userObj);
                                    finalSelectedUser = _responseUserArr;
                                }
                            });
                        }

                        if (_selectedStatus != null && _selectedStatus != undefined && _selectedStatus != "") {
                            _selectedStatusArr = _selectedStatus.split(',');
                            _selectedStatusArr.map((item, i) => {
                                let selectedAppStatus = showOptions.filter(f => { return f.label == item });
                                if (selectedAppStatus.length > 0) {
                                    let statusObj = {};
                                    statusObj.value = selectedAppStatus[0].value;
                                    statusObj.label = selectedAppStatus[0].label;
                                    responseStatusArr.push(statusObj);
                                    finalSelectedStatus = responseStatusArr;
                                }
                            });

                        }
                    }
                    let cookieSelectedUser = JSON.parse(getCookie(userCookieName));
                    if (cookieSelectedUser != undefined && cookieSelectedUser != null && cookieSelectedUser.length > 0) {
                        finalCookieUser = cookieSelectedUser;
                    } else {
                        finalCookieUser.push({ value: app.me.id, label: app.me.name, color: 'FE5722' });
                    }
                    calendar.calendarInfo.salesAssign = finalCookieUser;
                    calendar.calendarInfo.assign = finalSelectedUser;
                    calendar.calendarInfo.status = finalSelectedStatus;
                    calendar.calendarInfo.schedulerView = currentView;
                    calendar.calendarInfo.calendarView = 'Week';
                    calendar.calendarInfo.appTypes = _appTypes;
                    prepareSearchArray(_userList, finalSelectedUser, _appTypes, finalSelectedStatus, 'getAllAppointmentsTypes');

                }
            });
        }

    }
    const prepareSearchArray = (_userlist, finalSelectedUser, _appTypes, finalSelectedStatus, callFrom) => {
        let searchObject = null;
        let searchArr = [];
        // try {
        if (_appTypes != null && _appTypes.length > 0 && _userlist != null && _userlist.length > 0 && finalSelectedUser != null && finalSelectedUser.length > 0) {


            map(finalSelectedUser, function (userObject, index) {
                searchObject = {};
                searchObject.assign = userObject.label;
                searchArr.push(searchObject);
            });
            map(appTypes, function (typeObject, i) {
                searchObject = {};
                searchObject.event_type = typeObject.value;
                searchArr.push(searchObject);
            });
            map(finalSelectedStatus, function (showObject, index) {
                searchObject = {};
                searchObject.t_status = showObject.value;
                searchArr.push(searchObject);
            });
            setSelected_user(finalSelectedUser);
            setAllJobAppointments(searchArr, callFrom);
        }
        // } catch (error) {
        //     console.error("Error in 'jobscheduler.js -> prepareSearchArray()':" + error);
        // }

    }
    const setAllJobAppointments = (searchArr, callFrom) => {
        if (searchArr.length > 0) {
            let selectedDateRange = null;
            if (calendar.selectedDateRange != null) {
                selectedDateRange = calendar.selectedDateRange;
                if (callFrom === 'viewNavigate' || callFrom === 'dateNavigate') {
                    selectedDateRange = changeLog.selectedDateRange;
                }
            } else {
                selectedDateRange = getDateRange(calendar.calendarInfo.calendarView);
                dispatch(selectedDateRangeUpdate(selectedDateRange));
            }
            let searchObject = {};
            searchObject.start_date = selectedDateRange.startDate + "," + selectedDateRange.endDate;
            searchArr.push(searchObject);
            if (callFrom == 'viewNavigate' || callFrom == 'dateNavigate') {

                if (!changeLog.getAllJobAppointmentsfromNavigate) {
                    let log = changeLog;
                    log.getAllJobAppointmentsfromNavigate = true;
                    setChangeLog({ ...log });
                    getUnscheduledJob();
                    dispatch(getAllJobAppointments(searchArr));
                }
            } else if (callFrom == "OnUpdate") {
                if (alreadyMounted && calendar.isJobCalendarRefresh) {
                    calendar.isJobCalendarRefresh = false;
                    getUnscheduledJob();
                    dispatch(getAllJobAppointments(searchArr));
                }
            } else if (callFrom == "filter") {
                if (alreadyMounted) {
                    dispatch(getAllJobAppointments(searchArr));
                    getUnscheduledJob();
                }
            } else {
                if (!setAllJobAppointmentsCall) {
                    setSetAllJobAppointmentsCall(true);
                    getUnscheduledJob();
                    dispatch(getAllJobAppointments(searchArr));
                }
            }
        }
    }
    const minimizeLeftRightCard = (from) => {
        let view = calendarViewControl;
        if (from === 'left') {
            view.left = !calendarViewControl.leftMin ? 50 : '13%';
            view.leftMin = !calendarViewControl.leftMin;
        } else {
            view.right = !calendarViewControl.rightMin ? 37 : '25%'
            view.rightMin = !calendarViewControl.rightMin;
        }

        setCalendarViewControl({ ...view });

        let userId = (app.me.id * 19000) + 7;
        let projectId = (app.me.projectId * 19000) + 7;
        let cookieNameLeft = "jobCalendarMin-left" + projectId + '-' + userId;
        let cookieNameRight = "jobCalendarMin-right" + projectId + '-' + userId;
        if (from == 'left') {
            setCookie(cookieNameLeft, view.leftMin);
        } else {
            setCookie(cookieNameRight, view.rightMin);
        }
    }
    const getGroupByResourseData = () => {
        let GroupByResourse = [];
        let tempGroupByResourse = selected_user;
        GroupByResourse = tempGroupByResourse.filter(function (el) {
            return el.value != -5555 && el.value != -8888;
        });
        return GroupByResourse
    }
    const updateAppointmentDnD = (formData, data) => {
        // try {
        let startTime = data.data[0].StartTime;
        let endTime = data.data[0].EndTime;

        let param = {};
        param.id = data.data[0].Id;

        let momentStartDate = moment(startTime);
        let momentEndDate = moment(endTime);
        let startDate = momentStartDate.format(constants.INPUT_DATE_TIME_FORMAT_24);
        let endDate = momentEndDate.format(constants.INPUT_DATE_TIME_FORMAT_24);

        param.start_date = startDate;
        param.end_date = endDate;

        HTTPClient.post(endPoints.APPOINTMENT.APPOINTMENT_DRAG_AND_DROP, param)
            .then((response) => {
                if (isValidParam(response) && response.status == 0) {

                }
            });
        // } catch (error) {
        //     console.error("Error in 'jobscheduler.js -> updateAppointmentDnD()':" + error);
        // }
    }

    const wrkTimeSet = () => {
        if (app.me.calendar_setup.length > 0) {
            let data = app.me.calendar_setup[0]
            setStartWkHr(data.startHour + ':00');
            setEndWkHr(data.endHour + ':00');

        } else {
            setStartWkHr('9:00');
            setEndWkHr('17:00');
        }
    }
    const userListUpdate = () => {
        if (usersList.length <= 0 && userlist.data.length > 0) {
            let _userList = getArrayParam(userlist.data);
            let tmpUserlist = [];

            _userList.map((usr, i) => {
                let obj = {};
                obj.value = usr.id;
                obj.label = usr.value;
                tmpUserlist.push(usr);
            })
            setUsersList(tmpUserlist);
            let log = changeLog;
            log.usersList = tmpUserlist;
            setChangeLog({ ...log });
        }
    }
    const openLinkDetails = (recordId, _object, labelName) => {
        // try {
        let url = '/' + _object + '/detailview/' + recordId;
        let tabParams = {
            label: labelName,
            object: _object,
            type: TYPE_DETAIL_VIEW,
            url: url,
        }
        addTab(tabParams, true);
        // } catch (error) {
        //     console.error('Error in Calendar.js -> openLinkDetails() : ' + error);
        // }
    }
    const resourceHeaderTemplate = (props) => {
        let imgUrl = props.resourceData.profileImage;
        let groupBy = "Users";
        return (
            <div className="template-wrap" title={props.resourceData.label}>
                {groupBy == "Users" &&
                    <div>
                        {imgUrl != null ? <img className='resource-details' src={imgUrl} alt={props.resourceData.label} /> : <Avatar src="/broken-image.jpg" />}
                    </div>
                }

                <div className="resource-details">
                    <div className="resource-name" title={props.resourceData.label}>{props.resourceData.label}</div>
                </div>
            </div>);
    }
    const treeTemplate = (props) => {
        console.log("treeprops",props)
        let isHeighLight = false;
        if (type == 'jobdetailviewscheduler') {
            let info = getActiveTabInfo();
            if (info.detailView_recordid == props.jobId) {
                isHeighLight = true;
            }
        }
        return (
            <div id="waiting" style={{ border: '0.5px solid #E1E7EC', background: isHeighLight ? '#f5f2ea' : 'transparent', cursor: 'move' }}>
                <div id="waitdetails">
                    <div id="waitlist"
                        style={{ border: '0px solid red', display: 'inline-block', cursor: 'pointer' }}
                        onClick={openLinkDetails.bind(this, props.jobId, constants.JOBS_OBJECT, props.jobName)}
                    >
                        {props.jobName}
                    </div>
                    <div id="waitcategory">
                        {props.assign}
                    </div>
                </div>
            </div>);
    }
    const onItemDrag = (event) => {
        if (scheduleObj != null && scheduleObj != undefined && scheduleObj?.isAdaptive) {
            let classElement = scheduleObj?.element.querySelector('.e-device-hover');
            if (classElement) {
                classElement.classList.remove('e-device-hover');
            }
            if (event.target.classList.contains('e-work-cells')) {
                addClass([event.target], 'e-device-hover');
            }
        }
        if (document.body.style.cursor === 'not-allowed') {
            document.body.style.cursor = '';
        }
        if (event.name === 'nodeDragging') {
            let dragElementIcon = document.querySelectorAll('.e-drag-item.treeview-external-drag .e-icon-expandable');
            for (let i = 0; i < dragElementIcon.length; i++) {
                dragElementIcon[i].style.display = 'none';
            }
        }
    }
    const onActionBegin = (event) => {
        if (event.requestType === 'eventCreate' && isTreeItemDropped) {
            let treeViewdata = treeObj.fields.dataSource;
            const filteredPeople = treeViewdata.filter((item) => item.Id !== parseInt(draggedItemId, 10));
            treeObj.fields.dataSource = filteredPeople;
            let elements = document.querySelectorAll('.e-drag-item.treeview-external-drag');
            for (let i = 0; i < elements.length; i++) {
                remove(elements[i]);
            }
        }
    }
    const onTreeDragStop = (event) => {
        let treeElement = closest(event.target, '.e-treeview');
        let classElement = scheduleObj?.element.querySelector('.e-device-hover');
        if (classElement) {
            classElement.classList.remove('e-device-hover');
        }

        if (!treeElement) {
            event.cancel = true;
            let scheduleElement = closest(event.target, '.e-content-wrap');
            if (scheduleElement) {
                let treeviewData = treeObj?.fields?.dataSource;
                if (event.target.classList.contains('e-work-cells')) {
                    const filteredData = treeviewData?.filter((item) => item.jobId === (event.draggedNodeData.id));
                    let cellData = scheduleObj?.getCellDetails(event.target);
                    let resourceDetails = scheduleObj?.getResourcesByIndex(cellData.groupIndex);

                    let ConferenceId;
                    if (resourceDetails?.groupData?.ConferenceId?.length > 0) {
                        ConferenceId = resourceDetails?.groupData?.ConferenceId;
                    } else {
                        let tempArr = [];
                        tempArr.push(app.me.id)
                        ConferenceId = tempArr;
                    }

                    let refData = {
                        Subject: filteredData[0].jobName,
                        startTime: cellData.startTime,
                        endTime: cellData.endTime,
                        IsAllDay: cellData.isAllDay,
                        groupIndex: cellData.groupIndex,
                        ConferenceId: ConferenceId,
                        parent_record_id: filteredData[0].jobId,
                        call_from: 'Oparations'
                    };
                    addnewAppointment(refData);
                    isTreeItemDropped = true;
                    draggedItemId = event.draggedNodeData.id;
                }
            }
        }
    }
    const getUnscheduledJob = () => {
        // try {
        let endPoint = getObjectSpecificEndpoint(constants.JOBS_OBJECT, endPoints.RECORDS.GET_UNSCHEDULED_JOB);
        var promise = Promise.resolve(HTTPClient.post(endPoint, null));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (response.data.hasOwnProperty("unscheduledJobs") && response.data.unscheduledJobs != null) {
                    let _pendingJob = response.data.unscheduledJobs;
                    calendar.calendarInfo.unscheduledJobs = response.data.unscheduledJobs;
                    let info = getActiveTabInfo();
                    let selectedId = info.detailView_recordid;
                    let indx = _pendingJob?.findIndex((obj) => obj?.jobId == selectedId)
                    _pendingJob = arrayMove(_pendingJob, indx, 0);
                    let temp = changeLog;
                    temp.pendingJob = _pendingJob;
                    setChangeLog({...temp});
                    console.log("fun-3",changeLog.pendingJob)

                    calendar.isJobCalendarRefresh = true;
                }

            });
        }
        // } catch (error) {
        //     console.error("Error in 'jobScheduler.js -> getUnscheduledJob()':" + error);
        // }
    }

    const setAppoinmentData = () => {
        let modifiedEventArr = [];
        // try {
        let mounted = calendar.mounted;
        var _userlist = getArrayParam(userlist.data);
        let calendarInfoFromRedux = getObjectParam(calendar.calendarInfo);
        var showType = calendarInfoFromRedux.appTypes;
        let loggedInUser = app.me.name;
        let colorCodeField = 'Types';
        let groupBy = 'Users';

        let subject = '';
        if (mounted) {
            let calendarUser = new Array();
            if (groupBy == 'Types') {
                getArrayParam(selectedType).map((_type, i) => {
                    let id = _type.label;
                    map(showType, function (typeObject, i) {
                        if (typeObject.label == id) {
                            let obj = {};
                            let color = '#' + typeObject.color;
                            obj.Text = typeObject.label;
                            obj.Id = id;
                            obj.Color = color; //'#FE5722';
                            obj.resourceId = id;
                            calendarUser.push(obj);
                        }
                    });
                });
            } else {
                getArrayParam(selected_user).map((selectedUser, i) => {
                    let id = selectedUser.value;
                    map(_userlist, function (userObject, i) {
                        if (userObject.id == id) {
                            let obj = {};
                            let color = '#' + userObject.colorCode;
                            obj.Text = userObject.value;
                            obj.Id = id;
                            obj.Color = color; //'#FE5722';
                            obj.resourceId = id;
                            calendarUser.push(obj);
                        }
                    });
                });
            }
            let eventArr = getArrayParam(calendar.jobsAppData);
            let blockEventArr = [];
            let selectedDateRange = null;
            if (calendar.selectedDateRange != null) {
                selectedDateRange = calendar.selectedDateRange;
            } else {
                selectedDateRange = getDateRange(calendar.calendarInfo.calendarView);
                dispatch(selectedDateRangeUpdate(selectedDateRange));
            }
            userTimeAvailability.map((data, i) => {
                let tempDateEvents = prepareAllDateEvents(data, calendarUser, selectedDateRange);
                blockEventArr = [...blockEventArr, ...tempDateEvents]
            });
            eventArr = [...eventArr, ...blockEventArr];
            modifiedEventArr = eventArr.map(function (item) {
                let conId;
                let color = '';
                calendarUser.map((user, i) => {
                    if (groupBy == 'Types') {
                        let typeId = item.type
                        if (item.type == null || item.type == '' || item.type == undefined) {
                            typeId = 'Empty'
                        }
                        let selectedUser = _userlist.filter(f => { return f.value == item.assign_user })
                        if (typeId == user.Text && selectedUser.length > 0) {
                            conId = typeId == 'Empty' ? 'Empty' : getStringParam(typeId);
                            color = getStringParam(selectedUser[0].colorCode);
                        }
                        if (loggedInUser != item.assign_user && item.visibility == 'Private') {
                            subject = 'Busy';
                        } else {
                            subject = item.subject;
                        }

                    } else {
                        if (item.assign_user == user.Text) {
                            conId = getIntParam(user.Id);
                            color = getStringParam(user.Color);
                        }
                        if (loggedInUser != item.assign_user && item.visibility == 'Private') {
                            subject = 'Busy';
                        } else {
                            subject = item.subject;
                        }
                    }

                });
                let isexistType = showType.filter(f => { return (f.value == item.type) });

                let emptyType = showType.filter(f => { return (f.value == "Empty") });
                if (colorCodeField == 'Types') {
                    if (item.type == '' || isexistType.length == 0) {
                        color = '#' + getStringParam(emptyType[0].color);
                    } else {
                        map(showType, function (typeObject, i) {
                            if (typeObject.value == item.type) {
                                color = '#' + getStringParam(typeObject.color);
                            }
                        });
                    }
                }
                // conId = getIntParam(conId[0]);
                let parentRecordId = getIntParam(item.parent_recordid);
                let blockBGColor = '#E4E4E4';
                return {
                    Id: item.id,
                    Subject: subject,
                    StartTime: item.start,
                    EndTime: item.end,
                    IsBlock: isValidParam(item.IsBlock) ? getBooleanParam(item.IsBlock) : false,
                    IsAllDay: item.allDay,
                    ParentRecordId: parentRecordId,
                    Description: item.description,
                    ConferenceId: [conId],
                    Color: isValidParam(item.IsBlock) && item.IsBlock ? blockBGColor : color,
                    type: item.type,
                    Company: item.company,
                    Contact: item.contact,
                    LinkInfo: item.linkInfo,
                    Location: item.location,
                    JobName: item.jobName,
                    OpprtunityName: item.opportunityName,
                    userName: item.assign_user,
                }
            });
        }
        return modifiedEventArr;
        // } catch (error) {
        //     console.error("Error in 'jobscheduler.js -> setAppoinmentData()':" + error);
        // }
    }
    const editAppointment = (data) => {
        let events = data;
        let hasPermission = null;

        hasPermission = hasAccessPermission(constants.APPOINTMENTS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
        if (hasPermission) {
            let recordId = events.Id;
            let labelName = null;
            let objectName = constants.APPOINTMENTS;
            // labelName = getLocalizedStrings().label.COMMON.EDIT + ' ' + objectName.charAt(0).toUpperCase() + objectName.slice(1);
            labelName = getLocalizedStrings().label.COMMON.EDIT + ' ' + getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENTS;
            let data = {
                id: recordId,
                object: constants.APPOINTMENTS
            }
            dispatch(getAppDrawer(true, labelName, constants.EDIT, data, null));
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
        }

    }

    const onEventRendered = (args) => {
        if (scheduleObj != undefined && scheduleObj.currentView != 'Agenda') {
            if (scheduleObj.currentView != 'Month' && args.data.object == undefined && args.data.object != constants.BROADCAST_CAMPAIGNS_OBJECT) {
                let cellHeight = scheduleObj.element.querySelector('.e-work-cells').offsetHeight;
                let appHeight = (args.data.EndTime.getTime() - args.data.StartTime.getTime()) / (60 * 1000) * (cellHeight * scheduleObj.timeScale.slotCount) / scheduleObj.timeScale.interval;
                if (!args.data.IsAllDay) {
                    args.element.style.height = appHeight + 'px';
                }
            }
            let color = '#FE5722';
            var _userlist = userlist.data;
            map(_userlist, function (user, i) {
                let ConferenceId = args.data.ConferenceId;
                let userId = getIntParam(ConferenceId[0]);
                if (userId == user.id) {
                    color = '#' + user.colorCode;
                }
            });
            if (args.data.object != undefined && args.data.object == constants.BROADCAST_CAMPAIGNS_OBJECT) {
                color = args.data.color;
            }
            args.element.style.backgroundColor = args.data.Color;
            let nowTime = moment().tz(timeZone[app.me.timezone]);
            var isafter = moment(args.data.EndTime).isAfter(nowTime);
            if (!isafter) {
                args.element.style.opacity = 0.5;
            }
            if (args.data.Subject == 'Busy') {
                args.element.innerHTML = "<span><div class=e-appointment-details ><div class=e-subject>Busy</div></div></span>";
            }
            if (args.data.Subject === '') {
                args.element.innerHTML = "<span><div class=e-appointment-details ><div class=e-subject></div></div></span>";
            }
        }
    }
    const addnewAppointment = (refData) => {
        let start = refData.startTime;
        let end = refData.endTime
        let tabs = tab.tabs;
        let jobtab = tabs.find((_tab) => { return _tab.type == TYPE_OTHER_VIEW && _tab.object == constants.JOB_SCHEDULER });
        let groupBy = 'Users';

        if (currentView == 'Month') {
            end = moment(start).add(constants.CALENDER_DEFAULT_INTERVAL, 'm').format(dateTimeFormat[app.me.date_format]);
        }
        let hasPermission = null;
        var g1 = new Date(start);
        var g2 = new Date();
        if (g1.getTime() < g2.getTime()) {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You can create appointment only on future dates', null);
        }
        else {
            hasPermission = hasAccessPermission(object, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_CREATE);
            if (hasPermission) {
                let availableConferenceId = 0;
                if (groupBy == 'Users') {
                    availableConferenceId = selected_user[refData.groupIndex].value;
                }
                let dayAvailability = getDayAvailability(userTimeAvailability, { start: start, end: end }, availableConferenceId);
                if (!dayAvailability.isBlock) {
                    start = dayAvailability.start;
                    end = dayAvailability.end;
                    let labelName = null;
                    let objectName = object;
                    // labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + objectName.charAt(0).toUpperCase() + objectName.slice(1);
                    labelName = getLocalizedStrings().label.COMMON.ADD_NEW_APPOINTMENT + ' ' + getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENT;
                    let data = {};
                    data.object = constants.APPOINTMENTS;
                    let startDate = new Date(start);
                    let startDateValue = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes());
                    let endDate = new Date(end);
                    let endDateValue = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours(), endDate.getMinutes());
                    const momentStartDate = moment(startDateValue);
                    const momentEndDate = moment(endDateValue);
                    //data.timeSlot = { start_date: moment(start), end_date: moment(end) }
                    data.timeSlot = { start_date: momentStartDate.format(dateTimeFormat[app.me.date_format]), end_date: momentEndDate.format(dateTimeFormat[app.me.date_format]) }
                    if (refData.Subject != null && refData.Subject != undefined) {
                        data.subject = refData.Subject;
                    } else {
                        data.subject = getLocalizedStrings().label.APPOINTMENTS_TASKS.FOLLOWUP;
                    }
                    let confData = isValidParam(refData.ConferenceId) ? refData.ConferenceId : [];
                    if (confData.length > 0) {
                        if (groupBy != null && groupBy != undefined && groupBy != 'Types') {
                            let assignTo = null;
                            let userArray = getArrayParam(refData.ConferenceId)
                            let userId = getIntParam(userArray[0]);
                            selected_user.map((user, i) => {
                                if (userId == user.value) {
                                    assignTo = getStringParam(user.label);
                                }
                            });
                            data.assign = assignTo;
                        } else {
                            let appTypeArr = getArrayParam(refData.ConferenceId);
                            let selectedAppType = getStringParam(appTypeArr[0]);
                            if (selectedAppType != 'Empty') {
                                data.event_type = selectedAppType;
                            }
                            data.assign = app.me.name;
                        }
                    } else if (isValidParam(refData.groupIndex)) {
                        let selectUser = selected_user[refData.groupIndex];
                        data.assign = selectUser.label;
                    } else {
                        data.assign = app.me.name;
                    }
                    if (refData.hasOwnProperty('parent_record_id') && refData.hasOwnProperty('call_from') && refData.call_from == 'Oparations') {
                        data.parent_object = constants.JOBS_OBJECT;
                        data.parent_record_id = refData.parent_record_id;
                        data.id = 0;
                        data.isDetailView = true;
                        data.isFromJobScheduler = true;
                        data.callType = 'jobscheduler';
                        data.isDragNDrop = true;
                        let recordDetails = {};
                        recordDetails.project_name = refData.Subject
                        data.recordDetails = recordDetails;
                    }
                    if (!refData.hasOwnProperty('parent_record_id') && !refData.hasOwnProperty('call_from') && type == "jobdetailviewscheduler" && detailViewRecord != undefined && detailViewRecord != null) {
                        let info = jobtab.info
                        data.parent_object = constants.JOBS_OBJECT;
                        data.parent_record_id = info.detailView_recordid;
                        data.id = 0;
                        data.isDetailView = true;
                        data.isFromJobScheduler = true;
                        data.callType = 'jobscheduler';
                        data.isDragNDrop = false;
                        let recordDetails = {};
                        recordDetails.project_name = info.jobName;
                        data.recordDetails = recordDetails;
                    }
                    dispatch(getAppDrawer(true, labelName, constants.NEW, data, null));
                } else {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, selected_user[refData.groupIndex].label + ' is not available in this time', null)
                }

            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
            }
        }
    }
    const getAppoinmentFormData = (data) => {
        // try {
        if (isValidParam(sfForm.data['appointments'])) {
            let appFormdata = sfForm.data['appointments']
            processFormData(appFormdata, data);
        } else {
            var promise = getSFFormFieldsPromise(constants.APPOINTMENTS);
            promise.then((response) => {
                if (isValidParam(response)) {
                    sfForm.data['appointments'] = Object.assign({}, response);
                    processFormData(response, data);
                }
            });
        }
        // } catch (error) {
        //     console.error("Error in 'jobscheduler.js -> getAppoinmentFormData()':" + error);
        // }
    }
    const processFormData = (formData, data) => {
        let taskFilter = true;
        // fields = [];
        let fields = [];
        let moduleAccess = getObjectParam(app.me.module_access);
        // try {
        if (data.requestType == 'eventChanged') {
            updateAppointmentDnD(formData, data);
        } else {
            if (isValidParam(formData)) {

                let customFields = JSON.parse(JSON.stringify(formData.custom_fields));
                customFields = getArrayParam(customFields);

                let tempFields = filter(formData.fields, function (field) {
                    return find(customFields, function (fld) {
                        return (fld == field.name && field.name != 'location');
                    });
                });

                customFields.forEach(f => {
                    let searchFields = tempFields.filter(fld => { return fld.name == f; });
                    let searchField = searchFields.length > 0 ? searchFields[0] : null;
                    if (isValidParam(searchField)) {
                        fields.push(searchField);
                    }
                });
                let showReminder = formData.show_reminder;

                let fieldValue = {};
                let appId = 0;
                if (data.requestType == 'eventChanged') {
                    fieldValue = data.changedRecords[0]
                    appId = fieldValue.Id
                } else if (data.requestType == 'eventCreated') {
                    fieldValue = data.addedRecords[0]
                }
                let saveField = [];
                for (let i = 0; i < tempFields.length; i++) {
                    let saveObj = {};
                    let field = tempFields[i];
                    let value = '';
                    if (field.name == 'subject') {
                        value = fieldValue.Subject;
                    } else if (field.name == 'assign') {
                        let assignName = '';
                        let assign_user = fieldValue.ConferenceId[0];
                        selected_user.map((user, i) => {
                            if (user.value == assign_user) {
                                assignName = user.label;
                            }
                        });
                        value = assignName;
                    } else if (field.name == 'event_type') {
                        value = fieldValue.type;
                    } else if (field.name == 'company') {
                        value = fieldValue.Company;
                    } else if (field.name == 'fullname') {
                        value = fieldValue.Contact;
                    } else if (field.name == 'text') {
                        value = fieldValue.Description;
                    } else if (field.field_type == 'datetime') {
                        let dateValue = '';
                        if (field.name == 'start_date') {
                            dateValue = fieldValue.StartTime;
                        } else if (field.name == 'end_date') {
                            dateValue = fieldValue.EndTime;
                        }
                        value = moment(dateValue).format("YYYY-MM-DD HH:mm:ss");
                    } else {
                        value = field.default_value;
                    }
                    saveObj.id = field.id;
                    saveObj.name = field.name;
                    saveObj.value = value;
                    saveField.push(saveObj);
                }

                let params = {
                    id: appId,
                    fields: saveField,
                    is_repeat: false

                }

                let promise = saveRecordPromise(constants.APPOINTMENTS, params);
                promise.then((response) => {
                    if (isValidParam(response) && response.hasOwnProperty('data') && response.data.hasOwnProperty('id')) {
                        let id = isValidParam(response.data.id) && _.isArray(response.data.id) && response.data.id.length > 0 ? response.data.id[0] : 0;

                    }
                });
            }
        }
        // } catch (error) {
        //     console.error("Error in 'jobscheduler.js -> processFormData()':" + error);
        // }
    }
    const triggerAction = (args) => {
         try {
        let groupBy = 'Users';
        let _userlist = getArrayParam(userlist.data);

        let requestType = getStringParam(args.requestType);
        if (requestType == 'eventCreated') {
            getAppoinmentFormData(args);
        } else if (requestType == 'eventChanged') {
            let records = args.changedRecords[0];
            let selectedUser = _userlist.filter(f => { return f.value == records.userName });
            if (records.object != undefined && records.object == constants.BROADCAST_CAMPAIGNS_OBJECT) {
                args.cancel = true;
                return false;
            } else {
                if (records.Subject == 'Busy') {
                    args.cancel = true;
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.APPOINTMENTS_TASKS.PRIVATE_APPOINTMENT_DRAGE_ALERT, null);
                    return false;

                } else if (selectedUser[0].id != records.ConferenceId[0] && (groupBy == 'Users' || groupBy == 'Types')) {
                    args.cancel = true;
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "You cannot drag events in this view ", () => dispatch(getJobCalendarRefresh(true)));
                    return false;
                } else {
                    // let hasPermission = hasAccessPermission(constants.APPOINTMENTS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
                    // if (hasPermission) {
                    //     getAppoinmentFormData(args);
                    // } else {
                        args.cancel = true;
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "You cannot drag events in this view ", () => dispatch(getJobCalendarRefresh(true)));
                        return false;
                        
                    // }
                }
            }
        } else if (requestType == 'eventRemoved') {

        }
        else if (requestType == 'dateNavigate') {
            let view = 'Week';
            if (scheduleObj != null && scheduleObj != undefined) {
                let log = changeLog;
                log.getAllJobAppointmentsfromNavigate = false;
                setChangeLog({ ...log });
                view = scheduleObj.currentView;
                var dateRange = {};
                let dates = scheduleObj.activeView.renderDates;
                let datesCounts = dates.length;
                let startDate = formatDate(dates[0], 'start');
                let endDate = formatDate(dates[datesCounts - 1], 'end');
                dateRange.startDate = startDate;
                dateRange.endDate = endDate;
                changeView(view);
                dispatch(selectedDateRangeUpdate(dateRange));
                let log1 = changeLog;
                log1.selectedDateRange = dateRange;
                setChangeLog({ ...log1 });
                dispatch(setSelectedDate(scheduleObj.selectedDate));
                getJobSchedulerDetils('dateNavigate');
                dispatch(getJobCalendarRefresh(true));

            }
        }
        else if (requestType == 'viewNavigate') {
            let view = 'Week';
            if (scheduleObj != null && scheduleObj != undefined) {
                let log2 = changeLog;
                log2.getAllJobAppointmentsfromNavigate = false;
                setChangeLog({ ...log2 });
                view = scheduleObj.currentView;
                var dateRange = new Object;
                let dates = scheduleObj.activeView.renderDates;
                let datesCounts = dates.length;
                let startDate = formatDate(dates[0], 'start');
                let endDate = formatDate(dates[datesCounts - 1], 'end');
                dateRange.startDate = startDate;
                dateRange.endDate = endDate;
                changeView(view);
                dispatch(selectedDateRangeUpdate(dateRange));
                let log = changeLog;
                log.selectedDateRange = dateRange;
                setChangeLog({ ...log });
                dispatch(setSelectedDate(scheduleObj.selectedDate));
                getJobSchedulerDetils('viewNavigate');
                dispatch(getJobCalendarRefresh(true));

            }
        }
        } catch (error) {
            console.error("Error in 'jobscheduler.js -> triggerAction()':" + error);
        }
    }
    const onPopupOpen = (args) => {
        let data = args.data;
        let hasPermission = false;
        if (args.type === 'Editor') {
            args.cancel = true;
            data.endTime = data.EndTime;
            data.startTime = data.StartTime;
            if (data.Id > 0) {
                editAppointment(data);
            } else {
                addnewAppointment(data);
            }

        } else if (args.type == 'QuickInfo') {
            args.cancel = true;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "You cannot edit events in this view ", null);
            return false;
        } else if (args.type == 'DeleteAlert') {
            hasPermission = hasAccessPermission(constants.APPOINTMENTS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_DELETE);
            args.cancel = true;
            if (hasPermission) {
                if (data.ParentRecordId > 0) {
                    setSelectedId(data.Id);
                    dispatch(getAppDialog(true, constants.RECURRING_DELETE_DIALOG, getLocalizedStrings().message.REPEAT_DIALOG.DELETE_RECUR_TITLE, getRecurringDeleteChoice, null));
                } else {
                    sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.APPOINTMENTS_TASKS.DELETE, () => deleteRecord(data.Id, null), null);
                }
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
            }
        }
    }
    const changeView = (view) => {

        calendar.calendarInfo.schedulerView = view;

    }

    const tooltipShowTemplate = (props) => {
        // try {
        let momentStartDate = moment(props.StartTime);
        let momentEndDate = moment(props.EndTime);
        let eventDate = momentStartDate.format(dateFormat[app.me.date_format]);
        let eventStartTime = momentStartDate.format(timeFormat[app.me.date_format]);
        let eventEndTime = momentEndDate.format(timeFormat[app.me.date_format]);
        let appInfo = [];
        if (app.me.calendar_setup.length > 0 && app.me.calendar_setup[0].appInfo != null && app.me.calendar_setup[0].appInfo != undefined) {
            appInfo = app.me.calendar_setup[0].appInfo;
        }
        let isAccount = 1;
        let isContact = 0;
        let isOpportunity = 0;
        let isJob = 0;

        if (appInfo.length > 0) {
            let checkInfoArr = appInfo.split(',');

            checkInfoArr.map((item, i) => {
                let ObjRecordArr = item.split('_');
                let tableId = getIntParam(ObjRecordArr[0].trim());
                if (tableId == OBJECT_TABLEID_MAP.accounts) {
                    isAccount = getIntParam(ObjRecordArr[1]);
                } else if (tableId == OBJECT_TABLEID_MAP.contacts) {
                    isContact = getIntParam(ObjRecordArr[1]);
                } else if (tableId == OBJECT_TABLEID_MAP.opportunities) {
                    isOpportunity = getIntParam(ObjRecordArr[1]);
                } else if (tableId == OBJECT_TABLEID_MAP.jobs) {
                    isJob = getIntParam(ObjRecordArr[1]);
                }
            });
        }
        if (props.object != undefined && props.object == constants.BROADCAST_CAMPAIGNS_OBJECT) {
            return (
                <div className="tooltip-wrap">
                    <div className="content-area">
                        <div className="name">{props.Subject}</div>
                        <div className="time">{props.Status}</div>
                    </div>
                </div>
            );
        } else {
            if (props.Subject == 'Busy') {
                return false;
            } else {
                return (
                    <div className="tooltip-wrap">
                        <div className="content-area">
                            <div className="name">{props.Subject}</div>
                            <div className="time">{eventDate}</div>
                            <div className="time">{eventStartTime} - {eventEndTime}</div>
                            {(props.Company !== null && props.Company !== undefined && isAccount > 0) && <div className="company">{props.Company}</div>}
                            {(props.Contact !== null && props.Contact !== undefined && isContact > 0) && <div className="contact">{props.Contact}</div>}
                            {(props.OpprtunityName !== null && props.OpprtunityName !== undefined && isOpportunity > 0) && <div className="contact">{props.OpprtunityName}</div>}
                            {(props.JobName !== null && props.JobName !== undefined && isJob > 0) && <div className="JobName">{props.JobName}</div>}
                        </div>
                    </div>
                );
            }
        }
        // } catch (error) {
        //     console.error('Error in Calendar.js -> tooltipShowTemplate() : ' + error);
        // }
    }
    const deleteRecord = (id, delete_action_type) => {
        let params = {};
        params.id = [id];
        if (delete_action_type != null) {
            params.delete_action_type = delete_action_type;
        }

        // if (id > 0 && (props.object == constants.APPOINTMENTS || props.object == constants.NOTE_OBJECT
        //     || props.object == constants.TASKS)) {

        let linkObject = getEndPointObject(constants.APPOINTMENTS);
        let url = linkObject.DELETE;
        var promise = Promise.resolve(HTTPClient.post(url, params));
        promise.then((response) => {
            if (response.status == 0) {
                dispatch(getJobCalendarRefresh(true));
                dispatch(getAppDrawer(false, null, null, null, null));
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
            }
            else if (response.status == -1 && response.error.message != "") {
                dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        });
        // }
    }

    const getAvailableLocationListFnc = (callFrom) => {
        let calendarInfoFromRedux = getObjectParam(calendar.calendarInfo);
        let promise = Promise.resolve(getAvailableLocationList());
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    let _locationList = response.zipCodeList;
                    _locationList.unshift('None')

                    if (calendarInfoFromRedux.selectedLocation != null && calendarInfoFromRedux.selectedLocation != ""
                        && calendarInfoFromRedux.selectedLocation != 'None') {
                        setLocationList(_locationList);
                        setZipTextValue(calendarInfoFromRedux.selectedLocation);

                    } else {
                        setLocationList(_locationList);
                        setZipTextValue(_locationList[0]);
                    }
                }
            });
        }
    }
    const generateZipCodeMenuItems = () => {

        let listItems = locationList.map((_location, i) => {
            return (
                <MenuItem
                    key={i}
                    id={_location}
                    value={_location}
                    style={{ ...styles.popoverMenuItem }}
                    title={_location} >{_location}</MenuItem>
            );
        });

        return listItems;
    }
    const changeZipValue = (event) => {
        let value = event.target.value;
        setZipTextValue(value);
    }
    const changeDays = (event) => {
        let value = event.target.value;
        setSelectedDays(value);
    }
    const getAvailableUserList = () => {
        calendar.jobsAppData = [];
        let userIds = [];
        let allSelectedUser = [];
        let tmpUserlist = [];
        let params = {
            location: zipTextValue,
            day_id: selectedDays
        }
        var promise = Promise.resolve(HTTPClient.get(endPoints.COMPANY_SCHEDULER.GET_AVAILABLE_USERS, params));
        promise.then((response) => {
            if (isValidParam(response)) {
                userIds = response.selectedUserIds;
                let _userList = usersList;
                let singleSelectedValue = [];
                userIds.map((fld, i) => {
                    singleSelectedValue = _userList.filter(e => {
                        return e.id == fld;
                    });
                    allSelectedUser.push(singleSelectedValue[0]);
                });
                if (allSelectedUser.length > 0) {
                    allSelectedUser.map((usr, i) => {
                        let userObj = {};
                        userObj.value = usr.id;
                        userObj.label = usr.value;
                        userObj.color = usr.colorCode;
                        userObj.profileImage = usr.profile_image_url;
                        tmpUserlist.push(userObj);
                    })
                }

                setAvailableUser(tmpUserlist);
                setUserTimeAvailability(response.user_availability_info);
                setDataFetched(false);
                dispatch(userTimeAvailabilityUpdate(response.user_availability_info));
                calendar.calendarInfo.availableUser = tmpUserlist;

                if (zipTextValue != 'None') {
                    calendar.calendarInfo.selectedLocation = zipTextValue;
                    calendar.calendarInfo.userTimeAvailability = response.user_availability_info;
                }
                calendar.calendarInfo.selectedDays = selectedDays;
                getJobSchedulerDetils('filter');
            }

        })
    }

    const generatePendingJob = (_arr) => {
        let tempObj = { dataSource: [], id: 'jobId', text: 'jobName' };
        tempObj.dataSource = _arr;
        return tempObj

    }

    if (calendar.mounted && !calendar.isJobCalendarRefresh && dataFetched) {
        let colorCodeField = 'Types';
        let defaultView = calendar.calendarInfo.schedulerView ? calendar.calendarInfo.schedulerView : 'Day';
        defaultView = defaultView.charAt(0).toUpperCase() + defaultView.slice(1, defaultView.length)
        let showGroupBy = { allowGroupEdit: true, resources: ['Conferences'], enableCompactView: false }
        let updatedEventArr = setAppoinmentData();
        let zipCodeMenuItems = generateZipCodeMenuItems();


        let daysMenuItems = null;
        daysMenuItems = days.map((d, index) => {
            return <MenuItem key={d.value} value={d.value} style={styles.popoverMenuItem} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={d.label}>{d.label}</div>}</MenuItem>
        });
        
        let _pendingJobDataObj = generatePendingJob(changeLog.pendingJob)
        console.log("_pendingJobDataObj",_pendingJobDataObj)
        return (
            <div style={styles.workAreaContainer} >
                <div style={{ marginLeft: '12px', marginTop: '-10px' }}>
                    <div style={{ marginLeft: '-1px', marginTop: '3px', display: 'flex', marginBottom: '7px' }}>
                        <FormControl variant="outlined" style={{ width: '17%' }} >
                            <InputLabel id="sf-schedulerinfo-simple-select-outlined-label" className="sf-schedulerinfo"> Locations </InputLabel>
                            <Select
                                key="Locations"
                                id='Locations'
                                label={'Locations'}
                                variant="outlined"
                                name='Locations'
                                style={{ height: '40px' }}
                                value={zipTextValue}
                                onChange={(e) => changeZipValue(e)} >
                                {zipCodeMenuItems}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" style={{ width: '17%', marginLeft: '17px' }}>
                            <InputLabel id="sf-schedulerinfo-simple-select-outlined-label" className="sf-schedulerinfo"> Day of the week </InputLabel>
                            <Select
                                key="Days"
                                id='Days'
                                label={'Day of the week'}
                                variant="outlined"
                                name='Days'
                                style={{ height: '40px' }}
                                value={selectedDays}
                                onChange={(e) => changeDays(e)} >
                                {daysMenuItems}
                            </Select>
                        </FormControl>

                        <Button
                            key='Go'
                            style={{ ...styles.primaryButton, display: 'flex', alignItems: 'center', marginTop: '5px', marginLeft: '15px' }}
                            onClick={() => getAvailableUserList()}
                        >{getLocalizedStrings().label.REPORT.GO}</Button>

                    </div>
                </div>
                <div style={{ paddingLeft: '10', paddingRight: '10', width: '100%', display: 'flex' }}>

                    <div style={{ width: calendarViewControl.left }} >
                        <div className="card" style={{ width: '100%' }} >
                            <div className='inner-card-body' >

                                {(colorCodeField == "Types") &&
                                    <div>
                                        <div style={{ width: '100%', display: 'flex', padding: '7px', height: '42px' }}>
                                            {!calendarViewControl.leftMin && <div style={{ width: '100%' }}>
                                                <Typography variant="h6">
                                                    Types
                                                </Typography>
                                            </div>}
                                            <div style={{ width: '50px', marginTop: '-7px' }}>
                                                <IconButton onClick={() => { minimizeLeftRightCard("left") }} title={calendarViewControl.leftMin ? 'Expand' : 'Collapse'}>
                                                    {calendarViewControl.leftMin ? <i class="fas fa-angle-right"></i> : <i class="fas fa-angle-left"></i>}
                                                </IconButton>
                                            </div>
                                        </div>
                                        <Divider light />
                                        <div style={{ overflow: 'scroll', height: calendarHeight - 45 }}>
                                            {!calendarViewControl.leftMin && appTypes.length > 0 &&
                                                <List >
                                                    {appTypes.map(option => (
                                                        <ListItem >
                                                            {colorCodeField == "Types" && <span style={{ backgroundColor: option.color, ...colorBoxStyle }} />}
                                                            <ListItemText primary={option.label} />
                                                        </ListItem>))}
                                                </List>
                                            }
                                        </div>
                                    </div>
                                }

                            </div>

                        </div>
                    </div>
                    <div style={{ width: '100%' }} >
                        <div className='schedule-control-section'>
                            <div className=' control-section'>
                                <div className='control-wrapper drag-sample-wrapper'>
                                    <div className="schedule-container card" style={{ width: '100%' }}>
                                        {calendar.mounted && dataFetched &&
                                            <ScheduleComponent
                                                showQuickInfo={true} cssClass='schedule-drag-drop'
                                                currentView={defaultView}
                                                selectedDate={new Date(calendar.selectedDate)}
                                                resourceHeaderTemplate={resourceHeaderTemplate.bind(this)}
                                                height={calendarHeight}
                                                ref={schedule => scheduleObj = schedule}
                                                allowResizing={false}
                                                eventSettings={{
                                                    dataSource: updatedEventArr,
                                                    fields: {
                                                        id: 'Id',
                                                        subject: { name: 'Subject', title: 'Event Name', value: 'Follow' },
                                                        location: { name: 'Company', title: 'Company' },
                                                        description: { name: 'Description', title: 'Event Description' },
                                                        startTime: { name: 'StartTime', title: 'Start Duration' },
                                                        endTime: { name: 'EndTime', title: 'End Duration' },
                                                        attendees: { name: 'Attendees', title: 'Assign To' }
                                                    },
                                                    enableTooltip: true,
                                                    tooltipTemplate: tooltipShowTemplate.bind(this)
                                                }}
                                                group={showGroupBy}
                                                workHours={{
                                                    start: startWkHr,
                                                    end: endWkHr
                                                }}

                                                eventRendered={onEventRendered.bind(this)}
                                                cellDoubleClick={addnewAppointment.bind(this)}
                                                actionComplete={triggerAction.bind(this)}
                                                popupOpen={onPopupOpen.bind(this)}
                                                actionBegin={onActionBegin.bind(this)}
                                                drag={onItemDrag.bind(this)}
                                            >

                                                <ResourcesDirective>
                                                    <ResourceDirective field='ConferenceId' title='Attendees' name='Conferences' allowMultiple={true} dataSource={getGroupByResourseData()} textField='label' idField='value' ></ResourceDirective>
                                                </ResourcesDirective>
                                                <ViewsDirective>
                                                    <ViewDirective option='Day' />
                                                    <ViewDirective option='Week' />
                                                    <ViewDirective option='WorkWeek' workDays={workDays} />
                                                    <ViewDirective option='Month' />
                                                    <ViewDirective option='Agenda' />
                                                </ViewsDirective>
                                                <Inject services={[Day, Week, WorkWeek, Month, Agenda, Resize, DragAndDrop]} />
                                            </ScheduleComponent>
                                        }
                                    </div>
                                    <div className="treeview-container card" style={{ width: calendarViewControl.right }}>
                                        <div style={{ width: '100%', display: 'flex', padding: '7px', height: '42px' }}>

                                            {!calendarViewControl.rightMin && <div style={{ width: '100%' }}>
                                                <Typography variant="h6">
                                                    Unscheduled Jobs
                                                </Typography>
                                            </div>}
                                            <div style={{ width: '50px', marginTop: '-7px' }}>
                                                <IconButton onClick={() => { minimizeLeftRightCard("right") }} title={calendarViewControl.rightMin ? 'Expand' : 'Collapse'}>
                                                    {!calendarViewControl.rightMin ? <i class="fas fa-angle-right"></i> : <i class="fas fa-angle-left"></i>}
                                                </IconButton>
                                            </div>
                                        </div>
                                        <Divider light />
                                        <div style={{ overflow: 'scroll', height: calendarHeight - 45 }}>
                                            <div>
                                                  {_pendingJobDataObj.dataSource != null && _pendingJobDataObj.dataSource != undefined && _pendingJobDataObj.dataSource.length > 0 && !calendarViewControl.rightMin &&
                                                    <div>
                                                        <TreeViewComponent
                                                            ref={tree => treeObj = tree}
                                                            cssClass='treeview-external-drag'
                                                            dragArea=".drag-sample-wrapper"
                                                            nodeTemplate={treeTemplate.bind(this)}
                                                            fields={_pendingJobDataObj}
                                                            nodeDragStop={onTreeDragStop.bind(this)}
                                                            nodeDragging={onItemDrag.bind(this)}
                                                            allowDragAndDrop={allowDragAndDrops}
                                                        />
                                                    </div>}
                                            </div>
                                            <div>
                                                {(_pendingJobDataObj.dataSource != null && _pendingJobDataObj.dataSource != undefined && _pendingJobDataObj.dataSource.length == 0 && !calendarViewControl.rightMin) &&
                                                    <span style={{ marginLeft: '23px', color: '#5c5252' }}>There are no unscheduled jobs</span>

                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        );
    } else {
        return (<div style={{ width: '100%', height: calendarHeight }}>
            {<div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }} >
                <div style={{ ...styles.assetLoaderContainer, height: 55, width: 55, padding: 6 }} >
                    <ShowCircularProgress size={35} style={{ marginTop: '4', marginLeft: '4' }} />
                </div>
            </div>
            }
        </div>);
    }
}

export default JobScheduler;