import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { TextFields } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogTitle,
    FormLabel,
    DialogContent,
    Checkbox,
    DialogActions,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormControl,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';


const SignatureDialog = (props) => {
    const {setSignature, getSignatureData, fullName} = props
    const detailiew = useSelector((state) => state.detailView.data);

    let initialState = {
        agree_checked: false,
        user_signature: fullName ? fullName : detailiew?.full_name,
        open: true,
        fontFamilyName: '',
        isBtnDisabled: true,
    };

    let [state, setState] = React.useState(initialState);
    let [isEditName,setIsEditName] = useState(false);

    const handleClose = () => {
        if (!props.isAgreementDownloading) {
            setState({ open: false });
            setSignature(false);
        }
    };

    const setFieldValue = (event) => {
        setState({ open: state.open, agree_checked: state.agree_checked, user_signature: event.target.value, isBtnDisabled: state.isBtnDisabled });
    };

    const handleRadioChange = (event) => {
        setState({
            open: state.open,
            user_signature: state.user_signature,
            agree_checked: state.agree_checked,
            fontFamilyName: event.target.value,
            isBtnDisabled: state.isBtnDisabled,
        });
    };

    const handleCheckBoxChange = (event) => {
        setState({
            open: state.open,
            user_signature: state.user_signature,
            agree_checked: event.target.checked,
            fontFamilyName: state.fontFamilyName,
            isBtnDisabled: state.isBtnDisabled,
        });
    };

    const handleSign = (event) => {
        handleClose();
        if (state.user_signature != null && state.user_signature != '' && state.user_signature != 'undefined') {
            let signObj = { fontFamily: state.fontFamilyName, userSign: state.user_signature };
            getSignatureData(signObj);
        } else {
            alert('Please enter signature.');
        }
    };

    const handleOnchangeFunc = () =>{
        setIsEditName(true);
    }

    const handleTextFieldOnChange = (event) =>{
        setState({...state,user_signature:event.target.value});
    }
    const handleOnBlurTextfieldFun = () =>{
        if(state.user_signature.length === 0){
            setState({...state,user_signature: fullName ? fullName : detailiew?.full_name});

        }
        setIsEditName(false);
    }

    let getFields = () => {
        let div = (
            <div>
                <div>Confirm Your Name</div>
                <div style={{ marginTop: '10px' }}>
                    {!isEditName ? <span style={{fontFamily:state.fontFamilyName,fontWeight:'bold',cursor:'pointer'}} onClick={()=>handleOnchangeFunc()}>{state.user_signature}</span>
                    :
                    <div>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                            >
                            <TextField 
                                id="outlined-basic" 
                                variant="outlined" 
                                value={state.user_signature}
                                defaultValue={state.user_signature}
                                onChange={handleTextFieldOnChange}
                                onBlur={handleOnBlurTextfieldFun}
                                size='small'
                            />
                            </Box>
                    </div>
                    }
                </div>

                <div style={{ marginTop: '25px' }}>
                    <span> Choose a signature </span>
                    <div className="signoption-radios">
                        <FormControl component="fieldset">
                            <FormLabel component="legend"></FormLabel>
                            <RadioGroup
                                aria-label="aggr_sign"
                                name="aggr_sign"
                                value={state.fontFamilyName}
                                onChange={handleRadioChange}
                                style={{ flexDirection: 'row' }}>
                                <div style={{ width: '32%', display: 'flex', flexDirection: 'column' }}>
                                    <FormControlLabel
                                        value="'Dancing Script', cursive"
                                        style={{ width: '72%' }}
                                        control={<Radio color="default" />}
                                        label={
                                            <span
                                                className="radio-label"
                                                title={state.user_signature}
                                                style={{ fontFamily: "'Dancing Script', cursive" }}>
                                                {state.user_signature}
                                            </span>
                                        }
                                    />
                                    <FormControlLabel
                                        value="'Architects Daughter', cursive"
                                        control={<Radio color="default" />}
                                        label={
                                            <span
                                                className="radio-label"
                                                title={state.user_signature}
                                                style={{ fontFamily: "'Architects Daughter', cursive" }}>
                                                {state.user_signature}
                                            </span>
                                        }
                                    />
                                    <FormControlLabel
                                        value="'Sacramento', cursive"
                                        control={<Radio color="default" />}
                                        label={
                                            <span
                                                className="radio-label"
                                                title={state.user_signature}
                                                style={{ fontFamily: "'Sacramento', cursive" }}>
                                                {state.user_signature}
                                            </span>
                                        }
                                    />
                                </div>
                                <div style={{ width: '32%', display: 'flex', flexDirection: 'column' }}>
                                    <FormControlLabel
                                        value="'Cedarville Cursive', cursive"
                                        control={<Radio color="default" />}
                                        label={
                                            <span
                                                className="radio-label"
                                                title={state.user_signature}
                                                style={{ fontFamily: "'Cedarville Cursive', cursive" }}>
                                                {state.user_signature}
                                            </span>
                                        }
                                    />
                                    <FormControlLabel
                                        value="'Mr Dafoe', cursive"
                                        control={<Radio color="default" />}
                                        label={
                                            <span className="radio-label" title={state.user_signature} style={{ fontFamily: "'Mr Dafoe', cursive" }}>
                                                {state.user_signature}
                                            </span>
                                        }
                                    />
                                    <FormControlLabel
                                        value="'Kristi', cursive"
                                        control={<Radio color="default" />}
                                        label={
                                            <span className="radio-label" title={state.user_signature} style={{ fontFamily: "'Kristi', cursive" }}>
                                                {state.user_signature}
                                            </span>
                                        }
                                    />
                                </div>
                                <div style={{ width: '32%', display: 'flex', flexDirection: 'column' }}>
                                    <FormControlLabel
                                        value="'Calligraffitti', cursive"
                                        control={<Radio color="default" />}
                                        label={
                                            <span
                                                className="radio-label"
                                                title={state.user_signature}
                                                style={{ fontFamily: "'Calligraffitti', cursive" }}>
                                                {state.user_signature}
                                            </span>
                                        }
                                    />
                                    <FormControlLabel
                                        value="'La Belle Aurore', cursive"
                                        control={<Radio color="default" />}
                                        label={
                                            <span
                                                className="radio-label"
                                                title={state.user_signature}
                                                style={{ fontFamily: "'La Belle Aurore', cursive" }}>
                                                {state.user_signature}
                                            </span>
                                        }
                                    />
                                    <FormControlLabel
                                        value="'Crafty Girls', cursive"
                                        control={<Radio color="default" />}
                                        label={
                                            <span
                                                className="radio-label"
                                                title={state.user_signature}
                                                style={{ fontFamily: "'Crafty Girls', cursive" }}>
                                                {state.user_signature}
                                            </span>
                                        }
                                    />
                                </div>
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <div className="checkbox-content" style={{display:'inline-flex'}}> 
                    <div>
                        <Checkbox
                            checked={state.agree_checked}
                            onChange={handleCheckBoxChange.bind(this)}
                            color="default"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            style={{marginLeft:'-10px'}}
                        />
                    </div>
                    <div style={{ width: '455px' }}>
                        By clicking Adopt and Sign, I agree that the signature will be the electronic representation of my signature for all purposes
                        when I use them on documents - the same as a pen-and-paper signature.
                    </div>
                </div>
            </div>
        );

        return div;
    };
    let fields = getFields();
    let isDisabled = state.isBtnDisabled;

    if (state.agree_checked && state.fontFamilyName != null && state.fontFamilyName.length > 0) {
        isDisabled = false;
    }

    let btnStyle = { backgroundColor: '#EE5624', color: '#FFFFFF' };
    if (isDisabled) {
        btnStyle = { border: '1px solid #cccccc', backgroundColor: '#cccccc', color: '#FFFFFF' };
    }
    let modalheader = 'Adopt a signature and sign the agreement';
    
    return (
        <div key="sign-dialog">
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={state.open}>
                <DialogTitle id="customized-dialog-title" onClose={ handleClose}>
                    <div style={{display:'flex'}}>
                        <div
                            className="modal-header"
                            style={{
                                marginTop: 10,
                                display: 'block',
                                alignItems: 'center',
                            }}
                            title={modalheader}>
                            {modalheader}
                        </div>
                        <div style={{width:'50px',float:'right',alignItems:'right',textAlign:'right',alignSelf:'right',marginLeft:'20px'}}>
                            <IconButton style={{ marginTop: '8px' }} aria-label="close" onClick={()=>handleClose()}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent dividers={!props.isAgreementDownloading}>
                    {props.isAgreementDownloading ? (
                        <div style={{ marginBottom: '1rem' }}>
                            Thank you for signing this agreement. Please wait until we generate the signed PDF.
                        </div>
                    ) : (
                        fields
                    )}
                </DialogContent>
                <DialogActions style={{ marginRight: 5 }}>
                    <Button onClick={handleSign} style={btnStyle} disabled={isDisabled}>
                        Adopt and Sign
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SignatureDialog;
