import React from "react";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import ShowCircularProgress from "../components/circularProgress";
import { styles } from "../../../services/constants/styles";
import { Button, Checkbox, Select, FormControl, MenuItem, ListItemText, TextField, InputLabel, ListItem, ListItemIcon, FormControlLabel, RadioGroup, Radio } from "@mui/material";
import * as sfDialogs from "../components/sfDialogs";
import { COUNTRY_CODE_MAP, constants } from "../../../services/constants/constants";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import VerifiedIcon from '@mui/icons-material/Verified';
import {
	purchasePhoneNumber,
	removePhoneNumber,
	getAllocatePhoneNumbers,
	getImportTwilioPhoneNumbers,
	checkPhoneNumberStatus,
	updateRedirectNumber,
	getAvailablePhoneNumbers,
	saveImportTwilioPhoneNumbers
} from "../../../services/actions/smsAction";
import {
	isValidParam,
	getStringParam,
	getArrayParam,
	getObjectParam,
	getBooleanParam,
	getIntParam,
	isInteger,
} from "../../../services/helper/parameterVerifier";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import Icon from "@mui/material/Icon";
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { orange } from "@mui/material/colors";
import { refreshListView } from "../../../services/actions/listViewAcions";
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';

const SMSIntegration = ({ }) => {
	const dispatch = useDispatch();
	const appState = useSelector((state) => state.app);
	const appContainer = useSelector((state) => state.appContainer);
	const drawerData = appContainer.drawerProps.data ?? {}
	const [isLoaded, setIsLoaded] = useState(false);
	const [zipCode, setZipCode] = useState(null);
	const [areaCode, setAreaCode] = useState(null);
	const [country, setCountry] = useState('US');
	const [leaseRate, setLeaseRate] = useState('1.99');
	const [phoneNumbers, setPhoneNumbers] = useState([]);
	const [twilioNumbers, setTwilioNumbers] = useState([]);
	const [unitList, setUnitList] = useState([]);
	const [noType, setNoType] = useState('toll-free');
	const [isTollFreeNoAvailable, setTollFreeNoAvailable] = useState(true);
	const [areaCodeOrZipCode, setAreaCodeOrZipCode] = useState(null);
	const [countryISO2, setCountryISO2] = useState(null);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [openTooltipAnchorEl, setOpenTooltipAnchorEl] = useState(null);
	const [isTipOpen, setIsTipOpen] = useState(false);
	const [showNumberHeading, setShowNumberHeading] = useState(true);
	const [selectedTwilioImportNo, setSelectedTwilioImportNo] = useState({});
	const [isValidTwilioAccount, setIsValidTwilioAccount] = useState(true);

	useEffect(() => {
		if (drawerData.action_type == 'import' && drawerData.action_mode == 'add') {
			getAvailableTwilioNumbersData();
		} else if (drawerData.action_type == 'lease' || (drawerData.action_mode == 'edit')) {
			getAvailablePhoneNumbersData();
		}
		getCountryISO();
		let tenantCountry = getStringParam(appState.me.tenant_country);
		if (tenantCountry !== "USA" && tenantCountry !== 'Canada') {
			setNoType('local');
			setTollFreeNoAvailable(false);
			setLeaseRate('1.99');
		}
		const isManageUnitPhone = appState.me.is_manage_unit_phone;
		const isFranchisor = appState.me.is_franchisor;
		if (isManageUnitPhone && isFranchisor) {
			let url = endPoints.SMS_INTEGRATION.GET_FE_DETAILS;
			let params = {};
			let promise = Promise.resolve(HTTPClient.get(url, params));
			promise.then((response) => {
				if (isValidParam(response.fe_details)) {
					setUnitList(response.fe_details);
				}
			})
		}
	}, []);

	const getAvailableTwilioNumbersData = () => {
		try {
			let promise = Promise.resolve(getImportTwilioPhoneNumbers());
			if (isValidParam(promise)) {
				promise.then(response => {
					if (isValidParam(response)) {
						if (!isLoaded) {
							setIsLoaded(true);
						}
						let _twilioNumbers = getArrayParam(response.twilio_numbers);
						let _isValidTwilioAccount = getBooleanParam(response.is_valid_twilio_account);
						setTwilioNumbers([..._twilioNumbers]);
						setIsValidTwilioAccount(_isValidTwilioAccount);

					}
				});
			}
		} catch (error) {
			console.error("Error in 'smsIntegration.js -> getAvailablePhoneNumbers()':" + error);
		}
	}



	const getCountryISO = () => {
		let countryISO2Arr = JSON.parse(
			JSON.stringify(getArrayParam(appState.countryISO2))
		);
		let tenantCountry = getStringParam(appState.me.tenant_country);
		countryISO2Arr = countryISO2Arr.filter((f) => {
			return f.t_country === tenantCountry;
		});
		let countryISO2 =
			countryISO2Arr.length > 0 ? countryISO2Arr[0].t_country_iso2 : "";
		if (countryISO2 !== null && countryISO2 !== "") {
			setCountryISO2(countryISO2.toUpperCase());
		}
	};
	const handleTooltipClose = () => {
		setIsTipOpen(false);
	};
	const handleTooltipOpen = () => {
		setIsTipOpen(true);
	};
	const handleNOTypeChange = (value) => {
		setNoType(value);
		setLeaseRate(value === 'toll-free' ? '1.99' : '1.99');
		getAvailablePhoneNumbersData();
		setShowNumberHeading(true);
	}

	const getComponentToAdd = () => {
		let component = null;
		let label = (
			<p style={{ lineHeight: 1.2, fontFamily: "arial", color: "#717171" }}>
				{getLocalizedStrings().label.TWILIO_SMS.NO_NUMBER_PURCHASED}
			</p>
		);
		let inputLabel = null;
		let tooltipLabel = null;
		let tempPhoneNumbers = getArrayParam(phoneNumbers);
		let textFieldProps = {};
		textFieldProps.underlineFocusStyle = styles.textField.underlineFocus;
		textFieldProps.floatingLabelStyle = {
			...styles.textField.floatingLabel,
			top: "10px",
			color: "#999999",
			fontWeight: "normal",
		};
		textFieldProps.floatingLabelShrinkStyle = {
			...styles.textField.floatingLabelShrink,
			transform: "scale(0.7) translate(0px, -13px)",
		};
		textFieldProps.floatingLabelFocusStyle = {
			...styles.textField.floatingLabelFocus,
			transform: "scale(0.7) translate(0px, -13px)",
		};
		textFieldProps.style = {
			border: "1px solid rgb(221, 221, 221)",
			backgroundColor: "#fff",
			height: "42px",
			width: "100px%",
		};
		textFieldProps.inputStyle = {
			marginTop: "6px",
			color: "#333",
			fontSize: "15px",
			fontWeight: "normal",
			height: "100%",
		};
		textFieldProps.fullWidth = true;
		textFieldProps.underlineShow = false;
		textFieldProps.maxLength = 64;

		let me = getObjectParam(appState.me);
		let tenantCountry = getStringParam(me.tenant_country);

		let elements = [];
		let selectorEelements = (
			<FormControl style={{ width: '100%' }}>
				<RadioGroup
					row
					aria-labelledby="demo-row-radio-buttons-group-label"
					name="row-radio-buttons-group"
					value={noType}
					onChange={(event) => {
						handleNOTypeChange(event.target.value);
					}}
				>
					{isTollFreeNoAvailable && <FormControlLabel value="toll-free" control={<Radio color="default" />} label={getLocalizedStrings().label.COMMON.TOLL_FREE} />}
					<FormControlLabel value="local" control={<Radio color="default" />} label={getLocalizedStrings().label.COMMON.LOCAL} />
				</RadioGroup>
			</FormControl>
		)
		let language = getLocalizedStrings()._language;

		if (tenantCountry === "USA" || tenantCountry === "Canada") {

			inputLabel = getLocalizedStrings().label.TWILIO_SMS.FIND_PHONE_NUMBER;
			tooltipLabel = getLocalizedStrings().message.SMS_INTEGRATION.SMS_TOOLTIP_MESSAGE;
			if (noType == 'toll-free') {
				inputLabel = getLocalizedStrings().label.TWILIO_SMS.FIND_TOLL_FREE_NUMBERS;
				tooltipLabel = getLocalizedStrings().message.SMS_INTEGRATION.TOLL_FREE_TOOLTIP_MESSAGE;
			}
			if (noType == 'local') {
				elements.push(
					<FormControl
						style={{ width: "25%" }}
						className="test"
						noValidate
						autoComplete="off"
					>
						<TextField
							variant="outlined"
							size='small'
							key="zip-code"
							label={getLocalizedStrings().label.TWILIO_SMS.ZIP_CODE}
							name="zipCode"
							value={zipCode}
							onChange={(event) => setFieldValue(event, "zipCode")}
							disabled={
								isValidParam(areaCode) && areaCode !== "" ? true : false
							}
							autoComplete="new-password"
							className={"sf-fields-bg"}
							inputprops={{
								style: { height: "4px", fontSize: "12px" },
							}}
						/>
					</FormControl>

				);
				elements.push(
					<span
						style={{ position: "relative", marginRight: 10, marginLeft: 10, paddingTop: 10, fontSize: '14px', width: '20px' }}
					>
						{" "}
						{getLocalizedStrings().label.TWILIO_SMS.OR}{" "}
					</span>
				);
				elements.push(
					<FormControl
						style={{ width: "25%" }}
						className="test"
						noValidate
						autoComplete="off"
					>
						<TextField
							variant="outlined"
							size='small'
							key="area-code"
							label={getLocalizedStrings().label.TWILIO_SMS.AREA_CODE}
							fontSize="12px"
							name="areaCode"
							value={areaCode}
							onChange={(event) => setFieldValue(event, "areaCode")}
							disabled={isValidParam(zipCode) && zipCode !== "" ? true : false}
							autoComplete="new-password"
							className={"sf-fields-bg"}
							inputprops={{
								style: { height: "4px", fontSize: "12px" },
							}}
						/>
					</FormControl>
				);
			} else {
				elements.push(
					<FormControl
						style={{ width: "58%" }} size="small">
						<InputLabel id="demo-simple-select-helper-label">{getLocalizedStrings().label.COMMON.COUNTRY}</InputLabel>
						<Select
							labelId="demo-simple-select-helper-label"
							id="demo-simple-select-helper"
							value={country}
							label={getLocalizedStrings().label.COMMON.COUNTRY}
							onChange={(event) => {
								setCountry(event.target.value);
							}}
						>
							<MenuItem value={'US'}>USA</MenuItem>
							<MenuItem value={'CA'}>Canada</MenuItem>
						</Select>
					</FormControl>
				);
			}
			elements.push(
				<Button
					onClick={() => findNumbers()}
					style={{ ...styles.primaryButton, marginLeft: 15, height: 40, width: '3px', fontSize: '14px' }}
				>
					{getLocalizedStrings().label.COMMON.FIND}
				</Button>
			);
			elements.push(
				<div style={{ width: '20px' }}>
					<ClickAwayListener onClickAway={handleTooltipClose}>
						<Tooltip
							PopperProps={{
								disablePortal: true,
							}}
							onClose={handleTooltipClose}
							open={isTipOpen}
							disableFocusListener
							disableHoverListener
							disableTouchListener
							title={tooltipLabel}
							placement="top"
							arrow
						>

							<Icon
								id='clickButton'
								color="primary"
								title={getLocalizedStrings().label.DETAIL_VIEW.CLICK_TO_KNOW_MORE}
								style={{ fontSize: "25px", verticalAlign: "middle", marginTop: '6px' }}
								onClick={handleTooltipOpen}
							>
								info_outline
							</Icon>
						</Tooltip>
					</ClickAwayListener>
				</div>

			);
		} else {
			inputLabel = getLocalizedStrings().label.TWILIO_SMS.FIND_PHONE_NUMBER_BY_AREA_CODE;
			tooltipLabel = getLocalizedStrings().message.SMS_INTEGRATION.SMS_TOOLTIP_MESSAGE_AREA_CODE; //'Find by area code';
			let textFieldWidth = "70%";
			if (language === "es-ES" || language === "pt-PT") {
				textFieldWidth = "62%";
			} else if (language === "vi-VN") {
				textFieldWidth = "68%";
			}
			if (noType == 'local') {
				elements.push(
					<FormControl
						style={{ width: textFieldWidth }}
						className="test"
						noValidate
						autoComplete="off"
					>
						<TextField
							variant="outlined"
							size='small'
							key="area-code"
							label={getLocalizedStrings().label.TWILIO_SMS.AREA_CODE}
							name="areaCode"
							value={areaCode}
							onChange={(event) => setFieldValue(event, "areaCode")}
							autoComplete="new-password"
							className={"sf-fields-bg"}
							inputprops={{
								style: { height: "4px", fontSize: "15px" },
							}}
						/>
					</FormControl>
				);
			} else {
				elements.push(
					<FormControl
						style={{ width: "58%" }} size="small">
						<InputLabel id="demo-simple-select-helper-label">Country</InputLabel>
						<Select
							labelId="demo-simple-select-helper-label"
							id="demo-simple-select-helper"
							value={country}
							label="Country"
							onChange={(event) => {
								setCountry(event.target.value);
							}}
						>
							<MenuItem value={'US'}>USA</MenuItem>
							<MenuItem value={'CA'}>Canada</MenuItem>
						</Select>
					</FormControl>
				);
			}
			elements.push(
				<Button
					onClick={() => findNumbers()}
					style={{ ...styles.primaryButton, marginLeft: 15, height: 42, width: '3px', fontSize: '14px', marginTop: '0px' }}
				>
					{getLocalizedStrings().label.COMMON.FIND}
				</Button>
			);
			elements.push(

				<div style={{ width: '20px' }}>
					<ClickAwayListener onClickAway={handleTooltipClose}>
						<Tooltip
							PopperProps={{
								disablePortal: true,
							}}
							onClose={handleTooltipClose}
							open={isTipOpen}
							disableFocusListener
							disableHoverListener
							disableTouchListener
							title='Find by area code'
							placement="top"
							arrow
						>

							<Icon
								id='clickButton'
								color="primary"
								title={getLocalizedStrings().label.DETAIL_VIEW.CLICK_TO_KNOW_MORE}
								style={{ fontSize: "25px", verticalAlign: "middle", marginTop: '8px' }}
								onClick={handleTooltipOpen}
							>
								info_outline
							</Icon>
						</Tooltip>
					</ClickAwayListener>
				</div>
			);
		}

		component = (
			<div>
				{isLoaded && tempPhoneNumbers.length === 0 && <div>{label}</div>}

				<div style={{ fontFamily: "arial", color: "#717171", fontSize: '14px' }}>
					<span>{inputLabel}</span>
				</div>
				<div className="row" style={{ marginLeft: "0px", marginTop: "10px" }}>
					{selectorEelements}
				</div>
				<div className="row" style={{ marginLeft: "0px", marginTop: "10px" }}>
					{elements}
					<Popover
						id={"tool-tip-msg"}
						open={openTooltip}
						anchorEl={openTooltipAnchorEl}
						onClose={(event) => handleCloseToolTip(event)}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						transformOrigin={{
							vertical: "bottom",
							horizontal: "right",
							left: '1362px',
						}}
					>
						<div
							style={{
								height: "30px",
								backgroundColor: "#707171",
								color: "white",
								padding: "5px",

							}}
						>
							{tooltipLabel}{" "}
						</div>
					</Popover>
				</div></div>
		);
		return component;
	};

	const handleImportSelect = (item) => {
		let tempData = { ...selectedTwilioImportNo }
		if (tempData[item.twilio_sid]) {
			delete tempData[item.twilio_sid];
		} else {
			tempData = { ...tempData, [item.twilio_sid]: item };
		}
		setSelectedTwilioImportNo({ ...tempData });
	}

	const updateSelectedData = (key, fieldName, value) => {
		let tempData = { ...selectedTwilioImportNo }
		if (tempData[key]) {
			tempData = { ...tempData, [key]: { ...tempData[key], [fieldName]: value } };
			setSelectedTwilioImportNo({ ...tempData });
		}
	}

	const handleImportSave = () => {
		let _selectedNo = [];
		Object.keys(getObjectParam(selectedTwilioImportNo)).forEach((e) => {
			if (selectedTwilioImportNo[e]) {
				_selectedNo.push(selectedTwilioImportNo[e]);
			}
		});
		if (_selectedNo.length > 0) {
			let params = {};
			params.phone_numbers_details = _selectedNo;
			let importPromise = Promise.resolve(saveImportTwilioPhoneNumbers(params));
			if (isValidParam(importPromise)) {
				importPromise.then((response) => {
					if (isValidParam(response)) {
						if (response.status === 0) {
							refreshListView(constants.PHONE_NUMBERS_OBJECT);
							dispatch(showCustomSnackBar(getLocalizedStrings().message.SMS_INTEGRATION.TWILIO_NUMBER_IMPORTED_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
							handleImportCancel();
						} else {
							dispatch(showCustomSnackBar(getLocalizedStrings().message.SMS_INTEGRATION.TWILIO_NUMBER_IMPORTED_ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
						}
					}
				});
			}
		} else {
			dispatch(showCustomSnackBar(getLocalizedStrings().message.SMS_INTEGRATION.SELECT_AT_LEAST_ONE_NUMBER, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
		}
	}

	const handleImportCancel = () => {
		dispatch(getAppDrawer(false, null, null, null, null));
	}
	const getTwilioNumbersListElement = () => {
		let _areaCodeOrZipCode = areaCodeOrZipCode;
		let tempPhoneNumbers = null;
		let elements = [];
		tempPhoneNumbers = getArrayParam(twilioNumbers);
		let alreadyAdded = tempPhoneNumbers.filter((f) => {
			return f.is_saved;
		});
		let notAdded = tempPhoneNumbers.filter((f) => {
			return !f.is_saved;
		});
		let allNos = alreadyAdded.concat(notAdded);
		allNos.forEach((phDtls, index) => {
			if (isValidParam(phDtls)) {
				phDtls.verified = false;
				if (phDtls.verify_status !== 'Unverified') {
					phDtls.verified = true;
				}
				let _selectedDataObj = { ...selectedTwilioImportNo };
				let _selectedData = null;
				let key = phDtls.twilio_sid;
				if (_selectedDataObj) {
					_selectedData = _selectedDataObj[key];
				}
				elements.push(<PhoneNoDetails callFrom={'import'} selectedData={_selectedData} unitList={unitList} updateRowData={(fieldName, value) => updateSelectedData(phDtls.twilio_sid, fieldName, value)} onSelect={(item) => handleImportSelect(item)} key={phDtls.id} leaseRate={leaseRate} index={index} details={phDtls} areaCodeOrZipCode={_areaCodeOrZipCode} updatePhoneDetails={(details, index) => updatePhoneDetails(details, index)} getAvailablePhoneNumbersData={() => getAvailablePhoneNumbersData()} countryISO2={countryISO2} onChangeRedirectNum={(index, event) => onChangeRedirectNum(index, event)} />);
			}
		});
		return elements;
	};
	const getComponentToImport = () => {
		let component = null;
		let selectorEelements = (
			<div>
				{getTableHeader('import')}
				<div
					style={{
						margin: "15px 0px",
						maxHeight: pageHeight - (showNumberHeading ? 130 : 90),
						overflowY: "auto",
					}}
				>
					{getTwilioNumbersListElement()}
				</div>

			</div>
		)


		if (twilioNumbers.length == 0) {
			if (isValidTwilioAccount) {
				component = (
					<div>
						<div className="row">
							{getLocalizedStrings().message.PHONE_AND_TEXT_INTEGRATION.NO_TWILIO_NUMBERS_FOUND}
						</div>
					</div>
				)
			} else {
				component = (
					<div>
						<div className="row">
							{getLocalizedStrings().message.PHONE_AND_TEXT_INTEGRATION.WRONG_AUTHENTICATION_SID_TOKEN}
						</div>
					</div>
				)
			}
		} else {
			component = (
				<div>
					<div className="row" style={{ marginLeft: "0px", marginTop: "10px" }}>
						{selectorEelements}
					</div>
					{/* <div className="row" style={{ marginRight: "10%", marginTop: "10px", justifyContent: 'flex-end' }}>

						<Button
							onClick={() => handleImportSave()}
							style={{ ...styles.primaryButton, marginLeft: 15, height: 42, width: '3px', fontSize: '14px' }}
						>
							{getLocalizedStrings().label.COMMON.IMPORT}
						</Button>
						<Button
							onClick={() => handleImportCancel()}
							style={{ ...styles.secondaryButton, marginLeft: 15, height: 42, width: '3px', fontSize: '14px' }}
						>
							{getLocalizedStrings().label.COMMON.CANCEL}
						</Button>

					</div> */}

					<div style={{ textAlign: 'right', display: 'table', width: '100%' }}>
						<div style={{ display: 'inline-block' }}>
							<Button
								key='import'
								onClick={() => handleImportSave()}

								style={{ ...styles.primaryButton, marginLeft: '6px', marginTop: '1.5%', marginRight: '0px' }}
							>{getLocalizedStrings().label.COMMON.IMPORT}</Button>
						</div>
						<div style={{ display: 'inline-block' }}>
							<Button
								key='cancel'
								onClick={(e) => handleImportCancel()}

								style={{ ...styles.secondaryButton, marginLeft: '6px', marginTop: '1.5%', marginRight: '0px' }}
							>{getLocalizedStrings().label.COMMON.CANCEL}</Button>
						</div>
					</div>
				</div>
			);
		};
		return component;
	};

	const handleCloseToolTip = (event) => {
		setOpenTooltip(false);
	};

	const setFieldValue = (event, fieldName) => {
		let tempFieldName = getStringParam(fieldName);
		let tempValue = event.target.value;

		if (tempFieldName == 'zipCode') {
			if (tempValue === "" || (tempValue.indexOf(".") < 0 && isInteger(tempValue))) {
				setZipCode(tempValue);
			}
		}
		else if (tempFieldName == 'areaCode') {
			if (tempValue === "" || (tempValue.indexOf(".") < 0 && isInteger(tempValue))) {
				setAreaCode(tempValue);
			}
		}
	};

	const findNumbers = () => {
		let isValid = true;
		let message = null;
		let _areaCodeOrZipCode = areaCodeOrZipCode;
		try {
			let tenantCountry = getStringParam(appState.me.tenant_country);
			let _zipCode = getStringParam(zipCode).trim();
			let _areaCode = getStringParam(areaCode).trim();
			let _countryISO = getStringParam(countryISO2).trim();
			if (_countryISO == "IN") {
				_countryISO = "US";
			}
			if (noType == 'local') {
				if (tenantCountry === 'USA' || tenantCountry === 'Canada') {
					if (_zipCode === '' && _areaCode === "") {
						isValid = false;
						message = getLocalizedStrings().message.SMS_INTEGRATION.ENTER_ZIP_OR_AREA;
					} else if (_zipCode != '' && (_zipCode.indexOf('.') > -1 || !isInteger(_zipCode))) {
						isValid = false;
						message = getLocalizedStrings().message.SMS_INTEGRATION.ENTER_VALID_ZIP;
					} else if (_zipCode == '' && _areaCode != '' && (_areaCode.indexOf('.') > -1 || !isInteger(_areaCode))) {
						isValid = false;
						message = getLocalizedStrings().message.SMS_INTEGRATION.ENTER_VALID_AREA_CODE;
					}
				} else {
					if (_areaCode === "") {
						isValid = false;
						message = getLocalizedStrings().message.SMS_INTEGRATION.ENTER_AREA;
					} else if (_areaCode !== '' && (_areaCode.indexOf('.') > -1 || !isInteger(_areaCode))) {
						isValid = false;
						message = getLocalizedStrings().message.SMS_INTEGRATION.ENTER_VALID_AREA_CODE;
					}
				}
			}
			if (isValid) {
				if (_zipCode !== "") {
					_areaCodeOrZipCode = _zipCode;
				} else {
					_areaCodeOrZipCode = _areaCode;
				}
				setIsLoaded(false);
				let params = {};
				params.country = _countryISO;
				params.type = noType;
				if (noType == 'local') {
					params.areaCode = getIntParam(_areaCodeOrZipCode);
				} else {
					params.areaCode = 0;
					params.selectedTollFreeCountry = country.toLowerCase();
				}
				params.smsCapable = true;
				let promise = Promise.resolve(getAvailablePhoneNumbers(params));
				if (isValidParam(promise)) {
					promise.then(response => {
						if (isValidParam(response)) {
							if (response.status == 0) {
								let twilioNumbersArr = getArrayParam(response.twilio_numbers);

								setPhoneNumbers([...twilioNumbersArr]);
								setShowNumberHeading(false);
								setIsLoaded(true);
							} else {
								setIsLoaded(true);
								let message = null;
								if (tenantCountry === 'USA' || tenantCountry === 'Canada') {
									if (noType == 'local') {
										message = getLocalizedStrings().message.SMS_INTEGRATION.NO_AVAILABLE_PHONE_NUMBERS;
									} else if (noType == 'toll-free') {
										message = getLocalizedStrings().message.SMS_INTEGRATION.NO_AVAILABLE_TOLL_FREE_NUMBERS;
									}

								} else {
									message = getLocalizedStrings().message.SMS_INTEGRATION.NO_AVAILABLE_PHONE_NUMBERS_FOR_ZIP;
								}

								sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, message, null);
								getAvailablePhoneNumbersData();

							}

						}
					});
				}
			} else {
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, message, null);
			}
		} catch (error) {
			console.error("Error in 'smsIntegration.js -> findNumbers()':" + error);
		}
	}

	const getAvailablePhoneNumbersData = () => {
		try {
			let params = {};
			params.call_from = 'leaseNumber';
			let promise = Promise.resolve(getAllocatePhoneNumbers(params));
			if (isValidParam(promise)) {
				promise.then(response => {
					if (isValidParam(response)) {
						if (!isLoaded) {
							setIsLoaded(true);
						}
						let phoneNumbers = getArrayParam(response.twilio_numbers);
						setPhoneNumbers([...phoneNumbers]);

					}
				});
			}
		} catch (error) {
			console.error("Error in 'smsIntegration.js -> getAvailablePhoneNumbers()':" + error);
		}
	}


	const updatePhoneDetails = (details, index) => {
		let _phoneNumbers = [...phoneNumbers];
		if (_phoneNumbers.length > index && isValidParam(details)) {
			_phoneNumbers[index] = details;
		}
		setPhoneNumbers([..._phoneNumbers]);
	};

	const getPhoneNosElement = () => {
		let isValid = true;
		let _areaCodeOrZipCode = areaCodeOrZipCode;
		let _zipCode = getStringParam(zipCode).trim();
		let _areaCode = getStringParam(areaCode).trim();

		if (isValid) {
			if (_zipCode !== "") {
				_areaCodeOrZipCode = _zipCode;
			} else {
				_areaCodeOrZipCode = _areaCode;
			}

		}

		let tempPhoneNumbers = null;
		let elements = [];
		tempPhoneNumbers = getArrayParam(phoneNumbers);
		let alreadyAdded = tempPhoneNumbers.filter((f) => {
			return f.is_saved;
		});
		let notAdded = tempPhoneNumbers.filter((f) => {
			return !f.is_saved;
		});
		let allNos = alreadyAdded.concat(notAdded);
		allNos.forEach((phDtls, index) => {
			if (isValidParam(phDtls)) {
				phDtls.verified = false;
				if (phDtls.verify_status !== 'Unverified') {
					phDtls.verified = true;
				}
				elements.push(<PhoneNoDetails key={phDtls.id} leaseRate={leaseRate} unitList={unitList} index={index} details={phDtls} areaCodeOrZipCode={_areaCodeOrZipCode} updatePhoneDetails={(details, index) => updatePhoneDetails(details, index)} getAvailablePhoneNumbersData={() => getAvailablePhoneNumbersData()} countryISO2={countryISO2} onChangeRedirectNum={(index, event) => onChangeRedirectNum(index, event)} />);
			}
		});
		return elements;
	};

	const onChangeRedirectNum = (index, event) => {
		let value = getStringParam(event.target.value);
		let tempPhoneNumbers = phoneNumbers;
		if (index >= 0 && index < tempPhoneNumbers.length) {
			let detailObj = tempPhoneNumbers[index];
			detailObj.redirect_number = value;
			tempPhoneNumbers[index] = detailObj;
			setPhoneNumbers(tempPhoneNumbers);
		}
	};
	const getTableHeader = (callFrom) => {
		const isManageUnitPhone = appState.me.is_manage_unit_phone;
		const isFranchisor = appState.me.is_franchisor;
		return (
			<div
				container
				style={{
					marginBottom: 10,
					display: "grid",
					gridTemplateColumns: callFrom == 'import' ? "3% 18% 15% 12% auto 3% 3%" : isManageUnitPhone && isFranchisor ? "17% 10% 12% 12% auto 3% 3% 3%" : "18% 16% 12% auto 3% 3% 3%",
					gridColumnGap: "3%",
					gridRowGap: "2.5%",
					textAlign: 'center',
					marginRight: '15px'
				}}
			>
				{callFrom == 'import' && <div style={{ width: "100%" }}>
					<span style={{ fontWeight: "bold" }}>
					</span>
				</div>}
				<div style={{ width: '100%' }}>
					<span style={{ fontWeight: "bold" }}>
						{getLocalizedStrings().label.TWILIO_SMS.PHONE_NUMBER}
					</span>
				</div>
				{callFrom !== 'import' && isManageUnitPhone && isFranchisor && (
					<div style={{ width: '100%' }}>
						<span style={{ fontWeight: "bold" }}>
							{getLocalizedStrings().label.TWILIO_SMS.USED_BY}
						</span>
					</div>
				)}
				<div style={{ width: '100%' }}>
					<span style={{ fontWeight: "bold" }}>
						{getLocalizedStrings().label.TWILIO_SMS.AVAILABLE_TO}
					</span>
				</div>
				<div style={{ width: '100%' }}>
					<span style={{ fontWeight: "bold" }}>{"Call/Text"}</span>
				</div>
				<div style={{ width: '100%' }}>
					<span style={{ fontWeight: "bold" }}>{"Redirect To"}</span>
				</div>
				{callFrom != 'import' && <div style={{ width: "100%" }}>
					<span></span>
				</div>}
			</div>
		);
	}
	let pageHeight = window.innerHeight - 247;
	let top = (pageHeight - 160) / 2;
	let phoneElements = getPhoneNosElement();
	return (
		<div style={{ marginLeft: '25px' }}>
			{(!appState.me.is_manage_unit_phone_by_fr) && (drawerData.action_type == 'lease' || (drawerData.action_type == 'import' && drawerData.action_mode == 'add')) && <div style={{ borderBottom: "2.0px solid #afafaf5c" }}>
				<span style={{ fontWeight: "bold", fontSize: "20px" }}>
					{drawerData.action_type == 'import' ? `Import your Twilio Numbers` : `${getLocalizedStrings().label.COMMON.LEASE_A_NO} ($${leaseRate}/month)`}
				</span>
			</div>}
			{!appState.me.is_manage_unit_phone_by_fr && drawerData.action_type == 'lease' && <div style={{ marginTop: "10px" }}>{getComponentToAdd()}</div>}
			{isLoaded && drawerData.action_type == 'import' && drawerData.action_mode != 'edit' && <div style={{ marginTop: "10px" }}>{getComponentToImport()}</div>}
			{!isLoaded && (
				<div style={{ width: "100%" }}>
					<div
						className="asset-loaderh"
						style={{ paddingTop: top, paddingLeft: "45%" }}
					>
						<div
							style={{
								...styles.assetLoaderContainer,
								height: 50,
								width: 50,
								padding: 7,
							}}
						>
							<ShowCircularProgress
								size={30}
								style={{ marginTop: "3", marginLeft: "3" }}
							/>
						</div>
					</div>
				</div>
			)}
			{(drawerData.action_type == 'lease' || (drawerData.action_type == 'import' && drawerData.action_mode == 'edit')) && isLoaded && phoneElements.length > 0 && (
				<div
					style={{
						marginTop: "15px",
						fontSize: "15px",
					}}
				>
					{showNumberHeading && <div style={{ borderBottom: "2.0px solid #afafaf5c", paddingBottom: '5px', marginBottom: "10px", }}>
						<span style={{ fontWeight: "bold", fontSize: "20px" }}>
							{`Existing Number`}
						</span>
					</div>}
					<div>
						{getTableHeader('table')}
					</div>


					<div
						style={{
							margin: "15px 0px",
							height: pageHeight - (showNumberHeading ? 130 : 90),
							overflowY: "auto",
						}}
					>
						{phoneElements}
					</div>
				</div>
			)}
		</div>
	);
};

export default SMSIntegration;

const PhoneNoDetails = ({ callFrom = 'lease', key, selectedData, updateRowData, unitList, onSelect, leaseRate, index, details, areaCodeOrZipCode, updatePhoneDetails, getAvailablePhoneNumbersData, countryISO2, onChangeRedirectNum }) => {
	const dispatch = useDispatch();
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [selectedUnit, setSelectedUnit] = useState(null);
	const [selectedUnitName, setSelectedUnitName] = useState(null);
	const [selectedUserType, setSelectedUserType] = useState('Corporate');
	const [selectedUserIds, setSelectedUserIds] = useState([]);
	const [selectedUserNames, setSelectedUserNames] = useState([]);
	const [isRemoving, setIsRemoving] = useState(false);
	const [redirectNumber, setRedirectNumber] = useState(null);
	const [available_for, setAvailable_for] = useState(null);
	const [allUserId, setAllUserId] = useState(-9999);
	const [type, setType] = useState("local");
	const tempuserList = useSelector((state) => state.userlist);
	const appState = useSelector((state) => state.app);
	const appContainer = useSelector((state) => state.appContainer);
	const drawerData = appContainer.drawerProps.data ?? {}


	useEffect(() => {
		let availableTo = getStringParam(details.available_to);
		let arrAvailableTo = availableTo.split(",");
		if (availableTo !== "" && arrAvailableTo.length > 0) {
			arrAvailableTo = arrAvailableTo.map((val) => {
				return parseInt(val);
			});
			if (arrAvailableTo.indexOf(allUserId) > -1) {
				setSelectedUsersData(allUserId, [allUserId]);
			} else {
				setSelectedUsersData("", arrAvailableTo);
			}
		} else {
			setSelectedUsersData(allUserId, [allUserId]);
		}
		let available_for = isValidParam(details.available_for) && getStringParam(details.available_for) !== "" ? getStringParam(details.available_for) : "Both";
		setAvailable_for(available_for);

		let _type = isValidParam(details.type) && getStringParam(details.type) !== "" ? getStringParam(details.type) : "local";
		setType(_type);

		let _usedBy = isValidParam(details.used_by) && getStringParam(details.used_by) !== "" ? getStringParam(details.used_by) : "Corporate";
		setSelectedUserType(_usedBy);

		let availableToUnitId = getIntParam(details.available_to_unit_id);
		if (availableToUnitId > 0) {
			setUnit(availableToUnitId);
		}

	}, []);

	const gUserList = () => {
		let userList = JSON.parse(JSON.stringify(getArrayParam(tempuserList.data)));
		userList.unshift({ value: "All Users", id: allUserId });
		return userList;
	};

	const getUnitList = () => {
		let _userList = unitList.map((obj) => {
			obj.name = obj.fe_name;
			obj.value = obj.myclient_id;
			return obj;
		});;
		return _userList;
	};


	const getUserTypeMenuItem = () => {
		let userMenuItem = [];
		let userTypeList = ['Corporate', 'Units'];
		userMenuItem = userTypeList.map((listValue, listValueIndex) => {
			return (
				<MenuItem
					value={listValue}
					key={"call-setup-item-" + listValue + listValueIndex}
					id={listValue}
					title={listValue}
					insetChildren={true}
					selected={
						selectedUserType == listValue
							? true
							: false
					}
					style={{ ...styles.popoverMenuItem }}
				>
					{listValue}
				</MenuItem>
			);
		});
		return userMenuItem;
	};
	const getUserTypeDropDown = () => {
		let dropDown = null;
		let tempdetails;
		tempdetails = details;
		if (isValidParam(tempdetails)) {
			dropDown = (
				<FormControl variant="outlined" style={{ width: "100%" }}>
					<InputLabel id="sf-smsintegration-simple-select-outlined-label">
						{getLocalizedStrings().label.TWILIO_SMS.USED_BY}
					</InputLabel>
					<Select
						key={tempdetails.used_by}
						label={getLocalizedStrings().label.TWILIO_SMS.USED_BY}
						value={selectedUserType}
						title={selectedUserType}
						onChange={(event) => setUserType(event)}
						style={{ height: "42px", width: '100%' }}
						disabled={details.twilio_sid}
					>
						{getUserTypeMenuItem()}
					</Select>
				</FormControl>
			);
		}
		return dropDown;
	};
	const getUserMenuItem = () => {
		let userMenuItem = [];
		let userList = gUserList();
		userMenuItem = userList.map((listValue, listValueIndex) => {
			return (
				<MenuItem
					value={listValue.id}
					key={"call-setup-item-" + listValue.id + listValueIndex}
					id={listValue.id}
					title={listValue.value}
					insetChildren={true}
					selected={
						selectedUsers && selectedUsers.indexOf(listValue.id) > -1
							? true
							: false
					}
					style={{ ...styles.popoverMenuItem }}
					onClick={() => setUser(listValue.id)}
				>
					{listValue.value}
				</MenuItem>
			);
		});
		return userMenuItem;
	};
	const getUserDropDown = () => {
		let dropDown = null;
		let tempdetails;
		tempdetails = details;
		if (isValidParam(tempdetails)) {
			dropDown = (
				<FormControl variant="outlined" style={{ width: "100%" }}>
					<InputLabel id="sf-smsintegration-simple-select-outlined-label">
						{getLocalizedStrings().label.TWILIO_SMS.USER_NAME}
					</InputLabel>
					<Select
						key={tempdetails.phone_number}
						multiple={true}
						label={getLocalizedStrings().label.TWILIO_SMS.USER_NAME}
						value={selectedUsers}
						title={selectedUserNames}
						style={{ height: "42px", width: '100%' }}
					>
						{getUserMenuItem()}
					</Select>
				</FormControl>
			);
		}
		return dropDown;
	};
	const getUnitMenuItem = () => {
		let userMenuItem = [];
		let unitList = getUnitList();
		userMenuItem = unitList.map((listValue, listValueIndex) => {
			return (
				<MenuItem
					value={listValue.value}
					name={listValue.name}
					key={"call-setup-item-" + listValue.value + listValueIndex}
					id={listValue.value}
					title={listValue.name}
					insetChildren={true}
					selected={selectedUnit == listValue.value}
					style={{ ...styles.popoverMenuItem }}
				>
					{listValue.name}
				</MenuItem>
			);
		});
		return userMenuItem;
	};
	const getUnitDropDown = () => {
		let dropDown = null;
		let tempdetails;
		tempdetails = details;
		if (isValidParam(tempdetails)) {
			dropDown = (
				<FormControl variant="outlined" style={{ width: "100%" }}>
					<InputLabel id="sf-smsintegration-simple-select-outlined-label">
						{getLocalizedStrings().label.TWILIO_SMS.UNIT_NAME}
					</InputLabel>
					<Select
						key={tempdetails.available_to_unit_id}
						label={getLocalizedStrings().label.TWILIO_SMS.UNIT_NAME}
						value={selectedUnit}
						title={selectedUnitName}
						onChange={(event) => setUnit(event.target.value)}
						style={{ height: "42px", width: '100%' }}
						disabled={selectedUserType == 'Units' && (isManageUnitPhone && isFranchisor && details.twilio_sid)}
					>
						{getUnitMenuItem()}
					</Select>
				</FormControl>
			);
		}
		return dropDown;
	};

	const setUserType = (event) => {
		let _type = event.target.value;
		if (_type == 'Units') {
			unitList = getUnitList();
			setUnit(unitList[0].value);
		}
		setSelectedUserType(_type);
	};

	const setUser = (value) => {
		let currentId = parseInt(value);
		let _users = [];
		if (selectedUsers.indexOf(value) > -1) {
			_users = selectedUsers.filter((f) => {
				return f !== value;
			});
		} else if (selectedUsers.indexOf(value) < 0) {
			_users = [...selectedUsers, value];
		}
		setSelectedUsersData(currentId, _users);
	};

	const setUnit = (value) => {
		setSelectedUnit(value);
		setSelectedUnitName(unitList.find(v => v.value == value)?.name)
	};


	const setSelectedUsersData = (currentId, _selectedUsers) => {
		let tempallUserId = allUserId;
		let selectedUserDetails = [];
		let userList = gUserList();
		if (currentId === tempallUserId) {
			if (_selectedUsers.indexOf(tempallUserId) > -1) {
				selectedUserDetails = userList;
				_selectedUsers = userList.map((user, index) => {
					return user.id;
				});
			} else if (_selectedUsers.indexOf(tempallUserId) < 0) {
				_selectedUsers = [];
				selectedUserDetails = [];
			}
		} else if (currentId !== tempallUserId) {
			if (
				_selectedUsers.indexOf(tempallUserId) < 0 &&
				_selectedUsers.length + 1 === userList.length
			) {
				selectedUserDetails = userList;
				_selectedUsers = userList.map((user, index) => {
					return user.id;
				});
			} else if (
				_selectedUsers.indexOf(tempallUserId) > -1 &&
				_selectedUsers.length === userList.length - 1
			) {
				_selectedUsers = _selectedUsers.filter((f) => {
					return f !== tempallUserId;
				});
				selectedUserDetails = userList.filter((f) => {
					return _selectedUsers.indexOf(f.id) > -1;
				});
			} else {
				selectedUserDetails = userList.filter((f) => {
					return _selectedUsers.indexOf(f.id) > -1;
				});
			}
		}

		selectedUserDetails = getArrayParam(selectedUserDetails);
		let selectedUserNames = selectedUserDetails.map((user, index) => {
			return user.value;
		});


		let selectedUserIds = selectedUserDetails.map((user, index) => {
			return user.id;
		});

		let redirectNumber = getStringParam(details.redirect_number);
		let tenantCountry = getStringParam(appState.me.tenant_country);
		if (redirectNumber === null || redirectNumber === "") {
			redirectNumber = COUNTRY_CODE_MAP[tenantCountry];
		}
		if (updateRowData) {
			let selecteUserLists = getSelectedUserLists(selectedUserIds);
			updateRowData("available_to", selecteUserLists);
		}
		setSelectedUserNames(selectedUserNames);
		setSelectedUserIds(selectedUserIds);
		setRedirectNumber(redirectNumber);
		setAllUserId(tempallUserId);
		setSelectedUsers(_selectedUsers);
	};

	const getSelectedUserLists = (ids) => {
		let _rawIds = getArrayParam(ids);
		let _selectedIds = [];
		if (_rawIds.length > 0) {
			if (_rawIds.indexOf(allUserId) > -1) {
				_selectedIds = [allUserId];
			} else {
				_selectedIds = _rawIds;
			}
		}
		return _selectedIds.join();
	}

	const purchaseNumber = () => {
		let phoneNumberPrice = `$${leaseRate}`;
		let selecteUserLists = getArrayParam(selectedUserIds);
		let tempselectedUserIds = [];
		if (selecteUserLists.length > 0) {
			if (selecteUserLists.indexOf(allUserId) > -1) {
				tempselectedUserIds = [allUserId];
			} else {
				tempselectedUserIds = selecteUserLists;
			}
			let message =
				getLocalizedStrings().message.SMS_INTEGRATION
					.TWILIO_NUMBER_PURCHASE_MSG_1 +
				" " +
				phoneNumberPrice +
				"/" +
				getLocalizedStrings().message.SMS_INTEGRATION
					.TWILIO_NUMBER_PURCHASE_MSG_2 +
				" " +
				getLocalizedStrings().message.SMS_INTEGRATION
					.TWILIO_NUMBER_PURCHASE_MSG_3;
			let number = getStringParam(details.phone_number);

			if (number !== "") {
				sfDialogs.confirm(
					getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
					message,
					(event) => handleSfDialogPurchaseNumber(event, number, tempselectedUserIds),
					null
				);
			}
		} else {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				getLocalizedStrings().message.SMS_INTEGRATION.SELECT_USER,
				null
			);
		}
	};

	const getFormatedRedirectNumber = (inputNumber) => {
		let _formatedNumber = "";
		let _redirectNumber = null;
		_redirectNumber = getStringParam(inputNumber);
		if (_redirectNumber !== null && _redirectNumber !== "") {
			let _numArr = _redirectNumber.split(
				COUNTRY_CODE_MAP[appState.me.tenant_country]
			);
			if (_numArr !== null && _numArr.length > 1 && _numArr[1].trim() !== "") {
				_formatedNumber = getStringParam(inputNumber);
			}
		}
		return _formatedNumber
	}

	const handleSfDialogPurchaseNumber = (event, number, selectedUserIds) => {
		let params = {};
		params.phone_number = number;
		params.country = getStringParam(countryISO2).toLowerCase();
		params.used_by = selectedUserType
		if (selectedUserType == 'Units') {
			params.available_to_unit_id = selectedUnit;
		} else {
			params.available_to = selectedUserIds.join();
		}
		params.available_for = available_for;
		let redirectNumber = getFormatedRedirectNumber(details.redirect_number);
		redirectNumber = getStringParam(details.redirect_number);
		if (redirectNumber !== null && redirectNumber.length > 1) {
			params.redirect_number = getStringParam(details.redirect_number);
		}
		params.type = type;
		if (type == 'local') {
			params.areaCode = getIntParam(areaCodeOrZipCode);
		} else {
			params.areaCode = 0;
		}
		let purchasePromise = Promise.resolve(purchasePhoneNumber(params));
		if (isValidParam(purchasePromise)) {
			purchasePromise.then((response) => {
				if (isValidParam(response)) {
					let purchasedNumber = getObjectParam(
						response.data.purchased_number
					);
					if (
						purchasedNumber !== null &&
						purchasedNumber !== undefined &&
						purchasedNumber !== ""
					) {
						updatePhoneDetails(purchasedNumber, index);
						sfDialogs.alert(
							getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
							getLocalizedStrings().message.SMS_INTEGRATION.PURCHASED_NUMBER,
							() => { refreshListView(constants.PHONE_NUMBERS_OBJECT) }
						);
					} else {
						sfDialogs.alert(
							getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
							getLocalizedStrings().message.SMS_INTEGRATION.PHONE_NUMBER_CANNOT_BE_PURCHASED,
							null
						);
					}
				}
			});
		}
	};


	const removeNumber = () => {
		let twilio_sid = getStringParam(details.twilio_sid);
		let params = {};
		params.twilio_sid = twilio_sid;
		/*if (appState.me.is_franchisor && details.used_by == 'Units') {
			params.available_to_unit_id = details.available_to_unit_id;
		}*/
		if (details.used_by == 'Corporate') {
			let checkPhoneStatusPromise = Promise.resolve(
				checkPhoneNumberStatus(params)
			);
			if (isValidParam(checkPhoneStatusPromise)) {
				checkPhoneStatusPromise.then((response) => {
					if (isValidParam(response)) {
						let isWorkFlow = response.data.isWorkFlow;
						let isScheduled = response.data.isScheduled;
						if (isWorkFlow) {
							let message =
								getLocalizedStrings().message.SMS_INTEGRATION
									.WORKFLOW_STATUS_MSG;
							sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, message, null);

						} else if (isScheduled) {
							let message =
								getLocalizedStrings().message.SMS_INTEGRATION
									.SCHEDULED_STATUS_MSG;
							sfDialogs.confirm(
								getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
								message,
								() =>
									handleSfDialogRemoveNumber(
										isScheduled,
										isWorkFlow,
										params
									),
								null
							);
						} else if (!isScheduled && !isWorkFlow) {
							let message =
								getLocalizedStrings().message.SMS_INTEGRATION
									.REMOVE_NUMBER_CONFIRMATION;
							sfDialogs.confirm(
								getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
								message,
								() =>
									handleSfDialogRemoveNumber(
										isScheduled,
										isWorkFlow,
										params
									),
								null
							);
						}
					}
				});
			}
		} else if (details.used_by == 'Units') {
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SMS_INTEGRATION.CANNOT_DELETE_UNITS_NUMBER, null);
		}
	};

	const handleSfDialogRemoveNumber = (
		isScheduled,
		isWorkFlow,
		params
	) => {
		if (isScheduled) {
			setIsRemoving(true);
			params.isCancelScheduledSms = true;
			let removePromise = Promise.resolve(removePhoneNumber(params));
			if (isValidParam(removePromise)) {
				removePromise.then((response) => {
					if (isValidParam(response) && getBooleanParam(response.data)) {
						setIsRemoving(false);
						getAvailablePhoneNumbersData();
						dispatch(showCustomSnackBar(getLocalizedStrings().message.SMS_INTEGRATION.REMOVE_SUCCESS_MSG,
							styles.snackbarBodyStyleSuccess,
							styles.snackBarStyleTop
						)
						);
						refreshListView(constants.PHONE_NUMBERS_OBJECT);
					}
				});
			}
		} else if (!isScheduled && !isWorkFlow) {
			setIsRemoving(true);
			let removePromise = Promise.resolve(removePhoneNumber(params));
			if (isValidParam(removePromise)) {
				removePromise.then((response) => {
					if (isValidParam(response) && getBooleanParam(response.data)) {
						setIsRemoving(false);
						getAvailablePhoneNumbersData();
						dispatch(
							showCustomSnackBar(
								getLocalizedStrings().message.SMS_INTEGRATION
									.REMOVE_SUCCESS_MSG,
								styles.snackbarBodyStyleSuccess,
								styles.snackBarStyleTop
							)
						);
						refreshListView(constants.PHONE_NUMBERS_OBJECT);
					}
				});
			}
		}
	};

	const restrictInvalid = (event) => {
		const re = /^[\d ()+]+$/;
		let value = getStringParam(event.key);
		if (value !== " " && re.test(value)) {
			return true;
		} else {
			return false;
		}
	};

	const updateRedirectNumberData = (event) => {
		let redirectNumber = details.redirect_number;
		let twilio_sid = getStringParam(details.twilio_sid);
		let id = details.id;
		let selecteUserLists = getArrayParam(selectedUserIds);
		let tempselectedUserIds = [];
		if (selecteUserLists.length > 0) {
			if (selecteUserLists.indexOf(allUserId) > -1) {
				tempselectedUserIds = [allUserId];
			} else {
				tempselectedUserIds = selecteUserLists;
			}
		}
		if (id > 0 && tempselectedUserIds.length > 0) {
			let params = {};
			if (redirectNumber === null) {
				redirectNumber = "";
			}
			params.redirect_number = redirectNumber;
			params.twilio_sid = twilio_sid;
			params.available_for = available_for;
			params.used_by = selectedUserType
			//params.available_to_unit_id = selectedUnit;
			params.available_to = tempselectedUserIds.join();
			let promise = Promise.resolve(updateRedirectNumber(params));
			promise.then((response) => {
				if (isValidParam(response) && response.status === 0) {
					dispatch(
						showCustomSnackBar(
							getLocalizedStrings().message.SMS_INTEGRATION
								.NUMBER_SAVED_SUCCESS_MSG,
							styles.snackbarBodyStyleSuccess,
							styles.snackBarStyleTop
						)
					);
				}
			});
		} else {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				getLocalizedStrings().message.SMS_INTEGRATION.SELECT_USER,
				null
			);
		}
	};

	const getRedirectNumber = (index) => {
		return (
			<div>
				<FormControl
					style={{ width: "100%" }}
					className="test"
					noValidate
					autoComplete="off"
				>
					<TextField
						variant="outlined"
						size="small"
						key={"key-redirectnum-" + index}
						name={"redirectnum-" + index}
						inputStyle={styles.textField.input}
						disabled={appState.me.is_franchisor && selectedUserType == 'Units'}
						fullWidth={true}
						style={{ ...styles.sfFormFieldContainer }}
						value={redirectNumber}
						onChange={(event) => handleChangeNumber(index, event)}
						onKeyPress={(event) => restrictInvalid(event)}
						autoComplete="new-password"
						className={"sf-fields-bg"}
						inputprops={{
							style: { fontSize: "15px", height: "5px" },
						}}
					/>
				</FormControl>
			</div>
		);
	};
	const handleChangeNumber = (index, event) => {
		onChangeRedirectNum(index, event);
		setRedirectNumber(getStringParam(event.target.value));
		if (updateRowData) {
			updateRowData("redirect_number", getFormatedRedirectNumber(event.target.value));
		}
	};



	const getcallTextDropDown = () => {
        return (
            <div>
                <FormControl style={{ width: "100%" }}>
                    <Select
                        labelId="phone-number-type-selectl"
                        id="phone-number-type-select"
                        value={available_for}
                        onChange={(e) => handleAvailableForChange(e)}
                        variant="outlined"
                        style={{ height: "42px", width: "100%" }}
                        disabled={selectedUserType == 'Units' && (isManageUnitPhone && isFranchisor && details.twilio_sid)}
                    >
                        <MenuItem value={"Both"}>{"Both"}</MenuItem>
                        <MenuItem value={"Call"}>{"Call"}</MenuItem>
                        <MenuItem value={"SMS"}>{"SMS"}</MenuItem>
                        <MenuItem value={"Campaign"}>{"Campaign"}</MenuItem>
                    </Select>
                </FormControl>
            </div>
        );
    };

	const handleAvailableForChange = (event) => {
		setAvailable_for(event.target.value);
		if (updateRowData)
			updateRowData("available_for", event.target.value);
	};



	let label = null;
	let isSaved = getBooleanParam(details.is_saved);
	let tempisRemoving = getBooleanParam(isRemoving);
	let saveIcon = null;
	let margin = "0";
	if (tempisRemoving) {
		label = (
			<div style={{ color: "#337ab7", marginLeft: '-50px' }}>
				Removing
				<div style={{ display: "flex", justifyContent: "center" }}>
					<img
						src="/asset/images/processing.gif"
						alt="Removing"
						style={{ height: "10px", width: "50px" }}
					/>
				</div>
			</div>
		);

	} else if (isSaved) {
		label = getLocalizedStrings().label.COMMON.REMOVE;
		label = (
			<i
				className="fas fa-trash"
				title={label}
				onClick={() => removeNumber()}
				style={{
					cursor: "pointer",
					fontSize: "18px",
					marginTop: "13px",
					opacity: "0.6",
				}}
			></i>
		);
		saveIcon = (
			<i
				class="fas fa-save"
				title={getLocalizedStrings().label.COMMON.SAVE}
				onClick={(event) => updateRedirectNumberData(event)}
				style={{
					cursor: "pointer",
					fontSize: "18px",
					marginTop: "13px",
					opacity: "0.6",
				}}
			></i>
		);
		margin = "-20px";

	} else {
		label = (
			<Button
				style={{ ...styles.primaryButton, height: "42px" }}
				label={"Lease"}
				onClick={() => purchaseNumber()}
			>
				{"Lease"}{" "}
			</Button>
		);
	}

	const isManageUnitPhone = appState.me.is_manage_unit_phone;
	const isFranchisor = appState.me.is_franchisor;

	return (
		<div key={key}>
			<div
				container
				style={{
					marginBottom: 10,
					marginTop: 8,
					display: "grid",
					gridTemplateColumns: callFrom == 'import' ? "3% 18% 15% 12% auto 3% 3%" : isManageUnitPhone && isFranchisor ? "17% 10% 12% 12% auto 3% 3% 3%" : "18% 16% 12% auto 3% 3% 3%",
					gridColumnGap: "2.5%",
					textAlign: "center",
				}}
			>
				{callFrom === 'import' && <div style={{ width: "100%", display: "block", paddingTop: "0px" }} onClick={() => onSelect({ ...details, available_for: available_for, available_to: getSelectedUserLists(selectedUserIds), redirect_number: getFormatedRedirectNumber(redirectNumber) })}>
					<Checkbox
						edge="start"
						id={'checkedUser-' + details.phone_number}
						checked={selectedData}
						tabIndex={-1}
						color="default"
						inputProps={{ 'aria-labelledby': 'checkedUser-' + details.phone_number }}
					/>
				</div>}
				<div style={{ width: "100%", display: "block", paddingTop: "8px" }}>
					<span>{details.phone_number}</span>
				</div>
				{callFrom !== 'import' && isManageUnitPhone && isFranchisor && (
					<div style={{ width: "100%", display: "block" }}>
						<span>{getUserTypeDropDown()}</span>
					</div>
				)}
				<div style={{ width: "100%", display: "block" }}>
					{appState.me.is_franchisor && selectedUserType == 'Units' ? getUnitDropDown() : getUserDropDown()}
				</div>
				<div style={{ width: "100%", display: "block" }}>
					{getcallTextDropDown()}
				</div>
				<div style={{ width: "100%", display: "block" }}>
					{getRedirectNumber(index)}
				</div>
				{callFrom !== 'import' && isSaved && (
					<>
						<div
							style={{
								width: "100%",
								verticalAlign: "middle",
								display: "flex",
								verticalAlign: "middle",
								flexWrap: 'wrap',
								alignContent: "space-around",
								marginLeft: "-4px",
							}}
						>
						</div>
						<div
							style={{
								width: "100%",
								verticalAlign: "middle",
								display: "block",
								marginLeft: "-12px",
							}}
						>
							<span style={{}}>{saveIcon}</span>
						</div>
					</>
				)}
				{callFrom !== 'import' && !appState.me.is_manage_unit_phone_by_fr && <div
					style={{
						width: "100%",
						verticalAlign: "middle",
						display: "block",
						marginLeft: margin,
					}}
				>
					<span style={{}}>{label}</span>
				</div>}
			</div>
		</div>
	);
};
