import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';


function isXeroConnected() {
    let url = endPoints.XERO_INTEGRATION.IS_CONNECTED;
    try {
        return HTTPClient.get(url, null);
    } catch (e) { console.error("Error in 'xeroAction.js -> isXeroConnected()':" + e); }
}

function getXeroInfo() {
    let url = endPoints.XERO_INTEGRATION.GET;
    try {
        return HTTPClient.get(url, null);
    } catch (e) { console.error("Error in 'xeroAction.js -> getXeroInfo()':" + e); }
}

function updateXeroDetails(params) {
    let url = endPoints.XERO_INTEGRATION.UPDATE_DETAILS;
    try {
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'xeroAction.js -> updateXeroDetails()':" + e); }
}

function disconnect() {
    let url = endPoints.XERO_INTEGRATION.DISCONNECT;
    try {
        return HTTPClient.post(url, null);
    } catch (e) { console.error("Error in 'xeroAction.js -> disconnect()':" + e); }
}

export {
    isXeroConnected,
    getXeroInfo,
    updateXeroDetails,
    disconnect
}
