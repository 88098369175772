import React from "react";
import { Menu, MenuItem, Typography } from "@mui/material";
import { constants } from "../../../services/constants/constants";
import { Divider } from "@mui/material";
import { styles } from "../../../services/constants/styles";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { getAppDrawer } from "../../../services/actions/appContainerActions";
import * as sfDialogs from "../components/sfDialogs";
import { getCookie, setCookie, deleteCookie } from '../../../services/helper/sfCookies';
import { getStringParam } from "../../../services/helper/parameterVerifier";
import {
	hasAccessPermission,
	setSelectedModuleInfo,
	evictUserData,
} from "../../../services/helper/common";
import {
	addTab,
	getActiveTab,
	TYPE_HOME,
} from "../../../services/helper/sfTabManager";
import store from "../../../../src/services/store";
import { openLeftSideMenu } from "../../../services/actions/appActions";
import { removeToken } from "../../../services/helper/httpClient";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


const Profile = () => {
	let defaultIconStyle = {};
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [profileImage, setProfileImage] = useState(null);

	const appState = useSelector((state) => state.app);

	useEffect(() => {
		let tempImageUrl = appState.me.profile_image;
		if (tempImageUrl === null) {
			tempImageUrl = "https://storage.googleapis.com/material-icons/external-assets/v4/icons/svg/ic_account_circle_white_48px.svg"

		}
		setProfileImage(tempImageUrl)
	}, [appState.me]);

	const openSettings = () => {
		handleRequestClose();
		let object = constants.SETTING;
		setSelectedModuleInfo(object);
		let label = getLocalizedStrings().label.COMMON.MODULE_SETTING;
		let tab = {
			imgName: "fas fa-cog",
			item: object,
			label: label,
			object: object,
			type: TYPE_HOME,
			url: "/setting/module",
		};
		addTab(tab, true);
		dispatch(openLeftSideMenu(false));
	};
	const userLogout = (e) => {
		var metaData = {};
		metaData.email = appState.me.email;
		var eventData = {};
		eventData.name = "logout";
		eventData.meta_data = metaData;
		evictUserData();
		const franchiseAppLoginDetails = JSON.parse(localStorage.getItem('franchiseAppLoginDetails'))
		if (franchiseAppLoginDetails?.length > 0) {
			// eslint-disable-next-line array-callback-return
			franchiseAppLoginDetails.map((item) => {
				if (item?.shouldReturnToInitialPos_) {
					localStorage.removeItem(item?.shouldReturnToInitialPos_)
				}
			})
		}
		localStorage.removeItem('franchiseAppLoginDetails')
		removeToken("logout");
		let userId = (appState.me.id * 19000) + 7;
		let projectId = (appState.me.projectId * 19000) + 7;
		let taskcookieName = "task-view-mode" + projectId + '-' + userId;
		let isAllTakViewIncookie = JSON.parse(getCookie(taskcookieName));
		if (isAllTakViewIncookie !== null) {
			deleteCookie(taskcookieName);
		}
		let cookieCalendarMinName = "calendarMin-left" + projectId + '-' + userId;
         let calenderCookie = JSON.parse(getCookie(cookieCalendarMinName))
		 if(calenderCookie !== null){
			deleteCookie(cookieCalendarMinName);
		 }
	};
	const handleTouchTap = (event) => {
		setOpen(true);
		setAnchorEl(event.currentTarget);
	};
	function deleteCookie(keyName, path, domain) {
		if (getCookie(keyName)) document.cookie = keyName + "=" +
			((path) ? ";path=" + path : "") +
			((domain) ? ";domain=" + domain : "") +
			";expires=Thu, 01-Jan-1970 00:00:01 GMT";
	}
	const handleRequestClose = () => {
		setOpen(false);
	};
	const openMyIntegrations = () => {
		let hasPermission = true;
		if (hasPermission) {
			dispatch(
				getAppDrawer(
					true,
					getLocalizedStrings().label.MY_INTEGRATION.MY_INTEGRATIONS,
					constants.MY_INTEGRATIONS,
					null,
					null
				)
			);
		} else {
			sfDialogs.alert(
				getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
				getLocalizedStrings().message.COMMON.ACCESS_DENIED,
				null
			);
		}
		setOpen(false);
	};
	const goToFranchisorLogin = () => {
		let gloabalFranchisorToken = getStringParam(localStorage.getItem("global_franchisor_token"));
		const franchiseAppLoginDetails = JSON.parse(localStorage.getItem('franchiseAppLoginDetails'))
		if (franchiseAppLoginDetails && franchiseAppLoginDetails.length >= 2) {
			const index = franchiseAppLoginDetails.length - 2;
			const token = franchiseAppLoginDetails[index].token
			localStorage.setItem("token", token);
		} else {
			localStorage.setItem("token", gloabalFranchisorToken);
			localStorage.removeItem("is_franchisor_token");
		}
		if (constants.APP_URL === "") {
			window.location.replace("/");
		} else {
			window.location.replace(constants.APP_URL);
		}
	};
	let franchisorToken = "";
	let menu = {
		marginLeft: 10,
	};
	let is_sign_out = appState.me.is_sign_out;
	return (
		<div className="UserProfile">
			<div className="User">
				<div
					className="image"
					style={{ marginLeft: "5px" }}
					onClick={(e) => handleTouchTap(e)}
				>
					<img src={profileImage} style={{ defaultIconStyle, width: '29px', height: '29px' }} />
				</div>

				<Menu
					id={getLocalizedStrings().label.HOME.ADD_NEW}
					open={open}
					anchorEl={anchorEl}
					onClose={() => handleRequestClose()} PaperProps={{
						elevation: 0,
						sx: {
							overflow: 'visible',
							filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
							mt: 1,
							'&:before': {
								content: '""',
								display: 'block',
								position: 'absolute',
								top: 0,
								right: 13,
								width: 10,
								height: 10,
								bgcolor: 'background.paper',
								transform: 'translateY(-50%) rotate(45deg)',
								zIndex: 0,
							},
						},
					}}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				>
					<MenuItem
						className="profile"
						title={appState.me.name}
						style={{
							...styles.popoverMenuItem,
							fontSize: "12px",
							width: franchisorToken !== "" ? 143 + 26 : 143,
							overflow: "hidden",
							textOverflow: "ellipsis",
							wordWrap: "no-wrap",
							paddingLeft: "17px",
						}}
					>
						<div
							style={{
								width: 140,
								display: "inline-block",
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
								color: "#666666",
							}}
						>
							{appState.me.name}
						</div>
					</MenuItem>
					<Divider style={{ marginTop: "-1px" }} />
					{franchisorToken !== "" && (
						<MenuItem
							title={getLocalizedStrings().label.COMMON.BACK_TO_YOUR_ACCOUNT}
							onClick={() => goToFranchisorLogin()}
							className="sfprofilenametest"
							style={{ ...styles.popoverMenuItem, paddingLeft: "6px" }}
						>
							<Typography>
								{" "}
								<span style={menu}>
									{getLocalizedStrings().label.COMMON.BACK_TO_YOUR_ACCOUNT}
								</span>
							</Typography>
						</MenuItem>
					)}
					<MenuItem
						title={
							getLocalizedStrings().label.PROFILE_DETAILS.PROFILE_DETAILS_LABEL
						}
						onClick={() => openSettings()}
						className="sfprofilenametest"
						style={{ ...styles.popoverMenuItem, paddingLeft: "6px", marginTop: "-9px" }}
					>
						<Typography>
							{" "}
							<span style={menu}>
								{
									getLocalizedStrings().label.PROFILE_DETAILS
										.PROFILE_DETAILS_LABEL
								}
							</span>{" "}
						</Typography>
					</MenuItem>

					<MenuItem
						title={getLocalizedStrings().label.COMMON.MY_INTEGRATIONS}
						onClick={() => openMyIntegrations()}
						className="sfprofilenametest"
						style={{ ...styles.popoverMenuItem, paddingLeft: "6px" }}
					>
						<Typography>
							{" "}
							<span style={menu}>
								{getLocalizedStrings().label.COMMON.MY_INTEGRATIONS}
							</span>{" "}
						</Typography>
					</MenuItem>
					{is_sign_out && (
						<MenuItem
							title={getLocalizedStrings().label.COMMON.SIGN_OUT}
							onClick={() => userLogout()}
							className="sfprofilenametest"
							style={{ ...styles.popoverMenuItem, paddingLeft: "6px" }}
						>
							<Typography>
								{" "}
								<span style={menu}>
									{getLocalizedStrings().label.COMMON.SIGN_OUT}
								</span>{" "}
							</Typography>
						</MenuItem>
					)}
				</Menu>
			</div>
			<div className="UserProfile-menu" />
		</div>
	);
};

export default Profile;
