import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction'
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { useDispatch, useSelector } from "react-redux";
import { FormControl, TextField } from '@mui/material'
import { isValidParam, getStringParam, isUrl } from '../../../services/helper/parameterVerifier';
import { updateExternalMenuLink } from '../../../services/actions/appActions';
import { MODULES } from '../../../services/constants/constants';


const ExternalMenu = () => {

    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);
    const { callFrom } = appContainer.dialogProps.data;
    const [title, setTitle] = useState(callFrom == "update" ? appContainer.dialogProps.data.moduleItem.label : '');
    const [link, setLink] = useState(callFrom == "update" ? appContainer.dialogProps.data.moduleItem.url : '');
    const [linkValidationErrorText, setLinkValidationErrorText] = useState('');
    const [hasLinkValidationError, setHasLinkValidationError] = useState(false);
    
    function getHomeId(name) {
        let obj = MODULES.find(f => f.name == name);
        let id = obj?.id || 0;
        return id;
    }


    const handleTitleAndLinkChange = (event, name) => {
        let target = event.target;
        let value = target.value;
        if (name === 'title') {
            setTitle(value);
        }
        if (name === 'link') {
            if (isValidParam(value) && getStringParam(value).length > 120) {
                setHasLinkValidationError(true);
                setLinkValidationErrorText(getLocalizedStrings().label.COMMON.EXTERNAL_LINK_MAX_LENGTH);
            } else {
                setHasLinkValidationError(false);
                setLinkValidationErrorText("");
            }
            setLink(value);
        }

    }

    const processFormData = () => {
        let dialogTitle = "";
        let dialogUrl = "";
        if (callFrom != undefined && callFrom == "update") {
            dialogTitle = appContainer.dialogProps.data.moduleItem.label;
            dialogUrl = appContainer.dialogProps.data.moduleItem.url;
        }

        let component =
            <div key={'sfas-cointainer-from'} style={{ marginLeft: '22px', marginRight: '22px' }}>

                <div className='sfas-cointainer-from-class' id='sfas-cointainer-from-id' >
                    <h4 style={{ fontWeight: 'bold' }}> {callFrom == "update" ? "Update the external link for the menu" : "Add an external link to the menu"}</h4>
                </div>
                <div >
                    <FormControl style={{ width: '100%', marginLeft: '0px' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            label="Title"
                            fullWidth={true}
                            name="name"
                            id="name"
                            value={title}
                            defaultValue={dialogTitle != "" ? dialogTitle : ''}
                            onChange={(e) => handleTitleAndLinkChange(e, 'title')}
                            disabled={false}
                            autoFocus
                            autoComplete="new-password"
                            margin='dense'
                            className={"sf-fields-bg"}
                            size="small"
                        />
                    </FormControl>
                </div>
                <div >
                    <FormControl style={{ width: '100%', marginTop: '8px' }} className="test" noValidate autoComplete="off">
                        <TextField style={{}}
                            variant="outlined"
                            label="Link"
                            fullWidth={true}
                            name="link"
                            id="link"
                            value={link}
                            defaultValue={dialogUrl != "" ? dialogUrl : ''}
                            onChange={(e) => handleTitleAndLinkChange(e, 'link')}
                            autoComplete="new-password"
                            margin='dense'
                            className={"sf-fields-bg"}
                            error={hasLinkValidationError}
                            helperText={linkValidationErrorText}
                            size="small"
                        />
                    </FormControl>

                </div>


                <div style={{ float: 'right', marginTop: '10px' }}>
                    {getActionButtons()}
                </div>
            </div>
        return component;
    }

    const getActionButtons = () => {
        let callFrom = appContainer.dialogProps.data.callFrom;
        let buttons = [];
        buttons.push(
            <Button
                primary={true}
                onClick={() => addData()}
                style={styles.primaryButton}
            > {callFrom != "update" ? getLocalizedStrings().label.COMMON.ADD : "Update"}</Button>
        );

        buttons.push(
            <Button
                primary={true}
                onClick={() => handleClose()}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
        );
        return buttons;
    }
    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const addData = () => {
        if (!title || title.length === 0 || title.trim() === '') {
            dispatch(showCustomSnackBar("Title field cannot be blank", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else if (!link) {
            dispatch(showCustomSnackBar("Link field cannot be blank", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
        else if (!isUrl(link)) {
            dispatch(showCustomSnackBar(link + ' ' + getLocalizedStrings().message.COMMON.URL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));

        }
        else if (isValidParam(title) && getStringParam(title).length > 32) {
            dispatch(showCustomSnackBar('Title cannot be more than 32 characters.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
        else if (isValidParam(link) && getStringParam(link).length > 120) {
            dispatch(showCustomSnackBar('External link cannot be more than 120 characters.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
        else {
            let params = {};
            params.name = title;
            params.externalLink = link;
            let callFrom = appContainer.dialogProps?.data?.callFrom;
            if (callFrom == "update") {
                let id = appContainer.dialogProps.data?.moduleItem?.id;
                let homeName = appContainer.dialogProps.data?.moduleItem?.home_name;
                params.callFrom = "update";
                params.id = id;
                if (homeName!="") {
                    params.home_id = getHomeId(homeName);
                }
            } else {
                params.callFrom = "new";
            }

            let promise = Promise.resolve(HTTPClient.post(endPoints.HOME.GET_EXTERNAL_LINK, params));
            promise.then((response) => {
                if (isValidParam(response) && response.status === 0) {
                    let msg= callFrom == "update" ? "Link successfully updated" : "Link successfully added";
                    dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                   let extMenuObj= {
                        menuItem:{ ...response.data},
                        status:callFrom == "update"?'updated':'added'
                    }
             
                        dispatch(updateExternalMenuLink(extMenuObj))
                    
                   
                    dispatch(getAppDialog(false, null, null, null, null, null));
                }

                else {
                    if (response.error.message === 'duplicate') {
                        let moduleName = app.home.modules.module;
                        dispatch(showCustomSnackBar(title + ' ' + "item name already exists in Available Menu Items", styles.snackbarBodyStyleError, styles.snackBarStyleTop));

                    }
                    else {
                        dispatch(showCustomSnackBar("Something is wrong", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }

                }
            })
        }

    }

    return (
        <>
            {processFormData()}
        </>
    )

}
export default ExternalMenu;