import { actions } from '..//constants/actions';
import { getActiveTab } from '..//helper/sfTabManager';
import { getObjectParam, getStringParam, isValidParam, makeValidObjectName } from '..//helper/parameterVerifier';
import { getObjectNameByLabel } from '..//helper/common';
import { constants } from '..//constants/constants';

const treeViewInitialState = {
    mounted: false,
    data: {
        object: ''
    },
    error: null,
    actionsOnSelectedRows: [],
    selectedRows: [],
    queryName: '',
    isSetHeader: false,
    isShowGroupBy: false,
    groupByInfo: {
        object: ''
    },
    isGroupByMounted: false,
    isSearchFieldInfoMounted: false,
    searchFieldInfo: {
        object: ''
    },
    isShowLeadstoreSearchFld: false,
    isRefreshTreeView: false
}

const listViewReducer = (state = treeViewInitialState, action) => {
    let isMount = false;
    switch (action.type) {
        case actions.TREEVIEW_SET_SELECTED_RECORD:
        return {
            ...state,
            selectedRows: action.payload
        }
            
        case actions.TREEVIEW_REFRESH:
            return {
                ...state,
                isRefreshTreeView: action.payload,
                selectedRows:action.payload?[]:state.selectedRows
            }
            case actions.TREEVIEW_INFO_GET_FULFILLED:
            isMount = isListViewMount(action.payload);
            if (isMount) {
                return {
                    ...state,
                    isGroupByMounted: true,
                    mounted: true,
                    groupByInfo: action.payload,
                }
            } else {
                return {
                    ...state
                }
            }
            case actions.TREEVIEW_GET_FULFILLED:
            isMount = isListViewMount(action.payload);
            if (isMount) {
                return {
                    ...state,
                    mounted: true,
                    data: action.payload,
                    actionsOnSelectedRows: [],
                    selectedRows: [],
                    queryName: action.payload !== null ? action.payload.query_name : '',
                    isSetHeader: true,
                    isShowGroupBy: false
                }
            } 
            break;
        default:
            return state;
    }
}

export default listViewReducer;

const isListViewMount = (payload) => {
    let activeTab = getObjectParam(getActiveTab());
    let activeTabObject = getStringParam(activeTab.object);
    let payloadObject = "";
    activeTabObject = makeValidObjectName(activeTabObject);
    if (activeTabObject === constants.SEARCH_RESULTS && isValidParam(activeTab.info.search) && getStringParam(activeTab.info.search.object) !== "") {
        activeTabObject = getStringParam(activeTab.info.search.object);
    } else if (activeTabObject === constants.ADVANCE_SEARCH_RESULTS && isValidParam(activeTab.info.advanceSearch) && getStringParam(activeTab.info.advanceSearch.object) !== "") {
        activeTabObject = getStringParam(activeTab.info.advanceSearch.object);
    }
    if (activeTabObject === constants.RECYCLE_BIN_OBJECT) {
        activeTabObject = constants.ACCOUNTS_OBJECT;
    }
    payloadObject = getStringParam(getStringParam(payload.object));
    payloadObject = getObjectNameByLabel(payloadObject);
    payloadObject = (payloadObject === constants.AB_CAMPAIGNS_OBJECT) ? constants.BROADCAST_CAMPAIGNS_OBJECT : payloadObject;
    payloadObject = (payloadObject === constants.TOUCH_CAMPAIGNS_OBJECT) ? constants.AUTOMATION_DESIGNER_OBJECT : payloadObject;

    if (activeTab && activeTabObject.toUpperCase() === payloadObject.toUpperCase()) {
        return true;
    } else {
        return false;
    }
}