import React from 'react';
import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';
import { updateNotificationItems } from '../../services/actions/appActions';
import { getCookie, setCookie } from '..//helper/sfCookies';
import store from '../../../src/services/store';
import { isValidParam, getObjectParam, getIntParam, getBooleanParam, getArrayParam } from '..//helper/parameterVerifier';

const initNotfiAndReminder = () => {
    try {
        setTimeout(() => {
            setInterval(() => {
                getNotificationInfo(true);
            }, 60000);  // 1 Minute
            triggerNotification();
        }, 120000);  // 2 Minutes

    } catch (error) {
        console.error("Error in 'notification&ReminderActions.js -> initNotfiAndReminder()':" + error);
    }
}
export default initNotfiAndReminder;

function triggerNotification() {
    try {
        let curDt = new Date();
        let toDay = curDt.getFullYear() + '-' + curDt.getMonth() + '-' + curDt.getDate();
        let state = store.getState();
        let projectId = getIntParam(state.app.me.projectId);
        let userId = getIntParam(state.app.me.id);
        let cookieName = 'notification_' + projectId + '_' + userId;
        let cookieVal = getCookie(cookieName);
        if (!isValidParam(cookieVal) || cookieVal !== toDay) {
            setCookie(cookieName, toDay, 1);
            getNotificationInfo();
        }

    } catch (error) {
        console.error("Error in 'notification&ReminderActions.js -> triggerNotification()':" + error);
    }
}

function getNotificationInfo(isTaskAppointments) {
    let url = null;
    var promise = null;
    try {
        url = endPoints.NOTIFICATIONS_REMINDER.NOTIFICATION_GET;
        promise = Promise.resolve(HTTPClient.get(url, { is_task_appointments: getBooleanParam(isTaskAppointments) }));
        promise.then(response => {
            if (isValidParam(response)) {
                response = getObjectParam(response);
                let notifications = [];
                let appointments = getArrayParam(response.appointments);
                appointments.forEach((itemData) => {
                    if (itemData !== null) {
                        const _item = { ...itemData };
                            _item.type = 'appointments'
                        notifications.push({..._item});
                    }
                });

                let tasks = getArrayParam(response.tasks);
                tasks.forEach((itemData) => {
                    if (itemData !== null) {
                        const _item = { ...itemData };
                            _item.type = 'tasks'
                            notifications.push({..._item});
                    }
                });

                let objectRecordMap = getObjectParam(response.object_reminder);
                for (let objId in objectRecordMap) {
                    let recordArr = getArrayParam(objectRecordMap[objId]);
                    recordArr.forEach((itemData) => {
                        if (itemData !== null) {
                            const _item = { ...itemData };
                                _item.type = 'reminder'
                                notifications.push({..._item});
                        }

                    });
                }
                store.dispatch(updateNotificationItems(notifications))
            }
        })
    } catch (e) {
        console.error("Error in 'notification&ReminderActions.js -> getNotificationInfo()':" + e);
    }
    return promise;
}
