import { actions } from '..//constants/actions';
import concat from 'lodash/concat';

const reportInitialState = {
    marketingResults: [],
    campaignReport: 'broadcast',
    marketingReportData: [],
    object: ''
}

const marketingReportReducer = (state = reportInitialState, action) => {
    switch (action.type) {
        case actions.MARKETING_REPORTS_REDUCER_REDIRECTION:
            return {
                ...state,
                marketingResults: action.payload.marketingResults,
            }
        case actions.EMAIL_MARKETING_REPORTS_DETAILS:
            return {
                ...state,
                marketingReportData: action.payload.marketingReportData,
                object: action.payload.object
            }
        case actions.EMAIL_MARKETING_REPORTS_DETAILS_SCROLL:
            return {
                ...state,
                marketingReportData: concat(state.marketingReportData, action.payload.marketingReportData)                                                                                    
            }
        case actions.EMAIL_MARKETING_REPORTS_TOGGLE:
            return {
                ...state,
                campaignReport: action.payload.selectedCampaignReport,   
                object: action.payload.object                                                                                
            }
        default:
            return state;
    }
}
export default marketingReportReducer;